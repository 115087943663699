import React, { FC, useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { disableMarketEditMode, setOrderWindowType } from '../../../../../../redux/orders/OrdersSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store_hooks'
import { MarketOrderTypes, OrderWindowTypes } from '../../../../../../utils/enums'
import { IMarketOrderSetupState } from '../../../../../../utils/interfaces/IOrder.interface'
import {
  HomeID,
  MarketOrderModalId,
  MarketOrderModalLocators,
  MarketOrderModalTexts
} from '../../../../../../utils/locators/locators'
import FTOModals, {
  FTOModalAbstractProps,
  getFTOModalContext,
  ITabableOrderModalProps
} from '../../../../../modals/FTOModals/FTOModal'
import { DEFAULT_ORDER } from '../../../../../modals/FTOModals/FTOModalComponents'
import WebTesterFormattedText from '../../../../topMenuItems/WebTesterFormattedText'
import NotEnoughFreeMarginModal from '../NotEnoughFreeMarginModal'
import MarketOrderWindowCommonTab from './Tabs/CommonTab'
import MarketOrderWindowTrailingStopTab from './Tabs/TrailingStopTab'
export interface IMarketOrderSavedState {
  takeProfit: number
  stopLoss: number

  takeProfitMargin: number
  stopLossMargin: number

  lot: number
  buySell: MarketOrderTypes
  isRisk: boolean
  riskPercent: number
}

export const defaultMarketOrderSavedState = {
  takeProfit: 0,
  stopLoss: 0,
  stopLossMargin: 5,
  takeProfitMargin: 5,
  buySell: MarketOrderTypes.Sell,
  isRisk: false,
  riskPercent: 0.2,
  lot: 0.1
} as IMarketOrderSavedState

export const MarketOrderContext = getFTOModalContext<IMarketOrderSavedState>()

// something from old stuff
export const DEFAULT_MARKET_ORDER_WINDOW_STATE: IMarketOrderSetupState = {
  ...DEFAULT_ORDER,
  riskCalculationLabel: false,
  stopLossTooltip: false,
  takeProfitTooltip: false,
  riskPercentage: 0.2,
  calculatedLots: 0,
  slButton: 0,
  tpButton: 0,
  slDropdown: 5,
  tpDropdown: 5
}

interface MarketOrderWindowProps extends ITabableOrderModalProps {}

const MarketOrderWindow: FC<MarketOrderWindowProps> = ({ currentTab }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setOrderWindowType(OrderWindowTypes.Market))
  }, [])
  return (
    <>
      {/* Content of modal */}

      {currentTab.id == MarketOrderModalLocators.CommonTab && <MarketOrderWindowCommonTab />}
      {currentTab.id == MarketOrderModalLocators.TrailingStopTab && <MarketOrderWindowTrailingStopTab />}

      <NotEnoughFreeMarginModal />
    </>
  )
}

const MarketOrderModal: FC<FTOModalAbstractProps> = (props) => {
  const dispatch = useAppDispatch()
  const tabs = [
    {
      id: MarketOrderModalLocators.CommonTab,
      title: <WebTesterFormattedText id={MarketOrderModalId.Common} defaultMessage='Common' />
    },
    {
      id: MarketOrderModalLocators.TrailingStopTab,
      title: (
        <FormattedMessage id={MarketOrderModalId.TrailingStop} defaultMessage={MarketOrderModalTexts.TrailingStop} />
      )
    }
  ]
  const isEdit = useAppSelector((state) => state.orders.marketEditMode)
  const isPipette = useAppSelector((state) => state.orders.pipetteModeIsActive)
  const customToggle = useCallback(() => {
    if (isEdit && !isPipette) {
      dispatch(disableMarketEditMode())
    }
    props.toggleModal()
  }, [isPipette, isEdit])

  return (
    <FTOModals.Modal
      id={HomeID.MarketOrderID}
      title={<WebTesterFormattedText id={MarketOrderModalId.MarketOrder} defaultMessage='Market Order' />}
      tabs={tabs}
      {...props}
      context={MarketOrderContext}
      customContextFields={{ toggleModal: customToggle }}
      savedValuesDefault={defaultMarketOrderSavedState}
      component={MarketOrderWindow}
    />
  )
}
export default MarketOrderModal
