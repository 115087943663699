import { LineStyle } from '../enums'

export enum LineType {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export interface ILine {
  color: string
  strokeType: LineStyle
  selected?: boolean
}

export interface IHorizontalLine extends ILine {
  type: LineType
  yPos: number
}

export interface IVerticalLine extends ILine {
  type: LineType
  xPos: number
}

export interface ISlTpLine {
  id: string
  ticketId: number
  lineType: string
  slOrTpPrice: number
}

export interface Position {
  Left: 'left'
  Right: 'right'
}

export interface IOrderLine {
  bgFill: string
  bgOpacity: number
  stroke: string
  strokeOpacity: number
  strokeDasharray: LineStyle
  strokeWidth: number
  textFill: string
  fontFamily: string
  fontSize: number
  fontStyle: string
  fontWeight: string
  text: string
  textBox: {
    height: number
    left: number
    padding: {
      left: number
      right: number
    }
    closeIcon: {
      padding: {
        left: number
        right: number
      }
      width: number
    }
  }
  edge: {
    stroke: string
    strokeOpacity: number
    strokeWidth: number
    fill: string
    fillOpacity: number
    orient: Position
    at: Position
    arrowWidth: number
    dx: number
    rectWidth: number
    rectHeight: number
  }
  id: string
  draggable: boolean
  yValue: number
  ticketId: number
  lineType: string
}
