import React, { VFC } from 'react'
import { ReactComponent as arrowDown } from '../../icons/icon.arrow-down.svg'
import { ReactComponent as arrowUp } from '../../icons/icon.arrow-up.svg'
import { ReactComponent as back } from '../../icons/icon.back.svg'
import { ReactComponent as check } from '../../icons/icon.check.svg'
import { ReactComponent as crossInCircle } from '../../icons/icon.cross-in-circle.svg'
import { ReactComponent as cross } from '../../icons/icon.cross.svg'
import { ReactComponent as dragRowsV } from '../../icons/icon.drag-rows-v.svg'
import { ReactComponent as dragRows } from '../../icons/icon.drag-rows.svg'
import { ReactComponent as maximizeChartSmall } from '../../icons/icon.maximize-chart-small.svg'
import { ReactComponent as Pipette } from '../../icons/icon.pipette.svg'
import { ReactComponent as resetProject } from '../../icons/icon.reset-project.svg'
import { ReactComponent as RewardToRiskBuy } from '../../icons/icon.reward-to-risk-buy.svg'
import { ReactComponent as RewardToRiskSell } from '../../icons/icon.reward-to-risk-sell.svg'
import { ReactComponent as RewardToRiskTool } from '../../icons/icon.reward-to-risk-tool.svg'
import { ReactComponent as RotateDeviceIcon } from '../../icons/icon.rotate_device.svg'
import { ReactComponent as thumbsDown } from '../../icons/icon.thumbs-down.svg'
import { ReactComponent as thumbsUp } from '../../icons/icon.thumbs-up.svg'
import { ReactComponent as windows } from '../../icons/icon.windows.svg'

type RightIcon = typeof arrowDown
export type RightIconName =
  | 'arrowDown'
  | 'arrowUp'
  | 'back'
  | 'check'
  | 'crossInCircle'
  | 'cross'
  | 'dragRowsVertical'
  | 'dragRows'
  | 'pipette'
  | 'rotateDevice'
  | 'maximizeChartSmall'
  | 'resetProject'
  | 'RewardToRiskSell'
  | 'RewardToRiskBuy'
  | 'RewardToRiskTool'
  | 'thumbsUp'
  | 'thumbsDown'
  | 'windows'

type RightIconList = { [key in RightIconName]: RightIcon }
const IconList: RightIconList = {
  pipette: Pipette,
  arrowDown: arrowDown,
  arrowUp: arrowUp,
  back: back,
  check: check,
  cross: cross,
  dragRows: dragRows,
  dragRowsVertical: dragRowsV,
  crossInCircle: crossInCircle,
  rotateDevice: RotateDeviceIcon,
  maximizeChartSmall: maximizeChartSmall,
  resetProject: resetProject,
  RewardToRiskSell: RewardToRiskSell,
  RewardToRiskBuy: RewardToRiskBuy,
  RewardToRiskTool: RewardToRiskTool,
  thumbsUp: thumbsUp,
  thumbsDown: thumbsDown,
  windows
}

interface IFTORightIcon extends Omit<React.SVGProps<SVGElement>, 'onLoad' | 'onError' | 'ref'> {
  icon: RightIconName
}
const FTORightIcon: VFC<IFTORightIcon> = ({ icon, ...props }) => {
  const IconComponent = IconList[icon]
  return (
    <>
      <IconComponent {...props} />
    </>
  )
}

export default FTORightIcon
