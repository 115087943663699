export enum ID {
  BottomMenuContent = 'BottomMenuContent',
  LeftMenuWrapper = 'LeftMenuWrapper',
  ChartWindow = 'ChartWindow',
  ChartWindowWithTabs = 'ChartWindowWithTabs',
  OpenPositionsTable = 'OpenPositionsTable',
  PendingOrdersTable = 'PendingOrdersTable',
  AccountHistoryTable = 'AccountHistoryTable',
  AccountHistoryMobileContent = 'AccountHistoryMobileContent',
  JournalTable = 'JournalTable',
  NewsTable = 'NewsTable',
  NotesTable = 'NotesTable',
  AccountHistoryTableFooter = 'AccountHistoryTableFooter',
  TrendMenuItem = 'TrendMenuItem',
  RepeatLesson = 'RepeatLesson',
  NotEnoughFreeMargin = 'NotEnoughFreeMargin',
  ProvideFeedbackButton = 'ProvideFeedbackButton',
  SendFeedback = 'SendFeedback',
  StartDemonstrationButton = 'StartDemonstrationButton',
  StartDemonstration = 'StartDemonstration',
  BasicGreatJobDownload = 'BasicGreatJobDownload',
  MobileMainBlock = 'MobileMainBlock',
  MobileCoursesGroup = 'MobileCoursesGroup',
  MobileH4 = 'H4',
  GraphicPanel = 'GraphicPanel'
}
