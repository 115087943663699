import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ToastOptions } from 'react-toastify'
import shortid from 'shortid'
import { BasicLessonsID, IndicatorComponentType, LineStyle } from './enums'
import { ICurrentBar } from './interfaces/ICurrentBar'
import { LineType } from './interfaces/ILine'
import { INews } from './interfaces/INews.interface'
import { IProject, IProjectPascalCase } from './interfaces/IProject.interface'
import { ITutorial, ITutorialItem } from './interfaces/ITutorial.interface'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChartBar } from '@fortawesome/pro-solid-svg-icons'

export const dateRange: [number, number] = [
  1580515200, // Feb 1st, 2020
  1612130399 // Jan 31st, 2021
]

export const symbols: string[] = ['EURUSD', 'GBPUSD', 'USDJPY', 'USDCHF', 'USDCAD', 'NZDUSD']

export const topMenuTabs: string[] = [
  'Home',
  'Orders',
  'Charts',
  'Strategies',
  'Windows',
  'Settings',
  'Education',
  'Store',
  'Help'
]

export const bottomMenuTabs = ['Open Positions', 'Pending Orders', 'Account History', 'Journal', 'News', 'Notes']

export const leftMenuTabs: string[] = ['Symbols', 'Statistics', 'TickChart']
// export const bottomMenuTabs = Object.freeze({
// 	openPositions: Symbol('Open Positions'),
// 	pendingOrders: Symbol('Pending Orders'),
// 	accountHistory: Symbol('Account History'),
// 	journal: Symbol('Journal'),
// 	news: Symbol('News'),
// 	notes: Symbol('Notes')
// })

export const leftMenuWindows: string[][] = [['Symbols'], ['Statistics']]

export const chartSettings: {
  light: { [key: string]: string }
  dark: { [key: string]: string }
} = {
  light: {
    textColor: '#ff0000',
    linesColor: '#555555',
    axesLinesColor: '#555555',
    gridPriceTimeColor: '#555555',
    crossHairColor: '#333333',
    fibonacciRetracementColor: '#555555',
    periodSeparatorsColor: '#123abc'
  },
  dark: {
    axesLinesColor: '#858585',
    gridPriceTimeColor: '#858585',
    crossHairColor: '#858585',
    fibonacciRetracementColor: '#fa6',
    linesColor: '#009900',
    periodSeparatorsColor: '#123abc'
  }
}

export const upBar: string = '#00B896'
export const downBar: string = '#FF4846'
export const ma1: string = '#FFAE29'
export const ma2: string = '#1471EB'

export const candlesAppearance: {
  wickStroke: string
  fill: (d: { close: number; open: number }) => string
  candleStrokeWidth: number
  widthRatio: number
  opacity: number
} = {
  wickStroke: '#858585',
  fill: function fill(d) {
    return d.close > d.open ? '#00B896' : '#FF4846'
  },
  candleStrokeWidth: 0.2,
  widthRatio: 0.8,
  opacity: 1
}

// export const darkCandlesAppearance: {
// 	wickStroke: string
// 	fill: (d: { close: number; open: number }) => string
// 	stroke: string
// 	candleStrokeWidth: number
// 	widthRatio: number
// 	opacity: number
// } = {
// 	wickStroke: '#858585',
// 	fill: function fill(d) {
// 		return d.close > d.open ? '#2CA69A' : '#EE5253'
// 	},
// 	stroke: '#858585',
// 	candleStrokeWidth: 0.2,
// 	widthRatio: 0.8,
// 	opacity: 1
// }

export const rsiAppearance: {
  stroke: {}
  zeroLineStroke: string
} = {
  stroke: {
    line: '#000000',
    top: '#B8C2CC',
    middle: '#8795A1',
    bottom: '#B8C2CC',
    outsideThreshold: '#b300b3',
    insideThreshold: '#ffccff'
  },
  zeroLineStroke: '#333333'
}

export const macdAppearance: {
  stroke: { macd: string; signal: string }
  fill: { divergence: string }
  zeroLineStroke: string
} = {
  stroke: {
    macd: '#EE5253',
    signal: '#2CA69A'
  },
  fill: {
    divergence: '#4682B4'
  },
  zeroLineStroke: '#333333'
}

export const mouseEdgeAppearance: {
  textFill: string
  stroke: string
  strokeOpacity: number
  strokeWidth: number
  arrowWidth: number
  fill: string
} = {
  textFill: '#ADADAD',
  stroke: '#3C3C3C',
  strokeOpacity: 1,
  strokeWidth: 0, //3
  arrowWidth: 5,
  fill: '#3C3C3C'
}

export const notesTable: {
  dateTime: string
  symbol: string
  title: string
  headline: string
  notes: string
}[] = [
  {
    dateTime: '2018.04.06 16:00',
    symbol: 'EURUSD',
    title: 'bad entry',
    headline: '',
    notes: ''
  },
  {
    dateTime: '2018.04.06 16:00',
    symbol: 'EURUSD',
    title: '',
    headline: '',
    notes: ''
  },
  {
    dateTime: '2018.04.06 16:00',
    symbol: 'EURUSD',
    title: 'nice',
    headline: '',
    notes: ''
  },
  {
    dateTime: '2018.04.06 16:00',
    symbol: 'EURUSD',
    title: 'profit good',
    headline: '',
    notes: ''
  },
  {
    dateTime: '2018.04.06 16:00',
    symbol: 'EURUSD',
    title: 'title3',
    headline: '',
    notes: ''
  },
  {
    dateTime: '2018.04.06 16:00',
    symbol: 'EURUSD',
    title: '',
    headline: '',
    notes: ''
  }
]

export const defaultLots: number[] = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

// export const timeFrameOptionsArray = Object.freeze({
// 	1: '1 minute',
// 	5: '5 minutes',
// 	15: '15 minutes',
// 	30: '30 minutes',
// 	60: '1 hour',
// 	240: '4 hours',
// 	1440: '1 day',
// 	10080: '1 week'
// })

// 1, 5, 15, 30, 60, 240, 1440, 10080, 43200
export const timeFrameOptions: {
  value: number
  name: string
  testAttribute: string
}[] = [
  { value: 1, name: 'M1', testAttribute: 'data-test-m1' },
  { value: 5, name: 'M5', testAttribute: 'data-test-m5' },
  { value: 15, name: 'M15', testAttribute: 'data-test-m15' },
  { value: 30, name: 'M30', testAttribute: 'data-test-m30' },
  { value: 60, name: 'H1', testAttribute: 'data-test-h1' },
  { value: 240, name: 'H4', testAttribute: 'data-test-h4' },
  { value: 1440, name: 'D1', testAttribute: 'data-test-d1' },
  { value: 10080, name: 'W1', testAttribute: 'data-test-w1' },
  { value: 43200, name: 'MN', testAttribute: 'data-test-mn' }
]

export const timeFrameOptionsArray: string[][] = [
  ['1 minute', 'M1', 'data-test-m1'],
  ['5 minutes', 'M5', 'data-test-m5'],
  ['15 minutes', 'M15', 'data-test-m15'],
  ['30 minutes', 'M30', 'data-test-m30'],
  ['1 hour', 'H1', 'data-test-h1'],
  ['4 hours', 'H4', 'data-test-h4'],
  ['1 day', 'D1', 'data-test-d1'],
  ['1 week', 'W1', 'data-test-w1'],
  ['1 month', 'MN', 'data-test-mn']
]

export const demoTimeFrameOptionsArray: string[] = ['M1', 'M5', 'M15', 'M30', 'H1', 'H4', 'D1', 'W1']

export const testingOptionsArray: string[] = [
  '1 minute',
  '5 minutes',
  '15 minutes',
  '30 minutes',
  '1 hour',
  '4 hours',
  '1 day',
  '1 week',
  '1 month'
]

export enum TestingSpeed {
  SPEED_0 = 1000000, // 31 years === never
  SPEED_1 = 6000,
  SPEED_2 = 4000,
  SPEED_3 = 2000,
  SPEED_4 = 1000,
  SPEED_5 = 500,
  SPEED_6 = 250,
  SPEED_7 = 160,
  SPEED_8 = 110,
  SPEED_9 = 80,
  SPEED_10 = 0
}

// export const TestingSpeed = Object.freeze({
// 	SPEED_0: 1000000000000, // 31 years === never
// 	SPEED_1: 6000,
// 	SPEED_2: 4000,
// 	SPEED_3: 2000,
// 	SPEED_4: 1000,
// 	SPEED_5: 500,
// 	SPEED_6: 250,
// 	SPEED_7: 160,
// 	SPEED_8: 110,
// 	SPEED_9: 80,
// 	SPEED_10: 0
// })

export const statisticsParameters: { parameter: string; value: string }[] = [
  {
    parameter: 'Time:',
    value: ''
  },
  {
    parameter: 'Days processed',
    value: '0'
  },
  {
    parameter: 'Months processed',
    value: '0'
  },
  {
    parameter: 'Trades:',
    value: ''
  },
  {
    parameter: 'Total trades',
    value: '0'
  },
  {
    parameter: 'Profitable trades',
    value: '0'
  },
  {
    parameter: 'Losing trades',
    value: '0'
  },
  {
    parameter: 'Profitable trades in a row',
    value: '0'
  },
  {
    parameter: 'Losing trades in a row',
    value: '0'
  },
  {
    parameter: 'Trades / day',
    value: '0'
  },
  {
    parameter: 'Trades / month',
    value: '0'
  },
  {
    parameter: 'Profitable trades / month',
    value: '0'
  },
  {
    parameter: 'Losing trades / month',
    value: '0'
  },
  {
    parameter: 'Maximum profitable trade',
    value: '0.00'
  },
  {
    parameter: 'Maximum losing trade',
    value: '0.00'
  },
  {
    parameter: 'Profit:',
    value: ''
  },
  {
    parameter: 'Net profit',
    value: '0.00'
  },
  {
    parameter: 'Gross profit',
    value: '0.00'
  },
  {
    parameter: 'Profit / month',
    value: '0.00'
  },
  {
    parameter: 'Average profit',
    value: '0.00'
  },
  {
    parameter: 'Average loss',
    value: '0.00'
  },
  {
    parameter: 'Maximum drawdown',
    value: '0.00'
  },
  {
    parameter: 'Profit factor',
    value: '0.00'
  },
  {
    parameter: 'Return, %',
    value: '0.00'
  },
  {
    parameter: 'Other statistics',
    value: ''
  },
  {
    parameter: 'Maximum lot used',
    value: '0.00'
  },
  {
    parameter: 'Restoration factor',
    value: '0.00'
  },
  {
    parameter: 'Reliability factor',
    value: '0.00'
  },
  {
    parameter: 'Profit probability, %',
    value: '0.00'
  },
  {
    parameter: 'Loss probability, %',
    value: '0.00'
  }
]

export const sliderOptions: {
  dots: boolean
  infinite: boolean
  slidesToShow: number
  slidesToScroll: number
  variableWidth: boolean
  draggable: boolean
  arrows: boolean
  speed: number
  responsive: { breakpoint: number; settings: {} }[]
} = {
  dots: false,
  infinite: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  variableWidth: true,
  draggable: false,
  arrows: false,
  speed: 300,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 481,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        infinite: true
      }
    }
  ]
}

export const toastOptions: ToastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined
}

export const defaultProject: IProject = {
  Name: 'Default Project',
  TimeZone: 0,
  DaylightSavingTime: 0,
  TicksGenerated: false,
  UsedTickHistory: false,
  UsedFloatingSpread: false,
  ForwardTestingOnly: false,
  FromDate: dateRange[0],
  ToDate: dateRange[1],
  StartDate: dateRange[0],
  CurrentDate: dateRange[0],
  InitialDeposit: 10000,
  Symbols: ['EURUSD', 'GBPUSD', 'USDJPY'],
  Balance: 10000,
  Equity: 10000,
  Margin: 0,
  FreeMargin: 10000,
  MarketOrders: [],
  PendingOrders: [],
  ClosedOrders: [],
  Notes: [],
  Charts: [
    {
      symbol: 'EURUSD',
      timeFrame: 1,
      comment: '',
      digitsAfterPoint: 5,
      id: shortid(),
      index: 1
    },
    {
      symbol: 'GBPUSD',
      timeFrame: 1,
      comment: '',
      digitsAfterPoint: 5,
      id: shortid(),
      index: 2
    },
    {
      symbol: 'USDJPY',
      timeFrame: 1,
      comment: '',
      digitsAfterPoint: 3,
      id: shortid(),
      index: 3
    }
  ]
}

export const SESSION_CLOSE_TIME: { [key: string]: string } = {
  '-6': 'Sydney close (winter -6)',
  '-8': 'Tokyo close (winter -8)',
  '7': 'London close (winter +7)',
  '2': 'New York close (winter +2)',
  custom: 'Custom'
}
export const DST: { [key: string]: string } = {
  '0': 'No DST',
  '1': 'US DST',
  '2': 'European DST',
  '3': 'Australian DST'
}

export const dummyProject: IProjectPascalCase = {
  Name: 'Dummy Project',
  TimeZone: 0,
  DaylightSavingTime: 0,
  TicksGenerated: false,
  UsedTickHistory: false,
  UsedFloatingSpread: false,
  ForwardTestingOnly: false,
  FromDate: 0,
  ToDate: 0,
  StartDate: 0,
  CurrentDate: 0,
  InitialDeposit: 0,
  Symbols: [],
  Balance: 0,
  Equity: 0,
  Margin: 0,
  FreeMargin: 0,
  MarketOrders: [],
  PendingOrders: [],
  ClosedOrders: [],
  Notes: [],
  Charts: []
}

export const dummyTutorialItem: ITutorialItem = {
  id: '',
  Name: '',
  Description: '',
  Position: 0,
  LessonID: 0,
  LearningTime: '',
  OriginalLessonName: ''
}

export const dummyTutorial: ITutorial = {
  LessonId: 0,
  OriginalLessonName: '',
  Position: 0,
  Type: '',
  Name: '',
  Description: '',
  Author: '',
  CourseName: '',
  LearningTime: '',
  Steps: []
}

export const defaultCurrentBars: ICurrentBar = {
  EURUSD1: {
    close: 1.11688,
    date: '2020-01-02T22:41:00.000Z'
  },
  GBPUSD1: {
    close: 1.31404,
    date: '2020-01-02T22:41:00.000Z'
  },
  USDJPY1: {
    close: 108.519,
    date: '2020-01-02T22:41:00.000Z'
  }
}

export const dummyNewsRecord: INews = {
  Time: 0,
  Symbol: '',
  Title: '',
  Country: '',
  Priority: '',
  Actual: '',
  Consensus: '',
  Previews: '',
  IsPlanned: false
}

export const demoTestLimitationsMessage =
  // <> TODO because alert() not support HTML
  // 	<p>60 minutes have passed.</p>
  // 	<br />
  // 	<p>Your test will be restarted and all information will be lost.</p>
  // 	<br />
  // 	<p>
  // 		To get access to a fully functional unlimited version please consider
  // 		purchasing the software
  // 	</p>
  // </>

  '60 minutes have passed.\n\n' +
  'Your test will be restarted and all information will be lost.\n\n' +
  'To get access to a fully functional unlimited version please consider purchasing the software'

export const desktopOnlyAlert = (
  <>
    <p style={{ fontSize: 18 }}>
      <FormattedMessage id={'100068'} />
    </p>
    <br />
    <p style={{ fontSize: 18 }}>
      <FormattedMessage id={'100069'} />
    </p>
  </>
)

export const demoDataLimitationMessage = (
  <>
    <p>You've reached the end of the sample data set.</p>
    <br />
    <p>Either restart the test or consider purchasing the license for accessing up to 18 years of historical data</p>
  </>
)

export const nonDemoDataLimitationMessage = (
  <>
    <p>You've reached the end of the sample data set.</p>
    <br />
    <p>Either restart the test or consider rewinding back with the help of 'Bar Back' feature (Backspace)</p>
  </>
)

export const inactiveVolumeIndicatorDropdownItem = 'Volume oscillator is already added to the current chart'

export const noSuchElementInDom = 'Unable to find an element for this step of Education Tutorial'

export const indicatorDefaultLook = {
  stroke: '#FFAE29',
  strokeDasharray: LineStyle.Solid,
  strokeWidth: 1,
  strokeOpacity: 1
}

export const oscillatorDefaultLook = {
  lineType: IndicatorComponentType.Line,
  stroke: '#1e90ff',
  strokeDasharray: LineStyle.Solid,
  strokeWidth: 1,
  strokeOpacity: 1
}

export const historicalData: { name: string; isImplemented: boolean }[] = [
  { name: 'EURUSD', isImplemented: true },
  { name: 'GBPUSD', isImplemented: true },
  { name: 'USDJPY', isImplemented: true },
  { name: 'USDCHF', isImplemented: false },
  { name: 'USDCAD', isImplemented: false },
  { name: 'AUDUSD', isImplemented: false },
  { name: 'NZDUSD', isImplemented: false }
]

export const stopOrderAppearance = {
  type: LineType.Horizontal,
  color: '#FF4D4D',
  strokeType: LineStyle.LongDashDot
}

export const straightHorizontalLineAppearance = {
  type: LineType.Horizontal,
  color: '#123abc',
  strokeType: LineStyle.Solid
}

export const trendLineAppearance = {
  stroke: chartSettings.light.linesColor,
  strokeOpacity: 0.8,
  strokeWidth: 2,
  edgeStrokeWidth: 2,
  edgeFill: '#FFFFFF',
  edgeStroke: chartSettings.light.linesColor
}

export const verticalLineAppearance = {
  color: '#555555',
  stroke: chartSettings.light.linesColor,
  opacity: 0.8
}

export const horizontalLineAppearance = {
  color: '#555555',
  stroke: chartSettings.light.linesColor,
  opacity: 0.8
}

export const fibonacciRetracementAppearance = {
  fontFamily: 'Roboto',
  fontSize: 14,
  strokeOpacity: 0.6,
  strokeWidth: 1,
  r: 3,
  edgeStrokeWidth: 1,
  stroke: chartSettings.light.fibonacciRetracementColor,
  fontFill: chartSettings.light.fibonacciRetracementColor,
  nsEdgeFill: chartSettings.light.fibonacciRetracementColor,
  edgeFill: chartSettings.light.fibonacciRetracementColor,
  edgeStroke: chartSettings.light.fibonacciRetracementColor
}

export const gannFanAppearance = {
  fontFamily: 'Roboto',
  fontSize: 14,
  strokeOpacity: 0.4,
  fillOpacity: 0.3,
  r: 3,
  strokeWidth: 0.5,
  edgeStrokeWidth: 1,
  stroke: chartSettings.light.linesColor,
  fill: [
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`,
    `rgba(47, 189, 47, .2)`
  ],
  fontFill: chartSettings.light.linesColor,
  edgeFill: chartSettings.light.linesColor,
  edgeStroke: chartSettings.light.linesColor
}

export const equidistantChannelAppearance = {
  fillOpacity: 1,
  strokeOpacity: 0.4,
  strokeWidth: 1,
  edgeStrokeWidth: 1,
  r: 3,
  edgeFill2: chartSettings.light.linesColor,
  stroke: chartSettings.light.linesColor,
  fill: `rgba(47, 189, 47, .2)`,
  edgeFill: chartSettings.light.linesColor,
  edgeStroke: chartSettings.light.linesColor
}

export const standardDeviationChannelAppearance = {
  // fillOpacity: 1,
  // strokeOpacity: 0.4,
  // strokeWidth: 1,
  // edgeStrokeWidth: 1,
  // r: 3,
  // edgeFill2: chartSettings.light.linesColor,
  // stroke: chartSettings.light.linesColor,
  // fill: `rgba(47, 189, 47, .2)`,
  // edgeFill: chartSettings.light.linesColor,
  // edgeStroke: chartSettings.light.linesColor
}

export const rectangleAppearance = {
  // edgeStrokeWidth: 3,
  // edgeStroke: '#000000',
  // edgeFill: 'rgba(47, 189, 47, .2)',
  // r: 0,
  // withEdge: false,
  // strokeWidth: 1,
  // strokeDasharray: 'Solid',
  // stroke: 3,
  // strokeOpacity: 0.6
}

export const ellipseAppearance = {
  stroke: 3,
  strokeOpacity: 0.6
}

export const straightVerticalLineAppearance = {
  type: LineType.Vertical,
  color: '#123abc',
  strokeType: LineStyle.Solid
}

export const defaultInteractiveTextProps = {
  bgFill: '#D3D3D3',
  bgOpacity: 1,
  bgStrokeWidth: 1,
  textFill: '#F10040',
  fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 'normal'
}

export const hoverText = {
  interactiveText: {
    text: 'Click to select text label',
    selectedText: `Text label selected`
  },
  trendLine: {
    text: 'Click to select trend line',
    selectedText: `Trend line selected`
  },
  ray: {
    text: 'Click to select ray',
    selectedText: `Ray selected`
  },
  fibonacciRetracement: {
    text: 'Click to select Fibonacci retracement',
    selectedText: `Fibonacci retracement selected`
  },
  gannFan: {
    text: 'Click to select Gann fan',
    selectedText: `Gann fan selected`
  },
  equidistantChannel: {
    text: 'Click to select equidistant channel',
    selectedText: `Equidistant channel selected`
  },
  standardDeviationChannel: {
    text: 'Click to select standard deviation channel',
    selectedText: `Standard deviation channel selected`
  }
}

// Run a Strategy, Data Center, New project
export const nonImplementedQuickStartGuideTutorialsIndexes: number[] = [3, 4, 5]

export const lessonsRequiredSvgChartType = [BasicLessonsID.WhatAreBaseAndQuoteCurrencies]
