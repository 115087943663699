import axios from 'axios'
import msgpack from 'msgpack-lite'
import { parseResponse } from '../../components/webTester/helpers/initialState'
import { IBar } from '../interfaces/IHistoricalData.interface'
import { getBaseServerUrl } from './utils'

const apiClient = axios.create({
  baseURL: getBaseServerUrl() + 'data/api/HistoricalData/bars/daily',
  responseType: 'arraybuffer',
  headers: { 'Content-Type': 'application/octet-stream' }
})

export const getData = async (
  symbol: string,
  broker: string,
  start: number,
  end: number,
  timeFrame: number,
  useMessagePack: boolean
): Promise<IBar[]> => {
  const params = {
    Symbol: symbol,
    Broker: broker,
    Start: start,
    End: end,
    TimeFrame: timeFrame,
    UseMessagePack: useMessagePack
  }

  try {
    const msgPackResponse = await apiClient.get('/', { params })
    const arrayBuffer = new Uint8Array(msgPackResponse.data)
    const jsonResponse = msgpack.decode(arrayBuffer)
    return parseResponse(jsonResponse)
  } catch (e) {
    console.log(
      `FAIL.....: loading bars for symbol${params.Symbol} and timeframe ${params.TimeFrame}. Broker: ${params.Broker}`
    )
    return []
  }
}
