import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'
import { FtoRunMode, ITelemetryState } from './types'

export const initTelemetryState: ITelemetryState = {
  userGroup: undefined,
  ftoRunMode: ''
}

export const telemetrySlice = createSlice({
  name: 'telemetry',
  initialState: { ...initTelemetryState },
  reducers: {
    setUserGroup(state, action: PayloadAction<string | null>) {
      if (action.payload) {
        const value = parseInt(action.payload)
        state.userGroup = isNaN(value) ? undefined : value
      }
    },

    setFtoRunMode(state, action: PayloadAction<FtoRunMode>) {
      const { runMode, isTest } = action.payload

      state.ftoRunMode = `${runMode}_${isMobile ? 'MOBILE' : 'DESKTOP'}${isTest ? '_TEST' : ''}`
    }
  }
})

export default telemetrySlice.reducer
