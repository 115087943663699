import { combineReducers, configureStore } from '@reduxjs/toolkit'
import GraphicsSlice from '../graphics/GraphicsSlice'
import graphsReducer from '../graphs/reducers'
import journalReducer from '../journal/reducers'
import joyrideSlice from '../joyride/joyrideSlice'
import mobileReducer from '../mobile/reducer'
import modalsReducer from '../modals/reducers'
import OrdersSlice from '../orders/OrdersSlice'
import panelsSlice from '../panels/panelsSlice'
import contextMenuReducer from '../reducers/contextMenuReducer'
import SaveSlice from '../saves/SaveSlice'
import settingsReducer from '../settings/reducers'
import ShortcutSlice from '../shortcuts/ShortcutSlice'
import telemetryReducer from '../telemetry/reducers'
import testingReducer from '../testing/reducer'
import webTesterSlice from '../webTester/webTesterSlice'
import windowsSlice from '../windows/WindowsSlice'

const initialState = {}

export const rootReducer = combineReducers({
  orders: OrdersSlice,
  graphs: graphsReducer,
  modals: modalsReducer,
  windows: windowsSlice,
  panels: panelsSlice,
  graphics: GraphicsSlice,
  settings: settingsReducer,
  contextMenu: contextMenuReducer,
  mobile: mobileReducer,
  joyride: joyrideSlice,
  journal: journalReducer,
  telemetry: telemetryReducer,
  testing: testingReducer,
  shortcuts: ShortcutSlice,
  saves: SaveSlice,
  webTesterRedux: webTesterSlice
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  })
}

export const store = setupStore()

export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
