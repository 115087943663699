export const initialContextMenuState = {
  visible: false,
  x: 0,
  y: 0,
  chart: {},
  indicator: {
    editIndicator: `Edit ${'indicator'}`,
    deleteIndicator: `Delete ${'indicator'}`
  },
  oscillator: {
    addIndicator: 'Add Indicator to Window',
    closeOscillator: `Close ${'oscillator'} Window`
  },
  order: {
    closePartOfPosition: 'Close Part of Position',
    moveStopLossToBreakeven: 'Move Stop Loss to Breakeven',
    duplicatePosition: 'Duplicate Position',
    viewAndModifyOrder: 'View and Modify Order'
  },
  accountHistory: {
    exportHistoryToExcel: 'Export History to Excel',
    exportHistoryToFile: 'Export History to File',
    selectClosedTrade: 'Select Closed Trade'
  },
  auxiliaryTabs: {
    clearJournal: 'Clear Journal',
    news: 'Find News On Chart',
    notes: 'Find Note On Chart'
  },
  statistics: {
    exportStatisticsToExcel: 'Export Statistics to Excel',
    exportStatisticsToFile: 'Export Statistics to File'
  }
}

export default (state = initialContextMenuState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
