import {
  IJournalState,
  JournalActionTypes,
  ADD_OPEN_MARKET_ORDER_TO_JOURNAL,
  ADD_OPEN_PENDING_ORDER_TO_JOURNAL,
  ADD_CLOSED_MARKET_ORDER_TO_JOURNAL,
  ADD_CLOSED_PENDING_ORDER_TO_JOURNAL,
  ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL,
  ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL,
  ADD_START_TEST_TO_JOURNAL,
  ADD_PAUSE_TEST_TO_JOURNAL
} from './types'
import { IJournalRecord } from '../../utils/interfaces/IJournalRecord.interface'
import { AccountChangeType } from '../../utils/enums'

const initialJournalState: IJournalState = {
  journalRecords: []
}

export default (state = initialJournalState, action: JournalActionTypes): IJournalState => {
  let dateTime: string = ''
  let message: string = ''
  let journalRecord: IJournalRecord = { dateTime: '', message: '' }

  switch (action.type) {
    case ADD_OPEN_MARKET_ORDER_TO_JOURNAL: {
      //@ts-ignore
      dateTime = `${action.payload.openTime}`
      let [orderType, actionType]: string[] = ['Market', 'placed']
      // TODO: check if it's a new market order or a pending order became a market one
      if (false) {
        ;[orderType, actionType] = ['Pending', 'executed']
      }

      message = `${orderType} order #${action.payload.ticket}
				(${action.payload.symbol}, ${action.payload.type}, lot: ${action.payload.lots},
				open price: ${action.payload.marketPrice}, sl: ${action.payload.sl},
				tp: ${action.payload.tp}) was ${actionType} at price ${action.payload.marketPrice}`

      journalRecord = { dateTime, message }

      return {
        ...state,
        journalRecords: [...state.journalRecords, journalRecord]
      }
    }

    case ADD_OPEN_PENDING_ORDER_TO_JOURNAL: {
      //@ts-ignore
      dateTime = `${action.payload.createdAt}`
      //@ts-ignore
      const { execPrice } = action.payload
      message = `Pending order #${action.payload.ticket}
						(${action.payload.symbol}, ${action.payload.type}, lot: ${action.payload.lots},
						open price: ${execPrice}, sl: ${action.payload.sl},
						tp: ${action.payload.tp}) was placed at price ${execPrice}`

      journalRecord = { dateTime, message }

      return {
        ...state,
        journalRecords: [...state.journalRecords, journalRecord]
      }
    }

    case ADD_CLOSED_MARKET_ORDER_TO_JOURNAL: {
      //@ts-ignore
      dateTime = `${action.payload.closeTime}`
      //@ts-ignore
      const { openPrice, closePrice, points, profit } = action.payload

      let closeType: string = 'manually'
      if (action.payload.sl === closePrice) {
        closeType = 'by stop loss'
      } else if (action.payload.tp === closePrice) {
        closeType = 'by take profit'
      }

      message = `Order #${action.payload.ticket}
				(${action.payload.symbol}, ${action.payload.type}, lot: ${action.payload.lots}, open price: ${openPrice},
				sl: ${action.payload.sl}, tp: ${action.payload.tp}) was closed ${closeType}.
				Profit: ${points} pips, $${profit}`

      return {
        ...state,
        journalRecords: [...state.journalRecords, journalRecord]
      }
    }

    case ADD_CLOSED_PENDING_ORDER_TO_JOURNAL: {
      //@ts-ignore
      dateTime = `${action.payload.createdAt}`
      //@ts-ignore
      const { execPrice } = action.payload
      message = `Order #${action.payload.ticket}
						(${action.payload.symbol}, ${action.payload.type}, lot: ${action.payload.lots},
						open price: ${execPrice}, sl: ${action.payload.sl},
						tp: ${action.payload.tp}) was deleted`

      journalRecord = { dateTime, message }

      return {
        ...state,
        journalRecords: [...state.journalRecords, journalRecord]
      }
    }

    // TODO: get prev and final objects, compare what has changed
    // case ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL:
    // 	dateTime = `${action.payload.createdAt}`
    // 	message = `Order #${action.payload.ticket} was modified from
    // 				(${action.payload.symbol}, ${action.payload.type}, lot: ${action.payload.lots},
    // 				open price: ${action.payload.execPrice}, sl: ${action.payload.sl},
    // 				tp: ${action.payload.tp}) to (${action.payload.symbol}, ${action.payload.type},
    // 				lot: ${action.payload.lots}, open price: ${action.payload.execPrice},
    // 				sl: ${action.payload.sl}, tp: ${action.payload.tp})`

    // 	journalRecord = { dateTime, message }

    // 	return {
    // 		...state,
    // 		journalRecords: [...state.journalRecords, journalRecord]
    // 	}

    // 	case ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL:
    // 	dateTime = `${action.payload.createdAt}`
    // 	message = `Order #${action.payload.ticket} was modified from
    // 				(${action.payload.symbol}, ${action.payload.type}, lot: ${action.payload.lots},
    // 				open price: ${action.payload.execPrice}, sl: ${action.payload.sl},
    // 				tp: ${action.payload.tp}) to (${action.payload.symbol}, ${action.payload.type},
    // 				lot: ${action.payload.lots}, open price: ${action.payload.execPrice},
    // 				sl: ${action.payload.sl}, tp: ${action.payload.tp})`

    // 	journalRecord = { dateTime, message }

    // 	return {
    // 		...state,
    // 		journalRecords: [...state.journalRecords, journalRecord]
    // 	}
    case ADD_START_TEST_TO_JOURNAL: {
      const journalRecord = {
        dateTime: action.payload,
        message: '-----Test started-----'
      }

      return {
        ...state,
        journalRecords: [...state.journalRecords, journalRecord]
      }
    }

    case ADD_PAUSE_TEST_TO_JOURNAL: {
      const journalRecord = {
        dateTime: action.payload,
        message: '-----Test paused-----'
      }

      return {
        ...state,
        journalRecords: [...state.journalRecords, journalRecord]
      }
    }

    default:
      return state
  }
}
