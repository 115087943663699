import { flattenDeep, last } from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import shortid from 'shortid'
import { isDefined, isNotDefined } from '../../libs/react-stockcharts/lib/utils'
import { setCharts, setNotes } from '../../redux/graphs/actions'
import { IJoyrideState } from '../../redux/joyride/types'
import { IModalsState } from '../../redux/modals/types'
import {
  closeAndRemovePendingOrder,
  createMarketOrder,
  InteractiveYCoordinateStyle,
  removeMarketOrder,
  removePendingOrder,
  saveClosedOrder,
  setAccountHistory,
  setGlobalOrderTicket,
  setMarketOrders,
  setOrdersLines,
  setPendingOrders,
  updateOrderMarkerX1,
  updateOrderMarkerX2
} from '../../redux/orders/OrdersSlice'
import { SaveActions } from '../../redux/saves/SaveSlice'
import { setProject } from '../../redux/settings/actions'
import { store } from '../../redux/store/store'
import {
  applySavableWebTester,
  convertToSavableWebTester,
  SavableWebTesterState
} from '../../redux/webTester/webTesterSlice.types'
import getLoadingLimitInSeconds from '../../services/DataServices/DataLoadingLimitsService'
import PreloadDataService from '../../services/DataServices/PreloadDataService'
import { LessonEventType } from '../../services/TelemetryService/telemetryTypes'
import {
  demoDataLimitationMessage,
  dummyProject,
  nonDemoDataLimitationMessage,
  timeFrameOptions
} from '../../utils/constants'
import {
  GraphicToolType,
  LocalStorage,
  MarketOrderTypes,
  OscillatorsRequests,
  PendingOrderTypes,
  ProjectName,
  RunMode,
  TickSize,
  TrendIndicatorsRequests,
  TriggerType,
  Urls,
  VolumeIndicators
} from '../../utils/enums'
import { ALL_DEFAULT_TIMEFRAMES, COMMON_DATE_FORMAT } from '../../utils/helpers/constants'
import { formatDateWithIgnoredLocale, isEmptyArray, isNotEmptyArray } from '../../utils/helpers/functions'
import { IChart, IChartPascalCase, IChartTimeFrame } from '../../utils/interfaces/IChart.interface'
import { ICurrentBar } from '../../utils/interfaces/ICurrentBar'
import { IGraphicTool } from '../../utils/interfaces/IGraphics'
import { IBar, IHistoricalData, ILastBarIndexes, IVisibleData } from '../../utils/interfaces/IHistoricalData.interface'
import { IIndicatorValue } from '../../utils/interfaces/IIndicator.interface'
import { IOrderLine } from '../../utils/interfaces/ILine'
import { INews } from '../../utils/interfaces/INews.interface'
import { INote, INotePascalCase } from '../../utils/interfaces/INote.interface'
import {
  IAccountHistory,
  IAccountInfo,
  IClosedOrder,
  IMarketOrder,
  IMarketOrderPascalCase,
  IOrderMarker,
  IPendingOrder,
  IPendingOrderPascalCase
} from '../../utils/interfaces/IOrder.interface'
import { IProjectPascalCase } from '../../utils/interfaces/IProject.interface'
import { ITutorialStep } from '../../utils/interfaces/ITutorial.interface'
import { HomeID } from '../../utils/locators/locators'
import { basicCourseDemoAccountInfo, basicCourseDemoProject } from '../../utils/projects/basicCoursesProject'
import { defaultAccountInfo, defaultProject } from '../../utils/projects/defaultProject'
import { demoAccountInfo, demoProject } from '../../utils/projects/demoProject'
import { getData } from '../../utils/requests/dataRequests'
import { getIndicator } from '../../utils/requests/indicators/indicatorsRequests'
import { getNews } from '../../utils/requests/newsRequests'
import { createDefaultProject, getProject, getProjects } from '../../utils/requests/projectsRequests'
import { getBaseServerUrl } from '../../utils/requests/utils'
import { findStepIndexByAction, onNextStep } from '../topMenu/tabs/coursesTab/coursesUtils'
import { getMovingAverageMethodIndex, isMovingAverage } from '../topMenu/topMenuUtils/topMenuUtils'
import { WebTesterProps, WebTesterState } from './helpers/interface'
import { WebTester } from './WebTester'
import { handleStartPauseButtonToggle, loadDataFromDateToDate } from './webTesterHandlers'

export const getTimezoneOffsetSeconds = (): number => {
  return new Date().getTimezoneOffset() * -60
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const convertMinutesToTimeFrameIndex = (minutes: number): number => {
  switch (minutes) {
    case 1:
      return 0
    case 5:
      return 1
    case 15:
      return 2
    case 30:
      return 3
    case 60:
      return 4
    case 240:
      return 5
    case 1440:
      return 6
    case 10080:
      return 7
    case 43200:
      return 8
    default:
      return 0
  }
}

export const convertTimeFrameToIndex = (timeFrame: string): number => {
  if (timeFrame.includes('MN')) {
    return 43200
  } else if (timeFrame.includes('W')) {
    return 10080
  } else if (timeFrame.includes('D')) {
    return 1440
  } else if (timeFrame.includes('H')) {
    const hours = getNumbersFromString(timeFrame)
    return hours * 60
  } else if (timeFrame.includes('M')) {
    const minutes = getNumbersFromString(timeFrame)
    return minutes
  } else if (timeFrame.includes('Any')) {
    return 0
  } else return 1
}

export const convertMinutesToTimeFrame = (minutes: number): string => {
  switch (minutes) {
    case 1:
    case 5:
    case 15:
    case 30:
      return `M${minutes}`
    case 60:
    case 240:
      const hours: number = minutes / 60
      return `H${hours}`
    case 1440:
      return 'D1'
    case 10080:
      return 'W1'
    case 43200:
      return 'MN'
    default:
      return 'M1'
  }
}

export const getDistinctSymbols = (charts: IChart[]): string[] => {
  const symbols: string[] = charts.map((chart) => chart.symbol)

  const distinctSymbols = [...Array.from(new Set(symbols))]

  return distinctSymbols
}

export const convertUnixToString = (unixDate: number) => {
  return moment.unix(unixDate).utc().format(COMMON_DATE_FORMAT)
}

export const convertStringDateToUnix = (date: string) => {
  return moment.utc(date, COMMON_DATE_FORMAT).unix()
}

export const getProjectFromLocalStorage = (): IProjectPascalCase => {
  let currentFtProject: IProjectPascalCase = dummyProject

  const stringifiedCurrentFtProject: string = localStorage.getItem(LocalStorage.CurrentFtProject) || '{}'
  currentFtProject = JSON.parse(stringifiedCurrentFtProject)

  return currentFtProject
}

export const initializeProject = async () => {
  const currentFtProject = getProjectFromLocalStorage()

  if (currentFtProject) {
    // normal returning users
    const currentProjectId: string = localStorage.getItem(LocalStorage.CurrentFtProjectId) || ''
    await mapProjectAndSetToRedux(currentProjectId)
  } else {
    // returning users who cleared local storage
    const projectsList = await getProjects()

    if (projectsList && projectsList.length > 0) {
      const lastProjectOnServerId = projectsList[projectsList.length - 1].Id
      await mapProjectAndSetToRedux(lastProjectOnServerId)
    } else {
      // first-time users
      const newProjectId: string = await createDefaultProject()
      await mapProjectAndSetToRedux(newProjectId)
    }
  }
}

export const mapProjectAndSetToRedux = async (projectId: string) => {
  //@ts-ignore
  const currentProject: IProjectPascalCase = await getProject(projectId)
  const stringifiedCurrentFtProject: string = JSON.stringify(currentProject)
  localStorage.setItem(LocalStorage.CurrentFtProject, stringifiedCurrentFtProject)
  localStorage.setItem(LocalStorage.CurrentFtProjectId, projectId)

  let maxOrderTicket: number = 0

  if (currentProject) {
    const marketOrders: IMarketOrder[] = currentProject.MarketOrders
      ? currentProject.MarketOrders.map((marketOrder: IMarketOrderPascalCase) => {
          if (marketOrder.Ticket > maxOrderTicket) {
            maxOrderTicket = marketOrder.Ticket
          }

          return {
            ticket: marketOrder.Ticket,
            symbol: marketOrder.Symbol,
            type: marketOrder.Type === 0 ? MarketOrderTypes.Buy : MarketOrderTypes.Sell,
            lots: marketOrder.Volume,
            openTime: convertUnixToString(marketOrder.OpenTime),
            openPrice: marketOrder.OpenPrice,
            sl: marketOrder.StopLoss,
            tp: marketOrder.TakeProfit,
            marketPrice: marketOrder.MarketPrice,
            comment: marketOrder.Comment,
            swap: marketOrder.Swap,
            commission: marketOrder.Commission,
            points: marketOrder.Points,
            profit: marketOrder.Profit,
            barIndex: marketOrder.BarIndex,
            timeFrame: marketOrder.TimeFrame
          }
        })
      : []

    const pendingOrders: IPendingOrder[] = currentProject.PendingOrders
      ? currentProject.PendingOrders.map((pendingOrder: IPendingOrderPascalCase) => {
          let type: string = ''
          if (pendingOrder.Ticket > maxOrderTicket) {
            maxOrderTicket = pendingOrder.Ticket
          }

          switch (pendingOrder.Type) {
            case 2:
              type = PendingOrderTypes.BuyLimit
              break
            case 3:
              type = PendingOrderTypes.SellLimit
              break
            case 4:
              type = PendingOrderTypes.BuyStop
              break
            case 5:
              type = PendingOrderTypes.SellStop
              break
            default:
              break
          }

          return {
            ticket: pendingOrder.Ticket,
            symbol: pendingOrder.Symbol,
            type,
            lots: pendingOrder.Volume,
            createdAt: convertUnixToString(pendingOrder.CreateTime),
            execPrice: pendingOrder.ExecutionPrice,
            sl: pendingOrder.StopLoss,
            tp: pendingOrder.TakeProfit,
            marketPrice: pendingOrder.MarketPrice,
            comment: pendingOrder.Comment,
            barIndex: pendingOrder.BarIndex
          }
        })
      : []

    const closedOrders: IAccountHistory[] = currentProject.ClosedOrders
      ? currentProject.ClosedOrders.map((closedOrder: IClosedOrder) => {
          if (closedOrder.Ticket > maxOrderTicket) {
            maxOrderTicket = closedOrder.Ticket
          }

          return {
            ticket: closedOrder.Ticket,
            symbol: closedOrder.Symbol,
            type: closedOrder.Type === 0 ? MarketOrderTypes.Buy : MarketOrderTypes.Sell,
            lots: closedOrder.Volume,
            openTime: convertUnixToString(closedOrder.OpenTime),
            openPrice: closedOrder.OpenPrice,
            sl: closedOrder.StopLoss,
            tp: closedOrder.TakeProfit,
            marketPrice: closedOrder.MarketPrice,
            comment: closedOrder.Comment,
            swap: closedOrder.Swap,
            commission: closedOrder.Commission,
            points: closedOrder.Points,
            profit: closedOrder.Profit,
            closePrice: closedOrder.ClosePrice,
            closeTime: convertUnixToString(closedOrder.CloseTime),
            barIndex: closedOrder.BarIndex
          }
        })
      : []

    const charts: IChart[] = currentProject.Charts
      ? currentProject.Charts.map((chart: IChartPascalCase, index) => ({
          symbol: chart.Symbol,
          timeFrame: chart.TimeFrame,
          comment: chart.Comment,
          digitsAfterPoint: chart.Digits,
          id: shortid.generate(),
          index: index + 1
        }))
      : []

    const notes: INote[] = currentProject.Notes
      ? currentProject.Notes.map((note: INotePascalCase) => ({
          title: note.Title,
          text: note.Text,
          dateTime: note.DateTime,
          symbol: note.Symbol
        }))
      : []

    const ordersLines = generateOrdersLines(marketOrders, pendingOrders)

    store.dispatch(setMarketOrders(marketOrders))
    store.dispatch(setPendingOrders(pendingOrders))
    store.dispatch(setAccountHistory(closedOrders))
    store.dispatch(setCharts({ charts: charts })) // !dispatches 3 times
    store.dispatch(setNotes(notes))
    store.dispatch(setGlobalOrderTicket(maxOrderTicket))
    store.dispatch(setOrdersLines(ordersLines))
    // TODO: might need to form a currentProject object in camelCase and set it to redux
    store.dispatch(setProject(currentProject))
  }
}

export const mapProjectForServer = (
  marketOrders: IMarketOrder[],
  pendingOrders: IPendingOrder[],
  closedOrders: IAccountHistory[],
  notes: INote[],
  charts: IChart[]
): [IMarketOrderPascalCase[], IPendingOrderPascalCase[], IClosedOrder[], INotePascalCase[], IChartPascalCase[]] => {
  const MarketOrders: IMarketOrderPascalCase[] = marketOrders.map((marketOrder: IMarketOrder) => {
    return {
      Ticket: marketOrder.ticket,
      Symbol: marketOrder.symbol,
      Type: marketOrder.type === MarketOrderTypes.Sell ? 0 : 1,
      Volume: marketOrder.lots,
      OpenTime: convertStringDateToUnix(marketOrder.openTime), //TODO: potential errors here
      OpenPrice: marketOrder.openPrice,
      StopLoss: marketOrder.sl,
      TakeProfit: marketOrder.tp,
      MarketPrice: marketOrder.marketPrice,
      Comment: marketOrder.comment,
      Swap: marketOrder.swap,
      Commission: marketOrder.commission,
      Points: marketOrder.points,
      Profit: marketOrder.profit,
      BarIndex: marketOrder.barIndex,
      TimeFrame: marketOrder.timeFrame
    }
  })

  const PendingOrders: IPendingOrderPascalCase[] = pendingOrders.map((pendingOrder: IPendingOrder) => {
    let type: number = 0
    switch (pendingOrder.type) {
      case PendingOrderTypes.BuyLimit:
        type = 2
        break
      case PendingOrderTypes.BuyStop:
        type = 3
        break
      case PendingOrderTypes.SellLimit:
        type = 4
        break
      case PendingOrderTypes.SellStop:
        type = 5
        break
      default:
        break
    }
    return {
      Ticket: pendingOrder.ticket,
      Symbol: pendingOrder.symbol,
      Type: type,
      Volume: pendingOrder.lots,
      CreateTime: convertStringDateToUnix(pendingOrder.createdAt), //TODO: potential errors here
      ExecutionPrice: pendingOrder.execPrice,
      StopLoss: pendingOrder.sl,
      TakeProfit: pendingOrder.tp,
      MarketPrice: pendingOrder.marketPrice,
      Comment: pendingOrder.comment,
      BarIndex: pendingOrder.barIndex
    }
  })

  const ClosedOrders: IClosedOrder[] = closedOrders.map((closedOrder: IAccountHistory) => {
    return {
      Ticket: closedOrder.ticket,
      Symbol: closedOrder.symbol,
      Type: closedOrder.type === MarketOrderTypes.Sell ? 0 : 1,
      Volume: closedOrder.lots,
      OpenTime: convertStringDateToUnix(closedOrder.openTime), //TODO: potential errors here
      OpenPrice: closedOrder.openPrice,
      StopLoss: closedOrder.sl,
      TakeProfit: closedOrder.tp,
      MarketPrice: 0, // unnecessary field
      Comment: closedOrder.comment,
      Swap: closedOrder.swap,
      Commission: closedOrder.commission,
      Points: closedOrder.points,
      Profit: closedOrder.profit,
      ClosePrice: closedOrder.closePrice,
      CloseTime: convertStringDateToUnix(closedOrder.closeTime), //TODO: potential errors here
      BarIndex: closedOrder.barIndex
    }
  })

  const Charts: IChartPascalCase[] = charts.map((chart: IChart) => ({
    Symbol: chart.symbol,
    TimeFrame: chart.timeFrame,
    Comment: chart.comment,
    Digits: chart.digitsAfterPoint
    // Id: shortid.generate()
  }))

  const Notes: INotePascalCase[] = notes.map((note: INote) => ({
    Title: note.title,
    Text: note.text,
    DateTime: note.dateTime,
    Symbol: note.symbol
  }))

  return [MarketOrders, PendingOrders, ClosedOrders, Notes, Charts]
}

export const generateOrdersLines = (marketOrders: IMarketOrder[], pendingOrders: IPendingOrder[]): IOrderLine[] => {
  const entryOrders = {
    ...InteractiveYCoordinateStyle(),
    id: shortid(),
    draggable: false,
    stroke: '#1F8B55',
    textFill: '#1F8B55'
  }

  const stopOrders = {
    ...InteractiveYCoordinateStyle(),
    id: shortid(),
    draggable: true,
    stroke: '#D85C5C',
    textFill: '#D85C5C'
  }

  const marketOrdersLines = marketOrders.map(({ ticket, openPrice, type, sl, tp }): IOrderLine[] => {
    return [
      //@ts-ignore
      {
        ...entryOrders,
        text: `#${ticket} ${type}`,
        yValue: openPrice,
        ticketId: ticket,
        lineType: type
      },
      //@ts-ignore
      {
        ...stopOrders,
        text: `#${ticket} stop loss`,
        yValue: sl,
        ticketId: ticket,
        lineType: 'sl'
      },
      //@ts-ignore
      {
        ...stopOrders,
        text: `#${ticket} take profit`,
        yValue: tp,
        ticketId: ticket,
        lineType: 'tp'
      }
    ]
  })

  const pendingOrdersLines = pendingOrders.map(({ ticket, execPrice, type, sl, tp }): IOrderLine[] => {
    return [
      //@ts-ignore
      {
        ...entryOrders,
        text: `#${ticket} ${type}`,
        yValue: execPrice,
        ticketId: ticket,
        lineType: type
      },
      //@ts-ignore
      {
        ...stopOrders,
        text: `#${ticket} stop loss`,
        yValue: sl,
        ticketId: ticket,
        lineType: 'sl'
      },
      //@ts-ignore
      {
        ...stopOrders,
        text: `#${ticket} take profit`,
        yValue: tp,
        ticketId: ticket,
        lineType: 'tp'
      }
    ]
  })

  const flattenedMarketOrdersLines = flattenDeep(marketOrdersLines)
  const flattenedPendingOrdersLines = flattenDeep(pendingOrdersLines)

  return flattenDeep(flattenedMarketOrdersLines.concat(flattenedPendingOrdersLines))
}

export const alertOnProjectSave = () => {
  window.addEventListener('beforeunload', (e) => {
    e.preventDefault()
    return (e.returnValue = 'Do you want to save your project before closing the tab?')
  })
}

export const getNumbersFromString = (str: string): number => {
  return +str.replace(/[^0-9]/g, '')
}

export const trimChartsIndex = (chartName: string): string => {
  let trimmedChartName: string = ''

  for (let i = 0; i < chartName.length; i++) {
    if (chartName.charCodeAt(i) >= 48 && chartName.charCodeAt(i) <= 57) {
      trimmedChartName += ''
    } else {
      trimmedChartName += chartName[i]
    }
  }

  return trimmedChartName
}

export const populateCurrentBars = (visibleData: IHistoricalData): ICurrentBar => {
  const currentBars: ICurrentBar = {}

  for (const symbol of Object.keys(visibleData)) {
    const data = visibleData[symbol]

    if (data.length > 0) {
      currentBars[symbol] = {
        date: data[data.length - 1].date,
        close: data[data.length - 1].close,
        open: data[data.length - 1].open,
        barIndex: data.length - 1
      }
    }
  }

  return currentBars
}

export const filterMarketOrdersTicket = (symbol: string, orders: IMarketOrder[]): number[] => {
  return orders
    .filter(
      (order) => order.symbol === symbol && order.hasOwnProperty('openTime') && new Date(order.openTime).getTime()
    )
    .map((order) => order.ticket)
}

export const filterPendingOrdersTicket = (symbol: string, orders: IPendingOrder[]): number[] => {
  return orders
    .filter(
      (order) => order.symbol === symbol && order.hasOwnProperty('createdAt') && new Date(order.createdAt).getTime()
    )
    .map((order) => order.ticket)
}

export const calculateAccountInfo = (
  balance: number,
  totalProfit: number,
  marketOrders: IMarketOrder[],
  lotSize: number,
  leverage: number,
  currencyMultiplier: number
): number[] => {
  let margin: number = 0
  let marginLevel: number = 0
  marketOrders.map(({ lots, symbol }) => {
    if (leverage !== 0) {
      margin += calculateMargin(symbol, lots, lotSize, leverage, currencyMultiplier)
    }
  })

  const equity: number = Number((balance + totalProfit).toFixed(2))

  const freeMargin: number = Number((equity - margin).toFixed(2))

  if (margin !== 0) {
    marginLevel = Number(((equity / margin) * 100).toFixed(2))
  }

  if (margin === 0) {
    marginLevel = 0
  }

  return [equity, margin, freeMargin, marginLevel]
}

export const calculateMargin = (
  symbol: string,
  lots: number,
  lotSize: number,
  leverage: number,
  currencyMultiplier: number
): number => {
  const margin = Number(((lotSize * lots * currencyMultiplier) / leverage).toFixed(2))

  return margin
}

export const updateBalance = (prevBalance: number, closedOrders: IAccountHistory[]): number => {
  let newBalance: number = prevBalance

  if (closedOrders.length > 0) {
    const order: IAccountHistory = closedOrders[closedOrders.length - 1]
    const profit: number = order.profit
    newBalance = Number((prevBalance + profit).toFixed(2))
  }

  return newBalance
}

export const shouldCloseMarketOrder = (high: number, low: number, order: IMarketOrder): [boolean, string, number] => {
  if (order.sl !== 0) {
    if (order.type === MarketOrderTypes.Sell && high >= order.sl) {
      return [true, 'sl', order.sl]
    } else if (order.type === MarketOrderTypes.Buy && low <= order.sl) {
      return [true, 'sl', order.sl]
    }
  }

  if (order.tp !== 0) {
    if (order.type === MarketOrderTypes.Sell && low <= order.tp) {
      return [true, 'tp', order.tp]
    } else if (order.type === MarketOrderTypes.Buy && high >= order.tp) {
      return [true, 'tp', order.tp]
    }
  }

  return [false, '', 0]
}

export const shouldClosePendingOrder = (close: number, order: IPendingOrder): boolean => {
  if (order.type === PendingOrderTypes.BuyLimit || order.type === PendingOrderTypes.SellStop) {
    return close <= order.execPrice
  }

  if (order.type === PendingOrderTypes.BuyStop || order.type === PendingOrderTypes.SellLimit) {
    return close >= order.execPrice
  }

  return false
}

export const handleClosePendingOrder = (
  order: IPendingOrder,
  close: number,
  date: string,
  barIndex: number,
  timeframe: number,
  openPrice?: number
) => {
  store.dispatch(removePendingOrder(order.ticket))

  const type = order.type.toLowerCase().includes(MarketOrderTypes.Sell) ? MarketOrderTypes.Sell : MarketOrderTypes.Buy

  const ticket = store.getState().orders.globalOrderTicket

  const openTime = moment(date).utcOffset(0, false).format(COMMON_DATE_FORMAT)

  const newMarketOrder: IMarketOrder = {
    ticket,
    type,
    openTime,
    symbol: order.symbol,
    points: 0,
    profit: 0,
    swap: 0,
    commission: 0,
    comment: order.comment,
    lots: order.lots,
    sl: order.sl,
    tp: order.tp,
    marketPrice: order.marketPrice,
    openPrice: openPrice ? openPrice : order.execPrice,
    barIndex: barIndex,
    timeFrame: timeframe
  }

  store.dispatch(createMarketOrder({ order: newMarketOrder, timeframe }))
}

export const handleCloseMarketOrder = (
  order: IMarketOrder,
  date: string,
  price: number,
  barIndex: number,
  timeframe: number,
  charts: IChart[],
  visibleData: IVisibleData
) => {
  const [points, profit] = calculateProfitCellsForMarketOrder(price, order)

  const closeTime = moment(date).utc().format(COMMON_DATE_FORMAT)

  const closedOrderData: IAccountHistory = {
    ...order,
    barIndex: barIndex,
    closePrice: price,
    closeTime,
    profit,
    points
  }

  const addMarkersOnEachTimeFrame = (orderMarker: IOrderMarker) => {
    charts.map((chart) => {
      if (chart.symbol === order.symbol && chart.timeFrame !== timeframe) {
        const foundBarIndex = findBarIndex(
          visibleData[chart.symbol + chart.timeFrame],
          timeframe,
          chart.timeFrame,
          orderMarker.x2[orderMarker.symbol + timeframe],
          closedOrderData.closeTime
        )

        orderMarker.x2[chart.symbol + chart.timeFrame] = foundBarIndex
      }
    })
  }

  store.dispatch(
    saveClosedOrder({ closedOrder: closedOrderData, timeframe, updateX2Action: addMarkersOnEachTimeFrame })
  )
  store.dispatch(removeMarketOrder(order.ticket))
}

export const calculateProfitCellsForMarketOrder = (close: number, order: IMarketOrder): [number, number] => {
  // The minimal range is 1 minute.
  const open: number = order.openPrice
  const digitsAfterPoint: number = order.symbol && order.symbol.includes('JPY') ? 3 : 5
  const multiplier: number = Math.pow(10, digitsAfterPoint)

  let point: number
  let profit: number

  if (order.type === 'sell') {
    point = Number(((open - close) * multiplier).toFixed(0))
  } else if (order.type === 'buy') {
    point = Number(((close - open) * multiplier).toFixed(0))
  } else point = 0

  profit = Number((order.lots * point - order.commission).toFixed(2))

  return [point, profit]
}

export const diffDurationInMinutes = (first: string, last: string): number => {
  const fromMoment = moment.utc(first)
  const toMoment = moment.utc(last)

  const diff = fromMoment.diff(toMoment, 'minutes')

  const diffDuration = moment.duration(diff, 'minutes')

  return diffDuration.minutes() + diffDuration.hours() * 60 + diffDuration.days() * 24 * 60
}

export const checkClosePendingOrders = (
  bars: IBar[],
  symbol: string,
  timeframe: number,
  orders: IPendingOrder[],
  barIndex: number,
  webTester: WebTester
) => {
  const lastBar =
    webTester.state.visibleData[symbol + timeframe][webTester.state.visibleData[symbol + timeframe].length - 1]

  for (const order of orders) {
    if (order.symbol === symbol) {
      const shouldClose = shouldClosePendingOrder(lastBar.close, order)

      if (shouldClose) {
        handleClosePendingOrder(order, lastBar.close, lastBar.date, barIndex, timeframe)
        const {
          joyride: { step, originalTutorial, guideSteps, guideIsOn }
        } = webTester.props

        if (guideIsOn) {
          const currentStep: ITutorialStep = originalTutorial.Steps[step]

          if (
            currentStep.StepInfo.StepActions &&
            currentStep.StepInfo.StepActions.find((a) => a.Type === TriggerType.WAIT_OPEN_PENDING_ORDER)
          ) {
            handleStartPauseButtonToggle(webTester)
            const nextStep = step + 1
            onNextStep(webTester, originalTutorial.Steps, nextStep, guideSteps)
          }
        }

        break
      }
    }
  }
}

export const checkCloseMarketOrders = (
  visibleData: IVisibleData,
  symbol: string,
  timeframe: number,
  orders: IMarketOrder[],
  charts: IChart[]
) => {
  for (const order of orders) {
    if (order.symbol === symbol) {
      const lastBarIndex = visibleData[symbol + timeframe].length - 1
      const lastBar = visibleData[symbol + timeframe][lastBarIndex]

      const [shouldClose, type, closePrice] = shouldCloseMarketOrder(lastBar.high, lastBar.low, order)

      if (shouldClose) {
        handleCloseMarketOrder(order, lastBar.date, closePrice, lastBarIndex, timeframe, charts, visibleData)
      }
    }
  }
}

export const calculateOperationsRelatedToMarketOrders = (
  currentBars: ICurrentBar,
  orders: IMarketOrder[]
): [number[], number[], number] => {
  const pointsBySymbol: number[] = []
  const profitBySymbol: number[] = []

  let profitsSum: number = 0
  const keys: string[] = Object.keys(currentBars)
  let symbol: string = ''
  let timeFrame: number = 1

  for (let i = 0; i < orders.length; i++) {
    //! TODO: O(n^2) -> fine for demo but very bad for final version
    keys.some((chartTimeFrame) => {
      symbol = trimChartsIndex(chartTimeFrame)
      const tf = getNumbersFromString(chartTimeFrame)
      if (symbol === orders[i].symbol) {
        timeFrame = tf
        return
      }
    })

    const close = currentBars[orders[i].symbol + timeFrame].close
    const [point, profit] = calculateProfitCellsForMarketOrder(close, orders[i])
    profitsSum += profit
    pointsBySymbol.push(point)
    profitBySymbol.push(profit)
  }

  profitsSum = Number(profitsSum.toFixed(2))

  return [pointsBySymbol, profitBySymbol, profitsSum]
}

export const getDistinctCurrencies = (charts: IChart[]): string[] => {
  let allCurrencies: string[] = []

  for (let i = 0; i < charts.length; i++) {
    const firstPart: string = charts[i].symbol.substring(0, 3)
    const secondPart: string = charts[i].symbol.substring(3)

    allCurrencies.push(firstPart, secondPart)
  }

  const distinctCurrencies: string[] = [...Array.from(new Set(allCurrencies))]

  return distinctCurrencies
}

export const deleteBarsFromVisibleData = (
  visibleData: IHistoricalData,
  chartTimeFrame: string,
  newLastBarIndex: number
): IHistoricalData => {
  visibleData[chartTimeFrame].splice(newLastBarIndex + 1, visibleData[chartTimeFrame].length)

  return visibleData
}

export const addBarsToVisibleData = (
  visibleData: IHistoricalData,
  entireData: IHistoricalData,
  chartTimeFrame: string,
  lastBarIndex: number,
  newLastBarIndex: number
) => {
  const newBars = entireData[chartTimeFrame].slice(lastBarIndex + 1, newLastBarIndex + 1)

  visibleData[chartTimeFrame].push(...newBars)
}

export const getBarsDiffMinutes = (first: IBar, last: IBar): number => {
  const diff = moment(first.date).diff(moment(last.date))
  const diffDuration = moment.duration(diff)
  return diffDuration.minutes() + diffDuration.hours() * 60 + diffDuration.days() * 24 * 60
}

export const findCorrectBarBinarySearch = (first: number, last: number, eData: IBar[], currentBar: IBar): number => {
  /**
   It finds the index of the bar, which is closest to the current bar by time.
   It finds in eData range [first, last].

   This method needed, when we have data gaps.
   */
  let middleIndex = Math.trunc((last - first) / 2) + first

  while (first <= last) {
    const diffMinutes = getBarsDiffMinutes(currentBar, eData[middleIndex])

    if (diffMinutes > 0) {
      first = middleIndex + 1
    } else if (diffMinutes < 0) {
      last = middleIndex - 1
    } else {
      return middleIndex
    }
    middleIndex = Math.trunc((last - first) / 2) + first
  }

  return middleIndex
}

export const deleteCorrectNumberOfBars = (
  currentLastBar: IBar,
  numberOfBarsToDelete: number,
  barIndex: number,
  eData: IBar[]
): number => {
  if (eData[barIndex - numberOfBarsToDelete]) {
    // find closest bar index
    const correctBarIndex = findCorrectBarBinarySearch(barIndex - numberOfBarsToDelete, barIndex, eData, currentLastBar)
    // return number of bars, that we should to remove
    return barIndex - correctBarIndex
  } else {
    return 0
  }
}

export const getMinDateBar = (
  visibleData: IHistoricalData,
  symbol: string,
  tickSize: number,
  lastBarIndexes: ILastBarIndexes
): IBar | null => {
  if (isDefined(visibleData[symbol + tickSize])) {
    return visibleData[symbol + tickSize][visibleData[symbol + tickSize].length - 1]
  }

  const lastVisibleDataBars: IBar[] = []

  for (const chartTimeFrame of Object.keys(lastBarIndexes)) {
    const lastBarIndex: number = lastBarIndexes[chartTimeFrame]
    if (visibleData[chartTimeFrame]) {
      lastVisibleDataBars.push(visibleData[chartTimeFrame][lastBarIndex])
    }
  }

  lastVisibleDataBars.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  })

  return lastVisibleDataBars[0] || null
}

export const checkIfAlphaNumeric = (keyCode: number): boolean => {
  const numericCodes: number[] = [48, 57]
  const letterCodes: number[] = [65, 90]
  const numPadCodes: number[] = [96, 105]

  return (
    (keyCode >= numericCodes[0] && keyCode <= numericCodes[1]) ||
    (keyCode >= letterCodes[0] && keyCode <= letterCodes[1]) ||
    (keyCode >= numPadCodes[0] && keyCode <= numPadCodes[1])
  )
}

export const isModalOpened = (modals: IModalsState): boolean => {
  const modalsState: boolean | { IsOpen: boolean; type: string }[] = Object.values(modals)
  return modalsState.some((value) => {
    if (typeof value === 'object') return value.IsOpen

    return value
  })
}

export function updateMarkersDataOnTimeframeChange(
  visibleData: IBar[],
  orderMarkers: IOrderMarker[],
  timeframe: number,
  prevTimeframe: number,
  symbol: string
) {
  orderMarkers.forEach((orderMarker, markerIndex) => {
    if (orderMarker.symbol !== symbol) return

    if (!isDefined(orderMarker.x1[orderMarker.symbol + timeframe])) {
      const foundBarIndex = findBarIndex(
        visibleData,
        prevTimeframe,
        timeframe,
        orderMarker.x1[orderMarker.symbol + prevTimeframe],
        orderMarker.openTime
      )

      if (isDefined(foundBarIndex)) {
        store.dispatch(
          updateOrderMarkerX1({ markerIndex, barIndex: foundBarIndex, timeframe, symbol: orderMarker.symbol })
        )
      }
    }

    if (!isDefined(orderMarker.x2[orderMarker.symbol + timeframe]) && isDefined(orderMarker.closeTime)) {
      const foundBarIndex = findBarIndex(
        visibleData,
        prevTimeframe,
        timeframe,
        orderMarker.x2[orderMarker.symbol + prevTimeframe],
        orderMarker.closeTime
      )

      if (isDefined(foundBarIndex)) {
        store.dispatch(
          updateOrderMarkerX2({ markerIndex, barIndex: foundBarIndex, timeframe, symbol: orderMarker.symbol })
        )
      }
    }
  })
}

export function findBarIndex(
  bars: IBar[],
  prevTimeframe: number,
  timeframe: number,
  barIndex: number,
  time: string | undefined
): number {
  const coff = getTimeframeCoefficient(prevTimeframe, timeframe)
  let foundBarIndex = Math.floor(barIndex * coff)
  if (foundBarIndex >= bars.length) {
    foundBarIndex = bars.length - 1
  }

  let diff = diffDurationInMinutes(time!, moment.utc(bars[foundBarIndex].date).format(COMMON_DATE_FORMAT))

  if (diff < 0) {
    while (foundBarIndex > 0) {
      const bar = bars[foundBarIndex]

      if (isDefined(bar)) {
        const barTime = moment.utc(bar.date).format(COMMON_DATE_FORMAT)

        diff = diffDurationInMinutes(time!, barTime)
        if (diff >= 0) {
          return foundBarIndex
        }

        foundBarIndex--
      }
    }
  } else if (diff > 0) {
    const searchEnd = bars.length - 1
    while (foundBarIndex < searchEnd) {
      const bar = bars[foundBarIndex]

      if (isDefined(bar)) {
        const barTime = moment.utc(bar.date).format(COMMON_DATE_FORMAT)

        diff = diffDurationInMinutes(time!, barTime)
        if (diff <= 0) {
          if (diff === 0) return foundBarIndex

          if (prevTimeframe < timeframe) return --foundBarIndex

          return foundBarIndex
        }

        foundBarIndex++
      }
    }
  }

  return foundBarIndex
}

export function getTimeframeCoefficient(prevTimeframe: number, timeframe: number): number {
  return prevTimeframe / timeframe
}

export function getChartTimeFrames(charts: IChart[]): IChartTimeFrame[] {
  let chartTimeFrames: IChartTimeFrame[] = []
  charts.map((chart) => {
    chartTimeFrames.push(
      ...timeFrameOptions.map((option) => ({
        symbol: chart.symbol,
        timeFrame: option.value
      }))
    )
  })

  return chartTimeFrames
}

export async function getNewsRecords(charts: IChart[], start, end): Promise<INews[]> {
  let newsRecords: INews[] = []

  const distinctCurrencies: string[] = getDistinctCurrencies(charts)

  for (let i = 0; i < distinctCurrencies.length; i++) {
    const newsOnSymbol = await getNews(distinctCurrencies[i], start, end)
    newsRecords.push(...newsOnSymbol)
  }

  return newsRecords
}

export function isThereVisibleData(visibleData: IBar[] | undefined) {
  return !visibleData || visibleData[0].date === ''
}

export async function downloadIndicatorAndMap(
  webTester: WebTester,
  start: number,
  end: number,
  bars: IBar[],
  symbol: string,
  timeFrame: number,
  chartId: string,
  useOffset: boolean = true
) {
  let indicatorData: IIndicatorValue[] = []

  const { indicators, oscillators } = webTester.props.graphics

  const { visibleData } = webTester.state
  const enableOffset = useOffset && isThereVisibleData(visibleData[symbol + timeFrame])

  if (indicators[chartId] && indicators[chartId].length > 0) {
    await Promise.all(
      indicators[chartId].map(async ({ name, settings }) => {
        let trimmedName = trimChartsIndex(name)
        let settingsToLoad = { ...settings }

        if (isMovingAverage(trimmedName)) {
          const method: number = getMovingAverageMethodIndex(name)
          trimmedName = 'ma'
          settingsToLoad.Method = method
        }

        const period = settings.Period ?? 0
        const index = enableOffset ? period : 0
        const indicatorOffsetInSeconds = period * timeFrame * 60
        indicatorData = await getIndicator(
          trimmedName,
          symbol,
          start - (enableOffset ? 0 : indicatorOffsetInSeconds),
          end,
          timeFrame,
          true,
          settingsToLoad
        )

        mapIndicatorDataIntoBars(bars, index, name, indicatorData, enableOffset)
      })
    )
  }

  if (oscillators[chartId] && oscillators[chartId].length > 0) {
    await Promise.all(
      oscillators[chartId].map(async ({ name, settings }) => {
        const trimmedName = trimChartsIndex(name)

        if (name !== VolumeIndicators.Volume) {
          const period = getNumbersFromString(name) ?? settings.Period ?? 0

          const index = enableOffset ? period : 0
          const indicatorOffsetInSeconds = period * timeFrame * 60
          indicatorData = await getIndicator(
            trimmedName,
            symbol,
            start - (enableOffset ? 0 : indicatorOffsetInSeconds),
            end + indicatorOffsetInSeconds,
            timeFrame,
            true,
            settings
          )

          mapIndicatorDataIntoBars(bars, index, name, indicatorData, enableOffset)
        }
      })
    )
  }
}

export function mapIndicatorDataIntoBars(
  bars: IBar[],
  indexOffset: number,
  name: string,
  indicatorData: IIndicatorValue[],
  thereIsNoVisibleData: boolean
) {
  let dateOffset = 0
  bars.forEach((bar, i) => {
    if (thereIsNoVisibleData && i < indexOffset) {
      bar[name] = undefined
      return
    }
    if (indicatorData[i - indexOffset - dateOffset]) {
      const barDateUnix = moment.utc(bar.date).unix()
      const indicatorDate = indicatorData[i - indexOffset - dateOffset].t
      if (indicatorDate && barDateUnix !== indicatorDate) {
        if (barDateUnix > indicatorDate) {
          for (const indicatorBar of indicatorData) {
            dateOffset--
            if (barDateUnix === indicatorBar.t) {
              bar[name] = getIndicatorValueForBar(indicatorData, i, indexOffset + dateOffset, name)
              break
            }
          }
          return
        }

        bar[name] = undefined
        console.warn(`There is no indicator data for indicator ${name} bar date ${bar.date}`)
        dateOffset++
        return
      }

      bar[name] = getIndicatorValueForBar(indicatorData, i, indexOffset + dateOffset, name)
    } else {
      bar[name] = undefined
      console.warn(`There is no indicator data for indicator ${name} bar date ${bar.date}`)
    }
  })
}

export function isElementInDom(step, noHashtagTargetId: string) {
  return step.target !== 'body' ? !!document.getElementById(noHashtagTargetId) : true
}

export function getNoHashtagTargetId(step) {
  if (isNotDefined(step)) return ''

  return step.target !== 'body' ? step.target.substring(1) : step.target
}

export function finishTutorial(webTester: WebTester) {
  if (webTester.props.joyride.guideIsOn) {
    webTester.telemetryService?.sendLessonEvent(
      webTester.props.joyride,
      webTester.props.language,
      LessonEventType.LESSON_CANCELED
    )
  }

  webTester.props.finishLesson()
  webTester.props.hideAllModals()
}

export function updateStepOrFinishCourse(data: any, webTester: WebTester) {
  const { stepActionType } = webTester.props.joyride

  if (isDefined(stepActionType)) {
    const {
      originalTutorial: { Steps },
      step,
      guideSteps
    } = webTester.props.joyride
    const newStep = Steps[step]

    if (isNotDefined(newStep)) {
      toast.error(`Step ${newStep.Name} is broken`)
      return
    }

    webTester.props.setStepActionType(undefined)
  }
}

export default function isThereNextStep(
  joyride: IJoyrideState,
  targetId: string,
  currentCourseStepElementId: string
): boolean {
  if (isNotClickableTargetId(targetId)) return false

  const { step, guideSteps } = joyride

  const isNotLastStep: boolean = guideSteps.length > step

  return targetId === currentCourseStepElementId && isNotLastStep
}

export function isWaitingModalOpen(target: string) {
  return [HomeID.MarketOrderID.valueOf(), HomeID.PendingOrderID.valueOf()].includes(target)
}

export function isNotClickableTargetId(targetId: string): boolean {
  return [
    HomeID.TestingSpeedSliderID.valueOf(),
    HomeID.GraphicElementsGroupID.valueOf(),
    HomeID.ListOfIndicatorsGroupID.valueOf()
  ].includes(targetId)
}

export function clearOrders(symbol: string, webTesterProps: WebTesterProps) {
  filterMarketOrdersTicket(symbol, webTesterProps.orders.market).map((ticket: number) =>
    webTesterProps.removeMarketOrder(ticket)
  )
  filterPendingOrdersTicket(symbol, webTesterProps.orders.pending).map((ticket: number) =>
    webTesterProps.removePendingOrder(ticket)
  )
}

export function deleteBarForOtherCharts(
  webTesterProps: WebTesterProps,
  webTesterState: WebTesterState,
  visibleData: IVisibleData,
  currentLastBar: IBar,
  lastBarIndexes: ILastBarIndexes
): IVisibleData {
  for (const chart of webTesterState.chartTimeFrames) {
    const chartTimeFrame = chart.symbol + chart.timeFrame
    const symbol = chart.symbol
    const timeFrame = chart.timeFrame

    if (!visibleData.hasOwnProperty(chartTimeFrame)) continue

    if (visibleData[chartTimeFrame].length > 0) {
      const lastBar = visibleData[chartTimeFrame][visibleData[chartTimeFrame].length - 1]

      const diffMinutes = getBarsDiffMinutes(lastBar, currentLastBar)
      let numberOfBarsToDelete = Math.trunc(diffMinutes / +timeFrame)

      numberOfBarsToDelete = deleteCorrectNumberOfBars(
        currentLastBar,
        numberOfBarsToDelete,
        lastBarIndexes[chartTimeFrame],
        visibleData[chartTimeFrame]
      )

      if (numberOfBarsToDelete >= 1) {
        visibleData = deleteBarsFromVisibleData(
          visibleData,
          chartTimeFrame,
          lastBarIndexes[chartTimeFrame] - numberOfBarsToDelete
        )
        lastBarIndexes[chartTimeFrame] -= numberOfBarsToDelete

        // Check if we need to delete orders on step back
        // ! Equity changes even if step back to the bar before the order open
        clearOrders(symbol, webTesterProps)
      }
    }
  }
  return visibleData
}

export function deleteOneBar(
  webTesterProps: WebTesterProps,
  webTesterState: WebTesterState,
  visibleData: IVisibleData,
  currentChartTimeFrame: string,
  lastBarIndexes: ILastBarIndexes
): IVisibleData {
  if (visibleData[currentChartTimeFrame][lastBarIndexes[currentChartTimeFrame] - 1]) {
    visibleData = deleteBarsFromVisibleData(
      visibleData,
      currentChartTimeFrame,
      lastBarIndexes[currentChartTimeFrame] - 1
    )
    lastBarIndexes[currentChartTimeFrame] -= 1

    const currentLastBar = visibleData[currentChartTimeFrame][lastBarIndexes[currentChartTimeFrame]]

    visibleData = deleteBarForOtherCharts(webTesterProps, webTesterState, visibleData, currentLastBar, lastBarIndexes)
  }

  return visibleData
}

export function isNotStepAvailable(
  visibleData: IVisibleData,
  currentChartTimeFrame: string,
  lastBarIndexes: ILastBarIndexes,
  forwardTestingOnly: boolean
) {
  if (forwardTestingOnly) return true

  if (lastBarIndexes[currentChartTimeFrame] && lastBarIndexes[currentChartTimeFrame] <= 1) {
    return true
  }

  //TODO if we don't load current time frame yet
  if (!visibleData.hasOwnProperty(currentChartTimeFrame)) return true

  return false
}

export function isEndOfDataSet(visibleData: IVisibleData, currentChartTimeFrame: string, entireData: IHistoricalData) {
  return visibleData[currentChartTimeFrame].length === entireData[currentChartTimeFrame].length
}

export function isEndOfProject(
  endDateUnix: number,
  lastBarDate: string,
  timeFrame: number,
  tickLastBarDate: string,
  tickSize: number
) {
  const lastBarDateUnix = convertStringDateToUnix(lastBarDate)
  if (endDateUnix <= lastBarDateUnix + timeFrame * 60) return true

  const tickLastBarUnix = convertStringDateToUnix(tickLastBarDate)
  if (endDateUnix <= tickLastBarUnix + tickSize * 60) return true

  return false
}

export function restoreTickedBarClosePrice(
  visibleData: IVisibleData,
  chartTimeFrame: string,
  numberOfBarsToAdd: number
) {
  visibleData[chartTimeFrame][visibleData[chartTimeFrame].length - numberOfBarsToAdd - 1].close =
    visibleData[chartTimeFrame][visibleData[chartTimeFrame].length - numberOfBarsToAdd].open
}

export function syncCharts(
  webTesterState: WebTesterState,
  webTesterProps: WebTesterProps,
  currentChartTimeFrame: string,
  currentTimeFrame: number,
  entireData: IHistoricalData,
  visibleData: IVisibleData,
  currentLastBar: IBar,
  lastBarIndexes: ILastBarIndexes,
  currentChartSymbol: string
) {
  for (const chart of webTesterState.chartTimeFrames) {
    const chartTimeFrame = chart.symbol + chart.timeFrame
    const timeFrame = chart.timeFrame

    if (chartTimeFrame === currentChartTimeFrame) continue
    if (!entireData.hasOwnProperty(chartTimeFrame)) continue

    if (entireData[chartTimeFrame].length > 0) {
      const newBarIndex = findBarIndex(
        entireData[chartTimeFrame],
        currentTimeFrame,
        timeFrame,
        lastBarIndexes[currentChartTimeFrame],
        currentLastBar.date
      )

      const numberOfBarsToAdd = newBarIndex - lastBarIndexes[chartTimeFrame]

      if (numberOfBarsToAdd >= 1) {
        addBarsToVisibleData(
          visibleData,
          entireData,
          chartTimeFrame,
          lastBarIndexes[chartTimeFrame],
          lastBarIndexes[chartTimeFrame] + numberOfBarsToAdd
        )

        restoreTickedBarClosePrice(visibleData, chartTimeFrame, numberOfBarsToAdd)

        lastBarIndexes[chartTimeFrame] += numberOfBarsToAdd
      } else if (chart.symbol === currentChartSymbol) {
        if (currentTimeFrame < timeFrame) {
          visibleData[chartTimeFrame][visibleData[chartTimeFrame].length - 1].close =
            visibleData[currentChartTimeFrame][visibleData[currentChartTimeFrame].length - 1].close
        }
      }
    }
  }
}

export function addOneBar(
  webTester: WebTester,
  visibleData: IVisibleData,
  entireData: IHistoricalData,
  currentChartTimeFrame: string,
  currentTimeFrame: number,
  lastBarIndexes: ILastBarIndexes,
  currentChart: IChart
): IVisibleData {
  addBarsToVisibleData(
    visibleData,
    entireData,
    currentChartTimeFrame,
    lastBarIndexes[currentChartTimeFrame] - 1,
    lastBarIndexes[currentChartTimeFrame]
  )

  checkClosePendingOrders(
    visibleData[currentChartTimeFrame],
    currentChart.symbol,
    currentChart.timeFrame,
    webTester.props.orders.pending,
    lastBarIndexes[currentChartTimeFrame],
    webTester
  )
  checkCloseMarketOrders(
    visibleData,
    currentChart.symbol,
    currentChart.timeFrame,
    webTester.props.orders.market,
    webTester.props.graphs.charts
  )

  const currentLastBar = visibleData[currentChartTimeFrame][lastBarIndexes[currentChartTimeFrame]]

  syncCharts(
    webTester.state,
    webTester.props,
    currentChartTimeFrame,
    currentTimeFrame,
    entireData,
    visibleData,
    currentLastBar,
    lastBarIndexes,
    currentChart.symbol
  )

  return visibleData
}

export function demoLimitationsToast() {
  if (getBaseServerUrl() !== Urls.Beta) {
    toast.info(demoDataLimitationMessage)
  } else {
    toast.info(nonDemoDataLimitationMessage)
  }
}

export async function downloadData(
  webTesterState: WebTesterState,
  entireData: IHistoricalData,
  middleRange: number,
  newMiddleRange: number
) {
  for (const chart of webTesterState.chartTimeFrames) {
    const chartTimeFrame = chart.symbol + chart.timeFrame

    if (!entireData.hasOwnProperty(chartTimeFrame)) continue

    const additionalData = await getData(chart.symbol, 'Alpari', middleRange, newMiddleRange, chart.timeFrame, true)

    entireData = {
      ...entireData,
      [chartTimeFrame]: [...entireData[chartTimeFrame], ...additionalData]
    }
  }

  return entireData
}

// TODO: need to write tests, when loading data will be worked
export function calcNewMiddleRange(middleRange: number, timeFrame: number, dateRange: number[]) {
  const timeFrameSec = timeFrame * 60

  let newMiddleRange = middleRange + 100 * timeFrameSec

  newMiddleRange = newMiddleRange >= dateRange[1] ? dateRange[1] : newMiddleRange

  return newMiddleRange
}

function getIndicatorValueForBar(
  indicatorDataArray: IIndicatorValue[],
  i: number,
  indexOffset: number,
  indicatorNameField: string
) {
  function getValue() {
    switch (indicatorNameField) {
      case OscillatorsRequests.Stochastic:
        return {
          K: indicatorDataArray[i - indexOffset].m!,
          D: indicatorDataArray[i - indexOffset].s!
        }
      case OscillatorsRequests.MovingAverageConvergenceDivergence:
        return {
          macd: indicatorDataArray[i - indexOffset].m!,
          signal: indicatorDataArray[i - indexOffset].s!
        }
      case TrendIndicatorsRequests.Envelopes:
        return {
          L: indicatorDataArray[i - indexOffset].l!,
          U: indicatorDataArray[i - indexOffset].u!
        }
      case TrendIndicatorsRequests.BollingerBands:
        return {
          bottom: indicatorDataArray[i - indexOffset].l!,
          middle: indicatorDataArray[i - indexOffset].m!,
          top: indicatorDataArray[i - indexOffset].u!
        }
      default:
        return indicatorDataArray[i - indexOffset].v!
    }
  }

  return indicatorDataArray[i - indexOffset] && i >= indexOffset ? getValue() : undefined
}

export function getNewVisibleDataWithIndicatorField(
  oldVisibleData: IBar[],
  indicatorNameField: string,
  indicatorDataArray: IIndicatorValue[],
  index: number
): IBar[] {
  return oldVisibleData.map((bar, i) => ({
    ...bar,
    [indicatorNameField]: getIndicatorValueForBar(indicatorDataArray, i, index, indicatorNameField)
  }))
}

export function getNewEntireDataWithIndicatorField(
  oldEntireData: IBar[],
  indicatorNameField: string,
  indicatorDataArray: IIndicatorValue[],
  index: number
) {
  return oldEntireData.map((bar, i) => ({
    ...bar,
    [indicatorNameField]: getIndicatorValueForBar(indicatorDataArray, i, index, indicatorNameField)
  }))
}

export function getNewVisibleDataAndBarIndex(
  chartTimeFrames: IChartTimeFrame[],
  date: string,
  entireData: IHistoricalData,
  visibleData: IVisibleData,
  lastBarIndexes: ILastBarIndexes,
  currentBars: ICurrentBar
) {
  const searchKeyBar: IBar = {
    close: 0,
    date: moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss'),
    high: 0,
    low: 0,
    open: 0,
    volume: 0
  }

  for (const chart of chartTimeFrames) {
    const dataKey = chart.symbol + chart.timeFrame
    const barIndex = findCorrectBarBinarySearch(0, entireData[dataKey].length, entireData[dataKey], searchKeyBar)

    visibleData[dataKey] = entireData[dataKey].slice(0, barIndex)
    lastBarIndexes[dataKey] = barIndex - 1
    currentBars[dataKey] = visibleData[dataKey][barIndex - 1]
  }
}

export function addGraphicToolToChart(
  webTester: WebTester,
  tool: IGraphicTool,
  timeFrame: number,
  symbol: string,
  graphicToolType: GraphicToolType
) {
  webTester.props.deselectAllObjects()
  ALL_DEFAULT_TIMEFRAMES.map((tF) => {
    if (tF === timeFrame) {
      webTester.props.pushTool({ symbol, timeframe: tF, tool, graphicToolType })
    } else {
      const modifiedTool = modifyToolForTimeFrame(tool, timeFrame, tF, graphicToolType)
      webTester.props.pushTool({ symbol, timeframe: tF, tool: modifiedTool, graphicToolType })
    }
  })
}

export function modifyToolForTimeFrame(
  tool,
  prevTimeFrame: number,
  timeFrame: number,
  graphicToolType: GraphicToolType
) {
  const coff = getTimeframeCoefficient(prevTimeFrame, timeFrame)

  switch (graphicToolType) {
    case GraphicToolType.RETRACEMENT:
      return {
        ...tool,
        x1: Math.ceil(tool.x1 * coff),
        x2: Math.ceil(tool.x2 * coff)
      }
    case GraphicToolType.GANN_FAN:
      return {
        ...tool,
        startXY: [Math.ceil(tool.startXY[0] * coff), tool.startXY[1]],
        endXY: [Math.ceil(tool.endXY[0] * coff), tool.endXY[1]]
      }
      break
    case GraphicToolType.EQUIDISTANT_CHANNEL:
      return {
        ...tool,
        startXY: [Math.ceil(tool.startXY[0] * coff), tool.startXY[1]],
        endXY: [Math.ceil(tool.endXY[0] * coff), tool.endXY[1]]
      }
    case GraphicToolType.STANDARD_DEVIATION_CHANNEL:
      return {
        ...tool,
        start: [Math.ceil(tool.start[0] * coff), tool.start[1]],
        end: [Math.ceil(tool.end[0] * coff), tool.end[1]]
      }
    case GraphicToolType.RECTANGLE:
      return {
        ...tool,
        start: [Math.ceil(tool.start[0] * coff), tool.start[1]],
        end: [Math.ceil(tool.end[0] * coff), tool.end[1]]
      }
    case GraphicToolType.ELLIPSE:
      return {
        ...tool,
        r1Point1: [Math.ceil(tool.r1Point1.x * coff), tool.r1Point1.y],
        r1Point2: [Math.ceil(tool.r1Point2.x * coff), tool.r1Point2.y],
        r2Point1: [Math.ceil(tool.r2Point1.x * coff), tool.r2Point1.y],
        r2Point2: [Math.ceil(tool.r2Point2.x * coff), tool.r2Point2.y],
        center: [Math.ceil(tool.center.x * coff), tool.center.y]
      }
    case GraphicToolType.TRIANGLE:
      return tool
    case GraphicToolType.TEXT:
      return {
        ...tool,
        position: [Math.ceil(tool.position[0] * coff), tool.position[1]]
      }
    case GraphicToolType.RAY:
      return {
        ...tool,
        start: [Math.ceil(tool.start[0] * coff), tool.start[1]],
        end: [Math.ceil(tool.end[0] * coff), tool.end[1]]
      }
    case GraphicToolType.TREND:
      return {
        ...tool,
        start: [Math.ceil(tool.start[0] * coff), tool.start[1]],
        end: [Math.ceil(tool.end[0] * coff), tool.end[1]]
      }
    case GraphicToolType.VERTICAL_LINE:
      return {
        ...tool,
        xPos: Math.ceil(tool.xPos[0] * coff)
      }
    case GraphicToolType.HORIZONTAL_LINE:
      return tool
    case GraphicToolType.RISK_REWARD:
      return {
        ...tool,
        start: [Math.ceil(tool.start[0] * coff), tool.start[1]],
        end: [Math.ceil(tool.end[0] * coff), tool.end[1]]
      }
    default:
      throw new Error(`This graphic tool type ${graphicToolType} has not supported yet`)
  }
}

export function editGraphicTool(
  webTester: WebTester,
  tool: IGraphicTool,
  index: number,
  graphicToolType: GraphicToolType,
  timeFrame: number,
  symbol: string
) {
  const { chartTools } = webTester.props.graphics

  ALL_DEFAULT_TIMEFRAMES.map((tF) => {
    const tools = chartTools[symbol + tF][graphicToolType.valueOf()].map((each, i) => {
      if (i === index) {
        return tF === timeFrame ? { ...tool } : modifyToolForTimeFrame(tool, timeFrame, tF, graphicToolType)
      } else {
        return { ...each }
      }
    })

    webTester.props.updateChartTools({
      symbol,
      timeframe: tF,
      tools: {
        ...chartTools[symbol + tF],
        [graphicToolType.valueOf()]: tools
      }
    })
  })
}

export function datePrecision(timeFrame: number): 'minute' | 'hour' {
  switch (timeFrame) {
    case 1:
    case 5:
    case 15:
    case 30:
      return 'minute'
    case 60:
    case 240:
      return 'hour'
    default:
      return 'hour'
  }
}

export function isMobile() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export function closeAllOrders(webTester: WebTester): void {
  const { orders } = webTester.props

  if (isNotEmptyArray(orders.pending) || isNotEmptyArray(orders.market)) {
    orders.pending.forEach(({ ticket }) => closePendingOrder(webTester, ticket))
    orders.market.forEach(({ ticket }) => closeMarketOrder(webTester, ticket))
  }
}

export const closePendingOrder = (webTester: WebTester, ticket: number): boolean => {
  const {
    orders: { pending }
  } = webTester.props
  const { currentBars } = webTester.state
  const order = pending.find((order) => order.ticket === ticket)

  if (isNotDefined(order)) {
    console.warn('Closing the market order: an order is not defined')
    return false
  }

  store.dispatch(closeAndRemovePendingOrder({ currentBars: currentBars, orderTicket: ticket }))
  return true
}

export const closeMarketOrder = (webTester: WebTester, ticket: number): boolean => {
  const {
    orders: { market },
    graphs: { charts },
    testing: { tickSize }
  } = webTester.props
  const { visibleData, lastBarIndexes, currentBars } = webTester.state
  const order = market.find((order) => order.ticket === ticket)

  if (isNotDefined(order)) {
    console.warn('Closing the market order: an order is not defined')
    return false
  }

  const chart = charts.find((chart) => chart.symbol === order!.symbol)

  if (isNotDefined(chart)) {
    console.warn('Closing the market order: a chart is not defined')
    return false
  }

  const symbolTimeFrame = chart!.symbol + getValidTickSize(tickSize, webTester.state.testingSpeed)

  handleCloseMarketOrder(
    order!,
    currentBars[symbolTimeFrame].date,
    currentBars[symbolTimeFrame].close,
    lastBarIndexes[symbolTimeFrame],
    chart!.timeFrame,
    charts,
    visibleData
  )
  return true
}

export const updateAccountInfo = (webTester: WebTester): void => {
  const {
    orders: { market, accountHistory },
    graphs: {
      currentChart: { symbol, timeFrame }
    }
  } = webTester.props

  const {
    visibleData,
    accountInfo: { balance, lotSize, leverage }
  } = webTester.state

  if (Object.keys(visibleData).length < 1) {
    console.warn('Empty visible data passed into update account method')
    return
  }

  const currentChartTimeFrame = symbol + timeFrame
  const currentBars = populateCurrentBars(visibleData)
  const close: number = currentBars[symbol + timeFrame]?.close ?? 0
  const currencyMultiplier: number = getCurrencyMultiplier(symbol, close)

  const [points, profit, totalProfit] = calculateOperationsRelatedToMarketOrders(currentBars, market)

  const [equity, margin, freeMargin, marginLevel] = calculateAccountInfo(
    balance,
    totalProfit,
    market,
    lotSize,
    leverage,
    currencyMultiplier
  )

  if (visibleData[currentChartTimeFrame] !== undefined) {
    const firstBarDate = visibleData[currentChartTimeFrame][0].date
    const lastBarDate = visibleData[currentChartTimeFrame][visibleData[currentChartTimeFrame].length - 1].date

    webTester.setState({
      currentBars,
      points,
      profit,
      accountInfo: {
        ...webTester.state.accountInfo,
        equity,
        margin,
        freeMargin,
        marginLevel,
        totalProfit
      },
      firstBarDate,
      lastBarDate
    })
  }
}

export function getCurrencyMultiplier(symbol: string, close: number) {
  return symbol.indexOf('USD') === 0 ? 1 : close
}

export const checkTutorialTriggers = (webTester: WebTester): boolean => {
  if (webTester.props.joyride.guideIsOn) {
    const {
      step,
      originalTutorial: { Steps },
      guideSteps
    } = webTester.props.joyride

    if (isDefined(Steps[step])) {
      if (isDefined(Steps[step].StepInfo.StepActions) && Steps[step].StepInfo.StepActions.length > 0) {
        const waitForDate: string = Steps[step].StepInfo.StepActions[0].Action.Properties.WaitFor
        const precision = datePrecision(webTester.props.graphs.currentChart.timeFrame)
        const isItWaitingBar =
          isDefined(waitForDate) &&
          moment(waitForDate).startOf(precision).format(COMMON_DATE_FORMAT) <=
            formatDateWithIgnoredLocale(webTester.state.lastBarDate, COMMON_DATE_FORMAT)

        if (isItWaitingBar) {
          handleStartPauseButtonToggle(webTester)
          onNextStep(webTester, Steps, webTester.props.joyride.step + 1, guideSteps)
          return false
        }
      }
      const isMarketOrderClosed =
        webTester.props.joyride.isWaitingMarketOrderClose && isEmptyArray(webTester.props.orders.market)

      if (isMarketOrderClosed) {
        handleStartPauseButtonToggle(webTester)
        Steps[step].StepInfo.StepActions.map((trigger) => {
          if (isDefined(trigger.Action.Properties.NextStepName)) {
            const newStepIndex = findStepIndexByAction(Steps, trigger.Action.Properties.NextStepName)
            onNextStep(webTester, Steps, newStepIndex, guideSteps)
            webTester.props.setWaitMarketOrder(false)
          }
        })
        return false
      }
    }
  }

  return true
}

export function getDateFrom(projectName: ProjectName): string {
  switch (projectName) {
    case ProjectName.MARKETING:
      return moment.unix(demoProject.FromDate!).utc().format(COMMON_DATE_FORMAT)
    case ProjectName.DEFAULT:
      return moment.unix(defaultProject.FromDate!).utc().format(COMMON_DATE_FORMAT)
    case ProjectName.BASIC_COURSES:
      return moment.unix(basicCourseDemoProject.FromDate!).utc().format(COMMON_DATE_FORMAT)
    default:
      console.warn(`There is no project with name ${projectName}`)
      return 'Error'
  }
}

export function getInitAccountInfo(projectName: ProjectName): IAccountInfo {
  switch (projectName) {
    case ProjectName.MARKETING:
      return demoAccountInfo
    case ProjectName.DEFAULT:
      return defaultAccountInfo
    case ProjectName.BASIC_COURSES:
      return basicCourseDemoAccountInfo
    default:
      console.warn(`Error. Incorrect project name ${projectName} to get initial Account Info`)
      throw new Error(`Error. Incorrect project name ${projectName} to get initial Account Info`)
  }
}

export function getPointerEventsStyle(guideIsOn: boolean, runMode: RunMode) {
  return guideIsOn || window.location.hostname === 'localhost' || runMode === RunMode.DEFAULT ? 'auto' : 'none'
}

export function getCurrentDateToFillVisibleData(
  visibleData: IHistoricalData,
  symbol: string,
  tickSize: number,
  timeframe: number
) {
  const symbolTickSize = symbol + tickSize
  const currentDate =
    visibleData[symbolTickSize] && visibleData[symbolTickSize][visibleData[symbolTickSize].length - 1].date

  if (currentDate && currentDate !== '') return currentDate

  const symbolTimeframe = symbol + timeframe

  return visibleData[symbolTimeframe][visibleData[symbolTimeframe].length - 1].date
}

export const getValidTickSize = (tickSize: TickSize, testingSpeed: number) => {
  if (tickSize == TickSize.AUTO) {
    switch (testingSpeed) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return TickSize.M1
      case 6:
      case 7:
        return TickSize.M5
      case 8:
        return TickSize.M15
      case 9:
        return TickSize.M30
      case 10:
        return TickSize.H1

      default:
        toast.error('Unknown ticksize speed')
        return TickSize.M30
    }
  }
  return tickSize
}

export const getCalcucaltedTestingSpeed = (tickSize: TickSize, testingSpeed: number) => {
  if (tickSize != TickSize.AUTO) return testingSpeed

  switch (testingSpeed) {
    case 1:
      return 2
    case 2:
      return 4
    case 3:
      return 6
    case 4:
      return 8
    case 5:
      return 10
    case 6:
      return 7
    case 7:
      return 10
    case 8:
      return 8
    case 9:
      return 8
    case 10:
      return 8
    default:
      return 5
  }
}

export function getLastBarDate(webTester: WebTester, timeFrame: number) {
  const {
    currentChart: { symbol }
  } = webTester.props.graphs

  const chartTimeFrame = symbol + timeFrame
  const visibleData = webTester.state.visibleData[chartTimeFrame]
  if (isThereVisibleData(visibleData)) {
    return convertUnixToString(webTester.state.dateRange[0])
  }

  const lastBar = visibleData[visibleData.length - 1]

  return moment(lastBar.date).utc().format(COMMON_DATE_FORMAT)
}

export function getBarAfterLastDate(webTester: WebTester, timeFrame: number) {
  const {
    currentChart: { symbol }
  } = webTester.props.graphs

  const chartTimeFrame = symbol + timeFrame
  const visibleData = webTester.state.visibleData[chartTimeFrame]
  if (isThereVisibleData(visibleData)) {
    return convertUnixToString(webTester.state.dateRange[0])
  }

  const lastBar = visibleData[visibleData.length - 1]

  return moment(lastBar.date).utc().add(timeFrame, 'minutes').format(COMMON_DATE_FORMAT)
}

export function isVisibleDataEqualEntireData(
  entireData: IHistoricalData,
  visibleData: IHistoricalData,
  symbolTimeFrame: string
) {
  if (!visibleData[symbolTimeFrame] || !entireData[symbolTimeFrame]) {
    return false
  }

  return entireData[symbolTimeFrame].length === visibleData[symbolTimeFrame].length
}

export async function loadDataForTickSize(
  webTester: WebTester,
  validTickSize: TickSize,
  currentChart: IChart,
  tickSymbolTimeFrame: string
) {
  webTester.props.setIsLoading(true, currentChart.timeFrame, currentChart.symbol)
  const dateStart = webTester.state.dateRange[0]
  let dateEnd = webTester.state.currentTick.date

  const chartId = webTester.props.graphs.currentChart.id
  const loadingLimit = getLoadingLimitInSeconds(validTickSize)

  const visibleData = await loadDataFromDateToDate(
    webTester,
    dateStart,
    dateEnd,
    loadingLimit,
    currentChart.symbol,
    validTickSize,
    chartId
  )

  if (isEmptyArray(visibleData)) {
    console.error('Tick data is not loaded')
    return
  }

  dateEnd = convertStringDateToUnix(last(visibleData)?.date!) + validTickSize * 60

  if (dateEnd > webTester.state.dateRange[1]) {
    dateEnd = webTester.state.dateRange[1]
  }

  const extraData = await loadDataFromDateToDate(
    webTester,
    dateEnd,
    dateEnd + getLoadingLimitInSeconds(validTickSize),
    loadingLimit,
    currentChart.symbol,
    validTickSize,
    chartId
  )

  webTester.setState(
    (state) => ({
      ...state,
      entireData: {
        ...state.entireData,
        [tickSymbolTimeFrame]: [...visibleData, ...extraData]
      },
      visibleData: {
        ...state.visibleData,
        [tickSymbolTimeFrame]: [...visibleData]
      },
      lastBarIndexes: {
        ...state.lastBarIndexes,
        [tickSymbolTimeFrame]: visibleData.length - 1
      },
      currentBars: {
        ...state.currentBars,
        [tickSymbolTimeFrame]: { ...last(visibleData)! }
      },
      chartTimeFrames: [
        ...state.chartTimeFrames,
        {
          symbol: currentChart.symbol,
          timeFrame: validTickSize
        }
      ]
    }),
    () => {
      webTester.props.setIsLoading(false, validTickSize, currentChart.symbol)
    }
  )
}

export async function downloadRestoredData(webTester: WebTester, webTesterReduxState: SavableWebTesterState) {
  let visibleData: IHistoricalData = {}
  let entireData: IHistoricalData = {}

  const { timeFrame: t, symbol: s } = webTester.props.graphs.currentChart

  webTester.props.setIsLoading(true, t, s)
  await Promise.all(
    Object.keys(webTesterReduxState.currentBars).map(async (k) => {
      const { date } = webTesterReduxState.currentBars[k]
      const save = webTester.props.saves.save
      if (save === null) {
        console.error('there is no save')
        return
      }
      const dateStart = webTesterReduxState.dateRange[0]
      const symbol = /[A-Za-z]+/.exec(k)?.[0]
      const timeframe = Number(/[0-9]+/.exec(k)?.[0]) ?? -1
      const loadingLimit = getLoadingLimitInSeconds(timeframe)
      const currentChart = save.data.graphs.charts.find((chart) => chart.symbol === symbol)
      if (!symbol || timeframe <= 0 || !currentChart) {
        console.error('load save data error')
        return
      }

      const data = await loadDataFromDateToDate(
        webTester,
        dateStart,
        webTesterReduxState.currentTick.date,
        loadingLimit,
        symbol,
        timeframe,
        currentChart.id
      )

      const desiredDateEnd = webTesterReduxState.currentTick.date + loadingLimit
      const extraDataEndDate =
        desiredDateEnd < webTesterReduxState.dateRange[1] ? desiredDateEnd : webTesterReduxState.dateRange[1]

      const extraData = await loadDataFromDateToDate(
        webTester,
        webTesterReduxState.currentTick.date + timeframe * 60,
        extraDataEndDate,
        loadingLimit,
        symbol,
        timeframe,
        currentChart.id
      )

      entireData[k] = [...data, ...extraData]
      visibleData[k] = [...data]
    })
  )

  Object.keys(webTesterReduxState.currentBars).map(async (k) => {
    const symbol = /[A-Za-z]+/.exec(k)?.[0]
    const timeframe = Number(/[0-9]+/.exec(k)?.[0]) ?? -1

    if (!symbol || timeframe <= 0) {
      return
    }

    const tickPrice = getTickPrice(
      webTester.props.testing.tickSize,
      webTesterReduxState.testingSpeed,
      visibleData,
      symbol
    )

    if (tickPrice) {
      visibleData[k][visibleData[k].length - 1].close = tickPrice
    }
  })

  webTester.props.setIsLoading(false, t, s)

  return { visibleData, entireData }
}

export async function syncronizeSavedWebTester(webTester: WebTester) {
  const {
    webTesterRedux: { state: webTesterReduxState, isSynced: isSyncedWebTesterWithRedux }
  } = webTester.props
  if (webTesterReduxState && !isSyncedWebTesterWithRedux) {
    const {
      webTesterRedux: { isSynced: isSyncedWebTesterWithRedux }
    } = webTester.props
    if (isSyncedWebTesterWithRedux) return
    webTester.props.toggleIsSyncing()

    webTester.setState(
      {
        ...applySavableWebTester(webTester.state, webTesterReduxState),
        visibleData: {},
        entireData: {}
      },
      async () => {
        const { entireData, visibleData } = await downloadRestoredData(webTester, webTesterReduxState)
        const syncLastBar = {}
        Object.keys(visibleData).map((k) => (syncLastBar[k] = visibleData[k].length - 1))

        webTester.setState(
          {
            ...applySavableWebTester(webTester.state, webTesterReduxState),
            visibleData: Object.keys(visibleData).length > 0 ? visibleData : webTester.state.visibleData,
            entireData: Object.keys(entireData).length > 0 ? entireData : webTester.state.entireData,
            lastBarIndexes: syncLastBar
          },
          () => {
            webTester.props.toggleIsWebTesterSynced()
            webTester.props.toggleIsSyncing()
          }
        )
      }
    )
  }
}

export function isAutoSaveUsed(webTester: WebTester) {
  return webTester.props.saves.save !== null
}

export function saveProjectOnClose(webTester: WebTester) {
  checkLoadingDataForTimeFrameAndTickSize(webTester)
  webTester.props.copyWebTesterToSlice({ state: convertToSavableWebTester(webTester.state) })
  store.dispatch(SaveActions.saveProject())
}

export function checkLoadingDataForTimeFrameAndTickSize(webTester: WebTester) {
  const { charts, isLoadingData } = webTester.props.graphs
  const { visibleData, currentBars, chartTimeFrames, lastBarIndexes } = webTester.state
  const chartsWithLoading = charts.filter(
    (chart) => isLoadingData[chart.symbol] && isLoadingData[chart.symbol][chart.timeFrame]
  )
  chartsWithLoading.map((chart) => {
    const symbolTimeFrame = chart.symbol + chart.timeFrame
    const validData = Object.keys(visibleData).filter((key) => visibleData[key].length > 0)

    if (validData.length === 0) return

    if (visibleData[symbolTimeFrame].length <= 1) {
      if (
        currentBars[symbolTimeFrame] ||
        chartTimeFrames.find((ct) => ct.symbol + ct.timeFrame === symbolTimeFrame) ||
        lastBarIndexes[symbolTimeFrame]
      ) {
        let newCurrentBars = { ...currentBars }
        delete newCurrentBars[symbolTimeFrame]
        const newChartTimeFrames = chartTimeFrames.filter((ct) => ct.symbol + ct.timeFrame != symbolTimeFrame)
        let newLastBarIndexes = { ...lastBarIndexes }
        delete newLastBarIndexes[symbolTimeFrame]
        webTester.setState((prevState) => ({
          ...prevState,
          currentBars: newCurrentBars,
          chartTimeFrames: newChartTimeFrames,
          lastBarIndexes: newLastBarIndexes
        }))
      }
      webTester.props.setTimeFrame(getNumbersFromString(validData[0]))
      webTester.props.setIsLoading(false, chart.timeFrame, chart.symbol)
    }
  })
}

export function getTickPrice(
  tickSize: number,
  testingSpeed: number,
  visibleData: IHistoricalData,
  symbol: string
): number {
  const validTick = getValidTickSize(tickSize, testingSpeed)

  if (validTick === testingSpeed) {
    return 0
  }

  const tickPrice = last(visibleData[symbol + validTick])?.close

  if (!tickPrice) {
    console.error(`Incorrect tick price for tick ${tickSize}`)
    return 0
  }

  return tickPrice!
}

async function loadExtraEntireData(
  lastBarVisibleDataDate: number,
  lastBarEntireDataDate: number,
  loadingLimit: number,
  webTester: WebTester,
  timeFrame: number,
  symbol: string,
  id: string,
  symbolTimeFrame: string
) {
  const [rangeStart, rangeEnd] = webTester.state.dateRange

  if (
    lastBarEntireDataDate - lastBarVisibleDataDate < loadingLimit &&
    lastBarEntireDataDate < rangeEnd - timeFrame * 60
  ) {
    const desiredDateEnd = lastBarEntireDataDate + loadingLimit
    const endDate = desiredDateEnd > rangeEnd ? rangeEnd : desiredDateEnd

    PreloadDataService.setIsLoading(symbolTimeFrame, true)
    const data = await loadDataFromDateToDate(
      webTester,
      lastBarEntireDataDate + timeFrame * 60,
      endDate,
      loadingLimit,
      symbol,
      timeFrame,
      id
    )

    webTester.setState(
      (prevState) => ({
        ...prevState,
        entireData: {
          ...prevState.entireData,
          [symbolTimeFrame]: [...prevState.entireData[symbolTimeFrame], ...data]
        }
      }),
      () => {
        PreloadDataService.setIsLoading(symbolTimeFrame, false)
        webTester.props.setIsLoading(false, timeFrame, symbol)
      }
    )
  }
}

export async function preloadBufferData(webTester: WebTester) {
  const {
    currentChart,
    currentChart: { symbol, timeFrame, id }
  } = webTester.props.graphs
  const { tickSize } = webTester.props.testing
  const { testingSpeed } = webTester.state
  const validTickSize = getValidTickSize(tickSize, testingSpeed)
  const tickSymbolTimeFrame = symbol + validTickSize
  const currentChartTimeFrame = currentChart.symbol + currentChart.timeFrame
  const { entireData, visibleData } = webTester.state

  if (!entireData[currentChartTimeFrame] || !visibleData[currentChartTimeFrame]) {
    return
  }

  if (PreloadDataService.isLoading[currentChartTimeFrame] || PreloadDataService.isLoading[tickSymbolTimeFrame]) {
    return
  }

  const lastTimeFrameBarDateEntireData = convertStringDateToUnix(last(entireData[currentChartTimeFrame])!.date)
  const lastTimeFrameBarDateVisibleData = convertStringDateToUnix(last(visibleData[currentChartTimeFrame])!.date)
  const loadingLimitTimeframeData = getLoadingLimitInSeconds(timeFrame)

  await loadExtraEntireData(
    lastTimeFrameBarDateVisibleData,
    lastTimeFrameBarDateEntireData,
    loadingLimitTimeframeData,
    webTester,
    timeFrame,
    symbol,
    id,
    currentChartTimeFrame
  )

  if (validTickSize !== timeFrame) {
    const lastTickBarDateEntireData = convertStringDateToUnix(last(entireData[tickSymbolTimeFrame])!.date)
    const lastTickBarDateVisibleData = convertStringDateToUnix(last(visibleData[tickSymbolTimeFrame])!.date)
    const loadingLimitTickData = getLoadingLimitInSeconds(validTickSize)

    await loadExtraEntireData(
      lastTickBarDateVisibleData,
      lastTickBarDateEntireData,
      loadingLimitTickData,
      webTester,
      validTickSize,
      symbol,
      id,
      tickSymbolTimeFrame
    )
  }
}
