class PreloadDataService {
  private _isLoading: { [key: string]: boolean } = {}

  get isLoading(): { [key: string]: boolean } {
    return this._isLoading
  }

  setIsLoading(symbolTimeframe: string, isLoading: boolean) {
    this._isLoading[symbolTimeframe] = isLoading
  }
}

const preloadDataService = new PreloadDataService()

export default preloadDataService
