import { IJoyrideState } from '../../../redux/joyride/types'
import { IModalsState } from '../../../redux/modals/types'
import { GuideNames, PendingOrderTypes } from '../../../utils/enums'
import { ICurrentBar } from '../../../utils/interfaces/ICurrentBar'
import { IBar, IHistoricalData, IHistoricalDataTradingView } from '../../../utils/interfaces/IHistoricalData.interface'
import { IMarketOrder, IPendingOrder } from '../../../utils/interfaces/IOrder.interface'
import { ITimeFrame } from '../../../utils/interfaces/ITimeFrame'
import { ITutorial } from '../../../utils/interfaces/ITutorial.interface'

export const dummyOrder: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.12345,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const currentBar: ICurrentBar = {
  EURUSD5: {
    date: '2020-02-05T10:00:00.000Z',
    close: 1.10249,
    open: 1.10253,
    barIndex: 717
  },
  GBPUSD60: {
    date: '2020-02-05T10:00:00.000Z',
    close: 1.305,
    open: 1.30652,
    barIndex: 60
  },
  USDJPY15: {
    date: '2020-02-05T10:00:00.000Z',
    close: 109.671,
    open: 109.69,
    barIndex: 240
  }
}

export const joinedIndicatorsData: number[] = [
  0, 0, 0, 0, 1.2345, 1.2346, 1.2347, 1.2348, 1.2, 1.3, 0, 0, 0, 0, 1.4, 1.39, 1.38, 1.37, 1.36, 1.3
]

export const timeFrames: ITimeFrame = {
  EURUSD1: 0,
  GBPUSD1: 0,
  USDJPY1: 0
}

export const lastBarIndexex = {
  EURUSD1: 1,
  GBPUSD1: 1,
  USDJPY1: 1
}

export const visibleData: IHistoricalData = {
  EURUSD1: [
    {
      date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)',
      open: 1.11688,
      high: 1.11688,
      low: 1.11688,
      close: 1.11688,
      volume: 4
    },
    {
      date: 'Thu Jan 02 2020 06:41:00 GMT+0200 (Eastern European Standard Time)',
      open: 1.11688,
      high: 1.11688,
      low: 1.11688,
      close: 1.11688,
      volume: 4
    }
  ],
  GBPUSD1: [
    {
      date: 'Thu Jan 02 2020 06:39:00 GMT+0200 (Eastern European Standard Time)',
      open: 1.31404,
      high: 1.31404,
      low: 1.31404,
      close: 1.31404,
      volume: 4
    },
    {
      date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)',
      open: 1.31404,
      high: 1.31404,
      low: 1.31404,
      close: 1.31404,
      volume: 4
    }
  ],
  USDJPY1: [
    {
      date: 'Thu Jan 02 2020 06:30:00 GMT+0200 (Eastern European Standard Time)',
      open: 108.519,
      high: 108.519,
      low: 108.519,
      close: 108.519,
      volume: 4
    },
    {
      date: 'Thu Jan 02 2020 06:35:00 GMT+0200 (Eastern European Standard Time)',
      open: 108.519,
      high: 108.519,
      low: 108.519,
      close: 108.519,
      volume: 4
    }
  ]
}

export const entireData: IHistoricalData = {
  EURUSD1: [
    {
      date: '2020-02-02T18:05:00.000Z',
      open: 1.10906,
      high: 1.1092,
      low: 1.10905,
      close: 1.10914,
      volume: 25
    },
    {
      date: '2020-02-02T18:10:00.000Z',
      open: 1.10915,
      high: 1.10918,
      low: 1.10913,
      close: 1.10913,
      volume: 66
    },
    {
      date: '2020-02-02T18:15:00.000Z',
      open: 1.10913,
      high: 1.10914,
      low: 1.10906,
      close: 1.10906,
      volume: 16
    },
    {
      date: '2020-02-02T18:20:00.000Z',
      open: 1.10906,
      high: 1.10907,
      low: 1.10856,
      close: 1.1087,
      volume: 51
    },
    {
      date: '2020-02-02T18:25:00.000Z',
      open: 1.1087,
      high: 1.109,
      low: 1.1087,
      close: 1.10891,
      volume: 43
    },
    {
      date: '2020-02-02T18:30:00.000Z',
      open: 1.10891,
      high: 1.10897,
      low: 1.10847,
      close: 1.10848,
      volume: 134
    },
    {
      date: '2020-02-02T18:35:00.000Z',
      open: 1.10846,
      high: 1.10847,
      low: 1.10817,
      close: 1.10822,
      volume: 125
    },
    {
      date: '2020-02-02T18:40:00.000Z',
      open: 1.10822,
      high: 1.10855,
      low: 1.10817,
      close: 1.10852,
      volume: 134
    },
    {
      date: '2020-02-02T18:45:00.000Z',
      open: 1.10852,
      high: 1.10876,
      low: 1.10852,
      close: 1.1087,
      volume: 150,
      sma8: 1.10877,
      rsi8: 37.912087912089056
    },
    {
      date: '2020-02-02T18:50:00.000Z',
      open: 1.10868,
      high: 1.10872,
      low: 1.10861,
      close: 1.1087,
      volume: 243,
      sma8: 1.1087150000000001,
      rsi8: 37.912087912089056
    },
    {
      date: '2020-02-02T18:55:00.000Z',
      open: 1.10864,
      high: 1.10871,
      low: 1.10817,
      close: 1.10835,
      volume: 268,
      sma8: 1.10866125,
      rsi8: 30.30112923463004
    }
  ]
}

export const visibleDataTradingView: IHistoricalDataTradingView = {
  EURUSD1: [
    {
      time: '2018-10-19',
      open: 1.11688,
      high: 1.11688,
      low: 1.11688,
      close: 1.11688
    },
    {
      time: '2018-10-20',
      open: 1.11688,
      high: 1.11688,
      low: 1.11688,
      close: 1.11688
    }
  ],
  GBPUSD1: [
    {
      time: '2018-10-19',
      open: 1.31404,
      high: 1.31404,
      low: 1.31404,
      close: 1.31404
    },
    {
      time: '2018-10-20',
      open: 1.31404,
      high: 1.31404,
      low: 1.31404,
      close: 1.31404
    }
  ],
  USDJPY1: [
    {
      time: '2018-10-19',
      open: 108.519,
      high: 108.519,
      low: 108.519,
      close: 108.519
    },
    {
      time: '2018-10-20',
      open: 108.519,
      high: 108.519,
      low: 108.519,
      close: 108.519
    }
  ]
}

//#region 'close prices (from currentBars)'
export const closePriceZeroDigitsAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 99,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceOneDigitAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1.1,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1.2,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 95.1,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceTwoDigitsAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1.13,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1.37,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 95.71,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceThreeDigitsAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1.134,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1.378,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 95.721,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceFourDigitsAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1.1345,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1.3781,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 95.7233,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceFiveDigitsAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1.00092,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1.22133,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 100,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceTenDigitsAfterPoint: ICurrentBar = {
  EURUSD1: {
    close: 1.1234567891,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  GBPUSD1: {
    close: 1.2345678912,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  USDJPY1: {
    close: 95.3456789123,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}

export const closePriceLessThanOne: ICurrentBar = {
  BTCUSD1: {
    close: 0.1234567891,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  ETHUSD1: {
    close: 0.2345678912,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  },
  LTCUSD1: {
    close: 0.3456789123,
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)'
  }
}
//#endregion 'close prices (from currentBars)'

//#region 'open prices (from orders)'
// only type & openPrice are important for this method
export const openPriceZeroDigitsAfterPointSell: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 0.2,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'sell',
    lots: 0.74,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 2,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'sell',
    lots: 13,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 97,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceOneDigitAfterPointSell: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 0.3,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.2,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'sell',
    lots: 0.88,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.4,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'sell',
    lots: 4,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 97.3,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceTwoDigitsAfterPointSell: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 14.2,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.12,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'sell',
    lots: 0.01,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.34,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'sell',
    lots: 0.99,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 93.28,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceThreeDigitsAfterPointSell: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 1094.27,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.333,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'sell',
    lots: 0.0001,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.456,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'sell',
    lots: 3.1428,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 97.289,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceFourDigitsAfterPointBuy: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'buy',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.0009,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'buy',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.2213,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'buy',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 97.8314,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceFiveDigitsAfterPointBuy: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'buy',
    lots: 1.99,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.00092,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'buy',
    lots: 2.01,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.22134,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'buy',
    lots: 1052,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 99.99999,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceTenDigitsAfterPointBuy: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'buy',
    lots: 0.5,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.1234567889,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'buy',
    lots: 700.12,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.2345678912,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'buy',
    lots: 0.33,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 97.4567891234,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceLessThanOneBuy: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'buy',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 0.88221,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'buy',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 0.9992,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'buy',
    lots: 0,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 0.97,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const openPriceMixedDigitsAfterPointBuy: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'buy',
    lots: 1.99,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.092,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 2,
    symbol: 'GBPUSD',
    type: 'buy',
    lots: 2.01,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.22134,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 3,
    symbol: 'USDJPY',
    type: 'buy',
    lots: 1052,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 99.99,
    marketPrice: 98,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

//#endregion 'open prices (from orders)'

export const ordersIntegerLots: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 1,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.12345,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 2,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.12345,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const ordersFloatLots: IMarketOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 3.18,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.12345,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  },
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'sell',
    lots: 0.94,
    openTime: 'string',
    sl: 0,
    tp: 0,
    openPrice: 1.12345,
    marketPrice: 1.5,
    swap: 0,
    commission: 0,
    comment: '',
    points: 0,
    profit: 0,
    barIndex: 0,
    timeFrame: 5
  }
]

export const marketOrders: IMarketOrder[] = [
  {
    comment: '',
    commission: 0,
    lots: 0.1,
    marketPrice: 1.10131,
    openPrice: 1.10131,
    openTime: '2020-02-05T10:00:00Z',
    points: 0,
    profit: 0,
    sl: 0,
    swap: 0,
    symbol: 'EURUSD',
    ticket: 1,
    tp: 0,
    type: 'sell',
    barIndex: 764,
    timeFrame: 5
  },
  {
    comment: '',
    commission: 0,
    lots: 0.1,
    marketPrice: 1.10001,
    openPrice: 1.10001,
    openTime: '2020-02-05T10:40:00Z',
    points: 0,
    profit: 0,
    sl: 0,
    swap: 0,
    symbol: 'EURUSD',
    ticket: 2,
    tp: 0,
    type: 'sell',
    barIndex: 772,
    timeFrame: 5
  },
  {
    comment: '',
    commission: 0,
    lots: 0.1,
    marketPrice: 1.30463,
    openPrice: 1.30463,
    openTime: '2020-02-05T10:00:00Z',
    points: 0,
    profit: 0,
    sl: 0,
    swap: 0,
    symbol: 'GBPUSD',
    ticket: 3,
    tp: 0,
    type: 'sell',
    barIndex: 62,
    timeFrame: 5
  }
]

export const pendingOrders: IPendingOrder[] = [
  {
    ticket: 1,
    symbol: 'EURUSD',
    type: 'buy limit',
    lots: 0.1,
    sl: 0,
    tp: 0,
    comment: '',
    createdAt: '2020.02.05 10:00',
    execPrice: 1.10126,
    marketPrice: 1.10131,
    barIndex: 764
  },
  {
    ticket: 3,
    symbol: 'GBPUSD',
    type: 'buy limit',
    lots: 0.1,
    sl: 0,
    tp: 0,
    comment: '',
    createdAt: '2020.02.05 13:00',
    execPrice: 1.29827,
    marketPrice: 1.29832,
    barIndex: 65
  },
  {
    ticket: 4,
    symbol: 'EURUSD',
    type: 'buy limit',
    lots: 0.1,
    sl: 0,
    tp: 0,
    comment: '',
    createdAt: '2020.02.05 10:00',
    execPrice: 1.10126,
    marketPrice: 1.10131,
    barIndex: 764
  }
]

export const accountHistory = [
  {
    comment: '',
    commission: 0,
    lots: 0,
    marketPrice: 1.10131,
    openPrice: 1.10131,
    openTime: '2020-02-05T10:00:00Z',
    points: 145,
    profit: 14.5,
    sl: 0,
    swap: 0,
    symbol: 'EURUSD',
    ticket: 1,
    tp: 0,
    type: 'sell',
    barIndex: 71,
    closePrice: 1.09986,
    closeTime: '2020.02.05 19:00'
  }
]

export const marketOrderSellWithSLTP: IMarketOrder = {
  comment: '',
  commission: 0,
  lots: 0.1,
  marketPrice: 1.10131,
  openPrice: 1.10131,
  openTime: '2020-02-05T10:00:00Z',
  points: 0,
  profit: 0,
  sl: 1.102,
  swap: 0,
  symbol: 'EURUSD',
  ticket: 1,
  tp: 1.101,
  type: 'sell',
  barIndex: 764,
  timeFrame: 5
}

export const marketOrderBuyWithSLTP: IMarketOrder = {
  comment: '',
  commission: 0,
  lots: 0.1,
  marketPrice: 1.10131,
  openPrice: 1.10131,
  openTime: '2020-02-05T10:00:00Z',
  points: 0,
  profit: 0,
  sl: 1.101,
  swap: 0,
  symbol: 'EURUSD',
  ticket: 1,
  tp: 1.102,
  type: 'buy',
  barIndex: 764,
  timeFrame: 5
}

export const pendingBuyLimitOrder = (type: PendingOrderTypes): IPendingOrder => {
  return {
    comment: '',
    lots: 0.1,
    createdAt: '2020-02-05T10:00:00Z',
    marketPrice: 1.10131,
    execPrice: 1.10131,
    sl: 1.101,
    symbol: 'EURUSD',
    ticket: 1,
    tp: 1.102,
    type: type,
    barIndex: 764
  }
}

export const twoBars: IBar[] = [
  {
    date: 'Thu Jan 02 2020 06:40:00 GMT+0200 (Eastern European Standard Time)',
    open: 1.11688,
    high: 1.11688,
    low: 1.11688,
    close: 1.11688,
    volume: 4
  },
  {
    date: 'Thu Jan 02 2020 06:41:00 GMT+0200 (Eastern European Standard Time)',
    open: 1.11688,
    high: 1.11688,
    low: 1.11688,
    close: 1.11688,
    volume: 4
  }
]

export const modalStateAllFalse: IModalsState = {
  marketOrderModalIsOpen: false,
  marketOrderQuestionModalIsOpen: false,
  pendingOrderModalIsOpen: false,
  pendingOrderQuestionModalIsOpen: false,
  indicatorModalEditModeIsOpen: false,
  indicatorModalCreateModeIsOpen: false,
  oscillatorModalEditModeIsOpen: false,
  trialVersionModalIsOpen: false,
  lessonAlertModalIsOpen: false,
  newProjectModalIsOpen: false,
  openProjectModalIsOpen: false,
  editProjectModalIsOpen: false,
  saveProjectModalIsOpen: false,
  saveProjectAsModalIsOpen: false,
  projectInformationModalIsOpen: false,
  depositChangeModalIsOpen: false,
  interactiveTextModalIsOpen: false,
  quickStartCoursesModalIsOpen: false,
  tutorialFeedbackModalIsOpen: false,
  subscribeModalIsOpen: false,
  demonstrationModalIsOpen: false,
  finishedTutorialModalIsOpen: false,
  listOfStrategiesModalIsOpen: false,
  strategyOptimizerModalIsOpen: false,
  basicCoursesModalIsOpen: false,
  defaultOrdersModalIsOpen: false,
  hotKeysModalIsOpen: false,
  soundsModalIsOpen: false,
  signalToCloseDropdown: false,
  notEnoughFreeMarginModalIsOpened: false,
  quitTutorialModalIsOpened: false,
  startNewProjectModalIsOpen: false,
  rewardToRiskModal: {IsOpen: false}
}

export const modalStateHasTrue: IModalsState = {
  marketOrderModalIsOpen: true,
  marketOrderQuestionModalIsOpen: false,
  pendingOrderModalIsOpen: false,
  pendingOrderQuestionModalIsOpen: false,
  indicatorModalEditModeIsOpen: false,
  oscillatorModalEditModeIsOpen: false,
  indicatorModalCreateModeIsOpen: false,
  trialVersionModalIsOpen: false,
  lessonAlertModalIsOpen: false,
  newProjectModalIsOpen: false,
  openProjectModalIsOpen: false,
  editProjectModalIsOpen: false,
  saveProjectModalIsOpen: false,
  saveProjectAsModalIsOpen: false,
  projectInformationModalIsOpen: false,
  depositChangeModalIsOpen: false,
  interactiveTextModalIsOpen: false,
  quickStartCoursesModalIsOpen: false,
  tutorialFeedbackModalIsOpen: false,
  subscribeModalIsOpen: false,
  demonstrationModalIsOpen: false,
  finishedTutorialModalIsOpen: false,
  listOfStrategiesModalIsOpen: false,
  strategyOptimizerModalIsOpen: false,
  basicCoursesModalIsOpen: false,
  defaultOrdersModalIsOpen: false,
  hotKeysModalIsOpen: false,
  soundsModalIsOpen: false,
  signalToCloseDropdown: false,
  notEnoughFreeMarginModalIsOpened: false,
  quitTutorialModalIsOpened: false,
  startNewProjectModalIsOpen: false,
  rewardToRiskModal:{IsOpen: false}
}

export const mockLoadedDataH4 = [
  {
    date: '2020-02-02T16:00:00.000Z',
    open: 1.10906,
    high: 1.1092,
    low: 1.10817,
    close: 1.10886,
    volume: 2683
  },
  {
    date: '2020-02-02T20:00:00.000Z',
    open: 1.10886,
    high: 1.10886,
    low: 1.10795,
    close: 1.10806,
    volume: 5426
  },
  {
    date: '2020-02-03T00:00:00.000Z',
    open: 1.10806,
    high: 1.10858,
    low: 1.10699,
    close: 1.10743,
    volume: 4456
  },
  {
    date: '2020-02-03T04:00:00.000Z',
    open: 1.10744,
    high: 1.10779,
    low: 1.10579,
    close: 1.10622,
    volume: 10424
  },
  {
    date: '2020-02-03T08:00:00.000Z',
    open: 1.10623,
    high: 1.10663,
    low: 1.1035,
    close: 1.10552,
    volume: 10482
  },
  {
    date: '2020-02-03T12:00:00.000Z',
    open: 1.10553,
    high: 1.10659,
    low: 1.10533,
    close: 1.10631,
    volume: 7598
  },
  {
    date: '2020-02-03T16:00:00.000Z',
    open: 1.10628,
    high: 1.10636,
    low: 1.10559,
    close: 1.10618,
    volume: 3449
  },
  {
    date: '2020-02-03T20:00:00.000Z',
    open: 1.10619,
    high: 1.10636,
    low: 1.1055,
    close: 1.10575,
    volume: 4096
  },
  {
    date: '2020-02-04T00:00:00.000Z',
    open: 1.10573,
    high: 1.10632,
    low: 1.10484,
    close: 1.10496,
    volume: 4936
  },
  {
    date: '2020-02-04T04:00:00.000Z',
    open: 1.10492,
    high: 1.10604,
    low: 1.10488,
    close: 1.10535,
    volume: 9506
  },
  {
    date: '2020-02-04T08:00:00.000Z',
    open: 1.10536,
    high: 1.10536,
    low: 1.10326,
    close: 1.10379,
    volume: 9250
  },
  {
    date: '2020-02-04T12:00:00.000Z',
    open: 1.10379,
    high: 1.10448,
    low: 1.10323,
    close: 1.10415,
    volume: 6493
  },
  {
    date: '2020-02-04T16:00:00.000Z',
    open: 1.10414,
    high: 1.10455,
    low: 1.10399,
    close: 1.10443,
    volume: 3487
  },
  {
    date: '2020-02-04T20:00:00.000Z',
    open: 1.10445,
    high: 1.10445,
    low: 1.10331,
    close: 1.10337,
    volume: 3742
  },
  {
    date: '2020-02-05T00:00:00.000Z',
    open: 1.10334,
    high: 1.10401,
    low: 1.10332,
    close: 1.10363,
    volume: 3267
  },
  {
    date: '2020-02-05T04:00:00.000Z',
    open: 1.10366,
    high: 1.10474,
    low: 1.10141,
    close: 1.10212,
    volume: 12369
  },
  {
    date: '2020-02-05T08:00:00.000Z',
    open: 1.10217,
    high: 1.10228,
    low: 1.09959,
    close: 1.10016,
    volume: 12336
  }
]

export const orderMarker = {
  id: 1,
  type: 'Open',
  openTime: '2020-02-05T10:00:00Z',
  x1: {
    '5': 764
  },
  y1: 1.10131,
  x2: {}
}

export const courseStep = {
  styles: {
    beacon: {
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 0,
      color: '#555',
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1,
      padding: 8,
      WebkitAppearance: 'none',
      display: 'inline-block',
      height: 36,
      position: 'relative',
      width: 36,
      zIndex: 90
    },
    beaconInner: {
      animation: 'joyride-beacon-inner 1.2s infinite ease-in-out',
      backgroundColor: '#f04',
      borderRadius: '50%',
      display: 'block',
      height: '50%',
      left: '50%',
      opacity: 0.7,
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%'
    },
    beaconOuter: {
      animation: 'joyride-beacon-outer 1.2s infinite ease-in-out',
      backgroundColor: 'rgba(255,0,68, 0.2)',
      border: '2px solid #f04',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'block',
      height: '100%',
      left: 0,
      opacity: 0.9,
      position: 'absolute',
      top: 0,
      transformOrigin: 'center',
      width: '100%'
    },
    tooltip: {
      backgroundColor: '#fff',
      borderRadius: 5,
      boxSizing: 'border-box',
      color: '#333',
      fontSize: 16,
      maxWidth: '100%',
      padding: 15,
      position: 'relative',
      width: 440
    },
    tooltipContainer: {
      lineHeight: 1.4,
      textAlign: 'left'
    },
    tooltipTitle: {
      fontSize: 18,
      margin: 0
    },
    tooltipContent: {
      padding: '20px 10px'
    },
    tooltipFooter: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 15
    },
    tooltipFooterSpacer: {
      flex: 1
    },
    buttonNext: {
      backgroundColor: '#3084E9',
      border: 0,
      borderRadius: 6,
      color: '#ffffff',
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1,
      padding: 8,
      WebkitAppearance: 'none',
      display: 'none'
    },
    buttonBack: {
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 0,
      color: '#f04',
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1,
      padding: 8,
      WebkitAppearance: 'none',
      marginLeft: 'auto',
      marginRight: 5
    },
    buttonClose: {
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 0,
      color: '#333',
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1,
      padding: 15,
      WebkitAppearance: 'none',
      height: 14,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 14
    },
    buttonSkip: {
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 0,
      color: '#333',
      cursor: 'pointer',
      fontSize: 14,
      lineHeight: 1,
      padding: 8,
      WebkitAppearance: 'none'
    },
    overlay: {
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 90,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      mixBlendMode: 'hard-light'
    },
    overlayLegacy: {
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 90
    },
    overlayLegacyCenter: {
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 90,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    spotlight: {
      borderRadius: 4,
      position: 'absolute'
    },
    spotlightLegacy: {
      borderRadius: 4,
      position: 'absolute',
      boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5)'
    },
    options: {
      arrowColor: '#fff',
      backgroundColor: '#fff',
      beaconSize: 36,
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#f04',
      spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
      textColor: '#333',
      zIndex: 90,
      width: 440
    }
  },
  showProgress: true,
  disableOverlayClose: true,
  spotlightClicks: true,
  disableCloseOnEsc: false,
  disableOverlay: false,
  disableScrolling: true,
  disableScrollParentFix: false,
  hideBackButton: true,
  showSkipButton: false,
  spotlightPadding: 0,
  event: 'click',
  placement: 'bottom',
  offset: 10,
  showArrow: false,
  disableBeacon: true,
  target: '#StartTestID',
  title: '123',
  content: {
    key: null,
    ref: null,
    props: {
      content: {
        Texts: [
          {
            Content: 'Click the “Start” button to begin the testing process.',
            Alignment: 0,
            Ranges: null,
            Scale: {
              LeftPoint: 165,
              TopPoint: 25,
              Width: 180,
              Height: 260
            }
          }
        ],
        Buttons: null,
        Images: [
          {
            ImagePath: 'https://4xdev.com:8888/images/sirok-1-5.png',
            Scale: {
              LeftPoint: 15,
              TopPoint: 40,
              Width: 151,
              Height: 151
            }
          }
        ]
      }
    },
    _owner: null,
    _store: {}
  },
  locale: {
    back: 'Back',
    close: 'Close',
    last: 'Last',
    next: 'Next',
    open: 'Open the dialog',
    skip: 'Skip'
  },
  floaterProps: {
    options: {
      preventOverflow: {
        boundariesElement: 'scrollParent'
      }
    },
    wrapperOptions: {
      offset: -18,
      position: true
    },
    offset: 20,
    styles: {
      arrow: {
        color: '#fff'
      },
      options: {
        zIndex: 90
      }
    }
  }
}

export const defaultOriginalTutorial: ITutorial = {
  LessonId: 0,
  Type: '',
  CourseName: '',
  Position: 0,
  LearningTime: '',
  Name: '',
  Author: '',
  Description: '',
  Steps: [],
  OriginalLessonName: ''
}

export const getJoyride = (stepIndex: number): IJoyrideState => ({
  courses: {},
  tutorialsList: {},
  currentCourseName: '',
  currentTutorialIndex: 0,
  repeatIsPressed: false,
  lessonNumber: 1,
  isWaitingMarketOrderClose: false,
  stepActionType: undefined,
  originalTutorial: defaultOriginalTutorial,
  guideName: GuideNames.QuickStart,
  guideDescription: '',
  guideIsOn: true,
  activateNextStepOnModalOpened: false,
  manualStart: true,
  step: stepIndex,
  guideSteps: [
    {
      disableBeacon: true,
      disableScrolling: true,
      spotlightPadding: 0,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideBackButton: true,
      styles: {
        buttonNext: {
          backgroundColor: '#3084E9',
          borderRadius: 6,
          color: '#ffffff',
          display: 'none'
        }
      },
      placement: 'bottom',
      target: '#StartTestID',
      title: '123',
      content: {
        key: null,
        ref: null,
        props: {
          content: {
            Texts: [
              {
                Content: 'Click the “Start” button to begin the testing process.',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 165,
                  TopPoint: 25,
                  Width: 180,
                  Height: 260
                }
              }
            ],
            Buttons: null,
            Images: [
              {
                ImagePath: 'https://4xdev.com:8888/images/sirok-1-5.png',
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 40,
                  Width: 151,
                  Height: 151
                }
              }
            ]
          }
        },
        _owner: null,
        _store: {}
      }
    },
    {
      disableBeacon: true,
      disableScrolling: true,
      spotlightPadding: 0,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideBackButton: true,
      styles: {
        buttonNext: {
          backgroundColor: '#3084E9',
          borderRadius: 6,
          color: '#ffffff',
          display: 'none'
        }
      },
      placement: 'bottom',
      target: '#StartTestID',
      title: '123',
      content: {
        key: null,
        ref: null,
        props: {
          content: {
            Texts: [
              {
                Content:
                  'The simulation has begun!\r\n\r\nNow you see the price changing like in the live market.\r\n\r\nIf you need to pause testing, click the same button again.',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 165,
                  TopPoint: 25,
                  Width: 260,
                  Height: 180
                }
              }
            ],
            Buttons: null,
            Images: [
              {
                ImagePath: 'https://4xdev.com:8888/images/Gonn%20Abigood-4.png',
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 40,
                  Width: 150,
                  Height: 150
                }
              }
            ]
          }
        },
        _owner: null,
        _store: {}
      }
    },
    {
      disableBeacon: true,
      disableScrolling: true,
      spotlightPadding: 0,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideBackButton: true,
      styles: {
        buttonNext: {
          backgroundColor: '#3084E9',
          borderRadius: 6,
          color: '#ffffff',
          display: 'none'
        }
      },
      placement: 'bottom',
      target: '#StartTestID',
      title: '123',
      content: {
        key: null,
        ref: null,
        props: {
          content: {
            Texts: [
              {
                Content:
                  'As you can see, the testing has stopped.\r\n\r\nUse this button to resume it as well.\r\n\r\nYes, three actions are hidden in one button! Pretty convenient, isn’t it?',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 165,
                  TopPoint: 25,
                  Width: 260,
                  Height: 180
                }
              }
            ],
            Buttons: null,
            Images: [
              {
                ImagePath: 'https://4xdev.com:8888/images/Sir%20Bernard%20Ullish-5.png',
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 40,
                  Width: 150,
                  Height: 150
                }
              }
            ]
          }
        },
        _owner: null,
        _store: {}
      }
    },
    {
      disableBeacon: true,
      disableScrolling: true,
      spotlightPadding: 0,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideBackButton: true,
      styles: {
        buttonNext: {
          backgroundColor: '#3084E9',
          borderRadius: 6,
          color: '#ffffff',
          display: 'block'
        }
      },
      placement: 'bottom',
      target: '#PauseTestID',
      title: '123',
      content: {
        key: null,
        ref: null,
        props: {
          content: {
            Texts: [
              {
                Content: 'This section allows you to manage timeframes for the current chart.',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 165,
                  TopPoint: 25,
                  Width: 260,
                  Height: 180
                }
              }
            ],
            Buttons: [
              {
                EnableButtonRule: true,
                Action: 'Now choose one of',
                Content: 'Next',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 335,
                  TopPoint: 205,
                  Width: 90,
                  Height: 30
                }
              }
            ],
            Images: [
              {
                ImagePath: 'https://4xdev.com:8888/images/Smart%20Martin-7.png',
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 40,
                  Width: 150,
                  Height: 150
                }
              }
            ]
          }
        },
        _owner: null,
        _store: {}
      }
    },
    {
      disableBeacon: true,
      disableScrolling: true,
      spotlightPadding: 0,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideBackButton: false,
      styles: {
        buttonNext: {
          backgroundColor: '#3084E9',
          borderRadius: 6,
          color: '#ffffff',
          display: 'none'
        }
      },
      placement: 'bottom',
      target: '#M5ID',
      title: '123',
      content: {
        key: null,
        ref: null,
        props: {
          content: {
            Texts: [
              {
                Content: 'Now choose one of the timeframes to adjust the chart. \r\n\r\nLet it be M5.',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 165,
                  TopPoint: 25,
                  Width: 260,
                  Height: 180
                }
              }
            ],
            Buttons: [
              {
                EnableButtonRule: true,
                Action: 'This section allows you',
                Content: 'Back',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 205,
                  Width: 90,
                  Height: 30
                }
              }
            ],
            Images: [
              {
                ImagePath: 'https://4xdev.com:8888/images/sirok-1-6.png',
                Scale: {
                  LeftPoint: 275,
                  TopPoint: 40,
                  Width: 151,
                  Height: 151
                }
              }
            ]
          }
        },
        _owner: null,
        _store: {}
      }
    },
    {
      disableBeacon: true,
      disableScrolling: true,
      spotlightPadding: 0,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideBackButton: false,
      styles: {
        buttonNext: {
          backgroundColor: '#3084E9',
          borderRadius: 6,
          color: '#ffffff',
          display: 'none'
        }
      },
      placement: 'bottom',
      target: '#TestingSpeedSlider',
      title: '123',
      content: {
        key: null,
        ref: null,
        props: {
          content: {
            Texts: [
              {
                Content:
                  'You can control the speed of the market movement using this slider. Wouldn’t it be cool if you could do that with the live market, huh?\r\n\r\nCome on, it won’t change by itself!\r\n\r\nWhen you’re ready, click “Finish” to exit the tutorial.',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 165,
                  TopPoint: 25,
                  Width: 260,
                  Height: 220
                }
              }
            ],
            Buttons: [
              {
                EnableButtonRule: true,
                Action: '--> Next Lesson',
                Content: 'Finish',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 335,
                  TopPoint: 245,
                  Width: 90,
                  Height: 30
                }
              },
              {
                EnableButtonRule: true,
                Action: 'Now choose one of',
                Content: 'Back',
                Alignment: 0,
                Ranges: null,
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 245,
                  Width: 90,
                  Height: 30
                }
              }
            ],
            Images: [
              {
                ImagePath: 'https://4xdev.com:8888/images/Gonn%20Abigood-4.png',
                Scale: {
                  LeftPoint: 15,
                  TopPoint: 40,
                  Width: 150,
                  Height: 150
                }
              }
            ]
          }
        },
        _owner: null,
        _store: {}
      }
    }
  ],
  finishedTutorialsCount: 0,
  tutorialsStatus: {
    basicTestingActions: false
  }
})
