export const defaultMomentum = [
  {
    t: 1580693400,
    v: 100.1245433410827
  },
  {
    t: 1580694300,
    v: 100.1873506040442
  },
  {
    t: 1580695200,
    v: 100.13938890427399
  },
  {
    t: 1580696100,
    v: 100.1559803224824
  },
  {
    t: 1580697000,
    v: 100.1227129715915
  },
  {
    t: 1580697900,
    v: 100.08488337762032
  },
  {
    t: 1580698800,
    v: 100.09503510762956
  },
  {
    t: 1580699700,
    v: 100.1319407281652
  },
  {
    t: 1580700600,
    v: 100.0857712030103
  },
  {
    t: 1580701500,
    v: 100.11437320715386
  },
  {
    t: 1580702400,
    v: 99.9861788093505
  },
  {
    t: 1580703300,
    v: 100.02488915109558
  },
  {
    t: 1580704200,
    v: 99.99262598051415
  },
  {
    t: 1580705100,
    v: 100.02305273543759
  },
  {
    t: 1580706000,
    v: 99.96775114944117
  },
  {
    t: 1580706900,
    v: 99.96775857621873
  },
  {
    t: 1580707800,
    v: 100.03134189397223
  },
  {
    t: 1580708700,
    v: 100.00460761546684
  },
  {
    t: 1580709600,
    v: 99.96682516863874
  },
  {
    t: 1580710500,
    v: 100.03226520151922
  },
  {
    t: 1580711400,
    v: 99.99262564064747
  },
  {
    t: 1580712300,
    v: 99.99354987330108
  },
  {
    t: 1580713200,
    v: 100.00737184508066
  },
  {
    t: 1580714100,
    v: 100.07739011064945
  },
  {
    t: 1580715000,
    v: 100.15574027314449
  },
  {
    t: 1580715900,
    v: 100.10506137796291
  },
  {
    t: 1580716800,
    v: 100.04240373890359
  },
  {
    t: 1580717700,
    v: 100.03595397890702
  },
  {
    t: 1580718600,
    v: 100.0608317357322
  },
  {
    t: 1580719500,
    v: 100.0774043733471
  },
  {
    t: 1580720400,
    v: 100.05252730037321
  },
  {
    t: 1580721300,
    v: 100.03040886095779
  },
  {
    t: 1580722200,
    v: 100.04609144542773
  },
  {
    t: 1580723100,
    v: 99.99078434444435
  },
  {
    t: 1580724000,
    v: 100.03318706441976
  },
  {
    t: 1580724900,
    v: 99.99723548167124
  },
  {
    t: 1580725800,
    v: 99.91707285610298
  },
  {
    t: 1580726700,
    v: 99.80575373993096
  },
  {
    t: 1580727600,
    v: 99.8408214716193
  },
  {
    t: 1580728500,
    v: 99.89781075656865
  },
  {
    t: 1580729400,
    v: 99.98341426557447
  },
  {
    t: 1580730300,
    v: 100.00921565555568
  },
  {
    t: 1580731200,
    v: 99.93275731839871
  },
  {
    t: 1580732100,
    v: 99.91989319092123
  },
  {
    t: 1580733000,
    v: 99.9484213240983
  },
  {
    t: 1580733900,
    v: 99.95670357610038
  },
  {
    t: 1580734800,
    v: 99.99539297889984
  },
  {
    t: 1580735700,
    v: 100.05990728196052
  },
  {
    t: 1580736600,
    v: 100.04054851076378
  },
  {
    t: 1580737500,
    v: 100.01935216329541
  },
  {
    t: 1580738400,
    v: 100.06731895351304
  },
  {
    t: 1580739300,
    v: 100.0701016473887
  },
  {
    t: 1580740200,
    v: 100.05068657266611
  },
  {
    t: 1580741100,
    v: 100.10137221111223
  },
  {
    t: 1580742000,
    v: 100.18247334322498
  },
  {
    t: 1580742900,
    v: 100.24419236829738
  },
  {
    t: 1580743800,
    v: 100.21937707970395
  },
  {
    t: 1580744700,
    v: 100.17324314860207
  },
  {
    t: 1580745600,
    v: 100.13362084853846
  },
  {
    t: 1580746500,
    v: 100.09861114951109
  },
  {
    t: 1580747400,
    v: 100.02303616678184
  },
  {
    t: 1580748300,
    v: 100.02026417110328
  },
  {
    t: 1580749200,
    v: 100.0230295884152
  },
  {
    t: 1580750100,
    v: 100.07002284956144
  },
  {
    t: 1580751000,
    v: 100.1161161899145
  },
  {
    t: 1580751900,
    v: 100.15577472578119
  },
  {
    t: 1580752800,
    v: 100.03408096532031
  },
  {
    t: 1580753700,
    v: 100
  },
  {
    t: 1580754600,
    v: 99.9475654741645
  },
  {
    t: 1580755500,
    v: 99.88877245233763
  },
  {
    t: 1580756400,
    v: 99.92274228112612
  },
  {
    t: 1580757300,
    v: 99.94388534211542
  },
  {
    t: 1580758200,
    v: 99.99355794627327
  },
  {
    t: 1580759100,
    v: 100.0524794225422
  },
  {
    t: 1580760000,
    v: 100.12805158912943
  },
  {
    t: 1580760900,
    v: 100.12708586584154
  },
  {
    t: 1580761800,
    v: 100.10591171567769
  },
  {
    t: 1580762700,
    v: 100.06352889183516
  },
  {
    t: 1580763600,
    v: 100.04510392312082
  },
  {
    t: 1580764500,
    v: 100.01104372394371
  },
  {
    t: 1580765400,
    v: 100.06353474153332
  },
  {
    t: 1580766300,
    v: 100.03682529161028
  },
  {
    t: 1580767200,
    v: 100.01564657156005
  },
  {
    t: 1580768100,
    v: 99.99631896194727
  },
  {
    t: 1580769000,
    v: 100
  },
  {
    t: 1580769900,
    v: 100.0119655759584
  },
  {
    t: 1580770800,
    v: 99.95030095532607
  },
  {
    t: 1580771700,
    v: 99.94110664298663
  },
  {
    t: 1580772600,
    v: 99.93283588955644
  },
  {
    t: 1580773500,
    v: 99.8951492743226
  },
  {
    t: 1580774400,
    v: 99.94296018252741
  },
  {
    t: 1580775300,
    v: 99.96411516272393
  },
  {
    t: 1580776200,
    v: 99.94663575220588
  },
  {
    t: 1580777100,
    v: 99.92270246891994
  },
  {
    t: 1580778000,
    v: 99.91718121669994
  },
  {
    t: 1580778900,
    v: 99.93005770239552
  },
  {
    t: 1580779800,
    v: 99.97423320787358
  },
  {
    t: 1580780700,
    v: 100.0312899752441
  },
  {
    t: 1580781600,
    v: 100.01380668795964
  },
  {
    t: 1580782500,
    v: 99.98527489922509
  },
  {
    t: 1580783400,
    v: 100.0524861878453
  },
  {
    t: 1580784300,
    v: 100.04787905015331
  },
  {
    t: 1580785200,
    v: 100.03038226412315
  },
  {
    t: 1580786100,
    v: 100.05708393179391
  },
  {
    t: 1580787000,
    v: 100.075482813852
  },
  {
    t: 1580787900,
    v: 100.04326135380424
  },
  {
    t: 1580788800,
    v: 100.06536007880031
  },
  {
    t: 1580789700,
    v: 100.15011005000598
  },
  {
    t: 1580790600,
    v: 100.13906668754203
  },
  {
    t: 1580791500,
    v: 100.13353593958652
  },
  {
    t: 1580792400,
    v: 100.07824077909406
  },
  {
    t: 1580793300,
    v: 100.0496802980818
  },
  {
    t: 1580794200,
    v: 100.15737451453181
  },
  {
    t: 1580795100,
    v: 100.1537158741555
  },
  {
    t: 1580796000,
    v: 100.13252712664624
  },
  {
    t: 1580796900,
    v: 100.15461213521199
  },
  {
    t: 1580797800,
    v: 100.20708888254839
  },
  {
    t: 1580798700,
    v: 100.21532289232013
  },
  {
    t: 1580799600,
    v: 100.15729055520808
  },
  {
    t: 1580800500,
    v: 100.14444884026904
  },
  {
    t: 1580801400,
    v: 100.15363385464582
  },
  {
    t: 1580802300,
    v: 100.17011494252874
  },
  {
    t: 1580803200,
    v: 100.18945664569767
  },
  {
    t: 1580804100,
    v: 100.28786903338545
  },
  {
    t: 1580805000,
    v: 100.28328611898016
  },
  {
    t: 1580805900,
    v: 100.25747363194144
  },
  {
    t: 1580806800,
    v: 100.19204439993017
  },
  {
    t: 1580807700,
    v: 100.21689382312124
  },
  {
    t: 1580808600,
    v: 100.23345373663845
  },
  {
    t: 1580809500,
    v: 100.23156018267525
  },
  {
    t: 1580810400,
    v: 100.17818762973714
  },
  {
    t: 1580811300,
    v: 100.11477577404781
  },
  {
    t: 1580812200,
    v: 100.12306335926236
  },
  {
    t: 1580813100,
    v: 100.12127225621519
  },
  {
    t: 1580814000,
    v: 100.14053845517925
  },
  {
    t: 1580814900,
    v: 100.07802818194335
  },
  {
    t: 1580815800,
    v: 100.087205566469
  },
  {
    t: 1580816700,
    v: 99.98440981997928
  },
  {
    t: 1580817600,
    v: 100.01467459094577
  },
  {
    t: 1580818500,
    v: 100.04035623549699
  },
  {
    t: 1580819400,
    v: 100.0467726847521
  },
  {
    t: 1580820300,
    v: 100.07428126003026
  },
  {
    t: 1580821200,
    v: 100.02842602356607
  },
  {
    t: 1580822100,
    v: 100.0302533026522
  },
  {
    t: 1580823000,
    v: 100.09627021674552
  },
  {
    t: 1580823900,
    v: 100.13390441425992
  },
  {
    t: 1580824800,
    v: 100.11098779134295
  },
  {
    t: 1580825700,
    v: 100.16608856833491
  },
  {
    t: 1580826600,
    v: 100.14676206200699
  },
  {
    t: 1580827500,
    v: 100.20546688680976
  },
  {
    t: 1580828400,
    v: 100.1632533269744
  },
  {
    t: 1580829300,
    v: 100.29809403261606
  },
  {
    t: 1580830200,
    v: 100.25676766194704
  },
  {
    t: 1580831100,
    v: 100.23562201461407
  },
  {
    t: 1580832000,
    v: 100.19342005151756
  },
  {
    t: 1580832900,
    v: 100.2556677602038
  },
  {
    t: 1580833800,
    v: 100.31351410813487
  },
  {
    t: 1580834700,
    v: 100.26394896986584
  },
  {
    t: 1580835600,
    v: 100.23082630320684
  },
  {
    t: 1580836500,
    v: 100.24913215911484
  },
  {
    t: 1580837400,
    v: 100.27395503106047
  },
  {
    t: 1580838300,
    v: 100.27208017662308
  },
  {
    t: 1580839200,
    v: 100.2418025279355
  },
  {
    t: 1580840100,
    v: 100.18673794441798
  },
  {
    t: 1580841000,
    v: 100.25730008881891
  },
  {
    t: 1580841900,
    v: 100.1115673382045
  },
  {
    t: 1580842800,
    v: 100.1591541050783
  },
  {
    t: 1580843700,
    v: 100.14726058721301
  },
  {
    t: 1580844600,
    v: 100.20677035681611
  },
  {
    t: 1580845500,
    v: 100.08226315067867
  },
  {
    t: 1580846400,
    v: 100.07402127426252
  },
  {
    t: 1580847300,
    v: 100.12248628884825
  },
  {
    t: 1580848200,
    v: 100.05209047292666
  },
  {
    t: 1580849100,
    v: 100.0137047628619
  },
  {
    t: 1580850000,
    v: 100.03654937363511
  },
  {
    t: 1580850900,
    v: 100.05573015640988
  },
  {
    t: 1580851800,
    v: 100.08406125507108
  },
  {
    t: 1580852700,
    v: 100.06121628535927
  },
  {
    t: 1580853600,
    v: 100.00821977861396
  },
  {
    t: 1580854500,
    v: 100.00182693449527
  },
  {
    t: 1580855400,
    v: 99.95799163485599
  },
  {
    t: 1580856300,
    v: 99.97077385355875
  },
  {
    t: 1580857200,
    v: 99.95252268867667
  },
  {
    t: 1580858100,
    v: 99.98538746061463
  },
  {
    t: 1580859000,
    v: 99.94612314969547
  },
  {
    t: 1580859900,
    v: 99.91144302225791
  },
  {
    t: 1580860800,
    v: 99.97990537257266
  },
  {
    t: 1580861700,
    v: 99.92417737014233
  },
  {
    t: 1580862600,
    v: 99.92510115910524
  },
  {
    t: 1580863500,
    v: 99.90503757407527
  },
  {
    t: 1580864400,
    v: 99.91509640666082
  },
  {
    t: 1580865300,
    v: 99.9013833721408
  },
  {
    t: 1580866200,
    v: 99.9196354371193
  },
  {
    t: 1580867100,
    v: 100.00365380223796
  },
  {
    t: 1580868000,
    v: 100.00730887297178
  },
  {
    t: 1580868900,
    v: 100.00456792040855
  },
  {
    t: 1580869800,
    v: 99.99817308219302
  },
  {
    t: 1580870700,
    v: 99.97533773600416
  },
  {
    t: 1580871600,
    v: 99.9671082686158
  },
  {
    t: 1580872500,
    v: 99.97350073558303
  },
  {
    t: 1580873400,
    v: 99.98172848529143
  },
  {
    t: 1580874300,
    v: 100.05302469305101
  },
  {
    t: 1580875200,
    v: 100.04936059744605
  },
  {
    t: 1580876100,
    v: 100.05301015418644
  },
  {
    t: 1580877000,
    v: 100.0173606352165
  },
  {
    t: 1580877900,
    v: 100.05392707962014
  },
  {
    t: 1580878800,
    v: 100.0329028543226
  },
  {
    t: 1580879700,
    v: 99.9561559751185
  },
  {
    t: 1580880600,
    v: 99.95980413651976
  },
  {
    t: 1580881500,
    v: 99.96619893298254
  },
  {
    t: 1580882400,
    v: 99.96802835428238
  },
  {
    t: 1580883300,
    v: 99.97533165223112
  },
  {
    t: 1580884200,
    v: 99.99360228124371
  },
  {
    t: 1580885100,
    v: 99.99817198011115
  },
  {
    t: 1580886000,
    v: 99.90679824561404
  },
  {
    t: 1580886900,
    v: 99.89400681645819
  },
  {
    t: 1580887800,
    v: 99.89675933962523
  },
  {
    t: 1580888700,
    v: 99.87119876496972
  },
  {
    t: 1580889600,
    v: 99.89768138714804
  },
  {
    t: 1580890500,
    v: 99.95523724261415
  },
  {
    t: 1580891400,
    v: 100.10233076592752
  },
  {
    t: 1580892300,
    v: 100.10965814074623
  },
  {
    t: 1580893200,
    v: 100.12429171997806
  },
  {
    t: 1580894100,
    v: 100.18368409990221
  },
  {
    t: 1580895000,
    v: 100.22844193462905
  },
  {
    t: 1580895900,
    v: 100.24217500571166
  },
  {
    t: 1580896800,
    v: 100.24130083084263
  },
  {
    t: 1580897700,
    v: 100.20474197027585
  },
  {
    t: 1580898600,
    v: 100.28260988860234
  },
  {
    t: 1580899500,
    v: 100.31191401783673
  },
  {
    t: 1580900400,
    v: 100.29906713005306
  },
  {
    t: 1580901300,
    v: 100.34116893807739
  },
  {
    t: 1580902200,
    v: 100.21856424325561
  },
  {
    t: 1580903100,
    v: 100.13891808402717
  },
  {
    t: 1580904000,
    v: 100.05750221337884
  },
  {
    t: 1580904900,
    v: 100.08854323557064
  },
  {
    t: 1580905800,
    v: 100.0976669465844
  },
  {
    t: 1580906700,
    v: 100.0501696646842
  },
  {
    t: 1580907600,
    v: 100.01914538641772
  },
  {
    t: 1580908500,
    v: 100.04649466678823
  },
  {
    t: 1580909400,
    v: 100.08935817125769
  },
  {
    t: 1580910300,
    v: 100.12770227127612
  },
  {
    t: 1580911200,
    v: 100.08116957144291
  },
  {
    t: 1580912100,
    v: 100.11762989440666
  },
  {
    t: 1580913000,
    v: 100.07112440387719
  },
  {
    t: 1580913900,
    v: 100.09206676207579
  },
  {
    t: 1580914800,
    v: 100.16151256056722
  },
  {
    t: 1580915700,
    v: 100.10860736157126
  },
  {
    t: 1580916600,
    v: 100.08939648252206
  },
  {
    t: 1580917500,
    v: 100.0939369619145
  },
  {
    t: 1580918400,
    v: 100.03738726826732
  },
  {
    t: 1580919300,
    v: 100.00729374652406
  },
  {
    t: 1580920200,
    v: 99.98359281000474
  },
  {
    t: 1580921100,
    v: 99.99726629062977
  },
  {
    t: 1580922000,
    v: 99.97813590357933
  },
  {
    t: 1580922900,
    v: 99.96082718411225
  },
  {
    t: 1580923800,
    v: 100.00729022380987
  },
  {
    t: 1580924700,
    v: 99.96630083337128
  },
  {
    t: 1580925600,
    v: 100.00637842270719
  },
  {
    t: 1580926500,
    v: 99.94080361371171
  },
  {
    t: 1580927400,
    v: 99.99635588433577
  },
  {
    t: 1580928300,
    v: 100.06381737291225
  },
  {
    t: 1580929200,
    v: 100.03463298153517
  },
  {
    t: 1580930100,
    v: 100.03189037001941
  },
  {
    t: 1580931000,
    v: 100.09935827317145
  },
  {
    t: 1580931900,
    v: 100.12580795142718
  },
  {
    t: 1580932800,
    v: 100.11669249703709
  },
  {
    t: 1580933700,
    v: 100.07198964807085
  },
  {
    t: 1580934600,
    v: 100.06742904004737
  },
  {
    t: 1580935500,
    v: 100.08566715575928
  },
  {
    t: 1580936400,
    v: 100.08018661612479
  },
  {
    t: 1580937300,
    v: 100.05648790976511
  },
  {
    t: 1580938200,
    v: 100.06924702966688
  },
  {
    t: 1580939100,
    v: 100.06652147367845
  },
  {
    t: 1580940000,
    v: 100.03279823618374
  },
  {
    t: 1580940900,
    v: 100.00273328595637
  },
  {
    t: 1580941800,
    v: 100.03279883381924
  },
  {
    t: 1580942700,
    v: 100.00273258885468
  },
  {
    t: 1580943600,
    v: 99.98816169306002
  },
  {
    t: 1580944500,
    v: 99.97268481002286
  },
  {
    t: 1580945400,
    v: 99.96903968384055
  },
  {
    t: 1580946300,
    v: 99.99635757669579
  },
  {
    t: 1580947200,
    v: 100.02549649878436
  },
  {
    t: 1580948100,
    v: 100.04279691497983
  },
  {
    t: 1580949000,
    v: 100.03641925850391
  },
  {
    t: 1580949900,
    v: 100.01456929521035
  },
  {
    t: 1580950800,
    v: 100.00182102924573
  },
  {
    t: 1580951700,
    v: 100.05099624813316
  },
  {
    t: 1580952600,
    v: 100.03005519226215
  },
  {
    t: 1580953500,
    v: 100.05921957708111
  },
  {
    t: 1580954400,
    v: 100.061022259463
  },
  {
    t: 1580955300,
    v: 100.07104536884388
  },
  {
    t: 1580956200,
    v: 100.07559198542806
  },
  {
    t: 1580957100,
    v: 100.07103889835061
  },
  {
    t: 1580958000,
    v: 100.081979159076
  },
  {
    t: 1580958900,
    v: 100.0373361988107
  },
  {
    t: 1580959800,
    v: 100.0291314282593
  },
  {
    t: 1580960700,
    v: 100.01820366257692
  },
  {
    t: 1580961600,
    v: 100.06644094946846
  },
  {
    t: 1580962500,
    v: 100.08831348556029
  },
  {
    t: 1580963400,
    v: 100.10015478466721
  },
  {
    t: 1580964300,
    v: 100.07645538282304
  },
  {
    t: 1580965200,
    v: 100.10925877029253
  },
  {
    t: 1580966100,
    v: 100.11927958771147
  },
  {
    t: 1580967000,
    v: 100.08556110792533
  },
  {
    t: 1580967900,
    v: 100.03276689087714
  },
  {
    t: 1580968800,
    v: 100.05733370949102
  },
  {
    t: 1580969700,
    v: 100.07280868607624
  },
  {
    t: 1580970600,
    v: 100.04914720498026
  },
  {
    t: 1580971500,
    v: 100.03823256322029
  },
  {
    t: 1580972400,
    v: 99.98998898788668
  },
  {
    t: 1580973300,
    v: 99.99362987769365
  },
  {
    t: 1580974200,
    v: 99.9254172540816
  },
  {
    t: 1580975100,
    v: 99.97362029599847
  },
  {
    t: 1580976000,
    v: 99.95452064762598
  },
  {
    t: 1580976900,
    v: 99.97817229336438
  },
  {
    t: 1580977800,
    v: 99.97635310274578
  },
  {
    t: 1580978700,
    v: 99.98362996444065
  },
  {
    t: 1580979600,
    v: 99.9290631792428
  },
  {
    t: 1580980500,
    v: 99.89991174035285
  },
  {
    t: 1580981400,
    v: 99.89631273534279
  },
  {
    t: 1580982300,
    v: 99.89723255454405
  },
  {
    t: 1580983200,
    v: 99.86354704897752
  },
  {
    t: 1580984100,
    v: 99.91992429205794
  },
  {
    t: 1580985000,
    v: 99.95267047730005
  },
  {
    t: 1580985900,
    v: 99.91718313448186
  },
  {
    t: 1580986800,
    v: 99.9708728143233
  },
  {
    t: 1580987700,
    v: 99.95632552045421
  },
  {
    t: 1580988600,
    v: 100.00364000364002
  },
  {
    t: 1580989500,
    v: 99.9909031365985
  },
  {
    t: 1580990400,
    v: 99.99363202183308
  },
  {
    t: 1580991300,
    v: 99.94633387605855
  },
  {
    t: 1580992200,
    v: 100.01729174819573
  },
  {
    t: 1580993100,
    v: 100.05100507318316
  },
  {
    t: 1580994000,
    v: 100.00455240731299
  },
  {
    t: 1580994900,
    v: 100.04642948181058
  },
  {
    t: 1580995800,
    v: 100.11386616626281
  },
  {
    t: 1580996700,
    v: 100.06192627130991
  },
  {
    t: 1580997600,
    v: 100.08104465651635
  },
  {
    t: 1580998500,
    v: 100.0865288277621
  },
  {
    t: 1580999400,
    v: 100.00455244876218
  },
  {
    t: 1581000300,
    v: 100.06463006117099
  },
  {
    t: 1581001200,
    v: 100.03275884033704
  },
  {
    t: 1581002100,
    v: 99.9972706926982
  },
  {
    t: 1581003000,
    v: 100.01091722920722
  },
  {
    t: 1581003900,
    v: 100.07917728431015
  },
  {
    t: 1581004800,
    v: 100.05277621066809
  },
  {
    t: 1581005700,
    v: 100.07009622299702
  },
  {
    t: 1581006600,
    v: 100.08011872137803
  },
  {
    t: 1581007500,
    v: 99.995450202466
  },
  {
    t: 1581008400,
    v: 100.00727914615615
  },
  {
    t: 1581009300,
    v: 100.07371946557937
  },
  {
    t: 1581010200,
    v: 100.04185432873844
  },
  {
    t: 1581011100,
    v: 100.07280338535742
  },
  {
    t: 1581012000,
    v: 100.12200007283587
  },
  {
    t: 1581012900,
    v: 100.02274236538793
  },
  {
    t: 1581013800,
    v: 100.02547075411626
  },
  {
    t: 1581014700,
    v: 100.05003866624209
  },
  {
    t: 1581015600,
    v: 100.05276084781222
  },
  {
    t: 1581016500,
    v: 100.01091236461848
  },
  {
    t: 1581017400,
    v: 100.02091745789224
  },
  {
    t: 1581018300,
    v: 100.04002692720557
  },
  {
    t: 1581019200,
    v: 100.02820104616785
  },
  {
    t: 1581020100,
    v: 100.05460005460006
  },
  {
    t: 1581021000,
    v: 100.05004048730335
  },
  {
    t: 1581021900,
    v: 100.025464499759
  },
  {
    t: 1581022800,
    v: 100.022737401206
  },
  {
    t: 1581023700,
    v: 100.00636566180148
  },
  {
    t: 1581024600,
    v: 100.01545876148039
  },
  {
    t: 1581025500,
    v: 100.02819412107102
  },
  {
    t: 1581026400,
    v: 100.04638134560469
  },
  {
    t: 1581027300,
    v: 100.01182140583795
  },
  {
    t: 1581028200,
    v: 99.9972724297196
  },
  {
    t: 1581029100,
    v: 99.99818147100811
  },
  {
    t: 1581030000,
    v: 100.0018185289919
  },
  {
    t: 1581030900,
    v: 100.01273074474857
  },
  {
    t: 1581031800,
    v: 100.00818509221871
  },
  {
    t: 1581032700,
    v: 100.00636653024102
  },
  {
    t: 1581033600,
    v: 99.9818125602459
  },
  {
    t: 1581034500,
    v: 99.95544847024594
  },
  {
    t: 1581035400,
    v: 99.8736087873718
  },
  {
    t: 1581036300,
    v: 99.90361182846543
  },
  {
    t: 1581037200,
    v: 99.88635020502423
  },
  {
    t: 1581038100,
    v: 99.90543993162579
  },
  {
    t: 1581039000,
    v: 99.90455326382387
  },
  {
    t: 1581039900,
    v: 99.93180764299937
  },
  {
    t: 1581040800,
    v: 99.92999045324362
  },
  {
    t: 1581041700,
    v: 99.94908026223666
  },
  {
    t: 1581042600,
    v: 99.9518098580664
  },
  {
    t: 1581043500,
    v: 99.91907913878383
  },
  {
    t: 1581044400,
    v: 99.94998408584549
  },
  {
    t: 1581045300,
    v: 99.94543321480216
  },
  {
    t: 1581046200,
    v: 99.9654375784476
  },
  {
    t: 1581047100,
    v: 99.96998253529325
  },
  {
    t: 1581048000,
    v: 100.05280552090825
  },
  {
    t: 1581048900,
    v: 100.04368958549506
  },
  {
    t: 1581049800,
    v: 100.03731954633996
  },
  {
    t: 1581050700,
    v: 100.03003303633997
  },
  {
    t: 1581051600,
    v: 99.99454069005678
  },
  {
    t: 1581052500,
    v: 99.98453251810605
  },
  {
    t: 1581053400,
    v: 99.9881719256105
  },
  {
    t: 1581054300,
    v: 99.96088099635192
  },
  {
    t: 1581055200,
    v: 99.96270286005385
  },
  {
    t: 1581056100,
    v: 100.06460712498293
  },
  {
    t: 1581057000,
    v: 100.0363934127923
  },
  {
    t: 1581057900,
    v: 100.06096617742067
  },
  {
    t: 1581058800,
    v: 100.05004185318629
  },
  {
    t: 1581059700,
    v: 100.0054593596171
  },
  {
    t: 1581060600,
    v: 100.00636971654761
  },
  {
    t: 1581061500,
    v: 100.00363920883599
  },
  {
    t: 1581062400,
    v: 99.99454064038288
  },
  {
    t: 1581063300,
    v: 99.97270543707694
  },
  {
    t: 1581064200,
    v: 100.03275764800088
  },
  {
    t: 1581065100,
    v: 100.03912968304955
  },
  {
    t: 1581066000,
    v: 100.02729878520407
  },
  {
    t: 1581066900,
    v: 99.98543839531115
  },
  {
    t: 1581067800,
    v: 99.96177891834338
  },
  {
    t: 1581068700,
    v: 99.82812869432371
  },
  {
    t: 1581069600,
    v: 99.78990450204638
  },
  {
    t: 1581070500,
    v: 99.81175657487907
  },
  {
    t: 1581071400,
    v: 99.817211243782
  },
  {
    t: 1581072300,
    v: 99.86716283470871
  },
  {
    t: 1581073200,
    v: 99.90082073119689
  },
  {
    t: 1581074100,
    v: 99.91903055004639
  },
  {
    t: 1581075000,
    v: 99.92629462132724
  },
  {
    t: 1581075900,
    v: 99.94448590305964
  },
  {
    t: 1581076800,
    v: 99.92995797478488
  },
  {
    t: 1581077700,
    v: 99.89084359706733
  },
  {
    t: 1581078600,
    v: 99.92813281783035
  },
  {
    t: 1581079500,
    v: 100.0045511641878
  },
  {
    t: 1581080400,
    v: 99.99726887887478
  },
  {
    t: 1581081300,
    v: 100.08745001229767
  },
  {
    t: 1581082200,
    v: 100.04830521605193
  },
  {
    t: 1581083100,
    v: 100.01002213981795
  },
  {
    t: 1581084000,
    v: 100
  },
  {
    t: 1581084900,
    v: 99.95718047065039
  },
  {
    t: 1581085800,
    v: 99.79871212190213
  },
  {
    t: 1581086700,
    v: 99.76691037886168
  },
  {
    t: 1581087600,
    v: 99.80330735047716
  },
  {
    t: 1581088500,
    v: 99.82608062210325
  },
  {
    t: 1581089400,
    v: 99.82977871232602
  },
  {
    t: 1581090300,
    v: 99.9599322490757
  },
  {
    t: 1581091200,
    v: 99.91260491961474
  },
  {
    t: 1581092100,
    v: 99.95540062075054
  },
  {
    t: 1581093000,
    v: 99.94992807851278
  },
  {
    t: 1581093900,
    v: 99.93446979694738
  },
  {
    t: 1581094800,
    v: 100.04554895601791
  },
  {
    t: 1581095700,
    v: 100.04463960352743
  },
  {
    t: 1581096600,
    v: 100.02186549078915
  },
  {
    t: 1581097500,
    v: 100.07747274782166
  },
  {
    t: 1581098400,
    v: 100.20625707297484
  },
  {
    t: 1581099300,
    v: 100.19986675549634
  },
  {
    t: 1581100200,
    v: 100.15602189781023
  },
  {
    t: 1581101100,
    v: 100.10124965794034
  },
  {
    t: 1581102000,
    v: 100.03647305553022
  },
  {
    t: 1581102900,
    v: 99.95809419695728
  },
  {
    t: 1581103800,
    v: 100
  },
  {
    t: 1581104700,
    v: 99.91622502686262
  },
  {
    t: 1581105600,
    v: 99.95992239518338
  },
  {
    t: 1581106500,
    v: 99.96539193632117
  },
  {
    t: 1581107400,
    v: 99.94081331609331
  },
  {
    t: 1581108300,
    v: 99.94991667956693
  },
  {
    t: 1581109200,
    v: 99.96447634488914
  },
  {
    t: 1581110100,
    v: 99.9644811978033
  },
  {
    t: 1581111000,
    v: 99.97358786134538
  },
  {
    t: 1581285600,
    v: 99.92713494607986
  },
  {
    t: 1581286500,
    v: 99.94260779258639
  },
  {
    t: 1581287400,
    v: 99.99726629062977
  },
  {
    t: 1581288300,
    v: 100.00091149393857
  },
  {
    t: 1581289200,
    v: 100.01458204221501
  },
  {
    t: 1581290100,
    v: 99.9526195899772
  },
  {
    t: 1581291000,
    v: 99.89701620399906
  },
  {
    t: 1581291900,
    v: 99.88063019964827
  },
  {
    t: 1581292800,
    v: 99.85969771234386
  },
  {
    t: 1581293700,
    v: 99.9189117778365
  },
  {
    t: 1581294600,
    v: 99.93167034128386
  },
  {
    t: 1581295500,
    v: 99.90979252280245
  },
  {
    t: 1581296400,
    v: 99.90251635356499
  },
  {
    t: 1581297300,
    v: 99.91618763038745
  },
  {
    t: 1581298200,
    v: 100.05742307131398
  },
  {
    t: 1581299100,
    v: 100.06289422831516
  },
  {
    t: 1581300000,
    v: 100.08748109132661
  },
  {
    t: 1581300900,
    v: 100.10482084749934
  },
  {
    t: 1581301800,
    v: 100.09385821031529
  },
  {
    t: 1581302700,
    v: 100.11850717424203
  },
  {
    t: 1581303600,
    v: 100.20070612062437
  },
  {
    t: 1581304500,
    v: 100.16968945006022
  },
  {
    t: 1581305400,
    v: 100.17516809751024
  },
  {
    t: 1581306300,
    v: 100.08480140059086
  },
  {
    t: 1581307200,
    v: 100.06472963979323
  },
  {
    t: 1581308100,
    v: 100.09849700860937
  },
  {
    t: 1581309000,
    v: 100.10305047649446
  },
  {
    t: 1581309900,
    v: 100.0465001777948
  },
  {
    t: 1581310800,
    v: 99.9726713732635
  },
  {
    t: 1581311700,
    v: 99.99089062371898
  },
  {
    t: 1581312600,
    v: 99.94992443141467
  },
  {
    t: 1581313500,
    v: 99.94992078378526
  },
  {
    t: 1581314400,
    v: 99.9572116566372
  },
  {
    t: 1581315300,
    v: 99.99180536839421
  },
  {
    t: 1581316200,
    v: 99.9781486438502
  },
  {
    t: 1581317100,
    v: 100.01730450463577
  },
  {
    t: 1581318000,
    v: 99.97814227557126
  },
  {
    t: 1581318900,
    v: 100.04282030958174
  },
  {
    t: 1581319800,
    v: 100.05648790976511
  },
  {
    t: 1581320700,
    v: 100.03644447683955
  },
  {
    t: 1581321600,
    v: 100.05921580059763
  },
  {
    t: 1581322500,
    v: 100.03554243219597
  },
  {
    t: 1581323400,
    v: 99.98086473187846
  },
  {
    t: 1581324300,
    v: 100.00911020616397
  },
  {
    t: 1581325200,
    v: 100.01002013135482
  },
  {
    t: 1581326100,
    v: 99.9972670377422
  },
  {
    t: 1581327000,
    v: 99.95901490036067
  },
  {
    t: 1581327900,
    v: 99.91167284349703
  },
  {
    t: 1581328800,
    v: 99.94171698130391
  },
  {
    t: 1581329700,
    v: 99.9171348698289
  },
  {
    t: 1581330600,
    v: 99.9990890623719
  },
  {
    t: 1581331500,
    v: 99.9590193792802
  },
  {
    t: 1581332400,
    v: 99.95173920961574
  },
  {
    t: 1581333300,
    v: 100
  },
  {
    t: 1581334200,
    v: 99.96267059991078
  },
  {
    t: 1581335100,
    v: 100.004555103082
  },
  {
    t: 1581336000,
    v: 100.04556888192191
  },
  {
    t: 1581336900,
    v: 99.981781247438
  },
  {
    t: 1581337800,
    v: 99.967210128427
  },
  {
    t: 1581338700,
    v: 99.97540266744406
  },
  {
    t: 1581339600,
    v: 99.97995462455921
  },
  {
    t: 1581340500,
    v: 100.02916461602962
  },
  {
    t: 1581341400,
    v: 99.99726639026835
  },
  {
    t: 1581342300,
    v: 100.00729088821245
  },
  {
    t: 1581343200,
    v: 99.89524121848127
  },
  {
    t: 1581344100,
    v: 99.90525040314131
  },
  {
    t: 1581345000,
    v: 99.95809305164578
  },
  {
    t: 1581345900,
    v: 99.97085504025647
  },
  {
    t: 1581346800,
    v: 99.92986738560187
  },
  {
    t: 1581347700,
    v: 99.94260831541742
  },
  {
    t: 1581348600,
    v: 99.96356149907993
  },
  {
    t: 1581349500,
    v: 99.97266689140557
  },
  {
    t: 1581350400,
    v: 100
  },
  {
    t: 1581351300,
    v: 100.03189327598618
  },
  {
    t: 1581352200,
    v: 100.03371943606523
  },
  {
    t: 1581353100,
    v: 100.00728902819031
  },
  {
    t: 1581354000,
    v: 100.00273368445991
  },
  {
    t: 1581354900,
    v: 99.98268540288335
  },
  {
    t: 1581355800,
    v: 100.0091190122286
  },
  {
    t: 1581356700,
    v: 100.01915027494323
  },
  {
    t: 1581357600,
    v: 99.98450615652426
  },
  {
    t: 1581358500,
    v: 99.92438322218578
  },
  {
    t: 1581359400,
    v: 99.96901061842046
  },
  {
    t: 1581360300,
    v: 99.97174343034754
  },
  {
    t: 1581361200,
    v: 99.94441103030967
  },
  {
    t: 1581362100,
    v: 99.95169830579529
  },
  {
    t: 1581363000,
    v: 99.91526504728758
  },
  {
    t: 1581363900,
    v: 99.92348054219501
  },
  {
    t: 1581364800,
    v: 99.937139004792
  },
  {
    t: 1581365700,
    v: 99.97449026074598
  },
  {
    t: 1581366600,
    v: 99.99362157729281
  },
  {
    t: 1581367500,
    v: 100.02278630998495
  },
  {
    t: 1581368400,
    v: 100.07112180977651
  },
  {
    t: 1581369300,
    v: 100.0647337709701
  },
  {
    t: 1581370200,
    v: 100.04193101436594
  },
  {
    t: 1581371100,
    v: 100.08023267475681
  },
  {
    t: 1581372000,
    v: 100.06199797594843
  },
  {
    t: 1581372900,
    v: 100.07202903043455
  },
  {
    t: 1581373800,
    v: 100.08206213015055
  },
  {
    t: 1581374700,
    v: 100.06929627806042
  },
  {
    t: 1581375600,
    v: 100.07842350516592
  },
  {
    t: 1581376500,
    v: 100.07019655034097
  },
  {
    t: 1581377400,
    v: 100.05925412727785
  },
  {
    t: 1581378300,
    v: 100.02642754296755
  },
  {
    t: 1581379200,
    v: 100.00729009094388
  },
  {
    t: 1581380100,
    v: 100.00546746856205
  },
  {
    t: 1581381000,
    v: 100.02004574073568
  },
  {
    t: 1581381900,
    v: 100.05284689888929
  },
  {
    t: 1581382800,
    v: 100.05375854214122
  },
  {
    t: 1581383700,
    v: 100.04919421694649
  },
  {
    t: 1581384600,
    v: 100.05558137203985
  },
  {
    t: 1581385500,
    v: 100.07653270406442
  },
  {
    t: 1581386400,
    v: 100.06741798238022
  },
  {
    t: 1581387300,
    v: 100.11025056947607
  },
  {
    t: 1581388200,
    v: 100.12756612937028
  },
  {
    t: 1581389100,
    v: 100.09201140577031
  },
  {
    t: 1581390000,
    v: 100.09383939797014
  },
  {
    t: 1581390900,
    v: 100.07743957435564
  },
  {
    t: 1581391800,
    v: 100.11116578280756
  },
  {
    t: 1581392700,
    v: 100.11207697774861
  },
  {
    t: 1581393600,
    v: 100.0801668928952
  },
  {
    t: 1581394500,
    v: 100.04644428052345
  },
  {
    t: 1581395400,
    v: 100.06192570736461
  },
  {
    t: 1581396300,
    v: 100.0482594720596
  },
  {
    t: 1581397200,
    v: 100.06647846279937
  },
  {
    t: 1581398100,
    v: 100.0455203430413
  },
  {
    t: 1581399000,
    v: 100.03550716061072
  },
  {
    t: 1581399900,
    v: 100.01638284897744
  },
  {
    t: 1581400800,
    v: 100.02366066959694
  },
  {
    t: 1581401700,
    v: 100.04368799490307
  },
  {
    t: 1581402600,
    v: 100.04186956719612
  },
  {
    t: 1581403500,
    v: 100.0409656980555
  },
  {
    t: 1581404400,
    v: 100.02275457822114
  },
  {
    t: 1581405300,
    v: 100.01729332204717
  },
  {
    t: 1581406200,
    v: 100.00273077308186
  },
  {
    t: 1581407100,
    v: 100.04278172219188
  },
  {
    t: 1581408000,
    v: 100.02821336585454
  },
  {
    t: 1581408900,
    v: 100.02912373948816
  },
  {
    t: 1581409800,
    v: 99.9899893523111
  },
  {
    t: 1581410700,
    v: 99.98362013267692
  },
  {
    t: 1581411600,
    v: 99.96905602679384
  },
  {
    t: 1581412500,
    v: 99.97087970588503
  },
  {
    t: 1581413400,
    v: 99.95268985470327
  },
  {
    t: 1581414300,
    v: 99.94632362306446
  },
  {
    t: 1581415200,
    v: 99.94359072340349
  },
  {
    t: 1581416100,
    v: 99.96724086156534
  },
  {
    t: 1581417000,
    v: 99.9845304068503
  },
  {
    t: 1581417900,
    v: 99.98361968549796
  },
  {
    t: 1581418800,
    v: 100.00091023283757
  },
  {
    t: 1581419700,
    v: 99.93812950949439
  },
  {
    t: 1581420600,
    v: 99.93631036867198
  },
  {
    t: 1581421500,
    v: 99.94267933180478
  },
  {
    t: 1581422400,
    v: 99.970875200233
  },
  {
    t: 1581423300,
    v: 99.96177404821931
  },
  {
    t: 1581424200,
    v: 99.97359844139766
  },
  {
    t: 1581425100,
    v: 99.98452533748419
  },
  {
    t: 1581426000,
    v: 99.96541083733081
  },
  {
    t: 1581426900,
    v: 99.93719221911725
  },
  {
    t: 1581427800,
    v: 99.91624866862693
  },
  {
    t: 1581428700,
    v: 99.92626778448346
  },
  {
    t: 1581429600,
    v: 99.90443772980451
  },
  {
    t: 1581430500,
    v: 99.9335578410849
  },
  {
    t: 1581431400,
    v: 99.92809226036063
  },
  {
    t: 1581432300,
    v: 99.96085179216855
  },
  {
    t: 1581433200,
    v: 100.0291338152552
  },
  {
    t: 1581434100,
    v: 100.04824980654558
  },
  {
    t: 1581435000,
    v: 100.08648943918426
  },
  {
    t: 1581435900,
    v: 100.06282379291822
  },
  {
    t: 1581436800,
    v: 100.042800032783
  },
  {
    t: 1581437700,
    v: 100.01183539694101
  },
  {
    t: 1581438600,
    v: 99.98907332708086
  },
  {
    t: 1581439500,
    v: 99.99180253210675
  },
  {
    t: 1581440400,
    v: 100.02733310859443
  },
  {
    t: 1581441300,
    v: 100.02095175630375
  },
  {
    t: 1581442200,
    v: 100.02550764773939
  },
  {
    t: 1581443100,
    v: 100.01275080375602
  },
  {
    t: 1581444000,
    v: 100.03552430226625
  },
  {
    t: 1581444900,
    v: 100.00455389995993
  },
  {
    t: 1581445800,
    v: 99.91717484299626
  },
  {
    t: 1581446700,
    v: 99.8889879706637
  },
  {
    t: 1581447600,
    v: 99.83080911447672
  },
  {
    t: 1581448500,
    v: 99.85805277525021
  },
  {
    t: 1581449400,
    v: 99.92353904969961
  },
  {
    t: 1581450300,
    v: 99.90168679963224
  },
  {
    t: 1581451200,
    v: 99.93352214259045
  },
  {
    t: 1581452100,
    v: 99.9653856313934
  },
  {
    t: 1581453000,
    v: 99.98360461621138
  },
  {
    t: 1581453900,
    v: 99.97723112232352
  },
  {
    t: 1581454800,
    v: 99.97723112232352
  },
  {
    t: 1581455700,
    v: 99.9690377102476
  },
  {
    t: 1581456600,
    v: 99.96266720085957
  },
  {
    t: 1581457500,
    v: 99.97358858298195
  },
  {
    t: 1581458400,
    v: 99.97722697419361
  },
  {
    t: 1581459300,
    v: 100.01002040518875
  },
  {
    t: 1581460200,
    v: 100.02915744107008
  },
  {
    t: 1581461100,
    v: 100.03189240414055
  },
  {
    t: 1581462000,
    v: 100.0309721614925
  },
  {
    t: 1581462900,
    v: 100.0665178368035
  },
  {
    t: 1581463800,
    v: 100.08110226175071
  },
  {
    t: 1581464700,
    v: 100.08565466590125
  },
  {
    t: 1581465600,
    v: 100.0592152611393
  },
  {
    t: 1581466500,
    v: 100.02823983821305
  },
  {
    t: 1581467400,
    v: 100.02550695064404
  },
  {
    t: 1581468300,
    v: 100.05830000819844
  },
  {
    t: 1581469200,
    v: 100.07560437958864
  },
  {
    t: 1581470100,
    v: 100.05921364282331
  },
  {
    t: 1581471000,
    v: 100.08473495271242
  },
  {
    t: 1581471900,
    v: 100.07013580842906
  },
  {
    t: 1581472800,
    v: 100.0756050682723
  },
  {
    t: 1581473700,
    v: 100.07469552464498
  },
  {
    t: 1581474600,
    v: 100.04189053820234
  },
  {
    t: 1581475500,
    v: 100.0500828643756
  },
  {
    t: 1581476400,
    v: 100.01365784369965
  },
  {
    t: 1581477300,
    v: 100.00546264009395
  },
  {
    t: 1581478200,
    v: 100.00182092976672
  },
  {
    t: 1581479100,
    v: 100.01366058011929
  },
  {
    t: 1581480000,
    v: 100.02003606491685
  },
  {
    t: 1581480900,
    v: 99.98634389708761
  },
  {
    t: 1581481800,
    v: 99.95904064078643
  },
  {
    t: 1581482700,
    v: 99.98543295974945
  },
  {
    t: 1581483600,
    v: 99.98270321447104
  },
  {
    t: 1581484500,
    v: 99.96723221437414
  },
  {
    t: 1581485400,
    v: 99.98907740479139
  },
  {
    t: 1581486300,
    v: 99.99089758877126
  },
  {
    t: 1581487200,
    v: 99.9972691523449
  },
  {
    t: 1581488100,
    v: 99.98725801607308
  },
  {
    t: 1581489000,
    v: 100.02184956573987
  },
  {
    t: 1581489900,
    v: 100.01911819597062
  },
  {
    t: 1581490800,
    v: 100.01456717287593
  },
  {
    t: 1581491700,
    v: 100.05918776179203
  },
  {
    t: 1581492600,
    v: 100.06738053613053
  },
  {
    t: 1581493500,
    v: 100.04552656019523
  },
  {
    t: 1581494400,
    v: 100.05099253323621
  },
  {
    t: 1581495300,
    v: 100.09834184718771
  },
  {
    t: 1581496200,
    v: 100.26769129912225
  },
  {
    t: 1581497100,
    v: 100.24948100666498
  },
  {
    t: 1581498000,
    v: 100.169318719732
  },
  {
    t: 1581498900,
    v: 100.11561114600687
  },
  {
    t: 1581499800,
    v: 100.12380180787052
  },
  {
    t: 1581500700,
    v: 100.11560272713207
  },
  {
    t: 1581501600,
    v: 100.14290135255675
  },
  {
    t: 1581502500,
    v: 100.09193184300588
  },
  {
    t: 1581503400,
    v: 100.08283872847103
  },
  {
    t: 1581504300,
    v: 100.06734313145562
  },
  {
    t: 1581505200,
    v: 100.05641594933483
  },
  {
    t: 1581506100,
    v: 100.09465215333648
  },
  {
    t: 1581507000,
    v: 100.0591575958353
  },
  {
    t: 1581507900,
    v: 100.01910323936359
  },
  {
    t: 1581508800,
    v: 99.85198234685167
  },
  {
    t: 1581509700,
    v: 99.88011116964269
  },
  {
    t: 1581510600,
    v: 99.93365928133919
  },
  {
    t: 1581511500,
    v: 99.97544963538162
  },
  {
    t: 1581512400,
    v: 99.98818063624543
  },
  {
    t: 1581513300,
    v: 99.97454221446365
  },
  {
    t: 1581514200,
    v: 99.96818846968361
  },
  {
    t: 1581515100,
    v: 100.01091256308825
  },
  {
    t: 1581516000,
    v: 100.0436589869296
  },
  {
    t: 1581516900,
    v: 100.04365263416364
  },
  {
    t: 1581517800,
    v: 100.04547108039287
  },
  {
    t: 1581518700,
    v: 100.06273867975996
  },
  {
    t: 1581519600,
    v: 100.09277703495512
  },
  {
    t: 1581520500,
    v: 100.08731241473396
  },
  {
    t: 1581521400,
    v: 100.04365263416364
  },
  {
    t: 1581522300,
    v: 100.03546421751386
  },
  {
    t: 1581523200,
    v: 100.08730050470605
  },
  {
    t: 1581524100,
    v: 100.08185464434159
  },
  {
    t: 1581525000,
    v: 100.02000436458864
  },
  {
    t: 1581525900,
    v: 100.04092471671002
  },
  {
    t: 1581526800,
    v: 100.01727461177583
  },
  {
    t: 1581527700,
    v: 100.05001045673185
  },
  {
    t: 1581528600,
    v: 100.0300024547463
  },
  {
    t: 1581529500,
    v: 100.02181679347677
  },
  {
    t: 1581530400,
    v: 100.00999909099173
  },
  {
    t: 1581531300,
    v: 99.9618351825096
  },
  {
    t: 1581532200,
    v: 99.97364666539443
  },
  {
    t: 1581533100,
    v: 99.99091289097287
  },
  {
    t: 1581534000,
    v: 100.03999745470742
  },
  {
    t: 1581534900,
    v: 100.05999509131071
  },
  {
    t: 1581535800,
    v: 100.01090304467522
  },
  {
    t: 1581536700,
    v: 100.0308975745404
  },
  {
    t: 1581537600,
    v: 100.08091056201023
  },
  {
    t: 1581538500,
    v: 100.07636155377581
  },
  {
    t: 1581539400,
    v: 100.06635941349187
  },
  {
    t: 1581540300,
    v: 100.02999127526537
  },
  {
    t: 1581541200,
    v: 100.04180905984148
  },
  {
    t: 1581542100,
    v: 100.0518035826267
  },
  {
    t: 1581543000,
    v: 100.07725797802239
  },
  {
    t: 1581543900,
    v: 100.0790858763533
  },
  {
    t: 1581544800,
    v: 100.0590833893868
  },
  {
    t: 1581545700,
    v: 100.04089570685957
  },
  {
    t: 1581546600,
    v: 100.01181270501857
  },
  {
    t: 1581547500,
    v: 99.99818305700657
  },
  {
    t: 1581548400,
    v: 99.99000663196243
  },
  {
    t: 1581549300,
    v: 99.99545764251646
  },
  {
    t: 1581550200,
    v: 99.9791074332119
  },
  {
    t: 1581551100,
    v: 99.79834131187151
  },
  {
    t: 1581552000,
    v: 99.85374273255813
  },
  {
    t: 1581552900,
    v: 99.83010039522101
  },
  {
    t: 1581553800,
    v: 99.82465703643136
  },
  {
    t: 1581554700,
    v: 99.80833515006177
  },
  {
    t: 1581555600,
    v: 99.82017328755927
  },
  {
    t: 1581556500,
    v: 99.87283594019655
  },
  {
    t: 1581557400,
    v: 99.88462831239383
  },
  {
    t: 1581558300,
    v: 99.87463776673539
  },
  {
    t: 1581559200,
    v: 99.88370402674809
  },
  {
    t: 1581560100,
    v: 99.8964323676106
  },
  {
    t: 1581561000,
    v: 99.90459922589085
  },
  {
    t: 1581561900,
    v: 99.86190605978015
  },
  {
    t: 1581562800,
    v: 99.86825846780056
  },
  {
    t: 1581563700,
    v: 100.02184499158058
  },
  {
    t: 1581564600,
    v: 99.95542171962991
  },
  {
    t: 1581565500,
    v: 99.97815759296674
  },
  {
    t: 1581566400,
    v: 100.00182021715192
  },
  {
    t: 1581567300,
    v: 99.99362923996834
  },
  {
    t: 1581568200,
    v: 99.97361429559268
  },
  {
    t: 1581569100,
    v: 99.90996234789094
  },
  {
    t: 1581570000,
    v: 99.9308789289872
  },
  {
    t: 1581570900,
    v: 99.91450115059622
  },
  {
    t: 1581571800,
    v: 99.89721292388298
  },
  {
    t: 1581572700,
    v: 99.87449867677952
  },
  {
    t: 1581573600,
    v: 99.86449248342535
  },
  {
    t: 1581574500,
    v: 99.90902308993977
  },
  {
    t: 1581575400,
    v: 99.89628726607775
  },
  {
    t: 1581576300,
    v: 99.9244692371393
  },
  {
    t: 1581577200,
    v: 99.91535450987531
  },
  {
    t: 1581578100,
    v: 99.95812624028257
  },
  {
    t: 1581579000,
    v: 99.9226421791243
  },
  {
    t: 1581579900,
    v: 99.86529534904886
  },
  {
    t: 1581580800,
    v: 99.86348619845465
  },
  {
    t: 1581581700,
    v: 99.90168858950435
  },
  {
    t: 1581582600,
    v: 99.88077469147039
  },
  {
    t: 1581583500,
    v: 99.91715900918533
  },
  {
    t: 1581584400,
    v: 99.95811442047659
  },
  {
    t: 1581585300,
    v: 99.92897533258666
  },
  {
    t: 1581586200,
    v: 99.96903686434503
  },
  {
    t: 1581587100,
    v: 99.92077801453313
  },
  {
    t: 1581588000,
    v: 99.8551978507354
  },
  {
    t: 1581588900,
    v: 99.91894796277072
  },
  {
    t: 1581589800,
    v: 99.92257030161144
  },
  {
    t: 1581590700,
    v: 99.89071834474719
  },
  {
    t: 1581591600,
    v: 99.90527715540011
  },
  {
    t: 1581592500,
    v: 99.9453163449445
  },
  {
    t: 1581593400,
    v: 99.94896517784724
  },
  {
    t: 1581594300,
    v: 99.95717422799713
  },
  {
    t: 1581595200,
    v: 99.9671966832202
  },
  {
    t: 1581596100,
    v: 99.92437908854025
  },
  {
    t: 1581597000,
    v: 99.91528280058664
  },
  {
    t: 1581597900,
    v: 99.97357462434961
  },
  {
    t: 1581598800,
    v: 99.93076684825185
  },
  {
    t: 1581599700,
    v: 99.99544340250249
  },
  {
    t: 1581600600,
    v: 100.08755449355198
  },
  {
    t: 1581601500,
    v: 100.02643163775895
  },
  {
    t: 1581602400,
    v: 100.09845749917952
  },
  {
    t: 1581603300,
    v: 100.08387426154182
  },
  {
    t: 1581604200,
    v: 100.1121341963716
  },
  {
    t: 1581605100,
    v: 100.0547135744378
  },
  {
    t: 1581606000,
    v: 100.04923727809032
  },
  {
    t: 1581606900,
    v: 100.01640838650867
  },
  {
    t: 1581607800,
    v: 99.97538943933498
  },
  {
    t: 1581608700,
    v: 100.11032596307273
  },
  {
    t: 1581609600,
    v: 100.10119981036432
  },
  {
    t: 1581610500,
    v: 100.10755236341762
  },
  {
    t: 1581611400,
    v: 100.12306514248208
  },
  {
    t: 1581612300,
    v: 100.08019977033702
  },
  {
    t: 1581613200,
    v: 100.06378597073136
  },
  {
    t: 1581614100,
    v: 100.05558289140379
  },
  {
    t: 1581615000,
    v: 99.9872495446266
  },
  {
    t: 1581615900,
    v: 99.98451448351248
  },
  {
    t: 1581616800,
    v: 99.94080846530011
  },
  {
    t: 1581617700,
    v: 100.06653178031755
  },
  {
    t: 1581618600,
    v: 100.0710855122258
  },
  {
    t: 1581619500,
    v: 100.08293990047213
  },
  {
    t: 1581620400,
    v: 100.1130541018581
  },
  {
    t: 1581621300,
    v: 99.9936245400561
  },
  {
    t: 1581622200,
    v: 99.99544605856369
  },
  {
    t: 1581623100,
    v: 99.97177507465949
  },
  {
    t: 1581624000,
    v: 99.94810302914424
  },
  {
    t: 1581624900,
    v: 99.9817873859435
  },
  {
    t: 1581625800,
    v: 99.96266346118821
  },
  {
    t: 1581626700,
    v: 99.97267933738286
  },
  {
    t: 1581627600,
    v: 100.00455431475778
  },
  {
    t: 1581628500,
    v: 100.0391753140858
  },
  {
    t: 1581629400,
    v: 100.05922659182855
  },
  {
    t: 1581630300,
    v: 100.01730497745798
  },
  {
    t: 1581631200,
    v: 99.9990892946587
  },
  {
    t: 1581632100,
    v: 99.99089327832874
  },
  {
    t: 1581633000,
    v: 100
  },
  {
    t: 1581633900,
    v: 100.01366257093152
  },
  {
    t: 1581634800,
    v: 99.9945350214045
  },
  {
    t: 1581635700,
    v: 99.97905301408913
  },
  {
    t: 1581636600,
    v: 99.99362337851626
  },
  {
    t: 1581637500,
    v: 99.97449769568465
  },
  {
    t: 1581638400,
    v: 100.0100208616119
  },
  {
    t: 1581639300,
    v: 100.01730781493391
  },
  {
    t: 1581640200,
    v: 100.00182164293977
  },
  {
    t: 1581641100,
    v: 100.04280276123345
  },
  {
    t: 1581642000,
    v: 99.97450210812927
  },
  {
    t: 1581642900,
    v: 99.98269801664634
  },
  {
    t: 1581643800,
    v: 100.01092856362246
  },
  {
    t: 1581644700,
    v: 100.00910755107057
  },
  {
    t: 1581645600,
    v: 99.9990893029525
  },
  {
    t: 1581646500,
    v: 100.0182142727041
  },
  {
    t: 1581647400,
    v: 100.04645485680972
  },
  {
    t: 1581648300,
    v: 100.07378527573829
  },
  {
    t: 1581649200,
    v: 100.0792573495249
  },
  {
    t: 1581650100,
    v: 100.07470437111674
  },
  {
    t: 1581651000,
    v: 100.05647556065658
  },
  {
    t: 1581651900,
    v: 100.03460963969543
  },
  {
    t: 1581652800,
    v: 100.02641334146985
  },
  {
    t: 1581653700,
    v: 99.96267739615668
  },
  {
    t: 1581654600,
    v: 100.02459352370543
  },
  {
    t: 1581655500,
    v: 99.982695022542
  },
  {
    t: 1581656400,
    v: 99.97996648940045
  },
  {
    t: 1581657300,
    v: 99.99544663916437
  },
  {
    t: 1581658200,
    v: 99.99817858931742
  },
  {
    t: 1581659100,
    v: 99.96813082750583
  },
  {
    t: 1581660000,
    v: 99.9298948422634
  },
  {
    t: 1581660900,
    v: 99.91079474599259
  },
  {
    t: 1581661800,
    v: 99.92353626565686
  },
  {
    t: 1581662700,
    v: 99.9344548831112
  },
  {
    t: 1581663600,
    v: 99.9499289902043
  },
  {
    t: 1581664500,
    v: 99.9735965183823
  },
  {
    t: 1581665400,
    v: 99.96357742528818
  },
  {
    t: 1581666300,
    v: 99.97085928678105
  },
  {
    t: 1581667200,
    v: 99.96084216661203
  },
  {
    t: 1581668100,
    v: 100
  },
  {
    t: 1581669000,
    v: 99.99726761025192
  },
  {
    t: 1581669900,
    v: 99.98907143637754
  },
  {
    t: 1581670800,
    v: 99.99453566842436
  },
  {
    t: 1581671700,
    v: 100.009108380621
  },
  {
    t: 1581672600,
    v: 100.03735490807048
  },
  {
    t: 1581673500,
    v: 100.03188746458214
  },
  {
    t: 1581674400,
    v: 100.02550741537004
  },
  {
    t: 1581675300,
    v: 100.02823932371373
  },
  {
    t: 1581676200,
    v: 100.02641430380092
  },
  {
    t: 1581677100,
    v: 100.01274987477802
  },
  {
    t: 1581678000,
    v: 100.02277240349056
  },
  {
    t: 1581678900,
    v: 100.02368373109856
  },
  {
    t: 1581679800,
    v: 100.04463919685884
  },
  {
    t: 1581680700,
    v: 100.01821891852501
  },
  {
    t: 1581681600,
    v: 100.01275150057837
  },
  {
    t: 1581682500,
    v: 100.02641358204605
  },
  {
    t: 1581683400,
    v: 100.03005546599636
  },
  {
    t: 1581684300,
    v: 100.0446270002459
  },
  {
    t: 1581685200,
    v: 100.04553775535297
  },
  {
    t: 1581686100,
    v: 100.05646835950309
  },
  {
    t: 1581687000,
    v: 99.95719489981786
  },
  {
    t: 1581687900,
    v: 99.96903658236724
  },
  {
    t: 1581688800,
    v: 99.97541386657925
  },
  {
    t: 1581689700,
    v: 99.95902348409655
  },
  {
    t: 1581690600,
    v: 99.94262660850401
  },
  {
    t: 1581691500,
    v: 99.92168005391326
  },
  {
    t: 1581692400,
    v: 99.93534757507877
  },
  {
    t: 1581693300,
    v: 99.93533462056905
  },
  {
    t: 1581694200,
    v: 99.92259004599062
  },
  {
    t: 1581695100,
    v: 99.93899163183727
  },
  {
    t: 1581696000,
    v: 99.94445961941183
  },
  {
    t: 1581696900,
    v: 99.93536523195688
  },
  {
    t: 1581697800,
    v: 99.91897969030215
  },
  {
    t: 1581698700,
    v: 99.91352473192667
  },
  {
    t: 1581699600,
    v: 99.99544431587292
  },
  {
    t: 1581700500,
    v: 99.97084893370865
  },
  {
    t: 1581701400,
    v: 99.96538878414442
  },
  {
    t: 1581702300,
    v: 99.95809572394191
  },
  {
    t: 1581703200,
    v: 99.97175244204693
  },
  {
    t: 1581704100,
    v: 100.01913962814437
  },
  {
    t: 1581705000,
    v: 100
  },
  {
    t: 1581705900,
    v: 99.99635452267032
  },
  {
    t: 1581706800,
    v: 99.98724024790377
  },
  {
    t: 1581707700,
    v: 99.98906645771453
  },
  {
    t: 1581708600,
    v: 99.9772248995618
  },
  {
    t: 1581709500,
    v: 99.9890687484628
  },
  {
    t: 1581710400,
    v: 99.98906705539359
  },
  {
    t: 1581711300,
    v: 99.99180051565646
  },
  {
    t: 1581712200,
    v: 100.01822356671649
  },
  {
    t: 1581713100,
    v: 100.03189327598618
  },
  {
    t: 1581714000,
    v: 100.01457818920667
  },
  {
    t: 1581714900,
    v: 100.02734033245844
  },
  {
    t: 1581715800,
    v: 100.04557345073056
  },
  {
    t: 1581890400,
    v: 100.00911236456749
  },
  {
    t: 1581891300,
    v: 100.0154901728521
  },
  {
    t: 1581892200,
    v: 100.03281049206625
  },
  {
    t: 1581893100,
    v: 100.06107232056587
  },
  {
    t: 1581894000,
    v: 100.05194000473838
  },
  {
    t: 1581894900,
    v: 100.04920542353113
  },
  {
    t: 1581895800,
    v: 100.05284015852047
  },
  {
    t: 1581896700,
    v: 100.03735831176878
  },
  {
    t: 1581897600,
    v: 99.96264373052462
  },
  {
    t: 1581898500,
    v: 100.02550834487283
  },
  {
    t: 1581899400,
    v: 100.02095175630375
  },
  {
    t: 1581900300,
    v: 99.99726698794743
  },
  {
    t: 1581901200,
    v: 100.0227773829698
  },
  {
    t: 1581902100,
    v: 100.02186529158277
  },
  {
    t: 1581903000,
    v: 100.06104727975142
  },
  {
    t: 1581903900,
    v: 100.06012900404504
  },
  {
    t: 1581904800,
    v: 100.04099966289166
  },
  {
    t: 1581905700,
    v: 100.04919242436665
  },
  {
    t: 1581906600,
    v: 100.02276887767647
  },
  {
    t: 1581907500,
    v: 100.02823366545839
  },
  {
    t: 1581908400,
    v: 99.99089443923404
  },
  {
    t: 1581909300,
    v: 100.03370100829774
  },
  {
    t: 1581910200,
    v: 100.09752718456335
  },
  {
    t: 1581911100,
    v: 100.0127509198878
  },
  {
    t: 1581912000,
    v: 100.00546453064236
  },
  {
    t: 1581912900,
    v: 100.03826321447443
  },
  {
    t: 1581913800,
    v: 100.04007906506472
  },
  {
    t: 1581914700,
    v: 100.0364341861969
  },
  {
    t: 1581915600,
    v: 100.01183776794333
  },
  {
    t: 1581916500,
    v: 99.99908950195756
  },
  {
    t: 1581917400,
    v: 100.02094679514033
  },
  {
    t: 1581918300,
    v: 100.00637366039318
  },
  {
    t: 1581919200,
    v: 100.01456876456878
  },
  {
    t: 1581920100,
    v: 100.006373544328
  },
  {
    t: 1581921000,
    v: 100.02276597488458
  },
  {
    t: 1581921900,
    v: 100.019121155282
  },
  {
    t: 1581922800,
    v: 100.03004917137133
  },
  {
    t: 1581923700,
    v: 100.03551589108459
  },
  {
    t: 1581924600,
    v: 100.04462456172305
  },
  {
    t: 1581925500,
    v: 100.04553402302201
  },
  {
    t: 1581926400,
    v: 100.02640516448595
  },
  {
    t: 1581927300,
    v: 100.02458411865935
  },
  {
    t: 1581928200,
    v: 100.00637342826705
  },
  {
    t: 1581929100,
    v: 100.00273151899772
  },
  {
    t: 1581930000,
    v: 99.9908946050535
  },
  {
    t: 1581930900,
    v: 100.01183604348381
  },
  {
    t: 1581931800,
    v: 100.02184996358339
  },
  {
    t: 1581932700,
    v: 100.0264030008376
  },
  {
    t: 1581933600,
    v: 100.0254920883483
  },
  {
    t: 1581934500,
    v: 100.01001392846413
  },
  {
    t: 1581935400,
    v: 100.01001338151896
  },
  {
    t: 1581936300,
    v: 100.01820681116807
  },
  {
    t: 1581937200,
    v: 100.01911628161014
  },
  {
    t: 1581938100,
    v: 100.01274372371607
  },
  {
    t: 1581939000,
    v: 100.01183367317216
  },
  {
    t: 1581939900,
    v: 100.0245780763559
  },
  {
    t: 1581940800,
    v: 100.03823813252244
  },
  {
    t: 1581941700,
    v: 100.04734503605508
  },
  {
    t: 1581942600,
    v: 100.0519054773938
  },
  {
    t: 1581943500,
    v: 100.0236692854607
  },
  {
    t: 1581944400,
    v: 100.0054612976043
  },
  {
    t: 1581945300,
    v: 100
  },
  {
    t: 1581946200,
    v: 100.00273059909344
  },
  {
    t: 1581947100,
    v: 100.00819239381747
  },
  {
    t: 1581948000,
    v: 99.99544891866307
  },
  {
    t: 1581948900,
    v: 100.00182034968917
  },
  {
    t: 1581949800,
    v: 99.99726962457338
  },
  {
    t: 1581950700,
    v: 100.03549584971603
  },
  {
    t: 1581951600,
    v: 100.03003576987139
  },
  {
    t: 1581952500,
    v: 100.01820150890508
  },
  {
    t: 1581953400,
    v: 100.01820167455406
  },
  {
    t: 1581954300,
    v: 100.0482326817371
  },
  {
    t: 1581955200,
    v: 100.04277704965779
  },
  {
    t: 1581956100,
    v: 100.03822595178069
  },
  {
    t: 1581957000,
    v: 100.03458632929826
  },
  {
    t: 1581957900,
    v: 100.04277977517863
  },
  {
    t: 1581958800,
    v: 100.03549681893892
  },
  {
    t: 1581959700,
    v: 100.03094650805065
  },
  {
    t: 1581960600,
    v: 100.04278211161581
  },
  {
    t: 1581961500,
    v: 100.04186728072013
  },
  {
    t: 1581962400,
    v: 100.0436871996505
  },
  {
    t: 1581963300,
    v: 100.01455723267007
  },
  {
    t: 1581964200,
    v: 100.020927735619
  },
  {
    t: 1581965100,
    v: 100.02638738501015
  },
  {
    t: 1581966000,
    v: 100.02365787079161
  },
  {
    t: 1581966900,
    v: 99.98726543383938
  },
  {
    t: 1581967800,
    v: 100.01182689071044
  },
  {
    t: 1581968700,
    v: 100.01819587863348
  },
  {
    t: 1581969600,
    v: 100.02092659315063
  },
  {
    t: 1581970500,
    v: 100.01546691898973
  },
  {
    t: 1581971400,
    v: 100.01455763001783
  },
  {
    t: 1581972300,
    v: 100.01091891793523
  },
  {
    t: 1581973200,
    v: 99.96178552581296
  },
  {
    t: 1581974100,
    v: 99.9472329121064
  },
  {
    t: 1581975000,
    v: 99.95906113537117
  },
  {
    t: 1581975900,
    v: 99.94723771229998
  },
  {
    t: 1581976800,
    v: 99.88992494882876
  },
  {
    t: 1581977700,
    v: 99.88992995542617
  },
  {
    t: 1581978600,
    v: 99.91630733402471
  },
  {
    t: 1581979500,
    v: 99.93177042111296
  },
  {
    t: 1581980400,
    v: 99.891751264418
  },
  {
    t: 1581981300,
    v: 99.89448310365215
  },
  {
    t: 1581982200,
    v: 99.91540147910962
  },
  {
    t: 1581983100,
    v: 99.90994187157165
  },
  {
    t: 1581984000,
    v: 99.904479458535
  },
  {
    t: 1581984900,
    v: 99.91629743524425
  },
  {
    t: 1581985800,
    v: 99.93810529381781
  },
  {
    t: 1581986700,
    v: 99.89987165366514
  },
  {
    t: 1581987600,
    v: 99.85620022753129
  },
  {
    t: 1581988500,
    v: 99.90261129162911
  },
  {
    t: 1581989400,
    v: 99.95264289096936
  },
  {
    t: 1581990300,
    v: 99.96084109681357
  },
  {
    t: 1581991200,
    v: 99.9371779230475
  },
  {
    t: 1581992100,
    v: 99.90441337120384
  },
  {
    t: 1581993000,
    v: 99.89072332055403
  },
  {
    t: 1581993900,
    v: 99.86614338138209
  },
  {
    t: 1581994800,
    v: 99.86343524099128
  },
  {
    t: 1581995700,
    v: 99.86888828189021
  },
  {
    t: 1581996600,
    v: 99.87980222001657
  },
  {
    t: 1581997500,
    v: 99.89073128090256
  },
  {
    t: 1581998400,
    v: 99.9134759007614
  },
  {
    t: 1581999300,
    v: 99.97630957913056
  },
  {
    t: 1582000200,
    v: 100.02643163775895
  },
  {
    t: 1582001100,
    v: 99.97813450921083
  },
  {
    t: 1582002000,
    v: 99.98268824258328
  },
  {
    t: 1582002900,
    v: 99.96538090118979
  },
  {
    t: 1582003800,
    v: 99.98086821846672
  },
  {
    t: 1582004700,
    v: 100.00455609925008
  },
  {
    t: 1582005600,
    v: 100.0701959104045
  },
  {
    t: 1582006500,
    v: 100.10485812240135
  },
  {
    t: 1582007400,
    v: 100.07931587776238
  },
  {
    t: 1582008300,
    v: 100.07020039020476
  },
  {
    t: 1582009200,
    v: 100.05743615925316
  },
  {
    t: 1582010100,
    v: 100.04375529849318
  },
  {
    t: 1582011000,
    v: 99.98541490050228
  },
  {
    t: 1582011900,
    v: 99.97265842166182
  },
  {
    t: 1582012800,
    v: 100.00820075446941
  },
  {
    t: 1582013700,
    v: 99.95625945433669
  },
  {
    t: 1582014600,
    v: 99.98633045665389
  },
  {
    t: 1582015500,
    v: 100.01367016622922
  },
  {
    t: 1582016400,
    v: 99.93803761481266
  },
  {
    t: 1582017300,
    v: 99.96628640157451
  },
  {
    t: 1582018200,
    v: 99.96082718411225
  },
  {
    t: 1582019100,
    v: 99.93532931950048
  },
  {
    t: 1582020000,
    v: 99.94078797540422
  },
  {
    t: 1582020900,
    v: 99.92984885617193
  },
  {
    t: 1582021800,
    v: 99.96719817767654
  },
  {
    t: 1582022700,
    v: 99.9726648989968
  },
  {
    t: 1582023600,
    v: 100.01732233213292
  },
  {
    t: 1582024500,
    v: 100.02370251520152
  },
  {
    t: 1582025400,
    v: 99.9580884697736
  },
  {
    t: 1582026300,
    v: 100.02279150332755
  },
  {
    t: 1582027200,
    v: 100
  },
  {
    t: 1582028100,
    v: 99.99635512059996
  },
  {
    t: 1582029000,
    v: 100.05835369634195
  },
  {
    t: 1582029900,
    v: 100.04830873841273
  },
  {
    t: 1582030800,
    v: 100.01549299625434
  },
  {
    t: 1582031700,
    v: 100.02460899048454
  },
  {
    t: 1582032600,
    v: 100.05195515449823
  },
  {
    t: 1582033500,
    v: 100.08114071075616
  },
  {
    t: 1582034400,
    v: 100.08932314927902
  },
  {
    t: 1582035300,
    v: 100.1193959113736
  },
  {
    t: 1582036200,
    v: 100.20692044045796
  },
  {
    t: 1582037100,
    v: 100.17681531913342
  },
  {
    t: 1582038000,
    v: 100.1668049111741
  },
  {
    t: 1582038900,
    v: 100.07747345394887
  },
  {
    t: 1582039800,
    v: 100.1011684500264
  },
  {
    t: 1582040700,
    v: 100.05649768997348
  },
  {
    t: 1582041600,
    v: 100.06196464370332
  },
  {
    t: 1582042500,
    v: 100.01639881928502
  },
  {
    t: 1582043400,
    v: 100.0437381542499
  },
  {
    t: 1582044300,
    v: 100.05193953145076
  },
  {
    t: 1582045200,
    v: 100.06559348438056
  },
  {
    t: 1582046100,
    v: 100.06467774994306
  },
  {
    t: 1582047000,
    v: 100.0610133683022
  },
  {
    t: 1582047900,
    v: 100.01183431952663
  },
  {
    t: 1582048800,
    v: 99.95087827819268
  },
  {
    t: 1582049700,
    v: 99.95723890713565
  },
  {
    t: 1582050600,
    v: 99.95359079823827
  },
  {
    t: 1582051500,
    v: 100.04189435336977
  },
  {
    t: 1582052400,
    v: 100.01274708865601
  },
  {
    t: 1582053300,
    v: 100.06557317328621
  },
  {
    t: 1582054200,
    v: 100.06010491038904
  },
  {
    t: 1582055100,
    v: 100.07287169116977
  },
  {
    t: 1582056000,
    v: 100.07377586709414
  },
  {
    t: 1582056900,
    v: 100.06102003642987
  },
  {
    t: 1582057800,
    v: 100.02002931563472
  },
  {
    t: 1582058700,
    v: 100.0036414616827
  },
  {
    t: 1582059600,
    v: 99.98270825180425
  },
  {
    t: 1582060500,
    v: 100.0027306736572
  },
  {
    t: 1582061400,
    v: 99.99271913139238
  },
  {
    t: 1582062300,
    v: 99.99908980030217
  },
  {
    t: 1582063200,
    v: 100.02913301954644
  },
  {
    t: 1582064100,
    v: 99.99817926915863
  },
  {
    t: 1582065000,
    v: 100.00819351255883
  },
  {
    t: 1582065900,
    v: 99.98361744923685
  },
  {
    t: 1582066800,
    v: 100
  },
  {
    t: 1582067700,
    v: 100.0182046567512
  },
  {
    t: 1582068600,
    v: 100.00910141709063
  },
  {
    t: 1582069500,
    v: 100.0436891878362
  },
  {
    t: 1582070400,
    v: 100.0546144673724
  },
  {
    t: 1582071300,
    v: 100.06736458807465
  },
  {
    t: 1582072200,
    v: 100.06189695976697
  },
  {
    t: 1582073100,
    v: 100.03640798791255
  },
  {
    t: 1582074000,
    v: 99.99908982515541
  },
  {
    t: 1582074900,
    v: 100.05006143903883
  },
  {
    t: 1582075800,
    v: 100.06462006134356
  },
  {
    t: 1582076700,
    v: 100.10924583955428
  },
  {
    t: 1582077600,
    v: 100.15748461566471
  },
  {
    t: 1582078500,
    v: 100.1483774065814
  },
  {
    t: 1582079400,
    v: 100.14744161494076
  },
  {
    t: 1582080300,
    v: 100.13377987295462
  },
  {
    t: 1582081200,
    v: 100.14287924428712
  },
  {
    t: 1582082100,
    v: 100.11918300504936
  },
  {
    t: 1582083000,
    v: 100.1128082895898
  },
  {
    t: 1582083900,
    v: 100.11644408864306
  },
  {
    t: 1582084800,
    v: 100.1128011061786
  },
  {
    t: 1582085700,
    v: 100.11464342256109
  },
  {
    t: 1582086600,
    v: 100.15109039938837
  },
  {
    t: 1582087500,
    v: 100.09188500727801
  },
  {
    t: 1582088400,
    v: 100.0827694098814
  },
  {
    t: 1582089300,
    v: 100.05729147721073
  },
  {
    t: 1582090200,
    v: 100.01181549647806
  },
  {
    t: 1582091100,
    v: 100.02454143867368
  },
  {
    t: 1582092000,
    v: 99.98727689119924
  },
  {
    t: 1582092900,
    v: 100.00908851302839
  },
  {
    t: 1582093800,
    v: 99.99363867684478
  },
  {
    t: 1582094700,
    v: 99.99273031277829
  },
  {
    t: 1582095600,
    v: 100.00817847244309
  },
  {
    t: 1582096500,
    v: 100.03907243848363
  },
  {
    t: 1582097400,
    v: 100.04997637480464
  },
  {
    t: 1582098300,
    v: 100.07452377490185
  },
  {
    t: 1582099200,
    v: 100.0318083501463
  },
  {
    t: 1582100100,
    v: 100.0572617954754
  },
  {
    t: 1582101000,
    v: 100.11450901985732
  },
  {
    t: 1582101900,
    v: 100.13542130567951
  },
  {
    t: 1582102800,
    v: 100.15176575364875
  },
  {
    t: 1582103700,
    v: 100.17174792130493
  },
  {
    t: 1582104600,
    v: 100.15360564250786
  },
  {
    t: 1582105500,
    v: 100.17448359218095
  },
  {
    t: 1582106400,
    v: 100.18630774404042
  },
  {
    t: 1582107300,
    v: 100.24991366618805
  },
  {
    t: 1582108200,
    v: 100.24987733294563
  },
  {
    t: 1582109100,
    v: 100.2052772605477
  },
  {
    t: 1582110000,
    v: 100.22614366025775
  },
  {
    t: 1582110900,
    v: 100.27426122018998
  },
  {
    t: 1582111800,
    v: 100.3098056673541
  },
  {
    t: 1582112700,
    v: 100.32248101449801
  },
  {
    t: 1582113600,
    v: 100.22421728197818
  },
  {
    t: 1582114500,
    v: 100.23870897473132
  },
  {
    t: 1582115400,
    v: 100.21323896374939
  },
  {
    t: 1582116300,
    v: 100.22406879910008
  },
  {
    t: 1582117200,
    v: 100.28949732736794
  },
  {
    t: 1582118100,
    v: 100.31842222242382
  },
  {
    t: 1582119000,
    v: 100.46535677352637
  },
  {
    t: 1582119900,
    v: 100.30458785455929
  },
  {
    t: 1582120800,
    v: 100.37705408369516
  },
  {
    t: 1582121700,
    v: 100.36076540277917
  },
  {
    t: 1582122600,
    v: 100.3515894015731
  },
  {
    t: 1582123500,
    v: 100.37132299666716
  },
  {
    t: 1582124400,
    v: 100.37587175074722
  },
  {
    t: 1582125300,
    v: 100.40293736813987
  },
  {
    t: 1582126200,
    v: 100.46826316933554
  },
  {
    t: 1582127100,
    v: 100.45817147927816
  },
  {
    t: 1582128000,
    v: 100.50162984425933
  },
  {
    t: 1582128900,
    v: 100.54670033761461
  },
  {
    t: 1582129800,
    v: 100.61713872047777
  },
  {
    t: 1582130700,
    v: 100.53444502721962
  },
  {
    t: 1582131600,
    v: 100.39187005083477
  },
  {
    t: 1582132500,
    v: 100.41482525824905
  },
  {
    t: 1582133400,
    v: 100.3693168991828
  },
  {
    t: 1582134300,
    v: 100.48049566921668
  },
  {
    t: 1582135200,
    v: 100.4677454309037
  },
  {
    t: 1582136100,
    v: 100.39250717340697
  },
  {
    t: 1582137000,
    v: 100.42048274306339
  },
  {
    t: 1582137900,
    v: 100.4211608527831
  },
  {
    t: 1582138800,
    v: 100.482307865675
  },
  {
    t: 1582139700,
    v: 100.4398575870927
  },
  {
    t: 1582140600,
    v: 100.42885201001856
  },
  {
    t: 1582141500,
    v: 100.4375028131611
  },
  {
    t: 1582142400,
    v: 100.25631340384203
  },
  {
    t: 1582143300,
    v: 100.19878927437418
  },
  {
    t: 1582144200,
    v: 100.08904078787606
  },
  {
    t: 1582145100,
    v: 100.11520322569032
  },
  {
    t: 1582146000,
    v: 100.07377152419168
  },
  {
    t: 1582146900,
    v: 100.0638196510593
  },
  {
    t: 1582147800,
    v: 100.0593194441948
  },
  {
    t: 1582148700,
    v: 100.08089087820531
  },
  {
    t: 1582149600,
    v: 100.03684035546449
  },
  {
    t: 1582150500,
    v: 99.97126204524433
  },
  {
    t: 1582151400,
    v: 99.82146061367307
  },
  {
    t: 1582152300,
    v: 99.8223147541572
  },
  {
    t: 1582153200,
    v: 99.83044765407733
  },
  {
    t: 1582154100,
    v: 99.70870566724328
  },
  {
    t: 1582155000,
    v: 99.77304735505979
  },
  {
    t: 1582155900,
    v: 99.81776397292494
  },
  {
    t: 1582156800,
    v: 99.8741957514108
  },
  {
    t: 1582157700,
    v: 99.92897982667482
  },
  {
    t: 1582158600,
    v: 99.92088892085296
  },
  {
    t: 1582159500,
    v: 99.89310289071342
  },
  {
    t: 1582160400,
    v: 99.8535858005174
  },
  {
    t: 1582161300,
    v: 99.84283930992987
  },
  {
    t: 1582162200,
    v: 99.93263392375957
  },
  {
    t: 1582163100,
    v: 99.92813446042454
  },
  {
    t: 1582164000,
    v: 100.01348181303422
  },
  {
    t: 1582164900,
    v: 100.01078797141187
  },
  {
    t: 1582165800,
    v: 99.98831786198902
  },
  {
    t: 1582166700,
    v: 100.0782050590583
  },
  {
    t: 1582167600,
    v: 100.20139538229158
  },
  {
    t: 1582168500,
    v: 100.16098425232258
  },
  {
    t: 1582169400,
    v: 100.18444540416036
  },
  {
    t: 1582170300,
    v: 100.18082531914321
  },
  {
    t: 1582171200,
    v: 100.21862741569801
  },
  {
    t: 1582172100,
    v: 100.16636241828007
  },
  {
    t: 1582173000,
    v: 100.18441056087796
  },
  {
    t: 1582173900,
    v: 100.1682017701662
  },
  {
    t: 1582174800,
    v: 100.09078080480329
  },
  {
    t: 1582175700,
    v: 100.12315824486015
  },
  {
    t: 1582176600,
    v: 100.08267730687659
  },
  {
    t: 1582177500,
    v: 100.12764389152066
  },
  {
    t: 1582178400,
    v: 100.1366071107596
  },
  {
    t: 1582179300,
    v: 100.08622780307725
  },
  {
    t: 1582180200,
    v: 100.0305075012562
  },
  {
    t: 1582181100,
    v: 100.1535422465655
  },
  {
    t: 1582182000,
    v: 100.1715327483857
  },
  {
    t: 1582182900,
    v: 100.394224034196
  },
  {
    t: 1582183800,
    v: 100.24418489824131
  },
  {
    t: 1582184700,
    v: 100.26214673034796
  },
  {
    t: 1582185600,
    v: 100.31875729550148
  },
  {
    t: 1582186500,
    v: 100.2442462936523
  },
  {
    t: 1582187400,
    v: 100.32507767740083
  },
  {
    t: 1582188300,
    v: 100.31874012354547
  },
  {
    t: 1582189200,
    v: 100.39957617987214
  },
  {
    t: 1582190100,
    v: 100.36089739561358
  },
  {
    t: 1582191000,
    v: 100.45323999281996
  },
  {
    t: 1582191900,
    v: 100.48371608827145
  },
  {
    t: 1582192800,
    v: 100.4386358335875
  },
  {
    t: 1582193700,
    v: 100.41868012659023
  },
  {
    t: 1582194600,
    v: 100.46978662363277
  },
  {
    t: 1582195500,
    v: 100.31664534826517
  },
  {
    t: 1582196400,
    v: 100.4146404807322
  },
  {
    t: 1582197300,
    v: 100.41010028653294
  },
  {
    t: 1582198200,
    v: 100.2855224882524
  },
  {
    t: 1582199100,
    v: 100.1916961526403
  },
  {
    t: 1582200000,
    v: 100.15843179377015
  },
  {
    t: 1582200900,
    v: 100.15841619604228
  },
  {
    t: 1582201800,
    v: 100.09569549157969
  },
  {
    t: 1582202700,
    v: 100.15385853959621
  },
  {
    t: 1582203600,
    v: 100.06611570247934
  },
  {
    t: 1582204500,
    v: 99.97052729351243
  },
  {
    t: 1582205400,
    v: 99.95713175733002
  },
  {
    t: 1582206300,
    v: 99.98928647953717
  },
  {
    t: 1582207200,
    v: 99.9143346659052
  },
  {
    t: 1582208100,
    v: 99.94025911494326
  },
  {
    t: 1582209000,
    v: 99.99554073096337
  },
  {
    t: 1582209900,
    v: 99.99732472489255
  },
  {
    t: 1582210800,
    v: 100.01963514333656
  },
  {
    t: 1582211700,
    v: 100.15914313047054
  },
  {
    t: 1582212600,
    v: 100.13852024629794
  },
  {
    t: 1582213500,
    v: 100.1608464095507
  },
  {
    t: 1582214400,
    v: 100.02680486061473
  },
  {
    t: 1582215300,
    v: 99.96963282512974
  },
  {
    t: 1582216200,
    v: 99.88214180483756
  },
  {
    t: 1582217100,
    v: 99.92674319917809
  },
  {
    t: 1582218000,
    v: 99.94192435871089
  },
  {
    t: 1582218900,
    v: 99.85892353298333
  },
  {
    t: 1582219800,
    v: 99.95266504715633
  },
  {
    t: 1582220700,
    v: 99.9393312158738
  },
  {
    t: 1582221600,
    v: 99.94559449166526
  },
  {
    t: 1582222500,
    v: 99.90636286618808
  },
  {
    t: 1582223400,
    v: 99.96787607302839
  },
  {
    t: 1582224300,
    v: 99.98125451899989
  },
  {
    t: 1582225200,
    v: 99.97679648734515
  },
  {
    t: 1582226100,
    v: 99.94914709870817
  },
  {
    t: 1582227000,
    v: 100.09915140687808
  },
  {
    t: 1582227900,
    v: 100.11257136220284
  },
  {
    t: 1582228800,
    v: 100.17163238488563
  },
  {
    t: 1582229700,
    v: 100.18059417270884
  },
  {
    t: 1582230600,
    v: 100.1850560532103
  },
  {
    t: 1582231500,
    v: 100.20565460755736
  },
  {
    t: 1582232400,
    v: 100.1474333199303
  },
  {
    t: 1582233300,
    v: 100.12051849735751
  },
  {
    t: 1582234200,
    v: 100.03301802605746
  },
  {
    t: 1582235100,
    v: 100.04909399268051
  },
  {
    t: 1582236000,
    v: 100.02410068731591
  },
  {
    t: 1582236900,
    v: 100.02499866078603
  },
  {
    t: 1582237800,
    v: 100.01428239872887
  },
  {
    t: 1582238700,
    v: 100.02053003186617
  },
  {
    t: 1582239600,
    v: 99.99553814440348
  },
  {
    t: 1582240500,
    v: 99.98750613537993
  },
  {
    t: 1582241400,
    v: 99.96965884043229
  },
  {
    t: 1582242300,
    v: 99.91968229887108
  },
  {
    t: 1582243200,
    v: 99.97858385758265
  },
  {
    t: 1582244100,
    v: 100.0392618767177
  },
  {
    t: 1582245000,
    v: 100.04104211277661
  },
  {
    t: 1582245900,
    v: 99.96076717996273
  },
  {
    t: 1582246800,
    v: 99.97323746398209
  },
  {
    t: 1582247700,
    v: 99.92327251639381
  },
  {
    t: 1582248600,
    v: 100.00446201486743
  },
  {
    t: 1582249500,
    v: 99.96965206990734
  },
  {
    t: 1582250400,
    v: 99.91967297977544
  },
  {
    t: 1582251300,
    v: 99.89469362985703
  },
  {
    t: 1582252200,
    v: 99.97322767187835
  },
  {
    t: 1582253100,
    v: 99.96786890513295
  },
  {
    t: 1582254000,
    v: 99.96607900022315
  },
  {
    t: 1582254900,
    v: 100.04287053990086
  },
  {
    t: 1582255800,
    v: 99.95269588811239
  },
  {
    t: 1582256700,
    v: 99.90991151705437
  },
  {
    t: 1582257600,
    v: 99.8956531045431
  },
  {
    t: 1582258500,
    v: 99.91882754868118
  },
  {
    t: 1582259400,
    v: 99.93218342598624
  },
  {
    t: 1582260300,
    v: 99.97410691166885
  },
  {
    t: 1582261200,
    v: 99.91433313701343
  },
  {
    t: 1582262100,
    v: 99.93660714285714
  },
  {
    t: 1582263000,
    v: 99.99553380020009
  },
  {
    t: 1582263900,
    v: 100.01161377930245
  },
  {
    t: 1582264800,
    v: 99.91519825754736
  },
  {
    t: 1582265700,
    v: 99.94018124190886
  },
  {
    t: 1582266600,
    v: 99.95445899970534
  },
  {
    t: 1582267500,
    v: 99.96786087329149
  },
  {
    t: 1582268400,
    v: 99.94374397256848
  },
  {
    t: 1582269300,
    v: 99.87144119773951
  },
  {
    t: 1582270200,
    v: 99.74287780446215
  },
  {
    t: 1582271100,
    v: 99.74110841308384
  },
  {
    t: 1582272000,
    v: 99.59014563670294
  },
  {
    t: 1582272900,
    v: 99.68563007948558
  },
  {
    t: 1582273800,
    v: 99.74099280138614
  },
  {
    t: 1582274700,
    v: 99.79272574578528
  },
  {
    t: 1582275600,
    v: 99.81598434973694
  },
  {
    t: 1582276500,
    v: 99.81062805384595
  },
  {
    t: 1582277400,
    v: 99.84186686440755
  },
  {
    t: 1582278300,
    v: 99.78202219085566
  },
  {
    t: 1582279200,
    v: 99.82311320754717
  },
  {
    t: 1582280100,
    v: 99.81871277137269
  },
  {
    t: 1582281000,
    v: 99.8659816841635
  },
  {
    t: 1582281900,
    v: 99.93295609965405
  },
  {
    t: 1582282800,
    v: 100.05639047269537
  },
  {
    t: 1582283700,
    v: 100.03222168519414
  },
  {
    t: 1582284600,
    v: 100.2564286482803
  },
  {
    t: 1582285500,
    v: 100.23024960131879
  },
  {
    t: 1582286400,
    v: 100.22296643862603
  },
  {
    t: 1582287300,
    v: 100.28201294573714
  },
  {
    t: 1582288200,
    v: 100.197778792028
  },
  {
    t: 1582289100,
    v: 100.2282144679023
  },
  {
    t: 1582290000,
    v: 100.20133507525458
  },
  {
    t: 1582290900,
    v: 100.25158021021718
  },
  {
    t: 1582291800,
    v: 100.16556587732015
  },
  {
    t: 1582292700,
    v: 100.05815305885089
  },
  {
    t: 1582293600,
    v: 100.11988369492283
  },
  {
    t: 1582294500,
    v: 100.11628739086875
  },
  {
    t: 1582295400,
    v: 100.06172618621628
  },
  {
    t: 1582296300,
    v: 99.95705159177538
  },
  {
    t: 1582297200,
    v: 99.73260119122146
  },
  {
    t: 1582298100,
    v: 99.73631284916202
  },
  {
    t: 1582299000,
    v: 99.68192986374805
  },
  {
    t: 1582299900,
    v: 99.70985251580188
  },
  {
    t: 1582300800,
    v: 99.78028259588073
  },
  {
    t: 1582301700,
    v: 99.721408672048
  },
  {
    t: 1582302600,
    v: 99.784781075023
  },
  {
    t: 1582303500,
    v: 99.78655949988837
  },
  {
    t: 1582304400,
    v: 99.81415794787488
  },
  {
    t: 1582305300,
    v: 99.88197319360866
  },
  {
    t: 1582306200,
    v: 99.80430528375733
  },
  {
    t: 1582307100,
    v: 99.76144100355604
  },
  {
    t: 1582308000,
    v: 99.81225358282747
  },
  {
    t: 1582308900,
    v: 99.95345256637485
  },
  {
    t: 1582309800,
    v: 100.08787740205705
  },
  {
    t: 1582310700,
    v: 100.03316006452769
  },
  {
    t: 1582311600,
    v: 100.06901558676692
  },
  {
    t: 1582312500,
    v: 99.9552320323762
  },
  {
    t: 1582313400,
    v: 99.89706040316517
  },
  {
    t: 1582314300,
    v: 99.92478510028653
  },
  {
    t: 1582315200,
    v: 99.84875333369132
  },
  {
    t: 1582316100,
    v: 99.85054055989117
  },
  {
    t: 1582317000,
    v: 99.84156111533812
  },
  {
    t: 1582317900,
    v: 99.89436651239404
  },
  {
    t: 1582318800,
    v: 99.91315247560212
  },
  {
    t: 1582319700,
    v: 99.93641126684878
  },
  {
    t: 1582320600,
    v: 99.93998728088638
  },
  {
    t: 1582495200,
    v: 99.84685927173075
  },
  {
    t: 1582496100,
    v: 99.88353028660508
  },
  {
    t: 1582497000,
    v: 99.92026304236809
  },
  {
    t: 1582497900,
    v: 99.80115722909912
  },
  {
    t: 1582498800,
    v: 99.80920304200221
  },
  {
    t: 1582499700,
    v: 99.92473185724143
  },
  {
    t: 1582500600,
    v: 99.993727373741
  },
  {
    t: 1582501500,
    v: 99.9757997293155
  },
  {
    t: 1582502400,
    v: 99.99551846839175
  },
  {
    t: 1582503300,
    v: 100.10310387491259
  },
  {
    t: 1582504200,
    v: 100.05376874064649
  },
  {
    t: 1582505100,
    v: 99.98566218311183
  },
  {
    t: 1582506000,
    v: 99.9829724691712
  },
  {
    t: 1582506900,
    v: 99.96146124614613
  },
  {
    t: 1582507800,
    v: 100.00896933384757
  },
  {
    t: 1582508700,
    v: 100.08610869428722
  },
  {
    t: 1582509600,
    v: 100.01793271644787
  },
  {
    t: 1582510500,
    v: 100.09243968983344
  },
  {
    t: 1582511400,
    v: 100.07090034462951
  },
  {
    t: 1582512300,
    v: 99.94260964695967
  },
  {
    t: 1582513200,
    v: 99.94354282232119
  },
  {
    t: 1582514100,
    v: 100.02779222176399
  },
  {
    t: 1582515000,
    v: 99.97848768419921
  },
  {
    t: 1582515900,
    v: 99.90595863971411
  },
  {
    t: 1582516800,
    v: 99.90595527053533
  },
  {
    t: 1582517700,
    v: 99.96325407566076
  },
  {
    t: 1582518600,
    v: 99.98476224622418
  },
  {
    t: 1582519500,
    v: 100.02600127316579
  },
  {
    t: 1582520400,
    v: 100.06098600012557
  },
  {
    t: 1582521300,
    v: 100.0250934282104
  },
  {
    t: 1582522200,
    v: 100.02779072686197
  },
  {
    t: 1582523100,
    v: 100.03855568606706
  },
  {
    t: 1582524000,
    v: 100.06008806937929
  },
  {
    t: 1582524900,
    v: 100.10138802903465
  },
  {
    t: 1582525800,
    v: 100.02062299374137
  },
  {
    t: 1582526700,
    v: 99.99731117743539
  },
  {
    t: 1582527600,
    v: 99.96593150439304
  },
  {
    t: 1582528500,
    v: 100.01165417578083
  },
  {
    t: 1582529400,
    v: 100.0098615792871
  },
  {
    t: 1582530300,
    v: 100.00179314302109
  },
  {
    t: 1582531200,
    v: 99.93455732061534
  },
  {
    t: 1582532100,
    v: 99.92560190745952
  },
  {
    t: 1582533000,
    v: 99.84225008738986
  },
  {
    t: 1582533900,
    v: 99.79392712187867
  },
  {
    t: 1582534800,
    v: 99.7633963380206
  },
  {
    t: 1582535700,
    v: 99.73648830330734
  },
  {
    t: 1582536600,
    v: 99.77413283140629
  },
  {
    t: 1582537500,
    v: 99.77053941164871
  },
  {
    t: 1582538400,
    v: 99.75885037068912
  },
  {
    t: 1582539300,
    v: 99.7562068656449
  },
  {
    t: 1582540200,
    v: 99.84215529766281
  },
  {
    t: 1582541100,
    v: 99.75349808624877
  },
  {
    t: 1582542000,
    v: 99.72390300748509
  },
  {
    t: 1582542900,
    v: 99.76151625455003
  },
  {
    t: 1582543800,
    v: 99.84480825297153
  },
  {
    t: 1582544700,
    v: 99.846607881305
  },
  {
    t: 1582545600,
    v: 100.00538633486843
  },
  {
    t: 1582546500,
    v: 99.97755452007074
  },
  {
    t: 1582547400,
    v: 100.00359340609981
  },
  {
    t: 1582548300,
    v: 100.04583198533376
  },
  {
    t: 1582549200,
    v: 100.0709678578487
  },
  {
    t: 1582550100,
    v: 100.04941155331954
  },
  {
    t: 1582551000,
    v: 100.02965492451474
  },
  {
    t: 1582551900,
    v: 100
  },
  {
    t: 1582552800,
    v: 99.75836731760775
  },
  {
    t: 1582553700,
    v: 99.58754919756304
  },
  {
    t: 1582554600,
    v: 99.69257597957699
  },
  {
    t: 1582555500,
    v: 99.52638579337119
  },
  {
    t: 1582556400,
    v: 99.49506747407952
  },
  {
    t: 1582557300,
    v: 99.32260035217594
  },
  {
    t: 1582558200,
    v: 99.2800653506764
  },
  {
    t: 1582559100,
    v: 99.36779338338303
  },
  {
    t: 1582560000,
    v: 99.37656644418294
  },
  {
    t: 1582560900,
    v: 99.31552992535504
  },
  {
    t: 1582561800,
    v: 99.2252933202869
  },
  {
    t: 1582562700,
    v: 99.27804965653483
  },
  {
    t: 1582563600,
    v: 99.25255810192878
  },
  {
    t: 1582564500,
    v: 99.22819817067692
  },
  {
    t: 1582565400,
    v: 99.40390970402585
  },
  {
    t: 1582566300,
    v: 99.58223176662727
  },
  {
    t: 1582567200,
    v: 99.521211847978
  },
  {
    t: 1582568100,
    v: 99.76342047045014
  },
  {
    t: 1582569000,
    v: 99.78056709409428
  },
  {
    t: 1582569900,
    v: 99.9791956871755
  },
  {
    t: 1582570800,
    v: 99.9755870412398
  },
  {
    t: 1582571700,
    v: 100.0072298738387
  },
  {
    t: 1582572600,
    v: 100.03073446327684
  },
  {
    t: 1582573500,
    v: 100.14652014652015
  },
  {
    t: 1582574400,
    v: 100.20265257748746
  },
  {
    t: 1582575300,
    v: 100.21164786859742
  },
  {
    t: 1582576200,
    v: 100.21542165621238
  },
  {
    t: 1582577100,
    v: 100.2571555338241
  },
  {
    t: 1582578000,
    v: 100.2943973911862
  },
  {
    t: 1582578900,
    v: 100.31985067594505
  },
  {
    t: 1582579800,
    v: 100.263649047783
  },
  {
    t: 1582580700,
    v: 100.20093589058949
  },
  {
    t: 1582581600,
    v: 100.15747033856124
  },
  {
    t: 1582582500,
    v: 100.12123295726991
  },
  {
    t: 1582583400,
    v: 100.09315365831601
  },
  {
    t: 1582584300,
    v: 99.99638532441715
  },
  {
    t: 1582585200,
    v: 100.13374420517083
  },
  {
    t: 1582586100,
    v: 100.02619054069919
  },
  {
    t: 1582587000,
    v: 100.04153198865997
  },
  {
    t: 1582587900,
    v: 100.04693352588112
  },
  {
    t: 1582588800,
    v: 100.21044265211934
  },
  {
    t: 1582589700,
    v: 100.18514671748417
  },
  {
    t: 1582590600,
    v: 100.16528179190752
  },
  {
    t: 1582591500,
    v: 100.15715743743057
  },
  {
    t: 1582592400,
    v: 100.26837753580627
  },
  {
    t: 1582593300,
    v: 100.24569802628609
  },
  {
    t: 1582594200,
    v: 100.25029140425224
  },
  {
    t: 1582595100,
    v: 100.24578683413905
  },
  {
    t: 1582596000,
    v: 100.18523036332259
  },
  {
    t: 1582596900,
    v: 100.23315500289185
  },
  {
    t: 1582597800,
    v: 100.10107664678223
  },
  {
    t: 1582598700,
    v: 100.11105493156127
  },
  {
    t: 1582599600,
    v: 100.03790476878092
  },
  {
    t: 1582600500,
    v: 99.9693270904941
  },
  {
    t: 1582601400,
    v: 99.8566947869349
  },
  {
    t: 1582602300,
    v: 99.91706332035194
  },
  {
    t: 1582603200,
    v: 99.92335644662452
  },
  {
    t: 1582604100,
    v: 99.97204462039299
  },
  {
    t: 1582605000,
    v: 99.86932463365837
  },
  {
    t: 1582605900,
    v: 99.84861728105822
  },
  {
    t: 1582606800,
    v: 99.90265710062373
  },
  {
    t: 1582607700,
    v: 99.88371778577032
  },
  {
    t: 1582608600,
    v: 99.92965241075777
  },
  {
    t: 1582609500,
    v: 99.89541446526138
  },
  {
    t: 1582610400,
    v: 99.91074567927947
  },
  {
    t: 1582611300,
    v: 99.97745289910623
  },
  {
    t: 1582612200,
    v: 99.98736986449669
  },
  {
    t: 1582613100,
    v: 100.01714600272531
  },
  {
    t: 1582614000,
    v: 100.0505447095033
  },
  {
    t: 1582614900,
    v: 99.91158107474106
  },
  {
    t: 1582615800,
    v: 99.87637387428036
  },
  {
    t: 1582616700,
    v: 99.86649828612664
  },
  {
    t: 1582617600,
    v: 99.87456798144689
  },
  {
    t: 1582618500,
    v: 99.80958225414903
  },
  {
    t: 1582619400,
    v: 99.74648141465174
  },
  {
    t: 1582620300,
    v: 99.73106634900007
  },
  {
    t: 1582621200,
    v: 99.66245487364621
  },
  {
    t: 1582622100,
    v: 99.66425386739833
  },
  {
    t: 1582623000,
    v: 99.64356614329543
  },
  {
    t: 1582623900,
    v: 99.56970429574034
  },
  {
    t: 1582624800,
    v: 99.59488234444926
  },
  {
    t: 1582625700,
    v: 99.58044608055437
  },
  {
    t: 1582626600,
    v: 99.5642721179262
  },
  {
    t: 1582627500,
    v: 99.76972674240099
  },
  {
    t: 1582628400,
    v: 99.83465996873899
  },
  {
    t: 1582629300,
    v: 99.7967699978322
  },
  {
    t: 1582630200,
    v: 99.90422667558143
  },
  {
    t: 1582631100,
    v: 99.92314508399791
  },
  {
    t: 1582632000,
    v: 99.92311797320889
  },
  {
    t: 1582632900,
    v: 100.05791331101257
  },
  {
    t: 1582633800,
    v: 100.16119392172133
  },
  {
    t: 1582634700,
    v: 100.21824570300473
  },
  {
    t: 1582635600,
    v: 100.18021281412723
  },
  {
    t: 1582636500,
    v: 100.1676073819727
  },
  {
    t: 1582637400,
    v: 100.14404391980649
  },
  {
    t: 1582638300,
    v: 100.20839562550398
  },
  {
    t: 1582639200,
    v: 100.15856332566189
  },
  {
    t: 1582640100,
    v: 100.0787451463121
  },
  {
    t: 1582641000,
    v: 99.93031548082318
  },
  {
    t: 1582641900,
    v: 99.91311194982217
  },
  {
    t: 1582642800,
    v: 99.82183554606952
  },
  {
    t: 1582643700,
    v: 99.66248314677911
  },
  {
    t: 1582644600,
    v: 99.72663251081701
  },
  {
    t: 1582645500,
    v: 99.59936332230001
  },
  {
    t: 1582646400,
    v: 99.5678275650067
  },
  {
    t: 1582647300,
    v: 99.4912665925705
  },
  {
    t: 1582648200,
    v: 99.52451547584612
  },
  {
    t: 1582649100,
    v: 99.68976682766231
  },
  {
    t: 1582650000,
    v: 99.63271878561994
  },
  {
    t: 1582650900,
    v: 99.51626174308525
  },
  {
    t: 1582651800,
    v: 99.52415845704309
  },
  {
    t: 1582652700,
    v: 99.50438636158091
  },
  {
    t: 1582653600,
    v: 99.65495693753905
  },
  {
    t: 1582654500,
    v: 99.68566278048029
  },
  {
    t: 1582655400,
    v: 99.6955832389581
  },
  {
    t: 1582656300,
    v: 99.87652079171964
  },
  {
    t: 1582657200,
    v: 99.86838761209745
  },
  {
    t: 1582658100,
    v: 99.86652259581771
  },
  {
    t: 1582659000,
    v: 99.88649365272506
  },
  {
    t: 1582659900,
    v: 99.98456005231418
  },
  {
    t: 1582660800,
    v: 99.9836509291722
  },
  {
    t: 1582661700,
    v: 99.86844373474628
  },
  {
    t: 1582662600,
    v: 99.96822110844774
  },
  {
    t: 1582663500,
    v: 100.04361178244989
  },
  {
    t: 1582664400,
    v: 100.0981684315775
  },
  {
    t: 1582665300,
    v: 100.09270873098106
  },
  {
    t: 1582666200,
    v: 100.11359505634314
  },
  {
    t: 1582667100,
    v: 100.13721784013667
  },
  {
    t: 1582668000,
    v: 100.11359608865948
  },
  {
    t: 1582668900,
    v: 100.15181266135778
  },
  {
    t: 1582669800,
    v: 100.141783380443
  },
  {
    t: 1582670700,
    v: 100.19639220250218
  },
  {
    t: 1582671600,
    v: 100.223634330597
  },
  {
    t: 1582672500,
    v: 100.1444312225785
  },
  {
    t: 1582673400,
    v: 100.1716933139535
  },
  {
    t: 1582674300,
    v: 100.1925977069971
  },
  {
    t: 1582675200,
    v: 100.21434864351824
  },
  {
    t: 1582676100,
    v: 100.20343293070566
  },
  {
    t: 1582677000,
    v: 100.17707472553417
  },
  {
    t: 1582677900,
    v: 100.07446151610912
  },
  {
    t: 1582678800,
    v: 99.98456860164298
  },
  {
    t: 1582679700,
    v: 99.95099596170427
  },
  {
    t: 1582680600,
    v: 100.01724701354344
  },
  {
    t: 1582681500,
    v: 100.04175327445515
  },
  {
    t: 1582682400,
    v: 100.22961799914687
  },
  {
    t: 1582683300,
    v: 100.13339382940107
  },
  {
    t: 1582684200,
    v: 100.18050377788057
  },
  {
    t: 1582685100,
    v: 100.19229722620322
  },
  {
    t: 1582686000,
    v: 100.26299322565725
  },
  {
    t: 1582686900,
    v: 100.21580254973432
  },
  {
    t: 1582687800,
    v: 100.17038708683397
  },
  {
    t: 1582688700,
    v: 100.14138887378323
  },
  {
    t: 1582689600,
    v: 100.1015246831886
  },
  {
    t: 1582690500,
    v: 100.23047746946628
  },
  {
    t: 1582691400,
    v: 100.3032283836293
  },
  {
    t: 1582692300,
    v: 100.30415558238982
  },
  {
    t: 1582693200,
    v: 100.23506348529263
  },
  {
    t: 1582694100,
    v: 100.18508941451864
  },
  {
    t: 1582695000,
    v: 99.96287442500633
  },
  {
    t: 1582695900,
    v: 100.0181246431711
  },
  {
    t: 1582696800,
    v: 99.8841062600728
  },
  {
    t: 1582697700,
    v: 99.93119556754604
  },
  {
    t: 1582698600,
    v: 99.8399044853879
  },
  {
    t: 1582699500,
    v: 99.84166334913684
  },
  {
    t: 1582700400,
    v: 99.88418909748926
  },
  {
    t: 1582701300,
    v: 99.92759525748937
  },
  {
    t: 1582702200,
    v: 99.97192791813818
  },
  {
    t: 1582703100,
    v: 100.00724244070251
  },
  {
    t: 1582704000,
    v: 99.97013088104849
  },
  {
    t: 1582704900,
    v: 99.9067670806329
  },
  {
    t: 1582705800,
    v: 99.91126564169429
  },
  {
    t: 1582706700,
    v: 99.83517627987429
  },
  {
    t: 1582707600,
    v: 99.83332578468229
  },
  {
    t: 1582708500,
    v: 99.87586869263457
  },
  {
    t: 1582709400,
    v: 99.99365470730072
  },
  {
    t: 1582710300,
    v: 99.92027685673388
  },
  {
    t: 1582711200,
    v: 100.03351995796415
  },
  {
    t: 1582712100,
    v: 100.07974698456714
  },
  {
    t: 1582713000,
    v: 100.01177568231019
  },
  {
    t: 1582713900,
    v: 99.97554569332488
  },
  {
    t: 1582714800,
    v: 100.01721030081794
  },
  {
    t: 1582715700,
    v: 99.99004236520983
  },
  {
    t: 1582716600,
    v: 100.11951217303914
  },
  {
    t: 1582717500,
    v: 100.09513196162104
  },
  {
    t: 1582718400,
    v: 100.15043862828972
  },
  {
    t: 1582719300,
    v: 100.23675831602246
  },
  {
    t: 1582720200,
    v: 100.26131692843727
  },
  {
    t: 1582721100,
    v: 100.21681937766486
  },
  {
    t: 1582722000,
    v: 100.1368857140267
  },
  {
    t: 1582722900,
    v: 100.1323734745317
  },
  {
    t: 1582723800,
    v: 100.02898051965694
  },
  {
    t: 1582724700,
    v: 100.02263734074633
  },
  {
    t: 1582725600,
    v: 100.01811430124083
  },
  {
    t: 1582726500,
    v: 100.10508864589656
  },
  {
    t: 1582727400,
    v: 100.23818580303936
  },
  {
    t: 1582728300,
    v: 100.11226087979938
  },
  {
    t: 1582729200,
    v: 99.9846266537651
  },
  {
    t: 1582730100,
    v: 100.0199134669346
  },
  {
    t: 1582731000,
    v: 100.050674147136
  },
  {
    t: 1582731900,
    v: 100.05429864253395
  },
  {
    t: 1582732800,
    v: 99.96561054851175
  },
  {
    t: 1582733700,
    v: 100.0488824919208
  },
  {
    t: 1582734600,
    v: 100.00543173217939
  },
  {
    t: 1582735500,
    v: 99.92846794639624
  },
  {
    t: 1582736400,
    v: 99.87415233904628
  },
  {
    t: 1582737300,
    v: 99.89860766598467
  },
  {
    t: 1582738200,
    v: 99.93570587702617
  },
  {
    t: 1582739100,
    v: 99.95475072172599
  },
  {
    t: 1582740000,
    v: 99.79400258400268
  },
  {
    t: 1582740900,
    v: 99.90233403568425
  },
  {
    t: 1582741800,
    v: 99.6599254730292
  },
  {
    t: 1582742700,
    v: 99.8054298642534
  },
  {
    t: 1582743600,
    v: 99.77931733082502
  },
  {
    t: 1582744500,
    v: 99.7386034732272
  },
  {
    t: 1582745400,
    v: 99.77277048007895
  },
  {
    t: 1582746300,
    v: 99.70503877021073
  },
  {
    t: 1582747200,
    v: 99.89408697541369
  },
  {
    t: 1582748100,
    v: 99.98822047643642
  },
  {
    t: 1582749000,
    v: 100.11150192182174
  },
  {
    t: 1582749900,
    v: 100.07974626189397
  },
  {
    t: 1582750800,
    v: 100.06161708605552
  },
  {
    t: 1582751700,
    v: 99.99094604749703
  },
  {
    t: 1582752600,
    v: 100.00362144984744
  },
  {
    t: 1582753500,
    v: 99.94297249101591
  },
  {
    t: 1582754400,
    v: 100.16880241042581
  },
  {
    t: 1582755300,
    v: 100.08614045427755
  },
  {
    t: 1582756200,
    v: 100.06979568898315
  },
  {
    t: 1582757100,
    v: 100.12968051436914
  },
  {
    t: 1582758000,
    v: 100.19689683331822
  },
  {
    t: 1582758900,
    v: 100.19692006134468
  },
  {
    t: 1582759800,
    v: 100.06252775235384
  },
  {
    t: 1582760700,
    v: 100.02446804654366
  },
  {
    t: 1582761600,
    v: 99.88228369629194
  },
  {
    t: 1582762500,
    v: 99.89496369003423
  },
  {
    t: 1582763400,
    v: 99.9628713992049
  },
  {
    t: 1582764300,
    v: 99.89405916388233
  },
  {
    t: 1582765200,
    v: 99.86057922992659
  },
  {
    t: 1582767900,
    v: 99.93297708540894
  },
  {
    t: 1582768800,
    v: 99.94654538206463
  },
  {
    t: 1582769700,
    v: 99.88313100199312
  },
  {
    t: 1582770600,
    v: 99.89492658447993
  },
  {
    t: 1582771500,
    v: 99.83788287717137
  },
  {
    t: 1582772400,
    v: 99.82975178172005
  },
  {
    t: 1582773300,
    v: 99.86958175593674
  },
  {
    t: 1582774200,
    v: 99.85328744792609
  },
  {
    t: 1582775100,
    v: 99.86500566251415
  },
  {
    t: 1582776000,
    v: 99.95920402520284
  },
  {
    t: 1582776900,
    v: 99.8885081851308
  },
  {
    t: 1582777800,
    v: 99.81157030782889
  },
  {
    t: 1582778700,
    v: 99.712659306394
  },
  {
    t: 1582779600,
    v: 99.8078021450006
  },
  {
    t: 1582780500,
    v: 99.7942647911833
  },
  {
    t: 1582781400,
    v: 99.69813715269909
  },
  {
    t: 1582782300,
    v: 99.81315362218936
  },
  {
    t: 1582783200,
    v: 99.7606158700797
  },
  {
    t: 1582784100,
    v: 99.81675511407447
  },
  {
    t: 1582785000,
    v: 99.81766888306316
  },
  {
    t: 1582785900,
    v: 99.86124965992565
  },
  {
    t: 1582786800,
    v: 99.88209472328539
  },
  {
    t: 1582787700,
    v: 99.92923629633661
  },
  {
    t: 1582788600,
    v: 99.90749138400145
  },
  {
    t: 1582789500,
    v: 99.90471782865544
  },
  {
    t: 1582790400,
    v: 99.84388897965111
  },
  {
    t: 1582791300,
    v: 99.99272760329076
  },
  {
    t: 1582792200,
    v: 99.8528490067308
  },
  {
    t: 1582793100,
    v: 99.93733482276654
  },
  {
    t: 1582794000,
    v: 100.09001472968305
  },
  {
    t: 1582794900,
    v: 100.06633649870507
  },
  {
    t: 1582795800,
    v: 100.09816486243284
  },
  {
    t: 1582796700,
    v: 100.11360228295148
  },
  {
    t: 1582797600,
    v: 100.13722532216143
  },
  {
    t: 1582798500,
    v: 99.98819437507377
  },
  {
    t: 1582799400,
    v: 99.98728751997675
  },
  {
    t: 1582800300,
    v: 99.91193666702982
  },
  {
    t: 1582801200,
    v: 99.91739138328582
  },
  {
    t: 1582802100,
    v: 100.0145330354061
  },
  {
    t: 1582803000,
    v: 100.0245441157755
  },
  {
    t: 1582803900,
    v: 99.95454421484223
  },
  {
    t: 1582804800,
    v: 100.05367100583102
  },
  {
    t: 1582805700,
    v: 99.81824790985097
  },
  {
    t: 1582806600,
    v: 99.82739982376614
  },
  {
    t: 1582807500,
    v: 99.76570587914783
  },
  {
    t: 1582808400,
    v: 99.75755264376583
  },
  {
    t: 1582809300,
    v: 99.70133807803336
  },
  {
    t: 1582810200,
    v: 99.69869950721034
  },
  {
    t: 1582811100,
    v: 99.78474896461528
  },
  {
    t: 1582812000,
    v: 99.7956663094611
  },
  {
    t: 1582812900,
    v: 99.86006487901065
  },
  {
    t: 1582813800,
    v: 99.84282300780436
  },
  {
    t: 1582814700,
    v: 99.84197620561258
  },
  {
    t: 1582815600,
    v: 99.85458907782211
  },
  {
    t: 1582816500,
    v: 99.83264663883507
  },
  {
    t: 1582817400,
    v: 99.78634032803578
  },
  {
    t: 1582818300,
    v: 100.15112891478515
  },
  {
    t: 1582819200,
    v: 100.23841806881364
  },
  {
    t: 1582820100,
    v: 100.14655015474241
  },
  {
    t: 1582821000,
    v: 100.15110140178409
  },
  {
    t: 1582821900,
    v: 100.2950040517532
  },
  {
    t: 1582822800,
    v: 100.3914179342236
  },
  {
    t: 1582823700,
    v: 100.31310584615944
  },
  {
    t: 1582824600,
    v: 100.30394306982502
  },
  {
    t: 1582825500,
    v: 100.247504481469
  },
  {
    t: 1582826400,
    v: 100.24205143137932
  },
  {
    t: 1582827300,
    v: 100.10369669625962
  },
  {
    t: 1582828200,
    v: 100.03185495981724
  },
  {
    t: 1582829100,
    v: 100.1548791487113
  },
  {
    t: 1582830000,
    v: 100.1548932603209
  },
  {
    t: 1582830900,
    v: 99.95909314037415
  },
  {
    t: 1582831800,
    v: 99.84748486196472
  },
  {
    t: 1582832700,
    v: 99.92092418720063
  },
  {
    t: 1582833600,
    v: 99.96273608056278
  },
  {
    t: 1582834500,
    v: 99.81389521846886
  },
  {
    t: 1582835400,
    v: 99.64003010327599
  },
  {
    t: 1582836300,
    v: 99.64522597562856
  },
  {
    t: 1582837200,
    v: 99.4765157907152
  },
  {
    t: 1582838100,
    v: 99.56702883751328
  },
  {
    t: 1582839000,
    v: 99.59241103848947
  },
  {
    t: 1582839900,
    v: 99.55565651976376
  },
  {
    t: 1582840800,
    v: 99.65516613895258
  },
  {
    t: 1582841700,
    v: 99.62795520908189
  },
  {
    t: 1582842600,
    v: 99.62064354138806
  },
  {
    t: 1582843500,
    v: 99.60804285155646
  },
  {
    t: 1582844400,
    v: 99.63085875346638
  },
  {
    t: 1582845300,
    v: 99.72619935597722
  },
  {
    t: 1582846200,
    v: 99.59358094285584
  },
  {
    t: 1582855200,
    v: 99.48612071160913
  },
  {
    t: 1582856100,
    v: 99.48766948766948
  },
  {
    t: 1582857000,
    v: 99.55199417228192
  },
  {
    t: 1582857900,
    v: 99.68079090892509
  },
  {
    t: 1582858800,
    v: 99.50680086059148
  },
  {
    t: 1582859700,
    v: 99.41482622526456
  },
  {
    t: 1582860600,
    v: 99.4048977282062
  },
  {
    t: 1582861500,
    v: 99.6430169178939
  },
  {
    t: 1582862400,
    v: 99.55534859939374
  },
  {
    t: 1582863300,
    v: 99.60458787646338
  },
  {
    t: 1582864200,
    v: 99.48781155847712
  },
  {
    t: 1582865100,
    v: 99.42415973863605
  },
  {
    t: 1582866000,
    v: 99.38978227358551
  },
  {
    t: 1582866900,
    v: 99.40294692252917
  },
  {
    t: 1582867800,
    v: 99.53649104522641
  },
  {
    t: 1582868700,
    v: 99.58930547806123
  },
  {
    t: 1582869600,
    v: 99.5124762183521
  },
  {
    t: 1582870500,
    v: 99.59467867076562
  },
  {
    t: 1582871400,
    v: 99.76179787633646
  },
  {
    t: 1582872300,
    v: 99.8863105006922
  },
  {
    t: 1582873200,
    v: 99.96878127611124
  },
  {
    t: 1582874100,
    v: 99.9047078011325
  },
  {
    t: 1582875000,
    v: 99.87894017627043
  },
  {
    t: 1582875900,
    v: 99.79554976942048
  },
  {
    t: 1582876800,
    v: 99.93667923904965
  },
  {
    t: 1582877700,
    v: 99.9100488306348
  },
  {
    t: 1582878600,
    v: 99.80544032891598
  },
  {
    t: 1582879500,
    v: 99.80621578928034
  },
  {
    t: 1582880400,
    v: 99.77681031632315
  },
  {
    t: 1582881300,
    v: 99.67302852734254
  },
  {
    t: 1582882200,
    v: 99.79962314444597
  },
  {
    t: 1582883100,
    v: 99.8373953864386
  },
  {
    t: 1582884000,
    v: 99.81173834384843
  },
  {
    t: 1582884900,
    v: 99.86231584744597
  },
  {
    t: 1582885800,
    v: 99.85396096440871
  },
  {
    t: 1582886700,
    v: 99.63222481060953
  },
  {
    t: 1582887600,
    v: 99.74656810982049
  },
  {
    t: 1582888500,
    v: 99.80156178226919
  },
  {
    t: 1582889400,
    v: 99.77961432506886
  },
  {
    t: 1582890300,
    v: 99.8511713367019
  },
  {
    t: 1582891200,
    v: 99.97609239370311
  },
  {
    t: 1582892100,
    v: 100.13066723105095
  },
  {
    t: 1582893000,
    v: 100.09757624294645
  },
  {
    t: 1582893900,
    v: 100.13914229372847
  },
  {
    t: 1582894800,
    v: 100.13630879468028
  },
  {
    t: 1582895700,
    v: 100.01656299458944
  },
  {
    t: 1582896600,
    v: 99.92087297352003
  },
  {
    t: 1582897500,
    v: 99.74263523139851
  },
  {
    t: 1582898400,
    v: 99.8417896169837
  },
  {
    t: 1582899300,
    v: 99.8867747369584
  },
  {
    t: 1582900200,
    v: 99.90886411547561
  },
  {
    t: 1582901100,
    v: 99.79564408932747
  },
  {
    t: 1582902000,
    v: 99.5950671820357
  },
  {
    t: 1582902900,
    v: 99.4829235978213
  },
  {
    t: 1582903800,
    v: 99.39112999650497
  },
  {
    t: 1582904700,
    v: 99.17934108349033
  },
  {
    t: 1582905600,
    v: 99.43994335059178
  },
  {
    t: 1582906500,
    v: 99.69357614126784
  },
  {
    t: 1582907400,
    v: 99.75534605656473
  },
  {
    t: 1582908300,
    v: 99.48111211290411
  },
  {
    t: 1582909200,
    v: 99.6058931860037
  },
  {
    t: 1582910100,
    v: 99.66179790812329
  },
  {
    t: 1582911000,
    v: 99.66096698113208
  },
  {
    t: 1582911900,
    v: 99.66823334254907
  },
  {
    t: 1582912800,
    v: 99.72818575509076
  },
  {
    t: 1582913700,
    v: 99.86532856141388
  },
  {
    t: 1582914600,
    v: 99.83459619294031
  },
  {
    t: 1582915500,
    v: 99.93433586741395
  },
  {
    t: 1582916400,
    v: 99.912089132366
  },
  {
    t: 1582917300,
    v: 100.04077018587498
  },
  {
    t: 1582918200,
    v: 99.7872930731527
  }
]
