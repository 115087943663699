import { IPendingOrder } from '../../../../utils/interfaces/IOrder.interface'
import { ICurrentBar } from '../../../../utils/interfaces/ICurrentBar'
import { PendingOrderTypes } from '../../../../utils/enums'

export const firstPendingOrder: IPendingOrder = {
  ticket: 1,
  symbol: 'EURUSD',
  type: PendingOrderTypes.SellStop,
  lots: 1,
  createdAt: '2011-03-08',
  execPrice: 1.12321,
  sl: 0,
  tp: 1.3205,
  marketPrice: 1.4,
  comment: 'Good one',
  barIndex: 14
}

export const secondPendingOrder: IPendingOrder = {
  ticket: 2,
  symbol: 'GBPUSD',
  type: PendingOrderTypes.BuyLimit,
  lots: 0.3,
  createdAt: '2017-06-08',
  execPrice: 1.32399,
  sl: 1.2,
  tp: 0,
  marketPrice: 1.4,
  comment: 'Bad one',
  barIndex: 16
}

export const currentBars: ICurrentBar = {
  EURUSD1: {
    close: 1.12345,
    date: '2017-06-08 13:13'
  }
}
