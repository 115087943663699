import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SavableWebTesterState, WebTesterSlice } from './webTesterSlice.types'

const initialState: WebTesterSlice = {
  state: undefined,
  isSynced: true,
  needSave: false,
  isSyncing: false
}

const webTesterSlice = createSlice({
  name: 'webTester',
  initialState: initialState,
  reducers: {
    copyWebTester: (state, action: PayloadAction<{ state: SavableWebTesterState }>) => {
      state.state = action.payload.state
      state.needSave = false
    },
    toggleIsSynced: (state) => {
      state.isSynced = !state.isSynced
    },
    toggleNeedSave: (state) => {
      state.needSave = !state.needSave
    },
    toggleIsSyncing: (state) => {
      state.isSyncing = !state.isSyncing
    }
  }
})

export const {
  copyWebTester: copyWebTesterToSlice,
  toggleNeedSave: toggleWebTesterNeedSave,
  toggleIsSynced: toggleIsWebTesterSynced,
  toggleIsSyncing
} = {
  ...webTesterSlice.actions
}
export default webTesterSlice.reducer
