export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)

  return keys.length > 0 ? keys[0] : null
}

export enum PrimaryColors {
  white = '#FFFFFF',
  blue = '#0000ff',
  silver = '#CCCCCC',
  gray = '#666666'
}

export enum SecondaryColors {
  concrete = '#F2F2F2',
  zumthor = '#EFF6FF',
  mystic = '#E1E7EE',
  silverDark = '#C4C4C4',
  alto = '#DDDDDD',
  altoLight = '#E0E0E0',
  cosmos = '#FFD4D3',
  cosmosLight = '#FED5D5',
  seaPink = '#EBA4A4',
  puce = '#D39292',
  sunsetOrange = '#FF4846',
  cinnabar = '#E73937',
  thunderbird = '#C6201E',
  tamarillo = '#A9110F',
  tamarilloDark = '#931110',
  oldBrick = '#901C1B',
  darkTan = '#6A0F0F',
  barleyWhite = '#FFF1CD',
  salomie = '#FFDF8B',
  mySin = '#FFAE29',
  pizazz = '#FC9700',
  tahitiGold = '#D68209',
  hawaiianTan = '#976317',
  bermuda = '#7DD8C6',
  persianGreen = '#00B896',
  gossamer = '#01987C',
  tropicalRainForest = '#007A63',
  aquaDeep = '#005343',
  jordyBlue = '#9CC3F7',
  royalBlue = '#1471EB',
  denim = '#115EC7',
  toryBlue = '#0D51AD',
  shuttleGray = '#5A6579',
  fiord = '#48546A',
  gullGray = '#99A3B5',
  ghost = '#C5CBD6',

  mineShaft = '#333333',
  charade = '#2B3039',
  mineShaftDark = '#252525',
  shark = '#1D2226'
}

export enum TutorialActionType {
  START = 'Start',
  BACK = 'Back',
  NEXT = 'Next',
  FINISH = 'Finish',
  ANY = 'Any'
}

export enum TutorialStatus {
  STARTED = '10517',
  NOT_STARTED = '10512',
  FINISHED = '10513'
}

export enum OrderWindowTypes {
  Market = 'Market',
  Pending = 'Pending'
}

export enum InstantSetupButtonTypes {
  execPrice = 'execPrice',
  stopLoss = 'stopLoss',
  takeProfit = 'takeProfit'
}

export enum MarketOrderTypes {
  Buy = 'buy',
  Sell = 'sell'
}

export enum PendingOrderTypes {
  BuyLimit = 'Buy Limit',
  BuyStop = 'Buy Stop',
  SellLimit = 'Sell Limit',
  SellStop = 'Sell Stop'
}

export enum OrderInputTypes {
  ExecutionPrice = 'execPrice',
  StopLoss = 'sl',
  TakeProfit = 'tp'
}

export enum OrderWindowTargetName {
  Comment = 'comment',
  Symbol = 'symbol',
  Type = 'type',
  RiskCalculationLabel = 'riskCalculationLabel',
  ExecPriceDropdown = 'execPriceDropdown',
  ExecPrice = 'execPrice',
  SlDropdown = 'slDropdown',
  TpDropdown = 'tpDropdown',
  RiskPercentage = 'riskPercentage',
  Sl = 'sl',
  Tp = 'tp',
  ForecastType = 'forecastType',
  PartialLot = 'partialLot',
  Lots = 'lots'
}

export enum RowType {
  Lots = 'lots',
  StopLoss = 'stopLoss',
  TakeProfit = 'takeProfit'
}

export enum ChangeHandlers {
  RiskCalculation = 'riskCalculation',
  Lots = 'lots'
}

export enum GuideNames {
  QuickStart = 'quickStart',
  BasicTestingActions = 'basicTestingActions',
  BasicTestingManualStartActions = 'basicTestingManualStartActions',
  MarketOrder = 'marketOrder',
  AddIndicator = 'addIndicator',
  RunStrategy = 'runStrategy',
  NewProject = 'newProject'
}

export enum AccountChangeType {
  Deposit = 'deposit',
  Withdraw = 'withdraw'
}

export enum TrendIndicators {
  AdaptiveMovingAverage = 'Adaptive Moving Average',
  Alligator = 'Alligator',
  BBandWithRatio = 'BBand With Ratio',
  BollingerBands = 'Bollinger Bands',
  DeMarker = 'DeMarker',
  DonchianChannel = 'DonchianChannel',
  Envelopes = 'Envelopes',
  Gator = 'Gator',
  HeikinAshi = 'Heikin Ashi',
  HmaColored = 'HMA Colored',
  Ichimoku = 'Ichimoku',
  KeltnerChannels = 'Keltner Channels',
  LinearRegressionChannel = 'Linear Regression Channel',
  MaColored = 'MA Colored',
  MaCrossoverSignals = 'MA Crossover Signals',
  MovingAverage = 'Moving Average',
  MovingAverageLevels = 'Moving Average Levels',
  ParabolicSar = 'Parabolic SAR',
  PivotPoints = 'Pivot Points',
  PriceChannel = 'Price Channel',
  ZigZag = 'Zig Zag'
}

export enum TrendIndicatorsRequests {
  AdaptiveMovingAverage = '',
  Alligator = 'alligator',
  BBandWithRatio = '',
  BollingerBands = 'bands',
  DeMarker = 'demarker',
  DonchianChannel = '',
  Envelopes = 'envelopes',
  Gator = 'gator',
  HeikinAshi = '',
  HmaColored = '',
  Ichimoku = 'ichimoku',
  KeltnerChannels = '',
  LinearRegressionChannel = '',
  MaColored = '',
  MaCrossoverSignals = '',
  MovingAverage = 'ma',
  MovingAverageLevels = '',
  ParabolicSar = '',
  PivotPoints = '',
  PriceChannel = '',
  ZigZag = ''
}

export enum Oscillators {
  AcceleratorOscillator = 'Accelerator Oscillator',
  Aroon = 'Aroon',
  AverageDirectionalMovementIndex = 'Average Directional Movement Index (ADX)',
  AwesomeOscillator = 'Awesome Oscillator',
  BearsPower = 'Bears Power',
  BullsPower = 'Bulls Power',
  MovingAverageConvergenceDivergence = 'MACD',
  MovingAverageConvergenceDivergenceNew = 'MACD new',
  Momentum = 'Momentum',
  OSMA = 'Oscillator of the Moving Average',
  PolarizedFractalEfficiency = 'Polarized Fractal Efficiency (PFE)',
  PriceDailyRange = 'Price Daily Range',
  RateOfChange = 'Rate of Change (ROC)',
  RelativeStrengthIndex = 'Relative Strength Index (RSI)',
  RelativeVigorIndex = 'Relative Vigor Index (RVI)',
  SolarWind = 'Solar Wind',
  Stochastic = 'Stochastic',
  TradersDynamicIndex = 'Traders Dynamic Index (TDI)',
  UltimateOscillator = 'Ultimate Oscillator',
  WilliamsPercentRange = "Williams' Percent Range"
}

export enum OscillatorsRequests {
  AcceleratorOscillator = '',
  Aroon = '',
  AverageDirectionalMovementIndex = 'adx',
  AwesomeOscillator = 'ao',
  BearsPower = 'bearsPower',
  BullsPower = 'bullsPower',
  MovingAverageConvergenceDivergence = 'macd',
  MovingAverageConvergenceDivergenceNew = '',
  Momentum = 'momentum',
  OSMA = 'osma',
  PolarizedFractalEfficiency = '',
  PriceDailyRange = '',
  RateOfChange = '',
  RelativeStrengthIndex = 'rsi',
  RelativeVigorIndex = 'rvi',
  SolarWind = '',
  Stochastic = 'so',
  TradersDynamicIndex = '',
  UltimateOscillator = '',
  WilliamsPercentRange = ''
}

export enum VolumeIndicators {
  MarketProfile = 'Market Profile',
  MFI = 'MFI',
  OnBalanceVolume = 'On Balance Volume (OBV)',
  Volume = 'Volume'
}

export enum VolumeIndicatorsRequests {
  MarketProfile = '',
  MFI = 'mfi',
  OnBalanceVolume = 'obv',
  Volume = 'volume'
}

export enum VolatilityIndicators {
  ADR = 'ADR',
  AverageTrueRange = 'Average True Range (ATR)'
}

export enum VolatilityIndicatorsRequests {
  ADR = 'adr',
  AverageTrueRange = 'atr'
}

export enum AdaptiveIndicators {
  FATL = 'FATL'
}

export enum AdaptiveIndicatorsRequests {
  FATL = 'fatl'
}

export enum CustomIndicators {
  AD = 'Accumulation / Distribution (A/D)',
  ATR_MA = 'Average True Range (MA)',
  BollingerBands = 'Bollinger Bands',
  CCI = 'Commodity Channel Index (CCI)',
  EldersForceIndex = "Elder's Force Index",
  EngulfingBar = 'Engulfing Bar',
  Fractals = 'Fractals',
  FLTM_SLTM = 'FLTM-SLTM',
  GannHiLo = 'Gann HiLo',
  iSessions = 'i-Sessions',
  InsideBar = 'Inside Bar',
  PCCI = 'PCCI',
  RangeExpansionsIndex = 'Range Expansions Index',
  TimeBox = 'Time Box'
}

export enum CustomIndicatorsRequests {
  AD = 'ad',
  ATR_MA = '',
  BollingerBands = '',
  CCI = 'cci',
  EldersForceIndex = 'force',
  EngulfingBar = '',
  Fractals = 'fractals',
  FLTM_SLTM = '',
  GannHiLo = '',
  iSessions = '',
  InsideBar = '',
  PCCI = '',
  RangeExpansionsIndex = '',
  TimeBox = ''
}

export enum Languages {
  English = 'English',
  Japanese = '日本人',
  Spanish = 'Español',
  Russian = 'Русский',
  German = 'Deutsch',
  Hungarian = 'Magyar',
  Polish = 'Polski',
  Indonesian = 'Bahasa Indonesia',
  Portuguese = 'Português',
  Dutch = 'Nederlands',
  Italian = 'Italiano',
  Thai = 'ไทย',
  Slovak = 'Slovenčina',
  Vietnamese = 'Tiếng Việt',
  Turkish = 'Türkçe',
  Czech = 'Čeština',
  ChineseSimplified = '简体中文',
  ChineseTraditional = '中国传统的'
}

export enum WebTesterLocale {
  English = 'en',
  Japanese = 'ja',
  Spanish = 'es',
  Russian = 'ru',
  German = 'de',
  Hungarian = 'hu',
  Polish = 'pl',
  Indonesian = 'id',
  Portuguese = 'pt',
  Dutch = 'nl',
  Italian = 'it',
  Thai = 'th',
  Slovak = 'sk',
  Vietnamese = 'vi',
  Turkish = 'tr',
  Czech = 'cs',
  ChineseSimplified = 'zh_hans',
  ChineseTraditional = 'zh_hant'
}

export enum LineStyle {
  Solid = 'Solid',
  ShortDash = 'ShortDash',
  ShortDash2 = 'ShortDash2',
  ShortDot = 'ShortDot',
  ShortDashDot = 'ShortDashDot',
  ShortDashDotDot = 'ShortDashDotDot',
  Dot = 'Dot',
  Dash = 'Dash',
  LongDash = 'LongDash',
  DashDot = 'DashDot',
  LongDashDot = 'LongDashDot',
  LongDashDotDot = 'LongDashDotDot',
  None = ''
}

export enum IndicatorComponentType {
  Line = 'line',
  Histogram = 'histogram',
  Rsi = 'rsi',
  Cci = 'cci',
  AverageDirectionalMovementIndex = 'AverageDirectionalMovementIndex',
  MovingAverageConvergenceDivergence = 'MovingAverageConvergenceDivergence',
  Stochastic = 'stochastic',
  Envelopes = 'envelopes',
  BollingerBands = 'bollingerBands'
}

export enum IndicatorPlacement {
  OnChart = 'On Chart',
  BelowChart = 'Below Chart'
}

export enum IndicatorType {
  Indicator = 'indicator',
  Oscillator = 'oscillator'
}

export enum MobileMenu {
  ChartActionsRow = 'chartActionsRow',
  ButtonsRow = 'buttonsRow',
  MyOrdersMenu = 'myOrdersMenu',
  ScrollableMenu = 'scrollableMenu',
  OpenPositionsMenu = 'openPositionsMenu',
  PendingOrdersMenu = 'pendingOrdersMenu',
  AccountHistoryMenu = 'accountHistoryMenu',
  StatisticsMenu = 'statisticsMenu',
  NotesMenu = 'notesMenu'
}

export enum HistoricalDataCategories {
  Majors = 'Majors',
  Crosses = 'Crosses',
  Crypto = 'Crypto',
  Exotic = 'Exotic',
  Futures = 'Futures',
  Indexes = 'Indexes',
  Metals = 'Metals',
  StocksCanada = 'Stocks (Canada)',
  StocksEurope = 'Stocks (Europe)',
  StocksOther = 'Stocks (Other)',
  StocksUK = 'Stocks (UK)',
  StocksUS = 'Stocks (US)',
  Others = 'Others'
}

export enum LocalStorage {
  CurrentFtProjectId = 'CurrentFtProjectId',
  CurrentFtProject = 'CurrentFtProject'
}

export enum IGraphicalLine {
  Line = 'LINE',
  Ray = 'RAY'
}

export enum ButtonWidth {
  ExtraSmall,
  Small,
  MediumExtended,
  Big,
  Big60,
  Big66,
  Big64,
  Large82,
  Large87,
  Large76,
  Large70
}

export enum WindowAlignmentType {
  InfoWindow = 0,
  AlignedToRibbonControl = 1,
  AlignedToFtControl = 2,
  AlignedToMessageBoxControl = 3,
  AlignedToFtTab = 4,
  AlignedToWindowSystemControl = 5,
  AlignedToLastBidPriceIconOnActiveChart = 6,
  AlignedToLastOrderOnActiveChart = 7,
  AlignedToRibbonContextWindow = 8,
  AlignedToChartBar = 9,
  AlignedToDateTimeScale = 10,
  AlignedToPriceScale = 11,
  AlignedToChartNews = 12,
  AlignedToStrategyListControl = 13,
  AlignedToWindowsMessageBoxControl = 14,
  AlignedToUpdateHistoryProgressWindow = 15,
  AlignedToLoadingHistoryMessageBox = 16,
  AlignedToLastAskPriceIconOnActiveChart = 17,
  AlignedToIndicatorMenuItemWindow = 18
}

export enum RefineMethod {
  LeftTop = 0,
  PercentOnlyWidth = 1,
  PercentOnlyHeight = 2
}

export enum TutorialControlType {
  Unknown = 0,
  Image = 1,
  Button = 2,
  TextControl = 3,
  ImageTextButton = 4,
  OutlineButton = 5
}

export enum FtControlType {
  Unknown = 0,
  RibbonControl = 1,
  RibbonCaption = 2,
  ForexTesterControl = 3,
  MessageBoxControl = 4,
  SystemControlClose = 5,
  WindowTabCaptions = 6,
  RibbonContextMenu = 7,
  ChartBar = 8,
  PriceScale = 9,
  DateTimeScale = 10,
  ChartNews = 11,
  RibbonGroup = 12,
  DefaultStrategyWindowControl = 13,
  StrategyWindowControlInGroup = 14,
  IndicatorName = 15
}

export enum RibbonControlId {
  Unknown = 0,
  Box = 1,
  TabCaption = 2,
  StartPauseTestControl = 3,
  StartTest = 4,
  ResumeTest = 5,
  PauseTest = 6,
  RestartProject = 7,
  NewProject = 8,
  SaveProject = 9,
  OpenProject = 10,
  TimeFrameButtonM1 = 11,
  TimeFrameButtonM5 = 12,
  TimeFrameButtonM15 = 13,
  TimeFrameButtonM30 = 14,
  TimeFrameButtonH1 = 15
}

export enum TutorialTabId {
  Unknown = 0,
  Home = 1,
  Charts = 2,
  Data = 3,
  Strategies = 4,
  Windows = 5,
  Settings = 6,
  Education = 7,
  Store = 8,
  Help = 9,
  Order = 10
}

export enum RibbonGroupId {
  UNKNOWN,
  HOME_START_TESTING,
  HOME_,
  HOME_PROJECT,
  HOME_SPEED_TESTING,
  HOME_TIMEFRAME,
  HOME_ACTION_AND_ORDERS,
  HOME_GRAPHICS,
  HOME_INDICATORS,
  ZOOM,
  NEWS,
  CHARTS_ADD_CHART,
  CHARTS_MODES,
  CHARTS_VIEW,
  CHARTS_TEMPLATES,
  CHARTS_DESKTOP,
  CROSSHAIR,
  CHARTS_OTHER,
  CHARTS_WINDOWS,
  CHARTS_SETTINGS,
  DATA_DATACENTER,
  DATA_SETTINGS,
  DATA_NEW_HISTORY,
  DATA_NEWS,
  STRATEGIES_LIST,
  STRATEGIES_TOOLS,
  WINDOWS_WINDOWS,
  WINDOWS_MARKET_WATCH_PANELS,
  WINDOWS_TRAIDING_PANELS,
  WINDOWS_OTHER_PANELS,
  SETTINGS_CHECK_FOR_UPDATES,
  SETTINGS_OPTIONS,
  SETTINGS_LANGUAGE,
  DISABLE_RIBBON,
  EDUCATION_QUICK_GUIDE,
  EDUCATION_COURESES,
  STORE_GET_LICENCE,
  STORE_HISTORICAL_DATA,
  STORE_TRADE_COPYRIGHT_SOFTWARE,
  STORE_VISUAL_BUILDER,
  STORE_FREE_SOFTWARE,
  HELP_HOW_TO_USE_MATERIALS,
  HELP_PARTNERS,
  HELP_HELP,
  HELP_CONTACTS,
  HELP_INFO,
  TRADING,
  ORDER_ACTIONS,
  BALANCE,
  ONE_CLICK_TRADING,
  LESSONS_UPDATE
}

export enum TutorialElementId {
  Unknown = 0,
  Box = 1,
  TabCaption = 2,
  StartPauseTestControl = 3,
  StartTest = 4,
  ResumeTest = 5,
  PauseTest = 6,
  RestartProject = 7,
  NewProject = 8,
  SaveProject = 9,
  OpenProject = 10,
  TimeFrameButtonM1 = 11,
  TimeFrameButtonM5 = 12,
  TimeFrameButtonM15 = 13,
  TimeFrameButtonM30 = 14,
  TimeFrameButtonH1 = 15,
  TimeFrameButtonH4 = 16,
  TimeFrameButtonD1 = 17,
  TimeFrameButtonW1 = 18,
  TimeFrameButtonMn = 19,
  TimeFrameButtonManage = 20,
  BoxTimeFrames = 21,
  ToggleTimeFramesGroup = 22,
  BarBack = 23,
  BarForward = 24,
  JumpTo = 25,
  Tick = 26,
  ActionsOrdersBox = 27,
  ManageSpeed = 28,
  MarketOrder = 29,
  PendingOrder = 30,
  SpeedOfTestingSlider = 31,
  ZoomIn = 32,
  ZoomOut = 33,
  MarketWatchAddSymbol = 34,
  ProfitChart = 35,
  AddNewChart = 36,
  AddNewRangeBarChart = 37,
  AddNewRenkoBarChart = 38,
  ChartModeBox = 39,
  ChartViewAutoScroll = 40,
  ChartViewOffset = 41,
  ChartViewMagnetMode = 42,
  ChartViewSyncCharts = 43,
  ChartViewShowVolume = 44,
  ChartViewShowGrid = 45,
  ChartViewShowPeriodSeparators = 46,
  ChartViewShowAccountHistory = 47,
  ChartViewShowNotes = 48,
  ChartViewShowNews = 49,
  CrosshairPointer = 50,
  CrosshairSync = 51,
  ChartWindowsCascade = 52,
  ChartWindowsMinimizeAll = 53,
  ChartWindowsTileVertical = 54,
  ChartWindowsTileHorizontal = 55,
  ChartCloseActiveWindow = 56,
  ChartMinimizeActiveWindow = 57,
  ChartScreenshot = 58,
  ChartSettings = 59,
  DataCenter = 60,
  ProjectDataSettings = 61,
  UpdateNews = 62,
  ListOfStrategies = 63,
  AddStrategy = 64,
  QuickTest = 65,
  SwitchExecution = 66,
  WindowsRestoreAll = 67,
  WindowsSymbols = 68,
  WindowsData = 69,
  WindowsTickChart = 70,
  WindowsStatistics = 71,
  WindowsOpenPosition = 72,
  WindowsPendingOrder = 73,
  WindowsAccountHistory = 74,
  WindowsScripts = 75,
  WindowsGraphicPanel = 76,
  WindowsNotes = 77,
  WindowsNews = 78,
  WindowsJournal = 79,
  SettingsCheckForUpdates = 80,
  SettingsDefaultOrders = 81,
  SettingsHotKeys = 82,
  SettingsSound = 83,
  StoreBuyLicence = 84,
  StoreRegisterProgram = 85,
  StoreStandardSubscription = 86,
  StoreVipSubscription = 87,
  StoreActivateDateSubscription = 88,
  StoreForexTradeCopier = 89,
  StoreForexCopierRemote = 90,
  StoreVisualStrategiesBuilder = 91,
  StoreVisualIndicatorBuilder = 92,
  HelpTutorialsVideo = 93,
  HelpFaq = 94,
  HelpUserGuidePdf = 95,
  HelpPartnershipPrograms = 96,
  HelpForexTesterHelp = 97,
  HelpApi = 98,
  HelpContactsUs = 99,
  HelpForum = 100,
  HelpAboutProgram = 101,
  DisableRibbon = 102,
  StoreStrategyList = 103,
  Unregistered = 104,
  StrategyOptimizer = 105,
  ChooseStrategyLabel = 106,
  OptimizingAlgorithmLabel = 107,
  SymbolAndDataLabel = 108,
  ParametersSettingsLabel = 109,
  CommonSettingsLabel = 110,
  BuyPremium = 111,
  ApplyBtn = 112,
  NextBtn = 113,
  PreviousBtn = 114,
  StartBtn = 115,
  CancelBtn = 116,
  RetryBtn = 117,
  SaveBtn = 118,
  SymbolSwitch = 119,
  BuySubscription = 120,
  OptimizingAlgorithmFullGrid = 121,
  OptimizingAlgorithmNeuralNetwork = 122,
  OptimizingAlgorithmXGBoost = 123,
  CheckboxSmallerBalance = 124,
  CheckboxBiggerBalance = 125,
  InitialDeposit = 126,
  StopCriteriaSmallerBalance = 127,
  StopCriteriaBiggerBalance = 128,
  EditSpinBoxFrom = 129,
  EditSpinBoxTo = 130,
  EditSpinBoxStep = 131,
  Checkbox = 132,
  ComboBoxWithCheckboxes = 133,
  DataAdvancedSettings = 134,
  TickRadioGroup = 135,
  UsingFloatingSpreadCheckbox = 136,
  GenerateTicksBasedOnOhlcRadioButton = 137,
  GenerateTicksRandomlyRadioButton = 138,
  OptimizerStartTesting = 139,
  OptimizerPauseTesting = 140,
  TestingGraphic = 141,
  TestingPoint = 142,
  EditOptimizationSettings = 143,
  ReconfigureOptimizationSettings = 144,
  EditSymbolAndDataSummary = 145,
  EditParametersSummary = 146,
  EditCommonSummary = 147,
  ChangeStrategy = 148,
  ResumeBtn = 149,
  ChangeAlgorithm = 150,
  NextBtnJumpToSymbolAndData = 151,
  RadioBtnUse1MinuteData = 152,
  RadioBtnUse1MinuteDataAndTickData = 153,
  ReportBtn = 154,
  PrevMonthButton = 155,
  NextMonthButton = 156,
  PrevYearButton = 157,
  NextYearButton = 158,
  CalendarDayButton = 159,
  ListOfIndicatorsButton = 160,
  QuickStartGuideButton = 161,
  BasicTutorialsButton = 162,
  IntermediateTutorialsButton = 163,
  AdvancedTutorialsButton = 164,
  EducationButton = 165,
  CloseTutorialWindow = 166,
  EnableStrategySwitch = 167,
  EditStrategyInVsb = 168,
  LoadFreeStrategy = 169,
  BuyStrategy = 170,
  StrategyListAi = 171,
  StrategyListDelete = 172,
  StrategyListSettings = 173,
  StrategyListSwitchExecution = 174,
  EnableAllStrategies = 175,
  AddStrategyFromFile = 176,
  CloseStrategyList = 177,
  StrategyListHelp = 178,
  BoxNewProject = 179,
  BoxSpeedOfTesting = 180,
  BoxGraphicElements = 181,
  BoxNews = 182,
  BoxChartTemplate = 183,
  BoxLanguage = 184,
  StoreIndicatorsList = 185,
  BoxUtilities = 186,
  BoxDesktop = 187,
  TutorialWindowFeedbackButton = 188,
  DisableAllStrategies = 189,
  ModifyOrder = 190,
  ClosePosition = 191,
  DisableAllCloseOrderStrategies = 192,
  CloseAllPositionsAndOrder = 193,
  OpenGroupOfOrders = 194,
  DuplicatePosition = 195,
  MoveStopLossToBreakeven = 196,
  ChangeBalance = 197,
  ForexSpeedUp = 198,
  CourseTitle = 199,
  Close = 200,
  SwcSearchStrategy = 201,
  ExecutionSwitch = 202,
  StrategyName = 203,
  StrategyDescription = 204,
  StrategyEdit = 205,
  StrategyList = 206,
  GlobalStrategyExecutionSwitch = 207,
  Burger = 208,
  Note = 209,
  VerticalLine = 210,
  HorizontalLine = 211,
  TrendLine = 212,
  Roy = 213,
  Polyline = 214,
  ListOfObjects = 215,
  SelectAllObjects = 216,
  DeselectObjects = 217,
  RectangleShape = 218,
  EllipseShape = 219,
  TriangleShape = 220,
  AndrewsPitchfork = 221,
  TextLabels = 222,
  WaveSymbols = 223,
  DeleteMostRecentObjects = 224,
  DeleteSelected = 225,
  ThumbUp = 226,
  ThumbWn = 227,
  ArrowUp = 228,
  ArrowWn = 229,
  StopIcon = 230,
  CheckIcon = 231,
  RightPriceLabel = 232,
  LeftPriceLabel = 233,
  PriceLabel = 234,
  FiboChannel = 235,
  FibonacciRetracement = 236,
  FibonacciTimeZone = 237,
  FibonacciFan = 238,
  FibonacciArc = 239,
  FibonacciExtension = 240,
  LinearRegressionChannel = 241,
  DeleteAll = 242,
  RotateWindowToVertical = 243,
  RotateWindowToHorizontal = 244,
  TutorialWindowStepCounterLabel = 245,
  TutorialWindowStepCounterPlaceholder = 246,
  FirstNonExistentControlId = 247,
  JumpToSearch = 248,
  JumpToGoTo = 249,
  ApplyAllCharts = 250,
  Search = 251,
  CopyPaintTool = 252,
  UpdateHistoryInProject = 253,
  RiskRewardBuy = 254,
  RiskRewardSell = 255,
  Forward = 256,
  ShowQuickGuideLessonList = 257,
  SkipQuickGuideNow = 258,
  NtShowStartupWindow = 259,
  StartQuickGuideLesson = 260,
  Back = 261,
  SettingsCheckForRibbonUpdates = 262,
  Restore = 263,
  Maximize = 264
}

export enum FtControlName {
  SymbolsPanel = 'MainForm.lDockSite.xxx111.xxx112.pSymbols.SymbolsPanel.table.grid',
  AccountHistoryGrid = 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid',
  AccountHistoryFooter = 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid.footer',
  StatisticsGrid = 'MainForm.lDockSite.xxx111.dxTabContainerDockSite2.pStatistics.StatsPanel.table.grid',
  MarketOrderCommonTab = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1',
  MarketOrderSymbol = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1.symbol',
  MarketOrderTabLot = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1.lot',
  MarketOrderLot = 'MarketOrderFrm.Panel2.cxPageControl1.lot',
  MarketOrderBuyMode = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1.MkPanel1.BuyMode',
  MarketOrderMkPanel = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1.MkPanel1',
  MarketOrderStopLossLevel = 'MarketOrderFrm.Panel2.cxPageControl1.StopLossLevel',
  MarketOrderStopLossBtn = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1.SetStopLossBtn',
  MarketOrderTakeProfitLevel = 'MarketOrderFrm.Panel2.cxPageControl1.TakeProfitLevel',
  MarketOrderSetTakeProfitBtn = 'MarketOrderFrm.Panel2.cxPageControl1.cxTabSheet1.SetTakeProfitBtn',
  OpenPositionsGrid = 'MainForm.bDockSite.dxContainer.pOpenPositions.TradePanel.table.grid',
  MarketOrderBuyBtn = 'MarketOrderFrm.Panel1.BuyBtn',
  MarketOrderSellBtn = 'MarketOrderFrm.Panel1.SellBtn',
  PendingOrderCommonTab = 'PendingOrderFrm.Panel2.cxPageControl1.cxTabSheet1',
  PendingOrderType = 'PendingOrderFrm.Panel2.cxPageControl1.cxTabSheet1.OrderType',
  PendingOrderPickPriceBtn = 'PendingOrderFrm.Panel2.cxPageControl1.cxTabSheet1.PickPriceBtn',
  PendingOrderPrice = 'PendingOrderFrm.Panel2.cxPageControl1.price',
  PendingOrderPlaceBtn = 'PendingOrderFrm.Panel1.PlaceBtn',
  PendingOrderFrame = 'MainForm.bDockSite.dxContainer.pPendingOrders.OrdersPanel.frame',
  PendingOrderGrid = 'MainForm.bDockSite.dxContainer.pPendingOrders.OrdersPanel.table.grid',
  MarketOrderFrm = 'MarketOrderFrm'
}

export enum EnableButtonRuleType {
  Unknown = 0,
  None = 1,
  DependFtButton = 2,
  DependFtEditControl = 3,
  DependFtComboBox = 4,
  Default = 5,
  DependFtDataCenterSymbolsList = 6,
  Last = 7
}

export enum EditRuleOption {
  None = 0,
  EnableDisable = 1,
  NotEmpty = 2,
  FixedValue = 3
}

export enum ButtonRuleOption {
  None = 0,
  EnableDisable = 1
}

export enum CourseNameRows {
  QuickStartTutorial = 'QuickStartTutorial',
  ForexCourses = 'ForexCourses'
}

export enum WindowAlignment {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
  CENTER,
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  ABSOLUTE_LEFT,
  ABSOLUTE_RIGHT,
  ABSOLUTE_TOP,
  ABSOLUTE_BOTTOM,
  ABSOLUTE_TOP_LEFT,
  ABSOLUTE_TOP_RIGHT,
  ABSOLUTE_BOTTOM_LEFT,
  ABSOLUTE_BOTTOM_RIGHT
}

export enum MenuItemId {
  UNKNOWN,
  BOX,
  NEW_PROJECT,
  SAVE_PROJECT,
  OPEN_PROJECT,
  CLOSE_PROJECT,
  MARKET_ORDER,
  PENDING_ORDER,
  MODIFY_ORDER,
  CLOSE_ORDER,
  CLOSE_ALL_POSITIONS_AND_ORDERS,
  OPEN_GROUP_OF_ORDERS,
  DUPLICATE_POSITION,
  DEPOSIT_MONEY,
  CHART_MODE_LINE,
  CHART_MODE_CANDLES,
  CHART_MODE_BARS,
  CHART_MODE_KAGI,
  MOVE_STOPLOSS_TO_BREAKEVEN,
  OPEN_FOREX_SPEED_UP,
  CURRENT_CHART,
  INSTALL_NEW_INDICATOR,
  ADAPTING_MOVING_AVERAGE_AMA2,
  ALLIGATOR_B_WILLIAMS,
  BBAND_WIDTH_RATIO,
  BOLLINGER_BANDS,
  DEMARKER,
  DONCHIAN_CHANNEL_MIDLINE,
  ENVELOPES,
  HEIKEN_ASHI,
  HMA_COLORED,
  ICHIMOKU,
  KELTNER_CHANNELS,
  LINEAR_REGRESSION_CHANNEL,
  MA_COLORED,
  MA_CROSSOVER_SIGNALS,
  MOVING_AVERAGE,
  MOVING_AVERAGE_LEVELS,
  PARABOLIC_SAR,
  PIVOT_POINTS,
  PRICE_CHANNEL,
  ZIGZAG,
  MARKET_PROFILE_OLD,
  MFI,
  ON_BALANCE_VOLUME_OBV,
  VOLUME,
  ACCELERATOR_OSCILLATOR_B_WILLIAMS,
  AROON,
  AVERAGE_DIRECTIONAL_MOVEMENT_INDEX_ADX,
  AWESOME_OSCILLATOR_B_WILLIAMS,
  BEARS_POWER,
  BULLS_POWER,
  MACD,
  MACD_NEW,
  MOMENTUM,
  POLARIZED_FRACTAL_EFFICIENCY_PFE,
  PRICE_DAILY_RANGE,
  RATE_OF_CHANGE_ROC,
  RSI,
  SOLAR_WIND,
  STOCHASTIC,
  TRADERS_DYNAMIC_INDEX_TDI,
  ULTIMATE_OSCILLATOR,
  WILLIAMS_PERCENT_RANG,
  ADR,
  AVERAGE_TRUE_RANG,
  FATL,
  GRAPHIC_PANEL,
  NOTE,
  VERTICAL_LINE,
  HORIZONTAL_LINE,
  TREND_LINE,
  ROY,
  POLYLINE,
  LIST_OF_OBJECTS,
  SELECT_ALL_OBJECTS,
  DESELECT_OBJECTS,
  RECTANGLE_SHAPE,
  ELLIPSE_SHAPE,
  TRIANGLE_SHAPE,
  ANDREWS_PITCHFORK,
  TEXT_LABELS,
  WAVE_SYMBOLS,
  DELETE_MOST_RECENT_OBJECTS,
  DELETE_SELECTED,
  DELETE_ALL,
  CUSTOM_TEXT_MENU_ITEM,
  AUTO_TICK,
  LOAD_DESKTOP,
  SAVE_DESKTOP,
  RESTORE_DEFAULT_DESKTOP,
  LANG_CZ,
  LANG_TUR,
  LANG_VT,
  LANG_SL,
  LANG_TAI,
  LANG_IT,
  LANG_NED,
  LANG_POR,
  LANG_ID,
  LANG_PL,
  LANG_HU,
  LANG_JP,
  LANG_DE,
  LANG_ES,
  LANG_RUS,
  LANG_EN,
  LANG_TRADITIONAL_CN,
  LANG_SIMPLIFIED_CN,
  TEMPLATES_SAVE_TEMPLATE,
  TEMPLATES_EDIT_TEMPLATE,
  TEMPLATES_LOAD_TEMPLATE,
  STRATEGIES_API_HELPER,
  INDICATORS_API_HELPER,
  SCRIPTS_API_HELPER,
  THUMB_UP,
  THUMB_DOWN,
  ARROW_UP,
  ARROW_DOWN,
  STOP_ICON,
  CHECK_ICON,
  RIGHT_PRICE_LABEL,
  LEFT_PRICE_LABEL,
  PRICE_LABEL,
  FIBO_CHANNEL,
  FIBONACCI_RETRACEMENT,
  FIBONACCI_TIME_ZONE,
  FIBONACCI_FAN,
  FIBONACCI_ARC,
  FIBONACCI_EXTENSION,
  FAVORITE_INDICATOR,
  COPY_PAINT_TOOL,
  SAVE_PROJECT_AS,
  DOWNLOAD_NEW_INDICATORS,
  // Optimizer
  ADD_NEW_STRATEGY,
  PROJECT_DATA_SETTINGS,
  CLOSE_MDI_TAB,
  RISK_REWARD_BUY,
  RISK_REWARD_SELL,

  TREND_INDICATORS_GROUP,
  VOLUME_INDICATORS_GROUP,
  OSCILATOR_INDICATORS_GROUP,
  ADAPTIVE_INDICATORS_GROUP,
  VOLATILITY_INDICATORS_GROUP,
  CUSTOM_INDICATORS_GROUP,
  SEARCH
}

export enum Urls {
  Live = 'https://app.forextester.com/',
  Beta = 'https://beta.forextester.com/',
  Staging = 'http://fto-staging.forextester.com/',
  Local = 'http://localhost:3000/'
}

export enum CourseType {
  QuickStartGuide = 'Quick Start',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
  Marketing = 'MARKETING'
}

export enum CongratulationsContentModalType {
  Basic = 'basic',
  Intro = 'intro',
  GreatJob = 'greatjob',
  Subscribe = 'subscribe'
}

export enum QuickStartTutorialSubtype {
  QuickStartGuide = 'QUICK START GUIDE'
}

export enum IntermediateTutorialSubtype {
  None = ''
}

export enum AdvancedTutorialSubtype {
  None = ''
}

export enum TutorialTabId {
  UNKNOWN = 0,
  HOME = 1,
  CHARTS = 2,
  DATA = 3,
  STRATEGIES = 4,
  WINDOWS = 5,
  SETTINGS = 6,
  EDUCATION = 7,
  STORE = 8,
  HELP = 9,
  ORDER = 10
}

export enum RibbonTabId {
  HOME = 0,
  ORDER = 1,
  CHARTS = 2,
  STRATEGIES = 3,
  WINDOWS = 4,
  SETTINGS = 5,
  EDUCATION = 6,
  STORE = 7,
  HELP = 8,
  UNKNOWN = 9
}

export enum GraphicToolType {
  TREND = 'trend_lines',
  RAY = 'rays',
  HORIZONTAL_LINE = 'horizontal_lines',
  VERTICAL_LINE = 'vertical_lines',
  RETRACEMENT = 'retracements',
  GANN_FAN = 'gann_fans',
  EQUIDISTANT_CHANNEL = 'equidistant_channels',
  STANDARD_DEVIATION_CHANNEL = 'standard_deviation_channels',
  RECTANGLE = 'rectangles',
  ELLIPSE = 'ellipses',
  TRIANGLE = 'triangles',
  TEXT = 'textList',
  RISK_REWARD = 'riskRewards'
}

export enum WindowOrientationType {
  FIRST,

  MAXIMAZE_ACTIVE,
  CASCADE,
  TILE_HORIZONTALLY,
  TILE_VERTICALLY,
  //add only here

  LAST,
  UNKNOWN
}

export enum CourseTypeServerSide {
  Unknown,
  Basic,
  Intermediate,
  Advanced,
  QuickStartGuide,
  Marketing
}

export enum DropdownType {
  NONE = 'none',
  PROJECT = 'project',
  ORDER_ACTIONS = 'order_actions',
  GRAPHIC_ELEMENTS = 'graphic_elements',
  INDICATORS = 'indicators',
  TICK_SIZE = 'tick_size'
}

export enum TimeFrame {
  M1 = 1,
  M5 = 5,
  M15 = 15,
  M30 = 30,
  H1 = 60,
  H4 = 240,
  D1 = 1440,
  W1 = 10080,
  MN = 43200
}

export enum TickSize {
  AUTO = -1,
  M1 = 1,
  M5 = 5,
  M15 = 15,
  M30 = 30,
  H1 = 60,
  H4 = 240,
  D1 = 1440,
  W1 = 10080,
  MN = 43200
}

export enum TriggerType {
  CLOSE_FOREGROUND_WINDOW = 0,
  CLOSE_ALL_ORDERS = 1,
  NEWS_APPEAR_ON_CHART = 2,
  WAIT_SELECT_WINDOW_TAB = 3,
  WAIT_MOUSE_CLICK_ON_AREA = 4,
  WAIT_LBUTTON_DOWN_ON_AREA = 5,
  WAIT_CONTROL_VALUE_CHANGE = 6,
  WAIT_OPEN_PENDING_ORDER = 7,
  LOADING_STEP = 8,
  WAIT_DATE_TIME = 9,
  WAIT_MOUSE_MOVE_IN_RECT = 10,
  //new type add here only

  LAST,
  UNKNOWN
}

export enum ProjectName {
  UNKNOWN = 'UNKNOWN',
  MARKETING = 'marketing',
  DEFAULT = 'default',
  BASIC_COURSES = 'basic_courses'
}

export enum EnableButtonRuleType {
  //add only to the end
  UNKNOWN,

  NONE,
  DEPEND_FT_BUTTON,
  DEPEND_FT_EDIT_CONTROL,
  DEPEND_FT_COMBOBOX,
  DEFAULT,
  DEPEND_FT_DATA_CENTER_SYMBOLS_LIST,

  LAST
}

export enum ComparisonOperator {
  EQUAL,
  NOT_EQUAL,
  LESS,
  GREATER,
  LESS_OR_EQUAL,
  GREATER_OR_EQUAL
}

export enum FtoIntroLinkType {
  DOWNLOAD = 'download',
  ORDER = 'order'
}

export enum FromFtoType {
  BASIC_BETA_VERSION = 'basic_bv',
  BASIC_GREAT_JOB = 'basic_gj',
  INTRO = 'intro',
  UNKNOWN = 'unknown'
}

export enum RunMode {
  BASIC = 'basic',
  MARKETING = 'intro',
  DEFAULT = 'default'
}

export enum ChartType {
  HYBRID = 'hybrid',
  SVG = 'svg'
}

export enum BasicLessonsID {
  WhatIsForex = 'What is Forex',
  WhatAreTheMajorCurrencyPairs = 'What are the major currency pairs',
  HowDoesForexTradingWork = 'How does Forex trading work',
  WhatIsTradedOnForex = 'What is traded on Forex',
  WhatAreCrosses = 'What are crosses',
  WhatAreExotics = 'What are exotics',
  WhatAreBaseAndQuoteCurrencies = 'What are base and quote currencies',
  WhatDoesGoingLongAndShortMean = 'What does going long and short mean',
  WhatIsBidAndAskPrice = 'What is bid and ask price',
  WhatIsAPipInForexTrading = 'What is a pip in Forex trading',
  WhatIsRolloverInTheForexMarket = 'What is rollover in the Forex market',
  WhatIsASpread = 'What is a spread',
  WhatIsThePipsWorth = "What is the pip's worth",
  WhatIsALotInForex = 'What is a lot in Forex',
  WhatIsAnOrder = 'What is an order',
  WhatIsAMarketOrder = 'What is a market order',
  WhatIsAPendingOrder = 'What is a pending order',
  BuyLimitSellLimitOrders = 'Buy Limit & Sell Limit orders',
  BuyStopSellStopOrders = 'Buy Stop & Sell Stop orders',
  WhatIsStopLoss = 'What is Stop Loss',
  WhatIsACommissionInForex = 'What is a commission in Forex',
  WhatIsASwapInForex = 'What is a swap in Forex',
  WhatIsLeverage = 'What is leverage',
  WhatIsMargin = 'What is margin',
  WhatIsFreeMargin = 'What is free margin',
  WhatIsMarginLevel = 'What is margin level',
  WhatIsAMarginCall = 'What is a margin call',
  WhatIsBalance = 'What is balance',
  WhatIsEquity = 'What is equity',
  WhatIsAPriceChart = 'What is a price chart',
  BarChart = 'Bar chart',
  CandlestickChart = 'Candlestick chart'
}

export enum SubscribeCampaignId {
  BASIC = 'basic',
  INTRO = 'intro',
  INTRO_BETATESTERS = 'intro_betatesters',
  INTRO_MOB_COUPON = 'intro_mob_coupon'
}
