export const marketingCourseMobileJa = {
  Type: 'MARKETING',
  CourseName: 'INTRO MOBILE',
  Position: 0,
  Name: 'INTRO MOBILE',
  OriginalLessonName: 'Intro mobile',
  LessonId: 2193466755,
  LearningTime: '5 minutes',
  Author: 'Forex Tester Team',
  Description: '',
  Steps: [
    {
      Name: '1. You want to become a better trader',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'M5'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 13,
        JumpTo: null,
        WndOrientation: 4,
        RemoveObjects: 0,
        TickPackage: 'M5'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'FXトレーダーになりたいですか？　\nFXトレード勉強を始めたばかりの初心者ですか？\nあなたが習った手法がホントに収益性があるのか知りたいですか？\nFT５には、必要なものがすべて揃ってます！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 94,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 420,
                Height: 210
              }
            }
          ],
          Buttons: [
            {
              Action: "2. Those who don't know what is backtesting",
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: "2. Those who don't know what is backtesting",
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'FXトレードの勉強でよく見るパターンはこんな感じ: 　\n1. 通りいっぺんな基礎知識をちょこっと勉強。\n2. ともかくデモ口座/リアル口座で売買してみる。\n3. それぞれの手法の良し悪しを数週間〜数ヶ月かけて使えるかを見定める。\n\n時間もかかり高リスクな勉強法です！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 133,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 420,
                Height: 180
              }
            }
          ],
          Buttons: [
            {
              Action: '3. With Forex Tester you can instantly teleport back in time',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 205,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '1. You want to become a better trader',
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 205,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '3. With Forex Tester you can instantly teleport back in time',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 10,
        TopPoint: 136,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'フォレックステスターさえあれば、長年にわたるホンモノの過去データを使い、あなたの手法を検証できます！\nデモ口座でのトレードに似てますが、もっとずっと効果的！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 78,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 420,
                Height: 210
              }
            }
          ],
          Buttons: [
            {
              Action: "2. Those who don't know what is backtesting",
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '4. Click “Start” to begin our time-travel journey!',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '4. Click “Start” to begin our time-travel journey!',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 11,
        TopPoint: 136,
        Width: 400,
        Height: 240
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '開始をタップします。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 10,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 370,
                Height: 100
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '5. Wake up, Neo... The simulation has begun!',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 66,
        TopPoint: 92,
        Width: 420,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'テストスピードのスライダーで、ライブに近い相場環境の速度を変えられます。\nデモ口座に比べて百倍以上速いスピードで、検証・練習できます！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 67,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 390,
                Height: 230
              }
            }
          ],
          Buttons: [
            {
              Action: '6. In Forex Tester you have an interactive account with virtual money',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: 'MainForm',
                SelectedOption: '1049',
                Value: ''
              },
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 11,
        FtControlType: 1,
        ControlId: 31,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '6. In Forex Tester you have an interactive account with virtual money',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'H1'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: '2020-02-04 17:00',
        WndOrientation: 1,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 350
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'フォレックステスターでは、バーチャル米ドルの口座を使います。　\nリアルお金をリスクにさらさない、安全で限りなくリアルに近いトレード環境のソフトであなたのトレードアイディアと手法をすべて試しましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 99,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 420,
                Height: 270
              }
            }
          ],
          Buttons: [
            {
              Action: '7. There are lots of trading tools to help you',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 305,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 2,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid'
      }
    },
    {
      Name: '7. There are lots of trading tools to help you',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 481,
        TopPoint: 49,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '数々のインジケーターなどのトレードツールが、あなたの手法向上に役立ちます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 37,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 23,
                Width: 415,
                Height: 223
              }
            }
          ],
          Buttons: [
            {
              Action: '8. Graphic elements will help you to mark interesting spots',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: 'ChartWindow_1',
                SelectedOption: '1049',
                Value: ''
              },
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '6. In Forex Tester you have an interactive account with virtual money',
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 0,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 1,
        GroupId: 8,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '8. Graphic elements will help you to mark interesting spots',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 400,
        TopPoint: 49,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'グラフィック・エレメントでチャートの「ココだ！」と思う所に印をつけます。\nさっそく、サポ・レジ・レベルを使った手法の一例で検証してみましょう。ユロドル(EURUSD)の二つの安値に、サポート・レベルの線を引くところから始めましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 116,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 29,
                TopPoint: 26,
                Width: 390,
                Height: 209
              }
            }
          ],
          Buttons: [
            {
              Action: '9. Here we are! We are already on the',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'ライ線を描画しよう',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '7. There are lots of trading tools to help you',
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 0,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 1,
        GroupId: 7,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '9. Here we are! We are already on the',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'このチャートのサポートレベルでは、プライスは既に２回タッチしてます。もう一度このレベルに押し目をつけたらエントリー・ポイントを定めます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 68,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 26,
                Width: 418,
                Height: 204
              }
            }
          ],
          Buttons: [
            {
              Action: '10. Resume the testing so we can see if our trend prediction is correct',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.10356,
                    DateStart: '2020-02-03T15:00:00+00:00',
                    PriceEnd: 1.10356,
                    DateEnd: '2020-02-04T15:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-03T18:00:00+00:00',
                    Price: 1.10356,
                    Text: 'サポート・レベル',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 11,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow'
      }
    },
    {
      Name: '10. Resume the testing so we can see if our trend prediction is correct',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 10,
        TopPoint: 136,
        Width: 450,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '検証チャートを「更新」します。もう一度プライスがサポート・レベルにふれたら、買い注文を入れます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 48,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 420,
                Height: 150
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '11. Now eyes on the chart',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 14,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 400,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'では、チャートにご注目を！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 13,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 191,
                Height: 92
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: [
          {
            Type: 9,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '12. Have you seen it? The price hit our support level again!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: '2020-02-05 05:55',
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '12. Have you seen it? The price hit our support level again!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 440,
        Height: 320
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'サポートレベルを割り込みました。もう一度同じパターンで押し目からの戻りを確認したら、買いの成り行き注文を入れましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 59,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 17,
                TopPoint: 27,
                Width: 413,
                Height: 241
              }
            }
          ],
          Buttons: [
            {
              Action: '13. Congratulations! We just have opened a new market order.',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '買い注文を建てる',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 275,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T05:00:00+00:00',
                    Price: 1.1041,
                    Text: '戻り目が見\nえました！',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 11,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '13. Congratulations! We just have opened a new market order.',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 425,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'いいですね！成り行き注文を入れました。検証チャートの「更新」をタップして、値動きを見ましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 47,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 24,
                Width: 394,
                Height: 149
              }
            }
          ],
          Buttons: [
            {
              Action: "14. Do you think we've made the right choice?",
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '検証を更新',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 300,
                TopPoint: 205,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 5,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [
                  {
                    OrderType: 'BUY',
                    Symbol: 'EURUSD',
                    Lot: 1,
                    StopLoss: 1.1032,
                    TakeProfit: 1.1061,
                    PriceValidationSettings: null
                  }
                ],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 11,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: "14. Do you think we've made the right choice?",
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 11,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 430,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'さて、その判断は正しかったでしょうか？',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 19,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 399,
                Height: 119
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: [
          {
            Type: 1,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '15. Oops! Seems like it wasn’t the best trade. But that’s okay!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '15. Oops! Seems like it wasn’t the best trade. But that’s okay!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 6,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '負けです！リアルお金じゃなかったのが、不幸中の幸いです。何を間違えたのでしょうか。\nFXトレードを始める時、まずどうしたら損失が少なくできるかを勉強します。損失にならなかったお金は儲けだからです！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 98,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 17,
                TopPoint: 24,
                Width: 418,
                Height: 228
              }
            }
          ],
          Buttons: [
            {
              Action: '16. Another great feature is the ability to work with multiple charts at a time',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 2,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid'
      }
    },
    {
      Name: '16. Another great feature is the ability to work with multiple charts at a time',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 149,
        TopPoint: 145,
        Width: 450,
        Height: 270
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '今の一時間足(H1)より長い4時間足(H4)で、より長期的な動きを見てみましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 41,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 24,
                Width: 419,
                Height: 195
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 16,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '17. On a daily timeframe, we see our mistake — it is a downward movement',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'H4'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 1,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 430,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '間違いが見つかりました。新しいトレンドは下落傾向を示しています。あと数日ほど様子を見てからもう一度、より確実性の高いトレンドラインを描きましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 73,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 25,
                Width: 399,
                Height: 206
              }
            }
          ],
          Buttons: [
            {
              Action: '18. Great! We have corrected our downtrend line.',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: 'ChartWindow_1',
                SelectedOption: '1049',
                Value: ''
              },
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 325,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T00:00:00+00:00',
                    Price: 1.105,
                    Text: '下降トレンド',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.1089,
                    DateStart: '2020-02-03T00:00:00+00:00',
                    PriceEnd: 1.10449,
                    DateEnd: '2020-02-05T00:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 12,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow'
      }
    },
    {
      Name: '18. Great! We have corrected our downtrend line.',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'H1'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: '2020-02-06 15:00',
        WndOrientation: 0,
        RemoveObjects: 1,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 270
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'プライスは確かな下落傾向に転じたように見えます。\nレジスタンス・レベルにもう一度タッチしたら、売り注文を入れます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 57,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 418,
                Height: 170
              }
            }
          ],
          Buttons: [
            {
              Action: "19. Let's watch",
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '検証を更新',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 325,
                TopPoint: 225,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.10479,
                    DateStart: '2020-02-05T08:00:00+00:00',
                    PriceEnd: 1.10138,
                    DateEnd: '2020-02-06T08:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T23:00:00+00:00',
                    Price: 1.1025,
                    Text: '下降トレンド',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 12,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: "19. Let's watch",
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 14,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 350,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'さて、注目！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 6,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 25,
                Width: 120,
                Height: 96
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: [
          {
            Type: 9,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '20. It bounced! That means it’s time to SELL!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: '2020-02-07 07:55',
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '20. It bounced! That means it’s time to SELL!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '戻り目が出ました。では、今回は売り注文を建てます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 25,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 25,
                Width: 419,
                Height: 225
              }
            }
          ],
          Buttons: [
            {
              Action: '21. This time it must work out!',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '売り注文を実行',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 255,
                Width: 100,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-07T07:00:00+00:00',
                    Price: 1.0984,
                    Text: '戻り目が見\nえました！',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 12,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '21. This time it must work out!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 400,
        Height: 240
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'うまくいくはずですが…！\nそうでしょ？',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 19,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 25,
                Width: 369,
                Height: 82
              }
            }
          ],
          Buttons: [],
          Images: []
        },
        StepActions: [
          {
            Type: 1,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '22. Hurray!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 5,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [
                  {
                    OrderType: 'SELL',
                    Symbol: 'EURUSD',
                    Lot: 1,
                    StopLoss: 1.09882,
                    TakeProfit: 1.0922,
                    PriceValidationSettings: null
                  }
                ],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '22. Hurray!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 460,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'やった！利確値に自動的にたどり着いて決済しました。\nデモ口座だと、この注文ひとつでおよそ80時間かかります。フォレックステスターなら、あっという間です。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 76,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 26,
                Width: 426,
                Height: 228
              }
            }
          ],
          Buttons: [
            {
              Action:
                '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-10T16:00:00+00:00',
                    Price: 1.09325,
                    Text: '＄447ドル\nの利確です。',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 11,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 460,
        Height: 360
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'これは検証できる数ある手法の中のほんの一部でしかありません:\n\n  ‣ インジケーター、日本の相場観、フィボナッチ・レベルなど、どのようなトレード理論でも検証できます。\n  ‣ 長期・中期(デイトレ・スイング)・短期の手法(スキャルピング)も検証できます。\n  ‣ 裁量トレードでも自動売買でも詳しい統計を見ながら検証できます。\n  ‣ AIベースのストラテジー最適化ツール。\n  ‣ リスク/リワード・ツール。\n\nトレード手法・精度向上のためのツール/機能が盛りだくさん！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 237,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 14,
                TopPoint: 25,
                Width: 423,
                Height: 292
              }
            }
          ],
          Buttons: [
            {
              Action: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '22. Hurray!',
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 0,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 96,
        TopPoint: 149,
        Width: 460,
        Height: 320
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'ビルドインの双方向FX学習コースで、ソフトウェアの中でFXトレード勉強を！ソフトの使い方とFXを一気に実践で習得できます！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 61,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 26,
                Width: 429,
                Height: 245
              }
            }
          ],
          Buttons: [
            {
              Action: '26. Time & money are your most valuable resources.',
              ActionType: 0,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 275,
                Width: 90,
                Height: 30
              }
            },
            {
              Action:
                '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 275,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 7,
        GroupId: 35,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '26. Time & money are your most valuable resources.',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 360
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '時は金なり！フォレックステスターは、収益性の高いトレード手法構築への道をひらきます。\n\n‣ 一晩のフォレックステスター検証 = デモ口座6ヶ月分のトレードができる！\n‣ いつでもどこでも検証できる。週末・休日の閉場日にも検証出来る！\n‣ 双方向FX学習コースは、ビルトインで無料。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 140,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 32,
                TopPoint: 27,
                Width: 387,
                Height: 275
              }
            }
          ],
          Buttons: [
            {
              Action: '--> Next Lesson',
              ActionType: 2,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '完了',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 315,
                Width: 100,
                Height: 30
              }
            },
            {
              Action: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
              ActionType: 1,
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 3,
        FtControlType: 0,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    }
  ]
}
