import React, { StrictMode, Suspense } from 'react'
import { Provider } from 'react-redux'
import Spinner from './components/helpers/Spinner'
import AppRouter from './components/routes/AppRouter'
import { store } from './redux/store/store'

let App: React.FC = () => {
  return (
    <StrictMode>
      <Suspense fallback={<Spinner />}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </Suspense>
    </StrictMode>
  )
}

export default App
