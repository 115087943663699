import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMobileMenuState } from './types'

export const initialMobileState: IMobileMenuState = {
  chartActionsRow: true,
  buttonsRow: true,
  oscillatorRow: true,
  scrollableMenu: false,
  myOrdersMenu: false,
  openPositionsMenu: false,
  pendingOrdersMenu: false,
  accountHistoryMenu: false,
  statisticsMenu: false,
  notesMenu: false,
  timeframesMenu: false,
  symbolsMenu: false
}

export const mobileSlice = createSlice({
  name: 'mobile',
  initialState: { ...initialMobileState },
  reducers: {
    toggleChartActionsRow(state, action: PayloadAction<boolean>) {
      state.chartActionsRow = action.payload
    },

    toggleButtonsRow(state, action: PayloadAction<boolean>) {
      state.buttonsRow = action.payload
    },

    toggleOscillatorRow(state, action: PayloadAction<boolean>) {
      state.oscillatorRow = action.payload
    },

    toggleScrollableMenu(state, action: PayloadAction<boolean>) {
      state.scrollableMenu = action.payload
    },

    toggleMyOrdersMenu(state, action: PayloadAction<boolean>) {
      state.myOrdersMenu = action.payload
      state.scrollableMenu = false
    },

    toggleOpenPositionsMenu(state, action: PayloadAction<boolean>) {
      state.openPositionsMenu = action.payload
    },

    togglePendingOrdersMenu(state, action: PayloadAction<boolean>) {
      state.pendingOrdersMenu = action.payload
    },

    toggleAccountHistoryMenu(state, action: PayloadAction<boolean>) {
      state.accountHistoryMenu = action.payload
      if (action.payload) {
        state.myOrdersMenu = action.payload
      }
    },

    toggleStatisticMenu(state, action: PayloadAction<boolean>) {
      state.statisticsMenu = action.payload
    },

    toggleNotesState(state, action: PayloadAction<boolean>) {
      state.notesMenu = action.payload
    },

    toggleTimeframesSelect(state, action: PayloadAction<boolean>) {
      state.timeframesMenu = action.payload
    },

    toggleSymbolsSelect(state, action: PayloadAction<boolean>) {
      state.symbolsMenu = action.payload
    },

    toggleMobileMenuInitialState() {
      return { ...initialMobileState }
    }
  }
})

export default mobileSlice.reducer
