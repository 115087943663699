import { WebTesterLocale } from '../enums'

export const urls = {
  orderPage: 'https://forextester.com/order',
  historyPage: 'https://forextester.com/historyservicedescription',
  tradeCopierPage: 'https://forexcopier.com/products/fcopier3',
  remoteCopierPage: 'https://forexcopier.com/products/fcremote2',
  vsbPage: 'https://easyforexbuilder.com/easy-forex-builder-v2/demo',
  vibPage: 'https://tools.forextester.com/demo',
  forexDevPage: 'https://4xdev.com/',
  forexSpeedUpPage: 'https://forexspeedup.com/',

  videoTutorialsPage: 'https://forextester.com/video',
  faqPage: 'https://forextester.com/faq',
  userGuidePdfPage: 'https://forextester.com/templates/doc/QuickStartGuide.pdf',
  partnershipProgramPage: 'https://forextester.com/forex-partnership-programs',
  apiHelpPage: 'https://forextester.com/forum/viewforum.php?f=8&sid=c6df436432e04812e022366af14b79fd',
  contactUsPage: 'https://forextester.com/contacts',
  forumPage: 'https://forextester.com/forum',
  aboutProgramPage: 'https://forextester.com/',
  ftHomePage: 'forextester.com',
  ftHomePageJP: 'forextester.jp',
  ftHomePageHttps: 'https://forextester.com/',
  ftHomePageHttpsJP: 'https://forextester.jp/',
  ftLink: (locale: WebTesterLocale, ftoTag: 'intro' | 'mobile_intro', link: string = '') => {
    let domain = 'com'
    switch (locale) {
      case WebTesterLocale.Japanese:
        domain = 'jp'
        break
      default:
        domain = 'com'
    }
    return `https://www.forextester.${domain}${link.length > 0 ? `/${link}` : ''}?from_fto=${ftoTag}`
  },
  // ftOrderIntroEN: 'https://www.forextester.com/order?from_fto=intro',
  // ftDownloadIntroEN: 'https://www.forextester.com/download?from_fto=intro',
  // ftOrderIntroJA: 'https://www.forextester.jp/order?from_fto=intro',
  // ftDownloadIntroJA: 'https://www.forextester.jp/download?from_fto=intro',
  ftPrivacyEN: 'https://forextester.com/privacy',
  ftPrivacyJP: 'https://forextester.jp/privacy'
}
