import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Shortcut } from '../../components/webTester/helpers/useShortcutValidator'

export interface ShortcutSliceState {
  disabledShortcuts: Shortcut[] // if shortcut is in this list, it'll be ignored on press
}

const initialState = { disabledShortcuts: [] } as ShortcutSliceState

const shortcutSlice = createSlice({
  name: 'shortcut',
  initialState,
  reducers: {
    disabledShortcut: (state: ShortcutSliceState, action: PayloadAction<Shortcut>) => {
      if (!state.disabledShortcuts.includes(action.payload)) state.disabledShortcuts.push(action.payload as Shortcut)
    },
    enableShortcut: (state: ShortcutSliceState, action: PayloadAction<Shortcut>) => {
      state.disabledShortcuts.splice(state.disabledShortcuts.indexOf(action.payload as Shortcut), 1)
    },
    enableAllShortcuts: (state) => {
      state.disabledShortcuts = []
    }
  }
})

export const { disabledShortcut, enableAllShortcuts, enableShortcut } = shortcutSlice.actions
export default shortcutSlice.reducer
