import { isDefined } from '../../libs/react-stockcharts/lib/utils'
import { RunMode, Urls, WebTesterLocale } from '../../utils/enums'
import { getBaseServerUrl } from '../../utils/requests/utils'

export class RouteService {
  private readonly params: URLSearchParams
  constructor(locationSearch: string) {
    this.params = new URLSearchParams(locationSearch)
  }

  getLanguage(): string {
    let lang = this.params.get('lang')

    if (!isDefined(lang) || !Object.values(WebTesterLocale).includes(lang as WebTesterLocale)) {
      lang = 'en'
    }

    return lang!
  }

  getRunMode(): RunMode {
    let runMode = this.params.get('run')
    if (window.location.pathname === '/default') {
      runMode = RunMode.DEFAULT
    } else if (getBaseServerUrl() === Urls.Live && !isDefined(runMode)) {
      runMode = RunMode.MARKETING
    } else if (!isDefined(runMode)) {
      runMode = RunMode.DEFAULT
    }

    return runMode as RunMode
  }

  getUserGroup(): string | null {
    return this.params.get('user_group')
  }

  getIsTestMode(): boolean {
    return this.params.has('test')
  }

  getIsAutotest(): boolean {
    return this.params.has('autotest')
  }

  clearRoute(lang: string, runMode: RunMode, isAutoTest: boolean) {
    if (isAutoTest) return

    if (window.location.pathname === '/default') return

    let url = ''
    if (getBaseServerUrl() === Urls.Live || runMode !== RunMode.DEFAULT) {
      url = `${window.location.origin}/?lang=${lang}&run=${runMode}${isAutoTest ? '&autotest' : ''}`
    } else if (runMode === RunMode.DEFAULT) {
      url = `${window.location.origin}/default`
    }

    if (url !== window.location.href) window.location.assign(url)
  }
}
