import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultVisibleBarsCount } from '../../components/chartContent/chart/chartUtils/constants'
import { ChartType } from '../../utils/enums'
import { IChart } from '../../utils/interfaces/IChart.interface'
import { INote } from '../../utils/interfaces/INote.interface'
import { defaultCharts } from '../../utils/projects/defaultProject'
import { DEFAULT_CHART_MODES, DEFAULT_CHART_SETTINGS, IGraphsState } from './types'

export const initialGraphsState: IGraphsState = {
  charts: [],
  currentChart: defaultCharts[0],
  chartsSettings: {},
  autoScrollIsActive: false,
  magnetModeIsActive: false,
  syncChartsIsActive: false,
  notes: [],
  endDate: 1580904000, // Feb 5, 2020
  isLoadingData: {},
  projectName: '',
  zoom: defaultVisibleBarsCount,
  chartType: ChartType.HYBRID,
  crosshair: false
}

export const graphsSlice = createSlice({
  name: 'graphs',
  initialState: { ...initialGraphsState },
  reducers: {
    assignAllGraphs: (state, action: PayloadAction<IGraphsState>) => {
      return { ...action.payload }
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload
    },
    generateChartSettings: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload] = DEFAULT_CHART_SETTINGS()
    },
    setNotes: (state, action: PayloadAction<INote[]>) => {
      state.notes = state.notes.concat(action.payload)
    },
    setTimeFrame: (state, action: PayloadAction<number>) => {
      state.currentChart.timeFrame = action.payload
      state.charts = state.charts.map((chart) =>
        chart.id === state.currentChart.id ? { ...chart, timeFrame: action.payload } : { ...chart }
      )
    },
    setEndDate: (state, action: PayloadAction<number>) => {
      state.endDate = action.payload
    },
    toggleLinesMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, linesModeIsActive: true }
    },
    toggleBarsMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, barsModeIsActive: true }
    },
    toggleCandlesMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, candlesModeIsActive: true }
    },
    toggleAreaMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, areaModeIsActive: true }
    },
    toggleKagiMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, kagiModeIsActive: true }
    },
    togglePointsAndFigureMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, pointAndFigureModeIsActive: true }
    },
    toggleRenkoBarsMode: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartModes = { ...DEFAULT_CHART_MODES, renkoBarsModeIsActive: true }
    },
    toggleAutoScroll: (state) => {
      state.autoScrollIsActive = !state.autoScrollIsActive
    },
    toggleChartOffset: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].chartOffsetIsActive =
        !state.chartsSettings[action.payload].chartOffsetIsActive
    },
    toggleMagnetMode: (state) => {
      state.magnetModeIsActive = !state.magnetModeIsActive
    },
    toggleSyncCharts: (state) => {
      state.syncChartsIsActive = !state.syncChartsIsActive
    },
    enableVolumes: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showVolumesIsActive = true
    },
    disableVolumes: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showVolumesIsActive = false
    },
    toggleShowGrid: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showGridIsActive = !state.chartsSettings[action.payload].showGridIsActive
    },
    toggleShowPeriodSeparators: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showPeriodSeparatorsIsActive =
        !state.chartsSettings[action.payload].showPeriodSeparatorsIsActive
    },
    toggleShowAccountHistory: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showAccountHistoryIsActive =
        !state.chartsSettings[action.payload].showAccountHistoryIsActive
    },
    toggleShowNotes: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showNotesIsActive = !state.chartsSettings[action.payload].showNotesIsActive
    },
    toggleShowNews: (state, action: PayloadAction<string>) => {
      state.chartsSettings[action.payload].showNewsIsActive = !state.chartsSettings[action.payload].showNewsIsActive
    },
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload
    },
    setIsLoading: {
      reducer(state, action: PayloadAction<{ isLoading; timeframe; symbol }>) {
        state.isLoadingData[action.payload.symbol] = {
          [action.payload.timeframe]: action.payload.isLoading
        }
      },
      prepare(...args) {
        return {
          payload: {
            isLoading: args[0],
            timeframe: args[1],
            symbol: args[2]
          }
        }
      }
    },
    cleanGraphsState: (state) => {
      return { ...initialGraphsState }
    },
    setChartType: (state, action: PayloadAction<ChartType>) => {
      state.chartType = action.payload
    },
    addCharts: (state, action: PayloadAction<IChart[]>) => {
      state.charts = state.charts.concat(action.payload)
    },
    updateCurrentChart: (state, action: PayloadAction<IChart>) => {
      state.currentChart = { ...action.payload }
    },
    toggleCrosshair: (state) => {
      state.crosshair = !state.crosshair
    },
    setYZoom: {
      reducer(state, action: PayloadAction<{ chartId: string; yZoom: number }>) {
        const { chartId, yZoom } = action.payload
        state.charts = [...state.charts.map((chart) => (chart.id === chartId ? { ...chart, yZoom } : chart))]
        state.currentChart.yZoom = yZoom
      },
      prepare(...args) {
        return {
          payload: {
            chartId: args[0],
            yZoom: args[1]
          }
        }
      }
    }
  }
})

export default graphsSlice.reducer
