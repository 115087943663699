export const marketingCourseJa = {
  Type: 'MARKETING',
  CourseName: 'INTRO',
  Position: 0,
  Name: 'INTRO',
  OriginalLessonName: 'Intro',
  LessonId: 3690879943,
  LearningTime: '5 minutes',
  Author: 'Forex Tester Team',
  Description: '',
  Steps: [
    {
      Name: '1. You want to become a better trader',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'M5'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 13,
        JumpTo: null,
        WndOrientation: 4,
        RemoveObjects: 0,
        TickPackage: 'M5'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 310
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'トレーダーを目指していますか？',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 15,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 29,
                TopPoint: 26,
                Width: 385,
                Height: 35
              }
            },
            {
              Content:
                'FXトレードについて学習を始めたばかりの初心者の方ですか？\n\nトレード手法に心当たりはあるけれど、その手法に収益性があるかどうか分からない？\n\nこちらをご覧ください！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 49,
                  Weight: 400
                },
                {
                  StartIndex: 49,
                  Length: 4,
                  Weight: 700
                },
                {
                  StartIndex: 53,
                  Length: 19,
                  Weight: 400
                },
                {
                  StartIndex: 72,
                  Length: 11,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 149,
                TopPoint: 65,
                Width: 281,
                Height: 187
              }
            }
          ],
          Buttons: [
            {
              Action: "2. Those who don't know what is backtesting",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 265,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-5.png',
              Layout: {
                LeftPoint: 17,
                TopPoint: 78,
                Width: 153,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: "2. Those who don't know what is backtesting",
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'FXトレード学習者によくあるパターンは？',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 20,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 26,
                Width: 408,
                Height: 41
              }
            },
            {
              Content:
                'たいてい少しばかり基礎的なことを学んだら、デモ口座で数週間〜数ヶ月ぐらいでその手法が使えるのか、使えないのか確認しようといきなり「飛び込みます」。\n\nコレ、いちばん高リスクで時間がかかる方法です！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 21,
                  Weight: 400
                },
                {
                  StartIndex: 21,
                  Length: 12,
                  Weight: 500
                },
                {
                  StartIndex: 33,
                  Length: 49,
                  Weight: 400
                },
                {
                  StartIndex: 82,
                  Length: 11,
                  Weight: 500
                },
                {
                  StartIndex: 93,
                  Length: 5,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 117,
                TopPoint: 62,
                Width: 313,
                Height: 186
              }
            }
          ],
          Buttons: [
            {
              Action: '3. With Forex Tester you can instantly teleport back in time',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '王道ってないの？',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 250,
                Width: 100,
                Height: 35
              }
            },
            {
              Action: '1. You want to become a better trader',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-1.png',
              Layout: {
                LeftPoint: -9,
                TopPoint: 78,
                Width: 145,
                Height: 145
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '3. With Forex Tester you can instantly teleport back in time',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 10,
        TopPoint: 136,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'フォレックステスターを使い、時間旅行ができます！実際の10年以上の過去データを使ってあなたの手法を検証！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 24,
                  Weight: 400
                },
                {
                  StartIndex: 24,
                  Length: 28,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 116,
                TopPoint: 29,
                Width: 313,
                Height: 112
              }
            },
            {
              Content: 'デモ口座でトレードするよりずっと効果的で時短カンタン！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 20,
                  Weight: 400
                },
                {
                  StartIndex: 20,
                  Length: 7,
                  Weight: 700
                }
              ],
              Layout: {
                LeftPoint: 116,
                TopPoint: 146,
                Width: 290,
                Height: 62
              }
            }
          ],
          Buttons: [
            {
              Action: "2. Those who don't know what is backtesting",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '4. Click “Start” to begin our time-travel journey!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-5.png',
              Layout: {
                LeftPoint: -7,
                TopPoint: 52,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '4. Click “Start” to begin our time-travel journey!',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 11,
        TopPoint: 136,
        Width: 400,
        Height: 240
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '「開始」をクリックして時間旅行にレッツゴー！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 4,
                  Weight: 700
                },
                {
                  StartIndex: 4,
                  Length: 18,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 159,
                TopPoint: 65,
                Width: 210,
                Height: 92
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Beamy-4.png',
              Layout: {
                LeftPoint: 5,
                TopPoint: 39,
                Width: 142,
                Height: 142
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '5. Wake up, Neo... The simulation has begun!',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 37,
        TopPoint: 92,
        Width: 420,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'さあ起きてネオ…シミュレーションの始まりだ！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 22,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 52,
                TopPoint: 25,
                Width: 360,
                Height: 30
              }
            },
            {
              Content:
                '一時停止・戻る・早送りなどシミュレーションなら時間も短縮。 すべてのチャートと時間枠はシンクロして動きます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 30,
                  Weight: 400
                },
                {
                  StartIndex: 30,
                  Length: 24,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 60,
                TopPoint: 125,
                Width: 343,
                Height: 83
              }
            },
            {
              Content: 'バーを動かして、テストスピードを変えてみましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 25,
                  Weight: 700
                }
              ],
              Layout: {
                LeftPoint: 60,
                TopPoint: 206,
                Width: 345,
                Height: 37
              }
            },
            {
              Content:
                'いまは、2020年2月にいます。 価格は実際の市場と同じ動きを示しています。 そして、あなたは時間を操れます！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 55,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 60,
                TopPoint: 60,
                Width: 344,
                Height: 65
              }
            }
          ],
          Buttons: [
            {
              Action: '6. In Forex Tester you have an interactive account with virtual money',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: '\nMainForm\n',
                SelectedOption: '1049',
                Value: ''
              },
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\NEO_TILTED_1.png',
              Layout: {
                LeftPoint: -71,
                TopPoint: 45,
                Width: 140,
                Height: 160
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 8,
        FtControlType: 1,
        ControlId: 31,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '6. In Forex Tester you have an interactive account with virtual money',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'H1'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: '2020-02-04 17:00',
        WndOrientation: 1,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 350
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'フォレックステスターでは、バーチャル資産でトレード口座を開設できます。 リアル資産を高リスクにさらさず、安全でリアルに近い環境であなたの手法やアイディアを試せます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 13,
                  Weight: 400
                },
                {
                  StartIndex: 13,
                  Length: 17,
                  Weight: 500
                },
                {
                  StartIndex: 30,
                  Length: 52,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 20,
                TopPoint: 28,
                Width: 399,
                Height: 77
              }
            },
            {
              Content:
                'デモ口座より数百倍速く、検証・トレード練習ができます！たとえて言えば、ウサギとジェット機の駆けくらべ！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 11,
                  Weight: 500
                },
                {
                  StartIndex: 11,
                  Length: 40,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 217,
                Width: 418,
                Height: 83
              }
            }
          ],
          Buttons: [
            {
              Action: '7. There are lots of trading tools to help you',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 305,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Jet_JP.png',
              Layout: {
                LeftPoint: 25,
                TopPoint: 118,
                Width: 400,
                Height: 89
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 5,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid'
      }
    },
    {
      Name: '7. There are lots of trading tools to help you',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 481,
        TopPoint: 49,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'あなたの前進に役立つトレード・ツールも盛りだくさん！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 26,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 23,
                Width: 420,
                Height: 43
              }
            },
            {
              Content: 'その一つがトレンド・ボリューム、オシレーターなどのインジケーターです。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 25,
                  Weight: 400
                },
                {
                  StartIndex: 25,
                  Length: 7,
                  Weight: 500
                },
                {
                  StartIndex: 32,
                  Length: 3,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 71,
                Width: 414,
                Height: 55
              }
            },
            {
              Content: 'リボン・メニューのインジケーター・リストから探せます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 9,
                  Weight: 400
                },
                {
                  StartIndex: 9,
                  Length: 11,
                  Weight: 500
                },
                {
                  StartIndex: 20,
                  Length: 7,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 11,
                TopPoint: 125,
                Width: 433,
                Height: 37
              }
            }
          ],
          Buttons: [
            {
              Action: '8. Graphic elements will help you to mark interesting spots',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: '\nChartWindow_1\n',
                SelectedOption: '1049',
                Value: ''
              },
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '6. In Forex Tester you have an interactive account with virtual money',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\indicators_2_1.png',
              Layout: {
                LeftPoint: 6,
                TopPoint: 161,
                Width: 438,
                Height: 90
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 0,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 1,
        GroupId: 8,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '8. Graphic elements will help you to mark interesting spots',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 400,
        TopPoint: 49,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '描画ツールで、チャートに書き込み・印つけができます。さっそく書き込んでみましょう。\n\nたとえば、ある手法を試すのにサポ・レジ・レベルを使って検証すると仮定します。\n\nユロドル(EURUSD)の二点の安値をつないで、サポート・レベルを描きましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 5,
                  Weight: 500
                },
                {
                  StartIndex: 5,
                  Length: 94,
                  Weight: 400
                },
                {
                  StartIndex: 99,
                  Length: 24,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 29,
                TopPoint: 26,
                Width: 390,
                Height: 209
              }
            }
          ],
          Buttons: [
            {
              Action: '9. Here we are! We are already on the',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'ライ線を描画しよう',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 255,
                Width: 120,
                Height: 30
              }
            },
            {
              Action: '7. There are lots of trading tools to help you',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 0,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 1,
        GroupId: 7,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '9. Here we are! We are already on the',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '出来ました。サポート・ラインを描きました。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 6,
                  Weight: 400
                },
                {
                  StartIndex: 6,
                  Length: 8,
                  Weight: 500
                },
                {
                  StartIndex: 14,
                  Length: 7,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 18,
                Width: 388,
                Height: 60
              }
            },
            {
              Content:
                'すでに二回、このサポート・レベルに触れてます。\n\nもう一回、この押し目が起こるか見てから買い注文のポイントを探ります。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 25,
                  Weight: 400
                },
                {
                  StartIndex: 25,
                  Length: 23,
                  Weight: 500
                },
                {
                  StartIndex: 48,
                  Length: 11,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 25,
                TopPoint: 82,
                Width: 304,
                Height: 126
              }
            }
          ],
          Buttons: [
            {
              Action: '10. Resume the testing so we can see if our trend prediction is correct',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-2.png',
              Layout: {
                LeftPoint: 310,
                TopPoint: 70,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.10356,
                    DateStart: '2020-02-03T15:00:00+00:00',
                    PriceEnd: 1.10356,
                    DateEnd: '2020-02-04T15:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-03T18:00:00+00:00',
                    Price: 1.10356,
                    Text: 'サポート・レベルはここですね。',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-03T15:00:00+00:00',
                    Price: 1.10356,
                    Text: '1',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-04T16:00:00+00:00',
                    Price: 1.10356,
                    Text: '2',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow'
      }
    },
    {
      Name: '10. Resume the testing so we can see if our trend prediction is correct',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 10,
        TopPoint: 136,
        Width: 450,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '予想が正しいか「更新」ボタンをクリックしてします。\n\n抵抗線を三回、下抜けしました。まだ安値のうちに買い玉を建てます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 27,
                  Weight: 400
                },
                {
                  StartIndex: 27,
                  Length: 26,
                  Weight: 700
                },
                {
                  StartIndex: 53,
                  Length: 6,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 143,
                TopPoint: 42,
                Width: 281,
                Height: 140
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-4.png',
              Layout: {
                LeftPoint: 18,
                TopPoint: 47,
                Width: 130,
                Height: 130
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '11. Now eyes on the chart',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 13,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 400,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'では、チャートの動きを見ましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 17,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 38,
                TopPoint: 72,
                Width: 191,
                Height: 92
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-4.png',
              Layout: {
                LeftPoint: 223,
                TopPoint: 43,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 9,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '12. Have you seen it? The price hit our support level again!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: '2020-02-05 05:55',
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '12. Have you seen it? The price hit our support level again!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 440,
        Height: 320
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '見ましたか？プライスが下抜けして切り上がりましたね！\n\nもう一度プライスが抵抗線にふれて切り上がるのを確認できたら、成り行き注文を入れてみましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 28,
                  Weight: 400
                },
                {
                  StartIndex: 28,
                  Length: 37,
                  Weight: 500
                },
                {
                  StartIndex: 65,
                  Length: 9,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 16,
                TopPoint: 20,
                Width: 416,
                Height: 95
              }
            },
            {
              Content:
                '買い注文の建て玉を入れます：\n  ‣ 標準サイズロット\n  ‣ 損切値は抵抗線より下に設定\n  ‣ 利確値は直近高値に設定',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 4,
                  Weight: 500
                },
                {
                  StartIndex: 4,
                  Length: 32,
                  Weight: 400
                },
                {
                  StartIndex: 36,
                  Length: 9,
                  Weight: 500
                },
                {
                  StartIndex: 45,
                  Length: 9,
                  Weight: 400
                },
                {
                  StartIndex: 54,
                  Length: 7,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 142,
                TopPoint: 138,
                Width: 282,
                Height: 110
              }
            }
          ],
          Buttons: [
            {
              Action: '13. Congratulations! We just have opened a new market order.',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '買い注文を建てる',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 275,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-3-market-order-jp.png',
              Layout: {
                LeftPoint: -1,
                TopPoint: 131,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T00:00:00+00:00',
                    Price: 1.1031,
                    Text: '戻り目が見えました！',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '13. Congratulations! We just have opened a new market order.',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 425,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'おめでとうございます！成り行き注文を実行しました！\n\n「更新」をクリックして検証を再開し、値動きのゆくえを見ましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 11,
                  Weight: 400
                },
                {
                  StartIndex: 11,
                  Length: 34,
                  Weight: 700
                },
                {
                  StartIndex: 45,
                  Length: 14,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 145,
                TopPoint: 35,
                Width: 266,
                Height: 154
              }
            }
          ],
          Buttons: [
            {
              Action: "14. Do you think we've made the right choice?",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '検証を更新',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 300,
                TopPoint: 205,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-5.png',
              Layout: {
                LeftPoint: 13,
                TopPoint: 37,
                Width: 153,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T03:00:00+00:00',
                    Price: 1.10435,
                    Text: 'ここで買い注\n文を入れます。',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 5,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [
                  {
                    OrderType: 'BUY',
                    Symbol: 'EURUSD',
                    Lot: 1,
                    StopLoss: 1.1032,
                    TakeProfit: 1.1061,
                    PriceValidationSettings: null
                  }
                ],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: "14. Do you think we've made the right choice?",
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 10,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 430,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'さて、この判断は正しい選択だったのでしょうか？\n\n見てみましょう！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 33,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 148,
                TopPoint: 61,
                Width: 265,
                Height: 117
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-1.png',
              Layout: {
                LeftPoint: 1,
                TopPoint: 40,
                Width: 150,
                Height: 151
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 1,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '15. Oops! Seems like it wasn’t the best trade. But that’s okay!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '15. Oops! Seems like it wasn’t the best trade. But that’s okay!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 6,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'えぇ？どうも判断違いだったようです。でも大丈夫！\n\nリアルな資産ではないです。ここでは、試行錯誤を繰り返して学ぶために検証します。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 26,
                  Weight: 400
                },
                {
                  StartIndex: 26,
                  Length: 10,
                  Weight: 500
                },
                {
                  StartIndex: 36,
                  Length: 29,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 118,
                TopPoint: 23,
                Width: 323,
                Height: 149
              }
            },
            {
              Content:
                'FXトレードで稼げるようになるには、まず損失を減らすにはどうするか学ぶところから始めます。\n節約も儲けです！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 46,
                  Weight: 400
                },
                {
                  StartIndex: 46,
                  Length: 8,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 34,
                TopPoint: 175,
                Width: 392,
                Height: 75
              }
            }
          ],
          Buttons: [
            {
              Action: '16. Another great feature is the ability to work with multiple charts at a time',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-7.png',
              Layout: {
                LeftPoint: -10,
                TopPoint: 19,
                Width: 150,
                Height: 151
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 5,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid'
      }
    },
    {
      Name: '16. Another great feature is the ability to work with multiple charts at a time',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 149,
        TopPoint: 145,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '複数の時間枠チャートを同時進行で検証する機能もある！\n\nもう少し長い時間枠での動きを見るために、1時間足と４時間足のチャートを作成します。いまのユロドル(EURUSD)の一時間足と４時間足です。\n\nウィンドウ→垂直表示をクリックしてみましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 15,
                  Weight: 700
                },
                {
                  StartIndex: 15,
                  Length: 84,
                  Weight: 400
                },
                {
                  StartIndex: 99,
                  Length: 10,
                  Weight: 700
                },
                {
                  StartIndex: 109,
                  Length: 13,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 120,
                TopPoint: 19,
                Width: 315,
                Height: 211
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-5.png',
              Layout: {
                LeftPoint: -16,
                TopPoint: 46,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 54,
        RibbonTabId: 5,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '17. On a daily timeframe, we see our mistake — it is a downward movement',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 1,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 430,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '４時間足で、何が間違っていたのかが見られます。 さっき二つのチャートに描いたトレンド線とは違う、明らかな下落トレンドが見られます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 4,
                  Weight: 500
                },
                {
                  StartIndex: 4,
                  Length: 48,
                  Weight: 400
                },
                {
                  StartIndex: 52,
                  Length: 6,
                  Weight: 500
                },
                {
                  StartIndex: 58,
                  Length: 7,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 118,
                TopPoint: 17,
                Width: 288,
                Height: 115
              }
            },
            {
              Content:
                '次のステップでは、数日後の過去データで下落トレンド線がよりくっきりと描けるようになった時点を見てみましょう。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 19,
                  Weight: 400
                },
                {
                  StartIndex: 19,
                  Length: 7,
                  Weight: 700
                },
                {
                  StartIndex: 26,
                  Length: 28,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 118,
                TopPoint: 134,
                Width: 279,
                Height: 78
              }
            }
          ],
          Buttons: [
            {
              Action: '18. Great! We have corrected our downtrend line.',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: '\nChartWindow_1\n',
                SelectedOption: '1049',
                Value: ''
              },
              Content: 'トレンド線を描こう！',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 305,
                TopPoint: 230,
                Width: 110,
                Height: 35
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-3.png',
              Layout: {
                LeftPoint: -11,
                TopPoint: 58,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T00:00:00+00:00',
                    Price: 1.105,
                    Text: '下降トレンドですね。',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.1089,
                    DateStart: '2020-02-03T00:00:00+00:00',
                    PriceEnd: 1.10449,
                    DateEnd: '2020-02-05T00:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow'
      }
    },
    {
      Name: '18. Great! We have corrected our downtrend line.',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'H1'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: '2020-02-06 15:00',
        WndOrientation: 0,
        RemoveObjects: 1,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 270
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'すばらしい！ 二つのチャートに、よりハッキリした下落トレンド線を描けました。 プライスは今のところ、トレンド方向に向かっています。\n\nもう一度、下落トレンド線に高値が切り上げて来たら売り注文を入れます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 16,
                  Weight: 400
                },
                {
                  StartIndex: 16,
                  Length: 22,
                  Weight: 700
                },
                {
                  StartIndex: 38,
                  Length: 29,
                  Weight: 400
                },
                {
                  StartIndex: 67,
                  Length: 34,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 117,
                TopPoint: 30,
                Width: 317,
                Height: 184
              }
            }
          ],
          Buttons: [
            {
              Action: "19. Let's watch",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '検証を更新',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 325,
                TopPoint: 225,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-2.png',
              Layout: {
                LeftPoint: -4,
                TopPoint: 52,
                Width: 140,
                Height: 140
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.10479,
                    DateStart: '2020-02-05T08:00:00+00:00',
                    PriceEnd: 1.10138,
                    DateEnd: '2020-02-06T08:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T23:00:00+00:00',
                    Price: 1.1025,
                    Text: 'より確かな下降トレンド。',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: "19. Let's watch",
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 14,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 350,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'では、ご注目を！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 8,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 167,
                TopPoint: 68,
                Width: 165,
                Height: 102
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-6.png',
              Layout: {
                LeftPoint: 13,
                TopPoint: 38,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 9,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '20. It bounced! That means it’s time to SELL!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: '2020-02-07 07:55',
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '20. It bounced! That means it’s time to SELL!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '戻り目です！売り注文のタイミングと言えます！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 22,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 28,
                Width: 410,
                Height: 27
              }
            },
            {
              Content: '成り行き注文を入れますが、今回は売り注文です。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 23,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 58,
                Width: 411,
                Height: 65
              }
            },
            {
              Content:
                '売り注文を入れるには：\n  ‣ 同じ標準ロット\n  ‣ 損切値は下落トレンドと直近高値の上に設定\n  ‣ 利確値は、損切値の二倍以上を設定',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 4,
                  Weight: 500
                },
                {
                  StartIndex: 4,
                  Length: 65,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 140,
                TopPoint: 118,
                Width: 294,
                Height: 125
              }
            }
          ],
          Buttons: [
            {
              Action: '21. This time it must work out!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '売り注文を実行',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 255,
                Width: 100,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-5-3-jp.png',
              Layout: {
                LeftPoint: -5,
                TopPoint: 125,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-07T07:00:00+00:00',
                    Price: 1.0984,
                    Text: '戻り目が見\nえました！',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '21. This time it must work out!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 400,
        Height: 240
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: '今度こそ…うまく行け！\nうまく行って…お願い！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 23,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 170,
                TopPoint: 80,
                Width: 201,
                Height: 79
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-1.png',
              Layout: {
                LeftPoint: 21,
                TopPoint: 39,
                Width: 150,
                Height: 151
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 1,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '22. Hurray!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 5,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [
                  {
                    OrderType: 'SELL',
                    Symbol: 'EURUSD',
                    Lot: 1,
                    StopLoss: 1.09882,
                    TakeProfit: 1.0922,
                    PriceValidationSettings: null
                  }
                ],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '22. Hurray!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 460,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'やった！利確値で自動的にポジションが決済されました！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 4,
                  Weight: 500
                },
                {
                  StartIndex: 4,
                  Length: 22,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 17,
                TopPoint: 24,
                Width: 429,
                Height: 54
              }
            },
            {
              Content:
                '2月7日に注文を実行して、決済は2月10日でした。もしデモ口座なら、あなたの注文が決済されるまで80時間近く待てたでしょうか？\n\nフォレックステスターなら、数秒でできます。スピード感とリアリティーを体験しましょう！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 4,
                  Weight: 500
                },
                {
                  StartIndex: 4,
                  Length: 12,
                  Weight: 400
                },
                {
                  StartIndex: 16,
                  Length: 5,
                  Weight: 500
                },
                {
                  StartIndex: 21,
                  Length: 27,
                  Weight: 400
                },
                {
                  StartIndex: 48,
                  Length: 6,
                  Weight: 500
                },
                {
                  StartIndex: 54,
                  Length: 11,
                  Weight: 400
                },
                {
                  StartIndex: 65,
                  Length: 20,
                  Weight: 500
                },
                {
                  StartIndex: 85,
                  Length: 22,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 17,
                TopPoint: 75,
                Width: 331,
                Height: 167
              }
            }
          ],
          Buttons: [
            {
              Action: '23. Statistics are collected automatically!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Брат-1-3.png',
              Layout: {
                LeftPoint: 321,
                TopPoint: 88,
                Width: 135,
                Height: 135
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-10T16:00:00+00:00',
                    Price: 1.09325,
                    Text: 'おめでとう\nございます！\n＄447ドル\nの利確です。',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '23. Statistics are collected automatically!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 330
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'トレーダーの日進月歩に役立つのが、統計です。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 17,
                  Weight: 400
                },
                {
                  StartIndex: 17,
                  Length: 2,
                  Weight: 500
                },
                {
                  StartIndex: 19,
                  Length: 3,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 19,
                TopPoint: 27,
                Width: 413,
                Height: 47
              }
            },
            {
              Content:
                '統計では、あなたのトレード成績がトレードが終わるごとに更新され表示されます。いつでも、あなたのトレード成績を分岐点ごとに分析できます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 47,
                  Weight: 400
                },
                {
                  StartIndex: 47,
                  Length: 15,
                  Weight: 500
                },
                {
                  StartIndex: 62,
                  Length: 5,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 19,
                TopPoint: 79,
                Width: 299,
                Height: 132
              }
            },
            {
              Content: '振り返りが、数字ベースでできます！気長に待つ必要なし！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 27,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 19,
                TopPoint: 211,
                Width: 299,
                Height: 55
              }
            }
          ],
          Buttons: [
            {
              Action:
                '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 285,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '22. Hurray!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 285,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-1.png',
              Layout: {
                LeftPoint: 299,
                TopPoint: 73,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 1,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.lDockSite.xxx111.dxTabContainerDockSite2.pStatistics.StatsPanel.table.grid'
      }
    },
    {
      Name: '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 460,
        Height: 360
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'この事例は、描画ツールをもとにしたテクニカル分析の中の事例の一つを検証してみただけに過ぎません。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 48,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 23,
                Width: 417,
                Height: 67
              }
            },
            {
              Content:
                'この中に含まれているのは：\n  ‣ 日本のローソク足・フィボナッチ・レベルなど必須のツールをもとに検証。\n  ‣ 短期・中期・長期足で手法検証。\n  ‣ 裁量トレードでもEAでも検証。\n  ‣ AI機能を駆使したストラテジー最適化。\n  ‣ リスク/リワード・ツール(描画)。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 18,
                  Weight: 400
                },
                {
                  StartIndex: 18,
                  Length: 21,
                  Weight: 500
                },
                {
                  StartIndex: 39,
                  Length: 18,
                  Weight: 400
                },
                {
                  StartIndex: 57,
                  Length: 9,
                  Weight: 500
                },
                {
                  StartIndex: 66,
                  Length: 11,
                  Weight: 400
                },
                {
                  StartIndex: 77,
                  Length: 14,
                  Weight: 500
                },
                {
                  StartIndex: 91,
                  Length: 47,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 89,
                Width: 416,
                Height: 157
              }
            },
            {
              Content: 'トレード手法の相性探し・精度向上のために役立つ機能とツールが満載！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 33,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 252,
                Width: 419,
                Height: 55
              }
            }
          ],
          Buttons: [
            {
              Action: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '23. Statistics are collected automatically!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 0,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 96,
        TopPoint: 149,
        Width: 460,
        Height: 320
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'フォレックステスターはあなたのトレード手法のアイディアを実際に検証できるだけじゃない！双方向学習コースがソフトの中にビルトイン！',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 43,
                  Weight: 400
                },
                {
                  StartIndex: 43,
                  Length: 8,
                  Weight: 500
                },
                {
                  StartIndex: 51,
                  Length: 7,
                  Weight: 400
                },
                {
                  StartIndex: 58,
                  Length: 6,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 18,
                TopPoint: 26,
                Width: 416,
                Height: 71
              }
            },
            {
              Content:
                '必要な知識が実践形式にオールインワンで、そこにある！\n\n講義だけだと5％、読書で10％、討論で50％しか記憶に残らないのに比べて、実践形式で得た情報は最大75％記憶に残ると言われているからです。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 18,
                  Weight: 500
                },
                {
                  StartIndex: 18,
                  Length: 47,
                  Weight: 400
                },
                {
                  StartIndex: 65,
                  Length: 20,
                  Weight: 500
                },
                {
                  StartIndex: 85,
                  Length: 12,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 18,
                TopPoint: 101,
                Width: 330,
                Height: 165
              }
            }
          ],
          Buttons: [
            {
              Action: '26. Time & money are your most valuable resources.',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '次へ',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 275,
                Width: 90,
                Height: 30
              }
            },
            {
              Action:
                '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 275,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-1.png',
              Layout: {
                LeftPoint: 319,
                TopPoint: 99,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 7,
        GroupId: 35,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '26. Time & money are your most valuable resources.',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 450
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                '時間とお金は、もっとも価値ある二大資産！フォレックステスターはあなたの二大資産を節約し、あなたにピッタリなトレード手法の検証に役立ちます。\n\n‣ フォレックステスターでひと晩検証 = デモ口座6ヶ月分のトレードが出来る！\n\n‣ マーケット閉場後の週末でも、いつでもどこでもあなたの手法を検証できる！\n\n‣ 双方向FX学習コースは、ソフトウェアの機能のひとつとして　デモ版(無料)でも使えます。',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 20,
                  Weight: 700
                },
                {
                  StartIndex: 20,
                  Length: 64,
                  Weight: 400
                },
                {
                  StartIndex: 84,
                  Length: 3,
                  Weight: 700
                },
                {
                  StartIndex: 87,
                  Length: 9,
                  Weight: 400
                },
                {
                  StartIndex: 96,
                  Length: 4,
                  Weight: 700
                },
                {
                  StartIndex: 100,
                  Length: 28,
                  Weight: 400
                },
                {
                  StartIndex: 128,
                  Length: 17,
                  Weight: 700
                },
                {
                  StartIndex: 145,
                  Length: 8,
                  Weight: 400
                },
                {
                  StartIndex: 153,
                  Length: 12,
                  Weight: 700
                },
                {
                  StartIndex: 165,
                  Length: 17,
                  Weight: 400
                },
                {
                  StartIndex: 182,
                  Length: 14,
                  Weight: 700
                }
              ],
              Layout: {
                LeftPoint: 32,
                TopPoint: 31,
                Width: 387,
                Height: 275
              }
            }
          ],
          Buttons: [
            {
              Action: null,
              WebLink: 'www.forextester.jp/download?from_fto=intro',
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'ウィンドウズPCにダウンロード',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 235,
                TopPoint: 325,
                Width: 190,
                Height: 40
              }
            },
            {
              Action: '--> Next Lesson',
              WebLink: null,
              ActiveButtonColor: '1262cf',
              DefaultButtonColor: '8bc2ec',
              ActiveTextColor: 'ffffff',
              DefaultTextColor: 'ffffff',
              EnableButtonRule: null,
              Content: '完了',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 405,
                Width: 100,
                Height: 30
              }
            },
            {
              Action: null,
              WebLink: 'www.forextester.jp/order?from_fto=intro',
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'フォレックステスターを購入',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 25,
                TopPoint: 325,
                Width: 190,
                Height: 40
              }
            },
            {
              Action: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
              WebLink: null,
              ActiveButtonColor: '1262cf',
              DefaultButtonColor: '8bc2ec',
              ActiveTextColor: 'ffffff',
              DefaultTextColor: 'ffffff',
              EnableButtonRule: null,
              Content: '戻る',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 405,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 3,
        FtControlType: 0,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    }
  ]
}
