import TagManager from 'react-gtm-module'
import { Urls } from '../enums'
import { getBaseServerUrl } from '../requests/utils'

export const getWindowSize = () => {
  const isClient = typeof window === 'object'

  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  }
}

export const openNewTabHandler = (url: string) => {
  window.open(url, '_blank')
}

export const initializeJivoChat = () => {
  let widget_id = '0EqwzGEclY'
  const live_widget_id = '82yKuk21eL'
  let d = document
  let w = window

  if (getBaseServerUrl() !== Urls.Beta) {
    widget_id = live_widget_id
  }

  function l() {
    let s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = '//code-eu1.jivosite.com/widget/' + widget_id
    let ss = document.getElementsByTagName('script')[0]
    //@ts-ignore
    ss.parentNode.insertBefore(s, ss)
  }

  if (d.readyState === 'complete') {
    l()
  } else {
    //@ts-ignore
    if (w.attachEvent) {
      //@ts-ignore
      w.attachEvent('onload', l)
    } else {
      //@ts-ignore
      w.addEventListener('load', l, false)
    }
  }
}

// Google Analytics & Microsoft Clarity are already within Google Tag Manager container
export const initializeGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-MQ4939K'
  }

  TagManager.initialize(tagManagerArgs)
}
