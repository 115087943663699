import React from 'react'
import css from './Spinner.module.scss'

const Spinner: React.FC = () => {
  return (
    <div className={css.container}>
      <div className={`spinner-border text-primary ${css.spinner}`} role='status'>
        <span className='visually-hidden' />
      </div>
    </div>
  )
}

export default Spinner
