import { MarketOrderTypes, PendingOrderTypes } from '../../../utils/enums'

export const isMarketOrderValid = (
  forecastType: string = 'buy',
  sl: number = 0,
  tp: number = 0,
  close: number = 0.00001
): [boolean, boolean] => {
  if (close > 0) {
    let isStopLossValid: boolean = false
    let isTakeProfitValid: boolean = false

    if (forecastType === MarketOrderTypes.Buy) {
      isStopLossValid = sl === 0 || sl < close
      isTakeProfitValid = tp === 0 || tp > close

      return [isStopLossValid, isTakeProfitValid]
    } else if (forecastType === MarketOrderTypes.Sell) {
      isStopLossValid = sl === 0 || sl > close
      isTakeProfitValid = tp === 0 || tp < close

      return [isStopLossValid, isTakeProfitValid]
    }
  }
  return [false, false]
}
export const isPendingOrderValid = (
  type: string,
  atPrice: number,
  sl: number,
  tp: number,
  close: number
): [boolean, boolean, boolean] => {
  let isAtPriceValid: boolean = false
  let isStopLossValid: boolean = false
  let isTakeProfitValid: boolean = false

  atPrice = atPrice < 0 ? 0 : atPrice
  close = close < 0 ? 0 : close
  sl = sl < 0 ? 0 : sl
  tp = tp < 0 ? 0 : tp

  switch (type) {
    case PendingOrderTypes.BuyLimit:
      isAtPriceValid = atPrice < close && atPrice !== 0
      isStopLossValid = sl === 0 || sl < atPrice
      isTakeProfitValid = tp === 0 || tp > atPrice

      return [isAtPriceValid, isStopLossValid, isTakeProfitValid]

    case PendingOrderTypes.SellLimit:
      isAtPriceValid = atPrice > close && atPrice !== 0
      isStopLossValid = sl === 0 || sl > atPrice
      isTakeProfitValid = tp === 0 || tp < atPrice

      return [isAtPriceValid, isStopLossValid, isTakeProfitValid]

    case PendingOrderTypes.BuyStop:
      isAtPriceValid = atPrice > close && atPrice !== 0 // 1.1408 > 1.1409 => false
      isStopLossValid = sl === 0 || sl < atPrice // 1.1407 < 1.1408 => true
      isTakeProfitValid = tp === 0 || tp > atPrice // 1.1408 > 1.1408 => false

      return [isAtPriceValid, isStopLossValid, isTakeProfitValid]

    case PendingOrderTypes.SellStop:
      isAtPriceValid = atPrice < close && atPrice !== 0
      isStopLossValid = sl === 0 || sl > atPrice
      isTakeProfitValid = tp === 0 || tp < atPrice

      return [isAtPriceValid, isStopLossValid, isTakeProfitValid]
    default:
      return [isAtPriceValid, isStopLossValid, isTakeProfitValid]
  }
}
export const calculateMarketOrderRisk = (
  balance: number,
  riskPercentage: number,
  sl: number,
  close: number,
  // todo: add later
  // dollarsPerPipPerLot: number,
  digitsAfterPoint: number
): number => {
  const risk: number = riskPercentage / 100 // 5% / 100 = 0.05
  const sumToRisk: number = balance * risk // $10000 * 0.05 = $500
  let slFloat: number = 0
  let slInPips: number = 0
  let calculatedLots: number = 0

  if (close > sl) {
    slFloat = close - sl // 1.3722 - 1.3622 = 0.0100
  } else if (close < sl) {
    slFloat = sl - close // 1.3722 - 1.3622 = 0.0100
  }

  slInPips = slFloat * Math.pow(10, digitsAfterPoint) // 0.0100 * 10000 = 100pips

  calculatedLots = Number((sumToRisk / slInPips).toFixed(2))

  if (sl === 0 || close === sl) {
    calculatedLots = 0
  }

  return calculatedLots
}
export const calculatePendingOrderRisk = (
  balance: number,
  riskPercentage: number,
  sl: number,
  close: number,
  // todo: add later
  // dollarsPerPipPerLot: number,
  digitsAfterPoint: number
): number => {
  const risk: number = riskPercentage / 100 // 5% / 100 = 0.05
  let sumToRisk: number = balance * risk // $10000 * 0.05 = $500
  let slFloat: number = 0
  let slInPips: number = 0
  let calculatedLots: number = 0

  if (close > sl) {
    slFloat = close - sl // 1.3722 - 1.3622 = 0.0100
  } else if (close < sl) {
    slFloat = sl - close // 1.3722 - 1.3622 = 0.0100
  }

  slInPips = slFloat * Math.pow(10, digitsAfterPoint) // 0.0100 * 10000 = 100pips

  calculatedLots = Number((sumToRisk / slInPips).toFixed(2))

  if (close === sl) {
    calculatedLots = 0
  }

  return calculatedLots
}
