import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { isDefined } from '../../libs/react-stockcharts/lib/utils'
import { IJoyrideState } from '../../redux/joyride/types'
import { SubscribeCampaignId } from '../../utils/enums'
import { getBaseServerUrl } from '../../utils/requests/utils'
import { UserIdentityService } from '../UserIdentityService/UserIdentityService'
import {
  CourseEventDto,
  CourseEventType,
  FtoLaunchedDto,
  LessonEventDto,
  LessonEventType,
  UIEventDto,
  UIEventType
} from './telemetryTypes'

export enum LikeLessonState {
  NONE,
  LIKE,
  DISLIKE
}

function getDevice() {
  return isMobile ? 'MOBILE' : 'DESKTOP'
}

class TelemetryService {
  private readonly userIdentityService: UserIdentityService | undefined
  private apiClient = axios.create({
    baseURL: getBaseServerUrl(),
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' }
  })
  private readonly ftoRunMode: string
  private readonly userGroup: number | undefined

  constructor(userIdentityService: UserIdentityService, ftoRunMode: string, userGroup: number | undefined) {
    this.userIdentityService = userIdentityService
    this.ftoRunMode = ftoRunMode
    this.userGroup = userGroup
  }

  async sendFtoLaunched(language: string) {
    const ftoLaunchedDto: FtoLaunchedDto = {
      userId: this.userIdentityService?.id!,
      userType: this.userIdentityService?.userType!,
      clientVersion: process.env.REACT_APP_CLIENT_VERSION!,
      uiLang: language,
      timestamp: moment().local().format(),
      userGroup: this.userGroup,
      FTORunMode: this.ftoRunMode
    }

    try {
      const response = await this.apiClient.post('telemetry/api/data/ftoStartedEvents', ftoLaunchedDto)

      if (response.status !== StatusCodes.OK) {
        console.warn(`Status code: ${response.status}. Sending 'FtoLaunched' was failed`)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  async sendUIEvent(elementId: string, language: string, eventType: UIEventType) {
    const uiEventDto: UIEventDto = {
      userId: this.userIdentityService?.id!,
      userType: this.userIdentityService?.userType!,
      clientVersion: process.env.REACT_APP_CLIENT_VERSION!,
      EventType: eventType,
      UiElementName: elementId,
      uiLang: language,
      timestamp: moment().local().format(),
      userGroup: this.userGroup,
      FTORunMode: this.ftoRunMode
    }

    try {
      const response = await this.apiClient.post('telemetry/api/data/uiEvents', uiEventDto)

      if (response.status !== StatusCodes.OK) {
        console.warn(`Status code: ${response.status}. Sending 'UIEvent' ${eventType} was failed`)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  async sendLessonUnavailableEvent(
    courseName: string,
    lessonName: string,
    lessonNumber: number,
    lessonStepNumber: number,
    originalLessonName: string,
    lessonId: number,
    language: string,
    eventType: LessonEventType
  ) {
    const lessonEventDto: LessonEventDto = {
      userId: this.userIdentityService?.id!,
      userType: this.userIdentityService?.userType!,
      clientVersion: process.env.REACT_APP_CLIENT_VERSION!,
      timestamp: moment().local().format(),
      eventType,
      courseName,
      lessonName,
      lessonNumber,
      lessonStepNumber,
      uiLang: language,
      originalLessonName,
      lessonId,
      userGroup: this.userGroup,
      FTORunMode: this.ftoRunMode
    }

    try {
      const response = await this.apiClient.post('telemetry/api/data/lessonEvents', lessonEventDto)

      if (response.status !== StatusCodes.OK) {
        console.warn(`Status code: ${response.status}. Sending 'lessonEventDto' ${eventType} was failed`)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  async sendLessonEvent(joyride: IJoyrideState, language: string, eventType: LessonEventType) {
    const { CourseName, Name, Position, LessonId, OriginalLessonName } = joyride.originalTutorial

    await this.sendLessonUnavailableEvent(
      CourseName,
      Name,
      Position + 1,
      joyride.step + 1,
      OriginalLessonName,
      LessonId,
      language,
      eventType
    )
  }

  async sendCourseEvent(courseName: string, language: string, eventType: CourseEventType): Promise<boolean> {
    const courseEventDto: CourseEventDto = {
      userId: this.userIdentityService?.id!,
      userType: this.userIdentityService?.userType!,
      clientVersion: process.env.REACT_APP_CLIENT_VERSION!,
      uiLang: language,
      timestamp: moment().local().format(),
      userGroup: this.userGroup,
      courseName,
      eventType,
      FTORunMode: this.ftoRunMode
    }

    try {
      const response = await this.apiClient.post('telemetry/api/data/courseEvents', courseEventDto)

      if (response.status !== StatusCodes.OK) {
        console.warn(`Status code: ${response.status}. Sending 'courseEventDt' ${eventType} was failed`)
        return false
      }
    } catch (e) {
      console.warn(e)
      return false
    }

    return true
  }

  async subscribe(email: string, uiLang: string, campaignCode: SubscribeCampaignId) {
    const subscribeDto = {
      email,
      uiLang,
      campaignCode
    }

    let data = {
      status: StatusCodes.BAD_REQUEST,
      code: '0'
    }

    try {
      const response = await this.apiClient.post('/telemetry/api/mailinglist/contacts', subscribeDto)
      data.status = response.status
      return data
    } catch (e) {
      const error = e as AxiosError
      if (isDefined(error.response)) {
        data.status = error.response?.status!
        data.code = error.response?.data.Code
        return data
      }

      data.status = StatusCodes.BAD_GATEWAY
      return data
    }
  }
}

export default TelemetryService
