import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITestingState } from './types'

export const initTestingState: ITestingState = {
  tickSize: -1
}

export const testingSlice = createSlice({
  name: 'testing',
  initialState: { ...initTestingState },
  reducers: {
    setTickSize(state, action: PayloadAction<number>) {
      state.tickSize = action.payload
    },
    assignAllTesting(state, action: PayloadAction<ITestingState>) {
      return { ...action.payload }
    }
  }
})

export const { setTickSize, assignAllTesting } = testingSlice.actions

export default testingSlice.reducer
