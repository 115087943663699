import { TimeFrame } from '../enums'

/* 	Naming Principles:
	Target element by its name (e.g. 'Open Positions').
	Most of the names are unique.
	For those names that are not (e.g. 'Ticket' exists in all three Bottom Menu tabs) create two variants:
	1. variable with the text content for humans (e.g. openPositions: 'Open Positions')
	2. variable with the datatest-id for tests (e.g. openPositionsTicket: 'openPositionsTicket')
*/
export enum Tabs {
  HomeTab = 'Home',
  OrdersTab = 'Orders',
  ChartsTab = 'Charts',
  StrategiesTab = 'Strategies',
  WindowsTab = 'Windows',
  EducationTab = 'Education',
  SettingsTab = 'Settings',
  StoreTab = 'Store',
  HelpTab = 'Help',
  SymbolsTab = 'Symbols',
  StatisticsTab = 'Statistics',
  TickChartTab = 'TickChart',
  OpenPositionsTab = 'Open Positions',
  PendingOrdersTab = 'Pending Orders',
  AccountHistoryTab = 'Account History',
  JournalTab = 'Journal',
  NewsTab = 'News',
  NotesTab = 'Notes',
  EurusdTab = 'eurusd',
  GbpusdTab = 'gbpusd',
  UsdjpyTab = 'usdjpy'
}

export enum TabsID {
  HomeTab = 'HomeTabID',
  OrdersTab = 'OrdersTabID',
  ChartsTab = 'ChartsTabID',
  StrategiesTab = 'StrategiesTabID',
  WindowsTab = 'WindowsTabID',
  EducationTab = 'EducationTabID',
  SettingsTab = 'SettingsTabID',
  StoreTab = 'StoreTabID',
  HelpTab = 'HelpTabID',
  SymbolsTab = 'SymbolsTabID',
  StatisticsTab = 'StatisticsTabID',
  TickChartTab = 'TickChartTabID',
  OpenPositionsTab = 'Open PositionsTabID',
  PendingOrdersTab = 'Pending OrdersTabID',
  AccountHistoryTab = 'Account HistoryTabID',
  JournalTab = 'JournalTabID',
  NewsTab = 'NewsTabID',
  NotesTab = 'NotesTabID',
  EurusdTab = 'eurusdTabID',
  GbpusdTab = 'gbpusdTabID',
  UsdjpyTab = 'usdjpyTabID'
}

export enum GraphicsPanelId {
  Note = '698',
  Lines = '4203',
  Objects = '4209',
  ObjectsSelections = '-1',
  SelectAllObjects = '611',
  DeselectAllObjects = '612',
  ListOfObjects = '610',
  VerticalLine = '569',
  HorizontalLine = '571',
  TrendLine = '573',
  Polyline = '576',
  Ray = '575',
  Shapes = '1030',
  Rectangle = '600',
  Ellipse = '601',
  Triangle = '602',
  Icons = '-1',
  ThumbUp = '665',
  ThumbDown = '666',
  ArrowUp = '667',
  ArrowDown = '667',
  StopIcon = '669',
  CheckIcon = '670',
  RightPriceLabel = '671',
  LeftPriceLabel = '672',
  PriceLabel = '673',
  FibonacciTools = '1028',
  FibonacciRetracement = '584',
  FibonacciTimeZones = '586',
  FibonacciFan = '588',
  FibonacciArc = '590',
  FibonacciExtension = '592',
  FibonacciChannel = '595',
  Channels = '1029',
  LinearRegressionChannel = '596',
  StandardDeviationChannel = '-1',
  EquidistantChannel = '-1',
  GannFan = '-1',
  AndrewsPitchfork = '582',
  OtherTools = '-1',
  TextLabel = '578',
  WaveSymbols = '580',
  DeleteMostRecentObject = '613',
  DeleteSelectedObjects = '615',
  DeleteAllObjects = '614',
  DeleteAllIndicators = '-1',
  CloseButton = '-1',
  RewardToRisk = '4222',
  RewardToRiskBuy = '4247',
  RewardToRiskSell = '4248'
}

export enum ToolsTypes {
  TextList = 'textList',
  TrendLines = 'trend_lines',
  VerticalLines = 'vertical_lines',
  HorizontalLines = 'horizontal_lines',
  Retracements = 'retracements',
  GannFans = 'gann_fans',
  EquidistantChannels = 'equidistant_channels',
  StandardDeviationChannels = 'standard_deviation_channels',
  Rectangles = 'rectangles',
  Ellipses = 'ellipses',
  Triangles = 'triangles',
  Icons = 'icons'
}

export enum GraphicsPanel {
  Note = 'Note',
  Lines = 'Lines',
  Objects = 'Objects',
  ObjectsSelections = 'Objects Selections',
  SelectAllObjects = 'Select All Objects',
  DeselectAllObjects = 'Deselect All Objects',
  ListOfObjects = 'List of Objects',
  VerticalLine = 'Vertical Line',
  HorizontalLine = 'Horizontal Line',
  TrendLine = 'Trend Line',
  Polyline = 'Poly Line',
  Ray = 'Ray',
  Shapes = 'Shapes',
  Rectangle = 'Rectangle',
  Ellipse = 'Ellipse',
  Triangle = 'Triangle',
  Icons = 'Icons',
  ThumbUp = 'Thumb Up',
  ThumbDown = 'Thumb Down',
  ArrowUp = 'Arrow Up',
  ArrowDown = 'Arrow Down',
  StopIcon = 'Stop Icon',
  CheckIcon = 'Check Icon',
  RightPriceLabel = 'Right Price Label',
  LeftPriceLabel = 'Left Price Label',
  PriceLabel = 'Price Label',
  FibonacciTools = 'Fibonacci Tools',
  FibonacciRetracement = 'Fibonacci Retracement',
  FibonacciTimeZones = 'Fibonacci Time Zones',
  FibonacciFan = 'Fibonacci Fan',
  FibonacciArc = 'Fibonacci Arc',
  FibonacciExtension = 'Fibonacci Extension',
  FibonacciChannel = 'Fibonacci Channel',
  Channels = 'Channels',
  LinearRegressionChannel = 'Linear Regression Channel',
  StandardDeviationChannel = 'Standard Deviation Channel',
  EquidistantChannel = 'Equidistant Channel',
  GannFan = 'Gann Fan',
  AndrewsPitchfork = 'Andrews Pitchfork',
  OtherTools = 'Other Tools',
  TextLabel = 'Text Label',
  WaveSymbols = 'Wave Symbols',
  DeleteMostRecentObject = 'Delete Most Recent Object',
  DeleteSelectedObjects = 'Delete Selected Objects',
  DeleteAllObjects = 'Delete All Objects',
  DeleteAllIndicators = 'Delete All Indicators on all charts',
  CloseButton = 'Graphics Panel Close Button',
  RewardToRisk = 'Reward-to-Risk Tool',
  RewardToRiskBuy = 'Reward-to-Risk Buy',
  RewardToRiskSell = 'Reward-to-Risk Sell'
}

export enum GraphicPanelID {
  NoteID = 'GraphicPanelNoteID',
  VerticalLineID = 'GraphicPanelVerticalLineID',
  HorizontalLineID = 'GraphicPanelHorizontalLineID',
  TrendLineID = 'GraphicPanelTrendLineID',
  PolylineID = 'GraphicPanelPolylineID',
  RayID = 'GraphicPanelRayID',
  RectangleID = 'GraphicPanelRectangleID',
  EllipseID = 'GraphicPanelEllipseID',
  TriangleID = 'GraphicPanelTriangleID',
  IconsID = 'GraphicPanelIconsID',
  ThumbUpID = 'GraphicPanelThumbUpID',
  ThumbDownID = 'GraphicPanelThumbDownID',
  ArrowUpID = 'GraphicPanelArrowUpID',
  ArrowDownID = 'GraphicPanelArrowDownID',
  StopIconID = 'GraphicPanelStopIconID',
  CheckIconID = 'GraphicPanelCheckIconID',
  RightPriceLabelID = 'GraphicPanelRightPriceLabelID',
  LeftPriceLabelID = 'GraphicPanelLeftPriceLabelID',
  PriceLabelID = 'GraphicPanelPriceLabelID',
  FibonacciRetracementID = 'GraphicPanelFibonacciRetracementID',
  FibonacciTimeZonesID = 'GraphicPanelFibonacciTimeZonesID',
  FibonacciFanID = 'GraphicPanelFibonacciFanID',
  FibonacciArcID = 'GraphicPanelFibonacciArcID',
  FibonacciExtensionID = 'GraphicPanelFibonacciExtensionID',
  FibonacciChannelID = 'GraphicPanelFibonacciChannelID',
  LinearRegressionChannelID = 'GraphicPanelLinearRegressionChannelID',
  StandardDeviationChannelID = 'GraphicPanelStandardDeviationChannelID',
  EquidistantChannelID = 'GraphicPanelEquidistantChannelID',
  GannFanID = 'GraphicPanelGannFanID',
  AndrewsPitchforkID = 'GraphicPanelAndrewsPitchforkID',
  TextLabelID = 'GraphicPanelTextLabelID',
  WaveSymbolsID = 'GraphicPanelWaveSymbolsID',
  ListOfObjectsID = 'GraphicPanelListOfObjectsID',
  SelectAllObjectsID = 'SelectAllObjectsID',
  DeleteMostRecentObjectID = 'GraphicPanelDeleteMostRecentObjectID',
  DeleteSelectedObjectID = 'GraphicPanelDeleteSelectedObjectID',
  DeleteAllObjectsID = 'GraphicPanelDeleteAllObjectsID',
  DeselectAllObjectsID = 'DeselectAllObjectsID',
  DeleteAllIndicatorsID = 'DeleteAllIndicatorsID',
  CloseButtonID = 'GraphicPanelCloseButtonID',
  RewardToRiskBuyID = 'RewardToRiskBuyID',
  RewardToRiskSellID = 'RewardToRiskSellID'
}

export enum ChartsTabElementsID {
  NewChartID = 'NewChartID',
  RangeBarID = 'RangeBarID',
  RenkoBarID = 'RenkoBarID',
  ProfitChartID = 'ProfitChartID',
  LinesModeID = 'ChartsTabLinesModeID',
  BarsModeID = 'ChartsTabBarsModeID',
  CandlesticksModeID = 'ChartsTabCandlesticksModeID',
  AreaModeID = 'ChartsTabAreaModeID',
  KagiModeID = 'ChartsTabKagiModeID',
  PointsAndFigureModeID = 'ChartsTabPointsAndFigureModeID',
  AutoScrollID = 'ChartsTabAutoScrollID',
  ChartOffsetID = 'ChartsTabChartOffsetID',
  MagnetModeID = 'ChartsTabMagnetModeID',
  SyncChartsID = 'ChartsTabSyncChartsID',
  ShowVolumesID = 'ChartsTabShowVolumesID',
  ShowGridID = 'ChartsTabShowGridID',
  ShowPeriodSeparatorsID = 'ChartsTabShowPeriodSeparatorsID',
  ShowAccountHistoryID = 'ChartsTabShowAccountHistoryID',
  ShowNotesID = 'ChartsTabShowNotesID',
  ShowNewsID = 'ChartsTabShowNewsID',
  ChartTemplatesID = 'ChartTemplatesID',
  JumpToID = 'JumpToID',
  ScreenShotID = 'ScreenShotID',
  GraphicElementsID = 'GraphicElementsID',
  ListOfIndicatorsID = 'ListOfIndicatorsID',
  // graphics
  // indicators
  MaximizeActiveChartID = 'MaximizeActiveChartID',
  CascadeID = 'ChartsTabCascadeID',
  TileHorizontallyID = 'ChartsTabTileHorizontallyID',
  TileVerticallyID = 'ChartsTabTileVerticallyID',
  MinimizeAllID = 'ChartsTabMinimizeAllID',
  ChartSettingsID = 'ChartSettingsID',
  restoreAllChartsID = 'restoreAllChartsIconID'
}

export enum Chart {
  ChartContainer = 'Chart Container'
}

export enum Top {
  HomeTab = 'HomeTab',
  OrdersTab = 'OrdersTab',
  ChartsTab = 'ChartsTab',
  StrategiesTab = 'StrategiesTab',
  WindowsTab = 'WindowsTab',
  EducationTab = 'EducationTab',
  SettingsTab = 'SettingsTab',
  StoreTab = 'StoreTab',
  HelpTab = 'HelpTab'
}

export enum TopMenuId {
  Home = '3050',
  Orders = '3051',
  Charts = '3052',
  Strategies = '3054',
  Windows = '3055',
  Education = '3057',
  Settings = '3056',
  Store = '3058',
  Help = '3059'
}

export enum HomeID {
  TestingID = 'TestingID',
  StartTestID = 'StartTestID',
  PauseTestID = 'PauseTestID',
  ProjectID = 'ProjectID',
  RestartTestID = 'RestartTestID',
  ResetProjectID = 'ResetProjectID',
  SaveProjectID = 'SaveProjectID',
  OpenProjectID = 'OpenProjectID',
  TestingSpeedID = 'TestingSpeedID',
  BarBackID = 'BarBackID',
  BarForwardID = 'BarForwardID',
  JumpToID = 'JumpToID',
  StepByOneTickID = 'StepByOneTickID',
  SpeedOfTestingSettingsID = 'SpeedOfTestingSettingsID',
  TimeFrameID = 'TimeFrameID',
  MarketOrderID = 'MarketOrderID',
  PendingOrderID = 'PendingOrderID',
  GraphicElementsID = 'GraphicElementsID',
  ListOfIndicatorsID = 'ListOfIndicatorsID',
  CloseAllPositionsID = 'CloseAllPositionsID',
  TestingSpeedSliderID = 'TestingSpeedSliderID',
  NewsID = 'NewsID',
  MobileBurgerID = 'MobileBurgerID',
  TimeFrameGroupID = 'TimeFrameGroupID',
  GraphicElementsGroupID = 'GraphicElementsGroupID',
  ListOfIndicatorsGroupID = 'ListOfIndicatorsGroupID',
  CrosshairPointerID = 'CrosshairPointerID',
  CrosshairSyncID = 'CrosshairSyncID'
}

export enum HomeId {
  Testing = '1003',
  StartTest = '6052',
  ResumeTest = '100036',
  PauseTest = '6103',
  RestartTest = '3151',
  ProjectText = '1001',
  ResetProject = '3152',
  SaveProject = '504',
  OpenProject = '502',
  TestingSpeed = '1027',
  BarBack = '3155',
  BarForward = '3156',
  JumpTo = '3157',
  StepByOneTick = '3158',
  TimeFrame = '1011',
  ActionsWithOrders = '3103',
  MarketOrder = '5125',
  PendingOrder = '5130',
  Graphics = '3104',
  Indicators = '3105',
  ListOfIndicators = '3172',
  News = '3108',
  SpeedOfTestingSettingsID = '-1',
  CrosshairPointerID = '3367',
  CrosshairSyncID = '3368',
  Crosshair = '3106'
}

export enum Home {
  StartTest = 'Start',
  StartTestIcon = 'StartTestIcon',
  StartTestLabel = 'StartTestLabel',
  PauseTest = 'Pause',
  PauseTestIcon = 'PauseTestIcon',
  PauseTestLabel = 'PauseTestLabel',
  RestartTest = 'Restart',
  Testing = 'Testing',
  MarketOrder = 'Market\nOrder',
  PendingOrder = 'Pending\nOrder',
  ActionsWithOrders = 'Actions / Orders',
  TimeFrame = 'Time Frame',
  M1 = 'M1TimeFrame',
  M5 = 'M5TimeFrame',
  M15 = 'M15TimeFrame',
  M30 = 'M30TimeFrame',
  H1 = 'H1TimeFrame',
  H4 = 'H4TimeFrame',
  D1 = 'D1TimeFrame',
  W1 = 'W1TimeFrame',
  MN = 'MNTimeFrame',
  TestingSpeedSlider = 'TestingSpeedSlider',
  BarBack = 'Bar Back',
  BarForward = 'Bar Forward',
  JumpTo = 'Jump To',
  ProjectOptions = 'Project Options Dropdown Menu',
  NewProject = 'New Project',
  OpenProject = 'Open Project',
  SaveProject = 'Save Project',
  DataProjectSettings = 'Data Project Settings',
  CloseProject = 'Close Project',
  SpeedOptions = 'Speed Options Dropdown Menu',
  TimeFrameOptions = 'Time Frame Options Dropdown Menu',
  ActionsOrders = 'Actions / Orders Dropdown Menu',
  ProjectText = 'Project',
  ResetProjectText = 'Reset\nProject',
  SpeedOfTesting = 'Speed of testing',
  StepByOneTick = 'Tick',
  SpeedOfTestingSettings = 'SpeedOfTestingSettings',
  Graphics = 'Graphics',
  Indicators = 'Indicators',
  News = 'News',
  ListOfIndicators = 'List of\nindicators',
  CrosshairPointer = 'Crosshair Pointer',
  CrosshairSync = 'Crosshair Sync',
  Crosshair = 'Crosshair'
}

export enum ProjectId {
  NewProject = '500',
  SaveProject = '504',
  OpenProject = '502',
  CloseProject = '684',
  Project = '1001',
  RestartProject = '661'
}

export enum MyOrderMobileId {
  openPositions = '620',
  pendingOrders = '621',
  accountHistory = '622',
  statistics = '617',
  notes = '695'
}

export enum MarketOrderModalId {
  MarketOrder = '5125',
  Common = '5071',
  TrailingStop = '2300',
  SymbolName = '1905',
  Lot = '5072',
  ImSelling = '-1',
  ImBuying = '-1',
  CalculateWithRiskPercentage = '5221',
  CalculateWithRiskPercentageRequired = '5222',
  StopLoss = '5073',
  TakeProfit = '5074',
  Comment = '5075',
  SellAt = '-1',
  BuyAt = '-1',
  Cancel = '2'
}

export enum InteractiveTextModalTextId {
  Cancel = '2'
}

export enum MarketOrderModalTexts {
  Common = 'Common',
  TrailingStop = 'Trailing Stop',
  SymbolName = 'Symbol name:',
  Lot = 'Lot:',
  ImSelling = "I'm selling",
  ImBuying = "I'm buying",
  CalculateWithRiskPercentage = 'Calculate with risk %:',
  StopLoss = 'Stop Loss:',
  TakeProfit = 'Take Profit',
  SellAt = 'Sell at',
  BuyAt = 'Buy at',
  Cancel = 'Cancel'
}

export enum MarketOrderModalLocators {
  Section1 = 'MarketOrderModelSection1',
  CommonTab = 'commonTab',
  TrailingStopTab = 'trailingStopTab',
  SymbolSelect = 'marketOrderSymbolSelect',
  LotSelect = 'marketOrderLotSelect',
  StopLossField = 'marketOrderStopLossField',
  TakeProfitField = 'marketOrderTakeProfitField',
  CommentField = 'marketOrderCommentField',
  ButtonPrice = 'marketOrderButtonPrice',
  BuyButton = 'marketOrderBuyButton',
  SellButton = 'marketOrderSellButton',
  CancelOrderButton = 'marketOrderCancelButton',
  EditOrderButton = 'marketOrderEditButton',
  CloseOrderButton = 'marketOrderCloseButton',
  RiskCalculationCheckbox = 'marketOrderRiskCalculationCheckbox',
  RiskCalculationLabel = 'marketOrderRiskCalculationLabel',
  RiskCalculationField = 'marketOrderRiskCalculationField',
  RiskCalculationConfirmationButton = 'marketOrderRiskCalculationConfirmationButton',
  RiskIncrementButton = 'marketOrderRiskIncrementButton',
  RiskDecrementButton = 'marketOrderRiskDecrementButton',
  BuyRadio = 'marketOrderBuyRadio',
  SellRadio = 'marketOrderSellRadio',
  BuySellRadios = 'marketOrderBuySellRadios',
  StopLossDropdown = 'marketOrderStopLossDropdown',
  StopLossButton = 'marketOrderStopLossButton',
  TakeProfitButton = 'marketOrderTakeProfitButton',
  InstantStopLossSetupButton = 'marketOrderInstantStopLossSetupButton',
  StopLossRemovalButton = 'marketOrderStopLossRemovalButton',
  StopLossTooltip = 'marketOrderStopLossTooltip',
  StopLossIncrementButton = 'marketOrderStopLossIncrementButton',
  StopLossDecrementButton = 'marketOrderStopLossDecrementButton',
  StopLossPipette = 'marketOrderStopLossPipette',
  TakeProfitRemovalButton = 'marketOrderTakeProfitRemovalButton',
  TakeProfitDropdown = 'marketOrderTakeProfitDropdown',
  InstantTakeProfitSetupButton = 'marketOrderInstantTakeProfitSetupButton',
  TakeProfitTooltip = 'marketOrderTakeProfitTooltip',
  TakeProfitIncrementButton = 'marketOrderTakeProfitIncrementButton',
  TakeProfitDecrementButton = 'marketOrderTakeProfitDecrementButton',
  TakeProfitPipette = 'marketOrderTakeProfitPipette'
}

export enum PendingOrderModalLocators {
  PlaceAtBtn = 'pendingOrderModalPlaceAtBtn',
  OrderType = 'pendingOrderModalOrderType',
  AtPricePipette = 'pendingOrderModalAtPricePipette',
  AtPriceButton = 'pendingOrderModalAtPriceButton',
  CommonTab = 'pendingOrderModalCommonTab',
  TrailingStopTab = 'pendingOrderModalTrailingStopTab',
  SymbolSelect = 'pendingOrderSymbolSelect',
  LotSelect = 'pendingOrderLotSelect',
  StopLossField = 'pendingOrderStopLossField',
  TakeProfitField = 'pendingOrderTakeProfitField',
  CommentField = 'pendingOrderCommentField',
  ButtonPrice = 'pendingOrderButtonPrice',
  BuyButton = 'pendingOrderBuyButton',
  SellButton = 'pendingOrderSellButton',
  CancelOrderButton = 'pendingOrderCancelButton',
  EditOrderButton = 'pendingOrderEditButton',
  CloseOrderButton = 'pendingOrderCloseButton',
  RiskCalculationCheckbox = 'pendingOrderRiskCalculationCheckbox',
  RiskCalculationLabel = 'pendingOrderRiskCalculationLabel',
  RiskCalculationField = 'pendingOrderRiskCalculationField',
  RiskCalculationConfirmationButton = 'pendingOrderRiskCalculationConfirmationButton',
  RiskIncrementButton = 'pendingOrderRiskIncrementButton',
  RiskDecrementButton = 'pendingOrderRskDecrementButton',
  BuyRadio = 'pendingOrderBuyRadio',
  SellRadio = 'pendingOrderSellRadio',
  StopLossDropdown = 'pendingOrderStopLossDropdown',
  InstantStopLossSetupButton = 'pendingOrderInstantStopLossSetupButton',
  StopLossRemovalButton = 'pendingOrderStopLossRemovalButton',
  StopLossTooltip = 'pendingOrderStopLossTooltip',
  StopLossIncrementButton = 'pendingOrderStopLossIncrementButton',
  StopLossDecrementButton = 'pendingOrderStopLossDecrementButton',
  StopLossPipette = 'pendingOrderStopLossPipette',
  TakeProfitRemovalButton = 'pendingOrderTakeProfitRemovalButton',
  TakeProfitDropdown = 'pendingOrderTakeProfitDropdown',
  InstantTakeProfitSetupButton = 'pendingOrderInstantTakeProfitSetupButton',
  TakeProfitTooltip = 'pendingOrderTakeProfitTooltip',
  TakeProfitIncrementButton = 'pendingOrderTakeProfitIncrementButton',
  TakeProfitDecrementButton = 'pendingOrderTakeProfitDecrementButton',
  TakeProfitPipette = 'pendingOrderTakeProfitPipette'
}

export enum PendingOrderModalId {
  PendingOrder = '4151',
  StopLoss = '5073',
  TakeProfit = '5074',
  AtPrice = '5131',
  Comment = '5075',
  CalculateWithRiskPercentage = '5221',
  CalculateWithRiskPercentageRequired = '5222',
  Lot = '5072',
  SymbolName = '1905',
  Type = '5133',
  Common = '5071',
  TrailingStop = '2300',
  Cancel = '2'
}

export enum OrdersId {
  Trading = '2215',
  Orders = '15',
  MarketOrder = '5125',
  PendingOrder = '5130',
  ModifyOrder = '5134',
  CloseOrder = '3253',
  CloseAllPositions = '3254',
  OpenGroupOfOrders = '3255', // no such thing
  DuplicatePosition = '3256',
  MoveStopLossToBreakeven = '3257',
  ChangeBalance = '3258',
  WithdrawMoney = '5144',
  ForexSpeedUp = '3259',
  OneClickTrading = '3203',
  Balance = '3202',
  OrderActions = '3201'
}

export enum RewardToRiskModalID {
  CalculateLotFrom = 'rewardToRiskCalculateLotFrom',
  CustomLot = 'rewardToRiskCustomLot',
  Risk = 'rewardToRiskRisk'
}

export enum RewardToRiskModalId {
  Apply = '6',
  Close = '4'
}

export enum AccountHistoryAdditionalInfo {
  balance = '1539',
  profitLoss = '',
  deposit = '1159',
  withdraw = '5146'
}

export enum OrdersID {
  MarketOrderID = 'MarketOrderID',
  PendingOrderID = 'PendingOrderID',
  ModifyOrderID = 'ModifyOrderID',
  CloseOrderID = 'CloseOrderID',
  CloseAllPositionsID = 'CloseAllPositionsID',
  OpenGroupOfOrdersID = 'OpenGroupOfOrdersID',
  DuplicatePositionID = 'DuplicatePositionID',
  MoveStopLossToBreakevenID = 'MoveStopLossToBreakevenID',
  ChangeBalanceID = 'ChangeBalanceID',
  WithdrawMoneyID = 'WithdrawMoneyID',
  ForexSpeedUpID = 'ForexSpeedUpID'
}

export enum Orders {
  Orders = 'Orders',
  MarketOrder = 'Market Order',
  PendingOrder = 'Pending Order',
  OrderActions = 'Order Actions',
  ModifyOrder = 'Modify Order',
  CloseOrder = 'Close Order',
  CloseAllPositions = 'Close All Positions and Orders',
  OpenGroupOfOrders = 'Open Group of Orders',
  MoveStopLossToBreakeven = 'Move Stop Loss to Breakeven',
  Balance = 'Balance',
  OneClickTrading = 'One-click Trading',
  DuplicatePosition = 'Duplicate Position',
  ChangeBalance = 'Deposit Money to the Account',
  WithdrawMoney = 'Withdraw Money from the Account',
  ForexSpeedUp = 'Forex Speed Up'
}

export enum ChartsId {
  AddNewChart = '1006',
  ChartModes = '3354',
  LinesMode = '538',
  BarsMode = '540',
  CandlesMode = '542',
  AreaMode = '-1',
  KagiMode = '-1',
  PointsAndFigureMode = '-1',
  ChartView = '1002',
  AutoScroll = '534',
  ChartOffset = '537',
  MagnetMode = '598',
  SyncCharts = '700',
  ShowVolumes = '625',
  ShowGrid = '628',
  ShowPeriodSeparators = '627',
  ShowAccountHistory = '626',
  ShowNotes = '696',
  ShowNews = '697',
  Templates = '1013',
  Other = '2229',
  JumpToCharts = '5501',
  Screenshot = '64',
  Graphics = '3104',
  Indicators = '3105',
  Windows = '3310',
  MaximizeActiveChart = '11500',
  restoreAllCharts = '3654',
  Cascade = '635',
  MinimizeAll = '638',
  TileHorizontally = '636',
  TileVertically = '637',
  Settings = '63',
  ChartSettings = '1500',
  NewChart = '3350',
  RangeBar = '3351',
  RenkoBar = '3352',
  ProfitChart = '3353',
  ChartTemplates = '3365'
}

export enum ChartsID {
  OpenProjectID = 'OpenProjectID',
  ScreenshotID = 'ScreenshotID',
  ChartSettingsID = 'ChartSettingsID',
  JumpToChartsID = 'JumpToChartsID'
}

export enum ChartModes {
  LinesMode = 'Lines Mode',
  BarsMode = 'Bars Mode',
  CandlesticksMode = 'Candlesticks Mode',
  AreaMode = 'Area Mode',
  KagiMode = 'Kagi Mode'
}

export enum ChartView {
  AutoScroll = 'Auto Scroll',
  ChartOffset = 'Chart Offset',
  MagnetMode = 'Magnet Mode',
  SyncCharts = 'Synchronize Charts',
  ShowVolumes = 'Show Volumes',
  ShowGrid = 'Show Grid',
  ShowPeriodSeparators = 'Show Period Separators',
  ShowAccountHistory = 'Show Account History',
  ShowNotes = 'Show Notes',
  ShowNews = 'Show News'
}

export enum ChartWindows {
  MaximizeActiveChart = 'Maximize Active Chart',
  Cascade = 'Cascade',
  TileHorizontally = 'Tile Horizontally',
  TileVertically = 'Tile Vertically',
  MinimizeAll = 'Minimize All',
  restoreAllCharts = 'Restore All'
}

export enum DataID {
  DataCenterID = 'DataCenterID',
  ProjectDataSettingsID = 'ProjectDataSettingsID',
  UpdateNewsID = 'UpdateNewsID'
}

export enum StrategiesID {
  ListOfStrategiesID = 'ListOfStrategiesID',
  AddStrategyID = 'AddStrategyID',
  StrategyOptimizerID = 'StrategyOptimizerID',
  QuickTestID = 'QuickTestID',
  EasyForexBuilderID = 'EasyForexBuilderID',
  StrategyExecutionID = 'StrategyExecutionID'
}

export enum StrategiesId {
  ListOfStrategies = '3550',
  StrategiesTools = '3500',
  AddStrategy = '3553',
  StrategyOptimizer = '3554',
  QuickTest = '3555',
  EasyForexBuilder = '3556',
  EnableStrategiesExecution = '5213'
}

export enum WindowsID {
  CascadeID = 'CascadeID',
  TileHorizontallyID = 'TileHorizontallyID',
  TileVerticallyID = 'TileVerticallyID',
  MinimizeAllID = 'MinimizeAllID',
  RestoreAllID = 'RestoreAllID',
  SymbolsID = 'SymbolsID',
  TickChartID = 'TickChartID',
  DataWindowID = 'DataWindowID',
  StatisticsID = 'StatisticsID',
  OpenPositionsID = 'OpenPositionsID',
  PendingOrdersID = 'PendingOrdersID',
  AccountHistoryID = 'AccountHistoryID',
  JournalID = 'JournalID',
  NewsID = 'NewsID',
  NotesID = 'NotesID',
  GraphicPanelID = 'GraphicPanelID',
  ScriptsID = 'ScriptsID',
  maximizeActiveChartMain = 'maximizeActiveChartMain'
}

export enum WindowsId {
  Windows = '3600',
  Cascade = '3650',
  MinimizeAll = '3653',
  TileHorizontally = '3651',
  TileVertically = '3652',
  RestoreAll = '3654',
  MarketWatchPanels = '3601',
  Symbols = '3655',
  TickChart = '3656',
  DataWindow = '3657',
  TradingPanels = '3602',
  Statistics = '3658',
  OpenPositions = '3659',
  PendingOrders = '3660',
  AccountHistory = '3661',
  OtherPanels = '3603',
  Journal = '3662',
  News = '3663',
  Notes = '3664',
  GraphicPanel = '3665',
  Scripts = '3666',
  maximizeActiveChartMain = '3667'
}

export enum GraphicsID {
  NoteID = 'NoteID',
  VerticalLineID = 'VerticalLineID',
  HorizontalLineID = 'HorizontalLineID',
  TrendLineID = 'TrendLineID'
}

export enum GraphicsId {
  AddNote = '1185',
  AddLine = '1015'
}

export enum Graphics {
  AddNote = 'Add Note',
  AddLine = 'Add Line'
}

export enum EducationID {
  QuickStartGuideID = 'QuickStartGuideID',
  IntroID = 'IntroID',
  BasicCoursesID = 'BasicCoursesID',
  IntermediateCoursesID = 'IntermediateCoursesID',
  AdvancedCoursesID = 'AdvancedCoursesID',
  CoursesGroupID = 'CoursesGroupID'
}

export enum EducationId {
  Intro = '3855',
  HowTo = '3850',
  BasicCourses = '3851',
  IntermediateCourses = '3852',
  AdvancedCourses = '3853',
  Courses = '3800'
}

export enum SettingsID {
  DefaultOrdersID = 'DefaultOrdersID',
  HotKeysID = 'HotKeysID',
  SoundsID = 'SoundsID',
  LanguageID = 'LanguageID'
}

export enum SettingsId {
  Options = '2200',
  DefaultOrders = '3751',
  HotKeys = '3752',
  Sounds = '3753',
  ChangeLanguage = '3754',
  Language = '3754'
}

export enum Settings {
  Options = 'Options',
  DefaultOrders = 'Default Orders',
  HotKeys = 'Hot Keys',
  Sounds = 'Sounds',
  ChangeLanguage = 'Change Language',
  Language = 'Language'
}

export enum StoreID {
  BuyLicenseID = 'BuyLicenseID',
  RegisterProgramID = 'RegisterProgramID',
  StandardSubscriptionID = 'StandardSubscriptionID',
  VipSubscriptionID = 'VipSubscriptionID',
  ActivateDataSubscriptionID = 'ActivateDataSubscriptionID',
  ForexTradeCopierID = 'ForexTradeCopierID',
  ForexRemoteCopierID = 'ForexRemoteCopierID',
  EasyForexBuilderID = 'EasyForexBuilderID',
  VisualIndicatorBuilderID = 'VisualIndicatorBuilderID',
  IndicatorsListID = 'IndicatorsListID'
}

export enum StoreId {
  BuyLicense = '3950',
  RegisterProgram = '3951',
  HistoricalData = '1133',
  StandardSubscription = '3952',
  VipSubscription = '3953',
  ActivateDataSubscription = '3954',
  TradeCopyingSoftware = '3902',
  ForexTradeCopier = '3955',
  ForexRemoteCopier = '3956',
  VisualBuilders = '3957',
  EasyForexBuilder = '3957',
  VisualIndicatorBuilder = '3958',
  FreeSoftware = '-1',
  HireProgrammer = '-1', // ! No such button
  Indicators = '-1',
  Strategies = '-1',
  Utilities = '-1',
  GetMyLicence = '5187'
}

export enum HelpID {
  VideoTutorialsID = 'VideoTutorialsID',
  FaqID = 'FaqID',
  UserGuidePdfID = 'UserGuidePdfID',
  PartnershipProgramID = 'PartnershipProgramID',
  ForexTesterHelpID = 'ForexTesterHelpID',
  ApiHelpID = 'ApiHelpID',
  ContactUsID = 'ContactUsID',
  ForumID = 'ForumID',
  AboutProgramID = 'AboutProgramID'
}

export enum Help {
  HowToUseMaterials = '-1',
  Partners = '4002',
  Help = '3',
  Contacts = '4004',
  Info = '4005',

  VideoTutorials = '4050',
  Faq = '4051',
  UserGuidePdf = '4052',
  PartnershipProgram = '4053',
  ForexTesterHelp = '4054',
  ApiHelp = '4055', // ! No such id in .lng-file (not translated)
  IndicatorsApi = '645',
  StrategiesApi = '646',
  ScriptsApi = '647',
  ContactUs = '4056',
  Forum = '4057',
  AboutProgram = '4058'
}

export enum NonExisted {
  NoId = '-1'
}

export enum LeftMenuId {
  Symbols = '12',
  TickChart = '616',
  Statistics = '617',
  Scripts = '618',
  DataWindow = '619',
  MaximizeButton = '-1',
  CloseButton = '-1'
}

export enum LeftID {
  MaximizeButtonID = 'LeftMenuMaximizeButtonID',
  CloseButtonID = 'LeftMenuCloseButtonID'
}

export enum Left {
  SymbolsTab = 'Symbols Tab',
  StatisticsTab = 'Statistics Tab',
  MaximizeButton = 'Left Menu Maximize Button',
  CloseButton = 'Left Menu Close Button'
}

export enum StatisticsId {
  Parameter = '33',
  Value = '34',
  Time = '1102',
  DaysProcessed = '1103',
  MonthsProcessed = '1104',
  Trades = '1105',
  TotalTrades = '1106',
  ProfitableTrades = '1107',
  LosingTrades = '1108',
  ConsecutiveProfitableTrades = '1109',
  ConsecutiveLosingTrades = '1110',
  TradesPerDay = '1111',
  TradesPerMonth = '1112',
  ProfitableTradesPerMonth = '1113',
  LosingTradesPerMonth = '1114',
  MaximumProfitableTrade = '1115',
  MaximumLosingTrade = '1116',
  Income = '1117',
  NetProfit = '1118',
  GrossProfit = '1119',
  GrossLoss = '1120',
  ProfitPerMonth = '1121',
  AverageProfit = '1122',
  AverageLoss = '1123',
  MaximumDrawdown = '1124',
  ProfitFactor = '1125',
  Return = '1126',
  OtherStatistics = '1127',
  MaximumLotUsed = '1128',
  RestorationFactor = '1129',
  ReliabilityFactor = '1130',
  ProfitProbabilityPercentage = '1131',
  LossProbabilityPercentage = '1132'
}

export enum StatisticsID {}
// MaximizeButtonID = 'LeftMenuMaximizeButtonID',

export enum StatisticsTexts {
  Parameter = 'Parameter',
  Value = 'Value',
  Time = 'Time',
  DaysProcessed = 'Days Processed',
  MonthsProcessed = 'Months Processed',
  Trades = 'Trades',
  TotalTrades = 'Total Trades',
  ProfitableTrades = 'Profit Trades',
  LosingTrades = 'Loss Trades',
  ConsecutiveProfitableTrades = 'Profit trades cons.',
  ConsecutiveLosingTrades = 'Loss trades cons.',
  TradesPerDay = 'Trades / day',
  TradesPerMonth = 'Trades / month',
  ProfitableTradesPerMonth = 'Profit trades / month',
  LosingTradesPerMonth = 'Loss trades / month',
  MaximumProfitableTrade = 'Max profit trade',
  MaximumLosingTrade = 'Max loss trade',
  Income = 'Income:',
  NetProfit = 'Net profit',
  GrossProfit = 'Gross profit',
  GrossLoss = 'Gross loss',
  ProfitPerMonth = 'Profit / month',
  AverageProfit = 'Average profit',
  AverageLoss = 'Average loss',
  MaximumDrawdown = 'Max drawdown',
  ProfitFactor = 'Profit factor',
  Return = 'Return, %',
  OtherStatistics = 'Other statistics:',
  MaximumLotUsed = 'Max lot used',
  RestorationFactor = 'Restoration factor',
  ReliabilityFactor = 'Reliability factor',
  ProfitProbabilityPercentage = 'Profit probability, %',
  LossProbabilityPercentage = 'Loss probability, %'
}

export enum BottomMenuId {
  'Open Positions' = '100025',
  'Pending Orders' = '621',
  'Account History' = '622',
  Journal = '623',
  News = '694',
  Notes = '695',
  MaximizeButton = '-1',
  CloseButton = '-1'
}

export enum AccountHistoryID {
  Profit = '32',
  Loss = '80',
  Points = '5079',
  Deposit = '100024',
  Withdraw = '5146'
}

export enum BottomID {
  MaximizeButtonID = 'BottomMenuMaximizeButtonID',
  CloseButtonID = 'BottomMenuCloseButtonID'
}

export enum Bottom {
  MaximizeButton = 'Bottom Menu Maximize Button',
  CloseButton = 'Bottom Menu Close Button'
}

export enum OpenPositions {
  Ticket = 'Ticket',
  Symbol = 'Symbol',
  Type = 'Type',
  Lots = 'Lots',
  OpenTime = 'Open Time',
  OpenPrice = 'Open Price',
  StopLoss = 'S/L',
  TakeProfit = 'T/P',
  MarketPrice = 'Market Price',
  Comment = 'Comment',
  Swap = 'Swap',
  Commission = 'Commission',
  Points = 'Points',
  Profit = 'Profit'
}

export enum OpenPositionsTextId {
  Ticket = '45',
  Symbol = '11',
  Type = '20',
  Lots = '19',
  OpenTime = '21',
  OpenPrice = '23',
  StopLoss = '26',
  TakeProfit = '27',
  MarketPrice = '25',
  Comment = '28',
  Swap = '29',
  Commission = '82',
  Points = '31',
  Profit = '32'
}

export enum AccountHistory {
  Ticket = 'Ticket',
  Symbol = 'Symbol',
  Type = 'Type',
  Lots = 'Lots',
  OpenTime = 'Open Time',
  OpenPrice = 'Open Price',
  StopLoss = 'S/L',
  TakeProfit = 'T/P',
  CloseTime = 'Close Time',
  ClosePrice = 'Close Price',
  Comment = 'Comment',
  Swap = 'Swap',
  Commission = 'Commission',
  Points = 'Points',
  Profit = 'Profit'
}

export enum NewsTextId {
  DataTime = '1142',
  Symbol = '11',
  Event = '1176',
  Priority = '1177',
  Actual = '1178',
  Forecast = '1179',
  Previous = '1180'
}

export enum NewsTextDefault {
  DataTime = 'Date / Time',
  Symbol = 'Symbol',
  Event = 'Event',
  Priority = 'Priority',
  Actual = 'Actual',
  Forecast = 'Forecast',
  Previous = 'Previous'
}

export enum AccountHistoryTextId {
  Ticket = '45',
  Symbol = '11',
  Type = '20',
  Lots = '19',
  OpenTime = '21',
  OpenPrice = '23',
  StopLoss = '26',
  TakeProfit = '27',
  CloseTime = '22',
  ClosePrice = '24',
  Comment = '28',
  Swap = '29',
  Commission = '82',
  Points = '31',
  Profit = '32'
}

export enum Journal {
  DateTime = 'Date / Time',
  Message = 'Message'
}

export enum JournalTextId {
  DateTime = '50',
  Message = '1143'
}

export enum BalanceSummaryId {
  Balance = '1571',
  Equity = '1572',
  Margin = '1573',
  FreeMargin = '100022',
  MarginLevel = '100023'
}

export enum BalanceSummary {
  Balance = 'Balance',
  Equity = 'Equity',
  Margin = 'Margin',
  FreeMargin = 'Free margin',
  MarginLevel = 'Margin level'
}

export enum OpenPositionsId {
  Ticket = 'OpenPositionsTicket',
  Symbol = 'OpenPositionsSymbol',
  Type = 'OpenPositionsType',
  Lots = 'OpenPositionsLots',
  OpenTime = 'OpenPositionsOpenTime',
  OpenPrice = 'OpenPositionsOpenPrice',
  StopLoss = 'OpenPositionsSL',
  TakeProfit = 'OpenPositionsTP',
  MarketPrice = 'MarketPrice',
  Comment = 'OpenPositionsComment',
  Swap = 'OpenPositionsSwap',
  Commission = 'OpenPositionsCommission',
  Points = 'OpenPositionsPoints',
  Profit = 'OpenPositionsProfit',

  BalanceLabel = 'OpenPositionsBalanceLabel',
  EquityLabel = 'OpenPositionsEquityLabel',
  MarginLabel = 'OpenPositionsMarginLabel',
  FreeMarginLabel = 'OpenPositionsFreeMarginLabel',
  MarginLevelLabel = 'OpenPositionsMarginLevelLabel',

  BalanceValue = 'OpenPositionsBalanceValue',
  EquityValue = 'OpenPositionsEquityValue',
  MarginValue = 'OpenPositionsMarginValue',
  FreeMarginValue = 'OpenPositionsFreeMarginValue',
  MarginLevelValue = 'OpenPositionsMarginLevelValue'
}

export enum OpenPositionFooterIDs {
  BalanceLabelID = 'OpenPositionsBalanceLabelID',
  EquityLabelID = 'OpenPositionsEquityLabelID',
  MarginLabelID = 'OpenPositionsMarginLabelID',
  FreeMarginLabelID = 'OpenPositionsFreeMarginLabelID',
  MarginLevelLabelID = 'OpenPositionsMarginLevelLabelID',
  SummaryID = 'OpenPositionsSummaryID'
}

export enum Deposit {
  Amount = 'Amount',
  AmountIncrementButton = 'Amount Increment Button',
  AmountDecrementButton = 'Amount Decrement Button',
  Comment = 'Comment'
}

export enum ActionOrdersDropdownTextId {
  MarketOrder = '4150',
  PendingOrder = '4151',
  ModifyOrder = '4152',
  CloseOrder = '4153',
  CloseAllPositionsAndOrders = '4154',
  OpenGroupOfOrders = '4155',
  DuplicatePosition = '4156',
  // withdrawMoney
  DepositMoney = '4157',
  MoveStopLossToBreakeven = '4158',
  OpenForexSpeedUp = '4159'
}

export enum ActionOrdersDropdown {
  MarketOrder = 'Market Order',
  PendingOrder = 'Pending Order',
  ModifyOrder = 'Modify Order',
  CloseOrder = 'Close Order',
  CloseAllPositionsAndOrders = 'Close All Positions and Orders',
  OpenGroupOfOrders = 'Open Group Of Orders',
  DuplicatePosition = 'Duplicate Position',
  WithdrawMoney = 'Withdraw Money',
  DepositMoney = 'Withdraw Money',
  MoveStopLossToBreakeven = 'Move Stop Loss to Breakeven',
  OpenForexSpeedUp = 'Open Forex Speed Up'
}

export enum ProjectDropdown {
  NewProject = 'New Project',
  OpenProject = 'Open Project',
  SaveProject = 'Save Project',
  SaveProjectAs = 'Save Project As',
  DataProjectSettings = 'Data Project Settings',
  CloseProject = 'Close Project'
}

export enum ProjectDropdownTextId {
  NewProject = '4100',
  OpenProject = '4101',
  SaveProject = '4102',
  SaveProjectAs = '4103',
  DataProjectSettings = '-1', // not found
  CloseProject = '4104'
}

export enum TickSizeDropdownTextId {
  Auto = '6087',
  Minute = '30000',
  Minutes = '30001',
  Hour = '30002',
  Hours = '30003',
  Day = '30004',
  Week = '30005',
  Month = '30006'
}

export enum ListOfIndicatorsDropdown {
  Indicators = 'Indicators',
  Trend = 'Trend',
  Volume = 'Volume',
  Oscillators = 'Oscillators',
  Volatility = 'Volatility',
  Custom = 'Custom',
  InstallNewIndicator = 'installNewIndicator'
}

export enum ListOfIndicatorsDropdownId {
  Indicators = '3105',
  Trend = '4301',
  Volume = '4302',
  Oscillators = '4303',
  Volatility = '4305',
  Custom = '4306',
  InstallNewIndicator = '4307'
}

export const locators: { [key: string]: string } = {
  webVersionLimitations: 'Web Version Limitations',
  //#region 'Top Menu'

  //#region 'Market Order Window'
  marketOrderSymbolSelect: 'marketOrderSymbolSelect',
  marketOrderLotSelect: 'marketOrderLotSelect',
  marketOrderStopLossField: 'marketOrderStopLossField',
  marketOrderTakeProfitField: 'marketOrderTakeProfitField',
  marketOrderCommentField: 'marketOrderCommentField',
  marketOrderButtonPrice: 'marketOrderButtonPrice',
  marketOrderBuyButton: 'marketOrderBuyButton',
  marketOrderSellButton: 'marketOrderSellButton',
  marketOrderCancelButton: 'marketOrderCancelButton',

  marketOrderRiskCalculationCheckbox: 'marketOrderRiskCalculationCheckbox',
  marketOrderRiskCalculationLabel: 'marketOrderRiskCalculationLabel',
  marketOrderRiskCalculationField: 'marketOrderRiskCalculationField',
  marketOrderRiskCalculationConfirmationButton: 'marketOrderRiskCalculationConfirmationButton',
  marketOrderBuyRadio: 'marketOrderBuyRadio',
  marketOrderSellRadio: 'marketOrderSellRadio',

  marketOrderStopLossDropdown: 'marketOrderStopLossDropdown',
  marketOrderInstantStopLossSetupButton: 'marketOrderInstantStopLossSetupButton',
  marketOrderStopLossRemovalButton: 'marketOrderStopLossRemovalButton',
  marketOrderTakeProfitRemovalButton: 'marketOrderTakeProfitRemovalButton',
  marketOrderTakeProfitDropdown: 'marketOrderTakeProfitDropdown',
  marketOrderInstantTakeProfitSetupButton: 'marketOrderInstantTakeProfitSetupButton',
  //#endregion 'Market Order Window'
  //#region 'Pending Order Window'
  pendingOrderSymbolSelect: 'marketOrderSymbolSelect',
  pendingOrderTypeSelect: 'pendingOrderTypeSelect',
  pendingOrderLotSelect: 'marketOrderLotSelect',
  pendingOrderAtPriceField: 'pendingOrderAtPriceField',
  pendingOrderStopLossField: 'pendingOrderStopLossField',
  pendingOrderTakeProfitField: 'pendingOrderTakeProfitField',
  pendingOrderCommentField: 'pendingOrderCommentField',
  pendingOrderBidPrice: 'pendingOrderBidPrice',
  pendingOrderAskPrice: 'pendingOrderAskPrice',

  pendingOrderRiskCalculationCheckbox: 'pendingOrderRiskCalculationCheckbox',
  pendingOrderRiskCalculationLabel: 'pendingOrderRiskCalculationLabel',
  pendingOrderRiskCalculationField: 'pendingOrderRiskCalculationField',
  pendingOrderInfoButton: 'pendingOrderInfoButton',
  pendingOrderBuyRadio: 'pendingOrderBuyRadio',
  pendingOrderSellRadio: 'pendingOrderSellRadio',

  pendingOrderAtPriceDropdown: 'pendingOrderAtPriceDropdown',
  pendingOrderInstantAtPriceSetupButton: 'pendingOrderInstantAtPriceSetupButton',
  pendingOrderAtPriceRemovalButton: 'pendingOrderAtPriceRemovalButton',
  pendingOrderStopLossDropdown: 'pendingOrderStopLossDropdown',
  pendingOrderInstantStopLossSetupButton: 'pendingOrderInstantStopLossSetupButton',
  pendingOrderStopLossRemovalButton: 'pendingOrderStopLossRemovalButton',
  pendingOrderTakeProfitRemovalButton: 'pendingOrderTakeProfitRemovalButton',
  pendingOrderTakeProfitDropdown: 'pendingOrderTakeProfitDropdown',
  pendingOrderInstantTakeProfitSetupButton: 'pendingOrderInstantTakeProfitSetupButton',
  pendingOrderPlaceOrderButton: 'pendingOrderPlaceOrderButton',
  pendingOrderCancelOrderButton: 'pendingOrderCancelOrderButton',
  //#endregion 'Pending Order Window'
  //#endregion 'Home Tab'
  //#region 'Project Dropdown'
  newProject: 'New Project',
  openProject: 'Open Project',
  saveProject: 'Save Project',
  dataProjectSettings: 'Data Project Settings',
  closeProject: 'Close Project',
  // newProjectButton: 'New Project',
  projectNameInput: 'Project Name:',
  initialDeposit: 'Deposit (USD):',
  // duplicateProjectName: 'Project with this name already exists',
  // nextOne: 'Next >',
  // cancelOne: 'Cancel',
  // selectAllSymbols: 'Select All',
  // deselectAllSymbols: 'Deselect All',
  fromDateInput: 'From Date:',
  toDateInput: 'To Date:',
  useMinuteData: 'Use 1 minute data',
  useTickData: 'Use 1 minute and tick data',
  useFloatingSpread: 'Use floating spread',
  // backOne: '< Back',
  // nextTwo: 'Next >',
  // cancelTwo: 'Cancel',
  // timeZoneSlider: 'Time Zone',
  sessionCloseTimeInput: 'Session close time:',
  dstInput: 'Daylight Saving Time (DST):',
  // openProject: 'Open Project',
  // closeProject: 'Close Project',
  // saveProjectButton: 'Save Project',
  // saveProjectAs: 'Save Project as',
  // restartProject: 'Restart Project',
  // downloadAndUpdateNewHistory: 'Download and Update New History',
  // projectInformation: 'Project Information',
  // depositMoney: 'Deposit Money',
  // withdrawMoney: 'Withdraw Money',
  // setPauseWhenTestStarted: 'Set Pause when Test Started',
  //#endregion 'Projects Tab'
  //#region Graphic Elements Dropdown
  dropDownGraphicPanel: 'dropDownGraphicPanel',
  dropDownItemNote: 'dropDownItemNote',
  dropDownItemVerticalLine: 'dropDownItemVerticalLine',
  dropDownItemHorizontalLine: 'dropDownItemHorizontalLine',
  dropDownItemTrendLine: 'dropDownItemTrendLine',
  dropDownItemRay: 'dropDownItemRay',
  dropDownItemPolyLine: 'dropDownItemPolyLine',
  //#endregion
  //#region Actions / Orders Dropdown
  marketOrderDropdown: 'Market Order',
  pendingOrderDropdown: 'Pending Order',
  modifyOrder: 'Modify Order',
  closeOrder: 'Close Order',
  closeAllPositionsAndOrders: 'Close All Positions and Orders',
  openGroupOfOrders: 'Open Group of Orders',
  duplicatePosition: 'Duplicate Position',
  depositMoney: 'Deposit Money',
  withdrawMoney: 'Withdraw Money',
  moveStopLossToBreakeven: 'Move Stop Loss to Breakeven',
  openForexSpeedUp: 'Open Forex Speed Up',
  //#endregion
  //#region 'Charts Tab'
  newChart: 'New\nChart',
  rangeBar: 'Range\nBar',
  renkoBar: 'Renko\nBar',
  profitChart: 'Profit\nChart',
  linesMode: 'Lines Mode',
  barsMode: 'Bars Mode',
  candlesMode: 'Candles Mode',
  areaMode: 'Area Mode',
  kagiMode: 'Kagi Mode',
  chartModes: 'Chart Modes',
  autoScroll: 'Auto Scroll',
  showGrid: 'Show Grid',
  chartOffset: 'Chart Offset',
  showPeriodSeparators: 'Show Period Separators',
  magnetMode: 'Magnet Mode',
  showAccountHistory: 'Show Account History',
  syncCharts: 'Sync charts',
  showNotes: 'Show Notes',
  showVolumes: 'Show Volumes',
  showNews: 'Show News',
  chartTemplates: 'Chart\nTemplates',
  saveTemplate: 'Save Template',
  editTemplate: 'Edit Template',
  profiles: 'Profiles',
  loadProfile: 'Load Profile',
  saveProfile: 'Save Profile',
  restoreDefaultProfile: 'Restore Default Profile',
  jumpToChartsTab: 'Jump To',
  screenshot: 'Screenshot',
  cascadeWindowsAutoScroll: 'Cascade Windows Auto Scroll',
  tileWindowsHorizontally: 'Tile Windows Horizontally',
  tileWindowsVertically: 'Tile Windows Vertically',
  chartSettings: 'Chart\nSettings',
  settings: 'Settings',
  addChart: 'Add Charts',
  chartView: 'Chart view',
  templates: 'Templates',
  other: 'Other',
  windows: 'Windows',
  graphicElements: 'Graphic\nelements',
  listOfIndicators: 'List of\nindicators',
  //#endregion 'Charts Tab'
  //#region 'Graphics Elements'
  note: 'Note',
  lines: 'Lines',
  objects: 'Objects',
  objectsSelections: 'Objects Selections',
  selectAllObjects: 'Select all objects',
  deselectAllObjects: 'Deselect all objects',
  listOfObjects: 'List of Objects',
  verticalLine: 'Vertical Line',
  horizontalLine: 'Horizontal Line',
  trendLine: 'Trend Line',
  polyline: 'Poly Line',
  ray: 'Ray',
  rectangle: 'Rectangle',
  ellipse: 'Ellipse',
  triangle: 'Triangle',
  icons: 'Icons',
  thumbUp: 'ThumbUp',
  thumbDown: 'ThumbDown',
  arrowUp: 'Arrow Up',
  arrowDown: 'Arrow Down',
  stop: 'Stop',
  check: 'Check',
  rightPriceLabel: 'Right Price Label',
  leftPriceLabel: 'Left Price Label',
  priceLabel: 'Price Label',
  fibonacciRetracement: 'Fibonacci Retracement',
  fibonacciTimeZones: 'Fibonacci Time Zones',
  fibonacciFan: 'Fibonacci Fan',
  fibonacciArc: 'Fibonacci Arc',
  fibonacciExtension: 'Fibonacci Extension',
  fibonacciChannel: 'Fibonacci Channel',
  linearRegressionChannel: 'Linear Regression Channel',
  standardDeviationChannel: 'Standard Deviation Channel',
  equidistantChannel: 'Equidistant Channel',
  andrewsPitchfork: 'Andrews Pitchfork',
  textLabel: 'Text Label',
  waveSymbols: 'Wave Symbols',
  deleteMostRecentObject: 'Delete Most Recent Object',
  deleteSelectedObjects: 'Delete Selected Objects',
  deleteAllObjects: 'Delete All Objects',
  //#endregion 'Graphics Tab'
  //#region 'Indicators Tab'

  //#endregion 'Indicators Tab'
  //#region 'Strategies Tab'
  listOfStrategies: 'List of\nStrategies',
  strategiesExecution: 'Strategies Execution',
  actionsWithStrategies: 'Actions with Strategies',
  addStrategy: 'Add\nStrategy',
  strategyOptimizer: 'Strategy\nOptimizer',
  quickTest: 'Quick\nTest',
  visualStrategyBuilder: 'Easy Forex\nBuilder',
  strategiesTools: 'Strategies Tools',
  //#endregion 'Strategies Tab'
  //#region 'Settings Tab'
  showPanels: 'Show Panels',
  defaultOrders: 'Default\nOrders',
  hotKeys: 'Hot Keys',
  sounds: 'Sounds',
  language: 'Language',
  changeLanguage: 'Change language',
  darkMode: 'Dark Mode',
  lightMode: 'Light Mode',
  checkForUpdates: 'Check for\nupdates',
  options: 'options',
  english: 'English',
  russian: 'Русский',
  //#endregion 'Settings Tab'
  //#region 'Education Tab'
  quickStartGuide: 'Quick Start\nGuide',
  guide: 'Guide',
  basicCourses: 'Basic\nCourses',
  intermediateCourses: 'Intermediate\nCourses',
  advancedCourses: 'Advanced\nCourses',
  courses: 'Courses',
  //#endregion 'Education Tab'
  //#region 'Store Tab'
  buyLicense: 'Buy\nLicense',
  forexTester: 'Forex Tester',
  standardSubscription: 'Standard\nSubscription',
  vipSubscription: 'VIP\nSubscription',
  activateDataSubscription: 'Activate Data\nSubscription',
  historicalData: 'Historical Data',
  forexTradeCopier: 'Forex Trade\nCopier 3',
  forexRemoteCopier: 'Forex Remote\nCopier 2',
  tradeCopyingSoftware: 'Trade Copying Software',
  visualStrategyBuilderStoreTab: 'Easy Forex\nBuilder',
  visualIndicatorBuilder: 'Visual Indicator\nBuilder',
  visualBuilders: 'Visual Builders',
  indicatorsStoreTab: 'Indicators',
  strategiesStoreTab: 'Strategies',
  utilitiesStoreTab: 'Utilities',
  freeSoftware: 'Free Software',
  orderIndicatorsAndStrategies: 'Order Indicators and Strategies',
  hireProgrammer: 'Hire a Programmer',
  forexSpeedUp: 'Forex Speed Up',
  getTheLicense: 'Get the license',
  registerProgram: 'Register\nprogram',
  //#endregion 'Store Tab'
  //#region 'Help Tab'
  licensing: 'Licensing',
  videoTutorials: 'Video\nTutorials',
  faq: 'FAQ',
  userGuidePdf: 'User Guide (PDF)',
  userAccount: 'User Account',
  howToUseMaterials: 'How to use materials',
  partnershipProgram: 'Partnership\nProgram',
  partners: 'Partners',
  forexTesterHelp: 'Forex\nTester Help',
  apiHelp: 'API Help',
  help: 'Help',
  contactUs: 'Contact\nUs',
  forum: 'Forum',
  contacts: 'Contacts',
  aboutProgram: 'About\nProgram',
  info: 'Info',
  //#endregion 'Help Tab'
  //#region Data Tab
  dataCenter: 'Data\ncenter',
  projectDataSettings: 'Project Data\nSettings',
  dataSettings: 'Data settings',
  downloadStatus: 'Download status',
  dataLoading: 'dataLoading',
  updateNews: 'Update\nNews',
  //#endregion
  //#region Windows Tab
  cascade: 'Cascade',
  tileHorizontally: 'Tile\nHorizontally',
  tileVertically: 'Tile\nVertically',
  minimizeAll: 'Minimize\nAll',
  restoreAll: 'Restore All',
  marketWatchPanels: 'Market watch panels',
  symbols: 'Symbols',
  tickChart: 'Tick chart',
  dataWindow: 'Data\nwindow',
  tradingPanels: 'Trading panels',
  statistics: 'Statistics',
  openPositions: 'Open\nPositions',
  pendingOrders: 'Pending\nOrder',
  accountHistory: 'Account\nHistory',
  otherPanels: 'Other panels',
  journal: 'Journal',
  news: 'News',
  notes: 'Notes',
  graphicPanel: 'Graphic\nPanel',
  scripts: 'Scripts',
  maximizeActiveChartMain: 'Maximize\nActive',
  //#endregion
  //#endregion 'Top Menu'

  //#region 'Left Menu'
  data: 'Data',
  //#endregion 'Left Menu'

  //#region 'Chart'
  eurusdTab: 'eurusdTab',
  gbpusdTab: 'gbpusdTab',
  usdjpyTab: 'usdjpyTab',
  //#endregion 'Chart'

  //#region 'Bottom Menu'

  //#region 'Open Positions'
  //#region 'Market Order Table Header'
  ticket: 'Ticket',
  symbol: 'Symbol',
  type: 'Type',
  lots: 'Lots',
  openTime: 'Open Time',
  openPrice: 'Open price',
  sl: 'S/L',
  tp: 'T/P',
  marketPrice: 'Market Price',
  comment: 'Comment',
  swap: 'Swap',
  commission: 'Commission',
  points: 'Points',
  profit: 'Profit',
  openPositionsTicket: 'openPositionsTicket',
  openPositionsSymbol: 'openPositionsSymbol',
  openPositionsType: 'openPositionsType',
  openPositionsLots: 'openPositionsLots',
  openPositionsOpenTime: 'openPositionsOpenTime',
  openPositionOpenPrice: 'openPositionOpenPrice',
  openPositionsStopLoss: 'openPositionsStopLoss',
  openPositionsTakeProfit: 'openPositionsTakeProfit',
  openPositionsMarketPrice: 'openPositionsMarketPrice',
  openPositionsComment: 'openPositionsComment',
  openPositionsSwap: 'openPositionsSwap',
  openPositionsCommission: 'openPositionsCommission',
  openPositionsPoints: 'openPositionsPoints',
  openPositionsProfit: 'openPositionsProfit',
  //#endregion 'Market Order Table Header'
  //#region 'Market Order'
  marketOrderTicket: 'marketOrderTicket',
  marketOrderSymbol: 'marketOrderSymbol',
  marketOrderType: 'marketOrderType',
  marketOrderLots: 'marketOrderLots',
  marketOrderOpenTime: 'marketOrderOpenTime',
  marketOrderOpenPrice: 'marketOrderOpenPrice',
  marketOrderStopLoss: 'marketOrderStopLoss',
  marketOrderTakeProfit: 'marketOrderTakeProfit',
  marketOrderMarketPrice: 'marketOrderMarketPrice',
  marketOrderComment: 'marketOrderComment',
  marketOrderSwap: 'marketOrderSwap',
  marketOrderCommission: 'marketOrderCommission',
  marketOrderPoints: 'marketOrderPoints',
  marketOrderProfit: 'marketOrderProfit',
  marketOrderEditButton: 'marketOrderEditButton',
  marketOrderCloseButton: 'marketOrderCloseButton',
  //#region 'Market Order Context Menu'
  closePartOfPositionLabel: 'Close Part of Position',
  moveStopLossToBreakevenLabel: 'Move Stop Loss to Breakeven',
  duplicateMarketOrderLabel: 'Duplicate Position',
  viewAndModifyOrderLabel: 'View and Modify Order',
  //#endregion 'Market Order Context Menu'
  //#endregion 'Market Order'
  //#region 'Open Positions Account State'
  balanceLabel: 'Balance',
  equityLabel: 'Equity',
  marginLabel: 'Margin',
  freeMarginLabel: 'Free margin',
  marginLevelLabel: 'Margin level',
  //#endregion 'Open Positions Account State'
  //#endregion 'Open Positions'

  //#region 'Pending Orders'
  //#region 'Pending Order Table Header'
  createTime: 'Create Time',
  execPrice: 'Exec. Price',
  pendingOrdersTicket: 'pendingOrdersTicket',
  pendingOrdersSymbol: 'pendingOrdersSymbol',
  pendingOrdersType: 'pendingOrdersType',
  pendingOrdersLots: 'pendingOrdersLots',
  pendingOrdersCreateTime: 'pendingOrdersCreateTime',
  pendingOrdersExecPrice: 'pendingOrdersExecPrice',
  pendingOrdersStopLoss: 'pendingOrdersSl',
  pendingOrdersTakeProfit: 'pendingOrdersTp',
  pendingOrdersMarketPrice: 'pendingOrdersMarketPrice',
  pendingOrdersComment: 'pendingOrdersComment',
  //#endregion 'Pending Order Table Header'
  //#region 'Pending Order'
  pendingOrderTicket: 'pendingOrderTicket',
  pendingOrderSymbol: 'pendingOrderSymbol',
  pendingOrderType: 'pendingOrderType',
  pendingOrderLots: 'pendingOrderLots',
  pendingOrderCreateTime: 'pendingOrderCreateTime',
  pendingExecPrice: 'pendingOrderExecPrice',
  pendingOrderStopLoss: 'pendingOrderStopLoss',
  pendingOrderTakeProfit: 'pendingOrderTakeProfit',
  pendingOrderMarketPrice: 'pendingOrderMarketPrice',
  pendingOrderComment: 'pendingOrderComment',
  //#endregion 'Pending Order'
  //#endregion 'Pending Orders'

  //#region 'Account History'
  //#region 'Account History Table Header'
  closePrice: 'Close Price',
  accountHistoryTicket: 'accountHistoryTicket',
  accountHistorySymbol: 'accountHistorySymbol',
  accountHistoryType: 'accountHistoryType',
  accountHistoryLots: 'accountHistoryLots',
  accountHistoryOpenTime: 'accountHistoryOpenTime',
  accountHistoryOpenPrice: 'accountHistoryOpenPrice',
  accountHistoryStopLoss: 'accountHistoryStopLoss',
  accountHistoryTakeProfit: 'accountHistoryTakeProfit',
  accountHistoryMarketPrice: 'accountHistoryMarketPrice',
  accountHistoryClosePrice: 'accountHistoryClosePrice',
  accountHistoryComment: 'accountHistoryComment',
  accountHistorySwap: 'accountHistorySwap',
  accountHistoryCommission: 'accountHistoryCommission',
  accountHistoryPoints: 'accountHistoryPoints',
  accountHistoryProfit: 'accountHistoryProfit',
  //#endregion 'Account History Table Header'
  //#region 'Closed Order'
  closedOrderTicket: 'closedOrderTicket',
  closedOrderSymbol: 'closedOrderSymbol',
  closedOrderType: 'closedOrderType',
  closedOrderLots: 'closedOrderLots',
  closedOrderOpenTime: 'closedOrderOpenTime',
  closedOrderOpenPrice: 'closedOrderOpenPrice',
  closedOrderStopLoss: 'closedOrderStopLoss',
  closedOrderTakeProfit: 'closedOrderTakeProfit',
  closedOrderCloseTime: 'closedOrderCloseTime',
  closedOrderClosePrice: 'closedOrderClosePrice',
  closedOrderComment: 'closedOrderComment',
  closedOrderSwap: 'closedOrderSwap',
  closedOrderCommission: 'closedOrderCommission',
  closedOrderPoints: 'closedOrderPoints',
  closedOrderProfit: 'closedOrderProfit',
  //#region 'Closed Order Context Menu'
  exportToExcelFileLabel: 'Export History to an Excel (.xlsx) file',
  exportToTextFileLabel: 'Export History to a text (.txt) file',
  selectTradeLabel: 'Select Trade (Double click)',
  //#endregion 'Closed Order Context Menu'
  //#endregion 'Closed Order'
  //#region 'Account History Account State'
  profitLoss: 'Profit / Loss',
  deposit: 'Deposit',
  withdrawal: 'Withdrawal',
  //#endregion 'Account History Account State'
  //#endregion 'Account History'

  //#region 'Journal'
  //#region 'Journal Table Header'
  dateTime: 'Date / Time',
  message: 'Message',
  journalDateTime: 'journalDateTime',
  journalMessage: 'journalMessage',
  //#endregion 'Journal Table Header'
  //#region 'Journal Record'
  journalRecordDateTime: 'journalRecordDateTime',
  journalRecordMessage: 'journalRecordMessage',
  //#endregion 'Journal Record'
  //#region 'Journal Context Menu'
  deleteRecordLabel: 'Delete Record',
  clearJournalLabel: 'Clear Journal',
  //#endregion 'Journal Context Menu'

  //#endregion 'Journal'

  //#region 'News'
  //#region 'News Table Header'
  event: 'Event',
  priority: 'Priority',
  actual: 'Actual',
  forecast: 'Forecast',
  previous: 'Previous',
  //#endregion 'News Table Header'
  //#region 'News Filters'
  showFilters: 'Show filters',
  hideFilters: 'Hide filters',
  showByDate: 'Show by date',
  apply: 'Apply',
  clear: 'Clear',
  //#endregion 'News Filters'
  //#region 'News Record'
  newsRecordDateTime: 'newsRecordDateTime',
  newsRecordSymbol: 'newsRecordSymbol',
  newsRecordEvent: 'newsRecordEvent',
  newsRecordPriority: 'newsRecordPriority',
  newsRecordActual: 'newsRecordActual',
  newsRecordForecast: 'newsRecordForecast',
  newsRecordPrevious: 'newsRecordPrevious',
  //#endregion 'News Record'
  //#region 'News Context Menu'
  findNewsOnChartLabel: 'Find News on Chart (Double Click)'
  //#endregion 'News Context Menu'
  //#endregion 'News'

  //#region 'Notes'

  //#endregion 'Notes'

  //#endregion 'Bottom Menu'
}

export enum GraphicalToolsTexts {
  GraphicElements = 'Graphic\nElements',
  GraphicPanel = 'Graphic Panel',
  Note = 'Note',
  Lines = 'Lines',
  VerticalLine = 'Vertical Line',
  HorizontalLine = 'Horizontal Line',
  TrendLine = 'Trend Line',
  Ray = 'Ray',
  PolyLine = 'Polyline',
  Objects = 'Objects',
  ListOfObjects = 'List of Objects',
  ObjectsSelection = 'Objects selection',
  SelectAllObjects = 'Select All Objects',
  DeselectAllObjects = 'Deselect All Objects',
  Elements = 'Elements',
  RectangleShape = 'Rectangle Shape',
  EllipseShape = 'Ellipse Shape',
  TriangleShape = 'Triangle Shape',
  Icons = 'Icons',
  Channels = 'Channels',
  FibonacciTools = 'Fibonacci Tools',
  AndrewsPitchfork = "Andrew's Pitchfork",
  TextLabels = 'Text Labels',
  WaveSymbols = 'Wave Symbols',
  ObjectsDeletion = 'Objects Deletion',
  DeleteMostRecentObjects = 'Delete Most Recent Objects',
  DeleteSelectedObject = 'Delete Selected Object',
  DeleteAllObjects = 'Delete All Objects',
  ThumbUp = 'Thumb Up',
  ThumbDown = 'Thumb Down',
  ArrowUp = 'Arrow Up',
  ArrowDown = 'Arrow Down',
  StopIcon = 'Stop Icon',
  CheckIcon = 'Check Icon',
  RightPriceLabel = 'Right Price Label',
  LeftPriceLabel = 'Left Price Label',
  PriceLabel = 'Price Label',
  FiboChannel = 'Fibonacci Channel',
  LinearRegressionChannel = 'Linear Regression Channel',
  EquidistantChannel = 'Equidistant Channel',
  StandardDeviationChannel = 'Standard Deviation Channel',
  FibonacciRetracement = 'Fibonacci Retracement',
  FibonacciTimeZones = 'Fibonacci Time Zones',
  FibonacciFan = 'Fibonacci Fan',
  FibonacciArc = 'Fibonacci Arc',
  FibonacciExtension = 'Fibonacci Extension',
  RewardToRisk = 'Reward-to-Risk Tool',
  RewardToRiskBuy = 'Reward-to-Risk Buy',
  RewardToRiskSell = 'Reward-to-Risk Sell'
}

export enum GraphicalToolsTextsId {
  GraphicElements = '3171',
  GraphicPanel = '4200',
  Note = '4202',
  Lines = '4203',
  VerticalLine = '4204',
  HorizontalLine = '4205',
  TrendLine = '4206',
  Ray = '4207',
  PolyLine = '4208',
  Objects = '4209',
  ListOfObjects = '4210',
  ObjectsSelection = '4211',
  SelectAllObjects = '4212',
  DeselectAllObjects = '4213',
  Elements = '4214',
  RectangleShape = '4215',
  EllipseShape = '4216',
  TriangleShape = '4217',
  Icons = '4218',
  Channels = '4219',
  FibonacciTools = '4221',
  AndrewsPitchfork = '4223',
  TextLabels = '4224',
  WaveSymbols = '4225',
  ObjectsDeletion = '4226',
  DeleteMostRecentObjects = '4227',
  DeleteSelectedObject = '4228',
  DeleteAllObjects = '4229',
  ThumbUp = '4230',
  ThumbDown = '4231',
  ArrowUp = '4232',
  ArrowDown = '4233',
  StopIcon = '4234',
  CheckIcon = '4235',
  LeftPriceLabel = '4236',
  RightPriceLabel = '4237',
  PriceLabel = '4238',
  FiboChannel = '4239',
  LinearRegressionChannel = '4240',
  EquidistantChannel = '-1', // not find
  StandardDeviationChannel = '-1', // not find
  FibonacciRetracement = '4241',
  FibonacciTimeZones = '4242',
  FibonacciFan = '4243',
  FibonacciArc = '4244',
  FibonacciExtension = '4245',
  RewardToRisk = '4222',
  RewardToRiskBuy = '4247',
  RewardToRiskSell = '4248'
}

export enum GraphicalTools {
  Note = 'note',
  VerticalLine = 'verticalLine',
  HorizontalLine = 'horizontalLine',
  TrendLine = 'trendLine',
  Ray = 'ray',
  PolyLine = 'polyline',

  ListOfObjects = 'listOfObjects',
  ObjectsSelections = 'objectsSelections',
  SelectAllObjects = 'selectAllObjects',
  DeselectObjects = 'deselectAllObjects',

  Rectangle = 'rectangle',
  Ellipse = 'ellipse',
  Triangle = 'triangle',

  Icons = 'icons',
  AndrewsPitchfork = 'andrewsPitchfork',
  TextLabels = 'textLabels',
  GannFan = 'gannFan',
  WaveSymbols = 'waveSymbols',
  DeleteMostRecentObjects = 'deleteMostRecentObjects',
  DeleteSelected = 'deleteSelectedObject',
  DeleteAll = 'deleteAll',
  ThumbUp = 'thumbUp',
  ThumbDown = 'thumbDown',
  ArrowUp = 'arrowUp',
  ArrowDown = 'arrowDown',
  StopIcon = 'stopIcon',
  CheckIcon = 'checkIcon',
  RightPriceLabel = 'rightPriceLabel',
  LeftPriceLabel = 'leftPriceLabel',
  PriceLabel = 'priceLabel',
  InteractiveText = 'interactiveText',

  FiboChannel = 'fiboChannel',
  EquidistantChannel = 'equidistantChannel',
  StandardDeviationChannel = 'standardDeviationChannel',
  LinearRegressionChannel = 'linearRegressionChannel',

  FibonacciRetracement = 'fibonacciRetracement',
  FibonacciTimeZones = 'fibonacciTimeZones',
  FibonacciFan = 'fibonacciFan',
  FibonacciArc = 'fibonacciArc',
  FibonacciExtension = 'fibonacciExtension',

  RewardToRiskBuy = 'RewardToRiskBuy',
  RewardToRiskSell = 'RewardToRiskSell'
}

export enum AccountHistoryTableItems {
  accountHistoryTicket = 'accountHistoryTicket',
  accountHistorySymbol = 'accountHistorySymbol',
  accountHistoryType = 'accountHistoryType',
  accountHistoryLots = 'accountHistoryLots',
  accountHistoryOpenTime = 'accountHistoryOpenTime',
  accountHistoryOpenPrice = 'accountHistoryOpenPrice',
  accountHistoryStopLoss = 'accountHistoryStopLoss',
  accountHistoryTakeProfit = 'accountHistoryTakeProfit',
  accountHistoryCloseTime = 'accountHistoryCloseTime',
  accountHistoryClosePrice = 'accountHistoryClosePrice',
  accountHistoryComment = 'accountHistoryComment',
  accountHistorySwap = 'accountHistorySwap',
  accountHistoryCommission = 'accountHistoryCommission',
  accountHistoryPoints = 'accountHistoryPoints',
  accountHistoryProfit = 'accountHistoryProfit'
}

export enum AccountHistoryTableId {
  Symbol = '11',
  Type = '5133',
  Lots = '19',
  OpenTime = '21',
  OpenPrice = '23',
  StopLoss = '26',
  TakeProfit = '27',
  CloseTime = '22',
  ClosePrice = '24',
  Comment = '28',
  Swap = '29',
  Commission = '5247',
  Points = '31',
  Profit = '32'
}

export const AccountHistoryTableItemsIndexed: string[] = Object.values(AccountHistoryTableItems)

export enum AccountHistoryTab {
  AccountHistoryTable = 'AccountHistoryTable'
}

export enum NewProjectModal {
  NewProjectModalWindow = 'NewProjectModalLabelWindow',
  CrossModalButton = 'CrossModalButton',
  NextPageButton = 'NextPageButton',
  BackPageButton = 'BackPageButton',
  ProjectNameInput = 'ProjectNameInput',
  InitialDepositInput = 'InitialDepositInput',
  SearchSymbolsInput = 'SearchSymbolsInput',
  SymbolCheckbox = 'SymbolCheckbox',
  FromDateInput = 'FromDateInput',
  ToDateInput = 'ToDateInput',
  UseFloatingSpreadCheckBox = 'UseFloatingSpreadCheckbox',
  TickDataCheckBox = 'TickDataCheckBox',
  TimeZoneLabel = 'TimeZoneLabel',
  TimeZoneRange = 'TimeZoneRange',
  SessionCloseTimeInput = 'SessionCloseTimeInput',
  DstInput = 'DstInput',
  FirstDateInRangeRadioCheckbox = 'FirstDateInRangeRadioCheckbox',
  PreloadDateRadioCheckbox = 'PreloadDateRadioCheckbox',
  StartDateLabel = 'StartDateLabel',
  ToDateLabel = 'ToDateLabel',
  PreloadDateRange = 'PreloadDateRange',
  PreloadDateLabel = 'PreloadDateLabel',
  StartDateInput = 'StartDateInput',
  StartTestingAfterCreationCheckbox = 'StartTestingAfterCreation',
  ForwardTestingCheckbox = 'ForwardTestingCheckbox',
  CreateProjectButton = 'CreateProjectButton'
}

export enum IndicatorModalElements {
  PeriodField = 'IndicatorModalPeriodField',
  PeriodFieldArrowUp = 'IndicatorModalPeriodFieldArrowUp',
  PeriodFieldArrowDown = 'IndicatorModalPeriodFieldArrowDown',
  ApplyButton = 'IndicatorModalApplyButton',
  ModifyButton = 'IndicatorModalModifyButton',
  DeleteButton = 'IndicatorModalDeleteButton',
  CancelButton = 'IndicatorModalCancelButton'
}

export enum IndicatorModalTextId {
  Parameters = '2601',
  Parameter = '6077',
  Value = '34',
  Delete = '8',
  Cancel = '2',
  Apply = '6'
}

export enum TimeframeId {
  minute = '30000',
  minutes = '30001',
  hour = '30002',
  hours = '30003',
  day = '30004',
  week = '30005',
  month = '30006'
}

export function getTextIDByTimeframe(minutes: TimeFrame): number {
  return TimeframeId[minutes]
}

export enum OtherLocators {
  autoSaveIndicator = 'AutoSaveIndicator'
}
