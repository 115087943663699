export const defaultMFI = [
  {
    t: 1580731200,
    v: 13.300696329910892
  },
  {
    t: 1580734800,
    v: 7.367641495217953
  },
  {
    t: 1580738400,
    v: 6.779935077828171
  },
  {
    t: 1580742000,
    v: 6.365220309503201
  },
  {
    t: 1580745600,
    v: 6.1580337669380185
  },
  {
    t: 1580749200,
    v: 6.01582491973862
  },
  {
    t: 1580752800,
    v: 3.008467561747537
  },
  {
    t: 1580756400,
    v: 0
  },
  {
    t: 1580760000,
    v: 0
  },
  {
    t: 1580763600,
    v: 2.4301654090499056
  },
  {
    t: 1580767200,
    v: 4.269855546940107
  },
  {
    t: 1580770800,
    v: 10.089290077471176
  },
  {
    t: 1580774400,
    v: 11.051643766360854
  },
  {
    t: 1580778000,
    v: 11.555422307222585
  },
  {
    t: 1580781600,
    v: 17.209129236706005
  },
  {
    t: 1580785200,
    v: 24.346532431049667
  },
  {
    t: 1580788800,
    v: 32.50711128829853
  },
  {
    t: 1580792400,
    v: 42.90887791975788
  },
  {
    t: 1580796000,
    v: 45.01564403027125
  },
  {
    t: 1580799600,
    v: 43.25074552860351
  },
  {
    t: 1580803200,
    v: 54.24669945578354
  },
  {
    t: 1580806800,
    v: 64.39632956079065
  },
  {
    t: 1580810400,
    v: 71.76226886202869
  },
  {
    t: 1580814000,
    v: 73.37229148489581
  },
  {
    t: 1580817600,
    v: 74.52313770825293
  },
  {
    t: 1580821200,
    v: 76.063516808138
  },
  {
    t: 1580824800,
    v: 70.17537992429804
  },
  {
    t: 1580828400,
    v: 76.01738124350632
  },
  {
    t: 1580832000,
    v: 69.93319568126614
  },
  {
    t: 1580835600,
    v: 70.03438807797198
  },
  {
    t: 1580839200,
    v: 70.10700742766164
  },
  {
    t: 1580842800,
    v: 66.60703346894356
  },
  {
    t: 1580846400,
    v: 67.47291312033478
  },
  {
    t: 1580850000,
    v: 72.20169517867447
  },
  {
    t: 1580853600,
    v: 66.30572179842747
  },
  {
    t: 1580857200,
    v: 59.805708149758395
  },
  {
    t: 1580860800,
    v: 57.8099775118563
  },
  {
    t: 1580864400,
    v: 49.97487061249052
  },
  {
    t: 1580868000,
    v: 44.07695242997844
  },
  {
    t: 1580871600,
    v: 38.24754996509365
  },
  {
    t: 1580875200,
    v: 42.775613618909055
  },
  {
    t: 1580878800,
    v: 30.602846623588334
  },
  {
    t: 1580882400,
    v: 38.325380453997056
  },
  {
    t: 1580886000,
    v: 39.909348857403785
  },
  {
    t: 1580889600,
    v: 46.157285464362516
  },
  {
    t: 1580893200,
    v: 57.265932934461
  },
  {
    t: 1580896800,
    v: 54.78194320955413
  },
  {
    t: 1580900400,
    v: 60.93868171958685
  },
  {
    t: 1580904000,
    v: 58.58965385313472
  },
  {
    t: 1580907600,
    v: 53.578341651822136
  },
  {
    t: 1580911200,
    v: 44.2415221618165
  },
  {
    t: 1580914800,
    v: 52.688090212106516
  },
  {
    t: 1580918400,
    v: 57.91907058850974
  },
  {
    t: 1580922000,
    v: 58.66263203515301
  },
  {
    t: 1580925600,
    v: 58.12046609330002
  },
  {
    t: 1580929200,
    v: 57.9176402331193
  },
  {
    t: 1580932800,
    v: 55.12043360310383
  },
  {
    t: 1580936400,
    v: 53.11514200752102
  },
  {
    t: 1580940000,
    v: 46.39068819044587
  },
  {
    t: 1580943600,
    v: 37.85179037292477
  },
  {
    t: 1580947200,
    v: 39.717648753021095
  },
  {
    t: 1580950800,
    v: 31.935836675643202
  },
  {
    t: 1580954400,
    v: 33.34024838517182
  },
  {
    t: 1580958000,
    v: 41.58551277061791
  },
  {
    t: 1580961600,
    v: 48.02382038017806
  },
  {
    t: 1580965200,
    v: 33.83347736359937
  },
  {
    t: 1580968800,
    v: 29.860369582579523
  },
  {
    t: 1580972400,
    v: 32.23572925959803
  },
  {
    t: 1580976000,
    v: 29.36641642298595
  },
  {
    t: 1580979600,
    v: 27.53642806934694
  },
  {
    t: 1580983200,
    v: 26.054166166356666
  },
  {
    t: 1580986800,
    v: 21.256478272449712
  },
  {
    t: 1580990400,
    v: 27.700624965311135
  },
  {
    t: 1580994000,
    v: 25.874760281871346
  },
  {
    t: 1580997600,
    v: 24.158668252543805
  },
  {
    t: 1581001200,
    v: 22.705918694922744
  },
  {
    t: 1581004800,
    v: 21.7985023745791
  },
  {
    t: 1581008400,
    v: 23.065088300468602
  },
  {
    t: 1581012000,
    v: 23.18540224856821
  },
  {
    t: 1581015600,
    v: 23.09974639148166
  },
  {
    t: 1581019200,
    v: 23.003654997383606
  },
  {
    t: 1581022800,
    v: 16.09085093563246
  },
  {
    t: 1581026400,
    v: 19.577871955087886
  },
  {
    t: 1581030000,
    v: 23.657229176396854
  },
  {
    t: 1581033600,
    v: 28.033547622800157
  },
  {
    t: 1581037200,
    v: 35.384050691134945
  },
  {
    t: 1581040800,
    v: 34.27827886689954
  },
  {
    t: 1581044400,
    v: 42.48551293795143
  },
  {
    t: 1581048000,
    v: 47.44234052807793
  },
  {
    t: 1581051600,
    v: 60.233425585180825
  },
  {
    t: 1581055200,
    v: 73.62139590307697
  },
  {
    t: 1581058800,
    v: 57.913256571153006
  },
  {
    t: 1581062400,
    v: 51.307370224635704
  },
  {
    t: 1581066000,
    v: 61.305291595355214
  },
  {
    t: 1581069600,
    v: 63.93443760657445
  },
  {
    t: 1581073200,
    v: 60.26090407040723
  },
  {
    t: 1581076800,
    v: 63.4898950611077
  },
  {
    t: 1581080400,
    v: 54.76500282655248
  },
  {
    t: 1581084000,
    v: 58.81077131427128
  },
  {
    t: 1581087600,
    v: 61.70637004858759
  },
  {
    t: 1581091200,
    v: 54.433105531705
  },
  {
    t: 1581094800,
    v: 50.44512228190856
  },
  {
    t: 1581098400,
    v: 49.26191247291986
  },
  {
    t: 1581102000,
    v: 46.17044482472558
  },
  {
    t: 1581105600,
    v: 42.10791162334295
  },
  {
    t: 1581109200,
    v: 44.87693064508169
  },
  {
    t: 1581285600,
    v: 50.11294250605806
  },
  {
    t: 1581289200,
    v: 48.62786772404943
  },
  {
    t: 1581292800,
    v: 45.75629806615851
  },
  {
    t: 1581296400,
    v: 52.38470101904709
  },
  {
    t: 1581300000,
    v: 49.95995334820068
  },
  {
    t: 1581303600,
    v: 55.22325600098336
  },
  {
    t: 1581307200,
    v: 49.781337393868846
  },
  {
    t: 1581310800,
    v: 42.11025744430684
  },
  {
    t: 1581314400,
    v: 47.131630503471285
  },
  {
    t: 1581318000,
    v: 44.537996927579776
  },
  {
    t: 1581321600,
    v: 57.063135428917526
  },
  {
    t: 1581325200,
    v: 67.19619916986898
  },
  {
    t: 1581328800,
    v: 75.2094701115409
  },
  {
    t: 1581332400,
    v: 80.06114426167345
  },
  {
    t: 1581336000,
    v: 81.70474711202742
  },
  {
    t: 1581339600,
    v: 82.55277750895596
  },
  {
    t: 1581343200,
    v: 83.48220775561025
  },
  {
    t: 1581346800,
    v: 74.21920882799085
  },
  {
    t: 1581350400,
    v: 67.33543069900294
  },
  {
    t: 1581354000,
    v: 66.02917392613057
  },
  {
    t: 1581357600,
    v: 66.53847413280548
  },
  {
    t: 1581361200,
    v: 67.07790709401156
  },
  {
    t: 1581364800,
    v: 66.73089321110815
  },
  {
    t: 1581368400,
    v: 73.10290743429536
  },
  {
    t: 1581372000,
    v: 69.68315965773753
  },
  {
    t: 1581375600,
    v: 67.87707569279277
  },
  {
    t: 1581379200,
    v: 61.06957997125035
  },
  {
    t: 1581382800,
    v: 59.21774770576982
  },
  {
    t: 1581386400,
    v: 52.468342090911065
  },
  {
    t: 1581390000,
    v: 47.43638500989719
  },
  {
    t: 1581393600,
    v: 38.31905116044078
  },
  {
    t: 1581397200,
    v: 48.59770350621422
  },
  {
    t: 1581400800,
    v: 61.03026562188674
  },
  {
    t: 1581404400,
    v: 57.53500003517362
  },
  {
    t: 1581408000,
    v: 45.22758070476515
  },
  {
    t: 1581411600,
    v: 50.46197102815481
  },
  {
    t: 1581415200,
    v: 59.97603313978154
  },
  {
    t: 1581418800,
    v: 51.87533806744951
  },
  {
    t: 1581422400,
    v: 56.79775433883042
  },
  {
    t: 1581426000,
    v: 59.66860961012997
  },
  {
    t: 1581429600,
    v: 66.93853881192744
  },
  {
    t: 1581433200,
    v: 57.25676824396642
  },
  {
    t: 1581436800,
    v: 54.87450284691392
  },
  {
    t: 1581440400,
    v: 56.13867251201198
  },
  {
    t: 1581444000,
    v: 59.2613991719677
  },
  {
    t: 1581447600,
    v: 60.34439258156407
  },
  {
    t: 1581451200,
    v: 56.99171362834183
  },
  {
    t: 1581454800,
    v: 60.18577683097534
  },
  {
    t: 1581458400,
    v: 66.31419045427133
  },
  {
    t: 1581462000,
    v: 63.04622631372267
  },
  {
    t: 1581465600,
    v: 56.84457472106909
  },
  {
    t: 1581469200,
    v: 64.7153183365394
  },
  {
    t: 1581472800,
    v: 63.28332002723165
  },
  {
    t: 1581476400,
    v: 54.77340395860973
  },
  {
    t: 1581480000,
    v: 44.240914574177054
  },
  {
    t: 1581483600,
    v: 57.306212216119185
  },
  {
    t: 1581487200,
    v: 60.12345084673498
  },
  {
    t: 1581490800,
    v: 61.722606086191036
  },
  {
    t: 1581494400,
    v: 64.42142747319721
  },
  {
    t: 1581498000,
    v: 66.18289345485613
  },
  {
    t: 1581501600,
    v: 73.84261555856271
  },
  {
    t: 1581505200,
    v: 78.74007473358935
  },
  {
    t: 1581508800,
    v: 71.2823868144877
  },
  {
    t: 1581512400,
    v: 63.811402228090635
  },
  {
    t: 1581516000,
    v: 60.36452407176425
  },
  {
    t: 1581519600,
    v: 62.82937133717518
  },
  {
    t: 1581523200,
    v: 64.0705884740129
  },
  {
    t: 1581526800,
    v: 62.725377579484785
  },
  {
    t: 1581530400,
    v: 62.66801580116025
  },
  {
    t: 1581534000,
    v: 58.28617590995897
  },
  {
    t: 1581537600,
    v: 58.34537386731677
  },
  {
    t: 1581541200,
    v: 53.622257584478675
  },
  {
    t: 1581544800,
    v: 47.269435132964595
  },
  {
    t: 1581548400,
    v: 39.16533564495724
  },
  {
    t: 1581552000,
    v: 37.65404118906251
  },
  {
    t: 1581555600,
    v: 36.02126532877858
  },
  {
    t: 1581559200,
    v: 41.63191191997686
  },
  {
    t: 1581562800,
    v: 43.721021956981325
  },
  {
    t: 1581566400,
    v: 46.87492629137478
  },
  {
    t: 1581570000,
    v: 34.192217275118125
  },
  {
    t: 1581573600,
    v: 30.50158258314461
  },
  {
    t: 1581577200,
    v: 43.27298284487083
  },
  {
    t: 1581580800,
    v: 53.75385123670442
  },
  {
    t: 1581584400,
    v: 51.51868472773429
  },
  {
    t: 1581588000,
    v: 59.97133773540778
  },
  {
    t: 1581591600,
    v: 54.08835884314645
  },
  {
    t: 1581595200,
    v: 61.730057832668855
  },
  {
    t: 1581598800,
    v: 68.54841595416607
  },
  {
    t: 1581602400,
    v: 69.62878709090933
  },
  {
    t: 1581606000,
    v: 71.54000803924926
  },
  {
    t: 1581609600,
    v: 66.00120533265473
  },
  {
    t: 1581613200,
    v: 69.90376351268299
  },
  {
    t: 1581616800,
    v: 72.9745722393103
  },
  {
    t: 1581620400,
    v: 74.10698623836913
  },
  {
    t: 1581624000,
    v: 70.90470192182909
  },
  {
    t: 1581627600,
    v: 66.5528739241021
  },
  {
    t: 1581631200,
    v: 60.762086002774794
  },
  {
    t: 1581634800,
    v: 67.18552913100879
  },
  {
    t: 1581638400,
    v: 65.80638060907975
  },
  {
    t: 1581642000,
    v: 75.37118474647296
  },
  {
    t: 1581645600,
    v: 72.13255160002515
  },
  {
    t: 1581649200,
    v: 64.81896045809037
  },
  {
    t: 1581652800,
    v: 57.127360623379275
  },
  {
    t: 1581656400,
    v: 50.2684780524344
  },
  {
    t: 1581660000,
    v: 61.605737400048014
  },
  {
    t: 1581663600,
    v: 63.41509952383458
  },
  {
    t: 1581667200,
    v: 51.09094239240365
  },
  {
    t: 1581670800,
    v: 46.998234484226835
  },
  {
    t: 1581674400,
    v: 44.41549822361748
  },
  {
    t: 1581678000,
    v: 52.04440405664965
  },
  {
    t: 1581681600,
    v: 50.058211161787476
  },
  {
    t: 1581685200,
    v: 41.41484143317756
  },
  {
    t: 1581688800,
    v: 44.53470891611837
  },
  {
    t: 1581692400,
    v: 37.486703688874144
  },
  {
    t: 1581696000,
    v: 40.54273466747681
  },
  {
    t: 1581699600,
    v: 44.94560089050612
  },
  {
    t: 1581703200,
    v: 47.94726737093345
  },
  {
    t: 1581706800,
    v: 44.11770873658163
  },
  {
    t: 1581710400,
    v: 44.07933701819642
  },
  {
    t: 1581714000,
    v: 40.64616641623742
  },
  {
    t: 1581890400,
    v: 44.30695539829632
  },
  {
    t: 1581894000,
    v: 51.01140339587418
  },
  {
    t: 1581897600,
    v: 52.61203540518701
  },
  {
    t: 1581901200,
    v: 45.49807166029837
  },
  {
    t: 1581904800,
    v: 53.36480580025543
  },
  {
    t: 1581908400,
    v: 61.35520426540723
  },
  {
    t: 1581912000,
    v: 51.88276927427325
  },
  {
    t: 1581915600,
    v: 63.52883137996677
  },
  {
    t: 1581919200,
    v: 53.43165532813225
  },
  {
    t: 1581922800,
    v: 40.78679682386804
  },
  {
    t: 1581926400,
    v: 45.90923531604928
  },
  {
    t: 1581930000,
    v: 57.366810359306996
  },
  {
    t: 1581933600,
    v: 48.0674721049451
  },
  {
    t: 1581937200,
    v: 40.747984314083425
  },
  {
    t: 1581940800,
    v: 37.23721689103622
  },
  {
    t: 1581944400,
    v: 39.67099010651378
  },
  {
    t: 1581948000,
    v: 38.6744042910496
  },
  {
    t: 1581951600,
    v: 45.03939064649004
  },
  {
    t: 1581955200,
    v: 44.01870373639204
  },
  {
    t: 1581958800,
    v: 46.59239483887651
  },
  {
    t: 1581962400,
    v: 43.75899521986721
  },
  {
    t: 1581966000,
    v: 39.68927944470834
  },
  {
    t: 1581969600,
    v: 43.73666970114348
  },
  {
    t: 1581973200,
    v: 50.457742026175325
  },
  {
    t: 1581976800,
    v: 44.78462138353015
  },
  {
    t: 1581980400,
    v: 40.71647909638326
  },
  {
    t: 1581984000,
    v: 41.973816096883944
  },
  {
    t: 1581987600,
    v: 43.161367509151795
  },
  {
    t: 1581991200,
    v: 51.32154998558319
  },
  {
    t: 1581994800,
    v: 48.92382891813739
  },
  {
    t: 1581998400,
    v: 52.39392135855271
  },
  {
    t: 1582002000,
    v: 44.403398680074076
  },
  {
    t: 1582005600,
    v: 37.69373679506587
  },
  {
    t: 1582009200,
    v: 43.91275840538065
  },
  {
    t: 1582012800,
    v: 42.31120022260137
  },
  {
    t: 1582016400,
    v: 53.865685981068225
  },
  {
    t: 1582020000,
    v: 58.804689327932884
  },
  {
    t: 1582023600,
    v: 61.02295852857301
  },
  {
    t: 1582027200,
    v: 55.73934134365875
  },
  {
    t: 1582030800,
    v: 48.633946008874055
  },
  {
    t: 1582034400,
    v: 46.353181275871925
  },
  {
    t: 1582038000,
    v: 53.631898082062996
  },
  {
    t: 1582041600,
    v: 47.96961946410454
  },
  {
    t: 1582045200,
    v: 43.272324387256916
  },
  {
    t: 1582048800,
    v: 42.4592412589711
  },
  {
    t: 1582052400,
    v: 45.7721785758534
  },
  {
    t: 1582056000,
    v: 49.019390766303836
  },
  {
    t: 1582059600,
    v: 43.9581777149304
  },
  {
    t: 1582063200,
    v: 49.28797605650318
  },
  {
    t: 1582066800,
    v: 46.36694415086233
  },
  {
    t: 1582070400,
    v: 36.64491042657409
  },
  {
    t: 1582074000,
    v: 33.47958642731888
  },
  {
    t: 1582077600,
    v: 34.811294463150915
  },
  {
    t: 1582081200,
    v: 38.503066494058864
  },
  {
    t: 1582084800,
    v: 45.5355441941888
  },
  {
    t: 1582088400,
    v: 35.34422963456396
  },
  {
    t: 1582092000,
    v: 38.50841728117277
  },
  {
    t: 1582095600,
    v: 52.670130636786816
  },
  {
    t: 1582099200,
    v: 49.78079432753035
  },
  {
    t: 1582102800,
    v: 36.95756064124242
  },
  {
    t: 1582106400,
    v: 29.355084395725925
  },
  {
    t: 1582110000,
    v: 27.399504384722448
  },
  {
    t: 1582113600,
    v: 33.34299363542648
  },
  {
    t: 1582117200,
    v: 25.873927791156106
  },
  {
    t: 1582120800,
    v: 24.185271949179764
  },
  {
    t: 1582124400,
    v: 17.631178051192308
  },
  {
    t: 1582128000,
    v: 16.89771489704175
  },
  {
    t: 1582131600,
    v: 16.192529383197552
  },
  {
    t: 1582135200,
    v: 14.090016098441367
  },
  {
    t: 1582138800,
    v: 13.506582110990763
  },
  {
    t: 1582142400,
    v: 18.890061874950945
  },
  {
    t: 1582146000,
    v: 13.005343741490535
  },
  {
    t: 1582149600,
    v: 13.893166064009236
  },
  {
    t: 1582153200,
    v: 17.78890811796815
  },
  {
    t: 1582156800,
    v: 24.073925048351228
  },
  {
    t: 1582160400,
    v: 24.601135131626663
  },
  {
    t: 1582164000,
    v: 16.360226825014806
  },
  {
    t: 1582167600,
    v: 17.278984572479075
  },
  {
    t: 1582171200,
    v: 24.216240601957736
  },
  {
    t: 1582174800,
    v: 31.068822474062685
  },
  {
    t: 1582178400,
    v: 33.66513943940046
  },
  {
    t: 1582182000,
    v: 32.13584895949663
  },
  {
    t: 1582185600,
    v: 30.67454520635866
  },
  {
    t: 1582189200,
    v: 43.03896871355331
  },
  {
    t: 1582192800,
    v: 32.865153041589224
  },
  {
    t: 1582196400,
    v: 30.33897290779325
  },
  {
    t: 1582200000,
    v: 27.829552620157315
  },
  {
    t: 1582203600,
    v: 23.438873544572914
  },
  {
    t: 1582207200,
    v: 25.76261008743225
  },
  {
    t: 1582210800,
    v: 32.853395628590675
  },
  {
    t: 1582214400,
    v: 40.19393213306359
  },
  {
    t: 1582218000,
    v: 45.43034289652496
  },
  {
    t: 1582221600,
    v: 41.43797414293372
  },
  {
    t: 1582225200,
    v: 42.18736857014747
  },
  {
    t: 1582228800,
    v: 42.38114442348904
  },
  {
    t: 1582232400,
    v: 45.40097983536073
  },
  {
    t: 1582236000,
    v: 49.072155323594195
  },
  {
    t: 1582239600,
    v: 44.679451832558215
  },
  {
    t: 1582243200,
    v: 52.5618937692877
  },
  {
    t: 1582246800,
    v: 62.248349080873446
  },
  {
    t: 1582250400,
    v: 72.17644660092785
  },
  {
    t: 1582254000,
    v: 83.638184169059
  },
  {
    t: 1582257600,
    v: 77.67927674090596
  },
  {
    t: 1582261200,
    v: 75.86321231145655
  },
  {
    t: 1582264800,
    v: 66.54842962830699
  },
  {
    t: 1582268400,
    v: 53.02384583210952
  },
  {
    t: 1582272000,
    v: 66.0464932208717
  },
  {
    t: 1582275600,
    v: 68.96585173998032
  },
  {
    t: 1582279200,
    v: 64.42497633802517
  },
  {
    t: 1582282800,
    v: 70.61897718772866
  },
  {
    t: 1582286400,
    v: 64.50002399433777
  },
  {
    t: 1582290000,
    v: 66.83180540045208
  },
  {
    t: 1582293600,
    v: 68.91691439523655
  },
  {
    t: 1582297200,
    v: 71.32160499685148
  },
  {
    t: 1582300800,
    v: 72.42115960107134
  },
  {
    t: 1582304400,
    v: 73.07094064873695
  },
  {
    t: 1582308000,
    v: 75.94300828390212
  },
  {
    t: 1582311600,
    v: 72.49988402356439
  },
  {
    t: 1582315200,
    v: 70.98663003259631
  },
  {
    t: 1582318800,
    v: 74.58843397416416
  },
  {
    t: 1582495200,
    v: 70.88233914984754
  },
  {
    t: 1582498800,
    v: 68.4186791252343
  },
  {
    t: 1582502400,
    v: 71.78954361050374
  },
  {
    t: 1582506000,
    v: 64.98615348335913
  },
  {
    t: 1582509600,
    v: 69.47469719613264
  },
  {
    t: 1582513200,
    v: 62.1432645912164
  },
  {
    t: 1582516800,
    v: 58.434187776747386
  },
  {
    t: 1582520400,
    v: 51.42566121926039
  },
  {
    t: 1582524000,
    v: 45.91544674208348
  },
  {
    t: 1582527600,
    v: 47.948300136572904
  },
  {
    t: 1582531200,
    v: 34.26837627470526
  },
  {
    t: 1582534800,
    v: 30.666698657115944
  },
  {
    t: 1582538400,
    v: 29.213398497144
  },
  {
    t: 1582542000,
    v: 37.917735458745774
  },
  {
    t: 1582545600,
    v: 44.173375901496115
  },
  {
    t: 1582549200,
    v: 38.26873406383069
  },
  {
    t: 1582552800,
    v: 47.04638707471179
  },
  {
    t: 1582556400,
    v: 54.98630257519704
  },
  {
    t: 1582560000,
    v: 52.343310892741044
  },
  {
    t: 1582563600,
    v: 57.17507007750678
  },
  {
    t: 1582567200,
    v: 58.078656359277545
  },
  {
    t: 1582570800,
    v: 53.69563243115868
  },
  {
    t: 1582574400,
    v: 55.560736869293805
  },
  {
    t: 1582578000,
    v: 54.17017132671388
  },
  {
    t: 1582581600,
    v: 58.233724657052186
  },
  {
    t: 1582585200,
    v: 65.85663600698359
  },
  {
    t: 1582588800,
    v: 68.77052761937301
  },
  {
    t: 1582592400,
    v: 67.89160990971209
  },
  {
    t: 1582596000,
    v: 66.83913938671316
  },
  {
    t: 1582599600,
    v: 70.79007872010348
  },
  {
    t: 1582603200,
    v: 64.28542773261984
  },
  {
    t: 1582606800,
    v: 54.98379815360834
  },
  {
    t: 1582610400,
    v: 65.18320997677938
  },
  {
    t: 1582614000,
    v: 65.93515126360762
  },
  {
    t: 1582617600,
    v: 68.58938302434798
  },
  {
    t: 1582621200,
    v: 78.76457219400514
  },
  {
    t: 1582624800,
    v: 66.62681160932198
  },
  {
    t: 1582628400,
    v: 58.107556452892325
  },
  {
    t: 1582632000,
    v: 53.92687481553446
  },
  {
    t: 1582635600,
    v: 57.190300365604
  },
  {
    t: 1582639200,
    v: 63.37890249962274
  },
  {
    t: 1582642800,
    v: 65.81910973628295
  },
  {
    t: 1582646400,
    v: 67.59821786720941
  },
  {
    t: 1582650000,
    v: 71.19772728295204
  },
  {
    t: 1582653600,
    v: 68.9588968125368
  },
  {
    t: 1582657200,
    v: 66.88701689736669
  },
  {
    t: 1582660800,
    v: 62.29609756585538
  },
  {
    t: 1582664400,
    v: 60.47626189981285
  },
  {
    t: 1582668000,
    v: 55.73438264104266
  },
  {
    t: 1582671600,
    v: 52.563726384514496
  },
  {
    t: 1582675200,
    v: 55.49078908297705
  },
  {
    t: 1582678800,
    v: 62.95871876893167
  },
  {
    t: 1582682400,
    v: 65.75607811267409
  },
  {
    t: 1582686000,
    v: 57.9393479547
  },
  {
    t: 1582689600,
    v: 50.32088234766349
  },
  {
    t: 1582693200,
    v: 44.55869133826244
  },
  {
    t: 1582696800,
    v: 32.45189368280717
  },
  {
    t: 1582700400,
    v: 22.39174311315668
  },
  {
    t: 1582704000,
    v: 21.341980781513342
  },
  {
    t: 1582707600,
    v: 20.326415484001885
  },
  {
    t: 1582711200,
    v: 19.61873582255288
  },
  {
    t: 1582714800,
    v: 15.092255370589882
  },
  {
    t: 1582718400,
    v: 13.652126029068668
  },
  {
    t: 1582722000,
    v: 18.66287409009057
  },
  {
    t: 1582725600,
    v: 17.890830330162288
  },
  {
    t: 1582729200,
    v: 12.379106245868869
  },
  {
    t: 1582732800,
    v: 12.053057989609485
  },
  {
    t: 1582736400,
    v: 11.855482020105171
  },
  {
    t: 1582740000,
    v: 11.537621479704228
  },
  {
    t: 1582743600,
    v: 14.154217184749768
  },
  {
    t: 1582747200,
    v: 14.055972010030715
  },
  {
    t: 1582750800,
    v: 14.72343619422945
  },
  {
    t: 1582754400,
    v: 15.814973312574693
  },
  {
    t: 1582758000,
    v: 20.760183764365195
  },
  {
    t: 1582761600,
    v: 29.35650343713857
  },
  {
    t: 1582765200,
    v: 33.41746317722894
  },
  {
    t: 1582768800,
    v: 41.75070926303839
  },
  {
    t: 1582772400,
    v: 38.150529620883034
  },
  {
    t: 1582776000,
    v: 45.034922664338865
  },
  {
    t: 1582779600,
    v: 55.26053034673695
  },
  {
    t: 1582783200,
    v: 65.71723779743334
  },
  {
    t: 1582786800,
    v: 63.601659566448376
  },
  {
    t: 1582790400,
    v: 74.4931801673067
  },
  {
    t: 1582794000,
    v: 62.66966812355212
  },
  {
    t: 1582797600,
    v: 58.980226960452924
  },
  {
    t: 1582801200,
    v: 54.93294021713964
  },
  {
    t: 1582804800,
    v: 60.26708644764782
  },
  {
    t: 1582808400,
    v: 63.0723558160756
  },
  {
    t: 1582812000,
    v: 53.44914037447536
  },
  {
    t: 1582815600,
    v: 58.1509586868239
  },
  {
    t: 1582819200,
    v: 51.68709714386845
  },
  {
    t: 1582822800,
    v: 53.05470162587196
  },
  {
    t: 1582826400,
    v: 54.6323328266696
  },
  {
    t: 1582830000,
    v: 54.7737618565544
  },
  {
    t: 1582833600,
    v: 50.04698417022216
  },
  {
    t: 1582837200,
    v: 51.951049274352314
  },
  {
    t: 1582840800,
    v: 46.85948757855235
  },
  {
    t: 1582844400,
    v: 52.9178233931092
  },
  {
    t: 1582851600,
    v: 58.8142118423153
  },
  {
    t: 1582855200,
    v: 60.93956986519464
  },
  {
    t: 1582858800,
    v: 53.13699595048588
  },
  {
    t: 1582862400,
    v: 49.75663085831504
  },
  {
    t: 1582866000,
    v: 60.221959763340486
  },
  {
    t: 1582869600,
    v: 47.729652056309696
  },
  {
    t: 1582873200,
    v: 47.7776020021362
  },
  {
    t: 1582876800,
    v: 50.745748268269985
  },
  {
    t: 1582880400,
    v: 53.13493361408044
  },
  {
    t: 1582884000,
    v: 43.84084358514631
  },
  {
    t: 1582887600,
    v: 41.7897124864281
  },
  {
    t: 1582891200,
    v: 39.239338217218425
  },
  {
    t: 1582894800,
    v: 35.7579866381144
  },
  {
    t: 1582898400,
    v: 29.91472074879897
  },
  {
    t: 1582902000,
    v: 26.735945456671075
  },
  {
    t: 1582905600,
    v: 25.060135325701467
  },
  {
    t: 1582909200,
    v: 24.412085114981195
  },
  {
    t: 1582912800,
    v: 20.231850562919988
  },
  {
    t: 1582916400,
    v: 20.907987314721908
  }
]
