export const drawingObjectMap = {
  retracements: 'retracements',
  gann_fans: 'fans',
  equidistant_channels: 'channels',
  standard_deviation_channels: 'channels',
  rectangles: 'rectangles',
  trend_lines: 'trends',
  textList: 'textList',
  ellipses: 'ellipses',
  triangles: 'triangles'
}

export const chartCanvasMargin = {
  left: 20,
  right: 70,
  top: 20,
  bottom: 25
}

export const chartCanvasMarginForMobile = {
  left: 20,
  right: 70,
  top: 20,
  bottom: 20
}

export const priceChartBottomOffsetForBrowserView: number = 44

export const chartPadding = {
  top: 10,
  bottom: 30
}

export const gridMargin = {
  left: 15,
  right: 50,
  top: 20,
  bottom: 50
}

export const defaultVisibleBarsCount = 60
export const defaultAxisYMarkHeight = 50
