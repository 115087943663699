import { ReactNode } from 'react'
import { ComparisonOperator, EnableButtonRuleType, FtControlType, MarketOrderTypes, PendingOrderTypes } from '../enums'

export interface IStep {
  content: ReactNode
  target: string | HTMLElement
}

export interface ITutorialStep {
  Name: string
  MouseEnabledInActiveRect: boolean
  ChartsSettings: {
    ActiveChart: string
    ChartTimeFrame: string
  }
  TestingSettings: {
    WndOrientation: number
    RemoveObjects: boolean
    RestartProject: boolean
    StartTestingAfterRestart: boolean
    PauseTesting: boolean
    ResumeTesting: boolean
    UserMustStartTesting: boolean
    TestingSpeed: number
    JumpTo: string
    TickPackage: string
  }
  TutorialWindow: {
    WindowAlignmentType: number
    WindowAlignment: number
    FtControlType: FtControlType
    FtControlName: string
    ControlId: number
    RibbonTabId: number
    GroupId: number
    MenuItemId: number
    IndicatorName: string
  }
  WindowLayout: {
    LeftPoint: number
    TopPoint: number
    Width: number
    Height: number
  }
  StepInfo: {
    StepContent: ITutorialContent
    StepActions: ITrigger[]
  }
}

export interface ITrigger {
  Type: number
  Action: {
    Type: number
    Properties: {
      NextStepName: string
      TabName: string
      Control: {
        FtControlName: string
        RefinedRectInfo: string
      }
      Orders: IOrderProperties[]
      GraphicalTools: IGraphicalTool[]
      WaitFor: string
      ValidateNumberRules: {
        CompareWithAskPrice: boolean
        ComparisonOperator: ComparisonOperator
        MaxPointsDistanceFromPrice: number
        NextStepIfValid: string
        NextStepIfInvalid: string
        ValidatedNumberSource: string
      }
    }
  }
}

export interface ITutorialStepInfo {
  StepContent: ITutorialContent
  StepActions: ITutorialStepActions
}

export interface ITutorialStepActions {
  Orders: IOrderProperties[]
  GraphicalTools: IGraphicalTool[]
  JumpTo: string | null
}

export interface ITutorialItem {
  id: string
  Name: string
  Description: string
  LearningTime: string
  LessonID: number
  OriginalLessonName: string
  Position: number
  index?: number
  disabled?: boolean
  finished?: boolean
}

export interface ITutorial {
  Author: string
  CourseName: string
  LearningTime: string
  Position: number
  Name: string
  Type: string
  OriginalLessonName: string
  LessonId: number
  Description: string
  Steps: ITutorialStep[]
}
export interface ITutorialContent {
  Texts: ITutorialText[]
  Buttons: ITutorialButton[]
  Images: ITutorialImage[]
}

export interface ITutorialText {
  Alignment: number
  Content: string
  Ranges: ITutorialTextRange[]
  Layout: {
    LeftPoint: number
    TopPoint: number
    Width: number
    Height: number
  }
}

export interface ITutorialTextRange {
  StartIndex: number
  Length: number
  Weight: number
}

export interface IEnableButtonRule {
  FtControlName: string
  SelectedOption: number
  Type: EnableButtonRuleType
  Value: string
}

export enum ButtonActionType {
  NEXT,
  BACK,
  NONE
}

export interface ITutorialButton {
  EnableButtonRule: IEnableButtonRule | null
  Action: string
  Content: string
  Alignment: number
  Ranges: number | null
  Layout: {
    LeftPoint: number
    TopPoint: number
    Width: number
    Height: number
  }
  ActiveButtonColor: string
  DefaultButtonColor: string
  ActiveTextColor: string
  DefaultTextColor: string
  ActionType: ButtonActionType
  WebLink: string | null
}

export interface ITutorialImage {
  ImagePath: string
  Layout: {
    LeftPoint: number
    TopPoint: number
    Width: number
    Height: number
  }
}

export interface IOrderProperties {
  OrderType: PendingOrderTypes | MarketOrderTypes
  Symbol: string
  Lot: number
  StopLoss: number
  TakeProfit: number
  Comment: string
  PriceValidationSettings: {
    ValidatedNumberSource: string
    CompareWithAskPrice: number
    CompareWithClosePrice: number
    MaxPointsDistanceFromClosePrice: number
    NextStepIfValid: string
    NextStepIfInvalid: string
  }
}

export interface IGraphicalTool {
  PropertyType: number
  Timeframes: number
  PriceStart: number
  DateStart: string
  PriceEnd: number
  DateEnd: string
  DateTime: string
  Price: number
  Text: string
}

export enum PropertiesType {
  NEXT_STEP,
  NEWS_TRIGGER,
  NUMBER_VALIDATION_ACTION,
  WINDOW_TAB_PROPERTIES,
  WAIT_MOUSE_CLICK,
  WAIT_CONTROL_VALUE_CHANGED,
  GRAPHIC_OBJECT_VERTICAL_LINE,
  GRAPHIC_OBJECT_HORIZONTAL_LINE,
  GRAPHIC_OBJECT_TREND_LINE,
  GRAPHIC_OBJECT_RAY,
  MARKET_ORDER,
  WAIT_DATE_TIME,
  WHAT_IS_IT, // look right field name in the FT5 edu project
  TEXT_LABELS,
  //new property here only

  LAST,

  UNKNOWN
}

export interface IFeedbackRequest {
  courseTitle: string
  lessonTitle: string
  message: string
}
