import { IAccountInfo, IMarketOrder } from '../../../../utils/interfaces/IOrder.interface'

export const firstMarketOrder: IMarketOrder = {
  ticket: 1,
  symbol: 'EURUSD',
  type: 'sell',
  lots: 1,
  openTime: '2011-03-08 11:12',
  openPrice: 1.45667,
  sl: 0,
  tp: 1.3205,
  marketPrice: 1.44,
  comment: 'Good one',
  swap: 0.2,
  commission: 0.3,
  points: 0,
  profit: 0,
  barIndex: 1,
  timeFrame: 5
}

export const secondMarketOrder: IMarketOrder = {
  ticket: 2,
  symbol: 'GBPUSD',
  type: 'buy',
  lots: 0.3,
  openTime: '2017-06-08 22:33',
  openPrice: 1.45667,
  sl: 1.4,
  tp: 0,
  marketPrice: 1.44,
  comment: 'Bad one',
  swap: 0,
  commission: 0,
  points: 0,
  profit: 0,
  barIndex: 14,
  timeFrame: 5
}

export const defaultMarketOrders: IMarketOrder[] = [firstMarketOrder, secondMarketOrder]

export const accountInfo: IAccountInfo = {
  // change to non-default values
  balance: 10000,
  equity: 10000,
  margin: 0,
  freeMargin: 10000,
  marginLevel: 0,
  totalProfit: 0,
  dollarsPerPipPerLot: 10,
  lotSize: 100000,
  leverage: 100
}
