export const demoMaH4 = [
  {
    t: 1580774400,
    v: 1.1068312500000002
  },
  {
    t: 1580788800,
    v: 1.10634625
  },
  {
    t: 1580803200,
    v: 1.1060075
  },
  {
    t: 1580817600,
    v: 1.1055512500000002
  },
  {
    t: 1580832000,
    v: 1.1052925
  },
  {
    t: 1580846400,
    v: 1.1051549999999999
  },
  {
    t: 1580860800,
    v: 1.10478875
  },
  {
    t: 1580875200,
    v: 1.10447
  },
  {
    t: 1580889600,
    v: 1.10402125
  },
  {
    t: 1580904000,
    v: 1.10342
  },
  {
    t: 1580918400,
    v: 1.102695
  },
  {
    t: 1580932800,
    v: 1.10222125
  },
  {
    t: 1580947200,
    v: 1.1016612499999998
  },
  {
    t: 1580961600,
    v: 1.10114
  },
  {
    t: 1580976000,
    v: 1.1007149999999999
  },
  {
    t: 1580990400,
    v: 1.0999637500000001
  },
  {
    t: 1581004800,
    v: 1.0994
  },
  {
    t: 1581019200,
    v: 1.0991312500000001
  },
  {
    t: 1581033600,
    v: 1.09897
  },
  {
    t: 1581048000,
    v: 1.09855
  },
  {
    t: 1581062400,
    v: 1.09798375
  },
  {
    t: 1581076800,
    v: 1.0974887500000001
  },
  {
    t: 1581091200,
    v: 1.09681375
  },
  {
    t: 1581105600,
    v: 1.0964025
  },
  {
    t: 1581278400,
    v: 1.0960562500000002
  },
  {
    t: 1581292800,
    v: 1.0957025000000002
  },
  {
    t: 1581307200,
    v: 1.09524625
  },
  {
    t: 1581321600,
    v: 1.09502625
  },
  {
    t: 1581336000,
    v: 1.0946800000000003
  },
  {
    t: 1581350400,
    v: 1.09406125
  },
  {
    t: 1581364800,
    v: 1.0936112500000001
  },
  {
    t: 1581379200,
    v: 1.0932324999999998
  },
  {
    t: 1581393600,
    v: 1.09279625
  },
  {
    t: 1581408000,
    v: 1.0923
  },
  {
    t: 1581422400,
    v: 1.0919175
  },
  {
    t: 1581436800,
    v: 1.0915725
  },
  {
    t: 1581451200,
    v: 1.0915075
  },
  {
    t: 1581465600,
    v: 1.0915237500000001
  },
  {
    t: 1581480000,
    v: 1.09158
  },
  {
    t: 1581494400,
    v: 1.0915599999999999
  },
  {
    t: 1581508800,
    v: 1.0913125000000001
  },
  {
    t: 1581523200,
    v: 1.09073375
  },
  {
    t: 1581537600,
    v: 1.09022875
  },
  {
    t: 1581552000,
    v: 1.0895849999999998
  },
  {
    t: 1581566400,
    v: 1.08908875
  },
  {
    t: 1581580800,
    v: 1.08837
  },
  {
    t: 1581595200,
    v: 1.087545
  },
  {
    t: 1581609600,
    v: 1.0866875
  },
  {
    t: 1581624000,
    v: 1.08602375
  },
  {
    t: 1581638400,
    v: 1.0855825000000001
  },
  {
    t: 1581652800,
    v: 1.0851499999999998
  },
  {
    t: 1581667200,
    v: 1.0847375
  },
  {
    t: 1581681600,
    v: 1.08431875
  },
  {
    t: 1581696000,
    v: 1.0840912500000002
  },
  {
    t: 1581710400,
    v: 1.0838575000000001
  },
  {
    t: 1581883200,
    v: 1.08383
  },
  {
    t: 1581897600,
    v: 1.0837937500000001
  },
  {
    t: 1581912000,
    v: 1.0839137499999998
  },
  {
    t: 1581926400,
    v: 1.08389
  },
  {
    t: 1581940800,
    v: 1.0837975
  },
  {
    t: 1581955200,
    v: 1.0836475
  },
  {
    t: 1581969600,
    v: 1.0836124999999999
  },
  {
    t: 1581984000,
    v: 1.08364375
  },
  {
    t: 1581998400,
    v: 1.0835424999999999
  },
  {
    t: 1582012800,
    v: 1.08336875
  },
  {
    t: 1582027200,
    v: 1.0831449999999998
  },
  {
    t: 1582041600,
    v: 1.08258
  },
  {
    t: 1582056000,
    v: 1.08214625
  },
  {
    t: 1582070400,
    v: 1.08171
  },
  {
    t: 1582084800,
    v: 1.081275
  },
  {
    t: 1582099200,
    v: 1.08076375
  },
  {
    t: 1582113600,
    v: 1.08024125
  },
  {
    t: 1582128000,
    v: 1.07988875
  },
  {
    t: 1582142400,
    v: 1.0797249999999998
  },
  {
    t: 1582156800,
    v: 1.0797625
  },
  {
    t: 1582171200,
    v: 1.0797075
  },
  {
    t: 1582185600,
    v: 1.0796762500000001
  },
  {
    t: 1582200000,
    v: 1.0796825
  },
  {
    t: 1582214400,
    v: 1.07965625
  },
  {
    t: 1582228800,
    v: 1.0796100000000002
  },
  {
    t: 1582243200,
    v: 1.0795337500000002
  },
  {
    t: 1582257600,
    v: 1.0793650000000001
  },
  {
    t: 1582272000,
    v: 1.0794800000000002
  },
  {
    t: 1582286400,
    v: 1.0802562500000001
  },
  {
    t: 1582300800,
    v: 1.081025
  },
  {
    t: 1582315200,
    v: 1.08156625
  },
  {
    t: 1582488000,
    v: 1.08217875
  },
  {
    t: 1582502400,
    v: 1.08261125
  },
  {
    t: 1582516800,
    v: 1.0829474999999997
  },
  {
    t: 1582531200,
    v: 1.083255
  },
  {
    t: 1582545600,
    v: 1.0838762499999999
  },
  {
    t: 1582560000,
    v: 1.0837575
  },
  {
    t: 1582574400,
    v: 1.0836412500000001
  },
  {
    t: 1582588800,
    v: 1.08385625
  },
  {
    t: 1582603200,
    v: 1.0840425000000002
  },
  {
    t: 1582617600,
    v: 1.08422375
  },
  {
    t: 1582632000,
    v: 1.08481875
  },
  {
    t: 1582646400,
    v: 1.08557875
  },
  {
    t: 1582660800,
    v: 1.0858775
  },
  {
    t: 1582675200,
    v: 1.08617
  },
  {
    t: 1582689600,
    v: 1.0864512499999999
  },
  {
    t: 1582704000,
    v: 1.08672125
  },
  {
    t: 1582718400,
    v: 1.0868875
  },
  {
    t: 1582732800,
    v: 1.08767375
  },
  {
    t: 1582747200,
    v: 1.087945
  },
  {
    t: 1582761600,
    v: 1.0882425
  },
  {
    t: 1582776000,
    v: 1.0887625
  },
  {
    t: 1582790400,
    v: 1.08974625
  },
  {
    t: 1582804800,
    v: 1.0911625
  },
  {
    t: 1582819200,
    v: 1.0924475
  },
  {
    t: 1582833600,
    v: 1.09406875
  },
  {
    t: 1582848000,
    v: 1.0951600000000001
  },
  {
    t: 1582862400,
    v: 1.096525
  },
  {
    t: 1582876800,
    v: 1.09762625
  },
  {
    t: 1582891200,
    v: 1.09841375
  },
  {
    t: 1582905600,
    v: 1.0994625
  },
  {
    t: 1582920000,
    v: 1.0999725
  }
]
