import moment from 'moment'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  addTempStopLossLine,
  addTempTakeProfitLine,
  removeTempSlTpLines
} from '../../../../../../../redux/graphics/GraphicsSlice'
import { setCurrentChart } from '../../../../../../../redux/graphs/actions'
import { toggleNotEnoughFreeMarginModal } from '../../../../../../../redux/modals/actions'
import {
  createMarketOrder,
  editMarketOrder,
  enablePipetteMode,
  incrementGlobalOrderTicket,
  saveMarketOrderSetupOnPipette,
  setPipetteType,
  setPipettevalues
} from '../../../../../../../redux/orders/OrdersSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/store_hooks'
import { InstantSetupButtonTypes, LineStyle, MarketOrderTypes, OrderInputTypes } from '../../../../../../../utils/enums'
import { COMMON_DATE_FORMAT } from '../../../../../../../utils/helpers/constants'
import { IChart } from '../../../../../../../utils/interfaces/IChart.interface'
import { ICurrentBar } from '../../../../../../../utils/interfaces/ICurrentBar'
import { LineType } from '../../../../../../../utils/interfaces/ILine'
import { IAccountInfo, IMarketOrder } from '../../../../../../../utils/interfaces/IOrder.interface'
import {
  MarketOrderModalId,
  MarketOrderModalLocators,
  MarketOrderModalTexts
} from '../../../../../../../utils/locators/locators'
import FTORightIcon from '../../../../../../icons/RightIcon'
import { MOWErrorItem } from '../../../../../../modals/FTOModals/FTOModal'
import { DEFAULT_ORDER, FTOModalField, useErrorList } from '../../../../../../modals/FTOModals/FTOModalComponents'
import { calculateMargin, getCurrencyMultiplier, getDistinctSymbols } from '../../../../../../webTester/webTesterUtils'
import { calculateMarketOrderRisk } from '../../../../../topMenuUtils/orderValid'
import { handleMarketOrderInstantSetupButton } from '../../../../../topMenuUtils/topMenuUtils'
import { getOrderType } from '../../../../coursesTab/coursesUtils'
import { defaultMarketOrderSavedState, MarketOrderContext } from '../MarketOrderWindow'
// import css from '../../OrderModals.module.scss'
import css from '../MarketOrderWindow.module.scss'

const BidAsk: FC = () => {
  return (
    <div className={css.bidAskRegion}>
      <div className={css.bid}>
        <span className={css.bidTitle}>
          {/* TODO add formatted text */}
          bid
        </span>
        <span className={css.bidValue}>-</span>
      </div>
      <span className={css.ask}>
        <span className={css.askTitle}>
          {/* TODO add formatted text */}
          ask
        </span>
        <span className={css.askValue}>-</span>
      </span>
    </div>
  )
}

export const SellBuySelect: FC<{
  disabled?: boolean
  setValue: (value: MarketOrderTypes) => void
  value: MarketOrderTypes
}> = ({ setValue, value, disabled }) => {
  return (
    <div
      id={MarketOrderModalLocators.BuySellRadios}
      className={`${css.sellBuySelect} ${disabled ? css.disabled : ''} ${value == 'sell' ? css.sell : css.buy} `}
    >
      <span
        id={MarketOrderModalLocators.SellRadio}
        className={value == 'sell' ? css.selected : ''}
        onClick={() => {
          if (!disabled) setValue(MarketOrderTypes.Sell)
        }}
      >
        <FormattedMessage id={MarketOrderModalId.ImSelling} defaultMessage={MarketOrderModalTexts.ImSelling} />
      </span>
      <span
        id={MarketOrderModalLocators.BuyRadio}
        className={value == 'buy' ? css.selected : ''}
        onClick={() => {
          if (!disabled) setValue(MarketOrderTypes.Buy)
        }}
      >
        <FormattedMessage id={MarketOrderModalId.ImBuying} defaultMessage={MarketOrderModalTexts.ImBuying} />
      </span>
    </div>
  )
}

interface CommonTabProps {}
const lotOptions = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const MarketOrderWindowCommonTab: FC<CommonTabProps> = () => {
  // Error handler logic
  type MOWError = undefined | 'no stop loss for risk' | 'stop loss under price' | 'take profit above price'
  const [errors, addError, removeError] = useErrorList<MOWError>()
  const currentChart = useAppSelector((state) => state.graphs.currentChart as IChart)

  // Context properties
  const currentMOWContext = useContext(MarketOrderContext)
  if (!currentMOWContext) throw new Error('No Market Order Window context were provided for common tab')

  // Joyride
  const joyride = useAppSelector((state) => state.joyride)
  const updateJoyride = (withId: string) => {
    const { guideIsOn } = joyride
    if (!guideIsOn) return
    currentMOWContext.onClick({ target: { id: withId } })
  }

  const marketOrderSetup = useAppSelector((state) => state.orders.marketOrderSetup)
  const currentBars = currentMOWContext.currentBars as ICurrentBar
  const accountInfo = currentMOWContext.accountInfo as IAccountInfo
  const toggleModal = currentMOWContext.toggleModal
  const savedState = currentMOWContext.savedState
  const visibleData = currentMOWContext.visibleData

  // #region edit
  const isEdit = useAppSelector((state) => state.orders.marketEditMode)
  const editOrder = useAppSelector((state) => state.orders.marketEditedOrder)
  // #endregion

  const appSymbol = useAppSelector((state) => state.graphs.currentChart.symbol)
  const [symbolName, setsymbolName] = useState(appSymbol ? appSymbol : 'EURUSD')
  const currentChartTimeFrame = useMemo(() => symbolName + currentChart.timeFrame, [symbolName, currentChart])
  const dataLength = visibleData[currentChartTimeFrame]?.length - 1 || 0
  const closePrice = currentBars[currentChartTimeFrame]?.close || 0

  useEffect(() => {
    dispatch(setCurrentChart({ symbol: symbolName }))
  }, [symbolName])

  const appLot = useAppSelector((state) => state.orders.marketOrderSetup?.lots)
  const [lastLot, setlastLot] = useState(appLot ? appLot : 0.1)
  const [lot, setlot] = useState(savedState.lot ?? 0.1)

  useEffect(() => {
    if (!isRisk) setlastLot(lot)
  }, [lot])

  const stopLossTakeProfitMarginOptions = Array.from({ length: 20 }, (_, i) => Number(((i + 1) * 5).toFixed(0)))
  const [buySell, setbuySell] = useState<MarketOrderTypes>(getOrderType(joyride, savedState))

  const [isRisk, setisRisk] = useState(savedState.isRisk ?? false)
  const [riskPercent, setriskPercent] = useState(savedState.riskPercent ?? 0.2)

  const charts = useAppSelector((state) => state.graphs.charts as IChart[])
  const pipetteValues = useAppSelector((state) => state.orders.pipetteValues)
  const orders = useAppSelector((state) => {
    return state.orders
  })
  const [stopLoss, setstopLoss] = useState(savedState.stopLoss ?? 0)
  const [stopLossMargin, setstopLossMargin] = useState(savedState.stopLossMargin ?? 5)
  const stopLossArrowValue = useMemo(() => {
    const buttonSetup = handleMarketOrderInstantSetupButton(
      InstantSetupButtonTypes.stopLoss,
      buySell,
      stopLossMargin,
      closePrice,
      currentChart.digitsAfterPoint
    )

    return buttonSetup || 0
  }, [stopLossMargin, pipetteValues, currentChart, closePrice, buySell])

  const [takeProfit, settakeProfit] = useState(savedState.takeProfit ?? 0)
  const [takeProfitMargin, settakeProfitMargin] = useState(savedState.takeProfitMargin ?? 5)
  const takeProfitArrowValue = useMemo(() => {
    const buttonSetup = handleMarketOrderInstantSetupButton(
      InstantSetupButtonTypes.takeProfit,
      buySell,
      takeProfitMargin,
      closePrice,
      currentChart.digitsAfterPoint
    )
    return buttonSetup || 0
  }, [takeProfitMargin, pipetteValues, currentChart, closePrice, buySell])

  // validation conditions for SL and TP
  const takeProfitValidCondition = useMemo(
    () => (buySell == MarketOrderTypes.Buy ? takeProfit > closePrice : takeProfit < closePrice),
    [buySell, takeProfit, closePrice]
  )
  const stopLossValidCondition = useMemo(
    () => (buySell == MarketOrderTypes.Buy ? stopLoss < closePrice : stopLoss > closePrice),
    [buySell, stopLoss, closePrice]
  )

  // risk calculation
  useEffect(() => {
    if (isRisk) {
      // error check
      if (!stopLoss) addError('no stop loss for risk')
      else removeError('no stop loss for risk')
      // calculations
      const calculatedLots = calculateMarketOrderRisk(
        accountInfo.balance,
        riskPercent,
        stopLoss,
        closePrice,
        currentChart.digitsAfterPoint
      )
      setlastLot(lot)
      setlot(calculatedLots)
    } else {
      removeError('no stop loss for risk')
      setlot(lastLot)
    }
  }, [riskPercent, isRisk, accountInfo, riskPercent, closePrice, currentChart, stopLoss])

  // sell buy updater
  useEffect(() => {
    if (marketOrderSetup)
      dispatch(
        saveMarketOrderSetupOnPipette({
          ...marketOrderSetup,
          type: buySell
        })
      )
    updateJoyride(
      buySell == MarketOrderTypes.Buy ? MarketOrderModalLocators.BuyRadio : MarketOrderModalLocators.SellRadio
    )
  }, [buySell])
  // tp & sl updater
  useEffect(() => {
    if (marketOrderSetup)
      dispatch(
        saveMarketOrderSetupOnPipette({
          ...marketOrderSetup,
          tp: takeProfit,
          sl: stopLoss
        })
      )

    dispatch(
      addTempStopLossLine({
        yPos: stopLoss,
        color: '#000',
        strokeType: LineStyle.Solid,
        type: LineType.Horizontal
      })
    )

    dispatch(
      addTempTakeProfitLine({
        yPos: takeProfit,
        color: '#000',
        strokeType: LineStyle.Solid,
        type: LineType.Horizontal
      })
    )
  }, [takeProfit, stopLoss])

  // edit mode effect
  useEffect(() => {
    if (isEdit) {
      setstopLoss(editOrder.sl)
      settakeProfit(editOrder.tp)
      setlot(editOrder.lots)
      setlastLot(editOrder.lots)
      setbuySell(editOrder.type as MarketOrderTypes)
      setsymbolName(editOrder.symbol)
      // currentMOWContext.setsavedState({
      //   atPrice: editOrder.execPrice,
      //   lot: editOrder.lots,
      //   type: editOrder.type as PendingOrderTypes,
      //   symbol: editOrder.symbol
      // })
    }
  }, [isEdit, editOrder])
  // tp & sl errors validator
  useEffect(() => {
    if (!stopLossValidCondition && stopLoss != 0) {
      addError('stop loss under price')
    } else {
      removeError('stop loss under price')
    }
    if (!takeProfitValidCondition && takeProfit != 0) {
      addError('take profit above price')
    } else {
      removeError('take profit above price')
    }
  }, [stopLoss, takeProfit, stopLossValidCondition, takeProfitValidCondition])

  useEffect(() => {
    if (marketOrderSetup)
      dispatch(
        saveMarketOrderSetupOnPipette({
          ...marketOrderSetup,
          lots: lot
        })
      )
  }, [lot])

  // saving values of modal to context
  useEffect(() => {
    if (!isEdit)
      currentMOWContext.setsavedState({
        ...currentMOWContext.savedState,
        riskPercent,
        isRisk,
        stopLoss,
        takeProfit,
        stopLossMargin,
        takeProfitMargin,
        lot,
        buySell
      })
  }, [riskPercent, isRisk, stopLoss, takeProfit, stopLossMargin, takeProfitMargin, lot, buySell])

  const [comment, setcomment] = useState('')

  const dispatch = useAppDispatch()

  const handlePipetteClick = (e, type: OrderInputTypes) => {
    dispatch(setPipetteType(type))
    dispatch(enablePipetteMode())
    switch (type) {
      case OrderInputTypes.StopLoss:
        setstopLoss(0)
        break
      case OrderInputTypes.TakeProfit:
        settakeProfit(0)
        break
    }
    if (marketOrderSetup) dispatch(saveMarketOrderSetupOnPipette(marketOrderSetup))
    updateJoyride(e.target.id)
  }

  const handleRemoveClick = (type: OrderInputTypes) => {
    switch (type) {
      case OrderInputTypes.StopLoss:
        return setstopLoss(0)
      case OrderInputTypes.TakeProfit:
        return settakeProfit(0)
    }
  }

  const handleTakeProfitPushButton = () => {
    settakeProfit(takeProfitArrowValue)
  }

  const handleStopLossPushButton = () => {
    setstopLoss(stopLossArrowValue)
  }

  useEffect(() => {
    if (pipetteValues.takeProfit > 0 && takeProfit == 0) settakeProfit(pipetteValues.takeProfit)
    if (pipetteValues.stopLoss > 0 && stopLoss == 0) setstopLoss(pipetteValues.stopLoss)
  }, [pipetteValues])

  const formattedOrder = {
    ...DEFAULT_ORDER,
    comment: comment,
    lots: lot,
    marketPrice: closePrice,
    openPrice: closePrice,
    openTime: currentBars[currentChartTimeFrame]
      ? moment.utc(currentBars[currentChartTimeFrame]?.date).format(COMMON_DATE_FORMAT)
      : 'Unknown date',
    sl: stopLoss,
    symbol: symbolName,
    tp: takeProfit,
    type: buySell,
    barIndex: dataLength,
    timeFrame: currentChart.timeFrame
  } as IMarketOrder

  const handleBuySellClick = () => {
    const newMarketOrder: IMarketOrder = formattedOrder
    newMarketOrder.ticket = orders.globalOrderTicket + 1

    const currencyMultiplier: number = getCurrencyMultiplier(symbolName, closePrice)

    const orderMargin = calculateMargin(
      newMarketOrder.symbol,
      newMarketOrder.lots,
      accountInfo.lotSize,
      accountInfo.leverage,
      currencyMultiplier
    )
    if (accountInfo.freeMargin - orderMargin <= 0) {
      dispatch(toggleNotEnoughFreeMarginModal())
      updateJoyride(
        buySell == MarketOrderTypes.Buy ? MarketOrderModalLocators.BuyButton : MarketOrderModalLocators.SellButton
      )
      return
    }
    updateJoyride(
      buySell == MarketOrderTypes.Buy ? MarketOrderModalLocators.BuyButton : MarketOrderModalLocators.SellButton
    )

    currentMOWContext.setsavedState({ ...defaultMarketOrderSavedState, lot: savedState.lot })
    dispatch(createMarketOrder({ order: newMarketOrder, timeframe: currentChart.timeFrame }))
    dispatch(incrementGlobalOrderTicket())
    toggleModal()
    dispatch(removeTempSlTpLines())
    if (Object.keys(pipetteValues).some((x) => pipetteValues[x] != 0))
      dispatch(setPipettevalues({ execPrice: 0, stopLoss: 0, takeProfit: 0 }))
  }

  const handleEditOrder = () => {
    const newMarketOrder: IMarketOrder = formattedOrder
    newMarketOrder.ticket = editOrder.ticket

    const currencyMultiplier: number = getCurrencyMultiplier(symbolName, closePrice)

    const orderMargin = calculateMargin(
      newMarketOrder.symbol,
      newMarketOrder.lots,
      accountInfo.lotSize,
      accountInfo.leverage,
      currencyMultiplier
    )
    if (accountInfo.freeMargin - orderMargin <= 0) {
      dispatch(toggleNotEnoughFreeMarginModal())
      updateJoyride(
        buySell == MarketOrderTypes.Buy ? MarketOrderModalLocators.BuyButton : MarketOrderModalLocators.SellButton
      )
      return
    }
    updateJoyride(
      buySell == MarketOrderTypes.Buy ? MarketOrderModalLocators.BuyButton : MarketOrderModalLocators.SellButton
    )

    currentMOWContext.setsavedState(defaultMarketOrderSavedState)
    dispatch(editMarketOrder({ order: newMarketOrder }))
    toggleModal()
    dispatch(removeTempSlTpLines())
    if (Object.keys(pipetteValues).some((x) => pipetteValues[x] != 0))
      dispatch(setPipettevalues({ execPrice: 0, stopLoss: 0, takeProfit: 0 }))
  }

  const ispipetteMode = useAppSelector((state) => state.orders.pipetteModeIsActive)
  // pipette closer
  useEffect(() => {
    if (ispipetteMode == true) toggleModal()
  }, [ispipetteMode])

  return (
    <>
      <div id={MarketOrderModalLocators.Section1} className={css.inherited}>
        <FTOModalField
          disabled={isEdit}
          ids={{ main: MarketOrderModalLocators.SymbolSelect }}
          title={
            <FormattedMessage id={MarketOrderModalId.SymbolName} defaultMessage={MarketOrderModalTexts.SymbolName} />
          }
          value={symbolName}
          setValue={(val: string) => setsymbolName(val)}
          options={getDistinctSymbols(charts)}
        />
        <SellBuySelect disabled={isEdit} value={buySell} setValue={(val) => setbuySell(val)} />
        <FTOModalField
          disabled={isEdit}
          ids={{ main: MarketOrderModalLocators.LotSelect }}
          title={<FormattedMessage id={MarketOrderModalId.Lot} defaultMessage={MarketOrderModalTexts.Lot} />}
          value={lot}
          setValue={(val: number) => setlot(val)}
          options={isRisk ? undefined : lotOptions}
          editable={!isRisk}
        />
        {/* risk block */}
        <div className={css.riskBlock}>
          <input
            disabled={isEdit}
            type='checkbox'
            id='risk'
            name='risk'
            checked={isRisk}
            onChange={(ev) => setisRisk(!isRisk)}
          />
          <label className={`${css.checkbox} ${isRisk && !stopLoss ? css.red : ''}`} htmlFor='risk'>
            <FormattedMessage
              id={
                isRisk && !stopLoss
                  ? MarketOrderModalId.CalculateWithRiskPercentageRequired
                  : MarketOrderModalId.CalculateWithRiskPercentage
              }
              defaultMessage={MarketOrderModalTexts.CalculateWithRiskPercentage}
            />
          </label>
          <FTOModalField
            ids={{
              downButton: MarketOrderModalLocators.RiskIncrementButton,
              upButton: MarketOrderModalLocators.RiskDecrementButton
            }}
            disabled={!isRisk}
            value={riskPercent}
            setValue={(value: number) => setriskPercent(value)}
            step={0.01}
            roundDigit={2}
            min={0}
            max={100}
          />
        </div>
      </div>
      {/* Section id is temprorary decision */}
      <div className={css.separator} />

      {/* stop loss block */}
      <div className={css.stopLossBlock}>
        <FTOModalField
          ids={{
            main: MarketOrderModalLocators.StopLossField,
            upButton: MarketOrderModalLocators.StopLossIncrementButton,
            downButton: MarketOrderModalLocators.StopLossDecrementButton
          }}
          title={<FormattedMessage id={MarketOrderModalId.StopLoss} defaultMessage={MarketOrderModalTexts.StopLoss} />}
          valid={stopLossValidCondition || stopLoss == 0}
          roundDigit={currentChart.digitsAfterPoint}
          min={0}
          max={1e6}
          value={stopLoss}
          setValue={(value: number) => setstopLoss(value)}
          step={1 / 1e1 ** currentChart.digitsAfterPoint}
        />
        <button
          id={MarketOrderModalLocators.StopLossPipette}
          onClick={(e) => handlePipetteClick(e, OrderInputTypes.StopLoss)}
          className={css.pipettButton}
        >
          <FTORightIcon icon='pipette' />
        </button>
        <button
          id={MarketOrderModalLocators.StopLossRemovalButton}
          onClick={() => handleRemoveClick(OrderInputTypes.StopLoss)}
          className={css.removeButton}
        >
          <FTORightIcon icon='cross' />
        </button>
      </div>
      <div className={css.stopLossSelectorBlock}>
        <button
          id={MarketOrderModalLocators.InstantStopLossSetupButton}
          onClick={handleStopLossPushButton}
          disabled={stopLoss == stopLossArrowValue}
          className={css.pushButton}
        >
          {stopLossArrowValue.toFixed(currentChart.digitsAfterPoint)}
        </button>
        <FTOModalField
          ids={MarketOrderModalLocators.StopLossDropdown}
          fixedStepValue
          className={css.field}
          value={stopLossMargin}
          options={stopLossTakeProfitMarginOptions}
          min={5}
          max={100}
          step={5}
          setValue={(value: number) => setstopLossMargin(value)}
        />
      </div>

      {/* take profit block */}
      <div className={css.takeProfitBlock}>
        <FTOModalField
          ids={{
            main: MarketOrderModalLocators.TakeProfitField,
            upButton: MarketOrderModalLocators.TakeProfitIncrementButton,
            downButton: MarketOrderModalLocators.TakeProfitDecrementButton
          }}
          title={
            <FormattedMessage id={MarketOrderModalId.TakeProfit} defaultMessage={MarketOrderModalTexts.TakeProfit} />
          }
          valid={takeProfitValidCondition || takeProfit == 0}
          roundDigit={currentChart.digitsAfterPoint}
          min={0}
          max={1e6}
          value={takeProfit}
          setValue={(value: number) => settakeProfit(value)}
          step={1 / 1e1 ** currentChart.digitsAfterPoint}
        />
        <button
          id={MarketOrderModalLocators.TakeProfitPipette}
          onClick={(e) => handlePipetteClick(e, OrderInputTypes.TakeProfit)}
          className={css.pipettButton}
        >
          <FTORightIcon icon='pipette' />
        </button>
        <button
          id={MarketOrderModalLocators.TakeProfitRemovalButton}
          onClick={() => handleRemoveClick(OrderInputTypes.TakeProfit)}
          className={css.removeButton}
        >
          <FTORightIcon icon='cross' />
        </button>
      </div>
      <div className={css.takeProfitSelectorBlock}>
        <button
          id={MarketOrderModalLocators.InstantTakeProfitSetupButton}
          onClick={handleTakeProfitPushButton}
          disabled={takeProfit == takeProfitArrowValue}
          className={css.pushButton}
        >
          {takeProfitArrowValue.toFixed(currentChart.digitsAfterPoint)}
        </button>
        <FTOModalField
          ids={MarketOrderModalLocators.TakeProfitDropdown}
          fixedStepValue
          className={css.field}
          value={takeProfitMargin}
          options={stopLossTakeProfitMarginOptions}
          min={5}
          max={100}
          step={5}
          setValue={(value: number) => settakeProfitMargin(value)}
        />
      </div>

      {/* comment */}
      <FTOModalField
        ids={MarketOrderModalLocators.CommentField}
        className={css.fullFilled}
        value={comment}
        setValue={(val: string) => setcomment(val)}
        title={<FormattedMessage id={MarketOrderModalLocators.CommentField} defaultMessage={'Comment'} />}
      />

      <div className={css.errorGroup}>
        <MOWErrorItem title='Warning' shown={errors.includes('no stop loss for risk')}>
          You have to set stop loss before continue
        </MOWErrorItem>
        <MOWErrorItem title='Warning' shown={errors.includes('stop loss under price')}>
          The value of 'Stop Loss' field for {buySell} orders must be{' '}
          {buySell === MarketOrderTypes.Sell ? 'ABOVE' : 'BELOW'} market price
        </MOWErrorItem>
        <MOWErrorItem title='Warning' shown={errors.includes('take profit above price')}>
          The value of 'Take Profit' field for {buySell} orders must be{' '}
          {buySell === MarketOrderTypes.Buy ? 'ABOVE' : 'BELOW'} market price
        </MOWErrorItem>
      </div>
      <div className={css.buttonGroup}>
        {isEdit && (
          <button
            className={css.editButton}
            id={MarketOrderModalLocators.EditOrderButton}
            disabled={errors.length > 0}
            onClick={handleEditOrder}
          >
            edit market order
          </button>
        )}
        {!isEdit && (
          <>
            <button
              id={MarketOrderModalLocators.SellButton}
              className={css.sellButton}
              disabled={errors.length > 0 || buySell != MarketOrderTypes.Sell}
              onClick={handleBuySellClick}
            >
              sell
            </button>
            <button
              id={MarketOrderModalLocators.BuyButton}
              className={css.buyButton}
              disabled={errors.length > 0 || buySell != MarketOrderTypes.Buy}
              onClick={handleBuySellClick}
            >
              buy
            </button>
          </>
        )}
        <button className={css.helpButton}>help</button>
      </div>
    </>
  )
}

export default MarketOrderWindowCommonTab
