import { ID } from '../../../../utils/locators/ids'
import { HomeID } from '../../../../utils/locators/locators'

export const scrollableMenuItemsIds = [
  HomeID.TestingSpeedSliderID,
  ID.AccountHistoryTableFooter,
  ID.BottomMenuContent,
  ID.AccountHistoryTable,
  ID.AccountHistoryMobileContent,
  ID.MobileCoursesGroup,
  ID.MobileH4
]
