import { ITool } from '../../../../../redux/graphics/types'
import { LineStyle } from '../../../../../utils/enums'
import { IHorizontalLine, IVerticalLine, LineType } from '../../../../../utils/interfaces/ILine'

export const dummyHorizontalLine: IHorizontalLine = {
  color: '',
  strokeType: LineStyle.Solid,
  type: LineType.Horizontal,
  yPos: 0
}

export const dummyVerticalLine: IVerticalLine = {
  color: '',
  strokeType: LineStyle.Solid,
  type: LineType.Vertical,
  xPos: -1
}

export const activeToolsAllOff: ITool = {
  verticalLine: false,
  horizontalLine: false,
  trendLine: false,
  polyline: false,
  ray: false,
  rectangle: false,
  ellipse: false,
  triangle: false,
  fibonacciRetracement: false,
  fibonacciTimeZones: false,
  fibonacciFan: false,
  fibonacciArc: false,
  fibonacciExtension: false,
  fibonacciChannel: false,
  linearRegressionChannel: false,
  standardDeviationChannel: false,
  equidistantChannel: false,
  andrewsPitchfork: false,
  interactiveText: false,
  gannFan: false,
  interactiveYCoordinate: false,
  thumbUp: false,
  thumbDown: false,
  arrowUp: false,
  arrowDown: false,
  stopIcon: false,
  checkIcon: false,
  rightPriceLabel: false,
  leftPriceLabel: false,
  priceLabel: false,
  RewardToRiskBuy: false,
  RewardToRiskSell: false
}

export const activeToolsWithOneOn: ITool = {
  verticalLine: true,
  horizontalLine: false,
  trendLine: false,
  polyline: false,
  ray: false,
  rectangle: false,
  ellipse: false,
  triangle: false,
  fibonacciRetracement: false,
  fibonacciTimeZones: false,
  fibonacciFan: false,
  fibonacciArc: false,
  fibonacciExtension: false,
  fibonacciChannel: false,
  linearRegressionChannel: false,
  standardDeviationChannel: false,
  equidistantChannel: false,
  andrewsPitchfork: false,
  interactiveText: false,
  gannFan: false,
  interactiveYCoordinate: false,
  thumbUp: false,
  thumbDown: false,
  arrowUp: false,
  arrowDown: false,
  stopIcon: false,
  checkIcon: false,
  rightPriceLabel: false,
  leftPriceLabel: false,
  priceLabel: false,
  RewardToRiskBuy: false,
  RewardToRiskSell: false
}
