import { IMarketOrder, IPendingOrder } from '../../utils/interfaces/IOrder.interface'
import { IJournalRecord } from '../../utils/interfaces/IJournalRecord.interface'

export interface IJournalState {
  journalRecords: IJournalRecord[]
}

export const ADD_OPEN_MARKET_ORDER_TO_JOURNAL = 'ADD_OPEN_MARKET_ORDER_TO_JOURNAL'
export const ADD_OPEN_PENDING_ORDER_TO_JOURNAL = 'ADD_OPEN_PENDING_ORDER_TO_JOURNAL'
export const ADD_CLOSED_MARKET_ORDER_TO_JOURNAL = 'ADD_CLOSED_MARKET_ORDER_TO_JOURNAL'
export const ADD_CLOSED_PENDING_ORDER_TO_JOURNAL = 'ADD_CLOSED_PENDING_ORDER_TO_JOURNAL'
export const ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL = 'ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL'
export const ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL = 'ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL'
export const ADD_START_TEST_TO_JOURNAL = 'ADD_START_TEST_TO_JOURNAL'
export const ADD_PAUSE_TEST_TO_JOURNAL = 'ADD_PAUSE_TEST_TO_JOURNAL'

interface AddOpenMarketOrderToJournalAction {
  type: typeof ADD_OPEN_MARKET_ORDER_TO_JOURNAL
  payload: IMarketOrder
}

interface AddOpenPendingOrderToJournalAction {
  type: typeof ADD_OPEN_PENDING_ORDER_TO_JOURNAL
  payload: IPendingOrder
}

interface AddClosedMarketOrderToJournalAction {
  type: typeof ADD_CLOSED_MARKET_ORDER_TO_JOURNAL
  payload: IMarketOrder
}

interface AddClosedPendingOrderToJournalAction {
  type: typeof ADD_CLOSED_PENDING_ORDER_TO_JOURNAL
  payload: IPendingOrder
}

interface AddModifiedMarketOrderToJournalAction {
  type: typeof ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL
  payload: IMarketOrder
}

interface AddModifiedPendingOrderToJournalAction {
  type: typeof ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL
  payload: IPendingOrder
}

interface AddStartTestToJournalAction {
  type: typeof ADD_START_TEST_TO_JOURNAL
  payload: string
}

interface AddPauseTestToJournalAction {
  type: typeof ADD_PAUSE_TEST_TO_JOURNAL
  payload: string
}

export type JournalActionTypes =
  | AddOpenMarketOrderToJournalAction
  | AddOpenPendingOrderToJournalAction
  | AddClosedMarketOrderToJournalAction
  | AddClosedPendingOrderToJournalAction
  | AddModifiedMarketOrderToJournalAction
  | AddModifiedPendingOrderToJournalAction
  | AddStartTestToJournalAction
  | AddPauseTestToJournalAction
