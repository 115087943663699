import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { isNotDefined } from '../../libs/react-stockcharts/lib/utils'
import { FromFtoType, FtoIntroLinkType, WebTesterLocale } from '../../utils/enums'
import { UserType } from '../TelemetryService/telemetryTypes'
import UrlsManagementService from '../UrlsManagementService/UrlsManagementService'

export class UserIdentityService {
  readonly _id: string | null = ''
  private readonly keyUserId: string = 'FTO_user_identity'
  private readonly FTO: string = 'fto_intro'
  private readonly FT_CLICK: string = 'fto_click'
  private readonly FT_ID: string = 'ftid'
  private readonly keyUserIsSubscribed = 'user_is_subscribed'
  private readonly keyDemoModalIsShown: string = 'Demo_modal'
  private _userType: UserType = UserType.DEMO

  constructor() {
    this._id = Cookies.get(this.FT_ID)

    if (isNotDefined(this._id)) {
      this._id = localStorage.getItem(this.keyUserId)
    }

    if (isNotDefined(this._id)) {
      this._id = uuidv4()
      localStorage.setItem(this.keyUserId, this._id!)
    }
  }

  get id(): string | null {
    return this._id
  }
  get userType(): UserType {
    return this._userType
  }

  public isDemoModalShown(): boolean {
    return Cookies.get(this.keyDemoModalIsShown)
  }

  public markDemoIsShown() {
    Cookies.set(this.keyDemoModalIsShown, 'shown')
  }

  public addCookieFTO(type: FtoIntroLinkType, fromFtoType: FromFtoType, language: WebTesterLocale) {
    // TODO: deprecated. Remove after the end of basic release
    // Cookies.set(this.FTO, `${this._id}&link=${type}&from_fto=${fromFtoType}`, {
    //   expires: 365,
    //   path: '/',
    //   domain: UrlsManagementService.getFtUrlOrigin(language)
    // })
  }

  public addOkToCookieFtoClick(language: WebTesterLocale) {
    const clickCookie: string | null = Cookies.get(this.FT_CLICK)

    if (!clickCookie) return

    if (!clickCookie!.includes('OK')) {
      Cookies.set(this.FT_CLICK, `${clickCookie}&entered_fto=OK`, {
        expires: 365,
        path: '/',
        domain: UrlsManagementService.getFtUrlOrigin(language)
      })
    }
  }

  public isUserSubscribed() {
    return localStorage.getItem(this.keyUserIsSubscribed)
  }

  public markUserIsSubscribed() {
    localStorage.setItem(this.keyUserIsSubscribed, 'true')
  }
}
