import shortid from 'shortid'
import { IChart, IChartTimeFrame } from '../interfaces/IChart.interface'
import { ICurrentBar } from '../interfaces/ICurrentBar'
import { IChartTools } from '../interfaces/IGraphics'
import { ILastBarIndexes, IServerBar } from '../interfaces/IHistoricalData.interface'
import { IIndicator } from '../interfaces/IIndicator.interface'
import { INews } from '../interfaces/INews.interface'
import { IAccountInfo } from '../interfaces/IOrder.interface'
import { IProjectPascalCase } from '../interfaces/IProject.interface'

export const basicCourseDemoProject: IProjectPascalCase = {
  Name: 'Basic Courses',
  TimeZone: 0,
  DaylightSavingTime: 0,
  TicksGenerated: false,
  UsedTickHistory: false,
  UsedFloatingSpread: false,
  ForwardTestingOnly: false,
  FromDate: 1522540800, // 04/01/2018 @ 12:00am (UTC)
  ToDate: 1525132799, // 04/30/2018 @ 12:00am (UTC)
  StartDate: 1522540800, // 04/01/2018 @ 12:00am (UTC)
  CurrentDate: 1525132799, // 04/02/2018 @ 12:00am (UTC)
  InitialDeposit: 0,
  Symbols: ['EURUSD'],
  Balance: 0,
  Equity: 0,
  Margin: 0,
  FreeMargin: 0,
  MarketOrders: [],
  PendingOrders: [],
  ClosedOrders: [],
  Notes: [],
  Charts: [
    { Symbol: 'EURUSD', TimeFrame: 5, Comment: '', Digits: 5 },
    { Symbol: 'GPBUSD', TimeFrame: 60, Comment: '', Digits: 5 },
    { Symbol: 'USDJPY', TimeFrame: 15, Comment: '', Digits: 5 }
  ]
}

export const basicCourseDemoCharts: IChart[] = [
  {
    symbol: 'EURUSD',
    timeFrame: 5,
    comment: '',
    digitsAfterPoint: 5,
    id: shortid.generate(),
    index: 1,
    mode: 'candle'
  },
  {
    symbol: 'GBPUSD',
    timeFrame: 60,
    comment: '',
    digitsAfterPoint: 5,
    id: shortid.generate(),
    index: 2,
    mode: 'candle'
  },
  {
    symbol: 'USDJPY',
    timeFrame: 15,
    comment: '',
    digitsAfterPoint: 5,
    id: shortid.generate(),
    index: 2,
    mode: 'candle'
  }
]

export const basicCourseDemoAccountInfo: IAccountInfo = {
  balance: 0,
  equity: 0,
  margin: 0,
  freeMargin: 0,
  marginLevel: 0,
  totalProfit: 0,
  dollarsPerPipPerLot: 10,
  lotSize: 100000,
  leverage: 100
}

export const basicCourseDemoCurrentBars: ICurrentBar = {
  EURUSD5: { close: 1.10618, date: '2020-02-05T10:00:00.000Z', open: 1.10588 },
  GBPUSD60: { close: 1.10016, date: '2020-02-05T08:00:00.000Z', open: 1.10217 },
  USDJPY15: { close: 1.10016, date: '2020-02-05T08:00:00.000Z', open: 1.10217 }
}

export const basicCourseDemoChartTimeFrames: IChartTimeFrame[] = [
  {
    symbol: 'EURUSD',
    timeFrame: 5
  },
  {
    symbol: 'GBPUSD',
    timeFrame: 60
  },
  {
    symbol: 'USDJPY',
    timeFrame: 15
  }
]

export const basicCourseDemoLastBarIndexes: ILastBarIndexes = {
  EURUSD5: 1,
  GBPUSD60: 1,
  USDJPY15: 1
}

export const basicCourseDemoIndicators: IIndicator[] = []

export const basicCourseDemoFiboRetracement: IChartTools = {
  textList: [],
  trend_lines: [],
  rays: [],
  vertical_lines: [],
  horizontal_lines: [],
  retracements: [],
  gann_fans: [],
  equidistant_channels: [],
  standard_deviation_channels: [],
  rectangles: [],
  ellipses: [],
  triangles: [],
  icons: [],
  riskRewards: []
}

export const basicCourseDemoVisibleData: { [currency: string]: IServerBar[] } = {
  EURUSD5: [
    {
      d: 1522540800,
      b: [
        {
          t: 75600,
          o: 1.23199,
          h: 1.23209,
          l: 1.23172,
          c: 1.23172,
          v: 14730000
        },
        {
          t: 75900,
          o: 1.23185,
          h: 1.23206,
          l: 1.23184,
          c: 1.23206,
          v: 125500000
        }
      ]
    }
  ],
  GBPUSD60: [
    {
      d: 1522540800,
      b: [
        {
          t: 75600,
          o: 1.40224,
          h: 1.40328,
          l: 1.40193,
          c: 1.40273,
          v: 704780000
        },
        {
          t: 79200,
          o: 1.4027,
          h: 1.40333,
          l: 1.40203,
          c: 1.40329,
          v: 3268400000
        }
      ]
    }
  ],
  USDJPY15: [
    {
      d: 1522540800,
      b: [
        {
          t: 75600,
          o: 106.167,
          h: 106.237,
          l: 106.167,
          c: 106.231,
          v: 103270000
        },
        {
          t: 76500,
          o: 106.232,
          h: 106.234,
          l: 106.212,
          c: 106.215,
          v: 58130000
        }
      ]
    }
  ]
}

export const basicCourseDemoEntireData: { [currency: string]: IServerBar[] } = {
  EURUSD5: [
    {
      d: 1522540800,
      b: [
        {
          t: 75600,
          o: 1.23199,
          h: 1.23209,
          l: 1.23172,
          c: 1.23172,
          v: 14730000
        },
        {
          t: 75900,
          o: 1.23185,
          h: 1.23206,
          l: 1.23184,
          c: 1.23206,
          v: 125500000
        },
        {
          t: 76200,
          o: 1.23207,
          h: 1.23209,
          l: 1.23165,
          c: 1.23194,
          v: 68120000
        },
        {
          t: 76500,
          o: 1.23194,
          h: 1.23212,
          l: 1.23194,
          c: 1.23212,
          v: 66030000
        },
        {
          t: 76800,
          o: 1.23212,
          h: 1.23216,
          l: 1.23212,
          c: 1.23215,
          v: 23120000
        },
        {
          t: 77100,
          o: 1.23216,
          h: 1.23216,
          l: 1.23215,
          c: 1.23216,
          v: 12000000
        },
        {
          t: 77400,
          o: 1.23216,
          h: 1.23222,
          l: 1.23213,
          c: 1.23222,
          v: 41100000
        },
        {
          t: 77700,
          o: 1.23223,
          h: 1.23224,
          l: 1.23223,
          c: 1.23224,
          v: 20560000
        },
        {
          t: 78000,
          o: 1.23224,
          h: 1.23225,
          l: 1.23223,
          c: 1.23223,
          v: 25740000
        },
        {
          t: 78300,
          o: 1.23223,
          h: 1.23224,
          l: 1.23223,
          c: 1.23223,
          v: 33020000
        },
        {
          t: 78600,
          o: 1.23223,
          h: 1.23228,
          l: 1.23223,
          c: 1.23228,
          v: 19250000
        },
        {
          t: 78900,
          o: 1.23229,
          h: 1.2323,
          l: 1.23194,
          c: 1.23204,
          v: 20430000
        },
        {
          t: 79200,
          o: 1.23204,
          h: 1.23217,
          l: 1.23165,
          c: 1.23173,
          v: 425540000
        },
        {
          t: 79500,
          o: 1.23171,
          h: 1.23198,
          l: 1.23158,
          c: 1.23167,
          v: 546730000
        },
        {
          t: 79800,
          o: 1.23168,
          h: 1.23203,
          l: 1.23166,
          c: 1.23179,
          v: 566870000
        },
        {
          t: 80100,
          o: 1.23179,
          h: 1.23185,
          l: 1.23169,
          c: 1.2318,
          v: 435410000
        },
        {
          t: 80400,
          o: 1.2318,
          h: 1.23181,
          l: 1.23159,
          c: 1.23164,
          v: 553070000
        },
        {
          t: 80700,
          o: 1.23167,
          h: 1.23168,
          l: 1.23132,
          c: 1.23136,
          v: 469930000
        },
        {
          t: 81000,
          o: 1.23137,
          h: 1.2315,
          l: 1.23137,
          c: 1.23138,
          v: 413570000
        },
        {
          t: 81300,
          o: 1.23142,
          h: 1.23153,
          l: 1.23134,
          c: 1.23148,
          v: 196820000
        },
        {
          t: 81600,
          o: 1.23147,
          h: 1.23148,
          l: 1.23137,
          c: 1.23137,
          v: 94200000
        },
        {
          t: 81900,
          o: 1.23137,
          h: 1.23158,
          l: 1.23137,
          c: 1.23152,
          v: 121380000
        },
        {
          t: 82200,
          o: 1.23151,
          h: 1.23173,
          l: 1.23146,
          c: 1.23173,
          v: 170180000
        },
        {
          t: 82500,
          o: 1.23175,
          h: 1.23182,
          l: 1.23172,
          c: 1.23172,
          v: 101350000
        },
        {
          t: 82800,
          o: 1.23172,
          h: 1.23206,
          l: 1.23155,
          c: 1.23192,
          v: 361000000
        },
        {
          t: 83100,
          o: 1.23191,
          h: 1.23191,
          l: 1.23158,
          c: 1.2317,
          v: 424530000
        },
        {
          t: 83400,
          o: 1.23171,
          h: 1.23175,
          l: 1.23152,
          c: 1.23152,
          v: 200350000
        },
        {
          t: 83700,
          o: 1.23152,
          h: 1.23155,
          l: 1.2314,
          c: 1.23143,
          v: 178400000
        },
        {
          t: 84000,
          o: 1.23142,
          h: 1.23146,
          l: 1.23136,
          c: 1.23141,
          v: 222470001
        },
        {
          t: 84300,
          o: 1.23141,
          h: 1.23141,
          l: 1.23136,
          c: 1.23141,
          v: 140380000
        },
        {
          t: 84600,
          o: 1.23141,
          h: 1.23154,
          l: 1.2314,
          c: 1.23152,
          v: 357840000
        },
        {
          t: 84900,
          o: 1.23152,
          h: 1.23152,
          l: 1.23146,
          c: 1.23147,
          v: 165610000
        },
        {
          t: 85200,
          o: 1.23146,
          h: 1.23147,
          l: 1.23131,
          c: 1.23134,
          v: 208970000
        },
        {
          t: 85500,
          o: 1.23133,
          h: 1.2315,
          l: 1.23133,
          c: 1.23143,
          v: 285250000
        },
        {
          t: 85800,
          o: 1.23145,
          h: 1.23155,
          l: 1.23136,
          c: 1.23141,
          v: 429170000
        },
        {
          t: 86100,
          o: 1.23141,
          h: 1.2315,
          l: 1.23124,
          c: 1.23125,
          v: 317790000
        }
      ]
    },
    {
      d: 1522627200,
      b: [
        {
          t: 0,
          o: 1.23127,
          h: 1.23147,
          l: 1.23116,
          c: 1.23126,
          v: 578700000
        },
        {
          t: 300,
          o: 1.23127,
          h: 1.23127,
          l: 1.2311,
          c: 1.23112,
          v: 327960000
        },
        {
          t: 600,
          o: 1.23113,
          h: 1.23149,
          l: 1.23107,
          c: 1.23147,
          v: 379360000
        },
        {
          t: 900,
          o: 1.23147,
          h: 1.23171,
          l: 1.23137,
          c: 1.23169,
          v: 360400000
        },
        {
          t: 1200,
          o: 1.23169,
          h: 1.23189,
          l: 1.23167,
          c: 1.23184,
          v: 478590000
        },
        {
          t: 1500,
          o: 1.23185,
          h: 1.23196,
          l: 1.23174,
          c: 1.23185,
          v: 242940000
        },
        {
          t: 1800,
          o: 1.23184,
          h: 1.23202,
          l: 1.23178,
          c: 1.232,
          v: 516160000
        },
        {
          t: 2100,
          o: 1.23201,
          h: 1.23204,
          l: 1.23188,
          c: 1.23202,
          v: 343410000
        },
        {
          t: 2400,
          o: 1.23201,
          h: 1.23208,
          l: 1.23182,
          c: 1.23183,
          v: 237210000
        },
        {
          t: 2700,
          o: 1.23182,
          h: 1.23208,
          l: 1.23179,
          c: 1.23208,
          v: 479960000
        },
        {
          t: 3000,
          o: 1.23208,
          h: 1.23216,
          l: 1.23185,
          c: 1.23197,
          v: 946110002
        },
        {
          t: 3300,
          o: 1.232,
          h: 1.23217,
          l: 1.23181,
          c: 1.23217,
          v: 527440000
        },
        {
          t: 3600,
          o: 1.23219,
          h: 1.23267,
          l: 1.23214,
          c: 1.23248,
          v: 700890000
        },
        {
          t: 3900,
          o: 1.2325,
          h: 1.2326,
          l: 1.2324,
          c: 1.23248,
          v: 565270000
        },
        {
          t: 4200,
          o: 1.23249,
          h: 1.23271,
          l: 1.23249,
          c: 1.23254,
          v: 366680000
        },
        {
          t: 4500,
          o: 1.23254,
          h: 1.23259,
          l: 1.23248,
          c: 1.23249,
          v: 102890000
        },
        {
          t: 4800,
          o: 1.23249,
          h: 1.23261,
          l: 1.23241,
          c: 1.23241,
          v: 216350000
        },
        {
          t: 5100,
          o: 1.23242,
          h: 1.23274,
          l: 1.23242,
          c: 1.23268,
          v: 223500000
        },
        {
          t: 5400,
          o: 1.23267,
          h: 1.23282,
          l: 1.23258,
          c: 1.23276,
          v: 450370000
        },
        {
          t: 5700,
          o: 1.23276,
          h: 1.23278,
          l: 1.2326,
          c: 1.2326,
          v: 368920000
        },
        {
          t: 6000,
          o: 1.23259,
          h: 1.23275,
          l: 1.23256,
          c: 1.23275,
          v: 278390001
        },
        {
          t: 6300,
          o: 1.23276,
          h: 1.23277,
          l: 1.2325,
          c: 1.23268,
          v: 226550000
        },
        {
          t: 6600,
          o: 1.23267,
          h: 1.23277,
          l: 1.23258,
          c: 1.23269,
          v: 451870002
        },
        {
          t: 6900,
          o: 1.23267,
          h: 1.23268,
          l: 1.23258,
          c: 1.23263,
          v: 212840000
        },
        {
          t: 7200,
          o: 1.23263,
          h: 1.23278,
          l: 1.23256,
          c: 1.23268,
          v: 237190000
        },
        {
          t: 7500,
          o: 1.23267,
          h: 1.23268,
          l: 1.23244,
          c: 1.23248,
          v: 216950000
        },
        {
          t: 7800,
          o: 1.23248,
          h: 1.23248,
          l: 1.23232,
          c: 1.23238,
          v: 250370000
        },
        {
          t: 8100,
          o: 1.23238,
          h: 1.23238,
          l: 1.23227,
          c: 1.23228,
          v: 98660000
        },
        {
          t: 8400,
          o: 1.23228,
          h: 1.23233,
          l: 1.23225,
          c: 1.23227,
          v: 216490000
        },
        {
          t: 8700,
          o: 1.23229,
          h: 1.23234,
          l: 1.23223,
          c: 1.23223,
          v: 275790000
        },
        {
          t: 9000,
          o: 1.23223,
          h: 1.23224,
          l: 1.23209,
          c: 1.23219,
          v: 286830000
        },
        {
          t: 9300,
          o: 1.23219,
          h: 1.23223,
          l: 1.23214,
          c: 1.23222,
          v: 320700000
        },
        {
          t: 9600,
          o: 1.23222,
          h: 1.23225,
          l: 1.23218,
          c: 1.23224,
          v: 297990000
        },
        {
          t: 9900,
          o: 1.23224,
          h: 1.23224,
          l: 1.23209,
          c: 1.2321,
          v: 228540000
        },
        {
          t: 10200,
          o: 1.2321,
          h: 1.23233,
          l: 1.2321,
          c: 1.23223,
          v: 375190000
        },
        {
          t: 10500,
          o: 1.23228,
          h: 1.23241,
          l: 1.23224,
          c: 1.23234,
          v: 276660000
        },
        {
          t: 10800,
          o: 1.23235,
          h: 1.23235,
          l: 1.23216,
          c: 1.23218,
          v: 245350000
        },
        {
          t: 11100,
          o: 1.23218,
          h: 1.23219,
          l: 1.23214,
          c: 1.23217,
          v: 207720000
        },
        {
          t: 11400,
          o: 1.23216,
          h: 1.23229,
          l: 1.23216,
          c: 1.23226,
          v: 174550000
        },
        {
          t: 11700,
          o: 1.23227,
          h: 1.23228,
          l: 1.23216,
          c: 1.23216,
          v: 220900000
        },
        {
          t: 12000,
          o: 1.23216,
          h: 1.23218,
          l: 1.23213,
          c: 1.23214,
          v: 214190000
        },
        {
          t: 12300,
          o: 1.23214,
          h: 1.23214,
          l: 1.23198,
          c: 1.23203,
          v: 356680000
        },
        {
          t: 12600,
          o: 1.23203,
          h: 1.23208,
          l: 1.23198,
          c: 1.23208,
          v: 249360000
        },
        {
          t: 12900,
          o: 1.23209,
          h: 1.23209,
          l: 1.23182,
          c: 1.23187,
          v: 299410000
        },
        {
          t: 13200,
          o: 1.23187,
          h: 1.23189,
          l: 1.23177,
          c: 1.23177,
          v: 269760000
        },
        {
          t: 13500,
          o: 1.23177,
          h: 1.23187,
          l: 1.23172,
          c: 1.23173,
          v: 231350000
        },
        {
          t: 13800,
          o: 1.23173,
          h: 1.23178,
          l: 1.23172,
          c: 1.23172,
          v: 158980000
        },
        {
          t: 14100,
          o: 1.23173,
          h: 1.23189,
          l: 1.23162,
          c: 1.23184,
          v: 392750000
        },
        {
          t: 14400,
          o: 1.23184,
          h: 1.23184,
          l: 1.23159,
          c: 1.23168,
          v: 313210000
        },
        {
          t: 14700,
          o: 1.23168,
          h: 1.23181,
          l: 1.23162,
          c: 1.23164,
          v: 206640000
        },
        {
          t: 15000,
          o: 1.23164,
          h: 1.23169,
          l: 1.23148,
          c: 1.2316,
          v: 655149996
        },
        {
          t: 15300,
          o: 1.23159,
          h: 1.2318,
          l: 1.23158,
          c: 1.23175,
          v: 598359999
        },
        {
          t: 15600,
          o: 1.23174,
          h: 1.23184,
          l: 1.23172,
          c: 1.23174,
          v: 292870001
        },
        {
          t: 15900,
          o: 1.23174,
          h: 1.23177,
          l: 1.23165,
          c: 1.23176,
          v: 317060000
        },
        {
          t: 16200,
          o: 1.23175,
          h: 1.23175,
          l: 1.23161,
          c: 1.23175,
          v: 191250000
        },
        {
          t: 16500,
          o: 1.23175,
          h: 1.23178,
          l: 1.23155,
          c: 1.23165,
          v: 303600000
        },
        {
          t: 16800,
          o: 1.23164,
          h: 1.2317,
          l: 1.23163,
          c: 1.23166,
          v: 226600000
        },
        {
          t: 17100,
          o: 1.23167,
          h: 1.2317,
          l: 1.23159,
          c: 1.2317,
          v: 224810000
        },
        {
          t: 17400,
          o: 1.2317,
          h: 1.23175,
          l: 1.23169,
          c: 1.23173,
          v: 156060000
        },
        {
          t: 17700,
          o: 1.23174,
          h: 1.23175,
          l: 1.23165,
          c: 1.23169,
          v: 71750000
        },
        {
          t: 18000,
          o: 1.23169,
          h: 1.2317,
          l: 1.23163,
          c: 1.23165,
          v: 117900000
        },
        {
          t: 18300,
          o: 1.23165,
          h: 1.23172,
          l: 1.23155,
          c: 1.23158,
          v: 200690000
        },
        {
          t: 18600,
          o: 1.23158,
          h: 1.23167,
          l: 1.23156,
          c: 1.23163,
          v: 212440000
        },
        {
          t: 18900,
          o: 1.23163,
          h: 1.2317,
          l: 1.23159,
          c: 1.2316,
          v: 234270000
        },
        {
          t: 19200,
          o: 1.2316,
          h: 1.23164,
          l: 1.23159,
          c: 1.23159,
          v: 103440000
        },
        {
          t: 19500,
          o: 1.23159,
          h: 1.2316,
          l: 1.23153,
          c: 1.23154,
          v: 88410000
        },
        {
          t: 19800,
          o: 1.23153,
          h: 1.23157,
          l: 1.23134,
          c: 1.23134,
          v: 161790000
        },
        {
          t: 20100,
          o: 1.23133,
          h: 1.2315,
          l: 1.23133,
          c: 1.2314,
          v: 256300000
        },
        {
          t: 20400,
          o: 1.23139,
          h: 1.23155,
          l: 1.23139,
          c: 1.23152,
          v: 362960000
        },
        {
          t: 20700,
          o: 1.23155,
          h: 1.23167,
          l: 1.2315,
          c: 1.23158,
          v: 289470000
        },
        {
          t: 21000,
          o: 1.23158,
          h: 1.23162,
          l: 1.23147,
          c: 1.23149,
          v: 316820000
        },
        {
          t: 21300,
          o: 1.23149,
          h: 1.23172,
          l: 1.23149,
          c: 1.23171,
          v: 345520000
        },
        {
          t: 21600,
          o: 1.23171,
          h: 1.23183,
          l: 1.23159,
          c: 1.2318,
          v: 602100000
        },
        {
          t: 21900,
          o: 1.23179,
          h: 1.23185,
          l: 1.2316,
          c: 1.2316,
          v: 208550000
        },
        {
          t: 22200,
          o: 1.2316,
          h: 1.23176,
          l: 1.2316,
          c: 1.23171,
          v: 193270000
        },
        {
          t: 22500,
          o: 1.23171,
          h: 1.23171,
          l: 1.23116,
          c: 1.23134,
          v: 391340000
        },
        {
          t: 22800,
          o: 1.23134,
          h: 1.23153,
          l: 1.23118,
          c: 1.23129,
          v: 738750000
        },
        {
          t: 23100,
          o: 1.23129,
          h: 1.23152,
          l: 1.23124,
          c: 1.23151,
          v: 340660000
        },
        {
          t: 23400,
          o: 1.2315,
          h: 1.23175,
          l: 1.2314,
          c: 1.23171,
          v: 377470000
        },
        {
          t: 23700,
          o: 1.2317,
          h: 1.23184,
          l: 1.2316,
          c: 1.23165,
          v: 418019999
        },
        {
          t: 24000,
          o: 1.23164,
          h: 1.23202,
          l: 1.23161,
          c: 1.23186,
          v: 465670000
        },
        {
          t: 24300,
          o: 1.23184,
          h: 1.23187,
          l: 1.2316,
          c: 1.23169,
          v: 377550000
        },
        {
          t: 24600,
          o: 1.23171,
          h: 1.23208,
          l: 1.2317,
          c: 1.23194,
          v: 289340000
        },
        {
          t: 24900,
          o: 1.23194,
          h: 1.23226,
          l: 1.2319,
          c: 1.23224,
          v: 290200000
        },
        {
          t: 25200,
          o: 1.23224,
          h: 1.2325,
          l: 1.23209,
          c: 1.23236,
          v: 595520000
        },
        {
          t: 25500,
          o: 1.23237,
          h: 1.23265,
          l: 1.23221,
          c: 1.23225,
          v: 514340000
        },
        {
          t: 25800,
          o: 1.23224,
          h: 1.23229,
          l: 1.23202,
          c: 1.23202,
          v: 181650000
        },
        {
          t: 26100,
          o: 1.23202,
          h: 1.2323,
          l: 1.232,
          c: 1.23208,
          v: 340710000
        },
        {
          t: 26400,
          o: 1.23209,
          h: 1.23221,
          l: 1.23202,
          c: 1.2322,
          v: 265710000
        },
        {
          t: 26700,
          o: 1.2322,
          h: 1.2324,
          l: 1.23218,
          c: 1.23239,
          v: 180400000
        },
        {
          t: 27000,
          o: 1.23239,
          h: 1.23243,
          l: 1.2323,
          c: 1.23233,
          v: 207550000
        },
        {
          t: 27300,
          o: 1.23233,
          h: 1.2324,
          l: 1.23231,
          c: 1.23233,
          v: 209330000
        },
        {
          t: 27600,
          o: 1.23232,
          h: 1.23275,
          l: 1.23232,
          c: 1.23274,
          v: 370900000
        },
        {
          t: 27900,
          o: 1.23274,
          h: 1.23294,
          l: 1.23258,
          c: 1.23283,
          v: 469710000
        },
        {
          t: 28200,
          o: 1.23283,
          h: 1.23318,
          l: 1.23282,
          c: 1.23299,
          v: 510390000
        },
        {
          t: 28500,
          o: 1.23299,
          h: 1.23312,
          l: 1.23299,
          c: 1.23306,
          v: 509820000
        },
        {
          t: 28800,
          o: 1.23306,
          h: 1.23309,
          l: 1.23295,
          c: 1.23301,
          v: 612540000
        },
        {
          t: 29100,
          o: 1.23301,
          h: 1.23305,
          l: 1.23296,
          c: 1.23301,
          v: 362010000
        },
        {
          t: 29400,
          o: 1.23302,
          h: 1.23317,
          l: 1.23295,
          c: 1.2331,
          v: 508440000
        },
        {
          t: 29700,
          o: 1.23311,
          h: 1.23311,
          l: 1.23291,
          c: 1.23307,
          v: 524820000
        },
        {
          t: 30000,
          o: 1.23308,
          h: 1.23314,
          l: 1.23289,
          c: 1.23291,
          v: 451060000
        },
        {
          t: 30300,
          o: 1.23291,
          h: 1.23308,
          l: 1.23281,
          c: 1.23305,
          v: 569480000
        },
        {
          t: 30600,
          o: 1.23305,
          h: 1.23305,
          l: 1.23295,
          c: 1.23298,
          v: 313320001
        },
        {
          t: 30900,
          o: 1.23299,
          h: 1.23303,
          l: 1.2328,
          c: 1.23281,
          v: 357830000
        },
        {
          t: 31200,
          o: 1.23281,
          h: 1.23291,
          l: 1.2328,
          c: 1.23282,
          v: 126300000
        },
        {
          t: 31500,
          o: 1.23282,
          h: 1.23307,
          l: 1.23282,
          c: 1.23286,
          v: 312110000
        },
        {
          t: 31800,
          o: 1.23285,
          h: 1.23307,
          l: 1.23285,
          c: 1.23307,
          v: 345160000
        },
        {
          t: 32100,
          o: 1.23306,
          h: 1.2331,
          l: 1.23283,
          c: 1.23288,
          v: 232930000
        },
        {
          t: 32400,
          o: 1.23289,
          h: 1.23306,
          l: 1.23282,
          c: 1.23297,
          v: 413230000
        },
        {
          t: 32700,
          o: 1.23296,
          h: 1.23307,
          l: 1.23296,
          c: 1.23304,
          v: 277820000
        },
        {
          t: 33000,
          o: 1.23304,
          h: 1.23315,
          l: 1.23304,
          c: 1.23312,
          v: 283020000
        },
        {
          t: 33300,
          o: 1.23313,
          h: 1.23324,
          l: 1.23301,
          c: 1.23302,
          v: 235150000
        },
        {
          t: 33600,
          o: 1.23301,
          h: 1.23303,
          l: 1.23297,
          c: 1.23301,
          v: 141940000
        },
        {
          t: 33900,
          o: 1.23302,
          h: 1.23308,
          l: 1.23298,
          c: 1.23308,
          v: 235810000
        },
        {
          t: 34200,
          o: 1.23306,
          h: 1.23309,
          l: 1.23303,
          c: 1.23308,
          v: 284990000
        },
        {
          t: 34500,
          o: 1.23308,
          h: 1.2331,
          l: 1.23304,
          c: 1.2331,
          v: 246240000
        },
        {
          t: 34800,
          o: 1.23309,
          h: 1.23319,
          l: 1.23308,
          c: 1.23319,
          v: 368350000
        },
        {
          t: 35100,
          o: 1.23319,
          h: 1.23322,
          l: 1.23311,
          c: 1.23318,
          v: 265570000
        },
        {
          t: 35400,
          o: 1.23318,
          h: 1.23318,
          l: 1.23309,
          c: 1.23312,
          v: 186060000
        },
        {
          t: 35700,
          o: 1.23313,
          h: 1.23322,
          l: 1.23308,
          c: 1.23312,
          v: 356090000
        },
        {
          t: 36000,
          o: 1.23312,
          h: 1.23313,
          l: 1.23295,
          c: 1.23297,
          v: 277100000
        },
        {
          t: 36300,
          o: 1.23297,
          h: 1.23298,
          l: 1.23271,
          c: 1.23273,
          v: 368200000
        },
        {
          t: 36600,
          o: 1.23274,
          h: 1.23285,
          l: 1.23273,
          c: 1.23282,
          v: 482620000
        },
        {
          t: 36900,
          o: 1.23282,
          h: 1.23287,
          l: 1.23272,
          c: 1.23277,
          v: 419559999
        },
        {
          t: 37200,
          o: 1.23279,
          h: 1.23288,
          l: 1.23279,
          c: 1.23285,
          v: 468520000
        },
        {
          t: 37500,
          o: 1.23286,
          h: 1.23286,
          l: 1.23271,
          c: 1.23285,
          v: 445150000
        },
        {
          t: 37800,
          o: 1.23285,
          h: 1.23287,
          l: 1.23275,
          c: 1.23286,
          v: 391250000
        },
        {
          t: 38100,
          o: 1.23286,
          h: 1.23287,
          l: 1.23267,
          c: 1.23276,
          v: 348740000
        },
        {
          t: 38400,
          o: 1.23277,
          h: 1.2328,
          l: 1.23271,
          c: 1.23276,
          v: 269900000
        },
        {
          t: 38700,
          o: 1.23276,
          h: 1.23276,
          l: 1.2327,
          c: 1.23276,
          v: 346690000
        },
        {
          t: 39000,
          o: 1.23276,
          h: 1.23281,
          l: 1.23265,
          c: 1.23276,
          v: 224840001
        },
        {
          t: 39300,
          o: 1.23276,
          h: 1.23277,
          l: 1.2327,
          c: 1.23275,
          v: 203740000
        },
        {
          t: 39600,
          o: 1.23276,
          h: 1.233,
          l: 1.23274,
          c: 1.2329,
          v: 720910002
        },
        {
          t: 39900,
          o: 1.2329,
          h: 1.233,
          l: 1.2327,
          c: 1.23294,
          v: 596180000
        },
        {
          t: 40200,
          o: 1.23294,
          h: 1.23312,
          l: 1.23283,
          c: 1.23301,
          v: 627619999
        },
        {
          t: 40500,
          o: 1.233,
          h: 1.23314,
          l: 1.23298,
          c: 1.233,
          v: 285020000
        },
        {
          t: 40800,
          o: 1.23302,
          h: 1.23308,
          l: 1.23293,
          c: 1.23298,
          v: 412700000
        },
        {
          t: 41100,
          o: 1.23298,
          h: 1.23329,
          l: 1.23297,
          c: 1.23302,
          v: 568820000
        },
        {
          t: 41400,
          o: 1.23303,
          h: 1.23376,
          l: 1.23298,
          c: 1.23366,
          v: 645880000
        },
        {
          t: 41700,
          o: 1.23368,
          h: 1.23378,
          l: 1.2335,
          c: 1.23366,
          v: 691590000
        },
        {
          t: 42000,
          o: 1.23365,
          h: 1.23385,
          l: 1.23359,
          c: 1.23371,
          v: 576620000
        },
        {
          t: 42300,
          o: 1.23371,
          h: 1.23382,
          l: 1.23363,
          c: 1.2337,
          v: 462230000
        },
        {
          t: 42600,
          o: 1.23369,
          h: 1.23376,
          l: 1.23364,
          c: 1.23371,
          v: 417340000
        },
        {
          t: 42900,
          o: 1.23371,
          h: 1.23421,
          l: 1.23366,
          c: 1.23411,
          v: 991960000
        },
        {
          t: 43200,
          o: 1.23413,
          h: 1.23445,
          l: 1.23413,
          c: 1.23424,
          v: 986250000
        },
        {
          t: 43500,
          o: 1.23424,
          h: 1.23436,
          l: 1.23395,
          c: 1.23399,
          v: 822010000
        },
        {
          t: 43800,
          o: 1.234,
          h: 1.23436,
          l: 1.23388,
          c: 1.23408,
          v: 832160000
        },
        {
          t: 44100,
          o: 1.23408,
          h: 1.23421,
          l: 1.23392,
          c: 1.23421,
          v: 647020000
        },
        {
          t: 44400,
          o: 1.2342,
          h: 1.23449,
          l: 1.23411,
          c: 1.2342,
          v: 831260000
        },
        {
          t: 44700,
          o: 1.2342,
          h: 1.23425,
          l: 1.23392,
          c: 1.23397,
          v: 526790000
        },
        {
          t: 45000,
          o: 1.23397,
          h: 1.23399,
          l: 1.23377,
          c: 1.23377,
          v: 592250000
        },
        {
          t: 45300,
          o: 1.23377,
          h: 1.23382,
          l: 1.23344,
          c: 1.23353,
          v: 703440000
        },
        {
          t: 45600,
          o: 1.23353,
          h: 1.2337,
          l: 1.23339,
          c: 1.2337,
          v: 565459998
        },
        {
          t: 45900,
          o: 1.23366,
          h: 1.23379,
          l: 1.23342,
          c: 1.2336,
          v: 772310000
        },
        {
          t: 46200,
          o: 1.2336,
          h: 1.23372,
          l: 1.23346,
          c: 1.23357,
          v: 918380000
        },
        {
          t: 46500,
          o: 1.23358,
          h: 1.23388,
          l: 1.23357,
          c: 1.23358,
          v: 809180000
        },
        {
          t: 46800,
          o: 1.23358,
          h: 1.23373,
          l: 1.23328,
          c: 1.23336,
          v: 994580000
        },
        {
          t: 47100,
          o: 1.23335,
          h: 1.23363,
          l: 1.2333,
          c: 1.23358,
          v: 631350000
        },
        {
          t: 47400,
          o: 1.23358,
          h: 1.23368,
          l: 1.23336,
          c: 1.23359,
          v: 780820000
        },
        {
          t: 47700,
          o: 1.23357,
          h: 1.23367,
          l: 1.23342,
          c: 1.23342,
          v: 992320000
        },
        {
          t: 48000,
          o: 1.23341,
          h: 1.23347,
          l: 1.23329,
          c: 1.23331,
          v: 1096550000
        },
        {
          t: 48300,
          o: 1.23332,
          h: 1.23333,
          l: 1.23294,
          c: 1.23308,
          v: 1169220000
        },
        {
          t: 48600,
          o: 1.23311,
          h: 1.23328,
          l: 1.23287,
          c: 1.23291,
          v: 1221740000
        },
        {
          t: 48900,
          o: 1.23289,
          h: 1.23294,
          l: 1.23243,
          c: 1.23275,
          v: 1311020000
        },
        {
          t: 49200,
          o: 1.23278,
          h: 1.23289,
          l: 1.23241,
          c: 1.2325,
          v: 1017820000
        },
        {
          t: 49500,
          o: 1.23251,
          h: 1.23251,
          l: 1.23184,
          c: 1.2319,
          v: 989950000
        },
        {
          t: 49800,
          o: 1.2319,
          h: 1.23201,
          l: 1.23178,
          c: 1.23196,
          v: 1331660001
        },
        {
          t: 50100,
          o: 1.23197,
          h: 1.23242,
          l: 1.23196,
          c: 1.23216,
          v: 731200001
        },
        {
          t: 50400,
          o: 1.23221,
          h: 1.23223,
          l: 1.23138,
          c: 1.23165,
          v: 1338020000
        },
        {
          t: 50700,
          o: 1.23164,
          h: 1.23172,
          l: 1.23139,
          c: 1.23163,
          v: 1304680001
        },
        {
          t: 51000,
          o: 1.23161,
          h: 1.23222,
          l: 1.23161,
          c: 1.23219,
          v: 1313130000
        },
        {
          t: 51300,
          o: 1.23218,
          h: 1.2325,
          l: 1.23209,
          c: 1.23237,
          v: 992440000
        },
        {
          t: 51600,
          o: 1.23237,
          h: 1.23307,
          l: 1.23237,
          c: 1.23307,
          v: 1052000000
        },
        {
          t: 51900,
          o: 1.23307,
          h: 1.23311,
          l: 1.23265,
          c: 1.23282,
          v: 1044340000
        },
        {
          t: 52200,
          o: 1.23282,
          h: 1.23323,
          l: 1.23281,
          c: 1.23311,
          v: 1122960000
        },
        {
          t: 52500,
          o: 1.23311,
          h: 1.2334,
          l: 1.233,
          c: 1.23321,
          v: 938270000
        },
        {
          t: 52800,
          o: 1.23321,
          h: 1.23341,
          l: 1.23286,
          c: 1.23288,
          v: 758900000
        },
        {
          t: 53100,
          o: 1.23287,
          h: 1.23293,
          l: 1.2324,
          c: 1.23242,
          v: 950800000
        },
        {
          t: 53400,
          o: 1.23241,
          h: 1.23242,
          l: 1.23198,
          c: 1.23209,
          v: 1051730000
        },
        {
          t: 53700,
          o: 1.23208,
          h: 1.23223,
          l: 1.23101,
          c: 1.23118,
          v: 1470650000
        },
        {
          t: 54000,
          o: 1.23119,
          h: 1.23119,
          l: 1.22999,
          c: 1.23021,
          v: 1956650000
        },
        {
          t: 54300,
          o: 1.23021,
          h: 1.23044,
          l: 1.22998,
          c: 1.23038,
          v: 1418600000
        },
        {
          t: 54600,
          o: 1.23036,
          h: 1.23074,
          l: 1.23022,
          c: 1.23068,
          v: 1068580000
        },
        {
          t: 54900,
          o: 1.23065,
          h: 1.23076,
          l: 1.23022,
          c: 1.23034,
          v: 1175460000
        },
        {
          t: 55200,
          o: 1.23033,
          h: 1.23048,
          l: 1.23014,
          c: 1.2304,
          v: 912960000
        },
        {
          t: 55500,
          o: 1.23042,
          h: 1.23057,
          l: 1.23029,
          c: 1.23029,
          v: 761600000
        },
        {
          t: 55800,
          o: 1.23029,
          h: 1.23044,
          l: 1.23002,
          c: 1.23023,
          v: 1132480000
        },
        {
          t: 56100,
          o: 1.23025,
          h: 1.23031,
          l: 1.2282,
          c: 1.22841,
          v: 1600870000
        },
        {
          t: 56400,
          o: 1.22839,
          h: 1.22899,
          l: 1.22822,
          c: 1.2289,
          v: 1439410000
        },
        {
          t: 56700,
          o: 1.2289,
          h: 1.22909,
          l: 1.22858,
          c: 1.22909,
          v: 1228070000
        },
        {
          t: 57000,
          o: 1.22909,
          h: 1.2291,
          l: 1.22848,
          c: 1.22848,
          v: 1265799999
        },
        {
          t: 57300,
          o: 1.22848,
          h: 1.22901,
          l: 1.22829,
          c: 1.22877,
          v: 1094670000
        },
        {
          t: 57600,
          o: 1.22878,
          h: 1.22898,
          l: 1.22852,
          c: 1.22882,
          v: 1469950000
        },
        {
          t: 57900,
          o: 1.22882,
          h: 1.22907,
          l: 1.22865,
          c: 1.22902,
          v: 1535110000
        },
        {
          t: 58200,
          o: 1.22902,
          h: 1.22925,
          l: 1.22893,
          c: 1.22911,
          v: 1378790000
        },
        {
          t: 58500,
          o: 1.22911,
          h: 1.22941,
          l: 1.22909,
          c: 1.22923,
          v: 792910000
        },
        {
          t: 58800,
          o: 1.22921,
          h: 1.22932,
          l: 1.22908,
          c: 1.22911,
          v: 1089410000
        },
        {
          t: 59100,
          o: 1.22911,
          h: 1.22915,
          l: 1.22891,
          c: 1.22908,
          v: 1179990000
        },
        {
          t: 59400,
          o: 1.22908,
          h: 1.22925,
          l: 1.22903,
          c: 1.22912,
          v: 1033210000
        },
        {
          t: 59700,
          o: 1.22911,
          h: 1.22911,
          l: 1.22881,
          c: 1.22882,
          v: 641870000
        },
        {
          t: 60000,
          o: 1.22882,
          h: 1.22904,
          l: 1.22878,
          c: 1.22897,
          v: 590750000
        },
        {
          t: 60300,
          o: 1.22899,
          h: 1.22907,
          l: 1.22887,
          c: 1.22906,
          v: 455880000
        },
        {
          t: 60600,
          o: 1.22905,
          h: 1.22911,
          l: 1.22881,
          c: 1.22902,
          v: 539570000
        },
        {
          t: 60900,
          o: 1.22902,
          h: 1.22945,
          l: 1.22895,
          c: 1.2294,
          v: 936030000
        },
        {
          t: 61200,
          o: 1.2294,
          h: 1.22941,
          l: 1.22892,
          c: 1.22911,
          v: 1032620000
        },
        {
          t: 61500,
          o: 1.22911,
          h: 1.22921,
          l: 1.22891,
          c: 1.22905,
          v: 464660000
        },
        {
          t: 61800,
          o: 1.22905,
          h: 1.22907,
          l: 1.2288,
          c: 1.2288,
          v: 565850000
        },
        {
          t: 62100,
          o: 1.2288,
          h: 1.22915,
          l: 1.2288,
          c: 1.22899,
          v: 1164790000
        },
        {
          t: 62400,
          o: 1.22898,
          h: 1.2292,
          l: 1.22888,
          c: 1.22911,
          v: 1023310000
        },
        {
          t: 62700,
          o: 1.2291,
          h: 1.22916,
          l: 1.2289,
          c: 1.2291,
          v: 1097010000
        },
        {
          t: 63000,
          o: 1.2291,
          h: 1.22956,
          l: 1.22889,
          c: 1.22956,
          v: 1303950000
        },
        {
          t: 63300,
          o: 1.22956,
          h: 1.22957,
          l: 1.22933,
          c: 1.22946,
          v: 726410000
        },
        {
          t: 63600,
          o: 1.22945,
          h: 1.2298,
          l: 1.22943,
          c: 1.22976,
          v: 1296590000
        },
        {
          t: 63900,
          o: 1.22976,
          h: 1.23036,
          l: 1.22975,
          c: 1.23031,
          v: 1040910000
        },
        {
          t: 64200,
          o: 1.23032,
          h: 1.23045,
          l: 1.23019,
          c: 1.2303,
          v: 1047180000
        },
        {
          t: 64500,
          o: 1.2303,
          h: 1.23045,
          l: 1.23025,
          c: 1.23045,
          v: 718990000
        },
        {
          t: 64800,
          o: 1.23043,
          h: 1.2307,
          l: 1.23041,
          c: 1.23054,
          v: 1061530000
        },
        {
          t: 65100,
          o: 1.23054,
          h: 1.2306,
          l: 1.23038,
          c: 1.2304,
          v: 986330002
        },
        {
          t: 65400,
          o: 1.23039,
          h: 1.23051,
          l: 1.23029,
          c: 1.23038,
          v: 1172450001
        },
        {
          t: 65700,
          o: 1.23039,
          h: 1.23053,
          l: 1.2303,
          c: 1.2304,
          v: 658820000
        },
        {
          t: 66000,
          o: 1.2304,
          h: 1.23044,
          l: 1.23028,
          c: 1.2303,
          v: 661250000
        },
        {
          t: 66300,
          o: 1.23031,
          h: 1.23034,
          l: 1.23025,
          c: 1.23025,
          v: 416540000
        },
        {
          t: 66600,
          o: 1.23025,
          h: 1.23034,
          l: 1.2302,
          c: 1.23024,
          v: 842810000
        },
        {
          t: 66900,
          o: 1.23025,
          h: 1.23036,
          l: 1.23023,
          c: 1.23035,
          v: 591810000
        },
        {
          t: 67200,
          o: 1.23035,
          h: 1.23043,
          l: 1.23028,
          c: 1.23035,
          v: 855370001
        },
        {
          t: 67500,
          o: 1.23035,
          h: 1.2304,
          l: 1.2302,
          c: 1.23021,
          v: 808320000
        },
        {
          t: 67800,
          o: 1.23021,
          h: 1.23021,
          l: 1.22988,
          c: 1.22989,
          v: 905510000
        },
        {
          t: 68100,
          o: 1.22989,
          h: 1.2302,
          l: 1.22987,
          c: 1.23006,
          v: 1161370000
        },
        {
          t: 68400,
          o: 1.23003,
          h: 1.2302,
          l: 1.22981,
          c: 1.23016,
          v: 1058330000
        },
        {
          t: 68700,
          o: 1.23016,
          h: 1.23041,
          l: 1.23014,
          c: 1.23036,
          v: 893630000
        },
        {
          t: 69000,
          o: 1.23035,
          h: 1.23044,
          l: 1.23015,
          c: 1.23017,
          v: 608540000
        },
        {
          t: 69300,
          o: 1.23018,
          h: 1.23024,
          l: 1.23,
          c: 1.23014,
          v: 945400000
        },
        {
          t: 69600,
          o: 1.23015,
          h: 1.23017,
          l: 1.22998,
          c: 1.23012,
          v: 822800000
        },
        {
          t: 69900,
          o: 1.23012,
          h: 1.23016,
          l: 1.22998,
          c: 1.23007,
          v: 489680000
        },
        {
          t: 70200,
          o: 1.23008,
          h: 1.23018,
          l: 1.22998,
          c: 1.23017,
          v: 454760000
        },
        {
          t: 70500,
          o: 1.23018,
          h: 1.23023,
          l: 1.23001,
          c: 1.23002,
          v: 363380000
        },
        {
          t: 70800,
          o: 1.23004,
          h: 1.2301,
          l: 1.22995,
          c: 1.22997,
          v: 575879999
        },
        {
          t: 71100,
          o: 1.22996,
          h: 1.23022,
          l: 1.22987,
          c: 1.23015,
          v: 917620000
        },
        {
          t: 71400,
          o: 1.23015,
          h: 1.23032,
          l: 1.23001,
          c: 1.23006,
          v: 1266930000
        },
        {
          t: 71700,
          o: 1.23006,
          h: 1.23059,
          l: 1.23005,
          c: 1.23056,
          v: 1232610000
        },
        {
          t: 72000,
          o: 1.23055,
          h: 1.23059,
          l: 1.23012,
          c: 1.2302,
          v: 789300000
        },
        {
          t: 72300,
          o: 1.23021,
          h: 1.23034,
          l: 1.2302,
          c: 1.23028,
          v: 377350000
        },
        {
          t: 72600,
          o: 1.23029,
          h: 1.23034,
          l: 1.23017,
          c: 1.23021,
          v: 288820000
        },
        {
          t: 72900,
          o: 1.23021,
          h: 1.23024,
          l: 1.23013,
          c: 1.23014,
          v: 300170000
        },
        {
          t: 73200,
          o: 1.23015,
          h: 1.23025,
          l: 1.23013,
          c: 1.23015,
          v: 691850000
        },
        {
          t: 73500,
          o: 1.23015,
          h: 1.23026,
          l: 1.2301,
          c: 1.23025,
          v: 425280000
        },
        {
          t: 73800,
          o: 1.23026,
          h: 1.2303,
          l: 1.23014,
          c: 1.2302,
          v: 352870000
        },
        {
          t: 74100,
          o: 1.23019,
          h: 1.23022,
          l: 1.23014,
          c: 1.23016,
          v: 238920000
        },
        {
          t: 74400,
          o: 1.23016,
          h: 1.23026,
          l: 1.23009,
          c: 1.23015,
          v: 320450000
        },
        {
          t: 74700,
          o: 1.23015,
          h: 1.23022,
          l: 1.23009,
          c: 1.2301,
          v: 271790000
        },
        {
          t: 75000,
          o: 1.2301,
          h: 1.23017,
          l: 1.23008,
          c: 1.23013,
          v: 295020000
        },
        {
          t: 75300,
          o: 1.23013,
          h: 1.23016,
          l: 1.23007,
          c: 1.2301,
          v: 266630000
        },
        {
          t: 75600,
          o: 1.23008,
          h: 1.23053,
          l: 1.23007,
          c: 1.23039,
          v: 81800000
        },
        {
          t: 75900,
          o: 1.23034,
          h: 1.23036,
          l: 1.23021,
          c: 1.2303,
          v: 160850000
        },
        {
          t: 76200,
          o: 1.23031,
          h: 1.23044,
          l: 1.23018,
          c: 1.23039,
          v: 645930000
        },
        {
          t: 76500,
          o: 1.23041,
          h: 1.23041,
          l: 1.23028,
          c: 1.23032,
          v: 200240000
        },
        {
          t: 76800,
          o: 1.23032,
          h: 1.23033,
          l: 1.23015,
          c: 1.23022,
          v: 284800000
        },
        {
          t: 77100,
          o: 1.23024,
          h: 1.23041,
          l: 1.23015,
          c: 1.23015,
          v: 208790000
        },
        {
          t: 77400,
          o: 1.23015,
          h: 1.23031,
          l: 1.2299,
          c: 1.23017,
          v: 392770000
        },
        {
          t: 77700,
          o: 1.23019,
          h: 1.23036,
          l: 1.22983,
          c: 1.23013,
          v: 540940000
        },
        {
          t: 78000,
          o: 1.23011,
          h: 1.23032,
          l: 1.22997,
          c: 1.23022,
          v: 424560000
        },
        {
          t: 78300,
          o: 1.23024,
          h: 1.23032,
          l: 1.23006,
          c: 1.23014,
          v: 391310000
        },
        {
          t: 78600,
          o: 1.23014,
          h: 1.23021,
          l: 1.23007,
          c: 1.23015,
          v: 323720000
        },
        {
          t: 78900,
          o: 1.23015,
          h: 1.23022,
          l: 1.23011,
          c: 1.23016,
          v: 325600000
        },
        {
          t: 79200,
          o: 1.23014,
          h: 1.23032,
          l: 1.22996,
          c: 1.23027,
          v: 334830000
        },
        {
          t: 79500,
          o: 1.23026,
          h: 1.23031,
          l: 1.23019,
          c: 1.23023,
          v: 307720000
        },
        {
          t: 79800,
          o: 1.23023,
          h: 1.23028,
          l: 1.23013,
          c: 1.23018,
          v: 238400000
        },
        {
          t: 80100,
          o: 1.23021,
          h: 1.23025,
          l: 1.23013,
          c: 1.23022,
          v: 233300000
        },
        {
          t: 80400,
          o: 1.23016,
          h: 1.23018,
          l: 1.22984,
          c: 1.22984,
          v: 131840000
        },
        {
          t: 80700,
          o: 1.22988,
          h: 1.22996,
          l: 1.22987,
          c: 1.22993,
          v: 130680000
        },
        {
          t: 81000,
          o: 1.22993,
          h: 1.22993,
          l: 1.22984,
          c: 1.22984,
          v: 127840000
        },
        {
          t: 81300,
          o: 1.22983,
          h: 1.22987,
          l: 1.22981,
          c: 1.22987,
          v: 109120000
        },
        {
          t: 81600,
          o: 1.22986,
          h: 1.22995,
          l: 1.22986,
          c: 1.22995,
          v: 72080000
        },
        {
          t: 81900,
          o: 1.22995,
          h: 1.22999,
          l: 1.2299,
          c: 1.22999,
          v: 57180000
        },
        {
          t: 82200,
          o: 1.22999,
          h: 1.23004,
          l: 1.22999,
          c: 1.23004,
          v: 67730000
        },
        {
          t: 82500,
          o: 1.23004,
          h: 1.2301,
          l: 1.23001,
          c: 1.23009,
          v: 73220000
        },
        {
          t: 82800,
          o: 1.23008,
          h: 1.23014,
          l: 1.22996,
          c: 1.23006,
          v: 151410000
        },
        {
          t: 83100,
          o: 1.23006,
          h: 1.23027,
          l: 1.23005,
          c: 1.23026,
          v: 151600000
        },
        {
          t: 83400,
          o: 1.23025,
          h: 1.23032,
          l: 1.23,
          c: 1.23009,
          v: 397740000
        },
        {
          t: 83700,
          o: 1.23008,
          h: 1.23009,
          l: 1.22994,
          c: 1.23005,
          v: 323720000
        },
        {
          t: 84000,
          o: 1.23004,
          h: 1.23009,
          l: 1.23,
          c: 1.23005,
          v: 372530000
        },
        {
          t: 84300,
          o: 1.23004,
          h: 1.23009,
          l: 1.22982,
          c: 1.22982,
          v: 372630000
        },
        {
          t: 84600,
          o: 1.22982,
          h: 1.22999,
          l: 1.22968,
          c: 1.2297,
          v: 535050000
        },
        {
          t: 84900,
          o: 1.22969,
          h: 1.22984,
          l: 1.22954,
          c: 1.22955,
          v: 425980000
        },
        {
          t: 85200,
          o: 1.22958,
          h: 1.22965,
          l: 1.22943,
          c: 1.22956,
          v: 216630000
        },
        {
          t: 85500,
          o: 1.22956,
          h: 1.22956,
          l: 1.22935,
          c: 1.22935,
          v: 212020000
        },
        {
          t: 85800,
          o: 1.22935,
          h: 1.2294,
          l: 1.22926,
          c: 1.2293,
          v: 142880000
        },
        {
          t: 86100,
          o: 1.2293,
          h: 1.22936,
          l: 1.22921,
          c: 1.22931,
          v: 155080000
        }
      ]
    },
    {
      d: 1522713600,
      b: [
        {
          t: 0,
          o: 1.22931,
          h: 1.22975,
          l: 1.22921,
          c: 1.22953,
          v: 548210000
        },
        {
          t: 300,
          o: 1.22954,
          h: 1.22969,
          l: 1.22951,
          c: 1.22967,
          v: 286570000
        },
        {
          t: 600,
          o: 1.22966,
          h: 1.22981,
          l: 1.22951,
          c: 1.22972,
          v: 240760000
        },
        {
          t: 900,
          o: 1.22972,
          h: 1.22979,
          l: 1.22956,
          c: 1.22972,
          v: 414700000
        },
        {
          t: 1200,
          o: 1.22972,
          h: 1.22999,
          l: 1.22972,
          c: 1.22993,
          v: 271180000
        },
        {
          t: 1500,
          o: 1.22993,
          h: 1.23016,
          l: 1.22982,
          c: 1.23004,
          v: 451860000
        },
        {
          t: 1800,
          o: 1.23005,
          h: 1.23021,
          l: 1.22992,
          c: 1.22998,
          v: 346180000
        },
        {
          t: 2100,
          o: 1.22999,
          h: 1.22999,
          l: 1.22981,
          c: 1.22995,
          v: 166090000
        },
        {
          t: 2400,
          o: 1.22995,
          h: 1.23034,
          l: 1.22994,
          c: 1.23018,
          v: 411250000
        },
        {
          t: 2700,
          o: 1.23018,
          h: 1.23018,
          l: 1.22995,
          c: 1.23009,
          v: 236350000
        },
        {
          t: 3000,
          o: 1.23009,
          h: 1.2304,
          l: 1.22999,
          c: 1.23019,
          v: 656090000
        },
        {
          t: 3300,
          o: 1.2301,
          h: 1.23035,
          l: 1.23001,
          c: 1.23032,
          v: 431600000
        },
        {
          t: 3600,
          o: 1.23033,
          h: 1.23035,
          l: 1.23022,
          c: 1.23034,
          v: 570620000
        },
        {
          t: 3900,
          o: 1.23033,
          h: 1.23042,
          l: 1.23024,
          c: 1.23034,
          v: 413360000
        },
        {
          t: 4200,
          o: 1.23034,
          h: 1.2308,
          l: 1.23034,
          c: 1.23065,
          v: 454820000
        },
        {
          t: 4500,
          o: 1.23069,
          h: 1.23081,
          l: 1.23054,
          c: 1.2306,
          v: 431270000
        },
        {
          t: 4800,
          o: 1.23061,
          h: 1.23076,
          l: 1.23047,
          c: 1.23054,
          v: 438540000
        },
        {
          t: 5100,
          o: 1.23054,
          h: 1.23054,
          l: 1.23021,
          c: 1.23021,
          v: 386870000
        },
        {
          t: 5400,
          o: 1.2302,
          h: 1.23026,
          l: 1.22985,
          c: 1.22988,
          v: 471740000
        },
        {
          t: 5700,
          o: 1.22986,
          h: 1.2299,
          l: 1.22971,
          c: 1.2298,
          v: 516710000
        },
        {
          t: 6000,
          o: 1.2298,
          h: 1.23006,
          l: 1.22969,
          c: 1.23006,
          v: 461340000
        },
        {
          t: 6300,
          o: 1.23007,
          h: 1.23009,
          l: 1.2298,
          c: 1.22998,
          v: 568530000
        },
        {
          t: 6600,
          o: 1.22997,
          h: 1.23026,
          l: 1.22996,
          c: 1.23023,
          v: 629200000
        },
        {
          t: 6900,
          o: 1.23024,
          h: 1.23036,
          l: 1.23023,
          c: 1.23033,
          v: 482960000
        },
        {
          t: 7200,
          o: 1.23033,
          h: 1.23052,
          l: 1.23024,
          c: 1.23051,
          v: 382310000
        },
        {
          t: 7500,
          o: 1.23051,
          h: 1.23051,
          l: 1.2302,
          c: 1.23031,
          v: 222930000
        },
        {
          t: 7800,
          o: 1.23031,
          h: 1.23055,
          l: 1.23028,
          c: 1.23029,
          v: 314000000
        },
        {
          t: 8100,
          o: 1.2303,
          h: 1.23054,
          l: 1.23023,
          c: 1.23052,
          v: 321680000
        },
        {
          t: 8400,
          o: 1.23051,
          h: 1.23065,
          l: 1.23047,
          c: 1.2306,
          v: 319020000
        },
        {
          t: 8700,
          o: 1.23061,
          h: 1.23071,
          l: 1.23054,
          c: 1.2307,
          v: 285170000
        },
        {
          t: 9000,
          o: 1.2307,
          h: 1.2307,
          l: 1.23058,
          c: 1.23061,
          v: 305970000
        },
        {
          t: 9300,
          o: 1.2306,
          h: 1.2307,
          l: 1.23053,
          c: 1.23053,
          v: 301520000
        },
        {
          t: 9600,
          o: 1.23054,
          h: 1.23066,
          l: 1.23054,
          c: 1.23065,
          v: 328480000
        },
        {
          t: 9900,
          o: 1.23066,
          h: 1.23074,
          l: 1.23055,
          c: 1.23061,
          v: 335250000
        },
        {
          t: 10200,
          o: 1.23061,
          h: 1.23076,
          l: 1.23055,
          c: 1.23073,
          v: 272530000
        },
        {
          t: 10500,
          o: 1.23072,
          h: 1.23096,
          l: 1.2307,
          c: 1.23074,
          v: 342120000
        },
        {
          t: 10800,
          o: 1.23074,
          h: 1.23116,
          l: 1.23073,
          c: 1.23106,
          v: 578610000
        },
        {
          t: 11100,
          o: 1.23105,
          h: 1.23105,
          l: 1.23078,
          c: 1.23079,
          v: 189950000
        },
        {
          t: 11400,
          o: 1.23079,
          h: 1.23079,
          l: 1.23059,
          c: 1.23069,
          v: 222210000
        },
        {
          t: 11700,
          o: 1.23069,
          h: 1.23096,
          l: 1.23064,
          c: 1.23086,
          v: 254640000
        },
        {
          t: 12000,
          o: 1.23086,
          h: 1.23096,
          l: 1.23078,
          c: 1.23081,
          v: 377840000
        },
        {
          t: 12300,
          o: 1.2308,
          h: 1.23091,
          l: 1.23064,
          c: 1.2309,
          v: 393620000
        },
        {
          t: 12600,
          o: 1.23088,
          h: 1.23091,
          l: 1.23079,
          c: 1.23084,
          v: 384280000
        },
        {
          t: 12900,
          o: 1.23084,
          h: 1.23085,
          l: 1.23059,
          c: 1.23076,
          v: 360040000
        },
        {
          t: 13200,
          o: 1.23075,
          h: 1.23076,
          l: 1.2306,
          c: 1.2307,
          v: 228060000
        },
        {
          t: 13500,
          o: 1.2307,
          h: 1.23081,
          l: 1.2307,
          c: 1.2307,
          v: 198200000
        },
        {
          t: 13800,
          o: 1.23071,
          h: 1.23075,
          l: 1.23067,
          c: 1.23075,
          v: 143890000
        },
        {
          t: 14100,
          o: 1.23076,
          h: 1.23076,
          l: 1.2305,
          c: 1.23056,
          v: 261330000
        },
        {
          t: 14400,
          o: 1.23055,
          h: 1.23057,
          l: 1.23045,
          c: 1.23056,
          v: 222760000
        },
        {
          t: 14700,
          o: 1.23056,
          h: 1.2306,
          l: 1.2305,
          c: 1.23054,
          v: 261380000
        },
        {
          t: 15000,
          o: 1.23054,
          h: 1.23063,
          l: 1.23054,
          c: 1.23061,
          v: 229110000
        },
        {
          t: 15300,
          o: 1.23061,
          h: 1.23065,
          l: 1.23058,
          c: 1.2306,
          v: 148400000
        },
        {
          t: 15600,
          o: 1.2306,
          h: 1.23073,
          l: 1.23059,
          c: 1.2306,
          v: 246200000
        },
        {
          t: 15900,
          o: 1.23059,
          h: 1.23076,
          l: 1.23059,
          c: 1.2307,
          v: 341870000
        },
        {
          t: 16200,
          o: 1.2307,
          h: 1.2309,
          l: 1.23068,
          c: 1.23075,
          v: 401570000
        },
        {
          t: 16500,
          o: 1.23076,
          h: 1.23095,
          l: 1.23071,
          c: 1.23095,
          v: 267910000
        },
        {
          t: 16800,
          o: 1.23095,
          h: 1.23099,
          l: 1.23083,
          c: 1.23086,
          v: 371180000
        },
        {
          t: 17100,
          o: 1.23087,
          h: 1.23093,
          l: 1.23085,
          c: 1.23085,
          v: 219340000
        },
        {
          t: 17400,
          o: 1.23087,
          h: 1.23096,
          l: 1.23087,
          c: 1.23087,
          v: 195180000
        },
        {
          t: 17700,
          o: 1.23087,
          h: 1.23099,
          l: 1.23084,
          c: 1.23095,
          v: 168520000
        },
        {
          t: 18000,
          o: 1.23095,
          h: 1.23107,
          l: 1.23082,
          c: 1.23101,
          v: 217120000
        },
        {
          t: 18300,
          o: 1.23101,
          h: 1.23101,
          l: 1.2308,
          c: 1.2308,
          v: 240730000
        },
        {
          t: 18600,
          o: 1.2308,
          h: 1.2308,
          l: 1.23058,
          c: 1.23068,
          v: 546500000
        },
        {
          t: 18900,
          o: 1.23069,
          h: 1.23069,
          l: 1.2304,
          c: 1.23043,
          v: 760740000
        },
        {
          t: 19200,
          o: 1.23043,
          h: 1.23051,
          l: 1.23034,
          c: 1.23037,
          v: 346990000
        },
        {
          t: 19500,
          o: 1.23036,
          h: 1.23066,
          l: 1.23036,
          c: 1.23064,
          v: 346480000
        },
        {
          t: 19800,
          o: 1.23066,
          h: 1.23081,
          l: 1.2306,
          c: 1.2307,
          v: 435430000
        },
        {
          t: 20100,
          o: 1.2307,
          h: 1.23074,
          l: 1.23069,
          c: 1.23074,
          v: 192620000
        },
        {
          t: 20400,
          o: 1.23074,
          h: 1.23092,
          l: 1.23074,
          c: 1.23081,
          v: 343840000
        },
        {
          t: 20700,
          o: 1.23081,
          h: 1.23097,
          l: 1.23069,
          c: 1.23096,
          v: 452080000
        },
        {
          t: 21000,
          o: 1.23094,
          h: 1.23094,
          l: 1.23084,
          c: 1.23091,
          v: 460790000
        },
        {
          t: 21300,
          o: 1.2309,
          h: 1.23108,
          l: 1.23082,
          c: 1.23084,
          v: 372530000
        },
        {
          t: 21600,
          o: 1.23084,
          h: 1.23084,
          l: 1.23043,
          c: 1.2308,
          v: 580260000
        },
        {
          t: 21900,
          o: 1.23082,
          h: 1.23179,
          l: 1.23082,
          c: 1.23153,
          v: 856879999
        },
        {
          t: 22200,
          o: 1.23153,
          h: 1.2316,
          l: 1.23124,
          c: 1.2313,
          v: 791570000
        },
        {
          t: 22500,
          o: 1.2313,
          h: 1.23136,
          l: 1.23104,
          c: 1.23104,
          v: 775950000
        },
        {
          t: 22800,
          o: 1.23104,
          h: 1.2313,
          l: 1.23104,
          c: 1.23115,
          v: 794540000
        },
        {
          t: 23100,
          o: 1.23114,
          h: 1.23144,
          l: 1.23113,
          c: 1.23138,
          v: 1187760000
        },
        {
          t: 23400,
          o: 1.23139,
          h: 1.23189,
          l: 1.23121,
          c: 1.23188,
          v: 1039420000
        },
        {
          t: 23700,
          o: 1.23188,
          h: 1.23206,
          l: 1.23143,
          c: 1.23155,
          v: 1358150000
        },
        {
          t: 24000,
          o: 1.23156,
          h: 1.23204,
          l: 1.23146,
          c: 1.23183,
          v: 1387610000
        },
        {
          t: 24300,
          o: 1.23184,
          h: 1.23197,
          l: 1.23151,
          c: 1.23196,
          v: 808600000
        },
        {
          t: 24600,
          o: 1.23198,
          h: 1.23244,
          l: 1.23187,
          c: 1.2324,
          v: 1228970000
        },
        {
          t: 24900,
          o: 1.2324,
          h: 1.23247,
          l: 1.23208,
          c: 1.2323,
          v: 1232330000
        },
        {
          t: 25200,
          o: 1.2323,
          h: 1.23263,
          l: 1.23202,
          c: 1.23249,
          v: 1321090000
        },
        {
          t: 25500,
          o: 1.23249,
          h: 1.23301,
          l: 1.23249,
          c: 1.23278,
          v: 1335410002
        },
        {
          t: 25800,
          o: 1.23278,
          h: 1.23315,
          l: 1.23248,
          c: 1.23301,
          v: 1281000001
        },
        {
          t: 26100,
          o: 1.23302,
          h: 1.23338,
          l: 1.23281,
          c: 1.23328,
          v: 1291100001
        },
        {
          t: 26400,
          o: 1.23328,
          h: 1.23345,
          l: 1.2331,
          c: 1.23316,
          v: 1172780000
        },
        {
          t: 26700,
          o: 1.23315,
          h: 1.23352,
          l: 1.23306,
          c: 1.23307,
          v: 1227399999
        },
        {
          t: 27000,
          o: 1.23309,
          h: 1.23315,
          l: 1.23236,
          c: 1.23239,
          v: 1149120000
        },
        {
          t: 27300,
          o: 1.23239,
          h: 1.23312,
          l: 1.23234,
          c: 1.23297,
          v: 1446950001
        },
        {
          t: 27600,
          o: 1.23298,
          h: 1.23332,
          l: 1.23286,
          c: 1.23295,
          v: 1053490000
        },
        {
          t: 27900,
          o: 1.23296,
          h: 1.233,
          l: 1.2324,
          c: 1.23243,
          v: 1031380000
        },
        {
          t: 28200,
          o: 1.23243,
          h: 1.23256,
          l: 1.23216,
          c: 1.23236,
          v: 1096690000
        },
        {
          t: 28500,
          o: 1.23235,
          h: 1.23265,
          l: 1.23224,
          c: 1.2324,
          v: 983510000
        },
        {
          t: 28800,
          o: 1.23241,
          h: 1.23257,
          l: 1.2321,
          c: 1.23215,
          v: 1317960000
        },
        {
          t: 29100,
          o: 1.23213,
          h: 1.23237,
          l: 1.23186,
          c: 1.23226,
          v: 1025190000
        },
        {
          t: 29400,
          o: 1.23225,
          h: 1.23258,
          l: 1.23199,
          c: 1.23221,
          v: 1216050002
        },
        {
          t: 29700,
          o: 1.23221,
          h: 1.23266,
          l: 1.23213,
          c: 1.23246,
          v: 1113780000
        },
        {
          t: 30000,
          o: 1.23246,
          h: 1.23307,
          l: 1.2324,
          c: 1.23297,
          v: 942900000
        },
        {
          t: 30300,
          o: 1.23296,
          h: 1.23296,
          l: 1.2327,
          c: 1.23276,
          v: 980490000
        },
        {
          t: 30600,
          o: 1.23278,
          h: 1.23306,
          l: 1.23278,
          c: 1.23288,
          v: 1026300000
        },
        {
          t: 30900,
          o: 1.23288,
          h: 1.23296,
          l: 1.2324,
          c: 1.23258,
          v: 1077350000
        },
        {
          t: 31200,
          o: 1.23258,
          h: 1.2328,
          l: 1.23242,
          c: 1.23259,
          v: 1256560001
        },
        {
          t: 31500,
          o: 1.23258,
          h: 1.23258,
          l: 1.23202,
          c: 1.23217,
          v: 1110130000
        },
        {
          t: 31800,
          o: 1.23219,
          h: 1.23224,
          l: 1.23192,
          c: 1.23202,
          v: 934350000
        },
        {
          t: 32100,
          o: 1.23202,
          h: 1.23202,
          l: 1.23139,
          c: 1.23166,
          v: 1378329999
        },
        {
          t: 32400,
          o: 1.23166,
          h: 1.23188,
          l: 1.2316,
          c: 1.23174,
          v: 1060810000
        },
        {
          t: 32700,
          o: 1.23174,
          h: 1.23174,
          l: 1.23141,
          c: 1.23148,
          v: 1092990000
        },
        {
          t: 33000,
          o: 1.23147,
          h: 1.23159,
          l: 1.2312,
          c: 1.23124,
          v: 1158770000
        },
        {
          t: 33300,
          o: 1.23124,
          h: 1.23129,
          l: 1.23095,
          c: 1.23096,
          v: 1196450000
        },
        {
          t: 33600,
          o: 1.23091,
          h: 1.23105,
          l: 1.23056,
          c: 1.23063,
          v: 1108760000
        },
        {
          t: 33900,
          o: 1.23063,
          h: 1.23088,
          l: 1.23055,
          c: 1.23078,
          v: 976110000
        },
        {
          t: 34200,
          o: 1.23077,
          h: 1.23097,
          l: 1.23066,
          c: 1.23094,
          v: 947190001
        },
        {
          t: 34500,
          o: 1.23093,
          h: 1.23111,
          l: 1.23089,
          c: 1.23092,
          v: 1111390000
        },
        {
          t: 34800,
          o: 1.23091,
          h: 1.23097,
          l: 1.23042,
          c: 1.23083,
          v: 1579779998
        },
        {
          t: 35100,
          o: 1.23084,
          h: 1.23095,
          l: 1.2307,
          c: 1.23093,
          v: 1414660000
        },
        {
          t: 35400,
          o: 1.23093,
          h: 1.23095,
          l: 1.23074,
          c: 1.23078,
          v: 1404239999
        },
        {
          t: 35700,
          o: 1.23078,
          h: 1.2313,
          l: 1.23068,
          c: 1.23073,
          v: 1479680000
        },
        {
          t: 36000,
          o: 1.23073,
          h: 1.2313,
          l: 1.23061,
          c: 1.23127,
          v: 966900000
        },
        {
          t: 36300,
          o: 1.23125,
          h: 1.23154,
          l: 1.23123,
          c: 1.23141,
          v: 783620001
        },
        {
          t: 36600,
          o: 1.23142,
          h: 1.23147,
          l: 1.23104,
          c: 1.23118,
          v: 1038949999
        },
        {
          t: 36900,
          o: 1.23117,
          h: 1.23141,
          l: 1.23112,
          c: 1.23135,
          v: 723080000
        },
        {
          t: 37200,
          o: 1.23135,
          h: 1.23146,
          l: 1.23123,
          c: 1.23142,
          v: 715700000
        },
        {
          t: 37500,
          o: 1.23142,
          h: 1.23152,
          l: 1.23121,
          c: 1.23121,
          v: 892400000
        },
        {
          t: 37800,
          o: 1.23121,
          h: 1.23127,
          l: 1.23067,
          c: 1.23072,
          v: 975770000
        },
        {
          t: 38100,
          o: 1.23076,
          h: 1.23076,
          l: 1.23047,
          c: 1.23069,
          v: 901120000
        },
        {
          t: 38400,
          o: 1.2307,
          h: 1.23078,
          l: 1.22953,
          c: 1.22967,
          v: 1131040000
        },
        {
          t: 38700,
          o: 1.22967,
          h: 1.22981,
          l: 1.22925,
          c: 1.22945,
          v: 1079660000
        },
        {
          t: 39000,
          o: 1.22944,
          h: 1.22944,
          l: 1.22906,
          c: 1.22933,
          v: 981109999
        },
        {
          t: 39300,
          o: 1.22934,
          h: 1.22937,
          l: 1.22861,
          c: 1.22883,
          v: 1323320000
        },
        {
          t: 39600,
          o: 1.22881,
          h: 1.22895,
          l: 1.22868,
          c: 1.22892,
          v: 1124360000
        },
        {
          t: 39900,
          o: 1.2289,
          h: 1.22925,
          l: 1.22884,
          c: 1.2291,
          v: 918950000
        },
        {
          t: 40200,
          o: 1.22911,
          h: 1.22943,
          l: 1.22905,
          c: 1.22935,
          v: 1028600000
        },
        {
          t: 40500,
          o: 1.22939,
          h: 1.22943,
          l: 1.22863,
          c: 1.22877,
          v: 1126360000
        },
        {
          t: 40800,
          o: 1.22876,
          h: 1.22923,
          l: 1.22867,
          c: 1.22911,
          v: 1001300000
        },
        {
          t: 41100,
          o: 1.22912,
          h: 1.22971,
          l: 1.2289,
          c: 1.22971,
          v: 1177630001
        },
        {
          t: 41400,
          o: 1.2297,
          h: 1.22976,
          l: 1.22938,
          c: 1.22975,
          v: 999980000
        },
        {
          t: 41700,
          o: 1.22975,
          h: 1.23031,
          l: 1.22952,
          c: 1.23018,
          v: 842860000
        },
        {
          t: 42000,
          o: 1.2302,
          h: 1.23026,
          l: 1.22947,
          c: 1.22962,
          v: 724769999
        },
        {
          t: 42300,
          o: 1.22963,
          h: 1.23047,
          l: 1.22962,
          c: 1.23047,
          v: 1159100000
        },
        {
          t: 42600,
          o: 1.2305,
          h: 1.23056,
          l: 1.23024,
          c: 1.23026,
          v: 917929999
        },
        {
          t: 42900,
          o: 1.23025,
          h: 1.23027,
          l: 1.23001,
          c: 1.23018,
          v: 1273320000
        },
        {
          t: 43200,
          o: 1.23019,
          h: 1.23049,
          l: 1.23015,
          c: 1.23042,
          v: 1285159999
        },
        {
          t: 43500,
          o: 1.23041,
          h: 1.23052,
          l: 1.2302,
          c: 1.23047,
          v: 932110001
        },
        {
          t: 43800,
          o: 1.23048,
          h: 1.23097,
          l: 1.23048,
          c: 1.23078,
          v: 1273770000
        },
        {
          t: 44100,
          o: 1.23077,
          h: 1.23106,
          l: 1.2307,
          c: 1.23103,
          v: 924160001
        },
        {
          t: 44400,
          o: 1.23106,
          h: 1.23107,
          l: 1.23088,
          c: 1.2309,
          v: 933950000
        },
        {
          t: 44700,
          o: 1.2309,
          h: 1.23149,
          l: 1.23088,
          c: 1.23105,
          v: 1253910000
        },
        {
          t: 45000,
          o: 1.23104,
          h: 1.23114,
          l: 1.23053,
          c: 1.23055,
          v: 1123760000
        },
        {
          t: 45300,
          o: 1.23056,
          h: 1.23067,
          l: 1.23036,
          c: 1.23047,
          v: 1258220001
        },
        {
          t: 45600,
          o: 1.23046,
          h: 1.2306,
          l: 1.22937,
          c: 1.22943,
          v: 1457100000
        },
        {
          t: 45900,
          o: 1.22942,
          h: 1.2297,
          l: 1.22926,
          c: 1.22949,
          v: 1201850000
        },
        {
          t: 46200,
          o: 1.22949,
          h: 1.22965,
          l: 1.2292,
          c: 1.22949,
          v: 1075950000
        },
        {
          t: 46500,
          o: 1.22948,
          h: 1.22966,
          l: 1.2294,
          c: 1.22957,
          v: 926680000
        },
        {
          t: 46800,
          o: 1.22958,
          h: 1.22974,
          l: 1.22949,
          c: 1.22963,
          v: 865840000
        },
        {
          t: 47100,
          o: 1.22963,
          h: 1.22988,
          l: 1.22895,
          c: 1.22898,
          v: 1137990000
        },
        {
          t: 47400,
          o: 1.22898,
          h: 1.22917,
          l: 1.22749,
          c: 1.2277,
          v: 1877100000
        },
        {
          t: 47700,
          o: 1.22774,
          h: 1.22774,
          l: 1.22611,
          c: 1.22643,
          v: 2196080001
        },
        {
          t: 48000,
          o: 1.22646,
          h: 1.22674,
          l: 1.22606,
          c: 1.22668,
          v: 1834410000
        },
        {
          t: 48300,
          o: 1.22668,
          h: 1.22675,
          l: 1.22584,
          c: 1.22592,
          v: 1593699999
        },
        {
          t: 48600,
          o: 1.22592,
          h: 1.22611,
          l: 1.22561,
          c: 1.22592,
          v: 1960000002
        },
        {
          t: 48900,
          o: 1.22592,
          h: 1.22631,
          l: 1.22578,
          c: 1.22609,
          v: 1869240002
        },
        {
          t: 49200,
          o: 1.22608,
          h: 1.22637,
          l: 1.22587,
          c: 1.22624,
          v: 1830479999
        },
        {
          t: 49500,
          o: 1.22624,
          h: 1.22663,
          l: 1.2261,
          c: 1.22651,
          v: 1644779999
        },
        {
          t: 49800,
          o: 1.22651,
          h: 1.22652,
          l: 1.22613,
          c: 1.22634,
          v: 1312130000
        },
        {
          t: 50100,
          o: 1.22633,
          h: 1.22649,
          l: 1.22576,
          c: 1.22577,
          v: 1730720000
        },
        {
          t: 50400,
          o: 1.22579,
          h: 1.22652,
          l: 1.22573,
          c: 1.22639,
          v: 1773150000
        },
        {
          t: 50700,
          o: 1.22638,
          h: 1.22658,
          l: 1.22593,
          c: 1.22597,
          v: 1337070000
        },
        {
          t: 51000,
          o: 1.22598,
          h: 1.22598,
          l: 1.22537,
          c: 1.22564,
          v: 1718370001
        },
        {
          t: 51300,
          o: 1.22564,
          h: 1.22634,
          l: 1.22564,
          c: 1.22607,
          v: 1529930000
        },
        {
          t: 51600,
          o: 1.22608,
          h: 1.22634,
          l: 1.22604,
          c: 1.22634,
          v: 1344240000
        },
        {
          t: 51900,
          o: 1.22633,
          h: 1.22634,
          l: 1.22593,
          c: 1.22599,
          v: 933130000
        },
        {
          t: 52200,
          o: 1.22597,
          h: 1.22662,
          l: 1.22576,
          c: 1.22655,
          v: 1434269999
        },
        {
          t: 52500,
          o: 1.22655,
          h: 1.22667,
          l: 1.22602,
          c: 1.22654,
          v: 1258620000
        },
        {
          t: 52800,
          o: 1.22654,
          h: 1.22677,
          l: 1.22599,
          c: 1.22633,
          v: 1685870000
        },
        {
          t: 53100,
          o: 1.22633,
          h: 1.22696,
          l: 1.2263,
          c: 1.22669,
          v: 1645900000
        },
        {
          t: 53400,
          o: 1.22669,
          h: 1.22729,
          l: 1.22639,
          c: 1.22717,
          v: 1710409999
        },
        {
          t: 53700,
          o: 1.22717,
          h: 1.22747,
          l: 1.22711,
          c: 1.22726,
          v: 1856220000
        },
        {
          t: 54000,
          o: 1.2273,
          h: 1.22761,
          l: 1.22715,
          c: 1.22737,
          v: 1888559999
        },
        {
          t: 54300,
          o: 1.22736,
          h: 1.22754,
          l: 1.22726,
          c: 1.22743,
          v: 1193050000
        },
        {
          t: 54600,
          o: 1.22743,
          h: 1.22745,
          l: 1.2272,
          c: 1.22733,
          v: 1397060000
        },
        {
          t: 54900,
          o: 1.22733,
          h: 1.22734,
          l: 1.22684,
          c: 1.2269,
          v: 1581570000
        },
        {
          t: 55200,
          o: 1.22689,
          h: 1.22708,
          l: 1.22654,
          c: 1.22676,
          v: 1450710000
        },
        {
          t: 55500,
          o: 1.22674,
          h: 1.22718,
          l: 1.22666,
          c: 1.22689,
          v: 1648729999
        },
        {
          t: 55800,
          o: 1.2269,
          h: 1.22753,
          l: 1.22689,
          c: 1.22716,
          v: 1620780001
        },
        {
          t: 56100,
          o: 1.22714,
          h: 1.22733,
          l: 1.227,
          c: 1.227,
          v: 1437080001
        },
        {
          t: 56400,
          o: 1.22699,
          h: 1.22699,
          l: 1.22612,
          c: 1.22622,
          v: 1387990000
        },
        {
          t: 56700,
          o: 1.22616,
          h: 1.22627,
          l: 1.22603,
          c: 1.22617,
          v: 934090000
        },
        {
          t: 57000,
          o: 1.22618,
          h: 1.22647,
          l: 1.22606,
          c: 1.22612,
          v: 990750000
        },
        {
          t: 57300,
          o: 1.22613,
          h: 1.22631,
          l: 1.22587,
          c: 1.22608,
          v: 1093770000
        },
        {
          t: 57600,
          o: 1.22612,
          h: 1.22637,
          l: 1.22604,
          c: 1.22617,
          v: 854740000
        },
        {
          t: 57900,
          o: 1.22618,
          h: 1.22636,
          l: 1.22618,
          c: 1.22636,
          v: 708150000
        },
        {
          t: 58200,
          o: 1.22635,
          h: 1.2264,
          l: 1.22578,
          c: 1.22578,
          v: 901330000
        },
        {
          t: 58500,
          o: 1.22578,
          h: 1.22626,
          l: 1.22578,
          c: 1.2262,
          v: 1206720000
        },
        {
          t: 58800,
          o: 1.2262,
          h: 1.22633,
          l: 1.22609,
          c: 1.22612,
          v: 749300000
        },
        {
          t: 59100,
          o: 1.22612,
          h: 1.22688,
          l: 1.22592,
          c: 1.2268,
          v: 1312340000
        },
        {
          t: 59400,
          o: 1.2268,
          h: 1.2271,
          l: 1.22671,
          c: 1.22678,
          v: 1282560000
        },
        {
          t: 59700,
          o: 1.22677,
          h: 1.22694,
          l: 1.22666,
          c: 1.22687,
          v: 1197120000
        },
        {
          t: 60000,
          o: 1.22688,
          h: 1.22689,
          l: 1.22618,
          c: 1.22623,
          v: 832200000
        },
        {
          t: 60300,
          o: 1.22623,
          h: 1.22638,
          l: 1.22618,
          c: 1.22638,
          v: 532020000
        },
        {
          t: 60600,
          o: 1.22637,
          h: 1.22655,
          l: 1.2263,
          c: 1.22631,
          v: 571750000
        },
        {
          t: 60900,
          o: 1.2263,
          h: 1.22677,
          l: 1.2263,
          c: 1.22664,
          v: 1073100000
        },
        {
          t: 61200,
          o: 1.22666,
          h: 1.22667,
          l: 1.22641,
          c: 1.22652,
          v: 833520000
        },
        {
          t: 61500,
          o: 1.22651,
          h: 1.22672,
          l: 1.2264,
          c: 1.22667,
          v: 699320000
        },
        {
          t: 61800,
          o: 1.22667,
          h: 1.22686,
          l: 1.2265,
          c: 1.22656,
          v: 585200000
        },
        {
          t: 62100,
          o: 1.22654,
          h: 1.22682,
          l: 1.22651,
          c: 1.22675,
          v: 903040000
        },
        {
          t: 62400,
          o: 1.22675,
          h: 1.22684,
          l: 1.22626,
          c: 1.22683,
          v: 1035509999
        },
        {
          t: 62700,
          o: 1.22681,
          h: 1.22711,
          l: 1.22679,
          c: 1.22693,
          v: 1005770000
        },
        {
          t: 63000,
          o: 1.22696,
          h: 1.2271,
          l: 1.2268,
          c: 1.227,
          v: 853330000
        },
        {
          t: 63300,
          o: 1.227,
          h: 1.22738,
          l: 1.22694,
          c: 1.22732,
          v: 810510000
        },
        {
          t: 63600,
          o: 1.22731,
          h: 1.22738,
          l: 1.22688,
          c: 1.22688,
          v: 916680001
        },
        {
          t: 63900,
          o: 1.22689,
          h: 1.227,
          l: 1.22669,
          c: 1.22688,
          v: 940080000
        },
        {
          t: 64200,
          o: 1.22689,
          h: 1.22689,
          l: 1.22673,
          c: 1.22679,
          v: 551840000
        },
        {
          t: 64500,
          o: 1.22679,
          h: 1.22684,
          l: 1.22636,
          c: 1.22639,
          v: 939549999
        },
        {
          t: 64800,
          o: 1.22639,
          h: 1.22654,
          l: 1.22621,
          c: 1.2265,
          v: 936230000
        },
        {
          t: 65100,
          o: 1.22653,
          h: 1.22663,
          l: 1.2264,
          c: 1.2265,
          v: 623170000
        },
        {
          t: 65400,
          o: 1.2265,
          h: 1.22661,
          l: 1.22632,
          c: 1.22633,
          v: 833450000
        },
        {
          t: 65700,
          o: 1.22634,
          h: 1.22649,
          l: 1.22605,
          c: 1.22648,
          v: 1048429999
        },
        {
          t: 66000,
          o: 1.22648,
          h: 1.22651,
          l: 1.22623,
          c: 1.22644,
          v: 1049930001
        },
        {
          t: 66300,
          o: 1.22644,
          h: 1.22657,
          l: 1.22631,
          c: 1.22651,
          v: 855330000
        },
        {
          t: 66600,
          o: 1.22651,
          h: 1.22681,
          l: 1.22639,
          c: 1.22681,
          v: 984580000
        },
        {
          t: 66900,
          o: 1.22681,
          h: 1.22681,
          l: 1.22658,
          c: 1.22661,
          v: 754370000
        },
        {
          t: 67200,
          o: 1.22662,
          h: 1.22685,
          l: 1.2266,
          c: 1.22677,
          v: 526830000
        },
        {
          t: 67500,
          o: 1.22678,
          h: 1.22697,
          l: 1.2266,
          c: 1.22686,
          v: 1063480001
        },
        {
          t: 67800,
          o: 1.22686,
          h: 1.22695,
          l: 1.22664,
          c: 1.22685,
          v: 1112830000
        },
        {
          t: 68100,
          o: 1.22685,
          h: 1.22699,
          l: 1.2267,
          c: 1.22676,
          v: 1060430000
        },
        {
          t: 68400,
          o: 1.22675,
          h: 1.22677,
          l: 1.22649,
          c: 1.22664,
          v: 1031520000
        },
        {
          t: 68700,
          o: 1.22664,
          h: 1.22716,
          l: 1.22648,
          c: 1.22695,
          v: 1190790000
        },
        {
          t: 69000,
          o: 1.22694,
          h: 1.22715,
          l: 1.2269,
          c: 1.22704,
          v: 989930000
        },
        {
          t: 69300,
          o: 1.22703,
          h: 1.22705,
          l: 1.22673,
          c: 1.22695,
          v: 911650000
        },
        {
          t: 69600,
          o: 1.22695,
          h: 1.22717,
          l: 1.22683,
          c: 1.22707,
          v: 1164310000
        },
        {
          t: 69900,
          o: 1.22707,
          h: 1.2271,
          l: 1.22634,
          c: 1.22644,
          v: 1130300001
        },
        {
          t: 70200,
          o: 1.22647,
          h: 1.22658,
          l: 1.22623,
          c: 1.22657,
          v: 812380001
        },
        {
          t: 70500,
          o: 1.22657,
          h: 1.22658,
          l: 1.22619,
          c: 1.22634,
          v: 1310770000
        },
        {
          t: 70800,
          o: 1.22634,
          h: 1.22658,
          l: 1.22619,
          c: 1.2264,
          v: 991910000
        },
        {
          t: 71100,
          o: 1.22641,
          h: 1.22662,
          l: 1.22636,
          c: 1.22659,
          v: 849679999
        },
        {
          t: 71400,
          o: 1.22661,
          h: 1.22673,
          l: 1.22642,
          c: 1.2267,
          v: 600800000
        },
        {
          t: 71700,
          o: 1.22669,
          h: 1.22673,
          l: 1.22618,
          c: 1.22635,
          v: 802830000
        },
        {
          t: 72000,
          o: 1.22636,
          h: 1.22659,
          l: 1.22636,
          c: 1.22641,
          v: 452220000
        },
        {
          t: 72300,
          o: 1.22641,
          h: 1.22679,
          l: 1.22641,
          c: 1.22676,
          v: 390550000
        },
        {
          t: 72600,
          o: 1.22676,
          h: 1.22691,
          l: 1.22676,
          c: 1.22691,
          v: 409740000
        },
        {
          t: 72900,
          o: 1.2269,
          h: 1.22711,
          l: 1.2269,
          c: 1.22702,
          v: 338600000
        },
        {
          t: 73200,
          o: 1.22703,
          h: 1.22721,
          l: 1.22696,
          c: 1.22696,
          v: 470590001
        },
        {
          t: 73500,
          o: 1.22697,
          h: 1.22709,
          l: 1.22686,
          c: 1.22692,
          v: 542470000
        },
        {
          t: 73800,
          o: 1.22692,
          h: 1.2272,
          l: 1.22691,
          c: 1.22708,
          v: 388900000
        },
        {
          t: 74100,
          o: 1.22708,
          h: 1.22716,
          l: 1.22701,
          c: 1.22707,
          v: 297880000
        },
        {
          t: 74400,
          o: 1.22708,
          h: 1.22712,
          l: 1.22687,
          c: 1.22697,
          v: 310800000
        },
        {
          t: 74700,
          o: 1.22697,
          h: 1.22714,
          l: 1.22695,
          c: 1.22697,
          v: 408640000
        },
        {
          t: 75000,
          o: 1.22697,
          h: 1.22697,
          l: 1.22672,
          c: 1.22679,
          v: 298850000
        },
        {
          t: 75300,
          o: 1.22679,
          h: 1.22705,
          l: 1.22679,
          c: 1.22705,
          v: 214350000
        },
        {
          t: 75600,
          o: 1.22704,
          h: 1.22704,
          l: 1.22681,
          c: 1.22697,
          v: 45810000
        },
        {
          t: 75900,
          o: 1.22696,
          h: 1.22706,
          l: 1.22696,
          c: 1.227,
          v: 218490000
        },
        {
          t: 76200,
          o: 1.227,
          h: 1.22704,
          l: 1.22682,
          c: 1.22691,
          v: 410710000
        },
        {
          t: 76500,
          o: 1.22691,
          h: 1.22701,
          l: 1.2268,
          c: 1.22693,
          v: 310170000
        },
        {
          t: 76800,
          o: 1.22693,
          h: 1.22696,
          l: 1.22681,
          c: 1.22687,
          v: 300820000
        },
        {
          t: 77100,
          o: 1.22688,
          h: 1.22688,
          l: 1.2267,
          c: 1.22673,
          v: 269330000
        },
        {
          t: 77400,
          o: 1.22672,
          h: 1.22673,
          l: 1.22648,
          c: 1.22667,
          v: 105420000
        },
        {
          t: 77700,
          o: 1.22665,
          h: 1.22691,
          l: 1.22642,
          c: 1.22678,
          v: 407820000
        },
        {
          t: 78000,
          o: 1.22679,
          h: 1.22696,
          l: 1.22665,
          c: 1.22676,
          v: 235800000
        },
        {
          t: 78300,
          o: 1.22676,
          h: 1.22702,
          l: 1.22671,
          c: 1.22693,
          v: 279710000
        },
        {
          t: 78600,
          o: 1.22691,
          h: 1.227,
          l: 1.2268,
          c: 1.22685,
          v: 210040000
        },
        {
          t: 78900,
          o: 1.22686,
          h: 1.22702,
          l: 1.22677,
          c: 1.227,
          v: 272270000
        },
        {
          t: 79200,
          o: 1.22701,
          h: 1.22708,
          l: 1.22687,
          c: 1.22708,
          v: 127290000
        },
        {
          t: 79500,
          o: 1.22708,
          h: 1.22714,
          l: 1.22696,
          c: 1.22699,
          v: 189450000
        },
        {
          t: 79800,
          o: 1.22698,
          h: 1.22704,
          l: 1.22697,
          c: 1.22702,
          v: 137500000
        },
        {
          t: 80100,
          o: 1.22702,
          h: 1.22712,
          l: 1.227,
          c: 1.22712,
          v: 78410000
        },
        {
          t: 80400,
          o: 1.22712,
          h: 1.2272,
          l: 1.22706,
          c: 1.22718,
          v: 88130000
        },
        {
          t: 80700,
          o: 1.22718,
          h: 1.22719,
          l: 1.22717,
          c: 1.22718,
          v: 70810000
        },
        {
          t: 81000,
          o: 1.2272,
          h: 1.22721,
          l: 1.22716,
          c: 1.22717,
          v: 67440000
        },
        {
          t: 81300,
          o: 1.2272,
          h: 1.2272,
          l: 1.22711,
          c: 1.22712,
          v: 55370000
        },
        {
          t: 81600,
          o: 1.22712,
          h: 1.22715,
          l: 1.22709,
          c: 1.22714,
          v: 70760000
        },
        {
          t: 81900,
          o: 1.22714,
          h: 1.22715,
          l: 1.22708,
          c: 1.22713,
          v: 81320000
        },
        {
          t: 82200,
          o: 1.22715,
          h: 1.22725,
          l: 1.22713,
          c: 1.22722,
          v: 83850000
        },
        {
          t: 82500,
          o: 1.22723,
          h: 1.22728,
          l: 1.22722,
          c: 1.22723,
          v: 42890000
        },
        {
          t: 82800,
          o: 1.22724,
          h: 1.2273,
          l: 1.22717,
          c: 1.22717,
          v: 113950000
        },
        {
          t: 83100,
          o: 1.22717,
          h: 1.22717,
          l: 1.22706,
          c: 1.22706,
          v: 103010000
        },
        {
          t: 83400,
          o: 1.22706,
          h: 1.22721,
          l: 1.22706,
          c: 1.2272,
          v: 89130000
        },
        {
          t: 83700,
          o: 1.2272,
          h: 1.22721,
          l: 1.22691,
          c: 1.22691,
          v: 138370000
        },
        {
          t: 84000,
          o: 1.22692,
          h: 1.22696,
          l: 1.22686,
          c: 1.22692,
          v: 289350000
        },
        {
          t: 84300,
          o: 1.22694,
          h: 1.22705,
          l: 1.22691,
          c: 1.22702,
          v: 119060000
        },
        {
          t: 84600,
          o: 1.22702,
          h: 1.22722,
          l: 1.22702,
          c: 1.22721,
          v: 230270000
        },
        {
          t: 84900,
          o: 1.22722,
          h: 1.22726,
          l: 1.22719,
          c: 1.22725,
          v: 128180000
        },
        {
          t: 85200,
          o: 1.22725,
          h: 1.2273,
          l: 1.22722,
          c: 1.22726,
          v: 229320000
        },
        {
          t: 85500,
          o: 1.22726,
          h: 1.22755,
          l: 1.22722,
          c: 1.22748,
          v: 213520000
        },
        {
          t: 85800,
          o: 1.22747,
          h: 1.2278,
          l: 1.22743,
          c: 1.22777,
          v: 402410000
        },
        {
          t: 86100,
          o: 1.22777,
          h: 1.22781,
          l: 1.22774,
          c: 1.22781,
          v: 300800000
        }
      ]
    },
    {
      d: 1522800000,
      b: [
        {
          t: 0,
          o: 1.22781,
          h: 1.2279,
          l: 1.22751,
          c: 1.22764,
          v: 603800000
        },
        {
          t: 300,
          o: 1.22764,
          h: 1.22768,
          l: 1.22756,
          c: 1.2276,
          v: 274470000
        },
        {
          t: 600,
          o: 1.22758,
          h: 1.22773,
          l: 1.22735,
          c: 1.22748,
          v: 448500000
        },
        {
          t: 900,
          o: 1.22747,
          h: 1.22747,
          l: 1.22727,
          c: 1.22746,
          v: 351990000
        },
        {
          t: 1200,
          o: 1.22746,
          h: 1.22756,
          l: 1.22745,
          c: 1.22752,
          v: 302200000
        },
        {
          t: 1500,
          o: 1.22752,
          h: 1.22752,
          l: 1.22744,
          c: 1.22748,
          v: 340650000
        },
        {
          t: 1800,
          o: 1.22748,
          h: 1.22757,
          l: 1.22741,
          c: 1.22757,
          v: 210490000
        },
        {
          t: 2100,
          o: 1.22758,
          h: 1.22762,
          l: 1.22737,
          c: 1.22742,
          v: 358850000
        },
        {
          t: 2400,
          o: 1.22742,
          h: 1.22743,
          l: 1.22728,
          c: 1.22728,
          v: 212190000
        },
        {
          t: 2700,
          o: 1.22728,
          h: 1.22754,
          l: 1.22728,
          c: 1.22749,
          v: 275960000
        },
        {
          t: 3000,
          o: 1.22749,
          h: 1.22808,
          l: 1.22744,
          c: 1.22768,
          v: 606710000
        },
        {
          t: 3300,
          o: 1.22771,
          h: 1.22789,
          l: 1.22761,
          c: 1.22788,
          v: 515290000
        },
        {
          t: 3600,
          o: 1.22787,
          h: 1.22787,
          l: 1.22761,
          c: 1.2278,
          v: 452780000
        },
        {
          t: 3900,
          o: 1.22782,
          h: 1.2279,
          l: 1.22765,
          c: 1.22773,
          v: 479350000
        },
        {
          t: 4200,
          o: 1.22774,
          h: 1.22785,
          l: 1.22755,
          c: 1.22781,
          v: 706650001
        },
        {
          t: 4500,
          o: 1.22781,
          h: 1.22781,
          l: 1.22757,
          c: 1.22772,
          v: 530040000
        },
        {
          t: 4800,
          o: 1.22772,
          h: 1.22778,
          l: 1.22756,
          c: 1.2276,
          v: 532750000
        },
        {
          t: 5100,
          o: 1.22761,
          h: 1.22764,
          l: 1.22744,
          c: 1.22763,
          v: 408790000
        },
        {
          t: 5400,
          o: 1.22763,
          h: 1.22777,
          l: 1.22742,
          c: 1.22742,
          v: 420380000
        },
        {
          t: 5700,
          o: 1.22742,
          h: 1.22762,
          l: 1.22741,
          c: 1.22758,
          v: 423390000
        },
        {
          t: 6000,
          o: 1.2276,
          h: 1.22765,
          l: 1.22749,
          c: 1.22756,
          v: 442970000
        },
        {
          t: 6300,
          o: 1.22756,
          h: 1.22786,
          l: 1.22756,
          c: 1.22781,
          v: 554570000
        },
        {
          t: 6600,
          o: 1.22781,
          h: 1.22789,
          l: 1.2277,
          c: 1.22778,
          v: 544850000
        },
        {
          t: 6900,
          o: 1.22777,
          h: 1.22782,
          l: 1.2277,
          c: 1.22779,
          v: 418280000
        },
        {
          t: 7200,
          o: 1.22778,
          h: 1.22806,
          l: 1.22776,
          c: 1.22801,
          v: 597090000
        },
        {
          t: 7500,
          o: 1.22801,
          h: 1.22834,
          l: 1.2278,
          c: 1.22834,
          v: 623100000
        },
        {
          t: 7800,
          o: 1.22832,
          h: 1.2287,
          l: 1.22827,
          c: 1.22861,
          v: 732260000
        },
        {
          t: 8100,
          o: 1.22861,
          h: 1.22865,
          l: 1.22837,
          c: 1.22857,
          v: 432310000
        },
        {
          t: 8400,
          o: 1.22856,
          h: 1.22862,
          l: 1.2283,
          c: 1.22834,
          v: 469160000
        },
        {
          t: 8700,
          o: 1.22838,
          h: 1.22841,
          l: 1.22808,
          c: 1.22811,
          v: 284490000
        },
        {
          t: 9000,
          o: 1.2281,
          h: 1.22814,
          l: 1.22794,
          c: 1.228,
          v: 541790000
        },
        {
          t: 9300,
          o: 1.228,
          h: 1.22811,
          l: 1.22795,
          c: 1.22808,
          v: 394790000
        },
        {
          t: 9600,
          o: 1.22807,
          h: 1.22823,
          l: 1.22807,
          c: 1.22818,
          v: 492690000
        },
        {
          t: 9900,
          o: 1.22818,
          h: 1.22818,
          l: 1.22806,
          c: 1.22812,
          v: 425750000
        },
        {
          t: 10200,
          o: 1.22811,
          h: 1.22812,
          l: 1.22801,
          c: 1.22806,
          v: 198500000
        },
        {
          t: 10500,
          o: 1.22805,
          h: 1.22822,
          l: 1.22805,
          c: 1.22812,
          v: 385390000
        },
        {
          t: 10800,
          o: 1.22812,
          h: 1.22815,
          l: 1.2279,
          c: 1.22796,
          v: 391690000
        },
        {
          t: 11100,
          o: 1.22796,
          h: 1.22802,
          l: 1.22782,
          c: 1.22786,
          v: 264310000
        },
        {
          t: 11400,
          o: 1.22787,
          h: 1.22806,
          l: 1.22786,
          c: 1.22792,
          v: 286540000
        },
        {
          t: 11700,
          o: 1.22792,
          h: 1.22792,
          l: 1.22761,
          c: 1.22765,
          v: 406400000
        },
        {
          t: 12000,
          o: 1.22764,
          h: 1.22775,
          l: 1.22751,
          c: 1.22751,
          v: 320710000
        },
        {
          t: 12300,
          o: 1.22754,
          h: 1.22764,
          l: 1.22751,
          c: 1.22762,
          v: 241490000
        },
        {
          t: 12600,
          o: 1.22762,
          h: 1.22791,
          l: 1.22761,
          c: 1.2279,
          v: 377590000
        },
        {
          t: 12900,
          o: 1.2279,
          h: 1.22815,
          l: 1.2279,
          c: 1.22813,
          v: 469120000
        },
        {
          t: 13200,
          o: 1.22813,
          h: 1.22831,
          l: 1.22808,
          c: 1.22823,
          v: 383630000
        },
        {
          t: 13500,
          o: 1.22823,
          h: 1.22823,
          l: 1.22807,
          c: 1.22808,
          v: 318220000
        },
        {
          t: 13800,
          o: 1.2281,
          h: 1.22823,
          l: 1.2281,
          c: 1.22813,
          v: 208820000
        },
        {
          t: 14100,
          o: 1.22813,
          h: 1.22835,
          l: 1.22813,
          c: 1.22823,
          v: 325960000
        },
        {
          t: 14400,
          o: 1.22822,
          h: 1.22823,
          l: 1.22809,
          c: 1.22813,
          v: 330450000
        },
        {
          t: 14700,
          o: 1.22813,
          h: 1.22822,
          l: 1.22812,
          c: 1.22812,
          v: 301890000
        },
        {
          t: 15000,
          o: 1.22812,
          h: 1.22814,
          l: 1.22811,
          c: 1.22813,
          v: 220110000
        },
        {
          t: 15300,
          o: 1.22814,
          h: 1.2284,
          l: 1.22812,
          c: 1.22837,
          v: 442430000
        },
        {
          t: 15600,
          o: 1.22837,
          h: 1.22838,
          l: 1.22811,
          c: 1.22822,
          v: 332800000
        },
        {
          t: 15900,
          o: 1.22822,
          h: 1.22828,
          l: 1.22822,
          c: 1.22822,
          v: 242200000
        },
        {
          t: 16200,
          o: 1.22823,
          h: 1.22828,
          l: 1.22811,
          c: 1.22812,
          v: 284070000
        },
        {
          t: 16500,
          o: 1.22811,
          h: 1.22832,
          l: 1.22807,
          c: 1.22821,
          v: 464600000
        },
        {
          t: 16800,
          o: 1.22821,
          h: 1.22825,
          l: 1.22811,
          c: 1.22812,
          v: 137100000
        },
        {
          t: 17100,
          o: 1.22813,
          h: 1.22838,
          l: 1.22813,
          c: 1.22822,
          v: 370760000
        },
        {
          t: 17400,
          o: 1.22822,
          h: 1.22823,
          l: 1.22807,
          c: 1.2281,
          v: 357750000
        },
        {
          t: 17700,
          o: 1.2281,
          h: 1.22813,
          l: 1.22781,
          c: 1.22781,
          v: 433190000
        },
        {
          t: 18000,
          o: 1.22781,
          h: 1.22781,
          l: 1.22754,
          c: 1.22772,
          v: 436520000
        },
        {
          t: 18300,
          o: 1.22772,
          h: 1.22813,
          l: 1.22771,
          c: 1.22811,
          v: 556740000
        },
        {
          t: 18600,
          o: 1.22811,
          h: 1.22813,
          l: 1.22767,
          c: 1.22771,
          v: 572700000
        },
        {
          t: 18900,
          o: 1.22771,
          h: 1.22791,
          l: 1.2276,
          c: 1.22767,
          v: 443560000
        },
        {
          t: 19200,
          o: 1.22767,
          h: 1.22768,
          l: 1.22757,
          c: 1.22761,
          v: 137580000
        },
        {
          t: 19500,
          o: 1.22764,
          h: 1.22767,
          l: 1.22741,
          c: 1.22745,
          v: 406690000
        },
        {
          t: 19800,
          o: 1.22744,
          h: 1.22747,
          l: 1.22726,
          c: 1.22747,
          v: 537130000
        },
        {
          t: 20100,
          o: 1.22748,
          h: 1.2277,
          l: 1.22746,
          c: 1.22768,
          v: 308380000
        },
        {
          t: 20400,
          o: 1.22769,
          h: 1.22769,
          l: 1.22742,
          c: 1.22747,
          v: 330680000
        },
        {
          t: 20700,
          o: 1.22747,
          h: 1.22772,
          l: 1.22743,
          c: 1.22766,
          v: 485820000
        },
        {
          t: 21000,
          o: 1.22766,
          h: 1.22778,
          l: 1.22756,
          c: 1.22778,
          v: 306430000
        },
        {
          t: 21300,
          o: 1.22776,
          h: 1.22796,
          l: 1.22775,
          c: 1.22781,
          v: 410080000
        },
        {
          t: 21600,
          o: 1.22781,
          h: 1.22781,
          l: 1.22691,
          c: 1.22704,
          v: 767000000
        },
        {
          t: 21900,
          o: 1.22706,
          h: 1.22731,
          l: 1.22702,
          c: 1.22728,
          v: 778380000
        },
        {
          t: 22200,
          o: 1.22728,
          h: 1.22732,
          l: 1.22686,
          c: 1.22723,
          v: 765290000
        },
        {
          t: 22500,
          o: 1.22724,
          h: 1.22725,
          l: 1.22691,
          c: 1.22691,
          v: 865990000
        },
        {
          t: 22800,
          o: 1.22691,
          h: 1.22725,
          l: 1.22674,
          c: 1.22685,
          v: 925670000
        },
        {
          t: 23100,
          o: 1.22686,
          h: 1.22697,
          l: 1.22652,
          c: 1.22674,
          v: 927600000
        },
        {
          t: 23400,
          o: 1.22674,
          h: 1.2272,
          l: 1.22674,
          c: 1.22703,
          v: 839530000
        },
        {
          t: 23700,
          o: 1.22704,
          h: 1.22745,
          l: 1.22702,
          c: 1.22745,
          v: 707790000
        },
        {
          t: 24000,
          o: 1.22744,
          h: 1.22744,
          l: 1.22687,
          c: 1.22693,
          v: 950390000
        },
        {
          t: 24300,
          o: 1.22692,
          h: 1.22734,
          l: 1.22684,
          c: 1.22709,
          v: 751750000
        },
        {
          t: 24600,
          o: 1.22709,
          h: 1.22738,
          l: 1.22702,
          c: 1.22703,
          v: 862610000
        },
        {
          t: 24900,
          o: 1.22703,
          h: 1.22704,
          l: 1.22632,
          c: 1.22658,
          v: 1172050000
        },
        {
          t: 25200,
          o: 1.22663,
          h: 1.22675,
          l: 1.22595,
          c: 1.22651,
          v: 1406410001
        },
        {
          t: 25500,
          o: 1.22652,
          h: 1.22716,
          l: 1.22651,
          c: 1.22697,
          v: 1488160001
        },
        {
          t: 25800,
          o: 1.22698,
          h: 1.22732,
          l: 1.22679,
          c: 1.22715,
          v: 1312880000
        },
        {
          t: 26100,
          o: 1.22716,
          h: 1.2272,
          l: 1.22638,
          c: 1.22639,
          v: 1320130000
        },
        {
          t: 26400,
          o: 1.22638,
          h: 1.22643,
          l: 1.2257,
          c: 1.22575,
          v: 1356990000
        },
        {
          t: 26700,
          o: 1.22577,
          h: 1.22605,
          l: 1.22575,
          c: 1.22584,
          v: 1083620000
        },
        {
          t: 27000,
          o: 1.22586,
          h: 1.22688,
          l: 1.22582,
          c: 1.22672,
          v: 1118890000
        },
        {
          t: 27300,
          o: 1.22672,
          h: 1.22695,
          l: 1.22648,
          c: 1.22691,
          v: 1119740000
        },
        {
          t: 27600,
          o: 1.2269,
          h: 1.22805,
          l: 1.22683,
          c: 1.22773,
          v: 1477710000
        },
        {
          t: 27900,
          o: 1.22771,
          h: 1.22798,
          l: 1.22735,
          c: 1.22756,
          v: 1672320000
        },
        {
          t: 28200,
          o: 1.22756,
          h: 1.22849,
          l: 1.22754,
          c: 1.22844,
          v: 1694880000
        },
        {
          t: 28500,
          o: 1.22844,
          h: 1.22949,
          l: 1.22844,
          c: 1.22946,
          v: 1799550000
        },
        {
          t: 28800,
          o: 1.22946,
          h: 1.2295,
          l: 1.22914,
          c: 1.2292,
          v: 1538160000
        },
        {
          t: 29100,
          o: 1.22919,
          h: 1.2292,
          l: 1.22879,
          c: 1.22904,
          v: 1458900000
        },
        {
          t: 29400,
          o: 1.22904,
          h: 1.23145,
          l: 1.22898,
          c: 1.23057,
          v: 2638550000
        },
        {
          t: 29700,
          o: 1.23057,
          h: 1.23093,
          l: 1.23029,
          c: 1.23051,
          v: 2000510000
        },
        {
          t: 30000,
          o: 1.23052,
          h: 1.23106,
          l: 1.23035,
          c: 1.23069,
          v: 1486920001
        },
        {
          t: 30300,
          o: 1.23068,
          h: 1.23074,
          l: 1.23033,
          c: 1.23035,
          v: 1001800000
        },
        {
          t: 30600,
          o: 1.23033,
          h: 1.23047,
          l: 1.2298,
          c: 1.22992,
          v: 1458650000
        },
        {
          t: 30900,
          o: 1.22991,
          h: 1.23036,
          l: 1.22962,
          c: 1.22974,
          v: 1456240000
        },
        {
          t: 31200,
          o: 1.22974,
          h: 1.22982,
          l: 1.22951,
          c: 1.22972,
          v: 1353200000
        },
        {
          t: 31500,
          o: 1.22972,
          h: 1.2299,
          l: 1.22953,
          c: 1.22961,
          v: 1113120001
        },
        {
          t: 31800,
          o: 1.2296,
          h: 1.22969,
          l: 1.22919,
          c: 1.2293,
          v: 1259030000
        },
        {
          t: 32100,
          o: 1.2293,
          h: 1.2294,
          l: 1.22899,
          c: 1.22916,
          v: 793180000
        },
        {
          t: 32400,
          o: 1.22915,
          h: 1.22929,
          l: 1.22887,
          c: 1.22918,
          v: 1105680000
        },
        {
          t: 32700,
          o: 1.22917,
          h: 1.22944,
          l: 1.22911,
          c: 1.22918,
          v: 1010470000
        },
        {
          t: 33000,
          o: 1.22917,
          h: 1.22928,
          l: 1.22901,
          c: 1.22917,
          v: 791710000
        },
        {
          t: 33300,
          o: 1.22918,
          h: 1.22966,
          l: 1.22918,
          c: 1.22963,
          v: 1027510000
        },
        {
          t: 33600,
          o: 1.22963,
          h: 1.22965,
          l: 1.22933,
          c: 1.22938,
          v: 1102440000
        },
        {
          t: 33900,
          o: 1.22937,
          h: 1.22965,
          l: 1.22911,
          c: 1.22927,
          v: 906280000
        },
        {
          t: 34200,
          o: 1.22925,
          h: 1.22928,
          l: 1.22879,
          c: 1.22891,
          v: 964950000
        },
        {
          t: 34500,
          o: 1.22891,
          h: 1.22907,
          l: 1.22891,
          c: 1.22895,
          v: 796260000
        },
        {
          t: 34800,
          o: 1.22895,
          h: 1.22908,
          l: 1.2289,
          c: 1.22907,
          v: 635490000
        },
        {
          t: 35100,
          o: 1.22906,
          h: 1.22916,
          l: 1.22887,
          c: 1.22891,
          v: 779740001
        },
        {
          t: 35400,
          o: 1.22892,
          h: 1.22894,
          l: 1.22837,
          c: 1.22845,
          v: 925620000
        },
        {
          t: 35700,
          o: 1.22844,
          h: 1.22847,
          l: 1.22762,
          c: 1.22772,
          v: 1385830000
        },
        {
          t: 36000,
          o: 1.22772,
          h: 1.22806,
          l: 1.22758,
          c: 1.22802,
          v: 1206010000
        },
        {
          t: 36300,
          o: 1.22802,
          h: 1.22838,
          l: 1.22802,
          c: 1.22817,
          v: 956700000
        },
        {
          t: 36600,
          o: 1.22817,
          h: 1.2282,
          l: 1.22805,
          c: 1.2282,
          v: 784140000
        },
        {
          t: 36900,
          o: 1.22818,
          h: 1.22858,
          l: 1.22791,
          c: 1.22858,
          v: 1014670000
        },
        {
          t: 37200,
          o: 1.2286,
          h: 1.22871,
          l: 1.22805,
          c: 1.2281,
          v: 1124270000
        },
        {
          t: 37500,
          o: 1.22807,
          h: 1.2283,
          l: 1.22768,
          c: 1.22828,
          v: 1286420000
        },
        {
          t: 37800,
          o: 1.22827,
          h: 1.22847,
          l: 1.22817,
          c: 1.22838,
          v: 845850000
        },
        {
          t: 38100,
          o: 1.22841,
          h: 1.22841,
          l: 1.22776,
          c: 1.22782,
          v: 1284500000
        },
        {
          t: 38400,
          o: 1.22781,
          h: 1.228,
          l: 1.22761,
          c: 1.22796,
          v: 1307230000
        },
        {
          t: 38700,
          o: 1.22794,
          h: 1.22816,
          l: 1.22771,
          c: 1.2278,
          v: 971250000
        },
        {
          t: 39000,
          o: 1.2278,
          h: 1.2281,
          l: 1.2277,
          c: 1.22795,
          v: 1033670000
        },
        {
          t: 39300,
          o: 1.22796,
          h: 1.22854,
          l: 1.22791,
          c: 1.22848,
          v: 1292839999
        },
        {
          t: 39600,
          o: 1.22847,
          h: 1.22856,
          l: 1.22823,
          c: 1.22856,
          v: 1048060000
        },
        {
          t: 39900,
          o: 1.22855,
          h: 1.22924,
          l: 1.22849,
          c: 1.22913,
          v: 1132770000
        },
        {
          t: 40200,
          o: 1.22914,
          h: 1.22925,
          l: 1.22892,
          c: 1.2292,
          v: 1375930000
        },
        {
          t: 40500,
          o: 1.22921,
          h: 1.22923,
          l: 1.22873,
          c: 1.22873,
          v: 1238890000
        },
        {
          t: 40800,
          o: 1.22874,
          h: 1.22889,
          l: 1.22868,
          c: 1.22887,
          v: 752620000
        },
        {
          t: 41100,
          o: 1.22887,
          h: 1.22899,
          l: 1.22865,
          c: 1.22869,
          v: 904050000
        },
        {
          t: 41400,
          o: 1.2287,
          h: 1.22876,
          l: 1.22817,
          c: 1.2282,
          v: 1087259999
        },
        {
          t: 41700,
          o: 1.22821,
          h: 1.22864,
          l: 1.22821,
          c: 1.22846,
          v: 1001140000
        },
        {
          t: 42000,
          o: 1.22847,
          h: 1.22857,
          l: 1.22834,
          c: 1.22846,
          v: 999720000
        },
        {
          t: 42300,
          o: 1.22846,
          h: 1.22858,
          l: 1.22828,
          c: 1.22835,
          v: 795680000
        },
        {
          t: 42600,
          o: 1.22835,
          h: 1.22896,
          l: 1.2283,
          c: 1.22868,
          v: 1297989999
        },
        {
          t: 42900,
          o: 1.22868,
          h: 1.22897,
          l: 1.22861,
          c: 1.2288,
          v: 1276310000
        },
        {
          t: 43200,
          o: 1.22882,
          h: 1.22893,
          l: 1.22774,
          c: 1.22784,
          v: 1450570001
        },
        {
          t: 43500,
          o: 1.22786,
          h: 1.22789,
          l: 1.22757,
          c: 1.22787,
          v: 1131929999
        },
        {
          t: 43800,
          o: 1.22786,
          h: 1.22786,
          l: 1.22723,
          c: 1.22753,
          v: 1073620000
        },
        {
          t: 44100,
          o: 1.22765,
          h: 1.22765,
          l: 1.22708,
          c: 1.22743,
          v: 1489330000
        },
        {
          t: 44400,
          o: 1.22745,
          h: 1.22778,
          l: 1.22741,
          c: 1.22752,
          v: 1616860000
        },
        {
          t: 44700,
          o: 1.22751,
          h: 1.22779,
          l: 1.22738,
          c: 1.22755,
          v: 1149450000
        },
        {
          t: 45000,
          o: 1.22754,
          h: 1.22801,
          l: 1.2274,
          c: 1.22788,
          v: 1216470000
        },
        {
          t: 45300,
          o: 1.22789,
          h: 1.22874,
          l: 1.22789,
          c: 1.22838,
          v: 1198150000
        },
        {
          t: 45600,
          o: 1.22838,
          h: 1.22894,
          l: 1.22835,
          c: 1.22888,
          v: 1029890000
        },
        {
          t: 45900,
          o: 1.22888,
          h: 1.22971,
          l: 1.22879,
          c: 1.22971,
          v: 1051890000
        },
        {
          t: 46200,
          o: 1.22974,
          h: 1.22987,
          l: 1.22932,
          c: 1.22985,
          v: 1596230000
        },
        {
          t: 46500,
          o: 1.22985,
          h: 1.23003,
          l: 1.22958,
          c: 1.22965,
          v: 1103750000
        },
        {
          t: 46800,
          o: 1.22965,
          h: 1.23001,
          l: 1.2296,
          c: 1.22992,
          v: 1764079999
        },
        {
          t: 47100,
          o: 1.2299,
          h: 1.22993,
          l: 1.22967,
          c: 1.22979,
          v: 1077970000
        },
        {
          t: 47400,
          o: 1.22982,
          h: 1.22984,
          l: 1.22946,
          c: 1.22952,
          v: 1384030000
        },
        {
          t: 47700,
          o: 1.22951,
          h: 1.22956,
          l: 1.22909,
          c: 1.2291,
          v: 1215050000
        },
        {
          t: 48000,
          o: 1.22911,
          h: 1.22937,
          l: 1.22902,
          c: 1.22934,
          v: 1169030000
        },
        {
          t: 48300,
          o: 1.22934,
          h: 1.22943,
          l: 1.22879,
          c: 1.22892,
          v: 1135310000
        },
        {
          t: 48600,
          o: 1.22902,
          h: 1.22972,
          l: 1.22902,
          c: 1.22959,
          v: 1865360000
        },
        {
          t: 48900,
          o: 1.22966,
          h: 1.22972,
          l: 1.22918,
          c: 1.22928,
          v: 1523499999
        },
        {
          t: 49200,
          o: 1.22928,
          h: 1.22945,
          l: 1.22907,
          c: 1.22942,
          v: 1755549998
        },
        {
          t: 49500,
          o: 1.22945,
          h: 1.22979,
          l: 1.2294,
          c: 1.22962,
          v: 1522529999
        },
        {
          t: 49800,
          o: 1.22967,
          h: 1.22972,
          l: 1.22913,
          c: 1.22944,
          v: 1509560000
        },
        {
          t: 50100,
          o: 1.22947,
          h: 1.22989,
          l: 1.22947,
          c: 1.22966,
          v: 1310310000
        },
        {
          t: 50400,
          o: 1.22975,
          h: 1.23037,
          l: 1.22955,
          c: 1.23019,
          v: 1964219999
        },
        {
          t: 50700,
          o: 1.23024,
          h: 1.23074,
          l: 1.23017,
          c: 1.23041,
          v: 1840460000
        },
        {
          t: 51000,
          o: 1.23045,
          h: 1.23072,
          l: 1.23011,
          c: 1.23071,
          v: 1551720000
        },
        {
          t: 51300,
          o: 1.2308,
          h: 1.23097,
          l: 1.23035,
          c: 1.23036,
          v: 1511700000
        },
        {
          t: 51600,
          o: 1.23041,
          h: 1.23078,
          l: 1.23031,
          c: 1.23041,
          v: 1231680000
        },
        {
          t: 51900,
          o: 1.23047,
          h: 1.23065,
          l: 1.23027,
          c: 1.23054,
          v: 1582110000
        },
        {
          t: 52200,
          o: 1.23053,
          h: 1.23098,
          l: 1.23051,
          c: 1.23079,
          v: 1848430000
        },
        {
          t: 52500,
          o: 1.23082,
          h: 1.23086,
          l: 1.23012,
          c: 1.23019,
          v: 1327800001
        },
        {
          t: 52800,
          o: 1.23019,
          h: 1.23037,
          l: 1.22991,
          c: 1.23001,
          v: 1128540000
        },
        {
          t: 53100,
          o: 1.23001,
          h: 1.23008,
          l: 1.22905,
          c: 1.22913,
          v: 1655140001
        },
        {
          t: 53400,
          o: 1.22912,
          h: 1.22946,
          l: 1.22894,
          c: 1.22916,
          v: 1534490000
        },
        {
          t: 53700,
          o: 1.22916,
          h: 1.22987,
          l: 1.22896,
          c: 1.22982,
          v: 1617960000
        },
        {
          t: 54000,
          o: 1.22983,
          h: 1.23015,
          l: 1.22977,
          c: 1.23003,
          v: 1826190000
        },
        {
          t: 54300,
          o: 1.23003,
          h: 1.23017,
          l: 1.22971,
          c: 1.23017,
          v: 1514300000
        },
        {
          t: 54600,
          o: 1.23016,
          h: 1.23016,
          l: 1.22953,
          c: 1.22968,
          v: 1511420000
        },
        {
          t: 54900,
          o: 1.22969,
          h: 1.22985,
          l: 1.22948,
          c: 1.22982,
          v: 1287260000
        },
        {
          t: 55200,
          o: 1.22982,
          h: 1.23036,
          l: 1.22982,
          c: 1.23028,
          v: 1680459998
        },
        {
          t: 55500,
          o: 1.23028,
          h: 1.23034,
          l: 1.22986,
          c: 1.22991,
          v: 1509299999
        },
        {
          t: 55800,
          o: 1.22994,
          h: 1.23012,
          l: 1.22976,
          c: 1.22986,
          v: 1535830000
        },
        {
          t: 56100,
          o: 1.22985,
          h: 1.22991,
          l: 1.22929,
          c: 1.22946,
          v: 1191460000
        },
        {
          t: 56400,
          o: 1.22946,
          h: 1.22965,
          l: 1.2294,
          c: 1.22958,
          v: 808600000
        },
        {
          t: 56700,
          o: 1.22958,
          h: 1.22962,
          l: 1.22929,
          c: 1.22949,
          v: 1073050000
        },
        {
          t: 57000,
          o: 1.2295,
          h: 1.22956,
          l: 1.22931,
          c: 1.22937,
          v: 1175549999
        },
        {
          t: 57300,
          o: 1.22937,
          h: 1.22952,
          l: 1.229,
          c: 1.2291,
          v: 804370001
        },
        {
          t: 57600,
          o: 1.22912,
          h: 1.2292,
          l: 1.22869,
          c: 1.2291,
          v: 1183660000
        },
        {
          t: 57900,
          o: 1.22909,
          h: 1.22934,
          l: 1.22905,
          c: 1.22929,
          v: 1192090000
        },
        {
          t: 58200,
          o: 1.22929,
          h: 1.22946,
          l: 1.22913,
          c: 1.22936,
          v: 1249360001
        },
        {
          t: 58500,
          o: 1.22937,
          h: 1.22959,
          l: 1.2293,
          c: 1.22956,
          v: 900460000
        },
        {
          t: 58800,
          o: 1.22955,
          h: 1.22973,
          l: 1.22951,
          c: 1.22967,
          v: 846120000
        },
        {
          t: 59100,
          o: 1.22967,
          h: 1.22975,
          l: 1.22958,
          c: 1.22965,
          v: 1000940002
        },
        {
          t: 59400,
          o: 1.22967,
          h: 1.22967,
          l: 1.22949,
          c: 1.22962,
          v: 767450000
        },
        {
          t: 59700,
          o: 1.22962,
          h: 1.22976,
          l: 1.22956,
          c: 1.22969,
          v: 797490000
        },
        {
          t: 60000,
          o: 1.2297,
          h: 1.22975,
          l: 1.22959,
          c: 1.2297,
          v: 522480000
        },
        {
          t: 60300,
          o: 1.2297,
          h: 1.22976,
          l: 1.22961,
          c: 1.22967,
          v: 819470000
        },
        {
          t: 60600,
          o: 1.22967,
          h: 1.22967,
          l: 1.22935,
          c: 1.22936,
          v: 938300000
        },
        {
          t: 60900,
          o: 1.22936,
          h: 1.22951,
          l: 1.22919,
          c: 1.22939,
          v: 1084489999
        },
        {
          t: 61200,
          o: 1.2294,
          h: 1.22943,
          l: 1.22908,
          c: 1.22908,
          v: 963410000
        },
        {
          t: 61500,
          o: 1.22907,
          h: 1.22907,
          l: 1.22874,
          c: 1.22879,
          v: 876620000
        },
        {
          t: 61800,
          o: 1.2288,
          h: 1.22892,
          l: 1.2288,
          c: 1.22891,
          v: 689840000
        },
        {
          t: 62100,
          o: 1.2289,
          h: 1.2289,
          l: 1.22844,
          c: 1.22844,
          v: 898080001
        },
        {
          t: 62400,
          o: 1.22846,
          h: 1.2285,
          l: 1.22824,
          c: 1.22837,
          v: 1237259999
        },
        {
          t: 62700,
          o: 1.22837,
          h: 1.2285,
          l: 1.22825,
          c: 1.22841,
          v: 995910000
        },
        {
          t: 63000,
          o: 1.22841,
          h: 1.2286,
          l: 1.22835,
          c: 1.22841,
          v: 782360000
        },
        {
          t: 63300,
          o: 1.22842,
          h: 1.22867,
          l: 1.22826,
          c: 1.2286,
          v: 677880000
        },
        {
          t: 63600,
          o: 1.2286,
          h: 1.2287,
          l: 1.22843,
          c: 1.22865,
          v: 825490000
        },
        {
          t: 63900,
          o: 1.22863,
          h: 1.22886,
          l: 1.22855,
          c: 1.2288,
          v: 785730000
        },
        {
          t: 64200,
          o: 1.2288,
          h: 1.22884,
          l: 1.22865,
          c: 1.22865,
          v: 714230000
        },
        {
          t: 64500,
          o: 1.22867,
          h: 1.22871,
          l: 1.22823,
          c: 1.22831,
          v: 769600000
        },
        {
          t: 64800,
          o: 1.2283,
          h: 1.2283,
          l: 1.22788,
          c: 1.22807,
          v: 990640000
        },
        {
          t: 65100,
          o: 1.22806,
          h: 1.2285,
          l: 1.22801,
          c: 1.22837,
          v: 1228139999
        },
        {
          t: 65400,
          o: 1.22838,
          h: 1.22846,
          l: 1.22814,
          c: 1.22819,
          v: 1151600001
        },
        {
          t: 65700,
          o: 1.22819,
          h: 1.22831,
          l: 1.228,
          c: 1.2281,
          v: 971220000
        },
        {
          t: 66000,
          o: 1.22814,
          h: 1.22827,
          l: 1.22804,
          c: 1.22827,
          v: 754480000
        },
        {
          t: 66300,
          o: 1.22827,
          h: 1.22839,
          l: 1.22741,
          c: 1.22751,
          v: 1312690000
        },
        {
          t: 66600,
          o: 1.2275,
          h: 1.22791,
          l: 1.22737,
          c: 1.22787,
          v: 1095380000
        },
        {
          t: 66900,
          o: 1.22787,
          h: 1.22794,
          l: 1.22768,
          c: 1.22789,
          v: 892010000
        },
        {
          t: 67200,
          o: 1.2279,
          h: 1.22801,
          l: 1.22788,
          c: 1.22795,
          v: 785400000
        },
        {
          t: 67500,
          o: 1.22795,
          h: 1.22824,
          l: 1.22795,
          c: 1.22808,
          v: 791460000
        },
        {
          t: 67800,
          o: 1.22807,
          h: 1.22822,
          l: 1.22801,
          c: 1.22809,
          v: 803460000
        },
        {
          t: 68100,
          o: 1.22811,
          h: 1.22823,
          l: 1.22798,
          c: 1.22798,
          v: 851519999
        },
        {
          t: 68400,
          o: 1.22798,
          h: 1.22832,
          l: 1.22794,
          c: 1.22823,
          v: 927160001
        },
        {
          t: 68700,
          o: 1.22824,
          h: 1.22841,
          l: 1.22818,
          c: 1.22833,
          v: 1055590000
        },
        {
          t: 69000,
          o: 1.22834,
          h: 1.22838,
          l: 1.22819,
          c: 1.22823,
          v: 820850001
        },
        {
          t: 69300,
          o: 1.22823,
          h: 1.22827,
          l: 1.22807,
          c: 1.22807,
          v: 472460000
        },
        {
          t: 69600,
          o: 1.22807,
          h: 1.22812,
          l: 1.22776,
          c: 1.22794,
          v: 921490000
        },
        {
          t: 69900,
          o: 1.22794,
          h: 1.22812,
          l: 1.22777,
          c: 1.2278,
          v: 1169400000
        },
        {
          t: 70200,
          o: 1.2278,
          h: 1.22803,
          l: 1.22777,
          c: 1.22792,
          v: 505120000
        },
        {
          t: 70500,
          o: 1.22791,
          h: 1.22793,
          l: 1.22771,
          c: 1.22787,
          v: 870500000
        },
        {
          t: 70800,
          o: 1.22789,
          h: 1.22802,
          l: 1.22761,
          c: 1.22761,
          v: 980040000
        },
        {
          t: 71100,
          o: 1.22761,
          h: 1.22763,
          l: 1.22742,
          c: 1.22755,
          v: 832350000
        },
        {
          t: 71400,
          o: 1.22755,
          h: 1.22812,
          l: 1.22754,
          c: 1.22799,
          v: 801780000
        },
        {
          t: 71700,
          o: 1.22799,
          h: 1.22833,
          l: 1.22797,
          c: 1.22826,
          v: 1182130000
        },
        {
          t: 72000,
          o: 1.22827,
          h: 1.22827,
          l: 1.22795,
          c: 1.22806,
          v: 615120000
        },
        {
          t: 72300,
          o: 1.22806,
          h: 1.22808,
          l: 1.22786,
          c: 1.22786,
          v: 333130000
        },
        {
          t: 72600,
          o: 1.22785,
          h: 1.22787,
          l: 1.22775,
          c: 1.22776,
          v: 323880000
        },
        {
          t: 72900,
          o: 1.22775,
          h: 1.22787,
          l: 1.2277,
          c: 1.22772,
          v: 323410000
        },
        {
          t: 73200,
          o: 1.22771,
          h: 1.2278,
          l: 1.2277,
          c: 1.2277,
          v: 337280000
        },
        {
          t: 73500,
          o: 1.2277,
          h: 1.2279,
          l: 1.22763,
          c: 1.22778,
          v: 274090000
        },
        {
          t: 73800,
          o: 1.22778,
          h: 1.22803,
          l: 1.22778,
          c: 1.22792,
          v: 435130000
        },
        {
          t: 74100,
          o: 1.22794,
          h: 1.22805,
          l: 1.22792,
          c: 1.22797,
          v: 405950000
        },
        {
          t: 74400,
          o: 1.22797,
          h: 1.22801,
          l: 1.22782,
          c: 1.22789,
          v: 344300000
        },
        {
          t: 74700,
          o: 1.22789,
          h: 1.22789,
          l: 1.22781,
          c: 1.22788,
          v: 320830000
        },
        {
          t: 75000,
          o: 1.22786,
          h: 1.22795,
          l: 1.22785,
          c: 1.22789,
          v: 384440000
        },
        {
          t: 75300,
          o: 1.2279,
          h: 1.22793,
          l: 1.22776,
          c: 1.22776,
          v: 299690000
        },
        {
          t: 75600,
          o: 1.22772,
          h: 1.22857,
          l: 1.22768,
          c: 1.22795,
          v: 123580000
        },
        {
          t: 75900,
          o: 1.22799,
          h: 1.228,
          l: 1.22793,
          c: 1.22793,
          v: 94190000
        },
        {
          t: 76200,
          o: 1.22793,
          h: 1.22829,
          l: 1.22793,
          c: 1.22805,
          v: 180530000
        },
        {
          t: 76500,
          o: 1.22804,
          h: 1.22804,
          l: 1.22793,
          c: 1.22801,
          v: 256110000
        },
        {
          t: 76800,
          o: 1.228,
          h: 1.22815,
          l: 1.22795,
          c: 1.22814,
          v: 243860000
        },
        {
          t: 77100,
          o: 1.22815,
          h: 1.22829,
          l: 1.22808,
          c: 1.22819,
          v: 326440000
        },
        {
          t: 77400,
          o: 1.22818,
          h: 1.22825,
          l: 1.22791,
          c: 1.22815,
          v: 296730000
        },
        {
          t: 77700,
          o: 1.22815,
          h: 1.22826,
          l: 1.22802,
          c: 1.22823,
          v: 242390000
        },
        {
          t: 78000,
          o: 1.22824,
          h: 1.2283,
          l: 1.2282,
          c: 1.22823,
          v: 106210000
        },
        {
          t: 78300,
          o: 1.22822,
          h: 1.22825,
          l: 1.22815,
          c: 1.22817,
          v: 368990000
        },
        {
          t: 78600,
          o: 1.22818,
          h: 1.22823,
          l: 1.22812,
          c: 1.22816,
          v: 379330000
        },
        {
          t: 78900,
          o: 1.22812,
          h: 1.22825,
          l: 1.2281,
          c: 1.2282,
          v: 298770000
        },
        {
          t: 79200,
          o: 1.22822,
          h: 1.22824,
          l: 1.22805,
          c: 1.22817,
          v: 225800000
        },
        {
          t: 79500,
          o: 1.22817,
          h: 1.22846,
          l: 1.22817,
          c: 1.22841,
          v: 450130000
        },
        {
          t: 79800,
          o: 1.2284,
          h: 1.22841,
          l: 1.22826,
          c: 1.22835,
          v: 220630000
        },
        {
          t: 80100,
          o: 1.22837,
          h: 1.22843,
          l: 1.22824,
          c: 1.22824,
          v: 212180000
        },
        {
          t: 80400,
          o: 1.22824,
          h: 1.2284,
          l: 1.22819,
          c: 1.22825,
          v: 161220000
        },
        {
          t: 80700,
          o: 1.22825,
          h: 1.22831,
          l: 1.22824,
          c: 1.22825,
          v: 90990000
        },
        {
          t: 81000,
          o: 1.22827,
          h: 1.22827,
          l: 1.22813,
          c: 1.22826,
          v: 143180000
        },
        {
          t: 81300,
          o: 1.22826,
          h: 1.22839,
          l: 1.22825,
          c: 1.22836,
          v: 138350000
        },
        {
          t: 81600,
          o: 1.22836,
          h: 1.22851,
          l: 1.22836,
          c: 1.22851,
          v: 105280000
        },
        {
          t: 81900,
          o: 1.22851,
          h: 1.22853,
          l: 1.22848,
          c: 1.22848,
          v: 156160000
        },
        {
          t: 82200,
          o: 1.22848,
          h: 1.22857,
          l: 1.22847,
          c: 1.22854,
          v: 71250000
        },
        {
          t: 82500,
          o: 1.22854,
          h: 1.22854,
          l: 1.22838,
          c: 1.22845,
          v: 132380000
        },
        {
          t: 82800,
          o: 1.22844,
          h: 1.2285,
          l: 1.22816,
          c: 1.22844,
          v: 239070000
        },
        {
          t: 83100,
          o: 1.22845,
          h: 1.22858,
          l: 1.22845,
          c: 1.22853,
          v: 120240000
        },
        {
          t: 83400,
          o: 1.22853,
          h: 1.22854,
          l: 1.22843,
          c: 1.22852,
          v: 132850000
        },
        {
          t: 83700,
          o: 1.22851,
          h: 1.22858,
          l: 1.22847,
          c: 1.22853,
          v: 116060000
        },
        {
          t: 84000,
          o: 1.22853,
          h: 1.22858,
          l: 1.22852,
          c: 1.22857,
          v: 106390000
        },
        {
          t: 84300,
          o: 1.22857,
          h: 1.22858,
          l: 1.22836,
          c: 1.22843,
          v: 163770000
        },
        {
          t: 84600,
          o: 1.22843,
          h: 1.22857,
          l: 1.22843,
          c: 1.22853,
          v: 140470000
        },
        {
          t: 84900,
          o: 1.22853,
          h: 1.22854,
          l: 1.22842,
          c: 1.22842,
          v: 228640000
        },
        {
          t: 85200,
          o: 1.22842,
          h: 1.22857,
          l: 1.22841,
          c: 1.22857,
          v: 138380000
        },
        {
          t: 85500,
          o: 1.22856,
          h: 1.22857,
          l: 1.22847,
          c: 1.22847,
          v: 267560000
        },
        {
          t: 85800,
          o: 1.22847,
          h: 1.22853,
          l: 1.22842,
          c: 1.22847,
          v: 198690000
        },
        {
          t: 86100,
          o: 1.22847,
          h: 1.22872,
          l: 1.22846,
          c: 1.22872,
          v: 234360000
        }
      ]
    },
    {
      d: 1522886400,
      b: [
        {
          t: 0,
          o: 1.22873,
          h: 1.22886,
          l: 1.22861,
          c: 1.22884,
          v: 574440000
        },
        {
          t: 300,
          o: 1.22884,
          h: 1.22888,
          l: 1.22871,
          c: 1.22887,
          v: 560720000
        },
        {
          t: 600,
          o: 1.22885,
          h: 1.2289,
          l: 1.22871,
          c: 1.22871,
          v: 322050000
        },
        {
          t: 900,
          o: 1.22871,
          h: 1.22882,
          l: 1.22854,
          c: 1.22872,
          v: 579800000
        },
        {
          t: 1200,
          o: 1.22872,
          h: 1.22888,
          l: 1.22869,
          c: 1.2288,
          v: 504920000
        },
        {
          t: 1500,
          o: 1.2288,
          h: 1.22881,
          l: 1.22858,
          c: 1.22867,
          v: 384320000
        },
        {
          t: 1800,
          o: 1.22867,
          h: 1.22868,
          l: 1.22845,
          c: 1.22863,
          v: 286160000
        },
        {
          t: 2100,
          o: 1.22861,
          h: 1.22873,
          l: 1.22844,
          c: 1.2285,
          v: 283510000
        },
        {
          t: 2400,
          o: 1.22851,
          h: 1.22882,
          l: 1.22851,
          c: 1.2287,
          v: 263970000
        },
        {
          t: 2700,
          o: 1.2287,
          h: 1.22872,
          l: 1.22865,
          c: 1.22865,
          v: 117500000
        },
        {
          t: 3000,
          o: 1.22866,
          h: 1.22901,
          l: 1.22865,
          c: 1.22877,
          v: 484450000
        },
        {
          t: 3300,
          o: 1.22875,
          h: 1.22885,
          l: 1.22858,
          c: 1.2286,
          v: 656750000
        },
        {
          t: 3600,
          o: 1.2286,
          h: 1.22894,
          l: 1.22856,
          c: 1.22861,
          v: 527650000
        },
        {
          t: 3900,
          o: 1.22861,
          h: 1.22875,
          l: 1.22845,
          c: 1.22852,
          v: 375240000
        },
        {
          t: 4200,
          o: 1.22852,
          h: 1.22858,
          l: 1.22852,
          c: 1.22852,
          v: 335810000
        },
        {
          t: 4500,
          o: 1.22852,
          h: 1.22856,
          l: 1.22841,
          c: 1.22847,
          v: 228940000
        },
        {
          t: 4800,
          o: 1.22847,
          h: 1.22852,
          l: 1.22837,
          c: 1.2284,
          v: 253120000
        },
        {
          t: 5100,
          o: 1.2284,
          h: 1.2284,
          l: 1.22824,
          c: 1.22828,
          v: 281330000
        },
        {
          t: 5400,
          o: 1.22827,
          h: 1.22838,
          l: 1.22817,
          c: 1.22823,
          v: 231330000
        },
        {
          t: 5700,
          o: 1.22822,
          h: 1.22823,
          l: 1.22802,
          c: 1.22811,
          v: 365880000
        },
        {
          t: 6000,
          o: 1.22813,
          h: 1.22822,
          l: 1.22811,
          c: 1.22814,
          v: 464720000
        },
        {
          t: 6300,
          o: 1.22815,
          h: 1.22816,
          l: 1.22786,
          c: 1.22798,
          v: 478630000
        },
        {
          t: 6600,
          o: 1.22798,
          h: 1.22798,
          l: 1.22769,
          c: 1.22796,
          v: 502910000
        },
        {
          t: 6900,
          o: 1.22796,
          h: 1.22806,
          l: 1.22789,
          c: 1.22799,
          v: 266860000
        },
        {
          t: 7200,
          o: 1.22799,
          h: 1.228,
          l: 1.22774,
          c: 1.22783,
          v: 421330000
        },
        {
          t: 7500,
          o: 1.22783,
          h: 1.22803,
          l: 1.22783,
          c: 1.22799,
          v: 349040000
        },
        {
          t: 7800,
          o: 1.22799,
          h: 1.22816,
          l: 1.22798,
          c: 1.2281,
          v: 116640000
        },
        {
          t: 8100,
          o: 1.2281,
          h: 1.22812,
          l: 1.22784,
          c: 1.22794,
          v: 305780000
        },
        {
          t: 8400,
          o: 1.22794,
          h: 1.228,
          l: 1.22779,
          c: 1.22797,
          v: 236530000
        },
        {
          t: 8700,
          o: 1.22797,
          h: 1.22804,
          l: 1.22788,
          c: 1.22791,
          v: 320170000
        },
        {
          t: 9000,
          o: 1.2279,
          h: 1.22803,
          l: 1.22785,
          c: 1.228,
          v: 350230000
        },
        {
          t: 9300,
          o: 1.22801,
          h: 1.22801,
          l: 1.22775,
          c: 1.22791,
          v: 185140000
        },
        {
          t: 9600,
          o: 1.2279,
          h: 1.2279,
          l: 1.22771,
          c: 1.22781,
          v: 278740000
        },
        {
          t: 9900,
          o: 1.22782,
          h: 1.22786,
          l: 1.22774,
          c: 1.22786,
          v: 201260000
        },
        {
          t: 10200,
          o: 1.22786,
          h: 1.22796,
          l: 1.2278,
          c: 1.2278,
          v: 205290000
        },
        {
          t: 10500,
          o: 1.2278,
          h: 1.22798,
          l: 1.2278,
          c: 1.22796,
          v: 244460000
        },
        {
          t: 10800,
          o: 1.22796,
          h: 1.22798,
          l: 1.22775,
          c: 1.2278,
          v: 318570000
        },
        {
          t: 11100,
          o: 1.22782,
          h: 1.22802,
          l: 1.2278,
          c: 1.22792,
          v: 254100000
        },
        {
          t: 11400,
          o: 1.22792,
          h: 1.22813,
          l: 1.22792,
          c: 1.22811,
          v: 197120000
        },
        {
          t: 11700,
          o: 1.2281,
          h: 1.22823,
          l: 1.22786,
          c: 1.22821,
          v: 225900000
        },
        {
          t: 12000,
          o: 1.22821,
          h: 1.22824,
          l: 1.228,
          c: 1.228,
          v: 329890000
        },
        {
          t: 12300,
          o: 1.22799,
          h: 1.22801,
          l: 1.22789,
          c: 1.22801,
          v: 338350000
        },
        {
          t: 12600,
          o: 1.22801,
          h: 1.22811,
          l: 1.22793,
          c: 1.22804,
          v: 310800000
        },
        {
          t: 12900,
          o: 1.22806,
          h: 1.22824,
          l: 1.22806,
          c: 1.2282,
          v: 357820000
        },
        {
          t: 13200,
          o: 1.2282,
          h: 1.22853,
          l: 1.22819,
          c: 1.22852,
          v: 226230000
        },
        {
          t: 13500,
          o: 1.22851,
          h: 1.22874,
          l: 1.22834,
          c: 1.22863,
          v: 429340000
        },
        {
          t: 13800,
          o: 1.22863,
          h: 1.22866,
          l: 1.22837,
          c: 1.22846,
          v: 457950000
        },
        {
          t: 14100,
          o: 1.22845,
          h: 1.22855,
          l: 1.22834,
          c: 1.22845,
          v: 298620000
        },
        {
          t: 14400,
          o: 1.22846,
          h: 1.22852,
          l: 1.22829,
          c: 1.22829,
          v: 432850000
        },
        {
          t: 14700,
          o: 1.22829,
          h: 1.22829,
          l: 1.22815,
          c: 1.22821,
          v: 425230000
        },
        {
          t: 15000,
          o: 1.22821,
          h: 1.22821,
          l: 1.22806,
          c: 1.22816,
          v: 229450000
        },
        {
          t: 15300,
          o: 1.22817,
          h: 1.22817,
          l: 1.22801,
          c: 1.22806,
          v: 444290000
        },
        {
          t: 15600,
          o: 1.22806,
          h: 1.22807,
          l: 1.2279,
          c: 1.22801,
          v: 521170000
        },
        {
          t: 15900,
          o: 1.22801,
          h: 1.22801,
          l: 1.22769,
          c: 1.22776,
          v: 515760000
        },
        {
          t: 16200,
          o: 1.22775,
          h: 1.22802,
          l: 1.2277,
          c: 1.22801,
          v: 607010000
        },
        {
          t: 16500,
          o: 1.22802,
          h: 1.22807,
          l: 1.22775,
          c: 1.2278,
          v: 429360000
        },
        {
          t: 16800,
          o: 1.22779,
          h: 1.22779,
          l: 1.2275,
          c: 1.22755,
          v: 563270000
        },
        {
          t: 17100,
          o: 1.22754,
          h: 1.22762,
          l: 1.22751,
          c: 1.22755,
          v: 400170000
        },
        {
          t: 17400,
          o: 1.22757,
          h: 1.22767,
          l: 1.22746,
          c: 1.2275,
          v: 277770000
        },
        {
          t: 17700,
          o: 1.22749,
          h: 1.22767,
          l: 1.22749,
          c: 1.22767,
          v: 230850000
        },
        {
          t: 18000,
          o: 1.22767,
          h: 1.22777,
          l: 1.2276,
          c: 1.22762,
          v: 378850000
        },
        {
          t: 18300,
          o: 1.22762,
          h: 1.22773,
          l: 1.22755,
          c: 1.22767,
          v: 271120000
        },
        {
          t: 18600,
          o: 1.22767,
          h: 1.22781,
          l: 1.22767,
          c: 1.22772,
          v: 219130000
        },
        {
          t: 18900,
          o: 1.22771,
          h: 1.22771,
          l: 1.22749,
          c: 1.22756,
          v: 169230000
        },
        {
          t: 19200,
          o: 1.22757,
          h: 1.22779,
          l: 1.22752,
          c: 1.22779,
          v: 239840000
        },
        {
          t: 19500,
          o: 1.22779,
          h: 1.22789,
          l: 1.22772,
          c: 1.22778,
          v: 206690000
        },
        {
          t: 19800,
          o: 1.22778,
          h: 1.22778,
          l: 1.22768,
          c: 1.22768,
          v: 256340000
        },
        {
          t: 20100,
          o: 1.22768,
          h: 1.22769,
          l: 1.22745,
          c: 1.22745,
          v: 532970000
        },
        {
          t: 20400,
          o: 1.22746,
          h: 1.22766,
          l: 1.22741,
          c: 1.22758,
          v: 635460001
        },
        {
          t: 20700,
          o: 1.22757,
          h: 1.22766,
          l: 1.22754,
          c: 1.22761,
          v: 348750000
        },
        {
          t: 21000,
          o: 1.22761,
          h: 1.22765,
          l: 1.22711,
          c: 1.22717,
          v: 481260000
        },
        {
          t: 21300,
          o: 1.22719,
          h: 1.22755,
          l: 1.22706,
          c: 1.22751,
          v: 581370001
        },
        {
          t: 21600,
          o: 1.22751,
          h: 1.22751,
          l: 1.22702,
          c: 1.22722,
          v: 774510000
        },
        {
          t: 21900,
          o: 1.22722,
          h: 1.22755,
          l: 1.22712,
          c: 1.22716,
          v: 656240000
        },
        {
          t: 22200,
          o: 1.22716,
          h: 1.22732,
          l: 1.22711,
          c: 1.22714,
          v: 623890000
        },
        {
          t: 22500,
          o: 1.22713,
          h: 1.22713,
          l: 1.22641,
          c: 1.22647,
          v: 1072810000
        },
        {
          t: 22800,
          o: 1.22645,
          h: 1.22652,
          l: 1.22617,
          c: 1.22619,
          v: 894580000
        },
        {
          t: 23100,
          o: 1.22619,
          h: 1.22668,
          l: 1.22617,
          c: 1.22661,
          v: 1177440000
        },
        {
          t: 23400,
          o: 1.22661,
          h: 1.22665,
          l: 1.22633,
          c: 1.2264,
          v: 1050450000
        },
        {
          t: 23700,
          o: 1.2264,
          h: 1.22645,
          l: 1.22615,
          c: 1.22639,
          v: 816649999
        },
        {
          t: 24000,
          o: 1.22639,
          h: 1.22642,
          l: 1.22586,
          c: 1.22612,
          v: 1153510000
        },
        {
          t: 24300,
          o: 1.22611,
          h: 1.22652,
          l: 1.226,
          c: 1.22651,
          v: 1014559999
        },
        {
          t: 24600,
          o: 1.22651,
          h: 1.2266,
          l: 1.22628,
          c: 1.22631,
          v: 798530000
        },
        {
          t: 24900,
          o: 1.22631,
          h: 1.22642,
          l: 1.22596,
          c: 1.2263,
          v: 1318480000
        },
        {
          t: 25200,
          o: 1.2263,
          h: 1.22634,
          l: 1.2254,
          c: 1.226,
          v: 1546510000
        },
        {
          t: 25500,
          o: 1.22599,
          h: 1.22605,
          l: 1.22524,
          c: 1.22534,
          v: 1319300000
        },
        {
          t: 25800,
          o: 1.22534,
          h: 1.22534,
          l: 1.2249,
          c: 1.22532,
          v: 1418260000
        },
        {
          t: 26100,
          o: 1.22531,
          h: 1.22574,
          l: 1.22508,
          c: 1.22574,
          v: 1327320000
        },
        {
          t: 26400,
          o: 1.22574,
          h: 1.22637,
          l: 1.22562,
          c: 1.22634,
          v: 1146460000
        },
        {
          t: 26700,
          o: 1.22633,
          h: 1.22638,
          l: 1.22562,
          c: 1.22574,
          v: 1137530000
        },
        {
          t: 27000,
          o: 1.22572,
          h: 1.22621,
          l: 1.22553,
          c: 1.2262,
          v: 1186880000
        },
        {
          t: 27300,
          o: 1.22622,
          h: 1.22685,
          l: 1.22622,
          c: 1.22674,
          v: 1230260000
        },
        {
          t: 27600,
          o: 1.22673,
          h: 1.22708,
          l: 1.22657,
          c: 1.22681,
          v: 1239850000
        },
        {
          t: 27900,
          o: 1.22683,
          h: 1.22702,
          l: 1.22671,
          c: 1.22676,
          v: 871020000
        },
        {
          t: 28200,
          o: 1.22673,
          h: 1.22673,
          l: 1.22632,
          c: 1.22645,
          v: 944400000
        },
        {
          t: 28500,
          o: 1.22645,
          h: 1.22645,
          l: 1.2257,
          c: 1.2257,
          v: 888840000
        },
        {
          t: 28800,
          o: 1.22573,
          h: 1.22632,
          l: 1.22573,
          c: 1.22591,
          v: 1039290000
        },
        {
          t: 29100,
          o: 1.22582,
          h: 1.22593,
          l: 1.22558,
          c: 1.22568,
          v: 695870000
        },
        {
          t: 29400,
          o: 1.22568,
          h: 1.22593,
          l: 1.22536,
          c: 1.22588,
          v: 932150000
        },
        {
          t: 29700,
          o: 1.22589,
          h: 1.2262,
          l: 1.22583,
          c: 1.22599,
          v: 1029030000
        },
        {
          t: 30000,
          o: 1.22601,
          h: 1.22637,
          l: 1.22599,
          c: 1.22604,
          v: 907060000
        },
        {
          t: 30300,
          o: 1.22605,
          h: 1.22643,
          l: 1.22603,
          c: 1.22638,
          v: 846720000
        },
        {
          t: 30600,
          o: 1.22635,
          h: 1.22637,
          l: 1.22599,
          c: 1.22633,
          v: 934030000
        },
        {
          t: 30900,
          o: 1.22633,
          h: 1.22634,
          l: 1.22575,
          c: 1.22594,
          v: 944920000
        },
        {
          t: 31200,
          o: 1.22594,
          h: 1.22635,
          l: 1.22592,
          c: 1.22624,
          v: 809960000
        },
        {
          t: 31500,
          o: 1.22624,
          h: 1.22656,
          l: 1.22615,
          c: 1.22646,
          v: 749700000
        },
        {
          t: 31800,
          o: 1.22646,
          h: 1.22687,
          l: 1.22641,
          c: 1.22682,
          v: 873750000
        },
        {
          t: 32100,
          o: 1.22681,
          h: 1.22687,
          l: 1.22637,
          c: 1.2265,
          v: 929290000
        },
        {
          t: 32400,
          o: 1.22651,
          h: 1.2266,
          l: 1.22626,
          c: 1.2265,
          v: 1149000000
        },
        {
          t: 32700,
          o: 1.2265,
          h: 1.22683,
          l: 1.22647,
          c: 1.22659,
          v: 1171210000
        },
        {
          t: 33000,
          o: 1.22659,
          h: 1.22696,
          l: 1.22651,
          c: 1.22694,
          v: 908210000
        },
        {
          t: 33300,
          o: 1.22693,
          h: 1.22711,
          l: 1.22685,
          c: 1.22708,
          v: 621510000
        },
        {
          t: 33600,
          o: 1.22708,
          h: 1.22786,
          l: 1.22704,
          c: 1.22756,
          v: 1099770000
        },
        {
          t: 33900,
          o: 1.22756,
          h: 1.22807,
          l: 1.22754,
          c: 1.22784,
          v: 1046720000
        },
        {
          t: 34200,
          o: 1.22787,
          h: 1.22822,
          l: 1.22776,
          c: 1.22792,
          v: 1076540001
        },
        {
          t: 34500,
          o: 1.22793,
          h: 1.228,
          l: 1.22732,
          c: 1.22738,
          v: 1010110000
        },
        {
          t: 34800,
          o: 1.22739,
          h: 1.22835,
          l: 1.22739,
          c: 1.22834,
          v: 1129940000
        },
        {
          t: 35100,
          o: 1.22835,
          h: 1.22835,
          l: 1.22792,
          c: 1.22807,
          v: 1285940001
        },
        {
          t: 35400,
          o: 1.22809,
          h: 1.22811,
          l: 1.22764,
          c: 1.22771,
          v: 873780000
        },
        {
          t: 35700,
          o: 1.22771,
          h: 1.22782,
          l: 1.22758,
          c: 1.22761,
          v: 746920000
        },
        {
          t: 36000,
          o: 1.22761,
          h: 1.22767,
          l: 1.22736,
          c: 1.22746,
          v: 766110000
        },
        {
          t: 36300,
          o: 1.22746,
          h: 1.2277,
          l: 1.22746,
          c: 1.22762,
          v: 938310000
        },
        {
          t: 36600,
          o: 1.22762,
          h: 1.22769,
          l: 1.22722,
          c: 1.22726,
          v: 879309999
        },
        {
          t: 36900,
          o: 1.22726,
          h: 1.22757,
          l: 1.22722,
          c: 1.22757,
          v: 622769999
        },
        {
          t: 37200,
          o: 1.22758,
          h: 1.22787,
          l: 1.22741,
          c: 1.2275,
          v: 818160000
        },
        {
          t: 37500,
          o: 1.2275,
          h: 1.22787,
          l: 1.22727,
          c: 1.22763,
          v: 890550000
        },
        {
          t: 37800,
          o: 1.22765,
          h: 1.22772,
          l: 1.22741,
          c: 1.22745,
          v: 871170000
        },
        {
          t: 38100,
          o: 1.22745,
          h: 1.22749,
          l: 1.22687,
          c: 1.22695,
          v: 892350000
        },
        {
          t: 38400,
          o: 1.22694,
          h: 1.22755,
          l: 1.22693,
          c: 1.22754,
          v: 1089330000
        },
        {
          t: 38700,
          o: 1.22755,
          h: 1.22764,
          l: 1.22738,
          c: 1.2274,
          v: 795480000
        },
        {
          t: 39000,
          o: 1.2274,
          h: 1.22796,
          l: 1.22729,
          c: 1.22774,
          v: 934590002
        },
        {
          t: 39300,
          o: 1.22774,
          h: 1.22801,
          l: 1.22772,
          c: 1.22798,
          v: 879400000
        },
        {
          t: 39600,
          o: 1.22797,
          h: 1.22815,
          l: 1.22785,
          c: 1.22795,
          v: 888750000
        },
        {
          t: 39900,
          o: 1.22795,
          h: 1.22795,
          l: 1.2275,
          c: 1.22753,
          v: 1031490001
        },
        {
          t: 40200,
          o: 1.22753,
          h: 1.22754,
          l: 1.22706,
          c: 1.22736,
          v: 1101660000
        },
        {
          t: 40500,
          o: 1.22736,
          h: 1.22742,
          l: 1.22685,
          c: 1.22685,
          v: 1110759999
        },
        {
          t: 40800,
          o: 1.22684,
          h: 1.22684,
          l: 1.22632,
          c: 1.22662,
          v: 1158650000
        },
        {
          t: 41100,
          o: 1.22661,
          h: 1.2268,
          l: 1.22657,
          c: 1.22657,
          v: 668770000
        },
        {
          t: 41400,
          o: 1.22656,
          h: 1.22659,
          l: 1.22619,
          c: 1.22642,
          v: 1052200000
        },
        {
          t: 41700,
          o: 1.2264,
          h: 1.2264,
          l: 1.22595,
          c: 1.22611,
          v: 1048770000
        },
        {
          t: 42000,
          o: 1.22611,
          h: 1.22622,
          l: 1.22576,
          c: 1.22607,
          v: 904710000
        },
        {
          t: 42300,
          o: 1.22608,
          h: 1.22608,
          l: 1.22566,
          c: 1.22597,
          v: 900370000
        },
        {
          t: 42600,
          o: 1.22597,
          h: 1.22617,
          l: 1.22589,
          c: 1.226,
          v: 975400000
        },
        {
          t: 42900,
          o: 1.22598,
          h: 1.22606,
          l: 1.22527,
          c: 1.22558,
          v: 1361909999
        },
        {
          t: 43200,
          o: 1.2256,
          h: 1.22599,
          l: 1.22555,
          c: 1.2259,
          v: 1118240000
        },
        {
          t: 43500,
          o: 1.22588,
          h: 1.2262,
          l: 1.22583,
          c: 1.22601,
          v: 898300000
        },
        {
          t: 43800,
          o: 1.22602,
          h: 1.22607,
          l: 1.22561,
          c: 1.22598,
          v: 1094510001
        },
        {
          t: 44100,
          o: 1.22596,
          h: 1.22609,
          l: 1.22564,
          c: 1.22566,
          v: 1280830000
        },
        {
          t: 44400,
          o: 1.22565,
          h: 1.22565,
          l: 1.22419,
          c: 1.22432,
          v: 2005760001
        },
        {
          t: 44700,
          o: 1.22432,
          h: 1.2244,
          l: 1.22404,
          c: 1.22421,
          v: 1597680000
        },
        {
          t: 45000,
          o: 1.22417,
          h: 1.22548,
          l: 1.22357,
          c: 1.2251,
          v: 1967860000
        },
        {
          t: 45300,
          o: 1.2251,
          h: 1.22575,
          l: 1.22493,
          c: 1.22519,
          v: 1779060000
        },
        {
          t: 45600,
          o: 1.22519,
          h: 1.22628,
          l: 1.2249,
          c: 1.22568,
          v: 1761870000
        },
        {
          t: 45900,
          o: 1.22564,
          h: 1.22585,
          l: 1.22535,
          c: 1.22538,
          v: 1404719998
        },
        {
          t: 46200,
          o: 1.22538,
          h: 1.22542,
          l: 1.22468,
          c: 1.22474,
          v: 1664890000
        },
        {
          t: 46500,
          o: 1.22472,
          h: 1.22523,
          l: 1.22458,
          c: 1.22499,
          v: 1367070001
        },
        {
          t: 46800,
          o: 1.22498,
          h: 1.22523,
          l: 1.22447,
          c: 1.22495,
          v: 1213740000
        },
        {
          t: 47100,
          o: 1.22494,
          h: 1.22541,
          l: 1.22489,
          c: 1.22537,
          v: 1186720000
        },
        {
          t: 47400,
          o: 1.22536,
          h: 1.22574,
          l: 1.22496,
          c: 1.22515,
          v: 1456100000
        },
        {
          t: 47700,
          o: 1.22514,
          h: 1.22565,
          l: 1.22512,
          c: 1.2254,
          v: 1223540000
        },
        {
          t: 48000,
          o: 1.22539,
          h: 1.22558,
          l: 1.22505,
          c: 1.22508,
          v: 1114490000
        },
        {
          t: 48300,
          o: 1.22508,
          h: 1.22531,
          l: 1.22491,
          c: 1.22513,
          v: 1383149999
        },
        {
          t: 48600,
          o: 1.22516,
          h: 1.2256,
          l: 1.22507,
          c: 1.22554,
          v: 1600330000
        },
        {
          t: 48900,
          o: 1.22554,
          h: 1.22625,
          l: 1.22554,
          c: 1.22569,
          v: 1957230000
        },
        {
          t: 49200,
          o: 1.2257,
          h: 1.22617,
          l: 1.22565,
          c: 1.22609,
          v: 1429259999
        },
        {
          t: 49500,
          o: 1.22609,
          h: 1.22686,
          l: 1.22602,
          c: 1.22617,
          v: 2189630002
        },
        {
          t: 49800,
          o: 1.22617,
          h: 1.22619,
          l: 1.22572,
          c: 1.22573,
          v: 1234610000
        },
        {
          t: 50100,
          o: 1.22575,
          h: 1.22609,
          l: 1.22534,
          c: 1.22557,
          v: 1608680000
        },
        {
          t: 50400,
          o: 1.22557,
          h: 1.22569,
          l: 1.22489,
          c: 1.22497,
          v: 1736290000
        },
        {
          t: 50700,
          o: 1.22496,
          h: 1.2251,
          l: 1.22407,
          c: 1.22416,
          v: 1543860000
        },
        {
          t: 51000,
          o: 1.22411,
          h: 1.22453,
          l: 1.22409,
          c: 1.2242,
          v: 1555890000
        },
        {
          t: 51300,
          o: 1.22421,
          h: 1.22456,
          l: 1.2242,
          c: 1.22456,
          v: 1367000000
        },
        {
          t: 51600,
          o: 1.22456,
          h: 1.22459,
          l: 1.2241,
          c: 1.22411,
          v: 1362850000
        },
        {
          t: 51900,
          o: 1.22411,
          h: 1.22435,
          l: 1.22361,
          c: 1.22383,
          v: 1470970001
        },
        {
          t: 52200,
          o: 1.22383,
          h: 1.22426,
          l: 1.22362,
          c: 1.22415,
          v: 1357670000
        },
        {
          t: 52500,
          o: 1.22415,
          h: 1.22426,
          l: 1.22392,
          c: 1.22398,
          v: 1562300000
        },
        {
          t: 52800,
          o: 1.22397,
          h: 1.22401,
          l: 1.2231,
          c: 1.22345,
          v: 1596850000
        },
        {
          t: 53100,
          o: 1.2235,
          h: 1.22356,
          l: 1.22269,
          c: 1.22271,
          v: 1509650000
        },
        {
          t: 53400,
          o: 1.22273,
          h: 1.22283,
          l: 1.22212,
          c: 1.22282,
          v: 1955330000
        },
        {
          t: 53700,
          o: 1.2228,
          h: 1.22301,
          l: 1.22232,
          c: 1.22292,
          v: 1672230000
        },
        {
          t: 54000,
          o: 1.22292,
          h: 1.22315,
          l: 1.22241,
          c: 1.22249,
          v: 1912900000
        },
        {
          t: 54300,
          o: 1.22248,
          h: 1.22311,
          l: 1.22246,
          c: 1.22294,
          v: 1317480000
        },
        {
          t: 54600,
          o: 1.22294,
          h: 1.22328,
          l: 1.22271,
          c: 1.2229,
          v: 1617250000
        },
        {
          t: 54900,
          o: 1.2229,
          h: 1.22301,
          l: 1.2225,
          c: 1.22293,
          v: 1290790000
        },
        {
          t: 55200,
          o: 1.22293,
          h: 1.22306,
          l: 1.22244,
          c: 1.22244,
          v: 1708640000
        },
        {
          t: 55500,
          o: 1.22243,
          h: 1.22252,
          l: 1.22215,
          c: 1.22242,
          v: 1532620000
        },
        {
          t: 55800,
          o: 1.2224,
          h: 1.22263,
          l: 1.22233,
          c: 1.22238,
          v: 1373310000
        },
        {
          t: 56100,
          o: 1.22238,
          h: 1.2224,
          l: 1.22219,
          c: 1.22229,
          v: 1234340000
        },
        {
          t: 56400,
          o: 1.22228,
          h: 1.22232,
          l: 1.22189,
          c: 1.22194,
          v: 1385770000
        },
        {
          t: 56700,
          o: 1.22198,
          h: 1.22235,
          l: 1.22182,
          c: 1.22231,
          v: 1022020000
        },
        {
          t: 57000,
          o: 1.2223,
          h: 1.2228,
          l: 1.22223,
          c: 1.22275,
          v: 1478760000
        },
        {
          t: 57300,
          o: 1.22276,
          h: 1.22299,
          l: 1.22275,
          c: 1.2229,
          v: 1014710000
        },
        {
          t: 57600,
          o: 1.22289,
          h: 1.22338,
          l: 1.22285,
          c: 1.22332,
          v: 1355250000
        },
        {
          t: 57900,
          o: 1.22333,
          h: 1.2234,
          l: 1.22315,
          c: 1.22323,
          v: 1134880000
        },
        {
          t: 58200,
          o: 1.22324,
          h: 1.22368,
          l: 1.22318,
          c: 1.22365,
          v: 1115590000
        },
        {
          t: 58500,
          o: 1.22365,
          h: 1.22401,
          l: 1.22364,
          c: 1.2238,
          v: 1360500000
        },
        {
          t: 58800,
          o: 1.22379,
          h: 1.22404,
          l: 1.2236,
          c: 1.224,
          v: 1221340000
        },
        {
          t: 59100,
          o: 1.224,
          h: 1.22437,
          l: 1.22375,
          c: 1.22383,
          v: 1216100000
        },
        {
          t: 59400,
          o: 1.22385,
          h: 1.22398,
          l: 1.22378,
          c: 1.22388,
          v: 1122180001
        },
        {
          t: 59700,
          o: 1.22388,
          h: 1.22412,
          l: 1.22372,
          c: 1.22372,
          v: 1127530000
        },
        {
          t: 60000,
          o: 1.22372,
          h: 1.22392,
          l: 1.22361,
          c: 1.22363,
          v: 1445330015
        },
        {
          t: 60300,
          o: 1.22363,
          h: 1.22399,
          l: 1.22363,
          c: 1.22376,
          v: 978460000
        },
        {
          t: 60600,
          o: 1.22376,
          h: 1.22384,
          l: 1.22336,
          c: 1.22341,
          v: 1124909998
        },
        {
          t: 60900,
          o: 1.22342,
          h: 1.22379,
          l: 1.22339,
          c: 1.22361,
          v: 1157020000
        },
        {
          t: 61200,
          o: 1.22361,
          h: 1.22378,
          l: 1.22342,
          c: 1.22345,
          v: 1169230000
        },
        {
          t: 61500,
          o: 1.22345,
          h: 1.22346,
          l: 1.22315,
          c: 1.22321,
          v: 1129609998
        },
        {
          t: 61800,
          o: 1.22321,
          h: 1.22327,
          l: 1.22286,
          c: 1.22293,
          v: 991320000
        },
        {
          t: 62100,
          o: 1.22293,
          h: 1.22327,
          l: 1.22284,
          c: 1.22285,
          v: 1217990000
        },
        {
          t: 62400,
          o: 1.22285,
          h: 1.223,
          l: 1.22241,
          c: 1.22245,
          v: 1220780000
        },
        {
          t: 62700,
          o: 1.22243,
          h: 1.2228,
          l: 1.22243,
          c: 1.22258,
          v: 1003500000
        },
        {
          t: 63000,
          o: 1.22257,
          h: 1.22289,
          l: 1.22257,
          c: 1.22282,
          v: 971520000
        },
        {
          t: 63300,
          o: 1.22284,
          h: 1.22304,
          l: 1.22279,
          c: 1.2229,
          v: 765770000
        },
        {
          t: 63600,
          o: 1.2229,
          h: 1.22344,
          l: 1.22281,
          c: 1.22343,
          v: 825580000
        },
        {
          t: 63900,
          o: 1.22343,
          h: 1.22365,
          l: 1.22339,
          c: 1.22351,
          v: 637050000
        },
        {
          t: 64200,
          o: 1.2235,
          h: 1.22355,
          l: 1.22318,
          c: 1.22337,
          v: 712070000
        },
        {
          t: 64500,
          o: 1.22338,
          h: 1.22365,
          l: 1.22316,
          c: 1.22358,
          v: 936860000
        },
        {
          t: 64800,
          o: 1.22357,
          h: 1.22357,
          l: 1.22328,
          c: 1.22344,
          v: 896940000
        },
        {
          t: 65100,
          o: 1.22345,
          h: 1.22387,
          l: 1.22345,
          c: 1.22385,
          v: 1029429999
        },
        {
          t: 65400,
          o: 1.22384,
          h: 1.22384,
          l: 1.22343,
          c: 1.2235,
          v: 893699999
        },
        {
          t: 65700,
          o: 1.2235,
          h: 1.22382,
          l: 1.22344,
          c: 1.22368,
          v: 618880000
        },
        {
          t: 66000,
          o: 1.22369,
          h: 1.22384,
          l: 1.22365,
          c: 1.22382,
          v: 847420000
        },
        {
          t: 66300,
          o: 1.22382,
          h: 1.22393,
          l: 1.22371,
          c: 1.22374,
          v: 797320000
        },
        {
          t: 66600,
          o: 1.22374,
          h: 1.22376,
          l: 1.22365,
          c: 1.22373,
          v: 564030002
        },
        {
          t: 66900,
          o: 1.22373,
          h: 1.22392,
          l: 1.22373,
          c: 1.22379,
          v: 588180000
        },
        {
          t: 67200,
          o: 1.22379,
          h: 1.22389,
          l: 1.22361,
          c: 1.22373,
          v: 841410000
        },
        {
          t: 67500,
          o: 1.22373,
          h: 1.22391,
          l: 1.22366,
          c: 1.22368,
          v: 1172240000
        },
        {
          t: 67800,
          o: 1.22367,
          h: 1.22384,
          l: 1.22359,
          c: 1.22383,
          v: 887920000
        },
        {
          t: 68100,
          o: 1.22382,
          h: 1.22393,
          l: 1.22365,
          c: 1.22366,
          v: 907200000
        },
        {
          t: 68400,
          o: 1.22367,
          h: 1.22367,
          l: 1.22354,
          c: 1.22355,
          v: 643120000
        },
        {
          t: 68700,
          o: 1.22354,
          h: 1.2236,
          l: 1.22349,
          c: 1.22357,
          v: 497530000
        },
        {
          t: 69000,
          o: 1.22357,
          h: 1.22357,
          l: 1.22349,
          c: 1.22352,
          v: 446640000
        },
        {
          t: 69300,
          o: 1.22352,
          h: 1.22362,
          l: 1.22349,
          c: 1.22349,
          v: 400390000
        },
        {
          t: 69600,
          o: 1.22349,
          h: 1.22368,
          l: 1.22349,
          c: 1.22359,
          v: 591570001
        },
        {
          t: 69900,
          o: 1.2236,
          h: 1.22374,
          l: 1.22359,
          c: 1.22374,
          v: 605230000
        },
        {
          t: 70200,
          o: 1.22373,
          h: 1.22382,
          l: 1.22359,
          c: 1.22377,
          v: 492620001
        },
        {
          t: 70500,
          o: 1.22378,
          h: 1.22382,
          l: 1.2237,
          c: 1.22375,
          v: 439780000
        },
        {
          t: 70800,
          o: 1.22379,
          h: 1.22379,
          l: 1.22351,
          c: 1.22354,
          v: 348600000
        },
        {
          t: 71100,
          o: 1.22354,
          h: 1.2236,
          l: 1.22352,
          c: 1.22352,
          v: 376780000
        },
        {
          t: 71400,
          o: 1.22351,
          h: 1.22351,
          l: 1.22335,
          c: 1.22337,
          v: 558310000
        },
        {
          t: 71700,
          o: 1.22337,
          h: 1.22352,
          l: 1.22333,
          c: 1.22349,
          v: 491990000
        },
        {
          t: 72000,
          o: 1.22347,
          h: 1.2235,
          l: 1.22342,
          c: 1.22348,
          v: 234700000
        },
        {
          t: 72300,
          o: 1.22348,
          h: 1.22381,
          l: 1.22346,
          c: 1.22377,
          v: 414350000
        },
        {
          t: 72600,
          o: 1.22378,
          h: 1.22393,
          l: 1.22376,
          c: 1.22388,
          v: 317460000
        },
        {
          t: 72900,
          o: 1.22387,
          h: 1.22393,
          l: 1.2238,
          c: 1.22383,
          v: 256710000
        },
        {
          t: 73200,
          o: 1.22384,
          h: 1.22397,
          l: 1.22382,
          c: 1.22388,
          v: 286520001
        },
        {
          t: 73500,
          o: 1.22388,
          h: 1.22388,
          l: 1.2238,
          c: 1.22383,
          v: 260090000
        },
        {
          t: 73800,
          o: 1.22384,
          h: 1.22388,
          l: 1.22378,
          c: 1.22382,
          v: 233340000
        },
        {
          t: 74100,
          o: 1.2238,
          h: 1.22381,
          l: 1.22377,
          c: 1.22378,
          v: 201120000
        },
        {
          t: 74400,
          o: 1.22377,
          h: 1.22384,
          l: 1.22372,
          c: 1.22383,
          v: 261140000
        },
        {
          t: 74700,
          o: 1.22383,
          h: 1.22408,
          l: 1.22383,
          c: 1.22402,
          v: 370530000
        },
        {
          t: 75000,
          o: 1.224,
          h: 1.22411,
          l: 1.22396,
          c: 1.22401,
          v: 297430000
        },
        {
          t: 75300,
          o: 1.22401,
          h: 1.22405,
          l: 1.22391,
          c: 1.22397,
          v: 355740000
        },
        {
          t: 75600,
          o: 1.22398,
          h: 1.22398,
          l: 1.22366,
          c: 1.2237,
          v: 66010000
        },
        {
          t: 75900,
          o: 1.2237,
          h: 1.224,
          l: 1.22368,
          c: 1.22374,
          v: 172990000
        },
        {
          t: 76200,
          o: 1.22373,
          h: 1.22402,
          l: 1.22371,
          c: 1.22375,
          v: 425730000
        },
        {
          t: 76500,
          o: 1.22376,
          h: 1.224,
          l: 1.22372,
          c: 1.22396,
          v: 354000000
        },
        {
          t: 76800,
          o: 1.22396,
          h: 1.22401,
          l: 1.22375,
          c: 1.22396,
          v: 348110000
        },
        {
          t: 77100,
          o: 1.22395,
          h: 1.22402,
          l: 1.22381,
          c: 1.22386,
          v: 303710000
        },
        {
          t: 77400,
          o: 1.22386,
          h: 1.22407,
          l: 1.22374,
          c: 1.22391,
          v: 424920000
        },
        {
          t: 77700,
          o: 1.22391,
          h: 1.22397,
          l: 1.22384,
          c: 1.22394,
          v: 218380000
        },
        {
          t: 78000,
          o: 1.22393,
          h: 1.22404,
          l: 1.22384,
          c: 1.22385,
          v: 530390000
        },
        {
          t: 78300,
          o: 1.22385,
          h: 1.22387,
          l: 1.2238,
          c: 1.22383,
          v: 545440000
        },
        {
          t: 78600,
          o: 1.22383,
          h: 1.22385,
          l: 1.2238,
          c: 1.2238,
          v: 200940000
        },
        {
          t: 78900,
          o: 1.2238,
          h: 1.22392,
          l: 1.22375,
          c: 1.22391,
          v: 448730000
        },
        {
          t: 79200,
          o: 1.22389,
          h: 1.22402,
          l: 1.22375,
          c: 1.22377,
          v: 174700000
        },
        {
          t: 79500,
          o: 1.22376,
          h: 1.22393,
          l: 1.22376,
          c: 1.22391,
          v: 194790000
        },
        {
          t: 79800,
          o: 1.22392,
          h: 1.22398,
          l: 1.22391,
          c: 1.22395,
          v: 245270000
        },
        {
          t: 80100,
          o: 1.22395,
          h: 1.22401,
          l: 1.22392,
          c: 1.22396,
          v: 238170000
        },
        {
          t: 80400,
          o: 1.22395,
          h: 1.22401,
          l: 1.22392,
          c: 1.22392,
          v: 263950000
        },
        {
          t: 80700,
          o: 1.22392,
          h: 1.22401,
          l: 1.22388,
          c: 1.22398,
          v: 208910000
        },
        {
          t: 81000,
          o: 1.22397,
          h: 1.22405,
          l: 1.22397,
          c: 1.22399,
          v: 251610000
        },
        {
          t: 81300,
          o: 1.22399,
          h: 1.22422,
          l: 1.22397,
          c: 1.2242,
          v: 282940000
        },
        {
          t: 81600,
          o: 1.22418,
          h: 1.22423,
          l: 1.22416,
          c: 1.22422,
          v: 138440000
        },
        {
          t: 81900,
          o: 1.22422,
          h: 1.22431,
          l: 1.22411,
          c: 1.22426,
          v: 245140000
        },
        {
          t: 82200,
          o: 1.22426,
          h: 1.22467,
          l: 1.22421,
          c: 1.22436,
          v: 1151630000
        },
        {
          t: 82500,
          o: 1.22436,
          h: 1.22456,
          l: 1.22417,
          c: 1.22441,
          v: 790170000
        },
        {
          t: 82800,
          o: 1.22444,
          h: 1.22446,
          l: 1.22401,
          c: 1.22419,
          v: 1134100000
        },
        {
          t: 83100,
          o: 1.22416,
          h: 1.2246,
          l: 1.224,
          c: 1.22459,
          v: 1193990000
        },
        {
          t: 83400,
          o: 1.22458,
          h: 1.22497,
          l: 1.22451,
          c: 1.22497,
          v: 975060000
        },
        {
          t: 83700,
          o: 1.22495,
          h: 1.22501,
          l: 1.2248,
          c: 1.22485,
          v: 770420000
        },
        {
          t: 84000,
          o: 1.22488,
          h: 1.22515,
          l: 1.22469,
          c: 1.22506,
          v: 642400000
        },
        {
          t: 84300,
          o: 1.22507,
          h: 1.22507,
          l: 1.22481,
          c: 1.225,
          v: 665900000
        },
        {
          t: 84600,
          o: 1.225,
          h: 1.22519,
          l: 1.22499,
          c: 1.22505,
          v: 671980000
        },
        {
          t: 84900,
          o: 1.22505,
          h: 1.22543,
          l: 1.22504,
          c: 1.22528,
          v: 661940000
        },
        {
          t: 85200,
          o: 1.22528,
          h: 1.22528,
          l: 1.225,
          c: 1.22517,
          v: 534820000
        },
        {
          t: 85500,
          o: 1.22515,
          h: 1.22521,
          l: 1.22474,
          c: 1.2248,
          v: 696430001
        },
        {
          t: 85800,
          o: 1.22483,
          h: 1.22537,
          l: 1.22483,
          c: 1.2252,
          v: 385970000
        },
        {
          t: 86100,
          o: 1.2252,
          h: 1.22523,
          l: 1.22479,
          c: 1.22485,
          v: 431150000
        }
      ]
    },
    {
      d: 1522972800,
      b: [
        {
          t: 0,
          o: 1.22489,
          h: 1.22521,
          l: 1.22468,
          c: 1.2249,
          v: 925620000
        },
        {
          t: 300,
          o: 1.2249,
          h: 1.22505,
          l: 1.22474,
          c: 1.22499,
          v: 1105630000
        },
        {
          t: 600,
          o: 1.22497,
          h: 1.22505,
          l: 1.22479,
          c: 1.2248,
          v: 1071430000
        },
        {
          t: 900,
          o: 1.22482,
          h: 1.22501,
          l: 1.22467,
          c: 1.22499,
          v: 983780001
        },
        {
          t: 1200,
          o: 1.22501,
          h: 1.22522,
          l: 1.22491,
          c: 1.225,
          v: 792830000
        },
        {
          t: 1500,
          o: 1.225,
          h: 1.22511,
          l: 1.22493,
          c: 1.22494,
          v: 501960000
        },
        {
          t: 1800,
          o: 1.22494,
          h: 1.22525,
          l: 1.22489,
          c: 1.22515,
          v: 518820000
        },
        {
          t: 2100,
          o: 1.22514,
          h: 1.2253,
          l: 1.22503,
          c: 1.2252,
          v: 612070000
        },
        {
          t: 2400,
          o: 1.2252,
          h: 1.22523,
          l: 1.22491,
          c: 1.22491,
          v: 632950000
        },
        {
          t: 2700,
          o: 1.22491,
          h: 1.22497,
          l: 1.22474,
          c: 1.22483,
          v: 729980000
        },
        {
          t: 3000,
          o: 1.22483,
          h: 1.2249,
          l: 1.22468,
          c: 1.22482,
          v: 786239999
        },
        {
          t: 3300,
          o: 1.22485,
          h: 1.2249,
          l: 1.22474,
          c: 1.22489,
          v: 488770000
        },
        {
          t: 3600,
          o: 1.22489,
          h: 1.22512,
          l: 1.22477,
          c: 1.22505,
          v: 721170000
        },
        {
          t: 3900,
          o: 1.22505,
          h: 1.22538,
          l: 1.22505,
          c: 1.22516,
          v: 887100000
        },
        {
          t: 4200,
          o: 1.22515,
          h: 1.22549,
          l: 1.22512,
          c: 1.22528,
          v: 1059770000
        },
        {
          t: 4500,
          o: 1.22529,
          h: 1.2256,
          l: 1.22525,
          c: 1.22546,
          v: 1059480000
        },
        {
          t: 4800,
          o: 1.22547,
          h: 1.22592,
          l: 1.22534,
          c: 1.22592,
          v: 863470000
        },
        {
          t: 5100,
          o: 1.22591,
          h: 1.22595,
          l: 1.2254,
          c: 1.22554,
          v: 960440000
        },
        {
          t: 5400,
          o: 1.22554,
          h: 1.22567,
          l: 1.22536,
          c: 1.22555,
          v: 841530000
        },
        {
          t: 5700,
          o: 1.22556,
          h: 1.22583,
          l: 1.22549,
          c: 1.22581,
          v: 875320000
        },
        {
          t: 6000,
          o: 1.22581,
          h: 1.22583,
          l: 1.22554,
          c: 1.22576,
          v: 835630000
        },
        {
          t: 6300,
          o: 1.22577,
          h: 1.22593,
          l: 1.22561,
          c: 1.2258,
          v: 790820000
        },
        {
          t: 6600,
          o: 1.22579,
          h: 1.22586,
          l: 1.22559,
          c: 1.2257,
          v: 698180000
        },
        {
          t: 6900,
          o: 1.2257,
          h: 1.2259,
          l: 1.22565,
          c: 1.22578,
          v: 537150000
        },
        {
          t: 7200,
          o: 1.22579,
          h: 1.2259,
          l: 1.22576,
          c: 1.2258,
          v: 531120000
        },
        {
          t: 7500,
          o: 1.2258,
          h: 1.22598,
          l: 1.2258,
          c: 1.2259,
          v: 431600000
        },
        {
          t: 7800,
          o: 1.2259,
          h: 1.22597,
          l: 1.22576,
          c: 1.22586,
          v: 379020000
        },
        {
          t: 8100,
          o: 1.22586,
          h: 1.22586,
          l: 1.2256,
          c: 1.22571,
          v: 616560000
        },
        {
          t: 8400,
          o: 1.22572,
          h: 1.22573,
          l: 1.22547,
          c: 1.22551,
          v: 550640000
        },
        {
          t: 8700,
          o: 1.22551,
          h: 1.22561,
          l: 1.22546,
          c: 1.22555,
          v: 719470000
        },
        {
          t: 9000,
          o: 1.22555,
          h: 1.22563,
          l: 1.2255,
          c: 1.2256,
          v: 574820000
        },
        {
          t: 9300,
          o: 1.22561,
          h: 1.22568,
          l: 1.22557,
          c: 1.22561,
          v: 394520000
        },
        {
          t: 9600,
          o: 1.22561,
          h: 1.22567,
          l: 1.2255,
          c: 1.2255,
          v: 876779999
        },
        {
          t: 9900,
          o: 1.22549,
          h: 1.22562,
          l: 1.22545,
          c: 1.22556,
          v: 494490000
        },
        {
          t: 10200,
          o: 1.22555,
          h: 1.22562,
          l: 1.22547,
          c: 1.22553,
          v: 615830000
        },
        {
          t: 10500,
          o: 1.22555,
          h: 1.22561,
          l: 1.22548,
          c: 1.22548,
          v: 431620000
        },
        {
          t: 10800,
          o: 1.22549,
          h: 1.22557,
          l: 1.22526,
          c: 1.22535,
          v: 915980000
        },
        {
          t: 11100,
          o: 1.22535,
          h: 1.22541,
          l: 1.22534,
          c: 1.2254,
          v: 403680000
        },
        {
          t: 11400,
          o: 1.2254,
          h: 1.2254,
          l: 1.22516,
          c: 1.22521,
          v: 365820000
        },
        {
          t: 11700,
          o: 1.22522,
          h: 1.22524,
          l: 1.22485,
          c: 1.22485,
          v: 653440000
        },
        {
          t: 12000,
          o: 1.22485,
          h: 1.22495,
          l: 1.22474,
          c: 1.22474,
          v: 724500000
        },
        {
          t: 12300,
          o: 1.22475,
          h: 1.2248,
          l: 1.22428,
          c: 1.22435,
          v: 766900000
        },
        {
          t: 12600,
          o: 1.22434,
          h: 1.22441,
          l: 1.22416,
          c: 1.22436,
          v: 679560000
        },
        {
          t: 12900,
          o: 1.22436,
          h: 1.22447,
          l: 1.22421,
          c: 1.22443,
          v: 570460000
        },
        {
          t: 13200,
          o: 1.22445,
          h: 1.22448,
          l: 1.22431,
          c: 1.22433,
          v: 537510000
        },
        {
          t: 13500,
          o: 1.22433,
          h: 1.22459,
          l: 1.22431,
          c: 1.22459,
          v: 506350000
        },
        {
          t: 13800,
          o: 1.2246,
          h: 1.22472,
          l: 1.22457,
          c: 1.22462,
          v: 450240000
        },
        {
          t: 14100,
          o: 1.22462,
          h: 1.22467,
          l: 1.22418,
          c: 1.2242,
          v: 599380000
        },
        {
          t: 14400,
          o: 1.22421,
          h: 1.22422,
          l: 1.22387,
          c: 1.224,
          v: 735570000
        },
        {
          t: 14700,
          o: 1.224,
          h: 1.22421,
          l: 1.224,
          c: 1.22407,
          v: 766900000
        },
        {
          t: 15000,
          o: 1.22408,
          h: 1.22411,
          l: 1.22399,
          c: 1.22404,
          v: 694150000
        },
        {
          t: 15300,
          o: 1.22405,
          h: 1.22421,
          l: 1.22398,
          c: 1.22398,
          v: 535540000
        },
        {
          t: 15600,
          o: 1.22401,
          h: 1.22429,
          l: 1.2237,
          c: 1.22371,
          v: 834750000
        },
        {
          t: 15900,
          o: 1.22371,
          h: 1.22394,
          l: 1.22371,
          c: 1.22394,
          v: 613909999
        },
        {
          t: 16200,
          o: 1.22393,
          h: 1.22394,
          l: 1.22351,
          c: 1.22356,
          v: 1098420000
        },
        {
          t: 16500,
          o: 1.22356,
          h: 1.22383,
          l: 1.2234,
          c: 1.22382,
          v: 1017790000
        },
        {
          t: 16800,
          o: 1.22382,
          h: 1.2241,
          l: 1.22372,
          c: 1.22401,
          v: 712050000
        },
        {
          t: 17100,
          o: 1.22401,
          h: 1.2241,
          l: 1.22379,
          c: 1.22409,
          v: 685680000
        },
        {
          t: 17400,
          o: 1.22409,
          h: 1.22424,
          l: 1.22395,
          c: 1.224,
          v: 908180001
        },
        {
          t: 17700,
          o: 1.22402,
          h: 1.22404,
          l: 1.22381,
          c: 1.22382,
          v: 574830000
        },
        {
          t: 18000,
          o: 1.22382,
          h: 1.2239,
          l: 1.22355,
          c: 1.22387,
          v: 1045120000
        },
        {
          t: 18300,
          o: 1.22386,
          h: 1.224,
          l: 1.22371,
          c: 1.22397,
          v: 941740000
        },
        {
          t: 18600,
          o: 1.22396,
          h: 1.22409,
          l: 1.22386,
          c: 1.22407,
          v: 831560000
        },
        {
          t: 18900,
          o: 1.22407,
          h: 1.22413,
          l: 1.2239,
          c: 1.22396,
          v: 806310000
        },
        {
          t: 19200,
          o: 1.22396,
          h: 1.22396,
          l: 1.22364,
          c: 1.2238,
          v: 1121790000
        },
        {
          t: 19500,
          o: 1.22384,
          h: 1.22408,
          l: 1.22377,
          c: 1.22408,
          v: 634010000
        },
        {
          t: 19800,
          o: 1.2241,
          h: 1.22421,
          l: 1.224,
          c: 1.22417,
          v: 565480000
        },
        {
          t: 20100,
          o: 1.22417,
          h: 1.22423,
          l: 1.22389,
          c: 1.22397,
          v: 697450000
        },
        {
          t: 20400,
          o: 1.22396,
          h: 1.22425,
          l: 1.22394,
          c: 1.22403,
          v: 777380000
        },
        {
          t: 20700,
          o: 1.22403,
          h: 1.22405,
          l: 1.22371,
          c: 1.22375,
          v: 602610000
        },
        {
          t: 21000,
          o: 1.22375,
          h: 1.22381,
          l: 1.2236,
          c: 1.22375,
          v: 647730000
        },
        {
          t: 21300,
          o: 1.22376,
          h: 1.22381,
          l: 1.22361,
          c: 1.22374,
          v: 656510000
        },
        {
          t: 21600,
          o: 1.22377,
          h: 1.22387,
          l: 1.22291,
          c: 1.22292,
          v: 1341790000
        },
        {
          t: 21900,
          o: 1.22294,
          h: 1.22301,
          l: 1.22275,
          c: 1.22298,
          v: 1308840000
        },
        {
          t: 22200,
          o: 1.22299,
          h: 1.22329,
          l: 1.22295,
          c: 1.22322,
          v: 1198740001
        },
        {
          t: 22500,
          o: 1.22322,
          h: 1.22348,
          l: 1.22315,
          c: 1.22319,
          v: 1067440000
        },
        {
          t: 22800,
          o: 1.22319,
          h: 1.22323,
          l: 1.22278,
          c: 1.223,
          v: 1273570000
        },
        {
          t: 23100,
          o: 1.223,
          h: 1.22335,
          l: 1.22291,
          c: 1.22323,
          v: 1560230000
        },
        {
          t: 23400,
          o: 1.22323,
          h: 1.22371,
          l: 1.22314,
          c: 1.2236,
          v: 1599379998
        },
        {
          t: 23700,
          o: 1.2236,
          h: 1.22371,
          l: 1.22341,
          c: 1.22361,
          v: 1233740000
        },
        {
          t: 24000,
          o: 1.22361,
          h: 1.22391,
          l: 1.22341,
          c: 1.22342,
          v: 1411670000
        },
        {
          t: 24300,
          o: 1.22341,
          h: 1.22348,
          l: 1.22294,
          c: 1.2232,
          v: 1469280002
        },
        {
          t: 24600,
          o: 1.22319,
          h: 1.22362,
          l: 1.2231,
          c: 1.22339,
          v: 1253000000
        },
        {
          t: 24900,
          o: 1.2234,
          h: 1.2237,
          l: 1.22309,
          c: 1.22359,
          v: 1479720000
        },
        {
          t: 25200,
          o: 1.22358,
          h: 1.22367,
          l: 1.2228,
          c: 1.22283,
          v: 1574730000
        },
        {
          t: 25500,
          o: 1.22284,
          h: 1.22291,
          l: 1.2225,
          c: 1.22281,
          v: 1276560000
        },
        {
          t: 25800,
          o: 1.22279,
          h: 1.22322,
          l: 1.22278,
          c: 1.22292,
          v: 1203670000
        },
        {
          t: 26100,
          o: 1.22291,
          h: 1.22291,
          l: 1.22247,
          c: 1.22254,
          v: 1233820000
        },
        {
          t: 26400,
          o: 1.22254,
          h: 1.22258,
          l: 1.22197,
          c: 1.22217,
          v: 1307719999
        },
        {
          t: 26700,
          o: 1.22217,
          h: 1.22235,
          l: 1.22151,
          c: 1.2219,
          v: 1436790000
        },
        {
          t: 27000,
          o: 1.2219,
          h: 1.22233,
          l: 1.22181,
          c: 1.22225,
          v: 1242570000
        },
        {
          t: 27300,
          o: 1.22225,
          h: 1.22255,
          l: 1.22211,
          c: 1.22222,
          v: 1356550000
        },
        {
          t: 27600,
          o: 1.22221,
          h: 1.22262,
          l: 1.22217,
          c: 1.2226,
          v: 1151210000
        },
        {
          t: 27900,
          o: 1.2226,
          h: 1.22267,
          l: 1.22237,
          c: 1.22251,
          v: 1185810000
        },
        {
          t: 28200,
          o: 1.22252,
          h: 1.22276,
          l: 1.22233,
          c: 1.22273,
          v: 1296780000
        },
        {
          t: 28500,
          o: 1.22275,
          h: 1.223,
          l: 1.2226,
          c: 1.2227,
          v: 1240040000
        },
        {
          t: 28800,
          o: 1.2227,
          h: 1.22308,
          l: 1.22262,
          c: 1.22299,
          v: 1323980000
        },
        {
          t: 29100,
          o: 1.22298,
          h: 1.22337,
          l: 1.22296,
          c: 1.22308,
          v: 1247869999
        },
        {
          t: 29400,
          o: 1.22307,
          h: 1.22337,
          l: 1.22303,
          c: 1.22307,
          v: 1243950000
        },
        {
          t: 29700,
          o: 1.22307,
          h: 1.22307,
          l: 1.22256,
          c: 1.22263,
          v: 1036960000
        },
        {
          t: 30000,
          o: 1.22263,
          h: 1.22273,
          l: 1.22217,
          c: 1.22242,
          v: 1240990000
        },
        {
          t: 30300,
          o: 1.22243,
          h: 1.22282,
          l: 1.22242,
          c: 1.22278,
          v: 1100700000
        },
        {
          t: 30600,
          o: 1.22278,
          h: 1.22327,
          l: 1.22262,
          c: 1.22325,
          v: 1169380000
        },
        {
          t: 30900,
          o: 1.22328,
          h: 1.22329,
          l: 1.22312,
          c: 1.22329,
          v: 970930000
        },
        {
          t: 31200,
          o: 1.22328,
          h: 1.22365,
          l: 1.22328,
          c: 1.2234,
          v: 1049880000
        },
        {
          t: 31500,
          o: 1.2234,
          h: 1.22356,
          l: 1.22322,
          c: 1.22333,
          v: 952290000
        },
        {
          t: 31800,
          o: 1.22332,
          h: 1.22368,
          l: 1.22331,
          c: 1.22349,
          v: 1006330000
        },
        {
          t: 32100,
          o: 1.2235,
          h: 1.22353,
          l: 1.22309,
          c: 1.22349,
          v: 1219200000
        },
        {
          t: 32400,
          o: 1.22349,
          h: 1.22365,
          l: 1.22348,
          c: 1.22357,
          v: 802880002
        },
        {
          t: 32700,
          o: 1.22356,
          h: 1.22399,
          l: 1.22349,
          c: 1.22374,
          v: 968920000
        },
        {
          t: 33000,
          o: 1.22375,
          h: 1.22391,
          l: 1.22359,
          c: 1.22391,
          v: 837989999
        },
        {
          t: 33300,
          o: 1.22392,
          h: 1.22415,
          l: 1.22381,
          c: 1.22388,
          v: 1179900000
        },
        {
          t: 33600,
          o: 1.22387,
          h: 1.22404,
          l: 1.22379,
          c: 1.22399,
          v: 930960000
        },
        {
          t: 33900,
          o: 1.22399,
          h: 1.22422,
          l: 1.22391,
          c: 1.22405,
          v: 1119610000
        },
        {
          t: 34200,
          o: 1.22405,
          h: 1.22462,
          l: 1.224,
          c: 1.22449,
          v: 995940000
        },
        {
          t: 34500,
          o: 1.22451,
          h: 1.22471,
          l: 1.22438,
          c: 1.2244,
          v: 1061700000
        },
        {
          t: 34800,
          o: 1.22443,
          h: 1.22454,
          l: 1.22427,
          c: 1.22432,
          v: 1070230000
        },
        {
          t: 35100,
          o: 1.22432,
          h: 1.22433,
          l: 1.22394,
          c: 1.224,
          v: 1133540000
        },
        {
          t: 35400,
          o: 1.22401,
          h: 1.2242,
          l: 1.22372,
          c: 1.2238,
          v: 938160000
        },
        {
          t: 35700,
          o: 1.22378,
          h: 1.22446,
          l: 1.22376,
          c: 1.22429,
          v: 949150002
        },
        {
          t: 36000,
          o: 1.22429,
          h: 1.22444,
          l: 1.22419,
          c: 1.22423,
          v: 1044070000
        },
        {
          t: 36300,
          o: 1.22423,
          h: 1.22441,
          l: 1.22388,
          c: 1.224,
          v: 917400000
        },
        {
          t: 36600,
          o: 1.22402,
          h: 1.22458,
          l: 1.22401,
          c: 1.2245,
          v: 956440000
        },
        {
          t: 36900,
          o: 1.22449,
          h: 1.22459,
          l: 1.22414,
          c: 1.22424,
          v: 1029080000
        },
        {
          t: 37200,
          o: 1.22424,
          h: 1.2244,
          l: 1.2241,
          c: 1.2242,
          v: 905130000
        },
        {
          t: 37500,
          o: 1.2242,
          h: 1.22458,
          l: 1.2242,
          c: 1.22451,
          v: 1029560000
        },
        {
          t: 37800,
          o: 1.22451,
          h: 1.22451,
          l: 1.22419,
          c: 1.2243,
          v: 952130000
        },
        {
          t: 38100,
          o: 1.22429,
          h: 1.22459,
          l: 1.22421,
          c: 1.22452,
          v: 850380000
        },
        {
          t: 38400,
          o: 1.22452,
          h: 1.22458,
          l: 1.22431,
          c: 1.22438,
          v: 823700000
        },
        {
          t: 38700,
          o: 1.22438,
          h: 1.22453,
          l: 1.22427,
          c: 1.22429,
          v: 821660000
        },
        {
          t: 39000,
          o: 1.22431,
          h: 1.22445,
          l: 1.22419,
          c: 1.22439,
          v: 722150000
        },
        {
          t: 39300,
          o: 1.22438,
          h: 1.22443,
          l: 1.22392,
          c: 1.22396,
          v: 1024820000
        },
        {
          t: 39600,
          o: 1.22396,
          h: 1.22419,
          l: 1.22388,
          c: 1.22415,
          v: 862300000
        },
        {
          t: 39900,
          o: 1.22414,
          h: 1.22425,
          l: 1.22399,
          c: 1.22399,
          v: 838400000
        },
        {
          t: 40200,
          o: 1.224,
          h: 1.22413,
          l: 1.22376,
          c: 1.22385,
          v: 1055950000
        },
        {
          t: 40500,
          o: 1.22383,
          h: 1.22394,
          l: 1.22363,
          c: 1.22382,
          v: 1247810001
        },
        {
          t: 40800,
          o: 1.22382,
          h: 1.22393,
          l: 1.22366,
          c: 1.2238,
          v: 863210000
        },
        {
          t: 41100,
          o: 1.2238,
          h: 1.22384,
          l: 1.22358,
          c: 1.22359,
          v: 983200000
        },
        {
          t: 41400,
          o: 1.2236,
          h: 1.22378,
          l: 1.22358,
          c: 1.22367,
          v: 1089789999
        },
        {
          t: 41700,
          o: 1.22368,
          h: 1.22378,
          l: 1.2236,
          c: 1.22362,
          v: 896400000
        },
        {
          t: 42000,
          o: 1.22363,
          h: 1.22369,
          l: 1.22348,
          c: 1.22368,
          v: 921510000
        },
        {
          t: 42300,
          o: 1.22369,
          h: 1.22395,
          l: 1.22362,
          c: 1.22391,
          v: 472290000
        },
        {
          t: 42600,
          o: 1.22392,
          h: 1.22409,
          l: 1.22386,
          c: 1.22404,
          v: 555460000
        },
        {
          t: 42900,
          o: 1.22404,
          h: 1.22409,
          l: 1.22311,
          c: 1.22317,
          v: 1311020000
        },
        {
          t: 43200,
          o: 1.22317,
          h: 1.22329,
          l: 1.22292,
          c: 1.22312,
          v: 1014080000
        },
        {
          t: 43500,
          o: 1.22311,
          h: 1.22339,
          l: 1.22304,
          c: 1.22331,
          v: 1186820000
        },
        {
          t: 43800,
          o: 1.2233,
          h: 1.22341,
          l: 1.22309,
          c: 1.22338,
          v: 1020750000
        },
        {
          t: 44100,
          o: 1.22338,
          h: 1.22373,
          l: 1.22316,
          c: 1.22373,
          v: 940890001
        },
        {
          t: 44400,
          o: 1.22376,
          h: 1.22412,
          l: 1.22341,
          c: 1.22343,
          v: 1306660000
        },
        {
          t: 44700,
          o: 1.22345,
          h: 1.22405,
          l: 1.22298,
          c: 1.223,
          v: 1400830000
        },
        {
          t: 45000,
          o: 1.223,
          h: 1.22563,
          l: 1.223,
          c: 1.22554,
          v: 3238660000
        },
        {
          t: 45300,
          o: 1.22552,
          h: 1.22566,
          l: 1.22381,
          c: 1.22396,
          v: 2753340000
        },
        {
          t: 45600,
          o: 1.22396,
          h: 1.22418,
          l: 1.2232,
          c: 1.22344,
          v: 2002330000
        },
        {
          t: 45900,
          o: 1.22347,
          h: 1.22355,
          l: 1.22193,
          c: 1.22259,
          v: 2213550000
        },
        {
          t: 46200,
          o: 1.22254,
          h: 1.22401,
          l: 1.22249,
          c: 1.22376,
          v: 1999030001
        },
        {
          t: 46500,
          o: 1.22376,
          h: 1.22496,
          l: 1.22366,
          c: 1.22476,
          v: 2022050000
        },
        {
          t: 46800,
          o: 1.22479,
          h: 1.22541,
          l: 1.22439,
          c: 1.22533,
          v: 2320390001
        },
        {
          t: 47100,
          o: 1.22537,
          h: 1.22583,
          l: 1.22515,
          c: 1.22546,
          v: 2084130000
        },
        {
          t: 47400,
          o: 1.22547,
          h: 1.22664,
          l: 1.22545,
          c: 1.22566,
          v: 2691319999
        },
        {
          t: 47700,
          o: 1.22563,
          h: 1.22703,
          l: 1.22563,
          c: 1.22677,
          v: 2585050000
        },
        {
          t: 48000,
          o: 1.22677,
          h: 1.22749,
          l: 1.22668,
          c: 1.22736,
          v: 2318030001
        },
        {
          t: 48300,
          o: 1.22738,
          h: 1.22799,
          l: 1.22694,
          c: 1.22745,
          v: 2370720000
        },
        {
          t: 48600,
          o: 1.22748,
          h: 1.22784,
          l: 1.22707,
          c: 1.22765,
          v: 2485840001
        },
        {
          t: 48900,
          o: 1.22767,
          h: 1.22798,
          l: 1.22725,
          c: 1.22735,
          v: 1829289999
        },
        {
          t: 49200,
          o: 1.22736,
          h: 1.22788,
          l: 1.22725,
          c: 1.22771,
          v: 1754900001
        },
        {
          t: 49500,
          o: 1.22772,
          h: 1.228,
          l: 1.22733,
          c: 1.22735,
          v: 1800440000
        },
        {
          t: 49800,
          o: 1.22737,
          h: 1.22742,
          l: 1.22675,
          c: 1.22691,
          v: 1967320000
        },
        {
          t: 50100,
          o: 1.22694,
          h: 1.22698,
          l: 1.22658,
          c: 1.22683,
          v: 1467700000
        },
        {
          t: 50400,
          o: 1.22683,
          h: 1.22697,
          l: 1.22639,
          c: 1.22667,
          v: 1558820000
        },
        {
          t: 50700,
          o: 1.22665,
          h: 1.22672,
          l: 1.22642,
          c: 1.22642,
          v: 1221280000
        },
        {
          t: 51000,
          o: 1.2264,
          h: 1.227,
          l: 1.22629,
          c: 1.22697,
          v: 1383760001
        },
        {
          t: 51300,
          o: 1.22697,
          h: 1.22761,
          l: 1.22692,
          c: 1.22721,
          v: 1574779999
        },
        {
          t: 51600,
          o: 1.22721,
          h: 1.22721,
          l: 1.22657,
          c: 1.22699,
          v: 1476020000
        },
        {
          t: 51900,
          o: 1.227,
          h: 1.22708,
          l: 1.22649,
          c: 1.22657,
          v: 1551930000
        },
        {
          t: 52200,
          o: 1.22658,
          h: 1.22706,
          l: 1.22655,
          c: 1.22698,
          v: 1201490000
        },
        {
          t: 52500,
          o: 1.22698,
          h: 1.22712,
          l: 1.22675,
          c: 1.22707,
          v: 1165380001
        },
        {
          t: 52800,
          o: 1.2271,
          h: 1.22748,
          l: 1.22696,
          c: 1.22696,
          v: 1339040000
        },
        {
          t: 53100,
          o: 1.22697,
          h: 1.22727,
          l: 1.22678,
          c: 1.22706,
          v: 1533370000
        },
        {
          t: 53400,
          o: 1.22708,
          h: 1.22751,
          l: 1.22702,
          c: 1.22702,
          v: 1479730000
        },
        {
          t: 53700,
          o: 1.22702,
          h: 1.22702,
          l: 1.22569,
          c: 1.22618,
          v: 1889270000
        },
        {
          t: 54000,
          o: 1.22618,
          h: 1.22647,
          l: 1.22605,
          c: 1.22631,
          v: 2147440000
        },
        {
          t: 54300,
          o: 1.22634,
          h: 1.22695,
          l: 1.22612,
          c: 1.22685,
          v: 1582690003
        },
        {
          t: 54600,
          o: 1.22688,
          h: 1.22704,
          l: 1.22658,
          c: 1.22658,
          v: 1168660000
        },
        {
          t: 54900,
          o: 1.2266,
          h: 1.22697,
          l: 1.22651,
          c: 1.22695,
          v: 1157270000
        },
        {
          t: 55200,
          o: 1.22696,
          h: 1.22762,
          l: 1.22695,
          c: 1.22759,
          v: 1263119999
        },
        {
          t: 55500,
          o: 1.22758,
          h: 1.22778,
          l: 1.22735,
          c: 1.22752,
          v: 1362830000
        },
        {
          t: 55800,
          o: 1.22752,
          h: 1.22758,
          l: 1.22712,
          c: 1.22718,
          v: 1292640000
        },
        {
          t: 56100,
          o: 1.22717,
          h: 1.22743,
          l: 1.22713,
          c: 1.22722,
          v: 1099310000
        },
        {
          t: 56400,
          o: 1.22722,
          h: 1.22797,
          l: 1.22722,
          c: 1.22783,
          v: 1108860001
        },
        {
          t: 56700,
          o: 1.22781,
          h: 1.22788,
          l: 1.22718,
          c: 1.22746,
          v: 1375280000
        },
        {
          t: 57000,
          o: 1.22746,
          h: 1.22754,
          l: 1.22705,
          c: 1.22748,
          v: 930160000
        },
        {
          t: 57300,
          o: 1.22748,
          h: 1.22764,
          l: 1.2273,
          c: 1.22738,
          v: 697150000
        },
        {
          t: 57600,
          o: 1.22737,
          h: 1.22737,
          l: 1.22683,
          c: 1.22717,
          v: 940040000
        },
        {
          t: 57900,
          o: 1.22717,
          h: 1.22734,
          l: 1.22696,
          c: 1.22734,
          v: 1056720000
        },
        {
          t: 58200,
          o: 1.22734,
          h: 1.22758,
          l: 1.22727,
          c: 1.22753,
          v: 752370000
        },
        {
          t: 58500,
          o: 1.22752,
          h: 1.22764,
          l: 1.22729,
          c: 1.22753,
          v: 740430000
        },
        {
          t: 58800,
          o: 1.22752,
          h: 1.22789,
          l: 1.22742,
          c: 1.22764,
          v: 1162760001
        },
        {
          t: 59100,
          o: 1.22766,
          h: 1.22768,
          l: 1.22681,
          c: 1.22681,
          v: 953980000
        },
        {
          t: 59400,
          o: 1.22681,
          h: 1.22736,
          l: 1.22661,
          c: 1.22726,
          v: 1099270000
        },
        {
          t: 59700,
          o: 1.22725,
          h: 1.22727,
          l: 1.22677,
          c: 1.22687,
          v: 1314790000
        },
        {
          t: 60000,
          o: 1.22687,
          h: 1.22767,
          l: 1.22683,
          c: 1.22725,
          v: 1288970000
        },
        {
          t: 60300,
          o: 1.22725,
          h: 1.22757,
          l: 1.22717,
          c: 1.22737,
          v: 1056030000
        },
        {
          t: 60600,
          o: 1.22736,
          h: 1.22758,
          l: 1.22731,
          c: 1.22754,
          v: 805000000
        },
        {
          t: 60900,
          o: 1.22756,
          h: 1.22757,
          l: 1.22729,
          c: 1.22756,
          v: 1221180000
        },
        {
          t: 61200,
          o: 1.22756,
          h: 1.22768,
          l: 1.22735,
          c: 1.22741,
          v: 1077940000
        },
        {
          t: 61500,
          o: 1.2274,
          h: 1.22748,
          l: 1.22698,
          c: 1.22709,
          v: 935360000
        },
        {
          t: 61800,
          o: 1.22708,
          h: 1.22714,
          l: 1.22683,
          c: 1.22689,
          v: 814229999
        },
        {
          t: 62100,
          o: 1.22689,
          h: 1.22717,
          l: 1.22678,
          c: 1.22703,
          v: 1007970000
        },
        {
          t: 62400,
          o: 1.22702,
          h: 1.22733,
          l: 1.22702,
          c: 1.2273,
          v: 1048630000
        },
        {
          t: 62700,
          o: 1.2273,
          h: 1.22748,
          l: 1.22716,
          c: 1.22745,
          v: 588450001
        },
        {
          t: 63000,
          o: 1.22745,
          h: 1.22758,
          l: 1.22689,
          c: 1.22696,
          v: 1701739998
        },
        {
          t: 63300,
          o: 1.22696,
          h: 1.22752,
          l: 1.22696,
          c: 1.22751,
          v: 1356900001
        },
        {
          t: 63600,
          o: 1.22752,
          h: 1.22768,
          l: 1.22736,
          c: 1.22766,
          v: 815200000
        },
        {
          t: 63900,
          o: 1.22763,
          h: 1.2279,
          l: 1.22757,
          c: 1.22778,
          v: 1223380000
        },
        {
          t: 64200,
          o: 1.22777,
          h: 1.22806,
          l: 1.22774,
          c: 1.22787,
          v: 1332600001
        },
        {
          t: 64500,
          o: 1.22788,
          h: 1.22818,
          l: 1.22779,
          c: 1.22816,
          v: 1294250000
        },
        {
          t: 64800,
          o: 1.22819,
          h: 1.22889,
          l: 1.22819,
          c: 1.22878,
          v: 1352390002
        },
        {
          t: 65100,
          o: 1.22878,
          h: 1.22893,
          l: 1.22869,
          c: 1.22877,
          v: 949400000
        },
        {
          t: 65400,
          o: 1.22876,
          h: 1.22877,
          l: 1.22839,
          c: 1.22853,
          v: 1107810000
        },
        {
          t: 65700,
          o: 1.22855,
          h: 1.22888,
          l: 1.22854,
          c: 1.22873,
          v: 1134300000
        },
        {
          t: 66000,
          o: 1.22874,
          h: 1.22875,
          l: 1.2283,
          c: 1.22851,
          v: 1166170000
        },
        {
          t: 66300,
          o: 1.22849,
          h: 1.22868,
          l: 1.22843,
          c: 1.22848,
          v: 693360000
        },
        {
          t: 66600,
          o: 1.2285,
          h: 1.2285,
          l: 1.22819,
          c: 1.22826,
          v: 624240000
        },
        {
          t: 66900,
          o: 1.22826,
          h: 1.22855,
          l: 1.2282,
          c: 1.22821,
          v: 864810000
        },
        {
          t: 67200,
          o: 1.22822,
          h: 1.22823,
          l: 1.22793,
          c: 1.22795,
          v: 1103470000
        },
        {
          t: 67500,
          o: 1.22794,
          h: 1.22811,
          l: 1.22783,
          c: 1.22784,
          v: 946700000
        },
        {
          t: 67800,
          o: 1.22783,
          h: 1.22804,
          l: 1.22778,
          c: 1.22792,
          v: 820400000
        },
        {
          t: 68100,
          o: 1.22791,
          h: 1.22858,
          l: 1.22788,
          c: 1.22853,
          v: 1110220000
        },
        {
          t: 68400,
          o: 1.22856,
          h: 1.22871,
          l: 1.22836,
          c: 1.22863,
          v: 1115430000
        },
        {
          t: 68700,
          o: 1.22863,
          h: 1.22907,
          l: 1.22856,
          c: 1.22878,
          v: 1239430000
        },
        {
          t: 69000,
          o: 1.22878,
          h: 1.22905,
          l: 1.22878,
          c: 1.22887,
          v: 1081290000
        },
        {
          t: 69300,
          o: 1.22887,
          h: 1.22887,
          l: 1.2286,
          c: 1.22868,
          v: 1156650000
        },
        {
          t: 69600,
          o: 1.22867,
          h: 1.22877,
          l: 1.2284,
          c: 1.22872,
          v: 1055730000
        },
        {
          t: 69900,
          o: 1.22871,
          h: 1.22877,
          l: 1.22831,
          c: 1.22847,
          v: 1012470000
        },
        {
          t: 70200,
          o: 1.22846,
          h: 1.22854,
          l: 1.22823,
          c: 1.22832,
          v: 634920000
        },
        {
          t: 70500,
          o: 1.22833,
          h: 1.22836,
          l: 1.22813,
          c: 1.22822,
          v: 831890000
        },
        {
          t: 70800,
          o: 1.22823,
          h: 1.22827,
          l: 1.22816,
          c: 1.22822,
          v: 785760000
        },
        {
          t: 71100,
          o: 1.22822,
          h: 1.22859,
          l: 1.22822,
          c: 1.22854,
          v: 761100000
        },
        {
          t: 71400,
          o: 1.22854,
          h: 1.22862,
          l: 1.22843,
          c: 1.22852,
          v: 1019270000
        },
        {
          t: 71700,
          o: 1.22852,
          h: 1.22853,
          l: 1.22836,
          c: 1.22853,
          v: 801590000
        },
        {
          t: 72000,
          o: 1.22852,
          h: 1.22859,
          l: 1.22832,
          c: 1.22847,
          v: 573440000
        },
        {
          t: 72300,
          o: 1.22847,
          h: 1.22867,
          l: 1.22843,
          c: 1.22843,
          v: 364930000
        },
        {
          t: 72600,
          o: 1.22842,
          h: 1.22842,
          l: 1.22822,
          c: 1.22827,
          v: 374500000
        },
        {
          t: 72900,
          o: 1.22828,
          h: 1.22832,
          l: 1.22822,
          c: 1.22822,
          v: 240670000
        },
        {
          t: 73200,
          o: 1.22823,
          h: 1.22833,
          l: 1.22819,
          c: 1.22822,
          v: 238220000
        },
        {
          t: 73500,
          o: 1.22822,
          h: 1.22824,
          l: 1.2281,
          c: 1.22811,
          v: 321670000
        },
        {
          t: 73800,
          o: 1.22811,
          h: 1.2282,
          l: 1.22807,
          c: 1.22809,
          v: 404030001
        },
        {
          t: 74100,
          o: 1.2281,
          h: 1.22813,
          l: 1.22803,
          c: 1.2281,
          v: 422560000
        },
        {
          t: 74400,
          o: 1.2281,
          h: 1.22811,
          l: 1.22791,
          c: 1.228,
          v: 1259640004
        },
        {
          t: 74700,
          o: 1.228,
          h: 1.22807,
          l: 1.22799,
          c: 1.22799,
          v: 341420000
        },
        {
          t: 75000,
          o: 1.228,
          h: 1.22809,
          l: 1.22798,
          c: 1.22807,
          v: 331150000
        },
        {
          t: 75300,
          o: 1.22806,
          h: 1.22823,
          l: 1.22792,
          c: 1.22792,
          v: 383900000
        }
      ]
    },
    {
      d: 1523145600,
      b: [
        {
          t: 75600,
          o: 1.22824,
          h: 1.22833,
          l: 1.22786,
          c: 1.22808,
          v: 36670000
        },
        {
          t: 75900,
          o: 1.22809,
          h: 1.22813,
          l: 1.22744,
          c: 1.22757,
          v: 235380000
        },
        {
          t: 76200,
          o: 1.2276,
          h: 1.22791,
          l: 1.2276,
          c: 1.22791,
          v: 118750000
        },
        {
          t: 76500,
          o: 1.22791,
          h: 1.22794,
          l: 1.22788,
          c: 1.22789,
          v: 25660000
        },
        {
          t: 76800,
          o: 1.22788,
          h: 1.22792,
          l: 1.22788,
          c: 1.22792,
          v: 37120000
        },
        {
          t: 77100,
          o: 1.22793,
          h: 1.22801,
          l: 1.22793,
          c: 1.22801,
          v: 47530000
        },
        {
          t: 77400,
          o: 1.22801,
          h: 1.22805,
          l: 1.22785,
          c: 1.22785,
          v: 120010000
        },
        {
          t: 77700,
          o: 1.22784,
          h: 1.2279,
          l: 1.2277,
          c: 1.22783,
          v: 214810000
        },
        {
          t: 78000,
          o: 1.22783,
          h: 1.22804,
          l: 1.22783,
          c: 1.22803,
          v: 139810000
        },
        {
          t: 78300,
          o: 1.22801,
          h: 1.22809,
          l: 1.22801,
          c: 1.22806,
          v: 67230000
        },
        {
          t: 78600,
          o: 1.22806,
          h: 1.2283,
          l: 1.22806,
          c: 1.22812,
          v: 216160000
        },
        {
          t: 78900,
          o: 1.22811,
          h: 1.22811,
          l: 1.22763,
          c: 1.22775,
          v: 112400000
        },
        {
          t: 79200,
          o: 1.22774,
          h: 1.22783,
          l: 1.22728,
          c: 1.22739,
          v: 431070000
        },
        {
          t: 79500,
          o: 1.2274,
          h: 1.22774,
          l: 1.2274,
          c: 1.22774,
          v: 342000000
        },
        {
          t: 79800,
          o: 1.22773,
          h: 1.22775,
          l: 1.2277,
          c: 1.22771,
          v: 147380000
        },
        {
          t: 80100,
          o: 1.22772,
          h: 1.22787,
          l: 1.2277,
          c: 1.22786,
          v: 195020000
        },
        {
          t: 80400,
          o: 1.22786,
          h: 1.2279,
          l: 1.22779,
          c: 1.22779,
          v: 208180000
        },
        {
          t: 80700,
          o: 1.22777,
          h: 1.22777,
          l: 1.22768,
          c: 1.2277,
          v: 200490000
        },
        {
          t: 81000,
          o: 1.2277,
          h: 1.22771,
          l: 1.22737,
          c: 1.22744,
          v: 294560000
        },
        {
          t: 81300,
          o: 1.22743,
          h: 1.22744,
          l: 1.22737,
          c: 1.22739,
          v: 275410000
        },
        {
          t: 81600,
          o: 1.22741,
          h: 1.2275,
          l: 1.22738,
          c: 1.22741,
          v: 247890000
        },
        {
          t: 81900,
          o: 1.22742,
          h: 1.22749,
          l: 1.2274,
          c: 1.22743,
          v: 75520000
        },
        {
          t: 82200,
          o: 1.22743,
          h: 1.22751,
          l: 1.22737,
          c: 1.22739,
          v: 143490000
        },
        {
          t: 82500,
          o: 1.2274,
          h: 1.22745,
          l: 1.22734,
          c: 1.22738,
          v: 206420000
        },
        {
          t: 82800,
          o: 1.22739,
          h: 1.22768,
          l: 1.22737,
          c: 1.22753,
          v: 514630000
        },
        {
          t: 83100,
          o: 1.22753,
          h: 1.2276,
          l: 1.22725,
          c: 1.22741,
          v: 521850000
        },
        {
          t: 83400,
          o: 1.22742,
          h: 1.22752,
          l: 1.22721,
          c: 1.22726,
          v: 338440000
        },
        {
          t: 83700,
          o: 1.22727,
          h: 1.22727,
          l: 1.22699,
          c: 1.22705,
          v: 459430000
        },
        {
          t: 84000,
          o: 1.22708,
          h: 1.2271,
          l: 1.2267,
          c: 1.22676,
          v: 664690000
        },
        {
          t: 84300,
          o: 1.22675,
          h: 1.22682,
          l: 1.22665,
          c: 1.22669,
          v: 388370000
        },
        {
          t: 84600,
          o: 1.22669,
          h: 1.22683,
          l: 1.22656,
          c: 1.22663,
          v: 374840000
        },
        {
          t: 84900,
          o: 1.22662,
          h: 1.2268,
          l: 1.22662,
          c: 1.22677,
          v: 491730000
        },
        {
          t: 85200,
          o: 1.22674,
          h: 1.22683,
          l: 1.22671,
          c: 1.22683,
          v: 300260000
        },
        {
          t: 85500,
          o: 1.22683,
          h: 1.22698,
          l: 1.22681,
          c: 1.22687,
          v: 407920000
        },
        {
          t: 85800,
          o: 1.22687,
          h: 1.22706,
          l: 1.22687,
          c: 1.22699,
          v: 266210000
        },
        {
          t: 86100,
          o: 1.22701,
          h: 1.22715,
          l: 1.22701,
          c: 1.22709,
          v: 318320000
        }
      ]
    },
    {
      d: 1523232000,
      b: [
        {
          t: 0,
          o: 1.22709,
          h: 1.22729,
          l: 1.22698,
          c: 1.22729,
          v: 557570000
        },
        {
          t: 300,
          o: 1.22729,
          h: 1.22732,
          l: 1.22678,
          c: 1.2268,
          v: 588110000
        },
        {
          t: 600,
          o: 1.2268,
          h: 1.22696,
          l: 1.22657,
          c: 1.22689,
          v: 622719999
        },
        {
          t: 900,
          o: 1.2269,
          h: 1.22697,
          l: 1.22677,
          c: 1.22694,
          v: 560290000
        },
        {
          t: 1200,
          o: 1.22693,
          h: 1.22717,
          l: 1.22692,
          c: 1.22712,
          v: 387650000
        },
        {
          t: 1500,
          o: 1.22713,
          h: 1.22716,
          l: 1.22696,
          c: 1.22714,
          v: 266410000
        },
        {
          t: 1800,
          o: 1.22715,
          h: 1.22736,
          l: 1.227,
          c: 1.22719,
          v: 515880000
        },
        {
          t: 2100,
          o: 1.22719,
          h: 1.22742,
          l: 1.22703,
          c: 1.22706,
          v: 643570000
        },
        {
          t: 2400,
          o: 1.22707,
          h: 1.22715,
          l: 1.22684,
          c: 1.22685,
          v: 501390000
        },
        {
          t: 2700,
          o: 1.22685,
          h: 1.22685,
          l: 1.22676,
          c: 1.22681,
          v: 391910000
        },
        {
          t: 3000,
          o: 1.22681,
          h: 1.22732,
          l: 1.2268,
          c: 1.2273,
          v: 681050000
        },
        {
          t: 3300,
          o: 1.2273,
          h: 1.22752,
          l: 1.22716,
          c: 1.22747,
          v: 978860000
        },
        {
          t: 3600,
          o: 1.22749,
          h: 1.22764,
          l: 1.22715,
          c: 1.2274,
          v: 1040970000
        },
        {
          t: 3900,
          o: 1.22739,
          h: 1.22758,
          l: 1.22736,
          c: 1.22757,
          v: 798260000
        },
        {
          t: 4200,
          o: 1.22759,
          h: 1.22767,
          l: 1.22727,
          c: 1.22728,
          v: 731630000
        },
        {
          t: 4500,
          o: 1.22728,
          h: 1.22738,
          l: 1.22714,
          c: 1.2273,
          v: 889140000
        },
        {
          t: 4800,
          o: 1.2273,
          h: 1.22741,
          l: 1.2271,
          c: 1.22722,
          v: 851240000
        },
        {
          t: 5100,
          o: 1.22722,
          h: 1.22743,
          l: 1.2272,
          c: 1.22721,
          v: 608830000
        },
        {
          t: 5400,
          o: 1.22721,
          h: 1.22768,
          l: 1.22721,
          c: 1.22767,
          v: 838390000
        },
        {
          t: 5700,
          o: 1.22768,
          h: 1.22773,
          l: 1.22725,
          c: 1.22725,
          v: 605530000
        },
        {
          t: 6000,
          o: 1.22723,
          h: 1.22742,
          l: 1.22716,
          c: 1.22739,
          v: 744940000
        },
        {
          t: 6300,
          o: 1.22739,
          h: 1.22757,
          l: 1.22734,
          c: 1.2275,
          v: 525360000
        },
        {
          t: 6600,
          o: 1.22749,
          h: 1.22757,
          l: 1.22731,
          c: 1.22737,
          v: 380280000
        },
        {
          t: 6900,
          o: 1.22737,
          h: 1.22744,
          l: 1.22711,
          c: 1.22711,
          v: 564350000
        },
        {
          t: 7200,
          o: 1.2271,
          h: 1.22723,
          l: 1.22704,
          c: 1.2271,
          v: 651480000
        },
        {
          t: 7500,
          o: 1.2271,
          h: 1.22719,
          l: 1.2271,
          c: 1.22717,
          v: 211400000
        },
        {
          t: 7800,
          o: 1.22717,
          h: 1.22718,
          l: 1.22705,
          c: 1.22709,
          v: 272230001
        },
        {
          t: 8100,
          o: 1.22708,
          h: 1.22716,
          l: 1.22697,
          c: 1.22697,
          v: 366440000
        },
        {
          t: 8400,
          o: 1.22698,
          h: 1.22698,
          l: 1.22658,
          c: 1.22671,
          v: 782120000
        },
        {
          t: 8700,
          o: 1.22672,
          h: 1.22678,
          l: 1.22659,
          c: 1.2266,
          v: 375680000
        },
        {
          t: 9000,
          o: 1.2266,
          h: 1.22698,
          l: 1.2266,
          c: 1.22687,
          v: 490980000
        },
        {
          t: 9300,
          o: 1.22686,
          h: 1.22702,
          l: 1.22683,
          c: 1.22686,
          v: 723579999
        },
        {
          t: 9600,
          o: 1.22686,
          h: 1.22702,
          l: 1.22685,
          c: 1.22691,
          v: 538230000
        },
        {
          t: 9900,
          o: 1.22691,
          h: 1.22692,
          l: 1.22686,
          c: 1.22691,
          v: 264110000
        },
        {
          t: 10200,
          o: 1.22692,
          h: 1.2274,
          l: 1.22692,
          c: 1.2273,
          v: 566560000
        },
        {
          t: 10500,
          o: 1.2273,
          h: 1.2274,
          l: 1.22721,
          c: 1.22736,
          v: 630500000
        },
        {
          t: 10800,
          o: 1.22737,
          h: 1.22747,
          l: 1.22717,
          c: 1.22722,
          v: 503870000
        },
        {
          t: 11100,
          o: 1.22722,
          h: 1.22736,
          l: 1.2272,
          c: 1.22725,
          v: 665870000
        },
        {
          t: 11400,
          o: 1.22725,
          h: 1.22733,
          l: 1.22721,
          c: 1.22725,
          v: 548680000
        },
        {
          t: 11700,
          o: 1.22726,
          h: 1.22727,
          l: 1.22684,
          c: 1.22691,
          v: 548420000
        },
        {
          t: 12000,
          o: 1.22693,
          h: 1.22703,
          l: 1.22687,
          c: 1.22696,
          v: 329190000
        },
        {
          t: 12300,
          o: 1.22696,
          h: 1.22698,
          l: 1.22688,
          c: 1.22695,
          v: 423710000
        },
        {
          t: 12600,
          o: 1.22694,
          h: 1.22729,
          l: 1.22692,
          c: 1.22714,
          v: 405750000
        },
        {
          t: 12900,
          o: 1.22714,
          h: 1.22719,
          l: 1.22704,
          c: 1.22712,
          v: 677900000
        },
        {
          t: 13200,
          o: 1.22712,
          h: 1.22743,
          l: 1.22712,
          c: 1.22743,
          v: 494740000
        },
        {
          t: 13500,
          o: 1.22743,
          h: 1.22743,
          l: 1.22725,
          c: 1.22732,
          v: 673660000
        },
        {
          t: 13800,
          o: 1.22732,
          h: 1.22753,
          l: 1.22725,
          c: 1.22743,
          v: 587000000
        },
        {
          t: 14100,
          o: 1.22743,
          h: 1.22745,
          l: 1.22723,
          c: 1.22723,
          v: 536780000
        },
        {
          t: 14400,
          o: 1.22725,
          h: 1.22735,
          l: 1.2272,
          c: 1.22733,
          v: 692380000
        },
        {
          t: 14700,
          o: 1.22734,
          h: 1.22737,
          l: 1.22722,
          c: 1.22733,
          v: 469770000
        },
        {
          t: 15000,
          o: 1.22732,
          h: 1.22744,
          l: 1.22729,
          c: 1.2273,
          v: 393380000
        },
        {
          t: 15300,
          o: 1.2273,
          h: 1.22747,
          l: 1.22725,
          c: 1.22732,
          v: 509460000
        },
        {
          t: 15600,
          o: 1.22732,
          h: 1.22734,
          l: 1.22725,
          c: 1.2273,
          v: 504050000
        },
        {
          t: 15900,
          o: 1.2273,
          h: 1.22733,
          l: 1.22723,
          c: 1.2273,
          v: 304730000
        },
        {
          t: 16200,
          o: 1.2273,
          h: 1.22732,
          l: 1.22719,
          c: 1.22732,
          v: 433990000
        },
        {
          t: 16500,
          o: 1.22732,
          h: 1.22743,
          l: 1.22729,
          c: 1.22743,
          v: 268700000
        },
        {
          t: 16800,
          o: 1.22744,
          h: 1.22754,
          l: 1.22739,
          c: 1.22743,
          v: 291500000
        },
        {
          t: 17100,
          o: 1.22743,
          h: 1.22747,
          l: 1.22736,
          c: 1.22745,
          v: 484590001
        },
        {
          t: 17400,
          o: 1.22745,
          h: 1.22758,
          l: 1.22745,
          c: 1.22752,
          v: 463570000
        },
        {
          t: 17700,
          o: 1.22752,
          h: 1.22774,
          l: 1.22747,
          c: 1.22772,
          v: 520440000
        },
        {
          t: 18000,
          o: 1.22772,
          h: 1.22773,
          l: 1.22749,
          c: 1.22752,
          v: 550810000
        },
        {
          t: 18300,
          o: 1.22752,
          h: 1.22762,
          l: 1.22749,
          c: 1.2276,
          v: 556860000
        },
        {
          t: 18600,
          o: 1.22758,
          h: 1.22762,
          l: 1.22751,
          c: 1.22757,
          v: 304860000
        },
        {
          t: 18900,
          o: 1.22757,
          h: 1.22762,
          l: 1.22748,
          c: 1.22752,
          v: 551740000
        },
        {
          t: 19200,
          o: 1.22751,
          h: 1.22768,
          l: 1.22747,
          c: 1.22766,
          v: 464270000
        },
        {
          t: 19500,
          o: 1.22766,
          h: 1.22784,
          l: 1.22754,
          c: 1.22762,
          v: 485040000
        },
        {
          t: 19800,
          o: 1.2276,
          h: 1.22777,
          l: 1.22756,
          c: 1.22772,
          v: 485370000
        },
        {
          t: 20100,
          o: 1.22772,
          h: 1.22779,
          l: 1.22767,
          c: 1.22779,
          v: 280450000
        },
        {
          t: 20400,
          o: 1.2278,
          h: 1.22788,
          l: 1.22771,
          c: 1.22774,
          v: 350360000
        },
        {
          t: 20700,
          o: 1.22776,
          h: 1.22788,
          l: 1.22766,
          c: 1.2278,
          v: 509120000
        },
        {
          t: 21000,
          o: 1.2278,
          h: 1.22846,
          l: 1.2278,
          c: 1.22833,
          v: 834980000
        },
        {
          t: 21300,
          o: 1.22832,
          h: 1.22858,
          l: 1.22818,
          c: 1.2282,
          v: 805560000
        },
        {
          t: 21600,
          o: 1.22819,
          h: 1.22828,
          l: 1.22765,
          c: 1.2277,
          v: 1081700000
        },
        {
          t: 21900,
          o: 1.22771,
          h: 1.2278,
          l: 1.22732,
          c: 1.22775,
          v: 1196300000
        },
        {
          t: 22200,
          o: 1.22776,
          h: 1.22864,
          l: 1.22775,
          c: 1.22812,
          v: 1761130001
        },
        {
          t: 22500,
          o: 1.22812,
          h: 1.22812,
          l: 1.22776,
          c: 1.22799,
          v: 1809670000
        },
        {
          t: 22800,
          o: 1.22795,
          h: 1.22815,
          l: 1.22748,
          c: 1.22764,
          v: 1400110000
        },
        {
          t: 23100,
          o: 1.22762,
          h: 1.22791,
          l: 1.22747,
          c: 1.22766,
          v: 1466410000
        },
        {
          t: 23400,
          o: 1.22765,
          h: 1.22807,
          l: 1.22739,
          c: 1.22763,
          v: 1242540000
        },
        {
          t: 23700,
          o: 1.22762,
          h: 1.22787,
          l: 1.22755,
          c: 1.22768,
          v: 1193870000
        },
        {
          t: 24000,
          o: 1.22767,
          h: 1.2277,
          l: 1.22741,
          c: 1.2276,
          v: 1205180000
        },
        {
          t: 24300,
          o: 1.2276,
          h: 1.22776,
          l: 1.22731,
          c: 1.22746,
          v: 1108380000
        },
        {
          t: 24600,
          o: 1.22746,
          h: 1.2275,
          l: 1.22728,
          c: 1.22739,
          v: 831770000
        },
        {
          t: 24900,
          o: 1.22738,
          h: 1.22783,
          l: 1.22733,
          c: 1.22783,
          v: 1155300000
        },
        {
          t: 25200,
          o: 1.22783,
          h: 1.22783,
          l: 1.22602,
          c: 1.22689,
          v: 2553130000
        },
        {
          t: 25500,
          o: 1.22688,
          h: 1.22731,
          l: 1.2265,
          c: 1.22731,
          v: 1681880000
        },
        {
          t: 25800,
          o: 1.22733,
          h: 1.22761,
          l: 1.22684,
          c: 1.22688,
          v: 1538790000
        },
        {
          t: 26100,
          o: 1.22688,
          h: 1.22689,
          l: 1.22625,
          c: 1.22653,
          v: 1560180000
        },
        {
          t: 26400,
          o: 1.22652,
          h: 1.22725,
          l: 1.22644,
          c: 1.2268,
          v: 1542520000
        },
        {
          t: 26700,
          o: 1.22679,
          h: 1.2275,
          l: 1.22676,
          c: 1.2275,
          v: 984010000
        },
        {
          t: 27000,
          o: 1.2275,
          h: 1.22759,
          l: 1.22694,
          c: 1.22722,
          v: 1519830000
        },
        {
          t: 27300,
          o: 1.2272,
          h: 1.2275,
          l: 1.22693,
          c: 1.22739,
          v: 1177250000
        },
        {
          t: 27600,
          o: 1.22746,
          h: 1.22754,
          l: 1.22711,
          c: 1.22724,
          v: 1256690000
        },
        {
          t: 27900,
          o: 1.22724,
          h: 1.22788,
          l: 1.227,
          c: 1.22786,
          v: 1130430000
        },
        {
          t: 28200,
          o: 1.22783,
          h: 1.22862,
          l: 1.22782,
          c: 1.22849,
          v: 1619200000
        },
        {
          t: 28500,
          o: 1.22849,
          h: 1.22849,
          l: 1.22815,
          c: 1.22819,
          v: 1182260000
        },
        {
          t: 28800,
          o: 1.2282,
          h: 1.22825,
          l: 1.22763,
          c: 1.2278,
          v: 1427760000
        },
        {
          t: 29100,
          o: 1.2278,
          h: 1.22844,
          l: 1.22755,
          c: 1.22838,
          v: 992980000
        },
        {
          t: 29400,
          o: 1.2284,
          h: 1.22841,
          l: 1.22782,
          c: 1.22804,
          v: 1208360000
        },
        {
          t: 29700,
          o: 1.22803,
          h: 1.22848,
          l: 1.22785,
          c: 1.22789,
          v: 922420000
        },
        {
          t: 30000,
          o: 1.2279,
          h: 1.22828,
          l: 1.2279,
          c: 1.2281,
          v: 747310000
        },
        {
          t: 30300,
          o: 1.22812,
          h: 1.22813,
          l: 1.22787,
          c: 1.22803,
          v: 974340000
        },
        {
          t: 30600,
          o: 1.22803,
          h: 1.2283,
          l: 1.22788,
          c: 1.2279,
          v: 1033960000
        },
        {
          t: 30900,
          o: 1.22788,
          h: 1.22789,
          l: 1.22754,
          c: 1.22778,
          v: 1222200001
        },
        {
          t: 31200,
          o: 1.22779,
          h: 1.22788,
          l: 1.22754,
          c: 1.22774,
          v: 1080190000
        },
        {
          t: 31500,
          o: 1.22773,
          h: 1.22792,
          l: 1.2275,
          c: 1.22788,
          v: 791010000
        },
        {
          t: 31800,
          o: 1.2279,
          h: 1.22838,
          l: 1.22789,
          c: 1.22819,
          v: 954960000
        },
        {
          t: 32100,
          o: 1.22819,
          h: 1.22841,
          l: 1.22798,
          c: 1.22799,
          v: 835800000
        },
        {
          t: 32400,
          o: 1.22799,
          h: 1.22818,
          l: 1.22784,
          c: 1.22801,
          v: 1022710000
        },
        {
          t: 32700,
          o: 1.22799,
          h: 1.22839,
          l: 1.22798,
          c: 1.22829,
          v: 817060000
        },
        {
          t: 33000,
          o: 1.22828,
          h: 1.22843,
          l: 1.22816,
          c: 1.22816,
          v: 1029450000
        },
        {
          t: 33300,
          o: 1.22817,
          h: 1.22832,
          l: 1.22799,
          c: 1.22805,
          v: 821030000
        },
        {
          t: 33600,
          o: 1.22804,
          h: 1.22829,
          l: 1.22801,
          c: 1.22813,
          v: 662090000
        },
        {
          t: 33900,
          o: 1.22814,
          h: 1.22818,
          l: 1.22759,
          c: 1.22779,
          v: 817840000
        },
        {
          t: 34200,
          o: 1.22778,
          h: 1.22785,
          l: 1.22739,
          c: 1.22739,
          v: 1253040000
        },
        {
          t: 34500,
          o: 1.22739,
          h: 1.22759,
          l: 1.22724,
          c: 1.22724,
          v: 1193080000
        },
        {
          t: 34800,
          o: 1.22726,
          h: 1.22745,
          l: 1.22723,
          c: 1.22733,
          v: 804629999
        },
        {
          t: 35100,
          o: 1.22733,
          h: 1.22783,
          l: 1.22732,
          c: 1.2277,
          v: 894500000
        },
        {
          t: 35400,
          o: 1.22771,
          h: 1.22785,
          l: 1.22748,
          c: 1.2275,
          v: 871700000
        },
        {
          t: 35700,
          o: 1.22751,
          h: 1.22751,
          l: 1.22673,
          c: 1.22673,
          v: 1255850000
        },
        {
          t: 36000,
          o: 1.22674,
          h: 1.22706,
          l: 1.22652,
          c: 1.2269,
          v: 1508150000
        },
        {
          t: 36300,
          o: 1.22688,
          h: 1.22734,
          l: 1.22681,
          c: 1.22729,
          v: 1030710000
        },
        {
          t: 36600,
          o: 1.22728,
          h: 1.22735,
          l: 1.22648,
          c: 1.22668,
          v: 1222320000
        },
        {
          t: 36900,
          o: 1.22667,
          h: 1.22692,
          l: 1.22622,
          c: 1.2269,
          v: 1095350000
        },
        {
          t: 37200,
          o: 1.2269,
          h: 1.22692,
          l: 1.22657,
          c: 1.22674,
          v: 870760001
        },
        {
          t: 37500,
          o: 1.22673,
          h: 1.22699,
          l: 1.22671,
          c: 1.22679,
          v: 994650000
        },
        {
          t: 37800,
          o: 1.22679,
          h: 1.22694,
          l: 1.22657,
          c: 1.22679,
          v: 963260000
        },
        {
          t: 38100,
          o: 1.2268,
          h: 1.22705,
          l: 1.22672,
          c: 1.22674,
          v: 893360000
        },
        {
          t: 38400,
          o: 1.22674,
          h: 1.22685,
          l: 1.22653,
          c: 1.22663,
          v: 848730000
        },
        {
          t: 38700,
          o: 1.22664,
          h: 1.22705,
          l: 1.22664,
          c: 1.22687,
          v: 936290000
        },
        {
          t: 39000,
          o: 1.22687,
          h: 1.22712,
          l: 1.22671,
          c: 1.22711,
          v: 994370000
        },
        {
          t: 39300,
          o: 1.22711,
          h: 1.2275,
          l: 1.22706,
          c: 1.22729,
          v: 4679790012
        },
        {
          t: 39600,
          o: 1.22728,
          h: 1.22731,
          l: 1.22704,
          c: 1.22721,
          v: 915920000
        },
        {
          t: 39900,
          o: 1.22721,
          h: 1.22732,
          l: 1.22703,
          c: 1.22704,
          v: 908590000
        },
        {
          t: 40200,
          o: 1.22705,
          h: 1.22715,
          l: 1.22696,
          c: 1.22703,
          v: 832850000
        },
        {
          t: 40500,
          o: 1.22705,
          h: 1.22705,
          l: 1.22661,
          c: 1.22663,
          v: 732710000
        },
        {
          t: 40800,
          o: 1.22664,
          h: 1.2277,
          l: 1.22664,
          c: 1.22753,
          v: 912960000
        },
        {
          t: 41100,
          o: 1.22754,
          h: 1.22781,
          l: 1.22736,
          c: 1.22737,
          v: 1097480000
        },
        {
          t: 41400,
          o: 1.22737,
          h: 1.22784,
          l: 1.22737,
          c: 1.22763,
          v: 679840000
        },
        {
          t: 41700,
          o: 1.22762,
          h: 1.2279,
          l: 1.22752,
          c: 1.22766,
          v: 889090000
        },
        {
          t: 42000,
          o: 1.22766,
          h: 1.22797,
          l: 1.2276,
          c: 1.22793,
          v: 982940000
        },
        {
          t: 42300,
          o: 1.22794,
          h: 1.22801,
          l: 1.22781,
          c: 1.22781,
          v: 1084539999
        },
        {
          t: 42600,
          o: 1.22781,
          h: 1.22828,
          l: 1.22781,
          c: 1.2282,
          v: 1104590000
        },
        {
          t: 42900,
          o: 1.2282,
          h: 1.22861,
          l: 1.22815,
          c: 1.22837,
          v: 1215980001
        },
        {
          t: 43200,
          o: 1.22838,
          h: 1.22868,
          l: 1.22821,
          c: 1.22842,
          v: 1198510000
        },
        {
          t: 43500,
          o: 1.22841,
          h: 1.22969,
          l: 1.2283,
          c: 1.22969,
          v: 1811010000
        },
        {
          t: 43800,
          o: 1.22965,
          h: 1.23042,
          l: 1.22953,
          c: 1.23034,
          v: 1924850001
        },
        {
          t: 44100,
          o: 1.23036,
          h: 1.23045,
          l: 1.22992,
          c: 1.23016,
          v: 1445689999
        },
        {
          t: 44400,
          o: 1.23014,
          h: 1.23053,
          l: 1.23011,
          c: 1.23044,
          v: 1218360001
        },
        {
          t: 44700,
          o: 1.23043,
          h: 1.23075,
          l: 1.23043,
          c: 1.2305,
          v: 918040000
        },
        {
          t: 45000,
          o: 1.23052,
          h: 1.23071,
          l: 1.23025,
          c: 1.2307,
          v: 1018799999
        },
        {
          t: 45300,
          o: 1.2307,
          h: 1.23099,
          l: 1.23069,
          c: 1.2309,
          v: 1320660000
        },
        {
          t: 45600,
          o: 1.23091,
          h: 1.23116,
          l: 1.23071,
          c: 1.23099,
          v: 1476080002
        },
        {
          t: 45900,
          o: 1.231,
          h: 1.23122,
          l: 1.23086,
          c: 1.23098,
          v: 1041450000
        },
        {
          t: 46200,
          o: 1.23098,
          h: 1.23116,
          l: 1.23083,
          c: 1.23106,
          v: 1165039999
        },
        {
          t: 46500,
          o: 1.23106,
          h: 1.23135,
          l: 1.23099,
          c: 1.23133,
          v: 1139060000
        },
        {
          t: 46800,
          o: 1.23132,
          h: 1.23179,
          l: 1.23119,
          c: 1.23169,
          v: 1367970000
        },
        {
          t: 47100,
          o: 1.2317,
          h: 1.23189,
          l: 1.23154,
          c: 1.2316,
          v: 1336720000
        },
        {
          t: 47400,
          o: 1.2316,
          h: 1.2319,
          l: 1.23142,
          c: 1.23177,
          v: 1135180000
        },
        {
          t: 47700,
          o: 1.23177,
          h: 1.23219,
          l: 1.23164,
          c: 1.23206,
          v: 1442900000
        },
        {
          t: 48000,
          o: 1.23206,
          h: 1.23207,
          l: 1.23165,
          c: 1.23165,
          v: 1125640000
        },
        {
          t: 48300,
          o: 1.23164,
          h: 1.23188,
          l: 1.23161,
          c: 1.23176,
          v: 1054060000
        },
        {
          t: 48600,
          o: 1.23175,
          h: 1.23176,
          l: 1.23136,
          c: 1.23136,
          v: 1394610000
        },
        {
          t: 48900,
          o: 1.23138,
          h: 1.23166,
          l: 1.2313,
          c: 1.23133,
          v: 1377689998
        },
        {
          t: 49200,
          o: 1.23135,
          h: 1.23167,
          l: 1.23122,
          c: 1.23152,
          v: 1202769999
        },
        {
          t: 49500,
          o: 1.23154,
          h: 1.232,
          l: 1.23151,
          c: 1.23177,
          v: 1374440000
        },
        {
          t: 49800,
          o: 1.23177,
          h: 1.23268,
          l: 1.2316,
          c: 1.23256,
          v: 1389819999
        },
        {
          t: 50100,
          o: 1.23256,
          h: 1.23259,
          l: 1.23226,
          c: 1.23237,
          v: 1249720000
        },
        {
          t: 50400,
          o: 1.23237,
          h: 1.23252,
          l: 1.23185,
          c: 1.23191,
          v: 1516830000
        },
        {
          t: 50700,
          o: 1.23191,
          h: 1.23198,
          l: 1.23161,
          c: 1.23179,
          v: 1294859999
        },
        {
          t: 51000,
          o: 1.23178,
          h: 1.23202,
          l: 1.23165,
          c: 1.23201,
          v: 989830000
        },
        {
          t: 51300,
          o: 1.23201,
          h: 1.23259,
          l: 1.23189,
          c: 1.23257,
          v: 1469879999
        },
        {
          t: 51600,
          o: 1.23257,
          h: 1.23295,
          l: 1.23253,
          c: 1.23261,
          v: 1571710001
        },
        {
          t: 51900,
          o: 1.23261,
          h: 1.23273,
          l: 1.23229,
          c: 1.23263,
          v: 1047610000
        },
        {
          t: 52200,
          o: 1.23266,
          h: 1.23279,
          l: 1.23227,
          c: 1.23279,
          v: 1395980001
        },
        {
          t: 52500,
          o: 1.2328,
          h: 1.23308,
          l: 1.23226,
          c: 1.23246,
          v: 1509280000
        },
        {
          t: 52800,
          o: 1.23246,
          h: 1.23294,
          l: 1.23246,
          c: 1.23275,
          v: 1463210003
        },
        {
          t: 53100,
          o: 1.23276,
          h: 1.23294,
          l: 1.23231,
          c: 1.23231,
          v: 1164849999
        },
        {
          t: 53400,
          o: 1.23234,
          h: 1.23237,
          l: 1.2316,
          c: 1.2316,
          v: 1345280000
        },
        {
          t: 53700,
          o: 1.23159,
          h: 1.23176,
          l: 1.23098,
          c: 1.23113,
          v: 1797410000
        },
        {
          t: 54000,
          o: 1.23112,
          h: 1.23122,
          l: 1.23072,
          c: 1.23121,
          v: 1991050000
        },
        {
          t: 54300,
          o: 1.23121,
          h: 1.23125,
          l: 1.23104,
          c: 1.23117,
          v: 1065660000
        },
        {
          t: 54600,
          o: 1.23117,
          h: 1.23175,
          l: 1.23113,
          c: 1.23171,
          v: 918370000
        },
        {
          t: 54900,
          o: 1.23172,
          h: 1.23176,
          l: 1.23146,
          c: 1.23174,
          v: 745230000
        },
        {
          t: 55200,
          o: 1.23174,
          h: 1.23267,
          l: 1.23172,
          c: 1.2326,
          v: 933289999
        },
        {
          t: 55500,
          o: 1.2326,
          h: 1.23262,
          l: 1.23227,
          c: 1.23227,
          v: 854300000
        },
        {
          t: 55800,
          o: 1.23227,
          h: 1.23238,
          l: 1.23186,
          c: 1.23209,
          v: 1300480000
        },
        {
          t: 56100,
          o: 1.23209,
          h: 1.23209,
          l: 1.2313,
          c: 1.2313,
          v: 1296719998
        },
        {
          t: 56400,
          o: 1.23131,
          h: 1.23183,
          l: 1.23122,
          c: 1.23171,
          v: 1131520001
        },
        {
          t: 56700,
          o: 1.23171,
          h: 1.23177,
          l: 1.23148,
          c: 1.23166,
          v: 1199730000
        },
        {
          t: 57000,
          o: 1.23166,
          h: 1.23204,
          l: 1.23164,
          c: 1.23172,
          v: 1030469999
        },
        {
          t: 57300,
          o: 1.23173,
          h: 1.23203,
          l: 1.23169,
          c: 1.23187,
          v: 1055649999
        },
        {
          t: 57600,
          o: 1.23188,
          h: 1.23197,
          l: 1.2317,
          c: 1.23171,
          v: 1063560000
        },
        {
          t: 57900,
          o: 1.2317,
          h: 1.23179,
          l: 1.23149,
          c: 1.23152,
          v: 889660000
        },
        {
          t: 58200,
          o: 1.23152,
          h: 1.23168,
          l: 1.23145,
          c: 1.23153,
          v: 708640000
        },
        {
          t: 58500,
          o: 1.23152,
          h: 1.23163,
          l: 1.23127,
          c: 1.23158,
          v: 975060001
        },
        {
          t: 58800,
          o: 1.23157,
          h: 1.23205,
          l: 1.23157,
          c: 1.23192,
          v: 812610000
        },
        {
          t: 59100,
          o: 1.23192,
          h: 1.23205,
          l: 1.23172,
          c: 1.23193,
          v: 803370000
        },
        {
          t: 59400,
          o: 1.23193,
          h: 1.23217,
          l: 1.23188,
          c: 1.2321,
          v: 773920000
        },
        {
          t: 59700,
          o: 1.2321,
          h: 1.23262,
          l: 1.23203,
          c: 1.23259,
          v: 1169670000
        },
        {
          t: 60000,
          o: 1.23259,
          h: 1.23272,
          l: 1.23225,
          c: 1.23237,
          v: 812480000
        },
        {
          t: 60300,
          o: 1.23235,
          h: 1.23246,
          l: 1.23221,
          c: 1.23243,
          v: 1091360000
        },
        {
          t: 60600,
          o: 1.23243,
          h: 1.23243,
          l: 1.23195,
          c: 1.23211,
          v: 1291869999
        },
        {
          t: 60900,
          o: 1.23211,
          h: 1.23216,
          l: 1.23168,
          c: 1.23181,
          v: 1256050000
        },
        {
          t: 61200,
          o: 1.23184,
          h: 1.23235,
          l: 1.23181,
          c: 1.23213,
          v: 1179930000
        },
        {
          t: 61500,
          o: 1.23214,
          h: 1.23229,
          l: 1.23195,
          c: 1.23196,
          v: 1433640002
        },
        {
          t: 61800,
          o: 1.23196,
          h: 1.23211,
          l: 1.2317,
          c: 1.23172,
          v: 1137900000
        },
        {
          t: 62100,
          o: 1.2317,
          h: 1.23186,
          l: 1.23155,
          c: 1.2316,
          v: 985870000
        },
        {
          t: 62400,
          o: 1.2316,
          h: 1.23186,
          l: 1.23143,
          c: 1.23155,
          v: 933880000
        },
        {
          t: 62700,
          o: 1.23155,
          h: 1.23175,
          l: 1.23154,
          c: 1.23155,
          v: 930450001
        },
        {
          t: 63000,
          o: 1.23155,
          h: 1.23173,
          l: 1.23126,
          c: 1.23162,
          v: 1051350000
        },
        {
          t: 63300,
          o: 1.23164,
          h: 1.2318,
          l: 1.23155,
          c: 1.23171,
          v: 740560000
        },
        {
          t: 63600,
          o: 1.23171,
          h: 1.23184,
          l: 1.23165,
          c: 1.23174,
          v: 795970000
        },
        {
          t: 63900,
          o: 1.23175,
          h: 1.2318,
          l: 1.23154,
          c: 1.23155,
          v: 1029450000
        },
        {
          t: 64200,
          o: 1.23155,
          h: 1.23157,
          l: 1.23139,
          c: 1.23145,
          v: 830740000
        },
        {
          t: 64500,
          o: 1.23146,
          h: 1.23178,
          l: 1.23135,
          c: 1.23173,
          v: 804260000
        },
        {
          t: 64800,
          o: 1.23176,
          h: 1.23195,
          l: 1.23162,
          c: 1.23192,
          v: 1112560000
        },
        {
          t: 65100,
          o: 1.23191,
          h: 1.23201,
          l: 1.23154,
          c: 1.23154,
          v: 802890000
        },
        {
          t: 65400,
          o: 1.23155,
          h: 1.23174,
          l: 1.23144,
          c: 1.23166,
          v: 1031470000
        },
        {
          t: 65700,
          o: 1.23167,
          h: 1.232,
          l: 1.23165,
          c: 1.23186,
          v: 1050140000
        },
        {
          t: 66000,
          o: 1.23186,
          h: 1.23186,
          l: 1.23163,
          c: 1.23173,
          v: 1012600000
        },
        {
          t: 66300,
          o: 1.23173,
          h: 1.2318,
          l: 1.23156,
          c: 1.23172,
          v: 686310000
        },
        {
          t: 66600,
          o: 1.23172,
          h: 1.23186,
          l: 1.23171,
          c: 1.23186,
          v: 1047699999
        },
        {
          t: 66900,
          o: 1.23185,
          h: 1.23232,
          l: 1.23182,
          c: 1.23225,
          v: 1113770000
        },
        {
          t: 67200,
          o: 1.23228,
          h: 1.23249,
          l: 1.23217,
          c: 1.23248,
          v: 971620000
        },
        {
          t: 67500,
          o: 1.23248,
          h: 1.23254,
          l: 1.23236,
          c: 1.23242,
          v: 591640000
        },
        {
          t: 67800,
          o: 1.23242,
          h: 1.23243,
          l: 1.23224,
          c: 1.23226,
          v: 837580000
        },
        {
          t: 68100,
          o: 1.23226,
          h: 1.23231,
          l: 1.23207,
          c: 1.23213,
          v: 1161400000
        },
        {
          t: 68400,
          o: 1.23217,
          h: 1.23223,
          l: 1.23201,
          c: 1.23213,
          v: 929520000
        },
        {
          t: 68700,
          o: 1.23214,
          h: 1.23219,
          l: 1.23208,
          c: 1.23211,
          v: 796550000
        },
        {
          t: 69000,
          o: 1.23211,
          h: 1.23212,
          l: 1.23177,
          c: 1.23183,
          v: 898970000
        },
        {
          t: 69300,
          o: 1.23183,
          h: 1.23228,
          l: 1.23183,
          c: 1.23219,
          v: 750260000
        },
        {
          t: 69600,
          o: 1.23219,
          h: 1.2322,
          l: 1.23174,
          c: 1.23178,
          v: 1030420000
        },
        {
          t: 69900,
          o: 1.2318,
          h: 1.23188,
          l: 1.23156,
          c: 1.23178,
          v: 836610000
        },
        {
          t: 70200,
          o: 1.23177,
          h: 1.23203,
          l: 1.23177,
          c: 1.23191,
          v: 670140001
        },
        {
          t: 70500,
          o: 1.2319,
          h: 1.23198,
          l: 1.23182,
          c: 1.23186,
          v: 999640000
        },
        {
          t: 70800,
          o: 1.23187,
          h: 1.23196,
          l: 1.23172,
          c: 1.23183,
          v: 3889500066
        },
        {
          t: 71100,
          o: 1.23182,
          h: 1.23216,
          l: 1.2318,
          c: 1.23201,
          v: 641530000
        },
        {
          t: 71400,
          o: 1.23201,
          h: 1.23229,
          l: 1.23192,
          c: 1.23193,
          v: 947760000
        },
        {
          t: 71700,
          o: 1.23192,
          h: 1.23252,
          l: 1.23189,
          c: 1.23225,
          v: 1247209999
        },
        {
          t: 72000,
          o: 1.23222,
          h: 1.23222,
          l: 1.23187,
          c: 1.23189,
          v: 456220000
        },
        {
          t: 72300,
          o: 1.23189,
          h: 1.2322,
          l: 1.23189,
          c: 1.23215,
          v: 297130000
        },
        {
          t: 72600,
          o: 1.23213,
          h: 1.23216,
          l: 1.23202,
          c: 1.23203,
          v: 276920000
        },
        {
          t: 72900,
          o: 1.23203,
          h: 1.23207,
          l: 1.23193,
          c: 1.23207,
          v: 227500000
        },
        {
          t: 73200,
          o: 1.23207,
          h: 1.23208,
          l: 1.23203,
          c: 1.23205,
          v: 272430000
        },
        {
          t: 73500,
          o: 1.23205,
          h: 1.23212,
          l: 1.23205,
          c: 1.23208,
          v: 263020000
        },
        {
          t: 73800,
          o: 1.23207,
          h: 1.23208,
          l: 1.23178,
          c: 1.23178,
          v: 397340000
        },
        {
          t: 74100,
          o: 1.23178,
          h: 1.23182,
          l: 1.23177,
          c: 1.23177,
          v: 229100000
        },
        {
          t: 74400,
          o: 1.23177,
          h: 1.23185,
          l: 1.23167,
          c: 1.23178,
          v: 353930000
        },
        {
          t: 74700,
          o: 1.23178,
          h: 1.23195,
          l: 1.23178,
          c: 1.23183,
          v: 314910000
        },
        {
          t: 75000,
          o: 1.23184,
          h: 1.23196,
          l: 1.23174,
          c: 1.23193,
          v: 393860000
        },
        {
          t: 75300,
          o: 1.23193,
          h: 1.23211,
          l: 1.23183,
          c: 1.23204,
          v: 275530000
        },
        {
          t: 75600,
          o: 1.23205,
          h: 1.23205,
          l: 1.23184,
          c: 1.23186,
          v: 26230000
        },
        {
          t: 75900,
          o: 1.23186,
          h: 1.23201,
          l: 1.23184,
          c: 1.23199,
          v: 94790000
        },
        {
          t: 76200,
          o: 1.23199,
          h: 1.2323,
          l: 1.23197,
          c: 1.23211,
          v: 542760000
        },
        {
          t: 76500,
          o: 1.23211,
          h: 1.23231,
          l: 1.23202,
          c: 1.23214,
          v: 511620000
        },
        {
          t: 76800,
          o: 1.23214,
          h: 1.23226,
          l: 1.23206,
          c: 1.23223,
          v: 351680000
        },
        {
          t: 77100,
          o: 1.23226,
          h: 1.2323,
          l: 1.23208,
          c: 1.23212,
          v: 317380000
        },
        {
          t: 77400,
          o: 1.23214,
          h: 1.23216,
          l: 1.23195,
          c: 1.23205,
          v: 191740000
        },
        {
          t: 77700,
          o: 1.23206,
          h: 1.23222,
          l: 1.23204,
          c: 1.23207,
          v: 270650000
        },
        {
          t: 78000,
          o: 1.23206,
          h: 1.23212,
          l: 1.23198,
          c: 1.23201,
          v: 145300000
        },
        {
          t: 78300,
          o: 1.23202,
          h: 1.23219,
          l: 1.23195,
          c: 1.23205,
          v: 565060000
        },
        {
          t: 78600,
          o: 1.23204,
          h: 1.23225,
          l: 1.23198,
          c: 1.23207,
          v: 479430000
        },
        {
          t: 78900,
          o: 1.23206,
          h: 1.23231,
          l: 1.23206,
          c: 1.23228,
          v: 337450000
        },
        {
          t: 79200,
          o: 1.23229,
          h: 1.23239,
          l: 1.23211,
          c: 1.23226,
          v: 295910000
        },
        {
          t: 79500,
          o: 1.23226,
          h: 1.23243,
          l: 1.2322,
          c: 1.23236,
          v: 322510000
        },
        {
          t: 79800,
          o: 1.23235,
          h: 1.23246,
          l: 1.23225,
          c: 1.23236,
          v: 193410000
        },
        {
          t: 80100,
          o: 1.23236,
          h: 1.23261,
          l: 1.23236,
          c: 1.23261,
          v: 277670000
        },
        {
          t: 80400,
          o: 1.23261,
          h: 1.23265,
          l: 1.23225,
          c: 1.23229,
          v: 305780000
        },
        {
          t: 80700,
          o: 1.23229,
          h: 1.23237,
          l: 1.23228,
          c: 1.23232,
          v: 217290000
        },
        {
          t: 81000,
          o: 1.23232,
          h: 1.23234,
          l: 1.23225,
          c: 1.23228,
          v: 287190000
        },
        {
          t: 81300,
          o: 1.23228,
          h: 1.23237,
          l: 1.23228,
          c: 1.23235,
          v: 126570000
        },
        {
          t: 81600,
          o: 1.23236,
          h: 1.23237,
          l: 1.23234,
          c: 1.23236,
          v: 156670000
        },
        {
          t: 81900,
          o: 1.23235,
          h: 1.2324,
          l: 1.23235,
          c: 1.23239,
          v: 145280000
        },
        {
          t: 82200,
          o: 1.23239,
          h: 1.23239,
          l: 1.23225,
          c: 1.23225,
          v: 168970000
        },
        {
          t: 82500,
          o: 1.23226,
          h: 1.23227,
          l: 1.23218,
          c: 1.2322,
          v: 110830000
        },
        {
          t: 82800,
          o: 1.23221,
          h: 1.23222,
          l: 1.23213,
          c: 1.2322,
          v: 341880000
        },
        {
          t: 83100,
          o: 1.23221,
          h: 1.23236,
          l: 1.2322,
          c: 1.23236,
          v: 200580000
        },
        {
          t: 83400,
          o: 1.23236,
          h: 1.23237,
          l: 1.23217,
          c: 1.23222,
          v: 282290000
        },
        {
          t: 83700,
          o: 1.23222,
          h: 1.23225,
          l: 1.23217,
          c: 1.23225,
          v: 153730000
        },
        {
          t: 84000,
          o: 1.23227,
          h: 1.23231,
          l: 1.23215,
          c: 1.23215,
          v: 335990000
        },
        {
          t: 84300,
          o: 1.23214,
          h: 1.23229,
          l: 1.23214,
          c: 1.23229,
          v: 87870000
        },
        {
          t: 84600,
          o: 1.2323,
          h: 1.23237,
          l: 1.23224,
          c: 1.23229,
          v: 187720000
        },
        {
          t: 84900,
          o: 1.23228,
          h: 1.23245,
          l: 1.23228,
          c: 1.23234,
          v: 180760000
        },
        {
          t: 85200,
          o: 1.23234,
          h: 1.23234,
          l: 1.23209,
          c: 1.2323,
          v: 288720000
        },
        {
          t: 85500,
          o: 1.2323,
          h: 1.23235,
          l: 1.23224,
          c: 1.23225,
          v: 309680000
        },
        {
          t: 85800,
          o: 1.23225,
          h: 1.23239,
          l: 1.23225,
          c: 1.2323,
          v: 535330000
        },
        {
          t: 86100,
          o: 1.2323,
          h: 1.2323,
          l: 1.23206,
          c: 1.23206,
          v: 497130000
        }
      ]
    },
    {
      d: 1523318400,
      b: [
        {
          t: 0,
          o: 1.23206,
          h: 1.23225,
          l: 1.23203,
          c: 1.23223,
          v: 551600000
        },
        {
          t: 300,
          o: 1.23223,
          h: 1.23271,
          l: 1.23221,
          c: 1.23268,
          v: 502290000
        },
        {
          t: 600,
          o: 1.23268,
          h: 1.23289,
          l: 1.23259,
          c: 1.23261,
          v: 765170000
        },
        {
          t: 900,
          o: 1.23262,
          h: 1.23265,
          l: 1.23226,
          c: 1.23231,
          v: 616640001
        },
        {
          t: 1200,
          o: 1.2323,
          h: 1.23257,
          l: 1.23228,
          c: 1.23249,
          v: 447220000
        },
        {
          t: 1500,
          o: 1.2325,
          h: 1.23274,
          l: 1.2325,
          c: 1.23274,
          v: 478780000
        },
        {
          t: 1800,
          o: 1.23276,
          h: 1.23282,
          l: 1.23252,
          c: 1.23261,
          v: 618220000
        },
        {
          t: 2100,
          o: 1.23262,
          h: 1.23276,
          l: 1.23257,
          c: 1.23257,
          v: 382780000
        },
        {
          t: 2400,
          o: 1.23258,
          h: 1.2326,
          l: 1.23241,
          c: 1.23245,
          v: 411380000
        },
        {
          t: 2700,
          o: 1.23247,
          h: 1.23254,
          l: 1.23234,
          c: 1.2324,
          v: 566550000
        },
        {
          t: 3000,
          o: 1.2324,
          h: 1.23274,
          l: 1.23239,
          c: 1.23265,
          v: 648920000
        },
        {
          t: 3300,
          o: 1.23264,
          h: 1.23268,
          l: 1.23235,
          c: 1.23252,
          v: 591360000
        },
        {
          t: 3600,
          o: 1.23253,
          h: 1.23263,
          l: 1.23232,
          c: 1.23263,
          v: 433310000
        },
        {
          t: 3900,
          o: 1.23263,
          h: 1.23268,
          l: 1.2323,
          c: 1.2323,
          v: 445750000
        },
        {
          t: 4200,
          o: 1.2323,
          h: 1.23251,
          l: 1.23224,
          c: 1.23235,
          v: 878090000
        },
        {
          t: 4500,
          o: 1.23235,
          h: 1.23249,
          l: 1.23221,
          c: 1.23242,
          v: 779330000
        },
        {
          t: 4800,
          o: 1.23241,
          h: 1.23248,
          l: 1.23229,
          c: 1.2324,
          v: 726470000
        },
        {
          t: 5100,
          o: 1.23241,
          h: 1.23241,
          l: 1.23199,
          c: 1.23217,
          v: 835400000
        },
        {
          t: 5400,
          o: 1.23215,
          h: 1.23227,
          l: 1.23201,
          c: 1.23206,
          v: 912900000
        },
        {
          t: 5700,
          o: 1.23206,
          h: 1.23219,
          l: 1.23199,
          c: 1.23201,
          v: 719850000
        },
        {
          t: 6000,
          o: 1.232,
          h: 1.23206,
          l: 1.23182,
          c: 1.23206,
          v: 745890000
        },
        {
          t: 6300,
          o: 1.23206,
          h: 1.23207,
          l: 1.23175,
          c: 1.23185,
          v: 665480000
        },
        {
          t: 6600,
          o: 1.23184,
          h: 1.23203,
          l: 1.23175,
          c: 1.23187,
          v: 1054940000
        },
        {
          t: 6900,
          o: 1.23187,
          h: 1.23213,
          l: 1.2316,
          c: 1.23166,
          v: 1125910000
        },
        {
          t: 7200,
          o: 1.23167,
          h: 1.23172,
          l: 1.23145,
          c: 1.23146,
          v: 1058910001
        },
        {
          t: 7500,
          o: 1.23146,
          h: 1.23164,
          l: 1.23138,
          c: 1.23142,
          v: 786830000
        },
        {
          t: 7800,
          o: 1.23141,
          h: 1.23146,
          l: 1.23107,
          c: 1.23107,
          v: 1085550000
        },
        {
          t: 8100,
          o: 1.23108,
          h: 1.23141,
          l: 1.23086,
          c: 1.23133,
          v: 1415680000
        },
        {
          t: 8400,
          o: 1.23132,
          h: 1.23158,
          l: 1.2312,
          c: 1.23121,
          v: 1262620000
        },
        {
          t: 8700,
          o: 1.23125,
          h: 1.23136,
          l: 1.23087,
          c: 1.23107,
          v: 1282900000
        },
        {
          t: 9000,
          o: 1.23106,
          h: 1.23119,
          l: 1.23066,
          c: 1.23098,
          v: 1142100000
        },
        {
          t: 9300,
          o: 1.23099,
          h: 1.23123,
          l: 1.23095,
          c: 1.23123,
          v: 759540000
        },
        {
          t: 9600,
          o: 1.23125,
          h: 1.23163,
          l: 1.23122,
          c: 1.23158,
          v: 1027380000
        },
        {
          t: 9900,
          o: 1.23156,
          h: 1.23174,
          l: 1.23151,
          c: 1.23158,
          v: 680770000
        },
        {
          t: 10200,
          o: 1.23159,
          h: 1.23174,
          l: 1.23138,
          c: 1.23166,
          v: 875330000
        },
        {
          t: 10500,
          o: 1.23165,
          h: 1.23178,
          l: 1.23164,
          c: 1.23166,
          v: 678970000
        },
        {
          t: 10800,
          o: 1.23167,
          h: 1.23177,
          l: 1.2316,
          c: 1.23168,
          v: 741570000
        },
        {
          t: 11100,
          o: 1.23168,
          h: 1.2318,
          l: 1.2316,
          c: 1.23167,
          v: 665580000
        },
        {
          t: 11400,
          o: 1.23167,
          h: 1.23181,
          l: 1.23148,
          c: 1.23152,
          v: 809320000
        },
        {
          t: 11700,
          o: 1.23155,
          h: 1.2316,
          l: 1.23135,
          c: 1.23141,
          v: 616830000
        },
        {
          t: 12000,
          o: 1.2314,
          h: 1.23167,
          l: 1.23136,
          c: 1.23136,
          v: 395570000
        },
        {
          t: 12300,
          o: 1.23137,
          h: 1.23138,
          l: 1.23101,
          c: 1.23116,
          v: 761970000
        },
        {
          t: 12600,
          o: 1.23116,
          h: 1.23133,
          l: 1.23101,
          c: 1.23133,
          v: 888440000
        },
        {
          t: 12900,
          o: 1.23134,
          h: 1.23139,
          l: 1.23126,
          c: 1.23133,
          v: 569520000
        },
        {
          t: 13200,
          o: 1.23133,
          h: 1.23138,
          l: 1.2312,
          c: 1.23137,
          v: 782059999
        },
        {
          t: 13500,
          o: 1.23135,
          h: 1.23148,
          l: 1.23127,
          c: 1.23143,
          v: 621410000
        },
        {
          t: 13800,
          o: 1.23144,
          h: 1.23149,
          l: 1.23132,
          c: 1.23137,
          v: 563940000
        },
        {
          t: 14100,
          o: 1.2314,
          h: 1.23149,
          l: 1.23138,
          c: 1.23149,
          v: 273840000
        },
        {
          t: 14400,
          o: 1.23148,
          h: 1.23149,
          l: 1.23133,
          c: 1.23136,
          v: 500800000
        },
        {
          t: 14700,
          o: 1.23135,
          h: 1.23145,
          l: 1.23131,
          c: 1.23131,
          v: 330200000
        },
        {
          t: 15000,
          o: 1.23129,
          h: 1.2313,
          l: 1.23114,
          c: 1.23117,
          v: 375110000
        },
        {
          t: 15300,
          o: 1.23117,
          h: 1.23125,
          l: 1.23114,
          c: 1.23123,
          v: 372450000
        },
        {
          t: 15600,
          o: 1.23122,
          h: 1.2313,
          l: 1.23117,
          c: 1.2313,
          v: 330690000
        },
        {
          t: 15900,
          o: 1.2313,
          h: 1.23149,
          l: 1.2313,
          c: 1.23141,
          v: 568070000
        },
        {
          t: 16200,
          o: 1.2314,
          h: 1.2315,
          l: 1.2313,
          c: 1.23149,
          v: 407740000
        },
        {
          t: 16500,
          o: 1.2315,
          h: 1.23152,
          l: 1.23143,
          c: 1.23144,
          v: 344520000
        },
        {
          t: 16800,
          o: 1.23143,
          h: 1.23148,
          l: 1.23138,
          c: 1.23144,
          v: 131920000
        },
        {
          t: 17100,
          o: 1.23144,
          h: 1.2315,
          l: 1.23139,
          c: 1.23148,
          v: 243090000
        },
        {
          t: 17400,
          o: 1.23149,
          h: 1.23155,
          l: 1.23148,
          c: 1.23149,
          v: 218620000
        },
        {
          t: 17700,
          o: 1.23149,
          h: 1.23185,
          l: 1.23148,
          c: 1.23185,
          v: 404180000
        },
        {
          t: 18000,
          o: 1.23185,
          h: 1.23185,
          l: 1.23157,
          c: 1.23159,
          v: 616600000
        },
        {
          t: 18300,
          o: 1.23159,
          h: 1.2316,
          l: 1.23137,
          c: 1.23159,
          v: 690990000
        },
        {
          t: 18600,
          o: 1.23159,
          h: 1.23161,
          l: 1.23123,
          c: 1.23124,
          v: 712260000
        },
        {
          t: 18900,
          o: 1.23123,
          h: 1.23137,
          l: 1.23123,
          c: 1.23134,
          v: 510820000
        },
        {
          t: 19200,
          o: 1.23135,
          h: 1.23136,
          l: 1.23123,
          c: 1.23123,
          v: 339440000
        },
        {
          t: 19500,
          o: 1.23123,
          h: 1.23135,
          l: 1.23091,
          c: 1.23094,
          v: 839900000
        },
        {
          t: 19800,
          o: 1.23094,
          h: 1.23099,
          l: 1.23078,
          c: 1.23078,
          v: 710110000
        },
        {
          t: 20100,
          o: 1.23078,
          h: 1.2311,
          l: 1.23077,
          c: 1.23106,
          v: 683010000
        },
        {
          t: 20400,
          o: 1.23107,
          h: 1.23119,
          l: 1.23104,
          c: 1.23106,
          v: 455260000
        },
        {
          t: 20700,
          o: 1.23105,
          h: 1.23115,
          l: 1.23098,
          c: 1.231,
          v: 613640000
        },
        {
          t: 21000,
          o: 1.23099,
          h: 1.23115,
          l: 1.23088,
          c: 1.23112,
          v: 717090000
        },
        {
          t: 21300,
          o: 1.23112,
          h: 1.23115,
          l: 1.23078,
          c: 1.23081,
          v: 694900000
        },
        {
          t: 21600,
          o: 1.2308,
          h: 1.23106,
          l: 1.23068,
          c: 1.23086,
          v: 1160740000
        },
        {
          t: 21900,
          o: 1.23084,
          h: 1.23099,
          l: 1.23032,
          c: 1.23033,
          v: 1017000000
        },
        {
          t: 22200,
          o: 1.23034,
          h: 1.23057,
          l: 1.2303,
          c: 1.23041,
          v: 1202190000
        },
        {
          t: 22500,
          o: 1.23041,
          h: 1.23053,
          l: 1.23033,
          c: 1.23048,
          v: 1104940000
        },
        {
          t: 22800,
          o: 1.23049,
          h: 1.23109,
          l: 1.23049,
          c: 1.23094,
          v: 1146530000
        },
        {
          t: 23100,
          o: 1.23094,
          h: 1.23095,
          l: 1.23032,
          c: 1.23034,
          v: 1196880000
        },
        {
          t: 23400,
          o: 1.23032,
          h: 1.23114,
          l: 1.23027,
          c: 1.23109,
          v: 1514160000
        },
        {
          t: 23700,
          o: 1.23108,
          h: 1.23166,
          l: 1.23105,
          c: 1.23158,
          v: 1340140000
        },
        {
          t: 24000,
          o: 1.23158,
          h: 1.23166,
          l: 1.23142,
          c: 1.23165,
          v: 1126480000
        },
        {
          t: 24300,
          o: 1.23167,
          h: 1.23192,
          l: 1.23159,
          c: 1.23187,
          v: 1022560000
        },
        {
          t: 24600,
          o: 1.23187,
          h: 1.23187,
          l: 1.23146,
          c: 1.23157,
          v: 877710000
        },
        {
          t: 24900,
          o: 1.23157,
          h: 1.23175,
          l: 1.23142,
          c: 1.23155,
          v: 958220000
        },
        {
          t: 25200,
          o: 1.23155,
          h: 1.23179,
          l: 1.23132,
          c: 1.23168,
          v: 1564870000
        },
        {
          t: 25500,
          o: 1.23168,
          h: 1.23187,
          l: 1.23134,
          c: 1.2315,
          v: 1210520000
        },
        {
          t: 25800,
          o: 1.23151,
          h: 1.23157,
          l: 1.23092,
          c: 1.23125,
          v: 1575030000
        },
        {
          t: 26100,
          o: 1.23125,
          h: 1.23175,
          l: 1.23118,
          c: 1.23166,
          v: 1511490000
        },
        {
          t: 26400,
          o: 1.23166,
          h: 1.23262,
          l: 1.23163,
          c: 1.23256,
          v: 1378320000
        },
        {
          t: 26700,
          o: 1.23256,
          h: 1.23308,
          l: 1.23245,
          c: 1.23261,
          v: 1234010000
        },
        {
          t: 27000,
          o: 1.23261,
          h: 1.23312,
          l: 1.23237,
          c: 1.23311,
          v: 1409930000
        },
        {
          t: 27300,
          o: 1.23306,
          h: 1.23351,
          l: 1.23284,
          c: 1.23295,
          v: 1659040000
        },
        {
          t: 27600,
          o: 1.23298,
          h: 1.23298,
          l: 1.23244,
          c: 1.23251,
          v: 1488500000
        },
        {
          t: 27900,
          o: 1.2325,
          h: 1.2328,
          l: 1.23219,
          c: 1.23234,
          v: 1418210000
        },
        {
          t: 28200,
          o: 1.23235,
          h: 1.23251,
          l: 1.23216,
          c: 1.23229,
          v: 1320530000
        },
        {
          t: 28500,
          o: 1.2323,
          h: 1.23309,
          l: 1.23194,
          c: 1.23298,
          v: 1778180001
        },
        {
          t: 28800,
          o: 1.23298,
          h: 1.23306,
          l: 1.23184,
          c: 1.232,
          v: 1940970000
        },
        {
          t: 29100,
          o: 1.232,
          h: 1.23261,
          l: 1.23185,
          c: 1.23256,
          v: 1413430000
        },
        {
          t: 29400,
          o: 1.23254,
          h: 1.23281,
          l: 1.23219,
          c: 1.23219,
          v: 1103940000
        },
        {
          t: 29700,
          o: 1.23218,
          h: 1.2324,
          l: 1.23187,
          c: 1.23239,
          v: 919640000
        },
        {
          t: 30000,
          o: 1.23238,
          h: 1.23238,
          l: 1.23173,
          c: 1.23197,
          v: 1315020000
        },
        {
          t: 30300,
          o: 1.23197,
          h: 1.23208,
          l: 1.2313,
          c: 1.23135,
          v: 1448979999
        },
        {
          t: 30600,
          o: 1.23135,
          h: 1.23216,
          l: 1.23125,
          c: 1.23184,
          v: 1406960000
        },
        {
          t: 30900,
          o: 1.23184,
          h: 1.23259,
          l: 1.23184,
          c: 1.23246,
          v: 1074220000
        },
        {
          t: 31200,
          o: 1.23246,
          h: 1.23253,
          l: 1.23198,
          c: 1.23223,
          v: 913700000
        },
        {
          t: 31500,
          o: 1.23224,
          h: 1.23241,
          l: 1.23194,
          c: 1.23236,
          v: 948800000
        },
        {
          t: 31800,
          o: 1.23236,
          h: 1.2325,
          l: 1.23218,
          c: 1.23219,
          v: 985590000
        },
        {
          t: 32100,
          o: 1.23217,
          h: 1.2327,
          l: 1.23201,
          c: 1.23248,
          v: 956180000
        },
        {
          t: 32400,
          o: 1.23248,
          h: 1.23254,
          l: 1.232,
          c: 1.23208,
          v: 1399220000
        },
        {
          t: 32700,
          o: 1.23208,
          h: 1.23246,
          l: 1.23203,
          c: 1.23219,
          v: 1254480000
        },
        {
          t: 33000,
          o: 1.23223,
          h: 1.23243,
          l: 1.2322,
          c: 1.23238,
          v: 1143020000
        },
        {
          t: 33300,
          o: 1.23238,
          h: 1.23256,
          l: 1.23226,
          c: 1.23254,
          v: 903430000
        },
        {
          t: 33600,
          o: 1.23253,
          h: 1.23256,
          l: 1.23229,
          c: 1.23249,
          v: 956590001
        },
        {
          t: 33900,
          o: 1.23249,
          h: 1.23285,
          l: 1.23245,
          c: 1.23278,
          v: 943620000
        },
        {
          t: 34200,
          o: 1.23278,
          h: 1.23306,
          l: 1.23241,
          c: 1.23242,
          v: 1164880000
        },
        {
          t: 34500,
          o: 1.23241,
          h: 1.23249,
          l: 1.23212,
          c: 1.23231,
          v: 708330000
        },
        {
          t: 34800,
          o: 1.23231,
          h: 1.23238,
          l: 1.23197,
          c: 1.23213,
          v: 941419999
        },
        {
          t: 35100,
          o: 1.23214,
          h: 1.23224,
          l: 1.23193,
          c: 1.23218,
          v: 1029680001
        },
        {
          t: 35400,
          o: 1.23218,
          h: 1.23224,
          l: 1.23199,
          c: 1.23204,
          v: 603480000
        },
        {
          t: 35700,
          o: 1.23205,
          h: 1.2322,
          l: 1.23199,
          c: 1.23217,
          v: 864220000
        },
        {
          t: 36000,
          o: 1.23217,
          h: 1.23248,
          l: 1.23207,
          c: 1.23248,
          v: 926340000
        },
        {
          t: 36300,
          o: 1.23248,
          h: 1.23273,
          l: 1.23236,
          c: 1.23268,
          v: 1208949999
        },
        {
          t: 36600,
          o: 1.23268,
          h: 1.23278,
          l: 1.23256,
          c: 1.23261,
          v: 892349999
        },
        {
          t: 36900,
          o: 1.2326,
          h: 1.23276,
          l: 1.23242,
          c: 1.23243,
          v: 821730000
        },
        {
          t: 37200,
          o: 1.23242,
          h: 1.23272,
          l: 1.23242,
          c: 1.23263,
          v: 791410000
        },
        {
          t: 37500,
          o: 1.23263,
          h: 1.23269,
          l: 1.23211,
          c: 1.23227,
          v: 1044740000
        },
        {
          t: 37800,
          o: 1.23227,
          h: 1.23242,
          l: 1.23219,
          c: 1.23225,
          v: 803230000
        },
        {
          t: 38100,
          o: 1.23226,
          h: 1.23226,
          l: 1.23191,
          c: 1.23208,
          v: 788730000
        },
        {
          t: 38400,
          o: 1.23209,
          h: 1.23232,
          l: 1.23176,
          c: 1.23231,
          v: 1450230000
        },
        {
          t: 38700,
          o: 1.23232,
          h: 1.23233,
          l: 1.23207,
          c: 1.23227,
          v: 893540000
        },
        {
          t: 39000,
          o: 1.23227,
          h: 1.23265,
          l: 1.23225,
          c: 1.23247,
          v: 989050000
        },
        {
          t: 39300,
          o: 1.23247,
          h: 1.23254,
          l: 1.23231,
          c: 1.23249,
          v: 1059730000
        },
        {
          t: 39600,
          o: 1.23248,
          h: 1.23253,
          l: 1.2321,
          c: 1.23234,
          v: 866890000
        },
        {
          t: 39900,
          o: 1.23234,
          h: 1.23278,
          l: 1.23233,
          c: 1.23274,
          v: 606520000
        },
        {
          t: 40200,
          o: 1.23274,
          h: 1.23302,
          l: 1.23267,
          c: 1.23273,
          v: 992570000
        },
        {
          t: 40500,
          o: 1.23276,
          h: 1.23302,
          l: 1.23268,
          c: 1.23298,
          v: 1058689999
        },
        {
          t: 40800,
          o: 1.23298,
          h: 1.23301,
          l: 1.23283,
          c: 1.23289,
          v: 790690000
        },
        {
          t: 41100,
          o: 1.23288,
          h: 1.23293,
          l: 1.23263,
          c: 1.23287,
          v: 772860000
        },
        {
          t: 41400,
          o: 1.23287,
          h: 1.23735,
          l: 1.23286,
          c: 1.23648,
          v: 2925940001
        },
        {
          t: 41700,
          o: 1.23647,
          h: 1.23776,
          l: 1.23647,
          c: 1.23714,
          v: 2427619999
        },
        {
          t: 42000,
          o: 1.23714,
          h: 1.23727,
          l: 1.23631,
          c: 1.23664,
          v: 2043680000
        },
        {
          t: 42300,
          o: 1.23662,
          h: 1.23734,
          l: 1.23662,
          c: 1.23697,
          v: 1810370000
        },
        {
          t: 42600,
          o: 1.23699,
          h: 1.23723,
          l: 1.23662,
          c: 1.23673,
          v: 1586300000
        },
        {
          t: 42900,
          o: 1.23672,
          h: 1.23682,
          l: 1.23596,
          c: 1.23599,
          v: 1657980001
        },
        {
          t: 43200,
          o: 1.23598,
          h: 1.23624,
          l: 1.23582,
          c: 1.23598,
          v: 1798410000
        },
        {
          t: 43500,
          o: 1.23596,
          h: 1.23619,
          l: 1.23571,
          c: 1.236,
          v: 1371090000
        },
        {
          t: 43800,
          o: 1.236,
          h: 1.23632,
          l: 1.23599,
          c: 1.23604,
          v: 1107860001
        },
        {
          t: 44100,
          o: 1.23606,
          h: 1.23645,
          l: 1.23586,
          c: 1.23642,
          v: 1345500001
        },
        {
          t: 44400,
          o: 1.23642,
          h: 1.23651,
          l: 1.23606,
          c: 1.23631,
          v: 1315070000
        },
        {
          t: 44700,
          o: 1.23632,
          h: 1.2364,
          l: 1.23607,
          c: 1.2361,
          v: 1234890002
        },
        {
          t: 45000,
          o: 1.23609,
          h: 1.23612,
          l: 1.23551,
          c: 1.23559,
          v: 1638170000
        },
        {
          t: 45300,
          o: 1.23559,
          h: 1.23562,
          l: 1.23506,
          c: 1.23517,
          v: 1741750000
        },
        {
          t: 45600,
          o: 1.23516,
          h: 1.23564,
          l: 1.23514,
          c: 1.23548,
          v: 1332950000
        },
        {
          t: 45900,
          o: 1.23547,
          h: 1.2355,
          l: 1.23507,
          c: 1.2353,
          v: 1212530000
        },
        {
          t: 46200,
          o: 1.23529,
          h: 1.23532,
          l: 1.23409,
          c: 1.23485,
          v: 1544740001
        },
        {
          t: 46500,
          o: 1.23483,
          h: 1.23522,
          l: 1.23473,
          c: 1.23487,
          v: 1324610000
        },
        {
          t: 46800,
          o: 1.23481,
          h: 1.23516,
          l: 1.23479,
          c: 1.23493,
          v: 1336350001
        },
        {
          t: 47100,
          o: 1.23492,
          h: 1.23527,
          l: 1.2346,
          c: 1.23527,
          v: 1555690000
        },
        {
          t: 47400,
          o: 1.23529,
          h: 1.23606,
          l: 1.2352,
          c: 1.23594,
          v: 1923160001
        },
        {
          t: 47700,
          o: 1.23594,
          h: 1.23647,
          l: 1.2359,
          c: 1.23625,
          v: 1529400000
        },
        {
          t: 48000,
          o: 1.23622,
          h: 1.23625,
          l: 1.23571,
          c: 1.23588,
          v: 1471939999
        },
        {
          t: 48300,
          o: 1.23587,
          h: 1.23617,
          l: 1.23568,
          c: 1.23595,
          v: 1146560000
        },
        {
          t: 48600,
          o: 1.23594,
          h: 1.23645,
          l: 1.23583,
          c: 1.23624,
          v: 1874320002
        },
        {
          t: 48900,
          o: 1.23627,
          h: 1.23665,
          l: 1.23596,
          c: 1.23614,
          v: 1247779999
        },
        {
          t: 49200,
          o: 1.23614,
          h: 1.2366,
          l: 1.23614,
          c: 1.23615,
          v: 1572820001
        },
        {
          t: 49500,
          o: 1.23615,
          h: 1.23639,
          l: 1.23598,
          c: 1.23618,
          v: 1227129999
        },
        {
          t: 49800,
          o: 1.23618,
          h: 1.23679,
          l: 1.23609,
          c: 1.23664,
          v: 1228279999
        },
        {
          t: 50100,
          o: 1.23664,
          h: 1.23679,
          l: 1.2364,
          c: 1.23649,
          v: 1346800000
        },
        {
          t: 50400,
          o: 1.23647,
          h: 1.2367,
          l: 1.23615,
          c: 1.2363,
          v: 1453790000
        },
        {
          t: 50700,
          o: 1.2363,
          h: 1.23654,
          l: 1.23593,
          c: 1.23598,
          v: 1388699998
        },
        {
          t: 51000,
          o: 1.23599,
          h: 1.23605,
          l: 1.23547,
          c: 1.23578,
          v: 1489970000
        },
        {
          t: 51300,
          o: 1.23577,
          h: 1.23581,
          l: 1.23528,
          c: 1.23565,
          v: 1072090000
        },
        {
          t: 51600,
          o: 1.23564,
          h: 1.23611,
          l: 1.23538,
          c: 1.23609,
          v: 1357550001
        },
        {
          t: 51900,
          o: 1.23609,
          h: 1.23623,
          l: 1.2355,
          c: 1.23569,
          v: 1367150000
        },
        {
          t: 52200,
          o: 1.23571,
          h: 1.236,
          l: 1.23515,
          c: 1.23525,
          v: 1321660000
        },
        {
          t: 52500,
          o: 1.23523,
          h: 1.23585,
          l: 1.23523,
          c: 1.23574,
          v: 1125520000
        },
        {
          t: 52800,
          o: 1.23574,
          h: 1.23595,
          l: 1.23554,
          c: 1.2357,
          v: 1069520000
        },
        {
          t: 53100,
          o: 1.23569,
          h: 1.23584,
          l: 1.23525,
          c: 1.23525,
          v: 1038420000
        },
        {
          t: 53400,
          o: 1.23526,
          h: 1.23554,
          l: 1.23473,
          c: 1.2349,
          v: 1248210000
        },
        {
          t: 53700,
          o: 1.23489,
          h: 1.23528,
          l: 1.23461,
          c: 1.23497,
          v: 1593070000
        },
        {
          t: 54000,
          o: 1.23497,
          h: 1.23536,
          l: 1.23496,
          c: 1.23532,
          v: 1436100000
        },
        {
          t: 54300,
          o: 1.23531,
          h: 1.2354,
          l: 1.23502,
          c: 1.23535,
          v: 1303790001
        },
        {
          t: 54600,
          o: 1.23535,
          h: 1.23586,
          l: 1.2353,
          c: 1.23577,
          v: 1294330000
        },
        {
          t: 54900,
          o: 1.23577,
          h: 1.23601,
          l: 1.2356,
          c: 1.23596,
          v: 1396590000
        },
        {
          t: 55200,
          o: 1.23596,
          h: 1.23596,
          l: 1.23523,
          c: 1.23561,
          v: 1449420000
        },
        {
          t: 55500,
          o: 1.23561,
          h: 1.23575,
          l: 1.23243,
          c: 1.2329,
          v: 2809710001
        },
        {
          t: 55800,
          o: 1.23285,
          h: 1.23346,
          l: 1.23246,
          c: 1.23341,
          v: 1863760000
        },
        {
          t: 56100,
          o: 1.2334,
          h: 1.23386,
          l: 1.2333,
          c: 1.23363,
          v: 1609340000
        },
        {
          t: 56400,
          o: 1.23363,
          h: 1.23374,
          l: 1.23321,
          c: 1.23334,
          v: 1399370000
        },
        {
          t: 56700,
          o: 1.23334,
          h: 1.23393,
          l: 1.23323,
          c: 1.2337,
          v: 1422330000
        },
        {
          t: 57000,
          o: 1.2337,
          h: 1.23394,
          l: 1.23342,
          c: 1.23362,
          v: 1365840003
        },
        {
          t: 57300,
          o: 1.23371,
          h: 1.23386,
          l: 1.23335,
          c: 1.23369,
          v: 1624470000
        },
        {
          t: 57600,
          o: 1.2337,
          h: 1.23428,
          l: 1.23362,
          c: 1.23418,
          v: 1380530000
        },
        {
          t: 57900,
          o: 1.23418,
          h: 1.23422,
          l: 1.23372,
          c: 1.23379,
          v: 1329230000
        },
        {
          t: 58200,
          o: 1.2338,
          h: 1.23394,
          l: 1.23371,
          c: 1.23383,
          v: 1028690000
        },
        {
          t: 58500,
          o: 1.23383,
          h: 1.23389,
          l: 1.23365,
          c: 1.23377,
          v: 1145739999
        },
        {
          t: 58800,
          o: 1.23378,
          h: 1.23399,
          l: 1.23362,
          c: 1.23393,
          v: 1325800000
        },
        {
          t: 59100,
          o: 1.23392,
          h: 1.23447,
          l: 1.23392,
          c: 1.23444,
          v: 1383540000
        },
        {
          t: 59400,
          o: 1.23444,
          h: 1.23453,
          l: 1.23414,
          c: 1.23433,
          v: 1106870000
        },
        {
          t: 59700,
          o: 1.23433,
          h: 1.23447,
          l: 1.23421,
          c: 1.23443,
          v: 1121760000
        },
        {
          t: 60000,
          o: 1.23442,
          h: 1.23458,
          l: 1.23442,
          c: 1.23456,
          v: 977950001
        },
        {
          t: 60300,
          o: 1.23457,
          h: 1.23467,
          l: 1.23444,
          c: 1.23456,
          v: 582150000
        },
        {
          t: 60600,
          o: 1.23455,
          h: 1.23478,
          l: 1.23455,
          c: 1.23469,
          v: 567680000
        },
        {
          t: 60900,
          o: 1.23469,
          h: 1.23469,
          l: 1.23386,
          c: 1.23401,
          v: 1221240001
        },
        {
          t: 61200,
          o: 1.23403,
          h: 1.2342,
          l: 1.23399,
          c: 1.23414,
          v: 1425370000
        },
        {
          t: 61500,
          o: 1.23413,
          h: 1.23449,
          l: 1.23413,
          c: 1.23442,
          v: 920070000
        },
        {
          t: 61800,
          o: 1.23446,
          h: 1.23476,
          l: 1.23428,
          c: 1.23463,
          v: 783910000
        },
        {
          t: 62100,
          o: 1.23461,
          h: 1.23497,
          l: 1.23451,
          c: 1.23495,
          v: 765670000
        },
        {
          t: 62400,
          o: 1.23494,
          h: 1.23542,
          l: 1.23492,
          c: 1.23537,
          v: 1103810000
        },
        {
          t: 62700,
          o: 1.23538,
          h: 1.23585,
          l: 1.23532,
          c: 1.23579,
          v: 1081910000
        },
        {
          t: 63000,
          o: 1.23579,
          h: 1.23579,
          l: 1.23537,
          c: 1.23537,
          v: 1391610000
        },
        {
          t: 63300,
          o: 1.23537,
          h: 1.23572,
          l: 1.23536,
          c: 1.23567,
          v: 1265070000
        },
        {
          t: 63600,
          o: 1.23566,
          h: 1.23567,
          l: 1.23513,
          c: 1.23516,
          v: 1133500000
        },
        {
          t: 63900,
          o: 1.23518,
          h: 1.23556,
          l: 1.23511,
          c: 1.23547,
          v: 1054780001
        },
        {
          t: 64200,
          o: 1.23546,
          h: 1.23549,
          l: 1.23491,
          c: 1.23496,
          v: 1274620000
        },
        {
          t: 64500,
          o: 1.23497,
          h: 1.23497,
          l: 1.23452,
          c: 1.23456,
          v: 1340740000
        },
        {
          t: 64800,
          o: 1.23456,
          h: 1.2347,
          l: 1.2344,
          c: 1.23456,
          v: 1044900000
        },
        {
          t: 65100,
          o: 1.23456,
          h: 1.23507,
          l: 1.23452,
          c: 1.23506,
          v: 888460000
        },
        {
          t: 65400,
          o: 1.23506,
          h: 1.23508,
          l: 1.23485,
          c: 1.23495,
          v: 1027180000
        },
        {
          t: 65700,
          o: 1.23496,
          h: 1.23523,
          l: 1.23491,
          c: 1.23512,
          v: 1122350000
        },
        {
          t: 66000,
          o: 1.23512,
          h: 1.23527,
          l: 1.235,
          c: 1.23511,
          v: 996420000
        },
        {
          t: 66300,
          o: 1.23512,
          h: 1.23524,
          l: 1.235,
          c: 1.23524,
          v: 824950000
        },
        {
          t: 66600,
          o: 1.23524,
          h: 1.23537,
          l: 1.2348,
          c: 1.23496,
          v: 1073990000
        },
        {
          t: 66900,
          o: 1.23496,
          h: 1.23538,
          l: 1.23496,
          c: 1.2353,
          v: 968460000
        },
        {
          t: 67200,
          o: 1.23531,
          h: 1.23562,
          l: 1.23527,
          c: 1.23561,
          v: 1418499999
        },
        {
          t: 67500,
          o: 1.23561,
          h: 1.23568,
          l: 1.2354,
          c: 1.23551,
          v: 1057640000
        },
        {
          t: 67800,
          o: 1.23555,
          h: 1.23594,
          l: 1.23555,
          c: 1.23576,
          v: 1059120000
        },
        {
          t: 68100,
          o: 1.23577,
          h: 1.23612,
          l: 1.23576,
          c: 1.23607,
          v: 1251220000
        },
        {
          t: 68400,
          o: 1.23607,
          h: 1.23611,
          l: 1.23569,
          c: 1.23591,
          v: 810550000
        },
        {
          t: 68700,
          o: 1.2359,
          h: 1.23605,
          l: 1.23581,
          c: 1.23594,
          v: 1383319999
        },
        {
          t: 69000,
          o: 1.23594,
          h: 1.23604,
          l: 1.23585,
          c: 1.23586,
          v: 882360000
        },
        {
          t: 69300,
          o: 1.23583,
          h: 1.23585,
          l: 1.2356,
          c: 1.23569,
          v: 936980000
        },
        {
          t: 69600,
          o: 1.23569,
          h: 1.23574,
          l: 1.2356,
          c: 1.2356,
          v: 851240000
        },
        {
          t: 69900,
          o: 1.2356,
          h: 1.23565,
          l: 1.2355,
          c: 1.23555,
          v: 968090000
        },
        {
          t: 70200,
          o: 1.23555,
          h: 1.23557,
          l: 1.23533,
          c: 1.23537,
          v: 722930000
        },
        {
          t: 70500,
          o: 1.23537,
          h: 1.23558,
          l: 1.23537,
          c: 1.23545,
          v: 715190000
        },
        {
          t: 70800,
          o: 1.23547,
          h: 1.23551,
          l: 1.23535,
          c: 1.23541,
          v: 823300000
        },
        {
          t: 71100,
          o: 1.23541,
          h: 1.23569,
          l: 1.2353,
          c: 1.23566,
          v: 793230000
        },
        {
          t: 71400,
          o: 1.23566,
          h: 1.23584,
          l: 1.23549,
          c: 1.23565,
          v: 1135980000
        },
        {
          t: 71700,
          o: 1.23555,
          h: 1.23571,
          l: 1.23526,
          c: 1.23537,
          v: 944670000
        },
        {
          t: 72000,
          o: 1.23537,
          h: 1.23549,
          l: 1.23527,
          c: 1.23549,
          v: 515530000
        },
        {
          t: 72300,
          o: 1.23549,
          h: 1.23565,
          l: 1.23549,
          c: 1.23563,
          v: 196410000
        },
        {
          t: 72600,
          o: 1.23562,
          h: 1.23565,
          l: 1.23552,
          c: 1.23554,
          v: 194490000
        },
        {
          t: 72900,
          o: 1.23552,
          h: 1.23556,
          l: 1.23552,
          c: 1.23555,
          v: 132210000
        },
        {
          t: 73200,
          o: 1.23555,
          h: 1.23556,
          l: 1.23543,
          c: 1.23546,
          v: 330630000
        },
        {
          t: 73500,
          o: 1.23546,
          h: 1.23548,
          l: 1.23538,
          c: 1.23547,
          v: 479940000
        },
        {
          t: 73800,
          o: 1.23546,
          h: 1.2355,
          l: 1.23535,
          c: 1.23548,
          v: 357060000
        },
        {
          t: 74100,
          o: 1.23547,
          h: 1.23549,
          l: 1.2354,
          c: 1.23545,
          v: 203930000
        },
        {
          t: 74400,
          o: 1.23544,
          h: 1.23546,
          l: 1.23525,
          c: 1.23529,
          v: 522510000
        },
        {
          t: 74700,
          o: 1.2353,
          h: 1.23552,
          l: 1.2353,
          c: 1.23552,
          v: 250990000
        },
        {
          t: 75000,
          o: 1.23551,
          h: 1.23552,
          l: 1.23536,
          c: 1.23541,
          v: 227940000
        },
        {
          t: 75300,
          o: 1.23541,
          h: 1.23564,
          l: 1.23541,
          c: 1.23555,
          v: 275070000
        },
        {
          t: 75600,
          o: 1.23554,
          h: 1.23554,
          l: 1.23503,
          c: 1.23525,
          v: 149010000
        },
        {
          t: 75900,
          o: 1.23523,
          h: 1.23568,
          l: 1.23514,
          c: 1.23516,
          v: 380370001
        },
        {
          t: 76200,
          o: 1.23516,
          h: 1.23554,
          l: 1.23516,
          c: 1.2354,
          v: 301810000
        },
        {
          t: 76500,
          o: 1.2354,
          h: 1.2355,
          l: 1.23532,
          c: 1.23544,
          v: 244810000
        },
        {
          t: 76800,
          o: 1.23546,
          h: 1.23551,
          l: 1.23521,
          c: 1.23545,
          v: 311630000
        },
        {
          t: 77100,
          o: 1.23545,
          h: 1.23548,
          l: 1.23543,
          c: 1.23545,
          v: 139070000
        },
        {
          t: 77400,
          o: 1.23546,
          h: 1.23549,
          l: 1.23523,
          c: 1.23533,
          v: 309280000
        },
        {
          t: 77700,
          o: 1.23533,
          h: 1.23534,
          l: 1.23527,
          c: 1.23533,
          v: 278600000
        },
        {
          t: 78000,
          o: 1.23532,
          h: 1.23532,
          l: 1.23523,
          c: 1.23527,
          v: 178310000
        },
        {
          t: 78300,
          o: 1.23526,
          h: 1.23545,
          l: 1.23524,
          c: 1.23538,
          v: 209210000
        },
        {
          t: 78600,
          o: 1.23537,
          h: 1.23542,
          l: 1.23527,
          c: 1.23532,
          v: 218400000
        },
        {
          t: 78900,
          o: 1.2353,
          h: 1.23541,
          l: 1.23528,
          c: 1.23531,
          v: 227630000
        },
        {
          t: 79200,
          o: 1.23531,
          h: 1.23561,
          l: 1.23531,
          c: 1.23555,
          v: 168430000
        },
        {
          t: 79500,
          o: 1.23556,
          h: 1.2356,
          l: 1.23547,
          c: 1.23555,
          v: 293040000
        },
        {
          t: 79800,
          o: 1.23557,
          h: 1.23563,
          l: 1.23552,
          c: 1.23559,
          v: 302780000
        },
        {
          t: 80100,
          o: 1.23559,
          h: 1.23562,
          l: 1.23556,
          c: 1.23557,
          v: 136210000
        },
        {
          t: 80400,
          o: 1.23557,
          h: 1.2356,
          l: 1.23551,
          c: 1.23555,
          v: 179880000
        },
        {
          t: 80700,
          o: 1.23555,
          h: 1.23558,
          l: 1.23536,
          c: 1.23554,
          v: 216510000
        },
        {
          t: 81000,
          o: 1.23554,
          h: 1.23571,
          l: 1.23554,
          c: 1.23569,
          v: 99690000
        },
        {
          t: 81300,
          o: 1.2357,
          h: 1.23581,
          l: 1.2357,
          c: 1.23575,
          v: 81510000
        },
        {
          t: 81600,
          o: 1.23575,
          h: 1.2358,
          l: 1.23571,
          c: 1.23579,
          v: 192780000
        },
        {
          t: 81900,
          o: 1.23577,
          h: 1.2358,
          l: 1.23572,
          c: 1.23574,
          v: 122180000
        },
        {
          t: 82200,
          o: 1.23574,
          h: 1.23582,
          l: 1.2357,
          c: 1.23578,
          v: 300700000
        },
        {
          t: 82500,
          o: 1.23577,
          h: 1.23579,
          l: 1.23569,
          c: 1.23573,
          v: 178580000
        },
        {
          t: 82800,
          o: 1.23574,
          h: 1.23584,
          l: 1.23572,
          c: 1.2358,
          v: 266880000
        },
        {
          t: 83100,
          o: 1.23581,
          h: 1.23595,
          l: 1.23581,
          c: 1.2359,
          v: 275050000
        },
        {
          t: 83400,
          o: 1.2359,
          h: 1.23594,
          l: 1.23585,
          c: 1.23593,
          v: 167050000
        },
        {
          t: 83700,
          o: 1.23593,
          h: 1.23612,
          l: 1.23575,
          c: 1.2359,
          v: 383440000
        },
        {
          t: 84000,
          o: 1.23591,
          h: 1.23606,
          l: 1.23583,
          c: 1.23604,
          v: 206850000
        },
        {
          t: 84300,
          o: 1.23604,
          h: 1.23616,
          l: 1.23598,
          c: 1.2361,
          v: 355570000
        },
        {
          t: 84600,
          o: 1.23611,
          h: 1.23611,
          l: 1.23603,
          c: 1.23603,
          v: 339240000
        },
        {
          t: 84900,
          o: 1.23603,
          h: 1.23625,
          l: 1.23602,
          c: 1.23616,
          v: 269870000
        },
        {
          t: 85200,
          o: 1.23615,
          h: 1.23625,
          l: 1.23615,
          c: 1.23617,
          v: 182540000
        },
        {
          t: 85500,
          o: 1.23616,
          h: 1.23633,
          l: 1.23613,
          c: 1.2363,
          v: 233190000
        },
        {
          t: 85800,
          o: 1.2363,
          h: 1.23648,
          l: 1.23627,
          c: 1.23637,
          v: 355720000
        },
        {
          t: 86100,
          o: 1.23637,
          h: 1.23642,
          l: 1.23619,
          c: 1.23627,
          v: 386750000
        }
      ]
    },
    {
      d: 1523404800,
      b: [
        {
          t: 0,
          o: 1.23627,
          h: 1.2365,
          l: 1.23618,
          c: 1.23631,
          v: 619290000
        },
        {
          t: 300,
          o: 1.23629,
          h: 1.23639,
          l: 1.2358,
          c: 1.23583,
          v: 906020001
        },
        {
          t: 600,
          o: 1.23582,
          h: 1.23625,
          l: 1.23582,
          c: 1.2362,
          v: 565050000
        },
        {
          t: 900,
          o: 1.2362,
          h: 1.23626,
          l: 1.23605,
          c: 1.23606,
          v: 632280000
        },
        {
          t: 1200,
          o: 1.23606,
          h: 1.23644,
          l: 1.23604,
          c: 1.23634,
          v: 428840000
        },
        {
          t: 1500,
          o: 1.23635,
          h: 1.23641,
          l: 1.23617,
          c: 1.23619,
          v: 375760000
        },
        {
          t: 1800,
          o: 1.23619,
          h: 1.23619,
          l: 1.23607,
          c: 1.23611,
          v: 391420000
        },
        {
          t: 2100,
          o: 1.23613,
          h: 1.2362,
          l: 1.23597,
          c: 1.23597,
          v: 603520000
        },
        {
          t: 2400,
          o: 1.23597,
          h: 1.23609,
          l: 1.23592,
          c: 1.23608,
          v: 727130000
        },
        {
          t: 2700,
          o: 1.23609,
          h: 1.23636,
          l: 1.23608,
          c: 1.23624,
          v: 609840000
        },
        {
          t: 3000,
          o: 1.23624,
          h: 1.23631,
          l: 1.23609,
          c: 1.23609,
          v: 592230000
        },
        {
          t: 3300,
          o: 1.23609,
          h: 1.23616,
          l: 1.2359,
          c: 1.23609,
          v: 768240000
        },
        {
          t: 3600,
          o: 1.23609,
          h: 1.2363,
          l: 1.23601,
          c: 1.2362,
          v: 644620000
        },
        {
          t: 3900,
          o: 1.23621,
          h: 1.23626,
          l: 1.23593,
          c: 1.23599,
          v: 850350000
        },
        {
          t: 4200,
          o: 1.23603,
          h: 1.23611,
          l: 1.23591,
          c: 1.23599,
          v: 711000000
        },
        {
          t: 4500,
          o: 1.23598,
          h: 1.23604,
          l: 1.23589,
          c: 1.23601,
          v: 394650000
        },
        {
          t: 4800,
          o: 1.23602,
          h: 1.23617,
          l: 1.236,
          c: 1.23601,
          v: 550680000
        },
        {
          t: 5100,
          o: 1.23601,
          h: 1.23607,
          l: 1.23582,
          c: 1.23592,
          v: 357890000
        },
        {
          t: 5400,
          o: 1.23593,
          h: 1.23593,
          l: 1.23572,
          c: 1.23585,
          v: 369840000
        },
        {
          t: 5700,
          o: 1.23585,
          h: 1.23592,
          l: 1.23574,
          c: 1.23575,
          v: 695090000
        },
        {
          t: 6000,
          o: 1.23575,
          h: 1.23596,
          l: 1.23575,
          c: 1.23586,
          v: 632710000
        },
        {
          t: 6300,
          o: 1.23586,
          h: 1.23587,
          l: 1.23577,
          c: 1.23577,
          v: 447620000
        },
        {
          t: 6600,
          o: 1.23579,
          h: 1.23585,
          l: 1.23564,
          c: 1.23565,
          v: 720220000
        },
        {
          t: 6900,
          o: 1.23563,
          h: 1.23566,
          l: 1.23526,
          c: 1.23544,
          v: 609450000
        },
        {
          t: 7200,
          o: 1.23545,
          h: 1.23582,
          l: 1.23545,
          c: 1.23577,
          v: 460739999
        },
        {
          t: 7500,
          o: 1.23579,
          h: 1.23582,
          l: 1.23566,
          c: 1.23575,
          v: 652450000
        },
        {
          t: 7800,
          o: 1.23575,
          h: 1.23579,
          l: 1.2355,
          c: 1.23566,
          v: 404240000
        },
        {
          t: 8100,
          o: 1.23566,
          h: 1.23583,
          l: 1.23565,
          c: 1.23583,
          v: 508780000
        },
        {
          t: 8400,
          o: 1.23582,
          h: 1.23582,
          l: 1.23555,
          c: 1.23556,
          v: 308660000
        },
        {
          t: 8700,
          o: 1.23555,
          h: 1.23593,
          l: 1.23555,
          c: 1.23586,
          v: 358580000
        },
        {
          t: 9000,
          o: 1.23585,
          h: 1.23629,
          l: 1.23582,
          c: 1.23609,
          v: 557830000
        },
        {
          t: 9300,
          o: 1.23609,
          h: 1.23613,
          l: 1.23589,
          c: 1.23593,
          v: 539370000
        },
        {
          t: 9600,
          o: 1.23592,
          h: 1.23609,
          l: 1.23592,
          c: 1.23593,
          v: 455500000
        },
        {
          t: 9900,
          o: 1.23593,
          h: 1.23594,
          l: 1.23576,
          c: 1.2358,
          v: 488010000
        },
        {
          t: 10200,
          o: 1.23581,
          h: 1.23587,
          l: 1.23578,
          c: 1.23581,
          v: 443640000
        },
        {
          t: 10500,
          o: 1.23581,
          h: 1.23587,
          l: 1.23571,
          c: 1.23582,
          v: 407660000
        },
        {
          t: 10800,
          o: 1.23585,
          h: 1.23594,
          l: 1.23571,
          c: 1.23593,
          v: 546030000
        },
        {
          t: 11100,
          o: 1.23593,
          h: 1.23593,
          l: 1.23581,
          c: 1.23584,
          v: 438830000
        },
        {
          t: 11400,
          o: 1.23585,
          h: 1.23593,
          l: 1.23581,
          c: 1.23582,
          v: 790820004
        },
        {
          t: 11700,
          o: 1.23583,
          h: 1.23593,
          l: 1.23581,
          c: 1.23586,
          v: 482980000
        },
        {
          t: 12000,
          o: 1.23586,
          h: 1.23597,
          l: 1.23583,
          c: 1.23594,
          v: 745940004
        },
        {
          t: 12300,
          o: 1.23594,
          h: 1.23602,
          l: 1.23594,
          c: 1.23598,
          v: 351150000
        },
        {
          t: 12600,
          o: 1.23598,
          h: 1.2361,
          l: 1.23594,
          c: 1.23609,
          v: 461090000
        },
        {
          t: 12900,
          o: 1.23609,
          h: 1.23619,
          l: 1.23607,
          c: 1.23617,
          v: 426660000
        },
        {
          t: 13200,
          o: 1.23617,
          h: 1.23619,
          l: 1.23612,
          c: 1.23612,
          v: 408800000
        },
        {
          t: 13500,
          o: 1.23613,
          h: 1.2364,
          l: 1.23612,
          c: 1.23637,
          v: 343679999
        },
        {
          t: 13800,
          o: 1.23637,
          h: 1.2364,
          l: 1.23624,
          c: 1.23627,
          v: 404830000
        },
        {
          t: 14100,
          o: 1.23627,
          h: 1.2363,
          l: 1.23624,
          c: 1.23628,
          v: 279530000
        },
        {
          t: 14400,
          o: 1.23627,
          h: 1.23629,
          l: 1.23622,
          c: 1.23627,
          v: 352080000
        },
        {
          t: 14700,
          o: 1.23627,
          h: 1.23629,
          l: 1.23621,
          c: 1.23622,
          v: 314320000
        },
        {
          t: 15000,
          o: 1.23622,
          h: 1.23623,
          l: 1.23605,
          c: 1.23612,
          v: 295970000
        },
        {
          t: 15300,
          o: 1.23614,
          h: 1.23628,
          l: 1.23611,
          c: 1.23623,
          v: 430310000
        },
        {
          t: 15600,
          o: 1.23622,
          h: 1.23633,
          l: 1.2362,
          c: 1.23621,
          v: 392140000
        },
        {
          t: 15900,
          o: 1.23621,
          h: 1.23623,
          l: 1.23615,
          c: 1.23621,
          v: 438600000
        },
        {
          t: 16200,
          o: 1.23621,
          h: 1.23639,
          l: 1.23616,
          c: 1.23631,
          v: 531700000
        },
        {
          t: 16500,
          o: 1.23631,
          h: 1.23642,
          l: 1.2363,
          c: 1.23636,
          v: 409490000
        },
        {
          t: 16800,
          o: 1.23636,
          h: 1.23638,
          l: 1.23621,
          c: 1.23637,
          v: 680470000
        },
        {
          t: 17100,
          o: 1.23637,
          h: 1.23637,
          l: 1.23621,
          c: 1.23628,
          v: 454690000
        },
        {
          t: 17400,
          o: 1.23629,
          h: 1.23633,
          l: 1.23623,
          c: 1.23625,
          v: 333580000
        },
        {
          t: 17700,
          o: 1.23626,
          h: 1.2364,
          l: 1.23626,
          c: 1.23638,
          v: 377370000
        },
        {
          t: 18000,
          o: 1.23638,
          h: 1.23643,
          l: 1.23625,
          c: 1.23633,
          v: 810050000
        },
        {
          t: 18300,
          o: 1.23633,
          h: 1.23642,
          l: 1.23631,
          c: 1.23641,
          v: 483010000
        },
        {
          t: 18600,
          o: 1.23641,
          h: 1.23676,
          l: 1.23638,
          c: 1.23668,
          v: 543360001
        },
        {
          t: 18900,
          o: 1.23668,
          h: 1.23674,
          l: 1.2365,
          c: 1.23667,
          v: 553870000
        },
        {
          t: 19200,
          o: 1.23667,
          h: 1.23672,
          l: 1.23662,
          c: 1.23663,
          v: 365900000
        },
        {
          t: 19500,
          o: 1.23664,
          h: 1.23696,
          l: 1.23661,
          c: 1.23691,
          v: 551240000
        },
        {
          t: 19800,
          o: 1.23691,
          h: 1.23698,
          l: 1.23681,
          c: 1.23682,
          v: 642360000
        },
        {
          t: 20100,
          o: 1.23683,
          h: 1.23686,
          l: 1.23659,
          c: 1.23661,
          v: 594740000
        },
        {
          t: 20400,
          o: 1.2366,
          h: 1.23668,
          l: 1.23648,
          c: 1.23649,
          v: 462010000
        },
        {
          t: 20700,
          o: 1.23648,
          h: 1.23663,
          l: 1.2364,
          c: 1.23663,
          v: 320650000
        },
        {
          t: 21000,
          o: 1.23663,
          h: 1.23674,
          l: 1.23654,
          c: 1.23658,
          v: 397160000
        },
        {
          t: 21300,
          o: 1.23658,
          h: 1.23669,
          l: 1.23645,
          c: 1.23647,
          v: 578660000
        },
        {
          t: 21600,
          o: 1.23646,
          h: 1.23647,
          l: 1.23626,
          c: 1.23645,
          v: 710840000
        },
        {
          t: 21900,
          o: 1.23644,
          h: 1.23687,
          l: 1.23641,
          c: 1.23683,
          v: 1110090000
        },
        {
          t: 22200,
          o: 1.23683,
          h: 1.23709,
          l: 1.23683,
          c: 1.23694,
          v: 902280000
        },
        {
          t: 22500,
          o: 1.23694,
          h: 1.2371,
          l: 1.23673,
          c: 1.23699,
          v: 781110000
        },
        {
          t: 22800,
          o: 1.23699,
          h: 1.237,
          l: 1.23673,
          c: 1.23698,
          v: 818400000
        },
        {
          t: 23100,
          o: 1.23698,
          h: 1.23714,
          l: 1.23688,
          c: 1.23711,
          v: 769590000
        },
        {
          t: 23400,
          o: 1.23711,
          h: 1.23755,
          l: 1.23708,
          c: 1.2375,
          v: 1161710000
        },
        {
          t: 23700,
          o: 1.2375,
          h: 1.23755,
          l: 1.23734,
          c: 1.23744,
          v: 1261710000
        },
        {
          t: 24000,
          o: 1.23744,
          h: 1.23764,
          l: 1.23729,
          c: 1.23757,
          v: 937600000
        },
        {
          t: 24300,
          o: 1.23756,
          h: 1.23756,
          l: 1.23727,
          c: 1.23737,
          v: 831950000
        },
        {
          t: 24600,
          o: 1.23737,
          h: 1.23813,
          l: 1.23736,
          c: 1.23793,
          v: 1636250000
        },
        {
          t: 24900,
          o: 1.23792,
          h: 1.23792,
          l: 1.23751,
          c: 1.23763,
          v: 1276670001
        },
        {
          t: 25200,
          o: 1.23763,
          h: 1.23763,
          l: 1.23717,
          c: 1.23743,
          v: 1229740000
        },
        {
          t: 25500,
          o: 1.23743,
          h: 1.23781,
          l: 1.2373,
          c: 1.23737,
          v: 1197850000
        },
        {
          t: 25800,
          o: 1.23737,
          h: 1.23793,
          l: 1.23732,
          c: 1.23744,
          v: 1398720000
        },
        {
          t: 26100,
          o: 1.23744,
          h: 1.23785,
          l: 1.23725,
          c: 1.23748,
          v: 1278280000
        },
        {
          t: 26400,
          o: 1.23748,
          h: 1.23836,
          l: 1.23744,
          c: 1.23832,
          v: 1399210000
        },
        {
          t: 26700,
          o: 1.23833,
          h: 1.23854,
          l: 1.23788,
          c: 1.23816,
          v: 1429290001
        },
        {
          t: 27000,
          o: 1.23816,
          h: 1.23871,
          l: 1.23816,
          c: 1.23856,
          v: 1518380000
        },
        {
          t: 27300,
          o: 1.23858,
          h: 1.23872,
          l: 1.23794,
          c: 1.23806,
          v: 1140950000
        },
        {
          t: 27600,
          o: 1.23807,
          h: 1.23853,
          l: 1.23806,
          c: 1.23836,
          v: 1112990000
        },
        {
          t: 27900,
          o: 1.23839,
          h: 1.23839,
          l: 1.23784,
          c: 1.23785,
          v: 1103510001
        },
        {
          t: 28200,
          o: 1.23786,
          h: 1.23788,
          l: 1.23748,
          c: 1.23756,
          v: 1135220000
        },
        {
          t: 28500,
          o: 1.23757,
          h: 1.23787,
          l: 1.23757,
          c: 1.23762,
          v: 1270520000
        },
        {
          t: 28800,
          o: 1.23761,
          h: 1.23767,
          l: 1.23718,
          c: 1.23722,
          v: 1325390001
        },
        {
          t: 29100,
          o: 1.23722,
          h: 1.23727,
          l: 1.23687,
          c: 1.23687,
          v: 1454600001
        },
        {
          t: 29400,
          o: 1.23686,
          h: 1.23686,
          l: 1.2363,
          c: 1.23669,
          v: 1373850000
        },
        {
          t: 29700,
          o: 1.23667,
          h: 1.23697,
          l: 1.23658,
          c: 1.23691,
          v: 1267800000
        },
        {
          t: 30000,
          o: 1.23691,
          h: 1.23697,
          l: 1.23659,
          c: 1.23659,
          v: 1221610000
        },
        {
          t: 30300,
          o: 1.23659,
          h: 1.23686,
          l: 1.23652,
          c: 1.23661,
          v: 634590000
        },
        {
          t: 30600,
          o: 1.23657,
          h: 1.23676,
          l: 1.23623,
          c: 1.23629,
          v: 1087530001
        },
        {
          t: 30900,
          o: 1.23629,
          h: 1.23674,
          l: 1.23621,
          c: 1.23664,
          v: 1111270000
        },
        {
          t: 31200,
          o: 1.23665,
          h: 1.23699,
          l: 1.2366,
          c: 1.23686,
          v: 820490000
        },
        {
          t: 31500,
          o: 1.23686,
          h: 1.23719,
          l: 1.23686,
          c: 1.23705,
          v: 719690000
        },
        {
          t: 31800,
          o: 1.23704,
          h: 1.23739,
          l: 1.23694,
          c: 1.23734,
          v: 992480000
        },
        {
          t: 32100,
          o: 1.23733,
          h: 1.23772,
          l: 1.23723,
          c: 1.23772,
          v: 681370001
        },
        {
          t: 32400,
          o: 1.2377,
          h: 1.23771,
          l: 1.23719,
          c: 1.23722,
          v: 887690000
        },
        {
          t: 32700,
          o: 1.23722,
          h: 1.23752,
          l: 1.23708,
          c: 1.23718,
          v: 1070430000
        },
        {
          t: 33000,
          o: 1.23718,
          h: 1.23733,
          l: 1.23671,
          c: 1.23676,
          v: 995450000
        },
        {
          t: 33300,
          o: 1.23676,
          h: 1.23686,
          l: 1.23661,
          c: 1.23661,
          v: 1045409999
        },
        {
          t: 33600,
          o: 1.23659,
          h: 1.23669,
          l: 1.2363,
          c: 1.23653,
          v: 1172770001
        },
        {
          t: 33900,
          o: 1.23654,
          h: 1.2367,
          l: 1.23622,
          c: 1.23652,
          v: 1119740000
        },
        {
          t: 34200,
          o: 1.2365,
          h: 1.23689,
          l: 1.23637,
          c: 1.23689,
          v: 942700000
        },
        {
          t: 34500,
          o: 1.23689,
          h: 1.23718,
          l: 1.23682,
          c: 1.23707,
          v: 745920000
        },
        {
          t: 34800,
          o: 1.23706,
          h: 1.23738,
          l: 1.23702,
          c: 1.23719,
          v: 700740000
        },
        {
          t: 35100,
          o: 1.23721,
          h: 1.23761,
          l: 1.23712,
          c: 1.23723,
          v: 850390000
        },
        {
          t: 35400,
          o: 1.23722,
          h: 1.23747,
          l: 1.23701,
          c: 1.2374,
          v: 958570000
        },
        {
          t: 35700,
          o: 1.2374,
          h: 1.23787,
          l: 1.23739,
          c: 1.2378,
          v: 990019999
        },
        {
          t: 36000,
          o: 1.23781,
          h: 1.23797,
          l: 1.23768,
          c: 1.23768,
          v: 1120230000
        },
        {
          t: 36300,
          o: 1.23771,
          h: 1.23797,
          l: 1.23742,
          c: 1.23755,
          v: 1092230000
        },
        {
          t: 36600,
          o: 1.23756,
          h: 1.23758,
          l: 1.23729,
          c: 1.23751,
          v: 1058790000
        },
        {
          t: 36900,
          o: 1.23752,
          h: 1.23774,
          l: 1.23726,
          c: 1.23765,
          v: 1325119999
        },
        {
          t: 37200,
          o: 1.23765,
          h: 1.23845,
          l: 1.23765,
          c: 1.2382,
          v: 1329760000
        },
        {
          t: 37500,
          o: 1.2382,
          h: 1.23821,
          l: 1.23781,
          c: 1.23797,
          v: 786150002
        },
        {
          t: 37800,
          o: 1.23796,
          h: 1.23809,
          l: 1.2378,
          c: 1.23784,
          v: 684140000
        },
        {
          t: 38100,
          o: 1.23784,
          h: 1.23784,
          l: 1.23759,
          c: 1.2376,
          v: 611400000
        },
        {
          t: 38400,
          o: 1.2376,
          h: 1.23797,
          l: 1.23739,
          c: 1.2379,
          v: 880030000
        },
        {
          t: 38700,
          o: 1.23789,
          h: 1.2379,
          l: 1.23741,
          c: 1.23759,
          v: 946430001
        },
        {
          t: 39000,
          o: 1.23759,
          h: 1.23773,
          l: 1.23727,
          c: 1.23731,
          v: 778990000
        },
        {
          t: 39300,
          o: 1.23731,
          h: 1.23757,
          l: 1.2368,
          c: 1.2375,
          v: 963170000
        },
        {
          t: 39600,
          o: 1.23749,
          h: 1.23773,
          l: 1.23706,
          c: 1.23769,
          v: 1568110000
        },
        {
          t: 39900,
          o: 1.23766,
          h: 1.23828,
          l: 1.23749,
          c: 1.23814,
          v: 1702180000
        },
        {
          t: 40200,
          o: 1.23815,
          h: 1.23841,
          l: 1.23799,
          c: 1.23822,
          v: 1498090000
        },
        {
          t: 40500,
          o: 1.23824,
          h: 1.23845,
          l: 1.23801,
          c: 1.23829,
          v: 1339110000
        },
        {
          t: 40800,
          o: 1.2383,
          h: 1.23887,
          l: 1.23826,
          c: 1.23861,
          v: 1506829999
        },
        {
          t: 41100,
          o: 1.23861,
          h: 1.23863,
          l: 1.23813,
          c: 1.23817,
          v: 1303130000
        },
        {
          t: 41400,
          o: 1.23817,
          h: 1.23844,
          l: 1.23802,
          c: 1.23822,
          v: 1396590000
        },
        {
          t: 41700,
          o: 1.23822,
          h: 1.23847,
          l: 1.23799,
          c: 1.23825,
          v: 1186790000
        },
        {
          t: 42000,
          o: 1.23825,
          h: 1.23851,
          l: 1.23808,
          c: 1.23818,
          v: 1150170000
        },
        {
          t: 42300,
          o: 1.23819,
          h: 1.23826,
          l: 1.23798,
          c: 1.23807,
          v: 668120000
        },
        {
          t: 42600,
          o: 1.23808,
          h: 1.23813,
          l: 1.23765,
          c: 1.23787,
          v: 912560000
        },
        {
          t: 42900,
          o: 1.23788,
          h: 1.23788,
          l: 1.23731,
          c: 1.23754,
          v: 1435240001
        },
        {
          t: 43200,
          o: 1.23745,
          h: 1.23798,
          l: 1.23744,
          c: 1.23788,
          v: 1311999999
        },
        {
          t: 43500,
          o: 1.23787,
          h: 1.23828,
          l: 1.23785,
          c: 1.23815,
          v: 977710000
        },
        {
          t: 43800,
          o: 1.23815,
          h: 1.23845,
          l: 1.23802,
          c: 1.23836,
          v: 1036960000
        },
        {
          t: 44100,
          o: 1.23836,
          h: 1.23884,
          l: 1.23827,
          c: 1.23869,
          v: 1178579999
        },
        {
          t: 44400,
          o: 1.2387,
          h: 1.23958,
          l: 1.23856,
          c: 1.23907,
          v: 1401539999
        },
        {
          t: 44700,
          o: 1.23908,
          h: 1.23918,
          l: 1.23821,
          c: 1.23856,
          v: 1205780000
        },
        {
          t: 45000,
          o: 1.23864,
          h: 1.23964,
          l: 1.23815,
          c: 1.23896,
          v: 2140150000
        },
        {
          t: 45300,
          o: 1.23895,
          h: 1.23912,
          l: 1.23838,
          c: 1.23876,
          v: 1563300000
        },
        {
          t: 45600,
          o: 1.23876,
          h: 1.23877,
          l: 1.23811,
          c: 1.23813,
          v: 965660001
        },
        {
          t: 45900,
          o: 1.23813,
          h: 1.23841,
          l: 1.23774,
          c: 1.23795,
          v: 1523600000
        },
        {
          t: 46200,
          o: 1.23793,
          h: 1.23813,
          l: 1.23755,
          c: 1.2378,
          v: 1532210001
        },
        {
          t: 46500,
          o: 1.23782,
          h: 1.23787,
          l: 1.23733,
          c: 1.23753,
          v: 957050000
        },
        {
          t: 46800,
          o: 1.23753,
          h: 1.2378,
          l: 1.2371,
          c: 1.23723,
          v: 1438680002
        },
        {
          t: 47100,
          o: 1.23723,
          h: 1.23754,
          l: 1.23709,
          c: 1.23709,
          v: 1076020000
        },
        {
          t: 47400,
          o: 1.23707,
          h: 1.23761,
          l: 1.23706,
          c: 1.23759,
          v: 984060000
        },
        {
          t: 47700,
          o: 1.23758,
          h: 1.23805,
          l: 1.23742,
          c: 1.23744,
          v: 1024300000
        },
        {
          t: 48000,
          o: 1.23743,
          h: 1.23763,
          l: 1.23682,
          c: 1.23687,
          v: 1205000000
        },
        {
          t: 48300,
          o: 1.23688,
          h: 1.23739,
          l: 1.23686,
          c: 1.23735,
          v: 1006440000
        },
        {
          t: 48600,
          o: 1.23737,
          h: 1.23741,
          l: 1.23705,
          c: 1.23716,
          v: 1245270000
        },
        {
          t: 48900,
          o: 1.23716,
          h: 1.23758,
          l: 1.2371,
          c: 1.23751,
          v: 1184270000
        },
        {
          t: 49200,
          o: 1.23752,
          h: 1.23785,
          l: 1.23741,
          c: 1.23781,
          v: 1105370000
        },
        {
          t: 49500,
          o: 1.23779,
          h: 1.23788,
          l: 1.2375,
          c: 1.23753,
          v: 1227670000
        },
        {
          t: 49800,
          o: 1.23752,
          h: 1.23778,
          l: 1.2374,
          c: 1.2374,
          v: 1268870000
        },
        {
          t: 50100,
          o: 1.23739,
          h: 1.23769,
          l: 1.23714,
          c: 1.23752,
          v: 1536940000
        },
        {
          t: 50400,
          o: 1.23752,
          h: 1.23765,
          l: 1.2373,
          c: 1.23761,
          v: 1928959998
        },
        {
          t: 50700,
          o: 1.2376,
          h: 1.23853,
          l: 1.23758,
          c: 1.23832,
          v: 17833550135
        },
        {
          t: 51000,
          o: 1.2383,
          h: 1.2383,
          l: 1.23774,
          c: 1.23785,
          v: 36159889953
        },
        {
          t: 51300,
          o: 1.23785,
          h: 1.23793,
          l: 1.23751,
          c: 1.23763,
          v: 5051740026
        },
        {
          t: 51600,
          o: 1.23763,
          h: 1.23799,
          l: 1.23757,
          c: 1.23776,
          v: 1206970000
        },
        {
          t: 51900,
          o: 1.23775,
          h: 1.23776,
          l: 1.2374,
          c: 1.2376,
          v: 1288709999
        },
        {
          t: 52200,
          o: 1.2376,
          h: 1.2379,
          l: 1.23745,
          c: 1.23764,
          v: 1271710000
        },
        {
          t: 52500,
          o: 1.23763,
          h: 1.23812,
          l: 1.23751,
          c: 1.23812,
          v: 1307150000
        },
        {
          t: 52800,
          o: 1.23812,
          h: 1.23888,
          l: 1.23797,
          c: 1.23877,
          v: 1410730000
        },
        {
          t: 53100,
          o: 1.23877,
          h: 1.23891,
          l: 1.23834,
          c: 1.23876,
          v: 1431680000
        },
        {
          t: 53400,
          o: 1.23876,
          h: 1.23929,
          l: 1.23854,
          c: 1.23883,
          v: 1892819999
        },
        {
          t: 53700,
          o: 1.23882,
          h: 1.23891,
          l: 1.2379,
          c: 1.23835,
          v: 1945630001
        },
        {
          t: 54000,
          o: 1.23838,
          h: 1.23899,
          l: 1.23835,
          c: 1.23899,
          v: 1927090001
        },
        {
          t: 54300,
          o: 1.239,
          h: 1.23905,
          l: 1.23856,
          c: 1.23876,
          v: 1888709999
        },
        {
          t: 54600,
          o: 1.23876,
          h: 1.23905,
          l: 1.23848,
          c: 1.23895,
          v: 1695470000
        },
        {
          t: 54900,
          o: 1.23894,
          h: 1.23894,
          l: 1.23838,
          c: 1.23862,
          v: 1528910000
        },
        {
          t: 55200,
          o: 1.23862,
          h: 1.23866,
          l: 1.23806,
          c: 1.2382,
          v: 1925080000
        },
        {
          t: 55500,
          o: 1.2382,
          h: 1.23853,
          l: 1.2381,
          c: 1.23816,
          v: 1464350000
        },
        {
          t: 55800,
          o: 1.23816,
          h: 1.23831,
          l: 1.23804,
          c: 1.23815,
          v: 1208240000
        },
        {
          t: 56100,
          o: 1.23815,
          h: 1.23858,
          l: 1.2381,
          c: 1.23855,
          v: 1090690000
        },
        {
          t: 56400,
          o: 1.23854,
          h: 1.23881,
          l: 1.2384,
          c: 1.23879,
          v: 1044400000
        },
        {
          t: 56700,
          o: 1.2388,
          h: 1.23886,
          l: 1.23861,
          c: 1.23868,
          v: 1206539999
        },
        {
          t: 57000,
          o: 1.23869,
          h: 1.23895,
          l: 1.23813,
          c: 1.23813,
          v: 1316340001
        },
        {
          t: 57300,
          o: 1.23813,
          h: 1.2384,
          l: 1.23794,
          c: 1.23838,
          v: 1367280000
        },
        {
          t: 57600,
          o: 1.23838,
          h: 1.23845,
          l: 1.23811,
          c: 1.23811,
          v: 1261370000
        },
        {
          t: 57900,
          o: 1.2381,
          h: 1.2385,
          l: 1.23801,
          c: 1.23842,
          v: 1052920000
        },
        {
          t: 58200,
          o: 1.23842,
          h: 1.23846,
          l: 1.23788,
          c: 1.23825,
          v: 1290490000
        },
        {
          t: 58500,
          o: 1.23824,
          h: 1.23829,
          l: 1.23749,
          c: 1.23761,
          v: 1523700000
        },
        {
          t: 58800,
          o: 1.23762,
          h: 1.23776,
          l: 1.23746,
          c: 1.23773,
          v: 1313310001
        },
        {
          t: 59100,
          o: 1.23773,
          h: 1.23802,
          l: 1.23771,
          c: 1.23796,
          v: 906700000
        },
        {
          t: 59400,
          o: 1.23797,
          h: 1.23803,
          l: 1.23778,
          c: 1.23793,
          v: 1026300000
        },
        {
          t: 59700,
          o: 1.23793,
          h: 1.23817,
          l: 1.2379,
          c: 1.23812,
          v: 1110240000
        },
        {
          t: 60000,
          o: 1.23812,
          h: 1.2382,
          l: 1.23776,
          c: 1.23792,
          v: 1050490000
        },
        {
          t: 60300,
          o: 1.23791,
          h: 1.23799,
          l: 1.23764,
          c: 1.23764,
          v: 1121140000
        },
        {
          t: 60600,
          o: 1.23764,
          h: 1.23786,
          l: 1.23748,
          c: 1.23759,
          v: 1251060000
        },
        {
          t: 60900,
          o: 1.23759,
          h: 1.23759,
          l: 1.23709,
          c: 1.23745,
          v: 1529900000
        },
        {
          t: 61200,
          o: 1.23745,
          h: 1.2376,
          l: 1.23707,
          c: 1.23713,
          v: 1541980000
        },
        {
          t: 61500,
          o: 1.23713,
          h: 1.23748,
          l: 1.23709,
          c: 1.23746,
          v: 1034420000
        },
        {
          t: 61800,
          o: 1.23746,
          h: 1.23772,
          l: 1.23734,
          c: 1.23751,
          v: 936759999
        },
        {
          t: 62100,
          o: 1.23751,
          h: 1.23763,
          l: 1.2374,
          c: 1.23756,
          v: 929770000
        },
        {
          t: 62400,
          o: 1.23756,
          h: 1.23778,
          l: 1.23751,
          c: 1.23759,
          v: 894560000
        },
        {
          t: 62700,
          o: 1.23758,
          h: 1.23776,
          l: 1.23748,
          c: 1.23763,
          v: 1078690000
        },
        {
          t: 63000,
          o: 1.23762,
          h: 1.23769,
          l: 1.23749,
          c: 1.23754,
          v: 1002910001
        },
        {
          t: 63300,
          o: 1.23755,
          h: 1.23758,
          l: 1.23733,
          c: 1.23738,
          v: 848760000
        },
        {
          t: 63600,
          o: 1.23738,
          h: 1.2378,
          l: 1.23737,
          c: 1.23757,
          v: 828420000
        },
        {
          t: 63900,
          o: 1.23757,
          h: 1.23763,
          l: 1.23732,
          c: 1.23734,
          v: 523370000
        },
        {
          t: 64200,
          o: 1.23734,
          h: 1.23741,
          l: 1.23698,
          c: 1.23698,
          v: 717090000
        },
        {
          t: 64500,
          o: 1.23699,
          h: 1.23727,
          l: 1.23685,
          c: 1.23719,
          v: 950800000
        },
        {
          t: 64800,
          o: 1.23721,
          h: 1.23731,
          l: 1.236,
          c: 1.236,
          v: 1562520001
        },
        {
          t: 65100,
          o: 1.23602,
          h: 1.23615,
          l: 1.23524,
          c: 1.23584,
          v: 1933079999
        },
        {
          t: 65400,
          o: 1.23583,
          h: 1.23584,
          l: 1.23477,
          c: 1.23498,
          v: 1669020000
        },
        {
          t: 65700,
          o: 1.23498,
          h: 1.23506,
          l: 1.2347,
          c: 1.23504,
          v: 1343280000
        },
        {
          t: 66000,
          o: 1.23506,
          h: 1.23575,
          l: 1.23505,
          c: 1.23563,
          v: 1217139999
        },
        {
          t: 66300,
          o: 1.23563,
          h: 1.23586,
          l: 1.2355,
          c: 1.2357,
          v: 1113030000
        },
        {
          t: 66600,
          o: 1.23571,
          h: 1.23602,
          l: 1.23566,
          c: 1.23594,
          v: 981820000
        },
        {
          t: 66900,
          o: 1.23594,
          h: 1.23612,
          l: 1.23579,
          c: 1.23606,
          v: 839220000
        },
        {
          t: 67200,
          o: 1.23606,
          h: 1.23621,
          l: 1.23599,
          c: 1.23621,
          v: 923719999
        },
        {
          t: 67500,
          o: 1.23621,
          h: 1.23626,
          l: 1.23604,
          c: 1.23618,
          v: 1027230000
        },
        {
          t: 67800,
          o: 1.23619,
          h: 1.23654,
          l: 1.23618,
          c: 1.23639,
          v: 921050000
        },
        {
          t: 68100,
          o: 1.23638,
          h: 1.23647,
          l: 1.2362,
          c: 1.23622,
          v: 1253439999
        },
        {
          t: 68400,
          o: 1.23625,
          h: 1.23639,
          l: 1.23612,
          c: 1.23632,
          v: 1081790000
        },
        {
          t: 68700,
          o: 1.23633,
          h: 1.23668,
          l: 1.23632,
          c: 1.23652,
          v: 863670000
        },
        {
          t: 69000,
          o: 1.23652,
          h: 1.23665,
          l: 1.23643,
          c: 1.23648,
          v: 970120000
        },
        {
          t: 69300,
          o: 1.23647,
          h: 1.23653,
          l: 1.23623,
          c: 1.23639,
          v: 1175520000
        },
        {
          t: 69600,
          o: 1.2364,
          h: 1.23647,
          l: 1.23627,
          c: 1.23641,
          v: 956790000
        },
        {
          t: 69900,
          o: 1.23641,
          h: 1.23654,
          l: 1.2363,
          c: 1.23632,
          v: 806640000
        },
        {
          t: 70200,
          o: 1.23632,
          h: 1.23663,
          l: 1.23632,
          c: 1.23642,
          v: 848750000
        },
        {
          t: 70500,
          o: 1.23642,
          h: 1.23654,
          l: 1.23614,
          c: 1.23617,
          v: 747100000
        },
        {
          t: 70800,
          o: 1.23614,
          h: 1.23633,
          l: 1.23598,
          c: 1.2363,
          v: 891610001
        },
        {
          t: 71100,
          o: 1.23631,
          h: 1.23648,
          l: 1.23619,
          c: 1.23639,
          v: 702750000
        },
        {
          t: 71400,
          o: 1.23639,
          h: 1.23641,
          l: 1.23631,
          c: 1.23632,
          v: 608790000
        },
        {
          t: 71700,
          o: 1.23632,
          h: 1.23632,
          l: 1.23605,
          c: 1.23615,
          v: 773350000
        },
        {
          t: 72000,
          o: 1.23615,
          h: 1.23626,
          l: 1.23603,
          c: 1.23605,
          v: 453390000
        },
        {
          t: 72300,
          o: 1.23605,
          h: 1.23631,
          l: 1.23605,
          c: 1.23628,
          v: 273060000
        },
        {
          t: 72600,
          o: 1.23628,
          h: 1.23645,
          l: 1.23621,
          c: 1.2364,
          v: 427690000
        },
        {
          t: 72900,
          o: 1.23639,
          h: 1.2366,
          l: 1.2363,
          c: 1.23658,
          v: 635809999
        },
        {
          t: 73200,
          o: 1.23658,
          h: 1.23669,
          l: 1.23652,
          c: 1.23667,
          v: 289310000
        },
        {
          t: 73500,
          o: 1.23666,
          h: 1.23703,
          l: 1.23662,
          c: 1.23694,
          v: 609380000
        },
        {
          t: 73800,
          o: 1.23694,
          h: 1.23696,
          l: 1.23662,
          c: 1.23687,
          v: 462430000
        },
        {
          t: 74100,
          o: 1.23687,
          h: 1.23707,
          l: 1.23687,
          c: 1.23703,
          v: 423840000
        },
        {
          t: 74400,
          o: 1.23702,
          h: 1.23707,
          l: 1.23685,
          c: 1.23686,
          v: 408720000
        },
        {
          t: 74700,
          o: 1.23686,
          h: 1.23691,
          l: 1.23666,
          c: 1.23676,
          v: 404970000
        },
        {
          t: 75000,
          o: 1.23677,
          h: 1.23689,
          l: 1.23667,
          c: 1.23675,
          v: 356760000
        },
        {
          t: 75300,
          o: 1.23675,
          h: 1.23678,
          l: 1.23666,
          c: 1.23666,
          v: 235970000
        },
        {
          t: 75600,
          o: 1.23664,
          h: 1.23665,
          l: 1.23647,
          c: 1.23648,
          v: 109110000
        },
        {
          t: 75900,
          o: 1.23649,
          h: 1.23704,
          l: 1.23649,
          c: 1.23653,
          v: 378970000
        },
        {
          t: 76200,
          o: 1.23651,
          h: 1.23697,
          l: 1.23651,
          c: 1.23679,
          v: 794570000
        },
        {
          t: 76500,
          o: 1.23676,
          h: 1.23684,
          l: 1.23667,
          c: 1.23675,
          v: 509440000
        },
        {
          t: 76800,
          o: 1.23675,
          h: 1.23676,
          l: 1.23672,
          c: 1.23672,
          v: 65950000
        },
        {
          t: 77100,
          o: 1.23673,
          h: 1.23682,
          l: 1.2366,
          c: 1.23682,
          v: 311970000
        },
        {
          t: 77400,
          o: 1.23683,
          h: 1.23685,
          l: 1.23651,
          c: 1.23664,
          v: 366700000
        },
        {
          t: 77700,
          o: 1.23665,
          h: 1.23665,
          l: 1.23649,
          c: 1.23655,
          v: 128500000
        },
        {
          t: 78000,
          o: 1.23656,
          h: 1.23659,
          l: 1.23638,
          c: 1.23655,
          v: 242570000
        },
        {
          t: 78300,
          o: 1.23654,
          h: 1.23689,
          l: 1.23636,
          c: 1.23653,
          v: 626840000
        },
        {
          t: 78600,
          o: 1.23659,
          h: 1.23679,
          l: 1.23652,
          c: 1.23674,
          v: 702220000
        },
        {
          t: 78900,
          o: 1.23674,
          h: 1.2368,
          l: 1.23656,
          c: 1.23671,
          v: 573640000
        },
        {
          t: 79200,
          o: 1.23671,
          h: 1.23698,
          l: 1.23666,
          c: 1.23697,
          v: 615430000
        },
        {
          t: 79500,
          o: 1.23699,
          h: 1.237,
          l: 1.23694,
          c: 1.237,
          v: 336400000
        },
        {
          t: 79800,
          o: 1.23699,
          h: 1.2371,
          l: 1.23698,
          c: 1.237,
          v: 294320000
        },
        {
          t: 80100,
          o: 1.237,
          h: 1.23711,
          l: 1.23699,
          c: 1.23701,
          v: 211450000
        },
        {
          t: 80400,
          o: 1.23701,
          h: 1.23705,
          l: 1.23698,
          c: 1.23705,
          v: 155640000
        },
        {
          t: 80700,
          o: 1.23704,
          h: 1.23705,
          l: 1.2369,
          c: 1.2369,
          v: 116500000
        },
        {
          t: 81000,
          o: 1.2369,
          h: 1.23697,
          l: 1.23681,
          c: 1.23691,
          v: 116530000
        },
        {
          t: 81300,
          o: 1.23691,
          h: 1.23701,
          l: 1.2369,
          c: 1.23698,
          v: 54860000
        },
        {
          t: 81600,
          o: 1.23697,
          h: 1.23699,
          l: 1.23695,
          c: 1.23695,
          v: 77900000
        },
        {
          t: 81900,
          o: 1.23696,
          h: 1.23705,
          l: 1.23695,
          c: 1.23696,
          v: 74370000
        },
        {
          t: 82200,
          o: 1.23695,
          h: 1.2371,
          l: 1.23694,
          c: 1.23694,
          v: 252250000
        },
        {
          t: 82500,
          o: 1.23694,
          h: 1.23701,
          l: 1.23694,
          c: 1.237,
          v: 204940000
        },
        {
          t: 82800,
          o: 1.23699,
          h: 1.23705,
          l: 1.2368,
          c: 1.23684,
          v: 123880000
        },
        {
          t: 83100,
          o: 1.23684,
          h: 1.23707,
          l: 1.23675,
          c: 1.23695,
          v: 246000000
        },
        {
          t: 83400,
          o: 1.23695,
          h: 1.23696,
          l: 1.2369,
          c: 1.2369,
          v: 67750000
        },
        {
          t: 83700,
          o: 1.23691,
          h: 1.23702,
          l: 1.2369,
          c: 1.23692,
          v: 108570000
        },
        {
          t: 84000,
          o: 1.2369,
          h: 1.23694,
          l: 1.23685,
          c: 1.23686,
          v: 183610000
        },
        {
          t: 84300,
          o: 1.23686,
          h: 1.23707,
          l: 1.23686,
          c: 1.23703,
          v: 134480000
        },
        {
          t: 84600,
          o: 1.23704,
          h: 1.23709,
          l: 1.23698,
          c: 1.23707,
          v: 166870000
        },
        {
          t: 84900,
          o: 1.23708,
          h: 1.23718,
          l: 1.23707,
          c: 1.23712,
          v: 168760000
        },
        {
          t: 85200,
          o: 1.23711,
          h: 1.23718,
          l: 1.23711,
          c: 1.23716,
          v: 105400000
        },
        {
          t: 85500,
          o: 1.23715,
          h: 1.23716,
          l: 1.23701,
          c: 1.23712,
          v: 207290000
        },
        {
          t: 85800,
          o: 1.23711,
          h: 1.23722,
          l: 1.2371,
          c: 1.23721,
          v: 266120000
        },
        {
          t: 86100,
          o: 1.23721,
          h: 1.23723,
          l: 1.23712,
          c: 1.23719,
          v: 119360000
        }
      ]
    },
    {
      d: 1523491200,
      b: [
        {
          t: 0,
          o: 1.23719,
          h: 1.23751,
          l: 1.23718,
          c: 1.23747,
          v: 473800000
        },
        {
          t: 300,
          o: 1.23746,
          h: 1.23757,
          l: 1.23737,
          c: 1.23754,
          v: 389460000
        },
        {
          t: 600,
          o: 1.23753,
          h: 1.23768,
          l: 1.23749,
          c: 1.23763,
          v: 521730000
        },
        {
          t: 900,
          o: 1.23761,
          h: 1.23789,
          l: 1.23761,
          c: 1.23786,
          v: 353770000
        },
        {
          t: 1200,
          o: 1.23786,
          h: 1.23797,
          l: 1.23774,
          c: 1.23782,
          v: 418370000
        },
        {
          t: 1500,
          o: 1.23781,
          h: 1.23781,
          l: 1.23754,
          c: 1.23757,
          v: 411900000
        },
        {
          t: 1800,
          o: 1.23756,
          h: 1.23772,
          l: 1.23749,
          c: 1.23764,
          v: 565300000
        },
        {
          t: 2100,
          o: 1.23763,
          h: 1.23785,
          l: 1.23752,
          c: 1.23763,
          v: 364490000
        },
        {
          t: 2400,
          o: 1.23763,
          h: 1.23774,
          l: 1.23715,
          c: 1.23745,
          v: 714620000
        },
        {
          t: 2700,
          o: 1.23744,
          h: 1.23764,
          l: 1.23741,
          c: 1.23742,
          v: 718080001
        },
        {
          t: 3000,
          o: 1.23743,
          h: 1.23788,
          l: 1.23741,
          c: 1.23782,
          v: 790890000
        },
        {
          t: 3300,
          o: 1.2378,
          h: 1.2378,
          l: 1.23751,
          c: 1.23758,
          v: 941890000
        },
        {
          t: 3600,
          o: 1.23758,
          h: 1.23782,
          l: 1.23739,
          c: 1.23749,
          v: 821060000
        },
        {
          t: 3900,
          o: 1.23749,
          h: 1.23755,
          l: 1.2374,
          c: 1.23742,
          v: 620670000
        },
        {
          t: 4200,
          o: 1.23742,
          h: 1.23747,
          l: 1.23729,
          c: 1.23732,
          v: 503140000
        },
        {
          t: 4500,
          o: 1.23733,
          h: 1.23745,
          l: 1.23729,
          c: 1.2373,
          v: 552430000
        },
        {
          t: 4800,
          o: 1.2373,
          h: 1.23733,
          l: 1.23714,
          c: 1.23718,
          v: 262810000
        },
        {
          t: 5100,
          o: 1.23718,
          h: 1.2373,
          l: 1.23715,
          c: 1.23729,
          v: 379690000
        },
        {
          t: 5400,
          o: 1.23729,
          h: 1.23736,
          l: 1.23722,
          c: 1.23728,
          v: 430640000
        },
        {
          t: 5700,
          o: 1.23728,
          h: 1.23738,
          l: 1.23723,
          c: 1.23727,
          v: 436430000
        },
        {
          t: 6000,
          o: 1.23727,
          h: 1.23728,
          l: 1.23707,
          c: 1.23714,
          v: 663700000
        },
        {
          t: 6300,
          o: 1.23713,
          h: 1.23723,
          l: 1.23705,
          c: 1.23714,
          v: 505930000
        },
        {
          t: 6600,
          o: 1.23714,
          h: 1.23716,
          l: 1.23672,
          c: 1.23673,
          v: 939290000
        },
        {
          t: 6900,
          o: 1.23673,
          h: 1.23702,
          l: 1.23673,
          c: 1.23697,
          v: 793550000
        },
        {
          t: 7200,
          o: 1.23698,
          h: 1.23702,
          l: 1.23681,
          c: 1.23694,
          v: 768820000
        },
        {
          t: 7500,
          o: 1.23694,
          h: 1.23696,
          l: 1.23679,
          c: 1.23684,
          v: 541850000
        },
        {
          t: 7800,
          o: 1.23682,
          h: 1.23686,
          l: 1.23647,
          c: 1.23657,
          v: 905760000
        },
        {
          t: 8100,
          o: 1.23657,
          h: 1.23677,
          l: 1.23643,
          c: 1.23674,
          v: 889790000
        },
        {
          t: 8400,
          o: 1.23674,
          h: 1.23692,
          l: 1.23674,
          c: 1.23692,
          v: 596240000
        },
        {
          t: 8700,
          o: 1.23692,
          h: 1.23693,
          l: 1.23671,
          c: 1.23678,
          v: 475570000
        },
        {
          t: 9000,
          o: 1.23678,
          h: 1.2369,
          l: 1.23678,
          c: 1.23678,
          v: 603900000
        },
        {
          t: 9300,
          o: 1.23678,
          h: 1.23685,
          l: 1.23672,
          c: 1.23673,
          v: 325720000
        },
        {
          t: 9600,
          o: 1.23673,
          h: 1.23694,
          l: 1.23673,
          c: 1.23685,
          v: 616790000
        },
        {
          t: 9900,
          o: 1.23685,
          h: 1.237,
          l: 1.2368,
          c: 1.23682,
          v: 712880000
        },
        {
          t: 10200,
          o: 1.23683,
          h: 1.23688,
          l: 1.23673,
          c: 1.23677,
          v: 316200000
        },
        {
          t: 10500,
          o: 1.23678,
          h: 1.23683,
          l: 1.23638,
          c: 1.23649,
          v: 653700000
        },
        {
          t: 10800,
          o: 1.2365,
          h: 1.2366,
          l: 1.23627,
          c: 1.23633,
          v: 933900000
        },
        {
          t: 11100,
          o: 1.23633,
          h: 1.23649,
          l: 1.23627,
          c: 1.23643,
          v: 788920000
        },
        {
          t: 11400,
          o: 1.23643,
          h: 1.23652,
          l: 1.23637,
          c: 1.23647,
          v: 454720000
        },
        {
          t: 11700,
          o: 1.23647,
          h: 1.23657,
          l: 1.23646,
          c: 1.23653,
          v: 426660000
        },
        {
          t: 12000,
          o: 1.23654,
          h: 1.23665,
          l: 1.23646,
          c: 1.23646,
          v: 539300000
        },
        {
          t: 12300,
          o: 1.23647,
          h: 1.2365,
          l: 1.23632,
          c: 1.23649,
          v: 504530000
        },
        {
          t: 12600,
          o: 1.23649,
          h: 1.23659,
          l: 1.23644,
          c: 1.23645,
          v: 432390000
        },
        {
          t: 12900,
          o: 1.23646,
          h: 1.23685,
          l: 1.23646,
          c: 1.23684,
          v: 759940000
        },
        {
          t: 13200,
          o: 1.23684,
          h: 1.2369,
          l: 1.23679,
          c: 1.23685,
          v: 487990000
        },
        {
          t: 13500,
          o: 1.23685,
          h: 1.23696,
          l: 1.23683,
          c: 1.2369,
          v: 418330000
        },
        {
          t: 13800,
          o: 1.2369,
          h: 1.23696,
          l: 1.23683,
          c: 1.2369,
          v: 288840000
        },
        {
          t: 14100,
          o: 1.2369,
          h: 1.23691,
          l: 1.23674,
          c: 1.23685,
          v: 353710000
        },
        {
          t: 14400,
          o: 1.23684,
          h: 1.23687,
          l: 1.23664,
          c: 1.23669,
          v: 380770000
        },
        {
          t: 14700,
          o: 1.23668,
          h: 1.23681,
          l: 1.23668,
          c: 1.23679,
          v: 499590000
        },
        {
          t: 15000,
          o: 1.23678,
          h: 1.2368,
          l: 1.23657,
          c: 1.2366,
          v: 496560000
        },
        {
          t: 15300,
          o: 1.23661,
          h: 1.23664,
          l: 1.23653,
          c: 1.23654,
          v: 547290000
        },
        {
          t: 15600,
          o: 1.23654,
          h: 1.23666,
          l: 1.23632,
          c: 1.23633,
          v: 605290000
        },
        {
          t: 15900,
          o: 1.23633,
          h: 1.23633,
          l: 1.23602,
          c: 1.23611,
          v: 1005870000
        },
        {
          t: 16200,
          o: 1.2361,
          h: 1.2362,
          l: 1.23594,
          c: 1.236,
          v: 803390000
        },
        {
          t: 16500,
          o: 1.236,
          h: 1.23605,
          l: 1.23583,
          c: 1.23584,
          v: 971390000
        },
        {
          t: 16800,
          o: 1.23584,
          h: 1.23596,
          l: 1.2357,
          c: 1.23577,
          v: 539220001
        },
        {
          t: 17100,
          o: 1.23578,
          h: 1.23584,
          l: 1.23572,
          c: 1.2358,
          v: 540270000
        },
        {
          t: 17400,
          o: 1.23579,
          h: 1.23586,
          l: 1.23578,
          c: 1.2358,
          v: 660050000
        },
        {
          t: 17700,
          o: 1.2358,
          h: 1.23583,
          l: 1.23569,
          c: 1.23582,
          v: 579040000
        },
        {
          t: 18000,
          o: 1.23583,
          h: 1.23585,
          l: 1.23563,
          c: 1.23571,
          v: 715680000
        },
        {
          t: 18300,
          o: 1.23573,
          h: 1.23578,
          l: 1.23564,
          c: 1.23574,
          v: 561080000
        },
        {
          t: 18600,
          o: 1.23576,
          h: 1.23586,
          l: 1.2357,
          c: 1.2357,
          v: 452570000
        },
        {
          t: 18900,
          o: 1.23569,
          h: 1.23579,
          l: 1.23554,
          c: 1.23578,
          v: 629750000
        },
        {
          t: 19200,
          o: 1.23577,
          h: 1.236,
          l: 1.23576,
          c: 1.236,
          v: 556740000
        },
        {
          t: 19500,
          o: 1.236,
          h: 1.23607,
          l: 1.23588,
          c: 1.23588,
          v: 542680001
        },
        {
          t: 19800,
          o: 1.23589,
          h: 1.23592,
          l: 1.23568,
          c: 1.23589,
          v: 723990000
        },
        {
          t: 20100,
          o: 1.23588,
          h: 1.23601,
          l: 1.23562,
          c: 1.23569,
          v: 640630000
        },
        {
          t: 20400,
          o: 1.23569,
          h: 1.2358,
          l: 1.23567,
          c: 1.23572,
          v: 569760000
        },
        {
          t: 20700,
          o: 1.23572,
          h: 1.23572,
          l: 1.23551,
          c: 1.23558,
          v: 573320000
        },
        {
          t: 21000,
          o: 1.23558,
          h: 1.23591,
          l: 1.23557,
          c: 1.23579,
          v: 778380000
        },
        {
          t: 21300,
          o: 1.23579,
          h: 1.23588,
          l: 1.23566,
          c: 1.23567,
          v: 733160000
        },
        {
          t: 21600,
          o: 1.23567,
          h: 1.23589,
          l: 1.23535,
          c: 1.23583,
          v: 1032120000
        },
        {
          t: 21900,
          o: 1.23582,
          h: 1.23598,
          l: 1.23578,
          c: 1.23593,
          v: 906630000
        },
        {
          t: 22200,
          o: 1.23594,
          h: 1.23646,
          l: 1.23587,
          c: 1.23644,
          v: 1281190000
        },
        {
          t: 22500,
          o: 1.23645,
          h: 1.23647,
          l: 1.23616,
          c: 1.23645,
          v: 1128220000
        },
        {
          t: 22800,
          o: 1.23645,
          h: 1.23685,
          l: 1.23634,
          c: 1.23681,
          v: 1168359999
        },
        {
          t: 23100,
          o: 1.23682,
          h: 1.23697,
          l: 1.23673,
          c: 1.23697,
          v: 1288790000
        },
        {
          t: 23400,
          o: 1.23697,
          h: 1.23706,
          l: 1.23683,
          c: 1.237,
          v: 1014250000
        },
        {
          t: 23700,
          o: 1.237,
          h: 1.23733,
          l: 1.23694,
          c: 1.23727,
          v: 848370000
        },
        {
          t: 24000,
          o: 1.23726,
          h: 1.23739,
          l: 1.23706,
          c: 1.23713,
          v: 919090000
        },
        {
          t: 24300,
          o: 1.23712,
          h: 1.23724,
          l: 1.23706,
          c: 1.23706,
          v: 1027370001
        },
        {
          t: 24600,
          o: 1.23706,
          h: 1.23716,
          l: 1.23681,
          c: 1.23681,
          v: 1103419999
        },
        {
          t: 24900,
          o: 1.2368,
          h: 1.23693,
          l: 1.23625,
          c: 1.23626,
          v: 1244000000
        },
        {
          t: 25200,
          o: 1.23626,
          h: 1.23649,
          l: 1.23596,
          c: 1.23646,
          v: 1713849996
        },
        {
          t: 25500,
          o: 1.23644,
          h: 1.23654,
          l: 1.23627,
          c: 1.23639,
          v: 1243290000
        },
        {
          t: 25800,
          o: 1.2364,
          h: 1.23684,
          l: 1.23634,
          c: 1.23669,
          v: 1267220001
        },
        {
          t: 26100,
          o: 1.2367,
          h: 1.23708,
          l: 1.2366,
          c: 1.23693,
          v: 1323390000
        },
        {
          t: 26400,
          o: 1.23691,
          h: 1.23704,
          l: 1.23667,
          c: 1.23692,
          v: 1170760000
        },
        {
          t: 26700,
          o: 1.23692,
          h: 1.23692,
          l: 1.23614,
          c: 1.2362,
          v: 913350000
        },
        {
          t: 27000,
          o: 1.2362,
          h: 1.23629,
          l: 1.236,
          c: 1.23617,
          v: 1173190000
        },
        {
          t: 27300,
          o: 1.23614,
          h: 1.23639,
          l: 1.2361,
          c: 1.23621,
          v: 1023620000
        },
        {
          t: 27600,
          o: 1.23621,
          h: 1.23708,
          l: 1.23599,
          c: 1.23707,
          v: 1318380002
        },
        {
          t: 27900,
          o: 1.23706,
          h: 1.23717,
          l: 1.23661,
          c: 1.237,
          v: 1328480000
        },
        {
          t: 28200,
          o: 1.237,
          h: 1.23713,
          l: 1.23662,
          c: 1.23677,
          v: 1226970001
        },
        {
          t: 28500,
          o: 1.23677,
          h: 1.23709,
          l: 1.23645,
          c: 1.23685,
          v: 1160650000
        },
        {
          t: 28800,
          o: 1.23686,
          h: 1.23715,
          l: 1.23652,
          c: 1.23694,
          v: 1324219999
        },
        {
          t: 29100,
          o: 1.23693,
          h: 1.23699,
          l: 1.23664,
          c: 1.23671,
          v: 1219320000
        },
        {
          t: 29400,
          o: 1.23671,
          h: 1.23684,
          l: 1.23654,
          c: 1.23663,
          v: 1034310001
        },
        {
          t: 29700,
          o: 1.23663,
          h: 1.237,
          l: 1.23658,
          c: 1.2368,
          v: 903690000
        },
        {
          t: 30000,
          o: 1.23681,
          h: 1.23697,
          l: 1.23667,
          c: 1.23696,
          v: 1011120000
        },
        {
          t: 30300,
          o: 1.23696,
          h: 1.23728,
          l: 1.23683,
          c: 1.23689,
          v: 993860000
        },
        {
          t: 30600,
          o: 1.23687,
          h: 1.23689,
          l: 1.23657,
          c: 1.23669,
          v: 915050000
        },
        {
          t: 30900,
          o: 1.23669,
          h: 1.23675,
          l: 1.23637,
          c: 1.23654,
          v: 780840000
        },
        {
          t: 31200,
          o: 1.23654,
          h: 1.23669,
          l: 1.23646,
          c: 1.23657,
          v: 606390000
        },
        {
          t: 31500,
          o: 1.23657,
          h: 1.23657,
          l: 1.23582,
          c: 1.23593,
          v: 1370300000
        },
        {
          t: 31800,
          o: 1.23592,
          h: 1.23596,
          l: 1.23567,
          c: 1.23583,
          v: 1184200000
        },
        {
          t: 32100,
          o: 1.23583,
          h: 1.23583,
          l: 1.2345,
          c: 1.23457,
          v: 1304840000
        },
        {
          t: 32400,
          o: 1.23458,
          h: 1.23458,
          l: 1.23366,
          c: 1.23371,
          v: 1927310000
        },
        {
          t: 32700,
          o: 1.23369,
          h: 1.23392,
          l: 1.23356,
          c: 1.23384,
          v: 1603400000
        },
        {
          t: 33000,
          o: 1.23383,
          h: 1.23422,
          l: 1.23342,
          c: 1.2335,
          v: 1393660001
        },
        {
          t: 33300,
          o: 1.23351,
          h: 1.23381,
          l: 1.23308,
          c: 1.23366,
          v: 1519780000
        },
        {
          t: 33600,
          o: 1.23368,
          h: 1.23376,
          l: 1.23349,
          c: 1.23375,
          v: 1213140000
        },
        {
          t: 33900,
          o: 1.23377,
          h: 1.23397,
          l: 1.2337,
          c: 1.23376,
          v: 1171530001
        },
        {
          t: 34200,
          o: 1.23377,
          h: 1.23387,
          l: 1.23339,
          c: 1.23349,
          v: 1270980000
        },
        {
          t: 34500,
          o: 1.2335,
          h: 1.23373,
          l: 1.2334,
          c: 1.23364,
          v: 917590000
        },
        {
          t: 34800,
          o: 1.23364,
          h: 1.23444,
          l: 1.23351,
          c: 1.2343,
          v: 1341540000
        },
        {
          t: 35100,
          o: 1.23429,
          h: 1.23437,
          l: 1.23407,
          c: 1.23433,
          v: 1111690000
        },
        {
          t: 35400,
          o: 1.23434,
          h: 1.23442,
          l: 1.23415,
          c: 1.23434,
          v: 1166230000
        },
        {
          t: 35700,
          o: 1.23433,
          h: 1.23435,
          l: 1.23391,
          c: 1.23403,
          v: 1629840000
        },
        {
          t: 36000,
          o: 1.23403,
          h: 1.23405,
          l: 1.23353,
          c: 1.23375,
          v: 1110260001
        },
        {
          t: 36300,
          o: 1.23375,
          h: 1.23417,
          l: 1.23364,
          c: 1.23377,
          v: 1179010000
        },
        {
          t: 36600,
          o: 1.23378,
          h: 1.23384,
          l: 1.23345,
          c: 1.23365,
          v: 1208550000
        },
        {
          t: 36900,
          o: 1.23366,
          h: 1.23377,
          l: 1.23344,
          c: 1.23372,
          v: 1159149999
        },
        {
          t: 37200,
          o: 1.23371,
          h: 1.23427,
          l: 1.23371,
          c: 1.23425,
          v: 1163780000
        },
        {
          t: 37500,
          o: 1.23424,
          h: 1.23427,
          l: 1.23367,
          c: 1.23382,
          v: 1282150000
        },
        {
          t: 37800,
          o: 1.23383,
          h: 1.23383,
          l: 1.23279,
          c: 1.2329,
          v: 1478320000
        },
        {
          t: 38100,
          o: 1.23288,
          h: 1.23323,
          l: 1.23283,
          c: 1.23299,
          v: 1263070000
        },
        {
          t: 38400,
          o: 1.23299,
          h: 1.23324,
          l: 1.23273,
          c: 1.23324,
          v: 1173070000
        },
        {
          t: 38700,
          o: 1.23326,
          h: 1.23386,
          l: 1.23318,
          c: 1.23384,
          v: 1060819999
        },
        {
          t: 39000,
          o: 1.23383,
          h: 1.23415,
          l: 1.23369,
          c: 1.23413,
          v: 1159750000
        },
        {
          t: 39300,
          o: 1.23412,
          h: 1.23414,
          l: 1.23348,
          c: 1.23376,
          v: 1498910000
        },
        {
          t: 39600,
          o: 1.23376,
          h: 1.2341,
          l: 1.2336,
          c: 1.23392,
          v: 1327760001
        },
        {
          t: 39900,
          o: 1.23392,
          h: 1.2341,
          l: 1.23353,
          c: 1.23407,
          v: 1293210000
        },
        {
          t: 40200,
          o: 1.23407,
          h: 1.23426,
          l: 1.23385,
          c: 1.23411,
          v: 867330000
        },
        {
          t: 40500,
          o: 1.2341,
          h: 1.23474,
          l: 1.23408,
          c: 1.23457,
          v: 1264540000
        },
        {
          t: 40800,
          o: 1.23456,
          h: 1.23515,
          l: 1.23452,
          c: 1.23504,
          v: 1272870000
        },
        {
          t: 41100,
          o: 1.23505,
          h: 1.23515,
          l: 1.23483,
          c: 1.23491,
          v: 790920000
        },
        {
          t: 41400,
          o: 1.23491,
          h: 1.23509,
          l: 1.23323,
          c: 1.2345,
          v: 1943650000
        },
        {
          t: 41700,
          o: 1.2345,
          h: 1.23452,
          l: 1.23392,
          c: 1.23423,
          v: 1542910000
        },
        {
          t: 42000,
          o: 1.23425,
          h: 1.23472,
          l: 1.23376,
          c: 1.23384,
          v: 1545570000
        },
        {
          t: 42300,
          o: 1.23386,
          h: 1.23393,
          l: 1.23305,
          c: 1.23326,
          v: 1614150000
        },
        {
          t: 42600,
          o: 1.23325,
          h: 1.23336,
          l: 1.23233,
          c: 1.23277,
          v: 2011099999
        },
        {
          t: 42900,
          o: 1.23277,
          h: 1.23296,
          l: 1.232,
          c: 1.2321,
          v: 1739870000
        },
        {
          t: 43200,
          o: 1.23209,
          h: 1.23249,
          l: 1.23149,
          c: 1.23248,
          v: 1631819999
        },
        {
          t: 43500,
          o: 1.2325,
          h: 1.23257,
          l: 1.23211,
          c: 1.23228,
          v: 1342710000
        },
        {
          t: 43800,
          o: 1.23228,
          h: 1.23254,
          l: 1.23202,
          c: 1.23222,
          v: 1461920000
        },
        {
          t: 44100,
          o: 1.23223,
          h: 1.23232,
          l: 1.23155,
          c: 1.23194,
          v: 1810850000
        },
        {
          t: 44400,
          o: 1.23198,
          h: 1.23204,
          l: 1.23138,
          c: 1.23156,
          v: 1401500000
        },
        {
          t: 44700,
          o: 1.23156,
          h: 1.23171,
          l: 1.2312,
          c: 1.23161,
          v: 1344699999
        },
        {
          t: 45000,
          o: 1.23162,
          h: 1.23209,
          l: 1.23153,
          c: 1.23169,
          v: 1437000000
        },
        {
          t: 45300,
          o: 1.2317,
          h: 1.23212,
          l: 1.2317,
          c: 1.23193,
          v: 1163740000
        },
        {
          t: 45600,
          o: 1.23193,
          h: 1.23233,
          l: 1.23193,
          c: 1.23204,
          v: 1069929999
        },
        {
          t: 45900,
          o: 1.23202,
          h: 1.23253,
          l: 1.23185,
          c: 1.23253,
          v: 1260000001
        },
        {
          t: 46200,
          o: 1.23255,
          h: 1.23275,
          l: 1.23235,
          c: 1.23253,
          v: 1426160002
        },
        {
          t: 46500,
          o: 1.23253,
          h: 1.23259,
          l: 1.23143,
          c: 1.23155,
          v: 2147269999
        },
        {
          t: 46800,
          o: 1.23154,
          h: 1.23197,
          l: 1.23117,
          c: 1.2318,
          v: 1259470000
        },
        {
          t: 47100,
          o: 1.23182,
          h: 1.23199,
          l: 1.23161,
          c: 1.2317,
          v: 1279580000
        },
        {
          t: 47400,
          o: 1.23172,
          h: 1.23187,
          l: 1.23152,
          c: 1.23185,
          v: 1337370000
        },
        {
          t: 47700,
          o: 1.23185,
          h: 1.23185,
          l: 1.23105,
          c: 1.23124,
          v: 1327390000
        },
        {
          t: 48000,
          o: 1.23124,
          h: 1.23151,
          l: 1.23115,
          c: 1.23124,
          v: 1210240000
        },
        {
          t: 48300,
          o: 1.23124,
          h: 1.23124,
          l: 1.2306,
          c: 1.23085,
          v: 1860820002
        },
        {
          t: 48600,
          o: 1.23083,
          h: 1.23096,
          l: 1.23053,
          c: 1.23084,
          v: 1492420000
        },
        {
          t: 48900,
          o: 1.23084,
          h: 1.23084,
          l: 1.23037,
          c: 1.23054,
          v: 1345660000
        },
        {
          t: 49200,
          o: 1.23054,
          h: 1.23095,
          l: 1.23049,
          c: 1.23081,
          v: 1330169999
        },
        {
          t: 49500,
          o: 1.23082,
          h: 1.23086,
          l: 1.23057,
          c: 1.23083,
          v: 1321180000
        },
        {
          t: 49800,
          o: 1.23083,
          h: 1.23119,
          l: 1.23061,
          c: 1.23097,
          v: 1344000000
        },
        {
          t: 50100,
          o: 1.23097,
          h: 1.23099,
          l: 1.23005,
          c: 1.23067,
          v: 1662080000
        },
        {
          t: 50400,
          o: 1.23068,
          h: 1.23138,
          l: 1.23063,
          c: 1.23107,
          v: 1834940000
        },
        {
          t: 50700,
          o: 1.23107,
          h: 1.23123,
          l: 1.23076,
          c: 1.23106,
          v: 1175980000
        },
        {
          t: 51000,
          o: 1.23107,
          h: 1.23107,
          l: 1.23027,
          c: 1.23057,
          v: 1271690000
        },
        {
          t: 51300,
          o: 1.23057,
          h: 1.23079,
          l: 1.23005,
          c: 1.23014,
          v: 1667469999
        },
        {
          t: 51600,
          o: 1.23013,
          h: 1.23065,
          l: 1.22993,
          c: 1.23057,
          v: 1338959999
        },
        {
          t: 51900,
          o: 1.23057,
          h: 1.23068,
          l: 1.22997,
          c: 1.23019,
          v: 1308470000
        },
        {
          t: 52200,
          o: 1.2302,
          h: 1.23043,
          l: 1.23004,
          c: 1.23012,
          v: 1281850000
        },
        {
          t: 52500,
          o: 1.23011,
          h: 1.23101,
          l: 1.23009,
          c: 1.23081,
          v: 1236740000
        },
        {
          t: 52800,
          o: 1.2308,
          h: 1.23135,
          l: 1.23057,
          c: 1.23068,
          v: 1321620000
        },
        {
          t: 53100,
          o: 1.2307,
          h: 1.23138,
          l: 1.23066,
          c: 1.23107,
          v: 1335040000
        },
        {
          t: 53400,
          o: 1.23107,
          h: 1.23168,
          l: 1.23103,
          c: 1.23153,
          v: 1419039998
        },
        {
          t: 53700,
          o: 1.23151,
          h: 1.23177,
          l: 1.23136,
          c: 1.23167,
          v: 1653600000
        },
        {
          t: 54000,
          o: 1.23168,
          h: 1.23204,
          l: 1.2314,
          c: 1.23157,
          v: 1445580001
        },
        {
          t: 54300,
          o: 1.23159,
          h: 1.23189,
          l: 1.23142,
          c: 1.23151,
          v: 1066670000
        },
        {
          t: 54600,
          o: 1.23151,
          h: 1.23161,
          l: 1.23097,
          c: 1.23161,
          v: 1321290000
        },
        {
          t: 54900,
          o: 1.23162,
          h: 1.23181,
          l: 1.2313,
          c: 1.23135,
          v: 1156070000
        },
        {
          t: 55200,
          o: 1.23136,
          h: 1.23136,
          l: 1.23072,
          c: 1.23112,
          v: 1526630000
        },
        {
          t: 55500,
          o: 1.23111,
          h: 1.23175,
          l: 1.23108,
          c: 1.2317,
          v: 849079999
        },
        {
          t: 55800,
          o: 1.23169,
          h: 1.23186,
          l: 1.23152,
          c: 1.23171,
          v: 817540000
        },
        {
          t: 56100,
          o: 1.23171,
          h: 1.23192,
          l: 1.2317,
          c: 1.23175,
          v: 876340000
        },
        {
          t: 56400,
          o: 1.23175,
          h: 1.23188,
          l: 1.23148,
          c: 1.23152,
          v: 1035160000
        },
        {
          t: 56700,
          o: 1.23151,
          h: 1.23223,
          l: 1.23142,
          c: 1.23223,
          v: 1746939999
        },
        {
          t: 57000,
          o: 1.23223,
          h: 1.23231,
          l: 1.23177,
          c: 1.23179,
          v: 1169450000
        },
        {
          t: 57300,
          o: 1.2318,
          h: 1.23216,
          l: 1.23172,
          c: 1.23176,
          v: 1031420000
        },
        {
          t: 57600,
          o: 1.23179,
          h: 1.23196,
          l: 1.23164,
          c: 1.23164,
          v: 882970000
        },
        {
          t: 57900,
          o: 1.23164,
          h: 1.23179,
          l: 1.23151,
          c: 1.23171,
          v: 849010000
        },
        {
          t: 58200,
          o: 1.23171,
          h: 1.23218,
          l: 1.23167,
          c: 1.23217,
          v: 594020000
        },
        {
          t: 58500,
          o: 1.23216,
          h: 1.2322,
          l: 1.23184,
          c: 1.23196,
          v: 693590000
        },
        {
          t: 58800,
          o: 1.23197,
          h: 1.2323,
          l: 1.23197,
          c: 1.23205,
          v: 566460000
        },
        {
          t: 59100,
          o: 1.23206,
          h: 1.23223,
          l: 1.232,
          c: 1.23202,
          v: 713600000
        },
        {
          t: 59400,
          o: 1.23204,
          h: 1.23231,
          l: 1.23196,
          c: 1.232,
          v: 697280000
        },
        {
          t: 59700,
          o: 1.232,
          h: 1.23233,
          l: 1.23194,
          c: 1.23231,
          v: 794539999
        },
        {
          t: 60000,
          o: 1.23232,
          h: 1.23258,
          l: 1.23228,
          c: 1.23254,
          v: 837880001
        },
        {
          t: 60300,
          o: 1.23254,
          h: 1.23263,
          l: 1.23231,
          c: 1.23252,
          v: 870220000
        },
        {
          t: 60600,
          o: 1.23252,
          h: 1.23261,
          l: 1.23231,
          c: 1.23261,
          v: 934540000
        },
        {
          t: 60900,
          o: 1.23261,
          h: 1.23359,
          l: 1.23258,
          c: 1.23343,
          v: 1265350000
        },
        {
          t: 61200,
          o: 1.23343,
          h: 1.23354,
          l: 1.23308,
          c: 1.23315,
          v: 825300000
        },
        {
          t: 61500,
          o: 1.23316,
          h: 1.23355,
          l: 1.23284,
          c: 1.23315,
          v: 1163160001
        },
        {
          t: 61800,
          o: 1.23316,
          h: 1.23347,
          l: 1.23304,
          c: 1.23331,
          v: 1639310000
        },
        {
          t: 62100,
          o: 1.23332,
          h: 1.23395,
          l: 1.23313,
          c: 1.23318,
          v: 1742510000
        },
        {
          t: 62400,
          o: 1.2332,
          h: 1.23341,
          l: 1.23306,
          c: 1.23308,
          v: 1167890000
        },
        {
          t: 62700,
          o: 1.2331,
          h: 1.23363,
          l: 1.2331,
          c: 1.23347,
          v: 1142940000
        },
        {
          t: 63000,
          o: 1.23346,
          h: 1.23355,
          l: 1.23309,
          c: 1.23311,
          v: 1204250000
        },
        {
          t: 63300,
          o: 1.2331,
          h: 1.23317,
          l: 1.23296,
          c: 1.23308,
          v: 1074360000
        },
        {
          t: 63600,
          o: 1.23308,
          h: 1.23348,
          l: 1.23308,
          c: 1.23328,
          v: 938020000
        },
        {
          t: 63900,
          o: 1.23328,
          h: 1.23353,
          l: 1.2332,
          c: 1.23352,
          v: 823590000
        },
        {
          t: 64200,
          o: 1.23353,
          h: 1.23361,
          l: 1.23329,
          c: 1.23336,
          v: 621130000
        },
        {
          t: 64500,
          o: 1.23337,
          h: 1.23351,
          l: 1.23329,
          c: 1.23331,
          v: 732440000
        },
        {
          t: 64800,
          o: 1.23331,
          h: 1.23331,
          l: 1.23287,
          c: 1.23295,
          v: 776620000
        },
        {
          t: 65100,
          o: 1.23294,
          h: 1.23306,
          l: 1.23285,
          c: 1.23287,
          v: 724870001
        },
        {
          t: 65400,
          o: 1.23287,
          h: 1.23298,
          l: 1.23281,
          c: 1.23292,
          v: 569830000
        },
        {
          t: 65700,
          o: 1.23294,
          h: 1.23318,
          l: 1.23291,
          c: 1.23303,
          v: 699730000
        },
        {
          t: 66000,
          o: 1.23305,
          h: 1.23311,
          l: 1.23274,
          c: 1.23286,
          v: 699240000
        },
        {
          t: 66300,
          o: 1.23286,
          h: 1.23306,
          l: 1.23269,
          c: 1.23295,
          v: 786060000
        },
        {
          t: 66600,
          o: 1.23294,
          h: 1.23335,
          l: 1.23287,
          c: 1.23333,
          v: 535280000
        },
        {
          t: 66900,
          o: 1.23332,
          h: 1.23341,
          l: 1.23301,
          c: 1.23315,
          v: 660260000
        },
        {
          t: 67200,
          o: 1.23314,
          h: 1.23328,
          l: 1.23311,
          c: 1.23321,
          v: 568720000
        },
        {
          t: 67500,
          o: 1.23321,
          h: 1.2333,
          l: 1.233,
          c: 1.23309,
          v: 868280000
        },
        {
          t: 67800,
          o: 1.23309,
          h: 1.23311,
          l: 1.23293,
          c: 1.23305,
          v: 810000000
        },
        {
          t: 68100,
          o: 1.23305,
          h: 1.23311,
          l: 1.23288,
          c: 1.23293,
          v: 880610000
        },
        {
          t: 68400,
          o: 1.23304,
          h: 1.23309,
          l: 1.2328,
          c: 1.23297,
          v: 725710000
        },
        {
          t: 68700,
          o: 1.23297,
          h: 1.23297,
          l: 1.23276,
          c: 1.23282,
          v: 664640000
        },
        {
          t: 69000,
          o: 1.23282,
          h: 1.23293,
          l: 1.23281,
          c: 1.23289,
          v: 304170000
        },
        {
          t: 69300,
          o: 1.23289,
          h: 1.23314,
          l: 1.23288,
          c: 1.23303,
          v: 396190000
        },
        {
          t: 69600,
          o: 1.23304,
          h: 1.23305,
          l: 1.23287,
          c: 1.23289,
          v: 679840000
        },
        {
          t: 69900,
          o: 1.23289,
          h: 1.23299,
          l: 1.23273,
          c: 1.23273,
          v: 404220000
        },
        {
          t: 70200,
          o: 1.23273,
          h: 1.23285,
          l: 1.23267,
          c: 1.23274,
          v: 478450000
        },
        {
          t: 70500,
          o: 1.23274,
          h: 1.23281,
          l: 1.23262,
          c: 1.23262,
          v: 587490000
        },
        {
          t: 70800,
          o: 1.23262,
          h: 1.23277,
          l: 1.23262,
          c: 1.23273,
          v: 701889999
        },
        {
          t: 71100,
          o: 1.23273,
          h: 1.23282,
          l: 1.23273,
          c: 1.2328,
          v: 385880000
        },
        {
          t: 71400,
          o: 1.23281,
          h: 1.23297,
          l: 1.23276,
          c: 1.23286,
          v: 524020000
        },
        {
          t: 71700,
          o: 1.23287,
          h: 1.23295,
          l: 1.23255,
          c: 1.23255,
          v: 784860000
        },
        {
          t: 72000,
          o: 1.23246,
          h: 1.23269,
          l: 1.23246,
          c: 1.23269,
          v: 535400000
        },
        {
          t: 72300,
          o: 1.23268,
          h: 1.23273,
          l: 1.23263,
          c: 1.23271,
          v: 311340000
        },
        {
          t: 72600,
          o: 1.23271,
          h: 1.23288,
          l: 1.23271,
          c: 1.23284,
          v: 348660000
        },
        {
          t: 72900,
          o: 1.23283,
          h: 1.23312,
          l: 1.23281,
          c: 1.23309,
          v: 785900002
        },
        {
          t: 73200,
          o: 1.23314,
          h: 1.23314,
          l: 1.23293,
          c: 1.23294,
          v: 508510000
        },
        {
          t: 73500,
          o: 1.23294,
          h: 1.23301,
          l: 1.23278,
          c: 1.233,
          v: 358690000
        },
        {
          t: 73800,
          o: 1.233,
          h: 1.233,
          l: 1.23283,
          c: 1.2329,
          v: 312240000
        },
        {
          t: 74100,
          o: 1.2329,
          h: 1.23295,
          l: 1.23289,
          c: 1.2329,
          v: 124330000
        },
        {
          t: 74400,
          o: 1.23289,
          h: 1.23296,
          l: 1.23282,
          c: 1.23282,
          v: 187790000
        },
        {
          t: 74700,
          o: 1.23282,
          h: 1.23282,
          l: 1.2326,
          c: 1.2326,
          v: 287550000
        },
        {
          t: 75000,
          o: 1.2326,
          h: 1.23268,
          l: 1.23256,
          c: 1.2326,
          v: 350890000
        },
        {
          t: 75300,
          o: 1.23261,
          h: 1.23265,
          l: 1.23256,
          c: 1.23264,
          v: 215510000
        },
        {
          t: 75600,
          o: 1.23263,
          h: 1.23275,
          l: 1.23253,
          c: 1.23274,
          v: 36710000
        },
        {
          t: 75900,
          o: 1.23276,
          h: 1.23286,
          l: 1.23251,
          c: 1.23281,
          v: 194820000
        },
        {
          t: 76200,
          o: 1.23281,
          h: 1.23282,
          l: 1.23261,
          c: 1.23266,
          v: 220410000
        },
        {
          t: 76500,
          o: 1.23268,
          h: 1.23269,
          l: 1.23261,
          c: 1.23262,
          v: 108140000
        },
        {
          t: 76800,
          o: 1.23261,
          h: 1.23268,
          l: 1.23261,
          c: 1.23268,
          v: 12850000
        },
        {
          t: 77100,
          o: 1.23268,
          h: 1.23268,
          l: 1.23252,
          c: 1.23252,
          v: 74660000
        },
        {
          t: 77400,
          o: 1.23253,
          h: 1.23258,
          l: 1.23246,
          c: 1.23254,
          v: 126500000
        },
        {
          t: 77700,
          o: 1.23255,
          h: 1.23258,
          l: 1.23253,
          c: 1.23255,
          v: 217960000
        },
        {
          t: 78000,
          o: 1.23254,
          h: 1.23256,
          l: 1.2325,
          c: 1.23253,
          v: 127040000
        },
        {
          t: 78300,
          o: 1.23253,
          h: 1.23259,
          l: 1.23249,
          c: 1.23251,
          v: 341240000
        },
        {
          t: 78600,
          o: 1.23251,
          h: 1.23259,
          l: 1.23251,
          c: 1.23258,
          v: 191930000
        },
        {
          t: 78900,
          o: 1.23258,
          h: 1.23273,
          l: 1.23253,
          c: 1.23263,
          v: 357870000
        },
        {
          t: 79200,
          o: 1.23262,
          h: 1.23276,
          l: 1.23253,
          c: 1.23275,
          v: 318460000
        },
        {
          t: 79500,
          o: 1.23274,
          h: 1.23276,
          l: 1.23258,
          c: 1.2326,
          v: 478800000
        },
        {
          t: 79800,
          o: 1.2326,
          h: 1.23263,
          l: 1.23256,
          c: 1.23258,
          v: 252070000
        },
        {
          t: 80100,
          o: 1.23258,
          h: 1.23269,
          l: 1.23258,
          c: 1.23269,
          v: 166300000
        },
        {
          t: 80400,
          o: 1.23269,
          h: 1.23276,
          l: 1.23264,
          c: 1.23265,
          v: 114850000
        },
        {
          t: 80700,
          o: 1.23266,
          h: 1.23277,
          l: 1.23265,
          c: 1.23271,
          v: 70520000
        },
        {
          t: 81000,
          o: 1.23271,
          h: 1.23282,
          l: 1.2327,
          c: 1.23281,
          v: 85810000
        },
        {
          t: 81300,
          o: 1.23281,
          h: 1.23287,
          l: 1.23276,
          c: 1.23286,
          v: 183500000
        },
        {
          t: 81600,
          o: 1.23285,
          h: 1.23285,
          l: 1.23276,
          c: 1.23279,
          v: 209920000
        },
        {
          t: 81900,
          o: 1.2328,
          h: 1.23281,
          l: 1.23279,
          c: 1.2328,
          v: 134750000
        },
        {
          t: 82200,
          o: 1.23279,
          h: 1.23295,
          l: 1.23275,
          c: 1.23285,
          v: 193320000
        },
        {
          t: 82500,
          o: 1.23285,
          h: 1.2329,
          l: 1.23281,
          c: 1.23286,
          v: 181960000
        },
        {
          t: 82800,
          o: 1.23286,
          h: 1.23292,
          l: 1.23278,
          c: 1.23279,
          v: 355000000
        },
        {
          t: 83100,
          o: 1.23279,
          h: 1.23289,
          l: 1.23271,
          c: 1.23289,
          v: 180370000
        },
        {
          t: 83400,
          o: 1.23291,
          h: 1.23291,
          l: 1.23277,
          c: 1.23284,
          v: 80800000
        },
        {
          t: 83700,
          o: 1.23284,
          h: 1.23291,
          l: 1.23277,
          c: 1.23277,
          v: 195060000
        },
        {
          t: 84000,
          o: 1.23276,
          h: 1.233,
          l: 1.23276,
          c: 1.23295,
          v: 149370000
        },
        {
          t: 84300,
          o: 1.23292,
          h: 1.23297,
          l: 1.23285,
          c: 1.23295,
          v: 217000000
        },
        {
          t: 84600,
          o: 1.23295,
          h: 1.23296,
          l: 1.23285,
          c: 1.23286,
          v: 372370000
        },
        {
          t: 84900,
          o: 1.23285,
          h: 1.23296,
          l: 1.23285,
          c: 1.23296,
          v: 236420000
        },
        {
          t: 85200,
          o: 1.23296,
          h: 1.23296,
          l: 1.2328,
          c: 1.23281,
          v: 230490000
        },
        {
          t: 85500,
          o: 1.23281,
          h: 1.23286,
          l: 1.2328,
          c: 1.23281,
          v: 151430000
        },
        {
          t: 85800,
          o: 1.23281,
          h: 1.23285,
          l: 1.2328,
          c: 1.23284,
          v: 247660000
        },
        {
          t: 86100,
          o: 1.23284,
          h: 1.23296,
          l: 1.23282,
          c: 1.23295,
          v: 342340000
        }
      ]
    },
    {
      d: 1523577600,
      b: [
        {
          t: 0,
          o: 1.23293,
          h: 1.23313,
          l: 1.23276,
          c: 1.23291,
          v: 748490000
        },
        {
          t: 300,
          o: 1.23294,
          h: 1.23323,
          l: 1.2329,
          c: 1.23291,
          v: 734870000
        },
        {
          t: 600,
          o: 1.23291,
          h: 1.23313,
          l: 1.23287,
          c: 1.23307,
          v: 623100000
        },
        {
          t: 900,
          o: 1.23307,
          h: 1.23317,
          l: 1.233,
          c: 1.23316,
          v: 437390000
        },
        {
          t: 1200,
          o: 1.23316,
          h: 1.23328,
          l: 1.23299,
          c: 1.23307,
          v: 558260000
        },
        {
          t: 1500,
          o: 1.23305,
          h: 1.23325,
          l: 1.23305,
          c: 1.23318,
          v: 540910000
        },
        {
          t: 1800,
          o: 1.23318,
          h: 1.23318,
          l: 1.23296,
          c: 1.23296,
          v: 329840000
        },
        {
          t: 2100,
          o: 1.23296,
          h: 1.23307,
          l: 1.23285,
          c: 1.23286,
          v: 479390000
        },
        {
          t: 2400,
          o: 1.23286,
          h: 1.23292,
          l: 1.23271,
          c: 1.23292,
          v: 485610000
        },
        {
          t: 2700,
          o: 1.23291,
          h: 1.23296,
          l: 1.23278,
          c: 1.2328,
          v: 538170000
        },
        {
          t: 3000,
          o: 1.2328,
          h: 1.23298,
          l: 1.2326,
          c: 1.23297,
          v: 724100000
        },
        {
          t: 3300,
          o: 1.23297,
          h: 1.23299,
          l: 1.23279,
          c: 1.23285,
          v: 725070000
        },
        {
          t: 3600,
          o: 1.23288,
          h: 1.23312,
          l: 1.23279,
          c: 1.233,
          v: 591260000
        },
        {
          t: 3900,
          o: 1.233,
          h: 1.23305,
          l: 1.23284,
          c: 1.23294,
          v: 425240000
        },
        {
          t: 4200,
          o: 1.23292,
          h: 1.23292,
          l: 1.23263,
          c: 1.23282,
          v: 658440000
        },
        {
          t: 4500,
          o: 1.23279,
          h: 1.23292,
          l: 1.23268,
          c: 1.23272,
          v: 508000000
        },
        {
          t: 4800,
          o: 1.23272,
          h: 1.23278,
          l: 1.23259,
          c: 1.23265,
          v: 541400000
        },
        {
          t: 5100,
          o: 1.23263,
          h: 1.23275,
          l: 1.23252,
          c: 1.23264,
          v: 484290000
        },
        {
          t: 5400,
          o: 1.23264,
          h: 1.23301,
          l: 1.23264,
          c: 1.23284,
          v: 762970000
        },
        {
          t: 5700,
          o: 1.23284,
          h: 1.2329,
          l: 1.23258,
          c: 1.23262,
          v: 612500000
        },
        {
          t: 6000,
          o: 1.23262,
          h: 1.23264,
          l: 1.23247,
          c: 1.23253,
          v: 429120000
        },
        {
          t: 6300,
          o: 1.23252,
          h: 1.23265,
          l: 1.23231,
          c: 1.23233,
          v: 573630000
        },
        {
          t: 6600,
          o: 1.23234,
          h: 1.23248,
          l: 1.23219,
          c: 1.23248,
          v: 542140000
        },
        {
          t: 6900,
          o: 1.23249,
          h: 1.23254,
          l: 1.23227,
          c: 1.23231,
          v: 519960000
        },
        {
          t: 7200,
          o: 1.23229,
          h: 1.23237,
          l: 1.23213,
          c: 1.23233,
          v: 600170000
        },
        {
          t: 7500,
          o: 1.23233,
          h: 1.23254,
          l: 1.23232,
          c: 1.23242,
          v: 575780000
        },
        {
          t: 7800,
          o: 1.23242,
          h: 1.23265,
          l: 1.23233,
          c: 1.23261,
          v: 744380000
        },
        {
          t: 8100,
          o: 1.23262,
          h: 1.23275,
          l: 1.23261,
          c: 1.23267,
          v: 502020000
        },
        {
          t: 8400,
          o: 1.23266,
          h: 1.23269,
          l: 1.2326,
          c: 1.23261,
          v: 355500000
        },
        {
          t: 8700,
          o: 1.23261,
          h: 1.23267,
          l: 1.23251,
          c: 1.23265,
          v: 696990000
        },
        {
          t: 9000,
          o: 1.23266,
          h: 1.23276,
          l: 1.23264,
          c: 1.23272,
          v: 356660000
        },
        {
          t: 9300,
          o: 1.23271,
          h: 1.23283,
          l: 1.23267,
          c: 1.23282,
          v: 492120000
        },
        {
          t: 9600,
          o: 1.23282,
          h: 1.23295,
          l: 1.23282,
          c: 1.23282,
          v: 499370000
        },
        {
          t: 9900,
          o: 1.23282,
          h: 1.23296,
          l: 1.23271,
          c: 1.23288,
          v: 564490000
        },
        {
          t: 10200,
          o: 1.23288,
          h: 1.23301,
          l: 1.23283,
          c: 1.23296,
          v: 360870000
        },
        {
          t: 10500,
          o: 1.23296,
          h: 1.23304,
          l: 1.23289,
          c: 1.23293,
          v: 438340000
        },
        {
          t: 10800,
          o: 1.23293,
          h: 1.23309,
          l: 1.23289,
          c: 1.23309,
          v: 695950000
        },
        {
          t: 11100,
          o: 1.23308,
          h: 1.23332,
          l: 1.23293,
          c: 1.23293,
          v: 736060000
        },
        {
          t: 11400,
          o: 1.23294,
          h: 1.23302,
          l: 1.23274,
          c: 1.23299,
          v: 750820000
        },
        {
          t: 11700,
          o: 1.23299,
          h: 1.23304,
          l: 1.23294,
          c: 1.23304,
          v: 236640000
        },
        {
          t: 12000,
          o: 1.23303,
          h: 1.23305,
          l: 1.2329,
          c: 1.23298,
          v: 509390000
        },
        {
          t: 12300,
          o: 1.23298,
          h: 1.23298,
          l: 1.2328,
          c: 1.23284,
          v: 271200000
        },
        {
          t: 12600,
          o: 1.23284,
          h: 1.23289,
          l: 1.2328,
          c: 1.23284,
          v: 296420000
        },
        {
          t: 12900,
          o: 1.23285,
          h: 1.23299,
          l: 1.23284,
          c: 1.23297,
          v: 153600000
        },
        {
          t: 13200,
          o: 1.23295,
          h: 1.23296,
          l: 1.23284,
          c: 1.23288,
          v: 301420000
        },
        {
          t: 13500,
          o: 1.23288,
          h: 1.23302,
          l: 1.23288,
          c: 1.23301,
          v: 255200000
        },
        {
          t: 13800,
          o: 1.23302,
          h: 1.23308,
          l: 1.233,
          c: 1.23301,
          v: 200760000
        },
        {
          t: 14100,
          o: 1.23302,
          h: 1.23302,
          l: 1.23295,
          c: 1.23298,
          v: 223270000
        },
        {
          t: 14400,
          o: 1.23298,
          h: 1.23307,
          l: 1.23298,
          c: 1.23301,
          v: 177000000
        },
        {
          t: 14700,
          o: 1.233,
          h: 1.23302,
          l: 1.2329,
          c: 1.23295,
          v: 172310000
        },
        {
          t: 15000,
          o: 1.23294,
          h: 1.23295,
          l: 1.23283,
          c: 1.23283,
          v: 288990000
        },
        {
          t: 15300,
          o: 1.23283,
          h: 1.233,
          l: 1.23281,
          c: 1.23295,
          v: 244260000
        },
        {
          t: 15600,
          o: 1.23294,
          h: 1.23295,
          l: 1.23278,
          c: 1.2328,
          v: 311090000
        },
        {
          t: 15900,
          o: 1.2328,
          h: 1.23281,
          l: 1.23278,
          c: 1.23278,
          v: 129800000
        },
        {
          t: 16200,
          o: 1.23279,
          h: 1.23288,
          l: 1.23278,
          c: 1.23283,
          v: 232350000
        },
        {
          t: 16500,
          o: 1.23278,
          h: 1.23285,
          l: 1.23277,
          c: 1.23284,
          v: 181910000
        },
        {
          t: 16800,
          o: 1.23284,
          h: 1.23285,
          l: 1.23283,
          c: 1.23283,
          v: 329170000
        },
        {
          t: 17100,
          o: 1.23283,
          h: 1.23284,
          l: 1.23278,
          c: 1.23284,
          v: 70700000
        },
        {
          t: 17400,
          o: 1.23284,
          h: 1.23291,
          l: 1.23283,
          c: 1.23288,
          v: 297710000
        },
        {
          t: 17700,
          o: 1.23288,
          h: 1.23292,
          l: 1.23276,
          c: 1.23288,
          v: 315880000
        },
        {
          t: 18000,
          o: 1.23288,
          h: 1.23288,
          l: 1.23276,
          c: 1.23282,
          v: 346220000
        },
        {
          t: 18300,
          o: 1.23283,
          h: 1.23283,
          l: 1.23276,
          c: 1.2328,
          v: 290500000
        },
        {
          t: 18600,
          o: 1.2328,
          h: 1.23291,
          l: 1.23272,
          c: 1.23289,
          v: 339840000
        },
        {
          t: 18900,
          o: 1.23289,
          h: 1.23299,
          l: 1.23278,
          c: 1.23285,
          v: 291480000
        },
        {
          t: 19200,
          o: 1.23285,
          h: 1.23285,
          l: 1.23267,
          c: 1.23272,
          v: 416080000
        },
        {
          t: 19500,
          o: 1.23272,
          h: 1.23273,
          l: 1.23256,
          c: 1.23261,
          v: 296300000
        },
        {
          t: 19800,
          o: 1.23261,
          h: 1.2328,
          l: 1.23256,
          c: 1.23272,
          v: 303190000
        },
        {
          t: 20100,
          o: 1.23272,
          h: 1.23284,
          l: 1.23266,
          c: 1.23283,
          v: 277760000
        },
        {
          t: 20400,
          o: 1.23282,
          h: 1.23307,
          l: 1.23282,
          c: 1.23303,
          v: 286590000
        },
        {
          t: 20700,
          o: 1.23302,
          h: 1.23305,
          l: 1.23292,
          c: 1.23294,
          v: 434500000
        },
        {
          t: 21000,
          o: 1.23296,
          h: 1.23303,
          l: 1.23282,
          c: 1.23292,
          v: 508030000
        },
        {
          t: 21300,
          o: 1.23292,
          h: 1.23297,
          l: 1.23272,
          c: 1.23275,
          v: 356800000
        },
        {
          t: 21600,
          o: 1.23275,
          h: 1.23291,
          l: 1.23212,
          c: 1.23213,
          v: 961000000
        },
        {
          t: 21900,
          o: 1.23215,
          h: 1.23227,
          l: 1.23199,
          c: 1.23203,
          v: 1055720000
        },
        {
          t: 22200,
          o: 1.23202,
          h: 1.23215,
          l: 1.23173,
          c: 1.23213,
          v: 1215340000
        },
        {
          t: 22500,
          o: 1.23215,
          h: 1.23254,
          l: 1.23213,
          c: 1.23244,
          v: 1480400000
        },
        {
          t: 22800,
          o: 1.23245,
          h: 1.23289,
          l: 1.23244,
          c: 1.23279,
          v: 1016400000
        },
        {
          t: 23100,
          o: 1.2328,
          h: 1.23296,
          l: 1.23261,
          c: 1.23274,
          v: 1239960000
        },
        {
          t: 23400,
          o: 1.23274,
          h: 1.23308,
          l: 1.23263,
          c: 1.23276,
          v: 1268550000
        },
        {
          t: 23700,
          o: 1.23277,
          h: 1.23305,
          l: 1.23239,
          c: 1.23239,
          v: 1083590000
        },
        {
          t: 24000,
          o: 1.23239,
          h: 1.23275,
          l: 1.23238,
          c: 1.23259,
          v: 1235359999
        },
        {
          t: 24300,
          o: 1.23258,
          h: 1.23259,
          l: 1.23193,
          c: 1.23195,
          v: 1045600000
        },
        {
          t: 24600,
          o: 1.23193,
          h: 1.2322,
          l: 1.23172,
          c: 1.23193,
          v: 1241260000
        },
        {
          t: 24900,
          o: 1.23192,
          h: 1.23243,
          l: 1.23192,
          c: 1.23232,
          v: 1312600000
        },
        {
          t: 25200,
          o: 1.23231,
          h: 1.23256,
          l: 1.23202,
          c: 1.23216,
          v: 1535550000
        },
        {
          t: 25500,
          o: 1.23217,
          h: 1.23258,
          l: 1.23213,
          c: 1.23243,
          v: 1259530001
        },
        {
          t: 25800,
          o: 1.23243,
          h: 1.23252,
          l: 1.23211,
          c: 1.23233,
          v: 1222170000
        },
        {
          t: 26100,
          o: 1.23233,
          h: 1.23289,
          l: 1.23215,
          c: 1.23285,
          v: 1058820000
        },
        {
          t: 26400,
          o: 1.23283,
          h: 1.233,
          l: 1.23262,
          c: 1.23297,
          v: 1415530000
        },
        {
          t: 26700,
          o: 1.23297,
          h: 1.23303,
          l: 1.23254,
          c: 1.23285,
          v: 1052650000
        },
        {
          t: 27000,
          o: 1.23285,
          h: 1.23358,
          l: 1.23285,
          c: 1.23327,
          v: 1343690000
        },
        {
          t: 27300,
          o: 1.23329,
          h: 1.23389,
          l: 1.23312,
          c: 1.23384,
          v: 1612560000
        },
        {
          t: 27600,
          o: 1.23384,
          h: 1.23428,
          l: 1.23366,
          c: 1.23403,
          v: 1768270000
        },
        {
          t: 27900,
          o: 1.23405,
          h: 1.23426,
          l: 1.23385,
          c: 1.23387,
          v: 1302140000
        },
        {
          t: 28200,
          o: 1.23388,
          h: 1.23437,
          l: 1.23368,
          c: 1.23434,
          v: 1338390000
        },
        {
          t: 28500,
          o: 1.23434,
          h: 1.23434,
          l: 1.23382,
          c: 1.2341,
          v: 1146590000
        },
        {
          t: 28800,
          o: 1.23407,
          h: 1.23457,
          l: 1.23407,
          c: 1.23447,
          v: 1490230000
        },
        {
          t: 29100,
          o: 1.23445,
          h: 1.23458,
          l: 1.23433,
          c: 1.2344,
          v: 1287900000
        },
        {
          t: 29400,
          o: 1.23442,
          h: 1.23458,
          l: 1.23422,
          c: 1.2343,
          v: 1271660000
        },
        {
          t: 29700,
          o: 1.2343,
          h: 1.23438,
          l: 1.23378,
          c: 1.23398,
          v: 1201810000
        },
        {
          t: 30000,
          o: 1.23397,
          h: 1.23408,
          l: 1.2339,
          c: 1.23394,
          v: 1042230000
        },
        {
          t: 30300,
          o: 1.23394,
          h: 1.23398,
          l: 1.23353,
          c: 1.23359,
          v: 1340929999
        },
        {
          t: 30600,
          o: 1.23359,
          h: 1.23377,
          l: 1.23341,
          c: 1.23347,
          v: 1170610000
        },
        {
          t: 30900,
          o: 1.23347,
          h: 1.23369,
          l: 1.23331,
          c: 1.23349,
          v: 984420000
        },
        {
          t: 31200,
          o: 1.23348,
          h: 1.23356,
          l: 1.2333,
          c: 1.23334,
          v: 903070000
        },
        {
          t: 31500,
          o: 1.23336,
          h: 1.23343,
          l: 1.23308,
          c: 1.23339,
          v: 1230810000
        },
        {
          t: 31800,
          o: 1.23337,
          h: 1.23356,
          l: 1.23319,
          c: 1.2332,
          v: 990790000
        },
        {
          t: 32100,
          o: 1.2332,
          h: 1.23348,
          l: 1.2329,
          c: 1.23348,
          v: 1157960000
        },
        {
          t: 32400,
          o: 1.23347,
          h: 1.23372,
          l: 1.23331,
          c: 1.23367,
          v: 1196720000
        },
        {
          t: 32700,
          o: 1.23368,
          h: 1.23378,
          l: 1.23352,
          c: 1.23352,
          v: 1037620000
        },
        {
          t: 33000,
          o: 1.23352,
          h: 1.23364,
          l: 1.23327,
          c: 1.2334,
          v: 759220000
        },
        {
          t: 33300,
          o: 1.23342,
          h: 1.23355,
          l: 1.2331,
          c: 1.23311,
          v: 677710000
        },
        {
          t: 33600,
          o: 1.23311,
          h: 1.2333,
          l: 1.23307,
          c: 1.23327,
          v: 855230000
        },
        {
          t: 33900,
          o: 1.23327,
          h: 1.23347,
          l: 1.23295,
          c: 1.23313,
          v: 885320000
        },
        {
          t: 34200,
          o: 1.23313,
          h: 1.23354,
          l: 1.23299,
          c: 1.23348,
          v: 896650000
        },
        {
          t: 34500,
          o: 1.2335,
          h: 1.23398,
          l: 1.23347,
          c: 1.23391,
          v: 1210500000
        },
        {
          t: 34800,
          o: 1.23391,
          h: 1.23394,
          l: 1.23358,
          c: 1.23372,
          v: 1080080000
        },
        {
          t: 35100,
          o: 1.23372,
          h: 1.23383,
          l: 1.23355,
          c: 1.23378,
          v: 903950000
        },
        {
          t: 35400,
          o: 1.23378,
          h: 1.23397,
          l: 1.23362,
          c: 1.23377,
          v: 1045799999
        },
        {
          t: 35700,
          o: 1.23376,
          h: 1.23379,
          l: 1.23335,
          c: 1.23345,
          v: 1253590000
        },
        {
          t: 36000,
          o: 1.23346,
          h: 1.23365,
          l: 1.2333,
          c: 1.23331,
          v: 861980000
        },
        {
          t: 36300,
          o: 1.23331,
          h: 1.23342,
          l: 1.23327,
          c: 1.23332,
          v: 1077280000
        },
        {
          t: 36600,
          o: 1.2333,
          h: 1.23342,
          l: 1.23312,
          c: 1.23325,
          v: 1009230000
        },
        {
          t: 36900,
          o: 1.23326,
          h: 1.23336,
          l: 1.23319,
          c: 1.23323,
          v: 895720000
        },
        {
          t: 37200,
          o: 1.23324,
          h: 1.23359,
          l: 1.23321,
          c: 1.23358,
          v: 582940000
        },
        {
          t: 37500,
          o: 1.23358,
          h: 1.23369,
          l: 1.23353,
          c: 1.23366,
          v: 761540000
        },
        {
          t: 37800,
          o: 1.23366,
          h: 1.2338,
          l: 1.23357,
          c: 1.23363,
          v: 759080000
        },
        {
          t: 38100,
          o: 1.23363,
          h: 1.23387,
          l: 1.23359,
          c: 1.23384,
          v: 762170000
        },
        {
          t: 38400,
          o: 1.23384,
          h: 1.23387,
          l: 1.23358,
          c: 1.23366,
          v: 565640000
        },
        {
          t: 38700,
          o: 1.23363,
          h: 1.23378,
          l: 1.23337,
          c: 1.23369,
          v: 1131450000
        },
        {
          t: 39000,
          o: 1.23371,
          h: 1.23379,
          l: 1.23355,
          c: 1.23361,
          v: 948900000
        },
        {
          t: 39300,
          o: 1.23361,
          h: 1.23367,
          l: 1.23222,
          c: 1.23227,
          v: 1599690000
        },
        {
          t: 39600,
          o: 1.23225,
          h: 1.23228,
          l: 1.23099,
          c: 1.23152,
          v: 1666010000
        },
        {
          t: 39900,
          o: 1.23152,
          h: 1.23172,
          l: 1.23121,
          c: 1.2315,
          v: 1354850000
        },
        {
          t: 40200,
          o: 1.2315,
          h: 1.23167,
          l: 1.23127,
          c: 1.23153,
          v: 1344049998
        },
        {
          t: 40500,
          o: 1.23153,
          h: 1.23167,
          l: 1.23149,
          c: 1.23161,
          v: 1148990000
        },
        {
          t: 40800,
          o: 1.23161,
          h: 1.23172,
          l: 1.23145,
          c: 1.23167,
          v: 963560000
        },
        {
          t: 41100,
          o: 1.23167,
          h: 1.23181,
          l: 1.23162,
          c: 1.23175,
          v: 860490000
        },
        {
          t: 41400,
          o: 1.23174,
          h: 1.23221,
          l: 1.23142,
          c: 1.23205,
          v: 1435680001
        },
        {
          t: 41700,
          o: 1.23206,
          h: 1.23228,
          l: 1.23191,
          c: 1.23191,
          v: 880940000
        },
        {
          t: 42000,
          o: 1.23191,
          h: 1.23196,
          l: 1.23161,
          c: 1.23179,
          v: 1011610000
        },
        {
          t: 42300,
          o: 1.2318,
          h: 1.23199,
          l: 1.23135,
          c: 1.23137,
          v: 833070000
        },
        {
          t: 42600,
          o: 1.23145,
          h: 1.23149,
          l: 1.2307,
          c: 1.23091,
          v: 1192090000
        },
        {
          t: 42900,
          o: 1.23094,
          h: 1.23132,
          l: 1.23094,
          c: 1.23123,
          v: 1321470000
        },
        {
          t: 43200,
          o: 1.23126,
          h: 1.23131,
          l: 1.23093,
          c: 1.23098,
          v: 877789999
        },
        {
          t: 43500,
          o: 1.23098,
          h: 1.23146,
          l: 1.23093,
          c: 1.23145,
          v: 845700000
        },
        {
          t: 43800,
          o: 1.23145,
          h: 1.23177,
          l: 1.23137,
          c: 1.2317,
          v: 838230000
        },
        {
          t: 44100,
          o: 1.23168,
          h: 1.23176,
          l: 1.23152,
          c: 1.23168,
          v: 1012260000
        },
        {
          t: 44400,
          o: 1.23165,
          h: 1.23189,
          l: 1.23157,
          c: 1.23167,
          v: 895410000
        },
        {
          t: 44700,
          o: 1.23167,
          h: 1.2319,
          l: 1.23144,
          c: 1.2319,
          v: 728860001
        },
        {
          t: 45000,
          o: 1.23194,
          h: 1.23246,
          l: 1.23173,
          c: 1.2323,
          v: 1097580000
        },
        {
          t: 45300,
          o: 1.23231,
          h: 1.23245,
          l: 1.23206,
          c: 1.2323,
          v: 611340000
        },
        {
          t: 45600,
          o: 1.23229,
          h: 1.23231,
          l: 1.23199,
          c: 1.23222,
          v: 861890000
        },
        {
          t: 45900,
          o: 1.23222,
          h: 1.23246,
          l: 1.23211,
          c: 1.23246,
          v: 653310000
        },
        {
          t: 46200,
          o: 1.23246,
          h: 1.23247,
          l: 1.23211,
          c: 1.23222,
          v: 681730000
        },
        {
          t: 46500,
          o: 1.23222,
          h: 1.23222,
          l: 1.23151,
          c: 1.2316,
          v: 955670000
        },
        {
          t: 46800,
          o: 1.23161,
          h: 1.23237,
          l: 1.23161,
          c: 1.23215,
          v: 1106800000
        },
        {
          t: 47100,
          o: 1.23217,
          h: 1.23246,
          l: 1.23191,
          c: 1.23193,
          v: 770570000
        },
        {
          t: 47400,
          o: 1.23193,
          h: 1.23209,
          l: 1.23183,
          c: 1.23189,
          v: 459920000
        },
        {
          t: 47700,
          o: 1.23189,
          h: 1.23227,
          l: 1.23159,
          c: 1.23219,
          v: 841760000
        },
        {
          t: 48000,
          o: 1.2322,
          h: 1.2324,
          l: 1.23205,
          c: 1.23227,
          v: 704490000
        },
        {
          t: 48300,
          o: 1.23226,
          h: 1.23238,
          l: 1.23182,
          c: 1.23201,
          v: 1048680000
        },
        {
          t: 48600,
          o: 1.23201,
          h: 1.2324,
          l: 1.23201,
          c: 1.23228,
          v: 1127660000
        },
        {
          t: 48900,
          o: 1.2323,
          h: 1.23242,
          l: 1.23212,
          c: 1.23231,
          v: 1156920000
        },
        {
          t: 49200,
          o: 1.23232,
          h: 1.23311,
          l: 1.23232,
          c: 1.2328,
          v: 1263950000
        },
        {
          t: 49500,
          o: 1.2328,
          h: 1.233,
          l: 1.23258,
          c: 1.23267,
          v: 1285839997
        },
        {
          t: 49800,
          o: 1.23266,
          h: 1.23295,
          l: 1.23251,
          c: 1.23291,
          v: 1104580002
        },
        {
          t: 50100,
          o: 1.23288,
          h: 1.23288,
          l: 1.23243,
          c: 1.23244,
          v: 1361269999
        },
        {
          t: 50400,
          o: 1.23245,
          h: 1.23306,
          l: 1.2324,
          c: 1.23291,
          v: 1521430000
        },
        {
          t: 50700,
          o: 1.23292,
          h: 1.23331,
          l: 1.23255,
          c: 1.23313,
          v: 1206520000
        },
        {
          t: 51000,
          o: 1.23315,
          h: 1.23327,
          l: 1.23253,
          c: 1.23267,
          v: 1316120000
        },
        {
          t: 51300,
          o: 1.23268,
          h: 1.23331,
          l: 1.23267,
          c: 1.23311,
          v: 1239690000
        },
        {
          t: 51600,
          o: 1.23311,
          h: 1.23311,
          l: 1.23273,
          c: 1.23289,
          v: 1312989999
        },
        {
          t: 51900,
          o: 1.23289,
          h: 1.23299,
          l: 1.23255,
          c: 1.23283,
          v: 1291240000
        },
        {
          t: 52200,
          o: 1.23283,
          h: 1.23303,
          l: 1.23259,
          c: 1.23265,
          v: 1300970000
        },
        {
          t: 52500,
          o: 1.23265,
          h: 1.23281,
          l: 1.23251,
          c: 1.23251,
          v: 985020000
        },
        {
          t: 52800,
          o: 1.23252,
          h: 1.2329,
          l: 1.23245,
          c: 1.23277,
          v: 1081710000
        },
        {
          t: 53100,
          o: 1.23278,
          h: 1.2328,
          l: 1.23207,
          c: 1.23232,
          v: 1083819998
        },
        {
          t: 53400,
          o: 1.23231,
          h: 1.23267,
          l: 1.23231,
          c: 1.23262,
          v: 995320001
        },
        {
          t: 53700,
          o: 1.23261,
          h: 1.23297,
          l: 1.23237,
          c: 1.23283,
          v: 1233880000
        },
        {
          t: 54000,
          o: 1.23284,
          h: 1.23319,
          l: 1.2325,
          c: 1.23286,
          v: 1259640000
        },
        {
          t: 54300,
          o: 1.23284,
          h: 1.23299,
          l: 1.23249,
          c: 1.23273,
          v: 1155320000
        },
        {
          t: 54600,
          o: 1.23273,
          h: 1.23289,
          l: 1.23233,
          c: 1.23237,
          v: 1247690000
        },
        {
          t: 54900,
          o: 1.23236,
          h: 1.23259,
          l: 1.2323,
          c: 1.23237,
          v: 936370000
        },
        {
          t: 55200,
          o: 1.23239,
          h: 1.23248,
          l: 1.23226,
          c: 1.23238,
          v: 1137400000
        },
        {
          t: 55500,
          o: 1.23238,
          h: 1.2327,
          l: 1.23234,
          c: 1.23241,
          v: 1090810000
        },
        {
          t: 55800,
          o: 1.23242,
          h: 1.23264,
          l: 1.23227,
          c: 1.23242,
          v: 1136720002
        },
        {
          t: 56100,
          o: 1.23242,
          h: 1.23248,
          l: 1.23212,
          c: 1.23217,
          v: 1082250000
        },
        {
          t: 56400,
          o: 1.23217,
          h: 1.23222,
          l: 1.23205,
          c: 1.23206,
          v: 1038800000
        },
        {
          t: 56700,
          o: 1.23206,
          h: 1.23229,
          l: 1.23193,
          c: 1.23224,
          v: 719460000
        },
        {
          t: 57000,
          o: 1.23223,
          h: 1.23268,
          l: 1.2322,
          c: 1.23268,
          v: 759840000
        },
        {
          t: 57300,
          o: 1.2327,
          h: 1.2327,
          l: 1.23199,
          c: 1.23205,
          v: 917610000
        },
        {
          t: 57600,
          o: 1.23207,
          h: 1.23217,
          l: 1.23191,
          c: 1.23207,
          v: 683920000
        },
        {
          t: 57900,
          o: 1.23207,
          h: 1.23234,
          l: 1.23199,
          c: 1.23216,
          v: 685590000
        },
        {
          t: 58200,
          o: 1.23213,
          h: 1.23248,
          l: 1.23207,
          c: 1.23233,
          v: 816020000
        },
        {
          t: 58500,
          o: 1.23232,
          h: 1.23271,
          l: 1.23229,
          c: 1.23271,
          v: 814840000
        },
        {
          t: 58800,
          o: 1.23272,
          h: 1.23274,
          l: 1.23246,
          c: 1.2326,
          v: 1028470000
        },
        {
          t: 59100,
          o: 1.23259,
          h: 1.23294,
          l: 1.23254,
          c: 1.23267,
          v: 937670000
        },
        {
          t: 59400,
          o: 1.23269,
          h: 1.23312,
          l: 1.23269,
          c: 1.23298,
          v: 1004760000
        },
        {
          t: 59700,
          o: 1.233,
          h: 1.23354,
          l: 1.233,
          c: 1.23352,
          v: 1341130000
        },
        {
          t: 60000,
          o: 1.23352,
          h: 1.23366,
          l: 1.23343,
          c: 1.23351,
          v: 1308450001
        },
        {
          t: 60300,
          o: 1.23351,
          h: 1.23361,
          l: 1.23341,
          c: 1.23352,
          v: 1089710000
        },
        {
          t: 60600,
          o: 1.23353,
          h: 1.23353,
          l: 1.23326,
          c: 1.2334,
          v: 1313689999
        },
        {
          t: 60900,
          o: 1.2334,
          h: 1.23439,
          l: 1.2334,
          c: 1.23417,
          v: 1286280000
        },
        {
          t: 61200,
          o: 1.23421,
          h: 1.23425,
          l: 1.23365,
          c: 1.23373,
          v: 949550000
        },
        {
          t: 61500,
          o: 1.23372,
          h: 1.23377,
          l: 1.23356,
          c: 1.23366,
          v: 667050000
        },
        {
          t: 61800,
          o: 1.23365,
          h: 1.23377,
          l: 1.2335,
          c: 1.2337,
          v: 778000000
        },
        {
          t: 62100,
          o: 1.2337,
          h: 1.2337,
          l: 1.23318,
          c: 1.23335,
          v: 997630000
        },
        {
          t: 62400,
          o: 1.23334,
          h: 1.2335,
          l: 1.23306,
          c: 1.2334,
          v: 873940000
        },
        {
          t: 62700,
          o: 1.2334,
          h: 1.23375,
          l: 1.2334,
          c: 1.23358,
          v: 859950000
        },
        {
          t: 63000,
          o: 1.2336,
          h: 1.23369,
          l: 1.2335,
          c: 1.23368,
          v: 857550000
        },
        {
          t: 63300,
          o: 1.23366,
          h: 1.23388,
          l: 1.23365,
          c: 1.23368,
          v: 956100000
        },
        {
          t: 63600,
          o: 1.23368,
          h: 1.23377,
          l: 1.23335,
          c: 1.23356,
          v: 973380000
        },
        {
          t: 63900,
          o: 1.23357,
          h: 1.23368,
          l: 1.23341,
          c: 1.2335,
          v: 945650000
        },
        {
          t: 64200,
          o: 1.2335,
          h: 1.23371,
          l: 1.2335,
          c: 1.2335,
          v: 714700000
        },
        {
          t: 64500,
          o: 1.23351,
          h: 1.23359,
          l: 1.23339,
          c: 1.23359,
          v: 778880000
        },
        {
          t: 64800,
          o: 1.23359,
          h: 1.23362,
          l: 1.23332,
          c: 1.23335,
          v: 657790000
        },
        {
          t: 65100,
          o: 1.23338,
          h: 1.23339,
          l: 1.23329,
          c: 1.23334,
          v: 413550000
        },
        {
          t: 65400,
          o: 1.23334,
          h: 1.23357,
          l: 1.23331,
          c: 1.23351,
          v: 455120000
        },
        {
          t: 65700,
          o: 1.23352,
          h: 1.23357,
          l: 1.23334,
          c: 1.2335,
          v: 903480000
        },
        {
          t: 66000,
          o: 1.23349,
          h: 1.23361,
          l: 1.23326,
          c: 1.23328,
          v: 681260000
        },
        {
          t: 66300,
          o: 1.23328,
          h: 1.23338,
          l: 1.23312,
          c: 1.23327,
          v: 356370000
        },
        {
          t: 66600,
          o: 1.23326,
          h: 1.23339,
          l: 1.23323,
          c: 1.23334,
          v: 499720000
        },
        {
          t: 66900,
          o: 1.23332,
          h: 1.2334,
          l: 1.23321,
          c: 1.23329,
          v: 391230000
        },
        {
          t: 67200,
          o: 1.23329,
          h: 1.23343,
          l: 1.23325,
          c: 1.23341,
          v: 587060000
        },
        {
          t: 67500,
          o: 1.23341,
          h: 1.23348,
          l: 1.23309,
          c: 1.23312,
          v: 651850000
        },
        {
          t: 67800,
          o: 1.23311,
          h: 1.23327,
          l: 1.2329,
          c: 1.23301,
          v: 922690000
        },
        {
          t: 68100,
          o: 1.23303,
          h: 1.23323,
          l: 1.23301,
          c: 1.23314,
          v: 849739999
        },
        {
          t: 68400,
          o: 1.23314,
          h: 1.23374,
          l: 1.23314,
          c: 1.2335,
          v: 1104620000
        },
        {
          t: 68700,
          o: 1.2335,
          h: 1.2337,
          l: 1.23346,
          c: 1.23361,
          v: 922500000
        },
        {
          t: 69000,
          o: 1.23361,
          h: 1.23374,
          l: 1.23346,
          c: 1.23371,
          v: 791570000
        },
        {
          t: 69300,
          o: 1.2337,
          h: 1.23407,
          l: 1.23368,
          c: 1.23402,
          v: 883290000
        },
        {
          t: 69600,
          o: 1.23402,
          h: 1.23412,
          l: 1.23361,
          c: 1.23364,
          v: 1136900000
        },
        {
          t: 69900,
          o: 1.23365,
          h: 1.23367,
          l: 1.23345,
          c: 1.2336,
          v: 894810000
        },
        {
          t: 70200,
          o: 1.23359,
          h: 1.23366,
          l: 1.23344,
          c: 1.23345,
          v: 939899999
        },
        {
          t: 70500,
          o: 1.23345,
          h: 1.23354,
          l: 1.2334,
          c: 1.23354,
          v: 755510000
        },
        {
          t: 70800,
          o: 1.23354,
          h: 1.23365,
          l: 1.23349,
          c: 1.23361,
          v: 727970000
        },
        {
          t: 71100,
          o: 1.23363,
          h: 1.2337,
          l: 1.23355,
          c: 1.23366,
          v: 581920000
        },
        {
          t: 71400,
          o: 1.23366,
          h: 1.23367,
          l: 1.2334,
          c: 1.23352,
          v: 941410000
        },
        {
          t: 71700,
          o: 1.23353,
          h: 1.23372,
          l: 1.23344,
          c: 1.23372,
          v: 766610000
        },
        {
          t: 72000,
          o: 1.23371,
          h: 1.23393,
          l: 1.23369,
          c: 1.23387,
          v: 577910000
        },
        {
          t: 72300,
          o: 1.23388,
          h: 1.23389,
          l: 1.23376,
          c: 1.23382,
          v: 229020000
        },
        {
          t: 72600,
          o: 1.23382,
          h: 1.23402,
          l: 1.23382,
          c: 1.234,
          v: 395840000
        },
        {
          t: 72900,
          o: 1.234,
          h: 1.23402,
          l: 1.2338,
          c: 1.2338,
          v: 328800000
        },
        {
          t: 73200,
          o: 1.2338,
          h: 1.23386,
          l: 1.23357,
          c: 1.23364,
          v: 382270000
        },
        {
          t: 73500,
          o: 1.23365,
          h: 1.23371,
          l: 1.23363,
          c: 1.23369,
          v: 344960000
        },
        {
          t: 73800,
          o: 1.23369,
          h: 1.2337,
          l: 1.23348,
          c: 1.2336,
          v: 439230000
        },
        {
          t: 74100,
          o: 1.23361,
          h: 1.23361,
          l: 1.23351,
          c: 1.23356,
          v: 534530000
        },
        {
          t: 74400,
          o: 1.23357,
          h: 1.23366,
          l: 1.23353,
          c: 1.23359,
          v: 260960000
        },
        {
          t: 74700,
          o: 1.23358,
          h: 1.23359,
          l: 1.23334,
          c: 1.23339,
          v: 356820000
        },
        {
          t: 75000,
          o: 1.23337,
          h: 1.23338,
          l: 1.23298,
          c: 1.233,
          v: 472620000
        },
        {
          t: 75300,
          o: 1.23298,
          h: 1.23318,
          l: 1.23272,
          c: 1.23282,
          v: 479060000
        }
      ]
    },
    {
      d: 1523750400,
      b: [
        {
          t: 75600,
          o: 1.23278,
          h: 1.23303,
          l: 1.23231,
          c: 1.23248,
          v: 68830000
        },
        {
          t: 75900,
          o: 1.23248,
          h: 1.23272,
          l: 1.23246,
          c: 1.23256,
          v: 193130000
        },
        {
          t: 76200,
          o: 1.23257,
          h: 1.23284,
          l: 1.23253,
          c: 1.23273,
          v: 202960000
        },
        {
          t: 76500,
          o: 1.23271,
          h: 1.23356,
          l: 1.23264,
          c: 1.23301,
          v: 340430000
        },
        {
          t: 76800,
          o: 1.23302,
          h: 1.2331,
          l: 1.23302,
          c: 1.23305,
          v: 42660000
        },
        {
          t: 77100,
          o: 1.23305,
          h: 1.23309,
          l: 1.23304,
          c: 1.23308,
          v: 55850000
        },
        {
          t: 77400,
          o: 1.23305,
          h: 1.23331,
          l: 1.23305,
          c: 1.23331,
          v: 177610000
        },
        {
          t: 77700,
          o: 1.23332,
          h: 1.23366,
          l: 1.2329,
          c: 1.23306,
          v: 208290000
        },
        {
          t: 78000,
          o: 1.23304,
          h: 1.23361,
          l: 1.23304,
          c: 1.23324,
          v: 54320000
        },
        {
          t: 78300,
          o: 1.23324,
          h: 1.23352,
          l: 1.23301,
          c: 1.23336,
          v: 139150000
        },
        {
          t: 78600,
          o: 1.23346,
          h: 1.23346,
          l: 1.23284,
          c: 1.23309,
          v: 112000000
        },
        {
          t: 78900,
          o: 1.23308,
          h: 1.23313,
          l: 1.23283,
          c: 1.23287,
          v: 71340000
        },
        {
          t: 79200,
          o: 1.2329,
          h: 1.23345,
          l: 1.2329,
          c: 1.23309,
          v: 507440000
        },
        {
          t: 79500,
          o: 1.23309,
          h: 1.23326,
          l: 1.23299,
          c: 1.23308,
          v: 720200000
        },
        {
          t: 79800,
          o: 1.2331,
          h: 1.23313,
          l: 1.23262,
          c: 1.23283,
          v: 355660000
        },
        {
          t: 80100,
          o: 1.23284,
          h: 1.23284,
          l: 1.23266,
          c: 1.23269,
          v: 231870000
        },
        {
          t: 80400,
          o: 1.23268,
          h: 1.23275,
          l: 1.23253,
          c: 1.23263,
          v: 249450000
        },
        {
          t: 80700,
          o: 1.23263,
          h: 1.23279,
          l: 1.23263,
          c: 1.23277,
          v: 88480000
        },
        {
          t: 81000,
          o: 1.23274,
          h: 1.23278,
          l: 1.23263,
          c: 1.23266,
          v: 189810000
        },
        {
          t: 81300,
          o: 1.23266,
          h: 1.23285,
          l: 1.23263,
          c: 1.23285,
          v: 139860000
        },
        {
          t: 81600,
          o: 1.23284,
          h: 1.23284,
          l: 1.23266,
          c: 1.23269,
          v: 89380000
        },
        {
          t: 81900,
          o: 1.2327,
          h: 1.23288,
          l: 1.23269,
          c: 1.2328,
          v: 125490000
        },
        {
          t: 82200,
          o: 1.23281,
          h: 1.2329,
          l: 1.2328,
          c: 1.23283,
          v: 38820000
        },
        {
          t: 82500,
          o: 1.23282,
          h: 1.23285,
          l: 1.23282,
          c: 1.23284,
          v: 86590000
        },
        {
          t: 82800,
          o: 1.23283,
          h: 1.23299,
          l: 1.23283,
          c: 1.23285,
          v: 151790000
        },
        {
          t: 83100,
          o: 1.23284,
          h: 1.23298,
          l: 1.23284,
          c: 1.2329,
          v: 152500000
        },
        {
          t: 83400,
          o: 1.23287,
          h: 1.23294,
          l: 1.23281,
          c: 1.23286,
          v: 136580000
        },
        {
          t: 83700,
          o: 1.23286,
          h: 1.23305,
          l: 1.23286,
          c: 1.23304,
          v: 138450000
        },
        {
          t: 84000,
          o: 1.23304,
          h: 1.23312,
          l: 1.23303,
          c: 1.23304,
          v: 202650000
        },
        {
          t: 84300,
          o: 1.23304,
          h: 1.23311,
          l: 1.23301,
          c: 1.23309,
          v: 256860000
        },
        {
          t: 84600,
          o: 1.23308,
          h: 1.23316,
          l: 1.23297,
          c: 1.23311,
          v: 183200000
        },
        {
          t: 84900,
          o: 1.23311,
          h: 1.23336,
          l: 1.2331,
          c: 1.23325,
          v: 213030000
        },
        {
          t: 85200,
          o: 1.23325,
          h: 1.23325,
          l: 1.23309,
          c: 1.23315,
          v: 121780000
        },
        {
          t: 85500,
          o: 1.23314,
          h: 1.23316,
          l: 1.23313,
          c: 1.23314,
          v: 69360000
        },
        {
          t: 85800,
          o: 1.23314,
          h: 1.23321,
          l: 1.23312,
          c: 1.23314,
          v: 219070000
        },
        {
          t: 86100,
          o: 1.23315,
          h: 1.23322,
          l: 1.23312,
          c: 1.23316,
          v: 87370000
        }
      ]
    },
    {
      d: 1523836800,
      b: [
        {
          t: 0,
          o: 1.23316,
          h: 1.23329,
          l: 1.23311,
          c: 1.23321,
          v: 307710000
        },
        {
          t: 300,
          o: 1.23321,
          h: 1.23341,
          l: 1.23316,
          c: 1.23336,
          v: 382610000
        },
        {
          t: 600,
          o: 1.23336,
          h: 1.23353,
          l: 1.2333,
          c: 1.23346,
          v: 618820000
        },
        {
          t: 900,
          o: 1.23346,
          h: 1.23354,
          l: 1.23329,
          c: 1.23334,
          v: 498280000
        },
        {
          t: 1200,
          o: 1.23334,
          h: 1.2335,
          l: 1.23331,
          c: 1.2335,
          v: 309110000
        },
        {
          t: 1500,
          o: 1.23352,
          h: 1.23362,
          l: 1.23348,
          c: 1.23356,
          v: 370060000
        },
        {
          t: 1800,
          o: 1.23356,
          h: 1.23366,
          l: 1.23354,
          c: 1.2336,
          v: 388140000
        },
        {
          t: 2100,
          o: 1.2336,
          h: 1.2337,
          l: 1.23351,
          c: 1.23369,
          v: 341260000
        },
        {
          t: 2400,
          o: 1.2337,
          h: 1.23384,
          l: 1.23364,
          c: 1.23384,
          v: 693580000
        },
        {
          t: 2700,
          o: 1.23384,
          h: 1.23384,
          l: 1.23354,
          c: 1.23355,
          v: 529580000
        },
        {
          t: 3000,
          o: 1.23355,
          h: 1.23376,
          l: 1.2334,
          c: 1.2334,
          v: 813680000
        },
        {
          t: 3300,
          o: 1.23347,
          h: 1.23401,
          l: 1.23343,
          c: 1.2339,
          v: 764680000
        },
        {
          t: 3600,
          o: 1.2339,
          h: 1.23397,
          l: 1.23374,
          c: 1.2339,
          v: 665680000
        },
        {
          t: 3900,
          o: 1.23391,
          h: 1.23401,
          l: 1.23385,
          c: 1.2339,
          v: 366860000
        },
        {
          t: 4200,
          o: 1.2339,
          h: 1.23392,
          l: 1.2338,
          c: 1.23385,
          v: 513210000
        },
        {
          t: 4500,
          o: 1.23385,
          h: 1.23406,
          l: 1.2337,
          c: 1.23376,
          v: 505800000
        },
        {
          t: 4800,
          o: 1.23375,
          h: 1.2339,
          l: 1.23375,
          c: 1.2339,
          v: 336890000
        },
        {
          t: 5100,
          o: 1.2339,
          h: 1.23403,
          l: 1.2339,
          c: 1.23401,
          v: 322470000
        },
        {
          t: 5400,
          o: 1.23401,
          h: 1.23427,
          l: 1.234,
          c: 1.23403,
          v: 476750000
        },
        {
          t: 5700,
          o: 1.23404,
          h: 1.23418,
          l: 1.23377,
          c: 1.23384,
          v: 527820000
        },
        {
          t: 6000,
          o: 1.23384,
          h: 1.23388,
          l: 1.23363,
          c: 1.23369,
          v: 475770000
        },
        {
          t: 6300,
          o: 1.23369,
          h: 1.23383,
          l: 1.23348,
          c: 1.23355,
          v: 629300000
        },
        {
          t: 6600,
          o: 1.23353,
          h: 1.23362,
          l: 1.23342,
          c: 1.23347,
          v: 268890000
        },
        {
          t: 6900,
          o: 1.23347,
          h: 1.23369,
          l: 1.23347,
          c: 1.23363,
          v: 378710000
        },
        {
          t: 7200,
          o: 1.23363,
          h: 1.23369,
          l: 1.23336,
          c: 1.23342,
          v: 374130000
        },
        {
          t: 7500,
          o: 1.23341,
          h: 1.23351,
          l: 1.2334,
          c: 1.23342,
          v: 465240000
        },
        {
          t: 7800,
          o: 1.23342,
          h: 1.23376,
          l: 1.23336,
          c: 1.23372,
          v: 575440000
        },
        {
          t: 8100,
          o: 1.23373,
          h: 1.23374,
          l: 1.23351,
          c: 1.23359,
          v: 429670000
        },
        {
          t: 8400,
          o: 1.23359,
          h: 1.23384,
          l: 1.23358,
          c: 1.23383,
          v: 431170000
        },
        {
          t: 8700,
          o: 1.23383,
          h: 1.23401,
          l: 1.23378,
          c: 1.23392,
          v: 375530000
        },
        {
          t: 9000,
          o: 1.23392,
          h: 1.23401,
          l: 1.23386,
          c: 1.23394,
          v: 403850000
        },
        {
          t: 9300,
          o: 1.23396,
          h: 1.23403,
          l: 1.23388,
          c: 1.23388,
          v: 410660000
        },
        {
          t: 9600,
          o: 1.23387,
          h: 1.23388,
          l: 1.23377,
          c: 1.23382,
          v: 349630000
        },
        {
          t: 9900,
          o: 1.23383,
          h: 1.23394,
          l: 1.23377,
          c: 1.23382,
          v: 419190000
        },
        {
          t: 10200,
          o: 1.23382,
          h: 1.23404,
          l: 1.23376,
          c: 1.23403,
          v: 493620000
        },
        {
          t: 10500,
          o: 1.23403,
          h: 1.23414,
          l: 1.23401,
          c: 1.23401,
          v: 394110000
        },
        {
          t: 10800,
          o: 1.23401,
          h: 1.23408,
          l: 1.2339,
          c: 1.23401,
          v: 383540000
        },
        {
          t: 11100,
          o: 1.23399,
          h: 1.234,
          l: 1.23387,
          c: 1.23388,
          v: 450090000
        },
        {
          t: 11400,
          o: 1.23388,
          h: 1.234,
          l: 1.23382,
          c: 1.234,
          v: 305300000
        },
        {
          t: 11700,
          o: 1.234,
          h: 1.234,
          l: 1.23378,
          c: 1.23383,
          v: 220860000
        },
        {
          t: 12000,
          o: 1.23384,
          h: 1.23399,
          l: 1.2338,
          c: 1.23389,
          v: 380330000
        },
        {
          t: 12300,
          o: 1.23389,
          h: 1.23403,
          l: 1.23389,
          c: 1.2339,
          v: 553780000
        },
        {
          t: 12600,
          o: 1.2339,
          h: 1.23394,
          l: 1.23385,
          c: 1.23385,
          v: 141060000
        },
        {
          t: 12900,
          o: 1.23385,
          h: 1.23391,
          l: 1.23382,
          c: 1.23391,
          v: 139140000
        },
        {
          t: 13200,
          o: 1.2339,
          h: 1.23394,
          l: 1.23384,
          c: 1.23384,
          v: 195110000
        },
        {
          t: 13500,
          o: 1.23387,
          h: 1.23388,
          l: 1.23379,
          c: 1.2338,
          v: 205110000
        },
        {
          t: 13800,
          o: 1.2338,
          h: 1.23381,
          l: 1.23378,
          c: 1.23379,
          v: 362340000
        },
        {
          t: 14100,
          o: 1.23378,
          h: 1.23383,
          l: 1.23378,
          c: 1.23381,
          v: 205510000
        },
        {
          t: 14400,
          o: 1.2338,
          h: 1.2338,
          l: 1.23374,
          c: 1.23378,
          v: 232320000
        },
        {
          t: 14700,
          o: 1.23378,
          h: 1.23378,
          l: 1.23356,
          c: 1.23356,
          v: 170980000
        },
        {
          t: 15000,
          o: 1.23356,
          h: 1.23367,
          l: 1.23348,
          c: 1.23348,
          v: 316140000
        },
        {
          t: 15300,
          o: 1.23349,
          h: 1.23357,
          l: 1.23347,
          c: 1.23353,
          v: 319090000
        },
        {
          t: 15600,
          o: 1.23353,
          h: 1.23356,
          l: 1.23338,
          c: 1.23342,
          v: 420350000
        },
        {
          t: 15900,
          o: 1.23342,
          h: 1.23348,
          l: 1.23334,
          c: 1.23347,
          v: 492280001
        },
        {
          t: 16200,
          o: 1.23347,
          h: 1.23347,
          l: 1.23331,
          c: 1.23338,
          v: 665170000
        },
        {
          t: 16500,
          o: 1.23338,
          h: 1.23339,
          l: 1.23329,
          c: 1.23339,
          v: 480230000
        },
        {
          t: 16800,
          o: 1.23339,
          h: 1.23344,
          l: 1.23316,
          c: 1.23328,
          v: 498010000
        },
        {
          t: 17100,
          o: 1.23327,
          h: 1.23329,
          l: 1.23317,
          c: 1.23323,
          v: 326220000
        },
        {
          t: 17400,
          o: 1.23322,
          h: 1.23329,
          l: 1.23322,
          c: 1.23327,
          v: 250520000
        },
        {
          t: 17700,
          o: 1.23327,
          h: 1.23328,
          l: 1.23309,
          c: 1.23309,
          v: 394670000
        },
        {
          t: 18000,
          o: 1.23309,
          h: 1.23322,
          l: 1.23293,
          c: 1.23296,
          v: 404450000
        },
        {
          t: 18300,
          o: 1.23297,
          h: 1.23304,
          l: 1.23287,
          c: 1.23297,
          v: 417220000
        },
        {
          t: 18600,
          o: 1.23298,
          h: 1.23312,
          l: 1.23298,
          c: 1.23308,
          v: 327040000
        },
        {
          t: 18900,
          o: 1.23307,
          h: 1.2331,
          l: 1.23303,
          c: 1.23309,
          v: 332860000
        },
        {
          t: 19200,
          o: 1.23309,
          h: 1.23325,
          l: 1.23302,
          c: 1.23313,
          v: 417610000
        },
        {
          t: 19500,
          o: 1.23312,
          h: 1.23319,
          l: 1.23306,
          c: 1.23316,
          v: 502580000
        },
        {
          t: 19800,
          o: 1.23316,
          h: 1.23326,
          l: 1.23305,
          c: 1.23323,
          v: 627050000
        },
        {
          t: 20100,
          o: 1.23322,
          h: 1.23329,
          l: 1.23314,
          c: 1.23328,
          v: 499530000
        },
        {
          t: 20400,
          o: 1.23331,
          h: 1.23334,
          l: 1.23303,
          c: 1.23307,
          v: 259370000
        },
        {
          t: 20700,
          o: 1.2331,
          h: 1.23317,
          l: 1.23302,
          c: 1.23313,
          v: 369210000
        },
        {
          t: 21000,
          o: 1.23312,
          h: 1.23317,
          l: 1.23276,
          c: 1.23278,
          v: 421090000
        },
        {
          t: 21300,
          o: 1.23277,
          h: 1.233,
          l: 1.23276,
          c: 1.23297,
          v: 588800000
        },
        {
          t: 21600,
          o: 1.23298,
          h: 1.23321,
          l: 1.23282,
          c: 1.23321,
          v: 862330000
        },
        {
          t: 21900,
          o: 1.2332,
          h: 1.2337,
          l: 1.2332,
          c: 1.23356,
          v: 996180000
        },
        {
          t: 22200,
          o: 1.23356,
          h: 1.23365,
          l: 1.23338,
          c: 1.23342,
          v: 914240000
        },
        {
          t: 22500,
          o: 1.23342,
          h: 1.23346,
          l: 1.23322,
          c: 1.23335,
          v: 1115270000
        },
        {
          t: 22800,
          o: 1.23335,
          h: 1.23356,
          l: 1.23322,
          c: 1.23336,
          v: 1096080000
        },
        {
          t: 23100,
          o: 1.23336,
          h: 1.23387,
          l: 1.23332,
          c: 1.23383,
          v: 1270390001
        },
        {
          t: 23400,
          o: 1.23383,
          h: 1.23384,
          l: 1.23332,
          c: 1.23357,
          v: 932210000
        },
        {
          t: 23700,
          o: 1.23357,
          h: 1.2337,
          l: 1.23316,
          c: 1.23328,
          v: 888180000
        },
        {
          t: 24000,
          o: 1.23327,
          h: 1.23348,
          l: 1.23318,
          c: 1.23343,
          v: 704900000
        },
        {
          t: 24300,
          o: 1.23343,
          h: 1.23376,
          l: 1.23333,
          c: 1.23374,
          v: 735230000
        },
        {
          t: 24600,
          o: 1.23375,
          h: 1.23384,
          l: 1.23322,
          c: 1.23334,
          v: 989850000
        },
        {
          t: 24900,
          o: 1.23332,
          h: 1.23338,
          l: 1.23242,
          c: 1.23272,
          v: 1608610000
        },
        {
          t: 25200,
          o: 1.23271,
          h: 1.2331,
          l: 1.23261,
          c: 1.23308,
          v: 1200640000
        },
        {
          t: 25500,
          o: 1.23308,
          h: 1.23328,
          l: 1.23269,
          c: 1.2327,
          v: 1438450000
        },
        {
          t: 25800,
          o: 1.2327,
          h: 1.23324,
          l: 1.23269,
          c: 1.23289,
          v: 811890000
        },
        {
          t: 26100,
          o: 1.23289,
          h: 1.23353,
          l: 1.23271,
          c: 1.23347,
          v: 1349770000
        },
        {
          t: 26400,
          o: 1.23348,
          h: 1.23367,
          l: 1.23338,
          c: 1.23341,
          v: 1216650000
        },
        {
          t: 26700,
          o: 1.23342,
          h: 1.23361,
          l: 1.23333,
          c: 1.23357,
          v: 1021060000
        },
        {
          t: 27000,
          o: 1.23357,
          h: 1.23394,
          l: 1.23347,
          c: 1.23393,
          v: 1433020000
        },
        {
          t: 27300,
          o: 1.23393,
          h: 1.2341,
          l: 1.23381,
          c: 1.23385,
          v: 1078780000
        },
        {
          t: 27600,
          o: 1.23384,
          h: 1.23396,
          l: 1.23359,
          c: 1.23385,
          v: 1193300000
        },
        {
          t: 27900,
          o: 1.23385,
          h: 1.23419,
          l: 1.23385,
          c: 1.23419,
          v: 1015610000
        },
        {
          t: 28200,
          o: 1.23419,
          h: 1.23593,
          l: 1.23412,
          c: 1.23566,
          v: 1382129999
        },
        {
          t: 28500,
          o: 1.23569,
          h: 1.23592,
          l: 1.23562,
          c: 1.23566,
          v: 1728199999
        },
        {
          t: 28800,
          o: 1.23568,
          h: 1.23633,
          l: 1.23565,
          c: 1.23613,
          v: 1562300000
        },
        {
          t: 29100,
          o: 1.23613,
          h: 1.2363,
          l: 1.23587,
          c: 1.23593,
          v: 1392710000
        },
        {
          t: 29400,
          o: 1.23594,
          h: 1.23599,
          l: 1.2358,
          c: 1.23597,
          v: 1276950000
        },
        {
          t: 29700,
          o: 1.23595,
          h: 1.23602,
          l: 1.23543,
          c: 1.2355,
          v: 1342290000
        },
        {
          t: 30000,
          o: 1.2355,
          h: 1.23558,
          l: 1.2353,
          c: 1.23541,
          v: 850570000
        },
        {
          t: 30300,
          o: 1.2354,
          h: 1.23625,
          l: 1.23528,
          c: 1.23572,
          v: 1426000000
        },
        {
          t: 30600,
          o: 1.23571,
          h: 1.23624,
          l: 1.23567,
          c: 1.2361,
          v: 1136700000
        },
        {
          t: 30900,
          o: 1.23608,
          h: 1.23657,
          l: 1.23596,
          c: 1.23652,
          v: 1315360000
        },
        {
          t: 31200,
          o: 1.23651,
          h: 1.23673,
          l: 1.23623,
          c: 1.23654,
          v: 1409240000
        },
        {
          t: 31500,
          o: 1.23653,
          h: 1.23685,
          l: 1.23649,
          c: 1.23664,
          v: 1093990001
        },
        {
          t: 31800,
          o: 1.23664,
          h: 1.23676,
          l: 1.23648,
          c: 1.2365,
          v: 1067040000
        },
        {
          t: 32100,
          o: 1.23649,
          h: 1.23653,
          l: 1.23616,
          c: 1.23644,
          v: 1266350001
        },
        {
          t: 32400,
          o: 1.23644,
          h: 1.23644,
          l: 1.23619,
          c: 1.23632,
          v: 1108069999
        },
        {
          t: 32700,
          o: 1.23632,
          h: 1.23645,
          l: 1.23615,
          c: 1.23618,
          v: 860860000
        },
        {
          t: 33000,
          o: 1.23621,
          h: 1.23634,
          l: 1.23614,
          c: 1.23614,
          v: 698020000
        },
        {
          t: 33300,
          o: 1.23615,
          h: 1.23627,
          l: 1.23591,
          c: 1.23594,
          v: 846369999
        },
        {
          t: 33600,
          o: 1.23593,
          h: 1.23594,
          l: 1.23566,
          c: 1.23575,
          v: 850150000
        },
        {
          t: 33900,
          o: 1.23575,
          h: 1.23608,
          l: 1.2357,
          c: 1.23583,
          v: 739410000
        },
        {
          t: 34200,
          o: 1.23583,
          h: 1.23686,
          l: 1.23579,
          c: 1.23683,
          v: 1404870000
        },
        {
          t: 34500,
          o: 1.23685,
          h: 1.23736,
          l: 1.23665,
          c: 1.23671,
          v: 1530050000
        },
        {
          t: 34800,
          o: 1.23669,
          h: 1.23693,
          l: 1.23656,
          c: 1.23656,
          v: 770460000
        },
        {
          t: 35100,
          o: 1.23657,
          h: 1.23716,
          l: 1.23657,
          c: 1.2369,
          v: 1243510000
        },
        {
          t: 35400,
          o: 1.23689,
          h: 1.23694,
          l: 1.23646,
          c: 1.23654,
          v: 990580000
        },
        {
          t: 35700,
          o: 1.23654,
          h: 1.23669,
          l: 1.23623,
          c: 1.2364,
          v: 1086240000
        },
        {
          t: 36000,
          o: 1.23639,
          h: 1.23644,
          l: 1.23626,
          c: 1.23628,
          v: 886610000
        },
        {
          t: 36300,
          o: 1.23629,
          h: 1.23638,
          l: 1.23592,
          c: 1.23603,
          v: 1001500000
        },
        {
          t: 36600,
          o: 1.23604,
          h: 1.23619,
          l: 1.23598,
          c: 1.23618,
          v: 1122780001
        },
        {
          t: 36900,
          o: 1.23619,
          h: 1.23628,
          l: 1.23594,
          c: 1.23607,
          v: 1156749998
        },
        {
          t: 37200,
          o: 1.23609,
          h: 1.23624,
          l: 1.23592,
          c: 1.23601,
          v: 984449999
        },
        {
          t: 37500,
          o: 1.23601,
          h: 1.23616,
          l: 1.23593,
          c: 1.23607,
          v: 1235550001
        },
        {
          t: 37800,
          o: 1.23607,
          h: 1.23613,
          l: 1.23594,
          c: 1.23603,
          v: 885020000
        },
        {
          t: 38100,
          o: 1.23603,
          h: 1.23636,
          l: 1.23593,
          c: 1.23623,
          v: 1086270000
        },
        {
          t: 38400,
          o: 1.23623,
          h: 1.23656,
          l: 1.23623,
          c: 1.23652,
          v: 660530000
        },
        {
          t: 38700,
          o: 1.2365,
          h: 1.23655,
          l: 1.23622,
          c: 1.23634,
          v: 1028440000
        },
        {
          t: 39000,
          o: 1.23634,
          h: 1.23655,
          l: 1.23609,
          c: 1.23613,
          v: 752720000
        },
        {
          t: 39300,
          o: 1.23616,
          h: 1.23654,
          l: 1.23613,
          c: 1.23641,
          v: 990080000
        },
        {
          t: 39600,
          o: 1.23642,
          h: 1.2366,
          l: 1.23627,
          c: 1.23658,
          v: 1021060000
        },
        {
          t: 39900,
          o: 1.23659,
          h: 1.23683,
          l: 1.23656,
          c: 1.23662,
          v: 844010000
        },
        {
          t: 40200,
          o: 1.23662,
          h: 1.23662,
          l: 1.23644,
          c: 1.23648,
          v: 846380000
        },
        {
          t: 40500,
          o: 1.23648,
          h: 1.23648,
          l: 1.23622,
          c: 1.23623,
          v: 560090000
        },
        {
          t: 40800,
          o: 1.23625,
          h: 1.23654,
          l: 1.23623,
          c: 1.2365,
          v: 633700000
        },
        {
          t: 41100,
          o: 1.23651,
          h: 1.2367,
          l: 1.23645,
          c: 1.23664,
          v: 707450000
        },
        {
          t: 41400,
          o: 1.23664,
          h: 1.23705,
          l: 1.2366,
          c: 1.2369,
          v: 822340000
        },
        {
          t: 41700,
          o: 1.23689,
          h: 1.23706,
          l: 1.23683,
          c: 1.23706,
          v: 1117310000
        },
        {
          t: 42000,
          o: 1.23707,
          h: 1.23729,
          l: 1.23704,
          c: 1.23719,
          v: 1042970001
        },
        {
          t: 42300,
          o: 1.23719,
          h: 1.23722,
          l: 1.23702,
          c: 1.23721,
          v: 931370000
        },
        {
          t: 42600,
          o: 1.2372,
          h: 1.23732,
          l: 1.23699,
          c: 1.23713,
          v: 831800000
        },
        {
          t: 42900,
          o: 1.23714,
          h: 1.23729,
          l: 1.23689,
          c: 1.23695,
          v: 1129500000
        },
        {
          t: 43200,
          o: 1.23695,
          h: 1.23712,
          l: 1.23691,
          c: 1.23697,
          v: 712720000
        },
        {
          t: 43500,
          o: 1.23698,
          h: 1.23724,
          l: 1.2369,
          c: 1.23695,
          v: 838100000
        },
        {
          t: 43800,
          o: 1.23694,
          h: 1.23717,
          l: 1.23693,
          c: 1.23698,
          v: 790290000
        },
        {
          t: 44100,
          o: 1.237,
          h: 1.23702,
          l: 1.23609,
          c: 1.23619,
          v: 1257220000
        },
        {
          t: 44400,
          o: 1.23618,
          h: 1.23626,
          l: 1.23602,
          c: 1.23612,
          v: 913970000
        },
        {
          t: 44700,
          o: 1.23612,
          h: 1.2362,
          l: 1.23585,
          c: 1.23607,
          v: 791410000
        },
        {
          t: 45000,
          o: 1.23605,
          h: 1.23712,
          l: 1.236,
          c: 1.23639,
          v: 1588340000
        },
        {
          t: 45300,
          o: 1.2364,
          h: 1.2367,
          l: 1.23633,
          c: 1.23661,
          v: 867980000
        },
        {
          t: 45600,
          o: 1.2366,
          h: 1.23744,
          l: 1.2366,
          c: 1.2372,
          v: 1216780000
        },
        {
          t: 45900,
          o: 1.2372,
          h: 1.23766,
          l: 1.2372,
          c: 1.23728,
          v: 1071070000
        },
        {
          t: 46200,
          o: 1.23728,
          h: 1.23819,
          l: 1.23728,
          c: 1.23805,
          v: 1337920000
        },
        {
          t: 46500,
          o: 1.23807,
          h: 1.23831,
          l: 1.2379,
          c: 1.23812,
          v: 1560880000
        },
        {
          t: 46800,
          o: 1.23812,
          h: 1.23829,
          l: 1.23776,
          c: 1.23809,
          v: 1386530003
        },
        {
          t: 47100,
          o: 1.23809,
          h: 1.23945,
          l: 1.23804,
          c: 1.23885,
          v: 1974650000
        },
        {
          t: 47400,
          o: 1.23886,
          h: 1.2391,
          l: 1.23864,
          c: 1.23868,
          v: 1255650000
        },
        {
          t: 47700,
          o: 1.23867,
          h: 1.23891,
          l: 1.23855,
          c: 1.23884,
          v: 1306410000
        },
        {
          t: 48000,
          o: 1.23886,
          h: 1.23895,
          l: 1.23852,
          c: 1.2386,
          v: 1175239999
        },
        {
          t: 48300,
          o: 1.23863,
          h: 1.23894,
          l: 1.23857,
          c: 1.23884,
          v: 1339110002
        },
        {
          t: 48600,
          o: 1.23885,
          h: 1.23931,
          l: 1.23875,
          c: 1.23895,
          v: 1480250001
        },
        {
          t: 48900,
          o: 1.23895,
          h: 1.239,
          l: 1.23845,
          c: 1.23848,
          v: 1310700000
        },
        {
          t: 49200,
          o: 1.2385,
          h: 1.23852,
          l: 1.23821,
          c: 1.23827,
          v: 1227160000
        },
        {
          t: 49500,
          o: 1.23828,
          h: 1.23879,
          l: 1.23826,
          c: 1.23846,
          v: 1193940000
        },
        {
          t: 49800,
          o: 1.23847,
          h: 1.23895,
          l: 1.23835,
          c: 1.23882,
          v: 1198839999
        },
        {
          t: 50100,
          o: 1.23881,
          h: 1.23891,
          l: 1.23856,
          c: 1.2389,
          v: 1295600000
        },
        {
          t: 50400,
          o: 1.23891,
          h: 1.2392,
          l: 1.23844,
          c: 1.23861,
          v: 1458880000
        },
        {
          t: 50700,
          o: 1.23861,
          h: 1.2388,
          l: 1.23836,
          c: 1.23852,
          v: 1278920000
        },
        {
          t: 51000,
          o: 1.23851,
          h: 1.23879,
          l: 1.23842,
          c: 1.23867,
          v: 1562510000
        },
        {
          t: 51300,
          o: 1.23867,
          h: 1.23885,
          l: 1.23846,
          c: 1.23856,
          v: 1221400000
        },
        {
          t: 51600,
          o: 1.23856,
          h: 1.23863,
          l: 1.23814,
          c: 1.23817,
          v: 1191750000
        },
        {
          t: 51900,
          o: 1.23818,
          h: 1.23839,
          l: 1.23788,
          c: 1.23814,
          v: 1466610002
        },
        {
          t: 52200,
          o: 1.23813,
          h: 1.23818,
          l: 1.23773,
          c: 1.23808,
          v: 1230150000
        },
        {
          t: 52500,
          o: 1.2381,
          h: 1.2382,
          l: 1.23765,
          c: 1.23778,
          v: 973780000
        },
        {
          t: 52800,
          o: 1.23781,
          h: 1.23819,
          l: 1.23756,
          c: 1.23779,
          v: 1145830001
        },
        {
          t: 53100,
          o: 1.23779,
          h: 1.23791,
          l: 1.23741,
          c: 1.23783,
          v: 1348670000
        },
        {
          t: 53400,
          o: 1.23783,
          h: 1.23796,
          l: 1.2374,
          c: 1.23752,
          v: 1258040000
        },
        {
          t: 53700,
          o: 1.2375,
          h: 1.2377,
          l: 1.23664,
          c: 1.23669,
          v: 1921360000
        },
        {
          t: 54000,
          o: 1.2367,
          h: 1.23683,
          l: 1.23624,
          c: 1.23663,
          v: 1879480000
        },
        {
          t: 54300,
          o: 1.23663,
          h: 1.23696,
          l: 1.23663,
          c: 1.23694,
          v: 1236530001
        },
        {
          t: 54600,
          o: 1.23694,
          h: 1.23704,
          l: 1.23691,
          c: 1.23698,
          v: 1082530001
        },
        {
          t: 54900,
          o: 1.237,
          h: 1.23704,
          l: 1.23665,
          c: 1.2368,
          v: 921449999
        },
        {
          t: 55200,
          o: 1.23679,
          h: 1.23698,
          l: 1.2366,
          c: 1.23676,
          v: 1012409999
        },
        {
          t: 55500,
          o: 1.23676,
          h: 1.23702,
          l: 1.23671,
          c: 1.23679,
          v: 1113870000
        },
        {
          t: 55800,
          o: 1.23677,
          h: 1.23704,
          l: 1.23671,
          c: 1.23688,
          v: 1105530001
        },
        {
          t: 56100,
          o: 1.23687,
          h: 1.23694,
          l: 1.23651,
          c: 1.23663,
          v: 1165970000
        },
        {
          t: 56400,
          o: 1.23665,
          h: 1.23693,
          l: 1.23654,
          c: 1.23672,
          v: 1007690000
        },
        {
          t: 56700,
          o: 1.23673,
          h: 1.23692,
          l: 1.23665,
          c: 1.23692,
          v: 750220000
        },
        {
          t: 57000,
          o: 1.23691,
          h: 1.23702,
          l: 1.23659,
          c: 1.23695,
          v: 835960000
        },
        {
          t: 57300,
          o: 1.23695,
          h: 1.23729,
          l: 1.23691,
          c: 1.23728,
          v: 1017430000
        },
        {
          t: 57600,
          o: 1.23727,
          h: 1.23732,
          l: 1.23691,
          c: 1.23721,
          v: 1003450000
        },
        {
          t: 57900,
          o: 1.23721,
          h: 1.23749,
          l: 1.23721,
          c: 1.23746,
          v: 693880000
        },
        {
          t: 58200,
          o: 1.23745,
          h: 1.23747,
          l: 1.23705,
          c: 1.23706,
          v: 940810000
        },
        {
          t: 58500,
          o: 1.23706,
          h: 1.23773,
          l: 1.23695,
          c: 1.23771,
          v: 1185780000
        },
        {
          t: 58800,
          o: 1.23769,
          h: 1.2377,
          l: 1.23734,
          c: 1.2376,
          v: 1253040000
        },
        {
          t: 59100,
          o: 1.2376,
          h: 1.23766,
          l: 1.2374,
          c: 1.23751,
          v: 820870000
        },
        {
          t: 59400,
          o: 1.23753,
          h: 1.23762,
          l: 1.23734,
          c: 1.23755,
          v: 921840000
        },
        {
          t: 59700,
          o: 1.23756,
          h: 1.23781,
          l: 1.23743,
          c: 1.23745,
          v: 1068200000
        },
        {
          t: 60000,
          o: 1.23745,
          h: 1.23768,
          l: 1.23741,
          c: 1.23752,
          v: 709430000
        },
        {
          t: 60300,
          o: 1.23751,
          h: 1.23786,
          l: 1.23751,
          c: 1.23767,
          v: 843290000
        },
        {
          t: 60600,
          o: 1.23766,
          h: 1.23808,
          l: 1.2376,
          c: 1.23808,
          v: 778510000
        },
        {
          t: 60900,
          o: 1.23808,
          h: 1.23852,
          l: 1.23807,
          c: 1.23826,
          v: 1044420000
        },
        {
          t: 61200,
          o: 1.23828,
          h: 1.2383,
          l: 1.2379,
          c: 1.23796,
          v: 1063210000
        },
        {
          t: 61500,
          o: 1.23797,
          h: 1.23803,
          l: 1.23775,
          c: 1.2379,
          v: 1046900000
        },
        {
          t: 61800,
          o: 1.23791,
          h: 1.23809,
          l: 1.23779,
          c: 1.23785,
          v: 919450000
        },
        {
          t: 62100,
          o: 1.23785,
          h: 1.23802,
          l: 1.23777,
          c: 1.2379,
          v: 638380000
        },
        {
          t: 62400,
          o: 1.23794,
          h: 1.23795,
          l: 1.23781,
          c: 1.23785,
          v: 432110000
        },
        {
          t: 62700,
          o: 1.23785,
          h: 1.2379,
          l: 1.2377,
          c: 1.23772,
          v: 771770000
        },
        {
          t: 63000,
          o: 1.23774,
          h: 1.23776,
          l: 1.23761,
          c: 1.2377,
          v: 866640000
        },
        {
          t: 63300,
          o: 1.2377,
          h: 1.23775,
          l: 1.23763,
          c: 1.23771,
          v: 558229999
        },
        {
          t: 63600,
          o: 1.23772,
          h: 1.23785,
          l: 1.23764,
          c: 1.23777,
          v: 646860000
        },
        {
          t: 63900,
          o: 1.23776,
          h: 1.23797,
          l: 1.23765,
          c: 1.2379,
          v: 518900000
        },
        {
          t: 64200,
          o: 1.2379,
          h: 1.23796,
          l: 1.23763,
          c: 1.23765,
          v: 899320000
        },
        {
          t: 64500,
          o: 1.23765,
          h: 1.2378,
          l: 1.23759,
          c: 1.23771,
          v: 1027890000
        },
        {
          t: 64800,
          o: 1.23771,
          h: 1.23781,
          l: 1.23764,
          c: 1.2378,
          v: 592590000
        },
        {
          t: 65100,
          o: 1.2378,
          h: 1.23795,
          l: 1.23774,
          c: 1.23779,
          v: 563180000
        },
        {
          t: 65400,
          o: 1.2378,
          h: 1.23792,
          l: 1.23775,
          c: 1.23784,
          v: 578890000
        },
        {
          t: 65700,
          o: 1.23783,
          h: 1.23799,
          l: 1.23783,
          c: 1.23791,
          v: 516630001
        },
        {
          t: 66000,
          o: 1.23792,
          h: 1.23795,
          l: 1.23779,
          c: 1.23785,
          v: 609410000
        },
        {
          t: 66300,
          o: 1.23785,
          h: 1.23798,
          l: 1.23774,
          c: 1.23792,
          v: 909820000
        },
        {
          t: 66600,
          o: 1.23792,
          h: 1.23793,
          l: 1.2378,
          c: 1.23789,
          v: 610500000
        },
        {
          t: 66900,
          o: 1.2379,
          h: 1.23802,
          l: 1.23789,
          c: 1.23797,
          v: 741060000
        },
        {
          t: 67200,
          o: 1.23797,
          h: 1.23807,
          l: 1.23795,
          c: 1.238,
          v: 595970000
        },
        {
          t: 67500,
          o: 1.23801,
          h: 1.23802,
          l: 1.23785,
          c: 1.238,
          v: 596140000
        },
        {
          t: 67800,
          o: 1.238,
          h: 1.23821,
          l: 1.23798,
          c: 1.23815,
          v: 813269999
        },
        {
          t: 68100,
          o: 1.23816,
          h: 1.23826,
          l: 1.23805,
          c: 1.2381,
          v: 775970000
        },
        {
          t: 68400,
          o: 1.23809,
          h: 1.2382,
          l: 1.23804,
          c: 1.23805,
          v: 967399998
        },
        {
          t: 68700,
          o: 1.23805,
          h: 1.23806,
          l: 1.23778,
          c: 1.23784,
          v: 1039700000
        },
        {
          t: 69000,
          o: 1.23784,
          h: 1.23787,
          l: 1.23769,
          c: 1.23774,
          v: 510130000
        },
        {
          t: 69300,
          o: 1.23775,
          h: 1.23784,
          l: 1.23773,
          c: 1.23775,
          v: 586460000
        },
        {
          t: 69600,
          o: 1.23776,
          h: 1.23776,
          l: 1.23764,
          c: 1.23765,
          v: 634640000
        },
        {
          t: 69900,
          o: 1.23766,
          h: 1.23768,
          l: 1.23752,
          c: 1.23753,
          v: 903340000
        },
        {
          t: 70200,
          o: 1.23753,
          h: 1.23756,
          l: 1.23746,
          c: 1.23754,
          v: 746380001
        },
        {
          t: 70500,
          o: 1.23754,
          h: 1.23759,
          l: 1.23744,
          c: 1.23755,
          v: 439109999
        },
        {
          t: 70800,
          o: 1.23754,
          h: 1.23756,
          l: 1.23746,
          c: 1.2375,
          v: 344360000
        },
        {
          t: 71100,
          o: 1.2375,
          h: 1.23772,
          l: 1.2375,
          c: 1.23762,
          v: 587330000
        },
        {
          t: 71400,
          o: 1.23763,
          h: 1.23774,
          l: 1.23754,
          c: 1.23756,
          v: 411750000
        },
        {
          t: 71700,
          o: 1.23755,
          h: 1.23759,
          l: 1.23751,
          c: 1.23757,
          v: 530890000
        },
        {
          t: 72000,
          o: 1.23757,
          h: 1.23783,
          l: 1.23753,
          c: 1.23782,
          v: 372940000
        },
        {
          t: 72300,
          o: 1.23783,
          h: 1.23787,
          l: 1.23781,
          c: 1.23781,
          v: 360890000
        },
        {
          t: 72600,
          o: 1.23781,
          h: 1.23792,
          l: 1.23779,
          c: 1.2379,
          v: 265640000
        },
        {
          t: 72900,
          o: 1.23791,
          h: 1.23803,
          l: 1.23787,
          c: 1.238,
          v: 662710000
        },
        {
          t: 73200,
          o: 1.238,
          h: 1.23829,
          l: 1.23796,
          c: 1.23812,
          v: 742750000
        },
        {
          t: 73500,
          o: 1.23813,
          h: 1.23825,
          l: 1.23795,
          c: 1.23813,
          v: 337800000
        },
        {
          t: 73800,
          o: 1.23813,
          h: 1.23838,
          l: 1.23809,
          c: 1.23825,
          v: 721620000
        },
        {
          t: 74100,
          o: 1.23836,
          h: 1.23838,
          l: 1.23805,
          c: 1.23822,
          v: 634350000
        },
        {
          t: 74400,
          o: 1.23823,
          h: 1.23834,
          l: 1.23823,
          c: 1.2383,
          v: 456890000
        },
        {
          t: 74700,
          o: 1.23832,
          h: 1.23832,
          l: 1.23813,
          c: 1.23815,
          v: 313900000
        },
        {
          t: 75000,
          o: 1.23814,
          h: 1.23815,
          l: 1.23791,
          c: 1.23799,
          v: 372180000
        },
        {
          t: 75300,
          o: 1.23797,
          h: 1.238,
          l: 1.23786,
          c: 1.23791,
          v: 327870000
        },
        {
          t: 75600,
          o: 1.23783,
          h: 1.23797,
          l: 1.23783,
          c: 1.23791,
          v: 37870000
        },
        {
          t: 75900,
          o: 1.23792,
          h: 1.23807,
          l: 1.23792,
          c: 1.238,
          v: 97620000
        },
        {
          t: 76200,
          o: 1.23799,
          h: 1.23809,
          l: 1.23797,
          c: 1.23806,
          v: 209230000
        },
        {
          t: 76500,
          o: 1.23806,
          h: 1.23811,
          l: 1.23798,
          c: 1.238,
          v: 193120000
        },
        {
          t: 76800,
          o: 1.23799,
          h: 1.23804,
          l: 1.23798,
          c: 1.23799,
          v: 126840000
        },
        {
          t: 77100,
          o: 1.238,
          h: 1.23804,
          l: 1.23798,
          c: 1.23802,
          v: 152350000
        },
        {
          t: 77400,
          o: 1.23803,
          h: 1.23803,
          l: 1.23791,
          c: 1.23795,
          v: 361190000
        },
        {
          t: 77700,
          o: 1.23795,
          h: 1.23801,
          l: 1.23795,
          c: 1.23796,
          v: 306480000
        },
        {
          t: 78000,
          o: 1.23797,
          h: 1.23797,
          l: 1.23792,
          c: 1.23795,
          v: 84720000
        },
        {
          t: 78300,
          o: 1.23795,
          h: 1.23799,
          l: 1.23794,
          c: 1.23796,
          v: 109900000
        },
        {
          t: 78600,
          o: 1.23796,
          h: 1.23817,
          l: 1.23795,
          c: 1.23812,
          v: 234850000
        },
        {
          t: 78900,
          o: 1.2381,
          h: 1.2381,
          l: 1.23795,
          c: 1.23805,
          v: 261040000
        },
        {
          t: 79200,
          o: 1.23804,
          h: 1.23808,
          l: 1.23792,
          c: 1.23792,
          v: 390790000
        },
        {
          t: 79500,
          o: 1.23792,
          h: 1.23813,
          l: 1.23787,
          c: 1.2381,
          v: 324480000
        },
        {
          t: 79800,
          o: 1.2381,
          h: 1.2381,
          l: 1.23801,
          c: 1.23803,
          v: 220270000
        },
        {
          t: 80100,
          o: 1.23803,
          h: 1.23821,
          l: 1.23803,
          c: 1.23819,
          v: 370360000
        },
        {
          t: 80400,
          o: 1.2382,
          h: 1.23835,
          l: 1.23818,
          c: 1.23821,
          v: 356500000
        },
        {
          t: 80700,
          o: 1.2382,
          h: 1.2384,
          l: 1.23814,
          c: 1.23823,
          v: 232050000
        },
        {
          t: 81000,
          o: 1.23823,
          h: 1.23825,
          l: 1.23809,
          c: 1.23809,
          v: 242360000
        },
        {
          t: 81300,
          o: 1.2381,
          h: 1.23831,
          l: 1.23806,
          c: 1.2383,
          v: 176380000
        },
        {
          t: 81600,
          o: 1.23828,
          h: 1.23835,
          l: 1.23813,
          c: 1.23831,
          v: 269920000
        },
        {
          t: 81900,
          o: 1.23831,
          h: 1.23834,
          l: 1.23816,
          c: 1.23819,
          v: 203880000
        },
        {
          t: 82200,
          o: 1.23819,
          h: 1.23819,
          l: 1.23804,
          c: 1.23809,
          v: 110440000
        },
        {
          t: 82500,
          o: 1.23809,
          h: 1.23816,
          l: 1.23809,
          c: 1.23814,
          v: 80720000
        },
        {
          t: 82800,
          o: 1.23816,
          h: 1.2382,
          l: 1.23808,
          c: 1.23815,
          v: 211870000
        },
        {
          t: 83100,
          o: 1.23814,
          h: 1.23815,
          l: 1.23805,
          c: 1.23809,
          v: 101590000
        },
        {
          t: 83400,
          o: 1.23809,
          h: 1.23822,
          l: 1.23799,
          c: 1.23812,
          v: 206640000
        },
        {
          t: 83700,
          o: 1.23812,
          h: 1.23812,
          l: 1.23792,
          c: 1.23803,
          v: 255490000
        },
        {
          t: 84000,
          o: 1.23804,
          h: 1.23811,
          l: 1.23803,
          c: 1.23804,
          v: 135150000
        },
        {
          t: 84300,
          o: 1.23803,
          h: 1.23808,
          l: 1.238,
          c: 1.23803,
          v: 88440000
        },
        {
          t: 84600,
          o: 1.23803,
          h: 1.23809,
          l: 1.23802,
          c: 1.23805,
          v: 152600000
        },
        {
          t: 84900,
          o: 1.23804,
          h: 1.23832,
          l: 1.238,
          c: 1.23824,
          v: 301810000
        },
        {
          t: 85200,
          o: 1.23824,
          h: 1.2384,
          l: 1.23824,
          c: 1.23831,
          v: 478780000
        },
        {
          t: 85500,
          o: 1.23831,
          h: 1.23838,
          l: 1.23824,
          c: 1.23836,
          v: 425770000
        },
        {
          t: 85800,
          o: 1.23835,
          h: 1.23846,
          l: 1.23824,
          c: 1.2383,
          v: 352710000
        },
        {
          t: 86100,
          o: 1.2383,
          h: 1.23852,
          l: 1.23826,
          c: 1.23837,
          v: 528150000
        }
      ]
    },
    {
      d: 1523923200,
      b: [
        {
          t: 0,
          o: 1.23837,
          h: 1.23856,
          l: 1.2383,
          c: 1.23845,
          v: 591420000
        },
        {
          t: 300,
          o: 1.23845,
          h: 1.23852,
          l: 1.23827,
          c: 1.23836,
          v: 872440000
        },
        {
          t: 600,
          o: 1.23836,
          h: 1.23855,
          l: 1.23836,
          c: 1.23845,
          v: 273980000
        },
        {
          t: 900,
          o: 1.23845,
          h: 1.23847,
          l: 1.23842,
          c: 1.23845,
          v: 352850000
        },
        {
          t: 1200,
          o: 1.23845,
          h: 1.23845,
          l: 1.23835,
          c: 1.2384,
          v: 371830000
        },
        {
          t: 1500,
          o: 1.23839,
          h: 1.23854,
          l: 1.23839,
          c: 1.23854,
          v: 315200000
        },
        {
          t: 1800,
          o: 1.23854,
          h: 1.23854,
          l: 1.23839,
          c: 1.23846,
          v: 393980000
        },
        {
          t: 2100,
          o: 1.23846,
          h: 1.23858,
          l: 1.23844,
          c: 1.23849,
          v: 233890000
        },
        {
          t: 2400,
          o: 1.2385,
          h: 1.23875,
          l: 1.23849,
          c: 1.23866,
          v: 547930000
        },
        {
          t: 2700,
          o: 1.23868,
          h: 1.23874,
          l: 1.23839,
          c: 1.2384,
          v: 434750000
        },
        {
          t: 3000,
          o: 1.2384,
          h: 1.23852,
          l: 1.23835,
          c: 1.23844,
          v: 536780000
        },
        {
          t: 3300,
          o: 1.23845,
          h: 1.23852,
          l: 1.23828,
          c: 1.23834,
          v: 543470000
        },
        {
          t: 3600,
          o: 1.23835,
          h: 1.23871,
          l: 1.23835,
          c: 1.2387,
          v: 562250000
        },
        {
          t: 3900,
          o: 1.23871,
          h: 1.23877,
          l: 1.23849,
          c: 1.23849,
          v: 503990000
        },
        {
          t: 4200,
          o: 1.23849,
          h: 1.23861,
          l: 1.23841,
          c: 1.23844,
          v: 548500000
        },
        {
          t: 4500,
          o: 1.23845,
          h: 1.2386,
          l: 1.2384,
          c: 1.23842,
          v: 518290000
        },
        {
          t: 4800,
          o: 1.23842,
          h: 1.23868,
          l: 1.23841,
          c: 1.23862,
          v: 391640000
        },
        {
          t: 5100,
          o: 1.23862,
          h: 1.23862,
          l: 1.23844,
          c: 1.23853,
          v: 161490000
        },
        {
          t: 5400,
          o: 1.23853,
          h: 1.23863,
          l: 1.23836,
          c: 1.2384,
          v: 302310000
        },
        {
          t: 5700,
          o: 1.23842,
          h: 1.23847,
          l: 1.23832,
          c: 1.23835,
          v: 493290000
        },
        {
          t: 6000,
          o: 1.23835,
          h: 1.23851,
          l: 1.23824,
          c: 1.2383,
          v: 518950000
        },
        {
          t: 6300,
          o: 1.2383,
          h: 1.23832,
          l: 1.2381,
          c: 1.23815,
          v: 594500000
        },
        {
          t: 6600,
          o: 1.23816,
          h: 1.23823,
          l: 1.238,
          c: 1.23811,
          v: 662580000
        },
        {
          t: 6900,
          o: 1.2381,
          h: 1.2382,
          l: 1.23804,
          c: 1.23804,
          v: 412860000
        },
        {
          t: 7200,
          o: 1.23807,
          h: 1.23809,
          l: 1.23788,
          c: 1.23791,
          v: 425090000
        },
        {
          t: 7500,
          o: 1.23791,
          h: 1.23812,
          l: 1.23774,
          c: 1.2378,
          v: 572670000
        },
        {
          t: 7800,
          o: 1.23781,
          h: 1.23797,
          l: 1.2378,
          c: 1.23795,
          v: 549220000
        },
        {
          t: 8100,
          o: 1.23795,
          h: 1.23796,
          l: 1.23757,
          c: 1.23765,
          v: 775650001
        },
        {
          t: 8400,
          o: 1.23765,
          h: 1.2381,
          l: 1.23765,
          c: 1.238,
          v: 486630000
        },
        {
          t: 8700,
          o: 1.238,
          h: 1.23802,
          l: 1.2379,
          c: 1.23799,
          v: 483910000
        },
        {
          t: 9000,
          o: 1.23799,
          h: 1.23809,
          l: 1.2379,
          c: 1.23791,
          v: 304650000
        },
        {
          t: 9300,
          o: 1.2379,
          h: 1.23809,
          l: 1.23788,
          c: 1.23807,
          v: 366190000
        },
        {
          t: 9600,
          o: 1.23809,
          h: 1.2381,
          l: 1.2379,
          c: 1.23796,
          v: 341150000
        },
        {
          t: 9900,
          o: 1.23796,
          h: 1.23797,
          l: 1.23791,
          c: 1.23796,
          v: 339850000
        },
        {
          t: 10200,
          o: 1.23796,
          h: 1.23801,
          l: 1.23785,
          c: 1.23796,
          v: 228110000
        },
        {
          t: 10500,
          o: 1.23797,
          h: 1.23811,
          l: 1.23796,
          c: 1.23811,
          v: 284710000
        },
        {
          t: 10800,
          o: 1.23811,
          h: 1.2384,
          l: 1.23807,
          c: 1.23832,
          v: 625970000
        },
        {
          t: 11100,
          o: 1.23833,
          h: 1.23837,
          l: 1.23807,
          c: 1.23822,
          v: 535569999
        },
        {
          t: 11400,
          o: 1.23824,
          h: 1.23829,
          l: 1.23816,
          c: 1.23827,
          v: 368660000
        },
        {
          t: 11700,
          o: 1.23826,
          h: 1.23826,
          l: 1.23795,
          c: 1.23795,
          v: 356140000
        },
        {
          t: 12000,
          o: 1.23794,
          h: 1.23816,
          l: 1.23792,
          c: 1.23816,
          v: 465780000
        },
        {
          t: 12300,
          o: 1.23816,
          h: 1.23817,
          l: 1.23801,
          c: 1.23806,
          v: 464570000
        },
        {
          t: 12600,
          o: 1.23806,
          h: 1.2381,
          l: 1.23795,
          c: 1.23796,
          v: 292840000
        },
        {
          t: 12900,
          o: 1.23797,
          h: 1.23803,
          l: 1.23794,
          c: 1.23796,
          v: 343340000
        },
        {
          t: 13200,
          o: 1.238,
          h: 1.23807,
          l: 1.23785,
          c: 1.23801,
          v: 583980000
        },
        {
          t: 13500,
          o: 1.23801,
          h: 1.23817,
          l: 1.238,
          c: 1.23814,
          v: 544080000
        },
        {
          t: 13800,
          o: 1.23815,
          h: 1.23816,
          l: 1.23806,
          c: 1.23806,
          v: 275880000
        },
        {
          t: 14100,
          o: 1.23807,
          h: 1.23812,
          l: 1.23802,
          c: 1.23811,
          v: 338340000
        },
        {
          t: 14400,
          o: 1.23812,
          h: 1.23827,
          l: 1.23807,
          c: 1.23812,
          v: 419040000
        },
        {
          t: 14700,
          o: 1.23812,
          h: 1.23844,
          l: 1.23812,
          c: 1.23843,
          v: 330510000
        },
        {
          t: 15000,
          o: 1.23844,
          h: 1.23847,
          l: 1.23838,
          c: 1.23845,
          v: 283290000
        },
        {
          t: 15300,
          o: 1.23847,
          h: 1.23863,
          l: 1.23847,
          c: 1.2386,
          v: 452080000
        },
        {
          t: 15600,
          o: 1.23859,
          h: 1.23859,
          l: 1.23841,
          c: 1.23841,
          v: 244330000
        },
        {
          t: 15900,
          o: 1.2384,
          h: 1.23847,
          l: 1.23831,
          c: 1.23842,
          v: 224600000
        },
        {
          t: 16200,
          o: 1.23841,
          h: 1.23842,
          l: 1.23831,
          c: 1.23836,
          v: 198590000
        },
        {
          t: 16500,
          o: 1.23836,
          h: 1.2384,
          l: 1.23832,
          c: 1.23833,
          v: 230950000
        },
        {
          t: 16800,
          o: 1.23832,
          h: 1.23837,
          l: 1.23824,
          c: 1.23828,
          v: 267330000
        },
        {
          t: 17100,
          o: 1.23828,
          h: 1.2383,
          l: 1.23814,
          c: 1.23817,
          v: 216550000
        },
        {
          t: 17400,
          o: 1.23816,
          h: 1.2382,
          l: 1.23813,
          c: 1.23816,
          v: 285880000
        },
        {
          t: 17700,
          o: 1.23817,
          h: 1.23827,
          l: 1.23815,
          c: 1.23822,
          v: 398289999
        },
        {
          t: 18000,
          o: 1.23822,
          h: 1.23835,
          l: 1.23822,
          c: 1.23828,
          v: 364510000
        },
        {
          t: 18300,
          o: 1.23828,
          h: 1.23853,
          l: 1.23827,
          c: 1.23846,
          v: 510460000
        },
        {
          t: 18600,
          o: 1.23846,
          h: 1.23848,
          l: 1.23836,
          c: 1.23845,
          v: 358400000
        },
        {
          t: 18900,
          o: 1.23845,
          h: 1.23847,
          l: 1.23831,
          c: 1.23836,
          v: 310220000
        },
        {
          t: 19200,
          o: 1.23836,
          h: 1.23854,
          l: 1.23836,
          c: 1.2384,
          v: 412040000
        },
        {
          t: 19500,
          o: 1.23839,
          h: 1.23852,
          l: 1.23836,
          c: 1.23841,
          v: 459880000
        },
        {
          t: 19800,
          o: 1.2384,
          h: 1.2384,
          l: 1.23821,
          c: 1.23832,
          v: 460030000
        },
        {
          t: 20100,
          o: 1.23833,
          h: 1.23842,
          l: 1.23816,
          c: 1.23823,
          v: 546470000
        },
        {
          t: 20400,
          o: 1.23822,
          h: 1.23854,
          l: 1.2382,
          c: 1.23854,
          v: 502240000
        },
        {
          t: 20700,
          o: 1.23854,
          h: 1.2387,
          l: 1.23851,
          c: 1.23868,
          v: 601890000
        },
        {
          t: 21000,
          o: 1.23867,
          h: 1.23869,
          l: 1.23857,
          c: 1.23866,
          v: 311170000
        },
        {
          t: 21300,
          o: 1.23866,
          h: 1.23889,
          l: 1.23865,
          c: 1.23884,
          v: 333750000
        },
        {
          t: 21600,
          o: 1.23883,
          h: 1.239,
          l: 1.23862,
          c: 1.23873,
          v: 870250000
        },
        {
          t: 21900,
          o: 1.23874,
          h: 1.23879,
          l: 1.23856,
          c: 1.23878,
          v: 739270000
        },
        {
          t: 22200,
          o: 1.23877,
          h: 1.239,
          l: 1.23875,
          c: 1.23898,
          v: 714780000
        },
        {
          t: 22500,
          o: 1.23899,
          h: 1.23978,
          l: 1.23887,
          c: 1.23968,
          v: 1033140000
        },
        {
          t: 22800,
          o: 1.23968,
          h: 1.23968,
          l: 1.23917,
          c: 1.23928,
          v: 1296180000
        },
        {
          t: 23100,
          o: 1.23928,
          h: 1.23955,
          l: 1.23927,
          c: 1.23948,
          v: 1092760000
        },
        {
          t: 23400,
          o: 1.23948,
          h: 1.24002,
          l: 1.23944,
          c: 1.23994,
          v: 1288070000
        },
        {
          t: 23700,
          o: 1.23993,
          h: 1.23999,
          l: 1.23965,
          c: 1.23977,
          v: 1018350000
        },
        {
          t: 24000,
          o: 1.23978,
          h: 1.23986,
          l: 1.23948,
          c: 1.23948,
          v: 732129997
        },
        {
          t: 24300,
          o: 1.2395,
          h: 1.23993,
          l: 1.23948,
          c: 1.23968,
          v: 1012200000
        },
        {
          t: 24600,
          o: 1.23968,
          h: 1.24046,
          l: 1.23968,
          c: 1.24037,
          v: 1209820000
        },
        {
          t: 24900,
          o: 1.24037,
          h: 1.24041,
          l: 1.23996,
          c: 1.24016,
          v: 1146200000
        },
        {
          t: 25200,
          o: 1.24016,
          h: 1.24034,
          l: 1.23993,
          c: 1.24008,
          v: 1450680000
        },
        {
          t: 25500,
          o: 1.24011,
          h: 1.24016,
          l: 1.23951,
          c: 1.23988,
          v: 1452410000
        },
        {
          t: 25800,
          o: 1.23988,
          h: 1.24079,
          l: 1.23979,
          c: 1.24038,
          v: 1431740001
        },
        {
          t: 26100,
          o: 1.24038,
          h: 1.24078,
          l: 1.24018,
          c: 1.24036,
          v: 1267590000
        },
        {
          t: 26400,
          o: 1.2404,
          h: 1.24072,
          l: 1.24032,
          c: 1.24056,
          v: 1159820000
        },
        {
          t: 26700,
          o: 1.24057,
          h: 1.24123,
          l: 1.24053,
          c: 1.24119,
          v: 1267790000
        },
        {
          t: 27000,
          o: 1.2412,
          h: 1.24136,
          l: 1.24077,
          c: 1.24078,
          v: 1196780000
        },
        {
          t: 27300,
          o: 1.24078,
          h: 1.24108,
          l: 1.24078,
          c: 1.24107,
          v: 930850000
        },
        {
          t: 27600,
          o: 1.24107,
          h: 1.24108,
          l: 1.24084,
          c: 1.24101,
          v: 574060000
        },
        {
          t: 27900,
          o: 1.24102,
          h: 1.24114,
          l: 1.24062,
          c: 1.24088,
          v: 901370000
        },
        {
          t: 28200,
          o: 1.24087,
          h: 1.24087,
          l: 1.23988,
          c: 1.24008,
          v: 1511010003
        },
        {
          t: 28500,
          o: 1.24006,
          h: 1.24028,
          l: 1.23982,
          c: 1.2402,
          v: 1190940000
        },
        {
          t: 28800,
          o: 1.24022,
          h: 1.24023,
          l: 1.23952,
          c: 1.23975,
          v: 1103780000
        },
        {
          t: 29100,
          o: 1.23975,
          h: 1.23991,
          l: 1.23955,
          c: 1.23979,
          v: 860900000
        },
        {
          t: 29400,
          o: 1.23981,
          h: 1.23993,
          l: 1.23958,
          c: 1.23964,
          v: 895940000
        },
        {
          t: 29700,
          o: 1.23964,
          h: 1.23992,
          l: 1.23958,
          c: 1.2398,
          v: 839420000
        },
        {
          t: 30000,
          o: 1.2398,
          h: 1.23993,
          l: 1.23951,
          c: 1.23971,
          v: 781960000
        },
        {
          t: 30300,
          o: 1.2397,
          h: 1.23981,
          l: 1.2395,
          c: 1.23956,
          v: 698820000
        },
        {
          t: 30600,
          o: 1.23958,
          h: 1.23999,
          l: 1.23941,
          c: 1.23997,
          v: 1149750000
        },
        {
          t: 30900,
          o: 1.23997,
          h: 1.24029,
          l: 1.23969,
          c: 1.23973,
          v: 1245180000
        },
        {
          t: 31200,
          o: 1.23973,
          h: 1.23974,
          l: 1.2393,
          c: 1.23952,
          v: 1044140000
        },
        {
          t: 31500,
          o: 1.23954,
          h: 1.23964,
          l: 1.23909,
          c: 1.23911,
          v: 1089860000
        },
        {
          t: 31800,
          o: 1.2391,
          h: 1.23911,
          l: 1.23863,
          c: 1.23878,
          v: 1025810000
        },
        {
          t: 32100,
          o: 1.23879,
          h: 1.23892,
          l: 1.23844,
          c: 1.23853,
          v: 1033220000
        },
        {
          t: 32400,
          o: 1.23855,
          h: 1.23855,
          l: 1.238,
          c: 1.23824,
          v: 1334740000
        },
        {
          t: 32700,
          o: 1.23822,
          h: 1.23829,
          l: 1.23802,
          c: 1.23827,
          v: 1157830000
        },
        {
          t: 33000,
          o: 1.23826,
          h: 1.23853,
          l: 1.23826,
          c: 1.23847,
          v: 753300000
        },
        {
          t: 33300,
          o: 1.23846,
          h: 1.23869,
          l: 1.23822,
          c: 1.23822,
          v: 1156430000
        },
        {
          t: 33600,
          o: 1.23822,
          h: 1.23835,
          l: 1.23801,
          c: 1.23812,
          v: 878260000
        },
        {
          t: 33900,
          o: 1.23814,
          h: 1.23829,
          l: 1.23791,
          c: 1.23826,
          v: 981290000
        },
        {
          t: 34200,
          o: 1.23826,
          h: 1.23842,
          l: 1.23779,
          c: 1.23804,
          v: 1373229999
        },
        {
          t: 34500,
          o: 1.23802,
          h: 1.23808,
          l: 1.23788,
          c: 1.23802,
          v: 878530000
        },
        {
          t: 34800,
          o: 1.23804,
          h: 1.23821,
          l: 1.23797,
          c: 1.23803,
          v: 831320000
        },
        {
          t: 35100,
          o: 1.23804,
          h: 1.23808,
          l: 1.23648,
          c: 1.23674,
          v: 1680309999
        },
        {
          t: 35400,
          o: 1.23674,
          h: 1.23707,
          l: 1.23666,
          c: 1.23686,
          v: 1330350000
        },
        {
          t: 35700,
          o: 1.23686,
          h: 1.2373,
          l: 1.23681,
          c: 1.2372,
          v: 865120000
        },
        {
          t: 36000,
          o: 1.23722,
          h: 1.23729,
          l: 1.2371,
          c: 1.23715,
          v: 784280000
        },
        {
          t: 36300,
          o: 1.23715,
          h: 1.23736,
          l: 1.23707,
          c: 1.23731,
          v: 930870000
        },
        {
          t: 36600,
          o: 1.2373,
          h: 1.23732,
          l: 1.23682,
          c: 1.23692,
          v: 980270000
        },
        {
          t: 36900,
          o: 1.23694,
          h: 1.23733,
          l: 1.2368,
          c: 1.23695,
          v: 1062810000
        },
        {
          t: 37200,
          o: 1.23697,
          h: 1.23714,
          l: 1.23688,
          c: 1.23706,
          v: 648100000
        },
        {
          t: 37500,
          o: 1.23707,
          h: 1.23707,
          l: 1.23618,
          c: 1.23673,
          v: 1254290000
        },
        {
          t: 37800,
          o: 1.23673,
          h: 1.23673,
          l: 1.2364,
          c: 1.23663,
          v: 965940000
        },
        {
          t: 38100,
          o: 1.23664,
          h: 1.23666,
          l: 1.2364,
          c: 1.2365,
          v: 959270000
        },
        {
          t: 38400,
          o: 1.23651,
          h: 1.23685,
          l: 1.23651,
          c: 1.23682,
          v: 1175930002
        },
        {
          t: 38700,
          o: 1.23682,
          h: 1.23713,
          l: 1.23676,
          c: 1.23712,
          v: 936420000
        },
        {
          t: 39000,
          o: 1.23711,
          h: 1.23724,
          l: 1.23691,
          c: 1.23715,
          v: 911230000
        },
        {
          t: 39300,
          o: 1.23716,
          h: 1.23737,
          l: 1.23708,
          c: 1.23714,
          v: 1008730000
        },
        {
          t: 39600,
          o: 1.23713,
          h: 1.23746,
          l: 1.23699,
          c: 1.23743,
          v: 1241910000
        },
        {
          t: 39900,
          o: 1.23743,
          h: 1.23767,
          l: 1.23741,
          c: 1.23764,
          v: 981170000
        },
        {
          t: 40200,
          o: 1.23764,
          h: 1.23775,
          l: 1.23747,
          c: 1.2376,
          v: 780410000
        },
        {
          t: 40500,
          o: 1.23761,
          h: 1.23766,
          l: 1.2374,
          c: 1.23755,
          v: 1066030001
        },
        {
          t: 40800,
          o: 1.23755,
          h: 1.2376,
          l: 1.23745,
          c: 1.23756,
          v: 589610000
        },
        {
          t: 41100,
          o: 1.23757,
          h: 1.23768,
          l: 1.23744,
          c: 1.23744,
          v: 829240000
        },
        {
          t: 41400,
          o: 1.23742,
          h: 1.23758,
          l: 1.2374,
          c: 1.23753,
          v: 976979999
        },
        {
          t: 41700,
          o: 1.23753,
          h: 1.23774,
          l: 1.23733,
          c: 1.23764,
          v: 749670000
        },
        {
          t: 42000,
          o: 1.23765,
          h: 1.23779,
          l: 1.2376,
          c: 1.23775,
          v: 915500000
        },
        {
          t: 42300,
          o: 1.23774,
          h: 1.23804,
          l: 1.23767,
          c: 1.23778,
          v: 887250000
        },
        {
          t: 42600,
          o: 1.23778,
          h: 1.23779,
          l: 1.23749,
          c: 1.23749,
          v: 865160000
        },
        {
          t: 42900,
          o: 1.23749,
          h: 1.2375,
          l: 1.23666,
          c: 1.23672,
          v: 1463260000
        },
        {
          t: 43200,
          o: 1.23673,
          h: 1.23676,
          l: 1.23614,
          c: 1.23627,
          v: 1532340000
        },
        {
          t: 43500,
          o: 1.23628,
          h: 1.23672,
          l: 1.23627,
          c: 1.23668,
          v: 927160000
        },
        {
          t: 43800,
          o: 1.23668,
          h: 1.23668,
          l: 1.2354,
          c: 1.23566,
          v: 1434860000
        },
        {
          t: 44100,
          o: 1.23566,
          h: 1.23568,
          l: 1.23528,
          c: 1.23549,
          v: 1398780000
        },
        {
          t: 44400,
          o: 1.23547,
          h: 1.23592,
          l: 1.23541,
          c: 1.23591,
          v: 1087170001
        },
        {
          t: 44700,
          o: 1.2359,
          h: 1.23603,
          l: 1.23574,
          c: 1.236,
          v: 695180000
        },
        {
          t: 45000,
          o: 1.236,
          h: 1.23607,
          l: 1.2356,
          c: 1.2357,
          v: 1175450000
        },
        {
          t: 45300,
          o: 1.23572,
          h: 1.23577,
          l: 1.2353,
          c: 1.23553,
          v: 991530000
        },
        {
          t: 45600,
          o: 1.23553,
          h: 1.23567,
          l: 1.23519,
          c: 1.23546,
          v: 1711560000
        },
        {
          t: 45900,
          o: 1.23547,
          h: 1.23577,
          l: 1.23522,
          c: 1.23522,
          v: 1340350000
        },
        {
          t: 46200,
          o: 1.23521,
          h: 1.23544,
          l: 1.23471,
          c: 1.23494,
          v: 1469330000
        },
        {
          t: 46500,
          o: 1.23494,
          h: 1.23496,
          l: 1.23452,
          c: 1.23477,
          v: 1354480000
        },
        {
          t: 46800,
          o: 1.23476,
          h: 1.23521,
          l: 1.23473,
          c: 1.23518,
          v: 1642650000
        },
        {
          t: 47100,
          o: 1.23518,
          h: 1.23577,
          l: 1.23517,
          c: 1.2356,
          v: 1695080002
        },
        {
          t: 47400,
          o: 1.23559,
          h: 1.2358,
          l: 1.23545,
          c: 1.23555,
          v: 1137820001
        },
        {
          t: 47700,
          o: 1.23553,
          h: 1.23555,
          l: 1.23511,
          c: 1.23535,
          v: 1195670001
        },
        {
          t: 48000,
          o: 1.23535,
          h: 1.23548,
          l: 1.23507,
          c: 1.23527,
          v: 1293789999
        },
        {
          t: 48300,
          o: 1.23528,
          h: 1.23528,
          l: 1.23486,
          c: 1.2349,
          v: 1075200000
        },
        {
          t: 48600,
          o: 1.23491,
          h: 1.23504,
          l: 1.23458,
          c: 1.23503,
          v: 1426840000
        },
        {
          t: 48900,
          o: 1.23504,
          h: 1.23565,
          l: 1.23495,
          c: 1.2356,
          v: 1296230000
        },
        {
          t: 49200,
          o: 1.2356,
          h: 1.23611,
          l: 1.23555,
          c: 1.23589,
          v: 1478730000
        },
        {
          t: 49500,
          o: 1.23587,
          h: 1.23613,
          l: 1.23553,
          c: 1.23564,
          v: 1439560000
        },
        {
          t: 49800,
          o: 1.23561,
          h: 1.23572,
          l: 1.23539,
          c: 1.23539,
          v: 1083869999
        },
        {
          t: 50100,
          o: 1.2354,
          h: 1.23545,
          l: 1.23507,
          c: 1.23514,
          v: 1101849999
        },
        {
          t: 50400,
          o: 1.23513,
          h: 1.2353,
          l: 1.23447,
          c: 1.23447,
          v: 1350400000
        },
        {
          t: 50700,
          o: 1.23448,
          h: 1.23517,
          l: 1.2343,
          c: 1.23513,
          v: 1655850000
        },
        {
          t: 51000,
          o: 1.23513,
          h: 1.23543,
          l: 1.23499,
          c: 1.23514,
          v: 1247230000
        },
        {
          t: 51300,
          o: 1.23516,
          h: 1.23524,
          l: 1.23491,
          c: 1.23496,
          v: 1096480000
        },
        {
          t: 51600,
          o: 1.23496,
          h: 1.23506,
          l: 1.23453,
          c: 1.23462,
          v: 1104720000
        },
        {
          t: 51900,
          o: 1.23462,
          h: 1.23466,
          l: 1.2341,
          c: 1.23412,
          v: 1367070000
        },
        {
          t: 52200,
          o: 1.2341,
          h: 1.23446,
          l: 1.23362,
          c: 1.23414,
          v: 1539560000
        },
        {
          t: 52500,
          o: 1.23415,
          h: 1.23436,
          l: 1.23405,
          c: 1.23423,
          v: 1015480001
        },
        {
          t: 52800,
          o: 1.23424,
          h: 1.23465,
          l: 1.2341,
          c: 1.23439,
          v: 1206500000
        },
        {
          t: 53100,
          o: 1.23438,
          h: 1.23446,
          l: 1.23392,
          c: 1.23434,
          v: 1255390000
        },
        {
          t: 53400,
          o: 1.23434,
          h: 1.23485,
          l: 1.23423,
          c: 1.23427,
          v: 1411469999
        },
        {
          t: 53700,
          o: 1.23428,
          h: 1.23475,
          l: 1.23396,
          c: 1.23459,
          v: 1422540000
        },
        {
          t: 54000,
          o: 1.23462,
          h: 1.2348,
          l: 1.23419,
          c: 1.2345,
          v: 1735190000
        },
        {
          t: 54300,
          o: 1.2345,
          h: 1.23483,
          l: 1.23438,
          c: 1.2344,
          v: 1331780000
        },
        {
          t: 54600,
          o: 1.2344,
          h: 1.23482,
          l: 1.23433,
          c: 1.23453,
          v: 1189070000
        },
        {
          t: 54900,
          o: 1.23451,
          h: 1.23484,
          l: 1.2345,
          c: 1.2348,
          v: 1119120000
        },
        {
          t: 55200,
          o: 1.23478,
          h: 1.23489,
          l: 1.2346,
          c: 1.23475,
          v: 1040650000
        },
        {
          t: 55500,
          o: 1.23475,
          h: 1.23492,
          l: 1.23424,
          c: 1.23439,
          v: 1456000001
        },
        {
          t: 55800,
          o: 1.23436,
          h: 1.23489,
          l: 1.23428,
          c: 1.23483,
          v: 1072620000
        },
        {
          t: 56100,
          o: 1.23483,
          h: 1.23485,
          l: 1.23419,
          c: 1.23424,
          v: 1119830000
        },
        {
          t: 56400,
          o: 1.23423,
          h: 1.23451,
          l: 1.23415,
          c: 1.23441,
          v: 950740000
        },
        {
          t: 56700,
          o: 1.23442,
          h: 1.2345,
          l: 1.23388,
          c: 1.23415,
          v: 962280001
        },
        {
          t: 57000,
          o: 1.23414,
          h: 1.23424,
          l: 1.23364,
          c: 1.23394,
          v: 1072679999
        },
        {
          t: 57300,
          o: 1.23396,
          h: 1.23448,
          l: 1.23396,
          c: 1.23447,
          v: 1255320000
        },
        {
          t: 57600,
          o: 1.23447,
          h: 1.23448,
          l: 1.23379,
          c: 1.23412,
          v: 997890000
        },
        {
          t: 57900,
          o: 1.23412,
          h: 1.23435,
          l: 1.2338,
          c: 1.23408,
          v: 1019650000
        },
        {
          t: 58200,
          o: 1.23408,
          h: 1.23441,
          l: 1.23408,
          c: 1.23441,
          v: 882890000
        },
        {
          t: 58500,
          o: 1.23439,
          h: 1.23446,
          l: 1.23406,
          c: 1.23446,
          v: 760550000
        },
        {
          t: 58800,
          o: 1.23444,
          h: 1.2347,
          l: 1.23423,
          c: 1.23459,
          v: 843560000
        },
        {
          t: 59100,
          o: 1.23459,
          h: 1.23476,
          l: 1.23457,
          c: 1.2346,
          v: 613520000
        },
        {
          t: 59400,
          o: 1.2346,
          h: 1.23475,
          l: 1.23455,
          c: 1.23471,
          v: 733250000
        },
        {
          t: 59700,
          o: 1.23471,
          h: 1.23484,
          l: 1.23449,
          c: 1.23465,
          v: 795450000
        },
        {
          t: 60000,
          o: 1.23466,
          h: 1.23476,
          l: 1.23439,
          c: 1.23472,
          v: 730310000
        },
        {
          t: 60300,
          o: 1.2347,
          h: 1.23496,
          l: 1.23463,
          c: 1.23492,
          v: 829240000
        },
        {
          t: 60600,
          o: 1.23492,
          h: 1.2352,
          l: 1.23474,
          c: 1.23517,
          v: 952920000
        },
        {
          t: 60900,
          o: 1.23517,
          h: 1.2357,
          l: 1.23514,
          c: 1.2355,
          v: 1329700000
        },
        {
          t: 61200,
          o: 1.23551,
          h: 1.23575,
          l: 1.23539,
          c: 1.23562,
          v: 1010870000
        },
        {
          t: 61500,
          o: 1.23563,
          h: 1.23586,
          l: 1.23561,
          c: 1.23574,
          v: 699790000
        },
        {
          t: 61800,
          o: 1.23574,
          h: 1.23603,
          l: 1.23571,
          c: 1.23597,
          v: 733790000
        },
        {
          t: 62100,
          o: 1.23597,
          h: 1.23678,
          l: 1.23595,
          c: 1.23663,
          v: 1454549999
        },
        {
          t: 62400,
          o: 1.23663,
          h: 1.2367,
          l: 1.23623,
          c: 1.23628,
          v: 935620000
        },
        {
          t: 62700,
          o: 1.23629,
          h: 1.23638,
          l: 1.23623,
          c: 1.23634,
          v: 929810000
        },
        {
          t: 63000,
          o: 1.23634,
          h: 1.23639,
          l: 1.23618,
          c: 1.23623,
          v: 731680000
        },
        {
          t: 63300,
          o: 1.23622,
          h: 1.23649,
          l: 1.23619,
          c: 1.23643,
          v: 661220000
        },
        {
          t: 63600,
          o: 1.23644,
          h: 1.23656,
          l: 1.23619,
          c: 1.23619,
          v: 907180000
        },
        {
          t: 63900,
          o: 1.23619,
          h: 1.23628,
          l: 1.23611,
          c: 1.23613,
          v: 776360000
        },
        {
          t: 64200,
          o: 1.23614,
          h: 1.23625,
          l: 1.23597,
          c: 1.23598,
          v: 701900000
        },
        {
          t: 64500,
          o: 1.23599,
          h: 1.23633,
          l: 1.23589,
          c: 1.23631,
          v: 684660000
        },
        {
          t: 64800,
          o: 1.23631,
          h: 1.23647,
          l: 1.23607,
          c: 1.23643,
          v: 834020000
        },
        {
          t: 65100,
          o: 1.23643,
          h: 1.23648,
          l: 1.23634,
          c: 1.23645,
          v: 672479999
        },
        {
          t: 65400,
          o: 1.23644,
          h: 1.23694,
          l: 1.23643,
          c: 1.2368,
          v: 669790000
        },
        {
          t: 65700,
          o: 1.2368,
          h: 1.23698,
          l: 1.23677,
          c: 1.23689,
          v: 792410001
        },
        {
          t: 66000,
          o: 1.23689,
          h: 1.23717,
          l: 1.23683,
          c: 1.23693,
          v: 663400000
        },
        {
          t: 66300,
          o: 1.23694,
          h: 1.23704,
          l: 1.23687,
          c: 1.23687,
          v: 790450000
        },
        {
          t: 66600,
          o: 1.23687,
          h: 1.2371,
          l: 1.23664,
          c: 1.23671,
          v: 749010000
        },
        {
          t: 66900,
          o: 1.23672,
          h: 1.23673,
          l: 1.23653,
          c: 1.23657,
          v: 758560000
        },
        {
          t: 67200,
          o: 1.23657,
          h: 1.23659,
          l: 1.23647,
          c: 1.23656,
          v: 614610000
        },
        {
          t: 67500,
          o: 1.23656,
          h: 1.2367,
          l: 1.23642,
          c: 1.23647,
          v: 680170000
        },
        {
          t: 67800,
          o: 1.23648,
          h: 1.23667,
          l: 1.23637,
          c: 1.23664,
          v: 1060460000
        },
        {
          t: 68100,
          o: 1.23664,
          h: 1.23684,
          l: 1.23653,
          c: 1.23677,
          v: 1139490000
        },
        {
          t: 68400,
          o: 1.23678,
          h: 1.23686,
          l: 1.23651,
          c: 1.23656,
          v: 613500001
        },
        {
          t: 68700,
          o: 1.23656,
          h: 1.23687,
          l: 1.23656,
          c: 1.23666,
          v: 668300000
        },
        {
          t: 69000,
          o: 1.23667,
          h: 1.23682,
          l: 1.23646,
          c: 1.23664,
          v: 878450000
        },
        {
          t: 69300,
          o: 1.23663,
          h: 1.2369,
          l: 1.23662,
          c: 1.23689,
          v: 963890000
        },
        {
          t: 69600,
          o: 1.23689,
          h: 1.23698,
          l: 1.23684,
          c: 1.23693,
          v: 817620000
        },
        {
          t: 69900,
          o: 1.23694,
          h: 1.2371,
          l: 1.23693,
          c: 1.23706,
          v: 791700001
        },
        {
          t: 70200,
          o: 1.23706,
          h: 1.23709,
          l: 1.23693,
          c: 1.23703,
          v: 837330000
        },
        {
          t: 70500,
          o: 1.23703,
          h: 1.23726,
          l: 1.23696,
          c: 1.23703,
          v: 775220000
        },
        {
          t: 70800,
          o: 1.23702,
          h: 1.23718,
          l: 1.23699,
          c: 1.23716,
          v: 660770000
        },
        {
          t: 71100,
          o: 1.23714,
          h: 1.23723,
          l: 1.23696,
          c: 1.23697,
          v: 765880000
        },
        {
          t: 71400,
          o: 1.23697,
          h: 1.23711,
          l: 1.23689,
          c: 1.23702,
          v: 865820000
        },
        {
          t: 71700,
          o: 1.23707,
          h: 1.23724,
          l: 1.23685,
          c: 1.23722,
          v: 832800001
        },
        {
          t: 72000,
          o: 1.23721,
          h: 1.23736,
          l: 1.23702,
          c: 1.23714,
          v: 462710000
        },
        {
          t: 72300,
          o: 1.23714,
          h: 1.23737,
          l: 1.23714,
          c: 1.23727,
          v: 743490000
        },
        {
          t: 72600,
          o: 1.23727,
          h: 1.23739,
          l: 1.23721,
          c: 1.23734,
          v: 714140000
        },
        {
          t: 72900,
          o: 1.23735,
          h: 1.23743,
          l: 1.23729,
          c: 1.2374,
          v: 582490000
        },
        {
          t: 73200,
          o: 1.2374,
          h: 1.23752,
          l: 1.23736,
          c: 1.23746,
          v: 408220000
        },
        {
          t: 73500,
          o: 1.23746,
          h: 1.23752,
          l: 1.23744,
          c: 1.23749,
          v: 400560000
        },
        {
          t: 73800,
          o: 1.2375,
          h: 1.23759,
          l: 1.23745,
          c: 1.23755,
          v: 652780000
        },
        {
          t: 74100,
          o: 1.23756,
          h: 1.23761,
          l: 1.23736,
          c: 1.23736,
          v: 474040000
        },
        {
          t: 74400,
          o: 1.23736,
          h: 1.23749,
          l: 1.23725,
          c: 1.2374,
          v: 371590000
        },
        {
          t: 74700,
          o: 1.2374,
          h: 1.23755,
          l: 1.2373,
          c: 1.23735,
          v: 627480000
        },
        {
          t: 75000,
          o: 1.23735,
          h: 1.23739,
          l: 1.237,
          c: 1.23701,
          v: 431350000
        },
        {
          t: 75300,
          o: 1.23701,
          h: 1.23703,
          l: 1.23687,
          c: 1.23696,
          v: 457140000
        },
        {
          t: 75600,
          o: 1.23693,
          h: 1.23717,
          l: 1.23666,
          c: 1.23713,
          v: 113370000
        },
        {
          t: 75900,
          o: 1.23714,
          h: 1.23719,
          l: 1.2368,
          c: 1.2368,
          v: 322550000
        },
        {
          t: 76200,
          o: 1.2368,
          h: 1.23711,
          l: 1.2368,
          c: 1.23692,
          v: 271150000
        },
        {
          t: 76500,
          o: 1.23693,
          h: 1.23715,
          l: 1.23686,
          c: 1.23689,
          v: 152200000
        },
        {
          t: 76800,
          o: 1.2369,
          h: 1.23707,
          l: 1.23689,
          c: 1.23689,
          v: 187110000
        },
        {
          t: 77100,
          o: 1.23689,
          h: 1.23718,
          l: 1.23689,
          c: 1.23699,
          v: 190600000
        },
        {
          t: 77400,
          o: 1.237,
          h: 1.237,
          l: 1.23682,
          c: 1.23693,
          v: 139260000
        },
        {
          t: 77700,
          o: 1.23694,
          h: 1.23695,
          l: 1.2369,
          c: 1.23693,
          v: 117040000
        },
        {
          t: 78000,
          o: 1.23694,
          h: 1.23694,
          l: 1.23682,
          c: 1.2369,
          v: 399040000
        },
        {
          t: 78300,
          o: 1.2369,
          h: 1.237,
          l: 1.23686,
          c: 1.23695,
          v: 352700000
        },
        {
          t: 78600,
          o: 1.23695,
          h: 1.23724,
          l: 1.23692,
          c: 1.23717,
          v: 610430000
        },
        {
          t: 78900,
          o: 1.23724,
          h: 1.23744,
          l: 1.2371,
          c: 1.23736,
          v: 362820000
        },
        {
          t: 79200,
          o: 1.23735,
          h: 1.23749,
          l: 1.23729,
          c: 1.23742,
          v: 483100000
        },
        {
          t: 79500,
          o: 1.23742,
          h: 1.23749,
          l: 1.23734,
          c: 1.23741,
          v: 419860000
        },
        {
          t: 79800,
          o: 1.23742,
          h: 1.23742,
          l: 1.23729,
          c: 1.23729,
          v: 276840000
        },
        {
          t: 80100,
          o: 1.23729,
          h: 1.2373,
          l: 1.23729,
          c: 1.23729,
          v: 49550000
        },
        {
          t: 80400,
          o: 1.23729,
          h: 1.23734,
          l: 1.23721,
          c: 1.23731,
          v: 111110000
        },
        {
          t: 80700,
          o: 1.23731,
          h: 1.23732,
          l: 1.23709,
          c: 1.2371,
          v: 142760000
        },
        {
          t: 81000,
          o: 1.2371,
          h: 1.23719,
          l: 1.2371,
          c: 1.23716,
          v: 65780000
        },
        {
          t: 81300,
          o: 1.23714,
          h: 1.23715,
          l: 1.23711,
          c: 1.23713,
          v: 65440000
        },
        {
          t: 81600,
          o: 1.23714,
          h: 1.23717,
          l: 1.23705,
          c: 1.23705,
          v: 115820000
        },
        {
          t: 81900,
          o: 1.23704,
          h: 1.23716,
          l: 1.23704,
          c: 1.23715,
          v: 135510000
        },
        {
          t: 82200,
          o: 1.23715,
          h: 1.23718,
          l: 1.23712,
          c: 1.23712,
          v: 163490000
        },
        {
          t: 82500,
          o: 1.23711,
          h: 1.23719,
          l: 1.2371,
          c: 1.23719,
          v: 103530000
        },
        {
          t: 82800,
          o: 1.23719,
          h: 1.23721,
          l: 1.23714,
          c: 1.23714,
          v: 211080000
        },
        {
          t: 83100,
          o: 1.23714,
          h: 1.23716,
          l: 1.23707,
          c: 1.2371,
          v: 266110000
        },
        {
          t: 83400,
          o: 1.2371,
          h: 1.23715,
          l: 1.23704,
          c: 1.23705,
          v: 129900000
        },
        {
          t: 83700,
          o: 1.23705,
          h: 1.23711,
          l: 1.237,
          c: 1.2371,
          v: 92630000
        },
        {
          t: 84000,
          o: 1.23711,
          h: 1.23714,
          l: 1.23701,
          c: 1.23709,
          v: 181350000
        },
        {
          t: 84300,
          o: 1.23709,
          h: 1.23712,
          l: 1.23695,
          c: 1.23705,
          v: 106600000
        },
        {
          t: 84600,
          o: 1.23705,
          h: 1.23719,
          l: 1.23699,
          c: 1.23709,
          v: 287810000
        },
        {
          t: 84900,
          o: 1.23709,
          h: 1.23713,
          l: 1.23703,
          c: 1.23703,
          v: 245870000
        },
        {
          t: 85200,
          o: 1.23701,
          h: 1.23721,
          l: 1.23701,
          c: 1.2372,
          v: 208880000
        },
        {
          t: 85500,
          o: 1.23721,
          h: 1.23731,
          l: 1.23714,
          c: 1.2373,
          v: 155450000
        },
        {
          t: 85800,
          o: 1.2373,
          h: 1.23737,
          l: 1.23724,
          c: 1.23726,
          v: 232690000
        },
        {
          t: 86100,
          o: 1.23725,
          h: 1.23731,
          l: 1.23724,
          c: 1.23724,
          v: 209300000
        }
      ]
    },
    {
      d: 1524009600,
      b: [
        {
          t: 0,
          o: 1.23724,
          h: 1.23729,
          l: 1.23703,
          c: 1.23724,
          v: 434170000
        },
        {
          t: 300,
          o: 1.23724,
          h: 1.23733,
          l: 1.23709,
          c: 1.2372,
          v: 612320000
        },
        {
          t: 600,
          o: 1.23718,
          h: 1.23726,
          l: 1.23707,
          c: 1.2371,
          v: 624590000
        },
        {
          t: 900,
          o: 1.2371,
          h: 1.23724,
          l: 1.237,
          c: 1.23713,
          v: 483030000
        },
        {
          t: 1200,
          o: 1.23714,
          h: 1.23715,
          l: 1.237,
          c: 1.23712,
          v: 535650000
        },
        {
          t: 1500,
          o: 1.23713,
          h: 1.23737,
          l: 1.23705,
          c: 1.23737,
          v: 242090000
        },
        {
          t: 1800,
          o: 1.23738,
          h: 1.23742,
          l: 1.23725,
          c: 1.23725,
          v: 285360000
        },
        {
          t: 2100,
          o: 1.23725,
          h: 1.23732,
          l: 1.23714,
          c: 1.2373,
          v: 415710000
        },
        {
          t: 2400,
          o: 1.23731,
          h: 1.23738,
          l: 1.23721,
          c: 1.23731,
          v: 359660000
        },
        {
          t: 2700,
          o: 1.23731,
          h: 1.23734,
          l: 1.23704,
          c: 1.23705,
          v: 507580000
        },
        {
          t: 3000,
          o: 1.23705,
          h: 1.23725,
          l: 1.237,
          c: 1.23725,
          v: 434350000
        },
        {
          t: 3300,
          o: 1.23725,
          h: 1.23725,
          l: 1.23697,
          c: 1.237,
          v: 644600000
        },
        {
          t: 3600,
          o: 1.237,
          h: 1.23707,
          l: 1.23649,
          c: 1.23666,
          v: 588370000
        },
        {
          t: 3900,
          o: 1.23666,
          h: 1.23667,
          l: 1.23655,
          c: 1.23662,
          v: 505750000
        },
        {
          t: 4200,
          o: 1.23662,
          h: 1.23683,
          l: 1.23661,
          c: 1.23676,
          v: 516099999
        },
        {
          t: 4500,
          o: 1.23677,
          h: 1.23687,
          l: 1.23662,
          c: 1.23686,
          v: 485690000
        },
        {
          t: 4800,
          o: 1.23685,
          h: 1.2373,
          l: 1.23685,
          c: 1.2372,
          v: 660000000
        },
        {
          t: 5100,
          o: 1.23721,
          h: 1.23736,
          l: 1.23691,
          c: 1.23692,
          v: 671680000
        },
        {
          t: 5400,
          o: 1.23691,
          h: 1.23703,
          l: 1.23687,
          c: 1.23703,
          v: 772200000
        },
        {
          t: 5700,
          o: 1.23705,
          h: 1.23708,
          l: 1.23683,
          c: 1.23695,
          v: 490940000
        },
        {
          t: 6000,
          o: 1.23693,
          h: 1.23723,
          l: 1.23691,
          c: 1.23716,
          v: 589920000
        },
        {
          t: 6300,
          o: 1.23716,
          h: 1.23739,
          l: 1.23695,
          c: 1.23729,
          v: 465280000
        },
        {
          t: 6600,
          o: 1.23726,
          h: 1.23739,
          l: 1.23712,
          c: 1.23721,
          v: 603500000
        },
        {
          t: 6900,
          o: 1.23723,
          h: 1.23725,
          l: 1.237,
          c: 1.23714,
          v: 585110000
        },
        {
          t: 7200,
          o: 1.23714,
          h: 1.23764,
          l: 1.23714,
          c: 1.23755,
          v: 761670000
        },
        {
          t: 7500,
          o: 1.23755,
          h: 1.23769,
          l: 1.23746,
          c: 1.23746,
          v: 635840000
        },
        {
          t: 7800,
          o: 1.23746,
          h: 1.23759,
          l: 1.23736,
          c: 1.23759,
          v: 535710000
        },
        {
          t: 8100,
          o: 1.23759,
          h: 1.23773,
          l: 1.23748,
          c: 1.2377,
          v: 433180000
        },
        {
          t: 8400,
          o: 1.23769,
          h: 1.2377,
          l: 1.23756,
          c: 1.23767,
          v: 378820000
        },
        {
          t: 8700,
          o: 1.23767,
          h: 1.23784,
          l: 1.23762,
          c: 1.23775,
          v: 332940000
        },
        {
          t: 9000,
          o: 1.23774,
          h: 1.23774,
          l: 1.23759,
          c: 1.23761,
          v: 337180000
        },
        {
          t: 9300,
          o: 1.23762,
          h: 1.23772,
          l: 1.23757,
          c: 1.2377,
          v: 310790000
        },
        {
          t: 9600,
          o: 1.23772,
          h: 1.23784,
          l: 1.23768,
          c: 1.23784,
          v: 505150000
        },
        {
          t: 9900,
          o: 1.23785,
          h: 1.23806,
          l: 1.23776,
          c: 1.23796,
          v: 559610000
        },
        {
          t: 10200,
          o: 1.23795,
          h: 1.23795,
          l: 1.23767,
          c: 1.23784,
          v: 396520000
        },
        {
          t: 10500,
          o: 1.23785,
          h: 1.23806,
          l: 1.23778,
          c: 1.238,
          v: 368470000
        },
        {
          t: 10800,
          o: 1.238,
          h: 1.23807,
          l: 1.23792,
          c: 1.23794,
          v: 546270000
        },
        {
          t: 11100,
          o: 1.23794,
          h: 1.23803,
          l: 1.2379,
          c: 1.23802,
          v: 367100000
        },
        {
          t: 11400,
          o: 1.23803,
          h: 1.23805,
          l: 1.23798,
          c: 1.23799,
          v: 339060000
        },
        {
          t: 11700,
          o: 1.23799,
          h: 1.23802,
          l: 1.23781,
          c: 1.23783,
          v: 268340000
        },
        {
          t: 12000,
          o: 1.23783,
          h: 1.23784,
          l: 1.23777,
          c: 1.23783,
          v: 253840000
        },
        {
          t: 12300,
          o: 1.23782,
          h: 1.23783,
          l: 1.23772,
          c: 1.23774,
          v: 199360000
        },
        {
          t: 12600,
          o: 1.23773,
          h: 1.23773,
          l: 1.23761,
          c: 1.23762,
          v: 365240000
        },
        {
          t: 12900,
          o: 1.23762,
          h: 1.23762,
          l: 1.23749,
          c: 1.23753,
          v: 583430000
        },
        {
          t: 13200,
          o: 1.23753,
          h: 1.23753,
          l: 1.23737,
          c: 1.23741,
          v: 373640000
        },
        {
          t: 13500,
          o: 1.23741,
          h: 1.23742,
          l: 1.23722,
          c: 1.2374,
          v: 492800000
        },
        {
          t: 13800,
          o: 1.23741,
          h: 1.2378,
          l: 1.23739,
          c: 1.23776,
          v: 630260000
        },
        {
          t: 14100,
          o: 1.23776,
          h: 1.23822,
          l: 1.23773,
          c: 1.23816,
          v: 577750000
        },
        {
          t: 14400,
          o: 1.23816,
          h: 1.23817,
          l: 1.238,
          c: 1.23806,
          v: 265510000
        },
        {
          t: 14700,
          o: 1.23806,
          h: 1.23806,
          l: 1.23799,
          c: 1.23799,
          v: 261550000
        },
        {
          t: 15000,
          o: 1.23799,
          h: 1.23801,
          l: 1.23798,
          c: 1.23799,
          v: 217980000
        },
        {
          t: 15300,
          o: 1.238,
          h: 1.238,
          l: 1.23798,
          c: 1.238,
          v: 271440000
        },
        {
          t: 15600,
          o: 1.238,
          h: 1.23806,
          l: 1.23789,
          c: 1.23789,
          v: 336670000
        },
        {
          t: 15900,
          o: 1.23789,
          h: 1.23795,
          l: 1.23785,
          c: 1.23794,
          v: 373820000
        },
        {
          t: 16200,
          o: 1.23793,
          h: 1.23794,
          l: 1.23785,
          c: 1.2379,
          v: 282110000
        },
        {
          t: 16500,
          o: 1.23791,
          h: 1.23793,
          l: 1.23769,
          c: 1.23773,
          v: 485180000
        },
        {
          t: 16800,
          o: 1.23773,
          h: 1.23781,
          l: 1.23765,
          c: 1.23769,
          v: 694150000
        },
        {
          t: 17100,
          o: 1.2377,
          h: 1.23783,
          l: 1.23764,
          c: 1.23771,
          v: 525470001
        },
        {
          t: 17400,
          o: 1.23771,
          h: 1.23782,
          l: 1.23769,
          c: 1.23778,
          v: 365250000
        },
        {
          t: 17700,
          o: 1.2378,
          h: 1.23783,
          l: 1.2377,
          c: 1.23772,
          v: 300540000
        },
        {
          t: 18000,
          o: 1.2377,
          h: 1.23774,
          l: 1.23763,
          c: 1.23772,
          v: 401180000
        },
        {
          t: 18300,
          o: 1.23772,
          h: 1.2378,
          l: 1.23756,
          c: 1.23764,
          v: 427940000
        },
        {
          t: 18600,
          o: 1.23764,
          h: 1.23768,
          l: 1.23759,
          c: 1.23761,
          v: 337070000
        },
        {
          t: 18900,
          o: 1.23762,
          h: 1.23763,
          l: 1.23736,
          c: 1.23742,
          v: 540110000
        },
        {
          t: 19200,
          o: 1.23741,
          h: 1.23744,
          l: 1.23733,
          c: 1.23737,
          v: 379900000
        },
        {
          t: 19500,
          o: 1.23738,
          h: 1.23743,
          l: 1.23737,
          c: 1.23743,
          v: 187640000
        },
        {
          t: 19800,
          o: 1.23743,
          h: 1.23749,
          l: 1.23736,
          c: 1.23738,
          v: 429370000
        },
        {
          t: 20100,
          o: 1.23738,
          h: 1.23739,
          l: 1.23701,
          c: 1.23707,
          v: 493200000
        },
        {
          t: 20400,
          o: 1.23707,
          h: 1.23743,
          l: 1.23707,
          c: 1.23732,
          v: 424300000
        },
        {
          t: 20700,
          o: 1.23732,
          h: 1.23773,
          l: 1.23732,
          c: 1.23764,
          v: 555890000
        },
        {
          t: 21000,
          o: 1.23763,
          h: 1.23779,
          l: 1.23746,
          c: 1.23774,
          v: 775370000
        },
        {
          t: 21300,
          o: 1.23774,
          h: 1.23776,
          l: 1.23743,
          c: 1.23766,
          v: 531070000
        },
        {
          t: 21600,
          o: 1.23765,
          h: 1.23806,
          l: 1.23761,
          c: 1.23764,
          v: 1366980000
        },
        {
          t: 21900,
          o: 1.23766,
          h: 1.23792,
          l: 1.23752,
          c: 1.23769,
          v: 1258830000
        },
        {
          t: 22200,
          o: 1.23769,
          h: 1.2379,
          l: 1.23749,
          c: 1.23752,
          v: 930940000
        },
        {
          t: 22500,
          o: 1.23752,
          h: 1.23759,
          l: 1.23705,
          c: 1.23751,
          v: 1321789999
        },
        {
          t: 22800,
          o: 1.23752,
          h: 1.23767,
          l: 1.23715,
          c: 1.23722,
          v: 1324820000
        },
        {
          t: 23100,
          o: 1.23722,
          h: 1.23734,
          l: 1.23678,
          c: 1.23699,
          v: 1245550000
        },
        {
          t: 23400,
          o: 1.23699,
          h: 1.2371,
          l: 1.23667,
          c: 1.23678,
          v: 1437340000
        },
        {
          t: 23700,
          o: 1.23682,
          h: 1.23705,
          l: 1.2367,
          c: 1.23679,
          v: 990530000
        },
        {
          t: 24000,
          o: 1.23679,
          h: 1.23736,
          l: 1.23678,
          c: 1.23729,
          v: 938790000
        },
        {
          t: 24300,
          o: 1.2373,
          h: 1.23749,
          l: 1.23677,
          c: 1.23693,
          v: 1074440000
        },
        {
          t: 24600,
          o: 1.23693,
          h: 1.2372,
          l: 1.23627,
          c: 1.23647,
          v: 1518390000
        },
        {
          t: 24900,
          o: 1.23648,
          h: 1.23676,
          l: 1.23612,
          c: 1.2363,
          v: 1172090000
        },
        {
          t: 25200,
          o: 1.23631,
          h: 1.23648,
          l: 1.23584,
          c: 1.23598,
          v: 1558050001
        },
        {
          t: 25500,
          o: 1.23598,
          h: 1.23607,
          l: 1.23529,
          c: 1.23539,
          v: 1447440000
        },
        {
          t: 25800,
          o: 1.23539,
          h: 1.23548,
          l: 1.23488,
          c: 1.23543,
          v: 1816430000
        },
        {
          t: 26100,
          o: 1.23543,
          h: 1.23579,
          l: 1.23532,
          c: 1.23566,
          v: 1163339999
        },
        {
          t: 26400,
          o: 1.23566,
          h: 1.23587,
          l: 1.23545,
          c: 1.23576,
          v: 1121110000
        },
        {
          t: 26700,
          o: 1.23576,
          h: 1.23577,
          l: 1.23542,
          c: 1.23544,
          v: 1107530000
        },
        {
          t: 27000,
          o: 1.23544,
          h: 1.23607,
          l: 1.23544,
          c: 1.2357,
          v: 1216870000
        },
        {
          t: 27300,
          o: 1.23569,
          h: 1.23614,
          l: 1.23567,
          c: 1.23586,
          v: 1315560000
        },
        {
          t: 27600,
          o: 1.23586,
          h: 1.23605,
          l: 1.23581,
          c: 1.236,
          v: 1017140000
        },
        {
          t: 27900,
          o: 1.236,
          h: 1.23676,
          l: 1.236,
          c: 1.23672,
          v: 1415550000
        },
        {
          t: 28200,
          o: 1.23671,
          h: 1.23671,
          l: 1.2363,
          c: 1.23644,
          v: 1073900001
        },
        {
          t: 28500,
          o: 1.23644,
          h: 1.23659,
          l: 1.23623,
          c: 1.23639,
          v: 987470001
        },
        {
          t: 28800,
          o: 1.23638,
          h: 1.23696,
          l: 1.23625,
          c: 1.23694,
          v: 1298260000
        },
        {
          t: 29100,
          o: 1.23696,
          h: 1.23701,
          l: 1.23679,
          c: 1.23687,
          v: 1191360000
        },
        {
          t: 29400,
          o: 1.23687,
          h: 1.2372,
          l: 1.23682,
          c: 1.23699,
          v: 1296880000
        },
        {
          t: 29700,
          o: 1.23698,
          h: 1.23711,
          l: 1.23648,
          c: 1.2366,
          v: 1334080000
        },
        {
          t: 30000,
          o: 1.2366,
          h: 1.23689,
          l: 1.23598,
          c: 1.23631,
          v: 1700040000
        },
        {
          t: 30300,
          o: 1.23631,
          h: 1.2364,
          l: 1.23623,
          c: 1.23635,
          v: 1025010000
        },
        {
          t: 30600,
          o: 1.23635,
          h: 1.23646,
          l: 1.23579,
          c: 1.2364,
          v: 1770460000
        },
        {
          t: 30900,
          o: 1.23641,
          h: 1.23697,
          l: 1.23636,
          c: 1.23674,
          v: 1754690000
        },
        {
          t: 31200,
          o: 1.23674,
          h: 1.23679,
          l: 1.2361,
          c: 1.2362,
          v: 1752530000
        },
        {
          t: 31500,
          o: 1.23619,
          h: 1.23699,
          l: 1.23612,
          c: 1.23679,
          v: 1858960000
        },
        {
          t: 31800,
          o: 1.23679,
          h: 1.23689,
          l: 1.23647,
          c: 1.23671,
          v: 1349340000
        },
        {
          t: 32100,
          o: 1.2367,
          h: 1.2367,
          l: 1.23592,
          c: 1.23596,
          v: 1288619997
        },
        {
          t: 32400,
          o: 1.23592,
          h: 1.23594,
          l: 1.23418,
          c: 1.23462,
          v: 2174920000
        },
        {
          t: 32700,
          o: 1.23464,
          h: 1.23506,
          l: 1.23437,
          c: 1.235,
          v: 1624070000
        },
        {
          t: 33000,
          o: 1.23501,
          h: 1.23538,
          l: 1.23489,
          c: 1.23532,
          v: 1170510000
        },
        {
          t: 33300,
          o: 1.2353,
          h: 1.23576,
          l: 1.23524,
          c: 1.2357,
          v: 1259440000
        },
        {
          t: 33600,
          o: 1.2357,
          h: 1.23605,
          l: 1.23563,
          c: 1.23579,
          v: 1107780000
        },
        {
          t: 33900,
          o: 1.23579,
          h: 1.23677,
          l: 1.23577,
          c: 1.23649,
          v: 1654790000
        },
        {
          t: 34200,
          o: 1.23649,
          h: 1.23668,
          l: 1.23639,
          c: 1.23663,
          v: 1177290000
        },
        {
          t: 34500,
          o: 1.23662,
          h: 1.23667,
          l: 1.23641,
          c: 1.23665,
          v: 952030000
        },
        {
          t: 34800,
          o: 1.23665,
          h: 1.23701,
          l: 1.23664,
          c: 1.23694,
          v: 1252010000
        },
        {
          t: 35100,
          o: 1.23692,
          h: 1.23748,
          l: 1.23692,
          c: 1.23697,
          v: 1643850000
        },
        {
          t: 35400,
          o: 1.23699,
          h: 1.23725,
          l: 1.23682,
          c: 1.23702,
          v: 1272879999
        },
        {
          t: 35700,
          o: 1.23702,
          h: 1.23707,
          l: 1.23636,
          c: 1.23636,
          v: 1420950000
        },
        {
          t: 36000,
          o: 1.23637,
          h: 1.23678,
          l: 1.23632,
          c: 1.23655,
          v: 1242100000
        },
        {
          t: 36300,
          o: 1.23655,
          h: 1.2367,
          l: 1.2363,
          c: 1.2363,
          v: 1342200000
        },
        {
          t: 36600,
          o: 1.23632,
          h: 1.2364,
          l: 1.23612,
          c: 1.23631,
          v: 775200000
        },
        {
          t: 36900,
          o: 1.23631,
          h: 1.23639,
          l: 1.23617,
          c: 1.23627,
          v: 770160000
        },
        {
          t: 37200,
          o: 1.23627,
          h: 1.23628,
          l: 1.23606,
          c: 1.23614,
          v: 879730000
        },
        {
          t: 37500,
          o: 1.23613,
          h: 1.23651,
          l: 1.23605,
          c: 1.23627,
          v: 1265009999
        },
        {
          t: 37800,
          o: 1.23627,
          h: 1.23634,
          l: 1.2361,
          c: 1.23632,
          v: 819920000
        },
        {
          t: 38100,
          o: 1.23632,
          h: 1.23632,
          l: 1.23599,
          c: 1.236,
          v: 838840000
        },
        {
          t: 38400,
          o: 1.23599,
          h: 1.23667,
          l: 1.23598,
          c: 1.23666,
          v: 1118410001
        },
        {
          t: 38700,
          o: 1.23666,
          h: 1.23685,
          l: 1.2366,
          c: 1.23672,
          v: 1108310000
        },
        {
          t: 39000,
          o: 1.23674,
          h: 1.23725,
          l: 1.23665,
          c: 1.23692,
          v: 1372240000
        },
        {
          t: 39300,
          o: 1.23691,
          h: 1.23726,
          l: 1.23691,
          c: 1.23726,
          v: 1203260000
        },
        {
          t: 39600,
          o: 1.23725,
          h: 1.2376,
          l: 1.23713,
          c: 1.23731,
          v: 1510980000
        },
        {
          t: 39900,
          o: 1.23732,
          h: 1.23836,
          l: 1.23715,
          c: 1.23831,
          v: 1180610000
        },
        {
          t: 40200,
          o: 1.23833,
          h: 1.23833,
          l: 1.23767,
          c: 1.23795,
          v: 1336459999
        },
        {
          t: 40500,
          o: 1.23794,
          h: 1.23811,
          l: 1.23777,
          c: 1.23796,
          v: 896570000
        },
        {
          t: 40800,
          o: 1.23795,
          h: 1.23877,
          l: 1.23793,
          c: 1.23846,
          v: 1797320000
        },
        {
          t: 41100,
          o: 1.23845,
          h: 1.23845,
          l: 1.23808,
          c: 1.23811,
          v: 1081920000
        },
        {
          t: 41400,
          o: 1.23813,
          h: 1.2386,
          l: 1.23805,
          c: 1.23853,
          v: 1020140000
        },
        {
          t: 41700,
          o: 1.23851,
          h: 1.23881,
          l: 1.2384,
          c: 1.23875,
          v: 1145900000
        },
        {
          t: 42000,
          o: 1.23875,
          h: 1.23926,
          l: 1.23859,
          c: 1.23864,
          v: 1318089999
        },
        {
          t: 42300,
          o: 1.23864,
          h: 1.2389,
          l: 1.23839,
          c: 1.23848,
          v: 834470000
        },
        {
          t: 42600,
          o: 1.23849,
          h: 1.23885,
          l: 1.23844,
          c: 1.23861,
          v: 767330001
        },
        {
          t: 42900,
          o: 1.23862,
          h: 1.23869,
          l: 1.23824,
          c: 1.23829,
          v: 1198130000
        },
        {
          t: 43200,
          o: 1.2383,
          h: 1.23855,
          l: 1.23818,
          c: 1.23819,
          v: 1026730000
        },
        {
          t: 43500,
          o: 1.23821,
          h: 1.23847,
          l: 1.23813,
          c: 1.23823,
          v: 1099150000
        },
        {
          t: 43800,
          o: 1.23824,
          h: 1.23884,
          l: 1.23824,
          c: 1.23877,
          v: 1198630000
        },
        {
          t: 44100,
          o: 1.23876,
          h: 1.23884,
          l: 1.23857,
          c: 1.23879,
          v: 946570000
        },
        {
          t: 44400,
          o: 1.23883,
          h: 1.23925,
          l: 1.23882,
          c: 1.23924,
          v: 974390000
        },
        {
          t: 44700,
          o: 1.23923,
          h: 1.2395,
          l: 1.23898,
          c: 1.23922,
          v: 1218580001
        },
        {
          t: 45000,
          o: 1.23925,
          h: 1.23928,
          l: 1.23872,
          c: 1.23888,
          v: 1085500000
        },
        {
          t: 45300,
          o: 1.23887,
          h: 1.2391,
          l: 1.23858,
          c: 1.23887,
          v: 909530001
        },
        {
          t: 45600,
          o: 1.23888,
          h: 1.23888,
          l: 1.23826,
          c: 1.23835,
          v: 1022130000
        },
        {
          t: 45900,
          o: 1.23834,
          h: 1.23888,
          l: 1.23834,
          c: 1.23882,
          v: 1042840001
        },
        {
          t: 46200,
          o: 1.23883,
          h: 1.23911,
          l: 1.23869,
          c: 1.23901,
          v: 1102450000
        },
        {
          t: 46500,
          o: 1.239,
          h: 1.23918,
          l: 1.23884,
          c: 1.23914,
          v: 1289470000
        },
        {
          t: 46800,
          o: 1.23913,
          h: 1.23918,
          l: 1.23867,
          c: 1.23867,
          v: 1236920000
        },
        {
          t: 47100,
          o: 1.23868,
          h: 1.23904,
          l: 1.23866,
          c: 1.2387,
          v: 1067620000
        },
        {
          t: 47400,
          o: 1.23871,
          h: 1.23883,
          l: 1.23833,
          c: 1.23833,
          v: 1308750000
        },
        {
          t: 47700,
          o: 1.23831,
          h: 1.23865,
          l: 1.23802,
          c: 1.23856,
          v: 1506980000
        },
        {
          t: 48000,
          o: 1.23854,
          h: 1.23855,
          l: 1.2381,
          c: 1.23819,
          v: 1458290000
        },
        {
          t: 48300,
          o: 1.2382,
          h: 1.23857,
          l: 1.23813,
          c: 1.23834,
          v: 1362480000
        },
        {
          t: 48600,
          o: 1.23834,
          h: 1.23895,
          l: 1.23828,
          c: 1.23895,
          v: 1326620000
        },
        {
          t: 48900,
          o: 1.23897,
          h: 1.23969,
          l: 1.23891,
          c: 1.23895,
          v: 1612020000
        },
        {
          t: 49200,
          o: 1.23898,
          h: 1.23904,
          l: 1.23865,
          c: 1.23868,
          v: 1208430000
        },
        {
          t: 49500,
          o: 1.23869,
          h: 1.23878,
          l: 1.23813,
          c: 1.23871,
          v: 1375790000
        },
        {
          t: 49800,
          o: 1.23871,
          h: 1.23892,
          l: 1.23826,
          c: 1.2386,
          v: 1411050000
        },
        {
          t: 50100,
          o: 1.23863,
          h: 1.23869,
          l: 1.23804,
          c: 1.23846,
          v: 1057480000
        },
        {
          t: 50400,
          o: 1.23848,
          h: 1.23878,
          l: 1.23813,
          c: 1.23864,
          v: 1759960000
        },
        {
          t: 50700,
          o: 1.23865,
          h: 1.23898,
          l: 1.23803,
          c: 1.23849,
          v: 1383129999
        },
        {
          t: 51000,
          o: 1.23848,
          h: 1.23899,
          l: 1.23832,
          c: 1.23863,
          v: 1080170000
        },
        {
          t: 51300,
          o: 1.23864,
          h: 1.2395,
          l: 1.23858,
          c: 1.23919,
          v: 1270340000
        },
        {
          t: 51600,
          o: 1.2392,
          h: 1.23927,
          l: 1.23892,
          c: 1.23917,
          v: 1076220000
        },
        {
          t: 51900,
          o: 1.23918,
          h: 1.23949,
          l: 1.2389,
          c: 1.23945,
          v: 1189750000
        },
        {
          t: 52200,
          o: 1.23946,
          h: 1.23971,
          l: 1.23935,
          c: 1.23963,
          v: 1089930000
        },
        {
          t: 52500,
          o: 1.23963,
          h: 1.23964,
          l: 1.23892,
          c: 1.23893,
          v: 1330170000
        },
        {
          t: 52800,
          o: 1.23895,
          h: 1.23902,
          l: 1.23831,
          c: 1.23854,
          v: 1166020000
        },
        {
          t: 53100,
          o: 1.23854,
          h: 1.23882,
          l: 1.23827,
          c: 1.23832,
          v: 1169120000
        },
        {
          t: 53400,
          o: 1.23834,
          h: 1.23839,
          l: 1.2375,
          c: 1.2376,
          v: 1428540000
        },
        {
          t: 53700,
          o: 1.23763,
          h: 1.23792,
          l: 1.23745,
          c: 1.23768,
          v: 1387420000
        },
        {
          t: 54000,
          o: 1.23764,
          h: 1.23813,
          l: 1.23752,
          c: 1.23805,
          v: 1601540000
        },
        {
          t: 54300,
          o: 1.23805,
          h: 1.23831,
          l: 1.23781,
          c: 1.23781,
          v: 1203870000
        },
        {
          t: 54600,
          o: 1.23785,
          h: 1.23794,
          l: 1.23758,
          c: 1.2379,
          v: 967980000
        },
        {
          t: 54900,
          o: 1.23789,
          h: 1.23806,
          l: 1.23753,
          c: 1.23787,
          v: 1153100000
        },
        {
          t: 55200,
          o: 1.23785,
          h: 1.2383,
          l: 1.23766,
          c: 1.23802,
          v: 960380000
        },
        {
          t: 55500,
          o: 1.23802,
          h: 1.23832,
          l: 1.23796,
          c: 1.23803,
          v: 861650000
        },
        {
          t: 55800,
          o: 1.23804,
          h: 1.23826,
          l: 1.23801,
          c: 1.23826,
          v: 849760000
        },
        {
          t: 56100,
          o: 1.23825,
          h: 1.23825,
          l: 1.23769,
          c: 1.23799,
          v: 1037720000
        },
        {
          t: 56400,
          o: 1.238,
          h: 1.23806,
          l: 1.23711,
          c: 1.23737,
          v: 1323650000
        },
        {
          t: 56700,
          o: 1.23739,
          h: 1.23773,
          l: 1.23716,
          c: 1.23771,
          v: 989550000
        },
        {
          t: 57000,
          o: 1.2377,
          h: 1.23785,
          l: 1.23762,
          c: 1.2378,
          v: 815200000
        },
        {
          t: 57300,
          o: 1.23781,
          h: 1.23813,
          l: 1.23773,
          c: 1.23796,
          v: 1225639999
        },
        {
          t: 57600,
          o: 1.23799,
          h: 1.23801,
          l: 1.23721,
          c: 1.23727,
          v: 1226609999
        },
        {
          t: 57900,
          o: 1.23726,
          h: 1.23768,
          l: 1.23726,
          c: 1.23744,
          v: 1014820000
        },
        {
          t: 58200,
          o: 1.23744,
          h: 1.23785,
          l: 1.2374,
          c: 1.23781,
          v: 921790000
        },
        {
          t: 58500,
          o: 1.23781,
          h: 1.23805,
          l: 1.23764,
          c: 1.23801,
          v: 739970000
        },
        {
          t: 58800,
          o: 1.23804,
          h: 1.23804,
          l: 1.23762,
          c: 1.23781,
          v: 696490000
        },
        {
          t: 59100,
          o: 1.23783,
          h: 1.23846,
          l: 1.23783,
          c: 1.23842,
          v: 1043960000
        },
        {
          t: 59400,
          o: 1.23844,
          h: 1.23884,
          l: 1.23833,
          c: 1.23855,
          v: 900950000
        },
        {
          t: 59700,
          o: 1.23856,
          h: 1.23877,
          l: 1.23848,
          c: 1.23865,
          v: 907660000
        },
        {
          t: 60000,
          o: 1.23867,
          h: 1.23888,
          l: 1.23858,
          c: 1.2387,
          v: 800940000
        },
        {
          t: 60300,
          o: 1.23872,
          h: 1.23885,
          l: 1.23862,
          c: 1.23871,
          v: 684870000
        },
        {
          t: 60600,
          o: 1.23875,
          h: 1.23891,
          l: 1.23844,
          c: 1.2387,
          v: 846920000
        },
        {
          t: 60900,
          o: 1.23871,
          h: 1.23928,
          l: 1.23871,
          c: 1.23901,
          v: 1174580000
        },
        {
          t: 61200,
          o: 1.239,
          h: 1.23928,
          l: 1.23881,
          c: 1.23927,
          v: 756879999
        },
        {
          t: 61500,
          o: 1.23927,
          h: 1.23927,
          l: 1.23875,
          c: 1.23875,
          v: 765940000
        },
        {
          t: 61800,
          o: 1.23877,
          h: 1.23879,
          l: 1.2385,
          c: 1.23858,
          v: 580630000
        },
        {
          t: 62100,
          o: 1.23857,
          h: 1.23873,
          l: 1.23835,
          c: 1.23847,
          v: 656440000
        },
        {
          t: 62400,
          o: 1.23846,
          h: 1.23853,
          l: 1.23824,
          c: 1.23832,
          v: 828080000
        },
        {
          t: 62700,
          o: 1.23832,
          h: 1.23832,
          l: 1.2381,
          c: 1.23816,
          v: 710179999
        },
        {
          t: 63000,
          o: 1.23817,
          h: 1.23857,
          l: 1.23816,
          c: 1.23854,
          v: 685580000
        },
        {
          t: 63300,
          o: 1.23856,
          h: 1.23867,
          l: 1.23846,
          c: 1.23854,
          v: 697070000
        },
        {
          t: 63600,
          o: 1.23855,
          h: 1.2386,
          l: 1.23811,
          c: 1.23812,
          v: 749990000
        },
        {
          t: 63900,
          o: 1.23815,
          h: 1.23845,
          l: 1.23786,
          c: 1.23844,
          v: 1228100000
        },
        {
          t: 64200,
          o: 1.23846,
          h: 1.23852,
          l: 1.23814,
          c: 1.23822,
          v: 784200000
        },
        {
          t: 64500,
          o: 1.23824,
          h: 1.2383,
          l: 1.23812,
          c: 1.23824,
          v: 556800000
        },
        {
          t: 64800,
          o: 1.23825,
          h: 1.23825,
          l: 1.23772,
          c: 1.23809,
          v: 842200000
        },
        {
          t: 65100,
          o: 1.2381,
          h: 1.23845,
          l: 1.238,
          c: 1.23838,
          v: 684980000
        },
        {
          t: 65400,
          o: 1.23839,
          h: 1.23858,
          l: 1.23837,
          c: 1.23842,
          v: 689430000
        },
        {
          t: 65700,
          o: 1.23842,
          h: 1.23872,
          l: 1.23842,
          c: 1.23866,
          v: 612570000
        },
        {
          t: 66000,
          o: 1.23867,
          h: 1.23869,
          l: 1.23809,
          c: 1.23819,
          v: 954880000
        },
        {
          t: 66300,
          o: 1.23819,
          h: 1.23819,
          l: 1.23802,
          c: 1.23805,
          v: 534070000
        },
        {
          t: 66600,
          o: 1.23806,
          h: 1.23823,
          l: 1.23791,
          c: 1.23802,
          v: 629780000
        },
        {
          t: 66900,
          o: 1.23801,
          h: 1.23812,
          l: 1.23785,
          c: 1.23797,
          v: 633890000
        },
        {
          t: 67200,
          o: 1.23797,
          h: 1.23804,
          l: 1.23775,
          c: 1.23791,
          v: 773110001
        },
        {
          t: 67500,
          o: 1.23791,
          h: 1.23808,
          l: 1.23778,
          c: 1.23783,
          v: 894670000
        },
        {
          t: 67800,
          o: 1.23783,
          h: 1.23784,
          l: 1.23751,
          c: 1.23751,
          v: 848250000
        },
        {
          t: 68100,
          o: 1.2375,
          h: 1.23777,
          l: 1.2375,
          c: 1.23773,
          v: 692490000
        },
        {
          t: 68400,
          o: 1.23773,
          h: 1.23786,
          l: 1.23757,
          c: 1.23764,
          v: 904340000
        },
        {
          t: 68700,
          o: 1.23765,
          h: 1.23765,
          l: 1.2373,
          c: 1.23747,
          v: 1114980000
        },
        {
          t: 69000,
          o: 1.23747,
          h: 1.23758,
          l: 1.23739,
          c: 1.23753,
          v: 678040000
        },
        {
          t: 69300,
          o: 1.23757,
          h: 1.23772,
          l: 1.23743,
          c: 1.23745,
          v: 846950000
        },
        {
          t: 69600,
          o: 1.23745,
          h: 1.23752,
          l: 1.23727,
          c: 1.23739,
          v: 698930000
        },
        {
          t: 69900,
          o: 1.23742,
          h: 1.23763,
          l: 1.2374,
          c: 1.23758,
          v: 651210000
        },
        {
          t: 70200,
          o: 1.23758,
          h: 1.23771,
          l: 1.23749,
          c: 1.23763,
          v: 487820000
        },
        {
          t: 70500,
          o: 1.23763,
          h: 1.23764,
          l: 1.23731,
          c: 1.23731,
          v: 493590000
        },
        {
          t: 70800,
          o: 1.23732,
          h: 1.23746,
          l: 1.23727,
          c: 1.2374,
          v: 685070000
        },
        {
          t: 71100,
          o: 1.23741,
          h: 1.23747,
          l: 1.23722,
          c: 1.23747,
          v: 858640000
        },
        {
          t: 71400,
          o: 1.23746,
          h: 1.23757,
          l: 1.2373,
          c: 1.23742,
          v: 741160000
        },
        {
          t: 71700,
          o: 1.2374,
          h: 1.23747,
          l: 1.23725,
          c: 1.23747,
          v: 654450000
        },
        {
          t: 72000,
          o: 1.2375,
          h: 1.23765,
          l: 1.23738,
          c: 1.23752,
          v: 722940000
        },
        {
          t: 72300,
          o: 1.2375,
          h: 1.2377,
          l: 1.23743,
          c: 1.23764,
          v: 586290000
        },
        {
          t: 72600,
          o: 1.23764,
          h: 1.23782,
          l: 1.23763,
          c: 1.23779,
          v: 469220000
        },
        {
          t: 72900,
          o: 1.2378,
          h: 1.23807,
          l: 1.23775,
          c: 1.23806,
          v: 530580000
        },
        {
          t: 73200,
          o: 1.23805,
          h: 1.23805,
          l: 1.23777,
          c: 1.23781,
          v: 198450000
        },
        {
          t: 73500,
          o: 1.23781,
          h: 1.2379,
          l: 1.23775,
          c: 1.23776,
          v: 378890000
        },
        {
          t: 73800,
          o: 1.23775,
          h: 1.23785,
          l: 1.23772,
          c: 1.23777,
          v: 381230000
        },
        {
          t: 74100,
          o: 1.23776,
          h: 1.23778,
          l: 1.23766,
          c: 1.23766,
          v: 307620000
        },
        {
          t: 74400,
          o: 1.23766,
          h: 1.23768,
          l: 1.23755,
          c: 1.23761,
          v: 273350000
        },
        {
          t: 74700,
          o: 1.2376,
          h: 1.23763,
          l: 1.23758,
          c: 1.23762,
          v: 203050000
        },
        {
          t: 75000,
          o: 1.23762,
          h: 1.23766,
          l: 1.23749,
          c: 1.23751,
          v: 426090000
        },
        {
          t: 75300,
          o: 1.23751,
          h: 1.23751,
          l: 1.23735,
          c: 1.23736,
          v: 343790000
        },
        {
          t: 75600,
          o: 1.23737,
          h: 1.23783,
          l: 1.2373,
          c: 1.23763,
          v: 65280000
        },
        {
          t: 75900,
          o: 1.23763,
          h: 1.23788,
          l: 1.23762,
          c: 1.23779,
          v: 277120000
        },
        {
          t: 76200,
          o: 1.23779,
          h: 1.2378,
          l: 1.23776,
          c: 1.23779,
          v: 306800000
        },
        {
          t: 76500,
          o: 1.23779,
          h: 1.23779,
          l: 1.23761,
          c: 1.23767,
          v: 410240000
        },
        {
          t: 76800,
          o: 1.23766,
          h: 1.23799,
          l: 1.23764,
          c: 1.23792,
          v: 569380000
        },
        {
          t: 77100,
          o: 1.23795,
          h: 1.23795,
          l: 1.23766,
          c: 1.23788,
          v: 668100000
        },
        {
          t: 77400,
          o: 1.23788,
          h: 1.2379,
          l: 1.23784,
          c: 1.23785,
          v: 275650000
        },
        {
          t: 77700,
          o: 1.23785,
          h: 1.23793,
          l: 1.23782,
          c: 1.23785,
          v: 491940000
        },
        {
          t: 78000,
          o: 1.23785,
          h: 1.23793,
          l: 1.23777,
          c: 1.23779,
          v: 373930000
        },
        {
          t: 78300,
          o: 1.2378,
          h: 1.23786,
          l: 1.23777,
          c: 1.23779,
          v: 271370000
        },
        {
          t: 78600,
          o: 1.23778,
          h: 1.23781,
          l: 1.23777,
          c: 1.23778,
          v: 201030000
        },
        {
          t: 78900,
          o: 1.2378,
          h: 1.23785,
          l: 1.23778,
          c: 1.23785,
          v: 106230000
        },
        {
          t: 79200,
          o: 1.23784,
          h: 1.23785,
          l: 1.23777,
          c: 1.23781,
          v: 225190000
        },
        {
          t: 79500,
          o: 1.23782,
          h: 1.23793,
          l: 1.23779,
          c: 1.23787,
          v: 326640000
        },
        {
          t: 79800,
          o: 1.23786,
          h: 1.23798,
          l: 1.23786,
          c: 1.23798,
          v: 159560000
        },
        {
          t: 80100,
          o: 1.23797,
          h: 1.23809,
          l: 1.23792,
          c: 1.23804,
          v: 250100000
        },
        {
          t: 80400,
          o: 1.23803,
          h: 1.23803,
          l: 1.23793,
          c: 1.23795,
          v: 112200000
        },
        {
          t: 80700,
          o: 1.23795,
          h: 1.23795,
          l: 1.23789,
          c: 1.23789,
          v: 99660000
        },
        {
          t: 81000,
          o: 1.23789,
          h: 1.23794,
          l: 1.23787,
          c: 1.23793,
          v: 166510000
        },
        {
          t: 81300,
          o: 1.23794,
          h: 1.23814,
          l: 1.23794,
          c: 1.23813,
          v: 121650000
        },
        {
          t: 81600,
          o: 1.23813,
          h: 1.2382,
          l: 1.23803,
          c: 1.23814,
          v: 261370000
        },
        {
          t: 81900,
          o: 1.23814,
          h: 1.23829,
          l: 1.23814,
          c: 1.23819,
          v: 371280000
        },
        {
          t: 82200,
          o: 1.23819,
          h: 1.23825,
          l: 1.23809,
          c: 1.23813,
          v: 194720000
        },
        {
          t: 82500,
          o: 1.23814,
          h: 1.23814,
          l: 1.23789,
          c: 1.2379,
          v: 243680000
        },
        {
          t: 82800,
          o: 1.23791,
          h: 1.23806,
          l: 1.23791,
          c: 1.238,
          v: 298090000
        },
        {
          t: 83100,
          o: 1.238,
          h: 1.23831,
          l: 1.23799,
          c: 1.23831,
          v: 272060000
        },
        {
          t: 83400,
          o: 1.2383,
          h: 1.23836,
          l: 1.23809,
          c: 1.23814,
          v: 376170000
        },
        {
          t: 83700,
          o: 1.23814,
          h: 1.23814,
          l: 1.23799,
          c: 1.23799,
          v: 372700000
        },
        {
          t: 84000,
          o: 1.23799,
          h: 1.23806,
          l: 1.23794,
          c: 1.23799,
          v: 321480000
        },
        {
          t: 84300,
          o: 1.238,
          h: 1.23806,
          l: 1.23799,
          c: 1.23806,
          v: 163160000
        },
        {
          t: 84600,
          o: 1.23805,
          h: 1.23817,
          l: 1.23805,
          c: 1.23815,
          v: 104150000
        },
        {
          t: 84900,
          o: 1.23815,
          h: 1.23825,
          l: 1.23815,
          c: 1.23816,
          v: 209820000
        },
        {
          t: 85200,
          o: 1.23816,
          h: 1.23816,
          l: 1.23785,
          c: 1.23796,
          v: 646319999
        },
        {
          t: 85500,
          o: 1.23796,
          h: 1.23801,
          l: 1.2379,
          c: 1.238,
          v: 412380000
        },
        {
          t: 85800,
          o: 1.238,
          h: 1.238,
          l: 1.23789,
          c: 1.23791,
          v: 296600000
        },
        {
          t: 86100,
          o: 1.23791,
          h: 1.23794,
          l: 1.23785,
          c: 1.23785,
          v: 321050000
        }
      ]
    },
    {
      d: 1524096000,
      b: [
        {
          t: 0,
          o: 1.23786,
          h: 1.23789,
          l: 1.23764,
          c: 1.2377,
          v: 921700000
        },
        {
          t: 300,
          o: 1.2377,
          h: 1.23789,
          l: 1.23769,
          c: 1.23785,
          v: 367880000
        },
        {
          t: 600,
          o: 1.23784,
          h: 1.23789,
          l: 1.23764,
          c: 1.23769,
          v: 398070000
        },
        {
          t: 900,
          o: 1.23767,
          h: 1.2379,
          l: 1.23767,
          c: 1.23781,
          v: 354960000
        },
        {
          t: 1200,
          o: 1.23783,
          h: 1.23792,
          l: 1.23768,
          c: 1.23769,
          v: 520680000
        },
        {
          t: 1500,
          o: 1.23768,
          h: 1.23776,
          l: 1.23754,
          c: 1.23755,
          v: 388500000
        },
        {
          t: 1800,
          o: 1.23755,
          h: 1.23758,
          l: 1.23734,
          c: 1.2375,
          v: 503530000
        },
        {
          t: 2100,
          o: 1.2375,
          h: 1.23764,
          l: 1.23743,
          c: 1.23763,
          v: 421350000
        },
        {
          t: 2400,
          o: 1.23765,
          h: 1.23773,
          l: 1.23751,
          c: 1.23751,
          v: 432440000
        },
        {
          t: 2700,
          o: 1.23751,
          h: 1.23762,
          l: 1.23735,
          c: 1.23735,
          v: 550850000
        },
        {
          t: 3000,
          o: 1.23735,
          h: 1.23737,
          l: 1.23703,
          c: 1.2373,
          v: 883030000
        },
        {
          t: 3300,
          o: 1.23727,
          h: 1.23736,
          l: 1.23721,
          c: 1.23722,
          v: 437690000
        },
        {
          t: 3600,
          o: 1.23722,
          h: 1.23743,
          l: 1.23722,
          c: 1.2374,
          v: 281250000
        },
        {
          t: 3900,
          o: 1.2374,
          h: 1.23748,
          l: 1.23724,
          c: 1.23735,
          v: 397660000
        },
        {
          t: 4200,
          o: 1.23736,
          h: 1.23747,
          l: 1.23735,
          c: 1.23736,
          v: 572090000
        },
        {
          t: 4500,
          o: 1.23736,
          h: 1.2374,
          l: 1.23709,
          c: 1.23709,
          v: 616340000
        },
        {
          t: 4800,
          o: 1.2371,
          h: 1.23729,
          l: 1.23704,
          c: 1.23711,
          v: 414020000
        },
        {
          t: 5100,
          o: 1.23711,
          h: 1.23725,
          l: 1.23711,
          c: 1.23715,
          v: 366070000
        },
        {
          t: 5400,
          o: 1.23717,
          h: 1.23717,
          l: 1.23679,
          c: 1.23704,
          v: 970140000
        },
        {
          t: 5700,
          o: 1.23704,
          h: 1.23721,
          l: 1.23692,
          c: 1.23717,
          v: 723570000
        },
        {
          t: 6000,
          o: 1.23717,
          h: 1.23722,
          l: 1.23695,
          c: 1.23695,
          v: 528240000
        },
        {
          t: 6300,
          o: 1.23696,
          h: 1.23703,
          l: 1.23665,
          c: 1.23666,
          v: 874330000
        },
        {
          t: 6600,
          o: 1.23666,
          h: 1.23678,
          l: 1.23647,
          c: 1.23647,
          v: 716040000
        },
        {
          t: 6900,
          o: 1.23647,
          h: 1.23681,
          l: 1.23646,
          c: 1.23678,
          v: 726480000
        },
        {
          t: 7200,
          o: 1.23677,
          h: 1.23694,
          l: 1.23671,
          c: 1.23687,
          v: 615390000
        },
        {
          t: 7500,
          o: 1.23688,
          h: 1.23709,
          l: 1.23684,
          c: 1.23708,
          v: 526370000
        },
        {
          t: 7800,
          o: 1.23708,
          h: 1.2372,
          l: 1.23701,
          c: 1.23714,
          v: 508730000
        },
        {
          t: 8100,
          o: 1.23714,
          h: 1.23726,
          l: 1.23707,
          c: 1.23719,
          v: 391220000
        },
        {
          t: 8400,
          o: 1.2372,
          h: 1.23738,
          l: 1.23718,
          c: 1.23731,
          v: 262100000
        },
        {
          t: 8700,
          o: 1.23732,
          h: 1.23744,
          l: 1.23725,
          c: 1.23729,
          v: 500470000
        },
        {
          t: 9000,
          o: 1.23729,
          h: 1.2373,
          l: 1.2371,
          c: 1.23716,
          v: 419820000
        },
        {
          t: 9300,
          o: 1.23715,
          h: 1.23745,
          l: 1.23713,
          c: 1.23741,
          v: 424840000
        },
        {
          t: 9600,
          o: 1.2374,
          h: 1.23755,
          l: 1.2374,
          c: 1.23754,
          v: 352020000
        },
        {
          t: 9900,
          o: 1.23755,
          h: 1.23758,
          l: 1.23734,
          c: 1.23753,
          v: 430400000
        },
        {
          t: 10200,
          o: 1.23753,
          h: 1.23779,
          l: 1.23752,
          c: 1.23775,
          v: 611100000
        },
        {
          t: 10500,
          o: 1.23774,
          h: 1.23787,
          l: 1.23762,
          c: 1.23776,
          v: 731700000
        },
        {
          t: 10800,
          o: 1.23776,
          h: 1.23821,
          l: 1.23771,
          c: 1.23811,
          v: 921050000
        },
        {
          t: 11100,
          o: 1.23811,
          h: 1.23848,
          l: 1.23811,
          c: 1.23829,
          v: 817850000
        },
        {
          t: 11400,
          o: 1.23828,
          h: 1.23848,
          l: 1.2382,
          c: 1.23843,
          v: 774120000
        },
        {
          t: 11700,
          o: 1.23841,
          h: 1.23855,
          l: 1.23827,
          c: 1.23838,
          v: 717380000
        },
        {
          t: 12000,
          o: 1.23839,
          h: 1.23841,
          l: 1.23812,
          c: 1.23824,
          v: 650760000
        },
        {
          t: 12300,
          o: 1.23824,
          h: 1.23828,
          l: 1.23802,
          c: 1.2381,
          v: 351700000
        },
        {
          t: 12600,
          o: 1.23809,
          h: 1.23814,
          l: 1.23786,
          c: 1.23786,
          v: 474180000
        },
        {
          t: 12900,
          o: 1.23786,
          h: 1.23794,
          l: 1.23778,
          c: 1.23794,
          v: 524470000
        },
        {
          t: 13200,
          o: 1.23794,
          h: 1.23798,
          l: 1.23788,
          c: 1.23788,
          v: 168360000
        },
        {
          t: 13500,
          o: 1.23788,
          h: 1.23795,
          l: 1.23778,
          c: 1.2379,
          v: 373640000
        },
        {
          t: 13800,
          o: 1.2379,
          h: 1.23794,
          l: 1.23789,
          c: 1.2379,
          v: 166520000
        },
        {
          t: 14100,
          o: 1.23789,
          h: 1.23795,
          l: 1.23781,
          c: 1.23784,
          v: 431600000
        },
        {
          t: 14400,
          o: 1.23785,
          h: 1.23786,
          l: 1.23772,
          c: 1.23779,
          v: 630410000
        },
        {
          t: 14700,
          o: 1.2378,
          h: 1.23814,
          l: 1.23779,
          c: 1.23813,
          v: 469870000
        },
        {
          t: 15000,
          o: 1.23813,
          h: 1.23826,
          l: 1.23809,
          c: 1.23826,
          v: 405550000
        },
        {
          t: 15300,
          o: 1.23827,
          h: 1.23836,
          l: 1.23824,
          c: 1.23825,
          v: 381800000
        },
        {
          t: 15600,
          o: 1.23823,
          h: 1.23831,
          l: 1.23814,
          c: 1.23817,
          v: 552480000
        },
        {
          t: 15900,
          o: 1.23817,
          h: 1.23835,
          l: 1.23815,
          c: 1.23829,
          v: 158350000
        },
        {
          t: 16200,
          o: 1.23829,
          h: 1.23832,
          l: 1.23805,
          c: 1.23805,
          v: 431350000
        },
        {
          t: 16500,
          o: 1.23804,
          h: 1.23804,
          l: 1.23789,
          c: 1.23796,
          v: 243800000
        },
        {
          t: 16800,
          o: 1.23795,
          h: 1.23802,
          l: 1.23785,
          c: 1.23785,
          v: 409760000
        },
        {
          t: 17100,
          o: 1.23786,
          h: 1.23786,
          l: 1.23774,
          c: 1.23774,
          v: 266510000
        },
        {
          t: 17400,
          o: 1.23774,
          h: 1.23784,
          l: 1.23768,
          c: 1.23779,
          v: 289880000
        },
        {
          t: 17700,
          o: 1.23779,
          h: 1.23789,
          l: 1.23778,
          c: 1.23781,
          v: 291870000
        },
        {
          t: 18000,
          o: 1.23781,
          h: 1.23782,
          l: 1.23768,
          c: 1.2377,
          v: 400010000
        },
        {
          t: 18300,
          o: 1.2377,
          h: 1.23784,
          l: 1.23765,
          c: 1.23779,
          v: 486730000
        },
        {
          t: 18600,
          o: 1.23779,
          h: 1.23786,
          l: 1.23765,
          c: 1.23774,
          v: 410130000
        },
        {
          t: 18900,
          o: 1.23775,
          h: 1.23795,
          l: 1.23774,
          c: 1.23783,
          v: 421640000
        },
        {
          t: 19200,
          o: 1.23784,
          h: 1.23801,
          l: 1.23778,
          c: 1.23793,
          v: 476590000
        },
        {
          t: 19500,
          o: 1.23795,
          h: 1.23836,
          l: 1.23791,
          c: 1.23833,
          v: 860389999
        },
        {
          t: 19800,
          o: 1.23833,
          h: 1.23839,
          l: 1.23813,
          c: 1.23813,
          v: 573310001
        },
        {
          t: 20100,
          o: 1.23813,
          h: 1.23818,
          l: 1.23798,
          c: 1.238,
          v: 287600000
        },
        {
          t: 20400,
          o: 1.23799,
          h: 1.23804,
          l: 1.23738,
          c: 1.23753,
          v: 648240000
        },
        {
          t: 20700,
          o: 1.23755,
          h: 1.23779,
          l: 1.23749,
          c: 1.23757,
          v: 789130000
        },
        {
          t: 21000,
          o: 1.23754,
          h: 1.2378,
          l: 1.23753,
          c: 1.23778,
          v: 519490000
        },
        {
          t: 21300,
          o: 1.23778,
          h: 1.23778,
          l: 1.23744,
          c: 1.23744,
          v: 513080000
        },
        {
          t: 21600,
          o: 1.23744,
          h: 1.23755,
          l: 1.23702,
          c: 1.23717,
          v: 961190000
        },
        {
          t: 21900,
          o: 1.23716,
          h: 1.23727,
          l: 1.23706,
          c: 1.23724,
          v: 718520000
        },
        {
          t: 22200,
          o: 1.23728,
          h: 1.23768,
          l: 1.23726,
          c: 1.23734,
          v: 1016330000
        },
        {
          t: 22500,
          o: 1.23733,
          h: 1.2376,
          l: 1.23714,
          c: 1.23747,
          v: 1035210000
        },
        {
          t: 22800,
          o: 1.23747,
          h: 1.23768,
          l: 1.23717,
          c: 1.23717,
          v: 961320000
        },
        {
          t: 23100,
          o: 1.23716,
          h: 1.23716,
          l: 1.23685,
          c: 1.23686,
          v: 1372260000
        },
        {
          t: 23400,
          o: 1.23684,
          h: 1.23746,
          l: 1.23672,
          c: 1.23743,
          v: 1439510000
        },
        {
          t: 23700,
          o: 1.23744,
          h: 1.23779,
          l: 1.23739,
          c: 1.23763,
          v: 1152910000
        },
        {
          t: 24000,
          o: 1.23764,
          h: 1.23795,
          l: 1.23749,
          c: 1.23775,
          v: 1092320000
        },
        {
          t: 24300,
          o: 1.23776,
          h: 1.23781,
          l: 1.23728,
          c: 1.23728,
          v: 1133080000
        },
        {
          t: 24600,
          o: 1.23732,
          h: 1.23774,
          l: 1.23728,
          c: 1.23748,
          v: 1036360000
        },
        {
          t: 24900,
          o: 1.23747,
          h: 1.23818,
          l: 1.23739,
          c: 1.23805,
          v: 1435790000
        },
        {
          t: 25200,
          o: 1.23803,
          h: 1.23857,
          l: 1.23803,
          c: 1.23848,
          v: 1324910000
        },
        {
          t: 25500,
          o: 1.23849,
          h: 1.23949,
          l: 1.23848,
          c: 1.23944,
          v: 1612870000
        },
        {
          t: 25800,
          o: 1.23947,
          h: 1.23949,
          l: 1.23901,
          c: 1.23937,
          v: 1591520000
        },
        {
          t: 26100,
          o: 1.23935,
          h: 1.23937,
          l: 1.2388,
          c: 1.23905,
          v: 1336670001
        },
        {
          t: 26400,
          o: 1.23908,
          h: 1.23952,
          l: 1.23906,
          c: 1.23929,
          v: 1299420000
        },
        {
          t: 26700,
          o: 1.23931,
          h: 1.23993,
          l: 1.23931,
          c: 1.23985,
          v: 1686200000
        },
        {
          t: 27000,
          o: 1.23984,
          h: 1.24001,
          l: 1.23955,
          c: 1.23959,
          v: 1516380000
        },
        {
          t: 27300,
          o: 1.23957,
          h: 1.2398,
          l: 1.23944,
          c: 1.23946,
          v: 1343780002
        },
        {
          t: 27600,
          o: 1.23946,
          h: 1.23948,
          l: 1.23915,
          c: 1.23936,
          v: 1235610001
        },
        {
          t: 27900,
          o: 1.23935,
          h: 1.2394,
          l: 1.23855,
          c: 1.23873,
          v: 1138960000
        },
        {
          t: 28200,
          o: 1.23874,
          h: 1.23891,
          l: 1.2386,
          c: 1.23885,
          v: 867420000
        },
        {
          t: 28500,
          o: 1.23884,
          h: 1.239,
          l: 1.23835,
          c: 1.23881,
          v: 1409710000
        },
        {
          t: 28800,
          o: 1.23881,
          h: 1.23888,
          l: 1.2385,
          c: 1.23864,
          v: 1197400000
        },
        {
          t: 29100,
          o: 1.23863,
          h: 1.23877,
          l: 1.2384,
          c: 1.23865,
          v: 1055480000
        },
        {
          t: 29400,
          o: 1.23865,
          h: 1.23866,
          l: 1.238,
          c: 1.23837,
          v: 1122370000
        },
        {
          t: 29700,
          o: 1.23837,
          h: 1.23856,
          l: 1.2382,
          c: 1.23846,
          v: 1019500000
        },
        {
          t: 30000,
          o: 1.23845,
          h: 1.23856,
          l: 1.23812,
          c: 1.23827,
          v: 873090000
        },
        {
          t: 30300,
          o: 1.23827,
          h: 1.23828,
          l: 1.23797,
          c: 1.23803,
          v: 914390001
        },
        {
          t: 30600,
          o: 1.23803,
          h: 1.23808,
          l: 1.23636,
          c: 1.23684,
          v: 1677220000
        },
        {
          t: 30900,
          o: 1.23685,
          h: 1.23695,
          l: 1.236,
          c: 1.23687,
          v: 1557440001
        },
        {
          t: 31200,
          o: 1.23686,
          h: 1.23698,
          l: 1.2366,
          c: 1.23678,
          v: 1008520000
        },
        {
          t: 31500,
          o: 1.2368,
          h: 1.23718,
          l: 1.23657,
          c: 1.23712,
          v: 1210210000
        },
        {
          t: 31800,
          o: 1.23713,
          h: 1.23737,
          l: 1.23671,
          c: 1.23672,
          v: 1400040000
        },
        {
          t: 32100,
          o: 1.23676,
          h: 1.23686,
          l: 1.2366,
          c: 1.23661,
          v: 1022000000
        },
        {
          t: 32400,
          o: 1.23661,
          h: 1.23665,
          l: 1.23601,
          c: 1.23624,
          v: 1353860001
        },
        {
          t: 32700,
          o: 1.23624,
          h: 1.23663,
          l: 1.23617,
          c: 1.2364,
          v: 1016580000
        },
        {
          t: 33000,
          o: 1.23642,
          h: 1.23647,
          l: 1.23607,
          c: 1.23613,
          v: 768630000
        },
        {
          t: 33300,
          o: 1.23614,
          h: 1.23637,
          l: 1.23604,
          c: 1.23635,
          v: 842090000
        },
        {
          t: 33600,
          o: 1.23635,
          h: 1.2364,
          l: 1.2355,
          c: 1.23572,
          v: 1139240000
        },
        {
          t: 33900,
          o: 1.23572,
          h: 1.23599,
          l: 1.23549,
          c: 1.23597,
          v: 1416280000
        },
        {
          t: 34200,
          o: 1.23598,
          h: 1.23603,
          l: 1.23577,
          c: 1.23602,
          v: 933580000
        },
        {
          t: 34500,
          o: 1.23603,
          h: 1.23658,
          l: 1.23599,
          c: 1.23658,
          v: 1059740000
        },
        {
          t: 34800,
          o: 1.23657,
          h: 1.23672,
          l: 1.23635,
          c: 1.23668,
          v: 1104690000
        },
        {
          t: 35100,
          o: 1.23666,
          h: 1.23683,
          l: 1.2365,
          c: 1.2368,
          v: 806350000
        },
        {
          t: 35400,
          o: 1.2368,
          h: 1.23695,
          l: 1.23668,
          c: 1.23671,
          v: 863190000
        },
        {
          t: 35700,
          o: 1.23672,
          h: 1.23697,
          l: 1.2365,
          c: 1.23696,
          v: 800350000
        },
        {
          t: 36000,
          o: 1.23699,
          h: 1.23717,
          l: 1.23636,
          c: 1.23638,
          v: 1304000000
        },
        {
          t: 36300,
          o: 1.23639,
          h: 1.23685,
          l: 1.23631,
          c: 1.23683,
          v: 985070000
        },
        {
          t: 36600,
          o: 1.23683,
          h: 1.23689,
          l: 1.23646,
          c: 1.23663,
          v: 946030000
        },
        {
          t: 36900,
          o: 1.23662,
          h: 1.23682,
          l: 1.23645,
          c: 1.23669,
          v: 874160000
        },
        {
          t: 37200,
          o: 1.23669,
          h: 1.23677,
          l: 1.23652,
          c: 1.23661,
          v: 659010000
        },
        {
          t: 37500,
          o: 1.23661,
          h: 1.23695,
          l: 1.23557,
          c: 1.23673,
          v: 1373080000
        },
        {
          t: 37800,
          o: 1.23673,
          h: 1.23704,
          l: 1.2365,
          c: 1.23692,
          v: 1177390000
        },
        {
          t: 38100,
          o: 1.2369,
          h: 1.23731,
          l: 1.23678,
          c: 1.23708,
          v: 1091760000
        },
        {
          t: 38400,
          o: 1.23708,
          h: 1.23731,
          l: 1.23698,
          c: 1.23703,
          v: 1005180000
        },
        {
          t: 38700,
          o: 1.23704,
          h: 1.23716,
          l: 1.23684,
          c: 1.23703,
          v: 1066410000
        },
        {
          t: 39000,
          o: 1.23703,
          h: 1.23731,
          l: 1.2369,
          c: 1.23696,
          v: 1169960000
        },
        {
          t: 39300,
          o: 1.23694,
          h: 1.23694,
          l: 1.23664,
          c: 1.23667,
          v: 1086390000
        },
        {
          t: 39600,
          o: 1.23667,
          h: 1.23667,
          l: 1.23636,
          c: 1.23642,
          v: 1277580000
        },
        {
          t: 39900,
          o: 1.23641,
          h: 1.23663,
          l: 1.23631,
          c: 1.23637,
          v: 876650000
        },
        {
          t: 40200,
          o: 1.23635,
          h: 1.23672,
          l: 1.23634,
          c: 1.23668,
          v: 752140000
        },
        {
          t: 40500,
          o: 1.23667,
          h: 1.23696,
          l: 1.23649,
          c: 1.23692,
          v: 1111730000
        },
        {
          t: 40800,
          o: 1.23694,
          h: 1.23738,
          l: 1.2368,
          c: 1.23716,
          v: 1003200000
        },
        {
          t: 41100,
          o: 1.23715,
          h: 1.23758,
          l: 1.23708,
          c: 1.23741,
          v: 1089220000
        },
        {
          t: 41400,
          o: 1.23743,
          h: 1.23769,
          l: 1.23736,
          c: 1.23737,
          v: 962880000
        },
        {
          t: 41700,
          o: 1.23737,
          h: 1.23748,
          l: 1.23721,
          c: 1.23724,
          v: 769070000
        },
        {
          t: 42000,
          o: 1.23725,
          h: 1.23737,
          l: 1.23702,
          c: 1.23722,
          v: 1071530000
        },
        {
          t: 42300,
          o: 1.23723,
          h: 1.2374,
          l: 1.23723,
          c: 1.23732,
          v: 723959999
        },
        {
          t: 42600,
          o: 1.2373,
          h: 1.23752,
          l: 1.23694,
          c: 1.23744,
          v: 863850000
        },
        {
          t: 42900,
          o: 1.23744,
          h: 1.23808,
          l: 1.2374,
          c: 1.23807,
          v: 1393269997
        },
        {
          t: 43200,
          o: 1.2381,
          h: 1.23819,
          l: 1.23781,
          c: 1.23792,
          v: 1234550000
        },
        {
          t: 43500,
          o: 1.23793,
          h: 1.23804,
          l: 1.23773,
          c: 1.23793,
          v: 1138649999
        },
        {
          t: 43800,
          o: 1.23792,
          h: 1.23833,
          l: 1.23787,
          c: 1.23814,
          v: 869610000
        },
        {
          t: 44100,
          o: 1.23814,
          h: 1.23833,
          l: 1.23767,
          c: 1.23778,
          v: 887580000
        },
        {
          t: 44400,
          o: 1.23779,
          h: 1.23782,
          l: 1.23729,
          c: 1.23743,
          v: 921930000
        },
        {
          t: 44700,
          o: 1.23743,
          h: 1.23762,
          l: 1.23725,
          c: 1.23756,
          v: 656410000
        },
        {
          t: 45000,
          o: 1.23762,
          h: 1.23773,
          l: 1.23719,
          c: 1.23724,
          v: 1297479998
        },
        {
          t: 45300,
          o: 1.23724,
          h: 1.23819,
          l: 1.23722,
          c: 1.23794,
          v: 1371720000
        },
        {
          t: 45600,
          o: 1.23794,
          h: 1.23854,
          l: 1.23776,
          c: 1.23833,
          v: 1059550000
        },
        {
          t: 45900,
          o: 1.23832,
          h: 1.2384,
          l: 1.23792,
          c: 1.23805,
          v: 1176720000
        },
        {
          t: 46200,
          o: 1.23804,
          h: 1.23826,
          l: 1.238,
          c: 1.23813,
          v: 1225180000
        },
        {
          t: 46500,
          o: 1.23812,
          h: 1.23827,
          l: 1.23761,
          c: 1.23792,
          v: 1294310001
        },
        {
          t: 46800,
          o: 1.23791,
          h: 1.23846,
          l: 1.23786,
          c: 1.23841,
          v: 1502130000
        },
        {
          t: 47100,
          o: 1.23842,
          h: 1.23842,
          l: 1.23773,
          c: 1.23802,
          v: 1163970000
        },
        {
          t: 47400,
          o: 1.23802,
          h: 1.23807,
          l: 1.23781,
          c: 1.23796,
          v: 986160000
        },
        {
          t: 47700,
          o: 1.23796,
          h: 1.23821,
          l: 1.23741,
          c: 1.23767,
          v: 1256360000
        },
        {
          t: 48000,
          o: 1.23765,
          h: 1.23793,
          l: 1.23742,
          c: 1.23789,
          v: 884770000
        },
        {
          t: 48300,
          o: 1.23789,
          h: 1.23837,
          l: 1.23782,
          c: 1.23804,
          v: 1327270000
        },
        {
          t: 48600,
          o: 1.23804,
          h: 1.23829,
          l: 1.23787,
          c: 1.23794,
          v: 1460050000
        },
        {
          t: 48900,
          o: 1.23796,
          h: 1.23819,
          l: 1.23753,
          c: 1.2376,
          v: 1429180000
        },
        {
          t: 49200,
          o: 1.2376,
          h: 1.23785,
          l: 1.23741,
          c: 1.23745,
          v: 1222490000
        },
        {
          t: 49500,
          o: 1.23745,
          h: 1.23756,
          l: 1.23718,
          c: 1.23737,
          v: 1267090001
        },
        {
          t: 49800,
          o: 1.23737,
          h: 1.23766,
          l: 1.23732,
          c: 1.23746,
          v: 1071110000
        },
        {
          t: 50100,
          o: 1.23747,
          h: 1.23768,
          l: 1.23697,
          c: 1.23697,
          v: 993850000
        },
        {
          t: 50400,
          o: 1.23697,
          h: 1.23755,
          l: 1.23697,
          c: 1.23743,
          v: 1335770000
        },
        {
          t: 50700,
          o: 1.23743,
          h: 1.23763,
          l: 1.23732,
          c: 1.23757,
          v: 1182650000
        },
        {
          t: 51000,
          o: 1.23757,
          h: 1.23792,
          l: 1.2375,
          c: 1.23779,
          v: 1081610000
        },
        {
          t: 51300,
          o: 1.23778,
          h: 1.23804,
          l: 1.23776,
          c: 1.23801,
          v: 1027910000
        },
        {
          t: 51600,
          o: 1.23801,
          h: 1.23821,
          l: 1.23758,
          c: 1.23765,
          v: 1298730001
        },
        {
          t: 51900,
          o: 1.23765,
          h: 1.23815,
          l: 1.23762,
          c: 1.23815,
          v: 1037220000
        },
        {
          t: 52200,
          o: 1.23812,
          h: 1.23816,
          l: 1.23746,
          c: 1.23754,
          v: 1429730000
        },
        {
          t: 52500,
          o: 1.23756,
          h: 1.23784,
          l: 1.23743,
          c: 1.23781,
          v: 1357449999
        },
        {
          t: 52800,
          o: 1.23781,
          h: 1.23786,
          l: 1.23737,
          c: 1.23747,
          v: 1187010000
        },
        {
          t: 53100,
          o: 1.23747,
          h: 1.23761,
          l: 1.23735,
          c: 1.23749,
          v: 1045420000
        },
        {
          t: 53400,
          o: 1.23748,
          h: 1.23749,
          l: 1.23687,
          c: 1.23705,
          v: 1709210000
        },
        {
          t: 53700,
          o: 1.23706,
          h: 1.23706,
          l: 1.23638,
          c: 1.23642,
          v: 1881760000
        },
        {
          t: 54000,
          o: 1.23642,
          h: 1.23654,
          l: 1.23593,
          c: 1.23594,
          v: 1635460000
        },
        {
          t: 54300,
          o: 1.23595,
          h: 1.23625,
          l: 1.23573,
          c: 1.23603,
          v: 1299210000
        },
        {
          t: 54600,
          o: 1.23603,
          h: 1.23633,
          l: 1.23603,
          c: 1.23629,
          v: 1015980000
        },
        {
          t: 54900,
          o: 1.23629,
          h: 1.23637,
          l: 1.23586,
          c: 1.23586,
          v: 1222389999
        },
        {
          t: 55200,
          o: 1.23587,
          h: 1.23622,
          l: 1.23516,
          c: 1.23516,
          v: 1264400000
        },
        {
          t: 55500,
          o: 1.23515,
          h: 1.2354,
          l: 1.23511,
          c: 1.23532,
          v: 1185770000
        },
        {
          t: 55800,
          o: 1.23531,
          h: 1.23552,
          l: 1.23471,
          c: 1.23501,
          v: 1356290000
        },
        {
          t: 56100,
          o: 1.235,
          h: 1.23513,
          l: 1.23397,
          c: 1.23406,
          v: 1556340000
        },
        {
          t: 56400,
          o: 1.23408,
          h: 1.23425,
          l: 1.23373,
          c: 1.234,
          v: 1626640000
        },
        {
          t: 56700,
          o: 1.234,
          h: 1.23403,
          l: 1.2329,
          c: 1.23321,
          v: 1402470000
        },
        {
          t: 57000,
          o: 1.23323,
          h: 1.23357,
          l: 1.23315,
          c: 1.23352,
          v: 1305660000
        },
        {
          t: 57300,
          o: 1.23353,
          h: 1.23365,
          l: 1.23337,
          c: 1.23364,
          v: 1275700000
        },
        {
          t: 57600,
          o: 1.23362,
          h: 1.23381,
          l: 1.23328,
          c: 1.23359,
          v: 1160020000
        },
        {
          t: 57900,
          o: 1.23359,
          h: 1.23412,
          l: 1.23352,
          c: 1.23405,
          v: 1295170000
        },
        {
          t: 58200,
          o: 1.23403,
          h: 1.23413,
          l: 1.23362,
          c: 1.23391,
          v: 1084410000
        },
        {
          t: 58500,
          o: 1.23388,
          h: 1.2344,
          l: 1.23379,
          c: 1.23394,
          v: 1339160000
        },
        {
          t: 58800,
          o: 1.23393,
          h: 1.23424,
          l: 1.23364,
          c: 1.23373,
          v: 1510820000
        },
        {
          t: 59100,
          o: 1.23372,
          h: 1.23407,
          l: 1.23361,
          c: 1.23383,
          v: 1065970000
        },
        {
          t: 59400,
          o: 1.23383,
          h: 1.23406,
          l: 1.23354,
          c: 1.23405,
          v: 1322230000
        },
        {
          t: 59700,
          o: 1.23405,
          h: 1.23406,
          l: 1.23369,
          c: 1.23375,
          v: 1128200000
        },
        {
          t: 60000,
          o: 1.23374,
          h: 1.2339,
          l: 1.23355,
          c: 1.23385,
          v: 1073700000
        },
        {
          t: 60300,
          o: 1.23387,
          h: 1.23426,
          l: 1.23381,
          c: 1.2342,
          v: 983960000
        },
        {
          t: 60600,
          o: 1.23421,
          h: 1.23435,
          l: 1.23405,
          c: 1.23426,
          v: 1106920000
        },
        {
          t: 60900,
          o: 1.23428,
          h: 1.23478,
          l: 1.23426,
          c: 1.23447,
          v: 1186790000
        },
        {
          t: 61200,
          o: 1.23449,
          h: 1.23452,
          l: 1.23406,
          c: 1.23419,
          v: 1153650001
        },
        {
          t: 61500,
          o: 1.23419,
          h: 1.23449,
          l: 1.23417,
          c: 1.23446,
          v: 1062890000
        },
        {
          t: 61800,
          o: 1.23445,
          h: 1.23448,
          l: 1.23404,
          c: 1.23405,
          v: 1078990000
        },
        {
          t: 62100,
          o: 1.23405,
          h: 1.2343,
          l: 1.23375,
          c: 1.23399,
          v: 1723310000
        },
        {
          t: 62400,
          o: 1.23398,
          h: 1.23418,
          l: 1.23357,
          c: 1.23401,
          v: 1151600001
        },
        {
          t: 62700,
          o: 1.234,
          h: 1.23442,
          l: 1.23398,
          c: 1.23435,
          v: 985310000
        },
        {
          t: 63000,
          o: 1.23435,
          h: 1.23584,
          l: 1.23404,
          c: 1.23574,
          v: 1693300000
        },
        {
          t: 63300,
          o: 1.23572,
          h: 1.23579,
          l: 1.23487,
          c: 1.23493,
          v: 1642860000
        },
        {
          t: 63600,
          o: 1.23492,
          h: 1.23506,
          l: 1.23484,
          c: 1.23486,
          v: 703760000
        },
        {
          t: 63900,
          o: 1.23489,
          h: 1.23524,
          l: 1.23469,
          c: 1.23488,
          v: 1022820000
        },
        {
          t: 64200,
          o: 1.23488,
          h: 1.23496,
          l: 1.23448,
          c: 1.23495,
          v: 823270000
        },
        {
          t: 64500,
          o: 1.23495,
          h: 1.23495,
          l: 1.23449,
          c: 1.23458,
          v: 931030000
        },
        {
          t: 64800,
          o: 1.23458,
          h: 1.23464,
          l: 1.23407,
          c: 1.23426,
          v: 1119160000
        },
        {
          t: 65100,
          o: 1.23428,
          h: 1.23477,
          l: 1.23426,
          c: 1.23475,
          v: 715400000
        },
        {
          t: 65400,
          o: 1.23475,
          h: 1.23485,
          l: 1.23469,
          c: 1.23474,
          v: 727350000
        },
        {
          t: 65700,
          o: 1.23474,
          h: 1.2349,
          l: 1.2346,
          c: 1.23486,
          v: 851120000
        },
        {
          t: 66000,
          o: 1.23485,
          h: 1.23503,
          l: 1.23465,
          c: 1.2347,
          v: 797520000
        },
        {
          t: 66300,
          o: 1.23472,
          h: 1.23491,
          l: 1.23461,
          c: 1.2348,
          v: 515260000
        },
        {
          t: 66600,
          o: 1.23479,
          h: 1.23494,
          l: 1.23447,
          c: 1.23448,
          v: 758590000
        },
        {
          t: 66900,
          o: 1.23448,
          h: 1.23458,
          l: 1.23437,
          c: 1.23445,
          v: 759980000
        },
        {
          t: 67200,
          o: 1.23446,
          h: 1.23451,
          l: 1.23434,
          c: 1.2344,
          v: 824960000
        },
        {
          t: 67500,
          o: 1.2344,
          h: 1.23444,
          l: 1.2337,
          c: 1.23392,
          v: 680860000
        },
        {
          t: 67800,
          o: 1.23395,
          h: 1.23395,
          l: 1.2337,
          c: 1.2339,
          v: 736150000
        },
        {
          t: 68100,
          o: 1.2339,
          h: 1.23401,
          l: 1.23376,
          c: 1.23377,
          v: 1082680000
        },
        {
          t: 68400,
          o: 1.23379,
          h: 1.23443,
          l: 1.23368,
          c: 1.23442,
          v: 1023490000
        },
        {
          t: 68700,
          o: 1.23441,
          h: 1.23473,
          l: 1.23434,
          c: 1.23457,
          v: 1145060000
        },
        {
          t: 69000,
          o: 1.23457,
          h: 1.23468,
          l: 1.2344,
          c: 1.23458,
          v: 826890000
        },
        {
          t: 69300,
          o: 1.23457,
          h: 1.23472,
          l: 1.23448,
          c: 1.23466,
          v: 980810000
        },
        {
          t: 69600,
          o: 1.23465,
          h: 1.23471,
          l: 1.23453,
          c: 1.2347,
          v: 696320000
        },
        {
          t: 69900,
          o: 1.23471,
          h: 1.2349,
          l: 1.23464,
          c: 1.23464,
          v: 876920000
        },
        {
          t: 70200,
          o: 1.23465,
          h: 1.23493,
          l: 1.23462,
          c: 1.23477,
          v: 594390000
        },
        {
          t: 70500,
          o: 1.23477,
          h: 1.235,
          l: 1.23444,
          c: 1.2348,
          v: 1320360000
        },
        {
          t: 70800,
          o: 1.23481,
          h: 1.23484,
          l: 1.23463,
          c: 1.23483,
          v: 632450000
        },
        {
          t: 71100,
          o: 1.23482,
          h: 1.23503,
          l: 1.23477,
          c: 1.23492,
          v: 759080000
        },
        {
          t: 71400,
          o: 1.23492,
          h: 1.23514,
          l: 1.23485,
          c: 1.23512,
          v: 1001690000
        },
        {
          t: 71700,
          o: 1.23514,
          h: 1.23522,
          l: 1.23484,
          c: 1.23486,
          v: 804980000
        },
        {
          t: 72000,
          o: 1.23485,
          h: 1.2349,
          l: 1.23454,
          c: 1.23476,
          v: 599220000
        },
        {
          t: 72300,
          o: 1.23476,
          h: 1.23487,
          l: 1.23476,
          c: 1.2348,
          v: 313600000
        },
        {
          t: 72600,
          o: 1.2348,
          h: 1.23483,
          l: 1.23464,
          c: 1.23476,
          v: 289670000
        },
        {
          t: 72900,
          o: 1.23475,
          h: 1.23512,
          l: 1.23475,
          c: 1.23512,
          v: 629220000
        },
        {
          t: 73200,
          o: 1.23511,
          h: 1.23524,
          l: 1.23498,
          c: 1.23503,
          v: 546050000
        },
        {
          t: 73500,
          o: 1.23502,
          h: 1.23503,
          l: 1.23487,
          c: 1.23488,
          v: 336240000
        },
        {
          t: 73800,
          o: 1.23492,
          h: 1.23493,
          l: 1.23449,
          c: 1.23463,
          v: 497420000
        },
        {
          t: 74100,
          o: 1.23463,
          h: 1.23474,
          l: 1.23458,
          c: 1.23468,
          v: 199860000
        },
        {
          t: 74400,
          o: 1.23467,
          h: 1.23469,
          l: 1.23449,
          c: 1.23452,
          v: 269240000
        },
        {
          t: 74700,
          o: 1.2345,
          h: 1.2345,
          l: 1.23433,
          c: 1.23448,
          v: 301750000
        },
        {
          t: 75000,
          o: 1.23446,
          h: 1.23453,
          l: 1.23438,
          c: 1.23449,
          v: 382270000
        },
        {
          t: 75300,
          o: 1.23448,
          h: 1.23456,
          l: 1.23445,
          c: 1.23453,
          v: 253080000
        },
        {
          t: 75600,
          o: 1.23451,
          h: 1.23471,
          l: 1.23442,
          c: 1.23442,
          v: 84910000
        },
        {
          t: 75900,
          o: 1.23442,
          h: 1.2348,
          l: 1.23442,
          c: 1.23468,
          v: 202420000
        },
        {
          t: 76200,
          o: 1.23469,
          h: 1.23477,
          l: 1.23464,
          c: 1.23475,
          v: 289950000
        },
        {
          t: 76500,
          o: 1.23475,
          h: 1.23476,
          l: 1.23449,
          c: 1.2346,
          v: 351630000
        },
        {
          t: 76800,
          o: 1.2346,
          h: 1.23465,
          l: 1.23453,
          c: 1.23453,
          v: 179280000
        },
        {
          t: 77100,
          o: 1.23452,
          h: 1.23463,
          l: 1.2345,
          c: 1.23455,
          v: 208640000
        },
        {
          t: 77400,
          o: 1.23454,
          h: 1.23455,
          l: 1.23442,
          c: 1.23442,
          v: 109310000
        },
        {
          t: 77700,
          o: 1.23444,
          h: 1.23466,
          l: 1.23438,
          c: 1.23466,
          v: 179520000
        },
        {
          t: 78000,
          o: 1.23465,
          h: 1.23465,
          l: 1.23447,
          c: 1.23451,
          v: 259900000
        },
        {
          t: 78300,
          o: 1.23452,
          h: 1.23476,
          l: 1.2345,
          c: 1.23459,
          v: 375030000
        },
        {
          t: 78600,
          o: 1.23459,
          h: 1.23462,
          l: 1.23454,
          c: 1.23455,
          v: 325420000
        },
        {
          t: 78900,
          o: 1.23455,
          h: 1.23466,
          l: 1.23451,
          c: 1.23458,
          v: 327220000
        },
        {
          t: 79200,
          o: 1.23455,
          h: 1.23463,
          l: 1.23438,
          c: 1.23445,
          v: 309620000
        },
        {
          t: 79500,
          o: 1.23445,
          h: 1.23448,
          l: 1.23434,
          c: 1.23437,
          v: 207060000
        },
        {
          t: 79800,
          o: 1.23437,
          h: 1.23444,
          l: 1.2343,
          c: 1.23442,
          v: 352320000
        },
        {
          t: 80100,
          o: 1.23442,
          h: 1.23444,
          l: 1.23435,
          c: 1.2344,
          v: 171270000
        },
        {
          t: 80400,
          o: 1.2344,
          h: 1.23453,
          l: 1.2344,
          c: 1.2345,
          v: 179560000
        },
        {
          t: 80700,
          o: 1.2345,
          h: 1.23453,
          l: 1.23435,
          c: 1.23436,
          v: 341730000
        },
        {
          t: 81000,
          o: 1.23436,
          h: 1.23443,
          l: 1.23426,
          c: 1.23426,
          v: 292610000
        },
        {
          t: 81300,
          o: 1.23427,
          h: 1.23444,
          l: 1.23425,
          c: 1.23441,
          v: 152700000
        },
        {
          t: 81600,
          o: 1.23441,
          h: 1.2345,
          l: 1.2344,
          c: 1.23441,
          v: 191870000
        },
        {
          t: 81900,
          o: 1.23442,
          h: 1.23442,
          l: 1.23429,
          c: 1.23437,
          v: 223180000
        },
        {
          t: 82200,
          o: 1.23437,
          h: 1.23439,
          l: 1.23434,
          c: 1.23434,
          v: 106850000
        },
        {
          t: 82500,
          o: 1.23435,
          h: 1.23436,
          l: 1.23426,
          c: 1.23427,
          v: 147800000
        },
        {
          t: 82800,
          o: 1.23428,
          h: 1.23436,
          l: 1.23428,
          c: 1.23436,
          v: 171690000
        },
        {
          t: 83100,
          o: 1.23437,
          h: 1.23465,
          l: 1.23437,
          c: 1.23463,
          v: 365160000
        },
        {
          t: 83400,
          o: 1.23463,
          h: 1.23466,
          l: 1.23457,
          c: 1.23462,
          v: 249050000
        },
        {
          t: 83700,
          o: 1.23461,
          h: 1.23467,
          l: 1.23459,
          c: 1.23461,
          v: 268710000
        },
        {
          t: 84000,
          o: 1.2346,
          h: 1.23462,
          l: 1.23451,
          c: 1.23459,
          v: 210760000
        },
        {
          t: 84300,
          o: 1.23459,
          h: 1.23466,
          l: 1.23456,
          c: 1.23462,
          v: 157070000
        },
        {
          t: 84600,
          o: 1.23462,
          h: 1.23462,
          l: 1.23455,
          c: 1.23455,
          v: 145900000
        },
        {
          t: 84900,
          o: 1.23457,
          h: 1.23457,
          l: 1.23449,
          c: 1.23455,
          v: 368320000
        },
        {
          t: 85200,
          o: 1.23455,
          h: 1.23455,
          l: 1.23445,
          c: 1.23446,
          v: 428610000
        },
        {
          t: 85500,
          o: 1.23445,
          h: 1.23447,
          l: 1.23431,
          c: 1.23445,
          v: 219980000
        },
        {
          t: 85800,
          o: 1.23445,
          h: 1.23448,
          l: 1.23435,
          c: 1.23443,
          v: 118480000
        },
        {
          t: 86100,
          o: 1.23443,
          h: 1.23453,
          l: 1.2343,
          c: 1.2343,
          v: 266380000
        }
      ]
    },
    {
      d: 1524182400,
      b: [
        {
          t: 0,
          o: 1.2343,
          h: 1.2346,
          l: 1.23421,
          c: 1.23452,
          v: 531640000
        },
        {
          t: 300,
          o: 1.23452,
          h: 1.23454,
          l: 1.23416,
          c: 1.23418,
          v: 731170000
        },
        {
          t: 600,
          o: 1.23418,
          h: 1.23437,
          l: 1.23411,
          c: 1.23411,
          v: 560700000
        },
        {
          t: 900,
          o: 1.23411,
          h: 1.23426,
          l: 1.23401,
          c: 1.23401,
          v: 616780000
        },
        {
          t: 1200,
          o: 1.23401,
          h: 1.2343,
          l: 1.23398,
          c: 1.23416,
          v: 558440000
        },
        {
          t: 1500,
          o: 1.23417,
          h: 1.23447,
          l: 1.23412,
          c: 1.23436,
          v: 504560000
        },
        {
          t: 1800,
          o: 1.23436,
          h: 1.23445,
          l: 1.2341,
          c: 1.23417,
          v: 521190000
        },
        {
          t: 2100,
          o: 1.23416,
          h: 1.23418,
          l: 1.23391,
          c: 1.23412,
          v: 640770000
        },
        {
          t: 2400,
          o: 1.23414,
          h: 1.23439,
          l: 1.23407,
          c: 1.23437,
          v: 333590000
        },
        {
          t: 2700,
          o: 1.23439,
          h: 1.23447,
          l: 1.23428,
          c: 1.23437,
          v: 363770000
        },
        {
          t: 3000,
          o: 1.23437,
          h: 1.23476,
          l: 1.23435,
          c: 1.23476,
          v: 653900000
        },
        {
          t: 3300,
          o: 1.23476,
          h: 1.23487,
          l: 1.23461,
          c: 1.23472,
          v: 743790000
        },
        {
          t: 3600,
          o: 1.23471,
          h: 1.23482,
          l: 1.23465,
          c: 1.23471,
          v: 579950000
        },
        {
          t: 3900,
          o: 1.23471,
          h: 1.23471,
          l: 1.23449,
          c: 1.23455,
          v: 483720000
        },
        {
          t: 4200,
          o: 1.23456,
          h: 1.23463,
          l: 1.23451,
          c: 1.23456,
          v: 400990000
        },
        {
          t: 4500,
          o: 1.23456,
          h: 1.23458,
          l: 1.23437,
          c: 1.23447,
          v: 623210001
        },
        {
          t: 4800,
          o: 1.23447,
          h: 1.23447,
          l: 1.2341,
          c: 1.2341,
          v: 460230000
        },
        {
          t: 5100,
          o: 1.23411,
          h: 1.23411,
          l: 1.23366,
          c: 1.23366,
          v: 955539999
        },
        {
          t: 5400,
          o: 1.23366,
          h: 1.23405,
          l: 1.23365,
          c: 1.23392,
          v: 768280000
        },
        {
          t: 5700,
          o: 1.23393,
          h: 1.23396,
          l: 1.23379,
          c: 1.23388,
          v: 733530000
        },
        {
          t: 6000,
          o: 1.23387,
          h: 1.23403,
          l: 1.2337,
          c: 1.23381,
          v: 572450000
        },
        {
          t: 6300,
          o: 1.23382,
          h: 1.23407,
          l: 1.23381,
          c: 1.23407,
          v: 526160000
        },
        {
          t: 6600,
          o: 1.23407,
          h: 1.23433,
          l: 1.23396,
          c: 1.23417,
          v: 580470000
        },
        {
          t: 6900,
          o: 1.23415,
          h: 1.23433,
          l: 1.23409,
          c: 1.23426,
          v: 835530000
        },
        {
          t: 7200,
          o: 1.23425,
          h: 1.23428,
          l: 1.23401,
          c: 1.23427,
          v: 689030000
        },
        {
          t: 7500,
          o: 1.23427,
          h: 1.23444,
          l: 1.23427,
          c: 1.23439,
          v: 497050000
        },
        {
          t: 7800,
          o: 1.23439,
          h: 1.23468,
          l: 1.23438,
          c: 1.2346,
          v: 513100000
        },
        {
          t: 8100,
          o: 1.23459,
          h: 1.23463,
          l: 1.2341,
          c: 1.23416,
          v: 587199999
        },
        {
          t: 8400,
          o: 1.23416,
          h: 1.23423,
          l: 1.23411,
          c: 1.23422,
          v: 294990000
        },
        {
          t: 8700,
          o: 1.23423,
          h: 1.23435,
          l: 1.23407,
          c: 1.23408,
          v: 413700000
        },
        {
          t: 9000,
          o: 1.23407,
          h: 1.23413,
          l: 1.23401,
          c: 1.23402,
          v: 549550000
        },
        {
          t: 9300,
          o: 1.23402,
          h: 1.23445,
          l: 1.23402,
          c: 1.23414,
          v: 447840000
        },
        {
          t: 9600,
          o: 1.23413,
          h: 1.23418,
          l: 1.23397,
          c: 1.23401,
          v: 510740000
        },
        {
          t: 9900,
          o: 1.23402,
          h: 1.23403,
          l: 1.23389,
          c: 1.23391,
          v: 526820000
        },
        {
          t: 10200,
          o: 1.23392,
          h: 1.23392,
          l: 1.23376,
          c: 1.23377,
          v: 423070000
        },
        {
          t: 10500,
          o: 1.23377,
          h: 1.23382,
          l: 1.23367,
          c: 1.23377,
          v: 511350000
        },
        {
          t: 10800,
          o: 1.23376,
          h: 1.2338,
          l: 1.23367,
          c: 1.23367,
          v: 721320000
        },
        {
          t: 11100,
          o: 1.23367,
          h: 1.23404,
          l: 1.23362,
          c: 1.23398,
          v: 435990000
        },
        {
          t: 11400,
          o: 1.23398,
          h: 1.23402,
          l: 1.23382,
          c: 1.23384,
          v: 444860000
        },
        {
          t: 11700,
          o: 1.23385,
          h: 1.23404,
          l: 1.23385,
          c: 1.23401,
          v: 425420000
        },
        {
          t: 12000,
          o: 1.23401,
          h: 1.23404,
          l: 1.23397,
          c: 1.23398,
          v: 386760000
        },
        {
          t: 12300,
          o: 1.23398,
          h: 1.23421,
          l: 1.23392,
          c: 1.23402,
          v: 458570000
        },
        {
          t: 12600,
          o: 1.23402,
          h: 1.23403,
          l: 1.23386,
          c: 1.23391,
          v: 513260000
        },
        {
          t: 12900,
          o: 1.2339,
          h: 1.23404,
          l: 1.23381,
          c: 1.23402,
          v: 404620000
        },
        {
          t: 13200,
          o: 1.23403,
          h: 1.23405,
          l: 1.23394,
          c: 1.23402,
          v: 422370000
        },
        {
          t: 13500,
          o: 1.23403,
          h: 1.2341,
          l: 1.23392,
          c: 1.23408,
          v: 298500000
        },
        {
          t: 13800,
          o: 1.23408,
          h: 1.23412,
          l: 1.23402,
          c: 1.23408,
          v: 226830000
        },
        {
          t: 14100,
          o: 1.23407,
          h: 1.23415,
          l: 1.23407,
          c: 1.23413,
          v: 244790000
        },
        {
          t: 14400,
          o: 1.23413,
          h: 1.23418,
          l: 1.23407,
          c: 1.23417,
          v: 574830000
        },
        {
          t: 14700,
          o: 1.23417,
          h: 1.23418,
          l: 1.23406,
          c: 1.23408,
          v: 416100000
        },
        {
          t: 15000,
          o: 1.2341,
          h: 1.23418,
          l: 1.23409,
          c: 1.23418,
          v: 469140000
        },
        {
          t: 15300,
          o: 1.23417,
          h: 1.23422,
          l: 1.23406,
          c: 1.23407,
          v: 450140000
        },
        {
          t: 15600,
          o: 1.23408,
          h: 1.23412,
          l: 1.23398,
          c: 1.23402,
          v: 457850000
        },
        {
          t: 15900,
          o: 1.23402,
          h: 1.23403,
          l: 1.23397,
          c: 1.23397,
          v: 270490000
        },
        {
          t: 16200,
          o: 1.23397,
          h: 1.23404,
          l: 1.23391,
          c: 1.23403,
          v: 252110000
        },
        {
          t: 16500,
          o: 1.23403,
          h: 1.23412,
          l: 1.23403,
          c: 1.23408,
          v: 431050000
        },
        {
          t: 16800,
          o: 1.23409,
          h: 1.23425,
          l: 1.23409,
          c: 1.2342,
          v: 150160000
        },
        {
          t: 17100,
          o: 1.2342,
          h: 1.23426,
          l: 1.23417,
          c: 1.23418,
          v: 194830000
        },
        {
          t: 17400,
          o: 1.23418,
          h: 1.23424,
          l: 1.23414,
          c: 1.23424,
          v: 337700000
        },
        {
          t: 17700,
          o: 1.23424,
          h: 1.23424,
          l: 1.23407,
          c: 1.23408,
          v: 268760000
        },
        {
          t: 18000,
          o: 1.23408,
          h: 1.23421,
          l: 1.23399,
          c: 1.23403,
          v: 510670000
        },
        {
          t: 18300,
          o: 1.23403,
          h: 1.23408,
          l: 1.23393,
          c: 1.23394,
          v: 532040000
        },
        {
          t: 18600,
          o: 1.23393,
          h: 1.23414,
          l: 1.2339,
          c: 1.23403,
          v: 655360000
        },
        {
          t: 18900,
          o: 1.23403,
          h: 1.23404,
          l: 1.23382,
          c: 1.23404,
          v: 568140000
        },
        {
          t: 19200,
          o: 1.23403,
          h: 1.23418,
          l: 1.23402,
          c: 1.23403,
          v: 572580000
        },
        {
          t: 19500,
          o: 1.23403,
          h: 1.23406,
          l: 1.2339,
          c: 1.23393,
          v: 392060000
        },
        {
          t: 19800,
          o: 1.23393,
          h: 1.23408,
          l: 1.23392,
          c: 1.23407,
          v: 319440000
        },
        {
          t: 20100,
          o: 1.23406,
          h: 1.23428,
          l: 1.23402,
          c: 1.23426,
          v: 582320000
        },
        {
          t: 20400,
          o: 1.23425,
          h: 1.2343,
          l: 1.2342,
          c: 1.23422,
          v: 409660000
        },
        {
          t: 20700,
          o: 1.23422,
          h: 1.23452,
          l: 1.23422,
          c: 1.23451,
          v: 533210001
        },
        {
          t: 21000,
          o: 1.2345,
          h: 1.23456,
          l: 1.23404,
          c: 1.23407,
          v: 752520000
        },
        {
          t: 21300,
          o: 1.23407,
          h: 1.23433,
          l: 1.23399,
          c: 1.23417,
          v: 650900000
        },
        {
          t: 21600,
          o: 1.23416,
          h: 1.23424,
          l: 1.2338,
          c: 1.23424,
          v: 800260000
        },
        {
          t: 21900,
          o: 1.23424,
          h: 1.23424,
          l: 1.23386,
          c: 1.23412,
          v: 1200860000
        },
        {
          t: 22200,
          o: 1.23412,
          h: 1.23413,
          l: 1.23337,
          c: 1.23338,
          v: 1402070000
        },
        {
          t: 22500,
          o: 1.23338,
          h: 1.23394,
          l: 1.23332,
          c: 1.23388,
          v: 1450180000
        },
        {
          t: 22800,
          o: 1.23388,
          h: 1.23437,
          l: 1.23383,
          c: 1.23427,
          v: 1426520000
        },
        {
          t: 23100,
          o: 1.23428,
          h: 1.23428,
          l: 1.23381,
          c: 1.23387,
          v: 1010090000
        },
        {
          t: 23400,
          o: 1.23387,
          h: 1.23401,
          l: 1.23351,
          c: 1.23378,
          v: 1544510000
        },
        {
          t: 23700,
          o: 1.23378,
          h: 1.23423,
          l: 1.23377,
          c: 1.23418,
          v: 1302780000
        },
        {
          t: 24000,
          o: 1.2342,
          h: 1.23491,
          l: 1.2342,
          c: 1.23456,
          v: 1358340000
        },
        {
          t: 24300,
          o: 1.23456,
          h: 1.23457,
          l: 1.23419,
          c: 1.23428,
          v: 1256360000
        },
        {
          t: 24600,
          o: 1.23428,
          h: 1.23499,
          l: 1.23422,
          c: 1.23486,
          v: 1414540000
        },
        {
          t: 24900,
          o: 1.23487,
          h: 1.23487,
          l: 1.23417,
          c: 1.23421,
          v: 1354300000
        },
        {
          t: 25200,
          o: 1.23422,
          h: 1.23508,
          l: 1.23407,
          c: 1.23486,
          v: 1703030000
        },
        {
          t: 25500,
          o: 1.23486,
          h: 1.23491,
          l: 1.23442,
          c: 1.23455,
          v: 1436010000
        },
        {
          t: 25800,
          o: 1.23455,
          h: 1.23529,
          l: 1.23449,
          c: 1.2351,
          v: 1313880000
        },
        {
          t: 26100,
          o: 1.23512,
          h: 1.23523,
          l: 1.23452,
          c: 1.23481,
          v: 1303530000
        },
        {
          t: 26400,
          o: 1.2348,
          h: 1.23508,
          l: 1.2344,
          c: 1.23446,
          v: 1266680000
        },
        {
          t: 26700,
          o: 1.23446,
          h: 1.23458,
          l: 1.23407,
          c: 1.23419,
          v: 1490060000
        },
        {
          t: 27000,
          o: 1.23419,
          h: 1.23444,
          l: 1.23362,
          c: 1.23382,
          v: 1282020000
        },
        {
          t: 27300,
          o: 1.2338,
          h: 1.23397,
          l: 1.23303,
          c: 1.23307,
          v: 1343370000
        },
        {
          t: 27600,
          o: 1.23304,
          h: 1.23354,
          l: 1.23303,
          c: 1.23345,
          v: 1304730000
        },
        {
          t: 27900,
          o: 1.23345,
          h: 1.23358,
          l: 1.2331,
          c: 1.23341,
          v: 1127099999
        },
        {
          t: 28200,
          o: 1.23343,
          h: 1.23351,
          l: 1.23274,
          c: 1.23277,
          v: 1288520001
        },
        {
          t: 28500,
          o: 1.23279,
          h: 1.23307,
          l: 1.23244,
          c: 1.23249,
          v: 1476190000
        },
        {
          t: 28800,
          o: 1.23248,
          h: 1.23268,
          l: 1.23236,
          c: 1.23254,
          v: 1267410000
        },
        {
          t: 29100,
          o: 1.23254,
          h: 1.23285,
          l: 1.23228,
          c: 1.23261,
          v: 1150510000
        },
        {
          t: 29400,
          o: 1.23261,
          h: 1.23307,
          l: 1.23251,
          c: 1.23297,
          v: 970720000
        },
        {
          t: 29700,
          o: 1.23295,
          h: 1.23297,
          l: 1.23259,
          c: 1.23284,
          v: 837840001
        },
        {
          t: 30000,
          o: 1.23284,
          h: 1.23313,
          l: 1.23284,
          c: 1.23304,
          v: 781120000
        },
        {
          t: 30300,
          o: 1.23304,
          h: 1.23328,
          l: 1.23303,
          c: 1.23328,
          v: 769910000
        },
        {
          t: 30600,
          o: 1.23327,
          h: 1.23337,
          l: 1.23251,
          c: 1.23277,
          v: 1097900000
        },
        {
          t: 30900,
          o: 1.23278,
          h: 1.23306,
          l: 1.23272,
          c: 1.2328,
          v: 782490000
        },
        {
          t: 31200,
          o: 1.2328,
          h: 1.23302,
          l: 1.23258,
          c: 1.23278,
          v: 791850000
        },
        {
          t: 31500,
          o: 1.23278,
          h: 1.23278,
          l: 1.23194,
          c: 1.23198,
          v: 972830000
        },
        {
          t: 31800,
          o: 1.23198,
          h: 1.23228,
          l: 1.23159,
          c: 1.23207,
          v: 1046240000
        },
        {
          t: 32100,
          o: 1.23206,
          h: 1.23207,
          l: 1.23122,
          c: 1.23146,
          v: 1337060000
        },
        {
          t: 32400,
          o: 1.23145,
          h: 1.23156,
          l: 1.23107,
          c: 1.23122,
          v: 940720000
        },
        {
          t: 32700,
          o: 1.23123,
          h: 1.23124,
          l: 1.23075,
          c: 1.23077,
          v: 1234800000
        },
        {
          t: 33000,
          o: 1.23077,
          h: 1.23098,
          l: 1.23074,
          c: 1.23085,
          v: 701000000
        },
        {
          t: 33300,
          o: 1.23089,
          h: 1.23091,
          l: 1.23053,
          c: 1.2308,
          v: 911890000
        },
        {
          t: 33600,
          o: 1.2308,
          h: 1.23083,
          l: 1.23052,
          c: 1.2307,
          v: 803879999
        },
        {
          t: 33900,
          o: 1.2307,
          h: 1.23085,
          l: 1.23012,
          c: 1.23043,
          v: 1008160001
        },
        {
          t: 34200,
          o: 1.2304,
          h: 1.23063,
          l: 1.23002,
          c: 1.23005,
          v: 1181980000
        },
        {
          t: 34500,
          o: 1.23005,
          h: 1.23036,
          l: 1.23005,
          c: 1.23033,
          v: 987280000
        },
        {
          t: 34800,
          o: 1.23034,
          h: 1.23055,
          l: 1.2299,
          c: 1.23011,
          v: 1054850000
        },
        {
          t: 35100,
          o: 1.23011,
          h: 1.23043,
          l: 1.2299,
          c: 1.23025,
          v: 1156740000
        },
        {
          t: 35400,
          o: 1.23027,
          h: 1.23035,
          l: 1.22989,
          c: 1.22995,
          v: 704260000
        },
        {
          t: 35700,
          o: 1.22995,
          h: 1.23017,
          l: 1.22948,
          c: 1.23015,
          v: 1079180000
        },
        {
          t: 36000,
          o: 1.23013,
          h: 1.23024,
          l: 1.22946,
          c: 1.22951,
          v: 985660000
        },
        {
          t: 36300,
          o: 1.22951,
          h: 1.22987,
          l: 1.22946,
          c: 1.2297,
          v: 832440000
        },
        {
          t: 36600,
          o: 1.22967,
          h: 1.22977,
          l: 1.22951,
          c: 1.22954,
          v: 740470000
        },
        {
          t: 36900,
          o: 1.22954,
          h: 1.22984,
          l: 1.22934,
          c: 1.2294,
          v: 726160000
        },
        {
          t: 37200,
          o: 1.22939,
          h: 1.22971,
          l: 1.2293,
          c: 1.22952,
          v: 685580000
        },
        {
          t: 37500,
          o: 1.22952,
          h: 1.22996,
          l: 1.22951,
          c: 1.22992,
          v: 860270000
        },
        {
          t: 37800,
          o: 1.22992,
          h: 1.23029,
          l: 1.22986,
          c: 1.23025,
          v: 914830000
        },
        {
          t: 38100,
          o: 1.23024,
          h: 1.23024,
          l: 1.22991,
          c: 1.23,
          v: 814750000
        },
        {
          t: 38400,
          o: 1.23,
          h: 1.2301,
          l: 1.22975,
          c: 1.22996,
          v: 902510000
        },
        {
          t: 38700,
          o: 1.22999,
          h: 1.23006,
          l: 1.22976,
          c: 1.22988,
          v: 806230000
        },
        {
          t: 39000,
          o: 1.22988,
          h: 1.22994,
          l: 1.22952,
          c: 1.22965,
          v: 758130000
        },
        {
          t: 39300,
          o: 1.22965,
          h: 1.23026,
          l: 1.22952,
          c: 1.23024,
          v: 880520000
        },
        {
          t: 39600,
          o: 1.23025,
          h: 1.23026,
          l: 1.22954,
          c: 1.22976,
          v: 1108980000
        },
        {
          t: 39900,
          o: 1.22976,
          h: 1.23072,
          l: 1.22962,
          c: 1.23067,
          v: 986270000
        },
        {
          t: 40200,
          o: 1.23069,
          h: 1.23086,
          l: 1.23059,
          c: 1.23086,
          v: 1000240000
        },
        {
          t: 40500,
          o: 1.23085,
          h: 1.23107,
          l: 1.23059,
          c: 1.23074,
          v: 958310000
        },
        {
          t: 40800,
          o: 1.23073,
          h: 1.23081,
          l: 1.23042,
          c: 1.23051,
          v: 811400000
        },
        {
          t: 41100,
          o: 1.23053,
          h: 1.2309,
          l: 1.23048,
          c: 1.23088,
          v: 641170000
        },
        {
          t: 41400,
          o: 1.23088,
          h: 1.23111,
          l: 1.2308,
          c: 1.23107,
          v: 580580000
        },
        {
          t: 41700,
          o: 1.23107,
          h: 1.23112,
          l: 1.2305,
          c: 1.2305,
          v: 857160000
        },
        {
          t: 42000,
          o: 1.23051,
          h: 1.23058,
          l: 1.23004,
          c: 1.23015,
          v: 929480000
        },
        {
          t: 42300,
          o: 1.23014,
          h: 1.2307,
          l: 1.23014,
          c: 1.23064,
          v: 394330000
        },
        {
          t: 42600,
          o: 1.23064,
          h: 1.23079,
          l: 1.23051,
          c: 1.23067,
          v: 674730000
        },
        {
          t: 42900,
          o: 1.2307,
          h: 1.23077,
          l: 1.23002,
          c: 1.23042,
          v: 994920000
        },
        {
          t: 43200,
          o: 1.23043,
          h: 1.23063,
          l: 1.23015,
          c: 1.23047,
          v: 875730001
        },
        {
          t: 43500,
          o: 1.23048,
          h: 1.23076,
          l: 1.23041,
          c: 1.23052,
          v: 828510000
        },
        {
          t: 43800,
          o: 1.23053,
          h: 1.23104,
          l: 1.23051,
          c: 1.23093,
          v: 946720000
        },
        {
          t: 44100,
          o: 1.23095,
          h: 1.23114,
          l: 1.23083,
          c: 1.23092,
          v: 962770000
        },
        {
          t: 44400,
          o: 1.23092,
          h: 1.23119,
          l: 1.23085,
          c: 1.2309,
          v: 961960000
        },
        {
          t: 44700,
          o: 1.23091,
          h: 1.23108,
          l: 1.23079,
          c: 1.23095,
          v: 801470000
        },
        {
          t: 45000,
          o: 1.23082,
          h: 1.23083,
          l: 1.2302,
          c: 1.23026,
          v: 1541430000
        },
        {
          t: 45300,
          o: 1.23025,
          h: 1.23083,
          l: 1.23025,
          c: 1.23073,
          v: 1134210000
        },
        {
          t: 45600,
          o: 1.23073,
          h: 1.23074,
          l: 1.23024,
          c: 1.23029,
          v: 929920000
        },
        {
          t: 45900,
          o: 1.23029,
          h: 1.23033,
          l: 1.22959,
          c: 1.22989,
          v: 1310260000
        },
        {
          t: 46200,
          o: 1.2299,
          h: 1.22996,
          l: 1.2295,
          c: 1.22961,
          v: 1062620000
        },
        {
          t: 46500,
          o: 1.22964,
          h: 1.22964,
          l: 1.22899,
          c: 1.22904,
          v: 1263250000
        },
        {
          t: 46800,
          o: 1.22906,
          h: 1.22959,
          l: 1.22893,
          c: 1.22941,
          v: 1359970001
        },
        {
          t: 47100,
          o: 1.22941,
          h: 1.22941,
          l: 1.22904,
          c: 1.22916,
          v: 1063210000
        },
        {
          t: 47400,
          o: 1.22916,
          h: 1.22916,
          l: 1.22859,
          c: 1.22862,
          v: 1177200001
        },
        {
          t: 47700,
          o: 1.22862,
          h: 1.2289,
          l: 1.22853,
          c: 1.22875,
          v: 1144010000
        },
        {
          t: 48000,
          o: 1.22878,
          h: 1.2289,
          l: 1.22794,
          c: 1.22803,
          v: 1200690000
        },
        {
          t: 48300,
          o: 1.22803,
          h: 1.22842,
          l: 1.22789,
          c: 1.22824,
          v: 1681670000
        },
        {
          t: 48600,
          o: 1.22825,
          h: 1.22853,
          l: 1.22799,
          c: 1.22804,
          v: 1294099999
        },
        {
          t: 48900,
          o: 1.22803,
          h: 1.22852,
          l: 1.22799,
          c: 1.22805,
          v: 1322320000
        },
        {
          t: 49200,
          o: 1.22804,
          h: 1.22815,
          l: 1.22739,
          c: 1.22746,
          v: 1352190000
        },
        {
          t: 49500,
          o: 1.22745,
          h: 1.22754,
          l: 1.22697,
          c: 1.22711,
          v: 1161860000
        },
        {
          t: 49800,
          o: 1.22711,
          h: 1.22787,
          l: 1.22702,
          c: 1.22708,
          v: 1380100000
        },
        {
          t: 50100,
          o: 1.22709,
          h: 1.22719,
          l: 1.2266,
          c: 1.22666,
          v: 1083270000
        },
        {
          t: 50400,
          o: 1.22666,
          h: 1.22709,
          l: 1.22659,
          c: 1.22704,
          v: 1084450000
        },
        {
          t: 50700,
          o: 1.22704,
          h: 1.22726,
          l: 1.22695,
          c: 1.22721,
          v: 1051799999
        },
        {
          t: 51000,
          o: 1.22721,
          h: 1.2277,
          l: 1.22696,
          c: 1.22754,
          v: 1238350000
        },
        {
          t: 51300,
          o: 1.22755,
          h: 1.22772,
          l: 1.22698,
          c: 1.227,
          v: 1076720000
        },
        {
          t: 51600,
          o: 1.22699,
          h: 1.22752,
          l: 1.2268,
          c: 1.22752,
          v: 1029760000
        },
        {
          t: 51900,
          o: 1.22752,
          h: 1.22752,
          l: 1.22653,
          c: 1.22657,
          v: 1025980000
        },
        {
          t: 52200,
          o: 1.22658,
          h: 1.227,
          l: 1.22649,
          c: 1.227,
          v: 1261860000
        },
        {
          t: 52500,
          o: 1.22701,
          h: 1.22719,
          l: 1.22667,
          c: 1.22696,
          v: 817800000
        },
        {
          t: 52800,
          o: 1.22698,
          h: 1.22715,
          l: 1.22656,
          c: 1.2267,
          v: 1081260000
        },
        {
          t: 53100,
          o: 1.22669,
          h: 1.22724,
          l: 1.2266,
          c: 1.22694,
          v: 961200000
        },
        {
          t: 53400,
          o: 1.22698,
          h: 1.22732,
          l: 1.22499,
          c: 1.22642,
          v: 2270030000
        },
        {
          t: 53700,
          o: 1.22641,
          h: 1.22795,
          l: 1.22626,
          c: 1.22787,
          v: 1695840000
        },
        {
          t: 54000,
          o: 1.22789,
          h: 1.22825,
          l: 1.22761,
          c: 1.22796,
          v: 1497470000
        },
        {
          t: 54300,
          o: 1.22796,
          h: 1.2281,
          l: 1.22706,
          c: 1.22738,
          v: 1470870000
        },
        {
          t: 54600,
          o: 1.22737,
          h: 1.2276,
          l: 1.22728,
          c: 1.22743,
          v: 1210829999
        },
        {
          t: 54900,
          o: 1.22743,
          h: 1.2279,
          l: 1.22739,
          c: 1.22768,
          v: 1177910000
        },
        {
          t: 55200,
          o: 1.22768,
          h: 1.22772,
          l: 1.22708,
          c: 1.22733,
          v: 1094040000
        },
        {
          t: 55500,
          o: 1.22732,
          h: 1.22778,
          l: 1.22726,
          c: 1.22748,
          v: 1202790000
        },
        {
          t: 55800,
          o: 1.22748,
          h: 1.22816,
          l: 1.22747,
          c: 1.22811,
          v: 1005760000
        },
        {
          t: 56100,
          o: 1.22812,
          h: 1.22812,
          l: 1.22763,
          c: 1.22782,
          v: 800540000
        },
        {
          t: 56400,
          o: 1.22779,
          h: 1.22809,
          l: 1.22773,
          c: 1.22799,
          v: 951230000
        },
        {
          t: 56700,
          o: 1.22799,
          h: 1.22817,
          l: 1.22783,
          c: 1.22797,
          v: 851510000
        },
        {
          t: 57000,
          o: 1.22795,
          h: 1.22818,
          l: 1.2278,
          c: 1.22798,
          v: 863890000
        },
        {
          t: 57300,
          o: 1.228,
          h: 1.22884,
          l: 1.228,
          c: 1.22816,
          v: 1407630001
        },
        {
          t: 57600,
          o: 1.22817,
          h: 1.22854,
          l: 1.22812,
          c: 1.22846,
          v: 1108210000
        },
        {
          t: 57900,
          o: 1.22847,
          h: 1.2288,
          l: 1.22836,
          c: 1.22862,
          v: 720650000
        },
        {
          t: 58200,
          o: 1.22862,
          h: 1.22865,
          l: 1.2285,
          c: 1.22863,
          v: 634380000
        },
        {
          t: 58500,
          o: 1.22863,
          h: 1.22884,
          l: 1.22851,
          c: 1.22883,
          v: 929080000
        },
        {
          t: 58800,
          o: 1.22883,
          h: 1.22904,
          l: 1.22841,
          c: 1.22842,
          v: 966040001
        },
        {
          t: 59100,
          o: 1.22842,
          h: 1.22849,
          l: 1.22804,
          c: 1.22819,
          v: 1051690000
        },
        {
          t: 59400,
          o: 1.22819,
          h: 1.22849,
          l: 1.22809,
          c: 1.22823,
          v: 858280000
        },
        {
          t: 59700,
          o: 1.22827,
          h: 1.22832,
          l: 1.22806,
          c: 1.2283,
          v: 910890000
        },
        {
          t: 60000,
          o: 1.2283,
          h: 1.22882,
          l: 1.228,
          c: 1.22866,
          v: 1024800000
        },
        {
          t: 60300,
          o: 1.22866,
          h: 1.22932,
          l: 1.22862,
          c: 1.22913,
          v: 788750000
        },
        {
          t: 60600,
          o: 1.22912,
          h: 1.22951,
          l: 1.22909,
          c: 1.22951,
          v: 983690000
        },
        {
          t: 60900,
          o: 1.22949,
          h: 1.23001,
          l: 1.22946,
          c: 1.22966,
          v: 1199380000
        },
        {
          t: 61200,
          o: 1.22965,
          h: 1.22966,
          l: 1.22917,
          c: 1.22921,
          v: 1061160000
        },
        {
          t: 61500,
          o: 1.22922,
          h: 1.22934,
          l: 1.22917,
          c: 1.22922,
          v: 782720000
        },
        {
          t: 61800,
          o: 1.22923,
          h: 1.22924,
          l: 1.229,
          c: 1.229,
          v: 656400000
        },
        {
          t: 62100,
          o: 1.229,
          h: 1.22932,
          l: 1.22896,
          c: 1.22912,
          v: 849400001
        },
        {
          t: 62400,
          o: 1.22913,
          h: 1.22922,
          l: 1.22896,
          c: 1.22911,
          v: 852680000
        },
        {
          t: 62700,
          o: 1.22908,
          h: 1.22908,
          l: 1.2288,
          c: 1.22898,
          v: 694200000
        },
        {
          t: 63000,
          o: 1.22898,
          h: 1.22918,
          l: 1.22892,
          c: 1.22907,
          v: 480110000
        },
        {
          t: 63300,
          o: 1.22907,
          h: 1.22915,
          l: 1.22891,
          c: 1.22908,
          v: 365440000
        },
        {
          t: 63600,
          o: 1.22908,
          h: 1.2293,
          l: 1.22899,
          c: 1.22899,
          v: 643370000
        },
        {
          t: 63900,
          o: 1.229,
          h: 1.22922,
          l: 1.22899,
          c: 1.22906,
          v: 429150000
        },
        {
          t: 64200,
          o: 1.22906,
          h: 1.22917,
          l: 1.2289,
          c: 1.22892,
          v: 512330000
        },
        {
          t: 64500,
          o: 1.22892,
          h: 1.22897,
          l: 1.22864,
          c: 1.22866,
          v: 602650000
        },
        {
          t: 64800,
          o: 1.22866,
          h: 1.2288,
          l: 1.22852,
          c: 1.22879,
          v: 649050000
        },
        {
          t: 65100,
          o: 1.22879,
          h: 1.22879,
          l: 1.22862,
          c: 1.22863,
          v: 614600000
        },
        {
          t: 65400,
          o: 1.22863,
          h: 1.22874,
          l: 1.22849,
          c: 1.22857,
          v: 511160000
        },
        {
          t: 65700,
          o: 1.22857,
          h: 1.22868,
          l: 1.22844,
          c: 1.22865,
          v: 487290000
        },
        {
          t: 66000,
          o: 1.22866,
          h: 1.22866,
          l: 1.22845,
          c: 1.22855,
          v: 688460000
        },
        {
          t: 66300,
          o: 1.22856,
          h: 1.22892,
          l: 1.22843,
          c: 1.22872,
          v: 622810000
        },
        {
          t: 66600,
          o: 1.22875,
          h: 1.22883,
          l: 1.22851,
          c: 1.22854,
          v: 416770000
        },
        {
          t: 66900,
          o: 1.22854,
          h: 1.22869,
          l: 1.22854,
          c: 1.22868,
          v: 510820000
        },
        {
          t: 67200,
          o: 1.22868,
          h: 1.22883,
          l: 1.22862,
          c: 1.22874,
          v: 483200000
        },
        {
          t: 67500,
          o: 1.22873,
          h: 1.22894,
          l: 1.22872,
          c: 1.2289,
          v: 540810001
        },
        {
          t: 67800,
          o: 1.22889,
          h: 1.22898,
          l: 1.22887,
          c: 1.22888,
          v: 479250000
        },
        {
          t: 68100,
          o: 1.22889,
          h: 1.22896,
          l: 1.22818,
          c: 1.22823,
          v: 982840000
        },
        {
          t: 68400,
          o: 1.22825,
          h: 1.22845,
          l: 1.22816,
          c: 1.22832,
          v: 678010000
        },
        {
          t: 68700,
          o: 1.22831,
          h: 1.22836,
          l: 1.22821,
          c: 1.22822,
          v: 825440000
        },
        {
          t: 69000,
          o: 1.22822,
          h: 1.2283,
          l: 1.22808,
          c: 1.22812,
          v: 729820000
        },
        {
          t: 69300,
          o: 1.22814,
          h: 1.22821,
          l: 1.22809,
          c: 1.22819,
          v: 424750000
        },
        {
          t: 69600,
          o: 1.22817,
          h: 1.22818,
          l: 1.22801,
          c: 1.22807,
          v: 473040000
        },
        {
          t: 69900,
          o: 1.22808,
          h: 1.22822,
          l: 1.22806,
          c: 1.22812,
          v: 699110000
        },
        {
          t: 70200,
          o: 1.22812,
          h: 1.22835,
          l: 1.22807,
          c: 1.22819,
          v: 622600000
        },
        {
          t: 70500,
          o: 1.22819,
          h: 1.22829,
          l: 1.22809,
          c: 1.22829,
          v: 597290000
        },
        {
          t: 70800,
          o: 1.22827,
          h: 1.22827,
          l: 1.22807,
          c: 1.22807,
          v: 652580000
        },
        {
          t: 71100,
          o: 1.22808,
          h: 1.22835,
          l: 1.22807,
          c: 1.22828,
          v: 851660000
        },
        {
          t: 71400,
          o: 1.22828,
          h: 1.22833,
          l: 1.22817,
          c: 1.22827,
          v: 597000000
        },
        {
          t: 71700,
          o: 1.22827,
          h: 1.2284,
          l: 1.22806,
          c: 1.22812,
          v: 920980001
        },
        {
          t: 72000,
          o: 1.22812,
          h: 1.22828,
          l: 1.22799,
          c: 1.22816,
          v: 467710000
        },
        {
          t: 72300,
          o: 1.22817,
          h: 1.22834,
          l: 1.22817,
          c: 1.22827,
          v: 194300000
        },
        {
          t: 72600,
          o: 1.22829,
          h: 1.22841,
          l: 1.22825,
          c: 1.22838,
          v: 289399999
        },
        {
          t: 72900,
          o: 1.22839,
          h: 1.22868,
          l: 1.22839,
          c: 1.22861,
          v: 500810000
        },
        {
          t: 73200,
          o: 1.22861,
          h: 1.22889,
          l: 1.22861,
          c: 1.22883,
          v: 428620000
        },
        {
          t: 73500,
          o: 1.22882,
          h: 1.22895,
          l: 1.22869,
          c: 1.22887,
          v: 370840000
        },
        {
          t: 73800,
          o: 1.22888,
          h: 1.2289,
          l: 1.22881,
          c: 1.22885,
          v: 259020000
        },
        {
          t: 74100,
          o: 1.22885,
          h: 1.22886,
          l: 1.22876,
          c: 1.22883,
          v: 217860000
        },
        {
          t: 74400,
          o: 1.22881,
          h: 1.22929,
          l: 1.22881,
          c: 1.22915,
          v: 658800000
        },
        {
          t: 74700,
          o: 1.22914,
          h: 1.22925,
          l: 1.22883,
          c: 1.22885,
          v: 541660000
        },
        {
          t: 75000,
          o: 1.22883,
          h: 1.22901,
          l: 1.22856,
          c: 1.22894,
          v: 374240000
        },
        {
          t: 75300,
          o: 1.22893,
          h: 1.229,
          l: 1.22846,
          c: 1.22846,
          v: 304240000
        }
      ]
    },
    {
      d: 1524355200,
      b: [
        {
          t: 75600,
          o: 1.22699,
          h: 1.22787,
          l: 1.22699,
          c: 1.22768,
          v: 39490000
        },
        {
          t: 75900,
          o: 1.22767,
          h: 1.22792,
          l: 1.22752,
          c: 1.22785,
          v: 179930000
        },
        {
          t: 76200,
          o: 1.22785,
          h: 1.22835,
          l: 1.22781,
          c: 1.22812,
          v: 128740000
        },
        {
          t: 76500,
          o: 1.22812,
          h: 1.22826,
          l: 1.22809,
          c: 1.22819,
          v: 110750000
        },
        {
          t: 76800,
          o: 1.22817,
          h: 1.22836,
          l: 1.22817,
          c: 1.22822,
          v: 19690000
        },
        {
          t: 77100,
          o: 1.22819,
          h: 1.22824,
          l: 1.22809,
          c: 1.2282,
          v: 48940000
        },
        {
          t: 77400,
          o: 1.22814,
          h: 1.22825,
          l: 1.22802,
          c: 1.22819,
          v: 142840000
        },
        {
          t: 77700,
          o: 1.22817,
          h: 1.22823,
          l: 1.22816,
          c: 1.22818,
          v: 105560000
        },
        {
          t: 78000,
          o: 1.22823,
          h: 1.22833,
          l: 1.2282,
          c: 1.22824,
          v: 63070000
        },
        {
          t: 78300,
          o: 1.22825,
          h: 1.22844,
          l: 1.22824,
          c: 1.22842,
          v: 59350000
        },
        {
          t: 78600,
          o: 1.22839,
          h: 1.22845,
          l: 1.22829,
          c: 1.22832,
          v: 79130000
        },
        {
          t: 78900,
          o: 1.22832,
          h: 1.22841,
          l: 1.22822,
          c: 1.22825,
          v: 46900000
        },
        {
          t: 79200,
          o: 1.22824,
          h: 1.22842,
          l: 1.22769,
          c: 1.22842,
          v: 690480000
        },
        {
          t: 79500,
          o: 1.22842,
          h: 1.22876,
          l: 1.22824,
          c: 1.22854,
          v: 1107450000
        },
        {
          t: 79800,
          o: 1.22853,
          h: 1.22872,
          l: 1.22836,
          c: 1.22864,
          v: 1185340000
        },
        {
          t: 80100,
          o: 1.22864,
          h: 1.2288,
          l: 1.22839,
          c: 1.22842,
          v: 501420000
        },
        {
          t: 80400,
          o: 1.22842,
          h: 1.22848,
          l: 1.22838,
          c: 1.22842,
          v: 193080000
        },
        {
          t: 80700,
          o: 1.22843,
          h: 1.22843,
          l: 1.22827,
          c: 1.22837,
          v: 281320000
        },
        {
          t: 81000,
          o: 1.22838,
          h: 1.22843,
          l: 1.22828,
          c: 1.22842,
          v: 182040000
        },
        {
          t: 81300,
          o: 1.22843,
          h: 1.22843,
          l: 1.22809,
          c: 1.22818,
          v: 212200000
        },
        {
          t: 81600,
          o: 1.22819,
          h: 1.22823,
          l: 1.22812,
          c: 1.22823,
          v: 162770000
        },
        {
          t: 81900,
          o: 1.22823,
          h: 1.22823,
          l: 1.22802,
          c: 1.22806,
          v: 140260000
        },
        {
          t: 82200,
          o: 1.22806,
          h: 1.22807,
          l: 1.22796,
          c: 1.228,
          v: 194580000
        },
        {
          t: 82500,
          o: 1.22801,
          h: 1.22808,
          l: 1.2279,
          c: 1.22796,
          v: 268540000
        },
        {
          t: 82800,
          o: 1.228,
          h: 1.22806,
          l: 1.22787,
          c: 1.22791,
          v: 200030000
        },
        {
          t: 83100,
          o: 1.22791,
          h: 1.22818,
          l: 1.22791,
          c: 1.22806,
          v: 217380000
        },
        {
          t: 83400,
          o: 1.22806,
          h: 1.22817,
          l: 1.22802,
          c: 1.22811,
          v: 419610000
        },
        {
          t: 83700,
          o: 1.22811,
          h: 1.22825,
          l: 1.22797,
          c: 1.22806,
          v: 408070000
        },
        {
          t: 84000,
          o: 1.22806,
          h: 1.22812,
          l: 1.22796,
          c: 1.22796,
          v: 371330000
        },
        {
          t: 84300,
          o: 1.22797,
          h: 1.2282,
          l: 1.22797,
          c: 1.22807,
          v: 269720000
        },
        {
          t: 84600,
          o: 1.22807,
          h: 1.22807,
          l: 1.22767,
          c: 1.22772,
          v: 395740000
        },
        {
          t: 84900,
          o: 1.22771,
          h: 1.22781,
          l: 1.22751,
          c: 1.22774,
          v: 383930000
        },
        {
          t: 85200,
          o: 1.22774,
          h: 1.22778,
          l: 1.22762,
          c: 1.22764,
          v: 311310000
        },
        {
          t: 85500,
          o: 1.22763,
          h: 1.22766,
          l: 1.22752,
          c: 1.22757,
          v: 357030000
        },
        {
          t: 85800,
          o: 1.22756,
          h: 1.22767,
          l: 1.22729,
          c: 1.22731,
          v: 464740000
        },
        {
          t: 86100,
          o: 1.22733,
          h: 1.22746,
          l: 1.22727,
          c: 1.22737,
          v: 573690000
        }
      ]
    },
    {
      d: 1524441600,
      b: [
        {
          t: 0,
          o: 1.22738,
          h: 1.22743,
          l: 1.22706,
          c: 1.22708,
          v: 823110000
        },
        {
          t: 300,
          o: 1.22707,
          h: 1.22714,
          l: 1.22687,
          c: 1.22691,
          v: 849910000
        },
        {
          t: 600,
          o: 1.2269,
          h: 1.22717,
          l: 1.22681,
          c: 1.2271,
          v: 591260000
        },
        {
          t: 900,
          o: 1.2271,
          h: 1.22751,
          l: 1.2271,
          c: 1.22745,
          v: 614790000
        },
        {
          t: 1200,
          o: 1.22745,
          h: 1.22779,
          l: 1.22739,
          c: 1.22775,
          v: 767450000
        },
        {
          t: 1500,
          o: 1.22774,
          h: 1.2279,
          l: 1.22763,
          c: 1.22768,
          v: 570900000
        },
        {
          t: 1800,
          o: 1.22769,
          h: 1.22769,
          l: 1.22744,
          c: 1.22759,
          v: 409000000
        },
        {
          t: 2100,
          o: 1.22759,
          h: 1.22768,
          l: 1.22733,
          c: 1.22733,
          v: 413680000
        },
        {
          t: 2400,
          o: 1.22734,
          h: 1.22744,
          l: 1.2272,
          c: 1.22726,
          v: 586270000
        },
        {
          t: 2700,
          o: 1.22726,
          h: 1.22728,
          l: 1.22712,
          c: 1.22722,
          v: 479570000
        },
        {
          t: 3000,
          o: 1.22723,
          h: 1.22749,
          l: 1.22702,
          c: 1.22709,
          v: 653130000
        },
        {
          t: 3300,
          o: 1.22707,
          h: 1.2273,
          l: 1.22699,
          c: 1.22706,
          v: 713400000
        },
        {
          t: 3600,
          o: 1.22707,
          h: 1.22709,
          l: 1.22656,
          c: 1.22673,
          v: 959180000
        },
        {
          t: 3900,
          o: 1.22674,
          h: 1.22692,
          l: 1.22667,
          c: 1.22687,
          v: 683200000
        },
        {
          t: 4200,
          o: 1.22688,
          h: 1.22695,
          l: 1.22673,
          c: 1.22674,
          v: 586020000
        },
        {
          t: 4500,
          o: 1.22674,
          h: 1.22676,
          l: 1.2265,
          c: 1.22658,
          v: 629500000
        },
        {
          t: 4800,
          o: 1.22658,
          h: 1.22673,
          l: 1.22648,
          c: 1.22655,
          v: 747370000
        },
        {
          t: 5100,
          o: 1.22653,
          h: 1.22696,
          l: 1.22653,
          c: 1.22668,
          v: 756040000
        },
        {
          t: 5400,
          o: 1.22668,
          h: 1.2273,
          l: 1.22668,
          c: 1.22729,
          v: 781100000
        },
        {
          t: 5700,
          o: 1.22729,
          h: 1.2276,
          l: 1.22723,
          c: 1.22749,
          v: 638040000
        },
        {
          t: 6000,
          o: 1.22749,
          h: 1.22785,
          l: 1.22748,
          c: 1.22763,
          v: 802530000
        },
        {
          t: 6300,
          o: 1.22762,
          h: 1.22776,
          l: 1.22754,
          c: 1.22766,
          v: 677880000
        },
        {
          t: 6600,
          o: 1.22766,
          h: 1.22786,
          l: 1.22755,
          c: 1.22773,
          v: 840110000
        },
        {
          t: 6900,
          o: 1.22772,
          h: 1.22794,
          l: 1.22772,
          c: 1.22786,
          v: 666780000
        },
        {
          t: 7200,
          o: 1.22784,
          h: 1.22784,
          l: 1.22741,
          c: 1.22748,
          v: 676060000
        },
        {
          t: 7500,
          o: 1.22747,
          h: 1.22753,
          l: 1.22736,
          c: 1.22748,
          v: 638370000
        },
        {
          t: 7800,
          o: 1.22747,
          h: 1.22758,
          l: 1.22746,
          c: 1.22747,
          v: 572800000
        },
        {
          t: 8100,
          o: 1.22747,
          h: 1.22753,
          l: 1.22735,
          c: 1.22743,
          v: 517370000
        },
        {
          t: 8400,
          o: 1.22742,
          h: 1.22748,
          l: 1.22736,
          c: 1.22746,
          v: 659430000
        },
        {
          t: 8700,
          o: 1.22747,
          h: 1.22753,
          l: 1.22742,
          c: 1.22743,
          v: 536500000
        },
        {
          t: 9000,
          o: 1.22743,
          h: 1.22754,
          l: 1.22737,
          c: 1.22754,
          v: 698700000
        },
        {
          t: 9300,
          o: 1.22753,
          h: 1.22772,
          l: 1.22741,
          c: 1.22768,
          v: 478880000
        },
        {
          t: 9600,
          o: 1.22767,
          h: 1.22771,
          l: 1.22758,
          c: 1.22767,
          v: 430810000
        },
        {
          t: 9900,
          o: 1.22767,
          h: 1.2277,
          l: 1.22757,
          c: 1.22763,
          v: 506080000
        },
        {
          t: 10200,
          o: 1.22761,
          h: 1.22763,
          l: 1.22751,
          c: 1.22763,
          v: 539120000
        },
        {
          t: 10500,
          o: 1.22762,
          h: 1.22769,
          l: 1.2275,
          c: 1.22752,
          v: 443990000
        },
        {
          t: 10800,
          o: 1.22753,
          h: 1.22754,
          l: 1.22726,
          c: 1.22734,
          v: 752059999
        },
        {
          t: 11100,
          o: 1.22735,
          h: 1.22746,
          l: 1.2273,
          c: 1.22737,
          v: 576870000
        },
        {
          t: 11400,
          o: 1.22737,
          h: 1.22748,
          l: 1.22728,
          c: 1.22728,
          v: 493830000
        },
        {
          t: 11700,
          o: 1.22728,
          h: 1.22737,
          l: 1.22722,
          c: 1.22731,
          v: 521130000
        },
        {
          t: 12000,
          o: 1.22731,
          h: 1.22737,
          l: 1.22725,
          c: 1.22732,
          v: 474630000
        },
        {
          t: 12300,
          o: 1.22735,
          h: 1.22739,
          l: 1.22731,
          c: 1.22733,
          v: 345680000
        },
        {
          t: 12600,
          o: 1.22733,
          h: 1.22742,
          l: 1.22732,
          c: 1.22739,
          v: 415160000
        },
        {
          t: 12900,
          o: 1.22738,
          h: 1.22741,
          l: 1.22726,
          c: 1.22737,
          v: 303880000
        },
        {
          t: 13200,
          o: 1.22737,
          h: 1.22743,
          l: 1.22736,
          c: 1.22736,
          v: 250930000
        },
        {
          t: 13500,
          o: 1.22737,
          h: 1.22738,
          l: 1.22716,
          c: 1.22717,
          v: 346680000
        },
        {
          t: 13800,
          o: 1.22717,
          h: 1.22724,
          l: 1.22711,
          c: 1.22713,
          v: 438560000
        },
        {
          t: 14100,
          o: 1.22713,
          h: 1.22722,
          l: 1.22711,
          c: 1.22716,
          v: 480470000
        },
        {
          t: 14400,
          o: 1.22716,
          h: 1.22716,
          l: 1.22704,
          c: 1.22716,
          v: 454160001
        },
        {
          t: 14700,
          o: 1.22714,
          h: 1.22717,
          l: 1.22705,
          c: 1.22712,
          v: 344680000
        },
        {
          t: 15000,
          o: 1.22711,
          h: 1.22726,
          l: 1.22707,
          c: 1.22718,
          v: 368740000
        },
        {
          t: 15300,
          o: 1.22717,
          h: 1.22723,
          l: 1.22704,
          c: 1.22712,
          v: 397740000
        },
        {
          t: 15600,
          o: 1.22712,
          h: 1.22723,
          l: 1.22711,
          c: 1.22718,
          v: 315340000
        },
        {
          t: 15900,
          o: 1.22718,
          h: 1.22723,
          l: 1.22717,
          c: 1.22718,
          v: 321220000
        },
        {
          t: 16200,
          o: 1.22717,
          h: 1.22724,
          l: 1.22696,
          c: 1.22722,
          v: 646610000
        },
        {
          t: 16500,
          o: 1.22723,
          h: 1.22726,
          l: 1.22701,
          c: 1.22703,
          v: 396120000
        },
        {
          t: 16800,
          o: 1.22703,
          h: 1.22711,
          l: 1.22703,
          c: 1.22706,
          v: 341050000
        },
        {
          t: 17100,
          o: 1.22707,
          h: 1.22709,
          l: 1.22699,
          c: 1.22705,
          v: 249370001
        },
        {
          t: 17400,
          o: 1.22704,
          h: 1.22727,
          l: 1.227,
          c: 1.22727,
          v: 431160000
        },
        {
          t: 17700,
          o: 1.22727,
          h: 1.22727,
          l: 1.22716,
          c: 1.22717,
          v: 418060000
        },
        {
          t: 18000,
          o: 1.22717,
          h: 1.22728,
          l: 1.2271,
          c: 1.22724,
          v: 446490000
        },
        {
          t: 18300,
          o: 1.22725,
          h: 1.2275,
          l: 1.22723,
          c: 1.22749,
          v: 301560000
        },
        {
          t: 18600,
          o: 1.22749,
          h: 1.2275,
          l: 1.22738,
          c: 1.22744,
          v: 314770000
        },
        {
          t: 18900,
          o: 1.22743,
          h: 1.22744,
          l: 1.22728,
          c: 1.22732,
          v: 434750000
        },
        {
          t: 19200,
          o: 1.22732,
          h: 1.22751,
          l: 1.22731,
          c: 1.22734,
          v: 524800000
        },
        {
          t: 19500,
          o: 1.22734,
          h: 1.22742,
          l: 1.22734,
          c: 1.22738,
          v: 300850000
        },
        {
          t: 19800,
          o: 1.22738,
          h: 1.22748,
          l: 1.22663,
          c: 1.22667,
          v: 1032510000
        },
        {
          t: 20100,
          o: 1.22667,
          h: 1.22694,
          l: 1.22656,
          c: 1.22693,
          v: 517480000
        },
        {
          t: 20400,
          o: 1.22691,
          h: 1.22708,
          l: 1.22688,
          c: 1.22694,
          v: 374150000
        },
        {
          t: 20700,
          o: 1.22694,
          h: 1.22705,
          l: 1.2267,
          c: 1.2267,
          v: 537230000
        },
        {
          t: 21000,
          o: 1.22671,
          h: 1.22689,
          l: 1.22664,
          c: 1.22688,
          v: 583580000
        },
        {
          t: 21300,
          o: 1.22688,
          h: 1.22709,
          l: 1.22688,
          c: 1.22708,
          v: 504760000
        },
        {
          t: 21600,
          o: 1.22708,
          h: 1.22715,
          l: 1.22692,
          c: 1.22703,
          v: 660980000
        },
        {
          t: 21900,
          o: 1.22703,
          h: 1.22783,
          l: 1.22701,
          c: 1.22763,
          v: 1028570001
        },
        {
          t: 22200,
          o: 1.22763,
          h: 1.22783,
          l: 1.22747,
          c: 1.22783,
          v: 873710000
        },
        {
          t: 22500,
          o: 1.22784,
          h: 1.22798,
          l: 1.22755,
          c: 1.22774,
          v: 1235250000
        },
        {
          t: 22800,
          o: 1.22773,
          h: 1.22803,
          l: 1.22747,
          c: 1.22762,
          v: 1193330000
        },
        {
          t: 23100,
          o: 1.22762,
          h: 1.22769,
          l: 1.22723,
          c: 1.22743,
          v: 1138510000
        },
        {
          t: 23400,
          o: 1.22742,
          h: 1.22771,
          l: 1.22721,
          c: 1.22732,
          v: 1237480000
        },
        {
          t: 23700,
          o: 1.22731,
          h: 1.22731,
          l: 1.22687,
          c: 1.22699,
          v: 1214070000
        },
        {
          t: 24000,
          o: 1.22702,
          h: 1.22739,
          l: 1.22702,
          c: 1.22709,
          v: 738270000
        },
        {
          t: 24300,
          o: 1.22708,
          h: 1.22742,
          l: 1.22682,
          c: 1.22729,
          v: 1131319998
        },
        {
          t: 24600,
          o: 1.22729,
          h: 1.22772,
          l: 1.22712,
          c: 1.22769,
          v: 829790000
        },
        {
          t: 24900,
          o: 1.22769,
          h: 1.22785,
          l: 1.22736,
          c: 1.22736,
          v: 1105540000
        },
        {
          t: 25200,
          o: 1.22736,
          h: 1.22865,
          l: 1.22736,
          c: 1.22852,
          v: 1930950001
        },
        {
          t: 25500,
          o: 1.22851,
          h: 1.22894,
          l: 1.22844,
          c: 1.22845,
          v: 1681830000
        },
        {
          t: 25800,
          o: 1.22844,
          h: 1.2285,
          l: 1.22803,
          c: 1.22821,
          v: 1554960000
        },
        {
          t: 26100,
          o: 1.22822,
          h: 1.22881,
          l: 1.22811,
          c: 1.22824,
          v: 1740750000
        },
        {
          t: 26400,
          o: 1.22825,
          h: 1.22838,
          l: 1.22718,
          c: 1.22729,
          v: 1692700000
        },
        {
          t: 26700,
          o: 1.22728,
          h: 1.2276,
          l: 1.22701,
          c: 1.22729,
          v: 1564620000
        },
        {
          t: 27000,
          o: 1.22728,
          h: 1.22837,
          l: 1.22674,
          c: 1.22679,
          v: 1924030000
        },
        {
          t: 27300,
          o: 1.22681,
          h: 1.22734,
          l: 1.22662,
          c: 1.2268,
          v: 1564410000
        },
        {
          t: 27600,
          o: 1.2268,
          h: 1.22751,
          l: 1.2268,
          c: 1.22734,
          v: 1652980000
        },
        {
          t: 27900,
          o: 1.22735,
          h: 1.22735,
          l: 1.22678,
          c: 1.22721,
          v: 1298150000
        },
        {
          t: 28200,
          o: 1.2272,
          h: 1.22746,
          l: 1.22689,
          c: 1.22703,
          v: 1043020000
        },
        {
          t: 28500,
          o: 1.22704,
          h: 1.22714,
          l: 1.22629,
          c: 1.22642,
          v: 1411700000
        },
        {
          t: 28800,
          o: 1.22647,
          h: 1.22685,
          l: 1.22577,
          c: 1.22577,
          v: 1734430000
        },
        {
          t: 29100,
          o: 1.22577,
          h: 1.22592,
          l: 1.22546,
          c: 1.22558,
          v: 1595680000
        },
        {
          t: 29400,
          o: 1.22558,
          h: 1.2258,
          l: 1.22497,
          c: 1.22522,
          v: 1634870000
        },
        {
          t: 29700,
          o: 1.22522,
          h: 1.22542,
          l: 1.22452,
          c: 1.22455,
          v: 1741770000
        },
        {
          t: 30000,
          o: 1.22456,
          h: 1.22507,
          l: 1.22452,
          c: 1.22505,
          v: 1321030000
        },
        {
          t: 30300,
          o: 1.22505,
          h: 1.22544,
          l: 1.22504,
          c: 1.22539,
          v: 1313990000
        },
        {
          t: 30600,
          o: 1.22539,
          h: 1.22561,
          l: 1.22531,
          c: 1.22544,
          v: 1427260000
        },
        {
          t: 30900,
          o: 1.22545,
          h: 1.22545,
          l: 1.22476,
          c: 1.22507,
          v: 1430040000
        },
        {
          t: 31200,
          o: 1.22508,
          h: 1.22573,
          l: 1.22507,
          c: 1.22528,
          v: 1040470000
        },
        {
          t: 31500,
          o: 1.22528,
          h: 1.22556,
          l: 1.22518,
          c: 1.22528,
          v: 916660000
        },
        {
          t: 31800,
          o: 1.22528,
          h: 1.22529,
          l: 1.22462,
          c: 1.22467,
          v: 1399560000
        },
        {
          t: 32100,
          o: 1.22467,
          h: 1.22467,
          l: 1.22376,
          c: 1.22383,
          v: 1445879999
        },
        {
          t: 32400,
          o: 1.22383,
          h: 1.22407,
          l: 1.2235,
          c: 1.22364,
          v: 1501990000
        },
        {
          t: 32700,
          o: 1.22364,
          h: 1.22367,
          l: 1.22339,
          c: 1.22357,
          v: 1276900000
        },
        {
          t: 33000,
          o: 1.22357,
          h: 1.22362,
          l: 1.22337,
          c: 1.22353,
          v: 1002460000
        },
        {
          t: 33300,
          o: 1.22352,
          h: 1.22377,
          l: 1.22333,
          c: 1.2235,
          v: 1035700000
        },
        {
          t: 33600,
          o: 1.22351,
          h: 1.22361,
          l: 1.22305,
          c: 1.2231,
          v: 1258150000
        },
        {
          t: 33900,
          o: 1.2231,
          h: 1.22332,
          l: 1.22291,
          c: 1.22332,
          v: 1184590000
        },
        {
          t: 34200,
          o: 1.22335,
          h: 1.22352,
          l: 1.22316,
          c: 1.22349,
          v: 1083440000
        },
        {
          t: 34500,
          o: 1.22349,
          h: 1.22354,
          l: 1.22262,
          c: 1.22284,
          v: 1243809999
        },
        {
          t: 34800,
          o: 1.22283,
          h: 1.22346,
          l: 1.22283,
          c: 1.22328,
          v: 1264220000
        },
        {
          t: 35100,
          o: 1.22325,
          h: 1.22338,
          l: 1.22275,
          c: 1.22275,
          v: 1092440000
        },
        {
          t: 35400,
          o: 1.22274,
          h: 1.22319,
          l: 1.2227,
          c: 1.22318,
          v: 1105550001
        },
        {
          t: 35700,
          o: 1.22316,
          h: 1.22345,
          l: 1.22316,
          c: 1.22321,
          v: 1249739998
        },
        {
          t: 36000,
          o: 1.22323,
          h: 1.22326,
          l: 1.22289,
          c: 1.22297,
          v: 1230060000
        },
        {
          t: 36300,
          o: 1.22298,
          h: 1.22344,
          l: 1.22284,
          c: 1.22306,
          v: 1035260000
        },
        {
          t: 36600,
          o: 1.2231,
          h: 1.22316,
          l: 1.22299,
          c: 1.22299,
          v: 684760000
        },
        {
          t: 36900,
          o: 1.22299,
          h: 1.223,
          l: 1.22256,
          c: 1.22262,
          v: 826510000
        },
        {
          t: 37200,
          o: 1.22262,
          h: 1.22325,
          l: 1.2226,
          c: 1.22323,
          v: 893030000
        },
        {
          t: 37500,
          o: 1.22322,
          h: 1.2236,
          l: 1.22319,
          c: 1.22353,
          v: 1159590000
        },
        {
          t: 37800,
          o: 1.22353,
          h: 1.22358,
          l: 1.22285,
          c: 1.22295,
          v: 1206330000
        },
        {
          t: 38100,
          o: 1.22295,
          h: 1.2234,
          l: 1.22264,
          c: 1.2233,
          v: 1174180000
        },
        {
          t: 38400,
          o: 1.2233,
          h: 1.22337,
          l: 1.22271,
          c: 1.22274,
          v: 1099970000
        },
        {
          t: 38700,
          o: 1.22273,
          h: 1.22307,
          l: 1.2226,
          c: 1.22277,
          v: 1202420000
        },
        {
          t: 39000,
          o: 1.22278,
          h: 1.22311,
          l: 1.22267,
          c: 1.22302,
          v: 991190000
        },
        {
          t: 39300,
          o: 1.22305,
          h: 1.22314,
          l: 1.22271,
          c: 1.22289,
          v: 1292230000
        },
        {
          t: 39600,
          o: 1.22288,
          h: 1.22306,
          l: 1.2225,
          c: 1.22265,
          v: 1210219999
        },
        {
          t: 39900,
          o: 1.22265,
          h: 1.22283,
          l: 1.22254,
          c: 1.2227,
          v: 791310000
        },
        {
          t: 40200,
          o: 1.2227,
          h: 1.22299,
          l: 1.2225,
          c: 1.22293,
          v: 862510000
        },
        {
          t: 40500,
          o: 1.22292,
          h: 1.22316,
          l: 1.22275,
          c: 1.22311,
          v: 980060000
        },
        {
          t: 40800,
          o: 1.22312,
          h: 1.22337,
          l: 1.22306,
          c: 1.22322,
          v: 1339189998
        },
        {
          t: 41100,
          o: 1.22322,
          h: 1.22325,
          l: 1.22302,
          c: 1.22316,
          v: 832670000
        },
        {
          t: 41400,
          o: 1.22317,
          h: 1.2232,
          l: 1.22275,
          c: 1.22316,
          v: 1047040000
        },
        {
          t: 41700,
          o: 1.22317,
          h: 1.22332,
          l: 1.22302,
          c: 1.22315,
          v: 710650000
        },
        {
          t: 42000,
          o: 1.22315,
          h: 1.22335,
          l: 1.22299,
          c: 1.2233,
          v: 722470000
        },
        {
          t: 42300,
          o: 1.2233,
          h: 1.22345,
          l: 1.22319,
          c: 1.22338,
          v: 938200000
        },
        {
          t: 42600,
          o: 1.22338,
          h: 1.22349,
          l: 1.22328,
          c: 1.22344,
          v: 654390000
        },
        {
          t: 42900,
          o: 1.22344,
          h: 1.22366,
          l: 1.22338,
          c: 1.22361,
          v: 926230000
        },
        {
          t: 43200,
          o: 1.22365,
          h: 1.22397,
          l: 1.22338,
          c: 1.2234,
          v: 1110150000
        },
        {
          t: 43500,
          o: 1.22341,
          h: 1.22374,
          l: 1.22326,
          c: 1.22364,
          v: 974330000
        },
        {
          t: 43800,
          o: 1.22362,
          h: 1.22394,
          l: 1.22358,
          c: 1.22378,
          v: 967270000
        },
        {
          t: 44100,
          o: 1.22378,
          h: 1.22425,
          l: 1.22363,
          c: 1.22369,
          v: 1075100000
        },
        {
          t: 44400,
          o: 1.22367,
          h: 1.22443,
          l: 1.22364,
          c: 1.22411,
          v: 1310210000
        },
        {
          t: 44700,
          o: 1.22406,
          h: 1.22442,
          l: 1.22367,
          c: 1.22368,
          v: 1056220000
        },
        {
          t: 45000,
          o: 1.22366,
          h: 1.22386,
          l: 1.22325,
          c: 1.22365,
          v: 993710000
        },
        {
          t: 45300,
          o: 1.22365,
          h: 1.22382,
          l: 1.22328,
          c: 1.22334,
          v: 947540000
        },
        {
          t: 45600,
          o: 1.22335,
          h: 1.22371,
          l: 1.22326,
          c: 1.22354,
          v: 1179930001
        },
        {
          t: 45900,
          o: 1.22356,
          h: 1.22399,
          l: 1.22346,
          c: 1.22369,
          v: 853720000
        },
        {
          t: 46200,
          o: 1.22369,
          h: 1.22381,
          l: 1.22342,
          c: 1.22358,
          v: 944210000
        },
        {
          t: 46500,
          o: 1.22359,
          h: 1.22415,
          l: 1.22341,
          c: 1.22404,
          v: 1354770001
        },
        {
          t: 46800,
          o: 1.22406,
          h: 1.22432,
          l: 1.22373,
          c: 1.22428,
          v: 1381810001
        },
        {
          t: 47100,
          o: 1.22427,
          h: 1.22454,
          l: 1.22407,
          c: 1.22443,
          v: 1157040000
        },
        {
          t: 47400,
          o: 1.22443,
          h: 1.22443,
          l: 1.22355,
          c: 1.22358,
          v: 1384280002
        },
        {
          t: 47700,
          o: 1.22359,
          h: 1.22418,
          l: 1.22355,
          c: 1.22417,
          v: 873800000
        },
        {
          t: 48000,
          o: 1.22417,
          h: 1.22441,
          l: 1.22376,
          c: 1.22379,
          v: 1122820000
        },
        {
          t: 48300,
          o: 1.22378,
          h: 1.22401,
          l: 1.22361,
          c: 1.2237,
          v: 1049640000
        },
        {
          t: 48600,
          o: 1.22369,
          h: 1.22375,
          l: 1.22316,
          c: 1.22333,
          v: 1597440000
        },
        {
          t: 48900,
          o: 1.22333,
          h: 1.22339,
          l: 1.22311,
          c: 1.22321,
          v: 915040000
        },
        {
          t: 49200,
          o: 1.2232,
          h: 1.22366,
          l: 1.22306,
          c: 1.22346,
          v: 1042380000
        },
        {
          t: 49500,
          o: 1.22346,
          h: 1.22356,
          l: 1.2226,
          c: 1.22268,
          v: 1890540000
        },
        {
          t: 49800,
          o: 1.22266,
          h: 1.22276,
          l: 1.22202,
          c: 1.22202,
          v: 1560810001
        },
        {
          t: 50100,
          o: 1.22201,
          h: 1.22242,
          l: 1.22187,
          c: 1.22223,
          v: 1368630000
        },
        {
          t: 50400,
          o: 1.22224,
          h: 1.2223,
          l: 1.22164,
          c: 1.22176,
          v: 1673920000
        },
        {
          t: 50700,
          o: 1.2218,
          h: 1.22188,
          l: 1.22149,
          c: 1.22155,
          v: 1564840000
        },
        {
          t: 51000,
          o: 1.22155,
          h: 1.22202,
          l: 1.22152,
          c: 1.22202,
          v: 1448230000
        },
        {
          t: 51300,
          o: 1.22202,
          h: 1.22258,
          l: 1.22202,
          c: 1.22257,
          v: 1513290000
        },
        {
          t: 51600,
          o: 1.22257,
          h: 1.22295,
          l: 1.22246,
          c: 1.22278,
          v: 1239980000
        },
        {
          t: 51900,
          o: 1.22279,
          h: 1.22313,
          l: 1.22271,
          c: 1.22309,
          v: 1235780000
        },
        {
          t: 52200,
          o: 1.22309,
          h: 1.22317,
          l: 1.22289,
          c: 1.22299,
          v: 1353000001
        },
        {
          t: 52500,
          o: 1.22299,
          h: 1.22337,
          l: 1.22292,
          c: 1.22323,
          v: 870340000
        },
        {
          t: 52800,
          o: 1.22323,
          h: 1.22344,
          l: 1.22291,
          c: 1.22339,
          v: 1191510000
        },
        {
          t: 53100,
          o: 1.22339,
          h: 1.22366,
          l: 1.22333,
          c: 1.22338,
          v: 1175890001
        },
        {
          t: 53400,
          o: 1.22338,
          h: 1.22363,
          l: 1.22325,
          c: 1.22339,
          v: 1169190000
        },
        {
          t: 53700,
          o: 1.22336,
          h: 1.22336,
          l: 1.22199,
          c: 1.22206,
          v: 1689450000
        },
        {
          t: 54000,
          o: 1.22207,
          h: 1.22257,
          l: 1.2217,
          c: 1.22242,
          v: 1864510000
        },
        {
          t: 54300,
          o: 1.22242,
          h: 1.22242,
          l: 1.2215,
          c: 1.22172,
          v: 1684120000
        },
        {
          t: 54600,
          o: 1.22172,
          h: 1.22241,
          l: 1.22172,
          c: 1.22213,
          v: 1014300000
        },
        {
          t: 54900,
          o: 1.22208,
          h: 1.22245,
          l: 1.22189,
          c: 1.22227,
          v: 1201950000
        },
        {
          t: 55200,
          o: 1.22228,
          h: 1.22228,
          l: 1.22174,
          c: 1.22199,
          v: 1295430000
        },
        {
          t: 55500,
          o: 1.22199,
          h: 1.222,
          l: 1.22161,
          c: 1.22175,
          v: 1291830001
        },
        {
          t: 55800,
          o: 1.22172,
          h: 1.22184,
          l: 1.22147,
          c: 1.22159,
          v: 1146900000
        },
        {
          t: 56100,
          o: 1.22159,
          h: 1.22182,
          l: 1.22111,
          c: 1.22121,
          v: 1249780000
        },
        {
          t: 56400,
          o: 1.22121,
          h: 1.22154,
          l: 1.22105,
          c: 1.22152,
          v: 1567210000
        },
        {
          t: 56700,
          o: 1.22152,
          h: 1.22163,
          l: 1.22126,
          c: 1.22159,
          v: 1040820000
        },
        {
          t: 57000,
          o: 1.22159,
          h: 1.22164,
          l: 1.22128,
          c: 1.22133,
          v: 1264830000
        },
        {
          t: 57300,
          o: 1.22137,
          h: 1.22156,
          l: 1.22122,
          c: 1.22148,
          v: 1107370000
        },
        {
          t: 57600,
          o: 1.22149,
          h: 1.22194,
          l: 1.22148,
          c: 1.22194,
          v: 1119310000
        },
        {
          t: 57900,
          o: 1.22193,
          h: 1.22227,
          l: 1.22186,
          c: 1.22227,
          v: 1211629999
        },
        {
          t: 58200,
          o: 1.22227,
          h: 1.22227,
          l: 1.22167,
          c: 1.22167,
          v: 960380000
        },
        {
          t: 58500,
          o: 1.22167,
          h: 1.22177,
          l: 1.22158,
          c: 1.22169,
          v: 847780000
        },
        {
          t: 58800,
          o: 1.22167,
          h: 1.22167,
          l: 1.22116,
          c: 1.22139,
          v: 1303000002
        },
        {
          t: 59100,
          o: 1.2214,
          h: 1.22174,
          l: 1.22132,
          c: 1.22143,
          v: 928440000
        },
        {
          t: 59400,
          o: 1.22143,
          h: 1.22169,
          l: 1.22137,
          c: 1.22137,
          v: 1286609999
        },
        {
          t: 59700,
          o: 1.22138,
          h: 1.22154,
          l: 1.22131,
          c: 1.22147,
          v: 693270000
        },
        {
          t: 60000,
          o: 1.22147,
          h: 1.22187,
          l: 1.22132,
          c: 1.22181,
          v: 949260000
        },
        {
          t: 60300,
          o: 1.22182,
          h: 1.22183,
          l: 1.22143,
          c: 1.22143,
          v: 1036950000
        },
        {
          t: 60600,
          o: 1.22145,
          h: 1.22166,
          l: 1.22127,
          c: 1.22163,
          v: 810730000
        },
        {
          t: 60900,
          o: 1.22163,
          h: 1.22195,
          l: 1.22158,
          c: 1.22179,
          v: 1411320000
        },
        {
          t: 61200,
          o: 1.2218,
          h: 1.2218,
          l: 1.22131,
          c: 1.22141,
          v: 1073660000
        },
        {
          t: 61500,
          o: 1.22143,
          h: 1.22154,
          l: 1.22113,
          c: 1.22115,
          v: 757100000
        },
        {
          t: 61800,
          o: 1.22117,
          h: 1.22123,
          l: 1.22098,
          c: 1.22106,
          v: 844550000
        },
        {
          t: 62100,
          o: 1.22106,
          h: 1.22145,
          l: 1.22106,
          c: 1.22121,
          v: 1162160000
        },
        {
          t: 62400,
          o: 1.22122,
          h: 1.22128,
          l: 1.22101,
          c: 1.22101,
          v: 1023960000
        },
        {
          t: 62700,
          o: 1.22102,
          h: 1.22121,
          l: 1.22082,
          c: 1.22089,
          v: 1054590000
        },
        {
          t: 63000,
          o: 1.22089,
          h: 1.221,
          l: 1.22087,
          c: 1.2209,
          v: 729120000
        },
        {
          t: 63300,
          o: 1.22089,
          h: 1.22097,
          l: 1.22084,
          c: 1.22091,
          v: 560080000
        },
        {
          t: 63600,
          o: 1.22091,
          h: 1.221,
          l: 1.22079,
          c: 1.22081,
          v: 821750000
        },
        {
          t: 63900,
          o: 1.22082,
          h: 1.22085,
          l: 1.22064,
          c: 1.22065,
          v: 551430000
        },
        {
          t: 64200,
          o: 1.22065,
          h: 1.22066,
          l: 1.22035,
          c: 1.2204,
          v: 1028590000
        },
        {
          t: 64500,
          o: 1.22042,
          h: 1.2205,
          l: 1.22024,
          c: 1.2203,
          v: 1048209999
        },
        {
          t: 64800,
          o: 1.2203,
          h: 1.22063,
          l: 1.2203,
          c: 1.22061,
          v: 814820000
        },
        {
          t: 65100,
          o: 1.22061,
          h: 1.22078,
          l: 1.22045,
          c: 1.22063,
          v: 1333310000
        },
        {
          t: 65400,
          o: 1.22062,
          h: 1.22098,
          l: 1.22055,
          c: 1.22094,
          v: 1215820000
        },
        {
          t: 65700,
          o: 1.22099,
          h: 1.22115,
          l: 1.22095,
          c: 1.22115,
          v: 1063820000
        },
        {
          t: 66000,
          o: 1.22115,
          h: 1.22126,
          l: 1.22109,
          c: 1.22115,
          v: 929700000
        },
        {
          t: 66300,
          o: 1.22117,
          h: 1.22147,
          l: 1.22116,
          c: 1.22141,
          v: 798310000
        },
        {
          t: 66600,
          o: 1.22142,
          h: 1.22147,
          l: 1.22139,
          c: 1.22141,
          v: 905640000
        },
        {
          t: 66900,
          o: 1.2214,
          h: 1.22163,
          l: 1.22139,
          c: 1.22155,
          v: 867240000
        },
        {
          t: 67200,
          o: 1.22156,
          h: 1.22161,
          l: 1.22138,
          c: 1.22139,
          v: 863700000
        },
        {
          t: 67500,
          o: 1.22139,
          h: 1.22144,
          l: 1.22111,
          c: 1.22116,
          v: 1257850000
        },
        {
          t: 67800,
          o: 1.22115,
          h: 1.2213,
          l: 1.22113,
          c: 1.22128,
          v: 673390000
        },
        {
          t: 68100,
          o: 1.22128,
          h: 1.22128,
          l: 1.22026,
          c: 1.22029,
          v: 1277260000
        },
        {
          t: 68400,
          o: 1.22032,
          h: 1.22071,
          l: 1.22032,
          c: 1.22065,
          v: 1297820000
        },
        {
          t: 68700,
          o: 1.22064,
          h: 1.22081,
          l: 1.2205,
          c: 1.22055,
          v: 1223900001
        },
        {
          t: 69000,
          o: 1.22055,
          h: 1.22058,
          l: 1.22035,
          c: 1.22035,
          v: 973810000
        },
        {
          t: 69300,
          o: 1.22035,
          h: 1.22036,
          l: 1.21977,
          c: 1.22,
          v: 1255220000
        },
        {
          t: 69600,
          o: 1.22,
          h: 1.22027,
          l: 1.21982,
          c: 1.22024,
          v: 1254070000
        },
        {
          t: 69900,
          o: 1.22024,
          h: 1.22029,
          l: 1.22001,
          c: 1.22018,
          v: 955540000
        },
        {
          t: 70200,
          o: 1.22018,
          h: 1.22059,
          l: 1.22018,
          c: 1.22037,
          v: 1031840000
        },
        {
          t: 70500,
          o: 1.22038,
          h: 1.22051,
          l: 1.22026,
          c: 1.2205,
          v: 630860000
        },
        {
          t: 70800,
          o: 1.2205,
          h: 1.22072,
          l: 1.22043,
          c: 1.22067,
          v: 971340000
        },
        {
          t: 71100,
          o: 1.22068,
          h: 1.22079,
          l: 1.22065,
          c: 1.22077,
          v: 524180000
        },
        {
          t: 71400,
          o: 1.22077,
          h: 1.22098,
          l: 1.22066,
          c: 1.22092,
          v: 539030000
        },
        {
          t: 71700,
          o: 1.22094,
          h: 1.22098,
          l: 1.22048,
          c: 1.22076,
          v: 676370000
        },
        {
          t: 72000,
          o: 1.22076,
          h: 1.22089,
          l: 1.22062,
          c: 1.22073,
          v: 583030000
        },
        {
          t: 72300,
          o: 1.22072,
          h: 1.22072,
          l: 1.22047,
          c: 1.2205,
          v: 281410000
        },
        {
          t: 72600,
          o: 1.22049,
          h: 1.22078,
          l: 1.22048,
          c: 1.22073,
          v: 335670000
        },
        {
          t: 72900,
          o: 1.22073,
          h: 1.22074,
          l: 1.22066,
          c: 1.22067,
          v: 224699999
        },
        {
          t: 73200,
          o: 1.22067,
          h: 1.2207,
          l: 1.22061,
          c: 1.2207,
          v: 213110000
        },
        {
          t: 73500,
          o: 1.2207,
          h: 1.22079,
          l: 1.2207,
          c: 1.22075,
          v: 186970000
        },
        {
          t: 73800,
          o: 1.22074,
          h: 1.22081,
          l: 1.22064,
          c: 1.22074,
          v: 284980000
        },
        {
          t: 74100,
          o: 1.22074,
          h: 1.22079,
          l: 1.22061,
          c: 1.22075,
          v: 296900000
        },
        {
          t: 74400,
          o: 1.22075,
          h: 1.22075,
          l: 1.22054,
          c: 1.22057,
          v: 279260000
        },
        {
          t: 74700,
          o: 1.22058,
          h: 1.22071,
          l: 1.22054,
          c: 1.22069,
          v: 244900000
        },
        {
          t: 75000,
          o: 1.22069,
          h: 1.22079,
          l: 1.22059,
          c: 1.2207,
          v: 516930000
        },
        {
          t: 75300,
          o: 1.2207,
          h: 1.22084,
          l: 1.22059,
          c: 1.22084,
          v: 400180000
        },
        {
          t: 75600,
          o: 1.22082,
          h: 1.22082,
          l: 1.22074,
          c: 1.22075,
          v: 43280000
        },
        {
          t: 75900,
          o: 1.22077,
          h: 1.22085,
          l: 1.22077,
          c: 1.22081,
          v: 131770000
        },
        {
          t: 76200,
          o: 1.22081,
          h: 1.22089,
          l: 1.22079,
          c: 1.22082,
          v: 243780000
        },
        {
          t: 76500,
          o: 1.22083,
          h: 1.22085,
          l: 1.2208,
          c: 1.22081,
          v: 224970000
        },
        {
          t: 76800,
          o: 1.22081,
          h: 1.22084,
          l: 1.22078,
          c: 1.22081,
          v: 231690000
        },
        {
          t: 77100,
          o: 1.22082,
          h: 1.22084,
          l: 1.22059,
          c: 1.22061,
          v: 229070000
        },
        {
          t: 77400,
          o: 1.22059,
          h: 1.22089,
          l: 1.22055,
          c: 1.22075,
          v: 328560000
        },
        {
          t: 77700,
          o: 1.22075,
          h: 1.22079,
          l: 1.22075,
          c: 1.22077,
          v: 205460000
        },
        {
          t: 78000,
          o: 1.22076,
          h: 1.22082,
          l: 1.22072,
          c: 1.2208,
          v: 138380000
        },
        {
          t: 78300,
          o: 1.2208,
          h: 1.2208,
          l: 1.22076,
          c: 1.22077,
          v: 66830000
        },
        {
          t: 78600,
          o: 1.22077,
          h: 1.22085,
          l: 1.22076,
          c: 1.2208,
          v: 114940000
        },
        {
          t: 78900,
          o: 1.22081,
          h: 1.22088,
          l: 1.22079,
          c: 1.22083,
          v: 316730000
        },
        {
          t: 79200,
          o: 1.22081,
          h: 1.22089,
          l: 1.22077,
          c: 1.2208,
          v: 431900000
        },
        {
          t: 79500,
          o: 1.22081,
          h: 1.22087,
          l: 1.22066,
          c: 1.22068,
          v: 372230000
        },
        {
          t: 79800,
          o: 1.22071,
          h: 1.22084,
          l: 1.2207,
          c: 1.22081,
          v: 164650000
        },
        {
          t: 80100,
          o: 1.22081,
          h: 1.22084,
          l: 1.22079,
          c: 1.22081,
          v: 165990000
        },
        {
          t: 80400,
          o: 1.22081,
          h: 1.22087,
          l: 1.22078,
          c: 1.22084,
          v: 123880000
        },
        {
          t: 80700,
          o: 1.22084,
          h: 1.2209,
          l: 1.22083,
          c: 1.22087,
          v: 105210000
        },
        {
          t: 81000,
          o: 1.22087,
          h: 1.22105,
          l: 1.2208,
          c: 1.22086,
          v: 324550000
        },
        {
          t: 81300,
          o: 1.22086,
          h: 1.22097,
          l: 1.22081,
          c: 1.22096,
          v: 237700000
        },
        {
          t: 81600,
          o: 1.22097,
          h: 1.22097,
          l: 1.2209,
          c: 1.22092,
          v: 267530000
        },
        {
          t: 81900,
          o: 1.22093,
          h: 1.22101,
          l: 1.22093,
          c: 1.22097,
          v: 209570000
        },
        {
          t: 82200,
          o: 1.22097,
          h: 1.22103,
          l: 1.22096,
          c: 1.221,
          v: 250710000
        },
        {
          t: 82500,
          o: 1.22101,
          h: 1.2211,
          l: 1.22099,
          c: 1.22099,
          v: 248080000
        },
        {
          t: 82800,
          o: 1.22099,
          h: 1.22102,
          l: 1.22078,
          c: 1.2208,
          v: 280780000
        },
        {
          t: 83100,
          o: 1.22079,
          h: 1.22093,
          l: 1.22077,
          c: 1.22089,
          v: 263650000
        },
        {
          t: 83400,
          o: 1.22089,
          h: 1.2209,
          l: 1.22082,
          c: 1.22086,
          v: 115230000
        },
        {
          t: 83700,
          o: 1.22086,
          h: 1.22087,
          l: 1.22079,
          c: 1.22082,
          v: 115390000
        },
        {
          t: 84000,
          o: 1.22081,
          h: 1.22093,
          l: 1.22081,
          c: 1.2209,
          v: 140850000
        },
        {
          t: 84300,
          o: 1.2209,
          h: 1.22103,
          l: 1.2209,
          c: 1.22097,
          v: 259150000
        },
        {
          t: 84600,
          o: 1.22097,
          h: 1.22097,
          l: 1.22088,
          c: 1.22089,
          v: 274410000
        },
        {
          t: 84900,
          o: 1.2209,
          h: 1.22109,
          l: 1.2209,
          c: 1.22107,
          v: 343980000
        },
        {
          t: 85200,
          o: 1.22107,
          h: 1.22107,
          l: 1.22099,
          c: 1.22102,
          v: 320860000
        },
        {
          t: 85500,
          o: 1.22102,
          h: 1.22103,
          l: 1.22082,
          c: 1.22083,
          v: 394400000
        },
        {
          t: 85800,
          o: 1.22082,
          h: 1.221,
          l: 1.22082,
          c: 1.22098,
          v: 313950000
        },
        {
          t: 86100,
          o: 1.22097,
          h: 1.22098,
          l: 1.22078,
          c: 1.22092,
          v: 444280000
        }
      ]
    },
    {
      d: 1524528000,
      b: [
        {
          t: 0,
          o: 1.22094,
          h: 1.22115,
          l: 1.22051,
          c: 1.22052,
          v: 765700000
        },
        {
          t: 300,
          o: 1.22052,
          h: 1.2206,
          l: 1.22035,
          c: 1.22041,
          v: 984480000
        },
        {
          t: 600,
          o: 1.22041,
          h: 1.22082,
          l: 1.22039,
          c: 1.22082,
          v: 612689999
        },
        {
          t: 900,
          o: 1.22082,
          h: 1.22082,
          l: 1.22055,
          c: 1.22065,
          v: 660450000
        },
        {
          t: 1200,
          o: 1.22065,
          h: 1.22075,
          l: 1.22044,
          c: 1.22045,
          v: 564110000
        },
        {
          t: 1500,
          o: 1.22045,
          h: 1.2206,
          l: 1.22021,
          c: 1.2204,
          v: 634960000
        },
        {
          t: 1800,
          o: 1.22041,
          h: 1.22089,
          l: 1.22041,
          c: 1.22082,
          v: 558170000
        },
        {
          t: 2100,
          o: 1.22083,
          h: 1.22083,
          l: 1.22065,
          c: 1.22076,
          v: 391570000
        },
        {
          t: 2400,
          o: 1.22075,
          h: 1.22087,
          l: 1.22071,
          c: 1.22081,
          v: 813560003
        },
        {
          t: 2700,
          o: 1.22081,
          h: 1.22083,
          l: 1.22071,
          c: 1.22077,
          v: 419670000
        },
        {
          t: 3000,
          o: 1.22076,
          h: 1.22076,
          l: 1.2202,
          c: 1.2204,
          v: 707740000
        },
        {
          t: 3300,
          o: 1.22039,
          h: 1.22072,
          l: 1.22017,
          c: 1.22066,
          v: 749280000
        },
        {
          t: 3600,
          o: 1.22066,
          h: 1.22084,
          l: 1.2203,
          c: 1.22039,
          v: 580110000
        },
        {
          t: 3900,
          o: 1.2204,
          h: 1.22057,
          l: 1.22029,
          c: 1.22029,
          v: 801520000
        },
        {
          t: 4200,
          o: 1.2203,
          h: 1.2203,
          l: 1.2187,
          c: 1.21924,
          v: 1582000000
        },
        {
          t: 4500,
          o: 1.21926,
          h: 1.21927,
          l: 1.21889,
          c: 1.21911,
          v: 1071320000
        },
        {
          t: 4800,
          o: 1.21913,
          h: 1.21913,
          l: 1.21849,
          c: 1.2185,
          v: 1114340000
        },
        {
          t: 5100,
          o: 1.2185,
          h: 1.21937,
          l: 1.21845,
          c: 1.21937,
          v: 843240000
        },
        {
          t: 5400,
          o: 1.21936,
          h: 1.21976,
          l: 1.2189,
          c: 1.21973,
          v: 1052520000
        },
        {
          t: 5700,
          o: 1.21973,
          h: 1.22021,
          l: 1.21972,
          c: 1.22005,
          v: 1219660000
        },
        {
          t: 6000,
          o: 1.22006,
          h: 1.22027,
          l: 1.2199,
          c: 1.21994,
          v: 970310000
        },
        {
          t: 6300,
          o: 1.21994,
          h: 1.21994,
          l: 1.21939,
          c: 1.21947,
          v: 874350000
        },
        {
          t: 6600,
          o: 1.21947,
          h: 1.2201,
          l: 1.21947,
          c: 1.22006,
          v: 898740000
        },
        {
          t: 6900,
          o: 1.22006,
          h: 1.22053,
          l: 1.22005,
          c: 1.22046,
          v: 1043120000
        },
        {
          t: 7200,
          o: 1.22045,
          h: 1.22051,
          l: 1.22022,
          c: 1.22041,
          v: 941080000
        },
        {
          t: 7500,
          o: 1.22044,
          h: 1.22088,
          l: 1.22043,
          c: 1.22065,
          v: 634720000
        },
        {
          t: 7800,
          o: 1.22064,
          h: 1.22083,
          l: 1.22059,
          c: 1.2208,
          v: 789540000
        },
        {
          t: 8100,
          o: 1.22082,
          h: 1.22084,
          l: 1.2206,
          c: 1.22061,
          v: 792770000
        },
        {
          t: 8400,
          o: 1.22063,
          h: 1.22117,
          l: 1.22054,
          c: 1.22112,
          v: 810770000
        },
        {
          t: 8700,
          o: 1.22113,
          h: 1.22114,
          l: 1.22069,
          c: 1.2208,
          v: 732420000
        },
        {
          t: 9000,
          o: 1.22079,
          h: 1.22117,
          l: 1.2207,
          c: 1.22112,
          v: 464340000
        },
        {
          t: 9300,
          o: 1.22112,
          h: 1.22118,
          l: 1.22085,
          c: 1.22091,
          v: 637150000
        },
        {
          t: 9600,
          o: 1.2209,
          h: 1.22109,
          l: 1.2209,
          c: 1.22104,
          v: 418420000
        },
        {
          t: 9900,
          o: 1.22103,
          h: 1.22103,
          l: 1.22063,
          c: 1.22081,
          v: 589490000
        },
        {
          t: 10200,
          o: 1.2208,
          h: 1.22081,
          l: 1.22058,
          c: 1.22062,
          v: 629690000
        },
        {
          t: 10500,
          o: 1.22061,
          h: 1.22098,
          l: 1.22056,
          c: 1.22081,
          v: 452830000
        },
        {
          t: 10800,
          o: 1.22081,
          h: 1.22124,
          l: 1.22076,
          c: 1.22105,
          v: 769030000
        },
        {
          t: 11100,
          o: 1.22107,
          h: 1.22118,
          l: 1.221,
          c: 1.22112,
          v: 514480000
        },
        {
          t: 11400,
          o: 1.22113,
          h: 1.22117,
          l: 1.22096,
          c: 1.22106,
          v: 401880000
        },
        {
          t: 11700,
          o: 1.22107,
          h: 1.22107,
          l: 1.22081,
          c: 1.22082,
          v: 648040000
        },
        {
          t: 12000,
          o: 1.22082,
          h: 1.22082,
          l: 1.22075,
          c: 1.22077,
          v: 467190000
        },
        {
          t: 12300,
          o: 1.22075,
          h: 1.2208,
          l: 1.2207,
          c: 1.22074,
          v: 363820000
        },
        {
          t: 12600,
          o: 1.22074,
          h: 1.22086,
          l: 1.22074,
          c: 1.22084,
          v: 302400000
        },
        {
          t: 12900,
          o: 1.22084,
          h: 1.22097,
          l: 1.22078,
          c: 1.22086,
          v: 344500000
        },
        {
          t: 13200,
          o: 1.22086,
          h: 1.22143,
          l: 1.22086,
          c: 1.22142,
          v: 550340000
        },
        {
          t: 13500,
          o: 1.22142,
          h: 1.22146,
          l: 1.22131,
          c: 1.22132,
          v: 706170000
        },
        {
          t: 13800,
          o: 1.22131,
          h: 1.22132,
          l: 1.22124,
          c: 1.22125,
          v: 254330000
        },
        {
          t: 14100,
          o: 1.22125,
          h: 1.22133,
          l: 1.22123,
          c: 1.22131,
          v: 246160000
        },
        {
          t: 14400,
          o: 1.22131,
          h: 1.22133,
          l: 1.22115,
          c: 1.22117,
          v: 291160000
        },
        {
          t: 14700,
          o: 1.22116,
          h: 1.22116,
          l: 1.22091,
          c: 1.22091,
          v: 424390000
        },
        {
          t: 15000,
          o: 1.22091,
          h: 1.22097,
          l: 1.22084,
          c: 1.22087,
          v: 319490000
        },
        {
          t: 15300,
          o: 1.22086,
          h: 1.22108,
          l: 1.22086,
          c: 1.22108,
          v: 314880000
        },
        {
          t: 15600,
          o: 1.22108,
          h: 1.22112,
          l: 1.22102,
          c: 1.22102,
          v: 426380000
        },
        {
          t: 15900,
          o: 1.22102,
          h: 1.22102,
          l: 1.22096,
          c: 1.22102,
          v: 184910000
        },
        {
          t: 16200,
          o: 1.22102,
          h: 1.22102,
          l: 1.22091,
          c: 1.22096,
          v: 341860000
        },
        {
          t: 16500,
          o: 1.22097,
          h: 1.22097,
          l: 1.22081,
          c: 1.22085,
          v: 200250000
        },
        {
          t: 16800,
          o: 1.22087,
          h: 1.22101,
          l: 1.22084,
          c: 1.22095,
          v: 551010000
        },
        {
          t: 17100,
          o: 1.22099,
          h: 1.22102,
          l: 1.22091,
          c: 1.22091,
          v: 226250000
        },
        {
          t: 17400,
          o: 1.22091,
          h: 1.22093,
          l: 1.22081,
          c: 1.22087,
          v: 327790000
        },
        {
          t: 17700,
          o: 1.22088,
          h: 1.22132,
          l: 1.22088,
          c: 1.22131,
          v: 526990000
        },
        {
          t: 18000,
          o: 1.22132,
          h: 1.22194,
          l: 1.2213,
          c: 1.22185,
          v: 736510000
        },
        {
          t: 18300,
          o: 1.22185,
          h: 1.22185,
          l: 1.22171,
          c: 1.22173,
          v: 457650000
        },
        {
          t: 18600,
          o: 1.22173,
          h: 1.22173,
          l: 1.22149,
          c: 1.22161,
          v: 468340000
        },
        {
          t: 18900,
          o: 1.22161,
          h: 1.2217,
          l: 1.22153,
          c: 1.2217,
          v: 309280000
        },
        {
          t: 19200,
          o: 1.2217,
          h: 1.22174,
          l: 1.22156,
          c: 1.22162,
          v: 345480000
        },
        {
          t: 19500,
          o: 1.22161,
          h: 1.22188,
          l: 1.22161,
          c: 1.22188,
          v: 575410000
        },
        {
          t: 19800,
          o: 1.22188,
          h: 1.2219,
          l: 1.22167,
          c: 1.22169,
          v: 307140000
        },
        {
          t: 20100,
          o: 1.22168,
          h: 1.22172,
          l: 1.22167,
          c: 1.2217,
          v: 239780000
        },
        {
          t: 20400,
          o: 1.2217,
          h: 1.22182,
          l: 1.22169,
          c: 1.22179,
          v: 320910000
        },
        {
          t: 20700,
          o: 1.22179,
          h: 1.22184,
          l: 1.22166,
          c: 1.22183,
          v: 311650000
        },
        {
          t: 21000,
          o: 1.22184,
          h: 1.22189,
          l: 1.22164,
          c: 1.2217,
          v: 490690000
        },
        {
          t: 21300,
          o: 1.22169,
          h: 1.2217,
          l: 1.22144,
          c: 1.22163,
          v: 538300000
        },
        {
          t: 21600,
          o: 1.22163,
          h: 1.22173,
          l: 1.22132,
          c: 1.22159,
          v: 798320000
        },
        {
          t: 21900,
          o: 1.22157,
          h: 1.22166,
          l: 1.22134,
          c: 1.22134,
          v: 733580000
        },
        {
          t: 22200,
          o: 1.22134,
          h: 1.22184,
          l: 1.22107,
          c: 1.22161,
          v: 1066550000
        },
        {
          t: 22500,
          o: 1.22161,
          h: 1.22189,
          l: 1.2214,
          c: 1.22174,
          v: 1294070000
        },
        {
          t: 22800,
          o: 1.22174,
          h: 1.22235,
          l: 1.22152,
          c: 1.22218,
          v: 1336030000
        },
        {
          t: 23100,
          o: 1.22219,
          h: 1.22221,
          l: 1.22185,
          c: 1.22196,
          v: 919600000
        },
        {
          t: 23400,
          o: 1.22195,
          h: 1.22237,
          l: 1.22181,
          c: 1.22182,
          v: 1018690000
        },
        {
          t: 23700,
          o: 1.22183,
          h: 1.22184,
          l: 1.22138,
          c: 1.22142,
          v: 1147560000
        },
        {
          t: 24000,
          o: 1.22143,
          h: 1.22154,
          l: 1.22133,
          c: 1.2214,
          v: 989130000
        },
        {
          t: 24300,
          o: 1.2214,
          h: 1.22151,
          l: 1.22129,
          c: 1.2214,
          v: 738190000
        },
        {
          t: 24600,
          o: 1.22139,
          h: 1.22149,
          l: 1.22071,
          c: 1.22072,
          v: 1154100000
        },
        {
          t: 24900,
          o: 1.22072,
          h: 1.22081,
          l: 1.22018,
          c: 1.22022,
          v: 1307770000
        },
        {
          t: 25200,
          o: 1.22021,
          h: 1.22077,
          l: 1.22004,
          c: 1.22018,
          v: 1631180000
        },
        {
          t: 25500,
          o: 1.22018,
          h: 1.22046,
          l: 1.21993,
          c: 1.22039,
          v: 1438270000
        },
        {
          t: 25800,
          o: 1.22038,
          h: 1.22063,
          l: 1.22001,
          c: 1.22037,
          v: 1383880000
        },
        {
          t: 26100,
          o: 1.22037,
          h: 1.22112,
          l: 1.22029,
          c: 1.2211,
          v: 1023480000
        },
        {
          t: 26400,
          o: 1.2211,
          h: 1.2211,
          l: 1.2205,
          c: 1.2205,
          v: 946350000
        },
        {
          t: 26700,
          o: 1.2205,
          h: 1.22066,
          l: 1.22019,
          c: 1.22066,
          v: 874660000
        },
        {
          t: 27000,
          o: 1.22063,
          h: 1.22066,
          l: 1.21992,
          c: 1.21996,
          v: 929250000
        },
        {
          t: 27300,
          o: 1.21997,
          h: 1.22013,
          l: 1.2189,
          c: 1.21916,
          v: 1357920000
        },
        {
          t: 27600,
          o: 1.21917,
          h: 1.21975,
          l: 1.21916,
          c: 1.21933,
          v: 1054860000
        },
        {
          t: 27900,
          o: 1.21933,
          h: 1.21981,
          l: 1.21933,
          c: 1.21964,
          v: 968140001
        },
        {
          t: 28200,
          o: 1.21964,
          h: 1.2198,
          l: 1.21926,
          c: 1.21962,
          v: 989700000
        },
        {
          t: 28500,
          o: 1.21962,
          h: 1.21969,
          l: 1.2194,
          c: 1.21961,
          v: 709380000
        },
        {
          t: 28800,
          o: 1.21961,
          h: 1.21977,
          l: 1.21837,
          c: 1.21889,
          v: 1422370000
        },
        {
          t: 29100,
          o: 1.21888,
          h: 1.21911,
          l: 1.21853,
          c: 1.21893,
          v: 1382500001
        },
        {
          t: 29400,
          o: 1.21893,
          h: 1.21935,
          l: 1.2188,
          c: 1.21902,
          v: 1112190000
        },
        {
          t: 29700,
          o: 1.21902,
          h: 1.2193,
          l: 1.21853,
          c: 1.21868,
          v: 1197240000
        },
        {
          t: 30000,
          o: 1.21867,
          h: 1.21879,
          l: 1.21817,
          c: 1.21864,
          v: 943650000
        },
        {
          t: 30300,
          o: 1.21866,
          h: 1.21911,
          l: 1.21855,
          c: 1.21905,
          v: 884950000
        },
        {
          t: 30600,
          o: 1.21904,
          h: 1.21972,
          l: 1.21899,
          c: 1.21965,
          v: 1215050000
        },
        {
          t: 30900,
          o: 1.21965,
          h: 1.21975,
          l: 1.21925,
          c: 1.21925,
          v: 880300000
        },
        {
          t: 31200,
          o: 1.21924,
          h: 1.2193,
          l: 1.219,
          c: 1.21929,
          v: 748730000
        },
        {
          t: 31500,
          o: 1.21928,
          h: 1.21959,
          l: 1.21925,
          c: 1.21952,
          v: 864580000
        },
        {
          t: 31800,
          o: 1.2195,
          h: 1.21962,
          l: 1.21909,
          c: 1.21953,
          v: 819400000
        },
        {
          t: 32100,
          o: 1.21953,
          h: 1.22035,
          l: 1.21948,
          c: 1.22029,
          v: 1198610000
        },
        {
          t: 32400,
          o: 1.22032,
          h: 1.22072,
          l: 1.22006,
          c: 1.22025,
          v: 1309780000
        },
        {
          t: 32700,
          o: 1.22026,
          h: 1.22057,
          l: 1.22026,
          c: 1.22034,
          v: 874320000
        },
        {
          t: 33000,
          o: 1.22034,
          h: 1.22053,
          l: 1.22009,
          c: 1.22036,
          v: 627820000
        },
        {
          t: 33300,
          o: 1.22036,
          h: 1.22048,
          l: 1.22017,
          c: 1.22024,
          v: 857250000
        },
        {
          t: 33600,
          o: 1.22024,
          h: 1.22043,
          l: 1.22024,
          c: 1.2203,
          v: 629500000
        },
        {
          t: 33900,
          o: 1.2203,
          h: 1.22061,
          l: 1.22008,
          c: 1.22024,
          v: 753360000
        },
        {
          t: 34200,
          o: 1.22025,
          h: 1.22041,
          l: 1.22011,
          c: 1.22035,
          v: 557220000
        },
        {
          t: 34500,
          o: 1.22035,
          h: 1.22035,
          l: 1.2197,
          c: 1.22002,
          v: 873090000
        },
        {
          t: 34800,
          o: 1.22,
          h: 1.22039,
          l: 1.21979,
          c: 1.22035,
          v: 776350000
        },
        {
          t: 35100,
          o: 1.22036,
          h: 1.22059,
          l: 1.22022,
          c: 1.2204,
          v: 1025720001
        },
        {
          t: 35400,
          o: 1.22041,
          h: 1.22053,
          l: 1.22011,
          c: 1.22051,
          v: 783700000
        },
        {
          t: 35700,
          o: 1.2205,
          h: 1.22099,
          l: 1.2205,
          c: 1.22081,
          v: 1157290000
        },
        {
          t: 36000,
          o: 1.22082,
          h: 1.22146,
          l: 1.2208,
          c: 1.22116,
          v: 1249300000
        },
        {
          t: 36300,
          o: 1.22115,
          h: 1.22154,
          l: 1.22093,
          c: 1.2215,
          v: 1053180000
        },
        {
          t: 36600,
          o: 1.2215,
          h: 1.22155,
          l: 1.22128,
          c: 1.22137,
          v: 841270000
        },
        {
          t: 36900,
          o: 1.22137,
          h: 1.22172,
          l: 1.22122,
          c: 1.2217,
          v: 848270000
        },
        {
          t: 37200,
          o: 1.22173,
          h: 1.22228,
          l: 1.22167,
          c: 1.22194,
          v: 1278750000
        },
        {
          t: 37500,
          o: 1.22195,
          h: 1.22206,
          l: 1.2215,
          c: 1.22156,
          v: 896420000
        },
        {
          t: 37800,
          o: 1.22154,
          h: 1.22177,
          l: 1.22151,
          c: 1.22165,
          v: 1000780000
        },
        {
          t: 38100,
          o: 1.22171,
          h: 1.22185,
          l: 1.22153,
          c: 1.2218,
          v: 831010000
        },
        {
          t: 38400,
          o: 1.2218,
          h: 1.2218,
          l: 1.22129,
          c: 1.22132,
          v: 904270001
        },
        {
          t: 38700,
          o: 1.22132,
          h: 1.22154,
          l: 1.22132,
          c: 1.22137,
          v: 641100000
        },
        {
          t: 39000,
          o: 1.22138,
          h: 1.22149,
          l: 1.2211,
          c: 1.2211,
          v: 721120000
        },
        {
          t: 39300,
          o: 1.2211,
          h: 1.22136,
          l: 1.22103,
          c: 1.22109,
          v: 1035920000
        },
        {
          t: 39600,
          o: 1.22109,
          h: 1.22125,
          l: 1.22089,
          c: 1.2209,
          v: 990889999
        },
        {
          t: 39900,
          o: 1.2209,
          h: 1.22122,
          l: 1.22084,
          c: 1.2212,
          v: 585650000
        },
        {
          t: 40200,
          o: 1.2212,
          h: 1.22164,
          l: 1.22104,
          c: 1.22162,
          v: 823800000
        },
        {
          t: 40500,
          o: 1.22161,
          h: 1.22166,
          l: 1.2214,
          c: 1.22152,
          v: 728490000
        },
        {
          t: 40800,
          o: 1.2215,
          h: 1.2215,
          l: 1.22105,
          c: 1.22131,
          v: 658000000
        },
        {
          t: 41100,
          o: 1.22131,
          h: 1.22173,
          l: 1.22127,
          c: 1.22167,
          v: 507280000
        },
        {
          t: 41400,
          o: 1.22163,
          h: 1.22163,
          l: 1.22136,
          c: 1.22138,
          v: 511730000
        },
        {
          t: 41700,
          o: 1.22138,
          h: 1.22199,
          l: 1.22128,
          c: 1.2219,
          v: 824990000
        },
        {
          t: 42000,
          o: 1.22189,
          h: 1.22198,
          l: 1.22132,
          c: 1.22149,
          v: 775440000
        },
        {
          t: 42300,
          o: 1.22148,
          h: 1.22167,
          l: 1.22139,
          c: 1.22155,
          v: 673680000
        },
        {
          t: 42600,
          o: 1.22154,
          h: 1.22161,
          l: 1.22128,
          c: 1.22156,
          v: 628090000
        },
        {
          t: 42900,
          o: 1.22157,
          h: 1.2223,
          l: 1.22156,
          c: 1.22217,
          v: 807930000
        },
        {
          t: 43200,
          o: 1.22212,
          h: 1.22218,
          l: 1.22156,
          c: 1.22158,
          v: 1071850000
        },
        {
          t: 43500,
          o: 1.22158,
          h: 1.22168,
          l: 1.22111,
          c: 1.22153,
          v: 840350000
        },
        {
          t: 43800,
          o: 1.22152,
          h: 1.22152,
          l: 1.22104,
          c: 1.22138,
          v: 1091030000
        },
        {
          t: 44100,
          o: 1.22136,
          h: 1.22174,
          l: 1.22111,
          c: 1.22158,
          v: 696520000
        },
        {
          t: 44400,
          o: 1.22157,
          h: 1.22166,
          l: 1.22126,
          c: 1.22149,
          v: 801740000
        },
        {
          t: 44700,
          o: 1.22149,
          h: 1.22157,
          l: 1.22095,
          c: 1.22112,
          v: 805930000
        },
        {
          t: 45000,
          o: 1.22113,
          h: 1.22121,
          l: 1.22013,
          c: 1.22043,
          v: 1227700000
        },
        {
          t: 45300,
          o: 1.22044,
          h: 1.22048,
          l: 1.21991,
          c: 1.22013,
          v: 955090000
        },
        {
          t: 45600,
          o: 1.22014,
          h: 1.22053,
          l: 1.22001,
          c: 1.22005,
          v: 770420000
        },
        {
          t: 45900,
          o: 1.22005,
          h: 1.22045,
          l: 1.21963,
          c: 1.22039,
          v: 1361370000
        },
        {
          t: 46200,
          o: 1.2204,
          h: 1.22109,
          l: 1.22022,
          c: 1.22102,
          v: 863400000
        },
        {
          t: 46500,
          o: 1.22101,
          h: 1.22109,
          l: 1.2204,
          c: 1.22066,
          v: 745030000
        },
        {
          t: 46800,
          o: 1.22066,
          h: 1.22069,
          l: 1.22013,
          c: 1.22047,
          v: 887790000
        },
        {
          t: 47100,
          o: 1.22045,
          h: 1.22075,
          l: 1.22024,
          c: 1.22057,
          v: 786380000
        },
        {
          t: 47400,
          o: 1.22058,
          h: 1.22145,
          l: 1.22058,
          c: 1.22126,
          v: 1226850000
        },
        {
          t: 47700,
          o: 1.22127,
          h: 1.22203,
          l: 1.22114,
          c: 1.22192,
          v: 1163150000
        },
        {
          t: 48000,
          o: 1.22192,
          h: 1.22198,
          l: 1.22142,
          c: 1.22189,
          v: 1417280000
        },
        {
          t: 48300,
          o: 1.22187,
          h: 1.22252,
          l: 1.22163,
          c: 1.22195,
          v: 1663160000
        },
        {
          t: 48600,
          o: 1.22196,
          h: 1.22206,
          l: 1.22166,
          c: 1.22189,
          v: 1323660000
        },
        {
          t: 48900,
          o: 1.2219,
          h: 1.22248,
          l: 1.2218,
          c: 1.22234,
          v: 1274330000
        },
        {
          t: 49200,
          o: 1.22235,
          h: 1.22312,
          l: 1.22216,
          c: 1.22258,
          v: 1926059999
        },
        {
          t: 49500,
          o: 1.22257,
          h: 1.2228,
          l: 1.22212,
          c: 1.2227,
          v: 1502100000
        },
        {
          t: 49800,
          o: 1.22271,
          h: 1.22327,
          l: 1.22268,
          c: 1.22278,
          v: 1607610000
        },
        {
          t: 50100,
          o: 1.22276,
          h: 1.22345,
          l: 1.2227,
          c: 1.22318,
          v: 1445960000
        },
        {
          t: 50400,
          o: 1.2232,
          h: 1.22323,
          l: 1.22233,
          c: 1.22251,
          v: 1564230000
        },
        {
          t: 50700,
          o: 1.22253,
          h: 1.22257,
          l: 1.22199,
          c: 1.22228,
          v: 1352790000
        },
        {
          t: 51000,
          o: 1.22229,
          h: 1.22346,
          l: 1.22226,
          c: 1.22333,
          v: 1545990000
        },
        {
          t: 51300,
          o: 1.22332,
          h: 1.22337,
          l: 1.22268,
          c: 1.22311,
          v: 1486630000
        },
        {
          t: 51600,
          o: 1.2231,
          h: 1.22314,
          l: 1.22235,
          c: 1.22235,
          v: 1261240000
        },
        {
          t: 51900,
          o: 1.22237,
          h: 1.22245,
          l: 1.22195,
          c: 1.22233,
          v: 1461430000
        },
        {
          t: 52200,
          o: 1.22232,
          h: 1.22262,
          l: 1.22221,
          c: 1.22227,
          v: 1019530000
        },
        {
          t: 52500,
          o: 1.22226,
          h: 1.22239,
          l: 1.2218,
          c: 1.22183,
          v: 1419279999
        },
        {
          t: 52800,
          o: 1.22184,
          h: 1.2224,
          l: 1.22182,
          c: 1.22236,
          v: 1053280000
        },
        {
          t: 53100,
          o: 1.22235,
          h: 1.22239,
          l: 1.22184,
          c: 1.2222,
          v: 916960000
        },
        {
          t: 53400,
          o: 1.22221,
          h: 1.22234,
          l: 1.22161,
          c: 1.22165,
          v: 1103560000
        },
        {
          t: 53700,
          o: 1.22166,
          h: 1.22188,
          l: 1.22133,
          c: 1.22146,
          v: 1346110000
        },
        {
          t: 54000,
          o: 1.22147,
          h: 1.22154,
          l: 1.22103,
          c: 1.22135,
          v: 1339970000
        },
        {
          t: 54300,
          o: 1.22137,
          h: 1.22146,
          l: 1.22102,
          c: 1.22102,
          v: 1330310001
        },
        {
          t: 54600,
          o: 1.22102,
          h: 1.22162,
          l: 1.22093,
          c: 1.22155,
          v: 1173870000
        },
        {
          t: 54900,
          o: 1.22159,
          h: 1.22194,
          l: 1.22137,
          c: 1.22186,
          v: 1259480001
        },
        {
          t: 55200,
          o: 1.2219,
          h: 1.22203,
          l: 1.22175,
          c: 1.22188,
          v: 1196620001
        },
        {
          t: 55500,
          o: 1.22187,
          h: 1.22216,
          l: 1.22178,
          c: 1.22196,
          v: 790450000
        },
        {
          t: 55800,
          o: 1.22195,
          h: 1.22246,
          l: 1.22189,
          c: 1.2224,
          v: 1186690000
        },
        {
          t: 56100,
          o: 1.2224,
          h: 1.2229,
          l: 1.22239,
          c: 1.22282,
          v: 1134090000
        },
        {
          t: 56400,
          o: 1.22281,
          h: 1.22283,
          l: 1.22223,
          c: 1.22245,
          v: 1028240000
        },
        {
          t: 56700,
          o: 1.22246,
          h: 1.2227,
          l: 1.22236,
          c: 1.22265,
          v: 1473660000
        },
        {
          t: 57000,
          o: 1.22266,
          h: 1.22279,
          l: 1.22213,
          c: 1.22249,
          v: 1117379998
        },
        {
          t: 57300,
          o: 1.22248,
          h: 1.22277,
          l: 1.22238,
          c: 1.22277,
          v: 948400000
        },
        {
          t: 57600,
          o: 1.22277,
          h: 1.22278,
          l: 1.22235,
          c: 1.22248,
          v: 1016970000
        },
        {
          t: 57900,
          o: 1.22248,
          h: 1.22262,
          l: 1.22225,
          c: 1.22226,
          v: 1249870000
        },
        {
          t: 58200,
          o: 1.22226,
          h: 1.22254,
          l: 1.22224,
          c: 1.22244,
          v: 1026880000
        },
        {
          t: 58500,
          o: 1.22244,
          h: 1.22316,
          l: 1.2224,
          c: 1.22316,
          v: 1311380000
        },
        {
          t: 58800,
          o: 1.22316,
          h: 1.22336,
          l: 1.22292,
          c: 1.22335,
          v: 1329480000
        },
        {
          t: 59100,
          o: 1.22337,
          h: 1.22342,
          l: 1.22283,
          c: 1.22306,
          v: 1133600000
        },
        {
          t: 59400,
          o: 1.22306,
          h: 1.22327,
          l: 1.22285,
          c: 1.22306,
          v: 1031350000
        },
        {
          t: 59700,
          o: 1.22308,
          h: 1.22317,
          l: 1.22275,
          c: 1.22291,
          v: 1011800000
        },
        {
          t: 60000,
          o: 1.2229,
          h: 1.22316,
          l: 1.22274,
          c: 1.22315,
          v: 811400000
        },
        {
          t: 60300,
          o: 1.22316,
          h: 1.22329,
          l: 1.2231,
          c: 1.22329,
          v: 919830000
        },
        {
          t: 60600,
          o: 1.22329,
          h: 1.22367,
          l: 1.22321,
          c: 1.22356,
          v: 998730000
        },
        {
          t: 60900,
          o: 1.22357,
          h: 1.22434,
          l: 1.22355,
          c: 1.22404,
          v: 1331160001
        },
        {
          t: 61200,
          o: 1.22404,
          h: 1.22414,
          l: 1.22344,
          c: 1.22347,
          v: 1090740000
        },
        {
          t: 61500,
          o: 1.22348,
          h: 1.22406,
          l: 1.22319,
          c: 1.22374,
          v: 1192860000
        },
        {
          t: 61800,
          o: 1.22373,
          h: 1.22378,
          l: 1.22344,
          c: 1.22351,
          v: 1109570000
        },
        {
          t: 62100,
          o: 1.22355,
          h: 1.22364,
          l: 1.22335,
          c: 1.22362,
          v: 1027370000
        },
        {
          t: 62400,
          o: 1.22362,
          h: 1.22387,
          l: 1.22355,
          c: 1.22372,
          v: 973980000
        },
        {
          t: 62700,
          o: 1.22371,
          h: 1.22388,
          l: 1.22365,
          c: 1.22386,
          v: 938360000
        },
        {
          t: 63000,
          o: 1.22388,
          h: 1.22399,
          l: 1.22371,
          c: 1.2238,
          v: 903960000
        },
        {
          t: 63300,
          o: 1.22381,
          h: 1.22424,
          l: 1.22379,
          c: 1.2239,
          v: 1104480000
        },
        {
          t: 63600,
          o: 1.22391,
          h: 1.22391,
          l: 1.22359,
          c: 1.22372,
          v: 833590000
        },
        {
          t: 63900,
          o: 1.22372,
          h: 1.22375,
          l: 1.22338,
          c: 1.22345,
          v: 701400000
        },
        {
          t: 64200,
          o: 1.22343,
          h: 1.22403,
          l: 1.22343,
          c: 1.22396,
          v: 883390000
        },
        {
          t: 64500,
          o: 1.22397,
          h: 1.22431,
          l: 1.22396,
          c: 1.22421,
          v: 918550000
        },
        {
          t: 64800,
          o: 1.22421,
          h: 1.22432,
          l: 1.22398,
          c: 1.22431,
          v: 967490000
        },
        {
          t: 65100,
          o: 1.22431,
          h: 1.22448,
          l: 1.22386,
          c: 1.22387,
          v: 1124260000
        },
        {
          t: 65400,
          o: 1.22388,
          h: 1.22391,
          l: 1.22354,
          c: 1.22356,
          v: 1071420000
        },
        {
          t: 65700,
          o: 1.22356,
          h: 1.22406,
          l: 1.22356,
          c: 1.2239,
          v: 915440001
        },
        {
          t: 66000,
          o: 1.22389,
          h: 1.224,
          l: 1.22371,
          c: 1.22384,
          v: 918590000
        },
        {
          t: 66300,
          o: 1.22384,
          h: 1.22398,
          l: 1.22377,
          c: 1.22393,
          v: 800810000
        },
        {
          t: 66600,
          o: 1.22392,
          h: 1.22393,
          l: 1.22375,
          c: 1.22383,
          v: 1192320001
        },
        {
          t: 66900,
          o: 1.22383,
          h: 1.22401,
          l: 1.22371,
          c: 1.224,
          v: 800330000
        },
        {
          t: 67200,
          o: 1.224,
          h: 1.22404,
          l: 1.2237,
          c: 1.2238,
          v: 940720000
        },
        {
          t: 67500,
          o: 1.2238,
          h: 1.22381,
          l: 1.22359,
          c: 1.22376,
          v: 1152330000
        },
        {
          t: 67800,
          o: 1.22376,
          h: 1.2238,
          l: 1.22348,
          c: 1.22369,
          v: 781350000
        },
        {
          t: 68100,
          o: 1.22369,
          h: 1.22375,
          l: 1.22361,
          c: 1.22369,
          v: 912110000
        },
        {
          t: 68400,
          o: 1.22368,
          h: 1.22383,
          l: 1.22354,
          c: 1.22378,
          v: 977900000
        },
        {
          t: 68700,
          o: 1.22379,
          h: 1.22379,
          l: 1.22363,
          c: 1.22367,
          v: 693380000
        },
        {
          t: 69000,
          o: 1.22367,
          h: 1.22375,
          l: 1.22363,
          c: 1.2237,
          v: 581210000
        },
        {
          t: 69300,
          o: 1.22371,
          h: 1.22385,
          l: 1.22364,
          c: 1.2237,
          v: 611290000
        },
        {
          t: 69600,
          o: 1.22369,
          h: 1.22376,
          l: 1.22356,
          c: 1.22359,
          v: 878010000
        },
        {
          t: 69900,
          o: 1.22359,
          h: 1.22367,
          l: 1.22352,
          c: 1.22356,
          v: 813490000
        },
        {
          t: 70200,
          o: 1.22356,
          h: 1.22381,
          l: 1.22356,
          c: 1.22379,
          v: 645620000
        },
        {
          t: 70500,
          o: 1.22381,
          h: 1.22402,
          l: 1.2238,
          c: 1.22399,
          v: 747660000
        },
        {
          t: 70800,
          o: 1.224,
          h: 1.2241,
          l: 1.22379,
          c: 1.22401,
          v: 1025000000
        },
        {
          t: 71100,
          o: 1.22403,
          h: 1.22404,
          l: 1.22376,
          c: 1.22377,
          v: 658050000
        },
        {
          t: 71400,
          o: 1.22378,
          h: 1.22379,
          l: 1.22357,
          c: 1.2236,
          v: 888430000
        },
        {
          t: 71700,
          o: 1.22362,
          h: 1.22366,
          l: 1.2232,
          c: 1.22328,
          v: 664120000
        },
        {
          t: 72000,
          o: 1.2233,
          h: 1.22335,
          l: 1.22323,
          c: 1.22328,
          v: 490880000
        },
        {
          t: 72300,
          o: 1.22327,
          h: 1.22345,
          l: 1.22327,
          c: 1.22344,
          v: 183080000
        },
        {
          t: 72600,
          o: 1.22346,
          h: 1.22354,
          l: 1.22339,
          c: 1.22352,
          v: 283730000
        },
        {
          t: 72900,
          o: 1.22352,
          h: 1.22353,
          l: 1.22344,
          c: 1.22352,
          v: 185480000
        },
        {
          t: 73200,
          o: 1.22352,
          h: 1.22352,
          l: 1.22334,
          c: 1.2234,
          v: 249350000
        },
        {
          t: 73500,
          o: 1.2234,
          h: 1.22345,
          l: 1.22328,
          c: 1.22331,
          v: 239800000
        },
        {
          t: 73800,
          o: 1.22332,
          h: 1.22344,
          l: 1.22331,
          c: 1.22333,
          v: 297330000
        },
        {
          t: 74100,
          o: 1.22333,
          h: 1.22341,
          l: 1.22327,
          c: 1.22331,
          v: 205080000
        },
        {
          t: 74400,
          o: 1.22331,
          h: 1.22335,
          l: 1.22327,
          c: 1.22327,
          v: 254000000
        },
        {
          t: 74700,
          o: 1.22327,
          h: 1.22328,
          l: 1.22316,
          c: 1.22317,
          v: 188860000
        },
        {
          t: 75000,
          o: 1.22317,
          h: 1.2232,
          l: 1.22312,
          c: 1.22313,
          v: 195940000
        },
        {
          t: 75300,
          o: 1.22313,
          h: 1.22333,
          l: 1.22312,
          c: 1.2232,
          v: 402260002
        },
        {
          t: 75600,
          o: 1.22318,
          h: 1.22325,
          l: 1.22308,
          c: 1.22325,
          v: 82330000
        },
        {
          t: 75900,
          o: 1.22324,
          h: 1.22348,
          l: 1.22319,
          c: 1.22347,
          v: 185740000
        },
        {
          t: 76200,
          o: 1.22347,
          h: 1.22347,
          l: 1.22329,
          c: 1.2233,
          v: 226370000
        },
        {
          t: 76500,
          o: 1.2233,
          h: 1.22338,
          l: 1.2233,
          c: 1.2233,
          v: 231160000
        },
        {
          t: 76800,
          o: 1.2233,
          h: 1.22338,
          l: 1.22314,
          c: 1.22336,
          v: 317310000
        },
        {
          t: 77100,
          o: 1.22336,
          h: 1.22339,
          l: 1.22333,
          c: 1.22334,
          v: 82320000
        },
        {
          t: 77400,
          o: 1.22337,
          h: 1.22337,
          l: 1.22331,
          c: 1.22333,
          v: 88130000
        },
        {
          t: 77700,
          o: 1.22333,
          h: 1.22336,
          l: 1.22333,
          c: 1.22334,
          v: 71620000
        },
        {
          t: 78000,
          o: 1.22334,
          h: 1.22338,
          l: 1.22331,
          c: 1.22332,
          v: 104860000
        },
        {
          t: 78300,
          o: 1.22332,
          h: 1.22338,
          l: 1.22332,
          c: 1.22338,
          v: 71570000
        },
        {
          t: 78600,
          o: 1.22335,
          h: 1.22337,
          l: 1.22317,
          c: 1.22332,
          v: 208650000
        },
        {
          t: 78900,
          o: 1.22332,
          h: 1.22336,
          l: 1.22322,
          c: 1.22334,
          v: 172610000
        },
        {
          t: 79200,
          o: 1.22334,
          h: 1.22334,
          l: 1.22316,
          c: 1.22318,
          v: 193290000
        },
        {
          t: 79500,
          o: 1.22318,
          h: 1.2233,
          l: 1.22312,
          c: 1.22325,
          v: 466490000
        },
        {
          t: 79800,
          o: 1.22325,
          h: 1.22333,
          l: 1.22321,
          c: 1.22322,
          v: 585260000
        },
        {
          t: 80100,
          o: 1.22321,
          h: 1.22324,
          l: 1.22308,
          c: 1.22309,
          v: 397890000
        },
        {
          t: 80400,
          o: 1.2231,
          h: 1.22317,
          l: 1.22309,
          c: 1.22316,
          v: 181920000
        },
        {
          t: 80700,
          o: 1.22316,
          h: 1.2233,
          l: 1.22316,
          c: 1.22328,
          v: 358850000
        },
        {
          t: 81000,
          o: 1.22328,
          h: 1.2233,
          l: 1.22322,
          c: 1.2233,
          v: 197660000
        },
        {
          t: 81300,
          o: 1.22331,
          h: 1.22333,
          l: 1.2233,
          c: 1.2233,
          v: 140120000
        },
        {
          t: 81600,
          o: 1.2233,
          h: 1.22331,
          l: 1.22325,
          c: 1.22326,
          v: 136160000
        },
        {
          t: 81900,
          o: 1.22326,
          h: 1.22337,
          l: 1.22321,
          c: 1.22327,
          v: 171470000
        },
        {
          t: 82200,
          o: 1.22327,
          h: 1.22333,
          l: 1.22327,
          c: 1.22333,
          v: 96380000
        },
        {
          t: 82500,
          o: 1.22332,
          h: 1.22332,
          l: 1.22324,
          c: 1.22326,
          v: 137350000
        },
        {
          t: 82800,
          o: 1.22325,
          h: 1.22334,
          l: 1.22323,
          c: 1.22333,
          v: 145200000
        },
        {
          t: 83100,
          o: 1.22336,
          h: 1.22345,
          l: 1.22333,
          c: 1.22343,
          v: 190010000
        },
        {
          t: 83400,
          o: 1.22346,
          h: 1.22353,
          l: 1.22339,
          c: 1.22346,
          v: 263650000
        },
        {
          t: 83700,
          o: 1.22345,
          h: 1.22355,
          l: 1.2234,
          c: 1.22351,
          v: 265570000
        },
        {
          t: 84000,
          o: 1.22351,
          h: 1.22355,
          l: 1.22345,
          c: 1.22355,
          v: 455830000
        },
        {
          t: 84300,
          o: 1.22355,
          h: 1.22371,
          l: 1.2235,
          c: 1.22366,
          v: 349240000
        },
        {
          t: 84600,
          o: 1.22366,
          h: 1.22367,
          l: 1.22355,
          c: 1.22362,
          v: 254270000
        },
        {
          t: 84900,
          o: 1.22361,
          h: 1.22377,
          l: 1.22355,
          c: 1.2237,
          v: 368590000
        },
        {
          t: 85200,
          o: 1.2237,
          h: 1.22374,
          l: 1.2236,
          c: 1.22371,
          v: 364690000
        },
        {
          t: 85500,
          o: 1.22372,
          h: 1.22378,
          l: 1.22365,
          c: 1.22367,
          v: 400140000
        },
        {
          t: 85800,
          o: 1.22367,
          h: 1.22387,
          l: 1.22366,
          c: 1.22373,
          v: 347530000
        },
        {
          t: 86100,
          o: 1.22373,
          h: 1.22378,
          l: 1.22371,
          c: 1.22372,
          v: 213310000
        }
      ]
    },
    {
      d: 1524614400,
      b: [
        {
          t: 0,
          o: 1.22372,
          h: 1.22372,
          l: 1.2232,
          c: 1.22328,
          v: 731370000
        },
        {
          t: 300,
          o: 1.22327,
          h: 1.22331,
          l: 1.22308,
          c: 1.22318,
          v: 522900000
        },
        {
          t: 600,
          o: 1.22318,
          h: 1.22324,
          l: 1.22309,
          c: 1.22323,
          v: 519550000
        },
        {
          t: 900,
          o: 1.22324,
          h: 1.22329,
          l: 1.22312,
          c: 1.22312,
          v: 305680000
        },
        {
          t: 1200,
          o: 1.22312,
          h: 1.22317,
          l: 1.22296,
          c: 1.22296,
          v: 639020000
        },
        {
          t: 1500,
          o: 1.22295,
          h: 1.22298,
          l: 1.22279,
          c: 1.22289,
          v: 563100000
        },
        {
          t: 1800,
          o: 1.22287,
          h: 1.22298,
          l: 1.22263,
          c: 1.22272,
          v: 703940000
        },
        {
          t: 2100,
          o: 1.2227,
          h: 1.22284,
          l: 1.22268,
          c: 1.22272,
          v: 620380000
        },
        {
          t: 2400,
          o: 1.22272,
          h: 1.22277,
          l: 1.22246,
          c: 1.22262,
          v: 555740000
        },
        {
          t: 2700,
          o: 1.22261,
          h: 1.22264,
          l: 1.22245,
          c: 1.22248,
          v: 513100000
        },
        {
          t: 3000,
          o: 1.22247,
          h: 1.22298,
          l: 1.22246,
          c: 1.22283,
          v: 899070000
        },
        {
          t: 3300,
          o: 1.22283,
          h: 1.2231,
          l: 1.22281,
          c: 1.22294,
          v: 636060000
        },
        {
          t: 3600,
          o: 1.22294,
          h: 1.22306,
          l: 1.2229,
          c: 1.22306,
          v: 442650000
        },
        {
          t: 3900,
          o: 1.22306,
          h: 1.22306,
          l: 1.22299,
          c: 1.22305,
          v: 350910000
        },
        {
          t: 4200,
          o: 1.22304,
          h: 1.22304,
          l: 1.22248,
          c: 1.22258,
          v: 730600000
        },
        {
          t: 4500,
          o: 1.22258,
          h: 1.22268,
          l: 1.22222,
          c: 1.22229,
          v: 938960000
        },
        {
          t: 4800,
          o: 1.22229,
          h: 1.22238,
          l: 1.22206,
          c: 1.22231,
          v: 1155740001
        },
        {
          t: 5100,
          o: 1.2223,
          h: 1.22246,
          l: 1.22214,
          c: 1.2223,
          v: 902060000
        },
        {
          t: 5400,
          o: 1.2223,
          h: 1.22245,
          l: 1.22224,
          c: 1.22234,
          v: 636010000
        },
        {
          t: 5700,
          o: 1.22233,
          h: 1.22237,
          l: 1.22209,
          c: 1.22226,
          v: 781520000
        },
        {
          t: 6000,
          o: 1.22226,
          h: 1.22244,
          l: 1.22211,
          c: 1.22237,
          v: 762970000
        },
        {
          t: 6300,
          o: 1.22237,
          h: 1.22255,
          l: 1.22228,
          c: 1.22228,
          v: 680660000
        },
        {
          t: 6600,
          o: 1.2223,
          h: 1.2225,
          l: 1.22224,
          c: 1.2223,
          v: 782590000
        },
        {
          t: 6900,
          o: 1.2223,
          h: 1.22231,
          l: 1.2221,
          c: 1.22215,
          v: 628050000
        },
        {
          t: 7200,
          o: 1.22215,
          h: 1.22251,
          l: 1.22213,
          c: 1.2223,
          v: 936240000
        },
        {
          t: 7500,
          o: 1.22232,
          h: 1.22245,
          l: 1.2223,
          c: 1.22241,
          v: 852200000
        },
        {
          t: 7800,
          o: 1.22242,
          h: 1.22256,
          l: 1.2224,
          c: 1.22254,
          v: 444780000
        },
        {
          t: 8100,
          o: 1.22254,
          h: 1.22256,
          l: 1.22239,
          c: 1.22246,
          v: 486480000
        },
        {
          t: 8400,
          o: 1.22246,
          h: 1.22251,
          l: 1.22242,
          c: 1.22245,
          v: 291180000
        },
        {
          t: 8700,
          o: 1.22245,
          h: 1.22256,
          l: 1.22239,
          c: 1.22256,
          v: 417900000
        },
        {
          t: 9000,
          o: 1.22253,
          h: 1.22257,
          l: 1.22248,
          c: 1.22257,
          v: 391550000
        },
        {
          t: 9300,
          o: 1.22259,
          h: 1.22279,
          l: 1.22256,
          c: 1.22279,
          v: 455330000
        },
        {
          t: 9600,
          o: 1.2228,
          h: 1.22283,
          l: 1.22256,
          c: 1.22261,
          v: 591950004
        },
        {
          t: 9900,
          o: 1.22262,
          h: 1.22267,
          l: 1.2226,
          c: 1.22261,
          v: 262890000
        },
        {
          t: 10200,
          o: 1.22261,
          h: 1.22266,
          l: 1.2226,
          c: 1.22263,
          v: 302160000
        },
        {
          t: 10500,
          o: 1.22263,
          h: 1.22263,
          l: 1.22255,
          c: 1.22256,
          v: 271790000
        },
        {
          t: 10800,
          o: 1.22256,
          h: 1.22262,
          l: 1.22254,
          c: 1.22256,
          v: 388820000
        },
        {
          t: 11100,
          o: 1.22257,
          h: 1.22265,
          l: 1.22256,
          c: 1.22256,
          v: 338230000
        },
        {
          t: 11400,
          o: 1.22256,
          h: 1.22265,
          l: 1.22256,
          c: 1.22261,
          v: 233310000
        },
        {
          t: 11700,
          o: 1.2226,
          h: 1.22266,
          l: 1.22259,
          c: 1.22261,
          v: 174990000
        },
        {
          t: 12000,
          o: 1.22261,
          h: 1.22273,
          l: 1.22261,
          c: 1.22271,
          v: 322130000
        },
        {
          t: 12300,
          o: 1.22272,
          h: 1.22308,
          l: 1.2227,
          c: 1.22293,
          v: 404460000
        },
        {
          t: 12600,
          o: 1.22292,
          h: 1.22305,
          l: 1.2229,
          c: 1.22299,
          v: 442490000
        },
        {
          t: 12900,
          o: 1.22299,
          h: 1.22305,
          l: 1.22297,
          c: 1.22301,
          v: 383870000
        },
        {
          t: 13200,
          o: 1.22302,
          h: 1.22302,
          l: 1.22278,
          c: 1.22283,
          v: 402040000
        },
        {
          t: 13500,
          o: 1.22283,
          h: 1.22285,
          l: 1.22271,
          c: 1.22276,
          v: 486020000
        },
        {
          t: 13800,
          o: 1.22277,
          h: 1.22277,
          l: 1.22259,
          c: 1.2226,
          v: 481410000
        },
        {
          t: 14100,
          o: 1.22259,
          h: 1.22264,
          l: 1.22228,
          c: 1.22234,
          v: 769140000
        },
        {
          t: 14400,
          o: 1.22233,
          h: 1.22235,
          l: 1.22219,
          c: 1.22229,
          v: 589780000
        },
        {
          t: 14700,
          o: 1.22229,
          h: 1.22229,
          l: 1.22211,
          c: 1.22226,
          v: 324940000
        },
        {
          t: 15000,
          o: 1.22226,
          h: 1.22231,
          l: 1.22215,
          c: 1.22215,
          v: 308860000
        },
        {
          t: 15300,
          o: 1.22216,
          h: 1.22217,
          l: 1.22205,
          c: 1.22205,
          v: 374600000
        },
        {
          t: 15600,
          o: 1.22207,
          h: 1.22227,
          l: 1.22207,
          c: 1.22221,
          v: 386630000
        },
        {
          t: 15900,
          o: 1.2222,
          h: 1.22222,
          l: 1.22216,
          c: 1.22221,
          v: 103600000
        },
        {
          t: 16200,
          o: 1.22222,
          h: 1.22222,
          l: 1.22185,
          c: 1.22185,
          v: 556380000
        },
        {
          t: 16500,
          o: 1.22185,
          h: 1.22186,
          l: 1.2217,
          c: 1.22179,
          v: 883600000
        },
        {
          t: 16800,
          o: 1.2218,
          h: 1.22201,
          l: 1.2218,
          c: 1.22191,
          v: 434240000
        },
        {
          t: 17100,
          o: 1.22191,
          h: 1.22196,
          l: 1.22169,
          c: 1.2217,
          v: 414990000
        },
        {
          t: 17400,
          o: 1.22169,
          h: 1.22189,
          l: 1.22169,
          c: 1.22181,
          v: 435380000
        },
        {
          t: 17700,
          o: 1.22181,
          h: 1.22182,
          l: 1.22153,
          c: 1.22171,
          v: 568950000
        },
        {
          t: 18000,
          o: 1.2217,
          h: 1.22176,
          l: 1.22146,
          c: 1.22171,
          v: 792079999
        },
        {
          t: 18300,
          o: 1.22173,
          h: 1.22178,
          l: 1.22132,
          c: 1.22157,
          v: 911960000
        },
        {
          t: 18600,
          o: 1.22157,
          h: 1.22167,
          l: 1.22151,
          c: 1.22161,
          v: 320320000
        },
        {
          t: 18900,
          o: 1.22162,
          h: 1.22182,
          l: 1.22161,
          c: 1.22171,
          v: 369320000
        },
        {
          t: 19200,
          o: 1.22172,
          h: 1.22173,
          l: 1.22148,
          c: 1.22152,
          v: 299460000
        },
        {
          t: 19500,
          o: 1.22151,
          h: 1.22158,
          l: 1.22119,
          c: 1.22124,
          v: 831210000
        },
        {
          t: 19800,
          o: 1.22125,
          h: 1.22157,
          l: 1.22117,
          c: 1.22121,
          v: 661190000
        },
        {
          t: 20100,
          o: 1.22121,
          h: 1.22133,
          l: 1.22113,
          c: 1.22124,
          v: 708960000
        },
        {
          t: 20400,
          o: 1.22125,
          h: 1.22151,
          l: 1.22125,
          c: 1.22147,
          v: 709150000
        },
        {
          t: 20700,
          o: 1.22147,
          h: 1.22148,
          l: 1.22126,
          c: 1.22132,
          v: 569180000
        },
        {
          t: 21000,
          o: 1.22132,
          h: 1.22143,
          l: 1.22101,
          c: 1.22106,
          v: 704910000
        },
        {
          t: 21300,
          o: 1.22107,
          h: 1.22142,
          l: 1.22106,
          c: 1.22141,
          v: 652630000
        },
        {
          t: 21600,
          o: 1.22141,
          h: 1.22147,
          l: 1.22091,
          c: 1.2211,
          v: 1501310000
        },
        {
          t: 21900,
          o: 1.22112,
          h: 1.22122,
          l: 1.22072,
          c: 1.2208,
          v: 1208300000
        },
        {
          t: 22200,
          o: 1.2208,
          h: 1.22091,
          l: 1.22051,
          c: 1.22057,
          v: 1300550000
        },
        {
          t: 22500,
          o: 1.22057,
          h: 1.22105,
          l: 1.22057,
          c: 1.22101,
          v: 1113500000
        },
        {
          t: 22800,
          o: 1.22099,
          h: 1.22126,
          l: 1.22099,
          c: 1.22124,
          v: 733700000
        },
        {
          t: 23100,
          o: 1.22122,
          h: 1.22122,
          l: 1.22084,
          c: 1.22115,
          v: 986940000
        },
        {
          t: 23400,
          o: 1.22115,
          h: 1.22118,
          l: 1.22065,
          c: 1.22076,
          v: 1156380000
        },
        {
          t: 23700,
          o: 1.22078,
          h: 1.22101,
          l: 1.22065,
          c: 1.221,
          v: 1135620000
        },
        {
          t: 24000,
          o: 1.22102,
          h: 1.22147,
          l: 1.221,
          c: 1.2213,
          v: 1355460000
        },
        {
          t: 24300,
          o: 1.22131,
          h: 1.22176,
          l: 1.22128,
          c: 1.22165,
          v: 1166070000
        },
        {
          t: 24600,
          o: 1.22165,
          h: 1.22165,
          l: 1.22129,
          c: 1.22148,
          v: 1075290000
        },
        {
          t: 24900,
          o: 1.22147,
          h: 1.22152,
          l: 1.22127,
          c: 1.22136,
          v: 1168680000
        },
        {
          t: 25200,
          o: 1.22134,
          h: 1.22135,
          l: 1.22059,
          c: 1.22065,
          v: 1355110000
        },
        {
          t: 25500,
          o: 1.22066,
          h: 1.22075,
          l: 1.22016,
          c: 1.22041,
          v: 1074510000
        },
        {
          t: 25800,
          o: 1.22043,
          h: 1.22067,
          l: 1.22004,
          c: 1.22016,
          v: 1225390000
        },
        {
          t: 26100,
          o: 1.22015,
          h: 1.22022,
          l: 1.21952,
          c: 1.21959,
          v: 1180099999
        },
        {
          t: 26400,
          o: 1.2196,
          h: 1.21969,
          l: 1.21904,
          c: 1.21909,
          v: 1247230000
        },
        {
          t: 26700,
          o: 1.21909,
          h: 1.21951,
          l: 1.219,
          c: 1.21944,
          v: 1011560000
        },
        {
          t: 27000,
          o: 1.21944,
          h: 1.21978,
          l: 1.21935,
          c: 1.21964,
          v: 890330000
        },
        {
          t: 27300,
          o: 1.2196,
          h: 1.21985,
          l: 1.21949,
          c: 1.21964,
          v: 958380001
        },
        {
          t: 27600,
          o: 1.21965,
          h: 1.22026,
          l: 1.2195,
          c: 1.21954,
          v: 1073210000
        },
        {
          t: 27900,
          o: 1.21954,
          h: 1.2198,
          l: 1.2194,
          c: 1.21974,
          v: 1162250000
        },
        {
          t: 28200,
          o: 1.21974,
          h: 1.21975,
          l: 1.2191,
          c: 1.21939,
          v: 1092940000
        },
        {
          t: 28500,
          o: 1.21938,
          h: 1.21942,
          l: 1.21878,
          c: 1.21879,
          v: 1139450000
        },
        {
          t: 28800,
          o: 1.2188,
          h: 1.21962,
          l: 1.21867,
          c: 1.2196,
          v: 1156770000
        },
        {
          t: 29100,
          o: 1.2196,
          h: 1.21965,
          l: 1.21917,
          c: 1.21959,
          v: 1065850000
        },
        {
          t: 29400,
          o: 1.21959,
          h: 1.21969,
          l: 1.21931,
          c: 1.21941,
          v: 928180000
        },
        {
          t: 29700,
          o: 1.21941,
          h: 1.21959,
          l: 1.21894,
          c: 1.219,
          v: 876490000
        },
        {
          t: 30000,
          o: 1.21899,
          h: 1.21957,
          l: 1.21898,
          c: 1.21955,
          v: 1268269999
        },
        {
          t: 30300,
          o: 1.21956,
          h: 1.22031,
          l: 1.21952,
          c: 1.22022,
          v: 1056870000
        },
        {
          t: 30600,
          o: 1.22022,
          h: 1.22027,
          l: 1.21966,
          c: 1.21973,
          v: 1071370000
        },
        {
          t: 30900,
          o: 1.21973,
          h: 1.22006,
          l: 1.21965,
          c: 1.21992,
          v: 821210000
        },
        {
          t: 31200,
          o: 1.21992,
          h: 1.22009,
          l: 1.21966,
          c: 1.22008,
          v: 900860000
        },
        {
          t: 31500,
          o: 1.22008,
          h: 1.22034,
          l: 1.22001,
          c: 1.22016,
          v: 976550000
        },
        {
          t: 31800,
          o: 1.22016,
          h: 1.22037,
          l: 1.22008,
          c: 1.22011,
          v: 778680000
        },
        {
          t: 32100,
          o: 1.22015,
          h: 1.22037,
          l: 1.22009,
          c: 1.22027,
          v: 823669999
        },
        {
          t: 32400,
          o: 1.22027,
          h: 1.22047,
          l: 1.22004,
          c: 1.22042,
          v: 833380000
        },
        {
          t: 32700,
          o: 1.22042,
          h: 1.22063,
          l: 1.22008,
          c: 1.22048,
          v: 899450000
        },
        {
          t: 33000,
          o: 1.22049,
          h: 1.22072,
          l: 1.2204,
          c: 1.22072,
          v: 828920000
        },
        {
          t: 33300,
          o: 1.22074,
          h: 1.22091,
          l: 1.2207,
          c: 1.22076,
          v: 864680000
        },
        {
          t: 33600,
          o: 1.22076,
          h: 1.22096,
          l: 1.22068,
          c: 1.22092,
          v: 760600000
        },
        {
          t: 33900,
          o: 1.22093,
          h: 1.22123,
          l: 1.22085,
          c: 1.2211,
          v: 1022420000
        },
        {
          t: 34200,
          o: 1.22108,
          h: 1.22153,
          l: 1.22103,
          c: 1.22109,
          v: 980060000
        },
        {
          t: 34500,
          o: 1.2211,
          h: 1.22124,
          l: 1.22087,
          c: 1.2212,
          v: 723920000
        },
        {
          t: 34800,
          o: 1.22118,
          h: 1.22145,
          l: 1.22105,
          c: 1.22118,
          v: 650640000
        },
        {
          t: 35100,
          o: 1.22119,
          h: 1.22143,
          l: 1.22092,
          c: 1.22113,
          v: 1078490000
        },
        {
          t: 35400,
          o: 1.22113,
          h: 1.22124,
          l: 1.2209,
          c: 1.22096,
          v: 712680000
        },
        {
          t: 35700,
          o: 1.22095,
          h: 1.22099,
          l: 1.22057,
          c: 1.22058,
          v: 888780000
        },
        {
          t: 36000,
          o: 1.22057,
          h: 1.22102,
          l: 1.22046,
          c: 1.22098,
          v: 978319998
        },
        {
          t: 36300,
          o: 1.22098,
          h: 1.22118,
          l: 1.22079,
          c: 1.22095,
          v: 1186699999
        },
        {
          t: 36600,
          o: 1.22096,
          h: 1.221,
          l: 1.22058,
          c: 1.22061,
          v: 1012719999
        },
        {
          t: 36900,
          o: 1.22061,
          h: 1.2209,
          l: 1.2206,
          c: 1.22075,
          v: 970740000
        },
        {
          t: 37200,
          o: 1.22076,
          h: 1.22082,
          l: 1.22055,
          c: 1.22066,
          v: 808590000
        },
        {
          t: 37500,
          o: 1.22067,
          h: 1.22112,
          l: 1.22052,
          c: 1.22095,
          v: 962310000
        },
        {
          t: 37800,
          o: 1.22097,
          h: 1.22107,
          l: 1.22033,
          c: 1.22044,
          v: 903940000
        },
        {
          t: 38100,
          o: 1.22043,
          h: 1.22084,
          l: 1.22039,
          c: 1.22083,
          v: 812740000
        },
        {
          t: 38400,
          o: 1.22081,
          h: 1.22082,
          l: 1.22041,
          c: 1.22053,
          v: 833960000
        },
        {
          t: 38700,
          o: 1.22053,
          h: 1.22056,
          l: 1.22007,
          c: 1.22015,
          v: 1146590000
        },
        {
          t: 39000,
          o: 1.22015,
          h: 1.22026,
          l: 1.21982,
          c: 1.21984,
          v: 1218009999
        },
        {
          t: 39300,
          o: 1.21986,
          h: 1.22046,
          l: 1.21984,
          c: 1.22021,
          v: 904550000
        },
        {
          t: 39600,
          o: 1.22021,
          h: 1.2205,
          l: 1.2197,
          c: 1.21975,
          v: 1360059999
        },
        {
          t: 39900,
          o: 1.21977,
          h: 1.22049,
          l: 1.21971,
          c: 1.21979,
          v: 1084620000
        },
        {
          t: 40200,
          o: 1.2198,
          h: 1.22011,
          l: 1.21966,
          c: 1.22008,
          v: 986110000
        },
        {
          t: 40500,
          o: 1.22006,
          h: 1.22017,
          l: 1.21971,
          c: 1.21972,
          v: 827240000
        },
        {
          t: 40800,
          o: 1.21972,
          h: 1.21982,
          l: 1.21845,
          c: 1.21871,
          v: 1490770000
        },
        {
          t: 41100,
          o: 1.2187,
          h: 1.21901,
          l: 1.21856,
          c: 1.21879,
          v: 1130730000
        },
        {
          t: 41400,
          o: 1.21877,
          h: 1.21912,
          l: 1.2187,
          c: 1.21881,
          v: 926620000
        },
        {
          t: 41700,
          o: 1.21881,
          h: 1.219,
          l: 1.2182,
          c: 1.21823,
          v: 1214559999
        },
        {
          t: 42000,
          o: 1.21825,
          h: 1.21859,
          l: 1.21817,
          c: 1.2185,
          v: 1264280001
        },
        {
          t: 42300,
          o: 1.2185,
          h: 1.2188,
          l: 1.21799,
          c: 1.21806,
          v: 1441430000
        },
        {
          t: 42600,
          o: 1.21809,
          h: 1.21846,
          l: 1.21796,
          c: 1.21817,
          v: 1301310000
        },
        {
          t: 42900,
          o: 1.21816,
          h: 1.21816,
          l: 1.21749,
          c: 1.21773,
          v: 1372720000
        },
        {
          t: 43200,
          o: 1.21773,
          h: 1.21817,
          l: 1.21748,
          c: 1.21817,
          v: 1689200000
        },
        {
          t: 43500,
          o: 1.21816,
          h: 1.21834,
          l: 1.21791,
          c: 1.21812,
          v: 1083880000
        },
        {
          t: 43800,
          o: 1.21809,
          h: 1.21866,
          l: 1.21809,
          c: 1.21844,
          v: 1071440000
        },
        {
          t: 44100,
          o: 1.21844,
          h: 1.21857,
          l: 1.21813,
          c: 1.21813,
          v: 1194200000
        },
        {
          t: 44400,
          o: 1.21813,
          h: 1.21857,
          l: 1.21793,
          c: 1.21852,
          v: 1514040000
        },
        {
          t: 44700,
          o: 1.2185,
          h: 1.21875,
          l: 1.21824,
          c: 1.21844,
          v: 948570000
        },
        {
          t: 45000,
          o: 1.21844,
          h: 1.21894,
          l: 1.21802,
          c: 1.21874,
          v: 1473000000
        },
        {
          t: 45300,
          o: 1.21873,
          h: 1.21874,
          l: 1.21708,
          c: 1.21716,
          v: 1815699999
        },
        {
          t: 45600,
          o: 1.21715,
          h: 1.21764,
          l: 1.21703,
          c: 1.21747,
          v: 1254519999
        },
        {
          t: 45900,
          o: 1.21751,
          h: 1.21794,
          l: 1.21737,
          c: 1.21791,
          v: 1088340000
        },
        {
          t: 46200,
          o: 1.21791,
          h: 1.21821,
          l: 1.21755,
          c: 1.21762,
          v: 918330000
        },
        {
          t: 46500,
          o: 1.21762,
          h: 1.21847,
          l: 1.21756,
          c: 1.21833,
          v: 994920000
        },
        {
          t: 46800,
          o: 1.21835,
          h: 1.21882,
          l: 1.21812,
          c: 1.21879,
          v: 1252670000
        },
        {
          t: 47100,
          o: 1.2188,
          h: 1.21893,
          l: 1.21859,
          c: 1.21878,
          v: 1223980000
        },
        {
          t: 47400,
          o: 1.21878,
          h: 1.21894,
          l: 1.21812,
          c: 1.21812,
          v: 1107780000
        },
        {
          t: 47700,
          o: 1.21816,
          h: 1.21847,
          l: 1.21811,
          c: 1.21825,
          v: 1262170000
        },
        {
          t: 48000,
          o: 1.21825,
          h: 1.21857,
          l: 1.21818,
          c: 1.21837,
          v: 1092310000
        },
        {
          t: 48300,
          o: 1.21836,
          h: 1.2185,
          l: 1.21824,
          c: 1.21842,
          v: 835030000
        },
        {
          t: 48600,
          o: 1.21842,
          h: 1.21852,
          l: 1.21806,
          c: 1.21827,
          v: 1378890000
        },
        {
          t: 48900,
          o: 1.21824,
          h: 1.21906,
          l: 1.21822,
          c: 1.21886,
          v: 1479450000
        },
        {
          t: 49200,
          o: 1.21885,
          h: 1.21977,
          l: 1.21882,
          c: 1.21944,
          v: 1601620000
        },
        {
          t: 49500,
          o: 1.21943,
          h: 1.21998,
          l: 1.21926,
          c: 1.21927,
          v: 1248950000
        },
        {
          t: 49800,
          o: 1.21928,
          h: 1.21947,
          l: 1.2187,
          c: 1.21879,
          v: 1425040000
        },
        {
          t: 50100,
          o: 1.21878,
          h: 1.21888,
          l: 1.2185,
          c: 1.21879,
          v: 1420939999
        },
        {
          t: 50400,
          o: 1.21879,
          h: 1.21919,
          l: 1.21858,
          c: 1.21867,
          v: 1192450000
        },
        {
          t: 50700,
          o: 1.21867,
          h: 1.21917,
          l: 1.21834,
          c: 1.21834,
          v: 1435340000
        },
        {
          t: 51000,
          o: 1.21833,
          h: 1.21846,
          l: 1.21796,
          c: 1.21833,
          v: 1301850000
        },
        {
          t: 51300,
          o: 1.21833,
          h: 1.21861,
          l: 1.21828,
          c: 1.21847,
          v: 952740000
        },
        {
          t: 51600,
          o: 1.21848,
          h: 1.21875,
          l: 1.21792,
          c: 1.21795,
          v: 965370000
        },
        {
          t: 51900,
          o: 1.21795,
          h: 1.21802,
          l: 1.2171,
          c: 1.21716,
          v: 1467210000
        },
        {
          t: 52200,
          o: 1.21718,
          h: 1.21764,
          l: 1.21698,
          c: 1.21736,
          v: 1640919999
        },
        {
          t: 52500,
          o: 1.21734,
          h: 1.21762,
          l: 1.21718,
          c: 1.21758,
          v: 898990000
        },
        {
          t: 52800,
          o: 1.21759,
          h: 1.2176,
          l: 1.21687,
          c: 1.21693,
          v: 1188110000
        },
        {
          t: 53100,
          o: 1.21696,
          h: 1.2175,
          l: 1.21678,
          c: 1.21699,
          v: 1405850000
        },
        {
          t: 53400,
          o: 1.21697,
          h: 1.21741,
          l: 1.21684,
          c: 1.21729,
          v: 1313040000
        },
        {
          t: 53700,
          o: 1.21729,
          h: 1.21739,
          l: 1.21704,
          c: 1.2172,
          v: 1440360000
        },
        {
          t: 54000,
          o: 1.21723,
          h: 1.21767,
          l: 1.21715,
          c: 1.21763,
          v: 1379840000
        },
        {
          t: 54300,
          o: 1.21761,
          h: 1.21832,
          l: 1.21761,
          c: 1.21781,
          v: 1380620001
        },
        {
          t: 54600,
          o: 1.2178,
          h: 1.21812,
          l: 1.21756,
          c: 1.21812,
          v: 946520000
        },
        {
          t: 54900,
          o: 1.21812,
          h: 1.21846,
          l: 1.21803,
          c: 1.21822,
          v: 1295680001
        },
        {
          t: 55200,
          o: 1.2182,
          h: 1.21886,
          l: 1.21816,
          c: 1.21883,
          v: 1149320000
        },
        {
          t: 55500,
          o: 1.21881,
          h: 1.21896,
          l: 1.21834,
          c: 1.21848,
          v: 1326390000
        },
        {
          t: 55800,
          o: 1.21848,
          h: 1.21873,
          l: 1.21821,
          c: 1.21822,
          v: 1472650000
        },
        {
          t: 56100,
          o: 1.21821,
          h: 1.21838,
          l: 1.21804,
          c: 1.21816,
          v: 1420090000
        },
        {
          t: 56400,
          o: 1.21817,
          h: 1.21824,
          l: 1.21759,
          c: 1.2176,
          v: 1342849999
        },
        {
          t: 56700,
          o: 1.21764,
          h: 1.2177,
          l: 1.21735,
          c: 1.21741,
          v: 984750000
        },
        {
          t: 57000,
          o: 1.2174,
          h: 1.2178,
          l: 1.21738,
          c: 1.21766,
          v: 1063580000
        },
        {
          t: 57300,
          o: 1.21766,
          h: 1.21775,
          l: 1.21733,
          c: 1.21765,
          v: 1252610001
        },
        {
          t: 57600,
          o: 1.21765,
          h: 1.2177,
          l: 1.21724,
          c: 1.2173,
          v: 1409200000
        },
        {
          t: 57900,
          o: 1.21732,
          h: 1.21744,
          l: 1.21717,
          c: 1.21728,
          v: 1152080000
        },
        {
          t: 58200,
          o: 1.21727,
          h: 1.21734,
          l: 1.21703,
          c: 1.21718,
          v: 806190000
        },
        {
          t: 58500,
          o: 1.21718,
          h: 1.21758,
          l: 1.21718,
          c: 1.21758,
          v: 882430000
        },
        {
          t: 58800,
          o: 1.21759,
          h: 1.2176,
          l: 1.21738,
          c: 1.21743,
          v: 652390000
        },
        {
          t: 59100,
          o: 1.21743,
          h: 1.21761,
          l: 1.21721,
          c: 1.21759,
          v: 851600000
        },
        {
          t: 59400,
          o: 1.21759,
          h: 1.21798,
          l: 1.21754,
          c: 1.21798,
          v: 999199999
        },
        {
          t: 59700,
          o: 1.21798,
          h: 1.21831,
          l: 1.2178,
          c: 1.21824,
          v: 1002840000
        },
        {
          t: 60000,
          o: 1.21825,
          h: 1.2184,
          l: 1.21798,
          c: 1.21805,
          v: 803670000
        },
        {
          t: 60300,
          o: 1.21804,
          h: 1.2183,
          l: 1.21794,
          c: 1.21809,
          v: 820380000
        },
        {
          t: 60600,
          o: 1.21808,
          h: 1.2185,
          l: 1.21799,
          c: 1.21847,
          v: 903340000
        },
        {
          t: 60900,
          o: 1.21847,
          h: 1.21873,
          l: 1.21847,
          c: 1.2185,
          v: 1144570000
        },
        {
          t: 61200,
          o: 1.2185,
          h: 1.2187,
          l: 1.2185,
          c: 1.21855,
          v: 791820001
        },
        {
          t: 61500,
          o: 1.21854,
          h: 1.21885,
          l: 1.21844,
          c: 1.21849,
          v: 931130000
        },
        {
          t: 61800,
          o: 1.2185,
          h: 1.21851,
          l: 1.21815,
          c: 1.21831,
          v: 853330000
        },
        {
          t: 62100,
          o: 1.2183,
          h: 1.21847,
          l: 1.21822,
          c: 1.21843,
          v: 646180000
        },
        {
          t: 62400,
          o: 1.21842,
          h: 1.21842,
          l: 1.21809,
          c: 1.21809,
          v: 815580000
        },
        {
          t: 62700,
          o: 1.21808,
          h: 1.21827,
          l: 1.21805,
          c: 1.21825,
          v: 704740000
        },
        {
          t: 63000,
          o: 1.21825,
          h: 1.21878,
          l: 1.21825,
          c: 1.21876,
          v: 1220850000
        },
        {
          t: 63300,
          o: 1.21876,
          h: 1.21876,
          l: 1.21837,
          c: 1.21838,
          v: 1036770000
        },
        {
          t: 63600,
          o: 1.21837,
          h: 1.21841,
          l: 1.21805,
          c: 1.21809,
          v: 717810000
        },
        {
          t: 63900,
          o: 1.21809,
          h: 1.21811,
          l: 1.21776,
          c: 1.2179,
          v: 663640000
        },
        {
          t: 64200,
          o: 1.21789,
          h: 1.2179,
          l: 1.21758,
          c: 1.21763,
          v: 810810000
        },
        {
          t: 64500,
          o: 1.21763,
          h: 1.2177,
          l: 1.2176,
          c: 1.21763,
          v: 663980000
        },
        {
          t: 64800,
          o: 1.21763,
          h: 1.21764,
          l: 1.21749,
          c: 1.21752,
          v: 704900000
        },
        {
          t: 65100,
          o: 1.21753,
          h: 1.21755,
          l: 1.21728,
          c: 1.21731,
          v: 647160000
        },
        {
          t: 65400,
          o: 1.21733,
          h: 1.2174,
          l: 1.21726,
          c: 1.21733,
          v: 677690000
        },
        {
          t: 65700,
          o: 1.21732,
          h: 1.21752,
          l: 1.2173,
          c: 1.21741,
          v: 1020960000
        },
        {
          t: 66000,
          o: 1.21741,
          h: 1.21762,
          l: 1.2173,
          c: 1.21755,
          v: 705170000
        },
        {
          t: 66300,
          o: 1.21755,
          h: 1.21777,
          l: 1.21751,
          c: 1.21776,
          v: 824330000
        },
        {
          t: 66600,
          o: 1.21777,
          h: 1.21777,
          l: 1.21762,
          c: 1.21766,
          v: 496270000
        },
        {
          t: 66900,
          o: 1.21765,
          h: 1.21775,
          l: 1.21759,
          c: 1.21762,
          v: 606830000
        },
        {
          t: 67200,
          o: 1.21763,
          h: 1.21776,
          l: 1.21763,
          c: 1.21764,
          v: 756310000
        },
        {
          t: 67500,
          o: 1.21762,
          h: 1.2178,
          l: 1.21755,
          c: 1.21776,
          v: 784270000
        },
        {
          t: 67800,
          o: 1.21776,
          h: 1.21803,
          l: 1.21776,
          c: 1.21789,
          v: 773040000
        },
        {
          t: 68100,
          o: 1.21789,
          h: 1.21793,
          l: 1.21754,
          c: 1.21754,
          v: 993999999
        },
        {
          t: 68400,
          o: 1.2175,
          h: 1.2175,
          l: 1.21725,
          c: 1.21735,
          v: 947970000
        },
        {
          t: 68700,
          o: 1.21736,
          h: 1.2178,
          l: 1.21736,
          c: 1.21771,
          v: 774530000
        },
        {
          t: 69000,
          o: 1.21771,
          h: 1.21774,
          l: 1.21759,
          c: 1.21769,
          v: 597840000
        },
        {
          t: 69300,
          o: 1.21768,
          h: 1.21776,
          l: 1.21757,
          c: 1.21776,
          v: 495680000
        },
        {
          t: 69600,
          o: 1.21776,
          h: 1.21776,
          l: 1.2173,
          c: 1.21733,
          v: 699450000
        },
        {
          t: 69900,
          o: 1.21733,
          h: 1.21751,
          l: 1.21723,
          c: 1.21749,
          v: 1160550001
        },
        {
          t: 70200,
          o: 1.2175,
          h: 1.21772,
          l: 1.21749,
          c: 1.21763,
          v: 853260000
        },
        {
          t: 70500,
          o: 1.21768,
          h: 1.21774,
          l: 1.21745,
          c: 1.21745,
          v: 815440000
        },
        {
          t: 70800,
          o: 1.21744,
          h: 1.21751,
          l: 1.21734,
          c: 1.21741,
          v: 846680000
        },
        {
          t: 71100,
          o: 1.21741,
          h: 1.21742,
          l: 1.21715,
          c: 1.21734,
          v: 1048350000
        },
        {
          t: 71400,
          o: 1.21734,
          h: 1.21736,
          l: 1.2171,
          c: 1.21712,
          v: 462160000
        },
        {
          t: 71700,
          o: 1.2171,
          h: 1.21722,
          l: 1.21706,
          c: 1.21708,
          v: 761880000
        },
        {
          t: 72000,
          o: 1.21707,
          h: 1.21721,
          l: 1.21704,
          c: 1.21716,
          v: 513460000
        },
        {
          t: 72300,
          o: 1.21717,
          h: 1.21727,
          l: 1.21716,
          c: 1.21723,
          v: 191720000
        },
        {
          t: 72600,
          o: 1.21725,
          h: 1.2173,
          l: 1.21719,
          c: 1.21719,
          v: 135000000
        },
        {
          t: 72900,
          o: 1.2172,
          h: 1.21721,
          l: 1.21706,
          c: 1.21707,
          v: 253650000
        },
        {
          t: 73200,
          o: 1.21707,
          h: 1.21709,
          l: 1.21676,
          c: 1.21685,
          v: 393490000
        },
        {
          t: 73500,
          o: 1.21685,
          h: 1.21696,
          l: 1.21666,
          c: 1.21675,
          v: 513240000
        },
        {
          t: 73800,
          o: 1.21676,
          h: 1.21681,
          l: 1.21671,
          c: 1.21679,
          v: 364460000
        },
        {
          t: 74100,
          o: 1.21679,
          h: 1.21685,
          l: 1.21615,
          c: 1.21615,
          v: 505610000
        },
        {
          t: 74400,
          o: 1.21616,
          h: 1.2163,
          l: 1.21612,
          c: 1.2163,
          v: 511970000
        },
        {
          t: 74700,
          o: 1.21631,
          h: 1.21633,
          l: 1.21609,
          c: 1.21618,
          v: 447470000
        },
        {
          t: 75000,
          o: 1.21618,
          h: 1.21623,
          l: 1.21608,
          c: 1.2161,
          v: 293850000
        },
        {
          t: 75300,
          o: 1.21611,
          h: 1.21614,
          l: 1.216,
          c: 1.21605,
          v: 276530000
        },
        {
          t: 75600,
          o: 1.21608,
          h: 1.2162,
          l: 1.21599,
          c: 1.2162,
          v: 59250000
        },
        {
          t: 75900,
          o: 1.21621,
          h: 1.21652,
          l: 1.21619,
          c: 1.21624,
          v: 460710000
        },
        {
          t: 76200,
          o: 1.21624,
          h: 1.21628,
          l: 1.21607,
          c: 1.21609,
          v: 197540000
        },
        {
          t: 76500,
          o: 1.21607,
          h: 1.21607,
          l: 1.21599,
          c: 1.216,
          v: 171680000
        },
        {
          t: 76800,
          o: 1.216,
          h: 1.21615,
          l: 1.21599,
          c: 1.21615,
          v: 112200000
        },
        {
          t: 77100,
          o: 1.21614,
          h: 1.21615,
          l: 1.2161,
          c: 1.21613,
          v: 97640000
        },
        {
          t: 77400,
          o: 1.21613,
          h: 1.21614,
          l: 1.21608,
          c: 1.21612,
          v: 241920000
        },
        {
          t: 77700,
          o: 1.21612,
          h: 1.21614,
          l: 1.21603,
          c: 1.21607,
          v: 267990000
        },
        {
          t: 78000,
          o: 1.21608,
          h: 1.21618,
          l: 1.21603,
          c: 1.21616,
          v: 313490000
        },
        {
          t: 78300,
          o: 1.21616,
          h: 1.21625,
          l: 1.21615,
          c: 1.21622,
          v: 171530000
        },
        {
          t: 78600,
          o: 1.21623,
          h: 1.21638,
          l: 1.21615,
          c: 1.21619,
          v: 408540000
        },
        {
          t: 78900,
          o: 1.21621,
          h: 1.21628,
          l: 1.21619,
          c: 1.21621,
          v: 88980000
        },
        {
          t: 79200,
          o: 1.2162,
          h: 1.21624,
          l: 1.21599,
          c: 1.21607,
          v: 305900000
        },
        {
          t: 79500,
          o: 1.21605,
          h: 1.21609,
          l: 1.21602,
          c: 1.21607,
          v: 371550000
        },
        {
          t: 79800,
          o: 1.21607,
          h: 1.21624,
          l: 1.21607,
          c: 1.21622,
          v: 204550000
        },
        {
          t: 80100,
          o: 1.21622,
          h: 1.21648,
          l: 1.2162,
          c: 1.21646,
          v: 591600000
        },
        {
          t: 80400,
          o: 1.21649,
          h: 1.21658,
          l: 1.21635,
          c: 1.21653,
          v: 365950000
        },
        {
          t: 80700,
          o: 1.21653,
          h: 1.21659,
          l: 1.21632,
          c: 1.21634,
          v: 226300000
        },
        {
          t: 81000,
          o: 1.21635,
          h: 1.2165,
          l: 1.21627,
          c: 1.21648,
          v: 359850000
        },
        {
          t: 81300,
          o: 1.21647,
          h: 1.21662,
          l: 1.21624,
          c: 1.21626,
          v: 419640000
        },
        {
          t: 81600,
          o: 1.21628,
          h: 1.21671,
          l: 1.21623,
          c: 1.2165,
          v: 828220000
        },
        {
          t: 81900,
          o: 1.21649,
          h: 1.2167,
          l: 1.21644,
          c: 1.21669,
          v: 324870000
        },
        {
          t: 82200,
          o: 1.21669,
          h: 1.21688,
          l: 1.21658,
          c: 1.21674,
          v: 691940000
        },
        {
          t: 82500,
          o: 1.21675,
          h: 1.21691,
          l: 1.21675,
          c: 1.21687,
          v: 294840000
        },
        {
          t: 82800,
          o: 1.21687,
          h: 1.21687,
          l: 1.21673,
          c: 1.21684,
          v: 219040000
        },
        {
          t: 83100,
          o: 1.21684,
          h: 1.21691,
          l: 1.21684,
          c: 1.2169,
          v: 122260000
        },
        {
          t: 83400,
          o: 1.21689,
          h: 1.21711,
          l: 1.21687,
          c: 1.21708,
          v: 243290000
        },
        {
          t: 83700,
          o: 1.21709,
          h: 1.21709,
          l: 1.21692,
          c: 1.21692,
          v: 234780000
        },
        {
          t: 84000,
          o: 1.21692,
          h: 1.2171,
          l: 1.21688,
          c: 1.21705,
          v: 229370000
        },
        {
          t: 84300,
          o: 1.21704,
          h: 1.21709,
          l: 1.21688,
          c: 1.21709,
          v: 232540000
        },
        {
          t: 84600,
          o: 1.21709,
          h: 1.21713,
          l: 1.21699,
          c: 1.21713,
          v: 282630000
        },
        {
          t: 84900,
          o: 1.21713,
          h: 1.21714,
          l: 1.21677,
          c: 1.21685,
          v: 407170000
        },
        {
          t: 85200,
          o: 1.21685,
          h: 1.21697,
          l: 1.21683,
          c: 1.21693,
          v: 429540000
        },
        {
          t: 85500,
          o: 1.21692,
          h: 1.21712,
          l: 1.21678,
          c: 1.21707,
          v: 455850000
        },
        {
          t: 85800,
          o: 1.21707,
          h: 1.21714,
          l: 1.21704,
          c: 1.21709,
          v: 213160000
        },
        {
          t: 86100,
          o: 1.21709,
          h: 1.21716,
          l: 1.21704,
          c: 1.21706,
          v: 124720000
        }
      ]
    },
    {
      d: 1524700800,
      b: [
        {
          t: 0,
          o: 1.21707,
          h: 1.21712,
          l: 1.21669,
          c: 1.21669,
          v: 529500000
        },
        {
          t: 300,
          o: 1.21669,
          h: 1.21684,
          l: 1.21632,
          c: 1.2168,
          v: 821500000
        },
        {
          t: 600,
          o: 1.21681,
          h: 1.21747,
          l: 1.21677,
          c: 1.21724,
          v: 787610000
        },
        {
          t: 900,
          o: 1.21724,
          h: 1.21739,
          l: 1.21714,
          c: 1.21725,
          v: 565370000
        },
        {
          t: 1200,
          o: 1.21725,
          h: 1.21734,
          l: 1.21698,
          c: 1.21698,
          v: 533930000
        },
        {
          t: 1500,
          o: 1.21698,
          h: 1.21729,
          l: 1.21697,
          c: 1.21723,
          v: 497730000
        },
        {
          t: 1800,
          o: 1.21722,
          h: 1.21723,
          l: 1.21703,
          c: 1.21715,
          v: 394930000
        },
        {
          t: 2100,
          o: 1.21715,
          h: 1.21742,
          l: 1.21713,
          c: 1.2174,
          v: 537170000
        },
        {
          t: 2400,
          o: 1.2174,
          h: 1.21771,
          l: 1.2174,
          c: 1.21767,
          v: 558280000
        },
        {
          t: 2700,
          o: 1.21767,
          h: 1.21767,
          l: 1.21727,
          c: 1.21732,
          v: 653620000
        },
        {
          t: 3000,
          o: 1.21732,
          h: 1.21773,
          l: 1.21699,
          c: 1.21762,
          v: 1099820000
        },
        {
          t: 3300,
          o: 1.2176,
          h: 1.21762,
          l: 1.21692,
          c: 1.21723,
          v: 1108630000
        },
        {
          t: 3600,
          o: 1.21725,
          h: 1.21755,
          l: 1.21717,
          c: 1.2173,
          v: 800570000
        },
        {
          t: 3900,
          o: 1.2173,
          h: 1.21753,
          l: 1.21713,
          c: 1.21742,
          v: 668100000
        },
        {
          t: 4200,
          o: 1.21741,
          h: 1.21741,
          l: 1.21722,
          c: 1.21729,
          v: 522560000
        },
        {
          t: 4500,
          o: 1.21728,
          h: 1.21732,
          l: 1.21679,
          c: 1.21684,
          v: 837350000
        },
        {
          t: 4800,
          o: 1.21685,
          h: 1.21711,
          l: 1.21683,
          c: 1.21705,
          v: 671480000
        },
        {
          t: 5100,
          o: 1.21704,
          h: 1.2171,
          l: 1.21694,
          c: 1.21697,
          v: 624490000
        },
        {
          t: 5400,
          o: 1.21695,
          h: 1.21739,
          l: 1.21695,
          c: 1.21724,
          v: 701250000
        },
        {
          t: 5700,
          o: 1.21723,
          h: 1.21769,
          l: 1.21722,
          c: 1.21761,
          v: 746540000
        },
        {
          t: 6000,
          o: 1.21761,
          h: 1.21766,
          l: 1.21748,
          c: 1.21755,
          v: 551630000
        },
        {
          t: 6300,
          o: 1.21755,
          h: 1.2176,
          l: 1.21737,
          c: 1.21754,
          v: 642840000
        },
        {
          t: 6600,
          o: 1.21755,
          h: 1.21784,
          l: 1.2175,
          c: 1.2176,
          v: 769550000
        },
        {
          t: 6900,
          o: 1.21759,
          h: 1.2176,
          l: 1.21734,
          c: 1.21749,
          v: 721810000
        },
        {
          t: 7200,
          o: 1.21751,
          h: 1.21772,
          l: 1.21746,
          c: 1.2177,
          v: 721040000
        },
        {
          t: 7500,
          o: 1.21769,
          h: 1.21773,
          l: 1.21768,
          c: 1.21769,
          v: 413280000
        },
        {
          t: 7800,
          o: 1.21769,
          h: 1.2177,
          l: 1.21758,
          c: 1.21764,
          v: 376960000
        },
        {
          t: 8100,
          o: 1.21764,
          h: 1.2177,
          l: 1.21763,
          c: 1.21769,
          v: 378310000
        },
        {
          t: 8400,
          o: 1.21769,
          h: 1.2177,
          l: 1.21754,
          c: 1.21761,
          v: 346920000
        },
        {
          t: 8700,
          o: 1.2176,
          h: 1.21761,
          l: 1.21739,
          c: 1.21747,
          v: 417760000
        },
        {
          t: 9000,
          o: 1.2175,
          h: 1.21754,
          l: 1.21728,
          c: 1.21733,
          v: 581560000
        },
        {
          t: 9300,
          o: 1.21733,
          h: 1.21742,
          l: 1.21731,
          c: 1.21741,
          v: 603590001
        },
        {
          t: 9600,
          o: 1.2174,
          h: 1.2174,
          l: 1.21713,
          c: 1.21719,
          v: 614060000
        },
        {
          t: 9900,
          o: 1.21718,
          h: 1.21721,
          l: 1.21711,
          c: 1.21713,
          v: 550770000
        },
        {
          t: 10200,
          o: 1.21713,
          h: 1.2173,
          l: 1.21713,
          c: 1.21717,
          v: 515800000
        },
        {
          t: 10500,
          o: 1.21717,
          h: 1.21721,
          l: 1.21712,
          c: 1.21715,
          v: 440880000
        },
        {
          t: 10800,
          o: 1.21715,
          h: 1.21735,
          l: 1.2171,
          c: 1.21733,
          v: 628620000
        },
        {
          t: 11100,
          o: 1.21732,
          h: 1.21733,
          l: 1.21724,
          c: 1.21724,
          v: 517060000
        },
        {
          t: 11400,
          o: 1.21724,
          h: 1.2175,
          l: 1.21724,
          c: 1.21738,
          v: 697160000
        },
        {
          t: 11700,
          o: 1.21739,
          h: 1.21741,
          l: 1.2173,
          c: 1.21739,
          v: 410960000
        },
        {
          t: 12000,
          o: 1.2174,
          h: 1.21751,
          l: 1.21728,
          c: 1.21749,
          v: 462420000
        },
        {
          t: 12300,
          o: 1.21751,
          h: 1.21764,
          l: 1.21751,
          c: 1.2176,
          v: 465990000
        },
        {
          t: 12600,
          o: 1.21761,
          h: 1.2177,
          l: 1.21744,
          c: 1.21744,
          v: 430060000
        },
        {
          t: 12900,
          o: 1.21746,
          h: 1.21761,
          l: 1.21744,
          c: 1.2176,
          v: 439860004
        },
        {
          t: 13200,
          o: 1.21761,
          h: 1.21787,
          l: 1.21759,
          c: 1.21783,
          v: 389270000
        },
        {
          t: 13500,
          o: 1.21783,
          h: 1.21815,
          l: 1.21776,
          c: 1.2178,
          v: 471250000
        },
        {
          t: 13800,
          o: 1.21781,
          h: 1.21807,
          l: 1.21781,
          c: 1.21807,
          v: 320560000
        },
        {
          t: 14100,
          o: 1.21807,
          h: 1.21812,
          l: 1.21769,
          c: 1.21774,
          v: 468400000
        },
        {
          t: 14400,
          o: 1.21775,
          h: 1.21777,
          l: 1.2176,
          c: 1.21776,
          v: 372570000
        },
        {
          t: 14700,
          o: 1.21776,
          h: 1.21785,
          l: 1.21776,
          c: 1.2178,
          v: 203990000
        },
        {
          t: 15000,
          o: 1.21781,
          h: 1.21782,
          l: 1.21768,
          c: 1.21769,
          v: 6187369953
        },
        {
          t: 15300,
          o: 1.2177,
          h: 1.21787,
          l: 1.21767,
          c: 1.21781,
          v: 299150000
        },
        {
          t: 15600,
          o: 1.21779,
          h: 1.21804,
          l: 1.21777,
          c: 1.21794,
          v: 485840000
        },
        {
          t: 15900,
          o: 1.21795,
          h: 1.21795,
          l: 1.2178,
          c: 1.21785,
          v: 257800000
        },
        {
          t: 16200,
          o: 1.21785,
          h: 1.21785,
          l: 1.21767,
          c: 1.21781,
          v: 542630000
        },
        {
          t: 16500,
          o: 1.2178,
          h: 1.21793,
          l: 1.21766,
          c: 1.2177,
          v: 482330000
        },
        {
          t: 16800,
          o: 1.21767,
          h: 1.21774,
          l: 1.21752,
          c: 1.21767,
          v: 475250000
        },
        {
          t: 17100,
          o: 1.21767,
          h: 1.21782,
          l: 1.21762,
          c: 1.2177,
          v: 432660000
        },
        {
          t: 17400,
          o: 1.2177,
          h: 1.21776,
          l: 1.2177,
          c: 1.21771,
          v: 221010000
        },
        {
          t: 17700,
          o: 1.21771,
          h: 1.21772,
          l: 1.21743,
          c: 1.21755,
          v: 552630000
        },
        {
          t: 18000,
          o: 1.21756,
          h: 1.21772,
          l: 1.21756,
          c: 1.21757,
          v: 567940000
        },
        {
          t: 18300,
          o: 1.21757,
          h: 1.21766,
          l: 1.21749,
          c: 1.21755,
          v: 464390000
        },
        {
          t: 18600,
          o: 1.21756,
          h: 1.21766,
          l: 1.21745,
          c: 1.2176,
          v: 353720000
        },
        {
          t: 18900,
          o: 1.2176,
          h: 1.21767,
          l: 1.21753,
          c: 1.21756,
          v: 519540000
        },
        {
          t: 19200,
          o: 1.21756,
          h: 1.21766,
          l: 1.21745,
          c: 1.21749,
          v: 415080000
        },
        {
          t: 19500,
          o: 1.21749,
          h: 1.21757,
          l: 1.21741,
          c: 1.21755,
          v: 426350000
        },
        {
          t: 19800,
          o: 1.21756,
          h: 1.2176,
          l: 1.21736,
          c: 1.2175,
          v: 518310000
        },
        {
          t: 20100,
          o: 1.2175,
          h: 1.21752,
          l: 1.21738,
          c: 1.21741,
          v: 452320000
        },
        {
          t: 20400,
          o: 1.21741,
          h: 1.21761,
          l: 1.21741,
          c: 1.21757,
          v: 486130000
        },
        {
          t: 20700,
          o: 1.21756,
          h: 1.21795,
          l: 1.21756,
          c: 1.21789,
          v: 529240001
        },
        {
          t: 21000,
          o: 1.21788,
          h: 1.21811,
          l: 1.21784,
          c: 1.21795,
          v: 687800000
        },
        {
          t: 21300,
          o: 1.21795,
          h: 1.21797,
          l: 1.21779,
          c: 1.2178,
          v: 490450000
        },
        {
          t: 21600,
          o: 1.2178,
          h: 1.218,
          l: 1.21769,
          c: 1.21778,
          v: 635650000
        },
        {
          t: 21900,
          o: 1.21778,
          h: 1.21782,
          l: 1.21736,
          c: 1.21749,
          v: 989100000
        },
        {
          t: 22200,
          o: 1.21749,
          h: 1.21805,
          l: 1.21748,
          c: 1.21774,
          v: 1161100000
        },
        {
          t: 22500,
          o: 1.21774,
          h: 1.2179,
          l: 1.21768,
          c: 1.21785,
          v: 739890000
        },
        {
          t: 22800,
          o: 1.21785,
          h: 1.21823,
          l: 1.2173,
          c: 1.2174,
          v: 1494760000
        },
        {
          t: 23100,
          o: 1.21741,
          h: 1.21762,
          l: 1.21728,
          c: 1.21751,
          v: 1010020000
        },
        {
          t: 23400,
          o: 1.21751,
          h: 1.2177,
          l: 1.21734,
          c: 1.21739,
          v: 1273270000
        },
        {
          t: 23700,
          o: 1.21741,
          h: 1.21829,
          l: 1.21741,
          c: 1.21827,
          v: 1224910000
        },
        {
          t: 24000,
          o: 1.21827,
          h: 1.21883,
          l: 1.21827,
          c: 1.2184,
          v: 1358050000
        },
        {
          t: 24300,
          o: 1.2184,
          h: 1.21867,
          l: 1.2183,
          c: 1.21861,
          v: 1098400000
        },
        {
          t: 24600,
          o: 1.21861,
          h: 1.21881,
          l: 1.21847,
          c: 1.2188,
          v: 1138060000
        },
        {
          t: 24900,
          o: 1.21879,
          h: 1.2188,
          l: 1.21788,
          c: 1.21788,
          v: 1205610000
        },
        {
          t: 25200,
          o: 1.21787,
          h: 1.21856,
          l: 1.21769,
          c: 1.21856,
          v: 1585490000
        },
        {
          t: 25500,
          o: 1.21859,
          h: 1.21874,
          l: 1.21752,
          c: 1.21763,
          v: 1528190000
        },
        {
          t: 25800,
          o: 1.21763,
          h: 1.21798,
          l: 1.21758,
          c: 1.2177,
          v: 1284570000
        },
        {
          t: 26100,
          o: 1.21771,
          h: 1.21784,
          l: 1.21736,
          c: 1.21766,
          v: 1378720000
        },
        {
          t: 26400,
          o: 1.21765,
          h: 1.21765,
          l: 1.21662,
          c: 1.21673,
          v: 1789530000
        },
        {
          t: 26700,
          o: 1.21671,
          h: 1.217,
          l: 1.21657,
          c: 1.21698,
          v: 1249550000
        },
        {
          t: 27000,
          o: 1.21696,
          h: 1.21708,
          l: 1.21658,
          c: 1.21687,
          v: 1144600000
        },
        {
          t: 27300,
          o: 1.21686,
          h: 1.21696,
          l: 1.21657,
          c: 1.21684,
          v: 1277280000
        },
        {
          t: 27600,
          o: 1.21684,
          h: 1.21694,
          l: 1.21659,
          c: 1.21678,
          v: 1157560000
        },
        {
          t: 27900,
          o: 1.21679,
          h: 1.21687,
          l: 1.21623,
          c: 1.21637,
          v: 1406130000
        },
        {
          t: 28200,
          o: 1.21637,
          h: 1.21662,
          l: 1.21588,
          c: 1.21627,
          v: 1748460000
        },
        {
          t: 28500,
          o: 1.21626,
          h: 1.2166,
          l: 1.21607,
          c: 1.21652,
          v: 1223310000
        },
        {
          t: 28800,
          o: 1.21653,
          h: 1.21656,
          l: 1.21613,
          c: 1.21633,
          v: 1045670000
        },
        {
          t: 29100,
          o: 1.21633,
          h: 1.21633,
          l: 1.2156,
          c: 1.21607,
          v: 1203990000
        },
        {
          t: 29400,
          o: 1.21607,
          h: 1.21644,
          l: 1.21591,
          c: 1.21644,
          v: 1176280001
        },
        {
          t: 29700,
          o: 1.21643,
          h: 1.21721,
          l: 1.2163,
          c: 1.21703,
          v: 1297590000
        },
        {
          t: 30000,
          o: 1.21703,
          h: 1.21741,
          l: 1.21668,
          c: 1.21718,
          v: 940920000
        },
        {
          t: 30300,
          o: 1.21719,
          h: 1.21755,
          l: 1.21703,
          c: 1.21752,
          v: 790010000
        },
        {
          t: 30600,
          o: 1.21752,
          h: 1.21788,
          l: 1.21744,
          c: 1.21785,
          v: 995170000
        },
        {
          t: 30900,
          o: 1.21786,
          h: 1.21797,
          l: 1.21735,
          c: 1.21743,
          v: 913820000
        },
        {
          t: 31200,
          o: 1.21744,
          h: 1.21791,
          l: 1.21743,
          c: 1.2179,
          v: 870490000
        },
        {
          t: 31500,
          o: 1.21788,
          h: 1.21788,
          l: 1.21723,
          c: 1.21733,
          v: 1010430000
        },
        {
          t: 31800,
          o: 1.21732,
          h: 1.21761,
          l: 1.21717,
          c: 1.21724,
          v: 1183530000
        },
        {
          t: 32100,
          o: 1.21723,
          h: 1.21744,
          l: 1.21704,
          c: 1.21714,
          v: 1164940000
        },
        {
          t: 32400,
          o: 1.21713,
          h: 1.21734,
          l: 1.21687,
          c: 1.2173,
          v: 1177980000
        },
        {
          t: 32700,
          o: 1.21728,
          h: 1.21764,
          l: 1.21725,
          c: 1.21763,
          v: 741820000
        },
        {
          t: 33000,
          o: 1.21763,
          h: 1.21781,
          l: 1.21729,
          c: 1.21781,
          v: 740570000
        },
        {
          t: 33300,
          o: 1.21783,
          h: 1.21797,
          l: 1.21768,
          c: 1.21789,
          v: 1077180001
        },
        {
          t: 33600,
          o: 1.21789,
          h: 1.21791,
          l: 1.21749,
          c: 1.21758,
          v: 860330000
        },
        {
          t: 33900,
          o: 1.21758,
          h: 1.21764,
          l: 1.21697,
          c: 1.21705,
          v: 904290000
        },
        {
          t: 34200,
          o: 1.21705,
          h: 1.21795,
          l: 1.21695,
          c: 1.21793,
          v: 1036820000
        },
        {
          t: 34500,
          o: 1.21793,
          h: 1.21825,
          l: 1.21777,
          c: 1.21817,
          v: 971210000
        },
        {
          t: 34800,
          o: 1.21815,
          h: 1.21842,
          l: 1.21802,
          c: 1.21833,
          v: 1057170000
        },
        {
          t: 35100,
          o: 1.21831,
          h: 1.21866,
          l: 1.21804,
          c: 1.21844,
          v: 1039970000
        },
        {
          t: 35400,
          o: 1.21844,
          h: 1.21855,
          l: 1.2182,
          c: 1.21822,
          v: 995030000
        },
        {
          t: 35700,
          o: 1.21822,
          h: 1.21829,
          l: 1.21776,
          c: 1.21781,
          v: 937720000
        },
        {
          t: 36000,
          o: 1.21781,
          h: 1.21783,
          l: 1.21756,
          c: 1.21758,
          v: 677030000
        },
        {
          t: 36300,
          o: 1.21756,
          h: 1.21772,
          l: 1.21736,
          c: 1.21736,
          v: 815370000
        },
        {
          t: 36600,
          o: 1.21736,
          h: 1.21741,
          l: 1.21717,
          c: 1.21722,
          v: 1053420000
        },
        {
          t: 36900,
          o: 1.2172,
          h: 1.21775,
          l: 1.2172,
          c: 1.21744,
          v: 1083590000
        },
        {
          t: 37200,
          o: 1.21744,
          h: 1.21749,
          l: 1.21728,
          c: 1.21737,
          v: 586900000
        },
        {
          t: 37500,
          o: 1.21739,
          h: 1.21756,
          l: 1.21731,
          c: 1.2175,
          v: 664380000
        },
        {
          t: 37800,
          o: 1.21749,
          h: 1.21789,
          l: 1.21748,
          c: 1.21776,
          v: 811840000
        },
        {
          t: 38100,
          o: 1.21775,
          h: 1.21776,
          l: 1.21742,
          c: 1.21744,
          v: 685880000
        },
        {
          t: 38400,
          o: 1.21746,
          h: 1.21777,
          l: 1.21742,
          c: 1.21777,
          v: 588560000
        },
        {
          t: 38700,
          o: 1.21779,
          h: 1.21809,
          l: 1.21777,
          c: 1.21799,
          v: 1143960001
        },
        {
          t: 39000,
          o: 1.21799,
          h: 1.21858,
          l: 1.21793,
          c: 1.21857,
          v: 1171609998
        },
        {
          t: 39300,
          o: 1.21858,
          h: 1.21877,
          l: 1.21826,
          c: 1.21832,
          v: 1226160000
        },
        {
          t: 39600,
          o: 1.2183,
          h: 1.21835,
          l: 1.21783,
          c: 1.21816,
          v: 1484469999
        },
        {
          t: 39900,
          o: 1.21817,
          h: 1.21846,
          l: 1.218,
          c: 1.21831,
          v: 1116880000
        },
        {
          t: 40200,
          o: 1.21831,
          h: 1.21871,
          l: 1.21827,
          c: 1.21865,
          v: 741380000
        },
        {
          t: 40500,
          o: 1.21866,
          h: 1.21874,
          l: 1.21839,
          c: 1.21851,
          v: 786000000
        },
        {
          t: 40800,
          o: 1.21851,
          h: 1.21876,
          l: 1.21835,
          c: 1.21836,
          v: 959280000
        },
        {
          t: 41100,
          o: 1.21837,
          h: 1.21858,
          l: 1.21827,
          c: 1.21836,
          v: 766830000
        },
        {
          t: 41400,
          o: 1.21836,
          h: 1.21848,
          l: 1.21805,
          c: 1.21833,
          v: 815029999
        },
        {
          t: 41700,
          o: 1.21833,
          h: 1.21839,
          l: 1.21776,
          c: 1.21805,
          v: 996410000
        },
        {
          t: 42000,
          o: 1.21805,
          h: 1.21809,
          l: 1.21761,
          c: 1.21804,
          v: 966960000
        },
        {
          t: 42300,
          o: 1.21803,
          h: 1.21859,
          l: 1.21735,
          c: 1.21755,
          v: 1165890000
        },
        {
          t: 42600,
          o: 1.21755,
          h: 1.218,
          l: 1.21753,
          c: 1.21756,
          v: 1261920000
        },
        {
          t: 42900,
          o: 1.21757,
          h: 1.2176,
          l: 1.21535,
          c: 1.2157,
          v: 1604450000
        },
        {
          t: 43200,
          o: 1.21569,
          h: 1.21699,
          l: 1.21567,
          c: 1.21691,
          v: 1667550000
        },
        {
          t: 43500,
          o: 1.21691,
          h: 1.21707,
          l: 1.21663,
          c: 1.21685,
          v: 1218540000
        },
        {
          t: 43800,
          o: 1.21685,
          h: 1.21694,
          l: 1.21654,
          c: 1.21679,
          v: 1193950000
        },
        {
          t: 44100,
          o: 1.21677,
          h: 1.21684,
          l: 1.21616,
          c: 1.21637,
          v: 1311330000
        },
        {
          t: 44400,
          o: 1.21637,
          h: 1.21686,
          l: 1.21616,
          c: 1.21671,
          v: 1533660000
        },
        {
          t: 44700,
          o: 1.21671,
          h: 1.21692,
          l: 1.21666,
          c: 1.2167,
          v: 797030000
        },
        {
          t: 45000,
          o: 1.21669,
          h: 1.21776,
          l: 1.21597,
          c: 1.21597,
          v: 1945910000
        },
        {
          t: 45300,
          o: 1.21579,
          h: 1.21883,
          l: 1.21451,
          c: 1.21832,
          v: 3416740000
        },
        {
          t: 45600,
          o: 1.21829,
          h: 1.21966,
          l: 1.218,
          c: 1.21945,
          v: 2901450000
        },
        {
          t: 45900,
          o: 1.21946,
          h: 1.21971,
          l: 1.21761,
          c: 1.21922,
          v: 2827250000
        },
        {
          t: 46200,
          o: 1.21925,
          h: 1.2199,
          l: 1.21779,
          c: 1.2199,
          v: 2552220000
        },
        {
          t: 46500,
          o: 1.21995,
          h: 1.22099,
          l: 1.2199,
          c: 1.21995,
          v: 2230510001
        },
        {
          t: 46800,
          o: 1.21997,
          h: 1.2202,
          l: 1.21908,
          c: 1.21908,
          v: 1886140000
        },
        {
          t: 47100,
          o: 1.21907,
          h: 1.21956,
          l: 1.21891,
          c: 1.21917,
          v: 1838630000
        },
        {
          t: 47400,
          o: 1.21915,
          h: 1.21929,
          l: 1.21828,
          c: 1.21867,
          v: 1902460001
        },
        {
          t: 47700,
          o: 1.21867,
          h: 1.21927,
          l: 1.21867,
          c: 1.21874,
          v: 1244750000
        },
        {
          t: 48000,
          o: 1.21875,
          h: 1.21939,
          l: 1.21837,
          c: 1.21924,
          v: 1490540000
        },
        {
          t: 48300,
          o: 1.21924,
          h: 1.22036,
          l: 1.21919,
          c: 1.22027,
          v: 1600370000
        },
        {
          t: 48600,
          o: 1.22027,
          h: 1.22041,
          l: 1.21943,
          c: 1.21947,
          v: 1827220000
        },
        {
          t: 48900,
          o: 1.21946,
          h: 1.21982,
          l: 1.21886,
          c: 1.21896,
          v: 1216230000
        },
        {
          t: 49200,
          o: 1.21897,
          h: 1.21914,
          l: 1.21857,
          c: 1.2186,
          v: 1447850000
        },
        {
          t: 49500,
          o: 1.21861,
          h: 1.21892,
          l: 1.21808,
          c: 1.21842,
          v: 1407270000
        },
        {
          t: 49800,
          o: 1.21845,
          h: 1.21887,
          l: 1.2179,
          c: 1.21802,
          v: 1444949999
        },
        {
          t: 50100,
          o: 1.21801,
          h: 1.21818,
          l: 1.21712,
          c: 1.21731,
          v: 1603650000
        },
        {
          t: 50400,
          o: 1.21733,
          h: 1.21738,
          l: 1.21607,
          c: 1.21612,
          v: 1842570000
        },
        {
          t: 50700,
          o: 1.2161,
          h: 1.2161,
          l: 1.21422,
          c: 1.21432,
          v: 2246450000
        },
        {
          t: 51000,
          o: 1.21434,
          h: 1.21452,
          l: 1.2125,
          c: 1.2125,
          v: 2268829999
        },
        {
          t: 51300,
          o: 1.21249,
          h: 1.21272,
          l: 1.21148,
          c: 1.21205,
          v: 2481310000
        },
        {
          t: 51600,
          o: 1.21205,
          h: 1.21273,
          l: 1.21149,
          c: 1.21261,
          v: 1674880000
        },
        {
          t: 51900,
          o: 1.21263,
          h: 1.21297,
          l: 1.21206,
          c: 1.21281,
          v: 1446050000
        },
        {
          t: 52200,
          o: 1.2128,
          h: 1.2128,
          l: 1.2113,
          c: 1.21187,
          v: 1655480000
        },
        {
          t: 52500,
          o: 1.21187,
          h: 1.21191,
          l: 1.21108,
          c: 1.2115,
          v: 1803700000
        },
        {
          t: 52800,
          o: 1.21148,
          h: 1.21222,
          l: 1.21147,
          c: 1.21169,
          v: 1586170000
        },
        {
          t: 53100,
          o: 1.21169,
          h: 1.21197,
          l: 1.21068,
          c: 1.21071,
          v: 1892330000
        },
        {
          t: 53400,
          o: 1.21073,
          h: 1.21145,
          l: 1.21069,
          c: 1.21134,
          v: 1985070000
        },
        {
          t: 53700,
          o: 1.21129,
          h: 1.21157,
          l: 1.2107,
          c: 1.21148,
          v: 1725610000
        },
        {
          t: 54000,
          o: 1.21147,
          h: 1.2115,
          l: 1.21086,
          c: 1.21108,
          v: 1920480000
        },
        {
          t: 54300,
          o: 1.21109,
          h: 1.21203,
          l: 1.21106,
          c: 1.21149,
          v: 1609880000
        },
        {
          t: 54600,
          o: 1.2115,
          h: 1.21178,
          l: 1.21055,
          c: 1.21071,
          v: 1445280001
        },
        {
          t: 54900,
          o: 1.21074,
          h: 1.21103,
          l: 1.21051,
          c: 1.21084,
          v: 1315680000
        },
        {
          t: 55200,
          o: 1.21084,
          h: 1.21089,
          l: 1.21049,
          c: 1.21061,
          v: 1274410000
        },
        {
          t: 55500,
          o: 1.2106,
          h: 1.21093,
          l: 1.21027,
          c: 1.21049,
          v: 1479420000
        },
        {
          t: 55800,
          o: 1.2105,
          h: 1.21081,
          l: 1.21028,
          c: 1.21035,
          v: 1674509998
        },
        {
          t: 56100,
          o: 1.21035,
          h: 1.21051,
          l: 1.21018,
          c: 1.21047,
          v: 1394159999
        },
        {
          t: 56400,
          o: 1.21047,
          h: 1.21048,
          l: 1.21008,
          c: 1.21023,
          v: 1101040000
        },
        {
          t: 56700,
          o: 1.21023,
          h: 1.21097,
          l: 1.2098,
          c: 1.21092,
          v: 1561800001
        },
        {
          t: 57000,
          o: 1.21093,
          h: 1.21131,
          l: 1.21079,
          c: 1.21115,
          v: 1236580000
        },
        {
          t: 57300,
          o: 1.21115,
          h: 1.2113,
          l: 1.21075,
          c: 1.21129,
          v: 1129230000
        },
        {
          t: 57600,
          o: 1.21133,
          h: 1.21144,
          l: 1.21088,
          c: 1.21144,
          v: 1123240000
        },
        {
          t: 57900,
          o: 1.21142,
          h: 1.21157,
          l: 1.21121,
          c: 1.21142,
          v: 946630000
        },
        {
          t: 58200,
          o: 1.21141,
          h: 1.21173,
          l: 1.21128,
          c: 1.21171,
          v: 943910000
        },
        {
          t: 58500,
          o: 1.21172,
          h: 1.21177,
          l: 1.21127,
          c: 1.21145,
          v: 1150420000
        },
        {
          t: 58800,
          o: 1.21147,
          h: 1.21186,
          l: 1.21135,
          c: 1.21143,
          v: 1277310000
        },
        {
          t: 59100,
          o: 1.21144,
          h: 1.2118,
          l: 1.21121,
          c: 1.21176,
          v: 989080000
        },
        {
          t: 59400,
          o: 1.21176,
          h: 1.21226,
          l: 1.21164,
          c: 1.21172,
          v: 1245630000
        },
        {
          t: 59700,
          o: 1.21172,
          h: 1.2119,
          l: 1.21158,
          c: 1.2118,
          v: 975280000
        },
        {
          t: 60000,
          o: 1.21179,
          h: 1.21225,
          l: 1.21163,
          c: 1.21225,
          v: 1142890000
        },
        {
          t: 60300,
          o: 1.21224,
          h: 1.21225,
          l: 1.21193,
          c: 1.21219,
          v: 973810000
        },
        {
          t: 60600,
          o: 1.21219,
          h: 1.21267,
          l: 1.21213,
          c: 1.21235,
          v: 996020000
        },
        {
          t: 60900,
          o: 1.21234,
          h: 1.21287,
          l: 1.21214,
          c: 1.21257,
          v: 1526040000
        },
        {
          t: 61200,
          o: 1.21257,
          h: 1.21298,
          l: 1.21244,
          c: 1.2129,
          v: 697260000
        },
        {
          t: 61500,
          o: 1.2129,
          h: 1.2129,
          l: 1.21258,
          c: 1.2127,
          v: 838250000
        },
        {
          t: 61800,
          o: 1.21267,
          h: 1.21282,
          l: 1.21235,
          c: 1.21243,
          v: 751340000
        },
        {
          t: 62100,
          o: 1.21243,
          h: 1.21245,
          l: 1.2122,
          c: 1.21234,
          v: 896820000
        },
        {
          t: 62400,
          o: 1.21234,
          h: 1.21234,
          l: 1.21201,
          c: 1.21213,
          v: 541100000
        },
        {
          t: 62700,
          o: 1.21213,
          h: 1.21225,
          l: 1.21194,
          c: 1.21194,
          v: 868020000
        },
        {
          t: 63000,
          o: 1.21196,
          h: 1.21207,
          l: 1.21169,
          c: 1.21172,
          v: 633710000
        },
        {
          t: 63300,
          o: 1.21172,
          h: 1.21173,
          l: 1.21152,
          c: 1.21159,
          v: 635920000
        },
        {
          t: 63600,
          o: 1.21159,
          h: 1.21162,
          l: 1.21139,
          c: 1.21142,
          v: 603180000
        },
        {
          t: 63900,
          o: 1.21142,
          h: 1.2115,
          l: 1.21111,
          c: 1.21115,
          v: 582180000
        },
        {
          t: 64200,
          o: 1.21116,
          h: 1.21138,
          l: 1.21104,
          c: 1.21129,
          v: 616180000
        },
        {
          t: 64500,
          o: 1.2113,
          h: 1.21153,
          l: 1.21124,
          c: 1.21139,
          v: 701260000
        },
        {
          t: 64800,
          o: 1.21139,
          h: 1.21155,
          l: 1.21107,
          c: 1.21108,
          v: 831840000
        },
        {
          t: 65100,
          o: 1.21108,
          h: 1.21111,
          l: 1.21079,
          c: 1.21094,
          v: 906970000
        },
        {
          t: 65400,
          o: 1.21094,
          h: 1.21101,
          l: 1.21088,
          c: 1.21097,
          v: 608890000
        },
        {
          t: 65700,
          o: 1.21097,
          h: 1.2113,
          l: 1.21088,
          c: 1.21088,
          v: 866630000
        },
        {
          t: 66000,
          o: 1.21091,
          h: 1.21097,
          l: 1.21068,
          c: 1.21073,
          v: 572450000
        },
        {
          t: 66300,
          o: 1.21073,
          h: 1.21073,
          l: 1.21021,
          c: 1.21026,
          v: 987779999
        },
        {
          t: 66600,
          o: 1.21026,
          h: 1.21056,
          l: 1.21022,
          c: 1.2105,
          v: 699650000
        },
        {
          t: 66900,
          o: 1.21051,
          h: 1.21056,
          l: 1.21033,
          c: 1.21033,
          v: 610809999
        },
        {
          t: 67200,
          o: 1.21033,
          h: 1.21037,
          l: 1.20999,
          c: 1.21007,
          v: 891439998
        },
        {
          t: 67500,
          o: 1.21011,
          h: 1.21061,
          l: 1.2101,
          c: 1.21058,
          v: 1028859998
        },
        {
          t: 67800,
          o: 1.21057,
          h: 1.21088,
          l: 1.21056,
          c: 1.21057,
          v: 831960000
        },
        {
          t: 68100,
          o: 1.21058,
          h: 1.2108,
          l: 1.21052,
          c: 1.21053,
          v: 908650000
        },
        {
          t: 68400,
          o: 1.21052,
          h: 1.21063,
          l: 1.21037,
          c: 1.21044,
          v: 610610000
        },
        {
          t: 68700,
          o: 1.21045,
          h: 1.21056,
          l: 1.21031,
          c: 1.21049,
          v: 453200000
        },
        {
          t: 69000,
          o: 1.21048,
          h: 1.21057,
          l: 1.20983,
          c: 1.2099,
          v: 774310000
        },
        {
          t: 69300,
          o: 1.2099,
          h: 1.20993,
          l: 1.20962,
          c: 1.20963,
          v: 870160000
        },
        {
          t: 69600,
          o: 1.20963,
          h: 1.21002,
          l: 1.20962,
          c: 1.20993,
          v: 796750000
        },
        {
          t: 69900,
          o: 1.20992,
          h: 1.21002,
          l: 1.20971,
          c: 1.20979,
          v: 526250000
        },
        {
          t: 70200,
          o: 1.20981,
          h: 1.20986,
          l: 1.20965,
          c: 1.20985,
          v: 416560000
        },
        {
          t: 70500,
          o: 1.20984,
          h: 1.21041,
          l: 1.2098,
          c: 1.21039,
          v: 664560000
        },
        {
          t: 70800,
          o: 1.21038,
          h: 1.21046,
          l: 1.21022,
          c: 1.21023,
          v: 638230000
        },
        {
          t: 71100,
          o: 1.21024,
          h: 1.21061,
          l: 1.2102,
          c: 1.2106,
          v: 669420000
        },
        {
          t: 71400,
          o: 1.21059,
          h: 1.2106,
          l: 1.21039,
          c: 1.2105,
          v: 536370000
        },
        {
          t: 71700,
          o: 1.21051,
          h: 1.21066,
          l: 1.21041,
          c: 1.21045,
          v: 859560000
        },
        {
          t: 72000,
          o: 1.21043,
          h: 1.21055,
          l: 1.21035,
          c: 1.21048,
          v: 424720000
        },
        {
          t: 72300,
          o: 1.21048,
          h: 1.21072,
          l: 1.21045,
          c: 1.21071,
          v: 411440000
        },
        {
          t: 72600,
          o: 1.21071,
          h: 1.21071,
          l: 1.21055,
          c: 1.21055,
          v: 299300000
        },
        {
          t: 72900,
          o: 1.21055,
          h: 1.21055,
          l: 1.21033,
          c: 1.21037,
          v: 233610000
        },
        {
          t: 73200,
          o: 1.21037,
          h: 1.21042,
          l: 1.21025,
          c: 1.21039,
          v: 191570000
        },
        {
          t: 73500,
          o: 1.21038,
          h: 1.21047,
          l: 1.21035,
          c: 1.21041,
          v: 278440000
        },
        {
          t: 73800,
          o: 1.21041,
          h: 1.21046,
          l: 1.21028,
          c: 1.2103,
          v: 240150000
        },
        {
          t: 74100,
          o: 1.2103,
          h: 1.21034,
          l: 1.2102,
          c: 1.21033,
          v: 333970000
        },
        {
          t: 74400,
          o: 1.21029,
          h: 1.21034,
          l: 1.21028,
          c: 1.21029,
          v: 355290000
        },
        {
          t: 74700,
          o: 1.2103,
          h: 1.21031,
          l: 1.21028,
          c: 1.21029,
          v: 253210000
        },
        {
          t: 75000,
          o: 1.21029,
          h: 1.21041,
          l: 1.21027,
          c: 1.21035,
          v: 454440000
        },
        {
          t: 75300,
          o: 1.21032,
          h: 1.21037,
          l: 1.21024,
          c: 1.21032,
          v: 318980000
        },
        {
          t: 75600,
          o: 1.21029,
          h: 1.21039,
          l: 1.21029,
          c: 1.21038,
          v: 22460000
        },
        {
          t: 75900,
          o: 1.21038,
          h: 1.21075,
          l: 1.21036,
          c: 1.21052,
          v: 337880000
        },
        {
          t: 76200,
          o: 1.2105,
          h: 1.21059,
          l: 1.2104,
          c: 1.21052,
          v: 215660000
        },
        {
          t: 76500,
          o: 1.21051,
          h: 1.21058,
          l: 1.21043,
          c: 1.21046,
          v: 319060000
        },
        {
          t: 76800,
          o: 1.21045,
          h: 1.21059,
          l: 1.21045,
          c: 1.21058,
          v: 249940000
        },
        {
          t: 77100,
          o: 1.21056,
          h: 1.21067,
          l: 1.21052,
          c: 1.2106,
          v: 237930000
        },
        {
          t: 77400,
          o: 1.21059,
          h: 1.21061,
          l: 1.21036,
          c: 1.2105,
          v: 531960000
        },
        {
          t: 77700,
          o: 1.21048,
          h: 1.2106,
          l: 1.21036,
          c: 1.21042,
          v: 356640000
        },
        {
          t: 78000,
          o: 1.21044,
          h: 1.21062,
          l: 1.21042,
          c: 1.21047,
          v: 371320000
        },
        {
          t: 78300,
          o: 1.21046,
          h: 1.21055,
          l: 1.21046,
          c: 1.21052,
          v: 153450000
        },
        {
          t: 78600,
          o: 1.21052,
          h: 1.21065,
          l: 1.21048,
          c: 1.21051,
          v: 231770000
        },
        {
          t: 78900,
          o: 1.21052,
          h: 1.21065,
          l: 1.21042,
          c: 1.21046,
          v: 427560000
        },
        {
          t: 79200,
          o: 1.21046,
          h: 1.21058,
          l: 1.21034,
          c: 1.21054,
          v: 186220000
        },
        {
          t: 79500,
          o: 1.21054,
          h: 1.21069,
          l: 1.21054,
          c: 1.21058,
          v: 371990000
        },
        {
          t: 79800,
          o: 1.2106,
          h: 1.21066,
          l: 1.21058,
          c: 1.21061,
          v: 104560000
        },
        {
          t: 80100,
          o: 1.2106,
          h: 1.21067,
          l: 1.21043,
          c: 1.21066,
          v: 217050000
        },
        {
          t: 80400,
          o: 1.21065,
          h: 1.21075,
          l: 1.21064,
          c: 1.21071,
          v: 106500000
        },
        {
          t: 80700,
          o: 1.21071,
          h: 1.21077,
          l: 1.21071,
          c: 1.21075,
          v: 151890000
        },
        {
          t: 81000,
          o: 1.21074,
          h: 1.21085,
          l: 1.21074,
          c: 1.21085,
          v: 122340000
        },
        {
          t: 81300,
          o: 1.21085,
          h: 1.21095,
          l: 1.21078,
          c: 1.21079,
          v: 111110000
        },
        {
          t: 81600,
          o: 1.21079,
          h: 1.21086,
          l: 1.21069,
          c: 1.21074,
          v: 134630000
        },
        {
          t: 81900,
          o: 1.21074,
          h: 1.21076,
          l: 1.21068,
          c: 1.21076,
          v: 204680000
        },
        {
          t: 82200,
          o: 1.21076,
          h: 1.2108,
          l: 1.21074,
          c: 1.21079,
          v: 88580000
        },
        {
          t: 82500,
          o: 1.21079,
          h: 1.21079,
          l: 1.21039,
          c: 1.21044,
          v: 377830000
        },
        {
          t: 82800,
          o: 1.21043,
          h: 1.21051,
          l: 1.2104,
          c: 1.21045,
          v: 313080000
        },
        {
          t: 83100,
          o: 1.21044,
          h: 1.21049,
          l: 1.2104,
          c: 1.21045,
          v: 272940000
        },
        {
          t: 83400,
          o: 1.21044,
          h: 1.21052,
          l: 1.21044,
          c: 1.2105,
          v: 124960000
        },
        {
          t: 83700,
          o: 1.21049,
          h: 1.21055,
          l: 1.21049,
          c: 1.21054,
          v: 160670000
        },
        {
          t: 84000,
          o: 1.21055,
          h: 1.21078,
          l: 1.21053,
          c: 1.21066,
          v: 369390000
        },
        {
          t: 84300,
          o: 1.21066,
          h: 1.21073,
          l: 1.21065,
          c: 1.2107,
          v: 243200000
        },
        {
          t: 84600,
          o: 1.2107,
          h: 1.21071,
          l: 1.21064,
          c: 1.21067,
          v: 267270000
        },
        {
          t: 84900,
          o: 1.21066,
          h: 1.21072,
          l: 1.21066,
          c: 1.21067,
          v: 175960000
        },
        {
          t: 85200,
          o: 1.21066,
          h: 1.21082,
          l: 1.21066,
          c: 1.21081,
          v: 186090000
        },
        {
          t: 85500,
          o: 1.2108,
          h: 1.21081,
          l: 1.21074,
          c: 1.21074,
          v: 170000000
        },
        {
          t: 85800,
          o: 1.21074,
          h: 1.21086,
          l: 1.21074,
          c: 1.21086,
          v: 145070000
        },
        {
          t: 86100,
          o: 1.21087,
          h: 1.21091,
          l: 1.21081,
          c: 1.21081,
          v: 161310000
        }
      ]
    },
    {
      d: 1524787200,
      b: [
        {
          t: 0,
          o: 1.21081,
          h: 1.21087,
          l: 1.21017,
          c: 1.21018,
          v: 859950000
        },
        {
          t: 300,
          o: 1.21017,
          h: 1.21033,
          l: 1.21012,
          c: 1.2103,
          v: 536930000
        },
        {
          t: 600,
          o: 1.2103,
          h: 1.21077,
          l: 1.21027,
          c: 1.21071,
          v: 689430000
        },
        {
          t: 900,
          o: 1.21071,
          h: 1.21091,
          l: 1.21057,
          c: 1.2106,
          v: 731790000
        },
        {
          t: 1200,
          o: 1.21061,
          h: 1.21071,
          l: 1.21047,
          c: 1.21047,
          v: 398670000
        },
        {
          t: 1500,
          o: 1.21048,
          h: 1.21057,
          l: 1.21034,
          c: 1.21043,
          v: 463419999
        },
        {
          t: 1800,
          o: 1.21042,
          h: 1.21053,
          l: 1.21031,
          c: 1.21033,
          v: 362000000
        },
        {
          t: 2100,
          o: 1.21034,
          h: 1.21039,
          l: 1.21027,
          c: 1.21032,
          v: 264880000
        },
        {
          t: 2400,
          o: 1.21034,
          h: 1.21061,
          l: 1.21033,
          c: 1.21061,
          v: 359570000
        },
        {
          t: 2700,
          o: 1.2106,
          h: 1.2109,
          l: 1.2106,
          c: 1.21081,
          v: 524620000
        },
        {
          t: 3000,
          o: 1.2108,
          h: 1.21094,
          l: 1.21052,
          c: 1.2108,
          v: 797559999
        },
        {
          t: 3300,
          o: 1.21077,
          h: 1.21077,
          l: 1.21052,
          c: 1.21076,
          v: 486460000
        },
        {
          t: 3600,
          o: 1.21075,
          h: 1.21085,
          l: 1.21062,
          c: 1.21075,
          v: 639130000
        },
        {
          t: 3900,
          o: 1.21075,
          h: 1.21091,
          l: 1.21075,
          c: 1.21076,
          v: 448700000
        },
        {
          t: 4200,
          o: 1.21076,
          h: 1.21087,
          l: 1.21074,
          c: 1.21077,
          v: 492820000
        },
        {
          t: 4500,
          o: 1.2108,
          h: 1.2108,
          l: 1.21053,
          c: 1.21055,
          v: 596980000
        },
        {
          t: 4800,
          o: 1.21056,
          h: 1.21061,
          l: 1.21026,
          c: 1.21026,
          v: 575660000
        },
        {
          t: 5100,
          o: 1.21026,
          h: 1.21034,
          l: 1.21026,
          c: 1.21032,
          v: 354160000
        },
        {
          t: 5400,
          o: 1.21032,
          h: 1.21038,
          l: 1.20972,
          c: 1.20972,
          v: 652300000
        },
        {
          t: 5700,
          o: 1.20973,
          h: 1.21034,
          l: 1.20965,
          c: 1.21032,
          v: 738620000
        },
        {
          t: 6000,
          o: 1.21032,
          h: 1.21063,
          l: 1.21018,
          c: 1.21033,
          v: 614150000
        },
        {
          t: 6300,
          o: 1.21033,
          h: 1.21074,
          l: 1.21032,
          c: 1.21068,
          v: 631800000
        },
        {
          t: 6600,
          o: 1.21068,
          h: 1.21077,
          l: 1.21056,
          c: 1.21072,
          v: 672950000
        },
        {
          t: 6900,
          o: 1.21071,
          h: 1.21098,
          l: 1.2104,
          c: 1.2109,
          v: 916710000
        },
        {
          t: 7200,
          o: 1.21089,
          h: 1.2109,
          l: 1.21064,
          c: 1.21064,
          v: 609080000
        },
        {
          t: 7500,
          o: 1.21064,
          h: 1.21074,
          l: 1.21053,
          c: 1.21062,
          v: 573230000
        },
        {
          t: 7800,
          o: 1.21062,
          h: 1.2107,
          l: 1.21046,
          c: 1.21056,
          v: 460830000
        },
        {
          t: 8100,
          o: 1.21057,
          h: 1.21077,
          l: 1.21037,
          c: 1.21077,
          v: 570840000
        },
        {
          t: 8400,
          o: 1.21077,
          h: 1.21083,
          l: 1.21072,
          c: 1.21082,
          v: 351130000
        },
        {
          t: 8700,
          o: 1.21082,
          h: 1.2112,
          l: 1.2108,
          c: 1.21107,
          v: 575370000
        },
        {
          t: 9000,
          o: 1.21107,
          h: 1.21107,
          l: 1.21089,
          c: 1.21093,
          v: 440880000
        },
        {
          t: 9300,
          o: 1.21093,
          h: 1.21094,
          l: 1.21084,
          c: 1.21084,
          v: 275060000
        },
        {
          t: 9600,
          o: 1.21085,
          h: 1.21097,
          l: 1.21084,
          c: 1.21093,
          v: 287560000
        },
        {
          t: 9900,
          o: 1.21094,
          h: 1.21128,
          l: 1.21089,
          c: 1.21128,
          v: 364570000
        },
        {
          t: 10200,
          o: 1.21126,
          h: 1.21133,
          l: 1.21113,
          c: 1.21117,
          v: 393980000
        },
        {
          t: 10500,
          o: 1.21117,
          h: 1.21122,
          l: 1.21112,
          c: 1.21118,
          v: 393230000
        },
        {
          t: 10800,
          o: 1.21118,
          h: 1.21133,
          l: 1.21106,
          c: 1.2112,
          v: 446120000
        },
        {
          t: 11100,
          o: 1.2112,
          h: 1.21129,
          l: 1.2112,
          c: 1.21128,
          v: 332350000
        },
        {
          t: 11400,
          o: 1.21127,
          h: 1.21129,
          l: 1.21094,
          c: 1.21094,
          v: 395270000
        },
        {
          t: 11700,
          o: 1.21096,
          h: 1.21118,
          l: 1.21088,
          c: 1.21118,
          v: 408700000
        },
        {
          t: 12000,
          o: 1.21118,
          h: 1.21132,
          l: 1.21104,
          c: 1.21106,
          v: 263610000
        },
        {
          t: 12300,
          o: 1.21106,
          h: 1.21117,
          l: 1.21101,
          c: 1.21101,
          v: 404970000
        },
        {
          t: 12600,
          o: 1.21101,
          h: 1.21105,
          l: 1.21095,
          c: 1.211,
          v: 385430000
        },
        {
          t: 12900,
          o: 1.21099,
          h: 1.21118,
          l: 1.21098,
          c: 1.21117,
          v: 315250000
        },
        {
          t: 13200,
          o: 1.21118,
          h: 1.21143,
          l: 1.21117,
          c: 1.21137,
          v: 433940000
        },
        {
          t: 13500,
          o: 1.21138,
          h: 1.21155,
          l: 1.21128,
          c: 1.21148,
          v: 362230000
        },
        {
          t: 13800,
          o: 1.21148,
          h: 1.21154,
          l: 1.2112,
          c: 1.21123,
          v: 442650001
        },
        {
          t: 14100,
          o: 1.21124,
          h: 1.21164,
          l: 1.21121,
          c: 1.21149,
          v: 514690000
        },
        {
          t: 14400,
          o: 1.21149,
          h: 1.2115,
          l: 1.21125,
          c: 1.21129,
          v: 296660000
        },
        {
          t: 14700,
          o: 1.21129,
          h: 1.2113,
          l: 1.21101,
          c: 1.21114,
          v: 403980000
        },
        {
          t: 15000,
          o: 1.21113,
          h: 1.21115,
          l: 1.21101,
          c: 1.21107,
          v: 443510000
        },
        {
          t: 15300,
          o: 1.21107,
          h: 1.2112,
          l: 1.21101,
          c: 1.2112,
          v: 269710000
        },
        {
          t: 15600,
          o: 1.21119,
          h: 1.2112,
          l: 1.21106,
          c: 1.21115,
          v: 276790000
        },
        {
          t: 15900,
          o: 1.21114,
          h: 1.21118,
          l: 1.21099,
          c: 1.21102,
          v: 265590000
        },
        {
          t: 16200,
          o: 1.21101,
          h: 1.21115,
          l: 1.21101,
          c: 1.21113,
          v: 314940000
        },
        {
          t: 16500,
          o: 1.21114,
          h: 1.21118,
          l: 1.21107,
          c: 1.21118,
          v: 200900000
        },
        {
          t: 16800,
          o: 1.21118,
          h: 1.21128,
          l: 1.21106,
          c: 1.21108,
          v: 509480000
        },
        {
          t: 17100,
          o: 1.21109,
          h: 1.21127,
          l: 1.21108,
          c: 1.2111,
          v: 559980000
        },
        {
          t: 17400,
          o: 1.21109,
          h: 1.21117,
          l: 1.21104,
          c: 1.21116,
          v: 494810000
        },
        {
          t: 17700,
          o: 1.21116,
          h: 1.21125,
          l: 1.21114,
          c: 1.21116,
          v: 476300000
        },
        {
          t: 18000,
          o: 1.21115,
          h: 1.2112,
          l: 1.21098,
          c: 1.21101,
          v: 475120000
        },
        {
          t: 18300,
          o: 1.21102,
          h: 1.21104,
          l: 1.21095,
          c: 1.21095,
          v: 414660000
        },
        {
          t: 18600,
          o: 1.21095,
          h: 1.21095,
          l: 1.21045,
          c: 1.21055,
          v: 812700000
        },
        {
          t: 18900,
          o: 1.21055,
          h: 1.2108,
          l: 1.21054,
          c: 1.21071,
          v: 435820000
        },
        {
          t: 19200,
          o: 1.2107,
          h: 1.21074,
          l: 1.21056,
          c: 1.21058,
          v: 447990000
        },
        {
          t: 19500,
          o: 1.21058,
          h: 1.2106,
          l: 1.2105,
          c: 1.2106,
          v: 394210000
        },
        {
          t: 19800,
          o: 1.21059,
          h: 1.21067,
          l: 1.21045,
          c: 1.21045,
          v: 492020000
        },
        {
          t: 20100,
          o: 1.21045,
          h: 1.21086,
          l: 1.21045,
          c: 1.21069,
          v: 531730000
        },
        {
          t: 20400,
          o: 1.21069,
          h: 1.21092,
          l: 1.21069,
          c: 1.2109,
          v: 508800000
        },
        {
          t: 20700,
          o: 1.2109,
          h: 1.21102,
          l: 1.21085,
          c: 1.21097,
          v: 616360000
        },
        {
          t: 21000,
          o: 1.21097,
          h: 1.21116,
          l: 1.21094,
          c: 1.21097,
          v: 482270000
        },
        {
          t: 21300,
          o: 1.21098,
          h: 1.21099,
          l: 1.21058,
          c: 1.21063,
          v: 544090000
        },
        {
          t: 21600,
          o: 1.21063,
          h: 1.211,
          l: 1.21056,
          c: 1.21096,
          v: 660040000
        },
        {
          t: 21900,
          o: 1.21096,
          h: 1.21107,
          l: 1.21082,
          c: 1.21096,
          v: 442940000
        },
        {
          t: 22200,
          o: 1.21096,
          h: 1.21096,
          l: 1.21016,
          c: 1.21023,
          v: 994740000
        },
        {
          t: 22500,
          o: 1.21028,
          h: 1.21041,
          l: 1.20949,
          c: 1.20985,
          v: 1510100001
        },
        {
          t: 22800,
          o: 1.20983,
          h: 1.20992,
          l: 1.2095,
          c: 1.20966,
          v: 1244820000
        },
        {
          t: 23100,
          o: 1.20965,
          h: 1.20978,
          l: 1.20914,
          c: 1.20914,
          v: 1444890000
        },
        {
          t: 23400,
          o: 1.20915,
          h: 1.20949,
          l: 1.20889,
          c: 1.2091,
          v: 1517570000
        },
        {
          t: 23700,
          o: 1.2091,
          h: 1.20946,
          l: 1.20903,
          c: 1.20912,
          v: 1114540000
        },
        {
          t: 24000,
          o: 1.20912,
          h: 1.2095,
          l: 1.20912,
          c: 1.20926,
          v: 1263380000
        },
        {
          t: 24300,
          o: 1.20927,
          h: 1.20941,
          l: 1.2092,
          c: 1.20933,
          v: 827670000
        },
        {
          t: 24600,
          o: 1.20934,
          h: 1.20965,
          l: 1.2092,
          c: 1.20964,
          v: 1179540000
        },
        {
          t: 24900,
          o: 1.20965,
          h: 1.20968,
          l: 1.20842,
          c: 1.20879,
          v: 1472520000
        },
        {
          t: 25200,
          o: 1.20881,
          h: 1.209,
          l: 1.20859,
          c: 1.20863,
          v: 1131710000
        },
        {
          t: 25500,
          o: 1.20863,
          h: 1.2088,
          l: 1.20801,
          c: 1.20813,
          v: 1225770000
        },
        {
          t: 25800,
          o: 1.20813,
          h: 1.20828,
          l: 1.208,
          c: 1.20804,
          v: 914570000
        },
        {
          t: 26100,
          o: 1.20801,
          h: 1.20822,
          l: 1.20754,
          c: 1.20764,
          v: 1196640000
        },
        {
          t: 26400,
          o: 1.20766,
          h: 1.20774,
          l: 1.20661,
          c: 1.20704,
          v: 1563770000
        },
        {
          t: 26700,
          o: 1.20705,
          h: 1.20724,
          l: 1.20666,
          c: 1.20721,
          v: 1126500000
        },
        {
          t: 27000,
          o: 1.20721,
          h: 1.20721,
          l: 1.20649,
          c: 1.20658,
          v: 1098090000
        },
        {
          t: 27300,
          o: 1.20657,
          h: 1.20706,
          l: 1.20657,
          c: 1.20665,
          v: 1091570000
        },
        {
          t: 27600,
          o: 1.20663,
          h: 1.20731,
          l: 1.20663,
          c: 1.20731,
          v: 845230000
        },
        {
          t: 27900,
          o: 1.20731,
          h: 1.20768,
          l: 1.20717,
          c: 1.20759,
          v: 1056380000
        },
        {
          t: 28200,
          o: 1.20759,
          h: 1.20759,
          l: 1.20702,
          c: 1.20734,
          v: 730830000
        },
        {
          t: 28500,
          o: 1.20732,
          h: 1.20774,
          l: 1.20699,
          c: 1.20757,
          v: 825710000
        },
        {
          t: 28800,
          o: 1.20759,
          h: 1.20806,
          l: 1.20754,
          c: 1.20803,
          v: 1300960000
        },
        {
          t: 29100,
          o: 1.20805,
          h: 1.20847,
          l: 1.20802,
          c: 1.20806,
          v: 1093590000
        },
        {
          t: 29400,
          o: 1.20806,
          h: 1.20838,
          l: 1.20803,
          c: 1.20827,
          v: 918660000
        },
        {
          t: 29700,
          o: 1.20827,
          h: 1.20836,
          l: 1.20813,
          c: 1.20817,
          v: 762880000
        },
        {
          t: 30000,
          o: 1.20817,
          h: 1.2082,
          l: 1.20732,
          c: 1.20735,
          v: 896350000
        },
        {
          t: 30300,
          o: 1.20735,
          h: 1.20754,
          l: 1.20721,
          c: 1.2074,
          v: 624220000
        },
        {
          t: 30600,
          o: 1.20739,
          h: 1.20739,
          l: 1.20647,
          c: 1.20694,
          v: 1566880000
        },
        {
          t: 30900,
          o: 1.20695,
          h: 1.20739,
          l: 1.20655,
          c: 1.20736,
          v: 1486220000
        },
        {
          t: 31200,
          o: 1.20734,
          h: 1.20751,
          l: 1.20715,
          c: 1.20717,
          v: 1156540000
        },
        {
          t: 31500,
          o: 1.20716,
          h: 1.20793,
          l: 1.20711,
          c: 1.20755,
          v: 1286950000
        },
        {
          t: 31800,
          o: 1.20755,
          h: 1.20755,
          l: 1.20711,
          c: 1.20721,
          v: 907920000
        },
        {
          t: 32100,
          o: 1.20722,
          h: 1.20736,
          l: 1.20693,
          c: 1.20715,
          v: 1085040000
        },
        {
          t: 32400,
          o: 1.20715,
          h: 1.20807,
          l: 1.20715,
          c: 1.20795,
          v: 1182630000
        },
        {
          t: 32700,
          o: 1.20794,
          h: 1.20794,
          l: 1.20764,
          c: 1.20777,
          v: 902690000
        },
        {
          t: 33000,
          o: 1.20778,
          h: 1.20808,
          l: 1.20766,
          c: 1.2079,
          v: 944120000
        },
        {
          t: 33300,
          o: 1.2079,
          h: 1.20861,
          l: 1.20787,
          c: 1.20833,
          v: 1290110001
        },
        {
          t: 33600,
          o: 1.20832,
          h: 1.20856,
          l: 1.20801,
          c: 1.20803,
          v: 776700000
        },
        {
          t: 33900,
          o: 1.20803,
          h: 1.20841,
          l: 1.208,
          c: 1.2081,
          v: 616860000
        },
        {
          t: 34200,
          o: 1.2081,
          h: 1.20815,
          l: 1.20779,
          c: 1.20811,
          v: 895230000
        },
        {
          t: 34500,
          o: 1.20811,
          h: 1.20872,
          l: 1.20808,
          c: 1.20844,
          v: 1027970000
        },
        {
          t: 34800,
          o: 1.20846,
          h: 1.20869,
          l: 1.20829,
          c: 1.20843,
          v: 902610000
        },
        {
          t: 35100,
          o: 1.20842,
          h: 1.20847,
          l: 1.20797,
          c: 1.20823,
          v: 890020000
        },
        {
          t: 35400,
          o: 1.20823,
          h: 1.20823,
          l: 1.20754,
          c: 1.20787,
          v: 849970001
        },
        {
          t: 35700,
          o: 1.20785,
          h: 1.20827,
          l: 1.20779,
          c: 1.20823,
          v: 908140000
        },
        {
          t: 36000,
          o: 1.20822,
          h: 1.2085,
          l: 1.20799,
          c: 1.20831,
          v: 1129380000
        },
        {
          t: 36300,
          o: 1.20832,
          h: 1.20842,
          l: 1.20715,
          c: 1.2073,
          v: 1126700000
        },
        {
          t: 36600,
          o: 1.2073,
          h: 1.20809,
          l: 1.20729,
          c: 1.20808,
          v: 669719999
        },
        {
          t: 36900,
          o: 1.20806,
          h: 1.20817,
          l: 1.20785,
          c: 1.20794,
          v: 679230000
        },
        {
          t: 37200,
          o: 1.20793,
          h: 1.20802,
          l: 1.20787,
          c: 1.20791,
          v: 495020000
        },
        {
          t: 37500,
          o: 1.20791,
          h: 1.20835,
          l: 1.20791,
          c: 1.20834,
          v: 619420000
        },
        {
          t: 37800,
          o: 1.20835,
          h: 1.2087,
          l: 1.20822,
          c: 1.2087,
          v: 715030000
        },
        {
          t: 38100,
          o: 1.2087,
          h: 1.20929,
          l: 1.2086,
          c: 1.20924,
          v: 918190000
        },
        {
          t: 38400,
          o: 1.20923,
          h: 1.20949,
          l: 1.20901,
          c: 1.2092,
          v: 829640000
        },
        {
          t: 38700,
          o: 1.2092,
          h: 1.20948,
          l: 1.2089,
          c: 1.20898,
          v: 918220000
        },
        {
          t: 39000,
          o: 1.20897,
          h: 1.20913,
          l: 1.20865,
          c: 1.20913,
          v: 902070000
        },
        {
          t: 39300,
          o: 1.20912,
          h: 1.20912,
          l: 1.20841,
          c: 1.20877,
          v: 1200960000
        },
        {
          t: 39600,
          o: 1.20875,
          h: 1.20888,
          l: 1.20828,
          c: 1.20861,
          v: 1101490000
        },
        {
          t: 39900,
          o: 1.20861,
          h: 1.20889,
          l: 1.20845,
          c: 1.20856,
          v: 1148320000
        },
        {
          t: 40200,
          o: 1.20855,
          h: 1.20855,
          l: 1.20801,
          c: 1.20811,
          v: 963460000
        },
        {
          t: 40500,
          o: 1.20811,
          h: 1.20816,
          l: 1.20791,
          c: 1.20803,
          v: 852130000
        },
        {
          t: 40800,
          o: 1.20803,
          h: 1.20834,
          l: 1.20802,
          c: 1.20822,
          v: 952220000
        },
        {
          t: 41100,
          o: 1.20823,
          h: 1.2084,
          l: 1.20809,
          c: 1.20823,
          v: 779730000
        },
        {
          t: 41400,
          o: 1.20822,
          h: 1.20837,
          l: 1.20791,
          c: 1.20796,
          v: 751680000
        },
        {
          t: 41700,
          o: 1.20796,
          h: 1.20803,
          l: 1.20774,
          c: 1.20798,
          v: 527230000
        },
        {
          t: 42000,
          o: 1.20798,
          h: 1.20807,
          l: 1.20782,
          c: 1.20794,
          v: 538090000
        },
        {
          t: 42300,
          o: 1.20795,
          h: 1.20808,
          l: 1.20736,
          c: 1.20745,
          v: 682160000
        },
        {
          t: 42600,
          o: 1.20746,
          h: 1.20749,
          l: 1.20701,
          c: 1.20738,
          v: 1056120000
        },
        {
          t: 42900,
          o: 1.20737,
          h: 1.20737,
          l: 1.20651,
          c: 1.20661,
          v: 1468660000
        },
        {
          t: 43200,
          o: 1.20659,
          h: 1.20689,
          l: 1.20635,
          c: 1.20655,
          v: 1235750000
        },
        {
          t: 43500,
          o: 1.20656,
          h: 1.20677,
          l: 1.20635,
          c: 1.20653,
          v: 949770000
        },
        {
          t: 43800,
          o: 1.20653,
          h: 1.20708,
          l: 1.20653,
          c: 1.20698,
          v: 855640000
        },
        {
          t: 44100,
          o: 1.20698,
          h: 1.20719,
          l: 1.20682,
          c: 1.20712,
          v: 747290000
        },
        {
          t: 44400,
          o: 1.20712,
          h: 1.20756,
          l: 1.20698,
          c: 1.20747,
          v: 917110000
        },
        {
          t: 44700,
          o: 1.20746,
          h: 1.20771,
          l: 1.20737,
          c: 1.20768,
          v: 896350000
        },
        {
          t: 45000,
          o: 1.20766,
          h: 1.20819,
          l: 1.20588,
          c: 1.20651,
          v: 2634260000
        },
        {
          t: 45300,
          o: 1.2065,
          h: 1.20741,
          l: 1.20576,
          c: 1.2059,
          v: 2025620000
        },
        {
          t: 45600,
          o: 1.20592,
          h: 1.20709,
          l: 1.20556,
          c: 1.20708,
          v: 1670450000
        },
        {
          t: 45900,
          o: 1.20708,
          h: 1.20727,
          l: 1.20667,
          c: 1.20717,
          v: 1191320000
        },
        {
          t: 46200,
          o: 1.20717,
          h: 1.20754,
          l: 1.20707,
          c: 1.20754,
          v: 1277020000
        },
        {
          t: 46500,
          o: 1.20752,
          h: 1.20879,
          l: 1.20716,
          c: 1.20844,
          v: 1525520000
        },
        {
          t: 46800,
          o: 1.20845,
          h: 1.20869,
          l: 1.20777,
          c: 1.20785,
          v: 1322530000
        },
        {
          t: 47100,
          o: 1.20785,
          h: 1.20844,
          l: 1.20779,
          c: 1.20827,
          v: 1071910000
        },
        {
          t: 47400,
          o: 1.20829,
          h: 1.20895,
          l: 1.20829,
          c: 1.2086,
          v: 1159080000
        },
        {
          t: 47700,
          o: 1.2086,
          h: 1.2086,
          l: 1.20807,
          c: 1.20822,
          v: 914730000
        },
        {
          t: 48000,
          o: 1.20824,
          h: 1.20877,
          l: 1.20811,
          c: 1.20873,
          v: 1151270001
        },
        {
          t: 48300,
          o: 1.20875,
          h: 1.20895,
          l: 1.20838,
          c: 1.20839,
          v: 1168780000
        },
        {
          t: 48600,
          o: 1.20841,
          h: 1.20909,
          l: 1.20838,
          c: 1.20888,
          v: 1245220000
        },
        {
          t: 48900,
          o: 1.20888,
          h: 1.20945,
          l: 1.20886,
          c: 1.2092,
          v: 1468510000
        },
        {
          t: 49200,
          o: 1.2092,
          h: 1.20931,
          l: 1.20861,
          c: 1.20894,
          v: 1091270000
        },
        {
          t: 49500,
          o: 1.20894,
          h: 1.20935,
          l: 1.20882,
          c: 1.20911,
          v: 1003280000
        },
        {
          t: 49800,
          o: 1.20911,
          h: 1.20924,
          l: 1.2086,
          c: 1.20863,
          v: 1418160002
        },
        {
          t: 50100,
          o: 1.20863,
          h: 1.20869,
          l: 1.2081,
          c: 1.20828,
          v: 1309780000
        },
        {
          t: 50400,
          o: 1.20831,
          h: 1.20835,
          l: 1.20769,
          c: 1.20798,
          v: 1335170000
        },
        {
          t: 50700,
          o: 1.20797,
          h: 1.20817,
          l: 1.20767,
          c: 1.20794,
          v: 1377510000
        },
        {
          t: 51000,
          o: 1.20797,
          h: 1.20829,
          l: 1.20759,
          c: 1.20777,
          v: 1119080000
        },
        {
          t: 51300,
          o: 1.20776,
          h: 1.20802,
          l: 1.20734,
          c: 1.208,
          v: 1277880000
        },
        {
          t: 51600,
          o: 1.208,
          h: 1.20835,
          l: 1.20777,
          c: 1.20815,
          v: 1277920000
        },
        {
          t: 51900,
          o: 1.20816,
          h: 1.20883,
          l: 1.20802,
          c: 1.2085,
          v: 1002310000
        },
        {
          t: 52200,
          o: 1.2085,
          h: 1.2095,
          l: 1.2085,
          c: 1.20942,
          v: 1312370000
        },
        {
          t: 52500,
          o: 1.20942,
          h: 1.2111,
          l: 1.20938,
          c: 1.21088,
          v: 1885470002
        },
        {
          t: 52800,
          o: 1.2109,
          h: 1.21177,
          l: 1.21074,
          c: 1.21141,
          v: 1647730000
        },
        {
          t: 53100,
          o: 1.21139,
          h: 1.21164,
          l: 1.21102,
          c: 1.21115,
          v: 1396310000
        },
        {
          t: 53400,
          o: 1.21115,
          h: 1.21121,
          l: 1.21044,
          c: 1.21101,
          v: 1262119999
        },
        {
          t: 53700,
          o: 1.21103,
          h: 1.21103,
          l: 1.20955,
          c: 1.21011,
          v: 1660860001
        },
        {
          t: 54000,
          o: 1.21006,
          h: 1.21053,
          l: 1.20971,
          c: 1.20972,
          v: 1600560000
        },
        {
          t: 54300,
          o: 1.20972,
          h: 1.2105,
          l: 1.20964,
          c: 1.21043,
          v: 1043590000
        },
        {
          t: 54600,
          o: 1.21047,
          h: 1.21121,
          l: 1.21027,
          c: 1.21115,
          v: 1391960000
        },
        {
          t: 54900,
          o: 1.21114,
          h: 1.21127,
          l: 1.21079,
          c: 1.21125,
          v: 1104749999
        },
        {
          t: 55200,
          o: 1.21123,
          h: 1.21127,
          l: 1.21077,
          c: 1.21089,
          v: 1204780000
        },
        {
          t: 55500,
          o: 1.21088,
          h: 1.21122,
          l: 1.21041,
          c: 1.21047,
          v: 1293140000
        },
        {
          t: 55800,
          o: 1.21046,
          h: 1.21061,
          l: 1.21032,
          c: 1.21034,
          v: 856240000
        },
        {
          t: 56100,
          o: 1.21033,
          h: 1.2105,
          l: 1.21017,
          c: 1.21033,
          v: 952860001
        },
        {
          t: 56400,
          o: 1.21033,
          h: 1.21056,
          l: 1.21031,
          c: 1.21055,
          v: 733620000
        },
        {
          t: 56700,
          o: 1.21055,
          h: 1.21092,
          l: 1.21033,
          c: 1.21079,
          v: 900880000
        },
        {
          t: 57000,
          o: 1.21078,
          h: 1.21094,
          l: 1.21067,
          c: 1.21088,
          v: 836510000
        },
        {
          t: 57300,
          o: 1.21087,
          h: 1.2111,
          l: 1.21073,
          c: 1.21079,
          v: 875000000
        },
        {
          t: 57600,
          o: 1.21078,
          h: 1.21098,
          l: 1.21063,
          c: 1.21087,
          v: 839510000
        },
        {
          t: 57900,
          o: 1.21087,
          h: 1.21087,
          l: 1.21054,
          c: 1.21077,
          v: 626600002
        },
        {
          t: 58200,
          o: 1.21077,
          h: 1.21078,
          l: 1.21058,
          c: 1.21062,
          v: 751680000
        },
        {
          t: 58500,
          o: 1.21063,
          h: 1.2107,
          l: 1.21049,
          c: 1.21053,
          v: 583500000
        },
        {
          t: 58800,
          o: 1.21051,
          h: 1.21087,
          l: 1.21051,
          c: 1.21059,
          v: 709560000
        },
        {
          t: 59100,
          o: 1.2106,
          h: 1.21061,
          l: 1.21018,
          c: 1.21036,
          v: 836040000
        },
        {
          t: 59400,
          o: 1.21037,
          h: 1.21096,
          l: 1.21036,
          c: 1.21083,
          v: 829980000
        },
        {
          t: 59700,
          o: 1.21083,
          h: 1.2112,
          l: 1.21082,
          c: 1.21082,
          v: 1007710000
        },
        {
          t: 60000,
          o: 1.21082,
          h: 1.21097,
          l: 1.21071,
          c: 1.21094,
          v: 858869999
        },
        {
          t: 60300,
          o: 1.21095,
          h: 1.21146,
          l: 1.21095,
          c: 1.21118,
          v: 924290000
        },
        {
          t: 60600,
          o: 1.21119,
          h: 1.21143,
          l: 1.21109,
          c: 1.21129,
          v: 621610000
        },
        {
          t: 60900,
          o: 1.21129,
          h: 1.21153,
          l: 1.21106,
          c: 1.21114,
          v: 1172710000
        },
        {
          t: 61200,
          o: 1.21114,
          h: 1.21118,
          l: 1.21091,
          c: 1.21101,
          v: 827570000
        },
        {
          t: 61500,
          o: 1.21101,
          h: 1.21103,
          l: 1.21056,
          c: 1.21057,
          v: 763760000
        },
        {
          t: 61800,
          o: 1.21057,
          h: 1.21078,
          l: 1.21051,
          c: 1.21053,
          v: 628050000
        },
        {
          t: 62100,
          o: 1.21052,
          h: 1.21062,
          l: 1.21034,
          c: 1.21034,
          v: 788820000
        },
        {
          t: 62400,
          o: 1.21034,
          h: 1.21045,
          l: 1.21026,
          c: 1.21033,
          v: 674460000
        },
        {
          t: 62700,
          o: 1.21032,
          h: 1.21042,
          l: 1.21016,
          c: 1.2104,
          v: 726530000
        },
        {
          t: 63000,
          o: 1.21041,
          h: 1.21063,
          l: 1.21032,
          c: 1.21032,
          v: 789820000
        },
        {
          t: 63300,
          o: 1.21033,
          h: 1.21054,
          l: 1.2101,
          c: 1.2101,
          v: 567140000
        },
        {
          t: 63600,
          o: 1.21011,
          h: 1.21028,
          l: 1.21008,
          c: 1.21022,
          v: 614470000
        },
        {
          t: 63900,
          o: 1.21021,
          h: 1.21023,
          l: 1.21002,
          c: 1.21022,
          v: 719070000
        },
        {
          t: 64200,
          o: 1.21022,
          h: 1.21074,
          l: 1.21018,
          c: 1.21062,
          v: 689650000
        },
        {
          t: 64500,
          o: 1.21064,
          h: 1.21072,
          l: 1.2104,
          c: 1.21052,
          v: 692540000
        },
        {
          t: 64800,
          o: 1.21052,
          h: 1.21058,
          l: 1.21035,
          c: 1.2105,
          v: 1060220000
        },
        {
          t: 65100,
          o: 1.21052,
          h: 1.21073,
          l: 1.21051,
          c: 1.21063,
          v: 776540000
        },
        {
          t: 65400,
          o: 1.21064,
          h: 1.21091,
          l: 1.21062,
          c: 1.21086,
          v: 638440000
        },
        {
          t: 65700,
          o: 1.21086,
          h: 1.21105,
          l: 1.2108,
          c: 1.21105,
          v: 722030000
        },
        {
          t: 66000,
          o: 1.21104,
          h: 1.21118,
          l: 1.21097,
          c: 1.21113,
          v: 734800001
        },
        {
          t: 66300,
          o: 1.21113,
          h: 1.21114,
          l: 1.21095,
          c: 1.21102,
          v: 420390000
        },
        {
          t: 66600,
          o: 1.21101,
          h: 1.21123,
          l: 1.21095,
          c: 1.21121,
          v: 519240000
        },
        {
          t: 66900,
          o: 1.2112,
          h: 1.21148,
          l: 1.21115,
          c: 1.21147,
          v: 954180000
        },
        {
          t: 67200,
          o: 1.21148,
          h: 1.21189,
          l: 1.21147,
          c: 1.21184,
          v: 950990000
        },
        {
          t: 67500,
          o: 1.21182,
          h: 1.2123,
          l: 1.21167,
          c: 1.21201,
          v: 1161750000
        },
        {
          t: 67800,
          o: 1.21201,
          h: 1.21225,
          l: 1.21197,
          c: 1.21216,
          v: 1026130000
        },
        {
          t: 68100,
          o: 1.21216,
          h: 1.2123,
          l: 1.21199,
          c: 1.2122,
          v: 1251840000
        },
        {
          t: 68400,
          o: 1.21219,
          h: 1.21257,
          l: 1.212,
          c: 1.2124,
          v: 1171450000
        },
        {
          t: 68700,
          o: 1.21237,
          h: 1.21257,
          l: 1.2123,
          c: 1.2124,
          v: 854660000
        },
        {
          t: 69000,
          o: 1.2124,
          h: 1.21269,
          l: 1.21229,
          c: 1.2123,
          v: 1197870000
        },
        {
          t: 69300,
          o: 1.2123,
          h: 1.21266,
          l: 1.2123,
          c: 1.21261,
          v: 794730000
        },
        {
          t: 69600,
          o: 1.21262,
          h: 1.21271,
          l: 1.21248,
          c: 1.21261,
          v: 1070710000
        },
        {
          t: 69900,
          o: 1.21258,
          h: 1.21275,
          l: 1.21252,
          c: 1.2126,
          v: 669060000
        },
        {
          t: 70200,
          o: 1.2126,
          h: 1.21286,
          l: 1.21244,
          c: 1.21273,
          v: 923749999
        },
        {
          t: 70500,
          o: 1.21273,
          h: 1.21296,
          l: 1.21267,
          c: 1.21288,
          v: 921520000
        },
        {
          t: 70800,
          o: 1.2129,
          h: 1.21326,
          l: 1.21288,
          c: 1.21323,
          v: 742790000
        },
        {
          t: 71100,
          o: 1.21324,
          h: 1.21327,
          l: 1.21289,
          c: 1.21295,
          v: 859250000
        },
        {
          t: 71400,
          o: 1.21297,
          h: 1.2131,
          l: 1.21279,
          c: 1.21282,
          v: 1020100002
        },
        {
          t: 71700,
          o: 1.21281,
          h: 1.21322,
          l: 1.21281,
          c: 1.21305,
          v: 883930000
        },
        {
          t: 72000,
          o: 1.21305,
          h: 1.21311,
          l: 1.21274,
          c: 1.21278,
          v: 540660000
        },
        {
          t: 72300,
          o: 1.21279,
          h: 1.21284,
          l: 1.21273,
          c: 1.2128,
          v: 369420000
        },
        {
          t: 72600,
          o: 1.2128,
          h: 1.21286,
          l: 1.21263,
          c: 1.21269,
          v: 363350000
        },
        {
          t: 72900,
          o: 1.2127,
          h: 1.2129,
          l: 1.21266,
          c: 1.21287,
          v: 292450000
        },
        {
          t: 73200,
          o: 1.21286,
          h: 1.21295,
          l: 1.21285,
          c: 1.21294,
          v: 197050000
        },
        {
          t: 73500,
          o: 1.21294,
          h: 1.21305,
          l: 1.2129,
          c: 1.21304,
          v: 263370000
        },
        {
          t: 73800,
          o: 1.21305,
          h: 1.21311,
          l: 1.21296,
          c: 1.21306,
          v: 226940000
        },
        {
          t: 74100,
          o: 1.21306,
          h: 1.21309,
          l: 1.21299,
          c: 1.21304,
          v: 158910000
        },
        {
          t: 74400,
          o: 1.21306,
          h: 1.2131,
          l: 1.21283,
          c: 1.21295,
          v: 395120000
        },
        {
          t: 74700,
          o: 1.21296,
          h: 1.21301,
          l: 1.21288,
          c: 1.21289,
          v: 241310000
        },
        {
          t: 75000,
          o: 1.2129,
          h: 1.21309,
          l: 1.21288,
          c: 1.21292,
          v: 448290000
        },
        {
          t: 75300,
          o: 1.21293,
          h: 1.21314,
          l: 1.21285,
          c: 1.21291,
          v: 534710000
        }
      ]
    },
    {
      d: 1524960000,
      b: [
        {
          t: 75600,
          o: 1.21224,
          h: 1.21273,
          l: 1.21224,
          c: 1.21245,
          v: 68050000
        },
        {
          t: 75900,
          o: 1.21246,
          h: 1.21249,
          l: 1.21236,
          c: 1.21237,
          v: 98270000
        },
        {
          t: 76200,
          o: 1.21238,
          h: 1.21242,
          l: 1.21238,
          c: 1.21241,
          v: 37100000
        },
        {
          t: 76500,
          o: 1.21241,
          h: 1.21267,
          l: 1.21241,
          c: 1.21261,
          v: 123230000
        },
        {
          t: 76800,
          o: 1.21263,
          h: 1.21275,
          l: 1.21245,
          c: 1.21271,
          v: 82260000
        },
        {
          t: 77100,
          o: 1.21272,
          h: 1.21282,
          l: 1.21269,
          c: 1.21282,
          v: 98430000
        },
        {
          t: 77400,
          o: 1.21282,
          h: 1.21282,
          l: 1.21251,
          c: 1.21263,
          v: 211410000
        },
        {
          t: 77700,
          o: 1.21263,
          h: 1.2129,
          l: 1.21263,
          c: 1.21284,
          v: 122890000
        },
        {
          t: 78000,
          o: 1.21284,
          h: 1.2129,
          l: 1.21283,
          c: 1.21284,
          v: 102910000
        },
        {
          t: 78300,
          o: 1.2128,
          h: 1.2128,
          l: 1.21262,
          c: 1.21264,
          v: 170550000
        },
        {
          t: 78600,
          o: 1.21263,
          h: 1.21276,
          l: 1.21262,
          c: 1.21273,
          v: 108040000
        },
        {
          t: 78900,
          o: 1.21274,
          h: 1.21278,
          l: 1.2125,
          c: 1.21277,
          v: 143270000
        },
        {
          t: 79200,
          o: 1.21277,
          h: 1.21284,
          l: 1.21245,
          c: 1.21261,
          v: 204930000
        },
        {
          t: 79500,
          o: 1.21261,
          h: 1.21263,
          l: 1.21255,
          c: 1.21255,
          v: 342820000
        },
        {
          t: 79800,
          o: 1.21255,
          h: 1.21256,
          l: 1.21246,
          c: 1.21251,
          v: 248630000
        },
        {
          t: 80100,
          o: 1.21251,
          h: 1.21256,
          l: 1.21246,
          c: 1.2125,
          v: 251500000
        },
        {
          t: 80400,
          o: 1.2125,
          h: 1.2125,
          l: 1.21221,
          c: 1.21227,
          v: 323920000
        },
        {
          t: 80700,
          o: 1.21227,
          h: 1.21242,
          l: 1.21227,
          c: 1.21238,
          v: 232520000
        },
        {
          t: 81000,
          o: 1.21244,
          h: 1.21258,
          l: 1.21214,
          c: 1.21219,
          v: 729550000
        },
        {
          t: 81300,
          o: 1.21219,
          h: 1.21223,
          l: 1.21201,
          c: 1.21216,
          v: 526220000
        },
        {
          t: 81600,
          o: 1.21217,
          h: 1.21223,
          l: 1.21216,
          c: 1.21223,
          v: 141640000
        },
        {
          t: 81900,
          o: 1.21222,
          h: 1.21223,
          l: 1.21208,
          c: 1.21208,
          v: 113980000
        },
        {
          t: 82200,
          o: 1.21208,
          h: 1.21213,
          l: 1.21202,
          c: 1.21209,
          v: 222830000
        },
        {
          t: 82500,
          o: 1.2121,
          h: 1.21218,
          l: 1.21209,
          c: 1.21213,
          v: 143820000
        },
        {
          t: 82800,
          o: 1.21213,
          h: 1.21226,
          l: 1.21213,
          c: 1.2122,
          v: 229060000
        },
        {
          t: 83100,
          o: 1.2122,
          h: 1.2122,
          l: 1.21211,
          c: 1.21212,
          v: 202990000
        },
        {
          t: 83400,
          o: 1.21212,
          h: 1.21216,
          l: 1.21209,
          c: 1.21212,
          v: 344940000
        },
        {
          t: 83700,
          o: 1.21212,
          h: 1.21217,
          l: 1.21207,
          c: 1.21208,
          v: 198870000
        },
        {
          t: 84000,
          o: 1.21208,
          h: 1.2121,
          l: 1.21208,
          c: 1.21208,
          v: 149070000
        },
        {
          t: 84300,
          o: 1.21209,
          h: 1.2121,
          l: 1.21206,
          c: 1.21207,
          v: 202800000
        },
        {
          t: 84600,
          o: 1.21207,
          h: 1.21218,
          l: 1.21202,
          c: 1.21209,
          v: 323630000
        },
        {
          t: 84900,
          o: 1.21208,
          h: 1.21215,
          l: 1.21197,
          c: 1.21197,
          v: 450359999
        },
        {
          t: 85200,
          o: 1.21198,
          h: 1.21208,
          l: 1.21188,
          c: 1.21201,
          v: 343540000
        },
        {
          t: 85500,
          o: 1.21202,
          h: 1.21225,
          l: 1.21195,
          c: 1.21217,
          v: 562530000
        },
        {
          t: 85800,
          o: 1.21217,
          h: 1.21219,
          l: 1.21214,
          c: 1.21215,
          v: 282400000
        },
        {
          t: 86100,
          o: 1.21215,
          h: 1.21226,
          l: 1.21215,
          c: 1.21226,
          v: 204580000
        }
      ]
    },
    {
      d: 1525046400,
      b: [
        {
          t: 0,
          o: 1.21226,
          h: 1.21242,
          l: 1.21217,
          c: 1.21241,
          v: 299600000
        },
        {
          t: 300,
          o: 1.21243,
          h: 1.21249,
          l: 1.21239,
          c: 1.21241,
          v: 259150000
        },
        {
          t: 600,
          o: 1.21241,
          h: 1.2127,
          l: 1.2124,
          c: 1.2127,
          v: 455650000
        },
        {
          t: 900,
          o: 1.21273,
          h: 1.21292,
          l: 1.21265,
          c: 1.2129,
          v: 484090000
        },
        {
          t: 1200,
          o: 1.21291,
          h: 1.21291,
          l: 1.21274,
          c: 1.21276,
          v: 368370000
        },
        {
          t: 1500,
          o: 1.21275,
          h: 1.2128,
          l: 1.21253,
          c: 1.21273,
          v: 401990000
        },
        {
          t: 1800,
          o: 1.21273,
          h: 1.2129,
          l: 1.21269,
          c: 1.21285,
          v: 397310000
        },
        {
          t: 2100,
          o: 1.21286,
          h: 1.21293,
          l: 1.21279,
          c: 1.21285,
          v: 285110000
        },
        {
          t: 2400,
          o: 1.21285,
          h: 1.21318,
          l: 1.21283,
          c: 1.21317,
          v: 291370000
        },
        {
          t: 2700,
          o: 1.21317,
          h: 1.21317,
          l: 1.21285,
          c: 1.21285,
          v: 239910000
        },
        {
          t: 3000,
          o: 1.21285,
          h: 1.2129,
          l: 1.2127,
          c: 1.21279,
          v: 329770000
        },
        {
          t: 3300,
          o: 1.21279,
          h: 1.21303,
          l: 1.21278,
          c: 1.21284,
          v: 445320000
        },
        {
          t: 3600,
          o: 1.21285,
          h: 1.21304,
          l: 1.21278,
          c: 1.21279,
          v: 474440000
        },
        {
          t: 3900,
          o: 1.21279,
          h: 1.21284,
          l: 1.21266,
          c: 1.21268,
          v: 247870000
        },
        {
          t: 4200,
          o: 1.21268,
          h: 1.21268,
          l: 1.21226,
          c: 1.2123,
          v: 459260000
        },
        {
          t: 4500,
          o: 1.21229,
          h: 1.21239,
          l: 1.21212,
          c: 1.21213,
          v: 463320000
        },
        {
          t: 4800,
          o: 1.21213,
          h: 1.21242,
          l: 1.21213,
          c: 1.21232,
          v: 568700000
        },
        {
          t: 5100,
          o: 1.21232,
          h: 1.21269,
          l: 1.2123,
          c: 1.21241,
          v: 425440000
        },
        {
          t: 5400,
          o: 1.2124,
          h: 1.21266,
          l: 1.2124,
          c: 1.21265,
          v: 554280000
        },
        {
          t: 5700,
          o: 1.21264,
          h: 1.21266,
          l: 1.21249,
          c: 1.21264,
          v: 308960000
        },
        {
          t: 6000,
          o: 1.21265,
          h: 1.21289,
          l: 1.2126,
          c: 1.21284,
          v: 464970000
        },
        {
          t: 6300,
          o: 1.21285,
          h: 1.21287,
          l: 1.21272,
          c: 1.21286,
          v: 266450000
        },
        {
          t: 6600,
          o: 1.21287,
          h: 1.21321,
          l: 1.21286,
          c: 1.21309,
          v: 530970000
        },
        {
          t: 6900,
          o: 1.2131,
          h: 1.21341,
          l: 1.21304,
          c: 1.21337,
          v: 589900000
        },
        {
          t: 7200,
          o: 1.21336,
          h: 1.21339,
          l: 1.2132,
          c: 1.21335,
          v: 502670000
        },
        {
          t: 7500,
          o: 1.21334,
          h: 1.21337,
          l: 1.2132,
          c: 1.2133,
          v: 450100000
        },
        {
          t: 7800,
          o: 1.2133,
          h: 1.21335,
          l: 1.21327,
          c: 1.21327,
          v: 407750000
        },
        {
          t: 8100,
          o: 1.21327,
          h: 1.21328,
          l: 1.21306,
          c: 1.21318,
          v: 435900000
        },
        {
          t: 8400,
          o: 1.21318,
          h: 1.21318,
          l: 1.21306,
          c: 1.21314,
          v: 319740000
        },
        {
          t: 8700,
          o: 1.21313,
          h: 1.21314,
          l: 1.21297,
          c: 1.21303,
          v: 514670000
        },
        {
          t: 9000,
          o: 1.21303,
          h: 1.21306,
          l: 1.21277,
          c: 1.21282,
          v: 487160000
        },
        {
          t: 9300,
          o: 1.21283,
          h: 1.21284,
          l: 1.21255,
          c: 1.21257,
          v: 611330000
        },
        {
          t: 9600,
          o: 1.21258,
          h: 1.21288,
          l: 1.21247,
          c: 1.21288,
          v: 583410000
        },
        {
          t: 9900,
          o: 1.21291,
          h: 1.21319,
          l: 1.21285,
          c: 1.21308,
          v: 652550000
        },
        {
          t: 10200,
          o: 1.21308,
          h: 1.21323,
          l: 1.21296,
          c: 1.21297,
          v: 340800000
        },
        {
          t: 10500,
          o: 1.21296,
          h: 1.21298,
          l: 1.2125,
          c: 1.21274,
          v: 579550000
        },
        {
          t: 10800,
          o: 1.21273,
          h: 1.21305,
          l: 1.21262,
          c: 1.21262,
          v: 529670000
        },
        {
          t: 11100,
          o: 1.21261,
          h: 1.21276,
          l: 1.21241,
          c: 1.21252,
          v: 746670001
        },
        {
          t: 11400,
          o: 1.21252,
          h: 1.21258,
          l: 1.21242,
          c: 1.21242,
          v: 482160000
        },
        {
          t: 11700,
          o: 1.21242,
          h: 1.21268,
          l: 1.2124,
          c: 1.21267,
          v: 479110000
        },
        {
          t: 12000,
          o: 1.21267,
          h: 1.21269,
          l: 1.21258,
          c: 1.21259,
          v: 174780000
        },
        {
          t: 12300,
          o: 1.21259,
          h: 1.21268,
          l: 1.21259,
          c: 1.21267,
          v: 272140000
        },
        {
          t: 12600,
          o: 1.21267,
          h: 1.21275,
          l: 1.21264,
          c: 1.21268,
          v: 393660000
        },
        {
          t: 12900,
          o: 1.21268,
          h: 1.21269,
          l: 1.21252,
          c: 1.21257,
          v: 354790000
        },
        {
          t: 13200,
          o: 1.21258,
          h: 1.21264,
          l: 1.2125,
          c: 1.21253,
          v: 245000000
        },
        {
          t: 13500,
          o: 1.21253,
          h: 1.21266,
          l: 1.21252,
          c: 1.21263,
          v: 344860000
        },
        {
          t: 13800,
          o: 1.21263,
          h: 1.21273,
          l: 1.21262,
          c: 1.21263,
          v: 324480000
        },
        {
          t: 14100,
          o: 1.21263,
          h: 1.21264,
          l: 1.21242,
          c: 1.21248,
          v: 491770000
        },
        {
          t: 14400,
          o: 1.21248,
          h: 1.21253,
          l: 1.21242,
          c: 1.21247,
          v: 520640000
        },
        {
          t: 14700,
          o: 1.21247,
          h: 1.21258,
          l: 1.21239,
          c: 1.21257,
          v: 364460000
        },
        {
          t: 15000,
          o: 1.21257,
          h: 1.21274,
          l: 1.21256,
          c: 1.21274,
          v: 256970000
        },
        {
          t: 15300,
          o: 1.21276,
          h: 1.21296,
          l: 1.21268,
          c: 1.21294,
          v: 365000000
        },
        {
          t: 15600,
          o: 1.21295,
          h: 1.21295,
          l: 1.21278,
          c: 1.21287,
          v: 329790000
        },
        {
          t: 15900,
          o: 1.21287,
          h: 1.21287,
          l: 1.21266,
          c: 1.21267,
          v: 317510000
        },
        {
          t: 16200,
          o: 1.21268,
          h: 1.21277,
          l: 1.21262,
          c: 1.21263,
          v: 319570000
        },
        {
          t: 16500,
          o: 1.21263,
          h: 1.21276,
          l: 1.21256,
          c: 1.21262,
          v: 297130000
        },
        {
          t: 16800,
          o: 1.21263,
          h: 1.21275,
          l: 1.21262,
          c: 1.21273,
          v: 301150000
        },
        {
          t: 17100,
          o: 1.21272,
          h: 1.21277,
          l: 1.21261,
          c: 1.21276,
          v: 405970000
        },
        {
          t: 17400,
          o: 1.21276,
          h: 1.21294,
          l: 1.21268,
          c: 1.21283,
          v: 429610000
        },
        {
          t: 17700,
          o: 1.21284,
          h: 1.21293,
          l: 1.21276,
          c: 1.2129,
          v: 485690000
        },
        {
          t: 18000,
          o: 1.21291,
          h: 1.21301,
          l: 1.21283,
          c: 1.2129,
          v: 535620000
        },
        {
          t: 18300,
          o: 1.2129,
          h: 1.21291,
          l: 1.21267,
          c: 1.21277,
          v: 489730001
        },
        {
          t: 18600,
          o: 1.21277,
          h: 1.21283,
          l: 1.21272,
          c: 1.21282,
          v: 320950000
        },
        {
          t: 18900,
          o: 1.21283,
          h: 1.21284,
          l: 1.21269,
          c: 1.21278,
          v: 486209999
        },
        {
          t: 19200,
          o: 1.21278,
          h: 1.21295,
          l: 1.21273,
          c: 1.21293,
          v: 562370000
        },
        {
          t: 19500,
          o: 1.21293,
          h: 1.21331,
          l: 1.2129,
          c: 1.21328,
          v: 506360000
        },
        {
          t: 19800,
          o: 1.21328,
          h: 1.21334,
          l: 1.21323,
          c: 1.21329,
          v: 438870000
        },
        {
          t: 20100,
          o: 1.21329,
          h: 1.21336,
          l: 1.21319,
          c: 1.21335,
          v: 479190000
        },
        {
          t: 20400,
          o: 1.21335,
          h: 1.21347,
          l: 1.21329,
          c: 1.21336,
          v: 468340000
        },
        {
          t: 20700,
          o: 1.21336,
          h: 1.21355,
          l: 1.21322,
          c: 1.21354,
          v: 600910000
        },
        {
          t: 21000,
          o: 1.21355,
          h: 1.21379,
          l: 1.21355,
          c: 1.21378,
          v: 630300000
        },
        {
          t: 21300,
          o: 1.21375,
          h: 1.2138,
          l: 1.21358,
          c: 1.21362,
          v: 584120000
        },
        {
          t: 21600,
          o: 1.21362,
          h: 1.21362,
          l: 1.21292,
          c: 1.21317,
          v: 1261630000
        },
        {
          t: 21900,
          o: 1.21316,
          h: 1.21319,
          l: 1.2129,
          c: 1.21297,
          v: 978760000
        },
        {
          t: 22200,
          o: 1.21297,
          h: 1.21315,
          l: 1.21266,
          c: 1.21292,
          v: 1176290000
        },
        {
          t: 22500,
          o: 1.21294,
          h: 1.21303,
          l: 1.21268,
          c: 1.21295,
          v: 1208100000
        },
        {
          t: 22800,
          o: 1.21293,
          h: 1.213,
          l: 1.21238,
          c: 1.21267,
          v: 1395990000
        },
        {
          t: 23100,
          o: 1.21266,
          h: 1.21346,
          l: 1.2126,
          c: 1.21333,
          v: 1387870000
        },
        {
          t: 23400,
          o: 1.21333,
          h: 1.21351,
          l: 1.21302,
          c: 1.21339,
          v: 1101400000
        },
        {
          t: 23700,
          o: 1.21337,
          h: 1.21352,
          l: 1.2127,
          c: 1.21335,
          v: 1531280000
        },
        {
          t: 24000,
          o: 1.21334,
          h: 1.21357,
          l: 1.21305,
          c: 1.21318,
          v: 1510140000
        },
        {
          t: 24300,
          o: 1.21318,
          h: 1.21324,
          l: 1.21279,
          c: 1.21306,
          v: 1184910000
        },
        {
          t: 24600,
          o: 1.21307,
          h: 1.21377,
          l: 1.21283,
          c: 1.21363,
          v: 1133500000
        },
        {
          t: 24900,
          o: 1.21365,
          h: 1.21386,
          l: 1.21324,
          c: 1.21349,
          v: 1031710001
        },
        {
          t: 25200,
          o: 1.21356,
          h: 1.21372,
          l: 1.21268,
          c: 1.2132,
          v: 1140880000
        },
        {
          t: 25500,
          o: 1.2132,
          h: 1.21335,
          l: 1.2129,
          c: 1.21323,
          v: 1051780000
        },
        {
          t: 25800,
          o: 1.21323,
          h: 1.21356,
          l: 1.2127,
          c: 1.21288,
          v: 1340840000
        },
        {
          t: 26100,
          o: 1.21289,
          h: 1.21301,
          l: 1.21245,
          c: 1.21256,
          v: 1245960000
        },
        {
          t: 26400,
          o: 1.21255,
          h: 1.21255,
          l: 1.21132,
          c: 1.2117,
          v: 1289900000
        },
        {
          t: 26700,
          o: 1.21172,
          h: 1.21193,
          l: 1.21133,
          c: 1.21141,
          v: 1038150000
        },
        {
          t: 27000,
          o: 1.21142,
          h: 1.21168,
          l: 1.21104,
          c: 1.21135,
          v: 1523090000
        },
        {
          t: 27300,
          o: 1.21134,
          h: 1.2117,
          l: 1.21086,
          c: 1.21104,
          v: 1208280000
        },
        {
          t: 27600,
          o: 1.21103,
          h: 1.21163,
          l: 1.21099,
          c: 1.21155,
          v: 873110000
        },
        {
          t: 27900,
          o: 1.21154,
          h: 1.21154,
          l: 1.21108,
          c: 1.21133,
          v: 804390000
        },
        {
          t: 28200,
          o: 1.21134,
          h: 1.21151,
          l: 1.21118,
          c: 1.21149,
          v: 882220000
        },
        {
          t: 28500,
          o: 1.21154,
          h: 1.2119,
          l: 1.21153,
          c: 1.21184,
          v: 914330000
        },
        {
          t: 28800,
          o: 1.21184,
          h: 1.2123,
          l: 1.21162,
          c: 1.21223,
          v: 1247480000
        },
        {
          t: 29100,
          o: 1.21224,
          h: 1.21224,
          l: 1.21167,
          c: 1.2117,
          v: 864270000
        },
        {
          t: 29400,
          o: 1.21171,
          h: 1.21171,
          l: 1.21114,
          c: 1.21148,
          v: 1217980000
        },
        {
          t: 29700,
          o: 1.21147,
          h: 1.21187,
          l: 1.2114,
          c: 1.21176,
          v: 745950000
        },
        {
          t: 30000,
          o: 1.21176,
          h: 1.21185,
          l: 1.21139,
          c: 1.21154,
          v: 1054950000
        },
        {
          t: 30300,
          o: 1.21154,
          h: 1.21204,
          l: 1.21154,
          c: 1.21183,
          v: 1015450000
        },
        {
          t: 30600,
          o: 1.21185,
          h: 1.21188,
          l: 1.21106,
          c: 1.2111,
          v: 1085250000
        },
        {
          t: 30900,
          o: 1.2111,
          h: 1.21139,
          l: 1.21099,
          c: 1.21133,
          v: 907130002
        },
        {
          t: 31200,
          o: 1.21133,
          h: 1.21137,
          l: 1.21088,
          c: 1.21106,
          v: 928010000
        },
        {
          t: 31500,
          o: 1.21106,
          h: 1.21113,
          l: 1.21055,
          c: 1.21088,
          v: 893240000
        },
        {
          t: 31800,
          o: 1.21088,
          h: 1.2109,
          l: 1.21044,
          c: 1.21058,
          v: 1161139999
        },
        {
          t: 32100,
          o: 1.21056,
          h: 1.21056,
          l: 1.21019,
          c: 1.2104,
          v: 890500000
        },
        {
          t: 32400,
          o: 1.21041,
          h: 1.21093,
          l: 1.21029,
          c: 1.21037,
          v: 1051140000
        },
        {
          t: 32700,
          o: 1.21038,
          h: 1.21038,
          l: 1.20997,
          c: 1.21013,
          v: 1184460000
        },
        {
          t: 33000,
          o: 1.21016,
          h: 1.21071,
          l: 1.21001,
          c: 1.2106,
          v: 1001770000
        },
        {
          t: 33300,
          o: 1.2106,
          h: 1.21077,
          l: 1.21049,
          c: 1.21059,
          v: 901070000
        },
        {
          t: 33600,
          o: 1.21059,
          h: 1.2107,
          l: 1.21041,
          c: 1.21052,
          v: 737100000
        },
        {
          t: 33900,
          o: 1.21052,
          h: 1.21075,
          l: 1.21009,
          c: 1.21016,
          v: 983920000
        },
        {
          t: 34200,
          o: 1.21017,
          h: 1.21027,
          l: 1.20981,
          c: 1.21012,
          v: 1031290000
        },
        {
          t: 34500,
          o: 1.21013,
          h: 1.21045,
          l: 1.21008,
          c: 1.21033,
          v: 1084220000
        },
        {
          t: 34800,
          o: 1.21034,
          h: 1.21057,
          l: 1.21029,
          c: 1.2105,
          v: 817040000
        },
        {
          t: 35100,
          o: 1.2105,
          h: 1.21075,
          l: 1.21049,
          c: 1.21066,
          v: 968890000
        },
        {
          t: 35400,
          o: 1.21068,
          h: 1.21083,
          l: 1.21048,
          c: 1.21066,
          v: 1139200000
        },
        {
          t: 35700,
          o: 1.21066,
          h: 1.21213,
          l: 1.21042,
          c: 1.21183,
          v: 1460869999
        },
        {
          t: 36000,
          o: 1.21183,
          h: 1.21183,
          l: 1.21128,
          c: 1.2113,
          v: 1142720000
        },
        {
          t: 36300,
          o: 1.2113,
          h: 1.21143,
          l: 1.21096,
          c: 1.21103,
          v: 816620000
        },
        {
          t: 36600,
          o: 1.21102,
          h: 1.21117,
          l: 1.21096,
          c: 1.21107,
          v: 749610000
        },
        {
          t: 36900,
          o: 1.21106,
          h: 1.21125,
          l: 1.21078,
          c: 1.2108,
          v: 878310000
        },
        {
          t: 37200,
          o: 1.2108,
          h: 1.21086,
          l: 1.21043,
          c: 1.21044,
          v: 749170000
        },
        {
          t: 37500,
          o: 1.21044,
          h: 1.21064,
          l: 1.21012,
          c: 1.2102,
          v: 888580000
        },
        {
          t: 37800,
          o: 1.2102,
          h: 1.21032,
          l: 1.20932,
          c: 1.20954,
          v: 1244490000
        },
        {
          t: 38100,
          o: 1.20955,
          h: 1.20988,
          l: 1.20944,
          c: 1.20964,
          v: 1169420000
        },
        {
          t: 38400,
          o: 1.20962,
          h: 1.20988,
          l: 1.20902,
          c: 1.20927,
          v: 1244280000
        },
        {
          t: 38700,
          o: 1.20929,
          h: 1.20946,
          l: 1.20889,
          c: 1.20926,
          v: 1153380000
        },
        {
          t: 39000,
          o: 1.20927,
          h: 1.20949,
          l: 1.20892,
          c: 1.20944,
          v: 1210790000
        },
        {
          t: 39300,
          o: 1.20944,
          h: 1.20985,
          l: 1.20935,
          c: 1.20959,
          v: 1098340000
        },
        {
          t: 39600,
          o: 1.20959,
          h: 1.20962,
          l: 1.20927,
          c: 1.20948,
          v: 1410800002
        },
        {
          t: 39900,
          o: 1.20948,
          h: 1.20954,
          l: 1.20911,
          c: 1.20928,
          v: 704210000
        },
        {
          t: 40200,
          o: 1.2093,
          h: 1.20936,
          l: 1.20873,
          c: 1.20877,
          v: 948790000
        },
        {
          t: 40500,
          o: 1.20877,
          h: 1.20894,
          l: 1.20811,
          c: 1.20814,
          v: 1506090000
        },
        {
          t: 40800,
          o: 1.20814,
          h: 1.2086,
          l: 1.20797,
          c: 1.20808,
          v: 1293070000
        },
        {
          t: 41100,
          o: 1.20808,
          h: 1.20859,
          l: 1.20804,
          c: 1.20854,
          v: 711530000
        },
        {
          t: 41400,
          o: 1.20855,
          h: 1.20865,
          l: 1.2077,
          c: 1.20797,
          v: 946890000
        },
        {
          t: 41700,
          o: 1.20797,
          h: 1.20802,
          l: 1.20775,
          c: 1.20784,
          v: 1111870000
        },
        {
          t: 42000,
          o: 1.20785,
          h: 1.20789,
          l: 1.20745,
          c: 1.20768,
          v: 1122640000
        },
        {
          t: 42300,
          o: 1.20761,
          h: 1.20786,
          l: 1.20731,
          c: 1.20785,
          v: 1125580000
        },
        {
          t: 42600,
          o: 1.20784,
          h: 1.20798,
          l: 1.20742,
          c: 1.20795,
          v: 889060000
        },
        {
          t: 42900,
          o: 1.20794,
          h: 1.20799,
          l: 1.20741,
          c: 1.20772,
          v: 1280290000
        },
        {
          t: 43200,
          o: 1.2077,
          h: 1.20782,
          l: 1.20727,
          c: 1.20768,
          v: 1435710000
        },
        {
          t: 43500,
          o: 1.2077,
          h: 1.20792,
          l: 1.20752,
          c: 1.20786,
          v: 1225270000
        },
        {
          t: 43800,
          o: 1.20785,
          h: 1.20805,
          l: 1.20777,
          c: 1.20784,
          v: 987580000
        },
        {
          t: 44100,
          o: 1.20785,
          h: 1.20794,
          l: 1.20749,
          c: 1.20754,
          v: 1106240000
        },
        {
          t: 44400,
          o: 1.20753,
          h: 1.20778,
          l: 1.20722,
          c: 1.2076,
          v: 1163500001
        },
        {
          t: 44700,
          o: 1.2076,
          h: 1.20787,
          l: 1.20756,
          c: 1.20773,
          v: 1031600000
        },
        {
          t: 45000,
          o: 1.20773,
          h: 1.20865,
          l: 1.20773,
          c: 1.20863,
          v: 1440240001
        },
        {
          t: 45300,
          o: 1.20863,
          h: 1.20869,
          l: 1.20835,
          c: 1.20857,
          v: 1180970000
        },
        {
          t: 45600,
          o: 1.20857,
          h: 1.20896,
          l: 1.20827,
          c: 1.20837,
          v: 1459310000
        },
        {
          t: 45900,
          o: 1.20838,
          h: 1.20857,
          l: 1.20826,
          c: 1.2085,
          v: 938339999
        },
        {
          t: 46200,
          o: 1.2085,
          h: 1.20861,
          l: 1.20799,
          c: 1.20836,
          v: 1101270000
        },
        {
          t: 46500,
          o: 1.20836,
          h: 1.20883,
          l: 1.20836,
          c: 1.20872,
          v: 1126750000
        },
        {
          t: 46800,
          o: 1.20872,
          h: 1.20899,
          l: 1.20852,
          c: 1.20862,
          v: 1017690000
        },
        {
          t: 47100,
          o: 1.20862,
          h: 1.20869,
          l: 1.20827,
          c: 1.20829,
          v: 1257820000
        },
        {
          t: 47400,
          o: 1.2083,
          h: 1.20838,
          l: 1.20816,
          c: 1.2083,
          v: 938760000
        },
        {
          t: 47700,
          o: 1.2083,
          h: 1.20838,
          l: 1.20761,
          c: 1.20776,
          v: 1231679999
        },
        {
          t: 48000,
          o: 1.20773,
          h: 1.20776,
          l: 1.20719,
          c: 1.20748,
          v: 1344370000
        },
        {
          t: 48300,
          o: 1.20749,
          h: 1.20769,
          l: 1.20716,
          c: 1.20726,
          v: 1399980000
        },
        {
          t: 48600,
          o: 1.20726,
          h: 1.20779,
          l: 1.20707,
          c: 1.20772,
          v: 1517679999
        },
        {
          t: 48900,
          o: 1.20771,
          h: 1.20771,
          l: 1.20735,
          c: 1.20747,
          v: 792980000
        },
        {
          t: 49200,
          o: 1.20746,
          h: 1.2076,
          l: 1.20701,
          c: 1.20717,
          v: 847320000
        },
        {
          t: 49500,
          o: 1.20717,
          h: 1.20748,
          l: 1.207,
          c: 1.20706,
          v: 1073060000
        },
        {
          t: 49800,
          o: 1.20708,
          h: 1.20749,
          l: 1.207,
          c: 1.20744,
          v: 1191880000
        },
        {
          t: 50100,
          o: 1.20741,
          h: 1.20744,
          l: 1.20671,
          c: 1.20715,
          v: 1176560000
        },
        {
          t: 50400,
          o: 1.20713,
          h: 1.20765,
          l: 1.20683,
          c: 1.20759,
          v: 1483820000
        },
        {
          t: 50700,
          o: 1.20759,
          h: 1.20855,
          l: 1.20759,
          c: 1.2084,
          v: 1579190000
        },
        {
          t: 51000,
          o: 1.2084,
          h: 1.20881,
          l: 1.2083,
          c: 1.20849,
          v: 1215579999
        },
        {
          t: 51300,
          o: 1.2085,
          h: 1.20856,
          l: 1.20816,
          c: 1.20843,
          v: 1145369999
        },
        {
          t: 51600,
          o: 1.20841,
          h: 1.20871,
          l: 1.20795,
          c: 1.20803,
          v: 1324170000
        },
        {
          t: 51900,
          o: 1.20804,
          h: 1.20806,
          l: 1.2074,
          c: 1.20753,
          v: 1283120000
        },
        {
          t: 52200,
          o: 1.20753,
          h: 1.20786,
          l: 1.20737,
          c: 1.2078,
          v: 1397440000
        },
        {
          t: 52500,
          o: 1.2078,
          h: 1.20781,
          l: 1.20696,
          c: 1.20703,
          v: 1650190000
        },
        {
          t: 52800,
          o: 1.20702,
          h: 1.20861,
          l: 1.20701,
          c: 1.2084,
          v: 1757560000
        },
        {
          t: 53100,
          o: 1.2084,
          h: 1.20863,
          l: 1.20757,
          c: 1.20766,
          v: 1545620000
        },
        {
          t: 53400,
          o: 1.20763,
          h: 1.20783,
          l: 1.20732,
          c: 1.20737,
          v: 1535230000
        },
        {
          t: 53700,
          o: 1.20736,
          h: 1.20853,
          l: 1.20706,
          c: 1.20815,
          v: 2057510001
        },
        {
          t: 54000,
          o: 1.20815,
          h: 1.20871,
          l: 1.2078,
          c: 1.20798,
          v: 1760290000
        },
        {
          t: 54300,
          o: 1.20798,
          h: 1.20862,
          l: 1.20779,
          c: 1.20781,
          v: 1453320000
        },
        {
          t: 54600,
          o: 1.2078,
          h: 1.20786,
          l: 1.20721,
          c: 1.20727,
          v: 1372529999
        },
        {
          t: 54900,
          o: 1.20728,
          h: 1.20766,
          l: 1.20673,
          c: 1.20719,
          v: 1394450000
        },
        {
          t: 55200,
          o: 1.2072,
          h: 1.20768,
          l: 1.20713,
          c: 1.20756,
          v: 1065900000
        },
        {
          t: 55500,
          o: 1.20756,
          h: 1.20762,
          l: 1.20731,
          c: 1.20744,
          v: 874430001
        },
        {
          t: 55800,
          o: 1.20743,
          h: 1.20762,
          l: 1.20701,
          c: 1.20707,
          v: 991740000
        },
        {
          t: 56100,
          o: 1.20708,
          h: 1.20709,
          l: 1.2067,
          c: 1.20679,
          v: 1235620000
        },
        {
          t: 56400,
          o: 1.20678,
          h: 1.20693,
          l: 1.20643,
          c: 1.20667,
          v: 1410769999
        },
        {
          t: 56700,
          o: 1.20667,
          h: 1.20725,
          l: 1.20654,
          c: 1.20706,
          v: 1377450000
        },
        {
          t: 57000,
          o: 1.20706,
          h: 1.20759,
          l: 1.20701,
          c: 1.20746,
          v: 1065929999
        },
        {
          t: 57300,
          o: 1.20746,
          h: 1.20767,
          l: 1.20737,
          c: 1.20737,
          v: 1011690000
        },
        {
          t: 57600,
          o: 1.20735,
          h: 1.2077,
          l: 1.20715,
          c: 1.20718,
          v: 1010890000
        },
        {
          t: 57900,
          o: 1.20717,
          h: 1.20835,
          l: 1.20713,
          c: 1.20827,
          v: 1126040000
        },
        {
          t: 58200,
          o: 1.20825,
          h: 1.20846,
          l: 1.20741,
          c: 1.20756,
          v: 1473040000
        },
        {
          t: 58500,
          o: 1.20759,
          h: 1.2085,
          l: 1.20758,
          c: 1.20836,
          v: 1314130000
        },
        {
          t: 58800,
          o: 1.20834,
          h: 1.20926,
          l: 1.2083,
          c: 1.20906,
          v: 1383620000
        },
        {
          t: 59100,
          o: 1.20906,
          h: 1.20926,
          l: 1.20885,
          c: 1.20924,
          v: 1062480000
        },
        {
          t: 59400,
          o: 1.20927,
          h: 1.20949,
          l: 1.20895,
          c: 1.20948,
          v: 1249330000
        },
        {
          t: 59700,
          o: 1.20949,
          h: 1.20981,
          l: 1.20929,
          c: 1.20964,
          v: 1394750000
        },
        {
          t: 60000,
          o: 1.20965,
          h: 1.20996,
          l: 1.20951,
          c: 1.20976,
          v: 1391990000
        },
        {
          t: 60300,
          o: 1.20976,
          h: 1.2101,
          l: 1.20958,
          c: 1.20966,
          v: 1037940000
        },
        {
          t: 60600,
          o: 1.20965,
          h: 1.21026,
          l: 1.20963,
          c: 1.21007,
          v: 1163010000
        },
        {
          t: 60900,
          o: 1.21007,
          h: 1.21062,
          l: 1.20997,
          c: 1.21007,
          v: 1495380000
        },
        {
          t: 61200,
          o: 1.21001,
          h: 1.21017,
          l: 1.2092,
          c: 1.20939,
          v: 1432500000
        },
        {
          t: 61500,
          o: 1.20937,
          h: 1.20948,
          l: 1.20884,
          c: 1.20916,
          v: 1147460000
        },
        {
          t: 61800,
          o: 1.20915,
          h: 1.20959,
          l: 1.20895,
          c: 1.20948,
          v: 1167730000
        },
        {
          t: 62100,
          o: 1.2095,
          h: 1.20995,
          l: 1.20949,
          c: 1.20971,
          v: 1252190000
        },
        {
          t: 62400,
          o: 1.20973,
          h: 1.2098,
          l: 1.20912,
          c: 1.20914,
          v: 821200000
        },
        {
          t: 62700,
          o: 1.20915,
          h: 1.20928,
          l: 1.20868,
          c: 1.20876,
          v: 866580000
        },
        {
          t: 63000,
          o: 1.20876,
          h: 1.2088,
          l: 1.20835,
          c: 1.20837,
          v: 1088300000
        },
        {
          t: 63300,
          o: 1.20837,
          h: 1.20859,
          l: 1.20824,
          c: 1.20825,
          v: 842810000
        },
        {
          t: 63600,
          o: 1.20825,
          h: 1.20856,
          l: 1.20825,
          c: 1.20848,
          v: 875880000
        },
        {
          t: 63900,
          o: 1.20847,
          h: 1.20856,
          l: 1.20829,
          c: 1.20852,
          v: 1038380000
        },
        {
          t: 64200,
          o: 1.20853,
          h: 1.20897,
          l: 1.20835,
          c: 1.20878,
          v: 834820000
        },
        {
          t: 64500,
          o: 1.20878,
          h: 1.20891,
          l: 1.20855,
          c: 1.20858,
          v: 714340000
        },
        {
          t: 64800,
          o: 1.20859,
          h: 1.20885,
          l: 1.20859,
          c: 1.20878,
          v: 873710000
        },
        {
          t: 65100,
          o: 1.20878,
          h: 1.20886,
          l: 1.2085,
          c: 1.20857,
          v: 749740000
        },
        {
          t: 65400,
          o: 1.20859,
          h: 1.20912,
          l: 1.20853,
          c: 1.20891,
          v: 998740000
        },
        {
          t: 65700,
          o: 1.20891,
          h: 1.20913,
          l: 1.20891,
          c: 1.20896,
          v: 763270000
        },
        {
          t: 66000,
          o: 1.20896,
          h: 1.20917,
          l: 1.20892,
          c: 1.2091,
          v: 501890000
        },
        {
          t: 66300,
          o: 1.20911,
          h: 1.20949,
          l: 1.20906,
          c: 1.20919,
          v: 834550000
        },
        {
          t: 66600,
          o: 1.20919,
          h: 1.20927,
          l: 1.20889,
          c: 1.20919,
          v: 1143360000
        },
        {
          t: 66900,
          o: 1.2092,
          h: 1.20939,
          l: 1.2092,
          c: 1.20925,
          v: 709960000
        },
        {
          t: 67200,
          o: 1.20925,
          h: 1.20952,
          l: 1.20923,
          c: 1.20932,
          v: 809680000
        },
        {
          t: 67500,
          o: 1.20931,
          h: 1.20952,
          l: 1.209,
          c: 1.209,
          v: 650270000
        },
        {
          t: 67800,
          o: 1.209,
          h: 1.209,
          l: 1.20879,
          c: 1.20881,
          v: 888990000
        },
        {
          t: 68100,
          o: 1.20879,
          h: 1.20897,
          l: 1.20797,
          c: 1.20805,
          v: 1325080000
        },
        {
          t: 68400,
          o: 1.208,
          h: 1.20831,
          l: 1.20796,
          c: 1.20828,
          v: 709260000
        },
        {
          t: 68700,
          o: 1.20828,
          h: 1.20839,
          l: 1.20819,
          c: 1.20836,
          v: 684880000
        },
        {
          t: 69000,
          o: 1.20837,
          h: 1.20837,
          l: 1.20798,
          c: 1.20801,
          v: 484410000
        },
        {
          t: 69300,
          o: 1.20801,
          h: 1.20822,
          l: 1.20786,
          c: 1.20788,
          v: 778300000
        },
        {
          t: 69600,
          o: 1.20789,
          h: 1.20797,
          l: 1.20764,
          c: 1.20769,
          v: 648710000
        },
        {
          t: 69900,
          o: 1.2077,
          h: 1.20776,
          l: 1.2075,
          c: 1.20756,
          v: 860020000
        },
        {
          t: 70200,
          o: 1.20758,
          h: 1.20776,
          l: 1.20751,
          c: 1.20758,
          v: 685530000
        },
        {
          t: 70500,
          o: 1.20758,
          h: 1.20767,
          l: 1.20725,
          c: 1.20725,
          v: 876240000
        },
        {
          t: 70800,
          o: 1.20725,
          h: 1.20761,
          l: 1.20725,
          c: 1.2076,
          v: 575350000
        },
        {
          t: 71100,
          o: 1.2076,
          h: 1.20771,
          l: 1.2075,
          c: 1.2075,
          v: 687570000
        },
        {
          t: 71400,
          o: 1.20749,
          h: 1.20771,
          l: 1.20748,
          c: 1.2076,
          v: 906410000
        },
        {
          t: 71700,
          o: 1.2076,
          h: 1.20767,
          l: 1.20742,
          c: 1.20762,
          v: 851680000
        },
        {
          t: 72000,
          o: 1.20766,
          h: 1.20776,
          l: 1.20753,
          c: 1.20755,
          v: 399320000
        },
        {
          t: 72300,
          o: 1.20755,
          h: 1.20783,
          l: 1.20754,
          c: 1.20783,
          v: 207690000
        },
        {
          t: 72600,
          o: 1.20783,
          h: 1.20783,
          l: 1.20762,
          c: 1.20782,
          v: 312970000
        },
        {
          t: 72900,
          o: 1.20781,
          h: 1.20813,
          l: 1.2078,
          c: 1.20797,
          v: 146400000
        },
        {
          t: 73200,
          o: 1.20797,
          h: 1.20807,
          l: 1.20796,
          c: 1.20806,
          v: 90870000
        },
        {
          t: 73500,
          o: 1.2081,
          h: 1.20811,
          l: 1.20798,
          c: 1.20811,
          v: 233740000
        },
        {
          t: 73800,
          o: 1.2081,
          h: 1.20812,
          l: 1.20788,
          c: 1.20799,
          v: 327130000
        },
        {
          t: 74100,
          o: 1.20798,
          h: 1.2082,
          l: 1.2079,
          c: 1.20802,
          v: 392120000
        },
        {
          t: 74400,
          o: 1.20804,
          h: 1.20805,
          l: 1.20789,
          c: 1.20796,
          v: 421440000
        },
        {
          t: 74700,
          o: 1.20796,
          h: 1.20806,
          l: 1.20796,
          c: 1.20802,
          v: 233800000
        },
        {
          t: 75000,
          o: 1.20803,
          h: 1.20807,
          l: 1.20781,
          c: 1.20798,
          v: 397210000
        },
        {
          t: 75300,
          o: 1.20797,
          h: 1.20805,
          l: 1.20775,
          c: 1.20777,
          v: 362360000
        },
        {
          t: 75600,
          o: 1.20768,
          h: 1.20771,
          l: 1.20673,
          c: 1.2076,
          v: 88490000
        },
        {
          t: 75900,
          o: 1.2076,
          h: 1.20799,
          l: 1.2076,
          c: 1.20766,
          v: 347190000
        },
        {
          t: 76200,
          o: 1.20766,
          h: 1.20779,
          l: 1.20751,
          c: 1.20775,
          v: 405340000
        },
        {
          t: 76500,
          o: 1.20775,
          h: 1.2078,
          l: 1.20754,
          c: 1.2076,
          v: 364970000
        },
        {
          t: 76800,
          o: 1.20759,
          h: 1.20778,
          l: 1.20758,
          c: 1.20764,
          v: 713080000
        },
        {
          t: 77100,
          o: 1.20765,
          h: 1.20797,
          l: 1.20758,
          c: 1.20781,
          v: 563880000
        },
        {
          t: 77400,
          o: 1.20785,
          h: 1.20791,
          l: 1.2076,
          c: 1.20765,
          v: 662090000
        },
        {
          t: 77700,
          o: 1.20765,
          h: 1.20783,
          l: 1.20759,
          c: 1.20759,
          v: 443010000
        },
        {
          t: 78000,
          o: 1.2076,
          h: 1.20765,
          l: 1.20759,
          c: 1.20762,
          v: 145930000
        },
        {
          t: 78300,
          o: 1.20761,
          h: 1.20767,
          l: 1.20755,
          c: 1.20762,
          v: 195880000
        },
        {
          t: 78600,
          o: 1.20765,
          h: 1.20779,
          l: 1.20753,
          c: 1.20769,
          v: 340590000
        },
        {
          t: 78900,
          o: 1.20769,
          h: 1.20816,
          l: 1.20747,
          c: 1.20779,
          v: 646680000
        },
        {
          t: 79200,
          o: 1.2078,
          h: 1.20796,
          l: 1.20746,
          c: 1.20794,
          v: 241230000
        },
        {
          t: 79500,
          o: 1.20795,
          h: 1.20801,
          l: 1.20788,
          c: 1.20799,
          v: 213080000
        },
        {
          t: 79800,
          o: 1.20802,
          h: 1.20803,
          l: 1.20793,
          c: 1.20795,
          v: 190910000
        },
        {
          t: 80100,
          o: 1.20794,
          h: 1.20795,
          l: 1.20773,
          c: 1.20783,
          v: 410780000
        },
        {
          t: 80400,
          o: 1.20782,
          h: 1.20786,
          l: 1.20773,
          c: 1.20779,
          v: 227610000
        },
        {
          t: 80700,
          o: 1.20779,
          h: 1.2078,
          l: 1.20772,
          c: 1.20777,
          v: 176260000
        },
        {
          t: 81000,
          o: 1.20777,
          h: 1.20789,
          l: 1.20777,
          c: 1.20789,
          v: 149380000
        },
        {
          t: 81300,
          o: 1.20789,
          h: 1.20789,
          l: 1.20763,
          c: 1.20784,
          v: 248320000
        },
        {
          t: 81600,
          o: 1.20783,
          h: 1.20797,
          l: 1.20779,
          c: 1.20789,
          v: 330440000
        },
        {
          t: 81900,
          o: 1.20789,
          h: 1.208,
          l: 1.20789,
          c: 1.2079,
          v: 259130000
        },
        {
          t: 82200,
          o: 1.20789,
          h: 1.20794,
          l: 1.20787,
          c: 1.20791,
          v: 147590000
        },
        {
          t: 82500,
          o: 1.2079,
          h: 1.20806,
          l: 1.2079,
          c: 1.20804,
          v: 172110000
        },
        {
          t: 82800,
          o: 1.20804,
          h: 1.20812,
          l: 1.20799,
          c: 1.20805,
          v: 122300000
        },
        {
          t: 83100,
          o: 1.20806,
          h: 1.20807,
          l: 1.20794,
          c: 1.20794,
          v: 119140000
        },
        {
          t: 83400,
          o: 1.20794,
          h: 1.20796,
          l: 1.20789,
          c: 1.20791,
          v: 346840000
        },
        {
          t: 83700,
          o: 1.20791,
          h: 1.20792,
          l: 1.2077,
          c: 1.20784,
          v: 270370000
        },
        {
          t: 84000,
          o: 1.20784,
          h: 1.20794,
          l: 1.20784,
          c: 1.2079,
          v: 145640000
        },
        {
          t: 84300,
          o: 1.2079,
          h: 1.20816,
          l: 1.20789,
          c: 1.20805,
          v: 383340000
        },
        {
          t: 84600,
          o: 1.20807,
          h: 1.2082,
          l: 1.20796,
          c: 1.2082,
          v: 222000000
        },
        {
          t: 84900,
          o: 1.20827,
          h: 1.20837,
          l: 1.20815,
          c: 1.20826,
          v: 421090000
        },
        {
          t: 85200,
          o: 1.20826,
          h: 1.20832,
          l: 1.20805,
          c: 1.2083,
          v: 298620000
        },
        {
          t: 85500,
          o: 1.20831,
          h: 1.20841,
          l: 1.20814,
          c: 1.20833,
          v: 366230000
        },
        {
          t: 85800,
          o: 1.20835,
          h: 1.20836,
          l: 1.20805,
          c: 1.20808,
          v: 277130000
        },
        {
          t: 86100,
          o: 1.20809,
          h: 1.20815,
          l: 1.20801,
          c: 1.20815,
          v: 519030000
        }
      ]
    }
  ],
  GBPUSD60: [
    {
      d: 1522540800,
      b: [
        {
          t: 75600,
          o: 1.40224,
          h: 1.40328,
          l: 1.40193,
          c: 1.40273,
          v: 704780000
        },
        {
          t: 79200,
          o: 1.4027,
          h: 1.40333,
          l: 1.40203,
          c: 1.40329,
          v: 3268400000
        },
        {
          t: 82800,
          o: 1.40327,
          h: 1.40379,
          l: 1.40294,
          c: 1.40355,
          v: 2396410000
        }
      ]
    },
    {
      d: 1522627200,
      b: [
        {
          t: 0,
          o: 1.40355,
          h: 1.40466,
          l: 1.40301,
          c: 1.40463,
          v: 3417610000
        },
        {
          t: 3600,
          o: 1.40465,
          h: 1.40561,
          l: 1.40463,
          c: 1.405,
          v: 3239570000
        },
        {
          t: 7200,
          o: 1.405,
          h: 1.40543,
          l: 1.40434,
          c: 1.4047,
          v: 2792100000
        },
        {
          t: 10800,
          o: 1.40469,
          h: 1.40474,
          l: 1.40392,
          c: 1.40426,
          v: 2849370000
        },
        {
          t: 14400,
          o: 1.40426,
          h: 1.40445,
          l: 1.40392,
          c: 1.40409,
          v: 3073320000
        },
        {
          t: 18000,
          o: 1.4041,
          h: 1.40475,
          l: 1.40358,
          c: 1.40473,
          v: 2630210000
        },
        {
          t: 21600,
          o: 1.40473,
          h: 1.40523,
          l: 1.40399,
          c: 1.40495,
          v: 3079190000
        },
        {
          t: 25200,
          o: 1.40493,
          h: 1.40665,
          l: 1.4048,
          c: 1.40665,
          v: 3497810002
        },
        {
          t: 28800,
          o: 1.40663,
          h: 1.40698,
          l: 1.40591,
          c: 1.40658,
          v: 3001570000
        },
        {
          t: 32400,
          o: 1.4066,
          h: 1.40676,
          l: 1.40613,
          c: 1.40644,
          v: 1715550000
        },
        {
          t: 36000,
          o: 1.40647,
          h: 1.40655,
          l: 1.40575,
          c: 1.40587,
          v: 2204490000
        },
        {
          t: 39600,
          o: 1.40586,
          h: 1.4073,
          l: 1.40581,
          c: 1.40718,
          v: 4090680000
        },
        {
          t: 43200,
          o: 1.40722,
          h: 1.40775,
          l: 1.40607,
          c: 1.40615,
          v: 5568180000
        },
        {
          t: 46800,
          o: 1.40615,
          h: 1.40637,
          l: 1.40381,
          c: 1.40448,
          v: 6306180000
        },
        {
          t: 50400,
          o: 1.40449,
          h: 1.40596,
          l: 1.40397,
          c: 1.40551,
          v: 6957110000
        },
        {
          t: 54000,
          o: 1.40553,
          h: 1.40553,
          l: 1.40259,
          c: 1.4031,
          v: 8650350000
        },
        {
          t: 57600,
          o: 1.40311,
          h: 1.40357,
          l: 1.40267,
          c: 1.4034,
          v: 5270850000
        },
        {
          t: 61200,
          o: 1.40339,
          h: 1.40506,
          l: 1.40281,
          c: 1.40489,
          v: 8776630000
        },
        {
          t: 64800,
          o: 1.40487,
          h: 1.40493,
          l: 1.40402,
          c: 1.40423,
          v: 8038080000
        },
        {
          t: 68400,
          o: 1.40429,
          h: 1.40511,
          l: 1.40402,
          c: 1.40492,
          v: 4755820000
        },
        {
          t: 72000,
          o: 1.40492,
          h: 1.40492,
          l: 1.40417,
          c: 1.4043,
          v: 3138700000
        },
        {
          t: 75600,
          o: 1.40425,
          h: 1.40501,
          l: 1.40415,
          c: 1.40493,
          v: 1314620000
        },
        {
          t: 79200,
          o: 1.40493,
          h: 1.40513,
          l: 1.40461,
          c: 1.40485,
          v: 1474580000
        },
        {
          t: 82800,
          o: 1.40484,
          h: 1.4053,
          l: 1.40454,
          c: 1.40464,
          v: 1726870000
        }
      ]
    },
    {
      d: 1522713600,
      b: [
        {
          t: 0,
          o: 1.40464,
          h: 1.40544,
          l: 1.4045,
          c: 1.40519,
          v: 2427990000
        },
        {
          t: 3600,
          o: 1.4052,
          h: 1.40618,
          l: 1.40458,
          c: 1.40527,
          v: 4123090000
        },
        {
          t: 7200,
          o: 1.40527,
          h: 1.40595,
          l: 1.40518,
          c: 1.40567,
          v: 2874280000
        },
        {
          t: 10800,
          o: 1.40567,
          h: 1.40607,
          l: 1.40554,
          c: 1.40555,
          v: 2559950000
        },
        {
          t: 14400,
          o: 1.40555,
          h: 1.40615,
          l: 1.40534,
          c: 1.40605,
          v: 1962570000
        },
        {
          t: 18000,
          o: 1.40605,
          h: 1.40616,
          l: 1.40529,
          c: 1.40583,
          v: 2748980000
        },
        {
          t: 21600,
          o: 1.40585,
          h: 1.40749,
          l: 1.4058,
          c: 1.40722,
          v: 6086180000
        },
        {
          t: 25200,
          o: 1.40723,
          h: 1.40883,
          l: 1.4064,
          c: 1.40817,
          v: 7969470000
        },
        {
          t: 28800,
          o: 1.40818,
          h: 1.40868,
          l: 1.40745,
          c: 1.40803,
          v: 8078630000
        },
        {
          t: 32400,
          o: 1.40803,
          h: 1.40853,
          l: 1.40681,
          c: 1.40681,
          v: 9056220000
        },
        {
          t: 36000,
          o: 1.40682,
          h: 1.40746,
          l: 1.40267,
          c: 1.40276,
          v: 8835900000
        },
        {
          t: 39600,
          o: 1.40278,
          h: 1.40572,
          l: 1.40208,
          c: 1.40486,
          v: 9157270000
        },
        {
          t: 43200,
          o: 1.40484,
          h: 1.40721,
          l: 1.40464,
          c: 1.40631,
          v: 7957310000
        },
        {
          t: 46800,
          o: 1.40631,
          h: 1.4066,
          l: 1.40319,
          c: 1.4037,
          v: 11377210000
        },
        {
          t: 50400,
          o: 1.4037,
          h: 1.40487,
          l: 1.40323,
          c: 1.40377,
          v: 11558390000
        },
        {
          t: 54000,
          o: 1.40382,
          h: 1.4077,
          l: 1.40382,
          c: 1.40665,
          v: 9704520000
        },
        {
          t: 57600,
          o: 1.40666,
          h: 1.40714,
          l: 1.40544,
          c: 1.40558,
          v: 6245560000
        },
        {
          t: 61200,
          o: 1.40558,
          h: 1.40658,
          l: 1.40524,
          c: 1.40526,
          v: 5185760000
        },
        {
          t: 64800,
          o: 1.40526,
          h: 1.40575,
          l: 1.40489,
          c: 1.40561,
          v: 4537190000
        },
        {
          t: 68400,
          o: 1.4056,
          h: 1.40611,
          l: 1.40515,
          c: 1.40523,
          v: 3565430000
        },
        {
          t: 72000,
          o: 1.40523,
          h: 1.40595,
          l: 1.40522,
          c: 1.40557,
          v: 2741450000
        },
        {
          t: 75600,
          o: 1.40543,
          h: 1.40613,
          l: 1.40505,
          c: 1.40595,
          v: 912920000
        },
        {
          t: 79200,
          o: 1.40595,
          h: 1.40643,
          l: 1.40578,
          c: 1.40641,
          v: 1339530000
        },
        {
          t: 82800,
          o: 1.40641,
          h: 1.4069,
          l: 1.40598,
          c: 1.40688,
          v: 1525910000
        }
      ]
    },
    {
      d: 1522800000,
      b: [
        {
          t: 0,
          o: 1.40688,
          h: 1.40833,
          l: 1.40623,
          c: 1.40813,
          v: 2918960000
        },
        {
          t: 3600,
          o: 1.40816,
          h: 1.40845,
          l: 1.40748,
          c: 1.40789,
          v: 3073360000
        },
        {
          t: 7200,
          o: 1.4079,
          h: 1.40962,
          l: 1.40789,
          c: 1.40877,
          v: 4110370000
        },
        {
          t: 10800,
          o: 1.40877,
          h: 1.40926,
          l: 1.40826,
          c: 1.40905,
          v: 3509450000
        },
        {
          t: 14400,
          o: 1.40905,
          h: 1.40918,
          l: 1.40839,
          c: 1.40843,
          v: 2136130000
        },
        {
          t: 18000,
          o: 1.40843,
          h: 1.40864,
          l: 1.40749,
          c: 1.40784,
          v: 3850910000
        },
        {
          t: 21600,
          o: 1.40785,
          h: 1.4079,
          l: 1.40646,
          c: 1.40671,
          v: 6823660000
        },
        {
          t: 25200,
          o: 1.40671,
          h: 1.40834,
          l: 1.40541,
          c: 1.40832,
          v: 8995610000
        },
        {
          t: 28800,
          o: 1.40833,
          h: 1.40855,
          l: 1.40679,
          c: 1.40773,
          v: 11324080000
        },
        {
          t: 32400,
          o: 1.4077,
          h: 1.40798,
          l: 1.40495,
          c: 1.405,
          v: 9038430000
        },
        {
          t: 36000,
          o: 1.40499,
          h: 1.405,
          l: 1.40283,
          c: 1.40369,
          v: 9489390000
        },
        {
          t: 39600,
          o: 1.40369,
          h: 1.40476,
          l: 1.4033,
          c: 1.40395,
          v: 9562890000
        },
        {
          t: 43200,
          o: 1.40396,
          h: 1.40703,
          l: 1.40146,
          c: 1.40659,
          v: 10076100000
        },
        {
          t: 46800,
          o: 1.40661,
          h: 1.4081,
          l: 1.40627,
          c: 1.40794,
          v: 8420710000
        },
        {
          t: 50400,
          o: 1.40794,
          h: 1.40889,
          l: 1.40747,
          c: 1.40841,
          v: 9676650000
        },
        {
          t: 54000,
          o: 1.40842,
          h: 1.40852,
          l: 1.40725,
          c: 1.40739,
          v: 8598550000
        },
        {
          t: 57600,
          o: 1.40741,
          h: 1.40825,
          l: 1.40724,
          c: 1.40813,
          v: 6564190000
        },
        {
          t: 61200,
          o: 1.40815,
          h: 1.40818,
          l: 1.40729,
          c: 1.4075,
          v: 5653760000
        },
        {
          t: 64800,
          o: 1.4075,
          h: 1.40793,
          l: 1.40678,
          c: 1.40743,
          v: 5497050000
        },
        {
          t: 68400,
          o: 1.40741,
          h: 1.40832,
          l: 1.40734,
          c: 1.40821,
          v: 5182580000
        },
        {
          t: 72000,
          o: 1.40819,
          h: 1.40823,
          l: 1.40775,
          c: 1.40782,
          v: 3965390000
        },
        {
          t: 75600,
          o: 1.40782,
          h: 1.40872,
          l: 1.4072,
          c: 1.40848,
          v: 1211810000
        },
        {
          t: 79200,
          o: 1.40852,
          h: 1.40882,
          l: 1.40815,
          c: 1.40861,
          v: 1638770000
        },
        {
          t: 82800,
          o: 1.40861,
          h: 1.40887,
          l: 1.40839,
          c: 1.40874,
          v: 1081650000
        }
      ]
    },
    {
      d: 1522886400,
      b: [
        {
          t: 0,
          o: 1.40874,
          h: 1.40968,
          l: 1.40805,
          c: 1.40936,
          v: 3137750001
        },
        {
          t: 3600,
          o: 1.40935,
          h: 1.40956,
          l: 1.40797,
          c: 1.40838,
          v: 3105140000
        },
        {
          t: 7200,
          o: 1.40837,
          h: 1.40837,
          l: 1.40751,
          c: 1.40765,
          v: 3129570000
        },
        {
          t: 10800,
          o: 1.40765,
          h: 1.40847,
          l: 1.40753,
          c: 1.40806,
          v: 2763200000
        },
        {
          t: 14400,
          o: 1.40804,
          h: 1.40805,
          l: 1.40687,
          c: 1.407,
          v: 1911050000
        },
        {
          t: 18000,
          o: 1.40697,
          h: 1.40736,
          l: 1.40612,
          c: 1.40643,
          v: 3259120000
        },
        {
          t: 21600,
          o: 1.40641,
          h: 1.40686,
          l: 1.40521,
          c: 1.40532,
          v: 5639670000
        },
        {
          t: 25200,
          o: 1.40535,
          h: 1.40665,
          l: 1.40462,
          c: 1.4054,
          v: 9138590000
        },
        {
          t: 28800,
          o: 1.4054,
          h: 1.40612,
          l: 1.40323,
          c: 1.40395,
          v: 8881470000
        },
        {
          t: 32400,
          o: 1.40396,
          h: 1.40689,
          l: 1.40396,
          c: 1.40619,
          v: 6853170000
        },
        {
          t: 36000,
          o: 1.40622,
          h: 1.40655,
          l: 1.40499,
          c: 1.40641,
          v: 7217440000
        },
        {
          t: 39600,
          o: 1.4064,
          h: 1.40691,
          l: 1.40235,
          c: 1.40285,
          v: 7664040000
        },
        {
          t: 43200,
          o: 1.40285,
          h: 1.40296,
          l: 1.4,
          c: 1.40166,
          v: 10233250000
        },
        {
          t: 46800,
          o: 1.40166,
          h: 1.40315,
          l: 1.40101,
          c: 1.40226,
          v: 9413740000
        },
        {
          t: 50400,
          o: 1.40225,
          h: 1.40244,
          l: 1.39869,
          c: 1.39984,
          v: 10493270000
        },
        {
          t: 54000,
          o: 1.39985,
          h: 1.40007,
          l: 1.39652,
          c: 1.39903,
          v: 9406000000
        },
        {
          t: 57600,
          o: 1.39904,
          h: 1.40034,
          l: 1.39871,
          c: 1.39872,
          v: 27101599977
        },
        {
          t: 61200,
          o: 1.39872,
          h: 1.39976,
          l: 1.39821,
          c: 1.39968,
          v: 5539720000
        },
        {
          t: 64800,
          o: 1.39967,
          h: 1.40023,
          l: 1.39912,
          c: 1.40012,
          v: 3700160000
        },
        {
          t: 68400,
          o: 1.40012,
          h: 1.40023,
          l: 1.3997,
          c: 1.40021,
          v: 3191010000
        },
        {
          t: 72000,
          o: 1.4002,
          h: 1.40059,
          l: 1.39989,
          c: 1.40015,
          v: 2542410000
        },
        {
          t: 75600,
          o: 1.40015,
          h: 1.4005,
          l: 1.39987,
          c: 1.40011,
          v: 965040000
        },
        {
          t: 79200,
          o: 1.40015,
          h: 1.40057,
          l: 1.40005,
          c: 1.40028,
          v: 2246550000
        },
        {
          t: 82800,
          o: 1.40031,
          h: 1.40087,
          l: 1.39992,
          c: 1.4006,
          v: 3176610000
        }
      ]
    },
    {
      d: 1522972800,
      b: [
        {
          t: 0,
          o: 1.4006,
          h: 1.40108,
          l: 1.40048,
          c: 1.4007,
          v: 3532370000
        },
        {
          t: 3600,
          o: 1.4007,
          h: 1.40211,
          l: 1.40066,
          c: 1.40178,
          v: 3065060000
        },
        {
          t: 7200,
          o: 1.40178,
          h: 1.40193,
          l: 1.40129,
          c: 1.40131,
          v: 2342960000
        },
        {
          t: 10800,
          o: 1.40135,
          h: 1.4015,
          l: 1.39998,
          c: 1.40013,
          v: 4711600000
        },
        {
          t: 14400,
          o: 1.40013,
          h: 1.40018,
          l: 1.39923,
          c: 1.39972,
          v: 5495409997
        },
        {
          t: 18000,
          o: 1.3997,
          h: 1.39971,
          l: 1.39875,
          c: 1.3992,
          v: 5177440000
        },
        {
          t: 21600,
          o: 1.3992,
          h: 1.40018,
          l: 1.39857,
          c: 1.39951,
          v: 5778130000
        },
        {
          t: 25200,
          o: 1.3995,
          h: 1.39991,
          l: 1.39827,
          c: 1.39984,
          v: 7227120000
        },
        {
          t: 28800,
          o: 1.39982,
          h: 1.40094,
          l: 1.3993,
          c: 1.40049,
          v: 6217160000
        },
        {
          t: 32400,
          o: 1.40049,
          h: 1.40146,
          l: 1.40037,
          c: 1.40122,
          v: 6425030000
        },
        {
          t: 36000,
          o: 1.40122,
          h: 1.40248,
          l: 1.40106,
          c: 1.40141,
          v: 5092130000
        },
        {
          t: 39600,
          o: 1.40142,
          h: 1.40153,
          l: 1.40007,
          c: 1.40033,
          v: 6750490000
        },
        {
          t: 43200,
          o: 1.40034,
          h: 1.40376,
          l: 1.40027,
          c: 1.40345,
          v: 12458190000
        },
        {
          t: 46800,
          o: 1.40345,
          h: 1.40788,
          l: 1.4028,
          c: 1.40733,
          v: 13169200000
        },
        {
          t: 50400,
          o: 1.40734,
          h: 1.41055,
          l: 1.4068,
          c: 1.40822,
          v: 11659459999
        },
        {
          t: 54000,
          o: 1.4082,
          h: 1.40956,
          l: 1.4082,
          c: 1.40876,
          v: 9054820000
        },
        {
          t: 57600,
          o: 1.40875,
          h: 1.40937,
          l: 1.40829,
          c: 1.40844,
          v: 7018200000
        },
        {
          t: 61200,
          o: 1.40847,
          h: 1.40877,
          l: 1.40757,
          c: 1.40867,
          v: 6240810000
        },
        {
          t: 64800,
          o: 1.40869,
          h: 1.41016,
          l: 1.40799,
          c: 1.4085,
          v: 6497680000
        },
        {
          t: 68400,
          o: 1.4085,
          h: 1.40948,
          l: 1.4084,
          c: 1.40894,
          v: 6302190000
        },
        {
          t: 72000,
          o: 1.40894,
          h: 1.40918,
          l: 1.40814,
          c: 1.40814,
          v: 3826870000
        }
      ]
    },
    {
      d: 1523145600,
      b: [
        {
          t: 75600,
          o: 1.40866,
          h: 1.40888,
          l: 1.4084,
          c: 1.40877,
          v: 582540000
        },
        {
          t: 79200,
          o: 1.40877,
          h: 1.40909,
          l: 1.40818,
          c: 1.40899,
          v: 1495580000
        },
        {
          t: 82800,
          o: 1.40902,
          h: 1.40916,
          l: 1.40852,
          c: 1.409,
          v: 1965100000
        }
      ]
    },
    {
      d: 1523232000,
      b: [
        {
          t: 0,
          o: 1.409,
          h: 1.40966,
          l: 1.40858,
          c: 1.40965,
          v: 2418430000
        },
        {
          t: 3600,
          o: 1.40963,
          h: 1.41012,
          l: 1.40915,
          c: 1.40931,
          v: 2814750000
        },
        {
          t: 7200,
          o: 1.40931,
          h: 1.40967,
          l: 1.40878,
          c: 1.40962,
          v: 1963630000
        },
        {
          t: 10800,
          o: 1.40964,
          h: 1.40976,
          l: 1.40904,
          c: 1.40954,
          v: 2871600000
        },
        {
          t: 14400,
          o: 1.40954,
          h: 1.4096,
          l: 1.40929,
          c: 1.40949,
          v: 1375680000
        },
        {
          t: 18000,
          o: 1.40948,
          h: 1.40986,
          l: 1.40908,
          c: 1.40956,
          v: 2030350000
        },
        {
          t: 21600,
          o: 1.40957,
          h: 1.41178,
          l: 1.40894,
          c: 1.40997,
          v: 7413270000
        },
        {
          t: 25200,
          o: 1.40993,
          h: 1.41167,
          l: 1.40782,
          c: 1.41126,
          v: 10039800000
        },
        {
          t: 28800,
          o: 1.41126,
          h: 1.41137,
          l: 1.40985,
          c: 1.41106,
          v: 7233180003
        },
        {
          t: 32400,
          o: 1.41106,
          h: 1.41152,
          l: 1.40928,
          c: 1.40928,
          v: 5126329998
        },
        {
          t: 36000,
          o: 1.40931,
          h: 1.41061,
          l: 1.40839,
          c: 1.41031,
          v: 12852640068
        },
        {
          t: 39600,
          o: 1.41032,
          h: 1.41194,
          l: 1.40958,
          c: 1.41181,
          v: 6388770000
        },
        {
          t: 43200,
          o: 1.41183,
          h: 1.41447,
          l: 1.41155,
          c: 1.41438,
          v: 8031130000
        },
        {
          t: 46800,
          o: 1.41439,
          h: 1.41644,
          l: 1.41419,
          c: 1.41452,
          v: 8562170000
        },
        {
          t: 50400,
          o: 1.41454,
          h: 1.41523,
          l: 1.41252,
          c: 1.41272,
          v: 8732530000
        },
        {
          t: 54000,
          o: 1.4127,
          h: 1.41389,
          l: 1.41229,
          c: 1.41346,
          v: 7383890000
        },
        {
          t: 57600,
          o: 1.41345,
          h: 1.41438,
          l: 1.4124,
          c: 1.41344,
          v: 5791920000
        },
        {
          t: 61200,
          o: 1.41344,
          h: 1.41432,
          l: 1.41268,
          c: 1.41309,
          v: 4474350000
        },
        {
          t: 64800,
          o: 1.41309,
          h: 1.4138,
          l: 1.41275,
          c: 1.41331,
          v: 5090920000
        },
        {
          t: 68400,
          o: 1.41331,
          h: 1.41332,
          l: 1.41244,
          c: 1.41293,
          v: 5622560000
        },
        {
          t: 72000,
          o: 1.41291,
          h: 1.41318,
          l: 1.4126,
          c: 1.41294,
          v: 2778480000
        },
        {
          t: 75600,
          o: 1.41294,
          h: 1.4138,
          l: 1.41252,
          c: 1.4128,
          v: 1395400000
        },
        {
          t: 79200,
          o: 1.41281,
          h: 1.41332,
          l: 1.41281,
          c: 1.41313,
          v: 1037250000
        },
        {
          t: 82800,
          o: 1.41313,
          h: 1.41349,
          l: 1.41292,
          c: 1.4133,
          v: 1226450000
        }
      ]
    },
    {
      d: 1523318400,
      b: [
        {
          t: 0,
          o: 1.41327,
          h: 1.41402,
          l: 1.41311,
          c: 1.41348,
          v: 2174030000
        },
        {
          t: 3600,
          o: 1.41349,
          h: 1.41392,
          l: 1.41265,
          c: 1.41268,
          v: 3592420000
        },
        {
          t: 7200,
          o: 1.41269,
          h: 1.41311,
          l: 1.41199,
          c: 1.41286,
          v: 4440300000
        },
        {
          t: 10800,
          o: 1.41289,
          h: 1.41359,
          l: 1.41245,
          c: 1.41355,
          v: 3166760000
        },
        {
          t: 14400,
          o: 1.41356,
          h: 1.41367,
          l: 1.41321,
          c: 1.41366,
          v: 1557300000
        },
        {
          t: 18000,
          o: 1.41367,
          h: 1.4137,
          l: 1.41275,
          c: 1.41329,
          v: 2355960000
        },
        {
          t: 21600,
          o: 1.41337,
          h: 1.41498,
          l: 1.41254,
          c: 1.41474,
          v: 6331540000
        },
        {
          t: 25200,
          o: 1.4147,
          h: 1.41684,
          l: 1.41365,
          c: 1.41614,
          v: 8726510000
        },
        {
          t: 28800,
          o: 1.41612,
          h: 1.41685,
          l: 1.41485,
          c: 1.41666,
          v: 7803210000
        },
        {
          t: 32400,
          o: 1.41663,
          h: 1.41789,
          l: 1.41654,
          c: 1.41682,
          v: 6541520000
        },
        {
          t: 36000,
          o: 1.41683,
          h: 1.41707,
          l: 1.41555,
          c: 1.41579,
          v: 6242550000
        },
        {
          t: 39600,
          o: 1.41579,
          h: 1.41878,
          l: 1.41513,
          c: 1.41717,
          v: 11294220000
        },
        {
          t: 43200,
          o: 1.41717,
          h: 1.4182,
          l: 1.41554,
          c: 1.41618,
          v: 8156100000
        },
        {
          t: 46800,
          o: 1.41617,
          h: 1.418,
          l: 1.41601,
          c: 1.41776,
          v: 9216660000
        },
        {
          t: 50400,
          o: 1.41779,
          h: 1.41811,
          l: 1.4168,
          c: 1.41729,
          v: 8068100000
        },
        {
          t: 54000,
          o: 1.4173,
          h: 1.41771,
          l: 1.41538,
          c: 1.41595,
          v: 9391870000
        },
        {
          t: 57600,
          o: 1.41595,
          h: 1.41712,
          l: 1.41448,
          c: 1.41453,
          v: 7405630000
        },
        {
          t: 61200,
          o: 1.41455,
          h: 1.41709,
          l: 1.41452,
          c: 1.4165,
          v: 6286660000
        },
        {
          t: 64800,
          o: 1.41647,
          h: 1.4185,
          l: 1.41609,
          c: 1.41829,
          v: 5271460000
        },
        {
          t: 68400,
          o: 1.41828,
          h: 1.4183,
          l: 1.41715,
          c: 1.41762,
          v: 4298370000
        },
        {
          t: 72000,
          o: 1.4176,
          h: 1.41765,
          l: 1.41724,
          c: 1.41742,
          v: 2163740000
        },
        {
          t: 75600,
          o: 1.41744,
          h: 1.41778,
          l: 1.417,
          c: 1.4175,
          v: 1484830000
        },
        {
          t: 79200,
          o: 1.41752,
          h: 1.41806,
          l: 1.41725,
          c: 1.41768,
          v: 1853370000
        },
        {
          t: 82800,
          o: 1.41756,
          h: 1.41837,
          l: 1.41745,
          c: 1.41812,
          v: 1318320000
        }
      ]
    },
    {
      d: 1523404800,
      b: [
        {
          t: 0,
          o: 1.41811,
          h: 1.41826,
          l: 1.41759,
          c: 1.41799,
          v: 2429170000
        },
        {
          t: 3600,
          o: 1.41799,
          h: 1.41821,
          l: 1.41735,
          c: 1.41743,
          v: 2635640000
        },
        {
          t: 7200,
          o: 1.41743,
          h: 1.41827,
          l: 1.41733,
          c: 1.41764,
          v: 2964170000
        },
        {
          t: 10800,
          o: 1.41764,
          h: 1.41818,
          l: 1.41763,
          c: 1.41812,
          v: 2137100000
        },
        {
          t: 14400,
          o: 1.41812,
          h: 1.41813,
          l: 1.41782,
          c: 1.41808,
          v: 2145630000
        },
        {
          t: 18000,
          o: 1.41808,
          h: 1.41898,
          l: 1.41808,
          c: 1.41865,
          v: 2742850000
        },
        {
          t: 21600,
          o: 1.41865,
          h: 1.42149,
          l: 1.41836,
          c: 1.42052,
          v: 6233840000
        },
        {
          t: 25200,
          o: 1.42054,
          h: 1.4223,
          l: 1.41994,
          c: 1.42088,
          v: 8204570000
        },
        {
          t: 28800,
          o: 1.42087,
          h: 1.42147,
          l: 1.41918,
          c: 1.41943,
          v: 6435150000
        },
        {
          t: 32400,
          o: 1.41944,
          h: 1.42017,
          l: 1.41823,
          c: 1.41927,
          v: 5933140000
        },
        {
          t: 36000,
          o: 1.41929,
          h: 1.42015,
          l: 1.41883,
          c: 1.41952,
          v: 6150150000
        },
        {
          t: 39600,
          o: 1.41949,
          h: 1.41972,
          l: 1.41609,
          c: 1.41647,
          v: 9078030001
        },
        {
          t: 43200,
          o: 1.41647,
          h: 1.41908,
          l: 1.41632,
          c: 1.41803,
          v: 8485470000
        },
        {
          t: 46800,
          o: 1.41802,
          h: 1.41993,
          l: 1.41751,
          c: 1.41948,
          v: 6696010000
        },
        {
          t: 50400,
          o: 1.41946,
          h: 1.42201,
          l: 1.41939,
          c: 1.42066,
          v: 9387200000
        },
        {
          t: 54000,
          o: 1.42065,
          h: 1.42112,
          l: 1.41892,
          c: 1.41936,
          v: 8917940000
        },
        {
          t: 57600,
          o: 1.41936,
          h: 1.42032,
          l: 1.41732,
          c: 1.41754,
          v: 7424170000
        },
        {
          t: 61200,
          o: 1.41757,
          h: 1.41906,
          l: 1.41757,
          c: 1.41873,
          v: 4703120000
        },
        {
          t: 64800,
          o: 1.41872,
          h: 1.41907,
          l: 1.41608,
          c: 1.41718,
          v: 6815300000
        },
        {
          t: 68400,
          o: 1.41718,
          h: 1.41772,
          l: 1.41699,
          c: 1.41753,
          v: 4243550000
        },
        {
          t: 72000,
          o: 1.41752,
          h: 1.41821,
          l: 1.41745,
          c: 1.41746,
          v: 2490730000
        },
        {
          t: 75600,
          o: 1.41741,
          h: 1.41791,
          l: 1.41719,
          c: 1.41772,
          v: 1220240000
        },
        {
          t: 79200,
          o: 1.41768,
          h: 1.41839,
          l: 1.41766,
          c: 1.41801,
          v: 1292690000
        },
        {
          t: 82800,
          o: 1.41801,
          h: 1.41887,
          l: 1.4178,
          c: 1.41872,
          v: 1372160000
        }
      ]
    },
    {
      d: 1523491200,
      b: [
        {
          t: 0,
          o: 1.41871,
          h: 1.41973,
          l: 1.41867,
          c: 1.41946,
          v: 1924160000
        },
        {
          t: 3600,
          o: 1.41946,
          h: 1.41977,
          l: 1.41795,
          c: 1.41832,
          v: 2229910000
        },
        {
          t: 7200,
          o: 1.41833,
          h: 1.41841,
          l: 1.41786,
          c: 1.41789,
          v: 2596970000
        },
        {
          t: 10800,
          o: 1.41789,
          h: 1.41854,
          l: 1.41769,
          c: 1.41829,
          v: 2213550000
        },
        {
          t: 14400,
          o: 1.41828,
          h: 1.4183,
          l: 1.41711,
          c: 1.41735,
          v: 2158050000
        },
        {
          t: 18000,
          o: 1.41739,
          h: 1.41831,
          l: 1.41722,
          c: 1.41789,
          v: 2344130000
        },
        {
          t: 21600,
          o: 1.41788,
          h: 1.41951,
          l: 1.41755,
          c: 1.41808,
          v: 5481090000
        },
        {
          t: 25200,
          o: 1.41808,
          h: 1.41947,
          l: 1.4172,
          c: 1.41823,
          v: 8022560000
        },
        {
          t: 28800,
          o: 1.41822,
          h: 1.41879,
          l: 1.41539,
          c: 1.41572,
          v: 7134020000
        },
        {
          t: 32400,
          o: 1.41572,
          h: 1.41723,
          l: 1.41451,
          c: 1.41694,
          v: 8030620000
        },
        {
          t: 36000,
          o: 1.41694,
          h: 1.41888,
          l: 1.41675,
          c: 1.41865,
          v: 8348899998
        },
        {
          t: 39600,
          o: 1.41864,
          h: 1.42178,
          l: 1.41864,
          c: 1.42038,
          v: 12220800001
        },
        {
          t: 43200,
          o: 1.42037,
          h: 1.42086,
          l: 1.41873,
          c: 1.41888,
          v: 10830880000
        },
        {
          t: 46800,
          o: 1.41887,
          h: 1.42031,
          l: 1.41847,
          c: 1.41958,
          v: 10740990000
        },
        {
          t: 50400,
          o: 1.41958,
          h: 1.42396,
          l: 1.41889,
          c: 1.42317,
          v: 12410310000
        },
        {
          t: 54000,
          o: 1.4232,
          h: 1.42464,
          l: 1.42238,
          c: 1.42428,
          v: 8960090000
        },
        {
          t: 57600,
          o: 1.42428,
          h: 1.42436,
          l: 1.42265,
          c: 1.42266,
          v: 5211240000
        },
        {
          t: 61200,
          o: 1.42266,
          h: 1.42314,
          l: 1.42229,
          c: 1.42266,
          v: 5263020000
        },
        {
          t: 64800,
          o: 1.42267,
          h: 1.42334,
          l: 1.42218,
          c: 1.42273,
          v: 3782530000
        },
        {
          t: 68400,
          o: 1.42267,
          h: 1.42293,
          l: 1.42237,
          c: 1.42252,
          v: 2356380000
        },
        {
          t: 72000,
          o: 1.4225,
          h: 1.42311,
          l: 1.42249,
          c: 1.42268,
          v: 2521750000
        },
        {
          t: 75600,
          o: 1.42268,
          h: 1.42347,
          l: 1.42237,
          c: 1.42335,
          v: 954760000
        },
        {
          t: 79200,
          o: 1.42336,
          h: 1.42338,
          l: 1.42297,
          c: 1.42305,
          v: 1066700000
        },
        {
          t: 82800,
          o: 1.42305,
          h: 1.42395,
          l: 1.42296,
          c: 1.42389,
          v: 1555230000
        }
      ]
    },
    {
      d: 1523577600,
      b: [
        {
          t: 0,
          o: 1.42389,
          h: 1.42437,
          l: 1.42346,
          c: 1.42408,
          v: 2260800000
        },
        {
          t: 3600,
          o: 1.42408,
          h: 1.42449,
          l: 1.42323,
          c: 1.42337,
          v: 2271020000
        },
        {
          t: 7200,
          o: 1.42335,
          h: 1.42365,
          l: 1.42272,
          c: 1.42361,
          v: 2284000000
        },
        {
          t: 10800,
          o: 1.42363,
          h: 1.42405,
          l: 1.42339,
          c: 1.42344,
          v: 1675400000
        },
        {
          t: 14400,
          o: 1.42343,
          h: 1.42353,
          l: 1.42303,
          c: 1.42331,
          v: 1354350000
        },
        {
          t: 18000,
          o: 1.42331,
          h: 1.42335,
          l: 1.42282,
          c: 1.423,
          v: 1603040000
        },
        {
          t: 21600,
          o: 1.423,
          h: 1.42545,
          l: 1.42205,
          c: 1.42525,
          v: 7515920000
        },
        {
          t: 25200,
          o: 1.42524,
          h: 1.42756,
          l: 1.42475,
          c: 1.42721,
          v: 8253090000
        },
        {
          t: 28800,
          o: 1.42725,
          h: 1.42952,
          l: 1.4271,
          c: 1.42843,
          v: 7529670000
        },
        {
          t: 32400,
          o: 1.42841,
          h: 1.42886,
          l: 1.42761,
          c: 1.42828,
          v: 5852540000
        },
        {
          t: 36000,
          o: 1.42827,
          h: 1.42961,
          l: 1.42769,
          c: 1.4277,
          v: 5717720000
        },
        {
          t: 39600,
          o: 1.42771,
          h: 1.42787,
          l: 1.42488,
          c: 1.42557,
          v: 8623030000
        },
        {
          t: 43200,
          o: 1.42557,
          h: 1.42697,
          l: 1.42504,
          c: 1.4258,
          v: 5488520000
        },
        {
          t: 46800,
          o: 1.42583,
          h: 1.42659,
          l: 1.42519,
          c: 1.42574,
          v: 6512500000
        },
        {
          t: 50400,
          o: 1.42576,
          h: 1.42682,
          l: 1.42403,
          c: 1.42472,
          v: 7792650000
        },
        {
          t: 54000,
          o: 1.42473,
          h: 1.42592,
          l: 1.42396,
          c: 1.42508,
          v: 7369770000
        },
        {
          t: 57600,
          o: 1.42514,
          h: 1.42609,
          l: 1.4249,
          c: 1.4249,
          v: 4942650000
        },
        {
          t: 61200,
          o: 1.4249,
          h: 1.42547,
          l: 1.4244,
          c: 1.42494,
          v: 3698890000
        },
        {
          t: 64800,
          o: 1.42493,
          h: 1.42494,
          l: 1.42341,
          c: 1.42365,
          v: 3232860000
        },
        {
          t: 68400,
          o: 1.42364,
          h: 1.4245,
          l: 1.42356,
          c: 1.42445,
          v: 4187990000
        },
        {
          t: 72000,
          o: 1.42445,
          h: 1.42463,
          l: 1.42306,
          c: 1.42372,
          v: 2747030000
        }
      ]
    },
    {
      d: 1523750400,
      b: [
        {
          t: 75600,
          o: 1.42397,
          h: 1.42476,
          l: 1.42291,
          c: 1.42378,
          v: 1504000000
        },
        {
          t: 79200,
          o: 1.42384,
          h: 1.42445,
          l: 1.4234,
          c: 1.42378,
          v: 2560910000
        },
        {
          t: 82800,
          o: 1.4238,
          h: 1.42462,
          l: 1.42368,
          c: 1.42454,
          v: 1094810000
        }
      ]
    },
    {
      d: 1523836800,
      b: [
        {
          t: 0,
          o: 1.42453,
          h: 1.42634,
          l: 1.42449,
          c: 1.42612,
          v: 1703330000
        },
        {
          t: 3600,
          o: 1.42612,
          h: 1.42623,
          l: 1.42539,
          c: 1.42539,
          v: 1804380000
        },
        {
          t: 7200,
          o: 1.42538,
          h: 1.42621,
          l: 1.42499,
          c: 1.42612,
          v: 1451230000
        },
        {
          t: 10800,
          o: 1.42613,
          h: 1.42664,
          l: 1.42599,
          c: 1.42627,
          v: 2171970000
        },
        {
          t: 14400,
          o: 1.42628,
          h: 1.4263,
          l: 1.4256,
          c: 1.4256,
          v: 1748360000
        },
        {
          t: 18000,
          o: 1.4256,
          h: 1.4257,
          l: 1.42455,
          c: 1.42466,
          v: 1638140000
        },
        {
          t: 21600,
          o: 1.42467,
          h: 1.42689,
          l: 1.42366,
          c: 1.42408,
          v: 5112800000
        },
        {
          t: 25200,
          o: 1.42407,
          h: 1.42629,
          l: 1.42366,
          c: 1.42579,
          v: 7139780000
        },
        {
          t: 28800,
          o: 1.42578,
          h: 1.42803,
          l: 1.42555,
          c: 1.42708,
          v: 7091620000
        },
        {
          t: 32400,
          o: 1.42706,
          h: 1.43127,
          l: 1.42664,
          c: 1.4302,
          v: 7484980000
        },
        {
          t: 36000,
          o: 1.43024,
          h: 1.4309,
          l: 1.42968,
          c: 1.42974,
          v: 6454820000
        },
        {
          t: 39600,
          o: 1.42976,
          h: 1.43207,
          l: 1.4297,
          c: 1.43096,
          v: 5628610000
        },
        {
          t: 43200,
          o: 1.43093,
          h: 1.43135,
          l: 1.42978,
          c: 1.43117,
          v: 8013180000
        },
        {
          t: 46800,
          o: 1.43118,
          h: 1.43336,
          l: 1.43081,
          c: 1.43322,
          v: 7563840000
        },
        {
          t: 50400,
          o: 1.43322,
          h: 1.43378,
          l: 1.43116,
          c: 1.43282,
          v: 7491489999
        },
        {
          t: 54000,
          o: 1.4328,
          h: 1.43333,
          l: 1.43173,
          c: 1.43327,
          v: 6002910000
        },
        {
          t: 57600,
          o: 1.43322,
          h: 1.43345,
          l: 1.43223,
          c: 1.43246,
          v: 4228060000
        },
        {
          t: 61200,
          o: 1.43246,
          h: 1.43271,
          l: 1.43197,
          c: 1.432,
          v: 2332570000
        },
        {
          t: 64800,
          o: 1.43201,
          h: 1.43316,
          l: 1.43188,
          c: 1.43316,
          v: 2296100000
        },
        {
          t: 68400,
          o: 1.43316,
          h: 1.43365,
          l: 1.43267,
          c: 1.4336,
          v: 2381420000
        },
        {
          t: 72000,
          o: 1.43361,
          h: 1.43438,
          l: 1.4333,
          c: 1.43369,
          v: 3869310000
        },
        {
          t: 75600,
          o: 1.43366,
          h: 1.43398,
          l: 1.43338,
          c: 1.4336,
          v: 1492430000
        },
        {
          t: 79200,
          o: 1.43358,
          h: 1.43432,
          l: 1.43356,
          c: 1.43372,
          v: 1485280000
        },
        {
          t: 82800,
          o: 1.43373,
          h: 1.43438,
          l: 1.43336,
          c: 1.434,
          v: 2005430000
        }
      ]
    },
    {
      d: 1523923200,
      b: [
        {
          t: 0,
          o: 1.434,
          h: 1.43539,
          l: 1.43398,
          c: 1.43428,
          v: 2486680000
        },
        {
          t: 3600,
          o: 1.43431,
          h: 1.43467,
          l: 1.43376,
          c: 1.4338,
          v: 2002000000
        },
        {
          t: 7200,
          o: 1.43381,
          h: 1.43392,
          l: 1.43344,
          c: 1.43389,
          v: 1696060000
        },
        {
          t: 10800,
          o: 1.43391,
          h: 1.43435,
          l: 1.43338,
          c: 1.43351,
          v: 1601380000
        },
        {
          t: 14400,
          o: 1.43351,
          h: 1.43422,
          l: 1.43351,
          c: 1.43409,
          v: 1708590000
        },
        {
          t: 18000,
          o: 1.43409,
          h: 1.43475,
          l: 1.43376,
          c: 1.43386,
          v: 2579390000
        },
        {
          t: 21600,
          o: 1.43389,
          h: 1.43688,
          l: 1.43389,
          c: 1.43658,
          v: 6772540000
        },
        {
          t: 25200,
          o: 1.43656,
          h: 1.43763,
          l: 1.43613,
          c: 1.43659,
          v: 8323140000
        },
        {
          t: 28800,
          o: 1.43659,
          h: 1.4368,
          l: 1.43259,
          c: 1.4327,
          v: 10342570000
        },
        {
          t: 32400,
          o: 1.43272,
          h: 1.43365,
          l: 1.43119,
          c: 1.43205,
          v: 7571850000
        },
        {
          t: 36000,
          o: 1.43203,
          h: 1.43346,
          l: 1.43057,
          c: 1.43319,
          v: 6562050000
        },
        {
          t: 39600,
          o: 1.43321,
          h: 1.43459,
          l: 1.43269,
          c: 1.43353,
          v: 6484610000
        },
        {
          t: 43200,
          o: 1.43353,
          h: 1.43361,
          l: 1.43091,
          c: 1.43193,
          v: 8883570000
        },
        {
          t: 46800,
          o: 1.43194,
          h: 1.43272,
          l: 1.43046,
          c: 1.4305,
          v: 8825250000
        },
        {
          t: 50400,
          o: 1.43054,
          h: 1.4323,
          l: 1.43049,
          c: 1.43092,
          v: 9648500000
        },
        {
          t: 54000,
          o: 1.43093,
          h: 1.4315,
          l: 1.42932,
          c: 1.42993,
          v: 9401259999
        },
        {
          t: 57600,
          o: 1.42993,
          h: 1.42993,
          l: 1.42848,
          c: 1.42874,
          v: 7195700000
        },
        {
          t: 61200,
          o: 1.42874,
          h: 1.43047,
          l: 1.42873,
          c: 1.42925,
          v: 5431020000
        },
        {
          t: 64800,
          o: 1.42929,
          h: 1.43017,
          l: 1.4285,
          c: 1.42866,
          v: 3749060000
        },
        {
          t: 68400,
          o: 1.42866,
          h: 1.42889,
          l: 1.42826,
          c: 1.42884,
          v: 3162260000
        },
        {
          t: 72000,
          o: 1.42885,
          h: 1.42936,
          l: 1.42862,
          c: 1.42862,
          v: 3601450000
        },
        {
          t: 75600,
          o: 1.4285,
          h: 1.42908,
          l: 1.42835,
          c: 1.42892,
          v: 1193140000
        },
        {
          t: 79200,
          o: 1.42891,
          h: 1.42941,
          l: 1.42891,
          c: 1.42933,
          v: 1740700000
        },
        {
          t: 82800,
          o: 1.42933,
          h: 1.42973,
          l: 1.42914,
          c: 1.42964,
          v: 1681220000
        }
      ]
    },
    {
      d: 1524009600,
      b: [
        {
          t: 0,
          o: 1.42963,
          h: 1.43006,
          l: 1.42955,
          c: 1.42972,
          v: 1637750000
        },
        {
          t: 3600,
          o: 1.42972,
          h: 1.42973,
          l: 1.42901,
          c: 1.42943,
          v: 1771310000
        },
        {
          t: 7200,
          o: 1.42948,
          h: 1.43058,
          l: 1.42948,
          c: 1.43045,
          v: 2244830000
        },
        {
          t: 10800,
          o: 1.43045,
          h: 1.43056,
          l: 1.4296,
          c: 1.43022,
          v: 1650250000
        },
        {
          t: 14400,
          o: 1.43022,
          h: 1.43026,
          l: 1.42944,
          c: 1.42996,
          v: 1399290000
        },
        {
          t: 18000,
          o: 1.4299,
          h: 1.43078,
          l: 1.42941,
          c: 1.43071,
          v: 1602150000
        },
        {
          t: 21600,
          o: 1.43068,
          h: 1.43142,
          l: 1.42892,
          c: 1.42922,
          v: 7001500000
        },
        {
          t: 25200,
          o: 1.42922,
          h: 1.42948,
          l: 1.42699,
          c: 1.42811,
          v: 9154280000
        },
        {
          t: 28800,
          o: 1.42811,
          h: 1.42818,
          l: 1.41796,
          c: 1.41925,
          v: 13164150000
        },
        {
          t: 32400,
          o: 1.41921,
          h: 1.41995,
          l: 1.41728,
          c: 1.41861,
          v: 10287970000
        },
        {
          t: 36000,
          o: 1.41861,
          h: 1.42104,
          l: 1.41855,
          c: 1.42082,
          v: 7531540000
        },
        {
          t: 39600,
          o: 1.42081,
          h: 1.42175,
          l: 1.4205,
          c: 1.42164,
          v: 7417540000
        },
        {
          t: 43200,
          o: 1.42162,
          h: 1.42387,
          l: 1.42162,
          c: 1.42384,
          v: 7678010000
        },
        {
          t: 46800,
          o: 1.42383,
          h: 1.42393,
          l: 1.42102,
          c: 1.42321,
          v: 8787650000
        },
        {
          t: 50400,
          o: 1.42321,
          h: 1.42462,
          l: 1.42255,
          c: 1.42341,
          v: 9362450000
        },
        {
          t: 54000,
          o: 1.42341,
          h: 1.42351,
          l: 1.42154,
          c: 1.42265,
          v: 7460730000
        },
        {
          t: 57600,
          o: 1.42266,
          h: 1.42276,
          l: 1.42104,
          c: 1.42134,
          v: 5547880000
        },
        {
          t: 61200,
          o: 1.42134,
          h: 1.42177,
          l: 1.42054,
          c: 1.42083,
          v: 4706340000
        },
        {
          t: 64800,
          o: 1.42082,
          h: 1.42123,
          l: 1.42001,
          c: 1.42031,
          v: 3898620000
        },
        {
          t: 68400,
          o: 1.42033,
          h: 1.42053,
          l: 1.41978,
          c: 1.42023,
          v: 3419130000
        },
        {
          t: 72000,
          o: 1.42023,
          h: 1.42075,
          l: 1.42,
          c: 1.42,
          v: 2920630000
        },
        {
          t: 75600,
          o: 1.42,
          h: 1.42061,
          l: 1.41978,
          c: 1.42053,
          v: 1186450000
        },
        {
          t: 79200,
          o: 1.42053,
          h: 1.42097,
          l: 1.42017,
          c: 1.42058,
          v: 1557370000
        },
        {
          t: 82800,
          o: 1.42058,
          h: 1.42077,
          l: 1.4201,
          c: 1.42018,
          v: 1601020000
        }
      ]
    },
    {
      d: 1524096000,
      b: [
        {
          t: 0,
          o: 1.42017,
          h: 1.42024,
          l: 1.41912,
          c: 1.41932,
          v: 1478380000
        },
        {
          t: 3600,
          o: 1.41932,
          h: 1.41993,
          l: 1.41847,
          c: 1.41859,
          v: 2966490000
        },
        {
          t: 7200,
          o: 1.41858,
          h: 1.41963,
          l: 1.41851,
          c: 1.41942,
          v: 2577990000
        },
        {
          t: 10800,
          o: 1.41942,
          h: 1.42041,
          l: 1.41939,
          c: 1.41952,
          v: 3399560000
        },
        {
          t: 14400,
          o: 1.41951,
          h: 1.42047,
          l: 1.41943,
          c: 1.41984,
          v: 2769320000
        },
        {
          t: 18000,
          o: 1.41984,
          h: 1.42056,
          l: 1.41974,
          c: 1.42027,
          v: 2703000000
        },
        {
          t: 21600,
          o: 1.42025,
          h: 1.42092,
          l: 1.41896,
          c: 1.41998,
          v: 6866200000
        },
        {
          t: 25200,
          o: 1.41997,
          h: 1.42188,
          l: 1.41865,
          c: 1.41871,
          v: 8503740000
        },
        {
          t: 28800,
          o: 1.41873,
          h: 1.42037,
          l: 1.41607,
          c: 1.41772,
          v: 8713570000
        },
        {
          t: 32400,
          o: 1.41772,
          h: 1.41988,
          l: 1.4174,
          c: 1.4197,
          v: 6143370000
        },
        {
          t: 36000,
          o: 1.41973,
          h: 1.4227,
          l: 1.41852,
          c: 1.42198,
          v: 6162200000
        },
        {
          t: 39600,
          o: 1.42199,
          h: 1.42406,
          l: 1.42164,
          c: 1.42356,
          v: 7096580000
        },
        {
          t: 43200,
          o: 1.42359,
          h: 1.42454,
          l: 1.42177,
          c: 1.42378,
          v: 9779560000
        },
        {
          t: 46800,
          o: 1.42378,
          h: 1.42413,
          l: 1.42199,
          c: 1.42229,
          v: 9734130000
        },
        {
          t: 50400,
          o: 1.42231,
          h: 1.42385,
          l: 1.4219,
          c: 1.42202,
          v: 8278590000
        },
        {
          t: 54000,
          o: 1.42208,
          h: 1.42265,
          l: 1.41824,
          c: 1.41923,
          v: 9413630000
        },
        {
          t: 57600,
          o: 1.4192,
          h: 1.42023,
          l: 1.41881,
          c: 1.41931,
          v: 6973210002
        },
        {
          t: 61200,
          o: 1.41928,
          h: 1.41991,
          l: 1.40804,
          c: 1.40851,
          v: 13615790000
        },
        {
          t: 64800,
          o: 1.40851,
          h: 1.40928,
          l: 1.40684,
          c: 1.40775,
          v: 7061340000
        },
        {
          t: 68400,
          o: 1.40775,
          h: 1.4092,
          l: 1.40765,
          c: 1.40914,
          v: 6221570000
        },
        {
          t: 72000,
          o: 1.40914,
          h: 1.40987,
          l: 1.40759,
          c: 1.40833,
          v: 4689760000
        },
        {
          t: 75600,
          o: 1.40833,
          h: 1.40906,
          l: 1.4078,
          c: 1.40862,
          v: 1821640000
        },
        {
          t: 79200,
          o: 1.40873,
          h: 1.40873,
          l: 1.40768,
          c: 1.40795,
          v: 1735830000
        },
        {
          t: 82800,
          o: 1.40798,
          h: 1.40883,
          l: 1.40796,
          c: 1.40838,
          v: 1464340000
        }
      ]
    },
    {
      d: 1524182400,
      b: [
        {
          t: 0,
          o: 1.40838,
          h: 1.40873,
          l: 1.40757,
          c: 1.40857,
          v: 2281390000
        },
        {
          t: 3600,
          o: 1.40858,
          h: 1.40859,
          l: 1.40717,
          c: 1.40823,
          v: 2389229996
        },
        {
          t: 7200,
          o: 1.40823,
          h: 1.40854,
          l: 1.4075,
          c: 1.40752,
          v: 2066520000
        },
        {
          t: 10800,
          o: 1.40754,
          h: 1.40795,
          l: 1.40729,
          c: 1.40793,
          v: 2098900000
        },
        {
          t: 14400,
          o: 1.40793,
          h: 1.40821,
          l: 1.40772,
          c: 1.40784,
          v: 1843449999
        },
        {
          t: 18000,
          o: 1.40784,
          h: 1.40797,
          l: 1.40649,
          c: 1.40674,
          v: 3605360001
        },
        {
          t: 21600,
          o: 1.40673,
          h: 1.40706,
          l: 1.40449,
          c: 1.40637,
          v: 8938640000
        },
        {
          t: 25200,
          o: 1.40636,
          h: 1.40659,
          l: 1.40351,
          c: 1.40425,
          v: 10858910000
        },
        {
          t: 28800,
          o: 1.40431,
          h: 1.40663,
          l: 1.40401,
          c: 1.40658,
          v: 9049450000
        },
        {
          t: 32400,
          o: 1.40655,
          h: 1.40676,
          l: 1.40417,
          c: 1.40659,
          v: 8544070000
        },
        {
          t: 36000,
          o: 1.40659,
          h: 1.40818,
          l: 1.40552,
          c: 1.40722,
          v: 6567800000
        },
        {
          t: 39600,
          o: 1.40722,
          h: 1.40779,
          l: 1.4043,
          c: 1.40461,
          v: 7127660000
        },
        {
          t: 43200,
          o: 1.40462,
          h: 1.40621,
          l: 1.40338,
          c: 1.40381,
          v: 8929060000
        },
        {
          t: 46800,
          o: 1.40379,
          h: 1.40431,
          l: 1.40078,
          c: 1.40091,
          v: 8341240000
        },
        {
          t: 50400,
          o: 1.40091,
          h: 1.40396,
          l: 1.40068,
          c: 1.40242,
          v: 11446680000
        },
        {
          t: 54000,
          o: 1.40241,
          h: 1.40366,
          l: 1.40193,
          c: 1.40313,
          v: 9591160000
        },
        {
          t: 57600,
          o: 1.40313,
          h: 1.40435,
          l: 1.40273,
          c: 1.4037,
          v: 7321680000
        },
        {
          t: 61200,
          o: 1.40371,
          h: 1.40381,
          l: 1.40211,
          c: 1.40329,
          v: 4100970000
        },
        {
          t: 64800,
          o: 1.40329,
          h: 1.40365,
          l: 1.40221,
          c: 1.40225,
          v: 2744700000
        },
        {
          t: 68400,
          o: 1.40229,
          h: 1.40244,
          l: 1.401,
          c: 1.40113,
          v: 4267720000
        },
        {
          t: 72000,
          o: 1.40112,
          h: 1.40198,
          l: 1.39965,
          c: 1.40002,
          v: 4554880000
        }
      ]
    },
    {
      d: 1524355200,
      b: [
        {
          t: 75600,
          o: 1.3999,
          h: 1.40043,
          l: 1.39915,
          c: 1.40032,
          v: 643950000
        },
        {
          t: 79200,
          o: 1.40033,
          h: 1.40066,
          l: 1.3995,
          c: 1.40041,
          v: 3569230000
        },
        {
          t: 82800,
          o: 1.40041,
          h: 1.40073,
          l: 1.39993,
          c: 1.40014,
          v: 2242720000
        }
      ]
    },
    {
      d: 1524441600,
      b: [
        {
          t: 0,
          o: 1.40017,
          h: 1.40195,
          l: 1.40014,
          c: 1.40102,
          v: 2819380000
        },
        {
          t: 3600,
          o: 1.40102,
          h: 1.40228,
          l: 1.40065,
          c: 1.40217,
          v: 2377230000
        },
        {
          t: 7200,
          o: 1.40217,
          h: 1.40226,
          l: 1.40165,
          c: 1.40217,
          v: 3008030000
        },
        {
          t: 10800,
          o: 1.40217,
          h: 1.40221,
          l: 1.40162,
          c: 1.4017,
          v: 1951050000
        },
        {
          t: 14400,
          o: 1.4017,
          h: 1.4018,
          l: 1.40135,
          c: 1.40155,
          v: 1423770000
        },
        {
          t: 18000,
          o: 1.40155,
          h: 1.40199,
          l: 1.40115,
          c: 1.40197,
          v: 2383400000
        },
        {
          t: 21600,
          o: 1.40197,
          h: 1.40299,
          l: 1.40176,
          c: 1.40217,
          v: 5450980000
        },
        {
          t: 25200,
          o: 1.4022,
          h: 1.40307,
          l: 1.39842,
          c: 1.39961,
          v: 9920600000
        },
        {
          t: 28800,
          o: 1.3996,
          h: 1.40093,
          l: 1.39902,
          c: 1.39907,
          v: 8482030000
        },
        {
          t: 32400,
          o: 1.39907,
          h: 1.39919,
          l: 1.39587,
          c: 1.39651,
          v: 7579130000
        },
        {
          t: 36000,
          o: 1.39655,
          h: 1.39679,
          l: 1.39501,
          c: 1.39541,
          v: 7076880000
        },
        {
          t: 39600,
          o: 1.39545,
          h: 1.39596,
          l: 1.39425,
          c: 1.39593,
          v: 6471000000
        },
        {
          t: 43200,
          o: 1.39596,
          h: 1.39682,
          l: 1.39548,
          c: 1.39662,
          v: 8027010000
        },
        {
          t: 46800,
          o: 1.39662,
          h: 1.39767,
          l: 1.39391,
          c: 1.39441,
          v: 8213930000
        },
        {
          t: 50400,
          o: 1.39441,
          h: 1.39623,
          l: 1.39371,
          c: 1.39498,
          v: 7314780000
        },
        {
          t: 54000,
          o: 1.39498,
          h: 1.3958,
          l: 1.39436,
          c: 1.39512,
          v: 7333150000
        },
        {
          t: 57600,
          o: 1.39512,
          h: 1.3957,
          l: 1.39436,
          c: 1.39446,
          v: 4923760000
        },
        {
          t: 61200,
          o: 1.39447,
          h: 1.39449,
          l: 1.39261,
          c: 1.39294,
          v: 3436770000
        },
        {
          t: 64800,
          o: 1.39294,
          h: 1.39422,
          l: 1.39288,
          c: 1.39354,
          v: 3819190000
        },
        {
          t: 68400,
          o: 1.39356,
          h: 1.39459,
          l: 1.39342,
          c: 1.39439,
          v: 3446810000
        },
        {
          t: 72000,
          o: 1.39441,
          h: 1.39442,
          l: 1.39377,
          c: 1.39391,
          v: 2554600000
        },
        {
          t: 75600,
          o: 1.39391,
          h: 1.3941,
          l: 1.39344,
          c: 1.39379,
          v: 1083860000
        },
        {
          t: 79200,
          o: 1.39375,
          h: 1.39402,
          l: 1.39362,
          c: 1.39387,
          v: 1669970000
        },
        {
          t: 82800,
          o: 1.39387,
          h: 1.39391,
          l: 1.39338,
          c: 1.39371,
          v: 1358500000
        }
      ]
    },
    {
      d: 1524528000,
      b: [
        {
          t: 0,
          o: 1.39372,
          h: 1.39385,
          l: 1.39317,
          c: 1.39336,
          v: 1955270000
        },
        {
          t: 3600,
          o: 1.39338,
          h: 1.39338,
          l: 1.39177,
          c: 1.39325,
          v: 4194360000
        },
        {
          t: 7200,
          o: 1.39325,
          h: 1.39432,
          l: 1.39313,
          c: 1.39421,
          v: 3067010000
        },
        {
          t: 10800,
          o: 1.3942,
          h: 1.39494,
          l: 1.39399,
          c: 1.39472,
          v: 1571620000
        },
        {
          t: 14400,
          o: 1.39472,
          h: 1.39472,
          l: 1.39402,
          c: 1.39424,
          v: 1163020000
        },
        {
          t: 18000,
          o: 1.39424,
          h: 1.39466,
          l: 1.39424,
          c: 1.39454,
          v: 1640550000
        },
        {
          t: 21600,
          o: 1.39452,
          h: 1.39537,
          l: 1.39273,
          c: 1.39282,
          v: 5071570000
        },
        {
          t: 25200,
          o: 1.39283,
          h: 1.39384,
          l: 1.39197,
          c: 1.39308,
          v: 7708550000
        },
        {
          t: 28800,
          o: 1.39304,
          h: 1.39555,
          l: 1.39207,
          c: 1.39531,
          v: 6630160000
        },
        {
          t: 32400,
          o: 1.39538,
          h: 1.39583,
          l: 1.39399,
          c: 1.39568,
          v: 5264920000
        },
        {
          t: 36000,
          o: 1.39569,
          h: 1.3961,
          l: 1.39393,
          c: 1.39402,
          v: 5732240000
        },
        {
          t: 39600,
          o: 1.39404,
          h: 1.39673,
          l: 1.39381,
          c: 1.3967,
          v: 5830630001
        },
        {
          t: 43200,
          o: 1.39671,
          h: 1.39674,
          l: 1.39459,
          c: 1.39551,
          v: 7277060001
        },
        {
          t: 46800,
          o: 1.39552,
          h: 1.39841,
          l: 1.39517,
          c: 1.39737,
          v: 10988860000
        },
        {
          t: 50400,
          o: 1.39742,
          h: 1.3976,
          l: 1.39586,
          c: 1.39634,
          v: 9217510000
        },
        {
          t: 54000,
          o: 1.39636,
          h: 1.39757,
          l: 1.3954,
          c: 1.39748,
          v: 7186540000
        },
        {
          t: 57600,
          o: 1.39746,
          h: 1.39869,
          l: 1.39716,
          c: 1.3977,
          v: 6728870000
        },
        {
          t: 61200,
          o: 1.3977,
          h: 1.39794,
          l: 1.39723,
          c: 1.39775,
          v: 4892990000
        },
        {
          t: 64800,
          o: 1.39775,
          h: 1.39816,
          l: 1.39698,
          c: 1.39718,
          v: 4863970000
        },
        {
          t: 68400,
          o: 1.39719,
          h: 1.39827,
          l: 1.3971,
          c: 1.39812,
          v: 2964090000
        },
        {
          t: 72000,
          o: 1.39807,
          h: 1.39808,
          l: 1.39734,
          c: 1.39767,
          v: 2510340000
        },
        {
          t: 75600,
          o: 1.39768,
          h: 1.39814,
          l: 1.39706,
          c: 1.39801,
          v: 1018350000
        },
        {
          t: 79200,
          o: 1.39801,
          h: 1.39811,
          l: 1.39786,
          c: 1.39799,
          v: 940840000
        },
        {
          t: 82800,
          o: 1.398,
          h: 1.39965,
          l: 1.39795,
          c: 1.39937,
          v: 1730920000
        }
      ]
    },
    {
      d: 1524614400,
      b: [
        {
          t: 0,
          o: 1.39937,
          h: 1.39937,
          l: 1.39842,
          c: 1.39884,
          v: 2671970000
        },
        {
          t: 3600,
          o: 1.39885,
          h: 1.39885,
          l: 1.3977,
          c: 1.39772,
          v: 2913750000
        },
        {
          t: 7200,
          o: 1.39772,
          h: 1.39814,
          l: 1.39753,
          c: 1.39803,
          v: 1965750000
        },
        {
          t: 10800,
          o: 1.39803,
          h: 1.3986,
          l: 1.39794,
          c: 1.39816,
          v: 1343330000
        },
        {
          t: 14400,
          o: 1.39816,
          h: 1.39817,
          l: 1.39671,
          c: 1.39685,
          v: 1359710000
        },
        {
          t: 18000,
          o: 1.39686,
          h: 1.397,
          l: 1.39607,
          c: 1.39658,
          v: 2521330000
        },
        {
          t: 21600,
          o: 1.39657,
          h: 1.39707,
          l: 1.39554,
          c: 1.39584,
          v: 6140070000
        },
        {
          t: 25200,
          o: 1.39584,
          h: 1.39614,
          l: 1.39347,
          c: 1.39366,
          v: 6094190002
        },
        {
          t: 28800,
          o: 1.39366,
          h: 1.39577,
          l: 1.39336,
          c: 1.3956,
          v: 5952920000
        },
        {
          t: 32400,
          o: 1.3956,
          h: 1.39657,
          l: 1.39521,
          c: 1.3959,
          v: 5706160000
        },
        {
          t: 36000,
          o: 1.39588,
          h: 1.39699,
          l: 1.39434,
          c: 1.39479,
          v: 6187030000
        },
        {
          t: 39600,
          o: 1.39478,
          h: 1.39588,
          l: 1.39327,
          c: 1.39348,
          v: 6536310000
        },
        {
          t: 43200,
          o: 1.39348,
          h: 1.3948,
          l: 1.39336,
          c: 1.39451,
          v: 8301720000
        },
        {
          t: 46800,
          o: 1.39452,
          h: 1.39503,
          l: 1.39366,
          c: 1.39445,
          v: 7290670000
        },
        {
          t: 50400,
          o: 1.39445,
          h: 1.39454,
          l: 1.39244,
          c: 1.39345,
          v: 7080940000
        },
        {
          t: 54000,
          o: 1.39346,
          h: 1.3956,
          l: 1.39339,
          c: 1.39403,
          v: 7313040000
        },
        {
          t: 57600,
          o: 1.39405,
          h: 1.39481,
          l: 1.39335,
          c: 1.39372,
          v: 5233250000
        },
        {
          t: 61200,
          o: 1.39373,
          h: 1.39436,
          l: 1.39324,
          c: 1.39325,
          v: 3733270000
        },
        {
          t: 64800,
          o: 1.39324,
          h: 1.3941,
          l: 1.39261,
          c: 1.39381,
          v: 2922160000
        },
        {
          t: 68400,
          o: 1.39377,
          h: 1.39429,
          l: 1.39297,
          c: 1.39306,
          v: 4019620000
        },
        {
          t: 72000,
          o: 1.39303,
          h: 1.39311,
          l: 1.39231,
          c: 1.39291,
          v: 3474160000
        },
        {
          t: 75600,
          o: 1.39286,
          h: 1.39347,
          l: 1.3921,
          c: 1.3933,
          v: 1628780000
        },
        {
          t: 79200,
          o: 1.39331,
          h: 1.39343,
          l: 1.39298,
          c: 1.39329,
          v: 1315060000
        },
        {
          t: 82800,
          o: 1.39329,
          h: 1.39375,
          l: 1.39325,
          c: 1.39354,
          v: 1065990000
        }
      ]
    },
    {
      d: 1524700800,
      b: [
        {
          t: 0,
          o: 1.39357,
          h: 1.39456,
          l: 1.39287,
          c: 1.39385,
          v: 3259690000
        },
        {
          t: 3600,
          o: 1.39387,
          h: 1.39461,
          l: 1.39349,
          c: 1.39428,
          v: 4005020000
        },
        {
          t: 7200,
          o: 1.39429,
          h: 1.39462,
          l: 1.39389,
          c: 1.39394,
          v: 2144060000
        },
        {
          t: 10800,
          o: 1.39392,
          h: 1.39495,
          l: 1.39387,
          c: 1.39463,
          v: 2090569996
        },
        {
          t: 14400,
          o: 1.39463,
          h: 1.39492,
          l: 1.39412,
          c: 1.3942,
          v: 1554430000
        },
        {
          t: 18000,
          o: 1.39421,
          h: 1.39462,
          l: 1.394,
          c: 1.3945,
          v: 2246450000
        },
        {
          t: 21600,
          o: 1.39451,
          h: 1.39481,
          l: 1.3936,
          c: 1.3941,
          v: 5453280000
        },
        {
          t: 25200,
          o: 1.39409,
          h: 1.39492,
          l: 1.39024,
          c: 1.39081,
          v: 10753500000
        },
        {
          t: 28800,
          o: 1.39081,
          h: 1.39233,
          l: 1.38947,
          c: 1.3911,
          v: 8316060000
        },
        {
          t: 32400,
          o: 1.3911,
          h: 1.39408,
          l: 1.39078,
          c: 1.3939,
          v: 7026300000
        },
        {
          t: 36000,
          o: 1.3939,
          h: 1.39701,
          l: 1.39358,
          c: 1.39631,
          v: 6180450000
        },
        {
          t: 39600,
          o: 1.39631,
          h: 1.39691,
          l: 1.39515,
          c: 1.39549,
          v: 9405540000
        },
        {
          t: 43200,
          o: 1.39548,
          h: 1.39914,
          l: 1.39533,
          c: 1.39821,
          v: 16065670000
        },
        {
          t: 46800,
          o: 1.39821,
          h: 1.3998,
          l: 1.39728,
          c: 1.39801,
          v: 11743130000
        },
        {
          t: 50400,
          o: 1.39801,
          h: 1.39844,
          l: 1.39347,
          c: 1.39353,
          v: 12642480000
        },
        {
          t: 54000,
          o: 1.39351,
          h: 1.39355,
          l: 1.39041,
          c: 1.39264,
          v: 9010600000
        },
        {
          t: 57600,
          o: 1.39265,
          h: 1.39327,
          l: 1.39205,
          c: 1.39298,
          v: 6103380000
        },
        {
          t: 61200,
          o: 1.39298,
          h: 1.39394,
          l: 1.3923,
          c: 1.3923,
          v: 4109660000
        },
        {
          t: 64800,
          o: 1.3923,
          h: 1.39265,
          l: 1.39178,
          c: 1.39243,
          v: 3661550000
        },
        {
          t: 68400,
          o: 1.39244,
          h: 1.39266,
          l: 1.39134,
          c: 1.39174,
          v: 3191590000
        },
        {
          t: 72000,
          o: 1.39175,
          h: 1.3922,
          l: 1.39117,
          c: 1.3915,
          v: 2811680000
        },
        {
          t: 75600,
          o: 1.39137,
          h: 1.39172,
          l: 1.39112,
          c: 1.3915,
          v: 819030000
        },
        {
          t: 79200,
          o: 1.39152,
          h: 1.3919,
          l: 1.39141,
          c: 1.39142,
          v: 2319640000
        },
        {
          t: 82800,
          o: 1.39142,
          h: 1.39191,
          l: 1.39136,
          c: 1.39175,
          v: 1249410000
        }
      ]
    },
    {
      d: 1524787200,
      b: [
        {
          t: 0,
          o: 1.39175,
          h: 1.39185,
          l: 1.39114,
          c: 1.39184,
          v: 1591130000
        },
        {
          t: 3600,
          o: 1.39181,
          h: 1.39242,
          l: 1.39136,
          c: 1.39232,
          v: 2670320000
        },
        {
          t: 7200,
          o: 1.39232,
          h: 1.39253,
          l: 1.39169,
          c: 1.39245,
          v: 2346060000
        },
        {
          t: 10800,
          o: 1.39245,
          h: 1.39342,
          l: 1.39242,
          c: 1.39323,
          v: 1663990000
        },
        {
          t: 14400,
          o: 1.39323,
          h: 1.39323,
          l: 1.39248,
          c: 1.39279,
          v: 1467000000
        },
        {
          t: 18000,
          o: 1.39279,
          h: 1.39313,
          l: 1.39199,
          c: 1.39285,
          v: 2759190000
        },
        {
          t: 21600,
          o: 1.39285,
          h: 1.39333,
          l: 1.39063,
          c: 1.39186,
          v: 6537780000
        },
        {
          t: 25200,
          o: 1.39184,
          h: 1.3921,
          l: 1.38714,
          c: 1.3879,
          v: 8288900000
        },
        {
          t: 28800,
          o: 1.3879,
          h: 1.38916,
          l: 1.38014,
          c: 1.38197,
          v: 12399670000
        },
        {
          t: 32400,
          o: 1.38197,
          h: 1.38351,
          l: 1.3793,
          c: 1.37971,
          v: 10075180000
        },
        {
          t: 36000,
          o: 1.37976,
          h: 1.37993,
          l: 1.37649,
          c: 1.37679,
          v: 10167290000
        },
        {
          t: 39600,
          o: 1.37678,
          h: 1.3774,
          l: 1.37552,
          c: 1.37577,
          v: 9067480000
        },
        {
          t: 43200,
          o: 1.37576,
          h: 1.37764,
          l: 1.3747,
          c: 1.37592,
          v: 11813510000
        },
        {
          t: 46800,
          o: 1.37593,
          h: 1.3795,
          l: 1.37535,
          c: 1.37812,
          v: 9492620000
        },
        {
          t: 50400,
          o: 1.37813,
          h: 1.38076,
          l: 1.37652,
          c: 1.37947,
          v: 9588370000
        },
        {
          t: 54000,
          o: 1.37936,
          h: 1.38017,
          l: 1.37792,
          c: 1.37838,
          v: 5972680000
        },
        {
          t: 57600,
          o: 1.37838,
          h: 1.37882,
          l: 1.37746,
          c: 1.37763,
          v: 5858570000
        },
        {
          t: 61200,
          o: 1.37763,
          h: 1.37774,
          l: 1.37692,
          c: 1.37743,
          v: 3266710000
        },
        {
          t: 64800,
          o: 1.37743,
          h: 1.37889,
          l: 1.37736,
          c: 1.37884,
          v: 3341810000
        },
        {
          t: 68400,
          o: 1.37879,
          h: 1.3788,
          l: 1.37812,
          c: 1.37814,
          v: 4129730000
        },
        {
          t: 72000,
          o: 1.37813,
          h: 1.37836,
          l: 1.37741,
          c: 1.37785,
          v: 2472110000
        }
      ]
    },
    {
      d: 1524960000,
      b: [
        {
          t: 75600,
          o: 1.37711,
          h: 1.37779,
          l: 1.37691,
          c: 1.37746,
          v: 966540000
        },
        {
          t: 79200,
          o: 1.37749,
          h: 1.37779,
          l: 1.37726,
          c: 1.37743,
          v: 3183310000
        },
        {
          t: 82800,
          o: 1.37744,
          h: 1.37751,
          l: 1.37684,
          c: 1.37688,
          v: 1310840000
        }
      ]
    },
    {
      d: 1525046400,
      b: [
        {
          t: 0,
          o: 1.37688,
          h: 1.37778,
          l: 1.37676,
          c: 1.37735,
          v: 1985540000
        },
        {
          t: 3600,
          o: 1.37735,
          h: 1.3779,
          l: 1.37686,
          c: 1.37788,
          v: 1903270000
        },
        {
          t: 7200,
          o: 1.37787,
          h: 1.37833,
          l: 1.3772,
          c: 1.37765,
          v: 2131590001
        },
        {
          t: 10800,
          o: 1.37765,
          h: 1.37819,
          l: 1.37749,
          c: 1.37789,
          v: 2286850000
        },
        {
          t: 14400,
          o: 1.37792,
          h: 1.37857,
          l: 1.3779,
          c: 1.37835,
          v: 1926160000
        },
        {
          t: 18000,
          o: 1.37835,
          h: 1.37922,
          l: 1.37808,
          c: 1.37912,
          v: 2544850000
        },
        {
          t: 21600,
          o: 1.37913,
          h: 1.37914,
          l: 1.37596,
          c: 1.37619,
          v: 6798170000
        },
        {
          t: 25200,
          o: 1.37615,
          h: 1.37728,
          l: 1.37475,
          c: 1.37531,
          v: 7666940000
        },
        {
          t: 28800,
          o: 1.37531,
          h: 1.37659,
          l: 1.3732,
          c: 1.37339,
          v: 5692120000
        },
        {
          t: 32400,
          o: 1.37336,
          h: 1.37404,
          l: 1.37268,
          c: 1.37304,
          v: 5903830000
        },
        {
          t: 36000,
          o: 1.37302,
          h: 1.3742,
          l: 1.37125,
          c: 1.37272,
          v: 5948660000
        },
        {
          t: 39600,
          o: 1.37272,
          h: 1.3733,
          l: 1.37188,
          c: 1.3729,
          v: 6245000001
        },
        {
          t: 43200,
          o: 1.37288,
          h: 1.37499,
          l: 1.37239,
          c: 1.37456,
          v: 6592560000
        },
        {
          t: 46800,
          o: 1.37453,
          h: 1.37504,
          l: 1.37305,
          c: 1.37412,
          v: 7785820000
        },
        {
          t: 50400,
          o: 1.37411,
          h: 1.37859,
          l: 1.37352,
          c: 1.37748,
          v: 10752940000
        },
        {
          t: 54000,
          o: 1.37748,
          h: 1.37767,
          l: 1.37476,
          c: 1.37497,
          v: 9229110000
        },
        {
          t: 57600,
          o: 1.37496,
          h: 1.37765,
          l: 1.37374,
          c: 1.37649,
          v: 7839100000
        },
        {
          t: 61200,
          o: 1.37648,
          h: 1.37677,
          l: 1.37514,
          c: 1.3763,
          v: 4894180000
        },
        {
          t: 64800,
          o: 1.37629,
          h: 1.37662,
          l: 1.37445,
          c: 1.37459,
          v: 3492010000
        },
        {
          t: 68400,
          o: 1.37451,
          h: 1.37675,
          l: 1.37451,
          c: 1.37659,
          v: 2862160000
        },
        {
          t: 72000,
          o: 1.37663,
          h: 1.37681,
          l: 1.3755,
          c: 1.3755,
          v: 2289250000
        },
        {
          t: 75600,
          o: 1.37547,
          h: 1.37681,
          l: 1.37547,
          c: 1.37676,
          v: 2539690000
        },
        {
          t: 79200,
          o: 1.37674,
          h: 1.37719,
          l: 1.37648,
          c: 1.37679,
          v: 1627190000
        },
        {
          t: 82800,
          o: 1.37677,
          h: 1.37719,
          l: 1.37648,
          c: 1.37718,
          v: 1858750000
        }
      ]
    }
  ],
  USDJPY15: [
    {
      d: 1522540800,
      b: [
        {
          t: 75600,
          o: 106.167,
          h: 106.237,
          l: 106.167,
          c: 106.231,
          v: 103270000
        },
        {
          t: 76500,
          o: 106.232,
          h: 106.234,
          l: 106.212,
          c: 106.215,
          v: 58130000
        },
        {
          t: 77400,
          o: 106.215,
          h: 106.244,
          l: 106.215,
          c: 106.242,
          v: 56120000
        },
        {
          t: 78300,
          o: 106.242,
          h: 106.268,
          l: 106.232,
          c: 106.26,
          v: 24110000
        },
        {
          t: 79200,
          o: 106.261,
          h: 106.405,
          l: 106.261,
          c: 106.379,
          v: 1338130000
        },
        {
          t: 80100,
          o: 106.378,
          h: 106.399,
          l: 106.349,
          c: 106.388,
          v: 1335270000
        },
        {
          t: 81000,
          o: 106.388,
          h: 106.388,
          l: 106.347,
          c: 106.354,
          v: 553620000
        },
        {
          t: 81900,
          o: 106.354,
          h: 106.355,
          l: 106.292,
          c: 106.301,
          v: 474560000
        },
        {
          t: 82800,
          o: 106.301,
          h: 106.349,
          l: 106.301,
          c: 106.336,
          v: 732130000
        },
        {
          t: 83700,
          o: 106.336,
          h: 106.336,
          l: 106.297,
          c: 106.317,
          v: 853280000
        },
        {
          t: 84600,
          o: 106.317,
          h: 106.322,
          l: 106.261,
          c: 106.289,
          v: 1128790000
        },
        {
          t: 85500,
          o: 106.294,
          h: 106.322,
          l: 106.253,
          c: 106.318,
          v: 1539270000
        }
      ]
    },
    {
      d: 1522627200,
      b: [
        {
          t: 0,
          o: 106.32,
          h: 106.367,
          l: 106.275,
          c: 106.321,
          v: 2791520000
        },
        {
          t: 900,
          o: 106.321,
          h: 106.321,
          l: 106.218,
          c: 106.223,
          v: 1955690000
        },
        {
          t: 1800,
          o: 106.224,
          h: 106.253,
          l: 106.186,
          c: 106.248,
          v: 1592790000
        },
        {
          t: 2700,
          o: 106.249,
          h: 106.304,
          l: 106.236,
          c: 106.281,
          v: 2203880000
        },
        {
          t: 3600,
          o: 106.282,
          h: 106.288,
          l: 106.231,
          c: 106.276,
          v: 1781850000
        },
        {
          t: 4500,
          o: 106.279,
          h: 106.34,
          l: 106.262,
          c: 106.317,
          v: 1800800000
        },
        {
          t: 5400,
          o: 106.318,
          h: 106.341,
          l: 106.281,
          c: 106.326,
          v: 1025170000
        },
        {
          t: 6300,
          o: 106.326,
          h: 106.381,
          l: 106.32,
          c: 106.337,
          v: 1672010001
        },
        {
          t: 7200,
          o: 106.336,
          h: 106.35,
          l: 106.291,
          c: 106.301,
          v: 1218480000
        },
        {
          t: 8100,
          o: 106.301,
          h: 106.328,
          l: 106.28,
          c: 106.323,
          v: 1323550000
        },
        {
          t: 9000,
          o: 106.321,
          h: 106.332,
          l: 106.296,
          c: 106.33,
          v: 1242130000
        },
        {
          t: 9900,
          o: 106.33,
          h: 106.341,
          l: 106.307,
          c: 106.326,
          v: 1227360000
        },
        {
          t: 10800,
          o: 106.325,
          h: 106.338,
          l: 106.304,
          c: 106.314,
          v: 870710000
        },
        {
          t: 11700,
          o: 106.314,
          h: 106.351,
          l: 106.313,
          c: 106.346,
          v: 827900000
        },
        {
          t: 12600,
          o: 106.346,
          h: 106.363,
          l: 106.334,
          c: 106.359,
          v: 645510000
        },
        {
          t: 13500,
          o: 106.358,
          h: 106.39,
          l: 106.352,
          c: 106.357,
          v: 1398660000
        },
        {
          t: 14400,
          o: 106.358,
          h: 106.369,
          l: 106.316,
          c: 106.326,
          v: 1151610000
        },
        {
          t: 15300,
          o: 106.326,
          h: 106.332,
          l: 106.313,
          c: 106.319,
          v: 846910000
        },
        {
          t: 16200,
          o: 106.319,
          h: 106.336,
          l: 106.303,
          c: 106.314,
          v: 1271950000
        },
        {
          t: 17100,
          o: 106.313,
          h: 106.331,
          l: 106.31,
          c: 106.331,
          v: 771910000
        },
        {
          t: 18000,
          o: 106.33,
          h: 106.354,
          l: 106.324,
          c: 106.342,
          v: 669280000
        },
        {
          t: 18900,
          o: 106.342,
          h: 106.377,
          l: 106.327,
          c: 106.375,
          v: 652200000
        },
        {
          t: 19800,
          o: 106.377,
          h: 106.377,
          l: 106.339,
          c: 106.341,
          v: 936300000
        },
        {
          t: 20700,
          o: 106.341,
          h: 106.341,
          l: 106.3,
          c: 106.313,
          v: 1248590000
        },
        {
          t: 21600,
          o: 106.312,
          h: 106.324,
          l: 106.284,
          c: 106.318,
          v: 992770000
        },
        {
          t: 22500,
          o: 106.318,
          h: 106.348,
          l: 106.318,
          c: 106.341,
          v: 795390000
        },
        {
          t: 23400,
          o: 106.342,
          h: 106.354,
          l: 106.325,
          c: 106.344,
          v: 612420000
        },
        {
          t: 24300,
          o: 106.343,
          h: 106.361,
          l: 106.328,
          c: 106.33,
          v: 482710000
        },
        {
          t: 25200,
          o: 106.329,
          h: 106.331,
          l: 106.292,
          c: 106.315,
          v: 664490000
        },
        {
          t: 26100,
          o: 106.316,
          h: 106.33,
          l: 106.31,
          c: 106.314,
          v: 274660000
        },
        {
          t: 27000,
          o: 106.315,
          h: 106.322,
          l: 106.294,
          c: 106.298,
          v: 650460000
        },
        {
          t: 27900,
          o: 106.297,
          h: 106.3,
          l: 106.276,
          c: 106.29,
          v: 636560000
        },
        {
          t: 28800,
          o: 106.289,
          h: 106.306,
          l: 106.283,
          c: 106.3,
          v: 468820000
        },
        {
          t: 29700,
          o: 106.299,
          h: 106.317,
          l: 106.283,
          c: 106.301,
          v: 279520000
        },
        {
          t: 30600,
          o: 106.302,
          h: 106.311,
          l: 106.288,
          c: 106.289,
          v: 427180000
        },
        {
          t: 31500,
          o: 106.289,
          h: 106.297,
          l: 106.268,
          c: 106.283,
          v: 374460000
        },
        {
          t: 32400,
          o: 106.283,
          h: 106.286,
          l: 106.263,
          c: 106.284,
          v: 332510000
        },
        {
          t: 33300,
          o: 106.285,
          h: 106.285,
          l: 106.273,
          c: 106.275,
          v: 203650000
        },
        {
          t: 34200,
          o: 106.275,
          h: 106.28,
          l: 106.267,
          c: 106.276,
          v: 169650000
        },
        {
          t: 35100,
          o: 106.276,
          h: 106.292,
          l: 106.274,
          c: 106.291,
          v: 121830000
        },
        {
          t: 36000,
          o: 106.292,
          h: 106.313,
          l: 106.292,
          c: 106.303,
          v: 457200000
        },
        {
          t: 36900,
          o: 106.303,
          h: 106.315,
          l: 106.295,
          c: 106.303,
          v: 295900000
        },
        {
          t: 37800,
          o: 106.303,
          h: 106.318,
          l: 106.302,
          c: 106.307,
          v: 364800000
        },
        {
          t: 38700,
          o: 106.308,
          h: 106.313,
          l: 106.285,
          c: 106.285,
          v: 330610000
        },
        {
          t: 39600,
          o: 106.287,
          h: 106.299,
          l: 106.271,
          c: 106.274,
          v: 596050000
        },
        {
          t: 40500,
          o: 106.274,
          h: 106.307,
          l: 106.264,
          c: 106.284,
          v: 693950000
        },
        {
          t: 41400,
          o: 106.283,
          h: 106.286,
          l: 106.264,
          c: 106.285,
          v: 904090000
        },
        {
          t: 42300,
          o: 106.285,
          h: 106.307,
          l: 106.274,
          c: 106.28,
          v: 674920000
        },
        {
          t: 43200,
          o: 106.279,
          h: 106.332,
          l: 106.267,
          c: 106.325,
          v: 1443190001
        },
        {
          t: 44100,
          o: 106.324,
          h: 106.363,
          l: 106.314,
          c: 106.357,
          v: 1604940000
        },
        {
          t: 45000,
          o: 106.356,
          h: 106.382,
          l: 106.33,
          c: 106.352,
          v: 1196410000
        },
        {
          t: 45900,
          o: 106.353,
          h: 106.429,
          l: 106.343,
          c: 106.427,
          v: 1516840000
        },
        {
          t: 46800,
          o: 106.426,
          h: 106.448,
          l: 106.383,
          c: 106.385,
          v: 1690770000
        },
        {
          t: 47700,
          o: 106.39,
          h: 106.412,
          l: 106.355,
          c: 106.376,
          v: 1884280000
        },
        {
          t: 48600,
          o: 106.374,
          h: 106.428,
          l: 106.341,
          c: 106.396,
          v: 2859210000
        },
        {
          t: 49500,
          o: 106.398,
          h: 106.406,
          l: 106.35,
          c: 106.353,
          v: 2373510000
        },
        {
          t: 50400,
          o: 106.352,
          h: 106.439,
          l: 106.339,
          c: 106.357,
          v: 2896480000
        },
        {
          t: 51300,
          o: 106.356,
          h: 106.391,
          l: 106.315,
          c: 106.325,
          v: 2490060000
        },
        {
          t: 52200,
          o: 106.324,
          h: 106.326,
          l: 106.208,
          c: 106.245,
          v: 2235420000
        },
        {
          t: 53100,
          o: 106.245,
          h: 106.282,
          l: 106.217,
          c: 106.22,
          v: 2372500000
        },
        {
          t: 54000,
          o: 106.219,
          h: 106.23,
          l: 106.09,
          c: 106.108,
          v: 2751890000
        },
        {
          t: 54900,
          o: 106.109,
          h: 106.113,
          l: 106.052,
          c: 106.09,
          v: 2531430000
        },
        {
          t: 55800,
          o: 106.089,
          h: 106.093,
          l: 105.944,
          c: 105.997,
          v: 2743540000
        },
        {
          t: 56700,
          o: 105.998,
          h: 106.052,
          l: 105.965,
          c: 105.995,
          v: 2482710000
        },
        {
          t: 57600,
          o: 105.995,
          h: 106.052,
          l: 105.987,
          c: 106.026,
          v: 2756300000
        },
        {
          t: 58500,
          o: 106.03,
          h: 106.095,
          l: 105.998,
          c: 106.069,
          v: 2136500000
        },
        {
          t: 59400,
          o: 106.067,
          h: 106.121,
          l: 106.063,
          c: 106.083,
          v: 2033450000
        },
        {
          t: 60300,
          o: 106.083,
          h: 106.083,
          l: 106.01,
          c: 106.014,
          v: 2469870000
        },
        {
          t: 61200,
          o: 106.012,
          h: 106.034,
          l: 105.975,
          c: 105.977,
          v: 2899420000
        },
        {
          t: 62100,
          o: 105.978,
          h: 105.992,
          l: 105.859,
          c: 105.859,
          v: 3168940000
        },
        {
          t: 63000,
          o: 105.859,
          h: 105.876,
          l: 105.718,
          c: 105.718,
          v: 3712380000
        },
        {
          t: 63900,
          o: 105.719,
          h: 105.732,
          l: 105.666,
          c: 105.667,
          v: 3327050000
        },
        {
          t: 64800,
          o: 105.666,
          h: 105.768,
          l: 105.657,
          c: 105.761,
          v: 4119460000
        },
        {
          t: 65700,
          o: 105.761,
          h: 105.788,
          l: 105.743,
          c: 105.782,
          v: 3368860000
        },
        {
          t: 66600,
          o: 105.781,
          h: 105.829,
          l: 105.768,
          c: 105.778,
          v: 3021460000
        },
        {
          t: 67500,
          o: 105.777,
          h: 105.872,
          l: 105.763,
          c: 105.848,
          v: 2659450000
        },
        {
          t: 68400,
          o: 105.847,
          h: 105.895,
          l: 105.82,
          c: 105.89,
          v: 1850130002
        },
        {
          t: 69300,
          o: 105.892,
          h: 105.907,
          l: 105.834,
          c: 105.846,
          v: 2257950000
        },
        {
          t: 70200,
          o: 105.845,
          h: 105.907,
          l: 105.831,
          c: 105.907,
          v: 2748910007
        },
        {
          t: 71100,
          o: 105.907,
          h: 105.946,
          l: 105.879,
          c: 105.898,
          v: 2430630000
        },
        {
          t: 72000,
          o: 105.897,
          h: 105.938,
          l: 105.894,
          c: 105.927,
          v: 904250000
        },
        {
          t: 72900,
          o: 105.928,
          h: 105.947,
          l: 105.918,
          c: 105.926,
          v: 722120000
        },
        {
          t: 73800,
          o: 105.926,
          h: 105.926,
          l: 105.902,
          c: 105.91,
          v: 399170000
        },
        {
          t: 74700,
          o: 105.911,
          h: 105.919,
          l: 105.878,
          c: 105.878,
          v: 648340000
        },
        {
          t: 75600,
          o: 105.878,
          h: 105.893,
          l: 105.816,
          c: 105.872,
          v: 2388130000
        },
        {
          t: 76500,
          o: 105.874,
          h: 105.909,
          l: 105.861,
          c: 105.87,
          v: 2155440000
        },
        {
          t: 77400,
          o: 105.871,
          h: 105.911,
          l: 105.858,
          c: 105.873,
          v: 1273570000
        },
        {
          t: 78300,
          o: 105.873,
          h: 105.883,
          l: 105.822,
          c: 105.847,
          v: 902360000
        },
        {
          t: 79200,
          o: 105.847,
          h: 105.879,
          l: 105.845,
          c: 105.871,
          v: 643100000
        },
        {
          t: 80100,
          o: 105.871,
          h: 105.887,
          l: 105.865,
          c: 105.874,
          v: 353670000
        },
        {
          t: 81000,
          o: 105.88,
          h: 105.883,
          l: 105.833,
          c: 105.844,
          v: 386180000
        },
        {
          t: 81900,
          o: 105.844,
          h: 105.856,
          l: 105.834,
          c: 105.834,
          v: 360710000
        },
        {
          t: 82800,
          o: 105.833,
          h: 105.848,
          l: 105.79,
          c: 105.802,
          v: 731470000
        },
        {
          t: 83700,
          o: 105.801,
          h: 105.828,
          l: 105.793,
          c: 105.802,
          v: 725420000
        },
        {
          t: 84600,
          o: 105.802,
          h: 105.803,
          l: 105.738,
          c: 105.765,
          v: 1386260000
        },
        {
          t: 85500,
          o: 105.765,
          h: 105.82,
          l: 105.744,
          c: 105.795,
          v: 961770000
        }
      ]
    },
    {
      d: 1522713600,
      b: [
        {
          t: 0,
          o: 105.796,
          h: 105.842,
          l: 105.72,
          c: 105.83,
          v: 1782430000
        },
        {
          t: 900,
          o: 105.83,
          h: 105.854,
          l: 105.728,
          c: 105.751,
          v: 2323860001
        },
        {
          t: 1800,
          o: 105.752,
          h: 105.785,
          l: 105.692,
          c: 105.777,
          v: 1988040000
        },
        {
          t: 2700,
          o: 105.779,
          h: 105.834,
          l: 105.751,
          c: 105.81,
          v: 1666850000
        },
        {
          t: 3600,
          o: 105.809,
          h: 105.887,
          l: 105.799,
          c: 105.842,
          v: 2180890001
        },
        {
          t: 4500,
          o: 105.841,
          h: 105.936,
          l: 105.836,
          c: 105.899,
          v: 2111080000
        },
        {
          t: 5400,
          o: 105.899,
          h: 105.935,
          l: 105.88,
          c: 105.922,
          v: 1874120000
        },
        {
          t: 6300,
          o: 105.923,
          h: 105.927,
          l: 105.86,
          c: 105.861,
          v: 1618440000
        },
        {
          t: 7200,
          o: 105.861,
          h: 105.883,
          l: 105.819,
          c: 105.838,
          v: 1504630000
        },
        {
          t: 8100,
          o: 105.837,
          h: 105.878,
          l: 105.832,
          c: 105.86,
          v: 1185890000
        },
        {
          t: 9000,
          o: 105.859,
          h: 105.953,
          l: 105.854,
          c: 105.938,
          v: 1584500000
        },
        {
          t: 9900,
          o: 105.937,
          h: 105.939,
          l: 105.899,
          c: 105.904,
          v: 785490000
        },
        {
          t: 10800,
          o: 105.904,
          h: 105.927,
          l: 105.888,
          c: 105.888,
          v: 977090000
        },
        {
          t: 11700,
          o: 105.891,
          h: 105.902,
          l: 105.868,
          c: 105.894,
          v: 1195600000
        },
        {
          t: 12600,
          o: 105.892,
          h: 105.976,
          l: 105.887,
          c: 105.934,
          v: 1822170000
        },
        {
          t: 13500,
          o: 105.933,
          h: 105.951,
          l: 105.92,
          c: 105.949,
          v: 803190000
        },
        {
          t: 14400,
          o: 105.948,
          h: 105.976,
          l: 105.932,
          c: 105.942,
          v: 1194140000
        },
        {
          t: 15300,
          o: 105.943,
          h: 105.975,
          l: 105.921,
          c: 105.975,
          v: 760960000
        },
        {
          t: 16200,
          o: 105.974,
          h: 105.975,
          l: 105.925,
          c: 105.931,
          v: 942860001
        },
        {
          t: 17100,
          o: 105.931,
          h: 105.961,
          l: 105.925,
          c: 105.936,
          v: 713170000
        },
        {
          t: 18000,
          o: 105.936,
          h: 105.976,
          l: 105.927,
          c: 105.936,
          v: 1005940000
        },
        {
          t: 18900,
          o: 105.941,
          h: 105.97,
          l: 105.902,
          c: 105.907,
          v: 1633930000
        },
        {
          t: 19800,
          o: 105.906,
          h: 105.922,
          l: 105.873,
          c: 105.919,
          v: 1433030001
        },
        {
          t: 20700,
          o: 105.923,
          h: 106.036,
          l: 105.922,
          c: 105.988,
          v: 1789100000
        },
        {
          t: 21600,
          o: 105.986,
          h: 106.002,
          l: 105.919,
          c: 105.956,
          v: 1477780001
        },
        {
          t: 22500,
          o: 105.955,
          h: 106.064,
          l: 105.955,
          c: 106.038,
          v: 1998320000
        },
        {
          t: 23400,
          o: 106.039,
          h: 106.07,
          l: 105.998,
          c: 106.008,
          v: 1842780000
        },
        {
          t: 24300,
          o: 106.008,
          h: 106.025,
          l: 105.758,
          c: 105.897,
          v: 2953679998
        },
        {
          t: 25200,
          o: 105.895,
          h: 106.026,
          l: 105.893,
          c: 105.998,
          v: 2895290000
        },
        {
          t: 26100,
          o: 105.998,
          h: 106.037,
          l: 105.946,
          c: 106.027,
          v: 2483090005
        },
        {
          t: 27000,
          o: 106.026,
          h: 106.092,
          l: 106.02,
          c: 106.058,
          v: 2224170000
        },
        {
          t: 27900,
          o: 106.058,
          h: 106.06,
          l: 106.014,
          c: 106.042,
          v: 2031310000
        },
        {
          t: 28800,
          o: 106.044,
          h: 106.191,
          l: 106.039,
          c: 106.183,
          v: 2618980000
        },
        {
          t: 29700,
          o: 106.183,
          h: 106.183,
          l: 106.127,
          c: 106.138,
          v: 1729720000
        },
        {
          t: 30600,
          o: 106.139,
          h: 106.167,
          l: 106.095,
          c: 106.128,
          v: 1979160000
        },
        {
          t: 31500,
          o: 106.128,
          h: 106.156,
          l: 106.097,
          c: 106.099,
          v: 1653620000
        },
        {
          t: 32400,
          o: 106.097,
          h: 106.106,
          l: 106.06,
          c: 106.097,
          v: 1584440000
        },
        {
          t: 33300,
          o: 106.095,
          h: 106.122,
          l: 106.056,
          c: 106.117,
          v: 1767229999
        },
        {
          t: 34200,
          o: 106.117,
          h: 106.19,
          l: 106.117,
          c: 106.187,
          v: 2007449999
        },
        {
          t: 35100,
          o: 106.188,
          h: 106.207,
          l: 106.161,
          c: 106.202,
          v: 1848400000
        },
        {
          t: 36000,
          o: 106.202,
          h: 106.237,
          l: 106.156,
          c: 106.157,
          v: 1641800000
        },
        {
          t: 36900,
          o: 106.157,
          h: 106.167,
          l: 106.129,
          c: 106.141,
          v: 1581300000
        },
        {
          t: 37800,
          o: 106.141,
          h: 106.237,
          l: 106.141,
          c: 106.234,
          v: 1693320000
        },
        {
          t: 38700,
          o: 106.235,
          h: 106.268,
          l: 106.197,
          c: 106.197,
          v: 1786600000
        },
        {
          t: 39600,
          o: 106.199,
          h: 106.23,
          l: 106.175,
          c: 106.216,
          v: 1916490000
        },
        {
          t: 40500,
          o: 106.215,
          h: 106.23,
          l: 106.16,
          c: 106.212,
          v: 2038199999
        },
        {
          t: 41400,
          o: 106.212,
          h: 106.273,
          l: 106.212,
          c: 106.25,
          v: 2145590000
        },
        {
          t: 42300,
          o: 106.25,
          h: 106.265,
          l: 106.23,
          c: 106.248,
          v: 1821210000
        },
        {
          t: 43200,
          o: 106.247,
          h: 106.299,
          l: 106.232,
          c: 106.242,
          v: 2010700000
        },
        {
          t: 44100,
          o: 106.245,
          h: 106.258,
          l: 106.202,
          c: 106.253,
          v: 1902020000
        },
        {
          t: 45000,
          o: 106.254,
          h: 106.283,
          l: 106.209,
          c: 106.243,
          v: 2077610000
        },
        {
          t: 45900,
          o: 106.245,
          h: 106.302,
          l: 106.225,
          c: 106.293,
          v: 2176600000
        },
        {
          t: 46800,
          o: 106.296,
          h: 106.405,
          l: 106.27,
          c: 106.388,
          v: 2489800000
        },
        {
          t: 47700,
          o: 106.389,
          h: 106.539,
          l: 106.362,
          c: 106.527,
          v: 3345520001
        },
        {
          t: 48600,
          o: 106.525,
          h: 106.575,
          l: 106.51,
          c: 106.531,
          v: 3991070000
        },
        {
          t: 49500,
          o: 106.53,
          h: 106.558,
          l: 106.423,
          c: 106.465,
          v: 3605480000
        },
        {
          t: 50400,
          o: 106.464,
          h: 106.484,
          l: 106.38,
          c: 106.405,
          v: 4236459999
        },
        {
          t: 51300,
          o: 106.407,
          h: 106.471,
          l: 106.388,
          c: 106.471,
          v: 3421920001
        },
        {
          t: 52200,
          o: 106.471,
          h: 106.551,
          l: 106.463,
          c: 106.534,
          v: 4066229998
        },
        {
          t: 53100,
          o: 106.536,
          h: 106.593,
          l: 106.525,
          c: 106.556,
          v: 4161210001
        },
        {
          t: 54000,
          o: 106.556,
          h: 106.578,
          l: 106.505,
          c: 106.523,
          v: 3286030000
        },
        {
          t: 54900,
          o: 106.523,
          h: 106.546,
          l: 106.495,
          c: 106.528,
          v: 2740909998
        },
        {
          t: 55800,
          o: 106.524,
          h: 106.54,
          l: 106.458,
          c: 106.517,
          v: 2763500000
        },
        {
          t: 56700,
          o: 106.518,
          h: 106.532,
          l: 106.438,
          c: 106.439,
          v: 2734690000
        },
        {
          t: 57600,
          o: 106.441,
          h: 106.498,
          l: 106.441,
          c: 106.455,
          v: 2262010000
        },
        {
          t: 58500,
          o: 106.455,
          h: 106.476,
          l: 106.391,
          c: 106.406,
          v: 2289380000
        },
        {
          t: 59400,
          o: 106.404,
          h: 106.479,
          l: 106.397,
          c: 106.463,
          v: 2144540001
        },
        {
          t: 60300,
          o: 106.463,
          h: 106.469,
          l: 106.418,
          c: 106.441,
          v: 1768380000
        },
        {
          t: 61200,
          o: 106.441,
          h: 106.456,
          l: 106.407,
          c: 106.425,
          v: 2343400002
        },
        {
          t: 62100,
          o: 106.425,
          h: 106.532,
          l: 106.424,
          c: 106.504,
          v: 2442530000
        },
        {
          t: 63000,
          o: 106.504,
          h: 106.544,
          l: 106.48,
          c: 106.544,
          v: 2151030000
        },
        {
          t: 63900,
          o: 106.544,
          h: 106.578,
          l: 106.518,
          c: 106.561,
          v: 2031060000
        },
        {
          t: 64800,
          o: 106.561,
          h: 106.572,
          l: 106.498,
          c: 106.501,
          v: 2412649999
        },
        {
          t: 65700,
          o: 106.5,
          h: 106.519,
          l: 106.473,
          c: 106.499,
          v: 2052000001
        },
        {
          t: 66600,
          o: 106.497,
          h: 106.497,
          l: 106.421,
          c: 106.421,
          v: 2024410000
        },
        {
          t: 67500,
          o: 106.421,
          h: 106.578,
          l: 106.421,
          c: 106.573,
          v: 3198700001
        },
        {
          t: 68400,
          o: 106.571,
          h: 106.654,
          l: 106.535,
          c: 106.565,
          v: 3851420000
        },
        {
          t: 69300,
          o: 106.562,
          h: 106.651,
          l: 106.554,
          c: 106.639,
          v: 3467560000
        },
        {
          t: 70200,
          o: 106.639,
          h: 106.641,
          l: 106.564,
          c: 106.598,
          v: 2566860001
        },
        {
          t: 71100,
          o: 106.597,
          h: 106.655,
          l: 106.596,
          c: 106.647,
          v: 2270490000
        },
        {
          t: 72000,
          o: 106.65,
          h: 106.65,
          l: 106.591,
          c: 106.614,
          v: 721390000
        },
        {
          t: 72900,
          o: 106.614,
          h: 106.631,
          l: 106.594,
          c: 106.63,
          v: 568770000
        },
        {
          t: 73800,
          o: 106.63,
          h: 106.653,
          l: 106.626,
          c: 106.632,
          v: 411080000
        },
        {
          t: 74700,
          o: 106.632,
          h: 106.632,
          l: 106.595,
          c: 106.607,
          v: 544130000
        },
        {
          t: 75600,
          o: 106.606,
          h: 106.654,
          l: 106.598,
          c: 106.649,
          v: 1215670000
        },
        {
          t: 76500,
          o: 106.649,
          h: 106.651,
          l: 106.629,
          c: 106.641,
          v: 982390000
        },
        {
          t: 77400,
          o: 106.641,
          h: 106.653,
          l: 106.637,
          c: 106.642,
          v: 767190000
        },
        {
          t: 78300,
          o: 106.642,
          h: 106.643,
          l: 106.591,
          c: 106.594,
          v: 1128380000
        },
        {
          t: 79200,
          o: 106.593,
          h: 106.618,
          l: 106.561,
          c: 106.563,
          v: 1220870000
        },
        {
          t: 80100,
          o: 106.563,
          h: 106.57,
          l: 106.518,
          c: 106.54,
          v: 868190000
        },
        {
          t: 81000,
          o: 106.538,
          h: 106.558,
          l: 106.504,
          c: 106.52,
          v: 329420000
        },
        {
          t: 81900,
          o: 106.52,
          h: 106.524,
          l: 106.494,
          c: 106.503,
          v: 398210000
        },
        {
          t: 82800,
          o: 106.505,
          h: 106.541,
          l: 106.505,
          c: 106.538,
          v: 566170000
        },
        {
          t: 83700,
          o: 106.538,
          h: 106.54,
          l: 106.521,
          c: 106.536,
          v: 411440000
        },
        {
          t: 84600,
          o: 106.535,
          h: 106.545,
          l: 106.491,
          c: 106.498,
          v: 894220000
        },
        {
          t: 85500,
          o: 106.499,
          h: 106.507,
          l: 106.425,
          c: 106.441,
          v: 1352740000
        }
      ]
    },
    {
      d: 1522800000,
      b: [
        {
          t: 0,
          o: 106.445,
          h: 106.461,
          l: 106.409,
          c: 106.452,
          v: 1947389999
        },
        {
          t: 900,
          o: 106.451,
          h: 106.481,
          l: 106.431,
          c: 106.464,
          v: 1552960001
        },
        {
          t: 1800,
          o: 106.464,
          h: 106.567,
          l: 106.464,
          c: 106.544,
          v: 2622010000
        },
        {
          t: 2700,
          o: 106.543,
          h: 106.59,
          l: 106.494,
          c: 106.523,
          v: 2833739999
        },
        {
          t: 3600,
          o: 106.525,
          h: 106.552,
          l: 106.431,
          c: 106.475,
          v: 2886009998
        },
        {
          t: 4500,
          o: 106.475,
          h: 106.542,
          l: 106.475,
          c: 106.531,
          v: 1749760000
        },
        {
          t: 5400,
          o: 106.532,
          h: 106.584,
          l: 106.481,
          c: 106.488,
          v: 2323320000
        },
        {
          t: 6300,
          o: 106.489,
          h: 106.528,
          l: 106.471,
          c: 106.514,
          v: 1907530000
        },
        {
          t: 7200,
          o: 106.514,
          h: 106.546,
          l: 106.476,
          c: 106.483,
          v: 1895070000
        },
        {
          t: 8100,
          o: 106.483,
          h: 106.514,
          l: 106.465,
          c: 106.499,
          v: 1230040000
        },
        {
          t: 9000,
          o: 106.499,
          h: 106.515,
          l: 106.474,
          c: 106.487,
          v: 1659240000
        },
        {
          t: 9900,
          o: 106.487,
          h: 106.494,
          l: 106.459,
          c: 106.476,
          v: 676610000
        },
        {
          t: 10800,
          o: 106.478,
          h: 106.523,
          l: 106.477,
          c: 106.503,
          v: 1151630000
        },
        {
          t: 11700,
          o: 106.502,
          h: 106.56,
          l: 106.497,
          c: 106.541,
          v: 1166750000
        },
        {
          t: 12600,
          o: 106.541,
          h: 106.542,
          l: 106.464,
          c: 106.496,
          v: 1548390000
        },
        {
          t: 13500,
          o: 106.497,
          h: 106.516,
          l: 106.472,
          c: 106.506,
          v: 1213890000
        },
        {
          t: 14400,
          o: 106.507,
          h: 106.515,
          l: 106.485,
          c: 106.509,
          v: 1090680000
        },
        {
          t: 15300,
          o: 106.508,
          h: 106.515,
          l: 106.487,
          c: 106.492,
          v: 739730000
        },
        {
          t: 16200,
          o: 106.49,
          h: 106.536,
          l: 106.489,
          c: 106.533,
          v: 891810000
        },
        {
          t: 17100,
          o: 106.533,
          h: 106.557,
          l: 106.508,
          c: 106.557,
          v: 1415220004
        },
        {
          t: 18000,
          o: 106.556,
          h: 106.564,
          l: 106.514,
          c: 106.559,
          v: 1304630001
        },
        {
          t: 18900,
          o: 106.561,
          h: 106.59,
          l: 106.549,
          c: 106.584,
          v: 1228050000
        },
        {
          t: 19800,
          o: 106.583,
          h: 106.591,
          l: 106.538,
          c: 106.55,
          v: 1595140000
        },
        {
          t: 20700,
          o: 106.549,
          h: 106.561,
          l: 106.496,
          c: 106.54,
          v: 1539220004
        },
        {
          t: 21600,
          o: 106.54,
          h: 106.579,
          l: 106.529,
          c: 106.57,
          v: 2041610001
        },
        {
          t: 22500,
          o: 106.57,
          h: 106.621,
          l: 106.559,
          c: 106.588,
          v: 1599189999
        },
        {
          t: 23400,
          o: 106.586,
          h: 106.636,
          l: 106.557,
          c: 106.635,
          v: 1351480000
        },
        {
          t: 24300,
          o: 106.635,
          h: 106.687,
          l: 106.611,
          c: 106.653,
          v: 1340250000
        },
        {
          t: 25200,
          o: 106.65,
          h: 106.65,
          l: 106.515,
          c: 106.522,
          v: 2773940002
        },
        {
          t: 26100,
          o: 106.523,
          h: 106.567,
          l: 106.499,
          c: 106.515,
          v: 2225460000
        },
        {
          t: 27000,
          o: 106.515,
          h: 106.516,
          l: 106.335,
          c: 106.381,
          v: 2341040000
        },
        {
          t: 27900,
          o: 106.381,
          h: 106.381,
          l: 106.193,
          c: 106.204,
          v: 4700160000
        },
        {
          t: 28800,
          o: 106.205,
          h: 106.213,
          l: 105.99,
          c: 106.049,
          v: 4829220000
        },
        {
          t: 29700,
          o: 106.049,
          h: 106.119,
          l: 106.036,
          c: 106.087,
          v: 3268850000
        },
        {
          t: 30600,
          o: 106.09,
          h: 106.181,
          l: 106.059,
          c: 106.169,
          v: 3365180000
        },
        {
          t: 31500,
          o: 106.169,
          h: 106.221,
          l: 106.115,
          c: 106.197,
          v: 2730570000
        },
        {
          t: 32400,
          o: 106.199,
          h: 106.21,
          l: 106.152,
          c: 106.167,
          v: 2504140000
        },
        {
          t: 33300,
          o: 106.169,
          h: 106.174,
          l: 106.106,
          c: 106.149,
          v: 2352510000
        },
        {
          t: 34200,
          o: 106.149,
          h: 106.155,
          l: 106.119,
          c: 106.147,
          v: 1786460000
        },
        {
          t: 35100,
          o: 106.147,
          h: 106.173,
          l: 106.124,
          c: 106.169,
          v: 1473210000
        },
        {
          t: 36000,
          o: 106.17,
          h: 106.176,
          l: 106.082,
          c: 106.104,
          v: 6831540021
        },
        {
          t: 36900,
          o: 106.105,
          h: 106.122,
          l: 105.992,
          c: 106.041,
          v: 3215599996
        },
        {
          t: 37800,
          o: 106.042,
          h: 106.144,
          l: 106.033,
          c: 106.134,
          v: 2328720001
        },
        {
          t: 38700,
          o: 106.133,
          h: 106.173,
          l: 106.123,
          c: 106.135,
          v: 2202590000
        },
        {
          t: 39600,
          o: 106.136,
          h: 106.181,
          l: 106.124,
          c: 106.136,
          v: 2042240000
        },
        {
          t: 40500,
          o: 106.136,
          h: 106.236,
          l: 106.129,
          c: 106.224,
          v: 2718800000
        },
        {
          t: 41400,
          o: 106.223,
          h: 106.247,
          l: 106.197,
          c: 106.207,
          v: 2144360000
        },
        {
          t: 42300,
          o: 106.207,
          h: 106.24,
          l: 106.162,
          c: 106.174,
          v: 2108910000
        },
        {
          t: 43200,
          o: 106.174,
          h: 106.242,
          l: 106.169,
          c: 106.212,
          v: 2467870000
        },
        {
          t: 44100,
          o: 106.211,
          h: 106.278,
          l: 106.121,
          c: 106.173,
          v: 3716540000
        },
        {
          t: 45000,
          o: 106.172,
          h: 106.232,
          l: 106.128,
          c: 106.218,
          v: 2842940001
        },
        {
          t: 45900,
          o: 106.218,
          h: 106.277,
          l: 106.198,
          c: 106.248,
          v: 2648680000
        },
        {
          t: 46800,
          o: 106.248,
          h: 106.268,
          l: 106.206,
          c: 106.252,
          v: 2108190000
        },
        {
          t: 47700,
          o: 106.25,
          h: 106.267,
          l: 106.188,
          c: 106.246,
          v: 2307710000
        },
        {
          t: 48600,
          o: 106.244,
          h: 106.356,
          l: 106.215,
          c: 106.325,
          v: 4413630000
        },
        {
          t: 49500,
          o: 106.319,
          h: 106.347,
          l: 106.262,
          c: 106.324,
          v: 3580330000
        },
        {
          t: 50400,
          o: 106.335,
          h: 106.35,
          l: 106.262,
          c: 106.309,
          v: 3529210000
        },
        {
          t: 51300,
          o: 106.31,
          h: 106.339,
          l: 106.271,
          c: 106.277,
          v: 3035540000
        },
        {
          t: 52200,
          o: 106.278,
          h: 106.308,
          l: 106.238,
          c: 106.302,
          v: 2862420000
        },
        {
          t: 53100,
          o: 106.302,
          h: 106.566,
          l: 106.299,
          c: 106.551,
          v: 3474270000
        },
        {
          t: 54000,
          o: 106.551,
          h: 106.563,
          l: 106.472,
          c: 106.484,
          v: 2852370000
        },
        {
          t: 54900,
          o: 106.484,
          h: 106.593,
          l: 106.481,
          c: 106.579,
          v: 2968840000
        },
        {
          t: 55800,
          o: 106.578,
          h: 106.628,
          l: 106.541,
          c: 106.55,
          v: 2676920001
        },
        {
          t: 56700,
          o: 106.55,
          h: 106.59,
          l: 106.517,
          c: 106.559,
          v: 2425740000
        },
        {
          t: 57600,
          o: 106.558,
          h: 106.65,
          l: 106.535,
          c: 106.622,
          v: 2207210001
        },
        {
          t: 58500,
          o: 106.621,
          h: 106.648,
          l: 106.589,
          c: 106.633,
          v: 1994820000
        },
        {
          t: 59400,
          o: 106.633,
          h: 106.647,
          l: 106.61,
          c: 106.634,
          v: 1889510001
        },
        {
          t: 60300,
          o: 106.634,
          h: 106.644,
          l: 106.578,
          c: 106.612,
          v: 1840170000
        },
        {
          t: 61200,
          o: 106.614,
          h: 106.623,
          l: 106.537,
          c: 106.551,
          v: 1992860000
        },
        {
          t: 62100,
          o: 106.551,
          h: 106.578,
          l: 106.509,
          c: 106.554,
          v: 2150370000
        },
        {
          t: 63000,
          o: 106.554,
          h: 106.589,
          l: 106.527,
          c: 106.561,
          v: 1662570000
        },
        {
          t: 63900,
          o: 106.561,
          h: 106.573,
          l: 106.51,
          c: 106.573,
          v: 1854650000
        },
        {
          t: 64800,
          o: 106.576,
          h: 106.649,
          l: 106.576,
          c: 106.649,
          v: 2033620000
        },
        {
          t: 65700,
          o: 106.65,
          h: 106.755,
          l: 106.644,
          c: 106.741,
          v: 2771769999
        },
        {
          t: 66600,
          o: 106.741,
          h: 106.799,
          l: 106.712,
          c: 106.759,
          v: 2577030002
        },
        {
          t: 67500,
          o: 106.758,
          h: 106.762,
          l: 106.695,
          c: 106.741,
          v: 2367700000
        },
        {
          t: 68400,
          o: 106.739,
          h: 106.746,
          l: 106.68,
          c: 106.683,
          v: 1625520000
        },
        {
          t: 69300,
          o: 106.683,
          h: 106.755,
          l: 106.68,
          c: 106.752,
          v: 2187510001
        },
        {
          t: 70200,
          o: 106.752,
          h: 106.82,
          l: 106.734,
          c: 106.78,
          v: 2310530000
        },
        {
          t: 71100,
          o: 106.783,
          h: 106.822,
          l: 106.783,
          c: 106.796,
          v: 2353059999
        },
        {
          t: 72000,
          o: 106.796,
          h: 106.846,
          l: 106.789,
          c: 106.837,
          v: 907320000
        },
        {
          t: 72900,
          o: 106.837,
          h: 106.846,
          l: 106.815,
          c: 106.826,
          v: 685110000
        },
        {
          t: 73800,
          o: 106.826,
          h: 106.833,
          l: 106.797,
          c: 106.804,
          v: 559040000
        },
        {
          t: 74700,
          o: 106.805,
          h: 106.823,
          l: 106.756,
          c: 106.773,
          v: 539430000
        },
        {
          t: 75600,
          o: 106.772,
          h: 106.774,
          l: 106.732,
          c: 106.742,
          v: 1331060000
        },
        {
          t: 76500,
          o: 106.745,
          h: 106.766,
          l: 106.735,
          c: 106.742,
          v: 1289210000
        },
        {
          t: 77400,
          o: 106.742,
          h: 106.747,
          l: 106.72,
          c: 106.733,
          v: 2096570000
        },
        {
          t: 78300,
          o: 106.733,
          h: 106.769,
          l: 106.731,
          c: 106.76,
          v: 709230000
        },
        {
          t: 79200,
          o: 106.759,
          h: 106.811,
          l: 106.749,
          c: 106.78,
          v: 717830000
        },
        {
          t: 80100,
          o: 106.781,
          h: 106.781,
          l: 106.735,
          c: 106.751,
          v: 317200000
        },
        {
          t: 81000,
          o: 106.749,
          h: 106.774,
          l: 106.729,
          c: 106.748,
          v: 353830000
        },
        {
          t: 81900,
          o: 106.748,
          h: 106.786,
          l: 106.743,
          c: 106.78,
          v: 320830000
        },
        {
          t: 82800,
          o: 106.78,
          h: 106.796,
          l: 106.741,
          c: 106.769,
          v: 664110000
        },
        {
          t: 83700,
          o: 106.768,
          h: 106.777,
          l: 106.745,
          c: 106.764,
          v: 385720000
        },
        {
          t: 84600,
          o: 106.763,
          h: 106.769,
          l: 106.746,
          c: 106.752,
          v: 444010000
        },
        {
          t: 85500,
          o: 106.753,
          h: 106.804,
          l: 106.745,
          c: 106.785,
          v: 1441210000
        }
      ]
    },
    {
      d: 1522886400,
      b: [
        {
          t: 0,
          o: 106.789,
          h: 106.847,
          l: 106.779,
          c: 106.842,
          v: 2318340000
        },
        {
          t: 900,
          o: 106.844,
          h: 106.849,
          l: 106.759,
          c: 106.775,
          v: 2212720000
        },
        {
          t: 1800,
          o: 106.775,
          h: 106.826,
          l: 106.739,
          c: 106.739,
          v: 2036910000
        },
        {
          t: 2700,
          o: 106.739,
          h: 106.877,
          l: 106.71,
          c: 106.867,
          v: 2646680000
        },
        {
          t: 3600,
          o: 106.864,
          h: 106.871,
          l: 106.776,
          c: 106.782,
          v: 2579310000
        },
        {
          t: 4500,
          o: 106.782,
          h: 106.879,
          l: 106.779,
          c: 106.873,
          v: 2172730000
        },
        {
          t: 5400,
          o: 106.873,
          h: 106.915,
          l: 106.843,
          c: 106.883,
          v: 1702059999
        },
        {
          t: 6300,
          o: 106.884,
          h: 106.972,
          l: 106.871,
          c: 106.96,
          v: 2465460000
        },
        {
          t: 7200,
          o: 106.959,
          h: 106.987,
          l: 106.93,
          c: 106.932,
          v: 1775480000
        },
        {
          t: 8100,
          o: 106.932,
          h: 106.955,
          l: 106.899,
          c: 106.914,
          v: 1642910000
        },
        {
          t: 9000,
          o: 106.914,
          h: 106.977,
          l: 106.914,
          c: 106.96,
          v: 1190360000
        },
        {
          t: 9900,
          o: 106.961,
          h: 106.978,
          l: 106.952,
          c: 106.975,
          v: 920490000
        },
        {
          t: 10800,
          o: 106.975,
          h: 106.992,
          l: 106.956,
          c: 106.968,
          v: 872449999
        },
        {
          t: 11700,
          o: 106.967,
          h: 106.968,
          l: 106.921,
          c: 106.95,
          v: 1055610000
        },
        {
          t: 12600,
          o: 106.948,
          h: 106.966,
          l: 106.906,
          c: 106.924,
          v: 1052230000
        },
        {
          t: 13500,
          o: 106.924,
          h: 106.936,
          l: 106.899,
          c: 106.919,
          v: 1760570000
        },
        {
          t: 14400,
          o: 106.919,
          h: 106.984,
          l: 106.918,
          c: 106.958,
          v: 1567730000
        },
        {
          t: 15300,
          o: 106.957,
          h: 107.023,
          l: 106.956,
          c: 106.97,
          v: 1834900000
        },
        {
          t: 16200,
          o: 106.97,
          h: 106.992,
          l: 106.902,
          c: 106.95,
          v: 1422240000
        },
        {
          t: 17100,
          o: 106.95,
          h: 106.951,
          l: 106.916,
          c: 106.936,
          v: 1145210000
        },
        {
          t: 18000,
          o: 106.936,
          h: 106.941,
          l: 106.894,
          c: 106.906,
          v: 1193720000
        },
        {
          t: 18900,
          o: 106.905,
          h: 106.917,
          l: 106.889,
          c: 106.912,
          v: 900310000
        },
        {
          t: 19800,
          o: 106.912,
          h: 106.945,
          l: 106.832,
          c: 106.847,
          v: 1848020000
        },
        {
          t: 20700,
          o: 106.848,
          h: 106.923,
          l: 106.837,
          c: 106.898,
          v: 1980500003
        },
        {
          t: 21600,
          o: 106.898,
          h: 106.944,
          l: 106.866,
          c: 106.942,
          v: 1993440000
        },
        {
          t: 22500,
          o: 106.942,
          h: 106.974,
          l: 106.922,
          c: 106.971,
          v: 1670280000
        },
        {
          t: 23400,
          o: 106.971,
          h: 107.079,
          l: 106.955,
          c: 107.059,
          v: 2253350000
        },
        {
          t: 24300,
          o: 107.059,
          h: 107.086,
          l: 107.031,
          c: 107.057,
          v: 1950830001
        },
        {
          t: 25200,
          o: 107.057,
          h: 107.13,
          l: 107.053,
          c: 107.102,
          v: 2010220000
        },
        {
          t: 26100,
          o: 107.102,
          h: 107.152,
          l: 107.081,
          c: 107.09,
          v: 2150300000
        },
        {
          t: 27000,
          o: 107.089,
          h: 107.143,
          l: 107.078,
          c: 107.122,
          v: 2761200001
        },
        {
          t: 27900,
          o: 107.12,
          h: 107.129,
          l: 107.056,
          c: 107.056,
          v: 1946890001
        },
        {
          t: 28800,
          o: 107.057,
          h: 107.075,
          l: 107.028,
          c: 107.05,
          v: 2297680000
        },
        {
          t: 29700,
          o: 107.049,
          h: 107.081,
          l: 107.039,
          c: 107.072,
          v: 1670730001
        },
        {
          t: 30600,
          o: 107.072,
          h: 107.11,
          l: 107.069,
          c: 107.088,
          v: 1471980001
        },
        {
          t: 31500,
          o: 107.088,
          h: 107.143,
          l: 107.068,
          c: 107.115,
          v: 1824380001
        },
        {
          t: 32400,
          o: 107.115,
          h: 107.127,
          l: 107.091,
          c: 107.102,
          v: 1822930000
        },
        {
          t: 33300,
          o: 107.103,
          h: 107.116,
          l: 107.038,
          c: 107.046,
          v: 1382400000
        },
        {
          t: 34200,
          o: 107.045,
          h: 107.049,
          l: 106.972,
          c: 106.98,
          v: 1742820001
        },
        {
          t: 35100,
          o: 106.98,
          h: 107.056,
          l: 106.951,
          c: 107.05,
          v: 1954919999
        },
        {
          t: 36000,
          o: 107.053,
          h: 107.061,
          l: 107.031,
          c: 107.058,
          v: 1891000000
        },
        {
          t: 36900,
          o: 107.058,
          h: 107.059,
          l: 107.006,
          c: 107.015,
          v: 2064300000
        },
        {
          t: 37800,
          o: 107.006,
          h: 107.016,
          l: 106.966,
          c: 106.98,
          v: 1828180000
        },
        {
          t: 38700,
          o: 106.98,
          h: 107.002,
          l: 106.969,
          c: 106.995,
          v: 1819980000
        },
        {
          t: 39600,
          o: 106.997,
          h: 107.014,
          l: 106.973,
          c: 107.002,
          v: 1678960000
        },
        {
          t: 40500,
          o: 107.003,
          h: 107.047,
          l: 106.999,
          c: 107.035,
          v: 1575140000
        },
        {
          t: 41400,
          o: 107.036,
          h: 107.094,
          l: 107.036,
          c: 107.076,
          v: 1563960000
        },
        {
          t: 42300,
          o: 107.074,
          h: 107.079,
          l: 107.013,
          c: 107.042,
          v: 1494319999
        },
        {
          t: 43200,
          o: 107.05,
          h: 107.081,
          l: 107.005,
          c: 107.065,
          v: 1652860000
        },
        {
          t: 44100,
          o: 107.065,
          h: 107.146,
          l: 107.031,
          c: 107.105,
          v: 2960170001
        },
        {
          t: 45000,
          o: 107.105,
          h: 107.137,
          l: 107.023,
          c: 107.067,
          v: 2958880000
        },
        {
          t: 45900,
          o: 107.068,
          h: 107.087,
          l: 107.028,
          c: 107.081,
          v: 2197930000
        },
        {
          t: 46800,
          o: 107.08,
          h: 107.111,
          l: 107.062,
          c: 107.078,
          v: 2359600000
        },
        {
          t: 47700,
          o: 107.077,
          h: 107.124,
          l: 107.067,
          c: 107.122,
          v: 2193330001
        },
        {
          t: 48600,
          o: 107.119,
          h: 107.122,
          l: 107.039,
          c: 107.05,
          v: 3325470000
        },
        {
          t: 49500,
          o: 107.049,
          h: 107.131,
          l: 107,
          c: 107.129,
          v: 2877220000
        },
        {
          t: 50400,
          o: 107.13,
          h: 107.238,
          l: 107.111,
          c: 107.217,
          v: 2987590001
        },
        {
          t: 51300,
          o: 107.217,
          h: 107.266,
          l: 107.202,
          c: 107.253,
          v: 2892030001
        },
        {
          t: 52200,
          o: 107.252,
          h: 107.4,
          l: 107.247,
          c: 107.397,
          v: 2941090005
        },
        {
          t: 53100,
          o: 107.395,
          h: 107.491,
          l: 107.378,
          c: 107.455,
          v: 3573530001
        },
        {
          t: 54000,
          o: 107.456,
          h: 107.462,
          l: 107.393,
          c: 107.408,
          v: 3031200000
        },
        {
          t: 54900,
          o: 107.409,
          h: 107.433,
          l: 107.394,
          c: 107.419,
          v: 2356070000
        },
        {
          t: 55800,
          o: 107.42,
          h: 107.478,
          l: 107.407,
          c: 107.459,
          v: 2113580000
        },
        {
          t: 56700,
          o: 107.459,
          h: 107.485,
          l: 107.429,
          c: 107.48,
          v: 1759040000
        },
        {
          t: 57600,
          o: 107.48,
          h: 107.482,
          l: 107.444,
          c: 107.45,
          v: 1569199999
        },
        {
          t: 58500,
          o: 107.448,
          h: 107.448,
          l: 107.34,
          c: 107.387,
          v: 2518780000
        },
        {
          t: 59400,
          o: 107.386,
          h: 107.421,
          l: 107.361,
          c: 107.406,
          v: 70606710839
        },
        {
          t: 60300,
          o: 107.405,
          h: 107.438,
          l: 107.394,
          c: 107.427,
          v: 1463260000
        },
        {
          t: 61200,
          o: 107.427,
          h: 107.452,
          l: 107.413,
          c: 107.436,
          v: 1253330000
        },
        {
          t: 62100,
          o: 107.436,
          h: 107.439,
          l: 107.387,
          c: 107.411,
          v: 1894250001
        },
        {
          t: 63000,
          o: 107.411,
          h: 107.433,
          l: 107.32,
          c: 107.342,
          v: 1945450000
        },
        {
          t: 63900,
          o: 107.342,
          h: 107.394,
          l: 107.337,
          c: 107.373,
          v: 1733400000
        },
        {
          t: 64800,
          o: 107.374,
          h: 107.426,
          l: 107.365,
          c: 107.401,
          v: 1799830000
        },
        {
          t: 65700,
          o: 107.403,
          h: 107.424,
          l: 107.388,
          c: 107.394,
          v: 1403930000
        },
        {
          t: 66600,
          o: 107.394,
          h: 107.445,
          l: 107.389,
          c: 107.44,
          v: 1151389999
        },
        {
          t: 67500,
          o: 107.44,
          h: 107.448,
          l: 107.42,
          c: 107.44,
          v: 1259430000
        },
        {
          t: 68400,
          o: 107.439,
          h: 107.457,
          l: 107.415,
          c: 107.439,
          v: 1161050000
        },
        {
          t: 69300,
          o: 107.44,
          h: 107.449,
          l: 107.409,
          c: 107.42,
          v: 909130000
        },
        {
          t: 70200,
          o: 107.42,
          h: 107.466,
          l: 107.379,
          c: 107.46,
          v: 1057750000
        },
        {
          t: 71100,
          o: 107.46,
          h: 107.489,
          l: 107.452,
          c: 107.465,
          v: 1286130000
        },
        {
          t: 72000,
          o: 107.467,
          h: 107.478,
          l: 107.371,
          c: 107.404,
          v: 1059340001
        },
        {
          t: 72900,
          o: 107.405,
          h: 107.405,
          l: 107.391,
          c: 107.396,
          v: 444080000
        },
        {
          t: 73800,
          o: 107.396,
          h: 107.407,
          l: 107.391,
          c: 107.404,
          v: 314270000
        },
        {
          t: 74700,
          o: 107.403,
          h: 107.408,
          l: 107.369,
          c: 107.381,
          v: 351280000
        },
        {
          t: 75600,
          o: 107.381,
          h: 107.41,
          l: 107.369,
          c: 107.379,
          v: 910070000
        },
        {
          t: 76500,
          o: 107.38,
          h: 107.41,
          l: 107.367,
          c: 107.394,
          v: 1347610000
        },
        {
          t: 77400,
          o: 107.397,
          h: 107.403,
          l: 107.367,
          c: 107.379,
          v: 859320000
        },
        {
          t: 78300,
          o: 107.379,
          h: 107.429,
          l: 107.378,
          c: 107.413,
          v: 1033690000
        },
        {
          t: 79200,
          o: 107.412,
          h: 107.431,
          l: 107.399,
          c: 107.43,
          v: 461830000
        },
        {
          t: 80100,
          o: 107.431,
          h: 107.435,
          l: 107.399,
          c: 107.429,
          v: 338340000
        },
        {
          t: 81000,
          o: 107.429,
          h: 107.429,
          l: 107.366,
          c: 107.366,
          v: 634120000
        },
        {
          t: 81900,
          o: 107.366,
          h: 107.377,
          l: 107.155,
          c: 107.156,
          v: 2486820001
        },
        {
          t: 82800,
          o: 107.156,
          h: 107.197,
          l: 107.075,
          c: 107.082,
          v: 3224980000
        },
        {
          t: 83700,
          o: 107.086,
          h: 107.124,
          l: 107.047,
          c: 107.078,
          v: 1958330001
        },
        {
          t: 84600,
          o: 107.078,
          h: 107.109,
          l: 107.029,
          c: 107.093,
          v: 2114240000
        },
        {
          t: 85500,
          o: 107.094,
          h: 107.135,
          l: 107,
          c: 107.106,
          v: 2215450000
        }
      ]
    },
    {
      d: 1522972800,
      b: [
        {
          t: 0,
          o: 107.105,
          h: 107.17,
          l: 107.064,
          c: 107.133,
          v: 3408380000
        },
        {
          t: 900,
          o: 107.135,
          h: 107.16,
          l: 107.064,
          c: 107.132,
          v: 2711190000
        },
        {
          t: 1800,
          o: 107.131,
          h: 107.156,
          l: 107.098,
          c: 107.15,
          v: 1976990000
        },
        {
          t: 2700,
          o: 107.15,
          h: 107.187,
          l: 107.118,
          c: 107.152,
          v: 2369250000
        },
        {
          t: 3600,
          o: 107.153,
          h: 107.183,
          l: 107.01,
          c: 107.048,
          v: 2942800001
        },
        {
          t: 4500,
          o: 107.046,
          h: 107.125,
          l: 106.994,
          c: 107.113,
          v: 2886720000
        },
        {
          t: 5400,
          o: 107.113,
          h: 107.156,
          l: 107.091,
          c: 107.127,
          v: 3088300001
        },
        {
          t: 6300,
          o: 107.126,
          h: 107.153,
          l: 107.105,
          c: 107.143,
          v: 2817700000
        },
        {
          t: 7200,
          o: 107.145,
          h: 107.181,
          l: 107.13,
          c: 107.169,
          v: 1982939993
        },
        {
          t: 8100,
          o: 107.17,
          h: 107.264,
          l: 107.143,
          c: 107.207,
          v: 2642900000
        },
        {
          t: 9000,
          o: 107.206,
          h: 107.211,
          l: 107.171,
          c: 107.183,
          v: 1608080001
        },
        {
          t: 9900,
          o: 107.183,
          h: 107.222,
          l: 107.175,
          c: 107.217,
          v: 1178830000
        },
        {
          t: 10800,
          o: 107.218,
          h: 107.244,
          l: 107.175,
          c: 107.2,
          v: 1416140000
        },
        {
          t: 11700,
          o: 107.2,
          h: 107.281,
          l: 107.196,
          c: 107.272,
          v: 1691920000
        },
        {
          t: 12600,
          o: 107.272,
          h: 107.285,
          l: 107.244,
          c: 107.266,
          v: 2171190001
        },
        {
          t: 13500,
          o: 107.263,
          h: 107.275,
          l: 107.213,
          c: 107.228,
          v: 1810100000
        },
        {
          t: 14400,
          o: 107.229,
          h: 107.284,
          l: 107.224,
          c: 107.252,
          v: 1462190000
        },
        {
          t: 15300,
          o: 107.252,
          h: 107.37,
          l: 107.244,
          c: 107.348,
          v: 2061379997
        },
        {
          t: 16200,
          o: 107.348,
          h: 107.439,
          l: 107.344,
          c: 107.394,
          v: 2322030000
        },
        {
          t: 17100,
          o: 107.394,
          h: 107.403,
          l: 107.319,
          c: 107.351,
          v: 2123280000
        },
        {
          t: 18000,
          o: 107.35,
          h: 107.381,
          l: 107.316,
          c: 107.349,
          v: 2212990001
        },
        {
          t: 18900,
          o: 107.35,
          h: 107.374,
          l: 107.306,
          c: 107.313,
          v: 2378430000
        },
        {
          t: 19800,
          o: 107.313,
          h: 107.314,
          l: 107.27,
          c: 107.292,
          v: 1915500004
        },
        {
          t: 20700,
          o: 107.291,
          h: 107.344,
          l: 107.268,
          c: 107.286,
          v: 1593770000
        },
        {
          t: 21600,
          o: 107.288,
          h: 107.367,
          l: 107.276,
          c: 107.346,
          v: 1844790000
        },
        {
          t: 22500,
          o: 107.345,
          h: 107.392,
          l: 107.328,
          c: 107.34,
          v: 2013649999
        },
        {
          t: 23400,
          o: 107.344,
          h: 107.382,
          l: 107.313,
          c: 107.376,
          v: 1932959998
        },
        {
          t: 24300,
          o: 107.375,
          h: 107.43,
          l: 107.364,
          c: 107.418,
          v: 1949440000
        },
        {
          t: 25200,
          o: 107.419,
          h: 107.431,
          l: 107.351,
          c: 107.379,
          v: 2489580000
        },
        {
          t: 26100,
          o: 107.381,
          h: 107.448,
          l: 107.371,
          c: 107.437,
          v: 1971940000
        },
        {
          t: 27000,
          o: 107.437,
          h: 107.46,
          l: 107.399,
          c: 107.403,
          v: 1676370000
        },
        {
          t: 27900,
          o: 107.403,
          h: 107.428,
          l: 107.37,
          c: 107.4,
          v: 1662410000
        },
        {
          t: 28800,
          o: 107.405,
          h: 107.443,
          l: 107.356,
          c: 107.371,
          v: 1721840000
        },
        {
          t: 29700,
          o: 107.371,
          h: 107.424,
          l: 107.352,
          c: 107.412,
          v: 1636930000
        },
        {
          t: 30600,
          o: 107.414,
          h: 107.429,
          l: 107.373,
          c: 107.402,
          v: 1095160000
        },
        {
          t: 31500,
          o: 107.401,
          h: 107.416,
          l: 107.371,
          c: 107.414,
          v: 1216400000
        },
        {
          t: 32400,
          o: 107.414,
          h: 107.422,
          l: 107.39,
          c: 107.404,
          v: 1081950000
        },
        {
          t: 33300,
          o: 107.403,
          h: 107.415,
          l: 107.365,
          c: 107.384,
          v: 1448090000
        },
        {
          t: 34200,
          o: 107.384,
          h: 107.387,
          l: 107.327,
          c: 107.348,
          v: 1265429999
        },
        {
          t: 35100,
          o: 107.346,
          h: 107.351,
          l: 107.323,
          c: 107.329,
          v: 1113360000
        },
        {
          t: 36000,
          o: 107.325,
          h: 107.393,
          l: 107.323,
          c: 107.34,
          v: 1429540001
        },
        {
          t: 36900,
          o: 107.34,
          h: 107.387,
          l: 107.328,
          c: 107.38,
          v: 1123670000
        },
        {
          t: 37800,
          o: 107.38,
          h: 107.402,
          l: 107.368,
          c: 107.384,
          v: 1037700000
        },
        {
          t: 38700,
          o: 107.385,
          h: 107.391,
          l: 107.353,
          c: 107.387,
          v: 1033060000
        },
        {
          t: 39600,
          o: 107.387,
          h: 107.392,
          l: 107.313,
          c: 107.329,
          v: 1328840000
        },
        {
          t: 40500,
          o: 107.33,
          h: 107.378,
          l: 107.31,
          c: 107.37,
          v: 1139110000
        },
        {
          t: 41400,
          o: 107.368,
          h: 107.371,
          l: 107.32,
          c: 107.354,
          v: 1236799999
        },
        {
          t: 42300,
          o: 107.354,
          h: 107.354,
          l: 107.307,
          c: 107.308,
          v: 1380889999
        },
        {
          t: 43200,
          o: 107.309,
          h: 107.365,
          l: 107.295,
          c: 107.341,
          v: 1780529999
        },
        {
          t: 44100,
          o: 107.341,
          h: 107.402,
          l: 107.324,
          c: 107.401,
          v: 1598050000
        },
        {
          t: 45000,
          o: 107.406,
          h: 107.406,
          l: 107.125,
          c: 107.298,
          v: 6135699999
        },
        {
          t: 45900,
          o: 107.299,
          h: 107.406,
          l: 107.204,
          c: 107.225,
          v: 3446519999
        },
        {
          t: 46800,
          o: 107.223,
          h: 107.248,
          l: 107.101,
          c: 107.134,
          v: 3716889999
        },
        {
          t: 47700,
          o: 107.135,
          h: 107.136,
          l: 106.988,
          c: 107.059,
          v: 3825850001
        },
        {
          t: 48600,
          o: 107.057,
          h: 107.185,
          l: 107.029,
          c: 107.164,
          v: 4026470000
        },
        {
          t: 49500,
          o: 107.163,
          h: 107.285,
          l: 107.144,
          c: 107.216,
          v: 3547280002
        },
        {
          t: 50400,
          o: 107.216,
          h: 107.252,
          l: 107.137,
          c: 107.14,
          v: 3217480000
        },
        {
          t: 51300,
          o: 107.14,
          h: 107.256,
          l: 107.129,
          c: 107.221,
          v: 2868320000
        },
        {
          t: 52200,
          o: 107.221,
          h: 107.222,
          l: 107.113,
          c: 107.145,
          v: 3076090000
        },
        {
          t: 53100,
          o: 107.144,
          h: 107.177,
          l: 107.071,
          c: 107.119,
          v: 3625960000
        },
        {
          t: 54000,
          o: 107.118,
          h: 107.118,
          l: 106.952,
          c: 107.063,
          v: 3785390001
        },
        {
          t: 54900,
          o: 107.064,
          h: 107.088,
          l: 106.948,
          c: 107.007,
          v: 3120500000
        },
        {
          t: 55800,
          o: 107.009,
          h: 107.099,
          l: 107.009,
          c: 107.023,
          v: 2820490000
        },
        {
          t: 56700,
          o: 107.023,
          h: 107.061,
          l: 106.978,
          c: 107.044,
          v: 2994520000
        },
        {
          t: 57600,
          o: 107.044,
          h: 107.103,
          l: 107.032,
          c: 107.039,
          v: 2437750000
        },
        {
          t: 58500,
          o: 107.04,
          h: 107.069,
          l: 106.996,
          c: 107.063,
          v: 2275410000
        },
        {
          t: 59400,
          o: 107.062,
          h: 107.154,
          l: 107.041,
          c: 107.116,
          v: 2886900001
        },
        {
          t: 60300,
          o: 107.115,
          h: 107.127,
          l: 107.08,
          c: 107.108,
          v: 2395470000
        },
        {
          t: 61200,
          o: 107.108,
          h: 107.108,
          l: 107.026,
          c: 107.064,
          v: 2755780001
        },
        {
          t: 62100,
          o: 107.063,
          h: 107.081,
          l: 107.035,
          c: 107.069,
          v: 2274400000
        },
        {
          t: 63000,
          o: 107.067,
          h: 107.11,
          l: 106.99,
          c: 107.007,
          v: 2809610000
        },
        {
          t: 63900,
          o: 107.007,
          h: 107.015,
          l: 106.83,
          c: 106.852,
          v: 3652859998
        },
        {
          t: 64800,
          o: 106.851,
          h: 106.908,
          l: 106.824,
          c: 106.87,
          v: 3040139998
        },
        {
          t: 65700,
          o: 106.868,
          h: 106.93,
          l: 106.851,
          c: 106.889,
          v: 2386170000
        },
        {
          t: 66600,
          o: 106.886,
          h: 106.923,
          l: 106.844,
          c: 106.899,
          v: 2796450000
        },
        {
          t: 67500,
          o: 106.899,
          h: 106.903,
          l: 106.837,
          c: 106.841,
          v: 2780450000
        },
        {
          t: 68400,
          o: 106.84,
          h: 106.893,
          l: 106.775,
          c: 106.893,
          v: 3085810000
        },
        {
          t: 69300,
          o: 106.894,
          h: 106.922,
          l: 106.853,
          c: 106.859,
          v: 3471190000
        },
        {
          t: 70200,
          o: 106.86,
          h: 106.891,
          l: 106.847,
          c: 106.862,
          v: 2926040000
        },
        {
          t: 71100,
          o: 106.861,
          h: 106.903,
          l: 106.846,
          c: 106.87,
          v: 2949690000
        },
        {
          t: 72000,
          o: 106.87,
          h: 106.893,
          l: 106.844,
          c: 106.887,
          v: 1303919993
        },
        {
          t: 72900,
          o: 106.888,
          h: 106.924,
          l: 106.886,
          c: 106.914,
          v: 492680000
        },
        {
          t: 73800,
          o: 106.915,
          h: 106.975,
          l: 106.908,
          c: 106.975,
          v: 661710000
        },
        {
          t: 74700,
          o: 106.976,
          h: 106.976,
          l: 106.902,
          c: 106.902,
          v: 590890000
        }
      ]
    },
    {
      d: 1523145600,
      b: [
        {
          t: 75600,
          o: 106.931,
          h: 106.947,
          l: 106.915,
          c: 106.937,
          v: 39120000
        },
        {
          t: 76500,
          o: 106.941,
          h: 106.948,
          l: 106.905,
          c: 106.906,
          v: 59680000
        },
        {
          t: 77400,
          o: 106.908,
          h: 106.916,
          l: 106.889,
          c: 106.91,
          v: 63000000
        },
        {
          t: 78300,
          o: 106.909,
          h: 106.919,
          l: 106.904,
          c: 106.913,
          v: 45840000
        },
        {
          t: 79200,
          o: 106.912,
          h: 107.039,
          l: 106.912,
          c: 107.018,
          v: 1102580000
        },
        {
          t: 80100,
          o: 107.018,
          h: 107.039,
          l: 106.997,
          c: 107.023,
          v: 899560000
        },
        {
          t: 81000,
          o: 107.025,
          h: 107.028,
          l: 106.951,
          c: 106.951,
          v: 927130000
        },
        {
          t: 81900,
          o: 106.952,
          h: 106.976,
          l: 106.893,
          c: 106.893,
          v: 764680000
        },
        {
          t: 82800,
          o: 106.893,
          h: 106.9,
          l: 106.802,
          c: 106.889,
          v: 2138720000
        },
        {
          t: 83700,
          o: 106.89,
          h: 106.925,
          l: 106.879,
          c: 106.922,
          v: 1206470002
        },
        {
          t: 84600,
          o: 106.922,
          h: 106.993,
          l: 106.916,
          c: 106.986,
          v: 1167330000
        },
        {
          t: 85500,
          o: 106.986,
          h: 106.998,
          l: 106.939,
          c: 106.958,
          v: 1772970000
        }
      ]
    },
    {
      d: 1523232000,
      b: [
        {
          t: 0,
          o: 106.956,
          h: 107.015,
          l: 106.934,
          c: 106.967,
          v: 2197200000
        },
        {
          t: 900,
          o: 106.967,
          h: 107.007,
          l: 106.912,
          c: 106.924,
          v: 1939140000
        },
        {
          t: 1800,
          o: 106.923,
          h: 106.969,
          l: 106.868,
          c: 106.924,
          v: 1737600000
        },
        {
          t: 2700,
          o: 106.923,
          h: 106.986,
          l: 106.856,
          c: 106.887,
          v: 2162000000
        },
        {
          t: 3600,
          o: 106.887,
          h: 106.906,
          l: 106.842,
          c: 106.901,
          v: 2041920000
        },
        {
          t: 4500,
          o: 106.904,
          h: 106.966,
          l: 106.903,
          c: 106.92,
          v: 2075910000
        },
        {
          t: 5400,
          o: 106.92,
          h: 106.92,
          l: 106.838,
          c: 106.866,
          v: 2507510000
        },
        {
          t: 6300,
          o: 106.866,
          h: 106.92,
          l: 106.858,
          c: 106.918,
          v: 2339590004
        },
        {
          t: 7200,
          o: 106.918,
          h: 106.935,
          l: 106.896,
          c: 106.93,
          v: 1535029999
        },
        {
          t: 8100,
          o: 106.929,
          h: 106.931,
          l: 106.899,
          c: 106.927,
          v: 940080000
        },
        {
          t: 9000,
          o: 106.927,
          h: 106.934,
          l: 106.908,
          c: 106.909,
          v: 873550000
        },
        {
          t: 9900,
          o: 106.908,
          h: 106.966,
          l: 106.893,
          c: 106.957,
          v: 866180000
        },
        {
          t: 10800,
          o: 106.961,
          h: 106.973,
          l: 106.937,
          c: 106.966,
          v: 1040240001
        },
        {
          t: 11700,
          o: 106.966,
          h: 107.016,
          l: 106.965,
          c: 106.994,
          v: 1320050000
        },
        {
          t: 12600,
          o: 106.996,
          h: 107.067,
          l: 106.994,
          c: 107.019,
          v: 2004150000
        },
        {
          t: 13500,
          o: 107.019,
          h: 107.053,
          l: 106.999,
          c: 107.035,
          v: 1493300000
        },
        {
          t: 14400,
          o: 107.035,
          h: 107.048,
          l: 107.01,
          c: 107.023,
          v: 3537089998
        },
        {
          t: 15300,
          o: 107.024,
          h: 107.032,
          l: 107.001,
          c: 107.005,
          v: 922890000
        },
        {
          t: 16200,
          o: 107.006,
          h: 107.018,
          l: 106.999,
          c: 107.013,
          v: 946730002
        },
        {
          t: 17100,
          o: 107.013,
          h: 107.037,
          l: 107.009,
          c: 107.017,
          v: 985140000
        },
        {
          t: 18000,
          o: 107.018,
          h: 107.044,
          l: 107.001,
          c: 107.021,
          v: 1442560000
        },
        {
          t: 18900,
          o: 107.021,
          h: 107.027,
          l: 106.969,
          c: 106.975,
          v: 1105480000
        },
        {
          t: 19800,
          o: 106.975,
          h: 106.989,
          l: 106.954,
          c: 106.985,
          v: 1552700000
        },
        {
          t: 20700,
          o: 106.986,
          h: 107.002,
          l: 106.97,
          c: 106.979,
          v: 1399230000
        },
        {
          t: 21600,
          o: 106.981,
          h: 107.052,
          l: 106.963,
          c: 107,
          v: 2025940001
        },
        {
          t: 22500,
          o: 107,
          h: 107.098,
          l: 107,
          c: 107.054,
          v: 1713400000
        },
        {
          t: 23400,
          o: 107.054,
          h: 107.129,
          l: 107.054,
          c: 107.11,
          v: 1583480004
        },
        {
          t: 24300,
          o: 107.109,
          h: 107.129,
          l: 107.073,
          c: 107.097,
          v: 1706430000
        },
        {
          t: 25200,
          o: 107.097,
          h: 107.121,
          l: 106.955,
          c: 107.029,
          v: 3622280000
        },
        {
          t: 26100,
          o: 107.029,
          h: 107.104,
          l: 107.013,
          c: 107.1,
          v: 2448420002
        },
        {
          t: 27000,
          o: 107.101,
          h: 107.146,
          l: 107.057,
          c: 107.059,
          v: 2218150000
        },
        {
          t: 27900,
          o: 107.06,
          h: 107.09,
          l: 107.046,
          c: 107.088,
          v: 1734050000
        },
        {
          t: 28800,
          o: 107.087,
          h: 107.126,
          l: 107.075,
          c: 107.088,
          v: 1540290000
        },
        {
          t: 29700,
          o: 107.089,
          h: 107.142,
          l: 107.089,
          c: 107.139,
          v: 1557410000
        },
        {
          t: 30600,
          o: 107.137,
          h: 107.154,
          l: 107.11,
          c: 107.125,
          v: 1937000000
        },
        {
          t: 31500,
          o: 107.127,
          h: 107.135,
          l: 107.066,
          c: 107.088,
          v: 4378619970
        },
        {
          t: 32400,
          o: 107.086,
          h: 107.098,
          l: 107.073,
          c: 107.098,
          v: 1462640000
        },
        {
          t: 33300,
          o: 107.097,
          h: 107.131,
          l: 107.081,
          c: 107.114,
          v: 1261640000
        },
        {
          t: 34200,
          o: 107.113,
          h: 107.156,
          l: 107.107,
          c: 107.133,
          v: 1268110000
        },
        {
          t: 35100,
          o: 107.133,
          h: 107.149,
          l: 107.108,
          c: 107.143,
          v: 765240000
        },
        {
          t: 36000,
          o: 107.143,
          h: 107.203,
          l: 107.136,
          c: 107.168,
          v: 1318769999
        },
        {
          t: 36900,
          o: 107.167,
          h: 107.195,
          l: 107.14,
          c: 107.163,
          v: 1054300000
        },
        {
          t: 37800,
          o: 107.163,
          h: 107.163,
          l: 107.105,
          c: 107.139,
          v: 1253830000
        },
        {
          t: 38700,
          o: 107.137,
          h: 107.158,
          l: 107.094,
          c: 107.1,
          v: 23916440257
        },
        {
          t: 39600,
          o: 107.1,
          h: 107.12,
          l: 107.065,
          c: 107.115,
          v: 1440150000
        },
        {
          t: 40500,
          o: 107.114,
          h: 107.139,
          l: 107.103,
          c: 107.108,
          v: 1543220000
        },
        {
          t: 41400,
          o: 107.109,
          h: 107.122,
          l: 107.069,
          c: 107.082,
          v: 1289250000
        },
        {
          t: 42300,
          o: 107.082,
          h: 107.086,
          l: 107.038,
          c: 107.069,
          v: 1254470000
        },
        {
          t: 43200,
          o: 107.071,
          h: 107.107,
          l: 106.99,
          c: 107.011,
          v: 2033540001
        },
        {
          t: 44100,
          o: 107.011,
          h: 107.033,
          l: 106.964,
          c: 106.992,
          v: 1662750000
        },
        {
          t: 45000,
          o: 106.992,
          h: 107.029,
          l: 106.964,
          c: 106.971,
          v: 1545940000
        },
        {
          t: 45900,
          o: 106.97,
          h: 107.007,
          l: 106.965,
          c: 106.965,
          v: 1392160000
        },
        {
          t: 46800,
          o: 106.965,
          h: 106.987,
          l: 106.901,
          c: 106.921,
          v: 1855810000
        },
        {
          t: 47700,
          o: 106.921,
          h: 106.972,
          l: 106.909,
          c: 106.942,
          v: 1539020000
        },
        {
          t: 48600,
          o: 106.945,
          h: 107.001,
          l: 106.932,
          c: 106.979,
          v: 2995070000
        },
        {
          t: 49500,
          o: 106.98,
          h: 107.037,
          l: 106.938,
          c: 107.004,
          v: 2985080001
        },
        {
          t: 50400,
          o: 107.003,
          h: 107.058,
          l: 106.98,
          c: 106.98,
          v: 2273820001
        },
        {
          t: 51300,
          o: 106.982,
          h: 107.065,
          l: 106.974,
          c: 107.041,
          v: 2544280000
        },
        {
          t: 52200,
          o: 107.04,
          h: 107.106,
          l: 107.037,
          c: 107.038,
          v: 2403990000
        },
        {
          t: 53100,
          o: 107.037,
          h: 107.101,
          l: 107.021,
          c: 107.037,
          v: 2230380000
        },
        {
          t: 54000,
          o: 107.038,
          h: 107.054,
          l: 107.011,
          c: 107.04,
          v: 2201139998
        },
        {
          t: 54900,
          o: 107.04,
          h: 107.085,
          l: 107.021,
          c: 107.03,
          v: 1826620001
        },
        {
          t: 55800,
          o: 107.031,
          h: 107.095,
          l: 107.029,
          c: 107.086,
          v: 1902070000
        },
        {
          t: 56700,
          o: 107.087,
          h: 107.109,
          l: 107.046,
          c: 107.068,
          v: 2044760000
        },
        {
          t: 57600,
          o: 107.067,
          h: 107.12,
          l: 107.036,
          c: 107.103,
          v: 2067110000
        },
        {
          t: 58500,
          o: 107.103,
          h: 107.104,
          l: 107.046,
          c: 107.052,
          v: 1892399995
        },
        {
          t: 59400,
          o: 107.051,
          h: 107.051,
          l: 106.906,
          c: 106.954,
          v: 1913299999
        },
        {
          t: 60300,
          o: 106.953,
          h: 106.964,
          l: 106.927,
          c: 106.937,
          v: 1819900000
        },
        {
          t: 61200,
          o: 106.938,
          h: 106.961,
          l: 106.923,
          c: 106.941,
          v: 1326110000
        },
        {
          t: 62100,
          o: 106.941,
          h: 106.95,
          l: 106.921,
          c: 106.943,
          v: 1550620000
        },
        {
          t: 63000,
          o: 106.944,
          h: 106.95,
          l: 106.928,
          c: 106.939,
          v: 1570650000
        },
        {
          t: 63900,
          o: 106.94,
          h: 106.948,
          l: 106.912,
          c: 106.916,
          v: 1387370002
        },
        {
          t: 64800,
          o: 106.915,
          h: 106.919,
          l: 106.832,
          c: 106.9,
          v: 2114850009
        },
        {
          t: 65700,
          o: 106.898,
          h: 106.9,
          l: 106.837,
          c: 106.854,
          v: 1713330000
        },
        {
          t: 66600,
          o: 106.853,
          h: 106.871,
          l: 106.75,
          c: 106.753,
          v: 1693800001
        },
        {
          t: 67500,
          o: 106.753,
          h: 106.796,
          l: 106.753,
          c: 106.775,
          v: 1928450001
        },
        {
          t: 68400,
          o: 106.775,
          h: 106.778,
          l: 106.736,
          c: 106.739,
          v: 1964920000
        },
        {
          t: 69300,
          o: 106.739,
          h: 106.769,
          l: 106.69,
          c: 106.76,
          v: 2316800000
        },
        {
          t: 70200,
          o: 106.761,
          h: 106.778,
          l: 106.728,
          c: 106.769,
          v: 12307859948
        },
        {
          t: 71100,
          o: 106.769,
          h: 106.775,
          l: 106.616,
          c: 106.673,
          v: 2908780000
        },
        {
          t: 72000,
          o: 106.673,
          h: 106.72,
          l: 106.646,
          c: 106.72,
          v: 1035990000
        },
        {
          t: 72900,
          o: 106.72,
          h: 106.745,
          l: 106.709,
          c: 106.745,
          v: 342610000
        },
        {
          t: 73800,
          o: 106.744,
          h: 106.76,
          l: 106.738,
          c: 106.76,
          v: 322950000
        },
        {
          t: 74700,
          o: 106.759,
          h: 106.788,
          l: 106.74,
          c: 106.752,
          v: 633710000
        },
        {
          t: 75600,
          o: 106.757,
          h: 106.798,
          l: 106.708,
          c: 106.776,
          v: 1981780000
        },
        {
          t: 76500,
          o: 106.772,
          h: 106.776,
          l: 106.728,
          c: 106.75,
          v: 995270000
        },
        {
          t: 77400,
          o: 106.749,
          h: 106.766,
          l: 106.735,
          c: 106.742,
          v: 609690000
        },
        {
          t: 78300,
          o: 106.742,
          h: 106.793,
          l: 106.741,
          c: 106.786,
          v: 954120000
        },
        {
          t: 79200,
          o: 106.786,
          h: 106.786,
          l: 106.715,
          c: 106.726,
          v: 848990000
        },
        {
          t: 80100,
          o: 106.725,
          h: 106.772,
          l: 106.709,
          c: 106.76,
          v: 443250000
        },
        {
          t: 81000,
          o: 106.761,
          h: 106.786,
          l: 106.744,
          c: 106.761,
          v: 598290000
        },
        {
          t: 81900,
          o: 106.761,
          h: 106.761,
          l: 106.724,
          c: 106.733,
          v: 591400000
        },
        {
          t: 82800,
          o: 106.732,
          h: 106.739,
          l: 106.703,
          c: 106.725,
          v: 728570000
        },
        {
          t: 83700,
          o: 106.729,
          h: 106.757,
          l: 106.714,
          c: 106.725,
          v: 430620000
        },
        {
          t: 84600,
          o: 106.724,
          h: 106.749,
          l: 106.709,
          c: 106.733,
          v: 663500000
        },
        {
          t: 85500,
          o: 106.735,
          h: 106.759,
          l: 106.711,
          c: 106.755,
          v: 1082810000
        }
      ]
    },
    {
      d: 1523318400,
      b: [
        {
          t: 0,
          o: 106.753,
          h: 106.753,
          l: 106.618,
          c: 106.681,
          v: 2396740000
        },
        {
          t: 900,
          o: 106.68,
          h: 106.692,
          l: 106.629,
          c: 106.67,
          v: 1914690000
        },
        {
          t: 1800,
          o: 106.669,
          h: 106.738,
          l: 106.667,
          c: 106.715,
          v: 1519660000
        },
        {
          t: 2700,
          o: 106.714,
          h: 106.736,
          l: 106.677,
          c: 106.684,
          v: 1446950000
        },
        {
          t: 3600,
          o: 106.683,
          h: 106.796,
          l: 106.679,
          c: 106.77,
          v: 1438350000
        },
        {
          t: 4500,
          o: 106.769,
          h: 106.859,
          l: 106.761,
          c: 106.845,
          v: 1908650000
        },
        {
          t: 5400,
          o: 106.844,
          h: 106.848,
          l: 106.785,
          c: 106.84,
          v: 2848810002
        },
        {
          t: 6300,
          o: 106.84,
          h: 106.947,
          l: 106.839,
          c: 106.929,
          v: 3158290004
        },
        {
          t: 7200,
          o: 106.93,
          h: 106.998,
          l: 106.879,
          c: 106.987,
          v: 3028030000
        },
        {
          t: 8100,
          o: 106.987,
          h: 107.242,
          l: 106.985,
          c: 107.198,
          v: 4337900004
        },
        {
          t: 9000,
          o: 107.199,
          h: 107.231,
          l: 107.154,
          c: 107.17,
          v: 3582280000
        },
        {
          t: 9900,
          o: 107.17,
          h: 107.203,
          l: 107.134,
          c: 107.174,
          v: 2031020000
        },
        {
          t: 10800,
          o: 107.173,
          h: 107.188,
          l: 107.099,
          c: 107.099,
          v: 2041940000
        },
        {
          t: 11700,
          o: 107.098,
          h: 107.15,
          l: 107.091,
          c: 107.112,
          v: 1586550000
        },
        {
          t: 12600,
          o: 107.113,
          h: 107.119,
          l: 107.053,
          c: 107.078,
          v: 2203800000
        },
        {
          t: 13500,
          o: 107.083,
          h: 107.114,
          l: 107.061,
          c: 107.087,
          v: 1999260000
        },
        {
          t: 14400,
          o: 107.086,
          h: 107.124,
          l: 107.078,
          c: 107.119,
          v: 1545630000
        },
        {
          t: 15300,
          o: 107.119,
          h: 107.123,
          l: 107.082,
          c: 107.102,
          v: 1620279999
        },
        {
          t: 16200,
          o: 107.102,
          h: 107.12,
          l: 107.083,
          c: 107.102,
          v: 1149540000
        },
        {
          t: 17100,
          o: 107.101,
          h: 107.136,
          l: 107.098,
          c: 107.117,
          v: 1141250000
        },
        {
          t: 18000,
          o: 107.116,
          h: 107.137,
          l: 107.096,
          c: 107.133,
          v: 1805140000
        },
        {
          t: 18900,
          o: 107.136,
          h: 107.173,
          l: 107.123,
          c: 107.161,
          v: 1554270000
        },
        {
          t: 19800,
          o: 107.161,
          h: 107.184,
          l: 107.137,
          c: 107.162,
          v: 1302690000
        },
        {
          t: 20700,
          o: 107.162,
          h: 107.164,
          l: 107.128,
          c: 107.142,
          v: 1011360000
        },
        {
          t: 21600,
          o: 107.14,
          h: 107.205,
          l: 107.123,
          c: 107.192,
          v: 1361970000
        },
        {
          t: 22500,
          o: 107.191,
          h: 107.23,
          l: 107.183,
          c: 107.219,
          v: 1364840000
        },
        {
          t: 23400,
          o: 107.218,
          h: 107.228,
          l: 107.131,
          c: 107.139,
          v: 1753450000
        },
        {
          t: 24300,
          o: 107.138,
          h: 107.184,
          l: 107.136,
          c: 107.173,
          v: 1392370000
        },
        {
          t: 25200,
          o: 107.173,
          h: 107.173,
          l: 107.068,
          c: 107.108,
          v: 2210040000
        },
        {
          t: 26100,
          o: 107.108,
          h: 107.114,
          l: 107.022,
          c: 107.058,
          v: 2094770000
        },
        {
          t: 27000,
          o: 107.058,
          h: 107.061,
          l: 106.96,
          c: 107.021,
          v: 2718489998
        },
        {
          t: 27900,
          o: 107.021,
          h: 107.06,
          l: 106.805,
          c: 106.9,
          v: 2510910000
        },
        {
          t: 28800,
          o: 106.9,
          h: 106.992,
          l: 106.844,
          c: 106.982,
          v: 3026100000
        },
        {
          t: 29700,
          o: 106.982,
          h: 107.056,
          l: 106.978,
          c: 107.049,
          v: 2005240000
        },
        {
          t: 30600,
          o: 107.049,
          h: 107.063,
          l: 107.026,
          c: 107.028,
          v: 1420440000
        },
        {
          t: 31500,
          o: 107.029,
          h: 107.043,
          l: 106.972,
          c: 106.983,
          v: 1628810000
        },
        {
          t: 32400,
          o: 106.982,
          h: 107.035,
          l: 106.982,
          c: 106.994,
          v: 1454900000
        },
        {
          t: 33300,
          o: 106.994,
          h: 107.024,
          l: 106.973,
          c: 107,
          v: 1483060000
        },
        {
          t: 34200,
          o: 107,
          h: 107.013,
          l: 106.975,
          c: 107.009,
          v: 1217200000
        },
        {
          t: 35100,
          o: 107.01,
          h: 107.031,
          l: 107,
          c: 107.013,
          v: 1023750000
        },
        {
          t: 36000,
          o: 107.013,
          h: 107.021,
          l: 106.977,
          c: 106.988,
          v: 1201450000
        },
        {
          t: 36900,
          o: 106.988,
          h: 107.037,
          l: 106.976,
          c: 107.026,
          v: 870260000
        },
        {
          t: 37800,
          o: 107.027,
          h: 107.088,
          l: 107.016,
          c: 107.077,
          v: 1325440000
        },
        {
          t: 38700,
          o: 107.077,
          h: 107.091,
          l: 107.047,
          c: 107.047,
          v: 1406170000
        },
        {
          t: 39600,
          o: 107.047,
          h: 107.051,
          l: 107.012,
          c: 107.031,
          v: 1257310000
        },
        {
          t: 40500,
          o: 107.031,
          h: 107.036,
          l: 107.007,
          c: 107.011,
          v: 1129400000
        },
        {
          t: 41400,
          o: 107.012,
          h: 107.03,
          l: 106.932,
          c: 107.003,
          v: 3739110000
        },
        {
          t: 42300,
          o: 107.002,
          h: 107.07,
          l: 106.999,
          c: 107.057,
          v: 2078930000
        },
        {
          t: 43200,
          o: 107.057,
          h: 107.057,
          l: 107,
          c: 107.007,
          v: 1546340000
        },
        {
          t: 44100,
          o: 107.007,
          h: 107.029,
          l: 106.974,
          c: 107.025,
          v: 1494110000
        },
        {
          t: 45000,
          o: 107.023,
          h: 107.155,
          l: 107.02,
          c: 107.129,
          v: 3220979997
        },
        {
          t: 45900,
          o: 107.129,
          h: 107.146,
          l: 107.086,
          c: 107.088,
          v: 2142430000
        },
        {
          t: 46800,
          o: 107.087,
          h: 107.133,
          l: 106.994,
          c: 107.026,
          v: 2240590000
        },
        {
          t: 47700,
          o: 107.026,
          h: 107.041,
          l: 106.976,
          c: 107,
          v: 2083400000
        },
        {
          t: 48600,
          o: 107.002,
          h: 107.028,
          l: 106.945,
          c: 106.965,
          v: 3809160001
        },
        {
          t: 49500,
          o: 106.966,
          h: 107.008,
          l: 106.941,
          c: 106.972,
          v: 3000150000
        },
        {
          t: 50400,
          o: 106.971,
          h: 107.068,
          l: 106.943,
          c: 107.053,
          v: 2993610000
        },
        {
          t: 51300,
          o: 107.054,
          h: 107.071,
          l: 107.007,
          c: 107.062,
          v: 2086149999
        },
        {
          t: 52200,
          o: 107.061,
          h: 107.123,
          l: 107.061,
          c: 107.123,
          v: 2261380000
        },
        {
          t: 53100,
          o: 107.125,
          h: 107.178,
          l: 107.119,
          c: 107.168,
          v: 2349470001
        },
        {
          t: 54000,
          o: 107.167,
          h: 107.203,
          l: 107.136,
          c: 107.138,
          v: 2077410000
        },
        {
          t: 54900,
          o: 107.139,
          h: 107.357,
          l: 107.139,
          c: 107.353,
          v: 3060150001
        },
        {
          t: 55800,
          o: 107.352,
          h: 107.397,
          l: 107.325,
          c: 107.35,
          v: 2745830002
        },
        {
          t: 56700,
          o: 107.352,
          h: 107.352,
          l: 107.26,
          c: 107.299,
          v: 3405570000
        },
        {
          t: 57600,
          o: 107.298,
          h: 107.303,
          l: 107.241,
          c: 107.245,
          v: 3094139998
        },
        {
          t: 58500,
          o: 107.246,
          h: 107.269,
          l: 107.126,
          c: 107.183,
          v: 3191370001
        },
        {
          t: 59400,
          o: 107.183,
          h: 107.265,
          l: 107.171,
          c: 107.19,
          v: 3344290000
        },
        {
          t: 60300,
          o: 107.19,
          h: 107.247,
          l: 107.18,
          c: 107.224,
          v: 2137190000
        },
        {
          t: 61200,
          o: 107.223,
          h: 107.266,
          l: 107.189,
          c: 107.207,
          v: 2254690001
        },
        {
          t: 62100,
          o: 107.205,
          h: 107.209,
          l: 107.123,
          c: 107.146,
          v: 1844810000
        },
        {
          t: 63000,
          o: 107.146,
          h: 107.237,
          l: 107.128,
          c: 107.215,
          v: 1765340000
        },
        {
          t: 63900,
          o: 107.215,
          h: 107.331,
          l: 107.163,
          c: 107.323,
          v: 3041760000
        },
        {
          t: 64800,
          o: 107.324,
          h: 107.374,
          l: 107.301,
          c: 107.325,
          v: 2263180000
        },
        {
          t: 65700,
          o: 107.324,
          h: 107.34,
          l: 107.266,
          c: 107.273,
          v: 2197980000
        },
        {
          t: 66600,
          o: 107.273,
          h: 107.32,
          l: 107.16,
          c: 107.198,
          v: 2945410000
        },
        {
          t: 67500,
          o: 107.197,
          h: 107.247,
          l: 107.145,
          c: 107.169,
          v: 2871600000
        },
        {
          t: 68400,
          o: 107.17,
          h: 107.186,
          l: 107.126,
          c: 107.18,
          v: 2641940000
        },
        {
          t: 69300,
          o: 107.179,
          h: 107.212,
          l: 107.142,
          c: 107.164,
          v: 2237380001
        },
        {
          t: 70200,
          o: 107.163,
          h: 107.193,
          l: 107.15,
          c: 107.164,
          v: 2168110000
        },
        {
          t: 71100,
          o: 107.162,
          h: 107.182,
          l: 107.14,
          c: 107.157,
          v: 1956320000
        },
        {
          t: 72000,
          o: 107.154,
          h: 107.172,
          l: 107.137,
          c: 107.14,
          v: 600300000
        },
        {
          t: 72900,
          o: 107.14,
          h: 107.166,
          l: 107.14,
          c: 107.153,
          v: 454280000
        },
        {
          t: 73800,
          o: 107.152,
          h: 107.206,
          l: 107.152,
          c: 107.206,
          v: 452790000
        },
        {
          t: 74700,
          o: 107.206,
          h: 107.206,
          l: 107.195,
          c: 107.197,
          v: 377050000
        },
        {
          t: 75600,
          o: 107.192,
          h: 107.224,
          l: 107.19,
          c: 107.223,
          v: 2685850000
        },
        {
          t: 76500,
          o: 107.219,
          h: 107.224,
          l: 107.206,
          c: 107.214,
          v: 778890000
        },
        {
          t: 77400,
          o: 107.218,
          h: 107.25,
          l: 107.212,
          c: 107.224,
          v: 1896860000
        },
        {
          t: 78300,
          o: 107.224,
          h: 107.232,
          l: 107.193,
          c: 107.193,
          v: 1086910000
        },
        {
          t: 79200,
          o: 107.193,
          h: 107.211,
          l: 107.18,
          c: 107.197,
          v: 693680000
        },
        {
          t: 80100,
          o: 107.194,
          h: 107.203,
          l: 107.171,
          c: 107.181,
          v: 285790000
        },
        {
          t: 81000,
          o: 107.18,
          h: 107.226,
          l: 107.18,
          c: 107.216,
          v: 305080000
        },
        {
          t: 81900,
          o: 107.216,
          h: 107.218,
          l: 107.194,
          c: 107.207,
          v: 220770000
        },
        {
          t: 82800,
          o: 107.209,
          h: 107.225,
          l: 107.173,
          c: 107.197,
          v: 607460000
        },
        {
          t: 83700,
          o: 107.197,
          h: 107.207,
          l: 107.162,
          c: 107.194,
          v: 690680000
        },
        {
          t: 84600,
          o: 107.192,
          h: 107.207,
          l: 107.188,
          c: 107.189,
          v: 467970000
        },
        {
          t: 85500,
          o: 107.189,
          h: 107.205,
          l: 107.095,
          c: 107.131,
          v: 1320270000
        }
      ]
    },
    {
      d: 1523404800,
      b: [
        {
          t: 0,
          o: 107.13,
          h: 107.135,
          l: 107.046,
          c: 107.076,
          v: 2347610000
        },
        {
          t: 900,
          o: 107.077,
          h: 107.09,
          l: 107.055,
          c: 107.077,
          v: 1744480000
        },
        {
          t: 1800,
          o: 107.078,
          h: 107.09,
          l: 107.024,
          c: 107.026,
          v: 1879630000
        },
        {
          t: 2700,
          o: 107.025,
          h: 107.105,
          l: 106.97,
          c: 107.099,
          v: 2786150000
        },
        {
          t: 3600,
          o: 107.098,
          h: 107.151,
          l: 107.08,
          c: 107.115,
          v: 1885070000
        },
        {
          t: 4500,
          o: 107.117,
          h: 107.138,
          l: 107.084,
          c: 107.129,
          v: 1598670001
        },
        {
          t: 5400,
          o: 107.127,
          h: 107.15,
          l: 107.105,
          c: 107.132,
          v: 2002830000
        },
        {
          t: 6300,
          o: 107.132,
          h: 107.132,
          l: 107.086,
          c: 107.09,
          v: 1706240000
        },
        {
          t: 7200,
          o: 107.092,
          h: 107.106,
          l: 107.061,
          c: 107.073,
          v: 1480300001
        },
        {
          t: 8100,
          o: 107.073,
          h: 107.092,
          l: 107.056,
          c: 107.083,
          v: 1045710000
        },
        {
          t: 9000,
          o: 107.083,
          h: 107.131,
          l: 107.066,
          c: 107.124,
          v: 1068120000
        },
        {
          t: 9900,
          o: 107.124,
          h: 107.125,
          l: 107.106,
          c: 107.12,
          v: 910000000
        },
        {
          t: 10800,
          o: 107.12,
          h: 107.133,
          l: 107.114,
          c: 107.132,
          v: 1002580002
        },
        {
          t: 11700,
          o: 107.133,
          h: 107.142,
          l: 107.115,
          c: 107.129,
          v: 836830002
        },
        {
          t: 12600,
          o: 107.129,
          h: 107.133,
          l: 107.075,
          c: 107.085,
          v: 1235300000
        },
        {
          t: 13500,
          o: 107.084,
          h: 107.085,
          l: 107.022,
          c: 107.061,
          v: 1498520000
        },
        {
          t: 14400,
          o: 107.061,
          h: 107.087,
          l: 107.052,
          c: 107.081,
          v: 682500000
        },
        {
          t: 15300,
          o: 107.08,
          h: 107.084,
          l: 107.033,
          c: 107.056,
          v: 846240000
        },
        {
          t: 16200,
          o: 107.057,
          h: 107.074,
          l: 107.037,
          c: 107.048,
          v: 1076050000
        },
        {
          t: 17100,
          o: 107.05,
          h: 107.056,
          l: 107.019,
          c: 107.019,
          v: 796390000
        },
        {
          t: 18000,
          o: 107.016,
          h: 107.052,
          l: 107.015,
          c: 107.045,
          v: 913550000
        },
        {
          t: 18900,
          o: 107.045,
          h: 107.084,
          l: 107.037,
          c: 107.083,
          v: 509040001
        },
        {
          t: 19800,
          o: 107.083,
          h: 107.109,
          l: 107.077,
          c: 107.099,
          v: 836869999
        },
        {
          t: 20700,
          o: 107.099,
          h: 107.103,
          l: 107.052,
          c: 107.065,
          v: 933710000
        },
        {
          t: 21600,
          o: 107.065,
          h: 107.108,
          l: 107.057,
          c: 107.062,
          v: 1678890000
        },
        {
          t: 22500,
          o: 107.061,
          h: 107.069,
          l: 107.039,
          c: 107.052,
          v: 1061160000
        },
        {
          t: 23400,
          o: 107.052,
          h: 107.084,
          l: 107.039,
          c: 107.046,
          v: 987430000
        },
        {
          t: 24300,
          o: 107.046,
          h: 107.058,
          l: 107.018,
          c: 107.055,
          v: 1476290000
        },
        {
          t: 25200,
          o: 107.056,
          h: 107.085,
          l: 106.982,
          c: 107.001,
          v: 1695240000
        },
        {
          t: 26100,
          o: 107.002,
          h: 107.053,
          l: 107,
          c: 107.042,
          v: 1480840000
        },
        {
          t: 27000,
          o: 107.042,
          h: 107.068,
          l: 107.028,
          c: 107.04,
          v: 1337770000
        },
        {
          t: 27900,
          o: 107.041,
          h: 107.051,
          l: 106.994,
          c: 107.008,
          v: 1231110000
        },
        {
          t: 28800,
          o: 107.007,
          h: 107.017,
          l: 106.981,
          c: 106.993,
          v: 1439940000
        },
        {
          t: 29700,
          o: 106.993,
          h: 106.993,
          l: 106.893,
          c: 106.91,
          v: 1850119999
        },
        {
          t: 30600,
          o: 106.911,
          h: 106.973,
          l: 106.911,
          c: 106.97,
          v: 1130220000
        },
        {
          t: 31500,
          o: 106.971,
          h: 106.988,
          l: 106.949,
          c: 106.957,
          v: 889240000
        },
        {
          t: 32400,
          o: 106.956,
          h: 106.993,
          l: 106.956,
          c: 106.993,
          v: 894220000
        },
        {
          t: 33300,
          o: 106.994,
          h: 107.027,
          l: 106.99,
          c: 107.02,
          v: 985830001
        },
        {
          t: 34200,
          o: 107.017,
          h: 107.017,
          l: 106.974,
          c: 106.983,
          v: 635140000
        },
        {
          t: 35100,
          o: 106.984,
          h: 106.991,
          l: 106.933,
          c: 106.951,
          v: 905190000
        },
        {
          t: 36000,
          o: 106.951,
          h: 106.983,
          l: 106.946,
          c: 106.958,
          v: 963840000
        },
        {
          t: 36900,
          o: 106.958,
          h: 106.96,
          l: 106.882,
          c: 106.934,
          v: 1507450000
        },
        {
          t: 37800,
          o: 106.933,
          h: 106.97,
          l: 106.927,
          c: 106.934,
          v: 1179850000
        },
        {
          t: 38700,
          o: 106.934,
          h: 106.972,
          l: 106.865,
          c: 106.895,
          v: 1399730000
        },
        {
          t: 39600,
          o: 106.897,
          h: 106.904,
          l: 106.79,
          c: 106.827,
          v: 3134950000
        },
        {
          t: 40500,
          o: 106.828,
          h: 106.867,
          l: 106.748,
          c: 106.77,
          v: 2270950001
        },
        {
          t: 41400,
          o: 106.769,
          h: 106.846,
          l: 106.766,
          c: 106.846,
          v: 1659630000
        },
        {
          t: 42300,
          o: 106.845,
          h: 106.85,
          l: 106.793,
          c: 106.85,
          v: 1287680000
        },
        {
          t: 43200,
          o: 106.851,
          h: 106.851,
          l: 106.776,
          c: 106.8,
          v: 1629100000
        },
        {
          t: 44100,
          o: 106.8,
          h: 106.807,
          l: 106.754,
          c: 106.772,
          v: 1458820000
        },
        {
          t: 45000,
          o: 106.772,
          h: 106.797,
          l: 106.681,
          c: 106.75,
          v: 3095690000
        },
        {
          t: 45900,
          o: 106.75,
          h: 106.836,
          l: 106.733,
          c: 106.832,
          v: 2816459995
        },
        {
          t: 46800,
          o: 106.833,
          h: 106.878,
          l: 106.794,
          c: 106.859,
          v: 2409380000
        },
        {
          t: 47700,
          o: 106.859,
          h: 106.936,
          l: 106.842,
          c: 106.922,
          v: 2340779999
        },
        {
          t: 48600,
          o: 106.918,
          h: 106.96,
          l: 106.903,
          c: 106.928,
          v: 2623120000
        },
        {
          t: 49500,
          o: 106.928,
          h: 106.929,
          l: 106.882,
          c: 106.902,
          v: 2706640000
        },
        {
          t: 50400,
          o: 106.901,
          h: 106.912,
          l: 106.821,
          c: 106.836,
          v: 2648419998
        },
        {
          t: 51300,
          o: 106.837,
          h: 106.927,
          l: 106.824,
          c: 106.897,
          v: 2022070000
        },
        {
          t: 52200,
          o: 106.9,
          h: 106.915,
          l: 106.841,
          c: 106.863,
          v: 1999280000
        },
        {
          t: 53100,
          o: 106.864,
          h: 106.872,
          l: 106.699,
          c: 106.707,
          v: 3239760000
        },
        {
          t: 54000,
          o: 106.707,
          h: 106.742,
          l: 106.659,
          c: 106.707,
          v: 3240890000
        },
        {
          t: 54900,
          o: 106.708,
          h: 106.72,
          l: 106.65,
          c: 106.716,
          v: 2676949999
        },
        {
          t: 55800,
          o: 106.716,
          h: 106.767,
          l: 106.713,
          c: 106.741,
          v: 1938740000
        },
        {
          t: 56700,
          o: 106.74,
          h: 106.809,
          l: 106.705,
          c: 106.775,
          v: 2135900000
        },
        {
          t: 57600,
          o: 106.774,
          h: 106.87,
          l: 106.758,
          c: 106.807,
          v: 2882230005
        },
        {
          t: 58500,
          o: 106.808,
          h: 106.945,
          l: 106.8,
          c: 106.895,
          v: 2708579997
        },
        {
          t: 59400,
          o: 106.896,
          h: 106.913,
          l: 106.845,
          c: 106.863,
          v: 1571340000
        },
        {
          t: 60300,
          o: 106.867,
          h: 106.88,
          l: 106.832,
          c: 106.867,
          v: 2107759999
        },
        {
          t: 61200,
          o: 106.868,
          h: 106.924,
          l: 106.825,
          c: 106.83,
          v: 2537030001
        },
        {
          t: 62100,
          o: 106.829,
          h: 106.883,
          l: 106.828,
          c: 106.849,
          v: 1864320000
        },
        {
          t: 63000,
          o: 106.848,
          h: 106.865,
          l: 106.799,
          c: 106.841,
          v: 3249139981
        },
        {
          t: 63900,
          o: 106.841,
          h: 106.876,
          l: 106.821,
          c: 106.846,
          v: 2186930000
        },
        {
          t: 64800,
          o: 106.846,
          h: 107.043,
          l: 106.802,
          c: 107.031,
          v: 3853700000
        },
        {
          t: 65700,
          o: 107.03,
          h: 107.063,
          l: 106.951,
          c: 106.951,
          v: 2686239999
        },
        {
          t: 66600,
          o: 106.95,
          h: 107.008,
          l: 106.931,
          c: 106.938,
          v: 2942260003
        },
        {
          t: 67500,
          o: 106.936,
          h: 106.938,
          l: 106.882,
          c: 106.933,
          v: 2602050000
        },
        {
          t: 68400,
          o: 106.933,
          h: 106.942,
          l: 106.823,
          c: 106.839,
          v: 2620740000
        },
        {
          t: 69300,
          o: 106.838,
          h: 106.84,
          l: 106.78,
          c: 106.82,
          v: 2292260000
        },
        {
          t: 70200,
          o: 106.819,
          h: 106.89,
          l: 106.803,
          c: 106.859,
          v: 1722400001
        },
        {
          t: 71100,
          o: 106.859,
          h: 106.87,
          l: 106.811,
          c: 106.854,
          v: 1687840000
        },
        {
          t: 72000,
          o: 106.855,
          h: 106.873,
          l: 106.842,
          c: 106.847,
          v: 587060000
        },
        {
          t: 72900,
          o: 106.847,
          h: 106.854,
          l: 106.797,
          c: 106.809,
          v: 649580000
        },
        {
          t: 73800,
          o: 106.81,
          h: 106.839,
          l: 106.807,
          c: 106.814,
          v: 442510000
        },
        {
          t: 74700,
          o: 106.813,
          h: 106.82,
          l: 106.782,
          c: 106.786,
          v: 677910000
        },
        {
          t: 75600,
          o: 106.785,
          h: 106.814,
          l: 106.776,
          c: 106.804,
          v: 2208450000
        },
        {
          t: 76500,
          o: 106.804,
          h: 106.844,
          l: 106.789,
          c: 106.806,
          v: 1934020000
        },
        {
          t: 77400,
          o: 106.803,
          h: 106.837,
          l: 106.794,
          c: 106.813,
          v: 883300000
        },
        {
          t: 78300,
          o: 106.813,
          h: 106.828,
          l: 106.789,
          c: 106.798,
          v: 754500000
        },
        {
          t: 79200,
          o: 106.797,
          h: 106.807,
          l: 106.786,
          c: 106.802,
          v: 376800000
        },
        {
          t: 80100,
          o: 106.801,
          h: 106.812,
          l: 106.795,
          c: 106.811,
          v: 96870000
        },
        {
          t: 81000,
          o: 106.81,
          h: 106.823,
          l: 106.805,
          c: 106.819,
          v: 249640000
        },
        {
          t: 81900,
          o: 106.819,
          h: 106.829,
          l: 106.8,
          c: 106.8,
          v: 306150000
        },
        {
          t: 82800,
          o: 106.801,
          h: 106.865,
          l: 106.801,
          c: 106.824,
          v: 450900000
        },
        {
          t: 83700,
          o: 106.824,
          h: 106.835,
          l: 106.816,
          c: 106.828,
          v: 264550000
        },
        {
          t: 84600,
          o: 106.828,
          h: 106.828,
          l: 106.791,
          c: 106.807,
          v: 578890000
        },
        {
          t: 85500,
          o: 106.807,
          h: 106.841,
          l: 106.802,
          c: 106.825,
          v: 763270000
        }
      ]
    },
    {
      d: 1523491200,
      b: [
        {
          t: 0,
          o: 106.826,
          h: 106.826,
          l: 106.738,
          c: 106.741,
          v: 1786300000
        },
        {
          t: 900,
          o: 106.741,
          h: 106.761,
          l: 106.7,
          c: 106.76,
          v: 1547020000
        },
        {
          t: 1800,
          o: 106.763,
          h: 106.848,
          l: 106.751,
          c: 106.826,
          v: 1803120000
        },
        {
          t: 2700,
          o: 106.826,
          h: 106.872,
          l: 106.814,
          c: 106.852,
          v: 2216880000
        },
        {
          t: 3600,
          o: 106.852,
          h: 106.926,
          l: 106.832,
          c: 106.903,
          v: 2138990002
        },
        {
          t: 4500,
          o: 106.903,
          h: 106.904,
          l: 106.854,
          c: 106.86,
          v: 1411860004
        },
        {
          t: 5400,
          o: 106.86,
          h: 106.907,
          l: 106.851,
          c: 106.892,
          v: 1111520000
        },
        {
          t: 6300,
          o: 106.892,
          h: 106.92,
          l: 106.857,
          c: 106.857,
          v: 1552689999
        },
        {
          t: 7200,
          o: 106.855,
          h: 106.914,
          l: 106.855,
          c: 106.898,
          v: 1371530001
        },
        {
          t: 8100,
          o: 106.897,
          h: 106.906,
          l: 106.86,
          c: 106.895,
          v: 1834350000
        },
        {
          t: 9000,
          o: 106.895,
          h: 106.934,
          l: 106.893,
          c: 106.898,
          v: 1143819998
        },
        {
          t: 9900,
          o: 106.898,
          h: 106.903,
          l: 106.875,
          c: 106.879,
          v: 687310000
        },
        {
          t: 10800,
          o: 106.88,
          h: 106.903,
          l: 106.868,
          c: 106.877,
          v: 774900000
        },
        {
          t: 11700,
          o: 106.877,
          h: 106.883,
          l: 106.847,
          c: 106.878,
          v: 671570000
        },
        {
          t: 12600,
          o: 106.877,
          h: 106.877,
          l: 106.816,
          c: 106.82,
          v: 1448170000
        },
        {
          t: 13500,
          o: 106.819,
          h: 106.882,
          l: 106.815,
          c: 106.864,
          v: 1044130000
        },
        {
          t: 14400,
          o: 106.866,
          h: 106.887,
          l: 106.865,
          c: 106.887,
          v: 578450000
        },
        {
          t: 15300,
          o: 106.888,
          h: 106.92,
          l: 106.888,
          c: 106.906,
          v: 1026939998
        },
        {
          t: 16200,
          o: 106.906,
          h: 106.919,
          l: 106.881,
          c: 106.902,
          v: 1322830000
        },
        {
          t: 17100,
          o: 106.901,
          h: 106.936,
          l: 106.89,
          c: 106.909,
          v: 1030270000
        },
        {
          t: 18000,
          o: 106.909,
          h: 106.972,
          l: 106.893,
          c: 106.938,
          v: 1369800000
        },
        {
          t: 18900,
          o: 106.938,
          h: 106.948,
          l: 106.928,
          c: 106.937,
          v: 971260000
        },
        {
          t: 19800,
          o: 106.937,
          h: 106.958,
          l: 106.924,
          c: 106.942,
          v: 905560000
        },
        {
          t: 20700,
          o: 106.94,
          h: 106.955,
          l: 106.927,
          c: 106.933,
          v: 1173780001
        },
        {
          t: 21600,
          o: 106.935,
          h: 106.94,
          l: 106.86,
          c: 106.881,
          v: 1480400000
        },
        {
          t: 22500,
          o: 106.88,
          h: 106.91,
          l: 106.857,
          c: 106.857,
          v: 1381230000
        },
        {
          t: 23400,
          o: 106.857,
          h: 106.873,
          l: 106.837,
          c: 106.838,
          v: 1107890000
        },
        {
          t: 24300,
          o: 106.839,
          h: 106.876,
          l: 106.835,
          c: 106.876,
          v: 1240740000
        },
        {
          t: 25200,
          o: 106.876,
          h: 106.894,
          l: 106.857,
          c: 106.873,
          v: 1926010000
        },
        {
          t: 26100,
          o: 106.874,
          h: 106.916,
          l: 106.848,
          c: 106.91,
          v: 1295650000
        },
        {
          t: 27000,
          o: 106.909,
          h: 106.926,
          l: 106.875,
          c: 106.882,
          v: 1519110000
        },
        {
          t: 27900,
          o: 106.883,
          h: 106.919,
          l: 106.867,
          c: 106.899,
          v: 1613630000
        },
        {
          t: 28800,
          o: 106.899,
          h: 106.926,
          l: 106.887,
          c: 106.897,
          v: 1471390001
        },
        {
          t: 29700,
          o: 106.897,
          h: 106.915,
          l: 106.88,
          c: 106.901,
          v: 961560000
        },
        {
          t: 30600,
          o: 106.902,
          h: 106.916,
          l: 106.872,
          c: 106.885,
          v: 1126850000
        },
        {
          t: 31500,
          o: 106.885,
          h: 106.892,
          l: 106.82,
          c: 106.824,
          v: 1974729999
        },
        {
          t: 32400,
          o: 106.823,
          h: 106.889,
          l: 106.823,
          c: 106.889,
          v: 2153760000
        },
        {
          t: 33300,
          o: 106.89,
          h: 106.931,
          l: 106.88,
          c: 106.931,
          v: 1342510000
        },
        {
          t: 34200,
          o: 106.932,
          h: 106.96,
          l: 106.928,
          c: 106.934,
          v: 1179460000
        },
        {
          t: 35100,
          o: 106.935,
          h: 106.953,
          l: 106.917,
          c: 106.917,
          v: 1277300000
        },
        {
          t: 36000,
          o: 106.917,
          h: 106.958,
          l: 106.917,
          c: 106.952,
          v: 948679999
        },
        {
          t: 36900,
          o: 106.953,
          h: 107.084,
          l: 106.951,
          c: 107.075,
          v: 1864830000
        },
        {
          t: 37800,
          o: 107.074,
          h: 107.148,
          l: 107.072,
          c: 107.12,
          v: 2519930000
        },
        {
          t: 38700,
          o: 107.122,
          h: 107.18,
          l: 107.095,
          c: 107.155,
          v: 2251630000
        },
        {
          t: 39600,
          o: 107.155,
          h: 107.203,
          l: 107.119,
          c: 107.147,
          v: 1624270000
        },
        {
          t: 40500,
          o: 107.145,
          h: 107.156,
          l: 107.109,
          c: 107.137,
          v: 1372450000
        },
        {
          t: 41400,
          o: 107.136,
          h: 107.165,
          l: 107.096,
          c: 107.119,
          v: 1991020000
        },
        {
          t: 42300,
          o: 107.12,
          h: 107.179,
          l: 107.111,
          c: 107.178,
          v: 1713460000
        },
        {
          t: 43200,
          o: 107.178,
          h: 107.178,
          l: 107.11,
          c: 107.146,
          v: 1752590000
        },
        {
          t: 44100,
          o: 107.149,
          h: 107.214,
          l: 107.145,
          c: 107.2,
          v: 2129679999
        },
        {
          t: 45000,
          o: 107.201,
          h: 107.238,
          l: 107.162,
          c: 107.188,
          v: 2441670000
        },
        {
          t: 45900,
          o: 107.192,
          h: 107.241,
          l: 107.164,
          c: 107.235,
          v: 2155240000
        },
        {
          t: 46800,
          o: 107.236,
          h: 107.265,
          l: 107.179,
          c: 107.244,
          v: 2248310000
        },
        {
          t: 47700,
          o: 107.244,
          h: 107.404,
          l: 107.244,
          c: 107.371,
          v: 2752490001
        },
        {
          t: 48600,
          o: 107.372,
          h: 107.423,
          l: 107.349,
          c: 107.397,
          v: 3395500000
        },
        {
          t: 49500,
          o: 107.398,
          h: 107.429,
          l: 107.367,
          c: 107.375,
          v: 2853490000
        },
        {
          t: 50400,
          o: 107.374,
          h: 107.399,
          l: 107.336,
          c: 107.392,
          v: 2231290000
        },
        {
          t: 51300,
          o: 107.391,
          h: 107.402,
          l: 107.361,
          c: 107.375,
          v: 1639570000
        },
        {
          t: 52200,
          o: 107.376,
          h: 107.397,
          l: 107.311,
          c: 107.351,
          v: 1938000000
        },
        {
          t: 53100,
          o: 107.351,
          h: 107.351,
          l: 107.263,
          c: 107.265,
          v: 2322970001
        },
        {
          t: 54000,
          o: 107.263,
          h: 107.35,
          l: 107.263,
          c: 107.327,
          v: 2018630000
        },
        {
          t: 54900,
          o: 107.325,
          h: 107.409,
          l: 107.31,
          c: 107.357,
          v: 1976800000
        },
        {
          t: 55800,
          o: 107.362,
          h: 107.385,
          l: 107.338,
          c: 107.373,
          v: 1783810001
        },
        {
          t: 56700,
          o: 107.373,
          h: 107.375,
          l: 107.261,
          c: 107.315,
          v: 1926480000
        },
        {
          t: 57600,
          o: 107.315,
          h: 107.345,
          l: 107.248,
          c: 107.248,
          v: 1854350001
        },
        {
          t: 58500,
          o: 107.248,
          h: 107.307,
          l: 107.248,
          c: 107.303,
          v: 1454000000
        },
        {
          t: 59400,
          o: 107.3,
          h: 107.328,
          l: 107.281,
          c: 107.282,
          v: 1071620000
        },
        {
          t: 60300,
          o: 107.282,
          h: 107.322,
          l: 107.209,
          c: 107.219,
          v: 1834610000
        },
        {
          t: 61200,
          o: 107.218,
          h: 107.285,
          l: 107.121,
          c: 107.165,
          v: 2457650000
        },
        {
          t: 62100,
          o: 107.166,
          h: 107.184,
          l: 107.08,
          c: 107.126,
          v: 2480560000
        },
        {
          t: 63000,
          o: 107.125,
          h: 107.195,
          l: 107.119,
          c: 107.192,
          v: 1983090000
        },
        {
          t: 63900,
          o: 107.191,
          h: 107.203,
          l: 107.148,
          c: 107.176,
          v: 1652500000
        },
        {
          t: 64800,
          o: 107.176,
          h: 107.211,
          l: 107.17,
          c: 107.196,
          v: 1575590000
        },
        {
          t: 65700,
          o: 107.195,
          h: 107.265,
          l: 107.191,
          c: 107.224,
          v: 1655480000
        },
        {
          t: 66600,
          o: 107.225,
          h: 107.238,
          l: 107.176,
          c: 107.181,
          v: 1606870000
        },
        {
          t: 67500,
          o: 107.181,
          h: 107.228,
          l: 107.165,
          c: 107.21,
          v: 1546910000
        },
        {
          t: 68400,
          o: 107.213,
          h: 107.224,
          l: 107.166,
          c: 107.187,
          v: 1386750000
        },
        {
          t: 69300,
          o: 107.188,
          h: 107.235,
          l: 107.167,
          c: 107.226,
          v: 1488060000
        },
        {
          t: 70200,
          o: 107.225,
          h: 107.246,
          l: 107.207,
          c: 107.219,
          v: 1404340000
        },
        {
          t: 71100,
          o: 107.219,
          h: 107.232,
          l: 107.186,
          c: 107.226,
          v: 1694670000
        },
        {
          t: 72000,
          o: 107.227,
          h: 107.273,
          l: 107.226,
          c: 107.271,
          v: 565560000
        },
        {
          t: 72900,
          o: 107.271,
          h: 107.273,
          l: 107.23,
          c: 107.266,
          v: 705900000
        },
        {
          t: 73800,
          o: 107.267,
          h: 107.282,
          l: 107.244,
          c: 107.278,
          v: 943900000
        },
        {
          t: 74700,
          o: 107.276,
          h: 107.367,
          l: 107.27,
          c: 107.317,
          v: 1136530000
        },
        {
          t: 75600,
          o: 107.315,
          h: 107.345,
          l: 107.287,
          c: 107.302,
          v: 1765060000
        },
        {
          t: 76500,
          o: 107.301,
          h: 107.336,
          l: 107.301,
          c: 107.322,
          v: 1025350000
        },
        {
          t: 77400,
          o: 107.321,
          h: 107.342,
          l: 107.29,
          c: 107.316,
          v: 719670000
        },
        {
          t: 78300,
          o: 107.316,
          h: 107.323,
          l: 107.288,
          c: 107.304,
          v: 1132180000
        },
        {
          t: 79200,
          o: 107.302,
          h: 107.312,
          l: 107.277,
          c: 107.306,
          v: 839050000
        },
        {
          t: 80100,
          o: 107.307,
          h: 107.317,
          l: 107.284,
          c: 107.295,
          v: 179470000
        },
        {
          t: 81000,
          o: 107.295,
          h: 107.301,
          l: 107.26,
          c: 107.27,
          v: 538170000
        },
        {
          t: 81900,
          o: 107.27,
          h: 107.282,
          l: 107.243,
          c: 107.248,
          v: 607700000
        },
        {
          t: 82800,
          o: 107.248,
          h: 107.257,
          l: 107.221,
          c: 107.232,
          v: 695680000
        },
        {
          t: 83700,
          o: 107.232,
          h: 107.234,
          l: 107.214,
          c: 107.214,
          v: 370080000
        },
        {
          t: 84600,
          o: 107.214,
          h: 107.243,
          l: 107.203,
          c: 107.231,
          v: 537210000
        },
        {
          t: 85500,
          o: 107.232,
          h: 107.255,
          l: 107.222,
          c: 107.252,
          v: 909660000
        }
      ]
    },
    {
      d: 1523577600,
      b: [
        {
          t: 0,
          o: 107.254,
          h: 107.336,
          l: 107.254,
          c: 107.291,
          v: 2312599986
        },
        {
          t: 900,
          o: 107.292,
          h: 107.308,
          l: 107.251,
          c: 107.303,
          v: 1437490000
        },
        {
          t: 1800,
          o: 107.303,
          h: 107.358,
          l: 107.299,
          c: 107.326,
          v: 1626830000
        },
        {
          t: 2700,
          o: 107.326,
          h: 107.371,
          l: 107.313,
          c: 107.333,
          v: 2138030000
        },
        {
          t: 3600,
          o: 107.333,
          h: 107.441,
          l: 107.31,
          c: 107.428,
          v: 1625000000
        },
        {
          t: 4500,
          o: 107.427,
          h: 107.472,
          l: 107.422,
          c: 107.462,
          v: 1458970000
        },
        {
          t: 5400,
          o: 107.463,
          h: 107.48,
          l: 107.425,
          c: 107.448,
          v: 1694920000
        },
        {
          t: 6300,
          o: 107.449,
          h: 107.449,
          l: 107.391,
          c: 107.42,
          v: 1409630000
        },
        {
          t: 7200,
          o: 107.42,
          h: 107.433,
          l: 107.338,
          c: 107.344,
          v: 1509680000
        },
        {
          t: 8100,
          o: 107.344,
          h: 107.364,
          l: 107.319,
          c: 107.337,
          v: 1086600000
        },
        {
          t: 9000,
          o: 107.339,
          h: 107.352,
          l: 107.32,
          c: 107.343,
          v: 738110000
        },
        {
          t: 9900,
          o: 107.343,
          h: 107.376,
          l: 107.342,
          c: 107.369,
          v: 641450000
        },
        {
          t: 10800,
          o: 107.37,
          h: 107.397,
          l: 107.355,
          c: 107.363,
          v: 1110880000
        },
        {
          t: 11700,
          o: 107.362,
          h: 107.385,
          l: 107.358,
          c: 107.377,
          v: 630220000
        },
        {
          t: 12600,
          o: 107.378,
          h: 107.403,
          l: 107.359,
          c: 107.361,
          v: 553680000
        },
        {
          t: 13500,
          o: 107.361,
          h: 107.386,
          l: 107.355,
          c: 107.357,
          v: 556960001
        },
        {
          t: 14400,
          o: 107.357,
          h: 107.376,
          l: 107.342,
          c: 107.37,
          v: 797790000
        },
        {
          t: 15300,
          o: 107.37,
          h: 107.381,
          l: 107.356,
          c: 107.359,
          v: 964380000
        },
        {
          t: 16200,
          o: 107.359,
          h: 107.362,
          l: 107.333,
          c: 107.348,
          v: 737100000
        },
        {
          t: 17100,
          o: 107.348,
          h: 107.377,
          l: 107.348,
          c: 107.374,
          v: 691300000
        },
        {
          t: 18000,
          o: 107.374,
          h: 107.394,
          l: 107.362,
          c: 107.393,
          v: 826590000
        },
        {
          t: 18900,
          o: 107.393,
          h: 107.457,
          l: 107.386,
          c: 107.437,
          v: 1442290000
        },
        {
          t: 19800,
          o: 107.437,
          h: 107.46,
          l: 107.42,
          c: 107.449,
          v: 802170000
        },
        {
          t: 20700,
          o: 107.449,
          h: 107.483,
          l: 107.424,
          c: 107.467,
          v: 981490000
        },
        {
          t: 21600,
          o: 107.47,
          h: 107.584,
          l: 107.455,
          c: 107.562,
          v: 2289560000
        },
        {
          t: 22500,
          o: 107.559,
          h: 107.593,
          l: 107.538,
          c: 107.586,
          v: 1942640000
        },
        {
          t: 23400,
          o: 107.586,
          h: 107.597,
          l: 107.535,
          c: 107.594,
          v: 1820880000
        },
        {
          t: 24300,
          o: 107.594,
          h: 107.658,
          l: 107.579,
          c: 107.6,
          v: 2269170000
        },
        {
          t: 25200,
          o: 107.6,
          h: 107.639,
          l: 107.556,
          c: 107.616,
          v: 2095440000
        },
        {
          t: 26100,
          o: 107.617,
          h: 107.675,
          l: 107.602,
          c: 107.634,
          v: 1779810000
        },
        {
          t: 27000,
          o: 107.637,
          h: 107.667,
          l: 107.609,
          c: 107.632,
          v: 1766400001
        },
        {
          t: 27900,
          o: 107.632,
          h: 107.672,
          l: 107.603,
          c: 107.643,
          v: 1368700000
        },
        {
          t: 28800,
          o: 107.641,
          h: 107.656,
          l: 107.618,
          c: 107.63,
          v: 1220480000
        },
        {
          t: 29700,
          o: 107.63,
          h: 107.66,
          l: 107.616,
          c: 107.616,
          v: 1156890000
        },
        {
          t: 30600,
          o: 107.615,
          h: 107.619,
          l: 107.56,
          c: 107.6,
          v: 1306060000
        },
        {
          t: 31500,
          o: 107.6,
          h: 107.624,
          l: 107.571,
          c: 107.614,
          v: 1009259999
        },
        {
          t: 32400,
          o: 107.613,
          h: 107.631,
          l: 107.575,
          c: 107.578,
          v: 1154050000
        },
        {
          t: 33300,
          o: 107.58,
          h: 107.592,
          l: 107.559,
          c: 107.57,
          v: 752810000
        },
        {
          t: 34200,
          o: 107.569,
          h: 107.587,
          l: 107.523,
          c: 107.536,
          v: 1074010000
        },
        {
          t: 35100,
          o: 107.537,
          h: 107.601,
          l: 107.533,
          c: 107.593,
          v: 861430000
        },
        {
          t: 36000,
          o: 107.593,
          h: 107.603,
          l: 107.558,
          c: 107.57,
          v: 869940000
        },
        {
          t: 36900,
          o: 107.572,
          h: 107.607,
          l: 107.563,
          c: 107.605,
          v: 747070000
        },
        {
          t: 37800,
          o: 107.604,
          h: 107.624,
          l: 107.588,
          c: 107.614,
          v: 921920000
        },
        {
          t: 38700,
          o: 107.614,
          h: 107.682,
          l: 107.596,
          c: 107.675,
          v: 1473810000
        },
        {
          t: 39600,
          o: 107.673,
          h: 107.724,
          l: 107.658,
          c: 107.716,
          v: 1506810000
        },
        {
          t: 40500,
          o: 107.715,
          h: 107.737,
          l: 107.676,
          c: 107.68,
          v: 1070980000
        },
        {
          t: 41400,
          o: 107.681,
          h: 107.698,
          l: 107.65,
          c: 107.668,
          v: 995650000
        },
        {
          t: 42300,
          o: 107.669,
          h: 107.727,
          l: 107.668,
          c: 107.694,
          v: 1217550000
        },
        {
          t: 43200,
          o: 107.694,
          h: 107.697,
          l: 107.658,
          c: 107.692,
          v: 1474670000
        },
        {
          t: 44100,
          o: 107.691,
          h: 107.707,
          l: 107.68,
          c: 107.693,
          v: 1443340000
        },
        {
          t: 45000,
          o: 107.692,
          h: 107.715,
          l: 107.681,
          c: 107.695,
          v: 1544050000
        },
        {
          t: 45900,
          o: 107.694,
          h: 107.708,
          l: 107.68,
          c: 107.701,
          v: 1308609998
        },
        {
          t: 46800,
          o: 107.699,
          h: 107.759,
          l: 107.692,
          c: 107.755,
          v: 2029340000
        },
        {
          t: 47700,
          o: 107.755,
          h: 107.778,
          l: 107.711,
          c: 107.73,
          v: 1954700000
        },
        {
          t: 48600,
          o: 107.729,
          h: 107.732,
          l: 107.61,
          c: 107.621,
          v: 2416590000
        },
        {
          t: 49500,
          o: 107.62,
          h: 107.656,
          l: 107.603,
          c: 107.647,
          v: 1925900000
        },
        {
          t: 50400,
          o: 107.649,
          h: 107.649,
          l: 107.506,
          c: 107.542,
          v: 2621180000
        },
        {
          t: 51300,
          o: 107.541,
          h: 107.568,
          l: 107.504,
          c: 107.51,
          v: 2359520000
        },
        {
          t: 52200,
          o: 107.509,
          h: 107.562,
          l: 107.486,
          c: 107.531,
          v: 2289330000
        },
        {
          t: 53100,
          o: 107.531,
          h: 107.591,
          l: 107.513,
          c: 107.518,
          v: 2162720000
        },
        {
          t: 54000,
          o: 107.519,
          h: 107.53,
          l: 107.439,
          c: 107.494,
          v: 2732210000
        },
        {
          t: 54900,
          o: 107.496,
          h: 107.534,
          l: 107.492,
          c: 107.515,
          v: 1604910000
        },
        {
          t: 55800,
          o: 107.515,
          h: 107.549,
          l: 107.494,
          c: 107.518,
          v: 1723470000
        },
        {
          t: 56700,
          o: 107.518,
          h: 107.539,
          l: 107.475,
          c: 107.535,
          v: 1426950000
        },
        {
          t: 57600,
          o: 107.534,
          h: 107.539,
          l: 107.468,
          c: 107.471,
          v: 1761130000
        },
        {
          t: 58500,
          o: 107.471,
          h: 107.494,
          l: 107.428,
          c: 107.474,
          v: 1494060000
        },
        {
          t: 59400,
          o: 107.474,
          h: 107.475,
          l: 107.381,
          c: 107.422,
          v: 1702720000
        },
        {
          t: 60300,
          o: 107.424,
          h: 107.452,
          l: 107.377,
          c: 107.395,
          v: 1624630000
        },
        {
          t: 61200,
          o: 107.395,
          h: 107.454,
          l: 107.389,
          c: 107.426,
          v: 1141960000
        },
        {
          t: 62100,
          o: 107.429,
          h: 107.465,
          l: 107.424,
          c: 107.447,
          v: 1359660000
        },
        {
          t: 63000,
          o: 107.449,
          h: 107.507,
          l: 107.437,
          c: 107.483,
          v: 1563180000
        },
        {
          t: 63900,
          o: 107.483,
          h: 107.51,
          l: 107.481,
          c: 107.499,
          v: 1191330000
        },
        {
          t: 64800,
          o: 107.499,
          h: 107.506,
          l: 107.465,
          c: 107.466,
          v: 932880000
        },
        {
          t: 65700,
          o: 107.466,
          h: 107.47,
          l: 107.437,
          c: 107.465,
          v: 1396520000
        },
        {
          t: 66600,
          o: 107.465,
          h: 107.486,
          l: 107.444,
          c: 107.458,
          v: 1746120000
        },
        {
          t: 67500,
          o: 107.456,
          h: 107.489,
          l: 107.419,
          c: 107.42,
          v: 1853790000
        },
        {
          t: 68400,
          o: 107.421,
          h: 107.421,
          l: 107.313,
          c: 107.367,
          v: 2363900000
        },
        {
          t: 69300,
          o: 107.368,
          h: 107.368,
          l: 107.253,
          c: 107.328,
          v: 3010310000
        },
        {
          t: 70200,
          o: 107.327,
          h: 107.374,
          l: 107.321,
          c: 107.361,
          v: 2428200000
        },
        {
          t: 71100,
          o: 107.361,
          h: 107.398,
          l: 107.345,
          c: 107.369,
          v: 2203680000
        },
        {
          t: 72000,
          o: 107.37,
          h: 107.375,
          l: 107.341,
          c: 107.352,
          v: 565510000
        },
        {
          t: 72900,
          o: 107.352,
          h: 107.366,
          l: 107.349,
          c: 107.364,
          v: 402760000
        },
        {
          t: 73800,
          o: 107.365,
          h: 107.374,
          l: 107.354,
          c: 107.36,
          v: 504829999
        },
        {
          t: 74700,
          o: 107.361,
          h: 107.37,
          l: 107.335,
          c: 107.335,
          v: 658640000
        }
      ]
    },
    {
      d: 1523750400,
      b: [
        {
          t: 75600,
          o: 107.481,
          h: 107.511,
          l: 107.442,
          c: 107.508,
          v: 283870000
        },
        {
          t: 76500,
          o: 107.512,
          h: 107.559,
          l: 107.473,
          c: 107.522,
          v: 234830000
        },
        {
          t: 77400,
          o: 107.521,
          h: 107.521,
          l: 107.471,
          c: 107.478,
          v: 127140000
        },
        {
          t: 78300,
          o: 107.478,
          h: 107.516,
          l: 107.473,
          c: 107.489,
          v: 133250000
        },
        {
          t: 79200,
          o: 107.493,
          h: 107.555,
          l: 107.457,
          c: 107.551,
          v: 1402220000
        },
        {
          t: 80100,
          o: 107.549,
          h: 107.608,
          l: 107.54,
          c: 107.574,
          v: 773540000
        },
        {
          t: 81000,
          o: 107.573,
          h: 107.573,
          l: 107.538,
          c: 107.554,
          v: 323140000
        },
        {
          t: 81900,
          o: 107.554,
          h: 107.563,
          l: 107.538,
          c: 107.541,
          v: 221850000
        },
        {
          t: 82800,
          o: 107.54,
          h: 107.583,
          l: 107.539,
          c: 107.554,
          v: 403340000
        },
        {
          t: 83700,
          o: 107.555,
          h: 107.555,
          l: 107.501,
          c: 107.507,
          v: 600420000
        },
        {
          t: 84600,
          o: 107.507,
          h: 107.533,
          l: 107.498,
          c: 107.503,
          v: 525340000
        },
        {
          t: 85500,
          o: 107.502,
          h: 107.527,
          l: 107.478,
          c: 107.521,
          v: 760770000
        }
      ]
    },
    {
      d: 1523836800,
      b: [
        {
          t: 0,
          o: 107.521,
          h: 107.521,
          l: 107.456,
          c: 107.461,
          v: 1774710000
        },
        {
          t: 900,
          o: 107.461,
          h: 107.463,
          l: 107.38,
          c: 107.389,
          v: 1677510000
        },
        {
          t: 1800,
          o: 107.391,
          h: 107.458,
          l: 107.39,
          c: 107.432,
          v: 1217670000
        },
        {
          t: 2700,
          o: 107.435,
          h: 107.505,
          l: 107.416,
          c: 107.472,
          v: 1814550000
        },
        {
          t: 3600,
          o: 107.473,
          h: 107.478,
          l: 107.431,
          c: 107.434,
          v: 1219300000
        },
        {
          t: 4500,
          o: 107.434,
          h: 107.44,
          l: 107.386,
          c: 107.42,
          v: 975100000
        },
        {
          t: 5400,
          o: 107.42,
          h: 107.42,
          l: 107.33,
          c: 107.362,
          v: 1447040000
        },
        {
          t: 6300,
          o: 107.364,
          h: 107.404,
          l: 107.364,
          c: 107.38,
          v: 1032340000
        },
        {
          t: 7200,
          o: 107.38,
          h: 107.41,
          l: 107.293,
          c: 107.311,
          v: 1373200000
        },
        {
          t: 8100,
          o: 107.311,
          h: 107.341,
          l: 107.304,
          c: 107.32,
          v: 885960000
        },
        {
          t: 9000,
          o: 107.319,
          h: 107.321,
          l: 107.268,
          c: 107.283,
          v: 784020000
        },
        {
          t: 9900,
          o: 107.282,
          h: 107.288,
          l: 107.172,
          c: 107.198,
          v: 1583430001
        },
        {
          t: 10800,
          o: 107.201,
          h: 107.228,
          l: 107.175,
          c: 107.182,
          v: 1351890000
        },
        {
          t: 11700,
          o: 107.186,
          h: 107.235,
          l: 107.179,
          c: 107.221,
          v: 816990000
        },
        {
          t: 12600,
          o: 107.221,
          h: 107.252,
          l: 107.214,
          c: 107.25,
          v: 826590000
        },
        {
          t: 13500,
          o: 107.249,
          h: 107.273,
          l: 107.237,
          c: 107.25,
          v: 788510000
        },
        {
          t: 14400,
          o: 107.25,
          h: 107.253,
          l: 107.213,
          c: 107.224,
          v: 834410000
        },
        {
          t: 15300,
          o: 107.223,
          h: 107.236,
          l: 107.193,
          c: 107.203,
          v: 916570001
        },
        {
          t: 16200,
          o: 107.204,
          h: 107.252,
          l: 107.164,
          c: 107.238,
          v: 813950000
        },
        {
          t: 17100,
          o: 107.237,
          h: 107.252,
          l: 107.23,
          c: 107.248,
          v: 431530000
        },
        {
          t: 18000,
          o: 107.248,
          h: 107.253,
          l: 107.206,
          c: 107.206,
          v: 751210000
        },
        {
          t: 18900,
          o: 107.206,
          h: 107.245,
          l: 107.182,
          c: 107.182,
          v: 962260000
        },
        {
          t: 19800,
          o: 107.182,
          h: 107.196,
          l: 107.129,
          c: 107.16,
          v: 1349810001
        },
        {
          t: 20700,
          o: 107.159,
          h: 107.222,
          l: 107.152,
          c: 107.208,
          v: 961530000
        },
        {
          t: 21600,
          o: 107.207,
          h: 107.222,
          l: 107.17,
          c: 107.22,
          v: 1303680000
        },
        {
          t: 22500,
          o: 107.222,
          h: 107.271,
          l: 107.222,
          c: 107.228,
          v: 1192939999
        },
        {
          t: 23400,
          o: 107.23,
          h: 107.314,
          l: 107.225,
          c: 107.268,
          v: 1423170000
        },
        {
          t: 24300,
          o: 107.269,
          h: 107.28,
          l: 107.212,
          c: 107.217,
          v: 1467110000
        },
        {
          t: 25200,
          o: 107.219,
          h: 107.289,
          l: 107.202,
          c: 107.247,
          v: 2134139999
        },
        {
          t: 26100,
          o: 107.248,
          h: 107.273,
          l: 107.218,
          c: 107.259,
          v: 1395030000
        },
        {
          t: 27000,
          o: 107.259,
          h: 107.275,
          l: 107.232,
          c: 107.239,
          v: 1378240000
        },
        {
          t: 27900,
          o: 107.239,
          h: 107.277,
          l: 107.198,
          c: 107.21,
          v: 1966910000
        },
        {
          t: 28800,
          o: 107.208,
          h: 107.274,
          l: 107.195,
          c: 107.255,
          v: 1658600000
        },
        {
          t: 29700,
          o: 107.254,
          h: 107.263,
          l: 107.213,
          c: 107.217,
          v: 1307180000
        },
        {
          t: 30600,
          o: 107.217,
          h: 107.228,
          l: 107.197,
          c: 107.197,
          v: 1027780000
        },
        {
          t: 31500,
          o: 107.198,
          h: 107.215,
          l: 107.181,
          c: 107.193,
          v: 1101840000
        },
        {
          t: 32400,
          o: 107.192,
          h: 107.21,
          l: 107.163,
          c: 107.184,
          v: 969570000
        },
        {
          t: 33300,
          o: 107.183,
          h: 107.22,
          l: 107.177,
          c: 107.202,
          v: 1078040000
        },
        {
          t: 34200,
          o: 107.204,
          h: 107.205,
          l: 107.15,
          c: 107.177,
          v: 1383859999
        },
        {
          t: 35100,
          o: 107.177,
          h: 107.22,
          l: 107.166,
          c: 107.202,
          v: 1262050000
        },
        {
          t: 36000,
          o: 107.201,
          h: 107.249,
          l: 107.182,
          c: 107.23,
          v: 1184180000
        },
        {
          t: 36900,
          o: 107.229,
          h: 107.259,
          l: 107.215,
          c: 107.258,
          v: 1153070000
        },
        {
          t: 37800,
          o: 107.258,
          h: 107.271,
          l: 107.213,
          c: 107.227,
          v: 779510000
        },
        {
          t: 38700,
          o: 107.227,
          h: 107.26,
          l: 107.224,
          c: 107.235,
          v: 704590000
        },
        {
          t: 39600,
          o: 107.233,
          h: 107.247,
          l: 107.219,
          c: 107.233,
          v: 905130000
        },
        {
          t: 40500,
          o: 107.233,
          h: 107.272,
          l: 107.226,
          c: 107.253,
          v: 840440000
        },
        {
          t: 41400,
          o: 107.254,
          h: 107.283,
          l: 107.246,
          c: 107.265,
          v: 1188400000
        },
        {
          t: 42300,
          o: 107.264,
          h: 107.337,
          l: 107.256,
          c: 107.335,
          v: 1038970000
        },
        {
          t: 43200,
          o: 107.335,
          h: 107.345,
          l: 107.306,
          c: 107.327,
          v: 773600000
        },
        {
          t: 44100,
          o: 107.326,
          h: 107.39,
          l: 107.322,
          c: 107.366,
          v: 972050000
        },
        {
          t: 45000,
          o: 107.376,
          h: 107.378,
          l: 107.27,
          c: 107.296,
          v: 2512760000
        },
        {
          t: 45900,
          o: 107.295,
          h: 107.343,
          l: 107.263,
          c: 107.309,
          v: 2223240000
        },
        {
          t: 46800,
          o: 107.309,
          h: 107.338,
          l: 107.279,
          c: 107.299,
          v: 1674890000
        },
        {
          t: 47700,
          o: 107.299,
          h: 107.299,
          l: 107.25,
          c: 107.252,
          v: 1296830000
        },
        {
          t: 48600,
          o: 107.253,
          h: 107.264,
          l: 107.196,
          c: 107.252,
          v: 2275200000
        },
        {
          t: 49500,
          o: 107.252,
          h: 107.284,
          l: 107.21,
          c: 107.212,
          v: 1919920001
        },
        {
          t: 50400,
          o: 107.211,
          h: 107.271,
          l: 107.207,
          c: 107.213,
          v: 2030120000
        },
        {
          t: 51300,
          o: 107.214,
          h: 107.257,
          l: 107.167,
          c: 107.242,
          v: 2279510000
        },
        {
          t: 52200,
          o: 107.242,
          h: 107.298,
          l: 107.234,
          c: 107.289,
          v: 1251900000
        },
        {
          t: 53100,
          o: 107.288,
          h: 107.289,
          l: 107.212,
          c: 107.253,
          v: 1647920000
        },
        {
          t: 54000,
          o: 107.254,
          h: 107.29,
          l: 107.247,
          c: 107.259,
          v: 1733170000
        },
        {
          t: 54900,
          o: 107.256,
          h: 107.275,
          l: 107.236,
          c: 107.26,
          v: 1692720000
        },
        {
          t: 55800,
          o: 107.263,
          h: 107.283,
          l: 107.235,
          c: 107.267,
          v: 1622580000
        },
        {
          t: 56700,
          o: 107.268,
          h: 107.28,
          l: 107.258,
          c: 107.26,
          v: 1227840000
        },
        {
          t: 57600,
          o: 107.261,
          h: 107.28,
          l: 107.254,
          c: 107.267,
          v: 1407480000
        },
        {
          t: 58500,
          o: 107.268,
          h: 107.275,
          l: 107.18,
          c: 107.185,
          v: 1357420000
        },
        {
          t: 59400,
          o: 107.186,
          h: 107.214,
          l: 107.168,
          c: 107.192,
          v: 1182830000
        },
        {
          t: 60300,
          o: 107.191,
          h: 107.192,
          l: 107.1,
          c: 107.115,
          v: 1787090000
        },
        {
          t: 61200,
          o: 107.116,
          h: 107.16,
          l: 107.116,
          c: 107.149,
          v: 1329920000
        },
        {
          t: 62100,
          o: 107.147,
          h: 107.173,
          l: 107.139,
          c: 107.143,
          v: 861290000
        },
        {
          t: 63000,
          o: 107.142,
          h: 107.16,
          l: 107.104,
          c: 107.121,
          v: 1115400001
        },
        {
          t: 63900,
          o: 107.122,
          h: 107.126,
          l: 107.047,
          c: 107.059,
          v: 1181590000
        },
        {
          t: 64800,
          o: 107.059,
          h: 107.084,
          l: 107.042,
          c: 107.047,
          v: 1408910000
        },
        {
          t: 65700,
          o: 107.045,
          h: 107.092,
          l: 107.035,
          c: 107.066,
          v: 1063270000
        },
        {
          t: 66600,
          o: 107.066,
          h: 107.081,
          l: 107.057,
          c: 107.064,
          v: 894310000
        },
        {
          t: 67500,
          o: 107.062,
          h: 107.089,
          l: 107.052,
          c: 107.086,
          v: 1139690000
        },
        {
          t: 68400,
          o: 107.087,
          h: 107.116,
          l: 107.082,
          c: 107.104,
          v: 1076760000
        },
        {
          t: 69300,
          o: 107.105,
          h: 107.131,
          l: 107.071,
          c: 107.121,
          v: 1481830000
        },
        {
          t: 70200,
          o: 107.12,
          h: 107.148,
          l: 107.115,
          c: 107.148,
          v: 991720000
        },
        {
          t: 71100,
          o: 107.148,
          h: 107.154,
          l: 107.114,
          c: 107.135,
          v: 1349960000
        },
        {
          t: 72000,
          o: 107.136,
          h: 107.144,
          l: 107.12,
          c: 107.121,
          v: 699470000
        },
        {
          t: 72900,
          o: 107.124,
          h: 107.128,
          l: 107.096,
          c: 107.098,
          v: 674270000
        },
        {
          t: 73800,
          o: 107.098,
          h: 107.115,
          l: 107.074,
          c: 107.115,
          v: 867290000
        },
        {
          t: 74700,
          o: 107.114,
          h: 107.125,
          l: 107.095,
          c: 107.108,
          v: 798470000
        },
        {
          t: 75600,
          o: 107.103,
          h: 107.124,
          l: 107.095,
          c: 107.115,
          v: 1095770000
        },
        {
          t: 76500,
          o: 107.116,
          h: 107.122,
          l: 107.114,
          c: 107.119,
          v: 997500000
        },
        {
          t: 77400,
          o: 107.12,
          h: 107.145,
          l: 107.115,
          c: 107.133,
          v: 1943770000
        },
        {
          t: 78300,
          o: 107.133,
          h: 107.148,
          l: 107.111,
          c: 107.132,
          v: 634850000
        },
        {
          t: 79200,
          o: 107.132,
          h: 107.137,
          l: 107.115,
          c: 107.124,
          v: 352750000
        },
        {
          t: 80100,
          o: 107.124,
          h: 107.126,
          l: 107.09,
          c: 107.104,
          v: 417830000
        },
        {
          t: 81000,
          o: 107.103,
          h: 107.134,
          l: 107.101,
          c: 107.109,
          v: 226410000
        },
        {
          t: 81900,
          o: 107.113,
          h: 107.127,
          l: 107.11,
          c: 107.114,
          v: 113770000
        },
        {
          t: 82800,
          o: 107.115,
          h: 107.151,
          l: 107.115,
          c: 107.142,
          v: 184540000
        },
        {
          t: 83700,
          o: 107.142,
          h: 107.158,
          l: 107.134,
          c: 107.146,
          v: 182420000
        },
        {
          t: 84600,
          o: 107.146,
          h: 107.161,
          l: 107.091,
          c: 107.094,
          v: 588400000
        },
        {
          t: 85500,
          o: 107.095,
          h: 107.111,
          l: 107.036,
          c: 107.053,
          v: 1642610000
        }
      ]
    },
    {
      d: 1523923200,
      b: [
        {
          t: 0,
          o: 107.053,
          h: 107.076,
          l: 106.997,
          c: 107.073,
          v: 1926940000
        },
        {
          t: 900,
          o: 107.074,
          h: 107.118,
          l: 107.049,
          c: 107.068,
          v: 1377100000
        },
        {
          t: 1800,
          o: 107.068,
          h: 107.098,
          l: 107.017,
          c: 107.04,
          v: 1678840000
        },
        {
          t: 2700,
          o: 107.04,
          h: 107.095,
          l: 107.036,
          c: 107.068,
          v: 1260180000
        },
        {
          t: 3600,
          o: 107.068,
          h: 107.068,
          l: 106.969,
          c: 107.019,
          v: 1617660000
        },
        {
          t: 4500,
          o: 107.02,
          h: 107.041,
          l: 106.978,
          c: 106.992,
          v: 1205350000
        },
        {
          t: 5400,
          o: 106.99,
          h: 107.035,
          l: 106.975,
          c: 106.996,
          v: 1371790000
        },
        {
          t: 6300,
          o: 106.996,
          h: 107.022,
          l: 106.971,
          c: 107.002,
          v: 1123280000
        },
        {
          t: 7200,
          o: 107.002,
          h: 107.08,
          l: 107.001,
          c: 107.066,
          v: 1275230000
        },
        {
          t: 8100,
          o: 107.066,
          h: 107.091,
          l: 107.032,
          c: 107.036,
          v: 914920000
        },
        {
          t: 9000,
          o: 107.036,
          h: 107.059,
          l: 107.029,
          c: 107.051,
          v: 912530000
        },
        {
          t: 9900,
          o: 107.051,
          h: 107.066,
          l: 107.029,
          c: 107.043,
          v: 733610000
        },
        {
          t: 10800,
          o: 107.043,
          h: 107.054,
          l: 107.02,
          c: 107.035,
          v: 827890000
        },
        {
          t: 11700,
          o: 107.035,
          h: 107.07,
          l: 107.028,
          c: 107.06,
          v: 715900000
        },
        {
          t: 12600,
          o: 107.06,
          h: 107.094,
          l: 107.049,
          c: 107.083,
          v: 728220000
        },
        {
          t: 13500,
          o: 107.082,
          h: 107.084,
          l: 107.064,
          c: 107.073,
          v: 513400000
        },
        {
          t: 14400,
          o: 107.072,
          h: 107.081,
          l: 107.042,
          c: 107.055,
          v: 617620000
        },
        {
          t: 15300,
          o: 107.055,
          h: 107.055,
          l: 107.024,
          c: 107.048,
          v: 539560000
        },
        {
          t: 16200,
          o: 107.048,
          h: 107.059,
          l: 107.031,
          c: 107.05,
          v: 411020000
        },
        {
          t: 17100,
          o: 107.049,
          h: 107.067,
          l: 107.043,
          c: 107.058,
          v: 700730000
        },
        {
          t: 18000,
          o: 107.058,
          h: 107.065,
          l: 107.02,
          c: 107.021,
          v: 769650000
        },
        {
          t: 18900,
          o: 107.021,
          h: 107.035,
          l: 106.989,
          c: 106.998,
          v: 869100000
        },
        {
          t: 19800,
          o: 106.998,
          h: 107.042,
          l: 106.992,
          c: 107.029,
          v: 977210000
        },
        {
          t: 20700,
          o: 107.029,
          h: 107.029,
          l: 106.989,
          c: 106.999,
          v: 1084200000
        },
        {
          t: 21600,
          o: 106.999,
          h: 107.025,
          l: 106.974,
          c: 106.979,
          v: 1400320000
        },
        {
          t: 22500,
          o: 106.979,
          h: 106.985,
          l: 106.915,
          c: 106.947,
          v: 1690340000
        },
        {
          t: 23400,
          o: 106.946,
          h: 106.967,
          l: 106.917,
          c: 106.957,
          v: 1053990000
        },
        {
          t: 24300,
          o: 106.959,
          h: 106.959,
          l: 106.904,
          c: 106.916,
          v: 1458420000
        },
        {
          t: 25200,
          o: 106.915,
          h: 106.967,
          l: 106.883,
          c: 106.952,
          v: 1604000000
        },
        {
          t: 26100,
          o: 106.954,
          h: 106.959,
          l: 106.922,
          c: 106.938,
          v: 1348240000
        },
        {
          t: 27000,
          o: 106.94,
          h: 106.97,
          l: 106.932,
          c: 106.961,
          v: 1089280000
        },
        {
          t: 27900,
          o: 106.962,
          h: 107.009,
          l: 106.955,
          c: 106.997,
          v: 1309350000
        },
        {
          t: 28800,
          o: 106.995,
          h: 107.016,
          l: 106.972,
          c: 106.973,
          v: 1139349999
        },
        {
          t: 29700,
          o: 106.973,
          h: 106.987,
          l: 106.961,
          c: 106.985,
          v: 704180000
        },
        {
          t: 30600,
          o: 106.985,
          h: 106.991,
          l: 106.917,
          c: 106.935,
          v: 1636960000
        },
        {
          t: 31500,
          o: 106.934,
          h: 106.979,
          l: 106.927,
          c: 106.969,
          v: 1417230000
        },
        {
          t: 32400,
          o: 106.969,
          h: 107.008,
          l: 106.95,
          c: 107.002,
          v: 1133650000
        },
        {
          t: 33300,
          o: 107.003,
          h: 107.039,
          l: 106.987,
          c: 107.038,
          v: 945220000
        },
        {
          t: 34200,
          o: 107.037,
          h: 107.066,
          l: 107.029,
          c: 107.041,
          v: 1140890000
        },
        {
          t: 35100,
          o: 107.041,
          h: 107.07,
          l: 107.031,
          c: 107.058,
          v: 1138550000
        },
        {
          t: 36000,
          o: 107.059,
          h: 107.07,
          l: 107.028,
          c: 107.058,
          v: 1127490000
        },
        {
          t: 36900,
          o: 107.056,
          h: 107.065,
          l: 107.035,
          c: 107.052,
          v: 1314300000
        },
        {
          t: 37800,
          o: 107.051,
          h: 107.093,
          l: 107.049,
          c: 107.071,
          v: 1195830000
        },
        {
          t: 38700,
          o: 107.074,
          h: 107.083,
          l: 107.029,
          c: 107.078,
          v: 1060740000
        },
        {
          t: 39600,
          o: 107.077,
          h: 107.094,
          l: 107.072,
          c: 107.079,
          v: 1041020000
        },
        {
          t: 40500,
          o: 107.079,
          h: 107.099,
          l: 107.055,
          c: 107.065,
          v: 788630000
        },
        {
          t: 41400,
          o: 107.066,
          h: 107.083,
          l: 107.029,
          c: 107.035,
          v: 911220001
        },
        {
          t: 42300,
          o: 107.036,
          h: 107.091,
          l: 107.03,
          c: 107.091,
          v: 927290000
        },
        {
          t: 43200,
          o: 107.091,
          h: 107.113,
          l: 107.085,
          c: 107.107,
          v: 1282300000
        },
        {
          t: 44100,
          o: 107.109,
          h: 107.138,
          l: 107.076,
          c: 107.076,
          v: 1153710000
        },
        {
          t: 45000,
          o: 107.079,
          h: 107.163,
          l: 107.076,
          c: 107.139,
          v: 2212799991
        },
        {
          t: 45900,
          o: 107.139,
          h: 107.168,
          l: 107.131,
          c: 107.141,
          v: 1960850000
        },
        {
          t: 46800,
          o: 107.142,
          h: 107.152,
          l: 107.105,
          c: 107.125,
          v: 1408490001
        },
        {
          t: 47700,
          o: 107.124,
          h: 107.139,
          l: 107.103,
          c: 107.127,
          v: 1600590001
        },
        {
          t: 48600,
          o: 107.127,
          h: 107.136,
          l: 107.076,
          c: 107.082,
          v: 2154680000
        },
        {
          t: 49500,
          o: 107.083,
          h: 107.128,
          l: 107.083,
          c: 107.125,
          v: 2016280000
        },
        {
          t: 50400,
          o: 107.126,
          h: 107.158,
          l: 107.121,
          c: 107.144,
          v: 1977380000
        },
        {
          t: 51300,
          o: 107.145,
          h: 107.211,
          l: 107.131,
          c: 107.194,
          v: 1818790000
        },
        {
          t: 52200,
          o: 107.195,
          h: 107.204,
          l: 107.149,
          c: 107.16,
          v: 1757400000
        },
        {
          t: 53100,
          o: 107.159,
          h: 107.159,
          l: 107.12,
          c: 107.142,
          v: 1335180000
        },
        {
          t: 54000,
          o: 107.142,
          h: 107.158,
          l: 107.107,
          c: 107.123,
          v: 1600380000
        },
        {
          t: 54900,
          o: 107.122,
          h: 107.14,
          l: 107.108,
          c: 107.134,
          v: 1332210000
        },
        {
          t: 55800,
          o: 107.136,
          h: 107.144,
          l: 107.107,
          c: 107.112,
          v: 1405170000
        },
        {
          t: 56700,
          o: 107.113,
          h: 107.149,
          l: 107.086,
          c: 107.087,
          v: 1385960000
        },
        {
          t: 57600,
          o: 107.088,
          h: 107.126,
          l: 107.066,
          c: 107.069,
          v: 1549360000
        },
        {
          t: 58500,
          o: 107.069,
          h: 107.088,
          l: 107.043,
          c: 107.055,
          v: 1215250000
        },
        {
          t: 59400,
          o: 107.056,
          h: 107.075,
          l: 107.012,
          c: 107.024,
          v: 1436660000
        },
        {
          t: 60300,
          o: 107.023,
          h: 107.023,
          l: 106.935,
          c: 106.943,
          v: 1484980000
        },
        {
          t: 61200,
          o: 106.943,
          h: 106.955,
          l: 106.896,
          c: 106.929,
          v: 1823149998
        },
        {
          t: 62100,
          o: 106.93,
          h: 107.001,
          l: 106.896,
          c: 106.997,
          v: 1977270000
        },
        {
          t: 63000,
          o: 106.997,
          h: 107.021,
          l: 106.985,
          c: 107.014,
          v: 1495210001
        },
        {
          t: 63900,
          o: 107.014,
          h: 107.015,
          l: 106.986,
          c: 106.999,
          v: 1124750000
        },
        {
          t: 64800,
          o: 106.998,
          h: 107.031,
          l: 106.966,
          c: 106.972,
          v: 1279330000
        },
        {
          t: 65700,
          o: 106.97,
          h: 106.981,
          l: 106.951,
          c: 106.96,
          v: 1792170000
        },
        {
          t: 66600,
          o: 106.959,
          h: 106.989,
          l: 106.945,
          c: 106.987,
          v: 1086910000
        },
        {
          t: 67500,
          o: 106.987,
          h: 107.025,
          l: 106.986,
          c: 107.004,
          v: 1135900000
        },
        {
          t: 68400,
          o: 107.004,
          h: 107.047,
          l: 106.995,
          c: 107.029,
          v: 1391180000
        },
        {
          t: 69300,
          o: 107.029,
          h: 107.04,
          l: 106.988,
          c: 106.998,
          v: 1186150000
        },
        {
          t: 70200,
          o: 106.997,
          h: 107.047,
          l: 106.997,
          c: 107.025,
          v: 1053140000
        },
        {
          t: 71100,
          o: 107.024,
          h: 107.028,
          l: 107.003,
          c: 107.015,
          v: 1830320018
        },
        {
          t: 72000,
          o: 107.015,
          h: 107.04,
          l: 107.014,
          c: 107.034,
          v: 574910000
        },
        {
          t: 72900,
          o: 107.032,
          h: 107.041,
          l: 107.006,
          c: 107.019,
          v: 568560000
        },
        {
          t: 73800,
          o: 107.018,
          h: 107.031,
          l: 107.007,
          c: 107.018,
          v: 930040000
        },
        {
          t: 74700,
          o: 107.018,
          h: 107.019,
          l: 106.992,
          c: 107.004,
          v: 933100000
        },
        {
          t: 75600,
          o: 107.003,
          h: 107.036,
          l: 106.987,
          c: 106.993,
          v: 1740760000
        },
        {
          t: 76500,
          o: 106.994,
          h: 107.03,
          l: 106.988,
          c: 107.007,
          v: 1668850000
        },
        {
          t: 77400,
          o: 107.008,
          h: 107.041,
          l: 107.008,
          c: 107.024,
          v: 2326280000
        },
        {
          t: 78300,
          o: 107.023,
          h: 107.036,
          l: 107.007,
          c: 107.012,
          v: 932610000
        },
        {
          t: 79200,
          o: 107.011,
          h: 107.031,
          l: 107.002,
          c: 107.026,
          v: 612960000
        },
        {
          t: 80100,
          o: 107.024,
          h: 107.04,
          l: 107.014,
          c: 107.034,
          v: 227630000
        },
        {
          t: 81000,
          o: 107.032,
          h: 107.054,
          l: 107.031,
          c: 107.038,
          v: 607570000
        },
        {
          t: 81900,
          o: 107.038,
          h: 107.049,
          l: 107.023,
          c: 107.033,
          v: 236700000
        },
        {
          t: 82800,
          o: 107.032,
          h: 107.037,
          l: 107.014,
          c: 107.032,
          v: 363890000
        },
        {
          t: 83700,
          o: 107.032,
          h: 107.053,
          l: 107.02,
          c: 107.05,
          v: 217940000
        },
        {
          t: 84600,
          o: 107.048,
          h: 107.06,
          l: 107.033,
          c: 107.05,
          v: 404020000
        },
        {
          t: 85500,
          o: 107.05,
          h: 107.063,
          l: 107.007,
          c: 107.021,
          v: 748520000
        }
      ]
    },
    {
      d: 1524009600,
      b: [
        {
          t: 0,
          o: 107.021,
          h: 107.115,
          l: 107.008,
          c: 107.112,
          v: 1564970000
        },
        {
          t: 900,
          o: 107.113,
          h: 107.147,
          l: 107.101,
          c: 107.123,
          v: 1300880000
        },
        {
          t: 1800,
          o: 107.125,
          h: 107.176,
          l: 107.117,
          c: 107.165,
          v: 1573590000
        },
        {
          t: 2700,
          o: 107.165,
          h: 107.207,
          l: 107.15,
          c: 107.183,
          v: 2195590000
        },
        {
          t: 3600,
          o: 107.183,
          h: 107.208,
          l: 107.18,
          c: 107.198,
          v: 972080000
        },
        {
          t: 4500,
          o: 107.2,
          h: 107.299,
          l: 107.2,
          c: 107.288,
          v: 2040820000
        },
        {
          t: 5400,
          o: 107.287,
          h: 107.293,
          l: 107.251,
          c: 107.261,
          v: 1201380000
        },
        {
          t: 6300,
          o: 107.262,
          h: 107.324,
          l: 107.262,
          c: 107.29,
          v: 1701200000
        },
        {
          t: 7200,
          o: 107.29,
          h: 107.295,
          l: 107.24,
          c: 107.244,
          v: 1107870000
        },
        {
          t: 8100,
          o: 107.243,
          h: 107.265,
          l: 107.24,
          c: 107.247,
          v: 638549999
        },
        {
          t: 9000,
          o: 107.248,
          h: 107.255,
          l: 107.231,
          c: 107.233,
          v: 481540000
        },
        {
          t: 9900,
          o: 107.236,
          h: 107.262,
          l: 107.232,
          c: 107.243,
          v: 350080000
        },
        {
          t: 10800,
          o: 107.244,
          h: 107.266,
          l: 107.244,
          c: 107.261,
          v: 491460001
        },
        {
          t: 11700,
          o: 107.261,
          h: 107.281,
          l: 107.258,
          c: 107.279,
          v: 682070000
        },
        {
          t: 12600,
          o: 107.28,
          h: 107.305,
          l: 107.274,
          c: 107.275,
          v: 829880000
        },
        {
          t: 13500,
          o: 107.275,
          h: 107.353,
          l: 107.275,
          c: 107.322,
          v: 1606290000
        },
        {
          t: 14400,
          o: 107.322,
          h: 107.327,
          l: 107.3,
          c: 107.301,
          v: 521210000
        },
        {
          t: 15300,
          o: 107.303,
          h: 107.331,
          l: 107.296,
          c: 107.323,
          v: 617700000
        },
        {
          t: 16200,
          o: 107.324,
          h: 107.342,
          l: 107.312,
          c: 107.333,
          v: 638140000
        },
        {
          t: 17100,
          o: 107.333,
          h: 107.375,
          l: 107.333,
          c: 107.351,
          v: 987410000
        },
        {
          t: 18000,
          o: 107.351,
          h: 107.369,
          l: 107.31,
          c: 107.346,
          v: 1165380000
        },
        {
          t: 18900,
          o: 107.348,
          h: 107.383,
          l: 107.344,
          c: 107.345,
          v: 1064210000
        },
        {
          t: 19800,
          o: 107.345,
          h: 107.379,
          l: 107.345,
          c: 107.361,
          v: 953740001
        },
        {
          t: 20700,
          o: 107.36,
          h: 107.378,
          l: 107.336,
          c: 107.339,
          v: 727550000
        },
        {
          t: 21600,
          o: 107.341,
          h: 107.357,
          l: 107.304,
          c: 107.343,
          v: 1560720000
        },
        {
          t: 22500,
          o: 107.342,
          h: 107.35,
          l: 107.321,
          c: 107.339,
          v: 1406180000
        },
        {
          t: 23400,
          o: 107.339,
          h: 107.351,
          l: 107.283,
          c: 107.313,
          v: 1268470000
        },
        {
          t: 24300,
          o: 107.313,
          h: 107.33,
          l: 107.282,
          c: 107.303,
          v: 1547850000
        },
        {
          t: 25200,
          o: 107.306,
          h: 107.376,
          l: 107.277,
          c: 107.344,
          v: 2326030000
        },
        {
          t: 26100,
          o: 107.344,
          h: 107.35,
          l: 107.283,
          c: 107.3,
          v: 1956160000
        },
        {
          t: 27000,
          o: 107.3,
          h: 107.321,
          l: 107.277,
          c: 107.309,
          v: 1584640000
        },
        {
          t: 27900,
          o: 107.309,
          h: 107.319,
          l: 107.274,
          c: 107.306,
          v: 1460560000
        },
        {
          t: 28800,
          o: 107.304,
          h: 107.335,
          l: 107.299,
          c: 107.325,
          v: 1359800000
        },
        {
          t: 29700,
          o: 107.325,
          h: 107.348,
          l: 107.312,
          c: 107.322,
          v: 1211910000
        },
        {
          t: 30600,
          o: 107.321,
          h: 107.328,
          l: 107.271,
          c: 107.293,
          v: 1732970000
        },
        {
          t: 31500,
          o: 107.293,
          h: 107.305,
          l: 107.256,
          c: 107.281,
          v: 1466060000
        },
        {
          t: 32400,
          o: 107.281,
          h: 107.323,
          l: 107.281,
          c: 107.306,
          v: 1326610000
        },
        {
          t: 33300,
          o: 107.307,
          h: 107.316,
          l: 107.283,
          c: 107.29,
          v: 788940000
        },
        {
          t: 34200,
          o: 107.289,
          h: 107.291,
          l: 107.245,
          c: 107.259,
          v: 1104280000
        },
        {
          t: 35100,
          o: 107.258,
          h: 107.281,
          l: 107.252,
          c: 107.273,
          v: 1038870000
        },
        {
          t: 36000,
          o: 107.27,
          h: 107.275,
          l: 107.24,
          c: 107.258,
          v: 633600000
        },
        {
          t: 36900,
          o: 107.26,
          h: 107.266,
          l: 107.242,
          c: 107.249,
          v: 536620000
        },
        {
          t: 37800,
          o: 107.25,
          h: 107.257,
          l: 107.224,
          c: 107.224,
          v: 810400000
        },
        {
          t: 38700,
          o: 107.224,
          h: 107.239,
          l: 107.204,
          c: 107.227,
          v: 887700000
        },
        {
          t: 39600,
          o: 107.226,
          h: 107.234,
          l: 107.191,
          c: 107.196,
          v: 1007850000
        },
        {
          t: 40500,
          o: 107.196,
          h: 107.208,
          l: 107.165,
          c: 107.181,
          v: 1006850000
        },
        {
          t: 41400,
          o: 107.18,
          h: 107.195,
          l: 107.167,
          c: 107.191,
          v: 845030000
        },
        {
          t: 42300,
          o: 107.19,
          h: 107.239,
          l: 107.188,
          c: 107.233,
          v: 881260000
        },
        {
          t: 43200,
          o: 107.233,
          h: 107.245,
          l: 107.211,
          c: 107.22,
          v: 1111940000
        },
        {
          t: 44100,
          o: 107.218,
          h: 107.231,
          l: 107.199,
          c: 107.201,
          v: 1107130000
        },
        {
          t: 45000,
          o: 107.203,
          h: 107.241,
          l: 107.2,
          c: 107.232,
          v: 1039380000
        },
        {
          t: 45900,
          o: 107.232,
          h: 107.247,
          l: 107.214,
          c: 107.226,
          v: 1076560000
        },
        {
          t: 46800,
          o: 107.224,
          h: 107.241,
          l: 107.206,
          c: 107.229,
          v: 1487510000
        },
        {
          t: 47700,
          o: 107.229,
          h: 107.229,
          l: 107.166,
          c: 107.171,
          v: 1415470000
        },
        {
          t: 48600,
          o: 107.17,
          h: 107.192,
          l: 107.145,
          c: 107.165,
          v: 2087140000
        },
        {
          t: 49500,
          o: 107.167,
          h: 107.218,
          l: 107.161,
          c: 107.185,
          v: 1712699999
        },
        {
          t: 50400,
          o: 107.185,
          h: 107.186,
          l: 107.097,
          c: 107.134,
          v: 2361600000
        },
        {
          t: 51300,
          o: 107.134,
          h: 107.153,
          l: 107.101,
          c: 107.144,
          v: 1624900000
        },
        {
          t: 52200,
          o: 107.143,
          h: 107.17,
          l: 107.12,
          c: 107.149,
          v: 1771520001
        },
        {
          t: 53100,
          o: 107.15,
          h: 107.241,
          l: 107.15,
          c: 107.236,
          v: 2187750000
        },
        {
          t: 54000,
          o: 107.238,
          h: 107.288,
          l: 107.223,
          c: 107.258,
          v: 2079790000
        },
        {
          t: 54900,
          o: 107.258,
          h: 107.299,
          l: 107.248,
          c: 107.279,
          v: 1753550000
        },
        {
          t: 55800,
          o: 107.28,
          h: 107.284,
          l: 107.245,
          c: 107.258,
          v: 1689720000
        },
        {
          t: 56700,
          o: 107.257,
          h: 107.276,
          l: 107.246,
          c: 107.269,
          v: 1366960000
        },
        {
          t: 57600,
          o: 107.269,
          h: 107.306,
          l: 107.253,
          c: 107.263,
          v: 1206510000
        },
        {
          t: 58500,
          o: 107.262,
          h: 107.265,
          l: 107.197,
          c: 107.199,
          v: 1377160000
        },
        {
          t: 59400,
          o: 107.199,
          h: 107.208,
          l: 107.179,
          c: 107.182,
          v: 1211170000
        },
        {
          t: 60300,
          o: 107.181,
          h: 107.212,
          l: 107.151,
          c: 107.165,
          v: 1405850000
        },
        {
          t: 61200,
          o: 107.164,
          h: 107.228,
          l: 107.159,
          c: 107.228,
          v: 1117100000
        },
        {
          t: 62100,
          o: 107.228,
          h: 107.263,
          l: 107.227,
          c: 107.258,
          v: 1190320000
        },
        {
          t: 63000,
          o: 107.258,
          h: 107.271,
          l: 107.231,
          c: 107.256,
          v: 1370460000
        },
        {
          t: 63900,
          o: 107.257,
          h: 107.273,
          l: 107.205,
          c: 107.219,
          v: 1308100000
        },
        {
          t: 64800,
          o: 107.219,
          h: 107.226,
          l: 107.196,
          c: 107.206,
          v: 1604980000
        },
        {
          t: 65700,
          o: 107.205,
          h: 107.214,
          l: 107.18,
          c: 107.193,
          v: 871190000
        },
        {
          t: 66600,
          o: 107.191,
          h: 107.246,
          l: 107.181,
          c: 107.246,
          v: 1110790000
        },
        {
          t: 67500,
          o: 107.246,
          h: 107.274,
          l: 107.24,
          c: 107.263,
          v: 1131440000
        },
        {
          t: 68400,
          o: 107.264,
          h: 107.315,
          l: 107.262,
          c: 107.286,
          v: 1492770000
        },
        {
          t: 69300,
          o: 107.28,
          h: 107.283,
          l: 107.245,
          c: 107.251,
          v: 980300000
        },
        {
          t: 70200,
          o: 107.251,
          h: 107.257,
          l: 107.222,
          c: 107.226,
          v: 828970000
        },
        {
          t: 71100,
          o: 107.226,
          h: 107.245,
          l: 107.206,
          c: 107.221,
          v: 1122860000
        },
        {
          t: 72000,
          o: 107.22,
          h: 107.25,
          l: 107.21,
          c: 107.233,
          v: 628700000
        },
        {
          t: 72900,
          o: 107.233,
          h: 107.243,
          l: 107.21,
          c: 107.214,
          v: 636240000
        },
        {
          t: 73800,
          o: 107.215,
          h: 107.224,
          l: 107.207,
          c: 107.221,
          v: 667870000
        },
        {
          t: 74700,
          o: 107.221,
          h: 107.233,
          l: 107.212,
          c: 107.23,
          v: 717960000
        },
        {
          t: 75600,
          o: 107.23,
          h: 107.241,
          l: 107.213,
          c: 107.22,
          v: 1292940000
        },
        {
          t: 76500,
          o: 107.22,
          h: 107.233,
          l: 107.193,
          c: 107.219,
          v: 1525970000
        },
        {
          t: 77400,
          o: 107.219,
          h: 107.219,
          l: 107.19,
          c: 107.207,
          v: 1554070000
        },
        {
          t: 78300,
          o: 107.205,
          h: 107.23,
          l: 107.188,
          c: 107.205,
          v: 932660000
        },
        {
          t: 79200,
          o: 107.205,
          h: 107.21,
          l: 107.184,
          c: 107.195,
          v: 380770000
        },
        {
          t: 80100,
          o: 107.194,
          h: 107.232,
          l: 107.186,
          c: 107.229,
          v: 277040000
        },
        {
          t: 81000,
          o: 107.229,
          h: 107.247,
          l: 107.2,
          c: 107.2,
          v: 384490000
        },
        {
          t: 81900,
          o: 107.202,
          h: 107.24,
          l: 107.181,
          c: 107.213,
          v: 929970001
        },
        {
          t: 82800,
          o: 107.214,
          h: 107.245,
          l: 107.211,
          c: 107.24,
          v: 484450000
        },
        {
          t: 83700,
          o: 107.24,
          h: 107.262,
          l: 107.237,
          c: 107.257,
          v: 406620000
        },
        {
          t: 84600,
          o: 107.256,
          h: 107.352,
          l: 107.246,
          c: 107.346,
          v: 911940000
        },
        {
          t: 85500,
          o: 107.346,
          h: 107.36,
          l: 107.33,
          c: 107.355,
          v: 876180000
        }
      ]
    },
    {
      d: 1524096000,
      b: [
        {
          t: 0,
          o: 107.357,
          h: 107.379,
          l: 107.33,
          c: 107.363,
          v: 1416200000
        },
        {
          t: 900,
          o: 107.364,
          h: 107.382,
          l: 107.33,
          c: 107.378,
          v: 1057180000
        },
        {
          t: 1800,
          o: 107.377,
          h: 107.429,
          l: 107.377,
          c: 107.389,
          v: 1342310000
        },
        {
          t: 2700,
          o: 107.389,
          h: 107.467,
          l: 107.373,
          c: 107.434,
          v: 1923610000
        },
        {
          t: 3600,
          o: 107.434,
          h: 107.457,
          l: 107.427,
          c: 107.454,
          v: 1329680000
        },
        {
          t: 4500,
          o: 107.453,
          h: 107.508,
          l: 107.448,
          c: 107.489,
          v: 1334770000
        },
        {
          t: 5400,
          o: 107.489,
          h: 107.508,
          l: 107.417,
          c: 107.507,
          v: 1945269999
        },
        {
          t: 6300,
          o: 107.51,
          h: 107.515,
          l: 107.473,
          c: 107.478,
          v: 1315730000
        },
        {
          t: 7200,
          o: 107.478,
          h: 107.484,
          l: 107.451,
          c: 107.454,
          v: 929350001
        },
        {
          t: 8100,
          o: 107.453,
          h: 107.483,
          l: 107.447,
          c: 107.477,
          v: 656220000
        },
        {
          t: 9000,
          o: 107.478,
          h: 107.483,
          l: 107.431,
          c: 107.436,
          v: 862660000
        },
        {
          t: 9900,
          o: 107.436,
          h: 107.451,
          l: 107.41,
          c: 107.42,
          v: 856350000
        },
        {
          t: 10800,
          o: 107.421,
          h: 107.444,
          l: 107.372,
          c: 107.397,
          v: 1741630000
        },
        {
          t: 11700,
          o: 107.397,
          h: 107.423,
          l: 107.393,
          c: 107.415,
          v: 753080000
        },
        {
          t: 12600,
          o: 107.416,
          h: 107.432,
          l: 107.396,
          c: 107.402,
          v: 817510000
        },
        {
          t: 13500,
          o: 107.404,
          h: 107.419,
          l: 107.384,
          c: 107.413,
          v: 811790000
        },
        {
          t: 14400,
          o: 107.413,
          h: 107.426,
          l: 107.384,
          c: 107.397,
          v: 850649999
        },
        {
          t: 15300,
          o: 107.397,
          h: 107.41,
          l: 107.358,
          c: 107.359,
          v: 836710000
        },
        {
          t: 16200,
          o: 107.36,
          h: 107.412,
          l: 107.36,
          c: 107.408,
          v: 904000000
        },
        {
          t: 17100,
          o: 107.408,
          h: 107.431,
          l: 107.402,
          c: 107.431,
          v: 600100000
        },
        {
          t: 18000,
          o: 107.431,
          h: 107.442,
          l: 107.407,
          c: 107.438,
          v: 707710000
        },
        {
          t: 18900,
          o: 107.438,
          h: 107.448,
          l: 107.426,
          c: 107.427,
          v: 535030000
        },
        {
          t: 19800,
          o: 107.427,
          h: 107.429,
          l: 107.392,
          c: 107.408,
          v: 928470000
        },
        {
          t: 20700,
          o: 107.407,
          h: 107.428,
          l: 107.373,
          c: 107.422,
          v: 1068410000
        },
        {
          t: 21600,
          o: 107.422,
          h: 107.47,
          l: 107.411,
          c: 107.465,
          v: 1188880000
        },
        {
          t: 22500,
          o: 107.464,
          h: 107.469,
          l: 107.409,
          c: 107.411,
          v: 2019270000
        },
        {
          t: 23400,
          o: 107.411,
          h: 107.415,
          l: 107.376,
          c: 107.382,
          v: 1583200000
        },
        {
          t: 24300,
          o: 107.381,
          h: 107.395,
          l: 107.364,
          c: 107.373,
          v: 1018780000
        },
        {
          t: 25200,
          o: 107.373,
          h: 107.385,
          l: 107.286,
          c: 107.286,
          v: 1780540001
        },
        {
          t: 26100,
          o: 107.287,
          h: 107.324,
          l: 107.269,
          c: 107.309,
          v: 1512290000
        },
        {
          t: 27000,
          o: 107.308,
          h: 107.325,
          l: 107.293,
          c: 107.297,
          v: 1116690000
        },
        {
          t: 27900,
          o: 107.298,
          h: 107.3,
          l: 107.27,
          c: 107.288,
          v: 1141310000
        },
        {
          t: 28800,
          o: 107.288,
          h: 107.337,
          l: 107.262,
          c: 107.332,
          v: 1519270000
        },
        {
          t: 29700,
          o: 107.333,
          h: 107.359,
          l: 107.327,
          c: 107.344,
          v: 1384780000
        },
        {
          t: 30600,
          o: 107.343,
          h: 107.405,
          l: 107.32,
          c: 107.378,
          v: 1847890000
        },
        {
          t: 31500,
          o: 107.377,
          h: 107.401,
          l: 107.352,
          c: 107.36,
          v: 1032440000
        },
        {
          t: 32400,
          o: 107.361,
          h: 107.398,
          l: 107.353,
          c: 107.397,
          v: 1461560000
        },
        {
          t: 33300,
          o: 107.397,
          h: 107.431,
          l: 107.368,
          c: 107.392,
          v: 1486140000
        },
        {
          t: 34200,
          o: 107.395,
          h: 107.396,
          l: 107.361,
          c: 107.369,
          v: 1279520000
        },
        {
          t: 35100,
          o: 107.369,
          h: 107.385,
          l: 107.367,
          c: 107.372,
          v: 757520000
        },
        {
          t: 36000,
          o: 107.372,
          h: 107.406,
          l: 107.358,
          c: 107.384,
          v: 913790000
        },
        {
          t: 36900,
          o: 107.385,
          h: 107.456,
          l: 107.375,
          c: 107.432,
          v: 1367270000
        },
        {
          t: 37800,
          o: 107.434,
          h: 107.439,
          l: 107.399,
          c: 107.417,
          v: 903820000
        },
        {
          t: 38700,
          o: 107.417,
          h: 107.423,
          l: 107.384,
          c: 107.413,
          v: 1007300000
        },
        {
          t: 39600,
          o: 107.414,
          h: 107.44,
          l: 107.41,
          c: 107.439,
          v: 930610000
        },
        {
          t: 40500,
          o: 107.439,
          h: 107.439,
          l: 107.393,
          c: 107.393,
          v: 1153560000
        },
        {
          t: 41400,
          o: 107.393,
          h: 107.416,
          l: 107.379,
          c: 107.393,
          v: 789730000
        },
        {
          t: 42300,
          o: 107.393,
          h: 107.403,
          l: 107.356,
          c: 107.358,
          v: 900670001
        },
        {
          t: 43200,
          o: 107.358,
          h: 107.391,
          l: 107.317,
          c: 107.319,
          v: 1794700000
        },
        {
          t: 44100,
          o: 107.32,
          h: 107.392,
          l: 107.313,
          c: 107.366,
          v: 1157310000
        },
        {
          t: 45000,
          o: 107.359,
          h: 107.396,
          l: 107.326,
          c: 107.342,
          v: 1599540000
        },
        {
          t: 45900,
          o: 107.341,
          h: 107.365,
          l: 107.316,
          c: 107.348,
          v: 1477700000
        },
        {
          t: 46800,
          o: 107.349,
          h: 107.377,
          l: 107.326,
          c: 107.364,
          v: 1271700000
        },
        {
          t: 47700,
          o: 107.361,
          h: 107.373,
          l: 107.34,
          c: 107.364,
          v: 1214750000
        },
        {
          t: 48600,
          o: 107.365,
          h: 107.421,
          l: 107.327,
          c: 107.404,
          v: 2832720000
        },
        {
          t: 49500,
          o: 107.405,
          h: 107.412,
          l: 107.342,
          c: 107.385,
          v: 1901670000
        },
        {
          t: 50400,
          o: 107.385,
          h: 107.4,
          l: 107.332,
          c: 107.364,
          v: 2218790001
        },
        {
          t: 51300,
          o: 107.364,
          h: 107.385,
          l: 107.339,
          c: 107.348,
          v: 1637720000
        },
        {
          t: 52200,
          o: 107.348,
          h: 107.378,
          l: 107.331,
          c: 107.367,
          v: 1986730000
        },
        {
          t: 53100,
          o: 107.366,
          h: 107.377,
          l: 107.33,
          c: 107.362,
          v: 1890669999
        },
        {
          t: 54000,
          o: 107.361,
          h: 107.417,
          l: 107.348,
          c: 107.374,
          v: 2250410000
        },
        {
          t: 54900,
          o: 107.373,
          h: 107.435,
          l: 107.363,
          c: 107.426,
          v: 1903030000
        },
        {
          t: 55800,
          o: 107.427,
          h: 107.452,
          l: 107.392,
          c: 107.447,
          v: 2629920000
        },
        {
          t: 56700,
          o: 107.448,
          h: 107.469,
          l: 107.426,
          c: 107.428,
          v: 2209110000
        },
        {
          t: 57600,
          o: 107.428,
          h: 107.446,
          l: 107.371,
          c: 107.382,
          v: 1831280000
        },
        {
          t: 58500,
          o: 107.381,
          h: 107.392,
          l: 107.363,
          c: 107.368,
          v: 1449390000
        },
        {
          t: 59400,
          o: 107.366,
          h: 107.395,
          l: 107.36,
          c: 107.377,
          v: 967810000
        },
        {
          t: 60300,
          o: 107.377,
          h: 107.385,
          l: 107.26,
          c: 107.275,
          v: 1716780000
        },
        {
          t: 61200,
          o: 107.274,
          h: 107.313,
          l: 107.239,
          c: 107.279,
          v: 1682700000
        },
        {
          t: 62100,
          o: 107.279,
          h: 107.323,
          l: 107.269,
          c: 107.295,
          v: 1592740000
        },
        {
          t: 63000,
          o: 107.294,
          h: 107.329,
          l: 107.271,
          c: 107.315,
          v: 1794020000
        },
        {
          t: 63900,
          o: 107.314,
          h: 107.325,
          l: 107.289,
          c: 107.319,
          v: 1507519999
        },
        {
          t: 64800,
          o: 107.319,
          h: 107.378,
          l: 107.318,
          c: 107.362,
          v: 1664530000
        },
        {
          t: 65700,
          o: 107.362,
          h: 107.368,
          l: 107.35,
          c: 107.363,
          v: 1293710000
        },
        {
          t: 66600,
          o: 107.363,
          h: 107.391,
          l: 107.353,
          c: 107.384,
          v: 1495740000
        },
        {
          t: 67500,
          o: 107.384,
          h: 107.421,
          l: 107.377,
          c: 107.398,
          v: 1859740000
        },
        {
          t: 68400,
          o: 107.401,
          h: 107.413,
          l: 107.364,
          c: 107.388,
          v: 1239270000
        },
        {
          t: 69300,
          o: 107.388,
          h: 107.402,
          l: 107.376,
          c: 107.385,
          v: 750700000
        },
        {
          t: 70200,
          o: 107.384,
          h: 107.505,
          l: 107.37,
          c: 107.418,
          v: 2815300000
        },
        {
          t: 71100,
          o: 107.418,
          h: 107.42,
          l: 107.315,
          c: 107.352,
          v: 1744180000
        },
        {
          t: 72000,
          o: 107.352,
          h: 107.375,
          l: 107.349,
          c: 107.369,
          v: 695510000
        },
        {
          t: 72900,
          o: 107.369,
          h: 107.373,
          l: 107.361,
          c: 107.362,
          v: 433710000
        },
        {
          t: 73800,
          o: 107.362,
          h: 107.37,
          l: 107.352,
          c: 107.353,
          v: 477730001
        },
        {
          t: 74700,
          o: 107.353,
          h: 107.369,
          l: 107.349,
          c: 107.363,
          v: 532610000
        },
        {
          t: 75600,
          o: 107.361,
          h: 107.378,
          l: 107.352,
          c: 107.372,
          v: 2003460000
        },
        {
          t: 76500,
          o: 107.372,
          h: 107.375,
          l: 107.353,
          c: 107.359,
          v: 1220650000
        },
        {
          t: 77400,
          o: 107.362,
          h: 107.366,
          l: 107.353,
          c: 107.364,
          v: 2965650000
        },
        {
          t: 78300,
          o: 107.363,
          h: 107.415,
          l: 107.358,
          c: 107.391,
          v: 1170270000
        },
        {
          t: 79200,
          o: 107.39,
          h: 107.435,
          l: 107.385,
          c: 107.418,
          v: 2048850000
        },
        {
          t: 80100,
          o: 107.418,
          h: 107.432,
          l: 107.413,
          c: 107.428,
          v: 1767190000
        },
        {
          t: 81000,
          o: 107.428,
          h: 107.431,
          l: 107.405,
          c: 107.413,
          v: 598820000
        },
        {
          t: 81900,
          o: 107.413,
          h: 107.442,
          l: 107.409,
          c: 107.423,
          v: 264140000
        },
        {
          t: 82800,
          o: 107.422,
          h: 107.423,
          l: 107.388,
          c: 107.406,
          v: 291980000
        },
        {
          t: 83700,
          o: 107.405,
          h: 107.407,
          l: 107.377,
          c: 107.396,
          v: 419420000
        },
        {
          t: 84600,
          o: 107.397,
          h: 107.451,
          l: 107.385,
          c: 107.45,
          v: 474250000
        },
        {
          t: 85500,
          o: 107.45,
          h: 107.46,
          l: 107.426,
          c: 107.444,
          v: 469700000
        }
      ]
    },
    {
      d: 1524182400,
      b: [
        {
          t: 0,
          o: 107.443,
          h: 107.464,
          l: 107.394,
          c: 107.426,
          v: 1329270000
        },
        {
          t: 900,
          o: 107.427,
          h: 107.491,
          l: 107.427,
          c: 107.48,
          v: 1648680003
        },
        {
          t: 1800,
          o: 107.479,
          h: 107.524,
          l: 107.469,
          c: 107.486,
          v: 4503730012
        },
        {
          t: 2700,
          o: 107.485,
          h: 107.561,
          l: 107.448,
          c: 107.463,
          v: 1955250000
        },
        {
          t: 3600,
          o: 107.462,
          h: 107.462,
          l: 107.413,
          c: 107.452,
          v: 1025780000
        },
        {
          t: 4500,
          o: 107.453,
          h: 107.672,
          l: 107.453,
          c: 107.66,
          v: 1952870000
        },
        {
          t: 5400,
          o: 107.659,
          h: 107.699,
          l: 107.637,
          c: 107.684,
          v: 2026520000
        },
        {
          t: 6300,
          o: 107.686,
          h: 107.732,
          l: 107.665,
          c: 107.718,
          v: 2216900000
        },
        {
          t: 7200,
          o: 107.721,
          h: 107.728,
          l: 107.675,
          c: 107.675,
          v: 1740050000
        },
        {
          t: 8100,
          o: 107.676,
          h: 107.722,
          l: 107.639,
          c: 107.646,
          v: 1314610000
        },
        {
          t: 9000,
          o: 107.646,
          h: 107.665,
          l: 107.623,
          c: 107.653,
          v: 919000000
        },
        {
          t: 9900,
          o: 107.654,
          h: 107.659,
          l: 107.615,
          c: 107.656,
          v: 846570000
        },
        {
          t: 10800,
          o: 107.656,
          h: 107.672,
          l: 107.622,
          c: 107.649,
          v: 1343030000
        },
        {
          t: 11700,
          o: 107.65,
          h: 107.654,
          l: 107.625,
          c: 107.644,
          v: 723850000
        },
        {
          t: 12600,
          o: 107.645,
          h: 107.654,
          l: 107.617,
          c: 107.624,
          v: 882950002
        },
        {
          t: 13500,
          o: 107.625,
          h: 107.626,
          l: 107.58,
          c: 107.615,
          v: 715370000
        },
        {
          t: 14400,
          o: 107.616,
          h: 107.655,
          l: 107.616,
          c: 107.646,
          v: 676149999
        },
        {
          t: 15300,
          o: 107.646,
          h: 107.67,
          l: 107.64,
          c: 107.649,
          v: 882500000
        },
        {
          t: 16200,
          o: 107.65,
          h: 107.685,
          l: 107.647,
          c: 107.663,
          v: 769450000
        },
        {
          t: 17100,
          o: 107.662,
          h: 107.662,
          l: 107.635,
          c: 107.647,
          v: 593490000
        },
        {
          t: 18000,
          o: 107.647,
          h: 107.655,
          l: 107.605,
          c: 107.625,
          v: 1582100001
        },
        {
          t: 18900,
          o: 107.625,
          h: 107.648,
          l: 107.61,
          c: 107.638,
          v: 945270000
        },
        {
          t: 19800,
          o: 107.638,
          h: 107.654,
          l: 107.634,
          c: 107.649,
          v: 891880000
        },
        {
          t: 20700,
          o: 107.652,
          h: 107.672,
          l: 107.619,
          c: 107.634,
          v: 1298620000
        },
        {
          t: 21600,
          o: 107.63,
          h: 107.632,
          l: 107.59,
          c: 107.623,
          v: 1506990000
        },
        {
          t: 22500,
          o: 107.623,
          h: 107.628,
          l: 107.519,
          c: 107.555,
          v: 1781340000
        },
        {
          t: 23400,
          o: 107.554,
          h: 107.568,
          l: 107.486,
          c: 107.499,
          v: 1967760000
        },
        {
          t: 24300,
          o: 107.5,
          h: 107.522,
          l: 107.487,
          c: 107.506,
          v: 1406820000
        },
        {
          t: 25200,
          o: 107.507,
          h: 107.543,
          l: 107.479,
          c: 107.514,
          v: 1942310000
        },
        {
          t: 26100,
          o: 107.514,
          h: 107.571,
          l: 107.513,
          c: 107.548,
          v: 1391930000
        },
        {
          t: 27000,
          o: 107.548,
          h: 107.578,
          l: 107.528,
          c: 107.532,
          v: 1739590001
        },
        {
          t: 27900,
          o: 107.533,
          h: 107.562,
          l: 107.524,
          c: 107.555,
          v: 1073880000
        },
        {
          t: 28800,
          o: 107.554,
          h: 107.602,
          l: 107.554,
          c: 107.569,
          v: 1257370000
        },
        {
          t: 29700,
          o: 107.568,
          h: 107.578,
          l: 107.535,
          c: 107.551,
          v: 876520000
        },
        {
          t: 30600,
          o: 107.552,
          h: 107.575,
          l: 107.549,
          c: 107.569,
          v: 810210000
        },
        {
          t: 31500,
          o: 107.569,
          h: 107.616,
          l: 107.569,
          c: 107.616,
          v: 979270000
        },
        {
          t: 32400,
          o: 107.616,
          h: 107.631,
          l: 107.601,
          c: 107.627,
          v: 1251570000
        },
        {
          t: 33300,
          o: 107.626,
          h: 107.646,
          l: 107.603,
          c: 107.646,
          v: 1177010000
        },
        {
          t: 34200,
          o: 107.646,
          h: 107.656,
          l: 107.621,
          c: 107.655,
          v: 1189900000
        },
        {
          t: 35100,
          o: 107.656,
          h: 107.686,
          l: 107.642,
          c: 107.66,
          v: 1032760001
        },
        {
          t: 36000,
          o: 107.661,
          h: 107.677,
          l: 107.644,
          c: 107.663,
          v: 1037310000
        },
        {
          t: 36900,
          o: 107.663,
          h: 107.711,
          l: 107.654,
          c: 107.699,
          v: 997810000
        },
        {
          t: 37800,
          o: 107.697,
          h: 107.697,
          l: 107.66,
          c: 107.668,
          v: 1631850000
        },
        {
          t: 38700,
          o: 107.668,
          h: 107.671,
          l: 107.634,
          c: 107.636,
          v: 827940000
        },
        {
          t: 39600,
          o: 107.636,
          h: 107.655,
          l: 107.577,
          c: 107.577,
          v: 1248300000
        },
        {
          t: 40500,
          o: 107.578,
          h: 107.617,
          l: 107.574,
          c: 107.602,
          v: 955900000
        },
        {
          t: 41400,
          o: 107.602,
          h: 107.627,
          l: 107.585,
          c: 107.624,
          v: 849960000
        },
        {
          t: 42300,
          o: 107.624,
          h: 107.632,
          l: 107.591,
          c: 107.62,
          v: 1074760000
        },
        {
          t: 43200,
          o: 107.62,
          h: 107.63,
          l: 107.557,
          c: 107.564,
          v: 1086260000
        },
        {
          t: 44100,
          o: 107.563,
          h: 107.605,
          l: 107.551,
          c: 107.591,
          v: 950980000
        },
        {
          t: 45000,
          o: 107.59,
          h: 107.602,
          l: 107.569,
          c: 107.592,
          v: 1698090000
        },
        {
          t: 45900,
          o: 107.594,
          h: 107.702,
          l: 107.594,
          c: 107.693,
          v: 2008920000
        },
        {
          t: 46800,
          o: 107.695,
          h: 107.716,
          l: 107.663,
          c: 107.711,
          v: 2119790000
        },
        {
          t: 47700,
          o: 107.712,
          h: 107.759,
          l: 107.683,
          c: 107.735,
          v: 2381050001
        },
        {
          t: 48600,
          o: 107.734,
          h: 107.767,
          l: 107.706,
          c: 107.753,
          v: 2551299999
        },
        {
          t: 49500,
          o: 107.754,
          h: 107.798,
          l: 107.728,
          c: 107.791,
          v: 1922880000
        },
        {
          t: 50400,
          o: 107.79,
          h: 107.799,
          l: 107.691,
          c: 107.712,
          v: 2004760000
        },
        {
          t: 51300,
          o: 107.712,
          h: 107.811,
          l: 107.696,
          c: 107.793,
          v: 1800600000
        },
        {
          t: 52200,
          o: 107.794,
          h: 107.851,
          l: 107.774,
          c: 107.851,
          v: 2039670000
        },
        {
          t: 53100,
          o: 107.853,
          h: 107.855,
          l: 107.697,
          c: 107.698,
          v: 2881370000
        },
        {
          t: 54000,
          o: 107.698,
          h: 107.711,
          l: 107.6,
          c: 107.617,
          v: 2793020000
        },
        {
          t: 54900,
          o: 107.617,
          h: 107.642,
          l: 107.569,
          c: 107.579,
          v: 2442220000
        },
        {
          t: 55800,
          o: 107.58,
          h: 107.648,
          l: 107.569,
          c: 107.603,
          v: 2028470000
        },
        {
          t: 56700,
          o: 107.603,
          h: 107.614,
          l: 107.565,
          c: 107.614,
          v: 2109920000
        },
        {
          t: 57600,
          o: 107.615,
          h: 107.615,
          l: 107.555,
          c: 107.574,
          v: 1563460000
        },
        {
          t: 58500,
          o: 107.575,
          h: 107.642,
          l: 107.57,
          c: 107.634,
          v: 1730940000
        },
        {
          t: 59400,
          o: 107.633,
          h: 107.646,
          l: 107.589,
          c: 107.609,
          v: 1902510000
        },
        {
          t: 60300,
          o: 107.606,
          h: 107.633,
          l: 107.51,
          c: 107.511,
          v: 2019679999
        },
        {
          t: 61200,
          o: 107.512,
          h: 107.57,
          l: 107.506,
          c: 107.57,
          v: 1595629998
        },
        {
          t: 62100,
          o: 107.57,
          h: 107.577,
          l: 107.54,
          c: 107.573,
          v: 1414630001
        },
        {
          t: 63000,
          o: 107.573,
          h: 107.598,
          l: 107.556,
          c: 107.584,
          v: 854690000
        },
        {
          t: 63900,
          o: 107.585,
          h: 107.604,
          l: 107.569,
          c: 107.578,
          v: 1059850000
        },
        {
          t: 64800,
          o: 107.578,
          h: 107.604,
          l: 107.576,
          c: 107.582,
          v: 1107350000
        },
        {
          t: 65700,
          o: 107.582,
          h: 107.584,
          l: 107.56,
          c: 107.579,
          v: 1017120000
        },
        {
          t: 66600,
          o: 107.58,
          h: 107.58,
          l: 107.561,
          c: 107.569,
          v: 1055120000
        },
        {
          t: 67500,
          o: 107.568,
          h: 107.599,
          l: 107.556,
          c: 107.59,
          v: 957430000
        },
        {
          t: 68400,
          o: 107.588,
          h: 107.594,
          l: 107.559,
          c: 107.573,
          v: 1244200000
        },
        {
          t: 69300,
          o: 107.573,
          h: 107.587,
          l: 107.555,
          c: 107.584,
          v: 969060000
        },
        {
          t: 70200,
          o: 107.584,
          h: 107.59,
          l: 107.559,
          c: 107.589,
          v: 686560001
        },
        {
          t: 71100,
          o: 107.588,
          h: 107.645,
          l: 107.582,
          c: 107.645,
          v: 636610000
        },
        {
          t: 72000,
          o: 107.645,
          h: 107.666,
          l: 107.638,
          c: 107.645,
          v: 469240000
        },
        {
          t: 72900,
          o: 107.645,
          h: 107.645,
          l: 107.586,
          c: 107.599,
          v: 705400002
        },
        {
          t: 73800,
          o: 107.599,
          h: 107.618,
          l: 107.582,
          c: 107.59,
          v: 703739998
        },
        {
          t: 74700,
          o: 107.589,
          h: 107.643,
          l: 107.589,
          c: 107.632,
          v: 606190000
        }
      ]
    },
    {
      d: 1524355200,
      b: [
        {
          t: 75600,
          o: 107.765,
          h: 107.783,
          l: 107.745,
          c: 107.761,
          v: 114150000
        },
        {
          t: 76500,
          o: 107.764,
          h: 107.818,
          l: 107.736,
          c: 107.8,
          v: 247890000
        },
        {
          t: 77400,
          o: 107.794,
          h: 107.811,
          l: 107.752,
          c: 107.765,
          v: 542560000
        },
        {
          t: 78300,
          o: 107.766,
          h: 107.795,
          l: 107.741,
          c: 107.764,
          v: 980920000
        },
        {
          t: 79200,
          o: 107.764,
          h: 107.796,
          l: 107.659,
          c: 107.712,
          v: 3828090000
        },
        {
          t: 80100,
          o: 107.709,
          h: 107.757,
          l: 107.695,
          c: 107.739,
          v: 912550000
        },
        {
          t: 81000,
          o: 107.741,
          h: 107.782,
          l: 107.736,
          c: 107.752,
          v: 302890000
        },
        {
          t: 81900,
          o: 107.752,
          h: 107.804,
          l: 107.752,
          c: 107.78,
          v: 419360000
        },
        {
          t: 82800,
          o: 107.779,
          h: 107.808,
          l: 107.764,
          c: 107.764,
          v: 537630000
        },
        {
          t: 83700,
          o: 107.764,
          h: 107.798,
          l: 107.75,
          c: 107.773,
          v: 628330000
        },
        {
          t: 84600,
          o: 107.774,
          h: 107.852,
          l: 107.774,
          c: 107.842,
          v: 1064489999
        },
        {
          t: 85500,
          o: 107.842,
          h: 107.887,
          l: 107.839,
          c: 107.88,
          v: 1179180000
        }
      ]
    },
    {
      d: 1524441600,
      b: [
        {
          t: 0,
          o: 107.877,
          h: 107.878,
          l: 107.814,
          c: 107.82,
          v: 1430830000
        },
        {
          t: 900,
          o: 107.821,
          h: 107.834,
          l: 107.744,
          c: 107.766,
          v: 1878460000
        },
        {
          t: 1800,
          o: 107.766,
          h: 107.788,
          l: 107.735,
          c: 107.78,
          v: 1170670000
        },
        {
          t: 2700,
          o: 107.782,
          h: 107.854,
          l: 107.731,
          c: 107.818,
          v: 1861920000
        },
        {
          t: 3600,
          o: 107.818,
          h: 107.847,
          l: 107.793,
          c: 107.839,
          v: 1472780000
        },
        {
          t: 4500,
          o: 107.841,
          h: 107.883,
          l: 107.833,
          c: 107.871,
          v: 977410000
        },
        {
          t: 5400,
          o: 107.87,
          h: 107.879,
          l: 107.807,
          c: 107.818,
          v: 1546980000
        },
        {
          t: 6300,
          o: 107.821,
          h: 107.843,
          l: 107.777,
          c: 107.791,
          v: 1074850000
        },
        {
          t: 7200,
          o: 107.79,
          h: 107.804,
          l: 107.738,
          c: 107.765,
          v: 1192940000
        },
        {
          t: 8100,
          o: 107.766,
          h: 107.791,
          l: 107.751,
          c: 107.791,
          v: 1264330000
        },
        {
          t: 9000,
          o: 107.791,
          h: 107.805,
          l: 107.776,
          c: 107.779,
          v: 810200000
        },
        {
          t: 9900,
          o: 107.78,
          h: 107.815,
          l: 107.779,
          c: 107.813,
          v: 615100000
        },
        {
          t: 10800,
          o: 107.813,
          h: 107.838,
          l: 107.807,
          c: 107.831,
          v: 1097070000
        },
        {
          t: 11700,
          o: 107.83,
          h: 107.842,
          l: 107.818,
          c: 107.841,
          v: 692210000
        },
        {
          t: 12600,
          o: 107.842,
          h: 107.844,
          l: 107.807,
          c: 107.808,
          v: 580220000
        },
        {
          t: 13500,
          o: 107.807,
          h: 107.83,
          l: 107.803,
          c: 107.807,
          v: 682180000
        },
        {
          t: 14400,
          o: 107.808,
          h: 107.835,
          l: 107.784,
          c: 107.802,
          v: 816200000
        },
        {
          t: 15300,
          o: 107.802,
          h: 107.812,
          l: 107.795,
          c: 107.8,
          v: 503450000
        },
        {
          t: 16200,
          o: 107.8,
          h: 107.826,
          l: 107.792,
          c: 107.805,
          v: 704980000
        },
        {
          t: 17100,
          o: 107.808,
          h: 107.823,
          l: 107.79,
          c: 107.806,
          v: 880020000
        },
        {
          t: 18000,
          o: 107.805,
          h: 107.814,
          l: 107.788,
          c: 107.788,
          v: 860910000
        },
        {
          t: 18900,
          o: 107.788,
          h: 107.812,
          l: 107.78,
          c: 107.789,
          v: 917430000
        },
        {
          t: 19800,
          o: 107.789,
          h: 107.808,
          l: 107.766,
          c: 107.807,
          v: 702160000
        },
        {
          t: 20700,
          o: 107.807,
          h: 107.832,
          l: 107.8,
          c: 107.801,
          v: 957080000
        },
        {
          t: 21600,
          o: 107.803,
          h: 107.847,
          l: 107.788,
          c: 107.817,
          v: 1058830000
        },
        {
          t: 22500,
          o: 107.819,
          h: 107.876,
          l: 107.81,
          c: 107.852,
          v: 1700500000
        },
        {
          t: 23400,
          o: 107.852,
          h: 107.854,
          l: 107.824,
          c: 107.839,
          v: 1328520000
        },
        {
          t: 24300,
          o: 107.837,
          h: 107.851,
          l: 107.8,
          c: 107.842,
          v: 1148580000
        },
        {
          t: 25200,
          o: 107.841,
          h: 107.89,
          l: 107.82,
          c: 107.862,
          v: 2574430000
        },
        {
          t: 26100,
          o: 107.862,
          h: 107.939,
          l: 107.833,
          c: 107.89,
          v: 1846310000
        },
        {
          t: 27000,
          o: 107.891,
          h: 107.933,
          l: 107.877,
          c: 107.902,
          v: 1768930000
        },
        {
          t: 27900,
          o: 107.902,
          h: 107.936,
          l: 107.843,
          c: 107.913,
          v: 1655899999
        },
        {
          t: 28800,
          o: 107.912,
          h: 107.949,
          l: 107.879,
          c: 107.9,
          v: 2210120001
        },
        {
          t: 29700,
          o: 107.899,
          h: 107.918,
          l: 107.859,
          c: 107.867,
          v: 1963790000
        },
        {
          t: 30600,
          o: 107.869,
          h: 107.956,
          l: 107.86,
          c: 107.95,
          v: 1475790000
        },
        {
          t: 31500,
          o: 107.95,
          h: 108.01,
          l: 107.927,
          c: 107.999,
          v: 1228150000
        },
        {
          t: 32400,
          o: 108,
          h: 108.042,
          l: 107.997,
          c: 108.025,
          v: 1576630000
        },
        {
          t: 33300,
          o: 108.025,
          h: 108.106,
          l: 108.02,
          c: 108.103,
          v: 1119730000
        },
        {
          t: 34200,
          o: 108.103,
          h: 108.197,
          l: 108.079,
          c: 108.197,
          v: 1277570000
        },
        {
          t: 35100,
          o: 108.196,
          h: 108.255,
          l: 108.181,
          c: 108.199,
          v: 1341750001
        },
        {
          t: 36000,
          o: 108.199,
          h: 108.279,
          l: 108.197,
          c: 108.234,
          v: 1255700000
        },
        {
          t: 36900,
          o: 108.234,
          h: 108.252,
          l: 108.175,
          c: 108.192,
          v: 1171520000
        },
        {
          t: 37800,
          o: 108.193,
          h: 108.205,
          l: 108.169,
          c: 108.2,
          v: 887220000
        },
        {
          t: 38700,
          o: 108.2,
          h: 108.231,
          l: 108.194,
          c: 108.219,
          v: 972990000
        },
        {
          t: 39600,
          o: 108.22,
          h: 108.279,
          l: 108.22,
          c: 108.246,
          v: 981100000
        },
        {
          t: 40500,
          o: 108.245,
          h: 108.249,
          l: 108.224,
          c: 108.226,
          v: 864430000
        },
        {
          t: 41400,
          o: 108.227,
          h: 108.239,
          l: 108.209,
          c: 108.21,
          v: 818250000
        },
        {
          t: 42300,
          o: 108.208,
          h: 108.208,
          l: 108.169,
          c: 108.17,
          v: 663580000
        },
        {
          t: 43200,
          o: 108.171,
          h: 108.19,
          l: 108.138,
          c: 108.171,
          v: 1229700001
        },
        {
          t: 44100,
          o: 108.171,
          h: 108.183,
          l: 108.125,
          c: 108.172,
          v: 1904800000
        },
        {
          t: 45000,
          o: 108.174,
          h: 108.254,
          l: 108.165,
          c: 108.252,
          v: 1565000000
        },
        {
          t: 45900,
          o: 108.253,
          h: 108.258,
          l: 108.205,
          c: 108.212,
          v: 1274000000
        },
        {
          t: 46800,
          o: 108.212,
          h: 108.241,
          l: 108.176,
          c: 108.23,
          v: 1406570000
        },
        {
          t: 47700,
          o: 108.229,
          h: 108.258,
          l: 108.21,
          c: 108.258,
          v: 1683999997
        },
        {
          t: 48600,
          o: 108.257,
          h: 108.269,
          l: 108.224,
          c: 108.239,
          v: 2292750000
        },
        {
          t: 49500,
          o: 108.238,
          h: 108.383,
          l: 108.238,
          c: 108.375,
          v: 2499060001
        },
        {
          t: 50400,
          o: 108.378,
          h: 108.451,
          l: 108.371,
          c: 108.432,
          v: 2402390000
        },
        {
          t: 51300,
          o: 108.433,
          h: 108.458,
          l: 108.407,
          c: 108.419,
          v: 2071940000
        },
        {
          t: 52200,
          o: 108.42,
          h: 108.452,
          l: 108.366,
          c: 108.414,
          v: 2057940000
        },
        {
          t: 53100,
          o: 108.414,
          h: 108.489,
          l: 108.39,
          c: 108.444,
          v: 2127460001
        },
        {
          t: 54000,
          o: 108.443,
          h: 108.495,
          l: 108.427,
          c: 108.483,
          v: 2389570000
        },
        {
          t: 54900,
          o: 108.484,
          h: 108.571,
          l: 108.468,
          c: 108.566,
          v: 2014120002
        },
        {
          t: 55800,
          o: 108.566,
          h: 108.586,
          l: 108.544,
          c: 108.57,
          v: 1900520000
        },
        {
          t: 56700,
          o: 108.57,
          h: 108.634,
          l: 108.555,
          c: 108.627,
          v: 1798380000
        },
        {
          t: 57600,
          o: 108.626,
          h: 108.629,
          l: 108.566,
          c: 108.589,
          v: 1455920000
        },
        {
          t: 58500,
          o: 108.591,
          h: 108.656,
          l: 108.582,
          c: 108.65,
          v: 1421309999
        },
        {
          t: 59400,
          o: 108.649,
          h: 108.672,
          l: 108.632,
          c: 108.661,
          v: 1133000000
        },
        {
          t: 60300,
          o: 108.661,
          h: 108.713,
          l: 108.645,
          c: 108.648,
          v: 1745720000
        },
        {
          t: 61200,
          o: 108.648,
          h: 108.697,
          l: 108.63,
          c: 108.688,
          v: 1122730000
        },
        {
          t: 62100,
          o: 108.688,
          h: 108.689,
          l: 108.648,
          c: 108.671,
          v: 994440000
        },
        {
          t: 63000,
          o: 108.671,
          h: 108.691,
          l: 108.665,
          c: 108.684,
          v: 810410000
        },
        {
          t: 63900,
          o: 108.685,
          h: 108.734,
          l: 108.681,
          c: 108.716,
          v: 894260000
        },
        {
          t: 64800,
          o: 108.715,
          h: 108.718,
          l: 108.595,
          c: 108.595,
          v: 1623289998
        },
        {
          t: 65700,
          o: 108.596,
          h: 108.605,
          l: 108.565,
          c: 108.576,
          v: 1549060000
        },
        {
          t: 66600,
          o: 108.575,
          h: 108.621,
          l: 108.561,
          c: 108.62,
          v: 1363040001
        },
        {
          t: 67500,
          o: 108.619,
          h: 108.647,
          l: 108.552,
          c: 108.647,
          v: 1203650000
        },
        {
          t: 68400,
          o: 108.645,
          h: 108.701,
          l: 108.632,
          c: 108.701,
          v: 1161570000
        },
        {
          t: 69300,
          o: 108.702,
          h: 108.753,
          l: 108.692,
          c: 108.748,
          v: 1254090000
        },
        {
          t: 70200,
          o: 108.747,
          h: 108.748,
          l: 108.699,
          c: 108.722,
          v: 867240000
        },
        {
          t: 71100,
          o: 108.722,
          h: 108.738,
          l: 108.705,
          c: 108.726,
          v: 914260000
        },
        {
          t: 72000,
          o: 108.729,
          h: 108.729,
          l: 108.676,
          c: 108.722,
          v: 550580000
        },
        {
          t: 72900,
          o: 108.722,
          h: 108.748,
          l: 108.72,
          c: 108.726,
          v: 427800000
        },
        {
          t: 73800,
          o: 108.725,
          h: 108.727,
          l: 108.69,
          c: 108.707,
          v: 424420000
        },
        {
          t: 74700,
          o: 108.706,
          h: 108.71,
          l: 108.689,
          c: 108.701,
          v: 449220000
        },
        {
          t: 75600,
          o: 108.699,
          h: 108.715,
          l: 108.693,
          c: 108.703,
          v: 720170000
        },
        {
          t: 76500,
          o: 108.703,
          h: 108.711,
          l: 108.693,
          c: 108.704,
          v: 1316020000
        },
        {
          t: 77400,
          o: 108.705,
          h: 108.71,
          l: 108.682,
          c: 108.691,
          v: 1290710000
        },
        {
          t: 78300,
          o: 108.69,
          h: 108.712,
          l: 108.689,
          c: 108.71,
          v: 362720000
        },
        {
          t: 79200,
          o: 108.71,
          h: 108.735,
          l: 108.709,
          c: 108.727,
          v: 254110000
        },
        {
          t: 80100,
          o: 108.726,
          h: 108.727,
          l: 108.718,
          c: 108.721,
          v: 94450000
        },
        {
          t: 81000,
          o: 108.72,
          h: 108.72,
          l: 108.691,
          c: 108.705,
          v: 405860000
        },
        {
          t: 81900,
          o: 108.705,
          h: 108.711,
          l: 108.67,
          c: 108.672,
          v: 671730000
        },
        {
          t: 82800,
          o: 108.672,
          h: 108.703,
          l: 108.672,
          c: 108.683,
          v: 270460000
        },
        {
          t: 83700,
          o: 108.684,
          h: 108.732,
          l: 108.683,
          c: 108.728,
          v: 272250000
        },
        {
          t: 84600,
          o: 108.728,
          h: 108.782,
          l: 108.725,
          c: 108.77,
          v: 963830000
        },
        {
          t: 85500,
          o: 108.769,
          h: 108.789,
          l: 108.761,
          c: 108.772,
          v: 956990000
        }
      ]
    },
    {
      d: 1524528000,
      b: [
        {
          t: 0,
          o: 108.772,
          h: 108.804,
          l: 108.748,
          c: 108.763,
          v: 1854010000
        },
        {
          t: 900,
          o: 108.765,
          h: 108.8,
          l: 108.758,
          c: 108.766,
          v: 1501500000
        },
        {
          t: 1800,
          o: 108.768,
          h: 108.773,
          l: 108.743,
          c: 108.757,
          v: 1422730005
        },
        {
          t: 2700,
          o: 108.757,
          h: 108.783,
          l: 108.734,
          c: 108.75,
          v: 1774870000
        },
        {
          t: 3600,
          o: 108.753,
          h: 108.85,
          l: 108.706,
          c: 108.838,
          v: 2326450000
        },
        {
          t: 4500,
          o: 108.837,
          h: 108.869,
          l: 108.83,
          c: 108.847,
          v: 1680230000
        },
        {
          t: 5400,
          o: 108.848,
          h: 108.859,
          l: 108.788,
          c: 108.798,
          v: 1588420000
        },
        {
          t: 6300,
          o: 108.797,
          h: 108.843,
          l: 108.784,
          c: 108.811,
          v: 1683900001
        },
        {
          t: 7200,
          o: 108.811,
          h: 108.832,
          l: 108.753,
          c: 108.753,
          v: 1896000000
        },
        {
          t: 8100,
          o: 108.753,
          h: 108.771,
          l: 108.737,
          c: 108.754,
          v: 1350690000
        },
        {
          t: 9000,
          o: 108.754,
          h: 108.754,
          l: 108.693,
          c: 108.734,
          v: 1128520000
        },
        {
          t: 9900,
          o: 108.735,
          h: 108.776,
          l: 108.735,
          c: 108.751,
          v: 947980000
        },
        {
          t: 10800,
          o: 108.751,
          h: 108.775,
          l: 108.742,
          c: 108.756,
          v: 906889999
        },
        {
          t: 11700,
          o: 108.756,
          h: 108.83,
          l: 108.756,
          c: 108.811,
          v: 1099160000
        },
        {
          t: 12600,
          o: 108.811,
          h: 108.831,
          l: 108.781,
          c: 108.782,
          v: 1096840000
        },
        {
          t: 13500,
          o: 108.782,
          h: 108.782,
          l: 108.753,
          c: 108.769,
          v: 688950000
        },
        {
          t: 14400,
          o: 108.769,
          h: 108.813,
          l: 108.769,
          c: 108.794,
          v: 893390000
        },
        {
          t: 15300,
          o: 108.795,
          h: 108.802,
          l: 108.773,
          c: 108.796,
          v: 543130000
        },
        {
          t: 16200,
          o: 108.797,
          h: 108.805,
          l: 108.77,
          c: 108.804,
          v: 762740000
        },
        {
          t: 17100,
          o: 108.804,
          h: 108.832,
          l: 108.787,
          c: 108.812,
          v: 770410000
        },
        {
          t: 18000,
          o: 108.811,
          h: 108.811,
          l: 108.766,
          c: 108.785,
          v: 1004110000
        },
        {
          t: 18900,
          o: 108.785,
          h: 108.794,
          l: 108.758,
          c: 108.76,
          v: 749900000
        },
        {
          t: 19800,
          o: 108.759,
          h: 108.775,
          l: 108.754,
          c: 108.77,
          v: 1006280000
        },
        {
          t: 20700,
          o: 108.77,
          h: 108.8,
          l: 108.767,
          c: 108.796,
          v: 891890000
        },
        {
          t: 21600,
          o: 108.796,
          h: 108.836,
          l: 108.795,
          c: 108.812,
          v: 1683200000
        },
        {
          t: 22500,
          o: 108.812,
          h: 108.82,
          l: 108.755,
          c: 108.782,
          v: 1648060000
        },
        {
          t: 23400,
          o: 108.782,
          h: 108.828,
          l: 108.754,
          c: 108.813,
          v: 1147649999
        },
        {
          t: 24300,
          o: 108.813,
          h: 108.863,
          l: 108.81,
          c: 108.863,
          v: 1276710000
        },
        {
          t: 25200,
          o: 108.863,
          h: 108.917,
          l: 108.841,
          c: 108.865,
          v: 3244480005
        },
        {
          t: 26100,
          o: 108.866,
          h: 108.885,
          l: 108.836,
          c: 108.855,
          v: 1279330000
        },
        {
          t: 27000,
          o: 108.855,
          h: 108.899,
          l: 108.854,
          c: 108.872,
          v: 1352400000
        },
        {
          t: 27900,
          o: 108.872,
          h: 108.907,
          l: 108.864,
          c: 108.887,
          v: 1183650000
        },
        {
          t: 28800,
          o: 108.886,
          h: 108.9,
          l: 108.819,
          c: 108.845,
          v: 1810250000
        },
        {
          t: 29700,
          o: 108.845,
          h: 108.897,
          l: 108.827,
          c: 108.827,
          v: 1411770000
        },
        {
          t: 30600,
          o: 108.827,
          h: 108.845,
          l: 108.81,
          c: 108.834,
          v: 1238650000
        },
        {
          t: 31500,
          o: 108.834,
          h: 108.878,
          l: 108.82,
          c: 108.82,
          v: 1347350000
        },
        {
          t: 32400,
          o: 108.82,
          h: 108.862,
          l: 108.814,
          c: 108.839,
          v: 1122580000
        },
        {
          t: 33300,
          o: 108.839,
          h: 108.86,
          l: 108.813,
          c: 108.818,
          v: 1329210000
        },
        {
          t: 34200,
          o: 108.817,
          h: 108.844,
          l: 108.809,
          c: 108.82,
          v: 994160000
        },
        {
          t: 35100,
          o: 108.82,
          h: 108.839,
          l: 108.805,
          c: 108.832,
          v: 1217290000
        },
        {
          t: 36000,
          o: 108.831,
          h: 108.832,
          l: 108.774,
          c: 108.786,
          v: 1462680000
        },
        {
          t: 36900,
          o: 108.786,
          h: 108.849,
          l: 108.777,
          c: 108.845,
          v: 1421860000
        },
        {
          t: 37800,
          o: 108.845,
          h: 108.853,
          l: 108.816,
          c: 108.844,
          v: 755510000
        },
        {
          t: 38700,
          o: 108.843,
          h: 108.857,
          l: 108.824,
          c: 108.848,
          v: 1018340000
        },
        {
          t: 39600,
          o: 108.849,
          h: 108.869,
          l: 108.803,
          c: 108.809,
          v: 1329840000
        },
        {
          t: 40500,
          o: 108.808,
          h: 108.825,
          l: 108.798,
          c: 108.802,
          v: 1116030000
        },
        {
          t: 41400,
          o: 108.803,
          h: 108.831,
          l: 108.803,
          c: 108.826,
          v: 895039998
        },
        {
          t: 42300,
          o: 108.826,
          h: 108.829,
          l: 108.793,
          c: 108.797,
          v: 1249470000
        },
        {
          t: 43200,
          o: 108.795,
          h: 108.84,
          l: 108.792,
          c: 108.823,
          v: 1477820000
        },
        {
          t: 44100,
          o: 108.825,
          h: 108.868,
          l: 108.822,
          c: 108.855,
          v: 1097630000
        },
        {
          t: 45000,
          o: 108.855,
          h: 108.98,
          l: 108.852,
          c: 108.98,
          v: 1846869999
        },
        {
          t: 45900,
          o: 108.978,
          h: 109.066,
          l: 108.973,
          c: 108.998,
          v: 2578570000
        },
        {
          t: 46800,
          o: 108.996,
          h: 109.031,
          l: 108.939,
          c: 108.953,
          v: 2577810000
        },
        {
          t: 47700,
          o: 108.953,
          h: 109.129,
          l: 108.948,
          c: 109.065,
          v: 3208340000
        },
        {
          t: 48600,
          o: 109.066,
          h: 109.124,
          l: 109.038,
          c: 109.11,
          v: 3063960000
        },
        {
          t: 49500,
          o: 109.111,
          h: 109.157,
          l: 109.067,
          c: 109.083,
          v: 2737420000
        },
        {
          t: 50400,
          o: 109.082,
          h: 109.167,
          l: 109.078,
          c: 109.109,
          v: 2976840000
        },
        {
          t: 51300,
          o: 109.109,
          h: 109.124,
          l: 109.025,
          c: 109.041,
          v: 2949660000
        },
        {
          t: 52200,
          o: 109.041,
          h: 109.142,
          l: 109.038,
          c: 109.118,
          v: 2442790000
        },
        {
          t: 53100,
          o: 109.119,
          h: 109.178,
          l: 109.089,
          c: 109.171,
          v: 1988640000
        },
        {
          t: 54000,
          o: 109.172,
          h: 109.199,
          l: 109.132,
          c: 109.14,
          v: 2550340000
        },
        {
          t: 54900,
          o: 109.14,
          h: 109.184,
          l: 109.111,
          c: 109.135,
          v: 2189190000
        },
        {
          t: 55800,
          o: 109.133,
          h: 109.144,
          l: 109.09,
          c: 109.124,
          v: 1660280000
        },
        {
          t: 56700,
          o: 109.124,
          h: 109.137,
          l: 109.032,
          c: 109.032,
          v: 2165590000
        },
        {
          t: 57600,
          o: 109.032,
          h: 109.071,
          l: 108.975,
          c: 108.976,
          v: 2280670000
        },
        {
          t: 58500,
          o: 108.976,
          h: 108.978,
          l: 108.824,
          c: 108.825,
          v: 2737540000
        },
        {
          t: 59400,
          o: 108.835,
          h: 108.857,
          l: 108.801,
          c: 108.838,
          v: 2427540000
        },
        {
          t: 60300,
          o: 108.837,
          h: 108.849,
          l: 108.711,
          c: 108.721,
          v: 2595800000
        },
        {
          t: 61200,
          o: 108.72,
          h: 108.783,
          l: 108.717,
          c: 108.78,
          v: 2245120000
        },
        {
          t: 62100,
          o: 108.781,
          h: 108.789,
          l: 108.719,
          c: 108.726,
          v: 2317060000
        },
        {
          t: 63000,
          o: 108.727,
          h: 108.755,
          l: 108.707,
          c: 108.723,
          v: 1803630000
        },
        {
          t: 63900,
          o: 108.722,
          h: 108.759,
          l: 108.633,
          c: 108.643,
          v: 2115020001
        },
        {
          t: 64800,
          o: 108.644,
          h: 108.668,
          l: 108.542,
          c: 108.609,
          v: 2273900000
        },
        {
          t: 65700,
          o: 108.61,
          h: 108.636,
          l: 108.552,
          c: 108.561,
          v: 2287380001
        },
        {
          t: 66600,
          o: 108.56,
          h: 108.633,
          l: 108.56,
          c: 108.625,
          v: 2498790000
        },
        {
          t: 67500,
          o: 108.624,
          h: 108.678,
          l: 108.623,
          c: 108.676,
          v: 2728460000
        },
        {
          t: 68400,
          o: 108.675,
          h: 108.718,
          l: 108.665,
          c: 108.711,
          v: 2051220000
        },
        {
          t: 69300,
          o: 108.71,
          h: 108.75,
          l: 108.675,
          c: 108.726,
          v: 2205910000
        },
        {
          t: 70200,
          o: 108.725,
          h: 108.725,
          l: 108.655,
          c: 108.668,
          v: 1842580000
        },
        {
          t: 71100,
          o: 108.668,
          h: 108.747,
          l: 108.668,
          c: 108.747,
          v: 1872020000
        },
        {
          t: 72000,
          o: 108.745,
          h: 108.783,
          l: 108.743,
          c: 108.768,
          v: 1083190000
        },
        {
          t: 72900,
          o: 108.768,
          h: 108.793,
          l: 108.768,
          c: 108.788,
          v: 867190000
        },
        {
          t: 73800,
          o: 108.787,
          h: 108.831,
          l: 108.774,
          c: 108.791,
          v: 884260000
        },
        {
          t: 74700,
          o: 108.793,
          h: 108.819,
          l: 108.777,
          c: 108.813,
          v: 800980000
        },
        {
          t: 75600,
          o: 108.808,
          h: 108.827,
          l: 108.8,
          c: 108.82,
          v: 685260000
        },
        {
          t: 76500,
          o: 108.82,
          h: 108.821,
          l: 108.804,
          c: 108.808,
          v: 956460000
        },
        {
          t: 77400,
          o: 108.807,
          h: 108.814,
          l: 108.786,
          c: 108.802,
          v: 1046460000
        },
        {
          t: 78300,
          o: 108.804,
          h: 108.825,
          l: 108.803,
          c: 108.82,
          v: 1362930000
        },
        {
          t: 79200,
          o: 108.82,
          h: 108.853,
          l: 108.816,
          c: 108.85,
          v: 454690000
        },
        {
          t: 80100,
          o: 108.851,
          h: 108.876,
          l: 108.85,
          c: 108.868,
          v: 723370000
        },
        {
          t: 81000,
          o: 108.868,
          h: 108.868,
          l: 108.828,
          c: 108.831,
          v: 282050000
        },
        {
          t: 81900,
          o: 108.832,
          h: 108.859,
          l: 108.832,
          c: 108.856,
          v: 187300000
        },
        {
          t: 82800,
          o: 108.856,
          h: 108.856,
          l: 108.823,
          c: 108.827,
          v: 358940000
        },
        {
          t: 83700,
          o: 108.827,
          h: 108.864,
          l: 108.827,
          c: 108.831,
          v: 545380000
        },
        {
          t: 84600,
          o: 108.83,
          h: 108.844,
          l: 108.81,
          c: 108.826,
          v: 667200000
        },
        {
          t: 85500,
          o: 108.825,
          h: 108.836,
          l: 108.796,
          c: 108.802,
          v: 636400000
        }
      ]
    },
    {
      d: 1524614400,
      b: [
        {
          t: 0,
          o: 108.802,
          h: 108.851,
          l: 108.788,
          c: 108.847,
          v: 1772470000
        },
        {
          t: 900,
          o: 108.846,
          h: 108.88,
          l: 108.816,
          c: 108.88,
          v: 1329980000
        },
        {
          t: 1800,
          o: 108.882,
          h: 108.955,
          l: 108.882,
          c: 108.949,
          v: 1680450000
        },
        {
          t: 2700,
          o: 108.949,
          h: 108.963,
          l: 108.868,
          c: 108.89,
          v: 1899310000
        },
        {
          t: 3600,
          o: 108.89,
          h: 108.948,
          l: 108.871,
          c: 108.93,
          v: 3060079998
        },
        {
          t: 4500,
          o: 108.93,
          h: 109.061,
          l: 108.924,
          c: 109.006,
          v: 2142010000
        },
        {
          t: 5400,
          o: 109.006,
          h: 109.035,
          l: 108.959,
          c: 108.961,
          v: 1742920000
        },
        {
          t: 6300,
          o: 108.96,
          h: 108.964,
          l: 108.906,
          c: 108.919,
          v: 1893310000
        },
        {
          t: 7200,
          o: 108.919,
          h: 108.936,
          l: 108.81,
          c: 108.816,
          v: 2205300000
        },
        {
          t: 8100,
          o: 108.816,
          h: 108.874,
          l: 108.816,
          c: 108.853,
          v: 1571610000
        },
        {
          t: 9000,
          o: 108.853,
          h: 108.908,
          l: 108.849,
          c: 108.907,
          v: 1074980000
        },
        {
          t: 9900,
          o: 108.903,
          h: 108.905,
          l: 108.874,
          c: 108.897,
          v: 1177040000
        },
        {
          t: 10800,
          o: 108.898,
          h: 108.919,
          l: 108.884,
          c: 108.902,
          v: 1102530000
        },
        {
          t: 11700,
          o: 108.902,
          h: 108.932,
          l: 108.892,
          c: 108.932,
          v: 841910000
        },
        {
          t: 12600,
          o: 108.932,
          h: 108.944,
          l: 108.904,
          c: 108.919,
          v: 1045610000
        },
        {
          t: 13500,
          o: 108.919,
          h: 109.018,
          l: 108.904,
          c: 109.016,
          v: 1541100000
        },
        {
          t: 14400,
          o: 109.015,
          h: 109.038,
          l: 109.001,
          c: 109.025,
          v: 1237680000
        },
        {
          t: 15300,
          o: 109.026,
          h: 109.036,
          l: 108.972,
          c: 108.988,
          v: 1058900000
        },
        {
          t: 16200,
          o: 108.988,
          h: 109.044,
          l: 108.987,
          c: 109.018,
          v: 1304600000
        },
        {
          t: 17100,
          o: 109.019,
          h: 109.043,
          l: 109.013,
          c: 109.033,
          v: 682260001
        },
        {
          t: 18000,
          o: 109.033,
          h: 109.076,
          l: 109.022,
          c: 109.055,
          v: 1506650000
        },
        {
          t: 18900,
          o: 109.056,
          h: 109.081,
          l: 109.034,
          c: 109.076,
          v: 3669920006
        },
        {
          t: 19800,
          o: 109.076,
          h: 109.081,
          l: 108.998,
          c: 109.013,
          v: 1599200000
        },
        {
          t: 20700,
          o: 109.012,
          h: 109.064,
          l: 109.012,
          c: 109.063,
          v: 1382340000
        },
        {
          t: 21600,
          o: 109.062,
          h: 109.131,
          l: 109.06,
          c: 109.127,
          v: 2810670000
        },
        {
          t: 22500,
          o: 109.125,
          h: 109.147,
          l: 109.083,
          c: 109.139,
          v: 1854540000
        },
        {
          t: 23400,
          o: 109.14,
          h: 109.146,
          l: 109.098,
          c: 109.113,
          v: 1654720000
        },
        {
          t: 24300,
          o: 109.11,
          h: 109.17,
          l: 109.099,
          c: 109.166,
          v: 1551250000
        },
        {
          t: 25200,
          o: 109.166,
          h: 109.177,
          l: 109.136,
          c: 109.14,
          v: 1960120000
        },
        {
          t: 26100,
          o: 109.141,
          h: 109.175,
          l: 109.137,
          c: 109.174,
          v: 914230000
        },
        {
          t: 27000,
          o: 109.174,
          h: 109.179,
          l: 109.138,
          c: 109.165,
          v: 1388650000
        },
        {
          t: 27900,
          o: 109.165,
          h: 109.266,
          l: 109.137,
          c: 109.25,
          v: 1314530000
        },
        {
          t: 28800,
          o: 109.25,
          h: 109.267,
          l: 109.214,
          c: 109.261,
          v: 2122910000
        },
        {
          t: 29700,
          o: 109.26,
          h: 109.261,
          l: 109.193,
          c: 109.209,
          v: 1717120000
        },
        {
          t: 30600,
          o: 109.209,
          h: 109.227,
          l: 109.117,
          c: 109.118,
          v: 1840659999
        },
        {
          t: 31500,
          o: 109.12,
          h: 109.124,
          l: 109.058,
          c: 109.088,
          v: 2302840000
        },
        {
          t: 32400,
          o: 109.089,
          h: 109.104,
          l: 109.063,
          c: 109.082,
          v: 1865480000
        },
        {
          t: 33300,
          o: 109.082,
          h: 109.095,
          l: 108.981,
          c: 109.021,
          v: 2035230000
        },
        {
          t: 34200,
          o: 109.021,
          h: 109.05,
          l: 109.007,
          c: 109.043,
          v: 1110940000
        },
        {
          t: 35100,
          o: 109.044,
          h: 109.085,
          l: 109.028,
          c: 109.085,
          v: 1370700000
        },
        {
          t: 36000,
          o: 109.085,
          h: 109.101,
          l: 109.047,
          c: 109.096,
          v: 1354610000
        },
        {
          t: 36900,
          o: 109.097,
          h: 109.137,
          l: 109.086,
          c: 109.11,
          v: 1199390000
        },
        {
          t: 37800,
          o: 109.109,
          h: 109.131,
          l: 109.077,
          c: 109.101,
          v: 916100000
        },
        {
          t: 38700,
          o: 109.101,
          h: 109.137,
          l: 109.046,
          c: 109.054,
          v: 1390240000
        },
        {
          t: 39600,
          o: 109.053,
          h: 109.072,
          l: 109.034,
          c: 109.061,
          v: 1737740000
        },
        {
          t: 40500,
          o: 109.062,
          h: 109.111,
          l: 109.061,
          c: 109.085,
          v: 1527560000
        },
        {
          t: 41400,
          o: 109.085,
          h: 109.199,
          l: 109.085,
          c: 109.199,
          v: 1836820000
        },
        {
          t: 42300,
          o: 109.2,
          h: 109.25,
          l: 109.176,
          c: 109.22,
          v: 1655780000
        },
        {
          t: 43200,
          o: 109.219,
          h: 109.237,
          l: 109.166,
          c: 109.179,
          v: 1432030000
        },
        {
          t: 44100,
          o: 109.178,
          h: 109.254,
          l: 109.172,
          c: 109.219,
          v: 1548790000
        },
        {
          t: 45000,
          o: 109.216,
          h: 109.253,
          l: 109.18,
          c: 109.223,
          v: 1726170000
        },
        {
          t: 45900,
          o: 109.223,
          h: 109.229,
          l: 109.175,
          c: 109.199,
          v: 1663640000
        },
        {
          t: 46800,
          o: 109.199,
          h: 109.226,
          l: 109.175,
          c: 109.22,
          v: 1366320000
        },
        {
          t: 47700,
          o: 109.222,
          h: 109.238,
          l: 109.188,
          c: 109.223,
          v: 1391280000
        },
        {
          t: 48600,
          o: 109.222,
          h: 109.288,
          l: 109.071,
          c: 109.088,
          v: 3130049999
        },
        {
          t: 49500,
          o: 109.087,
          h: 109.175,
          l: 109.055,
          c: 109.107,
          v: 3412569999
        },
        {
          t: 50400,
          o: 109.106,
          h: 109.214,
          l: 109.099,
          c: 109.2,
          v: 3215540001
        },
        {
          t: 51300,
          o: 109.201,
          h: 109.271,
          l: 109.178,
          c: 109.258,
          v: 2941820000
        },
        {
          t: 52200,
          o: 109.257,
          h: 109.344,
          l: 109.24,
          c: 109.316,
          v: 3295319999
        },
        {
          t: 53100,
          o: 109.315,
          h: 109.321,
          l: 109.25,
          c: 109.306,
          v: 2434860000
        },
        {
          t: 54000,
          o: 109.306,
          h: 109.329,
          l: 109.271,
          c: 109.301,
          v: 2740979999
        },
        {
          t: 54900,
          o: 109.302,
          h: 109.339,
          l: 109.242,
          c: 109.332,
          v: 2507900001
        },
        {
          t: 55800,
          o: 109.33,
          h: 109.381,
          l: 109.316,
          c: 109.378,
          v: 2484140000
        },
        {
          t: 56700,
          o: 109.376,
          h: 109.382,
          l: 109.329,
          c: 109.336,
          v: 2200409996
        },
        {
          t: 57600,
          o: 109.335,
          h: 109.359,
          l: 109.32,
          c: 109.343,
          v: 2259050001
        },
        {
          t: 58500,
          o: 109.343,
          h: 109.365,
          l: 109.313,
          c: 109.334,
          v: 2042740000
        },
        {
          t: 59400,
          o: 109.336,
          h: 109.337,
          l: 109.278,
          c: 109.297,
          v: 2056070000
        },
        {
          t: 60300,
          o: 109.298,
          h: 109.336,
          l: 109.234,
          c: 109.24,
          v: 1976990000
        },
        {
          t: 61200,
          o: 109.239,
          h: 109.299,
          l: 109.237,
          c: 109.275,
          v: 1617770001
        },
        {
          t: 62100,
          o: 109.275,
          h: 109.312,
          l: 109.258,
          c: 109.287,
          v: 1189370000
        },
        {
          t: 63000,
          o: 109.287,
          h: 109.347,
          l: 109.269,
          c: 109.338,
          v: 1657190000
        },
        {
          t: 63900,
          o: 109.338,
          h: 109.374,
          l: 109.322,
          c: 109.363,
          v: 1462579998
        },
        {
          t: 64800,
          o: 109.364,
          h: 109.373,
          l: 109.352,
          c: 109.364,
          v: 1045180000
        },
        {
          t: 65700,
          o: 109.365,
          h: 109.375,
          l: 109.318,
          c: 109.324,
          v: 1177320000
        },
        {
          t: 66600,
          o: 109.323,
          h: 109.352,
          l: 109.32,
          c: 109.34,
          v: 1409170000
        },
        {
          t: 67500,
          o: 109.34,
          h: 109.348,
          l: 109.299,
          c: 109.348,
          v: 1468970000
        },
        {
          t: 68400,
          o: 109.348,
          h: 109.372,
          l: 109.33,
          c: 109.342,
          v: 1485110000
        },
        {
          t: 69300,
          o: 109.342,
          h: 109.355,
          l: 109.325,
          c: 109.333,
          v: 1529980000
        },
        {
          t: 70200,
          o: 109.335,
          h: 109.338,
          l: 109.311,
          c: 109.318,
          v: 1781320000
        },
        {
          t: 71100,
          o: 109.317,
          h: 109.368,
          l: 109.306,
          c: 109.362,
          v: 1226940000
        },
        {
          t: 72000,
          o: 109.365,
          h: 109.376,
          l: 109.348,
          c: 109.361,
          v: 940270000
        },
        {
          t: 72900,
          o: 109.361,
          h: 109.392,
          l: 109.36,
          c: 109.388,
          v: 595680000
        },
        {
          t: 73800,
          o: 109.387,
          h: 109.454,
          l: 109.387,
          c: 109.425,
          v: 908150000
        },
        {
          t: 74700,
          o: 109.425,
          h: 109.44,
          l: 109.407,
          c: 109.421,
          v: 847370000
        },
        {
          t: 75600,
          o: 109.423,
          h: 109.423,
          l: 109.368,
          c: 109.39,
          v: 627570000
        },
        {
          t: 76500,
          o: 109.39,
          h: 109.417,
          l: 109.385,
          c: 109.393,
          v: 592780000
        },
        {
          t: 77400,
          o: 109.396,
          h: 109.399,
          l: 109.376,
          c: 109.381,
          v: 850790000
        },
        {
          t: 78300,
          o: 109.382,
          h: 109.395,
          l: 109.376,
          c: 109.393,
          v: 685620000
        },
        {
          t: 79200,
          o: 109.392,
          h: 109.469,
          l: 109.392,
          c: 109.449,
          v: 835870000
        },
        {
          t: 80100,
          o: 109.448,
          h: 109.448,
          l: 109.404,
          c: 109.417,
          v: 461590000
        },
        {
          t: 81000,
          o: 109.417,
          h: 109.45,
          l: 109.4,
          c: 109.449,
          v: 574070000
        },
        {
          t: 81900,
          o: 109.453,
          h: 109.456,
          l: 109.407,
          c: 109.408,
          v: 436560000
        },
        {
          t: 82800,
          o: 109.409,
          h: 109.436,
          l: 109.396,
          c: 109.397,
          v: 440410000
        },
        {
          t: 83700,
          o: 109.397,
          h: 109.431,
          l: 109.397,
          c: 109.421,
          v: 509140000
        },
        {
          t: 84600,
          o: 109.419,
          h: 109.447,
          l: 109.403,
          c: 109.421,
          v: 668120000
        },
        {
          t: 85500,
          o: 109.42,
          h: 109.423,
          l: 109.356,
          c: 109.374,
          v: 953790000
        }
      ]
    },
    {
      d: 1524700800,
      b: [
        {
          t: 0,
          o: 109.377,
          h: 109.383,
          l: 109.243,
          c: 109.296,
          v: 1614520000
        },
        {
          t: 900,
          o: 109.297,
          h: 109.35,
          l: 109.296,
          c: 109.326,
          v: 1002659999
        },
        {
          t: 1800,
          o: 109.327,
          h: 109.393,
          l: 109.327,
          c: 109.342,
          v: 1172300000
        },
        {
          t: 2700,
          o: 109.343,
          h: 109.433,
          l: 109.302,
          c: 109.413,
          v: 2035450000
        },
        {
          t: 3600,
          o: 109.413,
          h: 109.455,
          l: 109.401,
          c: 109.409,
          v: 1442430000
        },
        {
          t: 4500,
          o: 109.412,
          h: 109.44,
          l: 109.379,
          c: 109.399,
          v: 1353850000
        },
        {
          t: 5400,
          o: 109.398,
          h: 109.424,
          l: 109.353,
          c: 109.364,
          v: 1460450000
        },
        {
          t: 6300,
          o: 109.363,
          h: 109.367,
          l: 109.29,
          c: 109.367,
          v: 1734790000
        },
        {
          t: 7200,
          o: 109.365,
          h: 109.365,
          l: 109.305,
          c: 109.322,
          v: 1431190000
        },
        {
          t: 8100,
          o: 109.322,
          h: 109.357,
          l: 109.308,
          c: 109.346,
          v: 850870000
        },
        {
          t: 9000,
          o: 109.347,
          h: 109.374,
          l: 109.335,
          c: 109.369,
          v: 1026010000
        },
        {
          t: 9900,
          o: 109.37,
          h: 109.375,
          l: 109.357,
          c: 109.367,
          v: 418670000
        },
        {
          t: 10800,
          o: 109.369,
          h: 109.374,
          l: 109.314,
          c: 109.321,
          v: 850970000
        },
        {
          t: 11700,
          o: 109.321,
          h: 109.34,
          l: 109.314,
          c: 109.317,
          v: 591530000
        },
        {
          t: 12600,
          o: 109.317,
          h: 109.324,
          l: 109.291,
          c: 109.3,
          v: 452140000
        },
        {
          t: 13500,
          o: 109.302,
          h: 109.306,
          l: 109.278,
          c: 109.291,
          v: 4085980028
        },
        {
          t: 14400,
          o: 109.291,
          h: 109.332,
          l: 109.291,
          c: 109.329,
          v: 443589999
        },
        {
          t: 15300,
          o: 109.33,
          h: 109.34,
          l: 109.304,
          c: 109.332,
          v: 7696749943
        },
        {
          t: 16200,
          o: 109.334,
          h: 109.356,
          l: 109.323,
          c: 109.323,
          v: 416380000
        },
        {
          t: 17100,
          o: 109.323,
          h: 109.343,
          l: 109.323,
          c: 109.338,
          v: 447980000
        },
        {
          t: 18000,
          o: 109.336,
          h: 109.347,
          l: 109.305,
          c: 109.306,
          v: 522990000
        },
        {
          t: 18900,
          o: 109.307,
          h: 109.338,
          l: 109.289,
          c: 109.334,
          v: 904280000
        },
        {
          t: 19800,
          o: 109.333,
          h: 109.341,
          l: 109.318,
          c: 109.341,
          v: 540420000
        },
        {
          t: 20700,
          o: 109.342,
          h: 109.346,
          l: 109.306,
          c: 109.325,
          v: 832580000
        },
        {
          t: 21600,
          o: 109.327,
          h: 109.378,
          l: 109.32,
          c: 109.369,
          v: 1630300000
        },
        {
          t: 22500,
          o: 109.368,
          h: 109.395,
          l: 109.336,
          c: 109.362,
          v: 1274560000
        },
        {
          t: 23400,
          o: 109.36,
          h: 109.376,
          l: 109.319,
          c: 109.33,
          v: 1577740000
        },
        {
          t: 24300,
          o: 109.326,
          h: 109.342,
          l: 109.28,
          c: 109.295,
          v: 1795960000
        },
        {
          t: 25200,
          o: 109.296,
          h: 109.302,
          l: 109.243,
          c: 109.286,
          v: 2506650000
        },
        {
          t: 26100,
          o: 109.287,
          h: 109.345,
          l: 109.275,
          c: 109.312,
          v: 2616830000
        },
        {
          t: 27000,
          o: 109.312,
          h: 109.332,
          l: 109.29,
          c: 109.313,
          v: 1712940000
        },
        {
          t: 27900,
          o: 109.312,
          h: 109.362,
          l: 109.309,
          c: 109.326,
          v: 1877730000
        },
        {
          t: 28800,
          o: 109.325,
          h: 109.377,
          l: 109.322,
          c: 109.368,
          v: 1519590000
        },
        {
          t: 29700,
          o: 109.367,
          h: 109.379,
          l: 109.335,
          c: 109.347,
          v: 1598920000
        },
        {
          t: 30600,
          o: 109.347,
          h: 109.348,
          l: 109.307,
          c: 109.327,
          v: 1288100000
        },
        {
          t: 31500,
          o: 109.328,
          h: 109.361,
          l: 109.327,
          c: 109.355,
          v: 1016220000
        },
        {
          t: 32400,
          o: 109.354,
          h: 109.383,
          l: 109.349,
          c: 109.35,
          v: 1074290000
        },
        {
          t: 33300,
          o: 109.35,
          h: 109.37,
          l: 109.325,
          c: 109.349,
          v: 1040260000
        },
        {
          t: 34200,
          o: 109.348,
          h: 109.361,
          l: 109.299,
          c: 109.305,
          v: 1338850001
        },
        {
          t: 35100,
          o: 109.305,
          h: 109.313,
          l: 109.264,
          c: 109.294,
          v: 1181679999
        },
        {
          t: 36000,
          o: 109.294,
          h: 109.316,
          l: 109.276,
          c: 109.312,
          v: 1726309985
        },
        {
          t: 36900,
          o: 109.311,
          h: 109.314,
          l: 109.273,
          c: 109.277,
          v: 1453430000
        },
        {
          t: 37800,
          o: 109.278,
          h: 109.278,
          l: 109.244,
          c: 109.244,
          v: 669660000
        },
        {
          t: 38700,
          o: 109.242,
          h: 109.246,
          l: 109.174,
          c: 109.212,
          v: 1277910000
        },
        {
          t: 39600,
          o: 109.212,
          h: 109.236,
          l: 109.137,
          c: 109.143,
          v: 1485020000
        },
        {
          t: 40500,
          o: 109.142,
          h: 109.163,
          l: 109.103,
          c: 109.128,
          v: 1461910000
        },
        {
          t: 41400,
          o: 109.127,
          h: 109.148,
          l: 109.11,
          c: 109.133,
          v: 946490000
        },
        {
          t: 42300,
          o: 109.132,
          h: 109.192,
          l: 109.128,
          c: 109.18,
          v: 1821660000
        },
        {
          t: 43200,
          o: 109.18,
          h: 109.197,
          l: 109.127,
          c: 109.189,
          v: 2097529999
        },
        {
          t: 44100,
          o: 109.188,
          h: 109.223,
          l: 109.172,
          c: 109.176,
          v: 1739150000
        },
        {
          t: 45000,
          o: 109.162,
          h: 109.263,
          l: 109.137,
          c: 109.14,
          v: 4439930000
        },
        {
          t: 45900,
          o: 109.139,
          h: 109.171,
          l: 109.098,
          c: 109.171,
          v: 4083820000
        },
        {
          t: 46800,
          o: 109.17,
          h: 109.196,
          l: 109.112,
          c: 109.167,
          v: 2233089999
        },
        {
          t: 47700,
          o: 109.167,
          h: 109.169,
          l: 109.067,
          c: 109.079,
          v: 1749340000
        },
        {
          t: 48600,
          o: 109.081,
          h: 109.166,
          l: 109.066,
          c: 109.158,
          v: 2669130000
        },
        {
          t: 49500,
          o: 109.158,
          h: 109.205,
          l: 109.105,
          c: 109.193,
          v: 2804000000
        },
        {
          t: 50400,
          o: 109.191,
          h: 109.283,
          l: 109.181,
          c: 109.265,
          v: 3292780000
        },
        {
          t: 51300,
          o: 109.264,
          h: 109.308,
          l: 109.21,
          c: 109.223,
          v: 3551440005
        },
        {
          t: 52200,
          o: 109.226,
          h: 109.319,
          l: 109.226,
          c: 109.284,
          v: 2564209998
        },
        {
          t: 53100,
          o: 109.283,
          h: 109.363,
          l: 109.283,
          c: 109.363,
          v: 2428590000
        },
        {
          t: 54000,
          o: 109.361,
          h: 109.388,
          l: 109.323,
          c: 109.373,
          v: 1821420000
        },
        {
          t: 54900,
          o: 109.376,
          h: 109.396,
          l: 109.334,
          c: 109.366,
          v: 1676750000
        },
        {
          t: 55800,
          o: 109.365,
          h: 109.368,
          l: 109.322,
          c: 109.325,
          v: 1958110000
        },
        {
          t: 56700,
          o: 109.325,
          h: 109.341,
          l: 109.254,
          c: 109.293,
          v: 2066980000
        },
        {
          t: 57600,
          o: 109.295,
          h: 109.329,
          l: 109.235,
          c: 109.235,
          v: 1567160001
        },
        {
          t: 58500,
          o: 109.238,
          h: 109.26,
          l: 109.215,
          c: 109.224,
          v: 1250300000
        },
        {
          t: 59400,
          o: 109.224,
          h: 109.241,
          l: 109.204,
          c: 109.237,
          v: 991020000
        },
        {
          t: 60300,
          o: 109.235,
          h: 109.264,
          l: 109.192,
          c: 109.2,
          v: 1224020000
        },
        {
          t: 61200,
          o: 109.201,
          h: 109.238,
          l: 109.201,
          c: 109.236,
          v: 976290000
        },
        {
          t: 62100,
          o: 109.236,
          h: 109.248,
          l: 109.233,
          c: 109.245,
          v: 579000000
        },
        {
          t: 63000,
          o: 109.247,
          h: 109.287,
          l: 109.24,
          c: 109.277,
          v: 996959999
        },
        {
          t: 63900,
          o: 109.277,
          h: 109.306,
          l: 109.259,
          c: 109.296,
          v: 1033750000
        },
        {
          t: 64800,
          o: 109.296,
          h: 109.349,
          l: 109.285,
          c: 109.348,
          v: 1041320000
        },
        {
          t: 65700,
          o: 109.347,
          h: 109.397,
          l: 109.331,
          c: 109.395,
          v: 1347300001
        },
        {
          t: 66600,
          o: 109.395,
          h: 109.405,
          l: 109.351,
          c: 109.399,
          v: 1265860000
        },
        {
          t: 67500,
          o: 109.399,
          h: 109.399,
          l: 109.335,
          c: 109.345,
          v: 1504270000
        },
        {
          t: 68400,
          o: 109.348,
          h: 109.407,
          l: 109.348,
          c: 109.407,
          v: 1167890000
        },
        {
          t: 69300,
          o: 109.407,
          h: 109.419,
          l: 109.37,
          c: 109.381,
          v: 1152539999
        },
        {
          t: 70200,
          o: 109.383,
          h: 109.396,
          l: 109.316,
          c: 109.341,
          v: 936820000
        },
        {
          t: 71100,
          o: 109.341,
          h: 109.357,
          l: 109.32,
          c: 109.357,
          v: 1043520000
        },
        {
          t: 72000,
          o: 109.357,
          h: 109.381,
          l: 109.345,
          c: 109.364,
          v: 775430000
        },
        {
          t: 72900,
          o: 109.364,
          h: 109.369,
          l: 109.334,
          c: 109.335,
          v: 785290000
        },
        {
          t: 73800,
          o: 109.335,
          h: 109.359,
          l: 109.321,
          c: 109.321,
          v: 582440000
        },
        {
          t: 74700,
          o: 109.323,
          h: 109.323,
          l: 109.294,
          c: 109.294,
          v: 552530000
        },
        {
          t: 75600,
          o: 109.294,
          h: 109.294,
          l: 109.253,
          c: 109.283,
          v: 720270000
        },
        {
          t: 76500,
          o: 109.283,
          h: 109.3,
          l: 109.283,
          c: 109.295,
          v: 1253680000
        },
        {
          t: 77400,
          o: 109.306,
          h: 109.33,
          l: 109.282,
          c: 109.294,
          v: 1719320000
        },
        {
          t: 78300,
          o: 109.293,
          h: 109.343,
          l: 109.258,
          c: 109.307,
          v: 1480270000
        },
        {
          t: 79200,
          o: 109.305,
          h: 109.325,
          l: 109.302,
          c: 109.316,
          v: 591270000
        },
        {
          t: 80100,
          o: 109.317,
          h: 109.333,
          l: 109.317,
          c: 109.331,
          v: 167270000
        },
        {
          t: 81000,
          o: 109.331,
          h: 109.331,
          l: 109.322,
          c: 109.324,
          v: 138420000
        },
        {
          t: 81900,
          o: 109.327,
          h: 109.353,
          l: 109.319,
          c: 109.348,
          v: 198380000
        },
        {
          t: 82800,
          o: 109.347,
          h: 109.347,
          l: 109.337,
          c: 109.337,
          v: 168590000
        },
        {
          t: 83700,
          o: 109.338,
          h: 109.34,
          l: 109.318,
          c: 109.319,
          v: 161620000
        },
        {
          t: 84600,
          o: 109.32,
          h: 109.329,
          l: 109.301,
          c: 109.319,
          v: 376950000
        },
        {
          t: 85500,
          o: 109.319,
          h: 109.32,
          l: 109.301,
          c: 109.302,
          v: 416910000
        }
      ]
    },
    {
      d: 1524787200,
      b: [
        {
          t: 0,
          o: 109.302,
          h: 109.319,
          l: 109.268,
          c: 109.272,
          v: 1584380000
        },
        {
          t: 900,
          o: 109.275,
          h: 109.291,
          l: 109.243,
          c: 109.289,
          v: 1567550000
        },
        {
          t: 1800,
          o: 109.292,
          h: 109.296,
          l: 109.246,
          c: 109.247,
          v: 1041120000
        },
        {
          t: 2700,
          o: 109.248,
          h: 109.325,
          l: 109.233,
          c: 109.266,
          v: 2395469999
        },
        {
          t: 3600,
          o: 109.266,
          h: 109.29,
          l: 109.251,
          c: 109.276,
          v: 1718190000
        },
        {
          t: 4500,
          o: 109.276,
          h: 109.329,
          l: 109.269,
          c: 109.312,
          v: 1106400000
        },
        {
          t: 5400,
          o: 109.312,
          h: 109.337,
          l: 109.252,
          c: 109.268,
          v: 1617900002
        },
        {
          t: 6300,
          o: 109.269,
          h: 109.274,
          l: 109.152,
          c: 109.158,
          v: 1723260000
        },
        {
          t: 7200,
          o: 109.16,
          h: 109.238,
          l: 109.144,
          c: 109.206,
          v: 1570430000
        },
        {
          t: 8100,
          o: 109.206,
          h: 109.224,
          l: 109.15,
          c: 109.158,
          v: 949010000
        },
        {
          t: 9000,
          o: 109.158,
          h: 109.179,
          l: 109.148,
          c: 109.161,
          v: 1052530000
        },
        {
          t: 9900,
          o: 109.168,
          h: 109.178,
          l: 109.153,
          c: 109.159,
          v: 848280000
        },
        {
          t: 10800,
          o: 109.158,
          h: 109.242,
          l: 109.157,
          c: 109.225,
          v: 1335500000
        },
        {
          t: 11700,
          o: 109.224,
          h: 109.245,
          l: 109.201,
          c: 109.216,
          v: 962610000
        },
        {
          t: 12600,
          o: 109.218,
          h: 109.245,
          l: 109.168,
          c: 109.18,
          v: 1084080000
        },
        {
          t: 13500,
          o: 109.179,
          h: 109.205,
          l: 109.163,
          c: 109.174,
          v: 696770000
        },
        {
          t: 14400,
          o: 109.175,
          h: 109.219,
          l: 109.175,
          c: 109.21,
          v: 585850000
        },
        {
          t: 15300,
          o: 109.21,
          h: 109.21,
          l: 109.181,
          c: 109.188,
          v: 853870000
        },
        {
          t: 16200,
          o: 109.188,
          h: 109.191,
          l: 109.166,
          c: 109.169,
          v: 1105830000
        },
        {
          t: 17100,
          o: 109.169,
          h: 109.179,
          l: 109.153,
          c: 109.179,
          v: 1066110002
        },
        {
          t: 18000,
          o: 109.178,
          h: 109.323,
          l: 109.163,
          c: 109.275,
          v: 1838489997
        },
        {
          t: 18900,
          o: 109.276,
          h: 109.295,
          l: 109.254,
          c: 109.263,
          v: 1104780000
        },
        {
          t: 19800,
          o: 109.263,
          h: 109.276,
          l: 109.241,
          c: 109.26,
          v: 1004450000
        },
        {
          t: 20700,
          o: 109.261,
          h: 109.263,
          l: 109.227,
          c: 109.243,
          v: 1061250000
        },
        {
          t: 21600,
          o: 109.244,
          h: 109.307,
          l: 109.244,
          c: 109.3,
          v: 1657030000
        },
        {
          t: 22500,
          o: 109.299,
          h: 109.339,
          l: 109.278,
          c: 109.333,
          v: 1844080000
        },
        {
          t: 23400,
          o: 109.333,
          h: 109.336,
          l: 109.3,
          c: 109.306,
          v: 1279230000
        },
        {
          t: 24300,
          o: 109.312,
          h: 109.332,
          l: 109.261,
          c: 109.303,
          v: 1563320000
        },
        {
          t: 25200,
          o: 109.303,
          h: 109.357,
          l: 109.273,
          c: 109.344,
          v: 1899249995
        },
        {
          t: 26100,
          o: 109.347,
          h: 109.502,
          l: 109.34,
          c: 109.369,
          v: 2335870001
        },
        {
          t: 27000,
          o: 109.369,
          h: 109.414,
          l: 109.345,
          c: 109.346,
          v: 1526670000
        },
        {
          t: 27900,
          o: 109.352,
          h: 109.408,
          l: 109.351,
          c: 109.393,
          v: 1324940000
        },
        {
          t: 28800,
          o: 109.392,
          h: 109.396,
          l: 109.287,
          c: 109.314,
          v: 1462990000
        },
        {
          t: 29700,
          o: 109.315,
          h: 109.363,
          l: 109.307,
          c: 109.332,
          v: 990700000
        },
        {
          t: 30600,
          o: 109.333,
          h: 109.393,
          l: 109.309,
          c: 109.319,
          v: 2182850000
        },
        {
          t: 31500,
          o: 109.319,
          h: 109.402,
          l: 109.301,
          c: 109.375,
          v: 1781940000
        },
        {
          t: 32400,
          o: 109.375,
          h: 109.384,
          l: 109.31,
          c: 109.314,
          v: 1562140000
        },
        {
          t: 33300,
          o: 109.32,
          h: 109.325,
          l: 109.285,
          c: 109.314,
          v: 1366810000
        },
        {
          t: 34200,
          o: 109.313,
          h: 109.329,
          l: 109.256,
          c: 109.285,
          v: 1335160000
        },
        {
          t: 35100,
          o: 109.285,
          h: 109.322,
          l: 109.285,
          c: 109.301,
          v: 1138310000
        },
        {
          t: 36000,
          o: 109.302,
          h: 109.365,
          l: 109.302,
          c: 109.326,
          v: 1306160000
        },
        {
          t: 36900,
          o: 109.328,
          h: 109.361,
          l: 109.324,
          c: 109.336,
          v: 954850000
        },
        {
          t: 37800,
          o: 109.335,
          h: 109.362,
          l: 109.318,
          c: 109.33,
          v: 1299320001
        },
        {
          t: 38700,
          o: 109.33,
          h: 109.355,
          l: 109.306,
          c: 109.307,
          v: 1281470000
        },
        {
          t: 39600,
          o: 109.309,
          h: 109.328,
          l: 109.3,
          c: 109.328,
          v: 1217760000
        },
        {
          t: 40500,
          o: 109.328,
          h: 109.348,
          l: 109.318,
          c: 109.321,
          v: 1056640000
        },
        {
          t: 41400,
          o: 109.321,
          h: 109.321,
          l: 109.298,
          c: 109.314,
          v: 681460000
        },
        {
          t: 42300,
          o: 109.315,
          h: 109.342,
          l: 109.297,
          c: 109.32,
          v: 1534510000
        },
        {
          t: 43200,
          o: 109.319,
          h: 109.345,
          l: 109.309,
          c: 109.314,
          v: 1824590002
        },
        {
          t: 44100,
          o: 109.316,
          h: 109.349,
          l: 109.303,
          c: 109.327,
          v: 2061549990
        },
        {
          t: 45000,
          o: 109.327,
          h: 109.536,
          l: 109.324,
          c: 109.455,
          v: 4615090000
        },
        {
          t: 45900,
          o: 109.454,
          h: 109.486,
          l: 109.263,
          c: 109.312,
          v: 3481420000
        },
        {
          t: 46800,
          o: 109.313,
          h: 109.33,
          l: 109.249,
          c: 109.274,
          v: 2614020000
        },
        {
          t: 47700,
          o: 109.273,
          h: 109.343,
          l: 109.273,
          c: 109.303,
          v: 2435640000
        },
        {
          t: 48600,
          o: 109.303,
          h: 109.307,
          l: 109.129,
          c: 109.16,
          v: 2733650000
        },
        {
          t: 49500,
          o: 109.159,
          h: 109.212,
          l: 109.133,
          c: 109.204,
          v: 2639509998
        },
        {
          t: 50400,
          o: 109.203,
          h: 109.26,
          l: 109.198,
          c: 109.237,
          v: 2638330000
        },
        {
          t: 51300,
          o: 109.237,
          h: 109.266,
          l: 109.169,
          c: 109.201,
          v: 2807840000
        },
        {
          t: 52200,
          o: 109.2,
          h: 109.2,
          l: 108.964,
          c: 109.042,
          v: 3349720000
        },
        {
          t: 53100,
          o: 109.043,
          h: 109.104,
          l: 108.992,
          c: 109.074,
          v: 2328690000
        },
        {
          t: 54000,
          o: 109.074,
          h: 109.111,
          l: 109.047,
          c: 109.057,
          v: 1975080000
        },
        {
          t: 54900,
          o: 109.058,
          h: 109.081,
          l: 109.007,
          c: 109.068,
          v: 1666800000
        },
        {
          t: 55800,
          o: 109.067,
          h: 109.141,
          l: 109.045,
          c: 109.094,
          v: 1658899999
        },
        {
          t: 56700,
          o: 109.092,
          h: 109.136,
          l: 109.075,
          c: 109.117,
          v: 1420919998
        },
        {
          t: 57600,
          o: 109.119,
          h: 109.148,
          l: 109.104,
          c: 109.117,
          v: 1012310000
        },
        {
          t: 58500,
          o: 109.118,
          h: 109.135,
          l: 109.067,
          c: 109.13,
          v: 944130000
        },
        {
          t: 59400,
          o: 109.13,
          h: 109.13,
          l: 109.047,
          c: 109.078,
          v: 1375000000
        },
        {
          t: 60300,
          o: 109.076,
          h: 109.106,
          l: 109.064,
          c: 109.084,
          v: 1339320000
        },
        {
          t: 61200,
          o: 109.084,
          h: 109.127,
          l: 109.084,
          c: 109.127,
          v: 902020000
        },
        {
          t: 62100,
          o: 109.128,
          h: 109.14,
          l: 109.112,
          c: 109.113,
          v: 744220000
        },
        {
          t: 63000,
          o: 109.112,
          h: 109.14,
          l: 109.104,
          c: 109.139,
          v: 763270000
        },
        {
          t: 63900,
          o: 109.14,
          h: 109.146,
          l: 109.09,
          c: 109.105,
          v: 899070000
        },
        {
          t: 64800,
          o: 109.108,
          h: 109.114,
          l: 109.086,
          c: 109.108,
          v: 1005120000
        },
        {
          t: 65700,
          o: 109.108,
          h: 109.121,
          l: 109.084,
          c: 109.12,
          v: 753719999
        },
        {
          t: 66600,
          o: 109.12,
          h: 109.12,
          l: 109.066,
          c: 109.083,
          v: 918640000
        },
        {
          t: 67500,
          o: 109.083,
          h: 109.089,
          l: 109.016,
          c: 109.023,
          v: 1377180001
        },
        {
          t: 68400,
          o: 109.023,
          h: 109.043,
          l: 109.003,
          c: 109.019,
          v: 1138500000
        },
        {
          t: 69300,
          o: 109.019,
          h: 109.039,
          l: 108.986,
          c: 109.01,
          v: 1065970000
        },
        {
          t: 70200,
          o: 109.01,
          h: 109.061,
          l: 109.01,
          c: 109.033,
          v: 1015060000
        },
        {
          t: 71100,
          o: 109.032,
          h: 109.069,
          l: 109.031,
          c: 109.059,
          v: 764400000
        },
        {
          t: 72000,
          o: 109.057,
          h: 109.136,
          l: 109.049,
          c: 109.136,
          v: 505950000
        },
        {
          t: 72900,
          o: 109.136,
          h: 109.137,
          l: 109.085,
          c: 109.088,
          v: 345580000
        },
        {
          t: 73800,
          o: 109.087,
          h: 109.094,
          l: 109.068,
          c: 109.088,
          v: 430010000
        },
        {
          t: 74700,
          o: 109.088,
          h: 109.09,
          l: 109.034,
          c: 109.045,
          v: 516890000
        }
      ]
    },
    {
      d: 1524960000,
      b: [
        {
          t: 75600,
          o: 109.057,
          h: 109.083,
          l: 109.017,
          c: 109.056,
          v: 79970000
        },
        {
          t: 76500,
          o: 109.061,
          h: 109.087,
          l: 109.026,
          c: 109.083,
          v: 133780000
        },
        {
          t: 77400,
          o: 109.083,
          h: 109.089,
          l: 109.032,
          c: 109.051,
          v: 67010000
        },
        {
          t: 78300,
          o: 109.051,
          h: 109.082,
          l: 109.05,
          c: 109.08,
          v: 60980000
        },
        {
          t: 79200,
          o: 109.08,
          h: 109.121,
          l: 109.077,
          c: 109.119,
          v: 1757790000
        },
        {
          t: 80100,
          o: 109.12,
          h: 109.134,
          l: 109.11,
          c: 109.132,
          v: 379160000
        },
        {
          t: 81000,
          o: 109.131,
          h: 109.143,
          l: 109.122,
          c: 109.14,
          v: 471090000
        },
        {
          t: 81900,
          o: 109.14,
          h: 109.161,
          l: 109.14,
          c: 109.143,
          v: 174600000
        },
        {
          t: 82800,
          o: 109.143,
          h: 109.176,
          l: 109.143,
          c: 109.174,
          v: 302680000
        },
        {
          t: 83700,
          o: 109.174,
          h: 109.179,
          l: 109.155,
          c: 109.161,
          v: 248390000
        },
        {
          t: 84600,
          o: 109.161,
          h: 109.161,
          l: 109.102,
          c: 109.102,
          v: 302920000
        },
        {
          t: 85500,
          o: 109.103,
          h: 109.131,
          l: 109.093,
          c: 109.094,
          v: 674170000
        }
      ]
    },
    {
      d: 1525046400,
      b: [
        {
          t: 0,
          o: 109.093,
          h: 109.105,
          l: 109.04,
          c: 109.041,
          v: 877170001
        },
        {
          t: 900,
          o: 109.041,
          h: 109.076,
          l: 109.02,
          c: 109.065,
          v: 773530000
        },
        {
          t: 1800,
          o: 109.065,
          h: 109.078,
          l: 109.045,
          c: 109.05,
          v: 551500000
        },
        {
          t: 2700,
          o: 109.052,
          h: 109.086,
          l: 109.04,
          c: 109.074,
          v: 688800000
        },
        {
          t: 3600,
          o: 109.073,
          h: 109.12,
          l: 109.06,
          c: 109.113,
          v: 494450000
        },
        {
          t: 4500,
          o: 109.114,
          h: 109.126,
          l: 109.081,
          c: 109.083,
          v: 768860000
        },
        {
          t: 5400,
          o: 109.082,
          h: 109.116,
          l: 109.075,
          c: 109.102,
          v: 768690000
        },
        {
          t: 6300,
          o: 109.103,
          h: 109.118,
          l: 109.076,
          c: 109.088,
          v: 1060000000
        },
        {
          t: 7200,
          o: 109.089,
          h: 109.098,
          l: 109.071,
          c: 109.078,
          v: 414150000
        },
        {
          t: 8100,
          o: 109.078,
          h: 109.099,
          l: 109.068,
          c: 109.093,
          v: 461480000
        },
        {
          t: 9000,
          o: 109.093,
          h: 109.133,
          l: 109.093,
          c: 109.106,
          v: 909100000
        },
        {
          t: 9900,
          o: 109.106,
          h: 109.132,
          l: 109.082,
          c: 109.12,
          v: 590870000
        },
        {
          t: 10800,
          o: 109.121,
          h: 109.151,
          l: 109.101,
          c: 109.141,
          v: 526140000
        },
        {
          t: 11700,
          o: 109.142,
          h: 109.145,
          l: 109.101,
          c: 109.113,
          v: 343630000
        },
        {
          t: 12600,
          o: 109.111,
          h: 109.131,
          l: 109.104,
          c: 109.123,
          v: 382160000
        },
        {
          t: 13500,
          o: 109.124,
          h: 109.134,
          l: 109.112,
          c: 109.134,
          v: 225070000
        },
        {
          t: 14400,
          o: 109.134,
          h: 109.147,
          l: 109.128,
          c: 109.135,
          v: 189870000
        },
        {
          t: 15300,
          o: 109.135,
          h: 109.149,
          l: 109.135,
          c: 109.148,
          v: 265060001
        },
        {
          t: 16200,
          o: 109.148,
          h: 109.156,
          l: 109.135,
          c: 109.141,
          v: 367510000
        },
        {
          t: 17100,
          o: 109.142,
          h: 109.153,
          l: 109.124,
          c: 109.138,
          v: 292310000
        },
        {
          t: 18000,
          o: 109.137,
          h: 109.147,
          l: 109.129,
          c: 109.146,
          v: 417990000
        },
        {
          t: 18900,
          o: 109.146,
          h: 109.164,
          l: 109.146,
          c: 109.158,
          v: 399200000
        },
        {
          t: 19800,
          o: 109.158,
          h: 109.164,
          l: 109.137,
          c: 109.158,
          v: 374680000
        },
        {
          t: 20700,
          o: 109.159,
          h: 109.161,
          l: 109.131,
          c: 109.142,
          v: 600450000
        },
        {
          t: 21600,
          o: 109.143,
          h: 109.204,
          l: 109.143,
          c: 109.192,
          v: 1425800000
        },
        {
          t: 22500,
          o: 109.192,
          h: 109.211,
          l: 109.14,
          c: 109.151,
          v: 1296580000
        },
        {
          t: 23400,
          o: 109.152,
          h: 109.204,
          l: 109.152,
          c: 109.189,
          v: 1406280000
        },
        {
          t: 24300,
          o: 109.187,
          h: 109.206,
          l: 109.171,
          c: 109.197,
          v: 794790000
        },
        {
          t: 25200,
          o: 109.196,
          h: 109.245,
          l: 109.187,
          c: 109.244,
          v: 1507940000
        },
        {
          t: 26100,
          o: 109.244,
          h: 109.262,
          l: 109.23,
          c: 109.239,
          v: 972200000
        },
        {
          t: 27000,
          o: 109.238,
          h: 109.26,
          l: 109.227,
          c: 109.239,
          v: 1158320000
        },
        {
          t: 27900,
          o: 109.238,
          h: 109.259,
          l: 109.237,
          c: 109.248,
          v: 776990001
        },
        {
          t: 28800,
          o: 109.248,
          h: 109.284,
          l: 109.241,
          c: 109.274,
          v: 1029800000
        },
        {
          t: 29700,
          o: 109.276,
          h: 109.284,
          l: 109.259,
          c: 109.26,
          v: 913710000
        },
        {
          t: 30600,
          o: 109.259,
          h: 109.294,
          l: 109.257,
          c: 109.291,
          v: 1127049997
        },
        {
          t: 31500,
          o: 109.291,
          h: 109.328,
          l: 109.287,
          c: 109.308,
          v: 899430000
        },
        {
          t: 32400,
          o: 109.307,
          h: 109.326,
          l: 109.274,
          c: 109.276,
          v: 1283900001
        },
        {
          t: 33300,
          o: 109.276,
          h: 109.309,
          l: 109.258,
          c: 109.291,
          v: 903700000
        },
        {
          t: 34200,
          o: 109.29,
          h: 109.306,
          l: 109.261,
          c: 109.265,
          v: 978370000
        },
        {
          t: 35100,
          o: 109.266,
          h: 109.284,
          l: 109.242,
          c: 109.25,
          v: 820920000
        },
        {
          t: 36000,
          o: 109.25,
          h: 109.276,
          l: 109.237,
          c: 109.242,
          v: 949140000
        },
        {
          t: 36900,
          o: 109.242,
          h: 109.27,
          l: 109.231,
          c: 109.253,
          v: 791780000
        },
        {
          t: 37800,
          o: 109.253,
          h: 109.305,
          l: 109.249,
          c: 109.272,
          v: 1233440000
        },
        {
          t: 38700,
          o: 109.272,
          h: 109.29,
          l: 109.256,
          c: 109.266,
          v: 959410000
        },
        {
          t: 39600,
          o: 109.268,
          h: 109.315,
          l: 109.264,
          c: 109.315,
          v: 867430000
        },
        {
          t: 40500,
          o: 109.315,
          h: 109.352,
          l: 109.313,
          c: 109.337,
          v: 955350000
        },
        {
          t: 41400,
          o: 109.337,
          h: 109.387,
          l: 109.324,
          c: 109.382,
          v: 922350000
        },
        {
          t: 42300,
          o: 109.383,
          h: 109.407,
          l: 109.367,
          c: 109.368,
          v: 1137760000
        },
        {
          t: 43200,
          o: 109.37,
          h: 109.394,
          l: 109.345,
          c: 109.388,
          v: 1224330000
        },
        {
          t: 44100,
          o: 109.39,
          h: 109.429,
          l: 109.379,
          c: 109.386,
          v: 1260090000
        },
        {
          t: 45000,
          o: 109.396,
          h: 109.41,
          l: 109.309,
          c: 109.362,
          v: 1629110000
        },
        {
          t: 45900,
          o: 109.361,
          h: 109.372,
          l: 109.297,
          c: 109.321,
          v: 1197110000
        },
        {
          t: 46800,
          o: 109.321,
          h: 109.375,
          l: 109.284,
          c: 109.373,
          v: 1108620000
        },
        {
          t: 47700,
          o: 109.374,
          h: 109.415,
          l: 109.368,
          c: 109.394,
          v: 1016870000
        },
        {
          t: 48600,
          o: 109.397,
          h: 109.397,
          l: 109.364,
          c: 109.368,
          v: 1767710000
        },
        {
          t: 49500,
          o: 109.368,
          h: 109.407,
          l: 109.364,
          c: 109.37,
          v: 1658130000
        },
        {
          t: 50400,
          o: 109.37,
          h: 109.384,
          l: 109.301,
          c: 109.329,
          v: 1658120000
        },
        {
          t: 51300,
          o: 109.328,
          h: 109.365,
          l: 109.299,
          c: 109.357,
          v: 1652550000
        },
        {
          t: 52200,
          o: 109.358,
          h: 109.411,
          l: 109.321,
          c: 109.351,
          v: 1693980000
        },
        {
          t: 53100,
          o: 109.35,
          h: 109.452,
          l: 109.341,
          c: 109.431,
          v: 2433930003
        },
        {
          t: 54000,
          o: 109.431,
          h: 109.44,
          l: 109.338,
          c: 109.399,
          v: 2232250000
        },
        {
          t: 54900,
          o: 109.398,
          h: 109.401,
          l: 109.363,
          c: 109.383,
          v: 1690020000
        },
        {
          t: 55800,
          o: 109.383,
          h: 109.385,
          l: 109.356,
          c: 109.36,
          v: 1673430000
        },
        {
          t: 56700,
          o: 109.359,
          h: 109.368,
          l: 109.246,
          c: 109.286,
          v: 1661080001
        },
        {
          t: 57600,
          o: 109.287,
          h: 109.307,
          l: 109.193,
          c: 109.221,
          v: 1803030000
        },
        {
          t: 58500,
          o: 109.221,
          h: 109.221,
          l: 109.122,
          c: 109.157,
          v: 1782600000
        },
        {
          t: 59400,
          o: 109.156,
          h: 109.187,
          l: 109.134,
          c: 109.144,
          v: 1433710000
        },
        {
          t: 60300,
          o: 109.144,
          h: 109.176,
          l: 109.086,
          c: 109.121,
          v: 1558680000
        },
        {
          t: 61200,
          o: 109.121,
          h: 109.198,
          l: 109.109,
          c: 109.127,
          v: 1462290000
        },
        {
          t: 62100,
          o: 109.127,
          h: 109.212,
          l: 109.105,
          c: 109.21,
          v: 1670890000
        },
        {
          t: 63000,
          o: 109.21,
          h: 109.275,
          l: 109.205,
          c: 109.257,
          v: 1151530000
        },
        {
          t: 63900,
          o: 109.257,
          h: 109.263,
          l: 109.206,
          c: 109.242,
          v: 1257210000
        },
        {
          t: 64800,
          o: 109.241,
          h: 109.243,
          l: 109.179,
          c: 109.188,
          v: 1208720000
        },
        {
          t: 65700,
          o: 109.187,
          h: 109.209,
          l: 109.163,
          c: 109.193,
          v: 1291569999
        },
        {
          t: 66600,
          o: 109.19,
          h: 109.21,
          l: 109.175,
          c: 109.193,
          v: 1431320000
        },
        {
          t: 67500,
          o: 109.193,
          h: 109.298,
          l: 109.188,
          c: 109.291,
          v: 1359720000
        },
        {
          t: 68400,
          o: 109.291,
          h: 109.315,
          l: 109.27,
          c: 109.31,
          v: 1087120000
        },
        {
          t: 69300,
          o: 109.308,
          h: 109.323,
          l: 109.287,
          c: 109.315,
          v: 942010000
        },
        {
          t: 70200,
          o: 109.315,
          h: 109.369,
          l: 109.307,
          c: 109.329,
          v: 799060000
        },
        {
          t: 71100,
          o: 109.33,
          h: 109.344,
          l: 109.312,
          c: 109.317,
          v: 899420000
        },
        {
          t: 72000,
          o: 109.316,
          h: 109.316,
          l: 109.287,
          c: 109.304,
          v: 468340000
        },
        {
          t: 72900,
          o: 109.304,
          h: 109.304,
          l: 109.284,
          c: 109.284,
          v: 231000000
        },
        {
          t: 73800,
          o: 109.284,
          h: 109.33,
          l: 109.283,
          c: 109.326,
          v: 374630000
        },
        {
          t: 74700,
          o: 109.326,
          h: 109.336,
          l: 109.299,
          c: 109.33,
          v: 385180000
        },
        {
          t: 75600,
          o: 109.33,
          h: 109.33,
          l: 109.264,
          c: 109.302,
          v: 1258220000
        },
        {
          t: 76500,
          o: 109.304,
          h: 109.305,
          l: 109.235,
          c: 109.267,
          v: 1058120000
        },
        {
          t: 77400,
          o: 109.266,
          h: 109.286,
          l: 109.261,
          c: 109.265,
          v: 1073510000
        },
        {
          t: 78300,
          o: 109.263,
          h: 109.27,
          l: 109.258,
          c: 109.262,
          v: 472960000
        },
        {
          t: 79200,
          o: 109.265,
          h: 109.279,
          l: 109.238,
          c: 109.274,
          v: 1087990000
        },
        {
          t: 80100,
          o: 109.274,
          h: 109.283,
          l: 109.269,
          c: 109.275,
          v: 1097730000
        },
        {
          t: 81000,
          o: 109.274,
          h: 109.274,
          l: 109.256,
          c: 109.262,
          v: 534300000
        },
        {
          t: 81900,
          o: 109.262,
          h: 109.281,
          l: 109.254,
          c: 109.271,
          v: 393280000
        },
        {
          t: 82800,
          o: 109.269,
          h: 109.277,
          l: 109.25,
          c: 109.262,
          v: 229270000
        },
        {
          t: 83700,
          o: 109.263,
          h: 109.286,
          l: 109.261,
          c: 109.28,
          v: 264580000
        },
        {
          t: 84600,
          o: 109.281,
          h: 109.317,
          l: 109.281,
          c: 109.315,
          v: 355810000
        },
        {
          t: 85500,
          o: 109.315,
          h: 109.333,
          l: 109.301,
          c: 109.312,
          v: 639100000
        }
      ]
    }
  ]
}

export const basicCourseDemoNewsRecords: INews[] = []

export const basicCourseDemoIndicatorsDataMapByName: { [chartName: string]: object } = {}

export const basicCourseDemoIndicatorsMapByChart: { [chartName: string]: string[] } = {}
