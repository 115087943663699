import { ChartType } from '../../utils/enums'
import { IChart } from '../../utils/interfaces/IChart.interface'
import { INote } from '../../utils/interfaces/INote.interface'

export interface IGraphsState {
  charts: IChart[]
  zoom: number // represent visible bars count
  currentChart: IChart
  chartsSettings: IChartsSettings
  autoScrollIsActive: boolean
  magnetModeIsActive: boolean
  syncChartsIsActive: boolean
  notes: INote[]
  endDate: number
  isLoadingData: { [symbol: string]: { [timeframe: number]: boolean } }
  projectName: string
  chartType: ChartType
  crosshair: boolean
}

export interface IChartsSettings {
  [key: string]: ICommonChartSettings
}

export interface IChartModes {
  linesModeIsActive: boolean
  barsModeIsActive: boolean
  candlesModeIsActive: boolean
  kagiModeIsActive: boolean
  areaModeIsActive: boolean
  pointAndFigureModeIsActive: boolean
  renkoBarsModeIsActive: boolean
}

export interface ICommonChartSettings {
  chartModes: IChartModes
  chartOffsetIsActive: boolean
  showVolumesIsActive: boolean
  showGridIsActive: boolean
  showPeriodSeparatorsIsActive: boolean
  showAccountHistoryIsActive: boolean
  showNotesIsActive: boolean
  showNewsIsActive: boolean
}

export const DEFAULT_CHART_MODES = {
  linesModeIsActive: false,
  barsModeIsActive: false,
  candlesModeIsActive: false,
  areaModeIsActive: false,
  kagiModeIsActive: false,
  pointAndFigureModeIsActive: false,
  renkoBarsModeIsActive: false
}

export const DEFAULT_CHART_SETTINGS = () => {
  return {
    chartModes: DEFAULT_CHART_MODES,
    chartOffsetIsActive: true, // false in desktop
    showVolumesIsActive: false,
    showGridIsActive: true,
    showPeriodSeparatorsIsActive: true,
    showAccountHistoryIsActive: true,
    showNotesIsActive: false,
    showNewsIsActive: true
  }
}
