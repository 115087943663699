import React, { FC } from 'react'
import { desktopOnlyAlert } from '../../../../../../../utils/constants'
import css from '../MarketOrderWindow.module.scss'

interface MarketOrderWindowTrailingStopTabProps {}

const MarketOrderWindowTrailingStopTab: FC<MarketOrderWindowTrailingStopTabProps> = () => {
  return (
    <div className={css.trailingStopTab}>
      <h2>Beta Version</h2>
      <span>{desktopOnlyAlert}</span>
    </div>
  )
}

export default MarketOrderWindowTrailingStopTab
