import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dummyHorizontalLine } from '../../components/chartContent/chart/tests/fixtures/fixtures'
import { isNotDefined } from '../../libs/react-stockcharts/lib/utils'
import { GraphicToolType } from '../../utils/enums'
import { ALL_DEFAULT_TIMEFRAMES } from '../../utils/helpers/constants'
import { ChartTool, IChartTools } from '../../utils/interfaces/IGraphics'
import { IIndicator, IOscillator } from '../../utils/interfaces/IIndicator.interface'
import { IHorizontalLine } from '../../utils/interfaces/ILine'
import { RootState } from '../store/store'
import { DEFAULT_CHART_TOOLS, IGraphicsState } from './types'

const initialState: IGraphicsState = {
  favoriteTools: [
    { type: 'Rectangle', count: 2 },
    { type: 'horizontalLine', count: 1 }
  ],
  toolIsActive: {
    verticalLine: false,
    horizontalLine: false,
    trendLine: false,
    polyline: false,
    ray: false,
    rectangle: false,
    RewardToRiskBuy: false,
    RewardToRiskSell: false,
    ellipse: false,
    triangle: false,
    fibonacciRetracement: false,
    fibonacciTimeZones: false,
    fibonacciFan: false,
    fibonacciArc: false,
    fibonacciExtension: false,
    fibonacciChannel: false,
    linearRegressionChannel: false,
    standardDeviationChannel: false,
    equidistantChannel: false,
    andrewsPitchfork: false,
    interactiveText: false,
    gannFan: false,
    interactiveYCoordinate: false,
    thumbUp: false,
    thumbDown: false,
    arrowUp: false,
    arrowDown: false,
    stopIcon: false,
    checkIcon: false,
    rightPriceLabel: false,
    leftPriceLabel: false,
    priceLabel: false
  },
  chartTools: {},
  indicators: {},
  oscillators: {},
  selectedIndicatorOscillator: {},
  selectedIndicatorOscillatorFullName: '',
  stopOrdersLines: {
    execPrice: dummyHorizontalLine,
    stopLoss: dummyHorizontalLine,
    takeProfit: dummyHorizontalLine
  },
  volumeOscillatorActive: true
}

const deleteAllObjects = createAsyncThunk<void, void, { state: RootState }>(
  'graphics/deleteAllObjects',
  async (_, api) => {
    const symbol = api.getState().graphs.currentChart.symbol
    ALL_DEFAULT_TIMEFRAMES.map((timeframe) => {
      api.dispatch(
        graphicsSlice.actions.updateChartTools({
          symbol,
          timeframe,
          tools: DEFAULT_CHART_TOOLS
        })
      )
    })
  }
)

const deleteSelectedObject = createAsyncThunk<void, void, { state: RootState }>(
  'graphics/deleteSelectedObject',
  async (_, api) => {
    const symbol = api.getState().graphs.currentChart.symbol
    ALL_DEFAULT_TIMEFRAMES.map((timeframe) => {
      api.dispatch(graphicsSlice.actions.deleteSelectedChartTools({ symbolTimeFrame: symbol + timeframe }))
    })
  }
)

const selectAllObjects = createAsyncThunk<void, void, { state: RootState }>(
  'graphics/selectAllObjects',
  async (_, api) => {
    const symbol = api.getState().graphs.currentChart.symbol
    ALL_DEFAULT_TIMEFRAMES.map((timeFrame) => {
      const symbolTimeframe = symbol + timeFrame
      const currentChartTools = api.getState().graphics.chartTools[symbolTimeframe]

      Object.keys(currentChartTools).map((toolName) => {
        const typedToolName = toolName as GraphicToolType
        currentChartTools[toolName].forEach((tools, index) => {
          if (!tools.selected)
            api.dispatch(graphicsSlice.actions.selectObject({ symbolTimeframe, toolName: typedToolName, index }))
        })
      })
    })
  }
)

const deselectAllObjects = createAsyncThunk<void, void, { state: RootState }>(
  'graphics/deselectAllObjects',
  async (_, api) => {
    const symbol = api.getState().graphs.currentChart.symbol
    ALL_DEFAULT_TIMEFRAMES.map((timeFrame) => {
      const symbolTimeframe = symbol + timeFrame
      const currentChartTools = api.getState().graphics.chartTools[symbolTimeframe]

      Object.keys(currentChartTools).map((toolName) => {
        const typedToolName = toolName as GraphicToolType
        currentChartTools[toolName].forEach((tools, index) => {
          if (tools.selected)
            api.dispatch(graphicsSlice.actions.deselectObject({ symbolTimeframe, toolName: typedToolName, index }))
        })
      })
    })
  }
)

const disableActiveTools = (tools) => {
  const newTools = { ...tools }

  Object.keys(newTools).forEach((key) => {
    newTools[key] = false
  })

  return newTools
}

const assignAllGraphics = createAction<IGraphicsState>('assignAllGraphics')

const graphicsSlice = createSlice({
  name: 'graphics',
  initialState,
  reducers: {
    // assignAllGraphics: (state: IGraphicsState, action: PayloadAction<IGraphicsState>) => {
    //   return action.payload
    // },

    toggleTool: (state: IGraphicsState, action: PayloadAction<string>) => {
      state.toolIsActive = {
        ...disableActiveTools(state.toolIsActive),
        [action.payload]: !state.toolIsActive[action.payload]
      }
    },

    disableTool: (state: IGraphicsState) => {
      state.toolIsActive = {
        ...disableActiveTools(state.toolIsActive)
      }
    },

    removeFavorite: (state: IGraphicsState, action: PayloadAction<{ tool: string }>) => {
      state.favoriteTools = state.favoriteTools.filter((x) => x.type != action.payload.tool)
    },

    createChartTools: (state: IGraphicsState, action: PayloadAction<{ symbol: string; timeframe: number }>) => {
      const { symbol, timeframe } = action.payload
      state.chartTools = {
        ...state.chartTools,
        [symbol + timeframe]: DEFAULT_CHART_TOOLS
      }
    },

    updateChartTools: (
      state: IGraphicsState,
      action: PayloadAction<{ symbol: string; timeframe: number; tools: IChartTools }>
    ) => {
      const { symbol, timeframe, tools } = action.payload
      state.chartTools = {
        ...state.chartTools,
        [symbol + timeframe]: { ...tools }
      }
    },

    pushTool: (
      state: IGraphicsState,
      action: PayloadAction<{ symbol: string; timeframe: number; tool: ChartTool; graphicToolType: GraphicToolType }>
    ) => {
      const { symbol, timeframe, tool, graphicToolType } = action.payload

      if (isNotDefined(state.chartTools[symbol + timeframe])) {
        const chartTimeFrame: string = symbol + timeframe

        state.chartTools = {
          ...state.chartTools,
          [chartTimeFrame]: {
            ...DEFAULT_CHART_TOOLS,
            [graphicToolType]: [tool]
          }
        }
      }

      const currentGraphicTool = state.chartTools[symbol + timeframe][graphicToolType]
      if (currentGraphicTool)
        state.chartTools = {
          ...state.chartTools,
          [symbol + timeframe]: {
            ...state.chartTools[symbol + timeframe],
            [graphicToolType]: [...currentGraphicTool, tool]
          }
        }
    },

    selectObject: (
      state: IGraphicsState,
      action: PayloadAction<{ symbolTimeframe: string; toolName: GraphicToolType; index: number }>
    ) => {
      const { index, symbolTimeframe, toolName } = action.payload
      const tool = state.chartTools[symbolTimeframe][toolName]
      if (tool)
        state.chartTools = {
          ...state.chartTools,
          [symbolTimeframe]: {
            ...state.chartTools[symbolTimeframe],
            [toolName]: tool.map((tool, idx) => {
              if (idx !== index) return tool

              return {
                ...tool,
                selected: true
              }
            })
          }
        }
    },

    deselectObject: (
      state: IGraphicsState,
      action: PayloadAction<{ symbolTimeframe: string; toolName: GraphicToolType; index: number }>
    ) => {
      const { index, symbolTimeframe, toolName } = action.payload
      const tool = state.chartTools[symbolTimeframe][toolName]
      if (tool)
        state.chartTools = {
          ...state.chartTools,
          [symbolTimeframe]: {
            ...state.chartTools[symbolTimeframe],
            [toolName]: tool.map((tool, idx) => {
              if (idx !== index) return tool

              return {
                ...tool,
                selected: false
              }
            })
          }
        }
    },

    addIndicator: (state: IGraphicsState, action: PayloadAction<{ chartId: string; indicator: IIndicator }>) => {
      const { chartId, indicator } = action.payload
      state.indicators = {
        ...state.indicators,
        [chartId]: state.indicators[chartId] ? state.indicators[chartId].concat(indicator) : [indicator]
      }
    },

    addOscillator: (state: IGraphicsState, action: PayloadAction<{ chartId: string; oscillator: IOscillator }>) => {
      const { chartId, oscillator } = action.payload
      state.oscillators = {
        ...state.oscillators,
        [chartId]: state.oscillators[chartId] ? state.oscillators[chartId].concat(oscillator) : [oscillator]
      }
    },

    editIndicator: (
      state: IGraphicsState,
      action: PayloadAction<{ chartId: string; updatedIndicator: IIndicator }>
    ) => {
      const { chartId, updatedIndicator } = action.payload

      state.indicators = {
        ...state.indicators,
        [chartId]: state.indicators[chartId].map((indicator) =>
          indicator.id === updatedIndicator.id ? { ...updatedIndicator } : { ...indicator }
        )
      }
    },

    editOscillator: (
      state: IGraphicsState,
      action: PayloadAction<{ chartId: string; updatedOscillator: IOscillator }>
    ) => {
      const { chartId, updatedOscillator } = action.payload

      state.oscillators = {
        ...state.oscillators,
        [chartId]: state.oscillators[chartId].map((oscillator) =>
          oscillator.id === updatedOscillator.id ? { ...updatedOscillator } : { ...oscillator }
        )
      }
    },

    deleteIndicator: (state: IGraphicsState, action: PayloadAction<{ chartId: string; indicatorId: string }>) => {
      const { payload } = action

      state.indicators = {
        ...state.indicators,
        [payload.chartId]: state.indicators[payload.chartId].filter((indicator) => indicator.id !== payload.indicatorId)
      }
    },

    deleteOscillator: (state: IGraphicsState, action: PayloadAction<{ chartId: string; oscillatorId: string }>) => {
      const { payload } = action
      state.oscillators = {
        ...state.oscillators,
        [payload.chartId]: state.oscillators[payload.chartId].filter(
          (oscillator) => oscillator.id !== payload.oscillatorId
        )
      }
    },

    enableVolumeOscillator: (state: IGraphicsState) => {
      state.volumeOscillatorActive = true
    },

    disableVolumeOscillator: (state: IGraphicsState) => {
      state.volumeOscillatorActive = false
    },

    setActiveIndicatorOscillator: (state: IGraphicsState, action: PayloadAction<{ id: string; chartId: string }>) => {
      const { chartId, id } = action.payload
      state.selectedIndicatorOscillator = {
        ...state.selectedIndicatorOscillator,
        [chartId]: id
      }
    },

    setIndicatorOscillator: (state: IGraphicsState, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload
      state.selectedIndicatorOscillatorFullName = name
    },

    addTempExecPriceLine: (state: IGraphicsState, action: PayloadAction<IHorizontalLine>) => {
      state.stopOrdersLines = {
        ...state.stopOrdersLines,
        execPrice: action.payload
      }
    },

    addTempStopLossLine: (state: IGraphicsState, action: PayloadAction<IHorizontalLine>) => {
      state.stopOrdersLines = {
        ...state.stopOrdersLines,
        stopLoss: action.payload
      }
    },

    addTempTakeProfitLine: (state: IGraphicsState, action: PayloadAction<IHorizontalLine>) => {
      state.stopOrdersLines = {
        ...state.stopOrdersLines,
        takeProfit: action.payload
      }
    },

    removeTempSlTpLines: (state: IGraphicsState) => {
      state.stopOrdersLines = {
        execPrice: dummyHorizontalLine,
        stopLoss: dummyHorizontalLine,
        takeProfit: dummyHorizontalLine
      }
    },

    cleanGraphicsTools: (state: IGraphicsState) => {
      Object.keys(state.chartTools).map(function (key, index) {
        state.chartTools[key] = { ...DEFAULT_CHART_TOOLS }
      })
    },
    deleteSelectedChartTools: (state: IGraphicsState, action: PayloadAction<{ symbolTimeFrame: string }>) => {
      const { symbolTimeFrame } = action.payload

      const entries: IChartTools = { ...state.chartTools[symbolTimeFrame] }

      Object.entries(entries).map(([key, value]) => {
        entries[key] = [...value.filter((each) => !each.selected)]
      })

      state.chartTools = {
        ...state.chartTools,
        [symbolTimeFrame]: {
          ...entries
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(assignAllGraphics, (state, action) => action.payload)
  }
})

export { selectAllObjects, deselectAllObjects, deleteAllObjects, deleteSelectedObject, assignAllGraphics }
export const {
  addIndicator,
  addOscillator,
  addTempExecPriceLine,
  addTempStopLossLine,
  addTempTakeProfitLine,
  cleanGraphicsTools,
  createChartTools,
  deleteIndicator,
  deleteOscillator,
  deleteSelectedChartTools,
  deselectObject,
  disableTool,
  disableVolumeOscillator,
  editIndicator,
  editOscillator,
  enableVolumeOscillator,
  pushTool,
  removeTempSlTpLines,
  selectObject,
  setActiveIndicatorOscillator,
  setIndicatorOscillator,
  toggleTool,
  updateChartTools,
  removeFavorite
} = graphicsSlice.actions

export const initialGraphicsState = initialState

export default graphicsSlice.reducer
