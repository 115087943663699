// import './wdyr'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

Bugsnag.start({
  apiKey: 'ada7f54d9cd966ec7f4595b53bf7ac54',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
// const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult
// const ErrorBoundary = plugin.createErrorBoundary()

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()

// expose store when run in Cypress
// if (window.hasOwnProperty('Cypress')) {
// 	window['store'] = store
// }
