import axios from 'axios'
import msgpack from 'msgpack-lite'
import { indicators } from '../../../components/topMenu/tabs/indicatorsTab/utils/indicatorsConstants'
import { ApplyToPrice, MAType } from '../../../components/topMenu/tabs/indicatorsTab/utils/recurringFields'
import { IIndicatorValue } from '../../interfaces/IIndicator.interface'
import { getBaseServerUrl } from '../utils'

const apiClient = axios.create({
  baseURL: getBaseServerUrl() + 'indicators/api/IndicatorData',
  responseType: 'arraybuffer',
  headers: { 'Content-Type': 'application/octet-stream' }
  // responseType: 'json',
  // headers: { 'Content-Type': 'application/json' }
})

export const getIndicator = async (
  ShortIndicatorName: string,
  Ticker: string,
  Start: number,
  End: number,
  TimeFrame: number,
  UseMessagePack: boolean,
  indicatorsSettings
): Promise<IIndicatorValue[]> => {
  const indicatorsParams = mapIndicatorKeysValues(ShortIndicatorName, indicatorsSettings)

  const params = {
    Symbol: Ticker,
    Broker: 'Alpari',
    Start,
    End,
    TimeFrame,
    UseMessagePack,
    ...indicatorsParams
  }

  const msgPackResponse = await apiClient.get(
    // TODO: remove this crutch when backend removes /raw
    ShortIndicatorName === 'ma' || ShortIndicatorName === 'rsi'
      ? `/${ShortIndicatorName}/raw`
      : `/${ShortIndicatorName}`,
    {
      params
    }
  )

  const arrayBuffer: Uint8Array = new Uint8Array(msgPackResponse.data)
  const jsonResponse: IIndicatorValue[] = msgpack.decode(arrayBuffer)

  if (jsonResponse) return jsonResponse

  throw new Error("Unable to fetch indicator's data")
}

export const mapIndicatorKeysValues = (indicatorName: string, params: {}): { Period: number; Method: number } => {
  const keys = Object.keys(params)
  let modifiedKeys = [...keys]
  let values = Object.values(params).map((v) => (v === -1 ? 0 : v))

  // Delete unnecessary fields (overall && for this indicator)
  const selectedIndicator = indicators.find((indicator) => indicator.indicatorName === indicatorName) || { fields: [] }

  // Rename fields && convert string values to numbers (for backend)
  const applyToPriceIndex = keys.findIndex((key) => key === 'ApplyToPrice') // 5
  const hShiftIndex = keys.findIndex((key) => key === 'HShift') // 2
  const maTypeIndex = keys.findIndex((key) => key === 'MAType') // 4

  if (applyToPriceIndex > -1) {
    modifiedKeys[applyToPriceIndex] = 'PriceType'
    const priceTypeStringValue: any = values[applyToPriceIndex] // 'Low'
    const applyToPriceIndexValue = ApplyToPrice.indexOf(priceTypeStringValue) // 3
    values[applyToPriceIndex] = applyToPriceIndexValue
  }

  if (hShiftIndex > -1) {
    modifiedKeys[hShiftIndex] = 'MaShift'
  }

  if (maTypeIndex > -1) {
    modifiedKeys[maTypeIndex] = 'Method'
    const movingTypeStringValue: any = values[maTypeIndex]
    const maTypeIndexValue = MAType.indexOf(movingTypeStringValue) // 3
    values[maTypeIndex] = maTypeIndexValue
  }

  let colorFieldIndex: number = 0
  let placementFieldIndex: number = 0

  const filterInvalidKeys = (item, index) => {
    // console.log(duplicateFields) // VShift, HShift
    if (item === 'Color') {
      colorFieldIndex = index
    } else if (item === 'placement') {
      placementFieldIndex = index
    }

    return (
      item !== 'Color' && item !== 'placement'
      //&& item !== duplicateFields[0]
    )
  }

  const validKeys = keys.filter(filterInvalidKeys)

  const result = { Period: 0, Method: 0 }
  modifiedKeys.forEach((key, i) => (result[key] = values[i]))

  return result
}
