export const defaultMA = [
  {
    t: 1580683200,
    v: 1.10877
  },
  {
    t: 1580683500,
    v: 1.1087150000000001
  },
  {
    t: 1580683800,
    v: 1.10866125
  },
  {
    t: 1580684100,
    v: 1.1085725
  },
  {
    t: 1580684400,
    v: 1.1086075
  },
  {
    t: 1580684700,
    v: 1.10861375
  },
  {
    t: 1580685000,
    v: 1.1086675
  },
  {
    t: 1580685300,
    v: 1.10875
  },
  {
    t: 1580685600,
    v: 1.10878625
  },
  {
    t: 1580685900,
    v: 1.1088075
  },
  {
    t: 1580686200,
    v: 1.1088425000000002
  },
  {
    t: 1580686500,
    v: 1.10891125
  },
  {
    t: 1580686800,
    v: 1.1088900000000002
  },
  {
    t: 1580687100,
    v: 1.1088775000000002
  },
  {
    t: 1580687400,
    v: 1.108875
  },
  {
    t: 1580687700,
    v: 1.1088725
  },
  {
    t: 1580688000,
    v: 1.1088550000000001
  },
  {
    t: 1580688300,
    v: 1.108835
  },
  {
    t: 1580688600,
    v: 1.1087825
  },
  {
    t: 1580688900,
    v: 1.1087449999999999
  },
  {
    t: 1580689200,
    v: 1.1087212499999999
  },
  {
    t: 1580689500,
    v: 1.1086825
  },
  {
    t: 1580689800,
    v: 1.108605
  },
  {
    t: 1580690100,
    v: 1.1085150000000001
  },
  {
    t: 1580690400,
    v: 1.10843875
  },
  {
    t: 1580690700,
    v: 1.1083574999999999
  },
  {
    t: 1580691000,
    v: 1.10828625
  },
  {
    t: 1580691300,
    v: 1.108225
  },
  {
    t: 1580691600,
    v: 1.10815375
  },
  {
    t: 1580691900,
    v: 1.108085
  },
  {
    t: 1580692200,
    v: 1.1080637500000001
  },
  {
    t: 1580692500,
    v: 1.1080475
  },
  {
    t: 1580692800,
    v: 1.10809625
  },
  {
    t: 1580693100,
    v: 1.1081725
  },
  {
    t: 1580693400,
    v: 1.108225
  },
  {
    t: 1580693700,
    v: 1.1082487500000002
  },
  {
    t: 1580694000,
    v: 1.10827125
  },
  {
    t: 1580694300,
    v: 1.10829375
  },
  {
    t: 1580694600,
    v: 1.10830625
  },
  {
    t: 1580694900,
    v: 1.10833875
  },
  {
    t: 1580695200,
    v: 1.1083325
  },
  {
    t: 1580695500,
    v: 1.10827375
  },
  {
    t: 1580695800,
    v: 1.10826
  },
  {
    t: 1580696100,
    v: 1.1082425
  },
  {
    t: 1580696400,
    v: 1.10824
  },
  {
    t: 1580696700,
    v: 1.10824
  },
  {
    t: 1580697000,
    v: 1.10823625
  },
  {
    t: 1580697300,
    v: 1.1082337500000001
  },
  {
    t: 1580697600,
    v: 1.1082
  },
  {
    t: 1580697900,
    v: 1.10817875
  },
  {
    t: 1580698200,
    v: 1.10816
  },
  {
    t: 1580698500,
    v: 1.108155
  },
  {
    t: 1580698800,
    v: 1.10815625
  },
  {
    t: 1580699100,
    v: 1.1081612500000002
  },
  {
    t: 1580699400,
    v: 1.10817125
  },
  {
    t: 1580699700,
    v: 1.1081750000000001
  },
  {
    t: 1580700000,
    v: 1.1081675
  },
  {
    t: 1580700300,
    v: 1.10816625
  },
  {
    t: 1580700600,
    v: 1.1081575
  },
  {
    t: 1580700900,
    v: 1.1081525
  },
  {
    t: 1580701200,
    v: 1.10814
  },
  {
    t: 1580701500,
    v: 1.1081349999999999
  },
  {
    t: 1580701800,
    v: 1.10812
  },
  {
    t: 1580702100,
    v: 1.1080925000000001
  },
  {
    t: 1580702400,
    v: 1.1080975
  },
  {
    t: 1580702700,
    v: 1.10811875
  },
  {
    t: 1580703000,
    v: 1.1081325
  },
  {
    t: 1580703300,
    v: 1.1081525
  },
  {
    t: 1580703600,
    v: 1.1081712500000003
  },
  {
    t: 1580703900,
    v: 1.10818375
  },
  {
    t: 1580704200,
    v: 1.1082025
  },
  {
    t: 1580704500,
    v: 1.1082474999999998
  },
  {
    t: 1580704800,
    v: 1.108275
  },
  {
    t: 1580705100,
    v: 1.1082824999999998
  },
  {
    t: 1580705400,
    v: 1.10829125
  },
  {
    t: 1580705700,
    v: 1.10827875
  },
  {
    t: 1580706000,
    v: 1.10825875
  },
  {
    t: 1580706300,
    v: 1.1082462499999999
  },
  {
    t: 1580706600,
    v: 1.1082400000000001
  },
  {
    t: 1580706900,
    v: 1.108225
  },
  {
    t: 1580707200,
    v: 1.10822375
  },
  {
    t: 1580707500,
    v: 1.10822625
  },
  {
    t: 1580707800,
    v: 1.1082412499999998
  },
  {
    t: 1580708100,
    v: 1.10827
  },
  {
    t: 1580708400,
    v: 1.1083
  },
  {
    t: 1580708700,
    v: 1.10832375
  },
  {
    t: 1580709000,
    v: 1.108345
  },
  {
    t: 1580709300,
    v: 1.10835625
  },
  {
    t: 1580709600,
    v: 1.10837625
  },
  {
    t: 1580709900,
    v: 1.10840875
  },
  {
    t: 1580710200,
    v: 1.1084275000000001
  },
  {
    t: 1580710500,
    v: 1.10842625
  },
  {
    t: 1580710800,
    v: 1.1084175
  },
  {
    t: 1580711100,
    v: 1.10839625
  },
  {
    t: 1580711400,
    v: 1.1083625000000001
  },
  {
    t: 1580711700,
    v: 1.10832875
  },
  {
    t: 1580712000,
    v: 1.1083037500000001
  },
  {
    t: 1580712300,
    v: 1.1082725
  },
  {
    t: 1580712600,
    v: 1.1082475
  },
  {
    t: 1580712900,
    v: 1.1082237499999998
  },
  {
    t: 1580713200,
    v: 1.10821875
  },
  {
    t: 1580713500,
    v: 1.1082225
  },
  {
    t: 1580713800,
    v: 1.1081699999999999
  },
  {
    t: 1580714100,
    v: 1.10811
  },
  {
    t: 1580714400,
    v: 1.1080275000000002
  },
  {
    t: 1580714700,
    v: 1.1079237499999999
  },
  {
    t: 1580715000,
    v: 1.1077899999999998
  },
  {
    t: 1580715300,
    v: 1.10766875
  },
  {
    t: 1580715600,
    v: 1.1075487499999999
  },
  {
    t: 1580715900,
    v: 1.10745125
  },
  {
    t: 1580716200,
    v: 1.10741
  },
  {
    t: 1580716500,
    v: 1.10738375
  },
  {
    t: 1580716800,
    v: 1.107375
  },
  {
    t: 1580717100,
    v: 1.107385
  },
  {
    t: 1580717400,
    v: 1.10745
  },
  {
    t: 1580717700,
    v: 1.1075175
  },
  {
    t: 1580718000,
    v: 1.1075612499999998
  },
  {
    t: 1580718300,
    v: 1.1075524999999997
  },
  {
    t: 1580718600,
    v: 1.107545
  },
  {
    t: 1580718900,
    v: 1.1074950000000001
  },
  {
    t: 1580719200,
    v: 1.10742375
  },
  {
    t: 1580719500,
    v: 1.10733
  },
  {
    t: 1580719800,
    v: 1.1071775000000001
  },
  {
    t: 1580720100,
    v: 1.10702875
  },
  {
    t: 1580720400,
    v: 1.1068937500000002
  },
  {
    t: 1580720700,
    v: 1.10678
  },
  {
    t: 1580721000,
    v: 1.1066425000000002
  },
  {
    t: 1580721300,
    v: 1.1065175
  },
  {
    t: 1580721600,
    v: 1.106385
  },
  {
    t: 1580721900,
    v: 1.10631
  },
  {
    t: 1580722200,
    v: 1.1062875
  },
  {
    t: 1580722500,
    v: 1.10629625
  },
  {
    t: 1580722800,
    v: 1.1063349999999998
  },
  {
    t: 1580723100,
    v: 1.106345
  },
  {
    t: 1580723400,
    v: 1.1064362499999998
  },
  {
    t: 1580723700,
    v: 1.1065850000000002
  },
  {
    t: 1580724000,
    v: 1.1067174999999998
  },
  {
    t: 1580724300,
    v: 1.1067900000000002
  },
  {
    t: 1580724600,
    v: 1.10683375
  },
  {
    t: 1580724900,
    v: 1.1068325
  },
  {
    t: 1580725200,
    v: 1.1068149999999999
  },
  {
    t: 1580725500,
    v: 1.10683375
  },
  {
    t: 1580725800,
    v: 1.1067925
  },
  {
    t: 1580726100,
    v: 1.1067250000000002
  },
  {
    t: 1580726400,
    v: 1.1067075000000002
  },
  {
    t: 1580726700,
    v: 1.10669625
  },
  {
    t: 1580727000,
    v: 1.10667625
  },
  {
    t: 1580727300,
    v: 1.10665375
  },
  {
    t: 1580727600,
    v: 1.1066475
  },
  {
    t: 1580727900,
    v: 1.1065975000000001
  },
  {
    t: 1580728200,
    v: 1.1065512499999999
  },
  {
    t: 1580728500,
    v: 1.1064800000000001
  },
  {
    t: 1580728800,
    v: 1.10637875
  },
  {
    t: 1580729100,
    v: 1.1063175
  },
  {
    t: 1580729400,
    v: 1.1062575000000001
  },
  {
    t: 1580729700,
    v: 1.1061950000000003
  },
  {
    t: 1580730000,
    v: 1.10614125
  },
  {
    t: 1580730300,
    v: 1.106105
  },
  {
    t: 1580730600,
    v: 1.10608375
  },
  {
    t: 1580730900,
    v: 1.1060975
  },
  {
    t: 1580731200,
    v: 1.1061225000000001
  },
  {
    t: 1580731500,
    v: 1.1061500000000002
  },
  {
    t: 1580731800,
    v: 1.10621125
  },
  {
    t: 1580732100,
    v: 1.10627875
  },
  {
    t: 1580732400,
    v: 1.106295
  },
  {
    t: 1580732700,
    v: 1.10633
  },
  {
    t: 1580733000,
    v: 1.10636875
  },
  {
    t: 1580733300,
    v: 1.106365
  },
  {
    t: 1580733600,
    v: 1.10636625
  },
  {
    t: 1580733900,
    v: 1.10637625
  },
  {
    t: 1580734200,
    v: 1.1063637499999999
  },
  {
    t: 1580734500,
    v: 1.1063725
  },
  {
    t: 1580734800,
    v: 1.1063774999999998
  },
  {
    t: 1580735100,
    v: 1.1064137499999998
  },
  {
    t: 1580735400,
    v: 1.1064337499999999
  },
  {
    t: 1580735700,
    v: 1.1064512499999999
  },
  {
    t: 1580736000,
    v: 1.1064775
  },
  {
    t: 1580736300,
    v: 1.1064399999999999
  },
  {
    t: 1580736600,
    v: 1.1064150000000001
  },
  {
    t: 1580736900,
    v: 1.1063762499999998
  },
  {
    t: 1580737200,
    v: 1.10636
  },
  {
    t: 1580737500,
    v: 1.10634125
  },
  {
    t: 1580737800,
    v: 1.10632
  },
  {
    t: 1580738100,
    v: 1.10631125
  },
  {
    t: 1580738400,
    v: 1.1062724999999998
  },
  {
    t: 1580738700,
    v: 1.1062349999999999
  },
  {
    t: 1580739000,
    v: 1.10616
  },
  {
    t: 1580739300,
    v: 1.10609
  },
  {
    t: 1580739600,
    v: 1.10597625
  },
  {
    t: 1580739900,
    v: 1.1058449999999997
  },
  {
    t: 1580740200,
    v: 1.10567625
  },
  {
    t: 1580740500,
    v: 1.1055475000000001
  },
  {
    t: 1580740800,
    v: 1.1054062500000001
  },
  {
    t: 1580741100,
    v: 1.1052625
  },
  {
    t: 1580741400,
    v: 1.10512125
  },
  {
    t: 1580741700,
    v: 1.105
  },
  {
    t: 1580742000,
    v: 1.10487625
  },
  {
    t: 1580742300,
    v: 1.1048025
  },
  {
    t: 1580742600,
    v: 1.1047212499999999
  },
  {
    t: 1580742900,
    v: 1.10465
  },
  {
    t: 1580743200,
    v: 1.1045987499999999
  },
  {
    t: 1580743500,
    v: 1.10462625
  },
  {
    t: 1580743800,
    v: 1.10466875
  },
  {
    t: 1580744100,
    v: 1.1046550000000002
  },
  {
    t: 1580744400,
    v: 1.10467625
  },
  {
    t: 1580744700,
    v: 1.104695
  },
  {
    t: 1580745000,
    v: 1.10477625
  },
  {
    t: 1580745300,
    v: 1.10488375
  },
  {
    t: 1580745600,
    v: 1.10501875
  },
  {
    t: 1580745900,
    v: 1.10509125
  },
  {
    t: 1580746200,
    v: 1.10517125
  },
  {
    t: 1580746500,
    v: 1.10534
  },
  {
    t: 1580746800,
    v: 1.10547875
  },
  {
    t: 1580747100,
    v: 1.1055825
  },
  {
    t: 1580747400,
    v: 1.1057075
  },
  {
    t: 1580747700,
    v: 1.10575375
  },
  {
    t: 1580748000,
    v: 1.10579875
  },
  {
    t: 1580748300,
    v: 1.1058824999999999
  },
  {
    t: 1580748600,
    v: 1.10598875
  },
  {
    t: 1580748900,
    v: 1.1060162500000001
  },
  {
    t: 1580749200,
    v: 1.10606875
  },
  {
    t: 1580749500,
    v: 1.10612625
  },
  {
    t: 1580749800,
    v: 1.1061937499999999
  },
  {
    t: 1580750100,
    v: 1.1062475
  },
  {
    t: 1580750400,
    v: 1.10627125
  },
  {
    t: 1580750700,
    v: 1.1062675
  },
  {
    t: 1580751000,
    v: 1.10623
  },
  {
    t: 1580751300,
    v: 1.1062212500000002
  },
  {
    t: 1580751600,
    v: 1.1062
  },
  {
    t: 1580751900,
    v: 1.10619
  },
  {
    t: 1580752200,
    v: 1.1061212500000002
  },
  {
    t: 1580752500,
    v: 1.10605875
  },
  {
    t: 1580752800,
    v: 1.1060525
  },
  {
    t: 1580753100,
    v: 1.10604875
  },
  {
    t: 1580753400,
    v: 1.1060675
  },
  {
    t: 1580753700,
    v: 1.106085
  },
  {
    t: 1580754000,
    v: 1.10611875
  },
  {
    t: 1580754300,
    v: 1.10612125
  },
  {
    t: 1580754600,
    v: 1.1061275000000002
  },
  {
    t: 1580754900,
    v: 1.1061400000000001
  },
  {
    t: 1580755200,
    v: 1.1061212500000002
  },
  {
    t: 1580755500,
    v: 1.10608875
  },
  {
    t: 1580755800,
    v: 1.1060637500000001
  },
  {
    t: 1580756100,
    v: 1.106045
  },
  {
    t: 1580756400,
    v: 1.10603625
  },
  {
    t: 1580756700,
    v: 1.1060375
  },
  {
    t: 1580757000,
    v: 1.10605125
  },
  {
    t: 1580757300,
    v: 1.1060912500000002
  },
  {
    t: 1580757600,
    v: 1.10610375
  },
  {
    t: 1580757900,
    v: 1.106155
  },
  {
    t: 1580758200,
    v: 1.1061762499999999
  },
  {
    t: 1580758500,
    v: 1.10620875
  },
  {
    t: 1580758800,
    v: 1.10623625
  },
  {
    t: 1580759100,
    v: 1.10624875
  },
  {
    t: 1580759400,
    v: 1.1062475
  },
  {
    t: 1580759700,
    v: 1.1062562500000002
  },
  {
    t: 1580760000,
    v: 1.10626875
  },
  {
    t: 1580760300,
    v: 1.1062475
  },
  {
    t: 1580760600,
    v: 1.10623375
  },
  {
    t: 1580760900,
    v: 1.1062
  },
  {
    t: 1580761200,
    v: 1.10614625
  },
  {
    t: 1580761500,
    v: 1.1061025
  },
  {
    t: 1580761800,
    v: 1.10606375
  },
  {
    t: 1580762100,
    v: 1.1059975
  },
  {
    t: 1580762400,
    v: 1.10595125
  },
  {
    t: 1580762700,
    v: 1.1059062499999999
  },
  {
    t: 1580763000,
    v: 1.1058725
  },
  {
    t: 1580763300,
    v: 1.105835
  },
  {
    t: 1580763600,
    v: 1.1058262499999998
  },
  {
    t: 1580763900,
    v: 1.10582625
  },
  {
    t: 1580764200,
    v: 1.1058375
  },
  {
    t: 1580764500,
    v: 1.10585625
  },
  {
    t: 1580764800,
    v: 1.10586375
  },
  {
    t: 1580765100,
    v: 1.1058825
  },
  {
    t: 1580765400,
    v: 1.1059012499999998
  },
  {
    t: 1580765700,
    v: 1.1059275
  },
  {
    t: 1580766000,
    v: 1.1059587499999999
  },
  {
    t: 1580766300,
    v: 1.10596375
  },
  {
    t: 1580766600,
    v: 1.105975
  },
  {
    t: 1580766900,
    v: 1.10597
  },
  {
    t: 1580767200,
    v: 1.10597
  },
  {
    t: 1580767500,
    v: 1.1059475
  },
  {
    t: 1580767800,
    v: 1.1059425000000003
  },
  {
    t: 1580768100,
    v: 1.10592
  },
  {
    t: 1580768400,
    v: 1.105885
  },
  {
    t: 1580768700,
    v: 1.1058637500000001
  },
  {
    t: 1580769000,
    v: 1.1058475
  },
  {
    t: 1580769300,
    v: 1.1058475
  },
  {
    t: 1580769600,
    v: 1.105845
  },
  {
    t: 1580769900,
    v: 1.1058700000000001
  },
  {
    t: 1580770200,
    v: 1.10586875
  },
  {
    t: 1580770500,
    v: 1.10587625
  },
  {
    t: 1580770800,
    v: 1.10591875
  },
  {
    t: 1580771100,
    v: 1.10596
  },
  {
    t: 1580771400,
    v: 1.1059875000000001
  },
  {
    t: 1580771700,
    v: 1.10601875
  },
  {
    t: 1580772000,
    v: 1.10604875
  },
  {
    t: 1580772300,
    v: 1.1060750000000001
  },
  {
    t: 1580772600,
    v: 1.10609625
  },
  {
    t: 1580772900,
    v: 1.10613125
  },
  {
    t: 1580773200,
    v: 1.10614375
  },
  {
    t: 1580773500,
    v: 1.1061524999999999
  },
  {
    t: 1580773800,
    v: 1.10616
  },
  {
    t: 1580774100,
    v: 1.1061649999999998
  },
  {
    t: 1580774400,
    v: 1.10617125
  },
  {
    t: 1580774700,
    v: 1.10617125
  },
  {
    t: 1580775000,
    v: 1.10618
  },
  {
    t: 1580775300,
    v: 1.106175
  },
  {
    t: 1580775600,
    v: 1.10614375
  },
  {
    t: 1580775900,
    v: 1.1061175
  },
  {
    t: 1580776200,
    v: 1.1061025000000002
  },
  {
    t: 1580776500,
    v: 1.10609
  },
  {
    t: 1580776800,
    v: 1.10607
  },
  {
    t: 1580777100,
    v: 1.10605375
  },
  {
    t: 1580777400,
    v: 1.106025
  },
  {
    t: 1580777700,
    v: 1.10601625
  },
  {
    t: 1580778000,
    v: 1.10603375
  },
  {
    t: 1580778300,
    v: 1.1060387500000002
  },
  {
    t: 1580778600,
    v: 1.1060325
  },
  {
    t: 1580778900,
    v: 1.1060275
  },
  {
    t: 1580779200,
    v: 1.10601375
  },
  {
    t: 1580779500,
    v: 1.1060100000000002
  },
  {
    t: 1580779800,
    v: 1.1060162500000001
  },
  {
    t: 1580780100,
    v: 1.1060237499999999
  },
  {
    t: 1580780400,
    v: 1.10602125
  },
  {
    t: 1580780700,
    v: 1.10604
  },
  {
    t: 1580781000,
    v: 1.10606
  },
  {
    t: 1580781300,
    v: 1.1060525
  },
  {
    t: 1580781600,
    v: 1.1060275
  },
  {
    t: 1580781900,
    v: 1.10599125
  },
  {
    t: 1580782200,
    v: 1.10595375
  },
  {
    t: 1580782500,
    v: 1.10590375
  },
  {
    t: 1580782800,
    v: 1.105845
  },
  {
    t: 1580783100,
    v: 1.1057774999999999
  },
  {
    t: 1580783400,
    v: 1.10570375
  },
  {
    t: 1580783700,
    v: 1.10565125
  },
  {
    t: 1580784000,
    v: 1.1056300000000001
  },
  {
    t: 1580784300,
    v: 1.1056237500000001
  },
  {
    t: 1580784600,
    v: 1.10563125
  },
  {
    t: 1580784900,
    v: 1.10564375
  },
  {
    t: 1580785200,
    v: 1.10567125
  },
  {
    t: 1580785500,
    v: 1.10569125
  },
  {
    t: 1580785800,
    v: 1.105705
  },
  {
    t: 1580786100,
    v: 1.10572125
  },
  {
    t: 1580786400,
    v: 1.1057374999999998
  },
  {
    t: 1580786700,
    v: 1.1057275
  },
  {
    t: 1580787000,
    v: 1.1057400000000002
  },
  {
    t: 1580787300,
    v: 1.10572
  },
  {
    t: 1580787600,
    v: 1.1057
  },
  {
    t: 1580787900,
    v: 1.1056862499999998
  },
  {
    t: 1580788200,
    v: 1.1056975
  },
  {
    t: 1580788500,
    v: 1.105705
  },
  {
    t: 1580788800,
    v: 1.10571875
  },
  {
    t: 1580789100,
    v: 1.1057537499999999
  },
  {
    t: 1580789400,
    v: 1.10576125
  },
  {
    t: 1580789700,
    v: 1.1057875
  },
  {
    t: 1580790000,
    v: 1.10581125
  },
  {
    t: 1580790300,
    v: 1.10584125
  },
  {
    t: 1580790600,
    v: 1.1058612499999998
  },
  {
    t: 1580790900,
    v: 1.1058925000000002
  },
  {
    t: 1580791200,
    v: 1.1059237499999999
  },
  {
    t: 1580791500,
    v: 1.10594875
  },
  {
    t: 1580791800,
    v: 1.10596
  },
  {
    t: 1580792100,
    v: 1.1059750000000002
  },
  {
    t: 1580792400,
    v: 1.1059825
  },
  {
    t: 1580792700,
    v: 1.10597875
  },
  {
    t: 1580793000,
    v: 1.10597625
  },
  {
    t: 1580793300,
    v: 1.10598
  },
  {
    t: 1580793600,
    v: 1.10597375
  },
  {
    t: 1580793900,
    v: 1.1059675
  },
  {
    t: 1580794200,
    v: 1.1059925000000002
  },
  {
    t: 1580794500,
    v: 1.10599
  },
  {
    t: 1580794800,
    v: 1.10598875
  },
  {
    t: 1580795100,
    v: 1.1059750000000002
  },
  {
    t: 1580795400,
    v: 1.1059625
  },
  {
    t: 1580795700,
    v: 1.1059337500000002
  },
  {
    t: 1580796000,
    v: 1.1059412499999999
  },
  {
    t: 1580796300,
    v: 1.10595
  },
  {
    t: 1580796600,
    v: 1.10592625
  },
  {
    t: 1580796900,
    v: 1.1059325
  },
  {
    t: 1580797200,
    v: 1.10596625
  },
  {
    t: 1580797500,
    v: 1.106
  },
  {
    t: 1580797800,
    v: 1.1060337500000001
  },
  {
    t: 1580798100,
    v: 1.1060962500000002
  },
  {
    t: 1580798400,
    v: 1.10610625
  },
  {
    t: 1580798700,
    v: 1.106085
  },
  {
    t: 1580799000,
    v: 1.10608
  },
  {
    t: 1580799300,
    v: 1.1060625000000002
  },
  {
    t: 1580799600,
    v: 1.1059875000000001
  },
  {
    t: 1580799900,
    v: 1.1059125
  },
  {
    t: 1580800200,
    v: 1.10581125
  },
  {
    t: 1580800500,
    v: 1.10569125
  },
  {
    t: 1580800800,
    v: 1.1055987500000002
  },
  {
    t: 1580801100,
    v: 1.10549875
  },
  {
    t: 1580801400,
    v: 1.1054
  },
  {
    t: 1580801700,
    v: 1.10529125
  },
  {
    t: 1580802000,
    v: 1.10521125
  },
  {
    t: 1580802300,
    v: 1.10515125
  },
  {
    t: 1580802600,
    v: 1.105135
  },
  {
    t: 1580802900,
    v: 1.1050875
  },
  {
    t: 1580803200,
    v: 1.10504625
  },
  {
    t: 1580803500,
    v: 1.10511125
  },
  {
    t: 1580803800,
    v: 1.10516125
  },
  {
    t: 1580804100,
    v: 1.1052575
  },
  {
    t: 1580804400,
    v: 1.1053187500000001
  },
  {
    t: 1580804700,
    v: 1.10537875
  },
  {
    t: 1580805000,
    v: 1.10544
  },
  {
    t: 1580805300,
    v: 1.1055249999999999
  },
  {
    t: 1580805600,
    v: 1.1055875
  },
  {
    t: 1580805900,
    v: 1.1055674999999998
  },
  {
    t: 1580806200,
    v: 1.10557875
  },
  {
    t: 1580806500,
    v: 1.10557875
  },
  {
    t: 1580806800,
    v: 1.10563875
  },
  {
    t: 1580807100,
    v: 1.1056987500000002
  },
  {
    t: 1580807400,
    v: 1.1057212500000002
  },
  {
    t: 1580807700,
    v: 1.1057350000000001
  },
  {
    t: 1580808000,
    v: 1.10572625
  },
  {
    t: 1580808300,
    v: 1.1057499999999998
  },
  {
    t: 1580808600,
    v: 1.1057375
  },
  {
    t: 1580808900,
    v: 1.105755
  },
  {
    t: 1580809200,
    v: 1.10572
  },
  {
    t: 1580809500,
    v: 1.1056850000000003
  },
  {
    t: 1580809800,
    v: 1.1056425
  },
  {
    t: 1580810100,
    v: 1.105615
  },
  {
    t: 1580810400,
    v: 1.10560375
  },
  {
    t: 1580810700,
    v: 1.105585
  },
  {
    t: 1580811000,
    v: 1.10557
  },
  {
    t: 1580811300,
    v: 1.1054724999999999
  },
  {
    t: 1580811600,
    v: 1.1053912499999998
  },
  {
    t: 1580811900,
    v: 1.10532875
  },
  {
    t: 1580812200,
    v: 1.1052749999999998
  },
  {
    t: 1580812500,
    v: 1.10522875
  },
  {
    t: 1580812800,
    v: 1.1052137499999999
  },
  {
    t: 1580813100,
    v: 1.1051925
  },
  {
    t: 1580813400,
    v: 1.10516625
  },
  {
    t: 1580813700,
    v: 1.10521625
  },
  {
    t: 1580814000,
    v: 1.10530125
  },
  {
    t: 1580814300,
    v: 1.1053425
  },
  {
    t: 1580814600,
    v: 1.1053875
  },
  {
    t: 1580814900,
    v: 1.10543
  },
  {
    t: 1580815200,
    v: 1.1054512500000002
  },
  {
    t: 1580815500,
    v: 1.10546125
  },
  {
    t: 1580815800,
    v: 1.1054775
  },
  {
    t: 1580816100,
    v: 1.1054687500000002
  },
  {
    t: 1580816400,
    v: 1.10543625
  },
  {
    t: 1580816700,
    v: 1.1054175000000002
  },
  {
    t: 1580817000,
    v: 1.10539875
  },
  {
    t: 1580817300,
    v: 1.10538
  },
  {
    t: 1580817600,
    v: 1.10533625
  },
  {
    t: 1580817900,
    v: 1.1052625
  },
  {
    t: 1580818200,
    v: 1.1051975
  },
  {
    t: 1580818500,
    v: 1.1051362500000002
  },
  {
    t: 1580818800,
    v: 1.1050925
  },
  {
    t: 1580819100,
    v: 1.10504875
  },
  {
    t: 1580819400,
    v: 1.10500875
  },
  {
    t: 1580819700,
    v: 1.1049275
  },
  {
    t: 1580820900,
    v: 1.10483625
  },
  {
    t: 1580821200,
    v: 1.1047925
  },
  {
    t: 1580821500,
    v: 1.1047650000000002
  },
  {
    t: 1580821800,
    v: 1.104735
  },
  {
    t: 1580822100,
    v: 1.1047212499999999
  },
  {
    t: 1580822400,
    v: 1.10469125
  },
  {
    t: 1580822700,
    v: 1.1046775
  },
  {
    t: 1580823000,
    v: 1.104705
  },
  {
    t: 1580823300,
    v: 1.1047475
  },
  {
    t: 1580823600,
    v: 1.10476875
  },
  {
    t: 1580823900,
    v: 1.10478625
  },
  {
    t: 1580824200,
    v: 1.10479875
  },
  {
    t: 1580824500,
    v: 1.1047275
  },
  {
    t: 1580824800,
    v: 1.104685
  },
  {
    t: 1580825100,
    v: 1.1046425
  },
  {
    t: 1580825400,
    v: 1.10459875
  },
  {
    t: 1580825700,
    v: 1.1045725
  },
  {
    t: 1580826000,
    v: 1.1045587499999998
  },
  {
    t: 1580826300,
    v: 1.104535
  },
  {
    t: 1580826600,
    v: 1.10448625
  },
  {
    t: 1580826900,
    v: 1.10448375
  },
  {
    t: 1580827200,
    v: 1.104475
  },
  {
    t: 1580827500,
    v: 1.1044387500000001
  },
  {
    t: 1580827800,
    v: 1.1044075
  },
  {
    t: 1580828100,
    v: 1.104395
  },
  {
    t: 1580828400,
    v: 1.104415
  },
  {
    t: 1580828700,
    v: 1.104395
  },
  {
    t: 1580829000,
    v: 1.1043887499999998
  },
  {
    t: 1580829300,
    v: 1.1044087500000002
  },
  {
    t: 1580829600,
    v: 1.10441875
  },
  {
    t: 1580829900,
    v: 1.1043525
  },
  {
    t: 1580830200,
    v: 1.10427875
  },
  {
    t: 1580830500,
    v: 1.10417375
  },
  {
    t: 1580830800,
    v: 1.10403625
  },
  {
    t: 1580831100,
    v: 1.10392125
  },
  {
    t: 1580831400,
    v: 1.10387875
  },
  {
    t: 1580831700,
    v: 1.103785
  },
  {
    t: 1580832000,
    v: 1.10370125
  },
  {
    t: 1580832300,
    v: 1.1036825
  },
  {
    t: 1580832600,
    v: 1.1036662499999998
  },
  {
    t: 1580832900,
    v: 1.1036625
  },
  {
    t: 1580833200,
    v: 1.10361875
  },
  {
    t: 1580833500,
    v: 1.10361875
  },
  {
    t: 1580833800,
    v: 1.10355625
  },
  {
    t: 1580834100,
    v: 1.10352625
  },
  {
    t: 1580834400,
    v: 1.1034899999999999
  },
  {
    t: 1580834700,
    v: 1.10354375
  },
  {
    t: 1580835000,
    v: 1.10360125
  },
  {
    t: 1580835300,
    v: 1.10369125
  },
  {
    t: 1580835600,
    v: 1.10383625
  },
  {
    t: 1580835900,
    v: 1.103935
  },
  {
    t: 1580836200,
    v: 1.1040100000000002
  },
  {
    t: 1580836500,
    v: 1.10407625
  },
  {
    t: 1580836800,
    v: 1.10413
  },
  {
    t: 1580837100,
    v: 1.1041675
  },
  {
    t: 1580837400,
    v: 1.104185
  },
  {
    t: 1580837700,
    v: 1.10415375
  },
  {
    t: 1580838000,
    v: 1.1040975
  },
  {
    t: 1580838300,
    v: 1.10404375
  },
  {
    t: 1580838600,
    v: 1.1040275
  },
  {
    t: 1580838900,
    v: 1.103975
  },
  {
    t: 1580839200,
    v: 1.1039837500000003
  },
  {
    t: 1580839500,
    v: 1.1039625000000002
  },
  {
    t: 1580839800,
    v: 1.1039712499999998
  },
  {
    t: 1580840100,
    v: 1.10401
  },
  {
    t: 1580840400,
    v: 1.1040425
  },
  {
    t: 1580840700,
    v: 1.1040800000000002
  },
  {
    t: 1580841000,
    v: 1.1040925000000001
  },
  {
    t: 1580841300,
    v: 1.1041349999999999
  },
  {
    t: 1580841600,
    v: 1.1041462499999999
  },
  {
    t: 1580841900,
    v: 1.1041325
  },
  {
    t: 1580842200,
    v: 1.10411875
  },
  {
    t: 1580842500,
    v: 1.10408625
  },
  {
    t: 1580842800,
    v: 1.10406625
  },
  {
    t: 1580843100,
    v: 1.10406
  },
  {
    t: 1580843400,
    v: 1.10405
  },
  {
    t: 1580843700,
    v: 1.10405
  },
  {
    t: 1580844000,
    v: 1.1040637500000001
  },
  {
    t: 1580844300,
    v: 1.1040875
  },
  {
    t: 1580844600,
    v: 1.1041100000000001
  },
  {
    t: 1580844900,
    v: 1.10414875
  },
  {
    t: 1580845200,
    v: 1.10417625
  },
  {
    t: 1580845500,
    v: 1.1042075
  },
  {
    t: 1580845800,
    v: 1.10425
  },
  {
    t: 1580846100,
    v: 1.1042687500000001
  },
  {
    t: 1580846400,
    v: 1.10428
  },
  {
    t: 1580846700,
    v: 1.104285
  },
  {
    t: 1580847000,
    v: 1.104275
  },
  {
    t: 1580847300,
    v: 1.104245
  },
  {
    t: 1580847600,
    v: 1.10422625
  },
  {
    t: 1580847900,
    v: 1.1042174999999999
  },
  {
    t: 1580848200,
    v: 1.10421375
  },
  {
    t: 1580848500,
    v: 1.1042287499999999
  },
  {
    t: 1580848800,
    v: 1.1042450000000001
  },
  {
    t: 1580849100,
    v: 1.1042525
  },
  {
    t: 1580849400,
    v: 1.10424875
  },
  {
    t: 1580849700,
    v: 1.10428125
  },
  {
    t: 1580850000,
    v: 1.1043275000000001
  },
  {
    t: 1580850300,
    v: 1.1043512500000001
  },
  {
    t: 1580850600,
    v: 1.10435875
  },
  {
    t: 1580850900,
    v: 1.1043712499999998
  },
  {
    t: 1580851200,
    v: 1.104375
  },
  {
    t: 1580851500,
    v: 1.10440625
  },
  {
    t: 1580851800,
    v: 1.1044412499999998
  },
  {
    t: 1580852100,
    v: 1.10445625
  },
  {
    t: 1580852400,
    v: 1.1044399999999999
  },
  {
    t: 1580852700,
    v: 1.1044375
  },
  {
    t: 1580853000,
    v: 1.10444375
  },
  {
    t: 1580853300,
    v: 1.1044375
  },
  {
    t: 1580853600,
    v: 1.10443625
  },
  {
    t: 1580853900,
    v: 1.10441125
  },
  {
    t: 1580854200,
    v: 1.104385
  },
  {
    t: 1580854500,
    v: 1.10433875
  },
  {
    t: 1580854800,
    v: 1.1042999999999998
  },
  {
    t: 1580855100,
    v: 1.10425125
  },
  {
    t: 1580855400,
    v: 1.10422375
  },
  {
    t: 1580855700,
    v: 1.10422
  },
  {
    t: 1580856000,
    v: 1.1042025
  },
  {
    t: 1580856300,
    v: 1.1042049999999999
  },
  {
    t: 1580856600,
    v: 1.10420625
  },
  {
    t: 1580856900,
    v: 1.10423875
  },
  {
    t: 1580857200,
    v: 1.1042975
  },
  {
    t: 1580857500,
    v: 1.104355
  },
  {
    t: 1580857800,
    v: 1.1043924999999999
  },
  {
    t: 1580858100,
    v: 1.1044212500000001
  },
  {
    t: 1580858400,
    v: 1.10444875
  },
  {
    t: 1580858700,
    v: 1.1044637499999999
  },
  {
    t: 1580859000,
    v: 1.1044774999999998
  },
  {
    t: 1580859300,
    v: 1.1044800000000001
  },
  {
    t: 1580859600,
    v: 1.1044762499999998
  },
  {
    t: 1580859900,
    v: 1.1044637499999999
  },
  {
    t: 1580860200,
    v: 1.1044512499999999
  },
  {
    t: 1580860500,
    v: 1.10444
  },
  {
    t: 1580860800,
    v: 1.1044075
  },
  {
    t: 1580861100,
    v: 1.10438375
  },
  {
    t: 1580861400,
    v: 1.1043724999999998
  },
  {
    t: 1580861700,
    v: 1.10436125
  },
  {
    t: 1580862000,
    v: 1.1043500000000002
  },
  {
    t: 1580862300,
    v: 1.10433625
  },
  {
    t: 1580862600,
    v: 1.10433125
  },
  {
    t: 1580862900,
    v: 1.10433
  },
  {
    t: 1580863200,
    v: 1.1043512500000001
  },
  {
    t: 1580863500,
    v: 1.1043637499999999
  },
  {
    t: 1580863800,
    v: 1.104355
  },
  {
    t: 1580864100,
    v: 1.10435
  },
  {
    t: 1580864400,
    v: 1.10434
  },
  {
    t: 1580864700,
    v: 1.1043225
  },
  {
    t: 1580865000,
    v: 1.10428875
  },
  {
    t: 1580865300,
    v: 1.1042575
  },
  {
    t: 1580865600,
    v: 1.1042212500000002
  },
  {
    t: 1580865900,
    v: 1.10419875
  },
  {
    t: 1580866200,
    v: 1.10419125
  },
  {
    t: 1580866500,
    v: 1.1041825
  },
  {
    t: 1580866800,
    v: 1.1041450000000002
  },
  {
    t: 1580867100,
    v: 1.10411375
  },
  {
    t: 1580867400,
    v: 1.1041025
  },
  {
    t: 1580867700,
    v: 1.10407875
  },
  {
    t: 1580868000,
    v: 1.1040699999999999
  },
  {
    t: 1580868300,
    v: 1.10402375
  },
  {
    t: 1580868600,
    v: 1.10397
  },
  {
    t: 1580868900,
    v: 1.10392625
  },
  {
    t: 1580869200,
    v: 1.1038837499999998
  },
  {
    t: 1580869500,
    v: 1.10383875
  },
  {
    t: 1580869800,
    v: 1.1037625
  },
  {
    t: 1580870100,
    v: 1.10368625
  },
  {
    t: 1580870400,
    v: 1.10360875
  },
  {
    t: 1580870700,
    v: 1.10356
  },
  {
    t: 1580871000,
    v: 1.1035175
  },
  {
    t: 1580871300,
    v: 1.1034587500000002
  },
  {
    t: 1580871600,
    v: 1.1034324999999998
  },
  {
    t: 1580871900,
    v: 1.1034275
  },
  {
    t: 1580872200,
    v: 1.10344875
  },
  {
    t: 1580872500,
    v: 1.103465
  },
  {
    t: 1580872800,
    v: 1.10347
  },
  {
    t: 1580873100,
    v: 1.1034712500000001
  },
  {
    t: 1580873400,
    v: 1.10346375
  },
  {
    t: 1580873700,
    v: 1.1034599999999999
  },
  {
    t: 1580874000,
    v: 1.1034449999999998
  },
  {
    t: 1580874300,
    v: 1.10342625
  },
  {
    t: 1580874600,
    v: 1.1034000000000002
  },
  {
    t: 1580874900,
    v: 1.1033837499999999
  },
  {
    t: 1580875200,
    v: 1.1033737499999998
  },
  {
    t: 1580875500,
    v: 1.103365
  },
  {
    t: 1580875800,
    v: 1.10337125
  },
  {
    t: 1580876100,
    v: 1.1033775
  },
  {
    t: 1580876400,
    v: 1.103395
  },
  {
    t: 1580876700,
    v: 1.10340875
  },
  {
    t: 1580877000,
    v: 1.10342
  },
  {
    t: 1580877300,
    v: 1.10342
  },
  {
    t: 1580877600,
    v: 1.10342625
  },
  {
    t: 1580877900,
    v: 1.10342625
  },
  {
    t: 1580878200,
    v: 1.103415
  },
  {
    t: 1580878500,
    v: 1.10341375
  },
  {
    t: 1580878800,
    v: 1.1034
  },
  {
    t: 1580879100,
    v: 1.1033925
  },
  {
    t: 1580879400,
    v: 1.10339375
  },
  {
    t: 1580879700,
    v: 1.10340625
  },
  {
    t: 1580880000,
    v: 1.1034137499999999
  },
  {
    t: 1580880300,
    v: 1.103435
  },
  {
    t: 1580880600,
    v: 1.1034562499999998
  },
  {
    t: 1580880900,
    v: 1.1034837499999999
  },
  {
    t: 1580881200,
    v: 1.10351875
  },
  {
    t: 1580881500,
    v: 1.10355
  },
  {
    t: 1580881800,
    v: 1.103585
  },
  {
    t: 1580882100,
    v: 1.1036175
  },
  {
    t: 1580882400,
    v: 1.10364875
  },
  {
    t: 1580882700,
    v: 1.1036725
  },
  {
    t: 1580883000,
    v: 1.1036812500000002
  },
  {
    t: 1580883300,
    v: 1.10367625
  },
  {
    t: 1580883600,
    v: 1.10367125
  },
  {
    t: 1580883900,
    v: 1.1036587500000001
  },
  {
    t: 1580884200,
    v: 1.1036362499999999
  },
  {
    t: 1580884500,
    v: 1.1036087499999998
  },
  {
    t: 1580884800,
    v: 1.10357
  },
  {
    t: 1580885100,
    v: 1.1035324999999998
  },
  {
    t: 1580885400,
    v: 1.1035249999999999
  },
  {
    t: 1580885700,
    v: 1.1035187499999999
  },
  {
    t: 1580886000,
    v: 1.10352125
  },
  {
    t: 1580886300,
    v: 1.1035412500000001
  },
  {
    t: 1580886600,
    v: 1.1035625
  },
  {
    t: 1580886900,
    v: 1.1035975
  },
  {
    t: 1580887200,
    v: 1.103645
  },
  {
    t: 1580887500,
    v: 1.1036762500000001
  },
  {
    t: 1580887800,
    v: 1.1037062499999999
  },
  {
    t: 1580888100,
    v: 1.10373125
  },
  {
    t: 1580888400,
    v: 1.10375125
  },
  {
    t: 1580888700,
    v: 1.1037737499999998
  },
  {
    t: 1580889000,
    v: 1.1037562499999998
  },
  {
    t: 1580889300,
    v: 1.10373625
  },
  {
    t: 1580889600,
    v: 1.10376
  },
  {
    t: 1580889900,
    v: 1.10382
  },
  {
    t: 1580890200,
    v: 1.10389625
  },
  {
    t: 1580890500,
    v: 1.10397875
  },
  {
    t: 1580890800,
    v: 1.10407875
  },
  {
    t: 1580891100,
    v: 1.1041637499999999
  },
  {
    t: 1580891400,
    v: 1.1042750000000001
  },
  {
    t: 1580891700,
    v: 1.1043525
  },
  {
    t: 1580892000,
    v: 1.10443625
  },
  {
    t: 1580892300,
    v: 1.10440125
  },
  {
    t: 1580892600,
    v: 1.1043775
  },
  {
    t: 1580892900,
    v: 1.1043475
  },
  {
    t: 1580893200,
    v: 1.10425
  },
  {
    t: 1580893500,
    v: 1.10413875
  },
  {
    t: 1580893800,
    v: 1.10408625
  },
  {
    t: 1580894100,
    v: 1.104055
  },
  {
    t: 1580894400,
    v: 1.10394875
  },
  {
    t: 1580894700,
    v: 1.10391625
  },
  {
    t: 1580895000,
    v: 1.1038275
  },
  {
    t: 1580895300,
    v: 1.10367375
  },
  {
    t: 1580895600,
    v: 1.1035000000000001
  },
  {
    t: 1580895900,
    v: 1.1033175000000002
  },
  {
    t: 1580896200,
    v: 1.1031000000000002
  },
  {
    t: 1580896500,
    v: 1.1029175
  },
  {
    t: 1580896800,
    v: 1.1027525000000002
  },
  {
    t: 1580897100,
    v: 1.10263375
  },
  {
    t: 1580897400,
    v: 1.10252375
  },
  {
    t: 1580897700,
    v: 1.1025075
  },
  {
    t: 1580898000,
    v: 1.102545
  },
  {
    t: 1580898300,
    v: 1.1026112499999998
  },
  {
    t: 1580898600,
    v: 1.1026574999999998
  },
  {
    t: 1580898900,
    v: 1.10266625
  },
  {
    t: 1580899200,
    v: 1.10264375
  },
  {
    t: 1580899500,
    v: 1.10254875
  },
  {
    t: 1580899800,
    v: 1.1024500000000002
  },
  {
    t: 1580900100,
    v: 1.10233625
  },
  {
    t: 1580900400,
    v: 1.102225
  },
  {
    t: 1580900700,
    v: 1.10209375
  },
  {
    t: 1580901000,
    v: 1.10195875
  },
  {
    t: 1580901300,
    v: 1.10181
  },
  {
    t: 1580901600,
    v: 1.1017437500000002
  },
  {
    t: 1580901900,
    v: 1.10173375
  },
  {
    t: 1580902200,
    v: 1.10173875
  },
  {
    t: 1580902500,
    v: 1.1017787499999998
  },
  {
    t: 1580902800,
    v: 1.1018474999999999
  },
  {
    t: 1580903100,
    v: 1.10192
  },
  {
    t: 1580903400,
    v: 1.1019875
  },
  {
    t: 1580903700,
    v: 1.102075
  },
  {
    t: 1580904000,
    v: 1.10213125
  },
  {
    t: 1580904300,
    v: 1.10217875
  },
  {
    t: 1580904600,
    v: 1.1022275000000001
  },
  {
    t: 1580904900,
    v: 1.10219875
  },
  {
    t: 1580905200,
    v: 1.10215875
  },
  {
    t: 1580905500,
    v: 1.1021362499999998
  },
  {
    t: 1580905800,
    v: 1.10213
  },
  {
    t: 1580906100,
    v: 1.1021287499999999
  },
  {
    t: 1580906400,
    v: 1.10212625
  },
  {
    t: 1580906700,
    v: 1.1021062499999998
  },
  {
    t: 1580907000,
    v: 1.1021062499999998
  },
  {
    t: 1580907300,
    v: 1.10213375
  },
  {
    t: 1580907600,
    v: 1.1021575000000001
  },
  {
    t: 1580907900,
    v: 1.102165
  },
  {
    t: 1580908200,
    v: 1.10217125
  },
  {
    t: 1580908500,
    v: 1.1021124999999998
  },
  {
    t: 1580908800,
    v: 1.1020374999999998
  },
  {
    t: 1580909100,
    v: 1.101975
  },
  {
    t: 1580909400,
    v: 1.10186625
  },
  {
    t: 1580909700,
    v: 1.10173375
  },
  {
    t: 1580910000,
    v: 1.10155875
  },
  {
    t: 1580910300,
    v: 1.1014024999999998
  },
  {
    t: 1580910600,
    v: 1.10127875
  },
  {
    t: 1580910900,
    v: 1.10122125
  },
  {
    t: 1580911200,
    v: 1.1011837500000001
  },
  {
    t: 1580911500,
    v: 1.101125
  },
  {
    t: 1580911800,
    v: 1.1010637499999998
  },
  {
    t: 1580912100,
    v: 1.1010375
  },
  {
    t: 1580912400,
    v: 1.10099625
  },
  {
    t: 1580912700,
    v: 1.1009324999999999
  },
  {
    t: 1580913000,
    v: 1.1008375
  },
  {
    t: 1580913300,
    v: 1.1007275
  },
  {
    t: 1580913600,
    v: 1.100565
  },
  {
    t: 1580913900,
    v: 1.10044625
  },
  {
    t: 1580914200,
    v: 1.10036625
  },
  {
    t: 1580914500,
    v: 1.1002775
  },
  {
    t: 1580914800,
    v: 1.1002399999999999
  },
  {
    t: 1580915100,
    v: 1.100255
  },
  {
    t: 1580915400,
    v: 1.1002574999999999
  },
  {
    t: 1580915700,
    v: 1.10022375
  },
  {
    t: 1580916000,
    v: 1.10026
  },
  {
    t: 1580916300,
    v: 1.1003375
  },
  {
    t: 1580916600,
    v: 1.10037625
  },
  {
    t: 1580916900,
    v: 1.1004175
  },
  {
    t: 1580917200,
    v: 1.1004212500000001
  },
  {
    t: 1580917500,
    v: 1.10033125
  },
  {
    t: 1580917800,
    v: 1.1002975000000002
  },
  {
    t: 1580918100,
    v: 1.10031375
  },
  {
    t: 1580918400,
    v: 1.1002875
  },
  {
    t: 1580918700,
    v: 1.1001937499999999
  },
  {
    t: 1580919000,
    v: 1.10014
  },
  {
    t: 1580919300,
    v: 1.10009
  },
  {
    t: 1580919600,
    v: 1.1000925000000001
  },
  {
    t: 1580919900,
    v: 1.10014625
  },
  {
    t: 1580920200,
    v: 1.1001325
  },
  {
    t: 1580920500,
    v: 1.10016375
  },
  {
    t: 1580920800,
    v: 1.10018625
  },
  {
    t: 1580921100,
    v: 1.1002174999999998
  },
  {
    t: 1580921400,
    v: 1.1002512500000001
  },
  {
    t: 1580921700,
    v: 1.100305
  },
  {
    t: 1580922000,
    v: 1.10034125
  },
  {
    t: 1580922300,
    v: 1.1003825
  },
  {
    t: 1580922600,
    v: 1.1004512499999999
  },
  {
    t: 1580922900,
    v: 1.10046625
  },
  {
    t: 1580923200,
    v: 1.1004775
  },
  {
    t: 1580923500,
    v: 1.1004975
  },
  {
    t: 1580923800,
    v: 1.10050125
  },
  {
    t: 1580924100,
    v: 1.1004737500000001
  },
  {
    t: 1580924400,
    v: 1.10045375
  },
  {
    t: 1580924700,
    v: 1.10043625
  },
  {
    t: 1580925000,
    v: 1.1003987499999999
  },
  {
    t: 1580925300,
    v: 1.10034
  },
  {
    t: 1580925600,
    v: 1.10031375
  },
  {
    t: 1580925900,
    v: 1.10026375
  },
  {
    t: 1580926200,
    v: 1.1002
  },
  {
    t: 1580926500,
    v: 1.1001562500000002
  },
  {
    t: 1580926800,
    v: 1.1001262500000002
  },
  {
    t: 1580927100,
    v: 1.1000975
  },
  {
    t: 1580927400,
    v: 1.1000800000000002
  },
  {
    t: 1580927700,
    v: 1.1000925
  },
  {
    t: 1580928000,
    v: 1.1000949999999998
  },
  {
    t: 1580928300,
    v: 1.10010875
  },
  {
    t: 1580928600,
    v: 1.10015
  },
  {
    t: 1580928900,
    v: 1.1001725
  },
  {
    t: 1580929200,
    v: 1.10015875
  },
  {
    t: 1580929500,
    v: 1.100125
  },
  {
    t: 1580929800,
    v: 1.10008625
  },
  {
    t: 1580930100,
    v: 1.1000275000000002
  },
  {
    t: 1580930400,
    v: 1.09994625
  },
  {
    t: 1580930700,
    v: 1.0998625
  },
  {
    t: 1580931000,
    v: 1.09975625
  },
  {
    t: 1580931300,
    v: 1.0996675
  },
  {
    t: 1580931600,
    v: 1.0995750000000002
  },
  {
    t: 1580931900,
    v: 1.0995062500000001
  },
  {
    t: 1580932200,
    v: 1.0994587500000002
  },
  {
    t: 1580932500,
    v: 1.0994412500000001
  },
  {
    t: 1580932800,
    v: 1.09946625
  },
  {
    t: 1580933100,
    v: 1.0995025
  },
  {
    t: 1580933400,
    v: 1.0995499999999998
  },
  {
    t: 1580933700,
    v: 1.09959125
  },
  {
    t: 1580934000,
    v: 1.0996587500000001
  },
  {
    t: 1580934300,
    v: 1.09971875
  },
  {
    t: 1580934600,
    v: 1.09976875
  },
  {
    t: 1580934900,
    v: 1.09980875
  },
  {
    t: 1580935200,
    v: 1.0998225000000001
  },
  {
    t: 1580935500,
    v: 1.09983
  },
  {
    t: 1580935800,
    v: 1.09982375
  },
  {
    t: 1580936100,
    v: 1.0998100000000002
  },
  {
    t: 1580936400,
    v: 1.0998149999999998
  },
  {
    t: 1580936700,
    v: 1.09981875
  },
  {
    t: 1580937000,
    v: 1.0998100000000002
  },
  {
    t: 1580937300,
    v: 1.0998075
  },
  {
    t: 1580937600,
    v: 1.09980375
  },
  {
    t: 1580937900,
    v: 1.0998025
  },
  {
    t: 1580938200,
    v: 1.09981125
  },
  {
    t: 1580938500,
    v: 1.0998075
  },
  {
    t: 1580938800,
    v: 1.09977875
  },
  {
    t: 1580939100,
    v: 1.0997575
  },
  {
    t: 1580939400,
    v: 1.09976625
  },
  {
    t: 1580939700,
    v: 1.0997675
  },
  {
    t: 1580940000,
    v: 1.09977625
  },
  {
    t: 1580940300,
    v: 1.0997750000000002
  },
  {
    t: 1580940600,
    v: 1.09978125
  },
  {
    t: 1580940900,
    v: 1.09980125
  },
  {
    t: 1580941200,
    v: 1.09982
  },
  {
    t: 1580941500,
    v: 1.09983125
  },
  {
    t: 1580941800,
    v: 1.0998325
  },
  {
    t: 1580942100,
    v: 1.09983875
  },
  {
    t: 1580942400,
    v: 1.09984375
  },
  {
    t: 1580942700,
    v: 1.09986125
  },
  {
    t: 1580943000,
    v: 1.099875
  },
  {
    t: 1580943300,
    v: 1.0998862499999997
  },
  {
    t: 1580943600,
    v: 1.0999025
  },
  {
    t: 1580943900,
    v: 1.0999162500000002
  },
  {
    t: 1580944200,
    v: 1.09991875
  },
  {
    t: 1580944500,
    v: 1.0999162500000002
  },
  {
    t: 1580944800,
    v: 1.09991
  },
  {
    t: 1580945100,
    v: 1.09990125
  },
  {
    t: 1580945400,
    v: 1.09990125
  },
  {
    t: 1580945700,
    v: 1.0999037500000002
  },
  {
    t: 1580946000,
    v: 1.0999025000000002
  },
  {
    t: 1580946300,
    v: 1.0999012500000003
  },
  {
    t: 1580946600,
    v: 1.0999075000000003
  },
  {
    t: 1580946900,
    v: 1.09992
  },
  {
    t: 1580947200,
    v: 1.0999225
  },
  {
    t: 1580947500,
    v: 1.09992375
  },
  {
    t: 1580947800,
    v: 1.09989875
  },
  {
    t: 1580948100,
    v: 1.0998887499999999
  },
  {
    t: 1580948400,
    v: 1.099885
  },
  {
    t: 1580948700,
    v: 1.09988875
  },
  {
    t: 1580949000,
    v: 1.0998875
  },
  {
    t: 1580949300,
    v: 1.0998825
  },
  {
    t: 1580949600,
    v: 1.0998725
  },
  {
    t: 1580949900,
    v: 1.09987
  },
  {
    t: 1580950200,
    v: 1.09986
  },
  {
    t: 1580950500,
    v: 1.0998275
  },
  {
    t: 1580950800,
    v: 1.0998162500000002
  },
  {
    t: 1580951100,
    v: 1.0998050000000001
  },
  {
    t: 1580951400,
    v: 1.099805
  },
  {
    t: 1580951700,
    v: 1.099785
  },
  {
    t: 1580952000,
    v: 1.09976625
  },
  {
    t: 1580952300,
    v: 1.0997350000000004
  },
  {
    t: 1580952600,
    v: 1.0997275
  },
  {
    t: 1580952900,
    v: 1.099715
  },
  {
    t: 1580953200,
    v: 1.099675
  },
  {
    t: 1580953500,
    v: 1.0996225000000002
  },
  {
    t: 1580953800,
    v: 1.0995825000000001
  },
  {
    t: 1580954100,
    v: 1.09957875
  },
  {
    t: 1580954400,
    v: 1.09958625
  },
  {
    t: 1580954700,
    v: 1.0996000000000001
  },
  {
    t: 1580955000,
    v: 1.09962
  },
  {
    t: 1580955300,
    v: 1.099645
  },
  {
    t: 1580955600,
    v: 1.0996712499999999
  },
  {
    t: 1580955900,
    v: 1.099705
  },
  {
    t: 1580956200,
    v: 1.09971875
  },
  {
    t: 1580956500,
    v: 1.09971875
  },
  {
    t: 1580956800,
    v: 1.09972
  },
  {
    t: 1580957100,
    v: 1.099715
  },
  {
    t: 1580957400,
    v: 1.09969875
  },
  {
    t: 1580957700,
    v: 1.0997025
  },
  {
    t: 1580958000,
    v: 1.09971
  },
  {
    t: 1580958300,
    v: 1.0997237499999999
  },
  {
    t: 1580958600,
    v: 1.0997350000000001
  },
  {
    t: 1580958900,
    v: 1.09973625
  },
  {
    t: 1580959200,
    v: 1.0997400000000002
  },
  {
    t: 1580959500,
    v: 1.0997462500000001
  },
  {
    t: 1580959800,
    v: 1.0997450000000002
  },
  {
    t: 1580960100,
    v: 1.09973125
  },
  {
    t: 1580960400,
    v: 1.0997275000000002
  },
  {
    t: 1580960700,
    v: 1.099715
  },
  {
    t: 1580961000,
    v: 1.0997000000000001
  },
  {
    t: 1580961300,
    v: 1.0996875000000002
  },
  {
    t: 1580961600,
    v: 1.0996825000000001
  },
  {
    t: 1580961900,
    v: 1.09967625
  },
  {
    t: 1580962200,
    v: 1.09968625
  },
  {
    t: 1580962500,
    v: 1.099685
  },
  {
    t: 1580962800,
    v: 1.0996562499999998
  },
  {
    t: 1580963100,
    v: 1.0996225
  },
  {
    t: 1580963400,
    v: 1.09959125
  },
  {
    t: 1580963700,
    v: 1.09958125
  },
  {
    t: 1580964000,
    v: 1.09957625
  },
  {
    t: 1580964300,
    v: 1.09957
  },
  {
    t: 1580964600,
    v: 1.0995537499999999
  },
  {
    t: 1580964900,
    v: 1.09954375
  },
  {
    t: 1580965200,
    v: 1.09955
  },
  {
    t: 1580965500,
    v: 1.09955625
  },
  {
    t: 1580965800,
    v: 1.0995650000000001
  },
  {
    t: 1580966100,
    v: 1.09954125
  },
  {
    t: 1580966400,
    v: 1.0995325
  },
  {
    t: 1580966700,
    v: 1.09954
  },
  {
    t: 1580967000,
    v: 1.0995525
  },
  {
    t: 1580967300,
    v: 1.09957
  },
  {
    t: 1580967600,
    v: 1.09959125
  },
  {
    t: 1580967900,
    v: 1.099605
  },
  {
    t: 1580968200,
    v: 1.09963375
  },
  {
    t: 1580968500,
    v: 1.0997000000000001
  },
  {
    t: 1580968800,
    v: 1.0997287500000001
  },
  {
    t: 1580969100,
    v: 1.0997412500000001
  },
  {
    t: 1580969400,
    v: 1.09975625
  },
  {
    t: 1580969700,
    v: 1.0997737500000002
  },
  {
    t: 1580970000,
    v: 1.0997675
  },
  {
    t: 1580970300,
    v: 1.099775
  },
  {
    t: 1580970600,
    v: 1.0997675
  },
  {
    t: 1580970900,
    v: 1.099735
  },
  {
    t: 1580971200,
    v: 1.09973375
  },
  {
    t: 1580971500,
    v: 1.09975625
  },
  {
    t: 1580971800,
    v: 1.09978
  },
  {
    t: 1580972100,
    v: 1.0997999999999999
  },
  {
    t: 1580972400,
    v: 1.099825
  },
  {
    t: 1580972700,
    v: 1.0998575
  },
  {
    t: 1580973000,
    v: 1.0998812500000001
  },
  {
    t: 1580973300,
    v: 1.0998937500000001
  },
  {
    t: 1580973600,
    v: 1.09990625
  },
  {
    t: 1580973900,
    v: 1.0999225
  },
  {
    t: 1580974200,
    v: 1.099975
  },
  {
    t: 1580974500,
    v: 1.10003375
  },
  {
    t: 1580974800,
    v: 1.10007875
  },
  {
    t: 1580975100,
    v: 1.1001375
  },
  {
    t: 1580975400,
    v: 1.10018
  },
  {
    t: 1580975700,
    v: 1.1002399999999999
  },
  {
    t: 1580976000,
    v: 1.1003425
  },
  {
    t: 1580976300,
    v: 1.10034
  },
  {
    t: 1580976600,
    v: 1.10030875
  },
  {
    t: 1580976900,
    v: 1.1002725
  },
  {
    t: 1580977200,
    v: 1.100225
  },
  {
    t: 1580977500,
    v: 1.10015375
  },
  {
    t: 1580977800,
    v: 1.10005125
  },
  {
    t: 1580978100,
    v: 1.09994375
  },
  {
    t: 1580978400,
    v: 1.0998075
  },
  {
    t: 1580978700,
    v: 1.0997612500000002
  },
  {
    t: 1580979000,
    v: 1.09972625
  },
  {
    t: 1580979300,
    v: 1.0996462500000002
  },
  {
    t: 1580979600,
    v: 1.09963
  },
  {
    t: 1580979900,
    v: 1.0996425
  },
  {
    t: 1580980200,
    v: 1.0997150000000002
  },
  {
    t: 1580980500,
    v: 1.09982375
  },
  {
    t: 1580980800,
    v: 1.0999237500000003
  },
  {
    t: 1580981100,
    v: 1.1000237500000003
  },
  {
    t: 1580981400,
    v: 1.1000925000000001
  },
  {
    t: 1580981700,
    v: 1.10022
  },
  {
    t: 1580982000,
    v: 1.10025
  },
  {
    t: 1580982300,
    v: 1.1002325
  },
  {
    t: 1580982600,
    v: 1.1002074999999998
  },
  {
    t: 1580982900,
    v: 1.10015625
  },
  {
    t: 1580983200,
    v: 1.10012125
  },
  {
    t: 1580983500,
    v: 1.1000925
  },
  {
    t: 1580983800,
    v: 1.10008375
  },
  {
    t: 1580984100,
    v: 1.10005625
  },
  {
    t: 1580984400,
    v: 1.100105
  },
  {
    t: 1580984700,
    v: 1.1001824999999998
  },
  {
    t: 1580985000,
    v: 1.10026
  },
  {
    t: 1580985300,
    v: 1.100315
  },
  {
    t: 1580985600,
    v: 1.1003325
  },
  {
    t: 1580985900,
    v: 1.1003137499999998
  },
  {
    t: 1580986200,
    v: 1.10029
  },
  {
    t: 1580986500,
    v: 1.10026125
  },
  {
    t: 1580986800,
    v: 1.10024
  },
  {
    t: 1580987100,
    v: 1.10019125
  },
  {
    t: 1580987400,
    v: 1.1001375
  },
  {
    t: 1580987700,
    v: 1.10011
  },
  {
    t: 1580988000,
    v: 1.10005875
  },
  {
    t: 1580988300,
    v: 1.1000225
  },
  {
    t: 1580988600,
    v: 1.09996
  },
  {
    t: 1580988900,
    v: 1.0999025
  },
  {
    t: 1580989200,
    v: 1.0998575
  },
  {
    t: 1580989500,
    v: 1.0998400000000002
  },
  {
    t: 1580989800,
    v: 1.099845
  },
  {
    t: 1580990100,
    v: 1.09983375
  },
  {
    t: 1580990400,
    v: 1.09982875
  },
  {
    t: 1580990700,
    v: 1.099855
  },
  {
    t: 1580991000,
    v: 1.0998875
  },
  {
    t: 1580991300,
    v: 1.0999424999999998
  },
  {
    t: 1580991600,
    v: 1.09998
  },
  {
    t: 1580991900,
    v: 1.1000137499999998
  },
  {
    t: 1580992200,
    v: 1.09998125
  },
  {
    t: 1580992500,
    v: 1.099955
  },
  {
    t: 1580992800,
    v: 1.09997375
  },
  {
    t: 1580993100,
    v: 1.09994375
  },
  {
    t: 1580993400,
    v: 1.0999375
  },
  {
    t: 1580993700,
    v: 1.0999575
  },
  {
    t: 1580994000,
    v: 1.09998
  },
  {
    t: 1580994300,
    v: 1.0999862499999997
  },
  {
    t: 1580994600,
    v: 1.10004875
  },
  {
    t: 1580994900,
    v: 1.10010875
  },
  {
    t: 1580995200,
    v: 1.10011
  },
  {
    t: 1580995500,
    v: 1.1001625
  },
  {
    t: 1580995800,
    v: 1.1001999999999998
  },
  {
    t: 1580996100,
    v: 1.1001925
  },
  {
    t: 1580996400,
    v: 1.10015625
  },
  {
    t: 1580996700,
    v: 1.1001587499999999
  },
  {
    t: 1580997000,
    v: 1.1001325
  },
  {
    t: 1580997300,
    v: 1.10011375
  },
  {
    t: 1580997600,
    v: 1.1001225000000001
  },
  {
    t: 1580997900,
    v: 1.10008875
  },
  {
    t: 1580998200,
    v: 1.1000425
  },
  {
    t: 1580998500,
    v: 1.10000375
  },
  {
    t: 1580998800,
    v: 1.0999637500000001
  },
  {
    t: 1580999100,
    v: 1.09988125
  },
  {
    t: 1580999400,
    v: 1.0997975
  },
  {
    t: 1580999700,
    v: 1.09972625
  },
  {
    t: 1581000000,
    v: 1.0996975
  },
  {
    t: 1581000300,
    v: 1.09967875
  },
  {
    t: 1581000600,
    v: 1.0996625
  },
  {
    t: 1581000900,
    v: 1.0995300000000001
  },
  {
    t: 1581001200,
    v: 1.0994237500000001
  },
  {
    t: 1581001500,
    v: 1.09931
  },
  {
    t: 1581001800,
    v: 1.09916875
  },
  {
    t: 1581002100,
    v: 1.09902
  },
  {
    t: 1581002400,
    v: 1.0988262500000001
  },
  {
    t: 1581002700,
    v: 1.0985800000000001
  },
  {
    t: 1581003000,
    v: 1.098305
  },
  {
    t: 1581003300,
    v: 1.09817875
  },
  {
    t: 1581003600,
    v: 1.0980237499999999
  },
  {
    t: 1581003900,
    v: 1.0978124999999999
  },
  {
    t: 1581004200,
    v: 1.097595
  },
  {
    t: 1581004500,
    v: 1.09750375
  },
  {
    t: 1581004800,
    v: 1.0974875
  },
  {
    t: 1581005100,
    v: 1.0974425
  },
  {
    t: 1581005400,
    v: 1.09747
  },
  {
    t: 1581005700,
    v: 1.0974074999999999
  },
  {
    t: 1581006000,
    v: 1.09742375
  },
  {
    t: 1581006300,
    v: 1.09758125
  },
  {
    t: 1581006600,
    v: 1.09778625
  },
  {
    t: 1581006900,
    v: 1.09784625
  },
  {
    t: 1581007200,
    v: 1.09780875
  },
  {
    t: 1581007500,
    v: 1.09783
  },
  {
    t: 1581007800,
    v: 1.09780375
  },
  {
    t: 1581008100,
    v: 1.09786125
  },
  {
    t: 1581008400,
    v: 1.09784375
  },
  {
    t: 1581008700,
    v: 1.09779
  },
  {
    t: 1581009000,
    v: 1.09773875
  },
  {
    t: 1581009300,
    v: 1.0977050000000002
  },
  {
    t: 1581009600,
    v: 1.09770625
  },
  {
    t: 1581009900,
    v: 1.09774375
  },
  {
    t: 1581010200,
    v: 1.09778625
  },
  {
    t: 1581010500,
    v: 1.09778
  },
  {
    t: 1581010800,
    v: 1.09780875
  },
  {
    t: 1581011100,
    v: 1.0978
  },
  {
    t: 1581011400,
    v: 1.0978012499999998
  },
  {
    t: 1581011700,
    v: 1.097755
  },
  {
    t: 1581012000,
    v: 1.09770125
  },
  {
    t: 1581012300,
    v: 1.09763125
  },
  {
    t: 1581012600,
    v: 1.0975662499999999
  },
  {
    t: 1581012900,
    v: 1.0975325
  },
  {
    t: 1581013200,
    v: 1.09750375
  },
  {
    t: 1581013500,
    v: 1.09748625
  },
  {
    t: 1581013800,
    v: 1.09746625
  },
  {
    t: 1581014100,
    v: 1.0974887500000001
  },
  {
    t: 1581014400,
    v: 1.0975087499999998
  },
  {
    t: 1581014700,
    v: 1.0975275
  },
  {
    t: 1581015000,
    v: 1.0975237500000001
  },
  {
    t: 1581015300,
    v: 1.0975050000000002
  },
  {
    t: 1581015600,
    v: 1.0974512500000002
  },
  {
    t: 1581015900,
    v: 1.09739625
  },
  {
    t: 1581016200,
    v: 1.0973424999999999
  },
  {
    t: 1581016500,
    v: 1.097295
  },
  {
    t: 1581016800,
    v: 1.09725125
  },
  {
    t: 1581017100,
    v: 1.0972312499999999
  },
  {
    t: 1581017400,
    v: 1.09724125
  },
  {
    t: 1581017700,
    v: 1.0972675
  },
  {
    t: 1581018000,
    v: 1.09730375
  },
  {
    t: 1581018300,
    v: 1.0973412500000002
  },
  {
    t: 1581018600,
    v: 1.0973775
  },
  {
    t: 1581018900,
    v: 1.097425
  },
  {
    t: 1581019200,
    v: 1.0974974999999998
  },
  {
    t: 1581019500,
    v: 1.09757
  },
  {
    t: 1581019800,
    v: 1.0976587500000001
  },
  {
    t: 1581020100,
    v: 1.09773625
  },
  {
    t: 1581020400,
    v: 1.09782125
  },
  {
    t: 1581020700,
    v: 1.09790125
  },
  {
    t: 1581021000,
    v: 1.09798
  },
  {
    t: 1581021300,
    v: 1.0980412499999999
  },
  {
    t: 1581021600,
    v: 1.09805
  },
  {
    t: 1581021900,
    v: 1.09804875
  },
  {
    t: 1581022200,
    v: 1.09801375
  },
  {
    t: 1581022500,
    v: 1.09798125
  },
  {
    t: 1581022800,
    v: 1.09794375
  },
  {
    t: 1581023100,
    v: 1.0979412499999999
  },
  {
    t: 1581023400,
    v: 1.09794375
  },
  {
    t: 1581023700,
    v: 1.09794
  },
  {
    t: 1581024000,
    v: 1.09796625
  },
  {
    t: 1581024300,
    v: 1.097985
  },
  {
    t: 1581024600,
    v: 1.09801125
  },
  {
    t: 1581024900,
    v: 1.09803125
  },
  {
    t: 1581025200,
    v: 1.0980562500000002
  },
  {
    t: 1581025500,
    v: 1.0980550000000002
  },
  {
    t: 1581025800,
    v: 1.09804625
  },
  {
    t: 1581026100,
    v: 1.0980649999999998
  },
  {
    t: 1581026400,
    v: 1.0980675
  },
  {
    t: 1581026700,
    v: 1.09805375
  },
  {
    t: 1581027000,
    v: 1.0980525
  },
  {
    t: 1581027300,
    v: 1.0980450000000002
  },
  {
    t: 1581027600,
    v: 1.0980375
  },
  {
    t: 1581027900,
    v: 1.0980225000000001
  },
  {
    t: 1581028200,
    v: 1.0980125
  },
  {
    t: 1581028500,
    v: 1.097975
  },
  {
    t: 1581028800,
    v: 1.0979487499999998
  },
  {
    t: 1581029100,
    v: 1.0979437499999998
  },
  {
    t: 1581029400,
    v: 1.0979487499999998
  },
  {
    t: 1581029700,
    v: 1.09796125
  },
  {
    t: 1581030000,
    v: 1.09796
  },
  {
    t: 1581030300,
    v: 1.09796125
  },
  {
    t: 1581030600,
    v: 1.0979712499999998
  },
  {
    t: 1581030900,
    v: 1.0979824999999999
  },
  {
    t: 1581031200,
    v: 1.0979937499999999
  },
  {
    t: 1581031500,
    v: 1.0980062499999999
  },
  {
    t: 1581031800,
    v: 1.0980149999999997
  },
  {
    t: 1581032100,
    v: 1.0980149999999997
  },
  {
    t: 1581032400,
    v: 1.09802125
  },
  {
    t: 1581032700,
    v: 1.0980325
  },
  {
    t: 1581033000,
    v: 1.09803375
  },
  {
    t: 1581033300,
    v: 1.09803375
  },
  {
    t: 1581033600,
    v: 1.09800125
  },
  {
    t: 1581033900,
    v: 1.09797
  },
  {
    t: 1581034200,
    v: 1.09793875
  },
  {
    t: 1581034500,
    v: 1.09790625
  },
  {
    t: 1581034800,
    v: 1.09788375
  },
  {
    t: 1581035100,
    v: 1.0978575
  },
  {
    t: 1581035400,
    v: 1.09783125
  },
  {
    t: 1581035700,
    v: 1.09780125
  },
  {
    t: 1581036000,
    v: 1.09782
  },
  {
    t: 1581036300,
    v: 1.0978575
  },
  {
    t: 1581036600,
    v: 1.09788
  },
  {
    t: 1581036900,
    v: 1.0979225000000001
  },
  {
    t: 1581037200,
    v: 1.09795375
  },
  {
    t: 1581037500,
    v: 1.097985
  },
  {
    t: 1581037800,
    v: 1.0980249999999998
  },
  {
    t: 1581038100,
    v: 1.09807375
  },
  {
    t: 1581038400,
    v: 1.09810875
  },
  {
    t: 1581038700,
    v: 1.09812125
  },
  {
    t: 1581039000,
    v: 1.098145
  },
  {
    t: 1581039300,
    v: 1.0981562500000002
  },
  {
    t: 1581039600,
    v: 1.0981775
  },
  {
    t: 1581039900,
    v: 1.098195
  },
  {
    t: 1581040200,
    v: 1.09820125
  },
  {
    t: 1581040500,
    v: 1.09821125
  },
  {
    t: 1581040800,
    v: 1.0982112499999999
  },
  {
    t: 1581041100,
    v: 1.09821375
  },
  {
    t: 1581041400,
    v: 1.0982125
  },
  {
    t: 1581041700,
    v: 1.09820625
  },
  {
    t: 1581042000,
    v: 1.0982037500000001
  },
  {
    t: 1581042300,
    v: 1.0982125
  },
  {
    t: 1581042600,
    v: 1.098225
  },
  {
    t: 1581042900,
    v: 1.09822875
  },
  {
    t: 1581043200,
    v: 1.09824125
  },
  {
    t: 1581043500,
    v: 1.09826
  },
  {
    t: 1581043800,
    v: 1.09827375
  },
  {
    t: 1581044100,
    v: 1.09830625
  },
  {
    t: 1581044400,
    v: 1.098325
  },
  {
    t: 1581044700,
    v: 1.09832625
  },
  {
    t: 1581045000,
    v: 1.098325
  },
  {
    t: 1581045300,
    v: 1.098325
  },
  {
    t: 1581045600,
    v: 1.09833625
  },
  {
    t: 1581045900,
    v: 1.09834
  },
  {
    t: 1581046200,
    v: 1.09835
  },
  {
    t: 1581046500,
    v: 1.09835375
  },
  {
    t: 1581046800,
    v: 1.0983462499999999
  },
  {
    t: 1581047100,
    v: 1.09834875
  },
  {
    t: 1581047400,
    v: 1.0983424999999998
  },
  {
    t: 1581047700,
    v: 1.0983399999999999
  },
  {
    t: 1581048000,
    v: 1.0983125
  },
  {
    t: 1581048300,
    v: 1.09828875
  },
  {
    t: 1581048600,
    v: 1.0982575
  },
  {
    t: 1581048900,
    v: 1.09822
  },
  {
    t: 1581049200,
    v: 1.09819625
  },
  {
    t: 1581049500,
    v: 1.09817
  },
  {
    t: 1581049800,
    v: 1.09815125
  },
  {
    t: 1581050100,
    v: 1.098115
  },
  {
    t: 1581050400,
    v: 1.09808625
  },
  {
    t: 1581050700,
    v: 1.0980487499999998
  },
  {
    t: 1581051000,
    v: 1.0980262499999998
  },
  {
    t: 1581051300,
    v: 1.09802
  },
  {
    t: 1581051600,
    v: 1.0980149999999997
  },
  {
    t: 1581051900,
    v: 1.09803
  },
  {
    t: 1581052200,
    v: 1.09804375
  },
  {
    t: 1581052500,
    v: 1.0980587499999999
  },
  {
    t: 1581052800,
    v: 1.0980837499999998
  },
  {
    t: 1581053100,
    v: 1.09812125
  },
  {
    t: 1581053400,
    v: 1.0981512500000001
  },
  {
    t: 1581053700,
    v: 1.0981637499999999
  },
  {
    t: 1581054000,
    v: 1.09816875
  },
  {
    t: 1581054300,
    v: 1.09815125
  },
  {
    t: 1581054600,
    v: 1.098135
  },
  {
    t: 1581054900,
    v: 1.09812625
  },
  {
    t: 1581055200,
    v: 1.09812625
  },
  {
    t: 1581055500,
    v: 1.09811375
  },
  {
    t: 1581055800,
    v: 1.09809625
  },
  {
    t: 1581056100,
    v: 1.09807
  },
  {
    t: 1581056400,
    v: 1.09803
  },
  {
    t: 1581056700,
    v: 1.0980075
  },
  {
    t: 1581057000,
    v: 1.09798125
  },
  {
    t: 1581057300,
    v: 1.0979762499999999
  },
  {
    t: 1581057600,
    v: 1.09794375
  },
  {
    t: 1581057900,
    v: 1.0979
  },
  {
    t: 1581058200,
    v: 1.09786
  },
  {
    t: 1581058500,
    v: 1.0978275000000002
  },
  {
    t: 1581058800,
    v: 1.09779125
  },
  {
    t: 1581059100,
    v: 1.0977112500000001
  },
  {
    t: 1581059400,
    v: 1.097635
  },
  {
    t: 1581059700,
    v: 1.0975300000000001
  },
  {
    t: 1581060000,
    v: 1.09740875
  },
  {
    t: 1581060300,
    v: 1.0973475000000001
  },
  {
    t: 1581060600,
    v: 1.097295
  },
  {
    t: 1581060900,
    v: 1.09725625
  },
  {
    t: 1581061200,
    v: 1.09725375
  },
  {
    t: 1581061500,
    v: 1.0972325
  },
  {
    t: 1581061800,
    v: 1.0971725
  },
  {
    t: 1581062100,
    v: 1.0971075
  },
  {
    t: 1581062400,
    v: 1.0970725
  },
  {
    t: 1581062700,
    v: 1.0969525
  },
  {
    t: 1581063000,
    v: 1.09688125
  },
  {
    t: 1581063300,
    v: 1.096805
  },
  {
    t: 1581063600,
    v: 1.09674
  },
  {
    t: 1581063900,
    v: 1.096705
  },
  {
    t: 1581064200,
    v: 1.0966662500000002
  },
  {
    t: 1581064500,
    v: 1.09666
  },
  {
    t: 1581064800,
    v: 1.09666
  },
  {
    t: 1581065100,
    v: 1.0967087500000001
  },
  {
    t: 1581065400,
    v: 1.0967062500000002
  },
  {
    t: 1581065700,
    v: 1.09657875
  },
  {
    t: 1581066000,
    v: 1.0964924999999999
  },
  {
    t: 1581066300,
    v: 1.09640875
  },
  {
    t: 1581066600,
    v: 1.0963850000000002
  },
  {
    t: 1581066900,
    v: 1.09636125
  },
  {
    t: 1581067200,
    v: 1.0963125
  },
  {
    t: 1581067500,
    v: 1.0962187499999998
  },
  {
    t: 1581067800,
    v: 1.09612125
  },
  {
    t: 1581068100,
    v: 1.096135
  },
  {
    t: 1581068400,
    v: 1.09610875
  },
  {
    t: 1581068700,
    v: 1.09606875
  },
  {
    t: 1581069000,
    v: 1.09599
  },
  {
    t: 1581069300,
    v: 1.0958649999999999
  },
  {
    t: 1581069600,
    v: 1.09578125
  },
  {
    t: 1581069900,
    v: 1.09571875
  },
  {
    t: 1581070200,
    v: 1.09560375
  },
  {
    t: 1581070500,
    v: 1.0955150000000002
  },
  {
    t: 1581070800,
    v: 1.09540375
  },
  {
    t: 1581071100,
    v: 1.09528
  },
  {
    t: 1581071400,
    v: 1.09522875
  },
  {
    t: 1581071700,
    v: 1.0951975
  },
  {
    t: 1581072000,
    v: 1.09514875
  },
  {
    t: 1581072300,
    v: 1.09511875
  },
  {
    t: 1581072600,
    v: 1.09512875
  },
  {
    t: 1581072900,
    v: 1.09515
  },
  {
    t: 1581073200,
    v: 1.09514375
  },
  {
    t: 1581073500,
    v: 1.09519375
  },
  {
    t: 1581073800,
    v: 1.0952087499999998
  },
  {
    t: 1581074100,
    v: 1.09525125
  },
  {
    t: 1581074400,
    v: 1.0953025
  },
  {
    t: 1581074700,
    v: 1.09536375
  },
  {
    t: 1581075000,
    v: 1.09544125
  },
  {
    t: 1581075300,
    v: 1.0954675
  },
  {
    t: 1581075600,
    v: 1.09553375
  },
  {
    t: 1581075900,
    v: 1.09552625
  },
  {
    t: 1581076200,
    v: 1.095505
  },
  {
    t: 1581076500,
    v: 1.09546375
  },
  {
    t: 1581076800,
    v: 1.09541375
  },
  {
    t: 1581077100,
    v: 1.0953775000000001
  },
  {
    t: 1581077400,
    v: 1.0953350000000002
  },
  {
    t: 1581077700,
    v: 1.09530375
  },
  {
    t: 1581078000,
    v: 1.0952725
  },
  {
    t: 1581078300,
    v: 1.0953300000000001
  },
  {
    t: 1581078600,
    v: 1.0953924999999998
  },
  {
    t: 1581078900,
    v: 1.0954587500000001
  },
  {
    t: 1581079200,
    v: 1.09555625
  },
  {
    t: 1581079500,
    v: 1.0956225
  },
  {
    t: 1581079800,
    v: 1.0956975
  },
  {
    t: 1581080100,
    v: 1.0958362499999998
  },
  {
    t: 1581080400,
    v: 1.0959812500000001
  },
  {
    t: 1581080700,
    v: 1.09610625
  },
  {
    t: 1581081000,
    v: 1.0962575
  },
  {
    t: 1581081300,
    v: 1.0964275
  },
  {
    t: 1581081600,
    v: 1.0965325
  },
  {
    t: 1581081900,
    v: 1.0966025
  },
  {
    t: 1581082200,
    v: 1.0966837499999997
  },
  {
    t: 1581082500,
    v: 1.09668875
  },
  {
    t: 1581082800,
    v: 1.0966224999999998
  },
  {
    t: 1581083100,
    v: 1.0965137500000002
  },
  {
    t: 1581083400,
    v: 1.09640625
  },
  {
    t: 1581083700,
    v: 1.0962487500000002
  },
  {
    t: 1581084000,
    v: 1.096065
  },
  {
    t: 1581084300,
    v: 1.0958825
  },
  {
    t: 1581084600,
    v: 1.09571375
  },
  {
    t: 1581084900,
    v: 1.0955875000000002
  },
  {
    t: 1581085200,
    v: 1.0955175
  },
  {
    t: 1581085500,
    v: 1.09549375
  },
  {
    t: 1581085800,
    v: 1.0955625
  },
  {
    t: 1581086100,
    v: 1.09560875
  },
  {
    t: 1581086400,
    v: 1.09579625
  },
  {
    t: 1581086700,
    v: 1.09602125
  },
  {
    t: 1581087000,
    v: 1.0962025
  },
  {
    t: 1581087300,
    v: 1.09640875
  },
  {
    t: 1581087600,
    v: 1.09664875
  },
  {
    t: 1581087900,
    v: 1.09683875
  },
  {
    t: 1581088200,
    v: 1.09686875
  },
  {
    t: 1581088500,
    v: 1.096925
  },
  {
    t: 1581088800,
    v: 1.09684
  },
  {
    t: 1581089100,
    v: 1.096735
  },
  {
    t: 1581089400,
    v: 1.0966662500000002
  },
  {
    t: 1581089700,
    v: 1.09654125
  },
  {
    t: 1581090000,
    v: 1.09653
  },
  {
    t: 1581090300,
    v: 1.096505
  },
  {
    t: 1581090600,
    v: 1.09643125
  },
  {
    t: 1581090900,
    v: 1.09639125
  },
  {
    t: 1581091200,
    v: 1.09639375
  },
  {
    t: 1581091500,
    v: 1.09639125
  },
  {
    t: 1581091800,
    v: 1.0963174999999998
  },
  {
    t: 1581092100,
    v: 1.0962049999999999
  },
  {
    t: 1581092400,
    v: 1.0959625
  },
  {
    t: 1581092700,
    v: 1.0956912500000002
  },
  {
    t: 1581093000,
    v: 1.0955300000000001
  },
  {
    t: 1581093300,
    v: 1.09540125
  },
  {
    t: 1581093600,
    v: 1.09527
  },
  {
    t: 1581093900,
    v: 1.0951775
  },
  {
    t: 1581094200,
    v: 1.09510625
  },
  {
    t: 1581094500,
    v: 1.09506875
  },
  {
    t: 1581094800,
    v: 1.09499125
  },
  {
    t: 1581095100,
    v: 1.09498625
  },
  {
    t: 1581095400,
    v: 1.0949425000000002
  },
  {
    t: 1581095700,
    v: 1.0948775000000002
  },
  {
    t: 1581096000,
    v: 1.09483375
  },
  {
    t: 1581096300,
    v: 1.09476375
  },
  {
    t: 1581096600,
    v: 1.0947312500000002
  },
  {
    t: 1581096900,
    v: 1.094695
  },
  {
    t: 1581097200,
    v: 1.09467
  },
  {
    t: 1581097500,
    v: 1.09465125
  },
  {
    t: 1581097800,
    v: 1.09462875
  },
  {
    t: 1581098100,
    v: 1.0945650000000002
  },
  {
    t: 1581098400,
    v: 1.0945187500000002
  },
  {
    t: 1581098700,
    v: 1.09451
  },
  {
    t: 1581099000,
    v: 1.09447125
  },
  {
    t: 1581099300,
    v: 1.0944437500000002
  },
  {
    t: 1581099600,
    v: 1.0944275
  },
  {
    t: 1581099900,
    v: 1.0944387500000001
  },
  {
    t: 1581100200,
    v: 1.09448
  },
  {
    t: 1581100500,
    v: 1.09450625
  },
  {
    t: 1581100800,
    v: 1.094515
  },
  {
    t: 1581101100,
    v: 1.09448125
  },
  {
    t: 1581101400,
    v: 1.0944512499999999
  },
  {
    t: 1581101700,
    v: 1.09444375
  },
  {
    t: 1581102000,
    v: 1.0944687499999999
  },
  {
    t: 1581102300,
    v: 1.09445875
  },
  {
    t: 1581102600,
    v: 1.0944387500000001
  },
  {
    t: 1581102900,
    v: 1.0944712499999998
  },
  {
    t: 1581103200,
    v: 1.09450125
  },
  {
    t: 1581103500,
    v: 1.09456
  },
  {
    t: 1581103800,
    v: 1.094625
  },
  {
    t: 1581104100,
    v: 1.0946749999999998
  },
  {
    t: 1581104400,
    v: 1.094655
  },
  {
    t: 1581104700,
    v: 1.094665
  },
  {
    t: 1581105000,
    v: 1.0946725
  },
  {
    t: 1581105300,
    v: 1.09465875
  },
  {
    t: 1581105600,
    v: 1.09462875
  },
  {
    t: 1581105900,
    v: 1.0946025
  },
  {
    t: 1581106200,
    v: 1.094585
  },
  {
    t: 1581106500,
    v: 1.0945612500000002
  },
  {
    t: 1581106800,
    v: 1.09456375
  },
  {
    t: 1581107100,
    v: 1.0945350000000003
  },
  {
    t: 1581107400,
    v: 1.09452875
  },
  {
    t: 1581107700,
    v: 1.0945425
  },
  {
    t: 1581108000,
    v: 1.0945699999999998
  },
  {
    t: 1581108300,
    v: 1.09457
  },
  {
    t: 1581108600,
    v: 1.09457625
  },
  {
    t: 1581108900,
    v: 1.0945925
  },
  {
    t: 1581109200,
    v: 1.0946175
  },
  {
    t: 1581109500,
    v: 1.0946475
  },
  {
    t: 1581109800,
    v: 1.0946574999999998
  },
  {
    t: 1581110100,
    v: 1.09464125
  },
  {
    t: 1581110400,
    v: 1.0946200000000001
  },
  {
    t: 1581110700,
    v: 1.09461625
  },
  {
    t: 1581111000,
    v: 1.0946075
  },
  {
    t: 1581111300,
    v: 1.09459125
  },
  {
    t: 1581111600,
    v: 1.09455875
  },
  {
    t: 1581286200,
    v: 1.09452375
  },
  {
    t: 1581286500,
    v: 1.0944912500000001
  },
  {
    t: 1581286800,
    v: 1.09445875
  },
  {
    t: 1581287100,
    v: 1.0944312500000002
  },
  {
    t: 1581287400,
    v: 1.0943950000000002
  },
  {
    t: 1581287700,
    v: 1.0943575
  },
  {
    t: 1581288000,
    v: 1.09432625
  },
  {
    t: 1581288300,
    v: 1.09430875
  },
  {
    t: 1581288600,
    v: 1.0943
  },
  {
    t: 1581288900,
    v: 1.09428875
  },
  {
    t: 1581289200,
    v: 1.094285
  },
  {
    t: 1581289500,
    v: 1.0943
  },
  {
    t: 1581289800,
    v: 1.0943224999999999
  },
  {
    t: 1581290100,
    v: 1.0943424999999998
  },
  {
    t: 1581290400,
    v: 1.09437
  },
  {
    t: 1581290700,
    v: 1.0943975
  },
  {
    t: 1581291000,
    v: 1.09444125
  },
  {
    t: 1581291300,
    v: 1.09448875
  },
  {
    t: 1581291600,
    v: 1.09456875
  },
  {
    t: 1581291900,
    v: 1.0946250000000002
  },
  {
    t: 1581292200,
    v: 1.0946887500000002
  },
  {
    t: 1581292500,
    v: 1.09474375
  },
  {
    t: 1581292800,
    v: 1.0947962500000001
  },
  {
    t: 1581293100,
    v: 1.09484375
  },
  {
    t: 1581293400,
    v: 1.09488875
  },
  {
    t: 1581293700,
    v: 1.09492625
  },
  {
    t: 1581294000,
    v: 1.09494
  },
  {
    t: 1581294300,
    v: 1.09496375
  },
  {
    t: 1581294600,
    v: 1.0949725
  },
  {
    t: 1581294900,
    v: 1.095
  },
  {
    t: 1581295200,
    v: 1.0950199999999999
  },
  {
    t: 1581295500,
    v: 1.0950499999999999
  },
  {
    t: 1581295800,
    v: 1.09507
  },
  {
    t: 1581296100,
    v: 1.09509875
  },
  {
    t: 1581296400,
    v: 1.09512375
  },
  {
    t: 1581296700,
    v: 1.0951325
  },
  {
    t: 1581297000,
    v: 1.0951487500000001
  },
  {
    t: 1581297300,
    v: 1.09515625
  },
  {
    t: 1581297600,
    v: 1.0951575
  },
  {
    t: 1581297900,
    v: 1.09515625
  },
  {
    t: 1581298200,
    v: 1.095155
  },
  {
    t: 1581298500,
    v: 1.09513875
  },
  {
    t: 1581298800,
    v: 1.0951149999999998
  },
  {
    t: 1581299100,
    v: 1.09508875
  },
  {
    t: 1581299400,
    v: 1.09506375
  },
  {
    t: 1581299700,
    v: 1.0950449999999998
  },
  {
    t: 1581300000,
    v: 1.0950425
  },
  {
    t: 1581300300,
    v: 1.09505875
  },
  {
    t: 1581300600,
    v: 1.09506125
  },
  {
    t: 1581300900,
    v: 1.09506
  },
  {
    t: 1581301200,
    v: 1.09507875
  },
  {
    t: 1581301500,
    v: 1.09510625
  },
  {
    t: 1581301800,
    v: 1.09512125
  },
  {
    t: 1581302100,
    v: 1.095135
  },
  {
    t: 1581302400,
    v: 1.0951325
  },
  {
    t: 1581302700,
    v: 1.0951125
  },
  {
    t: 1581303000,
    v: 1.09510375
  },
  {
    t: 1581303300,
    v: 1.0951312500000001
  },
  {
    t: 1581303600,
    v: 1.0951525
  },
  {
    t: 1581303900,
    v: 1.0951624999999998
  },
  {
    t: 1581304200,
    v: 1.09517
  },
  {
    t: 1581304500,
    v: 1.09516875
  },
  {
    t: 1581304800,
    v: 1.0951724999999999
  },
  {
    t: 1581305100,
    v: 1.0951775
  },
  {
    t: 1581305400,
    v: 1.09516375
  },
  {
    t: 1581305700,
    v: 1.09512625
  },
  {
    t: 1581306000,
    v: 1.09509875
  },
  {
    t: 1581306300,
    v: 1.0950875
  },
  {
    t: 1581306600,
    v: 1.09509375
  },
  {
    t: 1581306900,
    v: 1.09510375
  },
  {
    t: 1581307200,
    v: 1.09511625
  },
  {
    t: 1581307500,
    v: 1.09511625
  },
  {
    t: 1581307800,
    v: 1.0951425
  },
  {
    t: 1581308100,
    v: 1.0951725
  },
  {
    t: 1581308400,
    v: 1.0951849999999999
  },
  {
    t: 1581308700,
    v: 1.09519625
  },
  {
    t: 1581309000,
    v: 1.09521625
  },
  {
    t: 1581309300,
    v: 1.09523625
  },
  {
    t: 1581309600,
    v: 1.09526125
  },
  {
    t: 1581309900,
    v: 1.09529875
  },
  {
    t: 1581310200,
    v: 1.09532875
  },
  {
    t: 1581310500,
    v: 1.0953775000000001
  },
  {
    t: 1581310800,
    v: 1.0954225
  },
  {
    t: 1581311100,
    v: 1.0954687499999998
  },
  {
    t: 1581311400,
    v: 1.0955062500000001
  },
  {
    t: 1581311700,
    v: 1.09553625
  },
  {
    t: 1581312000,
    v: 1.09555
  },
  {
    t: 1581312300,
    v: 1.0955625
  },
  {
    t: 1581312600,
    v: 1.09556875
  },
  {
    t: 1581312900,
    v: 1.09555
  },
  {
    t: 1581313200,
    v: 1.095525
  },
  {
    t: 1581313500,
    v: 1.09550375
  },
  {
    t: 1581313800,
    v: 1.09548625
  },
  {
    t: 1581314100,
    v: 1.0954774999999999
  },
  {
    t: 1581314400,
    v: 1.0954774999999999
  },
  {
    t: 1581314700,
    v: 1.09546
  },
  {
    t: 1581315000,
    v: 1.09544375
  },
  {
    t: 1581315300,
    v: 1.09543125
  },
  {
    t: 1581315600,
    v: 1.0954325
  },
  {
    t: 1581315900,
    v: 1.09542875
  },
  {
    t: 1581316200,
    v: 1.09542125
  },
  {
    t: 1581316500,
    v: 1.09540625
  },
  {
    t: 1581316800,
    v: 1.0954074999999999
  },
  {
    t: 1581317100,
    v: 1.095415
  },
  {
    t: 1581317400,
    v: 1.0954025
  },
  {
    t: 1581317700,
    v: 1.095365
  },
  {
    t: 1581318000,
    v: 1.0952975
  },
  {
    t: 1581318300,
    v: 1.0952350000000002
  },
  {
    t: 1581318600,
    v: 1.0951625
  },
  {
    t: 1581318900,
    v: 1.0951387499999998
  },
  {
    t: 1581319200,
    v: 1.0951087499999999
  },
  {
    t: 1581319500,
    v: 1.09508
  },
  {
    t: 1581319800,
    v: 1.0950825
  },
  {
    t: 1581320100,
    v: 1.09509875
  },
  {
    t: 1581320400,
    v: 1.0951125
  },
  {
    t: 1581320700,
    v: 1.09509125
  },
  {
    t: 1581321000,
    v: 1.0950675
  },
  {
    t: 1581321300,
    v: 1.094995
  },
  {
    t: 1581321600,
    v: 1.09493625
  },
  {
    t: 1581321900,
    v: 1.09485625
  },
  {
    t: 1581322200,
    v: 1.0947675
  },
  {
    t: 1581322500,
    v: 1.0946862499999999
  },
  {
    t: 1581322800,
    v: 1.09464875
  },
  {
    t: 1581323100,
    v: 1.0946462499999998
  },
  {
    t: 1581323400,
    v: 1.09464625
  },
  {
    t: 1581323700,
    v: 1.09469375
  },
  {
    t: 1581324000,
    v: 1.09473125
  },
  {
    t: 1581324300,
    v: 1.0947812499999998
  },
  {
    t: 1581324600,
    v: 1.0948687499999998
  },
  {
    t: 1581324900,
    v: 1.0949337499999998
  },
  {
    t: 1581325200,
    v: 1.0949624999999998
  },
  {
    t: 1581325500,
    v: 1.0949974999999998
  },
  {
    t: 1581325800,
    v: 1.0950312500000001
  },
  {
    t: 1581326100,
    v: 1.0950375
  },
  {
    t: 1581326400,
    v: 1.09501125
  },
  {
    t: 1581326700,
    v: 1.0950075000000001
  },
  {
    t: 1581327000,
    v: 1.0949625
  },
  {
    t: 1581327300,
    v: 1.0949575
  },
  {
    t: 1581327600,
    v: 1.0949575
  },
  {
    t: 1581327900,
    v: 1.09490875
  },
  {
    t: 1581328200,
    v: 1.09486375
  },
  {
    t: 1581328500,
    v: 1.09484875
  },
  {
    t: 1581328800,
    v: 1.094875
  },
  {
    t: 1581329100,
    v: 1.09491875
  },
  {
    t: 1581329400,
    v: 1.09495625
  },
  {
    t: 1581329700,
    v: 1.0949825
  },
  {
    t: 1581330000,
    v: 1.0950312500000001
  },
  {
    t: 1581330300,
    v: 1.0951000000000002
  },
  {
    t: 1581330600,
    v: 1.09516125
  },
  {
    t: 1581330900,
    v: 1.09516875
  },
  {
    t: 1581331200,
    v: 1.0951625
  },
  {
    t: 1581331500,
    v: 1.0951187500000001
  },
  {
    t: 1581331800,
    v: 1.09509375
  },
  {
    t: 1581332100,
    v: 1.09505
  },
  {
    t: 1581332400,
    v: 1.0949512499999998
  },
  {
    t: 1581332700,
    v: 1.09489375
  },
  {
    t: 1581333000,
    v: 1.0948799999999999
  },
  {
    t: 1581333300,
    v: 1.0948674999999999
  },
  {
    t: 1581333600,
    v: 1.0948675
  },
  {
    t: 1581333900,
    v: 1.0948675
  },
  {
    t: 1581334200,
    v: 1.0948862499999998
  },
  {
    t: 1581334500,
    v: 1.09489125
  },
  {
    t: 1581334800,
    v: 1.09496875
  },
  {
    t: 1581335100,
    v: 1.09503375
  },
  {
    t: 1581335400,
    v: 1.09503125
  },
  {
    t: 1581335700,
    v: 1.0950275
  },
  {
    t: 1581336000,
    v: 1.094995
  },
  {
    t: 1581336300,
    v: 1.09497625
  },
  {
    t: 1581336600,
    v: 1.09495125
  },
  {
    t: 1581336900,
    v: 1.09494
  },
  {
    t: 1581337200,
    v: 1.09492875
  },
  {
    t: 1581337500,
    v: 1.09490875
  },
  {
    t: 1581337800,
    v: 1.09489625
  },
  {
    t: 1581338100,
    v: 1.0949175
  },
  {
    t: 1581338400,
    v: 1.09495
  },
  {
    t: 1581338700,
    v: 1.09498875
  },
  {
    t: 1581339000,
    v: 1.0949837500000001
  },
  {
    t: 1581339300,
    v: 1.09498875
  },
  {
    t: 1581339600,
    v: 1.09498375
  },
  {
    t: 1581339900,
    v: 1.09498875
  },
  {
    t: 1581340200,
    v: 1.0950175
  },
  {
    t: 1581340500,
    v: 1.09500625
  },
  {
    t: 1581340800,
    v: 1.09499375
  },
  {
    t: 1581341100,
    v: 1.0950075
  },
  {
    t: 1581341400,
    v: 1.09501125
  },
  {
    t: 1581341700,
    v: 1.0950075000000001
  },
  {
    t: 1581342000,
    v: 1.09497125
  },
  {
    t: 1581342300,
    v: 1.094875
  },
  {
    t: 1581342600,
    v: 1.0946912500000001
  },
  {
    t: 1581342900,
    v: 1.09447625
  },
  {
    t: 1581343200,
    v: 1.0942662500000002
  },
  {
    t: 1581343500,
    v: 1.0939750000000001
  },
  {
    t: 1581343800,
    v: 1.0937087500000002
  },
  {
    t: 1581344100,
    v: 1.0934675
  },
  {
    t: 1581344400,
    v: 1.0932525
  },
  {
    t: 1581344700,
    v: 1.0930625
  },
  {
    t: 1581345000,
    v: 1.0929775
  },
  {
    t: 1581345300,
    v: 1.0929274999999998
  },
  {
    t: 1581345600,
    v: 1.0928862499999998
  },
  {
    t: 1581345900,
    v: 1.0928849999999999
  },
  {
    t: 1581346200,
    v: 1.09288375
  },
  {
    t: 1581346500,
    v: 1.09285625
  },
  {
    t: 1581346800,
    v: 1.09281125
  },
  {
    t: 1581347100,
    v: 1.09282125
  },
  {
    t: 1581347400,
    v: 1.09286
  },
  {
    t: 1581347700,
    v: 1.09291
  },
  {
    t: 1581348000,
    v: 1.09297
  },
  {
    t: 1581348300,
    v: 1.0930775000000001
  },
  {
    t: 1581348600,
    v: 1.093145
  },
  {
    t: 1581348900,
    v: 1.0932075
  },
  {
    t: 1581349200,
    v: 1.0932487499999999
  },
  {
    t: 1581349500,
    v: 1.0932162499999998
  },
  {
    t: 1581349800,
    v: 1.09308625
  },
  {
    t: 1581350100,
    v: 1.0929775
  },
  {
    t: 1581350400,
    v: 1.09283125
  },
  {
    t: 1581350700,
    v: 1.0926075
  },
  {
    t: 1581351000,
    v: 1.0924299999999998
  },
  {
    t: 1581351300,
    v: 1.092215
  },
  {
    t: 1581351600,
    v: 1.092015
  },
  {
    t: 1581351900,
    v: 1.09185
  },
  {
    t: 1581352200,
    v: 1.09171625
  },
  {
    t: 1581352500,
    v: 1.091555
  },
  {
    t: 1581352800,
    v: 1.0914125
  },
  {
    t: 1581353100,
    v: 1.09133375
  },
  {
    t: 1581353400,
    v: 1.09126875
  },
  {
    t: 1581353700,
    v: 1.0912662499999999
  },
  {
    t: 1581354000,
    v: 1.09131625
  },
  {
    t: 1581354300,
    v: 1.09134625
  },
  {
    t: 1581354600,
    v: 1.0914062500000001
  },
  {
    t: 1581354900,
    v: 1.09148
  },
  {
    t: 1581355200,
    v: 1.09152125
  },
  {
    t: 1581355500,
    v: 1.0915412500000001
  },
  {
    t: 1581355800,
    v: 1.09156875
  },
  {
    t: 1581356100,
    v: 1.0915875000000002
  },
  {
    t: 1581356400,
    v: 1.0915225
  },
  {
    t: 1581356700,
    v: 1.0915074999999999
  },
  {
    t: 1581357000,
    v: 1.0914525
  },
  {
    t: 1581357300,
    v: 1.09136875
  },
  {
    t: 1581357600,
    v: 1.0913275
  },
  {
    t: 1581357900,
    v: 1.0913012500000001
  },
  {
    t: 1581358200,
    v: 1.0912712500000001
  },
  {
    t: 1581358500,
    v: 1.0912374999999999
  },
  {
    t: 1581358800,
    v: 1.091265
  },
  {
    t: 1581359100,
    v: 1.09127125
  },
  {
    t: 1581359400,
    v: 1.09130375
  },
  {
    t: 1581359700,
    v: 1.09136875
  },
  {
    t: 1581360000,
    v: 1.09141625
  },
  {
    t: 1581360300,
    v: 1.09143625
  },
  {
    t: 1581360600,
    v: 1.09142625
  },
  {
    t: 1581360900,
    v: 1.0914225
  },
  {
    t: 1581361200,
    v: 1.09139875
  },
  {
    t: 1581361500,
    v: 1.09136875
  },
  {
    t: 1581361800,
    v: 1.0913612499999998
  },
  {
    t: 1581362100,
    v: 1.0913575
  },
  {
    t: 1581362400,
    v: 1.09136625
  },
  {
    t: 1581362700,
    v: 1.09137125
  },
  {
    t: 1581363000,
    v: 1.0913975
  },
  {
    t: 1581363300,
    v: 1.0914175000000002
  },
  {
    t: 1581363600,
    v: 1.09143375
  },
  {
    t: 1581363900,
    v: 1.0914575
  },
  {
    t: 1581364200,
    v: 1.0914624999999998
  },
  {
    t: 1581364500,
    v: 1.09144625
  },
  {
    t: 1581364800,
    v: 1.09141875
  },
  {
    t: 1581365100,
    v: 1.0914025
  },
  {
    t: 1581365400,
    v: 1.09137
  },
  {
    t: 1581365700,
    v: 1.0913399999999998
  },
  {
    t: 1581366000,
    v: 1.09130875
  },
  {
    t: 1581366300,
    v: 1.0912425
  },
  {
    t: 1581366600,
    v: 1.09118125
  },
  {
    t: 1581366900,
    v: 1.09113125
  },
  {
    t: 1581367200,
    v: 1.09107
  },
  {
    t: 1581367500,
    v: 1.0910449999999998
  },
  {
    t: 1581367800,
    v: 1.09104375
  },
  {
    t: 1581368100,
    v: 1.0910375
  },
  {
    t: 1581368400,
    v: 1.0910575
  },
  {
    t: 1581368700,
    v: 1.09109625
  },
  {
    t: 1581369000,
    v: 1.09112
  },
  {
    t: 1581369300,
    v: 1.0911399999999998
  },
  {
    t: 1581369600,
    v: 1.091165
  },
  {
    t: 1581369900,
    v: 1.0911475
  },
  {
    t: 1581370200,
    v: 1.0911225
  },
  {
    t: 1581370500,
    v: 1.0911050000000002
  },
  {
    t: 1581370800,
    v: 1.09107
  },
  {
    t: 1581371100,
    v: 1.0910475
  },
  {
    t: 1581371400,
    v: 1.0910362500000002
  },
  {
    t: 1581371700,
    v: 1.09102625
  },
  {
    t: 1581372000,
    v: 1.0910125000000002
  },
  {
    t: 1581372300,
    v: 1.09102
  },
  {
    t: 1581372600,
    v: 1.09103
  },
  {
    t: 1581372900,
    v: 1.0910275
  },
  {
    t: 1581373200,
    v: 1.0910325
  },
  {
    t: 1581373500,
    v: 1.091035
  },
  {
    t: 1581373800,
    v: 1.0910387499999998
  },
  {
    t: 1581374100,
    v: 1.0910375
  },
  {
    t: 1581374400,
    v: 1.0910687499999998
  },
  {
    t: 1581374700,
    v: 1.0910825
  },
  {
    t: 1581375000,
    v: 1.09108875
  },
  {
    t: 1581375300,
    v: 1.09108625
  },
  {
    t: 1581375600,
    v: 1.09109875
  },
  {
    t: 1581375900,
    v: 1.0911275
  },
  {
    t: 1581376200,
    v: 1.0911512499999998
  },
  {
    t: 1581376500,
    v: 1.09116875
  },
  {
    t: 1581376800,
    v: 1.0911462499999998
  },
  {
    t: 1581377100,
    v: 1.0911125
  },
  {
    t: 1581377400,
    v: 1.0910799999999998
  },
  {
    t: 1581377700,
    v: 1.0910625
  },
  {
    t: 1581378000,
    v: 1.09102625
  },
  {
    t: 1581378300,
    v: 1.09099625
  },
  {
    t: 1581378600,
    v: 1.09096875
  },
  {
    t: 1581378900,
    v: 1.0909475
  },
  {
    t: 1581379200,
    v: 1.09093625
  },
  {
    t: 1581379500,
    v: 1.0909399999999998
  },
  {
    t: 1581379800,
    v: 1.09092625
  },
  {
    t: 1581380100,
    v: 1.09091375
  },
  {
    t: 1581380400,
    v: 1.09091375
  },
  {
    t: 1581380700,
    v: 1.0908862499999998
  },
  {
    t: 1581381000,
    v: 1.09087625
  },
  {
    t: 1581381300,
    v: 1.090875
  },
  {
    t: 1581381600,
    v: 1.09088875
  },
  {
    t: 1581381900,
    v: 1.09089875
  },
  {
    t: 1581382200,
    v: 1.0909149999999999
  },
  {
    t: 1581382500,
    v: 1.09093875
  },
  {
    t: 1581382800,
    v: 1.09094875
  },
  {
    t: 1581383100,
    v: 1.0909725
  },
  {
    t: 1581383400,
    v: 1.0910062500000002
  },
  {
    t: 1581383700,
    v: 1.0910575
  },
  {
    t: 1581384000,
    v: 1.0910925
  },
  {
    t: 1581384300,
    v: 1.0911375
  },
  {
    t: 1581384600,
    v: 1.0911837500000001
  },
  {
    t: 1581384900,
    v: 1.09123125
  },
  {
    t: 1581385200,
    v: 1.0912775000000001
  },
  {
    t: 1581385500,
    v: 1.0913275
  },
  {
    t: 1581385800,
    v: 1.0913599999999999
  },
  {
    t: 1581386100,
    v: 1.0913575
  },
  {
    t: 1581386400,
    v: 1.091365
  },
  {
    t: 1581386700,
    v: 1.09135125
  },
  {
    t: 1581387000,
    v: 1.09134125
  },
  {
    t: 1581387300,
    v: 1.0913325
  },
  {
    t: 1581387600,
    v: 1.0913399999999998
  },
  {
    t: 1581387900,
    v: 1.0913487499999999
  },
  {
    t: 1581388200,
    v: 1.09135125
  },
  {
    t: 1581388500,
    v: 1.09137125
  },
  {
    t: 1581388800,
    v: 1.091375
  },
  {
    t: 1581389100,
    v: 1.09137375
  },
  {
    t: 1581389400,
    v: 1.0913712500000001
  },
  {
    t: 1581389700,
    v: 1.09138375
  },
  {
    t: 1581390000,
    v: 1.09137625
  },
  {
    t: 1581390300,
    v: 1.09137
  },
  {
    t: 1581390600,
    v: 1.09137375
  },
  {
    t: 1581390900,
    v: 1.09138
  },
  {
    t: 1581391200,
    v: 1.0914050000000002
  },
  {
    t: 1581391500,
    v: 1.09145
  },
  {
    t: 1581391800,
    v: 1.09149375
  },
  {
    t: 1581392100,
    v: 1.0914962499999998
  },
  {
    t: 1581392400,
    v: 1.0914949999999999
  },
  {
    t: 1581392700,
    v: 1.091475
  },
  {
    t: 1581393000,
    v: 1.0914375
  },
  {
    t: 1581393300,
    v: 1.091405
  },
  {
    t: 1581393600,
    v: 1.09135625
  },
  {
    t: 1581393900,
    v: 1.09132125
  },
  {
    t: 1581394200,
    v: 1.09129
  },
  {
    t: 1581394500,
    v: 1.0912725
  },
  {
    t: 1581394800,
    v: 1.09126625
  },
  {
    t: 1581395100,
    v: 1.0912462500000002
  },
  {
    t: 1581395400,
    v: 1.09123375
  },
  {
    t: 1581395700,
    v: 1.09121375
  },
  {
    t: 1581396000,
    v: 1.09119375
  },
  {
    t: 1581396300,
    v: 1.0911525
  },
  {
    t: 1581396600,
    v: 1.09110375
  },
  {
    t: 1581396900,
    v: 1.0910587500000002
  },
  {
    t: 1581397200,
    v: 1.09099375
  },
  {
    t: 1581397500,
    v: 1.0909537500000002
  },
  {
    t: 1581397800,
    v: 1.09091625
  },
  {
    t: 1581398100,
    v: 1.09090375
  },
  {
    t: 1581398400,
    v: 1.0909062500000002
  },
  {
    t: 1581398700,
    v: 1.09093375
  },
  {
    t: 1581399000,
    v: 1.09096375
  },
  {
    t: 1581399300,
    v: 1.0909775
  },
  {
    t: 1581399600,
    v: 1.09101625
  },
  {
    t: 1581399900,
    v: 1.09105
  },
  {
    t: 1581400200,
    v: 1.0910725000000001
  },
  {
    t: 1581400500,
    v: 1.09108875
  },
  {
    t: 1581400800,
    v: 1.0910799999999998
  },
  {
    t: 1581401100,
    v: 1.09103375
  },
  {
    t: 1581401400,
    v: 1.0910000000000002
  },
  {
    t: 1581401700,
    v: 1.09099375
  },
  {
    t: 1581402000,
    v: 1.0909737499999999
  },
  {
    t: 1581402300,
    v: 1.09094375
  },
  {
    t: 1581402600,
    v: 1.09093375
  },
  {
    t: 1581402900,
    v: 1.09090375
  },
  {
    t: 1581403200,
    v: 1.0909
  },
  {
    t: 1581403500,
    v: 1.090915
  },
  {
    t: 1581403800,
    v: 1.09093
  },
  {
    t: 1581404100,
    v: 1.0909425000000001
  },
  {
    t: 1581404400,
    v: 1.0909112500000002
  },
  {
    t: 1581404700,
    v: 1.09089125
  },
  {
    t: 1581405000,
    v: 1.0908562499999999
  },
  {
    t: 1581405300,
    v: 1.0908075
  },
  {
    t: 1581405600,
    v: 1.0907574999999998
  },
  {
    t: 1581405900,
    v: 1.090745
  },
  {
    t: 1581406200,
    v: 1.0907424999999997
  },
  {
    t: 1581406500,
    v: 1.0907475
  },
  {
    t: 1581406800,
    v: 1.0908037499999998
  },
  {
    t: 1581407100,
    v: 1.0908712499999997
  },
  {
    t: 1581407400,
    v: 1.0909475
  },
  {
    t: 1581407700,
    v: 1.09105125
  },
  {
    t: 1581408000,
    v: 1.09112125
  },
  {
    t: 1581408300,
    v: 1.091135
  },
  {
    t: 1581408600,
    v: 1.0911075
  },
  {
    t: 1581408900,
    v: 1.0910674999999999
  },
  {
    t: 1581409200,
    v: 1.091015
  },
  {
    t: 1581409500,
    v: 1.09097125
  },
  {
    t: 1581409800,
    v: 1.0909325
  },
  {
    t: 1581410100,
    v: 1.09085125
  },
  {
    t: 1581410400,
    v: 1.0908262499999999
  },
  {
    t: 1581410700,
    v: 1.09082125
  },
  {
    t: 1581411000,
    v: 1.09085625
  },
  {
    t: 1581411300,
    v: 1.0908875
  },
  {
    t: 1581411600,
    v: 1.0909312500000001
  },
  {
    t: 1581411900,
    v: 1.0909475000000002
  },
  {
    t: 1581412200,
    v: 1.09100875
  },
  {
    t: 1581412500,
    v: 1.0910712500000002
  },
  {
    t: 1581412800,
    v: 1.0911125000000002
  },
  {
    t: 1581413100,
    v: 1.0911475000000002
  },
  {
    t: 1581413400,
    v: 1.09118875
  },
  {
    t: 1581413700,
    v: 1.0912337499999998
  },
  {
    t: 1581414000,
    v: 1.0912312499999999
  },
  {
    t: 1581414300,
    v: 1.0912587500000002
  },
  {
    t: 1581414600,
    v: 1.0912525
  },
  {
    t: 1581414900,
    v: 1.09126375
  },
  {
    t: 1581415200,
    v: 1.0912475
  },
  {
    t: 1581415500,
    v: 1.0912625
  },
  {
    t: 1581415800,
    v: 1.09124625
  },
  {
    t: 1581416100,
    v: 1.09124
  },
  {
    t: 1581416400,
    v: 1.09127
  },
  {
    t: 1581416700,
    v: 1.09132125
  },
  {
    t: 1581417000,
    v: 1.09134125
  },
  {
    t: 1581417300,
    v: 1.0913799999999998
  },
  {
    t: 1581417600,
    v: 1.09145625
  },
  {
    t: 1581417900,
    v: 1.0914875
  },
  {
    t: 1581418200,
    v: 1.0915575
  },
  {
    t: 1581418500,
    v: 1.0916124999999999
  },
  {
    t: 1581418800,
    v: 1.0916925
  },
  {
    t: 1581419100,
    v: 1.09173375
  },
  {
    t: 1581419400,
    v: 1.0917649999999999
  },
  {
    t: 1581419700,
    v: 1.0917649999999999
  },
  {
    t: 1581420000,
    v: 1.0917387500000002
  },
  {
    t: 1581420300,
    v: 1.09173625
  },
  {
    t: 1581420600,
    v: 1.09171375
  },
  {
    t: 1581420900,
    v: 1.0916775
  },
  {
    t: 1581421200,
    v: 1.0916187499999999
  },
  {
    t: 1581421500,
    v: 1.091545
  },
  {
    t: 1581421800,
    v: 1.0915124999999999
  },
  {
    t: 1581422100,
    v: 1.0914624999999998
  },
  {
    t: 1581422400,
    v: 1.091435
  },
  {
    t: 1581422700,
    v: 1.091375
  },
  {
    t: 1581423000,
    v: 1.09134
  },
  {
    t: 1581423300,
    v: 1.09132875
  },
  {
    t: 1581423600,
    v: 1.0913199999999998
  },
  {
    t: 1581423900,
    v: 1.0913225
  },
  {
    t: 1581424200,
    v: 1.09131
  },
  {
    t: 1581424500,
    v: 1.09133875
  },
  {
    t: 1581424800,
    v: 1.09135125
  },
  {
    t: 1581425100,
    v: 1.091385
  },
  {
    t: 1581425400,
    v: 1.09136875
  },
  {
    t: 1581425700,
    v: 1.09130375
  },
  {
    t: 1581426000,
    v: 1.0911650000000002
  },
  {
    t: 1581426300,
    v: 1.091045
  },
  {
    t: 1581426600,
    v: 1.090875
  },
  {
    t: 1581426900,
    v: 1.09073125
  },
  {
    t: 1581427200,
    v: 1.09058875
  },
  {
    t: 1581427500,
    v: 1.09038375
  },
  {
    t: 1581427800,
    v: 1.090155
  },
  {
    t: 1581428100,
    v: 1.090065
  },
  {
    t: 1581428400,
    v: 1.09002
  },
  {
    t: 1581428700,
    v: 1.08993375
  },
  {
    t: 1581429000,
    v: 1.0899462500000001
  },
  {
    t: 1581429300,
    v: 1.08994625
  },
  {
    t: 1581429600,
    v: 1.08996375
  },
  {
    t: 1581429900,
    v: 1.09009
  },
  {
    t: 1581430200,
    v: 1.0902725
  },
  {
    t: 1581430500,
    v: 1.090415
  },
  {
    t: 1581430800,
    v: 1.09063125
  },
  {
    t: 1581431100,
    v: 1.09083125
  },
  {
    t: 1581431400,
    v: 1.090995
  },
  {
    t: 1581431700,
    v: 1.0911724999999999
  },
  {
    t: 1581432000,
    v: 1.0913262499999998
  },
  {
    t: 1581432300,
    v: 1.0914312499999999
  },
  {
    t: 1581432600,
    v: 1.09156625
  },
  {
    t: 1581432900,
    v: 1.09165125
  },
  {
    t: 1581433200,
    v: 1.09170625
  },
  {
    t: 1581433500,
    v: 1.0918425
  },
  {
    t: 1581433800,
    v: 1.09192625
  },
  {
    t: 1581434100,
    v: 1.0919675
  },
  {
    t: 1581434400,
    v: 1.0919875
  },
  {
    t: 1581434700,
    v: 1.0920162500000001
  },
  {
    t: 1581435000,
    v: 1.0919825
  },
  {
    t: 1581435300,
    v: 1.09191125
  },
  {
    t: 1581435600,
    v: 1.0918225
  },
  {
    t: 1581435900,
    v: 1.0917050000000001
  },
  {
    t: 1581436200,
    v: 1.0915812500000002
  },
  {
    t: 1581436500,
    v: 1.09151875
  },
  {
    t: 1581436800,
    v: 1.09150375
  },
  {
    t: 1581437100,
    v: 1.0914812500000002
  },
  {
    t: 1581437400,
    v: 1.091505
  },
  {
    t: 1581437700,
    v: 1.0915675
  },
  {
    t: 1581438000,
    v: 1.09162875
  },
  {
    t: 1581438300,
    v: 1.0916750000000002
  },
  {
    t: 1581438600,
    v: 1.091745
  },
  {
    t: 1581438900,
    v: 1.09176875
  },
  {
    t: 1581439200,
    v: 1.09173
  },
  {
    t: 1581439500,
    v: 1.091715
  },
  {
    t: 1581439800,
    v: 1.0916875000000001
  },
  {
    t: 1581440100,
    v: 1.09165125
  },
  {
    t: 1581440400,
    v: 1.09167
  },
  {
    t: 1581440700,
    v: 1.09172625
  },
  {
    t: 1581441000,
    v: 1.0917649999999999
  },
  {
    t: 1581441300,
    v: 1.09178625
  },
  {
    t: 1581441600,
    v: 1.0918362499999998
  },
  {
    t: 1581441900,
    v: 1.0919199999999998
  },
  {
    t: 1581442200,
    v: 1.0919875
  },
  {
    t: 1581442500,
    v: 1.0920425
  },
  {
    t: 1581442800,
    v: 1.0920725
  },
  {
    t: 1581443100,
    v: 1.0920725
  },
  {
    t: 1581443400,
    v: 1.09208
  },
  {
    t: 1581443700,
    v: 1.09210625
  },
  {
    t: 1581444000,
    v: 1.09211875
  },
  {
    t: 1581444300,
    v: 1.0920874999999999
  },
  {
    t: 1581444600,
    v: 1.09205125
  },
  {
    t: 1581444900,
    v: 1.09204
  },
  {
    t: 1581445200,
    v: 1.09200625
  },
  {
    t: 1581445500,
    v: 1.0919375000000002
  },
  {
    t: 1581445800,
    v: 1.0919050000000001
  },
  {
    t: 1581446100,
    v: 1.0918787500000002
  },
  {
    t: 1581446400,
    v: 1.091885
  },
  {
    t: 1581446700,
    v: 1.09188
  },
  {
    t: 1581447000,
    v: 1.09187
  },
  {
    t: 1581447300,
    v: 1.0918575000000001
  },
  {
    t: 1581447600,
    v: 1.09186125
  },
  {
    t: 1581447900,
    v: 1.09190375
  },
  {
    t: 1581448200,
    v: 1.09194625
  },
  {
    t: 1581448500,
    v: 1.0919625
  },
  {
    t: 1581448800,
    v: 1.0919925000000001
  },
  {
    t: 1581449100,
    v: 1.09203875
  },
  {
    t: 1581449400,
    v: 1.0920625
  },
  {
    t: 1581449700,
    v: 1.0920662499999998
  },
  {
    t: 1581450000,
    v: 1.0920737500000002
  },
  {
    t: 1581450300,
    v: 1.092075
  },
  {
    t: 1581450600,
    v: 1.0920675
  },
  {
    t: 1581450900,
    v: 1.0920775
  },
  {
    t: 1581451200,
    v: 1.09206625
  },
  {
    t: 1581451500,
    v: 1.0920325
  },
  {
    t: 1581451800,
    v: 1.09203125
  },
  {
    t: 1581452100,
    v: 1.0920424999999998
  },
  {
    t: 1581452400,
    v: 1.0920424999999998
  },
  {
    t: 1581452700,
    v: 1.092035
  },
  {
    t: 1581453000,
    v: 1.09201375
  },
  {
    t: 1581453300,
    v: 1.0919875
  },
  {
    t: 1581453600,
    v: 1.0919524999999999
  },
  {
    t: 1581453900,
    v: 1.0919575
  },
  {
    t: 1581454200,
    v: 1.09194375
  },
  {
    t: 1581454500,
    v: 1.0919337500000001
  },
  {
    t: 1581454800,
    v: 1.0919100000000002
  },
  {
    t: 1581455100,
    v: 1.09188
  },
  {
    t: 1581455400,
    v: 1.09186375
  },
  {
    t: 1581455700,
    v: 1.091845
  },
  {
    t: 1581456000,
    v: 1.0918375
  },
  {
    t: 1581456300,
    v: 1.09180875
  },
  {
    t: 1581456600,
    v: 1.09178625
  },
  {
    t: 1581456900,
    v: 1.0917725
  },
  {
    t: 1581457200,
    v: 1.0917549999999998
  },
  {
    t: 1581457500,
    v: 1.09175125
  },
  {
    t: 1581457800,
    v: 1.09173375
  },
  {
    t: 1581458100,
    v: 1.0916875
  },
  {
    t: 1581458400,
    v: 1.0916625
  },
  {
    t: 1581458700,
    v: 1.0916275
  },
  {
    t: 1581459000,
    v: 1.0916312499999998
  },
  {
    t: 1581459300,
    v: 1.09162875
  },
  {
    t: 1581459600,
    v: 1.0916375
  },
  {
    t: 1581459900,
    v: 1.0916137499999998
  },
  {
    t: 1581460200,
    v: 1.09160125
  },
  {
    t: 1581460500,
    v: 1.091625
  },
  {
    t: 1581460800,
    v: 1.0916575
  },
  {
    t: 1581461100,
    v: 1.0916937500000001
  },
  {
    t: 1581461400,
    v: 1.0917150000000002
  },
  {
    t: 1581461700,
    v: 1.09172375
  },
  {
    t: 1581462000,
    v: 1.0917275000000002
  },
  {
    t: 1581462300,
    v: 1.09176375
  },
  {
    t: 1581462600,
    v: 1.09179375
  },
  {
    t: 1581462900,
    v: 1.09182
  },
  {
    t: 1581463200,
    v: 1.09181625
  },
  {
    t: 1581463500,
    v: 1.091815
  },
  {
    t: 1581463800,
    v: 1.09180375
  },
  {
    t: 1581464100,
    v: 1.0918025
  },
  {
    t: 1581464400,
    v: 1.0918087499999998
  },
  {
    t: 1581464700,
    v: 1.09180875
  },
  {
    t: 1581465000,
    v: 1.091815
  },
  {
    t: 1581465300,
    v: 1.0918225000000001
  },
  {
    t: 1581465600,
    v: 1.0917925
  },
  {
    t: 1581465900,
    v: 1.09175375
  },
  {
    t: 1581466200,
    v: 1.09172125
  },
  {
    t: 1581466500,
    v: 1.0916787499999998
  },
  {
    t: 1581466800,
    v: 1.0916325
  },
  {
    t: 1581467100,
    v: 1.09157
  },
  {
    t: 1581467400,
    v: 1.09151625
  },
  {
    t: 1581467700,
    v: 1.0914575
  },
  {
    t: 1581468000,
    v: 1.09143875
  },
  {
    t: 1581468300,
    v: 1.09139875
  },
  {
    t: 1581468600,
    v: 1.0913525000000002
  },
  {
    t: 1581468900,
    v: 1.0913237500000001
  },
  {
    t: 1581469200,
    v: 1.0913425
  },
  {
    t: 1581469500,
    v: 1.0913650000000001
  },
  {
    t: 1581469800,
    v: 1.091385
  },
  {
    t: 1581470100,
    v: 1.09141125
  },
  {
    t: 1581470400,
    v: 1.0914375
  },
  {
    t: 1581470700,
    v: 1.09147875
  },
  {
    t: 1581471000,
    v: 1.09154375
  },
  {
    t: 1581471300,
    v: 1.0916025
  },
  {
    t: 1581471600,
    v: 1.09162125
  },
  {
    t: 1581471900,
    v: 1.09166625
  },
  {
    t: 1581472200,
    v: 1.0916975000000002
  },
  {
    t: 1581472500,
    v: 1.09172125
  },
  {
    t: 1581472800,
    v: 1.0917375000000002
  },
  {
    t: 1581473100,
    v: 1.0917475
  },
  {
    t: 1581473400,
    v: 1.0917225000000002
  },
  {
    t: 1581473700,
    v: 1.0917125
  },
  {
    t: 1581474000,
    v: 1.0916949999999999
  },
  {
    t: 1581474300,
    v: 1.09167
  },
  {
    t: 1581474600,
    v: 1.091675
  },
  {
    t: 1581474900,
    v: 1.09168
  },
  {
    t: 1581475200,
    v: 1.0916825
  },
  {
    t: 1581475500,
    v: 1.09169
  },
  {
    t: 1581475800,
    v: 1.09169625
  },
  {
    t: 1581476100,
    v: 1.09168375
  },
  {
    t: 1581476400,
    v: 1.09167625
  },
  {
    t: 1581476700,
    v: 1.09165625
  },
  {
    t: 1581477000,
    v: 1.09161625
  },
  {
    t: 1581477300,
    v: 1.0915862499999998
  },
  {
    t: 1581477600,
    v: 1.09158125
  },
  {
    t: 1581477900,
    v: 1.09158
  },
  {
    t: 1581478200,
    v: 1.091605
  },
  {
    t: 1581478500,
    v: 1.09162
  },
  {
    t: 1581478800,
    v: 1.091625
  },
  {
    t: 1581479100,
    v: 1.09163875
  },
  {
    t: 1581479400,
    v: 1.0916424999999998
  },
  {
    t: 1581479700,
    v: 1.0916287500000001
  },
  {
    t: 1581480000,
    v: 1.0916012499999999
  },
  {
    t: 1581480300,
    v: 1.09158375
  },
  {
    t: 1581480600,
    v: 1.0915537499999999
  },
  {
    t: 1581480900,
    v: 1.09152375
  },
  {
    t: 1581481200,
    v: 1.0914937500000002
  },
  {
    t: 1581481500,
    v: 1.0914575
  },
  {
    t: 1581481800,
    v: 1.0914075
  },
  {
    t: 1581482100,
    v: 1.09138375
  },
  {
    t: 1581482400,
    v: 1.091355
  },
  {
    t: 1581482700,
    v: 1.09132
  },
  {
    t: 1581483000,
    v: 1.0912875
  },
  {
    t: 1581483300,
    v: 1.09125625
  },
  {
    t: 1581483600,
    v: 1.09123375
  },
  {
    t: 1581483900,
    v: 1.0912137499999999
  },
  {
    t: 1581484200,
    v: 1.0912275
  },
  {
    t: 1581484500,
    v: 1.09121875
  },
  {
    t: 1581484800,
    v: 1.0912362500000001
  },
  {
    t: 1581485100,
    v: 1.09123875
  },
  {
    t: 1581485400,
    v: 1.09125
  },
  {
    t: 1581485700,
    v: 1.09126
  },
  {
    t: 1581486000,
    v: 1.0912537500000001
  },
  {
    t: 1581486300,
    v: 1.0912337500000002
  },
  {
    t: 1581486600,
    v: 1.091195
  },
  {
    t: 1581486900,
    v: 1.0911724999999999
  },
  {
    t: 1581487200,
    v: 1.0911137499999999
  },
  {
    t: 1581487500,
    v: 1.0910725000000001
  },
  {
    t: 1581487800,
    v: 1.091005
  },
  {
    t: 1581488100,
    v: 1.09095375
  },
  {
    t: 1581488400,
    v: 1.09091125
  },
  {
    t: 1581488700,
    v: 1.0908825000000002
  },
  {
    t: 1581489000,
    v: 1.09086875
  },
  {
    t: 1581489300,
    v: 1.0908425
  },
  {
    t: 1581489600,
    v: 1.0908275
  },
  {
    t: 1581489900,
    v: 1.0908025000000001
  },
  {
    t: 1581490200,
    v: 1.0907787500000001
  },
  {
    t: 1581490500,
    v: 1.09073875
  },
  {
    t: 1581490800,
    v: 1.0907
  },
  {
    t: 1581491100,
    v: 1.09062875
  },
  {
    t: 1581491400,
    v: 1.09055
  },
  {
    t: 1581491700,
    v: 1.0905162499999999
  },
  {
    t: 1581492000,
    v: 1.090475
  },
  {
    t: 1581492300,
    v: 1.09045125
  },
  {
    t: 1581492600,
    v: 1.09052625
  },
  {
    t: 1581492900,
    v: 1.09061125
  },
  {
    t: 1581493200,
    v: 1.09070625
  },
  {
    t: 1581493500,
    v: 1.0908187500000002
  },
  {
    t: 1581493800,
    v: 1.090915
  },
  {
    t: 1581494100,
    v: 1.09102375
  },
  {
    t: 1581494400,
    v: 1.091125
  },
  {
    t: 1581494700,
    v: 1.09125875
  },
  {
    t: 1581495000,
    v: 1.0913125
  },
  {
    t: 1581495300,
    v: 1.0913662499999999
  },
  {
    t: 1581495600,
    v: 1.09143875
  },
  {
    t: 1581495900,
    v: 1.0915574999999997
  },
  {
    t: 1581496200,
    v: 1.091715
  },
  {
    t: 1581496500,
    v: 1.091845
  },
  {
    t: 1581496800,
    v: 1.09193
  },
  {
    t: 1581497100,
    v: 1.0919937499999999
  },
  {
    t: 1581497400,
    v: 1.0920575000000001
  },
  {
    t: 1581497700,
    v: 1.0921162500000001
  },
  {
    t: 1581498000,
    v: 1.0921325000000002
  },
  {
    t: 1581498300,
    v: 1.09212375
  },
  {
    t: 1581498600,
    v: 1.0921025
  },
  {
    t: 1581498900,
    v: 1.0920275
  },
  {
    t: 1581499200,
    v: 1.09199375
  },
  {
    t: 1581499500,
    v: 1.0919287500000001
  },
  {
    t: 1581499800,
    v: 1.09181375
  },
  {
    t: 1581500100,
    v: 1.09172375
  },
  {
    t: 1581500400,
    v: 1.09167875
  },
  {
    t: 1581500700,
    v: 1.0916475
  },
  {
    t: 1581501000,
    v: 1.0916062500000001
  },
  {
    t: 1581501300,
    v: 1.09162
  },
  {
    t: 1581501600,
    v: 1.09164125
  },
  {
    t: 1581501900,
    v: 1.0916787499999998
  },
  {
    t: 1581502200,
    v: 1.0917774999999998
  },
  {
    t: 1581502500,
    v: 1.09185125
  },
  {
    t: 1581502800,
    v: 1.0919025
  },
  {
    t: 1581503100,
    v: 1.0919225
  },
  {
    t: 1581503400,
    v: 1.0919137499999998
  },
  {
    t: 1581503700,
    v: 1.0918787499999998
  },
  {
    t: 1581504000,
    v: 1.0918675
  },
  {
    t: 1581504300,
    v: 1.0918374999999998
  },
  {
    t: 1581504600,
    v: 1.09180125
  },
  {
    t: 1581504900,
    v: 1.091775
  },
  {
    t: 1581505200,
    v: 1.09169375
  },
  {
    t: 1581505500,
    v: 1.0916312499999998
  },
  {
    t: 1581505800,
    v: 1.09161375
  },
  {
    t: 1581506100,
    v: 1.091585
  },
  {
    t: 1581506400,
    v: 1.09155
  },
  {
    t: 1581506700,
    v: 1.09153125
  },
  {
    t: 1581507000,
    v: 1.09148625
  },
  {
    t: 1581507300,
    v: 1.0914125000000001
  },
  {
    t: 1581507600,
    v: 1.0913837499999999
  },
  {
    t: 1581507900,
    v: 1.09133375
  },
  {
    t: 1581508200,
    v: 1.0912950000000001
  },
  {
    t: 1581508500,
    v: 1.091255
  },
  {
    t: 1581508800,
    v: 1.09121375
  },
  {
    t: 1581509100,
    v: 1.0911775
  },
  {
    t: 1581509400,
    v: 1.09118125
  },
  {
    t: 1581509700,
    v: 1.0911925
  },
  {
    t: 1581510000,
    v: 1.09120375
  },
  {
    t: 1581510300,
    v: 1.0912175
  },
  {
    t: 1581510600,
    v: 1.09118875
  },
  {
    t: 1581510900,
    v: 1.0911875
  },
  {
    t: 1581511200,
    v: 1.09117875
  },
  {
    t: 1581511500,
    v: 1.09116875
  },
  {
    t: 1581511800,
    v: 1.091145
  },
  {
    t: 1581512100,
    v: 1.09113375
  },
  {
    t: 1581512400,
    v: 1.09113125
  },
  {
    t: 1581512700,
    v: 1.0911375
  },
  {
    t: 1581513000,
    v: 1.09117375
  },
  {
    t: 1581513300,
    v: 1.0911674999999998
  },
  {
    t: 1581513600,
    v: 1.0911862499999998
  },
  {
    t: 1581513900,
    v: 1.0911775
  },
  {
    t: 1581514200,
    v: 1.09118375
  },
  {
    t: 1581514500,
    v: 1.0911899999999999
  },
  {
    t: 1581514800,
    v: 1.0911825000000002
  },
  {
    t: 1581515100,
    v: 1.0911825
  },
  {
    t: 1581515400,
    v: 1.0911437499999999
  },
  {
    t: 1581515700,
    v: 1.091065
  },
  {
    t: 1581516000,
    v: 1.0909287500000002
  },
  {
    t: 1581516300,
    v: 1.0908625
  },
  {
    t: 1581516600,
    v: 1.0907775
  },
  {
    t: 1581516900,
    v: 1.09068375
  },
  {
    t: 1581517200,
    v: 1.0906325
  },
  {
    t: 1581517500,
    v: 1.09060875
  },
  {
    t: 1581517800,
    v: 1.09056625
  },
  {
    t: 1581518100,
    v: 1.0905624999999999
  },
  {
    t: 1581518400,
    v: 1.0905974999999999
  },
  {
    t: 1581518700,
    v: 1.0905637499999998
  },
  {
    t: 1581519000,
    v: 1.0905349999999998
  },
  {
    t: 1581519300,
    v: 1.0905187499999998
  },
  {
    t: 1581519600,
    v: 1.0903512499999999
  },
  {
    t: 1581519900,
    v: 1.09013875
  },
  {
    t: 1581520200,
    v: 1.0899699999999999
  },
  {
    t: 1581520500,
    v: 1.08976125
  },
  {
    t: 1581520800,
    v: 1.08956375
  },
  {
    t: 1581521100,
    v: 1.08940125
  },
  {
    t: 1581521400,
    v: 1.0892849999999998
  },
  {
    t: 1581521700,
    v: 1.08911875
  },
  {
    t: 1581522000,
    v: 1.089105
  },
  {
    t: 1581522300,
    v: 1.08911625
  },
  {
    t: 1581522600,
    v: 1.089035
  },
  {
    t: 1581522900,
    v: 1.0891125
  },
  {
    t: 1581523200,
    v: 1.0892075
  },
  {
    t: 1581523500,
    v: 1.0892412500000002
  },
  {
    t: 1581523800,
    v: 1.08922
  },
  {
    t: 1581524100,
    v: 1.0892725
  },
  {
    t: 1581524400,
    v: 1.0893074999999999
  },
  {
    t: 1581524700,
    v: 1.0893074999999999
  },
  {
    t: 1581525000,
    v: 1.08940375
  },
  {
    t: 1581525300,
    v: 1.0893675
  },
  {
    t: 1581525600,
    v: 1.0893087499999998
  },
  {
    t: 1581525900,
    v: 1.08928125
  },
  {
    t: 1581526200,
    v: 1.0892625
  },
  {
    t: 1581526500,
    v: 1.08923
  },
  {
    t: 1581526800,
    v: 1.08915
  },
  {
    t: 1581527100,
    v: 1.0890662500000001
  },
  {
    t: 1581527400,
    v: 1.08888
  },
  {
    t: 1581527700,
    v: 1.0887425
  },
  {
    t: 1581528000,
    v: 1.088625
  },
  {
    t: 1581528300,
    v: 1.0884999999999998
  },
  {
    t: 1581528600,
    v: 1.08834625
  },
  {
    t: 1581528900,
    v: 1.08817625
  },
  {
    t: 1581529200,
    v: 1.08801625
  },
  {
    t: 1581529500,
    v: 1.08791
  },
  {
    t: 1581529800,
    v: 1.0879125
  },
  {
    t: 1581530100,
    v: 1.08789375
  },
  {
    t: 1581530400,
    v: 1.08784625
  },
  {
    t: 1581530700,
    v: 1.08782
  },
  {
    t: 1581531000,
    v: 1.08778625
  },
  {
    t: 1581531300,
    v: 1.0877875
  },
  {
    t: 1581531600,
    v: 1.0878375
  },
  {
    t: 1581531900,
    v: 1.08783625
  },
  {
    t: 1581532200,
    v: 1.08781375
  },
  {
    t: 1581532500,
    v: 1.0878
  },
  {
    t: 1581532800,
    v: 1.0877912500000002
  },
  {
    t: 1581533100,
    v: 1.0877775
  },
  {
    t: 1581533400,
    v: 1.08779375
  },
  {
    t: 1581533700,
    v: 1.0877649999999999
  },
  {
    t: 1581534000,
    v: 1.08772125
  },
  {
    t: 1581534300,
    v: 1.08769875
  },
  {
    t: 1581534600,
    v: 1.08767
  },
  {
    t: 1581534900,
    v: 1.0875887499999999
  },
  {
    t: 1581535200,
    v: 1.0874650000000001
  },
  {
    t: 1581535500,
    v: 1.08735125
  },
  {
    t: 1581535800,
    v: 1.0872325000000003
  },
  {
    t: 1581536100,
    v: 1.0871462500000002
  },
  {
    t: 1581536400,
    v: 1.08704375
  },
  {
    t: 1581536700,
    v: 1.086935
  },
  {
    t: 1581537000,
    v: 1.0868375000000001
  },
  {
    t: 1581537300,
    v: 1.0867775000000002
  },
  {
    t: 1581537600,
    v: 1.0867875
  },
  {
    t: 1581537900,
    v: 1.0868
  },
  {
    t: 1581538200,
    v: 1.08682375
  },
  {
    t: 1581538500,
    v: 1.08684125
  },
  {
    t: 1581538800,
    v: 1.0868637499999998
  },
  {
    t: 1581539100,
    v: 1.0868950000000002
  },
  {
    t: 1581539400,
    v: 1.08693625
  },
  {
    t: 1581539700,
    v: 1.08701125
  },
  {
    t: 1581540000,
    v: 1.08705875
  },
  {
    t: 1581540300,
    v: 1.0871137499999999
  },
  {
    t: 1581540600,
    v: 1.0871662500000001
  },
  {
    t: 1581540900,
    v: 1.0872199999999999
  },
  {
    t: 1581541200,
    v: 1.08726875
  },
  {
    t: 1581541500,
    v: 1.0873125000000001
  },
  {
    t: 1581541800,
    v: 1.08734375
  },
  {
    t: 1581542100,
    v: 1.0873612499999998
  },
  {
    t: 1581542400,
    v: 1.08738375
  },
  {
    t: 1581542700,
    v: 1.0873887500000001
  },
  {
    t: 1581543000,
    v: 1.0873737499999998
  },
  {
    t: 1581543300,
    v: 1.08733875
  },
  {
    t: 1581543600,
    v: 1.0873137499999999
  },
  {
    t: 1581543900,
    v: 1.08728125
  },
  {
    t: 1581544200,
    v: 1.08725375
  },
  {
    t: 1581544500,
    v: 1.0872425
  },
  {
    t: 1581544800,
    v: 1.08723375
  },
  {
    t: 1581545100,
    v: 1.0872275
  },
  {
    t: 1581545400,
    v: 1.0872325
  },
  {
    t: 1581545700,
    v: 1.0872362500000001
  },
  {
    t: 1581546000,
    v: 1.08724125
  },
  {
    t: 1581546300,
    v: 1.08726625
  },
  {
    t: 1581546600,
    v: 1.0872825000000002
  },
  {
    t: 1581546900,
    v: 1.08728375
  },
  {
    t: 1581547200,
    v: 1.08726125
  },
  {
    t: 1581547500,
    v: 1.08724375
  },
  {
    t: 1581547800,
    v: 1.0872199999999999
  },
  {
    t: 1581548100,
    v: 1.0872074999999999
  },
  {
    t: 1581548400,
    v: 1.08718625
  },
  {
    t: 1581548700,
    v: 1.08716125
  },
  {
    t: 1581549000,
    v: 1.08714625
  },
  {
    t: 1581549300,
    v: 1.0871262499999998
  },
  {
    t: 1581549600,
    v: 1.0871274999999998
  },
  {
    t: 1581549900,
    v: 1.08712875
  },
  {
    t: 1581550200,
    v: 1.0871187500000001
  },
  {
    t: 1581550500,
    v: 1.08710875
  },
  {
    t: 1581550800,
    v: 1.087095
  },
  {
    t: 1581551100,
    v: 1.08709125
  },
  {
    t: 1581551400,
    v: 1.08712
  },
  {
    t: 1581551700,
    v: 1.0871725
  },
  {
    t: 1581552000,
    v: 1.0871925
  },
  {
    t: 1581552300,
    v: 1.0872325
  },
  {
    t: 1581552600,
    v: 1.08725375
  },
  {
    t: 1581552900,
    v: 1.0872674999999998
  },
  {
    t: 1581553200,
    v: 1.0872875
  },
  {
    t: 1581553500,
    v: 1.0873137499999999
  },
  {
    t: 1581553800,
    v: 1.0873137499999999
  },
  {
    t: 1581554100,
    v: 1.0872950000000001
  },
  {
    t: 1581554400,
    v: 1.087285
  },
  {
    t: 1581554700,
    v: 1.0872525
  },
  {
    t: 1581555000,
    v: 1.08725375
  },
  {
    t: 1581555300,
    v: 1.0872600000000001
  },
  {
    t: 1581555600,
    v: 1.087275
  },
  {
    t: 1581555900,
    v: 1.087275
  },
  {
    t: 1581556200,
    v: 1.087275
  },
  {
    t: 1581556500,
    v: 1.087265
  },
  {
    t: 1581556800,
    v: 1.08727625
  },
  {
    t: 1581557100,
    v: 1.087275
  },
  {
    t: 1581557400,
    v: 1.08726375
  },
  {
    t: 1581557700,
    v: 1.0872525000000002
  },
  {
    t: 1581558000,
    v: 1.0872362500000001
  },
  {
    t: 1581558300,
    v: 1.0872275
  },
  {
    t: 1581558600,
    v: 1.08721375
  },
  {
    t: 1581558900,
    v: 1.08720875
  },
  {
    t: 1581559200,
    v: 1.08716875
  },
  {
    t: 1581559500,
    v: 1.08715375
  },
  {
    t: 1581559800,
    v: 1.08716
  },
  {
    t: 1581560100,
    v: 1.0871825
  },
  {
    t: 1581560400,
    v: 1.0871875
  },
  {
    t: 1581560700,
    v: 1.08718
  },
  {
    t: 1581561000,
    v: 1.0871724999999999
  },
  {
    t: 1581561300,
    v: 1.0871650000000002
  },
  {
    t: 1581561600,
    v: 1.08717875
  },
  {
    t: 1581561900,
    v: 1.0871875000000002
  },
  {
    t: 1581562200,
    v: 1.0871887500000001
  },
  {
    t: 1581562500,
    v: 1.08714375
  },
  {
    t: 1581562800,
    v: 1.0870925
  },
  {
    t: 1581563100,
    v: 1.0870437499999999
  },
  {
    t: 1581563400,
    v: 1.08699875
  },
  {
    t: 1581563700,
    v: 1.08696875
  },
  {
    t: 1581564000,
    v: 1.0869425
  },
  {
    t: 1581564300,
    v: 1.0869125
  },
  {
    t: 1581564600,
    v: 1.08688375
  },
  {
    t: 1581564900,
    v: 1.08689125
  },
  {
    t: 1581565200,
    v: 1.0869125
  },
  {
    t: 1581565500,
    v: 1.08693125
  },
  {
    t: 1581565800,
    v: 1.0869324999999999
  },
  {
    t: 1581566100,
    v: 1.0869337499999998
  },
  {
    t: 1581566400,
    v: 1.0869399999999998
  },
  {
    t: 1581566700,
    v: 1.086945
  },
  {
    t: 1581567000,
    v: 1.08694
  },
  {
    t: 1581567300,
    v: 1.08691625
  },
  {
    t: 1581567600,
    v: 1.0869024999999999
  },
  {
    t: 1581567900,
    v: 1.086885
  },
  {
    t: 1581568200,
    v: 1.08687875
  },
  {
    t: 1581568500,
    v: 1.08685125
  },
  {
    t: 1581568800,
    v: 1.0868262499999999
  },
  {
    t: 1581569100,
    v: 1.08678875
  },
  {
    t: 1581569400,
    v: 1.0867525
  },
  {
    t: 1581569700,
    v: 1.08675875
  },
  {
    t: 1581570000,
    v: 1.08676125
  },
  {
    t: 1581570300,
    v: 1.08678125
  },
  {
    t: 1581570600,
    v: 1.08679
  },
  {
    t: 1581570900,
    v: 1.08681
  },
  {
    t: 1581571200,
    v: 1.0868375000000001
  },
  {
    t: 1581571500,
    v: 1.08685375
  },
  {
    t: 1581571800,
    v: 1.0868925
  },
  {
    t: 1581572100,
    v: 1.0869125000000002
  },
  {
    t: 1581572400,
    v: 1.0869312500000001
  },
  {
    t: 1581572700,
    v: 1.086935
  },
  {
    t: 1581573000,
    v: 1.0869575
  },
  {
    t: 1581573300,
    v: 1.08698375
  },
  {
    t: 1581573600,
    v: 1.0870037499999998
  },
  {
    t: 1581573900,
    v: 1.08702875
  },
  {
    t: 1581574200,
    v: 1.0870475000000002
  },
  {
    t: 1581574500,
    v: 1.08706625
  },
  {
    t: 1581574800,
    v: 1.0870825
  },
  {
    t: 1581575100,
    v: 1.0871024999999999
  },
  {
    t: 1581575400,
    v: 1.08711375
  },
  {
    t: 1581575700,
    v: 1.0871075
  },
  {
    t: 1581576000,
    v: 1.0870825
  },
  {
    t: 1581576300,
    v: 1.0870875
  },
  {
    t: 1581576600,
    v: 1.0870875
  },
  {
    t: 1581576900,
    v: 1.0870787499999999
  },
  {
    t: 1581577200,
    v: 1.0870975
  },
  {
    t: 1581577500,
    v: 1.0871287499999998
  },
  {
    t: 1581577800,
    v: 1.0871562499999998
  },
  {
    t: 1581578100,
    v: 1.0872
  },
  {
    t: 1581578400,
    v: 1.0873050000000002
  },
  {
    t: 1581578700,
    v: 1.08737375
  },
  {
    t: 1581579000,
    v: 1.0874424999999999
  },
  {
    t: 1581579300,
    v: 1.08751
  },
  {
    t: 1581579600,
    v: 1.0875499999999998
  },
  {
    t: 1581579900,
    v: 1.08759
  },
  {
    t: 1581580200,
    v: 1.0876350000000001
  },
  {
    t: 1581580500,
    v: 1.08769375
  },
  {
    t: 1581580800,
    v: 1.08771125
  },
  {
    t: 1581581100,
    v: 1.08776
  },
  {
    t: 1581581400,
    v: 1.08780875
  },
  {
    t: 1581581700,
    v: 1.0878474999999999
  },
  {
    t: 1581582000,
    v: 1.087895
  },
  {
    t: 1581582300,
    v: 1.0879325
  },
  {
    t: 1581582600,
    v: 1.0880025
  },
  {
    t: 1581582900,
    v: 1.08807375
  },
  {
    t: 1581583200,
    v: 1.088145
  },
  {
    t: 1581583500,
    v: 1.088225
  },
  {
    t: 1581583800,
    v: 1.08830125
  },
  {
    t: 1581584100,
    v: 1.0883875
  },
  {
    t: 1581584400,
    v: 1.0884749999999999
  },
  {
    t: 1581584700,
    v: 1.0885449999999999
  },
  {
    t: 1581585000,
    v: 1.08857
  },
  {
    t: 1581585300,
    v: 1.0885449999999999
  },
  {
    t: 1581585600,
    v: 1.0885325
  },
  {
    t: 1581585900,
    v: 1.0885212499999999
  },
  {
    t: 1581586200,
    v: 1.08850875
  },
  {
    t: 1581586500,
    v: 1.088475
  },
  {
    t: 1581586800,
    v: 1.08844125
  },
  {
    t: 1581587100,
    v: 1.0884075
  },
  {
    t: 1581587400,
    v: 1.0884125
  },
  {
    t: 1581587700,
    v: 1.08841125
  },
  {
    t: 1581588000,
    v: 1.08837625
  },
  {
    t: 1581588300,
    v: 1.088325
  },
  {
    t: 1581588600,
    v: 1.0882725
  },
  {
    t: 1581588900,
    v: 1.08820875
  },
  {
    t: 1581589200,
    v: 1.08817375
  },
  {
    t: 1581589500,
    v: 1.0881649999999998
  },
  {
    t: 1581589800,
    v: 1.08813375
  },
  {
    t: 1581590100,
    v: 1.08817
  },
  {
    t: 1581590400,
    v: 1.0881925
  },
  {
    t: 1581590700,
    v: 1.08818625
  },
  {
    t: 1581591000,
    v: 1.08817125
  },
  {
    t: 1581591300,
    v: 1.08810875
  },
  {
    t: 1581591600,
    v: 1.087945
  },
  {
    t: 1581591900,
    v: 1.0877750000000002
  },
  {
    t: 1581592200,
    v: 1.08761625
  },
  {
    t: 1581592500,
    v: 1.0874525000000002
  },
  {
    t: 1581592800,
    v: 1.08724
  },
  {
    t: 1581593100,
    v: 1.0870700000000002
  },
  {
    t: 1581593400,
    v: 1.08694625
  },
  {
    t: 1581593700,
    v: 1.08688125
  },
  {
    t: 1581594000,
    v: 1.08686125
  },
  {
    t: 1581594300,
    v: 1.08683875
  },
  {
    t: 1581594600,
    v: 1.08680125
  },
  {
    t: 1581594900,
    v: 1.0866149999999999
  },
  {
    t: 1581595200,
    v: 1.08649625
  },
  {
    t: 1581595500,
    v: 1.0863425
  },
  {
    t: 1581595800,
    v: 1.0861550000000002
  },
  {
    t: 1581596100,
    v: 1.08600625
  },
  {
    t: 1581596400,
    v: 1.085855
  },
  {
    t: 1581596700,
    v: 1.08573125
  },
  {
    t: 1581597000,
    v: 1.0856275000000002
  },
  {
    t: 1581597300,
    v: 1.085665
  },
  {
    t: 1581597600,
    v: 1.0857937500000001
  },
  {
    t: 1581597900,
    v: 1.08594125
  },
  {
    t: 1581598200,
    v: 1.0860800000000002
  },
  {
    t: 1581598500,
    v: 1.086165
  },
  {
    t: 1581598800,
    v: 1.08627625
  },
  {
    t: 1581599100,
    v: 1.08633375
  },
  {
    t: 1581599400,
    v: 1.0864275
  },
  {
    t: 1581599700,
    v: 1.08652375
  },
  {
    t: 1581600000,
    v: 1.086475
  },
  {
    t: 1581600300,
    v: 1.08640375
  },
  {
    t: 1581600600,
    v: 1.08628875
  },
  {
    t: 1581600900,
    v: 1.08621625
  },
  {
    t: 1581601200,
    v: 1.08612125
  },
  {
    t: 1581601500,
    v: 1.0860400000000001
  },
  {
    t: 1581601800,
    v: 1.0859037500000002
  },
  {
    t: 1581602100,
    v: 1.0857337500000002
  },
  {
    t: 1581602400,
    v: 1.08560625
  },
  {
    t: 1581602700,
    v: 1.0854762500000001
  },
  {
    t: 1581603000,
    v: 1.08538
  },
  {
    t: 1581603300,
    v: 1.0853
  },
  {
    t: 1581603600,
    v: 1.08525125
  },
  {
    t: 1581603900,
    v: 1.0852249999999999
  },
  {
    t: 1581604200,
    v: 1.0852362500000001
  },
  {
    t: 1581604500,
    v: 1.0852975
  },
  {
    t: 1581604800,
    v: 1.0853350000000002
  },
  {
    t: 1581605100,
    v: 1.0853987500000002
  },
  {
    t: 1581605400,
    v: 1.0854575000000002
  },
  {
    t: 1581605700,
    v: 1.085505
  },
  {
    t: 1581606000,
    v: 1.0855325
  },
  {
    t: 1581606300,
    v: 1.0854312499999998
  },
  {
    t: 1581606600,
    v: 1.08530375
  },
  {
    t: 1581606900,
    v: 1.0850975
  },
  {
    t: 1581607200,
    v: 1.0848399999999998
  },
  {
    t: 1581607500,
    v: 1.08461375
  },
  {
    t: 1581607800,
    v: 1.08443
  },
  {
    t: 1581608100,
    v: 1.08429375
  },
  {
    t: 1581608400,
    v: 1.084155
  },
  {
    t: 1581608700,
    v: 1.0841725
  },
  {
    t: 1581609000,
    v: 1.08413125
  },
  {
    t: 1581609300,
    v: 1.08419625
  },
  {
    t: 1581609600,
    v: 1.0844
  },
  {
    t: 1581609900,
    v: 1.0845799999999999
  },
  {
    t: 1581610200,
    v: 1.08471125
  },
  {
    t: 1581610500,
    v: 1.08479625
  },
  {
    t: 1581610800,
    v: 1.08489
  },
  {
    t: 1581611100,
    v: 1.084955
  },
  {
    t: 1581611400,
    v: 1.0850425
  },
  {
    t: 1581611700,
    v: 1.08507625
  },
  {
    t: 1581612000,
    v: 1.08505625
  },
  {
    t: 1581612300,
    v: 1.08501125
  },
  {
    t: 1581612600,
    v: 1.08500125
  },
  {
    t: 1581612900,
    v: 1.0849525
  },
  {
    t: 1581613200,
    v: 1.08489625
  },
  {
    t: 1581613500,
    v: 1.0848
  },
  {
    t: 1581613800,
    v: 1.0846974999999999
  },
  {
    t: 1581614100,
    v: 1.08458875
  },
  {
    t: 1581614400,
    v: 1.0845150000000001
  },
  {
    t: 1581614700,
    v: 1.08443125
  },
  {
    t: 1581615000,
    v: 1.0843475
  },
  {
    t: 1581615300,
    v: 1.0842637499999999
  },
  {
    t: 1581615600,
    v: 1.08419625
  },
  {
    t: 1581615900,
    v: 1.08415375
  },
  {
    t: 1581616200,
    v: 1.0841525
  },
  {
    t: 1581616500,
    v: 1.0841525
  },
  {
    t: 1581616800,
    v: 1.08412
  },
  {
    t: 1581617100,
    v: 1.0841375
  },
  {
    t: 1581617400,
    v: 1.08413125
  },
  {
    t: 1581617700,
    v: 1.08413125
  },
  {
    t: 1581618000,
    v: 1.08411625
  },
  {
    t: 1581618300,
    v: 1.08411375
  },
  {
    t: 1581618600,
    v: 1.08410375
  },
  {
    t: 1581618900,
    v: 1.084095
  },
  {
    t: 1581619200,
    v: 1.08410875
  },
  {
    t: 1581619500,
    v: 1.084095
  },
  {
    t: 1581619800,
    v: 1.08408875
  },
  {
    t: 1581620100,
    v: 1.084105
  },
  {
    t: 1581620400,
    v: 1.084125
  },
  {
    t: 1581620700,
    v: 1.08412625
  },
  {
    t: 1581621000,
    v: 1.0841375
  },
  {
    t: 1581621300,
    v: 1.084155
  },
  {
    t: 1581621600,
    v: 1.08415875
  },
  {
    t: 1581621900,
    v: 1.08415375
  },
  {
    t: 1581622200,
    v: 1.0841762499999998
  },
  {
    t: 1581622500,
    v: 1.084185
  },
  {
    t: 1581622800,
    v: 1.0841925
  },
  {
    t: 1581623100,
    v: 1.08421875
  },
  {
    t: 1581623400,
    v: 1.0842312500000002
  },
  {
    t: 1581623700,
    v: 1.08423375
  },
  {
    t: 1581624000,
    v: 1.0842450000000001
  },
  {
    t: 1581624300,
    v: 1.08426375
  },
  {
    t: 1581624600,
    v: 1.0842625
  },
  {
    t: 1581624900,
    v: 1.0842675
  },
  {
    t: 1581625200,
    v: 1.0842675
  },
  {
    t: 1581625500,
    v: 1.08425625
  },
  {
    t: 1581625800,
    v: 1.0842450000000001
  },
  {
    t: 1581626100,
    v: 1.0842287499999999
  },
  {
    t: 1581626400,
    v: 1.08420875
  },
  {
    t: 1581626700,
    v: 1.08420125
  },
  {
    t: 1581627000,
    v: 1.08417875
  },
  {
    t: 1581627300,
    v: 1.0841575
  },
  {
    t: 1581627600,
    v: 1.08414
  },
  {
    t: 1581627900,
    v: 1.084115
  },
  {
    t: 1581628200,
    v: 1.0840812499999999
  },
  {
    t: 1581628500,
    v: 1.084045
  },
  {
    t: 1581628800,
    v: 1.083995
  },
  {
    t: 1581629100,
    v: 1.0839525
  },
  {
    t: 1581629400,
    v: 1.08393375
  },
  {
    t: 1581629700,
    v: 1.08391875
  },
  {
    t: 1581630000,
    v: 1.08391625
  },
  {
    t: 1581630300,
    v: 1.08391625
  },
  {
    t: 1581630600,
    v: 1.0839237499999999
  },
  {
    t: 1581630900,
    v: 1.0839474999999998
  },
  {
    t: 1581631200,
    v: 1.0839725
  },
  {
    t: 1581631500,
    v: 1.0839925
  },
  {
    t: 1581631800,
    v: 1.0839775
  },
  {
    t: 1581632100,
    v: 1.08396625
  },
  {
    t: 1581632400,
    v: 1.08394
  },
  {
    t: 1581632700,
    v: 1.0839149999999997
  },
  {
    t: 1581633000,
    v: 1.0839025
  },
  {
    t: 1581633300,
    v: 1.0838762499999999
  },
  {
    t: 1581633600,
    v: 1.0838587499999999
  },
  {
    t: 1581633900,
    v: 1.0838450000000002
  },
  {
    t: 1581634200,
    v: 1.0838475
  },
  {
    t: 1581634500,
    v: 1.08385625
  },
  {
    t: 1581634800,
    v: 1.08387125
  },
  {
    t: 1581635100,
    v: 1.0838774999999998
  },
  {
    t: 1581635400,
    v: 1.08387875
  },
  {
    t: 1581635700,
    v: 1.0838875
  },
  {
    t: 1581636000,
    v: 1.0838850000000002
  },
  {
    t: 1581636300,
    v: 1.0838824999999999
  },
  {
    t: 1581636600,
    v: 1.08388875
  },
  {
    t: 1581636900,
    v: 1.0838812500000001
  },
  {
    t: 1581637200,
    v: 1.0838862500000002
  },
  {
    t: 1581637500,
    v: 1.0839124999999998
  },
  {
    t: 1581637800,
    v: 1.0839450000000002
  },
  {
    t: 1581638100,
    v: 1.083965
  },
  {
    t: 1581638400,
    v: 1.0839450000000002
  },
  {
    t: 1581638700,
    v: 1.08391
  },
  {
    t: 1581639000,
    v: 1.08381875
  },
  {
    t: 1581639300,
    v: 1.08375625
  },
  {
    t: 1581639600,
    v: 1.0836812500000002
  },
  {
    t: 1581639900,
    v: 1.08357875
  },
  {
    t: 1581640200,
    v: 1.0834775
  },
  {
    t: 1581640500,
    v: 1.0834025
  },
  {
    t: 1581640800,
    v: 1.0833825
  },
  {
    t: 1581641100,
    v: 1.08338125
  },
  {
    t: 1581641400,
    v: 1.08344
  },
  {
    t: 1581641700,
    v: 1.08348625
  },
  {
    t: 1581642000,
    v: 1.08352625
  },
  {
    t: 1581642300,
    v: 1.0835899999999998
  },
  {
    t: 1581642600,
    v: 1.08364125
  },
  {
    t: 1581642900,
    v: 1.08366375
  },
  {
    t: 1581643200,
    v: 1.0836737499999998
  },
  {
    t: 1581643500,
    v: 1.0836574999999997
  },
  {
    t: 1581643800,
    v: 1.08363875
  },
  {
    t: 1581644100,
    v: 1.0835875
  },
  {
    t: 1581644400,
    v: 1.0835612500000003
  },
  {
    t: 1581644700,
    v: 1.08353625
  },
  {
    t: 1581645000,
    v: 1.0835
  },
  {
    t: 1581645300,
    v: 1.08349
  },
  {
    t: 1581645600,
    v: 1.0834837499999999
  },
  {
    t: 1581645900,
    v: 1.083485
  },
  {
    t: 1581646200,
    v: 1.08348125
  },
  {
    t: 1581646500,
    v: 1.0834899999999998
  },
  {
    t: 1581646800,
    v: 1.0834925000000002
  },
  {
    t: 1581647100,
    v: 1.083485
  },
  {
    t: 1581647400,
    v: 1.0835000000000001
  },
  {
    t: 1581647700,
    v: 1.08349875
  },
  {
    t: 1581648000,
    v: 1.0834949999999999
  },
  {
    t: 1581648300,
    v: 1.08350375
  },
  {
    t: 1581648600,
    v: 1.08351625
  },
  {
    t: 1581648900,
    v: 1.083545
  },
  {
    t: 1581649200,
    v: 1.08354875
  },
  {
    t: 1581649500,
    v: 1.08356875
  },
  {
    t: 1581649800,
    v: 1.0835624999999998
  },
  {
    t: 1581650100,
    v: 1.0835612499999998
  },
  {
    t: 1581650400,
    v: 1.08354875
  },
  {
    t: 1581650700,
    v: 1.0835350000000001
  },
  {
    t: 1581651000,
    v: 1.0835162500000002
  },
  {
    t: 1581651300,
    v: 1.08348
  },
  {
    t: 1581651600,
    v: 1.0834587500000001
  },
  {
    t: 1581651900,
    v: 1.0834075
  },
  {
    t: 1581652200,
    v: 1.0833575
  },
  {
    t: 1581652500,
    v: 1.083295
  },
  {
    t: 1581652800,
    v: 1.08326875
  },
  {
    t: 1581653100,
    v: 1.0832275
  },
  {
    t: 1581653400,
    v: 1.08318375
  },
  {
    t: 1581653700,
    v: 1.08316
  },
  {
    t: 1581654000,
    v: 1.0831374999999999
  },
  {
    t: 1581654300,
    v: 1.0831374999999999
  },
  {
    t: 1581654600,
    v: 1.083145
  },
  {
    t: 1581654900,
    v: 1.0831625
  },
  {
    t: 1581655200,
    v: 1.08316375
  },
  {
    t: 1581655500,
    v: 1.0831724999999999
  },
  {
    t: 1581655800,
    v: 1.0831899999999999
  },
  {
    t: 1581656100,
    v: 1.0832
  },
  {
    t: 1581656400,
    v: 1.0832175
  },
  {
    t: 1581656700,
    v: 1.08323
  },
  {
    t: 1581657000,
    v: 1.083255
  },
  {
    t: 1581657300,
    v: 1.08327875
  },
  {
    t: 1581657600,
    v: 1.083305
  },
  {
    t: 1581657900,
    v: 1.0833325
  },
  {
    t: 1581658200,
    v: 1.08335375
  },
  {
    t: 1581658500,
    v: 1.08336875
  },
  {
    t: 1581658800,
    v: 1.0833862500000002
  },
  {
    t: 1581659100,
    v: 1.0834050000000002
  },
  {
    t: 1581659400,
    v: 1.0834350000000004
  },
  {
    t: 1581659700,
    v: 1.0834687500000002
  },
  {
    t: 1581660000,
    v: 1.08348625
  },
  {
    t: 1581660300,
    v: 1.0834899999999998
  },
  {
    t: 1581660600,
    v: 1.08351375
  },
  {
    t: 1581660900,
    v: 1.08353875
  },
  {
    t: 1581661200,
    v: 1.0835637500000002
  },
  {
    t: 1581661500,
    v: 1.0836
  },
  {
    t: 1581661800,
    v: 1.08361375
  },
  {
    t: 1581662100,
    v: 1.083625
  },
  {
    t: 1581662400,
    v: 1.0836512500000002
  },
  {
    t: 1581662700,
    v: 1.0836762500000001
  },
  {
    t: 1581663000,
    v: 1.083695
  },
  {
    t: 1581663300,
    v: 1.0836875000000001
  },
  {
    t: 1581663600,
    v: 1.0836925000000002
  },
  {
    t: 1581663900,
    v: 1.0837137500000003
  },
  {
    t: 1581664200,
    v: 1.0837375000000002
  },
  {
    t: 1581664500,
    v: 1.0837512500000002
  },
  {
    t: 1581664800,
    v: 1.08374375
  },
  {
    t: 1581665100,
    v: 1.08374375
  },
  {
    t: 1581665400,
    v: 1.0837425
  },
  {
    t: 1581665700,
    v: 1.0838075000000003
  },
  {
    t: 1581666000,
    v: 1.08385125
  },
  {
    t: 1581666300,
    v: 1.08388375
  },
  {
    t: 1581666600,
    v: 1.0839275000000002
  },
  {
    t: 1581666900,
    v: 1.0839512500000001
  },
  {
    t: 1581667200,
    v: 1.0839987500000001
  },
  {
    t: 1581667500,
    v: 1.0840125
  },
  {
    t: 1581667800,
    v: 1.08396875
  },
  {
    t: 1581668100,
    v: 1.08387875
  },
  {
    t: 1581668400,
    v: 1.0837949999999998
  },
  {
    t: 1581668700,
    v: 1.083715
  },
  {
    t: 1581669000,
    v: 1.083655
  },
  {
    t: 1581669300,
    v: 1.08363
  },
  {
    t: 1581669600,
    v: 1.083585
  },
  {
    t: 1581669900,
    v: 1.08358
  },
  {
    t: 1581670200,
    v: 1.0836275
  },
  {
    t: 1581670500,
    v: 1.0837474999999999
  },
  {
    t: 1581670800,
    v: 1.08383875
  },
  {
    t: 1581671100,
    v: 1.0839375
  },
  {
    t: 1581671400,
    v: 1.0840325000000002
  },
  {
    t: 1581671700,
    v: 1.0841175
  },
  {
    t: 1581672000,
    v: 1.0842299999999998
  },
  {
    t: 1581672300,
    v: 1.0843425
  },
  {
    t: 1581672600,
    v: 1.08447
  },
  {
    t: 1581672900,
    v: 1.08449875
  },
  {
    t: 1581673200,
    v: 1.08456125
  },
  {
    t: 1581673500,
    v: 1.08460875
  },
  {
    t: 1581673800,
    v: 1.08462125
  },
  {
    t: 1581674100,
    v: 1.08461
  },
  {
    t: 1581674400,
    v: 1.08456875
  },
  {
    t: 1581674700,
    v: 1.0845375
  },
  {
    t: 1581675000,
    v: 1.0845025
  },
  {
    t: 1581675300,
    v: 1.0845075
  },
  {
    t: 1581675600,
    v: 1.0845150000000001
  },
  {
    t: 1581675900,
    v: 1.0845250000000002
  },
  {
    t: 1581676200,
    v: 1.08447375
  },
  {
    t: 1581676500,
    v: 1.08446625
  },
  {
    t: 1581676800,
    v: 1.0844425
  },
  {
    t: 1581677100,
    v: 1.0843900000000002
  },
  {
    t: 1581677400,
    v: 1.0843124999999998
  },
  {
    t: 1581677700,
    v: 1.08422625
  },
  {
    t: 1581678000,
    v: 1.0841625
  },
  {
    t: 1581678300,
    v: 1.0841025
  },
  {
    t: 1581678600,
    v: 1.08410375
  },
  {
    t: 1581678900,
    v: 1.08409375
  },
  {
    t: 1581679200,
    v: 1.0840824999999998
  },
  {
    t: 1581679500,
    v: 1.08410125
  },
  {
    t: 1581679800,
    v: 1.0841075
  },
  {
    t: 1581680100,
    v: 1.08412875
  },
  {
    t: 1581680400,
    v: 1.08410375
  },
  {
    t: 1581680700,
    v: 1.08406625
  },
  {
    t: 1581681000,
    v: 1.08402
  },
  {
    t: 1581681300,
    v: 1.0839400000000001
  },
  {
    t: 1581681600,
    v: 1.0838725
  },
  {
    t: 1581681900,
    v: 1.0837975000000002
  },
  {
    t: 1581682200,
    v: 1.0837387500000002
  },
  {
    t: 1581682500,
    v: 1.0836825
  },
  {
    t: 1581682800,
    v: 1.08364375
  },
  {
    t: 1581683100,
    v: 1.0836275
  },
  {
    t: 1581683400,
    v: 1.08362875
  },
  {
    t: 1581683700,
    v: 1.0837187499999998
  },
  {
    t: 1581684000,
    v: 1.0838137499999998
  },
  {
    t: 1581684300,
    v: 1.08393375
  },
  {
    t: 1581684600,
    v: 1.08402125
  },
  {
    t: 1581684900,
    v: 1.08406875
  },
  {
    t: 1581685200,
    v: 1.0841275
  },
  {
    t: 1581685500,
    v: 1.0841474999999998
  },
  {
    t: 1581685800,
    v: 1.0841812499999999
  },
  {
    t: 1581686100,
    v: 1.08410125
  },
  {
    t: 1581686400,
    v: 1.0840224999999999
  },
  {
    t: 1581686700,
    v: 1.0839237499999999
  },
  {
    t: 1581687000,
    v: 1.0839787499999998
  },
  {
    t: 1581687300,
    v: 1.08406
  },
  {
    t: 1581687600,
    v: 1.08420875
  },
  {
    t: 1581687900,
    v: 1.0843462499999998
  },
  {
    t: 1581688200,
    v: 1.08450625
  },
  {
    t: 1581688500,
    v: 1.0847212499999999
  },
  {
    t: 1581688800,
    v: 1.08495375
  },
  {
    t: 1581689100,
    v: 1.08516625
  },
  {
    t: 1581689400,
    v: 1.08531375
  },
  {
    t: 1581689700,
    v: 1.08543375
  },
  {
    t: 1581690000,
    v: 1.08544875
  },
  {
    t: 1581690300,
    v: 1.0854987500000002
  },
  {
    t: 1581690600,
    v: 1.085505
  },
  {
    t: 1581690900,
    v: 1.085555
  },
  {
    t: 1581691200,
    v: 1.0856175000000001
  },
  {
    t: 1581691500,
    v: 1.08568
  },
  {
    t: 1581691800,
    v: 1.0856875
  },
  {
    t: 1581692100,
    v: 1.0856925
  },
  {
    t: 1581692400,
    v: 1.0857012499999998
  },
  {
    t: 1581692700,
    v: 1.0856024999999998
  },
  {
    t: 1581693000,
    v: 1.0855249999999999
  },
  {
    t: 1581693300,
    v: 1.08538
  },
  {
    t: 1581693600,
    v: 1.0852487499999999
  },
  {
    t: 1581693900,
    v: 1.08515375
  },
  {
    t: 1581694200,
    v: 1.08506625
  },
  {
    t: 1581694500,
    v: 1.0850325
  },
  {
    t: 1581694800,
    v: 1.0850775
  },
  {
    t: 1581695100,
    v: 1.08517375
  },
  {
    t: 1581695400,
    v: 1.0852187500000001
  },
  {
    t: 1581695700,
    v: 1.0851975
  },
  {
    t: 1581696000,
    v: 1.085125
  },
  {
    t: 1581696300,
    v: 1.0850075000000001
  },
  {
    t: 1581696600,
    v: 1.08489375
  },
  {
    t: 1581696900,
    v: 1.0847225
  },
  {
    t: 1581697200,
    v: 1.0845175
  },
  {
    t: 1581697500,
    v: 1.08434
  },
  {
    t: 1581697800,
    v: 1.084195
  },
  {
    t: 1581698100,
    v: 1.08414125
  },
  {
    t: 1581698400,
    v: 1.0841287500000003
  },
  {
    t: 1581698700,
    v: 1.0841212500000001
  },
  {
    t: 1581699000,
    v: 1.08409
  },
  {
    t: 1581699300,
    v: 1.08408
  },
  {
    t: 1581699600,
    v: 1.0840187499999998
  },
  {
    t: 1581699900,
    v: 1.08396875
  },
  {
    t: 1581700200,
    v: 1.0838975
  },
  {
    t: 1581700500,
    v: 1.08379875
  },
  {
    t: 1581700800,
    v: 1.0837225
  },
  {
    t: 1581701100,
    v: 1.0836525
  },
  {
    t: 1581701400,
    v: 1.083615
  },
  {
    t: 1581701700,
    v: 1.08357875
  },
  {
    t: 1581702000,
    v: 1.08358875
  },
  {
    t: 1581702300,
    v: 1.08361375
  },
  {
    t: 1581702600,
    v: 1.0836875
  },
  {
    t: 1581702900,
    v: 1.0838112500000001
  },
  {
    t: 1581703200,
    v: 1.08389125
  },
  {
    t: 1581703500,
    v: 1.0840049999999999
  },
  {
    t: 1581703800,
    v: 1.0841112499999999
  },
  {
    t: 1581704100,
    v: 1.084225
  },
  {
    t: 1581704400,
    v: 1.08431625
  },
  {
    t: 1581704700,
    v: 1.0843525
  },
  {
    t: 1581705000,
    v: 1.08438875
  },
  {
    t: 1581705300,
    v: 1.08439625
  },
  {
    t: 1581705600,
    v: 1.08441
  },
  {
    t: 1581705900,
    v: 1.08438125
  },
  {
    t: 1581706200,
    v: 1.0843349999999998
  },
  {
    t: 1581706500,
    v: 1.0843125
  },
  {
    t: 1581706800,
    v: 1.08430125
  },
  {
    t: 1581707100,
    v: 1.08433375
  },
  {
    t: 1581707400,
    v: 1.0843475
  },
  {
    t: 1581707700,
    v: 1.08434125
  },
  {
    t: 1581708000,
    v: 1.0843262500000002
  },
  {
    t: 1581708300,
    v: 1.08432
  },
  {
    t: 1581708600,
    v: 1.08431
  },
  {
    t: 1581708900,
    v: 1.0842699999999998
  },
  {
    t: 1581709200,
    v: 1.0842075
  },
  {
    t: 1581709500,
    v: 1.0841399999999999
  },
  {
    t: 1581709800,
    v: 1.0840637499999999
  },
  {
    t: 1581710100,
    v: 1.0840137499999998
  },
  {
    t: 1581710400,
    v: 1.0839587499999999
  },
  {
    t: 1581710700,
    v: 1.08391625
  },
  {
    t: 1581711000,
    v: 1.08390375
  },
  {
    t: 1581711300,
    v: 1.08389
  },
  {
    t: 1581711600,
    v: 1.0838675
  },
  {
    t: 1581711900,
    v: 1.0838562499999997
  },
  {
    t: 1581712200,
    v: 1.0838325000000002
  },
  {
    t: 1581712500,
    v: 1.0838124999999998
  },
  {
    t: 1581712800,
    v: 1.0838125
  },
  {
    t: 1581713100,
    v: 1.08379
  },
  {
    t: 1581713400,
    v: 1.08376375
  },
  {
    t: 1581713700,
    v: 1.0837374999999998
  },
  {
    t: 1581714000,
    v: 1.08373
  },
  {
    t: 1581714300,
    v: 1.0837237499999999
  },
  {
    t: 1581714600,
    v: 1.0837137499999998
  },
  {
    t: 1581714900,
    v: 1.0836899999999998
  },
  {
    t: 1581715200,
    v: 1.08365125
  },
  {
    t: 1581715500,
    v: 1.083615
  },
  {
    t: 1581715800,
    v: 1.083575
  },
  {
    t: 1581716100,
    v: 1.08352625
  },
  {
    t: 1581716400,
    v: 1.08346875
  },
  {
    t: 1581890700,
    v: 1.08348125
  },
  {
    t: 1581891000,
    v: 1.083485
  },
  {
    t: 1581891300,
    v: 1.08348875
  },
  {
    t: 1581891600,
    v: 1.0835125
  },
  {
    t: 1581891900,
    v: 1.08355
  },
  {
    t: 1581892200,
    v: 1.08358875
  },
  {
    t: 1581892500,
    v: 1.0836437499999998
  },
  {
    t: 1581892800,
    v: 1.083715
  },
  {
    t: 1581893100,
    v: 1.08370125
  },
  {
    t: 1581893400,
    v: 1.08372125
  },
  {
    t: 1581893700,
    v: 1.0837387500000002
  },
  {
    t: 1581894000,
    v: 1.08374625
  },
  {
    t: 1581894300,
    v: 1.0837625000000002
  },
  {
    t: 1581894600,
    v: 1.0837812500000001
  },
  {
    t: 1581894900,
    v: 1.0837925
  },
  {
    t: 1581895200,
    v: 1.0838025
  },
  {
    t: 1581895500,
    v: 1.08381375
  },
  {
    t: 1581895800,
    v: 1.08383875
  },
  {
    t: 1581896100,
    v: 1.0838750000000001
  },
  {
    t: 1581896400,
    v: 1.08391
  },
  {
    t: 1581896700,
    v: 1.0839325
  },
  {
    t: 1581897000,
    v: 1.083955
  },
  {
    t: 1581897300,
    v: 1.08398625
  },
  {
    t: 1581897600,
    v: 1.083965
  },
  {
    t: 1581897900,
    v: 1.0839325
  },
  {
    t: 1581898200,
    v: 1.0839
  },
  {
    t: 1581898500,
    v: 1.08385
  },
  {
    t: 1581898800,
    v: 1.083785
  },
  {
    t: 1581899100,
    v: 1.0837474999999999
  },
  {
    t: 1581899400,
    v: 1.08369
  },
  {
    t: 1581899700,
    v: 1.0836275
  },
  {
    t: 1581900000,
    v: 1.0836000000000001
  },
  {
    t: 1581900300,
    v: 1.0835975
  },
  {
    t: 1581900600,
    v: 1.0835725
  },
  {
    t: 1581900900,
    v: 1.0835624999999998
  },
  {
    t: 1581901200,
    v: 1.08355375
  },
  {
    t: 1581901500,
    v: 1.0835362499999999
  },
  {
    t: 1581901800,
    v: 1.0835337500000002
  },
  {
    t: 1581902100,
    v: 1.08351
  },
  {
    t: 1581902400,
    v: 1.083485
  },
  {
    t: 1581902700,
    v: 1.0834724999999998
  },
  {
    t: 1581903000,
    v: 1.08348
  },
  {
    t: 1581903300,
    v: 1.08349125
  },
  {
    t: 1581903600,
    v: 1.08352125
  },
  {
    t: 1581903900,
    v: 1.0835400000000002
  },
  {
    t: 1581904200,
    v: 1.0835575000000002
  },
  {
    t: 1581904500,
    v: 1.0835875
  },
  {
    t: 1581904800,
    v: 1.08362625
  },
  {
    t: 1581905100,
    v: 1.0836649999999999
  },
  {
    t: 1581905400,
    v: 1.0836975
  },
  {
    t: 1581905700,
    v: 1.08371875
  },
  {
    t: 1581906000,
    v: 1.0837299999999999
  },
  {
    t: 1581906300,
    v: 1.083755
  },
  {
    t: 1581906600,
    v: 1.0837812500000001
  },
  {
    t: 1581906900,
    v: 1.0838124999999998
  },
  {
    t: 1581907200,
    v: 1.08385125
  },
  {
    t: 1581907500,
    v: 1.083865
  },
  {
    t: 1581907800,
    v: 1.083875
  },
  {
    t: 1581908100,
    v: 1.083885
  },
  {
    t: 1581908400,
    v: 1.083885
  },
  {
    t: 1581908700,
    v: 1.0838725
  },
  {
    t: 1581909000,
    v: 1.0838537499999998
  },
  {
    t: 1581909300,
    v: 1.08384875
  },
  {
    t: 1581909600,
    v: 1.08382875
  },
  {
    t: 1581909900,
    v: 1.0838124999999998
  },
  {
    t: 1581910200,
    v: 1.08379875
  },
  {
    t: 1581910500,
    v: 1.0837824999999999
  },
  {
    t: 1581910800,
    v: 1.083785
  },
  {
    t: 1581911100,
    v: 1.08378
  },
  {
    t: 1581911400,
    v: 1.0837775
  },
  {
    t: 1581911700,
    v: 1.083775
  },
  {
    t: 1581912000,
    v: 1.08378125
  },
  {
    t: 1581912300,
    v: 1.0838075
  },
  {
    t: 1581912600,
    v: 1.0838275
  },
  {
    t: 1581912900,
    v: 1.08384875
  },
  {
    t: 1581913200,
    v: 1.08384875
  },
  {
    t: 1581913500,
    v: 1.0838575
  },
  {
    t: 1581913800,
    v: 1.0838725
  },
  {
    t: 1581914100,
    v: 1.0838775
  },
  {
    t: 1581914400,
    v: 1.083885
  },
  {
    t: 1581914700,
    v: 1.0838774999999998
  },
  {
    t: 1581915000,
    v: 1.0838575
  },
  {
    t: 1581915300,
    v: 1.0838324999999998
  },
  {
    t: 1581915600,
    v: 1.0838299999999998
  },
  {
    t: 1581915900,
    v: 1.0838237499999996
  },
  {
    t: 1581916200,
    v: 1.0838237499999999
  },
  {
    t: 1581916500,
    v: 1.0838262499999998
  },
  {
    t: 1581916800,
    v: 1.08382875
  },
  {
    t: 1581917100,
    v: 1.08382
  },
  {
    t: 1581917400,
    v: 1.08382
  },
  {
    t: 1581917700,
    v: 1.0838449999999997
  },
  {
    t: 1581918000,
    v: 1.0838587499999999
  },
  {
    t: 1581918300,
    v: 1.0838762499999999
  },
  {
    t: 1581918600,
    v: 1.08389125
  },
  {
    t: 1581918900,
    v: 1.0838962499999998
  },
  {
    t: 1581919200,
    v: 1.0839012499999998
  },
  {
    t: 1581919500,
    v: 1.0838987499999997
  },
  {
    t: 1581919800,
    v: 1.08389375
  },
  {
    t: 1581920100,
    v: 1.0838725
  },
  {
    t: 1581920400,
    v: 1.0838575
  },
  {
    t: 1581920700,
    v: 1.0838475
  },
  {
    t: 1581921000,
    v: 1.08381875
  },
  {
    t: 1581921300,
    v: 1.08378625
  },
  {
    t: 1581921600,
    v: 1.08374375
  },
  {
    t: 1581921900,
    v: 1.08370375
  },
  {
    t: 1581922200,
    v: 1.0836625
  },
  {
    t: 1581922500,
    v: 1.08362625
  },
  {
    t: 1581922800,
    v: 1.0836075
  },
  {
    t: 1581923100,
    v: 1.08358
  },
  {
    t: 1581923400,
    v: 1.0835625
  },
  {
    t: 1581923700,
    v: 1.08358125
  },
  {
    t: 1581924000,
    v: 1.0836050000000002
  },
  {
    t: 1581924300,
    v: 1.08365
  },
  {
    t: 1581924600,
    v: 1.0836925000000002
  },
  {
    t: 1581924900,
    v: 1.08373125
  },
  {
    t: 1581925200,
    v: 1.0837425
  },
  {
    t: 1581925500,
    v: 1.08376125
  },
  {
    t: 1581925800,
    v: 1.0838050000000001
  },
  {
    t: 1581926100,
    v: 1.0838325000000002
  },
  {
    t: 1581926400,
    v: 1.0838575000000001
  },
  {
    t: 1581926700,
    v: 1.0838875
  },
  {
    t: 1581927000,
    v: 1.0839075
  },
  {
    t: 1581927300,
    v: 1.0839462500000001
  },
  {
    t: 1581927600,
    v: 1.0839625000000002
  },
  {
    t: 1581927900,
    v: 1.08398375
  },
  {
    t: 1581928200,
    v: 1.0839625
  },
  {
    t: 1581928500,
    v: 1.0839225000000001
  },
  {
    t: 1581928800,
    v: 1.0839074999999998
  },
  {
    t: 1581929100,
    v: 1.0839275
  },
  {
    t: 1581929400,
    v: 1.0839812500000001
  },
  {
    t: 1581929700,
    v: 1.0840437500000002
  },
  {
    t: 1581930000,
    v: 1.0841475
  },
  {
    t: 1581930300,
    v: 1.0842575
  },
  {
    t: 1581930600,
    v: 1.08439625
  },
  {
    t: 1581930900,
    v: 1.08451625
  },
  {
    t: 1581931200,
    v: 1.0845950000000002
  },
  {
    t: 1581931500,
    v: 1.08461625
  },
  {
    t: 1581931800,
    v: 1.0846325
  },
  {
    t: 1581932100,
    v: 1.08462875
  },
  {
    t: 1581932400,
    v: 1.0845900000000002
  },
  {
    t: 1581932700,
    v: 1.08455375
  },
  {
    t: 1581933000,
    v: 1.08449125
  },
  {
    t: 1581933300,
    v: 1.08445375
  },
  {
    t: 1581933600,
    v: 1.08443125
  },
  {
    t: 1581933900,
    v: 1.084425
  },
  {
    t: 1581934200,
    v: 1.0844225
  },
  {
    t: 1581934500,
    v: 1.08440875
  },
  {
    t: 1581934800,
    v: 1.0844312500000002
  },
  {
    t: 1581935100,
    v: 1.0844287499999998
  },
  {
    t: 1581935400,
    v: 1.0844425000000002
  },
  {
    t: 1581935700,
    v: 1.08447125
  },
  {
    t: 1581936000,
    v: 1.08450875
  },
  {
    t: 1581936300,
    v: 1.08452125
  },
  {
    t: 1581936600,
    v: 1.0845275
  },
  {
    t: 1581936900,
    v: 1.0845525
  },
  {
    t: 1581937200,
    v: 1.084545
  },
  {
    t: 1581937500,
    v: 1.08456
  },
  {
    t: 1581937800,
    v: 1.08455875
  },
  {
    t: 1581938100,
    v: 1.0845300000000002
  },
  {
    t: 1581938400,
    v: 1.084475
  },
  {
    t: 1581938700,
    v: 1.0844287499999998
  },
  {
    t: 1581939000,
    v: 1.08436875
  },
  {
    t: 1581939300,
    v: 1.08430375
  },
  {
    t: 1581939600,
    v: 1.08425
  },
  {
    t: 1581939900,
    v: 1.08417
  },
  {
    t: 1581940200,
    v: 1.0840875
  },
  {
    t: 1581940500,
    v: 1.0840262499999997
  },
  {
    t: 1581940800,
    v: 1.0839975
  },
  {
    t: 1581941100,
    v: 1.083985
  },
  {
    t: 1581941400,
    v: 1.0839875
  },
  {
    t: 1581941700,
    v: 1.08395625
  },
  {
    t: 1581942000,
    v: 1.08396125
  },
  {
    t: 1581942300,
    v: 1.08394125
  },
  {
    t: 1581942600,
    v: 1.08393625
  },
  {
    t: 1581942900,
    v: 1.0839175
  },
  {
    t: 1581943200,
    v: 1.0838875000000001
  },
  {
    t: 1581943500,
    v: 1.083845
  },
  {
    t: 1581943800,
    v: 1.08378125
  },
  {
    t: 1581944100,
    v: 1.08374625
  },
  {
    t: 1581944400,
    v: 1.08366
  },
  {
    t: 1581944700,
    v: 1.08359375
  },
  {
    t: 1581945000,
    v: 1.08355125
  },
  {
    t: 1581945300,
    v: 1.0835412500000001
  },
  {
    t: 1581945600,
    v: 1.08354375
  },
  {
    t: 1581945900,
    v: 1.0835625
  },
  {
    t: 1581946200,
    v: 1.08358375
  },
  {
    t: 1581946500,
    v: 1.08361875
  },
  {
    t: 1581946800,
    v: 1.0836975
  },
  {
    t: 1581947100,
    v: 1.0837675
  },
  {
    t: 1581947400,
    v: 1.08380125
  },
  {
    t: 1581947700,
    v: 1.08379125
  },
  {
    t: 1581948000,
    v: 1.0837700000000001
  },
  {
    t: 1581948300,
    v: 1.0837425
  },
  {
    t: 1581948600,
    v: 1.08373375
  },
  {
    t: 1581948900,
    v: 1.0837025
  },
  {
    t: 1581949200,
    v: 1.08365
  },
  {
    t: 1581949500,
    v: 1.08364625
  },
  {
    t: 1581949800,
    v: 1.08363875
  },
  {
    t: 1581950100,
    v: 1.08366375
  },
  {
    t: 1581950400,
    v: 1.0836999999999999
  },
  {
    t: 1581950700,
    v: 1.08375
  },
  {
    t: 1581951000,
    v: 1.0837762499999999
  },
  {
    t: 1581951300,
    v: 1.08380625
  },
  {
    t: 1581951600,
    v: 1.08383875
  },
  {
    t: 1581951900,
    v: 1.0838125
  },
  {
    t: 1581952200,
    v: 1.08379
  },
  {
    t: 1581952500,
    v: 1.083765
  },
  {
    t: 1581952800,
    v: 1.0837125
  },
  {
    t: 1581953100,
    v: 1.0836437499999998
  },
  {
    t: 1581953400,
    v: 1.0836075
  },
  {
    t: 1581953700,
    v: 1.08358
  },
  {
    t: 1581954000,
    v: 1.08354375
  },
  {
    t: 1581954300,
    v: 1.08348125
  },
  {
    t: 1581954600,
    v: 1.08345
  },
  {
    t: 1581954900,
    v: 1.08337
  },
  {
    t: 1581955200,
    v: 1.0833125
  },
  {
    t: 1581955500,
    v: 1.0832725
  },
  {
    t: 1581955800,
    v: 1.0832287499999997
  },
  {
    t: 1581956100,
    v: 1.0832024999999998
  },
  {
    t: 1581956400,
    v: 1.08319375
  },
  {
    t: 1581956700,
    v: 1.0832499999999998
  },
  {
    t: 1581957000,
    v: 1.08328125
  },
  {
    t: 1581957300,
    v: 1.08334125
  },
  {
    t: 1581957600,
    v: 1.08339
  },
  {
    t: 1581957900,
    v: 1.08343875
  },
  {
    t: 1581958200,
    v: 1.08348375
  },
  {
    t: 1581958500,
    v: 1.08351625
  },
  {
    t: 1581958800,
    v: 1.083555
  },
  {
    t: 1581959100,
    v: 1.08358625
  },
  {
    t: 1581959400,
    v: 1.0836124999999999
  },
  {
    t: 1581959700,
    v: 1.08363375
  },
  {
    t: 1581960000,
    v: 1.0836499999999998
  },
  {
    t: 1581960300,
    v: 1.0836612499999998
  },
  {
    t: 1581960600,
    v: 1.083655
  },
  {
    t: 1581960900,
    v: 1.0836337500000002
  },
  {
    t: 1581961200,
    v: 1.0835812500000002
  },
  {
    t: 1581961500,
    v: 1.08352125
  },
  {
    t: 1581961800,
    v: 1.0834650000000001
  },
  {
    t: 1581962100,
    v: 1.08341375
  },
  {
    t: 1581962400,
    v: 1.0833849999999998
  },
  {
    t: 1581962700,
    v: 1.0833575
  },
  {
    t: 1581963000,
    v: 1.08334
  },
  {
    t: 1581963300,
    v: 1.08332
  },
  {
    t: 1581963600,
    v: 1.0833074999999999
  },
  {
    t: 1581963900,
    v: 1.0832825000000001
  },
  {
    t: 1581964200,
    v: 1.08325875
  },
  {
    t: 1581964500,
    v: 1.0832612499999998
  },
  {
    t: 1581964800,
    v: 1.08325125
  },
  {
    t: 1581965100,
    v: 1.083225
  },
  {
    t: 1581965400,
    v: 1.0832087500000003
  },
  {
    t: 1581965700,
    v: 1.0831925
  },
  {
    t: 1581966000,
    v: 1.08318625
  },
  {
    t: 1581966300,
    v: 1.08318
  },
  {
    t: 1581966600,
    v: 1.083205
  },
  {
    t: 1581966900,
    v: 1.0832225
  },
  {
    t: 1581967200,
    v: 1.083235
  },
  {
    t: 1581967500,
    v: 1.0832475000000001
  },
  {
    t: 1581967800,
    v: 1.0832525
  },
  {
    t: 1581968100,
    v: 1.0832625
  },
  {
    t: 1581968400,
    v: 1.083275
  },
  {
    t: 1581968700,
    v: 1.08330875
  },
  {
    t: 1581969000,
    v: 1.08331375
  },
  {
    t: 1581969300,
    v: 1.08331375
  },
  {
    t: 1581969600,
    v: 1.08331625
  },
  {
    t: 1581969900,
    v: 1.0833225
  },
  {
    t: 1581970200,
    v: 1.0833225
  },
  {
    t: 1581970500,
    v: 1.08332625
  },
  {
    t: 1581970800,
    v: 1.08332
  },
  {
    t: 1581971100,
    v: 1.0833137499999999
  },
  {
    t: 1581971400,
    v: 1.0833125
  },
  {
    t: 1581971700,
    v: 1.08329125
  },
  {
    t: 1581972000,
    v: 1.0832675
  },
  {
    t: 1581972300,
    v: 1.08325875
  },
  {
    t: 1581972600,
    v: 1.08325
  },
  {
    t: 1581972900,
    v: 1.08324125
  },
  {
    t: 1581973200,
    v: 1.08323375
  },
  {
    t: 1581973500,
    v: 1.0832262499999998
  },
  {
    t: 1581973800,
    v: 1.0832325
  },
  {
    t: 1581974100,
    v: 1.08325625
  },
  {
    t: 1581974400,
    v: 1.08328625
  },
  {
    t: 1581974700,
    v: 1.08330625
  },
  {
    t: 1581975000,
    v: 1.083325
  },
  {
    t: 1581975300,
    v: 1.08334
  },
  {
    t: 1581975600,
    v: 1.083365
  },
  {
    t: 1581975900,
    v: 1.0833899999999999
  },
  {
    t: 1581976200,
    v: 1.083405
  },
  {
    t: 1581976500,
    v: 1.08341
  },
  {
    t: 1581976800,
    v: 1.08340625
  },
  {
    t: 1581977100,
    v: 1.0834175000000001
  },
  {
    t: 1581977400,
    v: 1.08342625
  },
  {
    t: 1581977700,
    v: 1.083415
  },
  {
    t: 1581978000,
    v: 1.08340875
  },
  {
    t: 1581978300,
    v: 1.0834149999999998
  },
  {
    t: 1581978600,
    v: 1.0833975
  },
  {
    t: 1581978900,
    v: 1.08341
  },
  {
    t: 1581979200,
    v: 1.083445
  },
  {
    t: 1581979500,
    v: 1.0834575
  },
  {
    t: 1581979800,
    v: 1.0834787499999998
  },
  {
    t: 1581980100,
    v: 1.08352
  },
  {
    t: 1581980400,
    v: 1.0835425
  },
  {
    t: 1581980700,
    v: 1.0835562500000002
  },
  {
    t: 1581981000,
    v: 1.08358875
  },
  {
    t: 1581981300,
    v: 1.0835975
  },
  {
    t: 1581981600,
    v: 1.08358625
  },
  {
    t: 1581981900,
    v: 1.08358125
  },
  {
    t: 1581982200,
    v: 1.08357375
  },
  {
    t: 1581982500,
    v: 1.0835662499999998
  },
  {
    t: 1581982800,
    v: 1.083555
  },
  {
    t: 1581983100,
    v: 1.083555
  },
  {
    t: 1581983400,
    v: 1.083555
  },
  {
    t: 1581983700,
    v: 1.0835625
  },
  {
    t: 1581984000,
    v: 1.0835312499999998
  },
  {
    t: 1581984300,
    v: 1.0834949999999999
  },
  {
    t: 1581984600,
    v: 1.08343125
  },
  {
    t: 1581984900,
    v: 1.08335375
  },
  {
    t: 1581985200,
    v: 1.0832899999999999
  },
  {
    t: 1581985500,
    v: 1.083235
  },
  {
    t: 1581985800,
    v: 1.0831724999999999
  },
  {
    t: 1581986100,
    v: 1.08311
  },
  {
    t: 1581986400,
    v: 1.0830687499999998
  },
  {
    t: 1581986700,
    v: 1.0830212499999998
  },
  {
    t: 1581987000,
    v: 1.0829775
  },
  {
    t: 1581987300,
    v: 1.0829275
  },
  {
    t: 1581987600,
    v: 1.082905
  },
  {
    t: 1581987900,
    v: 1.08284875
  },
  {
    t: 1581988200,
    v: 1.0828
  },
  {
    t: 1581988500,
    v: 1.082745
  },
  {
    t: 1581988800,
    v: 1.0827112500000002
  },
  {
    t: 1581989100,
    v: 1.0826837500000002
  },
  {
    t: 1581989400,
    v: 1.082665
  },
  {
    t: 1581989700,
    v: 1.08266625
  },
  {
    t: 1581990000,
    v: 1.0826125
  },
  {
    t: 1581990300,
    v: 1.08259375
  },
  {
    t: 1581990600,
    v: 1.0825875
  },
  {
    t: 1581990900,
    v: 1.0825925
  },
  {
    t: 1581991200,
    v: 1.0825875
  },
  {
    t: 1581991500,
    v: 1.0825850000000001
  },
  {
    t: 1581991800,
    v: 1.08259125
  },
  {
    t: 1581992100,
    v: 1.082585
  },
  {
    t: 1581992400,
    v: 1.08259125
  },
  {
    t: 1581992700,
    v: 1.082575
  },
  {
    t: 1581993000,
    v: 1.08255
  },
  {
    t: 1581993300,
    v: 1.08253125
  },
  {
    t: 1581993600,
    v: 1.0825425000000002
  },
  {
    t: 1581993900,
    v: 1.08255125
  },
  {
    t: 1581994200,
    v: 1.0825725
  },
  {
    t: 1581994500,
    v: 1.08260625
  },
  {
    t: 1581994800,
    v: 1.0826724999999997
  },
  {
    t: 1581995100,
    v: 1.0827525
  },
  {
    t: 1581995400,
    v: 1.082845
  },
  {
    t: 1581995700,
    v: 1.0829325
  },
  {
    t: 1581996000,
    v: 1.08299625
  },
  {
    t: 1581996300,
    v: 1.0830487500000001
  },
  {
    t: 1581996600,
    v: 1.083105
  },
  {
    t: 1581996900,
    v: 1.083165
  },
  {
    t: 1581997200,
    v: 1.08319375
  },
  {
    t: 1581997500,
    v: 1.08320625
  },
  {
    t: 1581997800,
    v: 1.083215
  },
  {
    t: 1581998100,
    v: 1.0832074999999999
  },
  {
    t: 1581998400,
    v: 1.08319875
  },
  {
    t: 1581998700,
    v: 1.083205
  },
  {
    t: 1581999000,
    v: 1.08320125
  },
  {
    t: 1581999300,
    v: 1.083195
  },
  {
    t: 1581999600,
    v: 1.08319375
  },
  {
    t: 1581999900,
    v: 1.08319875
  },
  {
    t: 1582000200,
    v: 1.083185
  },
  {
    t: 1582000500,
    v: 1.0831762500000002
  },
  {
    t: 1582000800,
    v: 1.08316
  },
  {
    t: 1582001100,
    v: 1.0831374999999999
  },
  {
    t: 1582001400,
    v: 1.083115
  },
  {
    t: 1582001700,
    v: 1.083085
  },
  {
    t: 1582002000,
    v: 1.0830675
  },
  {
    t: 1582002300,
    v: 1.08305
  },
  {
    t: 1582002600,
    v: 1.0830487500000001
  },
  {
    t: 1582002900,
    v: 1.083035
  },
  {
    t: 1582003200,
    v: 1.08303875
  },
  {
    t: 1582003500,
    v: 1.0830487500000001
  },
  {
    t: 1582003800,
    v: 1.08306125
  },
  {
    t: 1582004100,
    v: 1.0830775
  },
  {
    t: 1582004400,
    v: 1.08309
  },
  {
    t: 1582004700,
    v: 1.08308875
  },
  {
    t: 1582005000,
    v: 1.0830787499999999
  },
  {
    t: 1582005300,
    v: 1.0830837500000001
  },
  {
    t: 1582005600,
    v: 1.08312375
  },
  {
    t: 1582005900,
    v: 1.08318
  },
  {
    t: 1582006200,
    v: 1.08321875
  },
  {
    t: 1582006500,
    v: 1.0832575
  },
  {
    t: 1582006800,
    v: 1.08328875
  },
  {
    t: 1582007100,
    v: 1.0833325
  },
  {
    t: 1582007400,
    v: 1.0833487499999999
  },
  {
    t: 1582007700,
    v: 1.0833575
  },
  {
    t: 1582008000,
    v: 1.0833187499999999
  },
  {
    t: 1582008300,
    v: 1.08326
  },
  {
    t: 1582008600,
    v: 1.0832125
  },
  {
    t: 1582008900,
    v: 1.0831525
  },
  {
    t: 1582009200,
    v: 1.0831137499999999
  },
  {
    t: 1582009500,
    v: 1.0830975
  },
  {
    t: 1582009800,
    v: 1.0831237500000002
  },
  {
    t: 1582010100,
    v: 1.083185
  },
  {
    t: 1582010400,
    v: 1.08325
  },
  {
    t: 1582010700,
    v: 1.0833
  },
  {
    t: 1582011000,
    v: 1.0833499999999998
  },
  {
    t: 1582011300,
    v: 1.08344375
  },
  {
    t: 1582011600,
    v: 1.0835062500000001
  },
  {
    t: 1582011900,
    v: 1.0835275
  },
  {
    t: 1582012200,
    v: 1.08353625
  },
  {
    t: 1582012500,
    v: 1.08349
  },
  {
    t: 1582012800,
    v: 1.08342875
  },
  {
    t: 1582013100,
    v: 1.08342125
  },
  {
    t: 1582013400,
    v: 1.0833412500000001
  },
  {
    t: 1582013700,
    v: 1.08323375
  },
  {
    t: 1582014000,
    v: 1.08315875
  },
  {
    t: 1582014300,
    v: 1.0830887500000002
  },
  {
    t: 1582014600,
    v: 1.08300875
  },
  {
    t: 1582014900,
    v: 1.08292
  },
  {
    t: 1582015200,
    v: 1.0828737499999999
  },
  {
    t: 1582015500,
    v: 1.0827775
  },
  {
    t: 1582015800,
    v: 1.08275125
  },
  {
    t: 1582016100,
    v: 1.08272375
  },
  {
    t: 1582016400,
    v: 1.0826925
  },
  {
    t: 1582016700,
    v: 1.0826962500000001
  },
  {
    t: 1582017000,
    v: 1.08270125
  },
  {
    t: 1582017300,
    v: 1.0827575
  },
  {
    t: 1582017600,
    v: 1.08278625
  },
  {
    t: 1582017900,
    v: 1.0828175
  },
  {
    t: 1582018200,
    v: 1.082845
  },
  {
    t: 1582018500,
    v: 1.0828775
  },
  {
    t: 1582018800,
    v: 1.08288875
  },
  {
    t: 1582019100,
    v: 1.08288625
  },
  {
    t: 1582019400,
    v: 1.08287
  },
  {
    t: 1582019700,
    v: 1.082835
  },
  {
    t: 1582020000,
    v: 1.08276875
  },
  {
    t: 1582020300,
    v: 1.0827324999999999
  },
  {
    t: 1582020600,
    v: 1.08265
  },
  {
    t: 1582020900,
    v: 1.0826049999999998
  },
  {
    t: 1582021200,
    v: 1.08259875
  },
  {
    t: 1582021500,
    v: 1.0825300000000002
  },
  {
    t: 1582021800,
    v: 1.0825225
  },
  {
    t: 1582022100,
    v: 1.08255
  },
  {
    t: 1582022400,
    v: 1.08265375
  },
  {
    t: 1582022700,
    v: 1.0826900000000002
  },
  {
    t: 1582023000,
    v: 1.0828187500000002
  },
  {
    t: 1582023300,
    v: 1.082835
  },
  {
    t: 1582023600,
    v: 1.0828487500000001
  },
  {
    t: 1582023900,
    v: 1.0829674999999999
  },
  {
    t: 1582024200,
    v: 1.0830499999999998
  },
  {
    t: 1582024500,
    v: 1.0831062500000002
  },
  {
    t: 1582024800,
    v: 1.08310875
  },
  {
    t: 1582025100,
    v: 1.0831625
  },
  {
    t: 1582025400,
    v: 1.0831575
  },
  {
    t: 1582025700,
    v: 1.08323875
  },
  {
    t: 1582026000,
    v: 1.0832525
  },
  {
    t: 1582026300,
    v: 1.08319375
  },
  {
    t: 1582026600,
    v: 1.08311625
  },
  {
    t: 1582026900,
    v: 1.08299375
  },
  {
    t: 1582027200,
    v: 1.0828674999999999
  },
  {
    t: 1582027500,
    v: 1.08273625
  },
  {
    t: 1582027800,
    v: 1.08261
  },
  {
    t: 1582028100,
    v: 1.0824637499999998
  },
  {
    t: 1582028400,
    v: 1.0823274999999999
  },
  {
    t: 1582028700,
    v: 1.08219625
  },
  {
    t: 1582029000,
    v: 1.082035
  },
  {
    t: 1582029300,
    v: 1.0819275
  },
  {
    t: 1582029600,
    v: 1.08183625
  },
  {
    t: 1582029900,
    v: 1.08175625
  },
  {
    t: 1582030200,
    v: 1.0816675
  },
  {
    t: 1582030500,
    v: 1.08157375
  },
  {
    t: 1582030800,
    v: 1.08154375
  },
  {
    t: 1582031100,
    v: 1.081505
  },
  {
    t: 1582031400,
    v: 1.08151375
  },
  {
    t: 1582031700,
    v: 1.081455
  },
  {
    t: 1582032000,
    v: 1.0814012499999999
  },
  {
    t: 1582032300,
    v: 1.08134625
  },
  {
    t: 1582032600,
    v: 1.08112
  },
  {
    t: 1582032900,
    v: 1.08091625
  },
  {
    t: 1582033200,
    v: 1.08073
  },
  {
    t: 1582033500,
    v: 1.08053
  },
  {
    t: 1582033800,
    v: 1.0803675
  },
  {
    t: 1582034100,
    v: 1.080255
  },
  {
    t: 1582034400,
    v: 1.08009375
  },
  {
    t: 1582034700,
    v: 1.07986125
  },
  {
    t: 1582035000,
    v: 1.0798025
  },
  {
    t: 1582035300,
    v: 1.07967875
  },
  {
    t: 1582035600,
    v: 1.07955375
  },
  {
    t: 1582035900,
    v: 1.07948125
  },
  {
    t: 1582036200,
    v: 1.0794112500000002
  },
  {
    t: 1582036500,
    v: 1.07936875
  },
  {
    t: 1582036800,
    v: 1.0793650000000001
  },
  {
    t: 1582037100,
    v: 1.0793925000000002
  },
  {
    t: 1582037400,
    v: 1.07953
  },
  {
    t: 1582037700,
    v: 1.0797800000000002
  },
  {
    t: 1582038000,
    v: 1.0800875
  },
  {
    t: 1582038300,
    v: 1.08034875
  },
  {
    t: 1582038600,
    v: 1.0805275
  },
  {
    t: 1582038900,
    v: 1.08072625
  },
  {
    t: 1582039200,
    v: 1.0809300000000002
  },
  {
    t: 1582039500,
    v: 1.0811937500000002
  },
  {
    t: 1582039800,
    v: 1.081335
  },
  {
    t: 1582040100,
    v: 1.08148375
  },
  {
    t: 1582040400,
    v: 1.0815000000000001
  },
  {
    t: 1582040700,
    v: 1.0815562500000002
  },
  {
    t: 1582041000,
    v: 1.08165125
  },
  {
    t: 1582041300,
    v: 1.08175375
  },
  {
    t: 1582041600,
    v: 1.08184125
  },
  {
    t: 1582041900,
    v: 1.08189
  },
  {
    t: 1582042200,
    v: 1.0819750000000001
  },
  {
    t: 1582042500,
    v: 1.0819612500000002
  },
  {
    t: 1582042800,
    v: 1.08198625
  },
  {
    t: 1582043100,
    v: 1.0819787500000002
  },
  {
    t: 1582043400,
    v: 1.0819174999999999
  },
  {
    t: 1582043700,
    v: 1.0817912499999998
  },
  {
    t: 1582044000,
    v: 1.08168875
  },
  {
    t: 1582044300,
    v: 1.0815975
  },
  {
    t: 1582044600,
    v: 1.08150625
  },
  {
    t: 1582044900,
    v: 1.0814549999999998
  },
  {
    t: 1582045200,
    v: 1.0813787499999998
  },
  {
    t: 1582045500,
    v: 1.08129875
  },
  {
    t: 1582045800,
    v: 1.08127125
  },
  {
    t: 1582046100,
    v: 1.08125875
  },
  {
    t: 1582046400,
    v: 1.0812325
  },
  {
    t: 1582046700,
    v: 1.08117375
  },
  {
    t: 1582047000,
    v: 1.08107125
  },
  {
    t: 1582047300,
    v: 1.08094875
  },
  {
    t: 1582047600,
    v: 1.0808274999999998
  },
  {
    t: 1582047900,
    v: 1.08074625
  },
  {
    t: 1582048200,
    v: 1.08065125
  },
  {
    t: 1582048500,
    v: 1.0805575
  },
  {
    t: 1582048800,
    v: 1.08044625
  },
  {
    t: 1582049100,
    v: 1.0803437500000002
  },
  {
    t: 1582049400,
    v: 1.0802975
  },
  {
    t: 1582049700,
    v: 1.080265
  },
  {
    t: 1582050000,
    v: 1.08024375
  },
  {
    t: 1582050300,
    v: 1.08020625
  },
  {
    t: 1582050600,
    v: 1.0801875
  },
  {
    t: 1582050900,
    v: 1.08015875
  },
  {
    t: 1582051200,
    v: 1.08017125
  },
  {
    t: 1582051500,
    v: 1.080185
  },
  {
    t: 1582051800,
    v: 1.08018375
  },
  {
    t: 1582052100,
    v: 1.0801574999999999
  },
  {
    t: 1582052400,
    v: 1.08012125
  },
  {
    t: 1582052700,
    v: 1.08010375
  },
  {
    t: 1582053000,
    v: 1.08008
  },
  {
    t: 1582053300,
    v: 1.08003375
  },
  {
    t: 1582053600,
    v: 1.0799762499999999
  },
  {
    t: 1582053900,
    v: 1.0799375
  },
  {
    t: 1582054200,
    v: 1.07989
  },
  {
    t: 1582054500,
    v: 1.07982875
  },
  {
    t: 1582054800,
    v: 1.07976625
  },
  {
    t: 1582055100,
    v: 1.07968625
  },
  {
    t: 1582055400,
    v: 1.0796199999999998
  },
  {
    t: 1582055700,
    v: 1.0795662499999998
  },
  {
    t: 1582056000,
    v: 1.0795362499999999
  },
  {
    t: 1582056300,
    v: 1.0795
  },
  {
    t: 1582056600,
    v: 1.0794712499999999
  },
  {
    t: 1582056900,
    v: 1.07944
  },
  {
    t: 1582057200,
    v: 1.07941375
  },
  {
    t: 1582057500,
    v: 1.0793700000000002
  },
  {
    t: 1582057800,
    v: 1.0793250000000003
  },
  {
    t: 1582058100,
    v: 1.07933125
  },
  {
    t: 1582058400,
    v: 1.07929
  },
  {
    t: 1582058700,
    v: 1.0792599999999999
  },
  {
    t: 1582059000,
    v: 1.0792375
  },
  {
    t: 1582059300,
    v: 1.07928
  },
  {
    t: 1582059600,
    v: 1.07932
  },
  {
    t: 1582059900,
    v: 1.0793775
  },
  {
    t: 1582060200,
    v: 1.0794087499999998
  },
  {
    t: 1582060500,
    v: 1.07939375
  },
  {
    t: 1582060800,
    v: 1.0794
  },
  {
    t: 1582061100,
    v: 1.079385
  },
  {
    t: 1582061400,
    v: 1.07935625
  },
  {
    t: 1582061700,
    v: 1.0792912500000003
  },
  {
    t: 1582062000,
    v: 1.07922125
  },
  {
    t: 1582062300,
    v: 1.0791775000000001
  },
  {
    t: 1582062600,
    v: 1.0791587500000002
  },
  {
    t: 1582062900,
    v: 1.0791325
  },
  {
    t: 1582063200,
    v: 1.0791024999999999
  },
  {
    t: 1582063500,
    v: 1.07910625
  },
  {
    t: 1582063800,
    v: 1.07912125
  },
  {
    t: 1582064100,
    v: 1.07914375
  },
  {
    t: 1582064400,
    v: 1.0791775000000001
  },
  {
    t: 1582064700,
    v: 1.07919375
  },
  {
    t: 1582065000,
    v: 1.07919875
  },
  {
    t: 1582065300,
    v: 1.0792375
  },
  {
    t: 1582065600,
    v: 1.07928
  },
  {
    t: 1582065900,
    v: 1.07931125
  },
  {
    t: 1582066200,
    v: 1.07933625
  },
  {
    t: 1582066500,
    v: 1.07935625
  },
  {
    t: 1582066800,
    v: 1.07937375
  },
  {
    t: 1582067100,
    v: 1.0793975
  },
  {
    t: 1582067400,
    v: 1.0794124999999999
  },
  {
    t: 1582067700,
    v: 1.07942125
  },
  {
    t: 1582068000,
    v: 1.0794325
  },
  {
    t: 1582068300,
    v: 1.07944
  },
  {
    t: 1582068600,
    v: 1.07945
  },
  {
    t: 1582068900,
    v: 1.0794774999999999
  },
  {
    t: 1582069200,
    v: 1.07951
  },
  {
    t: 1582069500,
    v: 1.07952125
  },
  {
    t: 1582069800,
    v: 1.07954
  },
  {
    t: 1582070100,
    v: 1.0795387499999998
  },
  {
    t: 1582070400,
    v: 1.0795137499999998
  },
  {
    t: 1582070700,
    v: 1.0794899999999998
  },
  {
    t: 1582071000,
    v: 1.07947625
  },
  {
    t: 1582071300,
    v: 1.07945625
  },
  {
    t: 1582071600,
    v: 1.07944125
  },
  {
    t: 1582071900,
    v: 1.07943875
  },
  {
    t: 1582072200,
    v: 1.079445
  },
  {
    t: 1582072500,
    v: 1.07946
  },
  {
    t: 1582072800,
    v: 1.07949875
  },
  {
    t: 1582073100,
    v: 1.07953875
  },
  {
    t: 1582073400,
    v: 1.0795975
  },
  {
    t: 1582073700,
    v: 1.0796375
  },
  {
    t: 1582074000,
    v: 1.07967375
  },
  {
    t: 1582074300,
    v: 1.079705
  },
  {
    t: 1582074600,
    v: 1.0797175
  },
  {
    t: 1582074900,
    v: 1.07976
  },
  {
    t: 1582075200,
    v: 1.0798112500000001
  },
  {
    t: 1582075500,
    v: 1.0798525
  },
  {
    t: 1582075800,
    v: 1.0798962500000002
  },
  {
    t: 1582076100,
    v: 1.0799562500000002
  },
  {
    t: 1582076400,
    v: 1.0800137500000002
  },
  {
    t: 1582076700,
    v: 1.08007375
  },
  {
    t: 1582077000,
    v: 1.08014375
  },
  {
    t: 1582077300,
    v: 1.0801749999999999
  },
  {
    t: 1582077600,
    v: 1.0801675
  },
  {
    t: 1582077900,
    v: 1.08018875
  },
  {
    t: 1582078200,
    v: 1.08019
  },
  {
    t: 1582078500,
    v: 1.0801725
  },
  {
    t: 1582078800,
    v: 1.08015
  },
  {
    t: 1582079100,
    v: 1.0801124999999998
  },
  {
    t: 1582079400,
    v: 1.08007
  },
  {
    t: 1582079700,
    v: 1.08004125
  },
  {
    t: 1582080000,
    v: 1.080035
  },
  {
    t: 1582080300,
    v: 1.07999625
  },
  {
    t: 1582080600,
    v: 1.07994875
  },
  {
    t: 1582080900,
    v: 1.0799175
  },
  {
    t: 1582081200,
    v: 1.079895
  },
  {
    t: 1582081500,
    v: 1.07987875
  },
  {
    t: 1582081800,
    v: 1.079885
  },
  {
    t: 1582082100,
    v: 1.07990875
  },
  {
    t: 1582082400,
    v: 1.0799325
  },
  {
    t: 1582082700,
    v: 1.0799637500000001
  },
  {
    t: 1582083000,
    v: 1.07999125
  },
  {
    t: 1582083300,
    v: 1.0800100000000001
  },
  {
    t: 1582083600,
    v: 1.08002
  },
  {
    t: 1582083900,
    v: 1.08003375
  },
  {
    t: 1582084200,
    v: 1.08002625
  },
  {
    t: 1582084500,
    v: 1.07999375
  },
  {
    t: 1582084800,
    v: 1.079955
  },
  {
    t: 1582085100,
    v: 1.0799249999999998
  },
  {
    t: 1582085400,
    v: 1.07989
  },
  {
    t: 1582085700,
    v: 1.07985625
  },
  {
    t: 1582086000,
    v: 1.07982875
  },
  {
    t: 1582086300,
    v: 1.0798075
  },
  {
    t: 1582086600,
    v: 1.07979375
  },
  {
    t: 1582086900,
    v: 1.07977625
  },
  {
    t: 1582087200,
    v: 1.07975
  },
  {
    t: 1582087500,
    v: 1.0797125
  },
  {
    t: 1582087800,
    v: 1.0796825
  },
  {
    t: 1582088100,
    v: 1.0796762500000001
  },
  {
    t: 1582088400,
    v: 1.07967125
  },
  {
    t: 1582088700,
    v: 1.07966
  },
  {
    t: 1582089000,
    v: 1.07963375
  },
  {
    t: 1582089300,
    v: 1.079615
  },
  {
    t: 1582089600,
    v: 1.0796124999999999
  },
  {
    t: 1582089900,
    v: 1.0796125
  },
  {
    t: 1582090200,
    v: 1.07961375
  },
  {
    t: 1582090500,
    v: 1.0796
  },
  {
    t: 1582090800,
    v: 1.07957375
  },
  {
    t: 1582091100,
    v: 1.0795499999999998
  },
  {
    t: 1582091400,
    v: 1.0795325
  },
  {
    t: 1582091700,
    v: 1.079525
  },
  {
    t: 1582092000,
    v: 1.07949125
  },
  {
    t: 1582092300,
    v: 1.07945125
  },
  {
    t: 1582092600,
    v: 1.07941875
  },
  {
    t: 1582092900,
    v: 1.0793825000000001
  },
  {
    t: 1582093200,
    v: 1.07937375
  },
  {
    t: 1582093500,
    v: 1.07937125
  },
  {
    t: 1582093800,
    v: 1.07937375
  },
  {
    t: 1582094100,
    v: 1.0793825
  },
  {
    t: 1582094400,
    v: 1.07942375
  },
  {
    t: 1582094700,
    v: 1.0794825
  },
  {
    t: 1582095000,
    v: 1.0795225000000002
  },
  {
    t: 1582095300,
    v: 1.0795825000000001
  },
  {
    t: 1582095600,
    v: 1.079625
  },
  {
    t: 1582095900,
    v: 1.07963875
  },
  {
    t: 1582096200,
    v: 1.0796675
  },
  {
    t: 1582096500,
    v: 1.07969625
  },
  {
    t: 1582096800,
    v: 1.079745
  },
  {
    t: 1582097100,
    v: 1.07978125
  },
  {
    t: 1582097400,
    v: 1.0798225
  },
  {
    t: 1582097700,
    v: 1.0798462500000001
  },
  {
    t: 1582098000,
    v: 1.0798975000000002
  },
  {
    t: 1582098300,
    v: 1.07997375
  },
  {
    t: 1582098600,
    v: 1.0800375
  },
  {
    t: 1582098900,
    v: 1.08008125
  },
  {
    t: 1582099200,
    v: 1.08005
  },
  {
    t: 1582099500,
    v: 1.08000875
  },
  {
    t: 1582099800,
    v: 1.08000875
  },
  {
    t: 1582100100,
    v: 1.08002875
  },
  {
    t: 1582100400,
    v: 1.0800375
  },
  {
    t: 1582100700,
    v: 1.0800562500000002
  },
  {
    t: 1582101000,
    v: 1.08008625
  },
  {
    t: 1582101300,
    v: 1.0801237499999998
  },
  {
    t: 1582101600,
    v: 1.0802112499999998
  },
  {
    t: 1582101900,
    v: 1.0803124999999998
  },
  {
    t: 1582102200,
    v: 1.0803725
  },
  {
    t: 1582102500,
    v: 1.0804
  },
  {
    t: 1582102800,
    v: 1.0804337499999999
  },
  {
    t: 1582103100,
    v: 1.08044375
  },
  {
    t: 1582103400,
    v: 1.080465
  },
  {
    t: 1582103700,
    v: 1.08046625
  },
  {
    t: 1582104000,
    v: 1.08042875
  },
  {
    t: 1582104300,
    v: 1.08039
  },
  {
    t: 1582104600,
    v: 1.0803675
  },
  {
    t: 1582104900,
    v: 1.080355
  },
  {
    t: 1582105200,
    v: 1.0803049999999998
  },
  {
    t: 1582105500,
    v: 1.08025875
  },
  {
    t: 1582105800,
    v: 1.08019125
  },
  {
    t: 1582106100,
    v: 1.0801675
  },
  {
    t: 1582106400,
    v: 1.0802
  },
  {
    t: 1582106700,
    v: 1.08023625
  },
  {
    t: 1582107000,
    v: 1.0802575
  },
  {
    t: 1582107300,
    v: 1.08026875
  },
  {
    t: 1582107600,
    v: 1.0802625
  },
  {
    t: 1582107900,
    v: 1.0802450000000001
  },
  {
    t: 1582108200,
    v: 1.08023625
  },
  {
    t: 1582108500,
    v: 1.0802299999999998
  },
  {
    t: 1582108800,
    v: 1.0802375
  },
  {
    t: 1582109100,
    v: 1.0801924999999999
  },
  {
    t: 1582109400,
    v: 1.0801462499999999
  },
  {
    t: 1582109700,
    v: 1.08010625
  },
  {
    t: 1582110000,
    v: 1.0800874999999999
  },
  {
    t: 1582110300,
    v: 1.0800575
  },
  {
    t: 1582110600,
    v: 1.0799875
  },
  {
    t: 1582110900,
    v: 1.0798925000000001
  },
  {
    t: 1582111200,
    v: 1.07975875
  },
  {
    t: 1582111500,
    v: 1.07966625
  },
  {
    t: 1582111800,
    v: 1.0795125
  },
  {
    t: 1582112100,
    v: 1.079345
  },
  {
    t: 1582112400,
    v: 1.07920375
  },
  {
    t: 1582112700,
    v: 1.0791075
  },
  {
    t: 1582113000,
    v: 1.0790675
  },
  {
    t: 1582113300,
    v: 1.0790237500000002
  },
  {
    t: 1582113600,
    v: 1.07901
  },
  {
    t: 1582113900,
    v: 1.07901875
  },
  {
    t: 1582114200,
    v: 1.07911375
  },
  {
    t: 1582114500,
    v: 1.0792225
  },
  {
    t: 1582114800,
    v: 1.0793375
  },
  {
    t: 1582115100,
    v: 1.07945625
  },
  {
    t: 1582115400,
    v: 1.07952
  },
  {
    t: 1582115700,
    v: 1.0796075
  },
  {
    t: 1582116000,
    v: 1.0796687500000002
  },
  {
    t: 1582116300,
    v: 1.0797287500000001
  },
  {
    t: 1582116600,
    v: 1.079815
  },
  {
    t: 1582116900,
    v: 1.07987875
  },
  {
    t: 1582117200,
    v: 1.07986625
  },
  {
    t: 1582117500,
    v: 1.0798575
  },
  {
    t: 1582117800,
    v: 1.07989
  },
  {
    t: 1582118100,
    v: 1.07988375
  },
  {
    t: 1582118400,
    v: 1.0799137500000002
  },
  {
    t: 1582118700,
    v: 1.07991125
  },
  {
    t: 1582119000,
    v: 1.079845
  },
  {
    t: 1582119300,
    v: 1.0797575
  },
  {
    t: 1582119600,
    v: 1.07970125
  },
  {
    t: 1582119900,
    v: 1.0796450000000002
  },
  {
    t: 1582120200,
    v: 1.07961375
  },
  {
    t: 1582120500,
    v: 1.07955625
  },
  {
    t: 1582120800,
    v: 1.07943125
  },
  {
    t: 1582121100,
    v: 1.0793075
  },
  {
    t: 1582121400,
    v: 1.0792225
  },
  {
    t: 1582121700,
    v: 1.0792075
  },
  {
    t: 1582122000,
    v: 1.07920875
  },
  {
    t: 1582122300,
    v: 1.0791962499999999
  },
  {
    t: 1582122600,
    v: 1.0790787499999999
  },
  {
    t: 1582122900,
    v: 1.07902875
  },
  {
    t: 1582123200,
    v: 1.07901375
  },
  {
    t: 1582123500,
    v: 1.07900125
  },
  {
    t: 1582123800,
    v: 1.0789475
  },
  {
    t: 1582124100,
    v: 1.07887625
  },
  {
    t: 1582124400,
    v: 1.07885625
  },
  {
    t: 1582124700,
    v: 1.07880625
  },
  {
    t: 1582125000,
    v: 1.0788274999999998
  },
  {
    t: 1582125300,
    v: 1.0788125
  },
  {
    t: 1582125600,
    v: 1.0787725
  },
  {
    t: 1582125900,
    v: 1.07871875
  },
  {
    t: 1582126200,
    v: 1.07868125
  },
  {
    t: 1582126500,
    v: 1.078685
  },
  {
    t: 1582126800,
    v: 1.0786250000000002
  },
  {
    t: 1582127100,
    v: 1.0786
  },
  {
    t: 1582127400,
    v: 1.0786087500000001
  },
  {
    t: 1582127700,
    v: 1.0786475
  },
  {
    t: 1582128000,
    v: 1.07867
  },
  {
    t: 1582128300,
    v: 1.07873375
  },
  {
    t: 1582128600,
    v: 1.07885875
  },
  {
    t: 1582128900,
    v: 1.07894875
  },
  {
    t: 1582129200,
    v: 1.0790337499999998
  },
  {
    t: 1582129500,
    v: 1.07914625
  },
  {
    t: 1582129800,
    v: 1.0791724999999999
  },
  {
    t: 1582130100,
    v: 1.07916625
  },
  {
    t: 1582130400,
    v: 1.07926625
  },
  {
    t: 1582130700,
    v: 1.07937625
  },
  {
    t: 1582131000,
    v: 1.07938
  },
  {
    t: 1582131300,
    v: 1.0793650000000001
  },
  {
    t: 1582131600,
    v: 1.0793712500000001
  },
  {
    t: 1582131900,
    v: 1.0792899999999999
  },
  {
    t: 1582132200,
    v: 1.07923
  },
  {
    t: 1582132500,
    v: 1.0792225
  },
  {
    t: 1582132800,
    v: 1.0791575000000002
  },
  {
    t: 1582133100,
    v: 1.079085
  },
  {
    t: 1582133400,
    v: 1.0790575
  },
  {
    t: 1582133700,
    v: 1.07902125
  },
  {
    t: 1582134000,
    v: 1.0790375
  },
  {
    t: 1582134300,
    v: 1.07911625
  },
  {
    t: 1582134600,
    v: 1.07920375
  },
  {
    t: 1582134900,
    v: 1.079225
  },
  {
    t: 1582135200,
    v: 1.0792275
  },
  {
    t: 1582135500,
    v: 1.0792287500000002
  },
  {
    t: 1582135800,
    v: 1.0792350000000002
  },
  {
    t: 1582136100,
    v: 1.0792725
  },
  {
    t: 1582136400,
    v: 1.0792475000000001
  },
  {
    t: 1582136700,
    v: 1.07921
  },
  {
    t: 1582137000,
    v: 1.07918125
  },
  {
    t: 1582137300,
    v: 1.07919625
  },
  {
    t: 1582137600,
    v: 1.0792225000000002
  },
  {
    t: 1582137900,
    v: 1.07924625
  },
  {
    t: 1582138200,
    v: 1.0792650000000001
  },
  {
    t: 1582138500,
    v: 1.0793225000000002
  },
  {
    t: 1582138800,
    v: 1.0793487499999999
  },
  {
    t: 1582139100,
    v: 1.0793650000000001
  },
  {
    t: 1582139400,
    v: 1.079405
  },
  {
    t: 1582139700,
    v: 1.07942625
  },
  {
    t: 1582140000,
    v: 1.07947625
  },
  {
    t: 1582140300,
    v: 1.0795249999999998
  },
  {
    t: 1582140600,
    v: 1.0795975
  },
  {
    t: 1582140900,
    v: 1.0796124999999999
  },
  {
    t: 1582141200,
    v: 1.0796925
  },
  {
    t: 1582141500,
    v: 1.0797575
  },
  {
    t: 1582141800,
    v: 1.0797925
  },
  {
    t: 1582142100,
    v: 1.0798124999999998
  },
  {
    t: 1582142400,
    v: 1.07984375
  },
  {
    t: 1582142700,
    v: 1.07987875
  },
  {
    t: 1582143000,
    v: 1.0799075
  },
  {
    t: 1582143300,
    v: 1.07997875
  },
  {
    t: 1582143600,
    v: 1.0800337500000001
  },
  {
    t: 1582143900,
    v: 1.0800925
  },
  {
    t: 1582144200,
    v: 1.08015875
  },
  {
    t: 1582144500,
    v: 1.08026
  },
  {
    t: 1582144800,
    v: 1.0803362500000002
  },
  {
    t: 1582145100,
    v: 1.0804300000000002
  },
  {
    t: 1582145400,
    v: 1.0804962500000002
  },
  {
    t: 1582145700,
    v: 1.08056
  },
  {
    t: 1582146000,
    v: 1.0805974999999999
  },
  {
    t: 1582146300,
    v: 1.0806575
  },
  {
    t: 1582146600,
    v: 1.0807375000000001
  },
  {
    t: 1582146900,
    v: 1.0807974999999999
  },
  {
    t: 1582147200,
    v: 1.08086875
  },
  {
    t: 1582147500,
    v: 1.0808799999999998
  },
  {
    t: 1582147800,
    v: 1.08090375
  },
  {
    t: 1582148100,
    v: 1.0809025
  },
  {
    t: 1582148400,
    v: 1.080905
  },
  {
    t: 1582148700,
    v: 1.0808974999999998
  },
  {
    t: 1582149000,
    v: 1.0808650000000002
  },
  {
    t: 1582149300,
    v: 1.0807975
  },
  {
    t: 1582149600,
    v: 1.0807425
  },
  {
    t: 1582149900,
    v: 1.0807075
  },
  {
    t: 1582150200,
    v: 1.0806825
  },
  {
    t: 1582150500,
    v: 1.0806387499999999
  },
  {
    t: 1582150800,
    v: 1.08061875
  },
  {
    t: 1582151100,
    v: 1.08060125
  },
  {
    t: 1582151400,
    v: 1.08059875
  },
  {
    t: 1582151700,
    v: 1.08063
  },
  {
    t: 1582152000,
    v: 1.0806525
  },
  {
    t: 1582152300,
    v: 1.0806625
  },
  {
    t: 1582152600,
    v: 1.0806675000000001
  },
  {
    t: 1582152900,
    v: 1.0806825
  },
  {
    t: 1582153200,
    v: 1.0806974999999999
  },
  {
    t: 1582153500,
    v: 1.08071875
  },
  {
    t: 1582153800,
    v: 1.08073625
  },
  {
    t: 1582154100,
    v: 1.0807575
  },
  {
    t: 1582154400,
    v: 1.0807475
  },
  {
    t: 1582154700,
    v: 1.0807575
  },
  {
    t: 1582155000,
    v: 1.08075875
  },
  {
    t: 1582155300,
    v: 1.08075625
  },
  {
    t: 1582155600,
    v: 1.08075875
  },
  {
    t: 1582155900,
    v: 1.0807575
  },
  {
    t: 1582156200,
    v: 1.0807825
  },
  {
    t: 1582156500,
    v: 1.0808012500000002
  },
  {
    t: 1582156800,
    v: 1.0807749999999998
  },
  {
    t: 1582157100,
    v: 1.0807825
  },
  {
    t: 1582157400,
    v: 1.0808425
  },
  {
    t: 1582157700,
    v: 1.0809324999999999
  },
  {
    t: 1582158000,
    v: 1.08098125
  },
  {
    t: 1582158300,
    v: 1.0810199999999999
  },
  {
    t: 1582158600,
    v: 1.0810374999999999
  },
  {
    t: 1582158900,
    v: 1.08106
  },
  {
    t: 1582159200,
    v: 1.08112375
  },
  {
    t: 1582159500,
    v: 1.08115
  },
  {
    t: 1582159800,
    v: 1.0811437499999998
  },
  {
    t: 1582160100,
    v: 1.08110125
  },
  {
    t: 1582160400,
    v: 1.0810999999999997
  },
  {
    t: 1582160700,
    v: 1.0811074999999999
  },
  {
    t: 1582161000,
    v: 1.0810749999999998
  },
  {
    t: 1582161300,
    v: 1.0810512499999998
  },
  {
    t: 1582161600,
    v: 1.0809950000000002
  },
  {
    t: 1582161900,
    v: 1.080935
  },
  {
    t: 1582162200,
    v: 1.080835
  },
  {
    t: 1582162500,
    v: 1.0807475
  },
  {
    t: 1582162800,
    v: 1.08061
  },
  {
    t: 1582163100,
    v: 1.0804974999999999
  },
  {
    t: 1582163400,
    v: 1.0803775
  },
  {
    t: 1582163700,
    v: 1.08026
  },
  {
    t: 1582164000,
    v: 1.0802
  },
  {
    t: 1582164300,
    v: 1.0801375000000002
  },
  {
    t: 1582164600,
    v: 1.08008375
  },
  {
    t: 1582164900,
    v: 1.0800174999999999
  },
  {
    t: 1582165200,
    v: 1.08000125
  },
  {
    t: 1582165500,
    v: 1.0799625000000002
  },
  {
    t: 1582165800,
    v: 1.0799337500000001
  },
  {
    t: 1582166100,
    v: 1.079885
  },
  {
    t: 1582166400,
    v: 1.079825
  },
  {
    t: 1582166700,
    v: 1.0797750000000002
  },
  {
    t: 1582167000,
    v: 1.07971125
  },
  {
    t: 1582167300,
    v: 1.0796350000000001
  },
  {
    t: 1582167600,
    v: 1.0795837499999998
  },
  {
    t: 1582167900,
    v: 1.07954875
  },
  {
    t: 1582168200,
    v: 1.07956625
  },
  {
    t: 1582168500,
    v: 1.0796025
  },
  {
    t: 1582168800,
    v: 1.07962
  },
  {
    t: 1582169100,
    v: 1.07964125
  },
  {
    t: 1582169400,
    v: 1.079665
  },
  {
    t: 1582169700,
    v: 1.0797262500000002
  },
  {
    t: 1582170000,
    v: 1.07975375
  },
  {
    t: 1582170300,
    v: 1.07977625
  },
  {
    t: 1582170600,
    v: 1.0797637500000001
  },
  {
    t: 1582170900,
    v: 1.0797375000000002
  },
  {
    t: 1582171200,
    v: 1.0797225000000001
  },
  {
    t: 1582171500,
    v: 1.07970125
  },
  {
    t: 1582171800,
    v: 1.0797025000000002
  },
  {
    t: 1582172100,
    v: 1.07968625
  },
  {
    t: 1582172400,
    v: 1.0796775
  },
  {
    t: 1582172700,
    v: 1.07965375
  },
  {
    t: 1582173000,
    v: 1.0796475000000003
  },
  {
    t: 1582173300,
    v: 1.0796475000000003
  },
  {
    t: 1582173600,
    v: 1.0796512500000002
  },
  {
    t: 1582173900,
    v: 1.07964375
  },
  {
    t: 1582174200,
    v: 1.0796525000000001
  },
  {
    t: 1582174500,
    v: 1.07968
  },
  {
    t: 1582174800,
    v: 1.07970375
  },
  {
    t: 1582175100,
    v: 1.0797187499999998
  },
  {
    t: 1582175400,
    v: 1.0797275
  },
  {
    t: 1582175700,
    v: 1.079735
  },
  {
    t: 1582176000,
    v: 1.0797212499999997
  },
  {
    t: 1582176300,
    v: 1.07971625
  },
  {
    t: 1582176600,
    v: 1.0797062499999999
  },
  {
    t: 1582176900,
    v: 1.0796875
  },
  {
    t: 1582177200,
    v: 1.0796625
  },
  {
    t: 1582177500,
    v: 1.0796525
  },
  {
    t: 1582177800,
    v: 1.0796424999999998
  },
  {
    t: 1582178100,
    v: 1.07962875
  },
  {
    t: 1582178400,
    v: 1.0796299999999999
  },
  {
    t: 1582178700,
    v: 1.079645
  },
  {
    t: 1582179000,
    v: 1.079645
  },
  {
    t: 1582179300,
    v: 1.07960375
  },
  {
    t: 1582179600,
    v: 1.0795599999999999
  },
  {
    t: 1582179900,
    v: 1.079505
  },
  {
    t: 1582180200,
    v: 1.07943625
  },
  {
    t: 1582180500,
    v: 1.0793650000000001
  },
  {
    t: 1582180800,
    v: 1.079295
  },
  {
    t: 1582181100,
    v: 1.07920125
  },
  {
    t: 1582181400,
    v: 1.0790925
  },
  {
    t: 1582181700,
    v: 1.0790225
  },
  {
    t: 1582182000,
    v: 1.078985
  },
  {
    t: 1582182300,
    v: 1.0789325
  },
  {
    t: 1582182600,
    v: 1.0789075000000001
  },
  {
    t: 1582182900,
    v: 1.07883375
  },
  {
    t: 1582183200,
    v: 1.0787425000000002
  },
  {
    t: 1582183500,
    v: 1.07869
  },
  {
    t: 1582183800,
    v: 1.0786775
  },
  {
    t: 1582184100,
    v: 1.0786775000000002
  },
  {
    t: 1582184400,
    v: 1.07869375
  },
  {
    t: 1582184700,
    v: 1.07872625
  },
  {
    t: 1582185000,
    v: 1.0787125
  },
  {
    t: 1582185300,
    v: 1.07878375
  },
  {
    t: 1582185600,
    v: 1.07889375
  },
  {
    t: 1582185900,
    v: 1.078975
  },
  {
    t: 1582186200,
    v: 1.07901625
  },
  {
    t: 1582186500,
    v: 1.07897625
  },
  {
    t: 1582186800,
    v: 1.07894375
  },
  {
    t: 1582187100,
    v: 1.078945
  },
  {
    t: 1582187400,
    v: 1.07899625
  },
  {
    t: 1582187700,
    v: 1.07902
  },
  {
    t: 1582188000,
    v: 1.07901125
  },
  {
    t: 1582188300,
    v: 1.0790475
  },
  {
    t: 1582188600,
    v: 1.07909375
  },
  {
    t: 1582188900,
    v: 1.0792412500000002
  },
  {
    t: 1582189200,
    v: 1.0793475
  },
  {
    t: 1582189500,
    v: 1.07941125
  },
  {
    t: 1582189800,
    v: 1.07947
  },
  {
    t: 1582190100,
    v: 1.07955625
  },
  {
    t: 1582190400,
    v: 1.0797162500000002
  },
  {
    t: 1582190700,
    v: 1.079835
  },
  {
    t: 1582191000,
    v: 1.0799500000000002
  },
  {
    t: 1582191300,
    v: 1.0800300000000003
  },
  {
    t: 1582191600,
    v: 1.08011875
  },
  {
    t: 1582191900,
    v: 1.0802025
  },
  {
    t: 1582192200,
    v: 1.080245
  },
  {
    t: 1582192500,
    v: 1.0802512499999999
  },
  {
    t: 1582192800,
    v: 1.08014875
  },
  {
    t: 1582193100,
    v: 1.0800124999999998
  },
  {
    t: 1582193400,
    v: 1.07992125
  },
  {
    t: 1582193700,
    v: 1.0798075
  },
  {
    t: 1582194000,
    v: 1.0796474999999999
  },
  {
    t: 1582194300,
    v: 1.07951125
  },
  {
    t: 1582194600,
    v: 1.07945
  },
  {
    t: 1582194900,
    v: 1.07940625
  },
  {
    t: 1582195200,
    v: 1.0794025
  },
  {
    t: 1582195500,
    v: 1.0794400000000002
  },
  {
    t: 1582195800,
    v: 1.07939875
  },
  {
    t: 1582196100,
    v: 1.0793775
  },
  {
    t: 1582196400,
    v: 1.07937875
  },
  {
    t: 1582196700,
    v: 1.07939
  },
  {
    t: 1582197000,
    v: 1.07935125
  },
  {
    t: 1582197300,
    v: 1.0792462500000002
  },
  {
    t: 1582197600,
    v: 1.0791575
  },
  {
    t: 1582197900,
    v: 1.07910125
  },
  {
    t: 1582198200,
    v: 1.0790975
  },
  {
    t: 1582198500,
    v: 1.07909375
  },
  {
    t: 1582198800,
    v: 1.0790899999999999
  },
  {
    t: 1582199100,
    v: 1.07910375
  },
  {
    t: 1582199400,
    v: 1.0791425
  },
  {
    t: 1582199700,
    v: 1.0792475
  },
  {
    t: 1582200000,
    v: 1.07931
  },
  {
    t: 1582200300,
    v: 1.079335
  },
  {
    t: 1582200600,
    v: 1.079385
  },
  {
    t: 1582200900,
    v: 1.0794175
  },
  {
    t: 1582201200,
    v: 1.07946375
  },
  {
    t: 1582201500,
    v: 1.07942375
  },
  {
    t: 1582201800,
    v: 1.0793137499999998
  },
  {
    t: 1582202100,
    v: 1.0792112500000002
  },
  {
    t: 1582202400,
    v: 1.07918125
  },
  {
    t: 1582202700,
    v: 1.079115
  },
  {
    t: 1582203000,
    v: 1.07904625
  },
  {
    t: 1582203300,
    v: 1.0789662500000001
  },
  {
    t: 1582203600,
    v: 1.0788975
  },
  {
    t: 1582203900,
    v: 1.0788837500000001
  },
  {
    t: 1582204200,
    v: 1.0789175
  },
  {
    t: 1582204500,
    v: 1.0789475
  },
  {
    t: 1582204800,
    v: 1.07895375
  },
  {
    t: 1582205100,
    v: 1.0790437499999999
  },
  {
    t: 1582205400,
    v: 1.07906375
  },
  {
    t: 1582205700,
    v: 1.07916875
  },
  {
    t: 1582206000,
    v: 1.0792812500000002
  },
  {
    t: 1582206300,
    v: 1.0793812500000002
  },
  {
    t: 1582206600,
    v: 1.0794975
  },
  {
    t: 1582206900,
    v: 1.0796724999999998
  },
  {
    t: 1582207200,
    v: 1.0799462499999999
  },
  {
    t: 1582207500,
    v: 1.080215
  },
  {
    t: 1582207800,
    v: 1.08047125
  },
  {
    t: 1582208100,
    v: 1.08064375
  },
  {
    t: 1582208400,
    v: 1.0808062499999997
  },
  {
    t: 1582208700,
    v: 1.08101375
  },
  {
    t: 1582209000,
    v: 1.0812675
  },
  {
    t: 1582209300,
    v: 1.0814
  },
  {
    t: 1582209600,
    v: 1.08139875
  },
  {
    t: 1582209900,
    v: 1.0814025
  },
  {
    t: 1582210200,
    v: 1.08146875
  },
  {
    t: 1582210500,
    v: 1.08154875
  },
  {
    t: 1582210800,
    v: 1.08161125
  },
  {
    t: 1582211100,
    v: 1.08164125
  },
  {
    t: 1582211400,
    v: 1.0816237499999999
  },
  {
    t: 1582211700,
    v: 1.08162625
  },
  {
    t: 1582212000,
    v: 1.0815875
  },
  {
    t: 1582212300,
    v: 1.08156625
  },
  {
    t: 1582212600,
    v: 1.0815287500000001
  },
  {
    t: 1582212900,
    v: 1.0814287500000002
  },
  {
    t: 1582213200,
    v: 1.0812637500000002
  },
  {
    t: 1582213500,
    v: 1.08106875
  },
  {
    t: 1582213800,
    v: 1.080875
  },
  {
    t: 1582214100,
    v: 1.08070875
  },
  {
    t: 1582214400,
    v: 1.08065875
  },
  {
    t: 1582214700,
    v: 1.080535
  },
  {
    t: 1582215000,
    v: 1.0804225
  },
  {
    t: 1582215300,
    v: 1.0803425
  },
  {
    t: 1582215600,
    v: 1.0802
  },
  {
    t: 1582215900,
    v: 1.0800212500000002
  },
  {
    t: 1582216200,
    v: 1.0798787500000002
  },
  {
    t: 1582216500,
    v: 1.07970125
  },
  {
    t: 1582216800,
    v: 1.0795025000000003
  },
  {
    t: 1582217100,
    v: 1.07934625
  },
  {
    t: 1582217400,
    v: 1.0792000000000002
  },
  {
    t: 1582217700,
    v: 1.07912875
  },
  {
    t: 1582218000,
    v: 1.0792300000000001
  },
  {
    t: 1582218300,
    v: 1.0793825000000001
  },
  {
    t: 1582218600,
    v: 1.079505
  },
  {
    t: 1582218900,
    v: 1.0796762500000001
  },
  {
    t: 1582219200,
    v: 1.0798125
  },
  {
    t: 1582219500,
    v: 1.0799087500000002
  },
  {
    t: 1582219800,
    v: 1.0799550000000002
  },
  {
    t: 1582220100,
    v: 1.07995875
  },
  {
    t: 1582220400,
    v: 1.07993
  },
  {
    t: 1582220700,
    v: 1.07988625
  },
  {
    t: 1582221000,
    v: 1.0798275
  },
  {
    t: 1582221300,
    v: 1.07971875
  },
  {
    t: 1582221600,
    v: 1.07961625
  },
  {
    t: 1582221900,
    v: 1.0795100000000002
  },
  {
    t: 1582222200,
    v: 1.0794375
  },
  {
    t: 1582222500,
    v: 1.0794125
  },
  {
    t: 1582222800,
    v: 1.0793875
  },
  {
    t: 1582223100,
    v: 1.07936375
  },
  {
    t: 1582223400,
    v: 1.0793325
  },
  {
    t: 1582223700,
    v: 1.0793450000000002
  },
  {
    t: 1582224000,
    v: 1.079345
  },
  {
    t: 1582224300,
    v: 1.0793575
  },
  {
    t: 1582224600,
    v: 1.0793762500000001
  },
  {
    t: 1582224900,
    v: 1.07935875
  },
  {
    t: 1582225200,
    v: 1.0793162500000002
  },
  {
    t: 1582225500,
    v: 1.0792825
  },
  {
    t: 1582225800,
    v: 1.07925125
  },
  {
    t: 1582226100,
    v: 1.07922
  },
  {
    t: 1582226400,
    v: 1.0791937500000002
  },
  {
    t: 1582226700,
    v: 1.0792012500000001
  },
  {
    t: 1582227000,
    v: 1.0791925
  },
  {
    t: 1582227300,
    v: 1.07917125
  },
  {
    t: 1582227600,
    v: 1.0791362500000001
  },
  {
    t: 1582227900,
    v: 1.0790825
  },
  {
    t: 1582228200,
    v: 1.07905
  },
  {
    t: 1582228500,
    v: 1.0790250000000001
  },
  {
    t: 1582228800,
    v: 1.078995
  },
  {
    t: 1582229100,
    v: 1.0789149999999998
  },
  {
    t: 1582229400,
    v: 1.0788525
  },
  {
    t: 1582229700,
    v: 1.0787912499999999
  },
  {
    t: 1582230000,
    v: 1.07874125
  },
  {
    t: 1582230300,
    v: 1.07873625
  },
  {
    t: 1582230600,
    v: 1.0787325
  },
  {
    t: 1582230900,
    v: 1.078705
  },
  {
    t: 1582231200,
    v: 1.0786850000000001
  },
  {
    t: 1582231500,
    v: 1.07868
  },
  {
    t: 1582231800,
    v: 1.07867
  },
  {
    t: 1582232100,
    v: 1.0787037499999999
  },
  {
    t: 1582232400,
    v: 1.07874375
  },
  {
    t: 1582232700,
    v: 1.07876
  },
  {
    t: 1582233000,
    v: 1.0787674999999999
  },
  {
    t: 1582233300,
    v: 1.07876375
  },
  {
    t: 1582233600,
    v: 1.0787425000000002
  },
  {
    t: 1582233900,
    v: 1.0787149999999999
  },
  {
    t: 1582234200,
    v: 1.07870125
  },
  {
    t: 1582234500,
    v: 1.078655
  },
  {
    t: 1582234800,
    v: 1.0786162499999998
  },
  {
    t: 1582235100,
    v: 1.0785974999999999
  },
  {
    t: 1582235400,
    v: 1.0785312499999997
  },
  {
    t: 1582235700,
    v: 1.0784974999999999
  },
  {
    t: 1582236000,
    v: 1.0784725
  },
  {
    t: 1582236300,
    v: 1.0784625
  },
  {
    t: 1582236600,
    v: 1.0784462499999998
  },
  {
    t: 1582236900,
    v: 1.0784225
  },
  {
    t: 1582237200,
    v: 1.078405
  },
  {
    t: 1582237500,
    v: 1.078375
  },
  {
    t: 1582237800,
    v: 1.0784
  },
  {
    t: 1582238100,
    v: 1.0784162499999999
  },
  {
    t: 1582238400,
    v: 1.07845875
  },
  {
    t: 1582238700,
    v: 1.078475
  },
  {
    t: 1582239000,
    v: 1.078485
  },
  {
    t: 1582239300,
    v: 1.07851875
  },
  {
    t: 1582239600,
    v: 1.0785375
  },
  {
    t: 1582239900,
    v: 1.07857375
  },
  {
    t: 1582240200,
    v: 1.0786012500000002
  },
  {
    t: 1582240500,
    v: 1.07863375
  },
  {
    t: 1582240800,
    v: 1.07864
  },
  {
    t: 1582241100,
    v: 1.078665
  },
  {
    t: 1582241400,
    v: 1.0786975
  },
  {
    t: 1582241700,
    v: 1.07872125
  },
  {
    t: 1582242000,
    v: 1.07874625
  },
  {
    t: 1582242300,
    v: 1.07876375
  },
  {
    t: 1582242600,
    v: 1.07877375
  },
  {
    t: 1582242900,
    v: 1.0787675
  },
  {
    t: 1582243200,
    v: 1.0787875
  },
  {
    t: 1582243500,
    v: 1.07881375
  },
  {
    t: 1582243800,
    v: 1.0788524999999998
  },
  {
    t: 1582244100,
    v: 1.07886375
  },
  {
    t: 1582244400,
    v: 1.0788725000000001
  },
  {
    t: 1582244700,
    v: 1.0788700000000002
  },
  {
    t: 1582245000,
    v: 1.0788675
  },
  {
    t: 1582245300,
    v: 1.0788662500000004
  },
  {
    t: 1582245600,
    v: 1.0788675
  },
  {
    t: 1582245900,
    v: 1.078865
  },
  {
    t: 1582246200,
    v: 1.07882625
  },
  {
    t: 1582246500,
    v: 1.0788324999999999
  },
  {
    t: 1582246800,
    v: 1.0788437499999999
  },
  {
    t: 1582247100,
    v: 1.07889375
  },
  {
    t: 1582247400,
    v: 1.0789374999999999
  },
  {
    t: 1582247700,
    v: 1.0789949999999997
  },
  {
    t: 1582248000,
    v: 1.0790374999999996
  },
  {
    t: 1582248300,
    v: 1.0790674999999998
  },
  {
    t: 1582248600,
    v: 1.07912625
  },
  {
    t: 1582248900,
    v: 1.07916125
  },
  {
    t: 1582249200,
    v: 1.0791925
  },
  {
    t: 1582249500,
    v: 1.079205
  },
  {
    t: 1582249800,
    v: 1.079225
  },
  {
    t: 1582250100,
    v: 1.0792325
  },
  {
    t: 1582250400,
    v: 1.0792000000000002
  },
  {
    t: 1582250700,
    v: 1.0791737499999998
  },
  {
    t: 1582251000,
    v: 1.07913125
  },
  {
    t: 1582251300,
    v: 1.07910625
  },
  {
    t: 1582251600,
    v: 1.07908125
  },
  {
    t: 1582251900,
    v: 1.07902375
  },
  {
    t: 1582252200,
    v: 1.07897875
  },
  {
    t: 1582252500,
    v: 1.0789425
  },
  {
    t: 1582252800,
    v: 1.07892
  },
  {
    t: 1582253100,
    v: 1.07889
  },
  {
    t: 1582253400,
    v: 1.078875
  },
  {
    t: 1582253700,
    v: 1.0788600000000002
  },
  {
    t: 1582254000,
    v: 1.07886
  },
  {
    t: 1582254300,
    v: 1.0789062500000002
  },
  {
    t: 1582254600,
    v: 1.0789312500000001
  },
  {
    t: 1582254900,
    v: 1.0789225
  },
  {
    t: 1582255200,
    v: 1.07893375
  },
  {
    t: 1582255500,
    v: 1.0789575
  },
  {
    t: 1582255800,
    v: 1.07898
  },
  {
    t: 1582256100,
    v: 1.07898625
  },
  {
    t: 1582256400,
    v: 1.07898875
  },
  {
    t: 1582256700,
    v: 1.07896375
  },
  {
    t: 1582257000,
    v: 1.07893875
  },
  {
    t: 1582257300,
    v: 1.0789525
  },
  {
    t: 1582257600,
    v: 1.078955
  },
  {
    t: 1582257900,
    v: 1.07897125
  },
  {
    t: 1582258200,
    v: 1.0789862499999998
  },
  {
    t: 1582258500,
    v: 1.07901125
  },
  {
    t: 1582258800,
    v: 1.0790237500000002
  },
  {
    t: 1582259100,
    v: 1.0790525
  },
  {
    t: 1582259400,
    v: 1.07908
  },
  {
    t: 1582259700,
    v: 1.0791074999999999
  },
  {
    t: 1582260000,
    v: 1.0791549999999999
  },
  {
    t: 1582260300,
    v: 1.0791887500000001
  },
  {
    t: 1582260600,
    v: 1.07921375
  },
  {
    t: 1582260900,
    v: 1.07924375
  },
  {
    t: 1582261200,
    v: 1.07927
  },
  {
    t: 1582261500,
    v: 1.0792899999999999
  },
  {
    t: 1582261800,
    v: 1.07932125
  },
  {
    t: 1582262100,
    v: 1.0793362499999999
  },
  {
    t: 1582262400,
    v: 1.079335
  },
  {
    t: 1582262700,
    v: 1.079335
  },
  {
    t: 1582263000,
    v: 1.0793400000000002
  },
  {
    t: 1582263300,
    v: 1.07932625
  },
  {
    t: 1582263600,
    v: 1.079315
  },
  {
    t: 1582263900,
    v: 1.07929625
  },
  {
    t: 1582264200,
    v: 1.0792875
  },
  {
    t: 1582264500,
    v: 1.0792800000000002
  },
  {
    t: 1582264800,
    v: 1.07928375
  },
  {
    t: 1582265100,
    v: 1.0792899999999999
  },
  {
    t: 1582265400,
    v: 1.07929625
  },
  {
    t: 1582265700,
    v: 1.07929125
  },
  {
    t: 1582266000,
    v: 1.07929
  },
  {
    t: 1582266300,
    v: 1.07927625
  },
  {
    t: 1582266600,
    v: 1.0792487499999999
  },
  {
    t: 1582266900,
    v: 1.0792374999999998
  },
  {
    t: 1582267200,
    v: 1.0792175
  },
  {
    t: 1582267500,
    v: 1.07917375
  },
  {
    t: 1582267800,
    v: 1.07913
  },
  {
    t: 1582268100,
    v: 1.07909375
  },
  {
    t: 1582268400,
    v: 1.07907125
  },
  {
    t: 1582268700,
    v: 1.07907
  },
  {
    t: 1582269000,
    v: 1.0790849999999998
  },
  {
    t: 1582269300,
    v: 1.0791175
  },
  {
    t: 1582269600,
    v: 1.0791725
  },
  {
    t: 1582269900,
    v: 1.0792362500000001
  },
  {
    t: 1582270200,
    v: 1.0793087499999998
  },
  {
    t: 1582270500,
    v: 1.079385
  },
  {
    t: 1582270800,
    v: 1.07944375
  },
  {
    t: 1582271100,
    v: 1.07952375
  },
  {
    t: 1582271400,
    v: 1.079575
  },
  {
    t: 1582271700,
    v: 1.0795925
  },
  {
    t: 1582272000,
    v: 1.07954
  },
  {
    t: 1582272300,
    v: 1.0794724999999998
  },
  {
    t: 1582272600,
    v: 1.0794575
  },
  {
    t: 1582272900,
    v: 1.0795074999999998
  },
  {
    t: 1582273200,
    v: 1.07957625
  },
  {
    t: 1582273500,
    v: 1.0796062499999999
  },
  {
    t: 1582273800,
    v: 1.07981875
  },
  {
    t: 1582274100,
    v: 1.0800175
  },
  {
    t: 1582274400,
    v: 1.0802475
  },
  {
    t: 1582274700,
    v: 1.0805387499999999
  },
  {
    t: 1582275000,
    v: 1.0807712499999997
  },
  {
    t: 1582275300,
    v: 1.0809425
  },
  {
    t: 1582275600,
    v: 1.0811575
  },
  {
    t: 1582275900,
    v: 1.08136625
  },
  {
    t: 1582276200,
    v: 1.0813925
  },
  {
    t: 1582276500,
    v: 1.08143
  },
  {
    t: 1582276800,
    v: 1.0814712499999999
  },
  {
    t: 1582277100,
    v: 1.0814962499999998
  },
  {
    t: 1582277400,
    v: 1.0815374999999998
  },
  {
    t: 1582277700,
    v: 1.08153875
  },
  {
    t: 1582278000,
    v: 1.081505
  },
  {
    t: 1582278300,
    v: 1.0814725
  },
  {
    t: 1582278600,
    v: 1.08145
  },
  {
    t: 1582278900,
    v: 1.081385
  },
  {
    t: 1582279200,
    v: 1.08128125
  },
  {
    t: 1582279500,
    v: 1.081145
  },
  {
    t: 1582279800,
    v: 1.0809525
  },
  {
    t: 1582280100,
    v: 1.080755
  },
  {
    t: 1582280400,
    v: 1.08057125
  },
  {
    t: 1582280700,
    v: 1.0804099999999999
  },
  {
    t: 1582281000,
    v: 1.0802687499999999
  },
  {
    t: 1582281300,
    v: 1.0801875
  },
  {
    t: 1582281600,
    v: 1.080155
  },
  {
    t: 1582281900,
    v: 1.0801325
  },
  {
    t: 1582282200,
    v: 1.0801049999999999
  },
  {
    t: 1582282500,
    v: 1.08012125
  },
  {
    t: 1582282800,
    v: 1.0800912499999997
  },
  {
    t: 1582283100,
    v: 1.0800599999999998
  },
  {
    t: 1582283400,
    v: 1.08001625
  },
  {
    t: 1582283700,
    v: 1.07995625
  },
  {
    t: 1582284000,
    v: 1.07994375
  },
  {
    t: 1582284300,
    v: 1.0799575000000001
  },
  {
    t: 1582284600,
    v: 1.079985
  },
  {
    t: 1582284900,
    v: 1.080015
  },
  {
    t: 1582285200,
    v: 1.08007875
  },
  {
    t: 1582285500,
    v: 1.08011125
  },
  {
    t: 1582285800,
    v: 1.08016625
  },
  {
    t: 1582286100,
    v: 1.0802625
  },
  {
    t: 1582286400,
    v: 1.0802637500000003
  },
  {
    t: 1582286700,
    v: 1.0802387500000001
  },
  {
    t: 1582287000,
    v: 1.08026
  },
  {
    t: 1582287300,
    v: 1.08028
  },
  {
    t: 1582287600,
    v: 1.0802875
  },
  {
    t: 1582287900,
    v: 1.08029875
  },
  {
    t: 1582288200,
    v: 1.0802675000000002
  },
  {
    t: 1582288500,
    v: 1.0801837500000002
  },
  {
    t: 1582288800,
    v: 1.0801524999999998
  },
  {
    t: 1582289100,
    v: 1.0801225
  },
  {
    t: 1582289400,
    v: 1.08011
  },
  {
    t: 1582289700,
    v: 1.0800212500000002
  },
  {
    t: 1582290000,
    v: 1.07997625
  },
  {
    t: 1582290300,
    v: 1.07997625
  },
  {
    t: 1582290600,
    v: 1.07997375
  },
  {
    t: 1582290900,
    v: 1.07999
  },
  {
    t: 1582291200,
    v: 1.080025
  },
  {
    t: 1582291500,
    v: 1.08004125
  },
  {
    t: 1582291800,
    v: 1.0800325000000002
  },
  {
    t: 1582292100,
    v: 1.0801437500000002
  },
  {
    t: 1582292400,
    v: 1.0802375
  },
  {
    t: 1582292700,
    v: 1.08031875
  },
  {
    t: 1582293000,
    v: 1.0804062499999998
  },
  {
    t: 1582293300,
    v: 1.08051125
  },
  {
    t: 1582293600,
    v: 1.08050125
  },
  {
    t: 1582293900,
    v: 1.0805425
  },
  {
    t: 1582294200,
    v: 1.08060625
  },
  {
    t: 1582294500,
    v: 1.08061875
  },
  {
    t: 1582294800,
    v: 1.08061125
  },
  {
    t: 1582295100,
    v: 1.0806
  },
  {
    t: 1582295400,
    v: 1.0806075
  },
  {
    t: 1582295700,
    v: 1.0806762499999998
  },
  {
    t: 1582296000,
    v: 1.0808437499999999
  },
  {
    t: 1582296300,
    v: 1.081335
  },
  {
    t: 1582296600,
    v: 1.081715
  },
  {
    t: 1582296900,
    v: 1.08209875
  },
  {
    t: 1582297200,
    v: 1.08254125
  },
  {
    t: 1582297500,
    v: 1.083025
  },
  {
    t: 1582297800,
    v: 1.0835925
  },
  {
    t: 1582298100,
    v: 1.0841075
  },
  {
    t: 1582298400,
    v: 1.08461375
  },
  {
    t: 1582298700,
    v: 1.0847912499999999
  },
  {
    t: 1582299000,
    v: 1.0849912499999999
  },
  {
    t: 1582299300,
    v: 1.08526875
  },
  {
    t: 1582299600,
    v: 1.08547875
  },
  {
    t: 1582299900,
    v: 1.0856412500000001
  },
  {
    t: 1582300200,
    v: 1.08564375
  },
  {
    t: 1582300500,
    v: 1.08560125
  },
  {
    t: 1582300800,
    v: 1.085625
  },
  {
    t: 1582301100,
    v: 1.08562375
  },
  {
    t: 1582301400,
    v: 1.0857050000000001
  },
  {
    t: 1582301700,
    v: 1.08573625
  },
  {
    t: 1582302000,
    v: 1.0857125
  },
  {
    t: 1582302300,
    v: 1.08566
  },
  {
    t: 1582302600,
    v: 1.0856612500000002
  },
  {
    t: 1582302900,
    v: 1.0856587500000001
  },
  {
    t: 1582303200,
    v: 1.08555375
  },
  {
    t: 1582303500,
    v: 1.085465
  },
  {
    t: 1582303800,
    v: 1.0854
  },
  {
    t: 1582304100,
    v: 1.0853074999999999
  },
  {
    t: 1582304400,
    v: 1.0852862499999998
  },
  {
    t: 1582304700,
    v: 1.0853
  },
  {
    t: 1582305000,
    v: 1.0853587500000001
  },
  {
    t: 1582305300,
    v: 1.0854475
  },
  {
    t: 1582305600,
    v: 1.0855837499999998
  },
  {
    t: 1582305900,
    v: 1.08568625
  },
  {
    t: 1582306200,
    v: 1.08573875
  },
  {
    t: 1582306500,
    v: 1.08579375
  },
  {
    t: 1582306800,
    v: 1.0858287500000001
  },
  {
    t: 1582307100,
    v: 1.0858425
  },
  {
    t: 1582307400,
    v: 1.08578
  },
  {
    t: 1582307700,
    v: 1.0857537499999999
  },
  {
    t: 1582308000,
    v: 1.0856999999999999
  },
  {
    t: 1582308300,
    v: 1.08569
  },
  {
    t: 1582308600,
    v: 1.08569875
  },
  {
    t: 1582308900,
    v: 1.08571875
  },
  {
    t: 1582309200,
    v: 1.0857337500000002
  },
  {
    t: 1582309500,
    v: 1.0857150000000002
  },
  {
    t: 1582309800,
    v: 1.0857575000000002
  },
  {
    t: 1582310100,
    v: 1.08575125
  },
  {
    t: 1582310400,
    v: 1.08576875
  },
  {
    t: 1582310700,
    v: 1.08577
  },
  {
    t: 1582311000,
    v: 1.0857325
  },
  {
    t: 1582311300,
    v: 1.08568
  },
  {
    t: 1582311600,
    v: 1.08561
  },
  {
    t: 1582311900,
    v: 1.08557375
  },
  {
    t: 1582312200,
    v: 1.0855412500000001
  },
  {
    t: 1582312500,
    v: 1.0855025
  },
  {
    t: 1582312800,
    v: 1.08550375
  },
  {
    t: 1582313100,
    v: 1.08547875
  },
  {
    t: 1582313400,
    v: 1.08546125
  },
  {
    t: 1582313700,
    v: 1.08543375
  },
  {
    t: 1582314000,
    v: 1.0854412500000001
  },
  {
    t: 1582314300,
    v: 1.0854750000000002
  },
  {
    t: 1582314600,
    v: 1.0854975
  },
  {
    t: 1582314900,
    v: 1.0855475
  },
  {
    t: 1582315200,
    v: 1.0855350000000001
  },
  {
    t: 1582315500,
    v: 1.08551625
  },
  {
    t: 1582315800,
    v: 1.0855075
  },
  {
    t: 1582316100,
    v: 1.0855249999999999
  },
  {
    t: 1582316400,
    v: 1.0855187499999999
  },
  {
    t: 1582316700,
    v: 1.08548
  },
  {
    t: 1582317000,
    v: 1.08541875
  },
  {
    t: 1582317300,
    v: 1.0853325
  },
  {
    t: 1582317600,
    v: 1.08527
  },
  {
    t: 1582317900,
    v: 1.0852
  },
  {
    t: 1582318200,
    v: 1.08512625
  },
  {
    t: 1582318500,
    v: 1.0850487500000001
  },
  {
    t: 1582318800,
    v: 1.0849812500000002
  },
  {
    t: 1582319100,
    v: 1.08492
  },
  {
    t: 1582319400,
    v: 1.0848837500000001
  },
  {
    t: 1582319700,
    v: 1.08485125
  },
  {
    t: 1582320000,
    v: 1.0848250000000002
  },
  {
    t: 1582320300,
    v: 1.0848262499999999
  },
  {
    t: 1582320600,
    v: 1.0848275
  },
  {
    t: 1582320900,
    v: 1.0848175
  },
  {
    t: 1582495500,
    v: 1.08451375
  },
  {
    t: 1582495800,
    v: 1.0842425
  },
  {
    t: 1582496100,
    v: 1.08398125
  },
  {
    t: 1582496400,
    v: 1.08374
  },
  {
    t: 1582496700,
    v: 1.0835137499999998
  },
  {
    t: 1582497000,
    v: 1.0832937500000002
  },
  {
    t: 1582497300,
    v: 1.08308375
  },
  {
    t: 1582497600,
    v: 1.08286625
  },
  {
    t: 1582497900,
    v: 1.08290875
  },
  {
    t: 1582498200,
    v: 1.0829324999999999
  },
  {
    t: 1582498500,
    v: 1.0829437499999999
  },
  {
    t: 1582498800,
    v: 1.0830362499999997
  },
  {
    t: 1582499100,
    v: 1.0831325
  },
  {
    t: 1582499400,
    v: 1.0832424999999999
  },
  {
    t: 1582499700,
    v: 1.0833362500000001
  },
  {
    t: 1582500000,
    v: 1.08342125
  },
  {
    t: 1582500300,
    v: 1.0835175
  },
  {
    t: 1582500600,
    v: 1.08359375
  },
  {
    t: 1582500900,
    v: 1.0836824999999999
  },
  {
    t: 1582501200,
    v: 1.08370875
  },
  {
    t: 1582501500,
    v: 1.0837175000000001
  },
  {
    t: 1582501800,
    v: 1.0836812500000002
  },
  {
    t: 1582502100,
    v: 1.08367125
  },
  {
    t: 1582502400,
    v: 1.08369875
  },
  {
    t: 1582502700,
    v: 1.08375
  },
  {
    t: 1582503000,
    v: 1.0837987500000001
  },
  {
    t: 1582503300,
    v: 1.0838462500000001
  },
  {
    t: 1582503600,
    v: 1.08385125
  },
  {
    t: 1582503900,
    v: 1.08389125
  },
  {
    t: 1582504200,
    v: 1.0839237499999999
  },
  {
    t: 1582504500,
    v: 1.0838887499999998
  },
  {
    t: 1582504800,
    v: 1.0837712499999999
  },
  {
    t: 1582505100,
    v: 1.0836625
  },
  {
    t: 1582505400,
    v: 1.0835675
  },
  {
    t: 1582505700,
    v: 1.0834625
  },
  {
    t: 1582506000,
    v: 1.0833712500000001
  },
  {
    t: 1582506300,
    v: 1.0832125
  },
  {
    t: 1582506600,
    v: 1.0830437499999999
  },
  {
    t: 1582506900,
    v: 1.08293375
  },
  {
    t: 1582507200,
    v: 1.0828775000000002
  },
  {
    t: 1582507500,
    v: 1.08280375
  },
  {
    t: 1582507800,
    v: 1.0827300000000002
  },
  {
    t: 1582508100,
    v: 1.08266875
  },
  {
    t: 1582508400,
    v: 1.0826075
  },
  {
    t: 1582508700,
    v: 1.08257625
  },
  {
    t: 1582509000,
    v: 1.0825975
  },
  {
    t: 1582509300,
    v: 1.08261375
  },
  {
    t: 1582509600,
    v: 1.08264
  },
  {
    t: 1582509900,
    v: 1.0826625
  },
  {
    t: 1582510200,
    v: 1.08271875
  },
  {
    t: 1582510500,
    v: 1.08275625
  },
  {
    t: 1582510800,
    v: 1.0827787500000001
  },
  {
    t: 1582511100,
    v: 1.08278375
  },
  {
    t: 1582511400,
    v: 1.0827575
  },
  {
    t: 1582511700,
    v: 1.08272625
  },
  {
    t: 1582512000,
    v: 1.0827125
  },
  {
    t: 1582512300,
    v: 1.0827175
  },
  {
    t: 1582512600,
    v: 1.0826824999999998
  },
  {
    t: 1582512900,
    v: 1.0825974999999999
  },
  {
    t: 1582513200,
    v: 1.0825375000000002
  },
  {
    t: 1582513500,
    v: 1.08247125
  },
  {
    t: 1582513800,
    v: 1.08242875
  },
  {
    t: 1582514100,
    v: 1.0823875
  },
  {
    t: 1582514400,
    v: 1.0823325
  },
  {
    t: 1582514700,
    v: 1.0822800000000001
  },
  {
    t: 1582515000,
    v: 1.0822337499999999
  },
  {
    t: 1582515300,
    v: 1.0822475
  },
  {
    t: 1582515600,
    v: 1.0822524999999998
  },
  {
    t: 1582515900,
    v: 1.082255
  },
  {
    t: 1582516200,
    v: 1.08225
  },
  {
    t: 1582516500,
    v: 1.0822475
  },
  {
    t: 1582516800,
    v: 1.08223375
  },
  {
    t: 1582517100,
    v: 1.0822225
  },
  {
    t: 1582517400,
    v: 1.082215
  },
  {
    t: 1582517700,
    v: 1.08219
  },
  {
    t: 1582518000,
    v: 1.0821625000000001
  },
  {
    t: 1582518300,
    v: 1.08214625
  },
  {
    t: 1582518600,
    v: 1.0821212500000001
  },
  {
    t: 1582518900,
    v: 1.08210625
  },
  {
    t: 1582519200,
    v: 1.0820874999999999
  },
  {
    t: 1582519500,
    v: 1.08205
  },
  {
    t: 1582519800,
    v: 1.0820375
  },
  {
    t: 1582520100,
    v: 1.08203125
  },
  {
    t: 1582520400,
    v: 1.08202
  },
  {
    t: 1582520700,
    v: 1.082015
  },
  {
    t: 1582521000,
    v: 1.08202
  },
  {
    t: 1582521300,
    v: 1.08202125
  },
  {
    t: 1582521600,
    v: 1.0820075
  },
  {
    t: 1582521900,
    v: 1.08199625
  },
  {
    t: 1582522200,
    v: 1.08198875
  },
  {
    t: 1582522500,
    v: 1.0819862500000001
  },
  {
    t: 1582522800,
    v: 1.08197375
  },
  {
    t: 1582523100,
    v: 1.08193375
  },
  {
    t: 1582523400,
    v: 1.081895
  },
  {
    t: 1582523700,
    v: 1.08181875
  },
  {
    t: 1582524000,
    v: 1.0818050000000001
  },
  {
    t: 1582524300,
    v: 1.0817975
  },
  {
    t: 1582524600,
    v: 1.0817774999999998
  },
  {
    t: 1582524900,
    v: 1.0817425
  },
  {
    t: 1582525200,
    v: 1.0817237499999999
  },
  {
    t: 1582525500,
    v: 1.0817425
  },
  {
    t: 1582525800,
    v: 1.08176875
  },
  {
    t: 1582526100,
    v: 1.081835
  },
  {
    t: 1582526400,
    v: 1.081845
  },
  {
    t: 1582526700,
    v: 1.0818349999999999
  },
  {
    t: 1582527000,
    v: 1.081825
  },
  {
    t: 1582527300,
    v: 1.08180125
  },
  {
    t: 1582527600,
    v: 1.0817474999999999
  },
  {
    t: 1582527900,
    v: 1.0816299999999999
  },
  {
    t: 1582528200,
    v: 1.0815137499999998
  },
  {
    t: 1582528500,
    v: 1.081405
  },
  {
    t: 1582528800,
    v: 1.0813512499999998
  },
  {
    t: 1582529100,
    v: 1.08132875
  },
  {
    t: 1582529400,
    v: 1.081285
  },
  {
    t: 1582529700,
    v: 1.08129
  },
  {
    t: 1582530000,
    v: 1.0812875
  },
  {
    t: 1582530300,
    v: 1.0813525
  },
  {
    t: 1582530600,
    v: 1.0814237500000001
  },
  {
    t: 1582530900,
    v: 1.08149375
  },
  {
    t: 1582531200,
    v: 1.08155
  },
  {
    t: 1582531500,
    v: 1.08168625
  },
  {
    t: 1582531800,
    v: 1.081785
  },
  {
    t: 1582532100,
    v: 1.08188125
  },
  {
    t: 1582532400,
    v: 1.08200625
  },
  {
    t: 1582532700,
    v: 1.0821725
  },
  {
    t: 1582533000,
    v: 1.08227
  },
  {
    t: 1582533300,
    v: 1.08233
  },
  {
    t: 1582533600,
    v: 1.0823900000000002
  },
  {
    t: 1582533900,
    v: 1.0823150000000001
  },
  {
    t: 1582534200,
    v: 1.08231875
  },
  {
    t: 1582534500,
    v: 1.08234625
  },
  {
    t: 1582534800,
    v: 1.08256375
  },
  {
    t: 1582535100,
    v: 1.08262875
  },
  {
    t: 1582535400,
    v: 1.08272625
  },
  {
    t: 1582535700,
    v: 1.0828075
  },
  {
    t: 1582536000,
    v: 1.0827775
  },
  {
    t: 1582536300,
    v: 1.0827925
  },
  {
    t: 1582536600,
    v: 1.08281125
  },
  {
    t: 1582536900,
    v: 1.0827900000000001
  },
  {
    t: 1582537200,
    v: 1.08254375
  },
  {
    t: 1582537500,
    v: 1.08230625
  },
  {
    t: 1582537800,
    v: 1.0820975
  },
  {
    t: 1582538100,
    v: 1.08193625
  },
  {
    t: 1582538400,
    v: 1.081885
  },
  {
    t: 1582538700,
    v: 1.0818949999999998
  },
  {
    t: 1582539000,
    v: 1.0818699999999999
  },
  {
    t: 1582539300,
    v: 1.08180375
  },
  {
    t: 1582539600,
    v: 1.0817674999999998
  },
  {
    t: 1582539900,
    v: 1.0817712499999999
  },
  {
    t: 1582540200,
    v: 1.0817637500000001
  },
  {
    t: 1582540500,
    v: 1.0817774999999998
  },
  {
    t: 1582540800,
    v: 1.08174125
  },
  {
    t: 1582541100,
    v: 1.08169625
  },
  {
    t: 1582541400,
    v: 1.08161375
  },
  {
    t: 1582541700,
    v: 1.08156625
  },
  {
    t: 1582542000,
    v: 1.0815237500000001
  },
  {
    t: 1582542300,
    v: 1.08153375
  },
  {
    t: 1582542600,
    v: 1.0816000000000001
  },
  {
    t: 1582542900,
    v: 1.08166
  },
  {
    t: 1582543200,
    v: 1.0817300000000003
  },
  {
    t: 1582543500,
    v: 1.0817737500000002
  },
  {
    t: 1582543800,
    v: 1.08187625
  },
  {
    t: 1582544100,
    v: 1.0819475
  },
  {
    t: 1582544400,
    v: 1.0820425
  },
  {
    t: 1582544700,
    v: 1.08211375
  },
  {
    t: 1582545000,
    v: 1.0821524999999999
  },
  {
    t: 1582545300,
    v: 1.082145
  },
  {
    t: 1582545600,
    v: 1.08214
  },
  {
    t: 1582545900,
    v: 1.0821075
  },
  {
    t: 1582546200,
    v: 1.0820887499999998
  },
  {
    t: 1582546500,
    v: 1.0821125
  },
  {
    t: 1582546800,
    v: 1.082125
  },
  {
    t: 1582547100,
    v: 1.0822037500000001
  },
  {
    t: 1582547400,
    v: 1.0821987499999999
  },
  {
    t: 1582547700,
    v: 1.08220625
  },
  {
    t: 1582548000,
    v: 1.08223625
  },
  {
    t: 1582548300,
    v: 1.0822524999999998
  },
  {
    t: 1582548600,
    v: 1.08224
  },
  {
    t: 1582548900,
    v: 1.08217625
  },
  {
    t: 1582549200,
    v: 1.082135
  },
  {
    t: 1582549500,
    v: 1.0820275
  },
  {
    t: 1582549800,
    v: 1.08197625
  },
  {
    t: 1582550100,
    v: 1.0819575
  },
  {
    t: 1582550400,
    v: 1.08193375
  },
  {
    t: 1582550700,
    v: 1.08193625
  },
  {
    t: 1582551000,
    v: 1.0819075
  },
  {
    t: 1582551300,
    v: 1.081905
  },
  {
    t: 1582551600,
    v: 1.08184625
  },
  {
    t: 1582551900,
    v: 1.08180625
  },
  {
    t: 1582552200,
    v: 1.0817750000000002
  },
  {
    t: 1582552500,
    v: 1.08170875
  },
  {
    t: 1582552800,
    v: 1.08168125
  },
  {
    t: 1582553100,
    v: 1.0816762500000001
  },
  {
    t: 1582553400,
    v: 1.0817125
  },
  {
    t: 1582553700,
    v: 1.08180875
  },
  {
    t: 1582554000,
    v: 1.082005
  },
  {
    t: 1582554300,
    v: 1.0822325000000002
  },
  {
    t: 1582554600,
    v: 1.08242
  },
  {
    t: 1582554900,
    v: 1.08261125
  },
  {
    t: 1582555200,
    v: 1.08284
  },
  {
    t: 1582555500,
    v: 1.0830875
  },
  {
    t: 1582555800,
    v: 1.0834575
  },
  {
    t: 1582556100,
    v: 1.0837474999999999
  },
  {
    t: 1582556400,
    v: 1.0840125
  },
  {
    t: 1582556700,
    v: 1.084305
  },
  {
    t: 1582557000,
    v: 1.0845825
  },
  {
    t: 1582557300,
    v: 1.08487
  },
  {
    t: 1582557600,
    v: 1.0851075000000001
  },
  {
    t: 1582557900,
    v: 1.0853237500000001
  },
  {
    t: 1582558200,
    v: 1.0853712500000001
  },
  {
    t: 1582558500,
    v: 1.08544
  },
  {
    t: 1582558800,
    v: 1.0854225
  },
  {
    t: 1582559100,
    v: 1.08542125
  },
  {
    t: 1582559400,
    v: 1.08547625
  },
  {
    t: 1582559700,
    v: 1.08551125
  },
  {
    t: 1582560000,
    v: 1.0855525000000001
  },
  {
    t: 1582560300,
    v: 1.0854849999999998
  },
  {
    t: 1582560600,
    v: 1.08541
  },
  {
    t: 1582560900,
    v: 1.08540375
  },
  {
    t: 1582561200,
    v: 1.08548375
  },
  {
    t: 1582561500,
    v: 1.0855275
  },
  {
    t: 1582561800,
    v: 1.08557875
  },
  {
    t: 1582562100,
    v: 1.08561125
  },
  {
    t: 1582562400,
    v: 1.08561125
  },
  {
    t: 1582562700,
    v: 1.085625
  },
  {
    t: 1582563000,
    v: 1.08568125
  },
  {
    t: 1582563300,
    v: 1.08570625
  },
  {
    t: 1582563600,
    v: 1.085705
  },
  {
    t: 1582563900,
    v: 1.08567625
  },
  {
    t: 1582564200,
    v: 1.085685
  },
  {
    t: 1582564500,
    v: 1.08569625
  },
  {
    t: 1582564800,
    v: 1.0857437500000002
  },
  {
    t: 1582565100,
    v: 1.0858462500000001
  },
  {
    t: 1582565400,
    v: 1.0859812500000001
  },
  {
    t: 1582565700,
    v: 1.0860775
  },
  {
    t: 1582566000,
    v: 1.08615375
  },
  {
    t: 1582566300,
    v: 1.08627125
  },
  {
    t: 1582566600,
    v: 1.08636375
  },
  {
    t: 1582566900,
    v: 1.08650875
  },
  {
    t: 1582567200,
    v: 1.0866025000000001
  },
  {
    t: 1582567500,
    v: 1.0867099999999998
  },
  {
    t: 1582567800,
    v: 1.08678375
  },
  {
    t: 1582568100,
    v: 1.0868674999999999
  },
  {
    t: 1582568400,
    v: 1.086915
  },
  {
    t: 1582568700,
    v: 1.0869175
  },
  {
    t: 1582569000,
    v: 1.08688
  },
  {
    t: 1582569300,
    v: 1.0868125000000002
  },
  {
    t: 1582569600,
    v: 1.0867487500000002
  },
  {
    t: 1582569900,
    v: 1.086675
  },
  {
    t: 1582570200,
    v: 1.08655875
  },
  {
    t: 1582570500,
    v: 1.0864775
  },
  {
    t: 1582570800,
    v: 1.08641125
  },
  {
    t: 1582571100,
    v: 1.08629375
  },
  {
    t: 1582571400,
    v: 1.0862025
  },
  {
    t: 1582571700,
    v: 1.0861375
  },
  {
    t: 1582572000,
    v: 1.086025
  },
  {
    t: 1582572300,
    v: 1.0858975
  },
  {
    t: 1582572600,
    v: 1.08576875
  },
  {
    t: 1582572900,
    v: 1.0855675
  },
  {
    t: 1582573200,
    v: 1.0853975
  },
  {
    t: 1582573500,
    v: 1.08525625
  },
  {
    t: 1582573800,
    v: 1.0850937500000002
  },
  {
    t: 1582574100,
    v: 1.0848775
  },
  {
    t: 1582574400,
    v: 1.08476
  },
  {
    t: 1582574700,
    v: 1.0846624999999999
  },
  {
    t: 1582575000,
    v: 1.084615
  },
  {
    t: 1582575300,
    v: 1.0846225
  },
  {
    t: 1582575600,
    v: 1.0846275
  },
  {
    t: 1582575900,
    v: 1.0846475
  },
  {
    t: 1582576200,
    v: 1.08468875
  },
  {
    t: 1582576500,
    v: 1.08479875
  },
  {
    t: 1582576800,
    v: 1.0849024999999999
  },
  {
    t: 1582577100,
    v: 1.0850087499999999
  },
  {
    t: 1582577400,
    v: 1.0851012500000001
  },
  {
    t: 1582577700,
    v: 1.08515
  },
  {
    t: 1582578000,
    v: 1.085175
  },
  {
    t: 1582578300,
    v: 1.0852300000000001
  },
  {
    t: 1582578600,
    v: 1.08530125
  },
  {
    t: 1582578900,
    v: 1.08531125
  },
  {
    t: 1582579200,
    v: 1.08531375
  },
  {
    t: 1582579500,
    v: 1.0852849999999998
  },
  {
    t: 1582579800,
    v: 1.0852600000000001
  },
  {
    t: 1582580100,
    v: 1.085245
  },
  {
    t: 1582580400,
    v: 1.0852600000000001
  },
  {
    t: 1582580700,
    v: 1.08523875
  },
  {
    t: 1582581000,
    v: 1.0852000000000002
  },
  {
    t: 1582581300,
    v: 1.0851962499999999
  },
  {
    t: 1582581600,
    v: 1.08516125
  },
  {
    t: 1582581900,
    v: 1.0851600000000001
  },
  {
    t: 1582582200,
    v: 1.0851625000000003
  },
  {
    t: 1582582500,
    v: 1.0851625
  },
  {
    t: 1582582800,
    v: 1.08517625
  },
  {
    t: 1582583100,
    v: 1.0851912499999998
  },
  {
    t: 1582583400,
    v: 1.0852175
  },
  {
    t: 1582583700,
    v: 1.08524
  },
  {
    t: 1582584000,
    v: 1.0852612499999998
  },
  {
    t: 1582584300,
    v: 1.08529625
  },
  {
    t: 1582584600,
    v: 1.08527625
  },
  {
    t: 1582584900,
    v: 1.0852787499999998
  },
  {
    t: 1582585200,
    v: 1.0852699999999997
  },
  {
    t: 1582585500,
    v: 1.08526
  },
  {
    t: 1582585800,
    v: 1.085205
  },
  {
    t: 1582586100,
    v: 1.08515375
  },
  {
    t: 1582586400,
    v: 1.08512375
  },
  {
    t: 1582586700,
    v: 1.0850575
  },
  {
    t: 1582587000,
    v: 1.08507125
  },
  {
    t: 1582587300,
    v: 1.0850925
  },
  {
    t: 1582587600,
    v: 1.0850875000000002
  },
  {
    t: 1582587900,
    v: 1.08507
  },
  {
    t: 1582588200,
    v: 1.08507375
  },
  {
    t: 1582588500,
    v: 1.08505625
  },
  {
    t: 1582588800,
    v: 1.08504625
  },
  {
    t: 1582589100,
    v: 1.08506125
  },
  {
    t: 1582589400,
    v: 1.08505375
  },
  {
    t: 1582589700,
    v: 1.0850487500000001
  },
  {
    t: 1582590000,
    v: 1.08505
  },
  {
    t: 1582590300,
    v: 1.08506375
  },
  {
    t: 1582590600,
    v: 1.08508125
  },
  {
    t: 1582590900,
    v: 1.0851175000000002
  },
  {
    t: 1582591200,
    v: 1.0851374999999999
  },
  {
    t: 1582591500,
    v: 1.08515
  },
  {
    t: 1582591800,
    v: 1.0851375
  },
  {
    t: 1582592100,
    v: 1.08512875
  },
  {
    t: 1582592400,
    v: 1.08510375
  },
  {
    t: 1582592700,
    v: 1.08507625
  },
  {
    t: 1582593000,
    v: 1.0850387499999998
  },
  {
    t: 1582593300,
    v: 1.08500375
  },
  {
    t: 1582593600,
    v: 1.0850499999999998
  },
  {
    t: 1582593900,
    v: 1.08510375
  },
  {
    t: 1582594200,
    v: 1.0851887500000001
  },
  {
    t: 1582594500,
    v: 1.085265
  },
  {
    t: 1582594800,
    v: 1.0853525
  },
  {
    t: 1582595100,
    v: 1.0854549999999998
  },
  {
    t: 1582595400,
    v: 1.08556875
  },
  {
    t: 1582595700,
    v: 1.08569125
  },
  {
    t: 1582596000,
    v: 1.08576
  },
  {
    t: 1582596300,
    v: 1.08582375
  },
  {
    t: 1582596600,
    v: 1.08585875
  },
  {
    t: 1582596900,
    v: 1.0858875
  },
  {
    t: 1582597200,
    v: 1.0859225
  },
  {
    t: 1582597500,
    v: 1.0859412499999999
  },
  {
    t: 1582597800,
    v: 1.08598
  },
  {
    t: 1582598100,
    v: 1.086015
  },
  {
    t: 1582598400,
    v: 1.08601
  },
  {
    t: 1582598700,
    v: 1.0859925
  },
  {
    t: 1582599000,
    v: 1.0859887499999998
  },
  {
    t: 1582599300,
    v: 1.0860287499999999
  },
  {
    t: 1582599600,
    v: 1.08606625
  },
  {
    t: 1582599900,
    v: 1.0861162500000001
  },
  {
    t: 1582600200,
    v: 1.0861524999999999
  },
  {
    t: 1582600500,
    v: 1.0861825
  },
  {
    t: 1582600800,
    v: 1.0862412499999998
  },
  {
    t: 1582601100,
    v: 1.0863162499999999
  },
  {
    t: 1582601400,
    v: 1.08640375
  },
  {
    t: 1582601700,
    v: 1.0864525
  },
  {
    t: 1582602000,
    v: 1.0864800000000001
  },
  {
    t: 1582602300,
    v: 1.086505
  },
  {
    t: 1582602600,
    v: 1.0865137500000002
  },
  {
    t: 1582602900,
    v: 1.0865025
  },
  {
    t: 1582603200,
    v: 1.0864500000000001
  },
  {
    t: 1582603500,
    v: 1.086405
  },
  {
    t: 1582603800,
    v: 1.08634625
  },
  {
    t: 1582604100,
    v: 1.0862725
  },
  {
    t: 1582604400,
    v: 1.0862175
  },
  {
    t: 1582604700,
    v: 1.086165
  },
  {
    t: 1582605000,
    v: 1.0861224999999999
  },
  {
    t: 1582605300,
    v: 1.086105
  },
  {
    t: 1582605600,
    v: 1.0861212500000001
  },
  {
    t: 1582605900,
    v: 1.0861100000000001
  },
  {
    t: 1582606200,
    v: 1.08610125
  },
  {
    t: 1582606500,
    v: 1.0860925000000001
  },
  {
    t: 1582606800,
    v: 1.08607125
  },
  {
    t: 1582607100,
    v: 1.08605625
  },
  {
    t: 1582607400,
    v: 1.08603875
  },
  {
    t: 1582607700,
    v: 1.0860025000000002
  },
  {
    t: 1582608000,
    v: 1.0859425
  },
  {
    t: 1582608300,
    v: 1.0858637500000001
  },
  {
    t: 1582608600,
    v: 1.0857675
  },
  {
    t: 1582608900,
    v: 1.08569625
  },
  {
    t: 1582609200,
    v: 1.08561375
  },
  {
    t: 1582609500,
    v: 1.0855212500000002
  },
  {
    t: 1582609800,
    v: 1.08544375
  },
  {
    t: 1582610100,
    v: 1.08535125
  },
  {
    t: 1582610400,
    v: 1.0852712500000001
  },
  {
    t: 1582610700,
    v: 1.0852025
  },
  {
    t: 1582611000,
    v: 1.0851712500000001
  },
  {
    t: 1582611300,
    v: 1.0851387499999998
  },
  {
    t: 1582611600,
    v: 1.0851312499999999
  },
  {
    t: 1582611900,
    v: 1.0851649999999997
  },
  {
    t: 1582612200,
    v: 1.08520625
  },
  {
    t: 1582612500,
    v: 1.0852549999999999
  },
  {
    t: 1582612800,
    v: 1.08528
  },
  {
    t: 1582613100,
    v: 1.08531875
  },
  {
    t: 1582613400,
    v: 1.08535
  },
  {
    t: 1582613700,
    v: 1.0853412500000001
  },
  {
    t: 1582614000,
    v: 1.08531625
  },
  {
    t: 1582614300,
    v: 1.0852425
  },
  {
    t: 1582614600,
    v: 1.08517
  },
  {
    t: 1582614900,
    v: 1.0851250000000001
  },
  {
    t: 1582615200,
    v: 1.08512
  },
  {
    t: 1582615500,
    v: 1.08513125
  },
  {
    t: 1582615800,
    v: 1.085065
  },
  {
    t: 1582616100,
    v: 1.0850324999999998
  },
  {
    t: 1582616400,
    v: 1.0851075
  },
  {
    t: 1582616700,
    v: 1.08520375
  },
  {
    t: 1582617000,
    v: 1.08524375
  },
  {
    t: 1582617300,
    v: 1.08526875
  },
  {
    t: 1582617600,
    v: 1.08524875
  },
  {
    t: 1582617900,
    v: 1.0852575
  },
  {
    t: 1582618200,
    v: 1.0853325000000003
  },
  {
    t: 1582618500,
    v: 1.0854075
  },
  {
    t: 1582618800,
    v: 1.08545125
  },
  {
    t: 1582619100,
    v: 1.0855350000000001
  },
  {
    t: 1582619400,
    v: 1.0856325
  },
  {
    t: 1582619700,
    v: 1.0857775
  },
  {
    t: 1582620000,
    v: 1.08592375
  },
  {
    t: 1582620300,
    v: 1.08605
  },
  {
    t: 1582620600,
    v: 1.08616625
  },
  {
    t: 1582620900,
    v: 1.0862699999999998
  },
  {
    t: 1582621200,
    v: 1.0863462499999998
  },
  {
    t: 1582621500,
    v: 1.086365
  },
  {
    t: 1582621800,
    v: 1.0863399999999999
  },
  {
    t: 1582622100,
    v: 1.0862987499999999
  },
  {
    t: 1582622400,
    v: 1.08626625
  },
  {
    t: 1582622700,
    v: 1.0861775
  },
  {
    t: 1582623000,
    v: 1.08609875
  },
  {
    t: 1582623300,
    v: 1.0860237499999998
  },
  {
    t: 1582623600,
    v: 1.08587875
  },
  {
    t: 1582623900,
    v: 1.08573
  },
  {
    t: 1582624200,
    v: 1.08560125
  },
  {
    t: 1582624500,
    v: 1.0854625
  },
  {
    t: 1582624800,
    v: 1.085275
  },
  {
    t: 1582625100,
    v: 1.0850925
  },
  {
    t: 1582625400,
    v: 1.0849187500000002
  },
  {
    t: 1582625700,
    v: 1.08473375
  },
  {
    t: 1582626000,
    v: 1.0845625
  },
  {
    t: 1582626300,
    v: 1.0843749999999999
  },
  {
    t: 1582626600,
    v: 1.084225
  },
  {
    t: 1582626900,
    v: 1.0840775
  },
  {
    t: 1582627200,
    v: 1.0839874999999999
  },
  {
    t: 1582627500,
    v: 1.0839375
  },
  {
    t: 1582627800,
    v: 1.0838612499999998
  },
  {
    t: 1582628100,
    v: 1.08380375
  },
  {
    t: 1582628400,
    v: 1.08373
  },
  {
    t: 1582628700,
    v: 1.08371125
  },
  {
    t: 1582629000,
    v: 1.08367625
  },
  {
    t: 1582629300,
    v: 1.08361125
  },
  {
    t: 1582629600,
    v: 1.08354625
  },
  {
    t: 1582629900,
    v: 1.0834575
  },
  {
    t: 1582630200,
    v: 1.08338
  },
  {
    t: 1582630500,
    v: 1.08331125
  },
  {
    t: 1582630800,
    v: 1.08329125
  },
  {
    t: 1582631100,
    v: 1.0832825000000001
  },
  {
    t: 1582631400,
    v: 1.0833
  },
  {
    t: 1582631700,
    v: 1.08334125
  },
  {
    t: 1582632000,
    v: 1.0833525000000002
  },
  {
    t: 1582632300,
    v: 1.08335875
  },
  {
    t: 1582632600,
    v: 1.0834137499999998
  },
  {
    t: 1582632900,
    v: 1.08347125
  },
  {
    t: 1582633200,
    v: 1.08349375
  },
  {
    t: 1582633500,
    v: 1.083485
  },
  {
    t: 1582633800,
    v: 1.08349125
  },
  {
    t: 1582634100,
    v: 1.0834674999999998
  },
  {
    t: 1582634400,
    v: 1.083515
  },
  {
    t: 1582634700,
    v: 1.083545
  },
  {
    t: 1582635000,
    v: 1.0835087499999998
  },
  {
    t: 1582635300,
    v: 1.0834962499999998
  },
  {
    t: 1582635600,
    v: 1.0835375
  },
  {
    t: 1582635900,
    v: 1.08359875
  },
  {
    t: 1582636200,
    v: 1.08364
  },
  {
    t: 1582636500,
    v: 1.0837237499999999
  },
  {
    t: 1582636800,
    v: 1.08379
  },
  {
    t: 1582637100,
    v: 1.08394375
  },
  {
    t: 1582637400,
    v: 1.0841112499999999
  },
  {
    t: 1582637700,
    v: 1.084225
  },
  {
    t: 1582638000,
    v: 1.084305
  },
  {
    t: 1582638300,
    v: 1.08432
  },
  {
    t: 1582638600,
    v: 1.08436125
  },
  {
    t: 1582638900,
    v: 1.08435375
  },
  {
    t: 1582639200,
    v: 1.0842887499999998
  },
  {
    t: 1582639500,
    v: 1.084245
  },
  {
    t: 1582639800,
    v: 1.0841875
  },
  {
    t: 1582640100,
    v: 1.0841162500000001
  },
  {
    t: 1582640400,
    v: 1.0840675
  },
  {
    t: 1582640700,
    v: 1.08408125
  },
  {
    t: 1582641000,
    v: 1.0840887500000003
  },
  {
    t: 1582641300,
    v: 1.08409375
  },
  {
    t: 1582641600,
    v: 1.08416625
  },
  {
    t: 1582641900,
    v: 1.08411
  },
  {
    t: 1582642200,
    v: 1.0840224999999999
  },
  {
    t: 1582642500,
    v: 1.0839762499999999
  },
  {
    t: 1582642800,
    v: 1.08395375
  },
  {
    t: 1582643100,
    v: 1.08387875
  },
  {
    t: 1582643400,
    v: 1.0838
  },
  {
    t: 1582643700,
    v: 1.0837375
  },
  {
    t: 1582644000,
    v: 1.0837925
  },
  {
    t: 1582644300,
    v: 1.08391
  },
  {
    t: 1582644600,
    v: 1.0840975
  },
  {
    t: 1582644900,
    v: 1.08427375
  },
  {
    t: 1582645200,
    v: 1.0844162499999999
  },
  {
    t: 1582645500,
    v: 1.0846725
  },
  {
    t: 1582645800,
    v: 1.08499375
  },
  {
    t: 1582646100,
    v: 1.08534875
  },
  {
    t: 1582646400,
    v: 1.0855675
  },
  {
    t: 1582646700,
    v: 1.0858025
  },
  {
    t: 1582647000,
    v: 1.0860475
  },
  {
    t: 1582647300,
    v: 1.0863975
  },
  {
    t: 1582647600,
    v: 1.0867974999999999
  },
  {
    t: 1582647900,
    v: 1.08707375
  },
  {
    t: 1582648200,
    v: 1.0872662499999999
  },
  {
    t: 1582648500,
    v: 1.0874475
  },
  {
    t: 1582648800,
    v: 1.0876549999999998
  },
  {
    t: 1582649100,
    v: 1.0877325
  },
  {
    t: 1582649400,
    v: 1.0877075
  },
  {
    t: 1582649700,
    v: 1.0875875
  },
  {
    t: 1582650000,
    v: 1.0874737499999998
  },
  {
    t: 1582650300,
    v: 1.08738
  },
  {
    t: 1582650600,
    v: 1.0872975
  },
  {
    t: 1582650900,
    v: 1.0872487499999999
  },
  {
    t: 1582651200,
    v: 1.0871612499999999
  },
  {
    t: 1582651500,
    v: 1.0871899999999999
  },
  {
    t: 1582651800,
    v: 1.08728625
  },
  {
    t: 1582652100,
    v: 1.08733375
  },
  {
    t: 1582652400,
    v: 1.08738875
  },
  {
    t: 1582652700,
    v: 1.08746875
  },
  {
    t: 1582653000,
    v: 1.0875774999999999
  },
  {
    t: 1582653300,
    v: 1.08766625
  },
  {
    t: 1582653600,
    v: 1.08774625
  },
  {
    t: 1582653900,
    v: 1.08778375
  },
  {
    t: 1582654200,
    v: 1.08779625
  },
  {
    t: 1582654500,
    v: 1.0878887499999998
  },
  {
    t: 1582654800,
    v: 1.087895
  },
  {
    t: 1582655100,
    v: 1.08787
  },
  {
    t: 1582655400,
    v: 1.08776875
  },
  {
    t: 1582655700,
    v: 1.087655
  },
  {
    t: 1582656000,
    v: 1.0875725
  },
  {
    t: 1582656300,
    v: 1.08753875
  },
  {
    t: 1582656600,
    v: 1.08755
  },
  {
    t: 1582656900,
    v: 1.08751625
  },
  {
    t: 1582657200,
    v: 1.0874675
  },
  {
    t: 1582657500,
    v: 1.0875325
  },
  {
    t: 1582657800,
    v: 1.0876125
  },
  {
    t: 1582658100,
    v: 1.0876937500000001
  },
  {
    t: 1582658400,
    v: 1.0877675
  },
  {
    t: 1582658700,
    v: 1.08782875
  },
  {
    t: 1582659000,
    v: 1.08791375
  },
  {
    t: 1582659300,
    v: 1.088055
  },
  {
    t: 1582659600,
    v: 1.0881825
  },
  {
    t: 1582659900,
    v: 1.0882275000000001
  },
  {
    t: 1582660200,
    v: 1.0882525
  },
  {
    t: 1582660500,
    v: 1.0882725
  },
  {
    t: 1582660800,
    v: 1.08821875
  },
  {
    t: 1582661100,
    v: 1.0881625
  },
  {
    t: 1582661400,
    v: 1.0881250000000002
  },
  {
    t: 1582661700,
    v: 1.08802625
  },
  {
    t: 1582662000,
    v: 1.08802625
  },
  {
    t: 1582662300,
    v: 1.08801625
  },
  {
    t: 1582662600,
    v: 1.0880524999999999
  },
  {
    t: 1582662900,
    v: 1.08809
  },
  {
    t: 1582663200,
    v: 1.0881687500000001
  },
  {
    t: 1582663500,
    v: 1.0882512500000001
  },
  {
    t: 1582663800,
    v: 1.0882600000000002
  },
  {
    t: 1582664100,
    v: 1.0882825
  },
  {
    t: 1582664400,
    v: 1.0882800000000001
  },
  {
    t: 1582664700,
    v: 1.08827
  },
  {
    t: 1582665000,
    v: 1.0882625000000001
  },
  {
    t: 1582665300,
    v: 1.08823125
  },
  {
    t: 1582665600,
    v: 1.08823375
  },
  {
    t: 1582665900,
    v: 1.0882662500000002
  },
  {
    t: 1582666200,
    v: 1.08830875
  },
  {
    t: 1582666500,
    v: 1.08833125
  },
  {
    t: 1582666800,
    v: 1.088325
  },
  {
    t: 1582667100,
    v: 1.08831875
  },
  {
    t: 1582667400,
    v: 1.088295
  },
  {
    t: 1582667700,
    v: 1.08827375
  },
  {
    t: 1582668000,
    v: 1.0882075
  },
  {
    t: 1582668300,
    v: 1.0881275
  },
  {
    t: 1582668600,
    v: 1.08806
  },
  {
    t: 1582668900,
    v: 1.0880087500000002
  },
  {
    t: 1582669200,
    v: 1.0879825
  },
  {
    t: 1582669500,
    v: 1.0879525
  },
  {
    t: 1582669800,
    v: 1.08795
  },
  {
    t: 1582670100,
    v: 1.08796125
  },
  {
    t: 1582670400,
    v: 1.087985
  },
  {
    t: 1582670700,
    v: 1.08799
  },
  {
    t: 1582671000,
    v: 1.08801
  },
  {
    t: 1582671300,
    v: 1.08802125
  },
  {
    t: 1582671600,
    v: 1.08800125
  },
  {
    t: 1582671900,
    v: 1.08799375
  },
  {
    t: 1582672200,
    v: 1.08796875
  },
  {
    t: 1582672500,
    v: 1.0879349999999999
  },
  {
    t: 1582672800,
    v: 1.08790875
  },
  {
    t: 1582673100,
    v: 1.08788625
  },
  {
    t: 1582673400,
    v: 1.08783375
  },
  {
    t: 1582673700,
    v: 1.0878075
  },
  {
    t: 1582674000,
    v: 1.08778125
  },
  {
    t: 1582674300,
    v: 1.0877662499999998
  },
  {
    t: 1582674600,
    v: 1.08775875
  },
  {
    t: 1582674900,
    v: 1.0877724999999998
  },
  {
    t: 1582675200,
    v: 1.0877712499999999
  },
  {
    t: 1582675500,
    v: 1.0877562499999998
  },
  {
    t: 1582675800,
    v: 1.0877475
  },
  {
    t: 1582676100,
    v: 1.0877124999999999
  },
  {
    t: 1582676400,
    v: 1.08762625
  },
  {
    t: 1582676700,
    v: 1.08748875
  },
  {
    t: 1582677000,
    v: 1.0873712500000001
  },
  {
    t: 1582677300,
    v: 1.08725
  },
  {
    t: 1582677600,
    v: 1.08713875
  },
  {
    t: 1582677900,
    v: 1.08705125
  },
  {
    t: 1582678200,
    v: 1.08696875
  },
  {
    t: 1582678500,
    v: 1.0868925000000003
  },
  {
    t: 1582678800,
    v: 1.08689125
  },
  {
    t: 1582679100,
    v: 1.0869312500000001
  },
  {
    t: 1582679400,
    v: 1.0869737499999998
  },
  {
    t: 1582679700,
    v: 1.08701375
  },
  {
    t: 1582680000,
    v: 1.08707625
  },
  {
    t: 1582680300,
    v: 1.08713125
  },
  {
    t: 1582680600,
    v: 1.0871712500000001
  },
  {
    t: 1582680900,
    v: 1.0872000000000002
  },
  {
    t: 1582681200,
    v: 1.08721375
  },
  {
    t: 1582681500,
    v: 1.0872375
  },
  {
    t: 1582681800,
    v: 1.0872375
  },
  {
    t: 1582682100,
    v: 1.087245
  },
  {
    t: 1582682400,
    v: 1.0872425
  },
  {
    t: 1582682700,
    v: 1.08722875
  },
  {
    t: 1582683000,
    v: 1.08719375
  },
  {
    t: 1582683300,
    v: 1.0871737499999998
  },
  {
    t: 1582683600,
    v: 1.08714375
  },
  {
    t: 1582683900,
    v: 1.08710625
  },
  {
    t: 1582684200,
    v: 1.0870324999999998
  },
  {
    t: 1582684500,
    v: 1.08692375
  },
  {
    t: 1582684800,
    v: 1.08681875
  },
  {
    t: 1582685100,
    v: 1.0867212499999999
  },
  {
    t: 1582685400,
    v: 1.08667625
  },
  {
    t: 1582685700,
    v: 1.08664875
  },
  {
    t: 1582686000,
    v: 1.08664625
  },
  {
    t: 1582686300,
    v: 1.0866449999999999
  },
  {
    t: 1582686600,
    v: 1.086665
  },
  {
    t: 1582686900,
    v: 1.0867075
  },
  {
    t: 1582687200,
    v: 1.08674
  },
  {
    t: 1582687500,
    v: 1.08674375
  },
  {
    t: 1582687800,
    v: 1.08673
  },
  {
    t: 1582688100,
    v: 1.08670625
  },
  {
    t: 1582688400,
    v: 1.086665
  },
  {
    t: 1582688700,
    v: 1.0866224999999998
  },
  {
    t: 1582689000,
    v: 1.086595
  },
  {
    t: 1582689300,
    v: 1.08657125
  },
  {
    t: 1582689600,
    v: 1.0865525
  },
  {
    t: 1582689900,
    v: 1.0865737500000001
  },
  {
    t: 1582690200,
    v: 1.0866125
  },
  {
    t: 1582690500,
    v: 1.0866175
  },
  {
    t: 1582690800,
    v: 1.086625
  },
  {
    t: 1582691100,
    v: 1.08663875
  },
  {
    t: 1582691400,
    v: 1.086655
  },
  {
    t: 1582691700,
    v: 1.0866562499999999
  },
  {
    t: 1582692000,
    v: 1.08666625
  },
  {
    t: 1582692300,
    v: 1.0866587499999998
  },
  {
    t: 1582692600,
    v: 1.0866487500000002
  },
  {
    t: 1582692900,
    v: 1.0866600000000002
  },
  {
    t: 1582693200,
    v: 1.0866850000000001
  },
  {
    t: 1582693500,
    v: 1.0867075000000002
  },
  {
    t: 1582693800,
    v: 1.0867499999999999
  },
  {
    t: 1582694100,
    v: 1.08679
  },
  {
    t: 1582694400,
    v: 1.08684125
  },
  {
    t: 1582694700,
    v: 1.0869275
  },
  {
    t: 1582695000,
    v: 1.086995
  },
  {
    t: 1582695300,
    v: 1.08706875
  },
  {
    t: 1582695600,
    v: 1.08715625
  },
  {
    t: 1582695900,
    v: 1.0872600000000001
  },
  {
    t: 1582696200,
    v: 1.08732375
  },
  {
    t: 1582696500,
    v: 1.08740125
  },
  {
    t: 1582696800,
    v: 1.0874625
  },
  {
    t: 1582697100,
    v: 1.08747625
  },
  {
    t: 1582697400,
    v: 1.087495
  },
  {
    t: 1582697700,
    v: 1.08752375
  },
  {
    t: 1582698000,
    v: 1.0875175000000001
  },
  {
    t: 1582698300,
    v: 1.0874825000000001
  },
  {
    t: 1582698600,
    v: 1.0874424999999999
  },
  {
    t: 1582698900,
    v: 1.087385
  },
  {
    t: 1582699200,
    v: 1.08732
  },
  {
    t: 1582699500,
    v: 1.0872775000000001
  },
  {
    t: 1582699800,
    v: 1.08724625
  },
  {
    t: 1582700100,
    v: 1.0872300000000001
  },
  {
    t: 1582700400,
    v: 1.08721625
  },
  {
    t: 1582700700,
    v: 1.0871787499999999
  },
  {
    t: 1582701000,
    v: 1.0871449999999998
  },
  {
    t: 1582701300,
    v: 1.0871324999999998
  },
  {
    t: 1582701600,
    v: 1.0871099999999998
  },
  {
    t: 1582701900,
    v: 1.0870475
  },
  {
    t: 1582702200,
    v: 1.08700875
  },
  {
    t: 1582702500,
    v: 1.0869775
  },
  {
    t: 1582702800,
    v: 1.0869787499999999
  },
  {
    t: 1582703100,
    v: 1.08696375
  },
  {
    t: 1582703400,
    v: 1.0870225
  },
  {
    t: 1582703700,
    v: 1.08702875
  },
  {
    t: 1582704000,
    v: 1.0871024999999999
  },
  {
    t: 1582704300,
    v: 1.0872662499999999
  },
  {
    t: 1582704600,
    v: 1.08739875
  },
  {
    t: 1582704900,
    v: 1.087475
  },
  {
    t: 1582705200,
    v: 1.0875237500000001
  },
  {
    t: 1582705500,
    v: 1.0876625
  },
  {
    t: 1582705800,
    v: 1.0877400000000002
  },
  {
    t: 1582706100,
    v: 1.0878625000000002
  },
  {
    t: 1582706400,
    v: 1.08797875
  },
  {
    t: 1582706700,
    v: 1.0881150000000002
  },
  {
    t: 1582707000,
    v: 1.088335
  },
  {
    t: 1582707300,
    v: 1.08850625
  },
  {
    t: 1582707600,
    v: 1.0886675000000001
  },
  {
    t: 1582707900,
    v: 1.088835
  },
  {
    t: 1582708200,
    v: 1.08895875
  },
  {
    t: 1582708500,
    v: 1.08907875
  },
  {
    t: 1582708800,
    v: 1.0891825
  },
  {
    t: 1582709100,
    v: 1.0891425
  },
  {
    t: 1582709400,
    v: 1.0890874999999998
  },
  {
    t: 1582709700,
    v: 1.08899625
  },
  {
    t: 1582710000,
    v: 1.08896
  },
  {
    t: 1582710300,
    v: 1.0889499999999999
  },
  {
    t: 1582710600,
    v: 1.08890625
  },
  {
    t: 1582710900,
    v: 1.0888900000000001
  },
  {
    t: 1582711200,
    v: 1.0887775
  },
  {
    t: 1582711500,
    v: 1.08866375
  },
  {
    t: 1582711800,
    v: 1.08849375
  },
  {
    t: 1582712100,
    v: 1.088405
  },
  {
    t: 1582712400,
    v: 1.0883037500000001
  },
  {
    t: 1582712700,
    v: 1.08805875
  },
  {
    t: 1582713000,
    v: 1.08792
  },
  {
    t: 1582713300,
    v: 1.0877875
  },
  {
    t: 1582713600,
    v: 1.0877287500000001
  },
  {
    t: 1582713900,
    v: 1.0877375
  },
  {
    t: 1582714200,
    v: 1.08778
  },
  {
    t: 1582714500,
    v: 1.0878925
  },
  {
    t: 1582714800,
    v: 1.08798375
  },
  {
    t: 1582715100,
    v: 1.08814
  },
  {
    t: 1582715400,
    v: 1.08824875
  },
  {
    t: 1582715700,
    v: 1.08833625
  },
  {
    t: 1582716000,
    v: 1.0883975
  },
  {
    t: 1582716300,
    v: 1.08843375
  },
  {
    t: 1582716600,
    v: 1.0883462499999998
  },
  {
    t: 1582716900,
    v: 1.088215
  },
  {
    t: 1582717200,
    v: 1.0880524999999999
  },
  {
    t: 1582717500,
    v: 1.08796125
  },
  {
    t: 1582717800,
    v: 1.08793
  },
  {
    t: 1582718100,
    v: 1.0879175
  },
  {
    t: 1582718400,
    v: 1.08799
  },
  {
    t: 1582718700,
    v: 1.08818
  },
  {
    t: 1582719000,
    v: 1.0884175
  },
  {
    t: 1582719300,
    v: 1.08855375
  },
  {
    t: 1582719600,
    v: 1.08871125
  },
  {
    t: 1582719900,
    v: 1.0888675
  },
  {
    t: 1582720200,
    v: 1.0889375000000001
  },
  {
    t: 1582720500,
    v: 1.0889974999999998
  },
  {
    t: 1582720800,
    v: 1.08885125
  },
  {
    t: 1582721100,
    v: 1.0886075
  },
  {
    t: 1582721400,
    v: 1.08835375
  },
  {
    t: 1582721700,
    v: 1.08813625
  },
  {
    t: 1582722000,
    v: 1.0879112499999999
  },
  {
    t: 1582722300,
    v: 1.0876237500000001
  },
  {
    t: 1582722600,
    v: 1.08745125
  },
  {
    t: 1582722900,
    v: 1.0872362500000001
  },
  {
    t: 1582723200,
    v: 1.08718625
  },
  {
    t: 1582723500,
    v: 1.0871325
  },
  {
    t: 1582723800,
    v: 1.08711625
  },
  {
    t: 1582724100,
    v: 1.0871512499999998
  },
  {
    t: 1582724400,
    v: 1.08716
  },
  {
    t: 1582724700,
    v: 1.0870575
  },
  {
    t: 1582725000,
    v: 1.08694625
  },
  {
    t: 1582725300,
    v: 1.08686375
  },
  {
    t: 1582725600,
    v: 1.086785
  },
  {
    t: 1582725900,
    v: 1.08674875
  },
  {
    t: 1582726200,
    v: 1.08675625
  },
  {
    t: 1582726500,
    v: 1.0867075
  },
  {
    t: 1582726800,
    v: 1.086695
  },
  {
    t: 1582727100,
    v: 1.0867375
  },
  {
    t: 1582727400,
    v: 1.0866575
  },
  {
    t: 1582727700,
    v: 1.086535
  },
  {
    t: 1582728000,
    v: 1.086455
  },
  {
    t: 1582728300,
    v: 1.0863325
  },
  {
    t: 1582728600,
    v: 1.08612375
  },
  {
    t: 1582728900,
    v: 1.08601625
  },
  {
    t: 1582729200,
    v: 1.0859349999999999
  },
  {
    t: 1582729500,
    v: 1.0859125
  },
  {
    t: 1582729800,
    v: 1.08598
  },
  {
    t: 1582730100,
    v: 1.08605125
  },
  {
    t: 1582730400,
    v: 1.08611875
  },
  {
    t: 1582730700,
    v: 1.08627875
  },
  {
    t: 1582731000,
    v: 1.08649875
  },
  {
    t: 1582731300,
    v: 1.086595
  },
  {
    t: 1582731600,
    v: 1.0866212499999999
  },
  {
    t: 1582731900,
    v: 1.08666
  },
  {
    t: 1582732200,
    v: 1.08674375
  },
  {
    t: 1582732500,
    v: 1.0868087499999999
  },
  {
    t: 1582732800,
    v: 1.0868275
  },
  {
    t: 1582733100,
    v: 1.0868312500000001
  },
  {
    t: 1582733400,
    v: 1.08682875
  },
  {
    t: 1582733700,
    v: 1.08695875
  },
  {
    t: 1582734000,
    v: 1.0871675
  },
  {
    t: 1582734300,
    v: 1.08746625
  },
  {
    t: 1582734600,
    v: 1.08770125
  },
  {
    t: 1582734900,
    v: 1.08799375
  },
  {
    t: 1582735200,
    v: 1.08823375
  },
  {
    t: 1582735500,
    v: 1.0884
  },
  {
    t: 1582735800,
    v: 1.08859875
  },
  {
    t: 1582736100,
    v: 1.08870625
  },
  {
    t: 1582736400,
    v: 1.0888275
  },
  {
    t: 1582736700,
    v: 1.088825
  },
  {
    t: 1582737000,
    v: 1.0888312500000001
  },
  {
    t: 1582737300,
    v: 1.088805
  },
  {
    t: 1582737600,
    v: 1.088795
  },
  {
    t: 1582737900,
    v: 1.0887875
  },
  {
    t: 1582738200,
    v: 1.0887125
  },
  {
    t: 1582738500,
    v: 1.0886575
  },
  {
    t: 1582738800,
    v: 1.0885637499999998
  },
  {
    t: 1582739100,
    v: 1.0884675
  },
  {
    t: 1582739400,
    v: 1.08833125
  },
  {
    t: 1582739700,
    v: 1.088215
  },
  {
    t: 1582740000,
    v: 1.08813
  },
  {
    t: 1582740300,
    v: 1.0880625
  },
  {
    t: 1582740600,
    v: 1.08796375
  },
  {
    t: 1582740900,
    v: 1.0879162500000001
  },
  {
    t: 1582741200,
    v: 1.087855
  },
  {
    t: 1582741500,
    v: 1.08781
  },
  {
    t: 1582741800,
    v: 1.08784375
  },
  {
    t: 1582742100,
    v: 1.0879525
  },
  {
    t: 1582742400,
    v: 1.08807125
  },
  {
    t: 1582742700,
    v: 1.088035
  },
  {
    t: 1582743000,
    v: 1.0880775
  },
  {
    t: 1582743300,
    v: 1.08811875
  },
  {
    t: 1582743600,
    v: 1.08817625
  },
  {
    t: 1582743900,
    v: 1.08829125
  },
  {
    t: 1582744200,
    v: 1.0883275
  },
  {
    t: 1582744500,
    v: 1.08833125
  },
  {
    t: 1582744800,
    v: 1.08832125
  },
  {
    t: 1582745100,
    v: 1.0884825
  },
  {
    t: 1582745400,
    v: 1.08866125
  },
  {
    t: 1582745700,
    v: 1.08883625
  },
  {
    t: 1582746000,
    v: 1.08904625
  },
  {
    t: 1582746300,
    v: 1.0891775000000001
  },
  {
    t: 1582746600,
    v: 1.08934875
  },
  {
    t: 1582746900,
    v: 1.08949875
  },
  {
    t: 1582747200,
    v: 1.08957625
  },
  {
    t: 1582747500,
    v: 1.0896062499999999
  },
  {
    t: 1582747800,
    v: 1.08957
  },
  {
    t: 1582748100,
    v: 1.089495
  },
  {
    t: 1582748400,
    v: 1.0893475
  },
  {
    t: 1582748700,
    v: 1.0892325
  },
  {
    t: 1582749000,
    v: 1.08910375
  },
  {
    t: 1582749300,
    v: 1.08894125
  },
  {
    t: 1582749600,
    v: 1.088825
  },
  {
    t: 1582749900,
    v: 1.0887312500000002
  },
  {
    t: 1582750200,
    v: 1.088655
  },
  {
    t: 1582750500,
    v: 1.088595
  },
  {
    t: 1582750800,
    v: 1.0885425
  },
  {
    t: 1582751100,
    v: 1.0884925
  },
  {
    t: 1582751400,
    v: 1.08845125
  },
  {
    t: 1582751700,
    v: 1.0884200000000002
  },
  {
    t: 1582752000,
    v: 1.0884175
  },
  {
    t: 1582752300,
    v: 1.08839625
  },
  {
    t: 1582752600,
    v: 1.08836
  },
  {
    t: 1582752900,
    v: 1.08830125
  },
  {
    t: 1582753200,
    v: 1.0882625
  },
  {
    t: 1582753500,
    v: 1.0882112499999999
  },
  {
    t: 1582753800,
    v: 1.08812625
  },
  {
    t: 1582754100,
    v: 1.08808375
  },
  {
    t: 1582754400,
    v: 1.0880325
  },
  {
    t: 1582754700,
    v: 1.0879675
  },
  {
    t: 1582755000,
    v: 1.08795125
  },
  {
    t: 1582755300,
    v: 1.088005
  },
  {
    t: 1582755600,
    v: 1.0880699999999999
  },
  {
    t: 1582755900,
    v: 1.08810125
  },
  {
    t: 1582756200,
    v: 1.08813875
  },
  {
    t: 1582756500,
    v: 1.0881675
  },
  {
    t: 1582756800,
    v: 1.088195
  },
  {
    t: 1582757100,
    v: 1.08824
  },
  {
    t: 1582757400,
    v: 1.0882562500000001
  },
  {
    t: 1582757700,
    v: 1.0882325
  },
  {
    t: 1582758000,
    v: 1.0881825
  },
  {
    t: 1582758300,
    v: 1.0881625
  },
  {
    t: 1582758600,
    v: 1.0881525
  },
  {
    t: 1582758900,
    v: 1.0881275
  },
  {
    t: 1582759200,
    v: 1.08810875
  },
  {
    t: 1582759500,
    v: 1.0881025
  },
  {
    t: 1582759800,
    v: 1.0880824999999998
  },
  {
    t: 1582760100,
    v: 1.0880762499999999
  },
  {
    t: 1582760400,
    v: 1.08809625
  },
  {
    t: 1582760700,
    v: 1.08810625
  },
  {
    t: 1582761000,
    v: 1.08815
  },
  {
    t: 1582761300,
    v: 1.0882175
  },
  {
    t: 1582761600,
    v: 1.08841125
  },
  {
    t: 1582761900,
    v: 1.08872875
  },
  {
    t: 1582762200,
    v: 1.08901375
  },
  {
    t: 1582762500,
    v: 1.08925
  },
  {
    t: 1582762800,
    v: 1.08952
  },
  {
    t: 1582763100,
    v: 1.0897625
  },
  {
    t: 1582763400,
    v: 1.08999625
  },
  {
    t: 1582763700,
    v: 1.09018125
  },
  {
    t: 1582764000,
    v: 1.0902275
  },
  {
    t: 1582764300,
    v: 1.090145
  },
  {
    t: 1582764600,
    v: 1.0900962499999998
  },
  {
    t: 1582764900,
    v: 1.0900625000000002
  },
  {
    t: 1582765200,
    v: 1.08999
  },
  {
    t: 1582767900,
    v: 1.08995375
  },
  {
    t: 1582768200,
    v: 1.08990875
  },
  {
    t: 1582768500,
    v: 1.0899100000000002
  },
  {
    t: 1582768800,
    v: 1.0899287500000001
  },
  {
    t: 1582769100,
    v: 1.0899775
  },
  {
    t: 1582769400,
    v: 1.0900337500000001
  },
  {
    t: 1582769700,
    v: 1.0901100000000001
  },
  {
    t: 1582770000,
    v: 1.09021
  },
  {
    t: 1582770300,
    v: 1.09032
  },
  {
    t: 1582770600,
    v: 1.0904225
  },
  {
    t: 1582770900,
    v: 1.09053
  },
  {
    t: 1582771200,
    v: 1.09064
  },
  {
    t: 1582771500,
    v: 1.0907425
  },
  {
    t: 1582771800,
    v: 1.09083375
  },
  {
    t: 1582772100,
    v: 1.0909125
  },
  {
    t: 1582772400,
    v: 1.09093125
  },
  {
    t: 1582772700,
    v: 1.09093875
  },
  {
    t: 1582773000,
    v: 1.0909449999999998
  },
  {
    t: 1582773300,
    v: 1.0909149999999999
  },
  {
    t: 1582773600,
    v: 1.09086375
  },
  {
    t: 1582773900,
    v: 1.09078625
  },
  {
    t: 1582774200,
    v: 1.0907375
  },
  {
    t: 1582774500,
    v: 1.090695
  },
  {
    t: 1582774800,
    v: 1.090665
  },
  {
    t: 1582775100,
    v: 1.09063375
  },
  {
    t: 1582775400,
    v: 1.09060375
  },
  {
    t: 1582775700,
    v: 1.0905775000000002
  },
  {
    t: 1582776000,
    v: 1.0905325000000001
  },
  {
    t: 1582776300,
    v: 1.0904962500000002
  },
  {
    t: 1582776600,
    v: 1.0904625
  },
  {
    t: 1582776900,
    v: 1.09044375
  },
  {
    t: 1582777200,
    v: 1.0904375
  },
  {
    t: 1582777500,
    v: 1.0904375
  },
  {
    t: 1582777800,
    v: 1.0904487500000002
  },
  {
    t: 1582778100,
    v: 1.09046
  },
  {
    t: 1582778400,
    v: 1.0904975000000001
  },
  {
    t: 1582778700,
    v: 1.0905412499999998
  },
  {
    t: 1582779000,
    v: 1.09060125
  },
  {
    t: 1582779300,
    v: 1.09068875
  },
  {
    t: 1582779600,
    v: 1.09077
  },
  {
    t: 1582779900,
    v: 1.09083375
  },
  {
    t: 1582780200,
    v: 1.09087625
  },
  {
    t: 1582780500,
    v: 1.0909225
  },
  {
    t: 1582780800,
    v: 1.09093625
  },
  {
    t: 1582781100,
    v: 1.0909525
  },
  {
    t: 1582781400,
    v: 1.09094125
  },
  {
    t: 1582781700,
    v: 1.09089125
  },
  {
    t: 1582782000,
    v: 1.09092125
  },
  {
    t: 1582782300,
    v: 1.0909187500000002
  },
  {
    t: 1582782600,
    v: 1.0909475
  },
  {
    t: 1582782900,
    v: 1.0909175
  },
  {
    t: 1582783200,
    v: 1.09091875
  },
  {
    t: 1582783500,
    v: 1.0909
  },
  {
    t: 1582783800,
    v: 1.0908799999999998
  },
  {
    t: 1582784100,
    v: 1.0908499999999999
  },
  {
    t: 1582784400,
    v: 1.090765
  },
  {
    t: 1582784700,
    v: 1.0907537500000002
  },
  {
    t: 1582785000,
    v: 1.0907350000000002
  },
  {
    t: 1582785300,
    v: 1.0908212499999999
  },
  {
    t: 1582785600,
    v: 1.0909200000000001
  },
  {
    t: 1582785900,
    v: 1.09102875
  },
  {
    t: 1582786200,
    v: 1.0911125
  },
  {
    t: 1582786500,
    v: 1.09117375
  },
  {
    t: 1582786800,
    v: 1.091235
  },
  {
    t: 1582787100,
    v: 1.09130625
  },
  {
    t: 1582787400,
    v: 1.0913875000000002
  },
  {
    t: 1582787700,
    v: 1.09141875
  },
  {
    t: 1582788000,
    v: 1.09142625
  },
  {
    t: 1582788300,
    v: 1.0914275000000002
  },
  {
    t: 1582788600,
    v: 1.0914350000000002
  },
  {
    t: 1582788900,
    v: 1.09146
  },
  {
    t: 1582789200,
    v: 1.0914775
  },
  {
    t: 1582789500,
    v: 1.091445
  },
  {
    t: 1582789800,
    v: 1.09145625
  },
  {
    t: 1582790100,
    v: 1.0915112500000002
  },
  {
    t: 1582790400,
    v: 1.0915937500000001
  },
  {
    t: 1582790700,
    v: 1.09180625
  },
  {
    t: 1582791000,
    v: 1.0919975
  },
  {
    t: 1582791300,
    v: 1.09221875
  },
  {
    t: 1582791600,
    v: 1.0924425
  },
  {
    t: 1582791900,
    v: 1.092755
  },
  {
    t: 1582792200,
    v: 1.0930449999999998
  },
  {
    t: 1582792500,
    v: 1.09332125
  },
  {
    t: 1582792800,
    v: 1.0935774999999999
  },
  {
    t: 1582793100,
    v: 1.09369625
  },
  {
    t: 1582793400,
    v: 1.09388375
  },
  {
    t: 1582793700,
    v: 1.09409625
  },
  {
    t: 1582794000,
    v: 1.09423625
  },
  {
    t: 1582794300,
    v: 1.0942625
  },
  {
    t: 1582794600,
    v: 1.0942150000000002
  },
  {
    t: 1582794900,
    v: 1.0941312500000002
  },
  {
    t: 1582795200,
    v: 1.09405875
  },
  {
    t: 1582795500,
    v: 1.09396
  },
  {
    t: 1582795800,
    v: 1.09382125
  },
  {
    t: 1582796100,
    v: 1.09367125
  },
  {
    t: 1582796400,
    v: 1.09356375
  },
  {
    t: 1582796700,
    v: 1.09349125
  },
  {
    t: 1582797000,
    v: 1.093475
  },
  {
    t: 1582797300,
    v: 1.0934575
  },
  {
    t: 1582797600,
    v: 1.09343125
  },
  {
    t: 1582797900,
    v: 1.09342625
  },
  {
    t: 1582798200,
    v: 1.09349875
  },
  {
    t: 1582798500,
    v: 1.09353875
  },
  {
    t: 1582798800,
    v: 1.0935875
  },
  {
    t: 1582799100,
    v: 1.093655
  },
  {
    t: 1582799400,
    v: 1.093695
  },
  {
    t: 1582799700,
    v: 1.09375875
  },
  {
    t: 1582800000,
    v: 1.09385375
  },
  {
    t: 1582800300,
    v: 1.0939675000000002
  },
  {
    t: 1582800600,
    v: 1.09398625
  },
  {
    t: 1582800900,
    v: 1.09399625
  },
  {
    t: 1582801200,
    v: 1.09399375
  },
  {
    t: 1582801500,
    v: 1.094025
  },
  {
    t: 1582801800,
    v: 1.09403625
  },
  {
    t: 1582802100,
    v: 1.0939850000000002
  },
  {
    t: 1582802400,
    v: 1.0939375
  },
  {
    t: 1582802700,
    v: 1.09385375
  },
  {
    t: 1582803000,
    v: 1.09381
  },
  {
    t: 1582803300,
    v: 1.09379
  },
  {
    t: 1582803600,
    v: 1.0938425
  },
  {
    t: 1582803900,
    v: 1.0939050000000001
  },
  {
    t: 1582804200,
    v: 1.09395375
  },
  {
    t: 1582804500,
    v: 1.094065
  },
  {
    t: 1582804800,
    v: 1.0941337500000001
  },
  {
    t: 1582805100,
    v: 1.0942575
  },
  {
    t: 1582805400,
    v: 1.09436875
  },
  {
    t: 1582805700,
    v: 1.094465
  },
  {
    t: 1582806000,
    v: 1.0945200000000002
  },
  {
    t: 1582806300,
    v: 1.0945300000000002
  },
  {
    t: 1582806600,
    v: 1.0946225
  },
  {
    t: 1582806900,
    v: 1.0947725
  },
  {
    t: 1582807200,
    v: 1.0949862499999998
  },
  {
    t: 1582807500,
    v: 1.0951925
  },
  {
    t: 1582807800,
    v: 1.0954
  },
  {
    t: 1582808100,
    v: 1.0956325
  },
  {
    t: 1582808400,
    v: 1.0958375
  },
  {
    t: 1582808700,
    v: 1.09605375
  },
  {
    t: 1582809000,
    v: 1.096205
  },
  {
    t: 1582809300,
    v: 1.09629875
  },
  {
    t: 1582809600,
    v: 1.09632
  },
  {
    t: 1582809900,
    v: 1.09633125
  },
  {
    t: 1582810200,
    v: 1.09633625
  },
  {
    t: 1582810500,
    v: 1.0963949999999998
  },
  {
    t: 1582810800,
    v: 1.09645875
  },
  {
    t: 1582811100,
    v: 1.0965699999999998
  },
  {
    t: 1582811400,
    v: 1.0966875
  },
  {
    t: 1582811700,
    v: 1.096835
  },
  {
    t: 1582812000,
    v: 1.09697
  },
  {
    t: 1582812300,
    v: 1.09710375
  },
  {
    t: 1582812600,
    v: 1.0971574999999998
  },
  {
    t: 1582812900,
    v: 1.09717
  },
  {
    t: 1582813200,
    v: 1.0972487499999999
  },
  {
    t: 1582813500,
    v: 1.0972612499999999
  },
  {
    t: 1582813800,
    v: 1.09727875
  },
  {
    t: 1582814100,
    v: 1.09723125
  },
  {
    t: 1582814400,
    v: 1.097335
  },
  {
    t: 1582814700,
    v: 1.09740375
  },
  {
    t: 1582815000,
    v: 1.09747875
  },
  {
    t: 1582815300,
    v: 1.09756
  },
  {
    t: 1582815600,
    v: 1.0977299999999999
  },
  {
    t: 1582815900,
    v: 1.0979275000000002
  },
  {
    t: 1582816200,
    v: 1.0981762499999999
  },
  {
    t: 1582816500,
    v: 1.09840125
  },
  {
    t: 1582816800,
    v: 1.09857125
  },
  {
    t: 1582817100,
    v: 1.0987850000000001
  },
  {
    t: 1582817400,
    v: 1.09917875
  },
  {
    t: 1582817700,
    v: 1.0994425
  },
  {
    t: 1582818000,
    v: 1.0996450000000002
  },
  {
    t: 1582818300,
    v: 1.0996512500000002
  },
  {
    t: 1582818600,
    v: 1.0996337500000002
  },
  {
    t: 1582818900,
    v: 1.09955
  },
  {
    t: 1582819200,
    v: 1.09943125
  },
  {
    t: 1582819500,
    v: 1.0991675
  },
  {
    t: 1582819800,
    v: 1.0988075
  },
  {
    t: 1582820100,
    v: 1.09854875
  },
  {
    t: 1582820400,
    v: 1.0982512500000001
  },
  {
    t: 1582820700,
    v: 1.0981037500000002
  },
  {
    t: 1582821000,
    v: 1.0978687500000002
  },
  {
    t: 1582821300,
    v: 1.09777375
  },
  {
    t: 1582821600,
    v: 1.0976637500000002
  },
  {
    t: 1582821900,
    v: 1.097715
  },
  {
    t: 1582822200,
    v: 1.09776125
  },
  {
    t: 1582822500,
    v: 1.0977425
  },
  {
    t: 1582822800,
    v: 1.0976325
  },
  {
    t: 1582823100,
    v: 1.09748625
  },
  {
    t: 1582823400,
    v: 1.09738375
  },
  {
    t: 1582823700,
    v: 1.09728625
  },
  {
    t: 1582824000,
    v: 1.09720625
  },
  {
    t: 1582824300,
    v: 1.0971
  },
  {
    t: 1582824600,
    v: 1.09698375
  },
  {
    t: 1582824900,
    v: 1.09686125
  },
  {
    t: 1582825200,
    v: 1.09684875
  },
  {
    t: 1582825500,
    v: 1.0968875
  },
  {
    t: 1582825800,
    v: 1.09692125
  },
  {
    t: 1582826100,
    v: 1.09705875
  },
  {
    t: 1582826400,
    v: 1.0971525
  },
  {
    t: 1582826700,
    v: 1.09725625
  },
  {
    t: 1582827000,
    v: 1.0973825
  },
  {
    t: 1582827300,
    v: 1.0976249999999999
  },
  {
    t: 1582827600,
    v: 1.0978187499999998
  },
  {
    t: 1582827900,
    v: 1.0980124999999998
  },
  {
    t: 1582828200,
    v: 1.098245
  },
  {
    t: 1582828500,
    v: 1.0983612500000002
  },
  {
    t: 1582828800,
    v: 1.0985387500000001
  },
  {
    t: 1582829100,
    v: 1.09865125
  },
  {
    t: 1582829400,
    v: 1.0987087500000001
  },
  {
    t: 1582829700,
    v: 1.09875375
  },
  {
    t: 1582830000,
    v: 1.09880375
  },
  {
    t: 1582830300,
    v: 1.09885625
  },
  {
    t: 1582830600,
    v: 1.09890875
  },
  {
    t: 1582830900,
    v: 1.09897125
  },
  {
    t: 1582831200,
    v: 1.0989512499999998
  },
  {
    t: 1582831500,
    v: 1.0989324999999999
  },
  {
    t: 1582831800,
    v: 1.0989775
  },
  {
    t: 1582832100,
    v: 1.0989687499999998
  },
  {
    t: 1582832400,
    v: 1.09893125
  },
  {
    t: 1582832700,
    v: 1.09889
  },
  {
    t: 1582833000,
    v: 1.09883875
  },
  {
    t: 1582833300,
    v: 1.0987562499999999
  },
  {
    t: 1582833600,
    v: 1.09870875
  },
  {
    t: 1582833900,
    v: 1.0986500000000001
  },
  {
    t: 1582834200,
    v: 1.09859375
  },
  {
    t: 1582834500,
    v: 1.09855375
  },
  {
    t: 1582834800,
    v: 1.09853
  },
  {
    t: 1582835100,
    v: 1.09852875
  },
  {
    t: 1582835400,
    v: 1.0985175
  },
  {
    t: 1582835700,
    v: 1.0985525
  },
  {
    t: 1582836000,
    v: 1.09862
  },
  {
    t: 1582836300,
    v: 1.0987375
  },
  {
    t: 1582836600,
    v: 1.0988675
  },
  {
    t: 1582836900,
    v: 1.09896625
  },
  {
    t: 1582837200,
    v: 1.0990575
  },
  {
    t: 1582837500,
    v: 1.0991662500000001
  },
  {
    t: 1582837800,
    v: 1.0993024999999998
  },
  {
    t: 1582838100,
    v: 1.0993612499999998
  },
  {
    t: 1582838400,
    v: 1.09938875
  },
  {
    t: 1582838700,
    v: 1.0993950000000001
  },
  {
    t: 1582839000,
    v: 1.099385
  },
  {
    t: 1582839300,
    v: 1.0994362500000001
  },
  {
    t: 1582839600,
    v: 1.09949
  },
  {
    t: 1582839900,
    v: 1.0995099999999998
  },
  {
    t: 1582840200,
    v: 1.0995424999999999
  },
  {
    t: 1582840500,
    v: 1.0995975
  },
  {
    t: 1582840800,
    v: 1.09971
  },
  {
    t: 1582841100,
    v: 1.09986125
  },
  {
    t: 1582841400,
    v: 1.09996875
  },
  {
    t: 1582841700,
    v: 1.10004875
  },
  {
    t: 1582842000,
    v: 1.1001125
  },
  {
    t: 1582842300,
    v: 1.1001712499999998
  },
  {
    t: 1582842600,
    v: 1.10020625
  },
  {
    t: 1582842900,
    v: 1.100245
  },
  {
    t: 1582843200,
    v: 1.1002275
  },
  {
    t: 1582843500,
    v: 1.1002162500000001
  },
  {
    t: 1582843800,
    v: 1.1002174999999998
  },
  {
    t: 1582844100,
    v: 1.10021
  },
  {
    t: 1582844400,
    v: 1.10021375
  },
  {
    t: 1582844700,
    v: 1.10019375
  },
  {
    t: 1582845000,
    v: 1.100165
  },
  {
    t: 1582845300,
    v: 1.1001525
  },
  {
    t: 1582845600,
    v: 1.1001275
  },
  {
    t: 1582845900,
    v: 1.10006
  },
  {
    t: 1582846200,
    v: 1.10001
  },
  {
    t: 1582846500,
    v: 1.09996625
  },
  {
    t: 1582855200,
    v: 1.099755
  },
  {
    t: 1582855500,
    v: 1.09958375
  },
  {
    t: 1582855800,
    v: 1.0994350000000002
  },
  {
    t: 1582856100,
    v: 1.099275
  },
  {
    t: 1582856400,
    v: 1.09909125
  },
  {
    t: 1582856700,
    v: 1.09897375
  },
  {
    t: 1582857000,
    v: 1.09885875
  },
  {
    t: 1582857300,
    v: 1.09871375
  },
  {
    t: 1582857600,
    v: 1.0987275
  },
  {
    t: 1582857900,
    v: 1.09873625
  },
  {
    t: 1582858200,
    v: 1.0987187500000002
  },
  {
    t: 1582858500,
    v: 1.09869
  },
  {
    t: 1582858800,
    v: 1.0986725
  },
  {
    t: 1582859100,
    v: 1.09862
  },
  {
    t: 1582859400,
    v: 1.09857125
  },
  {
    t: 1582859700,
    v: 1.09853875
  },
  {
    t: 1582860000,
    v: 1.09854375
  },
  {
    t: 1582860300,
    v: 1.0985425
  },
  {
    t: 1582860600,
    v: 1.0985537499999998
  },
  {
    t: 1582860900,
    v: 1.09859625
  },
  {
    t: 1582861200,
    v: 1.0986999999999998
  },
  {
    t: 1582861500,
    v: 1.09880125
  },
  {
    t: 1582861800,
    v: 1.0988525
  },
  {
    t: 1582862100,
    v: 1.09887625
  },
  {
    t: 1582862400,
    v: 1.0988937499999998
  },
  {
    t: 1582862700,
    v: 1.0989262499999999
  },
  {
    t: 1582863000,
    v: 1.09894875
  },
  {
    t: 1582863300,
    v: 1.0989475
  },
  {
    t: 1582863600,
    v: 1.09891875
  },
  {
    t: 1582863900,
    v: 1.0989175
  },
  {
    t: 1582864200,
    v: 1.0989512499999998
  },
  {
    t: 1582864500,
    v: 1.0990325
  },
  {
    t: 1582864800,
    v: 1.09912125
  },
  {
    t: 1582865100,
    v: 1.09919125
  },
  {
    t: 1582865400,
    v: 1.09927375
  },
  {
    t: 1582865700,
    v: 1.0993325
  },
  {
    t: 1582866000,
    v: 1.09935875
  },
  {
    t: 1582866300,
    v: 1.0994012499999999
  },
  {
    t: 1582866600,
    v: 1.0994525
  },
  {
    t: 1582866900,
    v: 1.09946125
  },
  {
    t: 1582867200,
    v: 1.09952
  },
  {
    t: 1582867500,
    v: 1.09958125
  },
  {
    t: 1582867800,
    v: 1.0996000000000001
  },
  {
    t: 1582868100,
    v: 1.09966375
  },
  {
    t: 1582868400,
    v: 1.0997275
  },
  {
    t: 1582868700,
    v: 1.0997975
  },
  {
    t: 1582869000,
    v: 1.09985125
  },
  {
    t: 1582869300,
    v: 1.0999275000000002
  },
  {
    t: 1582869600,
    v: 1.10000375
  },
  {
    t: 1582869900,
    v: 1.100085
  },
  {
    t: 1582870200,
    v: 1.1001825
  },
  {
    t: 1582870500,
    v: 1.10023125
  },
  {
    t: 1582870800,
    v: 1.10029125
  },
  {
    t: 1582871100,
    v: 1.10031625
  },
  {
    t: 1582871400,
    v: 1.1003712499999998
  },
  {
    t: 1582871700,
    v: 1.10043625
  },
  {
    t: 1582872000,
    v: 1.1004275
  },
  {
    t: 1582872300,
    v: 1.1004099999999999
  },
  {
    t: 1582872600,
    v: 1.1004125
  },
  {
    t: 1582872900,
    v: 1.1003850000000002
  },
  {
    t: 1582873200,
    v: 1.1003612500000002
  },
  {
    t: 1582873500,
    v: 1.10034
  },
  {
    t: 1582873800,
    v: 1.100245
  },
  {
    t: 1582874100,
    v: 1.100125
  },
  {
    t: 1582874400,
    v: 1.09999
  },
  {
    t: 1582874700,
    v: 1.0999124999999998
  },
  {
    t: 1582875000,
    v: 1.09987375
  },
  {
    t: 1582875300,
    v: 1.0998537499999999
  },
  {
    t: 1582875600,
    v: 1.0997962499999998
  },
  {
    t: 1582875900,
    v: 1.09974625
  },
  {
    t: 1582876200,
    v: 1.0997124999999999
  },
  {
    t: 1582876500,
    v: 1.0997075
  },
  {
    t: 1582876800,
    v: 1.09983
  },
  {
    t: 1582877100,
    v: 1.1000025
  },
  {
    t: 1582877400,
    v: 1.10017
  },
  {
    t: 1582877700,
    v: 1.10046375
  },
  {
    t: 1582878000,
    v: 1.1007975
  },
  {
    t: 1582878300,
    v: 1.10113625
  },
  {
    t: 1582878600,
    v: 1.1013775
  },
  {
    t: 1582878900,
    v: 1.10165875
  },
  {
    t: 1582879200,
    v: 1.10187
  },
  {
    t: 1582879500,
    v: 1.10201
  },
  {
    t: 1582879800,
    v: 1.102165
  },
  {
    t: 1582880100,
    v: 1.1023025000000002
  },
  {
    t: 1582880400,
    v: 1.1024225
  },
  {
    t: 1582880700,
    v: 1.1025749999999999
  },
  {
    t: 1582881000,
    v: 1.1029862499999998
  },
  {
    t: 1582881300,
    v: 1.1032724999999999
  },
  {
    t: 1582881600,
    v: 1.1034924999999998
  },
  {
    t: 1582881900,
    v: 1.1037625000000002
  },
  {
    t: 1582882200,
    v: 1.10400875
  },
  {
    t: 1582882500,
    v: 1.1041875
  },
  {
    t: 1582882800,
    v: 1.1043375
  },
  {
    t: 1582883100,
    v: 1.10437625
  },
  {
    t: 1582883400,
    v: 1.1043174999999998
  },
  {
    t: 1582883700,
    v: 1.1043637499999999
  },
  {
    t: 1582884000,
    v: 1.104305
  },
  {
    t: 1582884300,
    v: 1.1041637499999999
  },
  {
    t: 1582884600,
    v: 1.104045
  },
  {
    t: 1582884900,
    v: 1.1038812500000001
  },
  {
    t: 1582885200,
    v: 1.1036812500000002
  },
  {
    t: 1582885500,
    v: 1.1035425
  },
  {
    t: 1582885800,
    v: 1.1034012500000001
  },
  {
    t: 1582886100,
    v: 1.1032175
  },
  {
    t: 1582886400,
    v: 1.10316625
  },
  {
    t: 1582886700,
    v: 1.1032
  },
  {
    t: 1582887000,
    v: 1.1031900000000001
  },
  {
    t: 1582887300,
    v: 1.103175
  },
  {
    t: 1582887600,
    v: 1.10331875
  },
  {
    t: 1582887900,
    v: 1.10339375
  },
  {
    t: 1582888200,
    v: 1.1033787499999999
  },
  {
    t: 1582888500,
    v: 1.10335375
  },
  {
    t: 1582888800,
    v: 1.1033
  },
  {
    t: 1582889100,
    v: 1.1030012500000002
  },
  {
    t: 1582889400,
    v: 1.1026799999999999
  },
  {
    t: 1582889700,
    v: 1.1024037500000001
  },
  {
    t: 1582890000,
    v: 1.1019625
  },
  {
    t: 1582890300,
    v: 1.1016375
  },
  {
    t: 1582890600,
    v: 1.1011175
  },
  {
    t: 1582890900,
    v: 1.1006900000000002
  },
  {
    t: 1582891200,
    v: 1.10026625
  },
  {
    t: 1582891500,
    v: 1.1000837500000002
  },
  {
    t: 1582891800,
    v: 1.09987
  },
  {
    t: 1582892100,
    v: 1.099635
  },
  {
    t: 1582892400,
    v: 1.09948125
  },
  {
    t: 1582892700,
    v: 1.09920125
  },
  {
    t: 1582893000,
    v: 1.09927875
  },
  {
    t: 1582893300,
    v: 1.09930625
  },
  {
    t: 1582893600,
    v: 1.0992825
  },
  {
    t: 1582893900,
    v: 1.0991562499999998
  },
  {
    t: 1582894200,
    v: 1.0990949999999997
  },
  {
    t: 1582894500,
    v: 1.0990199999999999
  },
  {
    t: 1582894800,
    v: 1.09895375
  },
  {
    t: 1582895100,
    v: 1.09897875
  },
  {
    t: 1582895400,
    v: 1.09876875
  },
  {
    t: 1582895700,
    v: 1.09861625
  },
  {
    t: 1582896000,
    v: 1.098415
  },
  {
    t: 1582896300,
    v: 1.09818625
  },
  {
    t: 1582896600,
    v: 1.0979762499999999
  },
  {
    t: 1582896900,
    v: 1.09785
  },
  {
    t: 1582897200,
    v: 1.0977212499999998
  },
  {
    t: 1582897500,
    v: 1.0975949999999999
  },
  {
    t: 1582897800,
    v: 1.0977249999999998
  },
  {
    t: 1582898100,
    v: 1.0977649999999999
  },
  {
    t: 1582898400,
    v: 1.09790375
  },
  {
    t: 1582898700,
    v: 1.09806125
  },
  {
    t: 1582899000,
    v: 1.09815875
  },
  {
    t: 1582899300,
    v: 1.09818375
  },
  {
    t: 1582899600,
    v: 1.0982075
  },
  {
    t: 1582899900,
    v: 1.0981475
  },
  {
    t: 1582900200,
    v: 1.097865
  },
  {
    t: 1582900500,
    v: 1.0975562499999998
  },
  {
    t: 1582900800,
    v: 1.09715
  },
  {
    t: 1582901100,
    v: 1.0968925
  },
  {
    t: 1582901400,
    v: 1.0966825
  },
  {
    t: 1582901700,
    v: 1.09652375
  },
  {
    t: 1582902000,
    v: 1.0963425
  },
  {
    t: 1582902300,
    v: 1.0961162500000001
  },
  {
    t: 1582902600,
    v: 1.09608375
  },
  {
    t: 1582902900,
    v: 1.09609375
  },
  {
    t: 1582903200,
    v: 1.0962687500000001
  },
  {
    t: 1582903500,
    v: 1.0964
  },
  {
    t: 1582903800,
    v: 1.0964525
  },
  {
    t: 1582904100,
    v: 1.09651625
  },
  {
    t: 1582904400,
    v: 1.096635
  },
  {
    t: 1582904700,
    v: 1.09695375
  },
  {
    t: 1582905000,
    v: 1.09701375
  },
  {
    t: 1582905300,
    v: 1.0972825
  },
  {
    t: 1582905600,
    v: 1.09752875
  },
  {
    t: 1582905900,
    v: 1.09785375
  },
  {
    t: 1582906200,
    v: 1.0980675
  },
  {
    t: 1582906500,
    v: 1.0982875
  },
  {
    t: 1582906800,
    v: 1.0985562500000001
  },
  {
    t: 1582907100,
    v: 1.09880375
  },
  {
    t: 1582907400,
    v: 1.0991937499999997
  },
  {
    t: 1582907700,
    v: 1.09942
  },
  {
    t: 1582908000,
    v: 1.0994950000000001
  },
  {
    t: 1582908300,
    v: 1.09944375
  },
  {
    t: 1582908600,
    v: 1.099645
  },
  {
    t: 1582908900,
    v: 1.09978625
  },
  {
    t: 1582909200,
    v: 1.09991125
  },
  {
    t: 1582909500,
    v: 1.0999
  },
  {
    t: 1582909800,
    v: 1.0999225
  },
  {
    t: 1582910100,
    v: 1.09995
  },
  {
    t: 1582910400,
    v: 1.1001275
  },
  {
    t: 1582910700,
    v: 1.1002075
  },
  {
    t: 1582911000,
    v: 1.10024375
  },
  {
    t: 1582911300,
    v: 1.10023625
  },
  {
    t: 1582911600,
    v: 1.10016375
  },
  {
    t: 1582911900,
    v: 1.10014875
  },
  {
    t: 1582912200,
    v: 1.1001337500000001
  },
  {
    t: 1582912500,
    v: 1.10009125
  },
  {
    t: 1582912800,
    v: 1.0999937499999999
  },
  {
    t: 1582913100,
    v: 1.0999162500000002
  },
  {
    t: 1582913400,
    v: 1.0998625
  },
  {
    t: 1582913700,
    v: 1.099785
  },
  {
    t: 1582914000,
    v: 1.09974
  },
  {
    t: 1582914300,
    v: 1.099715
  },
  {
    t: 1582914600,
    v: 1.09973375
  },
  {
    t: 1582914900,
    v: 1.099725
  },
  {
    t: 1582915200,
    v: 1.09979625
  },
  {
    t: 1582915500,
    v: 1.09987625
  },
  {
    t: 1582915800,
    v: 1.0999075
  },
  {
    t: 1582916100,
    v: 1.099995
  },
  {
    t: 1582916400,
    v: 1.100105
  },
  {
    t: 1582916700,
    v: 1.10022375
  },
  {
    t: 1582917000,
    v: 1.10019125
  },
  {
    t: 1582917300,
    v: 1.1000987500000001
  },
  {
    t: 1582917600,
    v: 1.1000075
  },
  {
    t: 1582917900,
    v: 1.10003625
  },
  {
    t: 1582918200,
    v: 1.1003237499999998
  },
  {
    t: 1582918500,
    v: 1.10061375
  },
  {
    t: 1582918800,
    v: 1.1008624999999999
  }
]

export const defaultEMA = [
  {
    t: 1580680800,
    v: 1.31774
  },
  {
    t: 1580684400,
    v: 1.3177714285714286
  },
  {
    t: 1580688000,
    v: 1.3177531972789116
  },
  {
    t: 1580691600,
    v: 1.3177405118237773
  },
  {
    t: 1580695200,
    v: 1.3177023678405604
  },
  {
    t: 1580698800,
    v: 1.317521189950983
  },
  {
    t: 1580702400,
    v: 1.3174106004318418
  },
  {
    t: 1580706000,
    v: 1.3173038765811902
  },
  {
    t: 1580709600,
    v: 1.3171216026210768
  },
  {
    t: 1580713200,
    v: 1.316853830942879
  },
  {
    t: 1580716800,
    v: 1.316367751805462
  },
  {
    t: 1580720400,
    v: 1.315579394490656
  },
  {
    t: 1580724000,
    v: 1.3146575473963078
  },
  {
    t: 1580727600,
    v: 1.3138311143109451
  },
  {
    t: 1580731200,
    v: 1.3130443415194266
  },
  {
    t: 1580734800,
    v: 1.312363928041386
  },
  {
    t: 1580738400,
    v: 1.3114483158469683
  },
  {
    t: 1580742000,
    v: 1.3104760952901142
  },
  {
    t: 1580745600,
    v: 1.3095269433577224
  },
  {
    t: 1580749200,
    v: 1.3086672344665107
  },
  {
    t: 1580752800,
    v: 1.3077855930887476
  },
  {
    t: 1580756400,
    v: 1.3070250604136289
  },
  {
    t: 1580760000,
    v: 1.3062721975170928
  },
  {
    t: 1580763600,
    v: 1.3056062739440364
  },
  {
    t: 1580767200,
    v: 1.3050332954731758
  },
  {
    t: 1580770800,
    v: 1.304518695904302
  },
  {
    t: 1580774400,
    v: 1.304005486770559
  },
  {
    t: 1580778000,
    v: 1.3035306785066962
  },
  {
    t: 1580781600,
    v: 1.3031201376965347
  },
  {
    t: 1580785200,
    v: 1.30281250553496
  },
  {
    t: 1580788800,
    v: 1.3026103621506782
  },
  {
    t: 1580792400,
    v: 1.3024865181363279
  },
  {
    t: 1580796000,
    v: 1.3022049449804871
  },
  {
    t: 1580799600,
    v: 1.3014720930775836
  },
  {
    t: 1580803200,
    v: 1.3012071318320995
  },
  {
    t: 1580806800,
    v: 1.3012435954671375
  },
  {
    t: 1580810400,
    v: 1.3011870625655053
  },
  {
    t: 1580814000,
    v: 1.3011997232735524
  },
  {
    t: 1580817600,
    v: 1.3011940353427378
  },
  {
    t: 1580821200,
    v: 1.3013555557862866
  },
  {
    t: 1580824800,
    v: 1.3014321695209259
  },
  {
    t: 1580828400,
    v: 1.301535772423695
  },
  {
    t: 1580832000,
    v: 1.3016542702881049
  },
  {
    t: 1580835600,
    v: 1.3017976731178091
  },
  {
    t: 1580839200,
    v: 1.3020131328208748
  },
  {
    t: 1580842800,
    v: 1.3021699773141249
  },
  {
    t: 1580846400,
    v: 1.3022804556651606
  },
  {
    t: 1580850000,
    v: 1.3023423170303834
  },
  {
    t: 1580853600,
    v: 1.3023868582655849
  },
  {
    t: 1580857200,
    v: 1.3024471574783862
  },
  {
    t: 1580860800,
    v: 1.3024807615280638
  },
  {
    t: 1580864400,
    v: 1.3024635461444387
  },
  {
    t: 1580868000,
    v: 1.302387970321159
  },
  {
    t: 1580871600,
    v: 1.3023700683858104
  },
  {
    t: 1580875200,
    v: 1.3023157761585904
  },
  {
    t: 1580878800,
    v: 1.3022609403339627
  },
  {
    t: 1580882400,
    v: 1.3022065650640615
  },
  {
    t: 1580886000,
    v: 1.3023497493436746
  },
  {
    t: 1580889600,
    v: 1.3025745351204676
  },
  {
    t: 1580893200,
    v: 1.3029579127280422
  },
  {
    t: 1580896800,
    v: 1.303160968658705
  },
  {
    t: 1580900400,
    v: 1.303431352595971
  },
  {
    t: 1580904000,
    v: 1.3035512237773073
  },
  {
    t: 1580907600,
    v: 1.3032958691318495
  },
  {
    t: 1580911200,
    v: 1.3026229292145304
  },
  {
    t: 1580914800,
    v: 1.3022169359560036
  },
  {
    t: 1580918400,
    v: 1.301993418245908
  },
  {
    t: 1580922000,
    v: 1.3018397593653455
  },
  {
    t: 1580925600,
    v: 1.3016893060924555
  },
  {
    t: 1580929200,
    v: 1.3014093721788882
  },
  {
    t: 1580932800,
    v: 1.3011951462570894
  },
  {
    t: 1580936400,
    v: 1.3010860847087953
  },
  {
    t: 1580940000,
    v: 1.3009559814031957
  },
  {
    t: 1580943600,
    v: 1.3008163641267008
  },
  {
    t: 1580947200,
    v: 1.300608138971777
  },
  {
    t: 1580950800,
    v: 1.300437840022084
  },
  {
    t: 1580954400,
    v: 1.3002485219247426
  },
  {
    t: 1580958000,
    v: 1.3000886626938148
  },
  {
    t: 1580961600,
    v: 1.2998878376753562
  },
  {
    t: 1580965200,
    v: 1.299748995991989
  },
  {
    t: 1580968800,
    v: 1.2996081392308472
  },
  {
    t: 1580972400,
    v: 1.299463554542195
  },
  {
    t: 1580976000,
    v: 1.2993765493477003
  },
  {
    t: 1580979600,
    v: 1.2991911636955384
  },
  {
    t: 1580983200,
    v: 1.298911052867392
  },
  {
    t: 1580986800,
    v: 1.2986623811657356
  },
  {
    t: 1580990400,
    v: 1.2985326305785228
  },
  {
    t: 1580994000,
    v: 1.2983466657615206
  },
  {
    t: 1580997600,
    v: 1.2980593642604235
  },
  {
    t: 1581001200,
    v: 1.2975556152832404
  },
  {
    t: 1581004800,
    v: 1.297134128113408
  },
  {
    t: 1581008400,
    v: 1.2967756397216548
  },
  {
    t: 1581012000,
    v: 1.296385578795783
  },
  {
    t: 1581015600,
    v: 1.2959936189104704
  },
  {
    t: 1581019200,
    v: 1.295659940918997
  },
  {
    t: 1581022800,
    v: 1.2953475655933784
  },
  {
    t: 1581026400,
    v: 1.2950954164892472
  },
  {
    t: 1581030000,
    v: 1.2948949006331285
  },
  {
    t: 1581033600,
    v: 1.2947687196204496
  },
  {
    t: 1581037200,
    v: 1.2946631272756448
  },
  {
    t: 1581040800,
    v: 1.2946152103922501
  },
  {
    t: 1581044400,
    v: 1.2945347141644168
  },
  {
    t: 1581048000,
    v: 1.294459027101139
  },
  {
    t: 1581051600,
    v: 1.2944181673772208
  },
  {
    t: 1581055200,
    v: 1.2944011990555808
  },
  {
    t: 1581058800,
    v: 1.2942982277169541
  },
  {
    t: 1581062400,
    v: 1.2941336346010537
  },
  {
    t: 1581066000,
    v: 1.294017097972382
  },
  {
    t: 1581069600,
    v: 1.2939097553083456
  },
  {
    t: 1581073200,
    v: 1.2938316833742174
  },
  {
    t: 1581076800,
    v: 1.2939086659100063
  },
  {
    t: 1581080400,
    v: 1.293853554870958
  },
  {
    t: 1581084000,
    v: 1.2939741686927715
  },
  {
    t: 1581087600,
    v: 1.2939671050077457
  },
  {
    t: 1581091200,
    v: 1.2936435711974843
  },
  {
    t: 1581094800,
    v: 1.2933032310834383
  },
  {
    t: 1581098400,
    v: 1.2929219709802537
  },
  {
    t: 1581102000,
    v: 1.292519878505944
  },
  {
    t: 1581105600,
    v: 1.2921294138863302
  },
  {
    t: 1581109200,
    v: 1.2917532792304893
  },
  {
    t: 1581285600,
    v: 1.2914682050180617
  },
  {
    t: 1581289200,
    v: 1.2912264712068178
  },
  {
    t: 1581292800,
    v: 1.2910449025204542
  },
  {
    t: 1581296400,
    v: 1.2908777689470776
  },
  {
    t: 1581300000,
    v: 1.2907932195235463
  },
  {
    t: 1581303600,
    v: 1.2906767224260658
  },
  {
    t: 1581307200,
    v: 1.2906627488616786
  },
  {
    t: 1581310800,
    v: 1.29066724897009
  },
  {
    t: 1581314400,
    v: 1.2906113204967482
  },
  {
    t: 1581318000,
    v: 1.290308337592296
  },
  {
    t: 1581321600,
    v: 1.2902065911549343
  },
  {
    t: 1581325200,
    v: 1.2902888205687502
  },
  {
    t: 1581328800,
    v: 1.290479409086012
  },
  {
    t: 1581332400,
    v: 1.2906556558397253
  },
  {
    t: 1581336000,
    v: 1.2908970219502276
  },
  {
    t: 1581339600,
    v: 1.2910858770025868
  },
  {
    t: 1581343200,
    v: 1.29136341252615
  },
  {
    t: 1581346800,
    v: 1.2914564208569927
  },
  {
    t: 1581350400,
    v: 1.2914910474420411
  },
  {
    t: 1581354000,
    v: 1.2914366619713706
  },
  {
    t: 1581357600,
    v: 1.2914531703550496
  },
  {
    t: 1581361200,
    v: 1.2914623922259973
  },
  {
    t: 1581364800,
    v: 1.2914193072520928
  },
  {
    t: 1581368400,
    v: 1.2914184208471315
  },
  {
    t: 1581372000,
    v: 1.2914052379093095
  },
  {
    t: 1581375600,
    v: 1.291418072394137
  },
  {
    t: 1581379200,
    v: 1.2914001607375527
  },
  {
    t: 1581382800,
    v: 1.2914106216196906
  },
  {
    t: 1581386400,
    v: 1.2914381814654343
  },
  {
    t: 1581390000,
    v: 1.2914650213258692
  },
  {
    t: 1581393600,
    v: 1.291447400247215
  },
  {
    t: 1581397200,
    v: 1.2914381240331945
  },
  {
    t: 1581400800,
    v: 1.2914535407919379
  },
  {
    t: 1581404400,
    v: 1.2913741559546104
  },
  {
    t: 1581408000,
    v: 1.2912689982446475
  },
  {
    t: 1581411600,
    v: 1.2915100460308715
  },
  {
    t: 1581415200,
    v: 1.2916195654565028
  },
  {
    t: 1581418800,
    v: 1.2917462735082645
  },
  {
    t: 1581422400,
    v: 1.2918894855550964
  },
  {
    t: 1581426000,
    v: 1.2920628678831825
  },
  {
    t: 1581429600,
    v: 1.2924787852276414
  },
  {
    t: 1581433200,
    v: 1.2926703294916755
  },
  {
    t: 1581436800,
    v: 1.2928341076353254
  },
  {
    t: 1581440400,
    v: 1.29304133547958
  },
  {
    t: 1581444000,
    v: 1.2932859701958104
  },
  {
    t: 1581447600,
    v: 1.2935711158914476
  },
  {
    t: 1581451200,
    v: 1.2937767239017859
  },
  {
    t: 1581454800,
    v: 1.2938627501968538
  },
  {
    t: 1581458400,
    v: 1.2939986787495343
  },
  {
    t: 1581462000,
    v: 1.2941483283924358
  },
  {
    t: 1581465600,
    v: 1.2942608685455372
  },
  {
    t: 1581469200,
    v: 1.2944684048745336
  },
  {
    t: 1581472800,
    v: 1.294674271076959
  },
  {
    t: 1581476400,
    v: 1.2947852928791534
  },
  {
    t: 1581480000,
    v: 1.2948838364144721
  },
  {
    t: 1581483600,
    v: 1.2950015662797605
  },
  {
    t: 1581487200,
    v: 1.2950395123483547
  },
  {
    t: 1581490800,
    v: 1.2951747968866065
  },
  {
    t: 1581494400,
    v: 1.295306720992644
  },
  {
    t: 1581498000,
    v: 1.2955679856600113
  },
  {
    t: 1581501600,
    v: 1.2958053203590578
  },
  {
    t: 1581505200,
    v: 1.2960524327058143
  },
  {
    t: 1581508800,
    v: 1.2962874391147843
  },
  {
    t: 1581512400,
    v: 1.296314349675281
  },
  {
    t: 1581516000,
    v: 1.2962920306585874
  },
  {
    t: 1581519600,
    v: 1.296400408691103
  },
  {
    t: 1581523200,
    v: 1.2965232269109979
  },
  {
    t: 1581526800,
    v: 1.2965267291099505
  },
  {
    t: 1581530400,
    v: 1.2965098977661456
  },
  {
    t: 1581534000,
    v: 1.2964537170265127
  },
  {
    t: 1581537600,
    v: 1.29639431540494
  },
  {
    t: 1581541200,
    v: 1.2963339044139934
  },
  {
    t: 1581544800,
    v: 1.296266865898375
  },
  {
    t: 1581548400,
    v: 1.2962157358128155
  },
  {
    t: 1581552000,
    v: 1.2961332847830236
  },
  {
    t: 1581555600,
    v: 1.2961044005179738
  },
  {
    t: 1581559200,
    v: 1.2960801718972144
  },
  {
    t: 1581562800,
    v: 1.2960087269546225
  },
  {
    t: 1581566400,
    v: 1.29592503867323
  },
  {
    t: 1581570000,
    v: 1.295844558799589
  },
  {
    t: 1581573600,
    v: 1.295774600818676
  },
  {
    t: 1581577200,
    v: 1.2959674959788021
  },
  {
    t: 1581580800,
    v: 1.295991543980821
  },
  {
    t: 1581584400,
    v: 1.296102825506457
  },
  {
    t: 1581588000,
    v: 1.2963606516486992
  },
  {
    t: 1581591600,
    v: 1.2965539229202516
  },
  {
    t: 1581595200,
    v: 1.2971840254992753
  },
  {
    t: 1581598800,
    v: 1.2976769754517252
  },
  {
    t: 1581602400,
    v: 1.298309644456323
  },
  {
    t: 1581606000,
    v: 1.298993487841435
  },
  {
    t: 1581609600,
    v: 1.2995560128089174
  },
  {
    t: 1581613200,
    v: 1.3001316306366395
  },
  {
    t: 1581616800,
    v: 1.300614332480769
  },
  {
    t: 1581620400,
    v: 1.3010682055778386
  },
  {
    t: 1581624000,
    v: 1.3014255193323303
  },
  {
    t: 1581627600,
    v: 1.3016830889197275
  },
  {
    t: 1581631200,
    v: 1.3019037471178487
  },
  {
    t: 1581634800,
    v: 1.3021481521542442
  },
  {
    t: 1581638400,
    v: 1.302377851949078
  },
  {
    t: 1581642000,
    v: 1.3025923422396422
  },
  {
    t: 1581645600,
    v: 1.3028035477406286
  },
  {
    t: 1581649200,
    v: 1.3029327336700924
  },
  {
    t: 1581652800,
    v: 1.3030581876062741
  },
  {
    t: 1581656400,
    v: 1.303197407834248
  },
  {
    t: 1581660000,
    v: 1.3033186070881293
  },
  {
    t: 1581663600,
    v: 1.3035482635559266
  },
  {
    t: 1581667200,
    v: 1.3035989051220287
  },
  {
    t: 1581670800,
    v: 1.3035828189199308
  },
  {
    t: 1581674400,
    v: 1.3035273123561277
  },
  {
    t: 1581678000,
    v: 1.3034809016555442
  },
  {
    t: 1581681600,
    v: 1.3034198634026353
  },
  {
    t: 1581685200,
    v: 1.3032351145071461
  },
  {
    t: 1581688800,
    v: 1.3031327226493228
  },
  {
    t: 1581692400,
    v: 1.3029800823970064
  },
  {
    t: 1581696000,
    v: 1.3030467412163391
  },
  {
    t: 1581699600,
    v: 1.3031384801481163
  },
  {
    t: 1581703200,
    v: 1.3032081487054386
  },
  {
    t: 1581706800,
    v: 1.3032054678763492
  },
  {
    t: 1581710400,
    v: 1.3033563756976492
  },
  {
    t: 1581714000,
    v: 1.303470054202635
  },
  {
    t: 1581890400,
    v: 1.3035510014214315
  },
  {
    t: 1581894000,
    v: 1.3036975727146285
  },
  {
    t: 1581897600,
    v: 1.3037416134084734
  },
  {
    t: 1581901200,
    v: 1.303810031179095
  },
  {
    t: 1581904800,
    v: 1.3038928853525145
  },
  {
    t: 1581908400,
    v: 1.3039497534141797
  },
  {
    t: 1581912000,
    v: 1.3039802530890197
  },
  {
    t: 1581915600,
    v: 1.3040602289853036
  },
  {
    t: 1581919200,
    v: 1.3040449690819413
  },
  {
    t: 1581922800,
    v: 1.3040064005979468
  },
  {
    t: 1581926400,
    v: 1.3040153148267137
  },
  {
    t: 1581930000,
    v: 1.3040090943670266
  },
  {
    t: 1581933600,
    v: 1.3039387044273099
  },
  {
    t: 1581937200,
    v: 1.3037197801961375
  },
  {
    t: 1581940800,
    v: 1.3034931344631722
  },
  {
    t: 1581944400,
    v: 1.3033061692762034
  },
  {
    t: 1581948000,
    v: 1.303060819821327
  },
  {
    t: 1581951600,
    v: 1.3028283607907243
  },
  {
    t: 1581955200,
    v: 1.3026542311916076
  },
  {
    t: 1581958800,
    v: 1.3025090663162164
  },
  {
    t: 1581962400,
    v: 1.3023034409527672
  },
  {
    t: 1581966000,
    v: 1.3020926370525037
  },
  {
    t: 1581969600,
    v: 1.3019057192379795
  },
  {
    t: 1581973200,
    v: 1.301762317405791
  },
  {
    t: 1581976800,
    v: 1.3016201919385728
  },
  {
    t: 1581980400,
    v: 1.3015182688968039
  },
  {
    t: 1581984000,
    v: 1.301328909954251
  },
  {
    t: 1581987600,
    v: 1.3011804423395605
  },
  {
    t: 1581991200,
    v: 1.3010537335453167
  },
  {
    t: 1581994800,
    v: 1.3009790922552866
  },
  {
    t: 1581998400,
    v: 1.3008363215643068
  },
  {
    t: 1582002000,
    v: 1.3007128623677062
  },
  {
    t: 1582005600,
    v: 1.3005135421422105
  },
  {
    t: 1582009200,
    v: 1.3004446333667619
  },
  {
    t: 1582012800,
    v: 1.300191811141356
  },
  {
    t: 1582016400,
    v: 1.300131638651703
  },
  {
    t: 1582020000,
    v: 1.3003933873515408
  },
  {
    t: 1582023600,
    v: 1.3007416361752036
  },
  {
    t: 1582027200,
    v: 1.300974813682327
  },
  {
    t: 1582030800,
    v: 1.3011286409506768
  },
  {
    t: 1582034400,
    v: 1.3012630560982315
  },
  {
    t: 1582038000,
    v: 1.301482765041257
  },
  {
    t: 1582041600,
    v: 1.3015005969420896
  },
  {
    t: 1582045200,
    v: 1.3013919686618907
  },
  {
    t: 1582048800,
    v: 1.3012270192655202
  },
  {
    t: 1582052400,
    v: 1.301098731716423
  },
  {
    t: 1582056000,
    v: 1.300991233457716
  },
  {
    t: 1582059600,
    v: 1.300852068366505
  },
  {
    t: 1582063200,
    v: 1.3007556809030283
  },
  {
    t: 1582066800,
    v: 1.300671330340835
  },
  {
    t: 1582070400,
    v: 1.3006073941178984
  },
  {
    t: 1582074000,
    v: 1.3005562137257176
  },
  {
    t: 1582077600,
    v: 1.3004727647994587
  },
  {
    t: 1582081200,
    v: 1.300408691961415
  },
  {
    t: 1582084800,
    v: 1.3003783403460423
  },
  {
    t: 1582088400,
    v: 1.3003318317416572
  },
  {
    t: 1582092000,
    v: 1.300246895385309
  },
  {
    t: 1582095600,
    v: 1.3002814767771844
  },
  {
    t: 1582099200,
    v: 1.3001984789888812
  },
  {
    t: 1582102800,
    v: 1.3002443381327973
  },
  {
    t: 1582106400,
    v: 1.3000429725963405
  },
  {
    t: 1582110000,
    v: 1.2999141180633558
  },
  {
    t: 1582113600,
    v: 1.2998365830097027
  },
  {
    t: 1582117200,
    v: 1.2996397655802072
  },
  {
    t: 1582120800,
    v: 1.2992788355249494
  },
  {
    t: 1582124400,
    v: 1.298876089284478
  },
  {
    t: 1582128000,
    v: 1.2983450331621467
  },
  {
    t: 1582131600,
    v: 1.297719791908609
  },
  {
    t: 1582135200,
    v: 1.2971388593458844
  },
  {
    t: 1582138800,
    v: 1.2966389679796098
  },
  {
    t: 1582142400,
    v: 1.296226685314885
  },
  {
    t: 1582146000,
    v: 1.295770810522991
  },
  {
    t: 1582149600,
    v: 1.2954059714255632
  },
  {
    t: 1582153200,
    v: 1.295133974146938
  },
  {
    t: 1582156800,
    v: 1.2949031194662772
  },
  {
    t: 1582160400,
    v: 1.2945742509456795
  },
  {
    t: 1582164000,
    v: 1.294181465141329
  },
  {
    t: 1582167600,
    v: 1.2938565636992978
  },
  {
    t: 1582171200,
    v: 1.2936016528707932
  },
  {
    t: 1582174800,
    v: 1.2933576859307176
  },
  {
    t: 1582178400,
    v: 1.293072192032554
  },
  {
    t: 1582182000,
    v: 1.292767221362787
  },
  {
    t: 1582185600,
    v: 1.292514152661569
  },
  {
    t: 1582189200,
    v: 1.2922909000271339
  },
  {
    t: 1582192800,
    v: 1.2918755762150258
  },
  {
    t: 1582196400,
    v: 1.2914140927659756
  },
  {
    t: 1582200000,
    v: 1.2909432267882637
  },
  {
    t: 1582203600,
    v: 1.2904553004274766
  },
  {
    t: 1582207200,
    v: 1.290125271815336
  },
  {
    t: 1582210800,
    v: 1.2899257221186373
  },
  {
    t: 1582214400,
    v: 1.2897080342978147
  },
  {
    t: 1582218000,
    v: 1.2895177453170703
  },
  {
    t: 1582221600,
    v: 1.2893684362392541
  },
  {
    t: 1582225200,
    v: 1.2892381089783729
  },
  {
    t: 1582228800,
    v: 1.289131622409004
  },
  {
    t: 1582232400,
    v: 1.288984801227194
  },
  {
    t: 1582236000,
    v: 1.2888814868246041
  },
  {
    t: 1582239600,
    v: 1.2888003928413085
  },
  {
    t: 1582243200,
    v: 1.2887374982849935
  },
  {
    t: 1582246800,
    v: 1.2887329746388037
  },
  {
    t: 1582250400,
    v: 1.2887412627684414
  },
  {
    t: 1582254000,
    v: 1.288800190123828
  },
  {
    t: 1582257600,
    v: 1.2888496958263205
  },
  {
    t: 1582261200,
    v: 1.2889078200333375
  },
  {
    t: 1582264800,
    v: 1.2889423133634959
  },
  {
    t: 1582268400,
    v: 1.2889382835193535
  },
  {
    t: 1582272000,
    v: 1.2891555898508436
  },
  {
    t: 1582275600,
    v: 1.28938839081743
  },
  {
    t: 1582279200,
    v: 1.2895361631205318
  },
  {
    t: 1582282800,
    v: 1.2900270047281002
  },
  {
    t: 1582286400,
    v: 1.2902863376111382
  },
  {
    t: 1582290000,
    v: 1.2905914483148393
  },
  {
    t: 1582293600,
    v: 1.290961786570569
  },
  {
    t: 1582297200,
    v: 1.2914625688019432
  },
  {
    t: 1582300800,
    v: 1.2919337527255677
  },
  {
    t: 1582304400,
    v: 1.2924514905612279
  },
  {
    t: 1582308000,
    v: 1.292964681936349
  },
  {
    t: 1582311600,
    v: 1.2933813788947919
  },
  {
    t: 1582315200,
    v: 1.2936488666190975
  },
  {
    t: 1582318800,
    v: 1.2938051650363263
  },
  {
    t: 1582495200,
    v: 1.2938846731281046
  },
  {
    t: 1582498800,
    v: 1.2940032756873328
  },
  {
    t: 1582502400,
    v: 1.2940582018123488
  },
  {
    t: 1582506000,
    v: 1.294122182592125
  },
  {
    t: 1582509600,
    v: 1.2941067366309702
  },
  {
    t: 1582513200,
    v: 1.294040380761354
  },
  {
    t: 1582516800,
    v: 1.2940155825936062
  },
  {
    t: 1582520400,
    v: 1.293978860441834
  },
  {
    t: 1582524000,
    v: 1.2939999213521356
  },
  {
    t: 1582527600,
    v: 1.2940132621757416
  },
  {
    t: 1582531200,
    v: 1.2938719991113854
  },
  {
    t: 1582534800,
    v: 1.2934660944341105
  },
  {
    t: 1582538400,
    v: 1.2931178949641953
  },
  {
    t: 1582542000,
    v: 1.2928571430628433
  },
  {
    t: 1582545600,
    v: 1.2927888437235249
  },
  {
    t: 1582549200,
    v: 1.2925213347974749
  },
  {
    t: 1582552800,
    v: 1.2924745410072391
  },
  {
    t: 1582556400,
    v: 1.2924626799589307
  },
  {
    t: 1582560000,
    v: 1.2924338532961754
  },
  {
    t: 1582563600,
    v: 1.2925458672679682
  },
  {
    t: 1582567200,
    v: 1.2925805465757807
  },
  {
    t: 1582570800,
    v: 1.2924633516638016
  },
  {
    t: 1582574400,
    v: 1.2924887467434394
  },
  {
    t: 1582578000,
    v: 1.2924926756250166
  },
  {
    t: 1582581600,
    v: 1.2924562303273959
  },
  {
    t: 1582585200,
    v: 1.2924499226771677
  },
  {
    t: 1582588800,
    v: 1.2924337395650565
  },
  {
    t: 1582592400,
    v: 1.2925829072255273
  },
  {
    t: 1582596000,
    v: 1.2927092970135723
  },
  {
    t: 1582599600,
    v: 1.2928160306313272
  },
  {
    t: 1582603200,
    v: 1.2928973610473913
  },
  {
    t: 1582606800,
    v: 1.2929280885666874
  },
  {
    t: 1582610400,
    v: 1.2930063658460504
  },
  {
    t: 1582614000,
    v: 1.2932333786226171
  },
  {
    t: 1582617600,
    v: 1.2936578187537964
  },
  {
    t: 1582621200,
    v: 1.2942046931581968
  },
  {
    t: 1582624800,
    v: 1.2944709128574161
  },
  {
    t: 1582628400,
    v: 1.2946689211567097
  },
  {
    t: 1582632000,
    v: 1.2947309286655946
  },
  {
    t: 1582635600,
    v: 1.2949384592688713
  },
  {
    t: 1582639200,
    v: 1.2952567012432645
  },
  {
    t: 1582642800,
    v: 1.2958570154105726
  },
  {
    t: 1582646400,
    v: 1.296272537752423
  },
  {
    t: 1582650000,
    v: 1.2967332484426684
  },
  {
    t: 1582653600,
    v: 1.2970929390671762
  },
  {
    t: 1582657200,
    v: 1.2973650401083974
  },
  {
    t: 1582660800,
    v: 1.2976074172409309
  },
  {
    t: 1582664400,
    v: 1.2978714727417946
  },
  {
    t: 1582668000,
    v: 1.2980637134330522
  },
  {
    t: 1582671600,
    v: 1.298267169296571
  },
  {
    t: 1582675200,
    v: 1.29841791507785
  },
  {
    t: 1582678800,
    v: 1.2986285898323406
  },
  {
    t: 1582682400,
    v: 1.2987334860387845
  },
  {
    t: 1582686000,
    v: 1.298778868320805
  },
  {
    t: 1582689600,
    v: 1.2987637380045378
  },
  {
    t: 1582693200,
    v: 1.2988471915279152
  },
  {
    t: 1582696800,
    v: 1.2988093637633518
  },
  {
    t: 1582700400,
    v: 1.2987779957858896
  },
  {
    t: 1582704000,
    v: 1.298641043806281
  },
  {
    t: 1582707600,
    v: 1.2982904682056828
  },
  {
    t: 1582711200,
    v: 1.2978056617099034
  },
  {
    t: 1582714800,
    v: 1.2973822653565792
  },
  {
    t: 1582718400,
    v: 1.296945859132143
  },
  {
    t: 1582722000,
    v: 1.2966148249290816
  },
  {
    t: 1582725600,
    v: 1.2962115082691692
  },
  {
    t: 1582729200,
    v: 1.295778031291153
  },
  {
    t: 1582732800,
    v: 1.295416314025329
  },
  {
    t: 1582736400,
    v: 1.294974760308631
  },
  {
    t: 1582740000,
    v: 1.2945266878982853
  },
  {
    t: 1582743600,
    v: 1.2941965271460676
  },
  {
    t: 1582747200,
    v: 1.2938797150369183
  },
  {
    t: 1582750800,
    v: 1.2934654564619736
  },
  {
    t: 1582754400,
    v: 1.2931420796560713
  },
  {
    t: 1582758000,
    v: 1.2928942625459692
  },
  {
    t: 1582761600,
    v: 1.2927586184939721
  },
  {
    t: 1582765200,
    v: 1.292604464351689
  },
  {
    t: 1582768800,
    v: 1.2925668963181949
  },
  {
    t: 1582772400,
    v: 1.2925414776212238
  },
  {
    t: 1582776000,
    v: 1.292593717847774
  },
  {
    t: 1582779600,
    v: 1.2926247923384622
  },
  {
    t: 1582783200,
    v: 1.2926776692586086
  },
  {
    t: 1582786800,
    v: 1.2926283674244554
  },
  {
    t: 1582790400,
    v: 1.2926789990983167
  },
  {
    t: 1582794000,
    v: 1.2924514753746674
  },
  {
    t: 1582797600,
    v: 1.291930382481842
  },
  {
    t: 1582801200,
    v: 1.2914617746264285
  },
  {
    t: 1582804800,
    v: 1.2912530341858164
  },
  {
    t: 1582808400,
    v: 1.2910898880728814
  },
  {
    t: 1582812000,
    v: 1.290817517780226
  },
  {
    t: 1582815600,
    v: 1.2904768018011568
  },
  {
    t: 1582819200,
    v: 1.2901894873439037
  },
  {
    t: 1582822800,
    v: 1.290017155215913
  },
  {
    t: 1582826400,
    v: 1.2899136166239213
  },
  {
    t: 1582830000,
    v: 1.2898504150406906
  },
  {
    t: 1582833600,
    v: 1.2897713278939582
  },
  {
    t: 1582837200,
    v: 1.2896397728564384
  },
  {
    t: 1582840800,
    v: 1.2895826516320157
  },
  {
    t: 1582844400,
    v: 1.2894985895718236
  },
  {
    t: 1582848000,
    v: 1.2894892000887928
  },
  {
    t: 1582851600,
    v: 1.289437847699384
  },
  {
    t: 1582855200,
    v: 1.2893694812518235
  },
  {
    t: 1582858800,
    v: 1.289180006846888
  },
  {
    t: 1582862400,
    v: 1.289103815718613
  },
  {
    t: 1582866000,
    v: 1.2890644046977926
  },
  {
    t: 1582869600,
    v: 1.2889563661551457
  },
  {
    t: 1582873200,
    v: 1.2887024265213223
  },
  {
    t: 1582876800,
    v: 1.2886241001859582
  },
  {
    t: 1582880400,
    v: 1.2887960906444385
  },
  {
    t: 1582884000,
    v: 1.2887459867735396
  },
  {
    t: 1582887600,
    v: 1.2887025594617738
  },
  {
    t: 1582891200,
    v: 1.2885823157035097
  },
  {
    t: 1582894800,
    v: 1.2882944761126993
  },
  {
    t: 1582898400,
    v: 1.2877150021972041
  },
  {
    t: 1582902000,
    v: 1.286725954368899
  },
  {
    t: 1582905600,
    v: 1.2858577682385275
  },
  {
    t: 1582909200,
    v: 1.2849284569777153
  },
  {
    t: 1582912800,
    v: 1.2840286039322186
  },
  {
    t: 1582916400,
    v: 1.2835658797481977
  },
  {
    t: 1582920000,
    v: 1.283420557867417
  },
  {
    t: 1582923600,
    v: 1.28321383807052
  }
]
