import React from 'react'
import { isMobile } from 'react-device-detect'
import { renko, smaCustom } from '../../../../libs/react-stockcharts/lib/indicator'
import { getMorePropsForChart } from '../../../../libs/react-stockcharts/lib/interactive/utils'
import { discontinuousTimeScaleProvider } from '../../../../libs/react-stockcharts/lib/scale'
import { head, toObject } from '../../../../libs/react-stockcharts/lib/utils'
import { IGraphicsState, ITool } from '../../../../redux/graphics/types'
import { LineStyle, MarketOrderTypes, OrderWindowTargetName } from '../../../../utils/enums'
import { IMoreProps } from '../../../../utils/interfaces'
import { IChartTools, IInteractives } from '../../../../utils/interfaces/IGraphics'
import { IBar } from '../../../../utils/interfaces/IHistoricalData.interface'
import { IIndicator, IIndicatorSettings, IOscillator } from '../../../../utils/interfaces/IIndicator.interface'
import { getValidTickSize } from '../../../webTester/webTesterUtils'
import { defaultVisibleBarsCount } from './constants'

export const calculateDigitsAfterPoint = (price: number): number => {
  if (Math.floor(price) === price) {
    return 0
  }

  return price.toString().split('.')[1].length || 0
}

export const getChartId = (charts: { symbol: string }[], symbol: string, index: number): string => {
  let id: number = 0

  for (let i = 0; i <= index && i < charts.length; i++) {
    if (charts[i].symbol === symbol) {
      id++
    }
  }

  if (id === 0) throw 'ChartId is not found'

  const chartId: string = `${symbol}${id}`

  return chartId
}

export const validateSlTpDragging = (
  orderType: string,
  lineType: string,
  slOrTpPrice: number,
  orderEntryPrice: number
): boolean => {
  if (orderType === MarketOrderTypes.Buy && lineType === OrderWindowTargetName.Sl && slOrTpPrice <= orderEntryPrice) {
    return true
  } else if (
    orderType === MarketOrderTypes.Buy &&
    lineType === OrderWindowTargetName.Tp &&
    slOrTpPrice >= orderEntryPrice
  ) {
    return true
  } else if (
    orderType === MarketOrderTypes.Sell &&
    lineType === OrderWindowTargetName.Sl &&
    slOrTpPrice >= orderEntryPrice
  ) {
    return true
  } else if (
    orderType === MarketOrderTypes.Sell &&
    lineType === OrderWindowTargetName.Tp &&
    slOrTpPrice <= orderEntryPrice
  ) {
    return true
  } else return false
}

export const getSlTpValidationMessage = (orderType: string, lineType: string): string => {
  if (orderType === MarketOrderTypes.Buy && lineType === OrderWindowTargetName.Sl) {
    return 'below'
  } else if (orderType === MarketOrderTypes.Buy && lineType === OrderWindowTargetName.Tp) {
    return 'above'
  } else if (orderType === MarketOrderTypes.Sell && lineType === OrderWindowTargetName.Sl) {
    return 'above'
  } else if (orderType === MarketOrderTypes.Sell && lineType === OrderWindowTargetName.Tp) {
    return 'below'
  } else return ''
}

export const getIndicatorNameWithSettings = (fullName: string, settings: IIndicatorSettings): string => {
  let periods: number[] = []
  Object.keys(settings).map((key, i) => {
    if (key.match(new RegExp('.*(eriod).*'))) {
      const period = Object.values(settings)[i]
      periods.push(period)
    }
  })

  // !Crutch: will work only if the periods array consists of 1-2 digits
  const period1 = periods[0] ? `${periods[0]}, ` : ''
  const period2 = periods[1] ? `${periods[1]}, ` : ''

  const maType = settings.MAType ? `${settings.MAType}, ` : ''
  const applyToPrice = settings.ApplyToPrice ? `${settings.ApplyToPrice}` : ''
  // Awesome Oscillator (5, 34, Simple (SMA), Close)
  return `${fullName} (${period1}${period2}${maType}${applyToPrice})`
}

export const calculateChartHeights = (chartCanvasHeight: number, oscillatorsCount: number) => {
  let shouldRemoveLastOscillator: boolean = false
  let priceChartHeight: number = 0
  let oscillatorChartHeight: number = 0

  const minPriceChartHeight: number = 100
  const preferredPriceChartHeight: number = 400

  const minOscillatorChartHeight: number = 40
  const preferredOscillatorChartHeight: number = 90

  const adjustedOscillatorHeight = (chartCanvasHeight - preferredPriceChartHeight) / oscillatorsCount

  let adjustedPriceChartHeight: number = 0
  if (adjustedOscillatorHeight > minOscillatorChartHeight) {
    adjustedPriceChartHeight = chartCanvasHeight - oscillatorsCount * preferredOscillatorChartHeight
  } else {
    adjustedPriceChartHeight = chartCanvasHeight - oscillatorsCount * minOscillatorChartHeight
  }

  if (adjustedPriceChartHeight >= preferredPriceChartHeight) {
    priceChartHeight = adjustedPriceChartHeight
    oscillatorChartHeight = preferredOscillatorChartHeight
  } else if (adjustedPriceChartHeight < preferredPriceChartHeight) {
    if (adjustedOscillatorHeight >= minOscillatorChartHeight) {
      priceChartHeight = preferredPriceChartHeight
      oscillatorChartHeight = adjustedOscillatorHeight
    } else if (adjustedOscillatorHeight < minOscillatorChartHeight) {
      if (adjustedPriceChartHeight >= minPriceChartHeight) {
        priceChartHeight = adjustedPriceChartHeight
        oscillatorChartHeight = minOscillatorChartHeight
      } else {
        shouldRemoveLastOscillator = true
        priceChartHeight = minPriceChartHeight
        oscillatorChartHeight = minOscillatorChartHeight
      }
    }
  }

  return {
    chartCanvasHeight,
    priceChartHeight,
    oscillatorChartHeight,
    shouldRemoveLastOscillator
  }
}

export const approximatelyEqualPrices = (
  price1: number | object,
  price2: number,
  digitsAfterPoint: number
): boolean => {
  function check(price) {
    const difference: number = Math.abs(price - price2) // e.g. 0.00010
    const multiplier: number = Math.pow(10, digitsAfterPoint) // e.g. 100,000
    const actualDeviationInPoints: number = difference * multiplier // e.g. 10
    const maxDeviationInPoints: number = 10 // 1 pip

    if (actualDeviationInPoints <= maxDeviationInPoints) {
      return true
    } else {
      return false
    }
  }

  // TODO: deviation for bar indexes
  if (typeof price1 === 'object') {
    const res = Object.keys(price1).map((key) => check(price1[key]))

    return res.some((r) => r)
  } else {
    return check(price1)
  }
}

export const approximatelyEqualPoints = (
  start: [number, number],
  end: [number, number],
  position: [number, number]
): boolean => {
  if (calcDistance(start, position) + calcDistance(end, position) === calcDistance(start, end)) {
    return true
  } else {
    return false
  }
}

export const calcDistance = (start: [number, number], end: [number, number]): number => {
  return Math.sqrt(Math.abs(start[0] - end[0]) + Math.abs(start[1] - end[1]))
}

export const chartCanvasPadding = (isChartOffsetActive: boolean, chartCanvasWidth: number) => ({
  left: 0,
  right: isChartOffsetActive ? chartCanvasWidth * 0.2 : 0
})

/**
 * Return yAccessor for each indicator
 * yAccessor provides indicator data for each bar (use it to draw indicators)
 * */
export const getIndicatorsYAccessors = (indicators: IIndicator[]) =>
  indicators.map((indicator) => {
    return (
      smaCustom()
        //@ts-ignore
        .accessor((d) => d[indicator.name])
        .accessor()
    )
  })

/**
 * Return yAccessor for each oscillator
 * yAccessor provides oscillator data for each bar (use it to draw oscillators)
 * */
export const getOscillatorsYAccessors = (oscillators: IOscillator[]) =>
  oscillators.map((oscillator) => {
    return (
      smaCustom()
        //@ts-ignore
        .accessor((d) => d[oscillator.name])
        .accessor()
    )
  })

export const checkAnyGraphicToolActive = (graphicToolsActiveState: ITool) =>
  Object.values(graphicToolsActiveState).reduce((previousValue, currentValue) => previousValue || currentValue)

export const getYGrid = (isShowGridActive: boolean, gridHeight: number) =>
  isShowGridActive
    ? {
        innerTickSize: -1 * gridHeight,
        tickStrokeDasharray: LineStyle.ShortDash,
        tickStrokeOpacity: 0.2,
        tickStrokeWidth: 1
      }
    : {
        innerTickSize: 0,
        tickStrokeDasharray: LineStyle.None,
        tickStrokeOpacity: 0,
        tickStrokeWidth: 0
      }

export const getXGrid = (isShowGridActive: boolean, gridWidth: number) =>
  isShowGridActive
    ? {
        innerTickSize: -1 * gridWidth,
        tickStrokeDasharray: LineStyle.ShortDash,
        tickStrokeOpacity: 0.2,
        tickStrokeWidth: 1
      }
    : {
        innerTickSize: 0,
        tickStrokeDasharray: LineStyle.None,
        tickStrokeOpacity: 0,
        tickStrokeWidth: 0
      }

export const getChartIndicators = (graphics: IGraphicsState, chartId: string): IIndicator[] =>
  graphics.indicators[chartId] ? graphics.indicators[chartId] : []

export const getChartOscillators = (graphics: IGraphicsState, chartId: string): IOscillator[] =>
  graphics.oscillators[chartId] ? graphics.oscillators[chartId] : []

export const getOscillatorsSelectIconXY = (
  oscillatorIconRef: React.RefObject<SVGSVGElement>,
  chartRef: React.RefObject<HTMLDivElement>
): [number, number] => {
  if (!oscillatorIconRef.current) return [50, 50]

  const iconOscillatorSelectorRect = oscillatorIconRef.current.getBoundingClientRect()
  const chartRect = chartRef.current!.getBoundingClientRect()
  const canvasMargin = 20

  const x = 0
  const y = iconOscillatorSelectorRect.y - iconOscillatorSelectorRect.height + canvasMargin - chartRect.y

  return [x, y]
}

export const getMousePosition = (moreProps: IMoreProps, independentCharts): [number, number] => {
  const first = head(independentCharts)

  const morePropsForChart = getMorePropsForChart(moreProps, first)
  const {
    mouseXY: [, mouseY],
    chartConfig: { yScale },
    xAccessor,
    currentItem
  } = morePropsForChart

  const x = xAccessor(currentItem)
  const y = yScale.invert(mouseY)

  return [x, y]
}

export const getStateInteractive = (interactives: IInteractives) =>
  toObject(interactives, (each) => [each.type + '_' + each.chartId, each.objects])

export const getChartTools = (tools: IChartTools): IChartTools => {
  const chartTools = { ...tools }
  Object.keys(chartTools).map((toolName) => {
    chartTools[toolName] = chartTools[toolName].filter((el) => !el.selected)
  })

  return chartTools
}

export const getChartCanvasWidth = (chartsLength: number, isChartsTileVertically: boolean): number => {
  const minWidth: number = 200
  const chartContainer = document.querySelector('.chartContainer')
  let chartWidth: number = chartContainer ? chartContainer.clientWidth : 0

  if (isChartsTileVertically) {
    const chartWrapperWithTabsBorder = 6
    chartWidth = +(chartWidth / chartsLength) - chartWrapperWithTabsBorder * chartsLength
  }

  if (chartWidth < minWidth) {
    chartWidth = minWidth
  }

  return chartWidth
}

export const getHeightsOptions = (
  chartsLength: number,
  isChartTileHorizontally: boolean,
  oscillatorsCount: number,
  mobileMenuIsOpened: boolean,
  height: number | undefined
) => {
  const minHeight: number = isMobile ? 100 : 200
  const tabsHeight: number = isMobile ? 0 : 23
  let chartHeight: number

  chartHeight = height
    ? height
    : isMobile
    ? document.querySelector('.chartContainer')!.clientHeight
    : document.querySelector('.chartContainer')!.clientHeight - tabsHeight

  if (isChartTileHorizontally && !height) {
    const newHeight = +((chartHeight - tabsHeight * (chartsLength - 1)) / chartsLength)
    chartHeight = newHeight > minHeight ? newHeight : minHeight
  }

  return calculateChartHeights(chartHeight, mobileMenuIsOpened ? 0 : oscillatorsCount)
}

export const getOscillatorsCount = (isMobile: boolean, oscillatorsLength: number): number => {
  return isMobile ? (oscillatorsLength > 0 ? 1 : 0) : oscillatorsLength
}

const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
  // TODO Temporary. It fixes a bug with the appearance of the chart.
  //  (Also comment <Slider> in ChartWrapper.tsx)
  (d) => {
    const date = new Date(d.date)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() + userTimezoneOffset)
  }
)

export const getChartCanvasProps = (initialData: IBar[], isRenkoBarActive: boolean) => {
  if (!initialData) {
    console.warn('Empty initial data passed')
    return
  }
  let _data, _xScale, _displayXAccessor, _xAccessor

  let { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(initialData)

  _data = data
  _xScale = xScale
  _xAccessor = xAccessor
  _displayXAccessor = displayXAccessor

  if (isRenkoBarActive) {
    const renkoCalculator = renko()
    let { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(renkoCalculator(initialData))
    _data = data
    _xScale = xScale
    _xAccessor = xAccessor
    _displayXAccessor = displayXAccessor
  }

  return { _data, _xScale, _displayXAccessor, _xAccessor }
}

export function isOscillatorsVisible(bottomMenuIsOpen: boolean): boolean {
  return !bottomMenuIsOpen
}

export function getVisibleBarsWidth(fullWidth, barsCount): number {
  const fixChartMoving = 0.0165
  return (fullWidth / defaultVisibleBarsCount) * barsCount - fullWidth * fixChartMoving
}

export const isTickDataLoading = (isLoadingData, symbol: string, tickSize: number, testingSpeed: number): boolean => {
  const validTickSize = getValidTickSize(tickSize, testingSpeed)

  return isLoadingData[symbol] && isLoadingData[symbol][validTickSize]
}

export function isTimeframeDataLoading(isLoadingData, symbol, timeFrame, tickSize) {
  return isLoadingData[symbol] && isLoadingData[symbol][timeFrame] && timeFrame !== tickSize
}
