import axios from 'axios'
import { AdvancedTutorialSubtype, IntermediateTutorialSubtype, QuickStartTutorialSubtype, CourseType } from '../enums'
import { ITutorial, ITutorialItem } from '../interfaces/ITutorial.interface'
import { getBaseServerUrl } from './utils'
import { convertLanguageNameToIndex } from '../../components/topMenu/topMenuUtils/topMenuUtils'
import { toast } from 'react-toastify'
import { StatusCodes } from 'http-status-codes'

const apiClient = axios.create({
  baseURL: getBaseServerUrl() + 'tutorials/api/EducationTutorial',
  // responseType: 'arraybuffer',
  // headers: { 'Content-Type': 'application/octet-stream' }
  responseType: 'json',
  headers: { 'Content-Type': 'application/json' }
})

type TutorialSubtype = QuickStartTutorialSubtype | IntermediateTutorialSubtype | AdvancedTutorialSubtype

export const getTutorials = async (
  languageIndex: number,
  type: CourseType,
  subtype: string
): Promise<ITutorialItem[]> => {
  const response = await apiClient.get<ITutorialItem[]>(
    `/Course?language=${languageIndex}&type=${type}&name=${subtype}`
  )
  const tutorials: ITutorialItem[] = response.data

  if (tutorials) return tutorials

  throw new Error('Unable to fetch tutorials!')
}

export const getTutorial = async (id: string, language: string): Promise<ITutorial | undefined> => {
  try {
    const response = await apiClient.get<ITutorial>(`/Lesson/${id}?language=${convertLanguageNameToIndex(language)}`)
    const tutorial = response.data

    if (response.status === StatusCodes.OK && tutorial) {
      return tutorial
    }
  } catch (e) {
    toast.error(`Unable to fetch tutorial! ID: ${id}`)
  }
}

export const getCoursesList = async (type: CourseType, language: string): Promise<string[] | undefined> => {
  const langIndex = convertLanguageNameToIndex(language)

  try {
    const response = await apiClient.get<string[]>(`/Courses?language=${langIndex}&type=${type}`)
    const courses = response.data

    if (response.status === StatusCodes.OK && courses) {
      return courses
    }
    toast.warn(`Unable to fetch courses list ${type}`)
  } catch (e) {
    toast.error(`Unable to fetch courses list ${type}`)
  }
}

export const getTutorialsList = async (language: string, type: CourseType, courseName: string): Promise<ITutorial> => {
  const langIndex = convertLanguageNameToIndex(language)
  const response = await apiClient.get<ITutorial>(`/Course?language=${langIndex}&type=${type}&name=${courseName}`)
  const tutorialsList = response.data

  if (tutorialsList) return tutorialsList

  throw new Error(`Unable to fetch a list of tutorials for course ${courseName!}`)
}
