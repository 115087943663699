import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IModalsState } from './types'

export const initialModalsState: IModalsState = {
  marketOrderModalIsOpen: false,
  marketOrderQuestionModalIsOpen: false,
  pendingOrderModalIsOpen: false,
  pendingOrderQuestionModalIsOpen: false,
  indicatorModalEditModeIsOpen: false,
  oscillatorModalEditModeIsOpen: false,
  indicatorModalCreateModeIsOpen: false,
  trialVersionModalIsOpen: false,
  newProjectModalIsOpen: false,
  openProjectModalIsOpen: false,
  editProjectModalIsOpen: false,
  saveProjectModalIsOpen: false,
  saveProjectAsModalIsOpen: false,
  projectInformationModalIsOpen: false,
  depositChangeModalIsOpen: false,
  interactiveTextModalIsOpen: false,
  quickStartCoursesModalIsOpen: false,
  tutorialFeedbackModalIsOpen: false,
  subscribeModalIsOpen: false,
  demonstrationModalIsOpen: false,
  finishedTutorialModalIsOpen: false,
  listOfStrategiesModalIsOpen: false,
  strategyOptimizerModalIsOpen: false,
  basicCoursesModalIsOpen: false,
  defaultOrdersModalIsOpen: false,
  hotKeysModalIsOpen: false,
  soundsModalIsOpen: false,
  signalToCloseDropdown: false,
  notEnoughFreeMarginModalIsOpened: false,
  quitTutorialModalIsOpened: false,
  lessonAlertModalIsOpen: false,
  startNewProjectModalIsOpen: false,
  rewardToRiskModal: {
    IsOpen: false,
    type: 'buy'
  }
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: { ...initialModalsState },
  reducers: {
    toggleRewardToRiskModal: (state, action: PayloadAction<'buy' | 'sell' | undefined>) => {
      state.rewardToRiskModal.IsOpen = !state.rewardToRiskModal.IsOpen
      state.rewardToRiskModal.type = action.payload
    },
    toggleStartNewProjectModal: (state) => {
      state.startNewProjectModalIsOpen = !state.startNewProjectModalIsOpen
    },
    toggleMarketOrderModal(state) {
      state.marketOrderModalIsOpen = !state.marketOrderModalIsOpen
    },

    toggleMarketOrderQuestionModal(state) {
      state.marketOrderQuestionModalIsOpen = !state.marketOrderQuestionModalIsOpen
    },

    togglePendingOrderModal(state) {
      state.pendingOrderModalIsOpen = !state.pendingOrderModalIsOpen
    },

    togglePendingOrderQuestionModal(state) {
      state.pendingOrderQuestionModalIsOpen = !state.pendingOrderQuestionModalIsOpen
    },

    toggleIndicatorModalCreateMode(state) {
      state.indicatorModalCreateModeIsOpen = !state.indicatorModalCreateModeIsOpen
    },

    toggleIndicatorModalEditMode(state) {
      state.indicatorModalEditModeIsOpen = !state.indicatorModalEditModeIsOpen
    },

    toggleOscillatorModalEditMode(state) {
      state.oscillatorModalEditModeIsOpen = !state.oscillatorModalEditModeIsOpen
    },

    toggleTrialVersionModal(state) {
      state.trialVersionModalIsOpen = !state.trialVersionModalIsOpen
    },

    toggleLessonAlertModal(state) {
      state.lessonAlertModalIsOpen = !state.lessonAlertModalIsOpen
    },

    toggleNewProjectModal(state) {
      state.newProjectModalIsOpen = !state.newProjectModalIsOpen
    },

    toggleOpenProjectModal(state) {
      state.openProjectModalIsOpen = !state.openProjectModalIsOpen
    },

    toggleEditProjectModal(state) {
      state.editProjectModalIsOpen = !state.editProjectModalIsOpen
    },

    toggleDepositChangeModal(state) {
      state.depositChangeModalIsOpen = !state.depositChangeModalIsOpen
    },

    toggleInteractiveTextModal(state) {
      state.interactiveTextModalIsOpen = !state.interactiveTextModalIsOpen
    },

    toggleQuickStartModal(state) {
      state.quickStartCoursesModalIsOpen = !state.quickStartCoursesModalIsOpen
    },

    toggleTutorialFeedbackModal(state) {
      state.tutorialFeedbackModalIsOpen = !state.tutorialFeedbackModalIsOpen
    },

    toggleSubscribeModal(state) {
      state.subscribeModalIsOpen = !state.subscribeModalIsOpen
    },

    toggleDemonstrationModal(state) {
      state.demonstrationModalIsOpen = !state.demonstrationModalIsOpen
    },

    toggleFinishedTutorialModal(state) {
      state.finishedTutorialModalIsOpen = !state.finishedTutorialModalIsOpen
    },

    toggleListOfStrategiesModal(state) {
      state.listOfStrategiesModalIsOpen = !state.listOfStrategiesModalIsOpen
    },

    toggleStrategyOptimizerModal(state) {
      state.strategyOptimizerModalIsOpen = !state.strategyOptimizerModalIsOpen
    },

    toggleBasicCoursesModal(state) {
      state.basicCoursesModalIsOpen = !state.basicCoursesModalIsOpen
    },

    toggleDefaultOrdersModal(state) {
      state.defaultOrdersModalIsOpen = !state.defaultOrdersModalIsOpen
    },

    toggleHotKeysModal(state) {
      state.hotKeysModalIsOpen = !state.hotKeysModalIsOpen
    },

    toggleSoundsModal(state) {
      state.soundsModalIsOpen = !state.soundsModalIsOpen
    },

    toggleNotEnoughFreeMarginModal(state) {
      state.notEnoughFreeMarginModalIsOpened = !state.notEnoughFreeMarginModalIsOpened
    },

    toggleQuitLessonModal(state) {
      state.quitTutorialModalIsOpened = !state.quitTutorialModalIsOpened
    },

    hideDropdown(state) {
      state.signalToCloseDropdown = !state.signalToCloseDropdown
    },

    hideAllModals(state) {
      return initialModalsState
    }
  }
})

export default modalsSlice.reducer
