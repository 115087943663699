export const ApplyToPrice: string[] = [
  'Close',
  'Open',
  'High',
  'Low',
  '(High + Low) / 2',
  '(High + Low + Close) / 3',
  '(High + Low + Close + Close) / 4'
]

export const MAType: string[] = ['Simple (SMA)', 'Exponential (EMA)', 'Smoothed Simple (SSMA)', 'Weighted (WMA)']

export const Styles: {}[] = ['Blue', 'Solid', 1]
