import { Color } from '../../components/topMenu/tabs/indicatorsTab/utils/indicatorsConstants'
import { IndicatorComponentType, IndicatorPlacement, LineStyle } from '../enums'

export interface IIndicatorParams {
  Symbol: string
  Broker: string
  Start: number
  End: number
  TimeFrame: number
  UseMessagePack: boolean
  indicatorsParams: {}
}

export interface IIndicatorValue {
  // !TODO remove when backend unify time variable name
  t?: number // time
  v?: number
  s?: number
  m?: number
  l?: number
  u?: number
}

export interface IIndicatorSettings {
  placement: IndicatorPlacement
  Color: Color
  ApplyToPrice?: string
  MAType?: string
  Period?: number
  PeriodF?: number
  PeriodS?: number
  JawPeriod?: number
  JawShift?: number
  TeethPeriod?: number
  TeethShift?: number
  LipsPeriod?: number
  LipsShift?: number
  'Tenkan-sen period'?: number
  'Kijun-sen period'?: number
  'Senkou span period'?: number
  'Fast EMA Period'?: number
  'Slow EMA Period'?: number
  'SMA Period'?: number
  '%K period'?: number
  '%D period'?: number
  Slowing?: number
  Deviation?: number
  Method?: number
  PriceType?: string
  Shift?: number
  HShift?: number
  VShift?: number
  'Pivot Type'?: string
  Continuous?: boolean
  'Show Midpoints'?: boolean
}

export enum IIndicatorCategory {
  Trend = 'Trend',
  Oscillators = 'Oscillators',
  Volume = 'Volume',
  Volatility = 'Volatility',
  Adaptive = 'Adaptive',
  Custom = 'Custom'
}

export interface IIndicator {
  id: string
  name: string
  fullName: string
  stroke: string
  strokeDasharray: LineStyle
  strokeWidth: number
  strokeOpacity: number
  category: IIndicatorCategory
  settings: IIndicatorSettings
  lineType: IndicatorComponentType
}
export const isIIndicator = (val: any): val is IIndicator =>
  typeof val == 'object' && val.settings != undefined && val.id?.length > 0

export interface IOscillator extends IIndicator {}
