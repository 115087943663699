import { WebTesterLocale } from '../../utils/enums'
import { urls } from '../../utils/locators/urls'

export default class UrlsManagementService {
  static getPrivacyPolicyUrl(language: WebTesterLocale): string {
    switch (language) {
      case WebTesterLocale.English:
        return urls.ftPrivacyEN
      case WebTesterLocale.Japanese:
        return urls.ftPrivacyJP
      default:
        console.warn(`There is no a Privacy url for the language ${language}`)
        return urls.ftPrivacyJP
    }
  }

  static getUrToFtBaseDomain(language: WebTesterLocale): string {
    switch (language) {
      case WebTesterLocale.English:
        return urls.ftHomePageHttps
      case WebTesterLocale.Japanese:
        return urls.ftHomePageHttpsJP
      default:
        console.warn(`There is no the FT site url for the language ${language}`)
        return urls.ftPrivacyJP
    }
  }

  static getFtUrlOrigin(language: WebTesterLocale): string {
    switch (language) {
      case WebTesterLocale.English:
        return urls.ftHomePage
      case WebTesterLocale.Japanese:
        return urls.ftHomePageJP
      default:
        console.warn(`There is no url for the language ${language}`)
        return urls.ftHomePage
    }
  }
}
