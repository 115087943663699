import { isMobile } from 'react-device-detect'
import { isNotDefined } from '../../../libs/react-stockcharts/lib/utils'
import { IJoyrideState } from '../../../redux/joyride/types'
import English from '../../../translations/English.json'
import Japanese from '../../../translations/Japanese.json'
import Russian from '../../../translations/Russian.json'
import Spanish from '../../../translations/Spanish.json'
import {
  ButtonWidth,
  DropdownType,
  FtControlName,
  FtControlType,
  InstantSetupButtonTypes,
  MarketOrderTypes,
  MenuItemId,
  PendingOrderTypes,
  RibbonGroupId,
  RibbonTabId,
  TimeFrame,
  TutorialElementId,
  TutorialTabId,
  WebTesterLocale
} from '../../../utils/enums'
import { EnumDictionary } from '../../../utils/interfaces'
import { ID } from '../../../utils/locators/ids'
import {
  ChartsTabElementsID,
  EducationID,
  GraphicPanelID,
  HelpID,
  HomeID,
  MarketOrderModalLocators,
  OrdersID,
  PendingOrderModalLocators,
  SettingsID,
  StoreID,
  StrategiesID,
  TimeframeId,
  WindowsID
} from '../../../utils/locators/locators'
import { getNoHashtagTargetId } from '../../webTester/webTesterUtils'

export const handleDropDownValue = (openPrice: number, line: number) => {
  if (line === 0) {
    return 0
  }
  const digitsCount = openPrice.toString().split('.')[1].length
  return Math.abs(+((openPrice - line) * Math.pow(10, digitsCount)).toFixed())
}
// todo: get digitsCount from WT when charts will have such a field
export const handleMarketOrderInstantSetupButton = (
  buttonType: string,
  orderType: string,
  dropdownValue: number,
  close: number,
  digitsCount: number
): number | undefined => {
  let slButton: number = 0
  let tpButton: number = 0
  let dropdownAsPrice: number = 0

  dropdownAsPrice = dropdownValue / Math.pow(10, digitsCount)

  switch (orderType) {
    case MarketOrderTypes.Sell:
      switch (buttonType) {
        case InstantSetupButtonTypes.stopLoss:
          slButton = Number((close + dropdownAsPrice).toFixed(digitsCount))

          return slButton
        case InstantSetupButtonTypes.takeProfit:
          tpButton = Number((close - dropdownAsPrice).toFixed(digitsCount))

          return tpButton
        default:
          return 0
      }

    case MarketOrderTypes.Buy:
      switch (buttonType) {
        case InstantSetupButtonTypes.stopLoss:
          slButton = Number((close - dropdownAsPrice).toFixed(digitsCount))

          return slButton
        case InstantSetupButtonTypes.takeProfit:
          tpButton = Number((close + dropdownAsPrice).toFixed(digitsCount))

          return tpButton
        default:
          return 0
      }

    default:
      break
  }
}

export const handlePendingOrderInstantSetupButton = (
  orderType: string,
  close: number,
  stopLossDropdown: number,
  takeProfitDropdown: number,
  execPriceDropdown: number,
  digitsCount: number
): number[] => {
  let execPriceButton: number = 0
  let slButton: number = 0
  let tpButton: number = 0

  const divider: number = Math.pow(10, digitsCount)

  let execDropdownAsPrice = execPriceDropdown / divider // 40 / (10^4) = 0.0040
  let slDropdownAsPrice = stopLossDropdown / divider // 40 / (10^4) = 0.0040
  let tpDropdownAsPrice = takeProfitDropdown / divider // 40 / (10^4) = 0.0040

  switch (orderType) {
    case PendingOrderTypes.BuyLimit:
      execPriceButton = Number((close - execDropdownAsPrice).toFixed(digitsCount))
      slButton = Number((execPriceButton - slDropdownAsPrice).toFixed(digitsCount))
      tpButton = Number((execPriceButton + tpDropdownAsPrice).toFixed(digitsCount))

      return [slButton, tpButton, execPriceButton]
    case PendingOrderTypes.BuyStop:
      execPriceButton = Number((close + execDropdownAsPrice).toFixed(digitsCount))
      slButton = Number((execPriceButton - slDropdownAsPrice).toFixed(digitsCount))
      tpButton = Number((execPriceButton + tpDropdownAsPrice).toFixed(digitsCount))

      return [slButton, tpButton, execPriceButton]
    case PendingOrderTypes.SellLimit:
      execPriceButton = Number((close + execDropdownAsPrice).toFixed(digitsCount))
      slButton = Number((execPriceButton + slDropdownAsPrice).toFixed(digitsCount))
      tpButton = Number((execPriceButton - tpDropdownAsPrice).toFixed(digitsCount))

      return [slButton, tpButton, execPriceButton]
    case PendingOrderTypes.SellStop:
      execPriceButton = Number((close - execDropdownAsPrice).toFixed(digitsCount))
      slButton = Number((execPriceButton + slDropdownAsPrice).toFixed(digitsCount))
      tpButton = Number((execPriceButton - tpDropdownAsPrice).toFixed(digitsCount))

      return [slButton, tpButton, execPriceButton]
    default:
      return [0, 0, 0]
  }
}

export const getMovingAverageType = (MAType: number): string => {
  switch (MAType) {
    case 0:
      return 'sma'
    case 1:
      return 'ema'
    case 2:
      return 'smma'
    case 3:
      return 'lwma'
    default:
      return 'sma'
  }
}

export const getMovingAverageMethodIndex = (MAType: string): number => {
  switch (MAType) {
    case 'sma':
      return 0
    case 'ema':
      return 1
    case 'smma':
      return 2
    case 'lwma':
      return 3
    default:
      return 0
  }
}

export const isMovingAverage = (indicatorName: string): boolean => {
  switch (indicatorName) {
    case 'sma':
      return true
    case 'ema':
      return true
    case 'smma':
      return true
    case 'lwma':
      return true
    default:
      return false
  }
}

export const getTimeFrameTooltip = (timeFrame: TimeFrame, language: WebTesterLocale): string => {
  const dict = getDict(language)

  switch (timeFrame) {
    case TimeFrame.M1:
      return `1 ${dict[TimeframeId.minute]}`
    case TimeFrame.M5:
      return `5 ${dict[TimeframeId.minutes]}`
    case TimeFrame.M15:
      return `15 ${dict[TimeframeId.minutes]}`
    case TimeFrame.M30:
      return `30 ${dict[TimeframeId.minutes]}`
    case TimeFrame.H1:
      return `1 ${dict[TimeframeId.hour]}`
    case TimeFrame.H4:
      return `1 ${dict[TimeframeId.hours]}`
    case TimeFrame.D1:
      return `1 ${dict[TimeframeId.day]}`
    case TimeFrame.W1:
      return `1 ${dict[TimeframeId.week]}`
    case TimeFrame.MN:
      return `1 ${dict[TimeframeId.month]}`
    default:
      throw new Error('Incorrect timeframe to get tooltip text')
  }
}

function getDict(language: WebTesterLocale) {
  switch (language) {
    case WebTesterLocale.English:
      return English
    case WebTesterLocale.Japanese:
      return Japanese
    case WebTesterLocale.Spanish:
      return Spanish
    case WebTesterLocale.Russian:
      return Russian
    default:
      throw new Error('Incorrect language value to get dictionary')
  }
}

export const convertLanguageNameToIndex = (language: string): number => {
  switch (language) {
    case 'en':
      return 0
    case 'ru':
      return 1
    case 'es':
      return 2
    case 'ja':
      return 3
    default:
      return 0
  }
}

export const isLessonDisabled = (currentTutorialIndex: number, disabled: number[]): boolean => {
  return disabled.includes(currentTutorialIndex)
}

export const convertControlId = (id: TutorialElementId): string => {
  switch (id) {
    case TutorialElementId.Unknown:
      return 'body'
    // case TutorialElementId.Box:
    // case TutorialElementId.TabCaption:
    case TutorialElementId.StartPauseTestControl:
      return HomeID.StartTestID
    case TutorialElementId.StartTest:
      return HomeID.StartTestID
    case TutorialElementId.PauseTest:
      return HomeID.PauseTestID
    case TutorialElementId.RestartProject:
      return HomeID.RestartTestID
    case TutorialElementId.ResumeTest:
      return HomeID.PauseTestID
    case TutorialElementId.NewProject:
      return HomeID.ResetProjectID
    case TutorialElementId.SaveProject:
      return HomeID.SaveProjectID
    case TutorialElementId.OpenProject:
      return HomeID.OpenProjectID
    case TutorialElementId.BoxNewProject:
      return 'Project Options Dropdown Menu'
    case TutorialElementId.TimeFrameButtonM1:
      return 'M1ID'
    case TutorialElementId.TimeFrameButtonM5:
      return 'M5ID'
    case TutorialElementId.TimeFrameButtonM15:
      return 'M15ID'
    case TutorialElementId.TimeFrameButtonM30:
      return 'M30ID'
    case TutorialElementId.TimeFrameButtonH1:
      return 'H1ID'
    case TutorialElementId.TimeFrameButtonH4:
      if (isMobile) {
        return ID.MobileH4
      }

      return 'H4ID'
    case TutorialElementId.TimeFrameButtonD1:
      return 'D1ID'
    case TutorialElementId.TimeFrameButtonW1:
      return 'W1ID'
    // case TutorialElementId.TimeFrameButtonMn:
    // case TutorialElementId.TimeFrameButtonManage:
    // case TutorialElementId.BoxTimeFrames:
    // case TutorialElementId.ToggleTimeFramesGroup:
    case TutorialElementId.BarBack:
      return HomeID.BarBackID
    case TutorialElementId.BarForward:
      return HomeID.BarForwardID
    case TutorialElementId.JumpTo:
      return HomeID.JumpToID
    case TutorialElementId.Tick:
      return HomeID.StepByOneTickID
    case TutorialElementId.ActionsOrdersBox:
      return 'Actions / Orders Dropdown Men'
    case TutorialElementId.SpeedOfTestingSlider:
      return HomeID.TestingSpeedSliderID
    // case TutorialElementId.ManageSpeed:
    // case TutorialElementId.BoxSpeedOfTesting: return 'Actions / Orders Dropdown Men'
    case TutorialElementId.MarketOrder:
      return HomeID.MarketOrderID
    case TutorialElementId.PendingOrder:
      return HomeID.PendingOrderID
    case TutorialElementId.BoxGraphicElements:
      return HomeID.GraphicElementsID
    case TutorialElementId.ListOfIndicatorsButton:
      return HomeID.ListOfIndicatorsID
    case TutorialElementId.BoxNews:
      return HomeID.NewsID
    // case TutorialElementId.ZoomIn:
    // case TutorialElementId.ZoomOut:
    // case TutorialElementId.MarketWatchAddSymbol:
    case TutorialElementId.ProfitChart:
      return ChartsTabElementsID.ProfitChartID
    case TutorialElementId.AddNewChart:
      return ChartsTabElementsID.NewChartID
    case TutorialElementId.AddNewRangeBarChart:
      return ChartsTabElementsID.RangeBarID
    case TutorialElementId.AddNewRenkoBarChart:
      return ChartsTabElementsID.RenkoBarID
    // case TutorialElementId.ChartModeBox:
    case TutorialElementId.ChartViewAutoScroll:
      return ChartsTabElementsID.AutoScrollID
    case TutorialElementId.ChartViewOffset:
      return ChartsTabElementsID.ChartOffsetID
    case TutorialElementId.ChartViewMagnetMode:
      return ChartsTabElementsID.MagnetModeID
    case TutorialElementId.ChartViewSyncCharts:
      return ChartsTabElementsID.SyncChartsID
    case TutorialElementId.ChartViewShowVolume:
      return ChartsTabElementsID.ShowVolumesID
    case TutorialElementId.ChartViewShowGrid:
      return ChartsTabElementsID.ShowGridID
    case TutorialElementId.ChartViewShowPeriodSeparators:
      return ChartsTabElementsID.ShowPeriodSeparatorsID
    case TutorialElementId.ChartViewShowAccountHistory:
      return ChartsTabElementsID.ShowAccountHistoryID
    case TutorialElementId.ChartViewShowNotes:
      return ChartsTabElementsID.ShowNotesID
    case TutorialElementId.ChartViewShowNews:
      return ChartsTabElementsID.ShowNewsID
    // case TutorialElementId.CrosshairPointer:
    // case TutorialElementId.CrosshairSync:
    // case TutorialElementId.BoxChartTemplate:
    // case TutorialElementId.BoxDesktop:
    case TutorialElementId.ChartWindowsCascade:
      return ChartsTabElementsID.CascadeID
    case TutorialElementId.ChartWindowsMinimizeAll:
      return ChartsTabElementsID.MinimizeAllID
    case TutorialElementId.ChartWindowsTileVertical:
      return WindowsID.TileVerticallyID
    case TutorialElementId.ChartWindowsTileHorizontal:
      return WindowsID.TileHorizontallyID
    // case TutorialElementId.ChartCloseActiveWindow:
    // case TutorialElementId.ChartMinimizeActiveWindow:
    case TutorialElementId.ChartScreenshot:
      return ChartsTabElementsID.ScreenShotID
    case TutorialElementId.ChartSettings:
      return ChartsTabElementsID.ChartSettingsID
    // case TutorialElementId.DataCenter:
    // case TutorialElementId.ProjectDataSettings:
    // case TutorialElementId.UpdateNews:
    case TutorialElementId.ListOfStrategies:
      return StrategiesID.ListOfStrategiesID
    case TutorialElementId.AddStrategy:
      return StrategiesID.AddStrategyID
    case TutorialElementId.QuickTest:
      return StrategiesID.QuickTestID
    case TutorialElementId.SwitchExecution:
      return StrategiesID.StrategyExecutionID
    case TutorialElementId.WindowsRestoreAll:
      return WindowsID.RestoreAllID
    case TutorialElementId.WindowsSymbols:
      return WindowsID.SymbolsID
    case TutorialElementId.WindowsData:
      return WindowsID.DataWindowID
    case TutorialElementId.WindowsTickChart:
      return WindowsID.TickChartID
    case TutorialElementId.WindowsStatistics:
      return WindowsID.StatisticsID
    case TutorialElementId.WindowsOpenPosition:
      return WindowsID.OpenPositionsID
    case TutorialElementId.WindowsPendingOrder:
      return WindowsID.PendingOrdersID
    case TutorialElementId.WindowsAccountHistory:
      return WindowsID.AccountHistoryID
    case TutorialElementId.WindowsScripts:
      return WindowsID.ScriptsID
    case TutorialElementId.WindowsGraphicPanel:
      return WindowsID.GraphicPanelID
    case TutorialElementId.WindowsNotes:
      return WindowsID.NotesID
    case TutorialElementId.WindowsNews:
      return WindowsID.NewsID
    case TutorialElementId.WindowsJournal:
      return WindowsID.JournalID
    // case TutorialElementId.SettingsCheckForUpdates: return SettingsID.Ch
    case TutorialElementId.SettingsDefaultOrders:
      return SettingsID.DefaultOrdersID
    case TutorialElementId.SettingsHotKeys:
      return SettingsID.HotKeysID
    case TutorialElementId.SettingsSound:
      return SettingsID.SoundsID
    case TutorialElementId.BoxLanguage:
      return SettingsID.LanguageID
    case TutorialElementId.StoreBuyLicence:
      return StoreID.BuyLicenseID
    case TutorialElementId.StoreRegisterProgram:
      return StoreID.RegisterProgramID
    case TutorialElementId.StoreStandardSubscription:
      return StoreID.StandardSubscriptionID
    case TutorialElementId.StoreVipSubscription:
      return StoreID.VipSubscriptionID
    case TutorialElementId.StoreActivateDateSubscription:
      return StoreID.ActivateDataSubscriptionID
    case TutorialElementId.StoreForexTradeCopier:
      return StoreID.ForexTradeCopierID
    case TutorialElementId.StoreForexCopierRemote:
      return StoreID.ForexRemoteCopierID
    case TutorialElementId.StoreVisualStrategiesBuilder:
      return StoreID.EasyForexBuilderID
    case TutorialElementId.StoreVisualIndicatorBuilder:
      return StoreID.VisualIndicatorBuilderID
    case TutorialElementId.StoreIndicatorsList:
      return StoreID.IndicatorsListID
    case TutorialElementId.HelpTutorialsVideo:
      return HelpID.VideoTutorialsID
    case TutorialElementId.HelpFaq:
      return HelpID.FaqID
    case TutorialElementId.HelpUserGuidePdf:
      return HelpID.UserGuidePdfID
    case TutorialElementId.HelpPartnershipPrograms:
      return HelpID.PartnershipProgramID
    case TutorialElementId.HelpForexTesterHelp:
      return HelpID.ForexTesterHelpID
    case TutorialElementId.HelpApi:
      return HelpID.ApiHelpID
    case TutorialElementId.HelpContactsUs:
      return HelpID.ContactUsID
    case TutorialElementId.HelpForum:
      return HelpID.ForumID
    case TutorialElementId.HelpAboutProgram:
      return HelpID.AboutProgramID
    // case TutorialElementId.BoxUtilities:

    // case TutorialElementId.DisableRibbon:
    // case TutorialElementId.StoreStrategyList:
    // case TutorialElementId.Unregistered:
    case TutorialElementId.StrategyOptimizer:
      return StrategiesID.StrategyOptimizerID

    case TutorialElementId.QuickStartGuideButton:
      return EducationID.QuickStartGuideID
    // case TutorialElementId.BasicTutorialsButton: return  EducationID.
    // case TutorialElementId.IntermediateTutorialsButton: return  EducationID.QuickStartGuideID
    // case TutorialElementId.AdvancedTutorialsButton: return  EducationID.QuickStartGuideID
    // case TutorialElementId.EducationButton:

    // case TutorialElementId.ChooseStrategyLabel:
    // case TutorialElementId.OptimizingAlgorithmLabel:
    // case TutorialElementId.SymbolAndDataLabel:
    // case TutorialElementId.ParametersSettingsLabel:
    // case TutorialElementId.CommonSettingsLabel:
    // case TutorialElementId.BuyPremium:
    // case TutorialElementId.ApplyBtn:
    // case TutorialElementId.NextBtn:
    // case TutorialElementId.PreviousBtn:
    // case TutorialElementId.StartBtn:
    // case TutorialElementId.CancelBtn:
    // case TutorialElementId.RetryBtn:
    // case TutorialElementId.SaveBtn:
    // case TutorialElementId.SymbolSwitch:
    // case TutorialElementId.BuySubscription:
    // case TutorialElementId.OptimizingAlgorithmFullGrid:
    // case TutorialElementId.OptimizingAlgorithmNeuralNetwork:
    // case TutorialElementId.OptimizingAlgorithmXGBoost:
    // case TutorialElementId.CheckboxSmallerBalance:
    // case TutorialElementId.CheckboxBiggerBalance:
    // case TutorialElementId.InitialDeposit:
    // case TutorialElementId.StopCriteriaSmallerBalance:
    // case TutorialElementId.StopCriteriaBiggerBalance:
    // case TutorialElementId.EditSpinBoxFrom:
    // case TutorialElementId.EditSpinBoxTo:
    // case TutorialElementId.EditSpinBoxStep:
    // case TutorialElementId.Checkbox:
    // case TutorialElementId.ComboBoxWithCheckboxes:
    // case TutorialElementId.DataAdvancedSettings:
    // case TutorialElementId.TickRadioGroup:
    // case TutorialElementId.UsingFloatingSpreadCheckbox:
    // case TutorialElementId.GenerateTicksBasedOnOhlcRadioButton:
    // case TutorialElementId.GenerateTicksRandomlyRadioButton:
    // case TutorialElementId.OptimizerStartTesting:
    // case TutorialElementId.OptimizerPauseTesting:
    // case TutorialElementId.TestingGraphic:
    // case TutorialElementId.TestingPoint:
    // case TutorialElementId.EditOptimizationSettings:
    // case TutorialElementId.ReconfigureOptimizationSettings:
    // case TutorialElementId.EditSymbolAndDataSummary:
    // case TutorialElementId.EditParametersSummary:
    // case TutorialElementId.EditCommonSummary:
    // case TutorialElementId.ChangeStrategy:
    // case TutorialElementId.ResumeBtn:
    // case TutorialElementId.ChangeAlgorithm:
    // case TutorialElementId.NextBtnJumpToSymbolAndData:
    // case TutorialElementId.RadioBtnUse1MinuteData:
    // case TutorialElementId.RadioBtnUse1MinuteDataAndTickData:
    // case TutorialElementId.ReportBtn:
    // case TutorialElementId.PrevMonthButton:
    // case TutorialElementId.NextMonthButton:
    // case TutorialElementId.PrevYearButton:
    // case TutorialElementId.NextYearButton:
    // case TutorialElementId.CalendarDayButton:

    // case TutorialElementId.CloseTutorialWindow:
    // case TutorialElementId.EnableStrategySwitch:
    // case TutorialElementId.EditStrategyInVsb:
    // case TutorialElementId.LoadFreeStrategy:
    // case TutorialElementId.BuyStrategy:
    // case TutorialElementId.StrategyListAi:
    // case TutorialElementId.StrategyListDelete:
    // case TutorialElementId.StrategyListSettings:
    // case TutorialElementId.StrategyListSwitchExecution:
    // case TutorialElementId.EnableAllStrategies:
    // case TutorialElementId.AddStrategyFromFile:
    // case TutorialElementId.CloseStrategyList:
    // case TutorialElementId.StrategyListHelp:

    // case TutorialElementId.TutorialWindowFeedbackButton:

    // case TutorialElementId.DisableAllStrategies:

    case TutorialElementId.ModifyOrder:
      return OrdersID.ModifyOrderID
    case TutorialElementId.ClosePosition:
      return OrdersID.CloseOrderID
    // case TutorialElementId.DisableAllCloseOrderStrategies: return OrdersID.
    case TutorialElementId.CloseAllPositionsAndOrder:
      return OrdersID.CloseAllPositionsID
    case TutorialElementId.OpenGroupOfOrders:
      return OrdersID.OpenGroupOfOrdersID
    case TutorialElementId.DuplicatePosition:
      return OrdersID.DuplicatePositionID
    case TutorialElementId.MoveStopLossToBreakeven:
      return OrdersID.MoveStopLossToBreakevenID
    // case TutorialElementId.ChangeBalance: return OrdersID.DepositMoneyID
    // case TutorialElementId.ForexSpeedUp:
    // case TutorialElementId.CourseTitle:
    // case TutorialElementId.Close:
    // case TutorialElementId.SwcSearchStrategy:
    // case TutorialElementId.ExecutionSwitch:
    // case TutorialElementId.StrategyName:
    // case TutorialElementId.StrategyDescription:
    // case TutorialElementId.StrategyEdit:
    // case TutorialElementId.StrategyList:
    // case TutorialElementId.GlobalStrategyExecutionSwitch:
    // case TutorialElementId.Burger:

    case TutorialElementId.Note:
      return GraphicPanelID.NoteID
    case TutorialElementId.VerticalLine:
      return GraphicPanelID.VerticalLineID
    case TutorialElementId.HorizontalLine:
      return GraphicPanelID.HorizontalLineID
    case TutorialElementId.TrendLine:
      return GraphicPanelID.TrendLineID
    case TutorialElementId.Roy:
      return GraphicPanelID.RayID
    case TutorialElementId.Polyline:
      return GraphicPanelID.PolylineID
    case TutorialElementId.ListOfObjects:
      return GraphicPanelID.ListOfObjectsID
    case TutorialElementId.SelectAllObjects:
      return GraphicPanelID.SelectAllObjectsID
    case TutorialElementId.DeselectObjects:
      return GraphicPanelID.DeselectAllObjectsID
    case TutorialElementId.RectangleShape:
      return GraphicPanelID.RectangleID
    case TutorialElementId.EllipseShape:
      return GraphicPanelID.EllipseID
    case TutorialElementId.TriangleShape:
      return GraphicPanelID.TriangleID
    case TutorialElementId.AndrewsPitchfork:
      return GraphicPanelID.AndrewsPitchforkID
    case TutorialElementId.TextLabels:
      return GraphicPanelID.TextLabelID
    case TutorialElementId.WaveSymbols:
      return GraphicPanelID.WaveSymbolsID
    case TutorialElementId.DeleteMostRecentObjects:
      return GraphicPanelID.DeleteMostRecentObjectID
    case TutorialElementId.DeleteSelected:
      return GraphicPanelID.DeleteSelectedObjectID
    case TutorialElementId.ThumbUp:
      return GraphicPanelID.ThumbUpID
    case TutorialElementId.ThumbWn:
      return GraphicPanelID.ThumbDownID
    case TutorialElementId.ArrowUp:
      return GraphicPanelID.ArrowUpID
    case TutorialElementId.ArrowWn:
      return GraphicPanelID.ArrowDownID
    case TutorialElementId.StopIcon:
      return GraphicPanelID.StopIconID
    case TutorialElementId.CheckIcon:
      return GraphicPanelID.CheckIconID
    case TutorialElementId.RightPriceLabel:
      return GraphicPanelID.RightPriceLabelID
    case TutorialElementId.LeftPriceLabel:
      return GraphicPanelID.LeftPriceLabelID
    case TutorialElementId.PriceLabel:
      return GraphicPanelID.PriceLabelID
    case TutorialElementId.FiboChannel:
      return GraphicPanelID.FibonacciChannelID
    case TutorialElementId.FibonacciRetracement:
      return GraphicPanelID.FibonacciRetracementID
    case TutorialElementId.FibonacciTimeZone:
      return GraphicPanelID.FibonacciTimeZonesID
    case TutorialElementId.FibonacciFan:
      return GraphicPanelID.FibonacciFanID
    case TutorialElementId.FibonacciArc:
      return GraphicPanelID.FibonacciArcID
    case TutorialElementId.FibonacciExtension:
      return GraphicPanelID.FibonacciExtensionID
    case TutorialElementId.LinearRegressionChannel:
      return GraphicPanelID.LinearRegressionChannelID
    case TutorialElementId.DeleteAll:
      return GraphicPanelID.DeleteAllObjectsID

    // case TutorialElementId.RotateWindowToVertical:
    // case TutorialElementId.RotateWindowToHorizontal:
    // case TutorialElementId.TutorialWindowStepCounterLabel:
    // case TutorialElementId.TutorialWindowStepCounterPlaceholder:
    // case TutorialElementId.FirstNonExistentControlId:
    // case TutorialElementId.JumpToSearch:
    // case TutorialElementId.JumpToGoTo:
    // case TutorialElementId.ApplyAllCharts:
    // case TutorialElementId.Search:
    // case TutorialElementId.CopyPaintTool:
    // case TutorialElementId.UpdateHistoryInProject:
    // case TutorialElementId.RiskRewardBuy:
    // case TutorialElementId.RiskRewardSell:
    // case TutorialElementId.Forward:
    // case TutorialElementId.ShowQuickGuideLessonList:
    // case TutorialElementId.SkipQuickGuideNow:
    // case TutorialElementId.NtShowStartupWindow:
    // case TutorialElementId.StartQuickGuideLesson:
    // case TutorialElementId.Back:
    // case TutorialElementId.SettingsCheckForRibbonUpdates:
    // case TutorialElementId.Restore:
    // case TutorialElementId.Maximize:

    default:
      throw `ControlId #${id} is not converted! Unable to find ${TutorialElementId[id]} button`
  }
}

export const convertFtControlName = (id: string, type: FtControlType): string => {
  if (id.includes('ChartWindow')) {
    return id
  }

  switch (id) {
    case FtControlName.AccountHistoryGrid:
      return ID.BottomMenuContent
    case FtControlName.AccountHistoryFooter:
      return ID.AccountHistoryTableFooter
    case FtControlName.StatisticsGrid:
      return ID.LeftMenuWrapper
    case FtControlName.MarketOrderCommonTab:
      if (type === FtControlType.WindowTabCaptions) return MarketOrderModalLocators.CommonTab
      else return MarketOrderModalLocators.Section1
    case FtControlName.MarketOrderSymbol:
      return MarketOrderModalLocators.SymbolSelect
    case FtControlName.MarketOrderLot:
    case FtControlName.MarketOrderTabLot:
      return MarketOrderModalLocators.LotSelect
    case FtControlName.MarketOrderBuyMode:
      return MarketOrderModalLocators.BuyRadio
    case FtControlName.MarketOrderMkPanel:
      return MarketOrderModalLocators.BuySellRadios
    case FtControlName.MarketOrderStopLossLevel:
      return MarketOrderModalLocators.StopLossDropdown
    case FtControlName.MarketOrderStopLossBtn:
      return MarketOrderModalLocators.StopLossPipette
    case FtControlName.MarketOrderTakeProfitLevel:
      return MarketOrderModalLocators.TakeProfitDropdown
    case FtControlName.MarketOrderSetTakeProfitBtn:
      return MarketOrderModalLocators.TakeProfitButton
    case FtControlName.OpenPositionsGrid:
      return ID.OpenPositionsTable
    case FtControlName.MarketOrderBuyBtn:
      return MarketOrderModalLocators.BuyButton
    case FtControlName.MarketOrderSellBtn:
      return MarketOrderModalLocators.SellButton
    case FtControlName.SymbolsPanel:
      return 'LeftMenuWrapper'
    case FtControlName.PendingOrderCommonTab:
      return PendingOrderModalLocators.CommonTab
    case FtControlName.PendingOrderType:
      return PendingOrderModalLocators.OrderType
    case FtControlName.PendingOrderPickPriceBtn:
      return PendingOrderModalLocators.AtPricePipette
    case FtControlName.PendingOrderPrice:
      return PendingOrderModalLocators.PlaceAtBtn
    case FtControlName.PendingOrderPlaceBtn:
      return PendingOrderModalLocators.PlaceAtBtn
    case FtControlName.PendingOrderFrame:
      return ID.PendingOrdersTable
    case FtControlName.PendingOrderGrid:
      return ID.PendingOrdersTable
    // return `${Tabs.PendingOrdersTab.replace(' ', '')}Tab`
    case FtControlName.MarketOrderFrm:
      return ID.NotEnoughFreeMargin
    default:
      console.warn(`FtControl ${id} is not converted`)
      return id
  }
}

export const convertGroupId = (groupId: RibbonGroupId): string => {
  switch (groupId) {
    case RibbonGroupId.HOME_TIMEFRAME:
      return HomeID.TimeFrameGroupID
    case RibbonGroupId.HOME_GRAPHICS:
      return HomeID.GraphicElementsGroupID
    case RibbonGroupId.HOME_INDICATORS:
      return HomeID.ListOfIndicatorsGroupID
    case RibbonGroupId.EDUCATION_COURESES: {
      if (isMobile) {
        return ID.MobileCoursesGroup
      }

      return EducationID.CoursesGroupID
    }
    default:
      throw `GroupId ${groupId} is not converted!`
  }
}

export const convertMenuItemId = (menuItemId: MenuItemId): string => {
  switch (menuItemId) {
    case MenuItemId.TREND_INDICATORS_GROUP:
      return ID.TrendMenuItem
    default:
      return ''
      throw `MenuItemId ${menuItemId} is not converted`
  }
}

export const convertEducationCourseTabId = (id: TutorialTabId): number => {
  switch (id) {
    case TutorialTabId.HOME:
      return RibbonTabId.HOME
    case TutorialTabId.CHARTS:
      return RibbonTabId.CHARTS
    case TutorialTabId.STRATEGIES:
      return RibbonTabId.STRATEGIES
    case TutorialTabId.WINDOWS:
      return RibbonTabId.WINDOWS
    case TutorialTabId.SETTINGS:
      return RibbonTabId.SETTINGS
    case TutorialTabId.EDUCATION:
      return RibbonTabId.EDUCATION
    case TutorialTabId.STORE:
      return RibbonTabId.STORE
    case TutorialTabId.HELP:
      return RibbonTabId.HELP
    case TutorialTabId.ORDER:
      return RibbonTabId.ORDER
    default:
      return RibbonTabId.UNKNOWN
  }
}

export function getStartButtonWidth(language: WebTesterLocale): ButtonWidth {
  switch (language) {
    case WebTesterLocale.English:
      return ButtonWidth.MediumExtended
    case WebTesterLocale.Japanese:
      return ButtonWidth.Large70
    case WebTesterLocale.Spanish:
      return ButtonWidth.Big
    case WebTesterLocale.Russian:
      return ButtonWidth.MediumExtended
    case WebTesterLocale.ChineseSimplified:
      return ButtonWidth.Big
    case WebTesterLocale.ChineseTraditional:
      return ButtonWidth.Big
    default:
      return ButtonWidth.Big
  }
}

export function getPauseButtonWidth(language: WebTesterLocale): ButtonWidth {
  switch (language) {
    case WebTesterLocale.English:
      return ButtonWidth.MediumExtended
    case WebTesterLocale.Japanese:
      return ButtonWidth.Large70
    case WebTesterLocale.Spanish:
      return ButtonWidth.MediumExtended
    case WebTesterLocale.Russian:
      return ButtonWidth.MediumExtended
    case WebTesterLocale.ChineseSimplified:
      return ButtonWidth.MediumExtended
    case WebTesterLocale.ChineseTraditional:
      return ButtonWidth.MediumExtended
    default:
      return ButtonWidth.MediumExtended
  }
}

export function isTargetVisible(
  container: HTMLElement,
  joyride: IJoyrideState
): { isInViewport: boolean; pixelsToScroll: number } {
  const { step, guideSteps } = joyride
  if (isNotDefined(guideSteps[step])) return { isInViewport: false, pixelsToScroll: 0 }

  const target: string = guideSteps[step].target as string
  const isTopMenuId = Object.values(HomeID).includes(getNoHashtagTargetId(guideSteps[step]))

  const box = document.querySelector(target)

  if (isNotDefined(box) || !isTopMenuId) return { isInViewport: false, pixelsToScroll: 0 }

  const rect = box!.getBoundingClientRect()

  const isInViewport =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  let pixelsToScroll: number = 0

  if (isInViewport) {
    return {
      isInViewport,
      pixelsToScroll
    }
  }

  if (rect.right > 0 && rect.right > container.offsetLeft + window.innerWidth) {
    pixelsToScroll = Math.ceil(rect.right - container.offsetLeft - window.innerWidth)
  } else if (rect.left < 0) {
    pixelsToScroll = rect.left
  }

  return {
    isInViewport,
    pixelsToScroll
  }
}

const BigDropdownWidthMap: EnumDictionary<DropdownType, EnumDictionary<WebTesterLocale, string>> = {
  [DropdownType.GRAPHIC_ELEMENTS]: {
    [WebTesterLocale.English]: '80px',
    [WebTesterLocale.Japanese]: '90px'
  } as EnumDictionary<WebTesterLocale, string>,
  [DropdownType.INDICATORS]: {
    [WebTesterLocale.English]: '80px',
    [WebTesterLocale.Japanese]: '90px'
  } as EnumDictionary<WebTesterLocale, string>,
  [DropdownType.NONE]: {} as EnumDictionary<WebTesterLocale, string>,
  [DropdownType.ORDER_ACTIONS]: {} as EnumDictionary<WebTesterLocale, string>,
  [DropdownType.PROJECT]: {} as EnumDictionary<WebTesterLocale, string>,
  [DropdownType.TICK_SIZE]: {} as EnumDictionary<WebTesterLocale, string>
}

export function getDropDownWidth(dropdownType: DropdownType, language: WebTesterLocale): string {
  return BigDropdownWidthMap[dropdownType][language]
}
