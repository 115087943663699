// import {
//   defaultAccountInfo,
//   defaultChartTimeFrames,
//   defaultCurrentBars,
//   defaultEntireData,
//   defaultIndicatorsDataMapByName,
//   defaultIndicatorsMapByChart,
//   defaultLastBarIndexes,
//   defaultNewsRecords,
//   defaultVisibleData
// } from "../../../utils/defaultProject"
import moment from 'moment'
import { UserIdentityService } from '../../../services/UserIdentityService/UserIdentityService'
import { dateRange, dummyProject } from '../../../utils/constants'
import { ProjectName } from '../../../utils/enums'
import { IBar, IHistoricalData, IServerBar } from '../../../utils/interfaces/IHistoricalData.interface'
import {
  defaultAccountInfo,
  defaultChartTimeFrames,
  defaultCurrentBars,
  defaultEntireData,
  defaultIndicatorsDataMapByName,
  defaultIndicatorsMapByChart,
  defaultLastBarIndexes,
  defaultNewsRecords,
  defaultVisibleData
} from '../../../utils/projects/defaultProject'
import { marketingIndicatorsDataMapByName, marketingIndicatorsMapByChart } from '../../../utils/projects/demoProject'
import { getNewVisibleDataWithIndicatorField } from '../webTesterUtils'

export const getMiddleRange = (dateRange: number[]): number => {
  // const dayInSeconds = 60 * 60 * 24
  const dayInSeconds = 60 * 60 * 6
  let middleRange = dateRange[0] + dayInSeconds

  return middleRange > dateRange[1] ? dateRange[1] : middleRange
}

const getNumbersFromString = (str: string): number => {
  return +str.replace(/[^0-9]/g, '')
}

const addDefaultIndicatorsData = (
  data: IBar[],
  currency: string,
  projectName: ProjectName = ProjectName.DEFAULT
): IBar[] => {
  if (projectName === ProjectName.BASIC_COURSES) return data

  const indicatorsMap = {
    defaultIndicatorsMapByChart: defaultIndicatorsMapByChart,
    defaultIndicatorsDataMapByName: defaultIndicatorsDataMapByName,
    marketingIndicatorsMapByChart: marketingIndicatorsMapByChart,
    marketingIndicatorsDataMapByName: marketingIndicatorsDataMapByName
  }

  const indicatorsMapByChart = indicatorsMap[`${projectName}IndicatorsMapByChart`]
  const indicatorsDataMapByName = indicatorsMap[`${projectName}IndicatorsDataMapByName`]

  indicatorsMapByChart[currency].map((indicatorName) => {
    data = getNewVisibleDataWithIndicatorField(
      data,
      indicatorName,
      indicatorsDataMapByName[currency][indicatorName],
      getNumbersFromString(indicatorName)
    )
  })
  return data
}

export const parseResponse = (data: IServerBar[]): IBar[] => {
  let mappedData: IBar[] = []

  data.map((day) => {
    day.b.forEach((bar) => {
      mappedData.push({
        date: moment(day.d * 1000 + bar.t * 1000)
          .utc()
          .toISOString(),
        open: Number(bar.o),
        high: Number(bar.h),
        low: Number(bar.l),
        close: Number(bar.c),
        volume: Number(bar.v)
      })
    })
  })

  return mappedData
}

export function parseServerStyleData(
  defaultEntireData: { [currency: string]: IServerBar[] },
  projectName: ProjectName = ProjectName.DEFAULT
): IHistoricalData {
  let resultData: IHistoricalData = {}

  const data: IBar[][] = Object.values(defaultEntireData).map((bars) => parseResponse(bars))

  Object.keys(defaultEntireData).map((currency, index) => {
    resultData[currency] = addDefaultIndicatorsData(data[index], currency, projectName)
  })

  return resultData
}

const initialState = {
  accountInfo: defaultAccountInfo,
  visibleData: parseServerStyleData(defaultVisibleData),
  entireData: parseServerStyleData(defaultEntireData),
  currentBars: defaultCurrentBars,
  lastBarIndexes: JSON.parse(JSON.stringify(defaultLastBarIndexes)),
  testIsPaused: true,
  testingStarted: false,
  testIsChanged: {},
  testingSpeed: 6,
  // todo: find the best month / day
  dateRange,
  middleRange: getMiddleRange(dateRange),
  // TODO: keep current chart active when re-render the entire app (on language change)
  firstBarDate: '',
  lastBarDate: '',
  menuTopCollapsed: false,
  bottomMenuCollapsed: false,
  orders: [],
  theme: 'light',
  strategyIsActive: false,
  points: [],
  profit: [],
  newsRecords: defaultNewsRecords,
  steps: [],
  isLoading: false,
  chartTimeFrames: defaultChartTimeFrames,
  currentProject: dummyProject,
  width: document.documentElement.offsetWidth,
  height: document.documentElement.offsetHeight,
  isKeyBeingHeld: false,
  activeTab: 0,
  isDemoMode: true,
  currentCourseStepElementId: '',
  dataLength: 0,
  userIdentityService: new UserIdentityService(),
  currentTick: {
    date: 1580896800, // '2020-02-05 10:00'
    price: 1.10249
  }
}

export default initialState
