import { createAction, createSlice } from '@reduxjs/toolkit'
import { IWindowsState } from './types'

export const initialState: IWindowsState = {
  graphicPanelIsOpen: true,
  singleChartIsActive: true,
  cascadeWindowsIsActive: false,
  tileHorizontallyIsActive: false,
  tileVerticallyIsActive: false,
  minimizeAllIsActive: false,
  restoreAllIsActive: false
}

const assignAllWindows = createAction<IWindowsState>('assignAllWindows')

const resetState = (state: IWindowsState) =>
  Object.keys(initialState)
    .filter((x) => x != 'graphicPanelIsOpen')
    .map((k) => {
      state[k] = initialState[k]
    })

const windowsSlice = createSlice({
  name: 'windows',
  initialState,
  reducers: {
    toggleCascadeWindows: (state: IWindowsState) => {
      resetState(state)
      state.cascadeWindowsIsActive = true
      state.graphicPanelIsOpen = state.graphicPanelIsOpen
    },

    toggleSingleActiveChart: (state: IWindowsState) => {
      resetState(state)
      state.singleChartIsActive = true
    },

    toggleTileHorizontally: (state: IWindowsState) => {
      resetState(state)
      state.tileHorizontallyIsActive = true
      state.singleChartIsActive = false
    },

    toggleTileVertically: (state: IWindowsState) => {
      resetState(state)
      state.tileVerticallyIsActive = true
      state.singleChartIsActive = false
    },

    toggleMinimizeAll: (state: IWindowsState) => {
      resetState(state)
      state.minimizeAllIsActive = true
    },

    toggleRestoreAll: (state: IWindowsState) => {
      resetState(state)
      state.restoreAllIsActive = true
    }
  },
  extraReducers: (builder) => {
    builder.addCase(assignAllWindows, (state, action) => action.payload)
  }
})

export { assignAllWindows }

export const {
  toggleCascadeWindows,
  toggleMinimizeAll,
  toggleRestoreAll,
  toggleSingleActiveChart,
  toggleTileHorizontally,
  toggleTileVertically
} = windowsSlice.actions

export const initialWindowsState = initialState

export default windowsSlice.reducer
