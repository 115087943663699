import React,{ FC,ReactNode,useCallback,useEffect,useMemo,useRef,useState } from 'react'
import { defaultLots } from '../../../utils/constants'
import { MarketOrderTypes } from '../../../utils/enums'
import { IMarketOrder } from '../../../utils/interfaces/IOrder.interface'
import FTOModals from './FTOModal'
const css = FTOModals.modalDefaultStyle

export const DEFAULT_ORDER: IMarketOrder = {
  openTime: '',
  openPrice: 0,
  marketPrice: 0,
  swap: 0,
  commission: 0,
  points: 0,
  profit: 0,
  ticket: 1,
  symbol: 'EURUSD',
  type: MarketOrderTypes.Sell,
  lots: defaultLots[0],
  sl: 0,
  tp: 0,
  comment: '',
  barIndex: 0,
  timeFrame: 5
}

interface OrderWindowIdsStructure {
  component?: string // whole component
  main?: string // input or select
  upButton?: string // number add button
  downButton?: string // number reduce button
}
type OrderWindowType = number | string
type OrderFieldProps<T extends OrderWindowType> = {
  value: T
  setValue: (val: T) => void
  title?: string | ReactNode
  disabled?: boolean
  editable?: boolean
  ids?: string | OrderWindowIdsStructure // id for whole component if string, or for specific components if else
  className?: string
  options?: OrderWindowType[] // options to select
  valid?: boolean // if false - border red
  fixedStepValue?: boolean // if it's true, user'll be unable to set any value not % to step value, for example if step = 5 value can be only 5,10,15 with this property
  min?: number
  max?: number
  step?: number // step for number for grow and reduce
  roundDigit?: number // count of numbers after coma
}

type OrderFieldStringProps = OrderFieldProps<string>
type OrderFieldNumberProps = OrderFieldProps<number>

type OrderFieldMultiProps = OrderFieldNumberProps | OrderFieldStringProps
export const FTOModalField: FC<OrderFieldMultiProps> = ({
  title,
  ids,
  value,
  options,
  valid = true,
  step = 0.01,
  roundDigit = 0,
  editable = true,
  disabled = false,
  className = '',
  fixedStepValue = false,
  min,
  max,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(Number(value).toFixed(roundDigit))

  useEffect(() => {
    if (typeof value == 'number' && Number(value) !== Number(inputValue)) {
      setInputValue(Number(value).toFixed(roundDigit))
    }
    if (typeof value == 'string') {
      setInputValue(value)
    }
  }, [value, inputValue])
  const changeValue = (newValue: OrderWindowType) => {
    if (disabled) return
    if (typeof value == 'number') {
      const { setValue } = props as OrderFieldNumberProps

      let valueNum = Number(newValue)
      if (typeof newValue == 'string') {
        if (newValue.split('.').length > 1) {
          const [fullpart, digitpart] = newValue.split('.')
          let fixed = [fullpart, digitpart.slice(0, roundDigit)].join('.')
          valueNum = Number(fixed)
        } else valueNum = Number(newValue)
      }

      if (options !== undefined) {
        setValue(Number(options.find((x) => x == newValue)) ?? valueNum)
        return
      }

      if (min != undefined && valueNum < min) {
        setValue(min)
        setInputValue(min.toFixed(roundDigit))

        return
      }
      if (max != undefined && valueNum > max) {
        setValue(max)
        setInputValue(max.toFixed(roundDigit))
        return
      }
      setValue(valueNum)
      setInputValue(valueNum.toString())
    }
    if (typeof value == 'string') {
      const { setValue } = props as OrderFieldStringProps

      setValue(newValue.toString())
    }
  }

  const handleChange = (ev) => changeValue(ev.target.value)

  const inputSelectRef = useRef<HTMLInputElement | HTMLSelectElement>(null)
  useEffect(() => {
    if (!valid) inputSelectRef.current?.setCustomValidity('not valid field')
    else inputSelectRef.current?.setCustomValidity('')
  }, [valid])

  const structuredIds = ids as OrderWindowIdsStructure
  const isStructuredIdValid = (ids: OrderWindowIdsStructure | string | undefined): ids is OrderWindowIdsStructure => {
    if (!ids || typeof ids == 'string') return false
    return Object.values(ids as OrderWindowIdsStructure).find((x) => x != undefined) != undefined
  }
  const additionalButtonsShown = useMemo(
    () => typeof value == 'number' && !options && editable,
    [editable, value, options]
  )
  return (
    <div id={isStructuredIdValid(ids) ? ids.component : ids} className={`${css.field} ${className}`}>
      <span className={css.fieldTitle}>{title}</span>
      <div className={options ? `${disabled ? css.fieldBodySelectDisabled : css.fieldBodySelect}` : css.fieldBody}>
        {options ? (
          <select
            id={isStructuredIdValid(ids) ? ids.main : ''}
            ref={inputSelectRef as React.RefObject<HTMLSelectElement>}
            disabled={disabled || !editable}
            value={roundDigit ? Number(value).toFixed(roundDigit) : value}
            onChange={handleChange}
          >
            {options.map((o) => (
              <option key={o}>{o}</option>
            ))}
          </select>
        ) : (
          <input
            id={isStructuredIdValid(ids) ? ids.main : ''}
            ref={inputSelectRef as React.RefObject<HTMLInputElement>}
            readOnly={!editable}
            disabled={disabled}
            type={typeof value}
            value={inputValue}
            onChange={handleChange}
          />
        )}
        {additionalButtonsShown && (
          <div className={`${css.numberSpin} ${disabled ? css.disabled : ''}`}>
            <button
              id={isStructuredIdValid(ids) ? ids.upButton : ''}
              disabled={disabled}
              className={css.buttonUp}
              tabIndex={-1}
              onClick={() => step && changeValue((Number(value) + step).toFixed(roundDigit))}
            ></button>
            <button
              id={isStructuredIdValid(ids) ? ids.downButton : ''}
              disabled={disabled}
              className={css.buttonDown}
              tabIndex={-1}
              onClick={() => step && changeValue((Number(value) - step).toFixed(roundDigit))}
            ></button>
          </div>
        )}
      </div>
    </div>
  )
}

export const useErrorList = <T extends unknown>(): [T[], (newError: T) => void, (toRemove: T) => void] => {
  const [errors, seterrors] = useState<T[]>([])

  const addError = useCallback(
    (newError: T) => {
      if (!errors.includes(newError)) {
        seterrors((prev) => [...prev, newError])
      }
    },
    [errors, seterrors]
  )

  const removeError = useCallback(
    (toDelete: T) => {
      seterrors((prev) => prev.filter((x) => x != toDelete))
    },
    [errors]
  )

  return [errors, addError, removeError]
}
