import {
  ADD_OPEN_MARKET_ORDER_TO_JOURNAL,
  ADD_OPEN_PENDING_ORDER_TO_JOURNAL,
  ADD_CLOSED_MARKET_ORDER_TO_JOURNAL,
  ADD_CLOSED_PENDING_ORDER_TO_JOURNAL,
  ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL,
  ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL,
  ADD_START_TEST_TO_JOURNAL,
  ADD_PAUSE_TEST_TO_JOURNAL
} from './types'
import { IMarketOrder, IPendingOrder, IAccountHistory } from '../../utils/interfaces/IOrder.interface'

export const addOpenMarketOrderToJournal = (marketOrder: IMarketOrder) => {
  return {
    type: ADD_OPEN_MARKET_ORDER_TO_JOURNAL,
    payload: marketOrder
  }
}

export const addOpenPendingOrderToJournal = (pendingOrder: IPendingOrder) => {
  return {
    type: ADD_OPEN_PENDING_ORDER_TO_JOURNAL,
    payload: pendingOrder
  }
}

export const addClosedMarketOrderToJournal = (closedOrder: IAccountHistory) => {
  return {
    type: ADD_CLOSED_MARKET_ORDER_TO_JOURNAL,
    payload: closedOrder
  }
}

export const addClosedPendingOrderToJournal = (pendingOrder: IPendingOrder) => {
  return {
    type: ADD_CLOSED_PENDING_ORDER_TO_JOURNAL,
    payload: pendingOrder
  }
}

export const addModifiedMarketOrderToJournal = (marketOrder: IMarketOrder) => {
  return {
    type: ADD_MODIFIED_MARKET_ORDER_TO_JOURNAL,
    payload: marketOrder
  }
}

export const addModifiedPendingOrderToJournal = (pendingOrder: IPendingOrder) => {
  return {
    type: ADD_MODIFIED_PENDING_ORDER_TO_JOURNAL,
    payload: pendingOrder
  }
}

export const addStartTestToJournal = (dateTime: string) => {
  return {
    type: ADD_START_TEST_TO_JOURNAL,
    payload: dateTime
  }
}

export const addPauseTestToJournal = (dateTime: string) => {
  return {
    type: ADD_PAUSE_TEST_TO_JOURNAL,
    payload: dateTime
  }
}
