import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultOriginalTutorial } from '../../components/webTester/fixtures/constants'
import { Step } from '../../libs/react-joyride/types'
import { ChartType, CourseType, GuideNames, TutorialActionType, WebTesterLocale } from '../../utils/enums'
import { ITutorial, ITutorialItem } from '../../utils/interfaces/ITutorial.interface'
import { setChartType } from '../graphs/actions'
import { IJoyrideState } from './types'

const initialState: IJoyrideState = {
  courses: {},
  tutorialsList: {},
  currentCourseName: '',
  currentTutorialIndex: 0,
  originalTutorial: defaultOriginalTutorial,
  guideName: GuideNames.BasicTestingActions,
  guideDescription: '',
  guideIsOn: false,
  manualStart: false,
  step: 0,
  guideSteps: [],
  finishedTutorialsCount: 0,
  tutorialsStatus: {
    basicTestingActions: false
  },
  stepActionType: undefined,
  isWaitingMarketOrderClose: false,
  lessonNumber: 1,
  repeatIsPressed: false,
  activateNextStepOnModalOpened: false
}

const finishLesson = createAsyncThunk('joyride/finishLesson', (_, api) => {
  const { dispatch } = api
  dispatch(setChartType(ChartType.HYBRID))
  dispatch(resetLesson())
})

const joyrideSlice = createSlice({
  name: 'joyride',
  initialState,
  reducers: {
    setGuideTourName: (state, action: PayloadAction<string>) => {
      state.guideName = action.payload as GuideNames
      state.guideIsOn = true
      state.step = 0
    },

    setGuideDescription: (state, action: PayloadAction<string>) => {
      state.guideDescription = action.payload
    },

    setGuideSteps: (state, action: PayloadAction<Step[]>) => {
      //@ts-ignore
      state.guideSteps = action.payload
    },

    startGuideTour: (state) => {
      state.guideIsOn = true
      state.step = 0
    },

    setStepIndex: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },

    setWaitMarketOrder: (state, action: PayloadAction<boolean>) => {
      state.isWaitingMarketOrderClose = action.payload
    },

    setManualStart: (state, action: PayloadAction<boolean>) => {
      ;(state.manualStart = action.payload), (state.stepActionType = TutorialActionType.START)
    },
    incrementFinishedTutorialsCount: (state) => {
      // WARN: this has been commented in reducer
      state.finishedTutorialsCount = state.finishedTutorialsCount + 1
    },
    saveOriginalTutorial: (state, action: PayloadAction<ITutorial>) => {
      state.originalTutorial = action.payload
    },

    setStepActionType: (state, action: PayloadAction<TutorialActionType | undefined>) => {
      state.stepActionType = action.payload
    },
    setRepeatPressed: (state) => {
      state.repeatIsPressed = true
    },

    setActionOnModalOpened: (state, action: PayloadAction<boolean>) => {
      state.activateNextStepOnModalOpened = action.payload
    },

    resetLesson: (state) => {
      state.guideIsOn = false
      state.step = 0
      state.manualStart = false
    },

    // updateGuideStepTarget: (state, action: PayloadAction<{ index: number; target: string | HTMLElement }>) => {
    //   state.guideSteps = {
    //     ...state.guideSteps,
    //     [action.payload.index]: {
    //       ...state.guideSteps[action.payload.index],
    //       target: action.payload.target
    //     }
    //   }
    // },

    setTutorialsList: (state, action: PayloadAction<{ course: string; tutorials: ITutorialItem[] }>) => {
      state.tutorialsList = {
        ...state.tutorialsList,
        [action.payload.course]: action.payload.tutorials
      }
    },

    setCourseInList: (
      state,
      action: PayloadAction<{ courseType: CourseType; language: WebTesterLocale; courses: string[] }>
    ) => {
      state.courses = {
        ...state.courses,
        [action.payload.courseType]: {
          ...state[action.payload.courseType],
          [action.payload.language]: action.payload.courses
        }
      }
    },

    setCurrentCourseName: (state, action: PayloadAction<string>) => {
      state.currentCourseName = action.payload
    },

    setCurrentTutorialIndex: (state, action: PayloadAction<number>) => {
      state.currentTutorialIndex = action.payload
    }
  }
})

export const initialJoyrideState = initialState
export { finishLesson }
export default joyrideSlice.reducer
export const {
  incrementFinishedTutorialsCount,
  saveOriginalTutorial,
  setActionOnModalOpened,
  setCourseInList,
  setCurrentCourseName,
  setCurrentTutorialIndex,
  setGuideDescription,
  setGuideSteps,
  setGuideTourName,
  setManualStart,
  setRepeatPressed,
  setStepActionType,
  setStepIndex,
  setTutorialsList,
  setWaitMarketOrder,
  startGuideTour,
  resetLesson
  // updateGuideStepTarget
} = joyrideSlice.actions
