import {
  CustomIndicators,
  CustomIndicatorsRequests,
  IndicatorPlacement,
  Oscillators,
  OscillatorsRequests,
  TrendIndicators,
  TrendIndicatorsRequests,
  VolatilityIndicators,
  VolatilityIndicatorsRequests,
  VolumeIndicators,
  VolumeIndicatorsRequests
} from '../../../../../utils/enums'
import { IIndicatorCategory } from '../../../../../utils/interfaces/IIndicator.interface'
import { ApplyToPrice, MAType } from './recurringFields'

const red = '#ff0000'
const lime = '#00ff00'
const green = '#009900'
const blue = '#0000ff'
const dodgerBlue = '#1e90ff'
const teal = '#008080'
const yellow = '#ffff00'
const maroon = '#800000'
const gray = '#808080'
const silver = '#c0c0c0'

export type Color = { [key: string]: string }
export type IndicatorField = number | string[] | Color
export const isColor = (val: IndicatorField): val is Color => typeof val == 'object' && !Array.isArray(val)
export type Indicator = {
  indicatorName: string
  fullIndicatorName: string
  placement: IndicatorPlacement
  category: IIndicatorCategory
  fields: {
    [key: string]: IndicatorField
  }
}

export enum IndicatorColorName {
  VOLUME_UP = 'Volume Up',
  VOLUME_DOWN = 'Volume Down',
  MA = 'MA',
  UP_BAND = 'Up band',
  DOWN_BAND = 'Up band',
  ENVELOPE_UP = 'Envelope up',
  ENVELOPE_DOWN = 'Envelope down',
  TENKAN_SEN = 'Tenkan-sen',
  KIJUN_SEN = 'Kijun-sen',
  CHINKOU_SPAN = 'Chinkou span',
  SENKOU_SPAN_A = 'Senkou span A',
  SENKOU_SPAN_B = 'Senkou span B',
  SMA_1 = 'SMA 1',
  SMA_2 = 'SMA 2',
  BEARS_POWER = 'Bears Power',
  BULLS_POWER = 'Bulls Power',
  MACD = 'MACD',
  SIGNAL_LINE = 'Signal Line',
  MOMENTUM = 'Momentum',
  RSI = 'RSI',
  RVI = 'RVI',
  FRACTAL_UP = 'Fractal Up',
  FRACTAL_DOWN = 'Fractal Down',
  FI = 'FI',
  CCI = 'CCI',
  AD = 'AD',
  ATR = 'ATR',
  OBV = 'ODB',
  MFI = 'MFI',
  OSMA = 'OSMA',
  ADX = 'ADX',
  PLUS_DI = '+Di',
  MINUS_DI = '-Di',
  UP = 'UP',
  DOWN = 'DOWN',
  DEMARKER = 'DeMarker',
  JAWS = 'Jaws',
  TEETH = 'Teeth',
  LIPS = 'Lips'
}

export const indicators: Indicator[] = [
  {
    indicatorName: TrendIndicatorsRequests.Alligator,
    fullIndicatorName: TrendIndicators.Alligator,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Trend,
    fields: {
      JawPeriod: 13,
      JawShift: 8,
      TeethPeriod: 8,
      TeethShift: 5,
      LipsPeriod: 5,
      LipsShift: 3,
      MAType,
      ApplyToPrice,
      Color: { [IndicatorColorName.JAWS]: blue, [IndicatorColorName.TEETH]: red, [IndicatorColorName.LIPS]: lime }
    }
  },
  {
    indicatorName: TrendIndicatorsRequests.BollingerBands,
    fullIndicatorName: TrendIndicators.BollingerBands,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Trend,
    fields: {
      Period: 8,
      Deviation: 2,
      MAType,
      ApplyToPrice,

      Color: { [IndicatorColorName.UP_BAND]: teal, [IndicatorColorName.MA]: teal, [IndicatorColorName.DOWN_BAND]: teal }
    }
  },
  {
    indicatorName: TrendIndicatorsRequests.DeMarker,
    fullIndicatorName: TrendIndicators.DeMarker,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Trend,
    fields: { Period: 14, Color: { [IndicatorColorName.DEMARKER]: lime } }
  },
  {
    indicatorName: TrendIndicatorsRequests.Envelopes,
    fullIndicatorName: TrendIndicators.Envelopes,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Trend,
    fields: {
      Period: 14,
      Shift: 0,
      MAType,
      ApplyToPrice,
      Deviation: 0.1,
      Color: { [IndicatorColorName.ENVELOPE_UP]: blue, [IndicatorColorName.ENVELOPE_DOWN]: red }
    }
  },
  {
    indicatorName: TrendIndicatorsRequests.Gator,
    fullIndicatorName: TrendIndicators.Gator,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Trend,
    fields: {
      JawPeriod: 13,
      JawShift: 8,
      TeethPeriod: 8,
      TeethShift: 5,
      LipsPeriod: 5,
      LipsShift: 3,
      MAType,
      ApplyToPrice,
      Color: { [IndicatorColorName.UP]: green, [IndicatorColorName.DOWN]: red }
    }
  },
  // {
  // 	indicatorName: TrendIndicatorsRequests.HeikinAshi,
  // 	fullIndicatorName: TrendIndicators.HeikinAshi,
  // 	placement: IndicatorPlacement.OnChart,
  //	category: IIndicatorCategory.Trend,
  // 	fields: [
  // 		{
  // 			Color: [
  // 				{ 'Up bar': red },
  // 				{ 'Up bar fill': red },
  // 				{ 'Down bar': blue },
  // 				{ 'Down bar fill': blue }
  // 			]
  // 		}
  // 	]
  // },
  {
    indicatorName: TrendIndicatorsRequests.Ichimoku,
    fullIndicatorName: TrendIndicators.Ichimoku,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Trend,
    fields: {
      'Tenkan-sen period': 9,
      'Kijun-sen period': 26,
      'Senkou span period': 52,

      Color: {
        [IndicatorColorName.TENKAN_SEN]: red,
        [IndicatorColorName.KIJUN_SEN]: blue,
        [IndicatorColorName.CHINKOU_SPAN]: green,
        [IndicatorColorName.SENKOU_SPAN_A]: '79ABFF',
        [IndicatorColorName.SENKOU_SPAN_B]: 'FF9E9E'
      }
    }
  },
  // {
  // 	// TODO: missing indicator
  // 	indicatorName: 'band',
  //	fullIndicatorName: ,
  //	placement: IndicatorPlacement.OnChart,
  // 	fields: [
  // 		{ Period: 10 },
  // 		{ MAType },
  // 		{
  // 			Color: [
  // 				{ 'Upper band': lime },
  // 				{ 'Middle band': red },
  // 				{ 'Lower band': lime }
  // 			]
  // 		}
  // 	]
  // },
  {
    indicatorName: TrendIndicatorsRequests.MovingAverage,
    fullIndicatorName: TrendIndicators.MovingAverage,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Trend,
    fields: {
      Period: 8,
      HShift: 0,
      VShift: 0,
      MAType,
      ApplyToPrice,
      Color: { [IndicatorColorName.MA]: yellow }
    }
  },
  {
    indicatorName: OscillatorsRequests.AverageDirectionalMovementIndex,
    fullIndicatorName: Oscillators.AverageDirectionalMovementIndex,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: {
      Period: 14,
      ApplyToPrice,
      Color: {
        [IndicatorColorName.ADX]: yellow,
        [IndicatorColorName.PLUS_DI]: green,
        [IndicatorColorName.MINUS_DI]: blue
      }
    }
  },
  {
    indicatorName: OscillatorsRequests.AwesomeOscillator,
    fullIndicatorName: Oscillators.AwesomeOscillator,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: {
      PeriodF: 5,
      PeriodS: 34,
      MAType,
      ApplyToPrice,
      Color: { [IndicatorColorName.SMA_1]: lime, [IndicatorColorName.SMA_2]: red }
    }
  },
  {
    indicatorName: OscillatorsRequests.BearsPower,
    fullIndicatorName: Oscillators.BearsPower,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: { Period: 13, Color: { [IndicatorColorName.BEARS_POWER]: red } }
  },
  {
    indicatorName: OscillatorsRequests.BullsPower,
    fullIndicatorName: Oscillators.BullsPower,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: { Period: 13, Color: { [IndicatorColorName.BULLS_POWER]: silver } }
  },
  {
    indicatorName: OscillatorsRequests.MovingAverageConvergenceDivergence,
    fullIndicatorName: Oscillators.MovingAverageConvergenceDivergence,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: {
      FastPeriod: 5,
      SlowPeriod: 13,
      MacdPeriod: 3,
      ApplyToPrice,

      Color: { [IndicatorColorName.MACD]: silver, [IndicatorColorName.SIGNAL_LINE]: red }
    }
  },
  {
    indicatorName: OscillatorsRequests.Momentum,
    fullIndicatorName: Oscillators.Momentum,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: { Period: 14, ApplyToPrice, Color: { [IndicatorColorName.MOMENTUM]: dodgerBlue } }
  },
  // {
  // 	indicatorName: TrendIndicatorsRequests.ParabolicSar,
  //	placement: IndicatorPlacement.OnChart,
  // 	fields: [{ Step: 0.02 }, { Maximum: 0.2 }, { Color: [{ Buffer: lime }] }]
  // },
  // {
  // 	indicatorName: TrendIndicatorsRequests.PivotPoints,
  // 	fields: [
  // 		{ 'Pivot Type': ['Daily', 'Weekly', 'Monthly', 'Yearly'] },
  // 		{ Continuous: 'No' },
  // 		{ 'Show Midpoints': 'No' },
  // 		{
  // 			Color: [
  // 				{ R3: green },
  // 				{ R2: blue },
  // 				{ R1: red },
  // 				{ PP: maroon },
  // 				{ S1: red },
  // 				{ S2: blue },
  // 				{ S3: green },
  // 				{ M1: gray },
  // 				{ M2: gray },
  // 				{ M3: gray },
  // 				{ M4: gray }
  // 			]
  // 		}
  // 	]
  // },
  {
    indicatorName: OscillatorsRequests.RelativeStrengthIndex,
    fullIndicatorName: Oscillators.RelativeStrengthIndex,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: { Period: 8, ApplyToPrice, Color: { [IndicatorColorName.RSI]: dodgerBlue } }
  },
  {
    indicatorName: OscillatorsRequests.RelativeVigorIndex,
    fullIndicatorName: Oscillators.RelativeVigorIndex,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: { Period: 10, Color: { [IndicatorColorName.RVI]: green, [IndicatorColorName.SIGNAL_LINE]: red } }
  },
  {
    indicatorName: OscillatorsRequests.OSMA,
    fullIndicatorName: Oscillators.OSMA,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: {
      'Fast EMA Period': 12,
      'Slow EMA Period': 26,
      'SMA period': 9,
      ApplyToPrice,
      Color: { [IndicatorColorName.OSMA]: silver }
    }
  },
  {
    indicatorName: OscillatorsRequests.Stochastic,
    fullIndicatorName: Oscillators.Stochastic,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Oscillators,
    fields: {
      method: ['SMA', 'EMA', 'Smoothed', 'WMA'],
      Kperiod: 6,
      Dperiod: 3,
      Slowing: 3,
      ApplyToPrice: ['Close / Close', 'Low / High'],
      Color: { 'K Line': red, 'D Line': dodgerBlue }
    }
  },
  {
    indicatorName: VolumeIndicatorsRequests.MFI,
    fullIndicatorName: VolumeIndicators.MFI,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Volume,
    fields: { Period: 14, Color: { [IndicatorColorName.MFI]: blue } }
  },
  {
    indicatorName: VolumeIndicatorsRequests.OnBalanceVolume,
    fullIndicatorName: VolumeIndicators.OnBalanceVolume,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Volume,
    fields: { ApplyToPrice, Color: { [IndicatorColorName.OBV]: blue } }
  },
  {
    indicatorName: VolumeIndicatorsRequests.Volume,
    fullIndicatorName: VolumeIndicators.Volume,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Volume,
    fields: {
      Color: { [IndicatorColorName.VOLUME_UP]: green, [IndicatorColorName.VOLUME_DOWN]: red }
    }
  },
  {
    indicatorName: VolatilityIndicatorsRequests.AverageTrueRange,
    fullIndicatorName: VolatilityIndicators.AverageTrueRange,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Volatility,
    fields: { Period: 14, ApplyToPrice, Color: { [IndicatorColorName.ATR]: red } }
  },
  {
    indicatorName: CustomIndicatorsRequests.AD,
    fullIndicatorName: CustomIndicators.AD,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Custom,
    fields: { Color: { [IndicatorColorName.AD]: dodgerBlue } }
  },
  {
    indicatorName: CustomIndicatorsRequests.CCI,
    fullIndicatorName: CustomIndicators.CCI,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Custom,
    fields: { Period: 14, Color: { [IndicatorColorName.CCI]: dodgerBlue } }
  },
  {
    indicatorName: CustomIndicatorsRequests.EldersForceIndex,
    fullIndicatorName: CustomIndicators.EldersForceIndex,
    placement: IndicatorPlacement.BelowChart,
    category: IIndicatorCategory.Custom,
    fields: { Period: 2, MAType, ApplyToPrice, Color: { [IndicatorColorName.FI]: red } }
  },
  {
    indicatorName: CustomIndicatorsRequests.Fractals,
    fullIndicatorName: CustomIndicators.Fractals,
    placement: IndicatorPlacement.OnChart,
    category: IIndicatorCategory.Custom,
    fields: {
      Color: { [IndicatorColorName.FRACTAL_UP]: green, [IndicatorColorName.FRACTAL_DOWN]: red }
    }
  }
]
