import { createAsyncThunk } from '@reduxjs/toolkit'
import { IndicatorColorName } from '../../components/topMenu/tabs/indicatorsTab/utils/indicatorsConstants'
import { IndicatorComponentType, IndicatorPlacement, LineStyle, ProjectName, VolumeIndicators } from '../../utils/enums'
import { ALL_DEFAULT_TIMEFRAMES } from '../../utils/helpers/constants'
import { IChart } from '../../utils/interfaces/IChart.interface'
import { IIndicatorCategory, IOscillator } from '../../utils/interfaces/IIndicator.interface'
import { defaultFiboRetracement, defaultIndicators, defaultOscillators } from '../../utils/projects/defaultProject'
import { demoIndicators } from '../../utils/projects/demoProject'
import {
  addIndicator,
  addOscillator,
  createChartTools,
  deleteOscillator,
  setActiveIndicatorOscillator,
  updateChartTools
} from '../graphics/GraphicsSlice'
import { RootState } from '../store/store'
import { graphsSlice } from './reducers'

export const {
  toggleCandlesMode,
  toggleBarsMode,
  toggleLinesMode,
  toggleAreaMode,
  toggleKagiMode,
  toggleCrosshair,
  toggleMagnetMode,
  toggleRenkoBarsMode,
  togglePointsAndFigureMode,
  toggleAutoScroll,
  toggleChartOffset,
  toggleSyncCharts,
  toggleShowAccountHistory,
  toggleShowGrid,
  toggleShowNews,
  toggleShowNotes,
  toggleShowPeriodSeparators,
  generateChartSettings,
  setNotes,
  setEndDate,
  setTimeFrame,
  setProjectName,
  setZoom,
  cleanGraphsState,
  assignAllGraphs,
  enableVolumes,
  disableVolumes,
  setChartType,
  setIsLoading,
  setYZoom,
  addCharts,
  updateCurrentChart
} = graphsSlice.actions

export const setCharts = createAsyncThunk<void, { charts: IChart[]; projectName?: string }, { state: RootState }>(
  'graphs/setCharts',
  async ({ charts, projectName = ProjectName.DEFAULT }, api) => {
    const { dispatch } = api

    dispatch(addCharts(charts))

    if (charts.length > 0) {
      const chart: IChart = charts[0]
      dispatch(setCurrentChart({ id: chart.id }))

      for (let i = 0; i < charts.length; i++) {
        const id = charts[i].id
        const symbol = charts[i].symbol
        const timeFrame = charts[i].timeFrame

        if (id) {
          dispatch(createChartTools({ symbol, timeframe: timeFrame }))
          dispatch(generateChartSettings(id))

          if (symbol === 'EURUSD' || charts[i]?.mode === 'candle') {
            dispatch(toggleCandlesMode(id))

            switch (projectName) {
              case ProjectName.DEFAULT:
                dispatch(addIndicator({ chartId: id, indicator: defaultIndicators[0] }))
                dispatch(addOscillator({ chartId: id, oscillator: defaultOscillators[0] }))
                dispatch(setActiveIndicatorOscillator({ chartId: defaultOscillators[0].id, id }))
                dispatch(addVolumesOscillator({ chartId: id }))
                break
              case ProjectName.MARKETING:
                dispatch(addIndicator({ chartId: id, indicator: demoIndicators[0] }))
                break
            }
          } else if (symbol === 'USDJPY') {
            dispatch(toggleBarsMode(id))
            ALL_DEFAULT_TIMEFRAMES.map((timeframe) => {
              dispatch(updateChartTools({ symbol, timeframe, tools: defaultFiboRetracement }))
            })
            dispatch(addOscillator({ chartId: id, oscillator: defaultOscillators[3] }))
            dispatch(addOscillator({ chartId: id, oscillator: defaultOscillators[4] }))
            dispatch(setActiveIndicatorOscillator({ chartId: defaultOscillators[3].id, id }))
          } else {
            dispatch(toggleCandlesMode(id))
          }
        }
      }
    }
  }
)

export const setCurrentChart = createAsyncThunk<void, { id?: string; symbol?: string }, { state: RootState }>(
  'graphs/setCurrentChart',
  async ({ id, symbol }, api) => {
    const { dispatch, getState } = api

    const set = (key, value) => {
      const currentChart = getState().graphs.charts.find((chart) => chart[key] === value)
      if (currentChart) {
        dispatch(updateCurrentChart(currentChart))
      } else {
        console.error(`Graphs with ${key} ${value} is not found. CurrentChart was not set.`)
      }
    }

    id && set('id', id)
    symbol && set('symbol', symbol)
  }
)

export const addVolumesOscillator = createAsyncThunk<void, { chartId: string }, { state: RootState }>(
  'graphs/addVolumesOscillator',
  async ({ chartId }, api) => {
    const { dispatch } = api

    const volumesOscillator: IOscillator = {
      id: chartId,
      name: VolumeIndicators.Volume,
      fullName: VolumeIndicators.Volume,
      stroke: '',
      strokeDasharray: LineStyle.None,
      strokeWidth: 1,
      strokeOpacity: 1,
      category: IIndicatorCategory.Volume,
      settings: {
        placement: IndicatorPlacement.BelowChart,
        Color: { [IndicatorColorName.VOLUME_UP]: '#009900', [IndicatorColorName.VOLUME_DOWN]: '#ff0000' }
      },
      lineType: IndicatorComponentType.Line
    }

    dispatch(addOscillator({ chartId, oscillator: volumesOscillator }))
    dispatch(enableVolumes(chartId))
  }
)

export const removeVolumesOscillator = createAsyncThunk<
  void,
  { chartId: string; volumesOscillatorId: string },
  { state: RootState }
>('graphs/removeVolumesOscillator', async ({ chartId, volumesOscillatorId }, api) => {
  const { dispatch } = api
  dispatch(deleteOscillator({ chartId, oscillatorId: volumesOscillatorId }))
  dispatch(disableVolumes(chartId))
})
