import shortid from 'shortid'
import { indicatorDefaultLook } from '../constants'
import { defaultMA } from '../defaultData/indicators/default/defaultMA'
import { demoMaH1 } from '../defaultData/indicators/demo/demoMA-H1'
import { demoMaH4 } from '../defaultData/indicators/demo/demoMA-H4'
import { IGraphicalLine, IndicatorComponentType, IndicatorPlacement, TrendIndicators } from '../enums'
import { IChart, IChartTimeFrame } from '../interfaces/IChart.interface'
import { ICurrentBar } from '../interfaces/ICurrentBar'
import { IChartTools } from '../interfaces/IGraphics'
import { ILastBarIndexes, IServerBar } from '../interfaces/IHistoricalData.interface'
import { IIndicator, IIndicatorCategory } from '../interfaces/IIndicator.interface'
import { INews } from '../interfaces/INews.interface'
import { IAccountInfo } from '../interfaces/IOrder.interface'
import { IProjectPascalCase } from '../interfaces/IProject.interface'

export const demoProject: IProjectPascalCase = {
  Name: 'Demo Project',
  TimeZone: 0,
  DaylightSavingTime: 0,
  TicksGenerated: false,
  UsedTickHistory: false,
  UsedFloatingSpread: false,
  ForwardTestingOnly: false,
  FromDate: 1580515200, // 04/01/2020 @ 12:00am (UTC)
  ToDate: 1588204800, // 04/30/2020 @ 12:00am (UTC)
  StartDate: 1585699200, // 04/01/2020 @ 12:00am (UTC)
  CurrentDate: 1585785600, // 04/02/2020 @ 12:00am (UTC)
  InitialDeposit: 10000,
  Symbols: ['EURUSD'],
  Balance: 10000,
  Equity: 10000,
  Margin: 0,
  FreeMargin: 0,
  MarketOrders: [],
  PendingOrders: [],
  ClosedOrders: [],
  Notes: [],
  Charts: [
    { Symbol: 'EURUSD60', TimeFrame: 60, Comment: '', Digits: 5 },
    { Symbol: 'EURUSD240', TimeFrame: 240, Comment: '', Digits: 5 }
  ]
}

export const demoCharts: IChart[] = [
  {
    symbol: 'EURUSD',
    timeFrame: 60,
    comment: '',
    digitsAfterPoint: 5,
    id: shortid.generate(),
    index: 1,
    mode: 'candle'
  },
  {
    symbol: 'EURUSD',
    timeFrame: 240,
    comment: '',
    digitsAfterPoint: 5,
    id: shortid.generate(),
    index: 2,
    mode: 'candle'
  }
]

export const demoAccountInfo: IAccountInfo = {
  balance: 0,
  equity: 10000,
  margin: 0,
  freeMargin: 10000,
  marginLevel: 0,
  totalProfit: 0,
  dollarsPerPipPerLot: 10,
  lotSize: 100000,
  leverage: 100
}

export const demoCurrentBars: ICurrentBar = {
  EURUSD60: { close: 1.10618, date: '2020-02-05T10:00:00.000Z', open: 1.10588 },
  // EURUSD240: { close: 1.08096, date: '2020-02-05T12:00:00.000Z', open: 1.07963 },
  EURUSD240: { close: 1.10016, date: '2020-02-05T08:00:00.000Z', open: 1.10217 }
}

export const demoChartTimeFrames: IChartTimeFrame[] = [
  {
    symbol: 'EURUSD',
    timeFrame: 60
  },
  {
    symbol: 'EURUSD',
    timeFrame: 240
  }
]

export const demoLastBarIndexes: ILastBarIndexes = {
  EURUSD60: 25,
  EURUSD240: 14
}

export const demoIndicators: IIndicator[] = [
  {
    id: shortid(),
    name: 'sma8',
    fullName: TrendIndicators.MovingAverage,
    ...indicatorDefaultLook,
    category: IIndicatorCategory.Trend,
    stroke: '#FFAE29',
    settings: {
      placement: IndicatorPlacement.OnChart,
      Period: 8,
      HShift: 0,
      VShift: 0,
      MAType: 'Simple (SMA)',
      ApplyToPrice: 'Close',
      Color: {}
    },
    lineType: IndicatorComponentType.Line
  }
]

export const demoFiboRetracement: IChartTools = {
  textList: [],
  trend_lines: [],
  rays: [],
  vertical_lines: [],
  horizontal_lines: [],
  retracements: [
    {
      x1: 229,
      y1: 109.29841386138614,
      x2: 245,
      y2: 109.71641386138613,
      selected: true,
      appearance: {
        fontFamily: 'Roboto',
        fontSize: 14,
        strokeOpacity: 0.6,
        strokeWidth: 1,
        pointerRadius: 3,
        edgeStrokeWidth: 1,
        stroke: '#555555',
        fontFill: '#555555',
        nsEdgeFill: '#555555',
        edgeFill: '#555555',
        edgeStroke: '#555555'
      },
      type: IGraphicalLine.Ray
    }
  ],
  gann_fans: [],
  equidistant_channels: [],
  standard_deviation_channels: [],
  rectangles: [],
  ellipses: [],
  triangles: [],
  icons: [],
  riskRewards: []
}

export const demoVisibleData: { [currency: string]: IServerBar[] } = {
  EURUSD60: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 1.10939,
          h: 1.10939,
          l: 1.10813,
          c: 1.10846,
          v: 1924075000
        },
        {
          t: 82800,
          o: 1.10851,
          h: 1.10907,
          l: 1.10851,
          c: 1.1089,
          v: 2262960000
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.1089,
          h: 1.1089,
          l: 1.10799,
          c: 1.10817,
          v: 2811280000
        },
        {
          t: 3600,
          o: 1.10817,
          h: 1.10876,
          l: 1.10802,
          c: 1.1083,
          v: 4559170000
        },
        {
          t: 7200,
          o: 1.10832,
          h: 1.10846,
          l: 1.10802,
          c: 1.10819,
          v: 3908790000
        },
        {
          t: 10800,
          o: 1.10819,
          h: 1.10838,
          l: 1.10801,
          c: 1.10811,
          v: 2431960000
        },
        {
          t: 14400,
          o: 1.10812,
          h: 1.10849,
          l: 1.1081,
          c: 1.10817,
          v: 1521690000
        },
        {
          t: 18000,
          o: 1.10818,
          h: 1.10848,
          l: 1.10813,
          c: 1.10844,
          v: 2330080000
        },
        {
          t: 21600,
          o: 1.10842,
          h: 1.10862,
          l: 1.10816,
          c: 1.10824,
          v: 2676330000
        },
        {
          t: 25200,
          o: 1.10823,
          h: 1.10845,
          l: 1.10704,
          c: 1.10747,
          v: 8427900000
        },
        {
          t: 28800,
          o: 1.10747,
          h: 1.10783,
          l: 1.10642,
          c: 1.1066,
          v: 11371630000
        },
        {
          t: 32400,
          o: 1.1066,
          h: 1.10727,
          l: 1.1059,
          c: 1.10726,
          v: 8715189998
        },
        {
          t: 36000,
          o: 1.10726,
          h: 1.10741,
          l: 1.10642,
          c: 1.10648,
          v: 8483800002
        },
        {
          t: 39600,
          o: 1.10647,
          h: 1.10681,
          l: 1.10583,
          c: 1.10626,
          v: 7957359995
        },
        {
          t: 43200,
          o: 1.10626,
          h: 1.10666,
          l: 1.10614,
          c: 1.10658,
          v: 4897790000
        },
        {
          t: 46800,
          o: 1.10657,
          h: 1.10666,
          l: 1.10617,
          c: 1.1063,
          v: 6224210000
        },
        {
          t: 50400,
          o: 1.1063,
          h: 1.10637,
          l: 1.1045,
          c: 1.10473,
          v: 10693710000
        },
        {
          t: 54000,
          o: 1.1047,
          h: 1.10598,
          l: 1.10356,
          c: 1.10556,
          v: 16883580001
        },
        {
          t: 57600,
          o: 1.10556,
          h: 1.10657,
          l: 1.10536,
          c: 1.1062,
          v: 10120609999
        },
        {
          t: 61200,
          o: 1.10621,
          h: 1.10663,
          l: 1.10575,
          c: 1.10585,
          v: 5661250001
        },
        {
          t: 64800,
          o: 1.10585,
          h: 1.10638,
          l: 1.10581,
          c: 1.10613,
          v: 4132920000
        },
        {
          t: 68400,
          o: 1.10616,
          h: 1.10653,
          l: 1.10609,
          c: 1.10634,
          v: 3156480000
        },
        {
          t: 72000,
          o: 1.10633,
          h: 1.1064,
          l: 1.10571,
          c: 1.10581,
          v: 2777847500
        },
        {
          t: 75600,
          o: 1.1058,
          h: 1.10614,
          l: 1.10577,
          c: 1.10593,
          v: 1298465000
        },
        {
          t: 79200,
          o: 1.1059,
          h: 1.10611,
          l: 1.10584,
          c: 1.10591,
          v: 2483930000
        },
        {
          t: 82800,
          o: 1.10591,
          h: 1.10631,
          l: 1.10588,
          c: 1.10623,
          v: 778070000
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.10623,
          h: 1.1063,
          l: 1.10593,
          c: 1.10609,
          v: 1083610000
        },
        {
          t: 3600,
          o: 1.1061,
          h: 1.10641,
          l: 1.10592,
          c: 1.10601,
          v: 2490750000
        },
        {
          t: 7200,
          o: 1.10602,
          h: 1.10604,
          l: 1.10556,
          c: 1.10586,
          v: 2685670000
        },
        {
          t: 10800,
          o: 1.10586,
          h: 1.10598,
          l: 1.10555,
          c: 1.10578,
          v: 2287780000
        },
        {
          t: 14400,
          o: 1.1058,
          h: 1.10609,
          l: 1.10573,
          c: 1.10599,
          v: 1835310000
        },
        {
          t: 18000,
          o: 1.10597,
          h: 1.10633,
          l: 1.10577,
          c: 1.10586,
          v: 2092500000
        },
        {
          t: 21600,
          o: 1.10585,
          h: 1.10637,
          l: 1.10582,
          c: 1.10591,
          v: 3083340000
        },
        {
          t: 25200,
          o: 1.1059,
          h: 1.1059,
          l: 1.10488,
          c: 1.10502,
          v: 6425290000
        },
        {
          t: 28800,
          o: 1.10498,
          h: 1.10593,
          l: 1.10495,
          c: 1.10578,
          v: 9061220000
        },
        {
          t: 32400,
          o: 1.10577,
          h: 1.10611,
          l: 1.10528,
          c: 1.10557,
          v: 9703980001
        },
        {
          t: 36000,
          o: 1.10556,
          h: 1.10571,
          l: 1.10494,
          c: 1.10554,
          v: 7264600000
        },
        {
          t: 39600,
          o: 1.10554,
          h: 1.1058,
          l: 1.10522,
          c: 1.1054,
          v: 5698310000
        },
        {
          t: 43200,
          o: 1.10541,
          h: 1.10541,
          l: 1.1043,
          c: 1.1044,
          v: 6226340000
        },
        {
          t: 46800,
          o: 1.10439,
          h: 1.10507,
          l: 1.10432,
          c: 1.10444,
          v: 8914090000
        },
        {
          t: 50400,
          o: 1.10443,
          h: 1.10467,
          l: 1.10408,
          c: 1.10444,
          v: 10003280007
        },
        {
          t: 54000,
          o: 1.10444,
          h: 1.10483,
          l: 1.10331,
          c: 1.10382,
          v: 10599270000
        },
        {
          t: 57600,
          o: 1.10384,
          h: 1.10431,
          l: 1.10328,
          c: 1.10428,
          v: 7006700000
        },
        {
          t: 61200,
          o: 1.10428,
          h: 1.10452,
          l: 1.10366,
          c: 1.10371,
          v: 5102620000
        },
        {
          t: 64800,
          o: 1.10371,
          h: 1.10446,
          l: 1.10365,
          c: 1.10409,
          v: 2549250000
        },
        {
          t: 68400,
          o: 1.10409,
          h: 1.10444,
          l: 1.10401,
          c: 1.10419,
          v: 1882040000
        },
        {
          t: 72000,
          o: 1.10419,
          h: 1.10447,
          l: 1.1041,
          c: 1.10442,
          v: 3040500000
        },
        {
          t: 75600,
          o: 1.10442,
          h: 1.10457,
          l: 1.10433,
          c: 1.10434,
          v: 1421635000
        },
        {
          t: 79200,
          o: 1.10436,
          h: 1.10452,
          l: 1.10413,
          c: 1.10444,
          v: 2431480000
        },
        {
          t: 82800,
          o: 1.10447,
          h: 1.10459,
          l: 1.10439,
          c: 1.10446,
          v: 829850000
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.10449,
          h: 1.10449,
          l: 1.1042,
          c: 1.10431,
          v: 1487660000
        },
        {
          t: 3600,
          o: 1.1043,
          h: 1.10436,
          l: 1.10397,
          c: 1.10402,
          v: 2463710000
        },
        {
          t: 7200,
          o: 1.10402,
          h: 1.10409,
          l: 1.10339,
          c: 1.10343,
          v: 2384650000
        },
        {
          t: 10800,
          o: 1.10343,
          h: 1.10366,
          l: 1.10336,
          c: 1.10341,
          v: 2843780000
        },
        {
          t: 14400,
          o: 1.1034,
          h: 1.10353,
          l: 1.10336,
          c: 1.10345,
          v: 821940000
        },
        {
          t: 18000,
          o: 1.10345,
          h: 1.10381,
          l: 1.10336,
          c: 1.10378,
          v: 1311710000
        },
        {
          t: 21600,
          o: 1.10378,
          h: 1.10382,
          l: 1.10345,
          c: 1.10358,
          v: 2161610000
        },
        {
          t: 25200,
          o: 1.1036,
          h: 1.10405,
          l: 1.10356,
          c: 1.10368,
          v: 4050990000
        },
        {
          t: 28800,
          o: 1.1037,
          h: 1.10479,
          l: 1.10365,
          c: 1.1042,
          v: 11955220000
        },
        {
          t: 32400,
          o: 1.10419,
          h: 1.10427,
          l: 1.10215,
          c: 1.1026,
          v: 12371690001
        }
      ]
    }
  ],
  EURUSD240: [
    {
      d: 1580601600,
      b: [
        {
          t: 72000,
          o: 1.10939,
          h: 1.10939,
          l: 1.10813,
          c: 1.1089,
          v: 4187035000
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.1089,
          h: 1.1089,
          l: 1.10799,
          c: 1.10811,
          v: 13711200000
        },
        {
          t: 14400,
          o: 1.10812,
          h: 1.10862,
          l: 1.10704,
          c: 1.10747,
          v: 14956000000
        },
        {
          t: 28800,
          o: 1.10747,
          h: 1.10783,
          l: 1.10583,
          c: 1.10626,
          v: 36527979995
        },
        {
          t: 43200,
          o: 1.10626,
          h: 1.10666,
          l: 1.10356,
          c: 1.10556,
          v: 38699290001
        },
        {
          t: 57600,
          o: 1.10556,
          h: 1.10663,
          l: 1.10536,
          c: 1.10634,
          v: 23071260000
        },
        {
          t: 72000,
          o: 1.10633,
          h: 1.1064,
          l: 1.10571,
          c: 1.10623,
          v: 7338312500
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.10623,
          h: 1.10641,
          l: 1.10555,
          c: 1.10578,
          v: 8547810000
        },
        {
          t: 14400,
          o: 1.1058,
          h: 1.10637,
          l: 1.10488,
          c: 1.10502,
          v: 13436440000
        },
        {
          t: 28800,
          o: 1.10498,
          h: 1.10611,
          l: 1.10494,
          c: 1.1054,
          v: 31728110001
        },
        {
          t: 43200,
          o: 1.10541,
          h: 1.10541,
          l: 1.10331,
          c: 1.10382,
          v: 35742980007
        },
        {
          t: 57600,
          o: 1.10384,
          h: 1.10452,
          l: 1.10328,
          c: 1.10419,
          v: 16540610000
        },
        {
          t: 72000,
          o: 1.10419,
          h: 1.10459,
          l: 1.1041,
          c: 1.10446,
          v: 7723465000
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.10449,
          h: 1.10449,
          l: 1.10336,
          c: 1.10341,
          v: 9179800000
        },
        {
          t: 14400,
          o: 1.1034,
          h: 1.10405,
          l: 1.10336,
          c: 1.10368,
          v: 8346250000
        }
      ]
    }
  ]
}

export const demoEntireData: { [currency: string]: IServerBar[] } = {
  EURUSD60: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 1.10939,
          h: 1.10939,
          l: 1.10813,
          c: 1.10846,
          v: 1924075000
        },
        {
          t: 82800,
          o: 1.10851,
          h: 1.10907,
          l: 1.10851,
          c: 1.1089,
          v: 2262960000
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.1089,
          h: 1.1089,
          l: 1.10799,
          c: 1.10817,
          v: 2811280000
        },
        {
          t: 3600,
          o: 1.10817,
          h: 1.10876,
          l: 1.10802,
          c: 1.1083,
          v: 4559170000
        },
        {
          t: 7200,
          o: 1.10832,
          h: 1.10846,
          l: 1.10802,
          c: 1.10819,
          v: 3908790000
        },
        {
          t: 10800,
          o: 1.10819,
          h: 1.10838,
          l: 1.10801,
          c: 1.10811,
          v: 2431960000
        },
        {
          t: 14400,
          o: 1.10812,
          h: 1.10849,
          l: 1.1081,
          c: 1.10817,
          v: 1521690000
        },
        {
          t: 18000,
          o: 1.10818,
          h: 1.10848,
          l: 1.10813,
          c: 1.10844,
          v: 2330080000
        },
        {
          t: 21600,
          o: 1.10842,
          h: 1.10862,
          l: 1.10816,
          c: 1.10824,
          v: 2676330000
        },
        {
          t: 25200,
          o: 1.10823,
          h: 1.10845,
          l: 1.10704,
          c: 1.10747,
          v: 8427900000
        },
        {
          t: 28800,
          o: 1.10747,
          h: 1.10783,
          l: 1.10642,
          c: 1.1066,
          v: 11371630000
        },
        {
          t: 32400,
          o: 1.1066,
          h: 1.10727,
          l: 1.1059,
          c: 1.10726,
          v: 8715189998
        },
        {
          t: 36000,
          o: 1.10726,
          h: 1.10741,
          l: 1.10642,
          c: 1.10648,
          v: 8483800002
        },
        {
          t: 39600,
          o: 1.10647,
          h: 1.10681,
          l: 1.10583,
          c: 1.10626,
          v: 7957359995
        },
        {
          t: 43200,
          o: 1.10626,
          h: 1.10666,
          l: 1.10614,
          c: 1.10658,
          v: 4897790000
        },
        {
          t: 46800,
          o: 1.10657,
          h: 1.10666,
          l: 1.10617,
          c: 1.1063,
          v: 6224210000
        },
        {
          t: 50400,
          o: 1.1063,
          h: 1.10637,
          l: 1.1045,
          c: 1.10473,
          v: 10693710000
        },
        {
          t: 54000,
          o: 1.1047,
          h: 1.10598,
          l: 1.10356,
          c: 1.10556,
          v: 16883580001
        },
        {
          t: 57600,
          o: 1.10556,
          h: 1.10657,
          l: 1.10536,
          c: 1.1062,
          v: 10120609999
        },
        {
          t: 61200,
          o: 1.10621,
          h: 1.10663,
          l: 1.10575,
          c: 1.10585,
          v: 5661250001
        },
        {
          t: 64800,
          o: 1.10585,
          h: 1.10638,
          l: 1.10581,
          c: 1.10613,
          v: 4132920000
        },
        {
          t: 68400,
          o: 1.10616,
          h: 1.10653,
          l: 1.10609,
          c: 1.10634,
          v: 3156480000
        },
        {
          t: 72000,
          o: 1.10633,
          h: 1.1064,
          l: 1.10571,
          c: 1.10581,
          v: 2777847500
        },
        {
          t: 75600,
          o: 1.1058,
          h: 1.10614,
          l: 1.10577,
          c: 1.10593,
          v: 1298465000
        },
        {
          t: 79200,
          o: 1.1059,
          h: 1.10611,
          l: 1.10584,
          c: 1.10591,
          v: 2483930000
        },
        {
          t: 82800,
          o: 1.10591,
          h: 1.10631,
          l: 1.10588,
          c: 1.10623,
          v: 778070000
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.10623,
          h: 1.1063,
          l: 1.10593,
          c: 1.10609,
          v: 1083610000
        },
        {
          t: 3600,
          o: 1.1061,
          h: 1.10641,
          l: 1.10592,
          c: 1.10601,
          v: 2490750000
        },
        {
          t: 7200,
          o: 1.10602,
          h: 1.10604,
          l: 1.10556,
          c: 1.10586,
          v: 2685670000
        },
        {
          t: 10800,
          o: 1.10586,
          h: 1.10598,
          l: 1.10555,
          c: 1.10578,
          v: 2287780000
        },
        {
          t: 14400,
          o: 1.1058,
          h: 1.10609,
          l: 1.10573,
          c: 1.10599,
          v: 1835310000
        },
        {
          t: 18000,
          o: 1.10597,
          h: 1.10633,
          l: 1.10577,
          c: 1.10586,
          v: 2092500000
        },
        {
          t: 21600,
          o: 1.10585,
          h: 1.10637,
          l: 1.10582,
          c: 1.10591,
          v: 3083340000
        },
        {
          t: 25200,
          o: 1.1059,
          h: 1.1059,
          l: 1.10488,
          c: 1.10502,
          v: 6425290000
        },
        {
          t: 28800,
          o: 1.10498,
          h: 1.10593,
          l: 1.10495,
          c: 1.10578,
          v: 9061220000
        },
        {
          t: 32400,
          o: 1.10577,
          h: 1.10611,
          l: 1.10528,
          c: 1.10557,
          v: 9703980001
        },
        {
          t: 36000,
          o: 1.10556,
          h: 1.10571,
          l: 1.10494,
          c: 1.10554,
          v: 7264600000
        },
        {
          t: 39600,
          o: 1.10554,
          h: 1.1058,
          l: 1.10522,
          c: 1.1054,
          v: 5698310000
        },
        {
          t: 43200,
          o: 1.10541,
          h: 1.10541,
          l: 1.1043,
          c: 1.1044,
          v: 6226340000
        },
        {
          t: 46800,
          o: 1.10439,
          h: 1.10507,
          l: 1.10432,
          c: 1.10444,
          v: 8914090000
        },
        {
          t: 50400,
          o: 1.10443,
          h: 1.10467,
          l: 1.10408,
          c: 1.10444,
          v: 10003280007
        },
        {
          t: 54000,
          o: 1.10444,
          h: 1.10483,
          l: 1.10331,
          c: 1.10382,
          v: 10599270000
        },
        {
          t: 57600,
          o: 1.10384,
          h: 1.10431,
          l: 1.10328,
          c: 1.10428,
          v: 7006700000
        },
        {
          t: 61200,
          o: 1.10428,
          h: 1.10452,
          l: 1.10366,
          c: 1.10371,
          v: 5102620000
        },
        {
          t: 64800,
          o: 1.10371,
          h: 1.10446,
          l: 1.10365,
          c: 1.10409,
          v: 2549250000
        },
        {
          t: 68400,
          o: 1.10409,
          h: 1.10444,
          l: 1.10401,
          c: 1.10419,
          v: 1882040000
        },
        {
          t: 72000,
          o: 1.10419,
          h: 1.10447,
          l: 1.1041,
          c: 1.10442,
          v: 3040500000
        },
        {
          t: 75600,
          o: 1.10442,
          h: 1.10457,
          l: 1.10433,
          c: 1.10434,
          v: 1421635000
        },
        {
          t: 79200,
          o: 1.10436,
          h: 1.10452,
          l: 1.10413,
          c: 1.10444,
          v: 2431480000
        },
        {
          t: 82800,
          o: 1.10447,
          h: 1.10459,
          l: 1.10439,
          c: 1.10446,
          v: 829850000
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.10449,
          h: 1.10449,
          l: 1.1042,
          c: 1.10431,
          v: 1487660000
        },
        {
          t: 3600,
          o: 1.1043,
          h: 1.10436,
          l: 1.10397,
          c: 1.10402,
          v: 2463710000
        },
        {
          t: 7200,
          o: 1.10402,
          h: 1.10409,
          l: 1.10339,
          c: 1.10343,
          v: 2384650000
        },
        {
          t: 10800,
          o: 1.10343,
          h: 1.10366,
          l: 1.10336,
          c: 1.10341,
          v: 2843780000
        },
        {
          t: 14400,
          o: 1.1034,
          h: 1.10353,
          l: 1.10336,
          c: 1.10345,
          v: 821940000
        },
        {
          t: 18000,
          o: 1.10345,
          h: 1.10381,
          l: 1.10336,
          c: 1.10378,
          v: 1311710000
        },
        {
          t: 21600,
          o: 1.10378,
          h: 1.10382,
          l: 1.10345,
          c: 1.10358,
          v: 2161610000
        },
        {
          t: 25200,
          o: 1.1036,
          h: 1.10405,
          l: 1.10356,
          c: 1.10368,
          v: 4050990000
        },
        {
          t: 28800,
          o: 1.1037,
          h: 1.10479,
          l: 1.10365,
          c: 1.1042,
          v: 11955220000
        },
        {
          t: 32400,
          o: 1.10419,
          h: 1.10427,
          l: 1.10215,
          c: 1.1026,
          v: 12371690001
        },
        {
          t: 36000,
          o: 1.10258,
          h: 1.10297,
          l: 1.10178,
          c: 1.10192,
          v: 8049160000
        },
        {
          t: 39600,
          o: 1.10191,
          h: 1.10257,
          l: 1.10145,
          c: 1.10219,
          v: 9459260000
        },
        {
          t: 43200,
          o: 1.10218,
          h: 1.10231,
          l: 1.10184,
          c: 1.10223,
          v: 5936880000
        },
        {
          t: 46800,
          o: 1.10222,
          h: 1.10232,
          l: 1.10078,
          c: 1.10122,
          v: 10444520000
        },
        {
          t: 50400,
          o: 1.10123,
          h: 1.10142,
          l: 1.09998,
          c: 1.10025,
          v: 10524100000
        },
        {
          t: 54000,
          o: 1.10027,
          h: 1.10096,
          l: 1.09963,
          c: 1.10021,
          v: 13426430000
        },
        {
          t: 57600,
          o: 1.10021,
          h: 1.10063,
          l: 1.09987,
          c: 1.10061,
          v: 7149609999
        },
        {
          t: 61200,
          o: 1.10062,
          h: 1.10068,
          l: 1.10009,
          c: 1.1001,
          v: 4274950000
        },
        {
          t: 64800,
          o: 1.10011,
          h: 1.1003,
          l: 1.09989,
          c: 1.10022,
          v: 2413970000
        },
        {
          t: 68400,
          o: 1.10023,
          h: 1.10027,
          l: 1.09937,
          c: 1.0996,
          v: 3338800000
        },
        {
          t: 72000,
          o: 1.09959,
          h: 1.09997,
          l: 1.09958,
          c: 1.09973,
          v: 2172070000
        },
        {
          t: 75600,
          o: 1.09976,
          h: 1.09997,
          l: 1.09969,
          c: 1.09989,
          v: 1187721000
        },
        {
          t: 79200,
          o: 1.09989,
          h: 1.10001,
          l: 1.09974,
          c: 1.09995,
          v: 1170440000
        },
        {
          t: 82800,
          o: 1.09994,
          h: 1.10003,
          l: 1.09984,
          c: 1.10003,
          v: 870110000
        }
      ]
    },
    {
      d: 1580947200,
      b: [
        {
          t: 0,
          o: 1.10003,
          h: 1.10005,
          l: 1.09959,
          c: 1.09963,
          v: 1563410000
        },
        {
          t: 3600,
          o: 1.09963,
          h: 1.1,
          l: 1.09943,
          c: 1.09981,
          v: 2410190000
        },
        {
          t: 7200,
          o: 1.09981,
          h: 1.09989,
          l: 1.09964,
          c: 1.09976,
          v: 1414130000
        },
        {
          t: 10800,
          o: 1.09976,
          h: 1.09988,
          l: 1.09962,
          c: 1.09971,
          v: 1058860000
        },
        {
          t: 14400,
          o: 1.09971,
          h: 1.09982,
          l: 1.09942,
          c: 1.09956,
          v: 1973990000
        },
        {
          t: 18000,
          o: 1.09958,
          h: 1.09997,
          l: 1.0994,
          c: 1.09997,
          v: 1608920000
        },
        {
          t: 21600,
          o: 1.09996,
          h: 1.10021,
          l: 1.09961,
          c: 1.10001,
          v: 2547990000
        },
        {
          t: 25200,
          o: 1.10004,
          h: 1.10056,
          l: 1.09977,
          c: 1.10029,
          v: 4900020000
        },
        {
          t: 28800,
          o: 1.10028,
          h: 1.10138,
          l: 1.09939,
          c: 1.09955,
          v: 9232060000
        },
        {
          t: 32400,
          o: 1.09955,
          h: 1.10074,
          l: 1.0995,
          c: 1.0999,
          v: 7393740000
        },
        {
          t: 36000,
          o: 1.09989,
          h: 1.10056,
          l: 1.09988,
          c: 1.10011,
          v: 4688110000
        },
        {
          t: 39600,
          o: 1.10012,
          h: 1.10028,
          l: 1.09955,
          c: 1.10001,
          v: 4682580000
        },
        {
          t: 43200,
          o: 1.10002,
          h: 1.10034,
          l: 1.09972,
          c: 1.10024,
          v: 4781610000
        },
        {
          t: 46800,
          o: 1.10024,
          h: 1.10041,
          l: 1.09988,
          c: 1.10015,
          v: 7020850000
        },
        {
          t: 50400,
          o: 1.10015,
          h: 1.10015,
          l: 1.09882,
          c: 1.09882,
          v: 7516710000
        },
        {
          t: 54000,
          o: 1.09881,
          h: 1.09909,
          l: 1.09644,
          c: 1.09767,
          v: 13848540000
        },
        {
          t: 57600,
          o: 1.09765,
          h: 1.09835,
          l: 1.0973,
          c: 1.09777,
          v: 8717150000
        },
        {
          t: 61200,
          o: 1.09777,
          h: 1.09803,
          l: 1.09744,
          c: 1.0975,
          v: 4663840000
        },
        {
          t: 64800,
          o: 1.0975,
          h: 1.09773,
          l: 1.09717,
          c: 1.09731,
          v: 2207860000
        },
        {
          t: 68400,
          o: 1.09731,
          h: 1.09774,
          l: 1.09707,
          c: 1.09768,
          v: 2831020000
        },
        {
          t: 72000,
          o: 1.0977,
          h: 1.09822,
          l: 1.09766,
          c: 1.09787,
          v: 3045230000
        },
        {
          t: 75600,
          o: 1.09787,
          h: 1.09828,
          l: 1.09785,
          c: 1.09827,
          v: 926870000
        },
        {
          t: 79200,
          o: 1.09827,
          h: 1.09829,
          l: 1.09771,
          c: 1.09807,
          v: 2416680000
        },
        {
          t: 82800,
          o: 1.09807,
          h: 1.09814,
          l: 1.09795,
          c: 1.09806,
          v: 727370000
        }
      ]
    },
    {
      d: 1581033600,
      b: [
        {
          t: 0,
          o: 1.09809,
          h: 1.09817,
          l: 1.09776,
          c: 1.09815,
          v: 1196270000
        },
        {
          t: 3600,
          o: 1.09815,
          h: 1.09831,
          l: 1.09802,
          c: 1.09829,
          v: 4769670000
        },
        {
          t: 7200,
          o: 1.09829,
          h: 1.09846,
          l: 1.09818,
          c: 1.09846,
          v: 3723770000
        },
        {
          t: 10800,
          o: 1.09846,
          h: 1.09849,
          l: 1.09825,
          c: 1.09831,
          v: 2592520000
        },
        {
          t: 14400,
          o: 1.09832,
          h: 1.09832,
          l: 1.09791,
          c: 1.09812,
          v: 2907550000
        },
        {
          t: 18000,
          o: 1.09811,
          h: 1.09833,
          l: 1.09804,
          c: 1.09807,
          v: 2548170000
        },
        {
          t: 21600,
          o: 1.09808,
          h: 1.09824,
          l: 1.09772,
          c: 1.09776,
          v: 4375680000
        },
        {
          t: 25200,
          o: 1.09777,
          h: 1.09783,
          l: 1.09667,
          c: 1.09667,
          v: 9868420000
        },
        {
          t: 28800,
          o: 1.09668,
          h: 1.0971,
          l: 1.09566,
          c: 1.09582,
          v: 9797350000
        },
        {
          t: 32400,
          o: 1.09582,
          h: 1.09646,
          l: 1.09534,
          c: 1.09543,
          v: 7052720000
        },
        {
          t: 36000,
          o: 1.09543,
          h: 1.09568,
          l: 1.09477,
          c: 1.09538,
          v: 10750190043
        },
        {
          t: 39600,
          o: 1.09538,
          h: 1.09579,
          l: 1.09506,
          c: 1.09518,
          v: 5687140000
        },
        {
          t: 43200,
          o: 1.0952,
          h: 1.09647,
          l: 1.09506,
          c: 1.09646,
          v: 4922450000
        },
        {
          t: 46800,
          o: 1.09644,
          h: 1.09708,
          l: 1.09543,
          c: 1.09581,
          v: 11605850000
        },
        {
          t: 50400,
          o: 1.0958,
          h: 1.09734,
          l: 1.09487,
          c: 1.09713,
          v: 13214860000
        },
        {
          t: 54000,
          o: 1.09713,
          h: 1.09763,
          l: 1.0957,
          c: 1.09633,
          v: 13506630000
        },
        {
          t: 57600,
          o: 1.09631,
          h: 1.09653,
          l: 1.09461,
          c: 1.09494,
          v: 7667370000
        },
        {
          t: 61200,
          o: 1.09494,
          h: 1.09498,
          l: 1.09419,
          c: 1.09427,
          v: 3822460000
        },
        {
          t: 64800,
          o: 1.09426,
          h: 1.09492,
          l: 1.09424,
          c: 1.09436,
          v: 3743289995
        },
        {
          t: 68400,
          o: 1.09437,
          h: 1.09488,
          l: 1.09437,
          c: 1.09461,
          v: 3109760000
        },
        {
          t: 72000,
          o: 1.09462,
          h: 1.09479,
          l: 1.09441,
          c: 1.09472,
          v: 1808415000
        },
        {
          t: 75600,
          o: 1.09471,
          h: 1.09475,
          l: 1.09424,
          c: 1.09438,
          v: 1692890000
        }
      ]
    },
    {
      d: 1581206400,
      b: [
        {
          t: 79200,
          o: 1.09439,
          h: 1.09451,
          l: 1.09419,
          c: 1.09435,
          v: 891730000
        },
        {
          t: 82800,
          o: 1.09435,
          h: 1.09507,
          l: 1.09428,
          c: 1.09491,
          v: 2901750000
        }
      ]
    },
    {
      d: 1581292800,
      b: [
        {
          t: 0,
          o: 1.09491,
          h: 1.09529,
          l: 1.0948,
          c: 1.09527,
          v: 2542140000
        },
        {
          t: 3600,
          o: 1.09526,
          h: 1.09532,
          l: 1.09492,
          c: 1.09504,
          v: 2820980000
        },
        {
          t: 7200,
          o: 1.09504,
          h: 1.0954,
          l: 1.09502,
          c: 1.09535,
          v: 2558050000
        },
        {
          t: 10800,
          o: 1.09535,
          h: 1.0954,
          l: 1.095,
          c: 1.09523,
          v: 2480760000
        },
        {
          t: 14400,
          o: 1.09524,
          h: 1.09572,
          l: 1.09518,
          c: 1.09567,
          v: 1314570000
        },
        {
          t: 18000,
          o: 1.09568,
          h: 1.09574,
          l: 1.09541,
          c: 1.09554,
          v: 1641800000
        },
        {
          t: 21600,
          o: 1.09554,
          h: 1.09573,
          l: 1.09513,
          c: 1.09513,
          v: 2284610000
        },
        {
          t: 25200,
          o: 1.09512,
          h: 1.09536,
          l: 1.09451,
          c: 1.09466,
          v: 6111600000
        },
        {
          t: 28800,
          o: 1.09466,
          h: 1.09536,
          l: 1.09447,
          c: 1.09513,
          v: 7206280000
        },
        {
          t: 32400,
          o: 1.09513,
          h: 1.09517,
          l: 1.09462,
          c: 1.09497,
          v: 6189060000
        },
        {
          t: 36000,
          o: 1.09496,
          h: 1.0955,
          l: 1.09478,
          c: 1.09494,
          v: 5732520000
        },
        {
          t: 39600,
          o: 1.09493,
          h: 1.09525,
          l: 1.09451,
          c: 1.09491,
          v: 5136180000
        },
        {
          t: 43200,
          o: 1.09491,
          h: 1.09518,
          l: 1.09473,
          c: 1.09492,
          v: 5609580000
        },
        {
          t: 46800,
          o: 1.09493,
          h: 1.09527,
          l: 1.09298,
          c: 1.09325,
          v: 9200800000
        },
        {
          t: 50400,
          o: 1.09324,
          h: 1.09337,
          l: 1.0927,
          c: 1.09275,
          v: 10916419999
        },
        {
          t: 54000,
          o: 1.09274,
          h: 1.09382,
          l: 1.09192,
          c: 1.09241,
          v: 10376680000
        },
        {
          t: 57600,
          o: 1.0924,
          h: 1.0924,
          l: 1.09091,
          c: 1.0915,
          v: 6907860000
        },
        {
          t: 61200,
          o: 1.09151,
          h: 1.09201,
          l: 1.09102,
          c: 1.09102,
          v: 3997570000
        },
        {
          t: 64800,
          o: 1.09102,
          h: 1.09165,
          l: 1.09093,
          c: 1.09136,
          v: 2765460000
        },
        {
          t: 68400,
          o: 1.09135,
          h: 1.09162,
          l: 1.09124,
          c: 1.09138,
          v: 1738220000
        },
        {
          t: 72000,
          o: 1.09141,
          h: 1.09152,
          l: 1.09076,
          c: 1.09123,
          v: 1945610000
        },
        {
          t: 75600,
          o: 1.09124,
          h: 1.09135,
          l: 1.09096,
          c: 1.09105,
          v: 903195000
        },
        {
          t: 79200,
          o: 1.09105,
          h: 1.09139,
          l: 1.09102,
          c: 1.09107,
          v: 1634322000
        },
        {
          t: 82800,
          o: 1.09107,
          h: 1.09131,
          l: 1.09088,
          c: 1.09101,
          v: 1237690000
        }
      ]
    },
    {
      d: 1581379200,
      b: [
        {
          t: 0,
          o: 1.09103,
          h: 1.09114,
          l: 1.09078,
          c: 1.091,
          v: 1180140000
        },
        {
          t: 3600,
          o: 1.09099,
          h: 1.09152,
          l: 1.09096,
          c: 1.09139,
          v: 3429690000
        },
        {
          t: 7200,
          o: 1.0914,
          h: 1.09162,
          l: 1.09118,
          c: 1.09139,
          v: 1915600000
        },
        {
          t: 10800,
          o: 1.09139,
          h: 1.0917,
          l: 1.09124,
          c: 1.09135,
          v: 1740810000
        },
        {
          t: 14400,
          o: 1.09134,
          h: 1.09136,
          l: 1.09088,
          c: 1.09093,
          v: 1824960000
        },
        {
          t: 18000,
          o: 1.09093,
          h: 1.09128,
          l: 1.09078,
          c: 1.09122,
          v: 2090210000
        },
        {
          t: 21600,
          o: 1.09122,
          h: 1.09122,
          l: 1.09082,
          c: 1.09111,
          v: 2322120000
        },
        {
          t: 25200,
          o: 1.09112,
          h: 1.09143,
          l: 1.09056,
          c: 1.09142,
          v: 5587060000
        },
        {
          t: 28800,
          o: 1.09144,
          h: 1.09152,
          l: 1.09056,
          c: 1.09107,
          v: 6868250000
        },
        {
          t: 32400,
          o: 1.09107,
          h: 1.09158,
          l: 1.0908,
          c: 1.09136,
          v: 6679490000
        },
        {
          t: 36000,
          o: 1.09136,
          h: 1.09194,
          l: 1.09102,
          c: 1.09182,
          v: 5859730000
        },
        {
          t: 39600,
          o: 1.09181,
          h: 1.09206,
          l: 1.09124,
          c: 1.09126,
          v: 5939099999
        },
        {
          t: 43200,
          o: 1.09127,
          h: 1.09162,
          l: 1.0907,
          c: 1.09091,
          v: 7121200001
        },
        {
          t: 46800,
          o: 1.0909,
          h: 1.09097,
          l: 1.08913,
          c: 1.09035,
          v: 13082460000
        },
        {
          t: 50400,
          o: 1.09035,
          h: 1.09228,
          l: 1.09015,
          c: 1.09202,
          v: 11610769999
        },
        {
          t: 54000,
          o: 1.09201,
          h: 1.09248,
          l: 1.09114,
          c: 1.0916,
          v: 10186010000
        },
        {
          t: 57600,
          o: 1.09159,
          h: 1.09233,
          l: 1.09129,
          c: 1.09164,
          v: 2729220000
        },
        {
          t: 61200,
          o: 1.09165,
          h: 1.09241,
          l: 1.09158,
          c: 1.09217,
          v: 1716310000
        },
        {
          t: 64800,
          o: 1.09216,
          h: 1.0922,
          l: 1.0917,
          c: 1.09189,
          v: 1136250000
        },
        {
          t: 68400,
          o: 1.09189,
          h: 1.09231,
          l: 1.09188,
          c: 1.09215,
          v: 855730000
        },
        {
          t: 72000,
          o: 1.09214,
          h: 1.09215,
          l: 1.09181,
          c: 1.09194,
          v: 648250000
        },
        {
          t: 75600,
          o: 1.09183,
          h: 1.09187,
          l: 1.09141,
          c: 1.09143,
          v: 341480000
        },
        {
          t: 79200,
          o: 1.09139,
          h: 1.09209,
          l: 1.09138,
          c: 1.09185,
          v: 869400000
        },
        {
          t: 82800,
          o: 1.09186,
          h: 1.09189,
          l: 1.09172,
          c: 1.09189,
          v: 439500000
        }
      ]
    },
    {
      d: 1581465600,
      b: [
        {
          t: 0,
          o: 1.09187,
          h: 1.0919,
          l: 1.09116,
          c: 1.09129,
          v: 1289300000
        },
        {
          t: 3600,
          o: 1.09128,
          h: 1.09196,
          l: 1.09108,
          c: 1.09181,
          v: 1169950000
        },
        {
          t: 7200,
          o: 1.0918,
          h: 1.09196,
          l: 1.09145,
          c: 1.09159,
          v: 773250000
        },
        {
          t: 10800,
          o: 1.0916,
          h: 1.09177,
          l: 1.09149,
          c: 1.09151,
          v: 471750000
        },
        {
          t: 14400,
          o: 1.09151,
          h: 1.09157,
          l: 1.0912,
          c: 1.09121,
          v: 382420000
        },
        {
          t: 18000,
          o: 1.0912,
          h: 1.09143,
          l: 1.09094,
          c: 1.09105,
          v: 605810000
        },
        {
          t: 21600,
          o: 1.09104,
          h: 1.09104,
          l: 1.09049,
          c: 1.09056,
          v: 744050000
        },
        {
          t: 25200,
          o: 1.09057,
          h: 1.09148,
          l: 1.08972,
          c: 1.09146,
          v: 1476410000
        },
        {
          t: 28800,
          o: 1.09144,
          h: 1.09255,
          l: 1.09115,
          c: 1.09216,
          v: 7888147000
        },
        {
          t: 32400,
          o: 1.09215,
          h: 1.09232,
          l: 1.09114,
          c: 1.09203,
          v: 14680860001
        },
        {
          t: 36000,
          o: 1.09203,
          h: 1.09217,
          l: 1.09152,
          c: 1.09181,
          v: 10440470000
        },
        {
          t: 39600,
          o: 1.09181,
          h: 1.09184,
          l: 1.09096,
          c: 1.09119,
          v: 7216580000
        },
        {
          t: 43200,
          o: 1.09118,
          h: 1.09143,
          l: 1.09097,
          c: 1.09123,
          v: 2256190000
        },
        {
          t: 46800,
          o: 1.09123,
          h: 1.09137,
          l: 1.09046,
          c: 1.09048,
          v: 2593120000
        },
        {
          t: 50400,
          o: 1.09049,
          h: 1.09105,
          l: 1.08992,
          c: 1.09039,
          v: 13841480000
        },
        {
          t: 54000,
          o: 1.09039,
          h: 1.09048,
          l: 1.08861,
          c: 1.08944,
          v: 35614270000
        },
        {
          t: 57600,
          o: 1.08946,
          h: 1.08975,
          l: 1.08893,
          c: 1.08922,
          v: 26787530000
        },
        {
          t: 61200,
          o: 1.08923,
          h: 1.08934,
          l: 1.08767,
          c: 1.08788,
          v: 32382340002
        },
        {
          t: 64800,
          o: 1.08788,
          h: 1.08809,
          l: 1.08758,
          c: 1.08764,
          v: 18495710001
        },
        {
          t: 68400,
          o: 1.08765,
          h: 1.08776,
          l: 1.08654,
          c: 1.08663,
          v: 23887329999
        },
        {
          t: 72000,
          o: 1.08663,
          h: 1.08753,
          l: 1.08663,
          c: 1.08752,
          v: 15201140000
        },
        {
          t: 75600,
          o: 1.08754,
          h: 1.08755,
          l: 1.08712,
          c: 1.08728,
          v: 10608140000
        },
        {
          t: 79200,
          o: 1.08707,
          h: 1.08745,
          l: 1.08707,
          c: 1.08716,
          v: 11710490019
        },
        {
          t: 82800,
          o: 1.08717,
          h: 1.08759,
          l: 1.08695,
          c: 1.08756,
          v: 12051530000
        }
      ]
    },
    {
      d: 1581552000,
      b: [
        {
          t: 0,
          o: 1.08755,
          h: 1.08764,
          l: 1.08712,
          c: 1.08724,
          v: 19390590000
        },
        {
          t: 3600,
          o: 1.08724,
          h: 1.08748,
          l: 1.0871,
          c: 1.08729,
          v: 12009900000
        },
        {
          t: 7200,
          o: 1.08728,
          h: 1.08738,
          l: 1.08697,
          c: 1.087,
          v: 9564590000
        },
        {
          t: 10800,
          o: 1.08699,
          h: 1.08713,
          l: 1.08678,
          c: 1.087,
          v: 9838250000
        },
        {
          t: 14400,
          o: 1.08699,
          h: 1.087,
          l: 1.08652,
          c: 1.0869,
          v: 9556520000
        },
        {
          t: 18000,
          o: 1.08689,
          h: 1.08716,
          l: 1.08675,
          c: 1.08715,
          v: 10036520000
        },
        {
          t: 21600,
          o: 1.08715,
          h: 1.08732,
          l: 1.08695,
          c: 1.08714,
          v: 13692620000
        },
        {
          t: 25200,
          o: 1.08714,
          h: 1.08795,
          l: 1.08708,
          c: 1.08792,
          v: 20169630000
        },
        {
          t: 28800,
          o: 1.08792,
          h: 1.08876,
          l: 1.08755,
          c: 1.08869,
          v: 16237560000
        },
        {
          t: 32400,
          o: 1.08869,
          h: 1.08887,
          l: 1.08815,
          c: 1.08828,
          v: 16596920000
        },
        {
          t: 36000,
          o: 1.08829,
          h: 1.08877,
          l: 1.08738,
          c: 1.08739,
          v: 21074580000
        },
        {
          t: 39600,
          o: 1.08739,
          h: 1.08756,
          l: 1.0856,
          c: 1.08576,
          v: 29362790001
        },
        {
          t: 43200,
          o: 1.08578,
          h: 1.08693,
          l: 1.08531,
          c: 1.08638,
          v: 26352940000
        },
        {
          t: 46800,
          o: 1.08639,
          h: 1.08691,
          l: 1.08532,
          c: 1.08548,
          v: 22694480000
        },
        {
          t: 50400,
          o: 1.08548,
          h: 1.08617,
          l: 1.08476,
          c: 1.08553,
          v: 30109510001
        },
        {
          t: 54000,
          o: 1.08553,
          h: 1.08583,
          l: 1.0834,
          c: 1.08486,
          v: 32436399998
        },
        {
          t: 57600,
          o: 1.08487,
          h: 1.08541,
          l: 1.08473,
          c: 1.08473,
          v: 12783640000
        },
        {
          t: 61200,
          o: 1.08474,
          h: 1.08478,
          l: 1.08389,
          c: 1.08424,
          v: 6710050000
        },
        {
          t: 64800,
          o: 1.08425,
          h: 1.08435,
          l: 1.08389,
          c: 1.0842,
          v: 5440119998
        },
        {
          t: 68400,
          o: 1.08419,
          h: 1.08443,
          l: 1.08406,
          c: 1.08433,
          v: 3193380000
        },
        {
          t: 72000,
          o: 1.08433,
          h: 1.08454,
          l: 1.0841,
          c: 1.08413,
          v: 3018870000
        },
        {
          t: 75600,
          o: 1.08413,
          h: 1.08419,
          l: 1.0838,
          c: 1.08408,
          v: 3021435000
        },
        {
          t: 79200,
          o: 1.08408,
          h: 1.08422,
          l: 1.0838,
          c: 1.084,
          v: 764424600
        },
        {
          t: 82800,
          o: 1.08401,
          h: 1.08418,
          l: 1.08381,
          c: 1.08413,
          v: 2674230000
        }
      ]
    },
    {
      d: 1581638400,
      b: [
        {
          t: 0,
          o: 1.08415,
          h: 1.08416,
          l: 1.08271,
          c: 1.0838,
          v: 5577730000
        },
        {
          t: 3600,
          o: 1.08381,
          h: 1.08383,
          l: 1.08332,
          c: 1.08361,
          v: 5636030000
        },
        {
          t: 7200,
          o: 1.0836,
          h: 1.08373,
          l: 1.08343,
          c: 1.08369,
          v: 4620170000
        },
        {
          t: 10800,
          o: 1.08369,
          h: 1.08369,
          l: 1.08308,
          c: 1.0831,
          v: 4914130000
        },
        {
          t: 14400,
          o: 1.0831,
          h: 1.0833,
          l: 1.08309,
          c: 1.0833,
          v: 2773990000
        },
        {
          t: 18000,
          o: 1.08331,
          h: 1.08372,
          l: 1.0833,
          c: 1.0837,
          v: 2139330000
        },
        {
          t: 21600,
          o: 1.0837,
          h: 1.08389,
          l: 1.08342,
          c: 1.08353,
          v: 2975300000
        },
        {
          t: 25200,
          o: 1.08353,
          h: 1.08432,
          l: 1.08348,
          c: 1.0841,
          v: 5024750000
        },
        {
          t: 28800,
          o: 1.08412,
          h: 1.08433,
          l: 1.08322,
          c: 1.0843,
          v: 5840880000
        },
        {
          t: 32400,
          o: 1.08429,
          h: 1.08501,
          l: 1.084,
          c: 1.08448,
          v: 6918590000
        },
        {
          t: 36000,
          o: 1.08449,
          h: 1.08492,
          l: 1.0838,
          c: 1.08389,
          v: 5577260000
        },
        {
          t: 39600,
          o: 1.0839,
          h: 1.08447,
          l: 1.0837,
          c: 1.0837,
          v: 5458630000
        },
        {
          t: 43200,
          o: 1.0837,
          h: 1.08451,
          l: 1.08324,
          c: 1.08398,
          v: 6950610001
        },
        {
          t: 46800,
          o: 1.084,
          h: 1.08596,
          l: 1.08358,
          c: 1.08551,
          v: 12253510000
        },
        {
          t: 50400,
          o: 1.08551,
          h: 1.08611,
          l: 1.08512,
          c: 1.08562,
          v: 9235450000
        },
        {
          t: 54000,
          o: 1.08566,
          h: 1.08591,
          l: 1.08437,
          c: 1.08457,
          v: 9399490000
        },
        {
          t: 57600,
          o: 1.08458,
          h: 1.08467,
          l: 1.08371,
          c: 1.08392,
          v: 8381120001
        },
        {
          t: 61200,
          o: 1.08391,
          h: 1.08436,
          l: 1.08328,
          c: 1.08433,
          v: 4536490000
        },
        {
          t: 64800,
          o: 1.08433,
          h: 1.08463,
          l: 1.08418,
          c: 1.08436,
          v: 2804330000
        },
        {
          t: 68400,
          o: 1.08436,
          h: 1.08455,
          l: 1.08388,
          c: 1.08394,
          v: 2873510000
        },
        {
          t: 72000,
          o: 1.08394,
          h: 1.08412,
          l: 1.08363,
          c: 1.08372,
          v: 2537060000
        },
        {
          t: 75600,
          o: 1.08373,
          h: 1.08389,
          l: 1.08294,
          c: 1.08299,
          v: 1939825000
        }
      ]
    },
    {
      d: 1581811200,
      b: [
        {
          t: 79200,
          o: 1.08406,
          h: 1.08415,
          l: 1.08355,
          c: 1.08384,
          v: 1936725000
        },
        {
          t: 82800,
          o: 1.0839,
          h: 1.08416,
          l: 1.08359,
          c: 1.08411,
          v: 1529240000
        }
      ]
    },
    {
      d: 1581897600,
      b: [
        {
          t: 0,
          o: 1.08411,
          h: 1.08411,
          l: 1.0835,
          c: 1.08358,
          v: 1865520000
        },
        {
          t: 3600,
          o: 1.08359,
          h: 1.08379,
          l: 1.08334,
          c: 1.08365,
          v: 1958190000
        },
        {
          t: 7200,
          o: 1.08366,
          h: 1.08402,
          l: 1.0836,
          c: 1.08392,
          v: 1450280000
        },
        {
          t: 10800,
          o: 1.08391,
          h: 1.08392,
          l: 1.08372,
          c: 1.08384,
          v: 1165590000
        },
        {
          t: 14400,
          o: 1.08385,
          h: 1.08407,
          l: 1.08375,
          c: 1.08376,
          v: 1270310000
        },
        {
          t: 18000,
          o: 1.08379,
          h: 1.08412,
          l: 1.08379,
          c: 1.08395,
          v: 1177580000
        },
        {
          t: 21600,
          o: 1.08396,
          h: 1.08404,
          l: 1.08339,
          c: 1.08351,
          v: 1614840000
        },
        {
          t: 25200,
          o: 1.08351,
          h: 1.08422,
          l: 1.08351,
          c: 1.08406,
          v: 9577980106
        },
        {
          t: 28800,
          o: 1.08407,
          h: 1.08462,
          l: 1.08372,
          c: 1.08462,
          v: 5676590000
        },
        {
          t: 32400,
          o: 1.08462,
          h: 1.08509,
          l: 1.08424,
          c: 1.08441,
          v: 6731450000
        },
        {
          t: 36000,
          o: 1.08442,
          h: 1.08473,
          l: 1.08425,
          c: 1.08468,
          v: 4224010000
        },
        {
          t: 39600,
          o: 1.08468,
          h: 1.0848,
          l: 1.08359,
          c: 1.08391,
          v: 4251830000
        },
        {
          t: 43200,
          o: 1.0839,
          h: 1.08418,
          l: 1.08352,
          c: 1.08362,
          v: 3986900000
        },
        {
          t: 46800,
          o: 1.08362,
          h: 1.08407,
          l: 1.0832,
          c: 1.0836,
          v: 3765770000
        },
        {
          t: 50400,
          o: 1.08359,
          h: 1.08413,
          l: 1.08349,
          c: 1.0839,
          v: 3642740000
        },
        {
          t: 54000,
          o: 1.08389,
          h: 1.08399,
          l: 1.08293,
          c: 1.08296,
          v: 5737110000
        },
        {
          t: 57600,
          o: 1.08295,
          h: 1.08379,
          l: 1.08291,
          c: 1.08374,
          v: 3949330000
        },
        {
          t: 61200,
          o: 1.08374,
          h: 1.08399,
          l: 1.08318,
          c: 1.0832,
          v: 3022760000
        },
        {
          t: 64800,
          o: 1.0832,
          h: 1.08349,
          l: 1.083,
          c: 1.08327,
          v: 1640110000
        },
        {
          t: 68400,
          o: 1.08327,
          h: 1.08355,
          l: 1.08309,
          c: 1.08337,
          v: 1401390000
        },
        {
          t: 72000,
          o: 1.08337,
          h: 1.08346,
          l: 1.08317,
          c: 1.08334,
          v: 1396935000
        },
        {
          t: 75600,
          o: 1.08333,
          h: 1.08371,
          l: 1.08322,
          c: 1.08345,
          v: 1208449900
        },
        {
          t: 79200,
          o: 1.08343,
          h: 1.08373,
          l: 1.08322,
          c: 1.08366,
          v: 2317860000
        },
        {
          t: 82800,
          o: 1.08367,
          h: 1.08371,
          l: 1.0835,
          c: 1.08366,
          v: 1799340000
        }
      ]
    },
    {
      d: 1581984000,
      b: [
        {
          t: 0,
          o: 1.08365,
          h: 1.08367,
          l: 1.0825,
          c: 1.08258,
          v: 4133460000
        },
        {
          t: 3600,
          o: 1.08259,
          h: 1.08287,
          l: 1.08229,
          c: 1.08276,
          v: 3758780000
        },
        {
          t: 7200,
          o: 1.08276,
          h: 1.08291,
          l: 1.08234,
          c: 1.08282,
          v: 3303070000
        },
        {
          t: 10800,
          o: 1.08281,
          h: 1.08339,
          l: 1.08279,
          c: 1.08324,
          v: 2330880000
        },
        {
          t: 14400,
          o: 1.08324,
          h: 1.08329,
          l: 1.08299,
          c: 1.08299,
          v: 1505930000
        },
        {
          t: 18000,
          o: 1.083,
          h: 1.08322,
          l: 1.083,
          c: 1.08311,
          v: 2416170000
        },
        {
          t: 21600,
          o: 1.08313,
          h: 1.08367,
          l: 1.08284,
          c: 1.083,
          v: 3701150000
        },
        {
          t: 25200,
          o: 1.08299,
          h: 1.08372,
          l: 1.08297,
          c: 1.0833,
          v: 6973520000
        },
        {
          t: 28800,
          o: 1.0833,
          h: 1.08351,
          l: 1.08249,
          c: 1.08263,
          v: 9969730000
        },
        {
          t: 32400,
          o: 1.08264,
          h: 1.08313,
          l: 1.08246,
          c: 1.08272,
          v: 7811750000
        },
        {
          t: 36000,
          o: 1.08273,
          h: 1.08367,
          l: 1.08212,
          c: 1.08267,
          v: 11988650000
        },
        {
          t: 39600,
          o: 1.08268,
          h: 1.08357,
          l: 1.08244,
          c: 1.08245,
          v: 8164050000
        },
        {
          t: 43200,
          o: 1.08245,
          h: 1.08249,
          l: 1.08124,
          c: 1.08143,
          v: 8001970000
        },
        {
          t: 46800,
          o: 1.08143,
          h: 1.08168,
          l: 1.07933,
          c: 1.08021,
          v: 10230600000
        },
        {
          t: 50400,
          o: 1.08023,
          h: 1.0808,
          l: 1.07854,
          c: 1.08078,
          v: 12106509999
        },
        {
          t: 54000,
          o: 1.08077,
          h: 1.08258,
          l: 1.08076,
          c: 1.08227,
          v: 12996199999
        },
        {
          t: 57600,
          o: 1.08227,
          h: 1.08241,
          l: 1.0812,
          c: 1.08146,
          v: 6206770000
        },
        {
          t: 61200,
          o: 1.08147,
          h: 1.08147,
          l: 1.08026,
          c: 1.08041,
          v: 3726410000
        },
        {
          t: 64800,
          o: 1.08041,
          h: 1.08042,
          l: 1.07994,
          c: 1.08,
          v: 2826340000
        },
        {
          t: 68400,
          o: 1.07999,
          h: 1.08017,
          l: 1.07929,
          c: 1.07939,
          v: 3140410000
        },
        {
          t: 72000,
          o: 1.07938,
          h: 1.07968,
          l: 1.07908,
          c: 1.07958,
          v: 2238737000
        },
        {
          t: 75600,
          o: 1.07957,
          h: 1.07959,
          l: 1.07897,
          c: 1.07907,
          v: 1148725000
        },
        {
          t: 79200,
          o: 1.07906,
          h: 1.07959,
          l: 1.07904,
          c: 1.07942,
          v: 1661860000
        },
        {
          t: 82800,
          o: 1.07943,
          h: 1.07971,
          l: 1.07925,
          c: 1.07949,
          v: 1246970000
        }
      ]
    },
    {
      d: 1582070400,
      b: [
        {
          t: 0,
          o: 1.07949,
          h: 1.07994,
          l: 1.07927,
          c: 1.07979,
          v: 1400540000
        },
        {
          t: 3600,
          o: 1.07981,
          h: 1.08037,
          l: 1.07971,
          c: 1.0802,
          v: 2607640000
        },
        {
          t: 7200,
          o: 1.0802,
          h: 1.08028,
          l: 1.07984,
          c: 1.07989,
          v: 2281560000
        },
        {
          t: 10800,
          o: 1.07988,
          h: 1.08017,
          l: 1.07984,
          c: 1.07988,
          v: 1398350000
        },
        {
          t: 14400,
          o: 1.07989,
          h: 1.07991,
          l: 1.07957,
          c: 1.07972,
          v: 1332100000
        },
        {
          t: 18000,
          o: 1.07972,
          h: 1.07975,
          l: 1.07943,
          c: 1.07954,
          v: 1414230000
        },
        {
          t: 21600,
          o: 1.07953,
          h: 1.07987,
          l: 1.07913,
          c: 1.07981,
          v: 2136180000
        },
        {
          t: 25200,
          o: 1.07981,
          h: 1.08045,
          l: 1.07963,
          c: 1.08018,
          v: 4267510000
        },
        {
          t: 28800,
          o: 1.0802,
          h: 1.08071,
          l: 1.07964,
          c: 1.08038,
          v: 7019260000
        },
        {
          t: 32400,
          o: 1.08038,
          h: 1.08079,
          l: 1.07992,
          c: 1.08031,
          v: 7306910000
        },
        {
          t: 36000,
          o: 1.0803,
          h: 1.08059,
          l: 1.07987,
          c: 1.08005,
          v: 5542900001
        },
        {
          t: 39600,
          o: 1.08005,
          h: 1.08017,
          l: 1.07846,
          c: 1.07915,
          v: 7088720000
        },
        {
          t: 43200,
          o: 1.07915,
          h: 1.08036,
          l: 1.07907,
          c: 1.08008,
          v: 6183610000
        },
        {
          t: 46800,
          o: 1.08008,
          h: 1.08011,
          l: 1.079,
          c: 1.07934,
          v: 8409820000
        },
        {
          t: 50400,
          o: 1.07934,
          h: 1.07937,
          l: 1.07834,
          c: 1.0787,
          v: 10907960000
        },
        {
          t: 54000,
          o: 1.07871,
          h: 1.07948,
          l: 1.07822,
          c: 1.07912,
          v: 9954580000
        },
        {
          t: 57600,
          o: 1.07912,
          h: 1.07987,
          l: 1.0787,
          c: 1.07933,
          v: 8387210000
        },
        {
          t: 61200,
          o: 1.07932,
          h: 1.07958,
          l: 1.0786,
          c: 1.07918,
          v: 4385740000
        },
        {
          t: 64800,
          o: 1.07918,
          h: 1.07986,
          l: 1.07901,
          c: 1.07983,
          v: 2344550000
        },
        {
          t: 68400,
          o: 1.07982,
          h: 1.08008,
          l: 1.07925,
          c: 1.07963,
          v: 4256830000
        },
        {
          t: 72000,
          o: 1.07963,
          h: 1.08101,
          l: 1.07956,
          c: 1.081,
          v: 3425535400
        },
        {
          t: 75600,
          o: 1.08102,
          h: 1.08115,
          l: 1.08022,
          c: 1.08022,
          v: 1475650000
        },
        {
          t: 79200,
          o: 1.08022,
          h: 1.08091,
          l: 1.08022,
          c: 1.08078,
          v: 933481500
        },
        {
          t: 82800,
          o: 1.08078,
          h: 1.08109,
          l: 1.08069,
          c: 1.08096,
          v: 691190000
        }
      ]
    },
    {
      d: 1582156800,
      b: [
        {
          t: 0,
          o: 1.08095,
          h: 1.08152,
          l: 1.08045,
          c: 1.08111,
          v: 3061970000
        },
        {
          t: 3600,
          o: 1.08112,
          h: 1.08132,
          l: 1.07976,
          c: 1.08,
          v: 2763360000
        },
        {
          t: 7200,
          o: 1.08001,
          h: 1.08021,
          l: 1.07924,
          c: 1.07926,
          v: 2896980000
        },
        {
          t: 10800,
          o: 1.07927,
          h: 1.08001,
          l: 1.07916,
          c: 1.07969,
          v: 2223620000
        },
        {
          t: 14400,
          o: 1.07971,
          h: 1.07985,
          l: 1.07959,
          c: 1.07985,
          v: 1253750000
        },
        {
          t: 18000,
          o: 1.07987,
          h: 1.0799,
          l: 1.07958,
          c: 1.07965,
          v: 1093120000
        },
        {
          t: 21600,
          o: 1.07967,
          h: 1.07972,
          l: 1.0787,
          c: 1.07881,
          v: 2900580000
        },
        {
          t: 25200,
          o: 1.07883,
          h: 1.07927,
          l: 1.07778,
          c: 1.07905,
          v: 6360020000
        },
        {
          t: 28800,
          o: 1.07906,
          h: 1.07979,
          l: 1.07848,
          c: 1.07968,
          v: 5276080000
        },
        {
          t: 32400,
          o: 1.07968,
          h: 1.08061,
          l: 1.07939,
          c: 1.07997,
          v: 5412070000
        },
        {
          t: 36000,
          o: 1.07996,
          h: 1.08016,
          l: 1.07902,
          c: 1.07923,
          v: 5726750006
        },
        {
          t: 39600,
          o: 1.07923,
          h: 1.07974,
          l: 1.07874,
          c: 1.07963,
          v: 5826430000
        },
        {
          t: 43200,
          o: 1.07964,
          h: 1.07972,
          l: 1.07858,
          c: 1.07881,
          v: 6453110000
        },
        {
          t: 46800,
          o: 1.07881,
          h: 1.08049,
          l: 1.07877,
          c: 1.08045,
          v: 6895130000
        },
        {
          t: 50400,
          o: 1.08044,
          h: 1.08211,
          l: 1.08036,
          c: 1.08168,
          v: 8117790000
        },
        {
          t: 54000,
          o: 1.08169,
          h: 1.08197,
          l: 1.08003,
          c: 1.08023,
          v: 8982919994
        },
        {
          t: 57600,
          o: 1.08024,
          h: 1.08075,
          l: 1.07829,
          c: 1.07966,
          v: 9665200001
        },
        {
          t: 61200,
          o: 1.07967,
          h: 1.08026,
          l: 1.07928,
          c: 1.0793,
          v: 6898050000
        },
        {
          t: 64800,
          o: 1.0793,
          h: 1.07971,
          l: 1.07908,
          c: 1.07935,
          v: 8766200000
        },
        {
          t: 68400,
          o: 1.07936,
          h: 1.07948,
          l: 1.07864,
          c: 1.07894,
          v: 8877090000
        },
        {
          t: 72000,
          o: 1.07895,
          h: 1.07905,
          l: 1.07848,
          c: 1.07896,
          v: 15249300000
        },
        {
          t: 75600,
          o: 1.07896,
          h: 1.07896,
          l: 1.07828,
          c: 1.07838,
          v: 16478250000
        },
        {
          t: 79200,
          o: 1.07843,
          h: 1.07883,
          l: 1.07843,
          c: 1.07868,
          v: 6827549992
        },
        {
          t: 82800,
          o: 1.07866,
          h: 1.07891,
          l: 1.07849,
          c: 1.07875,
          v: 6009280000
        }
      ]
    },
    {
      d: 1582243200,
      b: [
        {
          t: 0,
          o: 1.07875,
          h: 1.07916,
          l: 1.07871,
          c: 1.07902,
          v: 10558719998
        },
        {
          t: 3600,
          o: 1.07902,
          h: 1.07936,
          l: 1.07896,
          c: 1.07927,
          v: 2726930000
        },
        {
          t: 7200,
          o: 1.07927,
          h: 1.07935,
          l: 1.07876,
          c: 1.07897,
          v: 3557269999
        },
        {
          t: 10800,
          o: 1.07897,
          h: 1.07923,
          l: 1.0789,
          c: 1.07902,
          v: 2175990000
        },
        {
          t: 14400,
          o: 1.07902,
          h: 1.07948,
          l: 1.07892,
          c: 1.07946,
          v: 2305920000
        },
        {
          t: 18000,
          o: 1.07946,
          h: 1.07947,
          l: 1.07919,
          c: 1.0793,
          v: 1541860000
        },
        {
          t: 21600,
          o: 1.07929,
          h: 1.07946,
          l: 1.07894,
          c: 1.07903,
          v: 2144880000
        },
        {
          t: 25200,
          o: 1.07903,
          h: 1.07985,
          l: 1.07898,
          c: 1.07961,
          v: 25086580001
        },
        {
          t: 28800,
          o: 1.0796,
          h: 1.082,
          l: 1.07902,
          c: 1.08141,
          v: 46037110008
        },
        {
          t: 32400,
          o: 1.08141,
          h: 1.08196,
          l: 1.08092,
          c: 1.08097,
          v: 9218779999
        },
        {
          t: 36000,
          o: 1.08098,
          h: 1.08106,
          l: 1.07961,
          c: 1.07997,
          v: 6988420000
        },
        {
          t: 39600,
          o: 1.07997,
          h: 1.08066,
          l: 1.07953,
          c: 1.08061,
          v: 6929870000
        },
        {
          t: 43200,
          o: 1.08061,
          h: 1.08061,
          l: 1.07962,
          c: 1.07966,
          v: 7611909998
        },
        {
          t: 46800,
          o: 1.07965,
          h: 1.08123,
          l: 1.07965,
          c: 1.08092,
          v: 8021740000
        },
        {
          t: 50400,
          o: 1.08092,
          h: 1.08459,
          l: 1.08011,
          c: 1.08371,
          v: 13625980003
        },
        {
          t: 54000,
          o: 1.0837,
          h: 1.08634,
          l: 1.0837,
          c: 1.08526,
          v: 16036729999
        },
        {
          t: 57600,
          o: 1.08526,
          h: 1.08635,
          l: 1.08487,
          c: 1.08543,
          v: 7926310000
        },
        {
          t: 61200,
          o: 1.08542,
          h: 1.08608,
          l: 1.08538,
          c: 1.08574,
          v: 3892200000
        },
        {
          t: 64800,
          o: 1.08574,
          h: 1.08616,
          l: 1.08541,
          c: 1.08562,
          v: 3319970000
        },
        {
          t: 68400,
          o: 1.08564,
          h: 1.0858,
          l: 1.08527,
          c: 1.08578,
          v: 3547339999
        },
        {
          t: 72000,
          o: 1.08576,
          h: 1.08576,
          l: 1.08475,
          c: 1.08491,
          v: 2523560000
        },
        {
          t: 75600,
          o: 1.08489,
          h: 1.08502,
          l: 1.08456,
          c: 1.08456,
          v: 1887950000
        }
      ]
    },
    {
      d: 1582416000,
      b: [
        {
          t: 79200,
          o: 1.08145,
          h: 1.08345,
          l: 1.08132,
          c: 1.08296,
          v: 3208478900
        },
        {
          t: 82800,
          o: 1.08297,
          h: 1.08419,
          l: 1.08295,
          c: 1.08384,
          v: 4482060000
        }
      ]
    },
    {
      d: 1582502400,
      b: [
        {
          t: 0,
          o: 1.08385,
          h: 1.08423,
          l: 1.08286,
          c: 1.08312,
          v: 5021380000
        },
        {
          t: 3600,
          o: 1.08313,
          h: 1.08321,
          l: 1.08244,
          c: 1.08281,
          v: 4342880000
        },
        {
          t: 7200,
          o: 1.08282,
          h: 1.08298,
          l: 1.08226,
          c: 1.08226,
          v: 3081570000
        },
        {
          t: 10800,
          o: 1.08228,
          h: 1.08243,
          l: 1.08215,
          c: 1.08221,
          v: 3062580000
        },
        {
          t: 14400,
          o: 1.0822,
          h: 1.08226,
          l: 1.0819,
          c: 1.08211,
          v: 1762230000
        },
        {
          t: 18000,
          o: 1.0821,
          h: 1.08218,
          l: 1.08147,
          c: 1.08149,
          v: 2188580000
        },
        {
          t: 21600,
          o: 1.08149,
          h: 1.08206,
          l: 1.08149,
          c: 1.08163,
          v: 3393580000
        },
        {
          t: 25200,
          o: 1.08165,
          h: 1.08195,
          l: 1.0805,
          c: 1.08171,
          v: 9961420000
        },
        {
          t: 28800,
          o: 1.08171,
          h: 1.08284,
          l: 1.08153,
          c: 1.08265,
          v: 12455290000
        },
        {
          t: 32400,
          o: 1.0827,
          h: 1.08418,
          l: 1.08138,
          c: 1.08156,
          v: 13579190002
        },
        {
          t: 36000,
          o: 1.08156,
          h: 1.08258,
          l: 1.08129,
          c: 1.08158,
          v: 8897350000
        },
        {
          t: 39600,
          o: 1.08157,
          h: 1.08252,
          l: 1.08135,
          c: 1.08207,
          v: 8312690000
        },
        {
          t: 43200,
          o: 1.08208,
          h: 1.08281,
          l: 1.08161,
          c: 1.08182,
          v: 10333010002
        },
        {
          t: 46800,
          o: 1.08182,
          h: 1.08221,
          l: 1.0813,
          c: 1.08146,
          v: 9695830000
        },
        {
          t: 50400,
          o: 1.08146,
          h: 1.08526,
          l: 1.0814,
          c: 1.0849,
          v: 16329910000
        },
        {
          t: 54000,
          o: 1.08492,
          h: 1.08608,
          l: 1.08462,
          c: 1.08558,
          v: 16583380003
        },
        {
          t: 57600,
          o: 1.08559,
          h: 1.08637,
          l: 1.08458,
          c: 1.08559,
          v: 11196490000
        },
        {
          t: 61200,
          o: 1.08561,
          h: 1.08718,
          l: 1.0853,
          c: 1.08707,
          v: 9013729999
        },
        {
          t: 64800,
          o: 1.08708,
          h: 1.0872,
          l: 1.08608,
          c: 1.08638,
          v: 4468620000
        },
        {
          t: 68400,
          o: 1.08638,
          h: 1.0864,
          l: 1.08414,
          c: 1.08431,
          v: 6407840000
        },
        {
          t: 72000,
          o: 1.08431,
          h: 1.08569,
          l: 1.08414,
          c: 1.08522,
          v: 6236540000
        },
        {
          t: 75600,
          o: 1.08523,
          h: 1.08556,
          l: 1.08501,
          c: 1.08519,
          v: 2164100000
        },
        {
          t: 79200,
          o: 1.08519,
          h: 1.08557,
          l: 1.08502,
          c: 1.08518,
          v: 2631120000
        },
        {
          t: 82800,
          o: 1.08517,
          h: 1.08539,
          l: 1.08483,
          c: 1.08485,
          v: 3236320000
        }
      ]
    },
    {
      d: 1582588800,
      b: [
        {
          t: 0,
          o: 1.08485,
          h: 1.08529,
          l: 1.08481,
          c: 1.08517,
          v: 4218430000
        },
        {
          t: 3600,
          o: 1.08517,
          h: 1.08597,
          l: 1.0845,
          c: 1.08584,
          v: 5853610003
        },
        {
          t: 7200,
          o: 1.08585,
          h: 1.08646,
          l: 1.08582,
          c: 1.08634,
          v: 4378810000
        },
        {
          t: 10800,
          o: 1.08633,
          h: 1.08675,
          l: 1.08627,
          c: 1.08628,
          v: 3240500000
        },
        {
          t: 14400,
          o: 1.08628,
          h: 1.08628,
          l: 1.08603,
          c: 1.08608,
          v: 1809640000
        },
        {
          t: 18000,
          o: 1.08608,
          h: 1.08608,
          l: 1.08507,
          c: 1.0851,
          v: 2570930000
        },
        {
          t: 21600,
          o: 1.0851,
          h: 1.08575,
          l: 1.08493,
          c: 1.08518,
          v: 5321990000
        },
        {
          t: 25200,
          o: 1.08521,
          h: 1.08589,
          l: 1.08475,
          c: 1.08533,
          v: 8648430000
        },
        {
          t: 28800,
          o: 1.08535,
          h: 1.08659,
          l: 1.08501,
          c: 1.08634,
          v: 11215990000
        },
        {
          t: 32400,
          o: 1.08636,
          h: 1.08659,
          l: 1.08486,
          c: 1.08505,
          v: 11184800000
        },
        {
          t: 36000,
          o: 1.08504,
          h: 1.08505,
          l: 1.08348,
          c: 1.0838,
          v: 10496300001
        },
        {
          t: 39600,
          o: 1.0838,
          h: 1.08406,
          l: 1.083,
          c: 1.08366,
          v: 9719560000
        },
        {
          t: 43200,
          o: 1.08367,
          h: 1.08382,
          l: 1.083,
          c: 1.08361,
          v: 9909620000
        },
        {
          t: 46800,
          o: 1.08362,
          h: 1.08496,
          l: 1.08357,
          c: 1.08408,
          v: 10587360000
        },
        {
          t: 50400,
          o: 1.08408,
          h: 1.08458,
          l: 1.08319,
          c: 1.08359,
          v: 14698390000
        },
        {
          t: 54000,
          o: 1.08361,
          h: 1.08666,
          l: 1.08334,
          c: 1.08647,
          v: 18974969999
        },
        {
          t: 57600,
          o: 1.08648,
          h: 1.08825,
          l: 1.08624,
          c: 1.08681,
          v: 12813760000
        },
        {
          t: 61200,
          o: 1.08682,
          h: 1.08826,
          l: 1.08664,
          c: 1.08826,
          v: 7774430000
        },
        {
          t: 64800,
          o: 1.08827,
          h: 1.08827,
          l: 1.08719,
          c: 1.08768,
          v: 6903680000
        },
        {
          t: 68400,
          o: 1.08769,
          h: 1.08902,
          l: 1.08731,
          c: 1.08815,
          v: 9442290000
        },
        {
          t: 72000,
          o: 1.08814,
          h: 1.08863,
          l: 1.08754,
          c: 1.08823,
          v: 8026421000
        },
        {
          t: 75600,
          o: 1.08823,
          h: 1.08863,
          l: 1.08798,
          c: 1.08806,
          v: 2379989200
        },
        {
          t: 79200,
          o: 1.08806,
          h: 1.08829,
          l: 1.08769,
          c: 1.08809,
          v: 1421629800
        },
        {
          t: 82800,
          o: 1.08809,
          h: 1.0881,
          l: 1.08766,
          c: 1.08797,
          v: 1626560000
        }
      ]
    },
    {
      d: 1582675200,
      b: [
        {
          t: 0,
          o: 1.08796,
          h: 1.08796,
          l: 1.08673,
          c: 1.08697,
          v: 3507420000
        },
        {
          t: 3600,
          o: 1.08697,
          h: 1.08746,
          l: 1.08691,
          c: 1.08737,
          v: 4698730000
        },
        {
          t: 7200,
          o: 1.08738,
          h: 1.08741,
          l: 1.08624,
          c: 1.08682,
          v: 5368800000
        },
        {
          t: 10800,
          o: 1.08682,
          h: 1.08703,
          l: 1.0865,
          c: 1.08665,
          v: 3566430000
        },
        {
          t: 14400,
          o: 1.08665,
          h: 1.08689,
          l: 1.08657,
          c: 1.08674,
          v: 3177420000
        },
        {
          t: 18000,
          o: 1.08673,
          h: 1.08783,
          l: 1.08673,
          c: 1.08759,
          v: 4357130000
        },
        {
          t: 21600,
          o: 1.08761,
          h: 1.08775,
          l: 1.08703,
          c: 1.08745,
          v: 5832270000
        },
        {
          t: 25200,
          o: 1.08746,
          h: 1.08756,
          l: 1.08654,
          c: 1.0871,
          v: 9527470000
        },
        {
          t: 28800,
          o: 1.0871,
          h: 1.08976,
          l: 1.0871,
          c: 1.08918,
          v: 12278500000
        },
        {
          t: 32400,
          o: 1.08918,
          h: 1.08967,
          l: 1.08844,
          c: 1.0889,
          v: 12885089999
        },
        {
          t: 36000,
          o: 1.08889,
          h: 1.089,
          l: 1.0873,
          c: 1.08865,
          v: 10896460000
        },
        {
          t: 39600,
          o: 1.08865,
          h: 1.08896,
          l: 1.08734,
          c: 1.08844,
          v: 9560090000
        },
        {
          t: 43200,
          o: 1.08844,
          h: 1.09086,
          l: 1.08687,
          c: 1.08691,
          v: 15534020000
        },
        {
          t: 46800,
          o: 1.08692,
          h: 1.08755,
          l: 1.08562,
          c: 1.08654,
          v: 14704089999
        },
        {
          t: 50400,
          o: 1.08656,
          h: 1.08747,
          l: 1.08549,
          c: 1.08597,
          v: 15631560001
        },
        {
          t: 54000,
          o: 1.08597,
          h: 1.08753,
          l: 1.08571,
          c: 1.08666,
          v: 12642280002
        },
        {
          t: 57600,
          o: 1.08666,
          h: 1.08915,
          l: 1.0864,
          c: 1.08864,
          v: 11396560000
        },
        {
          t: 61200,
          o: 1.08863,
          h: 1.08942,
          l: 1.08781,
          c: 1.08786,
          v: 7140830000
        },
        {
          t: 64800,
          o: 1.08786,
          h: 1.08906,
          l: 1.08747,
          c: 1.08814,
          v: 7696850000
        },
        {
          t: 68400,
          o: 1.08816,
          h: 1.08998,
          l: 1.08795,
          c: 1.08995,
          v: 7546710000
        },
        {
          t: 72000,
          o: 1.08994,
          h: 1.08994,
          l: 1.08831,
          c: 1.08846,
          v: 5359595000
        },
        {
          t: 75600,
          o: 1.08846,
          h: 1.08856,
          l: 1.08768,
          c: 1.08813,
          v: 1999986004
        },
        {
          t: 79200,
          o: 1.08812,
          h: 1.0886,
          l: 1.08769,
          c: 1.08823,
          v: 824045004
        },
        {
          t: 82800,
          o: 1.08824,
          h: 1.08864,
          l: 1.08795,
          c: 1.08864,
          v: 2084640000
        }
      ]
    },
    {
      d: 1582761600,
      b: [
        {
          t: 0,
          o: 1.08865,
          h: 1.0913,
          l: 1.08848,
          c: 1.08979,
          v: 7213500000
        },
        {
          t: 3600,
          o: 1.0898,
          h: 1.09016,
          l: 1.08952,
          c: 1.09011,
          v: 5925340000
        },
        {
          t: 7200,
          o: 1.09011,
          h: 1.09144,
          l: 1.0901,
          c: 1.09103,
          v: 5124780000
        },
        {
          t: 10800,
          o: 1.09103,
          h: 1.09103,
          l: 1.09041,
          c: 1.09053,
          v: 3503330000
        },
        {
          t: 14400,
          o: 1.09052,
          h: 1.09134,
          l: 1.09032,
          c: 1.09125,
          v: 2895120000
        },
        {
          t: 18000,
          o: 1.09124,
          h: 1.09147,
          l: 1.09062,
          c: 1.09075,
          v: 4903159997
        },
        {
          t: 21600,
          o: 1.09074,
          h: 1.09178,
          l: 1.09058,
          c: 1.09109,
          v: 5788640000
        },
        {
          t: 25200,
          o: 1.09109,
          h: 1.09221,
          l: 1.09082,
          c: 1.09213,
          v: 10350930001
        },
        {
          t: 28800,
          o: 1.09213,
          h: 1.09478,
          l: 1.09188,
          c: 1.09478,
          v: 17199129999
        },
        {
          t: 32400,
          o: 1.09476,
          h: 1.09476,
          l: 1.09319,
          c: 1.09352,
          v: 11248050000
        },
        {
          t: 36000,
          o: 1.09352,
          h: 1.09443,
          l: 1.09327,
          c: 1.09397,
          v: 9552090000
        },
        {
          t: 39600,
          o: 1.09397,
          h: 1.09481,
          l: 1.09348,
          c: 1.09452,
          v: 10796999999
        },
        {
          t: 43200,
          o: 1.09452,
          h: 1.09677,
          l: 1.09418,
          c: 1.09642,
          v: 14208579999
        },
        {
          t: 46800,
          o: 1.09643,
          h: 1.09776,
          l: 1.09589,
          c: 1.09765,
          v: 16518739996
        },
        {
          t: 50400,
          o: 1.09766,
          h: 1.0985,
          l: 1.09631,
          c: 1.09767,
          v: 18608320001
        },
        {
          t: 54000,
          o: 1.09767,
          h: 1.10064,
          l: 1.09726,
          c: 1.09843,
          v: 19576319998
        },
        {
          t: 57600,
          o: 1.09841,
          h: 1.09893,
          l: 1.09705,
          c: 1.09756,
          v: 14414050000
        },
        {
          t: 61200,
          o: 1.09757,
          h: 1.09797,
          l: 1.09647,
          c: 1.09796,
          v: 8257300000
        },
        {
          t: 64800,
          o: 1.09795,
          h: 1.09946,
          l: 1.0978,
          c: 1.09888,
          v: 9206069999
        },
        {
          t: 68400,
          o: 1.09889,
          h: 1.09949,
          l: 1.09855,
          c: 1.09872,
          v: 5856770000
        },
        {
          t: 72000,
          o: 1.09871,
          h: 1.0995,
          l: 1.09807,
          c: 1.09929,
          v: 8176325000
        },
        {
          t: 75600,
          o: 1.09927,
          h: 1.10034,
          l: 1.09875,
          c: 1.09998,
          v: 2618580000
        },
        {
          t: 79200,
          o: 1.09998,
          h: 1.10048,
          l: 1.09998,
          c: 1.10026,
          v: 1189770000
        },
        {
          t: 82800,
          o: 1.10025,
          h: 1.10044,
          l: 1.09959,
          c: 1.09963,
          v: 3811220000
        }
      ]
    },
    {
      d: 1582848000,
      b: [
        {
          t: 0,
          o: 1.09964,
          h: 1.10052,
          l: 1.09949,
          c: 1.09966,
          v: 4413920000
        },
        {
          t: 3600,
          o: 1.09966,
          h: 1.09967,
          l: 1.09857,
          c: 1.09879,
          v: 6184970000
        },
        {
          t: 7200,
          o: 1.09879,
          h: 1.09912,
          l: 1.09822,
          c: 1.09861,
          v: 4942560000
        },
        {
          t: 10800,
          o: 1.09861,
          h: 1.09936,
          l: 1.09842,
          c: 1.09868,
          v: 7275900000
        },
        {
          t: 14400,
          o: 1.09871,
          h: 1.09971,
          l: 1.09857,
          c: 1.09941,
          v: 4876180000
        },
        {
          t: 18000,
          o: 1.09942,
          h: 1.10043,
          l: 1.09922,
          c: 1.1,
          v: 5858460000
        },
        {
          t: 21600,
          o: 1.1,
          h: 1.1008,
          l: 1.09987,
          c: 1.1001,
          v: 8175370000
        },
        {
          t: 25200,
          o: 1.10011,
          h: 1.10043,
          l: 1.09913,
          c: 1.09956,
          v: 13255800000
        },
        {
          t: 28800,
          o: 1.09957,
          h: 1.1034,
          l: 1.09951,
          c: 1.1034,
          v: 17034380000
        },
        {
          t: 32400,
          o: 1.1034,
          h: 1.1053,
          l: 1.10289,
          c: 1.10446,
          v: 16215100000
        },
        {
          t: 36000,
          o: 1.10446,
          h: 1.10449,
          l: 1.10259,
          c: 1.1034,
          v: 13500579999
        },
        {
          t: 39600,
          o: 1.1034,
          h: 1.10422,
          l: 1.09862,
          c: 1.09934,
          v: 14050700000
        },
        {
          t: 43200,
          o: 1.09936,
          h: 1.09999,
          l: 1.09851,
          c: 1.0987,
          v: 14872909998
        },
        {
          t: 46800,
          o: 1.09872,
          h: 1.09932,
          l: 1.09686,
          c: 1.09869,
          v: 18823140001
        },
        {
          t: 50400,
          o: 1.09869,
          h: 1.09882,
          l: 1.09509,
          c: 1.09661,
          v: 18385080001
        },
        {
          t: 54000,
          o: 1.09659,
          h: 1.09901,
          l: 1.09547,
          c: 1.09843,
          v: 20916240022
        },
        {
          t: 57600,
          o: 1.09844,
          h: 1.10076,
          l: 1.0979,
          c: 1.10002,
          v: 19203510000
        },
        {
          t: 61200,
          o: 1.10001,
          h: 1.10088,
          l: 1.0995,
          c: 1.10011,
          v: 8802220000
        },
        {
          t: 64800,
          o: 1.1001,
          h: 1.10057,
          l: 1.09902,
          c: 1.10006,
          v: 7748380000
        },
        {
          t: 68400,
          o: 1.10005,
          h: 1.10328,
          l: 1.09907,
          c: 1.10291,
          v: 10316670000
        },
        {
          t: 72000,
          o: 1.1029,
          h: 1.10459,
          l: 1.10254,
          c: 1.10378,
          v: 8743070001
        },
        {
          t: 75600,
          o: 1.10377,
          h: 1.1043,
          l: 1.10229,
          c: 1.10251,
          v: 4075455000
        }
      ]
    }
  ],
  EURUSD240: [
    {
      d: 1580601600,
      b: [
        {
          t: 72000,
          o: 1.10939,
          h: 1.10939,
          l: 1.10813,
          c: 1.1089,
          v: 4187035000
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.1089,
          h: 1.1089,
          l: 1.10799,
          c: 1.10811,
          v: 13711200000
        },
        {
          t: 14400,
          o: 1.10812,
          h: 1.10862,
          l: 1.10704,
          c: 1.10747,
          v: 14956000000
        },
        {
          t: 28800,
          o: 1.10747,
          h: 1.10783,
          l: 1.10583,
          c: 1.10626,
          v: 36527979995
        },
        {
          t: 43200,
          o: 1.10626,
          h: 1.10666,
          l: 1.10356,
          c: 1.10556,
          v: 38699290001
        },
        {
          t: 57600,
          o: 1.10556,
          h: 1.10663,
          l: 1.10536,
          c: 1.10634,
          v: 23071260000
        },
        {
          t: 72000,
          o: 1.10633,
          h: 1.1064,
          l: 1.10571,
          c: 1.10623,
          v: 7338312500
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.10623,
          h: 1.10641,
          l: 1.10555,
          c: 1.10578,
          v: 8547810000
        },
        {
          t: 14400,
          o: 1.1058,
          h: 1.10637,
          l: 1.10488,
          c: 1.10502,
          v: 13436440000
        },
        {
          t: 28800,
          o: 1.10498,
          h: 1.10611,
          l: 1.10494,
          c: 1.1054,
          v: 31728110001
        },
        {
          t: 43200,
          o: 1.10541,
          h: 1.10541,
          l: 1.10331,
          c: 1.10382,
          v: 35742980007
        },
        {
          t: 57600,
          o: 1.10384,
          h: 1.10452,
          l: 1.10328,
          c: 1.10419,
          v: 16540610000
        },
        {
          t: 72000,
          o: 1.10419,
          h: 1.10459,
          l: 1.1041,
          c: 1.10446,
          v: 7723465000
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.10449,
          h: 1.10449,
          l: 1.10336,
          c: 1.10341,
          v: 9179800000
        },
        {
          t: 14400,
          o: 1.1034,
          h: 1.10405,
          l: 1.10336,
          c: 1.10368,
          v: 8346250000
        },
        {
          t: 28800,
          o: 1.1037,
          h: 1.10479,
          l: 1.10145,
          c: 1.10219,
          v: 41835330001
        },
        {
          t: 43200,
          o: 1.10218,
          h: 1.10232,
          l: 1.09963,
          c: 1.10021,
          v: 40331930000
        },
        {
          t: 57600,
          o: 1.10021,
          h: 1.10068,
          l: 1.09937,
          c: 1.0996,
          v: 17177329999
        },
        {
          t: 72000,
          o: 1.09959,
          h: 1.10003,
          l: 1.09958,
          c: 1.10003,
          v: 5400341000
        }
      ]
    },
    {
      d: 1580947200,
      b: [
        {
          t: 0,
          o: 1.10003,
          h: 1.10005,
          l: 1.09943,
          c: 1.09971,
          v: 6446590000
        },
        {
          t: 14400,
          o: 1.09971,
          h: 1.10056,
          l: 1.0994,
          c: 1.10029,
          v: 11030920000
        },
        {
          t: 28800,
          o: 1.10028,
          h: 1.10138,
          l: 1.09939,
          c: 1.10001,
          v: 25996490000
        },
        {
          t: 43200,
          o: 1.10002,
          h: 1.10041,
          l: 1.09644,
          c: 1.09767,
          v: 33167710000
        },
        {
          t: 57600,
          o: 1.09765,
          h: 1.09835,
          l: 1.09707,
          c: 1.09768,
          v: 18419870000
        },
        {
          t: 72000,
          o: 1.0977,
          h: 1.09829,
          l: 1.09766,
          c: 1.09806,
          v: 7116150000
        }
      ]
    },
    {
      d: 1581033600,
      b: [
        {
          t: 0,
          o: 1.09809,
          h: 1.09849,
          l: 1.09776,
          c: 1.09831,
          v: 12282230000
        },
        {
          t: 14400,
          o: 1.09832,
          h: 1.09833,
          l: 1.09667,
          c: 1.09667,
          v: 19699820000
        },
        {
          t: 28800,
          o: 1.09668,
          h: 1.0971,
          l: 1.09477,
          c: 1.09518,
          v: 33287400043
        },
        {
          t: 43200,
          o: 1.0952,
          h: 1.09763,
          l: 1.09487,
          c: 1.09633,
          v: 43249790000
        },
        {
          t: 57600,
          o: 1.09631,
          h: 1.09653,
          l: 1.09419,
          c: 1.09461,
          v: 18342879995
        },
        {
          t: 72000,
          o: 1.09462,
          h: 1.09479,
          l: 1.09424,
          c: 1.09438,
          v: 3501305000
        }
      ]
    },
    {
      d: 1581206400,
      b: [
        {
          t: 72000,
          o: 1.09439,
          h: 1.09507,
          l: 1.09419,
          c: 1.09491,
          v: 3793480000
        }
      ]
    },
    {
      d: 1581292800,
      b: [
        {
          t: 0,
          o: 1.09491,
          h: 1.0954,
          l: 1.0948,
          c: 1.09523,
          v: 10401930000
        },
        {
          t: 14400,
          o: 1.09524,
          h: 1.09574,
          l: 1.09451,
          c: 1.09466,
          v: 11352580000
        },
        {
          t: 28800,
          o: 1.09466,
          h: 1.0955,
          l: 1.09447,
          c: 1.09491,
          v: 24264040000
        },
        {
          t: 43200,
          o: 1.09491,
          h: 1.09527,
          l: 1.09192,
          c: 1.09241,
          v: 36103479999
        },
        {
          t: 57600,
          o: 1.0924,
          h: 1.0924,
          l: 1.09091,
          c: 1.09138,
          v: 15409110000
        },
        {
          t: 72000,
          o: 1.09141,
          h: 1.09152,
          l: 1.09076,
          c: 1.09101,
          v: 5720817000
        }
      ]
    },
    {
      d: 1581379200,
      b: [
        {
          t: 0,
          o: 1.09103,
          h: 1.0917,
          l: 1.09078,
          c: 1.09135,
          v: 8266240000
        },
        {
          t: 14400,
          o: 1.09134,
          h: 1.09143,
          l: 1.09056,
          c: 1.09142,
          v: 11824350000
        },
        {
          t: 28800,
          o: 1.09144,
          h: 1.09206,
          l: 1.09056,
          c: 1.09126,
          v: 25346569999
        },
        {
          t: 43200,
          o: 1.09127,
          h: 1.09248,
          l: 1.08913,
          c: 1.0916,
          v: 42000440000
        },
        {
          t: 57600,
          o: 1.09159,
          h: 1.09241,
          l: 1.09129,
          c: 1.09215,
          v: 6437510000
        },
        {
          t: 72000,
          o: 1.09214,
          h: 1.09215,
          l: 1.09138,
          c: 1.09189,
          v: 2298630000
        }
      ]
    },
    {
      d: 1581465600,
      b: [
        {
          t: 0,
          o: 1.09187,
          h: 1.09196,
          l: 1.09108,
          c: 1.09151,
          v: 3704250000
        },
        {
          t: 14400,
          o: 1.09151,
          h: 1.09157,
          l: 1.08972,
          c: 1.09146,
          v: 3208690000
        },
        {
          t: 28800,
          o: 1.09144,
          h: 1.09255,
          l: 1.09096,
          c: 1.09119,
          v: 40226057001
        },
        {
          t: 43200,
          o: 1.09118,
          h: 1.09143,
          l: 1.08861,
          c: 1.08944,
          v: 54305060000
        },
        {
          t: 57600,
          o: 1.08946,
          h: 1.08975,
          l: 1.08654,
          c: 1.08663,
          v: 101552910002
        },
        {
          t: 72000,
          o: 1.08663,
          h: 1.08759,
          l: 1.08663,
          c: 1.08756,
          v: 49571300019
        }
      ]
    },
    {
      d: 1581552000,
      b: [
        {
          t: 0,
          o: 1.08755,
          h: 1.08764,
          l: 1.08678,
          c: 1.087,
          v: 50803330000
        },
        {
          t: 14400,
          o: 1.08699,
          h: 1.08795,
          l: 1.08652,
          c: 1.08792,
          v: 53455290000
        },
        {
          t: 28800,
          o: 1.08792,
          h: 1.08887,
          l: 1.0856,
          c: 1.08576,
          v: 83271850001
        },
        {
          t: 43200,
          o: 1.08578,
          h: 1.08693,
          l: 1.0834,
          c: 1.08486,
          v: 111593329999
        },
        {
          t: 57600,
          o: 1.08487,
          h: 1.08541,
          l: 1.08389,
          c: 1.08433,
          v: 28127189998
        },
        {
          t: 72000,
          o: 1.08433,
          h: 1.08454,
          l: 1.0838,
          c: 1.08413,
          v: 9478959600
        }
      ]
    },
    {
      d: 1581638400,
      b: [
        {
          t: 0,
          o: 1.08415,
          h: 1.08416,
          l: 1.08271,
          c: 1.0831,
          v: 20748060000
        },
        {
          t: 14400,
          o: 1.0831,
          h: 1.08432,
          l: 1.08309,
          c: 1.0841,
          v: 12913370000
        },
        {
          t: 28800,
          o: 1.08412,
          h: 1.08501,
          l: 1.08322,
          c: 1.0837,
          v: 23795360000
        },
        {
          t: 43200,
          o: 1.0837,
          h: 1.08611,
          l: 1.08324,
          c: 1.08457,
          v: 37839060001
        },
        {
          t: 57600,
          o: 1.08458,
          h: 1.08467,
          l: 1.08328,
          c: 1.08394,
          v: 18595450001
        },
        {
          t: 72000,
          o: 1.08394,
          h: 1.08412,
          l: 1.08294,
          c: 1.08299,
          v: 4476885000
        }
      ]
    },
    {
      d: 1581811200,
      b: [
        {
          t: 72000,
          o: 1.08406,
          h: 1.08416,
          l: 1.08355,
          c: 1.08411,
          v: 3465965000
        }
      ]
    },
    {
      d: 1581897600,
      b: [
        {
          t: 0,
          o: 1.08411,
          h: 1.08411,
          l: 1.08334,
          c: 1.08384,
          v: 6439580000
        },
        {
          t: 14400,
          o: 1.08385,
          h: 1.08422,
          l: 1.08339,
          c: 1.08406,
          v: 13640710106
        },
        {
          t: 28800,
          o: 1.08407,
          h: 1.08509,
          l: 1.08359,
          c: 1.08391,
          v: 20883880000
        },
        {
          t: 43200,
          o: 1.0839,
          h: 1.08418,
          l: 1.08293,
          c: 1.08296,
          v: 17132520000
        },
        {
          t: 57600,
          o: 1.08295,
          h: 1.08399,
          l: 1.08291,
          c: 1.08337,
          v: 10013590000
        },
        {
          t: 72000,
          o: 1.08337,
          h: 1.08373,
          l: 1.08317,
          c: 1.08366,
          v: 6722584900
        }
      ]
    },
    {
      d: 1581984000,
      b: [
        {
          t: 0,
          o: 1.08365,
          h: 1.08367,
          l: 1.08229,
          c: 1.08324,
          v: 13526190000
        },
        {
          t: 14400,
          o: 1.08324,
          h: 1.08372,
          l: 1.08284,
          c: 1.0833,
          v: 14596770000
        },
        {
          t: 28800,
          o: 1.0833,
          h: 1.08367,
          l: 1.08212,
          c: 1.08245,
          v: 37934180000
        },
        {
          t: 43200,
          o: 1.08245,
          h: 1.08258,
          l: 1.07854,
          c: 1.08227,
          v: 43335279998
        },
        {
          t: 57600,
          o: 1.08227,
          h: 1.08241,
          l: 1.07929,
          c: 1.07939,
          v: 15899930000
        },
        {
          t: 72000,
          o: 1.07938,
          h: 1.07971,
          l: 1.07897,
          c: 1.07949,
          v: 6296292000
        }
      ]
    },
    {
      d: 1582070400,
      b: [
        {
          t: 0,
          o: 1.07949,
          h: 1.08037,
          l: 1.07927,
          c: 1.07988,
          v: 7688090000
        },
        {
          t: 14400,
          o: 1.07989,
          h: 1.08045,
          l: 1.07913,
          c: 1.08018,
          v: 9150020000
        },
        {
          t: 28800,
          o: 1.0802,
          h: 1.08079,
          l: 1.07846,
          c: 1.07915,
          v: 26957790001
        },
        {
          t: 43200,
          o: 1.07915,
          h: 1.08036,
          l: 1.07822,
          c: 1.07912,
          v: 35455970000
        },
        {
          t: 57600,
          o: 1.07912,
          h: 1.08008,
          l: 1.0786,
          c: 1.07963,
          v: 19374330000
        },
        {
          t: 72000,
          o: 1.07963,
          h: 1.08115,
          l: 1.07956,
          c: 1.08096,
          v: 6525856900
        }
      ]
    },
    {
      d: 1582156800,
      b: [
        {
          t: 0,
          o: 1.08095,
          h: 1.08152,
          l: 1.07916,
          c: 1.07969,
          v: 10945930000
        },
        {
          t: 14400,
          o: 1.07971,
          h: 1.0799,
          l: 1.07778,
          c: 1.07905,
          v: 11607470000
        },
        {
          t: 28800,
          o: 1.07906,
          h: 1.08061,
          l: 1.07848,
          c: 1.07963,
          v: 22241330006
        },
        {
          t: 43200,
          o: 1.07964,
          h: 1.08211,
          l: 1.07858,
          c: 1.08023,
          v: 30448949994
        },
        {
          t: 57600,
          o: 1.08024,
          h: 1.08075,
          l: 1.07829,
          c: 1.07894,
          v: 34206540001
        },
        {
          t: 72000,
          o: 1.07895,
          h: 1.07905,
          l: 1.07828,
          c: 1.07875,
          v: 44564379992
        }
      ]
    },
    {
      d: 1582243200,
      b: [
        {
          t: 0,
          o: 1.07875,
          h: 1.07936,
          l: 1.07871,
          c: 1.07902,
          v: 19018909997
        },
        {
          t: 14400,
          o: 1.07902,
          h: 1.07985,
          l: 1.07892,
          c: 1.07961,
          v: 31079240001
        },
        {
          t: 28800,
          o: 1.0796,
          h: 1.082,
          l: 1.07902,
          c: 1.08061,
          v: 69174180007
        },
        {
          t: 43200,
          o: 1.08061,
          h: 1.08634,
          l: 1.07962,
          c: 1.08526,
          v: 45296360000
        },
        {
          t: 57600,
          o: 1.08526,
          h: 1.08635,
          l: 1.08487,
          c: 1.08578,
          v: 18685819999
        },
        {
          t: 72000,
          o: 1.08576,
          h: 1.08576,
          l: 1.08456,
          c: 1.08456,
          v: 4411510000
        }
      ]
    },
    {
      d: 1582416000,
      b: [
        {
          t: 72000,
          o: 1.08145,
          h: 1.08419,
          l: 1.08132,
          c: 1.08384,
          v: 7690538900
        }
      ]
    },
    {
      d: 1582502400,
      b: [
        {
          t: 0,
          o: 1.08385,
          h: 1.08423,
          l: 1.08215,
          c: 1.08221,
          v: 15508410000
        },
        {
          t: 14400,
          o: 1.0822,
          h: 1.08226,
          l: 1.0805,
          c: 1.08171,
          v: 17305810000
        },
        {
          t: 28800,
          o: 1.08171,
          h: 1.08418,
          l: 1.08129,
          c: 1.08207,
          v: 43244520002
        },
        {
          t: 43200,
          o: 1.08208,
          h: 1.08608,
          l: 1.0813,
          c: 1.08558,
          v: 52942130005
        },
        {
          t: 57600,
          o: 1.08559,
          h: 1.0872,
          l: 1.08414,
          c: 1.08431,
          v: 31086679999
        },
        {
          t: 72000,
          o: 1.08431,
          h: 1.08569,
          l: 1.08414,
          c: 1.08485,
          v: 14268080000
        }
      ]
    },
    {
      d: 1582588800,
      b: [
        {
          t: 0,
          o: 1.08485,
          h: 1.08675,
          l: 1.0845,
          c: 1.08628,
          v: 17691350003
        },
        {
          t: 14400,
          o: 1.08628,
          h: 1.08628,
          l: 1.08475,
          c: 1.08533,
          v: 18350990000
        },
        {
          t: 28800,
          o: 1.08535,
          h: 1.08659,
          l: 1.083,
          c: 1.08366,
          v: 42616650001
        },
        {
          t: 43200,
          o: 1.08367,
          h: 1.08666,
          l: 1.083,
          c: 1.08647,
          v: 54170339999
        },
        {
          t: 57600,
          o: 1.08648,
          h: 1.08902,
          l: 1.08624,
          c: 1.08815,
          v: 36934160000
        },
        {
          t: 72000,
          o: 1.08814,
          h: 1.08863,
          l: 1.08754,
          c: 1.08797,
          v: 13454600000
        }
      ]
    },
    {
      d: 1582675200,
      b: [
        {
          t: 0,
          o: 1.08796,
          h: 1.08796,
          l: 1.08624,
          c: 1.08665,
          v: 17141380000
        },
        {
          t: 14400,
          o: 1.08665,
          h: 1.08783,
          l: 1.08654,
          c: 1.0871,
          v: 22894290000
        },
        {
          t: 28800,
          o: 1.0871,
          h: 1.08976,
          l: 1.0871,
          c: 1.08844,
          v: 45620139999
        },
        {
          t: 43200,
          o: 1.08844,
          h: 1.09086,
          l: 1.08549,
          c: 1.08666,
          v: 58511950002
        },
        {
          t: 57600,
          o: 1.08666,
          h: 1.08998,
          l: 1.0864,
          c: 1.08995,
          v: 33780950000
        },
        {
          t: 72000,
          o: 1.08994,
          h: 1.08994,
          l: 1.08768,
          c: 1.08864,
          v: 10268266008
        }
      ]
    },
    {
      d: 1582761600,
      b: [
        {
          t: 0,
          o: 1.08865,
          h: 1.09144,
          l: 1.08848,
          c: 1.09053,
          v: 21766950000
        },
        {
          t: 14400,
          o: 1.09052,
          h: 1.09221,
          l: 1.09032,
          c: 1.09213,
          v: 23937849998
        },
        {
          t: 28800,
          o: 1.09213,
          h: 1.09481,
          l: 1.09188,
          c: 1.09452,
          v: 48796269998
        },
        {
          t: 43200,
          o: 1.09452,
          h: 1.10064,
          l: 1.09418,
          c: 1.09843,
          v: 68911959994
        },
        {
          t: 57600,
          o: 1.09841,
          h: 1.09949,
          l: 1.09647,
          c: 1.09872,
          v: 37734189999
        },
        {
          t: 72000,
          o: 1.09871,
          h: 1.10048,
          l: 1.09807,
          c: 1.09963,
          v: 15795895000
        }
      ]
    },
    {
      d: 1582848000,
      b: [
        {
          t: 0,
          o: 1.09964,
          h: 1.10052,
          l: 1.09822,
          c: 1.09868,
          v: 22817350000
        },
        {
          t: 14400,
          o: 1.09871,
          h: 1.1008,
          l: 1.09857,
          c: 1.09956,
          v: 32165810000
        },
        {
          t: 28800,
          o: 1.09957,
          h: 1.1053,
          l: 1.09862,
          c: 1.09934,
          v: 60800759999
        },
        {
          t: 43200,
          o: 1.09936,
          h: 1.09999,
          l: 1.09509,
          c: 1.09843,
          v: 72997370022
        },
        {
          t: 57600,
          o: 1.09844,
          h: 1.10328,
          l: 1.0979,
          c: 1.10291,
          v: 46070780000
        },
        {
          t: 72000,
          o: 1.1029,
          h: 1.10459,
          l: 1.10229,
          c: 1.10251,
          v: 12818525001
        }
      ]
    }
  ]
}

export const demoNewsRecords: INews[] = [
  {
    Time: 1580688000,
    Symbol: 'USD',
    Title: 'Wards Total Vehicle Sales',
    Country: 'United States',
    Priority: '0',
    Actual: '16.8M',
    Consensus: '16.8M',
    Previews: '16.7M',
    IsPlanned: true
  },
  {
    Time: 1580741100,
    Symbol: 'USD',
    Title: 'Final Manufacturing PMI',
    Country: 'United States',
    Priority: '0',
    Actual: '51.9',
    Consensus: '51.7',
    Previews: '51.7',
    IsPlanned: true
  },
  {
    Time: 1580742000,
    Symbol: 'USD',
    Title: 'Construction Spending m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '0.5%',
    Previews: '0.7%',
    IsPlanned: true
  },
  {
    Time: 1580742000,
    Symbol: 'USD',
    Title: 'ISM Manufacturing PMI',
    Country: 'United States',
    Priority: '2',
    Actual: '50.9',
    Consensus: '48.5',
    Previews: '47.2',
    IsPlanned: true
  },
  {
    Time: 1580742000,
    Symbol: 'USD',
    Title: 'ISM Manufacturing Prices',
    Country: 'United States',
    Priority: '0',
    Actual: '53.3',
    Consensus: '52.0',
    Previews: '51.7',
    IsPlanned: true
  },
  {
    Time: 1580756400,
    Symbol: 'USD',
    Title: 'Loan Officer Survey',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580828400,
    Symbol: 'USD',
    Title: 'Factory Orders m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '1.8%',
    Consensus: '0.7%',
    Previews: '-1.2%',
    IsPlanned: true
  },
  {
    Time: 1580828400,
    Symbol: 'USD',
    Title: 'IBD/TIPP Economic Optimism',
    Country: 'United States',
    Priority: '0',
    Actual: '59.8',
    Consensus: '58.3',
    Previews: '57.4',
    IsPlanned: true
  },
  {
    Time: 1580868000,
    Symbol: 'USD',
    Title: 'President Trump Speaks',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580908500,
    Symbol: 'USD',
    Title: 'ADP Non-Farm Employment Change',
    Country: 'United States',
    Priority: '2',
    Actual: '291K',
    Consensus: '157K',
    Previews: '199K',
    IsPlanned: true
  },
  {
    Time: 1580909400,
    Symbol: 'USD',
    Title: 'Trade Balance',
    Country: 'United States',
    Priority: '0',
    Actual: '-48.9B',
    Consensus: '-48.2B',
    Previews: '-43.7B',
    IsPlanned: true
  },
  {
    Time: 1580913900,
    Symbol: 'USD',
    Title: 'Final Services PMI',
    Country: 'United States',
    Priority: '0',
    Actual: '53.4',
    Consensus: '53.2',
    Previews: '53.2',
    IsPlanned: true
  },
  {
    Time: 1580914800,
    Symbol: 'USD',
    Title: 'ISM Non-Manufacturing PMI',
    Country: 'United States',
    Priority: '2',
    Actual: '55.5',
    Consensus: '55.1',
    Previews: '55.0',
    IsPlanned: true
  },
  {
    Time: 1580916600,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '3.4M',
    Consensus: '2.9M',
    Previews: '3.5M',
    IsPlanned: true
  },
  {
    Time: 1580937000,
    Symbol: 'USD',
    Title: 'FOMC Member Brainard Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580992200,
    Symbol: 'USD',
    Title: 'Challenger Job Cuts y/y',
    Country: 'United States',
    Priority: '0',
    Actual: '27.8%',
    Consensus: '-',
    Previews: '-25.2%',
    IsPlanned: true
  },
  {
    Time: 1580995800,
    Symbol: 'USD',
    Title: 'Prelim Nonfarm Productivity q/q',
    Country: 'United States',
    Priority: '0',
    Actual: '1.4%',
    Consensus: '1.6%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1580995800,
    Symbol: 'USD',
    Title: 'Prelim Unit Labor Costs q/q',
    Country: 'United States',
    Priority: '0',
    Actual: '1.4%',
    Consensus: '1.3%',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1580995800,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '202K',
    Consensus: '215K',
    Previews: '217K',
    IsPlanned: true
  },
  {
    Time: 1580998500,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581003000,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-137B',
    Consensus: '-123B',
    Previews: '-201B',
    IsPlanned: true
  },
  {
    Time: 1581034500,
    Symbol: 'USD',
    Title: 'FOMC Member Quarles Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581082200,
    Symbol: 'USD',
    Title: 'Average Hourly Earnings m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.2%',
    Consensus: '0.3%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581082200,
    Symbol: 'USD',
    Title: 'Non-Farm Employment Change',
    Country: 'United States',
    Priority: '2',
    Actual: '225K',
    Consensus: '163K',
    Previews: '147K',
    IsPlanned: true
  },
  {
    Time: 1581082200,
    Symbol: 'USD',
    Title: 'Unemployment Rate',
    Country: 'United States',
    Priority: '2',
    Actual: '3.6%',
    Consensus: '3.5%',
    Previews: '3.5%',
    IsPlanned: true
  },
  {
    Time: 1581087600,
    Symbol: 'USD',
    Title: 'Final Wholesale Inventories m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '-0.1%',
    Previews: '-0.1%',
    IsPlanned: true
  },
  {
    Time: 1581091200,
    Symbol: 'USD',
    Title: 'Fed Monetary Policy Report',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581105600,
    Symbol: 'USD',
    Title: 'Consumer Credit m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '22.1B',
    Consensus: '15.2B',
    Previews: '11.8B',
    IsPlanned: true
  },
  {
    Time: 1581340500,
    Symbol: 'USD',
    Title: 'FOMC Member Bowman Speaks',
    Country: 'United States',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581365700,
    Symbol: 'USD',
    Title: 'FOMC Member Harker Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581418560,
    Symbol: 'USD',
    Title: 'NFIB Small Business Index',
    Country: 'United States',
    Priority: '0',
    Actual: '104.3',
    Consensus: '103.6',
    Previews: '102.7',
    IsPlanned: true
  },
  {
    Time: 1581433200,
    Symbol: 'USD',
    Title: 'Fed Chair Powell Testifies',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581433200,
    Symbol: 'USD',
    Title: 'JOLTS Job Openings',
    Country: 'United States',
    Priority: '0',
    Actual: '6.42M',
    Consensus: '6.93M',
    Previews: '6.79M',
    IsPlanned: true
  },
  {
    Time: 1581433200,
    Symbol: 'USD',
    Title: 'Mortgage Delinquencies',
    Country: 'United States',
    Priority: '0',
    Actual: '3.77%',
    Consensus: '-',
    Previews: '3.97%',
    IsPlanned: true
  },
  {
    Time: 1581441300,
    Symbol: 'USD',
    Title: 'FOMC Member Quarles Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581448500,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581514200,
    Symbol: 'USD',
    Title: 'FOMC Member Harker Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581517800,
    Symbol: 'USD',
    Title: 'Fed Chair Powell Testifies',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581521400,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '7.5M',
    Consensus: '3.1M',
    Previews: '3.4M',
    IsPlanned: true
  },
  {
    Time: 1581530460,
    Symbol: 'USD',
    Title: '10-y Bond Auction',
    Country: 'United States',
    Priority: '0',
    Actual: '1.62|2.6',
    Consensus: '-',
    Previews: '1.87|2.4',
    IsPlanned: true
  },
  {
    Time: 1581534000,
    Symbol: 'USD',
    Title: 'Federal Budget Balance',
    Country: 'United States',
    Priority: '0',
    Actual: '-32.6B',
    Consensus: '-10.7B',
    Previews: '-13.3B',
    IsPlanned: true
  },
  {
    Time: 1581600600,
    Symbol: 'USD',
    Title: 'CPI m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.1%',
    Consensus: '0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581600600,
    Symbol: 'USD',
    Title: 'Core CPI m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.2%',
    Consensus: '0.2%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581600600,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '205K',
    Consensus: '210K',
    Previews: '203K',
    IsPlanned: true
  },
  {
    Time: 1581607800,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-115B',
    Consensus: '-106B',
    Previews: '-137B',
    IsPlanned: true
  },
  {
    Time: 1581616860,
    Symbol: 'USD',
    Title: '30-y Bond Auction',
    Country: 'United States',
    Priority: '0',
    Actual: '2.06|2.4',
    Consensus: '-',
    Previews: '2.34|2.5',
    IsPlanned: true
  },
  {
    Time: 1581633000,
    Symbol: 'USD',
    Title: 'FOMC Member Williams Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581687000,
    Symbol: 'USD',
    Title: 'Core Retail Sales m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.3%',
    Consensus: '0.3%',
    Previews: '0.6%',
    IsPlanned: true
  },
  {
    Time: 1581687000,
    Symbol: 'USD',
    Title: 'Import Prices m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581687000,
    Symbol: 'USD',
    Title: 'Retail Sales m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.3%',
    Consensus: '0.3%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581689700,
    Symbol: 'USD',
    Title: 'Capacity Utilization Rate',
    Country: 'United States',
    Priority: '0',
    Actual: '76.8%',
    Consensus: '76.9%',
    Previews: '77.1%',
    IsPlanned: true
  },
  {
    Time: 1581689700,
    Symbol: 'USD',
    Title: 'Industrial Production m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.3%',
    Consensus: '-0.2%',
    Previews: '-0.4%',
    IsPlanned: true
  },
  {
    Time: 1581692400,
    Symbol: 'USD',
    Title: 'Business Inventories m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.1%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1581692400,
    Symbol: 'USD',
    Title: 'Prelim UoM Consumer Sentiment',
    Country: 'United States',
    Priority: '1',
    Actual: '100.9',
    Consensus: '99.5',
    Previews: '99.8',
    IsPlanned: true
  },
  {
    Time: 1581692400,
    Symbol: 'USD',
    Title: 'Prelim UoM Inflation Expectations',
    Country: 'United States',
    Priority: '0',
    Actual: '2.5%',
    Consensus: '-',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1581698700,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581897600,
    Symbol: 'USD',
    Title: 'Bank Holiday',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582032600,
    Symbol: 'USD',
    Title: 'Empire State Manufacturing Index',
    Country: 'United States',
    Priority: '0',
    Actual: '12.9',
    Consensus: '5.1',
    Previews: '4.8',
    IsPlanned: true
  },
  {
    Time: 1582038000,
    Symbol: 'USD',
    Title: 'NAHB Housing Market Index',
    Country: 'United States',
    Priority: '0',
    Actual: '74',
    Consensus: '75',
    Previews: '75',
    IsPlanned: true
  },
  {
    Time: 1582052400,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582059600,
    Symbol: 'USD',
    Title: 'TIC Long-Term Purchases',
    Country: 'United States',
    Priority: '0',
    Actual: '85.6B',
    Consensus: '31.4B',
    Previews: '27.1B',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'Building Permits',
    Country: 'United States',
    Priority: '1',
    Actual: '1.55M',
    Consensus: '1.45M',
    Previews: '1.42M',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'Core PPI m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.5%',
    Consensus: '0.2%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'Housing Starts',
    Country: 'United States',
    Priority: '0',
    Actual: '1.57M',
    Consensus: '1.40M',
    Previews: '1.63M',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'PPI m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.5%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582130700,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582137000,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582138800,
    Symbol: 'USD',
    Title: 'FOMC Meeting Minutes',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582205400,
    Symbol: 'USD',
    Title: 'Philly Fed Manufacturing Index',
    Country: 'United States',
    Priority: '1',
    Actual: '36.7',
    Consensus: '10.1',
    Previews: '17.0',
    IsPlanned: true
  },
  {
    Time: 1582205400,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '210K',
    Consensus: '210K',
    Previews: '206K',
    IsPlanned: true
  },
  {
    Time: 1582210800,
    Symbol: 'USD',
    Title: 'CB Leading Index m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.4%',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1582212600,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-151B',
    Consensus: '-143B',
    Previews: '-115B',
    IsPlanned: true
  },
  {
    Time: 1582214400,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '0.4M',
    Consensus: '3.3M',
    Previews: '7.5M',
    IsPlanned: true
  },
  {
    Time: 1582295700,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582296300,
    Symbol: 'USD',
    Title: 'Flash Manufacturing PMI',
    Country: 'United States',
    Priority: '2',
    Actual: '50.8',
    Consensus: '51.5',
    Previews: '51.9',
    IsPlanned: true
  },
  {
    Time: 1582296300,
    Symbol: 'USD',
    Title: 'Flash Services PMI',
    Country: 'United States',
    Priority: '0',
    Actual: '49.4',
    Consensus: '53.3',
    Previews: '53.3',
    IsPlanned: true
  },
  {
    Time: 1582297200,
    Symbol: 'USD',
    Title: 'Existing Home Sales',
    Country: 'United States',
    Priority: '0',
    Actual: '5.46M',
    Consensus: '5.46M',
    Previews: '5.53M',
    IsPlanned: true
  },
  {
    Time: 1582298100,
    Symbol: 'USD',
    Title: 'FOMC Member Brainard Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582309800,
    Symbol: 'USD',
    Title: 'FOMC Member Clarida Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582574400,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582639200,
    Symbol: 'USD',
    Title: 'HPI m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.6%',
    Consensus: '0.4%',
    Previews: '0.3%',
    IsPlanned: true
  },
  {
    Time: 1582639200,
    Symbol: 'USD',
    Title: 'S&P/CS Composite-20 HPI y/y',
    Country: 'United States',
    Priority: '0',
    Actual: '2.9%',
    Consensus: '2.8%',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1582641900,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582642740,
    Symbol: 'USD',
    Title: 'Richmond Manufacturing Index',
    Country: 'United States',
    Priority: '1',
    Actual: '-2',
    Consensus: '10',
    Previews: '20',
    IsPlanned: true
  },
  {
    Time: 1582642800,
    Symbol: 'USD',
    Title: 'CB Consumer Confidence',
    Country: 'United States',
    Priority: '2',
    Actual: '130.7',
    Consensus: '132.6',
    Previews: '130.4',
    IsPlanned: true
  },
  {
    Time: 1582660800,
    Symbol: 'USD',
    Title: 'FOMC Member Clarida Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582728300,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582729200,
    Symbol: 'USD',
    Title: 'New Home Sales',
    Country: 'United States',
    Priority: '1',
    Actual: '764K',
    Consensus: '714K',
    Previews: '708K',
    IsPlanned: true
  },
  {
    Time: 1582731000,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '0.5M',
    Consensus: '2.3M',
    Previews: '0.4M',
    IsPlanned: true
  },
  {
    Time: 1582740000,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Core Durable Goods Orders m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.9%',
    Consensus: '0.2%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Durable Goods Orders m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '-0.2%',
    Consensus: '-1.5%',
    Previews: '2.9%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Prelim GDP Price Index q/q',
    Country: 'United States',
    Priority: '0',
    Actual: '1.3%',
    Consensus: '1.4%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Prelim GDP q/q',
    Country: 'United States',
    Priority: '1',
    Actual: '2.1%',
    Consensus: '2.1%',
    Previews: '2.1%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '219K',
    Consensus: '211K',
    Previews: '211K',
    IsPlanned: true
  },
  {
    Time: 1582815600,
    Symbol: 'USD',
    Title: 'Pending Home Sales m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '5.2%',
    Consensus: '2.8%',
    Previews: '-4.3%',
    IsPlanned: true
  },
  {
    Time: 1582817400,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-143B',
    Consensus: '-158B',
    Previews: '-151B',
    IsPlanned: true
  },
  {
    Time: 1582835400,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582848000,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Core PCE Price Index m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.1%',
    Consensus: '0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Goods Trade Balance',
    Country: 'United States',
    Priority: '0',
    Actual: '-65.5B',
    Consensus: '-68.5B',
    Previews: '-68.7B',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Personal Income m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.6%',
    Consensus: '0.3%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Personal Spending m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.2%',
    Consensus: '0.3%',
    Previews: '0.4%',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Prelim Wholesale Inventories m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '0.1%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1582901100,
    Symbol: 'USD',
    Title: 'Chicago PMI',
    Country: 'United States',
    Priority: '1',
    Actual: '49.0',
    Consensus: '46.1',
    Previews: '42.9',
    IsPlanned: true
  },
  {
    Time: 1582902000,
    Symbol: 'USD',
    Title: 'Revised UoM Consumer Sentiment',
    Country: 'United States',
    Priority: '0',
    Actual: '101.0',
    Consensus: '100.7',
    Previews: '100.9',
    IsPlanned: true
  },
  {
    Time: 1582902000,
    Symbol: 'USD',
    Title: 'Revised UoM Inflation Expectations',
    Country: 'United States',
    Priority: '0',
    Actual: '2.4%',
    Consensus: '-',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1582918200,
    Symbol: 'USD',
    Title: 'Fed Announcement',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580717700,
    Symbol: 'EUR',
    Title: 'Spanish Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '48.5',
    Consensus: '48.3',
    Previews: '47.4',
    IsPlanned: true
  },
  {
    Time: 1580719500,
    Symbol: 'EUR',
    Title: 'Italian Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '48.9',
    Consensus: '46.9',
    Previews: '46.2',
    IsPlanned: true
  },
  {
    Time: 1580719800,
    Symbol: 'EUR',
    Title: 'French Final Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '51.1',
    Consensus: '51.0',
    Previews: '51.0',
    IsPlanned: true
  },
  {
    Time: 1580720100,
    Symbol: 'EUR',
    Title: 'German Final Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '45.3',
    Consensus: '45.2',
    Previews: '45.2',
    IsPlanned: true
  },
  {
    Time: 1580720400,
    Symbol: 'EUR',
    Title: 'Final Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '47.9',
    Consensus: '47.8',
    Previews: '47.8',
    IsPlanned: true
  },
  {
    Time: 1580753700,
    Symbol: 'EUR',
    Title: 'German Buba President Weidmann Speaks',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580802300,
    Symbol: 'EUR',
    Title: 'French Gov Budget Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '-92.8B',
    Consensus: '-',
    Previews: '-113.9B',
    IsPlanned: true
  },
  {
    Time: 1580803200,
    Symbol: 'EUR',
    Title: 'Spanish Unemployment Change',
    Country: 'European Union',
    Priority: '0',
    Actual: '90.2K',
    Consensus: '44.2K',
    Previews: '-34.6K',
    IsPlanned: true
  },
  {
    Time: 1580810400,
    Symbol: 'EUR',
    Title: 'Italian Prelim CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.2%',
    Consensus: '-0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1580810400,
    Symbol: 'EUR',
    Title: 'PPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1580890500,
    Symbol: 'EUR',
    Title: 'Spanish Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '52.3',
    Consensus: '54.0',
    Previews: '54.9',
    IsPlanned: true
  },
  {
    Time: 1580892300,
    Symbol: 'EUR',
    Title: 'Italian Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '51.4',
    Consensus: '50.3',
    Previews: '51.1',
    IsPlanned: true
  },
  {
    Time: 1580892600,
    Symbol: 'EUR',
    Title: 'French Final Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '51.0',
    Consensus: '51.7',
    Previews: '51.7',
    IsPlanned: true
  },
  {
    Time: 1580892900,
    Symbol: 'EUR',
    Title: 'German Final Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '54.2',
    Consensus: '54.2',
    Previews: '54.2',
    IsPlanned: true
  },
  {
    Time: 1580893200,
    Symbol: 'EUR',
    Title: 'Final Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '52.5',
    Consensus: '52.2',
    Previews: '52.2',
    IsPlanned: true
  },
  {
    Time: 1580896800,
    Symbol: 'EUR',
    Title: 'Retail Sales m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-1.6%',
    Consensus: '-0.5%',
    Previews: '0.8%',
    IsPlanned: true
  },
  {
    Time: 1580904900,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580972400,
    Symbol: 'EUR',
    Title: 'German Factory Orders m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.1%',
    Consensus: '0.6%',
    Previews: '-0.8%',
    IsPlanned: true
  },
  {
    Time: 1580976000,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580979600,
    Symbol: 'EUR',
    Title: 'ECB Economic Bulletin',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580982540,
    Symbol: 'EUR',
    Title: 'Spanish 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.35|1.6',
    Consensus: '-',
    Previews: '0.44|1.5',
    IsPlanned: true
  },
  {
    Time: 1580983620,
    Symbol: 'EUR',
    Title: 'French 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.11|2.2',
    Consensus: '-',
    Previews: '0.04|1.7',
    IsPlanned: true
  },
  {
    Time: 1581058800,
    Symbol: 'EUR',
    Title: 'German Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-3.5%',
    Consensus: '-0.2%',
    Previews: '1.2%',
    IsPlanned: true
  },
  {
    Time: 1581058800,
    Symbol: 'EUR',
    Title: 'German Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '19.2B',
    Consensus: '16.4B',
    Previews: '18.5B',
    IsPlanned: true
  },
  {
    Time: 1581061500,
    Symbol: 'EUR',
    Title: 'French Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.8%',
    Consensus: '-0.3%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581061500,
    Symbol: 'EUR',
    Title: 'French Prelim Private Payrolls q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.2%',
    Consensus: '0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581061500,
    Symbol: 'EUR',
    Title: 'French Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '-4.1B',
    Consensus: '-5.1B',
    Previews: '-5.4B',
    IsPlanned: true
  },
  {
    Time: 1581066000,
    Symbol: 'EUR',
    Title: 'Italian Retail Sales m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.5%',
    Consensus: '0.2%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1581325200,
    Symbol: 'EUR',
    Title: 'Italian Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.7%',
    Consensus: '1.5%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581327000,
    Symbol: 'EUR',
    Title: 'Sentix Investor Confidence',
    Country: 'European Union',
    Priority: '0',
    Actual: '5.2',
    Consensus: '6.1',
    Previews: '7.6',
    IsPlanned: true
  },
  {
    Time: 1581429600,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581501600,
    Symbol: 'EUR',
    Title: 'Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.1%',
    Consensus: '-1.8%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581503580,
    Symbol: 'EUR',
    Title: 'German 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.38|1.2',
    Consensus: '-',
    Previews: '-0.25|1.2',
    IsPlanned: true
  },
  {
    Time: 1581577200,
    Symbol: 'EUR',
    Title: 'German Final CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.6%',
    Consensus: '-0.6%',
    Previews: '-0.6%',
    IsPlanned: true
  },
  {
    Time: 1581588000,
    Symbol: 'EUR',
    Title: 'EU Economic Forecasts',
    Country: 'European Union',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581663600,
    Symbol: 'EUR',
    Title: 'German Prelim GDP q/q',
    Country: 'European Union',
    Priority: '2',
    Actual: '0.0%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581663600,
    Symbol: 'EUR',
    Title: 'German WPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.0%',
    Consensus: '0.1%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581670800,
    Symbol: 'EUR',
    Title: 'Italian Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '5.01B',
    Consensus: '5.23B',
    Previews: '4.89B',
    IsPlanned: true
  },
  {
    Time: 1581674400,
    Symbol: 'EUR',
    Title: 'Flash Employment Change q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581674400,
    Symbol: 'EUR',
    Title: 'Flash GDP q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581674400,
    Symbol: 'EUR',
    Title: 'Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '22.2B',
    Consensus: '19.1B',
    Previews: '19.1B',
    IsPlanned: true
  },
  {
    Time: 1581897600,
    Symbol: 'EUR',
    Title: 'Eurogroup Meetings',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581937200,
    Symbol: 'EUR',
    Title: 'German Buba Monthly Report',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581984000,
    Symbol: 'EUR',
    Title: 'ECOFIN Meetings',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582020000,
    Symbol: 'EUR',
    Title: 'German ZEW Economic Sentiment',
    Country: 'European Union',
    Priority: '2',
    Actual: '8.7',
    Consensus: '20.0',
    Previews: '26.7',
    IsPlanned: true
  },
  {
    Time: 1582020000,
    Symbol: 'EUR',
    Title: 'ZEW Economic Sentiment',
    Country: 'European Union',
    Priority: '0',
    Actual: '10.4',
    Consensus: '21.3',
    Previews: '25.6',
    IsPlanned: true
  },
  {
    Time: 1582102800,
    Symbol: 'EUR',
    Title: 'Current Account',
    Country: 'European Union',
    Priority: '0',
    Actual: '32.6B',
    Consensus: '34.5B',
    Previews: '32.4B',
    IsPlanned: true
  },
  {
    Time: 1582108500,
    Symbol: 'EUR',
    Title: 'German 30-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.07|1.1',
    Consensus: '-',
    Previews: '0.31|1.1',
    IsPlanned: true
  },
  {
    Time: 1582181940,
    Symbol: 'EUR',
    Title: 'German GfK Consumer Climate',
    Country: 'European Union',
    Priority: '0',
    Actual: '9.8',
    Consensus: '9.9',
    Previews: '9.9',
    IsPlanned: true
  },
  {
    Time: 1582182000,
    Symbol: 'EUR',
    Title: 'German PPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582184700,
    Symbol: 'EUR',
    Title: 'French Final CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.4%',
    Consensus: '-0.4%',
    Previews: '-0.4%',
    IsPlanned: true
  },
  {
    Time: 1582191840,
    Symbol: 'EUR',
    Title: 'Spanish 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.24|2.3',
    Consensus: '-',
    Previews: '0.35|1.6',
    IsPlanned: true
  },
  {
    Time: 1582201800,
    Symbol: 'EUR',
    Title: 'ECB Monetary Policy Meeting Accounts',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582210620,
    Symbol: 'EUR',
    Title: 'Consumer Confidence',
    Country: 'European Union',
    Priority: '0',
    Actual: '-7',
    Consensus: '-8',
    Previews: '-8',
    IsPlanned: true
  },
  {
    Time: 1582272900,
    Symbol: 'EUR',
    Title: 'French Flash Manufacturing PMI',
    Country: 'European Union',
    Priority: '1',
    Actual: '49.7',
    Consensus: '50.8',
    Previews: '51.1',
    IsPlanned: true
  },
  {
    Time: 1582272900,
    Symbol: 'EUR',
    Title: 'French Flash Services PMI',
    Country: 'European Union',
    Priority: '2',
    Actual: '52.6',
    Consensus: '51.4',
    Previews: '51.0',
    IsPlanned: true
  },
  {
    Time: 1582273800,
    Symbol: 'EUR',
    Title: 'German Flash Manufacturing PMI',
    Country: 'European Union',
    Priority: '2',
    Actual: '47.8',
    Consensus: '44.8',
    Previews: '45.3',
    IsPlanned: true
  },
  {
    Time: 1582273800,
    Symbol: 'EUR',
    Title: 'German Flash Services PMI',
    Country: 'European Union',
    Priority: '2',
    Actual: '53.3',
    Consensus: '53.9',
    Previews: '54.2',
    IsPlanned: true
  },
  {
    Time: 1582275600,
    Symbol: 'EUR',
    Title: 'Flash Manufacturing PMI',
    Country: 'European Union',
    Priority: '1',
    Actual: '49.1',
    Consensus: '47.4',
    Previews: '47.9',
    IsPlanned: true
  },
  {
    Time: 1582275600,
    Symbol: 'EUR',
    Title: 'Flash Services PMI',
    Country: 'European Union',
    Priority: '1',
    Actual: '52.8',
    Consensus: '52.4',
    Previews: '52.5',
    IsPlanned: true
  },
  {
    Time: 1582279200,
    Symbol: 'EUR',
    Title: 'Final CPI y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.4%',
    Consensus: '1.4%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1582279200,
    Symbol: 'EUR',
    Title: 'Final Core CPI y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.1%',
    Consensus: '1.1%',
    Previews: '1.1%',
    IsPlanned: true
  },
  {
    Time: 1582293600,
    Symbol: 'EUR',
    Title: 'Belgian NBB Business Climate',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.7',
    Consensus: '-2.8',
    Previews: '-2.0',
    IsPlanned: true
  },
  {
    Time: 1582534800,
    Symbol: 'EUR',
    Title: 'German Ifo Business Climate',
    Country: 'European Union',
    Priority: '2',
    Actual: '96.1',
    Consensus: '95.0',
    Previews: '96.0',
    IsPlanned: true
  },
  {
    Time: 1582614000,
    Symbol: 'EUR',
    Title: 'German Final GDP q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.0%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1582723800,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582790400,
    Symbol: 'EUR',
    Title: 'Spanish Flash CPI y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.8%',
    Previews: '1.1%',
    IsPlanned: true
  },
  {
    Time: 1582794000,
    Symbol: 'EUR',
    Title: 'M3 Money Supply y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '5.2%',
    Consensus: '5.3%',
    Previews: '4.9%',
    IsPlanned: true
  },
  {
    Time: 1582794000,
    Symbol: 'EUR',
    Title: 'Private Loans y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '3.7%',
    Consensus: '3.7%',
    Previews: '3.6%',
    IsPlanned: true
  },
  {
    Time: 1582798740,
    Symbol: 'EUR',
    Title: 'Italian 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.00|1.3',
    Consensus: '-',
    Previews: '0.94|1.2',
    IsPlanned: true
  },
  {
    Time: 1582848000,
    Symbol: 'EUR',
    Title: 'German Prelim CPI m/m',
    Country: 'European Union',
    Priority: '1',
    Actual: '0.4%',
    Consensus: '0.3%',
    Previews: '-0.6%',
    IsPlanned: true
  },
  {
    Time: 1582873200,
    Symbol: 'EUR',
    Title: 'German Import Prices m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.4%',
    Consensus: '0.1%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1582875900,
    Symbol: 'EUR',
    Title: 'French Consumer Spending m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-1.1%',
    Consensus: '-0.1%',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1582875900,
    Symbol: 'EUR',
    Title: 'French Prelim CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.0%',
    Previews: '-0.4%',
    IsPlanned: true
  },
  {
    Time: 1582875900,
    Symbol: 'EUR',
    Title: 'French Prelim GDP q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.1%',
    Consensus: '-0.1%',
    Previews: '-0.1%',
    IsPlanned: true
  },
  {
    Time: 1582880100,
    Symbol: 'EUR',
    Title: 'German Unemployment Change',
    Country: 'European Union',
    Priority: '0',
    Actual: '-10K',
    Consensus: '4K',
    Previews: '-4K',
    IsPlanned: true
  },
  {
    Time: 1582884000,
    Symbol: 'EUR',
    Title: 'German Buba President Weidmann Speaks',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582884000,
    Symbol: 'EUR',
    Title: 'Italian Prelim CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1580722200,
    Symbol: 'GBP',
    Title: 'Final Manufacturing PMI',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '50.0',
    Consensus: '49.8',
    Previews: '49.8',
    IsPlanned: true
  },
  {
    Time: 1580808600,
    Symbol: 'GBP',
    Title: 'Construction PMI',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '48.4',
    Consensus: '48.1',
    Previews: '44.4',
    IsPlanned: true
  },
  {
    Time: 1580895000,
    Symbol: 'GBP',
    Title: 'Final Services PMI',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '53.9',
    Consensus: '52.9',
    Previews: '52.9',
    IsPlanned: true
  },
  {
    Time: 1581064200,
    Symbol: 'GBP',
    Title: 'Halifax HPI m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.4%',
    Consensus: '0.6%',
    Previews: '1.8%',
    IsPlanned: true
  },
  {
    Time: 1581379260,
    Symbol: 'GBP',
    Title: 'BRC Retail Sales Monitor y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.6%',
    Previews: '1.7%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Construction Output m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.4%',
    Consensus: '-0.4%',
    Previews: '2.4%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'GDP m/m',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '0.3%',
    Consensus: '0.2%',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Goods Trade Balance',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.8B',
    Consensus: '-10.0B',
    Previews: '-4.9B',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Index of Services 3m/3m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.0%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Industrial Production m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.3%',
    Previews: '-1.1%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Manufacturing Production m/m',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '0.3%',
    Consensus: '0.5%',
    Previews: '-1.6%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Prelim Business Investment q/q',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-1.0%',
    Consensus: '-0.5%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Prelim GDP q/q',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '0.0%',
    Consensus: '0.0%',
    Previews: '0.4%',
    IsPlanned: true
  },
  {
    Time: 1581435300,
    Symbol: 'GBP',
    Title: 'BOE Gov Carney Speaks',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581440400,
    Symbol: 'GBP',
    Title: 'MPC Member Haskel Speaks',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581442620,
    Symbol: 'GBP',
    Title: 'NIESR GDP Estimate',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581517800,
    Symbol: 'GBP',
    Title: 'CB Leading Index m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1581552060,
    Symbol: 'GBP',
    Title: 'RICS House Price Balance',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '17%',
    Consensus: '3%',
    Previews: '0%',
    IsPlanned: true
  },
  {
    Time: 1581897660,
    Symbol: 'GBP',
    Title: 'Rightmove HPI m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '-',
    Previews: '2.3%',
    IsPlanned: true
  },
  {
    Time: 1582018200,
    Symbol: 'GBP',
    Title: 'Average Earnings Index 3m/y',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '2.9%',
    Consensus: '3.1%',
    Previews: '3.2%',
    IsPlanned: true
  },
  {
    Time: 1582018200,
    Symbol: 'GBP',
    Title: 'Claimant Count Change',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '5.5K',
    Consensus: '20.2K',
    Previews: '2.6K',
    IsPlanned: true
  },
  {
    Time: 1582018200,
    Symbol: 'GBP',
    Title: 'Unemployment Rate',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '3.8%',
    Consensus: '3.8%',
    Previews: '3.8%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'CPI y/y',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '1.8%',
    Consensus: '1.6%',
    Previews: '1.3%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'Core CPI y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '1.6%',
    Consensus: '1.5%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'HPI y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '2.2%',
    Consensus: '2.3%',
    Previews: '1.7%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'PPI Input m/m',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '0.9%',
    Consensus: '-0.5%',
    Previews: '0.9%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'PPI Output m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.1%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'RPI y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '2.7%',
    Consensus: '2.5%',
    Previews: '2.2%',
    IsPlanned: true
  },
  {
    Time: 1582191000,
    Symbol: 'GBP',
    Title: 'Retail Sales m/m',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '0.9%',
    Consensus: '0.7%',
    Previews: '-0.5%',
    IsPlanned: true
  },
  {
    Time: 1582196400,
    Symbol: 'GBP',
    Title: 'CBI Industrial Order Expectations',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-18',
    Consensus: '-19',
    Previews: '-22',
    IsPlanned: true
  },
  {
    Time: 1582277400,
    Symbol: 'GBP',
    Title: 'Flash Manufacturing PMI',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '51.9',
    Consensus: '49.7',
    Previews: '50.0',
    IsPlanned: true
  },
  {
    Time: 1582277400,
    Symbol: 'GBP',
    Title: 'Flash Services PMI',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '53.3',
    Consensus: '53.4',
    Previews: '53.9',
    IsPlanned: true
  },
  {
    Time: 1582277400,
    Symbol: 'GBP',
    Title: 'Public Sector Net Borrowing',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-10.5B',
    Consensus: '-12.0B',
    Previews: '3.5B',
    IsPlanned: true
  },
  {
    Time: 1582309800,
    Symbol: 'GBP',
    Title: 'MPC Member Tenreyro Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582569000,
    Symbol: 'GBP',
    Title: 'MPC Member Haldane Speaks',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582626960,
    Symbol: 'GBP',
    Title: '10-y Bond Auction',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.51|2.2',
    Consensus: '-',
    Previews: '0.50|2.3',
    IsPlanned: true
  },
  {
    Time: 1582628400,
    Symbol: 'GBP',
    Title: 'CBI Realized Sales',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '1',
    Consensus: '4',
    Previews: '0',
    IsPlanned: true
  },
  {
    Time: 1582675260,
    Symbol: 'GBP',
    Title: 'BRC Shop Price Index y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-0.6%',
    Consensus: '-',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1582799400,
    Symbol: 'GBP',
    Title: 'MPC Member Cunliffe Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582848060,
    Symbol: 'GBP',
    Title: 'GfK Consumer Confidence',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-7',
    Consensus: '-8',
    Previews: '-9',
    IsPlanned: true
  },
  {
    Time: 1582873140,
    Symbol: 'GBP',
    Title: 'Nationwide HPI m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.4%',
    Previews: '0.5%',
    IsPlanned: true
  },
  {
    Time: 1582888500,
    Symbol: 'GBP',
    Title: 'MPC Member Haldane Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582906500,
    Symbol: 'GBP',
    Title: 'MPC Member Cunliffe Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580689800,
    Symbol: 'JPY',
    Title: 'Final Manufacturing PMI',
    Country: 'Japan',
    Priority: '0',
    Actual: '48.8',
    Consensus: '49.3',
    Previews: '49.3',
    IsPlanned: true
  },
  {
    Time: 1580773800,
    Symbol: 'JPY',
    Title: 'Monetary Base y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.9%',
    Consensus: '3.2%',
    Previews: '3.2%',
    IsPlanned: true
  },
  {
    Time: 1580787300,
    Symbol: 'JPY',
    Title: '10-y Bond Auction',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.05|3.6',
    Consensus: '-',
    Previews: '-0.01|3.7',
    IsPlanned: true
  },
  {
    Time: 1580960100,
    Symbol: 'JPY',
    Title: '30-y Bond Auction',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.43|3.7',
    Consensus: '-',
    Previews: '0.43|3.7',
    IsPlanned: true
  },
  {
    Time: 1581031800,
    Symbol: 'JPY',
    Title: 'Average Cash Earnings y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581031800,
    Symbol: 'JPY',
    Title: 'Household Spending y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-4.8%',
    Consensus: '-1.7%',
    Previews: '-2.0%',
    IsPlanned: true
  },
  {
    Time: 1581051600,
    Symbol: 'JPY',
    Title: 'Leading Indicators',
    Country: 'Japan',
    Priority: '0',
    Actual: '91.6%',
    Consensus: '91.4%',
    Previews: '90.8%',
    IsPlanned: true
  },
  {
    Time: 1581292200,
    Symbol: 'JPY',
    Title: 'Bank Lending y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.9%',
    Consensus: '1.7%',
    Previews: '1.8%',
    IsPlanned: true
  },
  {
    Time: 1581292200,
    Symbol: 'JPY',
    Title: 'Current Account',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.71T',
    Consensus: '1.68T',
    Previews: '1.79T',
    IsPlanned: true
  },
  {
    Time: 1581310920,
    Symbol: 'JPY',
    Title: 'Economy Watchers Sentiment',
    Country: 'Japan',
    Priority: '0',
    Actual: '41.9',
    Consensus: '39.2',
    Previews: '39.8',
    IsPlanned: true
  },
  {
    Time: 1581379200,
    Symbol: 'JPY',
    Title: 'Bank Holiday',
    Country: 'Japan',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581465000,
    Symbol: 'JPY',
    Title: 'M2 Money Stock y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.8%',
    Consensus: '2.8%',
    Previews: '2.7%',
    IsPlanned: true
  },
  {
    Time: 1581487140,
    Symbol: 'JPY',
    Title: 'Prelim Machine Tool Orders y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-35.6%',
    Consensus: '-',
    Previews: '-33.5%',
    IsPlanned: true
  },
  {
    Time: 1581551400,
    Symbol: 'JPY',
    Title: 'PPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.7%',
    Consensus: '1.5%',
    Previews: '0.9%',
    IsPlanned: true
  },
  {
    Time: 1581654600,
    Symbol: 'JPY',
    Title: 'Tertiary Industry Activity m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '0.1%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1581897000,
    Symbol: 'JPY',
    Title: 'Prelim GDP Price Index y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.3%',
    Consensus: '1.1%',
    Previews: '0.6%',
    IsPlanned: true
  },
  {
    Time: 1581897000,
    Symbol: 'JPY',
    Title: 'Prelim GDP q/q',
    Country: 'Japan',
    Priority: '0',
    Actual: '-1.6%',
    Consensus: '-1.0%',
    Previews: '0.4%',
    IsPlanned: true
  },
  {
    Time: 1581913800,
    Symbol: 'JPY',
    Title: 'Revised Industrial Production m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.2%',
    Consensus: '1.3%',
    Previews: '1.3%',
    IsPlanned: true
  },
  {
    Time: 1582069800,
    Symbol: 'JPY',
    Title: 'Core Machinery Orders m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '-12.5%',
    Consensus: '-9.0%',
    Previews: '18.0%',
    IsPlanned: true
  },
  {
    Time: 1582069800,
    Symbol: 'JPY',
    Title: 'Trade Balance',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.22T',
    Consensus: '-0.55T',
    Previews: '-0.11T',
    IsPlanned: true
  },
  {
    Time: 1582241400,
    Symbol: 'JPY',
    Title: 'National Core CPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.7%',
    Previews: '0.7%',
    IsPlanned: true
  },
  {
    Time: 1582245000,
    Symbol: 'JPY',
    Title: 'Flash Manufacturing PMI',
    Country: 'Japan',
    Priority: '0',
    Actual: '47.6',
    Consensus: '47.9',
    Previews: '48.8',
    IsPlanned: true
  },
  {
    Time: 1582259400,
    Symbol: 'JPY',
    Title: 'All Industries Activity m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.3%',
    Previews: '0.9%',
    IsPlanned: true
  },
  {
    Time: 1582502400,
    Symbol: 'JPY',
    Title: 'Bank Holiday',
    Country: 'Japan',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582588200,
    Symbol: 'JPY',
    Title: 'SPPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.3%',
    Consensus: '2.1%',
    Previews: '2.1%',
    IsPlanned: true
  },
  {
    Time: 1582693200,
    Symbol: 'JPY',
    Title: 'BOJ Core CPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.2%',
    Previews: '0.3%',
    IsPlanned: true
  },
  {
    Time: 1582846200,
    Symbol: 'JPY',
    Title: 'Tokyo Core CPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.5%',
    Consensus: '0.6%',
    Previews: '0.7%',
    IsPlanned: true
  },
  {
    Time: 1582846200,
    Symbol: 'JPY',
    Title: 'Unemployment Rate',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.4%',
    Consensus: '2.2%',
    Previews: '2.2%',
    IsPlanned: true
  },
  {
    Time: 1582847400,
    Symbol: 'JPY',
    Title: 'Prelim Industrial Production m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.2%',
    Previews: '1.2%',
    IsPlanned: true
  },
  {
    Time: 1582847400,
    Symbol: 'JPY',
    Title: 'Retail Sales y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.4%',
    Consensus: '-1.3%',
    Previews: '-2.6%',
    IsPlanned: true
  },
  {
    Time: 1582866000,
    Symbol: 'JPY',
    Title: 'Housing Starts y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-10.1%',
    Consensus: '-5.8%',
    Previews: '-7.9%',
    IsPlanned: true
  }
]

export const marketingIndicatorsDataMapByName: { [chartName: string]: object } = {
  ['EURUSD5']: {
    [demoIndicators[0].name]: defaultMA
  },
  ['EURUSD60']: {
    [demoIndicators[0].name]: demoMaH1
  },
  ['EURUSD240']: {
    [demoIndicators[0].name]: demoMaH4
  }
}

export const marketingIndicatorsMapByChart: { [chartName: string]: string[] } = {
  ['EURUSD5']: [demoIndicators[0].name],
  ['EURUSD60']: [demoIndicators[0].name],
  ['EURUSD240']: [demoIndicators[0].name]
}
