export const demoMaH1 = [
  {
    t: 1580706000,
    v: 1.1083425000000002
  },
  {
    t: 1580709600,
    v: 1.108315
  },
  {
    t: 1580713200,
    v: 1.1081362499999998
  },
  {
    t: 1580716800,
    v: 1.10794
  },
  {
    t: 1580720400,
    v: 1.10781
  },
  {
    t: 1580724000,
    v: 1.10759625
  },
  {
    t: 1580727600,
    v: 1.1073650000000002
  },
  {
    t: 1580731200,
    v: 1.1071662500000001
  },
  {
    t: 1580734800,
    v: 1.10689875
  },
  {
    t: 1580738400,
    v: 1.1064599999999998
  },
  {
    t: 1580742000,
    v: 1.10622125
  },
  {
    t: 1580745600,
    v: 1.1061712499999998
  },
  {
    t: 1580749200,
    v: 1.105995
  },
  {
    t: 1580752800,
    v: 1.10595125
  },
  {
    t: 1580756400,
    v: 1.1059612500000002
  },
  {
    t: 1580760000,
    v: 1.105865
  },
  {
    t: 1580763600,
    v: 1.10581875
  },
  {
    t: 1580767200,
    v: 1.10596625
  },
  {
    t: 1580770800,
    v: 1.10605
  },
  {
    t: 1580774400,
    v: 1.1060362499999998
  },
  {
    t: 1580778000,
    v: 1.10605625
  },
  {
    t: 1580781600,
    v: 1.1060225
  },
  {
    t: 1580785200,
    v: 1.1059525
  },
  {
    t: 1580788800,
    v: 1.105975
  },
  {
    t: 1580792400,
    v: 1.10596625
  },
  {
    t: 1580796000,
    v: 1.10596625
  },
  {
    t: 1580799600,
    v: 1.105815
  },
  {
    t: 1580803200,
    v: 1.10577625
  },
  {
    t: 1580806800,
    v: 1.10572125
  },
  {
    t: 1580810400,
    v: 1.10568125
  },
  {
    t: 1580814000,
    v: 1.10563375
  },
  {
    t: 1580817600,
    v: 1.105435
  },
  {
    t: 1580821200,
    v: 1.1052575
  },
  {
    t: 1580824800,
    v: 1.10507375
  },
  {
    t: 1580828400,
    v: 1.1049237500000002
  },
  {
    t: 1580832000,
    v: 1.10473625
  },
  {
    t: 1580835600,
    v: 1.1045037500000001
  },
  {
    t: 1580839200,
    v: 1.1043224999999999
  },
  {
    t: 1580842800,
    v: 1.10417125
  },
  {
    t: 1580846400,
    v: 1.10417375
  },
  {
    t: 1580850000,
    v: 1.10416125
  },
  {
    t: 1580853600,
    v: 1.10416125
  },
  {
    t: 1580857200,
    v: 1.10424125
  },
  {
    t: 1580860800,
    v: 1.104245
  },
  {
    t: 1580864400,
    v: 1.10428375
  },
  {
    t: 1580868000,
    v: 1.10420125
  },
  {
    t: 1580871600,
    v: 1.10410375
  },
  {
    t: 1580875200,
    v: 1.1039825
  },
  {
    t: 1580878800,
    v: 1.1039125
  },
  {
    t: 1580882400,
    v: 1.103805
  },
  {
    t: 1580886000,
    v: 1.1037075
  },
  {
    t: 1580889600,
    v: 1.1036937500000001
  },
  {
    t: 1580893200,
    v: 1.10351625
  },
  {
    t: 1580896800,
    v: 1.1033275
  },
  {
    t: 1580900400,
    v: 1.1031749999999998
  },
  {
    t: 1580904000,
    v: 1.1030225
  },
  {
    t: 1580907600,
    v: 1.1027025
  },
  {
    t: 1580911200,
    v: 1.1022862500000001
  },
  {
    t: 1580914800,
    v: 1.1018525
  },
  {
    t: 1580918400,
    v: 1.1014037499999998
  },
  {
    t: 1580922000,
    v: 1.1010912499999999
  },
  {
    t: 1580925600,
    v: 1.10087875
  },
  {
    t: 1580929200,
    v: 1.100555
  },
  {
    t: 1580932800,
    v: 1.1002425000000002
  },
  {
    t: 1580936400,
    v: 1.1000762499999999
  },
  {
    t: 1580940000,
    v: 1.1000387500000002
  },
  {
    t: 1580943600,
    v: 1.10001625
  },
  {
    t: 1580947200,
    v: 1.0998937500000001
  },
  {
    t: 1580950800,
    v: 1.0998575000000002
  },
  {
    t: 1580954400,
    v: 1.0998
  },
  {
    t: 1580958000,
    v: 1.09981375
  },
  {
    t: 1580961600,
    v: 1.0997925
  },
  {
    t: 1580965200,
    v: 1.0998025
  },
  {
    t: 1580968800,
    v: 1.09981
  },
  {
    t: 1580972400,
    v: 1.0998425
  },
  {
    t: 1580976000,
    v: 1.0998325
  },
  {
    t: 1580979600,
    v: 1.09984375
  },
  {
    t: 1580983200,
    v: 1.0998875
  },
  {
    t: 1580986800,
    v: 1.099925
  },
  {
    t: 1580990400,
    v: 1.10001
  },
  {
    t: 1580994000,
    v: 1.1000325
  },
  {
    t: 1580997600,
    v: 1.09988375
  },
  {
    t: 1581001200,
    v: 1.09955625
  },
  {
    t: 1581004800,
    v: 1.09933375
  },
  {
    t: 1581008400,
    v: 1.09903375
  },
  {
    t: 1581012000,
    v: 1.09868375
  },
  {
    t: 1581015600,
    v: 1.0983924999999999
  },
  {
    t: 1581019200,
    v: 1.09809625
  },
  {
    t: 1581022800,
    v: 1.09786125
  },
  {
    t: 1581026400,
    v: 1.0977675000000002
  },
  {
    t: 1581030000,
    v: 1.09781625
  },
  {
    t: 1581033600,
    v: 1.09786375
  },
  {
    t: 1581037200,
    v: 1.0979625
  },
  {
    t: 1581040800,
    v: 1.09810625
  },
  {
    t: 1581044400,
    v: 1.0981850000000002
  },
  {
    t: 1581048000,
    v: 1.0982162500000001
  },
  {
    t: 1581051600,
    v: 1.09819125
  },
  {
    t: 1581055200,
    v: 1.0981524999999999
  },
  {
    t: 1581058800,
    v: 1.09797875
  },
  {
    t: 1581062400,
    v: 1.0976875
  },
  {
    t: 1581066000,
    v: 1.09733
  },
  {
    t: 1581069600,
    v: 1.096945
  },
  {
    t: 1581073200,
    v: 1.09655375
  },
  {
    t: 1581076800,
    v: 1.09634625
  },
  {
    t: 1581080400,
    v: 1.0960637500000001
  },
  {
    t: 1581084000,
    v: 1.095985
  },
  {
    t: 1581087600,
    v: 1.0959425
  },
  {
    t: 1581091200,
    v: 1.0958325
  },
  {
    t: 1581094800,
    v: 1.0956875000000001
  },
  {
    t: 1581098400,
    v: 1.0955599999999999
  },
  {
    t: 1581102000,
    v: 1.09548875
  },
  {
    t: 1581105600,
    v: 1.0952712500000001
  },
  {
    t: 1581109200,
    v: 1.0950925
  },
  {
    t: 1581285600,
    v: 1.094745
  },
  {
    t: 1581289200,
    v: 1.0945675000000001
  },
  {
    t: 1581292800,
    v: 1.09460875
  },
  {
    t: 1581296400,
    v: 1.094705
  },
  {
    t: 1581300000,
    v: 1.09482875
  },
  {
    t: 1581303600,
    v: 1.09490625
  },
  {
    t: 1581307200,
    v: 1.095025
  },
  {
    t: 1581310800,
    v: 1.09517
  },
  {
    t: 1581314400,
    v: 1.0952674999999998
  },
  {
    t: 1581318000,
    v: 1.09523625
  },
  {
    t: 1581321600,
    v: 1.09521875
  },
  {
    t: 1581325200,
    v: 1.09521
  },
  {
    t: 1581328800,
    v: 1.09515875
  },
  {
    t: 1581332400,
    v: 1.0951187500000001
  },
  {
    t: 1581336000,
    v: 1.0950250000000001
  },
  {
    t: 1581339600,
    v: 1.0947387499999999
  },
  {
    t: 1581343200,
    v: 1.09444125
  },
  {
    t: 1581346800,
    v: 1.09416
  },
  {
    t: 1581350400,
    v: 1.0937062499999999
  },
  {
    t: 1581354000,
    v: 1.0932125
  },
  {
    t: 1581357600,
    v: 1.0927650000000002
  },
  {
    t: 1581361200,
    v: 1.09232375
  },
  {
    t: 1581364800,
    v: 1.0918625
  },
  {
    t: 1581368400,
    v: 1.0915875000000002
  },
  {
    t: 1581372000,
    v: 1.0913775
  },
  {
    t: 1581375600,
    v: 1.0912025
  },
  {
    t: 1581379200,
    v: 1.09114
  },
  {
    t: 1581382800,
    v: 1.09118625
  },
  {
    t: 1581386400,
    v: 1.09119
  },
  {
    t: 1581390000,
    v: 1.09118625
  },
  {
    t: 1581393600,
    v: 1.0911487500000001
  },
  {
    t: 1581397200,
    v: 1.0911700000000002
  },
  {
    t: 1581400800,
    v: 1.091175
  },
  {
    t: 1581404400,
    v: 1.0912262499999998
  },
  {
    t: 1581408000,
    v: 1.091235
  },
  {
    t: 1581411600,
    v: 1.09123125
  },
  {
    t: 1581415200,
    v: 1.091285
  },
  {
    t: 1581418800,
    v: 1.09127375
  },
  {
    t: 1581422400,
    v: 1.0912712500000001
  },
  {
    t: 1581426000,
    v: 1.0911625
  },
  {
    t: 1581429600,
    v: 1.0912762500000002
  },
  {
    t: 1581433200,
    v: 1.09129875
  },
  {
    t: 1581436800,
    v: 1.09137
  },
  {
    t: 1581440400,
    v: 1.09147125
  },
  {
    t: 1581444000,
    v: 1.09148
  },
  {
    t: 1581447600,
    v: 1.09159125
  },
  {
    t: 1581451200,
    v: 1.09172
  },
  {
    t: 1581454800,
    v: 1.091855
  },
  {
    t: 1581458400,
    v: 1.0918337500000002
  },
  {
    t: 1581462000,
    v: 1.0918700000000001
  },
  {
    t: 1581465600,
    v: 1.09182625
  },
  {
    t: 1581469200,
    v: 1.09178125
  },
  {
    t: 1581472800,
    v: 1.09174375
  },
  {
    t: 1581476400,
    v: 1.09166375
  },
  {
    t: 1581480000,
    v: 1.0915725
  },
  {
    t: 1581483600,
    v: 1.0915249999999999
  },
  {
    t: 1581487200,
    v: 1.0913637500000002
  },
  {
    t: 1581490800,
    v: 1.09131
  },
  {
    t: 1581494400,
    v: 1.0914187499999999
  },
  {
    t: 1581498000,
    v: 1.09144625
  },
  {
    t: 1581501600,
    v: 1.09147375
  },
  {
    t: 1581505200,
    v: 1.0914337500000002
  },
  {
    t: 1581508800,
    v: 1.09143625
  },
  {
    t: 1581512400,
    v: 1.091365
  },
  {
    t: 1581516000,
    v: 1.0913437499999998
  },
  {
    t: 1581519600,
    v: 1.09109125
  },
  {
    t: 1581523200,
    v: 1.09072375
  },
  {
    t: 1581526800,
    v: 1.0902049999999999
  },
  {
    t: 1581530400,
    v: 1.08968375
  },
  {
    t: 1581534000,
    v: 1.0891137499999999
  },
  {
    t: 1581537600,
    v: 1.08865
  },
  {
    t: 1581541200,
    v: 1.08825
  },
  {
    t: 1581544800,
    v: 1.0878462499999997
  },
  {
    t: 1581548400,
    v: 1.0876112500000001
  },
  {
    t: 1581552000,
    v: 1.08736375
  },
  {
    t: 1581555600,
    v: 1.0872899999999999
  },
  {
    t: 1581559200,
    v: 1.08721
  },
  {
    t: 1581562800,
    v: 1.08725625
  },
  {
    t: 1581566400,
    v: 1.08717875
  },
  {
    t: 1581570000,
    v: 1.0871624999999998
  },
  {
    t: 1581573600,
    v: 1.0871600000000001
  },
  {
    t: 1581577200,
    v: 1.087205
  },
  {
    t: 1581580800,
    v: 1.08738625
  },
  {
    t: 1581584400,
    v: 1.0875099999999998
  },
  {
    t: 1581588000,
    v: 1.08755875
  },
  {
    t: 1581591600,
    v: 1.08740375
  },
  {
    t: 1581595200,
    v: 1.08733875
  },
  {
    t: 1581598800,
    v: 1.0871300000000002
  },
  {
    t: 1581602400,
    v: 1.0869287500000002
  },
  {
    t: 1581606000,
    v: 1.08654625
  },
  {
    t: 1581609600,
    v: 1.08605125
  },
  {
    t: 1581613200,
    v: 1.08554625
  },
  {
    t: 1581616800,
    v: 1.0851475
  },
  {
    t: 1581620400,
    v: 1.08496875
  },
  {
    t: 1581624000,
    v: 1.0846875
  },
  {
    t: 1581627600,
    v: 1.0845125
  },
  {
    t: 1581631200,
    v: 1.08432125
  },
  {
    t: 1581634800,
    v: 1.08423
  },
  {
    t: 1581638400,
    v: 1.08411375
  },
  {
    t: 1581642000,
    v: 1.084035
  },
  {
    t: 1581645600,
    v: 1.08397125
  },
  {
    t: 1581649200,
    v: 1.0838174999999999
  },
  {
    t: 1581652800,
    v: 1.08371375
  },
  {
    t: 1581656400,
    v: 1.08366625
  },
  {
    t: 1581660000,
    v: 1.0836075
  },
  {
    t: 1581663600,
    v: 1.08360375
  },
  {
    t: 1581667200,
    v: 1.08366625
  },
  {
    t: 1581670800,
    v: 1.0837750000000002
  },
  {
    t: 1581674400,
    v: 1.0837999999999999
  },
  {
    t: 1581678000,
    v: 1.083875
  },
  {
    t: 1581681600,
    v: 1.08396
  },
  {
    t: 1581685200,
    v: 1.08418625
  },
  {
    t: 1581688800,
    v: 1.0844475
  },
  {
    t: 1581692400,
    v: 1.08450625
  },
  {
    t: 1581696000,
    v: 1.08445875
  },
  {
    t: 1581699600,
    v: 1.08444
  },
  {
    t: 1581703200,
    v: 1.08449875
  },
  {
    t: 1581706800,
    v: 1.08452875
  },
  {
    t: 1581710400,
    v: 1.08449625
  },
  {
    t: 1581714000,
    v: 1.08418125
  },
  {
    t: 1581890400,
    v: 1.0839587499999999
  },
  {
    t: 1581894000,
    v: 1.0839012499999998
  },
  {
    t: 1581897600,
    v: 1.0838587499999999
  },
  {
    t: 1581901200,
    v: 1.08377375
  },
  {
    t: 1581904800,
    v: 1.08371875
  },
  {
    t: 1581908400,
    v: 1.0837062499999999
  },
  {
    t: 1581912000,
    v: 1.08371125
  },
  {
    t: 1581915600,
    v: 1.0838312499999998
  },
  {
    t: 1581919200,
    v: 1.08379
  },
  {
    t: 1581922800,
    v: 1.0837837499999998
  },
  {
    t: 1581926400,
    v: 1.08391375
  },
  {
    t: 1581930000,
    v: 1.08400875
  },
  {
    t: 1581933600,
    v: 1.0841037500000001
  },
  {
    t: 1581937200,
    v: 1.0841125
  },
  {
    t: 1581940800,
    v: 1.084095
  },
  {
    t: 1581944400,
    v: 1.08405125
  },
  {
    t: 1581948000,
    v: 1.0841
  },
  {
    t: 1581951600,
    v: 1.0839625
  },
  {
    t: 1581955200,
    v: 1.0838524999999999
  },
  {
    t: 1581958800,
    v: 1.0837012499999998
  },
  {
    t: 1581962400,
    v: 1.0835249999999998
  },
  {
    t: 1581966000,
    v: 1.0834575
  },
  {
    t: 1581969600,
    v: 1.0834225
  },
  {
    t: 1581973200,
    v: 1.08340375
  },
  {
    t: 1581976800,
    v: 1.0833737499999998
  },
  {
    t: 1581980400,
    v: 1.08346125
  },
  {
    t: 1581984000,
    v: 1.08331625
  },
  {
    t: 1581987600,
    v: 1.08326125
  },
  {
    t: 1581991200,
    v: 1.083205
  },
  {
    t: 1581994800,
    v: 1.08318875
  },
  {
    t: 1581998400,
    v: 1.083145
  },
  {
    t: 1582002000,
    v: 1.0831025
  },
  {
    t: 1582005600,
    v: 1.08302
  },
  {
    t: 1582009200,
    v: 1.082975
  },
  {
    t: 1582012800,
    v: 1.08298125
  },
  {
    t: 1582016400,
    v: 1.08297625
  },
  {
    t: 1582020000,
    v: 1.0829575
  },
  {
    t: 1582023600,
    v: 1.08285875
  },
  {
    t: 1582027200,
    v: 1.0826637499999998
  },
  {
    t: 1582030800,
    v: 1.08230125
  },
  {
    t: 1582034400,
    v: 1.08202375
  },
  {
    t: 1582038000,
    v: 1.081895
  },
  {
    t: 1582041600,
    v: 1.08174875
  },
  {
    t: 1582045200,
    v: 1.08146
  },
  {
    t: 1582048800,
    v: 1.08112625
  },
  {
    t: 1582052400,
    v: 1.0807437500000001
  },
  {
    t: 1582056000,
    v: 1.0805125
  },
  {
    t: 1582059600,
    v: 1.08037
  },
  {
    t: 1582063200,
    v: 1.0802
  },
  {
    t: 1582066800,
    v: 1.0798525
  },
  {
    t: 1582070400,
    v: 1.07964375
  },
  {
    t: 1582074000,
    v: 1.0796175
  },
  {
    t: 1582077600,
    v: 1.07960375
  },
  {
    t: 1582081200,
    v: 1.0796649999999999
  },
  {
    t: 1582084800,
    v: 1.0796825
  },
  {
    t: 1582088400,
    v: 1.07974125
  },
  {
    t: 1582092000,
    v: 1.07979
  },
  {
    t: 1582095600,
    v: 1.07987625
  },
  {
    t: 1582099200,
    v: 1.07995
  },
  {
    t: 1582102800,
    v: 1.0799637500000001
  },
  {
    t: 1582106400,
    v: 1.0799837499999998
  },
  {
    t: 1582110000,
    v: 1.0798925
  },
  {
    t: 1582113600,
    v: 1.0799375
  },
  {
    t: 1582117200,
    v: 1.0799124999999998
  },
  {
    t: 1582120800,
    v: 1.07977375
  },
  {
    t: 1582124400,
    v: 1.0796412499999999
  },
  {
    t: 1582128000,
    v: 1.07951
  },
  {
    t: 1582131600,
    v: 1.07936875
  },
  {
    t: 1582135200,
    v: 1.07934125
  },
  {
    t: 1582138800,
    v: 1.07940125
  },
  {
    t: 1582142400,
    v: 1.07951625
  },
  {
    t: 1582146000,
    v: 1.07962625
  },
  {
    t: 1582149600,
    v: 1.07988625
  },
  {
    t: 1582153200,
    v: 1.0801162499999999
  },
  {
    t: 1582156800,
    v: 1.08033875
  },
  {
    t: 1582160400,
    v: 1.08044125
  },
  {
    t: 1582164000,
    v: 1.08037
  },
  {
    t: 1582167600,
    v: 1.0803775
  },
  {
    t: 1582171200,
    v: 1.0802337499999999
  },
  {
    t: 1582174800,
    v: 1.0801625000000001
  },
  {
    t: 1582178400,
    v: 1.07991625
  },
  {
    t: 1582182000,
    v: 1.0796775
  },
  {
    t: 1582185600,
    v: 1.07949875
  },
  {
    t: 1582189200,
    v: 1.0794949999999999
  },
  {
    t: 1582192800,
    v: 1.07949125
  },
  {
    t: 1582196400,
    v: 1.07948375
  },
  {
    t: 1582200000,
    v: 1.0793537500000001
  },
  {
    t: 1582203600,
    v: 1.0794537499999999
  },
  {
    t: 1582207200,
    v: 1.0798125
  },
  {
    t: 1582210800,
    v: 1.07996
  },
  {
    t: 1582214400,
    v: 1.0799575000000001
  },
  {
    t: 1582218000,
    v: 1.07987375
  },
  {
    t: 1582221600,
    v: 1.07988875
  },
  {
    t: 1582225200,
    v: 1.0798024999999998
  },
  {
    t: 1582228800,
    v: 1.07982125
  },
  {
    t: 1582232400,
    v: 1.0795624999999998
  },
  {
    t: 1582236000,
    v: 1.0791875
  },
  {
    t: 1582239600,
    v: 1.0790025
  },
  {
    t: 1582243200,
    v: 1.0789225
  },
  {
    t: 1582246800,
    v: 1.07891875
  },
  {
    t: 1582250400,
    v: 1.0788712500000002
  },
  {
    t: 1582254000,
    v: 1.07888125
  },
  {
    t: 1582257600,
    v: 1.07894375
  },
  {
    t: 1582261200,
    v: 1.0790587500000002
  },
  {
    t: 1582264800,
    v: 1.0791025
  },
  {
    t: 1582268400,
    v: 1.07921
  },
  {
    t: 1582272000,
    v: 1.07950875
  },
  {
    t: 1582275600,
    v: 1.07972125
  },
  {
    t: 1582279200,
    v: 1.07984625
  },
  {
    t: 1582282800,
    v: 1.0800450000000001
  },
  {
    t: 1582286400,
    v: 1.08007
  },
  {
    t: 1582290000,
    v: 1.0802725000000002
  },
  {
    t: 1582293600,
    v: 1.0808575
  },
  {
    t: 1582297200,
    v: 1.08156375
  },
  {
    t: 1582300800,
    v: 1.08206625
  },
  {
    t: 1582304400,
    v: 1.0826625
  },
  {
    t: 1582308000,
    v: 1.08336875
  },
  {
    t: 1582311600,
    v: 1.084015
  },
  {
    t: 1582315200,
    v: 1.08467125
  },
  {
    t: 1582318800,
    v: 1.08512625
  },
  {
    t: 1582495200,
    v: 1.0850324999999998
  },
  {
    t: 1582498800,
    v: 1.084855
  },
  {
    t: 1582502400,
    v: 1.08456625
  },
  {
    t: 1582506000,
    v: 1.0841999999999998
  },
  {
    t: 1582509600,
    v: 1.0837800000000002
  },
  {
    t: 1582513200,
    v: 1.08333375
  },
  {
    t: 1582516800,
    v: 1.08298375
  },
  {
    t: 1582520400,
    v: 1.0826
  },
  {
    t: 1582524000,
    v: 1.08243375
  },
  {
    t: 1582527600,
    v: 1.0821675
  },
  {
    t: 1582531200,
    v: 1.0821087500000002
  },
  {
    t: 1582534800,
    v: 1.0819525
  },
  {
    t: 1582538400,
    v: 1.0818675000000002
  },
  {
    t: 1582542000,
    v: 1.08185
  },
  {
    t: 1582545600,
    v: 1.08181375
  },
  {
    t: 1582549200,
    v: 1.08181
  },
  {
    t: 1582552800,
    v: 1.0822187499999998
  },
  {
    t: 1582556400,
    v: 1.0827025
  },
  {
    t: 1582560000,
    v: 1.0830700000000002
  },
  {
    t: 1582563600,
    v: 1.08375875
  },
  {
    t: 1582567200,
    v: 1.08435875
  },
  {
    t: 1582570800,
    v: 1.08463875
  },
  {
    t: 1582574400,
    v: 1.08506375
  },
  {
    t: 1582578000,
    v: 1.08553
  },
  {
    t: 1582581600,
    v: 1.0855650000000001
  },
  {
    t: 1582585200,
    v: 1.08547375
  },
  {
    t: 1582588800,
    v: 1.0854212500000002
  },
  {
    t: 1582592400,
    v: 1.0852675
  },
  {
    t: 1582596000,
    v: 1.0852625
  },
  {
    t: 1582599600,
    v: 1.08550875
  },
  {
    t: 1582603200,
    v: 1.0856162500000002
  },
  {
    t: 1582606800,
    v: 1.085605
  },
  {
    t: 1582610400,
    v: 1.085605
  },
  {
    t: 1582614000,
    v: 1.0856649999999999
  },
  {
    t: 1582617600,
    v: 1.0858112500000001
  },
  {
    t: 1582621200,
    v: 1.0857125
  },
  {
    t: 1582624800,
    v: 1.085395
  },
  {
    t: 1582628400,
    v: 1.0850675
  },
  {
    t: 1582632000,
    v: 1.08475875
  },
  {
    t: 1582635600,
    v: 1.08463125
  },
  {
    t: 1582639200,
    v: 1.0844325000000001
  },
  {
    t: 1582642800,
    v: 1.084575
  },
  {
    t: 1582646400,
    v: 1.08463375
  },
  {
    t: 1582650000,
    v: 1.085035
  },
  {
    t: 1582653600,
    v: 1.08552
  },
  {
    t: 1582657200,
    v: 1.0860812499999999
  },
  {
    t: 1582660800,
    v: 1.08665875
  },
  {
    t: 1582664400,
    v: 1.08715625
  },
  {
    t: 1582668000,
    v: 1.0877187499999998
  },
  {
    t: 1582671600,
    v: 1.0879062499999999
  },
  {
    t: 1582675200,
    v: 1.08792625
  },
  {
    t: 1582678800,
    v: 1.087815
  },
  {
    t: 1582682400,
    v: 1.0877075
  },
  {
    t: 1582686000,
    v: 1.08752
  },
  {
    t: 1582689600,
    v: 1.08733375
  },
  {
    t: 1582693200,
    v: 1.087275
  },
  {
    t: 1582696800,
    v: 1.0871950000000001
  },
  {
    t: 1582700400,
    v: 1.08708625
  },
  {
    t: 1582704000,
    v: 1.0873625
  },
  {
    t: 1582707600,
    v: 1.0875537499999999
  },
  {
    t: 1582711200,
    v: 1.0877824999999999
  },
  {
    t: 1582714800,
    v: 1.08800625
  },
  {
    t: 1582718400,
    v: 1.0880275
  },
  {
    t: 1582722000,
    v: 1.0878962499999998
  },
  {
    t: 1582725600,
    v: 1.0877112500000001
  },
  {
    t: 1582729200,
    v: 1.0876562500000002
  },
  {
    t: 1582732800,
    v: 1.08758875
  },
  {
    t: 1582736400,
    v: 1.0874587500000001
  },
  {
    t: 1582740000,
    v: 1.0873950000000001
  },
  {
    t: 1582743600,
    v: 1.0875837499999998
  },
  {
    t: 1582747200,
    v: 1.0877775
  },
  {
    t: 1582750800,
    v: 1.0879762499999999
  },
  {
    t: 1582754400,
    v: 1.0882587499999998
  },
  {
    t: 1582758000,
    v: 1.08850625
  },
  {
    t: 1582761600,
    v: 1.08865
  },
  {
    t: 1582765200,
    v: 1.08893125
  },
  {
    t: 1582768800,
    v: 1.0892925
  },
  {
    t: 1582772400,
    v: 1.089365
  },
  {
    t: 1582776000,
    v: 1.08971375
  },
  {
    t: 1582779600,
    v: 1.09004125
  },
  {
    t: 1582783200,
    v: 1.0903987499999999
  },
  {
    t: 1582786800,
    v: 1.0908349999999998
  },
  {
    t: 1582790400,
    v: 1.0914587500000001
  },
  {
    t: 1582794000,
    v: 1.0918850000000002
  },
  {
    t: 1582797600,
    v: 1.0922524999999998
  },
  {
    t: 1582801200,
    v: 1.09275125
  },
  {
    t: 1582804800,
    v: 1.0933975
  },
  {
    t: 1582808400,
    v: 1.0942600000000002
  },
  {
    t: 1582812000,
    v: 1.0950825
  },
  {
    t: 1582815600,
    v: 1.09587
  },
  {
    t: 1582819200,
    v: 1.0962175
  },
  {
    t: 1582822800,
    v: 1.0967725
  },
  {
    t: 1582826400,
    v: 1.09738625
  },
  {
    t: 1582830000,
    v: 1.09791125
  },
  {
    t: 1582833600,
    v: 1.09827
  },
  {
    t: 1582837200,
    v: 1.09856125
  },
  {
    t: 1582840800,
    v: 1.0988850000000001
  },
  {
    t: 1582844400,
    v: 1.099035
  },
  {
    t: 1582848000,
    v: 1.0992975
  },
  {
    t: 1582851600,
    v: 1.09940125
  },
  {
    t: 1582855200,
    v: 1.0993675
  },
  {
    t: 1582858800,
    v: 1.0993625
  },
  {
    t: 1582862400,
    v: 1.0993775000000001
  },
  {
    t: 1582866000,
    v: 1.09938
  },
  {
    t: 1582869600,
    v: 1.09936
  },
  {
    t: 1582873200,
    v: 1.09935125
  },
  {
    t: 1582876800,
    v: 1.09981875
  },
  {
    t: 1582880400,
    v: 1.1005275
  },
  {
    t: 1582884000,
    v: 1.10112625
  },
  {
    t: 1582887600,
    v: 1.10120875
  },
  {
    t: 1582891200,
    v: 1.1011199999999999
  },
  {
    t: 1582894800,
    v: 1.1009562499999999
  },
  {
    t: 1582898400,
    v: 1.10052
  },
  {
    t: 1582902000,
    v: 1.10037875
  },
  {
    t: 1582905600,
    v: 1.0999562500000002
  },
  {
    t: 1582909200,
    v: 1.0994125
  },
  {
    t: 1582912800,
    v: 1.098995
  },
  {
    t: 1582916400,
    v: 1.09944125
  },
  {
    t: 1582920000,
    v: 1.10007625
  },
  {
    t: 1582923600,
    v: 1.1005537500000002
  }
]
