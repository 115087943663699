import shortid from 'shortid'
import { indicatorDefaultLook, oscillatorDefaultLook } from '../constants'
import { defaultATR } from '../defaultData/indicators/default/defaultATR'
import { defaultBearsPower } from '../defaultData/indicators/default/defaultBearsPower'
import { defaultEMA, defaultMA } from '../defaultData/indicators/default/defaultMA'
import { defaultMFI } from '../defaultData/indicators/default/defaultMFI'
import { defaultMomentum } from '../defaultData/indicators/default/defaultMomentum'
import { defaultRsi } from '../defaultData/indicators/default/defaultRsi'
import {
  IGraphicalLine, IndicatorComponentType,
  IndicatorPlacement,
  Oscillators,
  TrendIndicators,
  VolatilityIndicators,
  VolumeIndicators
} from "../enums";
import { IChart, IChartTimeFrame } from '../interfaces/IChart.interface'
import { ICurrentBar } from '../interfaces/ICurrentBar'
import { IChartTools } from '../interfaces/IGraphics'
import { ILastBarIndexes, IServerBar } from '../interfaces/IHistoricalData.interface'
import { IIndicator, IIndicatorCategory, IOscillator } from '../interfaces/IIndicator.interface'
import { INews } from '../interfaces/INews.interface'
import { IAccountInfo } from '../interfaces/IOrder.interface'
import { IProjectPascalCase } from '../interfaces/IProject.interface'

export const defaultProject: IProjectPascalCase = {
  Name: 'Default Project',
  TimeZone: 0,
  DaylightSavingTime: 0,
  TicksGenerated: false,
  UsedTickHistory: false,
  UsedFloatingSpread: false,
  ForwardTestingOnly: false,
  FromDate: 1585699200, // 04/01/2020 @ 12:00am (UTC)
  ToDate: 1588204800, // 04/30/2020 @ 12:00am (UTC)
  StartDate: 1585699200, // 04/01/2020 @ 12:00am (UTC)
  CurrentDate: 1585785600, // 04/02/2020 @ 12:00am (UTC)
  InitialDeposit: 10000,
  Symbols: ['EURUSD', 'GBPUSD', 'USDJPY'],
  Balance: 10000,
  Equity: 10000,
  Margin: 0,
  FreeMargin: 0,
  MarketOrders: [],
  PendingOrders: [],
  ClosedOrders: [],
  Notes: [],
  Charts: [
    { Symbol: 'EURUSD', TimeFrame: 1, Comment: '', Digits: 5 },
    { Symbol: 'GBPUSD', TimeFrame: 1, Comment: '', Digits: 5 },
    { Symbol: 'USDJPY', TimeFrame: 1, Comment: '', Digits: 3 }
  ]
}

export const defaultCharts: IChart[] = [
  {
    symbol: 'EURUSD',
    timeFrame: 5,
    comment: '',
    digitsAfterPoint: 5,
    id: shortid.generate(),
    index: 1
	},
	// {
	// 	symbol: 'GBPUSD',
	// 	timeFrame: 60,
	// 	comment: '',
	// 	digitsAfterPoint: 5,
	// 	id: shortid.generate(),
  //   index: 2
	// },
	{
		symbol: 'USDJPY',
		timeFrame: 15,
		comment: '',
		digitsAfterPoint: 3,
		id: shortid.generate(),
    index: 3
  }
]

export const defaultAccountInfo: IAccountInfo = {
  balance: 0,
  equity: 0,
  margin: 0,
  freeMargin: 0,
  marginLevel: 0,
  totalProfit: 0,
  dollarsPerPipPerLot: 10,
  lotSize: 100000,
  leverage: 100
}

export const defaultCurrentBars: ICurrentBar = {
  // EURUSD240: { close: 1.10016, date: '2020-02-05T12:00:00.000Z', open: 1.10217 },
  EURUSD5: { close: 1.10249, date: '2020-02-05T12:00:00.000Z', open: 1.10253 },
  GBPUSD60: { close: 1.305, date: '2020-02-05T12:00:00.000Z', open: 1.30652 },
  USDJPY15: { close: 109.671, date: '2020-02-05T12:00:00.000Z', open: 109.69 }
}

export const defaultChartTimeFrames: IChartTimeFrame[] = [
  {
    symbol: 'EURUSD',
    timeFrame: 5
    // timeFrame: 240
  },
  {
    symbol: 'GBPUSD',
    timeFrame: 60
  },
  {
    symbol: 'USDJPY',
    timeFrame: 15
  }
]

export const defaultLastBarIndexes: ILastBarIndexes = {
  // EURUSD240: 16,
  EURUSD5: 717,
  GBPUSD60: 60,
  USDJPY15: 240
}

export const defaultIndicators: IIndicator[] = [
  {
    id: shortid(),
    name: 'sma8',
    fullName: TrendIndicators.MovingAverage,
    ...indicatorDefaultLook,
    category: IIndicatorCategory.Trend,
    stroke: '#FFAE29',
    settings: {
      placement: IndicatorPlacement.OnChart,
      Period: 8,
      HShift: 0,
      VShift: 0,
      MAType: 'Simple (SMA)',
      ApplyToPrice: 'Close',
      Color: {}
    },
    lineType: IndicatorComponentType.Line
  },
  {
    id: shortid(),
    name: 'ema20',
    fullName: TrendIndicators.MovingAverage,
    ...indicatorDefaultLook,
    category: IIndicatorCategory.Trend,
    stroke: '#1e90ff',
    settings: {
      placement: IndicatorPlacement.OnChart,
      Period: 20,
      HShift: 0,
      VShift: 0,
      MAType: 'Exponential (EMA)',
      ApplyToPrice: 'Close',
      Color: {}
    },
    lineType: IndicatorComponentType.Line
  }
]

export const defaultOscillators: IOscillator[] = [
  {
    id: shortid(),
    name: 'rsi8',
    fullName: Oscillators.RelativeStrengthIndex,
    ...oscillatorDefaultLook,
    category: IIndicatorCategory.Oscillators,
    stroke: '#1e90ff',
    settings: {
      placement: IndicatorPlacement.BelowChart,
      Period: 8,
      ApplyToPrice: 'Close',
      Color: {}
    }
  },
  {
    id: shortid(),
    name: 'mfi14',
    fullName: VolumeIndicators.MFI,
    ...oscillatorDefaultLook,
    category: IIndicatorCategory.Volume,
    stroke: '#1e90ff',
    settings: {
      placement: IndicatorPlacement.BelowChart,
      Period: 14,
      ApplyToPrice: 'Close',
      Color: {}
    }
  },
  {
    id: shortid(),
    name: 'bearsPower13',
    fullName: Oscillators.BearsPower,
    ...oscillatorDefaultLook,
    category: IIndicatorCategory.Oscillators,
    stroke: '#1e90ff',
    settings: {
      placement: IndicatorPlacement.BelowChart,
      Period: 13,
      ApplyToPrice: 'Close',
      Color: {}
    }
  },
  {
    id: shortid(),
    name: 'atr14',
    fullName: VolatilityIndicators.AverageTrueRange,
    ...oscillatorDefaultLook,
    category: IIndicatorCategory.Volatility,
    stroke: '#1e90ff',
    settings: {
      placement: IndicatorPlacement.BelowChart,
      Period: 14,
      ApplyToPrice: 'Close',
      Color: {}
    }
  },
  {
    id: shortid(),
    name: 'momentum14',
    fullName: Oscillators.Momentum,
    ...oscillatorDefaultLook,
    category: IIndicatorCategory.Oscillators,
    stroke: '#1e90ff',
    settings: {
      placement: IndicatorPlacement.BelowChart,
      Period: 14,
      ApplyToPrice: 'Close',
      Color: {}
    }
  },
  {
    id: 'volumes',
    name: 'volumes',
    fullName: VolumeIndicators.Volume,
    ...oscillatorDefaultLook,
    category: IIndicatorCategory.Volume,
    settings: {
      placement: IndicatorPlacement.BelowChart,
      Period: 0,
      ApplyToPrice: '',
      Color: {
        'Volume Up': '#009900',
        'Volume Down': '#ff0000'
      }
    }
  }
]

export const defaultFiboRetracement: IChartTools = {
  textList: [],
  trend_lines: [],
  rays: [],
  vertical_lines: [],
  horizontal_lines: [],
  retracements: [
    {
      x1: 229,
      y1: 109.29841386138614,
      x2: 245,
      y2: 109.71641386138613,
      selected: true,
      appearance: {
        fontFamily: 'Roboto',
        fontSize: 14,
        strokeOpacity: 0.6,
        strokeWidth: 1,
        pointerRadius: 3,
        edgeStrokeWidth: 1,
        stroke: '#555555',
        fontFill: '#555555',
        nsEdgeFill: '#555555',
        edgeFill: '#555555',
        edgeStroke: '#555555'
      },
      type: IGraphicalLine.Ray
    }
  ],
  gann_fans: [],
  equidistant_channels: [],
  standard_deviation_channels: [],
  rectangles: [],
  ellipses: [],
  triangles: [],
  icons: [],
  riskRewards: []
}

export const defaultVisibleData: { [currency: string]: IServerBar[] } = {
  EURUSD5: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 1.10939,
          h: 1.10939,
          l: 1.10918,
          c: 1.10921,
          v: 67
        },
        {
          t: 79500,
          o: 1.10906,
          h: 1.1092,
          l: 1.10905,
          c: 1.10914,
          v: 25
        },
        {
          t: 79800,
          o: 1.10915,
          h: 1.10918,
          l: 1.10913,
          c: 1.10913,
          v: 66
        },
        {
          t: 80100,
          o: 1.10913,
          h: 1.10914,
          l: 1.10906,
          c: 1.10906,
          v: 16
        },
        {
          t: 80400,
          o: 1.10906,
          h: 1.10907,
          l: 1.10856,
          c: 1.1087,
          v: 51
        },
        {
          t: 80700,
          o: 1.1087,
          h: 1.109,
          l: 1.1087,
          c: 1.10891,
          v: 43
        },
        {
          t: 81000,
          o: 1.10891,
          h: 1.10897,
          l: 1.10847,
          c: 1.10848,
          v: 134
        },
        {
          t: 81300,
          o: 1.10846,
          h: 1.10847,
          l: 1.10817,
          c: 1.10822,
          v: 125
        },
        {
          t: 81600,
          o: 1.10822,
          h: 1.10855,
          l: 1.10817,
          c: 1.10852,
          v: 134
        },
        {
          t: 81900,
          o: 1.10852,
          h: 1.10876,
          l: 1.10852,
          c: 1.1087,
          v: 150
        },
        {
          t: 82200,
          o: 1.10868,
          h: 1.10872,
          l: 1.10861,
          c: 1.1087,
          v: 243
        },
        {
          t: 82500,
          o: 1.10864,
          h: 1.10871,
          l: 1.10817,
          c: 1.10835,
          v: 268
        },
        {
          t: 82800,
          o: 1.10841,
          h: 1.10898,
          l: 1.10834,
          c: 1.10898,
          v: 281
        },
        {
          t: 83100,
          o: 1.10897,
          h: 1.109,
          l: 1.10891,
          c: 1.10896,
          v: 116
        },
        {
          t: 83400,
          o: 1.10896,
          h: 1.10902,
          l: 1.10891,
          c: 1.10891,
          v: 185
        },
        {
          t: 83700,
          o: 1.10892,
          h: 1.10895,
          l: 1.10885,
          c: 1.10888,
          v: 69
        },
        {
          t: 84000,
          o: 1.10887,
          h: 1.10888,
          l: 1.1088,
          c: 1.10881,
          v: 103
        },
        {
          t: 84300,
          o: 1.1088,
          h: 1.10892,
          l: 1.1088,
          c: 1.10887,
          v: 146
        },
        {
          t: 84600,
          o: 1.10889,
          h: 1.10898,
          l: 1.10885,
          c: 1.10898,
          v: 86
        },
        {
          t: 84900,
          o: 1.10897,
          h: 1.10901,
          l: 1.10889,
          c: 1.1089,
          v: 111
        },
        {
          t: 85200,
          o: 1.10891,
          h: 1.10891,
          l: 1.1088,
          c: 1.10881,
          v: 70
        },
        {
          t: 85500,
          o: 1.1088,
          h: 1.10888,
          l: 1.1088,
          c: 1.10886,
          v: 93
        },
        {
          t: 85800,
          o: 1.10887,
          h: 1.10892,
          l: 1.10884,
          c: 1.10889,
          v: 109
        },
        {
          t: 86100,
          o: 1.1089,
          h: 1.10891,
          l: 1.10885,
          c: 1.10886,
          v: 59
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.10886,
          h: 1.10886,
          l: 1.10865,
          c: 1.10867,
          v: 86
        },
        {
          t: 300,
          o: 1.10866,
          h: 1.10872,
          l: 1.10854,
          c: 1.10871,
          v: 91
        },
        {
          t: 600,
          o: 1.10872,
          h: 1.10872,
          l: 1.10853,
          c: 1.10856,
          v: 95
        },
        {
          t: 900,
          o: 1.10857,
          h: 1.10863,
          l: 1.10856,
          c: 1.1086,
          v: 80
        },
        {
          t: 1200,
          o: 1.1086,
          h: 1.10866,
          l: 1.1086,
          c: 1.10862,
          v: 57
        },
        {
          t: 1500,
          o: 1.10861,
          h: 1.10864,
          l: 1.10855,
          c: 1.10855,
          v: 43
        },
        {
          t: 1800,
          o: 1.10856,
          h: 1.10856,
          l: 1.10827,
          c: 1.10827,
          v: 138
        },
        {
          t: 2100,
          o: 1.10829,
          h: 1.10829,
          l: 1.10805,
          c: 1.10814,
          v: 96
        },
        {
          t: 2400,
          o: 1.10816,
          h: 1.10816,
          l: 1.10805,
          c: 1.10806,
          v: 126
        },
        {
          t: 2700,
          o: 1.10804,
          h: 1.10809,
          l: 1.10795,
          c: 1.10806,
          v: 161
        },
        {
          t: 3000,
          o: 1.10806,
          h: 1.10816,
          l: 1.10799,
          c: 1.10799,
          v: 110
        },
        {
          t: 3300,
          o: 1.10801,
          h: 1.10815,
          l: 1.10801,
          c: 1.10811,
          v: 100
        },
        {
          t: 3600,
          o: 1.10812,
          h: 1.10818,
          l: 1.10804,
          c: 1.10805,
          v: 164
        },
        {
          t: 3900,
          o: 1.10806,
          h: 1.10806,
          l: 1.10799,
          c: 1.108,
          v: 127
        },
        {
          t: 4200,
          o: 1.10801,
          h: 1.10816,
          l: 1.10801,
          c: 1.1081,
          v: 137
        },
        {
          t: 4500,
          o: 1.10811,
          h: 1.10815,
          l: 1.10799,
          c: 1.10801,
          v: 138
        },
        {
          t: 4800,
          o: 1.10801,
          h: 1.1085,
          l: 1.10801,
          c: 1.10845,
          v: 220
        },
        {
          t: 5100,
          o: 1.10844,
          h: 1.10872,
          l: 1.10836,
          c: 1.10867,
          v: 153
        },
        {
          t: 5400,
          o: 1.10868,
          h: 1.10868,
          l: 1.10829,
          c: 1.10841,
          v: 194
        },
        {
          t: 5700,
          o: 1.10841,
          h: 1.10848,
          l: 1.10829,
          c: 1.1083,
          v: 186
        },
        {
          t: 6000,
          o: 1.10829,
          h: 1.1084,
          l: 1.10821,
          c: 1.10823,
          v: 125
        },
        {
          t: 6300,
          o: 1.10824,
          h: 1.10827,
          l: 1.10809,
          c: 1.10818,
          v: 177
        },
        {
          t: 6600,
          o: 1.1082,
          h: 1.10821,
          l: 1.10815,
          c: 1.1082,
          v: 135
        },
        {
          t: 6900,
          o: 1.1082,
          h: 1.10827,
          l: 1.10819,
          c: 1.10827,
          v: 120
        },
        {
          t: 7200,
          o: 1.10826,
          h: 1.1084,
          l: 1.10821,
          c: 1.1084,
          v: 101
        },
        {
          t: 7500,
          o: 1.10839,
          h: 1.1084,
          l: 1.10815,
          c: 1.1082,
          v: 102
        },
        {
          t: 7800,
          o: 1.10819,
          h: 1.10834,
          l: 1.10816,
          c: 1.1083,
          v: 192
        },
        {
          t: 8100,
          o: 1.1083,
          h: 1.10832,
          l: 1.10816,
          c: 1.10816,
          v: 112
        },
        {
          t: 8400,
          o: 1.10816,
          h: 1.10823,
          l: 1.10809,
          c: 1.10821,
          v: 116
        },
        {
          t: 8700,
          o: 1.10821,
          h: 1.10827,
          l: 1.10816,
          c: 1.10818,
          v: 124
        },
        {
          t: 9000,
          o: 1.10819,
          h: 1.10821,
          l: 1.10811,
          c: 1.10817,
          v: 128
        },
        {
          t: 9300,
          o: 1.10818,
          h: 1.10838,
          l: 1.10813,
          c: 1.10825,
          v: 160
        },
        {
          t: 9600,
          o: 1.10825,
          h: 1.10841,
          l: 1.10813,
          c: 1.10813,
          v: 110
        },
        {
          t: 9900,
          o: 1.10812,
          h: 1.10822,
          l: 1.10803,
          c: 1.10803,
          v: 132
        },
        {
          t: 10200,
          o: 1.10803,
          h: 1.10816,
          l: 1.10798,
          c: 1.10815,
          v: 127
        },
        {
          t: 10500,
          o: 1.10815,
          h: 1.10823,
          l: 1.10811,
          c: 1.10812,
          v: 55
        },
        {
          t: 10800,
          o: 1.10812,
          h: 1.1083,
          l: 1.10812,
          c: 1.10822,
          v: 122
        },
        {
          t: 11100,
          o: 1.10822,
          h: 1.10833,
          l: 1.10821,
          c: 1.10822,
          v: 124
        },
        {
          t: 11400,
          o: 1.10822,
          h: 1.10826,
          l: 1.10817,
          c: 1.10825,
          v: 85
        },
        {
          t: 11700,
          o: 1.10825,
          h: 1.1083,
          l: 1.10823,
          c: 1.10828,
          v: 116
        },
        {
          t: 12000,
          o: 1.10828,
          h: 1.10828,
          l: 1.10806,
          c: 1.10807,
          v: 68
        },
        {
          t: 12300,
          o: 1.10806,
          h: 1.10809,
          l: 1.10801,
          c: 1.10802,
          v: 87
        },
        {
          t: 12600,
          o: 1.10806,
          h: 1.10812,
          l: 1.10798,
          c: 1.10808,
          v: 68
        },
        {
          t: 12900,
          o: 1.10808,
          h: 1.1081,
          l: 1.10806,
          c: 1.10808,
          v: 74
        },
        {
          t: 13200,
          o: 1.10806,
          h: 1.10812,
          l: 1.10802,
          c: 1.10812,
          v: 38
        },
        {
          t: 13500,
          o: 1.10812,
          h: 1.10818,
          l: 1.1081,
          c: 1.10818,
          v: 27
        },
        {
          t: 13800,
          o: 1.10817,
          h: 1.10819,
          l: 1.10812,
          c: 1.10813,
          v: 40
        },
        {
          t: 14100,
          o: 1.10813,
          h: 1.10813,
          l: 1.10802,
          c: 1.10806,
          v: 59
        },
        {
          t: 14400,
          o: 1.10806,
          h: 1.10814,
          l: 1.10806,
          c: 1.10811,
          v: 62
        },
        {
          t: 14700,
          o: 1.10812,
          h: 1.10819,
          l: 1.10808,
          c: 1.10819,
          v: 49
        },
        {
          t: 15000,
          o: 1.10819,
          h: 1.10819,
          l: 1.10818,
          c: 1.10819,
          v: 42
        },
        {
          t: 15300,
          o: 1.10819,
          h: 1.10828,
          l: 1.10818,
          c: 1.10824,
          v: 64
        },
        {
          t: 15600,
          o: 1.10827,
          h: 1.10829,
          l: 1.10826,
          c: 1.10827,
          v: 48
        },
        {
          t: 15900,
          o: 1.10828,
          h: 1.10828,
          l: 1.10826,
          c: 1.10828,
          v: 13
        },
        {
          t: 16200,
          o: 1.10827,
          h: 1.10829,
          l: 1.10818,
          c: 1.10828,
          v: 34
        },
        {
          t: 16500,
          o: 1.10829,
          h: 1.10843,
          l: 1.10828,
          c: 1.10842,
          v: 29
        },
        {
          t: 16800,
          o: 1.1084,
          h: 1.1084,
          l: 1.1083,
          c: 1.10833,
          v: 55
        },
        {
          t: 17100,
          o: 1.10832,
          h: 1.10837,
          l: 1.10824,
          c: 1.10825,
          v: 54
        },
        {
          t: 17400,
          o: 1.10826,
          h: 1.10827,
          l: 1.10822,
          c: 1.10826,
          v: 19
        },
        {
          t: 17700,
          o: 1.10822,
          h: 1.10822,
          l: 1.10813,
          c: 1.10814,
          v: 44
        },
        {
          t: 18000,
          o: 1.10813,
          h: 1.10815,
          l: 1.10809,
          c: 1.10811,
          v: 83
        },
        {
          t: 18300,
          o: 1.1081,
          h: 1.10818,
          l: 1.1081,
          c: 1.10818,
          v: 56
        },
        {
          t: 18600,
          o: 1.10818,
          h: 1.10823,
          l: 1.10818,
          c: 1.10823,
          v: 52
        },
        {
          t: 18900,
          o: 1.10826,
          h: 1.10832,
          l: 1.10823,
          c: 1.1083,
          v: 101
        },
        {
          t: 19200,
          o: 1.1083,
          h: 1.10833,
          l: 1.10826,
          c: 1.10832,
          v: 81
        },
        {
          t: 19500,
          o: 1.10832,
          h: 1.10839,
          l: 1.10824,
          c: 1.10827,
          v: 98
        },
        {
          t: 19800,
          o: 1.10827,
          h: 1.10839,
          l: 1.10825,
          c: 1.10838,
          v: 108
        },
        {
          t: 20100,
          o: 1.10837,
          h: 1.10839,
          l: 1.10833,
          c: 1.10837,
          v: 70
        },
        {
          t: 20400,
          o: 1.10837,
          h: 1.10839,
          l: 1.10834,
          c: 1.10835,
          v: 56
        },
        {
          t: 20700,
          o: 1.10834,
          h: 1.10839,
          l: 1.10833,
          c: 1.10837,
          v: 36
        },
        {
          t: 21000,
          o: 1.10839,
          h: 1.10842,
          l: 1.10839,
          c: 1.1084,
          v: 38
        },
        {
          t: 21300,
          o: 1.10839,
          h: 1.10843,
          l: 1.10833,
          c: 1.10839,
          v: 38
        },
        {
          t: 21600,
          o: 1.1084,
          h: 1.10848,
          l: 1.10839,
          c: 1.10848,
          v: 39
        },
        {
          t: 21900,
          o: 1.10848,
          h: 1.10858,
          l: 1.10848,
          c: 1.10853,
          v: 78
        },
        {
          t: 22200,
          o: 1.10853,
          h: 1.10855,
          l: 1.10853,
          c: 1.10853,
          v: 32
        },
        {
          t: 22500,
          o: 1.10854,
          h: 1.10855,
          l: 1.10832,
          c: 1.10836,
          v: 48
        },
        {
          t: 22800,
          o: 1.10836,
          h: 1.10838,
          l: 1.10826,
          c: 1.10828,
          v: 34
        },
        {
          t: 23100,
          o: 1.10823,
          h: 1.10828,
          l: 1.10816,
          c: 1.1082,
          v: 48
        },
        {
          t: 23400,
          o: 1.10822,
          h: 1.10823,
          l: 1.10812,
          c: 1.10813,
          v: 50
        },
        {
          t: 23700,
          o: 1.10818,
          h: 1.10818,
          l: 1.10812,
          c: 1.10812,
          v: 37
        },
        {
          t: 24000,
          o: 1.10812,
          h: 1.10828,
          l: 1.10812,
          c: 1.10828,
          v: 67
        },
        {
          t: 24300,
          o: 1.10827,
          h: 1.10836,
          l: 1.10818,
          c: 1.10828,
          v: 138
        },
        {
          t: 24600,
          o: 1.10828,
          h: 1.10835,
          l: 1.10822,
          c: 1.10833,
          v: 153
        },
        {
          t: 24900,
          o: 1.10829,
          h: 1.10834,
          l: 1.10816,
          c: 1.10817,
          v: 78
        },
        {
          t: 25200,
          o: 1.10819,
          h: 1.10841,
          l: 1.10813,
          c: 1.10824,
          v: 199
        },
        {
          t: 25500,
          o: 1.10826,
          h: 1.10833,
          l: 1.10816,
          c: 1.10823,
          v: 112
        },
        {
          t: 25800,
          o: 1.10824,
          h: 1.10825,
          l: 1.1077,
          c: 1.10771,
          v: 190
        },
        {
          t: 26100,
          o: 1.10773,
          h: 1.10785,
          l: 1.10763,
          c: 1.10764,
          v: 210
        },
        {
          t: 26400,
          o: 1.10762,
          h: 1.10788,
          l: 1.10761,
          c: 1.10762,
          v: 208
        },
        {
          t: 26700,
          o: 1.10761,
          h: 1.1077,
          l: 1.10741,
          c: 1.10745,
          v: 211
        },
        {
          t: 27000,
          o: 1.1075,
          h: 1.10756,
          l: 1.10726,
          c: 1.10726,
          v: 187
        },
        {
          t: 27300,
          o: 1.10726,
          h: 1.10729,
          l: 1.1071,
          c: 1.1072,
          v: 208
        },
        {
          t: 27600,
          o: 1.10721,
          h: 1.1074,
          l: 1.10699,
          c: 1.10728,
          v: 204
        },
        {
          t: 27900,
          o: 1.10728,
          h: 1.10762,
          l: 1.10717,
          c: 1.10745,
          v: 210
        },
        {
          t: 28200,
          o: 1.10745,
          h: 1.10748,
          l: 1.10727,
          c: 1.10738,
          v: 186
        },
        {
          t: 28500,
          o: 1.10738,
          h: 1.10751,
          l: 1.10727,
          c: 1.10743,
          v: 199
        },
        {
          t: 28800,
          o: 1.10744,
          h: 1.10766,
          l: 1.10707,
          c: 1.10755,
          v: 336
        },
        {
          t: 29100,
          o: 1.10756,
          h: 1.10763,
          l: 1.10732,
          c: 1.10753,
          v: 284
        },
        {
          t: 29400,
          o: 1.10753,
          h: 1.10778,
          l: 1.10741,
          c: 1.10778,
          v: 241
        },
        {
          t: 29700,
          o: 1.10776,
          h: 1.10779,
          l: 1.10758,
          c: 1.10774,
          v: 262
        },
        {
          t: 30000,
          o: 1.10774,
          h: 1.10774,
          l: 1.10741,
          c: 1.10763,
          v: 242
        },
        {
          t: 30300,
          o: 1.10761,
          h: 1.10774,
          l: 1.10729,
          c: 1.10738,
          v: 269
        },
        {
          t: 30600,
          o: 1.10737,
          h: 1.10752,
          l: 1.10729,
          c: 1.10732,
          v: 247
        },
        {
          t: 30900,
          o: 1.10731,
          h: 1.10738,
          l: 1.10703,
          c: 1.10703,
          v: 257
        },
        {
          t: 31200,
          o: 1.10703,
          h: 1.1071,
          l: 1.10696,
          c: 1.10698,
          v: 189
        },
        {
          t: 31500,
          o: 1.10698,
          h: 1.10716,
          l: 1.10678,
          c: 1.10678,
          v: 206
        },
        {
          t: 31800,
          o: 1.10677,
          h: 1.10684,
          l: 1.10656,
          c: 1.10656,
          v: 201
        },
        {
          t: 32100,
          o: 1.10656,
          h: 1.10668,
          l: 1.10637,
          c: 1.10655,
          v: 275
        },
        {
          t: 32400,
          o: 1.10657,
          h: 1.10659,
          l: 1.10633,
          c: 1.10655,
          v: 253
        },
        {
          t: 32700,
          o: 1.10655,
          h: 1.10665,
          l: 1.10645,
          c: 1.10647,
          v: 196
        },
        {
          t: 33000,
          o: 1.10646,
          h: 1.10647,
          l: 1.10601,
          c: 1.10622,
          v: 263
        },
        {
          t: 33300,
          o: 1.10623,
          h: 1.10639,
          l: 1.10603,
          c: 1.10603,
          v: 220
        },
        {
          t: 33600,
          o: 1.10602,
          h: 1.10608,
          l: 1.10586,
          c: 1.10592,
          v: 236
        },
        {
          t: 33900,
          o: 1.10594,
          h: 1.10619,
          l: 1.10592,
          c: 1.10618,
          v: 215
        },
        {
          t: 34200,
          o: 1.10617,
          h: 1.1064,
          l: 1.10615,
          c: 1.10638,
          v: 162
        },
        {
          t: 34500,
          o: 1.10637,
          h: 1.10667,
          l: 1.10636,
          c: 1.10662,
          v: 164
        },
        {
          t: 34800,
          o: 1.10662,
          h: 1.10689,
          l: 1.10662,
          c: 1.10686,
          v: 166
        },
        {
          t: 35100,
          o: 1.10689,
          h: 1.10694,
          l: 1.10651,
          c: 1.10655,
          v: 203
        },
        {
          t: 35400,
          o: 1.10657,
          h: 1.10697,
          l: 1.10656,
          c: 1.10695,
          v: 182
        },
        {
          t: 35700,
          o: 1.10697,
          h: 1.10723,
          l: 1.10695,
          c: 1.10722,
          v: 311
        },
        {
          t: 36000,
          o: 1.10722,
          h: 1.10737,
          l: 1.10696,
          c: 1.10698,
          v: 351
        },
        {
          t: 36300,
          o: 1.107,
          h: 1.107,
          l: 1.10672,
          c: 1.10676,
          v: 265
        },
        {
          t: 36600,
          o: 1.10678,
          h: 1.10683,
          l: 1.1066,
          c: 1.10673,
          v: 207
        },
        {
          t: 36900,
          o: 1.10674,
          h: 1.10677,
          l: 1.1066,
          c: 1.10661,
          v: 185
        },
        {
          t: 37200,
          o: 1.10661,
          h: 1.1068,
          l: 1.10651,
          c: 1.10672,
          v: 189
        },
        {
          t: 37500,
          o: 1.1067,
          h: 1.10678,
          l: 1.10646,
          c: 1.1067,
          v: 223
        },
        {
          t: 37800,
          o: 1.1067,
          h: 1.1067,
          l: 1.10652,
          c: 1.10662,
          v: 121
        },
        {
          t: 38100,
          o: 1.10661,
          h: 1.10668,
          l: 1.10655,
          c: 1.10668,
          v: 122
        },
        {
          t: 38400,
          o: 1.10666,
          h: 1.10688,
          l: 1.10645,
          c: 1.10684,
          v: 193
        },
        {
          t: 38700,
          o: 1.10685,
          h: 1.10695,
          l: 1.10655,
          c: 1.10667,
          v: 207
        },
        {
          t: 39000,
          o: 1.10666,
          h: 1.1067,
          l: 1.10652,
          c: 1.10657,
          v: 177
        },
        {
          t: 39300,
          o: 1.10662,
          h: 1.10678,
          l: 1.10638,
          c: 1.10643,
          v: 169
        },
        {
          t: 39600,
          o: 1.10645,
          h: 1.10677,
          l: 1.10637,
          c: 1.10667,
          v: 261
        },
        {
          t: 39900,
          o: 1.10667,
          h: 1.10673,
          l: 1.10628,
          c: 1.1063,
          v: 217
        },
        {
          t: 40200,
          o: 1.1063,
          h: 1.10639,
          l: 1.10618,
          c: 1.10625,
          v: 194
        },
        {
          t: 40500,
          o: 1.10627,
          h: 1.10631,
          l: 1.10606,
          c: 1.10611,
          v: 196
        },
        {
          t: 40800,
          o: 1.10611,
          h: 1.10627,
          l: 1.10598,
          c: 1.10603,
          v: 204
        },
        {
          t: 41100,
          o: 1.106,
          h: 1.1062,
          l: 1.10597,
          c: 1.10618,
          v: 204
        },
        {
          t: 41400,
          o: 1.10617,
          h: 1.10617,
          l: 1.10592,
          c: 1.10609,
          v: 183
        },
        {
          t: 41700,
          o: 1.1061,
          h: 1.1061,
          l: 1.10586,
          c: 1.10593,
          v: 114
        },
        {
          t: 42000,
          o: 1.10591,
          h: 1.10626,
          l: 1.10589,
          c: 1.10624,
          v: 243
        },
        {
          t: 42300,
          o: 1.10624,
          h: 1.1063,
          l: 1.106,
          c: 1.10601,
          v: 214
        },
        {
          t: 42600,
          o: 1.10601,
          h: 1.10623,
          l: 1.10599,
          c: 1.10608,
          v: 227
        },
        {
          t: 42900,
          o: 1.10609,
          h: 1.10622,
          l: 1.10579,
          c: 1.10622,
          v: 178
        },
        {
          t: 43200,
          o: 1.10623,
          h: 1.10625,
          l: 1.10609,
          c: 1.10623,
          v: 158
        },
        {
          t: 43500,
          o: 1.10623,
          h: 1.10642,
          l: 1.10621,
          c: 1.1064,
          v: 147
        },
        {
          t: 43800,
          o: 1.10639,
          h: 1.1066,
          l: 1.10639,
          c: 1.10658,
          v: 97
        },
        {
          t: 44100,
          o: 1.10654,
          h: 1.10655,
          l: 1.10638,
          c: 1.10647,
          v: 146
        },
        {
          t: 44400,
          o: 1.10647,
          h: 1.10651,
          l: 1.10633,
          c: 1.10637,
          v: 136
        },
        {
          t: 44700,
          o: 1.10637,
          h: 1.10644,
          l: 1.10626,
          c: 1.10629,
          v: 97
        },
        {
          t: 45000,
          o: 1.1063,
          h: 1.10644,
          l: 1.10629,
          c: 1.10639,
          v: 77
        },
        {
          t: 45300,
          o: 1.10639,
          h: 1.10639,
          l: 1.10618,
          c: 1.10619,
          v: 107
        },
        {
          t: 45600,
          o: 1.10622,
          h: 1.10627,
          l: 1.10616,
          c: 1.10624,
          v: 85
        },
        {
          t: 45900,
          o: 1.10625,
          h: 1.10651,
          l: 1.10625,
          c: 1.10648,
          v: 125
        },
        {
          t: 46200,
          o: 1.10648,
          h: 1.1066,
          l: 1.10645,
          c: 1.10648,
          v: 142
        },
        {
          t: 46500,
          o: 1.10651,
          h: 1.10662,
          l: 1.10645,
          c: 1.10654,
          v: 161
        },
        {
          t: 46800,
          o: 1.10654,
          h: 1.10657,
          l: 1.10638,
          c: 1.10641,
          v: 162
        },
        {
          t: 47100,
          o: 1.1064,
          h: 1.10663,
          l: 1.10633,
          c: 1.10658,
          v: 151
        },
        {
          t: 47400,
          o: 1.10656,
          h: 1.1066,
          l: 1.10648,
          c: 1.10655,
          v: 107
        },
        {
          t: 47700,
          o: 1.10651,
          h: 1.10655,
          l: 1.10632,
          c: 1.10633,
          v: 125
        },
        {
          t: 48000,
          o: 1.10638,
          h: 1.10658,
          l: 1.10628,
          c: 1.10645,
          v: 182
        },
        {
          t: 48300,
          o: 1.10646,
          h: 1.10651,
          l: 1.10617,
          c: 1.10618,
          v: 213
        },
        {
          t: 48600,
          o: 1.10618,
          h: 1.10638,
          l: 1.10613,
          c: 1.10628,
          v: 128
        },
        {
          t: 48900,
          o: 1.10626,
          h: 1.10633,
          l: 1.10618,
          c: 1.10623,
          v: 119
        },
        {
          t: 49200,
          o: 1.10624,
          h: 1.10629,
          l: 1.10618,
          c: 1.10628,
          v: 107
        },
        {
          t: 49500,
          o: 1.1063,
          h: 1.10643,
          l: 1.10628,
          c: 1.10643,
          v: 159
        },
        {
          t: 49800,
          o: 1.1064,
          h: 1.1065,
          l: 1.10638,
          c: 1.10638,
          v: 89
        },
        {
          t: 50100,
          o: 1.10636,
          h: 1.10638,
          l: 1.10618,
          c: 1.10626,
          v: 126
        },
        {
          t: 50400,
          o: 1.10627,
          h: 1.10633,
          l: 1.1061,
          c: 1.10614,
          v: 161
        },
        {
          t: 50700,
          o: 1.10614,
          h: 1.10619,
          l: 1.10587,
          c: 1.10588,
          v: 176
        },
        {
          t: 51000,
          o: 1.10587,
          h: 1.10601,
          l: 1.10566,
          c: 1.10568,
          v: 245
        },
        {
          t: 51300,
          o: 1.10568,
          h: 1.10573,
          l: 1.10557,
          c: 1.10567,
          v: 173
        },
        {
          t: 51600,
          o: 1.10568,
          h: 1.10569,
          l: 1.10537,
          c: 1.10537,
          v: 202
        },
        {
          t: 51900,
          o: 1.10538,
          h: 1.10545,
          l: 1.10527,
          c: 1.10538,
          v: 183
        },
        {
          t: 52200,
          o: 1.10539,
          h: 1.10543,
          l: 1.10499,
          c: 1.10503,
          v: 285
        },
        {
          t: 52500,
          o: 1.10503,
          h: 1.10536,
          l: 1.10496,
          c: 1.10523,
          v: 284
        },
        {
          t: 52800,
          o: 1.10521,
          h: 1.10521,
          l: 1.10499,
          c: 1.10501,
          v: 223
        },
        {
          t: 53100,
          o: 1.105,
          h: 1.10502,
          l: 1.10458,
          c: 1.10473,
          v: 340
        },
        {
          t: 53400,
          o: 1.10474,
          h: 1.1048,
          l: 1.10445,
          c: 1.10455,
          v: 322
        },
        {
          t: 53700,
          o: 1.10452,
          h: 1.10486,
          l: 1.10446,
          c: 1.1047,
          v: 294
        },
        {
          t: 54000,
          o: 1.10447,
          h: 1.10479,
          l: 1.1035,
          c: 1.10438,
          v: 627
        },
        {
          t: 54300,
          o: 1.1044,
          h: 1.10494,
          l: 1.1044,
          c: 1.10479,
          v: 572
        },
        {
          t: 54600,
          o: 1.10479,
          h: 1.1048,
          l: 1.10432,
          c: 1.10438,
          v: 417
        },
        {
          t: 54900,
          o: 1.10438,
          h: 1.10495,
          l: 1.10438,
          c: 1.10466,
          v: 304
        },
        {
          t: 55200,
          o: 1.10463,
          h: 1.10495,
          l: 1.10452,
          c: 1.1046,
          v: 339
        },
        {
          t: 55500,
          o: 1.10463,
          h: 1.10495,
          l: 1.1046,
          c: 1.10495,
          v: 293
        },
        {
          t: 55800,
          o: 1.10494,
          h: 1.10496,
          l: 1.10459,
          c: 1.10489,
          v: 301
        },
        {
          t: 56100,
          o: 1.10487,
          h: 1.10488,
          l: 1.10459,
          c: 1.10459,
          v: 226
        },
        {
          t: 56400,
          o: 1.1046,
          h: 1.10481,
          l: 1.10455,
          c: 1.10455,
          v: 264
        },
        {
          t: 56700,
          o: 1.10449,
          h: 1.10495,
          l: 1.10442,
          c: 1.10494,
          v: 292
        },
        {
          t: 57000,
          o: 1.10494,
          h: 1.10504,
          l: 1.10481,
          c: 1.10503,
          v: 302
        },
        {
          t: 57300,
          o: 1.10504,
          h: 1.10594,
          l: 1.10504,
          c: 1.10552,
          v: 511
        },
        {
          t: 57600,
          o: 1.10553,
          h: 1.10588,
          l: 1.10543,
          c: 1.10568,
          v: 324
        },
        {
          t: 57900,
          o: 1.10573,
          h: 1.10584,
          l: 1.10543,
          c: 1.10553,
          v: 216
        },
        {
          t: 58200,
          o: 1.10554,
          h: 1.10556,
          l: 1.10533,
          c: 1.10553,
          v: 266
        },
        {
          t: 58500,
          o: 1.10555,
          h: 1.10596,
          l: 1.10549,
          c: 1.10594,
          v: 233
        },
        {
          t: 58800,
          o: 1.10593,
          h: 1.10596,
          l: 1.10551,
          c: 1.10566,
          v: 263
        },
        {
          t: 59100,
          o: 1.10567,
          h: 1.10577,
          l: 1.10552,
          c: 1.10577,
          v: 196
        },
        {
          t: 59400,
          o: 1.10578,
          h: 1.10608,
          l: 1.10572,
          c: 1.10603,
          v: 212
        },
        {
          t: 59700,
          o: 1.10604,
          h: 1.10613,
          l: 1.10588,
          c: 1.10589,
          v: 229
        },
        {
          t: 60000,
          o: 1.1059,
          h: 1.10609,
          l: 1.10577,
          c: 1.10604,
          v: 389
        },
        {
          t: 60300,
          o: 1.10609,
          h: 1.10639,
          l: 1.10603,
          c: 1.1062,
          v: 243
        },
        {
          t: 60600,
          o: 1.10617,
          h: 1.10653,
          l: 1.10617,
          c: 1.10638,
          v: 190
        },
        {
          t: 60900,
          o: 1.10637,
          h: 1.10649,
          l: 1.10606,
          c: 1.10616,
          v: 203
        },
        {
          t: 61200,
          o: 1.10617,
          h: 1.10624,
          l: 1.10606,
          c: 1.10608,
          v: 151
        },
        {
          t: 61500,
          o: 1.10611,
          h: 1.1064,
          l: 1.10611,
          c: 1.10623,
          v: 121
        },
        {
          t: 61800,
          o: 1.10624,
          h: 1.10659,
          l: 1.10624,
          c: 1.10657,
          v: 235
        },
        {
          t: 62100,
          o: 1.10655,
          h: 1.10659,
          l: 1.10632,
          c: 1.10632,
          v: 184
        },
        {
          t: 62400,
          o: 1.10633,
          h: 1.10645,
          l: 1.10621,
          c: 1.10623,
          v: 142
        },
        {
          t: 62700,
          o: 1.10624,
          h: 1.10632,
          l: 1.1061,
          c: 1.10617,
          v: 167
        },
        {
          t: 63000,
          o: 1.10618,
          h: 1.1062,
          l: 1.10604,
          c: 1.10608,
          v: 189
        },
        {
          t: 63300,
          o: 1.10607,
          h: 1.10614,
          l: 1.10602,
          c: 1.10609,
          v: 105
        },
        {
          t: 63600,
          o: 1.10609,
          h: 1.1061,
          l: 1.10589,
          c: 1.10591,
          v: 158
        },
        {
          t: 63900,
          o: 1.10592,
          h: 1.10622,
          l: 1.1059,
          c: 1.10615,
          v: 129
        },
        {
          t: 64200,
          o: 1.10613,
          h: 1.10613,
          l: 1.10594,
          c: 1.10602,
          v: 60
        },
        {
          t: 64500,
          o: 1.10602,
          h: 1.10602,
          l: 1.10572,
          c: 1.10582,
          v: 159
        },
        {
          t: 64800,
          o: 1.10583,
          h: 1.10618,
          l: 1.10577,
          c: 1.10618,
          v: 127
        },
        {
          t: 65100,
          o: 1.10617,
          h: 1.10626,
          l: 1.1061,
          c: 1.10614,
          v: 118
        },
        {
          t: 65400,
          o: 1.10615,
          h: 1.10632,
          l: 1.10614,
          c: 1.10623,
          v: 158
        },
        {
          t: 65700,
          o: 1.10624,
          h: 1.10634,
          l: 1.10614,
          c: 1.10623,
          v: 169
        },
        {
          t: 66000,
          o: 1.10624,
          h: 1.10628,
          l: 1.10616,
          c: 1.10618,
          v: 145
        },
        {
          t: 66300,
          o: 1.10615,
          h: 1.10624,
          l: 1.10612,
          c: 1.10617,
          v: 117
        },
        {
          t: 66600,
          o: 1.10618,
          h: 1.10618,
          l: 1.10602,
          c: 1.10607,
          v: 107
        },
        {
          t: 66900,
          o: 1.10608,
          h: 1.10613,
          l: 1.10592,
          c: 1.10592,
          v: 106
        },
        {
          t: 67200,
          o: 1.10593,
          h: 1.10613,
          l: 1.10592,
          c: 1.10603,
          v: 106
        },
        {
          t: 67500,
          o: 1.10603,
          h: 1.10607,
          l: 1.10586,
          c: 1.10588,
          v: 97
        },
        {
          t: 67800,
          o: 1.10588,
          h: 1.1061,
          l: 1.10588,
          c: 1.10603,
          v: 138
        },
        {
          t: 68100,
          o: 1.10603,
          h: 1.10616,
          l: 1.10602,
          c: 1.10608,
          v: 170
        },
        {
          t: 68400,
          o: 1.10609,
          h: 1.10623,
          l: 1.10608,
          c: 1.10611,
          v: 138
        },
        {
          t: 68700,
          o: 1.1061,
          h: 1.10625,
          l: 1.1061,
          c: 1.10618,
          v: 115
        },
        {
          t: 69000,
          o: 1.10618,
          h: 1.10624,
          l: 1.10613,
          c: 1.10618,
          v: 123
        },
        {
          t: 69300,
          o: 1.10616,
          h: 1.10624,
          l: 1.10613,
          c: 1.10624,
          v: 97
        },
        {
          t: 69600,
          o: 1.10625,
          h: 1.10625,
          l: 1.10608,
          c: 1.10613,
          v: 92
        },
        {
          t: 69900,
          o: 1.10608,
          h: 1.10629,
          l: 1.10608,
          c: 1.10629,
          v: 59
        },
        {
          t: 70200,
          o: 1.10629,
          h: 1.10629,
          l: 1.10607,
          c: 1.1062,
          v: 75
        },
        {
          t: 70500,
          o: 1.10621,
          h: 1.10636,
          l: 1.10618,
          c: 1.10634,
          v: 74
        },
        {
          t: 70800,
          o: 1.10637,
          h: 1.10643,
          l: 1.10629,
          c: 1.10633,
          v: 72
        },
        {
          t: 71100,
          o: 1.1063,
          h: 1.1063,
          l: 1.10619,
          c: 1.10628,
          v: 76
        },
        {
          t: 71400,
          o: 1.10628,
          h: 1.10633,
          l: 1.10615,
          c: 1.10617,
          v: 124
        },
        {
          t: 71700,
          o: 1.10613,
          h: 1.10649,
          l: 1.1061,
          c: 1.10631,
          v: 231
        },
        {
          t: 72000,
          o: 1.10628,
          h: 1.10636,
          l: 1.10617,
          c: 1.10623,
          v: 117
        },
        {
          t: 72300,
          o: 1.10625,
          h: 1.10627,
          l: 1.10608,
          c: 1.10612,
          v: 144
        },
        {
          t: 72600,
          o: 1.10613,
          h: 1.10613,
          l: 1.10605,
          c: 1.10609,
          v: 124
        },
        {
          t: 72900,
          o: 1.1061,
          h: 1.10612,
          l: 1.10607,
          c: 1.10607,
          v: 89
        },
        {
          t: 73200,
          o: 1.10608,
          h: 1.10612,
          l: 1.1059,
          c: 1.1059,
          v: 148
        },
        {
          t: 73500,
          o: 1.10591,
          h: 1.10597,
          l: 1.1059,
          c: 1.10593,
          v: 140
        },
        {
          t: 73800,
          o: 1.1059,
          h: 1.10594,
          l: 1.10585,
          c: 1.10586,
          v: 125
        },
        {
          t: 74100,
          o: 1.10586,
          h: 1.10587,
          l: 1.10576,
          c: 1.10578,
          v: 132
        },
        {
          t: 74400,
          o: 1.1058,
          h: 1.10588,
          l: 1.1058,
          c: 1.10586,
          v: 128
        },
        {
          t: 74700,
          o: 1.10587,
          h: 1.10588,
          l: 1.1057,
          c: 1.10576,
          v: 123
        },
        {
          t: 75000,
          o: 1.10577,
          h: 1.10582,
          l: 1.10567,
          c: 1.10582,
          v: 107
        },
        {
          t: 75300,
          o: 1.10583,
          h: 1.10587,
          l: 1.10566,
          c: 1.10577,
          v: 170
        },
        {
          t: 75600,
          o: 1.10574,
          h: 1.10584,
          l: 1.10573,
          c: 1.10583,
          v: 90
        },
        {
          t: 75900,
          o: 1.10582,
          h: 1.10594,
          l: 1.10582,
          c: 1.10593,
          v: 76
        },
        {
          t: 76200,
          o: 1.10592,
          h: 1.10597,
          l: 1.1059,
          c: 1.10595,
          v: 53
        },
        {
          t: 76500,
          o: 1.10595,
          h: 1.10597,
          l: 1.10591,
          c: 1.10593,
          v: 37
        },
        {
          t: 76800,
          o: 1.10593,
          h: 1.10594,
          l: 1.1059,
          c: 1.10592,
          v: 20
        },
        {
          t: 77100,
          o: 1.10593,
          h: 1.10597,
          l: 1.1059,
          c: 1.10591,
          v: 69
        },
        {
          t: 77400,
          o: 1.10591,
          h: 1.10598,
          l: 1.1059,
          c: 1.10597,
          v: 43
        },
        {
          t: 77700,
          o: 1.10599,
          h: 1.10599,
          l: 1.10592,
          c: 1.10598,
          v: 38
        },
        {
          t: 78000,
          o: 1.10599,
          h: 1.10609,
          l: 1.10595,
          c: 1.10608,
          v: 59
        },
        {
          t: 78300,
          o: 1.1061,
          h: 1.1061,
          l: 1.10595,
          c: 1.10597,
          v: 73
        },
        {
          t: 78600,
          o: 1.10596,
          h: 1.10606,
          l: 1.10596,
          c: 1.10604,
          v: 48
        },
        {
          t: 78900,
          o: 1.10606,
          h: 1.10609,
          l: 1.10587,
          c: 1.10589,
          v: 78
        },
        {
          t: 79200,
          o: 1.1059,
          h: 1.10592,
          l: 1.10559,
          c: 1.10592,
          v: 47
        },
        {
          t: 79500,
          o: 1.10563,
          h: 1.10592,
          l: 1.10563,
          c: 1.10573,
          v: 47
        },
        {
          t: 79800,
          o: 1.10564,
          h: 1.10593,
          l: 1.10564,
          c: 1.10593,
          v: 83
        },
        {
          t: 80100,
          o: 1.10561,
          h: 1.10593,
          l: 1.10561,
          c: 1.1058,
          v: 78
        },
        {
          t: 80400,
          o: 1.1058,
          h: 1.10591,
          l: 1.1058,
          c: 1.1058,
          v: 12
        },
        {
          t: 80700,
          o: 1.1058,
          h: 1.10582,
          l: 1.1058,
          c: 1.1058,
          v: 16
        },
        {
          t: 81000,
          o: 1.10582,
          h: 1.10595,
          l: 1.10576,
          c: 1.10591,
          v: 89
        },
        {
          t: 81300,
          o: 1.1059,
          h: 1.10596,
          l: 1.10577,
          c: 1.10589,
          v: 94
        },
        {
          t: 81600,
          o: 1.10588,
          h: 1.10595,
          l: 1.10584,
          c: 1.1059,
          v: 65
        },
        {
          t: 81900,
          o: 1.1059,
          h: 1.10602,
          l: 1.1059,
          c: 1.10593,
          v: 68
        },
        {
          t: 82200,
          o: 1.10594,
          h: 1.10598,
          l: 1.10592,
          c: 1.10592,
          v: 32
        },
        {
          t: 82500,
          o: 1.10593,
          h: 1.10605,
          l: 1.10585,
          c: 1.10586,
          v: 93
        },
        {
          t: 82800,
          o: 1.10588,
          h: 1.10615,
          l: 1.10585,
          c: 1.10614,
          v: 80
        },
        {
          t: 83100,
          o: 1.10614,
          h: 1.10615,
          l: 1.10613,
          c: 1.10613,
          v: 27
        },
        {
          t: 83400,
          o: 1.10613,
          h: 1.10614,
          l: 1.10612,
          c: 1.10613,
          v: 37
        },
        {
          t: 83700,
          o: 1.10612,
          h: 1.10615,
          l: 1.10611,
          c: 1.10614,
          v: 25
        },
        {
          t: 84000,
          o: 1.10614,
          h: 1.10614,
          l: 1.10609,
          c: 1.10614,
          v: 24
        },
        {
          t: 84300,
          o: 1.10614,
          h: 1.10616,
          l: 1.10614,
          c: 1.10614,
          v: 31
        },
        {
          t: 84600,
          o: 1.10614,
          h: 1.10619,
          l: 1.10609,
          c: 1.10609,
          v: 35
        },
        {
          t: 84900,
          o: 1.10611,
          h: 1.10615,
          l: 1.10608,
          c: 1.10614,
          v: 46
        },
        {
          t: 85200,
          o: 1.10614,
          h: 1.10625,
          l: 1.10613,
          c: 1.10624,
          v: 39
        },
        {
          t: 85500,
          o: 1.10624,
          h: 1.10626,
          l: 1.10617,
          c: 1.1062,
          v: 47
        },
        {
          t: 85800,
          o: 1.10621,
          h: 1.10621,
          l: 1.10617,
          c: 1.10619,
          v: 41
        },
        {
          t: 86100,
          o: 1.10618,
          h: 1.10621,
          l: 1.10613,
          c: 1.10618,
          v: 62
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.10619,
          h: 1.10625,
          l: 1.10615,
          c: 1.10619,
          v: 69
        },
        {
          t: 300,
          o: 1.10619,
          h: 1.10621,
          l: 1.10614,
          c: 1.10614,
          v: 90
        },
        {
          t: 600,
          o: 1.10614,
          h: 1.10618,
          l: 1.10608,
          c: 1.10616,
          v: 57
        },
        {
          t: 900,
          o: 1.10615,
          h: 1.10615,
          l: 1.10608,
          c: 1.1061,
          v: 40
        },
        {
          t: 1200,
          o: 1.10611,
          h: 1.10614,
          l: 1.10595,
          c: 1.10599,
          v: 68
        },
        {
          t: 1500,
          o: 1.10599,
          h: 1.10604,
          l: 1.10598,
          c: 1.10599,
          v: 55
        },
        {
          t: 1800,
          o: 1.10599,
          h: 1.10607,
          l: 1.10598,
          c: 1.10607,
          v: 67
        },
        {
          t: 2100,
          o: 1.10606,
          h: 1.10609,
          l: 1.10603,
          c: 1.10608,
          v: 65
        },
        {
          t: 2400,
          o: 1.10609,
          h: 1.10609,
          l: 1.10602,
          c: 1.10603,
          v: 49
        },
        {
          t: 2700,
          o: 1.10604,
          h: 1.10604,
          l: 1.10596,
          c: 1.10601,
          v: 41
        },
        {
          t: 3000,
          o: 1.10601,
          h: 1.10602,
          l: 1.10593,
          c: 1.10593,
          v: 56
        },
        {
          t: 3300,
          o: 1.10587,
          h: 1.10604,
          l: 1.10587,
          c: 1.10603,
          v: 83
        },
        {
          t: 3600,
          o: 1.10607,
          h: 1.10616,
          l: 1.10588,
          c: 1.10613,
          v: 166
        },
        {
          t: 3900,
          o: 1.1061,
          h: 1.10615,
          l: 1.10603,
          c: 1.10603,
          v: 91
        },
        {
          t: 4200,
          o: 1.10604,
          h: 1.10605,
          l: 1.10602,
          c: 1.10602,
          v: 78
        },
        {
          t: 4500,
          o: 1.10602,
          h: 1.10605,
          l: 1.10598,
          c: 1.10604,
          v: 91
        },
        {
          t: 4800,
          o: 1.10603,
          h: 1.10604,
          l: 1.10592,
          c: 1.10592,
          v: 34
        },
        {
          t: 5100,
          o: 1.10593,
          h: 1.10598,
          l: 1.10592,
          c: 1.10598,
          v: 46
        },
        {
          t: 5400,
          o: 1.10602,
          h: 1.10607,
          l: 1.10597,
          c: 1.10598,
          v: 121
        },
        {
          t: 5700,
          o: 1.10599,
          h: 1.10614,
          l: 1.10597,
          c: 1.10609,
          v: 125
        },
        {
          t: 6000,
          o: 1.10609,
          h: 1.10611,
          l: 1.10602,
          c: 1.10611,
          v: 75
        },
        {
          t: 6300,
          o: 1.1061,
          h: 1.10636,
          l: 1.1061,
          c: 1.10618,
          v: 133
        },
        {
          t: 6600,
          o: 1.1062,
          h: 1.10628,
          l: 1.10618,
          c: 1.10618,
          v: 103
        },
        {
          t: 6900,
          o: 1.10618,
          h: 1.10618,
          l: 1.10595,
          c: 1.10598,
          v: 119
        },
        {
          t: 7200,
          o: 1.10598,
          h: 1.10599,
          l: 1.10572,
          c: 1.10572,
          v: 109
        },
        {
          t: 7500,
          o: 1.10571,
          h: 1.1058,
          l: 1.10569,
          c: 1.10569,
          v: 103
        },
        {
          t: 7800,
          o: 1.1057,
          h: 1.1057,
          l: 1.1056,
          c: 1.10568,
          v: 81
        },
        {
          t: 8100,
          o: 1.10566,
          h: 1.1057,
          l: 1.10563,
          c: 1.10569,
          v: 81
        },
        {
          t: 8400,
          o: 1.10569,
          h: 1.10569,
          l: 1.10562,
          c: 1.10564,
          v: 103
        },
        {
          t: 8700,
          o: 1.10563,
          h: 1.10569,
          l: 1.10559,
          c: 1.10564,
          v: 85
        },
        {
          t: 9000,
          o: 1.10564,
          h: 1.1057,
          l: 1.10557,
          c: 1.10559,
          v: 116
        },
        {
          t: 9300,
          o: 1.10559,
          h: 1.10568,
          l: 1.10556,
          c: 1.10556,
          v: 102
        },
        {
          t: 9600,
          o: 1.10556,
          h: 1.10558,
          l: 1.10552,
          c: 1.10555,
          v: 74
        },
        {
          t: 9900,
          o: 1.10554,
          h: 1.10566,
          l: 1.10554,
          c: 1.10564,
          v: 52
        },
        {
          t: 10200,
          o: 1.10563,
          h: 1.10576,
          l: 1.1056,
          c: 1.10574,
          v: 47
        },
        {
          t: 10500,
          o: 1.10574,
          h: 1.1058,
          l: 1.10568,
          c: 1.10579,
          v: 64
        },
        {
          t: 10800,
          o: 1.1058,
          h: 1.10587,
          l: 1.10575,
          c: 1.10586,
          v: 74
        },
        {
          t: 11100,
          o: 1.10586,
          h: 1.10594,
          l: 1.10578,
          c: 1.1058,
          v: 74
        },
        {
          t: 11400,
          o: 1.10579,
          h: 1.1058,
          l: 1.10569,
          c: 1.1057,
          v: 63
        },
        {
          t: 11700,
          o: 1.1057,
          h: 1.10575,
          l: 1.10568,
          c: 1.10569,
          v: 58
        },
        {
          t: 12000,
          o: 1.10568,
          h: 1.10568,
          l: 1.10559,
          c: 1.10568,
          v: 75
        },
        {
          t: 12300,
          o: 1.10568,
          h: 1.10571,
          l: 1.1055,
          c: 1.10556,
          v: 88
        },
        {
          t: 12600,
          o: 1.10553,
          h: 1.10585,
          l: 1.10551,
          c: 1.10584,
          v: 228
        },
        {
          t: 12900,
          o: 1.10582,
          h: 1.10585,
          l: 1.10563,
          c: 1.10563,
          v: 156
        },
        {
          t: 13200,
          o: 1.10561,
          h: 1.10571,
          l: 1.10559,
          c: 1.1057,
          v: 122
        },
        {
          t: 13500,
          o: 1.10569,
          h: 1.10575,
          l: 1.10568,
          c: 1.10569,
          v: 70
        },
        {
          t: 13800,
          o: 1.10568,
          h: 1.10581,
          l: 1.10565,
          c: 1.10579,
          v: 101
        },
        {
          t: 14100,
          o: 1.10579,
          h: 1.1058,
          l: 1.10573,
          c: 1.10575,
          v: 48
        },
        {
          t: 14400,
          o: 1.10573,
          h: 1.1058,
          l: 1.10569,
          c: 1.10579,
          v: 67
        },
        {
          t: 14700,
          o: 1.10579,
          h: 1.10587,
          l: 1.10579,
          c: 1.10584,
          v: 91
        },
        {
          t: 15000,
          o: 1.10585,
          h: 1.1059,
          l: 1.10583,
          c: 1.1059,
          v: 55
        },
        {
          t: 15300,
          o: 1.10592,
          h: 1.10593,
          l: 1.10583,
          c: 1.10584,
          v: 52
        },
        {
          t: 15600,
          o: 1.10585,
          h: 1.10591,
          l: 1.10582,
          c: 1.10589,
          v: 39
        },
        {
          t: 15900,
          o: 1.10589,
          h: 1.10598,
          l: 1.10588,
          c: 1.10593,
          v: 100
        },
        {
          t: 16200,
          o: 1.10594,
          h: 1.10596,
          l: 1.1059,
          c: 1.10595,
          v: 85
        },
        {
          t: 16500,
          o: 1.10593,
          h: 1.10601,
          l: 1.10592,
          c: 1.106,
          v: 56
        },
        {
          t: 16800,
          o: 1.10601,
          h: 1.10605,
          l: 1.106,
          c: 1.10604,
          v: 67
        },
        {
          t: 17100,
          o: 1.10602,
          h: 1.10605,
          l: 1.10599,
          c: 1.10604,
          v: 51
        },
        {
          t: 17400,
          o: 1.10603,
          h: 1.10605,
          l: 1.10598,
          c: 1.10599,
          v: 38
        },
        {
          t: 17700,
          o: 1.10598,
          h: 1.10601,
          l: 1.10593,
          c: 1.10596,
          v: 83
        },
        {
          t: 18000,
          o: 1.10593,
          h: 1.10598,
          l: 1.10593,
          c: 1.10595,
          v: 83
        },
        {
          t: 18300,
          o: 1.10596,
          h: 1.10596,
          l: 1.10589,
          c: 1.1059,
          v: 39
        },
        {
          t: 18600,
          o: 1.10591,
          h: 1.10599,
          l: 1.1059,
          c: 1.10593,
          v: 61
        },
        {
          t: 18900,
          o: 1.10593,
          h: 1.10603,
          l: 1.10593,
          c: 1.10603,
          v: 48
        },
        {
          t: 19200,
          o: 1.10605,
          h: 1.10605,
          l: 1.10598,
          c: 1.10599,
          v: 69
        },
        {
          t: 19500,
          o: 1.106,
          h: 1.106,
          l: 1.10598,
          c: 1.10599,
          v: 61
        },
        {
          t: 19800,
          o: 1.10599,
          h: 1.10629,
          l: 1.10599,
          c: 1.10619,
          v: 65
        },
        {
          t: 20100,
          o: 1.10619,
          h: 1.10619,
          l: 1.10594,
          c: 1.10594,
          v: 78
        },
        {
          t: 20400,
          o: 1.10593,
          h: 1.10601,
          l: 1.10592,
          c: 1.10594,
          v: 65
        },
        {
          t: 20700,
          o: 1.10593,
          h: 1.10593,
          l: 1.10579,
          c: 1.10579,
          v: 79
        },
        {
          t: 21000,
          o: 1.10581,
          h: 1.10584,
          l: 1.10579,
          c: 1.10583,
          v: 40
        },
        {
          t: 21300,
          o: 1.10586,
          h: 1.10589,
          l: 1.10573,
          c: 1.1058,
          v: 100
        },
        {
          t: 21600,
          o: 1.1058,
          h: 1.10609,
          l: 1.10578,
          c: 1.10605,
          v: 133
        },
        {
          t: 21900,
          o: 1.10605,
          h: 1.1061,
          l: 1.10598,
          c: 1.10606,
          v: 66
        },
        {
          t: 22200,
          o: 1.10605,
          h: 1.10605,
          l: 1.10593,
          c: 1.106,
          v: 53
        },
        {
          t: 22500,
          o: 1.106,
          h: 1.106,
          l: 1.10595,
          c: 1.10599,
          v: 78
        },
        {
          t: 22800,
          o: 1.10599,
          h: 1.10621,
          l: 1.10593,
          c: 1.10621,
          v: 116
        },
        {
          t: 23100,
          o: 1.10619,
          h: 1.10621,
          l: 1.10605,
          c: 1.10606,
          v: 122
        },
        {
          t: 23400,
          o: 1.10605,
          h: 1.10611,
          l: 1.10605,
          c: 1.1061,
          v: 51
        },
        {
          t: 23700,
          o: 1.1061,
          h: 1.10632,
          l: 1.10609,
          c: 1.1063,
          v: 130
        },
        {
          t: 24000,
          o: 1.10631,
          h: 1.10632,
          l: 1.10609,
          c: 1.10613,
          v: 130
        },
        {
          t: 24300,
          o: 1.10614,
          h: 1.10616,
          l: 1.10587,
          c: 1.10589,
          v: 116
        },
        {
          t: 24600,
          o: 1.1059,
          h: 1.10597,
          l: 1.10585,
          c: 1.10596,
          v: 94
        },
        {
          t: 24900,
          o: 1.10598,
          h: 1.10599,
          l: 1.10579,
          c: 1.10585,
          v: 118
        },
        {
          t: 25200,
          o: 1.10584,
          h: 1.10585,
          l: 1.10553,
          c: 1.10561,
          v: 201
        },
        {
          t: 25500,
          o: 1.10562,
          h: 1.10563,
          l: 1.10545,
          c: 1.10546,
          v: 119
        },
        {
          t: 25800,
          o: 1.10545,
          h: 1.10546,
          l: 1.10521,
          c: 1.10529,
          v: 189
        },
        {
          t: 26100,
          o: 1.10527,
          h: 1.1054,
          l: 1.10519,
          c: 1.10534,
          v: 155
        },
        {
          t: 26400,
          o: 1.10534,
          h: 1.10553,
          l: 1.10533,
          c: 1.10539,
          v: 130
        },
        {
          t: 26700,
          o: 1.1054,
          h: 1.10541,
          l: 1.10506,
          c: 1.10509,
          v: 238
        },
        {
          t: 27000,
          o: 1.10509,
          h: 1.10518,
          l: 1.10499,
          c: 1.10517,
          v: 193
        },
        {
          t: 27300,
          o: 1.10513,
          h: 1.10521,
          l: 1.10487,
          c: 1.10498,
          v: 171
        },
        {
          t: 27600,
          o: 1.10499,
          h: 1.10507,
          l: 1.10486,
          c: 1.10497,
          v: 205
        },
        {
          t: 27900,
          o: 1.10498,
          h: 1.10512,
          l: 1.10486,
          c: 1.10498,
          v: 167
        },
        {
          t: 28200,
          o: 1.10499,
          h: 1.10516,
          l: 1.10488,
          c: 1.10516,
          v: 156
        },
        {
          t: 28500,
          o: 1.10516,
          h: 1.10521,
          l: 1.10484,
          c: 1.10496,
          v: 233
        },
        {
          t: 28800,
          o: 1.10492,
          h: 1.10518,
          l: 1.1049,
          c: 1.10506,
          v: 280
        },
        {
          t: 29100,
          o: 1.10505,
          h: 1.10564,
          l: 1.10498,
          c: 1.10561,
          v: 301
        },
        {
          t: 29400,
          o: 1.1056,
          h: 1.10584,
          l: 1.10544,
          c: 1.10557,
          v: 358
        },
        {
          t: 29700,
          o: 1.10556,
          h: 1.10581,
          l: 1.10554,
          c: 1.10575,
          v: 325
        },
        {
          t: 30000,
          o: 1.10576,
          h: 1.10577,
          l: 1.10546,
          c: 1.10546,
          v: 277
        },
        {
          t: 30300,
          o: 1.10549,
          h: 1.10556,
          l: 1.10514,
          c: 1.10546,
          v: 303
        },
        {
          t: 30600,
          o: 1.10547,
          h: 1.10565,
          l: 1.10539,
          c: 1.10565,
          v: 218
        },
        {
          t: 30900,
          o: 1.10564,
          h: 1.10575,
          l: 1.10542,
          c: 1.10564,
          v: 205
        },
        {
          t: 31200,
          o: 1.10566,
          h: 1.10571,
          l: 1.10554,
          c: 1.10556,
          v: 198
        },
        {
          t: 31500,
          o: 1.10552,
          h: 1.10552,
          l: 1.10534,
          c: 1.10545,
          v: 160
        },
        {
          t: 31800,
          o: 1.10544,
          h: 1.10569,
          l: 1.1052,
          c: 1.10566,
          v: 245
        },
        {
          t: 32100,
          o: 1.10565,
          h: 1.10587,
          l: 1.10564,
          c: 1.10575,
          v: 285
        },
        {
          t: 32400,
          o: 1.10574,
          h: 1.10594,
          l: 1.10538,
          c: 1.10594,
          v: 282
        },
        {
          t: 32700,
          o: 1.10594,
          h: 1.10604,
          l: 1.10579,
          c: 1.10594,
          v: 303
        },
        {
          t: 33000,
          o: 1.10597,
          h: 1.10604,
          l: 1.10578,
          c: 1.10583,
          v: 212
        },
        {
          t: 33300,
          o: 1.10584,
          h: 1.10587,
          l: 1.1057,
          c: 1.10575,
          v: 238
        },
        {
          t: 33600,
          o: 1.10576,
          h: 1.10582,
          l: 1.10539,
          c: 1.10549,
          v: 223
        },
        {
          t: 33900,
          o: 1.10548,
          h: 1.10568,
          l: 1.10544,
          c: 1.10564,
          v: 192
        },
        {
          t: 34200,
          o: 1.10563,
          h: 1.10574,
          l: 1.10546,
          c: 1.10556,
          v: 210
        },
        {
          t: 34500,
          o: 1.10559,
          h: 1.10591,
          l: 1.10557,
          c: 1.10589,
          v: 233
        },
        {
          t: 34800,
          o: 1.10591,
          h: 1.10596,
          l: 1.1056,
          c: 1.10566,
          v: 257
        },
        {
          t: 35100,
          o: 1.10565,
          h: 1.10573,
          l: 1.10556,
          c: 1.10566,
          v: 213
        },
        {
          t: 35400,
          o: 1.10565,
          h: 1.10567,
          l: 1.10548,
          c: 1.10549,
          v: 210
        },
        {
          t: 35700,
          o: 1.10552,
          h: 1.10566,
          l: 1.10523,
          c: 1.10553,
          v: 251
        },
        {
          t: 36000,
          o: 1.10551,
          h: 1.10558,
          l: 1.10535,
          c: 1.1054,
          v: 234
        },
        {
          t: 36300,
          o: 1.1054,
          h: 1.10555,
          l: 1.10533,
          c: 1.10549,
          v: 181
        },
        {
          t: 36600,
          o: 1.1055,
          h: 1.10566,
          l: 1.1054,
          c: 1.10544,
          v: 155
        },
        {
          t: 36900,
          o: 1.10544,
          h: 1.10544,
          l: 1.10509,
          c: 1.10511,
          v: 211
        },
        {
          t: 37200,
          o: 1.10508,
          h: 1.10516,
          l: 1.10495,
          c: 1.10501,
          v: 163
        },
        {
          t: 37500,
          o: 1.10501,
          h: 1.10517,
          l: 1.10499,
          c: 1.10516,
          v: 157
        },
        {
          t: 37800,
          o: 1.10517,
          h: 1.1052,
          l: 1.10488,
          c: 1.10506,
          v: 177
        },
        {
          t: 38100,
          o: 1.10508,
          h: 1.10521,
          l: 1.10503,
          c: 1.10516,
          v: 152
        },
        {
          t: 38400,
          o: 1.10515,
          h: 1.10531,
          l: 1.10512,
          c: 1.10528,
          v: 184
        },
        {
          t: 38700,
          o: 1.10529,
          h: 1.10543,
          l: 1.1052,
          c: 1.10532,
          v: 133
        },
        {
          t: 39000,
          o: 1.10527,
          h: 1.10536,
          l: 1.1052,
          c: 1.10523,
          v: 137
        },
        {
          t: 39300,
          o: 1.10525,
          h: 1.10555,
          l: 1.10524,
          c: 1.10551,
          v: 137
        },
        {
          t: 39600,
          o: 1.10546,
          h: 1.10575,
          l: 1.10543,
          c: 1.10569,
          v: 130
        },
        {
          t: 39900,
          o: 1.10568,
          h: 1.10571,
          l: 1.10549,
          c: 1.10549,
          v: 126
        },
        {
          t: 40200,
          o: 1.1055,
          h: 1.10555,
          l: 1.10539,
          c: 1.10542,
          v: 99
        },
        {
          t: 40500,
          o: 1.10541,
          h: 1.10553,
          l: 1.10534,
          c: 1.1055,
          v: 162
        },
        {
          t: 40800,
          o: 1.10551,
          h: 1.1056,
          l: 1.10539,
          c: 1.10545,
          v: 91
        },
        {
          t: 41100,
          o: 1.10543,
          h: 1.10544,
          l: 1.1053,
          c: 1.1054,
          v: 86
        },
        {
          t: 41400,
          o: 1.1054,
          h: 1.10541,
          l: 1.10525,
          c: 1.10536,
          v: 130
        },
        {
          t: 41700,
          o: 1.10535,
          h: 1.10545,
          l: 1.10524,
          c: 1.10544,
          v: 116
        },
        {
          t: 42000,
          o: 1.10544,
          h: 1.10552,
          l: 1.10525,
          c: 1.10543,
          v: 175
        },
        {
          t: 42300,
          o: 1.10543,
          h: 1.10547,
          l: 1.10531,
          c: 1.10534,
          v: 178
        },
        {
          t: 42600,
          o: 1.10531,
          h: 1.10536,
          l: 1.10526,
          c: 1.10527,
          v: 81
        },
        {
          t: 42900,
          o: 1.10526,
          h: 1.10536,
          l: 1.10516,
          c: 1.10535,
          v: 132
        },
        {
          t: 43200,
          o: 1.10536,
          h: 1.10536,
          l: 1.10509,
          c: 1.1051,
          v: 95
        },
        {
          t: 43500,
          o: 1.10514,
          h: 1.10522,
          l: 1.10466,
          c: 1.10481,
          v: 231
        },
        {
          t: 43800,
          o: 1.10483,
          h: 1.10498,
          l: 1.10474,
          c: 1.10484,
          v: 180
        },
        {
          t: 44100,
          o: 1.10484,
          h: 1.10496,
          l: 1.10484,
          c: 1.10495,
          v: 143
        },
        {
          t: 44400,
          o: 1.10495,
          h: 1.10511,
          l: 1.10494,
          c: 1.10508,
          v: 106
        },
        {
          t: 44700,
          o: 1.10507,
          h: 1.10518,
          l: 1.10496,
          c: 1.10499,
          v: 158
        },
        {
          t: 45000,
          o: 1.105,
          h: 1.10505,
          l: 1.10492,
          c: 1.10495,
          v: 162
        },
        {
          t: 45300,
          o: 1.10495,
          h: 1.10496,
          l: 1.10464,
          c: 1.1047,
          v: 88
        },
        {
          t: 46500,
          o: 1.10436,
          h: 1.10437,
          l: 1.10436,
          c: 1.10437,
          v: 6
        },
        {
          t: 46800,
          o: 1.10451,
          h: 1.10457,
          l: 1.10445,
          c: 1.10446,
          v: 71
        },
        {
          t: 47100,
          o: 1.10445,
          h: 1.10469,
          l: 1.10445,
          c: 1.10462,
          v: 135
        },
        {
          t: 47400,
          o: 1.10461,
          h: 1.10475,
          l: 1.10455,
          c: 1.10471,
          v: 188
        },
        {
          t: 47700,
          o: 1.10473,
          h: 1.10497,
          l: 1.1047,
          c: 1.10497,
          v: 148
        },
        {
          t: 48000,
          o: 1.10496,
          h: 1.10502,
          l: 1.1047,
          c: 1.10475,
          v: 163
        },
        {
          t: 48300,
          o: 1.10476,
          h: 1.10485,
          l: 1.10455,
          c: 1.10484,
          v: 244
        },
        {
          t: 48600,
          o: 1.10485,
          h: 1.10503,
          l: 1.10473,
          c: 1.10492,
          v: 270
        },
        {
          t: 48900,
          o: 1.10489,
          h: 1.10502,
          l: 1.1047,
          c: 1.10471,
          v: 200
        },
        {
          t: 49200,
          o: 1.1047,
          h: 1.10472,
          l: 1.10452,
          c: 1.10463,
          v: 210
        },
        {
          t: 49500,
          o: 1.10462,
          h: 1.10484,
          l: 1.10462,
          c: 1.10476,
          v: 185
        },
        {
          t: 49800,
          o: 1.10477,
          h: 1.10491,
          l: 1.10468,
          c: 1.10481,
          v: 126
        },
        {
          t: 50100,
          o: 1.10479,
          h: 1.10479,
          l: 1.10434,
          c: 1.1044,
          v: 248
        },
        {
          t: 50400,
          o: 1.10439,
          h: 1.10458,
          l: 1.1043,
          c: 1.10441,
          v: 223
        },
        {
          t: 50700,
          o: 1.10436,
          h: 1.10462,
          l: 1.10435,
          c: 1.1045,
          v: 210
        },
        {
          t: 51000,
          o: 1.10451,
          h: 1.10459,
          l: 1.10429,
          c: 1.10457,
          v: 205
        },
        {
          t: 51300,
          o: 1.10456,
          h: 1.10461,
          l: 1.10445,
          c: 1.1045,
          v: 181
        },
        {
          t: 51600,
          o: 1.10449,
          h: 1.1046,
          l: 1.10436,
          c: 1.10452,
          v: 148
        },
        {
          t: 51900,
          o: 1.10451,
          h: 1.10463,
          l: 1.10448,
          c: 1.10457,
          v: 193
        },
        {
          t: 52200,
          o: 1.10458,
          h: 1.10461,
          l: 1.10437,
          c: 1.10442,
          v: 335
        },
        {
          t: 52500,
          o: 1.10441,
          h: 1.10443,
          l: 1.10416,
          c: 1.10438,
          v: 316
        },
        {
          t: 52800,
          o: 1.10437,
          h: 1.10441,
          l: 1.10424,
          c: 1.10434,
          v: 260
        },
        {
          t: 53100,
          o: 1.10433,
          h: 1.10439,
          l: 1.10413,
          c: 1.10421,
          v: 261
        },
        {
          t: 53400,
          o: 1.10419,
          h: 1.10435,
          l: 1.10403,
          c: 1.10432,
          v: 256
        },
        {
          t: 53700,
          o: 1.10436,
          h: 1.10449,
          l: 1.10433,
          c: 1.1044,
          v: 221
        },
        {
          t: 54000,
          o: 1.10442,
          h: 1.10478,
          l: 1.10429,
          c: 1.10468,
          v: 268
        },
        {
          t: 54300,
          o: 1.10469,
          h: 1.10474,
          l: 1.10435,
          c: 1.10441,
          v: 174
        },
        {
          t: 54600,
          o: 1.10442,
          h: 1.10462,
          l: 1.10427,
          c: 1.10437,
          v: 251
        },
        {
          t: 54900,
          o: 1.10437,
          h: 1.10454,
          l: 1.10436,
          c: 1.10454,
          v: 188
        },
        {
          t: 55200,
          o: 1.10456,
          h: 1.10461,
          l: 1.10434,
          c: 1.10442,
          v: 191
        },
        {
          t: 55500,
          o: 1.1044,
          h: 1.1044,
          l: 1.10361,
          c: 1.10368,
          v: 315
        },
        {
          t: 55800,
          o: 1.10369,
          h: 1.10393,
          l: 1.10362,
          c: 1.10373,
          v: 302
        },
        {
          t: 56100,
          o: 1.10372,
          h: 1.10384,
          l: 1.10355,
          c: 1.10356,
          v: 259
        },
        {
          t: 56400,
          o: 1.10355,
          h: 1.10359,
          l: 1.10326,
          c: 1.10358,
          v: 288
        },
        {
          t: 56700,
          o: 1.10357,
          h: 1.10363,
          l: 1.10338,
          c: 1.10349,
          v: 229
        },
        {
          t: 57000,
          o: 1.10348,
          h: 1.10403,
          l: 1.10343,
          c: 1.10403,
          v: 294
        },
        {
          t: 57300,
          o: 1.10406,
          h: 1.10415,
          l: 1.10375,
          c: 1.10379,
          v: 325
        },
        {
          t: 57600,
          o: 1.10379,
          h: 1.10379,
          l: 1.10355,
          c: 1.10375,
          v: 249
        },
        {
          t: 57900,
          o: 1.10374,
          h: 1.10384,
          l: 1.10351,
          c: 1.10353,
          v: 214
        },
        {
          t: 58200,
          o: 1.10353,
          h: 1.10368,
          l: 1.10347,
          c: 1.1036,
          v: 215
        },
        {
          t: 58500,
          o: 1.10359,
          h: 1.10365,
          l: 1.10347,
          c: 1.10353,
          v: 174
        },
        {
          t: 58800,
          o: 1.10354,
          h: 1.10354,
          l: 1.10323,
          c: 1.10323,
          v: 183
        },
        {
          t: 59100,
          o: 1.10323,
          h: 1.10358,
          l: 1.10323,
          c: 1.10349,
          v: 186
        },
        {
          t: 59400,
          o: 1.10348,
          h: 1.10356,
          l: 1.10331,
          c: 1.10353,
          v: 232
        },
        {
          t: 59700,
          o: 1.10354,
          h: 1.10359,
          l: 1.10333,
          c: 1.10355,
          v: 168
        },
        {
          t: 60000,
          o: 1.10354,
          h: 1.10359,
          l: 1.10345,
          c: 1.10346,
          v: 161
        },
        {
          t: 60300,
          o: 1.10345,
          h: 1.10398,
          l: 1.10345,
          c: 1.10396,
          v: 174
        },
        {
          t: 60600,
          o: 1.10397,
          h: 1.10406,
          l: 1.10389,
          c: 1.10406,
          v: 170
        },
        {
          t: 60900,
          o: 1.10405,
          h: 1.10427,
          l: 1.10404,
          c: 1.10425,
          v: 171
        },
        {
          t: 61200,
          o: 1.10425,
          h: 1.10448,
          l: 1.1042,
          c: 1.10439,
          v: 195
        },
        {
          t: 61500,
          o: 1.10438,
          h: 1.10442,
          l: 1.10418,
          c: 1.10428,
          v: 186
        },
        {
          t: 61800,
          o: 1.10428,
          h: 1.10429,
          l: 1.10402,
          c: 1.10413,
          v: 264
        },
        {
          t: 62100,
          o: 1.1041,
          h: 1.10418,
          l: 1.10397,
          c: 1.10408,
          v: 263
        },
        {
          t: 62400,
          o: 1.10406,
          h: 1.10407,
          l: 1.10385,
          c: 1.10389,
          v: 160
        },
        {
          t: 62700,
          o: 1.1039,
          h: 1.10436,
          l: 1.10388,
          c: 1.10426,
          v: 118
        },
        {
          t: 63000,
          o: 1.10427,
          h: 1.10434,
          l: 1.1042,
          c: 1.1042,
          v: 157
        },
        {
          t: 63300,
          o: 1.10419,
          h: 1.1042,
          l: 1.104,
          c: 1.104,
          v: 125
        },
        {
          t: 63600,
          o: 1.104,
          h: 1.10401,
          l: 1.10379,
          c: 1.10394,
          v: 81
        },
        {
          t: 63900,
          o: 1.10393,
          h: 1.104,
          l: 1.10384,
          c: 1.10385,
          v: 121
        },
        {
          t: 64200,
          o: 1.10385,
          h: 1.10404,
          l: 1.10383,
          c: 1.104,
          v: 127
        },
        {
          t: 64500,
          o: 1.104,
          h: 1.104,
          l: 1.10361,
          c: 1.10366,
          v: 186
        },
        {
          t: 64800,
          o: 1.10363,
          h: 1.10396,
          l: 1.1036,
          c: 1.10396,
          v: 165
        },
        {
          t: 65100,
          o: 1.10394,
          h: 1.10411,
          l: 1.1039,
          c: 1.10409,
          v: 149
        },
        {
          t: 65400,
          o: 1.10413,
          h: 1.10427,
          l: 1.10409,
          c: 1.10427,
          v: 101
        },
        {
          t: 65700,
          o: 1.10426,
          h: 1.10442,
          l: 1.1042,
          c: 1.10431,
          v: 119
        },
        {
          t: 66000,
          o: 1.10429,
          h: 1.10431,
          l: 1.10418,
          c: 1.1042,
          v: 111
        },
        {
          t: 66300,
          o: 1.1042,
          h: 1.10422,
          l: 1.10413,
          c: 1.10415,
          v: 90
        },
        {
          t: 66600,
          o: 1.10416,
          h: 1.10418,
          l: 1.10406,
          c: 1.1041,
          v: 61
        },
        {
          t: 66900,
          o: 1.1041,
          h: 1.10412,
          l: 1.104,
          c: 1.104,
          v: 71
        },
        {
          t: 67200,
          o: 1.10401,
          h: 1.10409,
          l: 1.10401,
          c: 1.10405,
          v: 51
        },
        {
          t: 67500,
          o: 1.10401,
          h: 1.10404,
          l: 1.10388,
          c: 1.10398,
          v: 92
        },
        {
          t: 67800,
          o: 1.10399,
          h: 1.1042,
          l: 1.10399,
          c: 1.10416,
          v: 99
        },
        {
          t: 68100,
          o: 1.10416,
          h: 1.10417,
          l: 1.10398,
          c: 1.10405,
          v: 115
        },
        {
          t: 68400,
          o: 1.10405,
          h: 1.10412,
          l: 1.10403,
          c: 1.10404,
          v: 107
        },
        {
          t: 68700,
          o: 1.10404,
          h: 1.1041,
          l: 1.104,
          c: 1.1041,
          v: 80
        },
        {
          t: 69000,
          o: 1.10409,
          h: 1.1041,
          l: 1.10398,
          c: 1.10402,
          v: 95
        },
        {
          t: 69300,
          o: 1.10401,
          h: 1.10406,
          l: 1.104,
          c: 1.104,
          v: 60
        },
        {
          t: 69600,
          o: 1.104,
          h: 1.10417,
          l: 1.104,
          c: 1.10416,
          v: 66
        },
        {
          t: 69900,
          o: 1.10415,
          h: 1.10422,
          l: 1.1041,
          c: 1.10417,
          v: 76
        },
        {
          t: 70200,
          o: 1.10417,
          h: 1.10435,
          l: 1.10415,
          c: 1.10434,
          v: 118
        },
        {
          t: 70500,
          o: 1.10435,
          h: 1.10437,
          l: 1.10426,
          c: 1.10436,
          v: 69
        },
        {
          t: 70800,
          o: 1.10435,
          h: 1.10437,
          l: 1.10425,
          c: 1.10426,
          v: 56
        },
        {
          t: 71100,
          o: 1.10426,
          h: 1.10437,
          l: 1.10426,
          c: 1.10435,
          v: 65
        },
        {
          t: 71400,
          o: 1.10436,
          h: 1.10437,
          l: 1.10426,
          c: 1.10436,
          v: 66
        },
        {
          t: 71700,
          o: 1.10437,
          h: 1.10439,
          l: 1.10411,
          c: 1.10415,
          v: 131
        },
        {
          t: 72000,
          o: 1.10414,
          h: 1.10432,
          l: 1.10414,
          c: 1.10425,
          v: 118
        },
        {
          t: 72300,
          o: 1.10426,
          h: 1.10427,
          l: 1.1042,
          c: 1.10421,
          v: 60
        },
        {
          t: 72600,
          o: 1.10421,
          h: 1.1043,
          l: 1.10421,
          c: 1.10426,
          v: 83
        },
        {
          t: 72900,
          o: 1.10425,
          h: 1.10425,
          l: 1.10408,
          c: 1.10412,
          v: 98
        },
        {
          t: 73200,
          o: 1.1041,
          h: 1.10417,
          l: 1.10406,
          c: 1.10411,
          v: 103
        },
        {
          t: 73500,
          o: 1.10412,
          h: 1.10429,
          l: 1.10409,
          c: 1.10428,
          v: 86
        },
        {
          t: 73800,
          o: 1.10427,
          h: 1.10437,
          l: 1.10425,
          c: 1.10433,
          v: 109
        },
        {
          t: 74100,
          o: 1.10433,
          h: 1.10433,
          l: 1.10426,
          c: 1.10427,
          v: 95
        },
        {
          t: 74400,
          o: 1.10428,
          h: 1.10438,
          l: 1.10428,
          c: 1.10438,
          v: 109
        },
        {
          t: 74700,
          o: 1.10436,
          h: 1.10441,
          l: 1.10423,
          c: 1.10427,
          v: 96
        },
        {
          t: 75000,
          o: 1.10426,
          h: 1.10428,
          l: 1.1042,
          c: 1.10423,
          v: 113
        },
        {
          t: 75300,
          o: 1.10423,
          h: 1.10443,
          l: 1.10422,
          c: 1.10438,
          v: 170
        },
        {
          t: 75600,
          o: 1.10437,
          h: 1.10448,
          l: 1.10437,
          c: 1.10448,
          v: 81
        },
        {
          t: 75900,
          o: 1.10448,
          h: 1.10448,
          l: 1.10444,
          c: 1.10447,
          v: 61
        },
        {
          t: 76200,
          o: 1.10447,
          h: 1.10449,
          l: 1.10439,
          c: 1.10439,
          v: 67
        },
        {
          t: 76500,
          o: 1.10438,
          h: 1.10446,
          l: 1.10436,
          c: 1.10437,
          v: 42
        },
        {
          t: 76800,
          o: 1.10436,
          h: 1.10442,
          l: 1.1043,
          c: 1.10441,
          v: 50
        },
        {
          t: 77100,
          o: 1.10441,
          h: 1.10453,
          l: 1.10436,
          c: 1.10452,
          v: 42
        },
        {
          t: 77400,
          o: 1.10451,
          h: 1.10452,
          l: 1.10447,
          c: 1.10451,
          v: 48
        },
        {
          t: 77700,
          o: 1.10451,
          h: 1.10452,
          l: 1.1045,
          c: 1.1045,
          v: 32
        },
        {
          t: 78000,
          o: 1.1045,
          h: 1.1045,
          l: 1.10435,
          c: 1.10435,
          v: 56
        },
        {
          t: 78300,
          o: 1.10433,
          h: 1.10449,
          l: 1.10433,
          c: 1.10445,
          v: 68
        },
        {
          t: 78600,
          o: 1.10446,
          h: 1.10451,
          l: 1.10443,
          c: 1.10444,
          v: 76
        },
        {
          t: 78900,
          o: 1.10444,
          h: 1.10444,
          l: 1.10431,
          c: 1.10432,
          v: 80
        },
        {
          t: 79200,
          o: 1.10431,
          h: 1.10444,
          l: 1.10412,
          c: 1.1044,
          v: 43
        },
        {
          t: 79500,
          o: 1.1044,
          h: 1.10443,
          l: 1.10426,
          c: 1.10432,
          v: 90
        },
        {
          t: 79800,
          o: 1.1043,
          h: 1.1044,
          l: 1.10429,
          c: 1.1043,
          v: 75
        },
        {
          t: 80100,
          o: 1.10428,
          h: 1.10439,
          l: 1.10412,
          c: 1.10413,
          v: 55
        },
        {
          t: 80400,
          o: 1.10407,
          h: 1.10419,
          l: 1.10402,
          c: 1.10404,
          v: 40
        },
        {
          t: 80700,
          o: 1.10404,
          h: 1.10426,
          l: 1.10399,
          c: 1.10406,
          v: 31
        },
        {
          t: 81000,
          o: 1.10412,
          h: 1.10431,
          l: 1.10412,
          c: 1.10422,
          v: 150
        },
        {
          t: 81300,
          o: 1.10422,
          h: 1.10438,
          l: 1.10414,
          c: 1.10429,
          v: 232
        },
        {
          t: 81600,
          o: 1.10427,
          h: 1.1043,
          l: 1.10412,
          c: 1.10426,
          v: 111
        },
        {
          t: 81900,
          o: 1.10426,
          h: 1.10435,
          l: 1.10417,
          c: 1.10434,
          v: 42
        },
        {
          t: 82200,
          o: 1.10434,
          h: 1.10452,
          l: 1.1042,
          c: 1.10431,
          v: 79
        },
        {
          t: 82500,
          o: 1.10429,
          h: 1.10441,
          l: 1.10416,
          c: 1.10439,
          v: 86
        },
        {
          t: 82800,
          o: 1.10442,
          h: 1.10454,
          l: 1.10441,
          c: 1.10451,
          v: 62
        },
        {
          t: 83100,
          o: 1.10451,
          h: 1.10454,
          l: 1.10447,
          c: 1.10452,
          v: 60
        },
        {
          t: 83400,
          o: 1.1045,
          h: 1.10455,
          l: 1.10447,
          c: 1.10452,
          v: 89
        },
        {
          t: 83700,
          o: 1.10451,
          h: 1.10452,
          l: 1.10451,
          c: 1.10452,
          v: 14
        },
        {
          t: 84000,
          o: 1.10452,
          h: 1.10453,
          l: 1.10448,
          c: 1.10448,
          v: 31
        },
        {
          t: 84300,
          o: 1.10448,
          h: 1.10448,
          l: 1.10444,
          c: 1.10446,
          v: 30
        },
        {
          t: 84600,
          o: 1.10447,
          h: 1.10447,
          l: 1.10442,
          c: 1.10442,
          v: 13
        },
        {
          t: 84900,
          o: 1.10443,
          h: 1.10443,
          l: 1.1044,
          c: 1.10441,
          v: 29
        },
        {
          t: 85200,
          o: 1.10442,
          h: 1.10448,
          l: 1.10435,
          c: 1.10448,
          v: 41
        },
        {
          t: 85500,
          o: 1.10447,
          h: 1.10448,
          l: 1.1044,
          c: 1.10442,
          v: 52
        },
        {
          t: 85800,
          o: 1.10441,
          h: 1.10444,
          l: 1.10441,
          c: 1.10442,
          v: 57
        },
        {
          t: 86100,
          o: 1.10443,
          h: 1.10443,
          l: 1.10441,
          c: 1.10443,
          v: 32
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.10445,
          h: 1.10445,
          l: 1.10417,
          c: 1.10422,
          v: 104
        },
        {
          t: 300,
          o: 1.10422,
          h: 1.10429,
          l: 1.10422,
          c: 1.10427,
          v: 84
        },
        {
          t: 600,
          o: 1.10426,
          h: 1.10435,
          l: 1.10426,
          c: 1.10433,
          v: 72
        },
        {
          t: 900,
          o: 1.10432,
          h: 1.10435,
          l: 1.10431,
          c: 1.10432,
          v: 52
        },
        {
          t: 1200,
          o: 1.10433,
          h: 1.10439,
          l: 1.10433,
          c: 1.10439,
          v: 44
        },
        {
          t: 1500,
          o: 1.10438,
          h: 1.10438,
          l: 1.1043,
          c: 1.10431,
          v: 68
        },
        {
          t: 1800,
          o: 1.10432,
          h: 1.1044,
          l: 1.10432,
          c: 1.10438,
          v: 57
        },
        {
          t: 2100,
          o: 1.10438,
          h: 1.10442,
          l: 1.10434,
          c: 1.10442,
          v: 60
        },
        {
          t: 2400,
          o: 1.10442,
          h: 1.10442,
          l: 1.10437,
          c: 1.10439,
          v: 47
        },
        {
          t: 2700,
          o: 1.1044,
          h: 1.10441,
          l: 1.10436,
          c: 1.10437,
          v: 69
        },
        {
          t: 3000,
          o: 1.10438,
          h: 1.10438,
          l: 1.10426,
          c: 1.10426,
          v: 89
        },
        {
          t: 3300,
          o: 1.10426,
          h: 1.10434,
          l: 1.10423,
          c: 1.10428,
          v: 97
        },
        {
          t: 3600,
          o: 1.10426,
          h: 1.10432,
          l: 1.10426,
          c: 1.10431,
          v: 85
        },
        {
          t: 3900,
          o: 1.10427,
          h: 1.10428,
          l: 1.10417,
          c: 1.10417,
          v: 78
        },
        {
          t: 4200,
          o: 1.10418,
          h: 1.10421,
          l: 1.10411,
          c: 1.10411,
          v: 80
        },
        {
          t: 4500,
          o: 1.10411,
          h: 1.10417,
          l: 1.10411,
          c: 1.10417,
          v: 67
        },
        {
          t: 4800,
          o: 1.10418,
          h: 1.10419,
          l: 1.1041,
          c: 1.1041,
          v: 76
        },
        {
          t: 5100,
          o: 1.10412,
          h: 1.10422,
          l: 1.10409,
          c: 1.10419,
          v: 84
        },
        {
          t: 5400,
          o: 1.10418,
          h: 1.10425,
          l: 1.10414,
          c: 1.1042,
          v: 138
        },
        {
          t: 5700,
          o: 1.10416,
          h: 1.10422,
          l: 1.10415,
          c: 1.10421,
          v: 84
        },
        {
          t: 6000,
          o: 1.10421,
          h: 1.10421,
          l: 1.104,
          c: 1.10401,
          v: 99
        },
        {
          t: 6300,
          o: 1.10402,
          h: 1.10403,
          l: 1.10391,
          c: 1.10392,
          v: 76
        },
        {
          t: 6600,
          o: 1.10395,
          h: 1.10403,
          l: 1.10395,
          c: 1.10402,
          v: 86
        },
        {
          t: 6900,
          o: 1.10399,
          h: 1.10407,
          l: 1.10396,
          c: 1.10398,
          v: 147
        },
        {
          t: 7200,
          o: 1.10399,
          h: 1.10404,
          l: 1.10392,
          c: 1.10403,
          v: 97
        },
        {
          t: 7500,
          o: 1.10404,
          h: 1.10404,
          l: 1.10382,
          c: 1.10382,
          v: 98
        },
        {
          t: 7800,
          o: 1.10381,
          h: 1.1039,
          l: 1.10373,
          c: 1.10377,
          v: 102
        },
        {
          t: 8100,
          o: 1.10376,
          h: 1.10388,
          l: 1.10376,
          c: 1.10386,
          v: 85
        },
        {
          t: 8400,
          o: 1.10384,
          h: 1.10384,
          l: 1.10366,
          c: 1.10367,
          v: 53
        },
        {
          t: 8700,
          o: 1.1037,
          h: 1.1037,
          l: 1.10352,
          c: 1.10356,
          v: 89
        },
        {
          t: 9000,
          o: 1.10355,
          h: 1.10362,
          l: 1.10341,
          c: 1.10341,
          v: 145
        },
        {
          t: 9300,
          o: 1.10339,
          h: 1.10342,
          l: 1.10336,
          c: 1.10337,
          v: 86
        },
        {
          t: 9600,
          o: 1.10338,
          h: 1.10342,
          l: 1.10337,
          c: 1.10341,
          v: 93
        },
        {
          t: 9900,
          o: 1.1034,
          h: 1.10348,
          l: 1.1034,
          c: 1.10343,
          v: 63
        },
        {
          t: 10200,
          o: 1.10344,
          h: 1.10347,
          l: 1.10341,
          c: 1.10343,
          v: 36
        },
        {
          t: 10500,
          o: 1.10345,
          h: 1.10346,
          l: 1.10336,
          c: 1.10339,
          v: 66
        },
        {
          t: 10800,
          o: 1.10338,
          h: 1.10348,
          l: 1.10337,
          c: 1.10346,
          v: 77
        },
        {
          t: 11100,
          o: 1.10347,
          h: 1.10353,
          l: 1.10345,
          c: 1.10352,
          v: 44
        },
        {
          t: 11400,
          o: 1.10354,
          h: 1.10358,
          l: 1.10351,
          c: 1.10358,
          v: 85
        },
        {
          t: 11700,
          o: 1.10357,
          h: 1.10361,
          l: 1.1035,
          c: 1.1035,
          v: 51
        },
        {
          t: 12000,
          o: 1.1035,
          h: 1.10351,
          l: 1.10341,
          c: 1.10345,
          v: 79
        },
        {
          t: 12300,
          o: 1.10345,
          h: 1.10346,
          l: 1.10332,
          c: 1.10344,
          v: 85
        },
        {
          t: 12600,
          o: 1.10342,
          h: 1.10344,
          l: 1.10336,
          c: 1.10337,
          v: 74
        },
        {
          t: 12900,
          o: 1.10336,
          h: 1.10338,
          l: 1.10331,
          c: 1.10336,
          v: 58
        },
        {
          t: 13200,
          o: 1.10333,
          h: 1.10338,
          l: 1.10333,
          c: 1.10334,
          v: 73
        },
        {
          t: 13500,
          o: 1.10333,
          h: 1.10337,
          l: 1.10332,
          c: 1.10337,
          v: 39
        },
        {
          t: 13800,
          o: 1.10337,
          h: 1.10337,
          l: 1.10332,
          c: 1.10337,
          v: 53
        },
        {
          t: 14100,
          o: 1.10337,
          h: 1.10338,
          l: 1.10333,
          c: 1.10337,
          v: 68
        },
        {
          t: 14400,
          o: 1.10334,
          h: 1.10339,
          l: 1.10332,
          c: 1.10337,
          v: 40
        },
        {
          t: 14700,
          o: 1.10339,
          h: 1.10341,
          l: 1.10337,
          c: 1.10337,
          v: 61
        },
        {
          t: 15000,
          o: 1.10339,
          h: 1.10343,
          l: 1.10338,
          c: 1.10342,
          v: 26
        },
        {
          t: 15300,
          o: 1.10342,
          h: 1.10343,
          l: 1.10341,
          c: 1.10341,
          v: 22
        },
        {
          t: 15600,
          o: 1.10342,
          h: 1.10348,
          l: 1.10342,
          c: 1.10348,
          v: 17
        },
        {
          t: 15900,
          o: 1.10347,
          h: 1.10348,
          l: 1.10346,
          c: 1.10348,
          v: 15
        },
        {
          t: 16200,
          o: 1.10348,
          h: 1.10348,
          l: 1.10346,
          c: 1.10346,
          v: 13
        },
        {
          t: 16500,
          o: 1.10347,
          h: 1.10348,
          l: 1.10337,
          c: 1.10337,
          v: 35
        },
        {
          t: 16800,
          o: 1.10337,
          h: 1.10343,
          l: 1.10337,
          c: 1.10342,
          v: 12
        },
        {
          t: 17100,
          o: 1.10341,
          h: 1.10345,
          l: 1.10336,
          c: 1.10337,
          v: 27
        },
        {
          t: 17400,
          o: 1.10337,
          h: 1.10338,
          l: 1.10332,
          c: 1.10333,
          v: 17
        },
        {
          t: 17700,
          o: 1.10334,
          h: 1.1034,
          l: 1.10333,
          c: 1.1034,
          v: 37
        },
        {
          t: 18000,
          o: 1.10339,
          h: 1.1034,
          l: 1.10332,
          c: 1.10337,
          v: 43
        },
        {
          t: 18300,
          o: 1.10338,
          h: 1.10343,
          l: 1.10337,
          c: 1.10342,
          v: 36
        },
        {
          t: 18600,
          o: 1.10343,
          h: 1.10348,
          l: 1.10343,
          c: 1.10347,
          v: 20
        },
        {
          t: 18900,
          o: 1.10347,
          h: 1.10352,
          l: 1.10342,
          c: 1.10347,
          v: 24
        },
        {
          t: 19200,
          o: 1.10346,
          h: 1.10348,
          l: 1.10345,
          c: 1.10348,
          v: 17
        },
        {
          t: 19500,
          o: 1.10351,
          h: 1.10354,
          l: 1.1035,
          c: 1.10354,
          v: 61
        },
        {
          t: 19800,
          o: 1.10352,
          h: 1.10353,
          l: 1.1035,
          c: 1.1035,
          v: 38
        },
        {
          t: 20100,
          o: 1.10351,
          h: 1.1037,
          l: 1.1035,
          c: 1.10362,
          v: 90
        },
        {
          t: 20400,
          o: 1.10363,
          h: 1.10368,
          l: 1.10361,
          c: 1.10365,
          v: 64
        },
        {
          t: 20700,
          o: 1.10366,
          h: 1.1037,
          l: 1.10363,
          c: 1.10367,
          v: 30
        },
        {
          t: 21000,
          o: 1.10368,
          h: 1.10376,
          l: 1.10366,
          c: 1.10375,
          v: 41
        },
        {
          t: 21300,
          o: 1.10376,
          h: 1.10376,
          l: 1.10371,
          c: 1.10373,
          v: 96
        },
        {
          t: 21600,
          o: 1.10373,
          h: 1.10378,
          l: 1.10372,
          c: 1.10373,
          v: 107
        },
        {
          t: 21900,
          o: 1.10373,
          h: 1.10373,
          l: 1.10371,
          c: 1.10373,
          v: 34
        },
        {
          t: 22200,
          o: 1.10373,
          h: 1.10373,
          l: 1.10357,
          c: 1.10357,
          v: 66
        },
        {
          t: 22500,
          o: 1.10357,
          h: 1.10361,
          l: 1.10352,
          c: 1.10358,
          v: 69
        },
        {
          t: 22800,
          o: 1.10357,
          h: 1.10367,
          l: 1.10357,
          c: 1.10361,
          v: 64
        },
        {
          t: 23100,
          o: 1.10362,
          h: 1.10368,
          l: 1.10353,
          c: 1.10357,
          v: 165
        },
        {
          t: 23400,
          o: 1.10357,
          h: 1.10361,
          l: 1.10355,
          c: 1.10357,
          v: 64
        },
        {
          t: 23700,
          o: 1.10357,
          h: 1.10358,
          l: 1.10347,
          c: 1.10351,
          v: 53
        },
        {
          t: 24000,
          o: 1.10347,
          h: 1.10348,
          l: 1.10342,
          c: 1.10342,
          v: 38
        },
        {
          t: 24300,
          o: 1.10342,
          h: 1.10347,
          l: 1.10341,
          c: 1.10343,
          v: 63
        },
        {
          t: 24600,
          o: 1.10344,
          h: 1.10353,
          l: 1.10342,
          c: 1.10351,
          v: 34
        },
        {
          t: 24900,
          o: 1.10347,
          h: 1.10356,
          l: 1.10347,
          c: 1.10353,
          v: 91
        },
        {
          t: 25200,
          o: 1.10355,
          h: 1.10365,
          l: 1.10353,
          c: 1.10363,
          v: 119
        },
        {
          t: 25500,
          o: 1.10364,
          h: 1.10375,
          l: 1.10363,
          c: 1.10373,
          v: 98
        },
        {
          t: 25800,
          o: 1.10374,
          h: 1.10382,
          l: 1.1037,
          c: 1.10374,
          v: 136
        },
        {
          t: 26100,
          o: 1.10375,
          h: 1.1038,
          l: 1.10363,
          c: 1.10379,
          v: 131
        },
        {
          t: 26400,
          o: 1.10379,
          h: 1.10389,
          l: 1.10376,
          c: 1.1038,
          v: 78
        },
        {
          t: 26700,
          o: 1.10381,
          h: 1.10391,
          l: 1.10365,
          c: 1.10368,
          v: 96
        },
        {
          t: 27000,
          o: 1.10368,
          h: 1.1038,
          l: 1.10364,
          c: 1.10375,
          v: 162
        },
        {
          t: 27300,
          o: 1.10376,
          h: 1.10381,
          l: 1.10365,
          c: 1.10373,
          v: 117
        },
        {
          t: 27600,
          o: 1.10371,
          h: 1.1038,
          l: 1.10368,
          c: 1.10379,
          v: 128
        },
        {
          t: 27900,
          o: 1.10378,
          h: 1.10401,
          l: 1.10374,
          c: 1.10391,
          v: 138
        },
        {
          t: 28200,
          o: 1.10389,
          h: 1.10394,
          l: 1.10359,
          c: 1.1036,
          v: 171
        },
        {
          t: 28500,
          o: 1.1036,
          h: 1.1037,
          l: 1.10352,
          c: 1.10363,
          v: 163
        },
        {
          t: 28800,
          o: 1.10366,
          h: 1.10405,
          l: 1.1036,
          c: 1.10399,
          v: 245
        },
        {
          t: 29100,
          o: 1.104,
          h: 1.10429,
          l: 1.10396,
          c: 1.10416,
          v: 292
        },
        {
          t: 29400,
          o: 1.10415,
          h: 1.1044,
          l: 1.10415,
          c: 1.10436,
          v: 170
        },
        {
          t: 29700,
          o: 1.10435,
          h: 1.10439,
          l: 1.10421,
          c: 1.10439,
          v: 164
        },
        {
          t: 30000,
          o: 1.10438,
          h: 1.10462,
          l: 1.10434,
          c: 1.10459,
          v: 161
        },
        {
          t: 30300,
          o: 1.1046,
          h: 1.1047,
          l: 1.10448,
          c: 1.10459,
          v: 296
        },
        {
          t: 30600,
          o: 1.10458,
          h: 1.1046,
          l: 1.10443,
          c: 1.10449,
          v: 274
        },
        {
          t: 30900,
          o: 1.10453,
          h: 1.10459,
          l: 1.10406,
          c: 1.10425,
          v: 465
        },
        {
          t: 31200,
          o: 1.10424,
          h: 1.10468,
          l: 1.10415,
          c: 1.10466,
          v: 470
        },
        {
          t: 31500,
          o: 1.10466,
          h: 1.10474,
          l: 1.10382,
          c: 1.10388,
          v: 465
        },
        {
          t: 31800,
          o: 1.10388,
          h: 1.10421,
          l: 1.10385,
          c: 1.10417,
          v: 369
        },
        {
          t: 32100,
          o: 1.10424,
          h: 1.10428,
          l: 1.10391,
          c: 1.10415,
          v: 371
        },
        {
          t: 32400,
          o: 1.10415,
          h: 1.10423,
          l: 1.10376,
          c: 1.10381,
          v: 310
        },
        {
          t: 32700,
          o: 1.10378,
          h: 1.10389,
          l: 1.10363,
          c: 1.1037,
          v: 269
        },
        {
          t: 33000,
          o: 1.10371,
          h: 1.10413,
          l: 1.10369,
          c: 1.10407,
          v: 264
        },
        {
          t: 33300,
          o: 1.10406,
          h: 1.10421,
          l: 1.10388,
          c: 1.104,
          v: 315
        },
        {
          t: 33600,
          o: 1.104,
          h: 1.10402,
          l: 1.10381,
          c: 1.10381,
          v: 226
        },
        {
          t: 33900,
          o: 1.1038,
          h: 1.10391,
          l: 1.10352,
          c: 1.10362,
          v: 230
        },
        {
          t: 34200,
          o: 1.10365,
          h: 1.10381,
          l: 1.10346,
          c: 1.10346,
          v: 320
        },
        {
          t: 34500,
          o: 1.10347,
          h: 1.10351,
          l: 1.10256,
          c: 1.10292,
          v: 386
        },
        {
          t: 34800,
          o: 1.10293,
          h: 1.10293,
          l: 1.10237,
          c: 1.10242,
          v: 397
        },
        {
          t: 35100,
          o: 1.10242,
          h: 1.10244,
          l: 1.1021,
          c: 1.10224,
          v: 343
        },
        {
          t: 35400,
          o: 1.1022,
          h: 1.10243,
          l: 1.10211,
          c: 1.10233,
          v: 346
        },
        {
          t: 35700,
          o: 1.10231,
          h: 1.10257,
          l: 1.10221,
          c: 1.10254,
          v: 283
        },
        {
          t: 36000,
          o: 1.10253,
          h: 1.10254,
          l: 1.10237,
          c: 1.10249,
          v: 197
        }
      ]
    }
  ],
  GBPUSD60: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 1.31753,
          h: 1.31788,
          l: 1.31751,
          c: 1.31774,
          v: 278
        },
        {
          t: 82800,
          o: 1.31776,
          h: 1.31836,
          l: 1.31768,
          c: 1.31804,
          v: 2342
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.31799,
          h: 1.31807,
          l: 1.31749,
          c: 1.31757,
          v: 1139
        },
        {
          t: 3600,
          o: 1.31758,
          h: 1.31819,
          l: 1.31723,
          c: 1.31757,
          v: 2562
        },
        {
          t: 7200,
          o: 1.31757,
          h: 1.31777,
          l: 1.31699,
          c: 1.31729,
          v: 2316
        },
        {
          t: 10800,
          o: 1.31734,
          h: 1.31736,
          l: 1.3155,
          c: 1.31576,
          v: 1985
        },
        {
          t: 14400,
          o: 1.31572,
          h: 1.3169,
          l: 1.31567,
          c: 1.31632,
          v: 1567
        },
        {
          t: 18000,
          o: 1.31631,
          h: 1.31668,
          l: 1.3161,
          c: 1.31627,
          v: 1606
        },
        {
          t: 21600,
          o: 1.31627,
          h: 1.31648,
          l: 1.31528,
          c: 1.31536,
          v: 1627
        },
        {
          t: 25200,
          o: 1.31536,
          h: 1.31565,
          l: 1.31397,
          c: 1.31424,
          v: 4047
        },
        {
          t: 28800,
          o: 1.31421,
          h: 1.31495,
          l: 1.31117,
          c: 1.3117,
          v: 4869
        },
        {
          t: 32400,
          o: 1.31169,
          h: 1.31187,
          l: 1.30793,
          c: 1.30806,
          v: 4843
        },
        {
          t: 36000,
          o: 1.30805,
          h: 1.30808,
          l: 1.3056,
          c: 1.30586,
          v: 5196
        },
        {
          t: 39600,
          o: 1.30587,
          h: 1.307,
          l: 1.30454,
          c: 1.30594,
          v: 4039
        },
        {
          t: 43200,
          o: 1.3059,
          h: 1.30635,
          l: 1.30523,
          c: 1.30553,
          v: 3485
        },
        {
          t: 46800,
          o: 1.30545,
          h: 1.30642,
          l: 1.30474,
          c: 1.30583,
          v: 3957
        },
        {
          t: 50400,
          o: 1.30585,
          h: 1.30595,
          l: 1.30218,
          c: 1.30261,
          v: 4921
        },
        {
          t: 54000,
          o: 1.30255,
          h: 1.30281,
          l: 1.30031,
          c: 1.30118,
          v: 5678
        },
        {
          t: 57600,
          o: 1.30115,
          h: 1.3016,
          l: 1.29998,
          c: 1.30048,
          v: 4043
        },
        {
          t: 61200,
          o: 1.30046,
          h: 1.30071,
          l: 1.29893,
          c: 1.30045,
          v: 3243
        },
        {
          t: 64800,
          o: 1.30044,
          h: 1.30099,
          l: 1.29823,
          c: 1.29935,
          v: 2235
        },
        {
          t: 68400,
          o: 1.29942,
          h: 1.30002,
          l: 1.2986,
          c: 1.29977,
          v: 2530
        },
        {
          t: 72000,
          o: 1.29976,
          h: 1.29989,
          l: 1.29864,
          c: 1.29909,
          v: 2104
        },
        {
          t: 75600,
          o: 1.29914,
          h: 1.30006,
          l: 1.29878,
          c: 1.29882,
          v: 1279
        },
        {
          t: 79200,
          o: 1.29928,
          h: 1.29958,
          l: 1.29858,
          c: 1.29954,
          v: 792
        },
        {
          t: 82800,
          o: 1.29954,
          h: 1.29983,
          l: 1.29924,
          c: 1.2996,
          v: 2591
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.2996,
          h: 1.29964,
          l: 1.29876,
          c: 1.29906,
          v: 1201
        },
        {
          t: 3600,
          o: 1.29906,
          h: 1.29917,
          l: 1.2981,
          c: 1.29898,
          v: 2221
        },
        {
          t: 7200,
          o: 1.29898,
          h: 1.29927,
          l: 1.29822,
          c: 1.29918,
          v: 2028
        },
        {
          t: 10800,
          o: 1.29922,
          h: 1.30017,
          l: 1.29912,
          c: 1.29987,
          v: 2387
        },
        {
          t: 14400,
          o: 1.29986,
          h: 1.30137,
          l: 1.2998,
          c: 1.30067,
          v: 2128
        },
        {
          t: 18000,
          o: 1.30065,
          h: 1.30226,
          l: 1.30052,
          c: 1.30128,
          v: 2004
        },
        {
          t: 21600,
          o: 1.30128,
          h: 1.30192,
          l: 1.29945,
          c: 1.29948,
          v: 2604
        },
        {
          t: 25200,
          o: 1.29944,
          h: 1.29955,
          l: 1.29404,
          c: 1.29445,
          v: 4454
        },
        {
          t: 28800,
          o: 1.29446,
          h: 1.29879,
          l: 1.29446,
          c: 1.29863,
          v: 4700
        },
        {
          t: 32400,
          o: 1.29854,
          h: 1.30245,
          l: 1.29799,
          c: 1.30154,
          v: 4829
        },
        {
          t: 36000,
          o: 1.30153,
          h: 1.30177,
          l: 1.30002,
          c: 1.30062,
          v: 3861
        },
        {
          t: 39600,
          o: 1.3006,
          h: 1.30156,
          l: 1.30035,
          c: 1.30124,
          v: 3516
        },
        {
          t: 43200,
          o: 1.30138,
          h: 1.30217,
          l: 1.3008,
          c: 1.30111,
          v: 2564
        },
        {
          t: 46800,
          o: 1.30096,
          h: 1.30459,
          l: 1.30094,
          c: 1.30287,
          v: 5222
        },
        {
          t: 50400,
          o: 1.30286,
          h: 1.30335,
          l: 1.30112,
          c: 1.30213,
          v: 4860
        },
        {
          t: 54000,
          o: 1.30212,
          h: 1.30359,
          l: 1.30193,
          c: 1.30248,
          v: 4500
        },
        {
          t: 57600,
          o: 1.30244,
          h: 1.30282,
          l: 1.30177,
          c: 1.30274,
          v: 3444
        },
        {
          t: 61200,
          o: 1.30273,
          h: 1.30346,
          l: 1.30266,
          c: 1.30311,
          v: 2553
        },
        {
          t: 64800,
          o: 1.30305,
          h: 1.30435,
          l: 1.30301,
          c: 1.30402,
          v: 2247
        },
        {
          t: 68400,
          o: 1.30402,
          h: 1.30412,
          l: 1.30359,
          c: 1.30363,
          v: 1690
        },
        {
          t: 72000,
          o: 1.30362,
          h: 1.30375,
          l: 1.303,
          c: 1.30329,
          v: 1944
        },
        {
          t: 75600,
          o: 1.30327,
          h: 1.30332,
          l: 1.30262,
          c: 1.30288,
          v: 1135
        },
        {
          t: 79200,
          o: 1.30288,
          h: 1.30381,
          l: 1.30236,
          c: 1.30264,
          v: 1803
        },
        {
          t: 82800,
          o: 1.3025,
          h: 1.30329,
          l: 1.30228,
          c: 1.30297,
          v: 1558
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.30299,
          h: 1.30326,
          l: 1.30258,
          c: 1.30277,
          v: 1921
        },
        {
          t: 3600,
          o: 1.30276,
          h: 1.30276,
          l: 1.30173,
          c: 1.30226,
          v: 2593
        },
        {
          t: 7200,
          o: 1.30226,
          h: 1.3023,
          l: 1.30115,
          c: 1.30164,
          v: 1841
        },
        {
          t: 10800,
          o: 1.3016,
          h: 1.30235,
          l: 1.30159,
          c: 1.30216,
          v: 1701
        },
        {
          t: 14400,
          o: 1.30216,
          h: 1.30228,
          l: 1.30175,
          c: 1.30176,
          v: 1282
        },
        {
          t: 18000,
          o: 1.30176,
          h: 1.3018,
          l: 1.30144,
          c: 1.3017,
          v: 1815
        },
        {
          t: 21600,
          o: 1.3017,
          h: 1.30207,
          l: 1.30146,
          c: 1.30167,
          v: 1308
        },
        {
          t: 25200,
          o: 1.30167,
          h: 1.30369,
          l: 1.30083,
          c: 1.30361,
          v: 3223
        },
        {
          t: 28800,
          o: 1.30364,
          h: 1.3052,
          l: 1.30336,
          c: 1.30465,
          v: 5266
        },
        {
          t: 32400,
          o: 1.30469,
          h: 1.30693,
          l: 1.30333,
          c: 1.30652,
          v: 5282
        },
        {
          t: 36000,
          o: 1.30652,
          h: 1.30652,
          l: 1.30465,
          c: 1.305,
          v: 3420
        }
      ]
    }
  ],
  USDJPY15: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 108.406,
          h: 108.427,
          l: 108.325,
          c: 108.396,
          v: 673
        },
        {
          t: 80100,
          o: 108.396,
          h: 108.396,
          l: 108.307,
          c: 108.353,
          v: 307
        },
        {
          t: 81000,
          o: 108.355,
          h: 108.356,
          l: 108.315,
          c: 108.33,
          v: 478
        },
        {
          t: 81900,
          o: 108.332,
          h: 108.349,
          l: 108.323,
          c: 108.347,
          v: 285
        },
        {
          t: 82800,
          o: 108.346,
          h: 108.408,
          l: 108.314,
          c: 108.383,
          v: 913
        },
        {
          t: 83700,
          o: 108.386,
          h: 108.406,
          l: 108.364,
          c: 108.384,
          v: 826
        },
        {
          t: 84600,
          o: 108.384,
          h: 108.398,
          l: 108.35,
          c: 108.381,
          v: 701
        },
        {
          t: 85500,
          o: 108.385,
          h: 108.395,
          l: 108.328,
          c: 108.382,
          v: 879
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 108.383,
          h: 108.434,
          l: 108.359,
          c: 108.428,
          v: 925
        },
        {
          t: 900,
          o: 108.43,
          h: 108.433,
          l: 108.411,
          c: 108.417,
          v: 470
        },
        {
          t: 1800,
          o: 108.417,
          h: 108.535,
          l: 108.41,
          c: 108.529,
          v: 1072
        },
        {
          t: 2700,
          o: 108.53,
          h: 108.56,
          l: 108.472,
          c: 108.481,
          v: 1078
        },
        {
          t: 3600,
          o: 108.478,
          h: 108.524,
          l: 108.434,
          c: 108.489,
          v: 1773
        },
        {
          t: 4500,
          o: 108.49,
          h: 108.515,
          l: 108.412,
          c: 108.447,
          v: 1791
        },
        {
          t: 5400,
          o: 108.447,
          h: 108.565,
          l: 108.43,
          c: 108.531,
          v: 1755
        },
        {
          t: 6300,
          o: 108.531,
          h: 108.577,
          l: 108.518,
          c: 108.556,
          v: 1387
        },
        {
          t: 7200,
          o: 108.557,
          h: 108.566,
          l: 108.48,
          c: 108.481,
          v: 1520
        },
        {
          t: 8100,
          o: 108.483,
          h: 108.536,
          l: 108.468,
          c: 108.516,
          v: 975
        },
        {
          t: 9000,
          o: 108.517,
          h: 108.525,
          l: 108.485,
          c: 108.516,
          v: 887
        },
        {
          t: 9900,
          o: 108.513,
          h: 108.516,
          l: 108.471,
          c: 108.476,
          v: 914
        },
        {
          t: 10800,
          o: 108.476,
          h: 108.491,
          l: 108.467,
          c: 108.484,
          v: 945
        },
        {
          t: 11700,
          o: 108.483,
          h: 108.545,
          l: 108.476,
          c: 108.525,
          v: 840
        },
        {
          t: 12600,
          o: 108.525,
          h: 108.542,
          l: 108.517,
          c: 108.521,
          v: 689
        },
        {
          t: 13500,
          o: 108.521,
          h: 108.541,
          l: 108.512,
          c: 108.541,
          v: 396
        },
        {
          t: 14400,
          o: 108.54,
          h: 108.545,
          l: 108.512,
          c: 108.514,
          v: 324
        },
        {
          t: 15300,
          o: 108.514,
          h: 108.53,
          l: 108.501,
          c: 108.508,
          v: 356
        },
        {
          t: 16200,
          o: 108.509,
          h: 108.51,
          l: 108.476,
          c: 108.481,
          v: 373
        },
        {
          t: 17100,
          o: 108.478,
          h: 108.487,
          l: 108.468,
          c: 108.472,
          v: 237
        },
        {
          t: 18000,
          o: 108.472,
          h: 108.498,
          l: 108.471,
          c: 108.496,
          v: 377
        },
        {
          t: 18900,
          o: 108.495,
          h: 108.526,
          l: 108.495,
          c: 108.521,
          v: 393
        },
        {
          t: 19800,
          o: 108.522,
          h: 108.526,
          l: 108.505,
          c: 108.515,
          v: 474
        },
        {
          t: 20700,
          o: 108.514,
          h: 108.525,
          l: 108.506,
          c: 108.521,
          v: 448
        },
        {
          t: 21600,
          o: 108.522,
          h: 108.523,
          l: 108.478,
          c: 108.48,
          v: 533
        },
        {
          t: 22500,
          o: 108.48,
          h: 108.513,
          l: 108.48,
          c: 108.511,
          v: 346
        },
        {
          t: 23400,
          o: 108.508,
          h: 108.52,
          l: 108.475,
          c: 108.476,
          v: 238
        },
        {
          t: 24300,
          o: 108.476,
          h: 108.518,
          l: 108.469,
          c: 108.518,
          v: 357
        },
        {
          t: 25200,
          o: 108.516,
          h: 108.556,
          l: 108.488,
          c: 108.529,
          v: 859
        },
        {
          t: 26100,
          o: 108.529,
          h: 108.63,
          l: 108.527,
          c: 108.625,
          v: 913
        },
        {
          t: 27000,
          o: 108.624,
          h: 108.693,
          l: 108.617,
          c: 108.683,
          v: 993
        },
        {
          t: 27900,
          o: 108.682,
          h: 108.685,
          l: 108.613,
          c: 108.622,
          v: 674
        },
        {
          t: 28800,
          o: 108.618,
          h: 108.625,
          l: 108.516,
          c: 108.527,
          v: 1430
        },
        {
          t: 29700,
          o: 108.526,
          h: 108.536,
          l: 108.457,
          c: 108.511,
          v: 1279
        },
        {
          t: 30600,
          o: 108.51,
          h: 108.569,
          l: 108.499,
          c: 108.562,
          v: 912
        },
        {
          t: 31500,
          o: 108.568,
          h: 108.644,
          l: 108.549,
          c: 108.605,
          v: 1223
        },
        {
          t: 32400,
          o: 108.605,
          h: 108.605,
          l: 108.538,
          c: 108.572,
          v: 1132
        },
        {
          t: 33300,
          o: 108.574,
          h: 108.583,
          l: 108.545,
          c: 108.554,
          v: 953
        },
        {
          t: 34200,
          o: 108.554,
          h: 108.567,
          l: 108.523,
          c: 108.53,
          v: 673
        },
        {
          t: 35100,
          o: 108.531,
          h: 108.548,
          l: 108.501,
          c: 108.501,
          v: 633
        },
        {
          t: 36000,
          o: 108.501,
          h: 108.525,
          l: 108.469,
          c: 108.512,
          v: 744
        },
        {
          t: 36900,
          o: 108.511,
          h: 108.521,
          l: 108.487,
          c: 108.515,
          v: 523
        },
        {
          t: 37800,
          o: 108.514,
          h: 108.514,
          l: 108.436,
          c: 108.439,
          v: 585
        },
        {
          t: 38700,
          o: 108.439,
          h: 108.459,
          l: 108.413,
          c: 108.414,
          v: 575
        },
        {
          t: 39600,
          o: 108.415,
          h: 108.514,
          l: 108.412,
          c: 108.51,
          v: 691
        },
        {
          t: 40500,
          o: 108.509,
          h: 108.533,
          l: 108.494,
          c: 108.511,
          v: 593
        },
        {
          t: 41400,
          o: 108.513,
          h: 108.523,
          l: 108.475,
          c: 108.509,
          v: 513
        },
        {
          t: 42300,
          o: 108.508,
          h: 108.534,
          l: 108.504,
          c: 108.521,
          v: 526
        },
        {
          t: 43200,
          o: 108.519,
          h: 108.526,
          l: 108.482,
          c: 108.489,
          v: 409
        },
        {
          t: 44100,
          o: 108.491,
          h: 108.519,
          l: 108.477,
          c: 108.518,
          v: 421
        },
        {
          t: 45000,
          o: 108.517,
          h: 108.523,
          l: 108.498,
          c: 108.516,
          v: 442
        },
        {
          t: 45900,
          o: 108.517,
          h: 108.517,
          l: 108.487,
          c: 108.507,
          v: 324
        },
        {
          t: 46800,
          o: 108.507,
          h: 108.553,
          l: 108.503,
          c: 108.525,
          v: 591
        },
        {
          t: 47700,
          o: 108.526,
          h: 108.571,
          l: 108.516,
          c: 108.566,
          v: 688
        },
        {
          t: 48600,
          o: 108.565,
          h: 108.565,
          l: 108.544,
          c: 108.556,
          v: 638
        },
        {
          t: 49500,
          o: 108.555,
          h: 108.559,
          l: 108.517,
          c: 108.536,
          v: 467
        },
        {
          t: 50400,
          o: 108.535,
          h: 108.544,
          l: 108.511,
          c: 108.512,
          v: 541
        },
        {
          t: 51300,
          o: 108.512,
          h: 108.526,
          l: 108.483,
          c: 108.49,
          v: 524
        },
        {
          t: 52200,
          o: 108.492,
          h: 108.565,
          l: 108.477,
          c: 108.565,
          v: 1249
        },
        {
          t: 53100,
          o: 108.565,
          h: 108.666,
          l: 108.565,
          c: 108.621,
          v: 1151
        },
        {
          t: 54000,
          o: 108.633,
          h: 108.786,
          l: 108.587,
          c: 108.707,
          v: 2566
        },
        {
          t: 54900,
          o: 108.707,
          h: 108.792,
          l: 108.671,
          c: 108.786,
          v: 1389
        },
        {
          t: 55800,
          o: 108.784,
          h: 108.793,
          l: 108.7,
          c: 108.727,
          v: 1142
        },
        {
          t: 56700,
          o: 108.728,
          h: 108.733,
          l: 108.66,
          c: 108.706,
          v: 1093
        },
        {
          t: 57600,
          o: 108.706,
          h: 108.726,
          l: 108.659,
          c: 108.661,
          v: 983
        },
        {
          t: 58500,
          o: 108.661,
          h: 108.673,
          l: 108.609,
          c: 108.614,
          v: 1008
        },
        {
          t: 59400,
          o: 108.613,
          h: 108.637,
          l: 108.522,
          c: 108.55,
          v: 1658
        },
        {
          t: 60300,
          o: 108.548,
          h: 108.589,
          l: 108.515,
          c: 108.588,
          v: 1393
        },
        {
          t: 61200,
          o: 108.587,
          h: 108.587,
          l: 108.548,
          c: 108.581,
          v: 1173
        },
        {
          t: 62100,
          o: 108.582,
          h: 108.64,
          l: 108.58,
          c: 108.612,
          v: 940
        },
        {
          t: 63000,
          o: 108.613,
          h: 108.644,
          l: 108.601,
          c: 108.638,
          v: 769
        },
        {
          t: 63900,
          o: 108.637,
          h: 108.673,
          l: 108.62,
          c: 108.659,
          v: 708
        },
        {
          t: 64800,
          o: 108.66,
          h: 108.663,
          l: 108.583,
          c: 108.602,
          v: 792
        },
        {
          t: 65700,
          o: 108.602,
          h: 108.631,
          l: 108.583,
          c: 108.621,
          v: 909
        },
        {
          t: 66600,
          o: 108.619,
          h: 108.666,
          l: 108.612,
          c: 108.65,
          v: 674
        },
        {
          t: 67500,
          o: 108.65,
          h: 108.666,
          l: 108.631,
          c: 108.665,
          v: 715
        },
        {
          t: 68400,
          o: 108.665,
          h: 108.666,
          l: 108.624,
          c: 108.643,
          v: 725
        },
        {
          t: 69300,
          o: 108.644,
          h: 108.677,
          l: 108.631,
          c: 108.645,
          v: 590
        },
        {
          t: 70200,
          o: 108.644,
          h: 108.655,
          l: 108.627,
          c: 108.654,
          v: 526
        },
        {
          t: 71100,
          o: 108.654,
          h: 108.688,
          l: 108.654,
          c: 108.671,
          v: 562
        },
        {
          t: 72000,
          o: 108.67,
          h: 108.706,
          l: 108.654,
          c: 108.689,
          v: 670
        },
        {
          t: 72900,
          o: 108.69,
          h: 108.726,
          l: 108.678,
          c: 108.726,
          v: 535
        },
        {
          t: 73800,
          o: 108.725,
          h: 108.738,
          l: 108.696,
          c: 108.696,
          v: 644
        },
        {
          t: 74700,
          o: 108.693,
          h: 108.701,
          l: 108.665,
          c: 108.681,
          v: 666
        },
        {
          t: 75600,
          o: 108.68,
          h: 108.687,
          l: 108.66,
          c: 108.687,
          v: 394
        },
        {
          t: 76500,
          o: 108.686,
          h: 108.687,
          l: 108.665,
          c: 108.671,
          v: 88
        },
        {
          t: 77400,
          o: 108.671,
          h: 108.689,
          l: 108.663,
          c: 108.671,
          v: 277
        },
        {
          t: 78300,
          o: 108.67,
          h: 108.695,
          l: 108.658,
          c: 108.661,
          v: 368
        },
        {
          t: 79200,
          o: 108.662,
          h: 108.698,
          l: 108.658,
          c: 108.667,
          v: 418
        },
        {
          t: 80100,
          o: 108.664,
          h: 108.675,
          l: 108.65,
          c: 108.661,
          v: 768
        },
        {
          t: 81000,
          o: 108.66,
          h: 108.667,
          l: 108.64,
          c: 108.643,
          v: 401
        },
        {
          t: 81900,
          o: 108.643,
          h: 108.66,
          l: 108.641,
          c: 108.658,
          v: 282
        },
        {
          t: 82800,
          o: 108.655,
          h: 108.664,
          l: 108.575,
          c: 108.6,
          v: 539
        },
        {
          t: 83700,
          o: 108.601,
          h: 108.629,
          l: 108.595,
          c: 108.607,
          v: 293
        },
        {
          t: 84600,
          o: 108.607,
          h: 108.635,
          l: 108.607,
          c: 108.616,
          v: 314
        },
        {
          t: 85500,
          o: 108.618,
          h: 108.636,
          l: 108.608,
          c: 108.612,
          v: 352
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 108.613,
          h: 108.652,
          l: 108.576,
          c: 108.634,
          v: 692
        },
        {
          t: 900,
          o: 108.635,
          h: 108.65,
          l: 108.61,
          c: 108.642,
          v: 529
        },
        {
          t: 1800,
          o: 108.644,
          h: 108.648,
          l: 108.616,
          c: 108.629,
          v: 494
        },
        {
          t: 2700,
          o: 108.627,
          h: 108.627,
          l: 108.567,
          c: 108.587,
          v: 723
        },
        {
          t: 3600,
          o: 108.588,
          h: 108.689,
          l: 108.543,
          c: 108.581,
          v: 1307
        },
        {
          t: 4500,
          o: 108.581,
          h: 108.636,
          l: 108.57,
          c: 108.585,
          v: 965
        },
        {
          t: 5400,
          o: 108.586,
          h: 108.688,
          l: 108.586,
          c: 108.639,
          v: 1234
        },
        {
          t: 6300,
          o: 108.64,
          h: 108.709,
          l: 108.629,
          c: 108.695,
          v: 1034
        },
        {
          t: 7200,
          o: 108.693,
          h: 108.693,
          l: 108.642,
          c: 108.658,
          v: 915
        },
        {
          t: 8100,
          o: 108.659,
          h: 108.684,
          l: 108.608,
          c: 108.642,
          v: 758
        },
        {
          t: 9000,
          o: 108.64,
          h: 108.681,
          l: 108.64,
          c: 108.657,
          v: 631
        },
        {
          t: 9900,
          o: 108.655,
          h: 108.665,
          l: 108.635,
          c: 108.659,
          v: 558
        },
        {
          t: 10800,
          o: 108.655,
          h: 108.655,
          l: 108.615,
          c: 108.649,
          v: 441
        },
        {
          t: 11700,
          o: 108.648,
          h: 108.685,
          l: 108.634,
          c: 108.674,
          v: 418
        },
        {
          t: 12600,
          o: 108.674,
          h: 108.776,
          l: 108.665,
          c: 108.716,
          v: 1181
        },
        {
          t: 13500,
          o: 108.714,
          h: 108.726,
          l: 108.667,
          c: 108.689,
          v: 539
        },
        {
          t: 14400,
          o: 108.689,
          h: 108.716,
          l: 108.687,
          c: 108.7,
          v: 455
        },
        {
          t: 15300,
          o: 108.699,
          h: 108.759,
          l: 108.697,
          c: 108.75,
          v: 304
        },
        {
          t: 16200,
          o: 108.752,
          h: 108.776,
          l: 108.725,
          c: 108.732,
          v: 659
        },
        {
          t: 17100,
          o: 108.732,
          h: 108.745,
          l: 108.718,
          c: 108.73,
          v: 400
        },
        {
          t: 18000,
          o: 108.732,
          h: 108.744,
          l: 108.715,
          c: 108.724,
          v: 499
        },
        {
          t: 18900,
          o: 108.722,
          h: 108.762,
          l: 108.72,
          c: 108.749,
          v: 434
        },
        {
          t: 19800,
          o: 108.751,
          h: 108.848,
          l: 108.746,
          c: 108.829,
          v: 684
        },
        {
          t: 20700,
          o: 108.826,
          h: 108.847,
          l: 108.802,
          c: 108.809,
          v: 747
        },
        {
          t: 21600,
          o: 108.808,
          h: 108.826,
          l: 108.784,
          c: 108.801,
          v: 598
        },
        {
          t: 22500,
          o: 108.801,
          h: 108.843,
          l: 108.796,
          c: 108.827,
          v: 466
        },
        {
          t: 23400,
          o: 108.828,
          h: 108.889,
          l: 108.819,
          c: 108.874,
          v: 550
        },
        {
          t: 24300,
          o: 108.876,
          h: 108.929,
          l: 108.861,
          c: 108.908,
          v: 459
        },
        {
          t: 25200,
          o: 108.911,
          h: 108.911,
          l: 108.845,
          c: 108.887,
          v: 652
        },
        {
          t: 26100,
          o: 108.887,
          h: 108.892,
          l: 108.84,
          c: 108.846,
          v: 646
        },
        {
          t: 27000,
          o: 108.844,
          h: 108.872,
          l: 108.822,
          c: 108.867,
          v: 691
        },
        {
          t: 27900,
          o: 108.868,
          h: 108.943,
          l: 108.864,
          c: 108.935,
          v: 735
        },
        {
          t: 28800,
          o: 108.93,
          h: 108.994,
          l: 108.912,
          c: 108.938,
          v: 1263
        },
        {
          t: 29700,
          o: 108.939,
          h: 109.081,
          l: 108.927,
          c: 109.043,
          v: 1361
        },
        {
          t: 30600,
          o: 109.045,
          h: 109.046,
          l: 109.016,
          c: 109.032,
          v: 879
        },
        {
          t: 31500,
          o: 109.03,
          h: 109.046,
          l: 109.024,
          c: 109.029,
          v: 956
        },
        {
          t: 32400,
          o: 109.028,
          h: 109.057,
          l: 109.016,
          c: 109.038,
          v: 811
        },
        {
          t: 33300,
          o: 109.038,
          h: 109.047,
          l: 109.002,
          c: 109.045,
          v: 639
        },
        {
          t: 34200,
          o: 109.044,
          h: 109.125,
          l: 109.044,
          c: 109.055,
          v: 759
        },
        {
          t: 35100,
          o: 109.057,
          h: 109.097,
          l: 109.056,
          c: 109.079,
          v: 645
        },
        {
          t: 36000,
          o: 109.081,
          h: 109.089,
          l: 109.054,
          c: 109.068,
          v: 535
        },
        {
          t: 36900,
          o: 109.066,
          h: 109.085,
          l: 109.03,
          c: 109.033,
          v: 427
        },
        {
          t: 37800,
          o: 109.032,
          h: 109.038,
          l: 109.01,
          c: 109.021,
          v: 502
        },
        {
          t: 38700,
          o: 109.02,
          h: 109.033,
          l: 108.975,
          c: 108.978,
          v: 412
        },
        {
          t: 39600,
          o: 108.979,
          h: 109.02,
          l: 108.97,
          c: 109.02,
          v: 398
        },
        {
          t: 40500,
          o: 109.02,
          h: 109.047,
          l: 109.007,
          c: 109.02,
          v: 451
        },
        {
          t: 41400,
          o: 109.022,
          h: 109.044,
          l: 109.001,
          c: 109.033,
          v: 433
        },
        {
          t: 42300,
          o: 109.037,
          h: 109.058,
          l: 109.019,
          c: 109.026,
          v: 397
        },
        {
          t: 43200,
          o: 109.025,
          h: 109.06,
          l: 109.022,
          c: 109.048,
          v: 577
        },
        {
          t: 44100,
          o: 109.048,
          h: 109.1,
          l: 109.034,
          c: 109.073,
          v: 547
        },
        {
          t: 45000,
          o: 109.074,
          h: 109.091,
          l: 109.056,
          c: 109.089,
          v: 441
        },
        {
          t: 45900,
          o: 109.121,
          h: 109.126,
          l: 109.121,
          c: 109.126,
          v: 4
        },
        {
          t: 46800,
          o: 109.094,
          h: 109.114,
          l: 109.08,
          c: 109.086,
          v: 500
        },
        {
          t: 47700,
          o: 109.085,
          h: 109.125,
          l: 109.063,
          c: 109.112,
          v: 702
        },
        {
          t: 48600,
          o: 109.112,
          h: 109.195,
          l: 109.092,
          c: 109.173,
          v: 821
        },
        {
          t: 49500,
          o: 109.173,
          h: 109.192,
          l: 109.15,
          c: 109.179,
          v: 625
        },
        {
          t: 50400,
          o: 109.178,
          h: 109.192,
          l: 109.136,
          c: 109.142,
          v: 804
        },
        {
          t: 51300,
          o: 109.142,
          h: 109.159,
          l: 109.124,
          c: 109.159,
          v: 503
        },
        {
          t: 52200,
          o: 109.157,
          h: 109.22,
          l: 109.134,
          c: 109.18,
          v: 1565
        },
        {
          t: 53100,
          o: 109.185,
          h: 109.266,
          l: 109.184,
          c: 109.244,
          v: 1267
        },
        {
          t: 54000,
          o: 109.242,
          h: 109.272,
          l: 109.193,
          c: 109.211,
          v: 1384
        },
        {
          t: 54900,
          o: 109.211,
          h: 109.355,
          l: 109.204,
          c: 109.351,
          v: 958
        },
        {
          t: 55800,
          o: 109.347,
          h: 109.347,
          l: 109.313,
          c: 109.328,
          v: 844
        },
        {
          t: 56700,
          o: 109.328,
          h: 109.345,
          l: 109.294,
          c: 109.33,
          v: 851
        },
        {
          t: 57600,
          o: 109.33,
          h: 109.346,
          l: 109.287,
          c: 109.3,
          v: 691
        },
        {
          t: 58500,
          o: 109.3,
          h: 109.42,
          l: 109.298,
          c: 109.405,
          v: 591
        },
        {
          t: 59400,
          o: 109.407,
          h: 109.441,
          l: 109.405,
          c: 109.428,
          v: 727
        },
        {
          t: 60300,
          o: 109.428,
          h: 109.431,
          l: 109.387,
          c: 109.4,
          v: 510
        },
        {
          t: 61200,
          o: 109.401,
          h: 109.432,
          l: 109.389,
          c: 109.425,
          v: 761
        },
        {
          t: 62100,
          o: 109.426,
          h: 109.456,
          l: 109.392,
          c: 109.451,
          v: 857
        },
        {
          t: 63000,
          o: 109.452,
          h: 109.464,
          l: 109.431,
          c: 109.441,
          v: 453
        },
        {
          t: 63900,
          o: 109.444,
          h: 109.46,
          l: 109.412,
          c: 109.456,
          v: 624
        },
        {
          t: 64800,
          o: 109.458,
          h: 109.49,
          l: 109.444,
          c: 109.444,
          v: 496
        },
        {
          t: 65700,
          o: 109.446,
          h: 109.453,
          l: 109.425,
          c: 109.448,
          v: 382
        },
        {
          t: 66600,
          o: 109.447,
          h: 109.507,
          l: 109.447,
          c: 109.492,
          v: 403
        },
        {
          t: 67500,
          o: 109.494,
          h: 109.517,
          l: 109.459,
          c: 109.473,
          v: 479
        },
        {
          t: 68400,
          o: 109.476,
          h: 109.512,
          l: 109.465,
          c: 109.502,
          v: 493
        },
        {
          t: 69300,
          o: 109.5,
          h: 109.509,
          l: 109.464,
          c: 109.491,
          v: 579
        },
        {
          t: 70200,
          o: 109.491,
          h: 109.533,
          l: 109.485,
          c: 109.526,
          v: 442
        },
        {
          t: 71100,
          o: 109.524,
          h: 109.527,
          l: 109.493,
          c: 109.495,
          v: 452
        },
        {
          t: 72000,
          o: 109.494,
          h: 109.51,
          l: 109.491,
          c: 109.509,
          v: 423
        },
        {
          t: 72900,
          o: 109.511,
          h: 109.539,
          l: 109.506,
          c: 109.534,
          v: 341
        },
        {
          t: 73800,
          o: 109.533,
          h: 109.538,
          l: 109.474,
          c: 109.482,
          v: 548
        },
        {
          t: 74700,
          o: 109.482,
          h: 109.49,
          l: 109.455,
          c: 109.466,
          v: 746
        },
        {
          t: 75600,
          o: 109.467,
          h: 109.481,
          l: 109.445,
          c: 109.481,
          v: 374
        },
        {
          t: 76500,
          o: 109.482,
          h: 109.521,
          l: 109.474,
          c: 109.517,
          v: 240
        },
        {
          t: 77400,
          o: 109.513,
          h: 109.536,
          l: 109.508,
          c: 109.536,
          v: 456
        },
        {
          t: 78300,
          o: 109.533,
          h: 109.533,
          l: 109.505,
          c: 109.515,
          v: 272
        },
        {
          t: 79200,
          o: 109.514,
          h: 109.516,
          l: 109.444,
          c: 109.501,
          v: 284
        },
        {
          t: 80100,
          o: 109.495,
          h: 109.504,
          l: 109.454,
          c: 109.475,
          v: 328
        },
        {
          t: 81000,
          o: 109.476,
          h: 109.483,
          l: 109.456,
          c: 109.456,
          v: 155
        },
        {
          t: 81900,
          o: 109.456,
          h: 109.464,
          l: 109.437,
          c: 109.459,
          v: 324
        },
        {
          t: 82800,
          o: 109.454,
          h: 109.488,
          l: 109.446,
          c: 109.474,
          v: 351
        },
        {
          t: 83700,
          o: 109.476,
          h: 109.491,
          l: 109.468,
          c: 109.479,
          v: 227
        },
        {
          t: 84600,
          o: 109.48,
          h: 109.484,
          l: 109.45,
          c: 109.45,
          v: 406
        },
        {
          t: 85500,
          o: 109.45,
          h: 109.452,
          l: 109.426,
          c: 109.437,
          v: 450
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 109.437,
          h: 109.474,
          l: 109.43,
          c: 109.46,
          v: 851
        },
        {
          t: 900,
          o: 109.461,
          h: 109.478,
          l: 109.376,
          c: 109.383,
          v: 853
        },
        {
          t: 1800,
          o: 109.385,
          h: 109.423,
          l: 109.385,
          c: 109.399,
          v: 703
        },
        {
          t: 2700,
          o: 109.397,
          h: 109.436,
          l: 109.366,
          c: 109.413,
          v: 942
        },
        {
          t: 3600,
          o: 109.412,
          h: 109.446,
          l: 109.383,
          c: 109.443,
          v: 695
        },
        {
          t: 4500,
          o: 109.442,
          h: 109.449,
          l: 109.376,
          c: 109.407,
          v: 792
        },
        {
          t: 5400,
          o: 109.405,
          h: 109.427,
          l: 109.361,
          c: 109.413,
          v: 1315
        },
        {
          t: 6300,
          o: 109.417,
          h: 109.482,
          l: 109.394,
          c: 109.479,
          v: 1113
        },
        {
          t: 7200,
          o: 109.48,
          h: 109.486,
          l: 109.458,
          c: 109.464,
          v: 924
        },
        {
          t: 8100,
          o: 109.463,
          h: 109.47,
          l: 109.443,
          c: 109.464,
          v: 667
        },
        {
          t: 9000,
          o: 109.463,
          h: 109.483,
          l: 109.446,
          c: 109.472,
          v: 620
        },
        {
          t: 9900,
          o: 109.471,
          h: 109.475,
          l: 109.445,
          c: 109.452,
          v: 595
        },
        {
          t: 10800,
          o: 109.452,
          h: 109.467,
          l: 109.408,
          c: 109.414,
          v: 597
        },
        {
          t: 11700,
          o: 109.414,
          h: 109.433,
          l: 109.401,
          c: 109.408,
          v: 430
        },
        {
          t: 12600,
          o: 109.41,
          h: 109.447,
          l: 109.41,
          c: 109.44,
          v: 496
        },
        {
          t: 13500,
          o: 109.44,
          h: 109.457,
          l: 109.428,
          c: 109.441,
          v: 345
        },
        {
          t: 14400,
          o: 109.44,
          h: 109.461,
          l: 109.431,
          c: 109.453,
          v: 295
        },
        {
          t: 15300,
          o: 109.454,
          h: 109.471,
          l: 109.449,
          c: 109.471,
          v: 285
        },
        {
          t: 16200,
          o: 109.47,
          h: 109.477,
          l: 109.458,
          c: 109.462,
          v: 247
        },
        {
          t: 17100,
          o: 109.463,
          h: 109.473,
          l: 109.455,
          c: 109.466,
          v: 190
        },
        {
          t: 18000,
          o: 109.467,
          h: 109.472,
          l: 109.445,
          c: 109.449,
          v: 274
        },
        {
          t: 18900,
          o: 109.448,
          h: 109.452,
          l: 109.426,
          c: 109.431,
          v: 385
        },
        {
          t: 19800,
          o: 109.433,
          h: 109.436,
          l: 109.411,
          c: 109.42,
          v: 417
        },
        {
          t: 20700,
          o: 109.422,
          h: 109.434,
          l: 109.4,
          c: 109.427,
          v: 637
        },
        {
          t: 21600,
          o: 109.43,
          h: 109.457,
          l: 109.405,
          c: 109.437,
          v: 608
        },
        {
          t: 22500,
          o: 109.436,
          h: 109.441,
          l: 109.421,
          c: 109.425,
          v: 393
        },
        {
          t: 23400,
          o: 109.424,
          h: 109.43,
          l: 109.401,
          c: 109.407,
          v: 245
        },
        {
          t: 24300,
          o: 109.406,
          h: 109.426,
          l: 109.395,
          c: 109.406,
          v: 240
        },
        {
          t: 25200,
          o: 109.404,
          h: 109.415,
          l: 109.32,
          c: 109.338,
          v: 613
        },
        {
          t: 26100,
          o: 109.34,
          h: 109.356,
          l: 109.312,
          c: 109.325,
          v: 529
        },
        {
          t: 27000,
          o: 109.326,
          h: 109.344,
          l: 109.313,
          c: 109.34,
          v: 502
        },
        {
          t: 27900,
          o: 109.339,
          h: 109.371,
          l: 109.325,
          c: 109.33,
          v: 578
        },
        {
          t: 28800,
          o: 109.332,
          h: 109.359,
          l: 109.298,
          c: 109.35,
          v: 961
        },
        {
          t: 29700,
          o: 109.351,
          h: 109.426,
          l: 109.335,
          c: 109.417,
          v: 909
        },
        {
          t: 30600,
          o: 109.42,
          h: 109.716,
          l: 109.416,
          c: 109.561,
          v: 2745
        },
        {
          t: 31500,
          o: 109.56,
          h: 109.611,
          l: 109.522,
          c: 109.551,
          v: 2191
        },
        {
          t: 32400,
          o: 109.55,
          h: 109.622,
          l: 109.526,
          c: 109.556,
          v: 1642
        },
        {
          t: 33300,
          o: 109.555,
          h: 109.641,
          l: 109.543,
          c: 109.628,
          v: 1486
        },
        {
          t: 34200,
          o: 109.628,
          h: 109.694,
          l: 109.628,
          c: 109.687,
          v: 1492
        },
        {
          t: 35100,
          o: 109.689,
          h: 109.7,
          l: 109.66,
          c: 109.69,
          v: 1203
        },
        {
          t: 36000,
          o: 109.69,
          h: 109.699,
          l: 109.645,
          c: 109.671,
          v: 1128
        }
      ]
    }
  ]
}

export const defaultEntireData: { [currency: string]: IServerBar[] } = {
  EURUSD5: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 1.10939,
          h: 1.10939,
          l: 1.10918,
          c: 1.10921,
          v: 67
        },
        {
          t: 79500,
          o: 1.10906,
          h: 1.1092,
          l: 1.10905,
          c: 1.10914,
          v: 25
        },
        {
          t: 79800,
          o: 1.10915,
          h: 1.10918,
          l: 1.10913,
          c: 1.10913,
          v: 66
        },
        {
          t: 80100,
          o: 1.10913,
          h: 1.10914,
          l: 1.10906,
          c: 1.10906,
          v: 16
        },
        {
          t: 80400,
          o: 1.10906,
          h: 1.10907,
          l: 1.10856,
          c: 1.1087,
          v: 51
        },
        {
          t: 80700,
          o: 1.1087,
          h: 1.109,
          l: 1.1087,
          c: 1.10891,
          v: 43
        },
        {
          t: 81000,
          o: 1.10891,
          h: 1.10897,
          l: 1.10847,
          c: 1.10848,
          v: 134
        },
        {
          t: 81300,
          o: 1.10846,
          h: 1.10847,
          l: 1.10817,
          c: 1.10822,
          v: 125
        },
        {
          t: 81600,
          o: 1.10822,
          h: 1.10855,
          l: 1.10817,
          c: 1.10852,
          v: 134
        },
        {
          t: 81900,
          o: 1.10852,
          h: 1.10876,
          l: 1.10852,
          c: 1.1087,
          v: 150
        },
        {
          t: 82200,
          o: 1.10868,
          h: 1.10872,
          l: 1.10861,
          c: 1.1087,
          v: 243
        },
        {
          t: 82500,
          o: 1.10864,
          h: 1.10871,
          l: 1.10817,
          c: 1.10835,
          v: 268
        },
        {
          t: 82800,
          o: 1.10841,
          h: 1.10898,
          l: 1.10834,
          c: 1.10898,
          v: 281
        },
        {
          t: 83100,
          o: 1.10897,
          h: 1.109,
          l: 1.10891,
          c: 1.10896,
          v: 116
        },
        {
          t: 83400,
          o: 1.10896,
          h: 1.10902,
          l: 1.10891,
          c: 1.10891,
          v: 185
        },
        {
          t: 83700,
          o: 1.10892,
          h: 1.10895,
          l: 1.10885,
          c: 1.10888,
          v: 69
        },
        {
          t: 84000,
          o: 1.10887,
          h: 1.10888,
          l: 1.1088,
          c: 1.10881,
          v: 103
        },
        {
          t: 84300,
          o: 1.1088,
          h: 1.10892,
          l: 1.1088,
          c: 1.10887,
          v: 146
        },
        {
          t: 84600,
          o: 1.10889,
          h: 1.10898,
          l: 1.10885,
          c: 1.10898,
          v: 86
        },
        {
          t: 84900,
          o: 1.10897,
          h: 1.10901,
          l: 1.10889,
          c: 1.1089,
          v: 111
        },
        {
          t: 85200,
          o: 1.10891,
          h: 1.10891,
          l: 1.1088,
          c: 1.10881,
          v: 70
        },
        {
          t: 85500,
          o: 1.1088,
          h: 1.10888,
          l: 1.1088,
          c: 1.10886,
          v: 93
        },
        {
          t: 85800,
          o: 1.10887,
          h: 1.10892,
          l: 1.10884,
          c: 1.10889,
          v: 109
        },
        {
          t: 86100,
          o: 1.1089,
          h: 1.10891,
          l: 1.10885,
          c: 1.10886,
          v: 59
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.10886,
          h: 1.10886,
          l: 1.10865,
          c: 1.10867,
          v: 86
        },
        {
          t: 300,
          o: 1.10866,
          h: 1.10872,
          l: 1.10854,
          c: 1.10871,
          v: 91
        },
        {
          t: 600,
          o: 1.10872,
          h: 1.10872,
          l: 1.10853,
          c: 1.10856,
          v: 95
        },
        {
          t: 900,
          o: 1.10857,
          h: 1.10863,
          l: 1.10856,
          c: 1.1086,
          v: 80
        },
        {
          t: 1200,
          o: 1.1086,
          h: 1.10866,
          l: 1.1086,
          c: 1.10862,
          v: 57
        },
        {
          t: 1500,
          o: 1.10861,
          h: 1.10864,
          l: 1.10855,
          c: 1.10855,
          v: 43
        },
        {
          t: 1800,
          o: 1.10856,
          h: 1.10856,
          l: 1.10827,
          c: 1.10827,
          v: 138
        },
        {
          t: 2100,
          o: 1.10829,
          h: 1.10829,
          l: 1.10805,
          c: 1.10814,
          v: 96
        },
        {
          t: 2400,
          o: 1.10816,
          h: 1.10816,
          l: 1.10805,
          c: 1.10806,
          v: 126
        },
        {
          t: 2700,
          o: 1.10804,
          h: 1.10809,
          l: 1.10795,
          c: 1.10806,
          v: 161
        },
        {
          t: 3000,
          o: 1.10806,
          h: 1.10816,
          l: 1.10799,
          c: 1.10799,
          v: 110
        },
        {
          t: 3300,
          o: 1.10801,
          h: 1.10815,
          l: 1.10801,
          c: 1.10811,
          v: 100
        },
        {
          t: 3600,
          o: 1.10812,
          h: 1.10818,
          l: 1.10804,
          c: 1.10805,
          v: 164
        },
        {
          t: 3900,
          o: 1.10806,
          h: 1.10806,
          l: 1.10799,
          c: 1.108,
          v: 127
        },
        {
          t: 4200,
          o: 1.10801,
          h: 1.10816,
          l: 1.10801,
          c: 1.1081,
          v: 137
        },
        {
          t: 4500,
          o: 1.10811,
          h: 1.10815,
          l: 1.10799,
          c: 1.10801,
          v: 138
        },
        {
          t: 4800,
          o: 1.10801,
          h: 1.1085,
          l: 1.10801,
          c: 1.10845,
          v: 220
        },
        {
          t: 5100,
          o: 1.10844,
          h: 1.10872,
          l: 1.10836,
          c: 1.10867,
          v: 153
        },
        {
          t: 5400,
          o: 1.10868,
          h: 1.10868,
          l: 1.10829,
          c: 1.10841,
          v: 194
        },
        {
          t: 5700,
          o: 1.10841,
          h: 1.10848,
          l: 1.10829,
          c: 1.1083,
          v: 186
        },
        {
          t: 6000,
          o: 1.10829,
          h: 1.1084,
          l: 1.10821,
          c: 1.10823,
          v: 125
        },
        {
          t: 6300,
          o: 1.10824,
          h: 1.10827,
          l: 1.10809,
          c: 1.10818,
          v: 177
        },
        {
          t: 6600,
          o: 1.1082,
          h: 1.10821,
          l: 1.10815,
          c: 1.1082,
          v: 135
        },
        {
          t: 6900,
          o: 1.1082,
          h: 1.10827,
          l: 1.10819,
          c: 1.10827,
          v: 120
        },
        {
          t: 7200,
          o: 1.10826,
          h: 1.1084,
          l: 1.10821,
          c: 1.1084,
          v: 101
        },
        {
          t: 7500,
          o: 1.10839,
          h: 1.1084,
          l: 1.10815,
          c: 1.1082,
          v: 102
        },
        {
          t: 7800,
          o: 1.10819,
          h: 1.10834,
          l: 1.10816,
          c: 1.1083,
          v: 192
        },
        {
          t: 8100,
          o: 1.1083,
          h: 1.10832,
          l: 1.10816,
          c: 1.10816,
          v: 112
        },
        {
          t: 8400,
          o: 1.10816,
          h: 1.10823,
          l: 1.10809,
          c: 1.10821,
          v: 116
        },
        {
          t: 8700,
          o: 1.10821,
          h: 1.10827,
          l: 1.10816,
          c: 1.10818,
          v: 124
        },
        {
          t: 9000,
          o: 1.10819,
          h: 1.10821,
          l: 1.10811,
          c: 1.10817,
          v: 128
        },
        {
          t: 9300,
          o: 1.10818,
          h: 1.10838,
          l: 1.10813,
          c: 1.10825,
          v: 160
        },
        {
          t: 9600,
          o: 1.10825,
          h: 1.10841,
          l: 1.10813,
          c: 1.10813,
          v: 110
        },
        {
          t: 9900,
          o: 1.10812,
          h: 1.10822,
          l: 1.10803,
          c: 1.10803,
          v: 132
        },
        {
          t: 10200,
          o: 1.10803,
          h: 1.10816,
          l: 1.10798,
          c: 1.10815,
          v: 127
        },
        {
          t: 10500,
          o: 1.10815,
          h: 1.10823,
          l: 1.10811,
          c: 1.10812,
          v: 55
        },
        {
          t: 10800,
          o: 1.10812,
          h: 1.1083,
          l: 1.10812,
          c: 1.10822,
          v: 122
        },
        {
          t: 11100,
          o: 1.10822,
          h: 1.10833,
          l: 1.10821,
          c: 1.10822,
          v: 124
        },
        {
          t: 11400,
          o: 1.10822,
          h: 1.10826,
          l: 1.10817,
          c: 1.10825,
          v: 85
        },
        {
          t: 11700,
          o: 1.10825,
          h: 1.1083,
          l: 1.10823,
          c: 1.10828,
          v: 116
        },
        {
          t: 12000,
          o: 1.10828,
          h: 1.10828,
          l: 1.10806,
          c: 1.10807,
          v: 68
        },
        {
          t: 12300,
          o: 1.10806,
          h: 1.10809,
          l: 1.10801,
          c: 1.10802,
          v: 87
        },
        {
          t: 12600,
          o: 1.10806,
          h: 1.10812,
          l: 1.10798,
          c: 1.10808,
          v: 68
        },
        {
          t: 12900,
          o: 1.10808,
          h: 1.1081,
          l: 1.10806,
          c: 1.10808,
          v: 74
        },
        {
          t: 13200,
          o: 1.10806,
          h: 1.10812,
          l: 1.10802,
          c: 1.10812,
          v: 38
        },
        {
          t: 13500,
          o: 1.10812,
          h: 1.10818,
          l: 1.1081,
          c: 1.10818,
          v: 27
        },
        {
          t: 13800,
          o: 1.10817,
          h: 1.10819,
          l: 1.10812,
          c: 1.10813,
          v: 40
        },
        {
          t: 14100,
          o: 1.10813,
          h: 1.10813,
          l: 1.10802,
          c: 1.10806,
          v: 59
        },
        {
          t: 14400,
          o: 1.10806,
          h: 1.10814,
          l: 1.10806,
          c: 1.10811,
          v: 62
        },
        {
          t: 14700,
          o: 1.10812,
          h: 1.10819,
          l: 1.10808,
          c: 1.10819,
          v: 49
        },
        {
          t: 15000,
          o: 1.10819,
          h: 1.10819,
          l: 1.10818,
          c: 1.10819,
          v: 42
        },
        {
          t: 15300,
          o: 1.10819,
          h: 1.10828,
          l: 1.10818,
          c: 1.10824,
          v: 64
        },
        {
          t: 15600,
          o: 1.10827,
          h: 1.10829,
          l: 1.10826,
          c: 1.10827,
          v: 48
        },
        {
          t: 15900,
          o: 1.10828,
          h: 1.10828,
          l: 1.10826,
          c: 1.10828,
          v: 13
        },
        {
          t: 16200,
          o: 1.10827,
          h: 1.10829,
          l: 1.10818,
          c: 1.10828,
          v: 34
        },
        {
          t: 16500,
          o: 1.10829,
          h: 1.10843,
          l: 1.10828,
          c: 1.10842,
          v: 29
        },
        {
          t: 16800,
          o: 1.1084,
          h: 1.1084,
          l: 1.1083,
          c: 1.10833,
          v: 55
        },
        {
          t: 17100,
          o: 1.10832,
          h: 1.10837,
          l: 1.10824,
          c: 1.10825,
          v: 54
        },
        {
          t: 17400,
          o: 1.10826,
          h: 1.10827,
          l: 1.10822,
          c: 1.10826,
          v: 19
        },
        {
          t: 17700,
          o: 1.10822,
          h: 1.10822,
          l: 1.10813,
          c: 1.10814,
          v: 44
        },
        {
          t: 18000,
          o: 1.10813,
          h: 1.10815,
          l: 1.10809,
          c: 1.10811,
          v: 83
        },
        {
          t: 18300,
          o: 1.1081,
          h: 1.10818,
          l: 1.1081,
          c: 1.10818,
          v: 56
        },
        {
          t: 18600,
          o: 1.10818,
          h: 1.10823,
          l: 1.10818,
          c: 1.10823,
          v: 52
        },
        {
          t: 18900,
          o: 1.10826,
          h: 1.10832,
          l: 1.10823,
          c: 1.1083,
          v: 101
        },
        {
          t: 19200,
          o: 1.1083,
          h: 1.10833,
          l: 1.10826,
          c: 1.10832,
          v: 81
        },
        {
          t: 19500,
          o: 1.10832,
          h: 1.10839,
          l: 1.10824,
          c: 1.10827,
          v: 98
        },
        {
          t: 19800,
          o: 1.10827,
          h: 1.10839,
          l: 1.10825,
          c: 1.10838,
          v: 108
        },
        {
          t: 20100,
          o: 1.10837,
          h: 1.10839,
          l: 1.10833,
          c: 1.10837,
          v: 70
        },
        {
          t: 20400,
          o: 1.10837,
          h: 1.10839,
          l: 1.10834,
          c: 1.10835,
          v: 56
        },
        {
          t: 20700,
          o: 1.10834,
          h: 1.10839,
          l: 1.10833,
          c: 1.10837,
          v: 36
        },
        {
          t: 21000,
          o: 1.10839,
          h: 1.10842,
          l: 1.10839,
          c: 1.1084,
          v: 38
        },
        {
          t: 21300,
          o: 1.10839,
          h: 1.10843,
          l: 1.10833,
          c: 1.10839,
          v: 38
        },
        {
          t: 21600,
          o: 1.1084,
          h: 1.10848,
          l: 1.10839,
          c: 1.10848,
          v: 39
        },
        {
          t: 21900,
          o: 1.10848,
          h: 1.10858,
          l: 1.10848,
          c: 1.10853,
          v: 78
        },
        {
          t: 22200,
          o: 1.10853,
          h: 1.10855,
          l: 1.10853,
          c: 1.10853,
          v: 32
        },
        {
          t: 22500,
          o: 1.10854,
          h: 1.10855,
          l: 1.10832,
          c: 1.10836,
          v: 48
        },
        {
          t: 22800,
          o: 1.10836,
          h: 1.10838,
          l: 1.10826,
          c: 1.10828,
          v: 34
        },
        {
          t: 23100,
          o: 1.10823,
          h: 1.10828,
          l: 1.10816,
          c: 1.1082,
          v: 48
        },
        {
          t: 23400,
          o: 1.10822,
          h: 1.10823,
          l: 1.10812,
          c: 1.10813,
          v: 50
        },
        {
          t: 23700,
          o: 1.10818,
          h: 1.10818,
          l: 1.10812,
          c: 1.10812,
          v: 37
        },
        {
          t: 24000,
          o: 1.10812,
          h: 1.10828,
          l: 1.10812,
          c: 1.10828,
          v: 67
        },
        {
          t: 24300,
          o: 1.10827,
          h: 1.10836,
          l: 1.10818,
          c: 1.10828,
          v: 138
        },
        {
          t: 24600,
          o: 1.10828,
          h: 1.10835,
          l: 1.10822,
          c: 1.10833,
          v: 153
        },
        {
          t: 24900,
          o: 1.10829,
          h: 1.10834,
          l: 1.10816,
          c: 1.10817,
          v: 78
        },
        {
          t: 25200,
          o: 1.10819,
          h: 1.10841,
          l: 1.10813,
          c: 1.10824,
          v: 199
        },
        {
          t: 25500,
          o: 1.10826,
          h: 1.10833,
          l: 1.10816,
          c: 1.10823,
          v: 112
        },
        {
          t: 25800,
          o: 1.10824,
          h: 1.10825,
          l: 1.1077,
          c: 1.10771,
          v: 190
        },
        {
          t: 26100,
          o: 1.10773,
          h: 1.10785,
          l: 1.10763,
          c: 1.10764,
          v: 210
        },
        {
          t: 26400,
          o: 1.10762,
          h: 1.10788,
          l: 1.10761,
          c: 1.10762,
          v: 208
        },
        {
          t: 26700,
          o: 1.10761,
          h: 1.1077,
          l: 1.10741,
          c: 1.10745,
          v: 211
        },
        {
          t: 27000,
          o: 1.1075,
          h: 1.10756,
          l: 1.10726,
          c: 1.10726,
          v: 187
        },
        {
          t: 27300,
          o: 1.10726,
          h: 1.10729,
          l: 1.1071,
          c: 1.1072,
          v: 208
        },
        {
          t: 27600,
          o: 1.10721,
          h: 1.1074,
          l: 1.10699,
          c: 1.10728,
          v: 204
        },
        {
          t: 27900,
          o: 1.10728,
          h: 1.10762,
          l: 1.10717,
          c: 1.10745,
          v: 210
        },
        {
          t: 28200,
          o: 1.10745,
          h: 1.10748,
          l: 1.10727,
          c: 1.10738,
          v: 186
        },
        {
          t: 28500,
          o: 1.10738,
          h: 1.10751,
          l: 1.10727,
          c: 1.10743,
          v: 199
        },
        {
          t: 28800,
          o: 1.10744,
          h: 1.10766,
          l: 1.10707,
          c: 1.10755,
          v: 336
        },
        {
          t: 29100,
          o: 1.10756,
          h: 1.10763,
          l: 1.10732,
          c: 1.10753,
          v: 284
        },
        {
          t: 29400,
          o: 1.10753,
          h: 1.10778,
          l: 1.10741,
          c: 1.10778,
          v: 241
        },
        {
          t: 29700,
          o: 1.10776,
          h: 1.10779,
          l: 1.10758,
          c: 1.10774,
          v: 262
        },
        {
          t: 30000,
          o: 1.10774,
          h: 1.10774,
          l: 1.10741,
          c: 1.10763,
          v: 242
        },
        {
          t: 30300,
          o: 1.10761,
          h: 1.10774,
          l: 1.10729,
          c: 1.10738,
          v: 269
        },
        {
          t: 30600,
          o: 1.10737,
          h: 1.10752,
          l: 1.10729,
          c: 1.10732,
          v: 247
        },
        {
          t: 30900,
          o: 1.10731,
          h: 1.10738,
          l: 1.10703,
          c: 1.10703,
          v: 257
        },
        {
          t: 31200,
          o: 1.10703,
          h: 1.1071,
          l: 1.10696,
          c: 1.10698,
          v: 189
        },
        {
          t: 31500,
          o: 1.10698,
          h: 1.10716,
          l: 1.10678,
          c: 1.10678,
          v: 206
        },
        {
          t: 31800,
          o: 1.10677,
          h: 1.10684,
          l: 1.10656,
          c: 1.10656,
          v: 201
        },
        {
          t: 32100,
          o: 1.10656,
          h: 1.10668,
          l: 1.10637,
          c: 1.10655,
          v: 275
        },
        {
          t: 32400,
          o: 1.10657,
          h: 1.10659,
          l: 1.10633,
          c: 1.10655,
          v: 253
        },
        {
          t: 32700,
          o: 1.10655,
          h: 1.10665,
          l: 1.10645,
          c: 1.10647,
          v: 196
        },
        {
          t: 33000,
          o: 1.10646,
          h: 1.10647,
          l: 1.10601,
          c: 1.10622,
          v: 263
        },
        {
          t: 33300,
          o: 1.10623,
          h: 1.10639,
          l: 1.10603,
          c: 1.10603,
          v: 220
        },
        {
          t: 33600,
          o: 1.10602,
          h: 1.10608,
          l: 1.10586,
          c: 1.10592,
          v: 236
        },
        {
          t: 33900,
          o: 1.10594,
          h: 1.10619,
          l: 1.10592,
          c: 1.10618,
          v: 215
        },
        {
          t: 34200,
          o: 1.10617,
          h: 1.1064,
          l: 1.10615,
          c: 1.10638,
          v: 162
        },
        {
          t: 34500,
          o: 1.10637,
          h: 1.10667,
          l: 1.10636,
          c: 1.10662,
          v: 164
        },
        {
          t: 34800,
          o: 1.10662,
          h: 1.10689,
          l: 1.10662,
          c: 1.10686,
          v: 166
        },
        {
          t: 35100,
          o: 1.10689,
          h: 1.10694,
          l: 1.10651,
          c: 1.10655,
          v: 203
        },
        {
          t: 35400,
          o: 1.10657,
          h: 1.10697,
          l: 1.10656,
          c: 1.10695,
          v: 182
        },
        {
          t: 35700,
          o: 1.10697,
          h: 1.10723,
          l: 1.10695,
          c: 1.10722,
          v: 311
        },
        {
          t: 36000,
          o: 1.10722,
          h: 1.10737,
          l: 1.10696,
          c: 1.10698,
          v: 351
        },
        {
          t: 36300,
          o: 1.107,
          h: 1.107,
          l: 1.10672,
          c: 1.10676,
          v: 265
        },
        {
          t: 36600,
          o: 1.10678,
          h: 1.10683,
          l: 1.1066,
          c: 1.10673,
          v: 207
        },
        {
          t: 36900,
          o: 1.10674,
          h: 1.10677,
          l: 1.1066,
          c: 1.10661,
          v: 185
        },
        {
          t: 37200,
          o: 1.10661,
          h: 1.1068,
          l: 1.10651,
          c: 1.10672,
          v: 189
        },
        {
          t: 37500,
          o: 1.1067,
          h: 1.10678,
          l: 1.10646,
          c: 1.1067,
          v: 223
        },
        {
          t: 37800,
          o: 1.1067,
          h: 1.1067,
          l: 1.10652,
          c: 1.10662,
          v: 121
        },
        {
          t: 38100,
          o: 1.10661,
          h: 1.10668,
          l: 1.10655,
          c: 1.10668,
          v: 122
        },
        {
          t: 38400,
          o: 1.10666,
          h: 1.10688,
          l: 1.10645,
          c: 1.10684,
          v: 193
        },
        {
          t: 38700,
          o: 1.10685,
          h: 1.10695,
          l: 1.10655,
          c: 1.10667,
          v: 207
        },
        {
          t: 39000,
          o: 1.10666,
          h: 1.1067,
          l: 1.10652,
          c: 1.10657,
          v: 177
        },
        {
          t: 39300,
          o: 1.10662,
          h: 1.10678,
          l: 1.10638,
          c: 1.10643,
          v: 169
        },
        {
          t: 39600,
          o: 1.10645,
          h: 1.10677,
          l: 1.10637,
          c: 1.10667,
          v: 261
        },
        {
          t: 39900,
          o: 1.10667,
          h: 1.10673,
          l: 1.10628,
          c: 1.1063,
          v: 217
        },
        {
          t: 40200,
          o: 1.1063,
          h: 1.10639,
          l: 1.10618,
          c: 1.10625,
          v: 194
        },
        {
          t: 40500,
          o: 1.10627,
          h: 1.10631,
          l: 1.10606,
          c: 1.10611,
          v: 196
        },
        {
          t: 40800,
          o: 1.10611,
          h: 1.10627,
          l: 1.10598,
          c: 1.10603,
          v: 204
        },
        {
          t: 41100,
          o: 1.106,
          h: 1.1062,
          l: 1.10597,
          c: 1.10618,
          v: 204
        },
        {
          t: 41400,
          o: 1.10617,
          h: 1.10617,
          l: 1.10592,
          c: 1.10609,
          v: 183
        },
        {
          t: 41700,
          o: 1.1061,
          h: 1.1061,
          l: 1.10586,
          c: 1.10593,
          v: 114
        },
        {
          t: 42000,
          o: 1.10591,
          h: 1.10626,
          l: 1.10589,
          c: 1.10624,
          v: 243
        },
        {
          t: 42300,
          o: 1.10624,
          h: 1.1063,
          l: 1.106,
          c: 1.10601,
          v: 214
        },
        {
          t: 42600,
          o: 1.10601,
          h: 1.10623,
          l: 1.10599,
          c: 1.10608,
          v: 227
        },
        {
          t: 42900,
          o: 1.10609,
          h: 1.10622,
          l: 1.10579,
          c: 1.10622,
          v: 178
        },
        {
          t: 43200,
          o: 1.10623,
          h: 1.10625,
          l: 1.10609,
          c: 1.10623,
          v: 158
        },
        {
          t: 43500,
          o: 1.10623,
          h: 1.10642,
          l: 1.10621,
          c: 1.1064,
          v: 147
        },
        {
          t: 43800,
          o: 1.10639,
          h: 1.1066,
          l: 1.10639,
          c: 1.10658,
          v: 97
        },
        {
          t: 44100,
          o: 1.10654,
          h: 1.10655,
          l: 1.10638,
          c: 1.10647,
          v: 146
        },
        {
          t: 44400,
          o: 1.10647,
          h: 1.10651,
          l: 1.10633,
          c: 1.10637,
          v: 136
        },
        {
          t: 44700,
          o: 1.10637,
          h: 1.10644,
          l: 1.10626,
          c: 1.10629,
          v: 97
        },
        {
          t: 45000,
          o: 1.1063,
          h: 1.10644,
          l: 1.10629,
          c: 1.10639,
          v: 77
        },
        {
          t: 45300,
          o: 1.10639,
          h: 1.10639,
          l: 1.10618,
          c: 1.10619,
          v: 107
        },
        {
          t: 45600,
          o: 1.10622,
          h: 1.10627,
          l: 1.10616,
          c: 1.10624,
          v: 85
        },
        {
          t: 45900,
          o: 1.10625,
          h: 1.10651,
          l: 1.10625,
          c: 1.10648,
          v: 125
        },
        {
          t: 46200,
          o: 1.10648,
          h: 1.1066,
          l: 1.10645,
          c: 1.10648,
          v: 142
        },
        {
          t: 46500,
          o: 1.10651,
          h: 1.10662,
          l: 1.10645,
          c: 1.10654,
          v: 161
        },
        {
          t: 46800,
          o: 1.10654,
          h: 1.10657,
          l: 1.10638,
          c: 1.10641,
          v: 162
        },
        {
          t: 47100,
          o: 1.1064,
          h: 1.10663,
          l: 1.10633,
          c: 1.10658,
          v: 151
        },
        {
          t: 47400,
          o: 1.10656,
          h: 1.1066,
          l: 1.10648,
          c: 1.10655,
          v: 107
        },
        {
          t: 47700,
          o: 1.10651,
          h: 1.10655,
          l: 1.10632,
          c: 1.10633,
          v: 125
        },
        {
          t: 48000,
          o: 1.10638,
          h: 1.10658,
          l: 1.10628,
          c: 1.10645,
          v: 182
        },
        {
          t: 48300,
          o: 1.10646,
          h: 1.10651,
          l: 1.10617,
          c: 1.10618,
          v: 213
        },
        {
          t: 48600,
          o: 1.10618,
          h: 1.10638,
          l: 1.10613,
          c: 1.10628,
          v: 128
        },
        {
          t: 48900,
          o: 1.10626,
          h: 1.10633,
          l: 1.10618,
          c: 1.10623,
          v: 119
        },
        {
          t: 49200,
          o: 1.10624,
          h: 1.10629,
          l: 1.10618,
          c: 1.10628,
          v: 107
        },
        {
          t: 49500,
          o: 1.1063,
          h: 1.10643,
          l: 1.10628,
          c: 1.10643,
          v: 159
        },
        {
          t: 49800,
          o: 1.1064,
          h: 1.1065,
          l: 1.10638,
          c: 1.10638,
          v: 89
        },
        {
          t: 50100,
          o: 1.10636,
          h: 1.10638,
          l: 1.10618,
          c: 1.10626,
          v: 126
        },
        {
          t: 50400,
          o: 1.10627,
          h: 1.10633,
          l: 1.1061,
          c: 1.10614,
          v: 161
        },
        {
          t: 50700,
          o: 1.10614,
          h: 1.10619,
          l: 1.10587,
          c: 1.10588,
          v: 176
        },
        {
          t: 51000,
          o: 1.10587,
          h: 1.10601,
          l: 1.10566,
          c: 1.10568,
          v: 245
        },
        {
          t: 51300,
          o: 1.10568,
          h: 1.10573,
          l: 1.10557,
          c: 1.10567,
          v: 173
        },
        {
          t: 51600,
          o: 1.10568,
          h: 1.10569,
          l: 1.10537,
          c: 1.10537,
          v: 202
        },
        {
          t: 51900,
          o: 1.10538,
          h: 1.10545,
          l: 1.10527,
          c: 1.10538,
          v: 183
        },
        {
          t: 52200,
          o: 1.10539,
          h: 1.10543,
          l: 1.10499,
          c: 1.10503,
          v: 285
        },
        {
          t: 52500,
          o: 1.10503,
          h: 1.10536,
          l: 1.10496,
          c: 1.10523,
          v: 284
        },
        {
          t: 52800,
          o: 1.10521,
          h: 1.10521,
          l: 1.10499,
          c: 1.10501,
          v: 223
        },
        {
          t: 53100,
          o: 1.105,
          h: 1.10502,
          l: 1.10458,
          c: 1.10473,
          v: 340
        },
        {
          t: 53400,
          o: 1.10474,
          h: 1.1048,
          l: 1.10445,
          c: 1.10455,
          v: 322
        },
        {
          t: 53700,
          o: 1.10452,
          h: 1.10486,
          l: 1.10446,
          c: 1.1047,
          v: 294
        },
        {
          t: 54000,
          o: 1.10447,
          h: 1.10479,
          l: 1.1035,
          c: 1.10438,
          v: 627
        },
        {
          t: 54300,
          o: 1.1044,
          h: 1.10494,
          l: 1.1044,
          c: 1.10479,
          v: 572
        },
        {
          t: 54600,
          o: 1.10479,
          h: 1.1048,
          l: 1.10432,
          c: 1.10438,
          v: 417
        },
        {
          t: 54900,
          o: 1.10438,
          h: 1.10495,
          l: 1.10438,
          c: 1.10466,
          v: 304
        },
        {
          t: 55200,
          o: 1.10463,
          h: 1.10495,
          l: 1.10452,
          c: 1.1046,
          v: 339
        },
        {
          t: 55500,
          o: 1.10463,
          h: 1.10495,
          l: 1.1046,
          c: 1.10495,
          v: 293
        },
        {
          t: 55800,
          o: 1.10494,
          h: 1.10496,
          l: 1.10459,
          c: 1.10489,
          v: 301
        },
        {
          t: 56100,
          o: 1.10487,
          h: 1.10488,
          l: 1.10459,
          c: 1.10459,
          v: 226
        },
        {
          t: 56400,
          o: 1.1046,
          h: 1.10481,
          l: 1.10455,
          c: 1.10455,
          v: 264
        },
        {
          t: 56700,
          o: 1.10449,
          h: 1.10495,
          l: 1.10442,
          c: 1.10494,
          v: 292
        },
        {
          t: 57000,
          o: 1.10494,
          h: 1.10504,
          l: 1.10481,
          c: 1.10503,
          v: 302
        },
        {
          t: 57300,
          o: 1.10504,
          h: 1.10594,
          l: 1.10504,
          c: 1.10552,
          v: 511
        },
        {
          t: 57600,
          o: 1.10553,
          h: 1.10588,
          l: 1.10543,
          c: 1.10568,
          v: 324
        },
        {
          t: 57900,
          o: 1.10573,
          h: 1.10584,
          l: 1.10543,
          c: 1.10553,
          v: 216
        },
        {
          t: 58200,
          o: 1.10554,
          h: 1.10556,
          l: 1.10533,
          c: 1.10553,
          v: 266
        },
        {
          t: 58500,
          o: 1.10555,
          h: 1.10596,
          l: 1.10549,
          c: 1.10594,
          v: 233
        },
        {
          t: 58800,
          o: 1.10593,
          h: 1.10596,
          l: 1.10551,
          c: 1.10566,
          v: 263
        },
        {
          t: 59100,
          o: 1.10567,
          h: 1.10577,
          l: 1.10552,
          c: 1.10577,
          v: 196
        },
        {
          t: 59400,
          o: 1.10578,
          h: 1.10608,
          l: 1.10572,
          c: 1.10603,
          v: 212
        },
        {
          t: 59700,
          o: 1.10604,
          h: 1.10613,
          l: 1.10588,
          c: 1.10589,
          v: 229
        },
        {
          t: 60000,
          o: 1.1059,
          h: 1.10609,
          l: 1.10577,
          c: 1.10604,
          v: 389
        },
        {
          t: 60300,
          o: 1.10609,
          h: 1.10639,
          l: 1.10603,
          c: 1.1062,
          v: 243
        },
        {
          t: 60600,
          o: 1.10617,
          h: 1.10653,
          l: 1.10617,
          c: 1.10638,
          v: 190
        },
        {
          t: 60900,
          o: 1.10637,
          h: 1.10649,
          l: 1.10606,
          c: 1.10616,
          v: 203
        },
        {
          t: 61200,
          o: 1.10617,
          h: 1.10624,
          l: 1.10606,
          c: 1.10608,
          v: 151
        },
        {
          t: 61500,
          o: 1.10611,
          h: 1.1064,
          l: 1.10611,
          c: 1.10623,
          v: 121
        },
        {
          t: 61800,
          o: 1.10624,
          h: 1.10659,
          l: 1.10624,
          c: 1.10657,
          v: 235
        },
        {
          t: 62100,
          o: 1.10655,
          h: 1.10659,
          l: 1.10632,
          c: 1.10632,
          v: 184
        },
        {
          t: 62400,
          o: 1.10633,
          h: 1.10645,
          l: 1.10621,
          c: 1.10623,
          v: 142
        },
        {
          t: 62700,
          o: 1.10624,
          h: 1.10632,
          l: 1.1061,
          c: 1.10617,
          v: 167
        },
        {
          t: 63000,
          o: 1.10618,
          h: 1.1062,
          l: 1.10604,
          c: 1.10608,
          v: 189
        },
        {
          t: 63300,
          o: 1.10607,
          h: 1.10614,
          l: 1.10602,
          c: 1.10609,
          v: 105
        },
        {
          t: 63600,
          o: 1.10609,
          h: 1.1061,
          l: 1.10589,
          c: 1.10591,
          v: 158
        },
        {
          t: 63900,
          o: 1.10592,
          h: 1.10622,
          l: 1.1059,
          c: 1.10615,
          v: 129
        },
        {
          t: 64200,
          o: 1.10613,
          h: 1.10613,
          l: 1.10594,
          c: 1.10602,
          v: 60
        },
        {
          t: 64500,
          o: 1.10602,
          h: 1.10602,
          l: 1.10572,
          c: 1.10582,
          v: 159
        },
        {
          t: 64800,
          o: 1.10583,
          h: 1.10618,
          l: 1.10577,
          c: 1.10618,
          v: 127
        },
        {
          t: 65100,
          o: 1.10617,
          h: 1.10626,
          l: 1.1061,
          c: 1.10614,
          v: 118
        },
        {
          t: 65400,
          o: 1.10615,
          h: 1.10632,
          l: 1.10614,
          c: 1.10623,
          v: 158
        },
        {
          t: 65700,
          o: 1.10624,
          h: 1.10634,
          l: 1.10614,
          c: 1.10623,
          v: 169
        },
        {
          t: 66000,
          o: 1.10624,
          h: 1.10628,
          l: 1.10616,
          c: 1.10618,
          v: 145
        },
        {
          t: 66300,
          o: 1.10615,
          h: 1.10624,
          l: 1.10612,
          c: 1.10617,
          v: 117
        },
        {
          t: 66600,
          o: 1.10618,
          h: 1.10618,
          l: 1.10602,
          c: 1.10607,
          v: 107
        },
        {
          t: 66900,
          o: 1.10608,
          h: 1.10613,
          l: 1.10592,
          c: 1.10592,
          v: 106
        },
        {
          t: 67200,
          o: 1.10593,
          h: 1.10613,
          l: 1.10592,
          c: 1.10603,
          v: 106
        },
        {
          t: 67500,
          o: 1.10603,
          h: 1.10607,
          l: 1.10586,
          c: 1.10588,
          v: 97
        },
        {
          t: 67800,
          o: 1.10588,
          h: 1.1061,
          l: 1.10588,
          c: 1.10603,
          v: 138
        },
        {
          t: 68100,
          o: 1.10603,
          h: 1.10616,
          l: 1.10602,
          c: 1.10608,
          v: 170
        },
        {
          t: 68400,
          o: 1.10609,
          h: 1.10623,
          l: 1.10608,
          c: 1.10611,
          v: 138
        },
        {
          t: 68700,
          o: 1.1061,
          h: 1.10625,
          l: 1.1061,
          c: 1.10618,
          v: 115
        },
        {
          t: 69000,
          o: 1.10618,
          h: 1.10624,
          l: 1.10613,
          c: 1.10618,
          v: 123
        },
        {
          t: 69300,
          o: 1.10616,
          h: 1.10624,
          l: 1.10613,
          c: 1.10624,
          v: 97
        },
        {
          t: 69600,
          o: 1.10625,
          h: 1.10625,
          l: 1.10608,
          c: 1.10613,
          v: 92
        },
        {
          t: 69900,
          o: 1.10608,
          h: 1.10629,
          l: 1.10608,
          c: 1.10629,
          v: 59
        },
        {
          t: 70200,
          o: 1.10629,
          h: 1.10629,
          l: 1.10607,
          c: 1.1062,
          v: 75
        },
        {
          t: 70500,
          o: 1.10621,
          h: 1.10636,
          l: 1.10618,
          c: 1.10634,
          v: 74
        },
        {
          t: 70800,
          o: 1.10637,
          h: 1.10643,
          l: 1.10629,
          c: 1.10633,
          v: 72
        },
        {
          t: 71100,
          o: 1.1063,
          h: 1.1063,
          l: 1.10619,
          c: 1.10628,
          v: 76
        },
        {
          t: 71400,
          o: 1.10628,
          h: 1.10633,
          l: 1.10615,
          c: 1.10617,
          v: 124
        },
        {
          t: 71700,
          o: 1.10613,
          h: 1.10649,
          l: 1.1061,
          c: 1.10631,
          v: 231
        },
        {
          t: 72000,
          o: 1.10628,
          h: 1.10636,
          l: 1.10617,
          c: 1.10623,
          v: 117
        },
        {
          t: 72300,
          o: 1.10625,
          h: 1.10627,
          l: 1.10608,
          c: 1.10612,
          v: 144
        },
        {
          t: 72600,
          o: 1.10613,
          h: 1.10613,
          l: 1.10605,
          c: 1.10609,
          v: 124
        },
        {
          t: 72900,
          o: 1.1061,
          h: 1.10612,
          l: 1.10607,
          c: 1.10607,
          v: 89
        },
        {
          t: 73200,
          o: 1.10608,
          h: 1.10612,
          l: 1.1059,
          c: 1.1059,
          v: 148
        },
        {
          t: 73500,
          o: 1.10591,
          h: 1.10597,
          l: 1.1059,
          c: 1.10593,
          v: 140
        },
        {
          t: 73800,
          o: 1.1059,
          h: 1.10594,
          l: 1.10585,
          c: 1.10586,
          v: 125
        },
        {
          t: 74100,
          o: 1.10586,
          h: 1.10587,
          l: 1.10576,
          c: 1.10578,
          v: 132
        },
        {
          t: 74400,
          o: 1.1058,
          h: 1.10588,
          l: 1.1058,
          c: 1.10586,
          v: 128
        },
        {
          t: 74700,
          o: 1.10587,
          h: 1.10588,
          l: 1.1057,
          c: 1.10576,
          v: 123
        },
        {
          t: 75000,
          o: 1.10577,
          h: 1.10582,
          l: 1.10567,
          c: 1.10582,
          v: 107
        },
        {
          t: 75300,
          o: 1.10583,
          h: 1.10587,
          l: 1.10566,
          c: 1.10577,
          v: 170
        },
        {
          t: 75600,
          o: 1.10574,
          h: 1.10584,
          l: 1.10573,
          c: 1.10583,
          v: 90
        },
        {
          t: 75900,
          o: 1.10582,
          h: 1.10594,
          l: 1.10582,
          c: 1.10593,
          v: 76
        },
        {
          t: 76200,
          o: 1.10592,
          h: 1.10597,
          l: 1.1059,
          c: 1.10595,
          v: 53
        },
        {
          t: 76500,
          o: 1.10595,
          h: 1.10597,
          l: 1.10591,
          c: 1.10593,
          v: 37
        },
        {
          t: 76800,
          o: 1.10593,
          h: 1.10594,
          l: 1.1059,
          c: 1.10592,
          v: 20
        },
        {
          t: 77100,
          o: 1.10593,
          h: 1.10597,
          l: 1.1059,
          c: 1.10591,
          v: 69
        },
        {
          t: 77400,
          o: 1.10591,
          h: 1.10598,
          l: 1.1059,
          c: 1.10597,
          v: 43
        },
        {
          t: 77700,
          o: 1.10599,
          h: 1.10599,
          l: 1.10592,
          c: 1.10598,
          v: 38
        },
        {
          t: 78000,
          o: 1.10599,
          h: 1.10609,
          l: 1.10595,
          c: 1.10608,
          v: 59
        },
        {
          t: 78300,
          o: 1.1061,
          h: 1.1061,
          l: 1.10595,
          c: 1.10597,
          v: 73
        },
        {
          t: 78600,
          o: 1.10596,
          h: 1.10606,
          l: 1.10596,
          c: 1.10604,
          v: 48
        },
        {
          t: 78900,
          o: 1.10606,
          h: 1.10609,
          l: 1.10587,
          c: 1.10589,
          v: 78
        },
        {
          t: 79200,
          o: 1.1059,
          h: 1.10592,
          l: 1.10559,
          c: 1.10592,
          v: 47
        },
        {
          t: 79500,
          o: 1.10563,
          h: 1.10592,
          l: 1.10563,
          c: 1.10573,
          v: 47
        },
        {
          t: 79800,
          o: 1.10564,
          h: 1.10593,
          l: 1.10564,
          c: 1.10593,
          v: 83
        },
        {
          t: 80100,
          o: 1.10561,
          h: 1.10593,
          l: 1.10561,
          c: 1.1058,
          v: 78
        },
        {
          t: 80400,
          o: 1.1058,
          h: 1.10591,
          l: 1.1058,
          c: 1.1058,
          v: 12
        },
        {
          t: 80700,
          o: 1.1058,
          h: 1.10582,
          l: 1.1058,
          c: 1.1058,
          v: 16
        },
        {
          t: 81000,
          o: 1.10582,
          h: 1.10595,
          l: 1.10576,
          c: 1.10591,
          v: 89
        },
        {
          t: 81300,
          o: 1.1059,
          h: 1.10596,
          l: 1.10577,
          c: 1.10589,
          v: 94
        },
        {
          t: 81600,
          o: 1.10588,
          h: 1.10595,
          l: 1.10584,
          c: 1.1059,
          v: 65
        },
        {
          t: 81900,
          o: 1.1059,
          h: 1.10602,
          l: 1.1059,
          c: 1.10593,
          v: 68
        },
        {
          t: 82200,
          o: 1.10594,
          h: 1.10598,
          l: 1.10592,
          c: 1.10592,
          v: 32
        },
        {
          t: 82500,
          o: 1.10593,
          h: 1.10605,
          l: 1.10585,
          c: 1.10586,
          v: 93
        },
        {
          t: 82800,
          o: 1.10588,
          h: 1.10615,
          l: 1.10585,
          c: 1.10614,
          v: 80
        },
        {
          t: 83100,
          o: 1.10614,
          h: 1.10615,
          l: 1.10613,
          c: 1.10613,
          v: 27
        },
        {
          t: 83400,
          o: 1.10613,
          h: 1.10614,
          l: 1.10612,
          c: 1.10613,
          v: 37
        },
        {
          t: 83700,
          o: 1.10612,
          h: 1.10615,
          l: 1.10611,
          c: 1.10614,
          v: 25
        },
        {
          t: 84000,
          o: 1.10614,
          h: 1.10614,
          l: 1.10609,
          c: 1.10614,
          v: 24
        },
        {
          t: 84300,
          o: 1.10614,
          h: 1.10616,
          l: 1.10614,
          c: 1.10614,
          v: 31
        },
        {
          t: 84600,
          o: 1.10614,
          h: 1.10619,
          l: 1.10609,
          c: 1.10609,
          v: 35
        },
        {
          t: 84900,
          o: 1.10611,
          h: 1.10615,
          l: 1.10608,
          c: 1.10614,
          v: 46
        },
        {
          t: 85200,
          o: 1.10614,
          h: 1.10625,
          l: 1.10613,
          c: 1.10624,
          v: 39
        },
        {
          t: 85500,
          o: 1.10624,
          h: 1.10626,
          l: 1.10617,
          c: 1.1062,
          v: 47
        },
        {
          t: 85800,
          o: 1.10621,
          h: 1.10621,
          l: 1.10617,
          c: 1.10619,
          v: 41
        },
        {
          t: 86100,
          o: 1.10618,
          h: 1.10621,
          l: 1.10613,
          c: 1.10618,
          v: 62
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.10619,
          h: 1.10625,
          l: 1.10615,
          c: 1.10619,
          v: 69
        },
        {
          t: 300,
          o: 1.10619,
          h: 1.10621,
          l: 1.10614,
          c: 1.10614,
          v: 90
        },
        {
          t: 600,
          o: 1.10614,
          h: 1.10618,
          l: 1.10608,
          c: 1.10616,
          v: 57
        },
        {
          t: 900,
          o: 1.10615,
          h: 1.10615,
          l: 1.10608,
          c: 1.1061,
          v: 40
        },
        {
          t: 1200,
          o: 1.10611,
          h: 1.10614,
          l: 1.10595,
          c: 1.10599,
          v: 68
        },
        {
          t: 1500,
          o: 1.10599,
          h: 1.10604,
          l: 1.10598,
          c: 1.10599,
          v: 55
        },
        {
          t: 1800,
          o: 1.10599,
          h: 1.10607,
          l: 1.10598,
          c: 1.10607,
          v: 67
        },
        {
          t: 2100,
          o: 1.10606,
          h: 1.10609,
          l: 1.10603,
          c: 1.10608,
          v: 65
        },
        {
          t: 2400,
          o: 1.10609,
          h: 1.10609,
          l: 1.10602,
          c: 1.10603,
          v: 49
        },
        {
          t: 2700,
          o: 1.10604,
          h: 1.10604,
          l: 1.10596,
          c: 1.10601,
          v: 41
        },
        {
          t: 3000,
          o: 1.10601,
          h: 1.10602,
          l: 1.10593,
          c: 1.10593,
          v: 56
        },
        {
          t: 3300,
          o: 1.10587,
          h: 1.10604,
          l: 1.10587,
          c: 1.10603,
          v: 83
        },
        {
          t: 3600,
          o: 1.10607,
          h: 1.10616,
          l: 1.10588,
          c: 1.10613,
          v: 166
        },
        {
          t: 3900,
          o: 1.1061,
          h: 1.10615,
          l: 1.10603,
          c: 1.10603,
          v: 91
        },
        {
          t: 4200,
          o: 1.10604,
          h: 1.10605,
          l: 1.10602,
          c: 1.10602,
          v: 78
        },
        {
          t: 4500,
          o: 1.10602,
          h: 1.10605,
          l: 1.10598,
          c: 1.10604,
          v: 91
        },
        {
          t: 4800,
          o: 1.10603,
          h: 1.10604,
          l: 1.10592,
          c: 1.10592,
          v: 34
        },
        {
          t: 5100,
          o: 1.10593,
          h: 1.10598,
          l: 1.10592,
          c: 1.10598,
          v: 46
        },
        {
          t: 5400,
          o: 1.10602,
          h: 1.10607,
          l: 1.10597,
          c: 1.10598,
          v: 121
        },
        {
          t: 5700,
          o: 1.10599,
          h: 1.10614,
          l: 1.10597,
          c: 1.10609,
          v: 125
        },
        {
          t: 6000,
          o: 1.10609,
          h: 1.10611,
          l: 1.10602,
          c: 1.10611,
          v: 75
        },
        {
          t: 6300,
          o: 1.1061,
          h: 1.10636,
          l: 1.1061,
          c: 1.10618,
          v: 133
        },
        {
          t: 6600,
          o: 1.1062,
          h: 1.10628,
          l: 1.10618,
          c: 1.10618,
          v: 103
        },
        {
          t: 6900,
          o: 1.10618,
          h: 1.10618,
          l: 1.10595,
          c: 1.10598,
          v: 119
        },
        {
          t: 7200,
          o: 1.10598,
          h: 1.10599,
          l: 1.10572,
          c: 1.10572,
          v: 109
        },
        {
          t: 7500,
          o: 1.10571,
          h: 1.1058,
          l: 1.10569,
          c: 1.10569,
          v: 103
        },
        {
          t: 7800,
          o: 1.1057,
          h: 1.1057,
          l: 1.1056,
          c: 1.10568,
          v: 81
        },
        {
          t: 8100,
          o: 1.10566,
          h: 1.1057,
          l: 1.10563,
          c: 1.10569,
          v: 81
        },
        {
          t: 8400,
          o: 1.10569,
          h: 1.10569,
          l: 1.10562,
          c: 1.10564,
          v: 103
        },
        {
          t: 8700,
          o: 1.10563,
          h: 1.10569,
          l: 1.10559,
          c: 1.10564,
          v: 85
        },
        {
          t: 9000,
          o: 1.10564,
          h: 1.1057,
          l: 1.10557,
          c: 1.10559,
          v: 116
        },
        {
          t: 9300,
          o: 1.10559,
          h: 1.10568,
          l: 1.10556,
          c: 1.10556,
          v: 102
        },
        {
          t: 9600,
          o: 1.10556,
          h: 1.10558,
          l: 1.10552,
          c: 1.10555,
          v: 74
        },
        {
          t: 9900,
          o: 1.10554,
          h: 1.10566,
          l: 1.10554,
          c: 1.10564,
          v: 52
        },
        {
          t: 10200,
          o: 1.10563,
          h: 1.10576,
          l: 1.1056,
          c: 1.10574,
          v: 47
        },
        {
          t: 10500,
          o: 1.10574,
          h: 1.1058,
          l: 1.10568,
          c: 1.10579,
          v: 64
        },
        {
          t: 10800,
          o: 1.1058,
          h: 1.10587,
          l: 1.10575,
          c: 1.10586,
          v: 74
        },
        {
          t: 11100,
          o: 1.10586,
          h: 1.10594,
          l: 1.10578,
          c: 1.1058,
          v: 74
        },
        {
          t: 11400,
          o: 1.10579,
          h: 1.1058,
          l: 1.10569,
          c: 1.1057,
          v: 63
        },
        {
          t: 11700,
          o: 1.1057,
          h: 1.10575,
          l: 1.10568,
          c: 1.10569,
          v: 58
        },
        {
          t: 12000,
          o: 1.10568,
          h: 1.10568,
          l: 1.10559,
          c: 1.10568,
          v: 75
        },
        {
          t: 12300,
          o: 1.10568,
          h: 1.10571,
          l: 1.1055,
          c: 1.10556,
          v: 88
        },
        {
          t: 12600,
          o: 1.10553,
          h: 1.10585,
          l: 1.10551,
          c: 1.10584,
          v: 228
        },
        {
          t: 12900,
          o: 1.10582,
          h: 1.10585,
          l: 1.10563,
          c: 1.10563,
          v: 156
        },
        {
          t: 13200,
          o: 1.10561,
          h: 1.10571,
          l: 1.10559,
          c: 1.1057,
          v: 122
        },
        {
          t: 13500,
          o: 1.10569,
          h: 1.10575,
          l: 1.10568,
          c: 1.10569,
          v: 70
        },
        {
          t: 13800,
          o: 1.10568,
          h: 1.10581,
          l: 1.10565,
          c: 1.10579,
          v: 101
        },
        {
          t: 14100,
          o: 1.10579,
          h: 1.1058,
          l: 1.10573,
          c: 1.10575,
          v: 48
        },
        {
          t: 14400,
          o: 1.10573,
          h: 1.1058,
          l: 1.10569,
          c: 1.10579,
          v: 67
        },
        {
          t: 14700,
          o: 1.10579,
          h: 1.10587,
          l: 1.10579,
          c: 1.10584,
          v: 91
        },
        {
          t: 15000,
          o: 1.10585,
          h: 1.1059,
          l: 1.10583,
          c: 1.1059,
          v: 55
        },
        {
          t: 15300,
          o: 1.10592,
          h: 1.10593,
          l: 1.10583,
          c: 1.10584,
          v: 52
        },
        {
          t: 15600,
          o: 1.10585,
          h: 1.10591,
          l: 1.10582,
          c: 1.10589,
          v: 39
        },
        {
          t: 15900,
          o: 1.10589,
          h: 1.10598,
          l: 1.10588,
          c: 1.10593,
          v: 100
        },
        {
          t: 16200,
          o: 1.10594,
          h: 1.10596,
          l: 1.1059,
          c: 1.10595,
          v: 85
        },
        {
          t: 16500,
          o: 1.10593,
          h: 1.10601,
          l: 1.10592,
          c: 1.106,
          v: 56
        },
        {
          t: 16800,
          o: 1.10601,
          h: 1.10605,
          l: 1.106,
          c: 1.10604,
          v: 67
        },
        {
          t: 17100,
          o: 1.10602,
          h: 1.10605,
          l: 1.10599,
          c: 1.10604,
          v: 51
        },
        {
          t: 17400,
          o: 1.10603,
          h: 1.10605,
          l: 1.10598,
          c: 1.10599,
          v: 38
        },
        {
          t: 17700,
          o: 1.10598,
          h: 1.10601,
          l: 1.10593,
          c: 1.10596,
          v: 83
        },
        {
          t: 18000,
          o: 1.10593,
          h: 1.10598,
          l: 1.10593,
          c: 1.10595,
          v: 83
        },
        {
          t: 18300,
          o: 1.10596,
          h: 1.10596,
          l: 1.10589,
          c: 1.1059,
          v: 39
        },
        {
          t: 18600,
          o: 1.10591,
          h: 1.10599,
          l: 1.1059,
          c: 1.10593,
          v: 61
        },
        {
          t: 18900,
          o: 1.10593,
          h: 1.10603,
          l: 1.10593,
          c: 1.10603,
          v: 48
        },
        {
          t: 19200,
          o: 1.10605,
          h: 1.10605,
          l: 1.10598,
          c: 1.10599,
          v: 69
        },
        {
          t: 19500,
          o: 1.106,
          h: 1.106,
          l: 1.10598,
          c: 1.10599,
          v: 61
        },
        {
          t: 19800,
          o: 1.10599,
          h: 1.10629,
          l: 1.10599,
          c: 1.10619,
          v: 65
        },
        {
          t: 20100,
          o: 1.10619,
          h: 1.10619,
          l: 1.10594,
          c: 1.10594,
          v: 78
        },
        {
          t: 20400,
          o: 1.10593,
          h: 1.10601,
          l: 1.10592,
          c: 1.10594,
          v: 65
        },
        {
          t: 20700,
          o: 1.10593,
          h: 1.10593,
          l: 1.10579,
          c: 1.10579,
          v: 79
        },
        {
          t: 21000,
          o: 1.10581,
          h: 1.10584,
          l: 1.10579,
          c: 1.10583,
          v: 40
        },
        {
          t: 21300,
          o: 1.10586,
          h: 1.10589,
          l: 1.10573,
          c: 1.1058,
          v: 100
        },
        {
          t: 21600,
          o: 1.1058,
          h: 1.10609,
          l: 1.10578,
          c: 1.10605,
          v: 133
        },
        {
          t: 21900,
          o: 1.10605,
          h: 1.1061,
          l: 1.10598,
          c: 1.10606,
          v: 66
        },
        {
          t: 22200,
          o: 1.10605,
          h: 1.10605,
          l: 1.10593,
          c: 1.106,
          v: 53
        },
        {
          t: 22500,
          o: 1.106,
          h: 1.106,
          l: 1.10595,
          c: 1.10599,
          v: 78
        },
        {
          t: 22800,
          o: 1.10599,
          h: 1.10621,
          l: 1.10593,
          c: 1.10621,
          v: 116
        },
        {
          t: 23100,
          o: 1.10619,
          h: 1.10621,
          l: 1.10605,
          c: 1.10606,
          v: 122
        },
        {
          t: 23400,
          o: 1.10605,
          h: 1.10611,
          l: 1.10605,
          c: 1.1061,
          v: 51
        },
        {
          t: 23700,
          o: 1.1061,
          h: 1.10632,
          l: 1.10609,
          c: 1.1063,
          v: 130
        },
        {
          t: 24000,
          o: 1.10631,
          h: 1.10632,
          l: 1.10609,
          c: 1.10613,
          v: 130
        },
        {
          t: 24300,
          o: 1.10614,
          h: 1.10616,
          l: 1.10587,
          c: 1.10589,
          v: 116
        },
        {
          t: 24600,
          o: 1.1059,
          h: 1.10597,
          l: 1.10585,
          c: 1.10596,
          v: 94
        },
        {
          t: 24900,
          o: 1.10598,
          h: 1.10599,
          l: 1.10579,
          c: 1.10585,
          v: 118
        },
        {
          t: 25200,
          o: 1.10584,
          h: 1.10585,
          l: 1.10553,
          c: 1.10561,
          v: 201
        },
        {
          t: 25500,
          o: 1.10562,
          h: 1.10563,
          l: 1.10545,
          c: 1.10546,
          v: 119
        },
        {
          t: 25800,
          o: 1.10545,
          h: 1.10546,
          l: 1.10521,
          c: 1.10529,
          v: 189
        },
        {
          t: 26100,
          o: 1.10527,
          h: 1.1054,
          l: 1.10519,
          c: 1.10534,
          v: 155
        },
        {
          t: 26400,
          o: 1.10534,
          h: 1.10553,
          l: 1.10533,
          c: 1.10539,
          v: 130
        },
        {
          t: 26700,
          o: 1.1054,
          h: 1.10541,
          l: 1.10506,
          c: 1.10509,
          v: 238
        },
        {
          t: 27000,
          o: 1.10509,
          h: 1.10518,
          l: 1.10499,
          c: 1.10517,
          v: 193
        },
        {
          t: 27300,
          o: 1.10513,
          h: 1.10521,
          l: 1.10487,
          c: 1.10498,
          v: 171
        },
        {
          t: 27600,
          o: 1.10499,
          h: 1.10507,
          l: 1.10486,
          c: 1.10497,
          v: 205
        },
        {
          t: 27900,
          o: 1.10498,
          h: 1.10512,
          l: 1.10486,
          c: 1.10498,
          v: 167
        },
        {
          t: 28200,
          o: 1.10499,
          h: 1.10516,
          l: 1.10488,
          c: 1.10516,
          v: 156
        },
        {
          t: 28500,
          o: 1.10516,
          h: 1.10521,
          l: 1.10484,
          c: 1.10496,
          v: 233
        },
        {
          t: 28800,
          o: 1.10492,
          h: 1.10518,
          l: 1.1049,
          c: 1.10506,
          v: 280
        },
        {
          t: 29100,
          o: 1.10505,
          h: 1.10564,
          l: 1.10498,
          c: 1.10561,
          v: 301
        },
        {
          t: 29400,
          o: 1.1056,
          h: 1.10584,
          l: 1.10544,
          c: 1.10557,
          v: 358
        },
        {
          t: 29700,
          o: 1.10556,
          h: 1.10581,
          l: 1.10554,
          c: 1.10575,
          v: 325
        },
        {
          t: 30000,
          o: 1.10576,
          h: 1.10577,
          l: 1.10546,
          c: 1.10546,
          v: 277
        },
        {
          t: 30300,
          o: 1.10549,
          h: 1.10556,
          l: 1.10514,
          c: 1.10546,
          v: 303
        },
        {
          t: 30600,
          o: 1.10547,
          h: 1.10565,
          l: 1.10539,
          c: 1.10565,
          v: 218
        },
        {
          t: 30900,
          o: 1.10564,
          h: 1.10575,
          l: 1.10542,
          c: 1.10564,
          v: 205
        },
        {
          t: 31200,
          o: 1.10566,
          h: 1.10571,
          l: 1.10554,
          c: 1.10556,
          v: 198
        },
        {
          t: 31500,
          o: 1.10552,
          h: 1.10552,
          l: 1.10534,
          c: 1.10545,
          v: 160
        },
        {
          t: 31800,
          o: 1.10544,
          h: 1.10569,
          l: 1.1052,
          c: 1.10566,
          v: 245
        },
        {
          t: 32100,
          o: 1.10565,
          h: 1.10587,
          l: 1.10564,
          c: 1.10575,
          v: 285
        },
        {
          t: 32400,
          o: 1.10574,
          h: 1.10594,
          l: 1.10538,
          c: 1.10594,
          v: 282
        },
        {
          t: 32700,
          o: 1.10594,
          h: 1.10604,
          l: 1.10579,
          c: 1.10594,
          v: 303
        },
        {
          t: 33000,
          o: 1.10597,
          h: 1.10604,
          l: 1.10578,
          c: 1.10583,
          v: 212
        },
        {
          t: 33300,
          o: 1.10584,
          h: 1.10587,
          l: 1.1057,
          c: 1.10575,
          v: 238
        },
        {
          t: 33600,
          o: 1.10576,
          h: 1.10582,
          l: 1.10539,
          c: 1.10549,
          v: 223
        },
        {
          t: 33900,
          o: 1.10548,
          h: 1.10568,
          l: 1.10544,
          c: 1.10564,
          v: 192
        },
        {
          t: 34200,
          o: 1.10563,
          h: 1.10574,
          l: 1.10546,
          c: 1.10556,
          v: 210
        },
        {
          t: 34500,
          o: 1.10559,
          h: 1.10591,
          l: 1.10557,
          c: 1.10589,
          v: 233
        },
        {
          t: 34800,
          o: 1.10591,
          h: 1.10596,
          l: 1.1056,
          c: 1.10566,
          v: 257
        },
        {
          t: 35100,
          o: 1.10565,
          h: 1.10573,
          l: 1.10556,
          c: 1.10566,
          v: 213
        },
        {
          t: 35400,
          o: 1.10565,
          h: 1.10567,
          l: 1.10548,
          c: 1.10549,
          v: 210
        },
        {
          t: 35700,
          o: 1.10552,
          h: 1.10566,
          l: 1.10523,
          c: 1.10553,
          v: 251
        },
        {
          t: 36000,
          o: 1.10551,
          h: 1.10558,
          l: 1.10535,
          c: 1.1054,
          v: 234
        },
        {
          t: 36300,
          o: 1.1054,
          h: 1.10555,
          l: 1.10533,
          c: 1.10549,
          v: 181
        },
        {
          t: 36600,
          o: 1.1055,
          h: 1.10566,
          l: 1.1054,
          c: 1.10544,
          v: 155
        },
        {
          t: 36900,
          o: 1.10544,
          h: 1.10544,
          l: 1.10509,
          c: 1.10511,
          v: 211
        },
        {
          t: 37200,
          o: 1.10508,
          h: 1.10516,
          l: 1.10495,
          c: 1.10501,
          v: 163
        },
        {
          t: 37500,
          o: 1.10501,
          h: 1.10517,
          l: 1.10499,
          c: 1.10516,
          v: 157
        },
        {
          t: 37800,
          o: 1.10517,
          h: 1.1052,
          l: 1.10488,
          c: 1.10506,
          v: 177
        },
        {
          t: 38100,
          o: 1.10508,
          h: 1.10521,
          l: 1.10503,
          c: 1.10516,
          v: 152
        },
        {
          t: 38400,
          o: 1.10515,
          h: 1.10531,
          l: 1.10512,
          c: 1.10528,
          v: 184
        },
        {
          t: 38700,
          o: 1.10529,
          h: 1.10543,
          l: 1.1052,
          c: 1.10532,
          v: 133
        },
        {
          t: 39000,
          o: 1.10527,
          h: 1.10536,
          l: 1.1052,
          c: 1.10523,
          v: 137
        },
        {
          t: 39300,
          o: 1.10525,
          h: 1.10555,
          l: 1.10524,
          c: 1.10551,
          v: 137
        },
        {
          t: 39600,
          o: 1.10546,
          h: 1.10575,
          l: 1.10543,
          c: 1.10569,
          v: 130
        },
        {
          t: 39900,
          o: 1.10568,
          h: 1.10571,
          l: 1.10549,
          c: 1.10549,
          v: 126
        },
        {
          t: 40200,
          o: 1.1055,
          h: 1.10555,
          l: 1.10539,
          c: 1.10542,
          v: 99
        },
        {
          t: 40500,
          o: 1.10541,
          h: 1.10553,
          l: 1.10534,
          c: 1.1055,
          v: 162
        },
        {
          t: 40800,
          o: 1.10551,
          h: 1.1056,
          l: 1.10539,
          c: 1.10545,
          v: 91
        },
        {
          t: 41100,
          o: 1.10543,
          h: 1.10544,
          l: 1.1053,
          c: 1.1054,
          v: 86
        },
        {
          t: 41400,
          o: 1.1054,
          h: 1.10541,
          l: 1.10525,
          c: 1.10536,
          v: 130
        },
        {
          t: 41700,
          o: 1.10535,
          h: 1.10545,
          l: 1.10524,
          c: 1.10544,
          v: 116
        },
        {
          t: 42000,
          o: 1.10544,
          h: 1.10552,
          l: 1.10525,
          c: 1.10543,
          v: 175
        },
        {
          t: 42300,
          o: 1.10543,
          h: 1.10547,
          l: 1.10531,
          c: 1.10534,
          v: 178
        },
        {
          t: 42600,
          o: 1.10531,
          h: 1.10536,
          l: 1.10526,
          c: 1.10527,
          v: 81
        },
        {
          t: 42900,
          o: 1.10526,
          h: 1.10536,
          l: 1.10516,
          c: 1.10535,
          v: 132
        },
        {
          t: 43200,
          o: 1.10536,
          h: 1.10536,
          l: 1.10509,
          c: 1.1051,
          v: 95
        },
        {
          t: 43500,
          o: 1.10514,
          h: 1.10522,
          l: 1.10466,
          c: 1.10481,
          v: 231
        },
        {
          t: 43800,
          o: 1.10483,
          h: 1.10498,
          l: 1.10474,
          c: 1.10484,
          v: 180
        },
        {
          t: 44100,
          o: 1.10484,
          h: 1.10496,
          l: 1.10484,
          c: 1.10495,
          v: 143
        },
        {
          t: 44400,
          o: 1.10495,
          h: 1.10511,
          l: 1.10494,
          c: 1.10508,
          v: 106
        },
        {
          t: 44700,
          o: 1.10507,
          h: 1.10518,
          l: 1.10496,
          c: 1.10499,
          v: 158
        },
        {
          t: 45000,
          o: 1.105,
          h: 1.10505,
          l: 1.10492,
          c: 1.10495,
          v: 162
        },
        {
          t: 45300,
          o: 1.10495,
          h: 1.10496,
          l: 1.10464,
          c: 1.1047,
          v: 88
        },
        {
          t: 46500,
          o: 1.10436,
          h: 1.10437,
          l: 1.10436,
          c: 1.10437,
          v: 6
        },
        {
          t: 46800,
          o: 1.10451,
          h: 1.10457,
          l: 1.10445,
          c: 1.10446,
          v: 71
        },
        {
          t: 47100,
          o: 1.10445,
          h: 1.10469,
          l: 1.10445,
          c: 1.10462,
          v: 135
        },
        {
          t: 47400,
          o: 1.10461,
          h: 1.10475,
          l: 1.10455,
          c: 1.10471,
          v: 188
        },
        {
          t: 47700,
          o: 1.10473,
          h: 1.10497,
          l: 1.1047,
          c: 1.10497,
          v: 148
        },
        {
          t: 48000,
          o: 1.10496,
          h: 1.10502,
          l: 1.1047,
          c: 1.10475,
          v: 163
        },
        {
          t: 48300,
          o: 1.10476,
          h: 1.10485,
          l: 1.10455,
          c: 1.10484,
          v: 244
        },
        {
          t: 48600,
          o: 1.10485,
          h: 1.10503,
          l: 1.10473,
          c: 1.10492,
          v: 270
        },
        {
          t: 48900,
          o: 1.10489,
          h: 1.10502,
          l: 1.1047,
          c: 1.10471,
          v: 200
        },
        {
          t: 49200,
          o: 1.1047,
          h: 1.10472,
          l: 1.10452,
          c: 1.10463,
          v: 210
        },
        {
          t: 49500,
          o: 1.10462,
          h: 1.10484,
          l: 1.10462,
          c: 1.10476,
          v: 185
        },
        {
          t: 49800,
          o: 1.10477,
          h: 1.10491,
          l: 1.10468,
          c: 1.10481,
          v: 126
        },
        {
          t: 50100,
          o: 1.10479,
          h: 1.10479,
          l: 1.10434,
          c: 1.1044,
          v: 248
        },
        {
          t: 50400,
          o: 1.10439,
          h: 1.10458,
          l: 1.1043,
          c: 1.10441,
          v: 223
        },
        {
          t: 50700,
          o: 1.10436,
          h: 1.10462,
          l: 1.10435,
          c: 1.1045,
          v: 210
        },
        {
          t: 51000,
          o: 1.10451,
          h: 1.10459,
          l: 1.10429,
          c: 1.10457,
          v: 205
        },
        {
          t: 51300,
          o: 1.10456,
          h: 1.10461,
          l: 1.10445,
          c: 1.1045,
          v: 181
        },
        {
          t: 51600,
          o: 1.10449,
          h: 1.1046,
          l: 1.10436,
          c: 1.10452,
          v: 148
        },
        {
          t: 51900,
          o: 1.10451,
          h: 1.10463,
          l: 1.10448,
          c: 1.10457,
          v: 193
        },
        {
          t: 52200,
          o: 1.10458,
          h: 1.10461,
          l: 1.10437,
          c: 1.10442,
          v: 335
        },
        {
          t: 52500,
          o: 1.10441,
          h: 1.10443,
          l: 1.10416,
          c: 1.10438,
          v: 316
        },
        {
          t: 52800,
          o: 1.10437,
          h: 1.10441,
          l: 1.10424,
          c: 1.10434,
          v: 260
        },
        {
          t: 53100,
          o: 1.10433,
          h: 1.10439,
          l: 1.10413,
          c: 1.10421,
          v: 261
        },
        {
          t: 53400,
          o: 1.10419,
          h: 1.10435,
          l: 1.10403,
          c: 1.10432,
          v: 256
        },
        {
          t: 53700,
          o: 1.10436,
          h: 1.10449,
          l: 1.10433,
          c: 1.1044,
          v: 221
        },
        {
          t: 54000,
          o: 1.10442,
          h: 1.10478,
          l: 1.10429,
          c: 1.10468,
          v: 268
        },
        {
          t: 54300,
          o: 1.10469,
          h: 1.10474,
          l: 1.10435,
          c: 1.10441,
          v: 174
        },
        {
          t: 54600,
          o: 1.10442,
          h: 1.10462,
          l: 1.10427,
          c: 1.10437,
          v: 251
        },
        {
          t: 54900,
          o: 1.10437,
          h: 1.10454,
          l: 1.10436,
          c: 1.10454,
          v: 188
        },
        {
          t: 55200,
          o: 1.10456,
          h: 1.10461,
          l: 1.10434,
          c: 1.10442,
          v: 191
        },
        {
          t: 55500,
          o: 1.1044,
          h: 1.1044,
          l: 1.10361,
          c: 1.10368,
          v: 315
        },
        {
          t: 55800,
          o: 1.10369,
          h: 1.10393,
          l: 1.10362,
          c: 1.10373,
          v: 302
        },
        {
          t: 56100,
          o: 1.10372,
          h: 1.10384,
          l: 1.10355,
          c: 1.10356,
          v: 259
        },
        {
          t: 56400,
          o: 1.10355,
          h: 1.10359,
          l: 1.10326,
          c: 1.10358,
          v: 288
        },
        {
          t: 56700,
          o: 1.10357,
          h: 1.10363,
          l: 1.10338,
          c: 1.10349,
          v: 229
        },
        {
          t: 57000,
          o: 1.10348,
          h: 1.10403,
          l: 1.10343,
          c: 1.10403,
          v: 294
        },
        {
          t: 57300,
          o: 1.10406,
          h: 1.10415,
          l: 1.10375,
          c: 1.10379,
          v: 325
        },
        {
          t: 57600,
          o: 1.10379,
          h: 1.10379,
          l: 1.10355,
          c: 1.10375,
          v: 249
        },
        {
          t: 57900,
          o: 1.10374,
          h: 1.10384,
          l: 1.10351,
          c: 1.10353,
          v: 214
        },
        {
          t: 58200,
          o: 1.10353,
          h: 1.10368,
          l: 1.10347,
          c: 1.1036,
          v: 215
        },
        {
          t: 58500,
          o: 1.10359,
          h: 1.10365,
          l: 1.10347,
          c: 1.10353,
          v: 174
        },
        {
          t: 58800,
          o: 1.10354,
          h: 1.10354,
          l: 1.10323,
          c: 1.10323,
          v: 183
        },
        {
          t: 59100,
          o: 1.10323,
          h: 1.10358,
          l: 1.10323,
          c: 1.10349,
          v: 186
        },
        {
          t: 59400,
          o: 1.10348,
          h: 1.10356,
          l: 1.10331,
          c: 1.10353,
          v: 232
        },
        {
          t: 59700,
          o: 1.10354,
          h: 1.10359,
          l: 1.10333,
          c: 1.10355,
          v: 168
        },
        {
          t: 60000,
          o: 1.10354,
          h: 1.10359,
          l: 1.10345,
          c: 1.10346,
          v: 161
        },
        {
          t: 60300,
          o: 1.10345,
          h: 1.10398,
          l: 1.10345,
          c: 1.10396,
          v: 174
        },
        {
          t: 60600,
          o: 1.10397,
          h: 1.10406,
          l: 1.10389,
          c: 1.10406,
          v: 170
        },
        {
          t: 60900,
          o: 1.10405,
          h: 1.10427,
          l: 1.10404,
          c: 1.10425,
          v: 171
        },
        {
          t: 61200,
          o: 1.10425,
          h: 1.10448,
          l: 1.1042,
          c: 1.10439,
          v: 195
        },
        {
          t: 61500,
          o: 1.10438,
          h: 1.10442,
          l: 1.10418,
          c: 1.10428,
          v: 186
        },
        {
          t: 61800,
          o: 1.10428,
          h: 1.10429,
          l: 1.10402,
          c: 1.10413,
          v: 264
        },
        {
          t: 62100,
          o: 1.1041,
          h: 1.10418,
          l: 1.10397,
          c: 1.10408,
          v: 263
        },
        {
          t: 62400,
          o: 1.10406,
          h: 1.10407,
          l: 1.10385,
          c: 1.10389,
          v: 160
        },
        {
          t: 62700,
          o: 1.1039,
          h: 1.10436,
          l: 1.10388,
          c: 1.10426,
          v: 118
        },
        {
          t: 63000,
          o: 1.10427,
          h: 1.10434,
          l: 1.1042,
          c: 1.1042,
          v: 157
        },
        {
          t: 63300,
          o: 1.10419,
          h: 1.1042,
          l: 1.104,
          c: 1.104,
          v: 125
        },
        {
          t: 63600,
          o: 1.104,
          h: 1.10401,
          l: 1.10379,
          c: 1.10394,
          v: 81
        },
        {
          t: 63900,
          o: 1.10393,
          h: 1.104,
          l: 1.10384,
          c: 1.10385,
          v: 121
        },
        {
          t: 64200,
          o: 1.10385,
          h: 1.10404,
          l: 1.10383,
          c: 1.104,
          v: 127
        },
        {
          t: 64500,
          o: 1.104,
          h: 1.104,
          l: 1.10361,
          c: 1.10366,
          v: 186
        },
        {
          t: 64800,
          o: 1.10363,
          h: 1.10396,
          l: 1.1036,
          c: 1.10396,
          v: 165
        },
        {
          t: 65100,
          o: 1.10394,
          h: 1.10411,
          l: 1.1039,
          c: 1.10409,
          v: 149
        },
        {
          t: 65400,
          o: 1.10413,
          h: 1.10427,
          l: 1.10409,
          c: 1.10427,
          v: 101
        },
        {
          t: 65700,
          o: 1.10426,
          h: 1.10442,
          l: 1.1042,
          c: 1.10431,
          v: 119
        },
        {
          t: 66000,
          o: 1.10429,
          h: 1.10431,
          l: 1.10418,
          c: 1.1042,
          v: 111
        },
        {
          t: 66300,
          o: 1.1042,
          h: 1.10422,
          l: 1.10413,
          c: 1.10415,
          v: 90
        },
        {
          t: 66600,
          o: 1.10416,
          h: 1.10418,
          l: 1.10406,
          c: 1.1041,
          v: 61
        },
        {
          t: 66900,
          o: 1.1041,
          h: 1.10412,
          l: 1.104,
          c: 1.104,
          v: 71
        },
        {
          t: 67200,
          o: 1.10401,
          h: 1.10409,
          l: 1.10401,
          c: 1.10405,
          v: 51
        },
        {
          t: 67500,
          o: 1.10401,
          h: 1.10404,
          l: 1.10388,
          c: 1.10398,
          v: 92
        },
        {
          t: 67800,
          o: 1.10399,
          h: 1.1042,
          l: 1.10399,
          c: 1.10416,
          v: 99
        },
        {
          t: 68100,
          o: 1.10416,
          h: 1.10417,
          l: 1.10398,
          c: 1.10405,
          v: 115
        },
        {
          t: 68400,
          o: 1.10405,
          h: 1.10412,
          l: 1.10403,
          c: 1.10404,
          v: 107
        },
        {
          t: 68700,
          o: 1.10404,
          h: 1.1041,
          l: 1.104,
          c: 1.1041,
          v: 80
        },
        {
          t: 69000,
          o: 1.10409,
          h: 1.1041,
          l: 1.10398,
          c: 1.10402,
          v: 95
        },
        {
          t: 69300,
          o: 1.10401,
          h: 1.10406,
          l: 1.104,
          c: 1.104,
          v: 60
        },
        {
          t: 69600,
          o: 1.104,
          h: 1.10417,
          l: 1.104,
          c: 1.10416,
          v: 66
        },
        {
          t: 69900,
          o: 1.10415,
          h: 1.10422,
          l: 1.1041,
          c: 1.10417,
          v: 76
        },
        {
          t: 70200,
          o: 1.10417,
          h: 1.10435,
          l: 1.10415,
          c: 1.10434,
          v: 118
        },
        {
          t: 70500,
          o: 1.10435,
          h: 1.10437,
          l: 1.10426,
          c: 1.10436,
          v: 69
        },
        {
          t: 70800,
          o: 1.10435,
          h: 1.10437,
          l: 1.10425,
          c: 1.10426,
          v: 56
        },
        {
          t: 71100,
          o: 1.10426,
          h: 1.10437,
          l: 1.10426,
          c: 1.10435,
          v: 65
        },
        {
          t: 71400,
          o: 1.10436,
          h: 1.10437,
          l: 1.10426,
          c: 1.10436,
          v: 66
        },
        {
          t: 71700,
          o: 1.10437,
          h: 1.10439,
          l: 1.10411,
          c: 1.10415,
          v: 131
        },
        {
          t: 72000,
          o: 1.10414,
          h: 1.10432,
          l: 1.10414,
          c: 1.10425,
          v: 118
        },
        {
          t: 72300,
          o: 1.10426,
          h: 1.10427,
          l: 1.1042,
          c: 1.10421,
          v: 60
        },
        {
          t: 72600,
          o: 1.10421,
          h: 1.1043,
          l: 1.10421,
          c: 1.10426,
          v: 83
        },
        {
          t: 72900,
          o: 1.10425,
          h: 1.10425,
          l: 1.10408,
          c: 1.10412,
          v: 98
        },
        {
          t: 73200,
          o: 1.1041,
          h: 1.10417,
          l: 1.10406,
          c: 1.10411,
          v: 103
        },
        {
          t: 73500,
          o: 1.10412,
          h: 1.10429,
          l: 1.10409,
          c: 1.10428,
          v: 86
        },
        {
          t: 73800,
          o: 1.10427,
          h: 1.10437,
          l: 1.10425,
          c: 1.10433,
          v: 109
        },
        {
          t: 74100,
          o: 1.10433,
          h: 1.10433,
          l: 1.10426,
          c: 1.10427,
          v: 95
        },
        {
          t: 74400,
          o: 1.10428,
          h: 1.10438,
          l: 1.10428,
          c: 1.10438,
          v: 109
        },
        {
          t: 74700,
          o: 1.10436,
          h: 1.10441,
          l: 1.10423,
          c: 1.10427,
          v: 96
        },
        {
          t: 75000,
          o: 1.10426,
          h: 1.10428,
          l: 1.1042,
          c: 1.10423,
          v: 113
        },
        {
          t: 75300,
          o: 1.10423,
          h: 1.10443,
          l: 1.10422,
          c: 1.10438,
          v: 170
        },
        {
          t: 75600,
          o: 1.10437,
          h: 1.10448,
          l: 1.10437,
          c: 1.10448,
          v: 81
        },
        {
          t: 75900,
          o: 1.10448,
          h: 1.10448,
          l: 1.10444,
          c: 1.10447,
          v: 61
        },
        {
          t: 76200,
          o: 1.10447,
          h: 1.10449,
          l: 1.10439,
          c: 1.10439,
          v: 67
        },
        {
          t: 76500,
          o: 1.10438,
          h: 1.10446,
          l: 1.10436,
          c: 1.10437,
          v: 42
        },
        {
          t: 76800,
          o: 1.10436,
          h: 1.10442,
          l: 1.1043,
          c: 1.10441,
          v: 50
        },
        {
          t: 77100,
          o: 1.10441,
          h: 1.10453,
          l: 1.10436,
          c: 1.10452,
          v: 42
        },
        {
          t: 77400,
          o: 1.10451,
          h: 1.10452,
          l: 1.10447,
          c: 1.10451,
          v: 48
        },
        {
          t: 77700,
          o: 1.10451,
          h: 1.10452,
          l: 1.1045,
          c: 1.1045,
          v: 32
        },
        {
          t: 78000,
          o: 1.1045,
          h: 1.1045,
          l: 1.10435,
          c: 1.10435,
          v: 56
        },
        {
          t: 78300,
          o: 1.10433,
          h: 1.10449,
          l: 1.10433,
          c: 1.10445,
          v: 68
        },
        {
          t: 78600,
          o: 1.10446,
          h: 1.10451,
          l: 1.10443,
          c: 1.10444,
          v: 76
        },
        {
          t: 78900,
          o: 1.10444,
          h: 1.10444,
          l: 1.10431,
          c: 1.10432,
          v: 80
        },
        {
          t: 79200,
          o: 1.10431,
          h: 1.10444,
          l: 1.10412,
          c: 1.1044,
          v: 43
        },
        {
          t: 79500,
          o: 1.1044,
          h: 1.10443,
          l: 1.10426,
          c: 1.10432,
          v: 90
        },
        {
          t: 79800,
          o: 1.1043,
          h: 1.1044,
          l: 1.10429,
          c: 1.1043,
          v: 75
        },
        {
          t: 80100,
          o: 1.10428,
          h: 1.10439,
          l: 1.10412,
          c: 1.10413,
          v: 55
        },
        {
          t: 80400,
          o: 1.10407,
          h: 1.10419,
          l: 1.10402,
          c: 1.10404,
          v: 40
        },
        {
          t: 80700,
          o: 1.10404,
          h: 1.10426,
          l: 1.10399,
          c: 1.10406,
          v: 31
        },
        {
          t: 81000,
          o: 1.10412,
          h: 1.10431,
          l: 1.10412,
          c: 1.10422,
          v: 150
        },
        {
          t: 81300,
          o: 1.10422,
          h: 1.10438,
          l: 1.10414,
          c: 1.10429,
          v: 232
        },
        {
          t: 81600,
          o: 1.10427,
          h: 1.1043,
          l: 1.10412,
          c: 1.10426,
          v: 111
        },
        {
          t: 81900,
          o: 1.10426,
          h: 1.10435,
          l: 1.10417,
          c: 1.10434,
          v: 42
        },
        {
          t: 82200,
          o: 1.10434,
          h: 1.10452,
          l: 1.1042,
          c: 1.10431,
          v: 79
        },
        {
          t: 82500,
          o: 1.10429,
          h: 1.10441,
          l: 1.10416,
          c: 1.10439,
          v: 86
        },
        {
          t: 82800,
          o: 1.10442,
          h: 1.10454,
          l: 1.10441,
          c: 1.10451,
          v: 62
        },
        {
          t: 83100,
          o: 1.10451,
          h: 1.10454,
          l: 1.10447,
          c: 1.10452,
          v: 60
        },
        {
          t: 83400,
          o: 1.1045,
          h: 1.10455,
          l: 1.10447,
          c: 1.10452,
          v: 89
        },
        {
          t: 83700,
          o: 1.10451,
          h: 1.10452,
          l: 1.10451,
          c: 1.10452,
          v: 14
        },
        {
          t: 84000,
          o: 1.10452,
          h: 1.10453,
          l: 1.10448,
          c: 1.10448,
          v: 31
        },
        {
          t: 84300,
          o: 1.10448,
          h: 1.10448,
          l: 1.10444,
          c: 1.10446,
          v: 30
        },
        {
          t: 84600,
          o: 1.10447,
          h: 1.10447,
          l: 1.10442,
          c: 1.10442,
          v: 13
        },
        {
          t: 84900,
          o: 1.10443,
          h: 1.10443,
          l: 1.1044,
          c: 1.10441,
          v: 29
        },
        {
          t: 85200,
          o: 1.10442,
          h: 1.10448,
          l: 1.10435,
          c: 1.10448,
          v: 41
        },
        {
          t: 85500,
          o: 1.10447,
          h: 1.10448,
          l: 1.1044,
          c: 1.10442,
          v: 52
        },
        {
          t: 85800,
          o: 1.10441,
          h: 1.10444,
          l: 1.10441,
          c: 1.10442,
          v: 57
        },
        {
          t: 86100,
          o: 1.10443,
          h: 1.10443,
          l: 1.10441,
          c: 1.10443,
          v: 32
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.10445,
          h: 1.10445,
          l: 1.10417,
          c: 1.10422,
          v: 104
        },
        {
          t: 300,
          o: 1.10422,
          h: 1.10429,
          l: 1.10422,
          c: 1.10427,
          v: 84
        },
        {
          t: 600,
          o: 1.10426,
          h: 1.10435,
          l: 1.10426,
          c: 1.10433,
          v: 72
        },
        {
          t: 900,
          o: 1.10432,
          h: 1.10435,
          l: 1.10431,
          c: 1.10432,
          v: 52
        },
        {
          t: 1200,
          o: 1.10433,
          h: 1.10439,
          l: 1.10433,
          c: 1.10439,
          v: 44
        },
        {
          t: 1500,
          o: 1.10438,
          h: 1.10438,
          l: 1.1043,
          c: 1.10431,
          v: 68
        },
        {
          t: 1800,
          o: 1.10432,
          h: 1.1044,
          l: 1.10432,
          c: 1.10438,
          v: 57
        },
        {
          t: 2100,
          o: 1.10438,
          h: 1.10442,
          l: 1.10434,
          c: 1.10442,
          v: 60
        },
        {
          t: 2400,
          o: 1.10442,
          h: 1.10442,
          l: 1.10437,
          c: 1.10439,
          v: 47
        },
        {
          t: 2700,
          o: 1.1044,
          h: 1.10441,
          l: 1.10436,
          c: 1.10437,
          v: 69
        },
        {
          t: 3000,
          o: 1.10438,
          h: 1.10438,
          l: 1.10426,
          c: 1.10426,
          v: 89
        },
        {
          t: 3300,
          o: 1.10426,
          h: 1.10434,
          l: 1.10423,
          c: 1.10428,
          v: 97
        },
        {
          t: 3600,
          o: 1.10426,
          h: 1.10432,
          l: 1.10426,
          c: 1.10431,
          v: 85
        },
        {
          t: 3900,
          o: 1.10427,
          h: 1.10428,
          l: 1.10417,
          c: 1.10417,
          v: 78
        },
        {
          t: 4200,
          o: 1.10418,
          h: 1.10421,
          l: 1.10411,
          c: 1.10411,
          v: 80
        },
        {
          t: 4500,
          o: 1.10411,
          h: 1.10417,
          l: 1.10411,
          c: 1.10417,
          v: 67
        },
        {
          t: 4800,
          o: 1.10418,
          h: 1.10419,
          l: 1.1041,
          c: 1.1041,
          v: 76
        },
        {
          t: 5100,
          o: 1.10412,
          h: 1.10422,
          l: 1.10409,
          c: 1.10419,
          v: 84
        },
        {
          t: 5400,
          o: 1.10418,
          h: 1.10425,
          l: 1.10414,
          c: 1.1042,
          v: 138
        },
        {
          t: 5700,
          o: 1.10416,
          h: 1.10422,
          l: 1.10415,
          c: 1.10421,
          v: 84
        },
        {
          t: 6000,
          o: 1.10421,
          h: 1.10421,
          l: 1.104,
          c: 1.10401,
          v: 99
        },
        {
          t: 6300,
          o: 1.10402,
          h: 1.10403,
          l: 1.10391,
          c: 1.10392,
          v: 76
        },
        {
          t: 6600,
          o: 1.10395,
          h: 1.10403,
          l: 1.10395,
          c: 1.10402,
          v: 86
        },
        {
          t: 6900,
          o: 1.10399,
          h: 1.10407,
          l: 1.10396,
          c: 1.10398,
          v: 147
        },
        {
          t: 7200,
          o: 1.10399,
          h: 1.10404,
          l: 1.10392,
          c: 1.10403,
          v: 97
        },
        {
          t: 7500,
          o: 1.10404,
          h: 1.10404,
          l: 1.10382,
          c: 1.10382,
          v: 98
        },
        {
          t: 7800,
          o: 1.10381,
          h: 1.1039,
          l: 1.10373,
          c: 1.10377,
          v: 102
        },
        {
          t: 8100,
          o: 1.10376,
          h: 1.10388,
          l: 1.10376,
          c: 1.10386,
          v: 85
        },
        {
          t: 8400,
          o: 1.10384,
          h: 1.10384,
          l: 1.10366,
          c: 1.10367,
          v: 53
        },
        {
          t: 8700,
          o: 1.1037,
          h: 1.1037,
          l: 1.10352,
          c: 1.10356,
          v: 89
        },
        {
          t: 9000,
          o: 1.10355,
          h: 1.10362,
          l: 1.10341,
          c: 1.10341,
          v: 145
        },
        {
          t: 9300,
          o: 1.10339,
          h: 1.10342,
          l: 1.10336,
          c: 1.10337,
          v: 86
        },
        {
          t: 9600,
          o: 1.10338,
          h: 1.10342,
          l: 1.10337,
          c: 1.10341,
          v: 93
        },
        {
          t: 9900,
          o: 1.1034,
          h: 1.10348,
          l: 1.1034,
          c: 1.10343,
          v: 63
        },
        {
          t: 10200,
          o: 1.10344,
          h: 1.10347,
          l: 1.10341,
          c: 1.10343,
          v: 36
        },
        {
          t: 10500,
          o: 1.10345,
          h: 1.10346,
          l: 1.10336,
          c: 1.10339,
          v: 66
        },
        {
          t: 10800,
          o: 1.10338,
          h: 1.10348,
          l: 1.10337,
          c: 1.10346,
          v: 77
        },
        {
          t: 11100,
          o: 1.10347,
          h: 1.10353,
          l: 1.10345,
          c: 1.10352,
          v: 44
        },
        {
          t: 11400,
          o: 1.10354,
          h: 1.10358,
          l: 1.10351,
          c: 1.10358,
          v: 85
        },
        {
          t: 11700,
          o: 1.10357,
          h: 1.10361,
          l: 1.1035,
          c: 1.1035,
          v: 51
        },
        {
          t: 12000,
          o: 1.1035,
          h: 1.10351,
          l: 1.10341,
          c: 1.10345,
          v: 79
        },
        {
          t: 12300,
          o: 1.10345,
          h: 1.10346,
          l: 1.10332,
          c: 1.10344,
          v: 85
        },
        {
          t: 12600,
          o: 1.10342,
          h: 1.10344,
          l: 1.10336,
          c: 1.10337,
          v: 74
        },
        {
          t: 12900,
          o: 1.10336,
          h: 1.10338,
          l: 1.10331,
          c: 1.10336,
          v: 58
        },
        {
          t: 13200,
          o: 1.10333,
          h: 1.10338,
          l: 1.10333,
          c: 1.10334,
          v: 73
        },
        {
          t: 13500,
          o: 1.10333,
          h: 1.10337,
          l: 1.10332,
          c: 1.10337,
          v: 39
        },
        {
          t: 13800,
          o: 1.10337,
          h: 1.10337,
          l: 1.10332,
          c: 1.10337,
          v: 53
        },
        {
          t: 14100,
          o: 1.10337,
          h: 1.10338,
          l: 1.10333,
          c: 1.10337,
          v: 68
        },
        {
          t: 14400,
          o: 1.10334,
          h: 1.10339,
          l: 1.10332,
          c: 1.10337,
          v: 40
        },
        {
          t: 14700,
          o: 1.10339,
          h: 1.10341,
          l: 1.10337,
          c: 1.10337,
          v: 61
        },
        {
          t: 15000,
          o: 1.10339,
          h: 1.10343,
          l: 1.10338,
          c: 1.10342,
          v: 26
        },
        {
          t: 15300,
          o: 1.10342,
          h: 1.10343,
          l: 1.10341,
          c: 1.10341,
          v: 22
        },
        {
          t: 15600,
          o: 1.10342,
          h: 1.10348,
          l: 1.10342,
          c: 1.10348,
          v: 17
        },
        {
          t: 15900,
          o: 1.10347,
          h: 1.10348,
          l: 1.10346,
          c: 1.10348,
          v: 15
        },
        {
          t: 16200,
          o: 1.10348,
          h: 1.10348,
          l: 1.10346,
          c: 1.10346,
          v: 13
        },
        {
          t: 16500,
          o: 1.10347,
          h: 1.10348,
          l: 1.10337,
          c: 1.10337,
          v: 35
        },
        {
          t: 16800,
          o: 1.10337,
          h: 1.10343,
          l: 1.10337,
          c: 1.10342,
          v: 12
        },
        {
          t: 17100,
          o: 1.10341,
          h: 1.10345,
          l: 1.10336,
          c: 1.10337,
          v: 27
        },
        {
          t: 17400,
          o: 1.10337,
          h: 1.10338,
          l: 1.10332,
          c: 1.10333,
          v: 17
        },
        {
          t: 17700,
          o: 1.10334,
          h: 1.1034,
          l: 1.10333,
          c: 1.1034,
          v: 37
        },
        {
          t: 18000,
          o: 1.10339,
          h: 1.1034,
          l: 1.10332,
          c: 1.10337,
          v: 43
        },
        {
          t: 18300,
          o: 1.10338,
          h: 1.10343,
          l: 1.10337,
          c: 1.10342,
          v: 36
        },
        {
          t: 18600,
          o: 1.10343,
          h: 1.10348,
          l: 1.10343,
          c: 1.10347,
          v: 20
        },
        {
          t: 18900,
          o: 1.10347,
          h: 1.10352,
          l: 1.10342,
          c: 1.10347,
          v: 24
        },
        {
          t: 19200,
          o: 1.10346,
          h: 1.10348,
          l: 1.10345,
          c: 1.10348,
          v: 17
        },
        {
          t: 19500,
          o: 1.10351,
          h: 1.10354,
          l: 1.1035,
          c: 1.10354,
          v: 61
        },
        {
          t: 19800,
          o: 1.10352,
          h: 1.10353,
          l: 1.1035,
          c: 1.1035,
          v: 38
        },
        {
          t: 20100,
          o: 1.10351,
          h: 1.1037,
          l: 1.1035,
          c: 1.10362,
          v: 90
        },
        {
          t: 20400,
          o: 1.10363,
          h: 1.10368,
          l: 1.10361,
          c: 1.10365,
          v: 64
        },
        {
          t: 20700,
          o: 1.10366,
          h: 1.1037,
          l: 1.10363,
          c: 1.10367,
          v: 30
        },
        {
          t: 21000,
          o: 1.10368,
          h: 1.10376,
          l: 1.10366,
          c: 1.10375,
          v: 41
        },
        {
          t: 21300,
          o: 1.10376,
          h: 1.10376,
          l: 1.10371,
          c: 1.10373,
          v: 96
        },
        {
          t: 21600,
          o: 1.10373,
          h: 1.10378,
          l: 1.10372,
          c: 1.10373,
          v: 107
        },
        {
          t: 21900,
          o: 1.10373,
          h: 1.10373,
          l: 1.10371,
          c: 1.10373,
          v: 34
        },
        {
          t: 22200,
          o: 1.10373,
          h: 1.10373,
          l: 1.10357,
          c: 1.10357,
          v: 66
        },
        {
          t: 22500,
          o: 1.10357,
          h: 1.10361,
          l: 1.10352,
          c: 1.10358,
          v: 69
        },
        {
          t: 22800,
          o: 1.10357,
          h: 1.10367,
          l: 1.10357,
          c: 1.10361,
          v: 64
        },
        {
          t: 23100,
          o: 1.10362,
          h: 1.10368,
          l: 1.10353,
          c: 1.10357,
          v: 165
        },
        {
          t: 23400,
          o: 1.10357,
          h: 1.10361,
          l: 1.10355,
          c: 1.10357,
          v: 64
        },
        {
          t: 23700,
          o: 1.10357,
          h: 1.10358,
          l: 1.10347,
          c: 1.10351,
          v: 53
        },
        {
          t: 24000,
          o: 1.10347,
          h: 1.10348,
          l: 1.10342,
          c: 1.10342,
          v: 38
        },
        {
          t: 24300,
          o: 1.10342,
          h: 1.10347,
          l: 1.10341,
          c: 1.10343,
          v: 63
        },
        {
          t: 24600,
          o: 1.10344,
          h: 1.10353,
          l: 1.10342,
          c: 1.10351,
          v: 34
        },
        {
          t: 24900,
          o: 1.10347,
          h: 1.10356,
          l: 1.10347,
          c: 1.10353,
          v: 91
        },
        {
          t: 25200,
          o: 1.10355,
          h: 1.10365,
          l: 1.10353,
          c: 1.10363,
          v: 119
        },
        {
          t: 25500,
          o: 1.10364,
          h: 1.10375,
          l: 1.10363,
          c: 1.10373,
          v: 98
        },
        {
          t: 25800,
          o: 1.10374,
          h: 1.10382,
          l: 1.1037,
          c: 1.10374,
          v: 136
        },
        {
          t: 26100,
          o: 1.10375,
          h: 1.1038,
          l: 1.10363,
          c: 1.10379,
          v: 131
        },
        {
          t: 26400,
          o: 1.10379,
          h: 1.10389,
          l: 1.10376,
          c: 1.1038,
          v: 78
        },
        {
          t: 26700,
          o: 1.10381,
          h: 1.10391,
          l: 1.10365,
          c: 1.10368,
          v: 96
        },
        {
          t: 27000,
          o: 1.10368,
          h: 1.1038,
          l: 1.10364,
          c: 1.10375,
          v: 162
        },
        {
          t: 27300,
          o: 1.10376,
          h: 1.10381,
          l: 1.10365,
          c: 1.10373,
          v: 117
        },
        {
          t: 27600,
          o: 1.10371,
          h: 1.1038,
          l: 1.10368,
          c: 1.10379,
          v: 128
        },
        {
          t: 27900,
          o: 1.10378,
          h: 1.10401,
          l: 1.10374,
          c: 1.10391,
          v: 138
        },
        {
          t: 28200,
          o: 1.10389,
          h: 1.10394,
          l: 1.10359,
          c: 1.1036,
          v: 171
        },
        {
          t: 28500,
          o: 1.1036,
          h: 1.1037,
          l: 1.10352,
          c: 1.10363,
          v: 163
        },
        {
          t: 28800,
          o: 1.10366,
          h: 1.10405,
          l: 1.1036,
          c: 1.10399,
          v: 245
        },
        {
          t: 29100,
          o: 1.104,
          h: 1.10429,
          l: 1.10396,
          c: 1.10416,
          v: 292
        },
        {
          t: 29400,
          o: 1.10415,
          h: 1.1044,
          l: 1.10415,
          c: 1.10436,
          v: 170
        },
        {
          t: 29700,
          o: 1.10435,
          h: 1.10439,
          l: 1.10421,
          c: 1.10439,
          v: 164
        },
        {
          t: 30000,
          o: 1.10438,
          h: 1.10462,
          l: 1.10434,
          c: 1.10459,
          v: 161
        },
        {
          t: 30300,
          o: 1.1046,
          h: 1.1047,
          l: 1.10448,
          c: 1.10459,
          v: 296
        },
        {
          t: 30600,
          o: 1.10458,
          h: 1.1046,
          l: 1.10443,
          c: 1.10449,
          v: 274
        },
        {
          t: 30900,
          o: 1.10453,
          h: 1.10459,
          l: 1.10406,
          c: 1.10425,
          v: 465
        },
        {
          t: 31200,
          o: 1.10424,
          h: 1.10468,
          l: 1.10415,
          c: 1.10466,
          v: 470
        },
        {
          t: 31500,
          o: 1.10466,
          h: 1.10474,
          l: 1.10382,
          c: 1.10388,
          v: 465
        },
        {
          t: 31800,
          o: 1.10388,
          h: 1.10421,
          l: 1.10385,
          c: 1.10417,
          v: 369
        },
        {
          t: 32100,
          o: 1.10424,
          h: 1.10428,
          l: 1.10391,
          c: 1.10415,
          v: 371
        },
        {
          t: 32400,
          o: 1.10415,
          h: 1.10423,
          l: 1.10376,
          c: 1.10381,
          v: 310
        },
        {
          t: 32700,
          o: 1.10378,
          h: 1.10389,
          l: 1.10363,
          c: 1.1037,
          v: 269
        },
        {
          t: 33000,
          o: 1.10371,
          h: 1.10413,
          l: 1.10369,
          c: 1.10407,
          v: 264
        },
        {
          t: 33300,
          o: 1.10406,
          h: 1.10421,
          l: 1.10388,
          c: 1.104,
          v: 315
        },
        {
          t: 33600,
          o: 1.104,
          h: 1.10402,
          l: 1.10381,
          c: 1.10381,
          v: 226
        },
        {
          t: 33900,
          o: 1.1038,
          h: 1.10391,
          l: 1.10352,
          c: 1.10362,
          v: 230
        },
        {
          t: 34200,
          o: 1.10365,
          h: 1.10381,
          l: 1.10346,
          c: 1.10346,
          v: 320
        },
        {
          t: 34500,
          o: 1.10347,
          h: 1.10351,
          l: 1.10256,
          c: 1.10292,
          v: 386
        },
        {
          t: 34800,
          o: 1.10293,
          h: 1.10293,
          l: 1.10237,
          c: 1.10242,
          v: 397
        },
        {
          t: 35100,
          o: 1.10242,
          h: 1.10244,
          l: 1.1021,
          c: 1.10224,
          v: 343
        },
        {
          t: 35400,
          o: 1.1022,
          h: 1.10243,
          l: 1.10211,
          c: 1.10233,
          v: 346
        },
        {
          t: 35700,
          o: 1.10231,
          h: 1.10257,
          l: 1.10221,
          c: 1.10254,
          v: 283
        },
        {
          t: 36000,
          o: 1.10253,
          h: 1.10254,
          l: 1.10237,
          c: 1.10249,
          v: 197
        },
        {
          t: 36300,
          o: 1.10246,
          h: 1.10268,
          l: 1.10237,
          c: 1.10267,
          v: 222
        },
        {
          t: 36600,
          o: 1.10265,
          h: 1.10275,
          l: 1.10253,
          c: 1.10258,
          v: 235
        },
        {
          t: 36900,
          o: 1.10257,
          h: 1.10285,
          l: 1.10248,
          c: 1.10279,
          v: 262
        },
        {
          t: 37200,
          o: 1.1028,
          h: 1.10293,
          l: 1.1027,
          c: 1.10272,
          v: 151
        },
        {
          t: 37500,
          o: 1.10272,
          h: 1.10285,
          l: 1.10264,
          c: 1.10277,
          v: 158
        },
        {
          t: 37800,
          o: 1.10278,
          h: 1.10283,
          l: 1.10269,
          c: 1.1027,
          v: 184
        },
        {
          t: 38100,
          o: 1.10269,
          h: 1.10272,
          l: 1.10247,
          c: 1.10261,
          v: 157
        },
        {
          t: 38400,
          o: 1.1026,
          h: 1.10263,
          l: 1.10226,
          c: 1.10231,
          v: 189
        },
        {
          t: 38700,
          o: 1.1023,
          h: 1.10231,
          l: 1.10182,
          c: 1.10191,
          v: 196
        },
        {
          t: 39000,
          o: 1.10192,
          h: 1.10208,
          l: 1.10174,
          c: 1.10179,
          v: 178
        },
        {
          t: 39300,
          o: 1.10178,
          h: 1.10209,
          l: 1.10177,
          c: 1.10188,
          v: 248
        },
        {
          t: 39600,
          o: 1.10186,
          h: 1.10193,
          l: 1.10162,
          c: 1.10183,
          v: 243
        },
        {
          t: 39900,
          o: 1.10179,
          h: 1.10184,
          l: 1.10164,
          c: 1.10172,
          v: 197
        },
        {
          t: 40200,
          o: 1.10173,
          h: 1.10175,
          l: 1.10151,
          c: 1.10162,
          v: 170
        },
        {
          t: 40500,
          o: 1.10162,
          h: 1.10162,
          l: 1.10141,
          c: 1.10142,
          v: 109
        },
        {
          t: 40800,
          o: 1.10142,
          h: 1.10182,
          l: 1.10142,
          c: 1.10178,
          v: 192
        },
        {
          t: 41100,
          o: 1.10179,
          h: 1.10188,
          l: 1.1017,
          c: 1.10183,
          v: 175
        },
        {
          t: 41400,
          o: 1.10181,
          h: 1.10187,
          l: 1.10168,
          c: 1.10183,
          v: 145
        },
        {
          t: 41700,
          o: 1.10183,
          h: 1.10222,
          l: 1.10171,
          c: 1.1022,
          v: 488
        },
        {
          t: 42000,
          o: 1.10218,
          h: 1.10243,
          l: 1.10211,
          c: 1.10238,
          v: 282
        },
        {
          t: 42300,
          o: 1.10237,
          h: 1.10252,
          l: 1.10228,
          c: 1.1023,
          v: 252
        },
        {
          t: 42600,
          o: 1.1023,
          h: 1.1023,
          l: 1.10208,
          c: 1.10216,
          v: 155
        },
        {
          t: 42900,
          o: 1.10218,
          h: 1.10221,
          l: 1.10201,
          c: 1.10212,
          v: 153
        },
        {
          t: 43200,
          o: 1.10217,
          h: 1.10226,
          l: 1.1021,
          c: 1.10223,
          v: 147
        },
        {
          t: 43500,
          o: 1.10224,
          h: 1.10226,
          l: 1.10215,
          c: 1.10221,
          v: 110
        },
        {
          t: 43800,
          o: 1.10221,
          h: 1.10223,
          l: 1.10213,
          c: 1.10222,
          v: 122
        },
        {
          t: 44100,
          o: 1.10221,
          h: 1.10225,
          l: 1.10192,
          c: 1.10197,
          v: 132
        },
        {
          t: 44400,
          o: 1.10198,
          h: 1.10208,
          l: 1.1018,
          c: 1.10206,
          v: 213
        },
        {
          t: 44700,
          o: 1.10205,
          h: 1.10221,
          l: 1.10201,
          c: 1.10212,
          v: 163
        },
        {
          t: 45000,
          o: 1.10212,
          h: 1.10225,
          l: 1.1021,
          c: 1.10211,
          v: 143
        },
        {
          t: 45300,
          o: 1.10212,
          h: 1.10218,
          l: 1.10205,
          c: 1.10211,
          v: 106
        },
        {
          t: 45600,
          o: 1.1021,
          h: 1.10221,
          l: 1.10209,
          c: 1.10221,
          v: 110
        },
        {
          t: 45900,
          o: 1.10222,
          h: 1.10222,
          l: 1.10204,
          c: 1.10205,
          v: 121
        },
        {
          t: 46200,
          o: 1.10204,
          h: 1.10227,
          l: 1.10201,
          c: 1.10222,
          v: 135
        },
        {
          t: 46500,
          o: 1.10223,
          h: 1.10227,
          l: 1.10208,
          c: 1.10219,
          v: 216
        },
        {
          t: 46800,
          o: 1.10219,
          h: 1.10227,
          l: 1.10206,
          c: 1.10225,
          v: 190
        },
        {
          t: 47100,
          o: 1.10222,
          h: 1.10224,
          l: 1.10212,
          c: 1.10218,
          v: 221
        },
        {
          t: 47400,
          o: 1.10217,
          h: 1.10228,
          l: 1.10206,
          c: 1.10216,
          v: 163
        },
        {
          t: 47700,
          o: 1.10213,
          h: 1.10223,
          l: 1.1015,
          c: 1.10164,
          v: 401
        },
        {
          t: 48000,
          o: 1.10166,
          h: 1.10197,
          l: 1.10158,
          c: 1.10161,
          v: 252
        },
        {
          t: 48300,
          o: 1.1016,
          h: 1.10165,
          l: 1.10146,
          c: 1.10155,
          v: 227
        },
        {
          t: 48600,
          o: 1.10155,
          h: 1.10163,
          l: 1.10116,
          c: 1.10135,
          v: 235
        },
        {
          t: 48900,
          o: 1.10136,
          h: 1.10145,
          l: 1.101,
          c: 1.10113,
          v: 323
        },
        {
          t: 49200,
          o: 1.10117,
          h: 1.10123,
          l: 1.10077,
          c: 1.10085,
          v: 302
        },
        {
          t: 49500,
          o: 1.10081,
          h: 1.10097,
          l: 1.10074,
          c: 1.10093,
          v: 388
        },
        {
          t: 49800,
          o: 1.10094,
          h: 1.10122,
          l: 1.10083,
          c: 1.10117,
          v: 286
        },
        {
          t: 50100,
          o: 1.10116,
          h: 1.10127,
          l: 1.10114,
          c: 1.10118,
          v: 218
        },
        {
          t: 50400,
          o: 1.10119,
          h: 1.10138,
          l: 1.10113,
          c: 1.10131,
          v: 198
        },
        {
          t: 50700,
          o: 1.10133,
          h: 1.10138,
          l: 1.10106,
          c: 1.10108,
          v: 239
        },
        {
          t: 51000,
          o: 1.1011,
          h: 1.10119,
          l: 1.10076,
          c: 1.10086,
          v: 267
        },
        {
          t: 51300,
          o: 1.10082,
          h: 1.10098,
          l: 1.10066,
          c: 1.10092,
          v: 214
        },
        {
          t: 51600,
          o: 1.10093,
          h: 1.10093,
          l: 1.10047,
          c: 1.10052,
          v: 196
        },
        {
          t: 51900,
          o: 1.10053,
          h: 1.10064,
          l: 1.10038,
          c: 1.10042,
          v: 263
        },
        {
          t: 52200,
          o: 1.10045,
          h: 1.10053,
          l: 1.10026,
          c: 1.10041,
          v: 348
        },
        {
          t: 52500,
          o: 1.10042,
          h: 1.10056,
          l: 1.10026,
          c: 1.1003,
          v: 299
        },
        {
          t: 52800,
          o: 1.10028,
          h: 1.10033,
          l: 1.09994,
          c: 1.10001,
          v: 324
        },
        {
          t: 53100,
          o: 1.1,
          h: 1.10025,
          l: 1.09994,
          c: 1.10013,
          v: 367
        },
        {
          t: 53400,
          o: 1.10013,
          h: 1.10023,
          l: 1.10002,
          c: 1.10022,
          v: 320
        },
        {
          t: 53700,
          o: 1.10018,
          h: 1.10044,
          l: 1.10013,
          c: 1.10021,
          v: 244
        },
        {
          t: 54000,
          o: 1.10023,
          h: 1.10036,
          l: 1.09967,
          c: 1.10022,
          v: 368
        },
        {
          t: 54300,
          o: 1.10021,
          h: 1.10066,
          l: 1.10016,
          c: 1.10054,
          v: 350
        },
        {
          t: 54600,
          o: 1.10053,
          h: 1.10056,
          l: 1.10005,
          c: 1.10043,
          v: 377
        },
        {
          t: 54900,
          o: 1.10044,
          h: 1.10048,
          l: 1.09987,
          c: 1.10003,
          v: 317
        },
        {
          t: 55200,
          o: 1.10002,
          h: 1.10043,
          l: 1.09992,
          c: 1.1003,
          v: 286
        },
        {
          t: 55500,
          o: 1.10033,
          h: 1.10092,
          l: 1.10028,
          c: 1.10075,
          v: 404
        },
        {
          t: 55800,
          o: 1.10075,
          h: 1.10088,
          l: 1.1005,
          c: 1.10053,
          v: 277
        },
        {
          t: 56100,
          o: 1.10053,
          h: 1.10068,
          l: 1.1003,
          c: 1.10054,
          v: 357
        },
        {
          t: 56400,
          o: 1.10055,
          h: 1.1006,
          l: 1.10008,
          c: 1.10025,
          v: 306
        },
        {
          t: 56700,
          o: 1.10025,
          h: 1.10025,
          l: 1.09977,
          c: 1.09982,
          v: 360
        },
        {
          t: 57000,
          o: 1.09983,
          h: 1.10023,
          l: 1.0998,
          c: 1.10016,
          v: 312
        },
        {
          t: 57300,
          o: 1.10018,
          h: 1.10018,
          l: 1.09959,
          c: 1.10016,
          v: 419
        },
        {
          t: 57600,
          o: 1.10018,
          h: 1.10023,
          l: 1.10005,
          c: 1.10009,
          v: 339
        },
        {
          t: 57900,
          o: 1.10014,
          h: 1.10036,
          l: 1.09995,
          c: 1.1,
          v: 246
        },
        {
          t: 58200,
          o: 1.09997,
          h: 1.10011,
          l: 1.09984,
          c: 1.1001,
          v: 207
        },
        {
          t: 58500,
          o: 1.10008,
          h: 1.10028,
          l: 1.09999,
          c: 1.10014,
          v: 223
        },
        {
          t: 58800,
          o: 1.10012,
          h: 1.10031,
          l: 1.10005,
          c: 1.10027,
          v: 230
        },
        {
          t: 59100,
          o: 1.10028,
          h: 1.10037,
          l: 1.10022,
          c: 1.10025,
          v: 156
        },
        {
          t: 59400,
          o: 1.10026,
          h: 1.10026,
          l: 1.09993,
          c: 1.10005,
          v: 186
        },
        {
          t: 59700,
          o: 1.10005,
          h: 1.10046,
          l: 1.10005,
          c: 1.10041,
          v: 218
        },
        {
          t: 60000,
          o: 1.1004,
          h: 1.10049,
          l: 1.10025,
          c: 1.10027,
          v: 178
        },
        {
          t: 60300,
          o: 1.10028,
          h: 1.10032,
          l: 1.10014,
          c: 1.10025,
          v: 147
        },
        {
          t: 60600,
          o: 1.10026,
          h: 1.10044,
          l: 1.10026,
          c: 1.10037,
          v: 69
        },
        {
          t: 60900,
          o: 1.10036,
          h: 1.10059,
          l: 1.10036,
          c: 1.10057,
          v: 177
        },
        {
          t: 61200,
          o: 1.10056,
          h: 1.10063,
          l: 1.1004,
          c: 1.10056,
          v: 212
        },
        {
          t: 61500,
          o: 1.10057,
          h: 1.1006,
          l: 1.10047,
          c: 1.10058,
          v: 140
        },
        {
          t: 61800,
          o: 1.10059,
          h: 1.10063,
          l: 1.1005,
          c: 1.1006,
          v: 151
        },
        {
          t: 62100,
          o: 1.10062,
          h: 1.10062,
          l: 1.10049,
          c: 1.10053,
          v: 99
        },
        {
          t: 62400,
          o: 1.10051,
          h: 1.10052,
          l: 1.10035,
          c: 1.10036,
          v: 135
        },
        {
          t: 62700,
          o: 1.10035,
          h: 1.10043,
          l: 1.10033,
          c: 1.10041,
          v: 86
        },
        {
          t: 63000,
          o: 1.10043,
          h: 1.10044,
          l: 1.10034,
          c: 1.1004,
          v: 105
        },
        {
          t: 63300,
          o: 1.1004,
          h: 1.10044,
          l: 1.1003,
          c: 1.10035,
          v: 95
        },
        {
          t: 63600,
          o: 1.10035,
          h: 1.10042,
          l: 1.10028,
          c: 1.1004,
          v: 115
        },
        {
          t: 63900,
          o: 1.10038,
          h: 1.10046,
          l: 1.10037,
          c: 1.10044,
          v: 83
        },
        {
          t: 64200,
          o: 1.10045,
          h: 1.10046,
          l: 1.1003,
          c: 1.1003,
          v: 98
        },
        {
          t: 64500,
          o: 1.1003,
          h: 1.1003,
          l: 1.10005,
          c: 1.10006,
          v: 145
        },
        {
          t: 64800,
          o: 1.10006,
          h: 1.1002,
          l: 1.10005,
          c: 1.10015,
          v: 110
        },
        {
          t: 65100,
          o: 1.10015,
          h: 1.10015,
          l: 1.09999,
          c: 1.10001,
          v: 94
        },
        {
          t: 65400,
          o: 1.09999,
          h: 1.10005,
          l: 1.09986,
          c: 1.09989,
          v: 130
        },
        {
          t: 65700,
          o: 1.0999,
          h: 1.10003,
          l: 1.09989,
          c: 1.1,
          v: 60
        },
        {
          t: 66000,
          o: 1.1,
          h: 1.10017,
          l: 1.1,
          c: 1.10016,
          v: 61
        },
        {
          t: 66300,
          o: 1.10016,
          h: 1.10022,
          l: 1.10016,
          c: 1.10021,
          v: 23
        },
        {
          t: 66600,
          o: 1.10022,
          h: 1.10023,
          l: 1.10016,
          c: 1.10016,
          v: 27
        },
        {
          t: 66900,
          o: 1.10012,
          h: 1.10017,
          l: 1.10004,
          c: 1.10016,
          v: 29
        },
        {
          t: 67200,
          o: 1.10016,
          h: 1.10023,
          l: 1.10016,
          c: 1.10017,
          v: 37
        },
        {
          t: 67500,
          o: 1.10016,
          h: 1.10017,
          l: 1.10011,
          c: 1.10012,
          v: 29
        },
        {
          t: 67800,
          o: 1.10011,
          h: 1.10026,
          l: 1.10011,
          c: 1.10022,
          v: 47
        },
        {
          t: 68100,
          o: 1.10023,
          h: 1.10023,
          l: 1.10015,
          c: 1.10018,
          v: 55
        },
        {
          t: 68400,
          o: 1.10021,
          h: 1.10021,
          l: 1.10003,
          c: 1.10005,
          v: 36
        },
        {
          t: 68700,
          o: 1.10007,
          h: 1.10007,
          l: 1.09989,
          c: 1.09994,
          v: 117
        },
        {
          t: 69000,
          o: 1.09993,
          h: 1.09993,
          l: 1.09985,
          c: 1.09985,
          v: 66
        },
        {
          t: 69300,
          o: 1.09985,
          h: 1.09987,
          l: 1.09968,
          c: 1.09969,
          v: 67
        },
        {
          t: 69600,
          o: 1.09966,
          h: 1.09966,
          l: 1.09942,
          c: 1.09952,
          v: 148
        },
        {
          t: 69900,
          o: 1.09952,
          h: 1.09962,
          l: 1.09943,
          c: 1.09945,
          v: 131
        },
        {
          t: 70200,
          o: 1.09945,
          h: 1.09948,
          l: 1.09932,
          c: 1.09937,
          v: 138
        },
        {
          t: 70500,
          o: 1.09937,
          h: 1.09957,
          l: 1.09937,
          c: 1.09947,
          v: 96
        },
        {
          t: 70800,
          o: 1.09947,
          h: 1.09947,
          l: 1.09931,
          c: 1.09931,
          v: 77
        },
        {
          t: 71100,
          o: 1.09933,
          h: 1.09942,
          l: 1.09932,
          c: 1.09939,
          v: 95
        },
        {
          t: 71400,
          o: 1.09938,
          h: 1.0995,
          l: 1.09938,
          c: 1.09947,
          v: 70
        },
        {
          t: 71700,
          o: 1.09949,
          h: 1.09967,
          l: 1.09949,
          c: 1.09955,
          v: 141
        },
        {
          t: 72000,
          o: 1.09954,
          h: 1.09974,
          l: 1.09954,
          c: 1.09972,
          v: 116
        },
        {
          t: 72300,
          o: 1.0997,
          h: 1.09974,
          l: 1.0997,
          c: 1.09974,
          v: 69
        },
        {
          t: 72600,
          o: 1.09974,
          h: 1.09977,
          l: 1.0997,
          c: 1.09975,
          v: 57
        },
        {
          t: 72900,
          o: 1.09975,
          h: 1.09982,
          l: 1.09974,
          c: 1.0998,
          v: 59
        },
        {
          t: 73200,
          o: 1.0998,
          h: 1.09985,
          l: 1.09973,
          c: 1.09985,
          v: 48
        },
        {
          t: 73500,
          o: 1.09984,
          h: 1.09988,
          l: 1.09984,
          c: 1.09987,
          v: 21
        },
        {
          t: 73800,
          o: 1.09987,
          h: 1.0999,
          l: 1.09982,
          c: 1.09987,
          v: 71
        },
        {
          t: 74100,
          o: 1.09988,
          h: 1.09992,
          l: 1.09982,
          c: 1.09987,
          v: 90
        },
        {
          t: 74400,
          o: 1.09986,
          h: 1.09986,
          l: 1.0998,
          c: 1.09983,
          v: 42
        },
        {
          t: 74700,
          o: 1.09982,
          h: 1.09982,
          l: 1.0997,
          c: 1.0998,
          v: 38
        },
        {
          t: 75000,
          o: 1.09979,
          h: 1.09981,
          l: 1.09969,
          c: 1.0997,
          v: 86
        },
        {
          t: 75300,
          o: 1.09971,
          h: 1.09975,
          l: 1.09959,
          c: 1.09969,
          v: 122
        },
        {
          t: 75600,
          o: 1.09971,
          h: 1.0999,
          l: 1.0997,
          c: 1.09989,
          v: 145
        },
        {
          t: 75900,
          o: 1.09987,
          h: 1.09993,
          l: 1.09984,
          c: 1.0999,
          v: 62
        },
        {
          t: 76200,
          o: 1.09989,
          h: 1.09989,
          l: 1.09979,
          c: 1.0998,
          v: 65
        },
        {
          t: 76500,
          o: 1.09982,
          h: 1.09987,
          l: 1.09982,
          c: 1.09985,
          v: 36
        },
        {
          t: 76800,
          o: 1.09985,
          h: 1.09985,
          l: 1.0998,
          c: 1.0998,
          v: 17
        },
        {
          t: 77100,
          o: 1.0998,
          h: 1.09983,
          l: 1.09979,
          c: 1.09979,
          v: 33
        },
        {
          t: 77400,
          o: 1.09981,
          h: 1.09986,
          l: 1.09975,
          c: 1.09977,
          v: 81
        },
        {
          t: 77700,
          o: 1.09977,
          h: 1.09977,
          l: 1.09966,
          c: 1.09966,
          v: 26
        },
        {
          t: 78000,
          o: 1.09966,
          h: 1.09971,
          l: 1.09964,
          c: 1.09966,
          v: 37
        },
        {
          t: 78300,
          o: 1.09966,
          h: 1.09974,
          l: 1.09966,
          c: 1.09973,
          v: 29
        },
        {
          t: 78600,
          o: 1.09972,
          h: 1.09988,
          l: 1.09972,
          c: 1.09987,
          v: 83
        },
        {
          t: 78900,
          o: 1.09985,
          h: 1.09993,
          l: 1.09978,
          c: 1.09986,
          v: 99
        },
        {
          t: 79200,
          o: 1.09986,
          h: 1.09987,
          l: 1.09971,
          c: 1.09987,
          v: 80
        },
        {
          t: 79500,
          o: 1.09987,
          h: 1.09987,
          l: 1.09975,
          c: 1.09978,
          v: 80
        },
        {
          t: 79800,
          o: 1.09978,
          h: 1.09984,
          l: 1.09978,
          c: 1.09982,
          v: 97
        },
        {
          t: 80100,
          o: 1.09981,
          h: 1.09982,
          l: 1.09978,
          c: 1.09982,
          v: 57
        },
        {
          t: 80400,
          o: 1.09978,
          h: 1.09985,
          l: 1.09978,
          c: 1.09981,
          v: 135
        },
        {
          t: 80700,
          o: 1.09981,
          h: 1.09984,
          l: 1.09978,
          c: 1.09982,
          v: 47
        },
        {
          t: 81000,
          o: 1.0998,
          h: 1.09988,
          l: 1.09979,
          c: 1.09988,
          v: 30
        },
        {
          t: 81300,
          o: 1.09986,
          h: 1.09991,
          l: 1.09986,
          c: 1.09991,
          v: 10
        },
        {
          t: 81600,
          o: 1.09989,
          h: 1.09994,
          l: 1.09988,
          c: 1.09991,
          v: 23
        },
        {
          t: 81900,
          o: 1.09993,
          h: 1.09996,
          l: 1.09989,
          c: 1.09992,
          v: 65
        },
        {
          t: 82200,
          o: 1.09992,
          h: 1.09996,
          l: 1.09992,
          c: 1.09993,
          v: 27
        },
        {
          t: 82500,
          o: 1.09994,
          h: 1.09994,
          l: 1.09991,
          c: 1.09991,
          v: 19
        },
        {
          t: 82800,
          o: 1.09991,
          h: 1.09998,
          l: 1.09988,
          c: 1.09994,
          v: 42
        },
        {
          t: 83100,
          o: 1.09993,
          h: 1.09997,
          l: 1.09991,
          c: 1.09993,
          v: 59
        },
        {
          t: 83400,
          o: 1.09993,
          h: 1.09997,
          l: 1.0999,
          c: 1.0999,
          v: 40
        },
        {
          t: 83700,
          o: 1.09992,
          h: 1.09994,
          l: 1.09988,
          c: 1.09989,
          v: 33
        },
        {
          t: 84000,
          o: 1.09988,
          h: 1.0999,
          l: 1.09981,
          c: 1.09986,
          v: 41
        },
        {
          t: 84300,
          o: 1.09986,
          h: 1.09988,
          l: 1.09984,
          c: 1.09985,
          v: 53
        },
        {
          t: 84600,
          o: 1.09991,
          h: 1.09993,
          l: 1.09986,
          c: 1.09993,
          v: 49
        },
        {
          t: 84900,
          o: 1.09993,
          h: 1.09993,
          l: 1.09989,
          c: 1.09993,
          v: 25
        },
        {
          t: 85200,
          o: 1.09993,
          h: 1.09996,
          l: 1.09991,
          c: 1.09993,
          v: 24
        },
        {
          t: 85500,
          o: 1.09994,
          h: 1.09996,
          l: 1.0999,
          c: 1.09992,
          v: 46
        },
        {
          t: 85800,
          o: 1.09992,
          h: 1.09995,
          l: 1.09991,
          c: 1.09995,
          v: 42
        },
        {
          t: 86100,
          o: 1.09994,
          h: 1.09999,
          l: 1.09993,
          c: 1.09999,
          v: 26
        }
      ]
    },
    {
      d: 1580947200,
      b: [
        {
          t: 0,
          o: 1.1,
          h: 1.10001,
          l: 1.09987,
          c: 1.09988,
          v: 65
        },
        {
          t: 300,
          o: 1.09987,
          h: 1.09987,
          l: 1.09985,
          c: 1.09986,
          v: 39
        },
        {
          t: 600,
          o: 1.09982,
          h: 1.09982,
          l: 1.09967,
          c: 1.09973,
          v: 68
        },
        {
          t: 900,
          o: 1.09973,
          h: 1.09985,
          l: 1.09973,
          c: 1.09985,
          v: 31
        },
        {
          t: 1200,
          o: 1.09984,
          h: 1.09991,
          l: 1.09984,
          c: 1.0999,
          v: 19
        },
        {
          t: 1500,
          o: 1.09991,
          h: 1.09996,
          l: 1.09989,
          c: 1.09995,
          v: 36
        },
        {
          t: 1800,
          o: 1.09993,
          h: 1.09996,
          l: 1.09985,
          c: 1.09994,
          v: 61
        },
        {
          t: 2100,
          o: 1.09992,
          h: 1.09996,
          l: 1.09989,
          c: 1.09995,
          v: 48
        },
        {
          t: 2400,
          o: 1.09995,
          h: 1.09995,
          l: 1.09979,
          c: 1.0998,
          v: 69
        },
        {
          t: 2700,
          o: 1.09979,
          h: 1.09985,
          l: 1.09972,
          c: 1.09984,
          v: 53
        },
        {
          t: 3000,
          o: 1.09982,
          h: 1.09984,
          l: 1.09956,
          c: 1.09965,
          v: 102
        },
        {
          t: 3300,
          o: 1.09965,
          h: 1.09965,
          l: 1.09955,
          c: 1.09959,
          v: 77
        },
        {
          t: 3600,
          o: 1.09961,
          h: 1.09981,
          l: 1.09959,
          c: 1.09981,
          v: 86
        },
        {
          t: 3900,
          o: 1.09977,
          h: 1.09991,
          l: 1.09977,
          c: 1.09986,
          v: 74
        },
        {
          t: 4200,
          o: 1.09985,
          h: 1.09996,
          l: 1.09985,
          c: 1.09994,
          v: 58
        },
        {
          t: 4500,
          o: 1.09995,
          h: 1.09996,
          l: 1.09975,
          c: 1.09979,
          v: 132
        },
        {
          t: 4800,
          o: 1.0998,
          h: 1.09981,
          l: 1.09965,
          c: 1.09965,
          v: 68
        },
        {
          t: 5100,
          o: 1.09963,
          h: 1.09964,
          l: 1.09952,
          c: 1.09959,
          v: 83
        },
        {
          t: 5400,
          o: 1.09958,
          h: 1.09966,
          l: 1.09955,
          c: 1.09959,
          v: 91
        },
        {
          t: 5700,
          o: 1.09959,
          h: 1.0996,
          l: 1.09949,
          c: 1.09949,
          v: 85
        },
        {
          t: 6000,
          o: 1.09949,
          h: 1.09955,
          l: 1.09949,
          c: 1.09949,
          v: 87
        },
        {
          t: 6300,
          o: 1.09949,
          h: 1.09951,
          l: 1.09939,
          c: 1.09944,
          v: 83
        },
        {
          t: 6600,
          o: 1.09943,
          h: 1.09963,
          l: 1.09943,
          c: 1.09962,
          v: 70
        },
        {
          t: 6900,
          o: 1.09961,
          h: 1.09977,
          l: 1.0996,
          c: 1.09976,
          v: 74
        },
        {
          t: 7200,
          o: 1.09977,
          h: 1.09985,
          l: 1.0997,
          c: 1.09971,
          v: 84
        },
        {
          t: 7500,
          o: 1.0997,
          h: 1.09975,
          l: 1.0997,
          c: 1.0997,
          v: 98
        },
        {
          t: 7800,
          o: 1.09972,
          h: 1.09976,
          l: 1.09968,
          c: 1.09975,
          v: 82
        },
        {
          t: 8100,
          o: 1.09974,
          h: 1.09981,
          l: 1.09966,
          c: 1.09969,
          v: 71
        },
        {
          t: 8400,
          o: 1.09966,
          h: 1.09972,
          l: 1.09964,
          c: 1.0997,
          v: 49
        },
        {
          t: 8700,
          o: 1.09972,
          h: 1.09972,
          l: 1.0997,
          c: 1.09971,
          v: 35
        },
        {
          t: 9000,
          o: 1.0997,
          h: 1.09973,
          l: 1.09967,
          c: 1.09973,
          v: 31
        },
        {
          t: 9300,
          o: 1.09972,
          h: 1.09977,
          l: 1.09972,
          c: 1.09976,
          v: 32
        },
        {
          t: 9600,
          o: 1.09977,
          h: 1.09977,
          l: 1.0997,
          c: 1.09972,
          v: 22
        },
        {
          t: 9900,
          o: 1.09971,
          h: 1.09972,
          l: 1.09966,
          c: 1.09966,
          v: 38
        },
        {
          t: 10200,
          o: 1.09967,
          h: 1.09967,
          l: 1.0996,
          c: 1.09962,
          v: 47
        },
        {
          t: 10500,
          o: 1.09961,
          h: 1.09972,
          l: 1.0996,
          c: 1.09972,
          v: 62
        },
        {
          t: 10800,
          o: 1.09971,
          h: 1.09977,
          l: 1.09967,
          c: 1.09976,
          v: 31
        },
        {
          t: 11100,
          o: 1.09977,
          h: 1.09982,
          l: 1.09976,
          c: 1.09982,
          v: 18
        },
        {
          t: 11400,
          o: 1.09981,
          h: 1.09983,
          l: 1.09977,
          c: 1.09982,
          v: 56
        },
        {
          t: 11700,
          o: 1.09981,
          h: 1.09981,
          l: 1.09971,
          c: 1.09977,
          v: 37
        },
        {
          t: 12000,
          o: 1.09976,
          h: 1.09977,
          l: 1.09969,
          c: 1.09975,
          v: 48
        },
        {
          t: 12300,
          o: 1.09975,
          h: 1.09978,
          l: 1.09971,
          c: 1.09971,
          v: 22
        },
        {
          t: 12600,
          o: 1.09972,
          h: 1.09972,
          l: 1.0996,
          c: 1.09961,
          v: 39
        },
        {
          t: 12900,
          o: 1.09962,
          h: 1.09966,
          l: 1.09958,
          c: 1.09961,
          v: 47
        },
        {
          t: 13200,
          o: 1.09961,
          h: 1.09973,
          l: 1.0996,
          c: 1.09973,
          v: 57
        },
        {
          t: 13500,
          o: 1.09971,
          h: 1.09972,
          l: 1.09969,
          c: 1.09972,
          v: 30
        },
        {
          t: 13800,
          o: 1.09972,
          h: 1.09972,
          l: 1.09967,
          c: 1.0997,
          v: 18
        },
        {
          t: 14100,
          o: 1.0997,
          h: 1.09972,
          l: 1.09965,
          c: 1.09967,
          v: 53
        },
        {
          t: 14400,
          o: 1.09967,
          h: 1.09972,
          l: 1.09966,
          c: 1.09971,
          v: 91
        },
        {
          t: 14700,
          o: 1.0997,
          h: 1.0997,
          l: 1.0995,
          c: 1.09966,
          v: 136
        },
        {
          t: 15000,
          o: 1.09965,
          h: 1.09977,
          l: 1.09957,
          c: 1.09969,
          v: 136
        },
        {
          t: 15300,
          o: 1.09969,
          h: 1.09975,
          l: 1.0996,
          c: 1.0996,
          v: 81
        },
        {
          t: 15600,
          o: 1.09961,
          h: 1.09966,
          l: 1.09949,
          c: 1.0995,
          v: 76
        },
        {
          t: 15900,
          o: 1.09949,
          h: 1.09951,
          l: 1.09939,
          c: 1.09945,
          v: 48
        },
        {
          t: 16200,
          o: 1.09946,
          h: 1.09948,
          l: 1.09944,
          c: 1.09945,
          v: 53
        },
        {
          t: 16500,
          o: 1.09945,
          h: 1.09959,
          l: 1.09945,
          c: 1.09959,
          v: 25
        },
        {
          t: 16800,
          o: 1.09958,
          h: 1.09967,
          l: 1.09955,
          c: 1.09967,
          v: 53
        },
        {
          t: 17100,
          o: 1.09967,
          h: 1.09967,
          l: 1.09961,
          c: 1.09961,
          v: 61
        },
        {
          t: 17400,
          o: 1.09961,
          h: 1.09964,
          l: 1.09951,
          c: 1.09956,
          v: 85
        },
        {
          t: 17700,
          o: 1.09955,
          h: 1.09956,
          l: 1.0995,
          c: 1.09952,
          v: 45
        },
        {
          t: 18000,
          o: 1.09956,
          h: 1.09956,
          l: 1.0995,
          c: 1.09955,
          v: 56
        },
        {
          t: 18300,
          o: 1.09955,
          h: 1.09956,
          l: 1.09946,
          c: 1.0995,
          v: 35
        },
        {
          t: 18600,
          o: 1.09951,
          h: 1.09952,
          l: 1.09946,
          c: 1.09952,
          v: 31
        },
        {
          t: 18900,
          o: 1.09952,
          h: 1.09956,
          l: 1.09939,
          c: 1.0994,
          v: 74
        },
        {
          t: 19200,
          o: 1.09939,
          h: 1.09964,
          l: 1.09936,
          c: 1.0996,
          v: 58
        },
        {
          t: 19500,
          o: 1.09961,
          h: 1.09971,
          l: 1.0996,
          c: 1.09967,
          v: 49
        },
        {
          t: 19800,
          o: 1.09966,
          h: 1.0997,
          l: 1.09964,
          c: 1.09966,
          v: 65
        },
        {
          t: 20100,
          o: 1.09967,
          h: 1.09968,
          l: 1.09965,
          c: 1.09966,
          v: 40
        },
        {
          t: 20400,
          o: 1.09967,
          h: 1.09972,
          l: 1.09962,
          c: 1.09972,
          v: 31
        },
        {
          t: 20700,
          o: 1.09972,
          h: 1.09972,
          l: 1.0996,
          c: 1.09961,
          v: 31
        },
        {
          t: 21000,
          o: 1.09963,
          h: 1.09976,
          l: 1.0996,
          c: 1.09975,
          v: 65
        },
        {
          t: 21300,
          o: 1.09978,
          h: 1.09993,
          l: 1.09973,
          c: 1.09993,
          v: 145
        },
        {
          t: 21600,
          o: 1.09991,
          h: 1.09991,
          l: 1.0998,
          c: 1.09983,
          v: 122
        },
        {
          t: 21900,
          o: 1.09982,
          h: 1.09985,
          l: 1.09976,
          c: 1.09977,
          v: 77
        },
        {
          t: 22200,
          o: 1.09978,
          h: 1.09981,
          l: 1.09976,
          c: 1.09978,
          v: 23
        },
        {
          t: 22500,
          o: 1.09978,
          h: 1.09982,
          l: 1.09972,
          c: 1.0998,
          v: 39
        },
        {
          t: 22800,
          o: 1.09979,
          h: 1.09979,
          l: 1.09966,
          c: 1.09967,
          v: 56
        },
        {
          t: 23100,
          o: 1.09967,
          h: 1.09978,
          l: 1.09966,
          c: 1.09967,
          v: 193
        },
        {
          t: 23400,
          o: 1.09968,
          h: 1.0997,
          l: 1.09955,
          c: 1.09969,
          v: 78
        },
        {
          t: 23700,
          o: 1.0997,
          h: 1.09973,
          l: 1.09966,
          c: 1.09967,
          v: 79
        },
        {
          t: 24000,
          o: 1.09969,
          h: 1.09982,
          l: 1.09969,
          c: 1.09982,
          v: 112
        },
        {
          t: 24300,
          o: 1.0998,
          h: 1.09997,
          l: 1.0998,
          c: 1.09995,
          v: 61
        },
        {
          t: 24600,
          o: 1.09993,
          h: 1.10009,
          l: 1.09991,
          c: 1.09997,
          v: 116
        },
        {
          t: 24900,
          o: 1.09996,
          h: 1.10015,
          l: 1.09992,
          c: 1.09996,
          v: 139
        },
        {
          t: 25200,
          o: 1.09999,
          h: 1.09999,
          l: 1.09972,
          c: 1.09987,
          v: 212
        },
        {
          t: 25500,
          o: 1.09988,
          h: 1.10005,
          l: 1.09985,
          c: 1.09993,
          v: 131
        },
        {
          t: 25800,
          o: 1.09992,
          h: 1.09993,
          l: 1.09977,
          c: 1.09988,
          v: 95
        },
        {
          t: 26100,
          o: 1.09987,
          h: 1.09989,
          l: 1.09976,
          c: 1.09977,
          v: 117
        },
        {
          t: 26400,
          o: 1.09977,
          h: 1.09994,
          l: 1.09972,
          c: 1.09992,
          v: 138
        },
        {
          t: 26700,
          o: 1.09992,
          h: 1.10014,
          l: 1.09987,
          c: 1.10008,
          v: 175
        },
        {
          t: 27000,
          o: 1.10007,
          h: 1.10039,
          l: 1.09991,
          c: 1.10039,
          v: 149
        },
        {
          t: 27300,
          o: 1.1004,
          h: 1.10053,
          l: 1.10031,
          c: 1.10043,
          v: 142
        },
        {
          t: 27600,
          o: 1.10043,
          h: 1.10043,
          l: 1.1002,
          c: 1.10023,
          v: 108
        },
        {
          t: 27900,
          o: 1.1002,
          h: 1.10043,
          l: 1.1002,
          c: 1.1004,
          v: 110
        },
        {
          t: 28200,
          o: 1.10043,
          h: 1.10043,
          l: 1.10022,
          c: 1.10022,
          v: 119
        },
        {
          t: 28500,
          o: 1.1002,
          h: 1.10034,
          l: 1.1002,
          c: 1.10025,
          v: 165
        },
        {
          t: 28800,
          o: 1.10024,
          h: 1.10134,
          l: 1.10021,
          c: 1.10074,
          v: 443
        },
        {
          t: 29100,
          o: 1.10077,
          h: 1.10089,
          l: 1.09991,
          c: 1.10006,
          v: 416
        },
        {
          t: 29400,
          o: 1.1001,
          h: 1.10023,
          l: 1.10006,
          c: 1.10014,
          v: 239
        },
        {
          t: 29700,
          o: 1.10016,
          h: 1.10028,
          l: 1.10005,
          c: 1.10014,
          v: 224
        },
        {
          t: 30000,
          o: 1.10014,
          h: 1.10014,
          l: 1.09974,
          c: 1.09985,
          v: 228
        },
        {
          t: 30300,
          o: 1.09986,
          h: 1.09997,
          l: 1.09971,
          c: 1.09983,
          v: 262
        },
        {
          t: 30600,
          o: 1.09981,
          h: 1.09987,
          l: 1.09936,
          c: 1.0994,
          v: 229
        },
        {
          t: 30900,
          o: 1.0994,
          h: 1.09948,
          l: 1.09938,
          c: 1.09939,
          v: 201
        },
        {
          t: 31200,
          o: 1.0994,
          h: 1.09965,
          l: 1.09934,
          c: 1.09965,
          v: 207
        },
        {
          t: 31500,
          o: 1.09966,
          h: 1.09977,
          l: 1.0996,
          c: 1.09969,
          v: 157
        },
        {
          t: 31800,
          o: 1.09969,
          h: 1.0999,
          l: 1.09961,
          c: 1.09986,
          v: 139
        },
        {
          t: 32100,
          o: 1.09985,
          h: 1.09988,
          l: 1.0994,
          c: 1.0995,
          v: 210
        },
        {
          t: 32400,
          o: 1.0995,
          h: 1.09972,
          l: 1.09946,
          c: 1.09972,
          v: 198
        },
        {
          t: 32700,
          o: 1.09975,
          h: 1.09993,
          l: 1.09956,
          c: 1.09993,
          v: 147
        },
        {
          t: 33000,
          o: 1.09993,
          h: 1.10012,
          l: 1.09985,
          c: 1.09998,
          v: 257
        },
        {
          t: 33300,
          o: 1.09996,
          h: 1.10026,
          l: 1.09995,
          c: 1.10026,
          v: 191
        },
        {
          t: 33600,
          o: 1.10026,
          h: 1.10053,
          l: 1.10012,
          c: 1.10045,
          v: 221
        },
        {
          t: 33900,
          o: 1.10046,
          h: 1.10067,
          l: 1.10044,
          c: 1.10049,
          v: 293
        },
        {
          t: 34200,
          o: 1.10049,
          h: 1.10056,
          l: 1.10039,
          c: 1.10041,
          v: 225
        },
        {
          t: 34500,
          o: 1.1004,
          h: 1.10072,
          l: 1.10039,
          c: 1.10052,
          v: 160
        },
        {
          t: 34800,
          o: 1.10054,
          h: 1.10054,
          l: 1.09995,
          c: 1.09996,
          v: 213
        },
        {
          t: 35100,
          o: 1.09997,
          h: 1.10004,
          l: 1.09979,
          c: 1.09979,
          v: 204
        },
        {
          t: 35400,
          o: 1.09977,
          h: 1.09986,
          l: 1.09963,
          c: 1.09978,
          v: 227
        },
        {
          t: 35700,
          o: 1.0998,
          h: 1.0999,
          l: 1.09975,
          c: 1.09985,
          v: 178
        },
        {
          t: 36000,
          o: 1.09988,
          h: 1.10017,
          l: 1.09984,
          c: 1.10017,
          v: 253
        },
        {
          t: 36300,
          o: 1.10018,
          h: 1.10032,
          l: 1.10006,
          c: 1.10026,
          v: 173
        },
        {
          t: 36600,
          o: 1.10026,
          h: 1.10041,
          l: 1.10019,
          c: 1.10034,
          v: 151
        },
        {
          t: 36900,
          o: 1.10034,
          h: 1.1004,
          l: 1.10023,
          c: 1.1003,
          v: 141
        },
        {
          t: 37200,
          o: 1.1003,
          h: 1.10042,
          l: 1.10027,
          c: 1.10035,
          v: 112
        },
        {
          t: 37500,
          o: 1.10034,
          h: 1.10045,
          l: 1.10033,
          c: 1.10041,
          v: 83
        },
        {
          t: 37800,
          o: 1.10042,
          h: 1.10052,
          l: 1.10036,
          c: 1.1004,
          v: 101
        },
        {
          t: 38100,
          o: 1.1004,
          h: 1.10041,
          l: 1.10026,
          c: 1.10029,
          v: 123
        },
        {
          t: 38400,
          o: 1.1003,
          h: 1.10033,
          l: 1.10029,
          c: 1.10031,
          v: 56
        },
        {
          t: 38700,
          o: 1.1003,
          h: 1.1003,
          l: 1.10009,
          c: 1.10011,
          v: 87
        },
        {
          t: 39000,
          o: 1.10011,
          h: 1.10016,
          l: 1.10005,
          c: 1.10015,
          v: 101
        },
        {
          t: 39300,
          o: 1.10016,
          h: 1.10025,
          l: 1.10006,
          c: 1.10007,
          v: 132
        },
        {
          t: 39600,
          o: 1.10008,
          h: 1.10024,
          l: 1.10008,
          c: 1.10018,
          v: 125
        },
        {
          t: 39900,
          o: 1.10019,
          h: 1.10022,
          l: 1.10002,
          c: 1.10002,
          v: 91
        },
        {
          t: 40200,
          o: 1.10003,
          h: 1.10014,
          l: 1.09997,
          c: 1.09997,
          v: 105
        },
        {
          t: 40500,
          o: 1.09997,
          h: 1.10007,
          l: 1.09979,
          c: 1.10007,
          v: 157
        },
        {
          t: 40800,
          o: 1.10007,
          h: 1.10011,
          l: 1.09989,
          c: 1.0999,
          v: 105
        },
        {
          t: 41100,
          o: 1.09991,
          h: 1.09992,
          l: 1.09971,
          c: 1.09982,
          v: 101
        },
        {
          t: 41400,
          o: 1.09981,
          h: 1.09985,
          l: 1.09957,
          c: 1.09965,
          v: 118
        },
        {
          t: 41700,
          o: 1.09965,
          h: 1.09972,
          l: 1.0995,
          c: 1.09961,
          v: 99
        },
        {
          t: 42000,
          o: 1.09963,
          h: 1.09991,
          l: 1.09963,
          c: 1.09982,
          v: 104
        },
        {
          t: 42300,
          o: 1.09982,
          h: 1.09991,
          l: 1.09972,
          c: 1.09988,
          v: 122
        },
        {
          t: 42600,
          o: 1.09991,
          h: 1.10001,
          l: 1.09976,
          c: 1.10001,
          v: 119
        },
        {
          t: 42900,
          o: 1.09997,
          h: 1.1,
          l: 1.09985,
          c: 1.09998,
          v: 118
        },
        {
          t: 43200,
          o: 1.09998,
          h: 1.09998,
          l: 1.09977,
          c: 1.09986,
          v: 94
        },
        {
          t: 43500,
          o: 1.09985,
          h: 1.10007,
          l: 1.09982,
          c: 1.10003,
          v: 98
        },
        {
          t: 43800,
          o: 1.10006,
          h: 1.10007,
          l: 1.09982,
          c: 1.09991,
          v: 140
        },
        {
          t: 44100,
          o: 1.09992,
          h: 1.10007,
          l: 1.09992,
          c: 1.10005,
          v: 95
        },
        {
          t: 44400,
          o: 1.10005,
          h: 1.10013,
          l: 1.1,
          c: 1.10012,
          v: 113
        },
        {
          t: 44700,
          o: 1.10013,
          h: 1.10023,
          l: 1.1001,
          c: 1.10015,
          v: 70
        },
        {
          t: 45000,
          o: 1.10015,
          h: 1.10015,
          l: 1.09975,
          c: 1.09975,
          v: 117
        },
        {
          t: 45300,
          o: 1.09976,
          h: 1.0998,
          l: 1.09968,
          c: 1.09977,
          v: 104
        },
        {
          t: 45600,
          o: 1.09977,
          h: 1.10002,
          l: 1.09977,
          c: 1.10001,
          v: 115
        },
        {
          t: 45900,
          o: 1.10002,
          h: 1.10002,
          l: 1.09971,
          c: 1.09979,
          v: 124
        },
        {
          t: 46200,
          o: 1.09976,
          h: 1.09989,
          l: 1.09976,
          c: 1.09986,
          v: 89
        },
        {
          t: 46500,
          o: 1.09988,
          h: 1.1003,
          l: 1.09988,
          c: 1.10021,
          v: 203
        },
        {
          t: 46800,
          o: 1.10021,
          h: 1.10031,
          l: 1.10015,
          c: 1.1003,
          v: 183
        },
        {
          t: 47100,
          o: 1.1003,
          h: 1.10031,
          l: 1.10016,
          c: 1.1002,
          v: 123
        },
        {
          t: 47400,
          o: 1.1002,
          h: 1.10029,
          l: 1.10011,
          c: 1.10025,
          v: 142
        },
        {
          t: 47700,
          o: 1.10022,
          h: 1.10032,
          l: 1.10016,
          c: 1.10025,
          v: 126
        },
        {
          t: 48000,
          o: 1.10025,
          h: 1.10025,
          l: 1.10001,
          c: 1.10002,
          v: 172
        },
        {
          t: 48300,
          o: 1.10004,
          h: 1.10025,
          l: 1.1,
          c: 1.10021,
          v: 131
        },
        {
          t: 48600,
          o: 1.1002,
          h: 1.10033,
          l: 1.10015,
          c: 1.10016,
          v: 144
        },
        {
          t: 48900,
          o: 1.10011,
          h: 1.10027,
          l: 1.10006,
          c: 1.10015,
          v: 150
        },
        {
          t: 49200,
          o: 1.10012,
          h: 1.10016,
          l: 1.09983,
          c: 1.10001,
          v: 190
        },
        {
          t: 49500,
          o: 1.1,
          h: 1.10022,
          l: 1.0999,
          c: 1.10022,
          v: 150
        },
        {
          t: 49800,
          o: 1.1002,
          h: 1.10039,
          l: 1.10004,
          c: 1.10004,
          v: 162
        },
        {
          t: 50100,
          o: 1.10001,
          h: 1.10021,
          l: 1.09998,
          c: 1.1001,
          v: 192
        },
        {
          t: 50400,
          o: 1.10009,
          h: 1.10011,
          l: 1.09995,
          c: 1.10009,
          v: 143
        },
        {
          t: 50700,
          o: 1.10009,
          h: 1.10009,
          l: 1.0998,
          c: 1.09994,
          v: 153
        },
        {
          t: 51000,
          o: 1.09994,
          h: 1.10004,
          l: 1.09979,
          c: 1.09979,
          v: 131
        },
        {
          t: 51300,
          o: 1.0998,
          h: 1.09992,
          l: 1.09971,
          c: 1.09984,
          v: 123
        },
        {
          t: 51600,
          o: 1.09983,
          h: 1.09987,
          l: 1.09965,
          c: 1.09969,
          v: 160
        },
        {
          t: 51900,
          o: 1.09969,
          h: 1.09973,
          l: 1.09948,
          c: 1.09956,
          v: 152
        },
        {
          t: 52200,
          o: 1.09957,
          h: 1.09958,
          l: 1.09936,
          c: 1.09937,
          v: 232
        },
        {
          t: 52500,
          o: 1.09937,
          h: 1.09959,
          l: 1.09927,
          c: 1.09953,
          v: 284
        },
        {
          t: 52800,
          o: 1.09954,
          h: 1.09997,
          l: 1.09953,
          c: 1.09986,
          v: 296
        },
        {
          t: 53100,
          o: 1.09986,
          h: 1.09988,
          l: 1.0997,
          c: 1.09979,
          v: 270
        },
        {
          t: 53400,
          o: 1.09979,
          h: 1.09979,
          l: 1.09953,
          c: 1.09966,
          v: 220
        },
        {
          t: 53700,
          o: 1.09967,
          h: 1.09968,
          l: 1.09878,
          c: 1.09878,
          v: 209
        },
        {
          t: 54000,
          o: 1.09878,
          h: 1.09906,
          l: 1.09858,
          c: 1.09884,
          v: 321
        },
        {
          t: 54300,
          o: 1.09885,
          h: 1.09885,
          l: 1.09858,
          c: 1.09865,
          v: 259
        },
        {
          t: 54600,
          o: 1.09864,
          h: 1.09872,
          l: 1.09824,
          c: 1.09824,
          v: 253
        },
        {
          t: 54900,
          o: 1.09828,
          h: 1.09847,
          l: 1.09813,
          c: 1.09834,
          v: 357
        },
        {
          t: 55200,
          o: 1.09834,
          h: 1.09845,
          l: 1.09823,
          c: 1.09831,
          v: 233
        },
        {
          t: 55500,
          o: 1.0983,
          h: 1.0984,
          l: 1.09774,
          c: 1.09782,
          v: 370
        },
        {
          t: 55800,
          o: 1.09778,
          h: 1.09785,
          l: 1.09717,
          c: 1.09746,
          v: 452
        },
        {
          t: 56100,
          o: 1.09742,
          h: 1.09777,
          l: 1.09725,
          c: 1.09777,
          v: 254
        },
        {
          t: 56400,
          o: 1.09774,
          h: 1.09782,
          l: 1.09759,
          c: 1.0976,
          v: 271
        },
        {
          t: 56700,
          o: 1.09761,
          h: 1.09762,
          l: 1.09696,
          c: 1.09696,
          v: 387
        },
        {
          t: 57000,
          o: 1.09697,
          h: 1.09707,
          l: 1.09639,
          c: 1.0965,
          v: 436
        },
        {
          t: 57300,
          o: 1.09652,
          h: 1.09781,
          l: 1.09641,
          c: 1.09761,
          v: 567
        },
        {
          t: 57600,
          o: 1.09762,
          h: 1.09822,
          l: 1.09749,
          c: 1.09818,
          v: 362
        },
        {
          t: 57900,
          o: 1.09819,
          h: 1.09819,
          l: 1.09741,
          c: 1.09746,
          v: 288
        },
        {
          t: 58200,
          o: 1.09743,
          h: 1.09782,
          l: 1.09727,
          c: 1.09768,
          v: 280
        },
        {
          t: 58500,
          o: 1.09765,
          h: 1.09767,
          l: 1.09726,
          c: 1.09727,
          v: 312
        },
        {
          t: 58800,
          o: 1.09729,
          h: 1.09773,
          l: 1.09725,
          c: 1.09773,
          v: 246
        },
        {
          t: 59100,
          o: 1.09775,
          h: 1.09822,
          l: 1.09771,
          c: 1.09822,
          v: 241
        },
        {
          t: 59400,
          o: 1.09818,
          h: 1.09828,
          l: 1.09798,
          c: 1.09814,
          v: 298
        },
        {
          t: 59700,
          o: 1.09817,
          h: 1.09832,
          l: 1.098,
          c: 1.09809,
          v: 233
        },
        {
          t: 60000,
          o: 1.09813,
          h: 1.09818,
          l: 1.09783,
          c: 1.09788,
          v: 227
        },
        {
          t: 60300,
          o: 1.09789,
          h: 1.09793,
          l: 1.0976,
          c: 1.09763,
          v: 152
        },
        {
          t: 60600,
          o: 1.09762,
          h: 1.09777,
          l: 1.09747,
          c: 1.09747,
          v: 144
        },
        {
          t: 60900,
          o: 1.09747,
          h: 1.09775,
          l: 1.0974,
          c: 1.09773,
          v: 202
        },
        {
          t: 61200,
          o: 1.09771,
          h: 1.09785,
          l: 1.0975,
          c: 1.09759,
          v: 231
        },
        {
          t: 61500,
          o: 1.09757,
          h: 1.09782,
          l: 1.09756,
          c: 1.09779,
          v: 161
        },
        {
          t: 61800,
          o: 1.09778,
          h: 1.09794,
          l: 1.09771,
          c: 1.09773,
          v: 136
        },
        {
          t: 62100,
          o: 1.09774,
          h: 1.09798,
          l: 1.09765,
          c: 1.09782,
          v: 197
        },
        {
          t: 62400,
          o: 1.09782,
          h: 1.09798,
          l: 1.09778,
          c: 1.09789,
          v: 144
        },
        {
          t: 62700,
          o: 1.09788,
          h: 1.09796,
          l: 1.09787,
          c: 1.09793,
          v: 109
        },
        {
          t: 63000,
          o: 1.09793,
          h: 1.09798,
          l: 1.09777,
          c: 1.09781,
          v: 124
        },
        {
          t: 63300,
          o: 1.0978,
          h: 1.09783,
          l: 1.09759,
          c: 1.09768,
          v: 100
        },
        {
          t: 63600,
          o: 1.09769,
          h: 1.09785,
          l: 1.09762,
          c: 1.09782,
          v: 94
        },
        {
          t: 63900,
          o: 1.09782,
          h: 1.09785,
          l: 1.09768,
          c: 1.09772,
          v: 126
        },
        {
          t: 64200,
          o: 1.09773,
          h: 1.0978,
          l: 1.0977,
          c: 1.09774,
          v: 88
        },
        {
          t: 64500,
          o: 1.09773,
          h: 1.09773,
          l: 1.0974,
          c: 1.09745,
          v: 184
        },
        {
          t: 64800,
          o: 1.09745,
          h: 1.09758,
          l: 1.0974,
          c: 1.09746,
          v: 141
        },
        {
          t: 65100,
          o: 1.09745,
          h: 1.09745,
          l: 1.09731,
          c: 1.09737,
          v: 95
        },
        {
          t: 65400,
          o: 1.09738,
          h: 1.0974,
          l: 1.09716,
          c: 1.09729,
          v: 99
        },
        {
          t: 65700,
          o: 1.09731,
          h: 1.09743,
          l: 1.0973,
          c: 1.09741,
          v: 93
        },
        {
          t: 66000,
          o: 1.09743,
          h: 1.0976,
          l: 1.09742,
          c: 1.09759,
          v: 67
        },
        {
          t: 66300,
          o: 1.09759,
          h: 1.09764,
          l: 1.09753,
          c: 1.09758,
          v: 72
        },
        {
          t: 66600,
          o: 1.09758,
          h: 1.09763,
          l: 1.09751,
          c: 1.09758,
          v: 100
        },
        {
          t: 66900,
          o: 1.09757,
          h: 1.09763,
          l: 1.09755,
          c: 1.09763,
          v: 70
        },
        {
          t: 67200,
          o: 1.09761,
          h: 1.09768,
          l: 1.09757,
          c: 1.09762,
          v: 88
        },
        {
          t: 67500,
          o: 1.09763,
          h: 1.09763,
          l: 1.09748,
          c: 1.09752,
          v: 76
        },
        {
          t: 67800,
          o: 1.09751,
          h: 1.09751,
          l: 1.09725,
          c: 1.09726,
          v: 87
        },
        {
          t: 68100,
          o: 1.09725,
          h: 1.09726,
          l: 1.09713,
          c: 1.09726,
          v: 87
        },
        {
          t: 68400,
          o: 1.09728,
          h: 1.09733,
          l: 1.09716,
          c: 1.09716,
          v: 102
        },
        {
          t: 68700,
          o: 1.09717,
          h: 1.09722,
          l: 1.09703,
          c: 1.09714,
          v: 122
        },
        {
          t: 69000,
          o: 1.09712,
          h: 1.09722,
          l: 1.0971,
          c: 1.09715,
          v: 114
        },
        {
          t: 69300,
          o: 1.09717,
          h: 1.09727,
          l: 1.09712,
          c: 1.09725,
          v: 108
        },
        {
          t: 69600,
          o: 1.09723,
          h: 1.09734,
          l: 1.09723,
          c: 1.09727,
          v: 97
        },
        {
          t: 69900,
          o: 1.09726,
          h: 1.09737,
          l: 1.09726,
          c: 1.09736,
          v: 94
        },
        {
          t: 70200,
          o: 1.09736,
          h: 1.09736,
          l: 1.09726,
          c: 1.09734,
          v: 107
        },
        {
          t: 70500,
          o: 1.09733,
          h: 1.09755,
          l: 1.09733,
          c: 1.09747,
          v: 65
        },
        {
          t: 70800,
          o: 1.09747,
          h: 1.09747,
          l: 1.09739,
          c: 1.09745,
          v: 70
        },
        {
          t: 71100,
          o: 1.09748,
          h: 1.09754,
          l: 1.09742,
          c: 1.09744,
          v: 72
        },
        {
          t: 71400,
          o: 1.09744,
          h: 1.09746,
          l: 1.09738,
          c: 1.09744,
          v: 56
        },
        {
          t: 71700,
          o: 1.09744,
          h: 1.09769,
          l: 1.09742,
          c: 1.09763,
          v: 129
        },
        {
          t: 72000,
          o: 1.09764,
          h: 1.09786,
          l: 1.09761,
          c: 1.09785,
          v: 134
        },
        {
          t: 72300,
          o: 1.09784,
          h: 1.09795,
          l: 1.09779,
          c: 1.09794,
          v: 68
        },
        {
          t: 72600,
          o: 1.09793,
          h: 1.09811,
          l: 1.09788,
          c: 1.09805,
          v: 97
        },
        {
          t: 72900,
          o: 1.09802,
          h: 1.09813,
          l: 1.09796,
          c: 1.09809,
          v: 91
        },
        {
          t: 73200,
          o: 1.09808,
          h: 1.09818,
          l: 1.09808,
          c: 1.09813,
          v: 69
        },
        {
          t: 73500,
          o: 1.09808,
          h: 1.09816,
          l: 1.09806,
          c: 1.09808,
          v: 74
        },
        {
          t: 73800,
          o: 1.09806,
          h: 1.09819,
          l: 1.09803,
          c: 1.09807,
          v: 111
        },
        {
          t: 74100,
          o: 1.09805,
          h: 1.09814,
          l: 1.09805,
          c: 1.09812,
          v: 66
        },
        {
          t: 74400,
          o: 1.09811,
          h: 1.09816,
          l: 1.09792,
          c: 1.09792,
          v: 106
        },
        {
          t: 74700,
          o: 1.09793,
          h: 1.09804,
          l: 1.09791,
          c: 1.09793,
          v: 118
        },
        {
          t: 75000,
          o: 1.0979,
          h: 1.09791,
          l: 1.09773,
          c: 1.09777,
          v: 115
        },
        {
          t: 75300,
          o: 1.09777,
          h: 1.09785,
          l: 1.09762,
          c: 1.09783,
          v: 170
        },
        {
          t: 75600,
          o: 1.09782,
          h: 1.09787,
          l: 1.09781,
          c: 1.09783,
          v: 87
        },
        {
          t: 75900,
          o: 1.09782,
          h: 1.09808,
          l: 1.09782,
          c: 1.09806,
          v: 75
        },
        {
          t: 76200,
          o: 1.09805,
          h: 1.09816,
          l: 1.09803,
          c: 1.09809,
          v: 46
        },
        {
          t: 76500,
          o: 1.09808,
          h: 1.0981,
          l: 1.09802,
          c: 1.09809,
          v: 41
        },
        {
          t: 76800,
          o: 1.09809,
          h: 1.09814,
          l: 1.09809,
          c: 1.09813,
          v: 25
        },
        {
          t: 77100,
          o: 1.09812,
          h: 1.09814,
          l: 1.09808,
          c: 1.09808,
          v: 20
        },
        {
          t: 77400,
          o: 1.09807,
          h: 1.09808,
          l: 1.09797,
          c: 1.09798,
          v: 47
        },
        {
          t: 77700,
          o: 1.09797,
          h: 1.098,
          l: 1.09792,
          c: 1.09799,
          v: 29
        },
        {
          t: 78000,
          o: 1.09799,
          h: 1.09806,
          l: 1.09799,
          c: 1.09803,
          v: 19
        },
        {
          t: 78300,
          o: 1.09804,
          h: 1.09807,
          l: 1.09799,
          c: 1.09805,
          v: 37
        },
        {
          t: 78600,
          o: 1.09804,
          h: 1.09805,
          l: 1.098,
          c: 1.09802,
          v: 59
        },
        {
          t: 78900,
          o: 1.09804,
          h: 1.09825,
          l: 1.09798,
          c: 1.09824,
          v: 93
        },
        {
          t: 79200,
          o: 1.09824,
          h: 1.09826,
          l: 1.09768,
          c: 1.09815,
          v: 101
        },
        {
          t: 79500,
          o: 1.09815,
          h: 1.09826,
          l: 1.09797,
          c: 1.09797,
          v: 58
        },
        {
          t: 79800,
          o: 1.09797,
          h: 1.09797,
          l: 1.09797,
          c: 1.09797,
          v: 160
        },
        {
          t: 80100,
          o: 1.09793,
          h: 1.09794,
          l: 1.09793,
          c: 1.09793,
          v: 35
        },
        {
          t: 80400,
          o: 1.09793,
          h: 1.09801,
          l: 1.09793,
          c: 1.09797,
          v: 73
        },
        {
          t: 80700,
          o: 1.09799,
          h: 1.09802,
          l: 1.09793,
          c: 1.09793,
          v: 55
        },
        {
          t: 81000,
          o: 1.09793,
          h: 1.09794,
          l: 1.09793,
          c: 1.09794,
          v: 36
        },
        {
          t: 81300,
          o: 1.09794,
          h: 1.09794,
          l: 1.09788,
          c: 1.09794,
          v: 13
        },
        {
          t: 81600,
          o: 1.09795,
          h: 1.09795,
          l: 1.09794,
          c: 1.09794,
          v: 2
        },
        {
          t: 81900,
          o: 1.09794,
          h: 1.09794,
          l: 1.09793,
          c: 1.09793,
          v: 5
        },
        {
          t: 82200,
          o: 1.09795,
          h: 1.09801,
          l: 1.09795,
          c: 1.09801,
          v: 9
        },
        {
          t: 82500,
          o: 1.098,
          h: 1.09803,
          l: 1.09799,
          c: 1.09803,
          v: 21
        },
        {
          t: 82800,
          o: 1.09803,
          h: 1.09807,
          l: 1.09793,
          c: 1.09796,
          v: 116
        },
        {
          t: 83100,
          o: 1.09796,
          h: 1.09796,
          l: 1.09793,
          c: 1.09794,
          v: 29
        },
        {
          t: 83400,
          o: 1.09794,
          h: 1.09802,
          l: 1.09793,
          c: 1.09802,
          v: 22
        },
        {
          t: 83700,
          o: 1.09803,
          h: 1.09804,
          l: 1.09803,
          c: 1.09803,
          v: 13
        },
        {
          t: 84000,
          o: 1.09804,
          h: 1.09804,
          l: 1.09802,
          c: 1.09803,
          v: 23
        },
        {
          t: 84300,
          o: 1.09802,
          h: 1.09804,
          l: 1.09802,
          c: 1.09803,
          v: 18
        },
        {
          t: 84600,
          o: 1.09807,
          h: 1.0981,
          l: 1.09803,
          c: 1.09808,
          v: 33
        },
        {
          t: 84900,
          o: 1.09807,
          h: 1.09808,
          l: 1.09798,
          c: 1.09803,
          v: 21
        },
        {
          t: 85200,
          o: 1.09804,
          h: 1.09804,
          l: 1.09797,
          c: 1.09801,
          v: 24
        },
        {
          t: 85500,
          o: 1.09798,
          h: 1.09803,
          l: 1.09795,
          c: 1.09803,
          v: 33
        },
        {
          t: 85800,
          o: 1.09804,
          h: 1.09805,
          l: 1.09802,
          c: 1.09803,
          v: 26
        },
        {
          t: 86100,
          o: 1.09804,
          h: 1.09804,
          l: 1.09803,
          c: 1.09803,
          v: 11
        }
      ]
    },
    {
      d: 1581033600,
      b: [
        {
          t: 0,
          o: 1.09802,
          h: 1.09804,
          l: 1.09773,
          c: 1.09777,
          v: 99
        },
        {
          t: 300,
          o: 1.09777,
          h: 1.09779,
          l: 1.09775,
          c: 1.09778,
          v: 33
        },
        {
          t: 600,
          o: 1.09777,
          h: 1.09783,
          l: 1.09777,
          c: 1.09783,
          v: 28
        },
        {
          t: 900,
          o: 1.09786,
          h: 1.09788,
          l: 1.09777,
          c: 1.09777,
          v: 29
        },
        {
          t: 1200,
          o: 1.09777,
          h: 1.09785,
          l: 1.09777,
          c: 1.09783,
          v: 41
        },
        {
          t: 1500,
          o: 1.09785,
          h: 1.09791,
          l: 1.09781,
          c: 1.09782,
          v: 33
        },
        {
          t: 1800,
          o: 1.09781,
          h: 1.09786,
          l: 1.09779,
          c: 1.09782,
          v: 48
        },
        {
          t: 2100,
          o: 1.09782,
          h: 1.09783,
          l: 1.09777,
          c: 1.09779,
          v: 72
        },
        {
          t: 2400,
          o: 1.09778,
          h: 1.09792,
          l: 1.09778,
          c: 1.09792,
          v: 67
        },
        {
          t: 2700,
          o: 1.09792,
          h: 1.09811,
          l: 1.09792,
          c: 1.09808,
          v: 59
        },
        {
          t: 3000,
          o: 1.09809,
          h: 1.09809,
          l: 1.09791,
          c: 1.09801,
          v: 90
        },
        {
          t: 3300,
          o: 1.09797,
          h: 1.09812,
          l: 1.09791,
          c: 1.09811,
          v: 72
        },
        {
          t: 3600,
          o: 1.09809,
          h: 1.0981,
          l: 1.09797,
          c: 1.09808,
          v: 61
        },
        {
          t: 3900,
          o: 1.0981,
          h: 1.09815,
          l: 1.09807,
          c: 1.09807,
          v: 211
        },
        {
          t: 4200,
          o: 1.09808,
          h: 1.09821,
          l: 1.09808,
          c: 1.09814,
          v: 199
        },
        {
          t: 4500,
          o: 1.09813,
          h: 1.0982,
          l: 1.09813,
          c: 1.09818,
          v: 202
        },
        {
          t: 4800,
          o: 1.09817,
          h: 1.09821,
          l: 1.09812,
          c: 1.0982,
          v: 185
        },
        {
          t: 5100,
          o: 1.09819,
          h: 1.09822,
          l: 1.09813,
          c: 1.09818,
          v: 206
        },
        {
          t: 5400,
          o: 1.09819,
          h: 1.09821,
          l: 1.09812,
          c: 1.0982,
          v: 153
        },
        {
          t: 5700,
          o: 1.09819,
          h: 1.09823,
          l: 1.09818,
          c: 1.0982,
          v: 180
        },
        {
          t: 6000,
          o: 1.09819,
          h: 1.09827,
          l: 1.09818,
          c: 1.09825,
          v: 200
        },
        {
          t: 6300,
          o: 1.09824,
          h: 1.09826,
          l: 1.09821,
          c: 1.09821,
          v: 125
        },
        {
          t: 6600,
          o: 1.0982,
          h: 1.09821,
          l: 1.09815,
          c: 1.09819,
          v: 171
        },
        {
          t: 6900,
          o: 1.0982,
          h: 1.09827,
          l: 1.09817,
          c: 1.09826,
          v: 200
        },
        {
          t: 7200,
          o: 1.09826,
          h: 1.09827,
          l: 1.09816,
          c: 1.0982,
          v: 194
        },
        {
          t: 7500,
          o: 1.0982,
          h: 1.09823,
          l: 1.09818,
          c: 1.0982,
          v: 103
        },
        {
          t: 7800,
          o: 1.09821,
          h: 1.09827,
          l: 1.09817,
          c: 1.09819,
          v: 90
        },
        {
          t: 8100,
          o: 1.09819,
          h: 1.09821,
          l: 1.09813,
          c: 1.09815,
          v: 130
        },
        {
          t: 8400,
          o: 1.09815,
          h: 1.09824,
          l: 1.09813,
          c: 1.09823,
          v: 109
        },
        {
          t: 8700,
          o: 1.09822,
          h: 1.09831,
          l: 1.09822,
          c: 1.09828,
          v: 174
        },
        {
          t: 9000,
          o: 1.09828,
          h: 1.09835,
          l: 1.09824,
          c: 1.09829,
          v: 153
        },
        {
          t: 9300,
          o: 1.09828,
          h: 1.09831,
          l: 1.09826,
          c: 1.09829,
          v: 168
        },
        {
          t: 9600,
          o: 1.09828,
          h: 1.09831,
          l: 1.09826,
          c: 1.0983,
          v: 112
        },
        {
          t: 9900,
          o: 1.0983,
          h: 1.09837,
          l: 1.09829,
          c: 1.09835,
          v: 100
        },
        {
          t: 10200,
          o: 1.09835,
          h: 1.09836,
          l: 1.09827,
          c: 1.0983,
          v: 154
        },
        {
          t: 10500,
          o: 1.09831,
          h: 1.09842,
          l: 1.09828,
          c: 1.09841,
          v: 202
        },
        {
          t: 10800,
          o: 1.0984,
          h: 1.09841,
          l: 1.09831,
          c: 1.09838,
          v: 114
        },
        {
          t: 11100,
          o: 1.09838,
          h: 1.09838,
          l: 1.09828,
          c: 1.09829,
          v: 107
        },
        {
          t: 11400,
          o: 1.09827,
          h: 1.09831,
          l: 1.09822,
          c: 1.09828,
          v: 97
        },
        {
          t: 11700,
          o: 1.09829,
          h: 1.09831,
          l: 1.09823,
          c: 1.09829,
          v: 106
        },
        {
          t: 12000,
          o: 1.0983,
          h: 1.09845,
          l: 1.0983,
          c: 1.09839,
          v: 122
        },
        {
          t: 12300,
          o: 1.09838,
          h: 1.09838,
          l: 1.09829,
          c: 1.09838,
          v: 101
        },
        {
          t: 12600,
          o: 1.09836,
          h: 1.09841,
          l: 1.09835,
          c: 1.09838,
          v: 126
        },
        {
          t: 12900,
          o: 1.09838,
          h: 1.09845,
          l: 1.09837,
          c: 1.09844,
          v: 92
        },
        {
          t: 13200,
          o: 1.09843,
          h: 1.09844,
          l: 1.0983,
          c: 1.09832,
          v: 102
        },
        {
          t: 13500,
          o: 1.09832,
          h: 1.09835,
          l: 1.09829,
          c: 1.09831,
          v: 98
        },
        {
          t: 13800,
          o: 1.09829,
          h: 1.09831,
          l: 1.09822,
          c: 1.09823,
          v: 57
        },
        {
          t: 14100,
          o: 1.09823,
          h: 1.09828,
          l: 1.0982,
          c: 1.09827,
          v: 110
        },
        {
          t: 14400,
          o: 1.09827,
          h: 1.09828,
          l: 1.09813,
          c: 1.09817,
          v: 150
        },
        {
          t: 14700,
          o: 1.09817,
          h: 1.09825,
          l: 1.09817,
          c: 1.09819,
          v: 128
        },
        {
          t: 15000,
          o: 1.09819,
          h: 1.0982,
          l: 1.09813,
          c: 1.09813,
          v: 99
        },
        {
          t: 15300,
          o: 1.09814,
          h: 1.09818,
          l: 1.09809,
          c: 1.09814,
          v: 65
        },
        {
          t: 15600,
          o: 1.09813,
          h: 1.09817,
          l: 1.09812,
          c: 1.09813,
          v: 57
        },
        {
          t: 15900,
          o: 1.09813,
          h: 1.09815,
          l: 1.09807,
          c: 1.0981,
          v: 86
        },
        {
          t: 16200,
          o: 1.09811,
          h: 1.09812,
          l: 1.09806,
          c: 1.09808,
          v: 66
        },
        {
          t: 16500,
          o: 1.09808,
          h: 1.09809,
          l: 1.09798,
          c: 1.09798,
          v: 92
        },
        {
          t: 16800,
          o: 1.09797,
          h: 1.09799,
          l: 1.09791,
          c: 1.09794,
          v: 121
        },
        {
          t: 17100,
          o: 1.09793,
          h: 1.09801,
          l: 1.09788,
          c: 1.09789,
          v: 94
        },
        {
          t: 17400,
          o: 1.09792,
          h: 1.09799,
          l: 1.09788,
          c: 1.09795,
          v: 167
        },
        {
          t: 17700,
          o: 1.09794,
          h: 1.09811,
          l: 1.09792,
          c: 1.09809,
          v: 130
        },
        {
          t: 18000,
          o: 1.09808,
          h: 1.09817,
          l: 1.09805,
          c: 1.09809,
          v: 134
        },
        {
          t: 18300,
          o: 1.0981,
          h: 1.09822,
          l: 1.09809,
          c: 1.09822,
          v: 86
        },
        {
          t: 18600,
          o: 1.09821,
          h: 1.09823,
          l: 1.09818,
          c: 1.09819,
          v: 76
        },
        {
          t: 18900,
          o: 1.09819,
          h: 1.0982,
          l: 1.09808,
          c: 1.0981,
          v: 101
        },
        {
          t: 19200,
          o: 1.09809,
          h: 1.09818,
          l: 1.09809,
          c: 1.09814,
          v: 80
        },
        {
          t: 19500,
          o: 1.09815,
          h: 1.0982,
          l: 1.09814,
          c: 1.09819,
          v: 90
        },
        {
          t: 19800,
          o: 1.0982,
          h: 1.09829,
          l: 1.09818,
          c: 1.09819,
          v: 115
        },
        {
          t: 20100,
          o: 1.09818,
          h: 1.0982,
          l: 1.09817,
          c: 1.09819,
          v: 100
        },
        {
          t: 20400,
          o: 1.09819,
          h: 1.09819,
          l: 1.09813,
          c: 1.09813,
          v: 95
        },
        {
          t: 20700,
          o: 1.09812,
          h: 1.09812,
          l: 1.09807,
          c: 1.09808,
          v: 106
        },
        {
          t: 21000,
          o: 1.0981,
          h: 1.09815,
          l: 1.09806,
          c: 1.09806,
          v: 78
        },
        {
          t: 21300,
          o: 1.09806,
          h: 1.09806,
          l: 1.098,
          c: 1.09803,
          v: 107
        },
        {
          t: 21600,
          o: 1.09804,
          h: 1.0982,
          l: 1.09804,
          c: 1.09814,
          v: 186
        },
        {
          t: 21900,
          o: 1.09813,
          h: 1.09813,
          l: 1.09806,
          c: 1.09809,
          v: 175
        },
        {
          t: 22200,
          o: 1.09808,
          h: 1.09813,
          l: 1.09801,
          c: 1.09805,
          v: 95
        },
        {
          t: 22500,
          o: 1.09803,
          h: 1.09804,
          l: 1.09798,
          c: 1.09798,
          v: 70
        },
        {
          t: 22800,
          o: 1.09799,
          h: 1.09799,
          l: 1.09777,
          c: 1.09781,
          v: 148
        },
        {
          t: 23100,
          o: 1.0978,
          h: 1.09797,
          l: 1.09779,
          c: 1.0979,
          v: 208
        },
        {
          t: 23400,
          o: 1.0979,
          h: 1.09792,
          l: 1.09777,
          c: 1.09785,
          v: 223
        },
        {
          t: 23700,
          o: 1.09788,
          h: 1.09801,
          l: 1.09785,
          c: 1.09799,
          v: 97
        },
        {
          t: 24000,
          o: 1.098,
          h: 1.098,
          l: 1.09786,
          c: 1.09788,
          v: 144
        },
        {
          t: 24300,
          o: 1.09788,
          h: 1.09793,
          l: 1.09774,
          c: 1.09774,
          v: 139
        },
        {
          t: 24600,
          o: 1.09774,
          h: 1.09783,
          l: 1.09769,
          c: 1.09773,
          v: 118
        },
        {
          t: 24900,
          o: 1.09773,
          h: 1.09783,
          l: 1.09768,
          c: 1.09772,
          v: 136
        },
        {
          t: 25200,
          o: 1.09775,
          h: 1.09779,
          l: 1.09746,
          c: 1.09752,
          v: 351
        },
        {
          t: 25500,
          o: 1.09752,
          h: 1.09753,
          l: 1.09723,
          c: 1.09726,
          v: 258
        },
        {
          t: 25800,
          o: 1.09728,
          h: 1.0973,
          l: 1.09707,
          c: 1.09724,
          v: 222
        },
        {
          t: 26100,
          o: 1.09725,
          h: 1.09727,
          l: 1.0971,
          c: 1.09715,
          v: 223
        },
        {
          t: 26400,
          o: 1.09715,
          h: 1.0972,
          l: 1.09681,
          c: 1.09691,
          v: 274
        },
        {
          t: 26700,
          o: 1.09693,
          h: 1.09728,
          l: 1.09692,
          c: 1.09725,
          v: 264
        },
        {
          t: 27000,
          o: 1.09725,
          h: 1.09738,
          l: 1.09718,
          c: 1.09731,
          v: 279
        },
        {
          t: 27300,
          o: 1.09731,
          h: 1.09745,
          l: 1.09725,
          c: 1.09741,
          v: 256
        },
        {
          t: 27600,
          o: 1.09742,
          h: 1.09753,
          l: 1.09736,
          c: 1.0975,
          v: 287
        },
        {
          t: 27900,
          o: 1.0975,
          h: 1.0975,
          l: 1.09707,
          c: 1.09709,
          v: 247
        },
        {
          t: 28200,
          o: 1.0971,
          h: 1.0971,
          l: 1.09673,
          c: 1.09676,
          v: 289
        },
        {
          t: 28500,
          o: 1.09675,
          h: 1.09691,
          l: 1.09662,
          c: 1.09663,
          v: 259
        },
        {
          t: 28800,
          o: 1.09663,
          h: 1.09683,
          l: 1.09656,
          c: 1.09663,
          v: 413
        },
        {
          t: 29100,
          o: 1.09664,
          h: 1.09665,
          l: 1.09629,
          c: 1.09629,
          v: 432
        },
        {
          t: 29400,
          o: 1.0963,
          h: 1.09675,
          l: 1.09622,
          c: 1.09674,
          v: 295
        },
        {
          t: 29700,
          o: 1.09675,
          h: 1.09699,
          l: 1.09673,
          c: 1.0968,
          v: 270
        },
        {
          t: 30000,
          o: 1.09676,
          h: 1.09705,
          l: 1.0967,
          c: 1.09698,
          v: 257
        },
        {
          t: 30300,
          o: 1.09697,
          h: 1.09698,
          l: 1.0968,
          c: 1.09681,
          v: 218
        },
        {
          t: 30600,
          o: 1.09681,
          h: 1.09682,
          l: 1.09634,
          c: 1.09645,
          v: 277
        },
        {
          t: 30900,
          o: 1.09645,
          h: 1.09667,
          l: 1.09639,
          c: 1.09658,
          v: 290
        },
        {
          t: 31200,
          o: 1.09659,
          h: 1.09665,
          l: 1.0965,
          c: 1.09663,
          v: 110
        },
        {
          t: 31500,
          o: 1.09665,
          h: 1.09677,
          l: 1.09657,
          c: 1.09668,
          v: 129
        },
        {
          t: 31800,
          o: 1.09668,
          h: 1.09678,
          l: 1.09666,
          c: 1.09672,
          v: 123
        },
        {
          t: 32100,
          o: 1.09673,
          h: 1.09673,
          l: 1.09562,
          c: 1.09578,
          v: 209
        },
        {
          t: 32400,
          o: 1.09578,
          h: 1.0963,
          l: 1.09578,
          c: 1.09629,
          v: 253
        },
        {
          t: 32700,
          o: 1.0963,
          h: 1.0963,
          l: 1.09612,
          c: 1.09614,
          v: 133
        },
        {
          t: 33000,
          o: 1.09616,
          h: 1.09637,
          l: 1.09608,
          c: 1.09626,
          v: 185
        },
        {
          t: 33300,
          o: 1.09623,
          h: 1.0964,
          l: 1.09623,
          c: 1.09639,
          v: 97
        },
        {
          t: 33600,
          o: 1.0964,
          h: 1.09643,
          l: 1.09617,
          c: 1.09624,
          v: 98
        },
        {
          t: 33900,
          o: 1.09624,
          h: 1.09629,
          l: 1.0959,
          c: 1.09593,
          v: 191
        },
        {
          t: 34200,
          o: 1.09593,
          h: 1.09625,
          l: 1.09586,
          c: 1.09594,
          v: 235
        },
        {
          t: 34500,
          o: 1.09595,
          h: 1.09598,
          l: 1.09581,
          c: 1.09589,
          v: 209
        },
        {
          t: 34800,
          o: 1.09589,
          h: 1.09618,
          l: 1.09586,
          c: 1.09608,
          v: 245
        },
        {
          t: 35100,
          o: 1.09605,
          h: 1.09614,
          l: 1.09577,
          c: 1.09582,
          v: 194
        },
        {
          t: 35400,
          o: 1.09583,
          h: 1.0959,
          l: 1.09552,
          c: 1.09563,
          v: 191
        },
        {
          t: 35700,
          o: 1.09563,
          h: 1.09569,
          l: 1.0953,
          c: 1.09539,
          v: 232
        },
        {
          t: 36000,
          o: 1.09538,
          h: 1.09558,
          l: 1.09538,
          c: 1.09557,
          v: 170
        },
        {
          t: 36300,
          o: 1.09558,
          h: 1.09563,
          l: 1.09538,
          c: 1.09543,
          v: 153
        },
        {
          t: 36600,
          o: 1.0954,
          h: 1.09558,
          l: 1.09501,
          c: 1.09502,
          v: 293
        },
        {
          t: 36900,
          o: 1.09502,
          h: 1.09528,
          l: 1.09502,
          c: 1.09518,
          v: 228
        },
        {
          t: 37200,
          o: 1.09517,
          h: 1.09543,
          l: 1.09511,
          c: 1.09519,
          v: 209
        },
        {
          t: 37500,
          o: 1.09518,
          h: 1.09519,
          l: 1.09474,
          c: 1.09483,
          v: 234
        },
        {
          t: 37800,
          o: 1.09487,
          h: 1.09523,
          l: 1.09473,
          c: 1.09522,
          v: 204
        },
        {
          t: 38100,
          o: 1.09521,
          h: 1.09524,
          l: 1.09504,
          c: 1.09514,
          v: 205
        },
        {
          t: 38400,
          o: 1.09514,
          h: 1.09523,
          l: 1.09488,
          c: 1.09518,
          v: 166
        },
        {
          t: 38700,
          o: 1.09517,
          h: 1.0953,
          l: 1.09509,
          c: 1.09519,
          v: 178
        },
        {
          t: 39000,
          o: 1.0952,
          h: 1.09524,
          l: 1.09509,
          c: 1.0951,
          v: 158
        },
        {
          t: 39300,
          o: 1.09508,
          h: 1.09538,
          l: 1.09505,
          c: 1.09535,
          v: 142
        },
        {
          t: 39600,
          o: 1.09535,
          h: 1.09535,
          l: 1.09501,
          c: 1.09514,
          v: 142
        },
        {
          t: 39900,
          o: 1.09517,
          h: 1.09535,
          l: 1.09513,
          c: 1.09523,
          v: 106
        },
        {
          t: 40200,
          o: 1.09524,
          h: 1.09535,
          l: 1.0952,
          c: 1.09534,
          v: 110
        },
        {
          t: 40500,
          o: 1.09535,
          h: 1.09548,
          l: 1.09523,
          c: 1.09548,
          v: 151
        },
        {
          t: 40800,
          o: 1.09546,
          h: 1.09561,
          l: 1.09545,
          c: 1.09559,
          v: 166
        },
        {
          t: 41100,
          o: 1.0956,
          h: 1.0957,
          l: 1.09558,
          c: 1.09568,
          v: 123
        },
        {
          t: 41400,
          o: 1.09568,
          h: 1.09574,
          l: 1.09561,
          c: 1.09572,
          v: 108
        },
        {
          t: 41700,
          o: 1.09572,
          h: 1.09573,
          l: 1.09547,
          c: 1.09556,
          v: 125
        },
        {
          t: 42000,
          o: 1.09556,
          h: 1.09568,
          l: 1.09539,
          c: 1.09567,
          v: 131
        },
        {
          t: 42300,
          o: 1.09565,
          h: 1.09575,
          l: 1.09515,
          c: 1.09517,
          v: 158
        },
        {
          t: 42600,
          o: 1.09516,
          h: 1.09532,
          l: 1.09515,
          c: 1.09517,
          v: 115
        },
        {
          t: 42900,
          o: 1.09518,
          h: 1.09529,
          l: 1.09508,
          c: 1.09515,
          v: 131
        },
        {
          t: 43200,
          o: 1.09515,
          h: 1.09522,
          l: 1.09502,
          c: 1.09519,
          v: 125
        },
        {
          t: 43500,
          o: 1.09519,
          h: 1.09539,
          l: 1.09513,
          c: 1.09539,
          v: 137
        },
        {
          t: 43800,
          o: 1.0954,
          h: 1.09547,
          l: 1.09526,
          c: 1.09538,
          v: 131
        },
        {
          t: 44100,
          o: 1.09537,
          h: 1.09554,
          l: 1.09531,
          c: 1.09531,
          v: 111
        },
        {
          t: 44400,
          o: 1.09533,
          h: 1.09556,
          l: 1.09533,
          c: 1.09542,
          v: 111
        },
        {
          t: 44700,
          o: 1.09543,
          h: 1.09563,
          l: 1.09538,
          c: 1.09563,
          v: 112
        },
        {
          t: 45000,
          o: 1.09563,
          h: 1.09569,
          l: 1.09548,
          c: 1.09567,
          v: 134
        },
        {
          t: 45300,
          o: 1.09568,
          h: 1.09581,
          l: 1.09567,
          c: 1.09568,
          v: 130
        },
        {
          t: 45600,
          o: 1.09568,
          h: 1.09603,
          l: 1.09563,
          c: 1.09597,
          v: 139
        },
        {
          t: 45900,
          o: 1.09597,
          h: 1.09597,
          l: 1.09581,
          c: 1.09592,
          v: 164
        },
        {
          t: 46200,
          o: 1.09591,
          h: 1.09618,
          l: 1.09591,
          c: 1.09598,
          v: 162
        },
        {
          t: 46500,
          o: 1.09598,
          h: 1.09642,
          l: 1.09598,
          c: 1.09642,
          v: 212
        },
        {
          t: 46800,
          o: 1.09637,
          h: 1.0966,
          l: 1.09629,
          c: 1.09658,
          v: 282
        },
        {
          t: 47100,
          o: 1.09658,
          h: 1.09681,
          l: 1.0965,
          c: 1.09663,
          v: 288
        },
        {
          t: 47400,
          o: 1.09665,
          h: 1.09694,
          l: 1.09665,
          c: 1.09688,
          v: 190
        },
        {
          t: 47700,
          o: 1.09687,
          h: 1.09704,
          l: 1.09681,
          c: 1.09704,
          v: 225
        },
        {
          t: 48000,
          o: 1.09704,
          h: 1.09704,
          l: 1.09676,
          c: 1.09681,
          v: 192
        },
        {
          t: 48300,
          o: 1.09682,
          h: 1.09686,
          l: 1.09635,
          c: 1.09648,
          v: 272
        },
        {
          t: 48600,
          o: 1.09648,
          h: 1.0967,
          l: 1.09534,
          c: 1.09663,
          v: 703
        },
        {
          t: 48900,
          o: 1.09667,
          h: 1.09691,
          l: 1.09631,
          c: 1.09646,
          v: 512
        },
        {
          t: 49200,
          o: 1.09647,
          h: 1.09647,
          l: 1.09601,
          c: 1.09605,
          v: 394
        },
        {
          t: 49500,
          o: 1.09605,
          h: 1.09641,
          l: 1.0957,
          c: 1.09576,
          v: 418
        },
        {
          t: 49800,
          o: 1.09575,
          h: 1.09615,
          l: 1.09575,
          c: 1.09602,
          v: 303
        },
        {
          t: 50100,
          o: 1.09603,
          h: 1.09613,
          l: 1.09575,
          c: 1.09578,
          v: 243
        },
        {
          t: 50400,
          o: 1.09577,
          h: 1.0958,
          l: 1.09525,
          c: 1.09534,
          v: 244
        },
        {
          t: 50700,
          o: 1.09533,
          h: 1.09537,
          l: 1.09482,
          c: 1.09502,
          v: 292
        },
        {
          t: 51000,
          o: 1.09503,
          h: 1.09536,
          l: 1.09501,
          c: 1.09528,
          v: 303
        },
        {
          t: 51300,
          o: 1.09525,
          h: 1.09554,
          l: 1.09512,
          c: 1.09545,
          v: 327
        },
        {
          t: 51600,
          o: 1.09544,
          h: 1.09562,
          l: 1.0953,
          c: 1.09549,
          v: 264
        },
        {
          t: 51900,
          o: 1.09552,
          h: 1.09583,
          l: 1.09548,
          c: 1.09557,
          v: 260
        },
        {
          t: 52200,
          o: 1.09556,
          h: 1.09677,
          l: 1.09556,
          c: 1.09657,
          v: 490
        },
        {
          t: 52500,
          o: 1.09658,
          h: 1.09686,
          l: 1.09615,
          c: 1.09615,
          v: 457
        },
        {
          t: 52800,
          o: 1.09616,
          h: 1.0969,
          l: 1.09601,
          c: 1.09684,
          v: 356
        },
        {
          t: 53100,
          o: 1.09681,
          h: 1.09729,
          l: 1.09677,
          c: 1.09682,
          v: 410
        },
        {
          t: 53400,
          o: 1.09683,
          h: 1.09702,
          l: 1.09667,
          c: 1.09673,
          v: 305
        },
        {
          t: 53700,
          o: 1.0967,
          h: 1.09723,
          l: 1.0967,
          c: 1.0971,
          v: 266
        },
        {
          t: 54000,
          o: 1.09707,
          h: 1.09747,
          l: 1.0968,
          c: 1.09741,
          v: 483
        },
        {
          t: 54300,
          o: 1.09737,
          h: 1.09737,
          l: 1.09698,
          c: 1.09709,
          v: 341
        },
        {
          t: 54600,
          o: 1.09707,
          h: 1.09707,
          l: 1.09677,
          c: 1.09681,
          v: 243
        },
        {
          t: 54900,
          o: 1.09681,
          h: 1.09712,
          l: 1.09658,
          c: 1.0966,
          v: 251
        },
        {
          t: 55200,
          o: 1.0966,
          h: 1.09662,
          l: 1.0961,
          c: 1.09616,
          v: 241
        },
        {
          t: 55500,
          o: 1.09618,
          h: 1.0962,
          l: 1.09566,
          c: 1.09598,
          v: 329
        },
        {
          t: 55800,
          o: 1.09598,
          h: 1.09625,
          l: 1.09581,
          c: 1.09618,
          v: 293
        },
        {
          t: 56100,
          o: 1.09617,
          h: 1.09638,
          l: 1.09604,
          c: 1.0961,
          v: 235
        },
        {
          t: 56400,
          o: 1.09608,
          h: 1.09758,
          l: 1.09607,
          c: 1.09732,
          v: 639
        },
        {
          t: 56700,
          o: 1.09729,
          h: 1.09752,
          l: 1.09685,
          c: 1.09689,
          v: 399
        },
        {
          t: 57000,
          o: 1.09687,
          h: 1.09703,
          l: 1.09617,
          c: 1.09622,
          v: 362
        },
        {
          t: 57300,
          o: 1.09624,
          h: 1.09638,
          l: 1.09598,
          c: 1.09628,
          v: 405
        },
        {
          t: 57600,
          o: 1.09628,
          h: 1.09648,
          l: 1.09611,
          c: 1.09618,
          v: 368
        },
        {
          t: 57900,
          o: 1.0962,
          h: 1.09623,
          l: 1.09588,
          c: 1.09596,
          v: 269
        },
        {
          t: 58200,
          o: 1.09597,
          h: 1.09608,
          l: 1.09559,
          c: 1.09559,
          v: 262
        },
        {
          t: 58500,
          o: 1.09561,
          h: 1.09567,
          l: 1.09515,
          c: 1.0952,
          v: 270
        },
        {
          t: 58800,
          o: 1.09517,
          h: 1.09554,
          l: 1.09506,
          c: 1.09538,
          v: 233
        },
        {
          t: 59100,
          o: 1.09538,
          h: 1.09544,
          l: 1.09471,
          c: 1.09472,
          v: 357
        },
        {
          t: 59400,
          o: 1.0947,
          h: 1.095,
          l: 1.09457,
          c: 1.09493,
          v: 229
        },
        {
          t: 59700,
          o: 1.09494,
          h: 1.09525,
          l: 1.09492,
          c: 1.09525,
          v: 227
        },
        {
          t: 60000,
          o: 1.09525,
          h: 1.09525,
          l: 1.09504,
          c: 1.09513,
          v: 178
        },
        {
          t: 60300,
          o: 1.09513,
          h: 1.09533,
          l: 1.09508,
          c: 1.09522,
          v: 153
        },
        {
          t: 60600,
          o: 1.09523,
          h: 1.0953,
          l: 1.09502,
          c: 1.09502,
          v: 137
        },
        {
          t: 60900,
          o: 1.09502,
          h: 1.09503,
          l: 1.09481,
          c: 1.0949,
          v: 128
        },
        {
          t: 61200,
          o: 1.09492,
          h: 1.09493,
          l: 1.09476,
          c: 1.09476,
          v: 144
        },
        {
          t: 61500,
          o: 1.09472,
          h: 1.09475,
          l: 1.09464,
          c: 1.09468,
          v: 157
        },
        {
          t: 61800,
          o: 1.09467,
          h: 1.09477,
          l: 1.09453,
          c: 1.09458,
          v: 117
        },
        {
          t: 62100,
          o: 1.09457,
          h: 1.09476,
          l: 1.09457,
          c: 1.09473,
          v: 97
        },
        {
          t: 62400,
          o: 1.09473,
          h: 1.09483,
          l: 1.09467,
          c: 1.09478,
          v: 133
        },
        {
          t: 62700,
          o: 1.09477,
          h: 1.09482,
          l: 1.09465,
          c: 1.09466,
          v: 75
        },
        {
          t: 63000,
          o: 1.09464,
          h: 1.09482,
          l: 1.09461,
          c: 1.09476,
          v: 80
        },
        {
          t: 63300,
          o: 1.09474,
          h: 1.09478,
          l: 1.0946,
          c: 1.09461,
          v: 103
        },
        {
          t: 63600,
          o: 1.09461,
          h: 1.09464,
          l: 1.09451,
          c: 1.09456,
          v: 62
        },
        {
          t: 63900,
          o: 1.09456,
          h: 1.09457,
          l: 1.09451,
          c: 1.09453,
          v: 35
        },
        {
          t: 64200,
          o: 1.09455,
          h: 1.09456,
          l: 1.09431,
          c: 1.0944,
          v: 139
        },
        {
          t: 64500,
          o: 1.09439,
          h: 1.09452,
          l: 1.09416,
          c: 1.09422,
          v: 189
        },
        {
          t: 64800,
          o: 1.0942,
          h: 1.09464,
          l: 1.0942,
          c: 1.09441,
          v: 190
        },
        {
          t: 65100,
          o: 1.09442,
          h: 1.09464,
          l: 1.0944,
          c: 1.09459,
          v: 106
        },
        {
          t: 65400,
          o: 1.09458,
          h: 1.09465,
          l: 1.09443,
          c: 1.09445,
          v: 109
        },
        {
          t: 65700,
          o: 1.09445,
          h: 1.09447,
          l: 1.09436,
          c: 1.09439,
          v: 57
        },
        {
          t: 66000,
          o: 1.09438,
          h: 1.09445,
          l: 1.09432,
          c: 1.09443,
          v: 60
        },
        {
          t: 66300,
          o: 1.09444,
          h: 1.09462,
          l: 1.09438,
          c: 1.09462,
          v: 97
        },
        {
          t: 66600,
          o: 1.09463,
          h: 1.09489,
          l: 1.09463,
          c: 1.09473,
          v: 140
        },
        {
          t: 66900,
          o: 1.09474,
          h: 1.09479,
          l: 1.0944,
          c: 1.09443,
          v: 188
        },
        {
          t: 67200,
          o: 1.09445,
          h: 1.0945,
          l: 1.09438,
          c: 1.09448,
          v: 92
        },
        {
          t: 67500,
          o: 1.09448,
          h: 1.09457,
          l: 1.0943,
          c: 1.09432,
          v: 105
        },
        {
          t: 67800,
          o: 1.09433,
          h: 1.09439,
          l: 1.09421,
          c: 1.09421,
          v: 71
        },
        {
          t: 68100,
          o: 1.09422,
          h: 1.09433,
          l: 1.09421,
          c: 1.09433,
          v: 83
        },
        {
          t: 68400,
          o: 1.09433,
          h: 1.09463,
          l: 1.09433,
          c: 1.09463,
          v: 114
        },
        {
          t: 68700,
          o: 1.09459,
          h: 1.09459,
          l: 1.09443,
          c: 1.09454,
          v: 149
        },
        {
          t: 69000,
          o: 1.09456,
          h: 1.09459,
          l: 1.09448,
          c: 1.09457,
          v: 127
        },
        {
          t: 69300,
          o: 1.09458,
          h: 1.09473,
          l: 1.09448,
          c: 1.09469,
          v: 142
        },
        {
          t: 69600,
          o: 1.09472,
          h: 1.09482,
          l: 1.09468,
          c: 1.09472,
          v: 131
        },
        {
          t: 69900,
          o: 1.09471,
          h: 1.09484,
          l: 1.09469,
          c: 1.09479,
          v: 82
        },
        {
          t: 70200,
          o: 1.09478,
          h: 1.0948,
          l: 1.09467,
          c: 1.09473,
          v: 109
        },
        {
          t: 70500,
          o: 1.09473,
          h: 1.09483,
          l: 1.09473,
          c: 1.09473,
          v: 50
        },
        {
          t: 70800,
          o: 1.0947,
          h: 1.09473,
          l: 1.09447,
          c: 1.09447,
          v: 104
        },
        {
          t: 71100,
          o: 1.09447,
          h: 1.09468,
          l: 1.09446,
          c: 1.09462,
          v: 93
        },
        {
          t: 71400,
          o: 1.09461,
          h: 1.09468,
          l: 1.0945,
          c: 1.09463,
          v: 84
        },
        {
          t: 71700,
          o: 1.09463,
          h: 1.09463,
          l: 1.09454,
          c: 1.09458,
          v: 129
        },
        {
          t: 72000,
          o: 1.09457,
          h: 1.09459,
          l: 1.09447,
          c: 1.09448,
          v: 83
        },
        {
          t: 72300,
          o: 1.09448,
          h: 1.09458,
          l: 1.09443,
          c: 1.09458,
          v: 88
        },
        {
          t: 72600,
          o: 1.09459,
          h: 1.09459,
          l: 1.09448,
          c: 1.09459,
          v: 64
        },
        {
          t: 72900,
          o: 1.0946,
          h: 1.0946,
          l: 1.09453,
          c: 1.09454,
          v: 57
        },
        {
          t: 73200,
          o: 1.09454,
          h: 1.09455,
          l: 1.09447,
          c: 1.09449,
          v: 65
        },
        {
          t: 73500,
          o: 1.09448,
          h: 1.09451,
          l: 1.09436,
          c: 1.09439,
          v: 113
        },
        {
          t: 73800,
          o: 1.09439,
          h: 1.0946,
          l: 1.09439,
          c: 1.09458,
          v: 87
        },
        {
          t: 74100,
          o: 1.0946,
          h: 1.0947,
          l: 1.09459,
          c: 1.09469,
          v: 80
        },
        {
          t: 74400,
          o: 1.09469,
          h: 1.09474,
          l: 1.09468,
          c: 1.0947,
          v: 47
        },
        {
          t: 74700,
          o: 1.09471,
          h: 1.09471,
          l: 1.09458,
          c: 1.09458,
          v: 103
        },
        {
          t: 75000,
          o: 1.09459,
          h: 1.09465,
          l: 1.09457,
          c: 1.09464,
          v: 79
        },
        {
          t: 75300,
          o: 1.09463,
          h: 1.09472,
          l: 1.09461,
          c: 1.09467,
          v: 109
        },
        {
          t: 75600,
          o: 1.09467,
          h: 1.09471,
          l: 1.09464,
          c: 1.09469,
          v: 87
        },
        {
          t: 75900,
          o: 1.09468,
          h: 1.09469,
          l: 1.09462,
          c: 1.09463,
          v: 40
        },
        {
          t: 76200,
          o: 1.09464,
          h: 1.09467,
          l: 1.09455,
          c: 1.09466,
          v: 66
        },
        {
          t: 76500,
          o: 1.09463,
          h: 1.09467,
          l: 1.09454,
          c: 1.09456,
          v: 62
        },
        {
          t: 76800,
          o: 1.09455,
          h: 1.09456,
          l: 1.09447,
          c: 1.09453,
          v: 47
        },
        {
          t: 77100,
          o: 1.09455,
          h: 1.0946,
          l: 1.09455,
          c: 1.09455,
          v: 42
        },
        {
          t: 77400,
          o: 1.09456,
          h: 1.09459,
          l: 1.09452,
          c: 1.09457,
          v: 66
        },
        {
          t: 77700,
          o: 1.09456,
          h: 1.09459,
          l: 1.09451,
          c: 1.09454,
          v: 80
        },
        {
          t: 78000,
          o: 1.09453,
          h: 1.09456,
          l: 1.09441,
          c: 1.09443,
          v: 56
        }
      ]
    },
    {
      d: 1581206400,
      b: [
        {
          t: 79800,
          o: 1.09434,
          h: 1.09438,
          l: 1.09433,
          c: 1.09435,
          v: 33
        },
        {
          t: 80100,
          o: 1.09435,
          h: 1.09444,
          l: 1.09428,
          c: 1.0944,
          v: 46
        },
        {
          t: 80400,
          o: 1.09439,
          h: 1.09441,
          l: 1.09429,
          c: 1.0943,
          v: 24
        },
        {
          t: 80700,
          o: 1.0943,
          h: 1.09432,
          l: 1.0943,
          c: 1.09431,
          v: 15
        },
        {
          t: 81000,
          o: 1.0943,
          h: 1.09435,
          l: 1.09418,
          c: 1.09426,
          v: 51
        },
        {
          t: 81300,
          o: 1.09426,
          h: 1.09432,
          l: 1.09424,
          c: 1.09427,
          v: 99
        },
        {
          t: 81600,
          o: 1.09427,
          h: 1.09439,
          l: 1.09424,
          c: 1.09429,
          v: 28
        },
        {
          t: 81900,
          o: 1.09429,
          h: 1.09433,
          l: 1.09426,
          c: 1.09429,
          v: 27
        },
        {
          t: 82200,
          o: 1.09428,
          h: 1.09428,
          l: 1.09428,
          c: 1.09428,
          v: 1
        },
        {
          t: 82500,
          o: 1.0943,
          h: 1.09433,
          l: 1.09425,
          c: 1.09431,
          v: 33
        },
        {
          t: 82800,
          o: 1.09438,
          h: 1.09448,
          l: 1.09423,
          c: 1.09427,
          v: 171
        },
        {
          t: 83100,
          o: 1.09428,
          h: 1.09444,
          l: 1.09423,
          c: 1.09443,
          v: 87
        },
        {
          t: 83400,
          o: 1.09444,
          h: 1.09445,
          l: 1.09439,
          c: 1.09444,
          v: 46
        },
        {
          t: 83700,
          o: 1.09443,
          h: 1.09445,
          l: 1.09443,
          c: 1.09443,
          v: 24
        },
        {
          t: 84000,
          o: 1.09442,
          h: 1.09452,
          l: 1.09442,
          c: 1.09451,
          v: 53
        },
        {
          t: 84300,
          o: 1.09449,
          h: 1.09453,
          l: 1.09448,
          c: 1.09451,
          v: 40
        },
        {
          t: 84600,
          o: 1.09451,
          h: 1.09469,
          l: 1.0945,
          c: 1.09463,
          v: 139
        },
        {
          t: 84900,
          o: 1.09462,
          h: 1.0949,
          l: 1.09462,
          c: 1.09469,
          v: 225
        },
        {
          t: 85200,
          o: 1.09469,
          h: 1.09496,
          l: 1.09468,
          c: 1.09491,
          v: 141
        },
        {
          t: 85500,
          o: 1.09488,
          h: 1.09495,
          l: 1.09482,
          c: 1.09488,
          v: 154
        },
        {
          t: 85800,
          o: 1.0949,
          h: 1.09497,
          l: 1.09488,
          c: 1.09495,
          v: 80
        },
        {
          t: 86100,
          o: 1.09494,
          h: 1.09502,
          l: 1.0948,
          c: 1.09487,
          v: 83
        }
      ]
    },
    {
      d: 1581292800,
      b: [
        {
          t: 0,
          o: 1.09485,
          h: 1.09497,
          l: 1.09477,
          c: 1.09493,
          v: 134
        },
        {
          t: 300,
          o: 1.09493,
          h: 1.09501,
          l: 1.09485,
          c: 1.09489,
          v: 156
        },
        {
          t: 600,
          o: 1.09488,
          h: 1.09502,
          l: 1.09487,
          c: 1.09499,
          v: 80
        },
        {
          t: 900,
          o: 1.095,
          h: 1.09506,
          l: 1.09497,
          c: 1.09499,
          v: 101
        },
        {
          t: 1200,
          o: 1.095,
          h: 1.09504,
          l: 1.09497,
          c: 1.09502,
          v: 87
        },
        {
          t: 1500,
          o: 1.09503,
          h: 1.09507,
          l: 1.09499,
          c: 1.09507,
          v: 99
        },
        {
          t: 1800,
          o: 1.09505,
          h: 1.09505,
          l: 1.09496,
          c: 1.09502,
          v: 39
        },
        {
          t: 2100,
          o: 1.09502,
          h: 1.09512,
          l: 1.09502,
          c: 1.09509,
          v: 59
        },
        {
          t: 2400,
          o: 1.09512,
          h: 1.09514,
          l: 1.09508,
          c: 1.09509,
          v: 74
        },
        {
          t: 2700,
          o: 1.09508,
          h: 1.09514,
          l: 1.09505,
          c: 1.09513,
          v: 34
        },
        {
          t: 3000,
          o: 1.09514,
          h: 1.0952,
          l: 1.09506,
          c: 1.09515,
          v: 101
        },
        {
          t: 3300,
          o: 1.09521,
          h: 1.09525,
          l: 1.09518,
          c: 1.09522,
          v: 84
        },
        {
          t: 3600,
          o: 1.09518,
          h: 1.09528,
          l: 1.09518,
          c: 1.09522,
          v: 80
        },
        {
          t: 3900,
          o: 1.09523,
          h: 1.09524,
          l: 1.09512,
          c: 1.09514,
          v: 56
        },
        {
          t: 4200,
          o: 1.09518,
          h: 1.0952,
          l: 1.09514,
          c: 1.09515,
          v: 30
        },
        {
          t: 4500,
          o: 1.09514,
          h: 1.09516,
          l: 1.09509,
          c: 1.09515,
          v: 48
        },
        {
          t: 4800,
          o: 1.09511,
          h: 1.09519,
          l: 1.09508,
          c: 1.0951,
          v: 53
        },
        {
          t: 5100,
          o: 1.09513,
          h: 1.09518,
          l: 1.09508,
          c: 1.09512,
          v: 59
        },
        {
          t: 5400,
          o: 1.09511,
          h: 1.0952,
          l: 1.09511,
          c: 1.09514,
          v: 105
        },
        {
          t: 5700,
          o: 1.09514,
          h: 1.09514,
          l: 1.09506,
          c: 1.09509,
          v: 106
        },
        {
          t: 6000,
          o: 1.0951,
          h: 1.09513,
          l: 1.09498,
          c: 1.09503,
          v: 119
        },
        {
          t: 6300,
          o: 1.09503,
          h: 1.09504,
          l: 1.09489,
          c: 1.09493,
          v: 158
        },
        {
          t: 6600,
          o: 1.09493,
          h: 1.09497,
          l: 1.09493,
          c: 1.09495,
          v: 127
        },
        {
          t: 6900,
          o: 1.09496,
          h: 1.095,
          l: 1.09494,
          c: 1.095,
          v: 88
        },
        {
          t: 7200,
          o: 1.09499,
          h: 1.09511,
          l: 1.09496,
          c: 1.09508,
          v: 70
        },
        {
          t: 7500,
          o: 1.09509,
          h: 1.09526,
          l: 1.09505,
          c: 1.09525,
          v: 67
        },
        {
          t: 7800,
          o: 1.09525,
          h: 1.09526,
          l: 1.09516,
          c: 1.09516,
          v: 82
        },
        {
          t: 8100,
          o: 1.09514,
          h: 1.09515,
          l: 1.09505,
          c: 1.09508,
          v: 149
        },
        {
          t: 8400,
          o: 1.09509,
          h: 1.0952,
          l: 1.09508,
          c: 1.09518,
          v: 93
        },
        {
          t: 8700,
          o: 1.09519,
          h: 1.09523,
          l: 1.09515,
          c: 1.09515,
          v: 111
        },
        {
          t: 9000,
          o: 1.09516,
          h: 1.0952,
          l: 1.09504,
          c: 1.09507,
          v: 130
        },
        {
          t: 9300,
          o: 1.09508,
          h: 1.09511,
          l: 1.09503,
          c: 1.09511,
          v: 81
        },
        {
          t: 9600,
          o: 1.09513,
          h: 1.09513,
          l: 1.09504,
          c: 1.09506,
          v: 86
        },
        {
          t: 9900,
          o: 1.09507,
          h: 1.09512,
          l: 1.09506,
          c: 1.09509,
          v: 65
        },
        {
          t: 10200,
          o: 1.09509,
          h: 1.09513,
          l: 1.09508,
          c: 1.09509,
          v: 47
        },
        {
          t: 10500,
          o: 1.0951,
          h: 1.09536,
          l: 1.0951,
          c: 1.0953,
          v: 62
        },
        {
          t: 10800,
          o: 1.09531,
          h: 1.09536,
          l: 1.09528,
          c: 1.09535,
          v: 64
        },
        {
          t: 11100,
          o: 1.09535,
          h: 1.09535,
          l: 1.09521,
          c: 1.09523,
          v: 73
        },
        {
          t: 11400,
          o: 1.09523,
          h: 1.09525,
          l: 1.0951,
          c: 1.09513,
          v: 92
        },
        {
          t: 11700,
          o: 1.09514,
          h: 1.09525,
          l: 1.09508,
          c: 1.0951,
          v: 98
        },
        {
          t: 12000,
          o: 1.0951,
          h: 1.09513,
          l: 1.09507,
          c: 1.09509,
          v: 73
        },
        {
          t: 12300,
          o: 1.09511,
          h: 1.09515,
          l: 1.09507,
          c: 1.09513,
          v: 92
        },
        {
          t: 12600,
          o: 1.09515,
          h: 1.09515,
          l: 1.09497,
          c: 1.09498,
          v: 96
        },
        {
          t: 12900,
          o: 1.09497,
          h: 1.09505,
          l: 1.09497,
          c: 1.095,
          v: 101
        },
        {
          t: 13200,
          o: 1.09499,
          h: 1.09513,
          l: 1.09498,
          c: 1.09513,
          v: 34
        },
        {
          t: 13500,
          o: 1.09513,
          h: 1.09516,
          l: 1.09512,
          c: 1.09514,
          v: 41
        },
        {
          t: 13800,
          o: 1.09515,
          h: 1.09519,
          l: 1.09514,
          c: 1.09518,
          v: 33
        },
        {
          t: 14100,
          o: 1.09519,
          h: 1.09525,
          l: 1.09518,
          c: 1.09518,
          v: 43
        },
        {
          t: 14400,
          o: 1.09519,
          h: 1.09524,
          l: 1.09518,
          c: 1.09519,
          v: 30
        },
        {
          t: 14700,
          o: 1.0952,
          h: 1.09525,
          l: 1.09513,
          c: 1.09513,
          v: 56
        },
        {
          t: 15000,
          o: 1.09514,
          h: 1.09524,
          l: 1.09513,
          c: 1.09519,
          v: 55
        },
        {
          t: 15300,
          o: 1.09519,
          h: 1.09524,
          l: 1.09519,
          c: 1.09524,
          v: 45
        },
        {
          t: 15600,
          o: 1.09524,
          h: 1.09529,
          l: 1.09522,
          c: 1.09523,
          v: 33
        },
        {
          t: 15900,
          o: 1.09524,
          h: 1.09528,
          l: 1.09522,
          c: 1.09523,
          v: 46
        },
        {
          t: 16200,
          o: 1.09523,
          h: 1.09536,
          l: 1.09523,
          c: 1.09534,
          v: 31
        },
        {
          t: 16500,
          o: 1.09534,
          h: 1.0954,
          l: 1.09533,
          c: 1.09534,
          v: 44
        },
        {
          t: 16800,
          o: 1.09535,
          h: 1.09543,
          l: 1.09533,
          c: 1.09539,
          v: 34
        },
        {
          t: 17100,
          o: 1.09542,
          h: 1.09543,
          l: 1.09538,
          c: 1.09543,
          v: 69
        },
        {
          t: 17400,
          o: 1.09543,
          h: 1.09547,
          l: 1.09541,
          c: 1.09543,
          v: 39
        },
        {
          t: 17700,
          o: 1.09543,
          h: 1.09568,
          l: 1.09543,
          c: 1.09563,
          v: 87
        },
        {
          t: 18000,
          o: 1.09565,
          h: 1.0957,
          l: 1.09555,
          c: 1.09559,
          v: 78
        },
        {
          t: 18300,
          o: 1.09559,
          h: 1.09561,
          l: 1.09558,
          c: 1.0956,
          v: 29
        },
        {
          t: 18600,
          o: 1.09559,
          h: 1.09564,
          l: 1.09558,
          c: 1.09564,
          v: 41
        },
        {
          t: 18900,
          o: 1.09563,
          h: 1.09564,
          l: 1.09558,
          c: 1.09558,
          v: 40
        },
        {
          t: 19200,
          o: 1.09555,
          h: 1.0956,
          l: 1.09548,
          c: 1.0955,
          v: 35
        },
        {
          t: 19500,
          o: 1.09552,
          h: 1.09562,
          l: 1.09552,
          c: 1.09553,
          v: 63
        },
        {
          t: 19800,
          o: 1.09554,
          h: 1.09555,
          l: 1.09545,
          c: 1.09548,
          v: 58
        },
        {
          t: 20100,
          o: 1.09548,
          h: 1.0955,
          l: 1.09547,
          c: 1.09548,
          v: 42
        },
        {
          t: 20400,
          o: 1.09548,
          h: 1.09548,
          l: 1.09538,
          c: 1.09539,
          v: 56
        },
        {
          t: 20700,
          o: 1.0954,
          h: 1.09546,
          l: 1.09538,
          c: 1.09543,
          v: 46
        },
        {
          t: 21000,
          o: 1.09543,
          h: 1.0955,
          l: 1.09543,
          c: 1.0955,
          v: 43
        },
        {
          t: 21300,
          o: 1.0955,
          h: 1.09554,
          l: 1.09547,
          c: 1.09551,
          v: 63
        },
        {
          t: 21600,
          o: 1.09551,
          h: 1.0956,
          l: 1.09548,
          c: 1.0955,
          v: 87
        },
        {
          t: 21900,
          o: 1.09551,
          h: 1.09551,
          l: 1.09537,
          c: 1.09539,
          v: 32
        },
        {
          t: 22200,
          o: 1.09538,
          h: 1.09541,
          l: 1.09535,
          c: 1.09535,
          v: 40
        },
        {
          t: 22500,
          o: 1.0953,
          h: 1.09541,
          l: 1.09525,
          c: 1.09538,
          v: 63
        },
        {
          t: 22800,
          o: 1.09538,
          h: 1.09541,
          l: 1.09535,
          c: 1.0954,
          v: 29
        },
        {
          t: 23100,
          o: 1.09538,
          h: 1.09542,
          l: 1.09531,
          c: 1.0954,
          v: 62
        },
        {
          t: 23400,
          o: 1.09542,
          h: 1.09551,
          l: 1.0954,
          c: 1.09544,
          v: 80
        },
        {
          t: 23700,
          o: 1.09543,
          h: 1.09545,
          l: 1.09533,
          c: 1.09539,
          v: 76
        },
        {
          t: 24000,
          o: 1.09538,
          h: 1.09554,
          l: 1.09528,
          c: 1.09551,
          v: 89
        },
        {
          t: 24300,
          o: 1.09557,
          h: 1.09569,
          l: 1.09535,
          c: 1.09545,
          v: 130
        },
        {
          t: 24600,
          o: 1.09546,
          h: 1.09546,
          l: 1.09525,
          c: 1.09525,
          v: 87
        },
        {
          t: 24900,
          o: 1.09524,
          h: 1.09524,
          l: 1.09508,
          c: 1.09508,
          v: 96
        },
        {
          t: 25200,
          o: 1.09507,
          h: 1.09513,
          l: 1.09477,
          c: 1.09486,
          v: 246
        },
        {
          t: 25500,
          o: 1.09485,
          h: 1.09493,
          l: 1.09483,
          c: 1.0949,
          v: 131
        },
        {
          t: 25800,
          o: 1.09491,
          h: 1.09492,
          l: 1.09478,
          c: 1.09486,
          v: 194
        },
        {
          t: 26100,
          o: 1.09485,
          h: 1.0952,
          l: 1.09485,
          c: 1.0952,
          v: 112
        },
        {
          t: 26400,
          o: 1.0952,
          h: 1.09527,
          l: 1.09501,
          c: 1.09527,
          v: 139
        },
        {
          t: 26700,
          o: 1.09527,
          h: 1.09531,
          l: 1.09516,
          c: 1.09522,
          v: 124
        },
        {
          t: 27000,
          o: 1.09522,
          h: 1.0953,
          l: 1.0951,
          c: 1.09527,
          v: 175
        },
        {
          t: 27300,
          o: 1.09527,
          h: 1.09532,
          l: 1.09516,
          c: 1.09521,
          v: 165
        },
        {
          t: 27600,
          o: 1.09521,
          h: 1.09525,
          l: 1.09496,
          c: 1.09497,
          v: 194
        },
        {
          t: 27900,
          o: 1.09497,
          h: 1.09497,
          l: 1.0947,
          c: 1.09473,
          v: 111
        },
        {
          t: 28200,
          o: 1.09473,
          h: 1.09491,
          l: 1.09464,
          c: 1.09467,
          v: 205
        },
        {
          t: 28500,
          o: 1.09466,
          h: 1.09467,
          l: 1.09445,
          c: 1.09462,
          v: 186
        },
        {
          t: 28800,
          o: 1.09457,
          h: 1.09486,
          l: 1.09456,
          c: 1.0948,
          v: 195
        },
        {
          t: 29100,
          o: 1.0948,
          h: 1.0948,
          l: 1.09446,
          c: 1.09458,
          v: 197
        },
        {
          t: 29400,
          o: 1.09459,
          h: 1.09473,
          l: 1.09451,
          c: 1.09456,
          v: 134
        },
        {
          t: 29700,
          o: 1.09458,
          h: 1.09465,
          l: 1.09442,
          c: 1.09456,
          v: 175
        },
        {
          t: 30000,
          o: 1.09457,
          h: 1.09472,
          l: 1.09457,
          c: 1.09467,
          v: 127
        },
        {
          t: 30300,
          o: 1.09466,
          h: 1.09475,
          l: 1.09465,
          c: 1.09471,
          v: 154
        },
        {
          t: 30600,
          o: 1.09472,
          h: 1.09478,
          l: 1.09455,
          c: 1.09467,
          v: 168
        },
        {
          t: 30900,
          o: 1.09467,
          h: 1.09517,
          l: 1.09466,
          c: 1.095,
          v: 181
        },
        {
          t: 31200,
          o: 1.095,
          h: 1.09522,
          l: 1.0949,
          c: 1.0951,
          v: 178
        },
        {
          t: 31500,
          o: 1.09513,
          h: 1.09521,
          l: 1.09497,
          c: 1.09498,
          v: 204
        },
        {
          t: 31800,
          o: 1.09498,
          h: 1.09531,
          l: 1.09488,
          c: 1.09526,
          v: 198
        },
        {
          t: 32100,
          o: 1.09525,
          h: 1.09529,
          l: 1.09508,
          c: 1.09508,
          v: 153
        },
        {
          t: 32400,
          o: 1.09509,
          h: 1.09511,
          l: 1.0949,
          c: 1.0949,
          v: 152
        },
        {
          t: 32700,
          o: 1.09489,
          h: 1.09505,
          l: 1.09485,
          c: 1.09499,
          v: 182
        },
        {
          t: 33000,
          o: 1.09499,
          h: 1.09504,
          l: 1.09488,
          c: 1.09494,
          v: 145
        },
        {
          t: 33300,
          o: 1.09495,
          h: 1.09505,
          l: 1.09484,
          c: 1.09505,
          v: 142
        },
        {
          t: 33600,
          o: 1.09505,
          h: 1.09514,
          l: 1.09488,
          c: 1.09489,
          v: 164
        },
        {
          t: 33900,
          o: 1.09484,
          h: 1.09501,
          l: 1.09483,
          c: 1.09495,
          v: 196
        },
        {
          t: 34200,
          o: 1.09495,
          h: 1.09497,
          l: 1.09485,
          c: 1.0949,
          v: 137
        },
        {
          t: 34500,
          o: 1.09491,
          h: 1.09514,
          l: 1.0949,
          c: 1.09504,
          v: 130
        },
        {
          t: 34800,
          o: 1.09501,
          h: 1.09507,
          l: 1.09489,
          c: 1.0949,
          v: 132
        },
        {
          t: 35100,
          o: 1.0949,
          h: 1.09513,
          l: 1.09459,
          c: 1.0946,
          v: 196
        },
        {
          t: 35400,
          o: 1.0946,
          h: 1.09468,
          l: 1.09458,
          c: 1.09458,
          v: 143
        },
        {
          t: 35700,
          o: 1.0946,
          h: 1.09499,
          l: 1.09459,
          c: 1.09493,
          v: 176
        },
        {
          t: 36000,
          o: 1.09492,
          h: 1.09521,
          l: 1.09489,
          c: 1.0951,
          v: 165
        },
        {
          t: 36300,
          o: 1.09512,
          h: 1.0953,
          l: 1.0951,
          c: 1.0953,
          v: 125
        },
        {
          t: 36600,
          o: 1.09529,
          h: 1.09536,
          l: 1.09519,
          c: 1.0952,
          v: 137
        },
        {
          t: 36900,
          o: 1.09523,
          h: 1.09532,
          l: 1.09514,
          c: 1.09525,
          v: 84
        },
        {
          t: 37200,
          o: 1.09523,
          h: 1.09545,
          l: 1.09513,
          c: 1.09529,
          v: 128
        },
        {
          t: 37500,
          o: 1.09528,
          h: 1.09528,
          l: 1.09513,
          c: 1.09515,
          v: 84
        },
        {
          t: 37800,
          o: 1.09515,
          h: 1.09532,
          l: 1.09507,
          c: 1.09507,
          v: 112
        },
        {
          t: 38100,
          o: 1.09505,
          h: 1.09509,
          l: 1.09495,
          c: 1.09499,
          v: 136
        },
        {
          t: 38400,
          o: 1.095,
          h: 1.09513,
          l: 1.09473,
          c: 1.09505,
          v: 204
        },
        {
          t: 38700,
          o: 1.09503,
          h: 1.09517,
          l: 1.0949,
          c: 1.09495,
          v: 238
        },
        {
          t: 39000,
          o: 1.09495,
          h: 1.09512,
          l: 1.0949,
          c: 1.095,
          v: 136
        },
        {
          t: 39300,
          o: 1.095,
          h: 1.09507,
          l: 1.09488,
          c: 1.0949,
          v: 123
        },
        {
          t: 39600,
          o: 1.09488,
          h: 1.09488,
          l: 1.09448,
          c: 1.0945,
          v: 196
        },
        {
          t: 39900,
          o: 1.09451,
          h: 1.0947,
          l: 1.09448,
          c: 1.09469,
          v: 161
        },
        {
          t: 40200,
          o: 1.09467,
          h: 1.09496,
          l: 1.09466,
          c: 1.09496,
          v: 95
        },
        {
          t: 40500,
          o: 1.09494,
          h: 1.09498,
          l: 1.09483,
          c: 1.09489,
          v: 83
        },
        {
          t: 40800,
          o: 1.09487,
          h: 1.0951,
          l: 1.09482,
          c: 1.09505,
          v: 103
        },
        {
          t: 41100,
          o: 1.09502,
          h: 1.0951,
          l: 1.09494,
          c: 1.09495,
          v: 96
        },
        {
          t: 41400,
          o: 1.09493,
          h: 1.09519,
          l: 1.09479,
          c: 1.09515,
          v: 193
        },
        {
          t: 41700,
          o: 1.09515,
          h: 1.09516,
          l: 1.0949,
          c: 1.09494,
          v: 110
        },
        {
          t: 42000,
          o: 1.09493,
          h: 1.09516,
          l: 1.09492,
          c: 1.09512,
          v: 90
        },
        {
          t: 42300,
          o: 1.09511,
          h: 1.09522,
          l: 1.09511,
          c: 1.09521,
          v: 111
        },
        {
          t: 42600,
          o: 1.0952,
          h: 1.09522,
          l: 1.0949,
          c: 1.09494,
          v: 118
        },
        {
          t: 42900,
          o: 1.09495,
          h: 1.09504,
          l: 1.09486,
          c: 1.09486,
          v: 136
        },
        {
          t: 43200,
          o: 1.09487,
          h: 1.09492,
          l: 1.09469,
          c: 1.09479,
          v: 169
        },
        {
          t: 43500,
          o: 1.0948,
          h: 1.09495,
          l: 1.09475,
          c: 1.0948,
          v: 151
        },
        {
          t: 43800,
          o: 1.09481,
          h: 1.09506,
          l: 1.09469,
          c: 1.09495,
          v: 144
        },
        {
          t: 44100,
          o: 1.09495,
          h: 1.09497,
          l: 1.09481,
          c: 1.09485,
          v: 137
        },
        {
          t: 44400,
          o: 1.09484,
          h: 1.0951,
          l: 1.09484,
          c: 1.09503,
          v: 136
        },
        {
          t: 44700,
          o: 1.09504,
          h: 1.09514,
          l: 1.09498,
          c: 1.09505,
          v: 90
        },
        {
          t: 45000,
          o: 1.09505,
          h: 1.09511,
          l: 1.09484,
          c: 1.09484,
          v: 98
        },
        {
          t: 45300,
          o: 1.09483,
          h: 1.09509,
          l: 1.09483,
          c: 1.09503,
          v: 97
        },
        {
          t: 45600,
          o: 1.09504,
          h: 1.09511,
          l: 1.09494,
          c: 1.09505,
          v: 81
        },
        {
          t: 45900,
          o: 1.09505,
          h: 1.09515,
          l: 1.09498,
          c: 1.09511,
          v: 119
        },
        {
          t: 46200,
          o: 1.0951,
          h: 1.09511,
          l: 1.0949,
          c: 1.09491,
          v: 135
        },
        {
          t: 46500,
          o: 1.09491,
          h: 1.09492,
          l: 1.09477,
          c: 1.09489,
          v: 183
        },
        {
          t: 46800,
          o: 1.09488,
          h: 1.09503,
          l: 1.09487,
          c: 1.09499,
          v: 159
        },
        {
          t: 47100,
          o: 1.095,
          h: 1.09516,
          l: 1.09499,
          c: 1.09509,
          v: 203
        },
        {
          t: 47400,
          o: 1.0951,
          h: 1.09516,
          l: 1.095,
          c: 1.09507,
          v: 182
        },
        {
          t: 47700,
          o: 1.09507,
          h: 1.0951,
          l: 1.09482,
          c: 1.09494,
          v: 119
        },
        {
          t: 48000,
          o: 1.09491,
          h: 1.09504,
          l: 1.09483,
          c: 1.09495,
          v: 180
        },
        {
          t: 48300,
          o: 1.09495,
          h: 1.09522,
          l: 1.09494,
          c: 1.09522,
          v: 233
        },
        {
          t: 48600,
          o: 1.09521,
          h: 1.09521,
          l: 1.09477,
          c: 1.09494,
          v: 277
        },
        {
          t: 48900,
          o: 1.09493,
          h: 1.09501,
          l: 1.09485,
          c: 1.09486,
          v: 138
        },
        {
          t: 49200,
          o: 1.09486,
          h: 1.09504,
          l: 1.09458,
          c: 1.0947,
          v: 204
        },
        {
          t: 49500,
          o: 1.09468,
          h: 1.09472,
          l: 1.09428,
          c: 1.09432,
          v: 207
        },
        {
          t: 49800,
          o: 1.09432,
          h: 1.09433,
          l: 1.09356,
          c: 1.0936,
          v: 333
        },
        {
          t: 50100,
          o: 1.0936,
          h: 1.09363,
          l: 1.09295,
          c: 1.09322,
          v: 366
        },
        {
          t: 50400,
          o: 1.09321,
          h: 1.09331,
          l: 1.09299,
          c: 1.09327,
          v: 296
        },
        {
          t: 50700,
          o: 1.09328,
          h: 1.09333,
          l: 1.09278,
          c: 1.09289,
          v: 369
        },
        {
          t: 51000,
          o: 1.09289,
          h: 1.09298,
          l: 1.09267,
          c: 1.09281,
          v: 277
        },
        {
          t: 51300,
          o: 1.0928,
          h: 1.09307,
          l: 1.0928,
          c: 1.09293,
          v: 193
        },
        {
          t: 51600,
          o: 1.09293,
          h: 1.0932,
          l: 1.09291,
          c: 1.09298,
          v: 160
        },
        {
          t: 51900,
          o: 1.09301,
          h: 1.09304,
          l: 1.09275,
          c: 1.0928,
          v: 179
        },
        {
          t: 52200,
          o: 1.09283,
          h: 1.09298,
          l: 1.09272,
          c: 1.09292,
          v: 273
        },
        {
          t: 52500,
          o: 1.09292,
          h: 1.09298,
          l: 1.0928,
          c: 1.09282,
          v: 196
        },
        {
          t: 52800,
          o: 1.09283,
          h: 1.09294,
          l: 1.09274,
          c: 1.09294,
          v: 172
        },
        {
          t: 53100,
          o: 1.09295,
          h: 1.09318,
          l: 1.09287,
          c: 1.09288,
          v: 217
        },
        {
          t: 53400,
          o: 1.09288,
          h: 1.09302,
          l: 1.09273,
          c: 1.0928,
          v: 184
        },
        {
          t: 53700,
          o: 1.09279,
          h: 1.09287,
          l: 1.09271,
          c: 1.09271,
          v: 227
        },
        {
          t: 54000,
          o: 1.09271,
          h: 1.09293,
          l: 1.09262,
          c: 1.09262,
          v: 189
        },
        {
          t: 54300,
          o: 1.09263,
          h: 1.09296,
          l: 1.09256,
          c: 1.09288,
          v: 208
        },
        {
          t: 54600,
          o: 1.09287,
          h: 1.09324,
          l: 1.09281,
          c: 1.09323,
          v: 194
        },
        {
          t: 54900,
          o: 1.09322,
          h: 1.09334,
          l: 1.09308,
          c: 1.09322,
          v: 252
        },
        {
          t: 55200,
          o: 1.09322,
          h: 1.09357,
          l: 1.09322,
          c: 1.09342,
          v: 180
        },
        {
          t: 55500,
          o: 1.09342,
          h: 1.09378,
          l: 1.09332,
          c: 1.09374,
          v: 225
        },
        {
          t: 55800,
          o: 1.09373,
          h: 1.09375,
          l: 1.09334,
          c: 1.09334,
          v: 208
        },
        {
          t: 56100,
          o: 1.09334,
          h: 1.0934,
          l: 1.0931,
          c: 1.09321,
          v: 270
        },
        {
          t: 56400,
          o: 1.09321,
          h: 1.09328,
          l: 1.09293,
          c: 1.09295,
          v: 209
        },
        {
          t: 56700,
          o: 1.09294,
          h: 1.09306,
          l: 1.09254,
          c: 1.09262,
          v: 237
        },
        {
          t: 57000,
          o: 1.0926,
          h: 1.09281,
          l: 1.09213,
          c: 1.09219,
          v: 347
        },
        {
          t: 57300,
          o: 1.09218,
          h: 1.09245,
          l: 1.09189,
          c: 1.09235,
          v: 333
        },
        {
          t: 57600,
          o: 1.09235,
          h: 1.09236,
          l: 1.09216,
          c: 1.09225,
          v: 274
        },
        {
          t: 57900,
          o: 1.09221,
          h: 1.09232,
          l: 1.09194,
          c: 1.09195,
          v: 160
        },
        {
          t: 58200,
          o: 1.09194,
          h: 1.09203,
          l: 1.0919,
          c: 1.09192,
          v: 175
        },
        {
          t: 58500,
          o: 1.09193,
          h: 1.09204,
          l: 1.09149,
          c: 1.09149,
          v: 239
        },
        {
          t: 58800,
          o: 1.09149,
          h: 1.09157,
          l: 1.0912,
          c: 1.09135,
          v: 222
        },
        {
          t: 59100,
          o: 1.09135,
          h: 1.09136,
          l: 1.09122,
          c: 1.0913,
          v: 144
        },
        {
          t: 59400,
          o: 1.0913,
          h: 1.09137,
          l: 1.09105,
          c: 1.09112,
          v: 166
        },
        {
          t: 59700,
          o: 1.09109,
          h: 1.0912,
          l: 1.09101,
          c: 1.09106,
          v: 144
        },
        {
          t: 60000,
          o: 1.09106,
          h: 1.09117,
          l: 1.09086,
          c: 1.09111,
          v: 210
        },
        {
          t: 60300,
          o: 1.09112,
          h: 1.09133,
          l: 1.09108,
          c: 1.09132,
          v: 148
        },
        {
          t: 60600,
          o: 1.09132,
          h: 1.09146,
          l: 1.09128,
          c: 1.0914,
          v: 163
        },
        {
          t: 60900,
          o: 1.0914,
          h: 1.09151,
          l: 1.09135,
          c: 1.09147,
          v: 130
        },
        {
          t: 61200,
          o: 1.09146,
          h: 1.09177,
          l: 1.09145,
          c: 1.09175,
          v: 132
        },
        {
          t: 61500,
          o: 1.09177,
          h: 1.09196,
          l: 1.09154,
          c: 1.09154,
          v: 157
        },
        {
          t: 61800,
          o: 1.09154,
          h: 1.09164,
          l: 1.09149,
          c: 1.0916,
          v: 96
        },
        {
          t: 62100,
          o: 1.09161,
          h: 1.0917,
          l: 1.09159,
          c: 1.09165,
          v: 97
        },
        {
          t: 62400,
          o: 1.09166,
          h: 1.09166,
          l: 1.09144,
          c: 1.09144,
          v: 123
        },
        {
          t: 62700,
          o: 1.09144,
          h: 1.09157,
          l: 1.09142,
          c: 1.09148,
          v: 98
        },
        {
          t: 63000,
          o: 1.09146,
          h: 1.09166,
          l: 1.09135,
          c: 1.09162,
          v: 115
        },
        {
          t: 63300,
          o: 1.09161,
          h: 1.09165,
          l: 1.09144,
          c: 1.09162,
          v: 133
        },
        {
          t: 63600,
          o: 1.09164,
          h: 1.09166,
          l: 1.09123,
          c: 1.09123,
          v: 112
        },
        {
          t: 63900,
          o: 1.09124,
          h: 1.09143,
          l: 1.09117,
          c: 1.09142,
          v: 122
        },
        {
          t: 64200,
          o: 1.0914,
          h: 1.09148,
          l: 1.09115,
          c: 1.09116,
          v: 115
        },
        {
          t: 64500,
          o: 1.09116,
          h: 1.09118,
          l: 1.09098,
          c: 1.09098,
          v: 140
        },
        {
          t: 64800,
          o: 1.09096,
          h: 1.09113,
          l: 1.09089,
          c: 1.09111,
          v: 138
        },
        {
          t: 65100,
          o: 1.09112,
          h: 1.09128,
          l: 1.09112,
          c: 1.09127,
          v: 52
        },
        {
          t: 65400,
          o: 1.09127,
          h: 1.09145,
          l: 1.09122,
          c: 1.09138,
          v: 112
        },
        {
          t: 65700,
          o: 1.09137,
          h: 1.09137,
          l: 1.09125,
          c: 1.09135,
          v: 70
        },
        {
          t: 66000,
          o: 1.0913,
          h: 1.09149,
          l: 1.09129,
          c: 1.09145,
          v: 105
        },
        {
          t: 66300,
          o: 1.09146,
          h: 1.09162,
          l: 1.09146,
          c: 1.09147,
          v: 131
        },
        {
          t: 66600,
          o: 1.09145,
          h: 1.09152,
          l: 1.0914,
          c: 1.09142,
          v: 103
        },
        {
          t: 66900,
          o: 1.09142,
          h: 1.09158,
          l: 1.09142,
          c: 1.0915,
          v: 63
        },
        {
          t: 67200,
          o: 1.09151,
          h: 1.09157,
          l: 1.09147,
          c: 1.09149,
          v: 64
        },
        {
          t: 67500,
          o: 1.0915,
          h: 1.09152,
          l: 1.0914,
          c: 1.09143,
          v: 68
        },
        {
          t: 67800,
          o: 1.09142,
          h: 1.09142,
          l: 1.0913,
          c: 1.0913,
          v: 82
        },
        {
          t: 68100,
          o: 1.0913,
          h: 1.09133,
          l: 1.09128,
          c: 1.09132,
          v: 47
        },
        {
          t: 68400,
          o: 1.09131,
          h: 1.09136,
          l: 1.09126,
          c: 1.09126,
          v: 73
        },
        {
          t: 68700,
          o: 1.09126,
          h: 1.09127,
          l: 1.0912,
          c: 1.09123,
          v: 53
        },
        {
          t: 69000,
          o: 1.09125,
          h: 1.09137,
          l: 1.09122,
          c: 1.09136,
          v: 58
        },
        {
          t: 69300,
          o: 1.09135,
          h: 1.0915,
          l: 1.09135,
          c: 1.09147,
          v: 55
        },
        {
          t: 69600,
          o: 1.09145,
          h: 1.09158,
          l: 1.09144,
          c: 1.09156,
          v: 49
        },
        {
          t: 69900,
          o: 1.09155,
          h: 1.09157,
          l: 1.09144,
          c: 1.09147,
          v: 67
        },
        {
          t: 70200,
          o: 1.09148,
          h: 1.09152,
          l: 1.09147,
          c: 1.09151,
          v: 33
        },
        {
          t: 70500,
          o: 1.09151,
          h: 1.09153,
          l: 1.09146,
          c: 1.09148,
          v: 38
        },
        {
          t: 70800,
          o: 1.09147,
          h: 1.09147,
          l: 1.09135,
          c: 1.09139,
          v: 58
        },
        {
          t: 71100,
          o: 1.0914,
          h: 1.09145,
          l: 1.09139,
          c: 1.09142,
          v: 36
        },
        {
          t: 71400,
          o: 1.09141,
          h: 1.09141,
          l: 1.09135,
          c: 1.0914,
          v: 46
        },
        {
          t: 71700,
          o: 1.0914,
          h: 1.09145,
          l: 1.09134,
          c: 1.09134,
          v: 135
        },
        {
          t: 72000,
          o: 1.09137,
          h: 1.09148,
          l: 1.09133,
          c: 1.09134,
          v: 100
        },
        {
          t: 72300,
          o: 1.09135,
          h: 1.09138,
          l: 1.09128,
          c: 1.09134,
          v: 61
        },
        {
          t: 72600,
          o: 1.09135,
          h: 1.09135,
          l: 1.09124,
          c: 1.09125,
          v: 73
        },
        {
          t: 72900,
          o: 1.09125,
          h: 1.0913,
          l: 1.09124,
          c: 1.09124,
          v: 54
        },
        {
          t: 73200,
          o: 1.09125,
          h: 1.09125,
          l: 1.09114,
          c: 1.09114,
          v: 64
        },
        {
          t: 73500,
          o: 1.09114,
          h: 1.09115,
          l: 1.09088,
          c: 1.09089,
          v: 74
        },
        {
          t: 73800,
          o: 1.09089,
          h: 1.09103,
          l: 1.09088,
          c: 1.09091,
          v: 88
        },
        {
          t: 74100,
          o: 1.09089,
          h: 1.09103,
          l: 1.09084,
          c: 1.09094,
          v: 98
        },
        {
          t: 74400,
          o: 1.09093,
          h: 1.09093,
          l: 1.09072,
          c: 1.09085,
          v: 99
        },
        {
          t: 74700,
          o: 1.09088,
          h: 1.09115,
          l: 1.09088,
          c: 1.09114,
          v: 159
        },
        {
          t: 75000,
          o: 1.09113,
          h: 1.09125,
          l: 1.09113,
          c: 1.09124,
          v: 147
        },
        {
          t: 75300,
          o: 1.09125,
          h: 1.09134,
          l: 1.09116,
          c: 1.09119,
          v: 185
        },
        {
          t: 75600,
          o: 1.0912,
          h: 1.0913,
          l: 1.09118,
          c: 1.0913,
          v: 64
        },
        {
          t: 75900,
          o: 1.09131,
          h: 1.09132,
          l: 1.0912,
          c: 1.0912,
          v: 39
        },
        {
          t: 76200,
          o: 1.09121,
          h: 1.09121,
          l: 1.0911,
          c: 1.0911,
          v: 28
        },
        {
          t: 76500,
          o: 1.09112,
          h: 1.09112,
          l: 1.09109,
          c: 1.0911,
          v: 13
        },
        {
          t: 76800,
          o: 1.0911,
          h: 1.09114,
          l: 1.09104,
          c: 1.09105,
          v: 30
        },
        {
          t: 77100,
          o: 1.09104,
          h: 1.09106,
          l: 1.09098,
          c: 1.091,
          v: 65
        },
        {
          t: 77400,
          o: 1.09101,
          h: 1.09105,
          l: 1.09099,
          c: 1.09104,
          v: 38
        },
        {
          t: 77700,
          o: 1.09103,
          h: 1.09111,
          l: 1.09103,
          c: 1.09105,
          v: 29
        },
        {
          t: 78000,
          o: 1.09104,
          h: 1.09105,
          l: 1.091,
          c: 1.09102,
          v: 38
        },
        {
          t: 78300,
          o: 1.09103,
          h: 1.09106,
          l: 1.09101,
          c: 1.09102,
          v: 57
        },
        {
          t: 78600,
          o: 1.09102,
          h: 1.09108,
          l: 1.09101,
          c: 1.09101,
          v: 62
        },
        {
          t: 78900,
          o: 1.09103,
          h: 1.09108,
          l: 1.09093,
          c: 1.09102,
          v: 97
        },
        {
          t: 79200,
          o: 1.09102,
          h: 1.09105,
          l: 1.09085,
          c: 1.09094,
          v: 23
        },
        {
          t: 79500,
          o: 1.09096,
          h: 1.09108,
          l: 1.09096,
          c: 1.09106,
          v: 34
        },
        {
          t: 79800,
          o: 1.09106,
          h: 1.09113,
          l: 1.09101,
          c: 1.09112,
          v: 52
        },
        {
          t: 80100,
          o: 1.09112,
          h: 1.09112,
          l: 1.09103,
          c: 1.09103,
          v: 13
        },
        {
          t: 80400,
          o: 1.09108,
          h: 1.09111,
          l: 1.09101,
          c: 1.09106,
          v: 26
        },
        {
          t: 80700,
          o: 1.09108,
          h: 1.09113,
          l: 1.09103,
          c: 1.09104,
          v: 230
        },
        {
          t: 81000,
          o: 1.09103,
          h: 1.09113,
          l: 1.09099,
          c: 1.09104,
          v: 31
        },
        {
          t: 81300,
          o: 1.09099,
          h: 1.09105,
          l: 1.09097,
          c: 1.09101,
          v: 28
        },
        {
          t: 81600,
          o: 1.09104,
          h: 1.09133,
          l: 1.09103,
          c: 1.09119,
          v: 80
        },
        {
          t: 81900,
          o: 1.0912,
          h: 1.09122,
          l: 1.09114,
          c: 1.09117,
          v: 125
        },
        {
          t: 82200,
          o: 1.09119,
          h: 1.0912,
          l: 1.09109,
          c: 1.09117,
          v: 47
        },
        {
          t: 82500,
          o: 1.09108,
          h: 1.09128,
          l: 1.09099,
          c: 1.09101,
          v: 128
        },
        {
          t: 82800,
          o: 1.09103,
          h: 1.0912,
          l: 1.09102,
          c: 1.09116,
          v: 88
        },
        {
          t: 83100,
          o: 1.09116,
          h: 1.09127,
          l: 1.09115,
          c: 1.09127,
          v: 43
        },
        {
          t: 83400,
          o: 1.09127,
          h: 1.09127,
          l: 1.09115,
          c: 1.09123,
          v: 65
        },
        {
          t: 83700,
          o: 1.09122,
          h: 1.09128,
          l: 1.09114,
          c: 1.09115,
          v: 47
        },
        {
          t: 84000,
          o: 1.09113,
          h: 1.09116,
          l: 1.091,
          c: 1.09101,
          v: 73
        },
        {
          t: 84300,
          o: 1.091,
          h: 1.09102,
          l: 1.0909,
          c: 1.0909,
          v: 94
        },
        {
          t: 84600,
          o: 1.09091,
          h: 1.09095,
          l: 1.09088,
          c: 1.09091,
          v: 90
        },
        {
          t: 84900,
          o: 1.09088,
          h: 1.09088,
          l: 1.09085,
          c: 1.09087,
          v: 62
        },
        {
          t: 85200,
          o: 1.09087,
          h: 1.09092,
          l: 1.09087,
          c: 1.09087,
          v: 38
        },
        {
          t: 85500,
          o: 1.09087,
          h: 1.09103,
          l: 1.09083,
          c: 1.09103,
          v: 56
        },
        {
          t: 85800,
          o: 1.09103,
          h: 1.09108,
          l: 1.091,
          c: 1.09101,
          v: 47
        },
        {
          t: 86100,
          o: 1.09102,
          h: 1.09102,
          l: 1.09096,
          c: 1.09098,
          v: 60
        }
      ]
    },
    {
      d: 1581379200,
      b: [
        {
          t: 0,
          o: 1.09098,
          h: 1.09098,
          l: 1.0909,
          c: 1.09092,
          v: 66
        },
        {
          t: 300,
          o: 1.09093,
          h: 1.09102,
          l: 1.09091,
          c: 1.09093,
          v: 55
        },
        {
          t: 600,
          o: 1.09091,
          h: 1.09091,
          l: 1.0908,
          c: 1.0908,
          v: 59
        },
        {
          t: 900,
          o: 1.0908,
          h: 1.09082,
          l: 1.09075,
          c: 1.09077,
          v: 66
        },
        {
          t: 1200,
          o: 1.09076,
          h: 1.09087,
          l: 1.09076,
          c: 1.09087,
          v: 65
        },
        {
          t: 1500,
          o: 1.09086,
          h: 1.09086,
          l: 1.09081,
          c: 1.09081,
          v: 44
        },
        {
          t: 1800,
          o: 1.0908,
          h: 1.09097,
          l: 1.0908,
          c: 1.09093,
          v: 41
        },
        {
          t: 2100,
          o: 1.09093,
          h: 1.09097,
          l: 1.0909,
          c: 1.09097,
          v: 39
        },
        {
          t: 2400,
          o: 1.09098,
          h: 1.09103,
          l: 1.09098,
          c: 1.09103,
          v: 36
        },
        {
          t: 2700,
          o: 1.09101,
          h: 1.09109,
          l: 1.09101,
          c: 1.09101,
          v: 57
        },
        {
          t: 3000,
          o: 1.09098,
          h: 1.09102,
          l: 1.09092,
          c: 1.09093,
          v: 43
        },
        {
          t: 3300,
          o: 1.09092,
          h: 1.09096,
          l: 1.0909,
          c: 1.09096,
          v: 42
        },
        {
          t: 3600,
          o: 1.09092,
          h: 1.09101,
          l: 1.09092,
          c: 1.09095,
          v: 75
        },
        {
          t: 3900,
          o: 1.09096,
          h: 1.09101,
          l: 1.09095,
          c: 1.091,
          v: 41
        },
        {
          t: 4200,
          o: 1.09102,
          h: 1.09121,
          l: 1.09099,
          c: 1.0912,
          v: 46
        },
        {
          t: 4500,
          o: 1.09117,
          h: 1.09147,
          l: 1.09115,
          c: 1.09138,
          v: 138
        },
        {
          t: 4800,
          o: 1.09136,
          h: 1.09148,
          l: 1.0913,
          c: 1.09131,
          v: 150
        },
        {
          t: 5100,
          o: 1.09131,
          h: 1.09138,
          l: 1.09127,
          c: 1.09137,
          v: 66
        },
        {
          t: 5400,
          o: 1.09137,
          h: 1.09137,
          l: 1.09125,
          c: 1.0913,
          v: 93
        },
        {
          t: 5700,
          o: 1.09131,
          h: 1.09141,
          l: 1.09127,
          c: 1.09134,
          v: 102
        },
        {
          t: 6000,
          o: 1.09133,
          h: 1.09141,
          l: 1.09131,
          c: 1.09132,
          v: 112
        },
        {
          t: 6300,
          o: 1.09133,
          h: 1.09143,
          l: 1.0913,
          c: 1.0914,
          v: 106
        },
        {
          t: 6600,
          o: 1.09141,
          h: 1.09147,
          l: 1.0914,
          c: 1.09146,
          v: 72
        },
        {
          t: 6900,
          o: 1.09147,
          h: 1.09148,
          l: 1.09135,
          c: 1.09136,
          v: 60
        },
        {
          t: 7200,
          o: 1.09135,
          h: 1.09138,
          l: 1.09132,
          c: 1.09137,
          v: 37
        },
        {
          t: 7500,
          o: 1.09137,
          h: 1.09137,
          l: 1.09125,
          c: 1.09126,
          v: 48
        },
        {
          t: 7800,
          o: 1.09126,
          h: 1.09126,
          l: 1.09114,
          c: 1.09122,
          v: 50
        },
        {
          t: 8100,
          o: 1.09121,
          h: 1.09132,
          l: 1.09119,
          c: 1.09127,
          v: 46
        },
        {
          t: 8400,
          o: 1.09127,
          h: 1.09143,
          l: 1.09127,
          c: 1.09138,
          v: 60
        },
        {
          t: 8700,
          o: 1.09139,
          h: 1.09147,
          l: 1.09134,
          c: 1.09147,
          v: 39
        },
        {
          t: 9000,
          o: 1.09147,
          h: 1.09152,
          l: 1.09142,
          c: 1.09148,
          v: 100
        },
        {
          t: 9300,
          o: 1.09149,
          h: 1.09152,
          l: 1.09146,
          c: 1.09152,
          v: 79
        },
        {
          t: 9600,
          o: 1.09151,
          h: 1.09157,
          l: 1.0914,
          c: 1.0914,
          v: 90
        },
        {
          t: 9900,
          o: 1.09139,
          h: 1.0914,
          l: 1.09124,
          c: 1.09125,
          v: 72
        },
        {
          t: 10200,
          o: 1.09127,
          h: 1.09127,
          l: 1.09117,
          c: 1.0912,
          v: 43
        },
        {
          t: 10500,
          o: 1.0912,
          h: 1.09137,
          l: 1.0912,
          c: 1.09137,
          v: 41
        },
        {
          t: 10800,
          o: 1.09137,
          h: 1.09137,
          l: 1.09125,
          c: 1.09132,
          v: 53
        },
        {
          t: 11100,
          o: 1.09131,
          h: 1.09147,
          l: 1.09126,
          c: 1.09142,
          v: 64
        },
        {
          t: 11400,
          o: 1.09142,
          h: 1.09155,
          l: 1.09142,
          c: 1.09151,
          v: 29
        },
        {
          t: 11700,
          o: 1.0915,
          h: 1.09162,
          l: 1.0915,
          c: 1.09157,
          v: 70
        },
        {
          t: 12000,
          o: 1.09159,
          h: 1.09161,
          l: 1.09155,
          c: 1.0916,
          v: 47
        },
        {
          t: 12300,
          o: 1.0916,
          h: 1.09162,
          l: 1.0916,
          c: 1.09161,
          v: 12
        },
        {
          t: 12600,
          o: 1.09162,
          h: 1.09167,
          l: 1.09154,
          c: 1.09155,
          v: 79
        },
        {
          t: 12900,
          o: 1.09154,
          h: 1.09154,
          l: 1.09139,
          c: 1.09139,
          v: 24
        },
        {
          t: 13200,
          o: 1.0914,
          h: 1.0914,
          l: 1.09128,
          c: 1.09131,
          v: 39
        },
        {
          t: 13500,
          o: 1.09132,
          h: 1.09136,
          l: 1.09122,
          c: 1.09126,
          v: 62
        },
        {
          t: 13800,
          o: 1.0913,
          h: 1.09131,
          l: 1.09121,
          c: 1.09121,
          v: 68
        },
        {
          t: 14100,
          o: 1.09121,
          h: 1.09135,
          l: 1.09121,
          c: 1.09131,
          v: 50
        },
        {
          t: 14400,
          o: 1.09131,
          h: 1.09131,
          l: 1.09119,
          c: 1.09121,
          v: 42
        },
        {
          t: 14700,
          o: 1.09124,
          h: 1.09133,
          l: 1.09124,
          c: 1.09133,
          v: 14
        },
        {
          t: 15000,
          o: 1.09133,
          h: 1.09133,
          l: 1.0913,
          c: 1.0913,
          v: 16
        },
        {
          t: 15300,
          o: 1.0913,
          h: 1.09131,
          l: 1.09125,
          c: 1.09125,
          v: 25
        },
        {
          t: 15600,
          o: 1.09126,
          h: 1.09126,
          l: 1.09125,
          c: 1.09126,
          v: 20
        },
        {
          t: 15900,
          o: 1.09122,
          h: 1.09127,
          l: 1.09109,
          c: 1.0911,
          v: 48
        },
        {
          t: 16200,
          o: 1.09111,
          h: 1.09112,
          l: 1.09107,
          c: 1.09111,
          v: 46
        },
        {
          t: 16500,
          o: 1.09114,
          h: 1.09117,
          l: 1.09112,
          c: 1.09115,
          v: 33
        },
        {
          t: 16800,
          o: 1.09112,
          h: 1.09113,
          l: 1.09104,
          c: 1.09105,
          v: 59
        },
        {
          t: 17100,
          o: 1.09105,
          h: 1.09107,
          l: 1.09094,
          c: 1.091,
          v: 73
        },
        {
          t: 17400,
          o: 1.09098,
          h: 1.09101,
          l: 1.09088,
          c: 1.09091,
          v: 111
        },
        {
          t: 17700,
          o: 1.09091,
          h: 1.09094,
          l: 1.09084,
          c: 1.09089,
          v: 128
        },
        {
          t: 18000,
          o: 1.0909,
          h: 1.09091,
          l: 1.09074,
          c: 1.09074,
          v: 91
        },
        {
          t: 18300,
          o: 1.09074,
          h: 1.09081,
          l: 1.09072,
          c: 1.09078,
          v: 55
        },
        {
          t: 18600,
          o: 1.09079,
          h: 1.09082,
          l: 1.09078,
          c: 1.09081,
          v: 53
        },
        {
          t: 18900,
          o: 1.09082,
          h: 1.09112,
          l: 1.09082,
          c: 1.09105,
          v: 95
        },
        {
          t: 19200,
          o: 1.09105,
          h: 1.0911,
          l: 1.091,
          c: 1.09107,
          v: 83
        },
        {
          t: 19500,
          o: 1.09108,
          h: 1.09122,
          l: 1.09108,
          c: 1.09122,
          v: 43
        },
        {
          t: 19800,
          o: 1.09124,
          h: 1.09124,
          l: 1.09113,
          c: 1.09115,
          v: 71
        },
        {
          t: 20100,
          o: 1.09116,
          h: 1.09116,
          l: 1.091,
          c: 1.091,
          v: 44
        },
        {
          t: 20400,
          o: 1.091,
          h: 1.09105,
          l: 1.091,
          c: 1.09105,
          v: 31
        },
        {
          t: 20700,
          o: 1.09104,
          h: 1.09107,
          l: 1.09099,
          c: 1.09105,
          v: 61
        },
        {
          t: 21000,
          o: 1.09104,
          h: 1.09105,
          l: 1.09098,
          c: 1.09099,
          v: 61
        },
        {
          t: 21300,
          o: 1.091,
          h: 1.09118,
          l: 1.091,
          c: 1.09118,
          v: 122
        },
        {
          t: 21600,
          o: 1.09117,
          h: 1.09117,
          l: 1.09099,
          c: 1.091,
          v: 163
        },
        {
          t: 21900,
          o: 1.091,
          h: 1.09102,
          l: 1.09084,
          c: 1.09085,
          v: 83
        },
        {
          t: 22200,
          o: 1.09085,
          h: 1.09098,
          l: 1.09085,
          c: 1.09088,
          v: 93
        },
        {
          t: 22500,
          o: 1.09086,
          h: 1.09097,
          l: 1.0908,
          c: 1.09095,
          v: 86
        },
        {
          t: 22800,
          o: 1.09096,
          h: 1.09098,
          l: 1.09086,
          c: 1.09089,
          v: 112
        },
        {
          t: 23100,
          o: 1.09088,
          h: 1.09089,
          l: 1.09077,
          c: 1.09081,
          v: 98
        },
        {
          t: 23400,
          o: 1.09083,
          h: 1.09092,
          l: 1.09081,
          c: 1.09091,
          v: 44
        },
        {
          t: 23700,
          o: 1.09092,
          h: 1.09096,
          l: 1.09087,
          c: 1.09094,
          v: 58
        },
        {
          t: 24000,
          o: 1.09093,
          h: 1.09102,
          l: 1.09091,
          c: 1.09097,
          v: 66
        },
        {
          t: 24300,
          o: 1.09096,
          h: 1.09098,
          l: 1.09092,
          c: 1.09097,
          v: 54
        },
        {
          t: 24600,
          o: 1.09098,
          h: 1.09103,
          l: 1.09092,
          c: 1.091,
          v: 61
        },
        {
          t: 24900,
          o: 1.09101,
          h: 1.09108,
          l: 1.09098,
          c: 1.09105,
          v: 50
        },
        {
          t: 25200,
          o: 1.0911,
          h: 1.09112,
          l: 1.09064,
          c: 1.09064,
          v: 176
        },
        {
          t: 25500,
          o: 1.09061,
          h: 1.09072,
          l: 1.0906,
          c: 1.09065,
          v: 142
        },
        {
          t: 25800,
          o: 1.09062,
          h: 1.09068,
          l: 1.09058,
          c: 1.09063,
          v: 155
        },
        {
          t: 26100,
          o: 1.09061,
          h: 1.09068,
          l: 1.09053,
          c: 1.09055,
          v: 176
        },
        {
          t: 26400,
          o: 1.09054,
          h: 1.09062,
          l: 1.09052,
          c: 1.09057,
          v: 167
        },
        {
          t: 26700,
          o: 1.09056,
          h: 1.09097,
          l: 1.09056,
          c: 1.09087,
          v: 182
        },
        {
          t: 27000,
          o: 1.09087,
          h: 1.09099,
          l: 1.09078,
          c: 1.09098,
          v: 168
        },
        {
          t: 27300,
          o: 1.09097,
          h: 1.0912,
          l: 1.09097,
          c: 1.09109,
          v: 175
        },
        {
          t: 27600,
          o: 1.09108,
          h: 1.09121,
          l: 1.09106,
          c: 1.09109,
          v: 146
        },
        {
          t: 27900,
          o: 1.09108,
          h: 1.09119,
          l: 1.09107,
          c: 1.09119,
          v: 101
        },
        {
          t: 28200,
          o: 1.09118,
          h: 1.09134,
          l: 1.09114,
          c: 1.09124,
          v: 111
        },
        {
          t: 28500,
          o: 1.09128,
          h: 1.09139,
          l: 1.09122,
          c: 1.09138,
          v: 169
        },
        {
          t: 28800,
          o: 1.09138,
          h: 1.09148,
          l: 1.09111,
          c: 1.09113,
          v: 296
        },
        {
          t: 29100,
          o: 1.09114,
          h: 1.09116,
          l: 1.09082,
          c: 1.09098,
          v: 181
        },
        {
          t: 29400,
          o: 1.09099,
          h: 1.09104,
          l: 1.09059,
          c: 1.09076,
          v: 198
        },
        {
          t: 29700,
          o: 1.09076,
          h: 1.09092,
          l: 1.0907,
          c: 1.09077,
          v: 159
        },
        {
          t: 30000,
          o: 1.09077,
          h: 1.09077,
          l: 1.09054,
          c: 1.09067,
          v: 119
        },
        {
          t: 30300,
          o: 1.09068,
          h: 1.09094,
          l: 1.09062,
          c: 1.09084,
          v: 140
        },
        {
          t: 30600,
          o: 1.09083,
          h: 1.09093,
          l: 1.09071,
          c: 1.09093,
          v: 147
        },
        {
          t: 30900,
          o: 1.09093,
          h: 1.09095,
          l: 1.09067,
          c: 1.09073,
          v: 131
        },
        {
          t: 31200,
          o: 1.09073,
          h: 1.09095,
          l: 1.09053,
          c: 1.09093,
          v: 159
        },
        {
          t: 31500,
          o: 1.09092,
          h: 1.09096,
          l: 1.09083,
          c: 1.09094,
          v: 151
        },
        {
          t: 31800,
          o: 1.09095,
          h: 1.09114,
          l: 1.09082,
          c: 1.09104,
          v: 189
        },
        {
          t: 32100,
          o: 1.09105,
          h: 1.09113,
          l: 1.09098,
          c: 1.09102,
          v: 108
        },
        {
          t: 32400,
          o: 1.09103,
          h: 1.09106,
          l: 1.09076,
          c: 1.09102,
          v: 189
        },
        {
          t: 32700,
          o: 1.09101,
          h: 1.09101,
          l: 1.0909,
          c: 1.09097,
          v: 146
        },
        {
          t: 33000,
          o: 1.09098,
          h: 1.09143,
          l: 1.09097,
          c: 1.09142,
          v: 170
        },
        {
          t: 33300,
          o: 1.09141,
          h: 1.09143,
          l: 1.09115,
          c: 1.09123,
          v: 169
        },
        {
          t: 33600,
          o: 1.09125,
          h: 1.09141,
          l: 1.09118,
          c: 1.09126,
          v: 152
        },
        {
          t: 33900,
          o: 1.09127,
          h: 1.09143,
          l: 1.09121,
          c: 1.09122,
          v: 126
        },
        {
          t: 34200,
          o: 1.09123,
          h: 1.09152,
          l: 1.09123,
          c: 1.09137,
          v: 217
        },
        {
          t: 34500,
          o: 1.09138,
          h: 1.09153,
          l: 1.09136,
          c: 1.09138,
          v: 162
        },
        {
          t: 34800,
          o: 1.09138,
          h: 1.09139,
          l: 1.091,
          c: 1.091,
          v: 116
        },
        {
          t: 35100,
          o: 1.091,
          h: 1.09122,
          l: 1.0909,
          c: 1.09119,
          v: 184
        },
        {
          t: 35400,
          o: 1.09118,
          h: 1.09137,
          l: 1.09107,
          c: 1.09137,
          v: 141
        },
        {
          t: 35700,
          o: 1.09135,
          h: 1.09139,
          l: 1.0913,
          c: 1.09132,
          v: 108
        },
        {
          t: 36000,
          o: 1.09129,
          h: 1.09131,
          l: 1.09098,
          c: 1.09113,
          v: 140
        },
        {
          t: 36300,
          o: 1.09115,
          h: 1.09147,
          l: 1.09113,
          c: 1.09134,
          v: 179
        },
        {
          t: 36600,
          o: 1.09135,
          h: 1.09135,
          l: 1.0911,
          c: 1.09124,
          v: 111
        },
        {
          t: 36900,
          o: 1.09124,
          h: 1.09133,
          l: 1.09118,
          c: 1.09133,
          v: 106
        },
        {
          t: 37200,
          o: 1.09138,
          h: 1.09143,
          l: 1.09123,
          c: 1.09124,
          v: 89
        },
        {
          t: 37500,
          o: 1.09123,
          h: 1.09163,
          l: 1.09121,
          c: 1.0916,
          v: 190
        },
        {
          t: 37800,
          o: 1.0916,
          h: 1.09174,
          l: 1.0915,
          c: 1.09153,
          v: 117
        },
        {
          t: 38100,
          o: 1.09153,
          h: 1.09168,
          l: 1.09147,
          c: 1.09163,
          v: 140
        },
        {
          t: 38400,
          o: 1.09162,
          h: 1.09174,
          l: 1.09149,
          c: 1.09174,
          v: 130
        },
        {
          t: 38700,
          o: 1.09173,
          h: 1.09173,
          l: 1.09147,
          c: 1.09159,
          v: 127
        },
        {
          t: 39000,
          o: 1.09159,
          h: 1.09187,
          l: 1.09159,
          c: 1.0918,
          v: 111
        },
        {
          t: 39300,
          o: 1.09181,
          h: 1.09191,
          l: 1.09174,
          c: 1.09177,
          v: 197
        },
        {
          t: 39600,
          o: 1.09175,
          h: 1.09191,
          l: 1.09168,
          c: 1.09188,
          v: 156
        },
        {
          t: 39900,
          o: 1.09187,
          h: 1.09202,
          l: 1.0918,
          c: 1.09193,
          v: 141
        },
        {
          t: 40200,
          o: 1.09193,
          h: 1.09194,
          l: 1.09177,
          c: 1.09178,
          v: 100
        },
        {
          t: 40500,
          o: 1.09179,
          h: 1.09189,
          l: 1.09159,
          c: 1.09163,
          v: 133
        },
        {
          t: 40800,
          o: 1.09162,
          h: 1.09168,
          l: 1.09147,
          c: 1.09153,
          v: 111
        },
        {
          t: 41100,
          o: 1.09153,
          h: 1.09163,
          l: 1.09152,
          c: 1.09157,
          v: 83
        },
        {
          t: 41400,
          o: 1.09158,
          h: 1.09173,
          l: 1.09157,
          c: 1.09162,
          v: 108
        },
        {
          t: 41700,
          o: 1.09162,
          h: 1.09163,
          l: 1.09146,
          c: 1.09148,
          v: 90
        },
        {
          t: 42000,
          o: 1.09152,
          h: 1.09152,
          l: 1.09138,
          c: 1.09141,
          v: 99
        },
        {
          t: 42300,
          o: 1.09141,
          h: 1.09141,
          l: 1.0913,
          c: 1.09134,
          v: 110
        },
        {
          t: 42600,
          o: 1.09135,
          h: 1.09152,
          l: 1.09126,
          c: 1.09152,
          v: 112
        },
        {
          t: 42900,
          o: 1.09151,
          h: 1.09151,
          l: 1.09121,
          c: 1.09123,
          v: 124
        },
        {
          t: 43200,
          o: 1.09122,
          h: 1.09137,
          l: 1.09122,
          c: 1.09131,
          v: 145
        },
        {
          t: 43500,
          o: 1.09131,
          h: 1.09133,
          l: 1.09106,
          c: 1.09109,
          v: 139
        },
        {
          t: 43800,
          o: 1.0911,
          h: 1.09145,
          l: 1.09106,
          c: 1.09134,
          v: 161
        },
        {
          t: 44100,
          o: 1.09131,
          h: 1.09142,
          l: 1.09114,
          c: 1.09139,
          v: 106
        },
        {
          t: 44400,
          o: 1.09139,
          h: 1.09139,
          l: 1.09128,
          c: 1.09134,
          v: 99
        },
        {
          t: 44700,
          o: 1.0913,
          h: 1.0914,
          l: 1.09124,
          c: 1.09136,
          v: 110
        },
        {
          t: 45000,
          o: 1.09136,
          h: 1.09143,
          l: 1.09135,
          c: 1.09142,
          v: 86
        },
        {
          t: 45300,
          o: 1.09141,
          h: 1.09157,
          l: 1.09137,
          c: 1.09146,
          v: 85
        },
        {
          t: 45600,
          o: 1.09147,
          h: 1.09155,
          l: 1.09141,
          c: 1.09141,
          v: 87
        },
        {
          t: 45900,
          o: 1.09141,
          h: 1.09147,
          l: 1.0913,
          c: 1.09136,
          v: 120
        },
        {
          t: 46200,
          o: 1.09137,
          h: 1.09137,
          l: 1.09119,
          c: 1.09121,
          v: 162
        },
        {
          t: 46500,
          o: 1.0912,
          h: 1.0912,
          l: 1.09068,
          c: 1.09087,
          v: 412
        },
        {
          t: 46800,
          o: 1.09085,
          h: 1.09093,
          l: 1.09021,
          c: 1.09023,
          v: 332
        },
        {
          t: 47100,
          o: 1.09023,
          h: 1.09048,
          l: 1.09015,
          c: 1.0904,
          v: 305
        },
        {
          t: 47400,
          o: 1.09043,
          h: 1.09043,
          l: 1.08994,
          c: 1.09006,
          v: 267
        },
        {
          t: 47700,
          o: 1.09007,
          h: 1.09041,
          l: 1.09005,
          c: 1.09031,
          v: 313
        },
        {
          t: 48000,
          o: 1.0903,
          h: 1.09036,
          l: 1.09016,
          c: 1.09027,
          v: 186
        },
        {
          t: 48300,
          o: 1.09031,
          h: 1.09031,
          l: 1.08972,
          c: 1.08972,
          v: 364
        },
        {
          t: 48600,
          o: 1.08973,
          h: 1.08986,
          l: 1.08936,
          c: 1.08938,
          v: 383
        },
        {
          t: 48900,
          o: 1.08938,
          h: 1.09017,
          l: 1.08908,
          c: 1.09015,
          v: 312
        },
        {
          t: 49200,
          o: 1.09014,
          h: 1.09034,
          l: 1.08986,
          c: 1.08987,
          v: 279
        },
        {
          t: 49500,
          o: 1.08984,
          h: 1.08984,
          l: 1.08945,
          c: 1.08971,
          v: 275
        },
        {
          t: 49800,
          o: 1.08972,
          h: 1.09018,
          l: 1.08971,
          c: 1.09016,
          v: 217
        },
        {
          t: 50100,
          o: 1.09017,
          h: 1.09035,
          l: 1.09005,
          c: 1.09031,
          v: 257
        },
        {
          t: 50400,
          o: 1.09029,
          h: 1.09093,
          l: 1.0901,
          c: 1.09041,
          v: 378
        },
        {
          t: 50700,
          o: 1.09042,
          h: 1.09096,
          l: 1.09037,
          c: 1.09073,
          v: 348
        },
        {
          t: 51000,
          o: 1.09074,
          h: 1.09113,
          l: 1.09052,
          c: 1.09084,
          v: 334
        },
        {
          t: 51300,
          o: 1.09085,
          h: 1.09132,
          l: 1.09084,
          c: 1.09129,
          v: 339
        },
        {
          t: 51600,
          o: 1.09128,
          h: 1.0917,
          l: 1.09127,
          c: 1.0916,
          v: 252
        },
        {
          t: 51900,
          o: 1.09163,
          h: 1.09163,
          l: 1.0913,
          c: 1.09131,
          v: 188
        },
        {
          t: 52200,
          o: 1.0913,
          h: 1.09151,
          l: 1.09117,
          c: 1.09147,
          v: 321
        },
        {
          t: 52500,
          o: 1.09147,
          h: 1.09177,
          l: 1.09134,
          c: 1.09173,
          v: 294
        },
        {
          t: 52800,
          o: 1.09171,
          h: 1.09172,
          l: 1.09153,
          c: 1.09164,
          v: 233
        },
        {
          t: 53100,
          o: 1.09163,
          h: 1.09177,
          l: 1.09148,
          c: 1.09157,
          v: 200
        },
        {
          t: 53400,
          o: 1.09153,
          h: 1.09224,
          l: 1.09153,
          c: 1.09192,
          v: 269
        },
        {
          t: 53700,
          o: 1.09188,
          h: 1.09203,
          l: 1.09176,
          c: 1.09197,
          v: 251
        },
        {
          t: 54000,
          o: 1.09197,
          h: 1.09205,
          l: 1.09182,
          c: 1.09204,
          v: 263
        },
        {
          t: 54300,
          o: 1.09206,
          h: 1.09243,
          l: 1.09184,
          c: 1.0924,
          v: 248
        },
        {
          t: 54600,
          o: 1.09239,
          h: 1.09242,
          l: 1.09206,
          c: 1.09214,
          v: 286
        },
        {
          t: 54900,
          o: 1.09214,
          h: 1.09237,
          l: 1.0917,
          c: 1.09206,
          v: 332
        },
        {
          t: 55200,
          o: 1.09205,
          h: 1.0923,
          l: 1.09176,
          c: 1.0918,
          v: 234
        },
        {
          t: 55500,
          o: 1.09181,
          h: 1.09192,
          l: 1.0916,
          c: 1.0918,
          v: 347
        },
        {
          t: 55800,
          o: 1.09181,
          h: 1.09207,
          l: 1.09152,
          c: 1.09165,
          v: 347
        },
        {
          t: 56100,
          o: 1.09165,
          h: 1.09166,
          l: 1.0914,
          c: 1.0914,
          v: 243
        },
        {
          t: 56400,
          o: 1.0914,
          h: 1.09146,
          l: 1.09111,
          c: 1.09133,
          v: 291
        },
        {
          t: 56700,
          o: 1.09131,
          h: 1.09161,
          l: 1.0912,
          c: 1.09146,
          v: 254
        },
        {
          t: 57000,
          o: 1.09145,
          h: 1.09158,
          l: 1.09114,
          c: 1.09115,
          v: 326
        },
        {
          t: 57300,
          o: 1.09119,
          h: 1.09171,
          l: 1.09115,
          c: 1.09156,
          v: 358
        },
        {
          t: 57600,
          o: 1.09156,
          h: 1.09168,
          l: 1.09125,
          c: 1.09168,
          v: 252
        },
        {
          t: 57900,
          o: 1.09166,
          h: 1.09187,
          l: 1.0915,
          c: 1.09162,
          v: 240
        },
        {
          t: 58200,
          o: 1.09163,
          h: 1.0919,
          l: 1.09158,
          c: 1.09184,
          v: 232
        },
        {
          t: 58500,
          o: 1.09185,
          h: 1.09212,
          l: 1.09176,
          c: 1.0919,
          v: 183
        },
        {
          t: 58800,
          o: 1.09192,
          h: 1.09203,
          l: 1.0918,
          c: 1.09182,
          v: 195
        },
        {
          t: 59100,
          o: 1.09183,
          h: 1.09187,
          l: 1.09175,
          c: 1.09183,
          v: 126
        },
        {
          t: 59400,
          o: 1.09183,
          h: 1.09185,
          l: 1.09165,
          c: 1.09171,
          v: 227
        },
        {
          t: 59700,
          o: 1.09175,
          h: 1.09231,
          l: 1.09169,
          c: 1.09175,
          v: 265
        },
        {
          t: 60000,
          o: 1.09175,
          h: 1.09195,
          l: 1.09132,
          c: 1.09137,
          v: 258
        },
        {
          t: 60300,
          o: 1.0914,
          h: 1.09153,
          l: 1.09132,
          c: 1.0915,
          v: 132
        },
        {
          t: 60600,
          o: 1.0915,
          h: 1.09163,
          l: 1.0914,
          c: 1.09162,
          v: 138
        },
        {
          t: 60900,
          o: 1.09162,
          h: 1.09168,
          l: 1.09136,
          c: 1.09161,
          v: 194
        },
        {
          t: 61200,
          o: 1.09162,
          h: 1.09198,
          l: 1.09156,
          c: 1.09197,
          v: 223
        },
        {
          t: 61500,
          o: 1.09198,
          h: 1.09233,
          l: 1.09193,
          c: 1.09228,
          v: 205
        },
        {
          t: 61800,
          o: 1.09226,
          h: 1.0923,
          l: 1.092,
          c: 1.09202,
          v: 174
        },
        {
          t: 62100,
          o: 1.09202,
          h: 1.09202,
          l: 1.0918,
          c: 1.09192,
          v: 144
        },
        {
          t: 62400,
          o: 1.09192,
          h: 1.09202,
          l: 1.09175,
          c: 1.09177,
          v: 156
        },
        {
          t: 62700,
          o: 1.09176,
          h: 1.09217,
          l: 1.09175,
          c: 1.09217,
          v: 154
        },
        {
          t: 63000,
          o: 1.09217,
          h: 1.09219,
          l: 1.09199,
          c: 1.09216,
          v: 144
        },
        {
          t: 63300,
          o: 1.09217,
          h: 1.09217,
          l: 1.09201,
          c: 1.09205,
          v: 121
        },
        {
          t: 63600,
          o: 1.09206,
          h: 1.09231,
          l: 1.09203,
          c: 1.09221,
          v: 112
        },
        {
          t: 63900,
          o: 1.0922,
          h: 1.09239,
          l: 1.09216,
          c: 1.09228,
          v: 127
        },
        {
          t: 64200,
          o: 1.09228,
          h: 1.09232,
          l: 1.09201,
          c: 1.09208,
          v: 119
        },
        {
          t: 64500,
          o: 1.09208,
          h: 1.09222,
          l: 1.09204,
          c: 1.09213,
          v: 123
        },
        {
          t: 64800,
          o: 1.09213,
          h: 1.09217,
          l: 1.0918,
          c: 1.09187,
          v: 179
        },
        {
          t: 65100,
          o: 1.09188,
          h: 1.09192,
          l: 1.09176,
          c: 1.09192,
          v: 94
        },
        {
          t: 65400,
          o: 1.09194,
          h: 1.09195,
          l: 1.09183,
          c: 1.09187,
          v: 113
        },
        {
          t: 65700,
          o: 1.09188,
          h: 1.09198,
          l: 1.09174,
          c: 1.09196,
          v: 124
        },
        {
          t: 66000,
          o: 1.09197,
          h: 1.09203,
          l: 1.0919,
          c: 1.09194,
          v: 109
        },
        {
          t: 66300,
          o: 1.09193,
          h: 1.09195,
          l: 1.09171,
          c: 1.09173,
          v: 106
        },
        {
          t: 66600,
          o: 1.09175,
          h: 1.09187,
          l: 1.09166,
          c: 1.09182,
          v: 103
        },
        {
          t: 66900,
          o: 1.09183,
          h: 1.09209,
          l: 1.09181,
          c: 1.09192,
          v: 96
        },
        {
          t: 67200,
          o: 1.09192,
          h: 1.09201,
          l: 1.09182,
          c: 1.09192,
          v: 98
        },
        {
          t: 67500,
          o: 1.09192,
          h: 1.09202,
          l: 1.09185,
          c: 1.09188,
          v: 94
        },
        {
          t: 67800,
          o: 1.09187,
          h: 1.09187,
          l: 1.09175,
          c: 1.09179,
          v: 129
        },
        {
          t: 68100,
          o: 1.09181,
          h: 1.09186,
          l: 1.09175,
          c: 1.09186,
          v: 106
        },
        {
          t: 68400,
          o: 1.09185,
          h: 1.09197,
          l: 1.09185,
          c: 1.09197,
          v: 85
        },
        {
          t: 68700,
          o: 1.09195,
          h: 1.09219,
          l: 1.09195,
          c: 1.09207,
          v: 126
        },
        {
          t: 69000,
          o: 1.09208,
          h: 1.09217,
          l: 1.09207,
          c: 1.09216,
          v: 108
        },
        {
          t: 69300,
          o: 1.09216,
          h: 1.09217,
          l: 1.092,
          c: 1.09205,
          v: 86
        },
        {
          t: 69600,
          o: 1.09206,
          h: 1.09217,
          l: 1.092,
          c: 1.09216,
          v: 85
        },
        {
          t: 69900,
          o: 1.09216,
          h: 1.09228,
          l: 1.09215,
          c: 1.09225,
          v: 138
        },
        {
          t: 70200,
          o: 1.09224,
          h: 1.09224,
          l: 1.09197,
          c: 1.09198,
          v: 117
        },
        {
          t: 70500,
          o: 1.09199,
          h: 1.09206,
          l: 1.09187,
          c: 1.09189,
          v: 71
        },
        {
          t: 70800,
          o: 1.09188,
          h: 1.09205,
          l: 1.09188,
          c: 1.09203,
          v: 70
        },
        {
          t: 71100,
          o: 1.09203,
          h: 1.09211,
          l: 1.09202,
          c: 1.09208,
          v: 61
        },
        {
          t: 71400,
          o: 1.09208,
          h: 1.09213,
          l: 1.09204,
          c: 1.0921,
          v: 74
        },
        {
          t: 71700,
          o: 1.09209,
          h: 1.09214,
          l: 1.09203,
          c: 1.09213,
          v: 97
        },
        {
          t: 72000,
          o: 1.09211,
          h: 1.09212,
          l: 1.09198,
          c: 1.09207,
          v: 77
        },
        {
          t: 72300,
          o: 1.09208,
          h: 1.09209,
          l: 1.09198,
          c: 1.09198,
          v: 47
        },
        {
          t: 72600,
          o: 1.09197,
          h: 1.09201,
          l: 1.09194,
          c: 1.09197,
          v: 71
        },
        {
          t: 72900,
          o: 1.09197,
          h: 1.09207,
          l: 1.09188,
          c: 1.09198,
          v: 111
        },
        {
          t: 73200,
          o: 1.09199,
          h: 1.09204,
          l: 1.09198,
          c: 1.09203,
          v: 48
        },
        {
          t: 73500,
          o: 1.09203,
          h: 1.09209,
          l: 1.09202,
          c: 1.09202,
          v: 26
        },
        {
          t: 73800,
          o: 1.09203,
          h: 1.09203,
          l: 1.09193,
          c: 1.09193,
          v: 81
        },
        {
          t: 74100,
          o: 1.09191,
          h: 1.09197,
          l: 1.09188,
          c: 1.09192,
          v: 75
        },
        {
          t: 74400,
          o: 1.09193,
          h: 1.09195,
          l: 1.09176,
          c: 1.09179,
          v: 69
        },
        {
          t: 74700,
          o: 1.09182,
          h: 1.09203,
          l: 1.0918,
          c: 1.09202,
          v: 74
        },
        {
          t: 75000,
          o: 1.09203,
          h: 1.09203,
          l: 1.09185,
          c: 1.09186,
          v: 77
        },
        {
          t: 75300,
          o: 1.09185,
          h: 1.09197,
          l: 1.09181,
          c: 1.0919,
          v: 136
        },
        {
          t: 75600,
          o: 1.09189,
          h: 1.09195,
          l: 1.09183,
          c: 1.09184,
          v: 65
        },
        {
          t: 75900,
          o: 1.09185,
          h: 1.09185,
          l: 1.09176,
          c: 1.09178,
          v: 29
        },
        {
          t: 76200,
          o: 1.09179,
          h: 1.09181,
          l: 1.09175,
          c: 1.0918,
          v: 47
        },
        {
          t: 76500,
          o: 1.0918,
          h: 1.09183,
          l: 1.09177,
          c: 1.09177,
          v: 25
        },
        {
          t: 76800,
          o: 1.09179,
          h: 1.09179,
          l: 1.09173,
          c: 1.09173,
          v: 26
        },
        {
          t: 77100,
          o: 1.09174,
          h: 1.0918,
          l: 1.09167,
          c: 1.09179,
          v: 51
        },
        {
          t: 77400,
          o: 1.09177,
          h: 1.0918,
          l: 1.09167,
          c: 1.09168,
          v: 34
        },
        {
          t: 77700,
          o: 1.09168,
          h: 1.09179,
          l: 1.09164,
          c: 1.09179,
          v: 31
        },
        {
          t: 78000,
          o: 1.0918,
          h: 1.0918,
          l: 1.0917,
          c: 1.0917,
          v: 21
        },
        {
          t: 78300,
          o: 1.09171,
          h: 1.09177,
          l: 1.09169,
          c: 1.09175,
          v: 64
        },
        {
          t: 78600,
          o: 1.09173,
          h: 1.09174,
          l: 1.09165,
          c: 1.09166,
          v: 35
        },
        {
          t: 78900,
          o: 1.09162,
          h: 1.09167,
          l: 1.09138,
          c: 1.0914,
          v: 87
        },
        {
          t: 79200,
          o: 1.09141,
          h: 1.09154,
          l: 1.09141,
          c: 1.09153,
          v: 29
        },
        {
          t: 79500,
          o: 1.09153,
          h: 1.0916,
          l: 1.09135,
          c: 1.09151,
          v: 58
        },
        {
          t: 79800,
          o: 1.09152,
          h: 1.09193,
          l: 1.0915,
          c: 1.09171,
          v: 100
        },
        {
          t: 80100,
          o: 1.09177,
          h: 1.09177,
          l: 1.09177,
          c: 1.09177,
          v: 34
        },
        {
          t: 80400,
          o: 1.09177,
          h: 1.09177,
          l: 1.09177,
          c: 1.09177,
          v: 49
        },
        {
          t: 80700,
          o: 1.09177,
          h: 1.09177,
          l: 1.09155,
          c: 1.09156,
          v: 54
        },
        {
          t: 81000,
          o: 1.09156,
          h: 1.09159,
          l: 1.09156,
          c: 1.09156,
          v: 32
        },
        {
          t: 81300,
          o: 1.09156,
          h: 1.09162,
          l: 1.09156,
          c: 1.09159,
          v: 28
        },
        {
          t: 81600,
          o: 1.09159,
          h: 1.09198,
          l: 1.09157,
          c: 1.09179,
          v: 106
        },
        {
          t: 81900,
          o: 1.09178,
          h: 1.09183,
          l: 1.09172,
          c: 1.0918,
          v: 58
        },
        {
          t: 82200,
          o: 1.0918,
          h: 1.09188,
          l: 1.09178,
          c: 1.09188,
          v: 17
        },
        {
          t: 82500,
          o: 1.09189,
          h: 1.09189,
          l: 1.09178,
          c: 1.09184,
          v: 42
        },
        {
          t: 82800,
          o: 1.09188,
          h: 1.09188,
          l: 1.09171,
          c: 1.0918,
          v: 78
        },
        {
          t: 83100,
          o: 1.09181,
          h: 1.09186,
          l: 1.0918,
          c: 1.09185,
          v: 39
        },
        {
          t: 83400,
          o: 1.09184,
          h: 1.09186,
          l: 1.09179,
          c: 1.0918,
          v: 54
        },
        {
          t: 83700,
          o: 1.09179,
          h: 1.09181,
          l: 1.09174,
          c: 1.0918,
          v: 43
        },
        {
          t: 84000,
          o: 1.09179,
          h: 1.0918,
          l: 1.09174,
          c: 1.09176,
          v: 60
        },
        {
          t: 84300,
          o: 1.09174,
          h: 1.09181,
          l: 1.09173,
          c: 1.09179,
          v: 22
        },
        {
          t: 84600,
          o: 1.0918,
          h: 1.0918,
          l: 1.09177,
          c: 1.09179,
          v: 20
        },
        {
          t: 84900,
          o: 1.09181,
          h: 1.09185,
          l: 1.0918,
          c: 1.09183,
          v: 31
        },
        {
          t: 85200,
          o: 1.09184,
          h: 1.09186,
          l: 1.0918,
          c: 1.09185,
          v: 25
        },
        {
          t: 85500,
          o: 1.09184,
          h: 1.09186,
          l: 1.09178,
          c: 1.09185,
          v: 32
        },
        {
          t: 85800,
          o: 1.09184,
          h: 1.09185,
          l: 1.09179,
          c: 1.09185,
          v: 15
        },
        {
          t: 86100,
          o: 1.09184,
          h: 1.09186,
          l: 1.0918,
          c: 1.09186,
          v: 32
        }
      ]
    },
    {
      d: 1581465600,
      b: [
        {
          t: 0,
          o: 1.09184,
          h: 1.09186,
          l: 1.09149,
          c: 1.09152,
          v: 152
        },
        {
          t: 300,
          o: 1.09153,
          h: 1.0917,
          l: 1.09146,
          c: 1.09148,
          v: 108
        },
        {
          t: 600,
          o: 1.09149,
          h: 1.09157,
          l: 1.09143,
          c: 1.09153,
          v: 86
        },
        {
          t: 900,
          o: 1.09153,
          h: 1.09153,
          l: 1.09145,
          c: 1.09149,
          v: 92
        },
        {
          t: 1200,
          o: 1.09149,
          h: 1.09153,
          l: 1.09142,
          c: 1.09148,
          v: 60
        },
        {
          t: 1500,
          o: 1.09148,
          h: 1.09148,
          l: 1.09132,
          c: 1.09135,
          v: 65
        },
        {
          t: 1800,
          o: 1.09134,
          h: 1.09143,
          l: 1.09132,
          c: 1.09142,
          v: 67
        },
        {
          t: 2100,
          o: 1.09142,
          h: 1.09143,
          l: 1.09139,
          c: 1.09139,
          v: 21
        },
        {
          t: 2400,
          o: 1.09141,
          h: 1.09142,
          l: 1.09133,
          c: 1.09137,
          v: 55
        },
        {
          t: 2700,
          o: 1.09136,
          h: 1.09137,
          l: 1.09116,
          c: 1.09116,
          v: 92
        },
        {
          t: 3000,
          o: 1.09118,
          h: 1.09137,
          l: 1.09115,
          c: 1.09116,
          v: 166
        },
        {
          t: 3300,
          o: 1.09115,
          h: 1.0913,
          l: 1.09114,
          c: 1.09126,
          v: 76
        },
        {
          t: 3600,
          o: 1.09127,
          h: 1.09168,
          l: 1.09127,
          c: 1.09163,
          v: 222
        },
        {
          t: 3900,
          o: 1.09163,
          h: 1.09165,
          l: 1.09146,
          c: 1.09153,
          v: 155
        },
        {
          t: 4200,
          o: 1.09152,
          h: 1.09162,
          l: 1.09152,
          c: 1.09158,
          v: 102
        },
        {
          t: 4500,
          o: 1.09159,
          h: 1.09169,
          l: 1.09156,
          c: 1.0916,
          v: 84
        },
        {
          t: 4800,
          o: 1.0916,
          h: 1.09165,
          l: 1.09156,
          c: 1.09158,
          v: 91
        },
        {
          t: 5100,
          o: 1.09159,
          h: 1.0916,
          l: 1.09148,
          c: 1.09149,
          v: 73
        },
        {
          t: 5400,
          o: 1.09151,
          h: 1.09171,
          l: 1.09148,
          c: 1.09168,
          v: 106
        },
        {
          t: 5700,
          o: 1.0917,
          h: 1.09179,
          l: 1.09167,
          c: 1.09173,
          v: 93
        },
        {
          t: 6000,
          o: 1.09173,
          h: 1.09183,
          l: 1.09173,
          c: 1.09178,
          v: 111
        },
        {
          t: 6300,
          o: 1.09177,
          h: 1.09193,
          l: 1.09174,
          c: 1.09189,
          v: 86
        },
        {
          t: 6600,
          o: 1.09188,
          h: 1.09189,
          l: 1.09183,
          c: 1.09183,
          v: 42
        },
        {
          t: 6900,
          o: 1.09184,
          h: 1.09184,
          l: 1.09178,
          c: 1.09179,
          v: 35
        },
        {
          t: 7200,
          o: 1.09178,
          h: 1.0918,
          l: 1.09168,
          c: 1.09171,
          v: 94
        },
        {
          t: 7500,
          o: 1.0917,
          h: 1.09176,
          l: 1.09153,
          c: 1.09157,
          v: 73
        },
        {
          t: 7800,
          o: 1.09157,
          h: 1.09157,
          l: 1.09142,
          c: 1.09148,
          v: 71
        },
        {
          t: 8100,
          o: 1.09151,
          h: 1.09165,
          l: 1.09148,
          c: 1.09165,
          v: 64
        },
        {
          t: 8400,
          o: 1.09165,
          h: 1.09167,
          l: 1.0916,
          c: 1.09164,
          v: 42
        },
        {
          t: 8700,
          o: 1.09163,
          h: 1.0917,
          l: 1.09159,
          c: 1.09169,
          v: 49
        },
        {
          t: 9000,
          o: 1.09166,
          h: 1.09193,
          l: 1.09162,
          c: 1.09187,
          v: 115
        },
        {
          t: 9300,
          o: 1.09188,
          h: 1.09188,
          l: 1.09174,
          c: 1.09183,
          v: 55
        },
        {
          t: 9600,
          o: 1.09183,
          h: 1.09184,
          l: 1.09169,
          c: 1.09173,
          v: 72
        },
        {
          t: 9900,
          o: 1.09175,
          h: 1.09176,
          l: 1.09163,
          c: 1.09163,
          v: 67
        },
        {
          t: 10200,
          o: 1.09163,
          h: 1.09163,
          l: 1.09153,
          c: 1.09153,
          v: 27
        },
        {
          t: 10500,
          o: 1.09153,
          h: 1.0916,
          l: 1.09153,
          c: 1.09155,
          v: 60
        },
        {
          t: 10800,
          o: 1.09156,
          h: 1.09163,
          l: 1.09156,
          c: 1.09158,
          v: 60
        },
        {
          t: 11100,
          o: 1.09157,
          h: 1.09157,
          l: 1.09148,
          c: 1.09153,
          v: 53
        },
        {
          t: 11400,
          o: 1.09155,
          h: 1.09158,
          l: 1.09152,
          c: 1.09155,
          v: 43
        },
        {
          t: 11700,
          o: 1.09155,
          h: 1.09163,
          l: 1.09155,
          c: 1.09159,
          v: 41
        },
        {
          t: 12000,
          o: 1.09158,
          h: 1.09173,
          l: 1.09158,
          c: 1.09169,
          v: 52
        },
        {
          t: 12300,
          o: 1.0917,
          h: 1.09173,
          l: 1.09162,
          c: 1.09162,
          v: 56
        },
        {
          t: 12600,
          o: 1.09162,
          h: 1.09173,
          l: 1.09162,
          c: 1.09173,
          v: 22
        },
        {
          t: 12900,
          o: 1.09172,
          h: 1.09173,
          l: 1.09167,
          c: 1.09167,
          v: 29
        },
        {
          t: 13200,
          o: 1.09167,
          h: 1.09169,
          l: 1.09153,
          c: 1.09162,
          v: 69
        },
        {
          t: 13500,
          o: 1.0916,
          h: 1.09164,
          l: 1.09157,
          c: 1.09164,
          v: 42
        },
        {
          t: 13800,
          o: 1.09164,
          h: 1.09164,
          l: 1.09157,
          c: 1.09158,
          v: 39
        },
        {
          t: 14100,
          o: 1.09158,
          h: 1.09158,
          l: 1.09146,
          c: 1.09148,
          v: 35
        },
        {
          t: 14400,
          o: 1.09148,
          h: 1.09149,
          l: 1.09146,
          c: 1.09147,
          v: 28
        },
        {
          t: 14700,
          o: 1.09147,
          h: 1.09154,
          l: 1.09139,
          c: 1.09148,
          v: 59
        },
        {
          t: 15000,
          o: 1.09148,
          h: 1.09155,
          l: 1.09148,
          c: 1.09149,
          v: 23
        },
        {
          t: 15300,
          o: 1.09149,
          h: 1.09151,
          l: 1.09143,
          c: 1.09143,
          v: 31
        },
        {
          t: 15600,
          o: 1.09144,
          h: 1.09144,
          l: 1.09138,
          c: 1.09138,
          v: 33
        },
        {
          t: 15900,
          o: 1.09137,
          h: 1.09138,
          l: 1.09132,
          c: 1.09135,
          v: 17
        },
        {
          t: 16200,
          o: 1.09135,
          h: 1.09135,
          l: 1.09117,
          c: 1.09118,
          v: 41
        },
        {
          t: 16500,
          o: 1.09117,
          h: 1.09132,
          l: 1.09117,
          c: 1.09129,
          v: 71
        },
        {
          t: 16800,
          o: 1.09128,
          h: 1.09129,
          l: 1.09123,
          c: 1.09124,
          v: 32
        },
        {
          t: 17100,
          o: 1.09123,
          h: 1.09124,
          l: 1.09118,
          c: 1.0912,
          v: 43
        },
        {
          t: 17400,
          o: 1.0912,
          h: 1.09124,
          l: 1.0912,
          c: 1.09123,
          v: 43
        },
        {
          t: 17700,
          o: 1.09123,
          h: 1.09123,
          l: 1.09118,
          c: 1.09118,
          v: 34
        },
        {
          t: 18000,
          o: 1.09118,
          h: 1.09124,
          l: 1.09108,
          c: 1.0912,
          v: 73
        },
        {
          t: 18300,
          o: 1.09119,
          h: 1.09125,
          l: 1.09117,
          c: 1.09119,
          v: 41
        },
        {
          t: 18600,
          o: 1.09118,
          h: 1.09131,
          l: 1.09118,
          c: 1.09129,
          v: 72
        },
        {
          t: 18900,
          o: 1.0913,
          h: 1.0913,
          l: 1.09122,
          c: 1.09122,
          v: 30
        },
        {
          t: 19200,
          o: 1.09123,
          h: 1.09139,
          l: 1.09122,
          c: 1.09138,
          v: 42
        },
        {
          t: 19500,
          o: 1.09139,
          h: 1.09139,
          l: 1.09118,
          c: 1.09122,
          v: 76
        },
        {
          t: 19800,
          o: 1.09122,
          h: 1.09132,
          l: 1.0912,
          c: 1.09132,
          v: 77
        },
        {
          t: 20100,
          o: 1.09134,
          h: 1.09135,
          l: 1.09125,
          c: 1.09126,
          v: 49
        },
        {
          t: 20400,
          o: 1.09122,
          h: 1.09123,
          l: 1.0911,
          c: 1.09115,
          v: 52
        },
        {
          t: 20700,
          o: 1.09114,
          h: 1.09115,
          l: 1.09098,
          c: 1.09103,
          v: 56
        },
        {
          t: 21000,
          o: 1.09103,
          h: 1.09106,
          l: 1.09092,
          c: 1.09098,
          v: 68
        },
        {
          t: 21300,
          o: 1.09099,
          h: 1.09105,
          l: 1.09098,
          c: 1.09104,
          v: 53
        },
        {
          t: 21600,
          o: 1.09104,
          h: 1.09104,
          l: 1.09088,
          c: 1.09091,
          v: 72
        },
        {
          t: 21900,
          o: 1.09093,
          h: 1.09098,
          l: 1.09089,
          c: 1.09089,
          v: 42
        },
        {
          t: 22200,
          o: 1.09089,
          h: 1.09098,
          l: 1.09076,
          c: 1.09078,
          v: 108
        },
        {
          t: 22500,
          o: 1.0908,
          h: 1.09088,
          l: 1.09079,
          c: 1.09085,
          v: 46
        },
        {
          t: 22800,
          o: 1.09085,
          h: 1.0909,
          l: 1.0908,
          c: 1.09081,
          v: 28
        },
        {
          t: 23100,
          o: 1.09083,
          h: 1.09087,
          l: 1.0908,
          c: 1.0908,
          v: 63
        },
        {
          t: 23400,
          o: 1.0908,
          h: 1.09094,
          l: 1.09079,
          c: 1.09087,
          v: 41
        },
        {
          t: 23700,
          o: 1.09087,
          h: 1.09089,
          l: 1.09079,
          c: 1.09083,
          v: 53
        },
        {
          t: 24000,
          o: 1.09083,
          h: 1.09083,
          l: 1.09067,
          c: 1.09079,
          v: 73
        },
        {
          t: 24300,
          o: 1.09079,
          h: 1.09079,
          l: 1.09065,
          c: 1.09069,
          v: 62
        },
        {
          t: 24600,
          o: 1.09068,
          h: 1.09071,
          l: 1.09057,
          c: 1.09059,
          v: 80
        },
        {
          t: 24900,
          o: 1.09058,
          h: 1.09058,
          l: 1.09046,
          c: 1.09053,
          v: 102
        },
        {
          t: 25200,
          o: 1.09054,
          h: 1.09059,
          l: 1.09033,
          c: 1.0905,
          v: 159
        },
        {
          t: 25500,
          o: 1.09048,
          h: 1.09051,
          l: 1.09022,
          c: 1.09023,
          v: 109
        },
        {
          t: 25800,
          o: 1.09022,
          h: 1.09033,
          l: 1.09018,
          c: 1.09024,
          v: 137
        },
        {
          t: 26100,
          o: 1.09024,
          h: 1.09057,
          l: 1.0902,
          c: 1.09056,
          v: 170
        },
        {
          t: 26400,
          o: 1.09055,
          h: 1.09064,
          l: 1.09045,
          c: 1.09046,
          v: 130
        },
        {
          t: 26700,
          o: 1.09047,
          h: 1.09057,
          l: 1.09034,
          c: 1.0905,
          v: 133
        },
        {
          t: 27000,
          o: 1.09049,
          h: 1.09123,
          l: 1.09049,
          c: 1.09119,
          v: 246
        },
        {
          t: 27300,
          o: 1.0912,
          h: 1.09137,
          l: 1.09106,
          c: 1.09121,
          v: 241
        },
        {
          t: 27600,
          o: 1.0912,
          h: 1.09139,
          l: 1.0912,
          c: 1.09126,
          v: 176
        },
        {
          t: 27900,
          o: 1.09127,
          h: 1.0913,
          l: 1.09105,
          c: 1.09113,
          v: 187
        },
        {
          t: 28200,
          o: 1.09113,
          h: 1.09115,
          l: 1.09088,
          c: 1.09101,
          v: 177
        },
        {
          t: 28500,
          o: 1.09101,
          h: 1.09146,
          l: 1.09098,
          c: 1.09143,
          v: 198
        },
        {
          t: 28800,
          o: 1.09141,
          h: 1.09146,
          l: 1.09112,
          c: 1.09127,
          v: 219
        },
        {
          t: 29100,
          o: 1.09127,
          h: 1.09157,
          l: 1.09121,
          c: 1.09157,
          v: 216
        },
        {
          t: 29400,
          o: 1.09156,
          h: 1.09168,
          l: 1.09139,
          c: 1.09162,
          v: 260
        },
        {
          t: 29700,
          o: 1.09165,
          h: 1.09182,
          l: 1.09163,
          c: 1.09164,
          v: 162
        },
        {
          t: 30000,
          o: 1.09165,
          h: 1.09188,
          l: 1.09158,
          c: 1.09184,
          v: 157
        },
        {
          t: 30300,
          o: 1.09184,
          h: 1.09229,
          l: 1.09176,
          c: 1.09208,
          v: 215
        },
        {
          t: 30600,
          o: 1.09207,
          h: 1.09251,
          l: 1.09205,
          c: 1.09227,
          v: 359
        },
        {
          t: 30900,
          o: 1.09227,
          h: 1.0925,
          l: 1.09216,
          c: 1.09247,
          v: 235
        },
        {
          t: 31200,
          o: 1.09246,
          h: 1.09247,
          l: 1.09195,
          c: 1.09195,
          v: 290
        },
        {
          t: 31500,
          o: 1.09194,
          h: 1.09212,
          l: 1.09187,
          c: 1.09208,
          v: 238
        },
        {
          t: 31800,
          o: 1.09209,
          h: 1.09231,
          l: 1.092,
          c: 1.09213,
          v: 222
        },
        {
          t: 32100,
          o: 1.09214,
          h: 1.09226,
          l: 1.09199,
          c: 1.09211,
          v: 201
        },
        {
          t: 32400,
          o: 1.09208,
          h: 1.09223,
          l: 1.09194,
          c: 1.09197,
          v: 214
        },
        {
          t: 32700,
          o: 1.09197,
          h: 1.09228,
          l: 1.09195,
          c: 1.09201,
          v: 160
        },
        {
          t: 33000,
          o: 1.09202,
          h: 1.09212,
          l: 1.0919,
          c: 1.0921,
          v: 140
        },
        {
          t: 33300,
          o: 1.09207,
          h: 1.09218,
          l: 1.09185,
          c: 1.09187,
          v: 208
        },
        {
          t: 33600,
          o: 1.09188,
          h: 1.09189,
          l: 1.09164,
          c: 1.09168,
          v: 181
        },
        {
          t: 33900,
          o: 1.09168,
          h: 1.09174,
          l: 1.09149,
          c: 1.09156,
          v: 144
        },
        {
          t: 34200,
          o: 1.09155,
          h: 1.09162,
          l: 1.09121,
          c: 1.09121,
          v: 187
        },
        {
          t: 34500,
          o: 1.09121,
          h: 1.09146,
          l: 1.0911,
          c: 1.09139,
          v: 211
        },
        {
          t: 34800,
          o: 1.09139,
          h: 1.09177,
          l: 1.09135,
          c: 1.09161,
          v: 163
        },
        {
          t: 35100,
          o: 1.09162,
          h: 1.09178,
          l: 1.09162,
          c: 1.09176,
          v: 115
        },
        {
          t: 35400,
          o: 1.09177,
          h: 1.09183,
          l: 1.09171,
          c: 1.09177,
          v: 91
        },
        {
          t: 35700,
          o: 1.09176,
          h: 1.09223,
          l: 1.09176,
          c: 1.09198,
          v: 144
        },
        {
          t: 36000,
          o: 1.09199,
          h: 1.092,
          l: 1.09162,
          c: 1.09185,
          v: 215
        },
        {
          t: 36300,
          o: 1.09184,
          h: 1.09203,
          l: 1.0918,
          c: 1.09186,
          v: 131
        },
        {
          t: 36600,
          o: 1.09185,
          h: 1.09207,
          l: 1.09185,
          c: 1.092,
          v: 95
        },
        {
          t: 36900,
          o: 1.09202,
          h: 1.09203,
          l: 1.09192,
          c: 1.09198,
          v: 118
        },
        {
          t: 37200,
          o: 1.09198,
          h: 1.09213,
          l: 1.09194,
          c: 1.09202,
          v: 148
        },
        {
          t: 37500,
          o: 1.09201,
          h: 1.09202,
          l: 1.09181,
          c: 1.09192,
          v: 224
        },
        {
          t: 37800,
          o: 1.09191,
          h: 1.09195,
          l: 1.09168,
          c: 1.0917,
          v: 178
        },
        {
          t: 38100,
          o: 1.09171,
          h: 1.09176,
          l: 1.09154,
          c: 1.0917,
          v: 163
        },
        {
          t: 38400,
          o: 1.09169,
          h: 1.09189,
          l: 1.09169,
          c: 1.09176,
          v: 165
        },
        {
          t: 38700,
          o: 1.09177,
          h: 1.09178,
          l: 1.09161,
          c: 1.09162,
          v: 118
        },
        {
          t: 39000,
          o: 1.09161,
          h: 1.09175,
          l: 1.0915,
          c: 1.09171,
          v: 109
        },
        {
          t: 39300,
          o: 1.09175,
          h: 1.09192,
          l: 1.0917,
          c: 1.09177,
          v: 127
        },
        {
          t: 39600,
          o: 1.09176,
          h: 1.0918,
          l: 1.09126,
          c: 1.09137,
          v: 189
        },
        {
          t: 39900,
          o: 1.0914,
          h: 1.09154,
          l: 1.09134,
          c: 1.09142,
          v: 140
        },
        {
          t: 40200,
          o: 1.09141,
          h: 1.0916,
          l: 1.09141,
          c: 1.09156,
          v: 98
        },
        {
          t: 40500,
          o: 1.09152,
          h: 1.09154,
          l: 1.09141,
          c: 1.09147,
          v: 81
        },
        {
          t: 40800,
          o: 1.09145,
          h: 1.09155,
          l: 1.09144,
          c: 1.09148,
          v: 73
        },
        {
          t: 41100,
          o: 1.09148,
          h: 1.09152,
          l: 1.09141,
          c: 1.09147,
          v: 97
        },
        {
          t: 41400,
          o: 1.09146,
          h: 1.09147,
          l: 1.0913,
          c: 1.09135,
          v: 114
        },
        {
          t: 41700,
          o: 1.09133,
          h: 1.09134,
          l: 1.09105,
          c: 1.09118,
          v: 156
        },
        {
          t: 42000,
          o: 1.09117,
          h: 1.09131,
          l: 1.09114,
          c: 1.09114,
          v: 127
        },
        {
          t: 42300,
          o: 1.09114,
          h: 1.09131,
          l: 1.09092,
          c: 1.09102,
          v: 142
        },
        {
          t: 42600,
          o: 1.09102,
          h: 1.09126,
          l: 1.091,
          c: 1.09125,
          v: 88
        },
        {
          t: 42900,
          o: 1.09122,
          h: 1.09124,
          l: 1.0911,
          c: 1.09115,
          v: 108
        },
        {
          t: 43200,
          o: 1.09113,
          h: 1.09119,
          l: 1.09102,
          c: 1.09115,
          v: 139
        },
        {
          t: 43500,
          o: 1.09114,
          h: 1.09123,
          l: 1.09113,
          c: 1.09118,
          v: 119
        },
        {
          t: 43800,
          o: 1.09118,
          h: 1.0914,
          l: 1.09116,
          c: 1.09138,
          v: 133
        },
        {
          t: 44100,
          o: 1.09137,
          h: 1.09137,
          l: 1.09116,
          c: 1.09127,
          v: 113
        },
        {
          t: 44400,
          o: 1.09127,
          h: 1.0913,
          l: 1.09113,
          c: 1.09123,
          v: 129
        },
        {
          t: 44700,
          o: 1.09123,
          h: 1.09131,
          l: 1.09111,
          c: 1.09113,
          v: 124
        },
        {
          t: 45000,
          o: 1.09113,
          h: 1.09119,
          l: 1.09098,
          c: 1.09102,
          v: 132
        },
        {
          t: 45300,
          o: 1.09101,
          h: 1.0912,
          l: 1.09095,
          c: 1.09114,
          v: 123
        },
        {
          t: 45600,
          o: 1.09115,
          h: 1.09119,
          l: 1.09101,
          c: 1.09108,
          v: 115
        },
        {
          t: 45900,
          o: 1.09109,
          h: 1.09118,
          l: 1.09104,
          c: 1.0911,
          v: 132
        },
        {
          t: 46200,
          o: 1.0911,
          h: 1.09119,
          l: 1.09098,
          c: 1.09119,
          v: 120
        },
        {
          t: 46500,
          o: 1.09118,
          h: 1.09132,
          l: 1.09116,
          c: 1.09118,
          v: 170
        },
        {
          t: 46800,
          o: 1.09118,
          h: 1.09129,
          l: 1.09118,
          c: 1.09121,
          v: 117
        },
        {
          t: 47100,
          o: 1.09122,
          h: 1.0913,
          l: 1.09113,
          c: 1.09118,
          v: 143
        },
        {
          t: 47400,
          o: 1.09117,
          h: 1.09133,
          l: 1.09109,
          c: 1.09131,
          v: 115
        },
        {
          t: 47700,
          o: 1.0913,
          h: 1.09133,
          l: 1.09107,
          c: 1.09109,
          v: 148
        },
        {
          t: 48000,
          o: 1.09111,
          h: 1.09131,
          l: 1.09109,
          c: 1.09123,
          v: 139
        },
        {
          t: 48300,
          o: 1.09124,
          h: 1.0913,
          l: 1.09073,
          c: 1.09103,
          v: 253
        },
        {
          t: 48600,
          o: 1.09102,
          h: 1.09128,
          l: 1.09093,
          c: 1.09124,
          v: 194
        },
        {
          t: 48900,
          o: 1.09123,
          h: 1.09133,
          l: 1.09113,
          c: 1.09123,
          v: 155
        },
        {
          t: 49200,
          o: 1.09121,
          h: 1.0913,
          l: 1.09113,
          c: 1.09115,
          v: 148
        },
        {
          t: 49500,
          o: 1.09115,
          h: 1.09122,
          l: 1.09108,
          c: 1.09118,
          v: 117
        },
        {
          t: 49800,
          o: 1.09118,
          h: 1.09118,
          l: 1.09098,
          c: 1.091,
          v: 96
        },
        {
          t: 50100,
          o: 1.09099,
          h: 1.091,
          l: 1.09044,
          c: 1.09046,
          v: 263
        },
        {
          t: 50400,
          o: 1.09048,
          h: 1.09054,
          l: 1.09014,
          c: 1.09014,
          v: 182
        },
        {
          t: 50700,
          o: 1.09013,
          h: 1.0905,
          l: 1.08993,
          c: 1.0905,
          v: 302
        },
        {
          t: 51000,
          o: 1.09049,
          h: 1.09063,
          l: 1.09036,
          c: 1.09056,
          v: 200
        },
        {
          t: 51300,
          o: 1.09055,
          h: 1.09055,
          l: 1.09025,
          c: 1.09048,
          v: 208
        },
        {
          t: 51600,
          o: 1.09052,
          h: 1.09076,
          l: 1.09029,
          c: 1.09074,
          v: 159
        },
        {
          t: 51900,
          o: 1.09074,
          h: 1.091,
          l: 1.09074,
          c: 1.09099,
          v: 204
        },
        {
          t: 52200,
          o: 1.091,
          h: 1.091,
          l: 1.09063,
          c: 1.09066,
          v: 226
        },
        {
          t: 52500,
          o: 1.09066,
          h: 1.0907,
          l: 1.09038,
          c: 1.09043,
          v: 260
        },
        {
          t: 52800,
          o: 1.09043,
          h: 1.09049,
          l: 1.09038,
          c: 1.09042,
          v: 184
        },
        {
          t: 53100,
          o: 1.09043,
          h: 1.09045,
          l: 1.0901,
          c: 1.09023,
          v: 145
        },
        {
          t: 53400,
          o: 1.09023,
          h: 1.09039,
          l: 1.08988,
          c: 1.09033,
          v: 250
        },
        {
          t: 53700,
          o: 1.09037,
          h: 1.09045,
          l: 1.09027,
          c: 1.09035,
          v: 245
        },
        {
          t: 54000,
          o: 1.09035,
          h: 1.09043,
          l: 1.08931,
          c: 1.0894,
          v: 369
        },
        {
          t: 54300,
          o: 1.08941,
          h: 1.08982,
          l: 1.08914,
          c: 1.08929,
          v: 334
        },
        {
          t: 54600,
          o: 1.08932,
          h: 1.08946,
          l: 1.08913,
          c: 1.08931,
          v: 300
        },
        {
          t: 54900,
          o: 1.08932,
          h: 1.08941,
          l: 1.08864,
          c: 1.08876,
          v: 367
        },
        {
          t: 55200,
          o: 1.08874,
          h: 1.08926,
          l: 1.08864,
          c: 1.08884,
          v: 340
        },
        {
          t: 55500,
          o: 1.08884,
          h: 1.08904,
          l: 1.08877,
          c: 1.08893,
          v: 275
        },
        {
          t: 55800,
          o: 1.0889,
          h: 1.0895,
          l: 1.08867,
          c: 1.0894,
          v: 327
        },
        {
          t: 56100,
          o: 1.08939,
          h: 1.08939,
          l: 1.08902,
          c: 1.08902,
          v: 265
        },
        {
          t: 56400,
          o: 1.08904,
          h: 1.0894,
          l: 1.08899,
          c: 1.08929,
          v: 250
        },
        {
          t: 56700,
          o: 1.08928,
          h: 1.08938,
          l: 1.08901,
          c: 1.08938,
          v: 257
        },
        {
          t: 57000,
          o: 1.08939,
          h: 1.08944,
          l: 1.08857,
          c: 1.08866,
          v: 347
        },
        {
          t: 57300,
          o: 1.08871,
          h: 1.08951,
          l: 1.08861,
          c: 1.08938,
          v: 405
        },
        {
          t: 57600,
          o: 1.08938,
          h: 1.08969,
          l: 1.08938,
          c: 1.0896,
          v: 351
        },
        {
          t: 57900,
          o: 1.0896,
          h: 1.08962,
          l: 1.08913,
          c: 1.0892,
          v: 223
        },
        {
          t: 58200,
          o: 1.08921,
          h: 1.0895,
          l: 1.08903,
          c: 1.08923,
          v: 248
        },
        {
          t: 58500,
          o: 1.08924,
          h: 1.08963,
          l: 1.08915,
          c: 1.08944,
          v: 258
        },
        {
          t: 58800,
          o: 1.08942,
          h: 1.08971,
          l: 1.08929,
          c: 1.08957,
          v: 190
        },
        {
          t: 59100,
          o: 1.08958,
          h: 1.08971,
          l: 1.08931,
          c: 1.08938,
          v: 219
        },
        {
          t: 59400,
          o: 1.08937,
          h: 1.08962,
          l: 1.08922,
          c: 1.08943,
          v: 188
        },
        {
          t: 59700,
          o: 1.08942,
          h: 1.08958,
          l: 1.08907,
          c: 1.08909,
          v: 220
        },
        {
          t: 60000,
          o: 1.08909,
          h: 1.08934,
          l: 1.08908,
          c: 1.08913,
          v: 198
        },
        {
          t: 60300,
          o: 1.08912,
          h: 1.08912,
          l: 1.08889,
          c: 1.08898,
          v: 195
        },
        {
          t: 60600,
          o: 1.08899,
          h: 1.08909,
          l: 1.08892,
          c: 1.08908,
          v: 133
        },
        {
          t: 60900,
          o: 1.08908,
          h: 1.0892,
          l: 1.08903,
          c: 1.08918,
          v: 98
        },
        {
          t: 61200,
          o: 1.08918,
          h: 1.0893,
          l: 1.08892,
          c: 1.08893,
          v: 145
        },
        {
          t: 61500,
          o: 1.08892,
          h: 1.08895,
          l: 1.08868,
          c: 1.08871,
          v: 164
        },
        {
          t: 61800,
          o: 1.08868,
          h: 1.08872,
          l: 1.08779,
          c: 1.08794,
          v: 272
        },
        {
          t: 62100,
          o: 1.08795,
          h: 1.08809,
          l: 1.08763,
          c: 1.08799,
          v: 351
        },
        {
          t: 62400,
          o: 1.08798,
          h: 1.08821,
          l: 1.08798,
          c: 1.08819,
          v: 200
        },
        {
          t: 62700,
          o: 1.08818,
          h: 1.08824,
          l: 1.08792,
          c: 1.08798,
          v: 140
        },
        {
          t: 63000,
          o: 1.08798,
          h: 1.088,
          l: 1.08774,
          c: 1.08785,
          v: 170
        },
        {
          t: 63300,
          o: 1.08788,
          h: 1.08796,
          l: 1.08768,
          c: 1.08782,
          v: 112
        },
        {
          t: 63600,
          o: 1.08783,
          h: 1.08789,
          l: 1.08763,
          c: 1.08765,
          v: 102
        },
        {
          t: 63900,
          o: 1.08764,
          h: 1.08786,
          l: 1.08763,
          c: 1.08786,
          v: 77
        },
        {
          t: 64200,
          o: 1.08788,
          h: 1.08807,
          l: 1.08786,
          c: 1.08796,
          v: 105
        },
        {
          t: 64500,
          o: 1.08796,
          h: 1.08807,
          l: 1.0878,
          c: 1.08784,
          v: 182
        },
        {
          t: 64800,
          o: 1.08785,
          h: 1.08794,
          l: 1.08769,
          c: 1.08781,
          v: 169
        },
        {
          t: 65100,
          o: 1.08782,
          h: 1.08793,
          l: 1.08777,
          c: 1.08777,
          v: 102
        },
        {
          t: 65400,
          o: 1.08774,
          h: 1.08788,
          l: 1.08754,
          c: 1.08758,
          v: 156
        },
        {
          t: 65700,
          o: 1.08759,
          h: 1.08785,
          l: 1.08758,
          c: 1.08783,
          v: 102
        },
        {
          t: 66000,
          o: 1.08782,
          h: 1.08805,
          l: 1.08782,
          c: 1.08805,
          v: 103
        },
        {
          t: 66300,
          o: 1.08801,
          h: 1.08803,
          l: 1.08785,
          c: 1.08785,
          v: 107
        },
        {
          t: 66600,
          o: 1.08784,
          h: 1.08801,
          l: 1.08773,
          c: 1.08778,
          v: 91
        },
        {
          t: 66900,
          o: 1.08779,
          h: 1.0879,
          l: 1.08773,
          c: 1.08773,
          v: 55
        },
        {
          t: 67200,
          o: 1.08774,
          h: 1.08781,
          l: 1.08773,
          c: 1.08774,
          v: 82
        },
        {
          t: 67500,
          o: 1.08774,
          h: 1.08775,
          l: 1.08756,
          c: 1.08766,
          v: 68
        },
        {
          t: 67800,
          o: 1.08769,
          h: 1.08774,
          l: 1.08768,
          c: 1.08771,
          v: 43
        },
        {
          t: 68100,
          o: 1.0877,
          h: 1.0877,
          l: 1.08756,
          c: 1.0876,
          v: 67
        },
        {
          t: 68400,
          o: 1.0876,
          h: 1.08771,
          l: 1.0876,
          c: 1.0877,
          v: 73
        },
        {
          t: 68700,
          o: 1.08769,
          h: 1.08771,
          l: 1.08765,
          c: 1.08767,
          v: 65
        },
        {
          t: 69000,
          o: 1.08766,
          h: 1.08767,
          l: 1.08754,
          c: 1.08755,
          v: 82
        },
        {
          t: 69300,
          o: 1.08756,
          h: 1.0876,
          l: 1.08682,
          c: 1.08708,
          v: 105
        },
        {
          t: 69600,
          o: 1.08709,
          h: 1.08714,
          l: 1.0865,
          c: 1.08675,
          v: 346
        },
        {
          t: 69900,
          o: 1.08679,
          h: 1.08692,
          l: 1.08665,
          c: 1.08675,
          v: 172
        },
        {
          t: 70200,
          o: 1.08674,
          h: 1.08697,
          l: 1.08674,
          c: 1.08676,
          v: 183
        },
        {
          t: 70500,
          o: 1.08676,
          h: 1.08702,
          l: 1.08676,
          c: 1.08691,
          v: 91
        },
        {
          t: 70800,
          o: 1.08689,
          h: 1.08695,
          l: 1.0868,
          c: 1.08688,
          v: 123
        },
        {
          t: 71100,
          o: 1.08689,
          h: 1.087,
          l: 1.0868,
          c: 1.0868,
          v: 134
        },
        {
          t: 71400,
          o: 1.08681,
          h: 1.08682,
          l: 1.08655,
          c: 1.08677,
          v: 219
        },
        {
          t: 71700,
          o: 1.08676,
          h: 1.08677,
          l: 1.08656,
          c: 1.0866,
          v: 227
        },
        {
          t: 72000,
          o: 1.0866,
          h: 1.08685,
          l: 1.0866,
          c: 1.08683,
          v: 129
        },
        {
          t: 72300,
          o: 1.08679,
          h: 1.08692,
          l: 1.08675,
          c: 1.08685,
          v: 78
        },
        {
          t: 72600,
          o: 1.08686,
          h: 1.08695,
          l: 1.08683,
          c: 1.08695,
          v: 106
        },
        {
          t: 72900,
          o: 1.08694,
          h: 1.08706,
          l: 1.08689,
          c: 1.08705,
          v: 92
        },
        {
          t: 73200,
          o: 1.08707,
          h: 1.08708,
          l: 1.08701,
          c: 1.08706,
          v: 59
        },
        {
          t: 73500,
          o: 1.08707,
          h: 1.08709,
          l: 1.08702,
          c: 1.08705,
          v: 50
        },
        {
          t: 73800,
          o: 1.08701,
          h: 1.0872,
          l: 1.08701,
          c: 1.0871,
          v: 75
        },
        {
          t: 74100,
          o: 1.0871,
          h: 1.0872,
          l: 1.08707,
          c: 1.0872,
          v: 54
        },
        {
          t: 74400,
          o: 1.0872,
          h: 1.08725,
          l: 1.08715,
          c: 1.08721,
          v: 52
        },
        {
          t: 74700,
          o: 1.08722,
          h: 1.08731,
          l: 1.08716,
          c: 1.08729,
          v: 108
        },
        {
          t: 75000,
          o: 1.08728,
          h: 1.08738,
          l: 1.08725,
          c: 1.08737,
          v: 79
        },
        {
          t: 75300,
          o: 1.08735,
          h: 1.08748,
          l: 1.08735,
          c: 1.08748,
          v: 130
        },
        {
          t: 75600,
          o: 1.08748,
          h: 1.08752,
          l: 1.0874,
          c: 1.08745,
          v: 104
        },
        {
          t: 75900,
          o: 1.08745,
          h: 1.08746,
          l: 1.0874,
          c: 1.0874,
          v: 43
        },
        {
          t: 76200,
          o: 1.08741,
          h: 1.08741,
          l: 1.08734,
          c: 1.08735,
          v: 68
        },
        {
          t: 76500,
          o: 1.08736,
          h: 1.08739,
          l: 1.08731,
          c: 1.08734,
          v: 25
        },
        {
          t: 76800,
          o: 1.08735,
          h: 1.08742,
          l: 1.08734,
          c: 1.08739,
          v: 70
        },
        {
          t: 77100,
          o: 1.08738,
          h: 1.08739,
          l: 1.08728,
          c: 1.08733,
          v: 65
        },
        {
          t: 77400,
          o: 1.08731,
          h: 1.08731,
          l: 1.08724,
          c: 1.08725,
          v: 42
        },
        {
          t: 77700,
          o: 1.08725,
          h: 1.08725,
          l: 1.0872,
          c: 1.0872,
          v: 29
        },
        {
          t: 78000,
          o: 1.08719,
          h: 1.08727,
          l: 1.08716,
          c: 1.08725,
          v: 60
        },
        {
          t: 78300,
          o: 1.08721,
          h: 1.08729,
          l: 1.08714,
          c: 1.08714,
          v: 99
        },
        {
          t: 78600,
          o: 1.08715,
          h: 1.08717,
          l: 1.08709,
          c: 1.08713,
          v: 70
        },
        {
          t: 78900,
          o: 1.08713,
          h: 1.08731,
          l: 1.08712,
          c: 1.08725,
          v: 261
        },
        {
          t: 79200,
          o: 1.08704,
          h: 1.08732,
          l: 1.08704,
          c: 1.08732,
          v: 71
        },
        {
          t: 79500,
          o: 1.08732,
          h: 1.08733,
          l: 1.08717,
          c: 1.08728,
          v: 54
        },
        {
          t: 79800,
          o: 1.08728,
          h: 1.08742,
          l: 1.0872,
          c: 1.08729,
          v: 121
        },
        {
          t: 80100,
          o: 1.08725,
          h: 1.08736,
          l: 1.08715,
          c: 1.08723,
          v: 105
        },
        {
          t: 80400,
          o: 1.08723,
          h: 1.08733,
          l: 1.08723,
          c: 1.08729,
          v: 95
        },
        {
          t: 80700,
          o: 1.08729,
          h: 1.08737,
          l: 1.08726,
          c: 1.08734,
          v: 54
        },
        {
          t: 81000,
          o: 1.08734,
          h: 1.08736,
          l: 1.08708,
          c: 1.08726,
          v: 35
        },
        {
          t: 81300,
          o: 1.08725,
          h: 1.08734,
          l: 1.08725,
          c: 1.08726,
          v: 30
        },
        {
          t: 81600,
          o: 1.08729,
          h: 1.08736,
          l: 1.08705,
          c: 1.08714,
          v: 97
        },
        {
          t: 81900,
          o: 1.08715,
          h: 1.08734,
          l: 1.08711,
          c: 1.08714,
          v: 110
        },
        {
          t: 82200,
          o: 1.08716,
          h: 1.08719,
          l: 1.08709,
          c: 1.0871,
          v: 135
        },
        {
          t: 82500,
          o: 1.08709,
          h: 1.08713,
          l: 1.08709,
          c: 1.08713,
          v: 106
        },
        {
          t: 82800,
          o: 1.08711,
          h: 1.08719,
          l: 1.08709,
          c: 1.08712,
          v: 100
        },
        {
          t: 83100,
          o: 1.08711,
          h: 1.08716,
          l: 1.08711,
          c: 1.08714,
          v: 29
        },
        {
          t: 83400,
          o: 1.08714,
          h: 1.08715,
          l: 1.08714,
          c: 1.08714,
          v: 9
        },
        {
          t: 83700,
          o: 1.08714,
          h: 1.08716,
          l: 1.08707,
          c: 1.0871,
          v: 70
        },
        {
          t: 84000,
          o: 1.0871,
          h: 1.08718,
          l: 1.0871,
          c: 1.08715,
          v: 39
        },
        {
          t: 84300,
          o: 1.08717,
          h: 1.08718,
          l: 1.08711,
          c: 1.08715,
          v: 50
        },
        {
          t: 84600,
          o: 1.08715,
          h: 1.08716,
          l: 1.087,
          c: 1.08702,
          v: 55
        },
        {
          t: 84900,
          o: 1.08702,
          h: 1.08712,
          l: 1.08702,
          c: 1.08705,
          v: 48
        },
        {
          t: 85200,
          o: 1.08705,
          h: 1.08706,
          l: 1.08693,
          c: 1.08701,
          v: 38
        },
        {
          t: 85500,
          o: 1.08701,
          h: 1.08722,
          l: 1.08691,
          c: 1.08711,
          v: 213
        },
        {
          t: 85800,
          o: 1.0871,
          h: 1.08737,
          l: 1.0871,
          c: 1.08737,
          v: 246
        },
        {
          t: 86100,
          o: 1.08738,
          h: 1.08755,
          l: 1.08729,
          c: 1.08752,
          v: 227
        }
      ]
    },
    {
      d: 1581552000,
      b: [
        {
          t: 0,
          o: 1.0875,
          h: 1.08758,
          l: 1.08727,
          c: 1.08731,
          v: 279
        },
        {
          t: 300,
          o: 1.08732,
          h: 1.08748,
          l: 1.08723,
          c: 1.08747,
          v: 239
        },
        {
          t: 600,
          o: 1.08744,
          h: 1.0875,
          l: 1.08719,
          c: 1.08719,
          v: 175
        },
        {
          t: 900,
          o: 1.0872,
          h: 1.08726,
          l: 1.08709,
          c: 1.08716,
          v: 144
        },
        {
          t: 1200,
          o: 1.08716,
          h: 1.08724,
          l: 1.08707,
          c: 1.08717,
          v: 119
        },
        {
          t: 1500,
          o: 1.08721,
          h: 1.08737,
          l: 1.08719,
          c: 1.08732,
          v: 93
        },
        {
          t: 1800,
          o: 1.08733,
          h: 1.08739,
          l: 1.08718,
          c: 1.08737,
          v: 121
        },
        {
          t: 2100,
          o: 1.08738,
          h: 1.08742,
          l: 1.0873,
          c: 1.08737,
          v: 100
        },
        {
          t: 2400,
          o: 1.08736,
          h: 1.0874,
          l: 1.08717,
          c: 1.08723,
          v: 100
        },
        {
          t: 2700,
          o: 1.08724,
          h: 1.08737,
          l: 1.08719,
          c: 1.08721,
          v: 78
        },
        {
          t: 3000,
          o: 1.08722,
          h: 1.08727,
          l: 1.08716,
          c: 1.0872,
          v: 66
        },
        {
          t: 3300,
          o: 1.08718,
          h: 1.08724,
          l: 1.08712,
          c: 1.08721,
          v: 87
        },
        {
          t: 3600,
          o: 1.08718,
          h: 1.0873,
          l: 1.08716,
          c: 1.08729,
          v: 87
        },
        {
          t: 3900,
          o: 1.0873,
          h: 1.08732,
          l: 1.08725,
          c: 1.08732,
          v: 58
        },
        {
          t: 4200,
          o: 1.08731,
          h: 1.08743,
          l: 1.0873,
          c: 1.08737,
          v: 106
        },
        {
          t: 4500,
          o: 1.08738,
          h: 1.08739,
          l: 1.08725,
          c: 1.08729,
          v: 93
        },
        {
          t: 4800,
          o: 1.08728,
          h: 1.08732,
          l: 1.08727,
          c: 1.08732,
          v: 78
        },
        {
          t: 5100,
          o: 1.08732,
          h: 1.08733,
          l: 1.08717,
          c: 1.0872,
          v: 85
        },
        {
          t: 5400,
          o: 1.08719,
          h: 1.0872,
          l: 1.08711,
          c: 1.08711,
          v: 91
        },
        {
          t: 5700,
          o: 1.08712,
          h: 1.08715,
          l: 1.08705,
          c: 1.08712,
          v: 130
        },
        {
          t: 6000,
          o: 1.08713,
          h: 1.08717,
          l: 1.0871,
          c: 1.08716,
          v: 108
        },
        {
          t: 6300,
          o: 1.08716,
          h: 1.08727,
          l: 1.08715,
          c: 1.08725,
          v: 93
        },
        {
          t: 6600,
          o: 1.08725,
          h: 1.08738,
          l: 1.08725,
          c: 1.08726,
          v: 59
        },
        {
          t: 6900,
          o: 1.08727,
          h: 1.0873,
          l: 1.0872,
          c: 1.08725,
          v: 59
        },
        {
          t: 7200,
          o: 1.08722,
          h: 1.08724,
          l: 1.08699,
          c: 1.087,
          v: 125
        },
        {
          t: 7500,
          o: 1.08699,
          h: 1.0871,
          l: 1.08694,
          c: 1.08708,
          v: 97
        },
        {
          t: 7800,
          o: 1.08707,
          h: 1.08717,
          l: 1.08707,
          c: 1.08716,
          v: 29
        },
        {
          t: 8100,
          o: 1.08717,
          h: 1.08734,
          l: 1.08715,
          c: 1.0873,
          v: 43
        },
        {
          t: 8400,
          o: 1.08726,
          h: 1.08728,
          l: 1.08719,
          c: 1.0872,
          v: 30
        },
        {
          t: 8700,
          o: 1.08721,
          h: 1.08721,
          l: 1.08713,
          c: 1.08719,
          v: 34
        },
        {
          t: 9000,
          o: 1.08717,
          h: 1.08727,
          l: 1.08717,
          c: 1.0872,
          v: 33
        },
        {
          t: 9300,
          o: 1.08721,
          h: 1.08724,
          l: 1.08714,
          c: 1.08719,
          v: 44
        },
        {
          t: 9600,
          o: 1.08719,
          h: 1.0872,
          l: 1.0871,
          c: 1.08711,
          v: 29
        },
        {
          t: 9900,
          o: 1.08712,
          h: 1.08716,
          l: 1.0871,
          c: 1.08715,
          v: 39
        },
        {
          t: 10200,
          o: 1.08712,
          h: 1.08717,
          l: 1.0871,
          c: 1.08717,
          v: 24
        },
        {
          t: 10500,
          o: 1.08715,
          h: 1.08715,
          l: 1.08694,
          c: 1.08694,
          v: 68
        },
        {
          t: 10800,
          o: 1.08694,
          h: 1.08695,
          l: 1.08679,
          c: 1.08679,
          v: 72
        },
        {
          t: 11100,
          o: 1.0868,
          h: 1.08683,
          l: 1.08673,
          c: 1.0868,
          v: 44
        },
        {
          t: 11400,
          o: 1.08681,
          h: 1.08684,
          l: 1.08673,
          c: 1.08684,
          v: 72
        },
        {
          t: 11700,
          o: 1.08685,
          h: 1.08696,
          l: 1.08681,
          c: 1.08695,
          v: 46
        },
        {
          t: 12000,
          o: 1.08698,
          h: 1.08698,
          l: 1.08685,
          c: 1.0869,
          v: 66
        },
        {
          t: 12300,
          o: 1.08689,
          h: 1.08692,
          l: 1.08688,
          c: 1.08691,
          v: 48
        },
        {
          t: 12600,
          o: 1.08692,
          h: 1.08703,
          l: 1.08689,
          c: 1.08694,
          v: 65
        },
        {
          t: 12900,
          o: 1.08693,
          h: 1.08704,
          l: 1.08693,
          c: 1.087,
          v: 79
        },
        {
          t: 13200,
          o: 1.087,
          h: 1.08709,
          l: 1.08695,
          c: 1.08696,
          v: 42
        },
        {
          t: 13500,
          o: 1.08699,
          h: 1.087,
          l: 1.08694,
          c: 1.08695,
          v: 33
        },
        {
          t: 13800,
          o: 1.08695,
          h: 1.08695,
          l: 1.08683,
          c: 1.08685,
          v: 55
        },
        {
          t: 14100,
          o: 1.08684,
          h: 1.08697,
          l: 1.08683,
          c: 1.08696,
          v: 56
        },
        {
          t: 14400,
          o: 1.08695,
          h: 1.08697,
          l: 1.08687,
          c: 1.08695,
          v: 40
        },
        {
          t: 14700,
          o: 1.08696,
          h: 1.08697,
          l: 1.08695,
          c: 1.08695,
          v: 17
        },
        {
          t: 15000,
          o: 1.08694,
          h: 1.08695,
          l: 1.0869,
          c: 1.0869,
          v: 22
        },
        {
          t: 15300,
          o: 1.08691,
          h: 1.08691,
          l: 1.08678,
          c: 1.08681,
          v: 32
        },
        {
          t: 15600,
          o: 1.0868,
          h: 1.08686,
          l: 1.0868,
          c: 1.08685,
          v: 39
        },
        {
          t: 15900,
          o: 1.08685,
          h: 1.08686,
          l: 1.08679,
          c: 1.08681,
          v: 42
        },
        {
          t: 16200,
          o: 1.0868,
          h: 1.08682,
          l: 1.08674,
          c: 1.0868,
          v: 37
        },
        {
          t: 16500,
          o: 1.0868,
          h: 1.0868,
          l: 1.08669,
          c: 1.08674,
          v: 66
        },
        {
          t: 16800,
          o: 1.08674,
          h: 1.08677,
          l: 1.08669,
          c: 1.08675,
          v: 38
        },
        {
          t: 17100,
          o: 1.08674,
          h: 1.08675,
          l: 1.08664,
          c: 1.08665,
          v: 63
        },
        {
          t: 17400,
          o: 1.08664,
          h: 1.08665,
          l: 1.08649,
          c: 1.08661,
          v: 58
        },
        {
          t: 17700,
          o: 1.08661,
          h: 1.08686,
          l: 1.08658,
          c: 1.08686,
          v: 66
        },
        {
          t: 18000,
          o: 1.08686,
          h: 1.0869,
          l: 1.0868,
          c: 1.08687,
          v: 118
        },
        {
          t: 18300,
          o: 1.08688,
          h: 1.08697,
          l: 1.08687,
          c: 1.08697,
          v: 47
        },
        {
          t: 18600,
          o: 1.08697,
          h: 1.08697,
          l: 1.08685,
          c: 1.08687,
          v: 28
        },
        {
          t: 18900,
          o: 1.08688,
          h: 1.08693,
          l: 1.08685,
          c: 1.0869,
          v: 58
        },
        {
          t: 19200,
          o: 1.08691,
          h: 1.08697,
          l: 1.08691,
          c: 1.08697,
          v: 19
        },
        {
          t: 19500,
          o: 1.08698,
          h: 1.08698,
          l: 1.08671,
          c: 1.08678,
          v: 63
        },
        {
          t: 19800,
          o: 1.08678,
          h: 1.08692,
          l: 1.08677,
          c: 1.08692,
          v: 96
        },
        {
          t: 20100,
          o: 1.08691,
          h: 1.08702,
          l: 1.08687,
          c: 1.08702,
          v: 70
        },
        {
          t: 20400,
          o: 1.08702,
          h: 1.08702,
          l: 1.08695,
          c: 1.08702,
          v: 50
        },
        {
          t: 20700,
          o: 1.08702,
          h: 1.08702,
          l: 1.08696,
          c: 1.087,
          v: 78
        },
        {
          t: 21000,
          o: 1.08699,
          h: 1.08708,
          l: 1.08698,
          c: 1.08705,
          v: 64
        },
        {
          t: 21300,
          o: 1.08707,
          h: 1.08712,
          l: 1.08701,
          c: 1.08711,
          v: 102
        },
        {
          t: 21600,
          o: 1.08711,
          h: 1.08717,
          l: 1.08705,
          c: 1.08713,
          v: 114
        },
        {
          t: 21900,
          o: 1.08713,
          h: 1.08716,
          l: 1.08696,
          c: 1.08698,
          v: 92
        },
        {
          t: 22200,
          o: 1.08699,
          h: 1.08707,
          l: 1.08697,
          c: 1.08707,
          v: 93
        },
        {
          t: 22500,
          o: 1.08707,
          h: 1.08717,
          l: 1.08706,
          c: 1.08717,
          v: 95
        },
        {
          t: 22800,
          o: 1.08717,
          h: 1.08722,
          l: 1.08713,
          c: 1.08715,
          v: 69
        },
        {
          t: 23100,
          o: 1.08713,
          h: 1.08716,
          l: 1.08705,
          c: 1.08716,
          v: 52
        },
        {
          t: 23400,
          o: 1.08715,
          h: 1.08727,
          l: 1.08713,
          c: 1.08714,
          v: 84
        },
        {
          t: 23700,
          o: 1.08713,
          h: 1.08717,
          l: 1.08705,
          c: 1.08706,
          v: 44
        },
        {
          t: 24000,
          o: 1.08705,
          h: 1.08706,
          l: 1.08691,
          c: 1.08693,
          v: 92
        },
        {
          t: 24300,
          o: 1.08691,
          h: 1.08702,
          l: 1.0869,
          c: 1.08702,
          v: 67
        },
        {
          t: 24600,
          o: 1.08703,
          h: 1.08707,
          l: 1.08701,
          c: 1.08707,
          v: 51
        },
        {
          t: 24900,
          o: 1.08706,
          h: 1.08711,
          l: 1.08696,
          c: 1.0871,
          v: 68
        },
        {
          t: 25200,
          o: 1.08709,
          h: 1.08735,
          l: 1.08703,
          c: 1.0873,
          v: 124
        },
        {
          t: 25500,
          o: 1.08727,
          h: 1.08743,
          l: 1.08715,
          c: 1.08741,
          v: 196
        },
        {
          t: 25800,
          o: 1.08746,
          h: 1.08746,
          l: 1.08725,
          c: 1.08736,
          v: 176
        },
        {
          t: 26100,
          o: 1.08735,
          h: 1.08745,
          l: 1.08729,
          c: 1.08741,
          v: 101
        },
        {
          t: 26400,
          o: 1.0874,
          h: 1.08783,
          l: 1.0874,
          c: 1.08777,
          v: 197
        },
        {
          t: 26700,
          o: 1.08775,
          h: 1.08781,
          l: 1.08748,
          c: 1.08757,
          v: 181
        },
        {
          t: 27000,
          o: 1.08758,
          h: 1.08763,
          l: 1.08747,
          c: 1.08762,
          v: 163
        },
        {
          t: 27300,
          o: 1.08762,
          h: 1.08782,
          l: 1.08759,
          c: 1.08764,
          v: 177
        },
        {
          t: 27600,
          o: 1.08765,
          h: 1.08768,
          l: 1.0876,
          c: 1.08762,
          v: 106
        },
        {
          t: 27900,
          o: 1.08762,
          h: 1.08776,
          l: 1.08757,
          c: 1.08773,
          v: 237
        },
        {
          t: 28200,
          o: 1.08775,
          h: 1.08776,
          l: 1.08766,
          c: 1.08772,
          v: 132
        },
        {
          t: 28500,
          o: 1.0877,
          h: 1.0879,
          l: 1.08756,
          c: 1.08788,
          v: 189
        },
        {
          t: 28800,
          o: 1.08786,
          h: 1.08814,
          l: 1.08751,
          c: 1.08791,
          v: 319
        },
        {
          t: 29100,
          o: 1.08792,
          h: 1.08813,
          l: 1.08791,
          c: 1.08796,
          v: 161
        },
        {
          t: 29400,
          o: 1.08798,
          h: 1.08803,
          l: 1.08791,
          c: 1.08801,
          v: 162
        },
        {
          t: 29700,
          o: 1.088,
          h: 1.08805,
          l: 1.08786,
          c: 1.08795,
          v: 148
        },
        {
          t: 30000,
          o: 1.08795,
          h: 1.08806,
          l: 1.08784,
          c: 1.088,
          v: 214
        },
        {
          t: 30300,
          o: 1.08802,
          h: 1.08806,
          l: 1.0879,
          c: 1.08803,
          v: 108
        },
        {
          t: 30600,
          o: 1.08804,
          h: 1.08833,
          l: 1.08803,
          c: 1.08828,
          v: 190
        },
        {
          t: 30900,
          o: 1.08827,
          h: 1.08849,
          l: 1.08822,
          c: 1.08845,
          v: 206
        },
        {
          t: 31200,
          o: 1.08845,
          h: 1.08855,
          l: 1.0884,
          c: 1.08848,
          v: 183
        },
        {
          t: 31500,
          o: 1.08847,
          h: 1.08862,
          l: 1.0884,
          c: 1.0886,
          v: 155
        },
        {
          t: 31800,
          o: 1.0886,
          h: 1.08872,
          l: 1.08856,
          c: 1.08862,
          v: 161
        },
        {
          t: 32100,
          o: 1.08862,
          h: 1.0887,
          l: 1.08846,
          c: 1.08864,
          v: 119
        },
        {
          t: 32400,
          o: 1.08864,
          h: 1.08883,
          l: 1.08863,
          c: 1.0887,
          v: 169
        },
        {
          t: 32700,
          o: 1.08871,
          h: 1.08883,
          l: 1.08854,
          c: 1.08859,
          v: 123
        },
        {
          t: 33000,
          o: 1.08859,
          h: 1.08869,
          l: 1.08844,
          c: 1.08848,
          v: 145
        },
        {
          t: 33300,
          o: 1.08847,
          h: 1.08847,
          l: 1.08821,
          c: 1.08825,
          v: 138
        },
        {
          t: 33600,
          o: 1.08827,
          h: 1.08839,
          l: 1.08811,
          c: 1.08838,
          v: 134
        },
        {
          t: 33900,
          o: 1.08837,
          h: 1.08864,
          l: 1.08832,
          c: 1.08851,
          v: 161
        },
        {
          t: 34200,
          o: 1.0885,
          h: 1.08868,
          l: 1.0884,
          c: 1.08852,
          v: 128
        },
        {
          t: 34500,
          o: 1.08852,
          h: 1.08852,
          l: 1.08836,
          c: 1.08837,
          v: 112
        },
        {
          t: 34800,
          o: 1.08837,
          h: 1.08843,
          l: 1.08823,
          c: 1.08843,
          v: 161
        },
        {
          t: 35100,
          o: 1.08844,
          h: 1.08853,
          l: 1.08831,
          c: 1.08832,
          v: 124
        },
        {
          t: 35400,
          o: 1.08835,
          h: 1.08857,
          l: 1.08833,
          c: 1.08852,
          v: 152
        },
        {
          t: 35700,
          o: 1.08851,
          h: 1.08852,
          l: 1.08817,
          c: 1.08824,
          v: 136
        },
        {
          t: 36000,
          o: 1.08826,
          h: 1.08826,
          l: 1.08804,
          c: 1.0881,
          v: 219
        },
        {
          t: 36300,
          o: 1.08811,
          h: 1.0882,
          l: 1.08796,
          c: 1.0881,
          v: 188
        },
        {
          t: 36600,
          o: 1.0881,
          h: 1.08824,
          l: 1.08806,
          c: 1.0881,
          v: 191
        },
        {
          t: 36900,
          o: 1.08812,
          h: 1.08816,
          l: 1.08781,
          c: 1.08786,
          v: 169
        },
        {
          t: 37200,
          o: 1.08788,
          h: 1.08819,
          l: 1.08784,
          c: 1.08815,
          v: 162
        },
        {
          t: 37500,
          o: 1.08815,
          h: 1.08834,
          l: 1.08815,
          c: 1.08825,
          v: 143
        },
        {
          t: 37800,
          o: 1.08824,
          h: 1.08832,
          l: 1.08811,
          c: 1.08827,
          v: 150
        },
        {
          t: 38100,
          o: 1.08828,
          h: 1.08856,
          l: 1.08828,
          c: 1.08853,
          v: 112
        },
        {
          t: 38400,
          o: 1.08853,
          h: 1.08872,
          l: 1.08828,
          c: 1.08828,
          v: 202
        },
        {
          t: 38700,
          o: 1.08833,
          h: 1.08833,
          l: 1.08796,
          c: 1.08805,
          v: 123
        },
        {
          t: 39000,
          o: 1.08803,
          h: 1.08811,
          l: 1.08789,
          c: 1.08798,
          v: 112
        },
        {
          t: 39300,
          o: 1.088,
          h: 1.088,
          l: 1.08734,
          c: 1.08736,
          v: 225
        },
        {
          t: 39600,
          o: 1.08735,
          h: 1.08752,
          l: 1.08684,
          c: 1.08684,
          v: 302
        },
        {
          t: 39900,
          o: 1.08681,
          h: 1.08695,
          l: 1.0868,
          c: 1.08689,
          v: 198
        },
        {
          t: 40200,
          o: 1.0869,
          h: 1.08714,
          l: 1.0869,
          c: 1.087,
          v: 147
        },
        {
          t: 40500,
          o: 1.08703,
          h: 1.08722,
          l: 1.08702,
          c: 1.08722,
          v: 126
        },
        {
          t: 40800,
          o: 1.08721,
          h: 1.08723,
          l: 1.0865,
          c: 1.08658,
          v: 180
        },
        {
          t: 41100,
          o: 1.08657,
          h: 1.08685,
          l: 1.08657,
          c: 1.08669,
          v: 221
        },
        {
          t: 41400,
          o: 1.0867,
          h: 1.08708,
          l: 1.08669,
          c: 1.08699,
          v: 160
        },
        {
          t: 41700,
          o: 1.087,
          h: 1.08704,
          l: 1.0867,
          c: 1.08684,
          v: 198
        },
        {
          t: 42000,
          o: 1.08685,
          h: 1.08698,
          l: 1.08663,
          c: 1.08668,
          v: 133
        },
        {
          t: 42300,
          o: 1.08672,
          h: 1.08682,
          l: 1.08662,
          c: 1.08671,
          v: 215
        },
        {
          t: 42600,
          o: 1.08671,
          h: 1.08686,
          l: 1.08665,
          c: 1.0867,
          v: 259
        },
        {
          t: 42900,
          o: 1.08671,
          h: 1.08684,
          l: 1.08556,
          c: 1.08573,
          v: 366
        },
        {
          t: 43200,
          o: 1.08574,
          h: 1.08595,
          l: 1.08551,
          c: 1.08563,
          v: 325
        },
        {
          t: 43500,
          o: 1.08561,
          h: 1.08572,
          l: 1.08536,
          c: 1.08546,
          v: 348
        },
        {
          t: 43800,
          o: 1.08549,
          h: 1.08553,
          l: 1.08527,
          c: 1.08549,
          v: 282
        },
        {
          t: 44100,
          o: 1.08549,
          h: 1.08573,
          l: 1.08545,
          c: 1.08565,
          v: 204
        },
        {
          t: 44400,
          o: 1.08566,
          h: 1.08566,
          l: 1.08546,
          c: 1.08547,
          v: 257
        },
        {
          t: 44700,
          o: 1.08549,
          h: 1.08578,
          l: 1.08546,
          c: 1.08572,
          v: 149
        },
        {
          t: 45000,
          o: 1.08573,
          h: 1.08592,
          l: 1.08567,
          c: 1.08587,
          v: 200
        },
        {
          t: 45300,
          o: 1.08588,
          h: 1.08628,
          l: 1.08585,
          c: 1.08603,
          v: 266
        },
        {
          t: 45600,
          o: 1.08602,
          h: 1.08672,
          l: 1.08602,
          c: 1.08666,
          v: 285
        },
        {
          t: 45900,
          o: 1.08666,
          h: 1.08689,
          l: 1.0866,
          c: 1.08664,
          v: 208
        },
        {
          t: 46200,
          o: 1.08665,
          h: 1.08675,
          l: 1.08659,
          c: 1.0866,
          v: 182
        },
        {
          t: 46500,
          o: 1.08659,
          h: 1.08666,
          l: 1.08617,
          c: 1.08633,
          v: 246
        },
        {
          t: 46800,
          o: 1.08634,
          h: 1.08641,
          l: 1.08607,
          c: 1.08636,
          v: 221
        },
        {
          t: 47100,
          o: 1.08633,
          h: 1.08651,
          l: 1.08618,
          c: 1.08618,
          v: 162
        },
        {
          t: 47400,
          o: 1.08618,
          h: 1.08665,
          l: 1.08618,
          c: 1.08662,
          v: 176
        },
        {
          t: 47700,
          o: 1.08661,
          h: 1.08687,
          l: 1.08658,
          c: 1.0868,
          v: 208
        },
        {
          t: 48000,
          o: 1.08678,
          h: 1.08683,
          l: 1.08625,
          c: 1.08627,
          v: 135
        },
        {
          t: 48300,
          o: 1.08629,
          h: 1.08629,
          l: 1.08595,
          c: 1.08607,
          v: 202
        },
        {
          t: 48600,
          o: 1.08604,
          h: 1.08619,
          l: 1.08563,
          c: 1.08568,
          v: 330
        },
        {
          t: 48900,
          o: 1.08569,
          h: 1.08593,
          l: 1.08563,
          c: 1.08575,
          v: 275
        },
        {
          t: 49200,
          o: 1.08574,
          h: 1.08586,
          l: 1.08555,
          c: 1.0856,
          v: 164
        },
        {
          t: 49500,
          o: 1.08559,
          h: 1.08573,
          l: 1.08549,
          c: 1.08553,
          v: 191
        },
        {
          t: 49800,
          o: 1.08552,
          h: 1.08555,
          l: 1.08528,
          c: 1.08553,
          v: 243
        },
        {
          t: 50100,
          o: 1.08553,
          h: 1.0856,
          l: 1.08535,
          c: 1.08544,
          v: 216
        },
        {
          t: 50400,
          o: 1.08545,
          h: 1.08554,
          l: 1.08509,
          c: 1.08525,
          v: 216
        },
        {
          t: 50700,
          o: 1.08525,
          h: 1.08526,
          l: 1.08477,
          c: 1.08503,
          v: 333
        },
        {
          t: 51000,
          o: 1.08502,
          h: 1.08503,
          l: 1.08472,
          c: 1.08491,
          v: 230
        },
        {
          t: 51300,
          o: 1.08493,
          h: 1.08517,
          l: 1.0848,
          c: 1.08511,
          v: 286
        },
        {
          t: 51600,
          o: 1.08511,
          h: 1.08523,
          l: 1.08501,
          c: 1.08521,
          v: 164
        },
        {
          t: 51900,
          o: 1.0852,
          h: 1.08532,
          l: 1.0851,
          c: 1.08532,
          v: 146
        },
        {
          t: 52200,
          o: 1.08532,
          h: 1.08563,
          l: 1.08531,
          c: 1.08562,
          v: 257
        },
        {
          t: 52500,
          o: 1.08561,
          h: 1.08595,
          l: 1.08559,
          c: 1.08593,
          v: 279
        },
        {
          t: 52800,
          o: 1.08593,
          h: 1.08613,
          l: 1.08545,
          c: 1.08555,
          v: 329
        },
        {
          t: 53100,
          o: 1.08558,
          h: 1.08561,
          l: 1.08536,
          c: 1.08554,
          v: 286
        },
        {
          t: 53400,
          o: 1.0855,
          h: 1.08561,
          l: 1.08526,
          c: 1.08538,
          v: 233
        },
        {
          t: 53700,
          o: 1.08542,
          h: 1.08553,
          l: 1.08515,
          c: 1.08549,
          v: 307
        },
        {
          t: 54000,
          o: 1.08548,
          h: 1.0858,
          l: 1.0853,
          c: 1.08543,
          v: 434
        },
        {
          t: 54300,
          o: 1.08542,
          h: 1.08543,
          l: 1.08448,
          c: 1.08451,
          v: 347
        },
        {
          t: 54600,
          o: 1.08453,
          h: 1.08482,
          l: 1.08448,
          c: 1.0846,
          v: 305
        },
        {
          t: 54900,
          o: 1.08456,
          h: 1.08477,
          l: 1.08414,
          c: 1.08428,
          v: 376
        },
        {
          t: 55200,
          o: 1.08426,
          h: 1.08426,
          l: 1.08335,
          c: 1.08349,
          v: 491
        },
        {
          t: 55500,
          o: 1.08348,
          h: 1.08383,
          l: 1.08346,
          c: 1.08373,
          v: 361
        },
        {
          t: 55800,
          o: 1.0837,
          h: 1.08398,
          l: 1.08355,
          c: 1.08391,
          v: 305
        },
        {
          t: 56100,
          o: 1.08391,
          h: 1.08443,
          l: 1.08388,
          c: 1.0844,
          v: 307
        },
        {
          t: 56400,
          o: 1.08437,
          h: 1.08441,
          l: 1.08404,
          c: 1.08432,
          v: 229
        },
        {
          t: 56700,
          o: 1.08433,
          h: 1.08466,
          l: 1.08428,
          c: 1.08465,
          v: 309
        },
        {
          t: 57000,
          o: 1.08464,
          h: 1.08468,
          l: 1.08427,
          c: 1.08427,
          v: 296
        },
        {
          t: 57300,
          o: 1.08428,
          h: 1.08491,
          l: 1.08392,
          c: 1.0848,
          v: 469
        },
        {
          t: 57600,
          o: 1.08484,
          h: 1.08513,
          l: 1.08471,
          c: 1.08512,
          v: 318
        },
        {
          t: 57900,
          o: 1.08512,
          h: 1.08537,
          l: 1.08498,
          c: 1.08517,
          v: 246
        },
        {
          t: 58200,
          o: 1.08517,
          h: 1.08518,
          l: 1.08486,
          c: 1.08496,
          v: 198
        },
        {
          t: 58500,
          o: 1.08499,
          h: 1.08524,
          l: 1.08489,
          c: 1.08508,
          v: 169
        },
        {
          t: 58800,
          o: 1.08513,
          h: 1.0852,
          l: 1.08499,
          c: 1.08507,
          v: 171
        },
        {
          t: 59100,
          o: 1.08506,
          h: 1.08527,
          l: 1.08498,
          c: 1.08517,
          v: 159
        },
        {
          t: 59400,
          o: 1.08516,
          h: 1.08518,
          l: 1.08484,
          c: 1.08497,
          v: 226
        },
        {
          t: 59700,
          o: 1.08497,
          h: 1.08523,
          l: 1.08484,
          c: 1.08507,
          v: 209
        },
        {
          t: 60000,
          o: 1.08506,
          h: 1.08511,
          l: 1.0849,
          c: 1.08496,
          v: 165
        },
        {
          t: 60300,
          o: 1.08496,
          h: 1.08497,
          l: 1.08471,
          c: 1.08481,
          v: 169
        },
        {
          t: 60600,
          o: 1.08478,
          h: 1.0849,
          l: 1.08475,
          c: 1.08488,
          v: 140
        },
        {
          t: 60900,
          o: 1.08487,
          h: 1.08495,
          l: 1.08468,
          c: 1.08469,
          v: 147
        },
        {
          t: 61200,
          o: 1.0847,
          h: 1.08474,
          l: 1.08455,
          c: 1.08462,
          v: 134
        },
        {
          t: 61500,
          o: 1.08464,
          h: 1.08466,
          l: 1.0844,
          c: 1.0844,
          v: 105
        },
        {
          t: 61800,
          o: 1.0844,
          h: 1.0845,
          l: 1.08394,
          c: 1.08415,
          v: 176
        },
        {
          t: 62100,
          o: 1.08414,
          h: 1.08436,
          l: 1.08411,
          c: 1.0842,
          v: 106
        },
        {
          t: 62400,
          o: 1.0842,
          h: 1.08441,
          l: 1.08386,
          c: 1.08437,
          v: 209
        },
        {
          t: 62700,
          o: 1.08436,
          h: 1.08446,
          l: 1.08411,
          c: 1.08414,
          v: 117
        },
        {
          t: 63000,
          o: 1.08413,
          h: 1.08423,
          l: 1.08403,
          c: 1.08421,
          v: 112
        },
        {
          t: 63300,
          o: 1.0842,
          h: 1.08431,
          l: 1.08389,
          c: 1.08402,
          v: 157
        },
        {
          t: 63600,
          o: 1.08403,
          h: 1.08412,
          l: 1.08398,
          c: 1.08408,
          v: 107
        },
        {
          t: 63900,
          o: 1.08407,
          h: 1.0841,
          l: 1.08394,
          c: 1.08406,
          v: 89
        },
        {
          t: 64200,
          o: 1.08405,
          h: 1.08424,
          l: 1.084,
          c: 1.08414,
          v: 82
        },
        {
          t: 64500,
          o: 1.08414,
          h: 1.08428,
          l: 1.08406,
          c: 1.0842,
          v: 208
        },
        {
          t: 64800,
          o: 1.0842,
          h: 1.0843,
          l: 1.08407,
          c: 1.08411,
          v: 217
        },
        {
          t: 65100,
          o: 1.08409,
          h: 1.08432,
          l: 1.08403,
          c: 1.08428,
          v: 152
        },
        {
          t: 65400,
          o: 1.08426,
          h: 1.08429,
          l: 1.08415,
          c: 1.08416,
          v: 148
        },
        {
          t: 65700,
          o: 1.08417,
          h: 1.08422,
          l: 1.08399,
          c: 1.08402,
          v: 115
        },
        {
          t: 66000,
          o: 1.084,
          h: 1.08408,
          l: 1.08385,
          c: 1.08396,
          v: 144
        },
        {
          t: 66300,
          o: 1.08395,
          h: 1.08404,
          l: 1.08388,
          c: 1.08404,
          v: 70
        },
        {
          t: 66600,
          o: 1.08408,
          h: 1.08408,
          l: 1.08395,
          c: 1.08406,
          v: 125
        },
        {
          t: 66900,
          o: 1.08407,
          h: 1.0842,
          l: 1.08406,
          c: 1.08413,
          v: 151
        },
        {
          t: 67200,
          o: 1.08413,
          h: 1.08423,
          l: 1.08413,
          c: 1.08422,
          v: 55
        },
        {
          t: 67500,
          o: 1.08423,
          h: 1.08423,
          l: 1.08412,
          c: 1.08417,
          v: 71
        },
        {
          t: 67800,
          o: 1.08414,
          h: 1.08423,
          l: 1.08408,
          c: 1.08411,
          v: 97
        },
        {
          t: 68100,
          o: 1.08409,
          h: 1.08419,
          l: 1.08408,
          c: 1.08415,
          v: 98
        },
        {
          t: 68400,
          o: 1.08416,
          h: 1.0842,
          l: 1.0841,
          c: 1.08412,
          v: 91
        },
        {
          t: 68700,
          o: 1.08412,
          h: 1.08413,
          l: 1.08401,
          c: 1.08405,
          v: 74
        },
        {
          t: 69000,
          o: 1.08405,
          h: 1.08421,
          l: 1.08405,
          c: 1.08415,
          v: 55
        },
        {
          t: 69300,
          o: 1.08413,
          h: 1.0843,
          l: 1.0841,
          c: 1.08427,
          v: 70
        },
        {
          t: 69600,
          o: 1.08428,
          h: 1.08428,
          l: 1.08416,
          c: 1.08425,
          v: 62
        },
        {
          t: 69900,
          o: 1.08426,
          h: 1.08432,
          l: 1.08409,
          c: 1.08413,
          v: 91
        },
        {
          t: 70200,
          o: 1.08413,
          h: 1.08429,
          l: 1.08411,
          c: 1.08429,
          v: 99
        },
        {
          t: 70500,
          o: 1.08428,
          h: 1.08437,
          l: 1.08418,
          c: 1.08422,
          v: 76
        },
        {
          t: 70800,
          o: 1.08423,
          h: 1.08424,
          l: 1.08414,
          c: 1.08418,
          v: 70
        },
        {
          t: 71100,
          o: 1.08419,
          h: 1.08428,
          l: 1.08418,
          c: 1.08426,
          v: 63
        },
        {
          t: 71400,
          o: 1.08425,
          h: 1.08434,
          l: 1.08423,
          c: 1.08425,
          v: 78
        },
        {
          t: 71700,
          o: 1.08426,
          h: 1.08439,
          l: 1.08426,
          c: 1.08429,
          v: 124
        },
        {
          t: 72000,
          o: 1.08427,
          h: 1.0845,
          l: 1.08422,
          c: 1.08434,
          v: 172
        },
        {
          t: 72300,
          o: 1.08434,
          h: 1.08436,
          l: 1.08428,
          c: 1.08428,
          v: 96
        },
        {
          t: 72600,
          o: 1.08426,
          h: 1.08428,
          l: 1.0842,
          c: 1.08428,
          v: 96
        },
        {
          t: 72900,
          o: 1.08427,
          h: 1.0843,
          l: 1.08424,
          c: 1.08426,
          v: 77
        },
        {
          t: 73200,
          o: 1.08423,
          h: 1.08423,
          l: 1.08417,
          c: 1.08418,
          v: 59
        },
        {
          t: 73500,
          o: 1.08417,
          h: 1.08425,
          l: 1.08416,
          c: 1.08417,
          v: 69
        },
        {
          t: 73800,
          o: 1.08417,
          h: 1.08424,
          l: 1.08412,
          c: 1.08416,
          v: 122
        },
        {
          t: 74100,
          o: 1.08416,
          h: 1.08419,
          l: 1.08407,
          c: 1.08416,
          v: 101
        },
        {
          t: 74400,
          o: 1.08417,
          h: 1.08424,
          l: 1.08409,
          c: 1.08418,
          v: 67
        },
        {
          t: 74700,
          o: 1.08417,
          h: 1.08422,
          l: 1.08411,
          c: 1.08422,
          v: 94
        },
        {
          t: 75000,
          o: 1.08421,
          h: 1.08424,
          l: 1.0841,
          c: 1.0841,
          v: 90
        },
        {
          t: 75300,
          o: 1.08412,
          h: 1.08419,
          l: 1.08406,
          c: 1.08409,
          v: 132
        },
        {
          t: 75600,
          o: 1.0841,
          h: 1.0841,
          l: 1.08404,
          c: 1.08404,
          v: 72
        },
        {
          t: 75900,
          o: 1.08403,
          h: 1.08403,
          l: 1.0839,
          c: 1.08397,
          v: 82
        },
        {
          t: 76200,
          o: 1.08397,
          h: 1.08397,
          l: 1.08388,
          c: 1.08389,
          v: 51
        },
        {
          t: 76500,
          o: 1.08388,
          h: 1.0839,
          l: 1.08386,
          c: 1.08387,
          v: 32
        },
        {
          t: 76800,
          o: 1.08388,
          h: 1.08388,
          l: 1.08377,
          c: 1.08378,
          v: 39
        },
        {
          t: 77100,
          o: 1.08379,
          h: 1.08388,
          l: 1.08376,
          c: 1.08388,
          v: 44
        },
        {
          t: 77400,
          o: 1.08387,
          h: 1.08398,
          l: 1.08386,
          c: 1.08395,
          v: 37
        },
        {
          t: 77700,
          o: 1.08393,
          h: 1.08398,
          l: 1.08393,
          c: 1.08397,
          v: 32
        },
        {
          t: 78000,
          o: 1.08395,
          h: 1.08402,
          l: 1.08388,
          c: 1.08402,
          v: 54
        },
        {
          t: 78300,
          o: 1.08403,
          h: 1.08403,
          l: 1.08394,
          c: 1.08397,
          v: 91
        },
        {
          t: 78600,
          o: 1.08396,
          h: 1.08398,
          l: 1.08393,
          c: 1.08395,
          v: 100
        },
        {
          t: 78900,
          o: 1.08394,
          h: 1.08416,
          l: 1.08384,
          c: 1.08406,
          v: 251
        },
        {
          t: 79200,
          o: 1.08405,
          h: 1.08416,
          l: 1.08386,
          c: 1.08398,
          v: 132
        },
        {
          t: 79500,
          o: 1.08398,
          h: 1.08406,
          l: 1.08389,
          c: 1.08404,
          v: 56
        },
        {
          t: 79800,
          o: 1.08402,
          h: 1.08416,
          l: 1.08371,
          c: 1.08383,
          v: 100
        },
        {
          t: 80100,
          o: 1.08384,
          h: 1.08388,
          l: 1.08376,
          c: 1.08388,
          v: 39
        },
        {
          t: 80400,
          o: 1.08387,
          h: 1.0839,
          l: 1.08371,
          c: 1.08381,
          v: 79
        },
        {
          t: 80700,
          o: 1.08381,
          h: 1.08391,
          l: 1.08375,
          c: 1.08377,
          v: 37
        },
        {
          t: 81000,
          o: 1.08377,
          h: 1.08388,
          l: 1.08368,
          c: 1.08385,
          v: 74
        },
        {
          t: 81300,
          o: 1.08383,
          h: 1.08385,
          l: 1.08358,
          c: 1.08385,
          v: 58
        },
        {
          t: 81600,
          o: 1.08378,
          h: 1.08387,
          l: 1.08378,
          c: 1.08384,
          v: 37
        },
        {
          t: 81900,
          o: 1.08385,
          h: 1.08394,
          l: 1.08384,
          c: 1.08393,
          v: 52
        },
        {
          t: 82200,
          o: 1.08393,
          h: 1.08393,
          l: 1.08383,
          c: 1.08385,
          v: 50
        },
        {
          t: 82500,
          o: 1.08386,
          h: 1.08404,
          l: 1.08385,
          c: 1.08395,
          v: 45
        },
        {
          t: 82800,
          o: 1.08397,
          h: 1.08398,
          l: 1.08387,
          c: 1.08393,
          v: 85
        },
        {
          t: 83100,
          o: 1.08392,
          h: 1.08396,
          l: 1.0838,
          c: 1.08382,
          v: 45
        },
        {
          t: 83400,
          o: 1.08382,
          h: 1.08387,
          l: 1.08381,
          c: 1.08386,
          v: 86
        },
        {
          t: 83700,
          o: 1.08387,
          h: 1.08394,
          l: 1.08386,
          c: 1.08392,
          v: 43
        },
        {
          t: 84000,
          o: 1.08393,
          h: 1.08393,
          l: 1.08378,
          c: 1.08382,
          v: 41
        },
        {
          t: 84300,
          o: 1.08385,
          h: 1.08394,
          l: 1.08381,
          c: 1.08391,
          v: 46
        },
        {
          t: 84600,
          o: 1.08389,
          h: 1.08391,
          l: 1.08381,
          c: 1.0839,
          v: 47
        },
        {
          t: 84900,
          o: 1.08392,
          h: 1.08397,
          l: 1.08389,
          c: 1.08389,
          v: 78
        },
        {
          t: 85200,
          o: 1.0839,
          h: 1.08402,
          l: 1.08387,
          c: 1.08397,
          v: 127
        },
        {
          t: 85500,
          o: 1.08396,
          h: 1.08403,
          l: 1.0839,
          c: 1.08403,
          v: 33
        },
        {
          t: 85800,
          o: 1.08402,
          h: 1.08414,
          l: 1.08402,
          c: 1.08412,
          v: 30
        },
        {
          t: 86100,
          o: 1.08411,
          h: 1.08413,
          l: 1.08407,
          c: 1.08408,
          v: 60
        }
      ]
    },
    {
      d: 1581638400,
      b: [
        {
          t: 0,
          o: 1.08411,
          h: 1.08412,
          l: 1.08366,
          c: 1.08366,
          v: 173
        },
        {
          t: 300,
          o: 1.08367,
          h: 1.08373,
          l: 1.08356,
          c: 1.08363,
          v: 105
        },
        {
          t: 600,
          o: 1.08362,
          h: 1.08362,
          l: 1.08268,
          c: 1.08317,
          v: 174
        },
        {
          t: 900,
          o: 1.08318,
          h: 1.08352,
          l: 1.08317,
          c: 1.08339,
          v: 157
        },
        {
          t: 1200,
          o: 1.08338,
          h: 1.08343,
          l: 1.08326,
          c: 1.08337,
          v: 120
        },
        {
          t: 1500,
          o: 1.08336,
          h: 1.08336,
          l: 1.0832,
          c: 1.08321,
          v: 138
        },
        {
          t: 1800,
          o: 1.0832,
          h: 1.08332,
          l: 1.08318,
          c: 1.08331,
          v: 111
        },
        {
          t: 2100,
          o: 1.08332,
          h: 1.0835,
          l: 1.08326,
          c: 1.08348,
          v: 97
        },
        {
          t: 2400,
          o: 1.08347,
          h: 1.08353,
          l: 1.08346,
          c: 1.0835,
          v: 106
        },
        {
          t: 2700,
          o: 1.08351,
          h: 1.08362,
          l: 1.0835,
          c: 1.08362,
          v: 62
        },
        {
          t: 3000,
          o: 1.08361,
          h: 1.08364,
          l: 1.08355,
          c: 1.08364,
          v: 64
        },
        {
          t: 3300,
          o: 1.08366,
          h: 1.08381,
          l: 1.08361,
          c: 1.08376,
          v: 153
        },
        {
          t: 3600,
          o: 1.08376,
          h: 1.08378,
          l: 1.08362,
          c: 1.08369,
          v: 87
        },
        {
          t: 3900,
          o: 1.08371,
          h: 1.08373,
          l: 1.08358,
          c: 1.08372,
          v: 70
        },
        {
          t: 4200,
          o: 1.08373,
          h: 1.08379,
          l: 1.08368,
          c: 1.08372,
          v: 78
        },
        {
          t: 4500,
          o: 1.08371,
          h: 1.08378,
          l: 1.08365,
          c: 1.08366,
          v: 92
        },
        {
          t: 4800,
          o: 1.08367,
          h: 1.08368,
          l: 1.08352,
          c: 1.08358,
          v: 55
        },
        {
          t: 5100,
          o: 1.08357,
          h: 1.08358,
          l: 1.08347,
          c: 1.08349,
          v: 45
        },
        {
          t: 5400,
          o: 1.08348,
          h: 1.08352,
          l: 1.08345,
          c: 1.08349,
          v: 73
        },
        {
          t: 5700,
          o: 1.08349,
          h: 1.08351,
          l: 1.08328,
          c: 1.08335,
          v: 134
        },
        {
          t: 6000,
          o: 1.08335,
          h: 1.08348,
          l: 1.08329,
          c: 1.08348,
          v: 58
        },
        {
          t: 6300,
          o: 1.08348,
          h: 1.08353,
          l: 1.08346,
          c: 1.08352,
          v: 74
        },
        {
          t: 6600,
          o: 1.08351,
          h: 1.08358,
          l: 1.08338,
          c: 1.08343,
          v: 89
        },
        {
          t: 6900,
          o: 1.08343,
          h: 1.08371,
          l: 1.08341,
          c: 1.08358,
          v: 130
        },
        {
          t: 7200,
          o: 1.08357,
          h: 1.08358,
          l: 1.08348,
          c: 1.08353,
          v: 88
        },
        {
          t: 7500,
          o: 1.08354,
          h: 1.08368,
          l: 1.08349,
          c: 1.0835,
          v: 92
        },
        {
          t: 7800,
          o: 1.08351,
          h: 1.08352,
          l: 1.08345,
          c: 1.08346,
          v: 57
        },
        {
          t: 8100,
          o: 1.08343,
          h: 1.08347,
          l: 1.08339,
          c: 1.08342,
          v: 63
        },
        {
          t: 8400,
          o: 1.08341,
          h: 1.08355,
          l: 1.08341,
          c: 1.0835,
          v: 63
        },
        {
          t: 8700,
          o: 1.08353,
          h: 1.08357,
          l: 1.08345,
          c: 1.08346,
          v: 58
        },
        {
          t: 9000,
          o: 1.08347,
          h: 1.08357,
          l: 1.08345,
          c: 1.08355,
          v: 62
        },
        {
          t: 9300,
          o: 1.08355,
          h: 1.08358,
          l: 1.08354,
          c: 1.08357,
          v: 27
        },
        {
          t: 9600,
          o: 1.08358,
          h: 1.08361,
          l: 1.08348,
          c: 1.0835,
          v: 44
        },
        {
          t: 9900,
          o: 1.08351,
          h: 1.08358,
          l: 1.08348,
          c: 1.08357,
          v: 42
        },
        {
          t: 10200,
          o: 1.08356,
          h: 1.08366,
          l: 1.08355,
          c: 1.08356,
          v: 54
        },
        {
          t: 10500,
          o: 1.08355,
          h: 1.08365,
          l: 1.08349,
          c: 1.08365,
          v: 83
        },
        {
          t: 10800,
          o: 1.08365,
          h: 1.08365,
          l: 1.08345,
          c: 1.08353,
          v: 121
        },
        {
          t: 11100,
          o: 1.08353,
          h: 1.08362,
          l: 1.08351,
          c: 1.08362,
          v: 43
        },
        {
          t: 11400,
          o: 1.08362,
          h: 1.08362,
          l: 1.0835,
          c: 1.0835,
          v: 34
        },
        {
          t: 11700,
          o: 1.0835,
          h: 1.08358,
          l: 1.0835,
          c: 1.08356,
          v: 37
        },
        {
          t: 12000,
          o: 1.08357,
          h: 1.08362,
          l: 1.0834,
          c: 1.0834,
          v: 80
        },
        {
          t: 12300,
          o: 1.08341,
          h: 1.08348,
          l: 1.08339,
          c: 1.08346,
          v: 68
        },
        {
          t: 12600,
          o: 1.08346,
          h: 1.08348,
          l: 1.08338,
          c: 1.08341,
          v: 40
        },
        {
          t: 12900,
          o: 1.0834,
          h: 1.08341,
          l: 1.08335,
          c: 1.08336,
          v: 34
        },
        {
          t: 13200,
          o: 1.08332,
          h: 1.08337,
          l: 1.08329,
          c: 1.08336,
          v: 30
        },
        {
          t: 13500,
          o: 1.08335,
          h: 1.08335,
          l: 1.0832,
          c: 1.08321,
          v: 72
        },
        {
          t: 13800,
          o: 1.08321,
          h: 1.08322,
          l: 1.08305,
          c: 1.0831,
          v: 111
        },
        {
          t: 14100,
          o: 1.08309,
          h: 1.08312,
          l: 1.08303,
          c: 1.08306,
          v: 80
        },
        {
          t: 14400,
          o: 1.08307,
          h: 1.0832,
          l: 1.08306,
          c: 1.08319,
          v: 87
        },
        {
          t: 14700,
          o: 1.08318,
          h: 1.08321,
          l: 1.08313,
          c: 1.08313,
          v: 41
        },
        {
          t: 15000,
          o: 1.08312,
          h: 1.08312,
          l: 1.08304,
          c: 1.08306,
          v: 61
        },
        {
          t: 15300,
          o: 1.08309,
          h: 1.08318,
          l: 1.08307,
          c: 1.08317,
          v: 43
        },
        {
          t: 15600,
          o: 1.08318,
          h: 1.08318,
          l: 1.0831,
          c: 1.08318,
          v: 70
        },
        {
          t: 15900,
          o: 1.0832,
          h: 1.08322,
          l: 1.0832,
          c: 1.08321,
          v: 27
        },
        {
          t: 16200,
          o: 1.0832,
          h: 1.08322,
          l: 1.08316,
          c: 1.08316,
          v: 26
        },
        {
          t: 16500,
          o: 1.08318,
          h: 1.08321,
          l: 1.08318,
          c: 1.0832,
          v: 11
        },
        {
          t: 16800,
          o: 1.08321,
          h: 1.08322,
          l: 1.08317,
          c: 1.0832,
          v: 19
        },
        {
          t: 17100,
          o: 1.08321,
          h: 1.08321,
          l: 1.08318,
          c: 1.0832,
          v: 24
        },
        {
          t: 17400,
          o: 1.0832,
          h: 1.0832,
          l: 1.08319,
          c: 1.0832,
          v: 6
        },
        {
          t: 17700,
          o: 1.08321,
          h: 1.08326,
          l: 1.08316,
          c: 1.08325,
          v: 51
        },
        {
          t: 18000,
          o: 1.08325,
          h: 1.0834,
          l: 1.08325,
          c: 1.08332,
          v: 67
        },
        {
          t: 18300,
          o: 1.08331,
          h: 1.08335,
          l: 1.08331,
          c: 1.08331,
          v: 48
        },
        {
          t: 18600,
          o: 1.0833,
          h: 1.08336,
          l: 1.0833,
          c: 1.08336,
          v: 26
        },
        {
          t: 18900,
          o: 1.08335,
          h: 1.08341,
          l: 1.08332,
          c: 1.08339,
          v: 46
        },
        {
          t: 19200,
          o: 1.08338,
          h: 1.08342,
          l: 1.08336,
          c: 1.08341,
          v: 43
        },
        {
          t: 19500,
          o: 1.0834,
          h: 1.08342,
          l: 1.0834,
          c: 1.08342,
          v: 25
        },
        {
          t: 19800,
          o: 1.08341,
          h: 1.08346,
          l: 1.08336,
          c: 1.08337,
          v: 38
        },
        {
          t: 20100,
          o: 1.08334,
          h: 1.08337,
          l: 1.0833,
          c: 1.08337,
          v: 31
        },
        {
          t: 20400,
          o: 1.08336,
          h: 1.08347,
          l: 1.08336,
          c: 1.08346,
          v: 49
        },
        {
          t: 20700,
          o: 1.08349,
          h: 1.08354,
          l: 1.08345,
          c: 1.08346,
          v: 47
        },
        {
          t: 21000,
          o: 1.08347,
          h: 1.08361,
          l: 1.08346,
          c: 1.0836,
          v: 69
        },
        {
          t: 21300,
          o: 1.08361,
          h: 1.08368,
          l: 1.08356,
          c: 1.08366,
          v: 91
        },
        {
          t: 21600,
          o: 1.08366,
          h: 1.08367,
          l: 1.08351,
          c: 1.08355,
          v: 95
        },
        {
          t: 21900,
          o: 1.08352,
          h: 1.08354,
          l: 1.08343,
          c: 1.08345,
          v: 52
        },
        {
          t: 22200,
          o: 1.08346,
          h: 1.08357,
          l: 1.08338,
          c: 1.08356,
          v: 89
        },
        {
          t: 22500,
          o: 1.0836,
          h: 1.08362,
          l: 1.0835,
          c: 1.08357,
          v: 92
        },
        {
          t: 22800,
          o: 1.08358,
          h: 1.08368,
          l: 1.08353,
          c: 1.08366,
          v: 59
        },
        {
          t: 23100,
          o: 1.08367,
          h: 1.08383,
          l: 1.08366,
          c: 1.08375,
          v: 90
        },
        {
          t: 23400,
          o: 1.08376,
          h: 1.08376,
          l: 1.08369,
          c: 1.08371,
          v: 92
        },
        {
          t: 23700,
          o: 1.08371,
          h: 1.08383,
          l: 1.08367,
          c: 1.08375,
          v: 50
        },
        {
          t: 24000,
          o: 1.08375,
          h: 1.08378,
          l: 1.08375,
          c: 1.08376,
          v: 20
        },
        {
          t: 24300,
          o: 1.08372,
          h: 1.08381,
          l: 1.08365,
          c: 1.08365,
          v: 46
        },
        {
          t: 24600,
          o: 1.08366,
          h: 1.08381,
          l: 1.08362,
          c: 1.08371,
          v: 90
        },
        {
          t: 24900,
          o: 1.08372,
          h: 1.08372,
          l: 1.08346,
          c: 1.08351,
          v: 113
        },
        {
          t: 25200,
          o: 1.08349,
          h: 1.08373,
          l: 1.08343,
          c: 1.0837,
          v: 182
        },
        {
          t: 25500,
          o: 1.08368,
          h: 1.08393,
          l: 1.08366,
          c: 1.08392,
          v: 115
        },
        {
          t: 25800,
          o: 1.08392,
          h: 1.08398,
          l: 1.08384,
          c: 1.0839,
          v: 127
        },
        {
          t: 26100,
          o: 1.08387,
          h: 1.08392,
          l: 1.08383,
          c: 1.08386,
          v: 118
        },
        {
          t: 26400,
          o: 1.08387,
          h: 1.08392,
          l: 1.0837,
          c: 1.0837,
          v: 70
        },
        {
          t: 26700,
          o: 1.0837,
          h: 1.08377,
          l: 1.08361,
          c: 1.08365,
          v: 81
        },
        {
          t: 27000,
          o: 1.08365,
          h: 1.08373,
          l: 1.08363,
          c: 1.0837,
          v: 92
        },
        {
          t: 27300,
          o: 1.0837,
          h: 1.08405,
          l: 1.08365,
          c: 1.08403,
          v: 133
        },
        {
          t: 27600,
          o: 1.08403,
          h: 1.08415,
          l: 1.08397,
          c: 1.08405,
          v: 143
        },
        {
          t: 27900,
          o: 1.08406,
          h: 1.08421,
          l: 1.08402,
          c: 1.08418,
          v: 111
        },
        {
          t: 28200,
          o: 1.08418,
          h: 1.08427,
          l: 1.08415,
          c: 1.08425,
          v: 114
        },
        {
          t: 28500,
          o: 1.08425,
          h: 1.08427,
          l: 1.084,
          c: 1.08405,
          v: 159
        },
        {
          t: 28800,
          o: 1.08408,
          h: 1.08423,
          l: 1.08386,
          c: 1.08408,
          v: 211
        },
        {
          t: 29100,
          o: 1.08406,
          h: 1.08418,
          l: 1.0837,
          c: 1.08376,
          v: 148
        },
        {
          t: 29400,
          o: 1.08376,
          h: 1.08387,
          l: 1.08319,
          c: 1.08335,
          v: 209
        },
        {
          t: 29700,
          o: 1.08335,
          h: 1.08343,
          l: 1.0833,
          c: 1.08331,
          v: 130
        },
        {
          t: 30000,
          o: 1.08332,
          h: 1.08346,
          l: 1.08318,
          c: 1.08338,
          v: 147
        },
        {
          t: 30300,
          o: 1.08337,
          h: 1.08357,
          l: 1.08336,
          c: 1.08354,
          v: 109
        },
        {
          t: 30600,
          o: 1.08354,
          h: 1.08377,
          l: 1.08349,
          c: 1.08377,
          v: 141
        },
        {
          t: 30900,
          o: 1.08381,
          h: 1.08388,
          l: 1.08367,
          c: 1.08385,
          v: 123
        },
        {
          t: 31200,
          o: 1.08384,
          h: 1.08392,
          l: 1.08371,
          c: 1.08372,
          v: 102
        },
        {
          t: 31500,
          o: 1.08371,
          h: 1.0838,
          l: 1.08367,
          c: 1.08372,
          v: 131
        },
        {
          t: 31800,
          o: 1.08372,
          h: 1.08383,
          l: 1.08366,
          c: 1.08373,
          v: 124
        },
        {
          t: 32100,
          o: 1.08375,
          h: 1.08429,
          l: 1.08375,
          c: 1.08427,
          v: 208
        },
        {
          t: 32400,
          o: 1.08424,
          h: 1.08424,
          l: 1.0841,
          c: 1.08411,
          v: 192
        },
        {
          t: 32700,
          o: 1.0841,
          h: 1.08433,
          l: 1.08396,
          c: 1.08433,
          v: 183
        },
        {
          t: 33000,
          o: 1.08432,
          h: 1.08453,
          l: 1.08432,
          c: 1.08453,
          v: 202
        },
        {
          t: 33300,
          o: 1.08453,
          h: 1.08462,
          l: 1.0844,
          c: 1.08453,
          v: 169
        },
        {
          t: 33600,
          o: 1.08452,
          h: 1.08478,
          l: 1.08446,
          c: 1.08462,
          v: 203
        },
        {
          t: 33900,
          o: 1.08466,
          h: 1.08478,
          l: 1.0845,
          c: 1.08462,
          v: 140
        },
        {
          t: 34200,
          o: 1.08464,
          h: 1.08497,
          l: 1.08456,
          c: 1.08475,
          v: 199
        },
        {
          t: 34500,
          o: 1.08475,
          h: 1.08475,
          l: 1.08446,
          c: 1.0845,
          v: 173
        },
        {
          t: 34800,
          o: 1.08449,
          h: 1.08462,
          l: 1.0844,
          c: 1.08461,
          v: 173
        },
        {
          t: 35100,
          o: 1.0846,
          h: 1.08478,
          l: 1.08457,
          c: 1.08471,
          v: 149
        },
        {
          t: 35400,
          o: 1.08467,
          h: 1.0847,
          l: 1.08441,
          c: 1.08463,
          v: 169
        },
        {
          t: 35700,
          o: 1.08462,
          h: 1.08465,
          l: 1.08443,
          c: 1.08444,
          v: 157
        },
        {
          t: 36000,
          o: 1.08445,
          h: 1.08457,
          l: 1.08429,
          c: 1.08429,
          v: 175
        },
        {
          t: 36300,
          o: 1.0843,
          h: 1.08437,
          l: 1.08429,
          c: 1.08437,
          v: 88
        },
        {
          t: 36600,
          o: 1.08438,
          h: 1.08454,
          l: 1.08422,
          c: 1.08447,
          v: 139
        },
        {
          t: 36900,
          o: 1.08447,
          h: 1.08463,
          l: 1.08439,
          c: 1.08454,
          v: 140
        },
        {
          t: 37200,
          o: 1.08454,
          h: 1.0847,
          l: 1.08453,
          c: 1.08467,
          v: 112
        },
        {
          t: 37500,
          o: 1.08468,
          h: 1.08483,
          l: 1.08457,
          c: 1.08479,
          v: 134
        },
        {
          t: 37800,
          o: 1.08478,
          h: 1.08488,
          l: 1.08421,
          c: 1.08422,
          v: 186
        },
        {
          t: 38100,
          o: 1.08421,
          h: 1.08445,
          l: 1.08421,
          c: 1.08438,
          v: 186
        },
        {
          t: 38400,
          o: 1.08439,
          h: 1.0844,
          l: 1.08409,
          c: 1.0841,
          v: 86
        },
        {
          t: 38700,
          o: 1.0841,
          h: 1.0842,
          l: 1.08393,
          c: 1.08395,
          v: 156
        },
        {
          t: 39000,
          o: 1.08398,
          h: 1.084,
          l: 1.08379,
          c: 1.08385,
          v: 129
        },
        {
          t: 39300,
          o: 1.08385,
          h: 1.08393,
          l: 1.08375,
          c: 1.08385,
          v: 114
        },
        {
          t: 39600,
          o: 1.08385,
          h: 1.08417,
          l: 1.08383,
          c: 1.08416,
          v: 125
        },
        {
          t: 39900,
          o: 1.08417,
          h: 1.08438,
          l: 1.08407,
          c: 1.08431,
          v: 117
        },
        {
          t: 40200,
          o: 1.0843,
          h: 1.08435,
          l: 1.08402,
          c: 1.08423,
          v: 169
        },
        {
          t: 40500,
          o: 1.08424,
          h: 1.08442,
          l: 1.08415,
          c: 1.0843,
          v: 150
        },
        {
          t: 40800,
          o: 1.08428,
          h: 1.08429,
          l: 1.08401,
          c: 1.08401,
          v: 91
        },
        {
          t: 41100,
          o: 1.084,
          h: 1.08413,
          l: 1.084,
          c: 1.0841,
          v: 106
        },
        {
          t: 41400,
          o: 1.08411,
          h: 1.08412,
          l: 1.08383,
          c: 1.0839,
          v: 127
        },
        {
          t: 41700,
          o: 1.0839,
          h: 1.08411,
          l: 1.0839,
          c: 1.08402,
          v: 107
        },
        {
          t: 42000,
          o: 1.08403,
          h: 1.08403,
          l: 1.08376,
          c: 1.08396,
          v: 104
        },
        {
          t: 42300,
          o: 1.08396,
          h: 1.08406,
          l: 1.08386,
          c: 1.08401,
          v: 110
        },
        {
          t: 42600,
          o: 1.08401,
          h: 1.08405,
          l: 1.08386,
          c: 1.08386,
          v: 128
        },
        {
          t: 42900,
          o: 1.08386,
          h: 1.08389,
          l: 1.08366,
          c: 1.08366,
          v: 164
        },
        {
          t: 43200,
          o: 1.08367,
          h: 1.08367,
          l: 1.08344,
          c: 1.08347,
          v: 116
        },
        {
          t: 43500,
          o: 1.08347,
          h: 1.08364,
          l: 1.08341,
          c: 1.0835,
          v: 129
        },
        {
          t: 43800,
          o: 1.08349,
          h: 1.08352,
          l: 1.0832,
          c: 1.08343,
          v: 214
        },
        {
          t: 44100,
          o: 1.08343,
          h: 1.08359,
          l: 1.08329,
          c: 1.08357,
          v: 179
        },
        {
          t: 44400,
          o: 1.08355,
          h: 1.08365,
          l: 1.08347,
          c: 1.08365,
          v: 159
        },
        {
          t: 44700,
          o: 1.08365,
          h: 1.08388,
          l: 1.08364,
          c: 1.08388,
          v: 122
        },
        {
          t: 45000,
          o: 1.08386,
          h: 1.08401,
          l: 1.08381,
          c: 1.08387,
          v: 112
        },
        {
          t: 45300,
          o: 1.0839,
          h: 1.08438,
          l: 1.0839,
          c: 1.08438,
          v: 182
        },
        {
          t: 45600,
          o: 1.08437,
          h: 1.08441,
          l: 1.08416,
          c: 1.08423,
          v: 146
        },
        {
          t: 45900,
          o: 1.08422,
          h: 1.08446,
          l: 1.08416,
          c: 1.08446,
          v: 157
        },
        {
          t: 46200,
          o: 1.08445,
          h: 1.08447,
          l: 1.08404,
          c: 1.08413,
          v: 209
        },
        {
          t: 46500,
          o: 1.0841,
          h: 1.08413,
          l: 1.08388,
          c: 1.08395,
          v: 194
        },
        {
          t: 46800,
          o: 1.08393,
          h: 1.08429,
          l: 1.08388,
          c: 1.08412,
          v: 186
        },
        {
          t: 47100,
          o: 1.08412,
          h: 1.08421,
          l: 1.08402,
          c: 1.08404,
          v: 191
        },
        {
          t: 47400,
          o: 1.08405,
          h: 1.08419,
          l: 1.08405,
          c: 1.08414,
          v: 99
        },
        {
          t: 47700,
          o: 1.08413,
          h: 1.08419,
          l: 1.08369,
          c: 1.08374,
          v: 138
        },
        {
          t: 48000,
          o: 1.08371,
          h: 1.08377,
          l: 1.08354,
          c: 1.0836,
          v: 291
        },
        {
          t: 48300,
          o: 1.08362,
          h: 1.08383,
          l: 1.0836,
          c: 1.08367,
          v: 290
        },
        {
          t: 48600,
          o: 1.08367,
          h: 1.08466,
          l: 1.08367,
          c: 1.08457,
          v: 437
        },
        {
          t: 48900,
          o: 1.08453,
          h: 1.08466,
          l: 1.08434,
          c: 1.0846,
          v: 295
        },
        {
          t: 49200,
          o: 1.08459,
          h: 1.08539,
          l: 1.08456,
          c: 1.08531,
          v: 428
        },
        {
          t: 49500,
          o: 1.08531,
          h: 1.08551,
          l: 1.08489,
          c: 1.08514,
          v: 289
        },
        {
          t: 49800,
          o: 1.08513,
          h: 1.08551,
          l: 1.0851,
          c: 1.08542,
          v: 202
        },
        {
          t: 50100,
          o: 1.08542,
          h: 1.08591,
          l: 1.08538,
          c: 1.08546,
          v: 308
        },
        {
          t: 50400,
          o: 1.08546,
          h: 1.0855,
          l: 1.08519,
          c: 1.08546,
          v: 256
        },
        {
          t: 50700,
          o: 1.08547,
          h: 1.08547,
          l: 1.08508,
          c: 1.08537,
          v: 266
        },
        {
          t: 51000,
          o: 1.08537,
          h: 1.08586,
          l: 1.08526,
          c: 1.08575,
          v: 214
        },
        {
          t: 51300,
          o: 1.08574,
          h: 1.08576,
          l: 1.08546,
          c: 1.08556,
          v: 239
        },
        {
          t: 51600,
          o: 1.08555,
          h: 1.08565,
          l: 1.08542,
          c: 1.08543,
          v: 170
        },
        {
          t: 51900,
          o: 1.08542,
          h: 1.08555,
          l: 1.08531,
          c: 1.08554,
          v: 181
        },
        {
          t: 52200,
          o: 1.08553,
          h: 1.0858,
          l: 1.08545,
          c: 1.08547,
          v: 236
        },
        {
          t: 52500,
          o: 1.08546,
          h: 1.08604,
          l: 1.08546,
          c: 1.08586,
          v: 265
        },
        {
          t: 52800,
          o: 1.08588,
          h: 1.08608,
          l: 1.08575,
          c: 1.08596,
          v: 232
        },
        {
          t: 53100,
          o: 1.08597,
          h: 1.08604,
          l: 1.0858,
          c: 1.08587,
          v: 224
        },
        {
          t: 53400,
          o: 1.08586,
          h: 1.0859,
          l: 1.08553,
          c: 1.08581,
          v: 232
        },
        {
          t: 53700,
          o: 1.08583,
          h: 1.08585,
          l: 1.08559,
          c: 1.0856,
          v: 227
        },
        {
          t: 54000,
          o: 1.08562,
          h: 1.08569,
          l: 1.08529,
          c: 1.0855,
          v: 270
        },
        {
          t: 54300,
          o: 1.08551,
          h: 1.08555,
          l: 1.08462,
          c: 1.08475,
          v: 329
        },
        {
          t: 54600,
          o: 1.08474,
          h: 1.08509,
          l: 1.08474,
          c: 1.08485,
          v: 206
        },
        {
          t: 54900,
          o: 1.08484,
          h: 1.08486,
          l: 1.0846,
          c: 1.0847,
          v: 173
        },
        {
          t: 55200,
          o: 1.08471,
          h: 1.08498,
          l: 1.0847,
          c: 1.08491,
          v: 171
        },
        {
          t: 55500,
          o: 1.08492,
          h: 1.08512,
          l: 1.08481,
          c: 1.08511,
          v: 150
        },
        {
          t: 55800,
          o: 1.08513,
          h: 1.08533,
          l: 1.08503,
          c: 1.08511,
          v: 195
        },
        {
          t: 56100,
          o: 1.08513,
          h: 1.08537,
          l: 1.0851,
          c: 1.08533,
          v: 177
        },
        {
          t: 56400,
          o: 1.08528,
          h: 1.08586,
          l: 1.08528,
          c: 1.08586,
          v: 239
        },
        {
          t: 56700,
          o: 1.08587,
          h: 1.08587,
          l: 1.0854,
          c: 1.08552,
          v: 211
        },
        {
          t: 57000,
          o: 1.08549,
          h: 1.0855,
          l: 1.08518,
          c: 1.08521,
          v: 144
        },
        {
          t: 57300,
          o: 1.08519,
          h: 1.08519,
          l: 1.08433,
          c: 1.08453,
          v: 318
        },
        {
          t: 57600,
          o: 1.08455,
          h: 1.08463,
          l: 1.08414,
          c: 1.08433,
          v: 298
        },
        {
          t: 57900,
          o: 1.08437,
          h: 1.08444,
          l: 1.08389,
          c: 1.08417,
          v: 222
        },
        {
          t: 58200,
          o: 1.08416,
          h: 1.08432,
          l: 1.08412,
          c: 1.0842,
          v: 175
        },
        {
          t: 58500,
          o: 1.08417,
          h: 1.08431,
          l: 1.08395,
          c: 1.08396,
          v: 192
        },
        {
          t: 58800,
          o: 1.08397,
          h: 1.08422,
          l: 1.0838,
          c: 1.08422,
          v: 153
        },
        {
          t: 59100,
          o: 1.08422,
          h: 1.08428,
          l: 1.08404,
          c: 1.0841,
          v: 196
        },
        {
          t: 59400,
          o: 1.0841,
          h: 1.08423,
          l: 1.08398,
          c: 1.08405,
          v: 142
        },
        {
          t: 59700,
          o: 1.08406,
          h: 1.08417,
          l: 1.08405,
          c: 1.0841,
          v: 148
        },
        {
          t: 60000,
          o: 1.08411,
          h: 1.08429,
          l: 1.08407,
          c: 1.08423,
          v: 182
        },
        {
          t: 60300,
          o: 1.08424,
          h: 1.08432,
          l: 1.08408,
          c: 1.08411,
          v: 125
        },
        {
          t: 60600,
          o: 1.08411,
          h: 1.08413,
          l: 1.08383,
          c: 1.08395,
          v: 132
        },
        {
          t: 60900,
          o: 1.08397,
          h: 1.08405,
          l: 1.08365,
          c: 1.08388,
          v: 188
        },
        {
          t: 61200,
          o: 1.08387,
          h: 1.0839,
          l: 1.08371,
          c: 1.08373,
          v: 108
        },
        {
          t: 61500,
          o: 1.08374,
          h: 1.0838,
          l: 1.08369,
          c: 1.0837,
          v: 112
        },
        {
          t: 61800,
          o: 1.0837,
          h: 1.0837,
          l: 1.08348,
          c: 1.08348,
          v: 157
        },
        {
          t: 62100,
          o: 1.08349,
          h: 1.08355,
          l: 1.08324,
          c: 1.08331,
          v: 145
        },
        {
          t: 62400,
          o: 1.0833,
          h: 1.08367,
          l: 1.08324,
          c: 1.08362,
          v: 166
        },
        {
          t: 62700,
          o: 1.08362,
          h: 1.08367,
          l: 1.08354,
          c: 1.08355,
          v: 103
        },
        {
          t: 63000,
          o: 1.08356,
          h: 1.08369,
          l: 1.08353,
          c: 1.08365,
          v: 116
        },
        {
          t: 63300,
          o: 1.08366,
          h: 1.0837,
          l: 1.08357,
          c: 1.08359,
          v: 81
        },
        {
          t: 63600,
          o: 1.0836,
          h: 1.08383,
          l: 1.08358,
          c: 1.08381,
          v: 110
        },
        {
          t: 63900,
          o: 1.08382,
          h: 1.0839,
          l: 1.0838,
          c: 1.0839,
          v: 91
        },
        {
          t: 64200,
          o: 1.08391,
          h: 1.08408,
          l: 1.08391,
          c: 1.08407,
          v: 76
        },
        {
          t: 64500,
          o: 1.08406,
          h: 1.08432,
          l: 1.08402,
          c: 1.0843,
          v: 129
        },
        {
          t: 64800,
          o: 1.08429,
          h: 1.08435,
          l: 1.0842,
          c: 1.08426,
          v: 124
        },
        {
          t: 65100,
          o: 1.08429,
          h: 1.08449,
          l: 1.08425,
          c: 1.08446,
          v: 87
        },
        {
          t: 65400,
          o: 1.08444,
          h: 1.08456,
          l: 1.08443,
          c: 1.0845,
          v: 121
        },
        {
          t: 65700,
          o: 1.0845,
          h: 1.0845,
          l: 1.08444,
          c: 1.0845,
          v: 63
        },
        {
          t: 66000,
          o: 1.08449,
          h: 1.08458,
          l: 1.08446,
          c: 1.08454,
          v: 103
        },
        {
          t: 66300,
          o: 1.08455,
          h: 1.08457,
          l: 1.08418,
          c: 1.08419,
          v: 117
        },
        {
          t: 66600,
          o: 1.08425,
          h: 1.08436,
          l: 1.08414,
          c: 1.08436,
          v: 58
        },
        {
          t: 66900,
          o: 1.08434,
          h: 1.0844,
          l: 1.0843,
          c: 1.08436,
          v: 59
        },
        {
          t: 67200,
          o: 1.08435,
          h: 1.08437,
          l: 1.08433,
          c: 1.08437,
          v: 30
        },
        {
          t: 67500,
          o: 1.08436,
          h: 1.08437,
          l: 1.08421,
          c: 1.08423,
          v: 45
        },
        {
          t: 67800,
          o: 1.08424,
          h: 1.08432,
          l: 1.08412,
          c: 1.08413,
          v: 103
        },
        {
          t: 68100,
          o: 1.08413,
          h: 1.08436,
          l: 1.08413,
          c: 1.08432,
          v: 137
        },
        {
          t: 68400,
          o: 1.08431,
          h: 1.08451,
          l: 1.08429,
          c: 1.08445,
          v: 86
        },
        {
          t: 68700,
          o: 1.08445,
          h: 1.0845,
          l: 1.08437,
          c: 1.08445,
          v: 67
        },
        {
          t: 69000,
          o: 1.08445,
          h: 1.0845,
          l: 1.08444,
          c: 1.08447,
          v: 70
        },
        {
          t: 69300,
          o: 1.08447,
          h: 1.08452,
          l: 1.08429,
          c: 1.08431,
          v: 100
        },
        {
          t: 69600,
          o: 1.08432,
          h: 1.08432,
          l: 1.08419,
          c: 1.08425,
          v: 59
        },
        {
          t: 69900,
          o: 1.08424,
          h: 1.08426,
          l: 1.08415,
          c: 1.08418,
          v: 100
        },
        {
          t: 70200,
          o: 1.08419,
          h: 1.08419,
          l: 1.08402,
          c: 1.08405,
          v: 77
        },
        {
          t: 70500,
          o: 1.08405,
          h: 1.08411,
          l: 1.084,
          c: 1.084,
          v: 57
        },
        {
          t: 70800,
          o: 1.084,
          h: 1.08401,
          l: 1.08395,
          c: 1.08395,
          v: 27
        },
        {
          t: 71100,
          o: 1.08396,
          h: 1.084,
          l: 1.0839,
          c: 1.08391,
          v: 53
        },
        {
          t: 71400,
          o: 1.0839,
          h: 1.08397,
          l: 1.08383,
          c: 1.08386,
          v: 92
        },
        {
          t: 71700,
          o: 1.08386,
          h: 1.08394,
          l: 1.08384,
          c: 1.08391,
          v: 92
        },
        {
          t: 72000,
          o: 1.0839,
          h: 1.08391,
          l: 1.08379,
          c: 1.08381,
          v: 82
        },
        {
          t: 72300,
          o: 1.08383,
          h: 1.08388,
          l: 1.0838,
          c: 1.08384,
          v: 46
        },
        {
          t: 72600,
          o: 1.08384,
          h: 1.08404,
          l: 1.08379,
          c: 1.08395,
          v: 85
        },
        {
          t: 72900,
          o: 1.08396,
          h: 1.08408,
          l: 1.08389,
          c: 1.08389,
          v: 94
        },
        {
          t: 73200,
          o: 1.0839,
          h: 1.0839,
          l: 1.08374,
          c: 1.08377,
          v: 82
        },
        {
          t: 73500,
          o: 1.08376,
          h: 1.08383,
          l: 1.0837,
          c: 1.08382,
          v: 59
        },
        {
          t: 73800,
          o: 1.08381,
          h: 1.08391,
          l: 1.08366,
          c: 1.08367,
          v: 72
        },
        {
          t: 74100,
          o: 1.08367,
          h: 1.08383,
          l: 1.08367,
          c: 1.08375,
          v: 35
        },
        {
          t: 74400,
          o: 1.08375,
          h: 1.08383,
          l: 1.08375,
          c: 1.08381,
          v: 42
        },
        {
          t: 74700,
          o: 1.08382,
          h: 1.08382,
          l: 1.08366,
          c: 1.08366,
          v: 69
        },
        {
          t: 75000,
          o: 1.08367,
          h: 1.08374,
          l: 1.08359,
          c: 1.08374,
          v: 164
        },
        {
          t: 75300,
          o: 1.08373,
          h: 1.08374,
          l: 1.08359,
          c: 1.08368,
          v: 132
        },
        {
          t: 75600,
          o: 1.08367,
          h: 1.08377,
          l: 1.08359,
          c: 1.08371,
          v: 92
        },
        {
          t: 75900,
          o: 1.08372,
          h: 1.08385,
          l: 1.08372,
          c: 1.08377,
          v: 59
        },
        {
          t: 76200,
          o: 1.08378,
          h: 1.08379,
          l: 1.08359,
          c: 1.08359,
          v: 84
        },
        {
          t: 76500,
          o: 1.08356,
          h: 1.08359,
          l: 1.08351,
          c: 1.08356,
          v: 70
        },
        {
          t: 76800,
          o: 1.08357,
          h: 1.08357,
          l: 1.08331,
          c: 1.0835,
          v: 62
        },
        {
          t: 77100,
          o: 1.08346,
          h: 1.08346,
          l: 1.08328,
          c: 1.08337,
          v: 88
        },
        {
          t: 77400,
          o: 1.08336,
          h: 1.08349,
          l: 1.0833,
          c: 1.08342,
          v: 83
        },
        {
          t: 77700,
          o: 1.08341,
          h: 1.08345,
          l: 1.08328,
          c: 1.08329,
          v: 67
        },
        {
          t: 78000,
          o: 1.08331,
          h: 1.08339,
          l: 1.0832,
          c: 1.08325,
          v: 156
        }
      ]
    },
    {
      d: 1581811200,
      b: [
        {
          t: 79500,
          o: 1.08298,
          h: 1.08408,
          l: 1.08298,
          c: 1.08387,
          v: 273
        },
        {
          t: 79800,
          o: 1.08387,
          h: 1.08391,
          l: 1.08351,
          c: 1.08362,
          v: 162
        },
        {
          t: 80100,
          o: 1.08359,
          h: 1.08359,
          l: 1.08357,
          c: 1.08359,
          v: 211
        },
        {
          t: 80400,
          o: 1.0836,
          h: 1.08369,
          l: 1.08338,
          c: 1.08369,
          v: 35
        },
        {
          t: 80700,
          o: 1.08371,
          h: 1.08387,
          l: 1.08346,
          c: 1.08367,
          v: 156
        },
        {
          t: 81000,
          o: 1.08365,
          h: 1.08387,
          l: 1.08347,
          c: 1.08373,
          v: 176
        },
        {
          t: 81300,
          o: 1.08373,
          h: 1.08381,
          l: 1.08373,
          c: 1.08373,
          v: 159
        },
        {
          t: 81600,
          o: 1.08373,
          h: 1.08383,
          l: 1.08373,
          c: 1.08382,
          v: 176
        },
        {
          t: 81900,
          o: 1.08381,
          h: 1.08384,
          l: 1.08373,
          c: 1.08376,
          v: 111
        },
        {
          t: 82200,
          o: 1.08375,
          h: 1.08385,
          l: 1.08373,
          c: 1.08378,
          v: 59
        },
        {
          t: 82500,
          o: 1.08378,
          h: 1.08389,
          l: 1.08373,
          c: 1.08373,
          v: 56
        },
        {
          t: 82800,
          o: 1.08382,
          h: 1.08382,
          l: 1.08352,
          c: 1.08375,
          v: 183
        },
        {
          t: 83100,
          o: 1.08375,
          h: 1.08383,
          l: 1.08374,
          c: 1.0838,
          v: 54
        },
        {
          t: 83400,
          o: 1.08379,
          h: 1.08396,
          l: 1.08379,
          c: 1.08388,
          v: 48
        },
        {
          t: 83700,
          o: 1.08388,
          h: 1.0839,
          l: 1.08381,
          c: 1.08382,
          v: 50
        },
        {
          t: 84000,
          o: 1.08382,
          h: 1.08393,
          l: 1.08382,
          c: 1.0839,
          v: 46
        },
        {
          t: 84300,
          o: 1.0839,
          h: 1.08391,
          l: 1.08382,
          c: 1.08385,
          v: 63
        },
        {
          t: 84600,
          o: 1.08384,
          h: 1.08401,
          l: 1.08384,
          c: 1.08398,
          v: 51
        },
        {
          t: 84900,
          o: 1.08397,
          h: 1.08402,
          l: 1.08392,
          c: 1.08402,
          v: 64
        },
        {
          t: 85200,
          o: 1.08402,
          h: 1.08408,
          l: 1.084,
          c: 1.08403,
          v: 73
        },
        {
          t: 85500,
          o: 1.08403,
          h: 1.08404,
          l: 1.08397,
          c: 1.08398,
          v: 57
        },
        {
          t: 85800,
          o: 1.084,
          h: 1.0841,
          l: 1.08397,
          c: 1.08406,
          v: 64
        },
        {
          t: 86100,
          o: 1.08407,
          h: 1.08412,
          l: 1.08405,
          c: 1.08407,
          v: 64
        }
      ]
    },
    {
      d: 1581897600,
      b: [
        {
          t: 0,
          o: 1.08406,
          h: 1.08407,
          l: 1.08359,
          c: 1.08373,
          v: 134
        },
        {
          t: 300,
          o: 1.08373,
          h: 1.08384,
          l: 1.08357,
          c: 1.08359,
          v: 90
        },
        {
          t: 600,
          o: 1.08359,
          h: 1.0838,
          l: 1.08359,
          c: 1.08372,
          v: 77
        },
        {
          t: 900,
          o: 1.08371,
          h: 1.08372,
          l: 1.08354,
          c: 1.08362,
          v: 125
        },
        {
          t: 1200,
          o: 1.08361,
          h: 1.08362,
          l: 1.0835,
          c: 1.08351,
          v: 65
        },
        {
          t: 1500,
          o: 1.0835,
          h: 1.0837,
          l: 1.08347,
          c: 1.08368,
          v: 107
        },
        {
          t: 1800,
          o: 1.08367,
          h: 1.08372,
          l: 1.08355,
          c: 1.0836,
          v: 89
        },
        {
          t: 2100,
          o: 1.08361,
          h: 1.08362,
          l: 1.08357,
          c: 1.08357,
          v: 32
        },
        {
          t: 2400,
          o: 1.08357,
          h: 1.08358,
          l: 1.0835,
          c: 1.08351,
          v: 42
        },
        {
          t: 2700,
          o: 1.08351,
          h: 1.08357,
          l: 1.08347,
          c: 1.08357,
          v: 76
        },
        {
          t: 3000,
          o: 1.08358,
          h: 1.08362,
          l: 1.08348,
          c: 1.08352,
          v: 82
        },
        {
          t: 3300,
          o: 1.08354,
          h: 1.08356,
          l: 1.08348,
          c: 1.08354,
          v: 77
        },
        {
          t: 3600,
          o: 1.08354,
          h: 1.08358,
          l: 1.08342,
          c: 1.08344,
          v: 72
        },
        {
          t: 3900,
          o: 1.08344,
          h: 1.08364,
          l: 1.08342,
          c: 1.08354,
          v: 93
        },
        {
          t: 4200,
          o: 1.08355,
          h: 1.08364,
          l: 1.08348,
          c: 1.08358,
          v: 75
        },
        {
          t: 4500,
          o: 1.08359,
          h: 1.08361,
          l: 1.08337,
          c: 1.08338,
          v: 60
        },
        {
          t: 4800,
          o: 1.08339,
          h: 1.08339,
          l: 1.08331,
          c: 1.08331,
          v: 65
        },
        {
          t: 5100,
          o: 1.08332,
          h: 1.08349,
          l: 1.08331,
          c: 1.08347,
          v: 68
        },
        {
          t: 5400,
          o: 1.08348,
          h: 1.08363,
          l: 1.08348,
          c: 1.08358,
          v: 66
        },
        {
          t: 5700,
          o: 1.08359,
          h: 1.08363,
          l: 1.08357,
          c: 1.08363,
          v: 60
        },
        {
          t: 6000,
          o: 1.08363,
          h: 1.0837,
          l: 1.08358,
          c: 1.08368,
          v: 84
        },
        {
          t: 6300,
          o: 1.08367,
          h: 1.08373,
          l: 1.08367,
          c: 1.08369,
          v: 73
        },
        {
          t: 6600,
          o: 1.0837,
          h: 1.08375,
          l: 1.0837,
          c: 1.08372,
          v: 45
        },
        {
          t: 6900,
          o: 1.08369,
          h: 1.08373,
          l: 1.08362,
          c: 1.08362,
          v: 54
        },
        {
          t: 7200,
          o: 1.08361,
          h: 1.08364,
          l: 1.08356,
          c: 1.08362,
          v: 64
        },
        {
          t: 7500,
          o: 1.08364,
          h: 1.08378,
          l: 1.08362,
          c: 1.08378,
          v: 56
        },
        {
          t: 7800,
          o: 1.0838,
          h: 1.08384,
          l: 1.08377,
          c: 1.08384,
          v: 43
        },
        {
          t: 8100,
          o: 1.08383,
          h: 1.08383,
          l: 1.08377,
          c: 1.0838,
          v: 73
        },
        {
          t: 8400,
          o: 1.08379,
          h: 1.08382,
          l: 1.08376,
          c: 1.08377,
          v: 41
        },
        {
          t: 8700,
          o: 1.08374,
          h: 1.08392,
          l: 1.08374,
          c: 1.08389,
          v: 32
        },
        {
          t: 9000,
          o: 1.0839,
          h: 1.08397,
          l: 1.08388,
          c: 1.08393,
          v: 56
        },
        {
          t: 9300,
          o: 1.08393,
          h: 1.08393,
          l: 1.08385,
          c: 1.08387,
          v: 21
        },
        {
          t: 9600,
          o: 1.08388,
          h: 1.08393,
          l: 1.08382,
          c: 1.08393,
          v: 63
        },
        {
          t: 9900,
          o: 1.08392,
          h: 1.08393,
          l: 1.08387,
          c: 1.08389,
          v: 28
        },
        {
          t: 10200,
          o: 1.08389,
          h: 1.08398,
          l: 1.08389,
          c: 1.08392,
          v: 29
        },
        {
          t: 10500,
          o: 1.08389,
          h: 1.08393,
          l: 1.08385,
          c: 1.08388,
          v: 38
        },
        {
          t: 10800,
          o: 1.08387,
          h: 1.08388,
          l: 1.08377,
          c: 1.08377,
          v: 59
        },
        {
          t: 11100,
          o: 1.08378,
          h: 1.08384,
          l: 1.08377,
          c: 1.08379,
          v: 56
        },
        {
          t: 11400,
          o: 1.0838,
          h: 1.08383,
          l: 1.08378,
          c: 1.08378,
          v: 31
        },
        {
          t: 11700,
          o: 1.08377,
          h: 1.08388,
          l: 1.08376,
          c: 1.08383,
          v: 56
        },
        {
          t: 12000,
          o: 1.08381,
          h: 1.08385,
          l: 1.08377,
          c: 1.08377,
          v: 44
        },
        {
          t: 12300,
          o: 1.08377,
          h: 1.08382,
          l: 1.08369,
          c: 1.08376,
          v: 54
        },
        {
          t: 12600,
          o: 1.08375,
          h: 1.08382,
          l: 1.08372,
          c: 1.08381,
          v: 66
        },
        {
          t: 12900,
          o: 1.08382,
          h: 1.08382,
          l: 1.08371,
          c: 1.08375,
          v: 64
        },
        {
          t: 13200,
          o: 1.08376,
          h: 1.08379,
          l: 1.08374,
          c: 1.08379,
          v: 43
        },
        {
          t: 13500,
          o: 1.08378,
          h: 1.08383,
          l: 1.08375,
          c: 1.08375,
          v: 57
        },
        {
          t: 13800,
          o: 1.08376,
          h: 1.08381,
          l: 1.08375,
          c: 1.08376,
          v: 35
        },
        {
          t: 14100,
          o: 1.08375,
          h: 1.08381,
          l: 1.08371,
          c: 1.08381,
          v: 50
        },
        {
          t: 14400,
          o: 1.08382,
          h: 1.08382,
          l: 1.08377,
          c: 1.08382,
          v: 50
        },
        {
          t: 14700,
          o: 1.08381,
          h: 1.08403,
          l: 1.08381,
          c: 1.08397,
          v: 42
        },
        {
          t: 15000,
          o: 1.08393,
          h: 1.08399,
          l: 1.08389,
          c: 1.08397,
          v: 55
        },
        {
          t: 15300,
          o: 1.08397,
          h: 1.08397,
          l: 1.08388,
          c: 1.08392,
          v: 30
        },
        {
          t: 15600,
          o: 1.08392,
          h: 1.08392,
          l: 1.08372,
          c: 1.08379,
          v: 64
        },
        {
          t: 15900,
          o: 1.08381,
          h: 1.0839,
          l: 1.08381,
          c: 1.08382,
          v: 45
        },
        {
          t: 16200,
          o: 1.08382,
          h: 1.08392,
          l: 1.08377,
          c: 1.08388,
          v: 44
        },
        {
          t: 16500,
          o: 1.08388,
          h: 1.08395,
          l: 1.08381,
          c: 1.08385,
          v: 44
        },
        {
          t: 16800,
          o: 1.08383,
          h: 1.08389,
          l: 1.08379,
          c: 1.08388,
          v: 28
        },
        {
          t: 17100,
          o: 1.08387,
          h: 1.08392,
          l: 1.08386,
          c: 1.08391,
          v: 31
        },
        {
          t: 17400,
          o: 1.08387,
          h: 1.08391,
          l: 1.08381,
          c: 1.08381,
          v: 21
        },
        {
          t: 17700,
          o: 1.08382,
          h: 1.08383,
          l: 1.08371,
          c: 1.08372,
          v: 73
        },
        {
          t: 18000,
          o: 1.08373,
          h: 1.08381,
          l: 1.08372,
          c: 1.08377,
          v: 44
        },
        {
          t: 18300,
          o: 1.08378,
          h: 1.08381,
          l: 1.08375,
          c: 1.08377,
          v: 35
        },
        {
          t: 18600,
          o: 1.08378,
          h: 1.0839,
          l: 1.08378,
          c: 1.08388,
          v: 45
        },
        {
          t: 18900,
          o: 1.08388,
          h: 1.08393,
          l: 1.08386,
          c: 1.08387,
          v: 35
        },
        {
          t: 19200,
          o: 1.08387,
          h: 1.08392,
          l: 1.08384,
          c: 1.0839,
          v: 29
        },
        {
          t: 19500,
          o: 1.08389,
          h: 1.08389,
          l: 1.0838,
          c: 1.08384,
          v: 25
        },
        {
          t: 19800,
          o: 1.08385,
          h: 1.08385,
          l: 1.08379,
          c: 1.08381,
          v: 37
        },
        {
          t: 20100,
          o: 1.0838,
          h: 1.08393,
          l: 1.08379,
          c: 1.08392,
          v: 39
        },
        {
          t: 20400,
          o: 1.08392,
          h: 1.08392,
          l: 1.08386,
          c: 1.08388,
          v: 41
        },
        {
          t: 20700,
          o: 1.08387,
          h: 1.08392,
          l: 1.08382,
          c: 1.08391,
          v: 30
        },
        {
          t: 21000,
          o: 1.0839,
          h: 1.08404,
          l: 1.0839,
          c: 1.084,
          v: 46
        },
        {
          t: 21300,
          o: 1.08403,
          h: 1.08411,
          l: 1.0839,
          c: 1.08391,
          v: 88
        },
        {
          t: 21600,
          o: 1.08392,
          h: 1.084,
          l: 1.08386,
          c: 1.08394,
          v: 53
        },
        {
          t: 21900,
          o: 1.08394,
          h: 1.08398,
          l: 1.08382,
          c: 1.08382,
          v: 64
        },
        {
          t: 22200,
          o: 1.08382,
          h: 1.08382,
          l: 1.08373,
          c: 1.08377,
          v: 98
        },
        {
          t: 22500,
          o: 1.08375,
          h: 1.08377,
          l: 1.08371,
          c: 1.08375,
          v: 96
        },
        {
          t: 22800,
          o: 1.08374,
          h: 1.08379,
          l: 1.08373,
          c: 1.08376,
          v: 47
        },
        {
          t: 23100,
          o: 1.08376,
          h: 1.08384,
          l: 1.08373,
          c: 1.08383,
          v: 106
        },
        {
          t: 23400,
          o: 1.08382,
          h: 1.08384,
          l: 1.08371,
          c: 1.08377,
          v: 65
        },
        {
          t: 23700,
          o: 1.08376,
          h: 1.08376,
          l: 1.08364,
          c: 1.08365,
          v: 83
        },
        {
          t: 24000,
          o: 1.08365,
          h: 1.08367,
          l: 1.08355,
          c: 1.0836,
          v: 79
        },
        {
          t: 24300,
          o: 1.0836,
          h: 1.08361,
          l: 1.08345,
          c: 1.0835,
          v: 64
        },
        {
          t: 24600,
          o: 1.08347,
          h: 1.0835,
          l: 1.08335,
          c: 1.08344,
          v: 56
        },
        {
          t: 24900,
          o: 1.08348,
          h: 1.08348,
          l: 1.08341,
          c: 1.08346,
          v: 36
        },
        {
          t: 25200,
          o: 1.08347,
          h: 1.08362,
          l: 1.08347,
          c: 1.08361,
          v: 94
        },
        {
          t: 25500,
          o: 1.08362,
          h: 1.08373,
          l: 1.08361,
          c: 1.08361,
          v: 91
        },
        {
          t: 25800,
          o: 1.08362,
          h: 1.08368,
          l: 1.08353,
          c: 1.08363,
          v: 107
        },
        {
          t: 26100,
          o: 1.08363,
          h: 1.08387,
          l: 1.08361,
          c: 1.0838,
          v: 97
        },
        {
          t: 26400,
          o: 1.0838,
          h: 1.08385,
          l: 1.08376,
          c: 1.08379,
          v: 72
        },
        {
          t: 26700,
          o: 1.0838,
          h: 1.08392,
          l: 1.08376,
          c: 1.08386,
          v: 90
        },
        {
          t: 27000,
          o: 1.08384,
          h: 1.08384,
          l: 1.08374,
          c: 1.08378,
          v: 77
        },
        {
          t: 27300,
          o: 1.08378,
          h: 1.08379,
          l: 1.08374,
          c: 1.08377,
          v: 82
        },
        {
          t: 27600,
          o: 1.08376,
          h: 1.08387,
          l: 1.0837,
          c: 1.0837,
          v: 127
        },
        {
          t: 27900,
          o: 1.08371,
          h: 1.08384,
          l: 1.0837,
          c: 1.08376,
          v: 123
        },
        {
          t: 28200,
          o: 1.08378,
          h: 1.08403,
          l: 1.08377,
          c: 1.08398,
          v: 117
        },
        {
          t: 28500,
          o: 1.084,
          h: 1.08418,
          l: 1.08388,
          c: 1.08402,
          v: 173
        },
        {
          t: 28800,
          o: 1.08406,
          h: 1.08407,
          l: 1.08378,
          c: 1.08399,
          v: 200
        },
        {
          t: 29100,
          o: 1.08399,
          h: 1.08411,
          l: 1.08391,
          c: 1.0841,
          v: 118
        },
        {
          t: 29400,
          o: 1.08411,
          h: 1.08416,
          l: 1.08391,
          c: 1.08394,
          v: 108
        },
        {
          t: 29700,
          o: 1.08393,
          h: 1.0842,
          l: 1.08378,
          c: 1.08408,
          v: 142
        },
        {
          t: 30000,
          o: 1.08409,
          h: 1.08409,
          l: 1.08377,
          c: 1.08383,
          v: 138
        },
        {
          t: 30300,
          o: 1.08382,
          h: 1.08396,
          l: 1.08377,
          c: 1.08393,
          v: 95
        },
        {
          t: 30600,
          o: 1.08393,
          h: 1.08397,
          l: 1.0838,
          c: 1.08381,
          v: 115
        },
        {
          t: 30900,
          o: 1.08383,
          h: 1.08394,
          l: 1.08368,
          c: 1.0837,
          v: 133
        },
        {
          t: 31200,
          o: 1.08369,
          h: 1.08395,
          l: 1.08369,
          c: 1.08387,
          v: 142
        },
        {
          t: 31500,
          o: 1.08388,
          h: 1.08431,
          l: 1.08381,
          c: 1.08426,
          v: 155
        },
        {
          t: 31800,
          o: 1.08424,
          h: 1.08439,
          l: 1.08418,
          c: 1.08437,
          v: 139
        },
        {
          t: 32100,
          o: 1.0844,
          h: 1.08458,
          l: 1.08439,
          c: 1.08458,
          v: 153
        },
        {
          t: 32400,
          o: 1.08455,
          h: 1.0847,
          l: 1.08448,
          c: 1.08466,
          v: 168
        },
        {
          t: 32700,
          o: 1.08468,
          h: 1.08486,
          l: 1.08464,
          c: 1.08481,
          v: 172
        },
        {
          t: 33000,
          o: 1.08483,
          h: 1.08505,
          l: 1.08475,
          c: 1.08492,
          v: 193
        },
        {
          t: 33300,
          o: 1.0849,
          h: 1.08493,
          l: 1.08461,
          c: 1.08466,
          v: 87
        },
        {
          t: 33600,
          o: 1.08469,
          h: 1.08472,
          l: 1.08444,
          c: 1.0845,
          v: 121
        },
        {
          t: 33900,
          o: 1.0845,
          h: 1.08456,
          l: 1.08435,
          c: 1.08443,
          v: 121
        },
        {
          t: 34200,
          o: 1.08439,
          h: 1.08462,
          l: 1.08433,
          c: 1.0845,
          v: 120
        },
        {
          t: 34500,
          o: 1.08451,
          h: 1.08457,
          l: 1.08442,
          c: 1.08455,
          v: 108
        },
        {
          t: 34800,
          o: 1.08457,
          h: 1.08458,
          l: 1.08423,
          c: 1.08435,
          v: 155
        },
        {
          t: 35100,
          o: 1.08432,
          h: 1.08462,
          l: 1.0842,
          c: 1.08452,
          v: 183
        },
        {
          t: 35400,
          o: 1.08452,
          h: 1.08455,
          l: 1.08441,
          c: 1.08442,
          v: 125
        },
        {
          t: 35700,
          o: 1.08442,
          h: 1.08447,
          l: 1.08426,
          c: 1.08436,
          v: 98
        },
        {
          t: 36000,
          o: 1.08437,
          h: 1.08437,
          l: 1.08421,
          c: 1.08432,
          v: 121
        },
        {
          t: 36300,
          o: 1.08433,
          h: 1.08453,
          l: 1.08432,
          c: 1.08438,
          v: 86
        },
        {
          t: 36600,
          o: 1.08435,
          h: 1.08456,
          l: 1.08435,
          c: 1.08448,
          v: 179
        },
        {
          t: 36900,
          o: 1.08449,
          h: 1.08463,
          l: 1.08442,
          c: 1.08444,
          v: 140
        },
        {
          t: 37200,
          o: 1.08446,
          h: 1.08467,
          l: 1.08441,
          c: 1.08453,
          v: 103
        },
        {
          t: 37500,
          o: 1.08452,
          h: 1.08453,
          l: 1.08442,
          c: 1.0845,
          v: 92
        },
        {
          t: 37800,
          o: 1.08449,
          h: 1.08454,
          l: 1.08436,
          c: 1.08453,
          v: 84
        },
        {
          t: 38100,
          o: 1.08452,
          h: 1.08464,
          l: 1.0845,
          c: 1.08459,
          v: 132
        },
        {
          t: 38400,
          o: 1.08458,
          h: 1.08468,
          l: 1.08452,
          c: 1.08462,
          v: 87
        },
        {
          t: 38700,
          o: 1.08457,
          h: 1.08467,
          l: 1.08447,
          c: 1.08448,
          v: 107
        },
        {
          t: 39000,
          o: 1.08448,
          h: 1.0846,
          l: 1.08448,
          c: 1.08453,
          v: 33
        },
        {
          t: 39300,
          o: 1.08454,
          h: 1.08467,
          l: 1.08453,
          c: 1.08464,
          v: 74
        },
        {
          t: 39600,
          o: 1.08463,
          h: 1.08467,
          l: 1.08446,
          c: 1.08447,
          v: 62
        },
        {
          t: 39900,
          o: 1.08449,
          h: 1.08468,
          l: 1.08438,
          c: 1.08462,
          v: 144
        },
        {
          t: 40200,
          o: 1.08462,
          h: 1.08476,
          l: 1.08438,
          c: 1.08452,
          v: 160
        },
        {
          t: 40500,
          o: 1.08452,
          h: 1.08458,
          l: 1.08429,
          c: 1.08436,
          v: 135
        },
        {
          t: 40800,
          o: 1.08435,
          h: 1.08438,
          l: 1.08416,
          c: 1.08418,
          v: 100
        },
        {
          t: 41100,
          o: 1.08417,
          h: 1.08417,
          l: 1.08405,
          c: 1.08411,
          v: 104
        },
        {
          t: 41400,
          o: 1.0841,
          h: 1.0841,
          l: 1.08391,
          c: 1.08405,
          v: 104
        },
        {
          t: 41700,
          o: 1.08402,
          h: 1.08414,
          l: 1.08386,
          c: 1.08412,
          v: 130
        },
        {
          t: 42000,
          o: 1.08412,
          h: 1.08421,
          l: 1.08404,
          c: 1.08404,
          v: 92
        },
        {
          t: 42300,
          o: 1.08405,
          h: 1.08418,
          l: 1.08389,
          c: 1.08398,
          v: 98
        },
        {
          t: 42600,
          o: 1.08397,
          h: 1.08405,
          l: 1.08386,
          c: 1.08386,
          v: 97
        },
        {
          t: 42900,
          o: 1.08386,
          h: 1.08391,
          l: 1.08354,
          c: 1.08387,
          v: 178
        },
        {
          t: 43200,
          o: 1.08386,
          h: 1.08402,
          l: 1.08377,
          c: 1.08395,
          v: 118
        },
        {
          t: 43500,
          o: 1.08392,
          h: 1.08403,
          l: 1.08387,
          c: 1.08401,
          v: 151
        },
        {
          t: 43800,
          o: 1.084,
          h: 1.08408,
          l: 1.08398,
          c: 1.08407,
          v: 143
        },
        {
          t: 44100,
          o: 1.08408,
          h: 1.08408,
          l: 1.08381,
          c: 1.08387,
          v: 92
        },
        {
          t: 44400,
          o: 1.0839,
          h: 1.08413,
          l: 1.08387,
          c: 1.08408,
          v: 74
        },
        {
          t: 44700,
          o: 1.08408,
          h: 1.08408,
          l: 1.08365,
          c: 1.08382,
          v: 128
        },
        {
          t: 45000,
          o: 1.08381,
          h: 1.08392,
          l: 1.08376,
          c: 1.08382,
          v: 87
        },
        {
          t: 45300,
          o: 1.08383,
          h: 1.08392,
          l: 1.08371,
          c: 1.08372,
          v: 143
        },
        {
          t: 45600,
          o: 1.08371,
          h: 1.08377,
          l: 1.08362,
          c: 1.08371,
          v: 94
        },
        {
          t: 45900,
          o: 1.08372,
          h: 1.08374,
          l: 1.08355,
          c: 1.08367,
          v: 134
        },
        {
          t: 46200,
          o: 1.08368,
          h: 1.08373,
          l: 1.08356,
          c: 1.08356,
          v: 84
        },
        {
          t: 46500,
          o: 1.08351,
          h: 1.08378,
          l: 1.08348,
          c: 1.08359,
          v: 171
        },
        {
          t: 46800,
          o: 1.08359,
          h: 1.0837,
          l: 1.08338,
          c: 1.08339,
          v: 151
        },
        {
          t: 47100,
          o: 1.08339,
          h: 1.08359,
          l: 1.08327,
          c: 1.08329,
          v: 153
        },
        {
          t: 47400,
          o: 1.08331,
          h: 1.08348,
          l: 1.08317,
          c: 1.08348,
          v: 87
        },
        {
          t: 47700,
          o: 1.08346,
          h: 1.08366,
          l: 1.08343,
          c: 1.08364,
          v: 72
        },
        {
          t: 48000,
          o: 1.08362,
          h: 1.08373,
          l: 1.08362,
          c: 1.08373,
          v: 59
        },
        {
          t: 48300,
          o: 1.08373,
          h: 1.08384,
          l: 1.08373,
          c: 1.08382,
          v: 62
        },
        {
          t: 48600,
          o: 1.08379,
          h: 1.08381,
          l: 1.08366,
          c: 1.08373,
          v: 114
        },
        {
          t: 48900,
          o: 1.08373,
          h: 1.08389,
          l: 1.08373,
          c: 1.08387,
          v: 101
        },
        {
          t: 49200,
          o: 1.08386,
          h: 1.08403,
          l: 1.08381,
          c: 1.08402,
          v: 134
        },
        {
          t: 49500,
          o: 1.08403,
          h: 1.08403,
          l: 1.08383,
          c: 1.08385,
          v: 97
        },
        {
          t: 49800,
          o: 1.08386,
          h: 1.08387,
          l: 1.08368,
          c: 1.08375,
          v: 53
        },
        {
          t: 50100,
          o: 1.08374,
          h: 1.0838,
          l: 1.08356,
          c: 1.08356,
          v: 108
        },
        {
          t: 50400,
          o: 1.08355,
          h: 1.08371,
          l: 1.08345,
          c: 1.08356,
          v: 141
        },
        {
          t: 50700,
          o: 1.08358,
          h: 1.08367,
          l: 1.08358,
          c: 1.0836,
          v: 99
        },
        {
          t: 51000,
          o: 1.08357,
          h: 1.08388,
          l: 1.08355,
          c: 1.08366,
          v: 143
        },
        {
          t: 51300,
          o: 1.08367,
          h: 1.08368,
          l: 1.08355,
          c: 1.08362,
          v: 128
        },
        {
          t: 51600,
          o: 1.08361,
          h: 1.0839,
          l: 1.08357,
          c: 1.0836,
          v: 118
        },
        {
          t: 51900,
          o: 1.08362,
          h: 1.08382,
          l: 1.08355,
          c: 1.08382,
          v: 108
        },
        {
          t: 52200,
          o: 1.08383,
          h: 1.08384,
          l: 1.08365,
          c: 1.08369,
          v: 105
        },
        {
          t: 52500,
          o: 1.08368,
          h: 1.08379,
          l: 1.08366,
          c: 1.08376,
          v: 116
        },
        {
          t: 52800,
          o: 1.08378,
          h: 1.08385,
          l: 1.08372,
          c: 1.08385,
          v: 127
        },
        {
          t: 53100,
          o: 1.08385,
          h: 1.08402,
          l: 1.08378,
          c: 1.084,
          v: 103
        },
        {
          t: 53400,
          o: 1.084,
          h: 1.08408,
          l: 1.08385,
          c: 1.08387,
          v: 120
        },
        {
          t: 53700,
          o: 1.08387,
          h: 1.08393,
          l: 1.08379,
          c: 1.08386,
          v: 110
        },
        {
          t: 54000,
          o: 1.08383,
          h: 1.08394,
          l: 1.08382,
          c: 1.08386,
          v: 119
        },
        {
          t: 54300,
          o: 1.08386,
          h: 1.08387,
          l: 1.08359,
          c: 1.08361,
          v: 83
        },
        {
          t: 54600,
          o: 1.08362,
          h: 1.08372,
          l: 1.08351,
          c: 1.08351,
          v: 100
        },
        {
          t: 54900,
          o: 1.08348,
          h: 1.08357,
          l: 1.08344,
          c: 1.08356,
          v: 85
        },
        {
          t: 55200,
          o: 1.08356,
          h: 1.08356,
          l: 1.08331,
          c: 1.08343,
          v: 137
        },
        {
          t: 55500,
          o: 1.08341,
          h: 1.08345,
          l: 1.0834,
          c: 1.08345,
          v: 66
        },
        {
          t: 55800,
          o: 1.08344,
          h: 1.08367,
          l: 1.08344,
          c: 1.08358,
          v: 145
        },
        {
          t: 56100,
          o: 1.08357,
          h: 1.08366,
          l: 1.08353,
          c: 1.08364,
          v: 98
        },
        {
          t: 56400,
          o: 1.08364,
          h: 1.08369,
          l: 1.0835,
          c: 1.08357,
          v: 98
        },
        {
          t: 56700,
          o: 1.08357,
          h: 1.08358,
          l: 1.08311,
          c: 1.08311,
          v: 99
        },
        {
          t: 57000,
          o: 1.0831,
          h: 1.08331,
          l: 1.0831,
          c: 1.08326,
          v: 156
        },
        {
          t: 57300,
          o: 1.08323,
          h: 1.08327,
          l: 1.08289,
          c: 1.08292,
          v: 237
        },
        {
          t: 57600,
          o: 1.08292,
          h: 1.08307,
          l: 1.08287,
          c: 1.08297,
          v: 166
        },
        {
          t: 57900,
          o: 1.08299,
          h: 1.08314,
          l: 1.08296,
          c: 1.08313,
          v: 106
        },
        {
          t: 58200,
          o: 1.08313,
          h: 1.0833,
          l: 1.08312,
          c: 1.08323,
          v: 124
        },
        {
          t: 58500,
          o: 1.08322,
          h: 1.08343,
          l: 1.08311,
          c: 1.08343,
          v: 138
        },
        {
          t: 58800,
          o: 1.08342,
          h: 1.0835,
          l: 1.08334,
          c: 1.0835,
          v: 97
        },
        {
          t: 59100,
          o: 1.08351,
          h: 1.08357,
          l: 1.08348,
          c: 1.08356,
          v: 71
        },
        {
          t: 59400,
          o: 1.08356,
          h: 1.08358,
          l: 1.08328,
          c: 1.08351,
          v: 82
        },
        {
          t: 59700,
          o: 1.0835,
          h: 1.08352,
          l: 1.08337,
          c: 1.0834,
          v: 57
        },
        {
          t: 60000,
          o: 1.08337,
          h: 1.08342,
          l: 1.08332,
          c: 1.08336,
          v: 72
        },
        {
          t: 60300,
          o: 1.08332,
          h: 1.08354,
          l: 1.08332,
          c: 1.08352,
          v: 82
        },
        {
          t: 60600,
          o: 1.08351,
          h: 1.08367,
          l: 1.08351,
          c: 1.08359,
          v: 80
        },
        {
          t: 60900,
          o: 1.08358,
          h: 1.08374,
          l: 1.08357,
          c: 1.08369,
          v: 68
        },
        {
          t: 61200,
          o: 1.08369,
          h: 1.08381,
          l: 1.08359,
          c: 1.08381,
          v: 95
        },
        {
          t: 61500,
          o: 1.08382,
          h: 1.08395,
          l: 1.0838,
          c: 1.08381,
          v: 95
        },
        {
          t: 61800,
          o: 1.08382,
          h: 1.08383,
          l: 1.08371,
          c: 1.08372,
          v: 65
        },
        {
          t: 62100,
          o: 1.08372,
          h: 1.08374,
          l: 1.08357,
          c: 1.08357,
          v: 78
        },
        {
          t: 62400,
          o: 1.08356,
          h: 1.08356,
          l: 1.08344,
          c: 1.08349,
          v: 56
        },
        {
          t: 62700,
          o: 1.08349,
          h: 1.08363,
          l: 1.08344,
          c: 1.08361,
          v: 72
        },
        {
          t: 63000,
          o: 1.08361,
          h: 1.08361,
          l: 1.08351,
          c: 1.08354,
          v: 48
        },
        {
          t: 63300,
          o: 1.08352,
          h: 1.08356,
          l: 1.08347,
          c: 1.08352,
          v: 60
        },
        {
          t: 63600,
          o: 1.08352,
          h: 1.08352,
          l: 1.08336,
          c: 1.08339,
          v: 71
        },
        {
          t: 63900,
          o: 1.08338,
          h: 1.08338,
          l: 1.0833,
          c: 1.08333,
          v: 28
        },
        {
          t: 64200,
          o: 1.08332,
          h: 1.08333,
          l: 1.08321,
          c: 1.08327,
          v: 50
        },
        {
          t: 64500,
          o: 1.08324,
          h: 1.08324,
          l: 1.08314,
          c: 1.08316,
          v: 84
        },
        {
          t: 64800,
          o: 1.08316,
          h: 1.0834,
          l: 1.08302,
          c: 1.08326,
          v: 153
        },
        {
          t: 65100,
          o: 1.08326,
          h: 1.08344,
          l: 1.08326,
          c: 1.08339,
          v: 84
        },
        {
          t: 65400,
          o: 1.0834,
          h: 1.08345,
          l: 1.08338,
          c: 1.0834,
          v: 39
        },
        {
          t: 65700,
          o: 1.0834,
          h: 1.0834,
          l: 1.0833,
          c: 1.08336,
          v: 60
        },
        {
          t: 66000,
          o: 1.08334,
          h: 1.08336,
          l: 1.08329,
          c: 1.08329,
          v: 37
        },
        {
          t: 66300,
          o: 1.08329,
          h: 1.08329,
          l: 1.08313,
          c: 1.08313,
          v: 28
        },
        {
          t: 66600,
          o: 1.08313,
          h: 1.08313,
          l: 1.08294,
          c: 1.08308,
          v: 67
        },
        {
          t: 66900,
          o: 1.08315,
          h: 1.08327,
          l: 1.08315,
          c: 1.08318,
          v: 73
        },
        {
          t: 67200,
          o: 1.08317,
          h: 1.08323,
          l: 1.08304,
          c: 1.08318,
          v: 83
        },
        {
          t: 67500,
          o: 1.08317,
          h: 1.08321,
          l: 1.08316,
          c: 1.08318,
          v: 20
        },
        {
          t: 67800,
          o: 1.08319,
          h: 1.0833,
          l: 1.08317,
          c: 1.08327,
          v: 77
        },
        {
          t: 68100,
          o: 1.08327,
          h: 1.08335,
          l: 1.08322,
          c: 1.08323,
          v: 63
        },
        {
          t: 68400,
          o: 1.08323,
          h: 1.08332,
          l: 1.0832,
          c: 1.08324,
          v: 63
        },
        {
          t: 68700,
          o: 1.08323,
          h: 1.08331,
          l: 1.08305,
          c: 1.08308,
          v: 87
        },
        {
          t: 69000,
          o: 1.0831,
          h: 1.08333,
          l: 1.0831,
          c: 1.08328,
          v: 146
        },
        {
          t: 69300,
          o: 1.08328,
          h: 1.08332,
          l: 1.08328,
          c: 1.08332,
          v: 67
        },
        {
          t: 69600,
          o: 1.08336,
          h: 1.08338,
          l: 1.08328,
          c: 1.08328,
          v: 35
        },
        {
          t: 69900,
          o: 1.08329,
          h: 1.08334,
          l: 1.08328,
          c: 1.08328,
          v: 49
        },
        {
          t: 70200,
          o: 1.08335,
          h: 1.08335,
          l: 1.08327,
          c: 1.08331,
          v: 44
        },
        {
          t: 70500,
          o: 1.08331,
          h: 1.08332,
          l: 1.0833,
          c: 1.08331,
          v: 67
        },
        {
          t: 70800,
          o: 1.08331,
          h: 1.08336,
          l: 1.08327,
          c: 1.08334,
          v: 40
        },
        {
          t: 71100,
          o: 1.08334,
          h: 1.08335,
          l: 1.08329,
          c: 1.08335,
          v: 38
        },
        {
          t: 71400,
          o: 1.08334,
          h: 1.08336,
          l: 1.08332,
          c: 1.08332,
          v: 20
        },
        {
          t: 71700,
          o: 1.08333,
          h: 1.08349,
          l: 1.08329,
          c: 1.08332,
          v: 80
        },
        {
          t: 72000,
          o: 1.08331,
          h: 1.08335,
          l: 1.08328,
          c: 1.0833,
          v: 57
        },
        {
          t: 72300,
          o: 1.08329,
          h: 1.08334,
          l: 1.08329,
          c: 1.08333,
          v: 37
        },
        {
          t: 72600,
          o: 1.08334,
          h: 1.08341,
          l: 1.08328,
          c: 1.08331,
          v: 62
        },
        {
          t: 72900,
          o: 1.08332,
          h: 1.08336,
          l: 1.08328,
          c: 1.08334,
          v: 65
        },
        {
          t: 73200,
          o: 1.08335,
          h: 1.08336,
          l: 1.08329,
          c: 1.08329,
          v: 50
        },
        {
          t: 73500,
          o: 1.0833,
          h: 1.08334,
          l: 1.08328,
          c: 1.0833,
          v: 45
        },
        {
          t: 73800,
          o: 1.0833,
          h: 1.08334,
          l: 1.0833,
          c: 1.08331,
          v: 18
        },
        {
          t: 74100,
          o: 1.08332,
          h: 1.08333,
          l: 1.0831,
          c: 1.08315,
          v: 59
        },
        {
          t: 74400,
          o: 1.08315,
          h: 1.0832,
          l: 1.08311,
          c: 1.08311,
          v: 22
        },
        {
          t: 74700,
          o: 1.08312,
          h: 1.08331,
          l: 1.08311,
          c: 1.08326,
          v: 55
        },
        {
          t: 75000,
          o: 1.08325,
          h: 1.08328,
          l: 1.08317,
          c: 1.08324,
          v: 63
        },
        {
          t: 75300,
          o: 1.08322,
          h: 1.08333,
          l: 1.08314,
          c: 1.08327,
          v: 164
        },
        {
          t: 75600,
          o: 1.08326,
          h: 1.08327,
          l: 1.08317,
          c: 1.08323,
          v: 58
        },
        {
          t: 75900,
          o: 1.08321,
          h: 1.08329,
          l: 1.0832,
          c: 1.08324,
          v: 60
        },
        {
          t: 76200,
          o: 1.08324,
          h: 1.0834,
          l: 1.08321,
          c: 1.08336,
          v: 123
        },
        {
          t: 76500,
          o: 1.08336,
          h: 1.08346,
          l: 1.08327,
          c: 1.08334,
          v: 128
        },
        {
          t: 76800,
          o: 1.08336,
          h: 1.08341,
          l: 1.08327,
          c: 1.08335,
          v: 188
        },
        {
          t: 77100,
          o: 1.08336,
          h: 1.08348,
          l: 1.08335,
          c: 1.08342,
          v: 43
        },
        {
          t: 77400,
          o: 1.0834,
          h: 1.08346,
          l: 1.08336,
          c: 1.08339,
          v: 23
        },
        {
          t: 77700,
          o: 1.0834,
          h: 1.08341,
          l: 1.08323,
          c: 1.08339,
          v: 72
        },
        {
          t: 78000,
          o: 1.08337,
          h: 1.08344,
          l: 1.08334,
          c: 1.08343,
          v: 47
        },
        {
          t: 78300,
          o: 1.08345,
          h: 1.08351,
          l: 1.08338,
          c: 1.08344,
          v: 60
        },
        {
          t: 78600,
          o: 1.08345,
          h: 1.08354,
          l: 1.08339,
          c: 1.08348,
          v: 75
        },
        {
          t: 78900,
          o: 1.08348,
          h: 1.08374,
          l: 1.08325,
          c: 1.08338,
          v: 119
        },
        {
          t: 79200,
          o: 1.08341,
          h: 1.08341,
          l: 1.08318,
          c: 1.08332,
          v: 39
        },
        {
          t: 79500,
          o: 1.08332,
          h: 1.08351,
          l: 1.08318,
          c: 1.08351,
          v: 220
        },
        {
          t: 79800,
          o: 1.08348,
          h: 1.0836,
          l: 1.08332,
          c: 1.08346,
          v: 69
        },
        {
          t: 80100,
          o: 1.08337,
          h: 1.08337,
          l: 1.08327,
          c: 1.0833,
          v: 33
        },
        {
          t: 80400,
          o: 1.0833,
          h: 1.08354,
          l: 1.08327,
          c: 1.08338,
          v: 82
        },
        {
          t: 80700,
          o: 1.08345,
          h: 1.08358,
          l: 1.08329,
          c: 1.08349,
          v: 58
        },
        {
          t: 81000,
          o: 1.08349,
          h: 1.08357,
          l: 1.08334,
          c: 1.08334,
          v: 86
        },
        {
          t: 81300,
          o: 1.08334,
          h: 1.08364,
          l: 1.08334,
          c: 1.08348,
          v: 197
        },
        {
          t: 81600,
          o: 1.08347,
          h: 1.08365,
          l: 1.08347,
          c: 1.0836,
          v: 60
        },
        {
          t: 81900,
          o: 1.0836,
          h: 1.08364,
          l: 1.0836,
          c: 1.08361,
          v: 61
        },
        {
          t: 82200,
          o: 1.08364,
          h: 1.08366,
          l: 1.08359,
          c: 1.08363,
          v: 48
        },
        {
          t: 82500,
          o: 1.08364,
          h: 1.08364,
          l: 1.08361,
          c: 1.08363,
          v: 36
        },
        {
          t: 82800,
          o: 1.08362,
          h: 1.08362,
          l: 1.08346,
          c: 1.08356,
          v: 146
        },
        {
          t: 83100,
          o: 1.08355,
          h: 1.0836,
          l: 1.0835,
          c: 1.0836,
          v: 90
        },
        {
          t: 83400,
          o: 1.08359,
          h: 1.08365,
          l: 1.08355,
          c: 1.0836,
          v: 74
        },
        {
          t: 83700,
          o: 1.0836,
          h: 1.08363,
          l: 1.08355,
          c: 1.08355,
          v: 60
        },
        {
          t: 84000,
          o: 1.08356,
          h: 1.0836,
          l: 1.08347,
          c: 1.08351,
          v: 57
        },
        {
          t: 84300,
          o: 1.08352,
          h: 1.08358,
          l: 1.0835,
          c: 1.08357,
          v: 55
        },
        {
          t: 84600,
          o: 1.08356,
          h: 1.08361,
          l: 1.08356,
          c: 1.08357,
          v: 32
        },
        {
          t: 84900,
          o: 1.08361,
          h: 1.08361,
          l: 1.08354,
          c: 1.08357,
          v: 67
        },
        {
          t: 85200,
          o: 1.08355,
          h: 1.08355,
          l: 1.08345,
          c: 1.08347,
          v: 22
        },
        {
          t: 85500,
          o: 1.0835,
          h: 1.08361,
          l: 1.0835,
          c: 1.0836,
          v: 42
        },
        {
          t: 85800,
          o: 1.08361,
          h: 1.08362,
          l: 1.08356,
          c: 1.0836,
          v: 47
        },
        {
          t: 86100,
          o: 1.0836,
          h: 1.08365,
          l: 1.08359,
          c: 1.08361,
          v: 56
        }
      ]
    },
    {
      d: 1581984000,
      b: [
        {
          t: 0,
          o: 1.0836,
          h: 1.08364,
          l: 1.08326,
          c: 1.08326,
          v: 116
        },
        {
          t: 300,
          o: 1.08327,
          h: 1.08343,
          l: 1.08322,
          c: 1.08328,
          v: 66
        },
        {
          t: 600,
          o: 1.08327,
          h: 1.08327,
          l: 1.08303,
          c: 1.08306,
          v: 115
        },
        {
          t: 900,
          o: 1.08306,
          h: 1.0832,
          l: 1.08295,
          c: 1.08295,
          v: 96
        },
        {
          t: 1200,
          o: 1.08294,
          h: 1.08298,
          l: 1.08278,
          c: 1.08296,
          v: 145
        },
        {
          t: 1500,
          o: 1.083,
          h: 1.08317,
          l: 1.083,
          c: 1.08316,
          v: 76
        },
        {
          t: 1800,
          o: 1.08313,
          h: 1.08313,
          l: 1.08298,
          c: 1.0831,
          v: 140
        },
        {
          t: 2100,
          o: 1.08311,
          h: 1.08312,
          l: 1.08301,
          c: 1.08311,
          v: 77
        },
        {
          t: 2400,
          o: 1.08309,
          h: 1.08311,
          l: 1.08287,
          c: 1.08293,
          v: 87
        },
        {
          t: 2700,
          o: 1.08292,
          h: 1.08298,
          l: 1.08289,
          c: 1.0829,
          v: 77
        },
        {
          t: 3000,
          o: 1.0829,
          h: 1.0829,
          l: 1.08246,
          c: 1.08271,
          v: 147
        },
        {
          t: 3300,
          o: 1.08271,
          h: 1.08271,
          l: 1.08254,
          c: 1.08255,
          v: 173
        },
        {
          t: 3600,
          o: 1.08251,
          h: 1.0828,
          l: 1.08251,
          c: 1.08278,
          v: 191
        },
        {
          t: 3900,
          o: 1.08279,
          h: 1.08279,
          l: 1.0826,
          c: 1.08271,
          v: 140
        },
        {
          t: 4200,
          o: 1.08272,
          h: 1.08282,
          l: 1.08267,
          c: 1.08271,
          v: 108
        },
        {
          t: 4500,
          o: 1.08273,
          h: 1.08283,
          l: 1.08266,
          c: 1.08267,
          v: 133
        },
        {
          t: 4800,
          o: 1.08267,
          h: 1.08273,
          l: 1.08265,
          c: 1.08266,
          v: 50
        },
        {
          t: 5100,
          o: 1.08267,
          h: 1.08272,
          l: 1.08261,
          c: 1.08268,
          v: 66
        },
        {
          t: 5400,
          o: 1.08269,
          h: 1.08271,
          l: 1.08256,
          c: 1.08256,
          v: 99
        },
        {
          t: 5700,
          o: 1.08257,
          h: 1.08263,
          l: 1.08254,
          c: 1.08256,
          v: 89
        },
        {
          t: 6000,
          o: 1.08255,
          h: 1.08257,
          l: 1.08225,
          c: 1.08235,
          v: 157
        },
        {
          t: 6300,
          o: 1.08235,
          h: 1.08258,
          l: 1.08235,
          c: 1.08256,
          v: 94
        },
        {
          t: 6600,
          o: 1.08257,
          h: 1.08267,
          l: 1.08255,
          c: 1.08266,
          v: 55
        },
        {
          t: 6900,
          o: 1.08267,
          h: 1.08275,
          l: 1.08266,
          c: 1.08271,
          v: 21
        },
        {
          t: 7200,
          o: 1.0827,
          h: 1.0827,
          l: 1.0826,
          c: 1.08262,
          v: 89
        },
        {
          t: 7500,
          o: 1.08261,
          h: 1.08272,
          l: 1.08257,
          c: 1.08266,
          v: 81
        },
        {
          t: 7800,
          o: 1.08267,
          h: 1.0827,
          l: 1.0826,
          c: 1.08261,
          v: 60
        },
        {
          t: 8100,
          o: 1.08263,
          h: 1.08266,
          l: 1.08244,
          c: 1.08251,
          v: 71
        },
        {
          t: 8400,
          o: 1.08252,
          h: 1.08252,
          l: 1.0823,
          c: 1.0824,
          v: 102
        },
        {
          t: 8700,
          o: 1.0824,
          h: 1.08246,
          l: 1.08238,
          c: 1.08243,
          v: 76
        },
        {
          t: 9000,
          o: 1.08242,
          h: 1.08248,
          l: 1.08235,
          c: 1.08246,
          v: 100
        },
        {
          t: 9300,
          o: 1.08246,
          h: 1.0826,
          l: 1.08245,
          c: 1.08256,
          v: 106
        },
        {
          t: 9600,
          o: 1.08255,
          h: 1.08271,
          l: 1.08252,
          c: 1.08271,
          v: 92
        },
        {
          t: 9900,
          o: 1.08272,
          h: 1.08282,
          l: 1.0827,
          c: 1.08273,
          v: 78
        },
        {
          t: 10200,
          o: 1.08272,
          h: 1.08281,
          l: 1.08271,
          c: 1.08278,
          v: 64
        },
        {
          t: 10500,
          o: 1.0828,
          h: 1.08287,
          l: 1.08278,
          c: 1.08278,
          v: 77
        },
        {
          t: 10800,
          o: 1.08276,
          h: 1.08298,
          l: 1.08276,
          c: 1.08293,
          v: 86
        },
        {
          t: 11100,
          o: 1.08292,
          h: 1.08312,
          l: 1.08292,
          c: 1.08307,
          v: 36
        },
        {
          t: 11400,
          o: 1.08307,
          h: 1.08322,
          l: 1.08305,
          c: 1.0832,
          v: 55
        },
        {
          t: 11700,
          o: 1.08322,
          h: 1.08333,
          l: 1.0832,
          c: 1.08326,
          v: 39
        },
        {
          t: 12000,
          o: 1.08326,
          h: 1.08326,
          l: 1.08316,
          c: 1.08322,
          v: 40
        },
        {
          t: 12300,
          o: 1.08322,
          h: 1.08322,
          l: 1.08315,
          c: 1.08315,
          v: 42
        },
        {
          t: 12600,
          o: 1.08313,
          h: 1.08327,
          l: 1.08312,
          c: 1.08323,
          v: 44
        },
        {
          t: 12900,
          o: 1.08322,
          h: 1.08327,
          l: 1.08322,
          c: 1.08326,
          v: 48
        },
        {
          t: 13200,
          o: 1.08325,
          h: 1.08327,
          l: 1.08315,
          c: 1.08316,
          v: 54
        },
        {
          t: 13500,
          o: 1.08315,
          h: 1.08317,
          l: 1.08306,
          c: 1.08317,
          v: 32
        },
        {
          t: 13800,
          o: 1.08316,
          h: 1.08334,
          l: 1.08314,
          c: 1.08327,
          v: 66
        },
        {
          t: 14100,
          o: 1.08327,
          h: 1.08328,
          l: 1.0832,
          c: 1.0832,
          v: 31
        },
        {
          t: 14400,
          o: 1.08321,
          h: 1.08322,
          l: 1.08315,
          c: 1.08315,
          v: 58
        },
        {
          t: 14700,
          o: 1.08316,
          h: 1.0832,
          l: 1.08315,
          c: 1.0832,
          v: 36
        },
        {
          t: 15000,
          o: 1.08319,
          h: 1.08326,
          l: 1.08317,
          c: 1.0832,
          v: 56
        },
        {
          t: 15300,
          o: 1.0832,
          h: 1.08325,
          l: 1.0832,
          c: 1.08321,
          v: 35
        },
        {
          t: 15600,
          o: 1.08322,
          h: 1.08322,
          l: 1.08312,
          c: 1.08315,
          v: 38
        },
        {
          t: 15900,
          o: 1.08317,
          h: 1.08322,
          l: 1.08315,
          c: 1.08321,
          v: 25
        },
        {
          t: 16200,
          o: 1.0832,
          h: 1.08322,
          l: 1.08316,
          c: 1.08316,
          v: 33
        },
        {
          t: 16500,
          o: 1.08314,
          h: 1.08315,
          l: 1.08311,
          c: 1.08313,
          v: 16
        },
        {
          t: 16800,
          o: 1.08313,
          h: 1.08313,
          l: 1.08302,
          c: 1.08302,
          v: 23
        },
        {
          t: 17100,
          o: 1.08299,
          h: 1.08303,
          l: 1.08297,
          c: 1.08302,
          v: 34
        },
        {
          t: 17400,
          o: 1.08302,
          h: 1.08308,
          l: 1.083,
          c: 1.08302,
          v: 24
        },
        {
          t: 17700,
          o: 1.08302,
          h: 1.08305,
          l: 1.08297,
          c: 1.08297,
          v: 35
        },
        {
          t: 18000,
          o: 1.08297,
          h: 1.08304,
          l: 1.08297,
          c: 1.08301,
          v: 35
        },
        {
          t: 18300,
          o: 1.08301,
          h: 1.08308,
          l: 1.08297,
          c: 1.08307,
          v: 29
        },
        {
          t: 18600,
          o: 1.08308,
          h: 1.08317,
          l: 1.08308,
          c: 1.08315,
          v: 42
        },
        {
          t: 18900,
          o: 1.08316,
          h: 1.08318,
          l: 1.083,
          c: 1.08302,
          v: 49
        },
        {
          t: 19200,
          o: 1.08297,
          h: 1.0831,
          l: 1.08297,
          c: 1.08305,
          v: 39
        },
        {
          t: 19500,
          o: 1.08306,
          h: 1.08315,
          l: 1.08305,
          c: 1.0831,
          v: 31
        },
        {
          t: 19800,
          o: 1.08309,
          h: 1.08312,
          l: 1.08304,
          c: 1.08312,
          v: 35
        },
        {
          t: 20100,
          o: 1.08313,
          h: 1.08315,
          l: 1.0831,
          c: 1.0831,
          v: 24
        },
        {
          t: 20400,
          o: 1.0831,
          h: 1.08312,
          l: 1.0831,
          c: 1.08311,
          v: 18
        },
        {
          t: 20700,
          o: 1.0831,
          h: 1.0831,
          l: 1.08306,
          c: 1.08306,
          v: 11
        },
        {
          t: 21000,
          o: 1.08307,
          h: 1.08311,
          l: 1.08305,
          c: 1.08307,
          v: 25
        },
        {
          t: 21300,
          o: 1.08306,
          h: 1.08312,
          l: 1.08305,
          c: 1.08306,
          v: 70
        },
        {
          t: 21600,
          o: 1.0831,
          h: 1.08341,
          l: 1.0831,
          c: 1.08337,
          v: 107
        },
        {
          t: 21900,
          o: 1.08337,
          h: 1.08355,
          l: 1.08335,
          c: 1.08355,
          v: 118
        },
        {
          t: 22200,
          o: 1.08355,
          h: 1.08362,
          l: 1.08343,
          c: 1.08343,
          v: 60
        },
        {
          t: 22500,
          o: 1.08342,
          h: 1.08345,
          l: 1.08333,
          c: 1.08341,
          v: 87
        },
        {
          t: 22800,
          o: 1.08341,
          h: 1.08346,
          l: 1.08329,
          c: 1.08336,
          v: 80
        },
        {
          t: 23100,
          o: 1.08338,
          h: 1.08343,
          l: 1.08334,
          c: 1.08341,
          v: 70
        },
        {
          t: 23400,
          o: 1.0834,
          h: 1.08345,
          l: 1.08319,
          c: 1.0832,
          v: 104
        },
        {
          t: 23700,
          o: 1.0832,
          h: 1.08321,
          l: 1.08312,
          c: 1.08313,
          v: 64
        },
        {
          t: 24000,
          o: 1.08314,
          h: 1.08315,
          l: 1.08303,
          c: 1.08306,
          v: 83
        },
        {
          t: 24300,
          o: 1.08309,
          h: 1.0831,
          l: 1.08302,
          c: 1.08308,
          v: 57
        },
        {
          t: 24600,
          o: 1.08307,
          h: 1.08316,
          l: 1.08304,
          c: 1.08305,
          v: 76
        },
        {
          t: 24900,
          o: 1.08306,
          h: 1.08313,
          l: 1.08279,
          c: 1.08293,
          v: 148
        },
        {
          t: 25200,
          o: 1.08294,
          h: 1.08322,
          l: 1.08291,
          c: 1.08305,
          v: 215
        },
        {
          t: 25500,
          o: 1.08304,
          h: 1.08331,
          l: 1.08304,
          c: 1.08328,
          v: 147
        },
        {
          t: 25800,
          o: 1.08329,
          h: 1.08342,
          l: 1.08322,
          c: 1.08341,
          v: 122
        },
        {
          t: 26100,
          o: 1.08338,
          h: 1.08364,
          l: 1.08331,
          c: 1.08362,
          v: 154
        },
        {
          t: 26400,
          o: 1.08363,
          h: 1.08363,
          l: 1.08345,
          c: 1.08358,
          v: 158
        },
        {
          t: 26700,
          o: 1.08358,
          h: 1.08358,
          l: 1.08333,
          c: 1.08348,
          v: 136
        },
        {
          t: 27000,
          o: 1.08346,
          h: 1.08366,
          l: 1.08338,
          c: 1.08345,
          v: 204
        },
        {
          t: 27300,
          o: 1.08345,
          h: 1.08368,
          l: 1.08341,
          c: 1.08368,
          v: 176
        },
        {
          t: 27600,
          o: 1.08366,
          h: 1.08367,
          l: 1.0835,
          c: 1.08355,
          v: 124
        },
        {
          t: 27900,
          o: 1.08355,
          h: 1.08355,
          l: 1.0834,
          c: 1.08345,
          v: 152
        },
        {
          t: 28200,
          o: 1.08346,
          h: 1.08363,
          l: 1.08336,
          c: 1.08348,
          v: 138
        },
        {
          t: 28500,
          o: 1.08346,
          h: 1.08346,
          l: 1.08325,
          c: 1.08325,
          v: 190
        },
        {
          t: 28800,
          o: 1.08321,
          h: 1.08325,
          l: 1.08307,
          c: 1.08309,
          v: 213
        },
        {
          t: 29100,
          o: 1.08309,
          h: 1.08346,
          l: 1.08269,
          c: 1.08342,
          v: 257
        },
        {
          t: 29400,
          o: 1.08343,
          h: 1.08347,
          l: 1.08259,
          c: 1.08281,
          v: 343
        },
        {
          t: 29700,
          o: 1.0828,
          h: 1.0829,
          l: 1.08262,
          c: 1.08282,
          v: 275
        },
        {
          t: 30000,
          o: 1.08282,
          h: 1.08304,
          l: 1.08271,
          c: 1.08295,
          v: 232
        },
        {
          t: 30300,
          o: 1.083,
          h: 1.08301,
          l: 1.0828,
          c: 1.08289,
          v: 210
        },
        {
          t: 30600,
          o: 1.0829,
          h: 1.08295,
          l: 1.0827,
          c: 1.08284,
          v: 201
        },
        {
          t: 30900,
          o: 1.08285,
          h: 1.08289,
          l: 1.08245,
          c: 1.08254,
          v: 197
        },
        {
          t: 31200,
          o: 1.08253,
          h: 1.08281,
          l: 1.08251,
          c: 1.08272,
          v: 178
        },
        {
          t: 31500,
          o: 1.0827,
          h: 1.08286,
          l: 1.08264,
          c: 1.08265,
          v: 172
        },
        {
          t: 31800,
          o: 1.08267,
          h: 1.08275,
          l: 1.08255,
          c: 1.0826,
          v: 189
        },
        {
          t: 32100,
          o: 1.08259,
          h: 1.0826,
          l: 1.08245,
          c: 1.0826,
          v: 194
        },
        {
          t: 32400,
          o: 1.08261,
          h: 1.08276,
          l: 1.08242,
          c: 1.0827,
          v: 249
        },
        {
          t: 32700,
          o: 1.08271,
          h: 1.08299,
          l: 1.08271,
          c: 1.08292,
          v: 205
        },
        {
          t: 33000,
          o: 1.08293,
          h: 1.08296,
          l: 1.08275,
          c: 1.08288,
          v: 189
        },
        {
          t: 33300,
          o: 1.08289,
          h: 1.08309,
          l: 1.08289,
          c: 1.08299,
          v: 197
        },
        {
          t: 33600,
          o: 1.08297,
          h: 1.08301,
          l: 1.08284,
          c: 1.08295,
          v: 154
        },
        {
          t: 33900,
          o: 1.08294,
          h: 1.08304,
          l: 1.08288,
          c: 1.0829,
          v: 143
        },
        {
          t: 34200,
          o: 1.08286,
          h: 1.083,
          l: 1.08275,
          c: 1.08282,
          v: 158
        },
        {
          t: 34500,
          o: 1.08283,
          h: 1.08301,
          l: 1.08278,
          c: 1.08286,
          v: 134
        },
        {
          t: 34800,
          o: 1.08287,
          h: 1.08296,
          l: 1.08278,
          c: 1.08279,
          v: 102
        },
        {
          t: 35100,
          o: 1.08278,
          h: 1.08296,
          l: 1.08278,
          c: 1.0829,
          v: 112
        },
        {
          t: 35400,
          o: 1.08292,
          h: 1.08292,
          l: 1.08261,
          c: 1.08275,
          v: 137
        },
        {
          t: 35700,
          o: 1.08274,
          h: 1.08284,
          l: 1.08267,
          c: 1.08271,
          v: 134
        },
        {
          t: 36000,
          o: 1.0827,
          h: 1.08272,
          l: 1.08209,
          c: 1.08242,
          v: 373
        },
        {
          t: 36300,
          o: 1.08241,
          h: 1.08273,
          l: 1.0824,
          c: 1.08261,
          v: 202
        },
        {
          t: 36600,
          o: 1.08261,
          h: 1.08271,
          l: 1.08216,
          c: 1.08216,
          v: 192
        },
        {
          t: 36900,
          o: 1.08216,
          h: 1.0825,
          l: 1.08213,
          c: 1.0825,
          v: 185
        },
        {
          t: 37200,
          o: 1.0825,
          h: 1.08281,
          l: 1.0825,
          c: 1.08274,
          v: 224
        },
        {
          t: 37500,
          o: 1.08273,
          h: 1.08275,
          l: 1.08234,
          c: 1.08235,
          v: 239
        },
        {
          t: 37800,
          o: 1.08235,
          h: 1.08365,
          l: 1.08221,
          c: 1.08269,
          v: 340
        },
        {
          t: 38100,
          o: 1.08268,
          h: 1.0833,
          l: 1.08264,
          c: 1.08293,
          v: 397
        },
        {
          t: 38400,
          o: 1.0829,
          h: 1.08331,
          l: 1.08268,
          c: 1.08325,
          v: 295
        },
        {
          t: 38700,
          o: 1.08324,
          h: 1.08328,
          l: 1.08283,
          c: 1.0829,
          v: 224
        },
        {
          t: 39000,
          o: 1.0829,
          h: 1.08333,
          l: 1.08288,
          c: 1.08319,
          v: 214
        },
        {
          t: 39300,
          o: 1.08321,
          h: 1.08336,
          l: 1.08256,
          c: 1.08263,
          v: 259
        },
        {
          t: 39600,
          o: 1.08263,
          h: 1.08298,
          l: 1.08263,
          c: 1.08285,
          v: 212
        },
        {
          t: 39900,
          o: 1.08288,
          h: 1.0833,
          l: 1.08278,
          c: 1.0833,
          v: 167
        },
        {
          t: 40200,
          o: 1.08325,
          h: 1.08343,
          l: 1.08324,
          c: 1.08335,
          v: 146
        },
        {
          t: 40500,
          o: 1.08338,
          h: 1.08354,
          l: 1.08328,
          c: 1.08338,
          v: 169
        },
        {
          t: 40800,
          o: 1.08338,
          h: 1.08343,
          l: 1.08327,
          c: 1.08327,
          v: 120
        },
        {
          t: 41100,
          o: 1.08328,
          h: 1.08334,
          l: 1.08314,
          c: 1.08333,
          v: 129
        },
        {
          t: 41400,
          o: 1.08332,
          h: 1.08332,
          l: 1.08285,
          c: 1.08315,
          v: 168
        },
        {
          t: 41700,
          o: 1.08314,
          h: 1.08331,
          l: 1.08314,
          c: 1.08328,
          v: 116
        },
        {
          t: 42000,
          o: 1.08329,
          h: 1.08336,
          l: 1.0829,
          c: 1.08296,
          v: 135
        },
        {
          t: 42300,
          o: 1.08296,
          h: 1.08297,
          l: 1.08273,
          c: 1.08283,
          v: 157
        },
        {
          t: 42600,
          o: 1.08279,
          h: 1.08285,
          l: 1.08268,
          c: 1.08273,
          v: 160
        },
        {
          t: 42900,
          o: 1.0827,
          h: 1.08272,
          l: 1.0824,
          c: 1.0824,
          v: 218
        },
        {
          t: 43200,
          o: 1.08241,
          h: 1.08243,
          l: 1.08217,
          c: 1.08226,
          v: 225
        },
        {
          t: 43500,
          o: 1.08226,
          h: 1.08243,
          l: 1.08217,
          c: 1.08228,
          v: 142
        },
        {
          t: 43800,
          o: 1.0823,
          h: 1.0823,
          l: 1.08197,
          c: 1.08214,
          v: 192
        },
        {
          t: 44100,
          o: 1.08214,
          h: 1.08228,
          l: 1.08201,
          c: 1.08211,
          v: 217
        },
        {
          t: 44400,
          o: 1.0821,
          h: 1.08225,
          l: 1.08168,
          c: 1.08187,
          v: 227
        },
        {
          t: 44700,
          o: 1.08188,
          h: 1.08189,
          l: 1.08167,
          c: 1.08178,
          v: 195
        },
        {
          t: 45000,
          o: 1.08177,
          h: 1.08186,
          l: 1.08135,
          c: 1.08144,
          v: 217
        },
        {
          t: 45300,
          o: 1.08142,
          h: 1.08157,
          l: 1.0812,
          c: 1.08154,
          v: 264
        },
        {
          t: 45600,
          o: 1.08154,
          h: 1.08166,
          l: 1.08149,
          c: 1.08153,
          v: 158
        },
        {
          t: 45900,
          o: 1.08155,
          h: 1.0817,
          l: 1.08144,
          c: 1.08164,
          v: 195
        },
        {
          t: 46200,
          o: 1.08163,
          h: 1.08169,
          l: 1.08138,
          c: 1.08143,
          v: 137
        },
        {
          t: 46500,
          o: 1.08142,
          h: 1.08155,
          l: 1.08134,
          c: 1.08136,
          v: 198
        },
        {
          t: 46800,
          o: 1.08137,
          h: 1.08164,
          l: 1.08135,
          c: 1.08163,
          v: 200
        },
        {
          t: 47100,
          o: 1.08161,
          h: 1.08162,
          l: 1.08142,
          c: 1.08147,
          v: 129
        },
        {
          t: 47400,
          o: 1.08146,
          h: 1.0816,
          l: 1.08141,
          c: 1.08151,
          v: 172
        },
        {
          t: 47700,
          o: 1.08148,
          h: 1.08152,
          l: 1.08102,
          c: 1.08107,
          v: 198
        },
        {
          t: 48000,
          o: 1.08106,
          h: 1.08128,
          l: 1.08106,
          c: 1.0811,
          v: 254
        },
        {
          t: 48300,
          o: 1.08108,
          h: 1.08128,
          l: 1.08107,
          c: 1.0812,
          v: 231
        },
        {
          t: 48600,
          o: 1.08121,
          h: 1.08127,
          l: 1.07954,
          c: 1.07962,
          v: 457
        },
        {
          t: 48900,
          o: 1.07964,
          h: 1.07975,
          l: 1.0793,
          c: 1.07973,
          v: 533
        },
        {
          t: 49200,
          o: 1.07975,
          h: 1.08018,
          l: 1.07966,
          c: 1.08014,
          v: 333
        },
        {
          t: 49500,
          o: 1.08018,
          h: 1.08023,
          l: 1.07973,
          c: 1.07987,
          v: 280
        },
        {
          t: 49800,
          o: 1.07989,
          h: 1.08023,
          l: 1.07988,
          c: 1.08021,
          v: 197
        },
        {
          t: 50100,
          o: 1.08019,
          h: 1.08028,
          l: 1.08008,
          c: 1.08017,
          v: 192
        },
        {
          t: 50400,
          o: 1.0802,
          h: 1.08023,
          l: 1.07973,
          c: 1.07981,
          v: 270
        },
        {
          t: 50700,
          o: 1.0798,
          h: 1.0798,
          l: 1.0793,
          c: 1.07934,
          v: 285
        },
        {
          t: 51000,
          o: 1.07937,
          h: 1.07953,
          l: 1.07903,
          c: 1.07915,
          v: 253
        },
        {
          t: 51300,
          o: 1.07915,
          h: 1.0792,
          l: 1.07848,
          c: 1.07874,
          v: 355
        },
        {
          t: 51600,
          o: 1.07875,
          h: 1.0792,
          l: 1.07871,
          c: 1.07914,
          v: 349
        },
        {
          t: 51900,
          o: 1.07915,
          h: 1.07942,
          l: 1.07904,
          c: 1.07929,
          v: 287
        },
        {
          t: 52200,
          o: 1.07929,
          h: 1.0797,
          l: 1.07913,
          c: 1.07965,
          v: 328
        },
        {
          t: 52500,
          o: 1.07963,
          h: 1.0799,
          l: 1.07957,
          c: 1.07983,
          v: 324
        },
        {
          t: 52800,
          o: 1.07986,
          h: 1.08001,
          l: 1.07974,
          c: 1.07978,
          v: 309
        },
        {
          t: 53100,
          o: 1.07979,
          h: 1.07979,
          l: 1.07938,
          c: 1.07956,
          v: 199
        },
        {
          t: 53400,
          o: 1.07955,
          h: 1.08028,
          l: 1.07955,
          c: 1.08025,
          v: 298
        },
        {
          t: 53700,
          o: 1.08024,
          h: 1.08075,
          l: 1.08014,
          c: 1.08074,
          v: 303
        },
        {
          t: 54000,
          o: 1.08072,
          h: 1.08162,
          l: 1.08071,
          c: 1.0816,
          v: 326
        },
        {
          t: 54300,
          o: 1.08163,
          h: 1.08173,
          l: 1.08132,
          c: 1.08138,
          v: 326
        },
        {
          t: 54600,
          o: 1.08138,
          h: 1.0814,
          l: 1.08108,
          c: 1.08108,
          v: 230
        },
        {
          t: 54900,
          o: 1.08112,
          h: 1.08143,
          l: 1.08093,
          c: 1.08142,
          v: 210
        },
        {
          t: 55200,
          o: 1.08141,
          h: 1.08158,
          l: 1.08128,
          c: 1.08141,
          v: 198
        },
        {
          t: 55500,
          o: 1.0814,
          h: 1.08169,
          l: 1.08115,
          c: 1.08167,
          v: 252
        },
        {
          t: 55800,
          o: 1.08163,
          h: 1.08165,
          l: 1.08123,
          c: 1.08138,
          v: 290
        },
        {
          t: 56100,
          o: 1.08145,
          h: 1.08198,
          l: 1.08144,
          c: 1.08193,
          v: 219
        },
        {
          t: 56400,
          o: 1.08193,
          h: 1.08194,
          l: 1.08158,
          c: 1.08173,
          v: 237
        },
        {
          t: 56700,
          o: 1.0817,
          h: 1.08192,
          l: 1.08147,
          c: 1.08183,
          v: 262
        },
        {
          t: 57000,
          o: 1.08184,
          h: 1.0819,
          l: 1.08156,
          c: 1.08184,
          v: 185
        },
        {
          t: 57300,
          o: 1.08186,
          h: 1.08254,
          l: 1.08186,
          c: 1.08224,
          v: 509
        },
        {
          t: 57600,
          o: 1.08222,
          h: 1.08226,
          l: 1.08196,
          c: 1.08211,
          v: 339
        },
        {
          t: 57900,
          o: 1.08212,
          h: 1.08237,
          l: 1.082,
          c: 1.08206,
          v: 288
        },
        {
          t: 58200,
          o: 1.08206,
          h: 1.08231,
          l: 1.08201,
          c: 1.08206,
          v: 240
        },
        {
          t: 58500,
          o: 1.08207,
          h: 1.08208,
          l: 1.08177,
          c: 1.08182,
          v: 270
        },
        {
          t: 58800,
          o: 1.08181,
          h: 1.08204,
          l: 1.08163,
          c: 1.08193,
          v: 233
        },
        {
          t: 59100,
          o: 1.08193,
          h: 1.08194,
          l: 1.08161,
          c: 1.08177,
          v: 239
        },
        {
          t: 59400,
          o: 1.08175,
          h: 1.08175,
          l: 1.08135,
          c: 1.08135,
          v: 204
        },
        {
          t: 59700,
          o: 1.08137,
          h: 1.08147,
          l: 1.08119,
          c: 1.08123,
          v: 210
        },
        {
          t: 60000,
          o: 1.08122,
          h: 1.08136,
          l: 1.08115,
          c: 1.08129,
          v: 158
        },
        {
          t: 60300,
          o: 1.08128,
          h: 1.08139,
          l: 1.08123,
          c: 1.08133,
          v: 184
        },
        {
          t: 60600,
          o: 1.08132,
          h: 1.08136,
          l: 1.08118,
          c: 1.08133,
          v: 143
        },
        {
          t: 60900,
          o: 1.08133,
          h: 1.0815,
          l: 1.08124,
          c: 1.08141,
          v: 164
        },
        {
          t: 61200,
          o: 1.08142,
          h: 1.08143,
          l: 1.08131,
          c: 1.08132,
          v: 125
        },
        {
          t: 61500,
          o: 1.08133,
          h: 1.08141,
          l: 1.08111,
          c: 1.08113,
          v: 120
        },
        {
          t: 61800,
          o: 1.08113,
          h: 1.08113,
          l: 1.08097,
          c: 1.08113,
          v: 160
        },
        {
          t: 62100,
          o: 1.08113,
          h: 1.08113,
          l: 1.081,
          c: 1.08113,
          v: 96
        },
        {
          t: 62400,
          o: 1.08112,
          h: 1.08113,
          l: 1.08105,
          c: 1.08108,
          v: 93
        },
        {
          t: 62700,
          o: 1.08106,
          h: 1.08108,
          l: 1.08085,
          c: 1.08086,
          v: 137
        },
        {
          t: 63000,
          o: 1.08086,
          h: 1.08087,
          l: 1.0805,
          c: 1.08051,
          v: 182
        },
        {
          t: 63300,
          o: 1.08052,
          h: 1.08052,
          l: 1.08021,
          c: 1.08043,
          v: 173
        },
        {
          t: 63600,
          o: 1.08043,
          h: 1.08044,
          l: 1.08031,
          c: 1.08035,
          v: 127
        },
        {
          t: 63900,
          o: 1.08034,
          h: 1.08052,
          l: 1.08032,
          c: 1.08048,
          v: 104
        },
        {
          t: 64200,
          o: 1.08048,
          h: 1.08055,
          l: 1.08036,
          c: 1.08037,
          v: 70
        },
        {
          t: 64500,
          o: 1.08035,
          h: 1.08043,
          l: 1.08027,
          c: 1.08038,
          v: 109
        },
        {
          t: 64800,
          o: 1.08038,
          h: 1.08038,
          l: 1.08016,
          c: 1.08019,
          v: 131
        },
        {
          t: 65100,
          o: 1.08019,
          h: 1.08023,
          l: 1.08001,
          c: 1.08004,
          v: 102
        },
        {
          t: 65400,
          o: 1.08007,
          h: 1.08024,
          l: 1.08006,
          c: 1.08014,
          v: 120
        },
        {
          t: 65700,
          o: 1.08013,
          h: 1.08019,
          l: 1.08006,
          c: 1.08017,
          v: 90
        },
        {
          t: 66000,
          o: 1.08018,
          h: 1.08028,
          l: 1.08014,
          c: 1.08018,
          v: 70
        },
        {
          t: 66300,
          o: 1.08018,
          h: 1.08028,
          l: 1.08017,
          c: 1.08018,
          v: 115
        },
        {
          t: 66600,
          o: 1.08018,
          h: 1.08029,
          l: 1.0801,
          c: 1.08022,
          v: 120
        },
        {
          t: 66900,
          o: 1.08023,
          h: 1.0803,
          l: 1.08015,
          c: 1.08015,
          v: 70
        },
        {
          t: 67200,
          o: 1.08016,
          h: 1.0803,
          l: 1.08015,
          c: 1.08029,
          v: 76
        },
        {
          t: 67500,
          o: 1.08028,
          h: 1.08035,
          l: 1.08015,
          c: 1.08015,
          v: 115
        },
        {
          t: 67800,
          o: 1.0802,
          h: 1.08022,
          l: 1.08012,
          c: 1.08013,
          v: 83
        },
        {
          t: 68100,
          o: 1.08013,
          h: 1.08016,
          l: 1.07991,
          c: 1.07996,
          v: 93
        },
        {
          t: 68400,
          o: 1.07995,
          h: 1.08002,
          l: 1.07988,
          c: 1.07989,
          v: 93
        },
        {
          t: 68700,
          o: 1.07989,
          h: 1.08005,
          l: 1.07986,
          c: 1.08004,
          v: 68
        },
        {
          t: 69000,
          o: 1.08008,
          h: 1.08015,
          l: 1.08001,
          c: 1.08003,
          v: 74
        },
        {
          t: 69300,
          o: 1.08005,
          h: 1.08007,
          l: 1.07977,
          c: 1.07978,
          v: 114
        },
        {
          t: 69600,
          o: 1.07978,
          h: 1.07987,
          l: 1.07972,
          c: 1.07983,
          v: 101
        },
        {
          t: 69900,
          o: 1.07983,
          h: 1.07996,
          l: 1.07983,
          c: 1.07984,
          v: 98
        },
        {
          t: 70200,
          o: 1.07988,
          h: 1.07989,
          l: 1.07972,
          c: 1.07975,
          v: 82
        },
        {
          t: 70500,
          o: 1.07974,
          h: 1.07974,
          l: 1.07947,
          c: 1.07947,
          v: 96
        },
        {
          t: 70800,
          o: 1.07947,
          h: 1.07949,
          l: 1.07925,
          c: 1.07939,
          v: 134
        },
        {
          t: 71100,
          o: 1.07938,
          h: 1.07943,
          l: 1.07925,
          c: 1.0794,
          v: 114
        },
        {
          t: 71400,
          o: 1.07943,
          h: 1.07955,
          l: 1.07943,
          c: 1.0795,
          v: 104
        },
        {
          t: 71700,
          o: 1.0795,
          h: 1.07953,
          l: 1.0793,
          c: 1.07935,
          v: 153
        },
        {
          t: 72000,
          o: 1.07934,
          h: 1.07964,
          l: 1.0793,
          c: 1.07959,
          v: 96
        },
        {
          t: 72300,
          o: 1.07959,
          h: 1.0796,
          l: 1.07953,
          c: 1.07955,
          v: 56
        },
        {
          t: 72600,
          o: 1.07954,
          h: 1.07954,
          l: 1.07947,
          c: 1.07952,
          v: 34
        },
        {
          t: 72900,
          o: 1.07952,
          h: 1.07952,
          l: 1.07918,
          c: 1.07922,
          v: 98
        },
        {
          t: 73200,
          o: 1.07923,
          h: 1.07924,
          l: 1.07914,
          c: 1.07918,
          v: 70
        },
        {
          t: 73500,
          o: 1.07916,
          h: 1.0792,
          l: 1.07904,
          c: 1.07905,
          v: 51
        },
        {
          t: 73800,
          o: 1.07906,
          h: 1.0792,
          l: 1.07903,
          c: 1.07914,
          v: 53
        },
        {
          t: 74100,
          o: 1.07915,
          h: 1.0794,
          l: 1.0791,
          c: 1.0794,
          v: 75
        },
        {
          t: 74400,
          o: 1.07936,
          h: 1.07938,
          l: 1.07919,
          c: 1.07926,
          v: 80
        },
        {
          t: 74700,
          o: 1.07925,
          h: 1.07938,
          l: 1.07923,
          c: 1.07931,
          v: 62
        },
        {
          t: 75000,
          o: 1.07932,
          h: 1.0794,
          l: 1.07928,
          c: 1.07934,
          v: 77
        },
        {
          t: 75300,
          o: 1.07935,
          h: 1.0796,
          l: 1.07933,
          c: 1.07956,
          v: 205
        },
        {
          t: 75600,
          o: 1.0795,
          h: 1.07951,
          l: 1.07938,
          c: 1.0795,
          v: 110
        },
        {
          t: 75900,
          o: 1.07949,
          h: 1.07954,
          l: 1.07949,
          c: 1.07951,
          v: 35
        },
        {
          t: 76200,
          o: 1.07953,
          h: 1.07955,
          l: 1.07937,
          c: 1.07939,
          v: 88
        },
        {
          t: 76500,
          o: 1.07941,
          h: 1.07943,
          l: 1.07922,
          c: 1.07928,
          v: 32
        },
        {
          t: 76800,
          o: 1.07928,
          h: 1.07931,
          l: 1.07927,
          c: 1.07931,
          v: 27
        },
        {
          t: 77100,
          o: 1.07931,
          h: 1.07931,
          l: 1.07915,
          c: 1.07919,
          v: 34
        },
        {
          t: 77400,
          o: 1.07918,
          h: 1.07918,
          l: 1.07909,
          c: 1.07911,
          v: 28
        },
        {
          t: 77700,
          o: 1.07911,
          h: 1.07911,
          l: 1.07895,
          c: 1.07904,
          v: 75
        },
        {
          t: 78000,
          o: 1.07905,
          h: 1.07905,
          l: 1.07893,
          c: 1.07894,
          v: 56
        },
        {
          t: 78300,
          o: 1.07894,
          h: 1.0793,
          l: 1.07893,
          c: 1.07916,
          v: 54
        },
        {
          t: 78600,
          o: 1.07918,
          h: 1.07926,
          l: 1.07913,
          c: 1.07924,
          v: 50
        },
        {
          t: 78900,
          o: 1.07923,
          h: 1.07925,
          l: 1.07899,
          c: 1.07907,
          v: 91
        },
        {
          t: 79200,
          o: 1.07906,
          h: 1.07911,
          l: 1.07883,
          c: 1.07907,
          v: 26
        },
        {
          t: 79500,
          o: 1.0791,
          h: 1.07922,
          l: 1.07906,
          c: 1.07922,
          v: 30
        },
        {
          t: 79800,
          o: 1.07924,
          h: 1.0794,
          l: 1.07921,
          c: 1.07923,
          v: 60
        },
        {
          t: 80100,
          o: 1.07919,
          h: 1.07924,
          l: 1.07915,
          c: 1.07922,
          v: 79
        },
        {
          t: 80400,
          o: 1.07915,
          h: 1.07929,
          l: 1.07915,
          c: 1.07921,
          v: 98
        },
        {
          t: 80700,
          o: 1.07919,
          h: 1.07934,
          l: 1.07915,
          c: 1.07929,
          v: 137
        },
        {
          t: 81000,
          o: 1.07915,
          h: 1.07932,
          l: 1.07915,
          c: 1.07928,
          v: 56
        },
        {
          t: 81300,
          o: 1.07929,
          h: 1.07942,
          l: 1.07928,
          c: 1.07938,
          v: 66
        },
        {
          t: 81600,
          o: 1.07938,
          h: 1.07943,
          l: 1.07936,
          c: 1.07941,
          v: 48
        },
        {
          t: 81900,
          o: 1.07939,
          h: 1.07947,
          l: 1.07932,
          c: 1.07947,
          v: 79
        },
        {
          t: 82200,
          o: 1.07947,
          h: 1.07947,
          l: 1.07939,
          c: 1.07943,
          v: 17
        },
        {
          t: 82500,
          o: 1.07938,
          h: 1.07948,
          l: 1.07936,
          c: 1.07938,
          v: 108
        },
        {
          t: 82800,
          o: 1.07938,
          h: 1.07942,
          l: 1.07917,
          c: 1.07935,
          v: 248
        },
        {
          t: 83100,
          o: 1.07936,
          h: 1.07948,
          l: 1.07934,
          c: 1.07948,
          v: 86
        },
        {
          t: 83400,
          o: 1.07945,
          h: 1.07949,
          l: 1.07925,
          c: 1.0794,
          v: 110
        },
        {
          t: 83700,
          o: 1.07939,
          h: 1.07948,
          l: 1.07938,
          c: 1.07945,
          v: 105
        },
        {
          t: 84000,
          o: 1.07946,
          h: 1.07952,
          l: 1.07944,
          c: 1.0795,
          v: 79
        },
        {
          t: 84300,
          o: 1.07949,
          h: 1.07956,
          l: 1.07949,
          c: 1.07953,
          v: 70
        },
        {
          t: 84600,
          o: 1.07955,
          h: 1.07955,
          l: 1.0795,
          c: 1.07951,
          v: 18
        },
        {
          t: 84900,
          o: 1.07951,
          h: 1.07961,
          l: 1.07951,
          c: 1.0796,
          v: 40
        },
        {
          t: 85200,
          o: 1.0796,
          h: 1.07967,
          l: 1.0796,
          c: 1.07961,
          v: 36
        },
        {
          t: 85500,
          o: 1.0796,
          h: 1.07961,
          l: 1.07955,
          c: 1.07957,
          v: 44
        },
        {
          t: 85800,
          o: 1.07956,
          h: 1.0796,
          l: 1.0795,
          c: 1.07955,
          v: 57
        },
        {
          t: 86100,
          o: 1.0795,
          h: 1.0795,
          l: 1.07939,
          c: 1.07944,
          v: 47
        }
      ]
    },
    {
      d: 1582070400,
      b: [
        {
          t: 0,
          o: 1.07944,
          h: 1.07944,
          l: 1.07924,
          c: 1.0793,
          v: 112
        },
        {
          t: 300,
          o: 1.0793,
          h: 1.07939,
          l: 1.07929,
          c: 1.07934,
          v: 79
        },
        {
          t: 600,
          o: 1.07934,
          h: 1.0794,
          l: 1.07934,
          c: 1.0794,
          v: 81
        },
        {
          t: 900,
          o: 1.0794,
          h: 1.07944,
          l: 1.07938,
          c: 1.07944,
          v: 40
        },
        {
          t: 1200,
          o: 1.07945,
          h: 1.07952,
          l: 1.07944,
          c: 1.07949,
          v: 32
        },
        {
          t: 1500,
          o: 1.0795,
          h: 1.07961,
          l: 1.07949,
          c: 1.07955,
          v: 86
        },
        {
          t: 1800,
          o: 1.07955,
          h: 1.07965,
          l: 1.07955,
          c: 1.0796,
          v: 48
        },
        {
          t: 2100,
          o: 1.0796,
          h: 1.0796,
          l: 1.07955,
          c: 1.07956,
          v: 40
        },
        {
          t: 2400,
          o: 1.07957,
          h: 1.07962,
          l: 1.07956,
          c: 1.07961,
          v: 35
        },
        {
          t: 2700,
          o: 1.07961,
          h: 1.0797,
          l: 1.0796,
          c: 1.07966,
          v: 38
        },
        {
          t: 3000,
          o: 1.07966,
          h: 1.07987,
          l: 1.07966,
          c: 1.07987,
          v: 78
        },
        {
          t: 3300,
          o: 1.07987,
          h: 1.07989,
          l: 1.07974,
          c: 1.07976,
          v: 92
        },
        {
          t: 3600,
          o: 1.07977,
          h: 1.07988,
          l: 1.07973,
          c: 1.07978,
          v: 76
        },
        {
          t: 3900,
          o: 1.07977,
          h: 1.07987,
          l: 1.07975,
          c: 1.0798,
          v: 104
        },
        {
          t: 4200,
          o: 1.07981,
          h: 1.07982,
          l: 1.0797,
          c: 1.0797,
          v: 72
        },
        {
          t: 4500,
          o: 1.0797,
          h: 1.07991,
          l: 1.07968,
          c: 1.0799,
          v: 88
        },
        {
          t: 4800,
          o: 1.0799,
          h: 1.08002,
          l: 1.07987,
          c: 1.08002,
          v: 79
        },
        {
          t: 5100,
          o: 1.08006,
          h: 1.08015,
          l: 1.07995,
          c: 1.07999,
          v: 80
        },
        {
          t: 5400,
          o: 1.08002,
          h: 1.08022,
          l: 1.08,
          c: 1.08022,
          v: 92
        },
        {
          t: 5700,
          o: 1.08021,
          h: 1.08029,
          l: 1.0802,
          c: 1.08024,
          v: 97
        },
        {
          t: 6000,
          o: 1.08025,
          h: 1.08029,
          l: 1.08017,
          c: 1.08024,
          v: 89
        },
        {
          t: 6300,
          o: 1.08023,
          h: 1.0803,
          l: 1.0802,
          c: 1.08028,
          v: 61
        },
        {
          t: 6600,
          o: 1.08027,
          h: 1.08031,
          l: 1.08022,
          c: 1.08026,
          v: 68
        },
        {
          t: 6900,
          o: 1.08025,
          h: 1.08032,
          l: 1.08013,
          c: 1.08015,
          v: 70
        },
        {
          t: 7200,
          o: 1.08015,
          h: 1.08023,
          l: 1.07995,
          c: 1.07996,
          v: 95
        },
        {
          t: 7500,
          o: 1.07996,
          h: 1.08016,
          l: 1.07995,
          c: 1.08016,
          v: 28
        },
        {
          t: 7800,
          o: 1.08017,
          h: 1.08024,
          l: 1.08016,
          c: 1.08023,
          v: 80
        },
        {
          t: 8100,
          o: 1.08023,
          h: 1.08023,
          l: 1.0801,
          c: 1.0801,
          v: 85
        },
        {
          t: 8400,
          o: 1.08011,
          h: 1.08017,
          l: 1.08005,
          c: 1.08006,
          v: 56
        },
        {
          t: 8700,
          o: 1.08006,
          h: 1.08017,
          l: 1.07998,
          c: 1.07998,
          v: 77
        },
        {
          t: 9000,
          o: 1.07999,
          h: 1.08002,
          l: 1.07984,
          c: 1.07992,
          v: 85
        },
        {
          t: 9300,
          o: 1.07993,
          h: 1.07993,
          l: 1.07986,
          c: 1.07992,
          v: 63
        },
        {
          t: 9600,
          o: 1.07992,
          h: 1.07992,
          l: 1.07985,
          c: 1.07991,
          v: 71
        },
        {
          t: 9900,
          o: 1.0799,
          h: 1.07993,
          l: 1.07985,
          c: 1.07985,
          v: 34
        },
        {
          t: 10200,
          o: 1.07981,
          h: 1.07988,
          l: 1.0798,
          c: 1.07985,
          v: 41
        },
        {
          t: 10500,
          o: 1.07985,
          h: 1.07991,
          l: 1.07983,
          c: 1.07985,
          v: 48
        },
        {
          t: 10800,
          o: 1.07985,
          h: 1.0799,
          l: 1.07981,
          c: 1.07988,
          v: 42
        },
        {
          t: 11100,
          o: 1.07987,
          h: 1.0799,
          l: 1.07985,
          c: 1.07985,
          v: 25
        },
        {
          t: 11400,
          o: 1.07986,
          h: 1.07998,
          l: 1.07984,
          c: 1.07997,
          v: 56
        },
        {
          t: 11700,
          o: 1.07998,
          h: 1.08012,
          l: 1.07992,
          c: 1.08011,
          v: 76
        },
        {
          t: 12000,
          o: 1.08009,
          h: 1.08012,
          l: 1.08006,
          c: 1.0801,
          v: 22
        },
        {
          t: 12300,
          o: 1.08008,
          h: 1.08011,
          l: 1.08006,
          c: 1.0801,
          v: 30
        },
        {
          t: 12600,
          o: 1.08011,
          h: 1.08011,
          l: 1.07998,
          c: 1.08007,
          v: 36
        },
        {
          t: 12900,
          o: 1.08008,
          h: 1.08011,
          l: 1.08,
          c: 1.08,
          v: 31
        },
        {
          t: 13200,
          o: 1.08,
          h: 1.08007,
          l: 1.07996,
          c: 1.07996,
          v: 55
        },
        {
          t: 13500,
          o: 1.07996,
          h: 1.07998,
          l: 1.07995,
          c: 1.07996,
          v: 27
        },
        {
          t: 13800,
          o: 1.07997,
          h: 1.07999,
          l: 1.07991,
          c: 1.07991,
          v: 26
        },
        {
          t: 14100,
          o: 1.07989,
          h: 1.0799,
          l: 1.07981,
          c: 1.07985,
          v: 50
        },
        {
          t: 14400,
          o: 1.07985,
          h: 1.07985,
          l: 1.07979,
          c: 1.07979,
          v: 37
        },
        {
          t: 14700,
          o: 1.0798,
          h: 1.07986,
          l: 1.07976,
          c: 1.07986,
          v: 30
        },
        {
          t: 15000,
          o: 1.07985,
          h: 1.07986,
          l: 1.07979,
          c: 1.07979,
          v: 25
        },
        {
          t: 15300,
          o: 1.07981,
          h: 1.07985,
          l: 1.07973,
          c: 1.07973,
          v: 48
        },
        {
          t: 15600,
          o: 1.07974,
          h: 1.07974,
          l: 1.07973,
          c: 1.07974,
          v: 14
        },
        {
          t: 15900,
          o: 1.07973,
          h: 1.07982,
          l: 1.07973,
          c: 1.07979,
          v: 21
        },
        {
          t: 16200,
          o: 1.0798,
          h: 1.0798,
          l: 1.07972,
          c: 1.0798,
          v: 28
        },
        {
          t: 16500,
          o: 1.07979,
          h: 1.0798,
          l: 1.07971,
          c: 1.07971,
          v: 30
        },
        {
          t: 16800,
          o: 1.07972,
          h: 1.07972,
          l: 1.07958,
          c: 1.07958,
          v: 43
        },
        {
          t: 17100,
          o: 1.07957,
          h: 1.07961,
          l: 1.07955,
          c: 1.07956,
          v: 47
        },
        {
          t: 17400,
          o: 1.07957,
          h: 1.0796,
          l: 1.07954,
          c: 1.07955,
          v: 47
        },
        {
          t: 17700,
          o: 1.07956,
          h: 1.07971,
          l: 1.07955,
          c: 1.07968,
          v: 65
        },
        {
          t: 18000,
          o: 1.07967,
          h: 1.0797,
          l: 1.07963,
          c: 1.0797,
          v: 39
        },
        {
          t: 18300,
          o: 1.07967,
          h: 1.07971,
          l: 1.07966,
          c: 1.0797,
          v: 30
        },
        {
          t: 18600,
          o: 1.07969,
          h: 1.07969,
          l: 1.07957,
          c: 1.07959,
          v: 28
        },
        {
          t: 18900,
          o: 1.0796,
          h: 1.07961,
          l: 1.07955,
          c: 1.07956,
          v: 69
        },
        {
          t: 19200,
          o: 1.07957,
          h: 1.0796,
          l: 1.07954,
          c: 1.07956,
          v: 34
        },
        {
          t: 19500,
          o: 1.07956,
          h: 1.07957,
          l: 1.07956,
          c: 1.07956,
          v: 10
        },
        {
          t: 19800,
          o: 1.07955,
          h: 1.07957,
          l: 1.07954,
          c: 1.07956,
          v: 39
        },
        {
          t: 20100,
          o: 1.07957,
          h: 1.07959,
          l: 1.07954,
          c: 1.07957,
          v: 29
        },
        {
          t: 20400,
          o: 1.07956,
          h: 1.07957,
          l: 1.07949,
          c: 1.07949,
          v: 35
        },
        {
          t: 20700,
          o: 1.0795,
          h: 1.07951,
          l: 1.0795,
          c: 1.07951,
          v: 14
        },
        {
          t: 21000,
          o: 1.0795,
          h: 1.07952,
          l: 1.07944,
          c: 1.07945,
          v: 35
        },
        {
          t: 21300,
          o: 1.07943,
          h: 1.07952,
          l: 1.07939,
          c: 1.0795,
          v: 107
        },
        {
          t: 21600,
          o: 1.0795,
          h: 1.0795,
          l: 1.07929,
          c: 1.07929,
          v: 58
        },
        {
          t: 21900,
          o: 1.07929,
          h: 1.07929,
          l: 1.07921,
          c: 1.07924,
          v: 51
        },
        {
          t: 22200,
          o: 1.07927,
          h: 1.0793,
          l: 1.07909,
          c: 1.0793,
          v: 91
        },
        {
          t: 22500,
          o: 1.07931,
          h: 1.07931,
          l: 1.07919,
          c: 1.07928,
          v: 110
        },
        {
          t: 22800,
          o: 1.07926,
          h: 1.07942,
          l: 1.07925,
          c: 1.07942,
          v: 52
        },
        {
          t: 23100,
          o: 1.0794,
          h: 1.0795,
          l: 1.07932,
          c: 1.07949,
          v: 70
        },
        {
          t: 23400,
          o: 1.0795,
          h: 1.07956,
          l: 1.07943,
          c: 1.07947,
          v: 92
        },
        {
          t: 23700,
          o: 1.07951,
          h: 1.07957,
          l: 1.07951,
          c: 1.07957,
          v: 38
        },
        {
          t: 24000,
          o: 1.07957,
          h: 1.07962,
          l: 1.07955,
          c: 1.07962,
          v: 61
        },
        {
          t: 24300,
          o: 1.07961,
          h: 1.07976,
          l: 1.07961,
          c: 1.07971,
          v: 40
        },
        {
          t: 24600,
          o: 1.07971,
          h: 1.07978,
          l: 1.07961,
          c: 1.07962,
          v: 82
        },
        {
          t: 24900,
          o: 1.07963,
          h: 1.07982,
          l: 1.0796,
          c: 1.07976,
          v: 75
        },
        {
          t: 25200,
          o: 1.07972,
          h: 1.07978,
          l: 1.0796,
          c: 1.07976,
          v: 109
        },
        {
          t: 25500,
          o: 1.07976,
          h: 1.0798,
          l: 1.07959,
          c: 1.0796,
          v: 100
        },
        {
          t: 25800,
          o: 1.07961,
          h: 1.07975,
          l: 1.07959,
          c: 1.0797,
          v: 97
        },
        {
          t: 26100,
          o: 1.07966,
          h: 1.07985,
          l: 1.0796,
          c: 1.0798,
          v: 119
        },
        {
          t: 26400,
          o: 1.0798,
          h: 1.08001,
          l: 1.07975,
          c: 1.08001,
          v: 141
        },
        {
          t: 26700,
          o: 1.07999,
          h: 1.08011,
          l: 1.0799,
          c: 1.08,
          v: 220
        },
        {
          t: 27000,
          o: 1.08,
          h: 1.08007,
          l: 1.07992,
          c: 1.07995,
          v: 203
        },
        {
          t: 27300,
          o: 1.07994,
          h: 1.08003,
          l: 1.07985,
          c: 1.07995,
          v: 134
        },
        {
          t: 27600,
          o: 1.07995,
          h: 1.0802,
          l: 1.07995,
          c: 1.08017,
          v: 100
        },
        {
          t: 27900,
          o: 1.08016,
          h: 1.08041,
          l: 1.0801,
          c: 1.08021,
          v: 217
        },
        {
          t: 28200,
          o: 1.08022,
          h: 1.08031,
          l: 1.08016,
          c: 1.08021,
          v: 139
        },
        {
          t: 28500,
          o: 1.0802,
          h: 1.08026,
          l: 1.08011,
          c: 1.08015,
          v: 143
        },
        {
          t: 28800,
          o: 1.08015,
          h: 1.08034,
          l: 1.07976,
          c: 1.07976,
          v: 191
        },
        {
          t: 29100,
          o: 1.07976,
          h: 1.07989,
          l: 1.07959,
          c: 1.07967,
          v: 202
        },
        {
          t: 29400,
          o: 1.07965,
          h: 1.07995,
          l: 1.07965,
          c: 1.07995,
          v: 150
        },
        {
          t: 29700,
          o: 1.07995,
          h: 1.08017,
          l: 1.07989,
          c: 1.08011,
          v: 134
        },
        {
          t: 30000,
          o: 1.08011,
          h: 1.08032,
          l: 1.08009,
          c: 1.08024,
          v: 140
        },
        {
          t: 30300,
          o: 1.08025,
          h: 1.08048,
          l: 1.08011,
          c: 1.08036,
          v: 187
        },
        {
          t: 30600,
          o: 1.08037,
          h: 1.08049,
          l: 1.08019,
          c: 1.08045,
          v: 249
        },
        {
          t: 30900,
          o: 1.08045,
          h: 1.08049,
          l: 1.08026,
          c: 1.08045,
          v: 190
        },
        {
          t: 31200,
          o: 1.08043,
          h: 1.08063,
          l: 1.0804,
          c: 1.08046,
          v: 237
        },
        {
          t: 31500,
          o: 1.08048,
          h: 1.08062,
          l: 1.08044,
          c: 1.08048,
          v: 179
        },
        {
          t: 31800,
          o: 1.08046,
          h: 1.08059,
          l: 1.08042,
          c: 1.08043,
          v: 111
        },
        {
          t: 32100,
          o: 1.08044,
          h: 1.08066,
          l: 1.08032,
          c: 1.08033,
          v: 154
        },
        {
          t: 32400,
          o: 1.08035,
          h: 1.08061,
          l: 1.08033,
          c: 1.08051,
          v: 201
        },
        {
          t: 32700,
          o: 1.0805,
          h: 1.08051,
          l: 1.08029,
          c: 1.08044,
          v: 154
        },
        {
          t: 33000,
          o: 1.08045,
          h: 1.08075,
          l: 1.08045,
          c: 1.08062,
          v: 257
        },
        {
          t: 33300,
          o: 1.08062,
          h: 1.08073,
          l: 1.08036,
          c: 1.08046,
          v: 250
        },
        {
          t: 33600,
          o: 1.08045,
          h: 1.08049,
          l: 1.08015,
          c: 1.08016,
          v: 163
        },
        {
          t: 33900,
          o: 1.08015,
          h: 1.08026,
          l: 1.08009,
          c: 1.08017,
          v: 124
        },
        {
          t: 34200,
          o: 1.08017,
          h: 1.08034,
          l: 1.08017,
          c: 1.08025,
          v: 221
        },
        {
          t: 34500,
          o: 1.0803,
          h: 1.08036,
          l: 1.08012,
          c: 1.08023,
          v: 207
        },
        {
          t: 34800,
          o: 1.08023,
          h: 1.08032,
          l: 1.08006,
          c: 1.08011,
          v: 185
        },
        {
          t: 35100,
          o: 1.08012,
          h: 1.08016,
          l: 1.07998,
          c: 1.08007,
          v: 147
        },
        {
          t: 35400,
          o: 1.08009,
          h: 1.08015,
          l: 1.07986,
          c: 1.08008,
          v: 159
        },
        {
          t: 35700,
          o: 1.0801,
          h: 1.08033,
          l: 1.0801,
          c: 1.08027,
          v: 145
        },
        {
          t: 36000,
          o: 1.08026,
          h: 1.08045,
          l: 1.08021,
          c: 1.08042,
          v: 194
        },
        {
          t: 36300,
          o: 1.08041,
          h: 1.08055,
          l: 1.08036,
          c: 1.08046,
          v: 123
        },
        {
          t: 36600,
          o: 1.08041,
          h: 1.08052,
          l: 1.08037,
          c: 1.08042,
          v: 160
        },
        {
          t: 36900,
          o: 1.08042,
          h: 1.08043,
          l: 1.08025,
          c: 1.08032,
          v: 118
        },
        {
          t: 37200,
          o: 1.08032,
          h: 1.08032,
          l: 1.08006,
          c: 1.08006,
          v: 130
        },
        {
          t: 37500,
          o: 1.08007,
          h: 1.08014,
          l: 1.07983,
          c: 1.07993,
          v: 157
        },
        {
          t: 37800,
          o: 1.07993,
          h: 1.08011,
          l: 1.07988,
          c: 1.08001,
          v: 162
        },
        {
          t: 38100,
          o: 1.08004,
          h: 1.08023,
          l: 1.07997,
          c: 1.08022,
          v: 146
        },
        {
          t: 38400,
          o: 1.08022,
          h: 1.08048,
          l: 1.08017,
          c: 1.08048,
          v: 111
        },
        {
          t: 38700,
          o: 1.08048,
          h: 1.08048,
          l: 1.08003,
          c: 1.0801,
          v: 168
        },
        {
          t: 39000,
          o: 1.0801,
          h: 1.08021,
          l: 1.07995,
          c: 1.08005,
          v: 151
        },
        {
          t: 39300,
          o: 1.08005,
          h: 1.08017,
          l: 1.08,
          c: 1.08,
          v: 96
        },
        {
          t: 39600,
          o: 1.08002,
          h: 1.08012,
          l: 1.07987,
          c: 1.07991,
          v: 153
        },
        {
          t: 39900,
          o: 1.0799,
          h: 1.07994,
          l: 1.07965,
          c: 1.07969,
          v: 149
        },
        {
          t: 40200,
          o: 1.0797,
          h: 1.07976,
          l: 1.07941,
          c: 1.07945,
          v: 178
        },
        {
          t: 40500,
          o: 1.07941,
          h: 1.0795,
          l: 1.07927,
          c: 1.07946,
          v: 133
        },
        {
          t: 40800,
          o: 1.07947,
          h: 1.0795,
          l: 1.07936,
          c: 1.07941,
          v: 90
        },
        {
          t: 41100,
          o: 1.07942,
          h: 1.07951,
          l: 1.07931,
          c: 1.07936,
          v: 132
        },
        {
          t: 41400,
          o: 1.07937,
          h: 1.07937,
          l: 1.07878,
          c: 1.07882,
          v: 212
        },
        {
          t: 41700,
          o: 1.07882,
          h: 1.07899,
          l: 1.07866,
          c: 1.07866,
          v: 228
        },
        {
          t: 42000,
          o: 1.0787,
          h: 1.07879,
          l: 1.07842,
          c: 1.07878,
          v: 253
        },
        {
          t: 42300,
          o: 1.07875,
          h: 1.07894,
          l: 1.07875,
          c: 1.07892,
          v: 116
        },
        {
          t: 42600,
          o: 1.07892,
          h: 1.07924,
          l: 1.07892,
          c: 1.07913,
          v: 172
        },
        {
          t: 42900,
          o: 1.07911,
          h: 1.07926,
          l: 1.0789,
          c: 1.07911,
          v: 229
        },
        {
          t: 43200,
          o: 1.07912,
          h: 1.07937,
          l: 1.07901,
          c: 1.0793,
          v: 232
        },
        {
          t: 43500,
          o: 1.07931,
          h: 1.07944,
          l: 1.07921,
          c: 1.07943,
          v: 170
        },
        {
          t: 43800,
          o: 1.07942,
          h: 1.07972,
          l: 1.07938,
          c: 1.07958,
          v: 212
        },
        {
          t: 44100,
          o: 1.07957,
          h: 1.07962,
          l: 1.07945,
          c: 1.07953,
          v: 122
        },
        {
          t: 44400,
          o: 1.07954,
          h: 1.07975,
          l: 1.07949,
          c: 1.0797,
          v: 121
        },
        {
          t: 44700,
          o: 1.0797,
          h: 1.07991,
          l: 1.07967,
          c: 1.07987,
          v: 138
        },
        {
          t: 45000,
          o: 1.07987,
          h: 1.0799,
          l: 1.07958,
          c: 1.07964,
          v: 152
        },
        {
          t: 45300,
          o: 1.07963,
          h: 1.07985,
          l: 1.07963,
          c: 1.07981,
          v: 93
        },
        {
          t: 45600,
          o: 1.07982,
          h: 1.07983,
          l: 1.07963,
          c: 1.07979,
          v: 113
        },
        {
          t: 45900,
          o: 1.07979,
          h: 1.08008,
          l: 1.07958,
          c: 1.07991,
          v: 165
        },
        {
          t: 46200,
          o: 1.07993,
          h: 1.08028,
          l: 1.07993,
          c: 1.08027,
          v: 187
        },
        {
          t: 46500,
          o: 1.08026,
          h: 1.08033,
          l: 1.07991,
          c: 1.08004,
          v: 261
        },
        {
          t: 46800,
          o: 1.08005,
          h: 1.08006,
          l: 1.07959,
          c: 1.0796,
          v: 175
        },
        {
          t: 47100,
          o: 1.0796,
          h: 1.07993,
          l: 1.07956,
          c: 1.0798,
          v: 140
        },
        {
          t: 47400,
          o: 1.07983,
          h: 1.08003,
          l: 1.0797,
          c: 1.0799,
          v: 137
        },
        {
          t: 47700,
          o: 1.07991,
          h: 1.08004,
          l: 1.07975,
          c: 1.07976,
          v: 170
        },
        {
          t: 48000,
          o: 1.07978,
          h: 1.08007,
          l: 1.07972,
          c: 1.08003,
          v: 255
        },
        {
          t: 48300,
          o: 1.08005,
          h: 1.08006,
          l: 1.07985,
          c: 1.07989,
          v: 191
        },
        {
          t: 48600,
          o: 1.07988,
          h: 1.0799,
          l: 1.07951,
          c: 1.07974,
          v: 269
        },
        {
          t: 48900,
          o: 1.07972,
          h: 1.07972,
          l: 1.07919,
          c: 1.07934,
          v: 276
        },
        {
          t: 49200,
          o: 1.07935,
          h: 1.0795,
          l: 1.07896,
          c: 1.07915,
          v: 283
        },
        {
          t: 49500,
          o: 1.07916,
          h: 1.07937,
          l: 1.07908,
          c: 1.07935,
          v: 242
        },
        {
          t: 49800,
          o: 1.07931,
          h: 1.07966,
          l: 1.0791,
          c: 1.07965,
          v: 237
        },
        {
          t: 50100,
          o: 1.07964,
          h: 1.07965,
          l: 1.07922,
          c: 1.0793,
          v: 237
        },
        {
          t: 50400,
          o: 1.0793,
          h: 1.07932,
          l: 1.07884,
          c: 1.07903,
          v: 232
        },
        {
          t: 50700,
          o: 1.079,
          h: 1.07933,
          l: 1.07876,
          c: 1.0789,
          v: 318
        },
        {
          t: 51000,
          o: 1.0789,
          h: 1.07923,
          l: 1.07888,
          c: 1.07906,
          v: 263
        },
        {
          t: 51300,
          o: 1.07905,
          h: 1.0793,
          l: 1.07889,
          c: 1.07922,
          v: 254
        },
        {
          t: 51600,
          o: 1.07923,
          h: 1.07928,
          l: 1.07905,
          c: 1.07916,
          v: 208
        },
        {
          t: 51900,
          o: 1.07922,
          h: 1.07928,
          l: 1.07906,
          c: 1.07925,
          v: 219
        },
        {
          t: 52200,
          o: 1.07927,
          h: 1.07931,
          l: 1.0787,
          c: 1.07871,
          v: 397
        },
        {
          t: 52500,
          o: 1.07873,
          h: 1.07905,
          l: 1.0787,
          c: 1.0789,
          v: 286
        },
        {
          t: 52800,
          o: 1.0789,
          h: 1.07891,
          l: 1.07853,
          c: 1.07891,
          v: 245
        },
        {
          t: 53100,
          o: 1.07892,
          h: 1.07901,
          l: 1.07875,
          c: 1.0788,
          v: 208
        },
        {
          t: 53400,
          o: 1.07881,
          h: 1.07897,
          l: 1.07858,
          c: 1.07863,
          v: 228
        },
        {
          t: 53700,
          o: 1.07863,
          h: 1.07878,
          l: 1.0783,
          c: 1.07865,
          v: 358
        },
        {
          t: 54000,
          o: 1.07865,
          h: 1.07942,
          l: 1.07865,
          c: 1.079,
          v: 357
        },
        {
          t: 54300,
          o: 1.079,
          h: 1.079,
          l: 1.07871,
          c: 1.07885,
          v: 244
        },
        {
          t: 54600,
          o: 1.07886,
          h: 1.07899,
          l: 1.07872,
          c: 1.07888,
          v: 220
        },
        {
          t: 54900,
          o: 1.07886,
          h: 1.07893,
          l: 1.07855,
          c: 1.07878,
          v: 235
        },
        {
          t: 55200,
          o: 1.07881,
          h: 1.07895,
          l: 1.07853,
          c: 1.07859,
          v: 241
        },
        {
          t: 55500,
          o: 1.07858,
          h: 1.07863,
          l: 1.07836,
          c: 1.07837,
          v: 264
        },
        {
          t: 55800,
          o: 1.07836,
          h: 1.07855,
          l: 1.07824,
          c: 1.07833,
          v: 274
        },
        {
          t: 56100,
          o: 1.07833,
          h: 1.07868,
          l: 1.07817,
          c: 1.07868,
          v: 243
        },
        {
          t: 56400,
          o: 1.07869,
          h: 1.07879,
          l: 1.07841,
          c: 1.07852,
          v: 240
        },
        {
          t: 56700,
          o: 1.07853,
          h: 1.07872,
          l: 1.07847,
          c: 1.07865,
          v: 255
        },
        {
          t: 57000,
          o: 1.07863,
          h: 1.07895,
          l: 1.07847,
          c: 1.07895,
          v: 230
        },
        {
          t: 57300,
          o: 1.07898,
          h: 1.07943,
          l: 1.07898,
          c: 1.07909,
          v: 319
        },
        {
          t: 57600,
          o: 1.07908,
          h: 1.07913,
          l: 1.07865,
          c: 1.07877,
          v: 286
        },
        {
          t: 57900,
          o: 1.07877,
          h: 1.07899,
          l: 1.07871,
          c: 1.07888,
          v: 247
        },
        {
          t: 58200,
          o: 1.0789,
          h: 1.07935,
          l: 1.0789,
          c: 1.07933,
          v: 247
        },
        {
          t: 58500,
          o: 1.07932,
          h: 1.07964,
          l: 1.07923,
          c: 1.0794,
          v: 283
        },
        {
          t: 58800,
          o: 1.07937,
          h: 1.0795,
          l: 1.079,
          c: 1.0792,
          v: 275
        },
        {
          t: 59100,
          o: 1.07918,
          h: 1.07972,
          l: 1.07906,
          c: 1.07955,
          v: 287
        },
        {
          t: 59400,
          o: 1.07956,
          h: 1.07963,
          l: 1.07906,
          c: 1.07916,
          v: 274
        },
        {
          t: 59700,
          o: 1.07916,
          h: 1.07917,
          l: 1.07885,
          c: 1.07904,
          v: 336
        },
        {
          t: 60000,
          o: 1.07901,
          h: 1.07958,
          l: 1.07895,
          c: 1.07957,
          v: 263
        },
        {
          t: 60300,
          o: 1.07956,
          h: 1.07983,
          l: 1.07948,
          c: 1.07976,
          v: 230
        },
        {
          t: 60600,
          o: 1.07975,
          h: 1.07978,
          l: 1.07935,
          c: 1.07936,
          v: 221
        },
        {
          t: 60900,
          o: 1.07935,
          h: 1.07937,
          l: 1.07921,
          c: 1.07928,
          v: 160
        },
        {
          t: 61200,
          o: 1.07928,
          h: 1.07932,
          l: 1.07902,
          c: 1.07925,
          v: 185
        },
        {
          t: 61500,
          o: 1.07924,
          h: 1.07927,
          l: 1.0789,
          c: 1.0789,
          v: 143
        },
        {
          t: 61800,
          o: 1.0789,
          h: 1.07892,
          l: 1.07865,
          c: 1.07868,
          v: 129
        },
        {
          t: 62100,
          o: 1.0787,
          h: 1.07903,
          l: 1.07856,
          c: 1.07898,
          v: 161
        },
        {
          t: 62400,
          o: 1.07898,
          h: 1.07913,
          l: 1.07897,
          c: 1.07905,
          v: 128
        },
        {
          t: 62700,
          o: 1.07906,
          h: 1.07928,
          l: 1.07905,
          c: 1.07918,
          v: 129
        },
        {
          t: 63000,
          o: 1.07918,
          h: 1.0793,
          l: 1.0791,
          c: 1.07914,
          v: 184
        },
        {
          t: 63300,
          o: 1.07914,
          h: 1.07924,
          l: 1.07896,
          c: 1.07899,
          v: 130
        },
        {
          t: 63600,
          o: 1.07899,
          h: 1.07938,
          l: 1.07898,
          c: 1.07938,
          v: 118
        },
        {
          t: 63900,
          o: 1.07937,
          h: 1.07953,
          l: 1.07935,
          c: 1.07953,
          v: 98
        },
        {
          t: 64200,
          o: 1.07952,
          h: 1.07954,
          l: 1.07937,
          c: 1.07938,
          v: 151
        },
        {
          t: 64500,
          o: 1.07938,
          h: 1.07938,
          l: 1.07914,
          c: 1.07915,
          v: 181
        },
        {
          t: 64800,
          o: 1.07912,
          h: 1.07919,
          l: 1.07896,
          c: 1.07907,
          v: 168
        },
        {
          t: 65100,
          o: 1.07907,
          h: 1.07922,
          l: 1.07904,
          c: 1.07919,
          v: 104
        },
        {
          t: 65400,
          o: 1.07917,
          h: 1.07928,
          l: 1.07915,
          c: 1.07919,
          v: 93
        },
        {
          t: 65700,
          o: 1.07918,
          h: 1.07935,
          l: 1.0791,
          c: 1.07929,
          v: 77
        },
        {
          t: 66000,
          o: 1.07929,
          h: 1.07929,
          l: 1.07913,
          c: 1.07918,
          v: 85
        },
        {
          t: 66300,
          o: 1.07918,
          h: 1.07932,
          l: 1.07913,
          c: 1.07923,
          v: 51
        },
        {
          t: 66600,
          o: 1.07923,
          h: 1.07933,
          l: 1.07914,
          c: 1.07915,
          v: 85
        },
        {
          t: 66900,
          o: 1.07917,
          h: 1.07931,
          l: 1.07916,
          c: 1.07927,
          v: 64
        },
        {
          t: 67200,
          o: 1.07926,
          h: 1.07931,
          l: 1.07924,
          c: 1.07928,
          v: 40
        },
        {
          t: 67500,
          o: 1.07926,
          h: 1.07939,
          l: 1.07919,
          c: 1.07938,
          v: 95
        },
        {
          t: 67800,
          o: 1.07938,
          h: 1.07949,
          l: 1.07933,
          c: 1.07934,
          v: 124
        },
        {
          t: 68100,
          o: 1.07933,
          h: 1.07979,
          l: 1.07933,
          c: 1.07975,
          v: 127
        },
        {
          t: 68400,
          o: 1.07974,
          h: 1.07979,
          l: 1.07937,
          c: 1.07939,
          v: 255
        },
        {
          t: 68700,
          o: 1.07938,
          h: 1.07956,
          l: 1.07929,
          c: 1.07936,
          v: 215
        },
        {
          t: 69000,
          o: 1.07943,
          h: 1.07948,
          l: 1.07921,
          c: 1.07947,
          v: 229
        },
        {
          t: 69300,
          o: 1.07944,
          h: 1.07951,
          l: 1.07927,
          c: 1.07944,
          v: 175
        },
        {
          t: 69600,
          o: 1.07945,
          h: 1.07968,
          l: 1.07943,
          c: 1.07968,
          v: 111
        },
        {
          t: 69900,
          o: 1.07968,
          h: 1.07978,
          l: 1.07957,
          c: 1.07977,
          v: 143
        },
        {
          t: 70200,
          o: 1.07978,
          h: 1.08,
          l: 1.0797,
          c: 1.07992,
          v: 114
        },
        {
          t: 70500,
          o: 1.07991,
          h: 1.07992,
          l: 1.07976,
          c: 1.07987,
          v: 132
        },
        {
          t: 70800,
          o: 1.07981,
          h: 1.08004,
          l: 1.0798,
          c: 1.08003,
          v: 179
        },
        {
          t: 71100,
          o: 1.08002,
          h: 1.08003,
          l: 1.07984,
          c: 1.07988,
          v: 255
        },
        {
          t: 71400,
          o: 1.07989,
          h: 1.07989,
          l: 1.0797,
          c: 1.07975,
          v: 123
        },
        {
          t: 71700,
          o: 1.07975,
          h: 1.07976,
          l: 1.07953,
          c: 1.0796,
          v: 189
        },
        {
          t: 72000,
          o: 1.0796,
          h: 1.07994,
          l: 1.07953,
          c: 1.07993,
          v: 172
        },
        {
          t: 72300,
          o: 1.07992,
          h: 1.08007,
          l: 1.07981,
          c: 1.08005,
          v: 123
        },
        {
          t: 72600,
          o: 1.08004,
          h: 1.08022,
          l: 1.08003,
          c: 1.08015,
          v: 131
        },
        {
          t: 72900,
          o: 1.08015,
          h: 1.08055,
          l: 1.08012,
          c: 1.08044,
          v: 131
        },
        {
          t: 73200,
          o: 1.08045,
          h: 1.08053,
          l: 1.08036,
          c: 1.08047,
          v: 154
        },
        {
          t: 73500,
          o: 1.08045,
          h: 1.08053,
          l: 1.08025,
          c: 1.08035,
          v: 139
        },
        {
          t: 73800,
          o: 1.08035,
          h: 1.08042,
          l: 1.08021,
          c: 1.08028,
          v: 83
        },
        {
          t: 74100,
          o: 1.08028,
          h: 1.08043,
          l: 1.08022,
          c: 1.08041,
          v: 154
        },
        {
          t: 74400,
          o: 1.0804,
          h: 1.08054,
          l: 1.08031,
          c: 1.08054,
          v: 177
        },
        {
          t: 74700,
          o: 1.08055,
          h: 1.08083,
          l: 1.08053,
          c: 1.0808,
          v: 165
        },
        {
          t: 75000,
          o: 1.08082,
          h: 1.08082,
          l: 1.08063,
          c: 1.08068,
          v: 152
        },
        {
          t: 75300,
          o: 1.08069,
          h: 1.08095,
          l: 1.08067,
          c: 1.08095,
          v: 271
        },
        {
          t: 75600,
          o: 1.08099,
          h: 1.08099,
          l: 1.0807,
          c: 1.08077,
          v: 189
        },
        {
          t: 75900,
          o: 1.08076,
          h: 1.0811,
          l: 1.08075,
          c: 1.08083,
          v: 147
        },
        {
          t: 76200,
          o: 1.08085,
          h: 1.08095,
          l: 1.08081,
          c: 1.08092,
          v: 61
        },
        {
          t: 76500,
          o: 1.0809,
          h: 1.08092,
          l: 1.0808,
          c: 1.08089,
          v: 83
        },
        {
          t: 76800,
          o: 1.0809,
          h: 1.08113,
          l: 1.08088,
          c: 1.08111,
          v: 68
        },
        {
          t: 77100,
          o: 1.0811,
          h: 1.0811,
          l: 1.0808,
          c: 1.08089,
          v: 88
        },
        {
          t: 77400,
          o: 1.08091,
          h: 1.08093,
          l: 1.0807,
          c: 1.08087,
          v: 85
        },
        {
          t: 77700,
          o: 1.08086,
          h: 1.08098,
          l: 1.08085,
          c: 1.08094,
          v: 69
        },
        {
          t: 78000,
          o: 1.08093,
          h: 1.08093,
          l: 1.08078,
          c: 1.08079,
          v: 39
        },
        {
          t: 78300,
          o: 1.08081,
          h: 1.08081,
          l: 1.08073,
          c: 1.08077,
          v: 79
        },
        {
          t: 78600,
          o: 1.08075,
          h: 1.08078,
          l: 1.08064,
          c: 1.08066,
          v: 66
        },
        {
          t: 78900,
          o: 1.08067,
          h: 1.08073,
          l: 1.08026,
          c: 1.08035,
          v: 76
        },
        {
          t: 79200,
          o: 1.08033,
          h: 1.08067,
          l: 1.08018,
          c: 1.08067,
          v: 96
        },
        {
          t: 79500,
          o: 1.08066,
          h: 1.08074,
          l: 1.08032,
          c: 1.08061,
          v: 126
        },
        {
          t: 79800,
          o: 1.08061,
          h: 1.08074,
          l: 1.08034,
          c: 1.08067,
          v: 58
        },
        {
          t: 80100,
          o: 1.08065,
          h: 1.08068,
          l: 1.08039,
          c: 1.08059,
          v: 98
        },
        {
          t: 80400,
          o: 1.08061,
          h: 1.08063,
          l: 1.08056,
          c: 1.08063,
          v: 64
        },
        {
          t: 80700,
          o: 1.08063,
          h: 1.08063,
          l: 1.08059,
          c: 1.08063,
          v: 13
        },
        {
          t: 81000,
          o: 1.08063,
          h: 1.08068,
          l: 1.08062,
          c: 1.08064,
          v: 86
        },
        {
          t: 81300,
          o: 1.08062,
          h: 1.08064,
          l: 1.08057,
          c: 1.0806,
          v: 73
        },
        {
          t: 81600,
          o: 1.08061,
          h: 1.08085,
          l: 1.08058,
          c: 1.08085,
          v: 91
        },
        {
          t: 81900,
          o: 1.08085,
          h: 1.08086,
          l: 1.08026,
          c: 1.08069,
          v: 81
        },
        {
          t: 82200,
          o: 1.0807,
          h: 1.08073,
          l: 1.08069,
          c: 1.08071,
          v: 55
        },
        {
          t: 82500,
          o: 1.08072,
          h: 1.0808,
          l: 1.08069,
          c: 1.08071,
          v: 62
        },
        {
          t: 82800,
          o: 1.08065,
          h: 1.08081,
          l: 1.08062,
          c: 1.08075,
          v: 118
        },
        {
          t: 83100,
          o: 1.08076,
          h: 1.08084,
          l: 1.08072,
          c: 1.0808,
          v: 57
        },
        {
          t: 83400,
          o: 1.08081,
          h: 1.08083,
          l: 1.08076,
          c: 1.08078,
          v: 53
        },
        {
          t: 83700,
          o: 1.08078,
          h: 1.08078,
          l: 1.08077,
          c: 1.08077,
          v: 7
        },
        {
          t: 84000,
          o: 1.08078,
          h: 1.08078,
          l: 1.08073,
          c: 1.08077,
          v: 83
        },
        {
          t: 84300,
          o: 1.08077,
          h: 1.08079,
          l: 1.08076,
          c: 1.08077,
          v: 41
        },
        {
          t: 84600,
          o: 1.08077,
          h: 1.0808,
          l: 1.08071,
          c: 1.08072,
          v: 53
        },
        {
          t: 84900,
          o: 1.08073,
          h: 1.08073,
          l: 1.08065,
          c: 1.08069,
          v: 53
        },
        {
          t: 85200,
          o: 1.08067,
          h: 1.08077,
          l: 1.08066,
          c: 1.08077,
          v: 39
        },
        {
          t: 85500,
          o: 1.08079,
          h: 1.0808,
          l: 1.08076,
          c: 1.08079,
          v: 31
        },
        {
          t: 85800,
          o: 1.08081,
          h: 1.08104,
          l: 1.0808,
          c: 1.08098,
          v: 61
        },
        {
          t: 86100,
          o: 1.08099,
          h: 1.08099,
          l: 1.08092,
          c: 1.08092,
          v: 42
        }
      ]
    },
    {
      d: 1582156800,
      b: [
        {
          t: 0,
          o: 1.08092,
          h: 1.08097,
          l: 1.08041,
          c: 1.08056,
          v: 229
        },
        {
          t: 300,
          o: 1.08053,
          h: 1.08085,
          l: 1.08047,
          c: 1.08083,
          v: 177
        },
        {
          t: 600,
          o: 1.08083,
          h: 1.08127,
          l: 1.08081,
          c: 1.0812,
          v: 183
        },
        {
          t: 900,
          o: 1.0812,
          h: 1.08148,
          l: 1.08109,
          c: 1.08141,
          v: 217
        },
        {
          t: 1200,
          o: 1.08142,
          h: 1.08143,
          l: 1.08114,
          c: 1.08116,
          v: 129
        },
        {
          t: 1500,
          o: 1.08116,
          h: 1.08131,
          l: 1.0811,
          c: 1.0811,
          v: 69
        },
        {
          t: 1800,
          o: 1.0811,
          h: 1.08123,
          l: 1.08084,
          c: 1.08112,
          v: 207
        },
        {
          t: 2100,
          o: 1.08112,
          h: 1.08123,
          l: 1.08105,
          c: 1.0811,
          v: 115
        },
        {
          t: 2400,
          o: 1.08108,
          h: 1.08118,
          l: 1.08103,
          c: 1.08107,
          v: 121
        },
        {
          t: 2700,
          o: 1.08106,
          h: 1.08109,
          l: 1.08099,
          c: 1.08104,
          v: 84
        },
        {
          t: 3000,
          o: 1.08105,
          h: 1.08115,
          l: 1.0809,
          c: 1.08115,
          v: 101
        },
        {
          t: 3300,
          o: 1.08113,
          h: 1.08116,
          l: 1.08105,
          c: 1.08107,
          v: 133
        },
        {
          t: 3600,
          o: 1.08108,
          h: 1.08117,
          l: 1.08106,
          c: 1.08115,
          v: 93
        },
        {
          t: 3900,
          o: 1.08116,
          h: 1.08128,
          l: 1.08109,
          c: 1.08116,
          v: 104
        },
        {
          t: 4200,
          o: 1.08117,
          h: 1.08117,
          l: 1.08086,
          c: 1.08086,
          v: 125
        },
        {
          t: 4500,
          o: 1.08085,
          h: 1.08103,
          l: 1.0808,
          c: 1.08091,
          v: 142
        },
        {
          t: 4800,
          o: 1.08091,
          h: 1.08092,
          l: 1.08055,
          c: 1.08062,
          v: 98
        },
        {
          t: 5100,
          o: 1.0806,
          h: 1.08061,
          l: 1.08047,
          c: 1.08056,
          v: 105
        },
        {
          t: 5400,
          o: 1.08054,
          h: 1.08062,
          l: 1.08025,
          c: 1.08035,
          v: 187
        },
        {
          t: 5700,
          o: 1.08036,
          h: 1.08042,
          l: 1.08016,
          c: 1.08037,
          v: 168
        },
        {
          t: 6000,
          o: 1.08033,
          h: 1.08048,
          l: 1.07999,
          c: 1.08005,
          v: 139
        },
        {
          t: 6300,
          o: 1.08006,
          h: 1.08028,
          l: 1.08006,
          c: 1.08026,
          v: 117
        },
        {
          t: 6600,
          o: 1.08024,
          h: 1.08032,
          l: 1.07986,
          c: 1.0799,
          v: 158
        },
        {
          t: 6900,
          o: 1.07992,
          h: 1.08007,
          l: 1.07973,
          c: 1.07997,
          v: 205
        },
        {
          t: 7200,
          o: 1.07997,
          h: 1.08017,
          l: 1.0799,
          c: 1.08014,
          v: 138
        },
        {
          t: 7500,
          o: 1.08013,
          h: 1.08015,
          l: 1.08001,
          c: 1.08006,
          v: 116
        },
        {
          t: 7800,
          o: 1.08006,
          h: 1.08013,
          l: 1.07988,
          c: 1.07992,
          v: 106
        },
        {
          t: 8100,
          o: 1.07993,
          h: 1.07997,
          l: 1.07981,
          c: 1.07984,
          v: 103
        },
        {
          t: 8400,
          o: 1.07982,
          h: 1.07993,
          l: 1.0798,
          c: 1.07992,
          v: 83
        },
        {
          t: 8700,
          o: 1.07992,
          h: 1.07995,
          l: 1.07986,
          c: 1.07995,
          v: 86
        },
        {
          t: 9000,
          o: 1.07994,
          h: 1.08005,
          l: 1.07965,
          c: 1.07967,
          v: 122
        },
        {
          t: 9300,
          o: 1.07968,
          h: 1.0797,
          l: 1.07936,
          c: 1.07958,
          v: 258
        },
        {
          t: 9600,
          o: 1.07961,
          h: 1.07978,
          l: 1.07961,
          c: 1.07966,
          v: 175
        },
        {
          t: 9900,
          o: 1.07965,
          h: 1.07974,
          l: 1.0796,
          c: 1.07966,
          v: 127
        },
        {
          t: 10200,
          o: 1.07965,
          h: 1.07965,
          l: 1.07941,
          c: 1.07941,
          v: 138
        },
        {
          t: 10500,
          o: 1.07942,
          h: 1.07948,
          l: 1.07921,
          c: 1.07923,
          v: 135
        },
        {
          t: 10800,
          o: 1.07923,
          h: 1.07952,
          l: 1.07912,
          c: 1.07951,
          v: 137
        },
        {
          t: 11100,
          o: 1.07948,
          h: 1.07969,
          l: 1.07944,
          c: 1.07967,
          v: 178
        },
        {
          t: 11400,
          o: 1.07966,
          h: 1.07981,
          l: 1.07962,
          c: 1.07981,
          v: 156
        },
        {
          t: 11700,
          o: 1.07982,
          h: 1.07997,
          l: 1.07976,
          c: 1.07987,
          v: 89
        },
        {
          t: 12000,
          o: 1.07986,
          h: 1.07996,
          l: 1.07976,
          c: 1.0798,
          v: 116
        },
        {
          t: 12300,
          o: 1.0798,
          h: 1.07984,
          l: 1.07957,
          c: 1.07983,
          v: 151
        },
        {
          t: 12600,
          o: 1.07982,
          h: 1.07987,
          l: 1.0796,
          c: 1.0796,
          v: 160
        },
        {
          t: 12900,
          o: 1.0796,
          h: 1.07972,
          l: 1.07953,
          c: 1.07972,
          v: 80
        },
        {
          t: 13200,
          o: 1.07975,
          h: 1.07993,
          l: 1.07968,
          c: 1.07973,
          v: 140
        },
        {
          t: 13500,
          o: 1.07972,
          h: 1.07991,
          l: 1.07968,
          c: 1.07985,
          v: 78
        },
        {
          t: 13800,
          o: 1.07982,
          h: 1.07983,
          l: 1.07961,
          c: 1.07971,
          v: 79
        },
        {
          t: 14100,
          o: 1.0797,
          h: 1.07978,
          l: 1.07966,
          c: 1.07966,
          v: 47
        },
        {
          t: 14400,
          o: 1.07969,
          h: 1.07971,
          l: 1.07957,
          c: 1.07968,
          v: 72
        },
        {
          t: 14700,
          o: 1.07969,
          h: 1.0797,
          l: 1.07963,
          c: 1.07966,
          v: 53
        },
        {
          t: 15000,
          o: 1.07967,
          h: 1.07967,
          l: 1.07956,
          c: 1.07961,
          v: 22
        },
        {
          t: 15300,
          o: 1.0796,
          h: 1.07964,
          l: 1.07959,
          c: 1.07959,
          v: 33
        },
        {
          t: 15600,
          o: 1.0796,
          h: 1.07969,
          l: 1.0796,
          c: 1.07966,
          v: 23
        },
        {
          t: 15900,
          o: 1.07966,
          h: 1.07969,
          l: 1.0796,
          c: 1.07966,
          v: 55
        },
        {
          t: 16200,
          o: 1.07967,
          h: 1.0797,
          l: 1.07963,
          c: 1.07966,
          v: 65
        },
        {
          t: 16500,
          o: 1.07965,
          h: 1.07971,
          l: 1.07961,
          c: 1.07966,
          v: 32
        },
        {
          t: 16800,
          o: 1.07965,
          h: 1.07977,
          l: 1.07965,
          c: 1.07971,
          v: 62
        },
        {
          t: 17100,
          o: 1.0797,
          h: 1.07971,
          l: 1.07959,
          c: 1.0796,
          v: 34
        },
        {
          t: 17400,
          o: 1.07958,
          h: 1.0797,
          l: 1.07958,
          c: 1.07968,
          v: 42
        },
        {
          t: 17700,
          o: 1.0797,
          h: 1.07982,
          l: 1.07968,
          c: 1.07981,
          v: 84
        },
        {
          t: 18000,
          o: 1.07982,
          h: 1.07986,
          l: 1.0798,
          c: 1.07985,
          v: 39
        },
        {
          t: 18300,
          o: 1.07986,
          h: 1.07986,
          l: 1.07978,
          c: 1.07978,
          v: 39
        },
        {
          t: 18600,
          o: 1.07978,
          h: 1.0798,
          l: 1.07971,
          c: 1.07973,
          v: 64
        },
        {
          t: 18900,
          o: 1.07973,
          h: 1.07978,
          l: 1.07968,
          c: 1.07972,
          v: 52
        },
        {
          t: 19200,
          o: 1.07971,
          h: 1.07974,
          l: 1.07959,
          c: 1.0796,
          v: 47
        },
        {
          t: 19500,
          o: 1.07959,
          h: 1.07964,
          l: 1.07954,
          c: 1.07956,
          v: 32
        },
        {
          t: 19800,
          o: 1.07955,
          h: 1.07965,
          l: 1.07955,
          c: 1.0796,
          v: 62
        },
        {
          t: 20100,
          o: 1.07961,
          h: 1.07967,
          l: 1.0796,
          c: 1.07966,
          v: 43
        },
        {
          t: 20400,
          o: 1.07965,
          h: 1.07967,
          l: 1.07963,
          c: 1.07965,
          v: 20
        },
        {
          t: 20700,
          o: 1.07966,
          h: 1.07972,
          l: 1.07966,
          c: 1.0797,
          v: 52
        },
        {
          t: 21000,
          o: 1.07972,
          h: 1.07972,
          l: 1.07965,
          c: 1.07965,
          v: 22
        },
        {
          t: 21300,
          o: 1.07963,
          h: 1.07965,
          l: 1.07954,
          c: 1.07961,
          v: 106
        },
        {
          t: 21600,
          o: 1.07962,
          h: 1.07967,
          l: 1.07953,
          c: 1.07961,
          v: 110
        },
        {
          t: 21900,
          o: 1.0796,
          h: 1.0797,
          l: 1.0796,
          c: 1.07968,
          v: 63
        },
        {
          t: 22200,
          o: 1.07966,
          h: 1.07968,
          l: 1.0796,
          c: 1.0796,
          v: 57
        },
        {
          t: 22500,
          o: 1.0796,
          h: 1.07962,
          l: 1.07933,
          c: 1.07933,
          v: 121
        },
        {
          t: 22800,
          o: 1.07934,
          h: 1.07935,
          l: 1.07924,
          c: 1.0793,
          v: 71
        },
        {
          t: 23100,
          o: 1.07929,
          h: 1.07935,
          l: 1.07923,
          c: 1.07926,
          v: 119
        },
        {
          t: 23400,
          o: 1.07927,
          h: 1.07929,
          l: 1.07908,
          c: 1.0791,
          v: 124
        },
        {
          t: 23700,
          o: 1.07909,
          h: 1.07911,
          l: 1.07899,
          c: 1.07904,
          v: 95
        },
        {
          t: 24000,
          o: 1.07905,
          h: 1.07912,
          l: 1.07899,
          c: 1.07905,
          v: 86
        },
        {
          t: 24300,
          o: 1.07904,
          h: 1.07906,
          l: 1.07889,
          c: 1.07893,
          v: 97
        },
        {
          t: 24600,
          o: 1.07894,
          h: 1.07895,
          l: 1.07873,
          c: 1.07873,
          v: 159
        },
        {
          t: 24900,
          o: 1.07872,
          h: 1.07893,
          l: 1.07866,
          c: 1.07877,
          v: 134
        },
        {
          t: 25200,
          o: 1.07882,
          h: 1.0791,
          l: 1.07879,
          c: 1.079,
          v: 176
        },
        {
          t: 25500,
          o: 1.07899,
          h: 1.07911,
          l: 1.07873,
          c: 1.07884,
          v: 171
        },
        {
          t: 25800,
          o: 1.07885,
          h: 1.07895,
          l: 1.07885,
          c: 1.0789,
          v: 145
        },
        {
          t: 26100,
          o: 1.0789,
          h: 1.0789,
          l: 1.07844,
          c: 1.07845,
          v: 259
        },
        {
          t: 26400,
          o: 1.07845,
          h: 1.0785,
          l: 1.07775,
          c: 1.07832,
          v: 463
        },
        {
          t: 26700,
          o: 1.07831,
          h: 1.07851,
          l: 1.07822,
          c: 1.07851,
          v: 264
        },
        {
          t: 27000,
          o: 1.0785,
          h: 1.07864,
          l: 1.07834,
          c: 1.07863,
          v: 252
        },
        {
          t: 27300,
          o: 1.07862,
          h: 1.07896,
          l: 1.07862,
          c: 1.07877,
          v: 269
        },
        {
          t: 27600,
          o: 1.07878,
          h: 1.07922,
          l: 1.07872,
          c: 1.07913,
          v: 187
        },
        {
          t: 27900,
          o: 1.07915,
          h: 1.07922,
          l: 1.07906,
          c: 1.0791,
          v: 136
        },
        {
          t: 28200,
          o: 1.07911,
          h: 1.07914,
          l: 1.07874,
          c: 1.07879,
          v: 156
        },
        {
          t: 28500,
          o: 1.07883,
          h: 1.07902,
          l: 1.07881,
          c: 1.07902,
          v: 172
        },
        {
          t: 28800,
          o: 1.07902,
          h: 1.07937,
          l: 1.07884,
          c: 1.0792,
          v: 268
        },
        {
          t: 29100,
          o: 1.07918,
          h: 1.07927,
          l: 1.07893,
          c: 1.07916,
          v: 184
        },
        {
          t: 29400,
          o: 1.0792,
          h: 1.07922,
          l: 1.07894,
          c: 1.07896,
          v: 173
        },
        {
          t: 29700,
          o: 1.07891,
          h: 1.07893,
          l: 1.07844,
          c: 1.07845,
          v: 284
        },
        {
          t: 30000,
          o: 1.07846,
          h: 1.07894,
          l: 1.07844,
          c: 1.07887,
          v: 260
        },
        {
          t: 30300,
          o: 1.07888,
          h: 1.07911,
          l: 1.0788,
          c: 1.07911,
          v: 225
        },
        {
          t: 30600,
          o: 1.07913,
          h: 1.07922,
          l: 1.07896,
          c: 1.0792,
          v: 193
        },
        {
          t: 30900,
          o: 1.0792,
          h: 1.07937,
          l: 1.079,
          c: 1.07921,
          v: 178
        },
        {
          t: 31200,
          o: 1.0792,
          h: 1.07924,
          l: 1.07906,
          c: 1.07913,
          v: 155
        },
        {
          t: 31500,
          o: 1.07911,
          h: 1.07945,
          l: 1.07908,
          c: 1.07945,
          v: 154
        },
        {
          t: 31800,
          o: 1.07946,
          h: 1.07947,
          l: 1.07925,
          c: 1.07933,
          v: 181
        },
        {
          t: 32100,
          o: 1.07933,
          h: 1.07975,
          l: 1.07926,
          c: 1.07963,
          v: 180
        },
        {
          t: 32400,
          o: 1.07962,
          h: 1.07984,
          l: 1.07952,
          c: 1.07972,
          v: 221
        },
        {
          t: 32700,
          o: 1.07975,
          h: 1.0798,
          l: 1.07957,
          c: 1.07962,
          v: 152
        },
        {
          t: 33000,
          o: 1.07962,
          h: 1.07983,
          l: 1.07936,
          c: 1.07967,
          v: 187
        },
        {
          t: 33300,
          o: 1.07965,
          h: 1.07992,
          l: 1.07964,
          c: 1.0799,
          v: 185
        },
        {
          t: 33600,
          o: 1.0799,
          h: 1.08047,
          l: 1.07988,
          c: 1.08041,
          v: 243
        },
        {
          t: 33900,
          o: 1.08041,
          h: 1.0805,
          l: 1.08025,
          c: 1.0804,
          v: 247
        },
        {
          t: 34200,
          o: 1.08035,
          h: 1.0804,
          l: 1.08018,
          c: 1.08025,
          v: 243
        },
        {
          t: 34500,
          o: 1.08024,
          h: 1.0803,
          l: 1.08017,
          c: 1.08027,
          v: 153
        },
        {
          t: 34800,
          o: 1.08028,
          h: 1.08057,
          l: 1.08028,
          c: 1.08043,
          v: 203
        },
        {
          t: 35100,
          o: 1.08043,
          h: 1.08049,
          l: 1.08026,
          c: 1.08029,
          v: 144
        },
        {
          t: 35400,
          o: 1.08029,
          h: 1.08042,
          l: 1.07994,
          c: 1.08001,
          v: 207
        },
        {
          t: 35700,
          o: 1.08,
          h: 1.08004,
          l: 1.07969,
          c: 1.07995,
          v: 225
        },
        {
          t: 36000,
          o: 1.07993,
          h: 1.08013,
          l: 1.07959,
          c: 1.07959,
          v: 237
        },
        {
          t: 36300,
          o: 1.07959,
          h: 1.0796,
          l: 1.07925,
          c: 1.07931,
          v: 203
        },
        {
          t: 36600,
          o: 1.07933,
          h: 1.07961,
          l: 1.07926,
          c: 1.07952,
          v: 208
        },
        {
          t: 36900,
          o: 1.07951,
          h: 1.07951,
          l: 1.07915,
          c: 1.07936,
          v: 177
        },
        {
          t: 37200,
          o: 1.07933,
          h: 1.07943,
          l: 1.07915,
          c: 1.07915,
          v: 179
        },
        {
          t: 37500,
          o: 1.07916,
          h: 1.07937,
          l: 1.07915,
          c: 1.0792,
          v: 161
        },
        {
          t: 37800,
          o: 1.0792,
          h: 1.07957,
          l: 1.07916,
          c: 1.07952,
          v: 218
        },
        {
          t: 38100,
          o: 1.07952,
          h: 1.07975,
          l: 1.0795,
          c: 1.0796,
          v: 150
        },
        {
          t: 38400,
          o: 1.07958,
          h: 1.07975,
          l: 1.07952,
          c: 1.07956,
          v: 150
        },
        {
          t: 38700,
          o: 1.07956,
          h: 1.07973,
          l: 1.07952,
          c: 1.07961,
          v: 117
        },
        {
          t: 39000,
          o: 1.07959,
          h: 1.0796,
          l: 1.07919,
          c: 1.07919,
          v: 195
        },
        {
          t: 39300,
          o: 1.0792,
          h: 1.07924,
          l: 1.07899,
          c: 1.07919,
          v: 167
        },
        {
          t: 39600,
          o: 1.07918,
          h: 1.07934,
          l: 1.07913,
          c: 1.07916,
          v: 183
        },
        {
          t: 39900,
          o: 1.07915,
          h: 1.07942,
          l: 1.0791,
          c: 1.07929,
          v: 162
        },
        {
          t: 40200,
          o: 1.07929,
          h: 1.0794,
          l: 1.07905,
          c: 1.07921,
          v: 193
        },
        {
          t: 40500,
          o: 1.07917,
          h: 1.07917,
          l: 1.07875,
          c: 1.07876,
          v: 274
        },
        {
          t: 40800,
          o: 1.07878,
          h: 1.07901,
          l: 1.07874,
          c: 1.07885,
          v: 165
        },
        {
          t: 41100,
          o: 1.07888,
          h: 1.07917,
          l: 1.0787,
          c: 1.07916,
          v: 175
        },
        {
          t: 41400,
          o: 1.07917,
          h: 1.07923,
          l: 1.07904,
          c: 1.07916,
          v: 198
        },
        {
          t: 41700,
          o: 1.07914,
          h: 1.07916,
          l: 1.079,
          c: 1.07916,
          v: 206
        },
        {
          t: 42000,
          o: 1.07918,
          h: 1.07922,
          l: 1.07898,
          c: 1.07913,
          v: 161
        },
        {
          t: 42300,
          o: 1.07915,
          h: 1.0794,
          l: 1.07905,
          c: 1.0794,
          v: 231
        },
        {
          t: 42600,
          o: 1.07941,
          h: 1.07961,
          l: 1.07918,
          c: 1.07952,
          v: 285
        },
        {
          t: 42900,
          o: 1.07955,
          h: 1.0797,
          l: 1.07943,
          c: 1.0796,
          v: 230
        },
        {
          t: 43200,
          o: 1.07963,
          h: 1.07965,
          l: 1.07926,
          c: 1.07935,
          v: 210
        },
        {
          t: 43500,
          o: 1.07935,
          h: 1.0795,
          l: 1.0793,
          c: 1.07936,
          v: 213
        },
        {
          t: 43800,
          o: 1.07936,
          h: 1.07968,
          l: 1.07931,
          c: 1.07956,
          v: 172
        },
        {
          t: 44100,
          o: 1.07957,
          h: 1.07962,
          l: 1.07937,
          c: 1.07942,
          v: 183
        },
        {
          t: 44400,
          o: 1.07941,
          h: 1.07966,
          l: 1.07941,
          c: 1.0795,
          v: 141
        },
        {
          t: 44700,
          o: 1.0795,
          h: 1.07952,
          l: 1.07905,
          c: 1.07908,
          v: 209
        },
        {
          t: 45000,
          o: 1.07909,
          h: 1.07923,
          l: 1.07854,
          c: 1.07864,
          v: 302
        },
        {
          t: 45300,
          o: 1.07864,
          h: 1.07896,
          l: 1.07864,
          c: 1.07878,
          v: 185
        },
        {
          t: 45600,
          o: 1.07878,
          h: 1.07912,
          l: 1.07875,
          c: 1.07911,
          v: 170
        },
        {
          t: 45900,
          o: 1.07909,
          h: 1.07917,
          l: 1.07879,
          c: 1.07883,
          v: 143
        },
        {
          t: 46200,
          o: 1.07883,
          h: 1.07909,
          l: 1.07875,
          c: 1.07901,
          v: 173
        },
        {
          t: 46500,
          o: 1.07901,
          h: 1.07919,
          l: 1.07878,
          c: 1.07878,
          v: 246
        },
        {
          t: 46800,
          o: 1.07876,
          h: 1.07896,
          l: 1.07873,
          c: 1.07895,
          v: 193
        },
        {
          t: 47100,
          o: 1.07899,
          h: 1.07916,
          l: 1.07893,
          c: 1.07897,
          v: 158
        },
        {
          t: 47400,
          o: 1.07896,
          h: 1.079,
          l: 1.07875,
          c: 1.07891,
          v: 158
        },
        {
          t: 47700,
          o: 1.0789,
          h: 1.0791,
          l: 1.07873,
          c: 1.07902,
          v: 197
        },
        {
          t: 48000,
          o: 1.07904,
          h: 1.07931,
          l: 1.07896,
          c: 1.07916,
          v: 279
        },
        {
          t: 48300,
          o: 1.07915,
          h: 1.07958,
          l: 1.07903,
          c: 1.07955,
          v: 210
        },
        {
          t: 48600,
          o: 1.07955,
          h: 1.07955,
          l: 1.07889,
          c: 1.07917,
          v: 380
        },
        {
          t: 48900,
          o: 1.07918,
          h: 1.0799,
          l: 1.07915,
          c: 1.07962,
          v: 339
        },
        {
          t: 49200,
          o: 1.07961,
          h: 1.07985,
          l: 1.07954,
          c: 1.07985,
          v: 280
        },
        {
          t: 49500,
          o: 1.07981,
          h: 1.07992,
          l: 1.07965,
          c: 1.07977,
          v: 162
        },
        {
          t: 49800,
          o: 1.07978,
          h: 1.08027,
          l: 1.07965,
          c: 1.07984,
          v: 248
        },
        {
          t: 50100,
          o: 1.07985,
          h: 1.08045,
          l: 1.07979,
          c: 1.08042,
          v: 296
        },
        {
          t: 50400,
          o: 1.08042,
          h: 1.08137,
          l: 1.08032,
          c: 1.08135,
          v: 432
        },
        {
          t: 50700,
          o: 1.08137,
          h: 1.08191,
          l: 1.08131,
          c: 1.0817,
          v: 405
        },
        {
          t: 51000,
          o: 1.08169,
          h: 1.0817,
          l: 1.08119,
          c: 1.08122,
          v: 224
        },
        {
          t: 51300,
          o: 1.08124,
          h: 1.08125,
          l: 1.08087,
          c: 1.081,
          v: 163
        },
        {
          t: 51600,
          o: 1.08103,
          h: 1.08133,
          l: 1.08103,
          c: 1.08115,
          v: 180
        },
        {
          t: 51900,
          o: 1.08115,
          h: 1.08143,
          l: 1.08113,
          c: 1.08143,
          v: 148
        },
        {
          t: 52200,
          o: 1.08143,
          h: 1.08189,
          l: 1.08134,
          c: 1.08187,
          v: 308
        },
        {
          t: 52500,
          o: 1.08186,
          h: 1.08208,
          l: 1.08134,
          c: 1.08148,
          v: 444
        },
        {
          t: 52800,
          o: 1.08148,
          h: 1.08178,
          l: 1.08124,
          c: 1.08134,
          v: 314
        },
        {
          t: 53100,
          o: 1.08135,
          h: 1.08187,
          l: 1.08135,
          c: 1.08173,
          v: 285
        },
        {
          t: 53400,
          o: 1.08172,
          h: 1.08195,
          l: 1.08151,
          c: 1.08175,
          v: 250
        },
        {
          t: 53700,
          o: 1.08175,
          h: 1.08192,
          l: 1.08153,
          c: 1.08164,
          v: 250
        },
        {
          t: 54000,
          o: 1.08164,
          h: 1.08189,
          l: 1.08156,
          c: 1.08165,
          v: 295
        },
        {
          t: 54300,
          o: 1.08164,
          h: 1.08182,
          l: 1.08157,
          c: 1.08167,
          v: 262
        },
        {
          t: 54600,
          o: 1.08166,
          h: 1.08193,
          l: 1.08161,
          c: 1.08173,
          v: 250
        },
        {
          t: 54900,
          o: 1.08171,
          h: 1.08179,
          l: 1.08139,
          c: 1.0815,
          v: 205
        },
        {
          t: 55200,
          o: 1.08151,
          h: 1.08166,
          l: 1.081,
          c: 1.08103,
          v: 261
        },
        {
          t: 55500,
          o: 1.08107,
          h: 1.08156,
          l: 1.08106,
          c: 1.08156,
          v: 269
        },
        {
          t: 55800,
          o: 1.08154,
          h: 1.0816,
          l: 1.08128,
          c: 1.08145,
          v: 203
        },
        {
          t: 56100,
          o: 1.08142,
          h: 1.08152,
          l: 1.08081,
          c: 1.08084,
          v: 283
        },
        {
          t: 56400,
          o: 1.08085,
          h: 1.08099,
          l: 1.08032,
          c: 1.08033,
          v: 269
        },
        {
          t: 56700,
          o: 1.08029,
          h: 1.08035,
          l: 1.07999,
          c: 1.08011,
          v: 354
        },
        {
          t: 57000,
          o: 1.0801,
          h: 1.08033,
          l: 1.08003,
          c: 1.08018,
          v: 276
        },
        {
          t: 57300,
          o: 1.0802,
          h: 1.08051,
          l: 1.08002,
          c: 1.08017,
          v: 356
        },
        {
          t: 57600,
          o: 1.08018,
          h: 1.08066,
          l: 1.08018,
          c: 1.08063,
          v: 361
        },
        {
          t: 57900,
          o: 1.08063,
          h: 1.08072,
          l: 1.08038,
          c: 1.08057,
          v: 231
        },
        {
          t: 58200,
          o: 1.08058,
          h: 1.08064,
          l: 1.08034,
          c: 1.08055,
          v: 263
        },
        {
          t: 58500,
          o: 1.08057,
          h: 1.08059,
          l: 1.08019,
          c: 1.0802,
          v: 284
        },
        {
          t: 58800,
          o: 1.08024,
          h: 1.08025,
          l: 1.07906,
          c: 1.07919,
          v: 345
        },
        {
          t: 59100,
          o: 1.07918,
          h: 1.07929,
          l: 1.07823,
          c: 1.07868,
          v: 438
        },
        {
          t: 59400,
          o: 1.07869,
          h: 1.07908,
          l: 1.07839,
          c: 1.07904,
          v: 578
        },
        {
          t: 59700,
          o: 1.079,
          h: 1.07918,
          l: 1.07872,
          c: 1.07875,
          v: 397
        },
        {
          t: 60000,
          o: 1.07875,
          h: 1.07922,
          l: 1.07874,
          c: 1.07904,
          v: 345
        },
        {
          t: 60300,
          o: 1.07907,
          h: 1.07933,
          l: 1.07889,
          c: 1.07932,
          v: 292
        },
        {
          t: 60600,
          o: 1.07932,
          h: 1.07966,
          l: 1.07925,
          c: 1.07938,
          v: 312
        },
        {
          t: 60900,
          o: 1.0794,
          h: 1.07975,
          l: 1.07938,
          c: 1.07963,
          v: 256
        },
        {
          t: 61200,
          o: 1.07964,
          h: 1.08018,
          l: 1.07959,
          c: 1.08,
          v: 354
        },
        {
          t: 61500,
          o: 1.08,
          h: 1.08019,
          l: 1.07987,
          c: 1.0799,
          v: 273
        },
        {
          t: 61800,
          o: 1.0799,
          h: 1.08005,
          l: 1.07975,
          c: 1.08002,
          v: 237
        },
        {
          t: 62100,
          o: 1.08001,
          h: 1.08022,
          l: 1.08001,
          c: 1.08012,
          v: 230
        },
        {
          t: 62400,
          o: 1.08012,
          h: 1.0802,
          l: 1.07991,
          c: 1.08013,
          v: 296
        },
        {
          t: 62700,
          o: 1.08015,
          h: 1.08023,
          l: 1.08003,
          c: 1.08009,
          v: 173
        },
        {
          t: 63000,
          o: 1.08006,
          h: 1.08017,
          l: 1.07974,
          c: 1.07975,
          v: 194
        },
        {
          t: 63300,
          o: 1.07973,
          h: 1.07979,
          l: 1.07928,
          c: 1.07966,
          v: 277
        },
        {
          t: 63600,
          o: 1.07967,
          h: 1.07977,
          l: 1.07954,
          c: 1.07977,
          v: 189
        },
        {
          t: 63900,
          o: 1.07978,
          h: 1.07978,
          l: 1.07941,
          c: 1.07955,
          v: 220
        },
        {
          t: 64200,
          o: 1.07958,
          h: 1.07968,
          l: 1.07948,
          c: 1.07955,
          v: 137
        },
        {
          t: 64500,
          o: 1.07957,
          h: 1.07963,
          l: 1.07924,
          c: 1.07925,
          v: 195
        },
        {
          t: 64800,
          o: 1.07925,
          h: 1.07944,
          l: 1.07925,
          c: 1.07931,
          v: 175
        },
        {
          t: 65100,
          o: 1.07931,
          h: 1.07934,
          l: 1.07905,
          c: 1.07924,
          v: 167
        },
        {
          t: 65400,
          o: 1.07922,
          h: 1.07924,
          l: 1.07907,
          c: 1.07917,
          v: 162
        },
        {
          t: 65700,
          o: 1.07917,
          h: 1.07947,
          l: 1.07916,
          c: 1.07946,
          v: 132
        },
        {
          t: 66000,
          o: 1.07946,
          h: 1.0796,
          l: 1.07936,
          c: 1.07957,
          v: 130
        },
        {
          t: 66300,
          o: 1.07959,
          h: 1.07966,
          l: 1.07935,
          c: 1.07936,
          v: 127
        },
        {
          t: 66600,
          o: 1.07938,
          h: 1.07938,
          l: 1.07928,
          c: 1.0793,
          v: 101
        },
        {
          t: 66900,
          o: 1.0793,
          h: 1.07943,
          l: 1.07924,
          c: 1.07935,
          v: 120
        },
        {
          t: 67200,
          o: 1.07936,
          h: 1.07936,
          l: 1.07925,
          c: 1.07931,
          v: 86
        },
        {
          t: 67500,
          o: 1.0793,
          h: 1.07935,
          l: 1.07929,
          c: 1.07934,
          v: 103
        },
        {
          t: 67800,
          o: 1.07932,
          h: 1.07943,
          l: 1.07928,
          c: 1.07932,
          v: 81
        },
        {
          t: 68100,
          o: 1.07931,
          h: 1.07937,
          l: 1.07926,
          c: 1.07932,
          v: 125
        },
        {
          t: 68400,
          o: 1.07931,
          h: 1.07938,
          l: 1.07921,
          c: 1.07923,
          v: 108
        },
        {
          t: 68700,
          o: 1.07923,
          h: 1.07925,
          l: 1.07891,
          c: 1.07909,
          v: 150
        },
        {
          t: 69000,
          o: 1.07906,
          h: 1.07917,
          l: 1.07904,
          c: 1.07905,
          v: 149
        },
        {
          t: 69300,
          o: 1.07906,
          h: 1.07912,
          l: 1.07903,
          c: 1.0791,
          v: 128
        },
        {
          t: 69600,
          o: 1.0791,
          h: 1.07912,
          l: 1.07897,
          c: 1.0791,
          v: 122
        },
        {
          t: 69900,
          o: 1.0791,
          h: 1.07944,
          l: 1.0791,
          c: 1.0794,
          v: 198
        },
        {
          t: 70200,
          o: 1.07941,
          h: 1.07942,
          l: 1.07919,
          c: 1.07925,
          v: 150
        },
        {
          t: 70500,
          o: 1.07921,
          h: 1.07925,
          l: 1.07912,
          c: 1.07915,
          v: 86
        },
        {
          t: 70800,
          o: 1.07912,
          h: 1.07912,
          l: 1.0788,
          c: 1.07895,
          v: 112
        },
        {
          t: 71100,
          o: 1.07897,
          h: 1.07901,
          l: 1.07861,
          c: 1.07866,
          v: 142
        },
        {
          t: 71400,
          o: 1.0787,
          h: 1.07889,
          l: 1.0787,
          c: 1.07879,
          v: 132
        },
        {
          t: 71700,
          o: 1.07878,
          h: 1.07899,
          l: 1.07876,
          c: 1.0789,
          v: 165
        },
        {
          t: 72000,
          o: 1.0789,
          h: 1.07902,
          l: 1.0788,
          c: 1.07886,
          v: 66
        },
        {
          t: 72300,
          o: 1.07886,
          h: 1.07888,
          l: 1.07874,
          c: 1.07876,
          v: 86
        },
        {
          t: 72600,
          o: 1.07877,
          h: 1.07894,
          l: 1.07863,
          c: 1.07875,
          v: 167
        },
        {
          t: 72900,
          o: 1.07877,
          h: 1.07896,
          l: 1.07864,
          c: 1.07866,
          v: 136
        },
        {
          t: 73200,
          o: 1.07864,
          h: 1.07864,
          l: 1.07843,
          c: 1.07855,
          v: 163
        },
        {
          t: 73500,
          o: 1.07857,
          h: 1.07873,
          l: 1.07857,
          c: 1.07862,
          v: 80
        },
        {
          t: 73800,
          o: 1.07865,
          h: 1.07877,
          l: 1.07861,
          c: 1.07876,
          v: 113
        },
        {
          t: 74100,
          o: 1.07876,
          h: 1.07878,
          l: 1.07865,
          c: 1.07868,
          v: 65
        },
        {
          t: 74400,
          o: 1.07868,
          h: 1.07871,
          l: 1.07864,
          c: 1.0787,
          v: 70
        },
        {
          t: 74700,
          o: 1.0787,
          h: 1.07879,
          l: 1.07869,
          c: 1.07872,
          v: 78
        },
        {
          t: 75000,
          o: 1.07873,
          h: 1.07882,
          l: 1.07867,
          c: 1.07867,
          v: 79
        },
        {
          t: 75300,
          o: 1.07867,
          h: 1.07894,
          l: 1.07858,
          c: 1.07893,
          v: 202
        },
        {
          t: 75600,
          o: 1.07892,
          h: 1.07892,
          l: 1.07868,
          c: 1.07887,
          v: 147
        },
        {
          t: 75900,
          o: 1.07887,
          h: 1.07887,
          l: 1.07875,
          c: 1.07875,
          v: 40
        },
        {
          t: 76200,
          o: 1.07875,
          h: 1.07884,
          l: 1.07875,
          c: 1.07882,
          v: 25
        },
        {
          t: 76500,
          o: 1.07882,
          h: 1.07883,
          l: 1.07863,
          c: 1.07865,
          v: 37
        },
        {
          t: 76800,
          o: 1.07864,
          h: 1.07865,
          l: 1.07853,
          c: 1.07853,
          v: 28
        },
        {
          t: 77100,
          o: 1.07853,
          h: 1.07855,
          l: 1.07845,
          c: 1.0785,
          v: 32
        },
        {
          t: 77400,
          o: 1.07852,
          h: 1.07856,
          l: 1.07846,
          c: 1.07856,
          v: 40
        },
        {
          t: 77700,
          o: 1.07856,
          h: 1.07857,
          l: 1.07854,
          c: 1.07856,
          v: 36
        },
        {
          t: 78000,
          o: 1.07855,
          h: 1.07857,
          l: 1.07854,
          c: 1.07856,
          v: 33
        },
        {
          t: 78300,
          o: 1.07857,
          h: 1.0786,
          l: 1.07844,
          c: 1.0786,
          v: 63
        },
        {
          t: 78600,
          o: 1.07859,
          h: 1.07859,
          l: 1.07829,
          c: 1.07829,
          v: 81
        },
        {
          t: 78900,
          o: 1.07829,
          h: 1.07838,
          l: 1.07825,
          c: 1.07838,
          v: 206
        },
        {
          t: 79200,
          o: 1.07838,
          h: 1.07839,
          l: 1.07824,
          c: 1.07833,
          v: 114
        },
        {
          t: 79500,
          o: 1.07833,
          h: 1.07842,
          l: 1.07831,
          c: 1.07842,
          v: 48
        },
        {
          t: 79800,
          o: 1.07842,
          h: 1.07848,
          l: 1.07838,
          c: 1.07843,
          v: 72
        },
        {
          t: 80100,
          o: 1.07836,
          h: 1.07844,
          l: 1.07835,
          c: 1.07837,
          v: 114
        },
        {
          t: 80400,
          o: 1.07837,
          h: 1.07847,
          l: 1.07831,
          c: 1.07842,
          v: 134
        },
        {
          t: 80700,
          o: 1.07842,
          h: 1.07847,
          l: 1.07827,
          c: 1.07836,
          v: 89
        },
        {
          t: 81000,
          o: 1.07833,
          h: 1.07863,
          l: 1.07832,
          c: 1.07849,
          v: 139
        },
        {
          t: 81300,
          o: 1.07848,
          h: 1.07854,
          l: 1.07843,
          c: 1.07851,
          v: 55
        },
        {
          t: 81600,
          o: 1.07849,
          h: 1.07874,
          l: 1.07847,
          c: 1.07867,
          v: 88
        },
        {
          t: 81900,
          o: 1.07867,
          h: 1.07871,
          l: 1.07841,
          c: 1.07855,
          v: 84
        },
        {
          t: 82200,
          o: 1.07856,
          h: 1.07856,
          l: 1.07848,
          c: 1.07851,
          v: 86
        },
        {
          t: 82500,
          o: 1.0785,
          h: 1.07866,
          l: 1.07849,
          c: 1.07864,
          v: 97
        },
        {
          t: 82800,
          o: 1.07863,
          h: 1.07863,
          l: 1.07836,
          c: 1.07857,
          v: 77
        },
        {
          t: 83100,
          o: 1.07859,
          h: 1.07867,
          l: 1.07856,
          c: 1.07865,
          v: 52
        },
        {
          t: 83400,
          o: 1.07865,
          h: 1.07871,
          l: 1.07865,
          c: 1.07871,
          v: 45
        },
        {
          t: 83700,
          o: 1.07871,
          h: 1.07878,
          l: 1.07871,
          c: 1.07877,
          v: 46
        },
        {
          t: 84000,
          o: 1.07878,
          h: 1.07882,
          l: 1.0787,
          c: 1.07872,
          v: 28
        },
        {
          t: 84300,
          o: 1.07873,
          h: 1.07877,
          l: 1.07871,
          c: 1.07875,
          v: 36
        },
        {
          t: 84600,
          o: 1.07876,
          h: 1.07877,
          l: 1.07871,
          c: 1.07877,
          v: 54
        },
        {
          t: 84900,
          o: 1.07877,
          h: 1.07884,
          l: 1.07877,
          c: 1.07883,
          v: 45
        },
        {
          t: 85200,
          o: 1.07883,
          h: 1.07887,
          l: 1.07874,
          c: 1.07877,
          v: 58
        },
        {
          t: 85500,
          o: 1.07878,
          h: 1.07882,
          l: 1.07871,
          c: 1.07879,
          v: 80
        },
        {
          t: 85800,
          o: 1.07878,
          h: 1.07881,
          l: 1.07876,
          c: 1.07879,
          v: 55
        },
        {
          t: 86100,
          o: 1.07878,
          h: 1.07878,
          l: 1.07871,
          c: 1.07872,
          v: 60
        }
      ]
    },
    {
      d: 1582243200,
      b: [
        {
          t: 0,
          o: 1.07872,
          h: 1.07896,
          l: 1.07868,
          c: 1.07888,
          v: 114
        },
        {
          t: 300,
          o: 1.07887,
          h: 1.07898,
          l: 1.07882,
          c: 1.07896,
          v: 75
        },
        {
          t: 600,
          o: 1.07897,
          h: 1.07908,
          l: 1.07897,
          c: 1.07908,
          v: 48
        },
        {
          t: 900,
          o: 1.07908,
          h: 1.07911,
          l: 1.07891,
          c: 1.07892,
          v: 84
        },
        {
          t: 1200,
          o: 1.07893,
          h: 1.07898,
          l: 1.07877,
          c: 1.07884,
          v: 103
        },
        {
          t: 1500,
          o: 1.07884,
          h: 1.07885,
          l: 1.0787,
          c: 1.07877,
          v: 69
        },
        {
          t: 1800,
          o: 1.07879,
          h: 1.07883,
          l: 1.07876,
          c: 1.07877,
          v: 38
        },
        {
          t: 2100,
          o: 1.07877,
          h: 1.07877,
          l: 1.07867,
          c: 1.07871,
          v: 74
        },
        {
          t: 2400,
          o: 1.07871,
          h: 1.07897,
          l: 1.07871,
          c: 1.07889,
          v: 100
        },
        {
          t: 2700,
          o: 1.07891,
          h: 1.07895,
          l: 1.07888,
          c: 1.07894,
          v: 58
        },
        {
          t: 3000,
          o: 1.07894,
          h: 1.07899,
          l: 1.07872,
          c: 1.07877,
          v: 142
        },
        {
          t: 3300,
          o: 1.07878,
          h: 1.079,
          l: 1.07877,
          c: 1.07897,
          v: 127
        },
        {
          t: 3600,
          o: 1.079,
          h: 1.07904,
          l: 1.07891,
          c: 1.07893,
          v: 134
        },
        {
          t: 3900,
          o: 1.07894,
          h: 1.07919,
          l: 1.07892,
          c: 1.07917,
          v: 91
        },
        {
          t: 4200,
          o: 1.07915,
          h: 1.07917,
          l: 1.0791,
          c: 1.07912,
          v: 91
        },
        {
          t: 4500,
          o: 1.07913,
          h: 1.07918,
          l: 1.07908,
          c: 1.07917,
          v: 102
        },
        {
          t: 4800,
          o: 1.07917,
          h: 1.0793,
          l: 1.07915,
          c: 1.07923,
          v: 77
        },
        {
          t: 5100,
          o: 1.07922,
          h: 1.07923,
          l: 1.07914,
          c: 1.07918,
          v: 72
        },
        {
          t: 5400,
          o: 1.07919,
          h: 1.07929,
          l: 1.07916,
          c: 1.07924,
          v: 80
        },
        {
          t: 5700,
          o: 1.07924,
          h: 1.0793,
          l: 1.07921,
          c: 1.07925,
          v: 85
        },
        {
          t: 6000,
          o: 1.07926,
          h: 1.07928,
          l: 1.07917,
          c: 1.07918,
          v: 45
        },
        {
          t: 6300,
          o: 1.0792,
          h: 1.07928,
          l: 1.07912,
          c: 1.07927,
          v: 85
        },
        {
          t: 6600,
          o: 1.07928,
          h: 1.07932,
          l: 1.07924,
          c: 1.07928,
          v: 100
        },
        {
          t: 6900,
          o: 1.07928,
          h: 1.07929,
          l: 1.07918,
          c: 1.07923,
          v: 103
        },
        {
          t: 7200,
          o: 1.07924,
          h: 1.07931,
          l: 1.07897,
          c: 1.07897,
          v: 110
        },
        {
          t: 7500,
          o: 1.07896,
          h: 1.07908,
          l: 1.0789,
          c: 1.07897,
          v: 121
        },
        {
          t: 7800,
          o: 1.07898,
          h: 1.07906,
          l: 1.07888,
          c: 1.0789,
          v: 126
        },
        {
          t: 8100,
          o: 1.07892,
          h: 1.07923,
          l: 1.0789,
          c: 1.07905,
          v: 224
        },
        {
          t: 8400,
          o: 1.07904,
          h: 1.07921,
          l: 1.07897,
          c: 1.07898,
          v: 143
        },
        {
          t: 8700,
          o: 1.07897,
          h: 1.07902,
          l: 1.07881,
          c: 1.07881,
          v: 128
        },
        {
          t: 9000,
          o: 1.0788,
          h: 1.07899,
          l: 1.07874,
          c: 1.07892,
          v: 141
        },
        {
          t: 9300,
          o: 1.07891,
          h: 1.07903,
          l: 1.07888,
          c: 1.07894,
          v: 101
        },
        {
          t: 9600,
          o: 1.07893,
          h: 1.07893,
          l: 1.07874,
          c: 1.07879,
          v: 110
        },
        {
          t: 9900,
          o: 1.07878,
          h: 1.07881,
          l: 1.07872,
          c: 1.07873,
          v: 68
        },
        {
          t: 10200,
          o: 1.07873,
          h: 1.07879,
          l: 1.07872,
          c: 1.07878,
          v: 109
        },
        {
          t: 10500,
          o: 1.07878,
          h: 1.07896,
          l: 1.07878,
          c: 1.07893,
          v: 64
        },
        {
          t: 10800,
          o: 1.07893,
          h: 1.07902,
          l: 1.07888,
          c: 1.07898,
          v: 73
        },
        {
          t: 11100,
          o: 1.07902,
          h: 1.07919,
          l: 1.07902,
          c: 1.07918,
          v: 48
        },
        {
          t: 11400,
          o: 1.07918,
          h: 1.07918,
          l: 1.07911,
          c: 1.07912,
          v: 57
        },
        {
          t: 11700,
          o: 1.07912,
          h: 1.07913,
          l: 1.07887,
          c: 1.07887,
          v: 89
        },
        {
          t: 12000,
          o: 1.07889,
          h: 1.07892,
          l: 1.07886,
          c: 1.07888,
          v: 91
        },
        {
          t: 12300,
          o: 1.07891,
          h: 1.07893,
          l: 1.0789,
          c: 1.07892,
          v: 17
        },
        {
          t: 12600,
          o: 1.07896,
          h: 1.07899,
          l: 1.0789,
          c: 1.07896,
          v: 26
        },
        {
          t: 12900,
          o: 1.07896,
          h: 1.07903,
          l: 1.07895,
          c: 1.07898,
          v: 79
        },
        {
          t: 13200,
          o: 1.07897,
          h: 1.07903,
          l: 1.07897,
          c: 1.079,
          v: 60
        },
        {
          t: 13500,
          o: 1.07899,
          h: 1.07902,
          l: 1.07897,
          c: 1.07898,
          v: 52
        },
        {
          t: 13800,
          o: 1.07897,
          h: 1.07897,
          l: 1.0789,
          c: 1.07892,
          v: 52
        },
        {
          t: 14100,
          o: 1.07891,
          h: 1.07898,
          l: 1.07888,
          c: 1.07898,
          v: 63
        },
        {
          t: 14400,
          o: 1.07898,
          h: 1.07898,
          l: 1.07888,
          c: 1.0789,
          v: 66
        },
        {
          t: 14700,
          o: 1.07891,
          h: 1.07908,
          l: 1.0789,
          c: 1.07905,
          v: 64
        },
        {
          t: 15000,
          o: 1.07905,
          h: 1.07931,
          l: 1.07897,
          c: 1.07908,
          v: 110
        },
        {
          t: 15300,
          o: 1.07908,
          h: 1.07919,
          l: 1.07904,
          c: 1.07918,
          v: 80
        },
        {
          t: 15600,
          o: 1.07918,
          h: 1.0792,
          l: 1.0791,
          c: 1.0791,
          v: 63
        },
        {
          t: 15900,
          o: 1.07908,
          h: 1.07921,
          l: 1.07908,
          c: 1.07921,
          v: 41
        },
        {
          t: 16200,
          o: 1.07922,
          h: 1.07925,
          l: 1.07913,
          c: 1.07914,
          v: 40
        },
        {
          t: 16500,
          o: 1.07913,
          h: 1.07922,
          l: 1.07913,
          c: 1.0792,
          v: 36
        },
        {
          t: 16800,
          o: 1.07918,
          h: 1.07929,
          l: 1.07917,
          c: 1.07928,
          v: 50
        },
        {
          t: 17100,
          o: 1.07928,
          h: 1.07938,
          l: 1.07926,
          c: 1.07932,
          v: 75
        },
        {
          t: 17400,
          o: 1.0793,
          h: 1.07933,
          l: 1.07927,
          c: 1.07928,
          v: 57
        },
        {
          t: 17700,
          o: 1.07932,
          h: 1.07944,
          l: 1.07931,
          c: 1.07942,
          v: 78
        },
        {
          t: 18000,
          o: 1.07942,
          h: 1.07944,
          l: 1.0793,
          c: 1.07931,
          v: 57
        },
        {
          t: 18300,
          o: 1.07931,
          h: 1.07938,
          l: 1.07931,
          c: 1.07937,
          v: 31
        },
        {
          t: 18600,
          o: 1.07938,
          h: 1.07943,
          l: 1.07936,
          c: 1.07939,
          v: 38
        },
        {
          t: 18900,
          o: 1.07938,
          h: 1.07942,
          l: 1.07931,
          c: 1.07932,
          v: 40
        },
        {
          t: 19200,
          o: 1.07931,
          h: 1.07932,
          l: 1.07926,
          c: 1.07927,
          v: 26
        },
        {
          t: 19500,
          o: 1.07927,
          h: 1.07934,
          l: 1.07927,
          c: 1.07932,
          v: 38
        },
        {
          t: 19800,
          o: 1.07932,
          h: 1.07933,
          l: 1.07927,
          c: 1.07932,
          v: 34
        },
        {
          t: 20100,
          o: 1.07931,
          h: 1.07936,
          l: 1.07931,
          c: 1.07931,
          v: 32
        },
        {
          t: 20400,
          o: 1.07928,
          h: 1.07928,
          l: 1.07915,
          c: 1.07922,
          v: 63
        },
        {
          t: 20700,
          o: 1.07923,
          h: 1.07923,
          l: 1.07917,
          c: 1.07922,
          v: 30
        },
        {
          t: 21000,
          o: 1.07921,
          h: 1.07932,
          l: 1.07921,
          c: 1.07932,
          v: 33
        },
        {
          t: 21300,
          o: 1.07931,
          h: 1.07933,
          l: 1.07925,
          c: 1.07926,
          v: 63
        },
        {
          t: 21600,
          o: 1.07923,
          h: 1.07932,
          l: 1.07916,
          c: 1.0793,
          v: 75
        },
        {
          t: 21900,
          o: 1.0793,
          h: 1.07942,
          l: 1.07921,
          c: 1.07937,
          v: 77
        },
        {
          t: 22200,
          o: 1.07938,
          h: 1.07941,
          l: 1.07933,
          c: 1.07937,
          v: 53
        },
        {
          t: 22500,
          o: 1.07938,
          h: 1.07938,
          l: 1.07922,
          c: 1.07927,
          v: 63
        },
        {
          t: 22800,
          o: 1.07927,
          h: 1.07927,
          l: 1.0792,
          c: 1.07921,
          v: 65
        },
        {
          t: 23100,
          o: 1.07921,
          h: 1.07927,
          l: 1.0791,
          c: 1.07911,
          v: 52
        },
        {
          t: 23400,
          o: 1.07911,
          h: 1.07916,
          l: 1.07907,
          c: 1.0791,
          v: 59
        },
        {
          t: 23700,
          o: 1.0791,
          h: 1.07921,
          l: 1.07908,
          c: 1.07917,
          v: 69
        },
        {
          t: 24000,
          o: 1.07917,
          h: 1.0792,
          l: 1.07912,
          c: 1.07914,
          v: 67
        },
        {
          t: 24300,
          o: 1.07913,
          h: 1.07915,
          l: 1.07892,
          c: 1.07902,
          v: 99
        },
        {
          t: 24600,
          o: 1.07901,
          h: 1.07903,
          l: 1.07896,
          c: 1.07902,
          v: 64
        },
        {
          t: 24900,
          o: 1.07902,
          h: 1.07903,
          l: 1.07891,
          c: 1.07898,
          v: 60
        },
        {
          t: 25200,
          o: 1.079,
          h: 1.0791,
          l: 1.07893,
          c: 1.07903,
          v: 189
        },
        {
          t: 25500,
          o: 1.07902,
          h: 1.07912,
          l: 1.07897,
          c: 1.0791,
          v: 164
        },
        {
          t: 25800,
          o: 1.07912,
          h: 1.07934,
          l: 1.07907,
          c: 1.07922,
          v: 183
        },
        {
          t: 26100,
          o: 1.07921,
          h: 1.0795,
          l: 1.07921,
          c: 1.07943,
          v: 141
        },
        {
          t: 26400,
          o: 1.07943,
          h: 1.07961,
          l: 1.0794,
          c: 1.07958,
          v: 177
        },
        {
          t: 26700,
          o: 1.07958,
          h: 1.07965,
          l: 1.07949,
          c: 1.07953,
          v: 164
        },
        {
          t: 27000,
          o: 1.07954,
          h: 1.07967,
          l: 1.07953,
          c: 1.0796,
          v: 161
        },
        {
          t: 27300,
          o: 1.07959,
          h: 1.07964,
          l: 1.0795,
          c: 1.07959,
          v: 171
        },
        {
          t: 27600,
          o: 1.0796,
          h: 1.07963,
          l: 1.07921,
          c: 1.0795,
          v: 211
        },
        {
          t: 27900,
          o: 1.07953,
          h: 1.07978,
          l: 1.07951,
          c: 1.07974,
          v: 225
        },
        {
          t: 28200,
          o: 1.07975,
          h: 1.07981,
          l: 1.07956,
          c: 1.07963,
          v: 205
        },
        {
          t: 28500,
          o: 1.07964,
          h: 1.07974,
          l: 1.07946,
          c: 1.07957,
          v: 171
        },
        {
          t: 28800,
          o: 1.07955,
          h: 1.07965,
          l: 1.07911,
          c: 1.07916,
          v: 374
        },
        {
          t: 29100,
          o: 1.07915,
          h: 1.07944,
          l: 1.07897,
          c: 1.07899,
          v: 334
        },
        {
          t: 29400,
          o: 1.07898,
          h: 1.07961,
          l: 1.07898,
          c: 1.07948,
          v: 331
        },
        {
          t: 29700,
          o: 1.0796,
          h: 1.08045,
          l: 1.0796,
          c: 1.07999,
          v: 510
        },
        {
          t: 30000,
          o: 1.07998,
          h: 1.08046,
          l: 1.07992,
          c: 1.08005,
          v: 364
        },
        {
          t: 30300,
          o: 1.08004,
          h: 1.08028,
          l: 1.07983,
          c: 1.07998,
          v: 303
        },
        {
          t: 30600,
          o: 1.08014,
          h: 1.08196,
          l: 1.08014,
          c: 1.08133,
          v: 818
        },
        {
          t: 30900,
          o: 1.0813,
          h: 1.08153,
          l: 1.08092,
          c: 1.08116,
          v: 363
        },
        {
          t: 31200,
          o: 1.08117,
          h: 1.08136,
          l: 1.08072,
          c: 1.081,
          v: 294
        },
        {
          t: 31500,
          o: 1.081,
          h: 1.08136,
          l: 1.08086,
          c: 1.08132,
          v: 307
        },
        {
          t: 31800,
          o: 1.08133,
          h: 1.08137,
          l: 1.08118,
          c: 1.08134,
          v: 269
        },
        {
          t: 32100,
          o: 1.08137,
          h: 1.08154,
          l: 1.0813,
          c: 1.08136,
          v: 339
        },
        {
          t: 32400,
          o: 1.08137,
          h: 1.08182,
          l: 1.08137,
          c: 1.08177,
          v: 409
        },
        {
          t: 32700,
          o: 1.08177,
          h: 1.0819,
          l: 1.08164,
          c: 1.08165,
          v: 295
        },
        {
          t: 33000,
          o: 1.08161,
          h: 1.08184,
          l: 1.08145,
          c: 1.08154,
          v: 316
        },
        {
          t: 33300,
          o: 1.08153,
          h: 1.08156,
          l: 1.08127,
          c: 1.08146,
          v: 264
        },
        {
          t: 33600,
          o: 1.08145,
          h: 1.08166,
          l: 1.08133,
          c: 1.08133,
          v: 165
        },
        {
          t: 33900,
          o: 1.08135,
          h: 1.08156,
          l: 1.0812,
          c: 1.08152,
          v: 196
        },
        {
          t: 34200,
          o: 1.08156,
          h: 1.08172,
          l: 1.0815,
          c: 1.08167,
          v: 235
        },
        {
          t: 34500,
          o: 1.08168,
          h: 1.08176,
          l: 1.08137,
          c: 1.08137,
          v: 238
        },
        {
          t: 34800,
          o: 1.08136,
          h: 1.0815,
          l: 1.0813,
          c: 1.0815,
          v: 114
        },
        {
          t: 35100,
          o: 1.08151,
          h: 1.08154,
          l: 1.08139,
          c: 1.08139,
          v: 202
        },
        {
          t: 35400,
          o: 1.0814,
          h: 1.08154,
          l: 1.08135,
          c: 1.08136,
          v: 166
        },
        {
          t: 35700,
          o: 1.08133,
          h: 1.08143,
          l: 1.08084,
          c: 1.08094,
          v: 227
        },
        {
          t: 36000,
          o: 1.08095,
          h: 1.08102,
          l: 1.08027,
          c: 1.0805,
          v: 272
        },
        {
          t: 36300,
          o: 1.08047,
          h: 1.08073,
          l: 1.08043,
          c: 1.08043,
          v: 207
        },
        {
          t: 36600,
          o: 1.08044,
          h: 1.08044,
          l: 1.08012,
          c: 1.08013,
          v: 192
        },
        {
          t: 36900,
          o: 1.08013,
          h: 1.08018,
          l: 1.07971,
          c: 1.07979,
          v: 251
        },
        {
          t: 37200,
          o: 1.07978,
          h: 1.0802,
          l: 1.07957,
          c: 1.08003,
          v: 216
        },
        {
          t: 37500,
          o: 1.08003,
          h: 1.0802,
          l: 1.07984,
          c: 1.0801,
          v: 144
        },
        {
          t: 37800,
          o: 1.08008,
          h: 1.08026,
          l: 1.08008,
          c: 1.08023,
          v: 105
        },
        {
          t: 38100,
          o: 1.08022,
          h: 1.08035,
          l: 1.08016,
          c: 1.08029,
          v: 132
        },
        {
          t: 38400,
          o: 1.08028,
          h: 1.08043,
          l: 1.08016,
          c: 1.08024,
          v: 139
        },
        {
          t: 38700,
          o: 1.08024,
          h: 1.08035,
          l: 1.08015,
          c: 1.08025,
          v: 115
        },
        {
          t: 39000,
          o: 1.08025,
          h: 1.0803,
          l: 1.07991,
          c: 1.07991,
          v: 147
        },
        {
          t: 39300,
          o: 1.07992,
          h: 1.08014,
          l: 1.07974,
          c: 1.07992,
          v: 153
        },
        {
          t: 39600,
          o: 1.07992,
          h: 1.08009,
          l: 1.07979,
          c: 1.07979,
          v: 168
        },
        {
          t: 39900,
          o: 1.0798,
          h: 1.07985,
          l: 1.07949,
          c: 1.07985,
          v: 193
        },
        {
          t: 40200,
          o: 1.07984,
          h: 1.0799,
          l: 1.07961,
          c: 1.07988,
          v: 174
        },
        {
          t: 40500,
          o: 1.07987,
          h: 1.07993,
          l: 1.07974,
          c: 1.07981,
          v: 123
        },
        {
          t: 40800,
          o: 1.07982,
          h: 1.08016,
          l: 1.07982,
          c: 1.08014,
          v: 129
        },
        {
          t: 41100,
          o: 1.08012,
          h: 1.08049,
          l: 1.08011,
          c: 1.08036,
          v: 187
        },
        {
          t: 41400,
          o: 1.08037,
          h: 1.08043,
          l: 1.08006,
          c: 1.08013,
          v: 205
        },
        {
          t: 41700,
          o: 1.08012,
          h: 1.08018,
          l: 1.07996,
          c: 1.08016,
          v: 153
        },
        {
          t: 42000,
          o: 1.08016,
          h: 1.08032,
          l: 1.08016,
          c: 1.0803,
          v: 129
        },
        {
          t: 42300,
          o: 1.08029,
          h: 1.08032,
          l: 1.08005,
          c: 1.08011,
          v: 145
        },
        {
          t: 42600,
          o: 1.08008,
          h: 1.08035,
          l: 1.08006,
          c: 1.08032,
          v: 146
        },
        {
          t: 42900,
          o: 1.08033,
          h: 1.08062,
          l: 1.08029,
          c: 1.08058,
          v: 261
        },
        {
          t: 43200,
          o: 1.08057,
          h: 1.08057,
          l: 1.08005,
          c: 1.08015,
          v: 255
        },
        {
          t: 43500,
          o: 1.08016,
          h: 1.08043,
          l: 1.08,
          c: 1.08016,
          v: 194
        },
        {
          t: 43800,
          o: 1.08014,
          h: 1.08033,
          l: 1.08009,
          c: 1.0803,
          v: 151
        },
        {
          t: 44100,
          o: 1.08031,
          h: 1.08038,
          l: 1.08026,
          c: 1.08032,
          v: 108
        },
        {
          t: 44400,
          o: 1.08031,
          h: 1.08051,
          l: 1.08029,
          c: 1.08036,
          v: 182
        },
        {
          t: 44700,
          o: 1.08037,
          h: 1.08053,
          l: 1.08018,
          c: 1.0802,
          v: 191
        },
        {
          t: 45000,
          o: 1.0802,
          h: 1.08024,
          l: 1.07994,
          c: 1.08007,
          v: 201
        },
        {
          t: 45300,
          o: 1.08006,
          h: 1.08012,
          l: 1.07991,
          c: 1.07991,
          v: 145
        },
        {
          t: 45600,
          o: 1.07992,
          h: 1.0801,
          l: 1.07988,
          c: 1.0799,
          v: 129
        },
        {
          t: 45900,
          o: 1.07991,
          h: 1.07994,
          l: 1.07979,
          c: 1.07992,
          v: 162
        },
        {
          t: 46200,
          o: 1.07994,
          h: 1.08028,
          l: 1.07985,
          c: 1.0802,
          v: 125
        },
        {
          t: 46500,
          o: 1.08018,
          h: 1.08019,
          l: 1.07958,
          c: 1.07961,
          v: 319
        },
        {
          t: 46800,
          o: 1.07963,
          h: 1.08009,
          l: 1.07963,
          c: 1.08,
          v: 267
        },
        {
          t: 47100,
          o: 1.07999,
          h: 1.0802,
          l: 1.07993,
          c: 1.0802,
          v: 180
        },
        {
          t: 47400,
          o: 1.08021,
          h: 1.08023,
          l: 1.07995,
          c: 1.08005,
          v: 173
        },
        {
          t: 47700,
          o: 1.08003,
          h: 1.08012,
          l: 1.07966,
          c: 1.08004,
          v: 241
        },
        {
          t: 48000,
          o: 1.08005,
          h: 1.08019,
          l: 1.08001,
          c: 1.08018,
          v: 174
        },
        {
          t: 48300,
          o: 1.08016,
          h: 1.08018,
          l: 1.07998,
          c: 1.08005,
          v: 198
        },
        {
          t: 48600,
          o: 1.08005,
          h: 1.0803,
          l: 1.08001,
          c: 1.08013,
          v: 193
        },
        {
          t: 48900,
          o: 1.08014,
          h: 1.08053,
          l: 1.08014,
          c: 1.0805,
          v: 203
        },
        {
          t: 49200,
          o: 1.08051,
          h: 1.08088,
          l: 1.08051,
          c: 1.08075,
          v: 214
        },
        {
          t: 49500,
          o: 1.08072,
          h: 1.0812,
          l: 1.08067,
          c: 1.08085,
          v: 262
        },
        {
          t: 49800,
          o: 1.08085,
          h: 1.08101,
          l: 1.0807,
          c: 1.08075,
          v: 151
        },
        {
          t: 50100,
          o: 1.08075,
          h: 1.08091,
          l: 1.08055,
          c: 1.08088,
          v: 166
        },
        {
          t: 50400,
          o: 1.08086,
          h: 1.08086,
          l: 1.08006,
          c: 1.0801,
          v: 226
        },
        {
          t: 50700,
          o: 1.08015,
          h: 1.08044,
          l: 1.08009,
          c: 1.08038,
          v: 193
        },
        {
          t: 51000,
          o: 1.08039,
          h: 1.08071,
          l: 1.08036,
          c: 1.08064,
          v: 189
        },
        {
          t: 51300,
          o: 1.08063,
          h: 1.08077,
          l: 1.08044,
          c: 1.0806,
          v: 177
        },
        {
          t: 51600,
          o: 1.08061,
          h: 1.08092,
          l: 1.0806,
          c: 1.08069,
          v: 180
        },
        {
          t: 51900,
          o: 1.08068,
          h: 1.0809,
          l: 1.08066,
          c: 1.08076,
          v: 149
        },
        {
          t: 52200,
          o: 1.0808,
          h: 1.08102,
          l: 1.08072,
          c: 1.08081,
          v: 268
        },
        {
          t: 52500,
          o: 1.08083,
          h: 1.08151,
          l: 1.08083,
          c: 1.08143,
          v: 290
        },
        {
          t: 52800,
          o: 1.08141,
          h: 1.08173,
          l: 1.08134,
          c: 1.08144,
          v: 254
        },
        {
          t: 53100,
          o: 1.08183,
          h: 1.08432,
          l: 1.08171,
          c: 1.08431,
          v: 779
        },
        {
          t: 53400,
          o: 1.08431,
          h: 1.08455,
          l: 1.08351,
          c: 1.08368,
          v: 623
        },
        {
          t: 53700,
          o: 1.08365,
          h: 1.08439,
          l: 1.08337,
          c: 1.08367,
          v: 419
        },
        {
          t: 54000,
          o: 1.08366,
          h: 1.08482,
          l: 1.08366,
          c: 1.08423,
          v: 534
        },
        {
          t: 54300,
          o: 1.08423,
          h: 1.0849,
          l: 1.08423,
          c: 1.08463,
          v: 474
        },
        {
          t: 54600,
          o: 1.08457,
          h: 1.0854,
          l: 1.08457,
          c: 1.08535,
          v: 488
        },
        {
          t: 54900,
          o: 1.08537,
          h: 1.08583,
          l: 1.08513,
          c: 1.08555,
          v: 467
        },
        {
          t: 55200,
          o: 1.08551,
          h: 1.08568,
          l: 1.08529,
          c: 1.08549,
          v: 438
        },
        {
          t: 55500,
          o: 1.0855,
          h: 1.08575,
          l: 1.08534,
          c: 1.08573,
          v: 338
        },
        {
          t: 55800,
          o: 1.08574,
          h: 1.08577,
          l: 1.08509,
          c: 1.08528,
          v: 391
        },
        {
          t: 56100,
          o: 1.08528,
          h: 1.08594,
          l: 1.0852,
          c: 1.08589,
          v: 401
        },
        {
          t: 56400,
          o: 1.08586,
          h: 1.08608,
          l: 1.08579,
          c: 1.08591,
          v: 430
        },
        {
          t: 56700,
          o: 1.0859,
          h: 1.0863,
          l: 1.0858,
          c: 1.08593,
          v: 365
        },
        {
          t: 57000,
          o: 1.08594,
          h: 1.08603,
          l: 1.08536,
          c: 1.08537,
          v: 357
        },
        {
          t: 57300,
          o: 1.08536,
          h: 1.08536,
          l: 1.08465,
          c: 1.08521,
          v: 471
        },
        {
          t: 57600,
          o: 1.08523,
          h: 1.0857,
          l: 1.08501,
          c: 1.08568,
          v: 385
        },
        {
          t: 57900,
          o: 1.08566,
          h: 1.08581,
          l: 1.08548,
          c: 1.08572,
          v: 264
        },
        {
          t: 58200,
          o: 1.08574,
          h: 1.08597,
          l: 1.08561,
          c: 1.08593,
          v: 260
        },
        {
          t: 58500,
          o: 1.08597,
          h: 1.0863,
          l: 1.08578,
          c: 1.08614,
          v: 303
        },
        {
          t: 58800,
          o: 1.08612,
          h: 1.08625,
          l: 1.08548,
          c: 1.08572,
          v: 323
        },
        {
          t: 59100,
          o: 1.08572,
          h: 1.08578,
          l: 1.08543,
          c: 1.08551,
          v: 311
        },
        {
          t: 59400,
          o: 1.08551,
          h: 1.08551,
          l: 1.08514,
          c: 1.08538,
          v: 286
        },
        {
          t: 59700,
          o: 1.08539,
          h: 1.08563,
          l: 1.08516,
          c: 1.08519,
          v: 266
        },
        {
          t: 60000,
          o: 1.08518,
          h: 1.08522,
          l: 1.08481,
          c: 1.08484,
          v: 230
        },
        {
          t: 60300,
          o: 1.08487,
          h: 1.08508,
          l: 1.08483,
          c: 1.08501,
          v: 236
        },
        {
          t: 60600,
          o: 1.08503,
          h: 1.08541,
          l: 1.08493,
          c: 1.08541,
          v: 182
        },
        {
          t: 60900,
          o: 1.08542,
          h: 1.08557,
          l: 1.08538,
          c: 1.0854,
          v: 141
        },
        {
          t: 61200,
          o: 1.08539,
          h: 1.08558,
          l: 1.08533,
          c: 1.08555,
          v: 152
        },
        {
          t: 61500,
          o: 1.08556,
          h: 1.08578,
          l: 1.08546,
          c: 1.08562,
          v: 213
        },
        {
          t: 61800,
          o: 1.08563,
          h: 1.08585,
          l: 1.08563,
          c: 1.08585,
          v: 149
        },
        {
          t: 62100,
          o: 1.08585,
          h: 1.08595,
          l: 1.08583,
          c: 1.0859,
          v: 186
        },
        {
          t: 62400,
          o: 1.08587,
          h: 1.08593,
          l: 1.08574,
          c: 1.08593,
          v: 157
        },
        {
          t: 62700,
          o: 1.0859,
          h: 1.08593,
          l: 1.08578,
          c: 1.08583,
          v: 158
        },
        {
          t: 63000,
          o: 1.08585,
          h: 1.08596,
          l: 1.08579,
          c: 1.08583,
          v: 146
        },
        {
          t: 63300,
          o: 1.08583,
          h: 1.08596,
          l: 1.0858,
          c: 1.08584,
          v: 121
        },
        {
          t: 63600,
          o: 1.08584,
          h: 1.08594,
          l: 1.08578,
          c: 1.08583,
          v: 130
        },
        {
          t: 63900,
          o: 1.08583,
          h: 1.08605,
          l: 1.08572,
          c: 1.08573,
          v: 146
        },
        {
          t: 64200,
          o: 1.08573,
          h: 1.08573,
          l: 1.08535,
          c: 1.08535,
          v: 158
        },
        {
          t: 64500,
          o: 1.08536,
          h: 1.08572,
          l: 1.08536,
          c: 1.08569,
          v: 236
        },
        {
          t: 64800,
          o: 1.0857,
          h: 1.08594,
          l: 1.08536,
          c: 1.0855,
          v: 216
        },
        {
          t: 65100,
          o: 1.08553,
          h: 1.08575,
          l: 1.08549,
          c: 1.08575,
          v: 104
        },
        {
          t: 65400,
          o: 1.08574,
          h: 1.0859,
          l: 1.08569,
          c: 1.0859,
          v: 109
        },
        {
          t: 65700,
          o: 1.0859,
          h: 1.08603,
          l: 1.08588,
          c: 1.086,
          v: 75
        },
        {
          t: 66000,
          o: 1.086,
          h: 1.08611,
          l: 1.08595,
          c: 1.08595,
          v: 111
        },
        {
          t: 66300,
          o: 1.08596,
          h: 1.08596,
          l: 1.08555,
          c: 1.08558,
          v: 124
        },
        {
          t: 66600,
          o: 1.08559,
          h: 1.0857,
          l: 1.08559,
          c: 1.08569,
          v: 98
        },
        {
          t: 66900,
          o: 1.08568,
          h: 1.08578,
          l: 1.08559,
          c: 1.08564,
          v: 91
        },
        {
          t: 67200,
          o: 1.08565,
          h: 1.08575,
          l: 1.08564,
          c: 1.08564,
          v: 77
        },
        {
          t: 67500,
          o: 1.08565,
          h: 1.08576,
          l: 1.08563,
          c: 1.08576,
          v: 89
        },
        {
          t: 67800,
          o: 1.08578,
          h: 1.08586,
          l: 1.0856,
          c: 1.0856,
          v: 94
        },
        {
          t: 68100,
          o: 1.0856,
          h: 1.08565,
          l: 1.0855,
          c: 1.08558,
          v: 129
        },
        {
          t: 68400,
          o: 1.08561,
          h: 1.08561,
          l: 1.08534,
          c: 1.08539,
          v: 146
        },
        {
          t: 68700,
          o: 1.08539,
          h: 1.08545,
          l: 1.08529,
          c: 1.08529,
          v: 112
        },
        {
          t: 69000,
          o: 1.0853,
          h: 1.08545,
          l: 1.08523,
          c: 1.08543,
          v: 120
        },
        {
          t: 69300,
          o: 1.08544,
          h: 1.08546,
          l: 1.0853,
          c: 1.08533,
          v: 137
        },
        {
          t: 69600,
          o: 1.08531,
          h: 1.08568,
          l: 1.08531,
          c: 1.08565,
          v: 141
        },
        {
          t: 69900,
          o: 1.08566,
          h: 1.08577,
          l: 1.08555,
          c: 1.08556,
          v: 90
        },
        {
          t: 70200,
          o: 1.08554,
          h: 1.08558,
          l: 1.08539,
          c: 1.08546,
          v: 83
        },
        {
          t: 70500,
          o: 1.08545,
          h: 1.08555,
          l: 1.08533,
          c: 1.08536,
          v: 93
        },
        {
          t: 70800,
          o: 1.0854,
          h: 1.08546,
          l: 1.08533,
          c: 1.08545,
          v: 87
        },
        {
          t: 71100,
          o: 1.08546,
          h: 1.08557,
          l: 1.08543,
          c: 1.08556,
          v: 87
        },
        {
          t: 71400,
          o: 1.08557,
          h: 1.08563,
          l: 1.08556,
          c: 1.08561,
          v: 90
        },
        {
          t: 71700,
          o: 1.08562,
          h: 1.08573,
          l: 1.08555,
          c: 1.08573,
          v: 128
        },
        {
          t: 72000,
          o: 1.08572,
          h: 1.08573,
          l: 1.08553,
          c: 1.08555,
          v: 119
        },
        {
          t: 72300,
          o: 1.08559,
          h: 1.0856,
          l: 1.0854,
          c: 1.08541,
          v: 95
        },
        {
          t: 72600,
          o: 1.0854,
          h: 1.08542,
          l: 1.08523,
          c: 1.08539,
          v: 95
        },
        {
          t: 72900,
          o: 1.08541,
          h: 1.08552,
          l: 1.08539,
          c: 1.0855,
          v: 100
        },
        {
          t: 73200,
          o: 1.0855,
          h: 1.08553,
          l: 1.0854,
          c: 1.0854,
          v: 109
        },
        {
          t: 73500,
          o: 1.0854,
          h: 1.08542,
          l: 1.08518,
          c: 1.08525,
          v: 96
        },
        {
          t: 73800,
          o: 1.08526,
          h: 1.08528,
          l: 1.0851,
          c: 1.08512,
          v: 134
        },
        {
          t: 74100,
          o: 1.0851,
          h: 1.08515,
          l: 1.08503,
          c: 1.08504,
          v: 83
        },
        {
          t: 74400,
          o: 1.08504,
          h: 1.08505,
          l: 1.08494,
          c: 1.08505,
          v: 104
        },
        {
          t: 74700,
          o: 1.08505,
          h: 1.08505,
          l: 1.08485,
          c: 1.08485,
          v: 125
        },
        {
          t: 75000,
          o: 1.08486,
          h: 1.08487,
          l: 1.08474,
          c: 1.0848,
          v: 123
        },
        {
          t: 75300,
          o: 1.0848,
          h: 1.08492,
          l: 1.0847,
          c: 1.08488,
          v: 199
        },
        {
          t: 75600,
          o: 1.08485,
          h: 1.08492,
          l: 1.0848,
          c: 1.08486,
          v: 101
        },
        {
          t: 75900,
          o: 1.08485,
          h: 1.08489,
          l: 1.08475,
          c: 1.08476,
          v: 43
        },
        {
          t: 76200,
          o: 1.08476,
          h: 1.08485,
          l: 1.08476,
          c: 1.08483,
          v: 57
        },
        {
          t: 76500,
          o: 1.0848,
          h: 1.08485,
          l: 1.08478,
          c: 1.08478,
          v: 36
        },
        {
          t: 76800,
          o: 1.08477,
          h: 1.08486,
          l: 1.08473,
          c: 1.08484,
          v: 26
        },
        {
          t: 77100,
          o: 1.08484,
          h: 1.08488,
          l: 1.08479,
          c: 1.08486,
          v: 64
        },
        {
          t: 77400,
          o: 1.08484,
          h: 1.08485,
          l: 1.08478,
          c: 1.08481,
          v: 24
        },
        {
          t: 77700,
          o: 1.0848,
          h: 1.08484,
          l: 1.08475,
          c: 1.0848,
          v: 38
        }
      ]
    },
    {
      d: 1582416000,
      b: [
        {
          t: 79500,
          o: 1.08234,
          h: 1.08281,
          l: 1.0822,
          c: 1.08243,
          v: 731
        },
        {
          t: 79800,
          o: 1.0825,
          h: 1.0826,
          l: 1.08238,
          c: 1.08259,
          v: 455
        },
        {
          t: 80100,
          o: 1.08259,
          h: 1.08279,
          l: 1.08257,
          c: 1.08274,
          v: 394
        },
        {
          t: 80400,
          o: 1.08273,
          h: 1.08285,
          l: 1.0826,
          c: 1.08285,
          v: 27
        },
        {
          t: 80700,
          o: 1.08287,
          h: 1.08314,
          l: 1.08284,
          c: 1.08303,
          v: 19
        },
        {
          t: 81000,
          o: 1.08304,
          h: 1.08318,
          l: 1.08303,
          c: 1.0831,
          v: 38
        },
        {
          t: 81300,
          o: 1.08312,
          h: 1.08316,
          l: 1.08298,
          c: 1.08313,
          v: 64
        },
        {
          t: 81600,
          o: 1.08312,
          h: 1.08313,
          l: 1.08302,
          c: 1.08306,
          v: 50
        },
        {
          t: 81900,
          o: 1.08309,
          h: 1.08334,
          l: 1.0827,
          c: 1.08277,
          v: 182
        },
        {
          t: 82200,
          o: 1.08277,
          h: 1.0829,
          l: 1.08267,
          c: 1.08278,
          v: 141
        },
        {
          t: 82500,
          o: 1.08278,
          h: 1.0829,
          l: 1.08273,
          c: 1.08283,
          v: 49
        },
        {
          t: 82800,
          o: 1.08281,
          h: 1.08375,
          l: 1.08281,
          c: 1.08359,
          v: 302
        },
        {
          t: 83100,
          o: 1.08355,
          h: 1.0839,
          l: 1.08355,
          c: 1.0838,
          v: 144
        },
        {
          t: 83400,
          o: 1.08382,
          h: 1.08415,
          l: 1.0838,
          c: 1.08398,
          v: 170
        },
        {
          t: 83700,
          o: 1.08399,
          h: 1.08402,
          l: 1.08379,
          c: 1.08388,
          v: 245
        },
        {
          t: 84000,
          o: 1.08389,
          h: 1.08395,
          l: 1.08373,
          c: 1.08374,
          v: 244
        },
        {
          t: 84300,
          o: 1.08375,
          h: 1.08377,
          l: 1.08354,
          c: 1.08354,
          v: 217
        },
        {
          t: 84600,
          o: 1.08355,
          h: 1.08364,
          l: 1.08329,
          c: 1.08339,
          v: 164
        },
        {
          t: 84900,
          o: 1.08338,
          h: 1.08359,
          l: 1.08334,
          c: 1.08354,
          v: 138
        },
        {
          t: 85200,
          o: 1.08353,
          h: 1.0838,
          l: 1.08353,
          c: 1.0838,
          v: 152
        },
        {
          t: 85500,
          o: 1.08381,
          h: 1.08393,
          l: 1.0837,
          c: 1.08387,
          v: 96
        },
        {
          t: 85800,
          o: 1.08382,
          h: 1.08383,
          l: 1.08361,
          c: 1.08369,
          v: 96
        },
        {
          t: 86100,
          o: 1.08373,
          h: 1.0838,
          l: 1.08365,
          c: 1.0838,
          v: 82
        }
      ]
    },
    {
      d: 1582502400,
      b: [
        {
          t: 0,
          o: 1.0838,
          h: 1.08397,
          l: 1.08372,
          c: 1.08396,
          v: 124
        },
        {
          t: 300,
          o: 1.08396,
          h: 1.08405,
          l: 1.08391,
          c: 1.08395,
          v: 129
        },
        {
          t: 600,
          o: 1.08396,
          h: 1.08397,
          l: 1.08376,
          c: 1.08378,
          v: 144
        },
        {
          t: 900,
          o: 1.08378,
          h: 1.08392,
          l: 1.08377,
          c: 1.08392,
          v: 200
        },
        {
          t: 1200,
          o: 1.08392,
          h: 1.08395,
          l: 1.08378,
          c: 1.08384,
          v: 130
        },
        {
          t: 1500,
          o: 1.08386,
          h: 1.08419,
          l: 1.08386,
          c: 1.08419,
          v: 194
        },
        {
          t: 1800,
          o: 1.08418,
          h: 1.08419,
          l: 1.08395,
          c: 1.08395,
          v: 155
        },
        {
          t: 2100,
          o: 1.08395,
          h: 1.08395,
          l: 1.0835,
          c: 1.08352,
          v: 135
        },
        {
          t: 2400,
          o: 1.08351,
          h: 1.08351,
          l: 1.08301,
          c: 1.08302,
          v: 146
        },
        {
          t: 2700,
          o: 1.08305,
          h: 1.08311,
          l: 1.08283,
          c: 1.08308,
          v: 156
        },
        {
          t: 3000,
          o: 1.0831,
          h: 1.08319,
          l: 1.08291,
          c: 1.08302,
          v: 174
        },
        {
          t: 3300,
          o: 1.08302,
          h: 1.08322,
          l: 1.08302,
          c: 1.08308,
          v: 97
        },
        {
          t: 3600,
          o: 1.08308,
          h: 1.08314,
          l: 1.08298,
          c: 1.08311,
          v: 178
        },
        {
          t: 3900,
          o: 1.08312,
          h: 1.08317,
          l: 1.08289,
          c: 1.08292,
          v: 175
        },
        {
          t: 4200,
          o: 1.0829,
          h: 1.08291,
          l: 1.0826,
          c: 1.0826,
          v: 210
        },
        {
          t: 4500,
          o: 1.0826,
          h: 1.0827,
          l: 1.08257,
          c: 1.08264,
          v: 125
        },
        {
          t: 4800,
          o: 1.08265,
          h: 1.08267,
          l: 1.08256,
          c: 1.08257,
          v: 110
        },
        {
          t: 5100,
          o: 1.0826,
          h: 1.08265,
          l: 1.0824,
          c: 1.08249,
          v: 127
        },
        {
          t: 5400,
          o: 1.0825,
          h: 1.08251,
          l: 1.08241,
          c: 1.08243,
          v: 127
        },
        {
          t: 5700,
          o: 1.08242,
          h: 1.0826,
          l: 1.08241,
          c: 1.08259,
          v: 162
        },
        {
          t: 6000,
          o: 1.08258,
          h: 1.08268,
          l: 1.08257,
          c: 1.08262,
          v: 88
        },
        {
          t: 6300,
          o: 1.08259,
          h: 1.08268,
          l: 1.08257,
          c: 1.08267,
          v: 126
        },
        {
          t: 6600,
          o: 1.08267,
          h: 1.08291,
          l: 1.08266,
          c: 1.08277,
          v: 110
        },
        {
          t: 6900,
          o: 1.08278,
          h: 1.08279,
          l: 1.0826,
          c: 1.08277,
          v: 111
        },
        {
          t: 7200,
          o: 1.08278,
          h: 1.0829,
          l: 1.08272,
          c: 1.08278,
          v: 137
        },
        {
          t: 7500,
          o: 1.08277,
          h: 1.08281,
          l: 1.08267,
          c: 1.08267,
          v: 53
        },
        {
          t: 7800,
          o: 1.08271,
          h: 1.08292,
          l: 1.08267,
          c: 1.08288,
          v: 104
        },
        {
          t: 8100,
          o: 1.08287,
          h: 1.08293,
          l: 1.08285,
          c: 1.08289,
          v: 83
        },
        {
          t: 8400,
          o: 1.08288,
          h: 1.08293,
          l: 1.08278,
          c: 1.0828,
          v: 113
        },
        {
          t: 8700,
          o: 1.08281,
          h: 1.08293,
          l: 1.0827,
          c: 1.08271,
          v: 95
        },
        {
          t: 9000,
          o: 1.08271,
          h: 1.08271,
          l: 1.08254,
          c: 1.08256,
          v: 99
        },
        {
          t: 9300,
          o: 1.08256,
          h: 1.08256,
          l: 1.08246,
          c: 1.08252,
          v: 87
        },
        {
          t: 9600,
          o: 1.08256,
          h: 1.08281,
          l: 1.08256,
          c: 1.08267,
          v: 147
        },
        {
          t: 9900,
          o: 1.08266,
          h: 1.08278,
          l: 1.08263,
          c: 1.08271,
          v: 119
        },
        {
          t: 10200,
          o: 1.08271,
          h: 1.08277,
          l: 1.08256,
          c: 1.0826,
          v: 97
        },
        {
          t: 10500,
          o: 1.08258,
          h: 1.08259,
          l: 1.08221,
          c: 1.08221,
          v: 225
        },
        {
          t: 10800,
          o: 1.08221,
          h: 1.08237,
          l: 1.0822,
          c: 1.08232,
          v: 156
        },
        {
          t: 11100,
          o: 1.08233,
          h: 1.08234,
          l: 1.08216,
          c: 1.08218,
          v: 206
        },
        {
          t: 11400,
          o: 1.08218,
          h: 1.08231,
          l: 1.08216,
          c: 1.08222,
          v: 66
        },
        {
          t: 11700,
          o: 1.08222,
          h: 1.08232,
          l: 1.08216,
          c: 1.08219,
          v: 79
        },
        {
          t: 12000,
          o: 1.08218,
          h: 1.08226,
          l: 1.08216,
          c: 1.08223,
          v: 62
        },
        {
          t: 12300,
          o: 1.08223,
          h: 1.08232,
          l: 1.08217,
          c: 1.08229,
          v: 69
        },
        {
          t: 12600,
          o: 1.0823,
          h: 1.08231,
          l: 1.08222,
          c: 1.08223,
          v: 74
        },
        {
          t: 12900,
          o: 1.08224,
          h: 1.08234,
          l: 1.08222,
          c: 1.08232,
          v: 83
        },
        {
          t: 13200,
          o: 1.08233,
          h: 1.08237,
          l: 1.08231,
          c: 1.08236,
          v: 48
        },
        {
          t: 13500,
          o: 1.08235,
          h: 1.08236,
          l: 1.08218,
          c: 1.0822,
          v: 56
        },
        {
          t: 13800,
          o: 1.08219,
          h: 1.08223,
          l: 1.08213,
          c: 1.08218,
          v: 86
        },
        {
          t: 14100,
          o: 1.08217,
          h: 1.0822,
          l: 1.08211,
          c: 1.08217,
          v: 69
        },
        {
          t: 14400,
          o: 1.08217,
          h: 1.08218,
          l: 1.08208,
          c: 1.08212,
          v: 91
        },
        {
          t: 14700,
          o: 1.08212,
          h: 1.08221,
          l: 1.08208,
          c: 1.0822,
          v: 79
        },
        {
          t: 15000,
          o: 1.08218,
          h: 1.08218,
          l: 1.08216,
          c: 1.08217,
          v: 42
        },
        {
          t: 15300,
          o: 1.08217,
          h: 1.0822,
          l: 1.08208,
          c: 1.08212,
          v: 45
        },
        {
          t: 15600,
          o: 1.08211,
          h: 1.08214,
          l: 1.0821,
          c: 1.08214,
          v: 52
        },
        {
          t: 15900,
          o: 1.08213,
          h: 1.08215,
          l: 1.08207,
          c: 1.08207,
          v: 28
        },
        {
          t: 16200,
          o: 1.08206,
          h: 1.08209,
          l: 1.08197,
          c: 1.08198,
          v: 29
        },
        {
          t: 16500,
          o: 1.08199,
          h: 1.08205,
          l: 1.08197,
          c: 1.08205,
          v: 38
        },
        {
          t: 16800,
          o: 1.08203,
          h: 1.08206,
          l: 1.08197,
          c: 1.08197,
          v: 64
        },
        {
          t: 17100,
          o: 1.08197,
          h: 1.08201,
          l: 1.08186,
          c: 1.0819,
          v: 37
        },
        {
          t: 17400,
          o: 1.08189,
          h: 1.0821,
          l: 1.08187,
          c: 1.08207,
          v: 40
        },
        {
          t: 17700,
          o: 1.08208,
          h: 1.08209,
          l: 1.08206,
          c: 1.08207,
          v: 39
        },
        {
          t: 18000,
          o: 1.08208,
          h: 1.08214,
          l: 1.082,
          c: 1.08205,
          v: 83
        },
        {
          t: 18300,
          o: 1.08205,
          h: 1.08206,
          l: 1.08198,
          c: 1.08203,
          v: 52
        },
        {
          t: 18600,
          o: 1.08205,
          h: 1.08205,
          l: 1.082,
          c: 1.08202,
          v: 29
        },
        {
          t: 18900,
          o: 1.08204,
          h: 1.08213,
          l: 1.08204,
          c: 1.08206,
          v: 54
        },
        {
          t: 19200,
          o: 1.08208,
          h: 1.08209,
          l: 1.08186,
          c: 1.08186,
          v: 62
        },
        {
          t: 19500,
          o: 1.08187,
          h: 1.08189,
          l: 1.08174,
          c: 1.08181,
          v: 69
        },
        {
          t: 19800,
          o: 1.08181,
          h: 1.08202,
          l: 1.08181,
          c: 1.08201,
          v: 57
        },
        {
          t: 20100,
          o: 1.08201,
          h: 1.0821,
          l: 1.082,
          c: 1.08205,
          v: 68
        },
        {
          t: 20400,
          o: 1.08205,
          h: 1.08208,
          l: 1.08195,
          c: 1.08195,
          v: 60
        },
        {
          t: 20700,
          o: 1.08196,
          h: 1.08197,
          l: 1.08171,
          c: 1.08171,
          v: 64
        },
        {
          t: 21000,
          o: 1.08172,
          h: 1.08178,
          l: 1.08161,
          c: 1.08171,
          v: 88
        },
        {
          t: 21300,
          o: 1.08167,
          h: 1.08177,
          l: 1.08144,
          c: 1.08145,
          v: 153
        },
        {
          t: 21600,
          o: 1.08144,
          h: 1.0819,
          l: 1.08144,
          c: 1.08175,
          v: 152
        },
        {
          t: 21900,
          o: 1.08176,
          h: 1.08182,
          l: 1.08164,
          c: 1.08175,
          v: 111
        },
        {
          t: 22200,
          o: 1.08175,
          h: 1.08188,
          l: 1.08175,
          c: 1.08185,
          v: 89
        },
        {
          t: 22500,
          o: 1.08186,
          h: 1.08188,
          l: 1.08169,
          c: 1.08177,
          v: 126
        },
        {
          t: 22800,
          o: 1.08177,
          h: 1.08181,
          l: 1.08175,
          c: 1.0818,
          v: 41
        },
        {
          t: 23100,
          o: 1.0818,
          h: 1.08192,
          l: 1.08173,
          c: 1.08186,
          v: 106
        },
        {
          t: 23400,
          o: 1.08185,
          h: 1.08198,
          l: 1.08164,
          c: 1.08192,
          v: 126
        },
        {
          t: 23700,
          o: 1.08192,
          h: 1.08199,
          l: 1.08188,
          c: 1.08198,
          v: 68
        },
        {
          t: 24000,
          o: 1.08198,
          h: 1.08202,
          l: 1.08183,
          c: 1.08183,
          v: 90
        },
        {
          t: 24300,
          o: 1.08182,
          h: 1.08192,
          l: 1.08166,
          c: 1.08167,
          v: 101
        },
        {
          t: 24600,
          o: 1.08166,
          h: 1.0818,
          l: 1.08164,
          c: 1.08177,
          v: 141
        },
        {
          t: 24900,
          o: 1.08174,
          h: 1.08175,
          l: 1.08151,
          c: 1.08158,
          v: 199
        },
        {
          t: 25200,
          o: 1.08162,
          h: 1.0819,
          l: 1.08114,
          c: 1.08137,
          v: 404
        },
        {
          t: 25500,
          o: 1.08141,
          h: 1.08141,
          l: 1.08046,
          c: 1.08092,
          v: 334
        },
        {
          t: 25800,
          o: 1.08093,
          h: 1.08122,
          l: 1.08083,
          c: 1.08099,
          v: 347
        },
        {
          t: 26100,
          o: 1.081,
          h: 1.0812,
          l: 1.0809,
          c: 1.08111,
          v: 260
        },
        {
          t: 26400,
          o: 1.08108,
          h: 1.0814,
          l: 1.08103,
          c: 1.0814,
          v: 236
        },
        {
          t: 26700,
          o: 1.0814,
          h: 1.08157,
          l: 1.08137,
          c: 1.08149,
          v: 205
        },
        {
          t: 27000,
          o: 1.08149,
          h: 1.08149,
          l: 1.08125,
          c: 1.08142,
          v: 214
        },
        {
          t: 27300,
          o: 1.08142,
          h: 1.0817,
          l: 1.08142,
          c: 1.08162,
          v: 207
        },
        {
          t: 27600,
          o: 1.08162,
          h: 1.08163,
          l: 1.08131,
          c: 1.08135,
          v: 208
        },
        {
          t: 27900,
          o: 1.08136,
          h: 1.08151,
          l: 1.08115,
          c: 1.08144,
          v: 227
        },
        {
          t: 28200,
          o: 1.08147,
          h: 1.08166,
          l: 1.08142,
          c: 1.08156,
          v: 208
        },
        {
          t: 28500,
          o: 1.08157,
          h: 1.08168,
          l: 1.08149,
          c: 1.08167,
          v: 191
        },
        {
          t: 28800,
          o: 1.08167,
          h: 1.08218,
          l: 1.08147,
          c: 1.08185,
          v: 424
        },
        {
          t: 29100,
          o: 1.08187,
          h: 1.08259,
          l: 1.08181,
          c: 1.08258,
          v: 340
        },
        {
          t: 29400,
          o: 1.0826,
          h: 1.0828,
          l: 1.08221,
          c: 1.08221,
          v: 322
        },
        {
          t: 29700,
          o: 1.08221,
          h: 1.08279,
          l: 1.08208,
          c: 1.08239,
          v: 346
        },
        {
          t: 30000,
          o: 1.08238,
          h: 1.08265,
          l: 1.08222,
          c: 1.08235,
          v: 345
        },
        {
          t: 30300,
          o: 1.08238,
          h: 1.08279,
          l: 1.08236,
          c: 1.08277,
          v: 348
        },
        {
          t: 30600,
          o: 1.08276,
          h: 1.0828,
          l: 1.08214,
          c: 1.08234,
          v: 392
        },
        {
          t: 30900,
          o: 1.08231,
          h: 1.08231,
          l: 1.08196,
          c: 1.08215,
          v: 341
        },
        {
          t: 31200,
          o: 1.08215,
          h: 1.08239,
          l: 1.08207,
          c: 1.08233,
          v: 305
        },
        {
          t: 31500,
          o: 1.08232,
          h: 1.08235,
          l: 1.08196,
          c: 1.08198,
          v: 322
        },
        {
          t: 31800,
          o: 1.08198,
          h: 1.0823,
          l: 1.08167,
          c: 1.08224,
          v: 284
        },
        {
          t: 32100,
          o: 1.08223,
          h: 1.08279,
          l: 1.08216,
          c: 1.08261,
          v: 302
        },
        {
          t: 32400,
          o: 1.08261,
          h: 1.08414,
          l: 1.08257,
          c: 1.08409,
          v: 537
        },
        {
          t: 32700,
          o: 1.0841,
          h: 1.0841,
          l: 1.08325,
          c: 1.08329,
          v: 336
        },
        {
          t: 33000,
          o: 1.08329,
          h: 1.08342,
          l: 1.08311,
          c: 1.08312,
          v: 313
        },
        {
          t: 33300,
          o: 1.08307,
          h: 1.08311,
          l: 1.08275,
          c: 1.0828,
          v: 293
        },
        {
          t: 33600,
          o: 1.08281,
          h: 1.08281,
          l: 1.08194,
          c: 1.08209,
          v: 286
        },
        {
          t: 33900,
          o: 1.08206,
          h: 1.08217,
          l: 1.08202,
          c: 1.0821,
          v: 291
        },
        {
          t: 34200,
          o: 1.0821,
          h: 1.08253,
          l: 1.08201,
          c: 1.08239,
          v: 262
        },
        {
          t: 34500,
          o: 1.08239,
          h: 1.0826,
          l: 1.08234,
          c: 1.08244,
          v: 299
        },
        {
          t: 34800,
          o: 1.08241,
          h: 1.08245,
          l: 1.08207,
          c: 1.08212,
          v: 299
        },
        {
          t: 35100,
          o: 1.08212,
          h: 1.08218,
          l: 1.08135,
          c: 1.08139,
          v: 388
        },
        {
          t: 35400,
          o: 1.08138,
          h: 1.08195,
          l: 1.08132,
          c: 1.08145,
          v: 360
        },
        {
          t: 35700,
          o: 1.08145,
          h: 1.08168,
          l: 1.08135,
          c: 1.08151,
          v: 325
        },
        {
          t: 36000,
          o: 1.08153,
          h: 1.08188,
          l: 1.0815,
          c: 1.08168,
          v: 242
        },
        {
          t: 36300,
          o: 1.08167,
          h: 1.08221,
          l: 1.08148,
          c: 1.08218,
          v: 317
        },
        {
          t: 36600,
          o: 1.08219,
          h: 1.08254,
          l: 1.08216,
          c: 1.08219,
          v: 243
        },
        {
          t: 36900,
          o: 1.0822,
          h: 1.08229,
          l: 1.08179,
          c: 1.08191,
          v: 224
        },
        {
          t: 37200,
          o: 1.0819,
          h: 1.08201,
          l: 1.08177,
          c: 1.08183,
          v: 199
        },
        {
          t: 37500,
          o: 1.08182,
          h: 1.08182,
          l: 1.08137,
          c: 1.08142,
          v: 233
        },
        {
          t: 37800,
          o: 1.08142,
          h: 1.08154,
          l: 1.08125,
          c: 1.08139,
          v: 262
        },
        {
          t: 38100,
          o: 1.08137,
          h: 1.0818,
          l: 1.08137,
          c: 1.08162,
          v: 204
        },
        {
          t: 38400,
          o: 1.0816,
          h: 1.08179,
          l: 1.08137,
          c: 1.08139,
          v: 192
        },
        {
          t: 38700,
          o: 1.08138,
          h: 1.08187,
          l: 1.08136,
          c: 1.08182,
          v: 265
        },
        {
          t: 39000,
          o: 1.08179,
          h: 1.0818,
          l: 1.08141,
          c: 1.08153,
          v: 214
        },
        {
          t: 39300,
          o: 1.08154,
          h: 1.08177,
          l: 1.08146,
          c: 1.08153,
          v: 169
        },
        {
          t: 39600,
          o: 1.08154,
          h: 1.08164,
          l: 1.08146,
          c: 1.08149,
          v: 198
        },
        {
          t: 39900,
          o: 1.08149,
          h: 1.08151,
          l: 1.0813,
          c: 1.0815,
          v: 218
        },
        {
          t: 40200,
          o: 1.0815,
          h: 1.0822,
          l: 1.0815,
          c: 1.08192,
          v: 276
        },
        {
          t: 40500,
          o: 1.0819,
          h: 1.08227,
          l: 1.08189,
          c: 1.0821,
          v: 240
        },
        {
          t: 40800,
          o: 1.0821,
          h: 1.08213,
          l: 1.08178,
          c: 1.08195,
          v: 191
        },
        {
          t: 41100,
          o: 1.08195,
          h: 1.08227,
          l: 1.08191,
          c: 1.08217,
          v: 159
        },
        {
          t: 41400,
          o: 1.08217,
          h: 1.08244,
          l: 1.08213,
          c: 1.08235,
          v: 220
        },
        {
          t: 41700,
          o: 1.08235,
          h: 1.08248,
          l: 1.08204,
          c: 1.0821,
          v: 232
        },
        {
          t: 42000,
          o: 1.08211,
          h: 1.08238,
          l: 1.08205,
          c: 1.08225,
          v: 177
        },
        {
          t: 42300,
          o: 1.08224,
          h: 1.08232,
          l: 1.0819,
          c: 1.08207,
          v: 119
        },
        {
          t: 42600,
          o: 1.0821,
          h: 1.08226,
          l: 1.08204,
          c: 1.08223,
          v: 168
        },
        {
          t: 42900,
          o: 1.08222,
          h: 1.08241,
          l: 1.08196,
          c: 1.08204,
          v: 206
        },
        {
          t: 43200,
          o: 1.08206,
          h: 1.08206,
          l: 1.08161,
          c: 1.08191,
          v: 280
        },
        {
          t: 43500,
          o: 1.08192,
          h: 1.08209,
          l: 1.08185,
          c: 1.08191,
          v: 223
        },
        {
          t: 43800,
          o: 1.08189,
          h: 1.0822,
          l: 1.08188,
          c: 1.0822,
          v: 235
        },
        {
          t: 44100,
          o: 1.08221,
          h: 1.08233,
          l: 1.08207,
          c: 1.08229,
          v: 203
        },
        {
          t: 44400,
          o: 1.0823,
          h: 1.08242,
          l: 1.08217,
          c: 1.08235,
          v: 164
        },
        {
          t: 44700,
          o: 1.08235,
          h: 1.08277,
          l: 1.08218,
          c: 1.0827,
          v: 216
        },
        {
          t: 45000,
          o: 1.08271,
          h: 1.08271,
          l: 1.08211,
          c: 1.08219,
          v: 246
        },
        {
          t: 45300,
          o: 1.08219,
          h: 1.08228,
          l: 1.08205,
          c: 1.0821,
          v: 182
        },
        {
          t: 45600,
          o: 1.08207,
          h: 1.08225,
          l: 1.08193,
          c: 1.08215,
          v: 257
        },
        {
          t: 45900,
          o: 1.08215,
          h: 1.08215,
          l: 1.08193,
          c: 1.08204,
          v: 170
        },
        {
          t: 46200,
          o: 1.08202,
          h: 1.08224,
          l: 1.08198,
          c: 1.0821,
          v: 166
        },
        {
          t: 46500,
          o: 1.08209,
          h: 1.08218,
          l: 1.08157,
          c: 1.08178,
          v: 399
        },
        {
          t: 46800,
          o: 1.08179,
          h: 1.08208,
          l: 1.08176,
          c: 1.08202,
          v: 321
        },
        {
          t: 47100,
          o: 1.08203,
          h: 1.08209,
          l: 1.08178,
          c: 1.08184,
          v: 240
        },
        {
          t: 47400,
          o: 1.08181,
          h: 1.08181,
          l: 1.08143,
          c: 1.08178,
          v: 270
        },
        {
          t: 47700,
          o: 1.08179,
          h: 1.08198,
          l: 1.08173,
          c: 1.08195,
          v: 250
        },
        {
          t: 48000,
          o: 1.08193,
          h: 1.08203,
          l: 1.08181,
          c: 1.08196,
          v: 223
        },
        {
          t: 48300,
          o: 1.08197,
          h: 1.08216,
          l: 1.08188,
          c: 1.08206,
          v: 219
        },
        {
          t: 48600,
          o: 1.08208,
          h: 1.0821,
          l: 1.08184,
          c: 1.08187,
          v: 299
        },
        {
          t: 48900,
          o: 1.08189,
          h: 1.0819,
          l: 1.08173,
          c: 1.08176,
          v: 223
        },
        {
          t: 49200,
          o: 1.08177,
          h: 1.08177,
          l: 1.08151,
          c: 1.08155,
          v: 211
        },
        {
          t: 49500,
          o: 1.08155,
          h: 1.08158,
          l: 1.08144,
          c: 1.08152,
          v: 187
        },
        {
          t: 49800,
          o: 1.08152,
          h: 1.08157,
          l: 1.08145,
          c: 1.08153,
          v: 160
        },
        {
          t: 50100,
          o: 1.08154,
          h: 1.08158,
          l: 1.08125,
          c: 1.08142,
          v: 163
        },
        {
          t: 50400,
          o: 1.08143,
          h: 1.08175,
          l: 1.08135,
          c: 1.08174,
          v: 254
        },
        {
          t: 50700,
          o: 1.08176,
          h: 1.08225,
          l: 1.08172,
          c: 1.08202,
          v: 334
        },
        {
          t: 51000,
          o: 1.08204,
          h: 1.08243,
          l: 1.082,
          c: 1.08216,
          v: 331
        },
        {
          t: 51300,
          o: 1.08212,
          h: 1.08271,
          l: 1.08212,
          c: 1.08253,
          v: 399
        },
        {
          t: 51600,
          o: 1.08252,
          h: 1.08317,
          l: 1.08251,
          c: 1.08312,
          v: 390
        },
        {
          t: 51900,
          o: 1.08314,
          h: 1.08366,
          l: 1.08294,
          c: 1.08334,
          v: 374
        },
        {
          t: 52200,
          o: 1.08333,
          h: 1.08348,
          l: 1.08267,
          c: 1.08303,
          v: 533
        },
        {
          t: 52500,
          o: 1.08302,
          h: 1.08323,
          l: 1.08281,
          c: 1.08295,
          v: 439
        },
        {
          t: 52800,
          o: 1.083,
          h: 1.08378,
          l: 1.083,
          c: 1.08357,
          v: 404
        },
        {
          t: 53100,
          o: 1.08357,
          h: 1.08402,
          l: 1.0835,
          c: 1.084,
          v: 364
        },
        {
          t: 53400,
          o: 1.08401,
          h: 1.08523,
          l: 1.0839,
          c: 1.08512,
          v: 540
        },
        {
          t: 53700,
          o: 1.0851,
          h: 1.0851,
          l: 1.08469,
          c: 1.08485,
          v: 462
        },
        {
          t: 54000,
          o: 1.08489,
          h: 1.08534,
          l: 1.08458,
          c: 1.08524,
          v: 484
        },
        {
          t: 54300,
          o: 1.08521,
          h: 1.0858,
          l: 1.0852,
          c: 1.08568,
          v: 445
        },
        {
          t: 54600,
          o: 1.0857,
          h: 1.08573,
          l: 1.08515,
          c: 1.08525,
          v: 425
        },
        {
          t: 54900,
          o: 1.08524,
          h: 1.08556,
          l: 1.08514,
          c: 1.08525,
          v: 295
        },
        {
          t: 55200,
          o: 1.08526,
          h: 1.08559,
          l: 1.08507,
          c: 1.08547,
          v: 329
        },
        {
          t: 55500,
          o: 1.08546,
          h: 1.08576,
          l: 1.08521,
          c: 1.08573,
          v: 379
        },
        {
          t: 55800,
          o: 1.08573,
          h: 1.08594,
          l: 1.08544,
          c: 1.0855,
          v: 434
        },
        {
          t: 56100,
          o: 1.08548,
          h: 1.0858,
          l: 1.08523,
          c: 1.0854,
          v: 446
        },
        {
          t: 56400,
          o: 1.08541,
          h: 1.08563,
          l: 1.08496,
          c: 1.0851,
          v: 441
        },
        {
          t: 56700,
          o: 1.0851,
          h: 1.0857,
          l: 1.0851,
          c: 1.08567,
          v: 374
        },
        {
          t: 57000,
          o: 1.08566,
          h: 1.08572,
          l: 1.0849,
          c: 1.08569,
          v: 380
        },
        {
          t: 57300,
          o: 1.08563,
          h: 1.08601,
          l: 1.08528,
          c: 1.08553,
          v: 405
        },
        {
          t: 57600,
          o: 1.08556,
          h: 1.08632,
          l: 1.08545,
          c: 1.0858,
          v: 414
        },
        {
          t: 57900,
          o: 1.08576,
          h: 1.0858,
          l: 1.08518,
          c: 1.08519,
          v: 274
        },
        {
          t: 58200,
          o: 1.08521,
          h: 1.08543,
          l: 1.08454,
          c: 1.0849,
          v: 367
        },
        {
          t: 58500,
          o: 1.0849,
          h: 1.08535,
          l: 1.08486,
          c: 1.08535,
          v: 318
        },
        {
          t: 58800,
          o: 1.08531,
          h: 1.08574,
          l: 1.08527,
          c: 1.08574,
          v: 282
        },
        {
          t: 59100,
          o: 1.08573,
          h: 1.08602,
          l: 1.0856,
          c: 1.08602,
          v: 275
        },
        {
          t: 59400,
          o: 1.08599,
          h: 1.08622,
          l: 1.0859,
          c: 1.0861,
          v: 352
        },
        {
          t: 59700,
          o: 1.08612,
          h: 1.08621,
          l: 1.08571,
          c: 1.08579,
          v: 317
        },
        {
          t: 60000,
          o: 1.08578,
          h: 1.0859,
          l: 1.08541,
          c: 1.0858,
          v: 356
        },
        {
          t: 60300,
          o: 1.08579,
          h: 1.08592,
          l: 1.08528,
          c: 1.0853,
          v: 261
        },
        {
          t: 60600,
          o: 1.08528,
          h: 1.08546,
          l: 1.08516,
          c: 1.08535,
          v: 273
        },
        {
          t: 60900,
          o: 1.08538,
          h: 1.0856,
          l: 1.08536,
          c: 1.08555,
          v: 302
        },
        {
          t: 61200,
          o: 1.08555,
          h: 1.08589,
          l: 1.08525,
          c: 1.08573,
          v: 321
        },
        {
          t: 61500,
          o: 1.08574,
          h: 1.08604,
          l: 1.08566,
          c: 1.08579,
          v: 276
        },
        {
          t: 61800,
          o: 1.08578,
          h: 1.08628,
          l: 1.08576,
          c: 1.08617,
          v: 341
        },
        {
          t: 62100,
          o: 1.08614,
          h: 1.08633,
          l: 1.08588,
          c: 1.08588,
          v: 298
        },
        {
          t: 62400,
          o: 1.0859,
          h: 1.08628,
          l: 1.0859,
          c: 1.08618,
          v: 234
        },
        {
          t: 62700,
          o: 1.08617,
          h: 1.08678,
          l: 1.08611,
          c: 1.08612,
          v: 406
        },
        {
          t: 63000,
          o: 1.08613,
          h: 1.08661,
          l: 1.08593,
          c: 1.08643,
          v: 304
        },
        {
          t: 63300,
          o: 1.08643,
          h: 1.08646,
          l: 1.08625,
          c: 1.08632,
          v: 274
        },
        {
          t: 63600,
          o: 1.08634,
          h: 1.0865,
          l: 1.08626,
          c: 1.08634,
          v: 264
        },
        {
          t: 63900,
          o: 1.08635,
          h: 1.0868,
          l: 1.08627,
          c: 1.08673,
          v: 279
        },
        {
          t: 64200,
          o: 1.08674,
          h: 1.08712,
          l: 1.08672,
          c: 1.08691,
          v: 285
        },
        {
          t: 64500,
          o: 1.08688,
          h: 1.08714,
          l: 1.08688,
          c: 1.08704,
          v: 203
        },
        {
          t: 64800,
          o: 1.08704,
          h: 1.08713,
          l: 1.0868,
          c: 1.08693,
          v: 282
        },
        {
          t: 65100,
          o: 1.08693,
          h: 1.08715,
          l: 1.08692,
          c: 1.08698,
          v: 232
        },
        {
          t: 65400,
          o: 1.08698,
          h: 1.0871,
          l: 1.08695,
          c: 1.08702,
          v: 154
        },
        {
          t: 65700,
          o: 1.08701,
          h: 1.08708,
          l: 1.08696,
          c: 1.08699,
          v: 199
        },
        {
          t: 66000,
          o: 1.08698,
          h: 1.08698,
          l: 1.08672,
          c: 1.08672,
          v: 241
        },
        {
          t: 66300,
          o: 1.08673,
          h: 1.08686,
          l: 1.08667,
          c: 1.08675,
          v: 226
        },
        {
          t: 66600,
          o: 1.08676,
          h: 1.08681,
          l: 1.0866,
          c: 1.08661,
          v: 200
        },
        {
          t: 66900,
          o: 1.08664,
          h: 1.08668,
          l: 1.08648,
          c: 1.0865,
          v: 157
        },
        {
          t: 67200,
          o: 1.08651,
          h: 1.08672,
          l: 1.08641,
          c: 1.08642,
          v: 136
        },
        {
          t: 67500,
          o: 1.08642,
          h: 1.08648,
          l: 1.08628,
          c: 1.08639,
          v: 122
        },
        {
          t: 67800,
          o: 1.08637,
          h: 1.08638,
          l: 1.08604,
          c: 1.08609,
          v: 127
        },
        {
          t: 68100,
          o: 1.08608,
          h: 1.08641,
          l: 1.08608,
          c: 1.08634,
          v: 151
        },
        {
          t: 68400,
          o: 1.08633,
          h: 1.08635,
          l: 1.08618,
          c: 1.08619,
          v: 153
        },
        {
          t: 68700,
          o: 1.08618,
          h: 1.08619,
          l: 1.08581,
          c: 1.08581,
          v: 193
        },
        {
          t: 69000,
          o: 1.0858,
          h: 1.08596,
          l: 1.08567,
          c: 1.08588,
          v: 210
        },
        {
          t: 69300,
          o: 1.08588,
          h: 1.08612,
          l: 1.08579,
          c: 1.08598,
          v: 207
        },
        {
          t: 69600,
          o: 1.08598,
          h: 1.08598,
          l: 1.08545,
          c: 1.08552,
          v: 217
        },
        {
          t: 69900,
          o: 1.0855,
          h: 1.0855,
          l: 1.08516,
          c: 1.08537,
          v: 317
        },
        {
          t: 70200,
          o: 1.08538,
          h: 1.0854,
          l: 1.08496,
          c: 1.08506,
          v: 383
        },
        {
          t: 70500,
          o: 1.08505,
          h: 1.08521,
          l: 1.08461,
          c: 1.08473,
          v: 266
        },
        {
          t: 70800,
          o: 1.08471,
          h: 1.08488,
          l: 1.08466,
          c: 1.08483,
          v: 223
        },
        {
          t: 71100,
          o: 1.08483,
          h: 1.08484,
          l: 1.08408,
          c: 1.08468,
          v: 254
        },
        {
          t: 71400,
          o: 1.08466,
          h: 1.08484,
          l: 1.08452,
          c: 1.08458,
          v: 281
        },
        {
          t: 71700,
          o: 1.08462,
          h: 1.08485,
          l: 1.08417,
          c: 1.08425,
          v: 331
        },
        {
          t: 72000,
          o: 1.08426,
          h: 1.08464,
          l: 1.08406,
          c: 1.08458,
          v: 286
        },
        {
          t: 72300,
          o: 1.08457,
          h: 1.08472,
          l: 1.08448,
          c: 1.08459,
          v: 242
        },
        {
          t: 72600,
          o: 1.08458,
          h: 1.08475,
          l: 1.08452,
          c: 1.08468,
          v: 195
        },
        {
          t: 72900,
          o: 1.08472,
          h: 1.08495,
          l: 1.08472,
          c: 1.08479,
          v: 260
        },
        {
          t: 73200,
          o: 1.08478,
          h: 1.08489,
          l: 1.08467,
          c: 1.08487,
          v: 214
        },
        {
          t: 73500,
          o: 1.08486,
          h: 1.08494,
          l: 1.0848,
          c: 1.08484,
          v: 241
        },
        {
          t: 73800,
          o: 1.08485,
          h: 1.08497,
          l: 1.08477,
          c: 1.08491,
          v: 239
        },
        {
          t: 74100,
          o: 1.08493,
          h: 1.08518,
          l: 1.0849,
          c: 1.08513,
          v: 285
        },
        {
          t: 74400,
          o: 1.08513,
          h: 1.08554,
          l: 1.08505,
          c: 1.08541,
          v: 208
        },
        {
          t: 74700,
          o: 1.0854,
          h: 1.08547,
          l: 1.08531,
          c: 1.08544,
          v: 212
        },
        {
          t: 75000,
          o: 1.08543,
          h: 1.08563,
          l: 1.08534,
          c: 1.08542,
          v: 286
        },
        {
          t: 75300,
          o: 1.08542,
          h: 1.08549,
          l: 1.08514,
          c: 1.08518,
          v: 256
        },
        {
          t: 75600,
          o: 1.08521,
          h: 1.08525,
          l: 1.08507,
          c: 1.08507,
          v: 137
        },
        {
          t: 75900,
          o: 1.08511,
          h: 1.08535,
          l: 1.08511,
          c: 1.08528,
          v: 63
        },
        {
          t: 76200,
          o: 1.0853,
          h: 1.08551,
          l: 1.08528,
          c: 1.08548,
          v: 125
        },
        {
          t: 76500,
          o: 1.08547,
          h: 1.0855,
          l: 1.08516,
          c: 1.08521,
          v: 83
        },
        {
          t: 76800,
          o: 1.08524,
          h: 1.08548,
          l: 1.08523,
          c: 1.08543,
          v: 57
        },
        {
          t: 77100,
          o: 1.08543,
          h: 1.08545,
          l: 1.08518,
          c: 1.08521,
          v: 54
        },
        {
          t: 77400,
          o: 1.08522,
          h: 1.08532,
          l: 1.08522,
          c: 1.08522,
          v: 86
        },
        {
          t: 77700,
          o: 1.0852,
          h: 1.08521,
          l: 1.08496,
          c: 1.08506,
          v: 76
        },
        {
          t: 78000,
          o: 1.08506,
          h: 1.08524,
          l: 1.08503,
          c: 1.08519,
          v: 64
        },
        {
          t: 78300,
          o: 1.08519,
          h: 1.08522,
          l: 1.08509,
          c: 1.08511,
          v: 83
        },
        {
          t: 78600,
          o: 1.08512,
          h: 1.08519,
          l: 1.08507,
          c: 1.08517,
          v: 65
        },
        {
          t: 78900,
          o: 1.08518,
          h: 1.08551,
          l: 1.08514,
          c: 1.08518,
          v: 121
        },
        {
          t: 79200,
          o: 1.08516,
          h: 1.08519,
          l: 1.08506,
          c: 1.08515,
          v: 38
        },
        {
          t: 79500,
          o: 1.08513,
          h: 1.0852,
          l: 1.08511,
          c: 1.0852,
          v: 23
        },
        {
          t: 79800,
          o: 1.08517,
          h: 1.08525,
          l: 1.08514,
          c: 1.08524,
          v: 26
        },
        {
          t: 80100,
          o: 1.08517,
          h: 1.08533,
          l: 1.08506,
          c: 1.08506,
          v: 106
        },
        {
          t: 80400,
          o: 1.08512,
          h: 1.08535,
          l: 1.08507,
          c: 1.0853,
          v: 98
        },
        {
          t: 80700,
          o: 1.08529,
          h: 1.08535,
          l: 1.08507,
          c: 1.08523,
          v: 28
        },
        {
          t: 81000,
          o: 1.08523,
          h: 1.08542,
          l: 1.08506,
          c: 1.08538,
          v: 125
        },
        {
          t: 81300,
          o: 1.08532,
          h: 1.08542,
          l: 1.08506,
          c: 1.08536,
          v: 97
        },
        {
          t: 81600,
          o: 1.08536,
          h: 1.08542,
          l: 1.08506,
          c: 1.08532,
          v: 95
        },
        {
          t: 81900,
          o: 1.08532,
          h: 1.08549,
          l: 1.08524,
          c: 1.08548,
          v: 66
        },
        {
          t: 82200,
          o: 1.08547,
          h: 1.0855,
          l: 1.08506,
          c: 1.08508,
          v: 151
        },
        {
          t: 82500,
          o: 1.08508,
          h: 1.08533,
          l: 1.08506,
          c: 1.08508,
          v: 339
        },
        {
          t: 82800,
          o: 1.08508,
          h: 1.08535,
          l: 1.08508,
          c: 1.08523,
          v: 172
        },
        {
          t: 83100,
          o: 1.08523,
          h: 1.08524,
          l: 1.08515,
          c: 1.08515,
          v: 104
        },
        {
          t: 83400,
          o: 1.08515,
          h: 1.08518,
          l: 1.0849,
          c: 1.08494,
          v: 98
        },
        {
          t: 83700,
          o: 1.08495,
          h: 1.08499,
          l: 1.08485,
          c: 1.08495,
          v: 153
        },
        {
          t: 84000,
          o: 1.08496,
          h: 1.0851,
          l: 1.08493,
          c: 1.08508,
          v: 99
        },
        {
          t: 84300,
          o: 1.08506,
          h: 1.08509,
          l: 1.08495,
          c: 1.08495,
          v: 153
        },
        {
          t: 84600,
          o: 1.08496,
          h: 1.0852,
          l: 1.08494,
          c: 1.08519,
          v: 133
        },
        {
          t: 84900,
          o: 1.0852,
          h: 1.08528,
          l: 1.08519,
          c: 1.08525,
          v: 121
        },
        {
          t: 85200,
          o: 1.08524,
          h: 1.08531,
          l: 1.08515,
          c: 1.08519,
          v: 142
        },
        {
          t: 85500,
          o: 1.0852,
          h: 1.08522,
          l: 1.08499,
          c: 1.08501,
          v: 170
        },
        {
          t: 85800,
          o: 1.08499,
          h: 1.08504,
          l: 1.08494,
          c: 1.08497,
          v: 134
        },
        {
          t: 86100,
          o: 1.08495,
          h: 1.08495,
          l: 1.08479,
          c: 1.08481,
          v: 144
        }
      ]
    },
    {
      d: 1582588800,
      b: [
        {
          t: 0,
          o: 1.0848,
          h: 1.08507,
          l: 1.08477,
          c: 1.085,
          v: 265
        },
        {
          t: 300,
          o: 1.08501,
          h: 1.0851,
          l: 1.08496,
          c: 1.08507,
          v: 220
        },
        {
          t: 600,
          o: 1.08507,
          h: 1.08519,
          l: 1.085,
          c: 1.08513,
          v: 239
        },
        {
          t: 900,
          o: 1.08512,
          h: 1.08524,
          l: 1.0851,
          c: 1.08521,
          v: 218
        },
        {
          t: 1200,
          o: 1.08518,
          h: 1.08525,
          l: 1.08513,
          c: 1.0852,
          v: 154
        },
        {
          t: 1500,
          o: 1.08516,
          h: 1.08518,
          l: 1.08507,
          c: 1.08512,
          v: 120
        },
        {
          t: 1800,
          o: 1.08512,
          h: 1.08522,
          l: 1.08509,
          c: 1.08511,
          v: 110
        },
        {
          t: 2100,
          o: 1.08513,
          h: 1.08514,
          l: 1.08505,
          c: 1.0851,
          v: 86
        },
        {
          t: 2400,
          o: 1.0851,
          h: 1.0852,
          l: 1.08506,
          c: 1.08516,
          v: 127
        },
        {
          t: 2700,
          o: 1.08516,
          h: 1.08524,
          l: 1.0851,
          c: 1.08517,
          v: 99
        },
        {
          t: 3000,
          o: 1.08516,
          h: 1.08521,
          l: 1.08502,
          c: 1.08503,
          v: 95
        },
        {
          t: 3300,
          o: 1.08503,
          h: 1.08514,
          l: 1.08503,
          c: 1.08514,
          v: 92
        },
        {
          t: 3600,
          o: 1.08514,
          h: 1.08516,
          l: 1.08495,
          c: 1.085,
          v: 135
        },
        {
          t: 3900,
          o: 1.085,
          h: 1.08504,
          l: 1.08486,
          c: 1.0849,
          v: 124
        },
        {
          t: 4200,
          o: 1.0849,
          h: 1.0849,
          l: 1.08446,
          c: 1.08481,
          v: 261
        },
        {
          t: 4500,
          o: 1.08481,
          h: 1.08488,
          l: 1.08474,
          c: 1.08482,
          v: 149
        },
        {
          t: 4800,
          o: 1.08483,
          h: 1.08553,
          l: 1.08475,
          c: 1.08553,
          v: 239
        },
        {
          t: 5100,
          o: 1.08554,
          h: 1.08574,
          l: 1.08549,
          c: 1.0856,
          v: 218
        },
        {
          t: 5400,
          o: 1.0856,
          h: 1.08585,
          l: 1.0856,
          c: 1.08571,
          v: 206
        },
        {
          t: 5700,
          o: 1.08568,
          h: 1.08587,
          l: 1.08558,
          c: 1.08575,
          v: 242
        },
        {
          t: 6000,
          o: 1.08576,
          h: 1.08592,
          l: 1.0857,
          c: 1.0857,
          v: 157
        },
        {
          t: 6300,
          o: 1.08568,
          h: 1.08578,
          l: 1.0856,
          c: 1.08572,
          v: 114
        },
        {
          t: 6600,
          o: 1.08572,
          h: 1.08581,
          l: 1.08565,
          c: 1.08572,
          v: 159
        },
        {
          t: 6900,
          o: 1.08571,
          h: 1.08593,
          l: 1.0857,
          c: 1.0858,
          v: 155
        },
        {
          t: 7200,
          o: 1.08579,
          h: 1.08611,
          l: 1.08577,
          c: 1.08608,
          v: 180
        },
        {
          t: 7500,
          o: 1.08607,
          h: 1.08627,
          l: 1.08602,
          c: 1.08611,
          v: 203
        },
        {
          t: 7800,
          o: 1.08612,
          h: 1.08612,
          l: 1.08587,
          c: 1.08599,
          v: 169
        },
        {
          t: 8100,
          o: 1.08598,
          h: 1.08604,
          l: 1.08589,
          c: 1.08598,
          v: 124
        },
        {
          t: 8400,
          o: 1.08599,
          h: 1.08609,
          l: 1.08592,
          c: 1.08598,
          v: 98
        },
        {
          t: 8700,
          o: 1.08598,
          h: 1.08598,
          l: 1.08579,
          c: 1.08587,
          v: 87
        },
        {
          t: 9000,
          o: 1.08588,
          h: 1.08603,
          l: 1.08587,
          c: 1.08603,
          v: 86
        },
        {
          t: 9300,
          o: 1.08602,
          h: 1.08612,
          l: 1.08597,
          c: 1.08608,
          v: 147
        },
        {
          t: 9600,
          o: 1.08609,
          h: 1.08611,
          l: 1.08592,
          c: 1.08604,
          v: 132
        },
        {
          t: 9900,
          o: 1.08605,
          h: 1.08612,
          l: 1.08596,
          c: 1.08597,
          v: 138
        },
        {
          t: 10200,
          o: 1.08598,
          h: 1.08601,
          l: 1.08592,
          c: 1.08596,
          v: 173
        },
        {
          t: 10500,
          o: 1.08597,
          h: 1.08641,
          l: 1.08591,
          c: 1.0863,
          v: 184
        },
        {
          t: 10800,
          o: 1.08627,
          h: 1.08632,
          l: 1.08622,
          c: 1.08628,
          v: 115
        },
        {
          t: 11100,
          o: 1.08627,
          h: 1.08638,
          l: 1.08626,
          c: 1.08627,
          v: 153
        },
        {
          t: 11400,
          o: 1.08628,
          h: 1.08642,
          l: 1.08626,
          c: 1.08632,
          v: 146
        },
        {
          t: 11700,
          o: 1.08631,
          h: 1.08635,
          l: 1.08626,
          c: 1.08632,
          v: 131
        },
        {
          t: 12000,
          o: 1.08631,
          h: 1.08654,
          l: 1.08631,
          c: 1.08651,
          v: 149
        },
        {
          t: 12300,
          o: 1.08652,
          h: 1.08661,
          l: 1.0865,
          c: 1.08657,
          v: 91
        },
        {
          t: 12600,
          o: 1.08657,
          h: 1.0867,
          l: 1.08657,
          c: 1.08666,
          v: 104
        },
        {
          t: 12900,
          o: 1.08665,
          h: 1.0867,
          l: 1.08664,
          c: 1.08669,
          v: 111
        },
        {
          t: 13200,
          o: 1.0867,
          h: 1.08671,
          l: 1.08645,
          c: 1.0865,
          v: 135
        },
        {
          t: 13500,
          o: 1.08651,
          h: 1.08657,
          l: 1.08647,
          c: 1.08647,
          v: 69
        },
        {
          t: 13800,
          o: 1.08646,
          h: 1.08653,
          l: 1.08639,
          c: 1.08639,
          v: 84
        },
        {
          t: 14100,
          o: 1.08638,
          h: 1.08643,
          l: 1.08623,
          c: 1.08623,
          v: 113
        },
        {
          t: 14400,
          o: 1.08623,
          h: 1.08624,
          l: 1.08605,
          c: 1.08609,
          v: 99
        },
        {
          t: 14700,
          o: 1.08609,
          h: 1.08621,
          l: 1.08607,
          c: 1.08621,
          v: 67
        },
        {
          t: 15000,
          o: 1.0862,
          h: 1.08621,
          l: 1.08615,
          c: 1.08619,
          v: 65
        },
        {
          t: 15300,
          o: 1.08619,
          h: 1.08624,
          l: 1.08609,
          c: 1.0861,
          v: 86
        },
        {
          t: 15600,
          o: 1.08609,
          h: 1.0861,
          l: 1.08604,
          c: 1.08606,
          v: 61
        },
        {
          t: 15900,
          o: 1.08607,
          h: 1.08613,
          l: 1.08604,
          c: 1.08605,
          v: 49
        },
        {
          t: 16200,
          o: 1.08604,
          h: 1.08605,
          l: 1.08598,
          c: 1.08605,
          v: 66
        },
        {
          t: 16500,
          o: 1.08606,
          h: 1.08611,
          l: 1.08605,
          c: 1.08609,
          v: 53
        },
        {
          t: 16800,
          o: 1.08609,
          h: 1.08622,
          l: 1.08609,
          c: 1.08622,
          v: 79
        },
        {
          t: 17100,
          o: 1.08623,
          h: 1.08623,
          l: 1.08609,
          c: 1.08612,
          v: 78
        },
        {
          t: 17400,
          o: 1.08613,
          h: 1.08613,
          l: 1.0861,
          c: 1.08612,
          v: 37
        },
        {
          t: 17700,
          o: 1.08612,
          h: 1.08613,
          l: 1.08602,
          c: 1.08603,
          v: 67
        },
        {
          t: 18000,
          o: 1.08603,
          h: 1.08604,
          l: 1.08588,
          c: 1.08589,
          v: 85
        },
        {
          t: 18300,
          o: 1.08588,
          h: 1.08594,
          l: 1.08588,
          c: 1.08593,
          v: 35
        },
        {
          t: 18600,
          o: 1.08593,
          h: 1.08595,
          l: 1.08589,
          c: 1.08591,
          v: 36
        },
        {
          t: 18900,
          o: 1.08591,
          h: 1.08593,
          l: 1.0858,
          c: 1.0858,
          v: 69
        },
        {
          t: 19200,
          o: 1.08579,
          h: 1.08579,
          l: 1.08573,
          c: 1.08574,
          v: 38
        },
        {
          t: 19500,
          o: 1.08573,
          h: 1.08574,
          l: 1.08548,
          c: 1.08549,
          v: 123
        },
        {
          t: 19800,
          o: 1.0855,
          h: 1.08554,
          l: 1.08533,
          c: 1.08535,
          v: 148
        },
        {
          t: 20100,
          o: 1.08534,
          h: 1.08547,
          l: 1.08534,
          c: 1.08546,
          v: 108
        },
        {
          t: 20400,
          o: 1.08546,
          h: 1.08547,
          l: 1.08516,
          c: 1.08523,
          v: 119
        },
        {
          t: 20700,
          o: 1.08525,
          h: 1.0853,
          l: 1.08516,
          c: 1.08519,
          v: 70
        },
        {
          t: 21000,
          o: 1.08521,
          h: 1.0853,
          l: 1.08519,
          c: 1.08529,
          v: 89
        },
        {
          t: 21300,
          o: 1.08527,
          h: 1.0853,
          l: 1.08502,
          c: 1.08506,
          v: 163
        },
        {
          t: 21600,
          o: 1.08505,
          h: 1.08516,
          l: 1.08503,
          c: 1.0851,
          v: 109
        },
        {
          t: 21900,
          o: 1.08511,
          h: 1.08511,
          l: 1.08492,
          c: 1.08494,
          v: 116
        },
        {
          t: 22200,
          o: 1.08493,
          h: 1.08518,
          l: 1.08488,
          c: 1.0851,
          v: 188
        },
        {
          t: 22500,
          o: 1.08509,
          h: 1.08523,
          l: 1.08501,
          c: 1.0852,
          v: 171
        },
        {
          t: 22800,
          o: 1.08521,
          h: 1.08523,
          l: 1.08516,
          c: 1.08517,
          v: 164
        },
        {
          t: 23100,
          o: 1.08516,
          h: 1.08546,
          l: 1.08516,
          c: 1.08546,
          v: 241
        },
        {
          t: 23400,
          o: 1.08545,
          h: 1.08571,
          l: 1.08543,
          c: 1.08562,
          v: 244
        },
        {
          t: 23700,
          o: 1.08562,
          h: 1.08563,
          l: 1.08539,
          c: 1.08545,
          v: 108
        },
        {
          t: 24000,
          o: 1.08543,
          h: 1.08552,
          l: 1.08524,
          c: 1.0853,
          v: 132
        },
        {
          t: 24300,
          o: 1.08532,
          h: 1.08546,
          l: 1.08508,
          c: 1.08525,
          v: 223
        },
        {
          t: 24600,
          o: 1.08522,
          h: 1.0855,
          l: 1.08522,
          c: 1.08535,
          v: 187
        },
        {
          t: 24900,
          o: 1.08536,
          h: 1.08536,
          l: 1.08497,
          c: 1.08513,
          v: 272
        },
        {
          t: 25200,
          o: 1.08514,
          h: 1.08522,
          l: 1.08483,
          c: 1.08497,
          v: 309
        },
        {
          t: 25500,
          o: 1.08495,
          h: 1.08499,
          l: 1.08478,
          c: 1.08487,
          v: 188
        },
        {
          t: 25800,
          o: 1.08488,
          h: 1.08505,
          l: 1.08471,
          c: 1.08504,
          v: 214
        },
        {
          t: 26100,
          o: 1.08505,
          h: 1.08517,
          l: 1.08494,
          c: 1.08509,
          v: 146
        },
        {
          t: 26400,
          o: 1.0851,
          h: 1.08526,
          l: 1.08499,
          c: 1.08526,
          v: 199
        },
        {
          t: 26700,
          o: 1.08525,
          h: 1.08548,
          l: 1.08522,
          c: 1.08534,
          v: 299
        },
        {
          t: 27000,
          o: 1.08535,
          h: 1.08535,
          l: 1.08475,
          c: 1.08482,
          v: 327
        },
        {
          t: 27300,
          o: 1.08484,
          h: 1.08503,
          l: 1.08478,
          c: 1.08487,
          v: 200
        },
        {
          t: 27600,
          o: 1.08491,
          h: 1.08563,
          l: 1.08488,
          c: 1.08557,
          v: 295
        },
        {
          t: 27900,
          o: 1.0856,
          h: 1.0858,
          l: 1.08556,
          c: 1.08564,
          v: 289
        },
        {
          t: 28200,
          o: 1.08563,
          h: 1.08585,
          l: 1.08525,
          c: 1.08536,
          v: 291
        },
        {
          t: 28500,
          o: 1.08531,
          h: 1.08552,
          l: 1.08519,
          c: 1.08529,
          v: 276
        },
        {
          t: 28800,
          o: 1.08529,
          h: 1.08529,
          l: 1.08495,
          c: 1.0851,
          v: 383
        },
        {
          t: 29100,
          o: 1.08511,
          h: 1.08547,
          l: 1.08506,
          c: 1.08541,
          v: 378
        },
        {
          t: 29400,
          o: 1.08547,
          h: 1.08556,
          l: 1.08518,
          c: 1.08542,
          v: 409
        },
        {
          t: 29700,
          o: 1.08541,
          h: 1.08558,
          l: 1.08534,
          c: 1.08547,
          v: 283
        },
        {
          t: 30000,
          o: 1.08547,
          h: 1.08599,
          l: 1.08541,
          c: 1.08592,
          v: 398
        },
        {
          t: 30300,
          o: 1.08593,
          h: 1.08642,
          l: 1.08576,
          c: 1.08631,
          v: 366
        },
        {
          t: 30600,
          o: 1.08627,
          h: 1.08652,
          l: 1.08608,
          c: 1.08614,
          v: 353
        },
        {
          t: 30900,
          o: 1.08616,
          h: 1.08655,
          l: 1.08603,
          c: 1.08645,
          v: 340
        },
        {
          t: 31200,
          o: 1.08644,
          h: 1.08646,
          l: 1.08607,
          c: 1.08627,
          v: 328
        },
        {
          t: 31500,
          o: 1.08628,
          h: 1.08653,
          l: 1.08622,
          c: 1.08642,
          v: 336
        },
        {
          t: 31800,
          o: 1.08641,
          h: 1.08647,
          l: 1.08626,
          c: 1.08635,
          v: 282
        },
        {
          t: 32100,
          o: 1.08636,
          h: 1.08655,
          l: 1.08621,
          c: 1.0863,
          v: 321
        },
        {
          t: 32400,
          o: 1.08629,
          h: 1.08654,
          l: 1.08621,
          c: 1.08653,
          v: 240
        },
        {
          t: 32700,
          o: 1.08652,
          h: 1.08655,
          l: 1.08613,
          c: 1.08646,
          v: 329
        },
        {
          t: 33000,
          o: 1.08647,
          h: 1.08651,
          l: 1.08588,
          c: 1.08594,
          v: 390
        },
        {
          t: 33300,
          o: 1.08593,
          h: 1.08616,
          l: 1.08572,
          c: 1.08612,
          v: 318
        },
        {
          t: 33600,
          o: 1.08614,
          h: 1.08644,
          l: 1.08599,
          c: 1.08601,
          v: 301
        },
        {
          t: 33900,
          o: 1.086,
          h: 1.08605,
          l: 1.08549,
          c: 1.08571,
          v: 306
        },
        {
          t: 34200,
          o: 1.08568,
          h: 1.08596,
          l: 1.08554,
          c: 1.08572,
          v: 248
        },
        {
          t: 34500,
          o: 1.08577,
          h: 1.08603,
          l: 1.08566,
          c: 1.0857,
          v: 241
        },
        {
          t: 34800,
          o: 1.08568,
          h: 1.0857,
          l: 1.08517,
          c: 1.08537,
          v: 257
        },
        {
          t: 35100,
          o: 1.08537,
          h: 1.08537,
          l: 1.08496,
          c: 1.08527,
          v: 254
        },
        {
          t: 35400,
          o: 1.08526,
          h: 1.08526,
          l: 1.08483,
          c: 1.08491,
          v: 292
        },
        {
          t: 35700,
          o: 1.08493,
          h: 1.08505,
          l: 1.08485,
          c: 1.08501,
          v: 241
        },
        {
          t: 36000,
          o: 1.085,
          h: 1.085,
          l: 1.08423,
          c: 1.08451,
          v: 256
        },
        {
          t: 36300,
          o: 1.08453,
          h: 1.0846,
          l: 1.08415,
          c: 1.08425,
          v: 221
        },
        {
          t: 36600,
          o: 1.08425,
          h: 1.08445,
          l: 1.08418,
          c: 1.08433,
          v: 274
        },
        {
          t: 36900,
          o: 1.08432,
          h: 1.08451,
          l: 1.08411,
          c: 1.08422,
          v: 247
        },
        {
          t: 37200,
          o: 1.08422,
          h: 1.08453,
          l: 1.08396,
          c: 1.084,
          v: 330
        },
        {
          t: 37500,
          o: 1.08396,
          h: 1.08417,
          l: 1.08374,
          c: 1.08377,
          v: 243
        },
        {
          t: 37800,
          o: 1.08381,
          h: 1.08385,
          l: 1.08351,
          c: 1.08371,
          v: 317
        },
        {
          t: 38100,
          o: 1.08369,
          h: 1.08398,
          l: 1.08355,
          c: 1.08383,
          v: 238
        },
        {
          t: 38400,
          o: 1.08383,
          h: 1.08388,
          l: 1.08344,
          c: 1.08379,
          v: 223
        },
        {
          t: 38700,
          o: 1.0838,
          h: 1.08392,
          l: 1.08374,
          c: 1.08385,
          v: 235
        },
        {
          t: 39000,
          o: 1.08385,
          h: 1.0839,
          l: 1.08365,
          c: 1.08372,
          v: 150
        },
        {
          t: 39300,
          o: 1.08371,
          h: 1.08382,
          l: 1.08351,
          c: 1.08376,
          v: 201
        },
        {
          t: 39600,
          o: 1.08378,
          h: 1.08401,
          l: 1.08341,
          c: 1.08341,
          v: 230
        },
        {
          t: 39900,
          o: 1.08342,
          h: 1.08373,
          l: 1.08336,
          c: 1.08362,
          v: 241
        },
        {
          t: 40200,
          o: 1.08362,
          h: 1.08362,
          l: 1.08339,
          c: 1.08343,
          v: 159
        },
        {
          t: 40500,
          o: 1.08343,
          h: 1.08343,
          l: 1.08313,
          c: 1.08331,
          v: 232
        },
        {
          t: 40800,
          o: 1.08333,
          h: 1.08335,
          l: 1.08315,
          c: 1.08327,
          v: 161
        },
        {
          t: 41100,
          o: 1.08331,
          h: 1.08338,
          l: 1.08312,
          c: 1.08314,
          v: 187
        },
        {
          t: 41400,
          o: 1.08315,
          h: 1.08327,
          l: 1.08301,
          c: 1.0831,
          v: 246
        },
        {
          t: 41700,
          o: 1.0831,
          h: 1.08328,
          l: 1.08304,
          c: 1.08321,
          v: 305
        },
        {
          t: 42000,
          o: 1.0832,
          h: 1.08335,
          l: 1.08296,
          c: 1.08325,
          v: 271
        },
        {
          t: 42300,
          o: 1.08323,
          h: 1.08379,
          l: 1.08321,
          c: 1.08355,
          v: 266
        },
        {
          t: 42600,
          o: 1.08353,
          h: 1.0836,
          l: 1.08342,
          c: 1.08357,
          v: 253
        },
        {
          t: 42900,
          o: 1.08358,
          h: 1.0837,
          l: 1.08348,
          c: 1.08364,
          v: 228
        },
        {
          t: 43200,
          o: 1.08365,
          h: 1.08375,
          l: 1.08336,
          c: 1.08336,
          v: 249
        },
        {
          t: 43500,
          o: 1.08334,
          h: 1.0834,
          l: 1.08309,
          c: 1.08319,
          v: 163
        },
        {
          t: 43800,
          o: 1.08319,
          h: 1.08354,
          l: 1.08312,
          c: 1.08354,
          v: 210
        },
        {
          t: 44100,
          o: 1.08353,
          h: 1.08367,
          l: 1.08344,
          c: 1.08367,
          v: 197
        },
        {
          t: 44400,
          o: 1.08365,
          h: 1.08372,
          l: 1.08342,
          c: 1.08343,
          v: 348
        },
        {
          t: 44700,
          o: 1.08343,
          h: 1.08354,
          l: 1.08338,
          c: 1.08348,
          v: 208
        },
        {
          t: 45000,
          o: 1.08346,
          h: 1.08362,
          l: 1.08338,
          c: 1.08362,
          v: 182
        },
        {
          t: 45300,
          o: 1.08361,
          h: 1.08361,
          l: 1.0834,
          c: 1.08345,
          v: 201
        },
        {
          t: 45600,
          o: 1.08346,
          h: 1.08378,
          l: 1.08342,
          c: 1.08374,
          v: 193
        },
        {
          t: 45900,
          o: 1.08373,
          h: 1.08377,
          l: 1.08337,
          c: 1.08343,
          v: 266
        },
        {
          t: 46200,
          o: 1.08344,
          h: 1.08359,
          l: 1.08297,
          c: 1.08325,
          v: 240
        },
        {
          t: 46500,
          o: 1.08324,
          h: 1.08368,
          l: 1.08324,
          c: 1.08357,
          v: 220
        },
        {
          t: 46800,
          o: 1.08362,
          h: 1.08383,
          l: 1.08353,
          c: 1.08376,
          v: 249
        },
        {
          t: 47100,
          o: 1.08376,
          h: 1.08403,
          l: 1.08372,
          c: 1.08397,
          v: 266
        },
        {
          t: 47400,
          o: 1.08395,
          h: 1.08401,
          l: 1.08376,
          c: 1.08395,
          v: 216
        },
        {
          t: 47700,
          o: 1.08396,
          h: 1.0842,
          l: 1.08394,
          c: 1.08412,
          v: 194
        },
        {
          t: 48000,
          o: 1.08409,
          h: 1.08429,
          l: 1.08387,
          c: 1.08427,
          v: 266
        },
        {
          t: 48300,
          o: 1.08426,
          h: 1.08467,
          l: 1.08423,
          c: 1.08466,
          v: 271
        },
        {
          t: 48600,
          o: 1.08464,
          h: 1.08493,
          l: 1.08451,
          c: 1.08459,
          v: 338
        },
        {
          t: 48900,
          o: 1.08457,
          h: 1.08468,
          l: 1.08439,
          c: 1.08448,
          v: 275
        },
        {
          t: 49200,
          o: 1.0845,
          h: 1.0845,
          l: 1.08421,
          c: 1.0844,
          v: 213
        },
        {
          t: 49500,
          o: 1.0844,
          h: 1.0844,
          l: 1.08403,
          c: 1.08409,
          v: 200
        },
        {
          t: 49800,
          o: 1.08409,
          h: 1.08443,
          l: 1.08404,
          c: 1.08428,
          v: 185
        },
        {
          t: 50100,
          o: 1.0843,
          h: 1.08434,
          l: 1.08386,
          c: 1.08406,
          v: 308
        },
        {
          t: 50400,
          o: 1.08405,
          h: 1.08405,
          l: 1.08343,
          c: 1.08375,
          v: 368
        },
        {
          t: 50700,
          o: 1.08378,
          h: 1.08431,
          l: 1.0837,
          c: 1.08431,
          v: 280
        },
        {
          t: 51000,
          o: 1.08433,
          h: 1.08453,
          l: 1.08409,
          c: 1.08413,
          v: 215
        },
        {
          t: 51300,
          o: 1.08414,
          h: 1.08414,
          l: 1.08383,
          c: 1.08391,
          v: 254
        },
        {
          t: 51600,
          o: 1.08391,
          h: 1.08431,
          l: 1.08383,
          c: 1.08401,
          v: 269
        },
        {
          t: 51900,
          o: 1.08403,
          h: 1.08433,
          l: 1.08403,
          c: 1.0842,
          v: 254
        },
        {
          t: 52200,
          o: 1.08422,
          h: 1.08435,
          l: 1.08394,
          c: 1.08434,
          v: 453
        },
        {
          t: 52500,
          o: 1.08436,
          h: 1.08454,
          l: 1.08386,
          c: 1.0841,
          v: 351
        },
        {
          t: 52800,
          o: 1.08412,
          h: 1.08445,
          l: 1.08406,
          c: 1.08433,
          v: 459
        },
        {
          t: 53100,
          o: 1.08433,
          h: 1.08443,
          l: 1.08382,
          c: 1.08386,
          v: 501
        },
        {
          t: 53400,
          o: 1.08385,
          h: 1.08403,
          l: 1.08339,
          c: 1.08343,
          v: 425
        },
        {
          t: 53700,
          o: 1.08342,
          h: 1.0837,
          l: 1.08315,
          c: 1.08354,
          v: 406
        },
        {
          t: 54000,
          o: 1.08358,
          h: 1.08415,
          l: 1.0833,
          c: 1.08383,
          v: 579
        },
        {
          t: 54300,
          o: 1.08384,
          h: 1.08391,
          l: 1.08345,
          c: 1.0836,
          v: 411
        },
        {
          t: 54600,
          o: 1.08359,
          h: 1.08384,
          l: 1.08349,
          c: 1.08371,
          v: 436
        },
        {
          t: 54900,
          o: 1.08373,
          h: 1.08419,
          l: 1.08347,
          c: 1.0836,
          v: 421
        },
        {
          t: 55200,
          o: 1.08358,
          h: 1.08482,
          l: 1.08349,
          c: 1.08477,
          v: 559
        },
        {
          t: 55500,
          o: 1.08476,
          h: 1.08537,
          l: 1.08453,
          c: 1.0848,
          v: 546
        },
        {
          t: 55800,
          o: 1.08479,
          h: 1.08551,
          l: 1.08463,
          c: 1.08493,
          v: 512
        },
        {
          t: 56100,
          o: 1.08497,
          h: 1.08512,
          l: 1.08454,
          c: 1.08495,
          v: 385
        },
        {
          t: 56400,
          o: 1.08498,
          h: 1.08527,
          l: 1.08493,
          c: 1.08497,
          v: 445
        },
        {
          t: 56700,
          o: 1.08498,
          h: 1.08572,
          l: 1.0849,
          c: 1.08565,
          v: 458
        },
        {
          t: 57000,
          o: 1.08566,
          h: 1.08661,
          l: 1.08565,
          c: 1.08628,
          v: 546
        },
        {
          t: 57300,
          o: 1.08626,
          h: 1.08654,
          l: 1.08575,
          c: 1.08644,
          v: 548
        },
        {
          t: 57600,
          o: 1.08643,
          h: 1.08655,
          l: 1.08619,
          c: 1.08652,
          v: 540
        },
        {
          t: 57900,
          o: 1.0865,
          h: 1.08693,
          l: 1.08634,
          c: 1.08668,
          v: 525
        },
        {
          t: 58200,
          o: 1.08669,
          h: 1.08713,
          l: 1.08652,
          c: 1.08689,
          v: 536
        },
        {
          t: 58500,
          o: 1.08689,
          h: 1.08783,
          l: 1.08687,
          c: 1.08775,
          v: 534
        },
        {
          t: 58800,
          o: 1.0878,
          h: 1.08817,
          l: 1.08762,
          c: 1.08817,
          v: 466
        },
        {
          t: 59100,
          o: 1.08815,
          h: 1.08821,
          l: 1.08765,
          c: 1.08786,
          v: 475
        },
        {
          t: 59400,
          o: 1.08784,
          h: 1.08802,
          l: 1.08762,
          c: 1.08782,
          v: 476
        },
        {
          t: 59700,
          o: 1.08786,
          h: 1.08802,
          l: 1.08763,
          c: 1.08789,
          v: 395
        },
        {
          t: 60000,
          o: 1.08786,
          h: 1.0882,
          l: 1.0876,
          c: 1.08818,
          v: 373
        },
        {
          t: 60300,
          o: 1.08814,
          h: 1.08816,
          l: 1.08726,
          c: 1.0873,
          v: 365
        },
        {
          t: 60600,
          o: 1.08731,
          h: 1.08738,
          l: 1.08631,
          c: 1.08669,
          v: 348
        },
        {
          t: 60900,
          o: 1.08668,
          h: 1.08701,
          l: 1.08649,
          c: 1.08679,
          v: 281
        },
        {
          t: 61200,
          o: 1.08677,
          h: 1.08731,
          l: 1.0866,
          c: 1.08726,
          v: 323
        },
        {
          t: 61500,
          o: 1.08727,
          h: 1.08734,
          l: 1.0871,
          c: 1.08711,
          v: 298
        },
        {
          t: 61800,
          o: 1.08713,
          h: 1.08717,
          l: 1.08687,
          c: 1.08716,
          v: 262
        },
        {
          t: 62100,
          o: 1.08713,
          h: 1.08752,
          l: 1.08707,
          c: 1.0875,
          v: 273
        },
        {
          t: 62400,
          o: 1.08753,
          h: 1.08757,
          l: 1.08729,
          c: 1.08748,
          v: 220
        },
        {
          t: 62700,
          o: 1.08747,
          h: 1.0876,
          l: 1.08731,
          c: 1.08753,
          v: 273
        },
        {
          t: 63000,
          o: 1.08752,
          h: 1.08755,
          l: 1.08731,
          c: 1.08746,
          v: 188
        },
        {
          t: 63300,
          o: 1.08746,
          h: 1.08747,
          l: 1.08709,
          c: 1.08717,
          v: 183
        },
        {
          t: 63600,
          o: 1.08716,
          h: 1.08771,
          l: 1.08716,
          c: 1.0877,
          v: 258
        },
        {
          t: 63900,
          o: 1.0877,
          h: 1.08788,
          l: 1.08758,
          c: 1.08775,
          v: 289
        },
        {
          t: 64200,
          o: 1.08778,
          h: 1.08813,
          l: 1.08777,
          c: 1.08803,
          v: 259
        },
        {
          t: 64500,
          o: 1.08804,
          h: 1.08822,
          l: 1.08797,
          c: 1.08821,
          v: 219
        },
        {
          t: 64800,
          o: 1.08822,
          h: 1.08823,
          l: 1.088,
          c: 1.08812,
          v: 265
        },
        {
          t: 65100,
          o: 1.08812,
          h: 1.08821,
          l: 1.08778,
          c: 1.08783,
          v: 290
        },
        {
          t: 65400,
          o: 1.08783,
          h: 1.08783,
          l: 1.08751,
          c: 1.08756,
          v: 250
        },
        {
          t: 65700,
          o: 1.08759,
          h: 1.08802,
          l: 1.08736,
          c: 1.08791,
          v: 256
        },
        {
          t: 66000,
          o: 1.08795,
          h: 1.08795,
          l: 1.08764,
          c: 1.08775,
          v: 219
        },
        {
          t: 66300,
          o: 1.08773,
          h: 1.08776,
          l: 1.08754,
          c: 1.08755,
          v: 186
        },
        {
          t: 66600,
          o: 1.08755,
          h: 1.08766,
          l: 1.08713,
          c: 1.08722,
          v: 308
        },
        {
          t: 66900,
          o: 1.08723,
          h: 1.08758,
          l: 1.08722,
          c: 1.0873,
          v: 294
        },
        {
          t: 67200,
          o: 1.08726,
          h: 1.0876,
          l: 1.08725,
          c: 1.08746,
          v: 209
        },
        {
          t: 67500,
          o: 1.08747,
          h: 1.08758,
          l: 1.08745,
          c: 1.08756,
          v: 176
        },
        {
          t: 67800,
          o: 1.08756,
          h: 1.08781,
          l: 1.08746,
          c: 1.08765,
          v: 210
        },
        {
          t: 68100,
          o: 1.08767,
          h: 1.08768,
          l: 1.08738,
          c: 1.08764,
          v: 198
        },
        {
          t: 68400,
          o: 1.08765,
          h: 1.0879,
          l: 1.08736,
          c: 1.08736,
          v: 363
        },
        {
          t: 68700,
          o: 1.08733,
          h: 1.08817,
          l: 1.08727,
          c: 1.08807,
          v: 383
        },
        {
          t: 69000,
          o: 1.08805,
          h: 1.08813,
          l: 1.08779,
          c: 1.08786,
          v: 410
        },
        {
          t: 69300,
          o: 1.08786,
          h: 1.08816,
          l: 1.08783,
          c: 1.08795,
          v: 308
        },
        {
          t: 69600,
          o: 1.08796,
          h: 1.08808,
          l: 1.08774,
          c: 1.08805,
          v: 345
        },
        {
          t: 69900,
          o: 1.08804,
          h: 1.0881,
          l: 1.08782,
          c: 1.08805,
          v: 281
        },
        {
          t: 70200,
          o: 1.08805,
          h: 1.08838,
          l: 1.088,
          c: 1.08833,
          v: 329
        },
        {
          t: 70500,
          o: 1.08835,
          h: 1.08883,
          l: 1.0883,
          c: 1.08877,
          v: 337
        },
        {
          t: 70800,
          o: 1.08877,
          h: 1.08897,
          l: 1.08828,
          c: 1.08838,
          v: 376
        },
        {
          t: 71100,
          o: 1.08836,
          h: 1.08856,
          l: 1.08829,
          c: 1.08843,
          v: 300
        },
        {
          t: 71400,
          o: 1.08843,
          h: 1.08849,
          l: 1.08801,
          c: 1.08806,
          v: 300
        },
        {
          t: 71700,
          o: 1.08804,
          h: 1.08814,
          l: 1.08789,
          c: 1.08811,
          v: 341
        },
        {
          t: 72000,
          o: 1.08807,
          h: 1.08807,
          l: 1.08749,
          c: 1.08762,
          v: 379
        },
        {
          t: 72300,
          o: 1.08763,
          h: 1.08773,
          l: 1.08751,
          c: 1.0876,
          v: 373
        },
        {
          t: 72600,
          o: 1.08762,
          h: 1.08804,
          l: 1.08758,
          c: 1.08803,
          v: 272
        },
        {
          t: 72900,
          o: 1.08801,
          h: 1.08808,
          l: 1.08778,
          c: 1.08798,
          v: 321
        },
        {
          t: 73200,
          o: 1.08797,
          h: 1.08851,
          l: 1.08797,
          c: 1.08838,
          v: 261
        },
        {
          t: 73500,
          o: 1.08837,
          h: 1.08843,
          l: 1.0883,
          c: 1.08835,
          v: 233
        },
        {
          t: 73800,
          o: 1.08838,
          h: 1.0886,
          l: 1.08826,
          c: 1.08835,
          v: 291
        },
        {
          t: 74100,
          o: 1.08833,
          h: 1.08841,
          l: 1.08819,
          c: 1.08841,
          v: 164
        },
        {
          t: 74400,
          o: 1.08839,
          h: 1.0884,
          l: 1.08806,
          c: 1.08825,
          v: 261
        },
        {
          t: 74700,
          o: 1.08823,
          h: 1.08827,
          l: 1.08795,
          c: 1.08826,
          v: 333
        },
        {
          t: 75000,
          o: 1.08828,
          h: 1.08833,
          l: 1.08803,
          c: 1.0881,
          v: 282
        },
        {
          t: 75300,
          o: 1.0881,
          h: 1.08827,
          l: 1.088,
          c: 1.08816,
          v: 367
        },
        {
          t: 75600,
          o: 1.08819,
          h: 1.08853,
          l: 1.08816,
          c: 1.08836,
          v: 265
        },
        {
          t: 75900,
          o: 1.08832,
          h: 1.08846,
          l: 1.08825,
          c: 1.08827,
          v: 127
        },
        {
          t: 76200,
          o: 1.08825,
          h: 1.08841,
          l: 1.0882,
          c: 1.08829,
          v: 136
        },
        {
          t: 76500,
          o: 1.08827,
          h: 1.08827,
          l: 1.08805,
          c: 1.08816,
          v: 72
        },
        {
          t: 76800,
          o: 1.0882,
          h: 1.08832,
          l: 1.0882,
          c: 1.08827,
          v: 39
        },
        {
          t: 77100,
          o: 1.08828,
          h: 1.08857,
          l: 1.08822,
          c: 1.08852,
          v: 82
        },
        {
          t: 77400,
          o: 1.0885,
          h: 1.08858,
          l: 1.08842,
          c: 1.08844,
          v: 116
        },
        {
          t: 77700,
          o: 1.08845,
          h: 1.08847,
          l: 1.08833,
          c: 1.08834,
          v: 71
        },
        {
          t: 78000,
          o: 1.08833,
          h: 1.08836,
          l: 1.0883,
          c: 1.08831,
          v: 60
        },
        {
          t: 78300,
          o: 1.08833,
          h: 1.08834,
          l: 1.08811,
          c: 1.08822,
          v: 77
        },
        {
          t: 78600,
          o: 1.08821,
          h: 1.08823,
          l: 1.08809,
          c: 1.0881,
          v: 73
        },
        {
          t: 78900,
          o: 1.08811,
          h: 1.08818,
          l: 1.08785,
          c: 1.08799,
          v: 127
        },
        {
          t: 79200,
          o: 1.08792,
          h: 1.08801,
          l: 1.08772,
          c: 1.08774,
          v: 62
        },
        {
          t: 79500,
          o: 1.08776,
          h: 1.08798,
          l: 1.08771,
          c: 1.08788,
          v: 55
        },
        {
          t: 79800,
          o: 1.08787,
          h: 1.08795,
          l: 1.08787,
          c: 1.0879,
          v: 130
        },
        {
          t: 80100,
          o: 1.08793,
          h: 1.08812,
          l: 1.08793,
          c: 1.08793,
          v: 66
        },
        {
          t: 80400,
          o: 1.08793,
          h: 1.08817,
          l: 1.08793,
          c: 1.0881,
          v: 69
        },
        {
          t: 80700,
          o: 1.08811,
          h: 1.08824,
          l: 1.08797,
          c: 1.08798,
          v: 44
        },
        {
          t: 81000,
          o: 1.08798,
          h: 1.08808,
          l: 1.08795,
          c: 1.08808,
          v: 46
        },
        {
          t: 81300,
          o: 1.08803,
          h: 1.08813,
          l: 1.088,
          c: 1.08808,
          v: 51
        },
        {
          t: 81600,
          o: 1.08808,
          h: 1.08808,
          l: 1.08792,
          c: 1.08793,
          v: 134
        },
        {
          t: 81900,
          o: 1.08792,
          h: 1.08808,
          l: 1.08792,
          c: 1.08792,
          v: 84
        },
        {
          t: 82200,
          o: 1.08792,
          h: 1.08813,
          l: 1.08792,
          c: 1.08806,
          v: 102
        },
        {
          t: 82500,
          o: 1.08806,
          h: 1.08807,
          l: 1.08794,
          c: 1.08802,
          v: 37
        },
        {
          t: 82800,
          o: 1.08799,
          h: 1.08802,
          l: 1.08771,
          c: 1.08794,
          v: 93
        },
        {
          t: 83100,
          o: 1.08793,
          h: 1.08796,
          l: 1.08786,
          c: 1.08792,
          v: 44
        },
        {
          t: 83400,
          o: 1.08791,
          h: 1.08794,
          l: 1.08781,
          c: 1.08788,
          v: 83
        },
        {
          t: 83700,
          o: 1.08788,
          h: 1.08789,
          l: 1.08774,
          c: 1.08781,
          v: 107
        },
        {
          t: 84000,
          o: 1.08781,
          h: 1.08785,
          l: 1.08772,
          c: 1.08772,
          v: 99
        },
        {
          t: 84300,
          o: 1.08773,
          h: 1.08778,
          l: 1.08762,
          c: 1.08774,
          v: 82
        },
        {
          t: 84600,
          o: 1.08773,
          h: 1.0878,
          l: 1.08764,
          c: 1.08764,
          v: 125
        },
        {
          t: 84900,
          o: 1.08764,
          h: 1.08785,
          l: 1.08762,
          c: 1.08781,
          v: 83
        },
        {
          t: 85200,
          o: 1.0878,
          h: 1.08781,
          l: 1.08769,
          c: 1.08773,
          v: 52
        },
        {
          t: 85500,
          o: 1.08777,
          h: 1.08789,
          l: 1.08777,
          c: 1.0878,
          v: 121
        },
        {
          t: 85800,
          o: 1.0878,
          h: 1.08782,
          l: 1.08773,
          c: 1.08782,
          v: 92
        },
        {
          t: 86100,
          o: 1.08782,
          h: 1.08793,
          l: 1.0878,
          c: 1.08792,
          v: 64
        }
      ]
    },
    {
      d: 1582675200,
      b: [
        {
          t: 0,
          o: 1.0879,
          h: 1.0879,
          l: 1.08757,
          c: 1.08771,
          v: 108
        },
        {
          t: 300,
          o: 1.08771,
          h: 1.08771,
          l: 1.08756,
          c: 1.08762,
          v: 117
        },
        {
          t: 600,
          o: 1.08762,
          h: 1.08767,
          l: 1.08751,
          c: 1.08757,
          v: 123
        },
        {
          t: 900,
          o: 1.08757,
          h: 1.08771,
          l: 1.08751,
          c: 1.08753,
          v: 111
        },
        {
          t: 1200,
          o: 1.08752,
          h: 1.08756,
          l: 1.08689,
          c: 1.08704,
          v: 261
        },
        {
          t: 1500,
          o: 1.08704,
          h: 1.08712,
          l: 1.08669,
          c: 1.0867,
          v: 217
        },
        {
          t: 1800,
          o: 1.0867,
          h: 1.08689,
          l: 1.08668,
          c: 1.08688,
          v: 173
        },
        {
          t: 2100,
          o: 1.08689,
          h: 1.08706,
          l: 1.08688,
          c: 1.08695,
          v: 258
        },
        {
          t: 2400,
          o: 1.08696,
          h: 1.08705,
          l: 1.08682,
          c: 1.08682,
          v: 105
        },
        {
          t: 2700,
          o: 1.08682,
          h: 1.08694,
          l: 1.08682,
          c: 1.08692,
          v: 104
        },
        {
          t: 3000,
          o: 1.08693,
          h: 1.08693,
          l: 1.0868,
          c: 1.08691,
          v: 97
        },
        {
          t: 3300,
          o: 1.08691,
          h: 1.08695,
          l: 1.08678,
          c: 1.08692,
          v: 190
        },
        {
          t: 3600,
          o: 1.08693,
          h: 1.08722,
          l: 1.08687,
          c: 1.08703,
          v: 312
        },
        {
          t: 3900,
          o: 1.08701,
          h: 1.08716,
          l: 1.08687,
          c: 1.08702,
          v: 179
        },
        {
          t: 4200,
          o: 1.08703,
          h: 1.08722,
          l: 1.08694,
          c: 1.08722,
          v: 188
        },
        {
          t: 4500,
          o: 1.0872,
          h: 1.08727,
          l: 1.0871,
          c: 1.08727,
          v: 237
        },
        {
          t: 4800,
          o: 1.08727,
          h: 1.08742,
          l: 1.08726,
          c: 1.08732,
          v: 110
        },
        {
          t: 5100,
          o: 1.08733,
          h: 1.08742,
          l: 1.08721,
          c: 1.08736,
          v: 134
        },
        {
          t: 5400,
          o: 1.08733,
          h: 1.08737,
          l: 1.08717,
          c: 1.08723,
          v: 200
        },
        {
          t: 5700,
          o: 1.08723,
          h: 1.08725,
          l: 1.08706,
          c: 1.08715,
          v: 154
        },
        {
          t: 6000,
          o: 1.08715,
          h: 1.08719,
          l: 1.08707,
          c: 1.08714,
          v: 143
        },
        {
          t: 6300,
          o: 1.08713,
          h: 1.08726,
          l: 1.08709,
          c: 1.08721,
          v: 209
        },
        {
          t: 6600,
          o: 1.08719,
          h: 1.08729,
          l: 1.08718,
          c: 1.08722,
          v: 157
        },
        {
          t: 6900,
          o: 1.08722,
          h: 1.08735,
          l: 1.08718,
          c: 1.08733,
          v: 146
        },
        {
          t: 7200,
          o: 1.08734,
          h: 1.08734,
          l: 1.08722,
          c: 1.0873,
          v: 183
        },
        {
          t: 7500,
          o: 1.08731,
          h: 1.08735,
          l: 1.08721,
          c: 1.08725,
          v: 108
        },
        {
          t: 7800,
          o: 1.08726,
          h: 1.08737,
          l: 1.08695,
          c: 1.08695,
          v: 231
        },
        {
          t: 8100,
          o: 1.08694,
          h: 1.08712,
          l: 1.08689,
          c: 1.08699,
          v: 255
        },
        {
          t: 8400,
          o: 1.08699,
          h: 1.08707,
          l: 1.08689,
          c: 1.0869,
          v: 130
        },
        {
          t: 8700,
          o: 1.08688,
          h: 1.08702,
          l: 1.08685,
          c: 1.08691,
          v: 149
        },
        {
          t: 9000,
          o: 1.08692,
          h: 1.08698,
          l: 1.0866,
          c: 1.08663,
          v: 210
        },
        {
          t: 9300,
          o: 1.08662,
          h: 1.0867,
          l: 1.08646,
          c: 1.08646,
          v: 263
        },
        {
          t: 9600,
          o: 1.08648,
          h: 1.08651,
          l: 1.08621,
          c: 1.08646,
          v: 266
        },
        {
          t: 9900,
          o: 1.08646,
          h: 1.08651,
          l: 1.08636,
          c: 1.08647,
          v: 175
        },
        {
          t: 10200,
          o: 1.08646,
          h: 1.08661,
          l: 1.08641,
          c: 1.08659,
          v: 151
        },
        {
          t: 10500,
          o: 1.0866,
          h: 1.0868,
          l: 1.08656,
          c: 1.08677,
          v: 106
        },
        {
          t: 10800,
          o: 1.08676,
          h: 1.08695,
          l: 1.08667,
          c: 1.08688,
          v: 208
        },
        {
          t: 11100,
          o: 1.08689,
          h: 1.08696,
          l: 1.08686,
          c: 1.0869,
          v: 145
        },
        {
          t: 11400,
          o: 1.08691,
          h: 1.08699,
          l: 1.08678,
          c: 1.08679,
          v: 129
        },
        {
          t: 11700,
          o: 1.0868,
          h: 1.08687,
          l: 1.08672,
          c: 1.0868,
          v: 80
        },
        {
          t: 12000,
          o: 1.08679,
          h: 1.0868,
          l: 1.08668,
          c: 1.08672,
          v: 67
        },
        {
          t: 12300,
          o: 1.08672,
          h: 1.08672,
          l: 1.0865,
          c: 1.0865,
          v: 89
        },
        {
          t: 12600,
          o: 1.0865,
          h: 1.08663,
          l: 1.08646,
          c: 1.08648,
          v: 161
        },
        {
          t: 12900,
          o: 1.08648,
          h: 1.0866,
          l: 1.08646,
          c: 1.08658,
          v: 112
        },
        {
          t: 13200,
          o: 1.08658,
          h: 1.08659,
          l: 1.08648,
          c: 1.08655,
          v: 101
        },
        {
          t: 13500,
          o: 1.08655,
          h: 1.0866,
          l: 1.0865,
          c: 1.08656,
          v: 84
        },
        {
          t: 13800,
          o: 1.08652,
          h: 1.08658,
          l: 1.08646,
          c: 1.08657,
          v: 98
        },
        {
          t: 14100,
          o: 1.08657,
          h: 1.08671,
          l: 1.08654,
          c: 1.08661,
          v: 127
        },
        {
          t: 14400,
          o: 1.08661,
          h: 1.0867,
          l: 1.08654,
          c: 1.08657,
          v: 172
        },
        {
          t: 14700,
          o: 1.08658,
          h: 1.08667,
          l: 1.08657,
          c: 1.08667,
          v: 75
        },
        {
          t: 15000,
          o: 1.08668,
          h: 1.08685,
          l: 1.08667,
          c: 1.08679,
          v: 96
        },
        {
          t: 15300,
          o: 1.08678,
          h: 1.08679,
          l: 1.08656,
          c: 1.08662,
          v: 86
        },
        {
          t: 15600,
          o: 1.08664,
          h: 1.08669,
          l: 1.08657,
          c: 1.08661,
          v: 82
        },
        {
          t: 15900,
          o: 1.0866,
          h: 1.08667,
          l: 1.08655,
          c: 1.08667,
          v: 55
        },
        {
          t: 16200,
          o: 1.08667,
          h: 1.08671,
          l: 1.08658,
          c: 1.0867,
          v: 120
        },
        {
          t: 16500,
          o: 1.08671,
          h: 1.08674,
          l: 1.08662,
          c: 1.08662,
          v: 113
        },
        {
          t: 16800,
          o: 1.0866,
          h: 1.0867,
          l: 1.08656,
          c: 1.08665,
          v: 109
        },
        {
          t: 17100,
          o: 1.08666,
          h: 1.08667,
          l: 1.08654,
          c: 1.08661,
          v: 125
        },
        {
          t: 17400,
          o: 1.0866,
          h: 1.08672,
          l: 1.08657,
          c: 1.08671,
          v: 46
        },
        {
          t: 17700,
          o: 1.08671,
          h: 1.08678,
          l: 1.08666,
          c: 1.08671,
          v: 48
        },
        {
          t: 18000,
          o: 1.0867,
          h: 1.08689,
          l: 1.0867,
          c: 1.08681,
          v: 120
        },
        {
          t: 18300,
          o: 1.08682,
          h: 1.08698,
          l: 1.08681,
          c: 1.08685,
          v: 153
        },
        {
          t: 18600,
          o: 1.08686,
          h: 1.08707,
          l: 1.08685,
          c: 1.08704,
          v: 140
        },
        {
          t: 18900,
          o: 1.08705,
          h: 1.08707,
          l: 1.08691,
          c: 1.08694,
          v: 143
        },
        {
          t: 19200,
          o: 1.08694,
          h: 1.0871,
          l: 1.08694,
          c: 1.08706,
          v: 117
        },
        {
          t: 19500,
          o: 1.08706,
          h: 1.08736,
          l: 1.08706,
          c: 1.0873,
          v: 128
        },
        {
          t: 19800,
          o: 1.08726,
          h: 1.0873,
          l: 1.08719,
          c: 1.08725,
          v: 110
        },
        {
          t: 20100,
          o: 1.08726,
          h: 1.08737,
          l: 1.08725,
          c: 1.0873,
          v: 139
        },
        {
          t: 20400,
          o: 1.08726,
          h: 1.08766,
          l: 1.08719,
          c: 1.08751,
          v: 129
        },
        {
          t: 20700,
          o: 1.08751,
          h: 1.0877,
          l: 1.08748,
          c: 1.08768,
          v: 160
        },
        {
          t: 21000,
          o: 1.08767,
          h: 1.0878,
          l: 1.0875,
          c: 1.08755,
          v: 167
        },
        {
          t: 21300,
          o: 1.08753,
          h: 1.08765,
          l: 1.08743,
          c: 1.08756,
          v: 160
        },
        {
          t: 21600,
          o: 1.08757,
          h: 1.08758,
          l: 1.08749,
          c: 1.08755,
          v: 96
        },
        {
          t: 21900,
          o: 1.08755,
          h: 1.08755,
          l: 1.0874,
          c: 1.08741,
          v: 110
        },
        {
          t: 22200,
          o: 1.08741,
          h: 1.0877,
          l: 1.08725,
          c: 1.0874,
          v: 197
        },
        {
          t: 22500,
          o: 1.08736,
          h: 1.08757,
          l: 1.08711,
          c: 1.08753,
          v: 380
        },
        {
          t: 22800,
          o: 1.08753,
          h: 1.08756,
          l: 1.08738,
          c: 1.08746,
          v: 200
        },
        {
          t: 23100,
          o: 1.08742,
          h: 1.08744,
          l: 1.0871,
          c: 1.0874,
          v: 346
        },
        {
          t: 23400,
          o: 1.08741,
          h: 1.08742,
          l: 1.08715,
          c: 1.08723,
          v: 185
        },
        {
          t: 23700,
          o: 1.08722,
          h: 1.08733,
          l: 1.08704,
          c: 1.0871,
          v: 172
        },
        {
          t: 24000,
          o: 1.08711,
          h: 1.08715,
          l: 1.08701,
          c: 1.08703,
          v: 137
        },
        {
          t: 24300,
          o: 1.08703,
          h: 1.08711,
          l: 1.08697,
          c: 1.08707,
          v: 93
        },
        {
          t: 24600,
          o: 1.0871,
          h: 1.08716,
          l: 1.087,
          c: 1.08715,
          v: 130
        },
        {
          t: 24900,
          o: 1.08714,
          h: 1.0874,
          l: 1.08706,
          c: 1.0874,
          v: 194
        },
        {
          t: 25200,
          o: 1.08741,
          h: 1.08745,
          l: 1.08714,
          c: 1.08735,
          v: 303
        },
        {
          t: 25500,
          o: 1.08736,
          h: 1.08736,
          l: 1.08699,
          c: 1.0871,
          v: 254
        },
        {
          t: 25800,
          o: 1.08711,
          h: 1.08713,
          l: 1.08675,
          c: 1.08696,
          v: 266
        },
        {
          t: 26100,
          o: 1.08698,
          h: 1.08716,
          l: 1.08695,
          c: 1.087,
          v: 277
        },
        {
          t: 26400,
          o: 1.087,
          h: 1.08705,
          l: 1.0868,
          c: 1.08685,
          v: 271
        },
        {
          t: 26700,
          o: 1.08689,
          h: 1.08689,
          l: 1.08649,
          c: 1.08657,
          v: 274
        },
        {
          t: 27000,
          o: 1.0866,
          h: 1.08685,
          l: 1.08653,
          c: 1.08684,
          v: 296
        },
        {
          t: 27300,
          o: 1.08684,
          h: 1.08719,
          l: 1.08684,
          c: 1.08715,
          v: 255
        },
        {
          t: 27600,
          o: 1.08716,
          h: 1.08742,
          l: 1.08711,
          c: 1.08736,
          v: 295
        },
        {
          t: 27900,
          o: 1.0874,
          h: 1.08741,
          l: 1.08696,
          c: 1.08698,
          v: 312
        },
        {
          t: 28200,
          o: 1.08699,
          h: 1.08751,
          l: 1.08692,
          c: 1.08743,
          v: 362
        },
        {
          t: 28500,
          o: 1.08742,
          h: 1.08752,
          l: 1.087,
          c: 1.08705,
          v: 381
        },
        {
          t: 28800,
          o: 1.08706,
          h: 1.08761,
          l: 1.08705,
          c: 1.08744,
          v: 445
        },
        {
          t: 29100,
          o: 1.08742,
          h: 1.08794,
          l: 1.08742,
          c: 1.08788,
          v: 337
        },
        {
          t: 29400,
          o: 1.08788,
          h: 1.08802,
          l: 1.0878,
          c: 1.0879,
          v: 289
        },
        {
          t: 29700,
          o: 1.08792,
          h: 1.08799,
          l: 1.08773,
          c: 1.08776,
          v: 245
        },
        {
          t: 30000,
          o: 1.08777,
          h: 1.08811,
          l: 1.08772,
          c: 1.08775,
          v: 260
        },
        {
          t: 30300,
          o: 1.08776,
          h: 1.0881,
          l: 1.08776,
          c: 1.08809,
          v: 212
        },
        {
          t: 30600,
          o: 1.08811,
          h: 1.08833,
          l: 1.08805,
          c: 1.08805,
          v: 296
        },
        {
          t: 30900,
          o: 1.0881,
          h: 1.08814,
          l: 1.08796,
          c: 1.08803,
          v: 225
        },
        {
          t: 31200,
          o: 1.08802,
          h: 1.0884,
          l: 1.08802,
          c: 1.08837,
          v: 310
        },
        {
          t: 31500,
          o: 1.08838,
          h: 1.08903,
          l: 1.08837,
          c: 1.08897,
          v: 336
        },
        {
          t: 31800,
          o: 1.08897,
          h: 1.08972,
          l: 1.08893,
          c: 1.08966,
          v: 471
        },
        {
          t: 32100,
          o: 1.08967,
          h: 1.08968,
          l: 1.08903,
          c: 1.08913,
          v: 352
        },
        {
          t: 32400,
          o: 1.08915,
          h: 1.08922,
          l: 1.08889,
          c: 1.08904,
          v: 350
        },
        {
          t: 32700,
          o: 1.08903,
          h: 1.08953,
          l: 1.08894,
          c: 1.08943,
          v: 382
        },
        {
          t: 33000,
          o: 1.08944,
          h: 1.08962,
          l: 1.08903,
          c: 1.08904,
          v: 350
        },
        {
          t: 33300,
          o: 1.08903,
          h: 1.08924,
          l: 1.08894,
          c: 1.08899,
          v: 286
        },
        {
          t: 33600,
          o: 1.089,
          h: 1.08935,
          l: 1.08898,
          c: 1.0892,
          v: 317
        },
        {
          t: 33900,
          o: 1.08919,
          h: 1.08919,
          l: 1.08846,
          c: 1.08865,
          v: 375
        },
        {
          t: 34200,
          o: 1.08866,
          h: 1.08923,
          l: 1.08864,
          c: 1.08922,
          v: 282
        },
        {
          t: 34500,
          o: 1.08922,
          h: 1.08927,
          l: 1.0884,
          c: 1.0884,
          v: 356
        },
        {
          t: 34800,
          o: 1.08843,
          h: 1.08883,
          l: 1.08842,
          c: 1.08875,
          v: 363
        },
        {
          t: 35100,
          o: 1.08875,
          h: 1.08958,
          l: 1.0887,
          c: 1.08935,
          v: 344
        },
        {
          t: 35400,
          o: 1.08935,
          h: 1.0894,
          l: 1.08868,
          c: 1.08869,
          v: 326
        },
        {
          t: 35700,
          o: 1.08867,
          h: 1.08887,
          l: 1.0884,
          c: 1.08886,
          v: 282
        },
        {
          t: 36000,
          o: 1.0888,
          h: 1.08896,
          l: 1.08829,
          c: 1.0883,
          v: 319
        },
        {
          t: 36300,
          o: 1.0883,
          h: 1.08836,
          l: 1.08773,
          c: 1.08774,
          v: 359
        },
        {
          t: 36600,
          o: 1.08773,
          h: 1.0879,
          l: 1.08766,
          c: 1.08786,
          v: 261
        },
        {
          t: 36900,
          o: 1.08785,
          h: 1.08797,
          l: 1.08765,
          c: 1.08769,
          v: 240
        },
        {
          t: 37200,
          o: 1.0877,
          h: 1.08797,
          l: 1.0876,
          c: 1.08794,
          v: 223
        },
        {
          t: 37500,
          o: 1.08793,
          h: 1.08794,
          l: 1.08737,
          c: 1.08739,
          v: 252
        },
        {
          t: 37800,
          o: 1.0874,
          h: 1.08763,
          l: 1.08726,
          c: 1.08758,
          v: 223
        },
        {
          t: 38100,
          o: 1.0876,
          h: 1.08795,
          l: 1.08755,
          c: 1.0878,
          v: 234
        },
        {
          t: 38400,
          o: 1.08781,
          h: 1.08791,
          l: 1.08757,
          c: 1.08783,
          v: 215
        },
        {
          t: 38700,
          o: 1.08784,
          h: 1.08799,
          l: 1.08755,
          c: 1.08781,
          v: 221
        },
        {
          t: 39000,
          o: 1.08781,
          h: 1.08825,
          l: 1.08779,
          c: 1.0882,
          v: 210
        },
        {
          t: 39300,
          o: 1.0882,
          h: 1.08863,
          l: 1.08813,
          c: 1.08859,
          v: 252
        },
        {
          t: 39600,
          o: 1.08859,
          h: 1.08879,
          l: 1.08837,
          c: 1.08867,
          v: 312
        },
        {
          t: 39900,
          o: 1.08866,
          h: 1.08892,
          l: 1.08864,
          c: 1.08864,
          v: 221
        },
        {
          t: 40200,
          o: 1.08864,
          h: 1.08864,
          l: 1.08818,
          c: 1.08845,
          v: 250
        },
        {
          t: 40500,
          o: 1.08844,
          h: 1.08853,
          l: 1.08815,
          c: 1.0885,
          v: 169
        },
        {
          t: 40800,
          o: 1.08852,
          h: 1.08856,
          l: 1.08814,
          c: 1.08832,
          v: 278
        },
        {
          t: 41100,
          o: 1.08833,
          h: 1.08841,
          l: 1.088,
          c: 1.0881,
          v: 276
        },
        {
          t: 41400,
          o: 1.0881,
          h: 1.0881,
          l: 1.0874,
          c: 1.0875,
          v: 224
        },
        {
          t: 41700,
          o: 1.08748,
          h: 1.08773,
          l: 1.0873,
          c: 1.08754,
          v: 198
        },
        {
          t: 42000,
          o: 1.08755,
          h: 1.08777,
          l: 1.0873,
          c: 1.08737,
          v: 236
        },
        {
          t: 42300,
          o: 1.08739,
          h: 1.08792,
          l: 1.08738,
          c: 1.08791,
          v: 244
        },
        {
          t: 42600,
          o: 1.08787,
          h: 1.08831,
          l: 1.08784,
          c: 1.0882,
          v: 235
        },
        {
          t: 42900,
          o: 1.08819,
          h: 1.08852,
          l: 1.08811,
          c: 1.0884,
          v: 219
        },
        {
          t: 43200,
          o: 1.0884,
          h: 1.08904,
          l: 1.0884,
          c: 1.0889,
          v: 405
        },
        {
          t: 43500,
          o: 1.0889,
          h: 1.08963,
          l: 1.08889,
          c: 1.08962,
          v: 504
        },
        {
          t: 43800,
          o: 1.08968,
          h: 1.09081,
          l: 1.08938,
          c: 1.0894,
          v: 630
        },
        {
          t: 44100,
          o: 1.08941,
          h: 1.08949,
          l: 1.08861,
          c: 1.08863,
          v: 452
        },
        {
          t: 44400,
          o: 1.08863,
          h: 1.08885,
          l: 1.0886,
          c: 1.08863,
          v: 284
        },
        {
          t: 44700,
          o: 1.08862,
          h: 1.08918,
          l: 1.08861,
          c: 1.08916,
          v: 313
        },
        {
          t: 45000,
          o: 1.08912,
          h: 1.08915,
          l: 1.0887,
          c: 1.08876,
          v: 259
        },
        {
          t: 45300,
          o: 1.08875,
          h: 1.08913,
          l: 1.08874,
          c: 1.08888,
          v: 256
        },
        {
          t: 45600,
          o: 1.08889,
          h: 1.0889,
          l: 1.08759,
          c: 1.08773,
          v: 373
        },
        {
          t: 45900,
          o: 1.08772,
          h: 1.08847,
          l: 1.08743,
          c: 1.08767,
          v: 491
        },
        {
          t: 46200,
          o: 1.08767,
          h: 1.08789,
          l: 1.0873,
          c: 1.08737,
          v: 380
        },
        {
          t: 46500,
          o: 1.08734,
          h: 1.08753,
          l: 1.08683,
          c: 1.08689,
          v: 523
        },
        {
          t: 46800,
          o: 1.08688,
          h: 1.08704,
          l: 1.08653,
          c: 1.08683,
          v: 439
        },
        {
          t: 47100,
          o: 1.08682,
          h: 1.08688,
          l: 1.08672,
          c: 1.08686,
          v: 308
        },
        {
          t: 47400,
          o: 1.08687,
          h: 1.08744,
          l: 1.08686,
          c: 1.08738,
          v: 393
        },
        {
          t: 47700,
          o: 1.08739,
          h: 1.08745,
          l: 1.08656,
          c: 1.08716,
          v: 497
        },
        {
          t: 48000,
          o: 1.08719,
          h: 1.08751,
          l: 1.08709,
          c: 1.08733,
          v: 367
        },
        {
          t: 48300,
          o: 1.08734,
          h: 1.08745,
          l: 1.08698,
          c: 1.08724,
          v: 375
        },
        {
          t: 48600,
          o: 1.08725,
          h: 1.0874,
          l: 1.08709,
          c: 1.08724,
          v: 327
        },
        {
          t: 48900,
          o: 1.08727,
          h: 1.0875,
          l: 1.08693,
          c: 1.08717,
          v: 323
        },
        {
          t: 49200,
          o: 1.0872,
          h: 1.0872,
          l: 1.08671,
          c: 1.0869,
          v: 353
        },
        {
          t: 49500,
          o: 1.08691,
          h: 1.08699,
          l: 1.08558,
          c: 1.08604,
          v: 487
        },
        {
          t: 49800,
          o: 1.08607,
          h: 1.08655,
          l: 1.08564,
          c: 1.08649,
          v: 406
        },
        {
          t: 50100,
          o: 1.0865,
          h: 1.08674,
          l: 1.08634,
          c: 1.0865,
          v: 312
        },
        {
          t: 50400,
          o: 1.0865,
          h: 1.087,
          l: 1.08648,
          c: 1.0867,
          v: 286
        },
        {
          t: 50700,
          o: 1.08674,
          h: 1.08712,
          l: 1.08669,
          c: 1.08695,
          v: 275
        },
        {
          t: 51000,
          o: 1.08694,
          h: 1.0874,
          l: 1.08683,
          c: 1.0873,
          v: 271
        },
        {
          t: 51300,
          o: 1.0873,
          h: 1.08744,
          l: 1.08663,
          c: 1.08678,
          v: 267
        },
        {
          t: 51600,
          o: 1.08676,
          h: 1.08682,
          l: 1.08642,
          c: 1.0868,
          v: 291
        },
        {
          t: 51900,
          o: 1.08675,
          h: 1.08693,
          l: 1.08616,
          c: 1.08638,
          v: 369
        },
        {
          t: 52200,
          o: 1.0864,
          h: 1.08645,
          l: 1.08546,
          c: 1.08585,
          v: 537
        },
        {
          t: 52500,
          o: 1.08585,
          h: 1.08592,
          l: 1.08545,
          c: 1.08552,
          v: 414
        },
        {
          t: 52800,
          o: 1.08555,
          h: 1.08613,
          l: 1.08544,
          c: 1.08606,
          v: 452
        },
        {
          t: 53100,
          o: 1.08601,
          h: 1.08601,
          l: 1.08563,
          c: 1.08597,
          v: 431
        },
        {
          t: 53400,
          o: 1.08595,
          h: 1.08596,
          l: 1.08555,
          c: 1.08563,
          v: 344
        },
        {
          t: 53700,
          o: 1.08565,
          h: 1.08602,
          l: 1.08552,
          c: 1.08592,
          v: 380
        },
        {
          t: 54000,
          o: 1.08593,
          h: 1.0863,
          l: 1.08566,
          c: 1.08615,
          v: 412
        },
        {
          t: 54300,
          o: 1.08617,
          h: 1.08652,
          l: 1.08602,
          c: 1.0862,
          v: 433
        },
        {
          t: 54600,
          o: 1.08619,
          h: 1.08671,
          l: 1.08612,
          c: 1.08639,
          v: 364
        },
        {
          t: 54900,
          o: 1.08638,
          h: 1.08659,
          l: 1.08596,
          c: 1.08609,
          v: 340
        },
        {
          t: 55200,
          o: 1.08608,
          h: 1.0867,
          l: 1.08601,
          c: 1.0866,
          v: 336
        },
        {
          t: 55500,
          o: 1.08661,
          h: 1.08732,
          l: 1.08652,
          c: 1.08725,
          v: 390
        },
        {
          t: 55800,
          o: 1.08724,
          h: 1.08749,
          l: 1.087,
          c: 1.08739,
          v: 390
        },
        {
          t: 56100,
          o: 1.08738,
          h: 1.08743,
          l: 1.08664,
          c: 1.08669,
          v: 346
        },
        {
          t: 56400,
          o: 1.0867,
          h: 1.08674,
          l: 1.08625,
          c: 1.08636,
          v: 277
        },
        {
          t: 56700,
          o: 1.08635,
          h: 1.08686,
          l: 1.08635,
          c: 1.08651,
          v: 259
        },
        {
          t: 57000,
          o: 1.0865,
          h: 1.08727,
          l: 1.0865,
          c: 1.08706,
          v: 324
        },
        {
          t: 57300,
          o: 1.08704,
          h: 1.08704,
          l: 1.08635,
          c: 1.08661,
          v: 494
        },
        {
          t: 57600,
          o: 1.08659,
          h: 1.08675,
          l: 1.08636,
          c: 1.08675,
          v: 417
        },
        {
          t: 57900,
          o: 1.08675,
          h: 1.08734,
          l: 1.08674,
          c: 1.08728,
          v: 318
        },
        {
          t: 58200,
          o: 1.08726,
          h: 1.08792,
          l: 1.08712,
          c: 1.08737,
          v: 429
        },
        {
          t: 58500,
          o: 1.08738,
          h: 1.08773,
          l: 1.08723,
          c: 1.08773,
          v: 312
        },
        {
          t: 58800,
          o: 1.08775,
          h: 1.08837,
          l: 1.08758,
          c: 1.08803,
          v: 397
        },
        {
          t: 59100,
          o: 1.08805,
          h: 1.08898,
          l: 1.08803,
          c: 1.0889,
          v: 395
        },
        {
          t: 59400,
          o: 1.0889,
          h: 1.089,
          l: 1.08831,
          c: 1.08894,
          v: 321
        },
        {
          t: 59700,
          o: 1.08894,
          h: 1.08908,
          l: 1.08875,
          c: 1.08895,
          v: 231
        },
        {
          t: 60000,
          o: 1.08898,
          h: 1.08899,
          l: 1.08856,
          c: 1.08867,
          v: 332
        },
        {
          t: 60300,
          o: 1.08866,
          h: 1.0887,
          l: 1.08827,
          c: 1.08861,
          v: 258
        },
        {
          t: 60600,
          o: 1.0886,
          h: 1.0891,
          l: 1.0886,
          c: 1.08896,
          v: 289
        },
        {
          t: 60900,
          o: 1.08897,
          h: 1.08905,
          l: 1.08855,
          c: 1.08859,
          v: 305
        },
        {
          t: 61200,
          o: 1.08858,
          h: 1.08914,
          l: 1.0883,
          c: 1.089,
          v: 364
        },
        {
          t: 61500,
          o: 1.08899,
          h: 1.08901,
          l: 1.08864,
          c: 1.08888,
          v: 327
        },
        {
          t: 61800,
          o: 1.08889,
          h: 1.08899,
          l: 1.0887,
          c: 1.08899,
          v: 252
        },
        {
          t: 62100,
          o: 1.08896,
          h: 1.0894,
          l: 1.08874,
          c: 1.08874,
          v: 269
        },
        {
          t: 62400,
          o: 1.08875,
          h: 1.08878,
          l: 1.08844,
          c: 1.08859,
          v: 299
        },
        {
          t: 62700,
          o: 1.08858,
          h: 1.08876,
          l: 1.08846,
          c: 1.08855,
          v: 233
        },
        {
          t: 63000,
          o: 1.08853,
          h: 1.08854,
          l: 1.08831,
          c: 1.08836,
          v: 179
        },
        {
          t: 63300,
          o: 1.08837,
          h: 1.08847,
          l: 1.08814,
          c: 1.08815,
          v: 185
        },
        {
          t: 63600,
          o: 1.08814,
          h: 1.08837,
          l: 1.08812,
          c: 1.08825,
          v: 153
        },
        {
          t: 63900,
          o: 1.08826,
          h: 1.08828,
          l: 1.088,
          c: 1.08811,
          v: 222
        },
        {
          t: 64200,
          o: 1.0881,
          h: 1.08812,
          l: 1.0878,
          c: 1.0879,
          v: 203
        },
        {
          t: 64500,
          o: 1.0879,
          h: 1.0881,
          l: 1.08776,
          c: 1.08781,
          v: 264
        },
        {
          t: 64800,
          o: 1.08783,
          h: 1.08795,
          l: 1.0876,
          c: 1.08791,
          v: 243
        },
        {
          t: 65100,
          o: 1.08791,
          h: 1.08808,
          l: 1.08785,
          c: 1.08801,
          v: 236
        },
        {
          t: 65400,
          o: 1.08801,
          h: 1.08801,
          l: 1.08747,
          c: 1.08757,
          v: 186
        },
        {
          t: 65700,
          o: 1.08758,
          h: 1.08781,
          l: 1.08757,
          c: 1.08777,
          v: 117
        },
        {
          t: 66000,
          o: 1.08776,
          h: 1.08787,
          l: 1.08752,
          c: 1.08776,
          v: 161
        },
        {
          t: 66300,
          o: 1.08776,
          h: 1.08788,
          l: 1.08767,
          c: 1.08775,
          v: 173
        },
        {
          t: 66600,
          o: 1.08775,
          h: 1.08817,
          l: 1.08772,
          c: 1.08817,
          v: 166
        },
        {
          t: 66900,
          o: 1.08812,
          h: 1.08876,
          l: 1.08806,
          c: 1.08868,
          v: 366
        },
        {
          t: 67200,
          o: 1.08869,
          h: 1.08897,
          l: 1.08866,
          c: 1.08886,
          v: 393
        },
        {
          t: 67500,
          o: 1.08886,
          h: 1.08901,
          l: 1.08743,
          c: 1.08772,
          v: 494
        },
        {
          t: 67800,
          o: 1.08773,
          h: 1.08793,
          l: 1.08765,
          c: 1.08791,
          v: 375
        },
        {
          t: 68100,
          o: 1.08791,
          h: 1.08821,
          l: 1.08786,
          c: 1.0881,
          v: 331
        },
        {
          t: 68400,
          o: 1.0881,
          h: 1.08827,
          l: 1.08791,
          c: 1.08822,
          v: 357
        },
        {
          t: 68700,
          o: 1.08819,
          h: 1.08871,
          l: 1.08815,
          c: 1.08867,
          v: 298
        },
        {
          t: 69000,
          o: 1.08867,
          h: 1.08879,
          l: 1.08845,
          c: 1.08846,
          v: 301
        },
        {
          t: 69300,
          o: 1.08846,
          h: 1.08878,
          l: 1.08832,
          c: 1.08871,
          v: 248
        },
        {
          t: 69600,
          o: 1.08871,
          h: 1.0888,
          l: 1.08856,
          c: 1.08878,
          v: 214
        },
        {
          t: 69900,
          o: 1.08879,
          h: 1.08905,
          l: 1.08858,
          c: 1.08901,
          v: 264
        },
        {
          t: 70200,
          o: 1.08898,
          h: 1.08936,
          l: 1.08893,
          c: 1.08934,
          v: 336
        },
        {
          t: 70500,
          o: 1.08933,
          h: 1.0896,
          l: 1.08931,
          c: 1.0895,
          v: 224
        },
        {
          t: 70800,
          o: 1.08946,
          h: 1.08991,
          l: 1.08946,
          c: 1.0899,
          v: 282
        },
        {
          t: 71100,
          o: 1.08986,
          h: 1.08993,
          l: 1.08966,
          c: 1.08972,
          v: 315
        },
        {
          t: 71400,
          o: 1.08972,
          h: 1.08987,
          l: 1.08972,
          c: 1.08983,
          v: 274
        },
        {
          t: 71700,
          o: 1.08981,
          h: 1.08995,
          l: 1.08973,
          c: 1.08991,
          v: 314
        },
        {
          t: 72000,
          o: 1.08988,
          h: 1.08988,
          l: 1.08931,
          c: 1.0894,
          v: 238
        },
        {
          t: 72300,
          o: 1.08939,
          h: 1.08941,
          l: 1.08918,
          c: 1.08925,
          v: 197
        },
        {
          t: 72600,
          o: 1.08925,
          h: 1.08926,
          l: 1.08883,
          c: 1.08905,
          v: 246
        },
        {
          t: 72900,
          o: 1.08904,
          h: 1.08905,
          l: 1.08885,
          c: 1.0889,
          v: 269
        },
        {
          t: 73200,
          o: 1.0889,
          h: 1.08892,
          l: 1.08868,
          c: 1.08872,
          v: 249
        },
        {
          t: 73500,
          o: 1.08871,
          h: 1.08893,
          l: 1.0887,
          c: 1.0888,
          v: 195
        },
        {
          t: 73800,
          o: 1.08881,
          h: 1.08886,
          l: 1.08865,
          c: 1.0888,
          v: 197
        },
        {
          t: 74100,
          o: 1.0888,
          h: 1.0888,
          l: 1.08851,
          c: 1.08861,
          v: 171
        },
        {
          t: 74400,
          o: 1.0886,
          h: 1.08862,
          l: 1.08845,
          c: 1.08847,
          v: 171
        },
        {
          t: 74700,
          o: 1.08846,
          h: 1.08852,
          l: 1.08826,
          c: 1.0885,
          v: 194
        },
        {
          t: 75000,
          o: 1.08848,
          h: 1.08851,
          l: 1.08828,
          c: 1.08844,
          v: 188
        },
        {
          t: 75300,
          o: 1.0884,
          h: 1.08867,
          l: 1.08829,
          c: 1.08842,
          v: 281
        },
        {
          t: 75600,
          o: 1.08841,
          h: 1.08847,
          l: 1.08797,
          c: 1.0883,
          v: 180
        },
        {
          t: 75900,
          o: 1.0883,
          h: 1.08847,
          l: 1.0883,
          c: 1.0884,
          v: 53
        },
        {
          t: 76200,
          o: 1.0884,
          h: 1.08852,
          l: 1.08831,
          c: 1.08847,
          v: 78
        },
        {
          t: 76500,
          o: 1.08846,
          h: 1.08847,
          l: 1.08834,
          c: 1.08836,
          v: 20
        },
        {
          t: 76800,
          o: 1.08833,
          h: 1.08845,
          l: 1.08832,
          c: 1.08845,
          v: 21
        },
        {
          t: 77100,
          o: 1.08846,
          h: 1.08849,
          l: 1.08831,
          c: 1.08833,
          v: 33
        },
        {
          t: 77400,
          o: 1.08832,
          h: 1.08835,
          l: 1.08815,
          c: 1.08815,
          v: 63
        },
        {
          t: 77700,
          o: 1.08814,
          h: 1.08814,
          l: 1.0879,
          c: 1.08795,
          v: 72
        },
        {
          t: 78000,
          o: 1.08795,
          h: 1.08802,
          l: 1.08794,
          c: 1.08799,
          v: 96
        },
        {
          t: 78300,
          o: 1.08801,
          h: 1.08809,
          l: 1.08799,
          c: 1.08799,
          v: 56
        },
        {
          t: 78600,
          o: 1.08799,
          h: 1.08802,
          l: 1.08762,
          c: 1.08779,
          v: 119
        },
        {
          t: 78900,
          o: 1.08781,
          h: 1.08806,
          l: 1.08781,
          c: 1.08802,
          v: 118
        },
        {
          t: 79200,
          o: 1.088,
          h: 1.08831,
          l: 1.088,
          c: 1.08804,
          v: 39
        },
        {
          t: 79500,
          o: 1.08803,
          h: 1.08823,
          l: 1.08764,
          c: 1.08781,
          v: 20
        },
        {
          t: 79800,
          o: 1.08777,
          h: 1.08813,
          l: 1.08775,
          c: 1.08802,
          v: 67
        },
        {
          t: 80100,
          o: 1.08799,
          h: 1.08838,
          l: 1.08797,
          c: 1.08838,
          v: 109
        },
        {
          t: 80400,
          o: 1.08837,
          h: 1.08856,
          l: 1.08805,
          c: 1.08851,
          v: 76
        },
        {
          t: 80700,
          o: 1.08811,
          h: 1.08824,
          l: 1.0881,
          c: 1.08824,
          v: 46
        },
        {
          t: 81000,
          o: 1.08824,
          h: 1.08824,
          l: 1.08809,
          c: 1.08809,
          v: 25
        },
        {
          t: 81300,
          o: 1.0881,
          h: 1.08827,
          l: 1.08809,
          c: 1.08825,
          v: 37
        },
        {
          t: 81600,
          o: 1.08825,
          h: 1.08837,
          l: 1.08825,
          c: 1.08826,
          v: 33
        },
        {
          t: 81900,
          o: 1.08826,
          h: 1.08826,
          l: 1.08817,
          c: 1.08817,
          v: 24
        },
        {
          t: 82200,
          o: 1.08816,
          h: 1.08821,
          l: 1.08806,
          c: 1.08815,
          v: 49
        },
        {
          t: 82500,
          o: 1.08816,
          h: 1.0882,
          l: 1.08815,
          c: 1.08819,
          v: 44
        },
        {
          t: 82800,
          o: 1.0882,
          h: 1.08825,
          l: 1.08809,
          c: 1.08811,
          v: 144
        },
        {
          t: 83100,
          o: 1.08811,
          h: 1.08821,
          l: 1.08805,
          c: 1.08808,
          v: 115
        },
        {
          t: 83400,
          o: 1.08807,
          h: 1.08807,
          l: 1.0879,
          c: 1.08801,
          v: 62
        },
        {
          t: 83700,
          o: 1.088,
          h: 1.08807,
          l: 1.088,
          c: 1.08805,
          v: 100
        },
        {
          t: 84000,
          o: 1.08806,
          h: 1.08814,
          l: 1.08801,
          c: 1.08811,
          v: 81
        },
        {
          t: 84300,
          o: 1.0881,
          h: 1.08813,
          l: 1.08808,
          c: 1.08812,
          v: 53
        },
        {
          t: 84600,
          o: 1.08807,
          h: 1.08813,
          l: 1.08791,
          c: 1.08799,
          v: 121
        },
        {
          t: 84900,
          o: 1.088,
          h: 1.08816,
          l: 1.088,
          c: 1.08814,
          v: 106
        },
        {
          t: 85200,
          o: 1.08816,
          h: 1.08831,
          l: 1.08816,
          c: 1.08827,
          v: 110
        },
        {
          t: 85500,
          o: 1.08828,
          h: 1.08828,
          l: 1.08803,
          c: 1.08816,
          v: 124
        },
        {
          t: 85800,
          o: 1.08815,
          h: 1.08839,
          l: 1.08804,
          c: 1.08836,
          v: 150
        },
        {
          t: 86100,
          o: 1.08835,
          h: 1.0886,
          l: 1.08835,
          c: 1.08859,
          v: 183
        }
      ]
    },
    {
      d: 1582761600,
      b: [
        {
          t: 0,
          o: 1.08862,
          h: 1.08966,
          l: 1.08842,
          c: 1.08966,
          v: 429
        },
        {
          t: 300,
          o: 1.08963,
          h: 1.0907,
          l: 1.08959,
          c: 1.09066,
          v: 445
        },
        {
          t: 600,
          o: 1.09067,
          h: 1.09125,
          l: 1.09018,
          c: 1.09027,
          v: 310
        },
        {
          t: 900,
          o: 1.09028,
          h: 1.09029,
          l: 1.08997,
          c: 1.09003,
          v: 219
        },
        {
          t: 1200,
          o: 1.09004,
          h: 1.09051,
          l: 1.09004,
          c: 1.09043,
          v: 215
        },
        {
          t: 1500,
          o: 1.09042,
          h: 1.09057,
          l: 1.09005,
          c: 1.0901,
          v: 183
        },
        {
          t: 1800,
          o: 1.09006,
          h: 1.09041,
          l: 1.09006,
          c: 1.09023,
          v: 164
        },
        {
          t: 2100,
          o: 1.09021,
          h: 1.09021,
          l: 1.08998,
          c: 1.09007,
          v: 180
        },
        {
          t: 2400,
          o: 1.09006,
          h: 1.09009,
          l: 1.08995,
          c: 1.09003,
          v: 206
        },
        {
          t: 2700,
          o: 1.09001,
          h: 1.09016,
          l: 1.08984,
          c: 1.09,
          v: 213
        },
        {
          t: 3000,
          o: 1.08999,
          h: 1.09022,
          l: 1.08986,
          c: 1.08988,
          v: 287
        },
        {
          t: 3300,
          o: 1.08988,
          h: 1.09003,
          l: 1.08972,
          c: 1.08976,
          v: 304
        },
        {
          t: 3600,
          o: 1.08976,
          h: 1.0899,
          l: 1.08959,
          c: 1.08985,
          v: 226
        },
        {
          t: 6300,
          o: 1.08976,
          h: 1.08981,
          l: 1.08976,
          c: 1.08981,
          v: 22
        },
        {
          t: 6600,
          o: 1.08982,
          h: 1.08992,
          l: 1.08982,
          c: 1.08987,
          v: 160
        },
        {
          t: 6900,
          o: 1.08986,
          h: 1.09011,
          l: 1.08985,
          c: 1.09008,
          v: 130
        },
        {
          t: 7200,
          o: 1.09008,
          h: 1.09023,
          l: 1.09006,
          c: 1.09018,
          v: 164
        },
        {
          t: 7500,
          o: 1.09017,
          h: 1.09062,
          l: 1.09013,
          c: 1.09039,
          v: 267
        },
        {
          t: 7800,
          o: 1.09039,
          h: 1.09041,
          l: 1.0903,
          c: 1.09033,
          v: 148
        },
        {
          t: 8100,
          o: 1.09032,
          h: 1.09039,
          l: 1.09028,
          c: 1.09037,
          v: 104
        },
        {
          t: 8400,
          o: 1.09037,
          h: 1.09068,
          l: 1.09031,
          c: 1.09065,
          v: 143
        },
        {
          t: 8700,
          o: 1.09066,
          h: 1.0908,
          l: 1.09061,
          c: 1.09069,
          v: 199
        },
        {
          t: 9000,
          o: 1.09071,
          h: 1.09074,
          l: 1.09061,
          c: 1.09069,
          v: 131
        },
        {
          t: 9300,
          o: 1.09068,
          h: 1.09094,
          l: 1.09061,
          c: 1.09094,
          v: 153
        },
        {
          t: 9600,
          o: 1.09093,
          h: 1.0911,
          l: 1.09079,
          c: 1.09106,
          v: 213
        },
        {
          t: 9900,
          o: 1.09105,
          h: 1.0914,
          l: 1.09093,
          c: 1.09121,
          v: 213
        },
        {
          t: 10200,
          o: 1.0912,
          h: 1.09121,
          l: 1.09105,
          c: 1.09106,
          v: 100
        },
        {
          t: 10500,
          o: 1.09107,
          h: 1.09111,
          l: 1.09097,
          c: 1.091,
          v: 169
        },
        {
          t: 10800,
          o: 1.09099,
          h: 1.09099,
          l: 1.09079,
          c: 1.0908,
          v: 99
        },
        {
          t: 11100,
          o: 1.09081,
          h: 1.09084,
          l: 1.09073,
          c: 1.09075,
          v: 89
        },
        {
          t: 11400,
          o: 1.09075,
          h: 1.09077,
          l: 1.09065,
          c: 1.09074,
          v: 115
        },
        {
          t: 11700,
          o: 1.09074,
          h: 1.09076,
          l: 1.09066,
          c: 1.0907,
          v: 118
        },
        {
          t: 12000,
          o: 1.09071,
          h: 1.0908,
          l: 1.09051,
          c: 1.09065,
          v: 103
        },
        {
          t: 12300,
          o: 1.09064,
          h: 1.09065,
          l: 1.09047,
          c: 1.09059,
          v: 130
        },
        {
          t: 12600,
          o: 1.09057,
          h: 1.09067,
          l: 1.09054,
          c: 1.09067,
          v: 93
        },
        {
          t: 12900,
          o: 1.09065,
          h: 1.09079,
          l: 1.09065,
          c: 1.09066,
          v: 91
        },
        {
          t: 13200,
          o: 1.09065,
          h: 1.09069,
          l: 1.09055,
          c: 1.09056,
          v: 77
        },
        {
          t: 13500,
          o: 1.09057,
          h: 1.0906,
          l: 1.09037,
          c: 1.0905,
          v: 59
        },
        {
          t: 13800,
          o: 1.09049,
          h: 1.09051,
          l: 1.09044,
          c: 1.0905,
          v: 45
        },
        {
          t: 14100,
          o: 1.09051,
          h: 1.09052,
          l: 1.09039,
          c: 1.09049,
          v: 91
        },
        {
          t: 14400,
          o: 1.0905,
          h: 1.09051,
          l: 1.09027,
          c: 1.09029,
          v: 65
        },
        {
          t: 14700,
          o: 1.09029,
          h: 1.09036,
          l: 1.09028,
          c: 1.0903,
          v: 37
        },
        {
          t: 15000,
          o: 1.0903,
          h: 1.09045,
          l: 1.0903,
          c: 1.0904,
          v: 78
        },
        {
          t: 15300,
          o: 1.09042,
          h: 1.09052,
          l: 1.09035,
          c: 1.09051,
          v: 56
        },
        {
          t: 15600,
          o: 1.09052,
          h: 1.09055,
          l: 1.09039,
          c: 1.09051,
          v: 38
        },
        {
          t: 15900,
          o: 1.09051,
          h: 1.09055,
          l: 1.09047,
          c: 1.0905,
          v: 63
        },
        {
          t: 16200,
          o: 1.09053,
          h: 1.09061,
          l: 1.09043,
          c: 1.09059,
          v: 86
        },
        {
          t: 16500,
          o: 1.0906,
          h: 1.09062,
          l: 1.09055,
          c: 1.09058,
          v: 99
        },
        {
          t: 16800,
          o: 1.0906,
          h: 1.09061,
          l: 1.09056,
          c: 1.09059,
          v: 38
        },
        {
          t: 17100,
          o: 1.0906,
          h: 1.09067,
          l: 1.0906,
          c: 1.09065,
          v: 68
        },
        {
          t: 17400,
          o: 1.09066,
          h: 1.0909,
          l: 1.09065,
          c: 1.09088,
          v: 149
        },
        {
          t: 17700,
          o: 1.09089,
          h: 1.09129,
          l: 1.09089,
          c: 1.09121,
          v: 229
        },
        {
          t: 18000,
          o: 1.09121,
          h: 1.09121,
          l: 1.09085,
          c: 1.09116,
          v: 209
        },
        {
          t: 18300,
          o: 1.09116,
          h: 1.09123,
          l: 1.091,
          c: 1.09101,
          v: 164
        },
        {
          t: 18600,
          o: 1.09099,
          h: 1.09103,
          l: 1.09087,
          c: 1.09093,
          v: 141
        },
        {
          t: 18900,
          o: 1.09094,
          h: 1.09101,
          l: 1.09079,
          c: 1.09095,
          v: 152
        },
        {
          t: 19200,
          o: 1.09092,
          h: 1.09092,
          l: 1.09058,
          c: 1.0907,
          v: 124
        },
        {
          t: 19500,
          o: 1.0907,
          h: 1.0908,
          l: 1.09063,
          c: 1.09078,
          v: 133
        },
        {
          t: 19800,
          o: 1.09079,
          h: 1.09089,
          l: 1.09075,
          c: 1.09079,
          v: 143
        },
        {
          t: 20100,
          o: 1.09081,
          h: 1.09087,
          l: 1.09077,
          c: 1.09081,
          v: 79
        },
        {
          t: 20400,
          o: 1.0908,
          h: 1.09142,
          l: 1.09075,
          c: 1.0914,
          v: 193
        },
        {
          t: 20700,
          o: 1.09141,
          h: 1.09141,
          l: 1.09096,
          c: 1.09099,
          v: 158
        },
        {
          t: 21000,
          o: 1.09099,
          h: 1.0912,
          l: 1.09096,
          c: 1.09116,
          v: 149
        },
        {
          t: 21300,
          o: 1.09115,
          h: 1.09115,
          l: 1.09068,
          c: 1.09071,
          v: 153
        },
        {
          t: 21600,
          o: 1.0907,
          h: 1.09084,
          l: 1.09069,
          c: 1.09071,
          v: 188
        },
        {
          t: 21900,
          o: 1.09072,
          h: 1.09074,
          l: 1.09055,
          c: 1.09063,
          v: 160
        },
        {
          t: 22200,
          o: 1.09061,
          h: 1.0907,
          l: 1.09054,
          c: 1.09063,
          v: 131
        },
        {
          t: 22500,
          o: 1.09064,
          h: 1.09074,
          l: 1.09055,
          c: 1.09057,
          v: 155
        },
        {
          t: 22800,
          o: 1.09059,
          h: 1.09078,
          l: 1.09054,
          c: 1.09072,
          v: 137
        },
        {
          t: 23100,
          o: 1.09073,
          h: 1.09102,
          l: 1.0907,
          c: 1.0909,
          v: 176
        },
        {
          t: 23400,
          o: 1.09093,
          h: 1.09109,
          l: 1.09085,
          c: 1.09101,
          v: 249
        },
        {
          t: 23700,
          o: 1.09102,
          h: 1.09163,
          l: 1.09102,
          c: 1.0914,
          v: 285
        },
        {
          t: 24000,
          o: 1.0914,
          h: 1.09155,
          l: 1.09121,
          c: 1.0915,
          v: 220
        },
        {
          t: 24300,
          o: 1.0915,
          h: 1.09161,
          l: 1.0914,
          c: 1.0915,
          v: 165
        },
        {
          t: 24600,
          o: 1.09155,
          h: 1.09173,
          l: 1.09125,
          c: 1.0913,
          v: 226
        },
        {
          t: 24900,
          o: 1.0913,
          h: 1.09136,
          l: 1.091,
          c: 1.09106,
          v: 275
        },
        {
          t: 25200,
          o: 1.09106,
          h: 1.09128,
          l: 1.09078,
          c: 1.09121,
          v: 367
        },
        {
          t: 25500,
          o: 1.09121,
          h: 1.09167,
          l: 1.09119,
          c: 1.09147,
          v: 336
        },
        {
          t: 25800,
          o: 1.09145,
          h: 1.09171,
          l: 1.09137,
          c: 1.09166,
          v: 331
        },
        {
          t: 26100,
          o: 1.09166,
          h: 1.0917,
          l: 1.09146,
          c: 1.09165,
          v: 247
        },
        {
          t: 26400,
          o: 1.09169,
          h: 1.09173,
          l: 1.09141,
          c: 1.09156,
          v: 251
        },
        {
          t: 26700,
          o: 1.09154,
          h: 1.09175,
          l: 1.0913,
          c: 1.09151,
          v: 300
        },
        {
          t: 27000,
          o: 1.09152,
          h: 1.09152,
          l: 1.09128,
          c: 1.09136,
          v: 290
        },
        {
          t: 27300,
          o: 1.09136,
          h: 1.09147,
          l: 1.09115,
          c: 1.09126,
          v: 363
        },
        {
          t: 27600,
          o: 1.09125,
          h: 1.09151,
          l: 1.09122,
          c: 1.09135,
          v: 295
        },
        {
          t: 27900,
          o: 1.09136,
          h: 1.09147,
          l: 1.09118,
          c: 1.09121,
          v: 252
        },
        {
          t: 28200,
          o: 1.09123,
          h: 1.09182,
          l: 1.0912,
          c: 1.09175,
          v: 264
        },
        {
          t: 28500,
          o: 1.09176,
          h: 1.09219,
          l: 1.09166,
          c: 1.09209,
          v: 362
        },
        {
          t: 28800,
          o: 1.09208,
          h: 1.09224,
          l: 1.09184,
          c: 1.09222,
          v: 350
        },
        {
          t: 29100,
          o: 1.09224,
          h: 1.09324,
          l: 1.0922,
          c: 1.09321,
          v: 480
        },
        {
          t: 29400,
          o: 1.09322,
          h: 1.09343,
          l: 1.09288,
          c: 1.09289,
          v: 414
        },
        {
          t: 29700,
          o: 1.09289,
          h: 1.09323,
          l: 1.09269,
          c: 1.09303,
          v: 446
        },
        {
          t: 30000,
          o: 1.09304,
          h: 1.09337,
          l: 1.09291,
          c: 1.09314,
          v: 461
        },
        {
          t: 30300,
          o: 1.09313,
          h: 1.09379,
          l: 1.09313,
          c: 1.09371,
          v: 441
        },
        {
          t: 30600,
          o: 1.09367,
          h: 1.09418,
          l: 1.09347,
          c: 1.09407,
          v: 536
        },
        {
          t: 30900,
          o: 1.09408,
          h: 1.09446,
          l: 1.09404,
          c: 1.0943,
          v: 458
        },
        {
          t: 31200,
          o: 1.09431,
          h: 1.09451,
          l: 1.09395,
          c: 1.09427,
          v: 460
        },
        {
          t: 31500,
          o: 1.09428,
          h: 1.09456,
          l: 1.09411,
          c: 1.09416,
          v: 369
        },
        {
          t: 31800,
          o: 1.09413,
          h: 1.09447,
          l: 1.09409,
          c: 1.09439,
          v: 378
        },
        {
          t: 32100,
          o: 1.09438,
          h: 1.09473,
          l: 1.09434,
          c: 1.09473,
          v: 408
        },
        {
          t: 32400,
          o: 1.09473,
          h: 1.09473,
          l: 1.09426,
          c: 1.09426,
          v: 361
        },
        {
          t: 32700,
          o: 1.09424,
          h: 1.09424,
          l: 1.09353,
          c: 1.09392,
          v: 436
        },
        {
          t: 33000,
          o: 1.09392,
          h: 1.09414,
          l: 1.09365,
          c: 1.09369,
          v: 329
        },
        {
          t: 33300,
          o: 1.09366,
          h: 1.09373,
          l: 1.09345,
          c: 1.09363,
          v: 317
        },
        {
          t: 33600,
          o: 1.09363,
          h: 1.09394,
          l: 1.09363,
          c: 1.09369,
          v: 237
        },
        {
          t: 33900,
          o: 1.0937,
          h: 1.09381,
          l: 1.09329,
          c: 1.09337,
          v: 239
        },
        {
          t: 34200,
          o: 1.09338,
          h: 1.09347,
          l: 1.09316,
          c: 1.09328,
          v: 252
        },
        {
          t: 34500,
          o: 1.09329,
          h: 1.09387,
          l: 1.09327,
          c: 1.09353,
          v: 305
        },
        {
          t: 34800,
          o: 1.09352,
          h: 1.09355,
          l: 1.09331,
          c: 1.0934,
          v: 227
        },
        {
          t: 35100,
          o: 1.09338,
          h: 1.0935,
          l: 1.09326,
          c: 1.09334,
          v: 278
        },
        {
          t: 35400,
          o: 1.09333,
          h: 1.09365,
          l: 1.0932,
          c: 1.09356,
          v: 243
        },
        {
          t: 35700,
          o: 1.09354,
          h: 1.09361,
          l: 1.09329,
          c: 1.09349,
          v: 228
        },
        {
          t: 36000,
          o: 1.09348,
          h: 1.09363,
          l: 1.09325,
          c: 1.09348,
          v: 293
        },
        {
          t: 36300,
          o: 1.09348,
          h: 1.09361,
          l: 1.09322,
          c: 1.09333,
          v: 381
        },
        {
          t: 36600,
          o: 1.09331,
          h: 1.09391,
          l: 1.0933,
          c: 1.09386,
          v: 348
        },
        {
          t: 36900,
          o: 1.09385,
          h: 1.09439,
          l: 1.09374,
          c: 1.09385,
          v: 374
        },
        {
          t: 37200,
          o: 1.09385,
          h: 1.0941,
          l: 1.09362,
          c: 1.09379,
          v: 319
        },
        {
          t: 37500,
          o: 1.09378,
          h: 1.0939,
          l: 1.09356,
          c: 1.09388,
          v: 334
        },
        {
          t: 37800,
          o: 1.09386,
          h: 1.09405,
          l: 1.09363,
          c: 1.09388,
          v: 290
        },
        {
          t: 38100,
          o: 1.09392,
          h: 1.09403,
          l: 1.09375,
          c: 1.094,
          v: 243
        },
        {
          t: 38400,
          o: 1.09401,
          h: 1.09434,
          l: 1.09389,
          c: 1.09424,
          v: 288
        },
        {
          t: 38700,
          o: 1.09423,
          h: 1.09439,
          l: 1.09401,
          c: 1.09424,
          v: 297
        },
        {
          t: 39000,
          o: 1.09426,
          h: 1.0943,
          l: 1.09399,
          c: 1.09401,
          v: 248
        },
        {
          t: 39300,
          o: 1.09401,
          h: 1.09413,
          l: 1.09384,
          c: 1.09393,
          v: 292
        },
        {
          t: 39600,
          o: 1.0939,
          h: 1.09403,
          l: 1.09359,
          c: 1.09377,
          v: 284
        },
        {
          t: 39900,
          o: 1.09378,
          h: 1.09437,
          l: 1.09378,
          c: 1.09413,
          v: 294
        },
        {
          t: 40200,
          o: 1.09412,
          h: 1.09413,
          l: 1.0938,
          c: 1.09397,
          v: 276
        },
        {
          t: 40500,
          o: 1.09397,
          h: 1.09397,
          l: 1.09355,
          c: 1.09359,
          v: 263
        },
        {
          t: 40800,
          o: 1.09363,
          h: 1.09394,
          l: 1.09363,
          c: 1.09386,
          v: 223
        },
        {
          t: 41100,
          o: 1.09385,
          h: 1.09392,
          l: 1.09343,
          c: 1.09357,
          v: 300
        },
        {
          t: 41400,
          o: 1.09358,
          h: 1.09393,
          l: 1.09356,
          c: 1.09366,
          v: 233
        },
        {
          t: 41700,
          o: 1.09365,
          h: 1.0939,
          l: 1.09346,
          c: 1.09377,
          v: 408
        },
        {
          t: 42000,
          o: 1.0938,
          h: 1.09445,
          l: 1.0938,
          c: 1.09419,
          v: 362
        },
        {
          t: 42300,
          o: 1.09414,
          h: 1.09468,
          l: 1.09409,
          c: 1.09463,
          v: 310
        },
        {
          t: 42600,
          o: 1.09462,
          h: 1.09475,
          l: 1.09432,
          c: 1.09436,
          v: 345
        },
        {
          t: 42900,
          o: 1.09435,
          h: 1.09448,
          l: 1.09407,
          c: 1.09448,
          v: 331
        },
        {
          t: 43200,
          o: 1.09446,
          h: 1.09454,
          l: 1.09415,
          c: 1.09441,
          v: 291
        },
        {
          t: 43500,
          o: 1.09441,
          h: 1.09466,
          l: 1.09422,
          c: 1.09456,
          v: 334
        },
        {
          t: 43800,
          o: 1.09454,
          h: 1.0947,
          l: 1.09445,
          c: 1.09455,
          v: 311
        },
        {
          t: 44100,
          o: 1.09454,
          h: 1.09462,
          l: 1.09447,
          c: 1.09454,
          v: 253
        },
        {
          t: 44400,
          o: 1.09453,
          h: 1.09465,
          l: 1.09441,
          c: 1.09463,
          v: 271
        },
        {
          t: 44700,
          o: 1.09461,
          h: 1.09474,
          l: 1.09454,
          c: 1.09471,
          v: 255
        },
        {
          t: 45000,
          o: 1.09472,
          h: 1.0956,
          l: 1.0946,
          c: 1.0951,
          v: 451
        },
        {
          t: 45300,
          o: 1.09509,
          h: 1.09569,
          l: 1.09509,
          c: 1.09568,
          v: 412
        },
        {
          t: 45600,
          o: 1.09573,
          h: 1.09627,
          l: 1.09558,
          c: 1.09612,
          v: 423
        },
        {
          t: 45900,
          o: 1.09618,
          h: 1.09663,
          l: 1.09587,
          c: 1.09621,
          v: 449
        },
        {
          t: 46200,
          o: 1.0962,
          h: 1.09633,
          l: 1.0958,
          c: 1.09621,
          v: 438
        },
        {
          t: 46500,
          o: 1.09622,
          h: 1.09672,
          l: 1.09622,
          c: 1.0964,
          v: 407
        },
        {
          t: 46800,
          o: 1.09641,
          h: 1.09646,
          l: 1.0961,
          c: 1.09627,
          v: 380
        },
        {
          t: 47100,
          o: 1.09622,
          h: 1.0966,
          l: 1.09618,
          c: 1.09644,
          v: 382
        },
        {
          t: 47400,
          o: 1.09645,
          h: 1.09651,
          l: 1.09608,
          c: 1.09631,
          v: 420
        },
        {
          t: 47700,
          o: 1.09636,
          h: 1.09661,
          l: 1.09628,
          c: 1.09643,
          v: 438
        },
        {
          t: 48000,
          o: 1.09645,
          h: 1.09647,
          l: 1.09608,
          c: 1.09629,
          v: 463
        },
        {
          t: 48300,
          o: 1.0963,
          h: 1.0965,
          l: 1.09624,
          c: 1.0963,
          v: 330
        },
        {
          t: 48600,
          o: 1.0963,
          h: 1.09642,
          l: 1.09585,
          c: 1.09625,
          v: 472
        },
        {
          t: 48900,
          o: 1.09627,
          h: 1.09706,
          l: 1.09624,
          c: 1.09687,
          v: 562
        },
        {
          t: 49200,
          o: 1.09689,
          h: 1.09705,
          l: 1.09677,
          c: 1.09678,
          v: 431
        },
        {
          t: 49500,
          o: 1.09679,
          h: 1.09735,
          l: 1.09657,
          c: 1.09733,
          v: 458
        },
        {
          t: 49800,
          o: 1.09733,
          h: 1.09743,
          l: 1.09696,
          c: 1.09725,
          v: 450
        },
        {
          t: 50100,
          o: 1.09723,
          h: 1.09772,
          l: 1.09709,
          c: 1.09761,
          v: 443
        },
        {
          t: 50400,
          o: 1.09762,
          h: 1.0978,
          l: 1.09735,
          c: 1.09737,
          v: 471
        },
        {
          t: 50700,
          o: 1.0974,
          h: 1.09762,
          l: 1.09717,
          c: 1.09737,
          v: 481
        },
        {
          t: 51000,
          o: 1.09737,
          h: 1.09754,
          l: 1.09624,
          c: 1.09668,
          v: 621
        },
        {
          t: 51300,
          o: 1.0967,
          h: 1.09709,
          l: 1.09667,
          c: 1.09697,
          v: 465
        },
        {
          t: 51600,
          o: 1.09698,
          h: 1.09744,
          l: 1.09698,
          c: 1.09741,
          v: 412
        },
        {
          t: 51900,
          o: 1.09739,
          h: 1.0978,
          l: 1.0971,
          c: 1.09743,
          v: 468
        },
        {
          t: 52200,
          o: 1.0974,
          h: 1.09764,
          l: 1.0969,
          c: 1.09739,
          v: 624
        },
        {
          t: 52500,
          o: 1.09744,
          h: 1.09773,
          l: 1.0972,
          c: 1.09723,
          v: 515
        },
        {
          t: 52800,
          o: 1.09722,
          h: 1.0983,
          l: 1.09715,
          c: 1.0982,
          v: 571
        },
        {
          t: 53100,
          o: 1.09822,
          h: 1.09846,
          l: 1.09789,
          c: 1.09792,
          v: 511
        },
        {
          t: 53400,
          o: 1.09786,
          h: 1.09795,
          l: 1.09727,
          c: 1.09728,
          v: 539
        },
        {
          t: 53700,
          o: 1.0973,
          h: 1.09766,
          l: 1.0969,
          c: 1.09762,
          v: 536
        },
        {
          t: 54000,
          o: 1.09762,
          h: 1.09883,
          l: 1.09721,
          c: 1.09877,
          v: 650
        },
        {
          t: 54300,
          o: 1.09878,
          h: 1.09925,
          l: 1.09866,
          c: 1.09901,
          v: 597
        },
        {
          t: 54600,
          o: 1.09903,
          h: 1.09948,
          l: 1.09887,
          c: 1.09938,
          v: 589
        },
        {
          t: 54900,
          o: 1.0994,
          h: 1.0997,
          l: 1.09899,
          c: 1.09903,
          v: 596
        },
        {
          t: 55200,
          o: 1.09902,
          h: 1.09978,
          l: 1.09886,
          c: 1.09956,
          v: 545
        },
        {
          t: 55500,
          o: 1.09955,
          h: 1.09986,
          l: 1.09936,
          c: 1.09963,
          v: 570
        },
        {
          t: 55800,
          o: 1.09963,
          h: 1.10044,
          l: 1.09946,
          c: 1.10043,
          v: 598
        },
        {
          t: 56100,
          o: 1.10042,
          h: 1.10043,
          l: 1.09945,
          c: 1.09973,
          v: 573
        },
        {
          t: 56400,
          o: 1.09974,
          h: 1.10059,
          l: 1.09973,
          c: 1.10039,
          v: 520
        },
        {
          t: 56700,
          o: 1.10038,
          h: 1.10039,
          l: 1.09905,
          c: 1.09906,
          v: 576
        },
        {
          t: 57000,
          o: 1.09907,
          h: 1.09938,
          l: 1.09857,
          c: 1.09924,
          v: 524
        },
        {
          t: 57300,
          o: 1.09929,
          h: 1.0995,
          l: 1.09835,
          c: 1.09836,
          v: 598
        },
        {
          t: 57600,
          o: 1.09837,
          h: 1.09888,
          l: 1.0981,
          c: 1.09861,
          v: 572
        },
        {
          t: 57900,
          o: 1.0986,
          h: 1.09877,
          l: 1.09726,
          c: 1.09752,
          v: 655
        },
        {
          t: 58200,
          o: 1.0975,
          h: 1.09868,
          l: 1.09742,
          c: 1.09755,
          v: 717
        },
        {
          t: 58500,
          o: 1.09756,
          h: 1.09795,
          l: 1.09735,
          c: 1.09766,
          v: 480
        },
        {
          t: 58800,
          o: 1.09767,
          h: 1.09808,
          l: 1.09717,
          c: 1.09801,
          v: 407
        },
        {
          t: 59100,
          o: 1.09795,
          h: 1.09802,
          l: 1.09751,
          c: 1.09788,
          v: 415
        },
        {
          t: 59400,
          o: 1.09792,
          h: 1.09823,
          l: 1.09735,
          c: 1.09736,
          v: 474
        },
        {
          t: 59700,
          o: 1.09737,
          h: 1.09769,
          l: 1.09701,
          c: 1.0976,
          v: 411
        },
        {
          t: 60000,
          o: 1.0976,
          h: 1.09773,
          l: 1.09736,
          c: 1.09773,
          v: 352
        },
        {
          t: 60300,
          o: 1.09772,
          h: 1.09803,
          l: 1.09766,
          c: 1.09793,
          v: 398
        },
        {
          t: 60600,
          o: 1.09793,
          h: 1.09828,
          l: 1.09777,
          c: 1.09792,
          v: 387
        },
        {
          t: 60900,
          o: 1.09792,
          h: 1.09806,
          l: 1.09738,
          c: 1.09751,
          v: 418
        },
        {
          t: 61200,
          o: 1.09753,
          h: 1.09768,
          l: 1.09686,
          c: 1.09713,
          v: 423
        },
        {
          t: 61500,
          o: 1.09709,
          h: 1.09718,
          l: 1.09662,
          c: 1.09671,
          v: 346
        },
        {
          t: 61800,
          o: 1.09673,
          h: 1.09692,
          l: 1.09647,
          c: 1.09654,
          v: 397
        },
        {
          t: 62100,
          o: 1.09656,
          h: 1.09697,
          l: 1.09642,
          c: 1.09682,
          v: 414
        },
        {
          t: 62400,
          o: 1.09683,
          h: 1.09717,
          l: 1.09662,
          c: 1.09709,
          v: 444
        },
        {
          t: 62700,
          o: 1.09706,
          h: 1.09723,
          l: 1.09697,
          c: 1.09708,
          v: 314
        },
        {
          t: 63000,
          o: 1.09709,
          h: 1.09712,
          l: 1.09684,
          c: 1.09699,
          v: 418
        },
        {
          t: 63300,
          o: 1.09698,
          h: 1.09699,
          l: 1.09646,
          c: 1.09653,
          v: 359
        },
        {
          t: 63600,
          o: 1.09656,
          h: 1.09711,
          l: 1.09656,
          c: 1.09703,
          v: 359
        },
        {
          t: 63900,
          o: 1.09702,
          h: 1.09723,
          l: 1.09693,
          c: 1.09702,
          v: 295
        },
        {
          t: 64200,
          o: 1.09703,
          h: 1.09718,
          l: 1.09666,
          c: 1.09681,
          v: 310
        },
        {
          t: 64500,
          o: 1.09681,
          h: 1.09793,
          l: 1.0968,
          c: 1.09792,
          v: 446
        },
        {
          t: 64800,
          o: 1.09791,
          h: 1.09837,
          l: 1.09776,
          c: 1.09784,
          v: 431
        },
        {
          t: 65100,
          o: 1.09788,
          h: 1.09834,
          l: 1.09778,
          c: 1.09791,
          v: 373
        },
        {
          t: 65400,
          o: 1.09791,
          h: 1.09817,
          l: 1.09784,
          c: 1.098,
          v: 222
        },
        {
          t: 65700,
          o: 1.09801,
          h: 1.09852,
          l: 1.09801,
          c: 1.09847,
          v: 281
        },
        {
          t: 66000,
          o: 1.09847,
          h: 1.09869,
          l: 1.09832,
          c: 1.09858,
          v: 323
        },
        {
          t: 66300,
          o: 1.09858,
          h: 1.09859,
          l: 1.09827,
          c: 1.09857,
          v: 275
        },
        {
          t: 66600,
          o: 1.09858,
          h: 1.09874,
          l: 1.09847,
          c: 1.09867,
          v: 256
        },
        {
          t: 66900,
          o: 1.09871,
          h: 1.09893,
          l: 1.09857,
          c: 1.09885,
          v: 314
        },
        {
          t: 67200,
          o: 1.09887,
          h: 1.09937,
          l: 1.09861,
          c: 1.09926,
          v: 465
        },
        {
          t: 67500,
          o: 1.09928,
          h: 1.09939,
          l: 1.09877,
          c: 1.09881,
          v: 404
        },
        {
          t: 67800,
          o: 1.09882,
          h: 1.09908,
          l: 1.09842,
          c: 1.09846,
          v: 345
        },
        {
          t: 68100,
          o: 1.09849,
          h: 1.09884,
          l: 1.09794,
          c: 1.09883,
          v: 401
        },
        {
          t: 68400,
          o: 1.09884,
          h: 1.09909,
          l: 1.09863,
          c: 1.09898,
          v: 372
        },
        {
          t: 68700,
          o: 1.09899,
          h: 1.09908,
          l: 1.09886,
          c: 1.09899,
          v: 331
        },
        {
          t: 69000,
          o: 1.09898,
          h: 1.09924,
          l: 1.09898,
          c: 1.09909,
          v: 351
        },
        {
          t: 69300,
          o: 1.09908,
          h: 1.09938,
          l: 1.09896,
          c: 1.09935,
          v: 367
        },
        {
          t: 69600,
          o: 1.09937,
          h: 1.09945,
          l: 1.09906,
          c: 1.0991,
          v: 282
        },
        {
          t: 69900,
          o: 1.09913,
          h: 1.09925,
          l: 1.0986,
          c: 1.09866,
          v: 304
        },
        {
          t: 70200,
          o: 1.09867,
          h: 1.09893,
          l: 1.09865,
          c: 1.09882,
          v: 255
        },
        {
          t: 70500,
          o: 1.09882,
          h: 1.09884,
          l: 1.09855,
          c: 1.09876,
          v: 244
        },
        {
          t: 70800,
          o: 1.09874,
          h: 1.09892,
          l: 1.09864,
          c: 1.09868,
          v: 239
        },
        {
          t: 71100,
          o: 1.0987,
          h: 1.0988,
          l: 1.0985,
          c: 1.09866,
          v: 243
        },
        {
          t: 71400,
          o: 1.09864,
          h: 1.09869,
          l: 1.09852,
          c: 1.09868,
          v: 217
        },
        {
          t: 71700,
          o: 1.09867,
          h: 1.09887,
          l: 1.09858,
          c: 1.09869,
          v: 230
        },
        {
          t: 72000,
          o: 1.09868,
          h: 1.0988,
          l: 1.0984,
          c: 1.09872,
          v: 309
        },
        {
          t: 72300,
          o: 1.09872,
          h: 1.09872,
          l: 1.09811,
          c: 1.09819,
          v: 304
        },
        {
          t: 72600,
          o: 1.09819,
          h: 1.09837,
          l: 1.09802,
          c: 1.09837,
          v: 238
        },
        {
          t: 72900,
          o: 1.09838,
          h: 1.09845,
          l: 1.09827,
          c: 1.09844,
          v: 236
        },
        {
          t: 73200,
          o: 1.09842,
          h: 1.09851,
          l: 1.0983,
          c: 1.09849,
          v: 265
        },
        {
          t: 73500,
          o: 1.09848,
          h: 1.09865,
          l: 1.0984,
          c: 1.09865,
          v: 237
        },
        {
          t: 73800,
          o: 1.09865,
          h: 1.09872,
          l: 1.09851,
          c: 1.09859,
          v: 213
        },
        {
          t: 74100,
          o: 1.0986,
          h: 1.09897,
          l: 1.09857,
          c: 1.09897,
          v: 271
        },
        {
          t: 74400,
          o: 1.09895,
          h: 1.09927,
          l: 1.09892,
          c: 1.09926,
          v: 280
        },
        {
          t: 74700,
          o: 1.09926,
          h: 1.09935,
          l: 1.09891,
          c: 1.09913,
          v: 294
        },
        {
          t: 75000,
          o: 1.09914,
          h: 1.09944,
          l: 1.09912,
          c: 1.09941,
          v: 340
        },
        {
          t: 75300,
          o: 1.09942,
          h: 1.09945,
          l: 1.09922,
          c: 1.09923,
          v: 381
        },
        {
          t: 75600,
          o: 1.09924,
          h: 1.09924,
          l: 1.0987,
          c: 1.09922,
          v: 321
        },
        {
          t: 75900,
          o: 1.09923,
          h: 1.09953,
          l: 1.09919,
          c: 1.09952,
          v: 152
        },
        {
          t: 76200,
          o: 1.0995,
          h: 1.09973,
          l: 1.09949,
          c: 1.09968,
          v: 191
        },
        {
          t: 76500,
          o: 1.09967,
          h: 1.09972,
          l: 1.09922,
          c: 1.09944,
          v: 138
        },
        {
          t: 76800,
          o: 1.09948,
          h: 1.09972,
          l: 1.09946,
          c: 1.09948,
          v: 121
        },
        {
          t: 77100,
          o: 1.09948,
          h: 1.09948,
          l: 1.09917,
          c: 1.09918,
          v: 138
        },
        {
          t: 77400,
          o: 1.09918,
          h: 1.09942,
          l: 1.09914,
          c: 1.09933,
          v: 120
        },
        {
          t: 77700,
          o: 1.09932,
          h: 1.09978,
          l: 1.09932,
          c: 1.09964,
          v: 94
        },
        {
          t: 78000,
          o: 1.09961,
          h: 1.09982,
          l: 1.09959,
          c: 1.09965,
          v: 92
        },
        {
          t: 78300,
          o: 1.09967,
          h: 1.09979,
          l: 1.09965,
          c: 1.09968,
          v: 105
        },
        {
          t: 78600,
          o: 1.09968,
          h: 1.09995,
          l: 1.09968,
          c: 1.09994,
          v: 91
        },
        {
          t: 78900,
          o: 1.09995,
          h: 1.10029,
          l: 1.09984,
          c: 1.09988,
          v: 212
        },
        {
          t: 79200,
          o: 1.09996,
          h: 1.10038,
          l: 1.09981,
          c: 1.10038,
          v: 109
        },
        {
          t: 79500,
          o: 1.10038,
          h: 1.10039,
          l: 1.10014,
          c: 1.10039,
          v: 59
        },
        {
          t: 79800,
          o: 1.1004,
          h: 1.10041,
          l: 1.10015,
          c: 1.10019,
          v: 87
        },
        {
          t: 80100,
          o: 1.10017,
          h: 1.10044,
          l: 1.10012,
          c: 1.10028,
          v: 105
        },
        {
          t: 80400,
          o: 1.10026,
          h: 1.10042,
          l: 1.10012,
          c: 1.10016,
          v: 112
        },
        {
          t: 80700,
          o: 1.10014,
          h: 1.10037,
          l: 1.10006,
          c: 1.10015,
          v: 50
        },
        {
          t: 81000,
          o: 1.09997,
          h: 1.10024,
          l: 1.09997,
          c: 1.10022,
          v: 75
        },
        {
          t: 81300,
          o: 1.10019,
          h: 1.10027,
          l: 1.10016,
          c: 1.10019,
          v: 67
        },
        {
          t: 81600,
          o: 1.1002,
          h: 1.10026,
          l: 1.10015,
          c: 1.10024,
          v: 117
        },
        {
          t: 81900,
          o: 1.10024,
          h: 1.10031,
          l: 1.10021,
          c: 1.1003,
          v: 65
        },
        {
          t: 82200,
          o: 1.10029,
          h: 1.10035,
          l: 1.10017,
          c: 1.1002,
          v: 68
        },
        {
          t: 82500,
          o: 1.10018,
          h: 1.10025,
          l: 1.10015,
          c: 1.10022,
          v: 46
        },
        {
          t: 82800,
          o: 1.10021,
          h: 1.10037,
          l: 1.1001,
          c: 1.10019,
          v: 186
        },
        {
          t: 83100,
          o: 1.10019,
          h: 1.10019,
          l: 1.09981,
          c: 1.09999,
          v: 221
        },
        {
          t: 83400,
          o: 1.09999,
          h: 1.10016,
          l: 1.09997,
          c: 1.09999,
          v: 172
        },
        {
          t: 83700,
          o: 1.09998,
          h: 1.10016,
          l: 1.09992,
          c: 1.10009,
          v: 156
        },
        {
          t: 84000,
          o: 1.10008,
          h: 1.10013,
          l: 1.10002,
          c: 1.10004,
          v: 151
        },
        {
          t: 84300,
          o: 1.10004,
          h: 1.10009,
          l: 1.09975,
          c: 1.09976,
          v: 184
        },
        {
          t: 84600,
          o: 1.09976,
          h: 1.0998,
          l: 1.09967,
          c: 1.0998,
          v: 208
        },
        {
          t: 84900,
          o: 1.09979,
          h: 1.09987,
          l: 1.09976,
          c: 1.09987,
          v: 75
        }
      ]
    },
    {
      d: 1582848000,
      b: [
        {
          t: 7200,
          o: 1.09851,
          h: 1.09851,
          l: 1.09818,
          c: 1.0985,
          v: 156
        },
        {
          t: 7500,
          o: 1.09849,
          h: 1.0987,
          l: 1.09848,
          c: 1.09862,
          v: 133
        },
        {
          t: 7800,
          o: 1.09863,
          h: 1.09882,
          l: 1.09859,
          c: 1.0988,
          v: 159
        },
        {
          t: 8100,
          o: 1.09879,
          h: 1.09883,
          l: 1.09874,
          c: 1.09881,
          v: 145
        },
        {
          t: 8400,
          o: 1.09882,
          h: 1.09883,
          l: 1.09857,
          c: 1.09857,
          v: 98
        },
        {
          t: 8700,
          o: 1.09851,
          h: 1.09886,
          l: 1.09851,
          c: 1.09882,
          v: 184
        },
        {
          t: 9000,
          o: 1.09881,
          h: 1.09907,
          l: 1.09878,
          c: 1.09888,
          v: 266
        },
        {
          t: 9300,
          o: 1.0989,
          h: 1.09898,
          l: 1.09851,
          c: 1.09871,
          v: 306
        },
        {
          t: 9600,
          o: 1.09871,
          h: 1.09874,
          l: 1.09855,
          c: 1.09861,
          v: 151
        },
        {
          t: 9900,
          o: 1.09863,
          h: 1.09882,
          l: 1.09862,
          c: 1.09869,
          v: 139
        },
        {
          t: 10200,
          o: 1.09869,
          h: 1.09875,
          l: 1.09865,
          c: 1.09866,
          v: 126
        },
        {
          t: 10500,
          o: 1.09863,
          h: 1.09871,
          l: 1.09856,
          c: 1.09858,
          v: 174
        },
        {
          t: 10800,
          o: 1.09858,
          h: 1.09858,
          l: 1.09842,
          c: 1.09843,
          v: 179
        },
        {
          t: 11100,
          o: 1.09841,
          h: 1.09851,
          l: 1.0984,
          c: 1.0984,
          v: 264
        },
        {
          t: 11400,
          o: 1.09839,
          h: 1.09863,
          l: 1.09839,
          c: 1.09849,
          v: 278
        },
        {
          t: 11700,
          o: 1.09851,
          h: 1.09851,
          l: 1.09837,
          c: 1.09845,
          v: 191
        },
        {
          t: 12000,
          o: 1.09843,
          h: 1.09865,
          l: 1.09841,
          c: 1.09865,
          v: 168
        },
        {
          t: 12300,
          o: 1.09865,
          h: 1.09876,
          l: 1.09857,
          c: 1.09868,
          v: 287
        },
        {
          t: 12600,
          o: 1.09869,
          h: 1.0988,
          l: 1.09859,
          c: 1.09875,
          v: 318
        },
        {
          t: 12900,
          o: 1.09878,
          h: 1.09916,
          l: 1.09873,
          c: 1.09892,
          v: 262
        },
        {
          t: 13200,
          o: 1.09891,
          h: 1.09932,
          l: 1.0989,
          c: 1.09926,
          v: 277
        },
        {
          t: 13500,
          o: 1.09928,
          h: 1.0993,
          l: 1.09909,
          c: 1.09921,
          v: 201
        },
        {
          t: 13800,
          o: 1.09923,
          h: 1.09924,
          l: 1.09877,
          c: 1.0989,
          v: 297
        },
        {
          t: 14100,
          o: 1.09889,
          h: 1.09889,
          l: 1.09861,
          c: 1.09864,
          v: 304
        },
        {
          t: 14400,
          o: 1.09865,
          h: 1.09881,
          l: 1.09852,
          c: 1.09879,
          v: 212
        },
        {
          t: 14700,
          o: 1.09878,
          h: 1.09897,
          l: 1.09876,
          c: 1.09894,
          v: 246
        },
        {
          t: 15000,
          o: 1.09894,
          h: 1.09905,
          l: 1.09889,
          c: 1.09893,
          v: 272
        },
        {
          t: 15300,
          o: 1.09893,
          h: 1.09907,
          l: 1.09883,
          c: 1.09891,
          v: 221
        },
        {
          t: 15600,
          o: 1.09894,
          h: 1.0991,
          l: 1.09894,
          c: 1.09903,
          v: 159
        },
        {
          t: 15900,
          o: 1.09903,
          h: 1.09926,
          l: 1.09903,
          c: 1.0992,
          v: 177
        },
        {
          t: 16200,
          o: 1.09921,
          h: 1.09923,
          l: 1.09914,
          c: 1.09917,
          v: 109
        },
        {
          t: 16500,
          o: 1.09918,
          h: 1.0993,
          l: 1.09915,
          c: 1.09929,
          v: 133
        },
        {
          t: 16800,
          o: 1.0993,
          h: 1.09951,
          l: 1.09929,
          c: 1.0995,
          v: 235
        },
        {
          t: 17100,
          o: 1.09949,
          h: 1.09955,
          l: 1.09941,
          c: 1.0995,
          v: 117
        },
        {
          t: 17400,
          o: 1.0995,
          h: 1.0996,
          l: 1.09948,
          c: 1.09959,
          v: 113
        },
        {
          t: 17700,
          o: 1.09959,
          h: 1.09966,
          l: 1.09937,
          c: 1.09938,
          v: 190
        },
        {
          t: 18000,
          o: 1.09938,
          h: 1.0994,
          l: 1.09919,
          c: 1.09924,
          v: 179
        },
        {
          t: 18300,
          o: 1.09925,
          h: 1.0996,
          l: 1.09923,
          c: 1.09954,
          v: 238
        },
        {
          t: 18600,
          o: 1.09953,
          h: 1.09961,
          l: 1.09942,
          c: 1.09958,
          v: 211
        },
        {
          t: 18900,
          o: 1.09959,
          h: 1.09971,
          l: 1.09936,
          c: 1.09936,
          v: 152
        },
        {
          t: 19200,
          o: 1.09936,
          h: 1.09998,
          l: 1.09936,
          c: 1.09997,
          v: 172
        },
        {
          t: 19500,
          o: 1.09999,
          h: 1.10039,
          l: 1.09999,
          c: 1.09999,
          v: 228
        },
        {
          t: 19800,
          o: 1.09998,
          h: 1.09999,
          l: 1.09967,
          c: 1.09974,
          v: 250
        },
        {
          t: 20100,
          o: 1.09978,
          h: 1.09989,
          l: 1.0996,
          c: 1.09989,
          v: 186
        },
        {
          t: 20400,
          o: 1.09983,
          h: 1.09983,
          l: 1.09963,
          c: 1.09975,
          v: 214
        },
        {
          t: 20700,
          o: 1.09979,
          h: 1.10015,
          l: 1.09973,
          c: 1.1001,
          v: 240
        },
        {
          t: 21000,
          o: 1.10009,
          h: 1.10022,
          l: 1.09997,
          c: 1.10001,
          v: 209
        },
        {
          t: 21300,
          o: 1.10004,
          h: 1.1002,
          l: 1.09994,
          c: 1.09997,
          v: 202
        },
        {
          t: 21600,
          o: 1.09994,
          h: 1.10075,
          l: 1.09982,
          c: 1.10058,
          v: 443
        },
        {
          t: 21900,
          o: 1.10056,
          h: 1.10076,
          l: 1.10042,
          c: 1.10064,
          v: 356
        },
        {
          t: 22200,
          o: 1.10064,
          h: 1.10067,
          l: 1.10039,
          c: 1.10052,
          v: 274
        },
        {
          t: 22500,
          o: 1.10053,
          h: 1.10053,
          l: 1.10018,
          c: 1.10028,
          v: 294
        },
        {
          t: 22800,
          o: 1.10027,
          h: 1.10029,
          l: 1.10009,
          c: 1.10023,
          v: 167
        },
        {
          t: 23100,
          o: 1.10029,
          h: 1.10035,
          l: 1.10007,
          c: 1.1003,
          v: 251
        },
        {
          t: 23400,
          o: 1.10031,
          h: 1.10047,
          l: 1.1001,
          c: 1.10045,
          v: 245
        },
        {
          t: 23700,
          o: 1.10046,
          h: 1.1005,
          l: 1.10026,
          c: 1.10049,
          v: 198
        },
        {
          t: 24000,
          o: 1.10047,
          h: 1.10073,
          l: 1.10041,
          c: 1.10051,
          v: 207
        },
        {
          t: 24300,
          o: 1.10048,
          h: 1.10075,
          l: 1.10047,
          c: 1.1005,
          v: 226
        },
        {
          t: 24600,
          o: 1.10052,
          h: 1.10055,
          l: 1.10034,
          c: 1.10054,
          v: 217
        },
        {
          t: 24900,
          o: 1.10051,
          h: 1.10054,
          l: 1.10006,
          c: 1.10006,
          v: 221
        },
        {
          t: 25200,
          o: 1.10009,
          h: 1.10038,
          l: 1.09994,
          c: 1.10004,
          v: 388
        },
        {
          t: 25500,
          o: 1.10005,
          h: 1.10037,
          l: 1.09995,
          c: 1.10013,
          v: 329
        },
        {
          t: 25800,
          o: 1.10013,
          h: 1.10018,
          l: 1.09969,
          c: 1.09969,
          v: 287
        },
        {
          t: 26100,
          o: 1.09968,
          h: 1.09987,
          l: 1.0995,
          c: 1.09953,
          v: 293
        },
        {
          t: 26400,
          o: 1.09954,
          h: 1.09976,
          l: 1.09929,
          c: 1.09943,
          v: 318
        },
        {
          t: 26700,
          o: 1.09945,
          h: 1.10001,
          l: 1.09943,
          c: 1.09988,
          v: 321
        },
        {
          t: 27000,
          o: 1.09991,
          h: 1.10039,
          l: 1.09968,
          c: 1.10023,
          v: 443
        },
        {
          t: 27300,
          o: 1.10024,
          h: 1.10025,
          l: 1.09981,
          c: 1.0999,
          v: 421
        },
        {
          t: 27600,
          o: 1.09991,
          h: 1.10004,
          l: 1.09947,
          c: 1.09958,
          v: 409
        },
        {
          t: 27900,
          o: 1.09959,
          h: 1.10001,
          l: 1.09922,
          c: 1.09973,
          v: 437
        },
        {
          t: 28200,
          o: 1.09974,
          h: 1.09976,
          l: 1.09922,
          c: 1.09942,
          v: 435
        },
        {
          t: 28500,
          o: 1.09941,
          h: 1.09952,
          l: 1.09909,
          c: 1.09949,
          v: 391
        },
        {
          t: 28800,
          o: 1.09952,
          h: 1.10046,
          l: 1.09947,
          c: 1.10041,
          v: 578
        },
        {
          t: 29100,
          o: 1.10041,
          h: 1.10127,
          l: 1.1003,
          c: 1.10126,
          v: 563
        },
        {
          t: 29400,
          o: 1.10126,
          h: 1.10195,
          l: 1.10126,
          c: 1.10157,
          v: 556
        },
        {
          t: 29700,
          o: 1.10156,
          h: 1.10259,
          l: 1.10145,
          c: 1.10225,
          v: 566
        },
        {
          t: 30000,
          o: 1.10224,
          h: 1.10275,
          l: 1.10181,
          c: 1.10225,
          v: 547
        },
        {
          t: 30300,
          o: 1.10225,
          h: 1.10275,
          l: 1.10224,
          c: 1.10244,
          v: 428
        },
        {
          t: 30600,
          o: 1.10243,
          h: 1.10249,
          l: 1.10123,
          c: 1.10135,
          v: 514
        },
        {
          t: 30900,
          o: 1.10135,
          h: 1.10199,
          l: 1.10125,
          c: 1.10174,
          v: 557
        },
        {
          t: 31200,
          o: 1.10175,
          h: 1.10212,
          l: 1.10156,
          c: 1.1021,
          v: 499
        },
        {
          t: 31500,
          o: 1.10209,
          h: 1.10244,
          l: 1.10174,
          c: 1.10238,
          v: 549
        },
        {
          t: 31800,
          o: 1.10242,
          h: 1.10289,
          l: 1.10231,
          c: 1.10281,
          v: 426
        },
        {
          t: 32100,
          o: 1.10281,
          h: 1.10336,
          l: 1.10265,
          c: 1.10335,
          v: 466
        },
        {
          t: 32400,
          o: 1.10336,
          h: 1.10338,
          l: 1.10285,
          c: 1.10321,
          v: 520
        },
        {
          t: 32700,
          o: 1.10321,
          h: 1.10381,
          l: 1.10316,
          c: 1.10366,
          v: 424
        },
        {
          t: 33000,
          o: 1.10365,
          h: 1.10467,
          l: 1.10355,
          c: 1.10464,
          v: 489
        },
        {
          t: 33300,
          o: 1.10465,
          h: 1.10484,
          l: 1.10403,
          c: 1.10403,
          v: 533
        },
        {
          t: 33600,
          o: 1.10405,
          h: 1.10431,
          l: 1.10367,
          c: 1.10386,
          v: 526
        },
        {
          t: 33900,
          o: 1.10388,
          h: 1.10459,
          l: 1.10378,
          c: 1.10454,
          v: 438
        },
        {
          t: 34200,
          o: 1.10454,
          h: 1.10484,
          l: 1.10451,
          c: 1.10478,
          v: 456
        },
        {
          t: 34500,
          o: 1.10477,
          h: 1.10485,
          l: 1.10451,
          c: 1.10478,
          v: 414
        },
        {
          t: 34800,
          o: 1.10477,
          h: 1.10524,
          l: 1.10438,
          c: 1.10441,
          v: 529
        },
        {
          t: 35100,
          o: 1.10442,
          h: 1.10442,
          l: 1.10374,
          c: 1.10397,
          v: 450
        },
        {
          t: 35400,
          o: 1.104,
          h: 1.10423,
          l: 1.10368,
          c: 1.10417,
          v: 491
        },
        {
          t: 35700,
          o: 1.10418,
          h: 1.10463,
          l: 1.10417,
          c: 1.1044,
          v: 428
        },
        {
          t: 36000,
          o: 1.1044,
          h: 1.10445,
          l: 1.10332,
          c: 1.10339,
          v: 535
        },
        {
          t: 36300,
          o: 1.10337,
          h: 1.10358,
          l: 1.10317,
          c: 1.10341,
          v: 439
        },
        {
          t: 36600,
          o: 1.10344,
          h: 1.10386,
          l: 1.10342,
          c: 1.10383,
          v: 361
        },
        {
          t: 36900,
          o: 1.10382,
          h: 1.10423,
          l: 1.10346,
          c: 1.10347,
          v: 427
        },
        {
          t: 37200,
          o: 1.10349,
          h: 1.10351,
          l: 1.10276,
          c: 1.10281,
          v: 395
        },
        {
          t: 37500,
          o: 1.10283,
          h: 1.10317,
          l: 1.10256,
          c: 1.10286,
          v: 423
        },
        {
          t: 37800,
          o: 1.10284,
          h: 1.10304,
          l: 1.10257,
          c: 1.10304,
          v: 383
        },
        {
          t: 38100,
          o: 1.10305,
          h: 1.10338,
          l: 1.10289,
          c: 1.10293,
          v: 339
        },
        {
          t: 38400,
          o: 1.10292,
          h: 1.10312,
          l: 1.10283,
          c: 1.10298,
          v: 321
        },
        {
          t: 38700,
          o: 1.10298,
          h: 1.10368,
          l: 1.10294,
          c: 1.10368,
          v: 324
        },
        {
          t: 39000,
          o: 1.1037,
          h: 1.1039,
          l: 1.10353,
          c: 1.10375,
          v: 291
        },
        {
          t: 39300,
          o: 1.10376,
          h: 1.10413,
          l: 1.1033,
          c: 1.10335,
          v: 406
        },
        {
          t: 39600,
          o: 1.10335,
          h: 1.10407,
          l: 1.10316,
          c: 1.10396,
          v: 431
        },
        {
          t: 39900,
          o: 1.10393,
          h: 1.10418,
          l: 1.10346,
          c: 1.10346,
          v: 389
        },
        {
          t: 40200,
          o: 1.1035,
          h: 1.10357,
          l: 1.10276,
          c: 1.10292,
          v: 436
        },
        {
          t: 40500,
          o: 1.10291,
          h: 1.10321,
          l: 1.10272,
          c: 1.10273,
          v: 342
        },
        {
          t: 40800,
          o: 1.10275,
          h: 1.10295,
          l: 1.10254,
          c: 1.10255,
          v: 342
        },
        {
          t: 41100,
          o: 1.10255,
          h: 1.10256,
          l: 1.10119,
          c: 1.10129,
          v: 471
        },
        {
          t: 41400,
          o: 1.10122,
          h: 1.10127,
          l: 1.10044,
          c: 1.10118,
          v: 499
        },
        {
          t: 41700,
          o: 1.10121,
          h: 1.10132,
          l: 1.10096,
          c: 1.10114,
          v: 354
        },
        {
          t: 42000,
          o: 1.10113,
          h: 1.10113,
          l: 1.10042,
          c: 1.10043,
          v: 375
        },
        {
          t: 42300,
          o: 1.10043,
          h: 1.10094,
          l: 1.10027,
          c: 1.10086,
          v: 387
        },
        {
          t: 42600,
          o: 1.10089,
          h: 1.10089,
          l: 1.09875,
          c: 1.09876,
          v: 492
        },
        {
          t: 42900,
          o: 1.0988,
          h: 1.09959,
          l: 1.09858,
          c: 1.09931,
          v: 594
        },
        {
          t: 43200,
          o: 1.09933,
          h: 1.09947,
          l: 1.09894,
          c: 1.09916,
          v: 460
        },
        {
          t: 43500,
          o: 1.09918,
          h: 1.09996,
          l: 1.09902,
          c: 1.09983,
          v: 428
        },
        {
          t: 43800,
          o: 1.09974,
          h: 1.09988,
          l: 1.09947,
          c: 1.09947,
          v: 455
        },
        {
          t: 44100,
          o: 1.09951,
          h: 1.09951,
          l: 1.09915,
          c: 1.09926,
          v: 383
        },
        {
          t: 44400,
          o: 1.09927,
          h: 1.09929,
          l: 1.09866,
          c: 1.0992,
          v: 454
        },
        {
          t: 44700,
          o: 1.09919,
          h: 1.09937,
          l: 1.09847,
          c: 1.09862,
          v: 480
        },
        {
          t: 45000,
          o: 1.09861,
          h: 1.09961,
          l: 1.09857,
          c: 1.09938,
          v: 527
        },
        {
          t: 45300,
          o: 1.09937,
          h: 1.09984,
          l: 1.09934,
          c: 1.09953,
          v: 418
        },
        {
          t: 45600,
          o: 1.09954,
          h: 1.0998,
          l: 1.09897,
          c: 1.09897,
          v: 383
        },
        {
          t: 45900,
          o: 1.09902,
          h: 1.09936,
          l: 1.09851,
          c: 1.09882,
          v: 435
        },
        {
          t: 46200,
          o: 1.09882,
          h: 1.09931,
          l: 1.09879,
          c: 1.09898,
          v: 423
        },
        {
          t: 46500,
          o: 1.099,
          h: 1.09916,
          l: 1.09866,
          c: 1.09866,
          v: 402
        },
        {
          t: 46800,
          o: 1.0987,
          h: 1.09896,
          l: 1.09864,
          c: 1.09867,
          v: 434
        },
        {
          t: 47100,
          o: 1.0987,
          h: 1.09928,
          l: 1.09859,
          c: 1.09882,
          v: 480
        },
        {
          t: 47400,
          o: 1.09883,
          h: 1.09892,
          l: 1.09739,
          c: 1.0977,
          v: 440
        },
        {
          t: 47700,
          o: 1.09774,
          h: 1.09839,
          l: 1.09749,
          c: 1.09831,
          v: 525
        },
        {
          t: 48000,
          o: 1.09835,
          h: 1.09835,
          l: 1.09694,
          c: 1.09736,
          v: 568
        },
        {
          t: 48300,
          o: 1.09737,
          h: 1.09761,
          l: 1.09684,
          c: 1.09699,
          v: 419
        },
        {
          t: 48600,
          o: 1.09698,
          h: 1.09751,
          l: 1.09684,
          c: 1.0973,
          v: 527
        },
        {
          t: 48900,
          o: 1.09729,
          h: 1.09771,
          l: 1.09722,
          c: 1.09765,
          v: 459
        },
        {
          t: 49200,
          o: 1.09766,
          h: 1.09768,
          l: 1.09722,
          c: 1.09764,
          v: 419
        },
        {
          t: 49500,
          o: 1.09763,
          h: 1.09805,
          l: 1.09731,
          c: 1.09781,
          v: 448
        },
        {
          t: 49800,
          o: 1.09782,
          h: 1.09903,
          l: 1.09756,
          c: 1.09874,
          v: 507
        },
        {
          t: 50100,
          o: 1.09874,
          h: 1.09907,
          l: 1.09846,
          c: 1.09863,
          v: 618
        },
        {
          t: 50400,
          o: 1.09865,
          h: 1.09878,
          l: 1.09827,
          c: 1.09847,
          v: 534
        },
        {
          t: 50700,
          o: 1.09849,
          h: 1.09864,
          l: 1.09806,
          c: 1.09825,
          v: 569
        },
        {
          t: 51000,
          o: 1.09826,
          h: 1.09836,
          l: 1.0979,
          c: 1.09808,
          v: 413
        },
        {
          t: 51300,
          o: 1.09809,
          h: 1.09822,
          l: 1.09774,
          c: 1.09785,
          v: 463
        },
        {
          t: 51600,
          o: 1.09785,
          h: 1.09788,
          l: 1.09757,
          c: 1.09783,
          v: 388
        },
        {
          t: 51900,
          o: 1.09784,
          h: 1.09807,
          l: 1.09726,
          c: 1.09733,
          v: 432
        },
        {
          t: 52200,
          o: 1.09732,
          h: 1.09735,
          l: 1.0961,
          c: 1.09648,
          v: 715
        },
        {
          t: 52500,
          o: 1.09648,
          h: 1.09673,
          l: 1.09579,
          c: 1.09616,
          v: 589
        },
        {
          t: 52800,
          o: 1.09615,
          h: 1.09627,
          l: 1.0951,
          c: 1.09522,
          v: 491
        },
        {
          t: 53100,
          o: 1.09527,
          h: 1.09619,
          l: 1.09504,
          c: 1.09619,
          v: 633
        },
        {
          t: 53400,
          o: 1.09617,
          h: 1.09668,
          l: 1.09615,
          c: 1.0964,
          v: 621
        },
        {
          t: 53700,
          o: 1.09641,
          h: 1.09723,
          l: 1.09607,
          c: 1.09658,
          v: 556
        },
        {
          t: 54000,
          o: 1.09655,
          h: 1.0968,
          l: 1.09598,
          c: 1.09638,
          v: 653
        },
        {
          t: 54300,
          o: 1.09638,
          h: 1.09641,
          l: 1.0955,
          c: 1.09552,
          v: 600
        },
        {
          t: 54600,
          o: 1.09559,
          h: 1.09643,
          l: 1.09544,
          c: 1.09622,
          v: 522
        },
        {
          t: 54900,
          o: 1.09623,
          h: 1.09638,
          l: 1.0957,
          c: 1.09624,
          v: 513
        },
        {
          t: 55200,
          o: 1.09624,
          h: 1.09663,
          l: 1.09612,
          c: 1.09662,
          v: 513
        },
        {
          t: 55500,
          o: 1.09661,
          h: 1.09728,
          l: 1.09632,
          c: 1.09724,
          v: 519
        },
        {
          t: 55800,
          o: 1.09723,
          h: 1.09729,
          l: 1.09662,
          c: 1.09682,
          v: 602
        },
        {
          t: 56100,
          o: 1.09684,
          h: 1.09739,
          l: 1.09666,
          c: 1.09709,
          v: 640
        },
        {
          t: 56400,
          o: 1.09709,
          h: 1.0975,
          l: 1.09627,
          c: 1.09733,
          v: 667
        },
        {
          t: 56700,
          o: 1.09729,
          h: 1.09808,
          l: 1.09715,
          c: 1.09807,
          v: 730
        },
        {
          t: 57000,
          o: 1.09808,
          h: 1.09832,
          l: 1.0966,
          c: 1.0967,
          v: 748
        },
        {
          t: 57300,
          o: 1.09669,
          h: 1.09896,
          l: 1.09669,
          c: 1.09839,
          v: 876
        },
        {
          t: 57600,
          o: 1.09843,
          h: 1.09883,
          l: 1.09785,
          c: 1.09859,
          v: 874
        },
        {
          t: 57900,
          o: 1.09859,
          h: 1.10019,
          l: 1.09858,
          c: 1.09984,
          v: 661
        },
        {
          t: 58200,
          o: 1.09985,
          h: 1.10008,
          l: 1.0985,
          c: 1.09853,
          v: 755
        },
        {
          t: 58500,
          o: 1.09853,
          h: 1.099,
          l: 1.09837,
          c: 1.09885,
          v: 671
        },
        {
          t: 58800,
          o: 1.09886,
          h: 1.09975,
          l: 1.09866,
          c: 1.09948,
          v: 665
        },
        {
          t: 59100,
          o: 1.09951,
          h: 1.10023,
          l: 1.09945,
          c: 1.10005,
          v: 568
        },
        {
          t: 59400,
          o: 1.10004,
          h: 1.10054,
          l: 1.09967,
          c: 1.09982,
          v: 642
        },
        {
          t: 59700,
          o: 1.0998,
          h: 1.10048,
          l: 1.09975,
          c: 1.1002,
          v: 640
        },
        {
          t: 60000,
          o: 1.10021,
          h: 1.10025,
          l: 1.09881,
          c: 1.09919,
          v: 695
        },
        {
          t: 60300,
          o: 1.09919,
          h: 1.09979,
          l: 1.09919,
          c: 1.09943,
          v: 566
        },
        {
          t: 60600,
          o: 1.09942,
          h: 1.10034,
          l: 1.0994,
          c: 1.10014,
          v: 541
        },
        {
          t: 60900,
          o: 1.10017,
          h: 1.10072,
          l: 1.09993,
          c: 1.09998,
          v: 611
        },
        {
          t: 61200,
          o: 1.09997,
          h: 1.10053,
          l: 1.09991,
          c: 1.10048,
          v: 493
        },
        {
          t: 61500,
          o: 1.10051,
          h: 1.10075,
          l: 1.09985,
          c: 1.09996,
          v: 512
        },
        {
          t: 61800,
          o: 1.09998,
          h: 1.10025,
          l: 1.09991,
          c: 1.1,
          v: 399
        },
        {
          t: 62100,
          o: 1.09998,
          h: 1.10053,
          l: 1.09998,
          c: 1.10042,
          v: 443
        },
        {
          t: 62400,
          o: 1.10043,
          h: 1.10083,
          l: 1.10035,
          c: 1.10061,
          v: 370
        },
        {
          t: 62700,
          o: 1.10063,
          h: 1.10063,
          l: 1.09997,
          c: 1.10007,
          v: 385
        },
        {
          t: 63000,
          o: 1.10008,
          h: 1.10048,
          l: 1.09974,
          c: 1.10043,
          v: 394
        },
        {
          t: 63300,
          o: 1.10042,
          h: 1.10058,
          l: 1.09991,
          c: 1.09992,
          v: 420
        },
        {
          t: 63600,
          o: 1.09992,
          h: 1.10022,
          l: 1.09946,
          c: 1.0999,
          v: 447
        },
        {
          t: 63900,
          o: 1.09989,
          h: 1.10001,
          l: 1.09965,
          c: 1.09984,
          v: 451
        },
        {
          t: 64200,
          o: 1.09986,
          h: 1.10027,
          l: 1.09985,
          c: 1.09988,
          v: 368
        },
        {
          t: 64500,
          o: 1.09988,
          h: 1.10022,
          l: 1.09987,
          c: 1.10008,
          v: 325
        },
        {
          t: 64800,
          o: 1.10007,
          h: 1.10012,
          l: 1.0996,
          c: 1.09983,
          v: 334
        },
        {
          t: 65100,
          o: 1.09984,
          h: 1.09984,
          l: 1.09918,
          c: 1.09945,
          v: 336
        },
        {
          t: 65400,
          o: 1.09947,
          h: 1.10001,
          l: 1.09936,
          c: 1.1,
          v: 378
        },
        {
          t: 65700,
          o: 1.09995,
          h: 1.09995,
          l: 1.099,
          c: 1.0993,
          v: 368
        },
        {
          t: 66000,
          o: 1.09928,
          h: 1.09958,
          l: 1.09925,
          c: 1.09954,
          v: 341
        },
        {
          t: 66300,
          o: 1.0995,
          h: 1.09964,
          l: 1.09944,
          c: 1.09964,
          v: 248
        },
        {
          t: 66600,
          o: 1.09963,
          h: 1.10009,
          l: 1.0996,
          c: 1.10003,
          v: 337
        },
        {
          t: 66900,
          o: 1.10002,
          h: 1.10009,
          l: 1.09954,
          c: 1.10001,
          v: 361
        },
        {
          t: 67200,
          o: 1.10001,
          h: 1.10043,
          l: 1.09995,
          c: 1.1004,
          v: 392
        },
        {
          t: 67500,
          o: 1.10039,
          h: 1.10053,
          l: 1.1,
          c: 1.10009,
          v: 447
        },
        {
          t: 67800,
          o: 1.1001,
          h: 1.10051,
          l: 1.10009,
          c: 1.10025,
          v: 316
        },
        {
          t: 68100,
          o: 1.10025,
          h: 1.10029,
          l: 1.09985,
          c: 1.1,
          v: 272
        },
        {
          t: 68400,
          o: 1.1,
          h: 1.1005,
          l: 1.09998,
          c: 1.10042,
          v: 349
        },
        {
          t: 68700,
          o: 1.10041,
          h: 1.10068,
          l: 1.10027,
          c: 1.10059,
          v: 379
        },
        {
          t: 69000,
          o: 1.10058,
          h: 1.10058,
          l: 1.0996,
          c: 1.09977,
          v: 479
        },
        {
          t: 69300,
          o: 1.09977,
          h: 1.09997,
          l: 1.09902,
          c: 1.09927,
          v: 354
        },
        {
          t: 69600,
          o: 1.09928,
          h: 1.09972,
          l: 1.09915,
          c: 1.09967,
          v: 284
        },
        {
          t: 69900,
          o: 1.09968,
          h: 1.10032,
          l: 1.09966,
          c: 1.10032,
          v: 244
        },
        {
          t: 70200,
          o: 1.10034,
          h: 1.10268,
          l: 1.09922,
          c: 1.10255,
          v: 819
        },
        {
          t: 70500,
          o: 1.10256,
          h: 1.10316,
          l: 1.10225,
          c: 1.10232,
          v: 608
        },
        {
          t: 70800,
          o: 1.10231,
          h: 1.10242,
          l: 1.10216,
          c: 1.10241,
          v: 225
        }
      ]
    }
  ],
  GBPUSD60: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 1.31753,
          h: 1.31788,
          l: 1.31751,
          c: 1.31774,
          v: 278
        },
        {
          t: 82800,
          o: 1.31776,
          h: 1.31836,
          l: 1.31768,
          c: 1.31804,
          v: 2342
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 1.31799,
          h: 1.31807,
          l: 1.31749,
          c: 1.31757,
          v: 1139
        },
        {
          t: 3600,
          o: 1.31758,
          h: 1.31819,
          l: 1.31723,
          c: 1.31757,
          v: 2562
        },
        {
          t: 7200,
          o: 1.31757,
          h: 1.31777,
          l: 1.31699,
          c: 1.31729,
          v: 2316
        },
        {
          t: 10800,
          o: 1.31734,
          h: 1.31736,
          l: 1.3155,
          c: 1.31576,
          v: 1985
        },
        {
          t: 14400,
          o: 1.31572,
          h: 1.3169,
          l: 1.31567,
          c: 1.31632,
          v: 1567
        },
        {
          t: 18000,
          o: 1.31631,
          h: 1.31668,
          l: 1.3161,
          c: 1.31627,
          v: 1606
        },
        {
          t: 21600,
          o: 1.31627,
          h: 1.31648,
          l: 1.31528,
          c: 1.31536,
          v: 1627
        },
        {
          t: 25200,
          o: 1.31536,
          h: 1.31565,
          l: 1.31397,
          c: 1.31424,
          v: 4047
        },
        {
          t: 28800,
          o: 1.31421,
          h: 1.31495,
          l: 1.31117,
          c: 1.3117,
          v: 4869
        },
        {
          t: 32400,
          o: 1.31169,
          h: 1.31187,
          l: 1.30793,
          c: 1.30806,
          v: 4843
        },
        {
          t: 36000,
          o: 1.30805,
          h: 1.30808,
          l: 1.3056,
          c: 1.30586,
          v: 5196
        },
        {
          t: 39600,
          o: 1.30587,
          h: 1.307,
          l: 1.30454,
          c: 1.30594,
          v: 4039
        },
        {
          t: 43200,
          o: 1.3059,
          h: 1.30635,
          l: 1.30523,
          c: 1.30553,
          v: 3485
        },
        {
          t: 46800,
          o: 1.30545,
          h: 1.30642,
          l: 1.30474,
          c: 1.30583,
          v: 3957
        },
        {
          t: 50400,
          o: 1.30585,
          h: 1.30595,
          l: 1.30218,
          c: 1.30261,
          v: 4921
        },
        {
          t: 54000,
          o: 1.30255,
          h: 1.30281,
          l: 1.30031,
          c: 1.30118,
          v: 5678
        },
        {
          t: 57600,
          o: 1.30115,
          h: 1.3016,
          l: 1.29998,
          c: 1.30048,
          v: 4043
        },
        {
          t: 61200,
          o: 1.30046,
          h: 1.30071,
          l: 1.29893,
          c: 1.30045,
          v: 3243
        },
        {
          t: 64800,
          o: 1.30044,
          h: 1.30099,
          l: 1.29823,
          c: 1.29935,
          v: 2235
        },
        {
          t: 68400,
          o: 1.29942,
          h: 1.30002,
          l: 1.2986,
          c: 1.29977,
          v: 2530
        },
        {
          t: 72000,
          o: 1.29976,
          h: 1.29989,
          l: 1.29864,
          c: 1.29909,
          v: 2104
        },
        {
          t: 75600,
          o: 1.29914,
          h: 1.30006,
          l: 1.29878,
          c: 1.29882,
          v: 1279
        },
        {
          t: 79200,
          o: 1.29928,
          h: 1.29958,
          l: 1.29858,
          c: 1.29954,
          v: 792
        },
        {
          t: 82800,
          o: 1.29954,
          h: 1.29983,
          l: 1.29924,
          c: 1.2996,
          v: 2591
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 1.2996,
          h: 1.29964,
          l: 1.29876,
          c: 1.29906,
          v: 1201
        },
        {
          t: 3600,
          o: 1.29906,
          h: 1.29917,
          l: 1.2981,
          c: 1.29898,
          v: 2221
        },
        {
          t: 7200,
          o: 1.29898,
          h: 1.29927,
          l: 1.29822,
          c: 1.29918,
          v: 2028
        },
        {
          t: 10800,
          o: 1.29922,
          h: 1.30017,
          l: 1.29912,
          c: 1.29987,
          v: 2387
        },
        {
          t: 14400,
          o: 1.29986,
          h: 1.30137,
          l: 1.2998,
          c: 1.30067,
          v: 2128
        },
        {
          t: 18000,
          o: 1.30065,
          h: 1.30226,
          l: 1.30052,
          c: 1.30128,
          v: 2004
        },
        {
          t: 21600,
          o: 1.30128,
          h: 1.30192,
          l: 1.29945,
          c: 1.29948,
          v: 2604
        },
        {
          t: 25200,
          o: 1.29944,
          h: 1.29955,
          l: 1.29404,
          c: 1.29445,
          v: 4454
        },
        {
          t: 28800,
          o: 1.29446,
          h: 1.29879,
          l: 1.29446,
          c: 1.29863,
          v: 4700
        },
        {
          t: 32400,
          o: 1.29854,
          h: 1.30245,
          l: 1.29799,
          c: 1.30154,
          v: 4829
        },
        {
          t: 36000,
          o: 1.30153,
          h: 1.30177,
          l: 1.30002,
          c: 1.30062,
          v: 3861
        },
        {
          t: 39600,
          o: 1.3006,
          h: 1.30156,
          l: 1.30035,
          c: 1.30124,
          v: 3516
        },
        {
          t: 43200,
          o: 1.30138,
          h: 1.30217,
          l: 1.3008,
          c: 1.30111,
          v: 2564
        },
        {
          t: 46800,
          o: 1.30096,
          h: 1.30459,
          l: 1.30094,
          c: 1.30287,
          v: 5222
        },
        {
          t: 50400,
          o: 1.30286,
          h: 1.30335,
          l: 1.30112,
          c: 1.30213,
          v: 4860
        },
        {
          t: 54000,
          o: 1.30212,
          h: 1.30359,
          l: 1.30193,
          c: 1.30248,
          v: 4500
        },
        {
          t: 57600,
          o: 1.30244,
          h: 1.30282,
          l: 1.30177,
          c: 1.30274,
          v: 3444
        },
        {
          t: 61200,
          o: 1.30273,
          h: 1.30346,
          l: 1.30266,
          c: 1.30311,
          v: 2553
        },
        {
          t: 64800,
          o: 1.30305,
          h: 1.30435,
          l: 1.30301,
          c: 1.30402,
          v: 2247
        },
        {
          t: 68400,
          o: 1.30402,
          h: 1.30412,
          l: 1.30359,
          c: 1.30363,
          v: 1690
        },
        {
          t: 72000,
          o: 1.30362,
          h: 1.30375,
          l: 1.303,
          c: 1.30329,
          v: 1944
        },
        {
          t: 75600,
          o: 1.30327,
          h: 1.30332,
          l: 1.30262,
          c: 1.30288,
          v: 1135
        },
        {
          t: 79200,
          o: 1.30288,
          h: 1.30381,
          l: 1.30236,
          c: 1.30264,
          v: 1803
        },
        {
          t: 82800,
          o: 1.3025,
          h: 1.30329,
          l: 1.30228,
          c: 1.30297,
          v: 1558
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 1.30299,
          h: 1.30326,
          l: 1.30258,
          c: 1.30277,
          v: 1921
        },
        {
          t: 3600,
          o: 1.30276,
          h: 1.30276,
          l: 1.30173,
          c: 1.30226,
          v: 2593
        },
        {
          t: 7200,
          o: 1.30226,
          h: 1.3023,
          l: 1.30115,
          c: 1.30164,
          v: 1841
        },
        {
          t: 10800,
          o: 1.3016,
          h: 1.30235,
          l: 1.30159,
          c: 1.30216,
          v: 1701
        },
        {
          t: 14400,
          o: 1.30216,
          h: 1.30228,
          l: 1.30175,
          c: 1.30176,
          v: 1282
        },
        {
          t: 18000,
          o: 1.30176,
          h: 1.3018,
          l: 1.30144,
          c: 1.3017,
          v: 1815
        },
        {
          t: 21600,
          o: 1.3017,
          h: 1.30207,
          l: 1.30146,
          c: 1.30167,
          v: 1308
        },
        {
          t: 25200,
          o: 1.30167,
          h: 1.30369,
          l: 1.30083,
          c: 1.30361,
          v: 3223
        },
        {
          t: 28800,
          o: 1.30364,
          h: 1.3052,
          l: 1.30336,
          c: 1.30465,
          v: 5266
        },
        {
          t: 32400,
          o: 1.30469,
          h: 1.30693,
          l: 1.30333,
          c: 1.30652,
          v: 5282
        },
        {
          t: 36000,
          o: 1.30652,
          h: 1.30652,
          l: 1.30465,
          c: 1.305,
          v: 3420
        },
        {
          t: 39600,
          o: 1.30504,
          h: 1.30667,
          l: 1.30467,
          c: 1.30597,
          v: 3606
        },
        {
          t: 43200,
          o: 1.30595,
          h: 1.30607,
          l: 1.30414,
          c: 1.30463,
          v: 3267
        },
        {
          t: 46800,
          o: 1.30463,
          h: 1.30538,
          l: 1.29908,
          c: 1.30085,
          v: 5127
        },
        {
          t: 50400,
          o: 1.30084,
          h: 1.30125,
          l: 1.29557,
          c: 1.29618,
          v: 6400
        },
        {
          t: 54000,
          o: 1.29628,
          h: 1.2995,
          l: 1.29572,
          c: 1.29832,
          v: 5370
        },
        {
          t: 57600,
          o: 1.2983,
          h: 1.30028,
          l: 1.29803,
          c: 1.29983,
          v: 3555
        },
        {
          t: 61200,
          o: 1.29983,
          h: 1.30097,
          l: 1.29976,
          c: 1.30036,
          v: 2615
        },
        {
          t: 64800,
          o: 1.30039,
          h: 1.30069,
          l: 1.29999,
          c: 1.30022,
          v: 1765
        },
        {
          t: 68400,
          o: 1.30021,
          h: 1.30023,
          l: 1.29863,
          c: 1.2987,
          v: 2001
        },
        {
          t: 72000,
          o: 1.29876,
          h: 1.29922,
          l: 1.29853,
          c: 1.29913,
          v: 1588
        },
        {
          t: 75600,
          o: 1.29908,
          h: 1.30014,
          l: 1.29905,
          c: 1.29988,
          v: 982
        },
        {
          t: 79200,
          o: 1.3,
          h: 1.30012,
          l: 1.29903,
          c: 1.29956,
          v: 1187
        },
        {
          t: 82800,
          o: 1.2996,
          h: 1.29993,
          l: 1.29928,
          c: 1.29942,
          v: 1716
        }
      ]
    },
    {
      d: 1580947200,
      b: [
        {
          t: 0,
          o: 1.29942,
          h: 1.29965,
          l: 1.29848,
          c: 1.29858,
          v: 1433
        },
        {
          t: 3600,
          o: 1.2986,
          h: 1.29899,
          l: 1.29821,
          c: 1.29878,
          v: 2181
        },
        {
          t: 7200,
          o: 1.29879,
          h: 1.29885,
          l: 1.2982,
          c: 1.29841,
          v: 1631
        },
        {
          t: 10800,
          o: 1.2984,
          h: 1.29901,
          l: 1.29823,
          c: 1.2985,
          v: 1644
        },
        {
          t: 14400,
          o: 1.29849,
          h: 1.29876,
          l: 1.2979,
          c: 1.29792,
          v: 1828
        },
        {
          t: 18000,
          o: 1.29796,
          h: 1.29841,
          l: 1.29712,
          c: 1.29839,
          v: 1880
        },
        {
          t: 21600,
          o: 1.2984,
          h: 1.29855,
          l: 1.29788,
          c: 1.29821,
          v: 2084
        },
        {
          t: 25200,
          o: 1.2982,
          h: 1.29958,
          l: 1.298,
          c: 1.29808,
          v: 3479
        },
        {
          t: 28800,
          o: 1.29809,
          h: 1.29919,
          l: 1.29759,
          c: 1.2985,
          v: 4250
        },
        {
          t: 32400,
          o: 1.29849,
          h: 1.2998,
          l: 1.2967,
          c: 1.29739,
          v: 3857
        },
        {
          t: 36000,
          o: 1.29739,
          h: 1.298,
          l: 1.29608,
          c: 1.29619,
          v: 3285
        },
        {
          t: 39600,
          o: 1.29619,
          h: 1.29681,
          l: 1.2952,
          c: 1.29622,
          v: 3460
        },
        {
          t: 43200,
          o: 1.29621,
          h: 1.29766,
          l: 1.29565,
          c: 1.29726,
          v: 2570
        },
        {
          t: 46800,
          o: 1.29726,
          h: 1.29783,
          l: 1.29477,
          c: 1.29654,
          v: 4214
        },
        {
          t: 50400,
          o: 1.29654,
          h: 1.29699,
          l: 1.29528,
          c: 1.29528,
          v: 4125
        },
        {
          t: 54000,
          o: 1.29527,
          h: 1.2957,
          l: 1.29215,
          c: 1.29273,
          v: 4788
        },
        {
          t: 57600,
          o: 1.29278,
          h: 1.29417,
          l: 1.2922,
          c: 1.29303,
          v: 3437
        },
        {
          t: 61200,
          o: 1.29303,
          h: 1.29355,
          l: 1.29274,
          c: 1.29331,
          v: 2392
        },
        {
          t: 64800,
          o: 1.2933,
          h: 1.29349,
          l: 1.29248,
          c: 1.29265,
          v: 1598
        },
        {
          t: 68400,
          o: 1.29264,
          h: 1.29297,
          l: 1.29202,
          c: 1.29224,
          v: 2057
        },
        {
          t: 72000,
          o: 1.29225,
          h: 1.29315,
          l: 1.2922,
          c: 1.29245,
          v: 2036
        },
        {
          t: 75600,
          o: 1.29247,
          h: 1.2928,
          l: 1.29229,
          c: 1.29233,
          v: 1389
        },
        {
          t: 79200,
          o: 1.29233,
          h: 1.29286,
          l: 1.2923,
          c: 1.29267,
          v: 849
        },
        {
          t: 82800,
          o: 1.29266,
          h: 1.29295,
          l: 1.29256,
          c: 1.29294,
          v: 944
        }
      ]
    },
    {
      d: 1581033600,
      b: [
        {
          t: 0,
          o: 1.29294,
          h: 1.29355,
          l: 1.29261,
          c: 1.29352,
          v: 978
        },
        {
          t: 3600,
          o: 1.29352,
          h: 1.2938,
          l: 1.29315,
          c: 1.29362,
          v: 2067
        },
        {
          t: 7200,
          o: 1.29361,
          h: 1.29428,
          l: 1.29346,
          c: 1.29412,
          v: 2013
        },
        {
          t: 10800,
          o: 1.29411,
          h: 1.29435,
          l: 1.29376,
          c: 1.29376,
          v: 1574
        },
        {
          t: 14400,
          o: 1.29376,
          h: 1.29394,
          l: 1.29345,
          c: 1.29369,
          v: 972
        },
        {
          t: 18000,
          o: 1.29372,
          h: 1.29417,
          l: 1.29356,
          c: 1.29399,
          v: 1460
        },
        {
          t: 21600,
          o: 1.294,
          h: 1.29454,
          l: 1.29367,
          c: 1.29417,
          v: 2468
        },
        {
          t: 25200,
          o: 1.29419,
          h: 1.29445,
          l: 1.29315,
          c: 1.29327,
          v: 4444
        },
        {
          t: 28800,
          o: 1.29325,
          h: 1.29368,
          l: 1.29206,
          c: 1.2925,
          v: 4799
        },
        {
          t: 32400,
          o: 1.29253,
          h: 1.29345,
          l: 1.29232,
          c: 1.29286,
          v: 3992
        },
        {
          t: 36000,
          o: 1.29284,
          h: 1.29439,
          l: 1.29244,
          c: 1.29284,
          v: 4221
        },
        {
          t: 39600,
          o: 1.29285,
          h: 1.29357,
          l: 1.29243,
          c: 1.29302,
          v: 3350
        },
        {
          t: 43200,
          o: 1.29303,
          h: 1.29458,
          l: 1.29293,
          c: 1.29458,
          v: 3865
        },
        {
          t: 46800,
          o: 1.29448,
          h: 1.29509,
          l: 1.29316,
          c: 1.29328,
          v: 5137
        },
        {
          t: 50400,
          o: 1.29328,
          h: 1.29585,
          l: 1.29212,
          c: 1.29508,
          v: 5035
        },
        {
          t: 54000,
          o: 1.29508,
          h: 1.29583,
          l: 1.29361,
          c: 1.29387,
          v: 5496
        },
        {
          t: 57600,
          o: 1.29384,
          h: 1.29396,
          l: 1.28983,
          c: 1.29051,
          v: 4847
        },
        {
          t: 61200,
          o: 1.29053,
          h: 1.29063,
          l: 1.2894,
          c: 1.28999,
          v: 2544
        },
        {
          t: 64800,
          o: 1.28996,
          h: 1.29012,
          l: 1.28909,
          c: 1.28924,
          v: 2243
        },
        {
          t: 68400,
          o: 1.28925,
          h: 1.28964,
          l: 1.2886,
          c: 1.28861,
          v: 1912
        },
        {
          t: 72000,
          o: 1.28866,
          h: 1.28917,
          l: 1.28816,
          c: 1.28831,
          v: 1854
        },
        {
          t: 75600,
          o: 1.28836,
          h: 1.28878,
          l: 1.28822,
          c: 1.28827,
          v: 1135
        }
      ]
    },
    {
      d: 1581206400,
      b: [
        {
          t: 79200,
          o: 1.28846,
          h: 1.28919,
          l: 1.28823,
          c: 1.28866,
          v: 473
        },
        {
          t: 82800,
          o: 1.28861,
          h: 1.28907,
          l: 1.28844,
          c: 1.28891,
          v: 2668
        }
      ]
    },
    {
      d: 1581292800,
      b: [
        {
          t: 0,
          o: 1.28888,
          h: 1.28961,
          l: 1.28868,
          c: 1.28928,
          v: 1858
        },
        {
          t: 3600,
          o: 1.28927,
          h: 1.28967,
          l: 1.28906,
          c: 1.28929,
          v: 2221
        },
        {
          t: 7200,
          o: 1.28928,
          h: 1.29002,
          l: 1.28926,
          c: 1.28996,
          v: 1874
        },
        {
          t: 10800,
          o: 1.28997,
          h: 1.29021,
          l: 1.28927,
          c: 1.28951,
          v: 1403
        },
        {
          t: 14400,
          o: 1.28955,
          h: 1.29047,
          l: 1.28917,
          c: 1.29046,
          v: 1195
        },
        {
          t: 18000,
          o: 1.29046,
          h: 1.29079,
          l: 1.29026,
          c: 1.29067,
          v: 1309
        },
        {
          t: 21600,
          o: 1.29067,
          h: 1.29068,
          l: 1.28996,
          c: 1.29006,
          v: 1919
        },
        {
          t: 25200,
          o: 1.29006,
          h: 1.29006,
          l: 1.28713,
          c: 1.28737,
          v: 3982
        },
        {
          t: 28800,
          o: 1.28734,
          h: 1.28968,
          l: 1.28721,
          c: 1.28918,
          v: 4618
        },
        {
          t: 32400,
          o: 1.28917,
          h: 1.29141,
          l: 1.28876,
          c: 1.29105,
          v: 4858
        },
        {
          t: 36000,
          o: 1.29103,
          h: 1.29314,
          l: 1.29075,
          c: 1.29224,
          v: 4508
        },
        {
          t: 39600,
          o: 1.29225,
          h: 1.29358,
          l: 1.29189,
          c: 1.2923,
          v: 3717
        },
        {
          t: 43200,
          o: 1.2923,
          h: 1.29358,
          l: 1.29143,
          c: 1.29316,
          v: 3753
        },
        {
          t: 46800,
          o: 1.29315,
          h: 1.29453,
          l: 1.29233,
          c: 1.29284,
          v: 4593
        },
        {
          t: 50400,
          o: 1.29282,
          h: 1.29436,
          l: 1.29239,
          c: 1.29395,
          v: 4199
        },
        {
          t: 54000,
          o: 1.29393,
          h: 1.29426,
          l: 1.292,
          c: 1.29229,
          v: 4733
        },
        {
          t: 57600,
          o: 1.29228,
          h: 1.29247,
          l: 1.29095,
          c: 1.29176,
          v: 3861
        },
        {
          t: 61200,
          o: 1.29176,
          h: 1.29191,
          l: 1.29064,
          c: 1.29087,
          v: 2364
        },
        {
          t: 64800,
          o: 1.29089,
          h: 1.29158,
          l: 1.29072,
          c: 1.29157,
          v: 1949
        },
        {
          t: 68400,
          o: 1.29158,
          h: 1.29199,
          l: 1.29145,
          c: 1.29151,
          v: 2133
        },
        {
          t: 72000,
          o: 1.29152,
          h: 1.29156,
          l: 1.29074,
          c: 1.29096,
          v: 2184
        },
        {
          t: 75600,
          o: 1.29098,
          h: 1.29139,
          l: 1.29079,
          c: 1.29136,
          v: 1395
        },
        {
          t: 79200,
          o: 1.29124,
          h: 1.29154,
          l: 1.29041,
          c: 1.2912,
          v: 394
        },
        {
          t: 82800,
          o: 1.2912,
          h: 1.29158,
          l: 1.29108,
          c: 1.29151,
          v: 2343
        }
      ]
    },
    {
      d: 1581379200,
      b: [
        {
          t: 0,
          o: 1.29152,
          h: 1.2916,
          l: 1.29099,
          c: 1.2912,
          v: 1824
        },
        {
          t: 3600,
          o: 1.29118,
          h: 1.29208,
          l: 1.29118,
          c: 1.2915,
          v: 1928
        },
        {
          t: 7200,
          o: 1.29152,
          h: 1.29187,
          l: 1.29102,
          c: 1.29169,
          v: 1443
        },
        {
          t: 10800,
          o: 1.29169,
          h: 1.29195,
          l: 1.29149,
          c: 1.29169,
          v: 1214
        },
        {
          t: 14400,
          o: 1.29168,
          h: 1.29169,
          l: 1.29107,
          c: 1.29124,
          v: 838
        },
        {
          t: 18000,
          o: 1.29124,
          h: 1.29162,
          l: 1.29107,
          c: 1.29132,
          v: 1241
        },
        {
          t: 21600,
          o: 1.29132,
          h: 1.2917,
          l: 1.29106,
          c: 1.29156,
          v: 1963
        },
        {
          t: 25200,
          o: 1.29159,
          h: 1.29163,
          l: 1.28962,
          c: 1.29057,
          v: 3776
        },
        {
          t: 28800,
          o: 1.29057,
          h: 1.29121,
          l: 1.28938,
          c: 1.29023,
          v: 4344
        },
        {
          t: 32400,
          o: 1.29023,
          h: 1.29416,
          l: 1.29002,
          c: 1.29376,
          v: 4983
        },
        {
          t: 36000,
          o: 1.29374,
          h: 1.29389,
          l: 1.29179,
          c: 1.2926,
          v: 3824
        },
        {
          t: 39600,
          o: 1.29259,
          h: 1.29303,
          l: 1.29149,
          c: 1.29292,
          v: 3624
        },
        {
          t: 43200,
          o: 1.29292,
          h: 1.29361,
          l: 1.29211,
          c: 1.29318,
          v: 3323
        },
        {
          t: 46800,
          o: 1.29321,
          h: 1.29408,
          l: 1.29256,
          c: 1.29367,
          v: 4946
        },
        {
          t: 50400,
          o: 1.29364,
          h: 1.29672,
          l: 1.29344,
          c: 1.29639,
          v: 4931
        },
        {
          t: 54000,
          o: 1.29637,
          h: 1.29657,
          l: 1.29412,
          c: 1.29443,
          v: 5716
        },
        {
          t: 57600,
          o: 1.29444,
          h: 1.29496,
          l: 1.29336,
          c: 1.29433,
          v: 3442
        },
        {
          t: 61200,
          o: 1.29434,
          h: 1.29522,
          l: 1.29318,
          c: 1.29497,
          v: 2598
        },
        {
          t: 64800,
          o: 1.29498,
          h: 1.29599,
          l: 1.29458,
          c: 1.29558,
          v: 2576
        },
        {
          t: 68400,
          o: 1.29557,
          h: 1.2966,
          l: 1.29536,
          c: 1.29625,
          v: 2634
        },
        {
          t: 72000,
          o: 1.29627,
          h: 1.29627,
          l: 1.29506,
          c: 1.29573,
          v: 1617
        },
        {
          t: 75600,
          o: 1.29572,
          h: 1.29572,
          l: 1.29463,
          c: 1.29463,
          v: 989
        },
        {
          t: 79200,
          o: 1.29507,
          h: 1.29558,
          l: 1.29443,
          c: 1.29533,
          v: 485
        },
        {
          t: 82800,
          o: 1.29531,
          h: 1.29559,
          l: 1.29525,
          c: 1.29553,
          v: 936
        }
      ]
    },
    {
      d: 1581465600,
      b: [
        {
          t: 0,
          o: 1.29553,
          h: 1.29574,
          l: 1.29516,
          c: 1.29529,
          v: 1642
        },
        {
          t: 3600,
          o: 1.29532,
          h: 1.29655,
          l: 1.29527,
          c: 1.29644,
          v: 2163
        },
        {
          t: 7200,
          o: 1.29643,
          h: 1.29668,
          l: 1.29601,
          c: 1.29662,
          v: 1834
        },
        {
          t: 10800,
          o: 1.29662,
          h: 1.29686,
          l: 1.29587,
          c: 1.2959,
          v: 1592
        },
        {
          t: 14400,
          o: 1.29588,
          h: 1.29607,
          l: 1.29571,
          c: 1.29581,
          v: 1677
        },
        {
          t: 18000,
          o: 1.2958,
          h: 1.29649,
          l: 1.29579,
          c: 1.29611,
          v: 1479
        },
        {
          t: 21600,
          o: 1.2961,
          h: 1.2961,
          l: 1.29515,
          c: 1.29537,
          v: 2236
        },
        {
          t: 25200,
          o: 1.29539,
          h: 1.29692,
          l: 1.2951,
          c: 1.29645,
          v: 3616
        },
        {
          t: 28800,
          o: 1.29645,
          h: 1.2974,
          l: 1.29582,
          c: 1.29653,
          v: 4505
        },
        {
          t: 32400,
          o: 1.29653,
          h: 1.29828,
          l: 1.29617,
          c: 1.29798,
          v: 4057
        },
        {
          t: 36000,
          o: 1.29799,
          h: 1.29884,
          l: 1.29763,
          c: 1.29797,
          v: 3873
        },
        {
          t: 39600,
          o: 1.29797,
          h: 1.2988,
          l: 1.29763,
          c: 1.29833,
          v: 3497
        },
        {
          t: 43200,
          o: 1.29834,
          h: 1.29865,
          l: 1.29689,
          c: 1.29845,
          v: 3316
        },
        {
          t: 46800,
          o: 1.29843,
          h: 1.29907,
          l: 1.29613,
          c: 1.2965,
          v: 3733
        },
        {
          t: 50400,
          o: 1.2965,
          h: 1.29701,
          l: 1.2953,
          c: 1.29601,
          v: 3882
        },
        {
          t: 54000,
          o: 1.296,
          h: 1.29768,
          l: 1.29556,
          c: 1.2974,
          v: 4911
        },
        {
          t: 57600,
          o: 1.29739,
          h: 1.29787,
          l: 1.29705,
          c: 1.29763,
          v: 3359
        },
        {
          t: 61200,
          o: 1.29762,
          h: 1.29782,
          l: 1.2964,
          c: 1.29651,
          v: 2572
        },
        {
          t: 64800,
          o: 1.2965,
          h: 1.29684,
          l: 1.29625,
          c: 1.2963,
          v: 1719
        },
        {
          t: 68400,
          o: 1.29629,
          h: 1.29669,
          l: 1.29578,
          c: 1.29583,
          v: 2459
        },
        {
          t: 72000,
          o: 1.29584,
          h: 1.29615,
          l: 1.29562,
          c: 1.29576,
          v: 2187
        },
        {
          t: 75600,
          o: 1.29577,
          h: 1.2959,
          l: 1.29551,
          c: 1.29572,
          v: 1076
        },
        {
          t: 79200,
          o: 1.29571,
          h: 1.29639,
          l: 1.29511,
          c: 1.29556,
          v: 1045
        },
        {
          t: 82800,
          o: 1.2955,
          h: 1.29574,
          l: 1.29513,
          c: 1.29568,
          v: 2325
        }
      ]
    },
    {
      d: 1581552000,
      b: [
        {
          t: 0,
          o: 1.29568,
          h: 1.29639,
          l: 1.29522,
          c: 1.29531,
          v: 2911
        },
        {
          t: 3600,
          o: 1.29529,
          h: 1.29603,
          l: 1.29528,
          c: 1.29577,
          v: 2508
        },
        {
          t: 7200,
          o: 1.29577,
          h: 1.29602,
          l: 1.2954,
          c: 1.29579,
          v: 1286
        },
        {
          t: 10800,
          o: 1.29575,
          h: 1.29591,
          l: 1.29518,
          c: 1.2953,
          v: 2018
        },
        {
          t: 14400,
          o: 1.2953,
          h: 1.29545,
          l: 1.29479,
          c: 1.29509,
          v: 1578
        },
        {
          t: 18000,
          o: 1.29508,
          h: 1.29521,
          l: 1.29436,
          c: 1.29502,
          v: 2398
        },
        {
          t: 21600,
          o: 1.29508,
          h: 1.2953,
          l: 1.2945,
          c: 1.29505,
          v: 1801
        },
        {
          t: 25200,
          o: 1.29506,
          h: 1.29779,
          l: 1.29493,
          c: 1.29777,
          v: 4310
        },
        {
          t: 28800,
          o: 1.29775,
          h: 1.29833,
          l: 1.29607,
          c: 1.29617,
          v: 4710
        },
        {
          t: 32400,
          o: 1.29615,
          h: 1.29745,
          l: 1.29548,
          c: 1.29704,
          v: 3872
        },
        {
          t: 36000,
          o: 1.29712,
          h: 1.29885,
          l: 1.29685,
          c: 1.29878,
          v: 3902
        },
        {
          t: 39600,
          o: 1.29877,
          h: 1.29879,
          l: 1.29614,
          c: 1.29832,
          v: 4957
        },
        {
          t: 43200,
          o: 1.29831,
          h: 1.30445,
          l: 1.29791,
          c: 1.30309,
          v: 6201
        },
        {
          t: 46800,
          o: 1.30311,
          h: 1.30428,
          l: 1.30208,
          c: 1.30231,
          v: 4626
        },
        {
          t: 50400,
          o: 1.30234,
          h: 1.3048,
          l: 1.30127,
          c: 1.30428,
          v: 4561
        },
        {
          t: 54000,
          o: 1.30427,
          h: 1.30687,
          l: 1.30411,
          c: 1.30544,
          v: 5744
        },
        {
          t: 57600,
          o: 1.30544,
          h: 1.30624,
          l: 1.30424,
          c: 1.30485,
          v: 3684
        },
        {
          t: 61200,
          o: 1.30485,
          h: 1.30571,
          l: 1.30446,
          c: 1.30555,
          v: 2368
        },
        {
          t: 64800,
          o: 1.30555,
          h: 1.30606,
          l: 1.30482,
          c: 1.30512,
          v: 1965
        },
        {
          t: 68400,
          o: 1.30512,
          h: 1.30547,
          l: 1.30487,
          c: 1.30533,
          v: 1540
        },
        {
          t: 72000,
          o: 1.30533,
          h: 1.30533,
          l: 1.30443,
          c: 1.30478,
          v: 1709
        },
        {
          t: 75600,
          o: 1.30475,
          h: 1.30479,
          l: 1.30387,
          c: 1.30408,
          v: 1379
        },
        {
          t: 79200,
          o: 1.30367,
          h: 1.30455,
          l: 1.30324,
          c: 1.30401,
          v: 1848
        },
        {
          t: 82800,
          o: 1.30401,
          h: 1.30469,
          l: 1.30386,
          c: 1.30446,
          v: 1570
        }
      ]
    },
    {
      d: 1581638400,
      b: [
        {
          t: 0,
          o: 1.30446,
          h: 1.30478,
          l: 1.30395,
          c: 1.3045,
          v: 2028
        },
        {
          t: 3600,
          o: 1.3045,
          h: 1.30487,
          l: 1.30418,
          c: 1.3046,
          v: 2051
        },
        {
          t: 7200,
          o: 1.30459,
          h: 1.30486,
          l: 1.30424,
          c: 1.30478,
          v: 1393
        },
        {
          t: 10800,
          o: 1.30476,
          h: 1.30478,
          l: 1.30394,
          c: 1.3041,
          v: 1607
        },
        {
          t: 14400,
          o: 1.30407,
          h: 1.30445,
          l: 1.30401,
          c: 1.30421,
          v: 1084
        },
        {
          t: 18000,
          o: 1.30423,
          h: 1.30446,
          l: 1.30396,
          c: 1.30446,
          v: 1614
        },
        {
          t: 21600,
          o: 1.30445,
          h: 1.3055,
          l: 1.30427,
          c: 1.30443,
          v: 1717
        },
        {
          t: 25200,
          o: 1.30448,
          h: 1.30598,
          l: 1.30422,
          c: 1.30565,
          v: 3548
        },
        {
          t: 28800,
          o: 1.30565,
          h: 1.30626,
          l: 1.30298,
          c: 1.30401,
          v: 4162
        },
        {
          t: 32400,
          o: 1.30399,
          h: 1.30423,
          l: 1.30186,
          c: 1.30339,
          v: 3918
        },
        {
          t: 36000,
          o: 1.30337,
          h: 1.30402,
          l: 1.3021,
          c: 1.30295,
          v: 3436
        },
        {
          t: 39600,
          o: 1.30294,
          h: 1.30407,
          l: 1.30254,
          c: 1.303,
          v: 3494
        },
        {
          t: 43200,
          o: 1.30298,
          h: 1.30348,
          l: 1.30206,
          c: 1.30276,
          v: 3179
        },
        {
          t: 46800,
          o: 1.30293,
          h: 1.30308,
          l: 1.30005,
          c: 1.3014,
          v: 5051
        },
        {
          t: 50400,
          o: 1.3014,
          h: 1.30253,
          l: 1.30113,
          c: 1.30212,
          v: 4188
        },
        {
          t: 54000,
          o: 1.30215,
          h: 1.30244,
          l: 1.30104,
          c: 1.30149,
          v: 4186
        },
        {
          t: 57600,
          o: 1.30151,
          h: 1.30381,
          l: 1.30095,
          c: 1.30364,
          v: 3584
        },
        {
          t: 61200,
          o: 1.30365,
          h: 1.30406,
          l: 1.30323,
          c: 1.30397,
          v: 2266
        },
        {
          t: 64800,
          o: 1.30396,
          h: 1.30475,
          l: 1.30366,
          c: 1.30383,
          v: 1619
        },
        {
          t: 68400,
          o: 1.30382,
          h: 1.30406,
          l: 1.3029,
          c: 1.30314,
          v: 1943
        },
        {
          t: 72000,
          o: 1.30313,
          h: 1.30476,
          l: 1.303,
          c: 1.30476,
          v: 1686
        },
        {
          t: 75600,
          o: 1.30474,
          h: 1.30524,
          l: 1.30458,
          c: 1.30505,
          v: 877
        }
      ]
    },
    {
      d: 1581811200,
      b: [
        {
          t: 79200,
          o: 1.30334,
          h: 1.30429,
          l: 1.3027,
          c: 1.30412,
          v: 565
        },
        {
          t: 82800,
          o: 1.30413,
          h: 1.30531,
          l: 1.30404,
          c: 1.30507,
          v: 1390
        }
      ]
    },
    {
      d: 1581897600,
      b: [
        {
          t: 0,
          o: 1.30506,
          h: 1.30507,
          l: 1.30399,
          c: 1.30413,
          v: 2294
        },
        {
          t: 3600,
          o: 1.30414,
          h: 1.30467,
          l: 1.30406,
          c: 1.30443,
          v: 1491
        },
        {
          t: 7200,
          o: 1.30442,
          h: 1.30491,
          l: 1.3043,
          c: 1.30465,
          v: 2149
        },
        {
          t: 10800,
          o: 1.30466,
          h: 1.30467,
          l: 1.30439,
          c: 1.30447,
          v: 709
        },
        {
          t: 14400,
          o: 1.30447,
          h: 1.3047,
          l: 1.3042,
          c: 1.30424,
          v: 1404
        },
        {
          t: 18000,
          o: 1.30423,
          h: 1.305,
          l: 1.30418,
          c: 1.30477,
          v: 1073
        },
        {
          t: 21600,
          o: 1.30476,
          h: 1.3049,
          l: 1.30374,
          c: 1.30387,
          v: 1235
        },
        {
          t: 25200,
          o: 1.30387,
          h: 1.3042,
          l: 1.30283,
          c: 1.30359,
          v: 3131
        },
        {
          t: 28800,
          o: 1.30364,
          h: 1.30448,
          l: 1.30317,
          c: 1.30405,
          v: 3662
        },
        {
          t: 32400,
          o: 1.30404,
          h: 1.30507,
          l: 1.30344,
          c: 1.30392,
          v: 3732
        },
        {
          t: 36000,
          o: 1.30392,
          h: 1.30438,
          l: 1.30265,
          c: 1.3032,
          v: 3094
        },
        {
          t: 39600,
          o: 1.30319,
          h: 1.3033,
          l: 1.30093,
          c: 1.30157,
          v: 3545
        },
        {
          t: 43200,
          o: 1.30158,
          h: 1.30194,
          l: 1.30101,
          c: 1.3013,
          v: 3350
        },
        {
          t: 46800,
          o: 1.30128,
          h: 1.30186,
          l: 1.30117,
          c: 1.30147,
          v: 2697
        },
        {
          t: 50400,
          o: 1.30149,
          h: 1.3015,
          l: 1.30023,
          c: 1.30067,
          v: 3167
        },
        {
          t: 54000,
          o: 1.30066,
          h: 1.30141,
          l: 1.30055,
          c: 1.30058,
          v: 2770
        },
        {
          t: 57600,
          o: 1.30059,
          h: 1.30142,
          l: 1.30017,
          c: 1.30096,
          v: 1503
        },
        {
          t: 61200,
          o: 1.30096,
          h: 1.30134,
          l: 1.30078,
          c: 1.30104,
          v: 1072
        },
        {
          t: 64800,
          o: 1.30107,
          h: 1.30139,
          l: 1.30014,
          c: 1.30022,
          v: 3706
        },
        {
          t: 68400,
          o: 1.3002,
          h: 1.30039,
          l: 1.2997,
          c: 1.29996,
          v: 2240
        },
        {
          t: 72000,
          o: 1.29994,
          h: 1.30021,
          l: 1.29978,
          c: 1.30006,
          v: 1840
        },
        {
          t: 75600,
          o: 1.30007,
          h: 1.30036,
          l: 1.29996,
          c: 1.30035,
          v: 2170
        },
        {
          t: 79200,
          o: 1.30034,
          h: 1.30046,
          l: 1.2999,
          c: 1.30028,
          v: 361
        },
        {
          t: 82800,
          o: 1.30021,
          h: 1.30065,
          l: 1.30019,
          c: 1.30049,
          v: 1322
        }
      ]
    },
    {
      d: 1581984000,
      b: [
        {
          t: 0,
          o: 1.30049,
          h: 1.30053,
          l: 1.29929,
          c: 1.29949,
          v: 2118
        },
        {
          t: 3600,
          o: 1.29946,
          h: 1.29984,
          l: 1.29911,
          c: 1.29973,
          v: 2674
        },
        {
          t: 7200,
          o: 1.29973,
          h: 1.29991,
          l: 1.2992,
          c: 1.29981,
          v: 1662
        },
        {
          t: 10800,
          o: 1.2998,
          h: 1.30047,
          l: 1.29962,
          c: 1.30023,
          v: 1324
        },
        {
          t: 14400,
          o: 1.30023,
          h: 1.30038,
          l: 1.29944,
          c: 1.29945,
          v: 1319
        },
        {
          t: 18000,
          o: 1.29946,
          h: 1.29995,
          l: 1.29934,
          c: 1.2995,
          v: 1224
        },
        {
          t: 21600,
          o: 1.2995,
          h: 1.30024,
          l: 1.29851,
          c: 1.29858,
          v: 1883
        },
        {
          t: 25200,
          o: 1.29859,
          h: 1.30014,
          l: 1.29852,
          c: 1.29978,
          v: 3837
        },
        {
          t: 28800,
          o: 1.29972,
          h: 1.29978,
          l: 1.297,
          c: 1.29774,
          v: 4762
        },
        {
          t: 32400,
          o: 1.29773,
          h: 1.30031,
          l: 1.29755,
          c: 1.29949,
          v: 4582
        },
        {
          t: 36000,
          o: 1.2995,
          h: 1.30377,
          l: 1.29927,
          c: 1.30283,
          v: 5556
        },
        {
          t: 39600,
          o: 1.30283,
          h: 1.30481,
          l: 1.30274,
          c: 1.304,
          v: 4138
        },
        {
          t: 43200,
          o: 1.30404,
          h: 1.30426,
          l: 1.30313,
          c: 1.30316,
          v: 3836
        },
        {
          t: 46800,
          o: 1.30313,
          h: 1.30359,
          l: 1.30195,
          c: 1.30255,
          v: 4475
        },
        {
          t: 50400,
          o: 1.30255,
          h: 1.3031,
          l: 1.3016,
          c: 1.3025,
          v: 4246
        },
        {
          t: 54000,
          o: 1.30251,
          h: 1.30414,
          l: 1.30165,
          c: 1.30355,
          v: 4049
        },
        {
          t: 57600,
          o: 1.30355,
          h: 1.30369,
          l: 1.30119,
          c: 1.30162,
          v: 3732
        },
        {
          t: 61200,
          o: 1.30162,
          h: 1.30162,
          l: 1.29993,
          c: 1.30032,
          v: 3583
        },
        {
          t: 64800,
          o: 1.30032,
          h: 1.30033,
          l: 1.29944,
          c: 1.29963,
          v: 2301
        },
        {
          t: 68400,
          o: 1.2996,
          h: 1.3001,
          l: 1.29948,
          c: 1.29983,
          v: 2160
        },
        {
          t: 72000,
          o: 1.29983,
          h: 1.30002,
          l: 1.2995,
          c: 1.29992,
          v: 1579
        },
        {
          t: 75600,
          o: 1.29989,
          h: 1.30014,
          l: 1.29949,
          c: 1.29954,
          v: 1200
        },
        {
          t: 79200,
          o: 1.29954,
          h: 1.29995,
          l: 1.29946,
          c: 1.29978,
          v: 660
        },
        {
          t: 82800,
          o: 1.29983,
          h: 1.30042,
          l: 1.29963,
          c: 1.29986,
          v: 2065
        }
      ]
    },
    {
      d: 1582070400,
      b: [
        {
          t: 0,
          o: 1.29986,
          h: 1.3001,
          l: 1.29963,
          c: 1.29996,
          v: 1959
        },
        {
          t: 3600,
          o: 1.29995,
          h: 1.30026,
          l: 1.29977,
          c: 1.30003,
          v: 2245
        },
        {
          t: 7200,
          o: 1.30003,
          h: 1.30016,
          l: 1.2995,
          c: 1.29964,
          v: 2388
        },
        {
          t: 10800,
          o: 1.29967,
          h: 1.29992,
          l: 1.29954,
          c: 1.29975,
          v: 968
        },
        {
          t: 14400,
          o: 1.29974,
          h: 1.30006,
          l: 1.29971,
          c: 1.30003,
          v: 725
        },
        {
          t: 18000,
          o: 1.30003,
          h: 1.30015,
          l: 1.29953,
          c: 1.29985,
          v: 1119
        },
        {
          t: 21600,
          o: 1.29984,
          h: 1.29984,
          l: 1.29866,
          c: 1.29939,
          v: 1546
        },
        {
          t: 25200,
          o: 1.29937,
          h: 1.30109,
          l: 1.29895,
          c: 1.30057,
          v: 3340
        },
        {
          t: 28800,
          o: 1.30054,
          h: 1.30175,
          l: 1.29921,
          c: 1.29934,
          v: 3708
        },
        {
          t: 32400,
          o: 1.29935,
          h: 1.30225,
          l: 1.29739,
          c: 1.30061,
          v: 5219
        },
        {
          t: 36000,
          o: 1.30055,
          h: 1.30089,
          l: 1.29727,
          c: 1.2981,
          v: 3642
        },
        {
          t: 39600,
          o: 1.29809,
          h: 1.29883,
          l: 1.29743,
          c: 1.29865,
          v: 3401
        },
        {
          t: 43200,
          o: 1.29866,
          h: 1.29984,
          l: 1.29811,
          c: 1.29902,
          v: 3603
        },
        {
          t: 46800,
          o: 1.29906,
          h: 1.29911,
          l: 1.29725,
          c: 1.29772,
          v: 4459
        },
        {
          t: 50400,
          o: 1.29773,
          h: 1.29786,
          l: 1.29483,
          c: 1.2958,
          v: 4647
        },
        {
          t: 54000,
          o: 1.29583,
          h: 1.29608,
          l: 1.29225,
          c: 1.29507,
          v: 4765
        },
        {
          t: 57600,
          o: 1.29498,
          h: 1.29533,
          l: 1.29306,
          c: 1.29325,
          v: 4295
        },
        {
          t: 61200,
          o: 1.29324,
          h: 1.29344,
          l: 1.29173,
          c: 1.29173,
          v: 2961
        },
        {
          t: 64800,
          o: 1.29173,
          h: 1.29209,
          l: 1.29137,
          c: 1.29155,
          v: 2661
        },
        {
          t: 68400,
          o: 1.29174,
          h: 1.29202,
          l: 1.2907,
          c: 1.29187,
          v: 3268
        },
        {
          t: 72000,
          o: 1.29188,
          h: 1.29288,
          l: 1.29184,
          c: 1.29225,
          v: 3069
        },
        {
          t: 75600,
          o: 1.29226,
          h: 1.29275,
          l: 1.29163,
          c: 1.29174,
          v: 1598
        },
        {
          t: 79200,
          o: 1.2917,
          h: 1.29238,
          l: 1.29102,
          c: 1.29184,
          v: 443
        },
        {
          t: 82800,
          o: 1.29186,
          h: 1.29257,
          l: 1.29179,
          c: 1.29249,
          v: 1146
        }
      ]
    },
    {
      d: 1582156800,
      b: [
        {
          t: 0,
          o: 1.29248,
          h: 1.29276,
          l: 1.29174,
          c: 1.29264,
          v: 2486
        },
        {
          t: 3600,
          o: 1.29263,
          h: 1.29268,
          l: 1.29105,
          c: 1.29142,
          v: 2499
        },
        {
          t: 7200,
          o: 1.29141,
          h: 1.29174,
          l: 1.2904,
          c: 1.29044,
          v: 2624
        },
        {
          t: 10800,
          o: 1.2904,
          h: 1.29079,
          l: 1.29003,
          c: 1.29073,
          v: 2304
        },
        {
          t: 14400,
          o: 1.29073,
          h: 1.29117,
          l: 1.29042,
          c: 1.29114,
          v: 2063
        },
        {
          t: 18000,
          o: 1.29115,
          h: 1.29139,
          l: 1.2909,
          c: 1.291,
          v: 1447
        },
        {
          t: 21600,
          o: 1.29101,
          h: 1.29104,
          l: 1.28994,
          c: 1.29027,
          v: 1769
        },
        {
          t: 25200,
          o: 1.29027,
          h: 1.29067,
          l: 1.28845,
          c: 1.28981,
          v: 4413
        },
        {
          t: 28800,
          o: 1.28985,
          h: 1.29019,
          l: 1.28825,
          c: 1.29003,
          v: 4182
        },
        {
          t: 32400,
          o: 1.28997,
          h: 1.29246,
          l: 1.28993,
          c: 1.29014,
          v: 4761
        },
        {
          t: 36000,
          o: 1.29014,
          h: 1.29066,
          l: 1.28734,
          c: 1.2879,
          v: 4506
        },
        {
          t: 39600,
          o: 1.28789,
          h: 1.28844,
          l: 1.28483,
          c: 1.28699,
          v: 4631
        },
        {
          t: 43200,
          o: 1.28691,
          h: 1.28742,
          l: 1.28548,
          c: 1.28645,
          v: 3996
        },
        {
          t: 46800,
          o: 1.2864,
          h: 1.2881,
          l: 1.28485,
          c: 1.28575,
          v: 4283
        },
        {
          t: 50400,
          o: 1.28577,
          h: 1.28748,
          l: 1.28534,
          c: 1.28688,
          v: 3939
        },
        {
          t: 54000,
          o: 1.28691,
          h: 1.28833,
          l: 1.28621,
          c: 1.28795,
          v: 3786
        },
        {
          t: 57600,
          o: 1.28799,
          h: 1.2888,
          l: 1.28705,
          c: 1.28759,
          v: 4214
        },
        {
          t: 61200,
          o: 1.28759,
          h: 1.28872,
          l: 1.28753,
          c: 1.28766,
          v: 2906
        },
        {
          t: 64800,
          o: 1.28767,
          h: 1.28817,
          l: 1.28741,
          c: 1.28792,
          v: 1983
        },
        {
          t: 68400,
          o: 1.28799,
          h: 1.28877,
          l: 1.28778,
          c: 1.28797,
          v: 2109
        },
        {
          t: 72000,
          o: 1.28797,
          h: 1.28817,
          l: 1.28755,
          c: 1.2881,
          v: 1537
        },
        {
          t: 75600,
          o: 1.28804,
          h: 1.28822,
          l: 1.28748,
          c: 1.28799,
          v: 1010
        },
        {
          t: 79200,
          o: 1.28795,
          h: 1.28853,
          l: 1.28731,
          c: 1.28776,
          v: 608
        },
        {
          t: 82800,
          o: 1.28773,
          h: 1.28855,
          l: 1.28768,
          c: 1.28801,
          v: 1257
        }
      ]
    },
    {
      d: 1582243200,
      b: [
        {
          t: 0,
          o: 1.28801,
          h: 1.28857,
          l: 1.28781,
          c: 1.28809,
          v: 1775
        },
        {
          t: 3600,
          o: 1.28808,
          h: 1.28872,
          l: 1.28799,
          c: 1.28863,
          v: 2124
        },
        {
          t: 7200,
          o: 1.28863,
          h: 1.28939,
          l: 1.28846,
          c: 1.28876,
          v: 2316
        },
        {
          t: 10800,
          o: 1.28877,
          h: 1.28933,
          l: 1.28877,
          c: 1.28933,
          v: 1838
        },
        {
          t: 14400,
          o: 1.28929,
          h: 1.28932,
          l: 1.2888,
          c: 1.28926,
          v: 1277
        },
        {
          t: 18000,
          o: 1.28925,
          h: 1.28981,
          l: 1.28925,
          c: 1.28943,
          v: 1620
        },
        {
          t: 21600,
          o: 1.28943,
          h: 1.28955,
          l: 1.28891,
          c: 1.28923,
          v: 1601
        },
        {
          t: 25200,
          o: 1.28926,
          h: 1.29022,
          l: 1.28857,
          c: 1.28883,
          v: 3536
        },
        {
          t: 28800,
          o: 1.28883,
          h: 1.29168,
          l: 1.2884,
          c: 1.29116,
          v: 5569
        },
        {
          t: 32400,
          o: 1.29117,
          h: 1.29266,
          l: 1.29117,
          c: 1.29156,
          v: 4748
        },
        {
          t: 36000,
          o: 1.29156,
          h: 1.29228,
          l: 1.29073,
          c: 1.2909,
          v: 3702
        },
        {
          t: 39600,
          o: 1.29091,
          h: 1.29506,
          l: 1.29075,
          c: 1.29462,
          v: 4516
        },
        {
          t: 43200,
          o: 1.29466,
          h: 1.29467,
          l: 1.29241,
          c: 1.29267,
          v: 4059
        },
        {
          t: 46800,
          o: 1.29277,
          h: 1.29384,
          l: 1.29247,
          c: 1.29345,
          v: 4054
        },
        {
          t: 50400,
          o: 1.29342,
          h: 1.29579,
          l: 1.29258,
          c: 1.29446,
          v: 4626
        },
        {
          t: 54000,
          o: 1.29439,
          h: 1.29769,
          l: 1.29379,
          c: 1.29617,
          v: 5921
        },
        {
          t: 57600,
          o: 1.2962,
          h: 1.29706,
          l: 1.29563,
          c: 1.29637,
          v: 4265
        },
        {
          t: 61200,
          o: 1.29638,
          h: 1.29761,
          l: 1.29637,
          c: 1.29733,
          v: 3061
        },
        {
          t: 64800,
          o: 1.29733,
          h: 1.298,
          l: 1.29695,
          c: 1.29778,
          v: 2247
        },
        {
          t: 68400,
          o: 1.29779,
          h: 1.29791,
          l: 1.29718,
          c: 1.29728,
          v: 1923
        },
        {
          t: 72000,
          o: 1.29728,
          h: 1.29742,
          l: 1.29572,
          c: 1.29614,
          v: 2737
        },
        {
          t: 75600,
          o: 1.29609,
          h: 1.29609,
          l: 1.29565,
          c: 1.29567,
          v: 868
        }
      ]
    },
    {
      d: 1582416000,
      b: [
        {
          t: 79200,
          o: 1.29447,
          h: 1.29502,
          l: 1.2942,
          c: 1.29455,
          v: 453
        },
        {
          t: 82800,
          o: 1.29461,
          h: 1.2954,
          l: 1.2945,
          c: 1.29511,
          v: 1057
        }
      ]
    },
    {
      d: 1582502400,
      b: [
        {
          t: 0,
          o: 1.2951,
          h: 1.29526,
          l: 1.29434,
          c: 1.29451,
          v: 1650
        },
        {
          t: 3600,
          o: 1.29454,
          h: 1.29474,
          l: 1.2942,
          c: 1.29469,
          v: 1909
        },
        {
          t: 7200,
          o: 1.29468,
          h: 1.29506,
          l: 1.29391,
          c: 1.29391,
          v: 1545
        },
        {
          t: 10800,
          o: 1.29392,
          h: 1.29393,
          l: 1.29324,
          c: 1.29335,
          v: 1822
        },
        {
          t: 14400,
          o: 1.2934,
          h: 1.29384,
          l: 1.29333,
          c: 1.29372,
          v: 1582
        },
        {
          t: 18000,
          o: 1.29371,
          h: 1.29424,
          l: 1.29355,
          c: 1.2936,
          v: 1445
        },
        {
          t: 21600,
          o: 1.29356,
          h: 1.29417,
          l: 1.29334,
          c: 1.29415,
          v: 1563
        },
        {
          t: 25200,
          o: 1.29417,
          h: 1.29535,
          l: 1.29382,
          c: 1.29409,
          v: 4000
        },
        {
          t: 28800,
          o: 1.29407,
          h: 1.29431,
          l: 1.29095,
          c: 1.29242,
          v: 5593
        },
        {
          t: 32400,
          o: 1.29252,
          h: 1.29262,
          l: 1.28862,
          c: 1.28955,
          v: 5250
        },
        {
          t: 36000,
          o: 1.28956,
          h: 1.29062,
          l: 1.28931,
          c: 1.28975,
          v: 4321
        },
        {
          t: 39600,
          o: 1.28975,
          h: 1.29092,
          l: 1.28914,
          c: 1.29034,
          v: 4112
        },
        {
          t: 43200,
          o: 1.29034,
          h: 1.29211,
          l: 1.2886,
          c: 1.29211,
          v: 3713
        },
        {
          t: 46800,
          o: 1.29208,
          h: 1.29208,
          l: 1.2899,
          c: 1.28993,
          v: 4401
        },
        {
          t: 50400,
          o: 1.28993,
          h: 1.29289,
          l: 1.28991,
          c: 1.29199,
          v: 5645
        },
        {
          t: 54000,
          o: 1.29201,
          h: 1.29319,
          l: 1.29151,
          c: 1.29226,
          v: 5934
        },
        {
          t: 57600,
          o: 1.29227,
          h: 1.29313,
          l: 1.29151,
          c: 1.29213,
          v: 4118
        },
        {
          t: 61200,
          o: 1.29212,
          h: 1.29365,
          l: 1.2917,
          c: 1.29356,
          v: 3597
        },
        {
          t: 64800,
          o: 1.29356,
          h: 1.29357,
          l: 1.29264,
          c: 1.29288,
          v: 2773
        },
        {
          t: 68400,
          o: 1.29287,
          h: 1.29304,
          l: 1.29121,
          c: 1.29128,
          v: 3361
        },
        {
          t: 72000,
          o: 1.29132,
          h: 1.29314,
          l: 1.29121,
          c: 1.29269,
          v: 4014
        },
        {
          t: 75600,
          o: 1.29267,
          h: 1.29309,
          l: 1.29235,
          c: 1.29251,
          v: 2161
        },
        {
          t: 79200,
          o: 1.29235,
          h: 1.29304,
          l: 1.29148,
          c: 1.29188,
          v: 1480
        },
        {
          t: 82800,
          o: 1.29187,
          h: 1.29256,
          l: 1.2917,
          c: 1.29234,
          v: 2135
        }
      ]
    },
    {
      d: 1582588800,
      b: [
        {
          t: 0,
          o: 1.29234,
          h: 1.29243,
          l: 1.29161,
          c: 1.29225,
          v: 2121
        },
        {
          t: 3600,
          o: 1.29226,
          h: 1.29414,
          l: 1.29137,
          c: 1.29395,
          v: 2747
        },
        {
          t: 7200,
          o: 1.29395,
          h: 1.29414,
          l: 1.29348,
          c: 1.29387,
          v: 2177
        },
        {
          t: 10800,
          o: 1.29385,
          h: 1.29399,
          l: 1.29353,
          c: 1.2938,
          v: 1789
        },
        {
          t: 14400,
          o: 1.29379,
          h: 1.29407,
          l: 1.29351,
          c: 1.29365,
          v: 1322
        },
        {
          t: 18000,
          o: 1.29364,
          h: 1.29385,
          l: 1.29267,
          c: 1.29317,
          v: 1863
        },
        {
          t: 21600,
          o: 1.29316,
          h: 1.29419,
          l: 1.29296,
          c: 1.29373,
          v: 2737
        },
        {
          t: 25200,
          o: 1.29373,
          h: 1.29566,
          l: 1.29283,
          c: 1.29537,
          v: 4348
        },
        {
          t: 28800,
          o: 1.29536,
          h: 1.2979,
          l: 1.29437,
          c: 1.29764,
          v: 5717
        },
        {
          t: 32400,
          o: 1.2977,
          h: 1.29946,
          l: 1.29702,
          c: 1.29935,
          v: 5723
        },
        {
          t: 36000,
          o: 1.2993,
          h: 1.29934,
          l: 1.29689,
          c: 1.29697,
          v: 5327
        },
        {
          t: 39600,
          o: 1.29694,
          h: 1.29711,
          l: 1.29578,
          c: 1.2965,
          v: 4546
        },
        {
          t: 43200,
          o: 1.29655,
          h: 1.29705,
          l: 1.2952,
          c: 1.29528,
          v: 4888
        },
        {
          t: 46800,
          o: 1.29527,
          h: 1.2977,
          l: 1.29527,
          c: 1.29686,
          v: 5741
        },
        {
          t: 50400,
          o: 1.29687,
          h: 1.29874,
          l: 1.29649,
          c: 1.29819,
          v: 4958
        },
        {
          t: 54000,
          o: 1.29821,
          h: 1.30173,
          l: 1.29775,
          c: 1.30153,
          v: 6566
        },
        {
          t: 57600,
          o: 1.30152,
          h: 1.3017,
          l: 1.29954,
          c: 1.30017,
          v: 5325
        },
        {
          t: 61200,
          o: 1.30015,
          h: 1.30132,
          l: 1.29975,
          c: 1.30106,
          v: 3185
        },
        {
          t: 64800,
          o: 1.30106,
          h: 1.30126,
          l: 1.30005,
          c: 1.30046,
          v: 3331
        },
        {
          t: 68400,
          o: 1.30047,
          h: 1.30133,
          l: 1.29985,
          c: 1.2999,
          v: 3839
        },
        {
          t: 72000,
          o: 1.29985,
          h: 1.30049,
          l: 1.29935,
          c: 1.29986,
          v: 3225
        },
        {
          t: 75600,
          o: 1.29983,
          h: 1.30051,
          l: 1.29976,
          c: 1.3004,
          v: 1262
        },
        {
          t: 79200,
          o: 1.30062,
          h: 1.30062,
          l: 1.29887,
          c: 1.2996,
          v: 879
        },
        {
          t: 82800,
          o: 1.29963,
          h: 1.3003,
          l: 1.29941,
          c: 1.30018,
          v: 1791
        }
      ]
    },
    {
      d: 1582675200,
      b: [
        {
          t: 0,
          o: 1.30019,
          h: 1.3002,
          l: 1.29928,
          c: 1.29981,
          v: 2427
        },
        {
          t: 3600,
          o: 1.29981,
          h: 1.30072,
          l: 1.29959,
          c: 1.3006,
          v: 2761
        },
        {
          t: 7200,
          o: 1.30061,
          h: 1.30073,
          l: 1.29965,
          c: 1.29969,
          v: 2739
        },
        {
          t: 10800,
          o: 1.29969,
          h: 1.29981,
          l: 1.29907,
          c: 1.29919,
          v: 2322
        },
        {
          t: 14400,
          o: 1.29918,
          h: 1.29935,
          l: 1.2981,
          c: 1.29858,
          v: 1869
        },
        {
          t: 18000,
          o: 1.2986,
          h: 1.29972,
          l: 1.29859,
          c: 1.29959,
          v: 2253
        },
        {
          t: 21600,
          o: 1.29958,
          h: 1.29967,
          l: 1.2977,
          c: 1.2984,
          v: 2797
        },
        {
          t: 25200,
          o: 1.29841,
          h: 1.29938,
          l: 1.2976,
          c: 1.29841,
          v: 4540
        },
        {
          t: 28800,
          o: 1.29833,
          h: 1.29915,
          l: 1.29709,
          c: 1.29727,
          v: 5113
        },
        {
          t: 32400,
          o: 1.29723,
          h: 1.29765,
          l: 1.29421,
          c: 1.29491,
          v: 5748
        },
        {
          t: 36000,
          o: 1.2949,
          h: 1.29497,
          l: 1.29295,
          c: 1.29313,
          v: 4679
        },
        {
          t: 39600,
          o: 1.29314,
          h: 1.29414,
          l: 1.29227,
          c: 1.29331,
          v: 5258
        },
        {
          t: 43200,
          o: 1.29333,
          h: 1.29372,
          l: 1.29132,
          c: 1.29275,
          v: 5667
        },
        {
          t: 46800,
          o: 1.29265,
          h: 1.29414,
          l: 1.29199,
          c: 1.29344,
          v: 4887
        },
        {
          t: 50400,
          o: 1.2934,
          h: 1.29499,
          l: 1.29199,
          c: 1.29231,
          v: 4735
        },
        {
          t: 54000,
          o: 1.29232,
          h: 1.2933,
          l: 1.29117,
          c: 1.29159,
          v: 5059
        },
        {
          t: 57600,
          o: 1.29159,
          h: 1.29293,
          l: 1.29116,
          c: 1.29193,
          v: 4320
        },
        {
          t: 61200,
          o: 1.29193,
          h: 1.29212,
          l: 1.29063,
          c: 1.29072,
          v: 3327
        },
        {
          t: 64800,
          o: 1.29071,
          h: 1.29131,
          l: 1.28995,
          c: 1.29022,
          v: 3546
        },
        {
          t: 68400,
          o: 1.2902,
          h: 1.29164,
          l: 1.29011,
          c: 1.29102,
          v: 4160
        },
        {
          t: 72000,
          o: 1.29106,
          h: 1.2914,
          l: 1.29031,
          c: 1.29081,
          v: 3261
        },
        {
          t: 75600,
          o: 1.29082,
          h: 1.29083,
          l: 1.28942,
          c: 1.28994,
          v: 1647
        },
        {
          t: 79200,
          o: 1.28996,
          h: 1.2908,
          l: 1.28921,
          c: 1.29011,
          v: 896
        },
        {
          t: 82800,
          o: 1.29011,
          h: 1.29071,
          l: 1.28984,
          c: 1.29051,
          v: 2063
        }
      ]
    },
    {
      d: 1582761600,
      b: [
        {
          t: 0,
          o: 1.29052,
          h: 1.292,
          l: 1.29024,
          c: 1.29144,
          v: 4567
        },
        {
          t: 3600,
          o: 1.29145,
          h: 1.29171,
          l: 1.2911,
          c: 1.29112,
          v: 615
        },
        {
          t: 7200,
          o: 1.29113,
          h: 1.29271,
          l: 1.29104,
          c: 1.29217,
          v: 2909
        },
        {
          t: 10800,
          o: 1.29216,
          h: 1.29236,
          l: 1.29167,
          c: 1.29225,
          v: 2215
        },
        {
          t: 14400,
          o: 1.29226,
          h: 1.29304,
          l: 1.29177,
          c: 1.29304,
          v: 1540
        },
        {
          t: 18000,
          o: 1.29303,
          h: 1.29353,
          l: 1.2926,
          c: 1.2929,
          v: 2915
        },
        {
          t: 21600,
          o: 1.29291,
          h: 1.29369,
          l: 1.29243,
          c: 1.29314,
          v: 3295
        },
        {
          t: 25200,
          o: 1.29314,
          h: 1.29363,
          l: 1.29156,
          c: 1.29213,
          v: 4552
        },
        {
          t: 28800,
          o: 1.29207,
          h: 1.2946,
          l: 1.29085,
          c: 1.29314,
          v: 5950
        },
        {
          t: 32400,
          o: 1.29314,
          h: 1.29348,
          l: 1.29015,
          c: 1.29025,
          v: 5183
        },
        {
          t: 36000,
          o: 1.29022,
          h: 1.29022,
          l: 1.2867,
          c: 1.28693,
          v: 5879
        },
        {
          t: 39600,
          o: 1.28695,
          h: 1.28775,
          l: 1.28596,
          c: 1.28696,
          v: 4921
        },
        {
          t: 43200,
          o: 1.28694,
          h: 1.28995,
          l: 1.28603,
          c: 1.28923,
          v: 5029
        },
        {
          t: 46800,
          o: 1.28922,
          h: 1.29165,
          l: 1.28843,
          c: 1.28947,
          v: 5989
        },
        {
          t: 50400,
          o: 1.28946,
          h: 1.28946,
          l: 1.28664,
          c: 1.28818,
          v: 6036
        },
        {
          t: 54000,
          o: 1.2882,
          h: 1.29016,
          l: 1.28697,
          c: 1.28719,
          v: 7035
        },
        {
          t: 57600,
          o: 1.28717,
          h: 1.28813,
          l: 1.28638,
          c: 1.2874,
          v: 5223
        },
        {
          t: 61200,
          o: 1.28742,
          h: 1.28857,
          l: 1.28665,
          c: 1.28831,
          v: 4141
        },
        {
          t: 64800,
          o: 1.28831,
          h: 1.28936,
          l: 1.28799,
          c: 1.2889,
          v: 3900
        },
        {
          t: 68400,
          o: 1.28892,
          h: 1.28931,
          l: 1.28875,
          c: 1.28919,
          v: 3143
        },
        {
          t: 72000,
          o: 1.28919,
          h: 1.28941,
          l: 1.2884,
          c: 1.28898,
          v: 3340
        },
        {
          t: 75600,
          o: 1.28897,
          h: 1.28942,
          l: 1.28825,
          c: 1.28841,
          v: 1987
        },
        {
          t: 79200,
          o: 1.28838,
          h: 1.28911,
          l: 1.28757,
          c: 1.28889,
          v: 594
        },
        {
          t: 82800,
          o: 1.28885,
          h: 1.28937,
          l: 1.28843,
          c: 1.28852,
          v: 2191
        }
      ]
    },
    {
      d: 1582848000,
      b: [
        {
          t: 3600,
          o: 1.28885,
          h: 1.28895,
          l: 1.28882,
          c: 1.28894,
          v: 112
        },
        {
          t: 7200,
          o: 1.28893,
          h: 1.28895,
          l: 1.28822,
          c: 1.2887,
          v: 3044
        },
        {
          t: 10800,
          o: 1.2887,
          h: 1.28875,
          l: 1.28693,
          c: 1.28735,
          v: 3168
        },
        {
          t: 14400,
          o: 1.28735,
          h: 1.28864,
          l: 1.287,
          c: 1.28835,
          v: 2639
        },
        {
          t: 18000,
          o: 1.28836,
          h: 1.28886,
          l: 1.28788,
          c: 1.28865,
          v: 3110
        },
        {
          t: 21600,
          o: 1.28866,
          h: 1.28956,
          l: 1.28762,
          c: 1.28785,
          v: 3715
        },
        {
          t: 25200,
          o: 1.28791,
          h: 1.28796,
          l: 1.28589,
          c: 1.28621,
          v: 5185
        },
        {
          t: 28800,
          o: 1.28619,
          h: 1.28805,
          l: 1.2861,
          c: 1.28785,
          v: 6571
        },
        {
          t: 32400,
          o: 1.28783,
          h: 1.29191,
          l: 1.28696,
          c: 1.2904,
          v: 6071
        },
        {
          t: 36000,
          o: 1.29035,
          h: 1.29065,
          l: 1.28791,
          c: 1.2882,
          v: 5481
        },
        {
          t: 39600,
          o: 1.28822,
          h: 1.28915,
          l: 1.28663,
          c: 1.28825,
          v: 5660
        },
        {
          t: 43200,
          o: 1.28825,
          h: 1.28915,
          l: 1.28652,
          c: 1.2874,
          v: 5209
        },
        {
          t: 46800,
          o: 1.2874,
          h: 1.28764,
          l: 1.28453,
          c: 1.28548,
          v: 5740
        },
        {
          t: 50400,
          o: 1.28547,
          h: 1.28554,
          l: 1.28202,
          c: 1.28213,
          v: 6195
        },
        {
          t: 54000,
          o: 1.28215,
          h: 1.28327,
          l: 1.27588,
          c: 1.27723,
          v: 7100
        },
        {
          t: 57600,
          o: 1.27721,
          h: 1.2791,
          l: 1.27523,
          c: 1.27755,
          v: 7711
        },
        {
          t: 61200,
          o: 1.27756,
          h: 1.27819,
          l: 1.27535,
          c: 1.27603,
          v: 5162
        },
        {
          t: 64800,
          o: 1.27603,
          h: 1.27604,
          l: 1.27251,
          c: 1.2754,
          v: 5220
        },
        {
          t: 68400,
          o: 1.2754,
          h: 1.28054,
          l: 1.27536,
          c: 1.27962,
          v: 3805
        }
      ]
    }
  ],
  USDJPY15: [
    {
      d: 1580601600,
      b: [
        {
          t: 79200,
          o: 108.406,
          h: 108.427,
          l: 108.325,
          c: 108.396,
          v: 673
        },
        {
          t: 80100,
          o: 108.396,
          h: 108.396,
          l: 108.307,
          c: 108.353,
          v: 307
        },
        {
          t: 81000,
          o: 108.355,
          h: 108.356,
          l: 108.315,
          c: 108.33,
          v: 478
        },
        {
          t: 81900,
          o: 108.332,
          h: 108.349,
          l: 108.323,
          c: 108.347,
          v: 285
        },
        {
          t: 82800,
          o: 108.346,
          h: 108.408,
          l: 108.314,
          c: 108.383,
          v: 913
        },
        {
          t: 83700,
          o: 108.386,
          h: 108.406,
          l: 108.364,
          c: 108.384,
          v: 826
        },
        {
          t: 84600,
          o: 108.384,
          h: 108.398,
          l: 108.35,
          c: 108.381,
          v: 701
        },
        {
          t: 85500,
          o: 108.385,
          h: 108.395,
          l: 108.328,
          c: 108.382,
          v: 879
        }
      ]
    },
    {
      d: 1580688000,
      b: [
        {
          t: 0,
          o: 108.383,
          h: 108.434,
          l: 108.359,
          c: 108.428,
          v: 925
        },
        {
          t: 900,
          o: 108.43,
          h: 108.433,
          l: 108.411,
          c: 108.417,
          v: 470
        },
        {
          t: 1800,
          o: 108.417,
          h: 108.535,
          l: 108.41,
          c: 108.529,
          v: 1072
        },
        {
          t: 2700,
          o: 108.53,
          h: 108.56,
          l: 108.472,
          c: 108.481,
          v: 1078
        },
        {
          t: 3600,
          o: 108.478,
          h: 108.524,
          l: 108.434,
          c: 108.489,
          v: 1773
        },
        {
          t: 4500,
          o: 108.49,
          h: 108.515,
          l: 108.412,
          c: 108.447,
          v: 1791
        },
        {
          t: 5400,
          o: 108.447,
          h: 108.565,
          l: 108.43,
          c: 108.531,
          v: 1755
        },
        {
          t: 6300,
          o: 108.531,
          h: 108.577,
          l: 108.518,
          c: 108.556,
          v: 1387
        },
        {
          t: 7200,
          o: 108.557,
          h: 108.566,
          l: 108.48,
          c: 108.481,
          v: 1520
        },
        {
          t: 8100,
          o: 108.483,
          h: 108.536,
          l: 108.468,
          c: 108.516,
          v: 975
        },
        {
          t: 9000,
          o: 108.517,
          h: 108.525,
          l: 108.485,
          c: 108.516,
          v: 887
        },
        {
          t: 9900,
          o: 108.513,
          h: 108.516,
          l: 108.471,
          c: 108.476,
          v: 914
        },
        {
          t: 10800,
          o: 108.476,
          h: 108.491,
          l: 108.467,
          c: 108.484,
          v: 945
        },
        {
          t: 11700,
          o: 108.483,
          h: 108.545,
          l: 108.476,
          c: 108.525,
          v: 840
        },
        {
          t: 12600,
          o: 108.525,
          h: 108.542,
          l: 108.517,
          c: 108.521,
          v: 689
        },
        {
          t: 13500,
          o: 108.521,
          h: 108.541,
          l: 108.512,
          c: 108.541,
          v: 396
        },
        {
          t: 14400,
          o: 108.54,
          h: 108.545,
          l: 108.512,
          c: 108.514,
          v: 324
        },
        {
          t: 15300,
          o: 108.514,
          h: 108.53,
          l: 108.501,
          c: 108.508,
          v: 356
        },
        {
          t: 16200,
          o: 108.509,
          h: 108.51,
          l: 108.476,
          c: 108.481,
          v: 373
        },
        {
          t: 17100,
          o: 108.478,
          h: 108.487,
          l: 108.468,
          c: 108.472,
          v: 237
        },
        {
          t: 18000,
          o: 108.472,
          h: 108.498,
          l: 108.471,
          c: 108.496,
          v: 377
        },
        {
          t: 18900,
          o: 108.495,
          h: 108.526,
          l: 108.495,
          c: 108.521,
          v: 393
        },
        {
          t: 19800,
          o: 108.522,
          h: 108.526,
          l: 108.505,
          c: 108.515,
          v: 474
        },
        {
          t: 20700,
          o: 108.514,
          h: 108.525,
          l: 108.506,
          c: 108.521,
          v: 448
        },
        {
          t: 21600,
          o: 108.522,
          h: 108.523,
          l: 108.478,
          c: 108.48,
          v: 533
        },
        {
          t: 22500,
          o: 108.48,
          h: 108.513,
          l: 108.48,
          c: 108.511,
          v: 346
        },
        {
          t: 23400,
          o: 108.508,
          h: 108.52,
          l: 108.475,
          c: 108.476,
          v: 238
        },
        {
          t: 24300,
          o: 108.476,
          h: 108.518,
          l: 108.469,
          c: 108.518,
          v: 357
        },
        {
          t: 25200,
          o: 108.516,
          h: 108.556,
          l: 108.488,
          c: 108.529,
          v: 859
        },
        {
          t: 26100,
          o: 108.529,
          h: 108.63,
          l: 108.527,
          c: 108.625,
          v: 913
        },
        {
          t: 27000,
          o: 108.624,
          h: 108.693,
          l: 108.617,
          c: 108.683,
          v: 993
        },
        {
          t: 27900,
          o: 108.682,
          h: 108.685,
          l: 108.613,
          c: 108.622,
          v: 674
        },
        {
          t: 28800,
          o: 108.618,
          h: 108.625,
          l: 108.516,
          c: 108.527,
          v: 1430
        },
        {
          t: 29700,
          o: 108.526,
          h: 108.536,
          l: 108.457,
          c: 108.511,
          v: 1279
        },
        {
          t: 30600,
          o: 108.51,
          h: 108.569,
          l: 108.499,
          c: 108.562,
          v: 912
        },
        {
          t: 31500,
          o: 108.568,
          h: 108.644,
          l: 108.549,
          c: 108.605,
          v: 1223
        },
        {
          t: 32400,
          o: 108.605,
          h: 108.605,
          l: 108.538,
          c: 108.572,
          v: 1132
        },
        {
          t: 33300,
          o: 108.574,
          h: 108.583,
          l: 108.545,
          c: 108.554,
          v: 953
        },
        {
          t: 34200,
          o: 108.554,
          h: 108.567,
          l: 108.523,
          c: 108.53,
          v: 673
        },
        {
          t: 35100,
          o: 108.531,
          h: 108.548,
          l: 108.501,
          c: 108.501,
          v: 633
        },
        {
          t: 36000,
          o: 108.501,
          h: 108.525,
          l: 108.469,
          c: 108.512,
          v: 744
        },
        {
          t: 36900,
          o: 108.511,
          h: 108.521,
          l: 108.487,
          c: 108.515,
          v: 523
        },
        {
          t: 37800,
          o: 108.514,
          h: 108.514,
          l: 108.436,
          c: 108.439,
          v: 585
        },
        {
          t: 38700,
          o: 108.439,
          h: 108.459,
          l: 108.413,
          c: 108.414,
          v: 575
        },
        {
          t: 39600,
          o: 108.415,
          h: 108.514,
          l: 108.412,
          c: 108.51,
          v: 691
        },
        {
          t: 40500,
          o: 108.509,
          h: 108.533,
          l: 108.494,
          c: 108.511,
          v: 593
        },
        {
          t: 41400,
          o: 108.513,
          h: 108.523,
          l: 108.475,
          c: 108.509,
          v: 513
        },
        {
          t: 42300,
          o: 108.508,
          h: 108.534,
          l: 108.504,
          c: 108.521,
          v: 526
        },
        {
          t: 43200,
          o: 108.519,
          h: 108.526,
          l: 108.482,
          c: 108.489,
          v: 409
        },
        {
          t: 44100,
          o: 108.491,
          h: 108.519,
          l: 108.477,
          c: 108.518,
          v: 421
        },
        {
          t: 45000,
          o: 108.517,
          h: 108.523,
          l: 108.498,
          c: 108.516,
          v: 442
        },
        {
          t: 45900,
          o: 108.517,
          h: 108.517,
          l: 108.487,
          c: 108.507,
          v: 324
        },
        {
          t: 46800,
          o: 108.507,
          h: 108.553,
          l: 108.503,
          c: 108.525,
          v: 591
        },
        {
          t: 47700,
          o: 108.526,
          h: 108.571,
          l: 108.516,
          c: 108.566,
          v: 688
        },
        {
          t: 48600,
          o: 108.565,
          h: 108.565,
          l: 108.544,
          c: 108.556,
          v: 638
        },
        {
          t: 49500,
          o: 108.555,
          h: 108.559,
          l: 108.517,
          c: 108.536,
          v: 467
        },
        {
          t: 50400,
          o: 108.535,
          h: 108.544,
          l: 108.511,
          c: 108.512,
          v: 541
        },
        {
          t: 51300,
          o: 108.512,
          h: 108.526,
          l: 108.483,
          c: 108.49,
          v: 524
        },
        {
          t: 52200,
          o: 108.492,
          h: 108.565,
          l: 108.477,
          c: 108.565,
          v: 1249
        },
        {
          t: 53100,
          o: 108.565,
          h: 108.666,
          l: 108.565,
          c: 108.621,
          v: 1151
        },
        {
          t: 54000,
          o: 108.633,
          h: 108.786,
          l: 108.587,
          c: 108.707,
          v: 2566
        },
        {
          t: 54900,
          o: 108.707,
          h: 108.792,
          l: 108.671,
          c: 108.786,
          v: 1389
        },
        {
          t: 55800,
          o: 108.784,
          h: 108.793,
          l: 108.7,
          c: 108.727,
          v: 1142
        },
        {
          t: 56700,
          o: 108.728,
          h: 108.733,
          l: 108.66,
          c: 108.706,
          v: 1093
        },
        {
          t: 57600,
          o: 108.706,
          h: 108.726,
          l: 108.659,
          c: 108.661,
          v: 983
        },
        {
          t: 58500,
          o: 108.661,
          h: 108.673,
          l: 108.609,
          c: 108.614,
          v: 1008
        },
        {
          t: 59400,
          o: 108.613,
          h: 108.637,
          l: 108.522,
          c: 108.55,
          v: 1658
        },
        {
          t: 60300,
          o: 108.548,
          h: 108.589,
          l: 108.515,
          c: 108.588,
          v: 1393
        },
        {
          t: 61200,
          o: 108.587,
          h: 108.587,
          l: 108.548,
          c: 108.581,
          v: 1173
        },
        {
          t: 62100,
          o: 108.582,
          h: 108.64,
          l: 108.58,
          c: 108.612,
          v: 940
        },
        {
          t: 63000,
          o: 108.613,
          h: 108.644,
          l: 108.601,
          c: 108.638,
          v: 769
        },
        {
          t: 63900,
          o: 108.637,
          h: 108.673,
          l: 108.62,
          c: 108.659,
          v: 708
        },
        {
          t: 64800,
          o: 108.66,
          h: 108.663,
          l: 108.583,
          c: 108.602,
          v: 792
        },
        {
          t: 65700,
          o: 108.602,
          h: 108.631,
          l: 108.583,
          c: 108.621,
          v: 909
        },
        {
          t: 66600,
          o: 108.619,
          h: 108.666,
          l: 108.612,
          c: 108.65,
          v: 674
        },
        {
          t: 67500,
          o: 108.65,
          h: 108.666,
          l: 108.631,
          c: 108.665,
          v: 715
        },
        {
          t: 68400,
          o: 108.665,
          h: 108.666,
          l: 108.624,
          c: 108.643,
          v: 725
        },
        {
          t: 69300,
          o: 108.644,
          h: 108.677,
          l: 108.631,
          c: 108.645,
          v: 590
        },
        {
          t: 70200,
          o: 108.644,
          h: 108.655,
          l: 108.627,
          c: 108.654,
          v: 526
        },
        {
          t: 71100,
          o: 108.654,
          h: 108.688,
          l: 108.654,
          c: 108.671,
          v: 562
        },
        {
          t: 72000,
          o: 108.67,
          h: 108.706,
          l: 108.654,
          c: 108.689,
          v: 670
        },
        {
          t: 72900,
          o: 108.69,
          h: 108.726,
          l: 108.678,
          c: 108.726,
          v: 535
        },
        {
          t: 73800,
          o: 108.725,
          h: 108.738,
          l: 108.696,
          c: 108.696,
          v: 644
        },
        {
          t: 74700,
          o: 108.693,
          h: 108.701,
          l: 108.665,
          c: 108.681,
          v: 666
        },
        {
          t: 75600,
          o: 108.68,
          h: 108.687,
          l: 108.66,
          c: 108.687,
          v: 394
        },
        {
          t: 76500,
          o: 108.686,
          h: 108.687,
          l: 108.665,
          c: 108.671,
          v: 88
        },
        {
          t: 77400,
          o: 108.671,
          h: 108.689,
          l: 108.663,
          c: 108.671,
          v: 277
        },
        {
          t: 78300,
          o: 108.67,
          h: 108.695,
          l: 108.658,
          c: 108.661,
          v: 368
        },
        {
          t: 79200,
          o: 108.662,
          h: 108.698,
          l: 108.658,
          c: 108.667,
          v: 418
        },
        {
          t: 80100,
          o: 108.664,
          h: 108.675,
          l: 108.65,
          c: 108.661,
          v: 768
        },
        {
          t: 81000,
          o: 108.66,
          h: 108.667,
          l: 108.64,
          c: 108.643,
          v: 401
        },
        {
          t: 81900,
          o: 108.643,
          h: 108.66,
          l: 108.641,
          c: 108.658,
          v: 282
        },
        {
          t: 82800,
          o: 108.655,
          h: 108.664,
          l: 108.575,
          c: 108.6,
          v: 539
        },
        {
          t: 83700,
          o: 108.601,
          h: 108.629,
          l: 108.595,
          c: 108.607,
          v: 293
        },
        {
          t: 84600,
          o: 108.607,
          h: 108.635,
          l: 108.607,
          c: 108.616,
          v: 314
        },
        {
          t: 85500,
          o: 108.618,
          h: 108.636,
          l: 108.608,
          c: 108.612,
          v: 352
        }
      ]
    },
    {
      d: 1580774400,
      b: [
        {
          t: 0,
          o: 108.613,
          h: 108.652,
          l: 108.576,
          c: 108.634,
          v: 692
        },
        {
          t: 900,
          o: 108.635,
          h: 108.65,
          l: 108.61,
          c: 108.642,
          v: 529
        },
        {
          t: 1800,
          o: 108.644,
          h: 108.648,
          l: 108.616,
          c: 108.629,
          v: 494
        },
        {
          t: 2700,
          o: 108.627,
          h: 108.627,
          l: 108.567,
          c: 108.587,
          v: 723
        },
        {
          t: 3600,
          o: 108.588,
          h: 108.689,
          l: 108.543,
          c: 108.581,
          v: 1307
        },
        {
          t: 4500,
          o: 108.581,
          h: 108.636,
          l: 108.57,
          c: 108.585,
          v: 965
        },
        {
          t: 5400,
          o: 108.586,
          h: 108.688,
          l: 108.586,
          c: 108.639,
          v: 1234
        },
        {
          t: 6300,
          o: 108.64,
          h: 108.709,
          l: 108.629,
          c: 108.695,
          v: 1034
        },
        {
          t: 7200,
          o: 108.693,
          h: 108.693,
          l: 108.642,
          c: 108.658,
          v: 915
        },
        {
          t: 8100,
          o: 108.659,
          h: 108.684,
          l: 108.608,
          c: 108.642,
          v: 758
        },
        {
          t: 9000,
          o: 108.64,
          h: 108.681,
          l: 108.64,
          c: 108.657,
          v: 631
        },
        {
          t: 9900,
          o: 108.655,
          h: 108.665,
          l: 108.635,
          c: 108.659,
          v: 558
        },
        {
          t: 10800,
          o: 108.655,
          h: 108.655,
          l: 108.615,
          c: 108.649,
          v: 441
        },
        {
          t: 11700,
          o: 108.648,
          h: 108.685,
          l: 108.634,
          c: 108.674,
          v: 418
        },
        {
          t: 12600,
          o: 108.674,
          h: 108.776,
          l: 108.665,
          c: 108.716,
          v: 1181
        },
        {
          t: 13500,
          o: 108.714,
          h: 108.726,
          l: 108.667,
          c: 108.689,
          v: 539
        },
        {
          t: 14400,
          o: 108.689,
          h: 108.716,
          l: 108.687,
          c: 108.7,
          v: 455
        },
        {
          t: 15300,
          o: 108.699,
          h: 108.759,
          l: 108.697,
          c: 108.75,
          v: 304
        },
        {
          t: 16200,
          o: 108.752,
          h: 108.776,
          l: 108.725,
          c: 108.732,
          v: 659
        },
        {
          t: 17100,
          o: 108.732,
          h: 108.745,
          l: 108.718,
          c: 108.73,
          v: 400
        },
        {
          t: 18000,
          o: 108.732,
          h: 108.744,
          l: 108.715,
          c: 108.724,
          v: 499
        },
        {
          t: 18900,
          o: 108.722,
          h: 108.762,
          l: 108.72,
          c: 108.749,
          v: 434
        },
        {
          t: 19800,
          o: 108.751,
          h: 108.848,
          l: 108.746,
          c: 108.829,
          v: 684
        },
        {
          t: 20700,
          o: 108.826,
          h: 108.847,
          l: 108.802,
          c: 108.809,
          v: 747
        },
        {
          t: 21600,
          o: 108.808,
          h: 108.826,
          l: 108.784,
          c: 108.801,
          v: 598
        },
        {
          t: 22500,
          o: 108.801,
          h: 108.843,
          l: 108.796,
          c: 108.827,
          v: 466
        },
        {
          t: 23400,
          o: 108.828,
          h: 108.889,
          l: 108.819,
          c: 108.874,
          v: 550
        },
        {
          t: 24300,
          o: 108.876,
          h: 108.929,
          l: 108.861,
          c: 108.908,
          v: 459
        },
        {
          t: 25200,
          o: 108.911,
          h: 108.911,
          l: 108.845,
          c: 108.887,
          v: 652
        },
        {
          t: 26100,
          o: 108.887,
          h: 108.892,
          l: 108.84,
          c: 108.846,
          v: 646
        },
        {
          t: 27000,
          o: 108.844,
          h: 108.872,
          l: 108.822,
          c: 108.867,
          v: 691
        },
        {
          t: 27900,
          o: 108.868,
          h: 108.943,
          l: 108.864,
          c: 108.935,
          v: 735
        },
        {
          t: 28800,
          o: 108.93,
          h: 108.994,
          l: 108.912,
          c: 108.938,
          v: 1263
        },
        {
          t: 29700,
          o: 108.939,
          h: 109.081,
          l: 108.927,
          c: 109.043,
          v: 1361
        },
        {
          t: 30600,
          o: 109.045,
          h: 109.046,
          l: 109.016,
          c: 109.032,
          v: 879
        },
        {
          t: 31500,
          o: 109.03,
          h: 109.046,
          l: 109.024,
          c: 109.029,
          v: 956
        },
        {
          t: 32400,
          o: 109.028,
          h: 109.057,
          l: 109.016,
          c: 109.038,
          v: 811
        },
        {
          t: 33300,
          o: 109.038,
          h: 109.047,
          l: 109.002,
          c: 109.045,
          v: 639
        },
        {
          t: 34200,
          o: 109.044,
          h: 109.125,
          l: 109.044,
          c: 109.055,
          v: 759
        },
        {
          t: 35100,
          o: 109.057,
          h: 109.097,
          l: 109.056,
          c: 109.079,
          v: 645
        },
        {
          t: 36000,
          o: 109.081,
          h: 109.089,
          l: 109.054,
          c: 109.068,
          v: 535
        },
        {
          t: 36900,
          o: 109.066,
          h: 109.085,
          l: 109.03,
          c: 109.033,
          v: 427
        },
        {
          t: 37800,
          o: 109.032,
          h: 109.038,
          l: 109.01,
          c: 109.021,
          v: 502
        },
        {
          t: 38700,
          o: 109.02,
          h: 109.033,
          l: 108.975,
          c: 108.978,
          v: 412
        },
        {
          t: 39600,
          o: 108.979,
          h: 109.02,
          l: 108.97,
          c: 109.02,
          v: 398
        },
        {
          t: 40500,
          o: 109.02,
          h: 109.047,
          l: 109.007,
          c: 109.02,
          v: 451
        },
        {
          t: 41400,
          o: 109.022,
          h: 109.044,
          l: 109.001,
          c: 109.033,
          v: 433
        },
        {
          t: 42300,
          o: 109.037,
          h: 109.058,
          l: 109.019,
          c: 109.026,
          v: 397
        },
        {
          t: 43200,
          o: 109.025,
          h: 109.06,
          l: 109.022,
          c: 109.048,
          v: 577
        },
        {
          t: 44100,
          o: 109.048,
          h: 109.1,
          l: 109.034,
          c: 109.073,
          v: 547
        },
        {
          t: 45000,
          o: 109.074,
          h: 109.091,
          l: 109.056,
          c: 109.089,
          v: 441
        },
        {
          t: 45900,
          o: 109.121,
          h: 109.126,
          l: 109.121,
          c: 109.126,
          v: 4
        },
        {
          t: 46800,
          o: 109.094,
          h: 109.114,
          l: 109.08,
          c: 109.086,
          v: 500
        },
        {
          t: 47700,
          o: 109.085,
          h: 109.125,
          l: 109.063,
          c: 109.112,
          v: 702
        },
        {
          t: 48600,
          o: 109.112,
          h: 109.195,
          l: 109.092,
          c: 109.173,
          v: 821
        },
        {
          t: 49500,
          o: 109.173,
          h: 109.192,
          l: 109.15,
          c: 109.179,
          v: 625
        },
        {
          t: 50400,
          o: 109.178,
          h: 109.192,
          l: 109.136,
          c: 109.142,
          v: 804
        },
        {
          t: 51300,
          o: 109.142,
          h: 109.159,
          l: 109.124,
          c: 109.159,
          v: 503
        },
        {
          t: 52200,
          o: 109.157,
          h: 109.22,
          l: 109.134,
          c: 109.18,
          v: 1565
        },
        {
          t: 53100,
          o: 109.185,
          h: 109.266,
          l: 109.184,
          c: 109.244,
          v: 1267
        },
        {
          t: 54000,
          o: 109.242,
          h: 109.272,
          l: 109.193,
          c: 109.211,
          v: 1384
        },
        {
          t: 54900,
          o: 109.211,
          h: 109.355,
          l: 109.204,
          c: 109.351,
          v: 958
        },
        {
          t: 55800,
          o: 109.347,
          h: 109.347,
          l: 109.313,
          c: 109.328,
          v: 844
        },
        {
          t: 56700,
          o: 109.328,
          h: 109.345,
          l: 109.294,
          c: 109.33,
          v: 851
        },
        {
          t: 57600,
          o: 109.33,
          h: 109.346,
          l: 109.287,
          c: 109.3,
          v: 691
        },
        {
          t: 58500,
          o: 109.3,
          h: 109.42,
          l: 109.298,
          c: 109.405,
          v: 591
        },
        {
          t: 59400,
          o: 109.407,
          h: 109.441,
          l: 109.405,
          c: 109.428,
          v: 727
        },
        {
          t: 60300,
          o: 109.428,
          h: 109.431,
          l: 109.387,
          c: 109.4,
          v: 510
        },
        {
          t: 61200,
          o: 109.401,
          h: 109.432,
          l: 109.389,
          c: 109.425,
          v: 761
        },
        {
          t: 62100,
          o: 109.426,
          h: 109.456,
          l: 109.392,
          c: 109.451,
          v: 857
        },
        {
          t: 63000,
          o: 109.452,
          h: 109.464,
          l: 109.431,
          c: 109.441,
          v: 453
        },
        {
          t: 63900,
          o: 109.444,
          h: 109.46,
          l: 109.412,
          c: 109.456,
          v: 624
        },
        {
          t: 64800,
          o: 109.458,
          h: 109.49,
          l: 109.444,
          c: 109.444,
          v: 496
        },
        {
          t: 65700,
          o: 109.446,
          h: 109.453,
          l: 109.425,
          c: 109.448,
          v: 382
        },
        {
          t: 66600,
          o: 109.447,
          h: 109.507,
          l: 109.447,
          c: 109.492,
          v: 403
        },
        {
          t: 67500,
          o: 109.494,
          h: 109.517,
          l: 109.459,
          c: 109.473,
          v: 479
        },
        {
          t: 68400,
          o: 109.476,
          h: 109.512,
          l: 109.465,
          c: 109.502,
          v: 493
        },
        {
          t: 69300,
          o: 109.5,
          h: 109.509,
          l: 109.464,
          c: 109.491,
          v: 579
        },
        {
          t: 70200,
          o: 109.491,
          h: 109.533,
          l: 109.485,
          c: 109.526,
          v: 442
        },
        {
          t: 71100,
          o: 109.524,
          h: 109.527,
          l: 109.493,
          c: 109.495,
          v: 452
        },
        {
          t: 72000,
          o: 109.494,
          h: 109.51,
          l: 109.491,
          c: 109.509,
          v: 423
        },
        {
          t: 72900,
          o: 109.511,
          h: 109.539,
          l: 109.506,
          c: 109.534,
          v: 341
        },
        {
          t: 73800,
          o: 109.533,
          h: 109.538,
          l: 109.474,
          c: 109.482,
          v: 548
        },
        {
          t: 74700,
          o: 109.482,
          h: 109.49,
          l: 109.455,
          c: 109.466,
          v: 746
        },
        {
          t: 75600,
          o: 109.467,
          h: 109.481,
          l: 109.445,
          c: 109.481,
          v: 374
        },
        {
          t: 76500,
          o: 109.482,
          h: 109.521,
          l: 109.474,
          c: 109.517,
          v: 240
        },
        {
          t: 77400,
          o: 109.513,
          h: 109.536,
          l: 109.508,
          c: 109.536,
          v: 456
        },
        {
          t: 78300,
          o: 109.533,
          h: 109.533,
          l: 109.505,
          c: 109.515,
          v: 272
        },
        {
          t: 79200,
          o: 109.514,
          h: 109.516,
          l: 109.444,
          c: 109.501,
          v: 284
        },
        {
          t: 80100,
          o: 109.495,
          h: 109.504,
          l: 109.454,
          c: 109.475,
          v: 328
        },
        {
          t: 81000,
          o: 109.476,
          h: 109.483,
          l: 109.456,
          c: 109.456,
          v: 155
        },
        {
          t: 81900,
          o: 109.456,
          h: 109.464,
          l: 109.437,
          c: 109.459,
          v: 324
        },
        {
          t: 82800,
          o: 109.454,
          h: 109.488,
          l: 109.446,
          c: 109.474,
          v: 351
        },
        {
          t: 83700,
          o: 109.476,
          h: 109.491,
          l: 109.468,
          c: 109.479,
          v: 227
        },
        {
          t: 84600,
          o: 109.48,
          h: 109.484,
          l: 109.45,
          c: 109.45,
          v: 406
        },
        {
          t: 85500,
          o: 109.45,
          h: 109.452,
          l: 109.426,
          c: 109.437,
          v: 450
        }
      ]
    },
    {
      d: 1580860800,
      b: [
        {
          t: 0,
          o: 109.437,
          h: 109.474,
          l: 109.43,
          c: 109.46,
          v: 851
        },
        {
          t: 900,
          o: 109.461,
          h: 109.478,
          l: 109.376,
          c: 109.383,
          v: 853
        },
        {
          t: 1800,
          o: 109.385,
          h: 109.423,
          l: 109.385,
          c: 109.399,
          v: 703
        },
        {
          t: 2700,
          o: 109.397,
          h: 109.436,
          l: 109.366,
          c: 109.413,
          v: 942
        },
        {
          t: 3600,
          o: 109.412,
          h: 109.446,
          l: 109.383,
          c: 109.443,
          v: 695
        },
        {
          t: 4500,
          o: 109.442,
          h: 109.449,
          l: 109.376,
          c: 109.407,
          v: 792
        },
        {
          t: 5400,
          o: 109.405,
          h: 109.427,
          l: 109.361,
          c: 109.413,
          v: 1315
        },
        {
          t: 6300,
          o: 109.417,
          h: 109.482,
          l: 109.394,
          c: 109.479,
          v: 1113
        },
        {
          t: 7200,
          o: 109.48,
          h: 109.486,
          l: 109.458,
          c: 109.464,
          v: 924
        },
        {
          t: 8100,
          o: 109.463,
          h: 109.47,
          l: 109.443,
          c: 109.464,
          v: 667
        },
        {
          t: 9000,
          o: 109.463,
          h: 109.483,
          l: 109.446,
          c: 109.472,
          v: 620
        },
        {
          t: 9900,
          o: 109.471,
          h: 109.475,
          l: 109.445,
          c: 109.452,
          v: 595
        },
        {
          t: 10800,
          o: 109.452,
          h: 109.467,
          l: 109.408,
          c: 109.414,
          v: 597
        },
        {
          t: 11700,
          o: 109.414,
          h: 109.433,
          l: 109.401,
          c: 109.408,
          v: 430
        },
        {
          t: 12600,
          o: 109.41,
          h: 109.447,
          l: 109.41,
          c: 109.44,
          v: 496
        },
        {
          t: 13500,
          o: 109.44,
          h: 109.457,
          l: 109.428,
          c: 109.441,
          v: 345
        },
        {
          t: 14400,
          o: 109.44,
          h: 109.461,
          l: 109.431,
          c: 109.453,
          v: 295
        },
        {
          t: 15300,
          o: 109.454,
          h: 109.471,
          l: 109.449,
          c: 109.471,
          v: 285
        },
        {
          t: 16200,
          o: 109.47,
          h: 109.477,
          l: 109.458,
          c: 109.462,
          v: 247
        },
        {
          t: 17100,
          o: 109.463,
          h: 109.473,
          l: 109.455,
          c: 109.466,
          v: 190
        },
        {
          t: 18000,
          o: 109.467,
          h: 109.472,
          l: 109.445,
          c: 109.449,
          v: 274
        },
        {
          t: 18900,
          o: 109.448,
          h: 109.452,
          l: 109.426,
          c: 109.431,
          v: 385
        },
        {
          t: 19800,
          o: 109.433,
          h: 109.436,
          l: 109.411,
          c: 109.42,
          v: 417
        },
        {
          t: 20700,
          o: 109.422,
          h: 109.434,
          l: 109.4,
          c: 109.427,
          v: 637
        },
        {
          t: 21600,
          o: 109.43,
          h: 109.457,
          l: 109.405,
          c: 109.437,
          v: 608
        },
        {
          t: 22500,
          o: 109.436,
          h: 109.441,
          l: 109.421,
          c: 109.425,
          v: 393
        },
        {
          t: 23400,
          o: 109.424,
          h: 109.43,
          l: 109.401,
          c: 109.407,
          v: 245
        },
        {
          t: 24300,
          o: 109.406,
          h: 109.426,
          l: 109.395,
          c: 109.406,
          v: 240
        },
        {
          t: 25200,
          o: 109.404,
          h: 109.415,
          l: 109.32,
          c: 109.338,
          v: 613
        },
        {
          t: 26100,
          o: 109.34,
          h: 109.356,
          l: 109.312,
          c: 109.325,
          v: 529
        },
        {
          t: 27000,
          o: 109.326,
          h: 109.344,
          l: 109.313,
          c: 109.34,
          v: 502
        },
        {
          t: 27900,
          o: 109.339,
          h: 109.371,
          l: 109.325,
          c: 109.33,
          v: 578
        },
        {
          t: 28800,
          o: 109.332,
          h: 109.359,
          l: 109.298,
          c: 109.35,
          v: 961
        },
        {
          t: 29700,
          o: 109.351,
          h: 109.426,
          l: 109.335,
          c: 109.417,
          v: 909
        },
        {
          t: 30600,
          o: 109.42,
          h: 109.716,
          l: 109.416,
          c: 109.561,
          v: 2745
        },
        {
          t: 31500,
          o: 109.56,
          h: 109.611,
          l: 109.522,
          c: 109.551,
          v: 2191
        },
        {
          t: 32400,
          o: 109.55,
          h: 109.622,
          l: 109.526,
          c: 109.556,
          v: 1642
        },
        {
          t: 33300,
          o: 109.555,
          h: 109.641,
          l: 109.543,
          c: 109.628,
          v: 1486
        },
        {
          t: 34200,
          o: 109.628,
          h: 109.694,
          l: 109.628,
          c: 109.687,
          v: 1492
        },
        {
          t: 35100,
          o: 109.689,
          h: 109.7,
          l: 109.66,
          c: 109.69,
          v: 1203
        },
        {
          t: 36000,
          o: 109.69,
          h: 109.699,
          l: 109.645,
          c: 109.671,
          v: 1128
        },
        {
          t: 36900,
          o: 109.67,
          h: 109.683,
          l: 109.615,
          c: 109.63,
          v: 775
        },
        {
          t: 37800,
          o: 109.631,
          h: 109.671,
          l: 109.628,
          c: 109.647,
          v: 664
        },
        {
          t: 38700,
          o: 109.644,
          h: 109.685,
          l: 109.644,
          c: 109.666,
          v: 527
        },
        {
          t: 39600,
          o: 109.668,
          h: 109.7,
          l: 109.66,
          c: 109.667,
          v: 547
        },
        {
          t: 40500,
          o: 109.667,
          h: 109.709,
          l: 109.664,
          c: 109.703,
          v: 563
        },
        {
          t: 41400,
          o: 109.702,
          h: 109.72,
          l: 109.54,
          c: 109.589,
          v: 1753
        },
        {
          t: 42300,
          o: 109.587,
          h: 109.612,
          l: 109.564,
          c: 109.569,
          v: 965
        },
        {
          t: 43200,
          o: 109.567,
          h: 109.639,
          l: 109.549,
          c: 109.624,
          v: 855
        },
        {
          t: 44100,
          o: 109.626,
          h: 109.671,
          l: 109.613,
          c: 109.648,
          v: 700
        },
        {
          t: 45000,
          o: 109.648,
          h: 109.668,
          l: 109.627,
          c: 109.663,
          v: 617
        },
        {
          t: 45900,
          o: 109.663,
          h: 109.692,
          l: 109.656,
          c: 109.683,
          v: 437
        },
        {
          t: 46800,
          o: 109.682,
          h: 109.726,
          l: 109.67,
          c: 109.708,
          v: 759
        },
        {
          t: 47700,
          o: 109.711,
          h: 109.798,
          l: 109.689,
          c: 109.741,
          v: 1153
        },
        {
          t: 48600,
          o: 109.742,
          h: 109.779,
          l: 109.72,
          c: 109.769,
          v: 845
        },
        {
          t: 49500,
          o: 109.771,
          h: 109.794,
          l: 109.732,
          c: 109.77,
          v: 604
        },
        {
          t: 50400,
          o: 109.77,
          h: 109.773,
          l: 109.728,
          c: 109.736,
          v: 658
        },
        {
          t: 51300,
          o: 109.734,
          h: 109.797,
          l: 109.727,
          c: 109.795,
          v: 467
        },
        {
          t: 52200,
          o: 109.794,
          h: 109.811,
          l: 109.724,
          c: 109.745,
          v: 1319
        },
        {
          t: 53100,
          o: 109.744,
          h: 109.826,
          l: 109.74,
          c: 109.804,
          v: 1344
        },
        {
          t: 54000,
          o: 109.804,
          h: 109.837,
          l: 109.761,
          c: 109.766,
          v: 1369
        },
        {
          t: 54900,
          o: 109.767,
          h: 109.776,
          l: 109.641,
          c: 109.688,
          v: 1551
        },
        {
          t: 55800,
          o: 109.686,
          h: 109.726,
          l: 109.649,
          c: 109.722,
          v: 1664
        },
        {
          t: 56700,
          o: 109.721,
          h: 109.77,
          l: 109.706,
          c: 109.751,
          v: 1429
        },
        {
          t: 57600,
          o: 109.75,
          h: 109.75,
          l: 109.675,
          c: 109.704,
          v: 1100
        },
        {
          t: 58500,
          o: 109.705,
          h: 109.716,
          l: 109.675,
          c: 109.691,
          v: 701
        },
        {
          t: 59400,
          o: 109.692,
          h: 109.714,
          l: 109.66,
          c: 109.69,
          v: 886
        },
        {
          t: 60300,
          o: 109.688,
          h: 109.746,
          l: 109.687,
          c: 109.738,
          v: 576
        },
        {
          t: 61200,
          o: 109.738,
          h: 109.751,
          l: 109.735,
          c: 109.745,
          v: 600
        },
        {
          t: 62100,
          o: 109.746,
          h: 109.755,
          l: 109.721,
          c: 109.727,
          v: 620
        },
        {
          t: 63000,
          o: 109.728,
          h: 109.749,
          l: 109.722,
          c: 109.744,
          v: 519
        },
        {
          t: 63900,
          o: 109.743,
          h: 109.758,
          l: 109.732,
          c: 109.758,
          v: 472
        },
        {
          t: 64800,
          o: 109.757,
          h: 109.757,
          l: 109.733,
          c: 109.752,
          v: 495
        },
        {
          t: 65700,
          o: 109.751,
          h: 109.758,
          l: 109.737,
          c: 109.739,
          v: 481
        },
        {
          t: 66600,
          o: 109.739,
          h: 109.762,
          l: 109.732,
          c: 109.762,
          v: 344
        },
        {
          t: 67500,
          o: 109.761,
          h: 109.768,
          l: 109.754,
          c: 109.758,
          v: 423
        },
        {
          t: 68400,
          o: 109.76,
          h: 109.777,
          l: 109.748,
          c: 109.76,
          v: 418
        },
        {
          t: 69300,
          o: 109.758,
          h: 109.792,
          l: 109.757,
          c: 109.786,
          v: 476
        },
        {
          t: 70200,
          o: 109.785,
          h: 109.813,
          l: 109.768,
          c: 109.813,
          v: 528
        },
        {
          t: 71100,
          o: 109.814,
          h: 109.829,
          l: 109.805,
          c: 109.829,
          v: 372
        },
        {
          t: 72000,
          o: 109.832,
          h: 109.832,
          l: 109.808,
          c: 109.818,
          v: 385
        },
        {
          t: 72900,
          o: 109.819,
          h: 109.828,
          l: 109.813,
          c: 109.817,
          v: 424
        },
        {
          t: 73800,
          o: 109.816,
          h: 109.827,
          l: 109.8,
          c: 109.819,
          v: 659
        },
        {
          t: 74700,
          o: 109.818,
          h: 109.825,
          l: 109.794,
          c: 109.821,
          v: 800
        },
        {
          t: 75600,
          o: 109.823,
          h: 109.837,
          l: 109.798,
          c: 109.832,
          v: 308
        },
        {
          t: 76500,
          o: 109.832,
          h: 109.832,
          l: 109.81,
          c: 109.82,
          v: 179
        },
        {
          t: 77400,
          o: 109.82,
          h: 109.836,
          l: 109.801,
          c: 109.828,
          v: 349
        },
        {
          t: 78300,
          o: 109.829,
          h: 109.839,
          l: 109.812,
          c: 109.812,
          v: 245
        },
        {
          t: 79200,
          o: 109.811,
          h: 109.815,
          l: 109.791,
          c: 109.798,
          v: 387
        },
        {
          t: 80100,
          o: 109.793,
          h: 109.82,
          l: 109.744,
          c: 109.761,
          v: 727
        },
        {
          t: 81000,
          o: 109.761,
          h: 109.799,
          l: 109.761,
          c: 109.796,
          v: 376
        },
        {
          t: 81900,
          o: 109.796,
          h: 109.799,
          l: 109.785,
          c: 109.789,
          v: 512
        },
        {
          t: 82800,
          o: 109.791,
          h: 109.82,
          l: 109.791,
          c: 109.8,
          v: 293
        },
        {
          t: 83700,
          o: 109.8,
          h: 109.807,
          l: 109.788,
          c: 109.799,
          v: 330
        },
        {
          t: 84600,
          o: 109.799,
          h: 109.804,
          l: 109.777,
          c: 109.784,
          v: 244
        },
        {
          t: 85500,
          o: 109.785,
          h: 109.813,
          l: 109.772,
          c: 109.813,
          v: 490
        }
      ]
    },
    {
      d: 1580947200,
      b: [
        {
          t: 0,
          o: 109.812,
          h: 109.861,
          l: 109.795,
          c: 109.847,
          v: 667
        },
        {
          t: 900,
          o: 109.847,
          h: 109.872,
          l: 109.842,
          c: 109.868,
          v: 595
        },
        {
          t: 1800,
          o: 109.868,
          h: 109.894,
          l: 109.86,
          c: 109.872,
          v: 594
        },
        {
          t: 2700,
          o: 109.872,
          h: 109.901,
          l: 109.828,
          c: 109.836,
          v: 993
        },
        {
          t: 3600,
          o: 109.834,
          h: 109.848,
          l: 109.822,
          c: 109.83,
          v: 779
        },
        {
          t: 4500,
          o: 109.831,
          h: 109.892,
          l: 109.831,
          c: 109.868,
          v: 1165
        },
        {
          t: 5400,
          o: 109.868,
          h: 109.868,
          l: 109.808,
          c: 109.831,
          v: 1152
        },
        {
          t: 6300,
          o: 109.831,
          h: 109.841,
          l: 109.807,
          c: 109.826,
          v: 930
        },
        {
          t: 7200,
          o: 109.827,
          h: 109.878,
          l: 109.826,
          c: 109.863,
          v: 772
        },
        {
          t: 8100,
          o: 109.864,
          h: 109.877,
          l: 109.854,
          c: 109.867,
          v: 444
        },
        {
          t: 9000,
          o: 109.869,
          h: 109.883,
          l: 109.86,
          c: 109.883,
          v: 384
        },
        {
          t: 9900,
          o: 109.884,
          h: 109.89,
          l: 109.866,
          c: 109.877,
          v: 476
        },
        {
          t: 10800,
          o: 109.876,
          h: 109.897,
          l: 109.868,
          c: 109.874,
          v: 385
        },
        {
          t: 11700,
          o: 109.873,
          h: 109.874,
          l: 109.852,
          c: 109.854,
          v: 332
        },
        {
          t: 12600,
          o: 109.855,
          h: 109.887,
          l: 109.846,
          c: 109.879,
          v: 295
        },
        {
          t: 13500,
          o: 109.88,
          h: 109.891,
          l: 109.873,
          c: 109.888,
          v: 293
        },
        {
          t: 14400,
          o: 109.887,
          h: 109.956,
          l: 109.88,
          c: 109.945,
          v: 987
        },
        {
          t: 15300,
          o: 109.946,
          h: 109.959,
          l: 109.931,
          c: 109.933,
          v: 558
        },
        {
          t: 16200,
          o: 109.932,
          h: 109.957,
          l: 109.927,
          c: 109.94,
          v: 641
        },
        {
          t: 17100,
          o: 109.943,
          h: 109.96,
          l: 109.937,
          c: 109.952,
          v: 641
        },
        {
          t: 18000,
          o: 109.951,
          h: 109.976,
          l: 109.944,
          c: 109.951,
          v: 705
        },
        {
          t: 18900,
          o: 109.955,
          h: 109.969,
          l: 109.94,
          c: 109.957,
          v: 484
        },
        {
          t: 19800,
          o: 109.958,
          h: 109.968,
          l: 109.943,
          c: 109.957,
          v: 531
        },
        {
          t: 20700,
          o: 109.958,
          h: 109.965,
          l: 109.899,
          c: 109.903,
          v: 799
        },
        {
          t: 21600,
          o: 109.9,
          h: 109.955,
          l: 109.897,
          c: 109.946,
          v: 558
        },
        {
          t: 22500,
          o: 109.945,
          h: 109.964,
          l: 109.932,
          c: 109.957,
          v: 386
        },
        {
          t: 23400,
          o: 109.957,
          h: 109.958,
          l: 109.921,
          c: 109.928,
          v: 309
        },
        {
          t: 24300,
          o: 109.928,
          h: 109.929,
          l: 109.885,
          c: 109.896,
          v: 428
        },
        {
          t: 25200,
          o: 109.898,
          h: 109.898,
          l: 109.846,
          c: 109.868,
          v: 687
        },
        {
          t: 26100,
          o: 109.867,
          h: 109.886,
          l: 109.861,
          c: 109.881,
          v: 533
        },
        {
          t: 27000,
          o: 109.88,
          h: 109.891,
          l: 109.856,
          c: 109.863,
          v: 426
        },
        {
          t: 27900,
          o: 109.862,
          h: 109.915,
          l: 109.847,
          c: 109.904,
          v: 451
        },
        {
          t: 28800,
          o: 109.902,
          h: 109.929,
          l: 109.872,
          c: 109.89,
          v: 895
        },
        {
          t: 29700,
          o: 109.891,
          h: 109.942,
          l: 109.89,
          c: 109.928,
          v: 643
        },
        {
          t: 30600,
          o: 109.925,
          h: 109.941,
          l: 109.912,
          c: 109.925,
          v: 682
        },
        {
          t: 31500,
          o: 109.925,
          h: 109.951,
          l: 109.909,
          c: 109.939,
          v: 529
        },
        {
          t: 32400,
          o: 109.939,
          h: 109.939,
          l: 109.879,
          c: 109.879,
          v: 707
        },
        {
          t: 33300,
          o: 109.882,
          h: 109.884,
          l: 109.787,
          c: 109.793,
          v: 992
        },
        {
          t: 34200,
          o: 109.795,
          h: 109.851,
          l: 109.794,
          c: 109.832,
          v: 732
        },
        {
          t: 35100,
          o: 109.83,
          h: 109.845,
          l: 109.811,
          c: 109.844,
          v: 645
        },
        {
          t: 36000,
          o: 109.844,
          h: 109.844,
          l: 109.775,
          c: 109.778,
          v: 808
        },
        {
          t: 36900,
          o: 109.775,
          h: 109.822,
          l: 109.775,
          c: 109.808,
          v: 475
        },
        {
          t: 37800,
          o: 109.807,
          h: 109.821,
          l: 109.79,
          c: 109.816,
          v: 376
        },
        {
          t: 38700,
          o: 109.816,
          h: 109.834,
          l: 109.781,
          c: 109.79,
          v: 376
        },
        {
          t: 39600,
          o: 109.788,
          h: 109.84,
          l: 109.788,
          c: 109.831,
          v: 450
        },
        {
          t: 40500,
          o: 109.831,
          h: 109.864,
          l: 109.825,
          c: 109.856,
          v: 437
        },
        {
          t: 41400,
          o: 109.856,
          h: 109.897,
          l: 109.855,
          c: 109.894,
          v: 409
        },
        {
          t: 42300,
          o: 109.893,
          h: 109.926,
          l: 109.87,
          c: 109.918,
          v: 365
        },
        {
          t: 43200,
          o: 109.916,
          h: 109.927,
          l: 109.9,
          c: 109.918,
          v: 425
        },
        {
          t: 44100,
          o: 109.916,
          h: 109.916,
          l: 109.878,
          c: 109.88,
          v: 275
        },
        {
          t: 45000,
          o: 109.881,
          h: 109.902,
          l: 109.877,
          c: 109.898,
          v: 223
        },
        {
          t: 45900,
          o: 109.899,
          h: 109.905,
          l: 109.841,
          c: 109.849,
          v: 390
        },
        {
          t: 46800,
          o: 109.851,
          h: 109.851,
          l: 109.816,
          c: 109.837,
          v: 550
        },
        {
          t: 47700,
          o: 109.837,
          h: 109.897,
          l: 109.833,
          c: 109.895,
          v: 547
        },
        {
          t: 48600,
          o: 109.894,
          h: 109.919,
          l: 109.888,
          c: 109.903,
          v: 637
        },
        {
          t: 49500,
          o: 109.905,
          h: 109.908,
          l: 109.843,
          c: 109.876,
          v: 641
        },
        {
          t: 50400,
          o: 109.875,
          h: 109.911,
          l: 109.862,
          c: 109.905,
          v: 595
        },
        {
          t: 51300,
          o: 109.904,
          h: 109.908,
          l: 109.874,
          c: 109.885,
          v: 391
        },
        {
          t: 52200,
          o: 109.88,
          h: 109.911,
          l: 109.813,
          c: 109.836,
          v: 1563
        },
        {
          t: 53100,
          o: 109.836,
          h: 109.927,
          l: 109.836,
          c: 109.927,
          v: 1054
        },
        {
          t: 54000,
          o: 109.926,
          h: 109.955,
          l: 109.902,
          c: 109.93,
          v: 943
        },
        {
          t: 54900,
          o: 109.93,
          h: 109.931,
          l: 109.875,
          c: 109.915,
          v: 942
        },
        {
          t: 55800,
          o: 109.916,
          h: 109.951,
          l: 109.896,
          c: 109.93,
          v: 940
        },
        {
          t: 56700,
          o: 109.929,
          h: 109.982,
          l: 109.927,
          c: 109.967,
          v: 1093
        },
        {
          t: 57600,
          o: 109.965,
          h: 109.979,
          l: 109.927,
          c: 109.956,
          v: 960
        },
        {
          t: 58500,
          o: 109.957,
          h: 109.97,
          l: 109.926,
          c: 109.926,
          v: 622
        },
        {
          t: 59400,
          o: 109.928,
          h: 109.943,
          l: 109.913,
          c: 109.925,
          v: 729
        },
        {
          t: 60300,
          o: 109.925,
          h: 109.928,
          l: 109.887,
          c: 109.89,
          v: 486
        },
        {
          t: 61200,
          o: 109.888,
          h: 109.93,
          l: 109.877,
          c: 109.911,
          v: 608
        },
        {
          t: 62100,
          o: 109.911,
          h: 109.959,
          l: 109.911,
          c: 109.957,
          v: 398
        },
        {
          t: 63000,
          o: 109.956,
          h: 109.956,
          l: 109.938,
          c: 109.951,
          v: 372
        },
        {
          t: 63900,
          o: 109.952,
          h: 109.968,
          l: 109.922,
          c: 109.965,
          v: 436
        },
        {
          t: 64800,
          o: 109.966,
          h: 109.985,
          l: 109.955,
          c: 109.97,
          v: 311
        },
        {
          t: 65700,
          o: 109.969,
          h: 109.974,
          l: 109.95,
          c: 109.952,
          v: 344
        },
        {
          t: 66600,
          o: 109.952,
          h: 109.961,
          l: 109.941,
          c: 109.958,
          v: 371
        },
        {
          t: 67500,
          o: 109.957,
          h: 109.981,
          l: 109.956,
          c: 109.97,
          v: 284
        },
        {
          t: 68400,
          o: 109.971,
          h: 109.993,
          l: 109.97,
          c: 109.988,
          v: 302
        },
        {
          t: 69300,
          o: 109.986,
          h: 109.991,
          l: 109.964,
          c: 109.979,
          v: 385
        },
        {
          t: 70200,
          o: 109.979,
          h: 109.987,
          l: 109.97,
          c: 109.979,
          v: 332
        },
        {
          t: 71100,
          o: 109.98,
          h: 109.991,
          l: 109.967,
          c: 109.97,
          v: 212
        },
        {
          t: 72000,
          o: 109.969,
          h: 109.976,
          l: 109.951,
          c: 109.956,
          v: 415
        },
        {
          t: 72900,
          o: 109.956,
          h: 109.96,
          l: 109.939,
          c: 109.95,
          v: 336
        },
        {
          t: 73800,
          o: 109.95,
          h: 109.975,
          l: 109.949,
          c: 109.966,
          v: 418
        },
        {
          t: 74700,
          o: 109.966,
          h: 110.001,
          l: 109.966,
          c: 109.985,
          v: 479
        },
        {
          t: 75600,
          o: 109.987,
          h: 109.99,
          l: 109.969,
          c: 109.976,
          v: 356
        },
        {
          t: 76500,
          o: 109.974,
          h: 109.974,
          l: 109.959,
          c: 109.972,
          v: 199
        },
        {
          t: 77400,
          o: 109.97,
          h: 109.988,
          l: 109.963,
          c: 109.987,
          v: 112
        },
        {
          t: 78300,
          o: 109.986,
          h: 109.993,
          l: 109.973,
          c: 109.983,
          v: 225
        },
        {
          t: 79200,
          o: 109.982,
          h: 110.012,
          l: 109.971,
          c: 110.009,
          v: 193
        },
        {
          t: 80100,
          o: 110.006,
          h: 110.016,
          l: 109.979,
          c: 109.983,
          v: 857
        },
        {
          t: 81000,
          o: 109.982,
          h: 109.99,
          l: 109.979,
          c: 109.985,
          v: 454
        },
        {
          t: 81900,
          o: 109.985,
          h: 109.987,
          l: 109.972,
          c: 109.977,
          v: 242
        },
        {
          t: 82800,
          o: 109.978,
          h: 110.009,
          l: 109.978,
          c: 109.981,
          v: 473
        },
        {
          t: 83700,
          o: 109.981,
          h: 109.99,
          l: 109.968,
          c: 109.984,
          v: 278
        },
        {
          t: 84600,
          o: 109.984,
          h: 109.986,
          l: 109.958,
          c: 109.965,
          v: 239
        },
        {
          t: 85500,
          o: 109.965,
          h: 109.972,
          l: 109.953,
          c: 109.957,
          v: 383
        }
      ]
    },
    {
      d: 1581033600,
      b: [
        {
          t: 0,
          o: 109.957,
          h: 109.971,
          l: 109.942,
          c: 109.946,
          v: 496
        },
        {
          t: 900,
          o: 109.948,
          h: 109.981,
          l: 109.925,
          c: 109.936,
          v: 551
        },
        {
          t: 1800,
          o: 109.935,
          h: 109.942,
          l: 109.833,
          c: 109.837,
          v: 720
        },
        {
          t: 2700,
          o: 109.839,
          h: 109.882,
          l: 109.807,
          c: 109.866,
          v: 1182
        },
        {
          t: 3600,
          o: 109.866,
          h: 109.887,
          l: 109.833,
          c: 109.862,
          v: 1577
        },
        {
          t: 4500,
          o: 109.863,
          h: 109.891,
          l: 109.853,
          c: 109.879,
          v: 1734
        },
        {
          t: 5400,
          o: 109.879,
          h: 109.911,
          l: 109.861,
          c: 109.904,
          v: 1787
        },
        {
          t: 6300,
          o: 109.903,
          h: 109.922,
          l: 109.888,
          c: 109.908,
          v: 1568
        },
        {
          t: 7200,
          o: 109.91,
          h: 109.928,
          l: 109.9,
          c: 109.908,
          v: 1125
        },
        {
          t: 8100,
          o: 109.907,
          h: 109.931,
          l: 109.891,
          c: 109.921,
          v: 980
        },
        {
          t: 9000,
          o: 109.922,
          h: 109.931,
          l: 109.919,
          c: 109.928,
          v: 845
        },
        {
          t: 9900,
          o: 109.929,
          h: 109.929,
          l: 109.89,
          c: 109.895,
          v: 810
        },
        {
          t: 10800,
          o: 109.894,
          h: 109.918,
          l: 109.894,
          c: 109.91,
          v: 918
        },
        {
          t: 11700,
          o: 109.912,
          h: 109.917,
          l: 109.897,
          c: 109.897,
          v: 677
        },
        {
          t: 12600,
          o: 109.898,
          h: 109.908,
          l: 109.888,
          c: 109.908,
          v: 664
        },
        {
          t: 13500,
          o: 109.909,
          h: 109.912,
          l: 109.898,
          c: 109.903,
          v: 547
        },
        {
          t: 14400,
          o: 109.904,
          h: 109.912,
          l: 109.894,
          c: 109.895,
          v: 476
        },
        {
          t: 15300,
          o: 109.894,
          h: 109.915,
          l: 109.89,
          c: 109.914,
          v: 376
        },
        {
          t: 16200,
          o: 109.915,
          h: 109.915,
          l: 109.897,
          c: 109.903,
          v: 343
        },
        {
          t: 17100,
          o: 109.902,
          h: 109.919,
          l: 109.902,
          c: 109.912,
          v: 477
        },
        {
          t: 18000,
          o: 109.912,
          h: 109.92,
          l: 109.891,
          c: 109.898,
          v: 724
        },
        {
          t: 18900,
          o: 109.899,
          h: 109.901,
          l: 109.878,
          c: 109.891,
          v: 730
        },
        {
          t: 19800,
          o: 109.892,
          h: 109.898,
          l: 109.888,
          c: 109.895,
          v: 627
        },
        {
          t: 20700,
          o: 109.895,
          h: 109.901,
          l: 109.868,
          c: 109.878,
          v: 703
        },
        {
          t: 21600,
          o: 109.879,
          h: 109.889,
          l: 109.864,
          c: 109.887,
          v: 663
        },
        {
          t: 22500,
          o: 109.888,
          h: 109.974,
          l: 109.887,
          c: 109.966,
          v: 674
        },
        {
          t: 23400,
          o: 109.965,
          h: 109.966,
          l: 109.94,
          c: 109.95,
          v: 438
        },
        {
          t: 24300,
          o: 109.952,
          h: 109.967,
          l: 109.946,
          c: 109.964,
          v: 388
        },
        {
          t: 25200,
          o: 109.967,
          h: 109.987,
          l: 109.936,
          c: 109.963,
          v: 572
        },
        {
          t: 26100,
          o: 109.967,
          h: 109.98,
          l: 109.902,
          c: 109.909,
          v: 909
        },
        {
          t: 27000,
          o: 109.907,
          h: 109.91,
          l: 109.877,
          c: 109.902,
          v: 1259
        },
        {
          t: 27900,
          o: 109.901,
          h: 109.921,
          l: 109.889,
          c: 109.918,
          v: 876
        },
        {
          t: 28800,
          o: 109.925,
          h: 109.927,
          l: 109.855,
          c: 109.897,
          v: 1644
        },
        {
          t: 29700,
          o: 109.897,
          h: 109.911,
          l: 109.87,
          c: 109.882,
          v: 919
        },
        {
          t: 30600,
          o: 109.883,
          h: 109.942,
          l: 109.881,
          c: 109.934,
          v: 760
        },
        {
          t: 31500,
          o: 109.934,
          h: 109.941,
          l: 109.897,
          c: 109.934,
          v: 475
        },
        {
          t: 32400,
          o: 109.934,
          h: 109.945,
          l: 109.902,
          c: 109.925,
          v: 438
        },
        {
          t: 33300,
          o: 109.925,
          h: 109.941,
          l: 109.862,
          c: 109.862,
          v: 485
        },
        {
          t: 34200,
          o: 109.862,
          h: 109.88,
          l: 109.836,
          c: 109.845,
          v: 651
        },
        {
          t: 35100,
          o: 109.845,
          h: 109.846,
          l: 109.775,
          c: 109.777,
          v: 704
        },
        {
          t: 36000,
          o: 109.776,
          h: 109.811,
          l: 109.698,
          c: 109.719,
          v: 1036
        },
        {
          t: 36900,
          o: 109.718,
          h: 109.767,
          l: 109.665,
          c: 109.757,
          v: 905
        },
        {
          t: 37800,
          o: 109.757,
          h: 109.767,
          l: 109.727,
          c: 109.762,
          v: 715
        },
        {
          t: 38700,
          o: 109.762,
          h: 109.777,
          l: 109.743,
          c: 109.763,
          v: 512
        },
        {
          t: 39600,
          o: 109.763,
          h: 109.801,
          l: 109.731,
          c: 109.793,
          v: 626
        },
        {
          t: 40500,
          o: 109.793,
          h: 109.837,
          l: 109.792,
          c: 109.829,
          v: 566
        },
        {
          t: 41400,
          o: 109.828,
          h: 109.841,
          l: 109.81,
          c: 109.816,
          v: 359
        },
        {
          t: 42300,
          o: 109.815,
          h: 109.83,
          l: 109.806,
          c: 109.821,
          v: 361
        },
        {
          t: 43200,
          o: 109.822,
          h: 109.864,
          l: 109.822,
          c: 109.857,
          v: 386
        },
        {
          t: 44100,
          o: 109.857,
          h: 109.859,
          l: 109.811,
          c: 109.814,
          v: 379
        },
        {
          t: 45000,
          o: 109.815,
          h: 109.85,
          l: 109.814,
          c: 109.846,
          v: 307
        },
        {
          t: 45900,
          o: 109.847,
          h: 109.88,
          l: 109.841,
          c: 109.867,
          v: 373
        },
        {
          t: 46800,
          o: 109.868,
          h: 109.882,
          l: 109.829,
          c: 109.842,
          v: 465
        },
        {
          t: 47700,
          o: 109.841,
          h: 109.917,
          l: 109.834,
          c: 109.873,
          v: 725
        },
        {
          t: 48600,
          o: 109.873,
          h: 109.997,
          l: 109.745,
          c: 109.772,
          v: 2405
        },
        {
          t: 49500,
          o: 109.776,
          h: 109.805,
          l: 109.739,
          c: 109.768,
          v: 1161
        },
        {
          t: 50400,
          o: 109.767,
          h: 109.837,
          l: 109.76,
          c: 109.762,
          v: 959
        },
        {
          t: 51300,
          o: 109.762,
          h: 109.783,
          l: 109.686,
          c: 109.716,
          v: 902
        },
        {
          t: 52200,
          o: 109.716,
          h: 109.716,
          l: 109.532,
          c: 109.572,
          v: 2217
        },
        {
          t: 53100,
          o: 109.572,
          h: 109.639,
          l: 109.545,
          c: 109.573,
          v: 1199
        },
        {
          t: 54000,
          o: 109.573,
          h: 109.623,
          l: 109.526,
          c: 109.6,
          v: 1265
        },
        {
          t: 54900,
          o: 109.598,
          h: 109.635,
          l: 109.57,
          c: 109.63,
          v: 923
        },
        {
          t: 55800,
          o: 109.632,
          h: 109.681,
          l: 109.61,
          c: 109.67,
          v: 1114
        },
        {
          t: 56700,
          o: 109.671,
          h: 109.792,
          l: 109.657,
          c: 109.77,
          v: 1154
        },
        {
          t: 57600,
          o: 109.769,
          h: 109.781,
          l: 109.715,
          c: 109.75,
          v: 1033
        },
        {
          t: 58500,
          o: 109.747,
          h: 109.832,
          l: 109.741,
          c: 109.818,
          v: 737
        },
        {
          t: 59400,
          o: 109.819,
          h: 109.839,
          l: 109.777,
          c: 109.787,
          v: 686
        },
        {
          t: 60300,
          o: 109.788,
          h: 109.801,
          l: 109.756,
          c: 109.801,
          v: 474
        },
        {
          t: 61200,
          o: 109.799,
          h: 109.827,
          l: 109.781,
          c: 109.822,
          v: 397
        },
        {
          t: 62100,
          o: 109.823,
          h: 109.83,
          l: 109.801,
          c: 109.817,
          v: 486
        },
        {
          t: 63000,
          o: 109.817,
          h: 109.82,
          l: 109.785,
          c: 109.786,
          v: 597
        },
        {
          t: 63900,
          o: 109.785,
          h: 109.801,
          l: 109.777,
          c: 109.801,
          v: 494
        },
        {
          t: 64800,
          o: 109.799,
          h: 109.8,
          l: 109.783,
          c: 109.798,
          v: 410
        },
        {
          t: 65700,
          o: 109.798,
          h: 109.805,
          l: 109.791,
          c: 109.792,
          v: 306
        },
        {
          t: 66600,
          o: 109.792,
          h: 109.82,
          l: 109.751,
          c: 109.771,
          v: 888
        },
        {
          t: 67500,
          o: 109.77,
          h: 109.774,
          l: 109.741,
          c: 109.741,
          v: 455
        },
        {
          t: 68400,
          o: 109.741,
          h: 109.741,
          l: 109.701,
          c: 109.71,
          v: 879
        },
        {
          t: 69300,
          o: 109.711,
          h: 109.731,
          l: 109.694,
          c: 109.724,
          v: 914
        },
        {
          t: 70200,
          o: 109.725,
          h: 109.756,
          l: 109.707,
          c: 109.75,
          v: 526
        },
        {
          t: 71100,
          o: 109.75,
          h: 109.75,
          l: 109.721,
          c: 109.726,
          v: 658
        },
        {
          t: 72000,
          o: 109.727,
          h: 109.761,
          l: 109.726,
          c: 109.743,
          v: 922
        },
        {
          t: 72900,
          o: 109.743,
          h: 109.769,
          l: 109.741,
          c: 109.763,
          v: 692
        },
        {
          t: 73800,
          o: 109.762,
          h: 109.764,
          l: 109.748,
          c: 109.757,
          v: 647
        },
        {
          t: 74700,
          o: 109.756,
          h: 109.772,
          l: 109.75,
          c: 109.762,
          v: 543
        },
        {
          t: 75600,
          o: 109.761,
          h: 109.764,
          l: 109.746,
          c: 109.747,
          v: 376
        },
        {
          t: 76500,
          o: 109.747,
          h: 109.771,
          l: 109.743,
          c: 109.762,
          v: 147
        },
        {
          t: 77400,
          o: 109.763,
          h: 109.794,
          l: 109.763,
          c: 109.769,
          v: 240
        }
      ]
    },
    {
      d: 1581206400,
      b: [
        {
          t: 79200,
          o: 109.7,
          h: 109.716,
          l: 109.691,
          c: 109.712,
          v: 217
        },
        {
          t: 80100,
          o: 109.71,
          h: 109.715,
          l: 109.7,
          c: 109.708,
          v: 469
        },
        {
          t: 81000,
          o: 109.709,
          h: 109.747,
          l: 109.704,
          c: 109.738,
          v: 335
        },
        {
          t: 81900,
          o: 109.741,
          h: 109.749,
          l: 109.706,
          c: 109.711,
          v: 137
        },
        {
          t: 82800,
          o: 109.708,
          h: 109.774,
          l: 109.694,
          c: 109.74,
          v: 706
        },
        {
          t: 83700,
          o: 109.74,
          h: 109.756,
          l: 109.691,
          c: 109.698,
          v: 785
        },
        {
          t: 84600,
          o: 109.698,
          h: 109.699,
          l: 109.555,
          c: 109.613,
          v: 1459
        },
        {
          t: 85500,
          o: 109.615,
          h: 109.672,
          l: 109.608,
          c: 109.612,
          v: 975
        }
      ]
    },
    {
      d: 1581292800,
      b: [
        {
          t: 0,
          o: 109.613,
          h: 109.666,
          l: 109.606,
          c: 109.609,
          v: 1076
        },
        {
          t: 900,
          o: 109.613,
          h: 109.675,
          l: 109.601,
          c: 109.668,
          v: 1004
        },
        {
          t: 1800,
          o: 109.668,
          h: 109.706,
          l: 109.667,
          c: 109.687,
          v: 764
        },
        {
          t: 2700,
          o: 109.687,
          h: 109.69,
          l: 109.6,
          c: 109.648,
          v: 1091
        },
        {
          t: 3600,
          o: 109.647,
          h: 109.671,
          l: 109.624,
          c: 109.655,
          v: 1085
        },
        {
          t: 4500,
          o: 109.656,
          h: 109.703,
          l: 109.653,
          c: 109.677,
          v: 1106
        },
        {
          t: 5400,
          o: 109.676,
          h: 109.788,
          l: 109.663,
          c: 109.775,
          v: 1364
        },
        {
          t: 6300,
          o: 109.776,
          h: 109.806,
          l: 109.758,
          c: 109.777,
          v: 1289
        },
        {
          t: 7200,
          o: 109.78,
          h: 109.837,
          l: 109.77,
          c: 109.834,
          v: 1047
        },
        {
          t: 8100,
          o: 109.833,
          h: 109.859,
          l: 109.814,
          c: 109.826,
          v: 1098
        },
        {
          t: 9000,
          o: 109.826,
          h: 109.872,
          l: 109.812,
          c: 109.843,
          v: 1024
        },
        {
          t: 9900,
          o: 109.842,
          h: 109.848,
          l: 109.815,
          c: 109.828,
          v: 521
        },
        {
          t: 10800,
          o: 109.827,
          h: 109.847,
          l: 109.827,
          c: 109.833,
          v: 497
        },
        {
          t: 11700,
          o: 109.831,
          h: 109.831,
          l: 109.789,
          c: 109.798,
          v: 569
        },
        {
          t: 12600,
          o: 109.799,
          h: 109.815,
          l: 109.78,
          c: 109.801,
          v: 414
        },
        {
          t: 13500,
          o: 109.804,
          h: 109.807,
          l: 109.759,
          c: 109.761,
          v: 373
        },
        {
          t: 14400,
          o: 109.761,
          h: 109.77,
          l: 109.743,
          c: 109.758,
          v: 353
        },
        {
          t: 15300,
          o: 109.758,
          h: 109.767,
          l: 109.744,
          c: 109.756,
          v: 216
        },
        {
          t: 16200,
          o: 109.755,
          h: 109.769,
          l: 109.738,
          c: 109.768,
          v: 297
        },
        {
          t: 17100,
          o: 109.77,
          h: 109.775,
          l: 109.724,
          c: 109.728,
          v: 510
        },
        {
          t: 18000,
          o: 109.728,
          h: 109.751,
          l: 109.727,
          c: 109.745,
          v: 359
        },
        {
          t: 18900,
          o: 109.745,
          h: 109.773,
          l: 109.734,
          c: 109.767,
          v: 372
        },
        {
          t: 19800,
          o: 109.768,
          h: 109.793,
          l: 109.762,
          c: 109.779,
          v: 578
        },
        {
          t: 20700,
          o: 109.776,
          h: 109.779,
          l: 109.755,
          c: 109.771,
          v: 423
        },
        {
          t: 21600,
          o: 109.768,
          h: 109.805,
          l: 109.756,
          c: 109.796,
          v: 492
        },
        {
          t: 22500,
          o: 109.795,
          h: 109.829,
          l: 109.79,
          c: 109.819,
          v: 531
        },
        {
          t: 23400,
          o: 109.82,
          h: 109.824,
          l: 109.802,
          c: 109.809,
          v: 316
        },
        {
          t: 24300,
          o: 109.81,
          h: 109.828,
          l: 109.808,
          c: 109.817,
          v: 382
        },
        {
          t: 25200,
          o: 109.819,
          h: 109.835,
          l: 109.754,
          c: 109.777,
          v: 695
        },
        {
          t: 26100,
          o: 109.776,
          h: 109.817,
          l: 109.773,
          c: 109.808,
          v: 435
        },
        {
          t: 27000,
          o: 109.808,
          h: 109.846,
          l: 109.799,
          c: 109.82,
          v: 743
        },
        {
          t: 27900,
          o: 109.819,
          h: 109.829,
          l: 109.787,
          c: 109.796,
          v: 671
        },
        {
          t: 28800,
          o: 109.795,
          h: 109.838,
          l: 109.767,
          c: 109.833,
          v: 1088
        },
        {
          t: 29700,
          o: 109.832,
          h: 109.841,
          l: 109.757,
          c: 109.767,
          v: 925
        },
        {
          t: 30600,
          o: 109.766,
          h: 109.771,
          l: 109.721,
          c: 109.724,
          v: 916
        },
        {
          t: 31500,
          o: 109.723,
          h: 109.777,
          l: 109.716,
          c: 109.777,
          v: 780
        },
        {
          t: 32400,
          o: 109.776,
          h: 109.817,
          l: 109.772,
          c: 109.79,
          v: 746
        },
        {
          t: 33300,
          o: 109.791,
          h: 109.791,
          l: 109.756,
          c: 109.768,
          v: 506
        },
        {
          t: 34200,
          o: 109.765,
          h: 109.779,
          l: 109.746,
          c: 109.751,
          v: 388
        },
        {
          t: 35100,
          o: 109.747,
          h: 109.756,
          l: 109.719,
          c: 109.722,
          v: 444
        },
        {
          t: 36000,
          o: 109.72,
          h: 109.75,
          l: 109.704,
          c: 109.745,
          v: 446
        },
        {
          t: 36900,
          o: 109.744,
          h: 109.744,
          l: 109.723,
          c: 109.726,
          v: 458
        },
        {
          t: 37800,
          o: 109.724,
          h: 109.807,
          l: 109.7,
          c: 109.776,
          v: 905
        },
        {
          t: 38700,
          o: 109.778,
          h: 109.782,
          l: 109.736,
          c: 109.763,
          v: 1018
        },
        {
          t: 39600,
          o: 109.768,
          h: 109.784,
          l: 109.754,
          c: 109.767,
          v: 614
        },
        {
          t: 40500,
          o: 109.768,
          h: 109.798,
          l: 109.758,
          c: 109.796,
          v: 419
        },
        {
          t: 41400,
          o: 109.795,
          h: 109.805,
          l: 109.781,
          c: 109.792,
          v: 461
        },
        {
          t: 42300,
          o: 109.792,
          h: 109.792,
          l: 109.758,
          c: 109.772,
          v: 310
        },
        {
          t: 43200,
          o: 109.772,
          h: 109.79,
          l: 109.739,
          c: 109.774,
          v: 502
        },
        {
          t: 44100,
          o: 109.774,
          h: 109.786,
          l: 109.757,
          c: 109.757,
          v: 394
        },
        {
          t: 45000,
          o: 109.757,
          h: 109.764,
          l: 109.743,
          c: 109.754,
          v: 389
        },
        {
          t: 45900,
          o: 109.756,
          h: 109.759,
          l: 109.723,
          c: 109.741,
          v: 621
        },
        {
          t: 46800,
          o: 109.742,
          h: 109.742,
          l: 109.697,
          c: 109.729,
          v: 624
        },
        {
          t: 47700,
          o: 109.728,
          h: 109.779,
          l: 109.728,
          c: 109.754,
          v: 592
        },
        {
          t: 48600,
          o: 109.754,
          h: 109.764,
          l: 109.727,
          c: 109.742,
          v: 498
        },
        {
          t: 49500,
          o: 109.741,
          h: 109.751,
          l: 109.719,
          c: 109.734,
          v: 514
        },
        {
          t: 50400,
          o: 109.734,
          h: 109.747,
          l: 109.657,
          c: 109.661,
          v: 915
        },
        {
          t: 51300,
          o: 109.659,
          h: 109.679,
          l: 109.647,
          c: 109.659,
          v: 544
        },
        {
          t: 52200,
          o: 109.66,
          h: 109.726,
          l: 109.647,
          c: 109.721,
          v: 1302
        },
        {
          t: 53100,
          o: 109.722,
          h: 109.767,
          l: 109.701,
          c: 109.764,
          v: 822
        },
        {
          t: 54000,
          o: 109.763,
          h: 109.765,
          l: 109.712,
          c: 109.715,
          v: 749
        },
        {
          t: 54900,
          o: 109.716,
          h: 109.739,
          l: 109.69,
          c: 109.709,
          v: 671
        },
        {
          t: 55800,
          o: 109.709,
          h: 109.757,
          l: 109.704,
          c: 109.734,
          v: 660
        },
        {
          t: 56700,
          o: 109.736,
          h: 109.751,
          l: 109.707,
          c: 109.727,
          v: 639
        },
        {
          t: 57600,
          o: 109.73,
          h: 109.757,
          l: 109.722,
          c: 109.754,
          v: 446
        },
        {
          t: 58500,
          o: 109.756,
          h: 109.781,
          l: 109.746,
          c: 109.776,
          v: 565
        },
        {
          t: 59400,
          o: 109.775,
          h: 109.793,
          l: 109.761,
          c: 109.766,
          v: 520
        },
        {
          t: 60300,
          o: 109.766,
          h: 109.781,
          l: 109.756,
          c: 109.762,
          v: 468
        },
        {
          t: 61200,
          o: 109.762,
          h: 109.763,
          l: 109.731,
          c: 109.745,
          v: 474
        },
        {
          t: 62100,
          o: 109.746,
          h: 109.746,
          l: 109.704,
          c: 109.715,
          v: 548
        },
        {
          t: 63000,
          o: 109.715,
          h: 109.727,
          l: 109.635,
          c: 109.671,
          v: 775
        },
        {
          t: 63900,
          o: 109.672,
          h: 109.686,
          l: 109.66,
          c: 109.68,
          v: 511
        },
        {
          t: 64800,
          o: 109.681,
          h: 109.71,
          l: 109.681,
          c: 109.704,
          v: 439
        },
        {
          t: 65700,
          o: 109.704,
          h: 109.712,
          l: 109.675,
          c: 109.681,
          v: 337
        },
        {
          t: 66600,
          o: 109.68,
          h: 109.692,
          l: 109.671,
          c: 109.681,
          v: 259
        },
        {
          t: 67500,
          o: 109.68,
          h: 109.698,
          l: 109.676,
          c: 109.678,
          v: 299
        },
        {
          t: 68400,
          o: 109.678,
          h: 109.689,
          l: 109.66,
          c: 109.673,
          v: 509
        },
        {
          t: 69300,
          o: 109.674,
          h: 109.674,
          l: 109.656,
          c: 109.674,
          v: 362
        },
        {
          t: 70200,
          o: 109.674,
          h: 109.674,
          l: 109.66,
          c: 109.661,
          v: 206
        },
        {
          t: 71100,
          o: 109.661,
          h: 109.692,
          l: 109.658,
          c: 109.692,
          v: 355
        },
        {
          t: 72000,
          o: 109.693,
          h: 109.706,
          l: 109.674,
          c: 109.697,
          v: 492
        },
        {
          t: 72900,
          o: 109.697,
          h: 109.736,
          l: 109.692,
          c: 109.734,
          v: 379
        },
        {
          t: 73800,
          o: 109.735,
          h: 109.745,
          l: 109.708,
          c: 109.738,
          v: 601
        },
        {
          t: 74700,
          o: 109.737,
          h: 109.751,
          l: 109.725,
          c: 109.74,
          v: 718
        },
        {
          t: 75600,
          o: 109.734,
          h: 109.752,
          l: 109.722,
          c: 109.749,
          v: 316
        },
        {
          t: 76500,
          o: 109.748,
          h: 109.757,
          l: 109.74,
          c: 109.751,
          v: 232
        },
        {
          t: 77400,
          o: 109.75,
          h: 109.752,
          l: 109.739,
          c: 109.75,
          v: 503
        },
        {
          t: 78300,
          o: 109.749,
          h: 109.771,
          l: 109.747,
          c: 109.769,
          v: 200
        },
        {
          t: 79200,
          o: 109.768,
          h: 109.774,
          l: 109.732,
          c: 109.749,
          v: 551
        },
        {
          t: 80100,
          o: 109.746,
          h: 109.766,
          l: 109.745,
          c: 109.757,
          v: 463
        },
        {
          t: 81000,
          o: 109.757,
          h: 109.779,
          l: 109.753,
          c: 109.763,
          v: 1037
        },
        {
          t: 81900,
          o: 109.762,
          h: 109.775,
          l: 109.746,
          c: 109.75,
          v: 242
        },
        {
          t: 82800,
          o: 109.752,
          h: 109.772,
          l: 109.742,
          c: 109.747,
          v: 454
        },
        {
          t: 83700,
          o: 109.747,
          h: 109.773,
          l: 109.747,
          c: 109.769,
          v: 592
        },
        {
          t: 84600,
          o: 109.768,
          h: 109.771,
          l: 109.749,
          c: 109.762,
          v: 317
        },
        {
          t: 85500,
          o: 109.763,
          h: 109.776,
          l: 109.758,
          c: 109.763,
          v: 314
        }
      ]
    },
    {
      d: 1581379200,
      b: [
        {
          t: 0,
          o: 109.76,
          h: 109.763,
          l: 109.734,
          c: 109.746,
          v: 425
        },
        {
          t: 900,
          o: 109.748,
          h: 109.763,
          l: 109.742,
          c: 109.746,
          v: 414
        },
        {
          t: 1800,
          o: 109.747,
          h: 109.778,
          l: 109.747,
          c: 109.771,
          v: 336
        },
        {
          t: 2700,
          o: 109.77,
          h: 109.826,
          l: 109.767,
          c: 109.809,
          v: 575
        },
        {
          t: 3600,
          o: 109.81,
          h: 109.822,
          l: 109.797,
          c: 109.809,
          v: 401
        },
        {
          t: 4500,
          o: 109.81,
          h: 109.848,
          l: 109.808,
          c: 109.823,
          v: 634
        },
        {
          t: 5400,
          o: 109.822,
          h: 109.831,
          l: 109.81,
          c: 109.81,
          v: 551
        },
        {
          t: 6300,
          o: 109.809,
          h: 109.843,
          l: 109.809,
          c: 109.841,
          v: 476
        },
        {
          t: 7200,
          o: 109.842,
          h: 109.849,
          l: 109.826,
          c: 109.837,
          v: 369
        },
        {
          t: 8100,
          o: 109.837,
          h: 109.885,
          l: 109.832,
          c: 109.871,
          v: 463
        },
        {
          t: 9000,
          o: 109.873,
          h: 109.894,
          l: 109.871,
          c: 109.887,
          v: 371
        },
        {
          t: 9900,
          o: 109.887,
          h: 109.891,
          l: 109.85,
          c: 109.87,
          v: 327
        },
        {
          t: 10800,
          o: 109.869,
          h: 109.884,
          l: 109.861,
          c: 109.865,
          v: 258
        },
        {
          t: 11700,
          o: 109.865,
          h: 109.867,
          l: 109.838,
          c: 109.848,
          v: 282
        },
        {
          t: 12600,
          o: 109.85,
          h: 109.869,
          l: 109.84,
          c: 109.868,
          v: 192
        },
        {
          t: 13500,
          o: 109.868,
          h: 109.877,
          l: 109.86,
          c: 109.869,
          v: 118
        },
        {
          t: 14400,
          o: 109.869,
          h: 109.87,
          l: 109.859,
          c: 109.859,
          v: 116
        },
        {
          t: 15300,
          o: 109.859,
          h: 109.862,
          l: 109.854,
          c: 109.86,
          v: 74
        },
        {
          t: 16200,
          o: 109.86,
          h: 109.877,
          l: 109.86,
          c: 109.877,
          v: 89
        },
        {
          t: 17100,
          o: 109.878,
          h: 109.88,
          l: 109.87,
          c: 109.876,
          v: 132
        },
        {
          t: 18000,
          o: 109.875,
          h: 109.883,
          l: 109.867,
          c: 109.883,
          v: 147
        },
        {
          t: 18900,
          o: 109.884,
          h: 109.893,
          l: 109.883,
          c: 109.891,
          v: 198
        },
        {
          t: 19800,
          o: 109.892,
          h: 109.901,
          l: 109.869,
          c: 109.876,
          v: 286
        },
        {
          t: 20700,
          o: 109.875,
          h: 109.9,
          l: 109.873,
          c: 109.889,
          v: 231
        },
        {
          t: 21600,
          o: 109.889,
          h: 109.923,
          l: 109.888,
          c: 109.913,
          v: 468
        },
        {
          t: 22500,
          o: 109.913,
          h: 109.92,
          l: 109.892,
          c: 109.918,
          v: 278
        },
        {
          t: 23400,
          o: 109.918,
          h: 109.926,
          l: 109.906,
          c: 109.911,
          v: 240
        },
        {
          t: 24300,
          o: 109.911,
          h: 109.919,
          l: 109.89,
          c: 109.893,
          v: 244
        },
        {
          t: 25200,
          o: 109.889,
          h: 109.904,
          l: 109.877,
          c: 109.893,
          v: 521
        },
        {
          t: 26100,
          o: 109.893,
          h: 109.897,
          l: 109.855,
          c: 109.888,
          v: 489
        },
        {
          t: 27000,
          o: 109.889,
          h: 109.89,
          l: 109.846,
          c: 109.862,
          v: 379
        },
        {
          t: 27900,
          o: 109.862,
          h: 109.907,
          l: 109.858,
          c: 109.907,
          v: 410
        },
        {
          t: 28800,
          o: 109.906,
          h: 109.941,
          l: 109.896,
          c: 109.908,
          v: 1048
        },
        {
          t: 29700,
          o: 109.91,
          h: 109.923,
          l: 109.886,
          c: 109.908,
          v: 605
        },
        {
          t: 30600,
          o: 109.907,
          h: 109.907,
          l: 109.871,
          c: 109.872,
          v: 546
        },
        {
          t: 31500,
          o: 109.872,
          h: 109.885,
          l: 109.856,
          c: 109.873,
          v: 469
        },
        {
          t: 32400,
          o: 109.871,
          h: 109.873,
          l: 109.827,
          c: 109.842,
          v: 602
        },
        {
          t: 33300,
          o: 109.842,
          h: 109.876,
          l: 109.842,
          c: 109.857,
          v: 551
        },
        {
          t: 34200,
          o: 109.857,
          h: 109.89,
          l: 109.842,
          c: 109.861,
          v: 671
        },
        {
          t: 35100,
          o: 109.86,
          h: 109.866,
          l: 109.838,
          c: 109.859,
          v: 377
        },
        {
          t: 36000,
          o: 109.86,
          h: 109.87,
          l: 109.827,
          c: 109.849,
          v: 328
        },
        {
          t: 36900,
          o: 109.85,
          h: 109.87,
          l: 109.842,
          c: 109.857,
          v: 340
        },
        {
          t: 37800,
          o: 109.858,
          h: 109.879,
          l: 109.847,
          c: 109.876,
          v: 365
        },
        {
          t: 38700,
          o: 109.876,
          h: 109.886,
          l: 109.864,
          c: 109.87,
          v: 381
        },
        {
          t: 39600,
          o: 109.869,
          h: 109.875,
          l: 109.847,
          c: 109.863,
          v: 361
        },
        {
          t: 40500,
          o: 109.865,
          h: 109.865,
          l: 109.833,
          c: 109.839,
          v: 233
        },
        {
          t: 41400,
          o: 109.839,
          h: 109.849,
          l: 109.829,
          c: 109.838,
          v: 330
        },
        {
          t: 42300,
          o: 109.837,
          h: 109.86,
          l: 109.836,
          c: 109.845,
          v: 286
        },
        {
          t: 43200,
          o: 109.844,
          h: 109.856,
          l: 109.838,
          c: 109.84,
          v: 293
        },
        {
          t: 44100,
          o: 109.84,
          h: 109.844,
          l: 109.829,
          c: 109.831,
          v: 325
        },
        {
          t: 45000,
          o: 109.831,
          h: 109.836,
          l: 109.809,
          c: 109.813,
          v: 346
        },
        {
          t: 45900,
          o: 109.816,
          h: 109.857,
          l: 109.816,
          c: 109.84,
          v: 521
        },
        {
          t: 46800,
          o: 109.843,
          h: 109.844,
          l: 109.809,
          c: 109.823,
          v: 520
        },
        {
          t: 47700,
          o: 109.824,
          h: 109.829,
          l: 109.753,
          c: 109.79,
          v: 664
        },
        {
          t: 48600,
          o: 109.788,
          h: 109.816,
          l: 109.744,
          c: 109.757,
          v: 1032
        },
        {
          t: 49500,
          o: 109.758,
          h: 109.779,
          l: 109.744,
          c: 109.776,
          v: 612
        },
        {
          t: 50400,
          o: 109.776,
          h: 109.806,
          l: 109.756,
          c: 109.771,
          v: 783
        },
        {
          t: 51300,
          o: 109.77,
          h: 109.809,
          l: 109.767,
          c: 109.797,
          v: 583
        },
        {
          t: 52200,
          o: 109.796,
          h: 109.806,
          l: 109.741,
          c: 109.784,
          v: 1368
        },
        {
          t: 53100,
          o: 109.785,
          h: 109.819,
          l: 109.778,
          c: 109.796,
          v: 932
        },
        {
          t: 54000,
          o: 109.796,
          h: 109.87,
          l: 109.795,
          c: 109.87,
          v: 914
        },
        {
          t: 54900,
          o: 109.869,
          h: 109.909,
          l: 109.849,
          c: 109.898,
          v: 830
        },
        {
          t: 55800,
          o: 109.899,
          h: 109.956,
          l: 109.874,
          c: 109.935,
          v: 952
        },
        {
          t: 56700,
          o: 109.935,
          h: 109.936,
          l: 109.888,
          c: 109.9,
          v: 790
        },
        {
          t: 57600,
          o: 109.901,
          h: 109.929,
          l: 109.86,
          c: 109.86,
          v: 786
        },
        {
          t: 58500,
          o: 109.861,
          h: 109.884,
          l: 109.843,
          c: 109.853,
          v: 650
        },
        {
          t: 59400,
          o: 109.855,
          h: 109.856,
          l: 109.746,
          c: 109.811,
          v: 1067
        },
        {
          t: 60300,
          o: 109.811,
          h: 109.826,
          l: 109.771,
          c: 109.781,
          v: 619
        },
        {
          t: 61200,
          o: 109.78,
          h: 109.799,
          l: 109.765,
          c: 109.787,
          v: 792
        },
        {
          t: 62100,
          o: 109.787,
          h: 109.817,
          l: 109.786,
          c: 109.799,
          v: 455
        },
        {
          t: 63000,
          o: 109.8,
          h: 109.817,
          l: 109.792,
          c: 109.799,
          v: 485
        },
        {
          t: 63900,
          o: 109.798,
          h: 109.811,
          l: 109.79,
          c: 109.811,
          v: 391
        },
        {
          t: 64800,
          o: 109.808,
          h: 109.832,
          l: 109.796,
          c: 109.823,
          v: 434
        },
        {
          t: 65700,
          o: 109.826,
          h: 109.828,
          l: 109.793,
          c: 109.801,
          v: 344
        },
        {
          t: 66600,
          o: 109.802,
          h: 109.811,
          l: 109.775,
          c: 109.779,
          v: 379
        },
        {
          t: 67500,
          o: 109.778,
          h: 109.783,
          l: 109.747,
          c: 109.776,
          v: 516
        },
        {
          t: 68400,
          o: 109.777,
          h: 109.782,
          l: 109.739,
          c: 109.749,
          v: 682
        },
        {
          t: 69300,
          o: 109.748,
          h: 109.772,
          l: 109.728,
          c: 109.744,
          v: 784
        },
        {
          t: 70200,
          o: 109.746,
          h: 109.778,
          l: 109.742,
          c: 109.776,
          v: 400
        },
        {
          t: 71100,
          o: 109.774,
          h: 109.775,
          l: 109.739,
          c: 109.745,
          v: 291
        },
        {
          t: 72000,
          o: 109.746,
          h: 109.756,
          l: 109.731,
          c: 109.738,
          v: 553
        },
        {
          t: 72900,
          o: 109.739,
          h: 109.749,
          l: 109.721,
          c: 109.743,
          v: 722
        },
        {
          t: 73800,
          o: 109.744,
          h: 109.771,
          l: 109.742,
          c: 109.769,
          v: 513
        },
        {
          t: 74700,
          o: 109.769,
          h: 109.785,
          l: 109.757,
          c: 109.774,
          v: 637
        },
        {
          t: 75600,
          o: 109.775,
          h: 109.785,
          l: 109.773,
          c: 109.774,
          v: 308
        },
        {
          t: 76500,
          o: 109.773,
          h: 109.78,
          l: 109.773,
          c: 109.777,
          v: 154
        },
        {
          t: 77400,
          o: 109.776,
          h: 109.782,
          l: 109.771,
          c: 109.782,
          v: 216
        },
        {
          t: 78300,
          o: 109.78,
          h: 109.783,
          l: 109.772,
          c: 109.772,
          v: 277
        },
        {
          t: 79200,
          o: 109.775,
          h: 109.804,
          l: 109.753,
          c: 109.754,
          v: 1141
        },
        {
          t: 80100,
          o: 109.755,
          h: 109.804,
          l: 109.753,
          c: 109.787,
          v: 279
        },
        {
          t: 81000,
          o: 109.787,
          h: 109.793,
          l: 109.77,
          c: 109.781,
          v: 601
        },
        {
          t: 81900,
          o: 109.78,
          h: 109.787,
          l: 109.776,
          c: 109.779,
          v: 593
        },
        {
          t: 82800,
          o: 109.782,
          h: 109.813,
          l: 109.779,
          c: 109.81,
          v: 1061
        },
        {
          t: 83700,
          o: 109.81,
          h: 109.825,
          l: 109.801,
          c: 109.818,
          v: 481
        },
        {
          t: 84600,
          o: 109.818,
          h: 109.839,
          l: 109.817,
          c: 109.827,
          v: 315
        },
        {
          t: 85500,
          o: 109.829,
          h: 109.845,
          l: 109.819,
          c: 109.837,
          v: 465
        }
      ]
    },
    {
      d: 1581465600,
      b: [
        {
          t: 0,
          o: 109.838,
          h: 109.849,
          l: 109.8,
          c: 109.834,
          v: 868
        },
        {
          t: 900,
          o: 109.838,
          h: 109.859,
          l: 109.798,
          c: 109.805,
          v: 913
        },
        {
          t: 1800,
          o: 109.806,
          h: 109.82,
          l: 109.794,
          c: 109.802,
          v: 758
        },
        {
          t: 2700,
          o: 109.801,
          h: 109.861,
          l: 109.795,
          c: 109.841,
          v: 1193
        },
        {
          t: 3600,
          o: 109.839,
          h: 109.879,
          l: 109.837,
          c: 109.865,
          v: 933
        },
        {
          t: 4500,
          o: 109.863,
          h: 109.878,
          l: 109.829,
          c: 109.837,
          v: 650
        },
        {
          t: 5400,
          o: 109.836,
          h: 109.856,
          l: 109.822,
          c: 109.847,
          v: 782
        },
        {
          t: 6300,
          o: 109.848,
          h: 109.87,
          l: 109.841,
          c: 109.864,
          v: 500
        },
        {
          t: 7200,
          o: 109.866,
          h: 109.877,
          l: 109.853,
          c: 109.864,
          v: 459
        },
        {
          t: 8100,
          o: 109.866,
          h: 109.868,
          l: 109.837,
          c: 109.861,
          v: 405
        },
        {
          t: 9000,
          o: 109.858,
          h: 109.886,
          l: 109.854,
          c: 109.856,
          v: 564
        },
        {
          t: 9900,
          o: 109.857,
          h: 109.877,
          l: 109.856,
          c: 109.873,
          v: 342
        },
        {
          t: 10800,
          o: 109.874,
          h: 109.874,
          l: 109.839,
          c: 109.842,
          v: 279
        },
        {
          t: 11700,
          o: 109.844,
          h: 109.846,
          l: 109.836,
          c: 109.843,
          v: 280
        },
        {
          t: 12600,
          o: 109.843,
          h: 109.849,
          l: 109.831,
          c: 109.836,
          v: 216
        },
        {
          t: 13500,
          o: 109.837,
          h: 109.842,
          l: 109.818,
          c: 109.82,
          v: 225
        },
        {
          t: 14400,
          o: 109.82,
          h: 109.831,
          l: 109.812,
          c: 109.824,
          v: 386
        },
        {
          t: 15300,
          o: 109.824,
          h: 109.834,
          l: 109.813,
          c: 109.826,
          v: 250
        },
        {
          t: 16200,
          o: 109.827,
          h: 109.83,
          l: 109.817,
          c: 109.82,
          v: 297
        },
        {
          t: 17100,
          o: 109.82,
          h: 109.835,
          l: 109.819,
          c: 109.821,
          v: 303
        },
        {
          t: 18000,
          o: 109.821,
          h: 109.835,
          l: 109.821,
          c: 109.828,
          v: 293
        },
        {
          t: 18900,
          o: 109.828,
          h: 109.833,
          l: 109.821,
          c: 109.828,
          v: 343
        },
        {
          t: 19800,
          o: 109.828,
          h: 109.853,
          l: 109.825,
          c: 109.852,
          v: 376
        },
        {
          t: 20700,
          o: 109.852,
          h: 109.861,
          l: 109.846,
          c: 109.851,
          v: 303
        },
        {
          t: 21600,
          o: 109.851,
          h: 109.865,
          l: 109.842,
          c: 109.853,
          v: 469
        },
        {
          t: 22500,
          o: 109.852,
          h: 109.88,
          l: 109.841,
          c: 109.859,
          v: 593
        },
        {
          t: 23400,
          o: 109.86,
          h: 109.872,
          l: 109.858,
          c: 109.866,
          v: 430
        },
        {
          t: 24300,
          o: 109.866,
          h: 109.87,
          l: 109.858,
          c: 109.864,
          v: 327
        },
        {
          t: 25200,
          o: 109.862,
          h: 109.871,
          l: 109.842,
          c: 109.852,
          v: 446
        },
        {
          t: 26100,
          o: 109.849,
          h: 109.901,
          l: 109.848,
          c: 109.885,
          v: 529
        },
        {
          t: 27000,
          o: 109.885,
          h: 109.917,
          l: 109.885,
          c: 109.898,
          v: 491
        },
        {
          t: 27900,
          o: 109.899,
          h: 109.9,
          l: 109.857,
          c: 109.876,
          v: 644
        },
        {
          t: 28800,
          o: 109.872,
          h: 109.9,
          l: 109.863,
          c: 109.876,
          v: 701
        },
        {
          t: 29700,
          o: 109.877,
          h: 109.938,
          l: 109.877,
          c: 109.929,
          v: 656
        },
        {
          t: 30600,
          o: 109.928,
          h: 110.13,
          l: 109.927,
          c: 110.122,
          v: 1533
        },
        {
          t: 31500,
          o: 110.12,
          h: 110.127,
          l: 110.087,
          c: 110.102,
          v: 1005
        },
        {
          t: 32400,
          o: 110.106,
          h: 110.108,
          l: 110.036,
          c: 110.038,
          v: 739
        },
        {
          t: 33300,
          o: 110.038,
          h: 110.044,
          l: 109.977,
          c: 109.978,
          v: 876
        },
        {
          t: 34200,
          o: 109.98,
          h: 109.999,
          l: 109.955,
          c: 109.989,
          v: 565
        },
        {
          t: 35100,
          o: 109.99,
          h: 109.996,
          l: 109.967,
          c: 109.986,
          v: 399
        },
        {
          t: 36000,
          o: 109.985,
          h: 110.031,
          l: 109.981,
          c: 110.023,
          v: 331
        },
        {
          t: 36900,
          o: 110.023,
          h: 110.023,
          l: 109.957,
          c: 109.965,
          v: 403
        },
        {
          t: 37800,
          o: 109.964,
          h: 109.969,
          l: 109.913,
          c: 109.943,
          v: 542
        },
        {
          t: 38700,
          o: 109.943,
          h: 109.976,
          l: 109.934,
          c: 109.959,
          v: 441
        },
        {
          t: 39600,
          o: 109.959,
          h: 109.976,
          l: 109.955,
          c: 109.96,
          v: 358
        },
        {
          t: 40500,
          o: 109.96,
          h: 109.99,
          l: 109.96,
          c: 109.98,
          v: 315
        },
        {
          t: 41400,
          o: 109.98,
          h: 109.982,
          l: 109.932,
          c: 109.941,
          v: 334
        },
        {
          t: 42300,
          o: 109.941,
          h: 109.957,
          l: 109.929,
          c: 109.95,
          v: 293
        },
        {
          t: 43200,
          o: 109.951,
          h: 109.969,
          l: 109.94,
          c: 109.959,
          v: 327
        },
        {
          t: 44100,
          o: 109.963,
          h: 109.979,
          l: 109.956,
          c: 109.97,
          v: 318
        },
        {
          t: 45000,
          o: 109.967,
          h: 109.973,
          l: 109.958,
          c: 109.965,
          v: 296
        },
        {
          t: 45900,
          o: 109.964,
          h: 109.965,
          l: 109.943,
          c: 109.951,
          v: 253
        },
        {
          t: 46800,
          o: 109.95,
          h: 109.976,
          l: 109.941,
          c: 109.976,
          v: 412
        },
        {
          t: 47700,
          o: 109.976,
          h: 109.977,
          l: 109.946,
          c: 109.958,
          v: 493
        },
        {
          t: 48600,
          o: 109.961,
          h: 109.997,
          l: 109.957,
          c: 109.988,
          v: 442
        },
        {
          t: 49500,
          o: 109.988,
          h: 110.005,
          l: 109.965,
          c: 109.977,
          v: 392
        },
        {
          t: 50400,
          o: 109.978,
          h: 110.014,
          l: 109.973,
          c: 109.991,
          v: 586
        },
        {
          t: 51300,
          o: 109.992,
          h: 110.013,
          l: 109.988,
          c: 110.007,
          v: 503
        },
        {
          t: 52200,
          o: 110.002,
          h: 110.044,
          l: 109.998,
          c: 110.01,
          v: 1020
        },
        {
          t: 53100,
          o: 110.01,
          h: 110.053,
          l: 110.009,
          c: 110.049,
          v: 794
        },
        {
          t: 54000,
          o: 110.049,
          h: 110.052,
          l: 110.005,
          c: 110.043,
          v: 951
        },
        {
          t: 54900,
          o: 110.042,
          h: 110.049,
          l: 110.016,
          c: 110.046,
          v: 840
        },
        {
          t: 55800,
          o: 110.044,
          h: 110.049,
          l: 110.001,
          c: 110.007,
          v: 776
        },
        {
          t: 56700,
          o: 110.008,
          h: 110.028,
          l: 109.993,
          c: 110.009,
          v: 673
        },
        {
          t: 57600,
          o: 110.008,
          h: 110.068,
          l: 110.006,
          c: 110.061,
          v: 507
        },
        {
          t: 58500,
          o: 110.061,
          h: 110.074,
          l: 110.027,
          c: 110.041,
          v: 585
        },
        {
          t: 59400,
          o: 110.04,
          h: 110.048,
          l: 109.992,
          c: 109.998,
          v: 550
        },
        {
          t: 60300,
          o: 109.999,
          h: 110.007,
          l: 109.979,
          c: 110.003,
          v: 435
        },
        {
          t: 61200,
          o: 110.004,
          h: 110.013,
          l: 109.99,
          c: 110.007,
          v: 465
        },
        {
          t: 62100,
          o: 110.008,
          h: 110.033,
          l: 109.998,
          c: 110.032,
          v: 397
        },
        {
          t: 63000,
          o: 110.031,
          h: 110.037,
          l: 110.016,
          c: 110.024,
          v: 342
        },
        {
          t: 63900,
          o: 110.026,
          h: 110.033,
          l: 110.018,
          c: 110.031,
          v: 268
        },
        {
          t: 64800,
          o: 110.03,
          h: 110.035,
          l: 110.007,
          c: 110.021,
          v: 435
        },
        {
          t: 65700,
          o: 110.021,
          h: 110.024,
          l: 109.981,
          c: 110.007,
          v: 405
        },
        {
          t: 66600,
          o: 110.006,
          h: 110.019,
          l: 109.998,
          c: 110.014,
          v: 269
        },
        {
          t: 67500,
          o: 110.016,
          h: 110.039,
          l: 110.013,
          c: 110.036,
          v: 188
        },
        {
          t: 68400,
          o: 110.035,
          h: 110.056,
          l: 110.024,
          c: 110.051,
          v: 249
        },
        {
          t: 69300,
          o: 110.053,
          h: 110.09,
          l: 110.042,
          c: 110.075,
          v: 603
        },
        {
          t: 70200,
          o: 110.076,
          h: 110.079,
          l: 110.068,
          c: 110.073,
          v: 363
        },
        {
          t: 71100,
          o: 110.073,
          h: 110.089,
          l: 110.072,
          c: 110.075,
          v: 307
        },
        {
          t: 72000,
          o: 110.075,
          h: 110.107,
          l: 110.073,
          c: 110.087,
          v: 548
        },
        {
          t: 72900,
          o: 110.087,
          h: 110.095,
          l: 110.08,
          c: 110.087,
          v: 241
        },
        {
          t: 73800,
          o: 110.087,
          h: 110.096,
          l: 110.079,
          c: 110.08,
          v: 266
        },
        {
          t: 74700,
          o: 110.08,
          h: 110.081,
          l: 110.064,
          c: 110.065,
          v: 342
        },
        {
          t: 75600,
          o: 110.066,
          h: 110.078,
          l: 110.062,
          c: 110.07,
          v: 207
        },
        {
          t: 76500,
          o: 110.07,
          h: 110.092,
          l: 110.068,
          c: 110.088,
          v: 177
        },
        {
          t: 77400,
          o: 110.087,
          h: 110.107,
          l: 110.087,
          c: 110.106,
          v: 593
        },
        {
          t: 78300,
          o: 110.104,
          h: 110.112,
          l: 110.09,
          c: 110.094,
          v: 429
        },
        {
          t: 79200,
          o: 110.092,
          h: 110.092,
          l: 110.054,
          c: 110.079,
          v: 1203
        },
        {
          t: 80100,
          o: 110.078,
          h: 110.086,
          l: 110.062,
          c: 110.081,
          v: 793
        },
        {
          t: 81000,
          o: 110.078,
          h: 110.081,
          l: 110.059,
          c: 110.064,
          v: 1390
        },
        {
          t: 81900,
          o: 110.065,
          h: 110.076,
          l: 110.064,
          c: 110.073,
          v: 1406
        },
        {
          t: 82800,
          o: 110.073,
          h: 110.075,
          l: 110.061,
          c: 110.062,
          v: 634
        },
        {
          t: 83700,
          o: 110.063,
          h: 110.081,
          l: 110.062,
          c: 110.07,
          v: 289
        },
        {
          t: 84600,
          o: 110.071,
          h: 110.081,
          l: 110.057,
          c: 110.064,
          v: 227
        },
        {
          t: 85500,
          o: 110.062,
          h: 110.075,
          l: 109.796,
          c: 109.865,
          v: 2439
        }
      ]
    },
    {
      d: 1581552000,
      b: [
        {
          t: 0,
          o: 109.866,
          h: 109.927,
          l: 109.846,
          c: 109.919,
          v: 2061
        },
        {
          t: 900,
          o: 109.919,
          h: 109.926,
          l: 109.866,
          c: 109.878,
          v: 1185
        },
        {
          t: 1800,
          o: 109.877,
          h: 109.915,
          l: 109.862,
          c: 109.877,
          v: 1062
        },
        {
          t: 2700,
          o: 109.877,
          h: 109.9,
          l: 109.846,
          c: 109.877,
          v: 1128
        },
        {
          t: 3600,
          o: 109.876,
          h: 109.926,
          l: 109.866,
          c: 109.908,
          v: 1075
        },
        {
          t: 4500,
          o: 109.906,
          h: 109.97,
          l: 109.903,
          c: 109.954,
          v: 972
        },
        {
          t: 5400,
          o: 109.957,
          h: 109.962,
          l: 109.927,
          c: 109.952,
          v: 1050
        },
        {
          t: 6300,
          o: 109.953,
          h: 109.98,
          l: 109.937,
          c: 109.943,
          v: 997
        },
        {
          t: 7200,
          o: 109.948,
          h: 109.959,
          l: 109.921,
          c: 109.936,
          v: 893
        },
        {
          t: 8100,
          o: 109.936,
          h: 109.961,
          l: 109.932,
          c: 109.959,
          v: 548
        },
        {
          t: 9000,
          o: 109.959,
          h: 109.973,
          l: 109.948,
          c: 109.957,
          v: 475
        },
        {
          t: 9900,
          o: 109.959,
          h: 109.96,
          l: 109.912,
          c: 109.918,
          v: 314
        },
        {
          t: 10800,
          o: 109.919,
          h: 109.931,
          l: 109.906,
          c: 109.919,
          v: 502
        },
        {
          t: 11700,
          o: 109.918,
          h: 109.93,
          l: 109.882,
          c: 109.889,
          v: 457
        },
        {
          t: 12600,
          o: 109.889,
          h: 109.9,
          l: 109.867,
          c: 109.87,
          v: 446
        },
        {
          t: 13500,
          o: 109.87,
          h: 109.883,
          l: 109.848,
          c: 109.854,
          v: 501
        },
        {
          t: 14400,
          o: 109.855,
          h: 109.886,
          l: 109.847,
          c: 109.879,
          v: 428
        },
        {
          t: 15300,
          o: 109.879,
          h: 109.891,
          l: 109.867,
          c: 109.87,
          v: 343
        },
        {
          t: 16200,
          o: 109.871,
          h: 109.891,
          l: 109.862,
          c: 109.879,
          v: 357
        },
        {
          t: 17100,
          o: 109.878,
          h: 109.882,
          l: 109.852,
          c: 109.855,
          v: 459
        },
        {
          t: 18000,
          o: 109.854,
          h: 109.883,
          l: 109.853,
          c: 109.876,
          v: 539
        },
        {
          t: 18900,
          o: 109.876,
          h: 109.882,
          l: 109.849,
          c: 109.849,
          v: 321
        },
        {
          t: 19800,
          o: 109.85,
          h: 109.862,
          l: 109.82,
          c: 109.823,
          v: 686
        },
        {
          t: 20700,
          o: 109.821,
          h: 109.849,
          l: 109.817,
          c: 109.821,
          v: 514
        },
        {
          t: 21600,
          o: 109.82,
          h: 109.842,
          l: 109.805,
          c: 109.808,
          v: 583
        },
        {
          t: 22500,
          o: 109.809,
          h: 109.82,
          l: 109.768,
          c: 109.818,
          v: 595
        },
        {
          t: 23400,
          o: 109.816,
          h: 109.816,
          l: 109.772,
          c: 109.805,
          v: 382
        },
        {
          t: 24300,
          o: 109.806,
          h: 109.816,
          l: 109.799,
          c: 109.806,
          v: 287
        },
        {
          t: 25200,
          o: 109.807,
          h: 109.815,
          l: 109.749,
          c: 109.777,
          v: 693
        },
        {
          t: 26100,
          o: 109.777,
          h: 109.819,
          l: 109.741,
          c: 109.808,
          v: 695
        },
        {
          t: 27000,
          o: 109.805,
          h: 109.844,
          l: 109.79,
          c: 109.794,
          v: 842
        },
        {
          t: 27900,
          o: 109.795,
          h: 109.795,
          l: 109.713,
          c: 109.722,
          v: 775
        },
        {
          t: 28800,
          o: 109.723,
          h: 109.771,
          l: 109.71,
          c: 109.729,
          v: 1126
        },
        {
          t: 29700,
          o: 109.728,
          h: 109.787,
          l: 109.722,
          c: 109.747,
          v: 814
        },
        {
          t: 30600,
          o: 109.749,
          h: 109.786,
          l: 109.735,
          c: 109.745,
          v: 790
        },
        {
          t: 31500,
          o: 109.745,
          h: 109.78,
          l: 109.736,
          c: 109.758,
          v: 576
        },
        {
          t: 32400,
          o: 109.757,
          h: 109.802,
          l: 109.747,
          c: 109.777,
          v: 594
        },
        {
          t: 33300,
          o: 109.776,
          h: 109.779,
          l: 109.728,
          c: 109.743,
          v: 659
        },
        {
          t: 34200,
          o: 109.744,
          h: 109.78,
          l: 109.743,
          c: 109.774,
          v: 470
        },
        {
          t: 35100,
          o: 109.775,
          h: 109.778,
          l: 109.711,
          c: 109.731,
          v: 732
        },
        {
          t: 36000,
          o: 109.729,
          h: 109.729,
          l: 109.612,
          c: 109.646,
          v: 1290
        },
        {
          t: 36900,
          o: 109.644,
          h: 109.737,
          l: 109.644,
          c: 109.717,
          v: 692
        },
        {
          t: 37800,
          o: 109.717,
          h: 109.719,
          l: 109.664,
          c: 109.692,
          v: 654
        },
        {
          t: 38700,
          o: 109.692,
          h: 109.703,
          l: 109.673,
          c: 109.688,
          v: 628
        },
        {
          t: 39600,
          o: 109.688,
          h: 109.711,
          l: 109.677,
          c: 109.69,
          v: 701
        },
        {
          t: 40500,
          o: 109.688,
          h: 109.711,
          l: 109.651,
          c: 109.662,
          v: 609
        },
        {
          t: 41400,
          o: 109.661,
          h: 109.685,
          l: 109.646,
          c: 109.673,
          v: 781
        },
        {
          t: 42300,
          o: 109.673,
          h: 109.722,
          l: 109.661,
          c: 109.7,
          v: 951
        },
        {
          t: 43200,
          o: 109.701,
          h: 109.74,
          l: 109.698,
          c: 109.709,
          v: 796
        },
        {
          t: 44100,
          o: 109.71,
          h: 109.711,
          l: 109.667,
          c: 109.675,
          v: 585
        },
        {
          t: 45000,
          o: 109.676,
          h: 109.693,
          l: 109.666,
          c: 109.684,
          v: 619
        },
        {
          t: 45900,
          o: 109.683,
          h: 109.716,
          l: 109.665,
          c: 109.714,
          v: 621
        },
        {
          t: 46800,
          o: 109.713,
          h: 109.731,
          l: 109.695,
          c: 109.698,
          v: 678
        },
        {
          t: 47700,
          o: 109.698,
          h: 109.737,
          l: 109.677,
          c: 109.726,
          v: 626
        },
        {
          t: 48600,
          o: 109.719,
          h: 109.766,
          l: 109.715,
          c: 109.742,
          v: 750
        },
        {
          t: 49500,
          o: 109.742,
          h: 109.786,
          l: 109.716,
          c: 109.746,
          v: 613
        },
        {
          t: 50400,
          o: 109.746,
          h: 109.828,
          l: 109.738,
          c: 109.8,
          v: 807
        },
        {
          t: 51300,
          o: 109.8,
          h: 109.807,
          l: 109.775,
          c: 109.78,
          v: 454
        },
        {
          t: 52200,
          o: 109.781,
          h: 109.822,
          l: 109.757,
          c: 109.813,
          v: 1438
        },
        {
          t: 53100,
          o: 109.815,
          h: 109.846,
          l: 109.702,
          c: 109.722,
          v: 1241
        },
        {
          t: 54000,
          o: 109.725,
          h: 109.744,
          l: 109.64,
          c: 109.727,
          v: 1821
        },
        {
          t: 54900,
          o: 109.727,
          h: 109.74,
          l: 109.695,
          c: 109.718,
          v: 1126
        },
        {
          t: 55800,
          o: 109.718,
          h: 109.722,
          l: 109.656,
          c: 109.682,
          v: 1255
        },
        {
          t: 56700,
          o: 109.683,
          h: 109.817,
          l: 109.682,
          c: 109.796,
          v: 1141
        },
        {
          t: 57600,
          o: 109.795,
          h: 109.831,
          l: 109.787,
          c: 109.795,
          v: 912
        },
        {
          t: 58500,
          o: 109.794,
          h: 109.84,
          l: 109.767,
          c: 109.832,
          v: 646
        },
        {
          t: 59400,
          o: 109.83,
          h: 109.861,
          l: 109.819,
          c: 109.833,
          v: 732
        },
        {
          t: 60300,
          o: 109.833,
          h: 109.834,
          l: 109.798,
          c: 109.814,
          v: 485
        },
        {
          t: 61200,
          o: 109.813,
          h: 109.826,
          l: 109.797,
          c: 109.812,
          v: 569
        },
        {
          t: 62100,
          o: 109.812,
          h: 109.825,
          l: 109.794,
          c: 109.807,
          v: 478
        },
        {
          t: 63000,
          o: 109.807,
          h: 109.815,
          l: 109.778,
          c: 109.786,
          v: 481
        },
        {
          t: 63900,
          o: 109.786,
          h: 109.809,
          l: 109.751,
          c: 109.763,
          v: 518
        },
        {
          t: 64800,
          o: 109.761,
          h: 109.77,
          l: 109.737,
          c: 109.748,
          v: 753
        },
        {
          t: 65700,
          o: 109.748,
          h: 109.801,
          l: 109.737,
          c: 109.795,
          v: 571
        },
        {
          t: 66600,
          o: 109.794,
          h: 109.817,
          l: 109.791,
          c: 109.805,
          v: 266
        },
        {
          t: 67500,
          o: 109.806,
          h: 109.824,
          l: 109.804,
          c: 109.809,
          v: 390
        },
        {
          t: 68400,
          o: 109.809,
          h: 109.844,
          l: 109.795,
          c: 109.806,
          v: 504
        },
        {
          t: 69300,
          o: 109.805,
          h: 109.809,
          l: 109.769,
          c: 109.789,
          v: 534
        },
        {
          t: 70200,
          o: 109.79,
          h: 109.812,
          l: 109.786,
          c: 109.79,
          v: 382
        },
        {
          t: 71100,
          o: 109.789,
          h: 109.821,
          l: 109.783,
          c: 109.801,
          v: 367
        },
        {
          t: 72000,
          o: 109.801,
          h: 109.818,
          l: 109.761,
          c: 109.776,
          v: 825
        },
        {
          t: 72900,
          o: 109.777,
          h: 109.802,
          l: 109.765,
          c: 109.794,
          v: 635
        },
        {
          t: 73800,
          o: 109.793,
          h: 109.801,
          l: 109.77,
          c: 109.771,
          v: 610
        },
        {
          t: 74700,
          o: 109.772,
          h: 109.798,
          l: 109.764,
          c: 109.777,
          v: 567
        },
        {
          t: 75600,
          o: 109.776,
          h: 109.798,
          l: 109.771,
          c: 109.791,
          v: 419
        },
        {
          t: 76500,
          o: 109.791,
          h: 109.809,
          l: 109.789,
          c: 109.806,
          v: 217
        },
        {
          t: 77400,
          o: 109.806,
          h: 109.818,
          l: 109.803,
          c: 109.813,
          v: 226
        },
        {
          t: 78300,
          o: 109.814,
          h: 109.829,
          l: 109.811,
          c: 109.814,
          v: 553
        },
        {
          t: 79200,
          o: 109.812,
          h: 109.818,
          l: 109.772,
          c: 109.804,
          v: 923
        },
        {
          t: 80100,
          o: 109.795,
          h: 109.804,
          l: 109.787,
          c: 109.799,
          v: 131
        },
        {
          t: 81000,
          o: 109.798,
          h: 109.819,
          l: 109.796,
          c: 109.806,
          v: 374
        },
        {
          t: 81900,
          o: 109.805,
          h: 109.817,
          l: 109.801,
          c: 109.804,
          v: 805
        },
        {
          t: 82800,
          o: 109.804,
          h: 109.812,
          l: 109.783,
          c: 109.784,
          v: 943
        },
        {
          t: 83700,
          o: 109.784,
          h: 109.787,
          l: 109.762,
          c: 109.778,
          v: 795
        },
        {
          t: 84600,
          o: 109.777,
          h: 109.793,
          l: 109.725,
          c: 109.769,
          v: 972
        },
        {
          t: 85500,
          o: 109.769,
          h: 109.776,
          l: 109.741,
          c: 109.766,
          v: 959
        }
      ]
    },
    {
      d: 1581638400,
      b: [
        {
          t: 0,
          o: 109.771,
          h: 109.795,
          l: 109.758,
          c: 109.782,
          v: 974
        },
        {
          t: 900,
          o: 109.781,
          h: 109.805,
          l: 109.746,
          c: 109.796,
          v: 833
        },
        {
          t: 1800,
          o: 109.795,
          h: 109.809,
          l: 109.782,
          c: 109.793,
          v: 715
        },
        {
          t: 2700,
          o: 109.794,
          h: 109.867,
          l: 109.788,
          c: 109.853,
          v: 1177
        },
        {
          t: 3600,
          o: 109.854,
          h: 109.862,
          l: 109.776,
          c: 109.785,
          v: 1024
        },
        {
          t: 4500,
          o: 109.784,
          h: 109.797,
          l: 109.758,
          c: 109.795,
          v: 827
        },
        {
          t: 5400,
          o: 109.797,
          h: 109.836,
          l: 109.782,
          c: 109.816,
          v: 873
        },
        {
          t: 6300,
          o: 109.819,
          h: 109.823,
          l: 109.798,
          c: 109.809,
          v: 654
        },
        {
          t: 7200,
          o: 109.808,
          h: 109.816,
          l: 109.787,
          c: 109.805,
          v: 686
        },
        {
          t: 8100,
          o: 109.806,
          h: 109.836,
          l: 109.806,
          c: 109.824,
          v: 650
        },
        {
          t: 9000,
          o: 109.825,
          h: 109.85,
          l: 109.807,
          c: 109.835,
          v: 667
        },
        {
          t: 9900,
          o: 109.836,
          h: 109.859,
          l: 109.823,
          c: 109.859,
          v: 519
        },
        {
          t: 10800,
          o: 109.86,
          h: 109.906,
          l: 109.856,
          c: 109.856,
          v: 805
        },
        {
          t: 11700,
          o: 109.857,
          h: 109.87,
          l: 109.842,
          c: 109.848,
          v: 409
        },
        {
          t: 12600,
          o: 109.848,
          h: 109.86,
          l: 109.841,
          c: 109.844,
          v: 373
        },
        {
          t: 13500,
          o: 109.843,
          h: 109.849,
          l: 109.816,
          c: 109.834,
          v: 340
        },
        {
          t: 14400,
          o: 109.834,
          h: 109.837,
          l: 109.801,
          c: 109.822,
          v: 317
        },
        {
          t: 15300,
          o: 109.82,
          h: 109.82,
          l: 109.79,
          c: 109.812,
          v: 352
        },
        {
          t: 16200,
          o: 109.813,
          h: 109.822,
          l: 109.81,
          c: 109.812,
          v: 270
        },
        {
          t: 17100,
          o: 109.812,
          h: 109.814,
          l: 109.773,
          c: 109.776,
          v: 352
        },
        {
          t: 18000,
          o: 109.776,
          h: 109.805,
          l: 109.775,
          c: 109.794,
          v: 472
        },
        {
          t: 18900,
          o: 109.795,
          h: 109.808,
          l: 109.79,
          c: 109.804,
          v: 508
        },
        {
          t: 19800,
          o: 109.803,
          h: 109.807,
          l: 109.785,
          c: 109.803,
          v: 553
        },
        {
          t: 20700,
          o: 109.804,
          h: 109.812,
          l: 109.789,
          c: 109.789,
          v: 348
        },
        {
          t: 21600,
          o: 109.792,
          h: 109.792,
          l: 109.741,
          c: 109.758,
          v: 710
        },
        {
          t: 22500,
          o: 109.756,
          h: 109.772,
          l: 109.746,
          c: 109.761,
          v: 497
        },
        {
          t: 23400,
          o: 109.76,
          h: 109.781,
          l: 109.759,
          c: 109.772,
          v: 300
        },
        {
          t: 24300,
          o: 109.773,
          h: 109.778,
          l: 109.763,
          c: 109.776,
          v: 387
        },
        {
          t: 25200,
          o: 109.777,
          h: 109.793,
          l: 109.748,
          c: 109.789,
          v: 529
        },
        {
          t: 26100,
          o: 109.79,
          h: 109.832,
          l: 109.783,
          c: 109.805,
          v: 585
        },
        {
          t: 27000,
          o: 109.804,
          h: 109.817,
          l: 109.78,
          c: 109.782,
          v: 472
        },
        {
          t: 27900,
          o: 109.783,
          h: 109.801,
          l: 109.771,
          c: 109.78,
          v: 314
        },
        {
          t: 28800,
          o: 109.779,
          h: 109.796,
          l: 109.752,
          c: 109.769,
          v: 848
        },
        {
          t: 29700,
          o: 109.77,
          h: 109.78,
          l: 109.756,
          c: 109.776,
          v: 560
        },
        {
          t: 30600,
          o: 109.778,
          h: 109.809,
          l: 109.778,
          c: 109.791,
          v: 438
        },
        {
          t: 31500,
          o: 109.791,
          h: 109.824,
          l: 109.785,
          c: 109.792,
          v: 475
        },
        {
          t: 32400,
          o: 109.794,
          h: 109.807,
          l: 109.777,
          c: 109.797,
          v: 436
        },
        {
          t: 33300,
          o: 109.796,
          h: 109.832,
          l: 109.79,
          c: 109.799,
          v: 505
        },
        {
          t: 34200,
          o: 109.799,
          h: 109.81,
          l: 109.782,
          c: 109.799,
          v: 399
        },
        {
          t: 35100,
          o: 109.798,
          h: 109.8,
          l: 109.778,
          c: 109.796,
          v: 431
        },
        {
          t: 36000,
          o: 109.797,
          h: 109.805,
          l: 109.784,
          c: 109.8,
          v: 435
        },
        {
          t: 36900,
          o: 109.799,
          h: 109.813,
          l: 109.796,
          c: 109.807,
          v: 386
        },
        {
          t: 37800,
          o: 109.808,
          h: 109.819,
          l: 109.798,
          c: 109.818,
          v: 349
        },
        {
          t: 38700,
          o: 109.819,
          h: 109.82,
          l: 109.809,
          c: 109.819,
          v: 208
        },
        {
          t: 39600,
          o: 109.82,
          h: 109.825,
          l: 109.802,
          c: 109.807,
          v: 216
        },
        {
          t: 40500,
          o: 109.807,
          h: 109.822,
          l: 109.793,
          c: 109.806,
          v: 293
        },
        {
          t: 41400,
          o: 109.807,
          h: 109.819,
          l: 109.807,
          c: 109.818,
          v: 205
        },
        {
          t: 42300,
          o: 109.818,
          h: 109.82,
          l: 109.794,
          c: 109.796,
          v: 207
        },
        {
          t: 43200,
          o: 109.797,
          h: 109.815,
          l: 109.794,
          c: 109.805,
          v: 367
        },
        {
          t: 44100,
          o: 109.804,
          h: 109.821,
          l: 109.795,
          c: 109.821,
          v: 368
        },
        {
          t: 45000,
          o: 109.821,
          h: 109.842,
          l: 109.813,
          c: 109.83,
          v: 307
        },
        {
          t: 45900,
          o: 109.829,
          h: 109.853,
          l: 109.821,
          c: 109.848,
          v: 389
        },
        {
          t: 46800,
          o: 109.847,
          h: 109.854,
          l: 109.83,
          c: 109.849,
          v: 308
        },
        {
          t: 47700,
          o: 109.847,
          h: 109.876,
          l: 109.838,
          c: 109.858,
          v: 543
        },
        {
          t: 48600,
          o: 109.849,
          h: 109.852,
          l: 109.751,
          c: 109.753,
          v: 1325
        },
        {
          t: 49500,
          o: 109.754,
          h: 109.782,
          l: 109.732,
          c: 109.773,
          v: 789
        },
        {
          t: 50400,
          o: 109.772,
          h: 109.81,
          l: 109.76,
          c: 109.791,
          v: 650
        },
        {
          t: 51300,
          o: 109.791,
          h: 109.807,
          l: 109.769,
          c: 109.774,
          v: 468
        },
        {
          t: 52200,
          o: 109.776,
          h: 109.789,
          l: 109.734,
          c: 109.744,
          v: 1172
        },
        {
          t: 53100,
          o: 109.742,
          h: 109.761,
          l: 109.694,
          c: 109.72,
          v: 877
        },
        {
          t: 54000,
          o: 109.717,
          h: 109.766,
          l: 109.71,
          c: 109.747,
          v: 1003
        },
        {
          t: 54900,
          o: 109.746,
          h: 109.774,
          l: 109.718,
          c: 109.725,
          v: 804
        },
        {
          t: 55800,
          o: 109.722,
          h: 109.74,
          l: 109.707,
          c: 109.72,
          v: 665
        },
        {
          t: 56700,
          o: 109.719,
          h: 109.761,
          l: 109.719,
          c: 109.754,
          v: 646
        },
        {
          t: 57600,
          o: 109.754,
          h: 109.779,
          l: 109.753,
          c: 109.769,
          v: 636
        },
        {
          t: 58500,
          o: 109.769,
          h: 109.794,
          l: 109.763,
          c: 109.777,
          v: 450
        },
        {
          t: 59400,
          o: 109.776,
          h: 109.776,
          l: 109.755,
          c: 109.76,
          v: 423
        },
        {
          t: 60300,
          o: 109.76,
          h: 109.774,
          l: 109.751,
          c: 109.763,
          v: 359
        },
        {
          t: 61200,
          o: 109.763,
          h: 109.774,
          l: 109.736,
          c: 109.748,
          v: 434
        },
        {
          t: 62100,
          o: 109.747,
          h: 109.753,
          l: 109.723,
          c: 109.741,
          v: 371
        },
        {
          t: 63000,
          o: 109.737,
          h: 109.768,
          l: 109.731,
          c: 109.753,
          v: 259
        },
        {
          t: 63900,
          o: 109.754,
          h: 109.756,
          l: 109.726,
          c: 109.728,
          v: 337
        },
        {
          t: 64800,
          o: 109.728,
          h: 109.767,
          l: 109.7,
          c: 109.713,
          v: 521
        },
        {
          t: 65700,
          o: 109.714,
          h: 109.744,
          l: 109.71,
          c: 109.741,
          v: 381
        },
        {
          t: 66600,
          o: 109.74,
          h: 109.749,
          l: 109.731,
          c: 109.747,
          v: 309
        },
        {
          t: 67500,
          o: 109.746,
          h: 109.753,
          l: 109.72,
          c: 109.721,
          v: 304
        },
        {
          t: 68400,
          o: 109.72,
          h: 109.732,
          l: 109.705,
          c: 109.706,
          v: 491
        },
        {
          t: 69300,
          o: 109.707,
          h: 109.751,
          l: 109.692,
          c: 109.742,
          v: 504
        },
        {
          t: 70200,
          o: 109.741,
          h: 109.763,
          l: 109.732,
          c: 109.744,
          v: 399
        },
        {
          t: 71100,
          o: 109.743,
          h: 109.766,
          l: 109.734,
          c: 109.765,
          v: 320
        },
        {
          t: 72000,
          o: 109.766,
          h: 109.767,
          l: 109.742,
          c: 109.748,
          v: 317
        },
        {
          t: 72900,
          o: 109.746,
          h: 109.765,
          l: 109.741,
          c: 109.754,
          v: 253
        },
        {
          t: 73800,
          o: 109.753,
          h: 109.779,
          l: 109.747,
          c: 109.768,
          v: 346
        },
        {
          t: 74700,
          o: 109.769,
          h: 109.79,
          l: 109.768,
          c: 109.776,
          v: 585
        },
        {
          t: 75600,
          o: 109.775,
          h: 109.781,
          l: 109.762,
          c: 109.769,
          v: 274
        },
        {
          t: 76500,
          o: 109.77,
          h: 109.772,
          l: 109.755,
          c: 109.758,
          v: 333
        },
        {
          t: 77400,
          o: 109.758,
          h: 109.768,
          l: 109.758,
          c: 109.763,
          v: 112
        }
      ]
    },
    {
      d: 1581811200,
      b: [
        {
          t: 79200,
          o: 109.793,
          h: 109.797,
          l: 109.746,
          c: 109.751,
          v: 1294
        },
        {
          t: 80100,
          o: 109.771,
          h: 109.794,
          l: 109.753,
          c: 109.764,
          v: 693
        },
        {
          t: 81000,
          o: 109.764,
          h: 109.774,
          l: 109.755,
          c: 109.757,
          v: 234
        },
        {
          t: 81900,
          o: 109.756,
          h: 109.776,
          l: 109.752,
          c: 109.773,
          v: 96
        },
        {
          t: 82800,
          o: 109.773,
          h: 109.825,
          l: 109.768,
          c: 109.799,
          v: 845
        },
        {
          t: 83700,
          o: 109.799,
          h: 109.826,
          l: 109.788,
          c: 109.798,
          v: 765
        },
        {
          t: 84600,
          o: 109.798,
          h: 109.829,
          l: 109.784,
          c: 109.823,
          v: 1088
        },
        {
          t: 85500,
          o: 109.824,
          h: 109.828,
          l: 109.768,
          c: 109.789,
          v: 932
        }
      ]
    },
    {
      d: 1581897600,
      b: [
        {
          t: 0,
          o: 109.788,
          h: 109.791,
          l: 109.713,
          c: 109.713,
          v: 1367
        },
        {
          t: 900,
          o: 109.713,
          h: 109.798,
          l: 109.713,
          c: 109.796,
          v: 1062
        },
        {
          t: 1800,
          o: 109.797,
          h: 109.816,
          l: 109.792,
          c: 109.799,
          v: 673
        },
        {
          t: 2700,
          o: 109.8,
          h: 109.815,
          l: 109.743,
          c: 109.766,
          v: 1228
        },
        {
          t: 3600,
          o: 109.765,
          h: 109.791,
          l: 109.748,
          c: 109.783,
          v: 864
        },
        {
          t: 4500,
          o: 109.779,
          h: 109.808,
          l: 109.774,
          c: 109.787,
          v: 659
        },
        {
          t: 5400,
          o: 109.785,
          h: 109.823,
          l: 109.78,
          c: 109.818,
          v: 817
        },
        {
          t: 6300,
          o: 109.818,
          h: 109.834,
          l: 109.805,
          c: 109.83,
          v: 794
        },
        {
          t: 7200,
          o: 109.831,
          h: 109.848,
          l: 109.8,
          c: 109.802,
          v: 705
        },
        {
          t: 8100,
          o: 109.805,
          h: 109.829,
          l: 109.805,
          c: 109.827,
          v: 562
        },
        {
          t: 9000,
          o: 109.827,
          h: 109.827,
          l: 109.812,
          c: 109.824,
          v: 390
        },
        {
          t: 9900,
          o: 109.824,
          h: 109.84,
          l: 109.822,
          c: 109.829,
          v: 323
        },
        {
          t: 10800,
          o: 109.83,
          h: 109.834,
          l: 109.813,
          c: 109.813,
          v: 317
        },
        {
          t: 11700,
          o: 109.813,
          h: 109.832,
          l: 109.81,
          c: 109.826,
          v: 232
        },
        {
          t: 12600,
          o: 109.826,
          h: 109.827,
          l: 109.811,
          c: 109.82,
          v: 366
        },
        {
          t: 13500,
          o: 109.82,
          h: 109.82,
          l: 109.8,
          c: 109.81,
          v: 297
        },
        {
          t: 14400,
          o: 109.806,
          h: 109.814,
          l: 109.798,
          c: 109.805,
          v: 260
        },
        {
          t: 15300,
          o: 109.804,
          h: 109.81,
          l: 109.797,
          c: 109.808,
          v: 275
        },
        {
          t: 16200,
          o: 109.808,
          h: 109.828,
          l: 109.807,
          c: 109.827,
          v: 175
        },
        {
          t: 17100,
          o: 109.826,
          h: 109.831,
          l: 109.819,
          c: 109.827,
          v: 187
        },
        {
          t: 18000,
          o: 109.826,
          h: 109.836,
          l: 109.821,
          c: 109.831,
          v: 312
        },
        {
          t: 18900,
          o: 109.831,
          h: 109.836,
          l: 109.811,
          c: 109.829,
          v: 246
        },
        {
          t: 19800,
          o: 109.829,
          h: 109.831,
          l: 109.816,
          c: 109.825,
          v: 235
        },
        {
          t: 20700,
          o: 109.826,
          h: 109.837,
          l: 109.816,
          c: 109.834,
          v: 231
        },
        {
          t: 21600,
          o: 109.835,
          h: 109.849,
          l: 109.827,
          c: 109.84,
          v: 347
        },
        {
          t: 22500,
          o: 109.841,
          h: 109.846,
          l: 109.828,
          c: 109.836,
          v: 328
        },
        {
          t: 23400,
          o: 109.836,
          h: 109.84,
          l: 109.828,
          c: 109.838,
          v: 240
        },
        {
          t: 24300,
          o: 109.836,
          h: 109.848,
          l: 109.828,
          c: 109.847,
          v: 257
        },
        {
          t: 25200,
          o: 109.849,
          h: 109.856,
          l: 109.837,
          c: 109.853,
          v: 253
        },
        {
          t: 26100,
          o: 109.852,
          h: 109.853,
          l: 109.837,
          c: 109.849,
          v: 326
        },
        {
          t: 27000,
          o: 109.85,
          h: 109.862,
          l: 109.847,
          c: 109.854,
          v: 251
        },
        {
          t: 27900,
          o: 109.853,
          h: 109.86,
          l: 109.851,
          c: 109.858,
          v: 382
        },
        {
          t: 28800,
          o: 109.859,
          h: 109.864,
          l: 109.848,
          c: 109.856,
          v: 408
        },
        {
          t: 29700,
          o: 109.856,
          h: 109.865,
          l: 109.845,
          c: 109.854,
          v: 428
        },
        {
          t: 30600,
          o: 109.854,
          h: 109.86,
          l: 109.828,
          c: 109.838,
          v: 407
        },
        {
          t: 31500,
          o: 109.838,
          h: 109.84,
          l: 109.825,
          c: 109.832,
          v: 343
        },
        {
          t: 32400,
          o: 109.834,
          h: 109.837,
          l: 109.812,
          c: 109.815,
          v: 367
        },
        {
          t: 33300,
          o: 109.816,
          h: 109.849,
          l: 109.816,
          c: 109.847,
          v: 251
        },
        {
          t: 34200,
          o: 109.846,
          h: 109.868,
          l: 109.846,
          c: 109.864,
          v: 278
        },
        {
          t: 35100,
          o: 109.864,
          h: 109.872,
          l: 109.857,
          c: 109.865,
          v: 277
        },
        {
          t: 36000,
          o: 109.864,
          h: 109.871,
          l: 109.853,
          c: 109.866,
          v: 211
        },
        {
          t: 36900,
          o: 109.865,
          h: 109.876,
          l: 109.854,
          c: 109.858,
          v: 204
        },
        {
          t: 37800,
          o: 109.858,
          h: 109.867,
          l: 109.857,
          c: 109.864,
          v: 153
        },
        {
          t: 38700,
          o: 109.864,
          h: 109.876,
          l: 109.864,
          c: 109.869,
          v: 142
        },
        {
          t: 39600,
          o: 109.87,
          h: 109.879,
          l: 109.862,
          c: 109.875,
          v: 198
        },
        {
          t: 40500,
          o: 109.874,
          h: 109.878,
          l: 109.868,
          c: 109.872,
          v: 257
        },
        {
          t: 41400,
          o: 109.871,
          h: 109.875,
          l: 109.861,
          c: 109.869,
          v: 222
        },
        {
          t: 42300,
          o: 109.869,
          h: 109.884,
          l: 109.864,
          c: 109.881,
          v: 256
        },
        {
          t: 43200,
          o: 109.882,
          h: 109.887,
          l: 109.875,
          c: 109.88,
          v: 325
        },
        {
          t: 44100,
          o: 109.879,
          h: 109.888,
          l: 109.879,
          c: 109.884,
          v: 234
        },
        {
          t: 45000,
          o: 109.884,
          h: 109.887,
          l: 109.861,
          c: 109.872,
          v: 282
        },
        {
          t: 45900,
          o: 109.873,
          h: 109.879,
          l: 109.868,
          c: 109.873,
          v: 196
        },
        {
          t: 46800,
          o: 109.872,
          h: 109.876,
          l: 109.861,
          c: 109.87,
          v: 186
        },
        {
          t: 47700,
          o: 109.869,
          h: 109.872,
          l: 109.864,
          c: 109.865,
          v: 109
        },
        {
          t: 48600,
          o: 109.865,
          h: 109.876,
          l: 109.859,
          c: 109.869,
          v: 117
        },
        {
          t: 49500,
          o: 109.869,
          h: 109.87,
          l: 109.859,
          c: 109.867,
          v: 126
        },
        {
          t: 50400,
          o: 109.866,
          h: 109.871,
          l: 109.858,
          c: 109.859,
          v: 125
        },
        {
          t: 51300,
          o: 109.858,
          h: 109.874,
          l: 109.858,
          c: 109.871,
          v: 134
        },
        {
          t: 52200,
          o: 109.871,
          h: 109.877,
          l: 109.863,
          c: 109.872,
          v: 158
        },
        {
          t: 53100,
          o: 109.873,
          h: 109.926,
          l: 109.872,
          c: 109.911,
          v: 344
        },
        {
          t: 54000,
          o: 109.911,
          h: 109.917,
          l: 109.893,
          c: 109.902,
          v: 247
        },
        {
          t: 54900,
          o: 109.901,
          h: 109.905,
          l: 109.899,
          c: 109.901,
          v: 104
        },
        {
          t: 55800,
          o: 109.9,
          h: 109.902,
          l: 109.884,
          c: 109.9,
          v: 127
        },
        {
          t: 56700,
          o: 109.901,
          h: 109.956,
          l: 109.895,
          c: 109.937,
          v: 373
        },
        {
          t: 57600,
          o: 109.935,
          h: 109.943,
          l: 109.914,
          c: 109.919,
          v: 215
        },
        {
          t: 58500,
          o: 109.919,
          h: 109.931,
          l: 109.907,
          c: 109.915,
          v: 147
        },
        {
          t: 59400,
          o: 109.914,
          h: 109.921,
          l: 109.906,
          c: 109.908,
          v: 115
        },
        {
          t: 60300,
          o: 109.909,
          h: 109.914,
          l: 109.9,
          c: 109.912,
          v: 140
        },
        {
          t: 61200,
          o: 109.913,
          h: 109.919,
          l: 109.905,
          c: 109.908,
          v: 313
        },
        {
          t: 62100,
          o: 109.909,
          h: 109.911,
          l: 109.901,
          c: 109.901,
          v: 128
        },
        {
          t: 63000,
          o: 109.903,
          h: 109.911,
          l: 109.896,
          c: 109.906,
          v: 195
        },
        {
          t: 63900,
          o: 109.907,
          h: 109.921,
          l: 109.907,
          c: 109.917,
          v: 256
        },
        {
          t: 64800,
          o: 109.918,
          h: 109.943,
          l: 109.914,
          c: 109.92,
          v: 261
        },
        {
          t: 65700,
          o: 109.92,
          h: 109.927,
          l: 109.917,
          c: 109.927,
          v: 702
        },
        {
          t: 66600,
          o: 109.926,
          h: 109.934,
          l: 109.921,
          c: 109.925,
          v: 1509
        },
        {
          t: 67500,
          o: 109.925,
          h: 109.934,
          l: 109.924,
          c: 109.93,
          v: 2341
        },
        {
          t: 68400,
          o: 109.93,
          h: 109.936,
          l: 109.923,
          c: 109.926,
          v: 1431
        },
        {
          t: 69300,
          o: 109.927,
          h: 109.928,
          l: 109.923,
          c: 109.923,
          v: 97
        },
        {
          t: 70200,
          o: 109.924,
          h: 109.935,
          l: 109.92,
          c: 109.932,
          v: 178
        },
        {
          t: 71100,
          o: 109.932,
          h: 109.936,
          l: 109.928,
          c: 109.935,
          v: 160
        },
        {
          t: 72000,
          o: 109.934,
          h: 109.936,
          l: 109.929,
          c: 109.931,
          v: 163
        },
        {
          t: 72900,
          o: 109.93,
          h: 109.936,
          l: 109.927,
          c: 109.929,
          v: 216
        },
        {
          t: 73800,
          o: 109.927,
          h: 109.929,
          l: 109.918,
          c: 109.924,
          v: 431
        },
        {
          t: 74700,
          o: 109.924,
          h: 109.924,
          l: 109.913,
          c: 109.913,
          v: 1903
        },
        {
          t: 75600,
          o: 109.913,
          h: 109.916,
          l: 109.858,
          c: 109.864,
          v: 590
        },
        {
          t: 76500,
          o: 109.864,
          h: 109.878,
          l: 109.843,
          c: 109.859,
          v: 608
        },
        {
          t: 77400,
          o: 109.858,
          h: 109.913,
          l: 109.858,
          c: 109.875,
          v: 646
        },
        {
          t: 78300,
          o: 109.878,
          h: 109.885,
          l: 109.854,
          c: 109.869,
          v: 206
        },
        {
          t: 79200,
          o: 109.865,
          h: 109.875,
          l: 109.803,
          c: 109.804,
          v: 703
        },
        {
          t: 80100,
          o: 109.801,
          h: 109.834,
          l: 109.791,
          c: 109.809,
          v: 855
        },
        {
          t: 81000,
          o: 109.808,
          h: 109.834,
          l: 109.805,
          c: 109.834,
          v: 839
        },
        {
          t: 81900,
          o: 109.832,
          h: 109.856,
          l: 109.812,
          c: 109.848,
          v: 1124
        },
        {
          t: 82800,
          o: 109.845,
          h: 109.861,
          l: 109.804,
          c: 109.813,
          v: 983
        },
        {
          t: 83700,
          o: 109.813,
          h: 109.829,
          l: 109.793,
          c: 109.819,
          v: 716
        },
        {
          t: 84600,
          o: 109.821,
          h: 109.842,
          l: 109.818,
          c: 109.838,
          v: 626
        },
        {
          t: 85500,
          o: 109.837,
          h: 109.848,
          l: 109.818,
          c: 109.83,
          v: 753
        }
      ]
    },
    {
      d: 1581984000,
      b: [
        {
          t: 0,
          o: 109.831,
          h: 109.834,
          l: 109.8,
          c: 109.819,
          v: 1040
        },
        {
          t: 900,
          o: 109.82,
          h: 109.831,
          l: 109.801,
          c: 109.821,
          v: 752
        },
        {
          t: 1800,
          o: 109.821,
          h: 109.821,
          l: 109.79,
          c: 109.796,
          v: 850
        },
        {
          t: 2700,
          o: 109.795,
          h: 109.826,
          l: 109.701,
          c: 109.749,
          v: 1196
        },
        {
          t: 3600,
          o: 109.748,
          h: 109.782,
          l: 109.711,
          c: 109.717,
          v: 1322
        },
        {
          t: 4500,
          o: 109.718,
          h: 109.772,
          l: 109.709,
          c: 109.762,
          v: 1025
        },
        {
          t: 5400,
          o: 109.762,
          h: 109.793,
          l: 109.742,
          c: 109.752,
          v: 1160
        },
        {
          t: 6300,
          o: 109.753,
          h: 109.772,
          l: 109.717,
          c: 109.766,
          v: 946
        },
        {
          t: 7200,
          o: 109.766,
          h: 109.79,
          l: 109.742,
          c: 109.765,
          v: 1141
        },
        {
          t: 8100,
          o: 109.764,
          h: 109.768,
          l: 109.742,
          c: 109.743,
          v: 715
        },
        {
          t: 9000,
          o: 109.744,
          h: 109.746,
          l: 109.666,
          c: 109.693,
          v: 1159
        },
        {
          t: 9900,
          o: 109.69,
          h: 109.69,
          l: 109.656,
          c: 109.672,
          v: 759
        },
        {
          t: 10800,
          o: 109.673,
          h: 109.697,
          l: 109.652,
          c: 109.688,
          v: 861
        },
        {
          t: 11700,
          o: 109.687,
          h: 109.69,
          l: 109.665,
          c: 109.686,
          v: 509
        },
        {
          t: 12600,
          o: 109.687,
          h: 109.695,
          l: 109.678,
          c: 109.687,
          v: 512
        },
        {
          t: 13500,
          o: 109.687,
          h: 109.708,
          l: 109.686,
          c: 109.701,
          v: 346
        },
        {
          t: 14400,
          o: 109.701,
          h: 109.71,
          l: 109.695,
          c: 109.701,
          v: 361
        },
        {
          t: 15300,
          o: 109.702,
          h: 109.728,
          l: 109.701,
          c: 109.723,
          v: 343
        },
        {
          t: 16200,
          o: 109.722,
          h: 109.753,
          l: 109.716,
          c: 109.746,
          v: 245
        },
        {
          t: 17100,
          o: 109.747,
          h: 109.751,
          l: 109.738,
          c: 109.738,
          v: 284
        },
        {
          t: 18000,
          o: 109.734,
          h: 109.739,
          l: 109.72,
          c: 109.733,
          v: 413
        },
        {
          t: 18900,
          o: 109.733,
          h: 109.738,
          l: 109.715,
          c: 109.728,
          v: 393
        },
        {
          t: 19800,
          o: 109.725,
          h: 109.755,
          l: 109.722,
          c: 109.744,
          v: 511
        },
        {
          t: 20700,
          o: 109.745,
          h: 109.757,
          l: 109.735,
          c: 109.748,
          v: 637
        },
        {
          t: 21600,
          o: 109.748,
          h: 109.778,
          l: 109.732,
          c: 109.77,
          v: 580
        },
        {
          t: 22500,
          o: 109.773,
          h: 109.787,
          l: 109.756,
          c: 109.787,
          v: 573
        },
        {
          t: 23400,
          o: 109.788,
          h: 109.792,
          l: 109.765,
          c: 109.775,
          v: 365
        },
        {
          t: 24300,
          o: 109.772,
          h: 109.777,
          l: 109.757,
          c: 109.763,
          v: 383
        },
        {
          t: 25200,
          o: 109.765,
          h: 109.772,
          l: 109.746,
          c: 109.75,
          v: 592
        },
        {
          t: 26100,
          o: 109.749,
          h: 109.761,
          l: 109.726,
          c: 109.749,
          v: 613
        },
        {
          t: 27000,
          o: 109.751,
          h: 109.755,
          l: 109.683,
          c: 109.685,
          v: 795
        },
        {
          t: 27900,
          o: 109.684,
          h: 109.702,
          l: 109.67,
          c: 109.693,
          v: 564
        },
        {
          t: 28800,
          o: 109.695,
          h: 109.763,
          l: 109.695,
          c: 109.755,
          v: 1040
        },
        {
          t: 29700,
          o: 109.755,
          h: 109.766,
          l: 109.686,
          c: 109.69,
          v: 932
        },
        {
          t: 30600,
          o: 109.689,
          h: 109.731,
          l: 109.686,
          c: 109.718,
          v: 864
        },
        {
          t: 31500,
          o: 109.719,
          h: 109.757,
          l: 109.712,
          c: 109.743,
          v: 739
        },
        {
          t: 32400,
          o: 109.744,
          h: 109.744,
          l: 109.675,
          c: 109.676,
          v: 771
        },
        {
          t: 33300,
          o: 109.676,
          h: 109.712,
          l: 109.656,
          c: 109.711,
          v: 631
        },
        {
          t: 34200,
          o: 109.71,
          h: 109.739,
          l: 109.698,
          c: 109.727,
          v: 467
        },
        {
          t: 35100,
          o: 109.726,
          h: 109.736,
          l: 109.712,
          c: 109.716,
          v: 463
        },
        {
          t: 36000,
          o: 109.715,
          h: 109.726,
          l: 109.685,
          c: 109.71,
          v: 681
        },
        {
          t: 36900,
          o: 109.711,
          h: 109.725,
          l: 109.68,
          c: 109.686,
          v: 566
        },
        {
          t: 37800,
          o: 109.688,
          h: 109.737,
          l: 109.681,
          c: 109.714,
          v: 1070
        },
        {
          t: 38700,
          o: 109.715,
          h: 109.73,
          l: 109.701,
          c: 109.719,
          v: 589
        },
        {
          t: 39600,
          o: 109.718,
          h: 109.726,
          l: 109.697,
          c: 109.704,
          v: 359
        },
        {
          t: 40500,
          o: 109.702,
          h: 109.725,
          l: 109.699,
          c: 109.719,
          v: 234
        },
        {
          t: 41400,
          o: 109.717,
          h: 109.724,
          l: 109.7,
          c: 109.709,
          v: 399
        },
        {
          t: 42300,
          o: 109.709,
          h: 109.722,
          l: 109.694,
          c: 109.715,
          v: 430
        },
        {
          t: 43200,
          o: 109.716,
          h: 109.726,
          l: 109.706,
          c: 109.718,
          v: 397
        },
        {
          t: 44100,
          o: 109.719,
          h: 109.755,
          l: 109.709,
          c: 109.739,
          v: 417
        },
        {
          t: 45000,
          o: 109.737,
          h: 109.75,
          l: 109.723,
          c: 109.74,
          v: 480
        },
        {
          t: 45900,
          o: 109.739,
          h: 109.771,
          l: 109.737,
          c: 109.764,
          v: 475
        },
        {
          t: 46800,
          o: 109.764,
          h: 109.765,
          l: 109.728,
          c: 109.744,
          v: 365
        },
        {
          t: 47700,
          o: 109.745,
          h: 109.771,
          l: 109.738,
          c: 109.743,
          v: 564
        },
        {
          t: 48600,
          o: 109.751,
          h: 109.797,
          l: 109.737,
          c: 109.767,
          v: 1045
        },
        {
          t: 49500,
          o: 109.766,
          h: 109.788,
          l: 109.758,
          c: 109.775,
          v: 508
        },
        {
          t: 50400,
          o: 109.776,
          h: 109.817,
          l: 109.763,
          c: 109.812,
          v: 710
        },
        {
          t: 51300,
          o: 109.812,
          h: 109.879,
          l: 109.811,
          c: 109.85,
          v: 857
        },
        {
          t: 52200,
          o: 109.851,
          h: 109.941,
          l: 109.84,
          c: 109.931,
          v: 1628
        },
        {
          t: 53100,
          o: 109.93,
          h: 109.942,
          l: 109.901,
          c: 109.913,
          v: 1069
        },
        {
          t: 54000,
          o: 109.914,
          h: 109.928,
          l: 109.867,
          c: 109.892,
          v: 837
        },
        {
          t: 54900,
          o: 109.892,
          h: 109.899,
          l: 109.8,
          c: 109.8,
          v: 748
        },
        {
          t: 55800,
          o: 109.806,
          h: 109.849,
          l: 109.804,
          c: 109.829,
          v: 1146
        },
        {
          t: 56700,
          o: 109.828,
          h: 109.862,
          l: 109.792,
          c: 109.801,
          v: 937
        },
        {
          t: 57600,
          o: 109.802,
          h: 109.825,
          l: 109.781,
          c: 109.808,
          v: 865
        },
        {
          t: 58500,
          o: 109.807,
          h: 109.807,
          l: 109.769,
          c: 109.782,
          v: 832
        },
        {
          t: 59400,
          o: 109.781,
          h: 109.829,
          l: 109.78,
          c: 109.792,
          v: 760
        },
        {
          t: 60300,
          o: 109.791,
          h: 109.822,
          l: 109.783,
          c: 109.8,
          v: 618
        },
        {
          t: 61200,
          o: 109.801,
          h: 109.85,
          l: 109.801,
          c: 109.839,
          v: 766
        },
        {
          t: 62100,
          o: 109.841,
          h: 109.852,
          l: 109.83,
          c: 109.846,
          v: 437
        },
        {
          t: 63000,
          o: 109.847,
          h: 109.881,
          l: 109.846,
          c: 109.879,
          v: 418
        },
        {
          t: 63900,
          o: 109.879,
          h: 109.884,
          l: 109.862,
          c: 109.863,
          v: 355
        },
        {
          t: 64800,
          o: 109.864,
          h: 109.899,
          l: 109.863,
          c: 109.877,
          v: 492
        },
        {
          t: 65700,
          o: 109.876,
          h: 109.877,
          l: 109.854,
          c: 109.866,
          v: 313
        },
        {
          t: 66600,
          o: 109.866,
          h: 109.875,
          l: 109.838,
          c: 109.841,
          v: 411
        },
        {
          t: 67500,
          o: 109.839,
          h: 109.859,
          l: 109.822,
          c: 109.846,
          v: 348
        },
        {
          t: 68400,
          o: 109.847,
          h: 109.852,
          l: 109.834,
          c: 109.843,
          v: 394
        },
        {
          t: 69300,
          o: 109.844,
          h: 109.875,
          l: 109.842,
          c: 109.873,
          v: 493
        },
        {
          t: 70200,
          o: 109.874,
          h: 109.876,
          l: 109.852,
          c: 109.874,
          v: 447
        },
        {
          t: 71100,
          o: 109.874,
          h: 109.879,
          l: 109.853,
          c: 109.862,
          v: 400
        },
        {
          t: 72000,
          o: 109.866,
          h: 109.877,
          l: 109.856,
          c: 109.873,
          v: 404
        },
        {
          t: 72900,
          o: 109.874,
          h: 109.879,
          l: 109.861,
          c: 109.867,
          v: 285
        },
        {
          t: 73800,
          o: 109.867,
          h: 109.867,
          l: 109.846,
          c: 109.861,
          v: 274
        },
        {
          t: 74700,
          o: 109.861,
          h: 109.862,
          l: 109.844,
          c: 109.85,
          v: 492
        },
        {
          t: 75600,
          o: 109.851,
          h: 109.869,
          l: 109.842,
          c: 109.86,
          v: 190
        },
        {
          t: 76500,
          o: 109.861,
          h: 109.87,
          l: 109.854,
          c: 109.866,
          v: 94
        },
        {
          t: 77400,
          o: 109.865,
          h: 109.876,
          l: 109.865,
          c: 109.869,
          v: 232
        },
        {
          t: 78300,
          o: 109.87,
          h: 109.875,
          l: 109.858,
          c: 109.865,
          v: 172
        },
        {
          t: 79200,
          o: 109.865,
          h: 109.878,
          l: 109.862,
          c: 109.873,
          v: 104
        },
        {
          t: 80100,
          o: 109.873,
          h: 109.881,
          l: 109.831,
          c: 109.844,
          v: 111
        },
        {
          t: 81000,
          o: 109.844,
          h: 109.871,
          l: 109.843,
          c: 109.852,
          v: 357
        },
        {
          t: 81900,
          o: 109.852,
          h: 109.862,
          l: 109.844,
          c: 109.855,
          v: 360
        },
        {
          t: 82800,
          o: 109.853,
          h: 109.885,
          l: 109.841,
          c: 109.874,
          v: 1306
        },
        {
          t: 83700,
          o: 109.874,
          h: 109.887,
          l: 109.874,
          c: 109.882,
          v: 1017
        },
        {
          t: 84600,
          o: 109.882,
          h: 109.891,
          l: 109.865,
          c: 109.883,
          v: 449
        },
        {
          t: 85500,
          o: 109.882,
          h: 109.929,
          l: 109.88,
          c: 109.915,
          v: 709
        }
      ]
    },
    {
      d: 1582070400,
      b: [
        {
          t: 0,
          o: 109.916,
          h: 109.945,
          l: 109.913,
          c: 109.921,
          v: 868
        },
        {
          t: 900,
          o: 109.922,
          h: 109.93,
          l: 109.897,
          c: 109.924,
          v: 556
        },
        {
          t: 1800,
          o: 109.923,
          h: 109.939,
          l: 109.906,
          c: 109.928,
          v: 736
        },
        {
          t: 2700,
          o: 109.926,
          h: 109.928,
          l: 109.893,
          c: 109.906,
          v: 741
        },
        {
          t: 3600,
          o: 109.907,
          h: 109.909,
          l: 109.863,
          c: 109.868,
          v: 948
        },
        {
          t: 4500,
          o: 109.869,
          h: 109.921,
          l: 109.856,
          c: 109.92,
          v: 823
        },
        {
          t: 5400,
          o: 109.921,
          h: 109.956,
          l: 109.921,
          c: 109.944,
          v: 838
        },
        {
          t: 6300,
          o: 109.943,
          h: 109.975,
          l: 109.943,
          c: 109.964,
          v: 976
        },
        {
          t: 7200,
          o: 109.965,
          h: 110.039,
          l: 109.965,
          c: 110.025,
          v: 880
        },
        {
          t: 8100,
          o: 110.025,
          h: 110.106,
          l: 110.012,
          c: 110.018,
          v: 1136
        },
        {
          t: 9000,
          o: 110.019,
          h: 110.046,
          l: 110.01,
          c: 110.036,
          v: 681
        },
        {
          t: 9900,
          o: 110.037,
          h: 110.041,
          l: 110.016,
          c: 110.029,
          v: 362
        },
        {
          t: 10800,
          o: 110.029,
          h: 110.044,
          l: 110.019,
          c: 110.04,
          v: 378
        },
        {
          t: 11700,
          o: 110.044,
          h: 110.065,
          l: 110.043,
          c: 110.046,
          v: 500
        },
        {
          t: 12600,
          o: 110.048,
          h: 110.05,
          l: 110.029,
          c: 110.045,
          v: 375
        },
        {
          t: 13500,
          o: 110.045,
          h: 110.061,
          l: 110.04,
          c: 110.052,
          v: 351
        },
        {
          t: 14400,
          o: 110.055,
          h: 110.063,
          l: 110.038,
          c: 110.052,
          v: 391
        },
        {
          t: 15300,
          o: 110.051,
          h: 110.057,
          l: 110.032,
          c: 110.032,
          v: 233
        },
        {
          t: 16200,
          o: 110.032,
          h: 110.038,
          l: 110.018,
          c: 110.034,
          v: 232
        },
        {
          t: 17100,
          o: 110.035,
          h: 110.036,
          l: 110.002,
          c: 110.021,
          v: 442
        },
        {
          t: 18000,
          o: 110.021,
          h: 110.035,
          l: 110.016,
          c: 110.035,
          v: 325
        },
        {
          t: 18900,
          o: 110.034,
          h: 110.039,
          l: 110.017,
          c: 110.027,
          v: 269
        },
        {
          t: 19800,
          o: 110.027,
          h: 110.04,
          l: 110.022,
          c: 110.038,
          v: 281
        },
        {
          t: 20700,
          o: 110.036,
          h: 110.059,
          l: 110.034,
          c: 110.045,
          v: 344
        },
        {
          t: 21600,
          o: 110.044,
          h: 110.056,
          l: 110.019,
          c: 110.022,
          v: 471
        },
        {
          t: 22500,
          o: 110.022,
          h: 110.046,
          l: 110.016,
          c: 110.039,
          v: 397
        },
        {
          t: 23400,
          o: 110.04,
          h: 110.05,
          l: 110.032,
          c: 110.033,
          v: 382
        },
        {
          t: 24300,
          o: 110.032,
          h: 110.048,
          l: 110.03,
          c: 110.038,
          v: 265
        },
        {
          t: 25200,
          o: 110.039,
          h: 110.062,
          l: 110.033,
          c: 110.054,
          v: 399
        },
        {
          t: 26100,
          o: 110.055,
          h: 110.101,
          l: 110.046,
          c: 110.095,
          v: 638
        },
        {
          t: 27000,
          o: 110.093,
          h: 110.141,
          l: 110.085,
          c: 110.107,
          v: 732
        },
        {
          t: 27900,
          o: 110.108,
          h: 110.131,
          l: 110.094,
          c: 110.114,
          v: 636
        },
        {
          t: 28800,
          o: 110.109,
          h: 110.115,
          l: 110.059,
          c: 110.069,
          v: 870
        },
        {
          t: 29700,
          o: 110.069,
          h: 110.103,
          l: 110.059,
          c: 110.084,
          v: 559
        },
        {
          t: 30600,
          o: 110.085,
          h: 110.171,
          l: 110.072,
          c: 110.161,
          v: 794
        },
        {
          t: 31500,
          o: 110.16,
          h: 110.184,
          l: 110.136,
          c: 110.176,
          v: 583
        },
        {
          t: 32400,
          o: 110.177,
          h: 110.211,
          l: 110.161,
          c: 110.205,
          v: 718
        },
        {
          t: 33300,
          o: 110.206,
          h: 110.244,
          l: 110.191,
          c: 110.234,
          v: 576
        },
        {
          t: 34200,
          o: 110.236,
          h: 110.242,
          l: 110.172,
          c: 110.191,
          v: 651
        },
        {
          t: 35100,
          o: 110.192,
          h: 110.242,
          l: 110.186,
          c: 110.231,
          v: 449
        },
        {
          t: 36000,
          o: 110.23,
          h: 110.239,
          l: 110.21,
          c: 110.238,
          v: 457
        },
        {
          t: 36900,
          o: 110.237,
          h: 110.34,
          l: 110.235,
          c: 110.313,
          v: 503
        },
        {
          t: 37800,
          o: 110.312,
          h: 110.361,
          l: 110.301,
          c: 110.329,
          v: 710
        },
        {
          t: 38700,
          o: 110.326,
          h: 110.338,
          l: 110.299,
          c: 110.321,
          v: 480
        },
        {
          t: 39600,
          o: 110.321,
          h: 110.376,
          l: 110.302,
          c: 110.356,
          v: 641
        },
        {
          t: 40500,
          o: 110.358,
          h: 110.421,
          l: 110.351,
          c: 110.416,
          v: 415
        },
        {
          t: 41400,
          o: 110.417,
          h: 110.418,
          l: 110.375,
          c: 110.41,
          v: 523
        },
        {
          t: 42300,
          o: 110.411,
          h: 110.442,
          l: 110.398,
          c: 110.439,
          v: 466
        },
        {
          t: 43200,
          o: 110.44,
          h: 110.445,
          l: 110.401,
          c: 110.408,
          v: 587
        },
        {
          t: 44100,
          o: 110.408,
          h: 110.442,
          l: 110.408,
          c: 110.439,
          v: 520
        },
        {
          t: 45000,
          o: 110.439,
          h: 110.448,
          l: 110.422,
          c: 110.44,
          v: 417
        },
        {
          t: 45900,
          o: 110.441,
          h: 110.507,
          l: 110.433,
          c: 110.481,
          v: 511
        },
        {
          t: 46800,
          o: 110.48,
          h: 110.517,
          l: 110.467,
          c: 110.51,
          v: 417
        },
        {
          t: 47700,
          o: 110.509,
          h: 110.625,
          l: 110.508,
          c: 110.582,
          v: 797
        },
        {
          t: 48600,
          o: 110.586,
          h: 110.765,
          l: 110.572,
          c: 110.751,
          v: 1383
        },
        {
          t: 49500,
          o: 110.75,
          h: 110.795,
          l: 110.646,
          c: 110.649,
          v: 1022
        },
        {
          t: 50400,
          o: 110.651,
          h: 110.747,
          l: 110.647,
          c: 110.745,
          v: 680
        },
        {
          t: 51300,
          o: 110.742,
          h: 110.75,
          l: 110.698,
          c: 110.719,
          v: 636
        },
        {
          t: 52200,
          o: 110.72,
          h: 110.768,
          l: 110.706,
          c: 110.744,
          v: 1385
        },
        {
          t: 53100,
          o: 110.745,
          h: 110.884,
          l: 110.745,
          c: 110.826,
          v: 861
        },
        {
          t: 54000,
          o: 110.825,
          h: 110.829,
          l: 110.763,
          c: 110.825,
          v: 823
        },
        {
          t: 54900,
          o: 110.826,
          h: 110.887,
          l: 110.813,
          c: 110.884,
          v: 765
        },
        {
          t: 55800,
          o: 110.885,
          h: 110.949,
          l: 110.883,
          c: 110.925,
          v: 750
        },
        {
          t: 56700,
          o: 110.925,
          h: 110.972,
          l: 110.912,
          c: 110.945,
          v: 844
        },
        {
          t: 57600,
          o: 110.94,
          h: 111.011,
          l: 110.915,
          c: 110.994,
          v: 783
        },
        {
          t: 58500,
          o: 110.99,
          h: 111.15,
          l: 110.972,
          c: 111.085,
          v: 1168
        },
        {
          t: 59400,
          o: 111.084,
          h: 111.306,
          l: 111.081,
          c: 111.192,
          v: 1219
        },
        {
          t: 60300,
          o: 111.192,
          h: 111.212,
          l: 111.134,
          c: 111.173,
          v: 814
        },
        {
          t: 61200,
          o: 111.172,
          h: 111.191,
          l: 111.129,
          c: 111.185,
          v: 590
        },
        {
          t: 62100,
          o: 111.186,
          h: 111.19,
          l: 111.108,
          c: 111.108,
          v: 528
        },
        {
          t: 63000,
          o: 111.107,
          h: 111.181,
          l: 111.106,
          c: 111.154,
          v: 498
        },
        {
          t: 63900,
          o: 111.154,
          h: 111.251,
          l: 111.146,
          c: 111.251,
          v: 749
        },
        {
          t: 64800,
          o: 111.25,
          h: 111.293,
          l: 111.233,
          c: 111.262,
          v: 510
        },
        {
          t: 65700,
          o: 111.263,
          h: 111.276,
          l: 111.252,
          c: 111.261,
          v: 283
        },
        {
          t: 66600,
          o: 111.26,
          h: 111.294,
          l: 111.251,
          c: 111.291,
          v: 183
        },
        {
          t: 67500,
          o: 111.286,
          h: 111.433,
          l: 111.285,
          c: 111.351,
          v: 819
        },
        {
          t: 68400,
          o: 111.34,
          h: 111.469,
          l: 111.336,
          c: 111.46,
          v: 1029
        },
        {
          t: 69300,
          o: 111.46,
          h: 111.473,
          l: 111.421,
          c: 111.433,
          v: 722
        },
        {
          t: 70200,
          o: 111.43,
          h: 111.474,
          l: 111.399,
          c: 111.47,
          v: 410
        },
        {
          t: 71100,
          o: 111.471,
          h: 111.584,
          l: 111.47,
          c: 111.571,
          v: 1196
        },
        {
          t: 72000,
          o: 111.567,
          h: 111.584,
          l: 111.471,
          c: 111.477,
          v: 1075
        },
        {
          t: 72900,
          o: 111.477,
          h: 111.482,
          l: 111.353,
          c: 111.394,
          v: 826
        },
        {
          t: 73800,
          o: 111.393,
          h: 111.396,
          l: 111.28,
          c: 111.284,
          v: 900
        },
        {
          t: 74700,
          o: 111.283,
          h: 111.294,
          l: 111.226,
          c: 111.236,
          v: 903
        },
        {
          t: 75600,
          o: 111.237,
          h: 111.275,
          l: 111.221,
          c: 111.236,
          v: 527
        },
        {
          t: 76500,
          o: 111.237,
          h: 111.322,
          l: 111.235,
          c: 111.322,
          v: 322
        },
        {
          t: 77400,
          o: 111.321,
          h: 111.349,
          l: 111.311,
          c: 111.328,
          v: 255
        },
        {
          t: 78300,
          o: 111.329,
          h: 111.359,
          l: 111.322,
          c: 111.351,
          v: 282
        },
        {
          t: 79200,
          o: 111.352,
          h: 111.358,
          l: 111.298,
          c: 111.332,
          v: 282
        },
        {
          t: 80100,
          o: 111.308,
          h: 111.356,
          l: 111.308,
          c: 111.319,
          v: 139
        },
        {
          t: 81000,
          o: 111.32,
          h: 111.328,
          l: 111.261,
          c: 111.261,
          v: 155
        },
        {
          t: 81900,
          o: 111.261,
          h: 111.275,
          l: 111.23,
          c: 111.235,
          v: 274
        },
        {
          t: 82800,
          o: 111.234,
          h: 111.292,
          l: 111.223,
          c: 111.281,
          v: 459
        },
        {
          t: 83700,
          o: 111.279,
          h: 111.281,
          l: 111.243,
          c: 111.246,
          v: 471
        },
        {
          t: 84600,
          o: 111.247,
          h: 111.295,
          l: 111.221,
          c: 111.224,
          v: 642
        },
        {
          t: 85500,
          o: 111.224,
          h: 111.24,
          l: 111.186,
          c: 111.191,
          v: 889
        }
      ]
    },
    {
      d: 1582156800,
      b: [
        {
          t: 0,
          o: 111.192,
          h: 111.302,
          l: 111.139,
          c: 111.144,
          v: 2134
        },
        {
          t: 900,
          o: 111.144,
          h: 111.19,
          l: 111.106,
          c: 111.157,
          v: 1358
        },
        {
          t: 1800,
          o: 111.156,
          h: 111.185,
          l: 111.11,
          c: 111.148,
          v: 1134
        },
        {
          t: 2700,
          o: 111.15,
          h: 111.255,
          l: 111.15,
          c: 111.203,
          v: 1362
        },
        {
          t: 3600,
          o: 111.202,
          h: 111.217,
          l: 111.129,
          c: 111.165,
          v: 1022
        },
        {
          t: 4500,
          o: 111.164,
          h: 111.179,
          l: 111.132,
          c: 111.176,
          v: 871
        },
        {
          t: 5400,
          o: 111.176,
          h: 111.268,
          l: 111.141,
          c: 111.257,
          v: 1292
        },
        {
          t: 6300,
          o: 111.256,
          h: 111.284,
          l: 111.235,
          c: 111.239,
          v: 1105
        },
        {
          t: 7200,
          o: 111.238,
          h: 111.287,
          l: 111.237,
          c: 111.276,
          v: 1008
        },
        {
          t: 8100,
          o: 111.277,
          h: 111.291,
          l: 111.229,
          c: 111.247,
          v: 851
        },
        {
          t: 9000,
          o: 111.248,
          h: 111.323,
          l: 111.227,
          c: 111.268,
          v: 1558
        },
        {
          t: 9900,
          o: 111.267,
          h: 111.34,
          l: 111.263,
          c: 111.333,
          v: 1052
        },
        {
          t: 10800,
          o: 111.333,
          h: 111.456,
          l: 111.316,
          c: 111.448,
          v: 1075
        },
        {
          t: 11700,
          o: 111.448,
          h: 111.486,
          l: 111.346,
          c: 111.37,
          v: 1169
        },
        {
          t: 12600,
          o: 111.369,
          h: 111.383,
          l: 111.319,
          c: 111.349,
          v: 1308
        },
        {
          t: 13500,
          o: 111.351,
          h: 111.367,
          l: 111.321,
          c: 111.358,
          v: 913
        },
        {
          t: 14400,
          o: 111.354,
          h: 111.408,
          l: 111.352,
          c: 111.391,
          v: 775
        },
        {
          t: 15300,
          o: 111.39,
          h: 111.423,
          l: 111.372,
          c: 111.388,
          v: 432
        },
        {
          t: 16200,
          o: 111.383,
          h: 111.383,
          l: 111.338,
          c: 111.37,
          v: 585
        },
        {
          t: 17100,
          o: 111.372,
          h: 111.404,
          l: 111.362,
          c: 111.363,
          v: 614
        },
        {
          t: 18000,
          o: 111.364,
          h: 111.384,
          l: 111.345,
          c: 111.358,
          v: 803
        },
        {
          t: 18900,
          o: 111.358,
          h: 111.38,
          l: 111.353,
          c: 111.376,
          v: 621
        },
        {
          t: 19800,
          o: 111.375,
          h: 111.377,
          l: 111.338,
          c: 111.368,
          v: 654
        },
        {
          t: 20700,
          o: 111.369,
          h: 111.417,
          l: 111.368,
          c: 111.389,
          v: 653
        },
        {
          t: 21600,
          o: 111.39,
          h: 111.42,
          l: 111.378,
          c: 111.42,
          v: 571
        },
        {
          t: 22500,
          o: 111.42,
          h: 111.479,
          l: 111.412,
          c: 111.429,
          v: 798
        },
        {
          t: 23400,
          o: 111.432,
          h: 111.486,
          l: 111.426,
          c: 111.482,
          v: 572
        },
        {
          t: 24300,
          o: 111.483,
          h: 111.554,
          l: 111.48,
          c: 111.541,
          v: 958
        },
        {
          t: 25200,
          o: 111.539,
          h: 111.555,
          l: 111.506,
          c: 111.54,
          v: 856
        },
        {
          t: 26100,
          o: 111.541,
          h: 111.823,
          l: 111.541,
          c: 111.797,
          v: 1855
        },
        {
          t: 27000,
          o: 111.797,
          h: 111.845,
          l: 111.643,
          c: 111.663,
          v: 1354
        },
        {
          t: 27900,
          o: 111.66,
          h: 111.701,
          l: 111.654,
          c: 111.68,
          v: 833
        },
        {
          t: 28800,
          o: 111.681,
          h: 111.737,
          l: 111.633,
          c: 111.725,
          v: 1208
        },
        {
          t: 29700,
          o: 111.726,
          h: 111.762,
          l: 111.611,
          c: 111.635,
          v: 1361
        },
        {
          t: 30600,
          o: 111.635,
          h: 111.736,
          l: 111.619,
          c: 111.72,
          v: 916
        },
        {
          t: 31500,
          o: 111.721,
          h: 111.76,
          l: 111.7,
          c: 111.731,
          v: 759
        },
        {
          t: 32400,
          o: 111.732,
          h: 111.835,
          l: 111.732,
          c: 111.813,
          v: 844
        },
        {
          t: 33300,
          o: 111.812,
          h: 111.824,
          l: 111.754,
          c: 111.791,
          v: 724
        },
        {
          t: 34200,
          o: 111.79,
          h: 111.954,
          l: 111.788,
          c: 111.925,
          v: 1056
        },
        {
          t: 35100,
          o: 111.926,
          h: 112.015,
          l: 111.912,
          c: 111.968,
          v: 995
        },
        {
          t: 36000,
          o: 111.969,
          h: 112.031,
          l: 111.961,
          c: 111.971,
          v: 1065
        },
        {
          t: 36900,
          o: 111.971,
          h: 112.019,
          l: 111.915,
          c: 112.008,
          v: 1069
        },
        {
          t: 37800,
          o: 112.007,
          h: 112.093,
          l: 111.998,
          c: 112.064,
          v: 1144
        },
        {
          t: 38700,
          o: 112.065,
          h: 112.183,
          l: 112.046,
          c: 112.151,
          v: 1052
        },
        {
          t: 39600,
          o: 112.152,
          h: 112.153,
          l: 112.039,
          c: 112.126,
          v: 1090
        },
        {
          t: 40500,
          o: 112.123,
          h: 112.171,
          l: 112.122,
          c: 112.138,
          v: 784
        },
        {
          t: 41400,
          o: 112.139,
          h: 112.148,
          l: 112.042,
          c: 112.044,
          v: 798
        },
        {
          t: 42300,
          o: 112.043,
          h: 112.044,
          l: 111.829,
          c: 111.849,
          v: 1312
        },
        {
          t: 43200,
          o: 111.848,
          h: 111.963,
          l: 111.843,
          c: 111.897,
          v: 1050
        },
        {
          t: 44100,
          o: 111.895,
          h: 111.936,
          l: 111.863,
          c: 111.908,
          v: 781
        },
        {
          t: 45000,
          o: 111.91,
          h: 111.996,
          l: 111.907,
          c: 111.92,
          v: 777
        },
        {
          t: 45900,
          o: 111.921,
          h: 111.989,
          l: 111.918,
          c: 111.963,
          v: 694
        },
        {
          t: 46800,
          o: 111.964,
          h: 112.059,
          l: 111.948,
          c: 111.999,
          v: 801
        },
        {
          t: 47700,
          o: 112,
          h: 112.036,
          l: 111.926,
          c: 111.935,
          v: 965
        },
        {
          t: 48600,
          o: 111.935,
          h: 112.038,
          l: 111.873,
          c: 111.923,
          v: 1785
        },
        {
          t: 49500,
          o: 111.924,
          h: 111.998,
          l: 111.91,
          c: 111.996,
          v: 787
        },
        {
          t: 50400,
          o: 111.994,
          h: 112.018,
          l: 111.941,
          c: 111.968,
          v: 946
        },
        {
          t: 51300,
          o: 111.969,
          h: 112.127,
          l: 111.962,
          c: 112.084,
          v: 873
        },
        {
          t: 52200,
          o: 112.083,
          h: 112.141,
          l: 112.042,
          c: 112.121,
          v: 1365
        },
        {
          t: 53100,
          o: 112.119,
          h: 112.221,
          l: 112.087,
          c: 112.135,
          v: 1085
        },
        {
          t: 54000,
          o: 112.13,
          h: 112.18,
          l: 112.041,
          c: 112.066,
          v: 1093
        },
        {
          t: 54900,
          o: 112.065,
          h: 112.106,
          l: 111.947,
          c: 112.027,
          v: 931
        },
        {
          t: 55800,
          o: 112.03,
          h: 112.053,
          l: 112.011,
          c: 112.052,
          v: 680
        },
        {
          t: 56700,
          o: 112.053,
          h: 112.158,
          l: 112.053,
          c: 112.088,
          v: 941
        },
        {
          t: 57600,
          o: 112.088,
          h: 112.093,
          l: 111.946,
          c: 111.95,
          v: 1171
        },
        {
          t: 58500,
          o: 111.95,
          h: 112.002,
          l: 111.917,
          c: 111.929,
          v: 1577
        },
        {
          t: 59400,
          o: 111.927,
          h: 111.963,
          l: 111.693,
          c: 111.867,
          v: 2868
        },
        {
          t: 60300,
          o: 111.872,
          h: 111.9,
          l: 111.801,
          c: 111.853,
          v: 1690
        },
        {
          t: 61200,
          o: 111.849,
          h: 111.885,
          l: 111.816,
          c: 111.858,
          v: 1766
        },
        {
          t: 62100,
          o: 111.856,
          h: 111.856,
          l: 111.8,
          c: 111.838,
          v: 1282
        },
        {
          t: 63000,
          o: 111.84,
          h: 111.951,
          l: 111.833,
          c: 111.915,
          v: 1521
        },
        {
          t: 63900,
          o: 111.914,
          h: 112.031,
          l: 111.914,
          c: 112.016,
          v: 1176
        },
        {
          t: 64800,
          o: 112.015,
          h: 112.089,
          l: 111.987,
          c: 112.06,
          v: 1125
        },
        {
          t: 65700,
          o: 112.061,
          h: 112.074,
          l: 112.016,
          c: 112.03,
          v: 867
        },
        {
          t: 66600,
          o: 112.031,
          h: 112.069,
          l: 112.007,
          c: 112.03,
          v: 977
        },
        {
          t: 67500,
          o: 112.028,
          h: 112.043,
          l: 111.992,
          c: 112.006,
          v: 736
        },
        {
          t: 68400,
          o: 112.008,
          h: 112.067,
          l: 112.001,
          c: 112.026,
          v: 957
        },
        {
          t: 69300,
          o: 112.027,
          h: 112.051,
          l: 112.021,
          c: 112.031,
          v: 800
        },
        {
          t: 70200,
          o: 112.032,
          h: 112.072,
          l: 112.018,
          c: 112.061,
          v: 589
        },
        {
          t: 71100,
          o: 112.062,
          h: 112.092,
          l: 112.042,
          c: 112.055,
          v: 615
        },
        {
          t: 72000,
          o: 112.056,
          h: 112.082,
          l: 112.038,
          c: 112.059,
          v: 553
        },
        {
          t: 72900,
          o: 112.058,
          h: 112.089,
          l: 112.047,
          c: 112.055,
          v: 617
        },
        {
          t: 73800,
          o: 112.058,
          h: 112.067,
          l: 112.044,
          c: 112.065,
          v: 478
        },
        {
          t: 74700,
          o: 112.066,
          h: 112.092,
          l: 112.048,
          c: 112.068,
          v: 626
        },
        {
          t: 75600,
          o: 112.067,
          h: 112.1,
          l: 112.06,
          c: 112.08,
          v: 779
        },
        {
          t: 76500,
          o: 112.079,
          h: 112.175,
          l: 112.068,
          c: 112.151,
          v: 498
        },
        {
          t: 77400,
          o: 112.145,
          h: 112.145,
          l: 112.097,
          c: 112.097,
          v: 381
        },
        {
          t: 78300,
          o: 112.097,
          h: 112.118,
          l: 112.082,
          c: 112.085,
          v: 589
        },
        {
          t: 79200,
          o: 112.086,
          h: 112.093,
          l: 112.047,
          c: 112.057,
          v: 361
        },
        {
          t: 80100,
          o: 112.055,
          h: 112.059,
          l: 112.03,
          c: 112.034,
          v: 101
        },
        {
          t: 81000,
          o: 112.032,
          h: 112.047,
          l: 112.024,
          c: 112.042,
          v: 239
        },
        {
          t: 81900,
          o: 112.041,
          h: 112.06,
          l: 112.037,
          c: 112.054,
          v: 1149
        },
        {
          t: 82800,
          o: 112.051,
          h: 112.074,
          l: 112.038,
          c: 112.056,
          v: 746
        },
        {
          t: 83700,
          o: 112.056,
          h: 112.065,
          l: 112.034,
          c: 112.041,
          v: 562
        },
        {
          t: 84600,
          o: 112.04,
          h: 112.054,
          l: 112.006,
          c: 112.025,
          v: 829
        },
        {
          t: 85500,
          o: 112.026,
          h: 112.033,
          l: 111.943,
          c: 111.965,
          v: 1589
        }
      ]
    },
    {
      d: 1582243200,
      b: [
        {
          t: 0,
          o: 111.964,
          h: 112.061,
          l: 111.947,
          c: 112.041,
          v: 1825
        },
        {
          t: 900,
          o: 112.042,
          h: 112.131,
          l: 112.029,
          c: 112.112,
          v: 1532
        },
        {
          t: 1800,
          o: 112.111,
          h: 112.179,
          l: 112.076,
          c: 112.126,
          v: 1569
        },
        {
          t: 2700,
          o: 112.127,
          h: 112.127,
          l: 112.039,
          c: 112.107,
          v: 1793
        },
        {
          t: 3600,
          o: 112.111,
          h: 112.114,
          l: 112.025,
          c: 112.067,
          v: 1464
        },
        {
          t: 4500,
          o: 112.067,
          h: 112.116,
          l: 111.992,
          c: 111.999,
          v: 1401
        },
        {
          t: 5400,
          o: 112,
          h: 112.076,
          l: 112,
          c: 112.062,
          v: 1363
        },
        {
          t: 6300,
          o: 112.06,
          h: 112.067,
          l: 111.974,
          c: 112,
          v: 1369
        },
        {
          t: 7200,
          o: 112,
          h: 112.006,
          l: 111.934,
          c: 111.952,
          v: 1267
        },
        {
          t: 8100,
          o: 111.952,
          h: 111.982,
          l: 111.917,
          c: 111.936,
          v: 1237
        },
        {
          t: 9000,
          o: 111.934,
          h: 112.031,
          l: 111.932,
          c: 112.026,
          v: 934
        },
        {
          t: 9900,
          o: 112.025,
          h: 112.063,
          l: 112.003,
          c: 112.005,
          v: 799
        },
        {
          t: 10800,
          o: 112.005,
          h: 112.012,
          l: 111.975,
          c: 111.987,
          v: 645
        },
        {
          t: 11700,
          o: 111.988,
          h: 112.022,
          l: 111.988,
          c: 112.013,
          v: 605
        },
        {
          t: 12600,
          o: 112.015,
          h: 112.016,
          l: 111.967,
          c: 111.988,
          v: 612
        },
        {
          t: 13500,
          o: 111.989,
          h: 112.015,
          l: 111.985,
          c: 112.011,
          v: 410
        },
        {
          t: 14400,
          o: 112.012,
          h: 112.042,
          l: 111.999,
          c: 112.009,
          v: 578
        },
        {
          t: 15300,
          o: 112.01,
          h: 112.025,
          l: 111.998,
          c: 112.016,
          v: 485
        },
        {
          t: 16200,
          o: 112.016,
          h: 112.018,
          l: 111.98,
          c: 111.991,
          v: 489
        },
        {
          t: 17100,
          o: 111.992,
          h: 111.995,
          l: 111.965,
          c: 111.97,
          v: 579
        },
        {
          t: 18000,
          o: 111.97,
          h: 111.985,
          l: 111.96,
          c: 111.966,
          v: 514
        },
        {
          t: 18900,
          o: 111.968,
          h: 112.005,
          l: 111.923,
          c: 111.929,
          v: 719
        },
        {
          t: 19800,
          o: 111.928,
          h: 111.949,
          l: 111.921,
          c: 111.947,
          v: 725
        },
        {
          t: 20700,
          o: 111.947,
          h: 111.963,
          l: 111.937,
          c: 111.949,
          v: 577
        },
        {
          t: 21600,
          o: 111.949,
          h: 111.97,
          l: 111.922,
          c: 111.931,
          v: 743
        },
        {
          t: 22500,
          o: 111.93,
          h: 111.953,
          l: 111.92,
          c: 111.938,
          v: 513
        },
        {
          t: 23400,
          o: 111.937,
          h: 111.94,
          l: 111.916,
          c: 111.936,
          v: 413
        },
        {
          t: 24300,
          o: 111.936,
          h: 111.983,
          l: 111.935,
          c: 111.977,
          v: 433
        },
        {
          t: 25200,
          o: 111.972,
          h: 111.988,
          l: 111.922,
          c: 111.925,
          v: 892
        },
        {
          t: 26100,
          o: 111.924,
          h: 111.955,
          l: 111.849,
          c: 111.867,
          v: 1023
        },
        {
          t: 27000,
          o: 111.871,
          h: 111.872,
          l: 111.716,
          c: 111.721,
          v: 1109
        },
        {
          t: 27900,
          o: 111.721,
          h: 111.764,
          l: 111.678,
          c: 111.726,
          v: 1184
        },
        {
          t: 28800,
          o: 111.728,
          h: 111.742,
          l: 111.48,
          c: 111.532,
          v: 1917
        },
        {
          t: 29700,
          o: 111.534,
          h: 111.671,
          l: 111.525,
          c: 111.618,
          v: 1707
        },
        {
          t: 30600,
          o: 111.619,
          h: 111.712,
          l: 111.619,
          c: 111.676,
          v: 1975
        },
        {
          t: 31500,
          o: 111.679,
          h: 111.701,
          l: 111.618,
          c: 111.697,
          v: 1162
        },
        {
          t: 32400,
          o: 111.695,
          h: 111.751,
          l: 111.69,
          c: 111.741,
          v: 1279
        },
        {
          t: 33300,
          o: 111.74,
          h: 111.759,
          l: 111.72,
          c: 111.737,
          v: 858
        },
        {
          t: 34200,
          o: 111.738,
          h: 111.765,
          l: 111.718,
          c: 111.754,
          v: 787
        },
        {
          t: 35100,
          o: 111.755,
          h: 111.76,
          l: 111.684,
          c: 111.694,
          v: 905
        },
        {
          t: 36000,
          o: 111.692,
          h: 111.74,
          l: 111.692,
          c: 111.738,
          v: 816
        },
        {
          t: 36900,
          o: 111.739,
          h: 111.787,
          l: 111.726,
          c: 111.774,
          v: 698
        },
        {
          t: 37800,
          o: 111.774,
          h: 111.799,
          l: 111.757,
          c: 111.775,
          v: 487
        },
        {
          t: 38700,
          o: 111.774,
          h: 111.826,
          l: 111.773,
          c: 111.792,
          v: 634
        },
        {
          t: 39600,
          o: 111.791,
          h: 111.811,
          l: 111.768,
          c: 111.784,
          v: 617
        },
        {
          t: 40500,
          o: 111.787,
          h: 111.799,
          l: 111.752,
          c: 111.762,
          v: 538
        },
        {
          t: 41400,
          o: 111.763,
          h: 111.823,
          l: 111.761,
          c: 111.818,
          v: 567
        },
        {
          t: 42300,
          o: 111.818,
          h: 111.885,
          l: 111.792,
          c: 111.875,
          v: 655
        },
        {
          t: 43200,
          o: 111.877,
          h: 111.949,
          l: 111.862,
          c: 111.925,
          v: 721
        },
        {
          t: 44100,
          o: 111.924,
          h: 112.015,
          l: 111.918,
          c: 112.012,
          v: 784
        },
        {
          t: 45000,
          o: 112.011,
          h: 112.012,
          l: 111.933,
          c: 111.962,
          v: 746
        },
        {
          t: 45900,
          o: 111.965,
          h: 112.026,
          l: 111.961,
          c: 111.992,
          v: 670
        },
        {
          t: 46800,
          o: 111.992,
          h: 112.005,
          l: 111.952,
          c: 111.979,
          v: 667
        },
        {
          t: 47700,
          o: 111.977,
          h: 111.998,
          l: 111.963,
          c: 111.975,
          v: 712
        },
        {
          t: 48600,
          o: 111.974,
          h: 111.976,
          l: 111.905,
          c: 111.923,
          v: 745
        },
        {
          t: 49500,
          o: 111.924,
          h: 111.924,
          l: 111.825,
          c: 111.839,
          v: 837
        },
        {
          t: 50400,
          o: 111.84,
          h: 111.919,
          l: 111.838,
          c: 111.909,
          v: 665
        },
        {
          t: 51300,
          o: 111.908,
          h: 111.931,
          l: 111.892,
          c: 111.922,
          v: 626
        },
        {
          t: 52200,
          o: 111.921,
          h: 111.931,
          l: 111.814,
          c: 111.853,
          v: 1460
        },
        {
          t: 53100,
          o: 111.803,
          h: 111.803,
          l: 111.598,
          c: 111.714,
          v: 2405
        },
        {
          t: 54000,
          o: 111.717,
          h: 111.718,
          l: 111.466,
          c: 111.519,
          v: 2464
        },
        {
          t: 54900,
          o: 111.52,
          h: 111.615,
          l: 111.504,
          c: 111.58,
          v: 1940
        },
        {
          t: 55800,
          o: 111.58,
          h: 111.622,
          l: 111.519,
          c: 111.569,
          v: 1657
        },
        {
          t: 56700,
          o: 111.571,
          h: 111.716,
          l: 111.519,
          c: 111.687,
          v: 1845
        },
        {
          t: 57600,
          o: 111.691,
          h: 111.733,
          l: 111.669,
          c: 111.716,
          v: 1187
        },
        {
          t: 58500,
          o: 111.716,
          h: 111.732,
          l: 111.674,
          c: 111.68,
          v: 1024
        },
        {
          t: 59400,
          o: 111.679,
          h: 111.738,
          l: 111.679,
          c: 111.738,
          v: 966
        },
        {
          t: 60300,
          o: 111.739,
          h: 111.764,
          l: 111.724,
          c: 111.736,
          v: 829
        },
        {
          t: 61200,
          o: 111.737,
          h: 111.761,
          l: 111.703,
          c: 111.715,
          v: 749
        },
        {
          t: 62100,
          o: 111.714,
          h: 111.736,
          l: 111.698,
          c: 111.707,
          v: 665
        },
        {
          t: 63000,
          o: 111.706,
          h: 111.711,
          l: 111.674,
          c: 111.69,
          v: 804
        },
        {
          t: 63900,
          o: 111.689,
          h: 111.689,
          l: 111.621,
          c: 111.655,
          v: 790
        },
        {
          t: 64800,
          o: 111.653,
          h: 111.7,
          l: 111.623,
          c: 111.643,
          v: 786
        },
        {
          t: 65700,
          o: 111.643,
          h: 111.663,
          l: 111.634,
          c: 111.662,
          v: 692
        },
        {
          t: 66600,
          o: 111.662,
          h: 111.664,
          l: 111.616,
          c: 111.617,
          v: 660
        },
        {
          t: 67500,
          o: 111.615,
          h: 111.635,
          l: 111.601,
          c: 111.617,
          v: 679
        },
        {
          t: 68400,
          o: 111.617,
          h: 111.663,
          l: 111.612,
          c: 111.646,
          v: 774
        },
        {
          t: 69300,
          o: 111.648,
          h: 111.65,
          l: 111.614,
          c: 111.637,
          v: 630
        },
        {
          t: 70200,
          o: 111.636,
          h: 111.661,
          l: 111.601,
          c: 111.601,
          v: 628
        },
        {
          t: 71100,
          o: 111.603,
          h: 111.615,
          l: 111.588,
          c: 111.596,
          v: 516
        },
        {
          t: 72000,
          o: 111.595,
          h: 111.614,
          l: 111.568,
          c: 111.569,
          v: 630
        },
        {
          t: 72900,
          o: 111.568,
          h: 111.57,
          l: 111.531,
          c: 111.569,
          v: 643
        },
        {
          t: 73800,
          o: 111.569,
          h: 111.578,
          l: 111.512,
          c: 111.538,
          v: 756
        },
        {
          t: 74700,
          o: 111.539,
          h: 111.592,
          l: 111.537,
          c: 111.589,
          v: 614
        },
        {
          t: 75600,
          o: 111.591,
          h: 111.611,
          l: 111.575,
          c: 111.593,
          v: 475
        },
        {
          t: 76500,
          o: 111.594,
          h: 111.598,
          l: 111.576,
          c: 111.584,
          v: 502
        },
        {
          t: 77400,
          o: 111.584,
          h: 111.585,
          l: 111.568,
          c: 111.576,
          v: 1245
        }
      ]
    },
    {
      d: 1582416000,
      b: [
        {
          t: 79200,
          o: 111.383,
          h: 111.495,
          l: 111.381,
          c: 111.491,
          v: 523
        },
        {
          t: 80100,
          o: 111.487,
          h: 111.493,
          l: 111.466,
          c: 111.487,
          v: 815
        },
        {
          t: 81000,
          o: 111.486,
          h: 111.542,
          l: 111.485,
          c: 111.528,
          v: 1022
        },
        {
          t: 81900,
          o: 111.527,
          h: 111.547,
          l: 111.42,
          c: 111.424,
          v: 828
        },
        {
          t: 82800,
          o: 111.424,
          h: 111.49,
          l: 111.375,
          c: 111.424,
          v: 1519
        },
        {
          t: 83700,
          o: 111.424,
          h: 111.555,
          l: 111.405,
          c: 111.517,
          v: 1926
        },
        {
          t: 84600,
          o: 111.519,
          h: 111.592,
          l: 111.519,
          c: 111.589,
          v: 1293
        },
        {
          t: 85500,
          o: 111.59,
          h: 111.591,
          l: 111.507,
          c: 111.542,
          v: 1136
        }
      ]
    },
    {
      d: 1582502400,
      b: [
        {
          t: 0,
          o: 111.541,
          h: 111.595,
          l: 111.502,
          c: 111.564,
          v: 1345
        },
        {
          t: 900,
          o: 111.565,
          h: 111.661,
          l: 111.562,
          c: 111.653,
          v: 1347
        },
        {
          t: 1800,
          o: 111.654,
          h: 111.666,
          l: 111.602,
          c: 111.649,
          v: 1091
        },
        {
          t: 2700,
          o: 111.649,
          h: 111.677,
          l: 111.541,
          c: 111.577,
          v: 1311
        },
        {
          t: 3600,
          o: 111.577,
          h: 111.6,
          l: 111.549,
          c: 111.565,
          v: 1325
        },
        {
          t: 4500,
          o: 111.563,
          h: 111.6,
          l: 111.511,
          c: 111.533,
          v: 1307
        },
        {
          t: 5400,
          o: 111.532,
          h: 111.561,
          l: 111.487,
          c: 111.501,
          v: 1116
        },
        {
          t: 6300,
          o: 111.502,
          h: 111.592,
          l: 111.499,
          c: 111.583,
          v: 817
        },
        {
          t: 7200,
          o: 111.58,
          h: 111.588,
          l: 111.548,
          c: 111.548,
          v: 852
        },
        {
          t: 8100,
          o: 111.549,
          h: 111.562,
          l: 111.518,
          c: 111.527,
          v: 668
        },
        {
          t: 9000,
          o: 111.526,
          h: 111.527,
          l: 111.477,
          c: 111.503,
          v: 1018
        },
        {
          t: 9900,
          o: 111.502,
          h: 111.505,
          l: 111.441,
          c: 111.453,
          v: 944
        },
        {
          t: 10800,
          o: 111.453,
          h: 111.536,
          l: 111.432,
          c: 111.526,
          v: 954
        },
        {
          t: 11700,
          o: 111.527,
          h: 111.583,
          l: 111.525,
          c: 111.573,
          v: 750
        },
        {
          t: 12600,
          o: 111.574,
          h: 111.583,
          l: 111.537,
          c: 111.54,
          v: 636
        },
        {
          t: 13500,
          o: 111.541,
          h: 111.557,
          l: 111.504,
          c: 111.548,
          v: 519
        },
        {
          t: 14400,
          o: 111.549,
          h: 111.562,
          l: 111.543,
          c: 111.544,
          v: 356
        },
        {
          t: 15300,
          o: 111.545,
          h: 111.556,
          l: 111.531,
          c: 111.536,
          v: 193
        },
        {
          t: 16200,
          o: 111.535,
          h: 111.555,
          l: 111.526,
          c: 111.548,
          v: 248
        },
        {
          t: 17100,
          o: 111.545,
          h: 111.568,
          l: 111.538,
          c: 111.562,
          v: 327
        },
        {
          t: 18000,
          o: 111.562,
          h: 111.578,
          l: 111.558,
          c: 111.569,
          v: 530
        },
        {
          t: 18900,
          o: 111.568,
          h: 111.627,
          l: 111.567,
          c: 111.611,
          v: 547
        },
        {
          t: 19800,
          o: 111.612,
          h: 111.617,
          l: 111.576,
          c: 111.579,
          v: 414
        },
        {
          t: 20700,
          o: 111.578,
          h: 111.587,
          l: 111.557,
          c: 111.57,
          v: 455
        },
        {
          t: 21600,
          o: 111.57,
          h: 111.601,
          l: 111.566,
          c: 111.57,
          v: 524
        },
        {
          t: 22500,
          o: 111.572,
          h: 111.586,
          l: 111.559,
          c: 111.566,
          v: 519
        },
        {
          t: 23400,
          o: 111.567,
          h: 111.574,
          l: 111.546,
          c: 111.549,
          v: 411
        },
        {
          t: 24300,
          o: 111.547,
          h: 111.571,
          l: 111.522,
          c: 111.57,
          v: 548
        },
        {
          t: 25200,
          o: 111.569,
          h: 111.586,
          l: 111.495,
          c: 111.502,
          v: 1665
        },
        {
          t: 26100,
          o: 111.501,
          h: 111.586,
          l: 111.498,
          c: 111.561,
          v: 1085
        },
        {
          t: 27000,
          o: 111.559,
          h: 111.608,
          l: 111.536,
          c: 111.555,
          v: 974
        },
        {
          t: 27900,
          o: 111.552,
          h: 111.552,
          l: 111.503,
          c: 111.538,
          v: 1082
        },
        {
          t: 28800,
          o: 111.535,
          h: 111.538,
          l: 111.432,
          c: 111.475,
          v: 1924
        },
        {
          t: 29700,
          o: 111.474,
          h: 111.51,
          l: 111.435,
          c: 111.479,
          v: 1828
        },
        {
          t: 30600,
          o: 111.477,
          h: 111.482,
          l: 111.317,
          c: 111.393,
          v: 2231
        },
        {
          t: 31500,
          o: 111.392,
          h: 111.397,
          l: 111.297,
          c: 111.381,
          v: 1721
        },
        {
          t: 32400,
          o: 111.38,
          h: 111.418,
          l: 111.306,
          c: 111.315,
          v: 2233
        },
        {
          t: 33300,
          o: 111.312,
          h: 111.313,
          l: 111.235,
          c: 111.276,
          v: 2047
        },
        {
          t: 34200,
          o: 111.277,
          h: 111.319,
          l: 111.237,
          c: 111.318,
          v: 1924
        },
        {
          t: 35100,
          o: 111.32,
          h: 111.332,
          l: 111.275,
          c: 111.31,
          v: 1375
        },
        {
          t: 36000,
          o: 111.309,
          h: 111.321,
          l: 111.277,
          c: 111.28,
          v: 1259
        },
        {
          t: 36900,
          o: 111.279,
          h: 111.312,
          l: 111.276,
          c: 111.298,
          v: 773
        },
        {
          t: 37800,
          o: 111.297,
          h: 111.333,
          l: 111.293,
          c: 111.326,
          v: 792
        },
        {
          t: 38700,
          o: 111.329,
          h: 111.341,
          l: 111.276,
          c: 111.286,
          v: 1161
        },
        {
          t: 39600,
          o: 111.287,
          h: 111.295,
          l: 111.238,
          c: 111.247,
          v: 1230
        },
        {
          t: 40500,
          o: 111.246,
          h: 111.274,
          l: 111.193,
          c: 111.272,
          v: 947
        },
        {
          t: 41400,
          o: 111.271,
          h: 111.321,
          l: 111.253,
          c: 111.302,
          v: 1158
        },
        {
          t: 42300,
          o: 111.303,
          h: 111.331,
          l: 111.255,
          c: 111.308,
          v: 1029
        },
        {
          t: 43200,
          o: 111.307,
          h: 111.421,
          l: 111.307,
          c: 111.399,
          v: 1294
        },
        {
          t: 44100,
          o: 111.398,
          h: 111.399,
          l: 111.345,
          c: 111.356,
          v: 952
        },
        {
          t: 45000,
          o: 111.356,
          h: 111.383,
          l: 111.311,
          c: 111.319,
          v: 953
        },
        {
          t: 45900,
          o: 111.319,
          h: 111.355,
          l: 111.317,
          c: 111.327,
          v: 834
        },
        {
          t: 46800,
          o: 111.325,
          h: 111.399,
          l: 111.316,
          c: 111.397,
          v: 1088
        },
        {
          t: 47700,
          o: 111.396,
          h: 111.407,
          l: 111.338,
          c: 111.365,
          v: 892
        },
        {
          t: 48600,
          o: 111.364,
          h: 111.376,
          l: 111.284,
          c: 111.313,
          v: 1258
        },
        {
          t: 49500,
          o: 111.312,
          h: 111.325,
          l: 111.271,
          c: 111.298,
          v: 994
        },
        {
          t: 50400,
          o: 111.299,
          h: 111.302,
          l: 111.044,
          c: 111.057,
          v: 1474
        },
        {
          t: 51300,
          o: 111.058,
          h: 111.059,
          l: 110.808,
          c: 110.827,
          v: 1970
        },
        {
          t: 52200,
          o: 110.836,
          h: 110.964,
          l: 110.825,
          c: 110.905,
          v: 2896
        },
        {
          t: 53100,
          o: 110.901,
          h: 110.914,
          l: 110.579,
          c: 110.745,
          v: 2506
        },
        {
          t: 54000,
          o: 110.746,
          h: 110.842,
          l: 110.644,
          c: 110.74,
          v: 2215
        },
        {
          t: 54900,
          o: 110.739,
          h: 110.76,
          l: 110.532,
          c: 110.554,
          v: 1862
        },
        {
          t: 55800,
          o: 110.551,
          h: 110.623,
          l: 110.462,
          c: 110.597,
          v: 2271
        },
        {
          t: 56700,
          o: 110.594,
          h: 110.681,
          l: 110.547,
          c: 110.652,
          v: 1637
        },
        {
          t: 57600,
          o: 110.648,
          h: 110.67,
          l: 110.603,
          c: 110.625,
          v: 1441
        },
        {
          t: 58500,
          o: 110.626,
          h: 110.663,
          l: 110.549,
          c: 110.565,
          v: 1286
        },
        {
          t: 59400,
          o: 110.564,
          h: 110.601,
          l: 110.518,
          c: 110.534,
          v: 1600
        },
        {
          t: 60300,
          o: 110.533,
          h: 110.59,
          l: 110.513,
          c: 110.561,
          v: 1475
        },
        {
          t: 61200,
          o: 110.557,
          h: 110.573,
          l: 110.462,
          c: 110.481,
          v: 1525
        },
        {
          t: 62100,
          o: 110.48,
          h: 110.514,
          l: 110.41,
          c: 110.439,
          v: 1580
        },
        {
          t: 63000,
          o: 110.438,
          h: 110.453,
          l: 110.384,
          c: 110.395,
          v: 1324
        },
        {
          t: 63900,
          o: 110.394,
          h: 110.431,
          l: 110.336,
          c: 110.364,
          v: 1363
        },
        {
          t: 64800,
          o: 110.358,
          h: 110.381,
          l: 110.327,
          c: 110.374,
          v: 1224
        },
        {
          t: 65700,
          o: 110.373,
          h: 110.487,
          l: 110.357,
          c: 110.483,
          v: 1184
        },
        {
          t: 66600,
          o: 110.482,
          h: 110.5,
          l: 110.457,
          c: 110.497,
          v: 1091
        },
        {
          t: 67500,
          o: 110.497,
          h: 110.538,
          l: 110.477,
          c: 110.531,
          v: 873
        },
        {
          t: 68400,
          o: 110.53,
          h: 110.589,
          l: 110.514,
          c: 110.57,
          v: 1262
        },
        {
          t: 69300,
          o: 110.569,
          h: 110.685,
          l: 110.555,
          c: 110.66,
          v: 1322
        },
        {
          t: 70200,
          o: 110.66,
          h: 110.731,
          l: 110.651,
          c: 110.659,
          v: 1425
        },
        {
          t: 71100,
          o: 110.658,
          h: 110.76,
          l: 110.658,
          c: 110.727,
          v: 1488
        },
        {
          t: 72000,
          o: 110.726,
          h: 110.804,
          l: 110.722,
          c: 110.758,
          v: 1450
        },
        {
          t: 72900,
          o: 110.758,
          h: 110.812,
          l: 110.724,
          c: 110.795,
          v: 1329
        },
        {
          t: 73800,
          o: 110.794,
          h: 110.803,
          l: 110.713,
          c: 110.719,
          v: 1368
        },
        {
          t: 74700,
          o: 110.72,
          h: 110.74,
          l: 110.677,
          c: 110.723,
          v: 1629
        },
        {
          t: 75600,
          o: 110.722,
          h: 110.74,
          l: 110.666,
          c: 110.72,
          v: 637
        },
        {
          t: 76500,
          o: 110.72,
          h: 110.767,
          l: 110.717,
          c: 110.717,
          v: 451
        },
        {
          t: 77400,
          o: 110.714,
          h: 110.714,
          l: 110.664,
          c: 110.665,
          v: 435
        },
        {
          t: 78300,
          o: 110.665,
          h: 110.723,
          l: 110.665,
          c: 110.705,
          v: 652
        },
        {
          t: 79200,
          o: 110.708,
          h: 110.708,
          l: 110.654,
          c: 110.671,
          v: 598
        },
        {
          t: 80100,
          o: 110.661,
          h: 110.683,
          l: 110.659,
          c: 110.665,
          v: 409
        },
        {
          t: 81000,
          o: 110.665,
          h: 110.687,
          l: 110.665,
          c: 110.673,
          v: 1239
        },
        {
          t: 81900,
          o: 110.675,
          h: 110.677,
          l: 110.655,
          c: 110.656,
          v: 2285
        },
        {
          t: 82800,
          o: 110.656,
          h: 110.808,
          l: 110.622,
          c: 110.807,
          v: 1294
        },
        {
          t: 83700,
          o: 110.807,
          h: 110.837,
          l: 110.731,
          c: 110.756,
          v: 1049
        },
        {
          t: 84600,
          o: 110.758,
          h: 110.826,
          l: 110.716,
          c: 110.804,
          v: 1584
        },
        {
          t: 85500,
          o: 110.806,
          h: 110.869,
          l: 110.784,
          c: 110.847,
          v: 1775
        }
      ]
    },
    {
      d: 1582588800,
      b: [
        {
          t: 0,
          o: 110.849,
          h: 110.973,
          l: 110.799,
          c: 110.952,
          v: 2824
        },
        {
          t: 900,
          o: 110.952,
          h: 110.97,
          l: 110.884,
          c: 110.928,
          v: 2008
        },
        {
          t: 1800,
          o: 110.927,
          h: 110.947,
          l: 110.876,
          c: 110.903,
          v: 1901
        },
        {
          t: 2700,
          o: 110.904,
          h: 110.935,
          l: 110.868,
          c: 110.891,
          v: 1717
        },
        {
          t: 3600,
          o: 110.892,
          h: 111.035,
          l: 110.867,
          c: 110.962,
          v: 2154
        },
        {
          t: 4500,
          o: 110.962,
          h: 111.024,
          l: 110.946,
          c: 110.977,
          v: 2034
        },
        {
          t: 5400,
          o: 110.976,
          h: 110.996,
          l: 110.933,
          c: 110.948,
          v: 2148
        },
        {
          t: 6300,
          o: 110.95,
          h: 110.98,
          l: 110.934,
          c: 110.937,
          v: 1426
        },
        {
          t: 7200,
          o: 110.936,
          h: 110.951,
          l: 110.843,
          c: 110.878,
          v: 1584
        },
        {
          t: 8100,
          o: 110.877,
          h: 110.922,
          l: 110.856,
          c: 110.914,
          v: 1323
        },
        {
          t: 9000,
          o: 110.916,
          h: 110.932,
          l: 110.881,
          c: 110.919,
          v: 1515
        },
        {
          t: 9900,
          o: 110.919,
          h: 110.933,
          l: 110.863,
          c: 110.879,
          v: 1229
        },
        {
          t: 10800,
          o: 110.878,
          h: 110.887,
          l: 110.814,
          c: 110.846,
          v: 1455
        },
        {
          t: 11700,
          o: 110.847,
          h: 110.863,
          l: 110.806,
          c: 110.813,
          v: 1168
        },
        {
          t: 12600,
          o: 110.813,
          h: 110.814,
          l: 110.772,
          c: 110.793,
          v: 1087
        },
        {
          t: 13500,
          o: 110.794,
          h: 110.849,
          l: 110.777,
          c: 110.836,
          v: 1178
        },
        {
          t: 14400,
          o: 110.837,
          h: 110.843,
          l: 110.806,
          c: 110.818,
          v: 820
        },
        {
          t: 15300,
          o: 110.818,
          h: 110.862,
          l: 110.813,
          c: 110.86,
          v: 711
        },
        {
          t: 16200,
          o: 110.861,
          h: 110.867,
          l: 110.814,
          c: 110.817,
          v: 737
        },
        {
          t: 17100,
          o: 110.816,
          h: 110.819,
          l: 110.787,
          c: 110.809,
          v: 694
        },
        {
          t: 18000,
          o: 110.809,
          h: 110.845,
          l: 110.804,
          c: 110.84,
          v: 959
        },
        {
          t: 18900,
          o: 110.839,
          h: 110.851,
          l: 110.798,
          c: 110.808,
          v: 924
        },
        {
          t: 19800,
          o: 110.807,
          h: 110.808,
          l: 110.777,
          c: 110.8,
          v: 970
        },
        {
          t: 20700,
          o: 110.799,
          h: 110.825,
          l: 110.787,
          c: 110.798,
          v: 892
        },
        {
          t: 21600,
          o: 110.799,
          h: 110.838,
          l: 110.763,
          c: 110.82,
          v: 1432
        },
        {
          t: 22500,
          o: 110.819,
          h: 110.866,
          l: 110.819,
          c: 110.854,
          v: 1517
        },
        {
          t: 23400,
          o: 110.853,
          h: 110.857,
          l: 110.81,
          c: 110.832,
          v: 924
        },
        {
          t: 24300,
          o: 110.832,
          h: 110.865,
          l: 110.814,
          c: 110.832,
          v: 992
        },
        {
          t: 25200,
          o: 110.829,
          h: 110.862,
          l: 110.799,
          c: 110.849,
          v: 1238
        },
        {
          t: 26100,
          o: 110.851,
          h: 110.853,
          l: 110.726,
          c: 110.738,
          v: 1481
        },
        {
          t: 27000,
          o: 110.739,
          h: 110.749,
          l: 110.639,
          c: 110.681,
          v: 1639
        },
        {
          t: 27900,
          o: 110.678,
          h: 110.722,
          l: 110.652,
          c: 110.712,
          v: 1456
        },
        {
          t: 28800,
          o: 110.711,
          h: 110.735,
          l: 110.615,
          c: 110.678,
          v: 2237
        },
        {
          t: 29700,
          o: 110.678,
          h: 110.683,
          l: 110.56,
          c: 110.598,
          v: 1930
        },
        {
          t: 30600,
          o: 110.599,
          h: 110.646,
          l: 110.556,
          c: 110.559,
          v: 2040
        },
        {
          t: 31500,
          o: 110.561,
          h: 110.578,
          l: 110.505,
          c: 110.51,
          v: 2072
        },
        {
          t: 32400,
          o: 110.509,
          h: 110.555,
          l: 110.378,
          c: 110.426,
          v: 2102
        },
        {
          t: 33300,
          o: 110.424,
          h: 110.447,
          l: 110.371,
          c: 110.426,
          v: 1757
        },
        {
          t: 34200,
          o: 110.426,
          h: 110.451,
          l: 110.411,
          c: 110.425,
          v: 1276
        },
        {
          t: 35100,
          o: 110.427,
          h: 110.471,
          l: 110.371,
          c: 110.377,
          v: 1313
        },
        {
          t: 36000,
          o: 110.375,
          h: 110.422,
          l: 110.351,
          c: 110.383,
          v: 1264
        },
        {
          t: 36900,
          o: 110.381,
          h: 110.396,
          l: 110.347,
          c: 110.367,
          v: 1344
        },
        {
          t: 37800,
          o: 110.365,
          h: 110.404,
          l: 110.357,
          c: 110.366,
          v: 1437
        },
        {
          t: 38700,
          o: 110.365,
          h: 110.56,
          l: 110.344,
          c: 110.483,
          v: 1615
        },
        {
          t: 39600,
          o: 110.484,
          h: 110.501,
          l: 110.438,
          c: 110.498,
          v: 1405
        },
        {
          t: 40500,
          o: 110.499,
          h: 110.5,
          l: 110.442,
          c: 110.487,
          v: 1250
        },
        {
          t: 41400,
          o: 110.486,
          h: 110.61,
          l: 110.473,
          c: 110.572,
          v: 1715
        },
        {
          t: 42300,
          o: 110.573,
          h: 110.578,
          l: 110.502,
          c: 110.513,
          v: 1300
        },
        {
          t: 43200,
          o: 110.512,
          h: 110.545,
          l: 110.469,
          c: 110.474,
          v: 1190
        },
        {
          t: 44100,
          o: 110.475,
          h: 110.643,
          l: 110.471,
          c: 110.574,
          v: 1804
        },
        {
          t: 45000,
          o: 110.573,
          h: 110.631,
          l: 110.547,
          c: 110.604,
          v: 1353
        },
        {
          t: 45900,
          o: 110.607,
          h: 110.708,
          l: 110.607,
          c: 110.667,
          v: 1357
        },
        {
          t: 46800,
          o: 110.666,
          h: 110.667,
          l: 110.59,
          c: 110.624,
          v: 1272
        },
        {
          t: 47700,
          o: 110.624,
          h: 110.625,
          l: 110.559,
          c: 110.562,
          v: 1038
        },
        {
          t: 48600,
          o: 110.563,
          h: 110.577,
          l: 110.525,
          c: 110.542,
          v: 1097
        },
        {
          t: 49500,
          o: 110.54,
          h: 110.628,
          l: 110.538,
          c: 110.597,
          v: 975
        },
        {
          t: 50400,
          o: 110.596,
          h: 110.613,
          l: 110.529,
          c: 110.541,
          v: 1180
        },
        {
          t: 51300,
          o: 110.539,
          h: 110.6,
          l: 110.538,
          c: 110.57,
          v: 1097
        },
        {
          t: 52200,
          o: 110.569,
          h: 110.597,
          l: 110.418,
          c: 110.421,
          v: 2690
        },
        {
          t: 53100,
          o: 110.42,
          h: 110.465,
          l: 110.374,
          c: 110.391,
          v: 2232
        },
        {
          t: 54000,
          o: 110.39,
          h: 110.455,
          l: 110.357,
          c: 110.375,
          v: 2568
        },
        {
          t: 54900,
          o: 110.379,
          h: 110.402,
          l: 110.065,
          c: 110.14,
          v: 2935
        },
        {
          t: 55800,
          o: 110.139,
          h: 110.181,
          l: 110.073,
          c: 110.172,
          v: 2543
        },
        {
          t: 56700,
          o: 110.172,
          h: 110.179,
          l: 110.001,
          c: 110.131,
          v: 2557
        },
        {
          t: 57600,
          o: 110.129,
          h: 110.159,
          l: 110.079,
          c: 110.126,
          v: 2589
        },
        {
          t: 58500,
          o: 110.128,
          h: 110.13,
          l: 110.029,
          c: 110.104,
          v: 2359
        },
        {
          t: 59400,
          o: 110.101,
          h: 110.142,
          l: 110.075,
          c: 110.098,
          v: 2028
        },
        {
          t: 60300,
          o: 110.098,
          h: 110.246,
          l: 110.093,
          c: 110.219,
          v: 1680
        },
        {
          t: 61200,
          o: 110.218,
          h: 110.249,
          l: 110.134,
          c: 110.136,
          v: 1673
        },
        {
          t: 62100,
          o: 110.136,
          h: 110.137,
          l: 110.046,
          c: 110.062,
          v: 1490
        },
        {
          t: 63000,
          o: 110.06,
          h: 110.13,
          l: 110.015,
          c: 110.015,
          v: 1349
        },
        {
          t: 63900,
          o: 110.015,
          h: 110.052,
          l: 109.997,
          c: 110.022,
          v: 1385
        },
        {
          t: 64800,
          o: 110.016,
          h: 110.067,
          l: 109.999,
          c: 110.04,
          v: 1936
        },
        {
          t: 65700,
          o: 110.033,
          h: 110.063,
          l: 109.997,
          c: 110.044,
          v: 1433
        },
        {
          t: 66600,
          o: 110.043,
          h: 110.051,
          l: 110.005,
          c: 110.039,
          v: 1586
        },
        {
          t: 67500,
          o: 110.033,
          h: 110.042,
          l: 109.997,
          c: 110.004,
          v: 1354
        },
        {
          t: 68400,
          o: 110.004,
          h: 110.065,
          l: 109.884,
          c: 110.027,
          v: 2798
        },
        {
          t: 69300,
          o: 110.027,
          h: 110.061,
          l: 109.979,
          c: 109.984,
          v: 2624
        },
        {
          t: 70200,
          o: 109.983,
          h: 110.01,
          l: 109.884,
          c: 110.001,
          v: 2722
        },
        {
          t: 71100,
          o: 110.003,
          h: 110.13,
          l: 109.974,
          c: 110.087,
          v: 2204
        },
        {
          t: 72000,
          o: 110.09,
          h: 110.171,
          l: 110.078,
          c: 110.08,
          v: 2379
        },
        {
          t: 72900,
          o: 110.081,
          h: 110.09,
          l: 110.007,
          c: 110.074,
          v: 2236
        },
        {
          t: 73800,
          o: 110.074,
          h: 110.105,
          l: 110.024,
          c: 110.101,
          v: 2289
        },
        {
          t: 74700,
          o: 110.103,
          h: 110.136,
          l: 110.085,
          c: 110.11,
          v: 2235
        },
        {
          t: 75600,
          o: 110.103,
          h: 110.125,
          l: 110.084,
          c: 110.123,
          v: 1020
        },
        {
          t: 76500,
          o: 110.123,
          h: 110.147,
          l: 110.116,
          c: 110.124,
          v: 436
        },
        {
          t: 77400,
          o: 110.121,
          h: 110.174,
          l: 110.11,
          c: 110.165,
          v: 604
        },
        {
          t: 78300,
          o: 110.165,
          h: 110.206,
          l: 110.164,
          c: 110.195,
          v: 668
        },
        {
          t: 79200,
          o: 110.196,
          h: 110.196,
          l: 110.144,
          c: 110.164,
          v: 236
        },
        {
          t: 80100,
          o: 110.158,
          h: 110.185,
          l: 110.155,
          c: 110.171,
          v: 474
        },
        {
          t: 81000,
          o: 110.168,
          h: 110.208,
          l: 110.166,
          c: 110.183,
          v: 447
        },
        {
          t: 81900,
          o: 110.182,
          h: 110.21,
          l: 110.181,
          c: 110.2,
          v: 773
        },
        {
          t: 82800,
          o: 110.198,
          h: 110.247,
          l: 110.174,
          c: 110.247,
          v: 807
        },
        {
          t: 83700,
          o: 110.247,
          h: 110.259,
          l: 110.227,
          c: 110.246,
          v: 828
        },
        {
          t: 84600,
          o: 110.249,
          h: 110.283,
          l: 110.237,
          c: 110.269,
          v: 768
        },
        {
          t: 85500,
          o: 110.27,
          h: 110.303,
          l: 110.256,
          c: 110.286,
          v: 1252
        }
      ]
    },
    {
      d: 1582675200,
      b: [
        {
          t: 0,
          o: 110.289,
          h: 110.362,
          l: 110.248,
          c: 110.337,
          v: 1709
        },
        {
          t: 900,
          o: 110.334,
          h: 110.361,
          l: 110.27,
          c: 110.334,
          v: 1895
        },
        {
          t: 1800,
          o: 110.335,
          h: 110.352,
          l: 110.271,
          c: 110.318,
          v: 1547
        },
        {
          t: 2700,
          o: 110.319,
          h: 110.323,
          l: 110.206,
          c: 110.206,
          v: 1867
        },
        {
          t: 3600,
          o: 110.208,
          h: 110.238,
          l: 110.135,
          c: 110.148,
          v: 2530
        },
        {
          t: 4500,
          o: 110.148,
          h: 110.202,
          l: 110.127,
          c: 110.141,
          v: 1998
        },
        {
          t: 5400,
          o: 110.142,
          h: 110.252,
          l: 110.141,
          c: 110.183,
          v: 2071
        },
        {
          t: 6300,
          o: 110.184,
          h: 110.251,
          l: 110.16,
          c: 110.217,
          v: 2146
        },
        {
          t: 7200,
          o: 110.216,
          h: 110.448,
          l: 110.167,
          c: 110.436,
          v: 2335
        },
        {
          t: 8100,
          o: 110.438,
          h: 110.438,
          l: 110.344,
          c: 110.347,
          v: 2174
        },
        {
          t: 9000,
          o: 110.348,
          h: 110.505,
          l: 110.347,
          c: 110.446,
          v: 2211
        },
        {
          t: 9900,
          o: 110.447,
          h: 110.474,
          l: 110.42,
          c: 110.458,
          v: 1651
        },
        {
          t: 10800,
          o: 110.458,
          h: 110.568,
          l: 110.458,
          c: 110.559,
          v: 1729
        },
        {
          t: 11700,
          o: 110.557,
          h: 110.575,
          l: 110.519,
          c: 110.524,
          v: 1263
        },
        {
          t: 12600,
          o: 110.523,
          h: 110.526,
          l: 110.48,
          c: 110.525,
          v: 1331
        },
        {
          t: 13500,
          o: 110.529,
          h: 110.535,
          l: 110.479,
          c: 110.49,
          v: 910
        },
        {
          t: 14400,
          o: 110.491,
          h: 110.494,
          l: 110.418,
          c: 110.43,
          v: 1119
        },
        {
          t: 15300,
          o: 110.431,
          h: 110.486,
          l: 110.417,
          c: 110.46,
          v: 990
        },
        {
          t: 16200,
          o: 110.46,
          h: 110.502,
          l: 110.437,
          c: 110.482,
          v: 1111
        },
        {
          t: 17100,
          o: 110.48,
          h: 110.506,
          l: 110.464,
          c: 110.476,
          v: 939
        },
        {
          t: 18000,
          o: 110.478,
          h: 110.479,
          l: 110.411,
          c: 110.442,
          v: 1343
        },
        {
          t: 18900,
          o: 110.442,
          h: 110.448,
          l: 110.402,
          c: 110.421,
          v: 1153
        },
        {
          t: 19800,
          o: 110.425,
          h: 110.426,
          l: 110.365,
          c: 110.395,
          v: 1085
        },
        {
          t: 20700,
          o: 110.395,
          h: 110.396,
          l: 110.356,
          c: 110.367,
          v: 1083
        },
        {
          t: 21600,
          o: 110.364,
          h: 110.379,
          l: 110.316,
          c: 110.318,
          v: 1267
        },
        {
          t: 22500,
          o: 110.319,
          h: 110.39,
          l: 110.312,
          c: 110.382,
          v: 1344
        },
        {
          t: 23400,
          o: 110.383,
          h: 110.431,
          l: 110.371,
          c: 110.382,
          v: 956
        },
        {
          t: 24300,
          o: 110.382,
          h: 110.416,
          l: 110.317,
          c: 110.349,
          v: 1011
        },
        {
          t: 25200,
          o: 110.347,
          h: 110.44,
          l: 110.328,
          c: 110.397,
          v: 1705
        },
        {
          t: 26100,
          o: 110.397,
          h: 110.427,
          l: 110.344,
          c: 110.41,
          v: 1773
        },
        {
          t: 27000,
          o: 110.409,
          h: 110.417,
          l: 110.356,
          c: 110.399,
          v: 1614
        },
        {
          t: 27900,
          o: 110.397,
          h: 110.472,
          l: 110.387,
          c: 110.468,
          v: 1666
        },
        {
          t: 28800,
          o: 110.466,
          h: 110.517,
          l: 110.442,
          c: 110.449,
          v: 2216
        },
        {
          t: 29700,
          o: 110.45,
          h: 110.465,
          l: 110.369,
          c: 110.373,
          v: 2008
        },
        {
          t: 30600,
          o: 110.372,
          h: 110.412,
          l: 110.319,
          c: 110.344,
          v: 2095
        },
        {
          t: 31500,
          o: 110.342,
          h: 110.35,
          l: 110.178,
          c: 110.239,
          v: 2787
        },
        {
          t: 32400,
          o: 110.238,
          h: 110.25,
          l: 110.124,
          c: 110.211,
          v: 2788
        },
        {
          t: 33300,
          o: 110.212,
          h: 110.279,
          l: 110.173,
          c: 110.23,
          v: 2599
        },
        {
          t: 34200,
          o: 110.231,
          h: 110.378,
          l: 110.198,
          c: 110.311,
          v: 2606
        },
        {
          t: 35100,
          o: 110.313,
          h: 110.358,
          l: 110.246,
          c: 110.294,
          v: 2506
        },
        {
          t: 36000,
          o: 110.295,
          h: 110.471,
          l: 110.292,
          c: 110.419,
          v: 2383
        },
        {
          t: 36900,
          o: 110.422,
          h: 110.44,
          l: 110.382,
          c: 110.437,
          v: 1855
        },
        {
          t: 37800,
          o: 110.437,
          h: 110.45,
          l: 110.393,
          c: 110.41,
          v: 1576
        },
        {
          t: 38700,
          o: 110.409,
          h: 110.415,
          l: 110.359,
          c: 110.383,
          v: 1761
        },
        {
          t: 39600,
          o: 110.383,
          h: 110.446,
          l: 110.382,
          c: 110.418,
          v: 1668
        },
        {
          t: 40500,
          o: 110.42,
          h: 110.507,
          l: 110.402,
          c: 110.457,
          v: 1497
        },
        {
          t: 41400,
          o: 110.459,
          h: 110.593,
          l: 110.458,
          c: 110.581,
          v: 1581
        },
        {
          t: 42300,
          o: 110.58,
          h: 110.583,
          l: 110.47,
          c: 110.478,
          v: 1438
        },
        {
          t: 43200,
          o: 110.477,
          h: 110.562,
          l: 110.42,
          c: 110.51,
          v: 2766
        },
        {
          t: 44100,
          o: 110.51,
          h: 110.539,
          l: 110.471,
          c: 110.5,
          v: 2017
        },
        {
          t: 45000,
          o: 110.498,
          h: 110.511,
          l: 110.432,
          c: 110.499,
          v: 1728
        },
        {
          t: 45900,
          o: 110.498,
          h: 110.525,
          l: 110.462,
          c: 110.469,
          v: 1779
        },
        {
          t: 46800,
          o: 110.47,
          h: 110.496,
          l: 110.441,
          c: 110.462,
          v: 1558
        },
        {
          t: 47700,
          o: 110.459,
          h: 110.475,
          l: 110.413,
          c: 110.44,
          v: 1773
        },
        {
          t: 48600,
          o: 110.439,
          h: 110.472,
          l: 110.42,
          c: 110.451,
          v: 1487
        },
        {
          t: 49500,
          o: 110.452,
          h: 110.499,
          l: 110.427,
          c: 110.462,
          v: 1331
        },
        {
          t: 50400,
          o: 110.461,
          h: 110.467,
          l: 110.407,
          c: 110.43,
          v: 1123
        },
        {
          t: 51300,
          o: 110.431,
          h: 110.532,
          l: 110.428,
          c: 110.499,
          v: 1192
        },
        {
          t: 52200,
          o: 110.499,
          h: 110.697,
          l: 110.478,
          c: 110.681,
          v: 2679
        },
        {
          t: 53100,
          o: 110.682,
          h: 110.691,
          l: 110.568,
          c: 110.581,
          v: 2039
        },
        {
          t: 54000,
          o: 110.582,
          h: 110.609,
          l: 110.495,
          c: 110.564,
          v: 2241
        },
        {
          t: 54900,
          o: 110.563,
          h: 110.606,
          l: 110.488,
          c: 110.5,
          v: 1878
        },
        {
          t: 55800,
          o: 110.501,
          h: 110.574,
          l: 110.495,
          c: 110.566,
          v: 1989
        },
        {
          t: 56700,
          o: 110.568,
          h: 110.591,
          l: 110.496,
          c: 110.56,
          v: 1486
        },
        {
          t: 57600,
          o: 110.565,
          h: 110.579,
          l: 110.445,
          c: 110.461,
          v: 1683
        },
        {
          t: 58500,
          o: 110.46,
          h: 110.553,
          l: 110.45,
          c: 110.523,
          v: 1259
        },
        {
          t: 59400,
          o: 110.522,
          h: 110.536,
          l: 110.456,
          c: 110.468,
          v: 1661
        },
        {
          t: 60300,
          o: 110.469,
          h: 110.471,
          l: 110.322,
          c: 110.361,
          v: 1680
        },
        {
          t: 61200,
          o: 110.364,
          h: 110.382,
          l: 110.275,
          c: 110.312,
          v: 2442
        },
        {
          t: 62100,
          o: 110.312,
          h: 110.354,
          l: 110.237,
          c: 110.35,
          v: 2172
        },
        {
          t: 63000,
          o: 110.355,
          h: 110.397,
          l: 110.348,
          c: 110.359,
          v: 1385
        },
        {
          t: 63900,
          o: 110.361,
          h: 110.454,
          l: 110.36,
          c: 110.449,
          v: 1490
        },
        {
          t: 64800,
          o: 110.448,
          h: 110.461,
          l: 110.407,
          c: 110.453,
          v: 1469
        },
        {
          t: 65700,
          o: 110.453,
          h: 110.486,
          l: 110.432,
          c: 110.473,
          v: 1482
        },
        {
          t: 66600,
          o: 110.472,
          h: 110.474,
          l: 110.168,
          c: 110.188,
          v: 2713
        },
        {
          t: 67500,
          o: 110.187,
          h: 110.398,
          l: 110.187,
          c: 110.285,
          v: 2999
        },
        {
          t: 68400,
          o: 110.285,
          h: 110.333,
          l: 110.273,
          c: 110.322,
          v: 2712
        },
        {
          t: 69300,
          o: 110.324,
          h: 110.337,
          l: 110.266,
          c: 110.271,
          v: 2111
        },
        {
          t: 70200,
          o: 110.272,
          h: 110.32,
          l: 110.195,
          c: 110.21,
          v: 2318
        },
        {
          t: 71100,
          o: 110.213,
          h: 110.252,
          l: 110.181,
          c: 110.197,
          v: 2290
        },
        {
          t: 72000,
          o: 110.197,
          h: 110.374,
          l: 110.197,
          c: 110.351,
          v: 2285
        },
        {
          t: 72900,
          o: 110.351,
          h: 110.399,
          l: 110.313,
          c: 110.348,
          v: 2268
        },
        {
          t: 73800,
          o: 110.347,
          h: 110.437,
          l: 110.333,
          c: 110.435,
          v: 2076
        },
        {
          t: 74700,
          o: 110.436,
          h: 110.455,
          l: 110.412,
          c: 110.438,
          v: 1610
        },
        {
          t: 75600,
          o: 110.443,
          h: 110.459,
          l: 110.412,
          c: 110.427,
          v: 942
        },
        {
          t: 76500,
          o: 110.427,
          h: 110.445,
          l: 110.42,
          c: 110.439,
          v: 705
        },
        {
          t: 77400,
          o: 110.44,
          h: 110.46,
          l: 110.422,
          c: 110.457,
          v: 1078
        },
        {
          t: 78300,
          o: 110.46,
          h: 110.464,
          l: 110.394,
          c: 110.41,
          v: 613
        },
        {
          t: 79200,
          o: 110.41,
          h: 110.412,
          l: 110.366,
          c: 110.374,
          v: 241
        },
        {
          t: 80100,
          o: 110.37,
          h: 110.395,
          l: 110.337,
          c: 110.38,
          v: 216
        },
        {
          t: 81000,
          o: 110.38,
          h: 110.402,
          l: 110.377,
          c: 110.399,
          v: 374
        },
        {
          t: 81900,
          o: 110.399,
          h: 110.424,
          l: 110.381,
          c: 110.414,
          v: 1277
        },
        {
          t: 82800,
          o: 110.415,
          h: 110.439,
          l: 110.364,
          c: 110.427,
          v: 1442
        },
        {
          t: 83700,
          o: 110.427,
          h: 110.428,
          l: 110.385,
          c: 110.414,
          v: 1299
        },
        {
          t: 84600,
          o: 110.41,
          h: 110.449,
          l: 110.329,
          c: 110.42,
          v: 1609
        },
        {
          t: 85500,
          o: 110.425,
          h: 110.45,
          l: 110.309,
          c: 110.375,
          v: 1942
        }
      ]
    },
    {
      d: 1582761600,
      b: [
        {
          t: 0,
          o: 110.376,
          h: 110.381,
          l: 110.181,
          c: 110.305,
          v: 3303
        },
        {
          t: 900,
          o: 110.307,
          h: 110.388,
          l: 110.28,
          c: 110.322,
          v: 2351
        },
        {
          t: 1800,
          o: 110.324,
          h: 110.398,
          l: 110.31,
          c: 110.386,
          v: 2113
        },
        {
          t: 2700,
          o: 110.384,
          h: 110.39,
          l: 110.28,
          c: 110.322,
          v: 2346
        },
        {
          t: 3600,
          o: 110.322,
          h: 110.343,
          l: 110.282,
          c: 110.303,
          v: 686
        },
        {
          t: 6300,
          o: 110.327,
          h: 110.339,
          l: 110.319,
          c: 110.336,
          v: 546
        },
        {
          t: 7200,
          o: 110.335,
          h: 110.338,
          l: 110.266,
          c: 110.315,
          v: 1916
        },
        {
          t: 8100,
          o: 110.316,
          h: 110.323,
          l: 110.237,
          c: 110.251,
          v: 1780
        },
        {
          t: 9000,
          o: 110.252,
          h: 110.306,
          l: 110.227,
          c: 110.283,
          v: 1815
        },
        {
          t: 9900,
          o: 110.282,
          h: 110.288,
          l: 110.213,
          c: 110.235,
          v: 1738
        },
        {
          t: 10800,
          o: 110.235,
          h: 110.263,
          l: 110.228,
          c: 110.239,
          v: 1267
        },
        {
          t: 11700,
          o: 110.239,
          h: 110.289,
          l: 110.229,
          c: 110.27,
          v: 1459
        },
        {
          t: 12600,
          o: 110.271,
          h: 110.276,
          l: 110.229,
          c: 110.258,
          v: 1138
        },
        {
          t: 13500,
          o: 110.258,
          h: 110.263,
          l: 110.216,
          c: 110.226,
          v: 1125
        },
        {
          t: 14400,
          o: 110.226,
          h: 110.267,
          l: 110.225,
          c: 110.26,
          v: 1044
        },
        {
          t: 15300,
          o: 110.261,
          h: 110.266,
          l: 110.188,
          c: 110.199,
          v: 836
        },
        {
          t: 16200,
          o: 110.198,
          h: 110.212,
          l: 110.153,
          c: 110.178,
          v: 1274
        },
        {
          t: 17100,
          o: 110.179,
          h: 110.183,
          l: 109.99,
          c: 110.005,
          v: 1523
        },
        {
          t: 18000,
          o: 110.005,
          h: 110.155,
          l: 110.005,
          c: 110.091,
          v: 2071
        },
        {
          t: 18900,
          o: 110.093,
          h: 110.154,
          l: 110.087,
          c: 110.109,
          v: 1620
        },
        {
          t: 19800,
          o: 110.11,
          h: 110.116,
          l: 109.961,
          c: 109.982,
          v: 1771
        },
        {
          t: 20700,
          o: 109.987,
          h: 110.071,
          l: 109.983,
          c: 110.045,
          v: 1447
        },
        {
          t: 21600,
          o: 110.044,
          h: 110.059,
          l: 110.019,
          c: 110.019,
          v: 1544
        },
        {
          t: 22500,
          o: 110.021,
          h: 110.05,
          l: 109.964,
          c: 110.033,
          v: 1498
        },
        {
          t: 23400,
          o: 110.033,
          h: 110.086,
          l: 109.994,
          c: 110.038,
          v: 1567
        },
        {
          t: 24300,
          o: 110.037,
          h: 110.134,
          l: 110.034,
          c: 110.117,
          v: 1490
        },
        {
          t: 25200,
          o: 110.116,
          h: 110.179,
          l: 110.047,
          c: 110.128,
          v: 2595
        },
        {
          t: 26100,
          o: 110.125,
          h: 110.183,
          l: 110.105,
          c: 110.148,
          v: 2169
        },
        {
          t: 27000,
          o: 110.145,
          h: 110.219,
          l: 110.139,
          c: 110.158,
          v: 2210
        },
        {
          t: 27900,
          o: 110.159,
          h: 110.193,
          l: 110.083,
          c: 110.094,
          v: 1802
        },
        {
          t: 28800,
          o: 110.091,
          h: 110.145,
          l: 109.965,
          c: 110.006,
          v: 2685
        },
        {
          t: 29700,
          o: 110.007,
          h: 110.087,
          l: 109.973,
          c: 109.997,
          v: 2462
        },
        {
          t: 30600,
          o: 109.995,
          h: 110.012,
          l: 109.844,
          c: 109.929,
          v: 2393
        },
        {
          t: 31500,
          o: 109.93,
          h: 110.077,
          l: 109.905,
          c: 110.04,
          v: 2243
        },
        {
          t: 32400,
          o: 110.04,
          h: 110.119,
          l: 110.038,
          c: 110.081,
          v: 2133
        },
        {
          t: 33300,
          o: 110.083,
          h: 110.13,
          l: 110.037,
          c: 110.118,
          v: 1631
        },
        {
          t: 34200,
          o: 110.118,
          h: 110.176,
          l: 110.069,
          c: 110.127,
          v: 1643
        },
        {
          t: 35100,
          o: 110.127,
          h: 110.177,
          l: 110.122,
          c: 110.158,
          v: 1568
        },
        {
          t: 36000,
          o: 110.158,
          h: 110.237,
          l: 110.148,
          c: 110.189,
          v: 1598
        },
        {
          t: 36900,
          o: 110.191,
          h: 110.199,
          l: 110.103,
          c: 110.104,
          v: 1734
        },
        {
          t: 37800,
          o: 110.103,
          h: 110.165,
          l: 110.103,
          c: 110.114,
          v: 1258
        },
        {
          t: 38700,
          o: 110.117,
          h: 110.13,
          l: 110.047,
          c: 110.051,
          v: 1481
        },
        {
          t: 39600,
          o: 110.051,
          h: 110.073,
          l: 110.006,
          c: 110.067,
          v: 1335
        },
        {
          t: 40500,
          o: 110.067,
          h: 110.123,
          l: 110.06,
          c: 110.11,
          v: 1327
        },
        {
          t: 41400,
          o: 110.11,
          h: 110.118,
          l: 109.986,
          c: 110.033,
          v: 1714
        },
        {
          t: 42300,
          o: 110.033,
          h: 110.043,
          l: 109.946,
          c: 109.947,
          v: 1496
        },
        {
          t: 43200,
          o: 109.948,
          h: 110.01,
          l: 109.939,
          c: 109.988,
          v: 1396
        },
        {
          t: 44100,
          o: 109.987,
          h: 109.994,
          l: 109.84,
          c: 109.84,
          v: 1547
        },
        {
          t: 45000,
          o: 109.834,
          h: 109.949,
          l: 109.83,
          c: 109.891,
          v: 2152
        },
        {
          t: 45900,
          o: 109.891,
          h: 109.944,
          l: 109.859,
          c: 109.86,
          v: 1858
        },
        {
          t: 46800,
          o: 109.861,
          h: 109.906,
          l: 109.847,
          c: 109.86,
          v: 1699
        },
        {
          t: 47700,
          o: 109.858,
          h: 109.908,
          l: 109.761,
          c: 109.829,
          v: 1918
        },
        {
          t: 48600,
          o: 109.828,
          h: 109.929,
          l: 109.779,
          c: 109.857,
          v: 1842
        },
        {
          t: 49500,
          o: 109.856,
          h: 109.92,
          l: 109.824,
          c: 109.867,
          v: 1746
        },
        {
          t: 50400,
          o: 109.868,
          h: 109.947,
          l: 109.845,
          c: 109.889,
          v: 2150
        },
        {
          t: 51300,
          o: 109.888,
          h: 109.948,
          l: 109.879,
          c: 109.897,
          v: 1566
        },
        {
          t: 52200,
          o: 109.899,
          h: 110.033,
          l: 109.848,
          c: 109.894,
          v: 3397
        },
        {
          t: 53100,
          o: 109.891,
          h: 109.987,
          l: 109.786,
          c: 109.936,
          v: 3173
        },
        {
          t: 54000,
          o: 109.937,
          h: 109.995,
          l: 109.861,
          c: 109.873,
          v: 2954
        },
        {
          t: 54900,
          o: 109.871,
          h: 109.873,
          l: 109.744,
          c: 109.763,
          v: 2653
        },
        {
          t: 55800,
          o: 109.76,
          h: 109.801,
          l: 109.687,
          c: 109.753,
          v: 2428
        },
        {
          t: 56700,
          o: 109.749,
          h: 110.011,
          l: 109.747,
          c: 110.006,
          v: 2454
        },
        {
          t: 57600,
          o: 110.003,
          h: 110.192,
          l: 109.962,
          c: 110.153,
          v: 2962
        },
        {
          t: 58500,
          o: 110.153,
          h: 110.177,
          l: 110.013,
          c: 110.021,
          v: 2467
        },
        {
          t: 59400,
          o: 110.021,
          h: 110.078,
          l: 110.003,
          c: 110.026,
          v: 2568
        },
        {
          t: 60300,
          o: 110.027,
          h: 110.165,
          l: 110,
          c: 110.153,
          v: 2335
        },
        {
          t: 61200,
          o: 110.148,
          h: 110.292,
          l: 110.115,
          c: 110.287,
          v: 2526
        },
        {
          t: 62100,
          o: 110.285,
          h: 110.289,
          l: 110.149,
          c: 110.211,
          v: 2705
        },
        {
          t: 63000,
          o: 110.212,
          h: 110.341,
          l: 110.194,
          c: 110.223,
          v: 2379
        },
        {
          t: 63900,
          o: 110.223,
          h: 110.284,
          l: 110.166,
          c: 110.169,
          v: 2194
        },
        {
          t: 64800,
          o: 110.169,
          h: 110.22,
          l: 110.143,
          c: 110.16,
          v: 2365
        },
        {
          t: 65700,
          o: 110.159,
          h: 110.162,
          l: 110.036,
          c: 110.05,
          v: 2229
        },
        {
          t: 66600,
          o: 110.049,
          h: 110.059,
          l: 109.884,
          c: 109.908,
          v: 2620
        },
        {
          t: 67500,
          o: 109.909,
          h: 110.032,
          l: 109.891,
          c: 109.933,
          v: 2919
        },
        {
          t: 68400,
          o: 109.932,
          h: 109.966,
          l: 109.883,
          c: 109.923,
          v: 2930
        },
        {
          t: 69300,
          o: 109.923,
          h: 109.962,
          l: 109.856,
          c: 109.961,
          v: 2311
        },
        {
          t: 70200,
          o: 109.956,
          h: 109.99,
          l: 109.911,
          c: 109.985,
          v: 1607
        },
        {
          t: 71100,
          o: 109.982,
          h: 109.996,
          l: 109.921,
          c: 109.934,
          v: 1438
        },
        {
          t: 72000,
          o: 109.935,
          h: 110.05,
          l: 109.914,
          c: 109.985,
          v: 1886
        },
        {
          t: 72900,
          o: 109.985,
          h: 110.016,
          l: 109.946,
          c: 109.948,
          v: 1662
        },
        {
          t: 73800,
          o: 109.948,
          h: 109.961,
          l: 109.883,
          c: 109.89,
          v: 1652
        },
        {
          t: 74700,
          o: 109.89,
          h: 109.941,
          l: 109.815,
          c: 109.82,
          v: 2328
        },
        {
          t: 75600,
          o: 109.819,
          h: 109.88,
          l: 109.642,
          c: 109.646,
          v: 1758
        },
        {
          t: 76500,
          o: 109.649,
          h: 109.695,
          l: 109.608,
          c: 109.692,
          v: 766
        },
        {
          t: 77400,
          o: 109.694,
          h: 109.741,
          l: 109.659,
          c: 109.711,
          v: 1042
        },
        {
          t: 78300,
          o: 109.711,
          h: 109.734,
          l: 109.561,
          c: 109.561,
          v: 846
        },
        {
          t: 79200,
          o: 109.587,
          h: 109.588,
          l: 109.526,
          c: 109.529,
          v: 411
        },
        {
          t: 80100,
          o: 109.527,
          h: 109.57,
          l: 109.32,
          c: 109.524,
          v: 1140
        },
        {
          t: 81000,
          o: 109.524,
          h: 109.533,
          l: 109.444,
          c: 109.506,
          v: 702
        },
        {
          t: 81900,
          o: 109.505,
          h: 109.531,
          l: 109.494,
          c: 109.53,
          v: 382
        },
        {
          t: 82800,
          o: 109.53,
          h: 109.625,
          l: 109.472,
          c: 109.579,
          v: 1746
        },
        {
          t: 83700,
          o: 109.578,
          h: 109.672,
          l: 109.545,
          c: 109.633,
          v: 1967
        },
        {
          t: 84600,
          o: 109.632,
          h: 109.643,
          l: 109.532,
          c: 109.538,
          v: 1074
        }
      ]
    },
    {
      d: 1582848000,
      b: [
        {
          t: 7200,
          o: 109.416,
          h: 109.441,
          l: 109.378,
          c: 109.383,
          v: 1329
        },
        {
          t: 8100,
          o: 109.384,
          h: 109.423,
          l: 109.288,
          c: 109.327,
          v: 1560
        },
        {
          t: 9000,
          o: 109.326,
          h: 109.343,
          l: 109.27,
          c: 109.328,
          v: 2138
        },
        {
          t: 9900,
          o: 109.329,
          h: 109.329,
          l: 109.254,
          c: 109.296,
          v: 1716
        },
        {
          t: 10800,
          o: 109.295,
          h: 109.326,
          l: 109.149,
          c: 109.151,
          v: 1981
        },
        {
          t: 11700,
          o: 109.15,
          h: 109.175,
          l: 109.025,
          c: 109.069,
          v: 1917
        },
        {
          t: 12600,
          o: 109.069,
          h: 109.084,
          l: 108.905,
          c: 108.909,
          v: 2591
        },
        {
          t: 13500,
          o: 108.906,
          h: 109.149,
          l: 108.906,
          c: 109.138,
          v: 2257
        },
        {
          t: 14400,
          o: 109.135,
          h: 109.182,
          l: 109.034,
          c: 109.037,
          v: 2478
        },
        {
          t: 15300,
          o: 109.036,
          h: 109.083,
          l: 108.974,
          c: 109.073,
          v: 2214
        },
        {
          t: 16200,
          o: 109.071,
          h: 109.082,
          l: 108.94,
          c: 108.969,
          v: 1875
        },
        {
          t: 17100,
          o: 108.968,
          h: 108.981,
          l: 108.911,
          c: 108.948,
          v: 1722
        },
        {
          t: 18000,
          o: 108.947,
          h: 108.964,
          l: 108.85,
          c: 108.964,
          v: 2115
        },
        {
          t: 18900,
          o: 108.963,
          h: 108.987,
          l: 108.866,
          c: 108.884,
          v: 2098
        },
        {
          t: 19800,
          o: 108.883,
          h: 108.948,
          l: 108.845,
          c: 108.876,
          v: 2204
        },
        {
          t: 20700,
          o: 108.878,
          h: 108.897,
          l: 108.805,
          c: 108.878,
          v: 2111
        },
        {
          t: 21600,
          o: 108.88,
          h: 108.92,
          l: 108.794,
          c: 108.795,
          v: 2416
        },
        {
          t: 22500,
          o: 108.794,
          h: 108.868,
          l: 108.786,
          c: 108.853,
          v: 2215
        },
        {
          t: 23400,
          o: 108.869,
          h: 108.911,
          l: 108.836,
          c: 108.891,
          v: 1758
        },
        {
          t: 24300,
          o: 108.897,
          h: 108.945,
          l: 108.855,
          c: 108.945,
          v: 1602
        },
        {
          t: 25200,
          o: 108.943,
          h: 108.965,
          l: 108.866,
          c: 108.875,
          v: 2073
        },
        {
          t: 26100,
          o: 108.876,
          h: 109.054,
          l: 108.857,
          c: 109.034,
          v: 2378
        },
        {
          t: 27000,
          o: 109.032,
          h: 109.058,
          l: 108.887,
          c: 108.905,
          v: 2633
        },
        {
          t: 27900,
          o: 108.903,
          h: 108.938,
          l: 108.808,
          c: 108.85,
          v: 2328
        },
        {
          t: 28800,
          o: 108.849,
          h: 108.974,
          l: 108.815,
          c: 108.9,
          v: 3325
        },
        {
          t: 29700,
          o: 108.899,
          h: 108.942,
          l: 108.842,
          c: 108.85,
          v: 2665
        },
        {
          t: 30600,
          o: 108.854,
          h: 108.869,
          l: 108.694,
          c: 108.752,
          v: 3208
        },
        {
          t: 31500,
          o: 108.753,
          h: 108.771,
          l: 108.647,
          c: 108.673,
          v: 2691
        },
        {
          t: 32400,
          o: 108.673,
          h: 108.702,
          l: 108.607,
          c: 108.633,
          v: 2511
        },
        {
          t: 33300,
          o: 108.635,
          h: 108.699,
          l: 108.52,
          c: 108.522,
          v: 2528
        },
        {
          t: 34200,
          o: 108.518,
          h: 108.59,
          l: 108.505,
          c: 108.577,
          v: 2565
        },
        {
          t: 35100,
          o: 108.575,
          h: 108.697,
          l: 108.561,
          c: 108.676,
          v: 2802
        },
        {
          t: 36000,
          o: 108.676,
          h: 108.73,
          l: 108.668,
          c: 108.686,
          v: 2479
        },
        {
          t: 36900,
          o: 108.685,
          h: 108.805,
          l: 108.647,
          c: 108.795,
          v: 2335
        },
        {
          t: 37800,
          o: 108.792,
          h: 108.797,
          l: 108.713,
          c: 108.716,
          v: 1874
        },
        {
          t: 38700,
          o: 108.715,
          h: 108.73,
          l: 108.629,
          c: 108.633,
          v: 1821
        },
        {
          t: 39600,
          o: 108.631,
          h: 108.643,
          l: 108.553,
          c: 108.629,
          v: 1765
        },
        {
          t: 40500,
          o: 108.628,
          h: 108.685,
          l: 108.587,
          c: 108.634,
          v: 1809
        },
        {
          t: 41400,
          o: 108.635,
          h: 108.726,
          l: 108.635,
          c: 108.66,
          v: 2040
        },
        {
          t: 42300,
          o: 108.659,
          h: 108.759,
          l: 108.657,
          c: 108.688,
          v: 2166
        },
        {
          t: 43200,
          o: 108.687,
          h: 108.727,
          l: 108.631,
          c: 108.726,
          v: 2199
        },
        {
          t: 44100,
          o: 108.725,
          h: 108.863,
          l: 108.704,
          c: 108.815,
          v: 2275
        },
        {
          t: 45000,
          o: 108.816,
          h: 108.833,
          l: 108.699,
          c: 108.739,
          v: 2380
        },
        {
          t: 45900,
          o: 108.737,
          h: 108.763,
          l: 108.627,
          c: 108.673,
          v: 2001
        },
        {
          t: 46800,
          o: 108.674,
          h: 108.732,
          l: 108.646,
          c: 108.725,
          v: 2088
        },
        {
          t: 47700,
          o: 108.724,
          h: 108.724,
          l: 108.597,
          c: 108.694,
          v: 1847
        },
        {
          t: 48600,
          o: 108.689,
          h: 108.695,
          l: 108.595,
          c: 108.6,
          v: 2145
        },
        {
          t: 49500,
          o: 108.597,
          h: 108.607,
          l: 108.448,
          c: 108.515,
          v: 2488
        },
        {
          t: 50400,
          o: 108.513,
          h: 108.668,
          l: 108.512,
          c: 108.544,
          v: 2486
        },
        {
          t: 51300,
          o: 108.544,
          h: 108.583,
          l: 108.468,
          c: 108.51,
          v: 2373
        },
        {
          t: 52200,
          o: 108.516,
          h: 108.685,
          l: 108.501,
          c: 108.53,
          v: 3376
        },
        {
          t: 53100,
          o: 108.533,
          h: 108.574,
          l: 108.376,
          c: 108.412,
          v: 3394
        },
        {
          t: 54000,
          o: 108.412,
          h: 108.453,
          l: 108.186,
          c: 108.22,
          v: 3366
        },
        {
          t: 54900,
          o: 108.223,
          h: 108.299,
          l: 108.118,
          c: 108.126,
          v: 3050
        },
        {
          t: 55800,
          o: 108.128,
          h: 108.169,
          l: 108.002,
          c: 108.064,
          v: 3292
        },
        {
          t: 56700,
          o: 108.065,
          h: 108.075,
          l: 107.745,
          c: 107.922,
          v: 3349
        },
        {
          t: 57600,
          o: 107.923,
          h: 108.167,
          l: 107.795,
          c: 108.13,
          v: 3474
        },
        {
          t: 58500,
          o: 108.129,
          h: 108.341,
          l: 108.112,
          c: 108.34,
          v: 2688
        },
        {
          t: 59400,
          o: 108.339,
          h: 108.494,
          l: 108.221,
          c: 108.459,
          v: 3058
        },
        {
          t: 60300,
          o: 108.458,
          h: 108.458,
          l: 108.017,
          c: 108.13,
          v: 3150
        },
        {
          t: 61200,
          o: 108.128,
          h: 108.199,
          l: 108.065,
          c: 108.172,
          v: 3192
        },
        {
          t: 62100,
          o: 108.173,
          h: 108.173,
          l: 108.046,
          c: 108.148,
          v: 2499
        },
        {
          t: 63000,
          o: 108.149,
          h: 108.213,
          l: 108.118,
          c: 108.176,
          v: 3058
        },
        {
          t: 63900,
          o: 108.176,
          h: 108.229,
          l: 108.145,
          c: 108.15,
          v: 2552
        },
        {
          t: 64800,
          o: 108.151,
          h: 108.335,
          l: 108.147,
          c: 108.235,
          v: 2327
        },
        {
          t: 65700,
          o: 108.235,
          h: 108.304,
          l: 108.223,
          c: 108.266,
          v: 2275
        },
        {
          t: 66600,
          o: 108.265,
          h: 108.27,
          l: 108.036,
          c: 108.041,
          v: 2840
        },
        {
          t: 67500,
          o: 108.038,
          h: 108.082,
          l: 108.012,
          c: 108.055,
          v: 2647
        },
        {
          t: 68400,
          o: 108.052,
          h: 108.061,
          l: 107.913,
          c: 107.969,
          v: 2409
        },
        {
          t: 69300,
          o: 107.967,
          h: 108.025,
          l: 107.931,
          c: 107.966,
          v: 2503
        },
        {
          t: 70200,
          o: 107.966,
          h: 108.268,
          l: 107.781,
          c: 107.9,
          v: 2928
        }
      ]
    }
  ]
}

export const defaultNewsRecords: INews[] = [
  {
    Time: 1580688000,
    Symbol: 'USD',
    Title: 'Wards Total Vehicle Sales',
    Country: 'United States',
    Priority: '0',
    Actual: '16.8M',
    Consensus: '16.8M',
    Previews: '16.7M',
    IsPlanned: true
  },
  {
    Time: 1580741100,
    Symbol: 'USD',
    Title: 'Final Manufacturing PMI',
    Country: 'United States',
    Priority: '0',
    Actual: '51.9',
    Consensus: '51.7',
    Previews: '51.7',
    IsPlanned: true
  },
  {
    Time: 1580742000,
    Symbol: 'USD',
    Title: 'Construction Spending m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '0.5%',
    Previews: '0.7%',
    IsPlanned: true
  },
  {
    Time: 1580742000,
    Symbol: 'USD',
    Title: 'ISM Manufacturing PMI',
    Country: 'United States',
    Priority: '2',
    Actual: '50.9',
    Consensus: '48.5',
    Previews: '47.2',
    IsPlanned: true
  },
  {
    Time: 1580742000,
    Symbol: 'USD',
    Title: 'ISM Manufacturing Prices',
    Country: 'United States',
    Priority: '0',
    Actual: '53.3',
    Consensus: '52.0',
    Previews: '51.7',
    IsPlanned: true
  },
  {
    Time: 1580756400,
    Symbol: 'USD',
    Title: 'Loan Officer Survey',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580828400,
    Symbol: 'USD',
    Title: 'Factory Orders m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '1.8%',
    Consensus: '0.7%',
    Previews: '-1.2%',
    IsPlanned: true
  },
  {
    Time: 1580828400,
    Symbol: 'USD',
    Title: 'IBD/TIPP Economic Optimism',
    Country: 'United States',
    Priority: '0',
    Actual: '59.8',
    Consensus: '58.3',
    Previews: '57.4',
    IsPlanned: true
  },
  {
    Time: 1580868000,
    Symbol: 'USD',
    Title: 'President Trump Speaks',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580908500,
    Symbol: 'USD',
    Title: 'ADP Non-Farm Employment Change',
    Country: 'United States',
    Priority: '2',
    Actual: '291K',
    Consensus: '157K',
    Previews: '199K',
    IsPlanned: true
  },
  {
    Time: 1580909400,
    Symbol: 'USD',
    Title: 'Trade Balance',
    Country: 'United States',
    Priority: '0',
    Actual: '-48.9B',
    Consensus: '-48.2B',
    Previews: '-43.7B',
    IsPlanned: true
  },
  {
    Time: 1580913900,
    Symbol: 'USD',
    Title: 'Final Services PMI',
    Country: 'United States',
    Priority: '0',
    Actual: '53.4',
    Consensus: '53.2',
    Previews: '53.2',
    IsPlanned: true
  },
  {
    Time: 1580914800,
    Symbol: 'USD',
    Title: 'ISM Non-Manufacturing PMI',
    Country: 'United States',
    Priority: '2',
    Actual: '55.5',
    Consensus: '55.1',
    Previews: '55.0',
    IsPlanned: true
  },
  {
    Time: 1580916600,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '3.4M',
    Consensus: '2.9M',
    Previews: '3.5M',
    IsPlanned: true
  },
  {
    Time: 1580937000,
    Symbol: 'USD',
    Title: 'FOMC Member Brainard Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580992200,
    Symbol: 'USD',
    Title: 'Challenger Job Cuts y/y',
    Country: 'United States',
    Priority: '0',
    Actual: '27.8%',
    Consensus: '-',
    Previews: '-25.2%',
    IsPlanned: true
  },
  {
    Time: 1580995800,
    Symbol: 'USD',
    Title: 'Prelim Nonfarm Productivity q/q',
    Country: 'United States',
    Priority: '0',
    Actual: '1.4%',
    Consensus: '1.6%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1580995800,
    Symbol: 'USD',
    Title: 'Prelim Unit Labor Costs q/q',
    Country: 'United States',
    Priority: '0',
    Actual: '1.4%',
    Consensus: '1.3%',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1580995800,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '202K',
    Consensus: '215K',
    Previews: '217K',
    IsPlanned: true
  },
  {
    Time: 1580998500,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581003000,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-137B',
    Consensus: '-123B',
    Previews: '-201B',
    IsPlanned: true
  },
  {
    Time: 1581034500,
    Symbol: 'USD',
    Title: 'FOMC Member Quarles Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581082200,
    Symbol: 'USD',
    Title: 'Average Hourly Earnings m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.2%',
    Consensus: '0.3%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581082200,
    Symbol: 'USD',
    Title: 'Non-Farm Employment Change',
    Country: 'United States',
    Priority: '2',
    Actual: '225K',
    Consensus: '163K',
    Previews: '147K',
    IsPlanned: true
  },
  {
    Time: 1581082200,
    Symbol: 'USD',
    Title: 'Unemployment Rate',
    Country: 'United States',
    Priority: '2',
    Actual: '3.6%',
    Consensus: '3.5%',
    Previews: '3.5%',
    IsPlanned: true
  },
  {
    Time: 1581087600,
    Symbol: 'USD',
    Title: 'Final Wholesale Inventories m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '-0.1%',
    Previews: '-0.1%',
    IsPlanned: true
  },
  {
    Time: 1581091200,
    Symbol: 'USD',
    Title: 'Fed Monetary Policy Report',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581105600,
    Symbol: 'USD',
    Title: 'Consumer Credit m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '22.1B',
    Consensus: '15.2B',
    Previews: '11.8B',
    IsPlanned: true
  },
  {
    Time: 1581340500,
    Symbol: 'USD',
    Title: 'FOMC Member Bowman Speaks',
    Country: 'United States',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581365700,
    Symbol: 'USD',
    Title: 'FOMC Member Harker Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581418560,
    Symbol: 'USD',
    Title: 'NFIB Small Business Index',
    Country: 'United States',
    Priority: '0',
    Actual: '104.3',
    Consensus: '103.6',
    Previews: '102.7',
    IsPlanned: true
  },
  {
    Time: 1581433200,
    Symbol: 'USD',
    Title: 'Fed Chair Powell Testifies',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581433200,
    Symbol: 'USD',
    Title: 'JOLTS Job Openings',
    Country: 'United States',
    Priority: '0',
    Actual: '6.42M',
    Consensus: '6.93M',
    Previews: '6.79M',
    IsPlanned: true
  },
  {
    Time: 1581433200,
    Symbol: 'USD',
    Title: 'Mortgage Delinquencies',
    Country: 'United States',
    Priority: '0',
    Actual: '3.77%',
    Consensus: '-',
    Previews: '3.97%',
    IsPlanned: true
  },
  {
    Time: 1581441300,
    Symbol: 'USD',
    Title: 'FOMC Member Quarles Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581448500,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581514200,
    Symbol: 'USD',
    Title: 'FOMC Member Harker Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581517800,
    Symbol: 'USD',
    Title: 'Fed Chair Powell Testifies',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581521400,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '7.5M',
    Consensus: '3.1M',
    Previews: '3.4M',
    IsPlanned: true
  },
  {
    Time: 1581530460,
    Symbol: 'USD',
    Title: '10-y Bond Auction',
    Country: 'United States',
    Priority: '0',
    Actual: '1.62|2.6',
    Consensus: '-',
    Previews: '1.87|2.4',
    IsPlanned: true
  },
  {
    Time: 1581534000,
    Symbol: 'USD',
    Title: 'Federal Budget Balance',
    Country: 'United States',
    Priority: '0',
    Actual: '-32.6B',
    Consensus: '-10.7B',
    Previews: '-13.3B',
    IsPlanned: true
  },
  {
    Time: 1581600600,
    Symbol: 'USD',
    Title: 'CPI m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.1%',
    Consensus: '0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581600600,
    Symbol: 'USD',
    Title: 'Core CPI m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.2%',
    Consensus: '0.2%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581600600,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '205K',
    Consensus: '210K',
    Previews: '203K',
    IsPlanned: true
  },
  {
    Time: 1581607800,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-115B',
    Consensus: '-106B',
    Previews: '-137B',
    IsPlanned: true
  },
  {
    Time: 1581616860,
    Symbol: 'USD',
    Title: '30-y Bond Auction',
    Country: 'United States',
    Priority: '0',
    Actual: '2.06|2.4',
    Consensus: '-',
    Previews: '2.34|2.5',
    IsPlanned: true
  },
  {
    Time: 1581633000,
    Symbol: 'USD',
    Title: 'FOMC Member Williams Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581687000,
    Symbol: 'USD',
    Title: 'Core Retail Sales m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.3%',
    Consensus: '0.3%',
    Previews: '0.6%',
    IsPlanned: true
  },
  {
    Time: 1581687000,
    Symbol: 'USD',
    Title: 'Import Prices m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581687000,
    Symbol: 'USD',
    Title: 'Retail Sales m/m',
    Country: 'United States',
    Priority: '2',
    Actual: '0.3%',
    Consensus: '0.3%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581689700,
    Symbol: 'USD',
    Title: 'Capacity Utilization Rate',
    Country: 'United States',
    Priority: '0',
    Actual: '76.8%',
    Consensus: '76.9%',
    Previews: '77.1%',
    IsPlanned: true
  },
  {
    Time: 1581689700,
    Symbol: 'USD',
    Title: 'Industrial Production m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.3%',
    Consensus: '-0.2%',
    Previews: '-0.4%',
    IsPlanned: true
  },
  {
    Time: 1581692400,
    Symbol: 'USD',
    Title: 'Business Inventories m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.1%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1581692400,
    Symbol: 'USD',
    Title: 'Prelim UoM Consumer Sentiment',
    Country: 'United States',
    Priority: '1',
    Actual: '100.9',
    Consensus: '99.5',
    Previews: '99.8',
    IsPlanned: true
  },
  {
    Time: 1581692400,
    Symbol: 'USD',
    Title: 'Prelim UoM Inflation Expectations',
    Country: 'United States',
    Priority: '0',
    Actual: '2.5%',
    Consensus: '-',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1581698700,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581897600,
    Symbol: 'USD',
    Title: 'Bank Holiday',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582032600,
    Symbol: 'USD',
    Title: 'Empire State Manufacturing Index',
    Country: 'United States',
    Priority: '0',
    Actual: '12.9',
    Consensus: '5.1',
    Previews: '4.8',
    IsPlanned: true
  },
  {
    Time: 1582038000,
    Symbol: 'USD',
    Title: 'NAHB Housing Market Index',
    Country: 'United States',
    Priority: '0',
    Actual: '74',
    Consensus: '75',
    Previews: '75',
    IsPlanned: true
  },
  {
    Time: 1582052400,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582059600,
    Symbol: 'USD',
    Title: 'TIC Long-Term Purchases',
    Country: 'United States',
    Priority: '0',
    Actual: '85.6B',
    Consensus: '31.4B',
    Previews: '27.1B',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'Building Permits',
    Country: 'United States',
    Priority: '1',
    Actual: '1.55M',
    Consensus: '1.45M',
    Previews: '1.42M',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'Core PPI m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.5%',
    Consensus: '0.2%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'Housing Starts',
    Country: 'United States',
    Priority: '0',
    Actual: '1.57M',
    Consensus: '1.40M',
    Previews: '1.63M',
    IsPlanned: true
  },
  {
    Time: 1582119000,
    Symbol: 'USD',
    Title: 'PPI m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.5%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582130700,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582137000,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582138800,
    Symbol: 'USD',
    Title: 'FOMC Meeting Minutes',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582205400,
    Symbol: 'USD',
    Title: 'Philly Fed Manufacturing Index',
    Country: 'United States',
    Priority: '1',
    Actual: '36.7',
    Consensus: '10.1',
    Previews: '17.0',
    IsPlanned: true
  },
  {
    Time: 1582205400,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '210K',
    Consensus: '210K',
    Previews: '206K',
    IsPlanned: true
  },
  {
    Time: 1582210800,
    Symbol: 'USD',
    Title: 'CB Leading Index m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.4%',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1582212600,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-151B',
    Consensus: '-143B',
    Previews: '-115B',
    IsPlanned: true
  },
  {
    Time: 1582214400,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '0.4M',
    Consensus: '3.3M',
    Previews: '7.5M',
    IsPlanned: true
  },
  {
    Time: 1582295700,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582296300,
    Symbol: 'USD',
    Title: 'Flash Manufacturing PMI',
    Country: 'United States',
    Priority: '2',
    Actual: '50.8',
    Consensus: '51.5',
    Previews: '51.9',
    IsPlanned: true
  },
  {
    Time: 1582296300,
    Symbol: 'USD',
    Title: 'Flash Services PMI',
    Country: 'United States',
    Priority: '0',
    Actual: '49.4',
    Consensus: '53.3',
    Previews: '53.3',
    IsPlanned: true
  },
  {
    Time: 1582297200,
    Symbol: 'USD',
    Title: 'Existing Home Sales',
    Country: 'United States',
    Priority: '0',
    Actual: '5.46M',
    Consensus: '5.46M',
    Previews: '5.53M',
    IsPlanned: true
  },
  {
    Time: 1582298100,
    Symbol: 'USD',
    Title: 'FOMC Member Brainard Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582309800,
    Symbol: 'USD',
    Title: 'FOMC Member Clarida Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582574400,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582639200,
    Symbol: 'USD',
    Title: 'HPI m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.6%',
    Consensus: '0.4%',
    Previews: '0.3%',
    IsPlanned: true
  },
  {
    Time: 1582639200,
    Symbol: 'USD',
    Title: 'S&P/CS Composite-20 HPI y/y',
    Country: 'United States',
    Priority: '0',
    Actual: '2.9%',
    Consensus: '2.8%',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1582641900,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582642740,
    Symbol: 'USD',
    Title: 'Richmond Manufacturing Index',
    Country: 'United States',
    Priority: '1',
    Actual: '-2',
    Consensus: '10',
    Previews: '20',
    IsPlanned: true
  },
  {
    Time: 1582642800,
    Symbol: 'USD',
    Title: 'CB Consumer Confidence',
    Country: 'United States',
    Priority: '2',
    Actual: '130.7',
    Consensus: '132.6',
    Previews: '130.4',
    IsPlanned: true
  },
  {
    Time: 1582660800,
    Symbol: 'USD',
    Title: 'FOMC Member Clarida Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582728300,
    Symbol: 'USD',
    Title: 'FOMC Member Kaplan Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582729200,
    Symbol: 'USD',
    Title: 'New Home Sales',
    Country: 'United States',
    Priority: '1',
    Actual: '764K',
    Consensus: '714K',
    Previews: '708K',
    IsPlanned: true
  },
  {
    Time: 1582731000,
    Symbol: 'USD',
    Title: 'Crude Oil Inventories',
    Country: 'United States',
    Priority: '1',
    Actual: '0.5M',
    Consensus: '2.3M',
    Previews: '0.4M',
    IsPlanned: true
  },
  {
    Time: 1582740000,
    Symbol: 'USD',
    Title: 'FOMC Member Kashkari Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Core Durable Goods Orders m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.9%',
    Consensus: '0.2%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Durable Goods Orders m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '-0.2%',
    Consensus: '-1.5%',
    Previews: '2.9%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Prelim GDP Price Index q/q',
    Country: 'United States',
    Priority: '0',
    Actual: '1.3%',
    Consensus: '1.4%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Prelim GDP q/q',
    Country: 'United States',
    Priority: '1',
    Actual: '2.1%',
    Consensus: '2.1%',
    Previews: '2.1%',
    IsPlanned: true
  },
  {
    Time: 1582810200,
    Symbol: 'USD',
    Title: 'Unemployment Claims',
    Country: 'United States',
    Priority: '0',
    Actual: '219K',
    Consensus: '211K',
    Previews: '211K',
    IsPlanned: true
  },
  {
    Time: 1582815600,
    Symbol: 'USD',
    Title: 'Pending Home Sales m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '5.2%',
    Consensus: '2.8%',
    Previews: '-4.3%',
    IsPlanned: true
  },
  {
    Time: 1582817400,
    Symbol: 'USD',
    Title: 'Natural Gas Storage',
    Country: 'United States',
    Priority: '0',
    Actual: '-143B',
    Consensus: '-158B',
    Previews: '-151B',
    IsPlanned: true
  },
  {
    Time: 1582835400,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582848000,
    Symbol: 'USD',
    Title: 'FOMC Member Mester Speaks',
    Country: 'United States',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Core PCE Price Index m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.1%',
    Consensus: '0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Goods Trade Balance',
    Country: 'United States',
    Priority: '0',
    Actual: '-65.5B',
    Consensus: '-68.5B',
    Previews: '-68.7B',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Personal Income m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '0.6%',
    Consensus: '0.3%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Personal Spending m/m',
    Country: 'United States',
    Priority: '1',
    Actual: '0.2%',
    Consensus: '0.3%',
    Previews: '0.4%',
    IsPlanned: true
  },
  {
    Time: 1582896600,
    Symbol: 'USD',
    Title: 'Prelim Wholesale Inventories m/m',
    Country: 'United States',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '0.1%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1582901100,
    Symbol: 'USD',
    Title: 'Chicago PMI',
    Country: 'United States',
    Priority: '1',
    Actual: '49.0',
    Consensus: '46.1',
    Previews: '42.9',
    IsPlanned: true
  },
  {
    Time: 1582902000,
    Symbol: 'USD',
    Title: 'Revised UoM Consumer Sentiment',
    Country: 'United States',
    Priority: '0',
    Actual: '101.0',
    Consensus: '100.7',
    Previews: '100.9',
    IsPlanned: true
  },
  {
    Time: 1582902000,
    Symbol: 'USD',
    Title: 'Revised UoM Inflation Expectations',
    Country: 'United States',
    Priority: '0',
    Actual: '2.4%',
    Consensus: '-',
    Previews: '2.5%',
    IsPlanned: true
  },
  {
    Time: 1582918200,
    Symbol: 'USD',
    Title: 'Fed Announcement',
    Country: 'United States',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580717700,
    Symbol: 'EUR',
    Title: 'Spanish Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '48.5',
    Consensus: '48.3',
    Previews: '47.4',
    IsPlanned: true
  },
  {
    Time: 1580719500,
    Symbol: 'EUR',
    Title: 'Italian Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '48.9',
    Consensus: '46.9',
    Previews: '46.2',
    IsPlanned: true
  },
  {
    Time: 1580719800,
    Symbol: 'EUR',
    Title: 'French Final Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '51.1',
    Consensus: '51.0',
    Previews: '51.0',
    IsPlanned: true
  },
  {
    Time: 1580720100,
    Symbol: 'EUR',
    Title: 'German Final Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '45.3',
    Consensus: '45.2',
    Previews: '45.2',
    IsPlanned: true
  },
  {
    Time: 1580720400,
    Symbol: 'EUR',
    Title: 'Final Manufacturing PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '47.9',
    Consensus: '47.8',
    Previews: '47.8',
    IsPlanned: true
  },
  {
    Time: 1580753700,
    Symbol: 'EUR',
    Title: 'German Buba President Weidmann Speaks',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580802300,
    Symbol: 'EUR',
    Title: 'French Gov Budget Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '-92.8B',
    Consensus: '-',
    Previews: '-113.9B',
    IsPlanned: true
  },
  {
    Time: 1580803200,
    Symbol: 'EUR',
    Title: 'Spanish Unemployment Change',
    Country: 'European Union',
    Priority: '0',
    Actual: '90.2K',
    Consensus: '44.2K',
    Previews: '-34.6K',
    IsPlanned: true
  },
  {
    Time: 1580810400,
    Symbol: 'EUR',
    Title: 'Italian Prelim CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.2%',
    Consensus: '-0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1580810400,
    Symbol: 'EUR',
    Title: 'PPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1580890500,
    Symbol: 'EUR',
    Title: 'Spanish Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '52.3',
    Consensus: '54.0',
    Previews: '54.9',
    IsPlanned: true
  },
  {
    Time: 1580892300,
    Symbol: 'EUR',
    Title: 'Italian Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '51.4',
    Consensus: '50.3',
    Previews: '51.1',
    IsPlanned: true
  },
  {
    Time: 1580892600,
    Symbol: 'EUR',
    Title: 'French Final Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '51.0',
    Consensus: '51.7',
    Previews: '51.7',
    IsPlanned: true
  },
  {
    Time: 1580892900,
    Symbol: 'EUR',
    Title: 'German Final Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '54.2',
    Consensus: '54.2',
    Previews: '54.2',
    IsPlanned: true
  },
  {
    Time: 1580893200,
    Symbol: 'EUR',
    Title: 'Final Services PMI',
    Country: 'European Union',
    Priority: '0',
    Actual: '52.5',
    Consensus: '52.2',
    Previews: '52.2',
    IsPlanned: true
  },
  {
    Time: 1580896800,
    Symbol: 'EUR',
    Title: 'Retail Sales m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-1.6%',
    Consensus: '-0.5%',
    Previews: '0.8%',
    IsPlanned: true
  },
  {
    Time: 1580904900,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580972400,
    Symbol: 'EUR',
    Title: 'German Factory Orders m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.1%',
    Consensus: '0.6%',
    Previews: '-0.8%',
    IsPlanned: true
  },
  {
    Time: 1580976000,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580979600,
    Symbol: 'EUR',
    Title: 'ECB Economic Bulletin',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580982540,
    Symbol: 'EUR',
    Title: 'Spanish 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.35|1.6',
    Consensus: '-',
    Previews: '0.44|1.5',
    IsPlanned: true
  },
  {
    Time: 1580983620,
    Symbol: 'EUR',
    Title: 'French 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.11|2.2',
    Consensus: '-',
    Previews: '0.04|1.7',
    IsPlanned: true
  },
  {
    Time: 1581058800,
    Symbol: 'EUR',
    Title: 'German Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-3.5%',
    Consensus: '-0.2%',
    Previews: '1.2%',
    IsPlanned: true
  },
  {
    Time: 1581058800,
    Symbol: 'EUR',
    Title: 'German Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '19.2B',
    Consensus: '16.4B',
    Previews: '18.5B',
    IsPlanned: true
  },
  {
    Time: 1581061500,
    Symbol: 'EUR',
    Title: 'French Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.8%',
    Consensus: '-0.3%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581061500,
    Symbol: 'EUR',
    Title: 'French Prelim Private Payrolls q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.2%',
    Consensus: '0.2%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1581061500,
    Symbol: 'EUR',
    Title: 'French Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '-4.1B',
    Consensus: '-5.1B',
    Previews: '-5.4B',
    IsPlanned: true
  },
  {
    Time: 1581066000,
    Symbol: 'EUR',
    Title: 'Italian Retail Sales m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.5%',
    Consensus: '0.2%',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1581325200,
    Symbol: 'EUR',
    Title: 'Italian Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.7%',
    Consensus: '1.5%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581327000,
    Symbol: 'EUR',
    Title: 'Sentix Investor Confidence',
    Country: 'European Union',
    Priority: '0',
    Actual: '5.2',
    Consensus: '6.1',
    Previews: '7.6',
    IsPlanned: true
  },
  {
    Time: 1581429600,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581501600,
    Symbol: 'EUR',
    Title: 'Industrial Production m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.1%',
    Consensus: '-1.8%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581503580,
    Symbol: 'EUR',
    Title: 'German 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.38|1.2',
    Consensus: '-',
    Previews: '-0.25|1.2',
    IsPlanned: true
  },
  {
    Time: 1581577200,
    Symbol: 'EUR',
    Title: 'German Final CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.6%',
    Consensus: '-0.6%',
    Previews: '-0.6%',
    IsPlanned: true
  },
  {
    Time: 1581588000,
    Symbol: 'EUR',
    Title: 'EU Economic Forecasts',
    Country: 'European Union',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581663600,
    Symbol: 'EUR',
    Title: 'German Prelim GDP q/q',
    Country: 'European Union',
    Priority: '2',
    Actual: '0.0%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581663600,
    Symbol: 'EUR',
    Title: 'German WPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.0%',
    Consensus: '0.1%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581670800,
    Symbol: 'EUR',
    Title: 'Italian Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '5.01B',
    Consensus: '5.23B',
    Previews: '4.89B',
    IsPlanned: true
  },
  {
    Time: 1581674400,
    Symbol: 'EUR',
    Title: 'Flash Employment Change q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581674400,
    Symbol: 'EUR',
    Title: 'Flash GDP q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581674400,
    Symbol: 'EUR',
    Title: 'Trade Balance',
    Country: 'European Union',
    Priority: '0',
    Actual: '22.2B',
    Consensus: '19.1B',
    Previews: '19.1B',
    IsPlanned: true
  },
  {
    Time: 1581897600,
    Symbol: 'EUR',
    Title: 'Eurogroup Meetings',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581937200,
    Symbol: 'EUR',
    Title: 'German Buba Monthly Report',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581984000,
    Symbol: 'EUR',
    Title: 'ECOFIN Meetings',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582020000,
    Symbol: 'EUR',
    Title: 'German ZEW Economic Sentiment',
    Country: 'European Union',
    Priority: '2',
    Actual: '8.7',
    Consensus: '20.0',
    Previews: '26.7',
    IsPlanned: true
  },
  {
    Time: 1582020000,
    Symbol: 'EUR',
    Title: 'ZEW Economic Sentiment',
    Country: 'European Union',
    Priority: '0',
    Actual: '10.4',
    Consensus: '21.3',
    Previews: '25.6',
    IsPlanned: true
  },
  {
    Time: 1582102800,
    Symbol: 'EUR',
    Title: 'Current Account',
    Country: 'European Union',
    Priority: '0',
    Actual: '32.6B',
    Consensus: '34.5B',
    Previews: '32.4B',
    IsPlanned: true
  },
  {
    Time: 1582108500,
    Symbol: 'EUR',
    Title: 'German 30-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.07|1.1',
    Consensus: '-',
    Previews: '0.31|1.1',
    IsPlanned: true
  },
  {
    Time: 1582181940,
    Symbol: 'EUR',
    Title: 'German GfK Consumer Climate',
    Country: 'European Union',
    Priority: '0',
    Actual: '9.8',
    Consensus: '9.9',
    Previews: '9.9',
    IsPlanned: true
  },
  {
    Time: 1582182000,
    Symbol: 'EUR',
    Title: 'German PPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1582184700,
    Symbol: 'EUR',
    Title: 'French Final CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.4%',
    Consensus: '-0.4%',
    Previews: '-0.4%',
    IsPlanned: true
  },
  {
    Time: 1582191840,
    Symbol: 'EUR',
    Title: 'Spanish 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.24|2.3',
    Consensus: '-',
    Previews: '0.35|1.6',
    IsPlanned: true
  },
  {
    Time: 1582201800,
    Symbol: 'EUR',
    Title: 'ECB Monetary Policy Meeting Accounts',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582210620,
    Symbol: 'EUR',
    Title: 'Consumer Confidence',
    Country: 'European Union',
    Priority: '0',
    Actual: '-7',
    Consensus: '-8',
    Previews: '-8',
    IsPlanned: true
  },
  {
    Time: 1582272900,
    Symbol: 'EUR',
    Title: 'French Flash Manufacturing PMI',
    Country: 'European Union',
    Priority: '1',
    Actual: '49.7',
    Consensus: '50.8',
    Previews: '51.1',
    IsPlanned: true
  },
  {
    Time: 1582272900,
    Symbol: 'EUR',
    Title: 'French Flash Services PMI',
    Country: 'European Union',
    Priority: '2',
    Actual: '52.6',
    Consensus: '51.4',
    Previews: '51.0',
    IsPlanned: true
  },
  {
    Time: 1582273800,
    Symbol: 'EUR',
    Title: 'German Flash Manufacturing PMI',
    Country: 'European Union',
    Priority: '2',
    Actual: '47.8',
    Consensus: '44.8',
    Previews: '45.3',
    IsPlanned: true
  },
  {
    Time: 1582273800,
    Symbol: 'EUR',
    Title: 'German Flash Services PMI',
    Country: 'European Union',
    Priority: '2',
    Actual: '53.3',
    Consensus: '53.9',
    Previews: '54.2',
    IsPlanned: true
  },
  {
    Time: 1582275600,
    Symbol: 'EUR',
    Title: 'Flash Manufacturing PMI',
    Country: 'European Union',
    Priority: '1',
    Actual: '49.1',
    Consensus: '47.4',
    Previews: '47.9',
    IsPlanned: true
  },
  {
    Time: 1582275600,
    Symbol: 'EUR',
    Title: 'Flash Services PMI',
    Country: 'European Union',
    Priority: '1',
    Actual: '52.8',
    Consensus: '52.4',
    Previews: '52.5',
    IsPlanned: true
  },
  {
    Time: 1582279200,
    Symbol: 'EUR',
    Title: 'Final CPI y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.4%',
    Consensus: '1.4%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1582279200,
    Symbol: 'EUR',
    Title: 'Final Core CPI y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.1%',
    Consensus: '1.1%',
    Previews: '1.1%',
    IsPlanned: true
  },
  {
    Time: 1582293600,
    Symbol: 'EUR',
    Title: 'Belgian NBB Business Climate',
    Country: 'European Union',
    Priority: '0',
    Actual: '-2.7',
    Consensus: '-2.8',
    Previews: '-2.0',
    IsPlanned: true
  },
  {
    Time: 1582534800,
    Symbol: 'EUR',
    Title: 'German Ifo Business Climate',
    Country: 'European Union',
    Priority: '2',
    Actual: '96.1',
    Consensus: '95.0',
    Previews: '96.0',
    IsPlanned: true
  },
  {
    Time: 1582614000,
    Symbol: 'EUR',
    Title: 'German Final GDP q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.0%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1582723800,
    Symbol: 'EUR',
    Title: 'ECB President Lagarde Speaks',
    Country: 'European Union',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582790400,
    Symbol: 'EUR',
    Title: 'Spanish Flash CPI y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.8%',
    Previews: '1.1%',
    IsPlanned: true
  },
  {
    Time: 1582794000,
    Symbol: 'EUR',
    Title: 'M3 Money Supply y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '5.2%',
    Consensus: '5.3%',
    Previews: '4.9%',
    IsPlanned: true
  },
  {
    Time: 1582794000,
    Symbol: 'EUR',
    Title: 'Private Loans y/y',
    Country: 'European Union',
    Priority: '0',
    Actual: '3.7%',
    Consensus: '3.7%',
    Previews: '3.6%',
    IsPlanned: true
  },
  {
    Time: 1582798740,
    Symbol: 'EUR',
    Title: 'Italian 10-y Bond Auction',
    Country: 'European Union',
    Priority: '0',
    Actual: '1.00|1.3',
    Consensus: '-',
    Previews: '0.94|1.2',
    IsPlanned: true
  },
  {
    Time: 1582848000,
    Symbol: 'EUR',
    Title: 'German Prelim CPI m/m',
    Country: 'European Union',
    Priority: '1',
    Actual: '0.4%',
    Consensus: '0.3%',
    Previews: '-0.6%',
    IsPlanned: true
  },
  {
    Time: 1582873200,
    Symbol: 'EUR',
    Title: 'German Import Prices m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.4%',
    Consensus: '0.1%',
    Previews: '0.2%',
    IsPlanned: true
  },
  {
    Time: 1582875900,
    Symbol: 'EUR',
    Title: 'French Consumer Spending m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '-1.1%',
    Consensus: '-0.1%',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1582875900,
    Symbol: 'EUR',
    Title: 'French Prelim CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.0%',
    Previews: '-0.4%',
    IsPlanned: true
  },
  {
    Time: 1582875900,
    Symbol: 'EUR',
    Title: 'French Prelim GDP q/q',
    Country: 'European Union',
    Priority: '0',
    Actual: '-0.1%',
    Consensus: '-0.1%',
    Previews: '-0.1%',
    IsPlanned: true
  },
  {
    Time: 1582880100,
    Symbol: 'EUR',
    Title: 'German Unemployment Change',
    Country: 'European Union',
    Priority: '0',
    Actual: '-10K',
    Consensus: '4K',
    Previews: '-4K',
    IsPlanned: true
  },
  {
    Time: 1582884000,
    Symbol: 'EUR',
    Title: 'German Buba President Weidmann Speaks',
    Country: 'European Union',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582884000,
    Symbol: 'EUR',
    Title: 'Italian Prelim CPI m/m',
    Country: 'European Union',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1580722200,
    Symbol: 'GBP',
    Title: 'Final Manufacturing PMI',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '50.0',
    Consensus: '49.8',
    Previews: '49.8',
    IsPlanned: true
  },
  {
    Time: 1580808600,
    Symbol: 'GBP',
    Title: 'Construction PMI',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '48.4',
    Consensus: '48.1',
    Previews: '44.4',
    IsPlanned: true
  },
  {
    Time: 1580895000,
    Symbol: 'GBP',
    Title: 'Final Services PMI',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '53.9',
    Consensus: '52.9',
    Previews: '52.9',
    IsPlanned: true
  },
  {
    Time: 1581064200,
    Symbol: 'GBP',
    Title: 'Halifax HPI m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.4%',
    Consensus: '0.6%',
    Previews: '1.8%',
    IsPlanned: true
  },
  {
    Time: 1581379260,
    Symbol: 'GBP',
    Title: 'BRC Retail Sales Monitor y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.6%',
    Previews: '1.7%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Construction Output m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.4%',
    Consensus: '-0.4%',
    Previews: '2.4%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'GDP m/m',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '0.3%',
    Consensus: '0.2%',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Goods Trade Balance',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.8B',
    Consensus: '-10.0B',
    Previews: '-4.9B',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Index of Services 3m/3m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.0%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Industrial Production m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.1%',
    Consensus: '0.3%',
    Previews: '-1.1%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Manufacturing Production m/m',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '0.3%',
    Consensus: '0.5%',
    Previews: '-1.6%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Prelim Business Investment q/q',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-1.0%',
    Consensus: '-0.5%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581413400,
    Symbol: 'GBP',
    Title: 'Prelim GDP q/q',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '0.0%',
    Consensus: '0.0%',
    Previews: '0.4%',
    IsPlanned: true
  },
  {
    Time: 1581435300,
    Symbol: 'GBP',
    Title: 'BOE Gov Carney Speaks',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581440400,
    Symbol: 'GBP',
    Title: 'MPC Member Haskel Speaks',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581442620,
    Symbol: 'GBP',
    Title: 'NIESR GDP Estimate',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1581517800,
    Symbol: 'GBP',
    Title: 'CB Leading Index m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-',
    Previews: '-0.2%',
    IsPlanned: true
  },
  {
    Time: 1581552060,
    Symbol: 'GBP',
    Title: 'RICS House Price Balance',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '17%',
    Consensus: '3%',
    Previews: '0%',
    IsPlanned: true
  },
  {
    Time: 1581897660,
    Symbol: 'GBP',
    Title: 'Rightmove HPI m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '-',
    Previews: '2.3%',
    IsPlanned: true
  },
  {
    Time: 1582018200,
    Symbol: 'GBP',
    Title: 'Average Earnings Index 3m/y',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '2.9%',
    Consensus: '3.1%',
    Previews: '3.2%',
    IsPlanned: true
  },
  {
    Time: 1582018200,
    Symbol: 'GBP',
    Title: 'Claimant Count Change',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '5.5K',
    Consensus: '20.2K',
    Previews: '2.6K',
    IsPlanned: true
  },
  {
    Time: 1582018200,
    Symbol: 'GBP',
    Title: 'Unemployment Rate',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '3.8%',
    Consensus: '3.8%',
    Previews: '3.8%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'CPI y/y',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '1.8%',
    Consensus: '1.6%',
    Previews: '1.3%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'Core CPI y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '1.6%',
    Consensus: '1.5%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'HPI y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '2.2%',
    Consensus: '2.3%',
    Previews: '1.7%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'PPI Input m/m',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '0.9%',
    Consensus: '-0.5%',
    Previews: '0.9%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'PPI Output m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.1%',
    Previews: '0.0%',
    IsPlanned: true
  },
  {
    Time: 1582104600,
    Symbol: 'GBP',
    Title: 'RPI y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '2.7%',
    Consensus: '2.5%',
    Previews: '2.2%',
    IsPlanned: true
  },
  {
    Time: 1582191000,
    Symbol: 'GBP',
    Title: 'Retail Sales m/m',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '0.9%',
    Consensus: '0.7%',
    Previews: '-0.5%',
    IsPlanned: true
  },
  {
    Time: 1582196400,
    Symbol: 'GBP',
    Title: 'CBI Industrial Order Expectations',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-18',
    Consensus: '-19',
    Previews: '-22',
    IsPlanned: true
  },
  {
    Time: 1582277400,
    Symbol: 'GBP',
    Title: 'Flash Manufacturing PMI',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '51.9',
    Consensus: '49.7',
    Previews: '50.0',
    IsPlanned: true
  },
  {
    Time: 1582277400,
    Symbol: 'GBP',
    Title: 'Flash Services PMI',
    Country: 'Great Britain',
    Priority: '2',
    Actual: '53.3',
    Consensus: '53.4',
    Previews: '53.9',
    IsPlanned: true
  },
  {
    Time: 1582277400,
    Symbol: 'GBP',
    Title: 'Public Sector Net Borrowing',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-10.5B',
    Consensus: '-12.0B',
    Previews: '3.5B',
    IsPlanned: true
  },
  {
    Time: 1582309800,
    Symbol: 'GBP',
    Title: 'MPC Member Tenreyro Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582569000,
    Symbol: 'GBP',
    Title: 'MPC Member Haldane Speaks',
    Country: 'Great Britain',
    Priority: '1',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582626960,
    Symbol: 'GBP',
    Title: '10-y Bond Auction',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.51|2.2',
    Consensus: '-',
    Previews: '0.50|2.3',
    IsPlanned: true
  },
  {
    Time: 1582628400,
    Symbol: 'GBP',
    Title: 'CBI Realized Sales',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '1',
    Consensus: '4',
    Previews: '0',
    IsPlanned: true
  },
  {
    Time: 1582675260,
    Symbol: 'GBP',
    Title: 'BRC Shop Price Index y/y',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-0.6%',
    Consensus: '-',
    Previews: '-0.3%',
    IsPlanned: true
  },
  {
    Time: 1582799400,
    Symbol: 'GBP',
    Title: 'MPC Member Cunliffe Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582848060,
    Symbol: 'GBP',
    Title: 'GfK Consumer Confidence',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-7',
    Consensus: '-8',
    Previews: '-9',
    IsPlanned: true
  },
  {
    Time: 1582873140,
    Symbol: 'GBP',
    Title: 'Nationwide HPI m/m',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.4%',
    Previews: '0.5%',
    IsPlanned: true
  },
  {
    Time: 1582888500,
    Symbol: 'GBP',
    Title: 'MPC Member Haldane Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582906500,
    Symbol: 'GBP',
    Title: 'MPC Member Cunliffe Speaks',
    Country: 'Great Britain',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1580689800,
    Symbol: 'JPY',
    Title: 'Final Manufacturing PMI',
    Country: 'Japan',
    Priority: '0',
    Actual: '48.8',
    Consensus: '49.3',
    Previews: '49.3',
    IsPlanned: true
  },
  {
    Time: 1580773800,
    Symbol: 'JPY',
    Title: 'Monetary Base y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.9%',
    Consensus: '3.2%',
    Previews: '3.2%',
    IsPlanned: true
  },
  {
    Time: 1580787300,
    Symbol: 'JPY',
    Title: '10-y Bond Auction',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.05|3.6',
    Consensus: '-',
    Previews: '-0.01|3.7',
    IsPlanned: true
  },
  {
    Time: 1580960100,
    Symbol: 'JPY',
    Title: '30-y Bond Auction',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.43|3.7',
    Consensus: '-',
    Previews: '0.43|3.7',
    IsPlanned: true
  },
  {
    Time: 1581031800,
    Symbol: 'JPY',
    Title: 'Average Cash Earnings y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '-0.1%',
    Previews: '0.1%',
    IsPlanned: true
  },
  {
    Time: 1581031800,
    Symbol: 'JPY',
    Title: 'Household Spending y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-4.8%',
    Consensus: '-1.7%',
    Previews: '-2.0%',
    IsPlanned: true
  },
  {
    Time: 1581051600,
    Symbol: 'JPY',
    Title: 'Leading Indicators',
    Country: 'Japan',
    Priority: '0',
    Actual: '91.6%',
    Consensus: '91.4%',
    Previews: '90.8%',
    IsPlanned: true
  },
  {
    Time: 1581292200,
    Symbol: 'JPY',
    Title: 'Bank Lending y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.9%',
    Consensus: '1.7%',
    Previews: '1.8%',
    IsPlanned: true
  },
  {
    Time: 1581292200,
    Symbol: 'JPY',
    Title: 'Current Account',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.71T',
    Consensus: '1.68T',
    Previews: '1.79T',
    IsPlanned: true
  },
  {
    Time: 1581310920,
    Symbol: 'JPY',
    Title: 'Economy Watchers Sentiment',
    Country: 'Japan',
    Priority: '0',
    Actual: '41.9',
    Consensus: '39.2',
    Previews: '39.8',
    IsPlanned: true
  },
  {
    Time: 1581379200,
    Symbol: 'JPY',
    Title: 'Bank Holiday',
    Country: 'Japan',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1581465000,
    Symbol: 'JPY',
    Title: 'M2 Money Stock y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.8%',
    Consensus: '2.8%',
    Previews: '2.7%',
    IsPlanned: true
  },
  {
    Time: 1581487140,
    Symbol: 'JPY',
    Title: 'Prelim Machine Tool Orders y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-35.6%',
    Consensus: '-',
    Previews: '-33.5%',
    IsPlanned: true
  },
  {
    Time: 1581551400,
    Symbol: 'JPY',
    Title: 'PPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.7%',
    Consensus: '1.5%',
    Previews: '0.9%',
    IsPlanned: true
  },
  {
    Time: 1581654600,
    Symbol: 'JPY',
    Title: 'Tertiary Industry Activity m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.2%',
    Consensus: '0.1%',
    Previews: '1.4%',
    IsPlanned: true
  },
  {
    Time: 1581897000,
    Symbol: 'JPY',
    Title: 'Prelim GDP Price Index y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.3%',
    Consensus: '1.1%',
    Previews: '0.6%',
    IsPlanned: true
  },
  {
    Time: 1581897000,
    Symbol: 'JPY',
    Title: 'Prelim GDP q/q',
    Country: 'Japan',
    Priority: '0',
    Actual: '-1.6%',
    Consensus: '-1.0%',
    Previews: '0.4%',
    IsPlanned: true
  },
  {
    Time: 1581913800,
    Symbol: 'JPY',
    Title: 'Revised Industrial Production m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '1.2%',
    Consensus: '1.3%',
    Previews: '1.3%',
    IsPlanned: true
  },
  {
    Time: 1582069800,
    Symbol: 'JPY',
    Title: 'Core Machinery Orders m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '-12.5%',
    Consensus: '-9.0%',
    Previews: '18.0%',
    IsPlanned: true
  },
  {
    Time: 1582069800,
    Symbol: 'JPY',
    Title: 'Trade Balance',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.22T',
    Consensus: '-0.55T',
    Previews: '-0.11T',
    IsPlanned: true
  },
  {
    Time: 1582241400,
    Symbol: 'JPY',
    Title: 'National Core CPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.7%',
    Previews: '0.7%',
    IsPlanned: true
  },
  {
    Time: 1582245000,
    Symbol: 'JPY',
    Title: 'Flash Manufacturing PMI',
    Country: 'Japan',
    Priority: '0',
    Actual: '47.6',
    Consensus: '47.9',
    Previews: '48.8',
    IsPlanned: true
  },
  {
    Time: 1582259400,
    Symbol: 'JPY',
    Title: 'All Industries Activity m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.0%',
    Consensus: '0.3%',
    Previews: '0.9%',
    IsPlanned: true
  },
  {
    Time: 1582502400,
    Symbol: 'JPY',
    Title: 'Bank Holiday',
    Country: 'Japan',
    Priority: '0',
    Actual: '-',
    Consensus: '-',
    Previews: '-',
    IsPlanned: true
  },
  {
    Time: 1582588200,
    Symbol: 'JPY',
    Title: 'SPPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.3%',
    Consensus: '2.1%',
    Previews: '2.1%',
    IsPlanned: true
  },
  {
    Time: 1582693200,
    Symbol: 'JPY',
    Title: 'BOJ Core CPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.3%',
    Consensus: '0.2%',
    Previews: '0.3%',
    IsPlanned: true
  },
  {
    Time: 1582846200,
    Symbol: 'JPY',
    Title: 'Tokyo Core CPI y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.5%',
    Consensus: '0.6%',
    Previews: '0.7%',
    IsPlanned: true
  },
  {
    Time: 1582846200,
    Symbol: 'JPY',
    Title: 'Unemployment Rate',
    Country: 'Japan',
    Priority: '0',
    Actual: '2.4%',
    Consensus: '2.2%',
    Previews: '2.2%',
    IsPlanned: true
  },
  {
    Time: 1582847400,
    Symbol: 'JPY',
    Title: 'Prelim Industrial Production m/m',
    Country: 'Japan',
    Priority: '0',
    Actual: '0.8%',
    Consensus: '0.2%',
    Previews: '1.2%',
    IsPlanned: true
  },
  {
    Time: 1582847400,
    Symbol: 'JPY',
    Title: 'Retail Sales y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-0.4%',
    Consensus: '-1.3%',
    Previews: '-2.6%',
    IsPlanned: true
  },
  {
    Time: 1582866000,
    Symbol: 'JPY',
    Title: 'Housing Starts y/y',
    Country: 'Japan',
    Priority: '0',
    Actual: '-10.1%',
    Consensus: '-5.8%',
    Previews: '-7.9%',
    IsPlanned: true
  }
]

export const defaultIndicatorsDataMapByName: { [chartName: string]: object } = {
  ['EURUSD5']: {
    [defaultIndicators[0].name]: defaultMA,
    [defaultOscillators[0].name]: defaultRsi
  },
  ['GBPUSD60']: {
    [defaultIndicators[1].name]: defaultEMA,
    [defaultOscillators[1].name]: defaultMFI,
    [defaultOscillators[2].name]: defaultBearsPower
  },
  ['USDJPY15']: {
    [defaultOscillators[3].name]: defaultATR,
    [defaultOscillators[4].name]: defaultMomentum
  }
}

export const defaultIndicatorsMapByChart: { [chartName: string]: string[] } = {
  ['EURUSD5']: [defaultIndicators[0].name, defaultOscillators[0].name],
  ['GBPUSD60']: [defaultIndicators[1].name, defaultOscillators[1].name, defaultOscillators[2].name],
  ['USDJPY15']: [defaultOscillators[3].name, defaultOscillators[4].name]
}
