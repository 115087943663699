import { AutoSave, StorageType } from './types'

export const AutoSaveTag = 'FTOsaves'

export const isAutoSave = (obj: any): obj is AutoSave => {
  return (
    obj.date !== undefined &&
    obj.id !== undefined &&
    obj.data !== undefined &&
    obj.data.graphics !== undefined &&
    obj.data.orders !== undefined &&
    obj.data.graphs !== undefined &&
    obj.data.testing !== undefined &&
    Object.keys(obj.data.graphics).length > 0 &&
    Object.keys(obj.data.graphs).length > 0 &&
    Object.keys(obj.data.testing).length > 0
  )
}

export function validateAutosave(save: unknown): { isArraySaves: boolean, save: AutoSave } {
  if (Array.isArray(save) && save[0]) {
    return {
      isArraySaves: true,
      save: save[0]
    }
  } else {
    return {
      isArraySaves: false,
      save: save as AutoSave
    }
  }
}

export const storages: Record<StorageType, Storage> = {
  localStorage,
  sessionStorage
}
