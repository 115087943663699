import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPanelsState } from './types'

export const initialPanelsState: IPanelsState = {
  leftMenu: true,
  bottomMenu: true,
  symbols: true,
  statistics: true,
  tickCharts: true,
  scripts: true,
  dataWindow: true,
  bottom: {
    openPositions: true,
    pendingOrders: true,
    accountHistory: true,
    journal: true,
    news: true,
    notes: true
  },
  graphicPanel: true,
  chartContextMenu: false,
  oscillatorContextMenu: false,
  settingsTab: false,
  topMenuTab: 0,
  leftMenuTab: 0,
  bottomMenuTab: 0
}

export const panelsSlice = createSlice({
  name: 'panels',
  initialState: initialPanelsState,
  reducers: {
    assignAllPanels: (state: IPanelsState, action: PayloadAction<IPanelsState>) => {
      return { ...action.payload }
    },
    enableLeftMenu: (state: IPanelsState) => {
      state.leftMenu = true
    },
    disableLeftMenu: (state: IPanelsState) => {
      state.leftMenu = false
      state.symbols = false
      state.statistics = false
      state.tickCharts = false
      state.scripts = false
      state.dataWindow = false
    },
    enableBottomMenu: (state: IPanelsState) => {
      state.bottomMenu = true
    },
    disableBottomMenu: (state: IPanelsState) => {
      state.bottomMenu = false
      state.bottom.openPositions = false
      state.bottom.pendingOrders = false
      state.bottom.accountHistory = false
      state.bottom.journal = false
      state.bottom.news = false
      state.bottom.notes = false
    },
    toggleSymbolsTab: (state: IPanelsState) => {
      state.symbols = !state.symbols
      state.leftMenu = true
    },
    toggleTickChartTab: (state: IPanelsState) => {
      state.tickCharts = !state.tickCharts
    },
    toggleDataWindowTab: (state: IPanelsState) => {
      state.dataWindow = !state.dataWindow
    },
    toggleStatisticsTab: (state: IPanelsState) => {
      state.statistics = !state.statistics
      state.leftMenu = true
    },
    toggleOpenPositionsTab: (state: IPanelsState) => {
      state.bottom.openPositions = !state.bottom.openPositions
      state.bottomMenu = true
    },
    togglePendingOrdersTab: (state: IPanelsState) => {
      state.bottom.pendingOrders = !state.bottom.pendingOrders
      state.bottomMenu = true
    },
    toggleAccountHistoryTab: (state: IPanelsState) => {
      state.bottom.accountHistory = !state.bottom.accountHistory
      state.bottomMenu = true
    },
    toggleJournalTab: (state: IPanelsState) => {
      state.bottom.journal = !state.bottom.journal
      state.bottomMenu = true
    },
    toggleNewsTab: (state: IPanelsState) => {
      state.bottom.news = !state.bottom.news
      state.bottomMenu = true
    },
    toggleNotesTab: (state: IPanelsState) => {
      state.bottom.notes = !state.bottom.notes
      state.bottomMenu = true
    },
    toggleGraphicPanel: (state: IPanelsState) => {
      state.graphicPanel = !state.graphicPanel
    },
    toggleScriptsTab: (state: IPanelsState) => {
      state.scripts = !state.scripts
    },
    toggleSettingsTab: (state: IPanelsState) => {
      state.settingsTab = !state.settingsTab
    },
    selectTopMenuTab: (state: IPanelsState, action: PayloadAction<number>) => {
      state.topMenuTab = action.payload
    },
    setBottomMenuTab: (state: IPanelsState, action: PayloadAction<number>) => {
      state.bottomMenuTab = action.payload
    },
    setLeftMenuTab: (state: IPanelsState, action: PayloadAction<number>) => {
      state.leftMenuTab = action.payload
    }
  }
})

export const {
  assignAllPanels,
  enableLeftMenu,
  disableLeftMenu,
  enableBottomMenu,
  disableBottomMenu,
  toggleSymbolsTab,
  toggleTickChartTab,
  toggleDataWindowTab,
  toggleStatisticsTab,
  toggleOpenPositionsTab,
  togglePendingOrdersTab,
  toggleAccountHistoryTab,
  toggleJournalTab,
  toggleNewsTab,
  toggleNotesTab,
  toggleGraphicPanel,
  toggleScriptsTab,
  toggleSettingsTab,
  selectTopMenuTab,
  setBottomMenuTab,
  setLeftMenuTab
} = panelsSlice.actions
export default panelsSlice.reducer
