import { settingsSlice } from './reducers'
import { RESET_REDUX } from './types'

export const resetRedux = () => {
  return {
    type: RESET_REDUX
  }
}

export const { setLanguage, setProject, setProjectId, setTheme, setBottomMenuHeight, setAutoTest } =
  settingsSlice.actions
