import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dummyProject } from '../../utils/constants'
import { IProjectPascalCase } from '../../utils/interfaces/IProject.interface'
import { ISettingsState } from './types'

export const initialSettingsState: ISettingsState = {
  language: 'en',
  theme: 'light',
  currentProjectId: '',
  currentProject: dummyProject,
  isDemoMode: true,
  uiBlocksDimensions: {
    bottomMenu: {
      height: 140
    }
  },
  isAutoTest: false
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: { ...initialSettingsState },
  reducers: {
    setProjectId(state, action: PayloadAction<string>) {
      state.currentProjectId = action.payload
    },

    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload
    },

    setTheme(state, action: PayloadAction<string>) {
      state.theme = action.payload
    },

    setProject(state, action: PayloadAction<IProjectPascalCase>) {
      state.currentProject = action.payload
    },

    setBottomMenuHeight(state, action: PayloadAction<number>) {
      state.uiBlocksDimensions.bottomMenu.height = action.payload
    },

    setAutoTest(state, action: PayloadAction<boolean>) {
      state.isAutoTest = action.payload
    }
  }
})

export default settingsSlice.reducer
