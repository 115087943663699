import moment from 'moment'
import { ToastContainer } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import { Placement } from '../../../../libs/react-floater/types/index'
import { Step } from '../../../../libs/react-joyride/types'
import { isDefined, isNotDefined } from '../../../../libs/react-stockcharts/lib/utils'
import { IJoyrideState } from '../../../../redux/joyride/types'
import { store } from '../../../../redux/store/store'
import TelemetryService, { LikeLessonState } from '../../../../services/TelemetryService/TelemetryService'
import { CourseEventType, LessonEventType } from '../../../../services/TelemetryService/telemetryTypes'
import { lessonsRequiredSvgChartType, trendLineAppearance } from '../../../../utils/constants'
import {
  BasicLessonsID,
  ChartType,
  ComparisonOperator,
  CourseType,
  CourseTypeServerSide,
  FtControlName,
  FtControlType,
  GraphicToolType,
  IGraphicalLine,
  LineStyle,
  MarketOrderTypes,
  PendingOrderTypes,
  RibbonTabId,
  TickSize,
  TutorialActionType,
  TutorialStatus,
  WindowAlignment,
  WindowAlignmentType,
  WindowOrientationType
} from '../../../../utils/enums'
import { COMMON_DATE_FORMAT, DESKTOP_SHADOW_MARGIN, TUTORIAL_CONTENT_OFFSET } from '../../../../utils/helpers/constants'
import { isEmptyArray, isNotEmptyArray, isTargetIdInDOM, stringReplaceAll } from '../../../../utils/helpers/functions'
import { IChart } from '../../../../utils/interfaces/IChart.interface'
import {
  ICourseImage,
  ImageAlignment,
  IOverlay,
  ITutorialButton,
  IWindowScale
} from '../../../../utils/interfaces/ICourse.interface'
import { IInteractiveText, ITrendLine } from '../../../../utils/interfaces/IGraphics'
import { IBar } from '../../../../utils/interfaces/IHistoricalData.interface'
import { IHorizontalLine, IVerticalLine, LineType } from '../../../../utils/interfaces/ILine'
import {
  IMarketOrder,
  IMarketOrderSetupState,
  IPendingOrder,
  IPendingOrderSetupState
} from '../../../../utils/interfaces/IOrder.interface'
import {
  IEnableButtonRule,
  IGraphicalTool,
  IOrderProperties,
  ITutorial,
  ITutorialImage,
  ITutorialStep,
  ITutorialText,
  PropertiesType
} from '../../../../utils/interfaces/ITutorial.interface'
import { ID } from '../../../../utils/locators/ids'
import { LocalStorageKeys } from '../../../../utils/locators/localStorageKeys'
import { OpenPositionFooterIDs } from '../../../../utils/locators/locators'
import { WebTester } from '../../../webTester/WebTester'
import {
  handleJumpTo,
  handleStartPauseButtonToggle,
  handleTestingSpeedSet,
  handleTestRestart,
  handleTimeFrameDataGet
} from '../../../webTester/webTesterHandlers'
import {
  addGraphicToolToChart,
  convertTimeFrameToIndex,
  findCorrectBarBinarySearch,
  getNoHashtagTargetId,
  updateMarkersDataOnTimeframeChange
} from '../../../webTester/webTesterUtils'
import { disabledLessons } from '../../topMenuUtils/topMenuConstants'
import {
  convertControlId,
  convertEducationCourseTabId,
  convertFtControlName,
  convertGroupId,
  convertMenuItemId
} from '../../topMenuUtils/topMenuUtils'
import { IMarketOrderSavedState } from '../homeTab/orderModals/MarketOrderWindow/MarketOrderWindow'
import { scrollableMenuItemsIds } from './courseConstants'

export const handleTutorialConversion = (webTester: WebTester, tutorial: ITutorial) => {
  webTester.props.finishLesson()

  webTester.props.setGuideTourName(tutorial.Name)
  webTester.props.setGuideDescription(tutorial.Description)

  try {
    const convertedSteps: Step[] = convertTutorialSteps(webTester, tutorial)
    webTester.props.setStepActionType(TutorialActionType.START)
    webTester.telemetryService?.sendLessonEvent(
      webTester.props.joyride,
      webTester.props.language,
      LessonEventType.LESSON_STARTED
    )
    if (lessonsRequiredSvgChartType.includes(tutorial.OriginalLessonName as BasicLessonsID)) {
      webTester.props.setChartType(ChartType.SVG)
    }
    onNextStep(webTester, tutorial.Steps, 0, convertedSteps, '', () => {
      webTester.props.setGuideSteps(convertedSteps)
      webTester.props.setManualStart(true)
    })
  } catch (e) {
    toast.error(e as typeof ToastContainer)
  }
}

export function isMarketingCourse(tutorial: ITutorial) {
  return tutorial.Type === CourseType.Marketing
}

function convertFtControlType(step: ITutorialStep, target: string) {
  if (step.TutorialWindow.WindowAlignmentType === WindowAlignmentType.AlignedToLastOrderOnActiveChart) {
    return `#${ID.ChartWindow}`
  }

  if (step.TutorialWindow.FtControlType === FtControlType.PriceScale) {
    return '#YAxis'
  }

  return target
}

export function checkBasicCourseHardcodeTargets(
  stepIndex: number,
  tutorialOriginalName: string,
  target: string
): string {
  switch (tutorialOriginalName) {
    case BasicLessonsID.WhatIsBalance: {
      if (stepIndex === 1) {
        return `#${OpenPositionFooterIDs.BalanceLabelID}`
      }
      break
    }

    case BasicLessonsID.WhatIsMarginLevel: {
      if (stepIndex === 3) {
        return `#${OpenPositionFooterIDs.MarginLevelLabelID}`
      }
      break
    }

    case BasicLessonsID.WhatIsFreeMargin: {
      if (stepIndex === 3) {
        return `#${OpenPositionFooterIDs.EquityLabelID}`
      }
      if (stepIndex === 10) {
        return `#${OpenPositionFooterIDs.FreeMarginLabelID}`
      }
      if (stepIndex === 12) {
        return `#${OpenPositionFooterIDs.SummaryID}`
      }
      break
    }

    case BasicLessonsID.WhatIsMargin: {
      if (stepIndex === 13 || stepIndex === 20) {
        return `#${OpenPositionFooterIDs.MarginLabelID}`
      }
      break
    }

    case BasicLessonsID.BuyStopSellStopOrders: {
      if (stepIndex === 11) {
        return `#${ID.PendingOrdersTable}_ticket1`
      }
      break
    }

    case BasicLessonsID.WhatAreBaseAndQuoteCurrencies: {
      if (stepIndex === 4) {
        return '#ChartCurrentPrice'
      }
      break
    }
    default:
      break
  }

  return target
}

function adjustPlacementForSomeSteps(lessonName: BasicLessonsID, stepIndex: number, placement: Placement): Placement {
  switch (lessonName) {
    case BasicLessonsID.WhatIsAMarketOrder:
      if (stepIndex === 8) {
        placement = 'top'
      }
      break

    case BasicLessonsID.BuyStopSellStopOrders:
      if (stepIndex === 10) {
        placement = 'top'
      }
      break

    case BasicLessonsID.WhatIsFreeMargin:
      if (stepIndex === 17) {
        placement = 'top'
      } else if (stepIndex === 18) {
        placement = 'left'
      }
      break
    default:
      break
  }

  return placement
}

export const convertTutorialSteps = (webTester: WebTester, tutorial: ITutorial): Step[] => {
  const isMarketing: boolean = isMarketingCourse(tutorial)

  const defaultTutorialOptions = {
    showArrow: false,
    disableBeacon: true,
    spotlightPadding: 0,
    disableOverlayClose: true,
    spotlightClicks: true,
    disableCloseOnEsc: isMarketing
  }

  const {
    joyride: {
      originalTutorial: { CourseName, Name }
    }
  } = store.getState()

  return tutorial.Steps.map((step, stepIndex) => {
    let target: string = 'body'
    if (step.TutorialWindow.ControlId !== 0) target = convertControlId(step.TutorialWindow.ControlId!)

    if (isDefined(step.TutorialWindow.FtControlName))
      target = convertFtControlName(step.TutorialWindow.FtControlName!, step.TutorialWindow.FtControlType)

    if (step.TutorialWindow.GroupId !== 0) target = convertGroupId(step.TutorialWindow.GroupId!)

    if (step.TutorialWindow.MenuItemId !== 0) target = convertMenuItemId(step.TutorialWindow.MenuItemId!)

    if (step.TutorialWindow.IndicatorName !== null) target = step.TutorialWindow.IndicatorName

    if (step.StepInfo.StepActions && step.StepInfo.StepActions[0].Action.Properties.TabName) {
      target = convertFtControlName(
        step.StepInfo.StepActions[0].Action.Properties.TabName,
        step.TutorialWindow.FtControlType
      )
    }

    if (step.TutorialWindow.FtControlType) {
      if (target !== 'body') {
        target = `#${target}`
      } else {
        target = convertFtControlType(step, target)
      }
    }

    target = checkBasicCourseHardcodeTargets(stepIndex, tutorial.OriginalLessonName, target)

    const backNextFinishButton = {
      backgroundColor: '#3084E9',
      borderRadius: 6,
      color: '#ffffff'
    }

    let hideBackButton: boolean = true
    // Crutch of the Joyride library. The only way to hide the 'Next' button

    let hideNextButton: ITutorialButton = {
      buttonNext: {
        ...backNextFinishButton,
        display: 'none'
      },
      buttonClose: {
        display: 'none'
      }
    }

    const overlayStyle: IOverlay =
      target === 'body'
        ? {
            overlay: { pointerEvents: 'auto' }
          }
        : {}

    const spotlightStyle = step.MouseEnabledInActiveRect
      ? {
          spotlight: {
            animation: `pulse 750ms infinite alternate`
          }
        }
      : {}

    const twoSidesBoxShadow: number = 2 * DESKTOP_SHADOW_MARGIN
    const width: number = step.WindowLayout.Width - twoSidesBoxShadow
    const height: number = step.WindowLayout.Height + TUTORIAL_CONTENT_OFFSET - DESKTOP_SHADOW_MARGIN

    const windowScale: IWindowScale = !isMobile
      ? {
          options: {
            width,
            height
          },
          tooltip: {
            width,
            height
          }
        }
      : {}

    let placement: Placement = convertWindowAlignment(step.TutorialWindow.WindowAlignment, target)

    const viewportHeightToCheckStepAlignment = 768
    if (window.innerHeight < viewportHeightToCheckStepAlignment) {
      placement = adjustPlacementForSomeSteps(tutorial.OriginalLessonName as BasicLessonsID, stepIndex, placement)
    }

    return {
      ...defaultTutorialOptions,
      disableScrolling: isMobile ? [3, 9, 15].includes(stepIndex) : true,
      hideBackButton,
      styles: {
        ...hideNextButton,
        ...overlayStyle,
        ...spotlightStyle,
        ...windowScale
      },
      spotlightClicks: step.MouseEnabledInActiveRect && !(placement === 'center'),
      placement: placement,
      floaterProps:
        target === 'body'
          ? {
              hideArrow: true,
              placement: placement,
              offset: 0,
              disableAnimation: true
            }
          : {
              hideArrow: isAbsolute(step.TutorialWindow.WindowAlignment),
              offset: 0,
              disableAnimation: true
            },
      target,
      content: ''
    }
  })
}

export const convertWindowAlignment = (windowAlignment: WindowAlignment, target: string = ''): Placement => {
  if (target.includes(ID.ChartWindow)) {
    if (isAbsolute(windowAlignment)) {
      return 'center'
    }
    return 'top-start'
  }

  if (target === 'body') {
    return 'center'
  }

  switch (windowAlignment) {
    case WindowAlignment.TOP_LEFT:
      return 'top-start' as const
    case WindowAlignment.TOP_RIGHT:
      return 'top-end' as const
    case WindowAlignment.BOTTOM_LEFT:
      return 'bottom-start' as const
    case WindowAlignment.BOTTOM_RIGHT:
      return 'bottom-end' as const
    case WindowAlignment.ABSOLUTE_LEFT:
      return 'absolute-left' as const
    case WindowAlignment.ABSOLUTE_RIGHT:
      return 'absolute-right' as const
    case WindowAlignment.ABSOLUTE_TOP:
      return 'absolute-top' as const
    case WindowAlignment.ABSOLUTE_BOTTOM:
      return 'absolute-bottom' as const
    case WindowAlignment.ABSOLUTE_TOP_LEFT:
      return 'absolute-top-left' as const
    case WindowAlignment.ABSOLUTE_TOP_RIGHT:
      return 'absolute-top-right' as const
    case WindowAlignment.ABSOLUTE_BOTTOM_LEFT:
      return 'absolute-bottom-left' as const
    case WindowAlignment.ABSOLUTE_BOTTOM_RIGHT:
      return 'absolute-bottom-right' as const
    case WindowAlignment.LEFT:
      return 'left' as const
    case WindowAlignment.RIGHT:
      return 'right' as const
    case WindowAlignment.TOP:
      return 'top' as const
    case WindowAlignment.BOTTOM:
      return 'bottom' as const
    case WindowAlignment.CENTER:
      return 'center' as const
    default:
      return 'center' as const
  }
}

export const convertTextStyles = (text: ITutorialText) => {
  let size: number = 0
  let textAlign: string

  switch (text.Alignment) {
    case 0:
      textAlign = ImageAlignment.Left
      break
    case 1:
      textAlign = ImageAlignment.Right
      break
    case 2:
      textAlign = ImageAlignment.Top
      break
    case 3:
      textAlign = ImageAlignment.Bottom
      break
    default:
      textAlign = ImageAlignment.Left
      break
  }

  return {
    textSize: size || 16,
    textAlign,
    marginLeft: 20
  }
}

export const makeText = (text: ITutorialText) => {
  if (!isDefined(text.Ranges)) return text.Content

  let resultText: string = ''
  let prevTextRangePosition: number = 0
  let newLinesCount: number = 0

  text.Ranges.map((range) => {
    let rangedText = text.Content.substr(range.StartIndex + newLinesCount, range.Length)

    const localNewLinesCount = (rangedText.match(/\r\n/g) || '').length
    if (localNewLinesCount > 0) {
      rangedText = text.Content.substr(range.StartIndex + newLinesCount, range.Length + localNewLinesCount)

      newLinesCount += localNewLinesCount
    }

    resultText += `<span style=\'font-weight:${
      range.Weight === 500 ? 600 : range.Weight
    }; white-space: pre-wrap;\'>${rangedText}</span>`

    prevTextRangePosition = range.StartIndex + newLinesCount + range.Length
  })

  resultText += text.Content.substr(prevTextRangePosition, text.Content.length - prevTextRangePosition)

  return resultText
}

export const getImageData = (imagePath: string): ICourseImage => {
  // back-end: Education\\TutorialResources\\Smart Martin-2.png
  // front-end:				public/educationCourse/Smart Martin-2.png
  // alt: Smart Martin-2

  const courseImagesFolder: string = '/educationCourse/'

  let index: number = 0
  index = imagePath.lastIndexOf('\\') + 1
  if (index === 0) {
    index = imagePath.lastIndexOf('/')
  }

  let imageName: string = stringReplaceAll(imagePath.substring(index), '%20', ' ')
  imageName = stringReplaceAll(imageName, '%', '%25')
  const imageAlt: string = imageName.slice(0, -4)

  const path: string = courseImagesFolder + imageName

  return {
    path,
    alt: imageAlt
  }
}

export const generateOrders = (webTester: WebTester, stepIndex: number) => {
  let orders: IOrderProperties[] = []

  webTester.props.joyride.originalTutorial.Steps[stepIndex].StepInfo.StepActions.map((trigger) => {
    if (isDefined(trigger.Action.Properties.Orders)) {
      orders = [...orders, ...trigger.Action.Properties.Orders]
    }
  })

  if (isNotDefined(orders)) {
    toast.error(`Orders are undefined`)
    return
  }

  orders.map((orderProps: IOrderProperties, index: number) => {
    switch (orderProps.OrderType.toLowerCase()) {
      case MarketOrderTypes.Buy:
      case MarketOrderTypes.Sell: {
        createMarketOrder(webTester, orderProps, index)
        return
      }
    }

    switch (orderProps.OrderType.toLowerCase()) {
      case PendingOrderTypes.BuyLimit:
      case PendingOrderTypes.BuyStop:
      case PendingOrderTypes.SellStop:
      case PendingOrderTypes.SellLimit: {
        createPendingOrder(webTester, orderProps)
        return
      }
    }

    toast.warn(`Incorrect order type = ${orderProps.OrderType}`)
  })
}

function createPendingOrder(webTester: WebTester, order: IOrderProperties) {
  const {
    orders: { globalOrderTicket },
    graphs: {
      currentChart: { timeFrame }
    }
  } = webTester.props
  const { lastBarIndexes, visibleData } = webTester.state
  const lastBar: IBar = visibleData[order.Symbol][lastBarIndexes[order.Symbol]]

  const pendingOrder: IPendingOrder = {
    type: order.OrderType,
    ticket: globalOrderTicket + 1,
    sl: order.StopLoss,
    tp: order.TakeProfit,
    lots: order.Lot,
    symbol: order.Symbol,
    barIndex: lastBarIndexes[order.Symbol],
    comment: '',
    marketPrice: 0,
    createdAt: '',
    execPrice: 0 // ?
  }

  webTester.props.createPendingOrder({ order: pendingOrder })
}

function createMarketOrder(webTester: WebTester, orderProps: IOrderProperties, index: number) {
  const {
    orders: { globalOrderTicket },
    graphs: { charts }
  } = webTester.props
  const { lastBarIndexes, visibleData } = webTester.state

  const symbol: string = orderProps.Symbol
  const timeFrame = getMatchedChartTimeFrame(symbol, charts)

  const chartTimeFrame: string = symbol + timeFrame
  const lastBarIndex: number = lastBarIndexes[chartTimeFrame]
  const lastBar: IBar = visibleData[chartTimeFrame][lastBarIndex]

  const marketOrder = buildMarketOrder(
    orderProps,
    globalOrderTicket + 1 + index,
    lastBarIndexes[chartTimeFrame],
    lastBar
  )

  webTester.props.createMarketOrder({ order: marketOrder, timeframe: timeFrame })
  webTester.props.incrementGlobalOrderTicket()
  webTester.props.setWaitMarketOrder(true)
  charts.forEach((chart) => {
    if (chart.symbol === symbol && chart.timeFrame !== timeFrame) {
      updateMarkersDataOnTimeframeChange(
        visibleData[chart.symbol + chart.timeFrame],
        webTester.props.orders.orderMarkers,
        chart.timeFrame,
        timeFrame,
        symbol
      )
    }
  })
}

export const getMatchedChartTimeFrame = (symbol: string, charts: Array<IChart>): number => {
  const matchedChart = charts.find((chart) => {
    return chart.symbol === symbol
  })

  let timeFrame: number = 0
  if (matchedChart) timeFrame = matchedChart.timeFrame

  return timeFrame
}

export function buildMarketOrder(orderProps: IOrderProperties, ticketId: number, barIndex: number, lastBar: IBar) {
  const marketOrder: IMarketOrder = {
    type: orderProps.OrderType.toLowerCase(),
    ticket: ticketId,
    sl: orderProps.StopLoss,
    tp: orderProps.TakeProfit,
    lots: orderProps.Lot,
    symbol: orderProps.Symbol,
    barIndex,
    openTime: moment.utc(lastBar.date).format(COMMON_DATE_FORMAT),
    openPrice: lastBar.close,
    swap: 0,
    commission: 0,
    points: 0,
    profit: 0,
    comment: '',
    marketPrice: lastBar.close,
    timeFrame: 5
  }

  return marketOrder
}

export function generateGraphicalTools(webTester: WebTester, stepIndex: number) {
  let GraphicalTools: IGraphicalTool[] = []

  webTester.props.joyride.originalTutorial.Steps[stepIndex].StepInfo.StepActions.map((trigger) => {
    if (isDefined(trigger.Action.Properties.GraphicalTools)) {
      GraphicalTools = [...GraphicalTools, ...trigger.Action.Properties.GraphicalTools]
    }
  })

  if (isNotDefined(GraphicalTools) || isEmptyArray(GraphicalTools)) {
    // toast.error('Graphic tools are not defined')
    return
  }

  const { timeFrame, symbol } = webTester.props.graphs.currentChart
  const symbolTimeframe = symbol + timeFrame

  GraphicalTools.map((graphicalTool: IGraphicalTool) => {
    switch (graphicalTool.PropertyType) {
      case PropertiesType.NEXT_STEP:
        break
      case PropertiesType.NEWS_TRIGGER:
        break
      case PropertiesType.NUMBER_VALIDATION_ACTION:
        break
      case PropertiesType.WINDOW_TAB_PROPERTIES:
        break
      case PropertiesType.WAIT_MOUSE_CLICK:
        break
      case PropertiesType.WAIT_CONTROL_VALUE_CHANGED:
        break
      case PropertiesType.GRAPHIC_OBJECT_VERTICAL_LINE: {
        let keyBar: IBar = {
          close: 0,
          // date: graphicalTool.DateStart,
          date: moment.utc(graphicalTool.DateTime).local().format('YYYY-MM-DD HH:mm:ss'),
          high: 0,
          low: 0,
          open: 0,
          volume: 0
        }
        const barIndex = findCorrectBarBinarySearch(
          0,
          webTester.state.entireData[symbolTimeframe].length,
          webTester.state.entireData[symbolTimeframe],
          keyBar
        )
        const line: IVerticalLine = {
          type: LineType.Vertical,
          xPos: barIndex,
          color: '',
          strokeType: LineStyle.Solid
        }

        addGraphicToolToChart(webTester, line, timeFrame, symbol, GraphicToolType.VERTICAL_LINE)

        break
      }
      case PropertiesType.GRAPHIC_OBJECT_HORIZONTAL_LINE: {
        const line: IHorizontalLine = {
          type: LineType.Horizontal,
          yPos: graphicalTool.Price,
          color: '',
          strokeType: LineStyle.Solid
        }

        addGraphicToolToChart(webTester, line, timeFrame, symbol, GraphicToolType.HORIZONTAL_LINE)
        break
      }
      case PropertiesType.GRAPHIC_OBJECT_TREND_LINE: {
        // TODO: swap hardcore data to graphicTool data
        let keyBar: IBar = {
          close: 0,
          // date: graphicalTool.DateStart,
          date: moment.utc(graphicalTool.DateStart).local().format('YYYY-MM-DD HH:mm:ss'),
          high: 0,
          low: 0,
          open: 0,
          volume: 0
        }
        const startBarIndex = findCorrectBarBinarySearch(
          0,
          webTester.state.visibleData[symbolTimeframe].length,
          webTester.state.visibleData[symbolTimeframe],
          keyBar
        )
        // keyBar.date = graphicalTool.DateEnd
        keyBar.date = '2020-02-05T10:00:00.000Z'
        const endBarIndex = findCorrectBarBinarySearch(
          0,
          webTester.state.visibleData[symbolTimeframe].length,
          webTester.state.visibleData[symbolTimeframe],
          keyBar
        )
        const newTrendLine: ITrendLine = {
          type: IGraphicalLine.Line,
          selected: false,
          start: [startBarIndex, 1.1015],
          end: [endBarIndex, 1.1027],
          appearance: { ...trendLineAppearance, pointerRadius: 1 }
        }

        addGraphicToolToChart(webTester, newTrendLine, timeFrame, symbol, GraphicToolType.TREND)
        break
      }

      case PropertiesType.GRAPHIC_OBJECT_RAY: {
        // TODO: swap hardcore data to graphicTool data
        let keyBar: IBar = {
          close: 0,
          date: moment.utc(graphicalTool.DateStart).local().format('YYYY-MM-DD HH:mm:ss'),
          high: 0,
          low: 0,
          open: 0,
          volume: 0
        }
        const startBarIndex = findCorrectBarBinarySearch(
          0,
          webTester.state.visibleData[symbolTimeframe].length,
          webTester.state.visibleData[symbolTimeframe],
          keyBar
        )
        keyBar.date = moment.utc(graphicalTool.DateEnd).local().format('YYYY-MM-DD HH:mm:ss')
        const endBarIndex = findCorrectBarBinarySearch(
          0,
          webTester.state.visibleData[symbolTimeframe].length,
          webTester.state.visibleData[symbolTimeframe],
          keyBar
        )
        const newRay: ITrendLine = {
          type: IGraphicalLine.Ray,
          selected: false,
          start: [startBarIndex, graphicalTool.PriceStart],
          end: [endBarIndex, graphicalTool.PriceEnd],
          appearance: { ...trendLineAppearance, pointerRadius: 1 }
        }

        addGraphicToolToChart(webTester, newRay, timeFrame, symbol, GraphicToolType.RAY)
        break
      }
      case PropertiesType.MARKET_ORDER:
        break
      case PropertiesType.WAIT_DATE_TIME:
        break
      case PropertiesType.LAST:
        break
      case PropertiesType.UNKNOWN:
        break
      case PropertiesType.TEXT_LABELS:
        let keyBar: IBar = {
          close: 0,
          date: moment.utc(graphicalTool.DateTime).local().format('YYYY-MM-DD HH:mm:ss'),
          high: 0,
          low: 0,
          open: 0,
          volume: 0
        }
        const startBarIndex = findCorrectBarBinarySearch(
          0,
          webTester.state.entireData[symbolTimeframe].length,
          webTester.state.entireData[symbolTimeframe],
          keyBar
        )
        const newTextLabel: IInteractiveText = {
          position: [startBarIndex, graphicalTool.Price],
          text: graphicalTool.Text,
          bgFill: '#D3D3D3',
          bgOpacity: 0,
          textFill: '#000',
          bgStrokeWidth: 1,
          fontFamily: 'Arial',
          fontSize: isMobile ? 14 : 20,
          fontStyle: 'normal',
          fontWeight: '500',
          selected: false
        }
        addGraphicToolToChart(webTester, newTextLabel, timeFrame, symbol, GraphicToolType.TEXT)
        break
      default:
        console.warn(`Unknown graphic tool ${graphicalTool.PropertyType}`)
    }
  })
}

export const findStepIndexByAction = (Steps: ITutorialStep[], action: string) => {
  const nextStepIndex = Steps.findIndex((step) => step.Name === action)
  return nextStepIndex
}

export const getActionType = (buttonContent: string) => {
  switch (buttonContent) {
    case 'Next':
      return TutorialActionType.NEXT
    case 'Back':
      return TutorialActionType.BACK
    default:
      return TutorialActionType.ANY
  }
}

function isTargetIdFromScrollableMenu(step: Step) {
  return scrollableMenuItemsIds.filter((id) => id === step.target.toString().substring(1)).length > 0
}

export const checkAndMakeTargetIdVisible = async (webTester: WebTester, nextStepIndex: number): Promise<void> => {
  const {
    joyride: { step, guideSteps }
  } = webTester.props

  if (!isMobile || isNotDefined(guideSteps[step])) return new Promise((resolve) => resolve())

  if (step === 5) {
    webTester.props.toggleMobileMenuInitialState()
  }

  if (isTargetIdFromScrollableMenu(guideSteps[nextStepIndex])) {
    if (!webTester.props.mobile.scrollableMenu && [4, 23].includes(nextStepIndex)) {
      webTester.props.toggleScrollableMenu(true)
    } else if (nextStepIndex === 5 || nextStepIndex === 14) {
      webTester.props.toggleMobileMenuInitialState()
      webTester.props.toggleAccountHistoryMenu(true)
    } else if (nextStepIndex === 15) {
      webTester.props.toggleMobileMenuInitialState()
      webTester.props.toggleTimeframesSelect(true)
      return new Promise((resolve) => {
        const interval = setInterval(async () => {
          if (webTester.props.mobile.timeframesMenu) {
            clearInterval(interval)
            resolve()
          }
        }, 10)
      })
    }
  }

  return new Promise((resolve) => resolve())
}

export function checkTabId(webTester: WebTester, originalStep: ITutorialStep, step) {
  if (isMobile || isNotDefined(originalStep) || isNotDefined(originalStep.TutorialWindow.RibbonTabId)) return

  const stepTabId = convertEducationCourseTabId(originalStep.TutorialWindow.RibbonTabId!)

  if (stepTabId !== RibbonTabId.UNKNOWN && stepTabId !== webTester.props.panels.topMenuTab) {
    webTester.props.selectTopMenuTab(stepTabId)
  }

  const target = getNoHashtagTargetId(step)

  selectBottomMenuTab(webTester, target as ID)
}

export function checkTargetId(targetId: string, action: () => void) {
  const timer = setInterval(() => {
    if (isTargetIdInDOM(targetId) || targetId === 'body') {
      action()
      clearInterval(timer)
    }
  }, 5)
}

export async function checkTimeframe(webTester: WebTester, newStep: ITutorialStep) {
  const timeFrame: number = convertTimeFrameToIndex(newStep.ChartsSettings.ChartTimeFrame)

  const stepRequiresTimeFrameChange =
    isDefined(timeFrame) && timeFrame !== 0 && webTester.props.graphs.currentChart.timeFrame !== timeFrame

  if (stepRequiresTimeFrameChange) {
    const chart: IChart = {
      ...webTester.props.graphs.currentChart,
      timeFrame: timeFrame
    }

    return handleTimeFrameDataGet(webTester, chart)
  }
}

export function isNextCommonTab(Steps: ITutorialStep[], nextStepIndex: number): boolean {
  const isTargetTabCaption = Steps[nextStepIndex].TutorialWindow.FtControlType === FtControlType.WindowTabCaptions

  return (
    isTargetTabCaption &&
    Steps[nextStepIndex].StepInfo.StepActions &&
    (Steps[nextStepIndex].StepInfo.StepActions[0].Action.Properties.TabName === FtControlName.PendingOrderCommonTab ||
      Steps[nextStepIndex].StepInfo.StepActions[0].Action.Properties.TabName === FtControlName.MarketOrderCommonTab)
  )
}

export function checkCommonTab(Steps: ITutorialStep[], nextStepIndex: number) {
  if (isNextCommonTab(Steps, nextStepIndex)) return nextStepIndex + 1

  return nextStepIndex
}

export function onNextStep(
  webTester: WebTester,
  Steps: ITutorialStep[],
  nextStepIndex: number,
  guideSteps: Step[],
  buttonContent: string = '',
  action: () => void = () => {}
) {
  nextStepIndex = checkValidateRules(Steps, webTester, nextStepIndex)
  nextStepIndex = checkCommonTab(Steps, nextStepIndex)

  checkTabId(webTester, Steps[nextStepIndex], guideSteps[nextStepIndex])
  const targetId = getNoHashtagTargetId(guideSteps[nextStepIndex])
  checkAndMakeTargetIdVisible(webTester, nextStepIndex).then(() => {
    checkTargetId(targetId, () => {
      const Step = webTester.props.joyride.originalTutorial.Steps[nextStepIndex]
      const { StepActions } = webTester.props.joyride.originalTutorial.Steps[nextStepIndex].StepInfo

      checkTimeframe(webTester, Step)
        .then(() => {
          if (Step.TestingSettings.RestartProject) {
            handleTestRestart(webTester)
            handleStartPauseButtonToggle(webTester)
            if (Step.TestingSettings.StartTestingAfterRestart) {
              handleStartPauseButtonToggle(webTester)
            }
          }

          if (Step.TestingSettings.RestartProject) {
            handleTestRestart(webTester)
            handleStartPauseButtonToggle(webTester)
            if (Step.TestingSettings.StartTestingAfterRestart) {
              handleStartPauseButtonToggle(webTester)
            }
          }

          if (TickSize[Step.TestingSettings.TickPackage]) {
            webTester.props.setTickSize(TickSize[Step.TestingSettings.TickPackage])
          }

          if (Step.TestingSettings.TestingSpeed > 0) {
            handleTestingSpeedSet(webTester, Math.floor(Step.TestingSettings.TestingSpeed / 2))
          }

          if (Step.TestingSettings.ResumeTesting) {
            handleStartPauseButtonToggle(webTester)
          }

          if (Step.TestingSettings.PauseTesting && !webTester.state.testIsPaused) {
            handleStartPauseButtonToggle(webTester)
          }

          if (isDefined(Step.TestingSettings.JumpTo)) {
            handleJumpTo(webTester, Step.TestingSettings.JumpTo!)
          }

          if (isDefined(StepActions)) {
            if (Step.TestingSettings.RemoveObjects) {
              webTester.props.deleteAllObjects()
            }
            generateGraphicalTools(webTester, nextStepIndex)
            generateOrders(webTester, nextStepIndex)
          }

          switchChartsOrientation(Step.TestingSettings.WndOrientation, webTester)

          if (Step.ChartsSettings.ActiveChart !== 'Any') {
            webTester.props.setCurrentChart({ symbol: Step.ChartsSettings.ActiveChart })
          }

          webTester.props.setStepActionType(getActionType(buttonContent))
          webTester.props.setStepIndex(nextStepIndex)
          action()
        })
        .catch((e) => {
          toast.error(`The step ${Step.Name} is not loaded correctly`)
          console.warn(e)
        })
    })
  })
}

export function isStepInsideViewport(stepHeight): boolean {
  return stepHeight <= window.innerHeight
}

export function getSkippedImageHeight(stepIsInsideViewPort: boolean, images: ITutorialImage[]) {
  // TODO: compute a sum of images height if images is set one by one
  if (stepIsInsideViewPort && isNotEmptyArray(images)) {
    return images[0].Layout.Height
  }

  return 0
}

export function switchChartsOrientation(orientation: WindowOrientationType, webTester: WebTester) {
  if (isMobile) {
    return
  }

  switch (orientation) {
    case WindowOrientationType.MAXIMAZE_ACTIVE:
      webTester.props.toggleSingleActiveChart()
      break
    case WindowOrientationType.TILE_HORIZONTALLY:
      webTester.props.toggleTileHorizontally()
      break
    case WindowOrientationType.TILE_VERTICALLY:
      webTester.props.toggleTileVertically()
      break
    default:
      break
  }
}

export function convertCourseNameToId(courseType: CourseType): number {
  switch (courseType) {
    case CourseType.QuickStartGuide:
      return CourseTypeServerSide.QuickStartGuide
    case CourseType.Basic:
      return CourseTypeServerSide.Basic
    case CourseType.Intermediate:
      return CourseTypeServerSide.Intermediate
    case CourseType.Advanced:
      return CourseTypeServerSide.Advanced
    case CourseType.Marketing:
      return CourseTypeServerSide.Marketing
    default:
      throw new Error('Unknown course type ' + courseType)
  }
}

export function isAbsolute(windowAlignment: WindowAlignment): boolean {
  return windowAlignment >= WindowAlignment.ABSOLUTE_LEFT && windowAlignment <= WindowAlignment.ABSOLUTE_BOTTOM_RIGHT
}

export function getBottomMenuId(target: string) {
  if (target.includes(ID.OpenPositionsTable)) {
    return 0
  }
  if (target.includes(ID.PendingOrdersTable)) {
    return 1
  }
  if (target.includes(ID.AccountHistoryTable)) {
    return 2
  }

  return undefined
}

function selectBottomMenuTab(webTester: WebTester, target: string) {
  const tabId: number | undefined = getBottomMenuId(target)
  if (isDefined(tabId)) {
    webTester.props.setBottomMenuTab(tabId!)
  }
}

export function getOrderType(joyride: IJoyrideState, savedState: IMarketOrderSavedState): MarketOrderTypes {
  const {
    guideIsOn,
    originalTutorial: { OriginalLessonName },
    step
  } = joyride

  if (!guideIsOn) return savedState.buySell || MarketOrderTypes.Sell

  if (OriginalLessonName === BasicLessonsID.WhatIsMargin) {
    if (step === 8) return MarketOrderTypes.Buy
  }

  if (OriginalLessonName === BasicLessonsID.WhatIsFreeMargin) return MarketOrderTypes.Buy

  return savedState.buySell || MarketOrderTypes.Sell
}

export function isNextButtonEnabled(
  buttonRules: IEnableButtonRule,
  marketOrderState: IMarketOrderSetupState,
  pendingOrderState: IPendingOrderSetupState,
  currentChartSymbol: string
): boolean {
  if (!buttonRules.Value) return false

  switch (buttonRules.FtControlName) {
    case FtControlName.MarketOrderSymbol:
      return !(buttonRules.Value === currentChartSymbol)
    case FtControlName.MarketOrderLot:
      return !(buttonRules.Value === marketOrderState.lots.toString())
    case FtControlName.PendingOrderType:
      return !(buttonRules.Value === pendingOrderState.type)
    default:
      return false
  }
}

function checkValidateRules(Steps: ITutorialStep[], webTester: WebTester, nextStepIndex: number) {
  const stepActions = Steps[webTester.props.joyride.step].StepInfo.StepActions
  if (stepActions) {
    const validationRules =
      Steps[webTester.props.joyride.step].StepInfo.StepActions[0].Action.Properties.ValidateNumberRules

    if (validationRules) {
      const currentChart = webTester.props.graphs.currentChart
      const isValid = compareWithOperator(
        Number(webTester.props.graphics.stopOrdersLines.execPrice.yPos),
        webTester.state.currentBars[currentChart.symbol + currentChart.timeFrame].close,
        validationRules.ComparisonOperator
      )

      if (isValid) {
        nextStepIndex = findStepIndexByAction(Steps, validationRules.NextStepIfValid)
      } else {
        nextStepIndex = findStepIndexByAction(Steps, validationRules.NextStepIfInvalid)
      }
    }
  }
  return nextStepIndex
}

export function compareWithOperator(left: number, right: number, operator: ComparisonOperator): boolean {
  switch (operator) {
    case ComparisonOperator.EQUAL:
      return left === right
    case ComparisonOperator.NOT_EQUAL:
      return left !== right
    case ComparisonOperator.LESS:
      return left < right
    case ComparisonOperator.GREATER:
      return left > right
    case ComparisonOperator.LESS_OR_EQUAL:
      return left <= right
    case ComparisonOperator.GREATER_OR_EQUAL:
      return left >= right
    default:
      console.warn(`${operator} - incorrect operator`)
      return false
  }
}

export const setLessonStatusInLocalStorage = (originalLessonName: string, status: string): void => {
  let lessonsStatus = localStorage.getItem('lessonsStatus')
  if (!lessonsStatus) lessonsStatus = '{}'

  let parseLessonsStatus = JSON.parse(lessonsStatus)

  const tutorial = parseLessonsStatus[originalLessonName]
  if (tutorial && tutorial === TutorialStatus.FINISHED) {
    return
  }

  parseLessonsStatus = {
    ...parseLessonsStatus,
    [originalLessonName]: status
  }

  localStorage.setItem('lessonsStatus', JSON.stringify(parseLessonsStatus))
}

export const progressCourse = (): string => {
  let lessonsStatus = localStorage.getItem('lessonsStatus')

  if (lessonsStatus) {
    let parseLessonsStatus = JSON.parse(lessonsStatus)

    if (parseLessonsStatus) {
      let statuses = Object.values(parseLessonsStatus)
      if (statuses.every((i) => i === TutorialStatus.FINISHED)) {
        return TutorialStatus.FINISHED
      } else if (statuses.some((i) => i === TutorialStatus.STARTED || i === TutorialStatus.FINISHED)) {
        return TutorialStatus.STARTED
      }
    }
  }
  return TutorialStatus.NOT_STARTED
}

export const getTutorialStatusInLocalStorage = () => {
  let lessonsStatus = localStorage.getItem('lessonsStatus')
  if (!lessonsStatus) lessonsStatus = '{}'
  return JSON.parse(lessonsStatus)
}

export function isAlreadySent(enabledLessonsCount: number, key: string) {
  const count = localStorage.getItem(key)

  if (!count) return

  return Number(count) === enabledLessonsCount
}

export async function checkCompletedLessonsProgressAndSendTelemetry(
  telemetryService: TelemetryService,
  courseName: string,
  lessonsCount: number | undefined,
  language: string
) {
  const lessonsStatus = localStorage.getItem('lessonsStatus')
  if (!lessonsStatus) return

  const parseLessonsStatus = JSON.parse(lessonsStatus)
  if (!parseLessonsStatus) return

  const statuses = Object.values(parseLessonsStatus)
  const finishedCount = statuses.reduce<number>(
    (accumulator, currentValue) => accumulator + (currentValue === TutorialStatus.FINISHED ? 1 : 0),
    0
  )

  const enabledLessonsCount = lessonsCount && lessonsCount - disabledLessons.length

  if (!enabledLessonsCount) return

  const checkProgressAndSendTelemetry = async (key: string, courseEvent: CourseEventType, percent: number) => {
    const completedLevelReached = finishedCount >= enabledLessonsCount * percent
    if (!completedLevelReached) return

    if (isAlreadySent(enabledLessonsCount, key)) return

    const sentSuccessfully = await telemetryService.sendCourseEvent(courseName, language, courseEvent)

    sentSuccessfully && localStorage.setItem(key, JSON.stringify(enabledLessonsCount))
  }

  await checkProgressAndSendTelemetry(LocalStorageKeys.COURSE_COMPLETED_50, CourseEventType.COURSE_COMPLETED_50, 0.5)
  await checkProgressAndSendTelemetry(LocalStorageKeys.COURSE_COMPLETED_80, CourseEventType.COURSE_COMPLETED_80, 0.8)
  await checkProgressAndSendTelemetry(LocalStorageKeys.COURSE_COMPLETED_100, CourseEventType.COURSE_COMPLETED_100, 1)
}

export function isFloaterDisabledForMobileIntro(step: number): boolean {
  return isMobile && [10, 13, 18, 20].includes(step)
}

export function getLessonRateFromLocalStorage(originalLessonName: string): LikeLessonState {
  const lessonsRates = localStorage.getItem(LocalStorageKeys.LESSONS_RATE)
  if (!lessonsRates) return LikeLessonState.NONE

  const lessonsRatesObject = JSON.parse(lessonsRates)
  if (!lessonsRatesObject) return LikeLessonState.NONE

  if (!lessonsRatesObject[originalLessonName]) return LikeLessonState.NONE

  return lessonsRatesObject[originalLessonName] as LikeLessonState
}

export function setLessonRateToLocalStorage(originalLessonName: string, likeLessonState: LikeLessonState): void {
  const lessonsRates = localStorage.getItem(LocalStorageKeys.LESSONS_RATE)
  if (!lessonsRates) {
    const state = {}
    state[originalLessonName] = likeLessonState
    localStorage.setItem(LocalStorageKeys.LESSONS_RATE, JSON.stringify(state))
    return
  }

  const lessonsRatesObject = JSON.parse(lessonsRates) || {}
  lessonsRatesObject[originalLessonName] = likeLessonState
  localStorage.setItem(LocalStorageKeys.LESSONS_RATE, JSON.stringify(lessonsRatesObject))
}
