import { IProjectPascalCase } from '../../utils/interfaces/IProject.interface'

export const RESET_REDUX = 'RESET_REDUX'

export interface IDimension {
  height?: number
  width?: number
}

export interface ISettingsState {
  language: string
  theme: string
  currentProjectId: string
  currentProject: IProjectPascalCase
  isDemoMode: boolean
  uiBlocksDimensions: {
    bottomMenu: IDimension
  }
  isAutoTest: boolean
}

export interface ResetReduxAction {
  type: typeof RESET_REDUX
}

export type SettingsActionTypes = ResetReduxAction
