import 'bootstrap-css-only/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import React, { FC, lazy, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { isMobile } from 'react-device-detect'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { setAutoTest } from '../../redux/settings/actions'
import { useAppDispatch } from '../../redux/store_hooks'
import { setFtoRunMode, setUserGroup } from '../../redux/telemetry/actions'
import '../../scss/main.scss'
import { RouteService } from '../../services/RouteService/RouteService'
import messages_en from '../../translations/English.json'
import messages_ja from '../../translations/Japanese.json'
import messages_ru from '../../translations/Russian.json'
import messages_es from '../../translations/Spanish.json'
import { RunMode } from '../../utils/enums'
import { initializeGoogleTagManager, initializeJivoChat } from '../../utils/helpers'
import { IMessage } from '../../utils/interfaces/IMessage'

const WebTester = lazy(() => import('../webTester/WebTester'))
const Demo = lazy(() => import('./Demo'))
const SignUp = lazy(() => import('./SignUp'))
const Login = lazy(() => import('./Login'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const Admin = lazy(() => import('./Admin'))
const PageNotFound = lazy(() => import('../pageNotFound/PageNotFound'))

const messages: IMessage = {
  en: messages_en,
  ja: messages_ja,
  es: messages_es,
  ru: messages_ru
}

const AppRouter: FC = () => {
  const [language, setLanguage] = useState('en')
  const [mess, setMessages] = useState(messages[language])
  const [mode, setMode] = useState(RunMode.BASIC)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const routeService = new RouteService(window.location.search)

    const lang = routeService.getLanguage()
    const runMode = routeService.getRunMode()
    // const runMode = RunMode.MARKETING
    const isAutoTest = routeService.getIsAutotest()
    const isTest = routeService.getIsTestMode()
    dispatch(setUserGroup(routeService.getUserGroup()))
    dispatch(setFtoRunMode({ runMode, isTest: isAutoTest || isTest }))
    dispatch(setAutoTest(isAutoTest))
    setLanguage(lang)
    setMessages(messages[lang])
    setMode(runMode)

    routeService.clearRoute(lang, runMode, isAutoTest || isTest)

    if (!isMobile) initializeJivoChat()
    if (process.env.NODE_ENV === 'production') {
      initializeGoogleTagManager()
    }
  }, [])

  const handleLanguageSet = (language: string) => {
    setLanguage(language)
    setMessages(messages[language])
    // toggleSettingsTab()
  }

  return (
    <IntlProvider locale={language} defaultLocale='en' messages={mess}>
      <HelmetProvider>
        <Router>
          <Routes>
            {/* <Notifications /> */}
            <Route
              path='/'
              element={<WebTester onLanguageSet={handleLanguageSet} language={language} runMode={mode} />}
            />
            <Route
              path='/default'
              element={<WebTester onLanguageSet={handleLanguageSet} language={language} runMode={RunMode.DEFAULT} />}
            />
            <Route
              path='/constructor'
              element={<WebTester onLanguageSet={handleLanguageSet} language={language} runMode={RunMode.DEFAULT} />}
            />
            <Route path='/demo' element={Demo} />
            <Route path='/sign-up' element={SignUp} />
            <Route path='/login' element={Login} />
            <Route path='/forgot-password' element={ForgotPassword} />
            <Route path='/admin' element={Admin} />
            <Route element={PageNotFound} />
            <Route path='/404' element={PageNotFound} />
          </Routes>
        </Router>
      </HelmetProvider>
    </IntlProvider>
  )
}

export default AppRouter
