export interface IOrder {
	ticket: number
	symbol: string
	type: string
	lots: number
	sl: number
	tp: number
	comment: string
	barIndex: number
}

export interface IMarketOrder extends IOrder {
  openTime: string
  openPrice: number
  marketPrice: number
  swap: number
  commission: number
  points: number
  profit: number
  barIndex: number
  timeFrame: number

  // [mobile] open positions
  isOpen?: boolean
  backgroundType?: string
  symbolType?: string
}

export interface IMarketOrderSetupState extends IMarketOrder {
  riskCalculationLabel: boolean
  stopLossTooltip: boolean
  takeProfitTooltip: boolean
  riskPercentage: number
  calculatedLots: number
  slButton: number
  tpButton: number
  slDropdown: number
  tpDropdown: number
}

export interface IPendingOrderSetupState extends IPendingOrder {
  riskCalculationLabel: boolean
  stopLossTooltip: boolean
  takeProfitTooltip: boolean
  riskPercentage: number
  calculatedLots: number
  slButton: number
  tpButton: number
  execPriceButton: number
  execPriceDropdown: number
  slDropdown: number
  tpDropdown: number
  comment: string
}

export interface IPendingOrder extends IOrder {
  createdAt: string
  execPrice: number
  marketPrice: number
}

export interface IAccountHistory extends IOrder {
  openTime: string
  openPrice: number
  closeTime: string
  closePrice: number
  swap: number
  commission: number
  points: number
  profit: number
}

export enum IOrderType {
  Buy = 0,
  Sell = 1,
  BuyLimit = 2,
  SellLimit = 3,
  BuyStop = 4,
  SellStop = 5
}

export interface IMarketOrderPascalCase {
  Type: IOrderType
  OpenTime: number
  OpenPrice: number
  MarketPrice: number
  Swap: number
  Commission: number
  Points: number
  Profit: number
  Ticket: number
  Symbol: string
  Volume: number
  StopLoss: number
  TakeProfit: number
  Comment: string
  BarIndex: number
  TimeFrame: number
}

export interface IPendingOrderPascalCase {
	Type: IOrderType
	CreateTime: number
	ExecutionPrice: number
	MarketPrice: number
	Ticket: number
	Symbol: string
	Volume: number
	StopLoss: number
	TakeProfit: number
	Comment: string
	BarIndex: number
}

export interface IClosedOrder {
	CloseTime: number
	ClosePrice: number
	Type: IOrderType
	OpenTime: number
	OpenPrice: number
	MarketPrice: number
	Swap: number
	Commission: number
	Points: number
	Profit: number
	Ticket: number
	Symbol: string
	Volume: number
	StopLoss: number
	TakeProfit: number
	Comment: string
	BarIndex: number
}

export interface IAccountInfo {
	balance: number
	equity: number
	margin: number
	freeMargin: number
	marginLevel: number
	totalProfit: number
	dollarsPerPipPerLot: number
	lotSize: number
	leverage: number
}

export interface IOrderInfo {
	ticket: number
	symbol: string
	type: string
	lots: number
	calculatedLots: number
	riskCalculationLabel: boolean
	riskPercentage: number
	slButton: number
	tpButton: number
	sl: number
	tp: number
	slDropdown: number
	tpDropdown: number
	marketPrice: number
	comment: string
	barIndex: number
}

export interface IMarketOrderInfo extends IOrderInfo {
	forecastType: string
	openTime: string
	openPrice: number
	closeTime: string
	closePrice: number
	swap: number
	commission: number
	points: number
	profit: number
}

export interface IPendingOrderInfo extends IOrderInfo {
	slButtonCounter: number
	tpButtonCounter: number
	createdAt: string
	execPrice: number
	execPriceButton: number
	execPriceDropdown: number
}

export interface IAccountHistoryInfo extends IOrderInfo {
	openTime: string
	openPrice: number
	closeTime: string
	closePrice: number
	swap: number
	commission: number
}

export interface ILineInfo {
	ticket: number
	type: string
	openPrice?: number
	execPrice?: number
	symbol?: string
	sl: number
	tp: number
}

export interface IOrderMobile {
	ticket: number
	symbol: string
	type: string
	lots: number
	sl: number
	tp: number
	marketPrice: number
}

export interface IMarketOrderMobile extends IOrderMobile {
	openTime: string
	openPrice: number
	// closeTime: string
	// closePrice: number
	swap: number
	commission: number
	points: number
	profit: number
}

export interface IPendingOrderMobile extends IOrderMobile {
	createdAt: string
	execPrice: number
}

export interface IClosedOrderMobile extends IOrderMobile {
	openTime: string
	openPrice: number
	closeTime: string
	closePrice: number
	swap: number
	commission: number
}

export enum OrderMarkerTypes {
	Open = 'Open',
	Close = 'Close'
}

export interface IOrderMarker {
	id: number
	symbol: string
	markerType: OrderMarkerTypes
	orderType: string
	openTime: string
	profit: number
	x1: {
		[timeframe: number]: number
	}
	y1: number
	closeTime: string | undefined
	x2: {
		[timeframe: number]: number
	}
	y2: number | undefined
}

export interface IStopOrder {
	execPrice?: number | undefined
	stopLoss: number
	takeProfit: number
}
