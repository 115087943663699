import { WebTesterState } from '../../components/webTester/helpers/interface'

export type SavableWebTesterState = Pick<
  WebTesterState,
  | 'accountInfo'
  //   | 'visibleData'
  //   | 'entireData'
  | 'currentBars'
  | 'lastBarIndexes'
  | 'dateRange'
  | 'middleRange'
  | 'firstBarDate'
  | 'lastBarDate'
  | 'menuTopCollapsed'
  | 'bottomMenuCollapsed'
  | 'orders'
  | 'theme'
  | 'strategyIsActive'
  | 'points'
  | 'profit'
  | 'newsRecords'
  | 'currentProject'
  | 'activeTab'
  | 'chartTimeFrames'
  | 'dataLength'
  | 'testingSpeed'
  | 'currentTick'
>

export const convertToSavableWebTester = (webTester: WebTesterState): SavableWebTesterState => ({
  accountInfo: { ...webTester.accountInfo },
  //   visibleData: JSON.parse(JSON.stringify(webTester.visibleData)),
  //   entireData: JSON.parse(JSON.stringify(webTester.entireData)),
  currentBars: JSON.parse(JSON.stringify(webTester.currentBars)),
  lastBarIndexes: { ...webTester.lastBarIndexes },
  dateRange: [...webTester.dateRange],
  middleRange: webTester.middleRange,
  firstBarDate: webTester.firstBarDate,
  lastBarDate: webTester.lastBarDate,
  menuTopCollapsed: webTester.menuTopCollapsed,
  bottomMenuCollapsed: webTester.bottomMenuCollapsed,
  orders: JSON.parse(JSON.stringify(webTester.orders)),
  theme: webTester.theme,
  strategyIsActive: webTester.strategyIsActive,
  points: [...webTester.points],
  profit: [...webTester.profit],
  newsRecords: JSON.parse(JSON.stringify(webTester.newsRecords)),
  currentProject: webTester.currentProject ? JSON.parse(JSON.stringify(webTester.currentProject)) : undefined,
  activeTab: webTester.activeTab,
  chartTimeFrames: JSON.parse(JSON.stringify(webTester.chartTimeFrames)),
  dataLength: webTester.dataLength,
  testingSpeed: webTester.testingSpeed,
  currentTick: webTester.currentTick
})

export const applySavableWebTester = (
  webTester: WebTesterState,
  savedWebTester: SavableWebTesterState
): WebTesterState => ({
  ...webTester,
  accountInfo: { ...savedWebTester.accountInfo },
  currentBars: JSON.parse(JSON.stringify(savedWebTester.currentBars)),
  lastBarIndexes: { ...savedWebTester.lastBarIndexes },
  dateRange: [...savedWebTester.dateRange],
  middleRange: savedWebTester.middleRange,
  firstBarDate: savedWebTester.firstBarDate,
  lastBarDate: savedWebTester.lastBarDate,
  menuTopCollapsed: savedWebTester.menuTopCollapsed,
  bottomMenuCollapsed: savedWebTester.bottomMenuCollapsed,
  orders: JSON.parse(JSON.stringify(savedWebTester.orders)),
  theme: savedWebTester.theme,
  strategyIsActive: savedWebTester.strategyIsActive,
  points: [...savedWebTester.points],
  profit: [...savedWebTester.profit],
  newsRecords: JSON.parse(JSON.stringify(savedWebTester.newsRecords)),
  currentProject: savedWebTester.currentProject ? JSON.parse(JSON.stringify(savedWebTester.currentProject)) : undefined,
  activeTab: savedWebTester.activeTab,
  chartTimeFrames: JSON.parse(JSON.stringify(savedWebTester.chartTimeFrames)),
  dataLength: savedWebTester.dataLength,
  testingSpeed: savedWebTester.testingSpeed
})

export type WebTesterSlice = {
  state?: SavableWebTesterState
  isSynced: boolean
  needSave: boolean
  isSyncing: boolean
}
