import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import {
	IProjectItem,
	IProject,
	IProjectPascalCase
} from '../interfaces/IProject.interface'
import { toastOptions, defaultProject } from '../constants'
import { getBaseServerUrl } from './utils'
import {
	IMarketOrder,
	IPendingOrder,
	IAccountHistory
} from '../interfaces/IOrder.interface'
import { INote } from '../interfaces/INote.interface'
import { IChart } from '../interfaces/IChart.interface'
import {
	mapProjectForServer,
	getProjectFromLocalStorage
} from '../../components/webTester/webTesterUtils'

const apiClient = axios.create({
	baseURL: getBaseServerUrl() + 'projects/api/ProjectData',
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' }
})

export const createDefaultProject = async (): Promise<string> => {
	const response = await apiClient.post<string>('/', defaultProject)
	const projectId = response.data

	if (projectId) {
		toast.success('Default project created successfully!', toastOptions)
		return projectId
	} else {
		toast.error('Unable to create default project!', toastOptions)
		throw new Error('Unable to create default project!')
	}
}

export const getProjects = async (): Promise<IProjectItem[]> => {
	const response = await apiClient.get<IProjectItem[]>('/')
	const projects = response.data

	if (projects) return projects

	throw new Error('Unable to get projects!')
}

export const getProject = async (
	projectId: string
): Promise<IProjectPascalCase> => {
	const response = await apiClient.get<IProjectPascalCase>(`/${projectId}`)
	const project = response.data

	if (project) return project

	throw new Error('Unable to get project!')
}

export const saveProject = async (
	marketOrders: IMarketOrder[],
	pendingOrders: IPendingOrder[],
	closedOrders: IAccountHistory[],
	notes: INote[],
	charts: IChart[],
	currentProjectId: string | null
): Promise<string> => {
	const [
		MarketOrders,
		PendingOrders,
		ClosedOrders,
		Notes,
		Charts
	] = mapProjectForServer(
		marketOrders,
		pendingOrders,
		closedOrders,
		notes,
		charts
	)

	let currentProject = getProjectFromLocalStorage()

	if (currentProject && currentProject.Name) {
		currentProject = {
			// TODO: delete next 13 fields when patch request is ready
			Name: currentProject.Name,
			TimeZone: currentProject.TimeZone,
			DaylightSavingTime: currentProject.DaylightSavingTime,
			TicksGenerated: currentProject.TicksGenerated,
			UsedTickHistory: currentProject.UsedTickHistory,
			UsedFloatingSpread: currentProject.UsedFloatingSpread,
			ForwardTestingOnly: currentProject.ForwardTestingOnly,
			FromDate: currentProject.FromDate,
			ToDate: currentProject.ToDate,
			StartDate: currentProject.StartDate,
			CurrentDate: currentProject.CurrentDate,
			InitialDeposit: currentProject.InitialDeposit,
			Symbols: currentProject.Symbols,

			Balance: currentProject.Balance,
			Equity: currentProject.Equity,
			Margin: currentProject.Margin,
			FreeMargin: currentProject.FreeMargin,

			MarketOrders,
			PendingOrders,
			ClosedOrders,
			Notes,
			Charts
		}
	}

	const response = await apiClient.put<string>(
		`/${currentProjectId}`,
		currentProject
	)

	const projectId = response.data

	if (projectId) {
		toast.success('Project saved successfully!', toastOptions)
		return projectId
	} else {
		toast.error('Unable to save project!', toastOptions)
		throw new Error('Unable to save project!')
	}
}

export const deleteProject = async (
	currentProjectId: string
): Promise<string> => {
	const response = await apiClient.delete<string>(`/${currentProjectId}`)

	const projectId = response.data
	if (projectId) {
		toast.warn('Project deleted successfully!', toastOptions)
		return projectId
	} else {
		toast.error('Unable to delete project!', toastOptions)
		throw new Error('Unable to delete project!')
	}
}

export const modifyBalance = async (
	currentProjectId: string,
	balance: number
): Promise<string> => {
	const projectWithChangedBalance = {
		// TODO: copy all fields of the current project
		Balance: balance
	}
	const response = await apiClient.put<string>(
		`/${currentProjectId}`,
		projectWithChangedBalance
	)

	const projectId = response.data

	if (projectId) {
		toast.success('Balance has been changed successfully!', toastOptions)
		return projectId
	} else {
		toast.error('Error: unable to deposit/withdraw money!', toastOptions)
		throw new Error('Error: unable to deposit/withdraw money!')
	}
}
