import { scaleLinear } from 'd3-scale'
import { getVisibleBarsWidth } from '../../../../components/chartContent/chart/chartUtils/chartUtils'
import { defaultVisibleBarsCount } from '../../../../components/chartContent/chart/chartUtils/constants'
import { head, isDefined, isNotDefined, last } from '../utils'


/**
 * function to create xScale (X-axis)
 * */
export default function financeDiscontinuousScale(bars, futureProvider, backingLinearScale = scaleLinear()) {
  if (isNotDefined(bars)) throw new Error('Use the discontinuousTimeScaleProvider to create financeDiscontinuousScale')

  /**
   * Get mouse coordinate from index
   * */
  function scale(x) {
    return backingLinearScale(x)
  }
  /**
   * Get index from mouse coordinate
   * */
  scale.invert = function (x) {
    const inverted = backingLinearScale.invert(x)
    return Math.round(inverted * 10000) / 10000
  }
  /**
   * Get indexes between visible bars
   * */
  scale.domain = function (x) {
    if (!arguments.length) return backingLinearScale.domain()
    backingLinearScale.domain(x)
    return scale
  }

  /**
   * Get width to render chart
   * There is a hack to draw normal size bars if bars count < defaultVisibleBarsCount
   * */
  scale.range = function (x) {
    if (!arguments.length) return backingLinearScale.range()
    if (bars.length < defaultVisibleBarsCount) {
      const x1 = getVisibleBarsWidth(x[1], bars.length)
      backingLinearScale.range([0, x1])
    } else {
      backingLinearScale.range(x)
    }
    return scale
  }
  scale.rangeRound = function (x) {
    return backingLinearScale.range(x)
  }
  scale.clamp = function (x) {
    if (!arguments.length) return backingLinearScale.clamp()
    backingLinearScale.clamp(x)
    return scale
  }
  scale.interpolate = function (x) {
    if (!arguments.length) return backingLinearScale.interpolate()
    backingLinearScale.interpolate(x)
    return scale
  }

  /**
   * Generate ticks for scale X (visible dates)
   * */
  scale.ticks = function (defaultTicks: number, width: number): number[] {
    const [domainStart, domainEnd] = backingLinearScale.domain()

    const start: number = Math.max(Math.ceil(domainStart), head(bars).index) + Math.abs(head(bars).index)
    const end: number = Math.min(Math.floor(domainEnd), last(bars).index) + Math.abs(head(bars).index)

    const zoomRate =
      bars.length > defaultVisibleBarsCount ? defaultVisibleBarsCount / (end - start) : bars.length / (end - start)

    const widthForOneTickText: number = 150
    const desiredTickCount: number = Math.round(width / widthForOneTickText)
    const eachBarIndex: number = Math.floor(defaultVisibleBarsCount / (desiredTickCount * zoomRate))

    const ticks: number[] = []
    for (let i = start; i <= end; i++) {
      if (i % eachBarIndex === 0) {
        ticks.push(i)
      }
    }

    return ticks
  }
  scale.tickFormat = function () {
    return function (x) {
      const d = Math.abs(head(bars).index)
      const { format, date } = bars[Math.floor(x + d)]
      return format(date)
    }
  }
  scale.value = function (x) {
    const d = Math.abs(head(bars).index)
    if (isDefined(bars[Math.floor(x + d)])) {
      const { date } = bars[Math.floor(x + d)]
      return date
    }
  }
  scale.nice = function (m) {
    backingLinearScale.nice(m)
    return scale
  }
  scale.index = function (x) {
    if (!arguments.length) return bars
    bars = x
    return scale
  }
  scale.copy = function () {
    return financeDiscontinuousScale(bars, futureProvider, backingLinearScale.copy())
  }
  return scale
}
