import { IChartTools } from '../../utils/interfaces/IGraphics'
import { IIndicator, IOscillator } from '../../utils/interfaces/IIndicator.interface'
import { IHorizontalLine } from '../../utils/interfaces/ILine'

export interface ITool {
  verticalLine: boolean
  horizontalLine: boolean
  trendLine: boolean
  polyline: boolean
  ray: boolean
  rectangle: boolean
  RewardToRiskBuy: boolean
  RewardToRiskSell: boolean
  ellipse: boolean
  triangle: boolean
  fibonacciRetracement: boolean
  fibonacciTimeZones: boolean
  fibonacciFan: boolean
  fibonacciArc: boolean
  fibonacciExtension: boolean
  fibonacciChannel: boolean
  linearRegressionChannel: boolean
  standardDeviationChannel: boolean
  equidistantChannel: boolean
  andrewsPitchfork: boolean
  interactiveText: boolean
  gannFan: boolean
  interactiveYCoordinate: boolean
  thumbUp: boolean
  thumbDown: boolean
  arrowUp: boolean
  arrowDown: boolean
  stopIcon: boolean
  checkIcon: boolean
  rightPriceLabel: boolean
  leftPriceLabel: boolean
  priceLabel: boolean
}

export interface IGraphicsState {
  volumeOscillatorActive: boolean
  favoriteTools: { type: string; count: number }[]
  toolIsActive: ITool
  chartTools: { [symbolAndTimeFrame: string]: IChartTools }
  indicators: { [key: string]: IIndicator[] }
  oscillators: { [key: string]: IOscillator[] }
  selectedIndicatorOscillator: { [chartId: string]: string }
  selectedIndicatorOscillatorFullName: string
  stopOrdersLines: {
    execPrice: IHorizontalLine
    stopLoss: IHorizontalLine
    takeProfit: IHorizontalLine
  }
}

export const DEFAULT_CHART_TOOLS = {
  textList: [],
  trend_lines: [],
  rays: [],
  vertical_lines: [],
  horizontal_lines: [],
  retracements: [],
  gann_fans: [],
  equidistant_channels: [],
  standard_deviation_channels: [],
  rectangles: [],
  ellipses: [],
  triangles: [],
  icons: [],
  riskRewards: []
}
