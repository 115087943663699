import React, { createContext, FC, ReactNode, useContext, useEffect, useState, VFC } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { setPipettevalues } from '../../../redux/orders/OrdersSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store_hooks'
import { ICurrentBar } from '../../../utils/interfaces/ICurrentBar'
import { IHistoricalData } from '../../../utils/interfaces/IHistoricalData.interface'
import { IAccountInfo } from '../../../utils/interfaces/IOrder.interface'
import defaultcss from './FTOModalDefault.module.scss'

// #region common items

interface MOWErrorBlockProps {
  title: string
  shown?: boolean
}

export const MOWErrorItem: FC<MOWErrorBlockProps> = ({ title, children, shown = true }) => {
  if (!shown) return <></>
  return (
    <div className={defaultcss.MOWError}>
      <h5 className={defaultcss.MOWErrorTitle}>{title}</h5>
      <p className={defaultcss.MOWErrorContent}>{children}</p>
    </div>
  )
}

export interface OWContextType<T> {
  accountInfo: IAccountInfo | undefined
  currentBars: ICurrentBar | undefined
  visibleData: IHistoricalData

  modalIsActive: boolean
  toggleModal: () => void
  onClick: (e?: any) => void

  savedState: T
  setsavedState: (values: T) => any
}
export const getFTOModalContext = <T extends object>(): React.Context<OWContextType<T>> =>
  createContext<OWContextType<T>>({
    accountInfo: undefined,
    currentBars: undefined,
    visibleData: {},
    modalIsActive: false,
    toggleModal: () => undefined,
    onClick: () => undefined,
    savedState: {} as T, // used for saving parameters set in modals if it were closed
    setsavedState: (values: T) => undefined
  })

// #endregion

export interface FTOModalAbstractProps {
  accountInfo: IAccountInfo
  currentBars: ICurrentBar
  visibleData: IHistoricalData

  modalIsActive: boolean
  toggleModal: () => void
  questionIconModalIsActive: boolean
  dataLength: number

  onClick: (e: any) => void
  onNextStep: () => void
}

export interface ITabableOrderModalProps extends FTOModalAbstractProps {
  currentTab: FTOModalTab
}

export interface FTOModalTab {
  title: string | ReactNode
  id?: string
}

interface FTOModalProps extends FTOModalAbstractProps {
  id?: string
  title?: string | ReactNode
  tabs: FTOModalTab[]
  component: FC<ITabableOrderModalProps>
  context: React.Context<any>
  customContextFields?: { [key: string]: any }
  savedValuesDefault?: any
}

const FTOTabModalBody: FC<FTOModalProps> = ({ component: Component, context, tabs, title, ...props }) => {
  const [currentTab, setcurrentTab] = useState(tabs[0])
  const currentMOWContext = useContext(context)
  return (
    <Modal
      data-test={defaultcss.orderModal}
      isOpen={props.modalIsActive}
      toggle={currentMOWContext.toggleModal}
      fade={false}
      size='lg'
      contentClassName={defaultcss.orderModal}
      onOpened={props.onNextStep}
      centered
    >
      {/* Modal header */}
      <div className={defaultcss.orderModalHeader}>
        {title ?? 'Modal'}
        <button onClick={currentMOWContext.toggleModal}>x</button>
      </div>
      <ModalBody className={defaultcss.orderModalBody}>
        {/* Modal navigation */}
        <nav className={defaultcss.tabSelect}>
          <ul>
            {tabs.map((t, i) => (
              <li
                key={i}
                onClick={() => setcurrentTab(t)}
                className={currentTab.id == t.id ? defaultcss.selected : ''}
                id={t.id}
              >
                {t.title}
              </li>
            ))}
          </ul>
        </nav>
        <div className={defaultcss.content}>
          <Component {...{ ...props, currentTab: currentTab }} />
        </div>
      </ModalBody>
    </Modal>
  )
}

const FTOTabsModal: VFC<FTOModalProps> = ({ savedValuesDefault, id, context, customContextFields, ...props }) => {
  const [savedState, setsavedState] = useState(savedValuesDefault ?? {})
  const isEdit = useAppSelector((state) => state.orders.marketEditMode)
  const isPipette = useAppSelector((state) => state.orders.pipetteModeIsActive)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isEdit && !isPipette && !props.modalIsActive) {
      setsavedState({ ...savedValuesDefault, lot: savedState.lot } ?? {})
      dispatch(setPipettevalues({ execPrice: 0, stopLoss: 0, takeProfit: 0 }))
    }
  }, [props.modalIsActive])

  return (
    <div id={id} className={props.modalIsActive ? defaultcss.orderModal : defaultcss.orderModalHidden}>
      <context.Provider
        value={{
          accountInfo: props.accountInfo,
          currentBars: props.currentBars,
          visibleData: props.visibleData,

          modalIsActive: props.modalIsActive,
          toggleModal: props.toggleModal,
          onClick: props.onClick,
          setsavedState: (v: any) => setsavedState(v),
          savedState,
          ...customContextFields
        }}
      >
        <FTOTabModalBody savedValuesDefault={savedValuesDefault} id={id} context={context} {...props} />
      </context.Provider>
    </div>
  )
}

const FTOBlankModal: FC<Omit<FTOModalProps, 'tabs'> & { children: React.ReactNode }> = ({
  savedValuesDefault,
  id,
  context,
  customContextFields,
  title,
  ...props
}) => {
  const [savedState, setsavedState] = useState(savedValuesDefault ?? {})
  const isEdit = useAppSelector((state) => state.orders.marketEditMode)
  const isPipette = useAppSelector((state) => state.orders.pipetteModeIsActive)
  const dispatch = useAppDispatch()

  const currentMOWContext = useContext(context)

  useEffect(() => {
    if (!isEdit && !isPipette && !props.modalIsActive) {
      setsavedState({ ...savedValuesDefault, lot: savedState.lot } ?? {})
      dispatch(setPipettevalues({ execPrice: 0, stopLoss: 0, takeProfit: 0 }))
    }
  }, [props.modalIsActive])
  return (
    <context.Provider
      value={{
        accountInfo: props.accountInfo,
        currentBars: props.currentBars,
        visibleData: props.visibleData,

        modalIsActive: props.modalIsActive,
        toggleModal: props.toggleModal,
        onClick: props.onClick,
        setsavedState: (v: any) => setsavedState(v),
        savedState,
        ...customContextFields
      }}
    >
      <Modal
        modalClassName={defaultcss.blankModalWrapper}
        isOpen={props.modalIsActive}
        toggle={props.toggleModal}
        className={defaultcss.blankModal}
      >
        {title && (
          <div className={defaultcss.orderModalHeader}>
            {title ?? 'Modal'}
            <button onClick={props.toggleModal}>x</button>
          </div>
        )}
        <ModalBody contentClassName={defaultcss.blankModalContent}>{props.children}</ModalBody>
      </Modal>
    </context.Provider>
  )
}

const FTOModals = {
  Modal: FTOTabsModal,
  BlankModal: FTOBlankModal,
  modalDefaultStyle: defaultcss
}
export default FTOModals
