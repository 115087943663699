export const marketingCourse = {
  Type: 'MARKETING',
  CourseName: 'INTRO',
  Position: 0,
  Name: 'INTRO',
  OriginalLessonName: 'Intro',
  LessonId: 3690879943,
  LearningTime: '5 minutes',
  Author: 'Forex Tester Team',
  Description: '',
  Steps: [
    {
      Name: '1. You want to become a better trader',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'M5'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 13,
        JumpTo: null,
        WndOrientation: 4,
        RemoveObjects: 0,
        TickPackage: 'M5'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 310
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'So, you want to become a better trader.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 38,
                  Weight: 500
                },
                {
                  StartIndex: 38,
                  Length: 1,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 29,
                TopPoint: 26,
                Width: 385,
                Height: 35
              }
            },
            {
              Content:
                "You’ve just started learning Forex and know nothing about it?\n\nOr maybe you already have a trading strategy in mind and need to know if it's profitable?\n\nWe have you covered!",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 154,
                  Weight: 400
                },
                {
                  StartIndex: 154,
                  Length: 20,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 149,
                TopPoint: 65,
                Width: 281,
                Height: 187
              }
            }
          ],
          Buttons: [
            {
              Action: "2. Those who don't know what is backtesting",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 265,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-5.png',
              Layout: {
                LeftPoint: 17,
                TopPoint: 78,
                Width: 153,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: "2. Those who don't know what is backtesting",
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'How do you think people start learning Forex trading?',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 53,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 26,
                Width: 408,
                Height: 41
              }
            },
            {
              Content:
                'Most of the time, shortly after getting to know the basics, they jump right into a demo account, and spend weeks or months figuring out how good or bad their strategy is.\n\nThat’s a slow & risky way of learning!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 101,
                  Weight: 400
                },
                {
                  StartIndex: 101,
                  Length: 21,
                  Weight: 500
                },
                {
                  StartIndex: 122,
                  Length: 59,
                  Weight: 400
                },
                {
                  StartIndex: 181,
                  Length: 12,
                  Weight: 500
                },
                {
                  StartIndex: 193,
                  Length: 17,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 117,
                TopPoint: 62,
                Width: 313,
                Height: 186
              }
            }
          ],
          Buttons: [
            {
              Action: '3. With Forex Tester you can instantly teleport back in time',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Is there                 a better way?',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 250,
                Width: 100,
                Height: 35
              }
            },
            {
              Action: '1. You want to become a better trader',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-1.png',
              Layout: {
                LeftPoint: -9,
                TopPoint: 78,
                Width: 145,
                Height: 145
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '3. With Forex Tester you can instantly teleport back in time',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: true,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 10,
        TopPoint: 136,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'With Forex Tester, you can instantly teleport back in time and test your strategy on years of real market historical data!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 82,
                  Weight: 400
                },
                {
                  StartIndex: 82,
                  Length: 40,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 116,
                TopPoint: 29,
                Width: 313,
                Height: 112
              }
            },
            {
              Content: 'It’s like trading on a demo account, but a gazillion times more effective!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 74,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 116,
                TopPoint: 146,
                Width: 290,
                Height: 62
              }
            }
          ],
          Buttons: [
            {
              Action: "2. Those who don't know what is backtesting",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '4. Click “Start” to begin our time-travel journey!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-5.png',
              Layout: {
                LeftPoint: -7,
                TopPoint: 52,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '4. Click “Start” to begin our time-travel journey!',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 11,
        TopPoint: 136,
        Width: 400,
        Height: 240
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'Click Start to begin our time-travel journey!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 6,
                  Weight: 400
                },
                {
                  StartIndex: 6,
                  Length: 5,
                  Weight: 500
                },
                {
                  StartIndex: 11,
                  Length: 34,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 159,
                TopPoint: 65,
                Width: 210,
                Height: 92
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Beamy-4.png',
              Layout: {
                LeftPoint: 5,
                TopPoint: 39,
                Width: 142,
                Height: 142
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '5. Wake up, Neo... The simulation has begun!',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 159,
        TopPoint: 92,
        Width: 420,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'Wake up, Neo… The simulation has begun!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 39,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 72,
                TopPoint: 24,
                Width: 319,
                Height: 29
              }
            },
            {
              Content:
                'You can pause, rewind, or fast-forward the simulation when needed. All charts and timeframes are synchronized!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 67,
                  Weight: 400
                },
                {
                  StartIndex: 67,
                  Length: 43,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 72,
                TopPoint: 133,
                Width: 326,
                Height: 81
              }
            },
            {
              Content: 'Try to change the testing speed by moving the slider.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 53,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 15,
                TopPoint: 209,
                Width: 396,
                Height: 36
              }
            },
            {
              Content:
                'We are in February 2020. The price is changing just like in the live market, but you can control time! ',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 103,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 72,
                TopPoint: 63,
                Width: 326,
                Height: 66
              }
            }
          ],
          Buttons: [
            {
              Action: '6. In Forex Tester you have an interactive account with virtual money',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: '\nMainForm\n',
                SelectedOption: '1049',
                Value: ''
              },
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\NEO_TILTED_1.png',
              Layout: {
                LeftPoint: -71,
                TopPoint: 45,
                Width: 140,
                Height: 160
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 8,
        FtControlType: 1,
        ControlId: 31,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '6. In Forex Tester you have an interactive account with virtual money',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'H1'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: '2020-02-04 17:00',
        WndOrientation: 1,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 350
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'In Forex Tester, you have an interactive account with virtual money. You can test every idea & strategy in a realistic but safe environment with no risk to funds!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 29,
                  Weight: 400
                },
                {
                  StartIndex: 29,
                  Length: 38,
                  Weight: 500
                },
                {
                  StartIndex: 67,
                  Length: 95,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 20,
                TopPoint: 26,
                Width: 398,
                Height: 74
              }
            },
            {
              Content:
                'You are able to test & practice hundreds of times faster than on a demo account. It’s like comparing a private jet to a tortoise!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 15,
                  Weight: 400
                },
                {
                  StartIndex: 15,
                  Length: 1,
                  Weight: 500
                },
                {
                  StartIndex: 16,
                  Length: 16,
                  Weight: 400
                },
                {
                  StartIndex: 32,
                  Length: 47,
                  Weight: 500
                },
                {
                  StartIndex: 79,
                  Length: 50,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 20,
                TopPoint: 218,
                Width: 412,
                Height: 86
              }
            }
          ],
          Buttons: [
            {
              Action: '7. There are lots of trading tools to help you',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 305,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Jet.png',
              Layout: {
                LeftPoint: 25,
                TopPoint: 118,
                Width: 400,
                Height: 89
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 5,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid'
      }
    },
    {
      Name: '7. There are lots of trading tools to help you',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 481,
        TopPoint: 49,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'There are lots of trading tools to help you boost your progress!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 64,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 23,
                Width: 399,
                Height: 55
              }
            },
            {
              Content: 'A key one is Indicators (trend and volume indicators, oscillators, etc.).',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 13,
                  Weight: 400
                },
                {
                  StartIndex: 13,
                  Length: 10,
                  Weight: 500
                },
                {
                  StartIndex: 23,
                  Length: 50,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 77,
                Width: 400,
                Height: 55
              }
            },
            {
              Content: 'You can find the List of indicators in the ribbon menu.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 17,
                  Weight: 400
                },
                {
                  StartIndex: 17,
                  Length: 18,
                  Weight: 500
                },
                {
                  StartIndex: 35,
                  Length: 20,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 132,
                Width: 401,
                Height: 36
              }
            }
          ],
          Buttons: [
            {
              Action: '8. Graphic elements will help you to mark interesting spots',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: '\nChartWindow_1\n',
                SelectedOption: '1049',
                Value: ''
              },
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '6. In Forex Tester you have an interactive account with virtual money',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\indicators_2_1.png',
              Layout: {
                LeftPoint: 6,
                TopPoint: 161,
                Width: 438,
                Height: 90
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 0,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 1,
        GroupId: 8,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '8. Graphic elements will help you to mark interesting spots',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 400,
        TopPoint: 49,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Graphic elements will help you to mark interesting spots on the chart, and we are going to draw one!\n\nImagine that we have some strategy based on support and resistance levels & lines, and we want to test it.\n\nLet’s start by drawing a support level across the last two lows of EURUSD.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 16,
                  Weight: 500
                },
                {
                  StartIndex: 16,
                  Length: 194,
                  Weight: 400
                },
                {
                  StartIndex: 210,
                  Length: 38,
                  Weight: 500
                },
                {
                  StartIndex: 248,
                  Length: 36,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 29,
                TopPoint: 26,
                Width: 390,
                Height: 209
              }
            }
          ],
          Buttons: [
            {
              Action: '9. Here we are! We are already on the',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Draw a Ray',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '7. There are lots of trading tools to help you',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 0,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 1,
        GroupId: 7,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '9. Here we are! We are already on the',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'Here we are! We just drew our support level.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 30,
                  Weight: 400
                },
                {
                  StartIndex: 30,
                  Length: 14,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 18,
                Width: 388,
                Height: 60
              }
            },
            {
              Content:
                'The price has bounced off this level two times already.\n\nLet’s wait until it happens one more time and look for a point to buy.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 57,
                  Weight: 400
                },
                {
                  StartIndex: 57,
                  Length: 42,
                  Weight: 500
                },
                {
                  StartIndex: 99,
                  Length: 21,
                  Weight: 400
                },
                {
                  StartIndex: 120,
                  Length: 7,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 81,
                Width: 304,
                Height: 126
              }
            }
          ],
          Buttons: [
            {
              Action: '10. Resume the testing so we can see if our trend prediction is correct',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-2.png',
              Layout: {
                LeftPoint: 310,
                TopPoint: 70,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.10356,
                    DateStart: '2020-02-03T15:00:00+00:00',
                    PriceEnd: 1.10356,
                    DateEnd: '2020-02-04T15:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-03T18:00:00+00:00',
                    Price: 1.10356,
                    Text: 'SUPPORT LEVEL is here',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-03T15:00:00+00:00',
                    Price: 1.10356,
                    Text: '1',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-04T16:00:00+00:00',
                    Price: 1.10356,
                    Text: '2',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow'
      }
    },
    {
      Name: '10. Resume the testing so we can see if our trend prediction is correct',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 10,
        TopPoint: 136,
        Width: 450,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Resume testing to check if our prediction is correct.\n\nIf it bounces off the support level for the third time, we will buy while the price is still low.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 6,
                  Weight: 500
                },
                {
                  StartIndex: 6,
                  Length: 49,
                  Weight: 400
                },
                {
                  StartIndex: 55,
                  Length: 67,
                  Weight: 500
                },
                {
                  StartIndex: 122,
                  Length: 30,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 143,
                TopPoint: 42,
                Width: 281,
                Height: 140
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-4.png',
              Layout: {
                LeftPoint: 18,
                TopPoint: 47,
                Width: 130,
                Height: 130
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 3,
        RibbonTabId: 1,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '11. Now eyes on the chart',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 13,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 400,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'Now eyes on the chart',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 21,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 38,
                TopPoint: 72,
                Width: 191,
                Height: 92
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-4.png',
              Layout: {
                LeftPoint: 223,
                TopPoint: 43,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 9,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '12. Have you seen it? The price hit our support level again!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: '2020-02-05 05:55',
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '12. Have you seen it? The price hit our support level again!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 440,
        Height: 320
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                "Have you seen it? The price has just bounced!\n\nIt's time to open a market order assuming that the price is going to bounce off the support level once again.",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 80,
                  Weight: 400
                },
                {
                  StartIndex: 80,
                  Length: 76,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 23,
                TopPoint: 14,
                Width: 405,
                Height: 121
              }
            },
            {
              Content:
                'Let’s place a Buy order with:\n  ‣ Standard lot size\n  ‣ Stop Loss below the support level\n  ‣ Take Profit at the previous high',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 14,
                  Weight: 400
                },
                {
                  StartIndex: 14,
                  Length: 9,
                  Weight: 500
                },
                {
                  StartIndex: 23,
                  Length: 103,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 141,
                TopPoint: 143,
                Width: 282,
                Height: 110
              }
            }
          ],
          Buttons: [
            {
              Action: '13. Congratulations! We just have opened a new market order.',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Open BUY order',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 315,
                TopPoint: 275,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-3-market-order.png',
              Layout: {
                LeftPoint: -1,
                TopPoint: 131,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T00:00:00+00:00',
                    Price: 1.1031,
                    Text: 'It bounced!',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '13. Congratulations! We just have opened a new market order.',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 425,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Congratulations! We have just opened a market order.\n\nNow let’s resume testing again to see where the price goes.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 17,
                  Weight: 400
                },
                {
                  StartIndex: 17,
                  Length: 35,
                  Weight: 500
                },
                {
                  StartIndex: 52,
                  Length: 6,
                  Weight: 400
                },
                {
                  StartIndex: 58,
                  Length: 20,
                  Weight: 500
                },
                {
                  StartIndex: 78,
                  Length: 35,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 159,
                TopPoint: 34,
                Width: 243,
                Height: 156
              }
            }
          ],
          Buttons: [
            {
              Action: "14. Do you think we've made the right choice?",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Resume testing',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 300,
                TopPoint: 205,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-5.png',
              Layout: {
                LeftPoint: 13,
                TopPoint: 37,
                Width: 153,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T03:00:00+00:00',
                    Price: 1.10435,
                    Text: 'We have opened\na BUY ORDER here',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 5,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [
                  {
                    OrderType: 'BUY',
                    Symbol: 'EURUSD',
                    Lot: 1,
                    StopLoss: 1.1032,
                    TakeProfit: 1.1061,
                    PriceValidationSettings: null
                  }
                ],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: "14. Do you think we've made the right choice?",
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 10,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 430,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: "Do you think we've made the right choice?\n\nLet's see!",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 53,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 148,
                TopPoint: 61,
                Width: 265,
                Height: 117
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-1.png',
              Layout: {
                LeftPoint: 1,
                TopPoint: 40,
                Width: 150,
                Height: 151
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 1,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '15. Oops! Seems like it wasn’t the best trade. But that’s okay!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_2'
      }
    },
    {
      Name: '15. Oops! Seems like it wasn’t the best trade. But that’s okay!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 6,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'H1'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Oops! Seems like it wasn’t the best trade. But that’s okay!\n\nIt wasn’t your real money, and you have an opportunity to learn from your mistakes.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 61,
                  Weight: 400
                },
                {
                  StartIndex: 61,
                  Length: 26,
                  Weight: 500
                },
                {
                  StartIndex: 87,
                  Length: 57,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 127,
                TopPoint: 24,
                Width: 303,
                Height: 148
              }
            },
            {
              Content:
                'To start making money on Forex, you have to learn how not to lose them first. \nMoney saved is money earned!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 79,
                  Weight: 400
                },
                {
                  StartIndex: 79,
                  Length: 28,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 34,
                TopPoint: 175,
                Width: 392,
                Height: 75
              }
            }
          ],
          Buttons: [
            {
              Action: '16. Another great feature is the ability to work with multiple charts at a time',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-7.png',
              Layout: {
                LeftPoint: 0,
                TopPoint: 24,
                Width: 150,
                Height: 151
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 5,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.bDockSite.dxContainer.pAccountHistory.HistoryPanel.table.grid'
      }
    },
    {
      Name: '16. Another great feature is the ability to work with multiple charts at a time',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 149,
        TopPoint: 145,
        Width: 450,
        Height: 270
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Another great feature is the ability to work with multiple charts at a time.\n\nTo have a look at the bigger picture, let’s open 2 charts simultaneously. The current EURUSD H1 and EURUSD H4.\n\nTo do that, please, click Tile Vertically.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 29,
                  Weight: 400
                },
                {
                  StartIndex: 29,
                  Length: 47,
                  Weight: 500
                },
                {
                  StartIndex: 76,
                  Length: 88,
                  Weight: 400
                },
                {
                  StartIndex: 164,
                  Length: 9,
                  Weight: 500
                },
                {
                  StartIndex: 173,
                  Length: 5,
                  Weight: 400
                },
                {
                  StartIndex: 178,
                  Length: 9,
                  Weight: 500
                },
                {
                  StartIndex: 187,
                  Length: 29,
                  Weight: 400
                },
                {
                  StartIndex: 216,
                  Length: 16,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 120,
                TopPoint: 21,
                Width: 316,
                Height: 201
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-5.png',
              Layout: {
                LeftPoint: -13,
                TopPoint: 46,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 1,
        ControlId: 54,
        RibbonTabId: 5,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '17. On a daily timeframe, we see our mistake — it is a downward movement',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 1,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 430,
        Height: 280
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'On the H4 time frame, we can see our mistake. The new trend line, which we’ve just drawn on both charts instead of the previous one, shows us that it is obviously a downtrend.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 7,
                  Weight: 400
                },
                {
                  StartIndex: 7,
                  Length: 13,
                  Weight: 500
                },
                {
                  StartIndex: 20,
                  Length: 145,
                  Weight: 400
                },
                {
                  StartIndex: 165,
                  Length: 10,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 118,
                TopPoint: 17,
                Width: 291,
                Height: 133
              }
            },
            {
              Content:
                'In the next step we will go a few days further into the future and draw a more precise downtrend line there.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 17,
                  Weight: 400
                },
                {
                  StartIndex: 17,
                  Length: 46,
                  Weight: 500
                },
                {
                  StartIndex: 63,
                  Length: 4,
                  Weight: 400
                },
                {
                  StartIndex: 67,
                  Length: 40,
                  Weight: 500
                },
                {
                  StartIndex: 107,
                  Length: 1,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 118,
                TopPoint: 150,
                Width: 279,
                Height: 78
              }
            }
          ],
          Buttons: [
            {
              Action: '18. Great! We have corrected our downtrend line.',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: {
                Type: 5,
                FtControlName: '\nChartWindow_1\n',
                SelectedOption: '1049',
                Value: ''
              },
              Content: 'Draw it!',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 325,
                TopPoint: 235,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-3.png',
              Layout: {
                LeftPoint: -11,
                TopPoint: 58,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T00:00:00+00:00',
                    Price: 1.105,
                    Text: 'Downtrend',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.1089,
                    DateStart: '2020-02-03T00:00:00+00:00',
                    PriceEnd: 1.10449,
                    DateEnd: '2020-02-05T00:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow'
      }
    },
    {
      Name: '18. Great! We have corrected our downtrend line.',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'EURUSD',
        ChartTimeFrame: 'H1'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: '2020-02-06 15:00',
        WndOrientation: 0,
        RemoveObjects: 1,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 270
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Great! We’ve drawn a more precise downtrend line through the last two highs on both charts. Seems like the price should go along this trend now.\n\nIf it bounces down from this downtrend line one more time, we will open a sell order.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 146,
                  Weight: 400
                },
                {
                  StartIndex: 146,
                  Length: 85,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 117,
                TopPoint: 30,
                Width: 317,
                Height: 184
              }
            }
          ],
          Buttons: [
            {
              Action: "19. Let's watch",
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Resume testing',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 325,
                TopPoint: 225,
                Width: 110,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-2.png',
              Layout: {
                LeftPoint: -4,
                TopPoint: 52,
                Width: 140,
                Height: 140
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 9,
                    TimeFrames: [60, 240],
                    PriceStart: 1.10479,
                    DateStart: '2020-02-05T08:00:00+00:00',
                    PriceEnd: 1.10138,
                    DateEnd: '2020-02-06T08:00:00+00:00',
                    DateTime: null,
                    Price: 0,
                    Text: '',
                    TextAlignment: 0,
                    PivotPoint: 0
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-05T23:00:00+00:00',
                    Price: 1.1025,
                    Text: 'More precise\ndowntrend',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: "19. Let's watch",
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 14,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 350,
        Height: 250
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: "Let's watch!",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 12,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 185,
                TopPoint: 70,
                Width: 120,
                Height: 96
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-6.png',
              Layout: {
                LeftPoint: 29,
                TopPoint: 38,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 9,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '20. It bounced! That means it’s time to SELL!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: '2020-02-07 07:55',
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '20. It bounced! That means it’s time to SELL!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'It bounced! This means it’s time to SELL!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 36,
                  Weight: 400
                },
                {
                  StartIndex: 36,
                  Length: 4,
                  Weight: 500
                },
                {
                  StartIndex: 40,
                  Length: 1,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 28,
                Width: 313,
                Height: 30
              }
            },
            {
              Content: "We're going to open one more market order, but this time we are selling.",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 72,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 24,
                TopPoint: 58,
                Width: 405,
                Height: 65
              }
            },
            {
              Content:
                "Let's place a sell order with:\n  ‣ The same standard lot\n  ‣ Stop Loss above the downtrend line\n     and previous high\n  ‣ Take Profit two times bigger than SL",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 13,
                  Weight: 400
                },
                {
                  StartIndex: 13,
                  Length: 11,
                  Weight: 500
                },
                {
                  StartIndex: 24,
                  Length: 135,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 140,
                TopPoint: 118,
                Width: 292,
                Height: 124
              }
            }
          ],
          Buttons: [
            {
              Action: '21. This time it must work out!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Open SELL order',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 255,
                Width: 100,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Gonn Abigood-5-3.png',
              Layout: {
                LeftPoint: 14,
                TopPoint: 122,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-07T07:00:00+00:00',
                    Price: 1.0984,
                    Text: 'It bounced!',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '21. This time it must work out!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: true,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 400,
        Height: 240
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: "This time it must work out!\nShouldn't it?",
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 41,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 170,
                TopPoint: 80,
                Width: 201,
                Height: 79
              }
            }
          ],
          Buttons: [],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Ozzy-1.png',
              Layout: {
                LeftPoint: 21,
                TopPoint: 39,
                Width: 150,
                Height: 151
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 1,
            Action: {
              Type: 0,
              Properties: {
                NextStepName: '22. Hurray!',
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          },
          {
            Type: 8,
            Action: {
              Type: 5,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [
                  {
                    OrderType: 'SELL',
                    Symbol: 'EURUSD',
                    Lot: 1,
                    StopLoss: 1.09882,
                    TakeProfit: 1.0922,
                    PriceValidationSettings: null
                  }
                ],
                GraphicalTools: [],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '22. Hurray!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: true,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 460,
        Height: 300
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'Hurray! We’ve just automatically closed a profitable order with Take Profit!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 7,
                  Weight: 500
                },
                {
                  StartIndex: 7,
                  Length: 69,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 17,
                TopPoint: 24,
                Width: 429,
                Height: 54
              }
            },
            {
              Content:
                'It was opened on Feb 7 and closed on Feb 10. If it was a demo account, you would have had to wait for about 80 hours for an order like    this to close.\n\nWith Forex Tester, it’s done in a few seconds! Sonic speed is now your superpower!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 17,
                  Weight: 400
                },
                {
                  StartIndex: 17,
                  Length: 5,
                  Weight: 500
                },
                {
                  StartIndex: 22,
                  Length: 15,
                  Weight: 400
                },
                {
                  StartIndex: 37,
                  Length: 6,
                  Weight: 500
                },
                {
                  StartIndex: 43,
                  Length: 59,
                  Weight: 400
                },
                {
                  StartIndex: 102,
                  Length: 14,
                  Weight: 500
                },
                {
                  StartIndex: 116,
                  Length: 38,
                  Weight: 400
                },
                {
                  StartIndex: 154,
                  Length: 47,
                  Weight: 500
                },
                {
                  StartIndex: 201,
                  Length: 35,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 17,
                TopPoint: 75,
                Width: 335,
                Height: 167
              }
            }
          ],
          Buttons: [
            {
              Action: '23. Statistics are collected automatically!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 255,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Брат-1-3.png',
              Layout: {
                LeftPoint: 321,
                TopPoint: 88,
                Width: 135,
                Height: 135
              }
            }
          ]
        },
        StepActions: [
          {
            Type: 8,
            Action: {
              Type: 4,
              Properties: {
                NextStepName: null,
                TabName: null,
                Control: null,
                Orders: [],
                GraphicalTools: [
                  {
                    PropertyType: 13,
                    TimeFrames: [60, 240],
                    PriceStart: 0,
                    DateStart: null,
                    PriceEnd: 0,
                    DateEnd: null,
                    DateTime: '2020-02-10T16:00:00+00:00',
                    Price: 1.09325,
                    Text: 'Congrats!\nYou’ve just\nmade $447!',
                    TextAlignment: 1,
                    PivotPoint: 1
                  }
                ],
                WaitFor: null,
                ValidateNumberRules: null
              }
            }
          }
        ]
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 15,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'ChartWindow_1'
      }
    },
    {
      Name: '23. Statistics are collected automatically!',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 330
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content: 'What will also help you to progress as a better trader is Statistics.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 58,
                  Weight: 400
                },
                {
                  StartIndex: 58,
                  Length: 10,
                  Weight: 500
                },
                {
                  StartIndex: 68,
                  Length: 1,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 19,
                TopPoint: 27,
                Width: 413,
                Height: 47
              }
            },
            {
              Content:
                'The statistics on your performance are displayed during the test and after the testing is finished, so that you can analyze the most important metrics, anytime.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 116,
                  Weight: 400
                },
                {
                  StartIndex: 116,
                  Length: 44,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 19,
                TopPoint: 79,
                Width: 300,
                Height: 150
              }
            },
            {
              Content: 'Everything is collected automatically! No hassle!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 49,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 19,
                TopPoint: 225,
                Width: 415,
                Height: 45
              }
            }
          ],
          Buttons: [
            {
              Action:
                '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 345,
                TopPoint: 285,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '22. Hurray!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 285,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Smart Martin-1.png',
              Layout: {
                LeftPoint: 299,
                TopPoint: 73,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 2,
        WindowAlignment: 1,
        FtControlType: 3,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: 'MainForm.lDockSite.xxx111.dxTabContainerDockSite2.pStatistics.StatsPanel.table.grid'
      }
    },
    {
      Name: '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 460,
        Height: 360
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'It’s just one example of testing a strategy based on graphical analysis amongst the many other options available.',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 113,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 23,
                Width: 417,
                Height: 67
              }
            },
            {
              Content:
                'Those include:\n  ‣ Testing based on indicators, Japanese candlesticks,\n     Fibonacci levels, etc.\n  ‣ Testing long-, medium- and short-term strategies\n  ‣ Manual and automated testing\n  ‣ AI-based Strategy Optimizer\n  ‣ Risk / Reward tool',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 35,
                  Weight: 400
                },
                {
                  StartIndex: 35,
                  Length: 58,
                  Weight: 500
                },
                {
                  StartIndex: 93,
                  Length: 18,
                  Weight: 400
                },
                {
                  StartIndex: 111,
                  Length: 15,
                  Weight: 500
                },
                {
                  StartIndex: 126,
                  Length: 3,
                  Weight: 400
                },
                {
                  StartIndex: 129,
                  Length: 12,
                  Weight: 500
                },
                {
                  StartIndex: 141,
                  Length: 15,
                  Weight: 400
                },
                {
                  StartIndex: 156,
                  Length: 7,
                  Weight: 500
                },
                {
                  StartIndex: 163,
                  Length: 3,
                  Weight: 400
                },
                {
                  StartIndex: 166,
                  Length: 10,
                  Weight: 500
                },
                {
                  StartIndex: 176,
                  Length: 13,
                  Weight: 400
                },
                {
                  StartIndex: 189,
                  Length: 31,
                  Weight: 500
                },
                {
                  StartIndex: 220,
                  Length: 1,
                  Weight: 400
                },
                {
                  StartIndex: 221,
                  Length: 18,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 94,
                Width: 416,
                Height: 157
              }
            },
            {
              Content: 'And many more tools & features to help you level up your trading skills faster!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 79,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 22,
                TopPoint: 252,
                Width: 419,
                Height: 55
              }
            }
          ],
          Buttons: [
            {
              Action: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            },
            {
              Action: '23. Statistics are collected automatically!',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 315,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 4,
        FtControlType: 0,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
      MouseEnabledInActiveRect: false,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: true,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 96,
        TopPoint: 149,
        Width: 460,
        Height: 320
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Forex Tester allows you not only to back test your trading ideas but also to learn Forex right in the software using the built-in interactive courses!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 121,
                  Weight: 400
                },
                {
                  StartIndex: 121,
                  Length: 29,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 18,
                TopPoint: 26,
                Width: 416,
                Height: 71
              }
            },
            {
              Content:
                'Learn and immediately practice the acquired knowledge, all in one place. \n\nThis provides the highest rate of information retention (75%) compared to other learning methods (lectures – 5%, reading – 10%, discussion – 50%).',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 30,
                  Weight: 500
                },
                {
                  StartIndex: 30,
                  Length: 25,
                  Weight: 400
                },
                {
                  StartIndex: 55,
                  Length: 16,
                  Weight: 500
                },
                {
                  StartIndex: 71,
                  Length: 18,
                  Weight: 400
                },
                {
                  StartIndex: 89,
                  Length: 47,
                  Weight: 500
                },
                {
                  StartIndex: 136,
                  Length: 85,
                  Weight: 400
                }
              ],
              Layout: {
                LeftPoint: 18,
                TopPoint: 101,
                Width: 335,
                Height: 166
              }
            }
          ],
          Buttons: [
            {
              Action: '26. Time & money are your most valuable resources.',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Next',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 355,
                TopPoint: 275,
                Width: 90,
                Height: 30
              }
            },
            {
              Action:
                '24. It’s just one example of strategy testing based on graphical analysis amongst many other options available',
              WebLink: null,
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 275,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: [
            {
              ImagePath: 'Education\\TutorialResources\\Sir Bernard Ullish-1.png',
              Layout: {
                LeftPoint: 319,
                TopPoint: 99,
                Width: 150,
                Height: 150
              }
            }
          ]
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 1,
        WindowAlignment: 3,
        FtControlType: 12,
        ControlId: 0,
        RibbonTabId: 7,
        GroupId: 35,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    },
    {
      Name: '26. Time & money are your most valuable resources.',
      MouseEnabledInActiveRect: true,
      ChartsSettings: {
        ActiveChart: 'Any',
        ChartTimeFrame: 'Any'
      },
      TestingSettings: {
        RestartProject: false,
        StartTestingAfterRestart: false,
        PauseTesting: false,
        ResumeTesting: false,
        UserMustStartTesting: false,
        TestingSpeed: 0,
        JumpTo: null,
        WndOrientation: 0,
        RemoveObjects: 0,
        TickPackage: 'Any'
      },
      ShouldGenerateAuxiliaryStep: false,
      RefineRect: 0,
      WindowLayout: {
        LeftPoint: 0,
        TopPoint: 0,
        Width: 450,
        Height: 450
      },
      StepInfo: {
        StepContent: {
          Texts: [
            {
              Content:
                'Time & money are your most valuable resources.\nForex Tester saves you both and helps you to find a profitable trading strategy!\n\n‣ 1 evening of testing in Forex Tester = 6 months of trading on a demo account!\n\n‣ You can test your trading strategies anytime, even when the market is closed on weekends!\n\n‣ Interactive Forex Courses are built right into the software and available in the free version!',
              Alignment: 0,
              Ranges: [
                {
                  StartIndex: 0,
                  Length: 46,
                  Weight: 500
                },
                {
                  StartIndex: 46,
                  Length: 85,
                  Weight: 400
                },
                {
                  StartIndex: 131,
                  Length: 9,
                  Weight: 500
                },
                {
                  StartIndex: 140,
                  Length: 30,
                  Weight: 400
                },
                {
                  StartIndex: 170,
                  Length: 8,
                  Weight: 500
                },
                {
                  StartIndex: 178,
                  Length: 42,
                  Weight: 400
                },
                {
                  StartIndex: 220,
                  Length: 36,
                  Weight: 500
                },
                {
                  StartIndex: 256,
                  Length: 49,
                  Weight: 400
                },
                {
                  StartIndex: 305,
                  Length: 25,
                  Weight: 500
                },
                {
                  StartIndex: 330,
                  Length: 39,
                  Weight: 400
                },
                {
                  StartIndex: 369,
                  Length: 30,
                  Weight: 500
                }
              ],
              Layout: {
                LeftPoint: 32,
                TopPoint: 31,
                Width: 387,
                Height: 275
              }
            }
          ],
          Buttons: [
            {
              Action: null,
              WebLink: 'www.forextester.com/download?from_fto=intro',
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Download desktop trial',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 235,
                TopPoint: 325,
                Width: 190,
                Height: 40
              }
            },
            {
              Action: '--> Next Lesson',
              WebLink: null,
              ActiveButtonColor: '1262cf',
              DefaultButtonColor: '8bc2ec',
              ActiveTextColor: 'ffffff',
              DefaultTextColor: 'ffffff',
              EnableButtonRule: null,
              Content: 'Finish',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 335,
                TopPoint: 405,
                Width: 100,
                Height: 30
              }
            },
            {
              Action: null,
              WebLink: 'www.forextester.com/order?from_fto=intro',
              ActiveButtonColor: null,
              DefaultButtonColor: null,
              ActiveTextColor: null,
              DefaultTextColor: null,
              EnableButtonRule: null,
              Content: 'Buy Forex Tester',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 25,
                TopPoint: 325,
                Width: 190,
                Height: 40
              }
            },
            {
              Action: '25. Forex Tester allows you not only to backtest your trading ideas but also to learn Forex',
              WebLink: null,
              ActiveButtonColor: '1262cf',
              DefaultButtonColor: '8bc2ec',
              ActiveTextColor: 'ffffff',
              DefaultTextColor: 'ffffff',
              EnableButtonRule: null,
              Content: 'Back',
              Alignment: 0,
              Ranges: null,
              Layout: {
                LeftPoint: 15,
                TopPoint: 405,
                Width: 90,
                Height: 30
              }
            }
          ],
          Images: []
        },
        StepActions: null
      },
      TutorialWindow: {
        WindowAlignmentType: 0,
        WindowAlignment: 3,
        FtControlType: 0,
        ControlId: 0,
        RibbonTabId: 0,
        GroupId: 0,
        IndicatorName: null,
        MenuItemId: 0,
        FtControlName: null
      }
    }
  ]
}
