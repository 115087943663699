import moment from 'moment'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store/store'
import { assignAllGraphics } from '../graphics/GraphicsSlice'
import { assignAllGraphs } from '../graphs/actions'
import { assignAllOrders } from '../orders/OrdersSlice'
import { assignAllPanels } from '../panels/panelsSlice'
import { assignAllTesting } from '../testing/reducer'
import { assignAllWindows } from '../windows/WindowsSlice'
import { copyWebTesterToSlice, toggleIsWebTesterSynced, toggleWebTesterNeedSave } from '../webTester/webTesterSlice'
import {
  AutoSave,
  AutoSaveData,
  ProjectAspectsLoadings,
  SavesState,
  SaveStatus,
  StorageType
} from './types'
import { AutoSaveTag, isAutoSave, storages, validateAutosave } from './utils'

const initialState: SavesState = {
  save: null,
  storageType: 'localStorage',
  clean: false,
  status: 'none',
  projectAspectsLoadings: {
    graphics: "none",
    orders: "none",
    windows: "none",
    panels: "none",
    webTester: "none",
    graphs: "none",
    testing: "none"
  }
}

const runProjectWithSave = createAsyncThunk<void, () => void>(
  'saves/runProject',
  async (callback, api) => {
    await api.dispatch(loadSaveFromStorage())
    callback()
  }
)

/**
 * method to manually save project to selected store
 */
const saveProject = createAsyncThunk<void>('save/saveProject', (_, api) => {
  let state = api.getState() as RootState

  const { graphics, orders, windows, panels, graphs, webTesterRedux, testing } = state
  if (state.telemetry.ftoRunMode != 'default_DESKTOP' || webTesterRedux.needSave) return

  api.dispatch(toggleWebTesterNeedSave())

  api.dispatch(SaveActions.setSaveStatus('saving'))
  api.dispatch(
    saveSlice.actions.saveToStorage({ orders, graphics, windows, panels, graphs, webTester: webTesterRedux, testing })
  )
  setTimeout(() => {
    api.dispatch(SaveActions.setSaveStatus('none'))
  }, 1000)
})

const runWebTesterAutoSaveLoop = createAsyncThunk<void, { secBetweenSaves: number } | undefined>(
  'save/autoSaveWebTester',
  (args, api) => {
    setInterval(async () => {
      api.dispatch(toggleWebTesterNeedSave())
    }, (args?.secBetweenSaves !== undefined ? args.secBetweenSaves : 60) * 1000)
  }
)
/**
 * will run loop that creates auto save every *secBetweenSaves* secs
 */
const runGlobalAutoSaveLoop = createAsyncThunk<void, { secBetweenSaves: number } | undefined>(
  'saves/autoSaveGlobal',
  (args, api) => {
    setInterval(async () => {
      api.dispatch(saveProject())
    }, (args?.secBetweenSaves !== undefined ? args.secBetweenSaves : 60) * 1000)
  }
)

const runAutoSaveLoop = createAsyncThunk<void, { secBetweenSaves: number } | undefined>(
  'saves/autoSave',
  (args, api) => {
    api.dispatch(runWebTesterAutoSaveLoop({ secBetweenSaves: (args?.secBetweenSaves ?? 60) - 5 }))
    api.dispatch(runGlobalAutoSaveLoop({ secBetweenSaves: args?.secBetweenSaves ?? 60 }))
  }
)

/**
 * loads save from selected source to redux
 */
const loadSaveFromStorage = createAsyncThunk<void>('saves/load', (_, api) => {
  const state = api.getState() as RootState
  const storage = storages[state.saves.storageType]

  const storedSave = storage.getItem(AutoSaveTag)

  if (storedSave !== null) {
    const parsedSave = JSON.parse(storedSave)

    const { isArraySaves, save } = validateAutosave(parsedSave)

    if (isArraySaves) {
      const encoded = JSON.stringify(save)

      storage.setItem(AutoSaveTag, encoded)
    }

    api.dispatch(saveSlice.actions.setSave(save))
  }
})

/**
 * apply selected autoSave to the current environment
 */
const applySave = createAsyncThunk<void, AutoSave>('saves/apply', (autoSave, api) => {
  if (!isAutoSave(autoSave)) throw new Error('invalid save')
  const { data } = autoSave
  let state = api.getState() as RootState
  if (state.telemetry.ftoRunMode != 'default_DESKTOP') return

  api.dispatch(
    SaveActions.setLoadingAspects({
      graphics: 'loading',
      orders: 'loading',
      windows: 'loading',
      panels: 'loading',
      webTester: 'loading',
      graphs: 'loading',
      testing: 'loading'
    })
  )
  // #region loading graphics
  state = api.getState() as RootState
  if (data.graphics) api.dispatch(assignAllGraphics(data.graphics))
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, graphics: 'loaded' }))
  // #endregion
  // #region loading orders
  state = api.getState() as RootState
  if (data.orders) api.dispatch(assignAllOrders(data.orders))
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, orders: 'loaded' }))
  // #endregion
  // #region loading windows
  state = api.getState() as RootState
  if (data.windows) api.dispatch(assignAllWindows(data.windows))
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, windows: 'loaded' }))
  // #endregion
  // #region loading panels
  state = api.getState() as RootState
  if (data.panels) api.dispatch(assignAllPanels(data.panels))
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, panels: 'loaded' }))
  // #endregion
  // #region loading webTester
  state = api.getState() as RootState
  if (data.webTester.state) {
    api.dispatch(copyWebTesterToSlice({ state: data.webTester.state }))
    api.dispatch(toggleIsWebTesterSynced())
  }
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, webTester: 'loaded' }))
  // #endregion
  // #region loading graphs
  state = api.getState() as RootState
  if (data.graphs) api.dispatch(assignAllGraphs(data.graphs))
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, graphs: 'loaded' }))
  // #endregion
  // #region loading graphs
  state = api.getState() as RootState
  if (data.testing) api.dispatch(assignAllTesting(data.testing))
  api.dispatch(SaveActions.setLoadingAspects({ ...state.saves.projectAspectsLoadings, testing: 'loaded' }))
  // #endregion
  state.graphics.chartTools
})

const saveSlice = createSlice({
  name: 'saves',
  initialState: initialState,
  reducers: {
    clearSave: (state) => {
      const storage = storages[state.storageType]
      storage.removeItem(AutoSaveTag)
      state.save = null
      state.clean = true
    },
    setSave: (state, action: PayloadAction<AutoSave>) => {
      state.save = action.payload
    },
    setSaveStatus: (state, action: PayloadAction<SaveStatus>) => {
      state.status = action.payload
    },
    setStorageType: (state, action: PayloadAction<StorageType>) => {
      state.storageType = action.payload
    },
    saveToStorage: (state, action: PayloadAction<AutoSaveData>) => {
      const storage = storages[state.storageType]

      const now = moment()

      const newSave: AutoSave = {
        id: `save_${now.format('YYYY-MM-DD-HH:mm')}`,
        date: now.toDate(),
        data: action.payload
      }

      const encoded = JSON.stringify(newSave)

      storage.setItem(AutoSaveTag, encoded)
    },
    setLoadingAspects: (state, action: PayloadAction<ProjectAspectsLoadings>) => {
      state.projectAspectsLoadings = action.payload
    }
  }
})

export type ISaveSlice = typeof initialState
export const SaveActions = { ...saveSlice.actions, runProjectWithSave, saveProject, runAutoSaveLoop, applySave }
export default saveSlice.reducer
