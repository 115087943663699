// import { rebind } from '../utils'
// import baseIndicator from './baseIndicator'

// export default function() {
// 	const base = baseIndicator().accessor(d => d.sma)

// 	var underlyingAlgorithm = function(data) {
// 		return data
// 	}

// 	var indicator = function indicator(data) {
// 		return underlyingAlgorithm(data)
// 	}

// 	rebind(indicator, base, 'id', 'accessor', 'stroke', 'fill', 'echo', 'type')

// 	return indicator
// }

'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})

exports.default = function () {
  var base = (0, _baseIndicator2.default)().accessor(function (d) {
    return d.sma
  })

  var underlyingAlgorithm = function (data) {
    return data
  }

  var indicator = function indicator(data) {
    return underlyingAlgorithm(data)
  }

  ;(0, _utils.rebind)(indicator, base, 'id', 'accessor', 'stroke', 'fill', 'echo', 'type')

  return indicator
}

var _utils = require('../utils')

var _baseIndicator = require('../../lib/indicator/baseIndicator')

var _baseIndicator2 = _interopRequireDefault(_baseIndicator)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}
