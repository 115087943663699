const LIMITS_DICT: { [key: number]: number } = {
  0: 43200,
  1: 43200,
  5: 86400,
  15: 259200,
  30: 388800,
  60: 518400,
  240: 518400,
  1440: 1036800,
  10080: 2592000,
  43200: 5184000
}

// !TODO: use coefficient, because there is wierd backend behavior, if use strict limits
const safeLimit = 0.8

export default function getLoadingLimitInSeconds(timeframe: number): number {
  return LIMITS_DICT[timeframe] * 60 * safeLimit
}
