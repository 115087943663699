import React from 'react'
import { BadgeElementProps } from './types/componentsTypes'
import { isNotDefined } from '../../../libs/react-stockcharts/lib/utils'

const WebTesterBadgeElement: React.FC<BadgeElementProps> = ({ badge }) => {
  if (isNotDefined(badge) || badge! <= 0) return null

  return <span className='ml-2 badge-pill badge-danger'>{badge}</span>
}

export default WebTesterBadgeElement
