export enum UserType {
  DEMO = 'demo',
  REGISTERED = 'registered'
}

export enum UIEventType {
  CLICK = 'click',
  MODAL_SHOWED = 'modal_showed'
}

export enum LessonEventType {
  STEP_REACHED = 'lesson_step_reached',
  LESSON_STARTED = 'lesson_started',
  LESSON_COMPLETED = 'lesson_completed',
  LESSON_CANCELED = 'lesson_cancelled',
  LESSON_LIKE = 'lesson_like',
  LESSON_DISLIKE = 'lesson_dislike',
  FEEDBACK_SENT = 'feedback_sent',
  LESSON_UNAVAILABLE_CLICKED = 'lesson_unavailable_clicked'
}

export enum CourseEventType {
  COURSE_OPENED = 'course_opened',
  COURSE_LESSONS_OPENED = 'course_lessons_opened',
  COURSE_COMPLETED_50 = 'course_completed_50%',
  COURSE_COMPLETED_80 = 'course_completed_80%',
  COURSE_COMPLETED_100 = 'course_completed_100%'
}

interface TelemetryBaseDto {
  userId: string
  userType: UserType
  clientVersion: string
  timestamp: string
  uiLang: string
  userGroup?: number
  FTORunMode: string
}

export interface FtoLaunchedDto extends TelemetryBaseDto {}

export interface UIEventDto extends TelemetryBaseDto {
  UiElementName: string
  EventType: UIEventType
}

export interface LessonEventDto extends TelemetryBaseDto {
  courseName: string
  lessonName: string
  lessonNumber: number
  lessonStepNumber: number
  eventType: LessonEventType
  lessonId: number
  originalLessonName: string
}

export interface CourseEventDto extends TelemetryBaseDto {
  eventType: CourseEventType
  courseName: string
}
