import React from 'react'
import { useIntl } from 'react-intl'
import { Modal } from 'reactstrap'
import { toggleNotEnoughFreeMarginModal } from '../../../../../redux/modals/actions'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store_hooks'
import { ID } from '../../../../../utils/locators/ids'
import FTORightIcon from '../../../../icons/RightIcon'
import './NotEnoughFreeMarginModal.scss'

export default function NotEnoughFreeMarginModal() {
  const isOpen = useAppSelector((state) => state.modals.notEnoughFreeMarginModalIsOpened)
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const toggle = () => dispatch(toggleNotEnoughFreeMarginModal())

  return (
    <Modal isOpen={isOpen} className='thick-borders' toggle={toggle} fade={false} centered style={{ width: '300px' }}>
      <div id={ID.NotEnoughFreeMargin}>
        <button onClick={toggle} className='exit'>
          <FTORightIcon icon='cross' />
        </button>
        <h4>Warning!</h4>

        <p className='content'>{intl.formatMessage({ id: '2717' })}</p>
        <button className='ok' onClick={toggle}>
          {intl.formatMessage({ id: '1' })}
        </button>
      </div>
    </Modal>
  )
}
