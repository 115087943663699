import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isNotDefined } from '../../../libs/react-stockcharts/lib/utils'
import { FormattedTextProps } from './types/componentsTypes'
import WebTesterBadgeElement from './WebTesterBadgeElement'

const WebTesterFormattedText: React.FC<FormattedTextProps> = ({
  dataTestText,
  classParagraph,
  defaultMessage,
  id,
  badge,
  style
}) => {
  if (isNotDefined(defaultMessage)) return null

  return (
    <p data-test={dataTestText} className={`${classParagraph}`} style={style}>
      <FormattedMessage id={id} defaultMessage={defaultMessage}>
        {(msg) => {
          if (msg) msg[0] = msg[0]!.toString().replace(/\\n/g, '\n')
          return <>{msg}</>
        }}
      </FormattedMessage>
      <WebTesterBadgeElement badge={badge} />
    </p>
  )
}

export default WebTesterFormattedText
