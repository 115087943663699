import { modalsSlice } from './reducers'

export const {
  toggleBasicCoursesModal,
  toggleDefaultOrdersModal,
  toggleDemonstrationModal,
  toggleDepositChangeModal,
  toggleEditProjectModal,
  toggleFinishedTutorialModal,
  toggleHotKeysModal,
  toggleInteractiveTextModal,
  toggleListOfStrategiesModal,
  toggleMarketOrderModal,
  toggleMarketOrderQuestionModal,
  toggleNewProjectModal,
  toggleOpenProjectModal,
  togglePendingOrderModal,
  togglePendingOrderQuestionModal,
  toggleQuickStartModal,
  toggleSoundsModal,
  toggleStrategyOptimizerModal,
  toggleSubscribeModal,
  toggleTrialVersionModal,
  toggleLessonAlertModal,
  toggleTutorialFeedbackModal,
  toggleNotEnoughFreeMarginModal,
  toggleStartNewProjectModal,
  hideDropdown,
  hideAllModals,
  toggleQuitLessonModal,
  toggleIndicatorModalEditMode,
  toggleOscillatorModalEditMode,
  toggleIndicatorModalCreateMode,
  toggleRewardToRiskModal
} = modalsSlice.actions
