export const defaultRsi = [
  {
    t: 1580683500,
    v: 37.912087912089056
  },
  {
    t: 1580683800,
    v: 37.912087912089056
  },
  {
    t: 1580684100,
    v: 30.30112923463004
  },
  {
    t: 1580684400,
    v: 50.672332868198914
  },
  {
    t: 1580684700,
    v: 50.140638328938124
  },
  {
    t: 1580685000,
    v: 48.68120396765314
  },
  {
    t: 1580685300,
    v: 47.72859093432217
  },
  {
    t: 1580685600,
    v: 45.36151861756588
  },
  {
    t: 1580685900,
    v: 47.892996679632034
  },
  {
    t: 1580686200,
    v: 52.50371481337112
  },
  {
    t: 1580686500,
    v: 48.906793675335614
  },
  {
    t: 1580686800,
    v: 44.94772472284287
  },
  {
    t: 1580687100,
    v: 47.63895948762296
  },
  {
    t: 1580687400,
    v: 49.33723594486372
  },
  {
    t: 1580687700,
    v: 47.57379913309602
  },
  {
    t: 1580688000,
    v: 37.795747618972854
  },
  {
    t: 1580688300,
    v: 40.726913304425885
  },
  {
    t: 1580688600,
    v: 33.884037451690915
  },
  {
    t: 1580688900,
    v: 37.104635196095636
  },
  {
    t: 1580689200,
    v: 38.80792020845548
  },
  {
    t: 1580689500,
    v: 35.01492829211648
  },
  {
    t: 1580689800,
    v: 24.201631370644037
  },
  {
    t: 1580690100,
    v: 20.794218508949186
  },
  {
    t: 1580690400,
    v: 18.92070820312756
  },
  {
    t: 1580690700,
    v: 18.92070820312756
  },
  {
    t: 1580691000,
    v: 17.154346617629457
  },
  {
    t: 1580691300,
    v: 29.9640332113957
  },
  {
    t: 1580691600,
    v: 27.53149061579613
  },
  {
    t: 1580691900,
    v: 25.555625050451468
  },
  {
    t: 1580692200,
    v: 36.04654599547492
  },
  {
    t: 1580692500,
    v: 31.48309305813507
  },
  {
    t: 1580692800,
    v: 59.86935375082266
  },
  {
    t: 1580693100,
    v: 67.55127750256653
  },
  {
    t: 1580693400,
    v: 53.674097958487934
  },
  {
    t: 1580693700,
    v: 48.82438833481085
  },
  {
    t: 1580694000,
    v: 45.81384407267255
  },
  {
    t: 1580694300,
    v: 43.6183062927502
  },
  {
    t: 1580694600,
    v: 44.827017587232504
  },
  {
    t: 1580694900,
    v: 49.18454633398376
  },
  {
    t: 1580695200,
    v: 56.479822219157555
  },
  {
    t: 1580695500,
    v: 45.096535345416875
  },
  {
    t: 1580695800,
    v: 50.76669516673889
  },
  {
    t: 1580696100,
    v: 43.56758216482166
  },
  {
    t: 1580696400,
    v: 46.655217232127086
  },
  {
    t: 1580696700,
    v: 44.968100820316735
  },
  {
    t: 1580697000,
    v: 44.357048819770725
  },
  {
    t: 1580697300,
    v: 50.50608900516307
  },
  {
    t: 1580697600,
    v: 42.46193833310559
  },
  {
    t: 1580697900,
    v: 36.869352386324685
  },
  {
    t: 1580698200,
    v: 46.5279275044569
  },
  {
    t: 1580698500,
    v: 44.57926049419659
  },
  {
    t: 1580698800,
    v: 52.20492606017148
  },
  {
    t: 1580699100,
    v: 52.204926060171495
  },
  {
    t: 1580699400,
    v: 54.64997799914309
  },
  {
    t: 1580699700,
    v: 57.15492098951687
  },
  {
    t: 1580700000,
    v: 39.63899878690818
  },
  {
    t: 1580700300,
    v: 36.587878927109216
  },
  {
    t: 1580700600,
    v: 42.64266432545434
  },
  {
    t: 1580700900,
    v: 42.64266432545434
  },
  {
    t: 1580701200,
    v: 47.04540176787804
  },
  {
    t: 1580701500,
    v: 53.203296803259086
  },
  {
    t: 1580701800,
    v: 47.898582856547236
  },
  {
    t: 1580702100,
    v: 41.30860524197369
  },
  {
    t: 1580702400,
    v: 47.23476141608156
  },
  {
    t: 1580702700,
    v: 55.458598044312566
  },
  {
    t: 1580703000,
    v: 55.458598044312566
  },
  {
    t: 1580703300,
    v: 60.48597807554454
  },
  {
    t: 1580703600,
    v: 63.32452924625325
  },
  {
    t: 1580703900,
    v: 64.30146625498426
  },
  {
    t: 1580704200,
    v: 64.30146625498426
  },
  {
    t: 1580704500,
    v: 75.99425282525769
  },
  {
    t: 1580704800,
    v: 61.25390188920308
  },
  {
    t: 1580705100,
    v: 51.17090571538469
  },
  {
    t: 1580705400,
    v: 52.29277424690116
  },
  {
    t: 1580705700,
    v: 39.7636057540754
  },
  {
    t: 1580706000,
    v: 37.21594512693724
  },
  {
    t: 1580706300,
    v: 46.377543585156936
  },
  {
    t: 1580706600,
    v: 52.08517253711517
  },
  {
    t: 1580706900,
    v: 59.05785107981866
  },
  {
    t: 1580707200,
    v: 60.915069415343055
  },
  {
    t: 1580707500,
    v: 53.925955292687284
  },
  {
    t: 1580707800,
    v: 64.24148420886334
  },
  {
    t: 1580708100,
    v: 62.78111243583659
  },
  {
    t: 1580708400,
    v: 59.680128230769455
  },
  {
    t: 1580708700,
    v: 61.83456010980479
  },
  {
    t: 1580709000,
    v: 65.03716573405755
  },
  {
    t: 1580709300,
    v: 63.02251569319509
  },
  {
    t: 1580709600,
    v: 71.95742824620555
  },
  {
    t: 1580709900,
    v: 75.68738696009456
  },
  {
    t: 1580710200,
    v: 75.68738696009456
  },
  {
    t: 1580710500,
    v: 47.581913771557296
  },
  {
    t: 1580710800,
    v: 39.66117061626939
  },
  {
    t: 1580711100,
    v: 33.321821057536226
  },
  {
    t: 1580711400,
    v: 28.72972795045807
  },
  {
    t: 1580711700,
    v: 28.09754292071399
  },
  {
    t: 1580712000,
    v: 48.72786558936028
  },
  {
    t: 1580712300,
    v: 48.727865589360285
  },
  {
    t: 1580712600,
    v: 54.10290223120211
  },
  {
    t: 1580712900,
    v: 39.10890032005333
  },
  {
    t: 1580713200,
    v: 46.51963652042285
  },
  {
    t: 1580713500,
    v: 45.613292926216246
  },
  {
    t: 1580713800,
    v: 21.13831750381985
  },
  {
    t: 1580714100,
    v: 19.52641079319011
  },
  {
    t: 1580714400,
    v: 19.052021954596512
  },
  {
    t: 1580714700,
    v: 15.414183305084038
  },
  {
    t: 1580715000,
    v: 12.391890411204614
  },
  {
    t: 1580715300,
    v: 11.572954575746337
  },
  {
    t: 1580715600,
    v: 19.663129383782504
  },
  {
    t: 1580715900,
    v: 34.26807719060473
  },
  {
    t: 1580716200,
    v: 31.567444101542918
  },
  {
    t: 1580716500,
    v: 35.70386413032857
  },
  {
    t: 1580716800,
    v: 44.847704815962146
  },
  {
    t: 1580717100,
    v: 43.66488976460768
  },
  {
    t: 1580717400,
    v: 59.081742175887236
  },
  {
    t: 1580717700,
    v: 56.26611328833937
  },
  {
    t: 1580718000,
    v: 48.93652050537641
  },
  {
    t: 1580718300,
    v: 36.56468712103335
  },
  {
    t: 1580718600,
    v: 34.19359244455639
  },
  {
    t: 1580718900,
    v: 25.17566587767324
  },
  {
    t: 1580719200,
    v: 23.931996754351957
  },
  {
    t: 1580719500,
    v: 19.52314410496686
  },
  {
    t: 1580719800,
    v: 15.85190450983967
  },
  {
    t: 1580720100,
    v: 15.698551670398402
  },
  {
    t: 1580720400,
    v: 15.698551670398402
  },
  {
    t: 1580720700,
    v: 14.257355095188402
  },
  {
    t: 1580721000,
    v: 10.736987279436377
  },
  {
    t: 1580721300,
    v: 8.840925996560046
  },
  {
    t: 1580721600,
    v: 7.9160004536840916
  },
  {
    t: 1580721900,
    v: 28.20555346818371
  },
  {
    t: 1580722200,
    v: 39.855699706696186
  },
  {
    t: 1580722500,
    v: 50.80393422163572
  },
  {
    t: 1580722800,
    v: 59.276043140610966
  },
  {
    t: 1580723100,
    v: 47.261416266119454
  },
  {
    t: 1580723400,
    v: 59.397403999931285
  },
  {
    t: 1580723700,
    v: 65.51849216026892
  },
  {
    t: 1580724000,
    v: 56.817020454474495
  },
  {
    t: 1580724300,
    v: 49.87739589636209
  },
  {
    t: 1580724600,
    v: 48.9457244155065
  },
  {
    t: 1580724900,
    v: 45.09502514646775
  },
  {
    t: 1580725200,
    v: 49.275681308437576
  },
  {
    t: 1580725500,
    v: 48.50818371098748
  },
  {
    t: 1580725800,
    v: 45.28385218710091
  },
  {
    t: 1580726100,
    v: 48.233220651904844
  },
  {
    t: 1580726400,
    v: 55.537365678893465
  },
  {
    t: 1580726700,
    v: 47.41384480251204
  },
  {
    t: 1580727000,
    v: 43.16889122908647
  },
  {
    t: 1580727300,
    v: 37.75987481401848
  },
  {
    t: 1580727600,
    v: 50.027344337847225
  },
  {
    t: 1580727900,
    v: 37.13238880660182
  },
  {
    t: 1580728200,
    v: 35.7108056319686
  },
  {
    t: 1580728500,
    v: 31.813369289132666
  },
  {
    t: 1580728800,
    v: 29.69675248309494
  },
  {
    t: 1580729100,
    v: 38.469157135999694
  },
  {
    t: 1580729400,
    v: 35.43705330624219
  },
  {
    t: 1580729700,
    v: 30.545487708663444
  },
  {
    t: 1580730000,
    v: 46.804634025934945
  },
  {
    t: 1580730300,
    v: 39.05275421513202
  },
  {
    t: 1580730600,
    v: 42.37253815511243
  },
  {
    t: 1580730900,
    v: 48.75292172375463
  },
  {
    t: 1580731200,
    v: 49.2119534638886
  },
  {
    t: 1580731500,
    v: 56.740276019762476
  },
  {
    t: 1580731800,
    v: 63.31966462251994
  },
  {
    t: 1580732100,
    v: 57.239570688839216
  },
  {
    t: 1580732400,
    v: 52.04717145449214
  },
  {
    t: 1580732700,
    v: 48.06108075847439
  },
  {
    t: 1580733000,
    v: 53.183243000334734
  },
  {
    t: 1580733300,
    v: 43.40019755491448
  },
  {
    t: 1580733600,
    v: 46.226381792184526
  },
  {
    t: 1580733900,
    v: 57.788753268904834
  },
  {
    t: 1580734200,
    v: 57.788753268904834
  },
  {
    t: 1580734500,
    v: 60.55799308024995
  },
  {
    t: 1580734800,
    v: 52.09519290301713
  },
  {
    t: 1580735100,
    v: 60.371676018883484
  },
  {
    t: 1580735400,
    v: 58.33890124138556
  },
  {
    t: 1580735700,
    v: 45.49924044756224
  },
  {
    t: 1580736000,
    v: 52.074494260759174
  },
  {
    t: 1580736300,
    v: 39.7445304369318
  },
  {
    t: 1580736600,
    v: 45.23337465945171
  },
  {
    t: 1580736900,
    v: 42.99533529212606
  },
  {
    t: 1580737200,
    v: 46.04619845389312
  },
  {
    t: 1580737500,
    v: 54.41148758569477
  },
  {
    t: 1580737800,
    v: 51.37691439912388
  },
  {
    t: 1580738100,
    v: 44.56044179904282
  },
  {
    t: 1580738400,
    v: 38.69338436092825
  },
  {
    t: 1580738700,
    v: 29.179915008997654
  },
  {
    t: 1580739000,
    v: 23.99372447552767
  },
  {
    t: 1580739300,
    v: 23.752492107932525
  },
  {
    t: 1580739600,
    v: 17.663682822520286
  },
  {
    t: 1580739900,
    v: 18.45996096749299
  },
  {
    t: 1580740200,
    v: 13.310791114049053
  },
  {
    t: 1580740500,
    v: 26.668992581998495
  },
  {
    t: 1580740800,
    v: 22.341136221711523
  },
  {
    t: 1580741100,
    v: 18.074705046121508
  },
  {
    t: 1580741400,
    v: 15.85079616391549
  },
  {
    t: 1580741700,
    v: 24.677184232866324
  },
  {
    t: 1580742000,
    v: 19.651645779178196
  },
  {
    t: 1580742300,
    v: 38.10804656648416
  },
  {
    t: 1580742600,
    v: 30.18411926914648
  },
  {
    t: 1580742900,
    v: 39.93244189052301
  },
  {
    t: 1580743200,
    v: 38.61210629487994
  },
  {
    t: 1580743500,
    v: 49.69970996706806
  },
  {
    t: 1580743800,
    v: 48.001146704871665
  },
  {
    t: 1580744100,
    v: 40.15842967413145
  },
  {
    t: 1580744400,
    v: 39.18289625619812
  },
  {
    t: 1580744700,
    v: 52.13828028147017
  },
  {
    t: 1580745000,
    v: 54.68419640773196
  },
  {
    t: 1580745300,
    v: 65.95304584433543
  },
  {
    t: 1580745600,
    v: 68.84428162314396
  },
  {
    t: 1580745900,
    v: 63.102883391953156
  },
  {
    t: 1580746200,
    v: 63.102883391953156
  },
  {
    t: 1580746500,
    v: 71.56801135177137
  },
  {
    t: 1580746800,
    v: 60.69902729470535
  },
  {
    t: 1580747100,
    v: 63.20775156791005
  },
  {
    t: 1580747400,
    v: 68.61891018044668
  },
  {
    t: 1580747700,
    v: 62.92387959045378
  },
  {
    t: 1580748000,
    v: 66.34420387206794
  },
  {
    t: 1580748300,
    v: 69.74648017184859
  },
  {
    t: 1580748600,
    v: 73.22634123727255
  },
  {
    t: 1580748900,
    v: 63.08985073311542
  },
  {
    t: 1580749200,
    v: 59.65785177996468
  },
  {
    t: 1580749500,
    v: 63.86952386867003
  },
  {
    t: 1580749800,
    v: 71.56071784924842
  },
  {
    t: 1580750100,
    v: 60.70205152881073
  },
  {
    t: 1580750400,
    v: 57.13508429836961
  },
  {
    t: 1580750700,
    v: 54.68670771670933
  },
  {
    t: 1580751000,
    v: 50.94427551870013
  },
  {
    t: 1580751300,
    v: 51.366898941421134
  },
  {
    t: 1580751600,
    v: 43.63383341385948
  },
  {
    t: 1580751900,
    v: 54.15159373332338
  },
  {
    t: 1580752200,
    v: 48.544140692008455
  },
  {
    t: 1580752500,
    v: 41.06713955241139
  },
  {
    t: 1580752800,
    v: 55.24713085985339
  },
  {
    t: 1580753100,
    v: 53.60915739375492
  },
  {
    t: 1580753400,
    v: 56.895369035290635
  },
  {
    t: 1580753700,
    v: 56.895369035290635
  },
  {
    t: 1580754000,
    v: 54.11384357336271
  },
  {
    t: 1580754300,
    v: 53.5158309699054
  },
  {
    t: 1580754600,
    v: 47.51483754517472
  },
  {
    t: 1580754900,
    v: 39.85370782207594
  },
  {
    t: 1580755200,
    v: 47.01381599882187
  },
  {
    t: 1580755500,
    v: 39.65655405620305
  },
  {
    t: 1580755800,
    v: 48.81148715064804
  },
  {
    t: 1580756100,
    v: 51.60831968128584
  },
  {
    t: 1580756400,
    v: 53.35588727826291
  },
  {
    t: 1580756700,
    v: 57.45319830413007
  },
  {
    t: 1580757000,
    v: 57.45319830413007
  },
  {
    t: 1580757300,
    v: 61.262703004928916
  },
  {
    t: 1580757600,
    v: 51.58526041203479
  },
  {
    t: 1580757900,
    v: 61.65452614988545
  },
  {
    t: 1580758200,
    v: 54.383409564782134
  },
  {
    t: 1580758500,
    v: 62.28971264944851
  },
  {
    t: 1580758800,
    v: 61.420693661669695
  },
  {
    t: 1580759100,
    v: 56.88568731289249
  },
  {
    t: 1580759400,
    v: 47.97877865274976
  },
  {
    t: 1580759700,
    v: 57.62868736184004
  },
  {
    t: 1580760000,
    v: 51.40175042492918
  },
  {
    t: 1580760300,
    v: 43.94073846134199
  },
  {
    t: 1580760600,
    v: 42.03882432611407
  },
  {
    t: 1580760900,
    v: 40.696727854092295
  },
  {
    t: 1580761200,
    v: 31.063117358486366
  },
  {
    t: 1580761500,
    v: 34.20428753456966
  },
  {
    t: 1580761800,
    v: 30.498450384463567
  },
  {
    t: 1580762100,
    v: 26.717618091844514
  },
  {
    t: 1580762400,
    v: 35.811674310339086
  },
  {
    t: 1580762700,
    v: 30.418991997438496
  },
  {
    t: 1580763000,
    v: 36.9313319993996
  },
  {
    t: 1580763300,
    v: 33.90880917078026
  },
  {
    t: 1580763600,
    v: 40.57830281420085
  },
  {
    t: 1580763900,
    v: 50.158622831688334
  },
  {
    t: 1580764200,
    v: 51.930078624722
  },
  {
    t: 1580764500,
    v: 49.90305251034682
  },
  {
    t: 1580764800,
    v: 48.814250435297815
  },
  {
    t: 1580765100,
    v: 47.62666744372164
  },
  {
    t: 1580765400,
    v: 55.1146549278825
  },
  {
    t: 1580765700,
    v: 56.304611740720475
  },
  {
    t: 1580766000,
    v: 66.46511823786858
  },
  {
    t: 1580766300,
    v: 51.43069477298436
  },
  {
    t: 1580766600,
    v: 58.29203967152773
  },
  {
    t: 1580766900,
    v: 43.3087243991105
  },
  {
    t: 1580767200,
    v: 46.45460842104409
  },
  {
    t: 1580767500,
    v: 33.14273638401312
  },
  {
    t: 1580767800,
    v: 50.28200480269292
  },
  {
    t: 1580768100,
    v: 42.23831100040411
  },
  {
    t: 1580768400,
    v: 42.2383110004041
  },
  {
    t: 1580768700,
    v: 42.23831100040411
  },
  {
    t: 1580769000,
    v: 51.94752140382653
  },
  {
    t: 1580769300,
    v: 50.19433540162629
  },
  {
    t: 1580769600,
    v: 51.136676468935846
  },
  {
    t: 1580769900,
    v: 54.113335371578984
  },
  {
    t: 1580770200,
    v: 52.88601593456254
  },
  {
    t: 1580770500,
    v: 45.768007302797976
  },
  {
    t: 1580770800,
    v: 68.4297835207217
  },
  {
    t: 1580771100,
    v: 67.2822307082778
  },
  {
    t: 1580771400,
    v: 67.2822307082778
  },
  {
    t: 1580771700,
    v: 67.98350069761021
  },
  {
    t: 1580772000,
    v: 67.9835006976102
  },
  {
    t: 1580772300,
    v: 67.9835006976102
  },
  {
    t: 1580772600,
    v: 58.60781679005064
  },
  {
    t: 1580772900,
    v: 64.24349785634058
  },
  {
    t: 1580773200,
    v: 72.73009581601731
  },
  {
    t: 1580773500,
    v: 65.61126087952084
  },
  {
    t: 1580773800,
    v: 63.826309251855164
  },
  {
    t: 1580774100,
    v: 61.9016999084381
  },
  {
    t: 1580774400,
    v: 63.170888179808365
  },
  {
    t: 1580774700,
    v: 53.068594515425076
  },
  {
    t: 1580775000,
    v: 56.26583829098542
  },
  {
    t: 1580775300,
    v: 45.612031682511336
  },
  {
    t: 1580775600,
    v: 32.65633355084174
  },
  {
    t: 1580775900,
    v: 32.65633355084174
  },
  {
    t: 1580776200,
    v: 46.96567493814855
  },
  {
    t: 1580776500,
    v: 48.528085512947385
  },
  {
    t: 1580776800,
    v: 41.53575175319184
  },
  {
    t: 1580777100,
    v: 38.96890948285304
  },
  {
    t: 1580777400,
    v: 30.38495134529849
  },
  {
    t: 1580777700,
    v: 47.04797677183824
  },
  {
    t: 1580778000,
    v: 58.42183871740145
  },
  {
    t: 1580778300,
    v: 46.90706089477821
  },
  {
    t: 1580778600,
    v: 45.873737148035964
  },
  {
    t: 1580778900,
    v: 48.46847294367006
  },
  {
    t: 1580779200,
    v: 36.477521998023924
  },
  {
    t: 1580779500,
    v: 44.34539506655685
  },
  {
    t: 1580779800,
    v: 44.34539506655685
  },
  {
    t: 1580780100,
    v: 57.076144405921895
  },
  {
    t: 1580780400,
    v: 59.023807015743756
  },
  {
    t: 1580780700,
    v: 65.3184793066339
  },
  {
    t: 1580781000,
    v: 65.3184793066339
  },
  {
    t: 1580781300,
    v: 41.51771820936745
  },
  {
    t: 1580781600,
    v: 26.935684549932176
  },
  {
    t: 1580781900,
    v: 25.743372550007706
  },
  {
    t: 1580782200,
    v: 25.316463242236395
  },
  {
    t: 1580782500,
    v: 26.705562079030557
  },
  {
    t: 1580782800,
    v: 24.139866710228915
  },
  {
    t: 1580783100,
    v: 24.139866710228915
  },
  {
    t: 1580783400,
    v: 21.448437724175676
  },
  {
    t: 1580783700,
    v: 19.925114443273117
  },
  {
    t: 1580784000,
    v: 19.400217896899335
  },
  {
    t: 1580784300,
    v: 36.58360884660899
  },
  {
    t: 1580784600,
    v: 50.09422719470918
  },
  {
    t: 1580784900,
    v: 55.51041825863931
  },
  {
    t: 1580785200,
    v: 62.09282344025557
  },
  {
    t: 1580785500,
    v: 54.23264773387063
  },
  {
    t: 1580785800,
    v: 43.69657147403229
  },
  {
    t: 1580786100,
    v: 42.747453296985526
  },
  {
    t: 1580786400,
    v: 41.71201076273573
  },
  {
    t: 1580786700,
    v: 31.310814111855834
  },
  {
    t: 1580787000,
    v: 58.74406775839254
  },
  {
    t: 1580787300,
    v: 43.76283353637033
  },
  {
    t: 1580787600,
    v: 48.7426195129723
  },
  {
    t: 1580787900,
    v: 48.04798503752777
  },
  {
    t: 1580788200,
    v: 55.32428980036895
  },
  {
    t: 1580788500,
    v: 51.99521321454203
  },
  {
    t: 1580788800,
    v: 55.08408669836055
  },
  {
    t: 1580789100,
    v: 58.86525768202928
  },
  {
    t: 1580789400,
    v: 63.12278453178528
  },
  {
    t: 1580789700,
    v: 56.44591240497321
  },
  {
    t: 1580790000,
    v: 60.431957241348115
  },
  {
    t: 1580790300,
    v: 63.48716499713634
  },
  {
    t: 1580790600,
    v: 65.03011663787004
  },
  {
    t: 1580790900,
    v: 68.79740937911224
  },
  {
    t: 1580791200,
    v: 71.5951627299463
  },
  {
    t: 1580791500,
    v: 71.5951627299463
  },
  {
    t: 1580791800,
    v: 62.452687171059814
  },
  {
    t: 1580792100,
    v: 57.42440299996119
  },
  {
    t: 1580792400,
    v: 55.715507649302346
  },
  {
    t: 1580792700,
    v: 47.61798861549409
  },
  {
    t: 1580793000,
    v: 52.365248295897764
  },
  {
    t: 1580793300,
    v: 64.59036388693274
  },
  {
    t: 1580793600,
    v: 57.80816962301607
  },
  {
    t: 1580793900,
    v: 57.80816962301607
  },
  {
    t: 1580794200,
    v: 74.97125741429477
  },
  {
    t: 1580794500,
    v: 47.416422980167944
  },
  {
    t: 1580794800,
    v: 47.416422980167944
  },
  {
    t: 1580795100,
    v: 36.81312737995119
  },
  {
    t: 1580795400,
    v: 40.84462989982473
  },
  {
    t: 1580795700,
    v: 38.72673608975182
  },
  {
    t: 1580796000,
    v: 58.98251489525359
  },
  {
    t: 1580796300,
    v: 59.59315428940543
  },
  {
    t: 1580796600,
    v: 54.073135682158394
  },
  {
    t: 1580796900,
    v: 53.13563680844769
  },
  {
    t: 1580797200,
    v: 67.36276366405573
  },
  {
    t: 1580797500,
    v: 54.47608743342251
  },
  {
    t: 1580797800,
    v: 56.98399635464197
  },
  {
    t: 1580798100,
    v: 67.28322353301293
  },
  {
    t: 1580798400,
    v: 54.58699722305289
  },
  {
    t: 1580798700,
    v: 41.84661481825249
  },
  {
    t: 1580799000,
    v: 46.04429525692574
  },
  {
    t: 1580799300,
    v: 40.76034396877784
  },
  {
    t: 1580799600,
    v: 31.69175418097845
  },
  {
    t: 1580799900,
    v: 27.345978119444737
  },
  {
    t: 1580800200,
    v: 23.22156500270711
  },
  {
    t: 1580800500,
    v: 26.926183717218805
  },
  {
    t: 1580800800,
    v: 30.74515480625365
  },
  {
    t: 1580801100,
    v: 22.633911999542605
  },
  {
    t: 1580801400,
    v: 28.39144342177076
  },
  {
    t: 1580801700,
    v: 23.620261814956464
  },
  {
    t: 1580802000,
    v: 23.38389113667067
  },
  {
    t: 1580802300,
    v: 24.250218882043157
  },
  {
    t: 1580802600,
    v: 38.54516816072754
  },
  {
    t: 1580802900,
    v: 31.09395981577967
  },
  {
    t: 1580803200,
    v: 37.948395835106915
  },
  {
    t: 1580803500,
    v: 61.82079106648407
  },
  {
    t: 1580803800,
    v: 59.90522585463564
  },
  {
    t: 1580804100,
    v: 65.4163223859509
  },
  {
    t: 1580804400,
    v: 52.204186809684124
  },
  {
    t: 1580804700,
    v: 52.204186809684124
  },
  {
    t: 1580805000,
    v: 59.24755046242563
  },
  {
    t: 1580805300,
    v: 58.726995744028315
  },
  {
    t: 1580805600,
    v: 54.36023639751264
  },
  {
    t: 1580805900,
    v: 48.67296536785872
  },
  {
    t: 1580806200,
    v: 58.21179087847587
  },
  {
    t: 1580806500,
    v: 61.69823478270151
  },
  {
    t: 1580806800,
    v: 68.11625754996692
  },
  {
    t: 1580807100,
    v: 68.11625754996692
  },
  {
    t: 1580807400,
    v: 60.45597684027612
  },
  {
    t: 1580807700,
    v: 55.2880710001135
  },
  {
    t: 1580808000,
    v: 41.96421894970036
  },
  {
    t: 1580808300,
    v: 49.921418363033716
  },
  {
    t: 1580808600,
    v: 46.07120616411074
  },
  {
    t: 1580808900,
    v: 60.45091493521548
  },
  {
    t: 1580809200,
    v: 49.86092479260181
  },
  {
    t: 1580809500,
    v: 49.86092479260181
  },
  {
    t: 1580809800,
    v: 42.64821418675612
  },
  {
    t: 1580810100,
    v: 44.79562289036914
  },
  {
    t: 1580810400,
    v: 39.32638185792625
  },
  {
    t: 1580810700,
    v: 44.67121016584227
  },
  {
    t: 1580811000,
    v: 42.30504287570455
  },
  {
    t: 1580811300,
    v: 30.227951985931767
  },
  {
    t: 1580811600,
    v: 27.508307863063052
  },
  {
    t: 1580811900,
    v: 37.19510209954806
  },
  {
    t: 1580812200,
    v: 33.758166632311145
  },
  {
    t: 1580812500,
    v: 40.08535231080273
  },
  {
    t: 1580812800,
    v: 47.02478767129954
  },
  {
    t: 1580813100,
    v: 49.26342116136115
  },
  {
    t: 1580813400,
    v: 44.434951910347934
  },
  {
    t: 1580813700,
    v: 58.794666394206686
  },
  {
    t: 1580814000,
    v: 65.36981207159806
  },
  {
    t: 1580814300,
    v: 54.35576359877277
  },
  {
    t: 1580814600,
    v: 50.92374173937976
  },
  {
    t: 1580814900,
    v: 54.662647300363915
  },
  {
    t: 1580815200,
    v: 51.84151963217236
  },
  {
    t: 1580815500,
    v: 48.95407995811011
  },
  {
    t: 1580815800,
    v: 46.58196914463374
  },
  {
    t: 1580816100,
    v: 51.90841055208169
  },
  {
    t: 1580816400,
    v: 51.17937912153075
  },
  {
    t: 1580816700,
    v: 44.71929821216846
  },
  {
    t: 1580817000,
    v: 40.20798732790116
  },
  {
    t: 1580817300,
    v: 47.16911439872555
  },
  {
    t: 1580817600,
    v: 33.316365195037406
  },
  {
    t: 1580817900,
    v: 23.980007222608137
  },
  {
    t: 1580818200,
    v: 26.417860131821357
  },
  {
    t: 1580818500,
    v: 35.13463656771198
  },
  {
    t: 1580818800,
    v: 44.08169203399821
  },
  {
    t: 1580819100,
    v: 39.74425900290828
  },
  {
    t: 1580819400,
    v: 37.852447254675944
  },
  {
    t: 1580819700,
    v: 28.24815172105724
  },
  {
    t: 1580820900,
    v: 20.42867579818484
  },
  {
    t: 1580821200,
    v: 26.748763249760643
  },
  {
    t: 1580821500,
    v: 36.927138476029214
  },
  {
    t: 1580821800,
    v: 42.099187441139705
  },
  {
    t: 1580822100,
    v: 54.43514510250035
  },
  {
    t: 1580822400,
    v: 45.13583069668218
  },
  {
    t: 1580822700,
    v: 49.19372453144795
  },
  {
    t: 1580823000,
    v: 52.74435132659525
  },
  {
    t: 1580823300,
    v: 43.60274085143268
  },
  {
    t: 1580823600,
    v: 40.54338889118728
  },
  {
    t: 1580823900,
    v: 47.39772634298986
  },
  {
    t: 1580824200,
    v: 49.93472665499337
  },
  {
    t: 1580824500,
    v: 34.390717574240924
  },
  {
    t: 1580824800,
    v: 34.9551105945135
  },
  {
    t: 1580825100,
    v: 40.242521548032286
  },
  {
    t: 1580825400,
    v: 44.269422155480235
  },
  {
    t: 1580825700,
    v: 41.10384405310014
  },
  {
    t: 1580826000,
    v: 42.447648746418075
  },
  {
    t: 1580826300,
    v: 45.9698740985801
  },
  {
    t: 1580826600,
    v: 37.996974627430134
  },
  {
    t: 1580826900,
    v: 36.0893925838534
  },
  {
    t: 1580827200,
    v: 34.1311048622443
  },
  {
    t: 1580827500,
    v: 28.40601236132396
  },
  {
    t: 1580827800,
    v: 38.39832397313328
  },
  {
    t: 1580828100,
    v: 44.80163872838135
  },
  {
    t: 1580828400,
    v: 61.01227884417872
  },
  {
    t: 1580828700,
    v: 46.094069120387246
  },
  {
    t: 1580829000,
    v: 44.2616888101413
  },
  {
    t: 1580829300,
    v: 53.28224662616082
  },
  {
    t: 1580829600,
    v: 47.12915335774267
  },
  {
    t: 1580829900,
    v: 25.982696495711735
  },
  {
    t: 1580830200,
    v: 28.461367235424916
  },
  {
    t: 1580830500,
    v: 25.184293477665733
  },
  {
    t: 1580830800,
    v: 26.324869900484686
  },
  {
    t: 1580831100,
    v: 24.410962291784443
  },
  {
    t: 1580831400,
    v: 49.55813128171894
  },
  {
    t: 1580831700,
    v: 42.39427414503468
  },
  {
    t: 1580832000,
    v: 41.25826035487427
  },
  {
    t: 1580832300,
    v: 35.310717520340305
  },
  {
    t: 1580832600,
    v: 38.53280528344557
  },
  {
    t: 1580832900,
    v: 36.45749153993587
  },
  {
    t: 1580833200,
    v: 28.8476067046615
  },
  {
    t: 1580833500,
    v: 41.03776344421359
  },
  {
    t: 1580833800,
    v: 42.761945925223394
  },
  {
    t: 1580834100,
    v: 43.70266311884358
  },
  {
    t: 1580834400,
    v: 40.296640535867866
  },
  {
    t: 1580834700,
    v: 60.060193411186006
  },
  {
    t: 1580835000,
    v: 62.86961939231178
  },
  {
    t: 1580835300,
    v: 67.7895008601489
  },
  {
    t: 1580835600,
    v: 71.022814412705
  },
  {
    t: 1580835900,
    v: 65.15030877147217
  },
  {
    t: 1580836200,
    v: 57.7133955770465
  },
  {
    t: 1580836500,
    v: 55.308274013690934
  },
  {
    t: 1580836800,
    v: 46.832435190142384
  },
  {
    t: 1580837100,
    v: 60.35411785615111
  },
  {
    t: 1580837400,
    v: 57.637487885955224
  },
  {
    t: 1580837700,
    v: 49.20089195225198
  },
  {
    t: 1580838000,
    v: 46.84973023580185
  },
  {
    t: 1580838300,
    v: 43.302376759804595
  },
  {
    t: 1580838600,
    v: 50.448850493405644
  },
  {
    t: 1580838900,
    v: 38.03104588265084
  },
  {
    t: 1580839200,
    v: 50.35393778731753
  },
  {
    t: 1580839500,
    v: 54.80480634256345
  },
  {
    t: 1580839800,
    v: 60.41991068816781
  },
  {
    t: 1580840100,
    v: 61.630592736699676
  },
  {
    t: 1580840400,
    v: 56.22540745788018
  },
  {
    t: 1580840700,
    v: 53.775401349080205
  },
  {
    t: 1580841000,
    v: 51.22444087486519
  },
  {
    t: 1580841300,
    v: 46.213587495188335
  },
  {
    t: 1580841600,
    v: 49.060977937278224
  },
  {
    t: 1580841900,
    v: 45.22990758183671
  },
  {
    t: 1580842200,
    v: 55.452741802180725
  },
  {
    t: 1580842500,
    v: 49.05765093028799
  },
  {
    t: 1580842800,
    v: 48.47681029237872
  },
  {
    t: 1580843100,
    v: 52.345775702658834
  },
  {
    t: 1580843400,
    v: 46.971086063046336
  },
  {
    t: 1580843700,
    v: 45.63240516406366
  },
  {
    t: 1580844000,
    v: 56.87070793442502
  },
  {
    t: 1580844300,
    v: 57.49824507509865
  },
  {
    t: 1580844600,
    v: 66.86509737166989
  },
  {
    t: 1580844900,
    v: 67.81869141665666
  },
  {
    t: 1580845200,
    v: 58.24085292387941
  },
  {
    t: 1580845500,
    v: 63.537478341235065
  },
  {
    t: 1580845800,
    v: 64.11544743236874
  },
  {
    t: 1580846100,
    v: 46.44616195767934
  },
  {
    t: 1580846400,
    v: 53.43057392882023
  },
  {
    t: 1580846700,
    v: 50.424283583901016
  },
  {
    t: 1580847000,
    v: 54.11266542104193
  },
  {
    t: 1580847300,
    v: 43.70703881122769
  },
  {
    t: 1580847600,
    v: 43.03154685678266
  },
  {
    t: 1580847900,
    v: 56.18715946113228
  },
  {
    t: 1580848200,
    v: 59.343067149159396
  },
  {
    t: 1580848500,
    v: 54.00784036371971
  },
  {
    t: 1580848800,
    v: 61.298178655668536
  },
  {
    t: 1580849100,
    v: 51.89671092954392
  },
  {
    t: 1580849400,
    v: 48.78705765033195
  },
  {
    t: 1580849700,
    v: 59.25132526494589
  },
  {
    t: 1580850000,
    v: 64.74048787432595
  },
  {
    t: 1580850300,
    v: 63.75890849074413
  },
  {
    t: 1580850600,
    v: 55.996566186167996
  },
  {
    t: 1580850900,
    v: 54.114237688613315
  },
  {
    t: 1580851200,
    v: 57.38828518670256
  },
  {
    t: 1580851500,
    v: 65.19360510749708
  },
  {
    t: 1580851800,
    v: 63.97607818795815
  },
  {
    t: 1580852100,
    v: 62.63914006137036
  },
  {
    t: 1580852400,
    v: 46.11779406290847
  },
  {
    t: 1580852700,
    v: 55.1338938045045
  },
  {
    t: 1580853000,
    v: 54.099330248719454
  },
  {
    t: 1580853300,
    v: 43.02672141187717
  },
  {
    t: 1580853600,
    v: 50.712617550962634
  },
  {
    t: 1580853900,
    v: 43.9383953397624
  },
  {
    t: 1580854200,
    v: 42.323096785802505
  },
  {
    t: 1580854500,
    v: 31.185864599752904
  },
  {
    t: 1580854800,
    v: 26.902559826809963
  },
  {
    t: 1580855100,
    v: 29.366396879803517
  },
  {
    t: 1580855400,
    v: 46.005832657836706
  },
  {
    t: 1580855700,
    v: 51.695476034420835
  },
  {
    t: 1580856000,
    v: 49.158302141276856
  },
  {
    t: 1580856300,
    v: 55.773474924458036
  },
  {
    t: 1580856600,
    v: 52.82765821806355
  },
  {
    t: 1580856900,
    v: 59.368083695192766
  },
  {
    t: 1580857200,
    v: 67.17103594907329
  },
  {
    t: 1580857500,
    v: 67.76067772929923
  },
  {
    t: 1580857800,
    v: 67.76067772929922
  },
  {
    t: 1580858100,
    v: 67.76067772929922
  },
  {
    t: 1580858400,
    v: 61.19768536358539
  },
  {
    t: 1580858700,
    v: 57.98826765834939
  },
  {
    t: 1580859000,
    v: 51.7812150371398
  },
  {
    t: 1580859300,
    v: 50.24459463623084
  },
  {
    t: 1580859600,
    v: 59.790427048836364
  },
  {
    t: 1580859900,
    v: 50.33119396464136
  },
  {
    t: 1580860200,
    v: 50.33119396464136
  },
  {
    t: 1580860500,
    v: 51.98481328302548
  },
  {
    t: 1580860800,
    v: 28.896024360757636
  },
  {
    t: 1580861100,
    v: 36.5627775270295
  },
  {
    t: 1580861400,
    v: 44.73501284440825
  },
  {
    t: 1580861700,
    v: 43.66359977900283
  },
  {
    t: 1580862000,
    v: 52.722121655585156
  },
  {
    t: 1580862300,
    v: 43.57142946849057
  },
  {
    t: 1580862600,
    v: 51.91694420140974
  },
  {
    t: 1580862900,
    v: 56.15198781795522
  },
  {
    t: 1580863200,
    v: 52.210359063915774
  },
  {
    t: 1580863500,
    v: 49.55978071418324
  },
  {
    t: 1580863800,
    v: 37.57065802995165
  },
  {
    t: 1580864100,
    v: 40.55862722966971
  },
  {
    t: 1580864400,
    v: 45.065887890436535
  },
  {
    t: 1580864700,
    v: 32.088825483312434
  },
  {
    t: 1580865000,
    v: 28.122426378580116
  },
  {
    t: 1580865300,
    v: 37.01938254973374
  },
  {
    t: 1580865600,
    v: 31.775228281892183
  },
  {
    t: 1580865900,
    v: 43.52969292144398
  },
  {
    t: 1580866200,
    v: 44.73870668506089
  },
  {
    t: 1580866500,
    v: 46.05855973614983
  },
  {
    t: 1580866800,
    v: 29.79368554599631
  },
  {
    t: 1580867100,
    v: 25.214438115141675
  },
  {
    t: 1580867400,
    v: 37.42698866122566
  },
  {
    t: 1580867700,
    v: 34.827082650222806
  },
  {
    t: 1580868000,
    v: 40.7107816552255
  },
  {
    t: 1580868300,
    v: 28.402822971589558
  },
  {
    t: 1580868600,
    v: 26.24385189833518
  },
  {
    t: 1580868900,
    v: 36.2174456907654
  },
  {
    t: 1580869200,
    v: 27.308066570485323
  },
  {
    t: 1580869500,
    v: 23.485459073004392
  },
  {
    t: 1580869800,
    v: 19.279579505405607
  },
  {
    t: 1580870100,
    v: 18.28179382901405
  },
  {
    t: 1580870400,
    v: 22.845254357434882
  },
  {
    t: 1580870700,
    v: 25.23118557416346
  },
  {
    t: 1580871000,
    v: 25.23118557416346
  },
  {
    t: 1580871300,
    v: 23.345326975929282
  },
  {
    t: 1580871600,
    v: 33.313975656450495
  },
  {
    t: 1580871900,
    v: 40.849311552456044
  },
  {
    t: 1580872200,
    v: 47.61437489325718
  },
  {
    t: 1580872500,
    v: 40.547785080255764
  },
  {
    t: 1580872800,
    v: 36.661343560454
  },
  {
    t: 1580873100,
    v: 35.87537815384084
  },
  {
    t: 1580873400,
    v: 30.623239605552556
  },
  {
    t: 1580873700,
    v: 29.90837079454424
  },
  {
    t: 1580874000,
    v: 28.393365889746647
  },
  {
    t: 1580874300,
    v: 34.11464795517543
  },
  {
    t: 1580874600,
    v: 34.11464795517543
  },
  {
    t: 1580874900,
    v: 34.11464795517543
  },
  {
    t: 1580875200,
    v: 34.11464795517543
  },
  {
    t: 1580875500,
    v: 34.114647955175414
  },
  {
    t: 1580875800,
    v: 47.6945224318528
  },
  {
    t: 1580876100,
    v: 45.54864793958919
  },
  {
    t: 1580876400,
    v: 59.96036945232751
  },
  {
    t: 1580876700,
    v: 59.960369452327505
  },
  {
    t: 1580877000,
    v: 54.5704691683006
  },
  {
    t: 1580877300,
    v: 37.318326219663554
  },
  {
    t: 1580877600,
    v: 47.796878303383316
  },
  {
    t: 1580877900,
    v: 40.12995214721099
  },
  {
    t: 1580878200,
    v: 34.99733721102915
  },
  {
    t: 1580878500,
    v: 48.23803302962789
  },
  {
    t: 1580878800,
    v: 43.861975611589656
  },
  {
    t: 1580879100,
    v: 52.13317904838223
  },
  {
    t: 1580879400,
    v: 59.03163855430812
  },
  {
    t: 1580879700,
    v: 59.03163855430812
  },
  {
    t: 1580880000,
    v: 60.51802023968573
  },
  {
    t: 1580880300,
    v: 68.3836961056553
  },
  {
    t: 1580880600,
    v: 59.37176664037131
  },
  {
    t: 1580880900,
    v: 72.01591386544322
  },
  {
    t: 1580881200,
    v: 74.30103484182101
  },
  {
    t: 1580881500,
    v: 75.80626096805561
  },
  {
    t: 1580881800,
    v: 80.91608109768664
  },
  {
    t: 1580882100,
    v: 76.31115561265091
  },
  {
    t: 1580882400,
    v: 76.31115561265091
  },
  {
    t: 1580882700,
    v: 76.31115561265091
  },
  {
    t: 1580883000,
    v: 45.4341150894645
  },
  {
    t: 1580883300,
    v: 46.966849917979076
  },
  {
    t: 1580883600,
    v: 51.62565091117512
  },
  {
    t: 1580883900,
    v: 45.53080478685396
  },
  {
    t: 1580884200,
    v: 45.53080478685396
  },
  {
    t: 1580884500,
    v: 36.97788457516817
  },
  {
    t: 1580884800,
    v: 27.970596887831306
  },
  {
    t: 1580885100,
    v: 30.131729427920533
  },
  {
    t: 1580885400,
    v: 45.17200748705877
  },
  {
    t: 1580885700,
    v: 48.34879581678572
  },
  {
    t: 1580886000,
    v: 61.19635013094796
  },
  {
    t: 1580886300,
    v: 69.78545671925642
  },
  {
    t: 1580886600,
    v: 70.53093286329488
  },
  {
    t: 1580886900,
    v: 74.17230685211243
  },
  {
    t: 1580887200,
    v: 74.88173827599583
  },
  {
    t: 1580887500,
    v: 54.39211605217142
  },
  {
    t: 1580887800,
    v: 61.42827766972907
  },
  {
    t: 1580888100,
    v: 58.4822045495604
  },
  {
    t: 1580888400,
    v: 64.3450473382704
  },
  {
    t: 1580888700,
    v: 73.04527795081756
  },
  {
    t: 1580889000,
    v: 42.457918202901446
  },
  {
    t: 1580889300,
    v: 45.004897241281874
  },
  {
    t: 1580889600,
    v: 65.77850633295547
  },
  {
    t: 1580889900,
    v: 71.57346375247569
  },
  {
    t: 1580890200,
    v: 76.84531538330883
  },
  {
    t: 1580890500,
    v: 77.55877437047877
  },
  {
    t: 1580890800,
    v: 81.82548875040857
  },
  {
    t: 1580891100,
    v: 81.82548875040857
  },
  {
    t: 1580891400,
    v: 72.787760938761
  },
  {
    t: 1580891700,
    v: 55.8637312671741
  },
  {
    t: 1580892000,
    v: 69.6439309777497
  },
  {
    t: 1580892300,
    v: 41.48355085455237
  },
  {
    t: 1580892600,
    v: 50.06322965854313
  },
  {
    t: 1580892900,
    v: 49.49129576507068
  },
  {
    t: 1580893200,
    v: 40.50168427214031
  },
  {
    t: 1580893500,
    v: 37.95273665394434
  },
  {
    t: 1580893800,
    v: 50.039624819163166
  },
  {
    t: 1580894100,
    v: 48.01717953004331
  },
  {
    t: 1580894400,
    v: 42.66772246079841
  },
  {
    t: 1580894700,
    v: 37.84872929516536
  },
  {
    t: 1580895000,
    v: 34.138046403032206
  },
  {
    t: 1580895300,
    v: 24.770857106483476
  },
  {
    t: 1580895600,
    v: 19.196837296320027
  },
  {
    t: 1580895900,
    v: 17.570172914938368
  },
  {
    t: 1580896200,
    v: 21.377139500490244
  },
  {
    t: 1580896500,
    v: 29.99841073067641
  },
  {
    t: 1580896800,
    v: 29.129261016591997
  },
  {
    t: 1580897100,
    v: 36.67754974272046
  },
  {
    t: 1580897400,
    v: 34.57335916423101
  },
  {
    t: 1580897700,
    v: 43.2546406780788
  },
  {
    t: 1580898000,
    v: 41.17342584354824
  },
  {
    t: 1580898300,
    v: 43.39668447024338
  },
  {
    t: 1580898600,
    v: 40.92214276575005
  },
  {
    t: 1580898900,
    v: 37.758486967343394
  },
  {
    t: 1580899200,
    v: 29.1681463696046
  },
  {
    t: 1580899500,
    v: 21.65932976351033
  },
  {
    t: 1580899800,
    v: 19.902671647727402
  },
  {
    t: 1580900100,
    v: 25.10892734877349
  },
  {
    t: 1580900400,
    v: 24.11376941552645
  },
  {
    t: 1580900700,
    v: 21.928580464363066
  },
  {
    t: 1580901000,
    v: 20.041647095778814
  },
  {
    t: 1580901300,
    v: 16.747658538596042
  },
  {
    t: 1580901600,
    v: 37.783457097090455
  },
  {
    t: 1580901900,
    v: 40.18256501069314
  },
  {
    t: 1580902200,
    v: 40.182565010693146
  },
  {
    t: 1580902500,
    v: 56.42352285240573
  },
  {
    t: 1580902800,
    v: 62.1388418507866
  },
  {
    t: 1580903100,
    v: 58.257770333102165
  },
  {
    t: 1580903400,
    v: 51.78854608705626
  },
  {
    t: 1580903700,
    v: 49.976424109478444
  },
  {
    t: 1580904000,
    v: 54.932539778340036
  },
  {
    t: 1580904300,
    v: 53.82444847626669
  },
  {
    t: 1580904600,
    v: 54.350638399284115
  },
  {
    t: 1580904900,
    v: 41.001293612927356
  },
  {
    t: 1580905200,
    v: 46.416112857328706
  },
  {
    t: 1580905500,
    v: 49.918163396786994
  },
  {
    t: 1580905800,
    v: 49.304381038578654
  },
  {
    t: 1580906100,
    v: 49.304381038578654
  },
  {
    t: 1580906400,
    v: 56.319392249520284
  },
  {
    t: 1580906700,
    v: 44.9466081856096
  },
  {
    t: 1580907000,
    v: 55.787696091362996
  },
  {
    t: 1580907300,
    v: 53.65672656309617
  },
  {
    t: 1580907600,
    v: 57.378023623464294
  },
  {
    t: 1580907900,
    v: 51.82896969692556
  },
  {
    t: 1580908200,
    v: 50.24236995216201
  },
  {
    t: 1580908500,
    v: 26.310137364008554
  },
  {
    t: 1580908800,
    v: 25.50898318312794
  },
  {
    t: 1580909100,
    v: 23.84906640982328
  },
  {
    t: 1580909400,
    v: 19.111470958644574
  },
  {
    t: 1580909700,
    v: 15.292476388456322
  },
  {
    t: 1580910000,
    v: 11.848589512354081
  },
  {
    t: 1580910300,
    v: 17.88679599111549
  },
  {
    t: 1580910600,
    v: 33.50353796955106
  },
  {
    t: 1580910900,
    v: 34.100355012827805
  },
  {
    t: 1580911200,
    v: 41.85387253882649
  },
  {
    t: 1580911500,
    v: 33.810417146255915
  },
  {
    t: 1580911800,
    v: 27.940544628076893
  },
  {
    t: 1580912100,
    v: 31.639700354501954
  },
  {
    t: 1580912400,
    v: 22.744014308939825
  },
  {
    t: 1580912700,
    v: 21.05283510451021
  },
  {
    t: 1580913000,
    v: 20.87543657176242
  },
  {
    t: 1580913300,
    v: 18.875890080143165
  },
  {
    t: 1580913600,
    v: 14.6483940109826
  },
  {
    t: 1580913900,
    v: 22.82252399148136
  },
  {
    t: 1580914200,
    v: 28.677306770119444
  },
  {
    t: 1580914500,
    v: 28.403688936104317
  },
  {
    t: 1580914800,
    v: 29.17597568966363
  },
  {
    t: 1580915100,
    v: 49.211319345797165
  },
  {
    t: 1580915400,
    v: 44.28923523589373
  },
  {
    t: 1580915700,
    v: 31.28511384127873
  },
  {
    t: 1580916000,
    v: 43.9750641523121
  },
  {
    t: 1580916300,
    v: 58.55416352046914
  },
  {
    t: 1580916600,
    v: 51.12134224312146
  },
  {
    t: 1580916900,
    v: 51.44154863937955
  },
  {
    t: 1580917200,
    v: 42.26496217442228
  },
  {
    t: 1580917500,
    v: 32.45424004540254
  },
  {
    t: 1580917800,
    v: 44.165988382862494
  },
  {
    t: 1580918100,
    v: 44.165988382862494
  },
  {
    t: 1580918400,
    v: 42.19845025077887
  },
  {
    t: 1580918700,
    v: 39.60587577246701
  },
  {
    t: 1580919000,
    v: 43.976602185386504
  },
  {
    t: 1580919300,
    v: 45.77069501841587
  },
  {
    t: 1580919600,
    v: 51.535380073730735
  },
  {
    t: 1580919900,
    v: 50.589832512621214
  },
  {
    t: 1580920200,
    v: 41.820624763866675
  },
  {
    t: 1580920500,
    v: 57.113319042549485
  },
  {
    t: 1580920800,
    v: 51.1390461252169
  },
  {
    t: 1580921100,
    v: 50.28034930999214
  },
  {
    t: 1580921400,
    v: 55.41402781069739
  },
  {
    t: 1580921700,
    v: 62.74167600020539
  },
  {
    t: 1580922000,
    v: 62.15792874052182
  },
  {
    t: 1580922300,
    v: 62.945929445275745
  },
  {
    t: 1580922600,
    v: 63.80725082373056
  },
  {
    t: 1580922900,
    v: 58.37915965689091
  },
  {
    t: 1580923200,
    v: 47.22801144428607
  },
  {
    t: 1580923500,
    v: 50.41186134900439
  },
  {
    t: 1580923800,
    v: 49.72612731238284
  },
  {
    t: 1580924100,
    v: 46.13971794760406
  },
  {
    t: 1580924400,
    v: 50.24116729991564
  },
  {
    t: 1580924700,
    v: 53.48001292810778
  },
  {
    t: 1580925000,
    v: 42.432214244516594
  },
  {
    t: 1580925300,
    v: 30.206789224476125
  },
  {
    t: 1580925600,
    v: 37.87757550507349
  },
  {
    t: 1580925900,
    v: 31.68635274069557
  },
  {
    t: 1580926200,
    v: 27.313049368944036
  },
  {
    t: 1580926500,
    v: 36.495253164002015
  },
  {
    t: 1580926800,
    v: 47.516537386489915
  },
  {
    t: 1580927100,
    v: 50.57972196129542
  },
  {
    t: 1580927400,
    v: 47.41689286480661
  },
  {
    t: 1580927700,
    v: 47.41689286480661
  },
  {
    t: 1580928000,
    v: 48.26202114844923
  },
  {
    t: 1580928300,
    v: 44.20241124359087
  },
  {
    t: 1580928600,
    v: 53.200355434760596
  },
  {
    t: 1580928900,
    v: 49.54774233579475
  },
  {
    t: 1580929200,
    v: 39.479824552458666
  },
  {
    t: 1580929500,
    v: 32.99616083522858
  },
  {
    t: 1580929800,
    v: 28.603688116235546
  },
  {
    t: 1580930100,
    v: 22.514301384527585
  },
  {
    t: 1580930400,
    v: 17.889690174967228
  },
  {
    t: 1580930700,
    v: 16.312850052714566
  },
  {
    t: 1580931000,
    v: 14.628721953414598
  },
  {
    t: 1580931300,
    v: 25.601386007379986
  },
  {
    t: 1580931600,
    v: 20.72946422859208
  },
  {
    t: 1580931900,
    v: 28.504097831194017
  },
  {
    t: 1580932200,
    v: 35.71022602024139
  },
  {
    t: 1580932500,
    v: 42.350806106867
  },
  {
    t: 1580932800,
    v: 53.91200380031345
  },
  {
    t: 1580933100,
    v: 55.12208708353136
  },
  {
    t: 1580933400,
    v: 55.785455125294014
  },
  {
    t: 1580933700,
    v: 59.2292154165851
  },
  {
    t: 1580934000,
    v: 62.56175406644943
  },
  {
    t: 1580934300,
    v: 63.91028680689418
  },
  {
    t: 1580934600,
    v: 63.91028680689418
  },
  {
    t: 1580934900,
    v: 63.910286806894185
  },
  {
    t: 1580935200,
    v: 57.70495581718299
  },
  {
    t: 1580935500,
    v: 53.27150060015038
  },
  {
    t: 1580935800,
    v: 41.20997471023483
  },
  {
    t: 1580936100,
    v: 40.170517312648755
  },
  {
    t: 1580936400,
    v: 62.050032588529554
  },
  {
    t: 1580936700,
    v: 62.82684091168745
  },
  {
    t: 1580937000,
    v: 50.915860780312194
  },
  {
    t: 1580937300,
    v: 55.713579753307805
  },
  {
    t: 1580937600,
    v: 50.115284801023506
  },
  {
    t: 1580937900,
    v: 48.990097154477795
  },
  {
    t: 1580938200,
    v: 46.5987063472294
  },
  {
    t: 1580938500,
    v: 35.657835944995384
  },
  {
    t: 1580938800,
    v: 35.6578359449954
  },
  {
    t: 1580939100,
    v: 46.16391637849423
  },
  {
    t: 1580939400,
    v: 60.79578425465711
  },
  {
    t: 1580939700,
    v: 59.47621096424294
  },
  {
    t: 1580940000,
    v: 60.45710139857089
  },
  {
    t: 1580940300,
    v: 48.40561417214703
  },
  {
    t: 1580940600,
    v: 53.14932894591821
  },
  {
    t: 1580940900,
    v: 53.14932894591821
  },
  {
    t: 1580941200,
    v: 51.60018670249475
  },
  {
    t: 1580941500,
    v: 53.16045097246809
  },
  {
    t: 1580941800,
    v: 61.64005624129328
  },
  {
    t: 1580942100,
    v: 65.23631316705776
  },
  {
    t: 1580942400,
    v: 65.23631316705776
  },
  {
    t: 1580942700,
    v: 66.59959944653379
  },
  {
    t: 1580943000,
    v: 68.03232887216555
  },
  {
    t: 1580943300,
    v: 61.95757279791215
  },
  {
    t: 1580943600,
    v: 67.00777046743242
  },
  {
    t: 1580943900,
    v: 63.78217305016678
  },
  {
    t: 1580944200,
    v: 54.74660629004064
  },
  {
    t: 1580944500,
    v: 51.943387174994385
  },
  {
    t: 1580944800,
    v: 44.18626946508659
  },
  {
    t: 1580945100,
    v: 41.80779718972989
  },
  {
    t: 1580945400,
    v: 61.000961774910735
  },
  {
    t: 1580945700,
    v: 61.000961774910735
  },
  {
    t: 1580946000,
    v: 61.00096177491073
  },
  {
    t: 1580946300,
    v: 57.46451297806386
  },
  {
    t: 1580946600,
    v: 64.51729707086271
  },
  {
    t: 1580946900,
    v: 71.67415948658213
  },
  {
    t: 1580947200,
    v: 43.86653285002231
  },
  {
    t: 1580947500,
    v: 40.593941389749155
  },
  {
    t: 1580947800,
    v: 26.11892455284817
  },
  {
    t: 1580948100,
    v: 46.3141103243013
  },
  {
    t: 1580948400,
    v: 52.49731354461378
  },
  {
    t: 1580948700,
    v: 58.02266801701056
  },
  {
    t: 1580949000,
    v: 56.51998979948296
  },
  {
    t: 1580949300,
    v: 57.76990989727238
  },
  {
    t: 1580949600,
    v: 38.69887796730581
  },
  {
    t: 1580949900,
    v: 44.302500589706156
  },
  {
    t: 1580950200,
    v: 29.60935097904408
  },
  {
    t: 1580950500,
    v: 26.444120203385893
  },
  {
    t: 1580950800,
    v: 49.200360079825906
  },
  {
    t: 1580951100,
    v: 52.978828720786936
  },
  {
    t: 1580951400,
    v: 58.60844575688989
  },
  {
    t: 1580951700,
    v: 46.64220576244475
  },
  {
    t: 1580952000,
    v: 38.300881610396715
  },
  {
    t: 1580952300,
    v: 35.21617892484409
  },
  {
    t: 1580952600,
    v: 35.21617892484407
  },
  {
    t: 1580952900,
    v: 29.962997061964487
  },
  {
    t: 1580953200,
    v: 29.962997061964487
  },
  {
    t: 1580953500,
    v: 27.303204737518598
  },
  {
    t: 1580953800,
    v: 46.75093462583058
  },
  {
    t: 1580954100,
    v: 56.98067700838285
  },
  {
    t: 1580954400,
    v: 52.83754072879612
  },
  {
    t: 1580954700,
    v: 51.973751817298435
  },
  {
    t: 1580955000,
    v: 56.07692387402714
  },
  {
    t: 1580955300,
    v: 50.19554163745091
  },
  {
    t: 1580955600,
    v: 51.171011040989335
  },
  {
    t: 1580955900,
    v: 52.24006855954443
  },
  {
    t: 1580956200,
    v: 54.51622339032884
  },
  {
    t: 1580956500,
    v: 57.95157391275727
  },
  {
    t: 1580956800,
    v: 51.97021657429898
  },
  {
    t: 1580957100,
    v: 44.15719234501841
  },
  {
    t: 1580957400,
    v: 39.61913179115173
  },
  {
    t: 1580957700,
    v: 53.32447710841078
  },
  {
    t: 1580958000,
    v: 57.712369041732664
  },
  {
    t: 1580958300,
    v: 63.581478949277916
  },
  {
    t: 1580958600,
    v: 63.58147894927792
  },
  {
    t: 1580958900,
    v: 55.23712695116017
  },
  {
    t: 1580959200,
    v: 52.11075059632208
  },
  {
    t: 1580959500,
    v: 46.14145112622214
  },
  {
    t: 1580959800,
    v: 34.76374820548887
  },
  {
    t: 1580960100,
    v: 34.76374820548887
  },
  {
    t: 1580960400,
    v: 52.94833621634491
  },
  {
    t: 1580960700,
    v: 51.5790368876194
  },
  {
    t: 1580961000,
    v: 48.70031449672705
  },
  {
    t: 1580961300,
    v: 44.447672824468135
  },
  {
    t: 1580961600,
    v: 50.971551271305806
  },
  {
    t: 1580961900,
    v: 43.648748174211754
  },
  {
    t: 1580962200,
    v: 48.702235091141
  },
  {
    t: 1580962500,
    v: 37.24925773295627
  },
  {
    t: 1580962800,
    v: 28.68372320783986
  },
  {
    t: 1580963100,
    v: 25.35239249966098
  },
  {
    t: 1580963400,
    v: 25.35239249966098
  },
  {
    t: 1580963700,
    v: 47.60618998056465
  },
  {
    t: 1580964000,
    v: 56.1444059364962
  },
  {
    t: 1580964300,
    v: 49.26322185266176
  },
  {
    t: 1580964600,
    v: 44.11397641566187
  },
  {
    t: 1580964900,
    v: 40.2659288074895
  },
  {
    t: 1580965200,
    v: 44.42144244074571
  },
  {
    t: 1580965500,
    v: 39.223942041602115
  },
  {
    t: 1580965800,
    v: 42.309664609007555
  },
  {
    t: 1580966100,
    v: 31.383477915476746
  },
  {
    t: 1580966400,
    v: 54.00704369408764
  },
  {
    t: 1580966700,
    v: 59.36601860564053
  },
  {
    t: 1580967000,
    v: 58.25776757566315
  },
  {
    t: 1580967300,
    v: 58.25776757566315
  },
  {
    t: 1580967600,
    v: 63.58515016959342
  },
  {
    t: 1580967900,
    v: 50.169508651288695
  },
  {
    t: 1580968200,
    v: 61.870943108741116
  },
  {
    t: 1580968500,
    v: 71.6522844953081
  },
  {
    t: 1580968800,
    v: 61.61635108038939
  },
  {
    t: 1580969100,
    v: 56.21703373919159
  },
  {
    t: 1580969400,
    v: 56.93581998242923
  },
  {
    t: 1580969700,
    v: 58.493342310553885
  },
  {
    t: 1580970000,
    v: 46.105939081959875
  },
  {
    t: 1580970300,
    v: 46.105939081959875
  },
  {
    t: 1580970600,
    v: 48.305756121786615
  },
  {
    t: 1580970900,
    v: 46.152797750234235
  },
  {
    t: 1580971200,
    v: 61.037416235393174
  },
  {
    t: 1580971500,
    v: 69.41209771810767
  },
  {
    t: 1580971800,
    v: 70.52597806674679
  },
  {
    t: 1580972100,
    v: 69.08832056435904
  },
  {
    t: 1580972400,
    v: 57.11325987722624
  },
  {
    t: 1580972700,
    v: 62.1162204052139
  },
  {
    t: 1580973000,
    v: 55.90514863511455
  },
  {
    t: 1580973300,
    v: 44.67385909876349
  },
  {
    t: 1580973600,
    v: 57.86567029538174
  },
  {
    t: 1580973900,
    v: 67.35459126693522
  },
  {
    t: 1580974200,
    v: 78.21709929219776
  },
  {
    t: 1580974500,
    v: 79.23594946055786
  },
  {
    t: 1580974800,
    v: 62.524722610139634
  },
  {
    t: 1580975100,
    v: 68.89705539359356
  },
  {
    t: 1580975400,
    v: 57.13975764813294
  },
  {
    t: 1580975700,
    v: 58.48906253612095
  },
  {
    t: 1580976000,
    v: 73.85392653835451
  },
  {
    t: 1580976300,
    v: 46.535513406902965
  },
  {
    t: 1580976600,
    v: 49.06854709098883
  },
  {
    t: 1580976900,
    v: 49.06854709098883
  },
  {
    t: 1580977200,
    v: 40.078213214811406
  },
  {
    t: 1580977500,
    v: 39.50768367642525
  },
  {
    t: 1580977800,
    v: 29.269629646909294
  },
  {
    t: 1580978100,
    v: 29.069415097480842
  },
  {
    t: 1580978400,
    v: 41.05113469213169
  },
  {
    t: 1580978700,
    v: 42.75144706246449
  },
  {
    t: 1580979000,
    v: 49.78631906941044
  },
  {
    t: 1580979300,
    v: 38.37399232416236
  },
  {
    t: 1580979600,
    v: 46.878457102586495
  },
  {
    t: 1580979900,
    v: 53.82930695963566
  },
  {
    t: 1580980200,
    v: 55.41669161067866
  },
  {
    t: 1580980500,
    v: 63.457413494869876
  },
  {
    t: 1580980800,
    v: 67.94131413508757
  },
  {
    t: 1580981100,
    v: 68.8606302075472
  },
  {
    t: 1580981400,
    v: 64.62478399504258
  },
  {
    t: 1580981700,
    v: 67.74287962869028
  },
  {
    t: 1580982000,
    v: 44.778764124763626
  },
  {
    t: 1580982300,
    v: 40.06659036964183
  },
  {
    t: 1580982600,
    v: 39.785132737300074
  },
  {
    t: 1580982900,
    v: 42.98903335216646
  },
  {
    t: 1580983200,
    v: 55.389907756537745
  },
  {
    t: 1580983500,
    v: 58.30506310496965
  },
  {
    t: 1580983800,
    v: 60.90066104843851
  },
  {
    t: 1580984100,
    v: 58.80868122131002
  },
  {
    t: 1580984400,
    v: 60.735485818799546
  },
  {
    t: 1580984700,
    v: 63.102506412102144
  },
  {
    t: 1580985000,
    v: 62.386147493339394
  },
  {
    t: 1580985300,
    v: 54.59468382351794
  },
  {
    t: 1580985600,
    v: 55.74320781622272
  },
  {
    t: 1580985900,
    v: 43.24246640478425
  },
  {
    t: 1580986200,
    v: 46.00991624582258
  },
  {
    t: 1580986500,
    v: 41.39631678466736
  },
  {
    t: 1580986800,
    v: 49.3736813293204
  },
  {
    t: 1580987100,
    v: 40.26284525112455
  },
  {
    t: 1580987400,
    v: 37.77346351050959
  },
  {
    t: 1580987700,
    v: 45.47853227630763
  },
  {
    t: 1580988000,
    v: 36.65937946457067
  },
  {
    t: 1580988300,
    v: 33.197161602177516
  },
  {
    t: 1580988600,
    v: 27.00358411437928
  },
  {
    t: 1580988900,
    v: 25.713537317328104
  },
  {
    t: 1580989200,
    v: 42.26316647337339
  },
  {
    t: 1580989500,
    v: 46.178411963419975
  },
  {
    t: 1580989800,
    v: 53.916528594483886
  },
  {
    t: 1580990100,
    v: 51.946795530377194
  },
  {
    t: 1580990400,
    v: 44.512799868640215
  },
  {
    t: 1580990700,
    v: 54.95066240380339
  },
  {
    t: 1580991000,
    v: 47.710374162877514
  },
  {
    t: 1580991300,
    v: 55.52391998806999
  },
  {
    t: 1580991600,
    v: 59.0228579771244
  },
  {
    t: 1580991900,
    v: 60.54321842238208
  },
  {
    t: 1580992200,
    v: 38.67652784648204
  },
  {
    t: 1580992500,
    v: 39.91656377722443
  },
  {
    t: 1580992800,
    v: 52.96131727401051
  },
  {
    t: 1580993100,
    v: 43.14746004287672
  },
  {
    t: 1580993400,
    v: 46.73649745245792
  },
  {
    t: 1580993700,
    v: 60.85685708221309
  },
  {
    t: 1580994000,
    v: 63.68600997451262
  },
  {
    t: 1580994300,
    v: 58.33225006728611
  },
  {
    t: 1580994600,
    v: 60.24210417580538
  },
  {
    t: 1580994900,
    v: 60.24210417580537
  },
  {
    t: 1580995200,
    v: 47.23440889260886
  },
  {
    t: 1580995500,
    v: 56.169420933989635
  },
  {
    t: 1580995800,
    v: 53.447472715635485
  },
  {
    t: 1580996100,
    v: 52.86194828488306
  },
  {
    t: 1580996400,
    v: 44.978089552351925
  },
  {
    t: 1580996700,
    v: 56.181215135492955
  },
  {
    t: 1580997000,
    v: 46.83888439157923
  },
  {
    t: 1580997300,
    v: 50.00592299611863
  },
  {
    t: 1580997600,
    v: 49.4448478740165
  },
  {
    t: 1580997900,
    v: 41.46854604771759
  },
  {
    t: 1580998200,
    v: 35.013391289664824
  },
  {
    t: 1580998500,
    v: 38.65139576279175
  },
  {
    t: 1580998800,
    v: 32.427455348014476
  },
  {
    t: 1580999100,
    v: 27.966902818467986
  },
  {
    t: 1580999400,
    v: 22.741721938914935
  },
  {
    t: 1580999700,
    v: 34.51637218734591
  },
  {
    t: 1581000000,
    v: 51.82347479359266
  },
  {
    t: 1581000300,
    v: 48.70298164037061
  },
  {
    t: 1581000600,
    v: 43.18402386697663
  },
  {
    t: 1581000900,
    v: 23.011067368760592
  },
  {
    t: 1581001200,
    v: 25.715073475576247
  },
  {
    t: 1581001500,
    v: 22.815088044654615
  },
  {
    t: 1581001800,
    v: 17.850523097302087
  },
  {
    t: 1581002100,
    v: 22.548358173040697
  },
  {
    t: 1581002400,
    v: 22.114759067371594
  },
  {
    t: 1581002700,
    v: 16.273354256789204
  },
  {
    t: 1581003000,
    v: 13.31932008248809
  },
  {
    t: 1581003300,
    v: 26.457314195249566
  },
  {
    t: 1581003600,
    v: 24.162112624103457
  },
  {
    t: 1581003900,
    v: 17.594858126809683
  },
  {
    t: 1581004200,
    v: 14.383532736410146
  },
  {
    t: 1581004500,
    v: 43.04891072484256
  },
  {
    t: 1581004800,
    v: 52.40160074165094
  },
  {
    t: 1581005100,
    v: 42.35930664433143
  },
  {
    t: 1581005400,
    v: 45.97479476441714
  },
  {
    t: 1581005700,
    v: 40.55662429348078
  },
  {
    t: 1581006000,
    v: 48.36003908775318
  },
  {
    t: 1581006300,
    v: 55.475605064820456
  },
  {
    t: 1581006600,
    v: 54.08505485955495
  },
  {
    t: 1581006900,
    v: 53.13373419849701
  },
  {
    t: 1581007200,
    v: 48.996975714153194
  },
  {
    t: 1581007500,
    v: 44.30402814059279
  },
  {
    t: 1581007800,
    v: 41.40344806401764
  },
  {
    t: 1581008100,
    v: 47.755675978126405
  },
  {
    t: 1581008400,
    v: 44.76906491846776
  },
  {
    t: 1581008700,
    v: 49.88596515549187
  },
  {
    t: 1581009000,
    v: 48.3501615429855
  },
  {
    t: 1581009300,
    v: 50.93940746513918
  },
  {
    t: 1581009600,
    v: 53.03231926339699
  },
  {
    t: 1581009900,
    v: 54.30534572506632
  },
  {
    t: 1581010200,
    v: 49.68789649299313
  },
  {
    t: 1581010500,
    v: 44.95534907811519
  },
  {
    t: 1581010800,
    v: 50.73092623973889
  },
  {
    t: 1581011100,
    v: 46.72847722475246
  },
  {
    t: 1581011400,
    v: 47.672121025181475
  },
  {
    t: 1581011700,
    v: 36.85388975943768
  },
  {
    t: 1581012000,
    v: 37.41360313457571
  },
  {
    t: 1581012300,
    v: 34.287591977778675
  },
  {
    t: 1581012600,
    v: 31.604990968575095
  },
  {
    t: 1581012900,
    v: 39.69346639103639
  },
  {
    t: 1581013200,
    v: 49.858771828838336
  },
  {
    t: 1581013500,
    v: 49.33082054831459
  },
  {
    t: 1581013800,
    v: 49.33082054831459
  },
  {
    t: 1581014100,
    v: 52.60807751995746
  },
  {
    t: 1581014400,
    v: 51.841656447409875
  },
  {
    t: 1581014700,
    v: 44.44216080157685
  },
  {
    t: 1581015000,
    v: 31.20675726488004
  },
  {
    t: 1581015300,
    v: 31.20675726488004
  },
  {
    t: 1581015600,
    v: 27.145582067923954
  },
  {
    t: 1581015900,
    v: 26.36144104990234
  },
  {
    t: 1581016200,
    v: 27.55722316665765
  },
  {
    t: 1581016500,
    v: 38.89692833077362
  },
  {
    t: 1581016800,
    v: 41.007618771802875
  },
  {
    t: 1581017100,
    v: 49.906707532925594
  },
  {
    t: 1581017400,
    v: 48.06525595119093
  },
  {
    t: 1581017700,
    v: 59.23804691147192
  },
  {
    t: 1581018000,
    v: 57.07901654931777
  },
  {
    t: 1581018300,
    v: 55.9145032815772
  },
  {
    t: 1581018600,
    v: 55.9145032815772
  },
  {
    t: 1581018900,
    v: 70.73253594492255
  },
  {
    t: 1581019200,
    v: 79.7427674535266
  },
  {
    t: 1581019500,
    v: 82.29160334030851
  },
  {
    t: 1581019800,
    v: 84.93868207037319
  },
  {
    t: 1581020100,
    v: 85.81959981338387
  },
  {
    t: 1581020400,
    v: 86.70808824509365
  },
  {
    t: 1581020700,
    v: 79.58457627186674
  },
  {
    t: 1581021000,
    v: 78.11765870742596
  },
  {
    t: 1581021300,
    v: 80.20283055145366
  },
  {
    t: 1581021600,
    v: 55.86664163663023
  },
  {
    t: 1581021900,
    v: 56.61882910433727
  },
  {
    t: 1581022200,
    v: 43.166018245911765
  },
  {
    t: 1581022500,
    v: 48.418552490816076
  },
  {
    t: 1581022800,
    v: 48.418552490816076
  },
  {
    t: 1581023100,
    v: 64.73601636621902
  },
  {
    t: 1581023400,
    v: 66.32406627198435
  },
  {
    t: 1581023700,
    v: 66.32406627198435
  },
  {
    t: 1581024000,
    v: 68.7730451580735
  },
  {
    t: 1581024300,
    v: 62.30071874315816
  },
  {
    t: 1581024600,
    v: 51.271596706508355
  },
  {
    t: 1581024900,
    v: 52.23792134219077
  },
  {
    t: 1581025200,
    v: 56.20790342659782
  },
  {
    t: 1581025500,
    v: 58.19358762057148
  },
  {
    t: 1581025800,
    v: 53.99636350540334
  },
  {
    t: 1581026100,
    v: 71.32797056475042
  },
  {
    t: 1581026400,
    v: 60.64580694668161
  },
  {
    t: 1581026700,
    v: 45.18013417032843
  },
  {
    t: 1581027000,
    v: 45.18013417032844
  },
  {
    t: 1581027300,
    v: 42.06644517537799
  },
  {
    t: 1581027600,
    v: 46.29628563164818
  },
  {
    t: 1581027900,
    v: 42.730738705176144
  },
  {
    t: 1581028200,
    v: 43.96378881663466
  },
  {
    t: 1581028500,
    v: 43.96378881663466
  },
  {
    t: 1581028800,
    v: 43.96378881663466
  },
  {
    t: 1581029100,
    v: 42.594825441644915
  },
  {
    t: 1581029400,
    v: 55.31607937634922
  },
  {
    t: 1581029700,
    v: 57.97680391722007
  },
  {
    t: 1581030000,
    v: 46.82414353553289
  },
  {
    t: 1581030300,
    v: 44.056812087716736
  },
  {
    t: 1581030600,
    v: 55.95627407010264
  },
  {
    t: 1581030900,
    v: 57.25514478558352
  },
  {
    t: 1581031200,
    v: 57.25514478558352
  },
  {
    t: 1581031500,
    v: 57.25514478558352
  },
  {
    t: 1581031800,
    v: 64.96621677159627
  },
  {
    t: 1581032100,
    v: 53.86163118221044
  },
  {
    t: 1581032400,
    v: 49.95797513563057
  },
  {
    t: 1581032700,
    v: 53.785859992601516
  },
  {
    t: 1581033000,
    v: 53.785859992601516
  },
  {
    t: 1581033300,
    v: 53.785859992601516
  },
  {
    t: 1581033600,
    v: 21.649667642313787
  },
  {
    t: 1581033900,
    v: 23.654726183919294
  },
  {
    t: 1581034200,
    v: 33.39468365988206
  },
  {
    t: 1581034500,
    v: 28.421884036330596
  },
  {
    t: 1581034800,
    v: 38.83167812520772
  },
  {
    t: 1581035100,
    v: 37.78497924239767
  },
  {
    t: 1581035400,
    v: 37.78497924239767
  },
  {
    t: 1581035700,
    v: 34.17541671812758
  },
  {
    t: 1581036000,
    v: 55.315487920304626
  },
  {
    t: 1581036300,
    v: 69.21999484902078
  },
  {
    t: 1581036600,
    v: 59.90037647397676
  },
  {
    t: 1581036900,
    v: 67.12651414986112
  },
  {
    t: 1581037200,
    v: 63.22047245211681
  },
  {
    t: 1581037500,
    v: 61.84943533785213
  },
  {
    t: 1581037800,
    v: 67.48973270677772
  },
  {
    t: 1581038100,
    v: 70.35224128663506
  },
  {
    t: 1581038400,
    v: 71.77247870895701
  },
  {
    t: 1581038700,
    v: 68.047098174247
  },
  {
    t: 1581039000,
    v: 69.83641637702874
  },
  {
    t: 1581039300,
    v: 69.83641637702874
  },
  {
    t: 1581039600,
    v: 74.49928822995066
  },
  {
    t: 1581039900,
    v: 65.27376251000302
  },
  {
    t: 1581040200,
    v: 60.960095746515144
  },
  {
    t: 1581040500,
    v: 69.12238058912865
  },
  {
    t: 1581040800,
    v: 57.37208497097206
  },
  {
    t: 1581041100,
    v: 57.37208497097206
  },
  {
    t: 1581041400,
    v: 55.32478043534387
  },
  {
    t: 1581041700,
    v: 47.56543284508332
  },
  {
    t: 1581042000,
    v: 60.29409034592478
  },
  {
    t: 1581042300,
    v: 66.16151807695925
  },
  {
    t: 1581042600,
    v: 67.26711993749726
  },
  {
    t: 1581042900,
    v: 67.26711993749726
  },
  {
    t: 1581043200,
    v: 68.60681856830331
  },
  {
    t: 1581043500,
    v: 74.55725489861945
  },
  {
    t: 1581043800,
    v: 61.282113278034934
  },
  {
    t: 1581044100,
    v: 73.25514491838743
  },
  {
    t: 1581044400,
    v: 66.81511720269941
  },
  {
    t: 1581044700,
    v: 51.34041825799771
  },
  {
    t: 1581045000,
    v: 49.87362847086007
  },
  {
    t: 1581045300,
    v: 51.45856909212749
  },
  {
    t: 1581045600,
    v: 64.3433973878434
  },
  {
    t: 1581045900,
    v: 62.44894745927257
  },
  {
    t: 1581046200,
    v: 62.448947459272574
  },
  {
    t: 1581046500,
    v: 69.48893188794864
  },
  {
    t: 1581046800,
    v: 48.643989046412734
  },
  {
    t: 1581047100,
    v: 47.29287657129022
  },
  {
    t: 1581047400,
    v: 37.71519114102642
  },
  {
    t: 1581047700,
    v: 44.17547800214788
  },
  {
    t: 1581048000,
    v: 34.076873491706436
  },
  {
    t: 1581048300,
    v: 37.35043216246438
  },
  {
    t: 1581048600,
    v: 31.916536542801495
  },
  {
    t: 1581048900,
    v: 33.75234294704897
  },
  {
    t: 1581049200,
    v: 32.7433225773603
  },
  {
    t: 1581049500,
    v: 29.69925197306368
  },
  {
    t: 1581049800,
    v: 27.734731207599367
  },
  {
    t: 1581050100,
    v: 20.12703876314623
  },
  {
    t: 1581050400,
    v: 17.884418471651543
  },
  {
    t: 1581050700,
    v: 15.428560571567658
  },
  {
    t: 1581051000,
    v: 28.831223716055916
  },
  {
    t: 1581051300,
    v: 49.97296917959818
  },
  {
    t: 1581051600,
    v: 49.97296917959818
  },
  {
    t: 1581051900,
    v: 63.223223465449486
  },
  {
    t: 1581052200,
    v: 59.095205471630706
  },
  {
    t: 1581052500,
    v: 48.28588598729555
  },
  {
    t: 1581052800,
    v: 52.68212010761897
  },
  {
    t: 1581053100,
    v: 57.8062676663777
  },
  {
    t: 1581053400,
    v: 57.8062676663777
  },
  {
    t: 1581053700,
    v: 57.8062676663777
  },
  {
    t: 1581054000,
    v: 48.414834204085174
  },
  {
    t: 1581054300,
    v: 41.927495431790824
  },
  {
    t: 1581054600,
    v: 39.5074733814685
  },
  {
    t: 1581054900,
    v: 35.950291846777816
  },
  {
    t: 1581055200,
    v: 53.4962804031517
  },
  {
    t: 1581055500,
    v: 46.83173944189277
  },
  {
    t: 1581055800,
    v: 42.042989316594834
  },
  {
    t: 1581056100,
    v: 34.90467752742104
  },
  {
    t: 1581056400,
    v: 23.72462033291545
  },
  {
    t: 1581056700,
    v: 36.10688283936347
  },
  {
    t: 1581057000,
    v: 32.73306420170837
  },
  {
    t: 1581057300,
    v: 48.2166585701683
  },
  {
    t: 1581057600,
    v: 39.95766956181824
  },
  {
    t: 1581057900,
    v: 31.987943476403586
  },
  {
    t: 1581058200,
    v: 31.475460726936404
  },
  {
    t: 1581058500,
    v: 30.909512077433746
  },
  {
    t: 1581058800,
    v: 21.9063231376536
  },
  {
    t: 1581059100,
    v: 15.289677893518757
  },
  {
    t: 1581059400,
    v: 14.894189422152166
  },
  {
    t: 1581059700,
    v: 13.145484437351513
  },
  {
    t: 1581060000,
    v: 9.681340967808623
  },
  {
    t: 1581060300,
    v: 36.692100715050174
  },
  {
    t: 1581060600,
    v: 40.29329618708883
  },
  {
    t: 1581060900,
    v: 46.130106821628544
  },
  {
    t: 1581061200,
    v: 51.05189425999831
  },
  {
    t: 1581061500,
    v: 34.59562937583624
  },
  {
    t: 1581061800,
    v: 26.683628538124992
  },
  {
    t: 1581062100,
    v: 24.192650494830147
  },
  {
    t: 1581062400,
    v: 24.192650494830147
  },
  {
    t: 1581062700,
    v: 18.343154009566277
  },
  {
    t: 1581063000,
    v: 40.21011000962873
  },
  {
    t: 1581063300,
    v: 42.554254011051746
  },
  {
    t: 1581063600,
    v: 49.36121154045703
  },
  {
    t: 1581063900,
    v: 43.76389922662999
  },
  {
    t: 1581064200,
    v: 34.33984823816266
  },
  {
    t: 1581064500,
    v: 39.69880593028264
  },
  {
    t: 1581064800,
    v: 41.78721431180346
  },
  {
    t: 1581065100,
    v: 44.00358150508683
  },
  {
    t: 1581065400,
    v: 45.88725165944773
  },
  {
    t: 1581065700,
    v: 24.107403378374087
  },
  {
    t: 1581066000,
    v: 41.36419221814313
  },
  {
    t: 1581066300,
    v: 38.427140016732885
  },
  {
    t: 1581066600,
    v: 42.18068799559073
  },
  {
    t: 1581066900,
    v: 46.238379703633036
  },
  {
    t: 1581067200,
    v: 42.32177405593655
  },
  {
    t: 1581067500,
    v: 35.26625506993878
  },
  {
    t: 1581067800,
    v: 35.66168092635061
  },
  {
    t: 1581068100,
    v: 34.4588701337056
  },
  {
    t: 1581068400,
    v: 42.832609150766295
  },
  {
    t: 1581068700,
    v: 35.69949376973433
  },
  {
    t: 1581069000,
    v: 31.340530036269882
  },
  {
    t: 1581069300,
    v: 26.644063375479618
  },
  {
    t: 1581069600,
    v: 34.993802043670584
  },
  {
    t: 1581069900,
    v: 31.778522110493824
  },
  {
    t: 1581070200,
    v: 24.304398123985067
  },
  {
    t: 1581070500,
    v: 31.490681252864576
  },
  {
    t: 1581070800,
    v: 31.952126523700045
  },
  {
    t: 1581071100,
    v: 25.018927254146575
  },
  {
    t: 1581071400,
    v: 40.896991555143146
  },
  {
    t: 1581071700,
    v: 38.96274097803906
  },
  {
    t: 1581072000,
    v: 40.56893079489551
  },
  {
    t: 1581072300,
    v: 41.01243148094075
  },
  {
    t: 1581072600,
    v: 38.088863335523044
  },
  {
    t: 1581072900,
    v: 49.51392939784668
  },
  {
    t: 1581073200,
    v: 42.06225708019898
  },
  {
    t: 1581073500,
    v: 46.03980165072448
  },
  {
    t: 1581073800,
    v: 50.76152163743707
  },
  {
    t: 1581074100,
    v: 56.32091632165349
  },
  {
    t: 1581074400,
    v: 60.34172409207109
  },
  {
    t: 1581074700,
    v: 63.484802511027716
  },
  {
    t: 1581075000,
    v: 64.89787432084427
  },
  {
    t: 1581075300,
    v: 55.1427857033033
  },
  {
    t: 1581075600,
    v: 59.881007776205294
  },
  {
    t: 1581075900,
    v: 38.66478781974994
  },
  {
    t: 1581076200,
    v: 38.66478781974994
  },
  {
    t: 1581076500,
    v: 37.96208316547374
  },
  {
    t: 1581076800,
    v: 40.436426092139314
  },
  {
    t: 1581077100,
    v: 51.4919318221189
  },
  {
    t: 1581077400,
    v: 50.95152970181369
  },
  {
    t: 1581077700,
    v: 47.005029114344545
  },
  {
    t: 1581078000,
    v: 53.47664278051797
  },
  {
    t: 1581078300,
    v: 63.264407675004314
  },
  {
    t: 1581078600,
    v: 64.87314236307535
  },
  {
    t: 1581078900,
    v: 65.3072210119279
  },
  {
    t: 1581079200,
    v: 75.38753698306112
  },
  {
    t: 1581079500,
    v: 71.3050989947408
  },
  {
    t: 1581079800,
    v: 73.28884430839653
  },
  {
    t: 1581080100,
    v: 83.08772668613852
  },
  {
    t: 1581080400,
    v: 85.32500977382655
  },
  {
    t: 1581080700,
    v: 85.9870588995636
  },
  {
    t: 1581081000,
    v: 88.85912088627856
  },
  {
    t: 1581081300,
    v: 90.31153775562014
  },
  {
    t: 1581081600,
    v: 74.38090333761684
  },
  {
    t: 1581081900,
    v: 57.69332092636857
  },
  {
    t: 1581082200,
    v: 62.10936250097863
  },
  {
    t: 1581082500,
    v: 54.71230734556044
  },
  {
    t: 1581082800,
    v: 41.19071125398043
  },
  {
    t: 1581083100,
    v: 34.33192627772196
  },
  {
    t: 1581083400,
    v: 43.90289021939255
  },
  {
    t: 1581083700,
    v: 38.05215433847478
  },
  {
    t: 1581084000,
    v: 29.74630887480835
  },
  {
    t: 1581084300,
    v: 25.178359555323226
  },
  {
    t: 1581084600,
    v: 34.51602941475936
  },
  {
    t: 1581084900,
    v: 40.10191160695892
  },
  {
    t: 1581085200,
    v: 41.44505796747517
  },
  {
    t: 1581085500,
    v: 44.29993884648716
  },
  {
    t: 1581085800,
    v: 67.16782589955795
  },
  {
    t: 1581086100,
    v: 56.11038019919171
  },
  {
    t: 1581086400,
    v: 66.4731895909209
  },
  {
    t: 1581086700,
    v: 65.95730630906273
  },
  {
    t: 1581087000,
    v: 63.42582053577199
  },
  {
    t: 1581087300,
    v: 69.01355211848933
  },
  {
    t: 1581087600,
    v: 72.96804117634855
  },
  {
    t: 1581087900,
    v: 63.41979419809368
  },
  {
    t: 1581088200,
    v: 56.081269018938094
  },
  {
    t: 1581088500,
    v: 51.0208671626163
  },
  {
    t: 1581088800,
    v: 41.95556856689114
  },
  {
    t: 1581089100,
    v: 38.73762075477437
  },
  {
    t: 1581089400,
    v: 44.17474821073436
  },
  {
    t: 1581089700,
    v: 42.452366148349704
  },
  {
    t: 1581090000,
    v: 65.73614371022119
  },
  {
    t: 1581090300,
    v: 56.524023251974604
  },
  {
    t: 1581090600,
    v: 45.23558441190193
  },
  {
    t: 1581090900,
    v: 46.33251824048138
  },
  {
    t: 1581091200,
    v: 44.62978179211923
  },
  {
    t: 1581091500,
    v: 40.854765128281116
  },
  {
    t: 1581091800,
    v: 35.141488494682406
  },
  {
    t: 1581092100,
    v: 30.075040535860367
  },
  {
    t: 1581092400,
    v: 35.01682928244932
  },
  {
    t: 1581092700,
    v: 27.015980469675142
  },
  {
    t: 1581093000,
    v: 32.614735772995914
  },
  {
    t: 1581093300,
    v: 40.55607611114677
  },
  {
    t: 1581093600,
    v: 38.60619091587817
  },
  {
    t: 1581093900,
    v: 41.03611342960104
  },
  {
    t: 1581094200,
    v: 37.28797143015676
  },
  {
    t: 1581094500,
    v: 35.09021746942281
  },
  {
    t: 1581094800,
    v: 32.53351582975961
  },
  {
    t: 1581095100,
    v: 31.05580102618515
  },
  {
    t: 1581095400,
    v: 29.163454975335
  },
  {
    t: 1581095700,
    v: 35.863060904919664
  },
  {
    t: 1581096000,
    v: 38.093541948023784
  },
  {
    t: 1581096300,
    v: 34.77630194765132
  },
  {
    t: 1581096600,
    v: 39.77134678655017
  },
  {
    t: 1581096900,
    v: 35.155884493625294
  },
  {
    t: 1581097200,
    v: 33.667462802017184
  },
  {
    t: 1581097500,
    v: 32.71761760451001
  },
  {
    t: 1581097800,
    v: 28.70673227427781
  },
  {
    t: 1581098100,
    v: 24.042687732740006
  },
  {
    t: 1581098400,
    v: 36.49044336813458
  },
  {
    t: 1581098700,
    v: 46.060962742847686
  },
  {
    t: 1581099000,
    v: 40.61991083397889
  },
  {
    t: 1581099300,
    v: 38.39825632153731
  },
  {
    t: 1581099600,
    v: 40.86259562764123
  },
  {
    t: 1581099900,
    v: 51.413878917210354
  },
  {
    t: 1581100200,
    v: 56.5439597312298
  },
  {
    t: 1581100500,
    v: 42.54293207857343
  },
  {
    t: 1581100800,
    v: 45.13080519177382
  },
  {
    t: 1581101100,
    v: 38.74826314674032
  },
  {
    t: 1581101400,
    v: 34.87321390526755
  },
  {
    t: 1581101700,
    v: 42.09318075174204
  },
  {
    t: 1581102000,
    v: 56.0226986948838
  },
  {
    t: 1581102300,
    v: 51.75429056646483
  },
  {
    t: 1581102600,
    v: 53.11512523631154
  },
  {
    t: 1581102900,
    v: 58.47012253766018
  },
  {
    t: 1581103200,
    v: 59.78254318974534
  },
  {
    t: 1581103500,
    v: 62.90831786146304
  },
  {
    t: 1581103800,
    v: 58.45760821121884
  },
  {
    t: 1581104100,
    v: 58.45760821121884
  },
  {
    t: 1581104400,
    v: 41.74259768431006
  },
  {
    t: 1581104700,
    v: 50.98356065983747
  },
  {
    t: 1581105000,
    v: 51.56887792351546
  },
  {
    t: 1581105300,
    v: 48.274813208888204
  },
  {
    t: 1581105600,
    v: 42.124451635612466
  },
  {
    t: 1581105900,
    v: 49.48029982460865
  },
  {
    t: 1581106200,
    v: 50.203614712464535
  },
  {
    t: 1581106500,
    v: 46.406874267981706
  },
  {
    t: 1581106800,
    v: 42.714989231308394
  },
  {
    t: 1581107100,
    v: 36.142806030471135
  },
  {
    t: 1581107400,
    v: 52.13458383537359
  },
  {
    t: 1581107700,
    v: 58.93841672789794
  },
  {
    t: 1581108000,
    v: 59.53600195407812
  },
  {
    t: 1581108300,
    v: 49.63031905667544
  },
  {
    t: 1581108600,
    v: 54.00344524072621
  },
  {
    t: 1581108900,
    v: 56.17755451994678
  },
  {
    t: 1581109200,
    v: 57.70086435882202
  },
  {
    t: 1581109500,
    v: 51.55635708778322
  },
  {
    t: 1581109800,
    v: 54.335105053312674
  },
  {
    t: 1581110100,
    v: 44.5912152418038
  },
  {
    t: 1581110400,
    v: 42.008355992138576
  },
  {
    t: 1581110700,
    v: 44.45946258271946
  },
  {
    t: 1581111000,
    v: 47.01870425855627
  },
  {
    t: 1581111300,
    v: 43.57649900429374
  },
  {
    t: 1581111600,
    v: 33.34642518966142
  },
  {
    t: 1581286200,
    v: 27.902010695220312
  },
  {
    t: 1581286500,
    v: 35.43195089210393
  },
  {
    t: 1581286800,
    v: 28.603661149420788
  },
  {
    t: 1581287100,
    v: 30.142252895752605
  },
  {
    t: 1581287400,
    v: 26.837415490385894
  },
  {
    t: 1581287700,
    v: 28.62610808802239
  },
  {
    t: 1581288000,
    v: 32.403506401939524
  },
  {
    t: 1581288300,
    v: 32.403506401939524
  },
  {
    t: 1581288600,
    v: 31.320970009499703
  },
  {
    t: 1581288900,
    v: 38.379130381055106
  },
  {
    t: 1581289200,
    v: 33.18265178798309
  },
  {
    t: 1581289500,
    v: 58.72835019950819
  },
  {
    t: 1581289800,
    v: 59.8254632640773
  },
  {
    t: 1581290100,
    v: 58.06153855530544
  },
  {
    t: 1581290400,
    v: 66.96647504659568
  },
  {
    t: 1581290700,
    v: 66.96647504659568
  },
  {
    t: 1581291000,
    v: 76.67124188550602
  },
  {
    t: 1581291300,
    v: 80.02465082464664
  },
  {
    t: 1581291600,
    v: 87.533817622338
  },
  {
    t: 1581291900,
    v: 82.68944048608225
  },
  {
    t: 1581292200,
    v: 84.91561147187974
  },
  {
    t: 1581292500,
    v: 72.70359954026142
  },
  {
    t: 1581292800,
    v: 75.6991359633679
  },
  {
    t: 1581293100,
    v: 69.85813970661367
  },
  {
    t: 1581293400,
    v: 75.30285024830974
  },
  {
    t: 1581293700,
    v: 75.30285024830974
  },
  {
    t: 1581294000,
    v: 76.93536276818571
  },
  {
    t: 1581294300,
    v: 79.51461867934798
  },
  {
    t: 1581294600,
    v: 70.50402025097281
  },
  {
    t: 1581294900,
    v: 75.0311697292897
  },
  {
    t: 1581295200,
    v: 75.0311697292897
  },
  {
    t: 1581295500,
    v: 77.5974564858307
  },
  {
    t: 1581295800,
    v: 78.84019727052092
  },
  {
    t: 1581296100,
    v: 82.68276668352253
  },
  {
    t: 1581296400,
    v: 82.68276668352253
  },
  {
    t: 1581296700,
    v: 65.0495915230732
  },
  {
    t: 1581297000,
    v: 66.08291431772184
  },
  {
    t: 1581297300,
    v: 66.08291431772184
  },
  {
    t: 1581297600,
    v: 55.38849482041634
  },
  {
    t: 1581297900,
    v: 58.461549307050696
  },
  {
    t: 1581298200,
    v: 61.49302916567116
  },
  {
    t: 1581298500,
    v: 50.88315202244843
  },
  {
    t: 1581298800,
    v: 41.146846065541304
  },
  {
    t: 1581299100,
    v: 30.155934854460256
  },
  {
    t: 1581299400,
    v: 34.17486841107046
  },
  {
    t: 1581299700,
    v: 43.468836870640175
  },
  {
    t: 1581300000,
    v: 55.06907166794816
  },
  {
    t: 1581300300,
    v: 70.01295369105627
  },
  {
    t: 1581300600,
    v: 58.284142730116564
  },
  {
    t: 1581300900,
    v: 49.80773558374509
  },
  {
    t: 1581301200,
    v: 58.44187901576954
  },
  {
    t: 1581301500,
    v: 55.187016934597345
  },
  {
    t: 1581301800,
    v: 47.179112743575836
  },
  {
    t: 1581302100,
    v: 51.223515487425544
  },
  {
    t: 1581302400,
    v: 46.172968027315925
  },
  {
    t: 1581302700,
    v: 49.581755998596975
  },
  {
    t: 1581303000,
    v: 49.581755998596975
  },
  {
    t: 1581303300,
    v: 68.06958868624889
  },
  {
    t: 1581303600,
    v: 70.96653115421432
  },
  {
    t: 1581303900,
    v: 56.82548089517067
  },
  {
    t: 1581304200,
    v: 47.76153919378601
  },
  {
    t: 1581304500,
    v: 45.28501972347583
  },
  {
    t: 1581304800,
    v: 44.40782971986964
  },
  {
    t: 1581305100,
    v: 48.930095631733785
  },
  {
    t: 1581305400,
    v: 36.281314133317196
  },
  {
    t: 1581305700,
    v: 38.69616841182831
  },
  {
    t: 1581306000,
    v: 52.16367580658258
  },
  {
    t: 1581306300,
    v: 53.07003107702138
  },
  {
    t: 1581306600,
    v: 56.810854540397834
  },
  {
    t: 1581306900,
    v: 56.81085454039784
  },
  {
    t: 1581307200,
    v: 57.906465194092114
  },
  {
    t: 1581307500,
    v: 49.32617002084031
  },
  {
    t: 1581307800,
    v: 56.664697906429
  },
  {
    t: 1581308100,
    v: 61.917184669558154
  },
  {
    t: 1581308400,
    v: 60.2480623488569
  },
  {
    t: 1581308700,
    v: 60.24806234885689
  },
  {
    t: 1581309000,
    v: 71.34593587865947
  },
  {
    t: 1581309300,
    v: 71.34593587865947
  },
  {
    t: 1581309600,
    v: 75.4199734479209
  },
  {
    t: 1581309900,
    v: 78.24763379234837
  },
  {
    t: 1581310200,
    v: 78.24763379234837
  },
  {
    t: 1581310500,
    v: 87.57912416406131
  },
  {
    t: 1581310800,
    v: 79.7584670198757
  },
  {
    t: 1581311100,
    v: 80.26205633844607
  },
  {
    t: 1581311400,
    v: 82.27766405589978
  },
  {
    t: 1581311700,
    v: 70.01995903610586
  },
  {
    t: 1581312000,
    v: 57.06520241404195
  },
  {
    t: 1581312300,
    v: 60.21948785096839
  },
  {
    t: 1581312600,
    v: 52.82703589138858
  },
  {
    t: 1581312900,
    v: 52.82703589138858
  },
  {
    t: 1581313200,
    v: 40.99543838392645
  },
  {
    t: 1581313500,
    v: 47.022280722982224
  },
  {
    t: 1581313800,
    v: 56.008946682756466
  },
  {
    t: 1581314100,
    v: 57.19444085292266
  },
  {
    t: 1581314400,
    v: 55.485578328097155
  },
  {
    t: 1581314700,
    v: 40.33523357249881
  },
  {
    t: 1581315000,
    v: 36.224629875907304
  },
  {
    t: 1581315300,
    v: 41.34801450647541
  },
  {
    t: 1581315600,
    v: 44.73089821043791
  },
  {
    t: 1581315900,
    v: 44.73089821043791
  },
  {
    t: 1581316200,
    v: 51.967771602450505
  },
  {
    t: 1581316500,
    v: 43.778720320080055
  },
  {
    t: 1581316800,
    v: 60.74530127182324
  },
  {
    t: 1581317100,
    v: 51.81070276465979
  },
  {
    t: 1581317400,
    v: 33.20526222698709
  },
  {
    t: 1581317700,
    v: 24.61756916002635
  },
  {
    t: 1581318000,
    v: 17.806516109146955
  },
  {
    t: 1581318300,
    v: 22.274989179439515
  },
  {
    t: 1581318600,
    v: 20.97196400449728
  },
  {
    t: 1581318900,
    v: 49.607784445289084
  },
  {
    t: 1581319200,
    v: 53.56663971876504
  },
  {
    t: 1581319500,
    v: 50.33837405393675
  },
  {
    t: 1581319800,
    v: 53.53845151167817
  },
  {
    t: 1581320100,
    v: 49.19133428486987
  },
  {
    t: 1581320400,
    v: 35.87511840498118
  },
  {
    t: 1581320700,
    v: 27.39867402096897
  },
  {
    t: 1581321000,
    v: 25.666023711306963
  },
  {
    t: 1581321300,
    v: 24.208043063914502
  },
  {
    t: 1581321600,
    v: 38.56610083766074
  },
  {
    t: 1581321900,
    v: 30.496314203404154
  },
  {
    t: 1581322200,
    v: 29.847436840573806
  },
  {
    t: 1581322500,
    v: 29.847436840573806
  },
  {
    t: 1581322800,
    v: 39.1485101785625
  },
  {
    t: 1581323100,
    v: 42.32630986755369
  },
  {
    t: 1581323400,
    v: 39.942443641235414
  },
  {
    t: 1581323700,
    v: 60.773049971979596
  },
  {
    t: 1581324000,
    v: 64.97966316847693
  },
  {
    t: 1581324300,
    v: 56.648433020883374
  },
  {
    t: 1581324600,
    v: 67.69391629521161
  },
  {
    t: 1581324900,
    v: 57.020211921005654
  },
  {
    t: 1581325200,
    v: 48.313978975507005
  },
  {
    t: 1581325500,
    v: 52.461678182388496
  },
  {
    t: 1581325800,
    v: 49.91828639862751
  },
  {
    t: 1581326100,
    v: 55.359712056016185
  },
  {
    t: 1581326400,
    v: 46.89058885065924
  },
  {
    t: 1581326700,
    v: 50.158420254246955
  },
  {
    t: 1581327000,
    v: 47.38183669193394
  },
  {
    t: 1581327300,
    v: 55.29999915155353
  },
  {
    t: 1581327600,
    v: 47.18506136354559
  },
  {
    t: 1581327900,
    v: 34.71090522339037
  },
  {
    t: 1581328200,
    v: 34.02555500193145
  },
  {
    t: 1581328500,
    v: 52.70277622632628
  },
  {
    t: 1581328800,
    v: 59.126043836480854
  },
  {
    t: 1581329100,
    v: 65.43711864985949
  },
  {
    t: 1581329400,
    v: 60.13167275146469
  },
  {
    t: 1581329700,
    v: 61.89697521277814
  },
  {
    t: 1581330000,
    v: 63.379484752964416
  },
  {
    t: 1581330300,
    v: 54.84401758282203
  },
  {
    t: 1581330600,
    v: 50.410452518573884
  },
  {
    t: 1581330900,
    v: 46.147019643646345
  },
  {
    t: 1581331200,
    v: 49.78707168970099
  },
  {
    t: 1581331500,
    v: 44.10824835846788
  },
  {
    t: 1581331800,
    v: 47.528271884795956
  },
  {
    t: 1581332100,
    v: 41.69647184798228
  },
  {
    t: 1581332400,
    v: 26.71271825627376
  },
  {
    t: 1581332700,
    v: 38.67570069088932
  },
  {
    t: 1581333000,
    v: 51.52620059530105
  },
  {
    t: 1581333300,
    v: 48.514020604926664
  },
  {
    t: 1581333600,
    v: 55.33482682136736
  },
  {
    t: 1581333900,
    v: 50.55127170404969
  },
  {
    t: 1581334200,
    v: 58.70994638994492
  },
  {
    t: 1581334500,
    v: 49.00699586942681
  },
  {
    t: 1581334800,
    v: 56.11226395242401
  },
  {
    t: 1581335100,
    v: 59.34897080447953
  },
  {
    t: 1581335400,
    v: 47.37094071225283
  },
  {
    t: 1581335700,
    v: 44.34059684850182
  },
  {
    t: 1581336000,
    v: 41.67464782150445
  },
  {
    t: 1581336300,
    v: 42.24161672789429
  },
  {
    t: 1581336600,
    v: 50.491777508859
  },
  {
    t: 1581336900,
    v: 45.53609107619357
  },
  {
    t: 1581337200,
    v: 54.685355658372956
  },
  {
    t: 1581337500,
    v: 55.63180756929834
  },
  {
    t: 1581337800,
    v: 44.48285893570664
  },
  {
    t: 1581338100,
    v: 54.01250462225736
  },
  {
    t: 1581338400,
    v: 54.9429287434904
  },
  {
    t: 1581338700,
    v: 57.865670804549836
  },
  {
    t: 1581339000,
    v: 46.399645721184065
  },
  {
    t: 1581339300,
    v: 45.37216577880499
  },
  {
    t: 1581339600,
    v: 51.50821469487209
  },
  {
    t: 1581339900,
    v: 57.02496149506606
  },
  {
    t: 1581340200,
    v: 55.57967863576377
  },
  {
    t: 1581340500,
    v: 46.773405586144285
  },
  {
    t: 1581340800,
    v: 47.50462174459683
  },
  {
    t: 1581341100,
    v: 63.13291650838179
  },
  {
    t: 1581341400,
    v: 46.66698002632165
  },
  {
    t: 1581341700,
    v: 43.00455343898611
  },
  {
    t: 1581342000,
    v: 36.463604346783775
  },
  {
    t: 1581342300,
    v: 25.808726709219542
  },
  {
    t: 1581342600,
    v: 15.80694093370758
  },
  {
    t: 1581342900,
    v: 12.812096781194555
  },
  {
    t: 1581343200,
    v: 15.227338327711365
  },
  {
    t: 1581343500,
    v: 12.274094312322319
  },
  {
    t: 1581343800,
    v: 11.72688155772768
  },
  {
    t: 1581344100,
    v: 17.99437119502815
  },
  {
    t: 1581344400,
    v: 20.67630943288698
  },
  {
    t: 1581344700,
    v: 18.22415928193395
  },
  {
    t: 1581345000,
    v: 25.001041774925056
  },
  {
    t: 1581345300,
    v: 23.172173563308633
  },
  {
    t: 1581345600,
    v: 30.17697983404115
  },
  {
    t: 1581345900,
    v: 28.68260964323818
  },
  {
    t: 1581346200,
    v: 26.67010094446927
  },
  {
    t: 1581346500,
    v: 24.46322797277257
  },
  {
    t: 1581346800,
    v: 22.349663003058353
  },
  {
    t: 1581347100,
    v: 39.58344463586405
  },
  {
    t: 1581347400,
    v: 54.961668464909856
  },
  {
    t: 1581347700,
    v: 54.50862554139168
  },
  {
    t: 1581348000,
    v: 61.72077226266769
  },
  {
    t: 1581348300,
    v: 70.32386742520048
  },
  {
    t: 1581348600,
    v: 53.23269150410511
  },
  {
    t: 1581348900,
    v: 48.825227431821986
  },
  {
    t: 1581349200,
    v: 41.05553218929494
  },
  {
    t: 1581349500,
    v: 33.35597299875907
  },
  {
    t: 1581349800,
    v: 26.074010020541337
  },
  {
    t: 1581350100,
    v: 32.354019815786344
  },
  {
    t: 1581350400,
    v: 30.50313411362731
  },
  {
    t: 1581350700,
    v: 25.501323192513112
  },
  {
    t: 1581351000,
    v: 25.032213922768392
  },
  {
    t: 1581351300,
    v: 19.235786571347077
  },
  {
    t: 1581351600,
    v: 17.709878907567244
  },
  {
    t: 1581351900,
    v: 17.1544493316987
  },
  {
    t: 1581352200,
    v: 15.193902611595021
  },
  {
    t: 1581352500,
    v: 14.559986095495518
  },
  {
    t: 1581352800,
    v: 17.825203240540702
  },
  {
    t: 1581353100,
    v: 30.56271819345902
  },
  {
    t: 1581353400,
    v: 34.95246575134655
  },
  {
    t: 1581353700,
    v: 38.82018543441354
  },
  {
    t: 1581354000,
    v: 51.89572842988326
  },
  {
    t: 1581354300,
    v: 43.86081058278145
  },
  {
    t: 1581354600,
    v: 46.56240787198421
  },
  {
    t: 1581354900,
    v: 48.90421050041801
  },
  {
    t: 1581355200,
    v: 40.404981062618226
  },
  {
    t: 1581355500,
    v: 42.57742514736033
  },
  {
    t: 1581355800,
    v: 49.88489865236747
  },
  {
    t: 1581356100,
    v: 49.88489865236747
  },
  {
    t: 1581356400,
    v: 34.097077229645265
  },
  {
    t: 1581356700,
    v: 43.970172688084396
  },
  {
    t: 1581357000,
    v: 35.62375601588984
  },
  {
    t: 1581357300,
    v: 30.972136728992126
  },
  {
    t: 1581357600,
    v: 37.687959436708596
  },
  {
    t: 1581357900,
    v: 45.18884171503411
  },
  {
    t: 1581358200,
    v: 49.92499775953098
  },
  {
    t: 1581358500,
    v: 48.61565403112796
  },
  {
    t: 1581358800,
    v: 53.28311004336
  },
  {
    t: 1581359100,
    v: 54.233322519551905
  },
  {
    t: 1581359400,
    v: 51.25471952244928
  },
  {
    t: 1581359700,
    v: 55.703376907274674
  },
  {
    t: 1581360000,
    v: 54.98648397903612
  },
  {
    t: 1581360300,
    v: 50.52742576541962
  },
  {
    t: 1581360600,
    v: 42.07801400958131
  },
  {
    t: 1581360900,
    v: 43.73239792754787
  },
  {
    t: 1581361200,
    v: 39.831760700240466
  },
  {
    t: 1581361500,
    v: 37.9000842007411
  },
  {
    t: 1581361800,
    v: 49.926291675703624
  },
  {
    t: 1581362100,
    v: 57.82466261253687
  },
  {
    t: 1581362400,
    v: 63.245648822643005
  },
  {
    t: 1581362700,
    v: 55.145032202506684
  },
  {
    t: 1581363000,
    v: 57.88493011208569
  },
  {
    t: 1581363300,
    v: 55.00501725043267
  },
  {
    t: 1581363600,
    v: 46.98955823391923
  },
  {
    t: 1581363900,
    v: 49.77756942090979
  },
  {
    t: 1581364200,
    v: 47.85976490274644
  },
  {
    t: 1581364500,
    v: 42.27542511289115
  },
  {
    t: 1581364800,
    v: 42.27542511289115
  },
  {
    t: 1581365100,
    v: 42.275425112891156
  },
  {
    t: 1581365400,
    v: 33.51847898819804
  },
  {
    t: 1581365700,
    v: 32.659420744824956
  },
  {
    t: 1581366000,
    v: 25.260449649977915
  },
  {
    t: 1581366300,
    v: 15.334605992526406
  },
  {
    t: 1581366600,
    v: 18.270805137755232
  },
  {
    t: 1581366900,
    v: 22.857067786889303
  },
  {
    t: 1581367200,
    v: 19.1690322885471
  },
  {
    t: 1581367500,
    v: 49.2963834352487
  },
  {
    t: 1581367800,
    v: 55.790143336300574
  },
  {
    t: 1581368100,
    v: 51.98560190823885
  },
  {
    t: 1581368400,
    v: 59.01315254437555
  },
  {
    t: 1581368700,
    v: 51.223765917393614
  },
  {
    t: 1581369000,
    v: 44.50949234142234
  },
  {
    t: 1581369300,
    v: 44.50949234142234
  },
  {
    t: 1581369600,
    v: 40.99984379285793
  },
  {
    t: 1581369900,
    v: 37.610525636410536
  },
  {
    t: 1581370200,
    v: 41.99462425057538
  },
  {
    t: 1581370500,
    v: 43.13628268762876
  },
  {
    t: 1581370800,
    v: 40.409417086067855
  },
  {
    t: 1581371100,
    v: 40.409417086067855
  },
  {
    t: 1581371400,
    v: 39.327048910429504
  },
  {
    t: 1581371700,
    v: 41.12917227660494
  },
  {
    t: 1581372000,
    v: 32.345355610961406
  },
  {
    t: 1581372300,
    v: 50.47657985467054
  },
  {
    t: 1581372600,
    v: 57.053456295575
  },
  {
    t: 1581372900,
    v: 46.4732268190874
  },
  {
    t: 1581373200,
    v: 50.005133600619445
  },
  {
    t: 1581373500,
    v: 47.6115364768181
  },
  {
    t: 1581373800,
    v: 47.6115364768181
  },
  {
    t: 1581374100,
    v: 43.52934176852861
  },
  {
    t: 1581374400,
    v: 64.43753703323252
  },
  {
    t: 1581374700,
    v: 61.54400121362356
  },
  {
    t: 1581375000,
    v: 61.54400121362356
  },
  {
    t: 1581375300,
    v: 41.889285566784224
  },
  {
    t: 1581375600,
    v: 56.70396825323567
  },
  {
    t: 1581375900,
    v: 64.32615280357606
  },
  {
    t: 1581376200,
    v: 59.94071892664059
  },
  {
    t: 1581376500,
    v: 51.85952600510032
  },
  {
    t: 1581376800,
    v: 40.845866687635926
  },
  {
    t: 1581377100,
    v: 34.30396500959074
  },
  {
    t: 1581377400,
    v: 35.379258692963475
  },
  {
    t: 1581377700,
    v: 32.916332937246395
  },
  {
    t: 1581378000,
    v: 32.916332937246395
  },
  {
    t: 1581378300,
    v: 50.80770240202211
  },
  {
    t: 1581378600,
    v: 48.94295944434154
  },
  {
    t: 1581378900,
    v: 46.045861181893606
  },
  {
    t: 1581379200,
    v: 40.558352199665904
  },
  {
    t: 1581379500,
    v: 41.87772624529282
  },
  {
    t: 1581379800,
    v: 31.49243577502446
  },
  {
    t: 1581380100,
    v: 29.5591446111885
  },
  {
    t: 1581380400,
    v: 42.91031535383216
  },
  {
    t: 1581380700,
    v: 37.97479080591428
  },
  {
    t: 1581381000,
    v: 50.8867519245771
  },
  {
    t: 1581381300,
    v: 54.49544184010312
  },
  {
    t: 1581381600,
    v: 59.586005710981496
  },
  {
    t: 1581381900,
    v: 57.150432424080336
  },
  {
    t: 1581382200,
    v: 48.152762207530415
  },
  {
    t: 1581382500,
    v: 51.429956979527866
  },
  {
    t: 1581382800,
    v: 50.220668783851764
  },
  {
    t: 1581383100,
    v: 56.11688082257647
  },
  {
    t: 1581383400,
    v: 71.53167865625522
  },
  {
    t: 1581383700,
    v: 79.08748816324189
  },
  {
    t: 1581384000,
    v: 70.74264951073934
  },
  {
    t: 1581384300,
    v: 73.48342091084214
  },
  {
    t: 1581384600,
    v: 65.32416719854962
  },
  {
    t: 1581384900,
    v: 67.6686085142521
  },
  {
    t: 1581385200,
    v: 65.15151731145511
  },
  {
    t: 1581385500,
    v: 70.21611537804733
  },
  {
    t: 1581385800,
    v: 73.51531864635623
  },
  {
    t: 1581386100,
    v: 60.70663279457789
  },
  {
    t: 1581386400,
    v: 61.47377371337041
  },
  {
    t: 1581386700,
    v: 49.35918859979266
  },
  {
    t: 1581387000,
    v: 45.62273779954362
  },
  {
    t: 1581387300,
    v: 50.92932437209682
  },
  {
    t: 1581387600,
    v: 60.597353249484044
  },
  {
    t: 1581387900,
    v: 66.72716907163777
  },
  {
    t: 1581388200,
    v: 67.37173224053458
  },
  {
    t: 1581388500,
    v: 70.02615520009417
  },
  {
    t: 1581388800,
    v: 54.75386713796267
  },
  {
    t: 1581389100,
    v: 41.74703761172684
  },
  {
    t: 1581389400,
    v: 38.282629089191026
  },
  {
    t: 1581389700,
    v: 53.331348670287944
  },
  {
    t: 1581390000,
    v: 49.291395973041126
  },
  {
    t: 1581390300,
    v: 56.77558547923983
  },
  {
    t: 1581390600,
    v: 62.47258550383322
  },
  {
    t: 1581390900,
    v: 65.89717231991574
  },
  {
    t: 1581391200,
    v: 67.58736355636952
  },
  {
    t: 1581391500,
    v: 68.18799381245984
  },
  {
    t: 1581391800,
    v: 60.50033933886402
  },
  {
    t: 1581392100,
    v: 45.02870801989811
  },
  {
    t: 1581392400,
    v: 39.287599656487764
  },
  {
    t: 1581392700,
    v: 36.008301819622744
  },
  {
    t: 1581393000,
    v: 32.87249050716039
  },
  {
    t: 1581393300,
    v: 44.016238877607286
  },
  {
    t: 1581393600,
    v: 36.99701091693686
  },
  {
    t: 1581393900,
    v: 48.3031364961796
  },
  {
    t: 1581394200,
    v: 45.947302803288856
  },
  {
    t: 1581394500,
    v: 42.04168199240945
  },
  {
    t: 1581394800,
    v: 43.14629653437644
  },
  {
    t: 1581395100,
    v: 31.995691875827248
  },
  {
    t: 1581395400,
    v: 33.22828402171196
  },
  {
    t: 1581395700,
    v: 38.33751928058509
  },
  {
    t: 1581396000,
    v: 31.459711235171923
  },
  {
    t: 1581396300,
    v: 28.534493714241094
  },
  {
    t: 1581396600,
    v: 23.952816639176717
  },
  {
    t: 1581396900,
    v: 23.01432173649758
  },
  {
    t: 1581397200,
    v: 17.228390723383114
  },
  {
    t: 1581397500,
    v: 23.118931425864915
  },
  {
    t: 1581397800,
    v: 27.53902195398436
  },
  {
    t: 1581398100,
    v: 52.50473605280806
  },
  {
    t: 1581398400,
    v: 54.013700358912416
  },
  {
    t: 1581398700,
    v: 63.8563830163097
  },
  {
    t: 1581399000,
    v: 57.313885074622405
  },
  {
    t: 1581399300,
    v: 45.81761571853211
  },
  {
    t: 1581399600,
    v: 49.66394902179933
  },
  {
    t: 1581399900,
    v: 49.66394902179933
  },
  {
    t: 1581400200,
    v: 44.69141206145887
  },
  {
    t: 1581400500,
    v: 59.40214114065333
  },
  {
    t: 1581400800,
    v: 46.12062482801333
  },
  {
    t: 1581401100,
    v: 38.02384557364881
  },
  {
    t: 1581401400,
    v: 40.41483261893548
  },
  {
    t: 1581401700,
    v: 45.973006519952015
  },
  {
    t: 1581402000,
    v: 42.12383692082509
  },
  {
    t: 1581402300,
    v: 37.357617856652915
  },
  {
    t: 1581402600,
    v: 46.07417246107539
  },
  {
    t: 1581402900,
    v: 48.52970908085947
  },
  {
    t: 1581403200,
    v: 51.07575190160601
  },
  {
    t: 1581403500,
    v: 51.07575190160602
  },
  {
    t: 1581403800,
    v: 54.04486679754858
  },
  {
    t: 1581404100,
    v: 58.80666016026364
  },
  {
    t: 1581404400,
    v: 29.835170056388037
  },
  {
    t: 1581404700,
    v: 30.78566208126624
  },
  {
    t: 1581405000,
    v: 29.86105732291611
  },
  {
    t: 1581405300,
    v: 26.256175507303567
  },
  {
    t: 1581405600,
    v: 28.71493667411862
  },
  {
    t: 1581405900,
    v: 54.641029936152066
  },
  {
    t: 1581406200,
    v: 60.63976401411655
  },
  {
    t: 1581406500,
    v: 65.8076954746693
  },
  {
    t: 1581406800,
    v: 65.8076954746693
  },
  {
    t: 1581407100,
    v: 70.41936109664121
  },
  {
    t: 1581407400,
    v: 72.53603803398961
  },
  {
    t: 1581407700,
    v: 77.65303879248421
  },
  {
    t: 1581408000,
    v: 56.2605902312302
  },
  {
    t: 1581408300,
    v: 47.32131960903701
  },
  {
    t: 1581408600,
    v: 37.36884922897984
  },
  {
    t: 1581408900,
    v: 38.04573366985033
  },
  {
    t: 1581409200,
    v: 33.86316025394757
  },
  {
    t: 1581409500,
    v: 45.50308993200483
  },
  {
    t: 1581409800,
    v: 50.74776291364564
  },
  {
    t: 1581410100,
    v: 40.780452038127606
  },
  {
    t: 1581410400,
    v: 51.63647995090179
  },
  {
    t: 1581410700,
    v: 52.13785186417212
  },
  {
    t: 1581411000,
    v: 57.20774931195034
  },
  {
    t: 1581411300,
    v: 55.85538415213378
  },
  {
    t: 1581411600,
    v: 55.85538415213378
  },
  {
    t: 1581411900,
    v: 51.852844868314044
  },
  {
    t: 1581412200,
    v: 72.28244684516088
  },
  {
    t: 1581412500,
    v: 59.9979183696061
  },
  {
    t: 1581412800,
    v: 61.188198139000185
  },
  {
    t: 1581413100,
    v: 58.534163654455476
  },
  {
    t: 1581413400,
    v: 65.03407121794831
  },
  {
    t: 1581413700,
    v: 65.44674455503812
  },
  {
    t: 1581414000,
    v: 43.269122684081864
  },
  {
    t: 1581414300,
    v: 52.47226246008664
  },
  {
    t: 1581414600,
    v: 59.57293635365816
  },
  {
    t: 1581414900,
    v: 56.875405748695364
  },
  {
    t: 1581415200,
    v: 47.52887476752945
  },
  {
    t: 1581415500,
    v: 56.54849764826869
  },
  {
    t: 1581415800,
    v: 51.710971238231636
  },
  {
    t: 1581416100,
    v: 55.616321804544356
  },
  {
    t: 1581416400,
    v: 50.910744136260185
  },
  {
    t: 1581416700,
    v: 64.60195588287033
  },
  {
    t: 1581417000,
    v: 60.831683226558475
  },
  {
    t: 1581417300,
    v: 64.23912995551075
  },
  {
    t: 1581417600,
    v: 67.76456219330052
  },
  {
    t: 1581417900,
    v: 58.739952880726314
  },
  {
    t: 1581418200,
    v: 65.98740731925551
  },
  {
    t: 1581418500,
    v: 64.14777398032939
  },
  {
    t: 1581418800,
    v: 67.89806364685172
  },
  {
    t: 1581419100,
    v: 69.55257056115002
  },
  {
    t: 1581419400,
    v: 59.1078725640439
  },
  {
    t: 1581419700,
    v: 50.44958090118274
  },
  {
    t: 1581420000,
    v: 45.38440830361971
  },
  {
    t: 1581420300,
    v: 47.78112201377796
  },
  {
    t: 1581420600,
    v: 50.861629227099854
  },
  {
    t: 1581420900,
    v: 42.78490515832012
  },
  {
    t: 1581421200,
    v: 39.22551508525129
  },
  {
    t: 1581421500,
    v: 35.819858707042
  },
  {
    t: 1581421800,
    v: 48.86662881522792
  },
  {
    t: 1581422100,
    v: 35.55746942691327
  },
  {
    t: 1581422400,
    v: 40.653340821618876
  },
  {
    t: 1581422700,
    v: 32.56108831392528
  },
  {
    t: 1581423000,
    v: 46.413815840202744
  },
  {
    t: 1581423300,
    v: 48.81692265759284
  },
  {
    t: 1581423600,
    v: 46.43692961820583
  },
  {
    t: 1581423900,
    v: 47.604679225872154
  },
  {
    t: 1581424200,
    v: 51.24872205231514
  },
  {
    t: 1581424500,
    v: 53.70203826243735
  },
  {
    t: 1581424800,
    v: 50.100318099534945
  },
  {
    t: 1581425100,
    v: 46.5335312624685
  },
  {
    t: 1581425400,
    v: 37.40367148207093
  },
  {
    t: 1581425700,
    v: 24.799378324921634
  },
  {
    t: 1581426000,
    v: 14.377018020929299
  },
  {
    t: 1581426300,
    v: 24.06489827716223
  },
  {
    t: 1581426600,
    v: 19.120082485757308
  },
  {
    t: 1581426900,
    v: 31.02931244520542
  },
  {
    t: 1581427200,
    v: 30.21575474857991
  },
  {
    t: 1581427500,
    v: 21.399063705990073
  },
  {
    t: 1581427800,
    v: 17.741648118727383
  },
  {
    t: 1581428100,
    v: 42.969910858732796
  },
  {
    t: 1581428400,
    v: 38.11221039301141
  },
  {
    t: 1581428700,
    v: 35.4919186129635
  },
  {
    t: 1581429000,
    v: 47.16733797939658
  },
  {
    t: 1581429300,
    v: 50.57514108350717
  },
  {
    t: 1581429600,
    v: 52.89031128045165
  },
  {
    t: 1581429900,
    v: 59.78029629088127
  },
  {
    t: 1581430200,
    v: 61.96563551238914
  },
  {
    t: 1581430500,
    v: 69.67038703541921
  },
  {
    t: 1581430800,
    v: 73.84219988228331
  },
  {
    t: 1581431100,
    v: 64.37534405849092
  },
  {
    t: 1581431400,
    v: 67.03977935668263
  },
  {
    t: 1581431700,
    v: 71.0595810267302
  },
  {
    t: 1581432000,
    v: 67.78892859690417
  },
  {
    t: 1581432300,
    v: 65.12450268080774
  },
  {
    t: 1581432600,
    v: 71.52087184001714
  },
  {
    t: 1581432900,
    v: 72.34885247136336
  },
  {
    t: 1581433200,
    v: 73.57793278699603
  },
  {
    t: 1581433500,
    v: 79.05096030104175
  },
  {
    t: 1581433800,
    v: 67.50886992119841
  },
  {
    t: 1581434100,
    v: 64.21199789599243
  },
  {
    t: 1581434400,
    v: 54.352859623976975
  },
  {
    t: 1581434700,
    v: 54.352859623976975
  },
  {
    t: 1581435000,
    v: 48.71647715641612
  },
  {
    t: 1581435300,
    v: 40.68102004094296
  },
  {
    t: 1581435600,
    v: 38.6414523146823
  },
  {
    t: 1581435900,
    v: 44.54268078034387
  },
  {
    t: 1581436200,
    v: 35.29233379473901
  },
  {
    t: 1581436500,
    v: 50.75157377714044
  },
  {
    t: 1581436800,
    v: 54.39596513298593
  },
  {
    t: 1581437100,
    v: 52.189106743809035
  },
  {
    t: 1581437400,
    v: 59.13629266829345
  },
  {
    t: 1581437700,
    v: 60.90682368353798
  },
  {
    t: 1581438000,
    v: 57.1346145753789
  },
  {
    t: 1581438300,
    v: 57.51054967898492
  },
  {
    t: 1581438600,
    v: 51.33604977799017
  },
  {
    t: 1581438900,
    v: 53.24820545589591
  },
  {
    t: 1581439200,
    v: 37.32498051789679
  },
  {
    t: 1581439500,
    v: 43.88569780311686
  },
  {
    t: 1581439800,
    v: 49.46614631173035
  },
  {
    t: 1581440100,
    v: 49.00203690331975
  },
  {
    t: 1581440400,
    v: 63.205391481411674
  },
  {
    t: 1581440700,
    v: 71.12081520974715
  },
  {
    t: 1581441000,
    v: 58.962591125107515
  },
  {
    t: 1581441300,
    v: 54.84159599270672
  },
  {
    t: 1581441600,
    v: 48.97382962954282
  },
  {
    t: 1581441900,
    v: 61.521030268496354
  },
  {
    t: 1581442200,
    v: 61.09182119381541
  },
  {
    t: 1581442500,
    v: 56.16574159202892
  },
  {
    t: 1581442800,
    v: 61.34684800752484
  },
  {
    t: 1581443100,
    v: 63.50373445248411
  },
  {
    t: 1581443400,
    v: 53.7162334060069
  },
  {
    t: 1581443700,
    v: 55.6684044814419
  },
  {
    t: 1581444000,
    v: 44.51123328269201
  },
  {
    t: 1581444300,
    v: 46.852323804479205
  },
  {
    t: 1581444600,
    v: 44.69713762514534
  },
  {
    t: 1581444900,
    v: 49.47792358859454
  },
  {
    t: 1581445200,
    v: 48.41497841026159
  },
  {
    t: 1581445500,
    v: 38.491835102741966
  },
  {
    t: 1581445800,
    v: 44.103236604888515
  },
  {
    t: 1581446100,
    v: 49.906465340615455
  },
  {
    t: 1581446400,
    v: 49.906465340615455
  },
  {
    t: 1581446700,
    v: 47.33876781875517
  },
  {
    t: 1581447000,
    v: 41.807600932374825
  },
  {
    t: 1581447300,
    v: 47.28279670645174
  },
  {
    t: 1581447600,
    v: 54.903016169164545
  },
  {
    t: 1581447900,
    v: 60.79139531576318
  },
  {
    t: 1581448200,
    v: 65.4337186600559
  },
  {
    t: 1581448500,
    v: 56.14772677821344
  },
  {
    t: 1581448800,
    v: 62.267486614633995
  },
  {
    t: 1581449100,
    v: 66.62292889683573
  },
  {
    t: 1581449400,
    v: 47.73243478725796
  },
  {
    t: 1581449700,
    v: 43.0791714534936
  },
  {
    t: 1581450000,
    v: 51.48693071160469
  },
  {
    t: 1581450300,
    v: 54.245457391212106
  },
  {
    t: 1581450600,
    v: 55.4046613122465
  },
  {
    t: 1581450900,
    v: 57.26090156406108
  },
  {
    t: 1581451200,
    v: 52.28633901001823
  },
  {
    t: 1581451500,
    v: 45.508755464305864
  },
  {
    t: 1581451800,
    v: 44.77180101077913
  },
  {
    t: 1581452100,
    v: 45.77534050610212
  },
  {
    t: 1581452400,
    v: 50.876031176501286
  },
  {
    t: 1581452700,
    v: 49.80518122702112
  },
  {
    t: 1581453000,
    v: 40.94150883185944
  },
  {
    t: 1581453300,
    v: 40.0367202683165
  },
  {
    t: 1581453600,
    v: 30.14050587854804
  },
  {
    t: 1581453900,
    v: 53.420463838827445
  },
  {
    t: 1581454200,
    v: 42.23175487834856
  },
  {
    t: 1581454500,
    v: 45.49351808800915
  },
  {
    t: 1581454800,
    v: 41.47865174106411
  },
  {
    t: 1581455100,
    v: 37.67845085357369
  },
  {
    t: 1581455400,
    v: 39.78025165051172
  },
  {
    t: 1581455700,
    v: 37.60607642584592
  },
  {
    t: 1581456000,
    v: 34.71490599510162
  },
  {
    t: 1581456300,
    v: 42.31720846877595
  },
  {
    t: 1581456600,
    v: 34.017437804325695
  },
  {
    t: 1581456900,
    v: 46.099335355737935
  },
  {
    t: 1581457200,
    v: 39.36018858574785
  },
  {
    t: 1581457500,
    v: 44.510576124006924
  },
  {
    t: 1581457800,
    v: 37.890320000129506
  },
  {
    t: 1581458100,
    v: 25.411670681381693
  },
  {
    t: 1581458400,
    v: 37.22535871180038
  },
  {
    t: 1581458700,
    v: 36.21679525880237
  },
  {
    t: 1581459000,
    v: 51.297126153755784
  },
  {
    t: 1581459300,
    v: 54.949049823537244
  },
  {
    t: 1581459600,
    v: 54.949049823537244
  },
  {
    t: 1581459900,
    v: 40.92177022378925
  },
  {
    t: 1581460200,
    v: 40.92177022378925
  },
  {
    t: 1581460500,
    v: 43.60784330723764
  },
  {
    t: 1581460800,
    v: 58.11666875325572
  },
  {
    t: 1581461100,
    v: 58.72351436565491
  },
  {
    t: 1581461400,
    v: 63.551820269589555
  },
  {
    t: 1581461700,
    v: 59.57000032214601
  },
  {
    t: 1581462000,
    v: 55.589494226462925
  },
  {
    t: 1581462300,
    v: 59.45941978348029
  },
  {
    t: 1581462600,
    v: 54.074250643333926
  },
  {
    t: 1581462900,
    v: 54.074250643333926
  },
  {
    t: 1581463200,
    v: 49.39933611756751
  },
  {
    t: 1581463500,
    v: 52.890310166155615
  },
  {
    t: 1581463800,
    v: 52.89031016615561
  },
  {
    t: 1581464100,
    v: 57.94329796567058
  },
  {
    t: 1581464400,
    v: 60.372146880827415
  },
  {
    t: 1581464700,
    v: 60.372146880827415
  },
  {
    t: 1581465000,
    v: 60.372146880827415
  },
  {
    t: 1581465300,
    v: 62.009658281703196
  },
  {
    t: 1581465600,
    v: 23.79802611289925
  },
  {
    t: 1581465900,
    v: 21.977149049858227
  },
  {
    t: 1581466200,
    v: 29.66513062391769
  },
  {
    t: 1581466500,
    v: 27.213490596100527
  },
  {
    t: 1581466800,
    v: 26.585734332497452
  },
  {
    t: 1581467100,
    v: 19.79987850592171
  },
  {
    t: 1581467400,
    v: 30.687091630908498
  },
  {
    t: 1581467700,
    v: 28.77391190596863
  },
  {
    t: 1581468000,
    v: 27.469107895811476
  },
  {
    t: 1581468300,
    v: 17.78901506577509
  },
  {
    t: 1581468600,
    v: 17.78901506577509
  },
  {
    t: 1581468900,
    v: 32.56858618335825
  },
  {
    t: 1581469200,
    v: 61.6909656426444
  },
  {
    t: 1581469500,
    v: 54.43001819858116
  },
  {
    t: 1581469800,
    v: 57.30174922222874
  },
  {
    t: 1581470100,
    v: 58.4973673454615
  },
  {
    t: 1581470400,
    v: 56.6833968414049
  },
  {
    t: 1581470700,
    v: 48.88703389508532
  },
  {
    t: 1581471000,
    v: 61.62252323229715
  },
  {
    t: 1581471300,
    v: 64.29790982048459
  },
  {
    t: 1581471600,
    v: 66.9324458849897
  },
  {
    t: 1581471900,
    v: 72.10747667587911
  },
  {
    t: 1581472200,
    v: 65.69812162608005
  },
  {
    t: 1581472500,
    v: 61.531067247063255
  },
  {
    t: 1581472800,
    v: 53.740017752797144
  },
  {
    t: 1581473100,
    v: 42.88088283268932
  },
  {
    t: 1581473400,
    v: 37.337786654395146
  },
  {
    t: 1581473700,
    v: 51.0089089038439
  },
  {
    t: 1581474000,
    v: 50.27157541928063
  },
  {
    t: 1581474300,
    v: 54.06574349551345
  },
  {
    t: 1581474600,
    v: 65.04005057342313
  },
  {
    t: 1581474900,
    v: 61.319409192114705
  },
  {
    t: 1581475200,
    v: 52.70508153125995
  },
  {
    t: 1581475500,
    v: 45.41380943902614
  },
  {
    t: 1581475800,
    v: 39.21393138493064
  },
  {
    t: 1581476100,
    v: 41.05333237268259
  },
  {
    t: 1581476400,
    v: 43.96037109150118
  },
  {
    t: 1581476700,
    v: 40.18550393267936
  },
  {
    t: 1581477000,
    v: 42.44481843354063
  },
  {
    t: 1581477300,
    v: 47.01898968426803
  },
  {
    t: 1581477600,
    v: 56.82315916274728
  },
  {
    t: 1581477900,
    v: 49.49577898950086
  },
  {
    t: 1581478200,
    v: 58.99249211182468
  },
  {
    t: 1581478500,
    v: 52.803003339272536
  },
  {
    t: 1581478800,
    v: 48.00605786919925
  },
  {
    t: 1581479100,
    v: 50.079248370785955
  },
  {
    t: 1581479400,
    v: 44.056317738437045
  },
  {
    t: 1581479700,
    v: 35.844900268797815
  },
  {
    t: 1581480000,
    v: 35.09728964640583
  },
  {
    t: 1581480300,
    v: 36.60831696537804
  },
  {
    t: 1581480600,
    v: 38.25128588530464
  },
  {
    t: 1581480900,
    v: 32.47905552610462
  },
  {
    t: 1581481200,
    v: 28.397806361042626
  },
  {
    t: 1581481500,
    v: 26.145010994462083
  },
  {
    t: 1581481800,
    v: 17.27161972802932
  },
  {
    t: 1581482100,
    v: 33.86906291101337
  },
  {
    t: 1581482400,
    v: 30.672354322624287
  },
  {
    t: 1581482700,
    v: 28.235770045977333
  },
  {
    t: 1581483000,
    v: 32.81073158723966
  },
  {
    t: 1581483300,
    v: 29.257982833297604
  },
  {
    t: 1581483600,
    v: 32.59451823433844
  },
  {
    t: 1581483900,
    v: 31.739106994419586
  },
  {
    t: 1581484200,
    v: 47.48886090873241
  },
  {
    t: 1581484500,
    v: 40.089094063131455
  },
  {
    t: 1581484800,
    v: 57.42069672402489
  },
  {
    t: 1581485100,
    v: 43.153446674822895
  },
  {
    t: 1581485400,
    v: 51.72175781083173
  },
  {
    t: 1581485700,
    v: 46.87677729673468
  },
  {
    t: 1581486000,
    v: 39.18580994325042
  },
  {
    t: 1581486300,
    v: 32.53143904516158
  },
  {
    t: 1581486600,
    v: 30.097605738363598
  },
  {
    t: 1581486900,
    v: 36.60240000862713
  },
  {
    t: 1581487200,
    v: 29.747821819763303
  },
  {
    t: 1581487500,
    v: 28.799544462921162
  },
  {
    t: 1581487800,
    v: 23.992202982634012
  },
  {
    t: 1581488100,
    v: 32.220585434843954
  },
  {
    t: 1581488400,
    v: 30.09305170693979
  },
  {
    t: 1581488700,
    v: 29.535834866372056
  },
  {
    t: 1581489000,
    v: 38.62710566488805
  },
  {
    t: 1581489300,
    v: 35.62538835911391
  },
  {
    t: 1581489600,
    v: 32.71951658198756
  },
  {
    t: 1581489900,
    v: 26.535437350656736
  },
  {
    t: 1581490200,
    v: 21.821848927753692
  },
  {
    t: 1581490500,
    v: 19.452427015677003
  },
  {
    t: 1581490800,
    v: 18.315995337552877
  },
  {
    t: 1581491100,
    v: 11.441043798046351
  },
  {
    t: 1581491400,
    v: 12.826057241147964
  },
  {
    t: 1581491700,
    v: 44.544323130383574
  },
  {
    t: 1581492000,
    v: 39.421620063593636
  },
  {
    t: 1581492300,
    v: 42.44730976073228
  },
  {
    t: 1581492600,
    v: 71.00128345199727
  },
  {
    t: 1581492900,
    v: 71.4701755110882
  },
  {
    t: 1581493200,
    v: 72.73000318431562
  },
  {
    t: 1581493500,
    v: 64.29381025837098
  },
  {
    t: 1581493800,
    v: 57.284146532070814
  },
  {
    t: 1581494100,
    v: 70.25569529606244
  },
  {
    t: 1581494400,
    v: 62.05180569076079
  },
  {
    t: 1581494700,
    v: 69.64691919650683
  },
  {
    t: 1581495000,
    v: 70.7615676882242
  },
  {
    t: 1581495300,
    v: 71.2443051026349
  },
  {
    t: 1581495600,
    v: 75.8089194530878
  },
  {
    t: 1581495900,
    v: 80.1337517813723
  },
  {
    t: 1581496200,
    v: 82.89974384383342
  },
  {
    t: 1581496500,
    v: 85.35304450396677
  },
  {
    t: 1581496800,
    v: 59.84235772322851
  },
  {
    t: 1581497100,
    v: 63.001835254834795
  },
  {
    t: 1581497400,
    v: 64.23858094974342
  },
  {
    t: 1581497700,
    v: 63.27172485846009
  },
  {
    t: 1581498000,
    v: 56.47203193211748
  },
  {
    t: 1581498300,
    v: 57.94771511161475
  },
  {
    t: 1581498600,
    v: 61.319724366982186
  },
  {
    t: 1581498900,
    v: 49.684009512202266
  },
  {
    t: 1581499200,
    v: 42.13553636543856
  },
  {
    t: 1581499500,
    v: 37.971454117528324
  },
  {
    t: 1581499800,
    v: 28.56242855040432
  },
  {
    t: 1581500100,
    v: 37.64401956432105
  },
  {
    t: 1581500400,
    v: 47.0470444484461
  },
  {
    t: 1581500700,
    v: 52.61494405747773
  },
  {
    t: 1581501000,
    v: 52.991541949621094
  },
  {
    t: 1581501300,
    v: 60.521730290658326
  },
  {
    t: 1581501600,
    v: 54.360986261649906
  },
  {
    t: 1581501900,
    v: 54.76578345843675
  },
  {
    t: 1581502200,
    v: 60.38732471425001
  },
  {
    t: 1581502500,
    v: 59.18643149532489
  },
  {
    t: 1581502800,
    v: 60.96094708234909
  },
  {
    t: 1581503100,
    v: 54.22488825671244
  },
  {
    t: 1581503400,
    v: 42.435354548376246
  },
  {
    t: 1581503700,
    v: 42.435354548376246
  },
  {
    t: 1581504000,
    v: 46.5731586459786
  },
  {
    t: 1581504300,
    v: 39.081846433132895
  },
  {
    t: 1581504600,
    v: 45.52005823067533
  },
  {
    t: 1581504900,
    v: 49.580019490527356
  },
  {
    t: 1581505200,
    v: 31.624193145754575
  },
  {
    t: 1581505500,
    v: 34.987728550386436
  },
  {
    t: 1581505800,
    v: 43.82972027712025
  },
  {
    t: 1581506100,
    v: 39.848025282405715
  },
  {
    t: 1581506400,
    v: 40.53401459194027
  },
  {
    t: 1581506700,
    v: 40.01251347987176
  },
  {
    t: 1581507000,
    v: 34.011382591280636
  },
  {
    t: 1581507300,
    v: 27.366145017787005
  },
  {
    t: 1581507600,
    v: 26.000102244781687
  },
  {
    t: 1581507900,
    v: 22.200585939306293
  },
  {
    t: 1581508200,
    v: 41.06571605441673
  },
  {
    t: 1581508500,
    v: 36.6498146119948
  },
  {
    t: 1581508800,
    v: 36.64981461199479
  },
  {
    t: 1581509100,
    v: 39.21116470440063
  },
  {
    t: 1581509400,
    v: 53.52714506396501
  },
  {
    t: 1581509700,
    v: 46.62518453090582
  },
  {
    t: 1581510000,
    v: 44.25376770914362
  },
  {
    t: 1581510300,
    v: 38.63884904477856
  },
  {
    t: 1581510600,
    v: 33.32353514280419
  },
  {
    t: 1581510900,
    v: 43.084942230191075
  },
  {
    t: 1581511200,
    v: 39.75884055160311
  },
  {
    t: 1581511500,
    v: 41.47986106651088
  },
  {
    t: 1581511800,
    v: 48.97651261154763
  },
  {
    t: 1581512100,
    v: 48.192557159360554
  },
  {
    t: 1581512400,
    v: 50.88784833997342
  },
  {
    t: 1581512700,
    v: 48.03199372627773
  },
  {
    t: 1581513000,
    v: 59.334243620954894
  },
  {
    t: 1581513300,
    v: 41.766151937140236
  },
  {
    t: 1581513600,
    v: 52.084154600350416
  },
  {
    t: 1581513900,
    v: 40.39795218495783
  },
  {
    t: 1581514200,
    v: 53.04136906576788
  },
  {
    t: 1581514500,
    v: 52.436020313567404
  },
  {
    t: 1581514800,
    v: 47.481544654376165
  },
  {
    t: 1581515100,
    v: 49.52546296777876
  },
  {
    t: 1581515400,
    v: 39.09286848718649
  },
  {
    t: 1581515700,
    v: 22.69895041686587
  },
  {
    t: 1581516000,
    v: 17.67817481454948
  },
  {
    t: 1581516300,
    v: 35.90573942745894
  },
  {
    t: 1581516600,
    v: 38.499517848434486
  },
  {
    t: 1581516900,
    v: 36.2633115036792
  },
  {
    t: 1581517200,
    v: 47.573776656202284
  },
  {
    t: 1581517500,
    v: 56.12893011052411
  },
  {
    t: 1581517800,
    v: 45.0409456449613
  },
  {
    t: 1581518100,
    v: 38.91724714330574
  },
  {
    t: 1581518400,
    v: 38.656098107957746
  },
  {
    t: 1581518700,
    v: 33.739837978331536
  },
  {
    t: 1581519000,
    v: 38.44846281324697
  },
  {
    t: 1581519300,
    v: 39.432258507869385
  },
  {
    t: 1581519600,
    v: 21.11314392726041
  },
  {
    t: 1581519900,
    v: 19.890342027502342
  },
  {
    t: 1581520200,
    v: 20.842966998490596
  },
  {
    t: 1581520500,
    v: 15.17249958541818
  },
  {
    t: 1581520800,
    v: 18.84283238145528
  },
  {
    t: 1581521100,
    v: 23.119723372264517
  },
  {
    t: 1581521400,
    v: 41.514573576564445
  },
  {
    t: 1581521700,
    v: 33.998106863072366
  },
  {
    t: 1581522000,
    v: 42.458090810851225
  },
  {
    t: 1581522300,
    v: 45.13703175032631
  },
  {
    t: 1581522600,
    v: 31.660490505484887
  },
  {
    t: 1581522900,
    v: 49.04684338452791
  },
  {
    t: 1581523200,
    v: 53.204273503417525
  },
  {
    t: 1581523500,
    v: 45.49145747518378
  },
  {
    t: 1581523800,
    v: 46.160449728638554
  },
  {
    t: 1581524100,
    v: 50.97407524298801
  },
  {
    t: 1581524400,
    v: 53.89066748866265
  },
  {
    t: 1581524700,
    v: 49.01962311413883
  },
  {
    t: 1581525000,
    v: 50.36880833862795
  },
  {
    t: 1581525300,
    v: 41.77663795125148
  },
  {
    t: 1581525600,
    v: 43.08209660088444
  },
  {
    t: 1581525900,
    v: 39.30516849987437
  },
  {
    t: 1581526200,
    v: 43.10542928451998
  },
  {
    t: 1581526500,
    v: 46.904777361857064
  },
  {
    t: 1581526800,
    v: 39.38941762412587
  },
  {
    t: 1581527100,
    v: 33.92301345805666
  },
  {
    t: 1581527400,
    v: 21.813843705918444
  },
  {
    t: 1581527700,
    v: 23.83158773882397
  },
  {
    t: 1581528000,
    v: 31.86927587488111
  },
  {
    t: 1581528300,
    v: 28.287253625251722
  },
  {
    t: 1581528600,
    v: 26.20356656329305
  },
  {
    t: 1581528900,
    v: 25.70420429459766
  },
  {
    t: 1581529200,
    v: 22.880377143102436
  },
  {
    t: 1581529500,
    v: 33.23522362306292
  },
  {
    t: 1581529800,
    v: 37.78164752823949
  },
  {
    t: 1581530100,
    v: 34.55462562649171
  },
  {
    t: 1581530400,
    v: 33.73145835566227
  },
  {
    t: 1581530700,
    v: 32.54988144145632
  },
  {
    t: 1581531000,
    v: 27.349232094677888
  },
  {
    t: 1581531300,
    v: 41.42308510461852
  },
  {
    t: 1581531600,
    v: 50.97453206738443
  },
  {
    t: 1581531900,
    v: 43.58991531928632
  },
  {
    t: 1581532200,
    v: 41.20234257130452
  },
  {
    t: 1581532500,
    v: 39.43890788350416
  },
  {
    t: 1581532800,
    v: 40.02562016484696
  },
  {
    t: 1581533100,
    v: 36.7688062497488
  },
  {
    t: 1581533400,
    v: 40.241953765108306
  },
  {
    t: 1581533700,
    v: 35.35876449317449
  },
  {
    t: 1581534000,
    v: 42.59589955393749
  },
  {
    t: 1581534300,
    v: 41.021266738535246
  },
  {
    t: 1581534600,
    v: 35.09117569523052
  },
  {
    t: 1581534900,
    v: 21.305027324998207
  },
  {
    t: 1581535200,
    v: 16.198480349106376
  },
  {
    t: 1581535500,
    v: 16.198480349106376
  },
  {
    t: 1581535800,
    v: 16.98600921702746
  },
  {
    t: 1581536100,
    v: 28.504064242466143
  },
  {
    t: 1581536400,
    v: 27.62787624216429
  },
  {
    t: 1581536700,
    v: 25.26136781890196
  },
  {
    t: 1581537000,
    v: 24.36686160060553
  },
  {
    t: 1581537300,
    v: 19.821379160756408
  },
  {
    t: 1581537600,
    v: 37.77064048312584
  },
  {
    t: 1581537900,
    v: 39.12496069554785
  },
  {
    t: 1581538200,
    v: 45.85815340203668
  },
  {
    t: 1581538500,
    v: 51.93406108831219
  },
  {
    t: 1581538800,
    v: 52.5427184886537
  },
  {
    t: 1581539100,
    v: 51.79316968771548
  },
  {
    t: 1581539400,
    v: 55.42666385081997
  },
  {
    t: 1581539700,
    v: 61.9772653658274
  },
  {
    t: 1581540000,
    v: 62.60533476945775
  },
  {
    t: 1581540300,
    v: 67.51183089012187
  },
  {
    t: 1581540600,
    v: 71.74825163939394
  },
  {
    t: 1581540900,
    v: 76.55286347421836
  },
  {
    t: 1581541200,
    v: 72.6992898356678
  },
  {
    t: 1581541500,
    v: 66.3385331491232
  },
  {
    t: 1581541800,
    v: 60.30812902704435
  },
  {
    t: 1581542100,
    v: 59.08055674285815
  },
  {
    t: 1581542400,
    v: 63.344156142322504
  },
  {
    t: 1581542700,
    v: 55.42425543548206
  },
  {
    t: 1581543000,
    v: 46.55460487052164
  },
  {
    t: 1581543300,
    v: 41.77891847354908
  },
  {
    t: 1581543600,
    v: 47.888338984381036
  },
  {
    t: 1581543900,
    v: 37.89125450950847
  },
  {
    t: 1581544200,
    v: 37.08687120842849
  },
  {
    t: 1581544500,
    v: 51.27307666563753
  },
  {
    t: 1581544800,
    v: 57.64076515009063
  },
  {
    t: 1581545100,
    v: 53.108351509696845
  },
  {
    t: 1581545400,
    v: 54.13868602814458
  },
  {
    t: 1581545700,
    v: 47.04971865845676
  },
  {
    t: 1581546000,
    v: 53.94212826755088
  },
  {
    t: 1581546300,
    v: 59.02211988562452
  },
  {
    t: 1581546600,
    v: 49.116180882144846
  },
  {
    t: 1581546900,
    v: 49.116180882144846
  },
  {
    t: 1581547200,
    v: 36.96229977744854
  },
  {
    t: 1581547500,
    v: 36.96229977744855
  },
  {
    t: 1581547800,
    v: 33.36748508745784
  },
  {
    t: 1581548100,
    v: 38.49471909836071
  },
  {
    t: 1581548400,
    v: 37.39844005041129
  },
  {
    t: 1581548700,
    v: 41.22438803566839
  },
  {
    t: 1581549000,
    v: 41.22438803566839
  },
  {
    t: 1581549300,
    v: 35.54900423996193
  },
  {
    t: 1581549600,
    v: 46.14147826180668
  },
  {
    t: 1581549900,
    v: 46.14147826180669
  },
  {
    t: 1581550200,
    v: 29.613621529209865
  },
  {
    t: 1581550500,
    v: 35.689087039215565
  },
  {
    t: 1581550800,
    v: 31.54058450035913
  },
  {
    t: 1581551100,
    v: 48.60846127208508
  },
  {
    t: 1581551400,
    v: 70.4785137417494
  },
  {
    t: 1581551700,
    v: 76.9469168666603
  },
  {
    t: 1581552000,
    v: 56.97352124004297
  },
  {
    t: 1581552300,
    v: 64.90566138091037
  },
  {
    t: 1581552600,
    v: 47.42105733141904
  },
  {
    t: 1581552900,
    v: 45.906780665034155
  },
  {
    t: 1581553200,
    v: 46.55690480487533
  },
  {
    t: 1581553500,
    v: 55.68686954940204
  },
  {
    t: 1581553800,
    v: 58.394555879280915
  },
  {
    t: 1581554100,
    v: 58.394555879280915
  },
  {
    t: 1581554400,
    v: 47.72886578848755
  },
  {
    t: 1581554700,
    v: 46.34679522640287
  },
  {
    t: 1581555000,
    v: 45.59239217974835
  },
  {
    t: 1581555300,
    v: 46.58603542731123
  },
  {
    t: 1581555600,
    v: 54.2287186432257
  },
  {
    t: 1581555900,
    v: 56.87332039796586
  },
  {
    t: 1581556200,
    v: 61.14904395181604
  },
  {
    t: 1581556500,
    v: 51.764602870266046
  },
  {
    t: 1581556800,
    v: 54.741360336728796
  },
  {
    t: 1581557100,
    v: 42.69606652436163
  },
  {
    t: 1581557400,
    v: 35.921132856974324
  },
  {
    t: 1581557700,
    v: 37.186794376828715
  },
  {
    t: 1581558000,
    v: 42.38869885769295
  },
  {
    t: 1581558300,
    v: 52.50331066626385
  },
  {
    t: 1581558600,
    v: 53.539116697509264
  },
  {
    t: 1581558900,
    v: 52.23718907134876
  },
  {
    t: 1581559200,
    v: 30.82239279702347
  },
  {
    t: 1581559500,
    v: 39.8416719881554
  },
  {
    t: 1581559800,
    v: 47.64306968186708
  },
  {
    t: 1581560100,
    v: 58.42583575257968
  },
  {
    t: 1581560400,
    v: 50.01697201275609
  },
  {
    t: 1581560700,
    v: 49.20758472791818
  },
  {
    t: 1581561000,
    v: 50.1298828337339
  },
  {
    t: 1581561300,
    v: 49.110726615455775
  },
  {
    t: 1581561600,
    v: 41.41299682618532
  },
  {
    t: 1581561900,
    v: 46.22909773245904
  },
  {
    t: 1581562200,
    v: 48.64160000255437
  },
  {
    t: 1581562500,
    v: 30.59852156341951
  },
  {
    t: 1581562800,
    v: 23.971081801350053
  },
  {
    t: 1581563100,
    v: 25.20536965165053
  },
  {
    t: 1581563400,
    v: 30.372732218875555
  },
  {
    t: 1581563700,
    v: 42.793972100848286
  },
  {
    t: 1581564000,
    v: 39.16446951556804
  },
  {
    t: 1581564300,
    v: 40.32139429098037
  },
  {
    t: 1581564600,
    v: 43.9743778039231
  },
  {
    t: 1581564900,
    v: 50.85086815209606
  },
  {
    t: 1581565200,
    v: 46.50221767994508
  },
  {
    t: 1581565500,
    v: 45.39309925098213
  },
  {
    t: 1581565800,
    v: 35.670095103893814
  },
  {
    t: 1581566100,
    v: 49.31757972160319
  },
  {
    t: 1581566400,
    v: 48.25399463876855
  },
  {
    t: 1581566700,
    v: 48.25399463876855
  },
  {
    t: 1581567000,
    v: 42.29691900424618
  },
  {
    t: 1581567300,
    v: 33.73069252391116
  },
  {
    t: 1581567600,
    v: 39.9119696653217
  },
  {
    t: 1581567900,
    v: 36.067201345249494
  },
  {
    t: 1581568200,
    v: 35.10110647408011
  },
  {
    t: 1581568500,
    v: 29.654339612461214
  },
  {
    t: 1581568800,
    v: 31.673847900292472
  },
  {
    t: 1581569100,
    v: 23.849074005816362
  },
  {
    t: 1581569400,
    v: 21.429019194921835
  },
  {
    t: 1581569700,
    v: 54.4466525943936
  },
  {
    t: 1581570000,
    v: 55.305255131629224
  },
  {
    t: 1581570300,
    v: 63.226580236552735
  },
  {
    t: 1581570600,
    v: 52.57706863036295
  },
  {
    t: 1581570900,
    v: 55.16616992444118
  },
  {
    t: 1581571200,
    v: 60.8639578607289
  },
  {
    t: 1581571500,
    v: 43.65422211987101
  },
  {
    t: 1581571800,
    v: 54.4905468330367
  },
  {
    t: 1581572100,
    v: 60.66580119572964
  },
  {
    t: 1581572400,
    v: 60.66580119572964
  },
  {
    t: 1581572700,
    v: 58.58905410741383
  },
  {
    t: 1581573000,
    v: 62.278495642465714
  },
  {
    t: 1581573300,
    v: 66.38566995387887
  },
  {
    t: 1581573600,
    v: 67.72441816344448
  },
  {
    t: 1581573900,
    v: 50.488923140554796
  },
  {
    t: 1581574200,
    v: 57.84535349775569
  },
  {
    t: 1581574500,
    v: 64.53643842740827
  },
  {
    t: 1581574800,
    v: 62.27699896512535
  },
  {
    t: 1581575100,
    v: 63.01687886088961
  },
  {
    t: 1581575400,
    v: 60.312996219679384
  },
  {
    t: 1581575700,
    v: 50.42270600273654
  },
  {
    t: 1581576000,
    v: 38.65173193542046
  },
  {
    t: 1581576300,
    v: 48.2163943238288
  },
  {
    t: 1581576600,
    v: 52.88068637719669
  },
  {
    t: 1581576900,
    v: 55.62167690043359
  },
  {
    t: 1581577200,
    v: 69.25026016929823
  },
  {
    t: 1581577500,
    v: 74.22560264238209
  },
  {
    t: 1581577800,
    v: 68.47048500716235
  },
  {
    t: 1581578100,
    v: 71.03695743134818
  },
  {
    t: 1581578400,
    v: 82.65476123405077
  },
  {
    t: 1581578700,
    v: 65.87702767721365
  },
  {
    t: 1581579000,
    v: 67.7475385540709
  },
  {
    t: 1581579300,
    v: 68.53599786337014
  },
  {
    t: 1581579600,
    v: 66.67322530270638
  },
  {
    t: 1581579900,
    v: 71.53607182570362
  },
  {
    t: 1581580200,
    v: 70.46778672700023
  },
  {
    t: 1581580500,
    v: 76.80236517813925
  },
  {
    t: 1581580800,
    v: 77.82175909707128
  },
  {
    t: 1581581100,
    v: 79.53475373191237
  },
  {
    t: 1581581400,
    v: 81.19472489774255
  },
  {
    t: 1581581700,
    v: 73.06684769975689
  },
  {
    t: 1581582000,
    v: 75.41107422418952
  },
  {
    t: 1581582300,
    v: 76.7959757588537
  },
  {
    t: 1581582600,
    v: 84.89716186642343
  },
  {
    t: 1581582900,
    v: 88.12037421835086
  },
  {
    t: 1581583200,
    v: 88.61059927418718
  },
  {
    t: 1581583500,
    v: 90.41816245402333
  },
  {
    t: 1581583800,
    v: 90.69931891131179
  },
  {
    t: 1581584100,
    v: 91.00109234276619
  },
  {
    t: 1581584400,
    v: 91.90195696147416
  },
  {
    t: 1581584700,
    v: 75.96769155993822
  },
  {
    t: 1581585000,
    v: 63.40403318549949
  },
  {
    t: 1581585300,
    v: 45.44448303484216
  },
  {
    t: 1581585600,
    v: 53.882687157732605
  },
  {
    t: 1581585900,
    v: 60.810186525724106
  },
  {
    t: 1581586200,
    v: 61.32096980497051
  },
  {
    t: 1581586500,
    v: 50.12206323051033
  },
  {
    t: 1581586800,
    v: 53.96535490001583
  },
  {
    t: 1581587100,
    v: 46.46392374394495
  },
  {
    t: 1581587400,
    v: 58.46183520784286
  },
  {
    t: 1581587700,
    v: 43.03175571553311
  },
  {
    t: 1581588000,
    v: 37.392269995574715
  },
  {
    t: 1581588300,
    v: 37.392269995574715
  },
  {
    t: 1581588600,
    v: 37.392269995574715
  },
  {
    t: 1581588900,
    v: 28.00166880316877
  },
  {
    t: 1581589200,
    v: 46.541540008890614
  },
  {
    t: 1581589500,
    v: 51.46667642980488
  },
  {
    t: 1581589800,
    v: 52.46762841911581
  },
  {
    t: 1581590100,
    v: 63.6161477812517
  },
  {
    t: 1581590400,
    v: 50.57962132873399
  },
  {
    t: 1581590700,
    v: 41.61343323734654
  },
  {
    t: 1581591000,
    v: 39.19662026129296
  },
  {
    t: 1581591300,
    v: 24.68474076848898
  },
  {
    t: 1581591600,
    v: 18.219166027324846
  },
  {
    t: 1581591900,
    v: 20.507212799272352
  },
  {
    t: 1581592200,
    v: 25.73156293140005
  },
  {
    t: 1581592500,
    v: 35.43107813721571
  },
  {
    t: 1581592800,
    v: 24.704330340589394
  },
  {
    t: 1581593100,
    v: 28.930726803799956
  },
  {
    t: 1581593400,
    v: 39.51307935631389
  },
  {
    t: 1581593700,
    v: 36.4146707466087
  },
  {
    t: 1581594000,
    v: 33.23745806681734
  },
  {
    t: 1581594300,
    v: 34.46278256590661
  },
  {
    t: 1581594600,
    v: 34.223498735802295
  },
  {
    t: 1581594900,
    v: 19.33848691590535
  },
  {
    t: 1581595200,
    v: 18.395807866344228
  },
  {
    t: 1581595500,
    v: 16.804320522556495
  },
  {
    t: 1581595800,
    v: 18.231035119621907
  },
  {
    t: 1581596100,
    v: 25.96922371072887
  },
  {
    t: 1581596400,
    v: 23.152217103650415
  },
  {
    t: 1581596700,
    v: 34.44041344254401
  },
  {
    t: 1581597000,
    v: 40.43962068070198
  },
  {
    t: 1581597300,
    v: 46.416932364084616
  },
  {
    t: 1581597600,
    v: 63.08707779572306
  },
  {
    t: 1581597900,
    v: 62.38293777065973
  },
  {
    t: 1581598200,
    v: 60.831030609418136
  },
  {
    t: 1581598500,
    v: 51.03663453533966
  },
  {
    t: 1581598800,
    v: 52.01766679308734
  },
  {
    t: 1581599100,
    v: 45.734238333513495
  },
  {
    t: 1581599400,
    v: 59.426151305825194
  },
  {
    t: 1581599700,
    v: 63.70737908721341
  },
  {
    t: 1581600000,
    v: 47.013988986228604
  },
  {
    t: 1581600300,
    v: 42.24055923034619
  },
  {
    t: 1581600600,
    v: 34.446333269212246
  },
  {
    t: 1581600900,
    v: 36.83706589851137
  },
  {
    t: 1581601200,
    v: 33.81675840029409
  },
  {
    t: 1581601500,
    v: 32.39995601800737
  },
  {
    t: 1581601800,
    v: 32.39995601800737
  },
  {
    t: 1581602100,
    v: 30.270240760291344
  },
  {
    t: 1581602400,
    v: 26.126756793574742
  },
  {
    t: 1581602700,
    v: 22.11997691853668
  },
  {
    t: 1581603000,
    v: 20.189818691365673
  },
  {
    t: 1581603300,
    v: 31.56430116542063
  },
  {
    t: 1581603600,
    v: 36.71796115476826
  },
  {
    t: 1581603900,
    v: 42.190822884002266
  },
  {
    t: 1581604200,
    v: 54.465166618668654
  },
  {
    t: 1581604500,
    v: 63.59385822979708
  },
  {
    t: 1581604800,
    v: 49.64962579358037
  },
  {
    t: 1581605100,
    v: 49.32435192332859
  },
  {
    t: 1581605400,
    v: 44.04759240178921
  },
  {
    t: 1581605700,
    v: 48.38607122921348
  },
  {
    t: 1581606000,
    v: 46.155124782394566
  },
  {
    t: 1581606300,
    v: 25.528646117315276
  },
  {
    t: 1581606600,
    v: 29.072427233133098
  },
  {
    t: 1581606900,
    v: 24.36173009466036
  },
  {
    t: 1581607200,
    v: 16.71858059737677
  },
  {
    t: 1581607500,
    v: 24.899177914401363
  },
  {
    t: 1581607800,
    v: 30.73130666132397
  },
  {
    t: 1581608100,
    v: 44.210162540092604
  },
  {
    t: 1581608400,
    v: 42.66122050966641
  },
  {
    t: 1581608700,
    v: 50.789308886037915
  },
  {
    t: 1581609000,
    v: 42.80408969500778
  },
  {
    t: 1581609300,
    v: 54.26559778900714
  },
  {
    t: 1581609600,
    v: 59.82129737367112
  },
  {
    t: 1581609900,
    v: 60.67436500609795
  },
  {
    t: 1581610200,
    v: 55.062763815314284
  },
  {
    t: 1581610500,
    v: 57.622364281227725
  },
  {
    t: 1581610800,
    v: 57.31146619940807
  },
  {
    t: 1581611100,
    v: 59.79085026309896
  },
  {
    t: 1581611400,
    v: 52.783516131350616
  },
  {
    t: 1581611700,
    v: 55.74712918065481
  },
  {
    t: 1581612000,
    v: 51.67003065171609
  },
  {
    t: 1581612300,
    v: 46.38336906493156
  },
  {
    t: 1581612600,
    v: 49.15775115149837
  },
  {
    t: 1581612900,
    v: 42.3585843486268
  },
  {
    t: 1581613200,
    v: 40.027497195968486
  },
  {
    t: 1581613500,
    v: 33.42123056622623
  },
  {
    t: 1581613800,
    v: 27.52209444210139
  },
  {
    t: 1581614100,
    v: 30.33280577010406
  },
  {
    t: 1581614400,
    v: 39.456103378804436
  },
  {
    t: 1581614700,
    v: 32.812118251524794
  },
  {
    t: 1581615000,
    v: 36.52959191468789
  },
  {
    t: 1581615300,
    v: 31.178318423641826
  },
  {
    t: 1581615600,
    v: 34.634152022681164
  },
  {
    t: 1581615900,
    v: 33.98406196805459
  },
  {
    t: 1581616200,
    v: 39.20101836017283
  },
  {
    t: 1581616500,
    v: 43.05805625871891
  },
  {
    t: 1581616800,
    v: 38.83467330085248
  },
  {
    t: 1581617100,
    v: 49.52276068308937
  },
  {
    t: 1581617400,
    v: 43.40418207093018
  },
  {
    t: 1581617700,
    v: 37.26530284760754
  },
  {
    t: 1581618000,
    v: 34.8510199032516
  },
  {
    t: 1581618300,
    v: 40.70476723888702
  },
  {
    t: 1581618600,
    v: 42.18888930026077
  },
  {
    t: 1581618900,
    v: 47.450056318853854
  },
  {
    t: 1581619200,
    v: 53.64835303146933
  },
  {
    t: 1581619500,
    v: 49.910586088711334
  },
  {
    t: 1581619800,
    v: 45.55757643480713
  },
  {
    t: 1581620100,
    v: 48.94987785408472
  },
  {
    t: 1581620400,
    v: 46.46808803938666
  },
  {
    t: 1581620700,
    v: 40.93377280973843
  },
  {
    t: 1581621000,
    v: 50.54934336281492
  },
  {
    t: 1581621300,
    v: 59.57466431966894
  },
  {
    t: 1581621600,
    v: 57.573183051421886
  },
  {
    t: 1581621900,
    v: 46.79324456135015
  },
  {
    t: 1581622200,
    v: 58.604157141948455
  },
  {
    t: 1581622500,
    v: 52.74945676263077
  },
  {
    t: 1581622800,
    v: 49.518734070361745
  },
  {
    t: 1581623100,
    v: 55.71788222804349
  },
  {
    t: 1581623400,
    v: 54.757275617662756
  },
  {
    t: 1581623700,
    v: 58.062531730373564
  },
  {
    t: 1581624000,
    v: 62.02574621589817
  },
  {
    t: 1581624300,
    v: 54.90928119279937
  },
  {
    t: 1581624600,
    v: 54.909281192799355
  },
  {
    t: 1581624900,
    v: 52.29693232296306
  },
  {
    t: 1581625200,
    v: 42.95474625850757
  },
  {
    t: 1581625500,
    v: 41.88583551725808
  },
  {
    t: 1581625800,
    v: 40.727563252515516
  },
  {
    t: 1581626100,
    v: 40.727563252515516
  },
  {
    t: 1581626400,
    v: 44.72074901215348
  },
  {
    t: 1581626700,
    v: 52.097231096074495
  },
  {
    t: 1581627000,
    v: 35.74400203723826
  },
  {
    t: 1581627300,
    v: 34.706450266320175
  },
  {
    t: 1581627600,
    v: 29.76870683191794
  },
  {
    t: 1581627900,
    v: 24.248833026393143
  },
  {
    t: 1581628200,
    v: 19.52106129932909
  },
  {
    t: 1581628500,
    v: 18.49101136829944
  },
  {
    t: 1581628800,
    v: 14.544180125154654
  },
  {
    t: 1581629100,
    v: 32.76713988242888
  },
  {
    t: 1581629400,
    v: 42.56524377227405
  },
  {
    t: 1581629700,
    v: 45.17421456936255
  },
  {
    t: 1581630000,
    v: 51.47239694166239
  },
  {
    t: 1581630300,
    v: 45.498966038449886
  },
  {
    t: 1581630600,
    v: 43.206762815488105
  },
  {
    t: 1581630900,
    v: 56.86598842645005
  },
  {
    t: 1581631200,
    v: 47.392165617111914
  },
  {
    t: 1581631500,
    v: 53.96581232489319
  },
  {
    t: 1581631800,
    v: 35.981462589411464
  },
  {
    t: 1581632100,
    v: 41.304095072345945
  },
  {
    t: 1581632400,
    v: 36.454634200584735
  },
  {
    t: 1581632700,
    v: 33.85852874996951
  },
  {
    t: 1581633000,
    v: 43.11763690597685
  },
  {
    t: 1581633300,
    v: 43.11763690597685
  },
  {
    t: 1581633600,
    v: 42.15418528213946
  },
  {
    t: 1581633900,
    v: 52.96443616104397
  },
  {
    t: 1581634200,
    v: 44.513660636811174
  },
  {
    t: 1581634500,
    v: 54.813353690494466
  },
  {
    t: 1581634800,
    v: 52.58235071916129
  },
  {
    t: 1581635100,
    v: 41.8702634133651
  },
  {
    t: 1581635400,
    v: 46.40755731426069
  },
  {
    t: 1581635700,
    v: 52.7323433048219
  },
  {
    t: 1581636000,
    v: 43.05408255352051
  },
  {
    t: 1581636300,
    v: 52.09715502723881
  },
  {
    t: 1581636600,
    v: 51.06737052591315
  },
  {
    t: 1581636900,
    v: 49.939222552232685
  },
  {
    t: 1581637200,
    v: 58.35134339413244
  },
  {
    t: 1581637500,
    v: 63.59487246382404
  },
  {
    t: 1581637800,
    v: 70.05731421146909
  },
  {
    t: 1581638100,
    v: 64.26296980619988
  },
  {
    t: 1581638400,
    v: 32.25237410295651
  },
  {
    t: 1581638700,
    v: 30.992147771050938
  },
  {
    t: 1581639000,
    v: 18.395982111490596
  },
  {
    t: 1581639300,
    v: 33.22905158072764
  },
  {
    t: 1581639600,
    v: 32.613149750328304
  },
  {
    t: 1581639900,
    v: 27.887281003197543
  },
  {
    t: 1581640200,
    v: 34.65120133343285
  },
  {
    t: 1581640500,
    v: 44.72427340149609
  },
  {
    t: 1581640800,
    v: 45.846609690357816
  },
  {
    t: 1581641100,
    v: 52.464904561821896
  },
  {
    t: 1581641400,
    v: 53.546292544243485
  },
  {
    t: 1581641700,
    v: 59.81495424766907
  },
  {
    t: 1581642000,
    v: 54.87797531976318
  },
  {
    t: 1581642300,
    v: 56.63122518308254
  },
  {
    t: 1581642600,
    v: 56.63122518308254
  },
  {
    t: 1581642900,
    v: 51.41278996463536
  },
  {
    t: 1581643200,
    v: 45.08250753708322
  },
  {
    t: 1581643500,
    v: 38.9210809592156
  },
  {
    t: 1581643800,
    v: 38.9210809592156
  },
  {
    t: 1581644100,
    v: 30.46233741395355
  },
  {
    t: 1581644400,
    v: 43.494560007750486
  },
  {
    t: 1581644700,
    v: 46.98821046994539
  },
  {
    t: 1581645000,
    v: 40.542456620102726
  },
  {
    t: 1581645300,
    v: 52.85979729934584
  },
  {
    t: 1581645600,
    v: 48.993302755599316
  },
  {
    t: 1581645900,
    v: 46.65329432769704
  },
  {
    t: 1581646200,
    v: 43.48822606879607
  },
  {
    t: 1581646500,
    v: 40.35902561781291
  },
  {
    t: 1581646800,
    v: 48.78267907127628
  },
  {
    t: 1581647100,
    v: 45.13954704841583
  },
  {
    t: 1581647400,
    v: 53.97753838796897
  },
  {
    t: 1581647700,
    v: 55.78649664131256
  },
  {
    t: 1581648000,
    v: 48.20717445425152
  },
  {
    t: 1581648300,
    v: 55.168285680291085
  },
  {
    t: 1581648600,
    v: 53.983702550179046
  },
  {
    t: 1581648900,
    v: 62.30819002338977
  },
  {
    t: 1581649200,
    v: 48.84381757278195
  },
  {
    t: 1581649500,
    v: 56.83833697678708
  },
  {
    t: 1581649800,
    v: 45.9063789711375
  },
  {
    t: 1581650100,
    v: 51.2628490286373
  },
  {
    t: 1581650400,
    v: 39.37898183563397
  },
  {
    t: 1581650700,
    v: 44.857518487469484
  },
  {
    t: 1581651000,
    v: 41.302601579559145
  },
  {
    t: 1581651300,
    v: 37.872477194322286
  },
  {
    t: 1581651600,
    v: 37.872477194322286
  },
  {
    t: 1581651900,
    v: 28.574051371872585
  },
  {
    t: 1581652200,
    v: 23.697790356742473
  },
  {
    t: 1581652500,
    v: 22.128422237163704
  },
  {
    t: 1581652800,
    v: 37.50152985768734
  },
  {
    t: 1581653100,
    v: 33.96472837276494
  },
  {
    t: 1581653400,
    v: 30.170814476568978
  },
  {
    t: 1581653700,
    v: 41.838428394725845
  },
  {
    t: 1581654000,
    v: 42.83087214689258
  },
  {
    t: 1581654300,
    v: 45.99061817761591
  },
  {
    t: 1581654600,
    v: 41.61005820946452
  },
  {
    t: 1581654900,
    v: 46.287592061040804
  },
  {
    t: 1581655200,
    v: 46.287592061040804
  },
  {
    t: 1581655500,
    v: 46.287592061040804
  },
  {
    t: 1581655800,
    v: 46.287592061040804
  },
  {
    t: 1581656100,
    v: 54.12438846844523
  },
  {
    t: 1581656400,
    v: 62.8069151335622
  },
  {
    t: 1581656700,
    v: 60.9243539541852
  },
  {
    t: 1581657000,
    v: 66.63847551435919
  },
  {
    t: 1581657300,
    v: 69.67888241854062
  },
  {
    t: 1581657600,
    v: 71.6475699796192
  },
  {
    t: 1581657900,
    v: 72.66186079891315
  },
  {
    t: 1581658200,
    v: 60.32908356719739
  },
  {
    t: 1581658500,
    v: 60.32908356719739
  },
  {
    t: 1581658800,
    v: 71.64408170282347
  },
  {
    t: 1581659100,
    v: 71.64408170282347
  },
  {
    t: 1581659400,
    v: 82.04750831625009
  },
  {
    t: 1581659700,
    v: 84.78215385870458
  },
  {
    t: 1581660000,
    v: 64.26975924533531
  },
  {
    t: 1581660300,
    v: 51.35957119492791
  },
  {
    t: 1581660600,
    v: 61.16621225930773
  },
  {
    t: 1581660900,
    v: 61.96297441043516
  },
  {
    t: 1581661200,
    v: 68.59129404611834
  },
  {
    t: 1581661500,
    v: 73.80761081303031
  },
  {
    t: 1581661800,
    v: 68.06575834829431
  },
  {
    t: 1581662100,
    v: 70.67316089568004
  },
  {
    t: 1581662400,
    v: 71.34171059507713
  },
  {
    t: 1581662700,
    v: 55.45045571985719
  },
  {
    t: 1581663000,
    v: 60.88220322029892
  },
  {
    t: 1581663300,
    v: 41.57257460486391
  },
  {
    t: 1581663600,
    v: 56.538506360181
  },
  {
    t: 1581663900,
    v: 67.54084953449285
  },
  {
    t: 1581664200,
    v: 65.80995193852914
  },
  {
    t: 1581664500,
    v: 62.16832094629535
  },
  {
    t: 1581664800,
    v: 49.61706070590336
  },
  {
    t: 1581665100,
    v: 46.28007591162897
  },
  {
    t: 1581665400,
    v: 50.11441249097111
  },
  {
    t: 1581665700,
    v: 67.57269668315487
  },
  {
    t: 1581666000,
    v: 68.34013508618327
  },
  {
    t: 1581666300,
    v: 73.07394417690648
  },
  {
    t: 1581666600,
    v: 75.34272907936102
  },
  {
    t: 1581666900,
    v: 59.08612017510719
  },
  {
    t: 1581667200,
    v: 60.545500072808345
  },
  {
    t: 1581667500,
    v: 42.197018461151394
  },
  {
    t: 1581667800,
    v: 29.22725378257448
  },
  {
    t: 1581668100,
    v: 28.258814600492926
  },
  {
    t: 1581668400,
    v: 32.717592697227474
  },
  {
    t: 1581668700,
    v: 42.115357200710676
  },
  {
    t: 1581669000,
    v: 52.918965573922435
  },
  {
    t: 1581669300,
    v: 56.17076300313344
  },
  {
    t: 1581669600,
    v: 49.78488903670581
  },
  {
    t: 1581669900,
    v: 49.78488903670581
  },
  {
    t: 1581670200,
    v: 50.35197970018752
  },
  {
    t: 1581670500,
    v: 70.74285092230801
  },
  {
    t: 1581670800,
    v: 62.105473569733654
  },
  {
    t: 1581671100,
    v: 68.20566809908968
  },
  {
    t: 1581671400,
    v: 72.76133786392289
  },
  {
    t: 1581671700,
    v: 72.76133786392288
  },
  {
    t: 1581672000,
    v: 74.87710839464572
  },
  {
    t: 1581672300,
    v: 74.87710839464572
  },
  {
    t: 1581672600,
    v: 78.0881541358093
  },
  {
    t: 1581672900,
    v: 60.96308273635838
  },
  {
    t: 1581673200,
    v: 64.8404357353814
  },
  {
    t: 1581673500,
    v: 68.12933254779645
  },
  {
    t: 1581673800,
    v: 62.76169295617823
  },
  {
    t: 1581674100,
    v: 51.70474689933121
  },
  {
    t: 1581674400,
    v: 44.61330507899778
  },
  {
    t: 1581674700,
    v: 48.88629769018405
  },
  {
    t: 1581675000,
    v: 53.960410244931495
  },
  {
    t: 1581675300,
    v: 57.34772059988824
  },
  {
    t: 1581675600,
    v: 63.10856738063929
  },
  {
    t: 1581675900,
    v: 67.70952611842517
  },
  {
    t: 1581676200,
    v: 40.374648689995524
  },
  {
    t: 1581676500,
    v: 47.21132741903959
  },
  {
    t: 1581676800,
    v: 38.40438706878517
  },
  {
    t: 1581677100,
    v: 34.467818541440096
  },
  {
    t: 1581677400,
    v: 31.970963175140312
  },
  {
    t: 1581677700,
    v: 31.970963175140312
  },
  {
    t: 1581678000,
    v: 47.39923374507811
  },
  {
    t: 1581678300,
    v: 53.2609443101698
  },
  {
    t: 1581678600,
    v: 49.87334911885587
  },
  {
    t: 1581678900,
    v: 52.87093392820584
  },
  {
    t: 1581679200,
    v: 41.204474504293735
  },
  {
    t: 1581679500,
    v: 45.472018187674365
  },
  {
    t: 1581679800,
    v: 38.39448411505342
  },
  {
    t: 1581680100,
    v: 44.335481892717524
  },
  {
    t: 1581680400,
    v: 42.01991711957738
  },
  {
    t: 1581680700,
    v: 44.76725955007923
  },
  {
    t: 1581681000,
    v: 38.51078682868507
  },
  {
    t: 1581681300,
    v: 31.749406822629453
  },
  {
    t: 1581681600,
    v: 26.666280049766527
  },
  {
    t: 1581681900,
    v: 28.725437181012623
  },
  {
    t: 1581682200,
    v: 26.724368664773152
  },
  {
    t: 1581682500,
    v: 36.78924078359358
  },
  {
    t: 1581682800,
    v: 41.99262399019512
  },
  {
    t: 1581683100,
    v: 54.34192099354508
  },
  {
    t: 1581683400,
    v: 53.7730836906306
  },
  {
    t: 1581683700,
    v: 71.2897680956164
  },
  {
    t: 1581684000,
    v: 63.23541960519305
  },
  {
    t: 1581684300,
    v: 69.31131184258366
  },
  {
    t: 1581684600,
    v: 54.5331857155626
  },
  {
    t: 1581684900,
    v: 48.13539145102798
  },
  {
    t: 1581685200,
    v: 53.96483937900785
  },
  {
    t: 1581685500,
    v: 50.888668722548196
  },
  {
    t: 1581685800,
    v: 54.586810060398825
  },
  {
    t: 1581686100,
    v: 40.60811653227337
  },
  {
    t: 1581686400,
    v: 36.835005401277094
  },
  {
    t: 1581686700,
    v: 40.01962719069862
  },
  {
    t: 1581687000,
    v: 65.54493165520259
  },
  {
    t: 1581687300,
    v: 66.09460075726173
  },
  {
    t: 1581687600,
    v: 76.31471688723765
  },
  {
    t: 1581687900,
    v: 70.49962855083533
  },
  {
    t: 1581688200,
    v: 74.20017482331977
  },
  {
    t: 1581688500,
    v: 74.71795388115866
  },
  {
    t: 1581688800,
    v: 74.71795388115866
  },
  {
    t: 1581689100,
    v: 70.55662601480381
  },
  {
    t: 1581689400,
    v: 76.79330389448359
  },
  {
    t: 1581689700,
    v: 68.50186192305799
  },
  {
    t: 1581690000,
    v: 63.168636802933726
  },
  {
    t: 1581690300,
    v: 65.74746018688693
  },
  {
    t: 1581690600,
    v: 62.56172394764689
  },
  {
    t: 1581690900,
    v: 71.38893286729632
  },
  {
    t: 1581691200,
    v: 73.23800314802314
  },
  {
    t: 1581691500,
    v: 68.67301224915224
  },
  {
    t: 1581691800,
    v: 65.55957416626208
  },
  {
    t: 1581692100,
    v: 55.49553791571441
  },
  {
    t: 1581692400,
    v: 51.21675021291944
  },
  {
    t: 1581692700,
    v: 30.837306606216472
  },
  {
    t: 1581693000,
    v: 34.791133397872116
  },
  {
    t: 1581693300,
    v: 31.68589513499684
  },
  {
    t: 1581693600,
    v: 40.222481131335755
  },
  {
    t: 1581693900,
    v: 47.37948975568423
  },
  {
    t: 1581694200,
    v: 47.37948975568423
  },
  {
    t: 1581694500,
    v: 55.10258774028508
  },
  {
    t: 1581694800,
    v: 68.02391053536891
  },
  {
    t: 1581695100,
    v: 56.17172545876638
  },
  {
    t: 1581695400,
    v: 47.54045227433086
  },
  {
    t: 1581695700,
    v: 34.320060867539155
  },
  {
    t: 1581696000,
    v: 31.386241654949046
  },
  {
    t: 1581696300,
    v: 29.11101541821698
  },
  {
    t: 1581696600,
    v: 30.195348862290118
  },
  {
    t: 1581696900,
    v: 26.4906070872607
  },
  {
    t: 1581697200,
    v: 36.18449811389584
  },
  {
    t: 1581697500,
    v: 33.83122230090903
  },
  {
    t: 1581697800,
    v: 32.81496670251191
  },
  {
    t: 1581698100,
    v: 35.04489301253787
  },
  {
    t: 1581698400,
    v: 40.87595195031475
  },
  {
    t: 1581698700,
    v: 37.33976252597236
  },
  {
    t: 1581699000,
    v: 32.99075128440897
  },
  {
    t: 1581699300,
    v: 31.17524064821636
  },
  {
    t: 1581699600,
    v: 27.472750587465114
  },
  {
    t: 1581699900,
    v: 26.746684773886116
  },
  {
    t: 1581700200,
    v: 21.89664961104107
  },
  {
    t: 1581700500,
    v: 18.87418440911668
  },
  {
    t: 1581700800,
    v: 36.99777514321567
  },
  {
    t: 1581701100,
    v: 34.98105179137302
  },
  {
    t: 1581701400,
    v: 40.29452823676443
  },
  {
    t: 1581701700,
    v: 38.15632698642647
  },
  {
    t: 1581702000,
    v: 49.40652975689625
  },
  {
    t: 1581702300,
    v: 53.37224356684031
  },
  {
    t: 1581702600,
    v: 60.120279219699256
  },
  {
    t: 1581702900,
    v: 67.41243065804152
  },
  {
    t: 1581703200,
    v: 65.04834124062172
  },
  {
    t: 1581703500,
    v: 70.88319058938171
  },
  {
    t: 1581703800,
    v: 71.95338873352566
  },
  {
    t: 1581704100,
    v: 71.95338873352566
  },
  {
    t: 1581704400,
    v: 73.23814435752362
  },
  {
    t: 1581704700,
    v: 50.22921091008219
  },
  {
    t: 1581705000,
    v: 57.6200307676371
  },
  {
    t: 1581705300,
    v: 57.6200307676371
  },
  {
    t: 1581705600,
    v: 58.098095630304044
  },
  {
    t: 1581705900,
    v: 49.21535827303818
  },
  {
    t: 1581706200,
    v: 43.754380883278984
  },
  {
    t: 1581706500,
    v: 54.67511809228859
  },
  {
    t: 1581706800,
    v: 60.649526600792996
  },
  {
    t: 1581707100,
    v: 60.649526600792996
  },
  {
    t: 1581707400,
    v: 61.66489906567951
  },
  {
    t: 1581707700,
    v: 49.89408680947044
  },
  {
    t: 1581708000,
    v: 46.1210518767004
  },
  {
    t: 1581708300,
    v: 41.89669810091885
  },
  {
    t: 1581708600,
    v: 35.07758329819936
  },
  {
    t: 1581708900,
    v: 32.735583314576346
  },
  {
    t: 1581709200,
    v: 30.414802275704716
  },
  {
    t: 1581709500,
    v: 28.563379477682673
  },
  {
    t: 1581709800,
    v: 26.278210319598827
  },
  {
    t: 1581710100,
    v: 32.4541022710729
  },
  {
    t: 1581710400,
    v: 27.238454801695212
  },
  {
    t: 1581710700,
    v: 31.03824929354849
  },
  {
    t: 1581711000,
    v: 43.4200575501096
  },
  {
    t: 1581711300,
    v: 39.04945526782742
  },
  {
    t: 1581711600,
    v: 31.745542342168818
  },
  {
    t: 1581711900,
    v: 37.327639187380655
  },
  {
    t: 1581712200,
    v: 29.153085142581915
  },
  {
    t: 1581712500,
    v: 37.496241015777706
  },
  {
    t: 1581712800,
    v: 43.226908008056164
  },
  {
    t: 1581713100,
    v: 34.253851511465015
  },
  {
    t: 1581713400,
    v: 41.63810037116443
  },
  {
    t: 1581713700,
    v: 37.98162417210727
  },
  {
    t: 1581714000,
    v: 40.94502426267783
  },
  {
    t: 1581714300,
    v: 46.75978252867841
  },
  {
    t: 1581714600,
    v: 34.95825716960117
  },
  {
    t: 1581714900,
    v: 33.3547741607607
  },
  {
    t: 1581715200,
    v: 30.189624543943737
  },
  {
    t: 1581715500,
    v: 24.44554963643799
  },
  {
    t: 1581715800,
    v: 30.276757933013897
  },
  {
    t: 1581716100,
    v: 24.628634986206578
  },
  {
    t: 1581716400,
    v: 23.112459330673232
  },
  {
    t: 1581890700,
    v: 63.220817911287305
  },
  {
    t: 1581891000,
    v: 50.96843674772551
  },
  {
    t: 1581891300,
    v: 49.64883570423996
  },
  {
    t: 1581891600,
    v: 54.169162399112025
  },
  {
    t: 1581891900,
    v: 53.07994956436503
  },
  {
    t: 1581892200,
    v: 56.10601843878806
  },
  {
    t: 1581892500,
    v: 56.10601843878806
  },
  {
    t: 1581892800,
    v: 61.03009541714902
  },
  {
    t: 1581893100,
    v: 56.22451078610521
  },
  {
    t: 1581893400,
    v: 57.499387139822005
  },
  {
    t: 1581893700,
    v: 53.08246169781694
  },
  {
    t: 1581894000,
    v: 54.67413839951673
  },
  {
    t: 1581894300,
    v: 58.679293477386956
  },
  {
    t: 1581894600,
    v: 64.42712652858599
  },
  {
    t: 1581894900,
    v: 57.56373771278394
  },
  {
    t: 1581895200,
    v: 63.490369685472594
  },
  {
    t: 1581895500,
    v: 57.731278266337796
  },
  {
    t: 1581895800,
    v: 66.70529024541608
  },
  {
    t: 1581896100,
    v: 69.01831931105926
  },
  {
    t: 1581896400,
    v: 69.6213048928316
  },
  {
    t: 1581896700,
    v: 62.65330853304733
  },
  {
    t: 1581897000,
    v: 68.43082192419199
  },
  {
    t: 1581897300,
    v: 69.11341315664478
  },
  {
    t: 1581897600,
    v: 37.55812312859751
  },
  {
    t: 1581897900,
    v: 30.915656591121348
  },
  {
    t: 1581898200,
    v: 41.83284023151354
  },
  {
    t: 1581898500,
    v: 36.73011829251118
  },
  {
    t: 1581898800,
    v: 31.846605483205508
  },
  {
    t: 1581899100,
    v: 44.80757921338235
  },
  {
    t: 1581899400,
    v: 40.64997247921737
  },
  {
    t: 1581899700,
    v: 39.095297314478906
  },
  {
    t: 1581900000,
    v: 35.95240637016599
  },
  {
    t: 1581900300,
    v: 41.3416359513737
  },
  {
    t: 1581900600,
    v: 38.2744276322794
  },
  {
    t: 1581900900,
    v: 40.2992518523357
  },
  {
    t: 1581901200,
    v: 33.93765697931873
  },
  {
    t: 1581901500,
    v: 44.03441640617427
  },
  {
    t: 1581901800,
    v: 47.689276058405966
  },
  {
    t: 1581902100,
    v: 34.72922503346176
  },
  {
    t: 1581902400,
    v: 31.324160030490347
  },
  {
    t: 1581902700,
    v: 45.327025252906274
  },
  {
    t: 1581903000,
    v: 52.87647574006573
  },
  {
    t: 1581903300,
    v: 56.030489161029465
  },
  {
    t: 1581903600,
    v: 59.15483015668143
  },
  {
    t: 1581903900,
    v: 59.807618626014204
  },
  {
    t: 1581904200,
    v: 61.8955702697706
  },
  {
    t: 1581904500,
    v: 51.67003313933755
  },
  {
    t: 1581904800,
    v: 51.67003313933755
  },
  {
    t: 1581905100,
    v: 64.07354792096423
  },
  {
    t: 1581905400,
    v: 67.6335226925157
  },
  {
    t: 1581905700,
    v: 62.88579468081557
  },
  {
    t: 1581906000,
    v: 59.316731426613856
  },
  {
    t: 1581906300,
    v: 67.69759065874676
  },
  {
    t: 1581906600,
    v: 70.04812908845912
  },
  {
    t: 1581906900,
    v: 62.27923954511767
  },
  {
    t: 1581907200,
    v: 66.52256661646862
  },
  {
    t: 1581907500,
    v: 61.27107967821748
  },
  {
    t: 1581907800,
    v: 63.72560057412209
  },
  {
    t: 1581908100,
    v: 58.11333956164366
  },
  {
    t: 1581908400,
    v: 45.51523169912951
  },
  {
    t: 1581908700,
    v: 47.86377374153239
  },
  {
    t: 1581909000,
    v: 46.71317325785432
  },
  {
    t: 1581909300,
    v: 53.148922967632394
  },
  {
    t: 1581909600,
    v: 45.59653456224636
  },
  {
    t: 1581909900,
    v: 44.394923663563894
  },
  {
    t: 1581910200,
    v: 51.67252233306333
  },
  {
    t: 1581910500,
    v: 43.80910639096246
  },
  {
    t: 1581910800,
    v: 49.64725837930488
  },
  {
    t: 1581911100,
    v: 44.3777870369384
  },
  {
    t: 1581911400,
    v: 46.01489632144335
  },
  {
    t: 1581911700,
    v: 53.78725945990738
  },
  {
    t: 1581912000,
    v: 55.259575969841094
  },
  {
    t: 1581912300,
    v: 71.06358780601555
  },
  {
    t: 1581912600,
    v: 71.06358780601555
  },
  {
    t: 1581912900,
    v: 61.591406326641824
  },
  {
    t: 1581913200,
    v: 44.11781511162526
  },
  {
    t: 1581913500,
    v: 48.00798484700389
  },
  {
    t: 1581913800,
    v: 55.14516102882749
  },
  {
    t: 1581914100,
    v: 51.134073752734
  },
  {
    t: 1581914400,
    v: 54.88443458269996
  },
  {
    t: 1581914700,
    v: 58.5225071924321
  },
  {
    t: 1581915000,
    v: 44.76949456834872
  },
  {
    t: 1581915300,
    v: 36.05447202921813
  },
  {
    t: 1581915600,
    v: 43.088524599074965
  },
  {
    t: 1581915900,
    v: 43.088524599074965
  },
  {
    t: 1581916200,
    v: 56.75694612638132
  },
  {
    t: 1581916500,
    v: 55.37518499763136
  },
  {
    t: 1581916800,
    v: 58.813035144801376
  },
  {
    t: 1581917100,
    v: 50.007295791688094
  },
  {
    t: 1581917400,
    v: 46.06603629160158
  },
  {
    t: 1581917700,
    v: 59.456283484403045
  },
  {
    t: 1581918000,
    v: 53.89547645362635
  },
  {
    t: 1581918300,
    v: 57.31720918622301
  },
  {
    t: 1581918600,
    v: 65.97510795618356
  },
  {
    t: 1581918900,
    v: 53.55903197602349
  },
  {
    t: 1581919200,
    v: 56.6657793148683
  },
  {
    t: 1581919500,
    v: 43.39500292128152
  },
  {
    t: 1581919800,
    v: 39.04110473383925
  },
  {
    t: 1581920100,
    v: 37.32897544860722
  },
  {
    t: 1581920400,
    v: 38.861099853994816
  },
  {
    t: 1581920700,
    v: 48.86242125965002
  },
  {
    t: 1581921000,
    v: 42.113876416167905
  },
  {
    t: 1581921300,
    v: 32.00903188182505
  },
  {
    t: 1581921600,
    v: 28.726778877399695
  },
  {
    t: 1581921900,
    v: 23.272223683660727
  },
  {
    t: 1581922200,
    v: 20.5912185002273
  },
  {
    t: 1581922500,
    v: 23.929675098433478
  },
  {
    t: 1581922800,
    v: 44.080529441809574
  },
  {
    t: 1581923100,
    v: 44.08052944180957
  },
  {
    t: 1581923400,
    v: 46.54644074666624
  },
  {
    t: 1581923700,
    v: 62.577392783386124
  },
  {
    t: 1581924000,
    v: 61.34066479225735
  },
  {
    t: 1581924300,
    v: 66.61846573320851
  },
  {
    t: 1581924600,
    v: 56.53716547743785
  },
  {
    t: 1581924900,
    v: 55.340785320946644
  },
  {
    t: 1581925200,
    v: 47.328634251942375
  },
  {
    t: 1581925500,
    v: 53.870844180992634
  },
  {
    t: 1581925800,
    v: 69.66167242886172
  },
  {
    t: 1581926100,
    v: 71.67636008215243
  },
  {
    t: 1581926400,
    v: 67.81622157366935
  },
  {
    t: 1581926700,
    v: 73.74207301161088
  },
  {
    t: 1581927000,
    v: 56.46066989390526
  },
  {
    t: 1581927300,
    v: 64.72689688318039
  },
  {
    t: 1581927600,
    v: 46.651281785464064
  },
  {
    t: 1581927900,
    v: 52.690843721086566
  },
  {
    t: 1581928200,
    v: 45.60958074321936
  },
  {
    t: 1581928500,
    v: 39.98061703260527
  },
  {
    t: 1581928800,
    v: 50.722272602094364
  },
  {
    t: 1581929100,
    v: 66.46020641273435
  },
  {
    t: 1581929400,
    v: 69.59076975653684
  },
  {
    t: 1581929700,
    v: 74.73578312501743
  },
  {
    t: 1581930000,
    v: 76.46911595811773
  },
  {
    t: 1581930300,
    v: 79.48515877060578
  },
  {
    t: 1581930600,
    v: 81.47513414947463
  },
  {
    t: 1581930900,
    v: 64.55875843372195
  },
  {
    t: 1581931200,
    v: 56.33287193843722
  },
  {
    t: 1581931500,
    v: 52.95893624750759
  },
  {
    t: 1581931800,
    v: 55.97256817221732
  },
  {
    t: 1581932100,
    v: 58.16064212291906
  },
  {
    t: 1581932400,
    v: 47.39331983976674
  },
  {
    t: 1581932700,
    v: 55.41207032246622
  },
  {
    t: 1581933000,
    v: 50.261618822278656
  },
  {
    t: 1581933300,
    v: 47.250091703586044
  },
  {
    t: 1581933600,
    v: 45.18724544306837
  },
  {
    t: 1581933900,
    v: 49.00391244854646
  },
  {
    t: 1581934200,
    v: 54.97552954996698
  },
  {
    t: 1581934500,
    v: 52.182155782812714
  },
  {
    t: 1581934800,
    v: 57.70792888256115
  },
  {
    t: 1581935100,
    v: 55.274607810052984
  },
  {
    t: 1581935400,
    v: 57.33083013397984
  },
  {
    t: 1581935700,
    v: 61.3883124709908
  },
  {
    t: 1581936000,
    v: 63.37826758563675
  },
  {
    t: 1581936300,
    v: 49.71361559928778
  },
  {
    t: 1581936600,
    v: 53.78097807936816
  },
  {
    t: 1581936900,
    v: 61.59186446133328
  },
  {
    t: 1581937200,
    v: 47.43350410042605
  },
  {
    t: 1581937500,
    v: 57.3256554968682
  },
  {
    t: 1581937800,
    v: 50.13710007414748
  },
  {
    t: 1581938100,
    v: 40.7850727515556
  },
  {
    t: 1581938400,
    v: 32.89588047888077
  },
  {
    t: 1581938700,
    v: 30.291692550191883
  },
  {
    t: 1581939000,
    v: 28.111662435510482
  },
  {
    t: 1581939300,
    v: 34.405891224056234
  },
  {
    t: 1581939600,
    v: 30.87506853500919
  },
  {
    t: 1581939900,
    v: 28.37880511477323
  },
  {
    t: 1581940200,
    v: 23.95238019971636
  },
  {
    t: 1581940500,
    v: 25.065524252450658
  },
  {
    t: 1581940800,
    v: 33.910203596545045
  },
  {
    t: 1581941100,
    v: 39.98222788213721
  },
  {
    t: 1581941400,
    v: 45.68529470607555
  },
  {
    t: 1581941700,
    v: 33.542991478479564
  },
  {
    t: 1581942000,
    v: 49.61323240881658
  },
  {
    t: 1581942300,
    v: 36.96525011332376
  },
  {
    t: 1581942600,
    v: 36.96525011332376
  },
  {
    t: 1581942900,
    v: 32.76869082956412
  },
  {
    t: 1581943200,
    v: 32.34897801864345
  },
  {
    t: 1581943500,
    v: 30.559636430549702
  },
  {
    t: 1581943800,
    v: 26.03383513682867
  },
  {
    t: 1581944100,
    v: 29.297478667783764
  },
  {
    t: 1581944400,
    v: 21.926316287215897
  },
  {
    t: 1581944700,
    v: 19.170217814281727
  },
  {
    t: 1581945000,
    v: 36.50173126049243
  },
  {
    t: 1581945300,
    v: 47.36370442761597
  },
  {
    t: 1581945600,
    v: 52.57849257695873
  },
  {
    t: 1581945900,
    v: 57.40169629299747
  },
  {
    t: 1581946200,
    v: 51.42419474688955
  },
  {
    t: 1581946500,
    v: 59.012191586621476
  },
  {
    t: 1581946800,
    v: 65.59337986639724
  },
  {
    t: 1581947100,
    v: 54.30053556475015
  },
  {
    t: 1581947400,
    v: 48.66769877579772
  },
  {
    t: 1581947700,
    v: 39.72056350008764
  },
  {
    t: 1581948000,
    v: 39.72056350008765
  },
  {
    t: 1581948300,
    v: 42.621146790095644
  },
  {
    t: 1581948600,
    v: 46.99362326796887
  },
  {
    t: 1581948900,
    v: 44.41489856682981
  },
  {
    t: 1581949200,
    v: 43.064543891283265
  },
  {
    t: 1581949500,
    v: 58.80843566058607
  },
  {
    t: 1581949800,
    v: 49.55452634482656
  },
  {
    t: 1581950100,
    v: 54.008146363864185
  },
  {
    t: 1581950400,
    v: 59.28937686269783
  },
  {
    t: 1581950700,
    v: 66.59567798512063
  },
  {
    t: 1581951000,
    v: 56.544357292253544
  },
  {
    t: 1581951300,
    v: 55.80391646132077
  },
  {
    t: 1581951600,
    v: 55.80391646132077
  },
  {
    t: 1581951900,
    v: 39.08966481398955
  },
  {
    t: 1581952200,
    v: 34.38200193106675
  },
  {
    t: 1581952500,
    v: 38.60697907738409
  },
  {
    t: 1581952800,
    v: 32.40681679784983
  },
  {
    t: 1581953100,
    v: 34.26302023948314
  },
  {
    t: 1581953400,
    v: 45.401132959507756
  },
  {
    t: 1581953700,
    v: 49.88042223647459
  },
  {
    t: 1581954000,
    v: 44.96216304395625
  },
  {
    t: 1581954300,
    v: 25.832698719915427
  },
  {
    t: 1581954600,
    v: 35.9829469850409
  },
  {
    t: 1581954900,
    v: 26.565042074582976
  },
  {
    t: 1581955200,
    v: 29.65923708090719
  },
  {
    t: 1581955500,
    v: 39.05111653857713
  },
  {
    t: 1581955800,
    v: 44.35778337440389
  },
  {
    t: 1581956100,
    v: 53.59325571872813
  },
  {
    t: 1581956400,
    v: 56.48246479252374
  },
  {
    t: 1581956700,
    v: 58.983944143661795
  },
  {
    t: 1581957000,
    v: 55.922476127324224
  },
  {
    t: 1581957300,
    v: 49.4670284251655
  },
  {
    t: 1581957600,
    v: 47.202567408555055
  },
  {
    t: 1581957900,
    v: 56.33931295283943
  },
  {
    t: 1581958200,
    v: 59.81626743217084
  },
  {
    t: 1581958500,
    v: 64.4397304735624
  },
  {
    t: 1581958800,
    v: 69.28618432930159
  },
  {
    t: 1581959100,
    v: 69.28618432930159
  },
  {
    t: 1581959400,
    v: 61.12549931729735
  },
  {
    t: 1581959700,
    v: 49.924971425797956
  },
  {
    t: 1581960000,
    v: 44.909155028927316
  },
  {
    t: 1581960300,
    v: 53.00335401181607
  },
  {
    t: 1581960600,
    v: 48.274846477705324
  },
  {
    t: 1581960900,
    v: 46.90839195921015
  },
  {
    t: 1581961200,
    v: 38.75858228848537
  },
  {
    t: 1581961500,
    v: 35.50483194182763
  },
  {
    t: 1581961800,
    v: 32.39663718497043
  },
  {
    t: 1581962100,
    v: 27.37536208061465
  },
  {
    t: 1581962400,
    v: 37.448226196869214
  },
  {
    t: 1581962700,
    v: 48.13565413018997
  },
  {
    t: 1581963000,
    v: 48.90315042645823
  },
  {
    t: 1581963300,
    v: 45.80453418599106
  },
  {
    t: 1581963600,
    v: 40.65282140189341
  },
  {
    t: 1581963900,
    v: 31.421169721827866
  },
  {
    t: 1581964200,
    v: 29.06402401093294
  },
  {
    t: 1581964500,
    v: 39.446996710127706
  },
  {
    t: 1581964800,
    v: 39.446996710127706
  },
  {
    t: 1581965100,
    v: 39.446996710127706
  },
  {
    t: 1581965400,
    v: 49.39750842319018
  },
  {
    t: 1581965700,
    v: 45.59203952710381
  },
  {
    t: 1581966000,
    v: 46.76380869196798
  },
  {
    t: 1581966300,
    v: 33.55095916550694
  },
  {
    t: 1581966600,
    v: 52.659308464742764
  },
  {
    t: 1581966900,
    v: 55.579048940060645
  },
  {
    t: 1581967200,
    v: 51.9194642497035
  },
  {
    t: 1581967500,
    v: 51.9194642497035
  },
  {
    t: 1581967800,
    v: 54.832790414758584
  },
  {
    t: 1581968100,
    v: 54.832790414758584
  },
  {
    t: 1581968400,
    v: 58.14523493850437
  },
  {
    t: 1581968700,
    v: 59.28279415264853
  },
  {
    t: 1581969000,
    v: 54.22946459135099
  },
  {
    t: 1581969300,
    v: 54.22946459135099
  },
  {
    t: 1581969600,
    v: 50.482477051181164
  },
  {
    t: 1581969900,
    v: 55.72660160057688
  },
  {
    t: 1581970200,
    v: 51.565804200641274
  },
  {
    t: 1581970500,
    v: 57.06174007682579
  },
  {
    t: 1581970800,
    v: 46.920464903338285
  },
  {
    t: 1581971100,
    v: 48.9925294776733
  },
  {
    t: 1581971400,
    v: 51.17097246046754
  },
  {
    t: 1581971700,
    v: 28.732370408711873
  },
  {
    t: 1581972000,
    v: 25.53338358501
  },
  {
    t: 1581972300,
    v: 49.587988793394096
  },
  {
    t: 1581972600,
    v: 47.26163359736146
  },
  {
    t: 1581972900,
    v: 51.18731820715506
  },
  {
    t: 1581973200,
    v: 45.972733322815316
  },
  {
    t: 1581973500,
    v: 47.50079897704531
  },
  {
    t: 1581973800,
    v: 62.17321522870573
  },
  {
    t: 1581974100,
    v: 59.03076743592966
  },
  {
    t: 1581974400,
    v: 60.18082339020263
  },
  {
    t: 1581974700,
    v: 67.48312724753528
  },
  {
    t: 1581975000,
    v: 61.92123704956962
  },
  {
    t: 1581975300,
    v: 61.92123704956961
  },
  {
    t: 1581975600,
    v: 66.70075626119122
  },
  {
    t: 1581975900,
    v: 67.85358778977246
  },
  {
    t: 1581976200,
    v: 72.24605158162882
  },
  {
    t: 1581976500,
    v: 51.96069696178769
  },
  {
    t: 1581976800,
    v: 43.57160034402171
  },
  {
    t: 1581977100,
    v: 64.38271486469785
  },
  {
    t: 1581977400,
    v: 57.95446356699658
  },
  {
    t: 1581977700,
    v: 42.45296947832313
  },
  {
    t: 1581978000,
    v: 50.082555344943316
  },
  {
    t: 1581978300,
    v: 58.68923976423853
  },
  {
    t: 1581978600,
    v: 46.2592077898246
  },
  {
    t: 1581978900,
    v: 56.16266128529827
  },
  {
    t: 1581979200,
    v: 62.866108890726814
  },
  {
    t: 1581979500,
    v: 63.39914420101963
  },
  {
    t: 1581979800,
    v: 64.56187246387805
  },
  {
    t: 1581980100,
    v: 64.56187246387805
  },
  {
    t: 1581980400,
    v: 56.37487724919562
  },
  {
    t: 1581980700,
    v: 59.71133190915465
  },
  {
    t: 1581981000,
    v: 59.71133190915465
  },
  {
    t: 1581981300,
    v: 53.08308569102381
  },
  {
    t: 1581981600,
    v: 48.19206659529503
  },
  {
    t: 1581981900,
    v: 55.259015348077725
  },
  {
    t: 1581982200,
    v: 55.259015348077725
  },
  {
    t: 1581982500,
    v: 55.259015348077725
  },
  {
    t: 1581982800,
    v: 41.25778568871046
  },
  {
    t: 1581983100,
    v: 57.323153079729806
  },
  {
    t: 1581983400,
    v: 57.323153079729806
  },
  {
    t: 1581983700,
    v: 58.46445565293508
  },
  {
    t: 1581984000,
    v: 28.247574871720502
  },
  {
    t: 1581984300,
    v: 30.590354532978736
  },
  {
    t: 1581984600,
    v: 21.688085574757693
  },
  {
    t: 1581984900,
    v: 18.595718043161767
  },
  {
    t: 1581985200,
    v: 19.78402870810325
  },
  {
    t: 1581985500,
    v: 39.852776392151
  },
  {
    t: 1581985800,
    v: 36.70437255929166
  },
  {
    t: 1581986100,
    v: 37.64271156217789
  },
  {
    t: 1581986400,
    v: 28.845755398659705
  },
  {
    t: 1581986700,
    v: 27.616449223975422
  },
  {
    t: 1581987000,
    v: 21.10602958833954
  },
  {
    t: 1581987300,
    v: 17.202986681453496
  },
  {
    t: 1581987600,
    v: 36.495918251837296
  },
  {
    t: 1581987900,
    v: 33.759737607895545
  },
  {
    t: 1581988200,
    v: 33.759737607895545
  },
  {
    t: 1581988500,
    v: 31.970781954231086
  },
  {
    t: 1581988800,
    v: 31.493956218472874
  },
  {
    t: 1581989100,
    v: 33.75234587409646
  },
  {
    t: 1581989400,
    v: 27.529237455237322
  },
  {
    t: 1581989700,
    v: 27.529237455237322
  },
  {
    t: 1581990000,
    v: 19.367284602776905
  },
  {
    t: 1581990300,
    v: 39.77409683313733
  },
  {
    t: 1581990600,
    v: 47.0649722953081
  },
  {
    t: 1581990900,
    v: 50.48990519763647
  },
  {
    t: 1581991200,
    v: 44.55914668992187
  },
  {
    t: 1581991500,
    v: 47.68074261529183
  },
  {
    t: 1581991800,
    v: 44.131032958445914
  },
  {
    t: 1581992100,
    v: 37.71349374800555
  },
  {
    t: 1581992400,
    v: 31.884555698183547
  },
  {
    t: 1581992700,
    v: 35.01513981921812
  },
  {
    t: 1581993000,
    v: 38.25817169228079
  },
  {
    t: 1581993300,
    v: 48.12099701597492
  },
  {
    t: 1581993600,
    v: 59.2736976135018
  },
  {
    t: 1581993900,
    v: 60.565498162314825
  },
  {
    t: 1581994200,
    v: 63.84232115195052
  },
  {
    t: 1581994500,
    v: 63.84232115195052
  },
  {
    t: 1581994800,
    v: 72.7234968925642
  },
  {
    t: 1581995100,
    v: 78.38626179142433
  },
  {
    t: 1581995400,
    v: 82.28841657196095
  },
  {
    t: 1581995700,
    v: 83.82843688778786
  },
  {
    t: 1581996000,
    v: 78.62005193897508
  },
  {
    t: 1581996300,
    v: 69.93030507199813
  },
  {
    t: 1581996600,
    v: 73.72365302544931
  },
  {
    t: 1581996900,
    v: 75.07141883473534
  },
  {
    t: 1581997200,
    v: 62.800354469438616
  },
  {
    t: 1581997500,
    v: 63.48253632345119
  },
  {
    t: 1581997800,
    v: 69.80984053403179
  },
  {
    t: 1581998100,
    v: 61.31122659661577
  },
  {
    t: 1581998400,
    v: 55.768954200125094
  },
  {
    t: 1581998700,
    v: 59.91056897947559
  },
  {
    t: 1581999000,
    v: 59.91056897947559
  },
  {
    t: 1581999300,
    v: 60.86774381311388
  },
  {
    t: 1581999600,
    v: 52.30440982952921
  },
  {
    t: 1581999900,
    v: 58.91094535694715
  },
  {
    t: 1582000200,
    v: 52.045210633234596
  },
  {
    t: 1582000500,
    v: 48.19375425464616
  },
  {
    t: 1582000800,
    v: 36.78620050009097
  },
  {
    t: 1582001100,
    v: 36.78620050009097
  },
  {
    t: 1582001400,
    v: 36.78620050009097
  },
  {
    t: 1582001700,
    v: 31.69575263334903
  },
  {
    t: 1582002000,
    v: 39.36693828377213
  },
  {
    t: 1582002300,
    v: 49.15593635009333
  },
  {
    t: 1582002600,
    v: 59.19462158718475
  },
  {
    t: 1582002900,
    v: 43.31285772153919
  },
  {
    t: 1582003200,
    v: 47.05895334019908
  },
  {
    t: 1582003500,
    v: 52.97781025065203
  },
  {
    t: 1582003800,
    v: 55.26421101823483
  },
  {
    t: 1582004100,
    v: 52.35484518523936
  },
  {
    t: 1582004400,
    v: 53.7462803511292
  },
  {
    t: 1582004700,
    v: 46.059789223388336
  },
  {
    t: 1582005000,
    v: 47.76722645440097
  },
  {
    t: 1582005300,
    v: 46.09951685464651
  },
  {
    t: 1582005600,
    v: 75.9042271470655
  },
  {
    t: 1582005900,
    v: 82.37247163534978
  },
  {
    t: 1582006200,
    v: 68.38585862765257
  },
  {
    t: 1582006500,
    v: 66.24339365137415
  },
  {
    t: 1582006800,
    v: 60.800998234901805
  },
  {
    t: 1582007100,
    v: 64.1656420312575
  },
  {
    t: 1582007400,
    v: 45.442841722407216
  },
  {
    t: 1582007700,
    v: 40.89684838491061
  },
  {
    t: 1582008000,
    v: 36.7008840698528
  },
  {
    t: 1582008300,
    v: 38.75276581958146
  },
  {
    t: 1582008600,
    v: 36.7126553369925
  },
  {
    t: 1582008900,
    v: 29.591243032711276
  },
  {
    t: 1582009200,
    v: 42.36764692085056
  },
  {
    t: 1582009500,
    v: 58.75994189920616
  },
  {
    t: 1582009800,
    v: 65.16093504923886
  },
  {
    t: 1582010100,
    v: 72.92048961046137
  },
  {
    t: 1582010400,
    v: 69.548466792949
  },
  {
    t: 1582010700,
    v: 61.43199136961301
  },
  {
    t: 1582011000,
    v: 59.06852594954269
  },
  {
    t: 1582011300,
    v: 69.38777481999946
  },
  {
    t: 1582011600,
    v: 59.670243003526494
  },
  {
    t: 1582011900,
    v: 53.129094358017824
  },
  {
    t: 1582012200,
    v: 54.826905034487545
  },
  {
    t: 1582012500,
    v: 41.618026976977625
  },
  {
    t: 1582012800,
    v: 34.927972294335504
  },
  {
    t: 1582013100,
    v: 52.80902354848065
  },
  {
    t: 1582013400,
    v: 33.41273668997623
  },
  {
    t: 1582013700,
    v: 33.867814280245085
  },
  {
    t: 1582014000,
    v: 39.963785189376765
  },
  {
    t: 1582014300,
    v: 38.11077570322489
  },
  {
    t: 1582014600,
    v: 36.49900679313923
  },
  {
    t: 1582014900,
    v: 28.293801001933602
  },
  {
    t: 1582015200,
    v: 37.8711429331932
  },
  {
    t: 1582015500,
    v: 35.749022883168735
  },
  {
    t: 1582015800,
    v: 34.18528260623442
  },
  {
    t: 1582016100,
    v: 34.18528260623442
  },
  {
    t: 1582016400,
    v: 40.93442752777987
  },
  {
    t: 1582016700,
    v: 53.0418420797143
  },
  {
    t: 1582017000,
    v: 50.87488794952016
  },
  {
    t: 1582017300,
    v: 56.46470110488762
  },
  {
    t: 1582017600,
    v: 53.91514951371995
  },
  {
    t: 1582017900,
    v: 50.648127048359626
  },
  {
    t: 1582018200,
    v: 45.59594011599815
  },
  {
    t: 1582018500,
    v: 48.529764590261664
  },
  {
    t: 1582018800,
    v: 43.80523250570883
  },
  {
    t: 1582019100,
    v: 52.16809388551549
  },
  {
    t: 1582019400,
    v: 42.34677372473793
  },
  {
    t: 1582019700,
    v: 40.04894997889363
  },
  {
    t: 1582020000,
    v: 27.627586199214804
  },
  {
    t: 1582020300,
    v: 41.26731033544775
  },
  {
    t: 1582020600,
    v: 27.326938280036103
  },
  {
    t: 1582020900,
    v: 43.73814740581069
  },
  {
    t: 1582021200,
    v: 52.40823027410755
  },
  {
    t: 1582021500,
    v: 40.74687891475821
  },
  {
    t: 1582021800,
    v: 51.499247029356376
  },
  {
    t: 1582022100,
    v: 57.692695405556016
  },
  {
    t: 1582022400,
    v: 64.58416985920144
  },
  {
    t: 1582022700,
    v: 53.658557248107314
  },
  {
    t: 1582023000,
    v: 60.057101695752436
  },
  {
    t: 1582023300,
    v: 46.03082565853712
  },
  {
    t: 1582023600,
    v: 51.1528735310485
  },
  {
    t: 1582023900,
    v: 60.02233186138193
  },
  {
    t: 1582024200,
    v: 60.9233323690169
  },
  {
    t: 1582024500,
    v: 61.51804599713899
  },
  {
    t: 1582024800,
    v: 57.829915296289805
  },
  {
    t: 1582025100,
    v: 59.34914676830425
  },
  {
    t: 1582025400,
    v: 52.824352009781165
  },
  {
    t: 1582025700,
    v: 56.74908792846056
  },
  {
    t: 1582026000,
    v: 45.986403945486295
  },
  {
    t: 1582026300,
    v: 42.26483395763935
  },
  {
    t: 1582026600,
    v: 39.45761573359132
  },
  {
    t: 1582026900,
    v: 31.55354158048081
  },
  {
    t: 1582027200,
    v: 28.760234791246916
  },
  {
    t: 1582027500,
    v: 29.775208725671433
  },
  {
    t: 1582027800,
    v: 26.728713809479103
  },
  {
    t: 1582028100,
    v: 26.075341368813625
  },
  {
    t: 1582028400,
    v: 21.312204512807043
  },
  {
    t: 1582028700,
    v: 19.76488314510803
  },
  {
    t: 1582029000,
    v: 15.047965536296118
  },
  {
    t: 1582029300,
    v: 21.356653605908974
  },
  {
    t: 1582029600,
    v: 21.176923805734887
  },
  {
    t: 1582029900,
    v: 28.718283713957177
  },
  {
    t: 1582030200,
    v: 23.75877281271076
  },
  {
    t: 1582030500,
    v: 22.29219885692237
  },
  {
    t: 1582030800,
    v: 38.91403731472017
  },
  {
    t: 1582031100,
    v: 33.99007495031344
  },
  {
    t: 1582031400,
    v: 36.29324433096499
  },
  {
    t: 1582031700,
    v: 25.227591145634676
  },
  {
    t: 1582032000,
    v: 26.962816187626075
  },
  {
    t: 1582032300,
    v: 32.895318877325224
  },
  {
    t: 1582032600,
    v: 13.33572944446479
  },
  {
    t: 1582032900,
    v: 17.25060538522024
  },
  {
    t: 1582033200,
    v: 30.60409777401111
  },
  {
    t: 1582033500,
    v: 27.28972704886965
  },
  {
    t: 1582033800,
    v: 37.094103134317606
  },
  {
    t: 1582034100,
    v: 36.433563533677244
  },
  {
    t: 1582034400,
    v: 30.793461600380567
  },
  {
    t: 1582034700,
    v: 25.015422804444484
  },
  {
    t: 1582035000,
    v: 23.019830786199222
  },
  {
    t: 1582035300,
    v: 19.235496343359912
  },
  {
    t: 1582035600,
    v: 31.74621372276134
  },
  {
    t: 1582035900,
    v: 35.995311160915776
  },
  {
    t: 1582036200,
    v: 45.330410495470176
  },
  {
    t: 1582036500,
    v: 49.53621360453446
  },
  {
    t: 1582036800,
    v: 48.35525082176164
  },
  {
    t: 1582037100,
    v: 43.17883721387299
  },
  {
    t: 1582037400,
    v: 58.93565693854791
  },
  {
    t: 1582037700,
    v: 66.47971593465164
  },
  {
    t: 1582038000,
    v: 75.50577570563595
  },
  {
    t: 1582038300,
    v: 69.99545904195166
  },
  {
    t: 1582038600,
    v: 62.84760557461525
  },
  {
    t: 1582038900,
    v: 67.18763715287574
  },
  {
    t: 1582039200,
    v: 66.92484856099537
  },
  {
    t: 1582039500,
    v: 70.36861994849218
  },
  {
    t: 1582039800,
    v: 62.123365804992474
  },
  {
    t: 1582040100,
    v: 69.79461410007666
  },
  {
    t: 1582040400,
    v: 64.37611877644875
  },
  {
    t: 1582040700,
    v: 65.8893614946746
  },
  {
    t: 1582041000,
    v: 66.05415720445222
  },
  {
    t: 1582041300,
    v: 72.1950337084585
  },
  {
    t: 1582041600,
    v: 67.64951192360401
  },
  {
    t: 1582041900,
    v: 65.82769934660537
  },
  {
    t: 1582042200,
    v: 65.82769934660537
  },
  {
    t: 1582042500,
    v: 56.319037861538874
  },
  {
    t: 1582042800,
    v: 59.3915954198293
  },
  {
    t: 1582043100,
    v: 53.17394939265412
  },
  {
    t: 1582043400,
    v: 40.465174785741596
  },
  {
    t: 1582043700,
    v: 37.53580375724697
  },
  {
    t: 1582044000,
    v: 40.0171205942227
  },
  {
    t: 1582044300,
    v: 41.7792175153196
  },
  {
    t: 1582044600,
    v: 41.7792175153196
  },
  {
    t: 1582044900,
    v: 45.928604589586
  },
  {
    t: 1582045200,
    v: 42.07331412649239
  },
  {
    t: 1582045500,
    v: 34.98749935809316
  },
  {
    t: 1582045800,
    v: 34.98749935809316
  },
  {
    t: 1582046100,
    v: 34.98749935809316
  },
  {
    t: 1582046400,
    v: 32.81646304471795
  },
  {
    t: 1582046700,
    v: 25.011942486004997
  },
  {
    t: 1582047000,
    v: 17.46148891880331
  },
  {
    t: 1582047300,
    v: 16.185176624981068
  },
  {
    t: 1582047600,
    v: 14.937384641527956
  },
  {
    t: 1582047900,
    v: 25.59096814452944
  },
  {
    t: 1582048200,
    v: 22.826352381333095
  },
  {
    t: 1582048500,
    v: 23.68293446908993
  },
  {
    t: 1582048800,
    v: 19.083507660022306
  },
  {
    t: 1582049100,
    v: 16.238164002708487
  },
  {
    t: 1582049400,
    v: 24.78281427452906
  },
  {
    t: 1582049700,
    v: 27.324655417787255
  },
  {
    t: 1582050000,
    v: 28.248361577436242
  },
  {
    t: 1582050300,
    v: 28.248361577436242
  },
  {
    t: 1582050600,
    v: 32.7162354810564
  },
  {
    t: 1582050900,
    v: 29.0930665680347
  },
  {
    t: 1582051200,
    v: 43.41623344761759
  },
  {
    t: 1582051500,
    v: 35.27318571762959
  },
  {
    t: 1582051800,
    v: 34.22515463636138
  },
  {
    t: 1582052100,
    v: 26.55934937771498
  },
  {
    t: 1582052400,
    v: 24.026845154021814
  },
  {
    t: 1582052700,
    v: 38.40930089191129
  },
  {
    t: 1582053000,
    v: 37.86317768426414
  },
  {
    t: 1582053300,
    v: 26.925055097928066
  },
  {
    t: 1582053600,
    v: 31.451386461771264
  },
  {
    t: 1582053900,
    v: 32.408344455527526
  },
  {
    t: 1582054200,
    v: 28.339092387616603
  },
  {
    t: 1582054500,
    v: 19.59227466626949
  },
  {
    t: 1582054800,
    v: 17.798486647669066
  },
  {
    t: 1582055100,
    v: 18.859753358502942
  },
  {
    t: 1582055400,
    v: 29.29257320801952
  },
  {
    t: 1582055700,
    v: 24.00206693900165
  },
  {
    t: 1582056000,
    v: 42.86972358407469
  },
  {
    t: 1582056300,
    v: 40.93400968649335
  },
  {
    t: 1582056600,
    v: 39.40877514485485
  },
  {
    t: 1582056900,
    v: 27.63902944217361
  },
  {
    t: 1582057200,
    v: 26.435935579927815
  },
  {
    t: 1582057500,
    v: 22.756672882130502
  },
  {
    t: 1582057800,
    v: 30.41879647339178
  },
  {
    t: 1582058100,
    v: 47.58477071168677
  },
  {
    t: 1582058400,
    v: 41.31275281021838
  },
  {
    t: 1582058700,
    v: 44.30887299569291
  },
  {
    t: 1582059000,
    v: 46.192527638610265
  },
  {
    t: 1582059300,
    v: 58.076596931915944
  },
  {
    t: 1582059600,
    v: 54.336081778040274
  },
  {
    t: 1582059900,
    v: 54.889493883019675
  },
  {
    t: 1582060200,
    v: 47.06669172409894
  },
  {
    t: 1582060500,
    v: 40.95228106187706
  },
  {
    t: 1582060800,
    v: 43.25015463080483
  },
  {
    t: 1582061100,
    v: 36.71803187812829
  },
  {
    t: 1582061400,
    v: 32.928861947801096
  },
  {
    t: 1582061700,
    v: 29.848593552012574
  },
  {
    t: 1582062000,
    v: 25.893976022998416
  },
  {
    t: 1582062300,
    v: 44.41141307270848
  },
  {
    t: 1582062600,
    v: 49.64095995079291
  },
  {
    t: 1582062900,
    v: 40.40876641492433
  },
  {
    t: 1582063200,
    v: 40.40876641492433
  },
  {
    t: 1582063500,
    v: 50.92681567486229
  },
  {
    t: 1582063800,
    v: 51.577988604928194
  },
  {
    t: 1582064100,
    v: 50.807490124375875
  },
  {
    t: 1582064400,
    v: 49.95463527310752
  },
  {
    t: 1582064700,
    v: 56.61328388326413
  },
  {
    t: 1582065000,
    v: 55.557280815680826
  },
  {
    t: 1582065300,
    v: 63.36664200450512
  },
  {
    t: 1582065600,
    v: 65.44824633740612
  },
  {
    t: 1582065900,
    v: 69.41998844291514
  },
  {
    t: 1582066200,
    v: 63.829697389129876
  },
  {
    t: 1582066500,
    v: 57.2442713806796
  },
  {
    t: 1582066800,
    v: 53.462026015826616
  },
  {
    t: 1582067100,
    v: 64.93558812563222
  },
  {
    t: 1582067400,
    v: 55.34005768305233
  },
  {
    t: 1582067700,
    v: 59.6038687118717
  },
  {
    t: 1582068000,
    v: 63.57794486695094
  },
  {
    t: 1582068300,
    v: 65.87966687482223
  },
  {
    t: 1582068600,
    v: 62.8533291165684
  },
  {
    t: 1582068900,
    v: 69.95211888872487
  },
  {
    t: 1582069200,
    v: 70.66401165320718
  },
  {
    t: 1582069500,
    v: 63.75857325892757
  },
  {
    t: 1582069800,
    v: 60.38651636333233
  },
  {
    t: 1582070100,
    v: 45.32031259937215
  },
  {
    t: 1582070400,
    v: 33.25276702697599
  },
  {
    t: 1582070700,
    v: 38.59195515517136
  },
  {
    t: 1582071000,
    v: 45.99723027215881
  },
  {
    t: 1582071300,
    v: 50.54143727941287
  },
  {
    t: 1582071600,
    v: 55.848884815641
  },
  {
    t: 1582071900,
    v: 61.51299311921069
  },
  {
    t: 1582072200,
    v: 65.7033608467229
  },
  {
    t: 1582072500,
    v: 59.75503889949491
  },
  {
    t: 1582072800,
    v: 64.36395065315128
  },
  {
    t: 1582073100,
    v: 68.48825983146101
  },
  {
    t: 1582073400,
    v: 79.7420430363176
  },
  {
    t: 1582073700,
    v: 65.69662807032455
  },
  {
    t: 1582074000,
    v: 66.9077900923792
  },
  {
    t: 1582074300,
    v: 68.19131157320173
  },
  {
    t: 1582074600,
    v: 55.81968731185315
  },
  {
    t: 1582074900,
    v: 68.77023924484553
  },
  {
    t: 1582075200,
    v: 73.99693510833026
  },
  {
    t: 1582075500,
    v: 70.62003320010592
  },
  {
    t: 1582075800,
    v: 79.01214549666126
  },
  {
    t: 1582076100,
    v: 79.59147508310717
  },
  {
    t: 1582076400,
    v: 79.59147508310717
  },
  {
    t: 1582076700,
    v: 80.96407962867363
  },
  {
    t: 1582077000,
    v: 77.96760927188906
  },
  {
    t: 1582077300,
    v: 63.25287265908743
  },
  {
    t: 1582077600,
    v: 46.08401363941308
  },
  {
    t: 1582077900,
    v: 59.355770508368586
  },
  {
    t: 1582078200,
    v: 62.998983094618964
  },
  {
    t: 1582078500,
    v: 52.92923716741306
  },
  {
    t: 1582078800,
    v: 50.11254111475488
  },
  {
    t: 1582079100,
    v: 44.67803192964955
  },
  {
    t: 1582079400,
    v: 40.87824412267246
  },
  {
    t: 1582079700,
    v: 40.87824412267246
  },
  {
    t: 1582080000,
    v: 40.1351852470518
  },
  {
    t: 1582080300,
    v: 35.68698755962728
  },
  {
    t: 1582080600,
    v: 35.68698755962727
  },
  {
    t: 1582080900,
    v: 35.68698755962727
  },
  {
    t: 1582081200,
    v: 40.60045175250962
  },
  {
    t: 1582081500,
    v: 37.34015586380353
  },
  {
    t: 1582081800,
    v: 54.165687915775266
  },
  {
    t: 1582082100,
    v: 66.24938692007535
  },
  {
    t: 1582082400,
    v: 64.85363757477637
  },
  {
    t: 1582082700,
    v: 64.85363757477637
  },
  {
    t: 1582083000,
    v: 59.908070987341254
  },
  {
    t: 1582083300,
    v: 49.78428957070364
  },
  {
    t: 1582083600,
    v: 44.83617365638159
  },
  {
    t: 1582083900,
    v: 44.83617365638158
  },
  {
    t: 1582084200,
    v: 38.57634389719336
  },
  {
    t: 1582084500,
    v: 32.377023369131024
  },
  {
    t: 1582084800,
    v: 27.35330991375018
  },
  {
    t: 1582085100,
    v: 39.80639681615915
  },
  {
    t: 1582085400,
    v: 33.28548881511385
  },
  {
    t: 1582085700,
    v: 28.682705992227156
  },
  {
    t: 1582086000,
    v: 30.51295460708276
  },
  {
    t: 1582086300,
    v: 39.39986450499613
  },
  {
    t: 1582086600,
    v: 41.121054824558676
  },
  {
    t: 1582086900,
    v: 31.82401441635065
  },
  {
    t: 1582087200,
    v: 23.17461736131746
  },
  {
    t: 1582087500,
    v: 22.11768050549948
  },
  {
    t: 1582087800,
    v: 21.555902626070818
  },
  {
    t: 1582088100,
    v: 43.047659891367175
  },
  {
    t: 1582088400,
    v: 45.6650561063049
  },
  {
    t: 1582088700,
    v: 45.6650561063049
  },
  {
    t: 1582089000,
    v: 34.33089060381933
  },
  {
    t: 1582089300,
    v: 31.865705108860112
  },
  {
    t: 1582089600,
    v: 31.865705108860112
  },
  {
    t: 1582089900,
    v: 31.865705108860112
  },
  {
    t: 1582090200,
    v: 31.865705108860112
  },
  {
    t: 1582090500,
    v: 34.53868559507495
  },
  {
    t: 1582090800,
    v: 25.42069134649735
  },
  {
    t: 1582091100,
    v: 30.651429462811407
  },
  {
    t: 1582091400,
    v: 24.7095629657599
  },
  {
    t: 1582091700,
    v: 36.44347895200439
  },
  {
    t: 1582092000,
    v: 20.84779302797952
  },
  {
    t: 1582092300,
    v: 18.673343217242234
  },
  {
    t: 1582092600,
    v: 28.85066158467079
  },
  {
    t: 1582092900,
    v: 27.537853569974644
  },
  {
    t: 1582093200,
    v: 46.87637063020201
  },
  {
    t: 1582093500,
    v: 53.90580189896353
  },
  {
    t: 1582093800,
    v: 51.673148646944924
  },
  {
    t: 1582094100,
    v: 60.9218665145658
  },
  {
    t: 1582094400,
    v: 64.77413580696746
  },
  {
    t: 1582094700,
    v: 70.71321342564644
  },
  {
    t: 1582095000,
    v: 59.28906375872491
  },
  {
    t: 1582095300,
    v: 68.37275147218085
  },
  {
    t: 1582095600,
    v: 68.37275147218085
  },
  {
    t: 1582095900,
    v: 51.28994622183403
  },
  {
    t: 1582096200,
    v: 58.666453382616126
  },
  {
    t: 1582096500,
    v: 64.76466524171562
  },
  {
    t: 1582096800,
    v: 73.97854679771122
  },
  {
    t: 1582097100,
    v: 72.94052668064646
  },
  {
    t: 1582097400,
    v: 67.5263179483601
  },
  {
    t: 1582097700,
    v: 67.5263179483601
  },
  {
    t: 1582098000,
    v: 77.23672468761632
  },
  {
    t: 1582098300,
    v: 78.56837514759712
  },
  {
    t: 1582098600,
    v: 78.56837514759712
  },
  {
    t: 1582098900,
    v: 70.48943025575362
  },
  {
    t: 1582099200,
    v: 39.963229552018106
  },
  {
    t: 1582099500,
    v: 35.86675136589763
  },
  {
    t: 1582099800,
    v: 52.99756225004995
  },
  {
    t: 1582100100,
    v: 59.978885650238354
  },
  {
    t: 1582100400,
    v: 64.82964218123499
  },
  {
    t: 1582100700,
    v: 68.81686019409338
  },
  {
    t: 1582101000,
    v: 71.57865282160765
  },
  {
    t: 1582101300,
    v: 71.57865282160765
  },
  {
    t: 1582101600,
    v: 71.93932342531937
  },
  {
    t: 1582101900,
    v: 72.73030843942182
  },
  {
    t: 1582102200,
    v: 67.30933392079879
  },
  {
    t: 1582102500,
    v: 57.51133614145086
  },
  {
    t: 1582102800,
    v: 67.30261668805892
  },
  {
    t: 1582103100,
    v: 61.04989858879023
  },
  {
    t: 1582103400,
    v: 69.40351982012457
  },
  {
    t: 1582103700,
    v: 56.98743381451487
  },
  {
    t: 1582104000,
    v: 41.195213532260986
  },
  {
    t: 1582104300,
    v: 41.80952229060008
  },
  {
    t: 1582104600,
    v: 46.88282667407322
  },
  {
    t: 1582104900,
    v: 45.743366690341716
  },
  {
    t: 1582105200,
    v: 39.20886135032471
  },
  {
    t: 1582105500,
    v: 37.18525493561727
  },
  {
    t: 1582105800,
    v: 38.098059983002685
  },
  {
    t: 1582106100,
    v: 52.94580049110794
  },
  {
    t: 1582106400,
    v: 61.3172879147649
  },
  {
    t: 1582106700,
    v: 63.30681705712534
  },
  {
    t: 1582107000,
    v: 59.79226223643297
  },
  {
    t: 1582107300,
    v: 51.60654752182142
  },
  {
    t: 1582107600,
    v: 36.683740397747734
  },
  {
    t: 1582107900,
    v: 31.48178093092521
  },
  {
    t: 1582108200,
    v: 37.69549655205004
  },
  {
    t: 1582108500,
    v: 51.020827531681086
  },
  {
    t: 1582108800,
    v: 62.39963254079122
  },
  {
    t: 1582109100,
    v: 44.95489628649457
  },
  {
    t: 1582109400,
    v: 43.14124653859091
  },
  {
    t: 1582109700,
    v: 41.23979666023316
  },
  {
    t: 1582110000,
    v: 37.8114844007894
  },
  {
    t: 1582110300,
    v: 30.68517626242074
  },
  {
    t: 1582110600,
    v: 24.846802593673274
  },
  {
    t: 1582110900,
    v: 25.521601391833002
  },
  {
    t: 1582111200,
    v: 24.276035919994897
  },
  {
    t: 1582111500,
    v: 22.993538789092113
  },
  {
    t: 1582111800,
    v: 13.918015835256568
  },
  {
    t: 1582112100,
    v: 12.277121661482283
  },
  {
    t: 1582112400,
    v: 20.32831842813735
  },
  {
    t: 1582112700,
    v: 29.014981981589315
  },
  {
    t: 1582113000,
    v: 40.193421488030324
  },
  {
    t: 1582113300,
    v: 39.516105590597085
  },
  {
    t: 1582113600,
    v: 48.870640434136114
  },
  {
    t: 1582113900,
    v: 54.387002485111005
  },
  {
    t: 1582114200,
    v: 60.068214450741536
  },
  {
    t: 1582114500,
    v: 57.34717343057784
  },
  {
    t: 1582114800,
    v: 63.7311966393743
  },
  {
    t: 1582115100,
    v: 69.02897896939955
  },
  {
    t: 1582115400,
    v: 56.31083558249504
  },
  {
    t: 1582115700,
    v: 62.19464527171533
  },
  {
    t: 1582116000,
    v: 61.08848700654158
  },
  {
    t: 1582116300,
    v: 65.31818438354243
  },
  {
    t: 1582116600,
    v: 74.73436211486467
  },
  {
    t: 1582116900,
    v: 62.37013940326651
  },
  {
    t: 1582117200,
    v: 45.802729033648546
  },
  {
    t: 1582117500,
    v: 52.37455731036441
  },
  {
    t: 1582117800,
    v: 55.46068753543489
  },
  {
    t: 1582118100,
    v: 50.25069307921369
  },
  {
    t: 1582118400,
    v: 58.78445845020744
  },
  {
    t: 1582118700,
    v: 53.360347879010156
  },
  {
    t: 1582119000,
    v: 47.94345204895056
  },
  {
    t: 1582119300,
    v: 36.61537892364168
  },
  {
    t: 1582119600,
    v: 32.45278308045414
  },
  {
    t: 1582119900,
    v: 40.57933909023065
  },
  {
    t: 1582120200,
    v: 50.73912198152258
  },
  {
    t: 1582120500,
    v: 41.31937160540145
  },
  {
    t: 1582120800,
    v: 35.507635726830316
  },
  {
    t: 1582121100,
    v: 32.956875004279325
  },
  {
    t: 1582121400,
    v: 39.1095725335962
  },
  {
    t: 1582121700,
    v: 44.88968340606194
  },
  {
    t: 1582122000,
    v: 43.1348392851743
  },
  {
    t: 1582122300,
    v: 46.706340109060015
  },
  {
    t: 1582122600,
    v: 32.64641113588644
  },
  {
    t: 1582122900,
    v: 39.91910745161251
  },
  {
    t: 1582123200,
    v: 40.30680928172106
  },
  {
    t: 1582123500,
    v: 37.28233940626106
  },
  {
    t: 1582123800,
    v: 32.91947313121628
  },
  {
    t: 1582124100,
    v: 33.958575181260514
  },
  {
    t: 1582124400,
    v: 49.57928410896292
  },
  {
    t: 1582124700,
    v: 44.43181741829605
  },
  {
    t: 1582125000,
    v: 45.719935252312155
  },
  {
    t: 1582125300,
    v: 42.01010640490696
  },
  {
    t: 1582125600,
    v: 35.716954243810974
  },
  {
    t: 1582125900,
    v: 29.808026247439315
  },
  {
    t: 1582126200,
    v: 28.81738125136667
  },
  {
    t: 1582126500,
    v: 46.57330095707319
  },
  {
    t: 1582126800,
    v: 41.203620141402446
  },
  {
    t: 1582127100,
    v: 46.889612095944926
  },
  {
    t: 1582127400,
    v: 57.68265523555705
  },
  {
    t: 1582127700,
    v: 61.82066513756474
  },
  {
    t: 1582128000,
    v: 49.24227375982164
  },
  {
    t: 1582128300,
    v: 52.99918931120704
  },
  {
    t: 1582128600,
    v: 65.08246380845068
  },
  {
    t: 1582128900,
    v: 66.60859362314163
  },
  {
    t: 1582129200,
    v: 58.2897329077844
  },
  {
    t: 1582129500,
    v: 66.62600345900574
  },
  {
    t: 1582129800,
    v: 53.1088749832404
  },
  {
    t: 1582130100,
    v: 49.572258472729246
  },
  {
    t: 1582130400,
    v: 62.25836052206637
  },
  {
    t: 1582130700,
    v: 65.78488104084298
  },
  {
    t: 1582131000,
    v: 53.710084394580164
  },
  {
    t: 1582131300,
    v: 51.54745117224942
  },
  {
    t: 1582131600,
    v: 50.67301721493565
  },
  {
    t: 1582131900,
    v: 41.325844065155515
  },
  {
    t: 1582132200,
    v: 36.49047503963915
  },
  {
    t: 1582132500,
    v: 46.28520248120204
  },
  {
    t: 1582132800,
    v: 48.40704255235928
  },
  {
    t: 1582133100,
    v: 52.398034473308485
  },
  {
    t: 1582133400,
    v: 51.01045393677449
  },
  {
    t: 1582133700,
    v: 45.811231646456086
  },
  {
    t: 1582134000,
    v: 58.40789211447881
  },
  {
    t: 1582134300,
    v: 62.263769848054885
  },
  {
    t: 1582134600,
    v: 56.298860161172485
  },
  {
    t: 1582134900,
    v: 48.20606311130876
  },
  {
    t: 1582135200,
    v: 45.60037994982256
  },
  {
    t: 1582135500,
    v: 50.213694305973284
  },
  {
    t: 1582135800,
    v: 50.213694305973284
  },
  {
    t: 1582136100,
    v: 54.42082754431811
  },
  {
    t: 1582136400,
    v: 49.19470751812916
  },
  {
    t: 1582136700,
    v: 51.608778783196584
  },
  {
    t: 1582137000,
    v: 47.48312872582839
  },
  {
    t: 1582137300,
    v: 53.812703741686846
  },
  {
    t: 1582137600,
    v: 54.3368497433155
  },
  {
    t: 1582137900,
    v: 59.5792059287471
  },
  {
    t: 1582138200,
    v: 56.60827526132122
  },
  {
    t: 1582138500,
    v: 72.60857793989159
  },
  {
    t: 1582138800,
    v: 52.997968213241016
  },
  {
    t: 1582139100,
    v: 51.66891249750194
  },
  {
    t: 1582139400,
    v: 56.2648868745765
  },
  {
    t: 1582139700,
    v: 54.64522714758344
  },
  {
    t: 1582140000,
    v: 64.09503150902918
  },
  {
    t: 1582140300,
    v: 67.03831715366252
  },
  {
    t: 1582140600,
    v: 71.48992517253711
  },
  {
    t: 1582140900,
    v: 67.99180440445508
  },
  {
    t: 1582141200,
    v: 72.85025353590335
  },
  {
    t: 1582141500,
    v: 62.65989723091456
  },
  {
    t: 1582141800,
    v: 55.034887148340985
  },
  {
    t: 1582142100,
    v: 47.4247022186764
  },
  {
    t: 1582142400,
    v: 60.98810837690452
  },
  {
    t: 1582142700,
    v: 64.76567580413214
  },
  {
    t: 1582143000,
    v: 67.74063950218934
  },
  {
    t: 1582143300,
    v: 74.79417157171268
  },
  {
    t: 1582143600,
    v: 75.42933146699012
  },
  {
    t: 1582143900,
    v: 67.63778913723381
  },
  {
    t: 1582144200,
    v: 63.280073454973895
  },
  {
    t: 1582144500,
    v: 67.69726329692759
  },
  {
    t: 1582144800,
    v: 71.6014641940157
  },
  {
    t: 1582145100,
    v: 77.74859240273301
  },
  {
    t: 1582145400,
    v: 69.7812264489029
  },
  {
    t: 1582145700,
    v: 76.08346634680058
  },
  {
    t: 1582146000,
    v: 65.6515567841162
  },
  {
    t: 1582146300,
    v: 67.35661571019025
  },
  {
    t: 1582146600,
    v: 69.91662802331214
  },
  {
    t: 1582146900,
    v: 67.8884164921144
  },
  {
    t: 1582147200,
    v: 74.16862043047325
  },
  {
    t: 1582147500,
    v: 60.61936693462819
  },
  {
    t: 1582147800,
    v: 59.49024667824099
  },
  {
    t: 1582148100,
    v: 62.299168922410686
  },
  {
    t: 1582148400,
    v: 53.255765296956724
  },
  {
    t: 1582148700,
    v: 52.10325399691511
  },
  {
    t: 1582149000,
    v: 45.86434824425186
  },
  {
    t: 1582149300,
    v: 33.099281284172605
  },
  {
    t: 1582149600,
    v: 49.63597071550589
  },
  {
    t: 1582149900,
    v: 47.13912054855122
  },
  {
    t: 1582150200,
    v: 50.01285254284781
  },
  {
    t: 1582150500,
    v: 46.18672265727061
  },
  {
    t: 1582150800,
    v: 48.440687572609036
  },
  {
    t: 1582151100,
    v: 48.44068757260903
  },
  {
    t: 1582151400,
    v: 49.13633512451363
  },
  {
    t: 1582151700,
    v: 46.28174470027182
  },
  {
    t: 1582152000,
    v: 62.03566912192734
  },
  {
    t: 1582152300,
    v: 51.07894558986084
  },
  {
    t: 1582152600,
    v: 52.28291403950576
  },
  {
    t: 1582152900,
    v: 52.28291403950576
  },
  {
    t: 1582153200,
    v: 55.165270823617924
  },
  {
    t: 1582153500,
    v: 58.72685601096094
  },
  {
    t: 1582153800,
    v: 56.668948380942055
  },
  {
    t: 1582154100,
    v: 55.55648471384874
  },
  {
    t: 1582154400,
    v: 55.55648471384874
  },
  {
    t: 1582154700,
    v: 55.55648471384874
  },
  {
    t: 1582155000,
    v: 48.4567686928814
  },
  {
    t: 1582155300,
    v: 44.55265045172482
  },
  {
    t: 1582155600,
    v: 55.483424958301356
  },
  {
    t: 1582155900,
    v: 57.8571271849613
  },
  {
    t: 1582156200,
    v: 73.30908486712651
  },
  {
    t: 1582156500,
    v: 64.7419649602146
  },
  {
    t: 1582156800,
    v: 35.94087621621101
  },
  {
    t: 1582157100,
    v: 53.624310681595084
  },
  {
    t: 1582157400,
    v: 67.62220699885927
  },
  {
    t: 1582157700,
    v: 72.92342475794041
  },
  {
    t: 1582158000,
    v: 59.63828296095613
  },
  {
    t: 1582158300,
    v: 56.80003129607956
  },
  {
    t: 1582158600,
    v: 57.569297554686514
  },
  {
    t: 1582158900,
    v: 56.42107375165572
  },
  {
    t: 1582159200,
    v: 54.555728687598986
  },
  {
    t: 1582159500,
    v: 52.56943663393963
  },
  {
    t: 1582159800,
    v: 58.84795969722923
  },
  {
    t: 1582160100,
    v: 53.015021178222305
  },
  {
    t: 1582160400,
    v: 57.79584900743899
  },
  {
    t: 1582160700,
    v: 58.40054030475905
  },
  {
    t: 1582161000,
    v: 39.162460506997675
  },
  {
    t: 1582161300,
    v: 42.75438951458617
  },
  {
    t: 1582161600,
    v: 30.728496115517345
  },
  {
    t: 1582161900,
    v: 28.812218033310046
  },
  {
    t: 1582162200,
    v: 23.059987476800657
  },
  {
    t: 1582162500,
    v: 24.696345363577308
  },
  {
    t: 1582162800,
    v: 17.7812260931006
  },
  {
    t: 1582163100,
    v: 32.05084321412889
  },
  {
    t: 1582163400,
    v: 23.91801482862013
  },
  {
    t: 1582163700,
    v: 27.97915382843759
  },
  {
    t: 1582164000,
    v: 37.27240142911006
  },
  {
    t: 1582164300,
    v: 34.85365349980461
  },
  {
    t: 1582164600,
    v: 30.84973956691998
  },
  {
    t: 1582164900,
    v: 28.69683710262545
  },
  {
    t: 1582165200,
    v: 33.96364966360464
  },
  {
    t: 1582165500,
    v: 35.98997999547987
  },
  {
    t: 1582165800,
    v: 27.115028759155848
  },
  {
    t: 1582166100,
    v: 24.862807931648476
  },
  {
    t: 1582166400,
    v: 30.70954717066664
  },
  {
    t: 1582166700,
    v: 30.70954717066664
  },
  {
    t: 1582167000,
    v: 23.307027526002486
  },
  {
    t: 1582167300,
    v: 19.449277578677908
  },
  {
    t: 1582167600,
    v: 37.76289186361175
  },
  {
    t: 1582167900,
    v: 45.808997124349325
  },
  {
    t: 1582168200,
    v: 52.012847122768406
  },
  {
    t: 1582168500,
    v: 54.560736374187385
  },
  {
    t: 1582168800,
    v: 50.953548718930065
  },
  {
    t: 1582169100,
    v: 52.491954277341264
  },
  {
    t: 1582169400,
    v: 41.175698520812496
  },
  {
    t: 1582169700,
    v: 47.87598306736136
  },
  {
    t: 1582170000,
    v: 48.4353531267019
  },
  {
    t: 1582170300,
    v: 55.05076432247528
  },
  {
    t: 1582170600,
    v: 47.00942368536551
  },
  {
    t: 1582170900,
    v: 44.36437661201502
  },
  {
    t: 1582171200,
    v: 45.759535959539505
  },
  {
    t: 1582171500,
    v: 44.48464531615571
  },
  {
    t: 1582171800,
    v: 41.20467599755209
  },
  {
    t: 1582172100,
    v: 39.86110410057962
  },
  {
    t: 1582172400,
    v: 46.79993864957403
  },
  {
    t: 1582172700,
    v: 46.79993864957403
  },
  {
    t: 1582173000,
    v: 46.79993864957403
  },
  {
    t: 1582173300,
    v: 46.79993864957403
  },
  {
    t: 1582173600,
    v: 53.35763624434123
  },
  {
    t: 1582173900,
    v: 40.73341267047147
  },
  {
    t: 1582174200,
    v: 50.47297770403602
  },
  {
    t: 1582174500,
    v: 62.053878605107926
  },
  {
    t: 1582174800,
    v: 64.93696844791918
  },
  {
    t: 1582175100,
    v: 56.370999165717386
  },
  {
    t: 1582175400,
    v: 50.89089854122305
  },
  {
    t: 1582175700,
    v: 49.784656254056024
  },
  {
    t: 1582176000,
    v: 38.35150921472607
  },
  {
    t: 1582176300,
    v: 35.266193870946125
  },
  {
    t: 1582176600,
    v: 40.71675270029087
  },
  {
    t: 1582176900,
    v: 48.19447261050304
  },
  {
    t: 1582177200,
    v: 47.063728750090526
  },
  {
    t: 1582177500,
    v: 53.321832458825526
  },
  {
    t: 1582177800,
    v: 46.97511444707751
  },
  {
    t: 1582178100,
    v: 42.36479345370908
  },
  {
    t: 1582178400,
    v: 42.36479345370908
  },
  {
    t: 1582178700,
    v: 52.92506307773588
  },
  {
    t: 1582179000,
    v: 42.70507221717135
  },
  {
    t: 1582179300,
    v: 24.475260813195703
  },
  {
    t: 1582179600,
    v: 23.216759274192995
  },
  {
    t: 1582179900,
    v: 21.529827003325025
  },
  {
    t: 1582180200,
    v: 16.16158638151488
  },
  {
    t: 1582180500,
    v: 14.601293979590423
  },
  {
    t: 1582180800,
    v: 16.143350208973814
  },
  {
    t: 1582181100,
    v: 12.939101413949444
  },
  {
    t: 1582181400,
    v: 9.389285673428773
  },
  {
    t: 1582181700,
    v: 14.736027329520653
  },
  {
    t: 1582182000,
    v: 38.560255463867946
  },
  {
    t: 1582182300,
    v: 31.551282480599426
  },
  {
    t: 1582182600,
    v: 36.49808444223875
  },
  {
    t: 1582182900,
    v: 22.537211003757918
  },
  {
    t: 1582183200,
    v: 20.010150402377093
  },
  {
    t: 1582183500,
    v: 32.62828815151066
  },
  {
    t: 1582183800,
    v: 39.515224214999726
  },
  {
    t: 1582184100,
    v: 46.77028927401064
  },
  {
    t: 1582184400,
    v: 60.64352679877887
  },
  {
    t: 1582184700,
    v: 59.174700349698625
  },
  {
    t: 1582185000,
    v: 46.013307035645916
  },
  {
    t: 1582185300,
    v: 54.57929998649414
  },
  {
    t: 1582185600,
    v: 60.22408928651715
  },
  {
    t: 1582185900,
    v: 58.381417520477015
  },
  {
    t: 1582186200,
    v: 49.69309833879006
  },
  {
    t: 1582186500,
    v: 34.660644160825726
  },
  {
    t: 1582186800,
    v: 49.140830184354535
  },
  {
    t: 1582187100,
    v: 55.57096490690638
  },
  {
    t: 1582187400,
    v: 57.8545874897093
  },
  {
    t: 1582187700,
    v: 58.127882566702425
  },
  {
    t: 1582188000,
    v: 54.87450795926523
  },
  {
    t: 1582188300,
    v: 64.06804036093797
  },
  {
    t: 1582188600,
    v: 58.923220742143265
  },
  {
    t: 1582188900,
    v: 66.58891145995196
  },
  {
    t: 1582189200,
    v: 68.59811454234548
  },
  {
    t: 1582189500,
    v: 63.73139756968736
  },
  {
    t: 1582189800,
    v: 65.1444485186166
  },
  {
    t: 1582190100,
    v: 71.06995964910037
  },
  {
    t: 1582190400,
    v: 79.78068870648434
  },
  {
    t: 1582190700,
    v: 79.24599562698964
  },
  {
    t: 1582191000,
    v: 71.07952330256933
  },
  {
    t: 1582191300,
    v: 71.52664575976486
  },
  {
    t: 1582191600,
    v: 75.05296698989255
  },
  {
    t: 1582191900,
    v: 66.78223089677834
  },
  {
    t: 1582192200,
    v: 53.34544040931121
  },
  {
    t: 1582192500,
    v: 50.8403229743039
  },
  {
    t: 1582192800,
    v: 38.456737203808146
  },
  {
    t: 1582193100,
    v: 31.612248897165813
  },
  {
    t: 1582193400,
    v: 40.66413425725715
  },
  {
    t: 1582193700,
    v: 36.461792464444486
  },
  {
    t: 1582194000,
    v: 31.56826710360761
  },
  {
    t: 1582194300,
    v: 33.97931860652636
  },
  {
    t: 1582194600,
    v: 47.50697271261433
  },
  {
    t: 1582194900,
    v: 50.41011009180515
  },
  {
    t: 1582195200,
    v: 48.86580543536691
  },
  {
    t: 1582195500,
    v: 51.009813373172975
  },
  {
    t: 1582195800,
    v: 36.37014509752429
  },
  {
    t: 1582196100,
    v: 36.37014509752429
  },
  {
    t: 1582196400,
    v: 35.42172006900884
  },
  {
    t: 1582196700,
    v: 42.80772193101758
  },
  {
    t: 1582197000,
    v: 39.62070941780753
  },
  {
    t: 1582197300,
    v: 26.796028739388007
  },
  {
    t: 1582197600,
    v: 31.838953680688235
  },
  {
    t: 1582197900,
    v: 46.379740442484305
  },
  {
    t: 1582198200,
    v: 46.379740442484305
  },
  {
    t: 1582198500,
    v: 46.3797404424843
  },
  {
    t: 1582198800,
    v: 44.993195665626224
  },
  {
    t: 1582199100,
    v: 57.929670664604885
  },
  {
    t: 1582199400,
    v: 62.4189615250081
  },
  {
    t: 1582199700,
    v: 65.2446494369236
  },
  {
    t: 1582200000,
    v: 51.43315805036085
  },
  {
    t: 1582200300,
    v: 51.89864225492935
  },
  {
    t: 1582200600,
    v: 60.54264662893373
  },
  {
    t: 1582200900,
    v: 52.93285218975234
  },
  {
    t: 1582201200,
    v: 56.50329271704573
  },
  {
    t: 1582201500,
    v: 38.829857592746016
  },
  {
    t: 1582201800,
    v: 28.250333618693702
  },
  {
    t: 1582202100,
    v: 34.71818261146457
  },
  {
    t: 1582202400,
    v: 47.47361888393274
  },
  {
    t: 1582202700,
    v: 39.91159267624055
  },
  {
    t: 1582203000,
    v: 46.206924607118225
  },
  {
    t: 1582203300,
    v: 40.07558845718719
  },
  {
    t: 1582203600,
    v: 46.11542964646497
  },
  {
    t: 1582203900,
    v: 46.835895276623674
  },
  {
    t: 1582204200,
    v: 44.782961884709025
  },
  {
    t: 1582204500,
    v: 49.42751279288824
  },
  {
    t: 1582204800,
    v: 54.94046947948989
  },
  {
    t: 1582205100,
    v: 66.54960807609474
  },
  {
    t: 1582205400,
    v: 51.71328195490496
  },
  {
    t: 1582205700,
    v: 62.90540283748486
  },
  {
    t: 1582206000,
    v: 67.32881008642136
  },
  {
    t: 1582206300,
    v: 64.2817028601844
  },
  {
    t: 1582206600,
    v: 65.82821884860124
  },
  {
    t: 1582206900,
    v: 75.76472200245091
  },
  {
    t: 1582207200,
    v: 84.18949999174023
  },
  {
    t: 1582207500,
    v: 86.24594977142358
  },
  {
    t: 1582207800,
    v: 71.64102192895304
  },
  {
    t: 1582208100,
    v: 65.80406091475126
  },
  {
    t: 1582208400,
    v: 67.84545969384344
  },
  {
    t: 1582208700,
    v: 71.47786325577727
  },
  {
    t: 1582209000,
    v: 76.28845252263191
  },
  {
    t: 1582209300,
    v: 65.15635770725069
  },
  {
    t: 1582209600,
    v: 61.47608765260466
  },
  {
    t: 1582209900,
    v: 67.34778950086525
  },
  {
    t: 1582210200,
    v: 67.63688495429332
  },
  {
    t: 1582210500,
    v: 64.07117511171856
  },
  {
    t: 1582210800,
    v: 64.26689386165555
  },
  {
    t: 1582211100,
    v: 64.7063424065446
  },
  {
    t: 1582211400,
    v: 66.13428488692533
  },
  {
    t: 1582211700,
    v: 56.176996328290414
  },
  {
    t: 1582212000,
    v: 41.5626441468691
  },
  {
    t: 1582212300,
    v: 56.23545782091878
  },
  {
    t: 1582212600,
    v: 53.07450811316772
  },
  {
    t: 1582212900,
    v: 39.133713971992364
  },
  {
    t: 1582213200,
    v: 31.28252219568394
  },
  {
    t: 1582213500,
    v: 28.466930874952993
  },
  {
    t: 1582213800,
    v: 30.733952025309577
  },
  {
    t: 1582214100,
    v: 30.5757472095992
  },
  {
    t: 1582214400,
    v: 45.361671382292826
  },
  {
    t: 1582214700,
    v: 43.96582429041011
  },
  {
    t: 1582215000,
    v: 43.45640743529872
  },
  {
    t: 1582215300,
    v: 35.28070095560673
  },
  {
    t: 1582215600,
    v: 21.771971486718655
  },
  {
    t: 1582215900,
    v: 17.83181404973061
  },
  {
    t: 1582216200,
    v: 28.299743760101492
  },
  {
    t: 1582216500,
    v: 25.329015959190656
  },
  {
    t: 1582216800,
    v: 33.32768683822205
  },
  {
    t: 1582217100,
    v: 40.37533021456081
  },
  {
    t: 1582217400,
    v: 41.879891826395564
  },
  {
    t: 1582217700,
    v: 48.11451969638814
  },
  {
    t: 1582218000,
    v: 56.08292152660006
  },
  {
    t: 1582218300,
    v: 53.543011110176536
  },
  {
    t: 1582218600,
    v: 56.25971657146616
  },
  {
    t: 1582218900,
    v: 58.56723971982316
  },
  {
    t: 1582219200,
    v: 58.815547153344234
  },
  {
    t: 1582219500,
    v: 57.24716540524456
  },
  {
    t: 1582219800,
    v: 45.468821975650286
  },
  {
    t: 1582220100,
    v: 42.804566492866165
  },
  {
    t: 1582220400,
    v: 47.13169391447167
  },
  {
    t: 1582220700,
    v: 40.183007551724195
  },
  {
    t: 1582221000,
    v: 40.183007551724195
  },
  {
    t: 1582221300,
    v: 31.825948290978815
  },
  {
    t: 1582221600,
    v: 34.919680112438755
  },
  {
    t: 1582221900,
    v: 32.927359619099846
  },
  {
    t: 1582222200,
    v: 30.911758440989786
  },
  {
    t: 1582222500,
    v: 46.43606127754917
  },
  {
    t: 1582222800,
    v: 51.19049706314549
  },
  {
    t: 1582223100,
    v: 42.88524314360651
  },
  {
    t: 1582223400,
    v: 40.72761570212603
  },
  {
    t: 1582223700,
    v: 43.43784019449968
  },
  {
    t: 1582224000,
    v: 41.694764191112945
  },
  {
    t: 1582224300,
    v: 43.633516175422194
  },
  {
    t: 1582224600,
    v: 42.555389099750855
  },
  {
    t: 1582224900,
    v: 42.555389099750855
  },
  {
    t: 1582225200,
    v: 37.15892502034373
  },
  {
    t: 1582225500,
    v: 30.322909143866255
  },
  {
    t: 1582225800,
    v: 28.604605966045696
  },
  {
    t: 1582226100,
    v: 33.95141196279485
  },
  {
    t: 1582226400,
    v: 33.95141196279485
  },
  {
    t: 1582226700,
    v: 58.378691612250414
  },
  {
    t: 1582227000,
    v: 48.19363975021684
  },
  {
    t: 1582227300,
    v: 42.53909297536935
  },
  {
    t: 1582227600,
    v: 33.54335960367247
  },
  {
    t: 1582227900,
    v: 24.838917167754957
  },
  {
    t: 1582228200,
    v: 33.65866032570135
  },
  {
    t: 1582228500,
    v: 40.41960759676488
  },
  {
    t: 1582228800,
    v: 38.777279576452194
  },
  {
    t: 1582229100,
    v: 34.74382313819295
  },
  {
    t: 1582229400,
    v: 34.33565665686304
  },
  {
    t: 1582229700,
    v: 30.63398659622993
  },
  {
    t: 1582230000,
    v: 26.624602975546395
  },
  {
    t: 1582230300,
    v: 33.00186296104755
  },
  {
    t: 1582230600,
    v: 44.10570091986185
  },
  {
    t: 1582230900,
    v: 39.79817907391017
  },
  {
    t: 1582231200,
    v: 41.4324416477097
  },
  {
    t: 1582231500,
    v: 43.19479223298082
  },
  {
    t: 1582231800,
    v: 39.775162341160254
  },
  {
    t: 1582232100,
    v: 59.04415154111865
  },
  {
    t: 1582232400,
    v: 54.44955404235841
  },
  {
    t: 1582232700,
    v: 46.227297338938484
  },
  {
    t: 1582233000,
    v: 51.1455370174147
  },
  {
    t: 1582233300,
    v: 40.79053754535191
  },
  {
    t: 1582233600,
    v: 35.06359244950116
  },
  {
    t: 1582233900,
    v: 33.711298730980644
  },
  {
    t: 1582234200,
    v: 39.08144547583848
  },
  {
    t: 1582234500,
    v: 39.08144547583848
  },
  {
    t: 1582234800,
    v: 39.08144547583848
  },
  {
    t: 1582235100,
    v: 43.62618164543949
  },
  {
    t: 1582235400,
    v: 26.26858924229758
  },
  {
    t: 1582235700,
    v: 34.866963956305696
  },
  {
    t: 1582236000,
    v: 32.463288767017644
  },
  {
    t: 1582236300,
    v: 40.8515080694902
  },
  {
    t: 1582236600,
    v: 41.769897464567464
  },
  {
    t: 1582236900,
    v: 37.75059924051421
  },
  {
    t: 1582237200,
    v: 42.97639236011823
  },
  {
    t: 1582237500,
    v: 38.53934045882561
  },
  {
    t: 1582237800,
    v: 51.052778457432794
  },
  {
    t: 1582238100,
    v: 52.74443043987992
  },
  {
    t: 1582238400,
    v: 64.09107231892483
  },
  {
    t: 1582238700,
    v: 53.151860594013094
  },
  {
    t: 1582239000,
    v: 49.90682739152892
  },
  {
    t: 1582239300,
    v: 59.16644709355949
  },
  {
    t: 1582239600,
    v: 53.12351080363839
  },
  {
    t: 1582239900,
    v: 58.64083137981793
  },
  {
    t: 1582240200,
    v: 62.43097982424152
  },
  {
    t: 1582240500,
    v: 65.99261628275039
  },
  {
    t: 1582240800,
    v: 60.52768754960053
  },
  {
    t: 1582241100,
    v: 62.64867623632071
  },
  {
    t: 1582241400,
    v: 64.11769324649109
  },
  {
    t: 1582241700,
    v: 68.38130775689474
  },
  {
    t: 1582242000,
    v: 60.2055825349395
  },
  {
    t: 1582242300,
    v: 61.939139738497985
  },
  {
    t: 1582242600,
    v: 61.939139738497985
  },
  {
    t: 1582242900,
    v: 51.652780874469315
  },
  {
    t: 1582243200,
    v: 66.2808501500331
  },
  {
    t: 1582243500,
    v: 71.25129975911707
  },
  {
    t: 1582243800,
    v: 77.05057875461841
  },
  {
    t: 1582244100,
    v: 58.934759237514484
  },
  {
    t: 1582244400,
    v: 51.95456164968169
  },
  {
    t: 1582244700,
    v: 46.4527283273195
  },
  {
    t: 1582245000,
    v: 46.4527283273195
  },
  {
    t: 1582245300,
    v: 41.52922054528626
  },
  {
    t: 1582245600,
    v: 57.11377586029802
  },
  {
    t: 1582245900,
    v: 60.45947872439446
  },
  {
    t: 1582246200,
    v: 46.39529921205488
  },
  {
    t: 1582246500,
    v: 59.16667691176636
  },
  {
    t: 1582246800,
    v: 56.1110174069161
  },
  {
    t: 1582247100,
    v: 67.58895798499165
  },
  {
    t: 1582247400,
    v: 63.62707944367986
  },
  {
    t: 1582247700,
    v: 65.91075663910217
  },
  {
    t: 1582248000,
    v: 68.61331558263053
  },
  {
    t: 1582248300,
    v: 63.79644484948914
  },
  {
    t: 1582248600,
    v: 66.97595386556537
  },
  {
    t: 1582248900,
    v: 67.5192979526025
  },
  {
    t: 1582249200,
    v: 59.665848210860275
  },
  {
    t: 1582249500,
    v: 65.55317526624191
  },
  {
    t: 1582249800,
    v: 66.18003138877009
  },
  {
    t: 1582250100,
    v: 59.94636596867444
  },
  {
    t: 1582250400,
    v: 38.43275446486824
  },
  {
    t: 1582250700,
    v: 38.43275446486824
  },
  {
    t: 1582251000,
    v: 34.12605000646987
  },
  {
    t: 1582251300,
    v: 48.310975541904604
  },
  {
    t: 1582251600,
    v: 43.33425332893316
  },
  {
    t: 1582251900,
    v: 33.69909838647882
  },
  {
    t: 1582252200,
    v: 43.06116399844364
  },
  {
    t: 1582252500,
    v: 44.68420925106954
  },
  {
    t: 1582252800,
    v: 35.9102813358587
  },
  {
    t: 1582253100,
    v: 32.95241107976207
  },
  {
    t: 1582253400,
    v: 37.8294448099478
  },
  {
    t: 1582253700,
    v: 50.23942723906767
  },
  {
    t: 1582254000,
    v: 53.75594655733067
  },
  {
    t: 1582254300,
    v: 65.04758106801287
  },
  {
    t: 1582254600,
    v: 60.02265878883257
  },
  {
    t: 1582254900,
    v: 43.88080282388419
  },
  {
    t: 1582255200,
    v: 44.56234855269303
  },
  {
    t: 1582255500,
    v: 47.47826167832842
  },
  {
    t: 1582255800,
    v: 50.456428460944416
  },
  {
    t: 1582256100,
    v: 52.01135668713587
  },
  {
    t: 1582256400,
    v: 53.67304194656263
  },
  {
    t: 1582256700,
    v: 51.62987743667855
  },
  {
    t: 1582257000,
    v: 45.669344329078484
  },
  {
    t: 1582257300,
    v: 52.00217035674241
  },
  {
    t: 1582257600,
    v: 44.15883319564476
  },
  {
    t: 1582257900,
    v: 57.798423101519354
  },
  {
    t: 1582258200,
    v: 60.02995650444942
  },
  {
    t: 1582258500,
    v: 66.73154860715437
  },
  {
    t: 1582258800,
    v: 57.86169493946317
  },
  {
    t: 1582259100,
    v: 65.1424546456027
  },
  {
    t: 1582259400,
    v: 57.87045522732931
  },
  {
    t: 1582259700,
    v: 62.02335516021609
  },
  {
    t: 1582260000,
    v: 66.98282541567667
  },
  {
    t: 1582260300,
    v: 69.27561326356806
  },
  {
    t: 1582260600,
    v: 64.18196533142938
  },
  {
    t: 1582260900,
    v: 72.3222460252105
  },
  {
    t: 1582261200,
    v: 60.06446549598867
  },
  {
    t: 1582261500,
    v: 63.88065803372728
  },
  {
    t: 1582261800,
    v: 65.14933918061828
  },
  {
    t: 1582262100,
    v: 57.12354854959352
  },
  {
    t: 1582262400,
    v: 51.90389506479918
  },
  {
    t: 1582262700,
    v: 56.451584504740715
  },
  {
    t: 1582263000,
    v: 56.451584504740715
  },
  {
    t: 1582263300,
    v: 55.090847376347384
  },
  {
    t: 1582263600,
    v: 44.1457131500506
  },
  {
    t: 1582263900,
    v: 44.1457131500506
  },
  {
    t: 1582264200,
    v: 56.64582614407518
  },
  {
    t: 1582264500,
    v: 49.10940292295224
  },
  {
    t: 1582264800,
    v: 53.79325751618401
  },
  {
    t: 1582265100,
    v: 60.97652158190579
  },
  {
    t: 1582265400,
    v: 60.97652158190579
  },
  {
    t: 1582265700,
    v: 47.26606833871284
  },
  {
    t: 1582266000,
    v: 40.952022654094094
  },
  {
    t: 1582266300,
    v: 32.64545412601393
  },
  {
    t: 1582266600,
    v: 31.90583462676807
  },
  {
    t: 1582266900,
    v: 42.35409703317798
  },
  {
    t: 1582267200,
    v: 39.39353423466711
  },
  {
    t: 1582267500,
    v: 29.85389768639213
  },
  {
    t: 1582267800,
    v: 29.85389768639213
  },
  {
    t: 1582268100,
    v: 27.0065610987689
  },
  {
    t: 1582268400,
    v: 35.759406775061734
  },
  {
    t: 1582268700,
    v: 46.1005741667969
  },
  {
    t: 1582269000,
    v: 59.02370782685693
  },
  {
    t: 1582269300,
    v: 72.30447772695454
  },
  {
    t: 1582269600,
    v: 78.09900859281771
  },
  {
    t: 1582269900,
    v: 72.3337302965424
  },
  {
    t: 1582270200,
    v: 75.25626690629853
  },
  {
    t: 1582270500,
    v: 73.9803579411
  },
  {
    t: 1582270800,
    v: 62.99492801518455
  },
  {
    t: 1582271100,
    v: 74.52396707659291
  },
  {
    t: 1582271400,
    v: 64.06836826104013
  },
  {
    t: 1582271700,
    v: 58.91567471835732
  },
  {
    t: 1582272000,
    v: 36.187203453031486
  },
  {
    t: 1582272300,
    v: 30.594304570866157
  },
  {
    t: 1582272600,
    v: 54.0091925877961
  },
  {
    t: 1582272900,
    v: 67.1797661676338
  },
  {
    t: 1582273200,
    v: 68.396624843107
  },
  {
    t: 1582273500,
    v: 65.1746940360625
  },
  {
    t: 1582273800,
    v: 82.91426034619163
  },
  {
    t: 1582274100,
    v: 77.25108820122347
  },
  {
    t: 1582274400,
    v: 71.96408755099547
  },
  {
    t: 1582274700,
    v: 75.75654874723936
  },
  {
    t: 1582275000,
    v: 75.98855342432509
  },
  {
    t: 1582275300,
    v: 76.24832313935488
  },
  {
    t: 1582275600,
    v: 81.05116701024926
  },
  {
    t: 1582275900,
    v: 75.91630706100867
  },
  {
    t: 1582276200,
    v: 71.19132878868058
  },
  {
    t: 1582276500,
    v: 67.6896503534345
  },
  {
    t: 1582276800,
    v: 62.02394648873281
  },
  {
    t: 1582277100,
    v: 66.68207759900342
  },
  {
    t: 1582277400,
    v: 70.00197144194172
  },
  {
    t: 1582277700,
    v: 57.01623064600336
  },
  {
    t: 1582278000,
    v: 60.632862784694815
  },
  {
    t: 1582278300,
    v: 56.07063860098651
  },
  {
    t: 1582278600,
    v: 54.7857754384318
  },
  {
    t: 1582278900,
    v: 40.087897472517305
  },
  {
    t: 1582279200,
    v: 30.341923176730276
  },
  {
    t: 1582279500,
    v: 29.057501682281142
  },
  {
    t: 1582279800,
    v: 24.067405683731508
  },
  {
    t: 1582280100,
    v: 19.688110030492396
  },
  {
    t: 1582280400,
    v: 29.96815113554284
  },
  {
    t: 1582280700,
    v: 32.83393640862431
  },
  {
    t: 1582281000,
    v: 38.20134136023138
  },
  {
    t: 1582281300,
    v: 40.700894938321326
  },
  {
    t: 1582281600,
    v: 39.19122187419144
  },
  {
    t: 1582281900,
    v: 39.70243344587702
  },
  {
    t: 1582282200,
    v: 29.926449871531446
  },
  {
    t: 1582282500,
    v: 30.50166561367932
  },
  {
    t: 1582282800,
    v: 27.186091665367144
  },
  {
    t: 1582283100,
    v: 31.134625996709744
  },
  {
    t: 1582283400,
    v: 33.204439082055174
  },
  {
    t: 1582283700,
    v: 30.740605514371268
  },
  {
    t: 1582284000,
    v: 50.52129387310955
  },
  {
    t: 1582284300,
    v: 59.363818696732146
  },
  {
    t: 1582284600,
    v: 48.918371331898655
  },
  {
    t: 1582284900,
    v: 50.22397333300917
  },
  {
    t: 1582285200,
    v: 56.195235620301446
  },
  {
    t: 1582285500,
    v: 47.379579588822885
  },
  {
    t: 1582285800,
    v: 56.08225546623529
  },
  {
    t: 1582286100,
    v: 64.41070068192175
  },
  {
    t: 1582286400,
    v: 47.41535604345338
  },
  {
    t: 1582286700,
    v: 47.78155690583331
  },
  {
    t: 1582287000,
    v: 53.01664976322738
  },
  {
    t: 1582287300,
    v: 53.77328645177403
  },
  {
    t: 1582287600,
    v: 55.41447778404835
  },
  {
    t: 1582287900,
    v: 47.67657631792457
  },
  {
    t: 1582288200,
    v: 42.20425594370042
  },
  {
    t: 1582288500,
    v: 36.33759241604973
  },
  {
    t: 1582288800,
    v: 35.980342455500505
  },
  {
    t: 1582289100,
    v: 37.38736153930959
  },
  {
    t: 1582289400,
    v: 53.67677400878383
  },
  {
    t: 1582289700,
    v: 33.001156122065055
  },
  {
    t: 1582290000,
    v: 48.102701291253894
  },
  {
    t: 1582290300,
    v: 54.1584852424888
  },
  {
    t: 1582290600,
    v: 49.234174089670084
  },
  {
    t: 1582290900,
    v: 48.895448428434015
  },
  {
    t: 1582291200,
    v: 53.96310787865445
  },
  {
    t: 1582291500,
    v: 48.82506275236021
  },
  {
    t: 1582291800,
    v: 52.03685326085022
  },
  {
    t: 1582292100,
    v: 63.98451344530925
  },
  {
    t: 1582292400,
    v: 69.79468318828523
  },
  {
    t: 1582292700,
    v: 71.86927383512896
  },
  {
    t: 1582293000,
    v: 66.63849734892943
  },
  {
    t: 1582293300,
    v: 69.89395406367265
  },
  {
    t: 1582293600,
    v: 41.8745199375666
  },
  {
    t: 1582293900,
    v: 50.08398781067967
  },
  {
    t: 1582294200,
    v: 56.59039908245359
  },
  {
    t: 1582294500,
    v: 55.32250762077871
  },
  {
    t: 1582294800,
    v: 57.756258715221996
  },
  {
    t: 1582295100,
    v: 59.707277542372566
  },
  {
    t: 1582295400,
    v: 61.17119640450959
  },
  {
    t: 1582295700,
    v: 74.36835640382726
  },
  {
    t: 1582296000,
    v: 74.52794093185344
  },
  {
    t: 1582296300,
    v: 91.62696182345744
  },
  {
    t: 1582296600,
    v: 78.42047615523356
  },
  {
    t: 1582296900,
    v: 78.2159679207094
  },
  {
    t: 1582297200,
    v: 81.33173666896734
  },
  {
    t: 1582297500,
    v: 83.28353645448189
  },
  {
    t: 1582297800,
    v: 86.24247592528472
  },
  {
    t: 1582298100,
    v: 86.97442046747031
  },
  {
    t: 1582298400,
    v: 85.41633234634372
  },
  {
    t: 1582298700,
    v: 86.52024429165834
  },
  {
    t: 1582299000,
    v: 74.4449879941148
  },
  {
    t: 1582299300,
    v: 78.98809802026798
  },
  {
    t: 1582299600,
    v: 79.12714168258289
  },
  {
    t: 1582299900,
    v: 79.28381232201438
  },
  {
    t: 1582300200,
    v: 63.928741493836924
  },
  {
    t: 1582300500,
    v: 60.12635648305034
  },
  {
    t: 1582300800,
    v: 66.76303927433455
  },
  {
    t: 1582301100,
    v: 67.29253937411424
  },
  {
    t: 1582301400,
    v: 70.14615974966345
  },
  {
    t: 1582301700,
    v: 72.85300302771293
  },
  {
    t: 1582302000,
    v: 60.34647510090198
  },
  {
    t: 1582302300,
    v: 54.955555527982376
  },
  {
    t: 1582302600,
    v: 51.68875214056863
  },
  {
    t: 1582302900,
    v: 47.02004501332632
  },
  {
    t: 1582303200,
    v: 39.507508206849224
  },
  {
    t: 1582303500,
    v: 44.43553992970498
  },
  {
    t: 1582303800,
    v: 54.420467217429135
  },
  {
    t: 1582304100,
    v: 54.14248440545345
  },
  {
    t: 1582304400,
    v: 57.834754924649324
  },
  {
    t: 1582304700,
    v: 59.57086082298066
  },
  {
    t: 1582305000,
    v: 64.98465495418907
  },
  {
    t: 1582305300,
    v: 66.11207646402934
  },
  {
    t: 1582305600,
    v: 66.8441113459111
  },
  {
    t: 1582305900,
    v: 61.7616249649201
  },
  {
    t: 1582306200,
    v: 61.7616249649201
  },
  {
    t: 1582306500,
    v: 62.13763951423906
  },
  {
    t: 1582306800,
    v: 61.44708423782729
  },
  {
    t: 1582307100,
    v: 54.522249554000815
  },
  {
    t: 1582307400,
    v: 36.60630893344711
  },
  {
    t: 1582307700,
    v: 52.55001268520335
  },
  {
    t: 1582308000,
    v: 45.27738804014736
  },
  {
    t: 1582308300,
    v: 54.70401661614126
  },
  {
    t: 1582308600,
    v: 59.4892493509143
  },
  {
    t: 1582308900,
    v: 62.50707009907697
  },
  {
    t: 1582309200,
    v: 59.95489905558385
  },
  {
    t: 1582309500,
    v: 44.56598930487046
  },
  {
    t: 1582309800,
    v: 49.01259852145031
  },
  {
    t: 1582310100,
    v: 47.05195082633945
  },
  {
    t: 1582310400,
    v: 47.05195082633944
  },
  {
    t: 1582310700,
    v: 52.951668109355325
  },
  {
    t: 1582311000,
    v: 45.2659634901682
  },
  {
    t: 1582311300,
    v: 44.34643598237045
  },
  {
    t: 1582311600,
    v: 36.333136112000076
  },
  {
    t: 1582311900,
    v: 32.7712204350524
  },
  {
    t: 1582312200,
    v: 41.88664097703464
  },
  {
    t: 1582312500,
    v: 37.71246881232394
  },
  {
    t: 1582312800,
    v: 54.34968127540901
  },
  {
    t: 1582313100,
    v: 50.05244511768046
  },
  {
    t: 1582313400,
    v: 45.485606668718404
  },
  {
    t: 1582313700,
    v: 41.1904515707077
  },
  {
    t: 1582314000,
    v: 46.3967650695732
  },
  {
    t: 1582314300,
    v: 52.29580293753841
  },
  {
    t: 1582314600,
    v: 54.87551766482354
  },
  {
    t: 1582314900,
    v: 60.704130217759555
  },
  {
    t: 1582315200,
    v: 49.699235982528975
  },
  {
    t: 1582315500,
    v: 42.80194409719577
  },
  {
    t: 1582315800,
    v: 41.85362204897717
  },
  {
    t: 1582316100,
    v: 48.9615708115332
  },
  {
    t: 1582316400,
    v: 43.443968740150176
  },
  {
    t: 1582316700,
    v: 36.41002057227527
  },
  {
    t: 1582317000,
    v: 31.378034327924766
  },
  {
    t: 1582317300,
    v: 28.598334826922454
  },
  {
    t: 1582317600,
    v: 29.490655089725593
  },
  {
    t: 1582317900,
    v: 22.93832158168631
  },
  {
    t: 1582318200,
    v: 21.56909460921601
  },
  {
    t: 1582318500,
    v: 29.2874093888164
  },
  {
    t: 1582318800,
    v: 28.486458883315336
  },
  {
    t: 1582319100,
    v: 24.636426464738037
  },
  {
    t: 1582319400,
    v: 31.98985090745191
  },
  {
    t: 1582319700,
    v: 29.62980296299979
  },
  {
    t: 1582320000,
    v: 36.095471513049
  },
  {
    t: 1582320300,
    v: 38.25662555736963
  },
  {
    t: 1582320600,
    v: 34.88581094637735
  },
  {
    t: 1582320900,
    v: 34.19709620269113
  },
  {
    t: 1582495500,
    v: 5.387698963182132
  },
  {
    t: 1582495800,
    v: 11.162098334709626
  },
  {
    t: 1582496100,
    v: 16.61479615731058
  },
  {
    t: 1582496400,
    v: 20.69433187563112
  },
  {
    t: 1582496700,
    v: 27.342106883314486
  },
  {
    t: 1582497000,
    v: 29.95178323371077
  },
  {
    t: 1582497300,
    v: 31.16277822090862
  },
  {
    t: 1582497600,
    v: 29.789443548532475
  },
  {
    t: 1582497900,
    v: 24.646738879978656
  },
  {
    t: 1582498200,
    v: 25.155929671540548
  },
  {
    t: 1582498500,
    v: 27.93848512197485
  },
  {
    t: 1582498800,
    v: 56.21584808408766
  },
  {
    t: 1582499100,
    v: 61.04327401346896
  },
  {
    t: 1582499400,
    v: 64.84065845977605
  },
  {
    t: 1582499700,
    v: 61.061549782505395
  },
  {
    t: 1582500000,
    v: 55.853091785153595
  },
  {
    t: 1582500300,
    v: 49.02564165096058
  },
  {
    t: 1582500600,
    v: 44.376065913337264
  },
  {
    t: 1582500900,
    v: 49.81547593071901
  },
  {
    t: 1582501200,
    v: 57.95938932857765
  },
  {
    t: 1582501500,
    v: 59.958734467694335
  },
  {
    t: 1582501800,
    v: 52.6064223583196
  },
  {
    t: 1582502100,
    v: 56.34508874812718
  },
  {
    t: 1582502400,
    v: 61.40607763009402
  },
  {
    t: 1582502700,
    v: 60.90176007972478
  },
  {
    t: 1582503000,
    v: 52.5212800552178
  },
  {
    t: 1582503300,
    v: 57.965281766728346
  },
  {
    t: 1582503600,
    v: 53.92714885417326
  },
  {
    t: 1582503900,
    v: 65.82952514955645
  },
  {
    t: 1582504200,
    v: 54.746037904565995
  },
  {
    t: 1582504500,
    v: 40.71092683098217
  },
  {
    t: 1582504800,
    v: 30.365701145878916
  },
  {
    t: 1582505100,
    v: 32.71072894979166
  },
  {
    t: 1582505400,
    v: 31.498440980940458
  },
  {
    t: 1582505700,
    v: 34.28194726592328
  },
  {
    t: 1582506000,
    v: 35.773262867513964
  },
  {
    t: 1582506300,
    v: 30.7264065516581
  },
  {
    t: 1582506600,
    v: 24.16451802430167
  },
  {
    t: 1582506900,
    v: 26.40964011245275
  },
  {
    t: 1582507200,
    v: 24.93332936627054
  },
  {
    t: 1582507500,
    v: 23.236748556037895
  },
  {
    t: 1582507800,
    v: 21.956176000075615
  },
  {
    t: 1582508100,
    v: 33.17900728845029
  },
  {
    t: 1582508400,
    v: 35.17651956647255
  },
  {
    t: 1582508700,
    v: 38.66872074102929
  },
  {
    t: 1582509000,
    v: 45.39288183375301
  },
  {
    t: 1582509300,
    v: 45.39288183375301
  },
  {
    t: 1582509600,
    v: 46.16381067211147
  },
  {
    t: 1582509900,
    v: 39.20559731669414
  },
  {
    t: 1582510200,
    v: 54.25080281031398
  },
  {
    t: 1582510500,
    v: 54.858770589240656
  },
  {
    t: 1582510800,
    v: 48.261926820478436
  },
  {
    t: 1582511100,
    v: 42.43067241120229
  },
  {
    t: 1582511400,
    v: 34.492467681960264
  },
  {
    t: 1582511700,
    v: 32.63190020753596
  },
  {
    t: 1582512000,
    v: 45.281541902974794
  },
  {
    t: 1582512300,
    v: 48.24331031652099
  },
  {
    t: 1582512600,
    v: 41.22955605592226
  },
  {
    t: 1582512900,
    v: 25.9454836819217
  },
  {
    t: 1582513200,
    v: 33.8500812954766
  },
  {
    t: 1582513500,
    v: 29.30087071199091
  },
  {
    t: 1582513800,
    v: 32.272963082803145
  },
  {
    t: 1582514100,
    v: 31.150513960473077
  },
  {
    t: 1582514400,
    v: 34.6157411420871
  },
  {
    t: 1582514700,
    v: 39.809063890991474
  },
  {
    t: 1582515000,
    v: 36.4961432849818
  },
  {
    t: 1582515300,
    v: 44.424699160983
  },
  {
    t: 1582515600,
    v: 47.73892254806905
  },
  {
    t: 1582515900,
    v: 37.51242286828839
  },
  {
    t: 1582516200,
    v: 36.39853802026351
  },
  {
    t: 1582516500,
    v: 35.79123723375061
  },
  {
    t: 1582516800,
    v: 32.67587152958541
  },
  {
    t: 1582517100,
    v: 41.9200897594578
  },
  {
    t: 1582517400,
    v: 39.59032672650441
  },
  {
    t: 1582517700,
    v: 35.80050015310047
  },
  {
    t: 1582518000,
    v: 38.49211540129565
  },
  {
    t: 1582518300,
    v: 32.963954962164024
  },
  {
    t: 1582518600,
    v: 27.21975567660536
  },
  {
    t: 1582518900,
    v: 36.98107761729065
  },
  {
    t: 1582519200,
    v: 31.468496263076602
  },
  {
    t: 1582519500,
    v: 27.38617786678087
  },
  {
    t: 1582519800,
    v: 46.6098012519151
  },
  {
    t: 1582520100,
    v: 46.6098012519151
  },
  {
    t: 1582520400,
    v: 44.78783233157217
  },
  {
    t: 1582520700,
    v: 42.87254082464306
  },
  {
    t: 1582521000,
    v: 41.8498814070158
  },
  {
    t: 1582521300,
    v: 47.567370870422856
  },
  {
    t: 1582521600,
    v: 30.45587944000215
  },
  {
    t: 1582521900,
    v: 27.617354309650807
  },
  {
    t: 1582522200,
    v: 49.243013931820364
  },
  {
    t: 1582522500,
    v: 52.48763162483893
  },
  {
    t: 1582522800,
    v: 44.381689596497736
  },
  {
    t: 1582523100,
    v: 31.175807477677353
  },
  {
    t: 1582523400,
    v: 31.175807477677353
  },
  {
    t: 1582523700,
    v: 21.93893233044261
  },
  {
    t: 1582524000,
    v: 43.86936396894643
  },
  {
    t: 1582524300,
    v: 43.86936396894643
  },
  {
    t: 1582524600,
    v: 49.98668124712444
  },
  {
    t: 1582524900,
    v: 45.45722663297575
  },
  {
    t: 1582525200,
    v: 47.49617291609747
  },
  {
    t: 1582525500,
    v: 51.629248474762356
  },
  {
    t: 1582525800,
    v: 55.6217450455688
  },
  {
    t: 1582526100,
    v: 59.44713091612518
  },
  {
    t: 1582526400,
    v: 47.69948877115907
  },
  {
    t: 1582526700,
    v: 38.43936683490245
  },
  {
    t: 1582527000,
    v: 45.93625559233156
  },
  {
    t: 1582527300,
    v: 36.32939126496953
  },
  {
    t: 1582527600,
    v: 28.737739059693766
  },
  {
    t: 1582527900,
    v: 19.009502839425437
  },
  {
    t: 1582528200,
    v: 23.60691092703331
  },
  {
    t: 1582528500,
    v: 31.252501599870314
  },
  {
    t: 1582528800,
    v: 46.14027639440383
  },
  {
    t: 1582529100,
    v: 49.98207849979467
  },
  {
    t: 1582529400,
    v: 47.00195495087122
  },
  {
    t: 1582529700,
    v: 55.638667116276636
  },
  {
    t: 1582530000,
    v: 44.460128627328984
  },
  {
    t: 1582530300,
    v: 48.408830739956215
  },
  {
    t: 1582530600,
    v: 53.45176602210289
  },
  {
    t: 1582530900,
    v: 57.7756531673134
  },
  {
    t: 1582531200,
    v: 64.0251113555864
  },
  {
    t: 1582531500,
    v: 78.6625331550526
  },
  {
    t: 1582531800,
    v: 63.658938205258465
  },
  {
    t: 1582532100,
    v: 67.14323348712512
  },
  {
    t: 1582532400,
    v: 65.54716800085966
  },
  {
    t: 1582532700,
    v: 73.19372826953422
  },
  {
    t: 1582533000,
    v: 58.1046367651441
  },
  {
    t: 1582533300,
    v: 52.62606662188676
  },
  {
    t: 1582533600,
    v: 57.0143144295573
  },
  {
    t: 1582533900,
    v: 47.281648886247886
  },
  {
    t: 1582534200,
    v: 53.95478050804688
  },
  {
    t: 1582534500,
    v: 61.81569520961498
  },
  {
    t: 1582534800,
    v: 78.55346542920454
  },
  {
    t: 1582535100,
    v: 61.814661342626096
  },
  {
    t: 1582535400,
    v: 58.77315342184522
  },
  {
    t: 1582535700,
    v: 53.14749614766693
  },
  {
    t: 1582536000,
    v: 42.76728330830935
  },
  {
    t: 1582536300,
    v: 42.94664851771807
  },
  {
    t: 1582536600,
    v: 48.31508253490456
  },
  {
    t: 1582536900,
    v: 49.255922534739895
  },
  {
    t: 1582537200,
    v: 43.4683432341672
  },
  {
    t: 1582537500,
    v: 33.274914802089754
  },
  {
    t: 1582537800,
    v: 34.71303118263525
  },
  {
    t: 1582538100,
    v: 36.2825101549062
  },
  {
    t: 1582538400,
    v: 40.88424759399898
  },
  {
    t: 1582538700,
    v: 52.43186382347237
  },
  {
    t: 1582539000,
    v: 52.64330639701738
  },
  {
    t: 1582539300,
    v: 46.08772056009538
  },
  {
    t: 1582539600,
    v: 44.286911580429425
  },
  {
    t: 1582539900,
    v: 36.03904681912645
  },
  {
    t: 1582540200,
    v: 35.486387783048
  },
  {
    t: 1582540500,
    v: 43.12793816619829
  },
  {
    t: 1582540800,
    v: 37.98580412887118
  },
  {
    t: 1582541100,
    v: 50.57652373091054
  },
  {
    t: 1582541400,
    v: 43.73285805499427
  },
  {
    t: 1582541700,
    v: 43.732858054994274
  },
  {
    t: 1582542000,
    v: 42.6921387532265
  },
  {
    t: 1582542300,
    v: 43.0791548638306
  },
  {
    t: 1582542600,
    v: 57.01353885149678
  },
  {
    t: 1582542900,
    v: 61.615921417502165
  },
  {
    t: 1582543200,
    v: 55.914463946810834
  },
  {
    t: 1582543500,
    v: 61.834062380288614
  },
  {
    t: 1582543800,
    v: 66.09148412254169
  },
  {
    t: 1582544100,
    v: 56.149441338366614
  },
  {
    t: 1582544400,
    v: 60.24972119695811
  },
  {
    t: 1582544700,
    v: 53.401678352806506
  },
  {
    t: 1582545000,
    v: 58.22520994897631
  },
  {
    t: 1582545300,
    v: 51.053163748460165
  },
  {
    t: 1582545600,
    v: 46.567783362917325
  },
  {
    t: 1582545900,
    v: 46.567783362917325
  },
  {
    t: 1582546200,
    v: 57.45793097826336
  },
  {
    t: 1582546500,
    v: 60.325897915418345
  },
  {
    t: 1582546800,
    v: 62.264150414243936
  },
  {
    t: 1582547100,
    v: 71.53505861657446
  },
  {
    t: 1582547400,
    v: 50.765376864904766
  },
  {
    t: 1582547700,
    v: 47.95717222760682
  },
  {
    t: 1582548000,
    v: 49.723006254040136
  },
  {
    t: 1582548300,
    v: 45.81453513572523
  },
  {
    t: 1582548600,
    v: 48.34562270804387
  },
  {
    t: 1582548900,
    v: 37.631308805267615
  },
  {
    t: 1582549200,
    v: 47.58753978792758
  },
  {
    t: 1582549500,
    v: 41.85985321668539
  },
  {
    t: 1582549800,
    v: 40.024649737231414
  },
  {
    t: 1582550100,
    v: 47.48049317803322
  },
  {
    t: 1582550400,
    v: 47.91577767207845
  },
  {
    t: 1582550700,
    v: 52.42236086270846
  },
  {
    t: 1582551000,
    v: 44.13092433824421
  },
  {
    t: 1582551300,
    v: 39.95009837929323
  },
  {
    t: 1582551600,
    v: 33.10693906316688
  },
  {
    t: 1582551900,
    v: 32.206254084705236
  },
  {
    t: 1582552200,
    v: 32.901656795288915
  },
  {
    t: 1582552500,
    v: 29.14351385977899
  },
  {
    t: 1582552800,
    v: 48.64564410853321
  },
  {
    t: 1582553100,
    v: 59.729471832558964
  },
  {
    t: 1582553400,
    v: 64.15081041517053
  },
  {
    t: 1582553700,
    v: 73.07838045982064
  },
  {
    t: 1582554000,
    v: 81.48231752491724
  },
  {
    t: 1582554300,
    v: 83.65647040402935
  },
  {
    t: 1582554600,
    v: 70.35423443324198
  },
  {
    t: 1582554900,
    v: 67.2026305711421
  },
  {
    t: 1582555200,
    v: 76.51907554479635
  },
  {
    t: 1582555500,
    v: 80.83430989470466
  },
  {
    t: 1582555800,
    v: 87.61149763981852
  },
  {
    t: 1582556100,
    v: 79.83382464906711
  },
  {
    t: 1582556400,
    v: 82.41140656032296
  },
  {
    t: 1582556700,
    v: 84.89996038869779
  },
  {
    t: 1582557000,
    v: 73.31449457984417
  },
  {
    t: 1582557300,
    v: 73.31449457984418
  },
  {
    t: 1582557600,
    v: 75.54456945375787
  },
  {
    t: 1582557900,
    v: 78.02494066690663
  },
  {
    t: 1582558200,
    v: 70.76843716652246
  },
  {
    t: 1582558500,
    v: 67.64251734303251
  },
  {
    t: 1582558800,
    v: 58.74582737993906
  },
  {
    t: 1582559100,
    v: 67.91050389099482
  },
  {
    t: 1582559400,
    v: 68.19384365964854
  },
  {
    t: 1582559700,
    v: 63.099888006156
  },
  {
    t: 1582560000,
    v: 67.74637055911695
  },
  {
    t: 1582560300,
    v: 51.124389610801686
  },
  {
    t: 1582560600,
    v: 45.11075602934343
  },
  {
    t: 1582560900,
    v: 54.58445931100489
  },
  {
    t: 1582561200,
    v: 61.21488814387945
  },
  {
    t: 1582561500,
    v: 65.363958093306
  },
  {
    t: 1582561800,
    v: 66.53299041581687
  },
  {
    t: 1582562100,
    v: 57.88131347366383
  },
  {
    t: 1582562400,
    v: 58.082265000979625
  },
  {
    t: 1582562700,
    v: 45.63944546496179
  },
  {
    t: 1582563000,
    v: 46.93855648274871
  },
  {
    t: 1582563300,
    v: 52.16449926545685
  },
  {
    t: 1582563600,
    v: 56.56461152129386
  },
  {
    t: 1582563900,
    v: 58.03512842969075
  },
  {
    t: 1582564200,
    v: 66.29456682979576
  },
  {
    t: 1582564500,
    v: 56.58170905054072
  },
  {
    t: 1582564800,
    v: 62.9920272997122
  },
  {
    t: 1582565100,
    v: 60.93566165096655
  },
  {
    t: 1582565400,
    v: 67.24878848980362
  },
  {
    t: 1582565700,
    v: 63.112575594328156
  },
  {
    t: 1582566000,
    v: 63.57806594282181
  },
  {
    t: 1582566300,
    v: 71.57264152317632
  },
  {
    t: 1582566600,
    v: 74.52242624254653
  },
  {
    t: 1582566900,
    v: 76.53237825670806
  },
  {
    t: 1582567200,
    v: 71.10756597803896
  },
  {
    t: 1582567500,
    v: 72.13366352176705
  },
  {
    t: 1582567800,
    v: 73.01003605199978
  },
  {
    t: 1582568100,
    v: 71.09360745973828
  },
  {
    t: 1582568400,
    v: 55.97974540111886
  },
  {
    t: 1582568700,
    v: 57.13686505780762
  },
  {
    t: 1582569000,
    v: 50.11159994158277
  },
  {
    t: 1582569300,
    v: 45.128973156851245
  },
  {
    t: 1582569600,
    v: 41.68405214348695
  },
  {
    t: 1582569900,
    v: 40.36355801809155
  },
  {
    t: 1582570200,
    v: 29.6345997524636
  },
  {
    t: 1582570500,
    v: 43.84920293531663
  },
  {
    t: 1582570800,
    v: 38.51414611601326
  },
  {
    t: 1582571100,
    v: 28.481346339588626
  },
  {
    t: 1582571400,
    v: 32.19961220514358
  },
  {
    t: 1582571700,
    v: 37.50435994795271
  },
  {
    t: 1582572000,
    v: 26.573914326833602
  },
  {
    t: 1582572300,
    v: 23.970417338789858
  },
  {
    t: 1582572600,
    v: 19.46598291583284
  },
  {
    t: 1582572900,
    v: 15.843814663022002
  },
  {
    t: 1582573200,
    v: 20.938698079266146
  },
  {
    t: 1582573500,
    v: 18.969919385908057
  },
  {
    t: 1582573800,
    v: 17.701785826294582
  },
  {
    t: 1582574100,
    v: 14.137464004516772
  },
  {
    t: 1582574400,
    v: 30.199792039219147
  },
  {
    t: 1582574700,
    v: 30.64909051741236
  },
  {
    t: 1582575000,
    v: 34.9555738805885
  },
  {
    t: 1582575300,
    v: 40.14713178942831
  },
  {
    t: 1582575600,
    v: 43.87075770942579
  },
  {
    t: 1582575900,
    v: 42.73142218313823
  },
  {
    t: 1582576200,
    v: 46.44062624642497
  },
  {
    t: 1582576500,
    v: 56.54898868919316
  },
  {
    t: 1582576800,
    v: 65.90791749227833
  },
  {
    t: 1582577100,
    v: 66.78396805774125
  },
  {
    t: 1582577400,
    v: 65.50155497055083
  },
  {
    t: 1582577700,
    v: 51.847622237495926
  },
  {
    t: 1582578000,
    v: 46.74371508309037
  },
  {
    t: 1582578300,
    v: 56.15970430284169
  },
  {
    t: 1582578600,
    v: 63.23483207154838
  },
  {
    t: 1582578900,
    v: 50.628666275584514
  },
  {
    t: 1582579200,
    v: 58.359006245848775
  },
  {
    t: 1582579500,
    v: 49.501109479451735
  },
  {
    t: 1582579800,
    v: 49.89616935577184
  },
  {
    t: 1582580100,
    v: 43.651717112561265
  },
  {
    t: 1582580400,
    v: 49.51819375269837
  },
  {
    t: 1582580700,
    v: 46.1397950460956
  },
  {
    t: 1582581000,
    v: 49.115467382483814
  },
  {
    t: 1582581300,
    v: 49.64537138474603
  },
  {
    t: 1582581600,
    v: 47.933908618958455
  },
  {
    t: 1582581900,
    v: 51.14212714849936
  },
  {
    t: 1582582200,
    v: 53.74781935321818
  },
  {
    t: 1582582500,
    v: 42.178994586886766
  },
  {
    t: 1582582800,
    v: 56.4597206555604
  },
  {
    t: 1582583100,
    v: 52.16511251211988
  },
  {
    t: 1582583400,
    v: 59.67661720445816
  },
  {
    t: 1582583700,
    v: 58.282024155507564
  },
  {
    t: 1582584000,
    v: 55.32673303838347
  },
  {
    t: 1582584300,
    v: 63.733409109720945
  },
  {
    t: 1582584600,
    v: 41.44828287090591
  },
  {
    t: 1582584900,
    v: 41.44828287090591
  },
  {
    t: 1582585200,
    v: 50.0097537996372
  },
  {
    t: 1582585500,
    v: 45.91736936361986
  },
  {
    t: 1582585800,
    v: 36.866758097822775
  },
  {
    t: 1582586100,
    v: 37.536793637121285
  },
  {
    t: 1582586400,
    v: 46.04447083228052
  },
  {
    t: 1582586700,
    v: 39.84255513214935
  },
  {
    t: 1582587000,
    v: 53.15532534835072
  },
  {
    t: 1582587300,
    v: 55.94109202240516
  },
  {
    t: 1582587600,
    v: 52.38108427899419
  },
  {
    t: 1582587900,
    v: 42.999137788760514
  },
  {
    t: 1582588200,
    v: 41.12828959207845
  },
  {
    t: 1582588500,
    v: 34.30507390380794
  },
  {
    t: 1582588800,
    v: 46.378101711655475
  },
  {
    t: 1582589100,
    v: 50.229288703924
  },
  {
    t: 1582589400,
    v: 53.500762247941346
  },
  {
    t: 1582589700,
    v: 57.73416601138784
  },
  {
    t: 1582590000,
    v: 56.992912659390086
  },
  {
    t: 1582590300,
    v: 51.005579882104506
  },
  {
    t: 1582590600,
    v: 50.251420997611035
  },
  {
    t: 1582590900,
    v: 49.41637954837463
  },
  {
    t: 1582591200,
    v: 54.59063977399916
  },
  {
    t: 1582591500,
    v: 55.458488447834206
  },
  {
    t: 1582591800,
    v: 42.471322539151146
  },
  {
    t: 1582592100,
    v: 52.46673277625868
  },
  {
    t: 1582592400,
    v: 41.882174220656694
  },
  {
    t: 1582592700,
    v: 35.960103683922966
  },
  {
    t: 1582593000,
    v: 31.394186342669386
  },
  {
    t: 1582593300,
    v: 32.48279307351609
  },
  {
    t: 1582593600,
    v: 70.48478428575184
  },
  {
    t: 1582593900,
    v: 72.24499566025787
  },
  {
    t: 1582594200,
    v: 74.9300826247993
  },
  {
    t: 1582594500,
    v: 75.89905282426682
  },
  {
    t: 1582594800,
    v: 71.92755112816948
  },
  {
    t: 1582595100,
    v: 72.5833707311989
  },
  {
    t: 1582595400,
    v: 72.5833707311989
  },
  {
    t: 1582595700,
    v: 75.56565281915425
  },
  {
    t: 1582596000,
    v: 82.97383304848034
  },
  {
    t: 1582596300,
    v: 83.58330226045459
  },
  {
    t: 1582596600,
    v: 71.82923751417998
  },
  {
    t: 1582596900,
    v: 70.87993945712803
  },
  {
    t: 1582597200,
    v: 70.87993945712803
  },
  {
    t: 1582597500,
    v: 59.56900992516564
  },
  {
    t: 1582597800,
    v: 68.04565691053611
  },
  {
    t: 1582598100,
    v: 70.27164371966825
  },
  {
    t: 1582598400,
    v: 66.06399514446284
  },
  {
    t: 1582598700,
    v: 58.99867853748077
  },
  {
    t: 1582599000,
    v: 57.98620108915326
  },
  {
    t: 1582599300,
    v: 74.79416315141015
  },
  {
    t: 1582599600,
    v: 72.83528265488005
  },
  {
    t: 1582599900,
    v: 71.76131165515295
  },
  {
    t: 1582600200,
    v: 73.95575544240131
  },
  {
    t: 1582600500,
    v: 73.95575544240131
  },
  {
    t: 1582600800,
    v: 81.2049639772859
  },
  {
    t: 1582601100,
    v: 82.92065700030669
  },
  {
    t: 1582601400,
    v: 85.2317097024185
  },
  {
    t: 1582601700,
    v: 85.9556631150656
  },
  {
    t: 1582602000,
    v: 63.444471822041635
  },
  {
    t: 1582602300,
    v: 60.58145887734927
  },
  {
    t: 1582602600,
    v: 53.25712812147155
  },
  {
    t: 1582602900,
    v: 41.726309549840785
  },
  {
    t: 1582603200,
    v: 34.299951258810424
  },
  {
    t: 1582603500,
    v: 44.05390819273342
  },
  {
    t: 1582603800,
    v: 42.84239111548879
  },
  {
    t: 1582604100,
    v: 37.53387690850296
  },
  {
    t: 1582604400,
    v: 35.311465521768824
  },
  {
    t: 1582604700,
    v: 34.724026139817326
  },
  {
    t: 1582605000,
    v: 34.724026139817326
  },
  {
    t: 1582605300,
    v: 39.943770831587265
  },
  {
    t: 1582605600,
    v: 53.69640572569424
  },
  {
    t: 1582605900,
    v: 44.69801779557786
  },
  {
    t: 1582606200,
    v: 44.69801779557786
  },
  {
    t: 1582606500,
    v: 37.34199005775952
  },
  {
    t: 1582606800,
    v: 28.889681826437766
  },
  {
    t: 1582607100,
    v: 33.78371156217352
  },
  {
    t: 1582607400,
    v: 32.50535828088769
  },
  {
    t: 1582607700,
    v: 26.259587215132782
  },
  {
    t: 1582608000,
    v: 23.450681945482998
  },
  {
    t: 1582608300,
    v: 15.53677692892262
  },
  {
    t: 1582608600,
    v: 12.77715548646033
  },
  {
    t: 1582608900,
    v: 24.775098733427015
  },
  {
    t: 1582609200,
    v: 18.646077347165473
  },
  {
    t: 1582609500,
    v: 17.772218113250062
  },
  {
    t: 1582609800,
    v: 27.482439662408666
  },
  {
    t: 1582610100,
    v: 20.972458974631337
  },
  {
    t: 1582610400,
    v: 24.525879294013478
  },
  {
    t: 1582610700,
    v: 20.344120567924207
  },
  {
    t: 1582611000,
    v: 33.33464110346566
  },
  {
    t: 1582611300,
    v: 40.290117739840554
  },
  {
    t: 1582611600,
    v: 38.898646091572004
  },
  {
    t: 1582611900,
    v: 55.77313621840612
  },
  {
    t: 1582612200,
    v: 62.332485037047554
  },
  {
    t: 1582612500,
    v: 52.82000224610877
  },
  {
    t: 1582612800,
    v: 45.775549030060375
  },
  {
    t: 1582613100,
    v: 43.562299587206795
  },
  {
    t: 1582613400,
    v: 49.17877513271404
  },
  {
    t: 1582613700,
    v: 39.336329520952674
  },
  {
    t: 1582614000,
    v: 33.726099347829035
  },
  {
    t: 1582614300,
    v: 30.607975143103047
  },
  {
    t: 1582614600,
    v: 41.17453325053618
  },
  {
    t: 1582614900,
    v: 44.03886633342652
  },
  {
    t: 1582615200,
    v: 52.942337420357234
  },
  {
    t: 1582615500,
    v: 56.651531987517885
  },
  {
    t: 1582615800,
    v: 35.730179237409345
  },
  {
    t: 1582616100,
    v: 38.23667724255855
  },
  {
    t: 1582616400,
    v: 61.968251538497434
  },
  {
    t: 1582616700,
    v: 63.56806759575153
  },
  {
    t: 1582617000,
    v: 53.31556845012296
  },
  {
    t: 1582617300,
    v: 50.96695586939989
  },
  {
    t: 1582617600,
    v: 44.83968102634643
  },
  {
    t: 1582617900,
    v: 54.940661240099786
  },
  {
    t: 1582618200,
    v: 55.242816220161515
  },
  {
    t: 1582618500,
    v: 56.89454643335769
  },
  {
    t: 1582618800,
    v: 68.75480301193925
  },
  {
    t: 1582619100,
    v: 75.44629471982101
  },
  {
    t: 1582619400,
    v: 68.17304300232513
  },
  {
    t: 1582619700,
    v: 73.49757086252632
  },
  {
    t: 1582620000,
    v: 66.15342827313586
  },
  {
    t: 1582620300,
    v: 69.0945554891824
  },
  {
    t: 1582620600,
    v: 66.03423437534948
  },
  {
    t: 1582620900,
    v: 63.729977726094255
  },
  {
    t: 1582621200,
    v: 69.35223035227789
  },
  {
    t: 1582621500,
    v: 65.80426998239199
  },
  {
    t: 1582621800,
    v: 45.87819048718731
  },
  {
    t: 1582622100,
    v: 51.66799265263218
  },
  {
    t: 1582622400,
    v: 48.07602747539168
  },
  {
    t: 1582622700,
    v: 39.51391211952286
  },
  {
    t: 1582623000,
    v: 39.921519586848625
  },
  {
    t: 1582623300,
    v: 39.315931740642384
  },
  {
    t: 1582623600,
    v: 30.57101254424674
  },
  {
    t: 1582623900,
    v: 28.38452565943608
  },
  {
    t: 1582624200,
    v: 21.931081575321457
  },
  {
    t: 1582624500,
    v: 27.186544746092196
  },
  {
    t: 1582624800,
    v: 19.633871074529623
  },
  {
    t: 1582625100,
    v: 16.85168963884
  },
  {
    t: 1582625400,
    v: 20.798285728702425
  },
  {
    t: 1582625700,
    v: 19.354675989598704
  },
  {
    t: 1582626000,
    v: 16.704484349904476
  },
  {
    t: 1582626300,
    v: 14.355840797900669
  },
  {
    t: 1582626600,
    v: 13.778284217405002
  },
  {
    t: 1582626900,
    v: 21.03932227183377
  },
  {
    t: 1582627200,
    v: 20.385333101789016
  },
  {
    t: 1582627500,
    v: 24.41313717895021
  },
  {
    t: 1582627800,
    v: 21.695291500825036
  },
  {
    t: 1582628100,
    v: 24.645279513553902
  },
  {
    t: 1582628400,
    v: 17.901292076506422
  },
  {
    t: 1582628700,
    v: 30.87241529591428
  },
  {
    t: 1582629000,
    v: 26.537097521158188
  },
  {
    t: 1582629300,
    v: 24.094827175235395
  },
  {
    t: 1582629600,
    v: 23.27867840841604
  },
  {
    t: 1582629900,
    v: 20.677240842080195
  },
  {
    t: 1582630200,
    v: 19.89540347407153
  },
  {
    t: 1582630500,
    v: 28.403637666028942
  },
  {
    t: 1582630800,
    v: 31.430363599805034
  },
  {
    t: 1582631100,
    v: 49.6683386388
  },
  {
    t: 1582631400,
    v: 50.66804799948582
  },
  {
    t: 1582631700,
    v: 54.2989820259892
  },
  {
    t: 1582632000,
    v: 40.62876010687569
  },
  {
    t: 1582632300,
    v: 34.58680896160787
  },
  {
    t: 1582632600,
    v: 51.542506373992495
  },
  {
    t: 1582632900,
    v: 56.34584458480207
  },
  {
    t: 1582633200,
    v: 46.59984240592083
  },
  {
    t: 1582633500,
    v: 48.71202226030225
  },
  {
    t: 1582633800,
    v: 54.47430919341467
  },
  {
    t: 1582634100,
    v: 47.12650799071537
  },
  {
    t: 1582634400,
    v: 58.135601197883396
  },
  {
    t: 1582634700,
    v: 46.346382181033626
  },
  {
    t: 1582635000,
    v: 40.84932214134058
  },
  {
    t: 1582635300,
    v: 52.335576016269435
  },
  {
    t: 1582635600,
    v: 57.88503559790062
  },
  {
    t: 1582635900,
    v: 63.28463818496632
  },
  {
    t: 1582636200,
    v: 62.41365924845995
  },
  {
    t: 1582636500,
    v: 66.84620719946369
  },
  {
    t: 1582636800,
    v: 70.36984749053481
  },
  {
    t: 1582637100,
    v: 77.4814075759443
  },
  {
    t: 1582637400,
    v: 73.84575869633397
  },
  {
    t: 1582637700,
    v: 68.10645386222276
  },
  {
    t: 1582638000,
    v: 63.973834852408366
  },
  {
    t: 1582638300,
    v: 50.423900482228724
  },
  {
    t: 1582638600,
    v: 56.82880591700848
  },
  {
    t: 1582638900,
    v: 48.53169569102711
  },
  {
    t: 1582639200,
    v: 39.293106529089286
  },
  {
    t: 1582639500,
    v: 56.42020095857841
  },
  {
    t: 1582639800,
    v: 51.12198323208176
  },
  {
    t: 1582640100,
    v: 45.19386593425342
  },
  {
    t: 1582640400,
    v: 48.30775918239062
  },
  {
    t: 1582640700,
    v: 53.98479297398421
  },
  {
    t: 1582641000,
    v: 57.88016682429638
  },
  {
    t: 1582641300,
    v: 49.64620297769005
  },
  {
    t: 1582641600,
    v: 56.434088749623335
  },
  {
    t: 1582641900,
    v: 42.9214857784978
  },
  {
    t: 1582642200,
    v: 34.3273798874005
  },
  {
    t: 1582642500,
    v: 37.959161998907234
  },
  {
    t: 1582642800,
    v: 46.820109092047986
  },
  {
    t: 1582643100,
    v: 41.45365846339555
  },
  {
    t: 1582643400,
    v: 44.90526745881978
  },
  {
    t: 1582643700,
    v: 42.07065891835554
  },
  {
    t: 1582644000,
    v: 67.22207312460722
  },
  {
    t: 1582644300,
    v: 67.63386895880747
  },
  {
    t: 1582644600,
    v: 69.529665527737
  },
  {
    t: 1582644900,
    v: 69.84026949012981
  },
  {
    t: 1582645200,
    v: 70.18758080839407
  },
  {
    t: 1582645500,
    v: 79.40375087339248
  },
  {
    t: 1582645800,
    v: 84.48286862801251
  },
  {
    t: 1582646100,
    v: 85.51934572612468
  },
  {
    t: 1582646400,
    v: 86.0517363195231
  },
  {
    t: 1582646700,
    v: 87.13302254668223
  },
  {
    t: 1582647000,
    v: 88.47336098883824
  },
  {
    t: 1582647300,
    v: 92.25120024575966
  },
  {
    t: 1582647600,
    v: 93.44948262261863
  },
  {
    t: 1582647900,
    v: 82.66606664970759
  },
  {
    t: 1582648200,
    v: 81.28291759060909
  },
  {
    t: 1582648500,
    v: 81.88897631306
  },
  {
    t: 1582648800,
    v: 84.29647283558258
  },
  {
    t: 1582649100,
    v: 57.69785963476204
  },
  {
    t: 1582649400,
    v: 46.15937830594786
  },
  {
    t: 1582649700,
    v: 48.10378343509353
  },
  {
    t: 1582650000,
    v: 56.535259118216096
  },
  {
    t: 1582650300,
    v: 53.37246787875239
  },
  {
    t: 1582650600,
    v: 54.345452278737696
  },
  {
    t: 1582650900,
    v: 60.71604084454389
  },
  {
    t: 1582651200,
    v: 60.15177085130019
  },
  {
    t: 1582651500,
    v: 61.18249646482844
  },
  {
    t: 1582651800,
    v: 58.751023410442954
  },
  {
    t: 1582652100,
    v: 49.446940042177914
  },
  {
    t: 1582652400,
    v: 62.012213926510704
  },
  {
    t: 1582652700,
    v: 63.00366057247883
  },
  {
    t: 1582653000,
    v: 68.29883803126799
  },
  {
    t: 1582653300,
    v: 71.31517834680321
  },
  {
    t: 1582653600,
    v: 67.63765329180038
  },
  {
    t: 1582653900,
    v: 56.84322150987576
  },
  {
    t: 1582654200,
    v: 48.5917483151141
  },
  {
    t: 1582654500,
    v: 57.690588240509015
  },
  {
    t: 1582654800,
    v: 52.807519443226404
  },
  {
    t: 1582655100,
    v: 47.11096987204289
  },
  {
    t: 1582655400,
    v: 39.14759605427328
  },
  {
    t: 1582655700,
    v: 41.86994496541752
  },
  {
    t: 1582656000,
    v: 47.26264578663126
  },
  {
    t: 1582656300,
    v: 50.5400586519463
  },
  {
    t: 1582656600,
    v: 53.51166491861395
  },
  {
    t: 1582656900,
    v: 53.1064972674165
  },
  {
    t: 1582657200,
    v: 42.748857720781174
  },
  {
    t: 1582657500,
    v: 63.42257364595699
  },
  {
    t: 1582657800,
    v: 56.52313831607985
  },
  {
    t: 1582658100,
    v: 58.72250759590989
  },
  {
    t: 1582658400,
    v: 61.2140260380616
  },
  {
    t: 1582658700,
    v: 61.2140260380616
  },
  {
    t: 1582659000,
    v: 68.22764452342975
  },
  {
    t: 1582659300,
    v: 76.01640448760712
  },
  {
    t: 1582659600,
    v: 60.89465724016713
  },
  {
    t: 1582659900,
    v: 62.002175084644165
  },
  {
    t: 1582660200,
    v: 50.02119458339828
  },
  {
    t: 1582660500,
    v: 51.46950088829124
  },
  {
    t: 1582660800,
    v: 38.85786316092913
  },
  {
    t: 1582661100,
    v: 38.4187358349658
  },
  {
    t: 1582661400,
    v: 51.80221520237067
  },
  {
    t: 1582661700,
    v: 50.348105855495454
  },
  {
    t: 1582662000,
    v: 60.48848791998814
  },
  {
    t: 1582662300,
    v: 59.447830658069144
  },
  {
    t: 1582662600,
    v: 59.447830658069144
  },
  {
    t: 1582662900,
    v: 61.19192887513395
  },
  {
    t: 1582663200,
    v: 54.100715775838495
  },
  {
    t: 1582663500,
    v: 54.477527321907075
  },
  {
    t: 1582663800,
    v: 47.366938952753806
  },
  {
    t: 1582664100,
    v: 50.155186574152296
  },
  {
    t: 1582664400,
    v: 58.52522485486223
  },
  {
    t: 1582664700,
    v: 53.872779179930355
  },
  {
    t: 1582665000,
    v: 54.78561831158228
  },
  {
    t: 1582665300,
    v: 47.7639191534503
  },
  {
    t: 1582665600,
    v: 53.52420172972663
  },
  {
    t: 1582665900,
    v: 63.87215633264539
  },
  {
    t: 1582666200,
    v: 59.062834221608846
  },
  {
    t: 1582666500,
    v: 53.3267041559977
  },
  {
    t: 1582666800,
    v: 51.60825348269901
  },
  {
    t: 1582667100,
    v: 46.47359249619278
  },
  {
    t: 1582667400,
    v: 40.35537778833468
  },
  {
    t: 1582667700,
    v: 35.464214127869866
  },
  {
    t: 1582668000,
    v: 26.97285861947391
  },
  {
    t: 1582668300,
    v: 36.67643859469316
  },
  {
    t: 1582668600,
    v: 38.021016726156866
  },
  {
    t: 1582668900,
    v: 40.19782846940989
  },
  {
    t: 1582669200,
    v: 51.2795519939596
  },
  {
    t: 1582669500,
    v: 44.61066543925074
  },
  {
    t: 1582669800,
    v: 50.71495298943573
  },
  {
    t: 1582670100,
    v: 50.71495298943574
  },
  {
    t: 1582670400,
    v: 41.70927132299043
  },
  {
    t: 1582670700,
    v: 41.15249921925706
  },
  {
    t: 1582671000,
    v: 51.50925438354048
  },
  {
    t: 1582671300,
    v: 48.71002833388178
  },
  {
    t: 1582671600,
    v: 43.328029617122425
  },
  {
    t: 1582671900,
    v: 42.00207722124087
  },
  {
    t: 1582672200,
    v: 39.256152260150536
  },
  {
    t: 1582672500,
    v: 34.716858297706935
  },
  {
    t: 1582672800,
    v: 29.674830434591
  },
  {
    t: 1582673100,
    v: 32.176469502331685
  },
  {
    t: 1582673400,
    v: 26.74082947091523
  },
  {
    t: 1582673700,
    v: 44.84372655574265
  },
  {
    t: 1582674000,
    v: 39.58319164612978
  },
  {
    t: 1582674300,
    v: 45.92645906846766
  },
  {
    t: 1582674600,
    v: 47.718816236387326
  },
  {
    t: 1582674900,
    v: 56.04441960928516
  },
  {
    t: 1582675200,
    v: 40.54749356835677
  },
  {
    t: 1582675500,
    v: 35.7110025187813
  },
  {
    t: 1582675800,
    v: 33.19689804246737
  },
  {
    t: 1582676100,
    v: 31.189334409740525
  },
  {
    t: 1582676400,
    v: 16.889756126567562
  },
  {
    t: 1582676700,
    v: 12.386394562050526
  },
  {
    t: 1582677000,
    v: 24.557138508323476
  },
  {
    t: 1582677300,
    v: 28.944094075836603
  },
  {
    t: 1582677600,
    v: 25.76428895906021
  },
  {
    t: 1582677900,
    v: 32.302538903511916
  },
  {
    t: 1582678200,
    v: 31.980633948594857
  },
  {
    t: 1582678500,
    v: 32.746578505421766
  },
  {
    t: 1582678800,
    v: 41.08653782286238
  },
  {
    t: 1582679100,
    v: 40.56391464030491
  },
  {
    t: 1582679400,
    v: 53.95208038558582
  },
  {
    t: 1582679700,
    v: 56.736438586403054
  },
  {
    t: 1582680000,
    v: 59.53290174499561
  },
  {
    t: 1582680300,
    v: 61.79095878796286
  },
  {
    t: 1582680600,
    v: 51.18295818317379
  },
  {
    t: 1582680900,
    v: 45.66894089458542
  },
  {
    t: 1582681200,
    v: 44.976739186758515
  },
  {
    t: 1582681500,
    v: 50.927101664260476
  },
  {
    t: 1582681800,
    v: 51.77849792699257
  },
  {
    t: 1582682100,
    v: 60.41283606022333
  },
  {
    t: 1582682400,
    v: 57.2194424204188
  },
  {
    t: 1582682700,
    v: 51.98531152376721
  },
  {
    t: 1582683000,
    v: 31.946628182351574
  },
  {
    t: 1582683300,
    v: 35.72217933641335
  },
  {
    t: 1582683600,
    v: 31.262273097387904
  },
  {
    t: 1582683900,
    v: 32.335029503101424
  },
  {
    t: 1582684200,
    v: 21.565190230339184
  },
  {
    t: 1582684500,
    v: 17.516866625900846
  },
  {
    t: 1582684800,
    v: 17.516866625900832
  },
  {
    t: 1582685100,
    v: 18.68960988303091
  },
  {
    t: 1582685400,
    v: 31.95722857214399
  },
  {
    t: 1582685700,
    v: 46.83013111209942
  },
  {
    t: 1582686000,
    v: 53.87203594190385
  },
  {
    t: 1582686300,
    v: 55.107493065503824
  },
  {
    t: 1582686600,
    v: 47.166862110935014
  },
  {
    t: 1582686900,
    v: 47.946147434604505
  },
  {
    t: 1582687200,
    v: 42.24864163261572
  },
  {
    t: 1582687500,
    v: 30.760513294604237
  },
  {
    t: 1582687800,
    v: 29.915370342977596
  },
  {
    t: 1582688100,
    v: 39.42553685108526
  },
  {
    t: 1582688400,
    v: 37.67283963577671
  },
  {
    t: 1582688700,
    v: 38.71080741719461
  },
  {
    t: 1582689000,
    v: 39.855514302046586
  },
  {
    t: 1582689300,
    v: 44.586763766089405
  },
  {
    t: 1582689600,
    v: 40.908945510809254
  },
  {
    t: 1582689900,
    v: 52.17918704307788
  },
  {
    t: 1582690200,
    v: 62.09414674500704
  },
  {
    t: 1582690500,
    v: 46.48857041871465
  },
  {
    t: 1582690800,
    v: 45.71617085258525
  },
  {
    t: 1582691100,
    v: 51.26819704990322
  },
  {
    t: 1582691400,
    v: 53.959034160840105
  },
  {
    t: 1582691700,
    v: 46.1866834921087
  },
  {
    t: 1582692000,
    v: 49.31554414773649
  },
  {
    t: 1582692300,
    v: 45.30186010350677
  },
  {
    t: 1582692600,
    v: 55.621488128355175
  },
  {
    t: 1582692900,
    v: 55.621488128355175
  },
  {
    t: 1582693200,
    v: 64.3952091097349
  },
  {
    t: 1582693500,
    v: 67.34637998877334
  },
  {
    t: 1582693800,
    v: 77.47960163935306
  },
  {
    t: 1582694100,
    v: 65.29208808596962
  },
  {
    t: 1582694400,
    v: 71.4508580787201
  },
  {
    t: 1582694700,
    v: 79.6888580017053
  },
  {
    t: 1582695000,
    v: 74.56591211713861
  },
  {
    t: 1582695300,
    v: 76.3066768889182
  },
  {
    t: 1582695600,
    v: 82.16565954094473
  },
  {
    t: 1582695900,
    v: 85.48613881461746
  },
  {
    t: 1582696200,
    v: 73.52280630072937
  },
  {
    t: 1582696500,
    v: 73.84459187307941
  },
  {
    t: 1582696800,
    v: 72.83297833616992
  },
  {
    t: 1582697100,
    v: 59.73894434027888
  },
  {
    t: 1582697400,
    v: 58.87489321899979
  },
  {
    t: 1582697700,
    v: 66.1491244165806
  },
  {
    t: 1582698000,
    v: 59.6556345672761
  },
  {
    t: 1582698300,
    v: 54.42232621583746
  },
  {
    t: 1582698600,
    v: 42.38291397303616
  },
  {
    t: 1582698900,
    v: 35.5163047046197
  },
  {
    t: 1582699200,
    v: 32.29633760904909
  },
  {
    t: 1582699500,
    v: 36.08083863275597
  },
  {
    t: 1582699800,
    v: 43.32236516524387
  },
  {
    t: 1582700100,
    v: 59.648963851534845
  },
  {
    t: 1582700400,
    v: 55.9641476086774
  },
  {
    t: 1582700700,
    v: 41.363001966875736
  },
  {
    t: 1582701000,
    v: 35.44456923113563
  },
  {
    t: 1582701300,
    v: 38.32607920277895
  },
  {
    t: 1582701600,
    v: 32.17169623507597
  },
  {
    t: 1582701900,
    v: 23.962776895703655
  },
  {
    t: 1582702200,
    v: 40.65139042176283
  },
  {
    t: 1582702500,
    v: 53.92168290939905
  },
  {
    t: 1582702800,
    v: 60.721195139898555
  },
  {
    t: 1582703100,
    v: 46.52370207875642
  },
  {
    t: 1582703400,
    v: 59.37811599017722
  },
  {
    t: 1582703700,
    v: 48.19723343122221
  },
  {
    t: 1582704000,
    v: 57.568724868010825
  },
  {
    t: 1582704300,
    v: 65.59415028291733
  },
  {
    t: 1582704600,
    v: 65.92891311540606
  },
  {
    t: 1582704900,
    v: 61.16770547002463
  },
  {
    t: 1582705200,
    v: 60.80921754434904
  },
  {
    t: 1582705500,
    v: 68.07870387450569
  },
  {
    t: 1582705800,
    v: 66.42214934664952
  },
  {
    t: 1582706100,
    v: 65.51124606130081
  },
  {
    t: 1582706400,
    v: 72.7671735026764
  },
  {
    t: 1582706700,
    v: 80.8799397668711
  },
  {
    t: 1582707000,
    v: 86.25969400595949
  },
  {
    t: 1582707300,
    v: 69.17393148813453
  },
  {
    t: 1582707600,
    v: 66.61330620866545
  },
  {
    t: 1582707900,
    v: 71.78565281603593
  },
  {
    t: 1582708200,
    v: 60.98755249758473
  },
  {
    t: 1582708500,
    v: 59.672386846529314
  },
  {
    t: 1582708800,
    v: 63.455130453557246
  },
  {
    t: 1582709100,
    v: 49.54479095510768
  },
  {
    t: 1582709400,
    v: 59.94480549451443
  },
  {
    t: 1582709700,
    v: 44.77201344168498
  },
  {
    t: 1582710000,
    v: 50.841584066493574
  },
  {
    t: 1582710300,
    v: 59.55087903743466
  },
  {
    t: 1582710600,
    v: 48.70339564970995
  },
  {
    t: 1582710900,
    v: 51.314004492006646
  },
  {
    t: 1582711200,
    v: 43.06329179537388
  },
  {
    t: 1582711500,
    v: 36.37844270707642
  },
  {
    t: 1582711800,
    v: 38.7085165348473
  },
  {
    t: 1582712100,
    v: 36.54173949032775
  },
  {
    t: 1582712400,
    v: 41.99844331454943
  },
  {
    t: 1582712700,
    v: 34.53249747148472
  },
  {
    t: 1582713000,
    v: 38.82590444559471
  },
  {
    t: 1582713300,
    v: 43.71087353129304
  },
  {
    t: 1582713600,
    v: 44.40276500200515
  },
  {
    t: 1582713900,
    v: 43.99078525163636
  },
  {
    t: 1582714200,
    v: 53.58758770530311
  },
  {
    t: 1582714500,
    v: 61.187822761992656
  },
  {
    t: 1582714800,
    v: 62.62271078501
  },
  {
    t: 1582715100,
    v: 61.64597312759056
  },
  {
    t: 1582715400,
    v: 55.39249947784772
  },
  {
    t: 1582715700,
    v: 56.713129455271385
  },
  {
    t: 1582716000,
    v: 50.55521474753673
  },
  {
    t: 1582716300,
    v: 43.897409706603256
  },
  {
    t: 1582716600,
    v: 31.122452720177648
  },
  {
    t: 1582716900,
    v: 32.61653521832832
  },
  {
    t: 1582717200,
    v: 29.507600020398115
  },
  {
    t: 1582717500,
    v: 47.62930226318815
  },
  {
    t: 1582717800,
    v: 54.766291949835946
  },
  {
    t: 1582718100,
    v: 59.15365756986785
  },
  {
    t: 1582718400,
    v: 68.01692429379413
  },
  {
    t: 1582718700,
    v: 76.43285367028847
  },
  {
    t: 1582719000,
    v: 70.00056491230768
  },
  {
    t: 1582719300,
    v: 52.37116030457314
  },
  {
    t: 1582719600,
    v: 52.37116030457314
  },
  {
    t: 1582719900,
    v: 61.16417294060083
  },
  {
    t: 1582720200,
    v: 52.76244395846518
  },
  {
    t: 1582720500,
    v: 54.88708540219851
  },
  {
    t: 1582720800,
    v: 36.772457304475715
  },
  {
    t: 1582721100,
    v: 36.062775359999655
  },
  {
    t: 1582721400,
    v: 32.48074667446353
  },
  {
    t: 1582721700,
    v: 27.48814152213204
  },
  {
    t: 1582722000,
    v: 26.897511820156012
  },
  {
    t: 1582722300,
    v: 27.784186212682044
  },
  {
    t: 1582722600,
    v: 41.774287385772304
  },
  {
    t: 1582722900,
    v: 38.1964331780996
  },
  {
    t: 1582723200,
    v: 42.542333502610106
  },
  {
    t: 1582723500,
    v: 40.80621996819507
  },
  {
    t: 1582723800,
    v: 40.80621996819507
  },
  {
    t: 1582724100,
    v: 39.18186355515405
  },
  {
    t: 1582724400,
    v: 33.3328219029335
  },
  {
    t: 1582724700,
    v: 21.596891827473343
  },
  {
    t: 1582725000,
    v: 35.23338346752914
  },
  {
    t: 1582725300,
    v: 35.518213555982726
  },
  {
    t: 1582725600,
    v: 41.407942971531014
  },
  {
    t: 1582725900,
    v: 48.170862554391114
  },
  {
    t: 1582726200,
    v: 56.250447883934626
  },
  {
    t: 1582726500,
    v: 44.4775539335515
  },
  {
    t: 1582726800,
    v: 44.98369002201597
  },
  {
    t: 1582727100,
    v: 36.9087501970947
  },
  {
    t: 1582727400,
    v: 29.31866106593641
  },
  {
    t: 1582727700,
    v: 25.576002423049346
  },
  {
    t: 1582728000,
    v: 39.919135430478825
  },
  {
    t: 1582728300,
    v: 38.50563477010847
  },
  {
    t: 1582728600,
    v: 33.399592439781784
  },
  {
    t: 1582728900,
    v: 41.02307290965535
  },
  {
    t: 1582729200,
    v: 46.566887105949796
  },
  {
    t: 1582729500,
    v: 47.786284436764895
  },
  {
    t: 1582729800,
    v: 52.4944789173391
  },
  {
    t: 1582730100,
    v: 45.14816475535579
  },
  {
    t: 1582730400,
    v: 56.87383287077464
  },
  {
    t: 1582730700,
    v: 67.11374054920094
  },
  {
    t: 1582731000,
    v: 68.92970436673616
  },
  {
    t: 1582731300,
    v: 52.39650268087852
  },
  {
    t: 1582731600,
    v: 46.40026480040728
  },
  {
    t: 1582731900,
    v: 49.407922013115126
  },
  {
    t: 1582732200,
    v: 59.03944388442178
  },
  {
    t: 1582732500,
    v: 50.11777761886298
  },
  {
    t: 1582732800,
    v: 52.66125524788448
  },
  {
    t: 1582733100,
    v: 61.21708860096036
  },
  {
    t: 1582733400,
    v: 62.531322106129764
  },
  {
    t: 1582733700,
    v: 67.55710442224601
  },
  {
    t: 1582734000,
    v: 71.23208008130061
  },
  {
    t: 1582734300,
    v: 79.08436247465582
  },
  {
    t: 1582734600,
    v: 79.3801001709806
  },
  {
    t: 1582734900,
    v: 79.46306673913963
  },
  {
    t: 1582735200,
    v: 70.39881604107524
  },
  {
    t: 1582735500,
    v: 68.48565569390017
  },
  {
    t: 1582735800,
    v: 73.31946289831939
  },
  {
    t: 1582736100,
    v: 61.85660840115225
  },
  {
    t: 1582736400,
    v: 68.16056163747112
  },
  {
    t: 1582736700,
    v: 64.58990835916751
  },
  {
    t: 1582737000,
    v: 66.43212924211338
  },
  {
    t: 1582737300,
    v: 58.523772783279085
  },
  {
    t: 1582737600,
    v: 54.10700321399907
  },
  {
    t: 1582737900,
    v: 52.890506796986855
  },
  {
    t: 1582738200,
    v: 47.137328004885
  },
  {
    t: 1582738500,
    v: 41.44304047130504
  },
  {
    t: 1582738800,
    v: 45.05525274979647
  },
  {
    t: 1582739100,
    v: 41.007804989170886
  },
  {
    t: 1582739400,
    v: 35.53537809549539
  },
  {
    t: 1582739700,
    v: 33.355199134086064
  },
  {
    t: 1582740000,
    v: 38.17207354195423
  },
  {
    t: 1582740300,
    v: 42.889518253079075
  },
  {
    t: 1582740600,
    v: 30.99675069037822
  },
  {
    t: 1582740900,
    v: 39.684896595207576
  },
  {
    t: 1582741200,
    v: 39.401410809588185
  },
  {
    t: 1582741500,
    v: 39.0823462190603
  },
  {
    t: 1582741800,
    v: 56.1331405910153
  },
  {
    t: 1582742100,
    v: 68.40545324153273
  },
  {
    t: 1582742400,
    v: 71.60922382834282
  },
  {
    t: 1582742700,
    v: 41.298035342473966
  },
  {
    t: 1582743000,
    v: 45.677811859082134
  },
  {
    t: 1582743300,
    v: 49.945877709478495
  },
  {
    t: 1582743600,
    v: 52.63219149297184
  },
  {
    t: 1582743900,
    v: 61.48979001334637
  },
  {
    t: 1582744200,
    v: 55.91346785681577
  },
  {
    t: 1582744500,
    v: 60.75558574085351
  },
  {
    t: 1582744800,
    v: 62.08804985901232
  },
  {
    t: 1582745100,
    v: 66.37511622086126
  },
  {
    t: 1582745400,
    v: 71.63468096076713
  },
  {
    t: 1582745700,
    v: 73.89711301900451
  },
  {
    t: 1582746000,
    v: 78.74162371458873
  },
  {
    t: 1582746300,
    v: 71.88076425998915
  },
  {
    t: 1582746600,
    v: 73.49376416410249
  },
  {
    t: 1582746900,
    v: 74.7000221952537
  },
  {
    t: 1582747200,
    v: 56.09955950913675
  },
  {
    t: 1582747500,
    v: 51.76677438860975
  },
  {
    t: 1582747800,
    v: 46.31587398066687
  },
  {
    t: 1582748100,
    v: 42.48169655963516
  },
  {
    t: 1582748400,
    v: 38.15042946514335
  },
  {
    t: 1582748700,
    v: 41.195738310738534
  },
  {
    t: 1582749000,
    v: 41.195738310738534
  },
  {
    t: 1582749300,
    v: 35.737349118841735
  },
  {
    t: 1582749600,
    v: 32.150104608238706
  },
  {
    t: 1582749900,
    v: 33.77799857344
  },
  {
    t: 1582750200,
    v: 32.02191119233488
  },
  {
    t: 1582750500,
    v: 31.400022218519084
  },
  {
    t: 1582750800,
    v: 27.709877404187864
  },
  {
    t: 1582751100,
    v: 34.986467625440554
  },
  {
    t: 1582751400,
    v: 39.831618172828826
  },
  {
    t: 1582751700,
    v: 35.12980045911878
  },
  {
    t: 1582752000,
    v: 41.57823482347421
  },
  {
    t: 1582752300,
    v: 36.108686932149915
  },
  {
    t: 1582752600,
    v: 29.46417765480976
  },
  {
    t: 1582752900,
    v: 23.883378271194644
  },
  {
    t: 1582753200,
    v: 27.041995333899308
  },
  {
    t: 1582753500,
    v: 27.041995333899322
  },
  {
    t: 1582753800,
    v: 21.27613510890555
  },
  {
    t: 1582754100,
    v: 38.50804653849385
  },
  {
    t: 1582754400,
    v: 39.81720880373589
  },
  {
    t: 1582754700,
    v: 31.111785207984866
  },
  {
    t: 1582755000,
    v: 43.90852554468757
  },
  {
    t: 1582755300,
    v: 58.87540382374517
  },
  {
    t: 1582755600,
    v: 62.95482731543843
  },
  {
    t: 1582755900,
    v: 50.95675962006726
  },
  {
    t: 1582756200,
    v: 45.456335988034176
  },
  {
    t: 1582756500,
    v: 51.79899159742288
  },
  {
    t: 1582756800,
    v: 52.196057416254455
  },
  {
    t: 1582757100,
    v: 48.11890825480242
  },
  {
    t: 1582757400,
    v: 47.18289305807986
  },
  {
    t: 1582757700,
    v: 49.43127991904651
  },
  {
    t: 1582758000,
    v: 45.0480489252549
  },
  {
    t: 1582758300,
    v: 43.398773553382966
  },
  {
    t: 1582758600,
    v: 39.53859694975301
  },
  {
    t: 1582758900,
    v: 42.857848770326065
  },
  {
    t: 1582759200,
    v: 47.77303498706831
  },
  {
    t: 1582759500,
    v: 48.61493591657892
  },
  {
    t: 1582759800,
    v: 39.22147868874605
  },
  {
    t: 1582760100,
    v: 51.563093704927674
  },
  {
    t: 1582760400,
    v: 59.67371495030698
  },
  {
    t: 1582760700,
    v: 51.357546591022086
  },
  {
    t: 1582761000,
    v: 62.28040835508684
  },
  {
    t: 1582761300,
    v: 70.87578606328086
  },
  {
    t: 1582761600,
    v: 86.83093418078917
  },
  {
    t: 1582761900,
    v: 91.69213969830729
  },
  {
    t: 1582762200,
    v: 78.73745259747906
  },
  {
    t: 1582762500,
    v: 71.62084764237503
  },
  {
    t: 1582762800,
    v: 75.7890109539938
  },
  {
    t: 1582763100,
    v: 66.57027168888868
  },
  {
    t: 1582763400,
    v: 68.30593163805416
  },
  {
    t: 1582763700,
    v: 63.65706604116598
  },
  {
    t: 1582764000,
    v: 62.4428300406675
  },
  {
    t: 1582764300,
    v: 61.43833195504799
  },
  {
    t: 1582764600,
    v: 57.229709074617524
  },
  {
    t: 1582764900,
    v: 53.074628005114185
  },
  {
    t: 1582765200,
    v: 55.82378580577561
  },
  {
    t: 1582767900,
    v: 54.210595687069976
  },
  {
    t: 1582768200,
    v: 56.371899304188645
  },
  {
    t: 1582768500,
    v: 63.30084077216269
  },
  {
    t: 1582768800,
    v: 66.22046466630624
  },
  {
    t: 1582769100,
    v: 71.63608501311985
  },
  {
    t: 1582769400,
    v: 68.07247645629857
  },
  {
    t: 1582769700,
    v: 69.23839401534373
  },
  {
    t: 1582770000,
    v: 76.19330658961495
  },
  {
    t: 1582770300,
    v: 77.04079435066433
  },
  {
    t: 1582770600,
    v: 77.04079435066433
  },
  {
    t: 1582770900,
    v: 82.21046488708991
  },
  {
    t: 1582771200,
    v: 84.166257375668
  },
  {
    t: 1582771500,
    v: 86.31551549906617
  },
  {
    t: 1582771800,
    v: 74.7236110229414
  },
  {
    t: 1582772100,
    v: 70.40146383206678
  },
  {
    t: 1582772400,
    v: 57.68958346134631
  },
  {
    t: 1582772700,
    v: 54.85942151562523
  },
  {
    t: 1582773000,
    v: 54.25108448888465
  },
  {
    t: 1582773300,
    v: 51.63363869232396
  },
  {
    t: 1582773600,
    v: 48.30430683098613
  },
  {
    t: 1582773900,
    v: 44.37981826591951
  },
  {
    t: 1582774200,
    v: 50.50713702531008
  },
  {
    t: 1582774500,
    v: 49.724588587070535
  },
  {
    t: 1582774800,
    v: 42.244297225660716
  },
  {
    t: 1582775100,
    v: 38.29407031377544
  },
  {
    t: 1582775400,
    v: 38.29407031377544
  },
  {
    t: 1582775700,
    v: 37.530117522970095
  },
  {
    t: 1582776000,
    v: 25.776326624544808
  },
  {
    t: 1582776300,
    v: 27.081291892446004
  },
  {
    t: 1582776600,
    v: 39.28155265964847
  },
  {
    t: 1582776900,
    v: 49.83341903943099
  },
  {
    t: 1582777200,
    v: 49.83341903943099
  },
  {
    t: 1582777500,
    v: 48.82590720593402
  },
  {
    t: 1582777800,
    v: 57.635670917005
  },
  {
    t: 1582778100,
    v: 56.40267053047331
  },
  {
    t: 1582778400,
    v: 57.44314945292351
  },
  {
    t: 1582778700,
    v: 63.42814208948568
  },
  {
    t: 1582779000,
    v: 77.37052788915344
  },
  {
    t: 1582779300,
    v: 86.07525762775339
  },
  {
    t: 1582779600,
    v: 80.69996367003709
  },
  {
    t: 1582779900,
    v: 66.4684082454929
  },
  {
    t: 1582780200,
    v: 60.017141341758936
  },
  {
    t: 1582780500,
    v: 61.09597919430216
  },
  {
    t: 1582780800,
    v: 44.09783349424136
  },
  {
    t: 1582781100,
    v: 49.2605318795571
  },
  {
    t: 1582781400,
    v: 49.92123098527571
  },
  {
    t: 1582781700,
    v: 51.36865583628916
  },
  {
    t: 1582782000,
    v: 75.3695854762366
  },
  {
    t: 1582782300,
    v: 54.1465895807138
  },
  {
    t: 1582782600,
    v: 59.544713567485836
  },
  {
    t: 1582782900,
    v: 43.9073381851801
  },
  {
    t: 1582783200,
    v: 43.9073381851801
  },
  {
    t: 1582783500,
    v: 41.38378628482849
  },
  {
    t: 1582783800,
    v: 41.38378628482849
  },
  {
    t: 1582784100,
    v: 39.178004237170505
  },
  {
    t: 1582784400,
    v: 47.216302875287234
  },
  {
    t: 1582784700,
    v: 55.31536733588156
  },
  {
    t: 1582785000,
    v: 59.64044171779699
  },
  {
    t: 1582785300,
    v: 71.0100729728511
  },
  {
    t: 1582785600,
    v: 73.22075200637533
  },
  {
    t: 1582785900,
    v: 73.22075200637533
  },
  {
    t: 1582786200,
    v: 61.05792567402922
  },
  {
    t: 1582786500,
    v: 49.729116588935824
  },
  {
    t: 1582786800,
    v: 55.61187456224627
  },
  {
    t: 1582787100,
    v: 63.965218962607395
  },
  {
    t: 1582787400,
    v: 68.85952396204883
  },
  {
    t: 1582787700,
    v: 68.30151926777872
  },
  {
    t: 1582788000,
    v: 63.04655626928589
  },
  {
    t: 1582788300,
    v: 60.11021182484342
  },
  {
    t: 1582788600,
    v: 51.83331049223444
  },
  {
    t: 1582788900,
    v: 46.911760109503504
  },
  {
    t: 1582789200,
    v: 51.63518461990626
  },
  {
    t: 1582789500,
    v: 44.58325599652954
  },
  {
    t: 1582789800,
    v: 65.40847429130142
  },
  {
    t: 1582790100,
    v: 72.77140740435232
  },
  {
    t: 1582790400,
    v: 75.0884707565187
  },
  {
    t: 1582790700,
    v: 85.68814377628402
  },
  {
    t: 1582791000,
    v: 74.04907004521422
  },
  {
    t: 1582791300,
    v: 75.69944947246591
  },
  {
    t: 1582791600,
    v: 77.01220766566696
  },
  {
    t: 1582791900,
    v: 82.58396342292689
  },
  {
    t: 1582792200,
    v: 85.1772127438305
  },
  {
    t: 1582792500,
    v: 86.63072918208051
  },
  {
    t: 1582792800,
    v: 85.38264170537255
  },
  {
    t: 1582793100,
    v: 80.52139435152401
  },
  {
    t: 1582793400,
    v: 82.85412636113107
  },
  {
    t: 1582793700,
    v: 85.73940443193064
  },
  {
    t: 1582794000,
    v: 67.73259858757044
  },
  {
    t: 1582794300,
    v: 57.71196686776582
  },
  {
    t: 1582794600,
    v: 51.78855387762631
  },
  {
    t: 1582794900,
    v: 50.25087679064201
  },
  {
    t: 1582795200,
    v: 51.883614057471775
  },
  {
    t: 1582795500,
    v: 43.23482583013394
  },
  {
    t: 1582795800,
    v: 41.03607826948962
  },
  {
    t: 1582796100,
    v: 49.232379502915094
  },
  {
    t: 1582796400,
    v: 45.47568331290666
  },
  {
    t: 1582796700,
    v: 43.71615192160513
  },
  {
    t: 1582797000,
    v: 51.56864843637924
  },
  {
    t: 1582797300,
    v: 49.07873288365489
  },
  {
    t: 1582797600,
    v: 48.694870118361244
  },
  {
    t: 1582797900,
    v: 42.9377518203382
  },
  {
    t: 1582798200,
    v: 61.37703717716488
  },
  {
    t: 1582798500,
    v: 60.95231834983932
  },
  {
    t: 1582798800,
    v: 58.19113273826256
  },
  {
    t: 1582799100,
    v: 61.20397123199498
  },
  {
    t: 1582799400,
    v: 61.203971231994984
  },
  {
    t: 1582799700,
    v: 65.52984035206256
  },
  {
    t: 1582800000,
    v: 72.53075481385767
  },
  {
    t: 1582800300,
    v: 72.53075481385767
  },
  {
    t: 1582800600,
    v: 57.82931115228913
  },
  {
    t: 1582800900,
    v: 53.5172429933706
  },
  {
    t: 1582801200,
    v: 45.72422170967506
  },
  {
    t: 1582801500,
    v: 60.51073172500331
  },
  {
    t: 1582801800,
    v: 53.155189072655205
  },
  {
    t: 1582802100,
    v: 39.96804433529616
  },
  {
    t: 1582802400,
    v: 50.033938085160564
  },
  {
    t: 1582802700,
    v: 41.493566019604614
  },
  {
    t: 1582803000,
    v: 44.83339929379555
  },
  {
    t: 1582803300,
    v: 48.90740160978199
  },
  {
    t: 1582803600,
    v: 61.35936541774135
  },
  {
    t: 1582803900,
    v: 70.08760066338476
  },
  {
    t: 1582804200,
    v: 60.503226443088785
  },
  {
    t: 1582804500,
    v: 63.0684738266332
  },
  {
    t: 1582804800,
    v: 60.4510143495283
  },
  {
    t: 1582805100,
    v: 64.09981012613576
  },
  {
    t: 1582805400,
    v: 63.652375580759404
  },
  {
    t: 1582805700,
    v: 63.148609977174104
  },
  {
    t: 1582806000,
    v: 65.9226585238896
  },
  {
    t: 1582806300,
    v: 68.3434800794393
  },
  {
    t: 1582806600,
    v: 77.3199805982228
  },
  {
    t: 1582806900,
    v: 84.69579520848855
  },
  {
    t: 1582807200,
    v: 88.06184426138803
  },
  {
    t: 1582807500,
    v: 88.64563129504167
  },
  {
    t: 1582807800,
    v: 88.64563129504167
  },
  {
    t: 1582808100,
    v: 89.99472254787277
  },
  {
    t: 1582808400,
    v: 82.3441686148062
  },
  {
    t: 1582808700,
    v: 84.33446838211214
  },
  {
    t: 1582809000,
    v: 76.77111256900939
  },
  {
    t: 1582809300,
    v: 78.77885532338054
  },
  {
    t: 1582809600,
    v: 70.6382226645743
  },
  {
    t: 1582809900,
    v: 70.88383298124268
  },
  {
    t: 1582810200,
    v: 67.6501674924391
  },
  {
    t: 1582810500,
    v: 80.35223977314905
  },
  {
    t: 1582810800,
    v: 75.4382216675022
  },
  {
    t: 1582811100,
    v: 82.78928074135881
  },
  {
    t: 1582811400,
    v: 78.865571611039
  },
  {
    t: 1582811700,
    v: 83.00736081835586
  },
  {
    t: 1582812000,
    v: 72.22344748713508
  },
  {
    t: 1582812300,
    v: 72.22344748713508
  },
  {
    t: 1582812600,
    v: 48.54233176461741
  },
  {
    t: 1582812900,
    v: 55.54391161852479
  },
  {
    t: 1582813200,
    v: 64.0304024761866
  },
  {
    t: 1582813500,
    v: 64.38359882158665
  },
  {
    t: 1582813800,
    v: 62.970286215313976
  },
  {
    t: 1582814100,
    v: 57.22753548418582
  },
  {
    t: 1582814400,
    v: 73.78930026690664
  },
  {
    t: 1582814700,
    v: 65.4312360050208
  },
  {
    t: 1582815000,
    v: 50.49145877037342
  },
  {
    t: 1582815300,
    v: 56.519112404095786
  },
  {
    t: 1582815600,
    v: 70.43382350557786
  },
  {
    t: 1582815900,
    v: 72.53050210516858
  },
  {
    t: 1582816200,
    v: 75.58147627534397
  },
  {
    t: 1582816500,
    v: 67.47905085459666
  },
  {
    t: 1582816800,
    v: 72.5682847209062
  },
  {
    t: 1582817100,
    v: 73.20130405527621
  },
  {
    t: 1582817400,
    v: 79.40784585848493
  },
  {
    t: 1582817700,
    v: 64.47542456864542
  },
  {
    t: 1582818000,
    v: 70.46093745647985
  },
  {
    t: 1582818300,
    v: 50.763044032654015
  },
  {
    t: 1582818600,
    v: 52.80380060902017
  },
  {
    t: 1582818900,
    v: 42.8748242556599
  },
  {
    t: 1582819200,
    v: 46.16167595567421
  },
  {
    t: 1582819500,
    v: 35.87595998549584
  },
  {
    t: 1582819800,
    v: 36.32226041195893
  },
  {
    t: 1582820100,
    v: 38.126824088916294
  },
  {
    t: 1582820400,
    v: 43.907235184528936
  },
  {
    t: 1582820700,
    v: 42.23241016802383
  },
  {
    t: 1582821000,
    v: 35.96159214251658
  },
  {
    t: 1582821300,
    v: 40.612852417150016
  },
  {
    t: 1582821600,
    v: 43.16817524502245
  },
  {
    t: 1582821900,
    v: 47.16532944542288
  },
  {
    t: 1582822200,
    v: 46.97652936229888
  },
  {
    t: 1582822500,
    v: 39.55696976060974
  },
  {
    t: 1582822800,
    v: 33.88765685370949
  },
  {
    t: 1582823100,
    v: 28.693160550272594
  },
  {
    t: 1582823400,
    v: 26.793308330641466
  },
  {
    t: 1582823700,
    v: 34.90630692885236
  },
  {
    t: 1582824000,
    v: 41.991038359693924
  },
  {
    t: 1582824300,
    v: 41.79847524493618
  },
  {
    t: 1582824600,
    v: 39.91571567014508
  },
  {
    t: 1582824900,
    v: 31.60107400094452
  },
  {
    t: 1582825200,
    v: 45.661831878142756
  },
  {
    t: 1582825500,
    v: 45.44828222851443
  },
  {
    t: 1582825800,
    v: 40.86185171932964
  },
  {
    t: 1582826100,
    v: 63.2593595809162
  },
  {
    t: 1582826400,
    v: 61.34565567337828
  },
  {
    t: 1582826700,
    v: 62.48067934453377
  },
  {
    t: 1582827000,
    v: 64.03254024361837
  },
  {
    t: 1582827300,
    v: 71.15349625886182
  },
  {
    t: 1582827600,
    v: 72.60426524798187
  },
  {
    t: 1582827900,
    v: 72.22686419958967
  },
  {
    t: 1582828200,
    v: 73.78424658360562
  },
  {
    t: 1582828500,
    v: 76.49558786212397
  },
  {
    t: 1582828800,
    v: 81.48137631700828
  },
  {
    t: 1582829100,
    v: 64.35743332224357
  },
  {
    t: 1582829400,
    v: 54.227384217673354
  },
  {
    t: 1582829700,
    v: 61.54106096142457
  },
  {
    t: 1582830000,
    v: 64.19195359803211
  },
  {
    t: 1582830300,
    v: 64.37902239863817
  },
  {
    t: 1582830600,
    v: 66.38595792346058
  },
  {
    t: 1582830900,
    v: 71.20641882185275
  },
  {
    t: 1582831200,
    v: 61.51268448821267
  },
  {
    t: 1582831500,
    v: 48.2896616952408
  },
  {
    t: 1582831800,
    v: 52.530394785216195
  },
  {
    t: 1582832100,
    v: 50.746803005872614
  },
  {
    t: 1582832400,
    v: 48.250390697393236
  },
  {
    t: 1582832700,
    v: 47.581617613140054
  },
  {
    t: 1582833000,
    v: 48.399005079403786
  },
  {
    t: 1582833300,
    v: 48.85473936948234
  },
  {
    t: 1582833600,
    v: 50.35793892149305
  },
  {
    t: 1582833900,
    v: 31.60380055571936
  },
  {
    t: 1582834200,
    v: 40.24183627218602
  },
  {
    t: 1582834500,
    v: 43.4178332571448
  },
  {
    t: 1582834800,
    v: 45.77061389256214
  },
  {
    t: 1582835100,
    v: 52.928743812983186
  },
  {
    t: 1582835400,
    v: 50.09486475633123
  },
  {
    t: 1582835700,
    v: 64.03331361922604
  },
  {
    t: 1582836000,
    v: 71.07855066879432
  },
  {
    t: 1582836300,
    v: 64.59610594994933
  },
  {
    t: 1582836600,
    v: 71.0869453200913
  },
  {
    t: 1582836900,
    v: 62.64842317289011
  },
  {
    t: 1582837200,
    v: 62.179776925834084
  },
  {
    t: 1582837500,
    v: 69.89978562036958
  },
  {
    t: 1582837800,
    v: 73.23041300919843
  },
  {
    t: 1582838100,
    v: 61.5543051187826
  },
  {
    t: 1582838400,
    v: 62.68749254677613
  },
  {
    t: 1582838700,
    v: 50.04418123999948
  },
  {
    t: 1582839000,
    v: 55.20662858679424
  },
  {
    t: 1582839300,
    v: 63.99477794511473
  },
  {
    t: 1582839600,
    v: 64.25333090348727
  },
  {
    t: 1582839900,
    v: 65.11228459463473
  },
  {
    t: 1582840200,
    v: 71.81930852254526
  },
  {
    t: 1582840500,
    v: 68.35362633576104
  },
  {
    t: 1582840800,
    v: 78.31811869116574
  },
  {
    t: 1582841100,
    v: 78.47304875381373
  },
  {
    t: 1582841400,
    v: 67.45565131355247
  },
  {
    t: 1582841700,
    v: 69.6472498345665
  },
  {
    t: 1582842000,
    v: 63.16544895827581
  },
  {
    t: 1582842300,
    v: 62.61050437533817
  },
  {
    t: 1582842600,
    v: 65.06583712648228
  },
  {
    t: 1582842900,
    v: 63.038247580411344
  },
  {
    t: 1582843200,
    v: 65.10923837269695
  },
  {
    t: 1582843500,
    v: 67.59899708405317
  },
  {
    t: 1582843800,
    v: 59.51028236795939
  },
  {
    t: 1582844100,
    v: 60.588204596620976
  },
  {
    t: 1582844400,
    v: 57.94377114200068
  },
  {
    t: 1582844700,
    v: 43.48366730316875
  },
  {
    t: 1582845000,
    v: 43.48366730316876
  },
  {
    t: 1582845300,
    v: 51.403618192656786
  },
  {
    t: 1582845600,
    v: 47.59252788530399
  },
  {
    t: 1582845900,
    v: 32.27707852795385
  },
  {
    t: 1582846200,
    v: 35.65758842602747
  },
  {
    t: 1582846500,
    v: 41.498046261301546
  },
  {
    t: 1582855200,
    v: 13.69428829764476
  },
  {
    t: 1582855500,
    v: 19.118980385151403
  },
  {
    t: 1582855800,
    v: 26.986239869063027
  },
  {
    t: 1582856100,
    v: 27.4343937143733
  },
  {
    t: 1582856400,
    v: 23.481217197554727
  },
  {
    t: 1582856700,
    v: 34.6854361001826
  },
  {
    t: 1582857000,
    v: 37.20732363488338
  },
  {
    t: 1582857300,
    v: 33.07237020845329
  },
  {
    t: 1582857600,
    v: 30.773265258800237
  },
  {
    t: 1582857900,
    v: 34.91028666863674
  },
  {
    t: 1582858200,
    v: 34.03850523432041
  },
  {
    t: 1582858500,
    v: 31.631203151787872
  },
  {
    t: 1582858800,
    v: 27.468393185505406
  },
  {
    t: 1582859100,
    v: 26.666244863099976
  },
  {
    t: 1582859400,
    v: 33.3404144145923
  },
  {
    t: 1582859700,
    v: 31.867262618803522
  },
  {
    t: 1582860000,
    v: 45.60202600787894
  },
  {
    t: 1582860300,
    v: 47.419115987883494
  },
  {
    t: 1582860600,
    v: 51.71974514224456
  },
  {
    t: 1582860900,
    v: 60.65214241893616
  },
  {
    t: 1582861200,
    v: 72.3463894081496
  },
  {
    t: 1582861500,
    v: 68.90461413130032
  },
  {
    t: 1582861800,
    v: 51.53316342216687
  },
  {
    t: 1582862100,
    v: 41.503690683819016
  },
  {
    t: 1582862400,
    v: 48.15636959905092
  },
  {
    t: 1582862700,
    v: 54.119668656845015
  },
  {
    t: 1582863000,
    v: 53.649495839790305
  },
  {
    t: 1582863300,
    v: 52.60489472184757
  },
  {
    t: 1582863600,
    v: 58.18746883440394
  },
  {
    t: 1582863900,
    v: 64.8842042644946
  },
  {
    t: 1582864200,
    v: 62.85393592831667
  },
  {
    t: 1582864500,
    v: 67.50247685255087
  },
  {
    t: 1582864800,
    v: 74.00789603955708
  },
  {
    t: 1582865100,
    v: 74.00789603955708
  },
  {
    t: 1582865400,
    v: 76.62696667538918
  },
  {
    t: 1582865700,
    v: 60.39781745360069
  },
  {
    t: 1582866000,
    v: 52.00579650011869
  },
  {
    t: 1582866300,
    v: 64.19078829494205
  },
  {
    t: 1582866600,
    v: 65.52454655797239
  },
  {
    t: 1582866900,
    v: 53.09418044155461
  },
  {
    t: 1582867200,
    v: 70.70482100470579
  },
  {
    t: 1582867500,
    v: 71.11123578750664
  },
  {
    t: 1582867800,
    v: 59.34902655606021
  },
  {
    t: 1582868100,
    v: 63.49002791342581
  },
  {
    t: 1582868400,
    v: 57.26744161526841
  },
  {
    t: 1582868700,
    v: 66.61585021491919
  },
  {
    t: 1582869000,
    v: 62.59180650158386
  },
  {
    t: 1582869300,
    v: 60.72848835127248
  },
  {
    t: 1582869600,
    v: 74.14370341879498
  },
  {
    t: 1582869900,
    v: 75.09987304700346
  },
  {
    t: 1582870200,
    v: 69.2467213223033
  },
  {
    t: 1582870500,
    v: 58.77608292858062
  },
  {
    t: 1582870800,
    v: 56.73357020812501
  },
  {
    t: 1582871100,
    v: 59.01252285103799
  },
  {
    t: 1582871400,
    v: 63.69557476296463
  },
  {
    t: 1582871700,
    v: 64.91718563492344
  },
  {
    t: 1582872000,
    v: 65.57903374418477
  },
  {
    t: 1582872300,
    v: 64.87962001029278
  },
  {
    t: 1582872600,
    v: 66.51231911062376
  },
  {
    t: 1582872900,
    v: 40.61675545704369
  },
  {
    t: 1582873200,
    v: 39.87743754809011
  },
  {
    t: 1582873500,
    v: 45.02385208753219
  },
  {
    t: 1582873800,
    v: 30.457208513258536
  },
  {
    t: 1582874100,
    v: 26.84744274054374
  },
  {
    t: 1582874400,
    v: 24.752020511646478
  },
  {
    t: 1582874700,
    v: 46.30498632325335
  },
  {
    t: 1582875000,
    v: 57.201513087095734
  },
  {
    t: 1582875300,
    v: 46.93760215611708
  },
  {
    t: 1582875600,
    v: 39.15207345571215
  },
  {
    t: 1582875900,
    v: 44.11770689574356
  },
  {
    t: 1582876200,
    v: 36.98826938281078
  },
  {
    t: 1582876500,
    v: 39.5108670424357
  },
  {
    t: 1582876800,
    v: 62.22554298757938
  },
  {
    t: 1582877100,
    v: 72.95078140120461
  },
  {
    t: 1582877400,
    v: 75.81313345220866
  },
  {
    t: 1582877700,
    v: 80.88420607720494
  },
  {
    t: 1582878000,
    v: 80.88420607720494
  },
  {
    t: 1582878300,
    v: 82.24289770910795
  },
  {
    t: 1582878600,
    v: 56.09989561165328
  },
  {
    t: 1582878900,
    v: 61.14977132616975
  },
  {
    t: 1582879200,
    v: 65.35410142604883
  },
  {
    t: 1582879500,
    v: 68.39439047638322
  },
  {
    t: 1582879800,
    v: 72.61249962521956
  },
  {
    t: 1582880100,
    v: 77.01513613112051
  },
  {
    t: 1582880400,
    v: 73.51363281916477
  },
  {
    t: 1582880700,
    v: 77.30417140591146
  },
  {
    t: 1582881000,
    v: 83.26503156089794
  },
  {
    t: 1582881300,
    v: 70.15720019101663
  },
  {
    t: 1582881600,
    v: 66.80751094147641
  },
  {
    t: 1582881900,
    v: 72.75429601372586
  },
  {
    t: 1582882200,
    v: 74.59054688818651
  },
  {
    t: 1582882500,
    v: 74.59054688818651
  },
  {
    t: 1582882800,
    v: 65.6775220468329
  },
  {
    t: 1582883100,
    v: 56.50168623187163
  },
  {
    t: 1582883400,
    v: 59.44503781790437
  },
  {
    t: 1582883700,
    v: 62.75713163370055
  },
  {
    t: 1582884000,
    v: 44.51276813329085
  },
  {
    t: 1582884300,
    v: 44.87543789950084
  },
  {
    t: 1582884600,
    v: 52.35009945688262
  },
  {
    t: 1582884900,
    v: 46.21184552212043
  },
  {
    t: 1582885200,
    v: 37.09783902350914
  },
  {
    t: 1582885500,
    v: 38.153893688696364
  },
  {
    t: 1582885800,
    v: 42.14984173345954
  },
  {
    t: 1582886100,
    v: 40.3299430692023
  },
  {
    t: 1582886400,
    v: 41.63895493789578
  },
  {
    t: 1582886700,
    v: 56.80159502392976
  },
  {
    t: 1582887000,
    v: 58.04726777161955
  },
  {
    t: 1582887300,
    v: 48.84829096619934
  },
  {
    t: 1582887600,
    v: 59.91865332347273
  },
  {
    t: 1582887900,
    v: 49.81856797009545
  },
  {
    t: 1582888200,
    v: 41.23864989655034
  },
  {
    t: 1582888500,
    v: 38.5676902885207
  },
  {
    t: 1582888800,
    v: 36.04035537108892
  },
  {
    t: 1582889100,
    v: 23.64482230552936
  },
  {
    t: 1582889400,
    v: 22.860357876637153
  },
  {
    t: 1582889700,
    v: 22.54944951776548
  },
  {
    t: 1582890000,
    v: 17.673473236806785
  },
  {
    t: 1582890300,
    v: 28.391015588465535
  },
  {
    t: 1582890600,
    v: 16.443263265793718
  },
  {
    t: 1582890900,
    v: 25.790822822366238
  },
  {
    t: 1582891200,
    v: 24.9218280145909
  },
  {
    t: 1582891500,
    v: 35.940105236424486
  },
  {
    t: 1582891800,
    v: 32.96895362185967
  },
  {
    t: 1582892100,
    v: 31.24684559203095
  },
  {
    t: 1582892400,
    v: 30.722834580504212
  },
  {
    t: 1582892700,
    v: 25.92055707078417
  },
  {
    t: 1582893000,
    v: 39.9719073551313
  },
  {
    t: 1582893300,
    v: 42.43482448581106
  },
  {
    t: 1582893600,
    v: 36.112936773198776
  },
  {
    t: 1582893900,
    v: 34.53781406144117
  },
  {
    t: 1582894200,
    v: 37.84275724648432
  },
  {
    t: 1582894500,
    v: 33.92760511638828
  },
  {
    t: 1582894800,
    v: 34.17084016330321
  },
  {
    t: 1582895100,
    v: 38.078618708321
  },
  {
    t: 1582895400,
    v: 25.275219669575137
  },
  {
    t: 1582895700,
    v: 38.20770956611783
  },
  {
    t: 1582896000,
    v: 29.209955581133826
  },
  {
    t: 1582896300,
    v: 26.438603587469032
  },
  {
    t: 1582896600,
    v: 32.56490918161131
  },
  {
    t: 1582896900,
    v: 39.108330312073505
  },
  {
    t: 1582897200,
    v: 38.98481003693471
  },
  {
    t: 1582897500,
    v: 42.512445335738434
  },
  {
    t: 1582897800,
    v: 57.775364273613434
  },
  {
    t: 1582898100,
    v: 55.77368369661937
  },
  {
    t: 1582898400,
    v: 52.73642348855569
  },
  {
    t: 1582898700,
    v: 48.57925024059175
  },
  {
    t: 1582899000,
    v: 45.41749113273742
  },
  {
    t: 1582899300,
    v: 41.26480915529991
  },
  {
    t: 1582899600,
    v: 40.89323024449299
  },
  {
    t: 1582899900,
    v: 32.525201430038365
  },
  {
    t: 1582900200,
    v: 23.272696526069765
  },
  {
    t: 1582900500,
    v: 20.734861081060757
  },
  {
    t: 1582900800,
    v: 15.178266149345959
  },
  {
    t: 1582901100,
    v: 35.54780326801696
  },
  {
    t: 1582901400,
    v: 39.16260224934817
  },
  {
    t: 1582901700,
    v: 42.33096064297315
  },
  {
    t: 1582902000,
    v: 39.70516958481691
  },
  {
    t: 1582902300,
    v: 30.429303744975414
  },
  {
    t: 1582902600,
    v: 42.84927717405299
  },
  {
    t: 1582902900,
    v: 43.180496488661326
  },
  {
    t: 1582903200,
    v: 49.53172755627309
  },
  {
    t: 1582903500,
    v: 58.236499732184434
  },
  {
    t: 1582903800,
    v: 51.376096789598314
  },
  {
    t: 1582904100,
    v: 55.24922063832272
  },
  {
    t: 1582904400,
    v: 58.59932554489067
  },
  {
    t: 1582904700,
    v: 67.24104665165166
  },
  {
    t: 1582905000,
    v: 46.641879323910395
  },
  {
    t: 1582905300,
    v: 62.73588360858804
  },
  {
    t: 1582905600,
    v: 64.19646167372787
  },
  {
    t: 1582905900,
    v: 72.02775138175193
  },
  {
    t: 1582906200,
    v: 57.07539888195283
  },
  {
    t: 1582906500,
    v: 59.426763363300914
  },
  {
    t: 1582906800,
    v: 63.8787967497768
  },
  {
    t: 1582907100,
    v: 67.55951329277904
  },
  {
    t: 1582907400,
    v: 64.52730775091305
  },
  {
    t: 1582907700,
    v: 67.29862265600207
  },
  {
    t: 1582908000,
    v: 54.390946350134286
  },
  {
    t: 1582908300,
    v: 56.64894350669782
  },
  {
    t: 1582908600,
    v: 62.864753062363434
  },
  {
    t: 1582908900,
    v: 60.62597853881384
  },
  {
    t: 1582909200,
    v: 65.0687977773645
  },
  {
    t: 1582909500,
    v: 57.37412381477387
  },
  {
    t: 1582909800,
    v: 57.812702718821235
  },
  {
    t: 1582910100,
    v: 62.44905603071317
  },
  {
    t: 1582910400,
    v: 64.46794046305467
  },
  {
    t: 1582910700,
    v: 54.883542436071096
  },
  {
    t: 1582911000,
    v: 59.473995214592165
  },
  {
    t: 1582911300,
    v: 51.06234991201226
  },
  {
    t: 1582911600,
    v: 50.7407157332468
  },
  {
    t: 1582911900,
    v: 49.66808070395077
  },
  {
    t: 1582912200,
    v: 50.465892434422216
  },
  {
    t: 1582912500,
    v: 54.57991630703372
  },
  {
    t: 1582912800,
    v: 48.790918102293446
  },
  {
    t: 1582913100,
    v: 41.199867244796266
  },
  {
    t: 1582913400,
    v: 53.23508824959346
  },
  {
    t: 1582913700,
    v: 41.02212144628039
  },
  {
    t: 1582914000,
    v: 45.88656789328896
  },
  {
    t: 1582914300,
    v: 47.93159586229129
  },
  {
    t: 1582914600,
    v: 55.437755307947036
  },
  {
    t: 1582914900,
    v: 54.97329074015433
  },
  {
    t: 1582915200,
    v: 62.05761094874758
  },
  {
    t: 1582915500,
    v: 54.29704797925003
  },
  {
    t: 1582915800,
    v: 57.43671472036416
  },
  {
    t: 1582916100,
    v: 51.16065112154963
  },
  {
    t: 1582916400,
    v: 59.63012411819561
  },
  {
    t: 1582916700,
    v: 62.628068966165834
  },
  {
    t: 1582917000,
    v: 44.43671427113391
  },
  {
    t: 1582917300,
    v: 36.956200393226965
  },
  {
    t: 1582917600,
    v: 45.36514048939107
  },
  {
    t: 1582917900,
    v: 56.21190562442357
  },
  {
    t: 1582918200,
    v: 75.37808843763342
  },
  {
    t: 1582918500,
    v: 71.6798768803585
  },
  {
    t: 1582918800,
    v: 72.2879035569444
  }
]
