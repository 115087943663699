import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import shortid from 'shortid'
import { firstMarketOrder } from '../../components/bottomMenu/openPositions/fixtures/fixtures'
import { firstPendingOrder } from '../../components/bottomMenu/pendingOrders/fixtures/fixtures'
import { DEFAULT_MARKET_ORDER_WINDOW_STATE } from '../../components/topMenu/tabs/homeTab/orderModals/MarketOrderWindow/MarketOrderWindow'
import InteractiveYCoordinate from '../../libs/react-stockcharts/lib/interactive/InteractiveYCoordinate'
import { isDefined } from '../../libs/react-stockcharts/lib/utils'
import { defaultLots } from '../../utils/constants'
import {
  AccountChangeType,
  MarketOrderTypes,
  OrderInputTypes,
  OrderWindowTypes,
  PendingOrderTypes
} from '../../utils/enums'
import { COMMON_DATE_FORMAT } from '../../utils/helpers/constants'
import { ICurrentBar } from '../../utils/interfaces/ICurrentBar'
import { IOrderLine, ISlTpLine } from '../../utils/interfaces/ILine'
import {
  IAccountHistory,
  ILineInfo,
  IMarketOrder,
  IMarketOrderSetupState,
  IOrderMarker,
  IPendingOrder,
  IPendingOrderSetupState,
  IStopOrder,
  OrderMarkerTypes
} from '../../utils/interfaces/IOrder.interface'
import { IGraphsState } from '../graphs/types'
import {
  addClosedMarketOrderToJournal,
  addModifiedMarketOrderToJournal,
  addOpenMarketOrderToJournal,
  addOpenPendingOrderToJournal
} from '../journal/actions'
import { toggleMarketOrderModal, togglePendingOrderModal } from '../modals/actions'
import { RootState } from '../store/store'
import { IOrdersState } from './types'

type State = {
  execPriceTooltip: boolean
  stopLossTooltip: boolean
  takeProfitTooltip: boolean
  riskCalculationLabel: boolean
  riskPercentage: number
  calculatedLots: number
  slButtonCounter: number
  tpButtonCounter: number
  slButton: number
  tpButton: number
  execPriceButton: number
  execPriceDropdown: number
  slDropdown: number
  tpDropdown: number
} & IPendingOrder

const DEFAULT_ORDER: IPendingOrder = {
  ticket: 0,
  symbol: '',
  type: PendingOrderTypes.BuyLimit,
  lots: defaultLots[0],
  sl: 0,
  tp: 0,
  comment: '',
  createdAt: '',
  execPrice: 0,
  marketPrice: 0,
  barIndex: 0
}

export const DEFAULT_PENDING_ORDER_STATE: State = {
  ...DEFAULT_ORDER,
  execPriceTooltip: false,
  stopLossTooltip: false,
  takeProfitTooltip: false,
  riskCalculationLabel: false,
  riskPercentage: 1,
  calculatedLots: 0,
  slButtonCounter: 0,
  tpButtonCounter: 0,
  slButton: 0,
  tpButton: 0,
  execPriceButton: 0,
  execPriceDropdown: 5,
  slDropdown: 5,
  tpDropdown: 5
}

export const initialState: IOrdersState = {
  market: [],
  pending: [],
  accountHistory: [],
  closedOrdersMobile: [],
  priceSlTpLines: [],
  orderMarkers: [],
  globalOrderTicket: 0,
  marketEditedOrder: firstMarketOrder,
  marketEditMode: false,
  pendingEditedOrder: firstPendingOrder,
  pendingEditMode: false,
  pipetteValues: { execPrice: 0, stopLoss: 0, takeProfit: 0 },
  pipetteModeIsActive: false,
  pipetteType: OrderInputTypes.StopLoss,
  currentOrderType: MarketOrderTypes.Sell,
  orderWindowType: OrderWindowTypes.Market,
  generalPoints: 0,
  generalProfit: 0,
  selectedOrderTicket: -1,
  selectedPendingOrderTicket: -1,
  marketOrderSetup: { ...DEFAULT_MARKET_ORDER_WINDOW_STATE },
  pendingOrderSetup: { ...DEFAULT_PENDING_ORDER_STATE }
}

export const InteractiveYCoordinateStyle = () => {
  return {
    ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,

    edge: {
      ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate.edge,
      stroke: '#ffffff',
      strokeOpacity: 0,
      strokeWidth: 0,
      fill: '#ffffff',
      fillOpacity: 0,
      arrowWidth: 0,
      rectWidth: 0,
      rectHeight: 0,
      displayFormat: '100'
    }
  }
}

//#region asyncThunks

export const closeAndRemovePendingOrder = createAsyncThunk<
  void,
  { currentBars: ICurrentBar; orderTicket?: number },
  { state: RootState }
>('orders/closeAndRemovePendingOrder', async (args, api) => {
  const state = api.getState()
  const { currentBars, orderTicket } = args
  const orders: IOrdersState = state.orders
  const graphs: IGraphsState = state.graphs

  const { pending, selectedPendingOrderTicket } = orders

  const ticket = orderTicket ? orderTicket : selectedPendingOrderTicket

  const order = pending.length === 1 ? pending[0] : pending.find((order) => order.ticket === ticket)

  if (!order) {
    console.error(`The pending order #${ticket} is not found and cannot be modified`)
    return
  }

  const chart = graphs.charts.find((chart) => chart.symbol === order.symbol)

  if (!chart) {
    return
  }

  const closeTime = moment(currentBars[`${chart.symbol}${chart.timeFrame}`].date)
    .utcOffset(0, false)
    .format('YYYY.MM.DD HH:mm')

  const closedOrderData: IAccountHistory = {
    ticket: order.ticket,
    closePrice: order.marketPrice,
    closeTime,
    points: 0,
    profit: 0,
    openPrice: order.execPrice,
    swap: 0,
    commission: 0,
    comment: order.comment,
    symbol: order.symbol,
    type: order.type,
    lots: order.lots,
    openTime: order.createdAt,
    sl: order.sl,
    tp: order.tp,
    barIndex: order.barIndex
  }

  api.dispatch(saveClosedOrder({ closedOrder: closedOrderData, timeframe: chart.timeFrame }))
  api.dispatch(removePendingOrder(order.ticket))
  api.dispatch(ordersSlice.actions.setSelectedPendingOrderTicket(-1))
})

export const createMarketOrder = createAsyncThunk<
  void,
  { order: IMarketOrder; timeframe: number; action?: () => void },
  { state: RootState }
>('orders/createMarketOrder', async (args, api) => {
  const { order, timeframe, action } = args
  const lineInfo: ILineInfo = {
    ticket: order.ticket,
    type: order.type,
    openPrice: order.openPrice,
    sl: order.sl,
    tp: order.tp,
    symbol: order.symbol
  }

  const openOrderMarker: IOrderMarker = {
    id: order.ticket,
    symbol: order.symbol,
    markerType: OrderMarkerTypes.Open,
    orderType: order.type,
    profit: order.profit,
    openTime: moment.utc(order.openTime).format(COMMON_DATE_FORMAT),
    x1: { [order.symbol + timeframe]: order.barIndex },
    y1: order.openPrice,
    closeTime: undefined,
    x2: {},
    y2: undefined
  }

  order.openTime = moment.utc(order.openTime).format(COMMON_DATE_FORMAT)
  order.points = 0
  order.profit = 0

  api.dispatch(ordersSlice.actions.createMarketOrder(order))

  api.dispatch(ordersSlice.actions.addMarketPriceSlTpLines(lineInfo))
  api.dispatch(addOpenMarketOrderToJournal(order))
  api.dispatch(ordersSlice.actions.addOrderMarker(openOrderMarker))
})

export const createPendingOrder = createAsyncThunk<void, { order: IPendingOrder }, { state: RootState }>(
  'orders/createPendingOrder',
  async (args, api) => {
    const { order } = args
    const lineInfo: ILineInfo = {
      ticket: order.ticket,
      type: order.type,
      execPrice: order.execPrice,
      symbol: order.symbol,
      sl: order.sl,
      tp: order.tp
    }

    api.dispatch(ordersSlice.actions.createPendingOrder(order))

    api.dispatch(ordersSlice.actions.addPendingPriceSlTpLines(lineInfo))
    api.dispatch(addOpenPendingOrderToJournal(order))
  }
)

export const removeMarketOrder = createAsyncThunk<void, number, { state: RootState }>(
  'orders/removeMarketOrder',
  (args, api) => {
    api.dispatch(ordersSlice.actions.removePriceSlTpLinesByTicket(args))
    api.dispatch(ordersSlice.actions.removeMarketOrder(args))
  }
)

export const saveClosedOrder = createAsyncThunk<
  void,
  {
    closedOrder: IAccountHistory
    timeframe: number
    updateX2Action?: (orderMarker: IOrderMarker, timeFrame: number) => void
  },
  { state: RootState }
>('orders/saveClosedOrder', async (args, api) => {
  const { closedOrder, timeframe, updateX2Action } = args

  api.dispatch(ordersSlice.actions.saveClosedOrder(closedOrder))

  const state = api.getState()

  const selectedTicket = state.orders.selectedOrderTicket
  if (selectedTicket === closedOrder.ticket) {
    api.dispatch(ordersSlice.actions.setSelectedOrderTicket(-1))
  }

  const markers = state.orders.orderMarkers

  markers.map(({ id, openTime, x1, y1 }, i) => {
    if (closedOrder && id === closedOrder.ticket) {
      let order: IOrderMarker = {
        id,
        symbol: closedOrder.symbol,
        markerType: OrderMarkerTypes.Close,
        orderType: closedOrder.type,
        profit: closedOrder.profit,
        openTime,
        x1,
        y1,
        closeTime: closedOrder.closeTime,
        x2: { [closedOrder.symbol + timeframe]: closedOrder.barIndex },
        y2: closedOrder.closePrice
      }

      if (isDefined(updateX2Action)) {
        updateX2Action!(order, timeframe)
      }

      api.dispatch(ordersSlice.actions.addOrderMarker(order))
    }
  })

  api.dispatch(ordersSlice.actions.removeOrderMarker(closedOrder.ticket))
  api.dispatch(addClosedMarketOrderToJournal(closedOrder))
})

export const removePendingOrder = createAsyncThunk<void, number, { state: RootState }>(
  'orders/removePendingOrder',
  async (args, api) => {
    api.dispatch(ordersSlice.actions.removePriceSlTpLinesByTicket(args))
    api.dispatch(ordersSlice.actions.removePendingOrder(args))
  }
)
export const editMarketOrder = createAsyncThunk<void, { order: IMarketOrder }, { state: RootState }>(
  'orders/editMarketOrder',
  async (args, api) => {
    const { order } = args
    api.dispatch(
      ordersSlice.actions.modifySlTpByOrder({
        sl: order.sl,
        tp: order.tp,
        ticketId: order.ticket
      })
    )

    api.dispatch(ordersSlice.actions.editMarketOrder(order))

    api.dispatch(addModifiedMarketOrderToJournal(order))
  }
)

export const editPendingOrder = createAsyncThunk<void, IPendingOrder, { state: RootState }>(
  'orders/editPendingOrder',
  (args, api) => {
    const order = args
    api.dispatch(
      ordersSlice.actions.modifySlTpByOrder({
        sl: order.sl,
        tp: order.tp,
        ticketId: order.ticket
      })
    )

    api.dispatch(updatePendingOrder(order))
  }
)

export const modifyMarketOrder = createAsyncThunk<void, number | undefined, { state: RootState }>(
  'orders/modifyMarketOrder',
  (args, api) => {
    const { market, selectedOrderTicket } = api.getState().orders
    const ticket = args ? args : selectedOrderTicket

    if (market.length > 1) {
      const order = market.find((order) => order.ticket === ticket)

      if (!order) {
        console.error(`The market order #${ticket} is not found and cannot be modified`)
        return
      }

      api.dispatch(toggleMarketOrderModal())
      api.dispatch(ordersSlice.actions.findMarketOrderForEdit(order))
      api.dispatch(ordersSlice.actions.enableMarketEditMode())
    } else if (market.length === 1) {
      api.dispatch(toggleMarketOrderModal())
      api.dispatch(ordersSlice.actions.findMarketOrderForEdit(market[0]))
      api.dispatch(ordersSlice.actions.enableMarketEditMode())
    }
  }
)

export const modifyPendingOrder = createAsyncThunk<
  void,
  { currentBars: ICurrentBar; orderTicket?: number },
  { state: RootState }
>('orders/modifyPendingOrder', (args, api) => {
  {
    const state = api.getState()
    const { currentBars, orderTicket } = args
    const orders: IOrdersState = state.orders
    const graphs: IGraphsState = state.graphs

    const { pending, selectedPendingOrderTicket } = orders

    const ticket = orderTicket ? orderTicket : selectedPendingOrderTicket

    const order = pending.length === 1 ? pending[0] : pending.find((order) => order.ticket === ticket)

    if (!order) {
      console.error(`The pending order #${ticket} is not found and cannot be modified`)
      return
    }

    const chart = graphs.charts.find((chart) => chart.symbol === order.symbol)

    if (!chart) {
      return
    }

    const close = currentBars[`${chart.symbol}${chart.timeFrame}`].close

    const modificationOrder: IPendingOrder = {
      ...order,
      marketPrice: close
    }

    api.dispatch(togglePendingOrderModal())
    api.dispatch(ordersSlice.actions.enablePendingEditMode())
    api.dispatch(ordersSlice.actions.findPendingOrderForEdit(modificationOrder))
  }
})

//#endregion

const assignAllOrders = createAction<IOrdersState>('assignAllOrders')
const cleanOrdersState = createAction('cleanOrdersState')
const ordersSlice = createSlice({
  name: 'graphics',
  initialState,
  reducers: {
    editMarketOrder: (state: IOrdersState, action: PayloadAction<IMarketOrder>) => {
      state.market = state.market.map((order) =>
        order.ticket === action.payload.ticket ? { ...action.payload } : order
      )
    },
    updatePendingOrder: (state: IOrdersState, action: PayloadAction<IPendingOrder>) => {
      state.pending = state.pending.map((order) =>
        order.ticket === action.payload.ticket ? { ...action.payload } : order
      )
    },
    saveMarketOrderSetupOnPipette: (state: IOrdersState, action: PayloadAction<IMarketOrderSetupState>) => {
      state.marketOrderSetup = action.payload
    },

    savePendingOrderSetupOnPipette: (state: IOrdersState, action: PayloadAction<IPendingOrderSetupState>) => {
      state.pendingOrderSetup = action.payload
    },

    nullifyStopLoss: (state: IOrdersState, action: PayloadAction<number>) => {
      state.market = state.market.map((order) => (order.ticket === action.payload ? { ...order, sl: 0 } : order))
      state.pending = state.pending.map((order) => (order.ticket === action.payload ? { ...order, sl: 0 } : order))
    },

    nullifyTakeProfit: (state: IOrdersState, action: PayloadAction<number>) => {
      ;(state.market = state.market.map((order) => (order.ticket === action.payload ? { ...order, tp: 0 } : order))),
        (state.pending = state.pending.map((order) => (order.ticket === action.payload ? { ...order, tp: 0 } : order)))
    },

    setGlobalOrderTicket: (state: IOrdersState, action: PayloadAction<number>) => {
      state.globalOrderTicket = action.payload
    },

    incrementGlobalOrderTicket: (state: IOrdersState) => {
      state.globalOrderTicket = state.globalOrderTicket + 1
    },

    removeAllMarketOrders: (state: IOrdersState) => {
      const historyMarket: IAccountHistory[] = state.market.map(
        (m) => ({ ...m, closeTime: moment().format(COMMON_DATE_FORMAT), closePrice: m.marketPrice } as IAccountHistory)
      )
      state.accountHistory = state.accountHistory.concat(historyMarket)
      state.market = []
    },

    removeAllPendingOrders: (state: IOrdersState) => {
      // FIXME: here is the problem with type conversion between pending order and IHistory
      // const historyMarket:IAccountHistory[] = state.pending.map(m=>({...m, closeTime: moment().format(COMMON_DATE_FORMAT), closePrice: m.marketPrice} as IAccountHistory))
      // state.accountHistory = state.accountHistory.concat(historyMarket)
      state.pending = []
    },

    removeMarketOrder: (state: IOrdersState, action: PayloadAction<number>) => {
      state.market = state.market.filter((order) => order.ticket !== action.payload)
    },
    removePendingOrder: (state: IOrdersState, action: PayloadAction<number>) => {
      state.pending = state.pending.filter((order) => order.ticket !== action.payload)
    },

    setOrdersLines: (state: IOrdersState, action: PayloadAction<IOrderLine[]>) => {
      state.priceSlTpLines = state.priceSlTpLines.concat(action.payload)
    },

    addMarketPriceSlTpLines: (state: IOrdersState, action: PayloadAction<ILineInfo>) => {
      const {
        payload: { openPrice, ticket, type, sl, tp }
      } = action

      const interactive = InteractiveYCoordinateStyle()
      state.priceSlTpLines = [
        ...state.priceSlTpLines,
        {
          ...interactive,
          yValue: openPrice ?? 0,
          id: shortid.generate(),
          ticketId: ticket,
          lineType: type
          // draggable: false,
          // stroke: '#1F8B55',
          // textFill: '#1F8B55'
        },
        {
          ...interactive,
          yValue: sl,
          id: shortid.generate(),
          ticketId: ticket,
          lineType: 'sl',
          // draggable: true,
          stroke: '#D85C5C',
          textFill: '#D85C5C'
        },
        {
          ...interactive,
          yValue: tp,
          id: shortid.generate(),
          ticketId: ticket,
          lineType: 'tp',
          // draggable: true,
          stroke: '#D85C5C',
          textFill: '#D85C5C'
        }
      ]
    },

    createMarketOrder: (state: IOrdersState, action: PayloadAction<IMarketOrder>) => {
      state.market = [...state.market, action.payload]
    },
    createPendingOrder: (state: IOrdersState, action: PayloadAction<IPendingOrder>) => {
      state.pending = [...state.pending, action.payload]
    },

    addPendingPriceSlTpLines: (state: IOrdersState, action: PayloadAction<ILineInfo>) => {
      const {
        payload: { execPrice, ticket, type, sl, tp }
      } = action

      const interactive = InteractiveYCoordinateStyle()
      state.priceSlTpLines = [
        ...state.priceSlTpLines,
        {
          ...interactive,
          yValue: execPrice ?? 0,
          id: shortid.generate(),
          ticketId: ticket,
          text: `#${ticket} ${type}`,
          lineType: type
          // draggable: type.includes('limit') || type.includes('stop') ? false : true,
          // stroke: '#1F8B55',
          // textFill: '#1F8B55'
        },
        {
          ...interactive,
          yValue: sl,
          id: shortid.generate(),
          ticketId: ticket,
          text: `#${ticket} stop loss`,
          lineType: 'sl'
          // draggable: true,
          // stroke: '#D85C5C',
          // textFill: '#D85C5C'
        },
        {
          ...interactive,
          yValue: tp,
          id: shortid.generate(),
          ticketId: ticket,
          text: `#${ticket} take profit`,
          lineType: 'tp'
          // draggable: true,
          // stroke: '#D85C5C',
          // textFill: '#D85C5C'
        }
      ]
    },

    removeStopLossLine: (state: IOrdersState, action: PayloadAction<string>) => {
      state.priceSlTpLines = state.priceSlTpLines.filter((line) => line.text !== action.payload)
    },

    removeTakeProfitLine: (state: IOrdersState, action: PayloadAction<string>) => {
      state.priceSlTpLines = state.priceSlTpLines.filter((line) => line.text !== action.payload)
    },

    removePriceSlTpLinesByTicket: (state: IOrdersState, action: PayloadAction<number>) => {
      state.priceSlTpLines = state.priceSlTpLines.filter((line) => line.ticketId !== action.payload)
    },
    removePriceSlTpLineById: (state: IOrdersState, action: PayloadAction<string>) => {
      state.priceSlTpLines = state.priceSlTpLines.filter((line) => line.id !== action.payload)
    },
    sendDataOnMarketOrderClose: (state: IOrdersState, action: PayloadAction<{ closedOrderData: IAccountHistory }>) => {
      state.market = state.market.map((order) => {
        //TODO changed index + 1 to order.ticket
        if (order.ticket !== action.payload.closedOrderData.ticket) {
          return order
        }

        return {
          ...order,
          ...action.payload
        }
      })
    },
    setMarketOrders: (state: IOrdersState, action: PayloadAction<IMarketOrder[]>) => {
      state.market = [...state.market, ...action.payload]
    },
    setPendingOrders: (state: IOrdersState, action: PayloadAction<IPendingOrder[]>) => {
      state.pending = [...state.pending, ...action.payload]
    },
    setAccountHistory: (state: IOrdersState, action: PayloadAction<IAccountHistory[]>) => {
      state.accountHistory = [...state.accountHistory, ...action.payload]
    },
    moveStopLossToBreakeven: (state: IOrdersState, action: PayloadAction<IMarketOrder>) => {
      state.market = state.market.map((order) =>
        order.ticket === action.payload.ticket
          ? // if SL was not set initially (equals zero) - should stay as zero
            { ...order, sl: order.sl !== 0 ? action.payload.openPrice : 0 }
          : order
      )
      state.pending = state.pending.map((order) =>
        order.ticket === action.payload.ticket
          ? // if SL was not set initially (equals zero) - should stay as zero
            { ...order, sl: order.sl !== 0 ? action.payload.openPrice : 0 }
          : order
      )
      state.priceSlTpLines = state.priceSlTpLines.map((line) =>
        line.ticketId === action.payload.ticket && line.text.includes('stop loss')
          ? { ...line, yValue: action.payload.openPrice }
          : line
      )
    },
    modifySlTp: (state: IOrdersState, action: PayloadAction<ISlTpLine>) => {
      const { id, ticketId, slOrTpPrice, lineType } = action.payload

      state.market = state.market.map((order) =>
        order.ticket === ticketId ? { ...order, [lineType]: slOrTpPrice } : order
      )
      state.pending = state.pending.map((order) =>
        order.ticket === ticketId ? { ...order, [lineType]: slOrTpPrice } : order
      )
      state.priceSlTpLines = state.priceSlTpLines.map((line) =>
        line.id === id ? { ...line, yValue: slOrTpPrice } : line
      )
    },
    modifySlTpByOrder: (state: IOrdersState, action: PayloadAction<{ sl: number; tp: number; ticketId: number }>) => {
      state.priceSlTpLines = state.priceSlTpLines.map((line) => {
        if (line.ticketId === action.payload.ticketId) {
          if (line.lineType === 'sl') {
            return { ...line, yValue: action.payload.sl }
          }
          if (line.lineType === 'tp') {
            return { ...line, yValue: action.payload.tp }
          }
        }
        return line
      })
    },
    duplicateMarketOrder: (state: IOrdersState, action: PayloadAction<number>) => {
      const duplicateMarketOrder = state.market.find((order) => order.ticket === action.payload)
      // !bug: 1st and 2nd order get the same ticket number of 2
      if (duplicateMarketOrder) {
        duplicateMarketOrder.ticket = state.globalOrderTicket + 1
        state.market = [...state.market, duplicateMarketOrder]
      }
      // market: state.market.push(duplicateMarketOrder),
      state.globalOrderTicket = duplicateMarketOrder ? state.globalOrderTicket + 1 : state.globalOrderTicket
    },
    findMarketOrderForEdit: (state: IOrdersState, action: PayloadAction<IMarketOrder>) => {
      state.marketEditedOrder = action.payload
    },
    modifyLot: (state: IOrdersState, action: PayloadAction<{ ticket: number; lots: number }>) => {
      state.market = state.market.map((order) =>
        order.ticket === action.payload.ticket ? { ...order, lots: action.payload.lots } : order
      )
      state.pending = state.pending.map((order) =>
        order.ticket === action.payload.ticket ? { ...order, lots: action.payload.lots } : order
      )
    },
    findPendingOrderForEdit: (state: IOrdersState, action: PayloadAction<IPendingOrder>) => {
      state.pendingEditedOrder = action.payload
    },
    enableMarketEditMode: (state: IOrdersState) => {
      state.marketEditMode = true
    },
    disableMarketEditMode: (state: IOrdersState) => {
      state.marketEditMode = false
    },
    enablePendingEditMode: (state: IOrdersState) => {
      state.pendingEditMode = true
    },
    disablePendingEditMode: (state: IOrdersState) => {
      state.pendingEditMode = false
    },
    addOrderMarker: (state: IOrdersState, action: PayloadAction<IOrderMarker>) => {
      state.orderMarkers = [...state.orderMarkers, action.payload]
    },
    removeOrderMarker: (state: IOrdersState, action: PayloadAction<number>) => {
      state.orderMarkers = state.orderMarkers.filter(
        (marker) => marker.id !== action.payload || marker.markerType === OrderMarkerTypes.Close
      )
    },
    setPipettevalues: (state: IOrdersState, action: PayloadAction<{ execPrice; stopLoss; takeProfit }>) => {
      const { execPrice, stopLoss, takeProfit } = action.payload
      state.pipetteValues = { execPrice, stopLoss, takeProfit }
    },
    togglePipetteMode: (state: IOrdersState) => {
      state.pipetteModeIsActive = !state.pipetteModeIsActive
    },
    disablePipetteMode: (state: IOrdersState) => {
      state.pipetteModeIsActive = false
    },
    enablePipetteMode: (state: IOrdersState) => {
      state.pipetteModeIsActive = true
    },
    setPipetteType: (state: IOrdersState, action: PayloadAction<OrderInputTypes>) => {
      state.pipetteType = action.payload
    },
    setStopOrders: (state: IOrdersState, action: PayloadAction<IStopOrder>) => {
      const { stopLoss, takeProfit, execPrice } = action.payload
      state.pipetteValues = {
        execPrice: execPrice ?? state.pipetteValues.execPrice,
        stopLoss,
        takeProfit
      }
    },
    saveClosedOrder: (state: IOrdersState, action: PayloadAction<IAccountHistory>) => {
      state.accountHistory = [...state.accountHistory, action.payload]
      state.generalPoints = state.generalPoints + action.payload.points
      state.generalProfit = Number(
        (
          state.generalProfit +
          (action.payload.type !== AccountChangeType.Deposit && action.payload.type !== AccountChangeType.Withdraw
            ? action.payload.profit
            : 0)
        ).toFixed(2)
      )
    },
    setCurrentOrderType: (state: IOrdersState, action: PayloadAction<MarketOrderTypes>) => {
      state.currentOrderType = action.payload
    },
    setOrderWindowType: (state: IOrdersState, action: PayloadAction<OrderWindowTypes>) => {
      state.orderWindowType = action.payload
    },
    updateOrderMarkerX1: (
      state: IOrdersState,
      action: PayloadAction<{ markerIndex: number; barIndex: number; timeframe: number; symbol: string }>
    ) => {
      const { barIndex, markerIndex, symbol, timeframe } = action.payload
      const chartTimeFrame = symbol + timeframe
      state.orderMarkers = state.orderMarkers.map((orderMarker, index) => {
        if (index === markerIndex) {
          return {
            ...orderMarker,
            x1: {
              ...state.orderMarkers[markerIndex].x1,
              [chartTimeFrame]: barIndex
            }
          }
        }

        return orderMarker
      })
    },
    updateOrderMarkerX2: (
      state: IOrdersState,
      action: PayloadAction<{ markerIndex: number; barIndex: number; timeframe: number; symbol: string }>
    ) => {
      const { barIndex, markerIndex, symbol, timeframe } = action.payload
      const chartTimeFrame = symbol + timeframe
      state.orderMarkers = state.orderMarkers.map((orderMarker, index) => {
        if (index === markerIndex) {
          return {
            ...orderMarker,
            x2: {
              ...state.orderMarkers[markerIndex].x2,
              [chartTimeFrame]: barIndex
            }
          }
        }

        return orderMarker
      })
    },
    setSelectedOrderTicket: (state: IOrdersState, action: PayloadAction<number>) => {
      state.selectedOrderTicket = action.payload
    },
    setSelectedPendingOrderTicket: (state: IOrdersState, action: PayloadAction<number>) => {
      state.selectedPendingOrderTicket = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(assignAllOrders, (state, action) => action.payload)
    builder.addCase(cleanOrdersState, (state) => initialState)
  }
})

export const initialOrdersState = initialState
export { assignAllOrders, cleanOrdersState }

export const {
  addMarketPriceSlTpLines,
  addOrderMarker,
  addPendingPriceSlTpLines,
  disableMarketEditMode,
  disablePendingEditMode,
  disablePipetteMode,
  duplicateMarketOrder,
  enableMarketEditMode,
  enablePendingEditMode,
  enablePipetteMode,
  findMarketOrderForEdit,
  findPendingOrderForEdit,
  incrementGlobalOrderTicket,
  modifyLot,
  modifySlTp,
  modifySlTpByOrder,
  moveStopLossToBreakeven,
  nullifyStopLoss,
  nullifyTakeProfit,
  removeAllMarketOrders,
  removeAllPendingOrders,
  removeOrderMarker,
  removePriceSlTpLineById,
  removePriceSlTpLinesByTicket,
  removeStopLossLine,
  removeTakeProfitLine,
  saveMarketOrderSetupOnPipette,
  savePendingOrderSetupOnPipette,
  sendDataOnMarketOrderClose,
  setAccountHistory,
  setCurrentOrderType,
  setGlobalOrderTicket,
  setMarketOrders,
  setOrderWindowType,
  setOrdersLines,
  setPendingOrders,
  setPipetteType,
  setPipettevalues,
  setSelectedOrderTicket,
  setSelectedPendingOrderTicket,
  setStopOrders,
  togglePipetteMode,
  updateOrderMarkerX1,
  updateOrderMarkerX2,
  updatePendingOrder
} = ordersSlice.actions
export default ordersSlice.reducer
