export const defaultATR = [
  {
    t: 1580692500,
    v: 0.07228571428571635
  },
  {
    t: 1580693400,
    v: 0.07464285714285827
  },
  {
    t: 1580694300,
    v: 0.07250000000000105
  },
  {
    t: 1580695200,
    v: 0.07571428571428689
  },
  {
    t: 1580696100,
    v: 0.07871428571428599
  },
  {
    t: 1580697000,
    v: 0.07485714285714298
  },
  {
    t: 1580697900,
    v: 0.0750714285714287
  },
  {
    t: 1580698800,
    v: 0.07335714285714293
  },
  {
    t: 1580699700,
    v: 0.07350000000000076
  },
  {
    t: 1580700600,
    v: 0.06992857142857238
  },
  {
    t: 1580701500,
    v: 0.07042857142857173
  },
  {
    t: 1580702400,
    v: 0.06385714285714325
  },
  {
    t: 1580703300,
    v: 0.0596428571428567
  },
  {
    t: 1580704200,
    v: 0.05564285714285688
  },
  {
    t: 1580705100,
    v: 0.049642857142856656
  },
  {
    t: 1580706000,
    v: 0.04192857142857166
  },
  {
    t: 1580706900,
    v: 0.03992857142857125
  },
  {
    t: 1580707800,
    v: 0.03528571428571427
  },
  {
    t: 1580708700,
    v: 0.03178571428571481
  },
  {
    t: 1580709600,
    v: 0.03214285714285735
  },
  {
    t: 1580710500,
    v: 0.03128571428571446
  },
  {
    t: 1580711400,
    v: 0.032785714285714515
  },
  {
    t: 1580712300,
    v: 0.03135714285714337
  },
  {
    t: 1580713200,
    v: 0.03442857142857138
  },
  {
    t: 1580714100,
    v: 0.03971428571428553
  },
  {
    t: 1580715000,
    v: 0.04278571428571354
  },
  {
    t: 1580715900,
    v: 0.04585714285714256
  },
  {
    t: 1580716800,
    v: 0.05121428571428461
  },
  {
    t: 1580717700,
    v: 0.05550000000000007
  },
  {
    t: 1580718600,
    v: 0.0585714285714291
  },
  {
    t: 1580719500,
    v: 0.06314285714285818
  },
  {
    t: 1580720400,
    v: 0.06642857142857293
  },
  {
    t: 1580721300,
    v: 0.06778571428571516
  },
  {
    t: 1580722200,
    v: 0.06771428571428625
  },
  {
    t: 1580723100,
    v: 0.06871428571428595
  },
  {
    t: 1580724000,
    v: 0.06950000000000094
  },
  {
    t: 1580724900,
    v: 0.06842857142857232
  },
  {
    t: 1580725800,
    v: 0.06921428571428631
  },
  {
    t: 1580726700,
    v: 0.0651428571428586
  },
  {
    t: 1580727600,
    v: 0.06700000000000118
  },
  {
    t: 1580728500,
    v: 0.06464285714285824
  },
  {
    t: 1580729400,
    v: 0.06028571428571589
  },
  {
    t: 1580730300,
    v: 0.056785714285715425
  },
  {
    t: 1580731200,
    v: 0.05492857142857182
  },
  {
    t: 1580732100,
    v: 0.051142857142857726
  },
  {
    t: 1580733000,
    v: 0.0481428571428566
  },
  {
    t: 1580733900,
    v: 0.04757142857142834
  },
  {
    t: 1580734800,
    v: 0.047999999999999786
  },
  {
    t: 1580735700,
    v: 0.048571428571428044
  },
  {
    t: 1580736600,
    v: 0.04614285714285619
  },
  {
    t: 1580737500,
    v: 0.04671428571428444
  },
  {
    t: 1580738400,
    v: 0.043428571428570706
  },
  {
    t: 1580739300,
    v: 0.04321428571428397
  },
  {
    t: 1580740200,
    v: 0.04221428571428427
  },
  {
    t: 1580741100,
    v: 0.04664285714285553
  },
  {
    t: 1580742000,
    v: 0.057428571428569546
  },
  {
    t: 1580742900,
    v: 0.06392857142856911
  },
  {
    t: 1580743800,
    v: 0.06742857142856959
  },
  {
    t: 1580744700,
    v: 0.06964285714285572
  },
  {
    t: 1580745600,
    v: 0.07264285714285583
  },
  {
    t: 1580746500,
    v: 0.07507142857142769
  },
  {
    t: 1580747400,
    v: 0.07971428571428467
  },
  {
    t: 1580748300,
    v: 0.08107142857142792
  },
  {
    t: 1580749200,
    v: 0.08235714285714124
  },
  {
    t: 1580750100,
    v: 0.08364285714285558
  },
  {
    t: 1580751000,
    v: 0.08435714285714166
  },
  {
    t: 1580751900,
    v: 0.08507142857142773
  },
  {
    t: 1580752800,
    v: 0.08449999999999948
  },
  {
    t: 1580753700,
    v: 0.08071428571428539
  },
  {
    t: 1580754600,
    v: 0.07035714285714281
  },
  {
    t: 1580755500,
    v: 0.06421428571428578
  },
  {
    t: 1580756400,
    v: 0.0605714285714285
  },
  {
    t: 1580757300,
    v: 0.058642857142857
  },
  {
    t: 1580758200,
    v: 0.05585714285714362
  },
  {
    t: 1580759100,
    v: 0.053714285714286394
  },
  {
    t: 1580760000,
    v: 0.049214285714287244
  },
  {
    t: 1580760900,
    v: 0.04735714285714465
  },
  {
    t: 1580761800,
    v: 0.04750000000000248
  },
  {
    t: 1580762700,
    v: 0.045785714285715685
  },
  {
    t: 1580763600,
    v: 0.04464285714285816
  },
  {
    t: 1580764500,
    v: 0.04242857142857202
  },
  {
    t: 1580765400,
    v: 0.03857142857142902
  },
  {
    t: 1580766300,
    v: 0.03778571428571403
  },
  {
    t: 1580767200,
    v: 0.03678571428571331
  },
  {
    t: 1580768100,
    v: 0.036071428571427235
  },
  {
    t: 1580769000,
    v: 0.03499999999999862
  },
  {
    t: 1580769900,
    v: 0.0330714285714261
  },
  {
    t: 1580770800,
    v: 0.037428571428568445
  },
  {
    t: 1580771700,
    v: 0.037428571428568445
  },
  {
    t: 1580772600,
    v: 0.035714285714282666
  },
  {
    t: 1580773500,
    v: 0.03428571428571051
  },
  {
    t: 1580774400,
    v: 0.03671428571428237
  },
  {
    t: 1580775300,
    v: 0.03699999999999802
  },
  {
    t: 1580776200,
    v: 0.03735714285714055
  },
  {
    t: 1580777100,
    v: 0.04021428571428487
  },
  {
    t: 1580778000,
    v: 0.04878571428571275
  },
  {
    t: 1580778900,
    v: 0.05085714285714208
  },
  {
    t: 1580779800,
    v: 0.055357142857143264
  },
  {
    t: 1580780700,
    v: 0.05928571428571518
  },
  {
    t: 1580781600,
    v: 0.06114285714285777
  },
  {
    t: 1580782500,
    v: 0.06521428571428649
  },
  {
    t: 1580783400,
    v: 0.061785714285714936
  },
  {
    t: 1580784300,
    v: 0.0615000000000003
  },
  {
    t: 1580785200,
    v: 0.06264285714285782
  },
  {
    t: 1580786100,
    v: 0.06428571428571571
  },
  {
    t: 1580787000,
    v: 0.06678571428571445
  },
  {
    t: 1580787900,
    v: 0.06814285714285669
  },
  {
    t: 1580788800,
    v: 0.06792857142857096
  },
  {
    t: 1580789700,
    v: 0.06792857142856994
  },
  {
    t: 1580790600,
    v: 0.061142857142856756
  },
  {
    t: 1580791500,
    v: 0.058357142857142365
  },
  {
    t: 1580792400,
    v: 0.0530714285714272
  },
  {
    t: 1580793300,
    v: 0.05035714285714172
  },
  {
    t: 1580794200,
    v: 0.053857142857142194
  },
  {
    t: 1580795100,
    v: 0.051642857142856054
  },
  {
    t: 1580796000,
    v: 0.05171428571428395
  },
  {
    t: 1580796900,
    v: 0.052928571428569375
  },
  {
    t: 1580797800,
    v: 0.05478571428571095
  },
  {
    t: 1580798700,
    v: 0.05599999999999637
  },
  {
    t: 1580799600,
    v: 0.05278571428571155
  },
  {
    t: 1580800500,
    v: 0.052285714285711195
  },
  {
    t: 1580801400,
    v: 0.05378571428571125
  },
  {
    t: 1580802300,
    v: 0.05499999999999667
  },
  {
    t: 1580803200,
    v: 0.05721428571428179
  },
  {
    t: 1580804100,
    v: 0.06628571428571003
  },
  {
    t: 1580805000,
    v: 0.06635714285713894
  },
  {
    t: 1580805900,
    v: 0.0649285714285678
  },
  {
    t: 1580806800,
    v: 0.06057142857142444
  },
  {
    t: 1580807700,
    v: 0.06057142857142545
  },
  {
    t: 1580808600,
    v: 0.06335714285714086
  },
  {
    t: 1580809500,
    v: 0.06299999999999731
  },
  {
    t: 1580810400,
    v: 0.060499999999997556
  },
  {
    t: 1580811300,
    v: 0.05957142857142576
  },
  {
    t: 1580812200,
    v: 0.05685714285713926
  },
  {
    t: 1580813100,
    v: 0.05728571428571172
  },
  {
    t: 1580814000,
    v: 0.05728571428571172
  },
  {
    t: 1580814900,
    v: 0.05449999999999733
  },
  {
    t: 1580815800,
    v: 0.05171428571428294
  },
  {
    t: 1580816700,
    v: 0.04349999999999759
  },
  {
    t: 1580817600,
    v: 0.04407142857142584
  },
  {
    t: 1580818500,
    v: 0.047214285714281753
  },
  {
    t: 1580819400,
    v: 0.04678571428571031
  },
  {
    t: 1580820300,
    v: 0.04621428571428205
  },
  {
    t: 1580821200,
    v: 0.0437142857142823
  },
  {
    t: 1580822100,
    v: 0.04514285714285445
  },
  {
    t: 1580823000,
    v: 0.04999999999999716
  },
  {
    t: 1580823900,
    v: 0.04907142857142536
  },
  {
    t: 1580824800,
    v: 0.05107142857142577
  },
  {
    t: 1580825700,
    v: 0.0494285714285689
  },
  {
    t: 1580826600,
    v: 0.05199999999999757
  },
  {
    t: 1580827500,
    v: 0.05528571428571232
  },
  {
    t: 1580828400,
    v: 0.057857142857142004
  },
  {
    t: 1580829300,
    v: 0.06585714285714264
  },
  {
    t: 1580830200,
    v: 0.06585714285714264
  },
  {
    t: 1580831100,
    v: 0.06478571428571504
  },
  {
    t: 1580832000,
    v: 0.06650000000000082
  },
  {
    t: 1580832900,
    v: 0.07257142857142895
  },
  {
    t: 1580833800,
    v: 0.07185714285714287
  },
  {
    t: 1580834700,
    v: 0.07057142857142853
  },
  {
    t: 1580835600,
    v: 0.0662857142857151
  },
  {
    t: 1580836500,
    v: 0.06785714285714509
  },
  {
    t: 1580837400,
    v: 0.06621428571428822
  },
  {
    t: 1580838300,
    v: 0.06714285714285799
  },
  {
    t: 1580839200,
    v: 0.0642857142857147
  },
  {
    t: 1580840100,
    v: 0.06014285714285807
  },
  {
    t: 1580841000,
    v: 0.05878571428571482
  },
  {
    t: 1580841900,
    v: 0.052142857142856415
  },
  {
    t: 1580842800,
    v: 0.05278571428571358
  },
  {
    t: 1580843700,
    v: 0.05235714285714214
  },
  {
    t: 1580844600,
    v: 0.05157142857142816
  },
  {
    t: 1580845500,
    v: 0.04528571428571431
  },
  {
    t: 1580846400,
    v: 0.044071428571428886
  },
  {
    t: 1580847300,
    v: 0.04328571428571491
  },
  {
    t: 1580848200,
    v: 0.04478571428571395
  },
  {
    t: 1580849100,
    v: 0.04271428571428463
  },
  {
    t: 1580850000,
    v: 0.04292857142857035
  },
  {
    t: 1580850900,
    v: 0.04285714285714245
  },
  {
    t: 1580851800,
    v: 0.041571428571429134
  },
  {
    t: 1580852700,
    v: 0.041785714285714856
  },
  {
    t: 1580853600,
    v: 0.042642857142857746
  },
  {
    t: 1580854500,
    v: 0.0420714285714305
  },
  {
    t: 1580855400,
    v: 0.04064285714285936
  },
  {
    t: 1580856300,
    v: 0.039357142857145026
  },
  {
    t: 1580857200,
    v: 0.03892857142857358
  },
  {
    t: 1580858100,
    v: 0.03814285714285859
  },
  {
    t: 1580859000,
    v: 0.03921428571428619
  },
  {
    t: 1580859900,
    v: 0.038714285714285826
  },
  {
    t: 1580860800,
    v: 0.037285714285714686
  },
  {
    t: 1580861700,
    v: 0.042071428571428475
  },
  {
    t: 1580862600,
    v: 0.04235714285714311
  },
  {
    t: 1580863500,
    v: 0.04400000000000099
  },
  {
    t: 1580864400,
    v: 0.04650000000000075
  },
  {
    t: 1580865300,
    v: 0.04949999999999984
  },
  {
    t: 1580866200,
    v: 0.0490714285714284
  },
  {
    t: 1580867100,
    v: 0.05178571428571287
  },
  {
    t: 1580868000,
    v: 0.051857142857141776
  },
  {
    t: 1580868900,
    v: 0.051857142857141776
  },
  {
    t: 1580869800,
    v: 0.05149999999999925
  },
  {
    t: 1580870700,
    v: 0.0519999999999996
  },
  {
    t: 1580871600,
    v: 0.05378571428571429
  },
  {
    t: 1580872500,
    v: 0.054214285714286756
  },
  {
    t: 1580873400,
    v: 0.05385714285714422
  },
  {
    t: 1580874300,
    v: 0.048642857142858986
  },
  {
    t: 1580875200,
    v: 0.04792857142857291
  },
  {
    t: 1580876100,
    v: 0.04450000000000135
  },
  {
    t: 1580877000,
    v: 0.041357142857144424
  },
  {
    t: 1580877900,
    v: 0.037428571428573525
  },
  {
    t: 1580878800,
    v: 0.03464285714285913
  },
  {
    t: 1580879700,
    v: 0.030214285714287876
  },
  {
    t: 1580880600,
    v: 0.030000000000002153
  },
  {
    t: 1580881500,
    v: 0.030500000000001495
  },
  {
    t: 1580882400,
    v: 0.03157142857142909
  },
  {
    t: 1580883300,
    v: 0.03085714285714301
  },
  {
    t: 1580884200,
    v: 0.028714285714286802
  },
  {
    t: 1580885100,
    v: 0.02864285714285789
  },
  {
    t: 1580886000,
    v: 0.032642857142858715
  },
  {
    t: 1580886900,
    v: 0.03371428571428733
  },
  {
    t: 1580887800,
    v: 0.03378571428571523
  },
  {
    t: 1580888700,
    v: 0.03549999999999999
  },
  {
    t: 1580889600,
    v: 0.03849999999999909
  },
  {
    t: 1580890500,
    v: 0.04371428571428534
  },
  {
    t: 1580891400,
    v: 0.06321428571428507
  },
  {
    t: 1580892300,
    v: 0.06771428571428524
  },
  {
    t: 1580893200,
    v: 0.07278571428571366
  },
  {
    t: 1580894100,
    v: 0.07735714285714275
  },
  {
    t: 1580895000,
    v: 0.07835714285714346
  },
  {
    t: 1580895900,
    v: 0.07978571428571561
  },
  {
    t: 1580896800,
    v: 0.0815714285714293
  },
  {
    t: 1580897700,
    v: 0.08421428571428688
  },
  {
    t: 1580898600,
    v: 0.08050000000000068
  },
  {
    t: 1580899500,
    v: 0.08028571428571496
  },
  {
    t: 1580900400,
    v: 0.08092857142857314
  },
  {
    t: 1580901300,
    v: 0.08085714285714525
  },
  {
    t: 1580902200,
    v: 0.08935714285714523
  },
  {
    t: 1580903100,
    v: 0.0862857142857162
  },
  {
    t: 1580904000,
    v: 0.07128571428571563
  },
  {
    t: 1580904900,
    v: 0.06907142857143052
  },
  {
    t: 1580905800,
    v: 0.06514285714285961
  },
  {
    t: 1580906700,
    v: 0.06071428571428734
  },
  {
    t: 1580907600,
    v: 0.06000000000000126
  },
  {
    t: 1580908500,
    v: 0.06492857142857288
  },
  {
    t: 1580909400,
    v: 0.0652857142857154
  },
  {
    t: 1580910300,
    v: 0.06485714285714295
  },
  {
    t: 1580911200,
    v: 0.06499999999999975
  },
  {
    t: 1580912100,
    v: 0.06707142857142807
  },
  {
    t: 1580913000,
    v: 0.07042857142857072
  },
  {
    t: 1580913900,
    v: 0.07335714285714191
  },
  {
    t: 1580914800,
    v: 0.06592857142857156
  },
  {
    t: 1580915700,
    v: 0.0721428571428565
  },
  {
    t: 1580916600,
    v: 0.0712142857142857
  },
  {
    t: 1580917500,
    v: 0.07164285714285613
  },
  {
    t: 1580918400,
    v: 0.07414285714285589
  },
  {
    t: 1580919300,
    v: 0.07449999999999944
  },
  {
    t: 1580920200,
    v: 0.07435714285714264
  },
  {
    t: 1580921100,
    v: 0.07078571428571324
  },
  {
    t: 1580922000,
    v: 0.06771428571428524
  },
  {
    t: 1580922900,
    v: 0.06571428571428482
  },
  {
    t: 1580923800,
    v: 0.06442857142857049
  },
  {
    t: 1580924700,
    v: 0.06128571428571356
  },
  {
    t: 1580925600,
    v: 0.05685714285714129
  },
  {
    t: 1580926500,
    v: 0.05221428571428431
  },
  {
    t: 1580927400,
    v: 0.04892857142856956
  },
  {
    t: 1580928300,
    v: 0.040285714285712766
  },
  {
    t: 1580929200,
    v: 0.03685714285714121
  },
  {
    t: 1580930100,
    v: 0.0347857142857129
  },
  {
    t: 1580931000,
    v: 0.03257142857142676
  },
  {
    t: 1580931900,
    v: 0.03135714285714032
  },
  {
    t: 1580932800,
    v: 0.029214285714282084
  },
  {
    t: 1580933700,
    v: 0.02607142857142516
  },
  {
    t: 1580934600,
    v: 0.026857142857139138
  },
  {
    t: 1580935500,
    v: 0.026642857142854432
  },
  {
    t: 1580936400,
    v: 0.02749999999999732
  },
  {
    t: 1580937300,
    v: 0.027214285714282686
  },
  {
    t: 1580938200,
    v: 0.027928571428568767
  },
  {
    t: 1580939100,
    v: 0.02835714285714021
  },
  {
    t: 1580940000,
    v: 0.027928571428568767
  },
  {
    t: 1580940900,
    v: 0.032357142857140024
  },
  {
    t: 1580941800,
    v: 0.032999999999998204
  },
  {
    t: 1580942700,
    v: 0.03149999999999916
  },
  {
    t: 1580943600,
    v: 0.03049999999999845
  },
  {
    t: 1580944500,
    v: 0.030142857142856933
  },
  {
    t: 1580945400,
    v: 0.03035714285714367
  },
  {
    t: 1580946300,
    v: 0.03221428571428626
  },
  {
    t: 1580947200,
    v: 0.03500000000000065
  },
  {
    t: 1580948100,
    v: 0.03492857142857174
  },
  {
    t: 1580949000,
    v: 0.034571428571429204
  },
  {
    t: 1580949900,
    v: 0.038214285714286485
  },
  {
    t: 1580950800,
    v: 0.03757142857142932
  },
  {
    t: 1580951700,
    v: 0.04007142857142908
  },
  {
    t: 1580952600,
    v: 0.042642857142856726
  },
  {
    t: 1580953500,
    v: 0.03964285714285661
  },
  {
    t: 1580954400,
    v: 0.040642857142856315
  },
  {
    t: 1580955300,
    v: 0.04128571428571247
  },
  {
    t: 1580956200,
    v: 0.04071428571428421
  },
  {
    t: 1580957100,
    v: 0.041071428571426746
  },
  {
    t: 1580958000,
    v: 0.04121428571428457
  },
  {
    t: 1580958900,
    v: 0.03985714285714132
  },
  {
    t: 1580959800,
    v: 0.03807142857142663
  },
  {
    t: 1580960700,
    v: 0.03721428571428374
  },
  {
    t: 1580961600,
    v: 0.04021428571428386
  },
  {
    t: 1580962500,
    v: 0.036999999999999034
  },
  {
    t: 1580963400,
    v: 0.03728571428571265
  },
  {
    t: 1580964300,
    v: 0.03449999999999826
  },
  {
    t: 1580965200,
    v: 0.03249999999999886
  },
  {
    t: 1580966100,
    v: 0.03214285714285633
  },
  {
    t: 1580967000,
    v: 0.03021428571428483
  },
  {
    t: 1580967900,
    v: 0.033285714285713856
  },
  {
    t: 1580968800,
    v: 0.03578571428571361
  },
  {
    t: 1580969700,
    v: 0.036357142857141866
  },
  {
    t: 1580970600,
    v: 0.036928571428569104
  },
  {
    t: 1580971500,
    v: 0.03849999999999808
  },
  {
    t: 1580972400,
    v: 0.03928571428571205
  },
  {
    t: 1580973300,
    v: 0.03978571428571139
  },
  {
    t: 1580974200,
    v: 0.036857142857140195
  },
  {
    t: 1580975100,
    v: 0.039714285714283495
  },
  {
    t: 1580976000,
    v: 0.04164285714285602
  },
  {
    t: 1580976900,
    v: 0.043714285714284325
  },
  {
    t: 1580977800,
    v: 0.0434999999999986
  },
  {
    t: 1580978700,
    v: 0.04442857142856939
  },
  {
    t: 1580979600,
    v: 0.046928571428568135
  },
  {
    t: 1580980500,
    v: 0.049142857142853255
  },
  {
    t: 1580981400,
    v: 0.049142857142853255
  },
  {
    t: 1580982300,
    v: 0.04928571428571006
  },
  {
    t: 1580983200,
    v: 0.0515714285714241
  },
  {
    t: 1580984100,
    v: 0.05178571428570982
  },
  {
    t: 1580985000,
    v: 0.050285714285709764
  },
  {
    t: 1580985900,
    v: 0.052285714285710175
  },
  {
    t: 1580986800,
    v: 0.0534999999999956
  },
  {
    t: 1580987700,
    v: 0.05142857142856627
  },
  {
    t: 1580988600,
    v: 0.05035714285713766
  },
  {
    t: 1580989500,
    v: 0.05064285714285229
  },
  {
    t: 1580990400,
    v: 0.050499999999995486
  },
  {
    t: 1580991300,
    v: 0.05035714285713969
  },
  {
    t: 1580992200,
    v: 0.04785714285714095
  },
  {
    t: 1580993100,
    v: 0.04549999999999902
  },
  {
    t: 1580994000,
    v: 0.04385714285714215
  },
  {
    t: 1580994900,
    v: 0.04600000000000039
  },
  {
    t: 1580995800,
    v: 0.04328571428571491
  },
  {
    t: 1580996700,
    v: 0.04457142857142925
  },
  {
    t: 1580997600,
    v: 0.045857142857144595
  },
  {
    t: 1580998500,
    v: 0.044500000000002364
  },
  {
    t: 1580999400,
    v: 0.0477857142857161
  },
  {
    t: 1581000300,
    v: 0.05150000000000229
  },
  {
    t: 1581001200,
    v: 0.05228571428571627
  },
  {
    t: 1581002100,
    v: 0.05228571428571627
  },
  {
    t: 1581003000,
    v: 0.054285714285715665
  },
  {
    t: 1581003900,
    v: 0.055357142857143264
  },
  {
    t: 1581004800,
    v: 0.05728571428571375
  },
  {
    t: 1581005700,
    v: 0.05585714285714159
  },
  {
    t: 1581006600,
    v: 0.05549999999999906
  },
  {
    t: 1581007500,
    v: 0.053857142857141174
  },
  {
    t: 1581008400,
    v: 0.05542857142857116
  },
  {
    t: 1581009300,
    v: 0.054214285714285736
  },
  {
    t: 1581010200,
    v: 0.0520714285714275
  },
  {
    t: 1581011100,
    v: 0.05292857142857039
  },
  {
    t: 1581012000,
    v: 0.04807142857142769
  },
  {
    t: 1581012900,
    v: 0.04328571428571288
  },
  {
    t: 1581013800,
    v: 0.04092857142856993
  },
  {
    t: 1581014700,
    v: 0.0387142857142838
  },
  {
    t: 1581015600,
    v: 0.03642857142856976
  },
  {
    t: 1581016500,
    v: 0.034428571428570365
  },
  {
    t: 1581017400,
    v: 0.031928571428570605
  },
  {
    t: 1581018300,
    v: 0.030499999999999465
  },
  {
    t: 1581019200,
    v: 0.030142857142856933
  },
  {
    t: 1581020100,
    v: 0.028714285714285786
  },
  {
    t: 1581021000,
    v: 0.02678571428571327
  },
  {
    t: 1581021900,
    v: 0.025857142857142485
  },
  {
    t: 1581022800,
    v: 0.026000000000000308
  },
  {
    t: 1581023700,
    v: 0.023928571428570983
  },
  {
    t: 1581024600,
    v: 0.023571428571428448
  },
  {
    t: 1581025500,
    v: 0.023285714285713816
  },
  {
    t: 1581026400,
    v: 0.024785714285713873
  },
  {
    t: 1581027300,
    v: 0.025642857142857776
  },
  {
    t: 1581028200,
    v: 0.024785714285714886
  },
  {
    t: 1581029100,
    v: 0.023928571428571997
  },
  {
    t: 1581030000,
    v: 0.02500000000000061
  },
  {
    t: 1581030900,
    v: 0.024857142857142783
  },
  {
    t: 1581031800,
    v: 0.025071428571428505
  },
  {
    t: 1581032700,
    v: 0.024928571428570682
  },
  {
    t: 1581033600,
    v: 0.025142857142857418
  },
  {
    t: 1581034500,
    v: 0.02664285714285646
  },
  {
    t: 1581035400,
    v: 0.03292857142857031
  },
  {
    t: 1581036300,
    v: 0.03707142857142794
  },
  {
    t: 1581037200,
    v: 0.03914285714285626
  },
  {
    t: 1581038100,
    v: 0.040428571428571605
  },
  {
    t: 1581039000,
    v: 0.04107142857142877
  },
  {
    t: 1581039900,
    v: 0.04085714285714204
  },
  {
    t: 1581040800,
    v: 0.04207142857142746
  },
  {
    t: 1581041700,
    v: 0.04385714285714114
  },
  {
    t: 1581042600,
    v: 0.04242857142856999
  },
  {
    t: 1581043500,
    v: 0.04364285714285643
  },
  {
    t: 1581044400,
    v: 0.043357142857141796
  },
  {
    t: 1581045300,
    v: 0.043428571428570706
  },
  {
    t: 1581046200,
    v: 0.042785714285712526
  },
  {
    t: 1581047100,
    v: 0.039785714285713425
  },
  {
    t: 1581048000,
    v: 0.033285714285713856
  },
  {
    t: 1581048900,
    v: 0.02971428571428549
  },
  {
    t: 1581049800,
    v: 0.02714285714285682
  },
  {
    t: 1581050700,
    v: 0.025642857142855746
  },
  {
    t: 1581051600,
    v: 0.02414285714285569
  },
  {
    t: 1581052500,
    v: 0.023357142857141713
  },
  {
    t: 1581053400,
    v: 0.022071428571427378
  },
  {
    t: 1581054300,
    v: 0.021571428571428037
  },
  {
    t: 1581055200,
    v: 0.022499999999998823
  },
  {
    t: 1581056100,
    v: 0.02592857142857038
  },
  {
    t: 1581057000,
    v: 0.02607142857142719
  },
  {
    t: 1581057900,
    v: 0.026142857142856104
  },
  {
    t: 1581058800,
    v: 0.028357142857141224
  },
  {
    t: 1581059700,
    v: 0.032928571428569295
  },
  {
    t: 1581060600,
    v: 0.03399999999999791
  },
  {
    t: 1581061500,
    v: 0.03449999999999826
  },
  {
    t: 1581062400,
    v: 0.038357142857141265
  },
  {
    t: 1581063300,
    v: 0.040071428571427044
  },
  {
    t: 1581064200,
    v: 0.04235714285714108
  },
  {
    t: 1581065100,
    v: 0.04385714285714114
  },
  {
    t: 1581066000,
    v: 0.046214285714284084
  },
  {
    t: 1581066900,
    v: 0.04949999999999883
  },
  {
    t: 1581067800,
    v: 0.05085714285714208
  },
  {
    t: 1581068700,
    v: 0.04971428571428455
  },
  {
    t: 1581069600,
    v: 0.055928571428571515
  },
  {
    t: 1581070500,
    v: 0.06171428571428501
  },
  {
    t: 1581071400,
    v: 0.060928571428571034
  },
  {
    t: 1581072300,
    v: 0.05778571428571411
  },
  {
    t: 1581073200,
    v: 0.060428571428571685
  },
  {
    t: 1581074100,
    v: 0.06135714285714248
  },
  {
    t: 1581075000,
    v: 0.05842857142857026
  },
  {
    t: 1581075900,
    v: 0.057214285714284836
  },
  {
    t: 1581076800,
    v: 0.055928571428571515
  },
  {
    t: 1581077700,
    v: 0.05621428571428514
  },
  {
    t: 1581078600,
    v: 0.05571428571428579
  },
  {
    t: 1581079500,
    v: 0.05285714285714249
  },
  {
    t: 1581080400,
    v: 0.05350000000000067
  },
  {
    t: 1581081300,
    v: 0.05435714285714356
  },
  {
    t: 1581082200,
    v: 0.06428571428571368
  },
  {
    t: 1581083100,
    v: 0.06171428571428602
  },
  {
    t: 1581084000,
    v: 0.0643571428571436
  },
  {
    t: 1581084900,
    v: 0.06885714285714276
  },
  {
    t: 1581085800,
    v: 0.0769999999999992
  },
  {
    t: 1581086700,
    v: 0.08049999999999866
  },
  {
    t: 1581087600,
    v: 0.08521428571428556
  },
  {
    t: 1581088500,
    v: 0.08814285714285777
  },
  {
    t: 1581089400,
    v: 0.09014285714285718
  },
  {
    t: 1581090300,
    v: 0.09635714285714414
  },
  {
    t: 1581091200,
    v: 0.09850000000000136
  },
  {
    t: 1581092100,
    v: 0.10221428571428655
  },
  {
    t: 1581093000,
    v: 0.10285714285714269
  },
  {
    t: 1581093900,
    v: 0.10014285714285721
  },
  {
    t: 1581094800,
    v: 0.08542857142857128
  },
  {
    t: 1581095700,
    v: 0.0827857142857137
  },
  {
    t: 1581096600,
    v: 0.07978571428571359
  },
  {
    t: 1581097500,
    v: 0.07457142857142836
  },
  {
    t: 1581098400,
    v: 0.06271428571428572
  },
  {
    t: 1581099300,
    v: 0.05700000000000115
  },
  {
    t: 1581100200,
    v: 0.054999999999999716
  },
  {
    t: 1581101100,
    v: 0.052714285714284666
  },
  {
    t: 1581102000,
    v: 0.050499999999999545
  },
  {
    t: 1581102900,
    v: 0.0434999999999986
  },
  {
    t: 1581103800,
    v: 0.042285714285713184
  },
  {
    t: 1581104700,
    v: 0.03785714285714192
  },
  {
    t: 1581105600,
    v: 0.03592857142857042
  },
  {
    t: 1581106500,
    v: 0.034714285714285
  },
  {
    t: 1581107400,
    v: 0.03257142857142777
  },
  {
    t: 1581108300,
    v: 0.03207142857142843
  },
  {
    t: 1581109200,
    v: 0.03085714285714301
  },
  {
    t: 1581110100,
    v: 0.031142857142857645
  },
  {
    t: 1581111000,
    v: 0.03214285714285735
  },
  {
    t: 1581285600,
    v: 0.036714285714285415
  },
  {
    t: 1581286500,
    v: 0.032857142857143425
  },
  {
    t: 1581287400,
    v: 0.03357142857142951
  },
  {
    t: 1581288300,
    v: 0.03378571428571421
  },
  {
    t: 1581289200,
    v: 0.03685714285714324
  },
  {
    t: 1581290100,
    v: 0.03799999999999975
  },
  {
    t: 1581291000,
    v: 0.0462142857142851
  },
  {
    t: 1581291900,
    v: 0.04828571428571341
  },
  {
    t: 1581292800,
    v: 0.05057142857142744
  },
  {
    t: 1581293700,
    v: 0.054714285714285084
  },
  {
    t: 1581294600,
    v: 0.0559285714285705
  },
  {
    t: 1581295500,
    v: 0.06107142857142784
  },
  {
    t: 1581296400,
    v: 0.06242857142857109
  },
  {
    t: 1581297300,
    v: 0.06371428571428542
  },
  {
    t: 1581298200,
    v: 0.06707142857142807
  },
  {
    t: 1581299100,
    v: 0.06942857142857102
  },
  {
    t: 1581300000,
    v: 0.0711428571428568
  },
  {
    t: 1581300900,
    v: 0.07128571428571462
  },
  {
    t: 1581301800,
    v: 0.06985714285714348
  },
  {
    t: 1581302700,
    v: 0.06757142857142943
  },
  {
    t: 1581303600,
    v: 0.058714285714286926
  },
  {
    t: 1581304500,
    v: 0.05728571428571578
  },
  {
    t: 1581305400,
    v: 0.05550000000000109
  },
  {
    t: 1581306300,
    v: 0.0536428571428585
  },
  {
    t: 1581307200,
    v: 0.05278571428571561
  },
  {
    t: 1581308100,
    v: 0.048000000000000806
  },
  {
    t: 1581309000,
    v: 0.04685714285714328
  },
  {
    t: 1581309900,
    v: 0.046928571428572194
  },
  {
    t: 1581310800,
    v: 0.03971428571428654
  },
  {
    t: 1581311700,
    v: 0.039071428571429374
  },
  {
    t: 1581312600,
    v: 0.036500000000000705
  },
  {
    t: 1581313500,
    v: 0.03500000000000065
  },
  {
    t: 1581314400,
    v: 0.034214285714286675
  },
  {
    t: 1581315300,
    v: 0.0346428571428571
  },
  {
    t: 1581316200,
    v: 0.03478571428571391
  },
  {
    t: 1581317100,
    v: 0.033071428571428134
  },
  {
    t: 1581318000,
    v: 0.036357142857141866
  },
  {
    t: 1581318900,
    v: 0.036071428571427235
  },
  {
    t: 1581319800,
    v: 0.037499999999998375
  },
  {
    t: 1581320700,
    v: 0.038857142857140606
  },
  {
    t: 1581321600,
    v: 0.041714285714282894
  },
  {
    t: 1581322500,
    v: 0.04407142857142483
  },
  {
    t: 1581323400,
    v: 0.04592857142856742
  },
  {
    t: 1581324300,
    v: 0.047499999999996385
  },
  {
    t: 1581325200,
    v: 0.04849999999999507
  },
  {
    t: 1581326100,
    v: 0.04928571428570905
  },
  {
    t: 1581327000,
    v: 0.048142857142851526
  },
  {
    t: 1581327900,
    v: 0.04799999999999573
  },
  {
    t: 1581328800,
    v: 0.049714285714282526
  },
  {
    t: 1581329700,
    v: 0.049857142857140345
  },
  {
    t: 1581330600,
    v: 0.05171428571428395
  },
  {
    t: 1581331500,
    v: 0.05185714285714076
  },
  {
    t: 1581332400,
    v: 0.05064285714285534
  },
  {
    t: 1581333300,
    v: 0.050499999999999545
  },
  {
    t: 1581334200,
    v: 0.047142857142856896
  },
  {
    t: 1581335100,
    v: 0.043571428571429545
  },
  {
    t: 1581336000,
    v: 0.04364285714285846
  },
  {
    t: 1581336900,
    v: 0.04135714285714341
  },
  {
    t: 1581337800,
    v: 0.039642857142858645
  },
  {
    t: 1581338700,
    v: 0.039714285714287555
  },
  {
    t: 1581339600,
    v: 0.040571428571430444
  },
  {
    t: 1581340500,
    v: 0.04157142857143015
  },
  {
    t: 1581341400,
    v: 0.040928571428571967
  },
  {
    t: 1581342300,
    v: 0.04164285714285804
  },
  {
    t: 1581343200,
    v: 0.040428571428572625
  },
  {
    t: 1581344100,
    v: 0.03942857142857292
  },
  {
    t: 1581345000,
    v: 0.04292857142857238
  },
  {
    t: 1581345900,
    v: 0.04478571428571497
  },
  {
    t: 1581346800,
    v: 0.04685714285714328
  },
  {
    t: 1581347700,
    v: 0.04792857142857189
  },
  {
    t: 1581348600,
    v: 0.048071428571429715
  },
  {
    t: 1581349500,
    v: 0.04914285714285934
  },
  {
    t: 1581350400,
    v: 0.050142857142860056
  },
  {
    t: 1581351300,
    v: 0.05007142857143216
  },
  {
    t: 1581352200,
    v: 0.049142857142861374
  },
  {
    t: 1581353100,
    v: 0.04728571428571878
  },
  {
    t: 1581354000,
    v: 0.046928571428577266
  },
  {
    t: 1581354900,
    v: 0.04764285714286233
  },
  {
    t: 1581355800,
    v: 0.04778571428571914
  },
  {
    t: 1581356700,
    v: 0.04735714285714871
  },
  {
    t: 1581357600,
    v: 0.04385714285714824
  },
  {
    t: 1581358500,
    v: 0.04178571428571993
  },
  {
    t: 1581359400,
    v: 0.03950000000000488
  },
  {
    t: 1581360300,
    v: 0.037571428571432364
  },
  {
    t: 1581361200,
    v: 0.03585714285714557
  },
  {
    t: 1581362100,
    v: 0.034000000000001966
  },
  {
    t: 1581363000,
    v: 0.03250000000000191
  },
  {
    t: 1581363900,
    v: 0.03242857142857198
  },
  {
    t: 1581364800,
    v: 0.03242857142857097
  },
  {
    t: 1581365700,
    v: 0.03378571428571421
  },
  {
    t: 1581366600,
    v: 0.034142857142856746
  },
  {
    t: 1581367500,
    v: 0.03300000000000024
  },
  {
    t: 1581368400,
    v: 0.028571428571428976
  },
  {
    t: 1581369300,
    v: 0.02792857142857181
  },
  {
    t: 1581370200,
    v: 0.026714285714286388
  },
  {
    t: 1581371100,
    v: 0.025785714285714585
  },
  {
    t: 1581372000,
    v: 0.02728571428571566
  },
  {
    t: 1581372900,
    v: 0.027214285714287762
  },
  {
    t: 1581373800,
    v: 0.02700000000000204
  },
  {
    t: 1581374700,
    v: 0.02778571428571703
  },
  {
    t: 1581375600,
    v: 0.02892857142857354
  },
  {
    t: 1581376500,
    v: 0.028357142857145284
  },
  {
    t: 1581377400,
    v: 0.02764285714286022
  },
  {
    t: 1581378300,
    v: 0.025785714285716615
  },
  {
    t: 1581379200,
    v: 0.02521428571428836
  },
  {
    t: 1581380100,
    v: 0.024857142857144816
  },
  {
    t: 1581381000,
    v: 0.025000000000002638
  },
  {
    t: 1581381900,
    v: 0.02800000000000174
  },
  {
    t: 1581382800,
    v: 0.02885714285714564
  },
  {
    t: 1581383700,
    v: 0.030000000000002153
  },
  {
    t: 1581384600,
    v: 0.028500000000002097
  },
  {
    t: 1581385500,
    v: 0.029428571428573896
  },
  {
    t: 1581386400,
    v: 0.02921428571428919
  },
  {
    t: 1581387300,
    v: 0.03092857142857497
  },
  {
    t: 1581388200,
    v: 0.030428571428575628
  },
  {
    t: 1581389100,
    v: 0.03150000000000525
  },
  {
    t: 1581390000,
    v: 0.03157142857143315
  },
  {
    t: 1581390900,
    v: 0.03235714285714814
  },
  {
    t: 1581391800,
    v: 0.03235714285714713
  },
  {
    t: 1581392700,
    v: 0.03207142857143249
  },
  {
    t: 1581393600,
    v: 0.030571428571432437
  },
  {
    t: 1581394500,
    v: 0.026928571428575156
  },
  {
    t: 1581395400,
    v: 0.026357142857145886
  },
  {
    t: 1581396300,
    v: 0.02421428571428866
  },
  {
    t: 1581397200,
    v: 0.023857142857145113
  },
  {
    t: 1581398100,
    v: 0.022142857142859334
  },
  {
    t: 1581399000,
    v: 0.022785714285715488
  },
  {
    t: 1581399900,
    v: 0.020928571428571883
  },
  {
    t: 1581400800,
    v: 0.02178571428571376
  },
  {
    t: 1581401700,
    v: 0.020857142857141957
  },
  {
    t: 1581402600,
    v: 0.020642857142856234
  },
  {
    t: 1581403500,
    v: 0.020642857142855218
  },
  {
    t: 1581404400,
    v: 0.02049999999999841
  },
  {
    t: 1581405300,
    v: 0.0222857142857131
  },
  {
    t: 1581406200,
    v: 0.02464285714285503
  },
  {
    t: 1581407100,
    v: 0.02757142857142623
  },
  {
    t: 1581408000,
    v: 0.029571428571426646
  },
  {
    t: 1581408900,
    v: 0.03149999999999916
  },
  {
    t: 1581409800,
    v: 0.03300000000000024
  },
  {
    t: 1581410700,
    v: 0.03435714285714348
  },
  {
    t: 1581411600,
    v: 0.0353571428571442
  },
  {
    t: 1581412500,
    v: 0.03585714285714455
  },
  {
    t: 1581413400,
    v: 0.03678571428571636
  },
  {
    t: 1581414300,
    v: 0.03678571428571636
  },
  {
    t: 1581415200,
    v: 0.03842857142857424
  },
  {
    t: 1581416100,
    v: 0.038357142857146344
  },
  {
    t: 1581417000,
    v: 0.038714285714289885
  },
  {
    t: 1581417900,
    v: 0.03728571428571773
  },
  {
    t: 1581418800,
    v: 0.036142857142861216
  },
  {
    t: 1581419700,
    v: 0.0349285714285758
  },
  {
    t: 1581420600,
    v: 0.03314285714286212
  },
  {
    t: 1581421500,
    v: 0.03221428571429032
  },
  {
    t: 1581422400,
    v: 0.030857142857147073
  },
  {
    t: 1581423300,
    v: 0.029857142857146357
  },
  {
    t: 1581424200,
    v: 0.02850000000000311
  },
  {
    t: 1581425100,
    v: 0.029214285714288173
  },
  {
    t: 1581426000,
    v: 0.028285714285716374
  },
  {
    t: 1581426900,
    v: 0.031714285714286916
  },
  {
    t: 1581427800,
    v: 0.03378571428571523
  },
  {
    t: 1581428700,
    v: 0.03428571428571457
  },
  {
    t: 1581429600,
    v: 0.035571428571427886
  },
  {
    t: 1581430500,
    v: 0.03700000000000005
  },
  {
    t: 1581431400,
    v: 0.03964285714285661
  },
  {
    t: 1581432300,
    v: 0.04028571428571378
  },
  {
    t: 1581433200,
    v: 0.044214285714284686
  },
  {
    t: 1581434100,
    v: 0.046785714285713355
  },
  {
    t: 1581435000,
    v: 0.051357142857142435
  },
  {
    t: 1581435900,
    v: 0.05371428571428538
  },
  {
    t: 1581436800,
    v: 0.056714285714285495
  },
  {
    t: 1581437700,
    v: 0.05649999999999977
  },
  {
    t: 1581438600,
    v: 0.06185714285714283
  },
  {
    t: 1581439500,
    v: 0.060357142857142776
  },
  {
    t: 1581440400,
    v: 0.057642857142857294
  },
  {
    t: 1581441300,
    v: 0.05735714285714266
  },
  {
    t: 1581442200,
    v: 0.055571428571427973
  },
  {
    t: 1581443100,
    v: 0.05407142857142792
  },
  {
    t: 1581444000,
    v: 0.05199999999999859
  },
  {
    t: 1581444900,
    v: 0.051571428571427144
  },
  {
    t: 1581445800,
    v: 0.04878571428571275
  },
  {
    t: 1581446700,
    v: 0.047071428571426974
  },
  {
    t: 1581447600,
    v: 0.04428571428571156
  },
  {
    t: 1581448500,
    v: 0.043999999999997944
  },
  {
    t: 1581449400,
    v: 0.041642857142855
  },
  {
    t: 1581450300,
    v: 0.041357142857140365
  },
  {
    t: 1581451200,
    v: 0.03528571428571224
  },
  {
    t: 1581452100,
    v: 0.03335714285714074
  },
  {
    t: 1581453000,
    v: 0.03299999999999719
  },
  {
    t: 1581453900,
    v: 0.03278571428571147
  },
  {
    t: 1581454800,
    v: 0.03185714285714068
  },
  {
    t: 1581455700,
    v: 0.03085714285714098
  },
  {
    t: 1581456600,
    v: 0.029071428571427305
  },
  {
    t: 1581457500,
    v: 0.027357142857141525
  },
  {
    t: 1581458400,
    v: 0.028428571428570137
  },
  {
    t: 1581459300,
    v: 0.02949999999999875
  },
  {
    t: 1581460200,
    v: 0.02807142857142862
  },
  {
    t: 1581461100,
    v: 0.025714285714285672
  },
  {
    t: 1581462000,
    v: 0.025571428571428863
  },
  {
    t: 1581462900,
    v: 0.024642857142858077
  },
  {
    t: 1581463800,
    v: 0.024428571428572354
  },
  {
    t: 1581464700,
    v: 0.024285714285715545
  },
  {
    t: 1581465600,
    v: 0.025714285714287705
  },
  {
    t: 1581466500,
    v: 0.028071428571430652
  },
  {
    t: 1581467400,
    v: 0.02907142857143035
  },
  {
    t: 1581468300,
    v: 0.03328571428571588
  },
  {
    t: 1581469200,
    v: 0.03550000000000202
  },
  {
    t: 1581470100,
    v: 0.03821428571428852
  },
  {
    t: 1581471000,
    v: 0.03700000000000208
  },
  {
    t: 1581471900,
    v: 0.03542857142857413
  },
  {
    t: 1581472800,
    v: 0.03550000000000202
  },
  {
    t: 1581473700,
    v: 0.03692857142857215
  },
  {
    t: 1581474600,
    v: 0.036785714285714324
  },
  {
    t: 1581475500,
    v: 0.0365714285714286
  },
  {
    t: 1581476400,
    v: 0.03749999999999939
  },
  {
    t: 1581477300,
    v: 0.03635714285714288
  },
  {
    t: 1581478200,
    v: 0.034142857142856746
  },
  {
    t: 1581479100,
    v: 0.03150000000000018
  },
  {
    t: 1581480000,
    v: 0.031000000000000836
  },
  {
    t: 1581480900,
    v: 0.027785714285715
  },
  {
    t: 1581481800,
    v: 0.02571428571428669
  },
  {
    t: 1581482700,
    v: 0.023357142857142726
  },
  {
    t: 1581483600,
    v: 0.021928571428571582
  },
  {
    t: 1581484500,
    v: 0.020714285714285147
  },
  {
    t: 1581485400,
    v: 0.020999999999998766
  },
  {
    t: 1581486300,
    v: 0.019857142857142258
  },
  {
    t: 1581487200,
    v: 0.01921428571428509
  },
  {
    t: 1581488100,
    v: 0.020499999999999425
  },
  {
    t: 1581489000,
    v: 0.018999999999999368
  },
  {
    t: 1581489900,
    v: 0.019142857142856178
  },
  {
    t: 1581490800,
    v: 0.019928571428570154
  },
  {
    t: 1581491700,
    v: 0.021999999999998465
  },
  {
    t: 1581492600,
    v: 0.02292857142856925
  },
  {
    t: 1581493500,
    v: 0.02449999999999822
  },
  {
    t: 1581494400,
    v: 0.026214285714284
  },
  {
    t: 1581495300,
    v: 0.02949999999999875
  },
  {
    t: 1581496200,
    v: 0.04299999999999825
  },
  {
    t: 1581497100,
    v: 0.044999999999997646
  },
  {
    t: 1581498000,
    v: 0.048142857142855586
  },
  {
    t: 1581498900,
    v: 0.05185714285714076
  },
  {
    t: 1581499800,
    v: 0.05335714285714082
  },
  {
    t: 1581500700,
    v: 0.052642857142854736
  },
  {
    t: 1581501600,
    v: 0.055214285714284425
  },
  {
    t: 1581502500,
    v: 0.05907142857142743
  },
  {
    t: 1581503400,
    v: 0.06099999999999893
  },
  {
    t: 1581504300,
    v: 0.06021428571428495
  },
  {
    t: 1581505200,
    v: 0.05942857142857098
  },
  {
    t: 1581506100,
    v: 0.05849999999999917
  },
  {
    t: 1581507000,
    v: 0.05942857142856996
  },
  {
    t: 1581507900,
    v: 0.0569999999999981
  },
  {
    t: 1581508800,
    v: 0.044571428571427214
  },
  {
    t: 1581509700,
    v: 0.043357142857141796
  },
  {
    t: 1581510600,
    v: 0.03928571428571307
  },
  {
    t: 1581511500,
    v: 0.03607142857142825
  },
  {
    t: 1581512400,
    v: 0.03542857142857108
  },
  {
    t: 1581513300,
    v: 0.035571428571428906
  },
  {
    t: 1581514200,
    v: 0.03485714285714282
  },
  {
    t: 1581515100,
    v: 0.03299999999999922
  },
  {
    t: 1581516000,
    v: 0.031928571428570605
  },
  {
    t: 1581516900,
    v: 0.030714285714285187
  },
  {
    t: 1581517800,
    v: 0.03249999999999886
  },
  {
    t: 1581518700,
    v: 0.033499999999998566
  },
  {
    t: 1581519600,
    v: 0.033285714285713856
  },
  {
    t: 1581520500,
    v: 0.033642857142857405
  },
  {
    t: 1581521400,
    v: 0.03500000000000065
  },
  {
    t: 1581522300,
    v: 0.03585714285714455
  },
  {
    t: 1581523200,
    v: 0.0392142857142872
  },
  {
    t: 1581524100,
    v: 0.041000000000000876
  },
  {
    t: 1581525000,
    v: 0.04250000000000093
  },
  {
    t: 1581525900,
    v: 0.04228571428571521
  },
  {
    t: 1581526800,
    v: 0.04107142857142979
  },
  {
    t: 1581527700,
    v: 0.04071428571428726
  },
  {
    t: 1581528600,
    v: 0.03928571428571611
  },
  {
    t: 1581529500,
    v: 0.03857142857143003
  },
  {
    t: 1581530400,
    v: 0.0372857142857157
  },
  {
    t: 1581531300,
    v: 0.0372142857142878
  },
  {
    t: 1581532200,
    v: 0.0353571428571442
  },
  {
    t: 1581533100,
    v: 0.03485714285714384
  },
  {
    t: 1581534000,
    v: 0.033714285714286314
  },
  {
    t: 1581534900,
    v: 0.0346428571428571
  },
  {
    t: 1581535800,
    v: 0.030999999999999823
  },
  {
    t: 1581536700,
    v: 0.0288571428571426
  },
  {
    t: 1581537600,
    v: 0.027285714285714642
  },
  {
    t: 1581538500,
    v: 0.02635714285714284
  },
  {
    t: 1581539400,
    v: 0.025928571428571395
  },
  {
    t: 1581540300,
    v: 0.024642857142858077
  },
  {
    t: 1581541200,
    v: 0.024285714285715545
  },
  {
    t: 1581542100,
    v: 0.024928571428572712
  },
  {
    t: 1581543000,
    v: 0.024357142857144458
  },
  {
    t: 1581543900,
    v: 0.022857142857143384
  },
  {
    t: 1581544800,
    v: 0.024214285714285615
  },
  {
    t: 1581545700,
    v: 0.024071428571428806
  },
  {
    t: 1581546600,
    v: 0.023357142857143742
  },
  {
    t: 1581547500,
    v: 0.020785714285715073
  },
  {
    t: 1581548400,
    v: 0.021000000000000796
  },
  {
    t: 1581549300,
    v: 0.021142857142858622
  },
  {
    t: 1581550200,
    v: 0.02042857142857254
  },
  {
    t: 1581551100,
    v: 0.0392857142857151
  },
  {
    t: 1581552000,
    v: 0.043857142857143164
  },
  {
    t: 1581552900,
    v: 0.046928571428571174
  },
  {
    t: 1581553800,
    v: 0.04957142857142876
  },
  {
    t: 1581554700,
    v: 0.05171428571428598
  },
  {
    t: 1581555600,
    v: 0.054571428571429284
  },
  {
    t: 1581556500,
    v: 0.05778571428571512
  },
  {
    t: 1581557400,
    v: 0.05742857142857259
  },
  {
    t: 1581558300,
    v: 0.058785714285715836
  },
  {
    t: 1581559200,
    v: 0.059928571428572344
  },
  {
    t: 1581560100,
    v: 0.06114285714285777
  },
  {
    t: 1581561000,
    v: 0.06192857142857276
  },
  {
    t: 1581561900,
    v: 0.06400000000000006
  },
  {
    t: 1581562800,
    v: 0.06407142857142796
  },
  {
    t: 1581563700,
    v: 0.04757142857142834
  },
  {
    t: 1581564600,
    v: 0.04414285714285678
  },
  {
    t: 1581565500,
    v: 0.042357142857142094
  },
  {
    t: 1581566400,
    v: 0.04135714285714138
  },
  {
    t: 1581567300,
    v: 0.039214285714284154
  },
  {
    t: 1581568200,
    v: 0.036999999999999034
  },
  {
    t: 1581569100,
    v: 0.03435714285714247
  },
  {
    t: 1581570000,
    v: 0.03399999999999993
  },
  {
    t: 1581570900,
    v: 0.033285714285713856
  },
  {
    t: 1581571800,
    v: 0.03357142857142849
  },
  {
    t: 1581572700,
    v: 0.03378571428571523
  },
  {
    t: 1581573600,
    v: 0.0346428571428571
  },
  {
    t: 1581574500,
    v: 0.03492857142857174
  },
  {
    t: 1581575400,
    v: 0.036428571428571796
  },
  {
    t: 1581576300,
    v: 0.034214285714285655
  },
  {
    t: 1581577200,
    v: 0.0365714285714286
  },
  {
    t: 1581578100,
    v: 0.03964285714285763
  },
  {
    t: 1581579000,
    v: 0.040714285714285224
  },
  {
    t: 1581579900,
    v: 0.044857142857142866
  },
  {
    t: 1581580800,
    v: 0.047142857142856896
  },
  {
    t: 1581581700,
    v: 0.04964285714285767
  },
  {
    t: 1581582600,
    v: 0.051142857142857726
  },
  {
    t: 1581583500,
    v: 0.051928571428571706
  },
  {
    t: 1581584400,
    v: 0.052857142857143505
  },
  {
    t: 1581585300,
    v: 0.054214285714285736
  },
  {
    t: 1581586200,
    v: 0.054214285714286756
  },
  {
    t: 1581587100,
    v: 0.05528571428571638
  },
  {
    t: 1581588000,
    v: 0.06050000000000263
  },
  {
    t: 1581588900,
    v: 0.06592857142857358
  },
  {
    t: 1581589800,
    v: 0.0651428571428586
  },
  {
    t: 1581590700,
    v: 0.06171428571428704
  },
  {
    t: 1581591600,
    v: 0.06028571428571589
  },
  {
    t: 1581592500,
    v: 0.058714285714286926
  },
  {
    t: 1581593400,
    v: 0.05714285714285795
  },
  {
    t: 1581594300,
    v: 0.05685714285714231
  },
  {
    t: 1581595200,
    v: 0.05621428571428514
  },
  {
    t: 1581596100,
    v: 0.05621428571428514
  },
  {
    t: 1581597000,
    v: 0.05421428571428472
  },
  {
    t: 1581597900,
    v: 0.05421428571428371
  },
  {
    t: 1581598800,
    v: 0.05414285714285479
  },
  {
    t: 1581599700,
    v: 0.053642857142853426
  },
  {
    t: 1581600600,
    v: 0.04878571428571072
  },
  {
    t: 1581601500,
    v: 0.04714285714285487
  },
  {
    t: 1581602400,
    v: 0.04964285714285564
  },
  {
    t: 1581603300,
    v: 0.04978571428571245
  },
  {
    t: 1581604200,
    v: 0.05199999999999859
  },
  {
    t: 1581605100,
    v: 0.057999999999998816
  },
  {
    t: 1581606000,
    v: 0.06264285714285579
  },
  {
    t: 1581606900,
    v: 0.061499999999999284
  },
  {
    t: 1581607800,
    v: 0.06321428571428404
  },
  {
    t: 1581608700,
    v: 0.06971428571428362
  },
  {
    t: 1581609600,
    v: 0.07092857142856904
  },
  {
    t: 1581610500,
    v: 0.07249999999999902
  },
  {
    t: 1581611400,
    v: 0.07292857142857047
  },
  {
    t: 1581612300,
    v: 0.0712142857142857
  },
  {
    t: 1581613200,
    v: 0.06964285714285674
  },
  {
    t: 1581614100,
    v: 0.06685714285714235
  },
  {
    t: 1581615000,
    v: 0.06307142857142724
  },
  {
    t: 1581615900,
    v: 0.06492857142856982
  },
  {
    t: 1581616800,
    v: 0.06264285714285579
  },
  {
    t: 1581617700,
    v: 0.056928571428570204
  },
  {
    t: 1581618600,
    v: 0.05135714285714142
  },
  {
    t: 1581619500,
    v: 0.049571428571426726
  },
  {
    t: 1581620400,
    v: 0.04835714285714131
  },
  {
    t: 1581621300,
    v: 0.0415714285714271
  },
  {
    t: 1581622200,
    v: 0.040285714285712766
  },
  {
    t: 1581623100,
    v: 0.037785714285711994
  },
  {
    t: 1581624000,
    v: 0.038857142857140606
  },
  {
    t: 1581624900,
    v: 0.03892857142856952
  },
  {
    t: 1581625800,
    v: 0.03907142857142735
  },
  {
    t: 1581626700,
    v: 0.03928571428571307
  },
  {
    t: 1581627600,
    v: 0.038571428571428
  },
  {
    t: 1581628500,
    v: 0.035857142857142525
  },
  {
    t: 1581629400,
    v: 0.03457142857142819
  },
  {
    t: 1581630300,
    v: 0.031285714285712425
  },
  {
    t: 1581631200,
    v: 0.03271428571428357
  },
  {
    t: 1581632100,
    v: 0.03249999999999785
  },
  {
    t: 1581633000,
    v: 0.030642857142855258
  },
  {
    t: 1581633900,
    v: 0.02892857142856948
  },
  {
    t: 1581634800,
    v: 0.0291428571428552
  },
  {
    t: 1581635700,
    v: 0.028214285714284415
  },
  {
    t: 1581636600,
    v: 0.028999999999999408
  },
  {
    t: 1581637500,
    v: 0.028857142857141582
  },
  {
    t: 1581638400,
    v: 0.029285714285713027
  },
  {
    t: 1581639300,
    v: 0.03107142857142772
  },
  {
    t: 1581640200,
    v: 0.03107142857142772
  },
  {
    t: 1581641100,
    v: 0.03528571428571427
  },
  {
    t: 1581642000,
    v: 0.040357142857142696
  },
  {
    t: 1581642900,
    v: 0.041857142857143766
  },
  {
    t: 1581643800,
    v: 0.042428571428573036
  },
  {
    t: 1581644700,
    v: 0.043000000000001294
  },
  {
    t: 1581645600,
    v: 0.04342857142857274
  },
  {
    t: 1581646500,
    v: 0.04450000000000135
  },
  {
    t: 1581647400,
    v: 0.04550000000000105
  },
  {
    t: 1581648300,
    v: 0.04628571428571503
  },
  {
    t: 1581649200,
    v: 0.04500000000000069
  },
  {
    t: 1581650100,
    v: 0.04450000000000135
  },
  {
    t: 1581651000,
    v: 0.04321428571428702
  },
  {
    t: 1581651900,
    v: 0.04135714285714341
  },
  {
    t: 1581652800,
    v: 0.04200000000000058
  },
  {
    t: 1581653700,
    v: 0.038642857142856916
  },
  {
    t: 1581654600,
    v: 0.033357142857142766
  },
  {
    t: 1581655500,
    v: 0.03349999999999958
  },
  {
    t: 1581656400,
    v: 0.0317857142857138
  },
  {
    t: 1581657300,
    v: 0.03128571428571446
  },
  {
    t: 1581658200,
    v: 0.030785714285715114
  },
  {
    t: 1581659100,
    v: 0.03021428571428686
  },
  {
    t: 1581660000,
    v: 0.03078571428571613
  },
  {
    t: 1581660900,
    v: 0.030071428571431063
  },
  {
    t: 1581661800,
    v: 0.028071428571430652
  },
  {
    t: 1581662700,
    v: 0.02714285714285885
  },
  {
    t: 1581663600,
    v: 0.029000000000001438
  },
  {
    t: 1581664500,
    v: 0.030142857142857946
  },
  {
    t: 1581665400,
    v: 0.030214285714285843
  },
  {
    t: 1581666300,
    v: 0.030071428571429033
  },
  {
    t: 1581667200,
    v: 0.03235714285714408
  },
  {
    t: 1581668100,
    v: 0.031142857142858662
  },
  {
    t: 1581669000,
    v: 0.03135714285714438
  },
  {
    t: 1581669900,
    v: 0.03285714285714444
  },
  {
    t: 1581670800,
    v: 0.033428571428572695
  },
  {
    t: 1581671700,
    v: 0.034785714285714926
  },
  {
    t: 1581672600,
    v: 0.03314285714285806
  },
  {
    t: 1581673500,
    v: 0.03285714285714241
  },
  {
    t: 1581674400,
    v: 0.032785714285713495
  },
  {
    t: 1581675300,
    v: 0.03292857142857031
  },
  {
    t: 1581676200,
    v: 0.03121428571428453
  },
  {
    t: 1581677100,
    v: 0.02849999999999905
  },
  {
    t: 1581678000,
    v: 0.02749999999999935
  },
  {
    t: 1581678900,
    v: 0.02742857142857044
  },
  {
    t: 1581679800,
    v: 0.0252142857142843
  },
  {
    t: 1581680700,
    v: 0.02535714285714111
  },
  {
    t: 1581681600,
    v: 0.02449999999999822
  },
  {
    t: 1581682500,
    v: 0.02357142857142642
  },
  {
    t: 1581683400,
    v: 0.023499999999997506
  },
  {
    t: 1581684300,
    v: 0.022785714285712442
  },
  {
    t: 1581685200,
    v: 0.022499999999997806
  },
  {
    t: 1581686100,
    v: 0.023642857142856348
  },
  {
    t: 1581687000,
    v: 0.029785714285713385
  },
  {
    t: 1581687900,
    v: 0.03214285714285633
  },
  {
    t: 1581688800,
    v: 0.03421428571428464
  },
  {
    t: 1581689700,
    v: 0.036142857142856144
  },
  {
    t: 1581690600,
    v: 0.038428571428571194
  },
  {
    t: 1581691500,
    v: 0.04114285714285667
  },
  {
    t: 1581692400,
    v: 0.0442142857142857
  },
  {
    t: 1581693300,
    v: 0.04635714285714292
  },
  {
    t: 1581694200,
    v: 0.04721428571428581
  },
  {
    t: 1581695100,
    v: 0.048357142857143334
  },
  {
    t: 1581696000,
    v: 0.04814285714285761
  },
  {
    t: 1581696900,
    v: 0.0480714285714287
  },
  {
    t: 1581697800,
    v: 0.04792857142857189
  },
  {
    t: 1581698700,
    v: 0.046857142857142264
  },
  {
    t: 1581699600,
    v: 0.04192857142857065
  },
  {
    t: 1581700500,
    v: 0.0404999999999995
  },
  {
    t: 1581701400,
    v: 0.039571428571428716
  },
  {
    t: 1581702300,
    v: 0.039000000000000465
  },
  {
    t: 1581703200,
    v: 0.039857142857142334
  },
  {
    t: 1581704100,
    v: 0.03750000000000041
  },
  {
    t: 1581705000,
    v: 0.03478571428571391
  },
  {
    t: 1581705900,
    v: 0.03314285714285704
  },
  {
    t: 1581706800,
    v: 0.0327142857142856
  },
  {
    t: 1581707700,
    v: 0.03392857142857204
  },
  {
    t: 1581708600,
    v: 0.034285714285715585
  },
  {
    t: 1581709500,
    v: 0.034357142857145515
  },
  {
    t: 1581710400,
    v: 0.03457142857143022
  },
  {
    t: 1581711300,
    v: 0.03464285714285913
  },
  {
    t: 1581712200,
    v: 0.03421428571428769
  },
  {
    t: 1581713100,
    v: 0.03364285714285943
  },
  {
    t: 1581714000,
    v: 0.032357142857145096
  },
  {
    t: 1581714900,
    v: 0.03142857142857431
  },
  {
    t: 1581715800,
    v: 0.0273571428571466
  },
  {
    t: 1581890400,
    v: 0.028571428571432023
  },
  {
    t: 1581891300,
    v: 0.0303571428571457
  },
  {
    t: 1581892200,
    v: 0.029357142857146
  },
  {
    t: 1581893100,
    v: 0.029142857142860277
  },
  {
    t: 1581894000,
    v: 0.02900000000000245
  },
  {
    t: 1581894900,
    v: 0.029500000000001796
  },
  {
    t: 1581895800,
    v: 0.030428571428571565
  },
  {
    t: 1581896700,
    v: 0.03292857142857234
  },
  {
    t: 1581897600,
    v: 0.036785714285715344
  },
  {
    t: 1581898500,
    v: 0.040571428571430444
  },
  {
    t: 1581899400,
    v: 0.04071428571428726
  },
  {
    t: 1581900300,
    v: 0.04450000000000135
  },
  {
    t: 1581901200,
    v: 0.04635714285714292
  },
  {
    t: 1581902100,
    v: 0.0480714285714287
  },
  {
    t: 1581903000,
    v: 0.04749999999999943
  },
  {
    t: 1581903900,
    v: 0.04649999999999973
  },
  {
    t: 1581904800,
    v: 0.048571428571428044
  },
  {
    t: 1581905700,
    v: 0.04878571428571275
  },
  {
    t: 1581906600,
    v: 0.04578571428571264
  },
  {
    t: 1581907500,
    v: 0.04435714285714149
  },
  {
    t: 1581908400,
    v: 0.042642857142856726
  },
  {
    t: 1581909300,
    v: 0.03992857142857024
  },
  {
    t: 1581910200,
    v: 0.035499999999997964
  },
  {
    t: 1581911100,
    v: 0.030857142857139967
  },
  {
    t: 1581912000,
    v: 0.030285714285710696
  },
  {
    t: 1581912900,
    v: 0.02607142857142516
  },
  {
    t: 1581913800,
    v: 0.024499999999997208
  },
  {
    t: 1581914700,
    v: 0.022928571428568238
  },
  {
    t: 1581915600,
    v: 0.020928571428568837
  },
  {
    t: 1581916500,
    v: 0.020642857142854205
  },
  {
    t: 1581917400,
    v: 0.018285714285711258
  },
  {
    t: 1581918300,
    v: 0.017857142857140827
  },
  {
    t: 1581919200,
    v: 0.018357142857141184
  },
  {
    t: 1581920100,
    v: 0.018357142857141184
  },
  {
    t: 1581921000,
    v: 0.017714285714284017
  },
  {
    t: 1581921900,
    v: 0.017571428571427208
  },
  {
    t: 1581922800,
    v: 0.01778571428571293
  },
  {
    t: 1581923700,
    v: 0.017499999999998295
  },
  {
    t: 1581924600,
    v: 0.017428571428570398
  },
  {
    t: 1581925500,
    v: 0.017142857142855763
  },
  {
    t: 1581926400,
    v: 0.01678571428571323
  },
  {
    t: 1581927300,
    v: 0.017357142857141485
  },
  {
    t: 1581928200,
    v: 0.018571428571426907
  },
  {
    t: 1581929100,
    v: 0.017857142857141843
  },
  {
    t: 1581930000,
    v: 0.018571428571427923
  },
  {
    t: 1581930900,
    v: 0.019499999999999722
  },
  {
    t: 1581931800,
    v: 0.01949999999999871
  },
  {
    t: 1581932700,
    v: 0.019285714285712987
  },
  {
    t: 1581933600,
    v: 0.019714285714284432
  },
  {
    t: 1581934500,
    v: 0.019857142857142258
  },
  {
    t: 1581935400,
    v: 0.019214285714286104
  },
  {
    t: 1581936300,
    v: 0.018928571428572485
  },
  {
    t: 1581937200,
    v: 0.01907142857143031
  },
  {
    t: 1581938100,
    v: 0.01914285714285922
  },
  {
    t: 1581939000,
    v: 0.019000000000001398
  },
  {
    t: 1581939900,
    v: 0.019000000000001398
  },
  {
    t: 1581940800,
    v: 0.017571428571430254
  },
  {
    t: 1581941700,
    v: 0.01714285714285881
  },
  {
    t: 1581942600,
    v: 0.017214285714286705
  },
  {
    t: 1581943500,
    v: 0.015571428571429837
  },
  {
    t: 1581944400,
    v: 0.015071428571430496
  },
  {
    t: 1581945300,
    v: 0.014571428571430138
  },
  {
    t: 1581946200,
    v: 0.014500000000002242
  },
  {
    t: 1581947100,
    v: 0.013714285714288264
  },
  {
    t: 1581948000,
    v: 0.013928571428572971
  },
  {
    t: 1581948900,
    v: 0.014214285714286592
  },
  {
    t: 1581949800,
    v: 0.013999999999999855
  },
  {
    t: 1581950700,
    v: 0.017142857142856776
  },
  {
    t: 1581951600,
    v: 0.017857142857142856
  },
  {
    t: 1581952500,
    v: 0.016857142857143157
  },
  {
    t: 1581953400,
    v: 0.017285714285714602
  },
  {
    t: 1581954300,
    v: 0.021000000000000796
  },
  {
    t: 1581955200,
    v: 0.021214285714286518
  },
  {
    t: 1581956100,
    v: 0.022142857142857304
  },
  {
    t: 1581957000,
    v: 0.022142857142857304
  },
  {
    t: 1581957900,
    v: 0.02257142857142875
  },
  {
    t: 1581958800,
    v: 0.022357142857142014
  },
  {
    t: 1581959700,
    v: 0.0222857142857131
  },
  {
    t: 1581960600,
    v: 0.022428571428570927
  },
  {
    t: 1581961500,
    v: 0.022357142857143027
  },
  {
    t: 1581962400,
    v: 0.02342857142857164
  },
  {
    t: 1581963300,
    v: 0.020285714285714716
  },
  {
    t: 1581964200,
    v: 0.019499999999999722
  },
  {
    t: 1581965100,
    v: 0.019785714285713345
  },
  {
    t: 1581966000,
    v: 0.019428571428570813
  },
  {
    t: 1581966900,
    v: 0.015428571428569984
  },
  {
    t: 1581967800,
    v: 0.014428571428570283
  },
  {
    t: 1581968700,
    v: 0.013285714285713774
  },
  {
    t: 1581969600,
    v: 0.01271428571428552
  },
  {
    t: 1581970500,
    v: 0.012357142857142987
  },
  {
    t: 1581971400,
    v: 0.012142857142857264
  },
  {
    t: 1581972300,
    v: 0.012214285714286177
  },
  {
    t: 1581973200,
    v: 0.015285714285714189
  },
  {
    t: 1581974100,
    v: 0.01671428571428533
  },
  {
    t: 1581975000,
    v: 0.018571428571427923
  },
  {
    t: 1581975900,
    v: 0.02007142857142798
  },
  {
    t: 1581976800,
    v: 0.02428571428571453
  },
  {
    t: 1581977700,
    v: 0.02664285714285849
  },
  {
    t: 1581978600,
    v: 0.027785714285715
  },
  {
    t: 1581979500,
    v: 0.03057142857142939
  },
  {
    t: 1581980400,
    v: 0.03357142857142951
  },
  {
    t: 1581981300,
    v: 0.035571428571427886
  },
  {
    t: 1581982200,
    v: 0.03678571428571331
  },
  {
    t: 1581983100,
    v: 0.03828571428571337
  },
  {
    t: 1581984000,
    v: 0.03992857142857125
  },
  {
    t: 1581984900,
    v: 0.04128571428571348
  },
  {
    t: 1581985800,
    v: 0.03935714285714198
  },
  {
    t: 1581986700,
    v: 0.04578571428571365
  },
  {
    t: 1581987600,
    v: 0.046928571428571174
  },
  {
    t: 1581988500,
    v: 0.04921428571428521
  },
  {
    t: 1581989400,
    v: 0.047714285714285154
  },
  {
    t: 1581990300,
    v: 0.048571428571428044
  },
  {
    t: 1581991200,
    v: 0.04992857142857129
  },
  {
    t: 1581992100,
    v: 0.04864285714285695
  },
  {
    t: 1581993000,
    v: 0.05028571428571382
  },
  {
    t: 1581993900,
    v: 0.05035714285714273
  },
  {
    t: 1581994800,
    v: 0.05185714285714279
  },
  {
    t: 1581995700,
    v: 0.05149999999999925
  },
  {
    t: 1581996600,
    v: 0.05028571428571281
  },
  {
    t: 1581997500,
    v: 0.04971428571428354
  },
  {
    t: 1581998400,
    v: 0.04857142857142703
  },
  {
    t: 1581999300,
    v: 0.0415714285714271
  },
  {
    t: 1582000200,
    v: 0.03914285714285626
  },
  {
    t: 1582001100,
    v: 0.035571428571427886
  },
  {
    t: 1582002000,
    v: 0.033285714285713856
  },
  {
    t: 1582002900,
    v: 0.030999999999998806
  },
  {
    t: 1582003800,
    v: 0.029928571428570194
  },
  {
    t: 1582004700,
    v: 0.029642857142856575
  },
  {
    t: 1582005600,
    v: 0.02721428571428573
  },
  {
    t: 1582006500,
    v: 0.0267857142857153
  },
  {
    t: 1582007400,
    v: 0.025500000000000966
  },
  {
    t: 1582008300,
    v: 0.025142857142858435
  },
  {
    t: 1582009200,
    v: 0.025785714285716615
  },
  {
    t: 1582010100,
    v: 0.026714285714288417
  },
  {
    t: 1582011000,
    v: 0.03078571428571613
  },
  {
    t: 1582011900,
    v: 0.031142857142858662
  },
  {
    t: 1582012800,
    v: 0.033500000000001605
  },
  {
    t: 1582013700,
    v: 0.0382857142857154
  },
  {
    t: 1582014600,
    v: 0.04014285714285697
  },
  {
    t: 1582015500,
    v: 0.04171428571428594
  },
  {
    t: 1582016400,
    v: 0.04428571428571461
  },
  {
    t: 1582017300,
    v: 0.04671428571428545
  },
  {
    t: 1582018200,
    v: 0.04635714285714292
  },
  {
    t: 1582019100,
    v: 0.04585714285714256
  },
  {
    t: 1582020000,
    v: 0.046857142857142264
  },
  {
    t: 1582020900,
    v: 0.04864285714285594
  },
  {
    t: 1582021800,
    v: 0.05078571428571215
  },
  {
    t: 1582022700,
    v: 0.05035714285714172
  },
  {
    t: 1582023600,
    v: 0.04728571428571371
  },
  {
    t: 1582024500,
    v: 0.046857142857142264
  },
  {
    t: 1582025400,
    v: 0.04357142857142752
  },
  {
    t: 1582026300,
    v: 0.03985714285714132
  },
  {
    t: 1582027200,
    v: 0.038071428571427646
  },
  {
    t: 1582028100,
    v: 0.03814285714285554
  },
  {
    t: 1582029000,
    v: 0.03514285714285543
  },
  {
    t: 1582029900,
    v: 0.033571428571427475
  },
  {
    t: 1582030800,
    v: 0.03328571428571284
  },
  {
    t: 1582031700,
    v: 0.03392857142857001
  },
  {
    t: 1582032600,
    v: 0.035285714285713254
  },
  {
    t: 1582033500,
    v: 0.034214285714285655
  },
  {
    t: 1582034400,
    v: 0.03407142857142783
  },
  {
    t: 1582035300,
    v: 0.03685714285714121
  },
  {
    t: 1582036200,
    v: 0.041999999999998545
  },
  {
    t: 1582037100,
    v: 0.04307142857142716
  },
  {
    t: 1582038000,
    v: 0.04571428571428372
  },
  {
    t: 1582038900,
    v: 0.050785714285713164
  },
  {
    t: 1582039800,
    v: 0.05285714285714249
  },
  {
    t: 1582040700,
    v: 0.05457142857142827
  },
  {
    t: 1582041600,
    v: 0.055785714285713696
  },
  {
    t: 1582042500,
    v: 0.056142857142856224
  },
  {
    t: 1582043400,
    v: 0.0569999999999981
  },
  {
    t: 1582044300,
    v: 0.057428571428569546
  },
  {
    t: 1582045200,
    v: 0.05671428571428346
  },
  {
    t: 1582046100,
    v: 0.05614285714285521
  },
  {
    t: 1582047000,
    v: 0.05478571428571298
  },
  {
    t: 1582047900,
    v: 0.05149999999999925
  },
  {
    t: 1582048800,
    v: 0.046857142857142264
  },
  {
    t: 1582049700,
    v: 0.04557142857142793
  },
  {
    t: 1582050600,
    v: 0.043857142857143164
  },
  {
    t: 1582051500,
    v: 0.03942857142857089
  },
  {
    t: 1582052400,
    v: 0.037214285714284756
  },
  {
    t: 1582053300,
    v: 0.03457142857142819
  },
  {
    t: 1582054200,
    v: 0.03314285714285704
  },
  {
    t: 1582055100,
    v: 0.03221428571428626
  },
  {
    t: 1582056000,
    v: 0.03021428571428686
  },
  {
    t: 1582056900,
    v: 0.028714285714286802
  },
  {
    t: 1582057800,
    v: 0.02664285714285849
  },
  {
    t: 1582058700,
    v: 0.026357142857143856
  },
  {
    t: 1582059600,
    v: 0.0257857142857156
  },
  {
    t: 1582060500,
    v: 0.025357142857144157
  },
  {
    t: 1582061400,
    v: 0.02357142857143048
  },
  {
    t: 1582062300,
    v: 0.023142857142859036
  },
  {
    t: 1582063200,
    v: 0.02164285714285898
  },
  {
    t: 1582064100,
    v: 0.02257142857143078
  },
  {
    t: 1582065000,
    v: 0.023285714285715846
  },
  {
    t: 1582065900,
    v: 0.022214285714287234
  },
  {
    t: 1582066800,
    v: 0.02364285714285939
  },
  {
    t: 1582067700,
    v: 0.02271428571428759
  },
  {
    t: 1582068600,
    v: 0.023071428571431137
  },
  {
    t: 1582069500,
    v: 0.025285714285717274
  },
  {
    t: 1582070400,
    v: 0.02607142857143125
  },
  {
    t: 1582071300,
    v: 0.027142857142859862
  },
  {
    t: 1582072200,
    v: 0.027571428571430294
  },
  {
    t: 1582073100,
    v: 0.028928571428572525
  },
  {
    t: 1582074000,
    v: 0.031428571428572284
  },
  {
    t: 1582074900,
    v: 0.034857142857144856
  },
  {
    t: 1582075800,
    v: 0.036285714285715996
  },
  {
    t: 1582076700,
    v: 0.03500000000000166
  },
  {
    t: 1582077600,
    v: 0.038357142857145324
  },
  {
    t: 1582078500,
    v: 0.04378571428571628
  },
  {
    t: 1582079400,
    v: 0.04321428571428702
  },
  {
    t: 1582080300,
    v: 0.044071428571428886
  },
  {
    t: 1582081200,
    v: 0.043999999999998964
  },
  {
    t: 1582082100,
    v: 0.042285714285712164
  },
  {
    t: 1582083000,
    v: 0.04149999999999819
  },
  {
    t: 1582083900,
    v: 0.0406428571428553
  },
  {
    t: 1582084800,
    v: 0.04007142857142806
  },
  {
    t: 1582085700,
    v: 0.03935714285714299
  },
  {
    t: 1582086600,
    v: 0.03749999999999939
  },
  {
    t: 1582087500,
    v: 0.035285714285713254
  },
  {
    t: 1582088400,
    v: 0.034071428571426816
  },
  {
    t: 1582089300,
    v: 0.03335714285714175
  },
  {
    t: 1582090200,
    v: 0.029285714285713027
  },
  {
    t: 1582091100,
    v: 0.02435714285714141
  },
  {
    t: 1582092000,
    v: 0.024428571428569308
  },
  {
    t: 1582092900,
    v: 0.024785714285712857
  },
  {
    t: 1582093800,
    v: 0.024285714285713515
  },
  {
    t: 1582094700,
    v: 0.02378571428571417
  },
  {
    t: 1582095600,
    v: 0.024357142857142428
  },
  {
    t: 1582096500,
    v: 0.02678571428571327
  },
  {
    t: 1582097400,
    v: 0.028999999999999408
  },
  {
    t: 1582098300,
    v: 0.029857142857142298
  },
  {
    t: 1582099200,
    v: 0.03242857142857097
  },
  {
    t: 1582100100,
    v: 0.03314285714285603
  },
  {
    t: 1582101000,
    v: 0.03885714285714264
  },
  {
    t: 1582101900,
    v: 0.040714285714285224
  },
  {
    t: 1582102800,
    v: 0.04299999999999926
  },
  {
    t: 1582103700,
    v: 0.04499999999999967
  },
  {
    t: 1582104600,
    v: 0.04735714285714364
  },
  {
    t: 1582105500,
    v: 0.049214285714286224
  },
  {
    t: 1582106400,
    v: 0.05000000000000122
  },
  {
    t: 1582107300,
    v: 0.05621428571428717
  },
  {
    t: 1582108200,
    v: 0.05842857142857331
  },
  {
    t: 1582109100,
    v: 0.05728571428571578
  },
  {
    t: 1582110000,
    v: 0.0585714285714291
  },
  {
    t: 1582110900,
    v: 0.06092857142857205
  },
  {
    t: 1582111800,
    v: 0.06000000000000126
  },
  {
    t: 1582112700,
    v: 0.06000000000000126
  },
  {
    t: 1582113600,
    v: 0.05607142857142934
  },
  {
    t: 1582114500,
    v: 0.055071428571428625
  },
  {
    t: 1582115400,
    v: 0.053357142857142846
  },
  {
    t: 1582116300,
    v: 0.0548571428571429
  },
  {
    t: 1582117200,
    v: 0.05342857142857075
  },
  {
    t: 1582118100,
    v: 0.05778571428571411
  },
  {
    t: 1582119000,
    v: 0.06949999999999891
  },
  {
    t: 1582119900,
    v: 0.07264285714285583
  },
  {
    t: 1582120800,
    v: 0.07549999999999812
  },
  {
    t: 1582121700,
    v: 0.07642857142857094
  },
  {
    t: 1582122600,
    v: 0.07557142857142805
  },
  {
    t: 1582123500,
    v: 0.08057142857142756
  },
  {
    t: 1582124400,
    v: 0.08221428571428342
  },
  {
    t: 1582125300,
    v: 0.08435714285714065
  },
  {
    t: 1582126200,
    v: 0.0859285714285696
  },
  {
    t: 1582127100,
    v: 0.0877857142857122
  },
  {
    t: 1582128000,
    v: 0.09278571428571171
  },
  {
    t: 1582128900,
    v: 0.1002142857142841
  },
  {
    t: 1582129800,
    v: 0.1127142857142839
  },
  {
    t: 1582130700,
    v: 0.1099285714285695
  },
  {
    t: 1582131600,
    v: 0.10057142857142663
  },
  {
    t: 1582132500,
    v: 0.09578571428571182
  },
  {
    t: 1582133400,
    v: 0.09399999999999815
  },
  {
    t: 1582134300,
    v: 0.09778571428571224
  },
  {
    t: 1582135200,
    v: 0.09764285714285544
  },
  {
    t: 1582136100,
    v: 0.08935714285714116
  },
  {
    t: 1582137000,
    v: 0.0877142857142843
  },
  {
    t: 1582137900,
    v: 0.09299999999999946
  },
  {
    t: 1582138800,
    v: 0.09778571428571325
  },
  {
    t: 1582139700,
    v: 0.097214285714285
  },
  {
    t: 1582140600,
    v: 0.09571428571428596
  },
  {
    t: 1582141500,
    v: 0.09114285714285687
  },
  {
    t: 1582142400,
    v: 0.08314285714285725
  },
  {
    t: 1582143300,
    v: 0.08678571428571454
  },
  {
    t: 1582144200,
    v: 0.09064285714285754
  },
  {
    t: 1582145100,
    v: 0.08964285714285783
  },
  {
    t: 1582146000,
    v: 0.08814285714285777
  },
  {
    t: 1582146900,
    v: 0.08685714285714344
  },
  {
    t: 1582147800,
    v: 0.08528571428571448
  },
  {
    t: 1582148700,
    v: 0.08621428571428526
  },
  {
    t: 1582149600,
    v: 0.0874285714285717
  },
  {
    t: 1582150500,
    v: 0.08028571428571293
  },
  {
    t: 1582151400,
    v: 0.07557142857142805
  },
  {
    t: 1582152300,
    v: 0.0750714285714287
  },
  {
    t: 1582153200,
    v: 0.07464285714285726
  },
  {
    t: 1582154100,
    v: 0.06921428571428631
  },
  {
    t: 1582155000,
    v: 0.06642857142857192
  },
  {
    t: 1582155900,
    v: 0.06107142857142784
  },
  {
    t: 1582156800,
    v: 0.0644285714285715
  },
  {
    t: 1582157700,
    v: 0.06557142857142903
  },
  {
    t: 1582158600,
    v: 0.06707142857142909
  },
  {
    t: 1582159500,
    v: 0.06850000000000023
  },
  {
    t: 1582160400,
    v: 0.07207142857142859
  },
  {
    t: 1582161300,
    v: 0.07278571428571468
  },
  {
    t: 1582162200,
    v: 0.07757142857142847
  },
  {
    t: 1582163100,
    v: 0.07764285714285839
  },
  {
    t: 1582164000,
    v: 0.07642857142857297
  },
  {
    t: 1582164900,
    v: 0.07764285714285839
  },
  {
    t: 1582165800,
    v: 0.07957142857142888
  },
  {
    t: 1582166700,
    v: 0.08235714285714225
  },
  {
    t: 1582167600,
    v: 0.08707142857142815
  },
  {
    t: 1582168500,
    v: 0.09321428571428621
  },
  {
    t: 1582169400,
    v: 0.08614285714285634
  },
  {
    t: 1582170300,
    v: 0.08342857142857087
  },
  {
    t: 1582171200,
    v: 0.08207142857142762
  },
  {
    t: 1582172100,
    v: 0.0780714285714278
  },
  {
    t: 1582173000,
    v: 0.07535714285714334
  },
  {
    t: 1582173900,
    v: 0.07500000000000082
  },
  {
    t: 1582174800,
    v: 0.06871428571428696
  },
  {
    t: 1582175700,
    v: 0.06714285714285799
  },
  {
    t: 1582176600,
    v: 0.066357142857143
  },
  {
    t: 1582177500,
    v: 0.06542857142857221
  },
  {
    t: 1582178400,
    v: 0.06157142857143023
  },
  {
    t: 1582179300,
    v: 0.06085714285714415
  },
  {
    t: 1582180200,
    v: 0.055142857142858555
  },
  {
    t: 1582181100,
    v: 0.05042857142857266
  },
  {
    t: 1582182000,
    v: 0.04935714285714506
  },
  {
    t: 1582182900,
    v: 0.0662857142857151
  },
  {
    t: 1582183800,
    v: 0.0767142857142866
  },
  {
    t: 1582184700,
    v: 0.07642857142857196
  },
  {
    t: 1582185600,
    v: 0.08028571428571395
  },
  {
    t: 1582186500,
    v: 0.08807142857142784
  },
  {
    t: 1582187400,
    v: 0.09364285714285663
  },
  {
    t: 1582188300,
    v: 0.09599999999999957
  },
  {
    t: 1582189200,
    v: 0.10064285714285656
  },
  {
    t: 1582190100,
    v: 0.1021428571428556
  },
  {
    t: 1582191000,
    v: 0.11099999999999811
  },
  {
    t: 1582191900,
    v: 0.11357142857142678
  },
  {
    t: 1582192800,
    v: 0.11428571428571287
  },
  {
    t: 1582193700,
    v: 0.11642857142857009
  },
  {
    t: 1582194600,
    v: 0.11971428571428382
  },
  {
    t: 1582195500,
    v: 0.10928571428571335
  },
  {
    t: 1582196400,
    v: 0.10299999999999951
  },
  {
    t: 1582197300,
    v: 0.10314285714285733
  },
  {
    t: 1582198200,
    v: 0.10328571428571413
  },
  {
    t: 1582199100,
    v: 0.10785714285714322
  },
  {
    t: 1582200000,
    v: 0.10807142857142793
  },
  {
    t: 1582200900,
    v: 0.10899999999999974
  },
  {
    t: 1582201800,
    v: 0.10792857142857112
  },
  {
    t: 1582202700,
    v: 0.10800000000000003
  },
  {
    t: 1582203600,
    v: 0.10407142857142913
  },
  {
    t: 1582204500,
    v: 0.1045714285714295
  },
  {
    t: 1582205400,
    v: 0.11135714285714268
  },
  {
    t: 1582206300,
    v: 0.11021428571428617
  },
  {
    t: 1582207200,
    v: 0.10892857142857183
  },
  {
    t: 1582208100,
    v: 0.11092857142857124
  },
  {
    t: 1582209000,
    v: 0.10985714285714263
  },
  {
    t: 1582209900,
    v: 0.11592857142857074
  },
  {
    t: 1582210800,
    v: 0.1182857142857147
  },
  {
    t: 1582211700,
    v: 0.11428571428571388
  },
  {
    t: 1582212600,
    v: 0.10871428571428611
  },
  {
    t: 1582213500,
    v: 0.11107142857142804
  },
  {
    t: 1582214400,
    v: 0.11521428571428569
  },
  {
    t: 1582215300,
    v: 0.11621428571428538
  },
  {
    t: 1582216200,
    v: 0.12757142857142764
  },
  {
    t: 1582217100,
    v: 0.12678571428571367
  },
  {
    t: 1582218000,
    v: 0.11992857142857158
  },
  {
    t: 1582218900,
    v: 0.11778571428571435
  },
  {
    t: 1582219800,
    v: 0.12071428571428555
  },
  {
    t: 1582220700,
    v: 0.117285714285715
  },
  {
    t: 1582221600,
    v: 0.11750000000000073
  },
  {
    t: 1582222500,
    v: 0.11207142857142875
  },
  {
    t: 1582223400,
    v: 0.10657142857142787
  },
  {
    t: 1582224300,
    v: 0.09885714285714288
  },
  {
    t: 1582225200,
    v: 0.10057142857142765
  },
  {
    t: 1582226100,
    v: 0.09514285714285668
  },
  {
    t: 1582227000,
    v: 0.08849999999999929
  },
  {
    t: 1582227900,
    v: 0.08599999999999954
  },
  {
    t: 1582228800,
    v: 0.06985714285714247
  },
  {
    t: 1582229700,
    v: 0.06578571428571374
  },
  {
    t: 1582230600,
    v: 0.06249999999999899
  },
  {
    t: 1582231500,
    v: 0.06149999999999827
  },
  {
    t: 1582232400,
    v: 0.05592857142856949
  },
  {
    t: 1582233300,
    v: 0.055214285714283405
  },
  {
    t: 1582234200,
    v: 0.05178571428571185
  },
  {
    t: 1582235100,
    v: 0.05021428571428389
  },
  {
    t: 1582236000,
    v: 0.049071428571427385
  },
  {
    t: 1582236900,
    v: 0.04749999999999842
  },
  {
    t: 1582237800,
    v: 0.04442857142857041
  },
  {
    t: 1582238700,
    v: 0.04392857142857005
  },
  {
    t: 1582239600,
    v: 0.04264285714285571
  },
  {
    t: 1582240500,
    v: 0.04128571428571247
  },
  {
    t: 1582241400,
    v: 0.0415714285714271
  },
  {
    t: 1582242300,
    v: 0.04499999999999866
  },
  {
    t: 1582243200,
    v: 0.05149999999999925
  },
  {
    t: 1582244100,
    v: 0.05564285714285688
  },
  {
    t: 1582245000,
    v: 0.06014285714285807
  },
  {
    t: 1582245900,
    v: 0.05878571428571482
  },
  {
    t: 1582246800,
    v: 0.061285714285714575
  },
  {
    t: 1582247700,
    v: 0.06757142857142842
  },
  {
    t: 1582248600,
    v: 0.06978571428571355
  },
  {
    t: 1582249500,
    v: 0.07435714285714161
  },
  {
    t: 1582250400,
    v: 0.07785714285714208
  },
  {
    t: 1582251300,
    v: 0.0808571428571422
  },
  {
    t: 1582252200,
    v: 0.08535714285714237
  },
  {
    t: 1582253100,
    v: 0.0874285714285717
  },
  {
    t: 1582254000,
    v: 0.08664285714285772
  },
  {
    t: 1582254900,
    v: 0.08271428571428682
  },
  {
    t: 1582255800,
    v: 0.07807142857142983
  },
  {
    t: 1582256700,
    v: 0.0729285714285725
  },
  {
    t: 1582257600,
    v: 0.06864285714285805
  },
  {
    t: 1582258500,
    v: 0.06428571428571571
  },
  {
    t: 1582259400,
    v: 0.06064285714285843
  },
  {
    t: 1582260300,
    v: 0.05392857142857314
  },
  {
    t: 1582261200,
    v: 0.05021428571428795
  },
  {
    t: 1582262100,
    v: 0.04942857142857398
  },
  {
    t: 1582263000,
    v: 0.04628571428571604
  },
  {
    t: 1582263900,
    v: 0.04350000000000165
  },
  {
    t: 1582264800,
    v: 0.03985714285714437
  },
  {
    t: 1582265700,
    v: 0.037928571428572866
  },
  {
    t: 1582266600,
    v: 0.03700000000000107
  },
  {
    t: 1582267500,
    v: 0.03792857142857185
  },
  {
    t: 1582268400,
    v: 0.03914285714285727
  },
  {
    t: 1582269300,
    v: 0.04457142857142823
  },
  {
    t: 1582270200,
    v: 0.05264285714285677
  },
  {
    t: 1582271100,
    v: 0.05685714285714231
  },
  {
    t: 1582272000,
    v: 0.07285714285714258
  },
  {
    t: 1582272900,
    v: 0.08114285714285684
  },
  {
    t: 1582273800,
    v: 0.08607142857142845
  },
  {
    t: 1582274700,
    v: 0.08614285714285737
  },
  {
    t: 1582275600,
    v: 0.08850000000000133
  },
  {
    t: 1582276500,
    v: 0.08942857142857312
  },
  {
    t: 1582277400,
    v: 0.08935714285714422
  },
  {
    t: 1582278300,
    v: 0.09242857142857323
  },
  {
    t: 1582279200,
    v: 0.09414285714285901
  },
  {
    t: 1582280100,
    v: 0.09507142857143082
  },
  {
    t: 1582281000,
    v: 0.09335714285714504
  },
  {
    t: 1582281900,
    v: 0.08957142857143095
  },
  {
    t: 1582282800,
    v: 0.08150000000000242
  },
  {
    t: 1582283700,
    v: 0.07871428571428904
  },
  {
    t: 1582284600,
    v: 0.06442857142857454
  },
  {
    t: 1582285500,
    v: 0.060642857142860454
  },
  {
    t: 1582286400,
    v: 0.0601428571428601
  },
  {
    t: 1582287300,
    v: 0.061142857142859795
  },
  {
    t: 1582288200,
    v: 0.06242857142857312
  },
  {
    t: 1582289100,
    v: 0.06428571428571571
  },
  {
    t: 1582290000,
    v: 0.06471428571428715
  },
  {
    t: 1582290900,
    v: 0.06178571428571595
  },
  {
    t: 1582291800,
    v: 0.06342857142857282
  },
  {
    t: 1582292700,
    v: 0.0661428571428583
  },
  {
    t: 1582293600,
    v: 0.06892857142857269
  },
  {
    t: 1582294500,
    v: 0.06792857142857299
  },
  {
    t: 1582295400,
    v: 0.07321428571428713
  },
  {
    t: 1582296300,
    v: 0.08807142857142887
  },
  {
    t: 1582297200,
    v: 0.10164285714285828
  },
  {
    t: 1582298100,
    v: 0.1029285714285716
  },
  {
    t: 1582299000,
    v: 0.10407142857142812
  },
  {
    t: 1582299900,
    v: 0.11121428571428485
  },
  {
    t: 1582300800,
    v: 0.11014285714285725
  },
  {
    t: 1582301700,
    v: 0.1096428571428569
  },
  {
    t: 1582302600,
    v: 0.11007142857142835
  },
  {
    t: 1582303500,
    v: 0.11042857142856986
  },
  {
    t: 1582304400,
    v: 0.10949999999999806
  },
  {
    t: 1582305300,
    v: 0.10514285714285572
  },
  {
    t: 1582306200,
    v: 0.10199999999999777
  },
  {
    t: 1582307100,
    v: 0.104142857142855
  },
  {
    t: 1582308000,
    v: 0.10128571428571169
  },
  {
    t: 1582308900,
    v: 0.08514285714285462
  },
  {
    t: 1582309800,
    v: 0.0705714285714255
  },
  {
    t: 1582310700,
    v: 0.06507142857142664
  },
  {
    t: 1582311600,
    v: 0.06135714285714146
  },
  {
    t: 1582312500,
    v: 0.04985714285714238
  },
  {
    t: 1582313400,
    v: 0.049571428571427746
  },
  {
    t: 1582314300,
    v: 0.04735714285714262
  },
  {
    t: 1582315200,
    v: 0.04642857142857183
  },
  {
    t: 1582316100,
    v: 0.04635714285714292
  },
  {
    t: 1582317000,
    v: 0.046928571428572194
  },
  {
    t: 1582317900,
    v: 0.0481428571428566
  },
  {
    t: 1582318800,
    v: 0.0480714285714287
  },
  {
    t: 1582319700,
    v: 0.04471428571428605
  },
  {
    t: 1582320600,
    v: 0.040428571428571605
  },
  {
    t: 1582495200,
    v: 0.052285714285714234
  },
  {
    t: 1582496100,
    v: 0.05078571428571418
  },
  {
    t: 1582497000,
    v: 0.05242857142857105
  },
  {
    t: 1582497900,
    v: 0.057857142857142004
  },
  {
    t: 1582498800,
    v: 0.06349999999999868
  },
  {
    t: 1582499700,
    v: 0.06992857142857035
  },
  {
    t: 1582500600,
    v: 0.07335714285714191
  },
  {
    t: 1582501500,
    v: 0.07607142857142637
  },
  {
    t: 1582502400,
    v: 0.07992857142857039
  },
  {
    t: 1582503300,
    v: 0.08228571428571334
  },
  {
    t: 1582504200,
    v: 0.0829285714285705
  },
  {
    t: 1582505100,
    v: 0.09007142857142826
  },
  {
    t: 1582506000,
    v: 0.09214285714285556
  },
  {
    t: 1582506900,
    v: 0.0972857142857129
  },
  {
    t: 1582507800,
    v: 0.08864285714285712
  },
  {
    t: 1582508700,
    v: 0.09335714285714301
  },
  {
    t: 1582509600,
    v: 0.09214285714285657
  },
  {
    t: 1582510500,
    v: 0.08621428571428526
  },
  {
    t: 1582511400,
    v: 0.08157142857142828
  },
  {
    t: 1582512300,
    v: 0.07542857142857022
  },
  {
    t: 1582513200,
    v: 0.07749999999999854
  },
  {
    t: 1582514100,
    v: 0.07564285714285594
  },
  {
    t: 1582515000,
    v: 0.07228571428571229
  },
  {
    t: 1582515900,
    v: 0.06899999999999754
  },
  {
    t: 1582516800,
    v: 0.0657857142857117
  },
  {
    t: 1582517700,
    v: 0.05785714285713896
  },
  {
    t: 1582518600,
    v: 0.05628571428571202
  },
  {
    t: 1582519500,
    v: 0.052071428571426485
  },
  {
    t: 1582520400,
    v: 0.04821428571428247
  },
  {
    t: 1582521300,
    v: 0.04585714285713952
  },
  {
    t: 1582522200,
    v: 0.045928571428569445
  },
  {
    t: 1582523100,
    v: 0.04492857142856975
  },
  {
    t: 1582524000,
    v: 0.04385714285714114
  },
  {
    t: 1582524900,
    v: 0.04121428571428457
  },
  {
    t: 1582525800,
    v: 0.035785714285712596
  },
  {
    t: 1582526700,
    v: 0.03514285714285543
  },
  {
    t: 1582527600,
    v: 0.038357142857141265
  },
  {
    t: 1582528500,
    v: 0.040857142857141024
  },
  {
    t: 1582529400,
    v: 0.04464285714285613
  },
  {
    t: 1582530300,
    v: 0.046571428571428646
  },
  {
    t: 1582531200,
    v: 0.0520714285714275
  },
  {
    t: 1582532100,
    v: 0.055285714285713335
  },
  {
    t: 1582533000,
    v: 0.06564285714285692
  },
  {
    t: 1582533900,
    v: 0.06850000000000023
  },
  {
    t: 1582534800,
    v: 0.07357142857142865
  },
  {
    t: 1582535700,
    v: 0.07714285714285703
  },
  {
    t: 1582536600,
    v: 0.08050000000000068
  },
  {
    t: 1582537500,
    v: 0.0826428571428569
  },
  {
    t: 1582538400,
    v: 0.08378571428571442
  },
  {
    t: 1582539300,
    v: 0.08285714285714363
  },
  {
    t: 1582540200,
    v: 0.07921428571428635
  },
  {
    t: 1582541100,
    v: 0.07757142857142948
  },
  {
    t: 1582542000,
    v: 0.07650000000000087
  },
  {
    t: 1582542900,
    v: 0.07857142857142918
  },
  {
    t: 1582543800,
    v: 0.0758571428571437
  },
  {
    t: 1582544700,
    v: 0.07592857142857261
  },
  {
    t: 1582545600,
    v: 0.07228571428571533
  },
  {
    t: 1582546500,
    v: 0.06900000000000059
  },
  {
    t: 1582547400,
    v: 0.06614285714285627
  },
  {
    t: 1582548300,
    v: 0.06314285714285717
  },
  {
    t: 1582549200,
    v: 0.06321428571428507
  },
  {
    t: 1582550100,
    v: 0.06407142857142897
  },
  {
    t: 1582551000,
    v: 0.06750000000000053
  },
  {
    t: 1582551900,
    v: 0.06878571428571487
  },
  {
    t: 1582552800,
    v: 0.0843571428571447
  },
  {
    t: 1582553700,
    v: 0.09764285714285847
  },
  {
    t: 1582554600,
    v: 0.10350000000000088
  },
  {
    t: 1582555500,
    v: 0.12164285714285837
  },
  {
    t: 1582556400,
    v: 0.13092857142857234
  },
  {
    t: 1582557300,
    v: 0.14178571428571526
  },
  {
    t: 1582558200,
    v: 0.1451428571428579
  },
  {
    t: 1582559100,
    v: 0.1508571428571435
  },
  {
    t: 1582560000,
    v: 0.150500000000002
  },
  {
    t: 1582560900,
    v: 0.15592857142857192
  },
  {
    t: 1582561800,
    v: 0.15592857142857192
  },
  {
    t: 1582562700,
    v: 0.15650000000000017
  },
  {
    t: 1582563600,
    v: 0.15785714285714242
  },
  {
    t: 1582564500,
    v: 0.16142857142857078
  },
  {
    t: 1582565400,
    v: 0.14792857142857027
  },
  {
    t: 1582566300,
    v: 0.1367857142857137
  },
  {
    t: 1582567200,
    v: 0.1307142857142856
  },
  {
    t: 1582568100,
    v: 0.11607142857142756
  },
  {
    t: 1582569000,
    v: 0.10499999999999991
  },
  {
    t: 1582569900,
    v: 0.09307142857142736
  },
  {
    t: 1582570800,
    v: 0.08692857142857033
  },
  {
    t: 1582571700,
    v: 0.08664285714285569
  },
  {
    t: 1582572600,
    v: 0.08757142857142648
  },
  {
    t: 1582573500,
    v: 0.0867142857142846
  },
  {
    t: 1582574400,
    v: 0.0866428571428567
  },
  {
    t: 1582575300,
    v: 0.08742857142857068
  },
  {
    t: 1582576200,
    v: 0.08592857142857165
  },
  {
    t: 1582577100,
    v: 0.08299999999999942
  },
  {
    t: 1582578000,
    v: 0.08335714285714195
  },
  {
    t: 1582578900,
    v: 0.08014285714285611
  },
  {
    t: 1582579800,
    v: 0.08007142857142721
  },
  {
    t: 1582580700,
    v: 0.07492857142856987
  },
  {
    t: 1582581600,
    v: 0.07571428571428385
  },
  {
    t: 1582582500,
    v: 0.07307142857142727
  },
  {
    t: 1582583400,
    v: 0.06928571428571217
  },
  {
    t: 1582584300,
    v: 0.06157142857142718
  },
  {
    t: 1582585200,
    v: 0.06914285714285638
  },
  {
    t: 1582586100,
    v: 0.06942857142857102
  },
  {
    t: 1582587000,
    v: 0.07142857142857041
  },
  {
    t: 1582587900,
    v: 0.07121428571428469
  },
  {
    t: 1582588800,
    v: 0.0772142857142839
  },
  {
    t: 1582589700,
    v: 0.07885714285714178
  },
  {
    t: 1582590600,
    v: 0.07864285714285606
  },
  {
    t: 1582591500,
    v: 0.0798571428571425
  },
  {
    t: 1582592400,
    v: 0.08807142857142784
  },
  {
    t: 1582593300,
    v: 0.08950000000000001
  },
  {
    t: 1582594200,
    v: 0.09014285714285616
  },
  {
    t: 1582595100,
    v: 0.09171428571428512
  },
  {
    t: 1582596000,
    v: 0.09785714285714217
  },
  {
    t: 1582596900,
    v: 0.10099999999999909
  },
  {
    t: 1582597800,
    v: 0.09135714285714158
  },
  {
    t: 1582598700,
    v: 0.08878571428571291
  },
  {
    t: 1582599600,
    v: 0.08614285714285634
  },
  {
    t: 1582600500,
    v: 0.08414285714285695
  },
  {
    t: 1582601400,
    v: 0.07471428571428516
  },
  {
    t: 1582602300,
    v: 0.07371428571428547
  },
  {
    t: 1582603200,
    v: 0.07128571428571462
  },
  {
    t: 1582604100,
    v: 0.06999999999999927
  },
  {
    t: 1582605000,
    v: 0.061785714285714936
  },
  {
    t: 1582605900,
    v: 0.058500000000000184
  },
  {
    t: 1582606800,
    v: 0.05692857142857223
  },
  {
    t: 1582607700,
    v: 0.05742857142857157
  },
  {
    t: 1582608600,
    v: 0.05192857142857272
  },
  {
    t: 1582609500,
    v: 0.04992857142857231
  },
  {
    t: 1582610400,
    v: 0.05164285714285707
  },
  {
    t: 1582611300,
    v: 0.04999999999999919
  },
  {
    t: 1582612200,
    v: 0.048142857142855586
  },
  {
    t: 1582613100,
    v: 0.04771428571428414
  },
  {
    t: 1582614000,
    v: 0.0492142857142842
  },
  {
    t: 1582614900,
    v: 0.0531428571428551
  },
  {
    t: 1582615800,
    v: 0.05835714285714033
  },
  {
    t: 1582616700,
    v: 0.05985714285714039
  },
  {
    t: 1582617600,
    v: 0.06464285714285418
  },
  {
    t: 1582618500,
    v: 0.07114285714285476
  },
  {
    t: 1582619400,
    v: 0.07464285714285523
  },
  {
    t: 1582620300,
    v: 0.07607142857142739
  },
  {
    t: 1582621200,
    v: 0.08649999999999888
  },
  {
    t: 1582622100,
    v: 0.08921428571428537
  },
  {
    t: 1582623000,
    v: 0.08671428571428562
  },
  {
    t: 1582623900,
    v: 0.09050000000000072
  },
  {
    t: 1582624800,
    v: 0.0922142857142865
  },
  {
    t: 1582625700,
    v: 0.09207142857142969
  },
  {
    t: 1582626600,
    v: 0.09092857142857318
  },
  {
    t: 1582627500,
    v: 0.09728571428571696
  },
  {
    t: 1582628400,
    v: 0.09392857142857432
  },
  {
    t: 1582629300,
    v: 0.09307142857143244
  },
  {
    t: 1582630200,
    v: 0.09428571428571786
  },
  {
    t: 1582631100,
    v: 0.09092857142857522
  },
  {
    t: 1582632000,
    v: 0.08992857142857551
  },
  {
    t: 1582632900,
    v: 0.09700000000000333
  },
  {
    t: 1582633800,
    v: 0.09035714285714594
  },
  {
    t: 1582634700,
    v: 0.09235714285714534
  },
  {
    t: 1582635600,
    v: 0.09500000000000293
  },
  {
    t: 1582636500,
    v: 0.09257142857143107
  },
  {
    t: 1582637400,
    v: 0.09121428571428782
  },
  {
    t: 1582638300,
    v: 0.09414285714285901
  },
  {
    t: 1582639200,
    v: 0.0967857142857166
  },
  {
    t: 1582640100,
    v: 0.08578571428571584
  },
  {
    t: 1582641000,
    v: 0.0940714285714291
  },
  {
    t: 1582641900,
    v: 0.09642857142857204
  },
  {
    t: 1582642800,
    v: 0.09364285714285765
  },
  {
    t: 1582643700,
    v: 0.11228571428571447
  },
  {
    t: 1582644600,
    v: 0.11457142857142852
  },
  {
    t: 1582645500,
    v: 0.11499999999999996
  },
  {
    t: 1582646400,
    v: 0.11471428571428634
  },
  {
    t: 1582647300,
    v: 0.11450000000000062
  },
  {
    t: 1582648200,
    v: 0.11378571428571453
  },
  {
    t: 1582649100,
    v: 0.11999999999999947
  },
  {
    t: 1582650000,
    v: 0.12449999999999964
  },
  {
    t: 1582650900,
    v: 0.12457142857142754
  },
  {
    t: 1582651800,
    v: 0.12678571428571267
  },
  {
    t: 1582652700,
    v: 0.1262857142857133
  },
  {
    t: 1582653600,
    v: 0.1183571428571426
  },
  {
    t: 1582654500,
    v: 0.11657142857142791
  },
  {
    t: 1582655400,
    v: 0.11285714285714274
  },
  {
    t: 1582656300,
    v: 0.09199999999999976
  },
  {
    t: 1582657200,
    v: 0.097214285714285
  },
  {
    t: 1582658100,
    v: 0.0903571428571429
  },
  {
    t: 1582659000,
    v: 0.09364285714285663
  },
  {
    t: 1582659900,
    v: 0.09757142857142753
  },
  {
    t: 1582660800,
    v: 0.09942857142857113
  },
  {
    t: 1582661700,
    v: 0.09442857142857163
  },
  {
    t: 1582662600,
    v: 0.09200000000000078
  },
  {
    t: 1582663500,
    v: 0.0891428571428585
  },
  {
    t: 1582664400,
    v: 0.08385714285714434
  },
  {
    t: 1582665300,
    v: 0.08214285714285856
  },
  {
    t: 1582666200,
    v: 0.08185714285714495
  },
  {
    t: 1582667100,
    v: 0.08014285714285917
  },
  {
    t: 1582668000,
    v: 0.0805714285714296
  },
  {
    t: 1582668900,
    v: 0.07950000000000099
  },
  {
    t: 1582669800,
    v: 0.06957142857142985
  },
  {
    t: 1582670700,
    v: 0.06578571428571475
  },
  {
    t: 1582671600,
    v: 0.061999999999999646
  },
  {
    t: 1582672500,
    v: 0.053142857142857124
  },
  {
    t: 1582673400,
    v: 0.04978571428571448
  },
  {
    t: 1582674300,
    v: 0.04721428571428581
  },
  {
    t: 1582675200,
    v: 0.049571428571427746
  },
  {
    t: 1582676100,
    v: 0.05242857142857105
  },
  {
    t: 1582677000,
    v: 0.05528571428571435
  },
  {
    t: 1582677900,
    v: 0.061428571428570375
  },
  {
    t: 1582678800,
    v: 0.06421428571428375
  },
  {
    t: 1582679700,
    v: 0.0665714285714267
  },
  {
    t: 1582680600,
    v: 0.07078571428571223
  },
  {
    t: 1582681500,
    v: 0.0751428571428556
  },
  {
    t: 1582682400,
    v: 0.09221428571428346
  },
  {
    t: 1582683300,
    v: 0.09685714285714145
  },
  {
    t: 1582684200,
    v: 0.10292857142857059
  },
  {
    t: 1582685100,
    v: 0.10449999999999957
  },
  {
    t: 1582686000,
    v: 0.10907142857142763
  },
  {
    t: 1582686900,
    v: 0.10971428571428479
  },
  {
    t: 1582687800,
    v: 0.1048571428571421
  },
  {
    t: 1582688700,
    v: 0.10235714285714133
  },
  {
    t: 1582689600,
    v: 0.10199999999999777
  },
  {
    t: 1582690500,
    v: 0.09857142857142723
  },
  {
    t: 1582691400,
    v: 0.09585714285714175
  },
  {
    t: 1582692300,
    v: 0.0934999999999988
  },
  {
    t: 1582693200,
    v: 0.09042857142857079
  },
  {
    t: 1582694100,
    v: 0.08721428571428394
  },
  {
    t: 1582695000,
    v: 0.07149999999999933
  },
  {
    t: 1582695900,
    v: 0.06764285714285632
  },
  {
    t: 1582696800,
    v: 0.06085714285714212
  },
  {
    t: 1582697700,
    v: 0.0625714285714279
  },
  {
    t: 1582698600,
    v: 0.05899999999999953
  },
  {
    t: 1582699500,
    v: 0.062071428571428555
  },
  {
    t: 1582700400,
    v: 0.06678571428571445
  },
  {
    t: 1582701300,
    v: 0.06871428571428696
  },
  {
    t: 1582702200,
    v: 0.06764285714285936
  },
  {
    t: 1582703100,
    v: 0.06878571428571588
  },
  {
    t: 1582704000,
    v: 0.06950000000000196
  },
  {
    t: 1582704900,
    v: 0.07335714285714497
  },
  {
    t: 1582705800,
    v: 0.07514285714285965
  },
  {
    t: 1582706700,
    v: 0.08414285714285999
  },
  {
    t: 1582707600,
    v: 0.08878571428571698
  },
  {
    t: 1582708500,
    v: 0.09350000000000185
  },
  {
    t: 1582709400,
    v: 0.10185714285714502
  },
  {
    t: 1582710300,
    v: 0.10428571428571688
  },
  {
    t: 1582711200,
    v: 0.11278571428571686
  },
  {
    t: 1582712100,
    v: 0.10985714285714465
  },
  {
    t: 1582713000,
    v: 0.10592857142857375
  },
  {
    t: 1582713900,
    v: 0.10400000000000224
  },
  {
    t: 1582714800,
    v: 0.10421428571428695
  },
  {
    t: 1582715700,
    v: 0.10564285714285912
  },
  {
    t: 1582716600,
    v: 0.11000000000000247
  },
  {
    t: 1582717500,
    v: 0.1112142857142879
  },
  {
    t: 1582718400,
    v: 0.11471428571428735
  },
  {
    t: 1582719300,
    v: 0.10728571428571598
  },
  {
    t: 1582720200,
    v: 0.10392857142857233
  },
  {
    t: 1582721100,
    v: 0.10085714285714431
  },
  {
    t: 1582722000,
    v: 0.09192857142857187
  },
  {
    t: 1582722900,
    v: 0.08835714285714248
  },
  {
    t: 1582723800,
    v: 0.07928571428571322
  },
  {
    t: 1582724700,
    v: 0.08028571428571293
  },
  {
    t: 1582725600,
    v: 0.08049999999999866
  },
  {
    t: 1582726500,
    v: 0.0839285714285692
  },
  {
    t: 1582727400,
    v: 0.09499999999999886
  },
  {
    t: 1582728300,
    v: 0.0962857142857132
  },
  {
    t: 1582729200,
    v: 0.09471428571428321
  },
  {
    t: 1582730100,
    v: 0.09507142857142574
  },
  {
    t: 1582731000,
    v: 0.09057142857142557
  },
  {
    t: 1582731900,
    v: 0.09249999999999707
  },
  {
    t: 1582732800,
    v: 0.09642857142856899
  },
  {
    t: 1582733700,
    v: 0.09928571428571129
  },
  {
    t: 1582734600,
    v: 0.10107142857142597
  },
  {
    t: 1582735500,
    v: 0.10728571428571192
  },
  {
    t: 1582736400,
    v: 0.11121428571428384
  },
  {
    t: 1582737300,
    v: 0.11442857142857069
  },
  {
    t: 1582738200,
    v: 0.11364285714285671
  },
  {
    t: 1582739100,
    v: 0.11299999999999955
  },
  {
    t: 1582740000,
    v: 0.10121428571428481
  },
  {
    t: 1582740900,
    v: 0.0962857142857132
  },
  {
    t: 1582741800,
    v: 0.10999999999999943
  },
  {
    t: 1582742700,
    v: 0.11664285714285683
  },
  {
    t: 1582743600,
    v: 0.11528571428571459
  },
  {
    t: 1582744500,
    v: 0.11357142857142881
  },
  {
    t: 1582745400,
    v: 0.11292857142857164
  },
  {
    t: 1582746300,
    v: 0.11064285714285761
  },
  {
    t: 1582747200,
    v: 0.11757142857142863
  },
  {
    t: 1582748100,
    v: 0.11307142857142846
  },
  {
    t: 1582749000,
    v: 0.11285714285714274
  },
  {
    t: 1582749900,
    v: 0.10757142857142757
  },
  {
    t: 1582750800,
    v: 0.10742857142856975
  },
  {
    t: 1582751700,
    v: 0.10242857142856922
  },
  {
    t: 1582752600,
    v: 0.10128571428571169
  },
  {
    t: 1582753500,
    v: 0.1024285714285682
  },
  {
    t: 1582754400,
    v: 0.08385714285714028
  },
  {
    t: 1582755300,
    v: 0.07292857142856844
  },
  {
    t: 1582756200,
    v: 0.07042857142856869
  },
  {
    t: 1582757100,
    v: 0.06842857142856928
  },
  {
    t: 1582758000,
    v: 0.06485714285713991
  },
  {
    t: 1582758900,
    v: 0.06285714285713949
  },
  {
    t: 1582759800,
    v: 0.058785714285711776
  },
  {
    t: 1582760700,
    v: 0.0627142857142837
  },
  {
    t: 1582761600,
    v: 0.06957142857142681
  },
  {
    t: 1582762500,
    v: 0.0742142857142848
  },
  {
    t: 1582763400,
    v: 0.077142857142856
  },
  {
    t: 1582764300,
    v: 0.08321428571428514
  },
  {
    t: 1582765200,
    v: 0.08485714285714303
  },
  {
    t: 1582767900,
    v: 0.08242857142857218
  },
  {
    t: 1582768800,
    v: 0.08428571428571376
  },
  {
    t: 1582769700,
    v: 0.08628571428571417
  },
  {
    t: 1582770600,
    v: 0.09014285714285616
  },
  {
    t: 1582771500,
    v: 0.0924285714285702
  },
  {
    t: 1582772400,
    v: 0.08957142857142893
  },
  {
    t: 1582773300,
    v: 0.09078571428571536
  },
  {
    t: 1582774200,
    v: 0.0855714285714291
  },
  {
    t: 1582775100,
    v: 0.07885714285714382
  },
  {
    t: 1582776000,
    v: 0.06757142857142943
  },
  {
    t: 1582776900,
    v: 0.06542857142857221
  },
  {
    t: 1582777800,
    v: 0.0633571428571439
  },
  {
    t: 1582778700,
    v: 0.06928571428571624
  },
  {
    t: 1582779600,
    v: 0.075642857142859
  },
  {
    t: 1582780500,
    v: 0.07785714285714411
  },
  {
    t: 1582781400,
    v: 0.08378571428571645
  },
  {
    t: 1582782300,
    v: 0.08400000000000217
  },
  {
    t: 1582783200,
    v: 0.08121428571428778
  },
  {
    t: 1582784100,
    v: 0.08200000000000175
  },
  {
    t: 1582785000,
    v: 0.08607142857142946
  },
  {
    t: 1582785900,
    v: 0.08892857142857176
  },
  {
    t: 1582786800,
    v: 0.09500000000000089
  },
  {
    t: 1582787700,
    v: 0.09721428571428602
  },
  {
    t: 1582788600,
    v: 0.09992857142857149
  },
  {
    t: 1582789500,
    v: 0.10221428571428552
  },
  {
    t: 1582790400,
    v: 0.11085714285714232
  },
  {
    t: 1582791300,
    v: 0.10521428571428462
  },
  {
    t: 1582792200,
    v: 0.10649999999999896
  },
  {
    t: 1582793100,
    v: 0.11399999999999924
  },
  {
    t: 1582794000,
    v: 0.1087142857142851
  },
  {
    t: 1582794900,
    v: 0.10899999999999872
  },
  {
    t: 1582795800,
    v: 0.11378571428571352
  },
  {
    t: 1582796700,
    v: 0.1115714285714284
  },
  {
    t: 1582797600,
    v: 0.11135714285714268
  },
  {
    t: 1582798500,
    v: 0.11107142857142906
  },
  {
    t: 1582799400,
    v: 0.10607142857142955
  },
  {
    t: 1582800300,
    v: 0.10642857142857208
  },
  {
    t: 1582801200,
    v: 0.10550000000000027
  },
  {
    t: 1582802100,
    v: 0.10214285714285763
  },
  {
    t: 1582803000,
    v: 0.09871428571428607
  },
  {
    t: 1582803900,
    v: 0.09750000000000066
  },
  {
    t: 1582804800,
    v: 0.09057142857142964
  },
  {
    t: 1582805700,
    v: 0.0892857142857153
  },
  {
    t: 1582806600,
    v: 0.09200000000000078
  },
  {
    t: 1582807500,
    v: 0.09142857142857354
  },
  {
    t: 1582808400,
    v: 0.08800000000000299
  },
  {
    t: 1582809300,
    v: 0.09457142857143148
  },
  {
    t: 1582810200,
    v: 0.09892857142857484
  },
  {
    t: 1582811100,
    v: 0.09892857142857484
  },
  {
    t: 1582812000,
    v: 0.10178571428571713
  },
  {
    t: 1582812900,
    v: 0.10078571428571641
  },
  {
    t: 1582813800,
    v: 0.1092142857142885
  },
  {
    t: 1582814700,
    v: 0.11907142857143072
  },
  {
    t: 1582815600,
    v: 0.11921428571428853
  },
  {
    t: 1582816500,
    v: 0.12150000000000258
  },
  {
    t: 1582817400,
    v: 0.12457142857143058
  },
  {
    t: 1582818300,
    v: 0.1324285714285734
  },
  {
    t: 1582819200,
    v: 0.14035714285714412
  },
  {
    t: 1582820100,
    v: 0.1460000000000008
  },
  {
    t: 1582821000,
    v: 0.1471428571428573
  },
  {
    t: 1582821900,
    v: 0.14842857142857163
  },
  {
    t: 1582822800,
    v: 0.15035714285714313
  },
  {
    t: 1582823700,
    v: 0.15350000000000005
  },
  {
    t: 1582824600,
    v: 0.1567142857142849
  },
  {
    t: 1582825500,
    v: 0.16021428571428636
  },
  {
    t: 1582826400,
    v: 0.15250000000000036
  },
  {
    t: 1582827300,
    v: 0.14714285714285832
  },
  {
    t: 1582828200,
    v: 0.15007142857142952
  },
  {
    t: 1582829100,
    v: 0.15092857142857138
  },
  {
    t: 1582830000,
    v: 0.14871428571428527
  },
  {
    t: 1582830900,
    v: 0.1374285714285719
  },
  {
    t: 1582831800,
    v: 0.1266428571428579
  },
  {
    t: 1582832700,
    v: 0.12028571428571411
  },
  {
    t: 1582833600,
    v: 0.12464285714285646
  },
  {
    t: 1582834500,
    v: 0.11785714285714224
  },
  {
    t: 1582835400,
    v: 0.1107857142857134
  },
  {
    t: 1582836300,
    v: 0.10978571428571371
  },
  {
    t: 1582837200,
    v: 0.1162857142857143
  },
  {
    t: 1582838100,
    v: 0.11407142857142714
  },
  {
    t: 1582839000,
    v: 0.11442857142856967
  },
  {
    t: 1582839900,
    v: 0.1177857142857113
  },
  {
    t: 1582840800,
    v: 0.10971428571428277
  },
  {
    t: 1582841700,
    v: 0.11749999999999769
  },
  {
    t: 1582842600,
    v: 0.11792857142856913
  },
  {
    t: 1582843500,
    v: 0.11299999999999752
  },
  {
    t: 1582844400,
    v: 0.11828571428571268
  },
  {
    t: 1582845300,
    v: 0.12199999999999887
  },
  {
    t: 1582846200,
    v: 0.12021428571428519
  },
  {
    t: 1582855200,
    v: 0.12664285714285586
  },
  {
    t: 1582856100,
    v: 0.1307142857142846
  },
  {
    t: 1582857000,
    v: 0.12692857142857047
  },
  {
    t: 1582857900,
    v: 0.11528571428571256
  },
  {
    t: 1582858800,
    v: 0.12171428571428423
  },
  {
    t: 1582859700,
    v: 0.12657142857142695
  },
  {
    t: 1582860600,
    v: 0.1269999999999994
  },
  {
    t: 1582861500,
    v: 0.13992857142857065
  },
  {
    t: 1582862400,
    v: 0.1326428571428561
  },
  {
    t: 1582863300,
    v: 0.13407142857142723
  },
  {
    t: 1582864200,
    v: 0.1415714285714265
  },
  {
    t: 1582865100,
    v: 0.13564285714285415
  },
  {
    t: 1582866000,
    v: 0.13471428571428337
  },
  {
    t: 1582866900,
    v: 0.13542857142856843
  },
  {
    t: 1582867800,
    v: 0.1313571428571397
  },
  {
    t: 1582868700,
    v: 0.1282857142857107
  },
  {
    t: 1582869600,
    v: 0.1320714285714248
  },
  {
    t: 1582870500,
    v: 0.13257142857142515
  },
  {
    t: 1582871400,
    v: 0.12528571428571159
  },
  {
    t: 1582872300,
    v: 0.12099999999999714
  },
  {
    t: 1582873200,
    v: 0.11528571428571155
  },
  {
    t: 1582874100,
    v: 0.11199999999999781
  },
  {
    t: 1582875000,
    v: 0.1136428571428557
  },
  {
    t: 1582875900,
    v: 0.11514285714285576
  },
  {
    t: 1582876800,
    v: 0.11635714285714219
  },
  {
    t: 1582877700,
    v: 0.11849999999999941
  },
  {
    t: 1582878600,
    v: 0.12285714285714176
  },
  {
    t: 1582879500,
    v: 0.12307142857142749
  },
  {
    t: 1582880400,
    v: 0.12249999999999923
  },
  {
    t: 1582881300,
    v: 0.12871428571428517
  },
  {
    t: 1582882200,
    v: 0.12578571428571397
  },
  {
    t: 1582883100,
    v: 0.12964285714285698
  },
  {
    t: 1582884000,
    v: 0.12871428571428517
  },
  {
    t: 1582884900,
    v: 0.1335714285714289
  },
  {
    t: 1582885800,
    v: 0.13250000000000028
  },
  {
    t: 1582886700,
    v: 0.12564285714285717
  },
  {
    t: 1582887600,
    v: 0.11985714285714266
  },
  {
    t: 1582888500,
    v: 0.11757142857142863
  },
  {
    t: 1582889400,
    v: 0.11278571428571382
  },
  {
    t: 1582890300,
    v: 0.11292857142857164
  },
  {
    t: 1582891200,
    v: 0.10728571428571497
  },
  {
    t: 1582892100,
    v: 0.10978571428571574
  },
  {
    t: 1582893000,
    v: 0.11257142857143013
  },
  {
    t: 1582893900,
    v: 0.10950000000000212
  },
  {
    t: 1582894800,
    v: 0.10957142857143001
  },
  {
    t: 1582895700,
    v: 0.10900000000000176
  },
  {
    t: 1582896600,
    v: 0.11171428571428724
  },
  {
    t: 1582897500,
    v: 0.11178571428571615
  },
  {
    t: 1582898400,
    v: 0.11692857142857349
  },
  {
    t: 1582899300,
    v: 0.11792857142857319
  },
  {
    t: 1582900200,
    v: 0.12464285714285848
  },
  {
    t: 1582901100,
    v: 0.13178571428571523
  },
  {
    t: 1582902000,
    v: 0.14428571428571502
  },
  {
    t: 1582902900,
    v: 0.14992857142857272
  },
  {
    t: 1582903800,
    v: 0.15500000000000114
  },
  {
    t: 1582904700,
    v: 0.1672142857142863
  },
  {
    t: 1582905600,
    v: 0.18421428571428627
  },
  {
    t: 1582906500,
    v: 0.19085714285714264
  },
  {
    t: 1582907400,
    v: 0.20421428571428532
  },
  {
    t: 1582908300,
    v: 0.22664285714285728
  },
  {
    t: 1582909200,
    v: 0.22907142857142912
  },
  {
    t: 1582910100,
    v: 0.2267857142857141
  },
  {
    t: 1582911000,
    v: 0.22242857142857073
  },
  {
    t: 1582911900,
    v: 0.2202142857142856
  },
  {
    t: 1582912800,
    v: 0.22049999999999922
  },
  {
    t: 1582913700,
    v: 0.21214285714285705
  },
  {
    t: 1582914600,
    v: 0.2097857142857141
  },
  {
    t: 1582915500,
    v: 0.20185714285714138
  },
  {
    t: 1582916400,
    v: 0.20049999999999915
  },
  {
    t: 1582917300,
    v: 0.183642857142857
  },
  {
    t: 1582918200,
    v: 0.19185714285714234
  }
]
