import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isDefined, isNotDefined } from '../../libs/react-stockcharts/lib/utils'
import { urls } from '../locators/urls'

export function isEllipsisActive<T extends HTMLElement | null>(element: T): boolean {
  if (element == null) throw "Invalid HTML element. It's impossible to check ellipsis of null"

  return element.offsetWidth < element.scrollWidth
}

export function formatDateWithIgnoredLocale(date: string, format: string) {
  return moment(date).utcOffset(0, false).format(format)
}

export const translate = (id: string, defaultMessage: string): React.ReactNode => {
  return <FormattedMessage id={id} defaultMessage={defaultMessage} />
}

export function isEmptyArray(array: Array<any> | string): boolean {
  return array.length === 0
}

export function isNotEmptyArray(array: Array<any> | string): boolean {
  return !isEmptyArray(array)
}

export function isTargetIdInDOM(id: string): boolean {
  return isDefined(document.getElementById(id))
}

export function openUrlInNewTab(url: string) {
  const modifiedUrl = adjustUrl(url)

  window.open(modifiedUrl, '_blank')
}

export function adjustUrl(url: string) {
  if (window.location.href.includes('fto-staging')) {
    if (url.includes(urls.ftHomePage)) {
      url = url.replace(urls.ftHomePage, `temp.${urls.ftHomePage}`)
    } else if (url.includes(urls.ftHomePageJP)) {
      url = url.replace(urls.ftHomePageJP, `temp.${urls.ftHomePageJP}`)
    }
  }

  if (!url.includes('http')) {
    url = `https://${url}`
  }

  return url
}

export function stringReplaceAll(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement)
}

export function isElementVisibleInVerticalScroll(
  elementRef: HTMLElement | null,
  scrollAreaRef: HTMLElement | null
): boolean {
  if (isNotDefined(elementRef) || isNotDefined(scrollAreaRef)) {
    return false
  }

  return isDomRectInsideAnother(elementRef!.getBoundingClientRect(), scrollAreaRef!.getBoundingClientRect())
}

export function isDomRectInsideAnother(innerRect: DOMRect, borderRect: DOMRect): boolean {
  return innerRect.top >= borderRect.top && innerRect.bottom <= borderRect.bottom
}

export function isArraysEqualByField(lhs: Array<object>, rhs: Array<object>, field: string): boolean {
  if (lhs.length !== rhs.length) {
    return false
  }

  let i = lhs.length
  while (i > 0 && i--) {
    if (rhs[i] && rhs[i] && rhs[i][field] !== rhs[i][field]) {
      return false
    }
  }
  return true
}
