import axios from 'axios'
import { last } from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import { isDefined, isNotDefined } from '../../libs/react-stockcharts/lib/utils'
import { DEFAULT_CHART_TOOLS } from '../../redux/graphics/types'
import { SaveActions } from '../../redux/saves/SaveSlice'
import { store } from '../../redux/store/store'
import { convertToSavableWebTester } from '../../redux/webTester/webTesterSlice.types'
import getLoadingLimitInSeconds from '../../services/DataServices/DataLoadingLimitsService'
import PreloadDataService from '../../services/DataServices/PreloadDataService'
import { LessonEventType } from '../../services/TelemetryService/telemetryTypes'
import { demoTestLimitationsMessage, TestingSpeed } from '../../utils/constants'
import { marketingCourse } from '../../utils/defaultData/marketingCourse'
import { marketingCourseJa } from '../../utils/defaultData/marketingCourseJa'
import { marketingCourseMobile } from '../../utils/defaultData/marketingCourseMobile'
import { marketingCourseMobileJa } from '../../utils/defaultData/marketingCourseMobileJa'
import {
  AccountChangeType,
  FtControlName,
  GraphicToolType,
  LocalStorage,
  ProjectName,
  RunMode,
  TickSize,
  Urls,
  WebTesterLocale,
  WindowAlignment
} from '../../utils/enums'
import { ALL_DEFAULT_TIMEFRAMES, COMMON_DATE_FORMAT } from '../../utils/helpers/constants'
import { formatDateWithIgnoredLocale } from '../../utils/helpers/functions'
import { IChart } from '../../utils/interfaces/IChart.interface'
import { IGraphicTool } from '../../utils/interfaces/IGraphics'
import { IBar, IHistoricalData, ILastBarIndexes, IVisibleData } from '../../utils/interfaces/IHistoricalData.interface'
import { IIndicatorValue } from '../../utils/interfaces/IIndicator.interface'
import { INote } from '../../utils/interfaces/INote.interface'
import { IAccountHistory } from '../../utils/interfaces/IOrder.interface'
import { ITutorial, ITutorialStep } from '../../utils/interfaces/ITutorial.interface'
import { ID } from '../../utils/locators/ids'
import {
  basicCourseDemoCharts,
  basicCourseDemoChartTimeFrames,
  basicCourseDemoCurrentBars,
  basicCourseDemoEntireData,
  basicCourseDemoLastBarIndexes,
  basicCourseDemoNewsRecords,
  basicCourseDemoProject,
  basicCourseDemoVisibleData
} from '../../utils/projects/basicCoursesProject'
import {
  defaultCharts,
  defaultChartTimeFrames,
  defaultCurrentBars,
  defaultEntireData,
  defaultLastBarIndexes,
  defaultProject,
  defaultVisibleData
} from '../../utils/projects/defaultProject'
import {
  demoCharts,
  demoChartTimeFrames,
  demoCurrentBars,
  demoEntireData,
  demoLastBarIndexes,
  demoNewsRecords,
  demoProject,
  demoVisibleData
} from '../../utils/projects/demoProject'
import { getData } from '../../utils/requests/dataRequests'
import { saveProject } from '../../utils/requests/projectsRequests'
import { getTutorial } from '../../utils/requests/tutorialsRequests'
import { getBaseServerUrl } from '../../utils/requests/utils'
import { isTickDataLoading, isTimeframeDataLoading } from '../chartContent/chart/chartUtils/chartUtils'
import { handleTutorialConversion, onNextStep } from '../topMenu/tabs/coursesTab/coursesUtils'
import { convertLanguageNameToIndex } from '../topMenu/topMenuUtils/topMenuUtils'
import { getMiddleRange, parseServerStyleData } from './helpers/initialState'
import { WebTesterProps, WebTesterState } from './helpers/interface'
import { WebTester } from './WebTester'
import isThereNextStep, {
  addGraphicToolToChart,
  addOneBar,
  calculateAccountInfo,
  calculateOperationsRelatedToMarketOrders,
  checkTutorialTriggers,
  convertStringDateToUnix,
  convertUnixToString,
  deleteOneBar,
  demoLimitationsToast,
  downloadIndicatorAndMap,
  editGraphicTool,
  findBarIndex,
  getBarAfterLastDate,
  getCalcucaltedTestingSpeed,
  getChartTimeFrames,
  getCurrencyMultiplier,
  getDateFrom,
  getInitAccountInfo,
  getLastBarDate,
  getNewEntireDataWithIndicatorField,
  getNewsRecords,
  getNewVisibleDataAndBarIndex,
  getNewVisibleDataWithIndicatorField,
  getNoHashtagTargetId,
  getNumbersFromString,
  getProjectFromLocalStorage,
  getValidTickSize,
  isAutoSaveUsed,
  isEndOfProject,
  isNotStepAvailable,
  isVisibleDataEqualEntireData,
  isWaitingModalOpen,
  loadDataForTickSize,
  populateCurrentBars,
  preloadBufferData,
  saveProjectOnClose,
  sleep,
  syncronizeSavedWebTester,
  updateAccountInfo,
  updateBalance,
  updateMarkersDataOnTimeframeChange,
  updateStepOrFinishCourse
} from './webTesterUtils'

const hour: number = 3600000

let handleKeyUpFunc: (event: KeyboardEvent) => void
let handleKeyPressFunc: (event: KeyboardEvent) => void
let updateDimensionsFunc: () => void
let handleButtonClickFunc: (event: MouseEvent | TouchEvent) => void
let handleTabOrBrowserClosedFunc: (event: BeforeUnloadEvent) => void

//#region React handlers
export const onComponentDidMount = async (webTester: WebTester) => {
  webTester.telemetryService?.sendFtoLaunched(webTester.props.language)
  store.dispatch(
    SaveActions.runProjectWithSave(async () => {
      await startApp(webTester)
      await startDemonstration(webTester)
      setCurrentProject(webTester)
      handleEvents(webTester)
      addEventListeners()
      // startDemoApp(webTester)
      handleNews(webTester)
    })
  )
  // webTester.startApp()
  // startMarketingCourse(webTester)
}

const backgroundDataLoading = (webTester: WebTester) => {
  const { charts } = webTester.props.graphs

  charts.forEach((chart) => {
    ALL_DEFAULT_TIMEFRAMES.forEach(async (timeframe) => {
      // await DataService.loadData(chart.symbol, timeframe, webTester.state.dateRange[0], webTester.state.dateRange[1])
    })
  })
}

const startDemonstration = async (webTester: WebTester) => {
  if (webTester.props.runMode === RunMode.MARKETING) {
    if (webTester.userIdentityService.isDemoModalShown()) {
      await startMarketingCourse(webTester)
      return
    }

    webTester.props.toggleDemonstrationModal()
  }
}

export function setInitDeposit(dateFrom: string, webTester: WebTester) {
  const initDepositOrder: IAccountHistory = {
    openTime: dateFrom,
    openPrice: 0.0,
    closeTime: dateFrom,
    closePrice: 0.0,
    swap: 0,
    commission: 0,
    points: 0,
    profit: 10000.0,
    ticket: 0,
    symbol: '',
    type: AccountChangeType.Deposit,
    lots: 0.0,
    sl: 0,
    tp: 0,
    comment: 'Initial deposit',
    barIndex: 0
  }

  webTester.props.saveClosedOrder({ closedOrder: initDepositOrder, timeframe: 1 })
}

const loadProject = async (webTester: WebTester, projectName: ProjectName) => {
  if (webTester.props.graphs.projectName === projectName) return

  webTester.props.cleanOrdersState()
  webTester.props.cleanGraphsState()
  webTester.props.setProjectName(projectName)

  switch (projectName) {
    case ProjectName.MARKETING: {
      webTester.props.setCharts({ charts: [defaultCharts[0], demoCharts[1]], projectName: ProjectName.MARKETING })
      webTester.props.setProject(demoProject)
      webTester.props.toggleGraphicPanel()
      webTester.props.setBottomMenuTab(2)
      webTester.props.setLeftMenuTab(1)
      webTester.props.setTickSize(TickSize.M5)
      webTester.setState({
        ...webTester.state,
        accountInfo: getInitAccountInfo(projectName),
        visibleData: parseServerStyleData(
          {
            ...demoVisibleData,
            EURUSD5: defaultVisibleData.EURUSD5
          },
          ProjectName.MARKETING
        ),
        entireData: parseServerStyleData(
          {
            ...demoEntireData,
            EURUSD5: defaultEntireData.EURUSD5
          },
          ProjectName.MARKETING
        ),
        currentBars: {
          ...demoCurrentBars,
          EURUSD5: defaultCurrentBars.EURUSD5
        },
        lastBarIndexes: {
          ...demoLastBarIndexes,
          EURUSD5: defaultLastBarIndexes.EURUSD5
        },
        newsRecords: demoNewsRecords,
        chartTimeFrames: [...demoChartTimeFrames, defaultChartTimeFrames[0]],
        currentProject: demoProject
      })
      setInitDeposit(getDateFrom(ProjectName.MARKETING), webTester)
      break
    }
    case ProjectName.DEFAULT: {
      webTester.props.setCharts({ charts: defaultCharts })
      webTester.props.setProject(defaultProject)
      webTester.props.setTickSize(TickSize.AUTO)
      setInitDeposit(getDateFrom(ProjectName.DEFAULT), webTester)
      break
    }
    case ProjectName.BASIC_COURSES: {
      webTester.props.setCharts({ charts: basicCourseDemoCharts, projectName: ProjectName.MARKETING })
      webTester.props.setProject(basicCourseDemoProject)
      webTester.props.setTickSize(TickSize.H1)
      if (webTester.props.panels.graphicPanel) {
        webTester.props.toggleGraphicPanel()
      }
      webTester.props.setBottomMenuTab(0)
      webTester.props.setLeftMenuTab(1)
      webTester.props.panels.graphicPanel && webTester.props.toggleGraphicPanel()
      webTester.props.toggleSingleActiveChart()
      webTester.setState({
        ...webTester.state,
        accountInfo: getInitAccountInfo(projectName),
        visibleData: parseServerStyleData(basicCourseDemoVisibleData, ProjectName.BASIC_COURSES),
        entireData: parseServerStyleData(basicCourseDemoEntireData, ProjectName.BASIC_COURSES),
        currentBars: basicCourseDemoCurrentBars,
        lastBarIndexes: basicCourseDemoLastBarIndexes,
        newsRecords: basicCourseDemoNewsRecords,
        chartTimeFrames: basicCourseDemoChartTimeFrames,
        currentProject: basicCourseDemoProject,
        dateRange: [basicCourseDemoProject.FromDate, basicCourseDemoProject.ToDate],
        middleRange: getMiddleRange([basicCourseDemoProject.FromDate, basicCourseDemoProject.ToDate])
      })
      setInitDeposit(getDateFrom(ProjectName.BASIC_COURSES), webTester)
      break
    }
    default:
      console.warn('there is no project with that name')
      return
  }
}

export const onComponentWillUnmount = () => {
  removeEventListeners()
}

export const onComponentDidUpdate = async (
  webTester: WebTester,
  prevProps: WebTesterProps,
  prevState: WebTesterState
) => {
  // if (prevProps.settings.currentProjectId !== webTester.props.settings.currentProjectId) {
  //   await startApp(webTester)
  // }

  if (!webTester.state.isLoading) {
    // save process
    if (webTester.props.webTesterRedux.needSave) {
      webTester.props.copyWebTesterToSlice({ state: convertToSavableWebTester(prevState) })
    }
    if (!webTester.props.webTesterRedux.isSynced && !webTester.props.webTesterRedux.isSyncing) {
      await syncronizeSavedWebTester(webTester)
    }
    const {
      orders: { market, accountHistory },
      graphs: {
        currentChart: { symbol, timeFrame }
      }
    } = webTester.props

    const {
      visibleData,
      accountInfo: { balance, lotSize, leverage },
      currentBars
    } = webTester.state

    if (
      prevState.visibleData !== webTester.state.visibleData ||
      prevProps.orders.market.length !== webTester.props.orders.market.length
    ) {
      updateAccountInfo(webTester)
    }

    if (prevProps.orders.accountHistory !== accountHistory) {
      const newBalance: number = updateBalance(balance, accountHistory)

      const [points, profit, totalProfit] = calculateOperationsRelatedToMarketOrders(
        webTester.state.currentBars,
        market
      )

      const close: number = currentBars[symbol + timeFrame].close
      const currencyMultiplier: number = getCurrencyMultiplier(symbol, close)

      const [equity, margin, freeMargin, marginLevel] = calculateAccountInfo(
        newBalance,
        totalProfit,
        market,
        lotSize,
        leverage,
        currencyMultiplier
      )

      webTester.setState((prevState) => ({
        points,
        profit,
        accountInfo: {
          ...prevState.accountInfo,
          balance: newBalance,
          equity,
          margin,
          freeMargin,
          marginLevel,
          totalProfit
        }
      }))
    }

    if (isDefined(webTester.state.currentBars[symbol + timeFrame])) {
      if (prevState.testIsPaused && !webTester.state.testIsPaused) {
        const dateTime: string = webTester.state.currentBars[symbol + timeFrame].date
        webTester.props.addStartTestToJournal(formatDateWithIgnoredLocale(dateTime, COMMON_DATE_FORMAT))
      }

      if (!prevState.testIsPaused && webTester.state.testIsPaused) {
        const dateTime: string = webTester.state.currentBars[symbol + timeFrame].date
        webTester.props.addPauseTestToJournal(formatDateWithIgnoredLocale(dateTime, COMMON_DATE_FORMAT))
      }
    }

    if (prevState.visibleData[symbol + timeFrame] !== webTester.state.visibleData[symbol + timeFrame]) {
      const data = visibleData[symbol + timeFrame]
      const dataLength = data ? data.length - 1 : 0

      webTester.setState({ dataLength })
    }
  }
}
//#endregion React handlers

export const setCurrentProject = (webTester: WebTester) => {
  const currentProject = getProjectFromLocalStorage()
  webTester.setState({ currentProject })

  // TODO: waiting for backend to support 'StartTestingAfterCreation' field
  // if (currentProject.StartTestingAfterCreation) {
  // 	webTester.setState({ testIsPaused: false })
  // }
}

export const handleEvents = (webTester: WebTester) => {
  updateDimensionsFunc = () => updateDimensions(webTester)
  handleButtonClickFunc = (event: MouseEvent | TouchEvent) => handleClick(webTester, event)
  handleTabOrBrowserClosedFunc = (event: BeforeUnloadEvent) => handleTabOrBrowserClosed(webTester, event)
}

export const addEventListeners = () => {
  window.addEventListener('resize', updateDimensionsFunc)
  window.addEventListener('click', handleButtonClickFunc)
  window.addEventListener('beforeunload', handleTabOrBrowserClosedFunc)
}

export const removeEventListeners = () => {
  window.removeEventListener('resize', updateDimensionsFunc)
  window.removeEventListener('click', handleButtonClickFunc)
  window.removeEventListener('beforeunload', handleTabOrBrowserClosedFunc)
}

export const startDemoApp = (webTester: WebTester) => {
  webTester.props.resetRedux()
  webTester.props.setCharts({ charts: defaultCharts })
  webTester.props.setProject(defaultProject)

  if (getBaseServerUrl() !== Urls.Beta) {
    handleDemoTestRestartInAnHour(webTester)
  }

  webTester.setState({ isLoading: false })
}

export const handleNews = (webTester: WebTester) => {
  const newsRecords = webTester.state.newsRecords.sort((a, b) => a.Time - b.Time)

  webTester.setState((prevState) => ({
    ...prevState,
    newsRecords
  }))
}

export const startMarketingCourse = async (webTester: WebTester) => {
  let tutorial

  const apiClient = axios.create({
    baseURL: getBaseServerUrl(),
    responseType: 'json',
    headers: { 'Content-Type': 'application/json' }
  })

  try {
    const response = await apiClient.get<ITutorial>(
      `/tutorials/api/EducationTutorial/${
        isMobile ? 'IntroMobileLesson' : 'IntroLesson'
      }?language=${convertLanguageNameToIndex(webTester.props.language)}`,
      {
        timeout: 2000
      }
    )
    tutorial = response.data
  } catch (e) {
    console.warn('Loading marketing course failed', e)
    if (isMobile) {
      tutorial = webTester.props.language !== WebTesterLocale.Japanese ? marketingCourseMobile : marketingCourseMobileJa
    } else {
      tutorial = webTester.props.language !== WebTesterLocale.Japanese ? marketingCourse : marketingCourseJa
    }
  }

  tutorial.Steps.map((step: ITutorialStep, index) => {
    if (index === 5) {
      step.TutorialWindow.FtControlName = FtControlName.AccountHistoryFooter
      if (!isMobile) step.TutorialWindow.WindowAlignment = WindowAlignment.TOP_LEFT
    }

    if (index === 16 && !isMobile) {
      step.TutorialWindow.FtControlName = `${ID.ChartWindowWithTabs}_1`
      return
    }

    if (isMobile) {
      if (index === 14) {
        step.TutorialWindow.FtControlName = ID.AccountHistoryMobileContent
      }
      if (index === 23) {
        step.TutorialWindow.WindowAlignment = WindowAlignment.TOP
      }
    }

    if (isNotDefined(step.TutorialWindow.FtControlName)) return

    if (step.TutorialWindow.FtControlName.includes(ID.ChartWindow)) {
      step.TutorialWindow.FtControlName = ID.ChartWindow
    }
  })

  webTester.props.saveOriginalTutorial(tutorial)
  handleTutorialConversion(webTester, tutorial)
}

// TODO: will be used when projects are ready
export const startApp = async (webTester: WebTester) => {
  if (webTester.props.runMode == RunMode.MARKETING) {
    await loadProject(webTester, ProjectName.MARKETING)
  } else if (webTester.props.runMode == RunMode.BASIC) {
    await loadProject(webTester, ProjectName.BASIC_COURSES)
    webTester.props.toggleBasicCoursesModal()
  } else if (webTester.props.runMode == RunMode.DEFAULT && !isAutoSaveUsed(webTester)) {
    await loadProject(webTester, ProjectName.DEFAULT)
    await backgroundDataLoading(webTester)
  } else {
    console.error(`Incorrect run mode ${webTester.props.runMode}`)
  }
  webTester.setState({ isLoading: false })
}

export const addChartTimeFrames = (webTester: WebTester) => {
  const chartTimeFrames = getChartTimeFrames(webTester.props.graphs.charts)

  webTester.setState({
    ...webTester.state,
    chartTimeFrames
  })
}

export const handleDemoTestRestartInAnHour = (webTester: WebTester) => {
  setTimeout(() => {
    alert(demoTestLimitationsMessage)
    handleTestRestart(webTester)
  }, hour)
}

export const saveProjectAutomatically = (webTester: WebTester, seconds: number) => {
  const { market, pending, accountHistory } = webTester.props.orders
  const notes: INote[] = []
  const { charts } = webTester.props.graphs

  setInterval(() => {
    saveProject(market, pending, accountHistory, notes, charts, localStorage.getItem(LocalStorage.CurrentFtProjectId))
  }, seconds * 1000)
}

export const handleJoyrideCallback = (webTester: WebTester, data: any) => {
  updateStepOrFinishCourse(data, webTester)
}

export const handleNewsDownload = async (webTester: WebTester) => {
  const { charts } = webTester.props.graphs
  const [start, end] = webTester.state.dateRange

  const newsRecords = await getNewsRecords(charts, start, end)

  webTester.setState({ newsRecords })
}

export const handleStepBack = async (webTester: WebTester) => {
  let visibleData = { ...webTester.state.visibleData }
  const lastBarIndexes = { ...webTester.state.lastBarIndexes }
  const { currentChart } = webTester.props.graphs
  const currentChartTimeFrame = currentChart.symbol + currentChart.timeFrame
  const { ForwardTestingOnly } = webTester.props.settings.currentProject

  if (isNotStepAvailable(visibleData, currentChartTimeFrame, lastBarIndexes, ForwardTestingOnly)) {
    return
  }

  visibleData = deleteOneBar(webTester.props, webTester.state, visibleData, currentChartTimeFrame, lastBarIndexes)

  setVisibleData(webTester, visibleData, lastBarIndexes)
}

export const setVisibleData = (webTester: WebTester, visibleData: IVisibleData, lastBarIndex: ILastBarIndexes) => {
  const {
    currentChart,
    currentChart: { symbol, timeFrame }
  } = webTester.props.graphs
  const { tickSize } = webTester.props.testing
  const { testingSpeed } = webTester.state
  const validTickSize = getValidTickSize(tickSize, testingSpeed)
  const tickSymbolTimeFrame = symbol + validTickSize
  const currentChartTimeFrame = currentChart.symbol + currentChart.timeFrame
  const lastBar = visibleData[currentChartTimeFrame][lastBarIndex[currentChartTimeFrame]]

  let currentBars = { ...webTester.state.currentBars }
  Object.keys(visibleData).forEach((k) => {
    const lastVisible = last(visibleData[k])
    currentBars[k] = {
      open: lastVisible?.open ?? 0,
      close: lastVisible?.close ?? 0,
      date: lastVisible?.date ?? '',
      barIndex: visibleData[k].length
    }
  })

  webTester.setState(
    (prevState) => ({
      ...prevState,
      visibleData: {
        ...visibleData
      },
      lastBarIndexes: {
        ...lastBarIndex
      },
      testIsChanged: {},
      currentBars: { ...currentBars },
      currentTick: {
        date: moment.utc(currentBars[tickSymbolTimeFrame].date).unix(),
        price: currentBars[tickSymbolTimeFrame].close
      }
    }),
    async () => {
      await preloadBufferData(webTester)
    }
  )
}

export const handleStepForward = async (webTester: WebTester) => {
  const { entireData } = webTester.state
  let visibleData = { ...webTester.state.visibleData }
  const lastBar = { ...webTester.state.lastBarIndexes }
  const { currentChart } = webTester.props.graphs
  const currentChartTimeFrame = currentChart.symbol + currentChart.timeFrame

  if (!entireData.hasOwnProperty(currentChartTimeFrame)) {
    console.warn(`There is no data for chart ${currentChartTimeFrame}`)
    return
  }

  const validTickSize = getValidTickSize(webTester.props.testing.tickSize, webTester.state.testingSpeed)
  if (
    isEndOfProject(
      webTester.state.dateRange[1],
      getLastBarDate(webTester, currentChart.timeFrame),
      currentChart.timeFrame,
      getLastBarDate(webTester, validTickSize),
      validTickSize
    )
  ) {
    webTester.setState({
      ...webTester.state,
      testIsPaused: true
    })

    if (!webTester.props.joyride.guideIsOn) {
      demoLimitationsToast()
    }

    return
  }

  const nextBarFunc = () => {
    if (entireData[currentChartTimeFrame][lastBar[currentChartTimeFrame] + 1]) {
      lastBar[currentChartTimeFrame] += 1
      addOneBar(
        webTester,
        visibleData,
        entireData,
        currentChartTimeFrame,
        currentChart.timeFrame,
        lastBar,
        currentChart
      )

      setVisibleData(webTester, visibleData, lastBar)
    }
  }

  if (entireData[currentChartTimeFrame].length === visibleData[currentChartTimeFrame].length) {
    webTester.props.setIsLoading(true, currentChart.timeFrame, currentChart.symbol)
    const additionalData = await getNextEntireData(webTester, currentChart.timeFrame, currentChart.symbol)
    webTester.props.setIsLoading(false, currentChart.timeFrame, currentChart.symbol)

    webTester.setState(
      (state) => ({
        ...state,
        entireData: {
          ...state.entireData,
          [currentChartTimeFrame]: state.entireData[currentChartTimeFrame].concat(additionalData)
        }
      }),
      nextBarFunc
    )
    return
  }

  nextBarFunc()
}

export const nextTick = async (webTester: WebTester) => {
  const { entireData, testingSpeed } = webTester.state
  let visibleData = { ...webTester.state.visibleData }
  const lastBar = { ...webTester.state.lastBarIndexes }
  const { currentChart, isLoadingData } = webTester.props.graphs
  const validTickSize = getValidTickSize(webTester.props.testing.tickSize, webTester.state.testingSpeed)

  const tickSymbolTimeFrame = currentChart.symbol + validTickSize
  const selectedChartSymbolTimeFrame = currentChart.symbol + currentChart.timeFrame

  if (
    isTickDataLoading(isLoadingData, currentChart.symbol, validTickSize, testingSpeed) ||
    isTimeframeDataLoading(isLoadingData, currentChart.symbol, tickSymbolTimeFrame, validTickSize)
  ) {
    return
  }

  if (!entireData.hasOwnProperty(tickSymbolTimeFrame)) {
    await loadDataForTickSize(webTester, validTickSize, currentChart, tickSymbolTimeFrame)
    return
  }

  if (
    isEndOfProject(
      webTester.state.dateRange[1],
      getLastBarDate(webTester, currentChart.timeFrame),
      currentChart.timeFrame,
      getLastBarDate(webTester, validTickSize),
      validTickSize
    )
  ) {
    webTester.setState({
      ...webTester.state,
      testIsPaused: true
    })

    if (!webTester.props.joyride.guideIsOn) {
      demoLimitationsToast()
    }

    return
  }

  const nextBarFunc = () => {
    if (entireData[tickSymbolTimeFrame][lastBar[tickSymbolTimeFrame] + 1]) {
      lastBar[tickSymbolTimeFrame] += 1

      addOneBar(webTester, visibleData, entireData, tickSymbolTimeFrame, validTickSize, lastBar, currentChart)

      setVisibleData(webTester, visibleData, lastBar)
    }
  }

  const endOfTickData = isVisibleDataEqualEntireData(entireData, visibleData, tickSymbolTimeFrame)
  const endOfTimeFrameData = isVisibleDataEqualEntireData(entireData, visibleData, selectedChartSymbolTimeFrame)

  if (endOfTickData || endOfTimeFrameData) {
    let additionalCurrentTickData: IBar[] = []
    let additionalEntireData: IBar[] = []

    if (
      PreloadDataService.isLoading[tickSymbolTimeFrame] ||
      PreloadDataService.isLoading[selectedChartSymbolTimeFrame]
    ) {
      webTester.props.setIsLoading(true, currentChart.timeFrame, currentChart.symbol)
      webTester.props.setIsLoading(true, validTickSize, currentChart.symbol)
      return
    }

    webTester.props.setIsLoading(true, validTickSize, currentChart.symbol)
    webTester.props.setIsLoading(true, currentChart.timeFrame, currentChart.symbol)
    additionalEntireData = await getNextEntireData(webTester, currentChart.timeFrame, currentChart.symbol)

    if (validTickSize !== currentChart.timeFrame) {
      additionalCurrentTickData = await getNextEntireData(webTester, validTickSize, currentChart.symbol)
    }

    webTester.setState(
      (state) => ({
        ...state,
        entireData: {
          ...state.entireData,
          [selectedChartSymbolTimeFrame]: state.entireData[selectedChartSymbolTimeFrame].concat(additionalEntireData),
          [tickSymbolTimeFrame]: state.entireData[tickSymbolTimeFrame].concat(additionalCurrentTickData)
        }
      }),
      () => {
        nextBarFunc()
      }
    )
    return
  }

  nextBarFunc()
}

export const startTest = async (webTester: WebTester) => {
  const prevTestIsChanged = webTester.state.testIsChanged
  // value if auto tickSize is selected
  const calculatedTestingSpeed = getCalcucaltedTestingSpeed(
    webTester.props.testing.tickSize,
    webTester.state.testingSpeed
  )

  const delay: string = `SPEED_${calculatedTestingSpeed}` // SPEED_10

  if (!webTester.state.testIsPaused) {
    await sleep(TestingSpeed[delay as keyof typeof TestingSpeed])
    // Avoids unnecessary function calls when we change testing speed
    if (prevTestIsChanged === webTester.state.testIsChanged && !webTester.state.isLoading) {
      await nextTick(webTester)

      if (checkTutorialTriggers(webTester)) {
        await startTest(webTester)
      }
    }
  }
}

export const handleTestRestart = (webTester: WebTester): void => {
  sliceVisibleDataAndLastBarIndex(webTester)
  cleanAccountInfo(webTester)
  webTester.props.cleanOrdersState()
  webTester.props.cleanGraphicsTools()
  setInitDeposit(getDateFrom(webTester.props.graphs.projectName as ProjectName), webTester)
  testIsStart(webTester)
}

export const testIsStart = (webTester: WebTester): void => {
  webTester.setState(
    {
      testIsPaused: false,
      testIsChanged: {}
    },
    () => startTest(webTester)
  )
}

export const sliceVisibleDataAndLastBarIndex = (webTester: WebTester): void => {
  const { visibleData, lastBarIndexes, lastBarDate } = webTester.state

  let keys = Object.keys(visibleData)

  keys.map((key) => {
    visibleData[key] = visibleData[key].slice(0, 2)
    lastBarIndexes[key] = 1
  })

  webTester.setState({
    ...webTester.state,
    visibleData: { ...visibleData },
    lastBarIndexes: { ...lastBarIndexes }
  })
}

export const cleanAccountInfo = (webTester) => {
  webTester.setState((state) => ({
    ...state,
    accountInfo: getInitAccountInfo(webTester.props.graphs.projectName)
  }))
}

export const handleStartPauseButtonToggle = (webTester: WebTester) => {
  webTester.setState(
    {
      testIsPaused: !webTester.state.testIsPaused,
      testingStarted: true,
      testIsChanged: {}
    },
    () => startTest(webTester)
  )
}

export const handleTestingSpeedSet = (webTester: WebTester, testingSpeed: number) => {
  webTester.setState(
    {
      testingSpeed,
      testIsChanged: {}
    },
    () => startTest(webTester)
  )
}

export const handleThemeSet = (webTester: WebTester, theme: string) => {
  webTester.setState({ theme })
}

export const handleIndicatorMapping = (
  webTester: WebTester,
  indicatorDataArray: IIndicatorValue[],
  indicatorNameField: string,
  chartTimeFrame: string
) => {
  // adds a new field to each bar of data (e.g. sma8 or rsi14) with its corresponding indicator value
  // TODO Can only map entire datadata and transform into visible data by lastBarIndex
  const oldVisibleData = webTester.state.visibleData[chartTimeFrame]
  const oldEntireData = webTester.state.entireData[chartTimeFrame]
  const index = getNumbersFromString(indicatorNameField)

  if (oldVisibleData === undefined) return

  const newVisibleDataWithIndicatorField = getNewVisibleDataWithIndicatorField(
    oldVisibleData,
    indicatorNameField,
    indicatorDataArray,
    index
  )

  const newEntireDataWithIndicatorField = getNewEntireDataWithIndicatorField(
    oldEntireData,
    indicatorNameField,
    indicatorDataArray,
    index
  )

  webTester.setState((prevState) => ({
    visibleData: {
      ...prevState.visibleData,
      [chartTimeFrame]: [...newVisibleDataWithIndicatorField]
    },
    entireData: {
      ...prevState.entireData,
      [chartTimeFrame]: [...newEntireDataWithIndicatorField]
    }
  }))
}

export function updateBarDate(webTester: WebTester, currentChartTimeFrame: number) {
  if (webTester.state.visibleData.hasOwnProperty(currentChartTimeFrame)) {
    const currentVisibleData = webTester.state.visibleData[currentChartTimeFrame]

    const currentBars = populateCurrentBars(webTester.state.visibleData)

    webTester.setState({
      firstBarDate: currentVisibleData[0].date,
      lastBarDate: currentVisibleData[currentVisibleData.length - 1].date,
      currentBars
    })
  }
}

async function getNextEntireData(webTester: WebTester, timeFrame: number, symbol: string) {
  const chartTimeFrame: string = symbol + timeFrame
  const chartId = webTester.props.graphs.currentChart.id
  const lastBarDate = getBarAfterLastDate(webTester, timeFrame)
  const dateStart = convertStringDateToUnix(lastBarDate)
  const desireDateEnd = moment(lastBarDate).utc().add(getLoadingLimitInSeconds(timeFrame), 'seconds').unix()
  const dateEnd = desireDateEnd > webTester.state.dateRange[1] ? webTester.state.dateRange[1] : desireDateEnd

  const entireData: IBar[] = await getData(symbol, 'Alpari', dateStart, dateEnd, timeFrame, true)

  if (entireData) {
    await downloadIndicatorAndMap(webTester, dateStart, dateEnd, entireData, symbol, timeFrame, chartId)
  }

  return []
}

async function getNextEntireDataOnTimeframeChange(webTester: WebTester, timeFrame: number, symbol: string) {
  const chartTimeFrame: string = symbol + timeFrame
  const chartId = webTester.props.graphs.currentChart.id
  const lastBarDateStr = getBarAfterLastDate(webTester, timeFrame)
  let dateStart = convertStringDateToUnix(lastBarDateStr)
  const loadingLimit = getLoadingLimitInSeconds(timeFrame)
  let desireDateEnd = webTester.state.currentTick.date + loadingLimit
  const dateEnd = desireDateEnd > webTester.state.dateRange[1] ? webTester.state.dateRange[1] : desireDateEnd

  return await loadDataFromDateToDate(
    webTester,
    dateStart,
    dateEnd,
    loadingLimit,
    symbol,
    timeFrame,
    webTester.props.graphs.currentChart.id
  )
}

export async function loadDataFromDateToDate(
  webTester: WebTester,
  dateStart: number,
  dateEnd: number,
  loadingLimit: number,
  symbol: string,
  timeFrame: number,
  chartId: string
) {
  let entireData: IBar[] = []

  if (dateEnd - dateStart < loadingLimit) {
    entireData = await getData(symbol, 'Alpari', dateStart, dateEnd, timeFrame, true)

    if (entireData) {
      await downloadIndicatorAndMap(webTester, dateStart, dateEnd, entireData, symbol, timeFrame, chartId)
    }
  } else {
    while (dateEnd - dateStart > loadingLimit) {
      const moreData = await getData(symbol, 'Alpari', dateStart, dateStart + loadingLimit, timeFrame, true)
      if (moreData) {
        await downloadIndicatorAndMap(
          webTester,
          dateStart,
          dateStart + loadingLimit,
          moreData,
          symbol,
          timeFrame,
          chartId,
          entireData.length === 0
        )
      }
      entireData.push(...moreData)

      dateStart = dateStart + loadingLimit + timeFrame
    }

    if (dateStart < dateEnd) {
      const moreData = await getData(symbol, 'Alpari', dateStart, dateEnd, timeFrame, true)

      if (moreData) {
        await downloadIndicatorAndMap(
          webTester,
          dateStart,
          dateEnd,
          moreData,
          symbol,
          timeFrame,
          chartId,
          entireData.length === 0
        )
      }
      entireData.push(...moreData)
    }
  }

  return entireData
}

async function loadDataForTimeFrame(
  webTester: WebTester,
  symbol: string,
  timeFrame: number,
  chartTimeFrame: string,
  tickSize: number,
  prevTimeFrame: number
) {
  if (isNotDefined(webTester.props.graphics.chartTools[symbol + timeFrame])) {
    webTester.props.updateChartTools({ symbol, timeframe: timeFrame, tools: DEFAULT_CHART_TOOLS })
  }

  webTester.props.setIsLoading(true, timeFrame, symbol)

  const emptyBar = {
    close: 0,
    date: '',
    high: 0,
    low: 0,
    open: 0,
    volume: 0
  }

  webTester.setState((prevState) => ({
    ...prevState,
    visibleData: {
      ...prevState.visibleData,
      [chartTimeFrame]: [emptyBar]
    },
    lastBarIndexes: {
      ...prevState.lastBarIndexes,
      [chartTimeFrame]: 0
    }
  }))

  const entireData = await getNextEntireDataOnTimeframeChange(webTester, timeFrame, symbol)

  if (entireData && entireData.length > 0) {
    fillVisibleData(webTester, entireData, tickSize, timeFrame, chartTimeFrame, symbol, prevTimeFrame)
  } else {
    toast.error('Unable to Fetch Historical Data')
  }
  webTester.props.setIsLoading(false, timeFrame, symbol)
}

export const handleTimeFrameDataGet = async (webTester: WebTester, { id, symbol, timeFrame }: IChart) => {
  const prevTimeFrame = webTester.props.graphs.currentChart.timeFrame
  webTester.props.setTimeFrame(timeFrame)

  if (
    webTester.props.graphs.isLoadingData[symbol] !== undefined &&
    webTester.props.graphs.isLoadingData[symbol][timeFrame]
  ) {
    return
  }

  const validTickSize = getValidTickSize(webTester.props.testing.tickSize, webTester.state.testingSpeed)

  const symbolTimeFrame: string = symbol + timeFrame
  const tickSymbolTimeFrame: string = symbol + validTickSize

  const { entireData, visibleData, currentTick } = webTester.state

  if (!entireData.hasOwnProperty(tickSymbolTimeFrame)) {
    await loadDataForTickSize(webTester, validTickSize, webTester.props.graphs.currentChart, tickSymbolTimeFrame)
  }

  if (!entireData.hasOwnProperty(symbolTimeFrame)) {
    await loadDataForTimeFrame(webTester, symbol, timeFrame, symbolTimeFrame, validTickSize, prevTimeFrame)
    return
  }

  const lastEntireDataBarDate = convertStringDateToUnix(last(entireData[symbolTimeFrame])?.date!) + timeFrame * 60
  const lastVisibleBarDate = convertStringDateToUnix(last(visibleData[symbolTimeFrame])?.date!)

  if (lastEntireDataBarDate < currentTick.date) {
    await loadDataForTimeFrame(webTester, symbol, timeFrame, symbolTimeFrame, validTickSize, prevTimeFrame)
    return
  }

  if (lastVisibleBarDate > currentTick.date) {
    return
  }

  const data = webTester.state.entireData[symbolTimeFrame]

  fillVisibleData(webTester, data, validTickSize, timeFrame, symbolTimeFrame, symbol, prevTimeFrame)
}

export function fillVisibleData(
  webTester: WebTester,
  entireData: IBar[],
  tickSize: number,
  timeFrame: number,
  chartTimeFrame: string,
  symbol: string,
  prevTimeFrame: number
) {
  const lastBarIndex: number = findBarIndex(
    entireData,
    tickSize,
    timeFrame,
    webTester.state.lastBarIndexes[symbol + tickSize],
    convertUnixToString(webTester.state.currentTick.date)
  )

  const visibleData: IBar[] = entireData.slice(0, lastBarIndex + 1)

  visibleData[visibleData.length - 1].close = webTester.state.currentTick.price

  webTester.setState((prevState) => ({
    visibleData: {
      ...prevState.visibleData,
      [chartTimeFrame]: visibleData
    },
    entireData: {
      ...prevState.entireData,
      [chartTimeFrame]: entireData
    },
    lastBarIndexes: {
      ...prevState.lastBarIndexes,
      [chartTimeFrame]: lastBarIndex
    },
    chartTimeFrames: [
      ...prevState.chartTimeFrames,
      {
        symbol,
        timeFrame
      }
    ],
    currentBars: {
      ...prevState.currentBars,
      [chartTimeFrame]: last(visibleData)!
    }
  }))

  updateBarDate(webTester, +chartTimeFrame)

  updateMarkersDataOnTimeframeChange(
    webTester.state.visibleData[symbol + timeFrame],
    webTester.props.orders.orderMarkers,
    timeFrame,
    prevTimeFrame,
    symbol
  )
}

export const handleResize = (webTester: WebTester) => {
  setTimeout(() => {
    const visibleData: IHistoricalData = { ...webTester.state.visibleData }
    webTester.setState({ ...webTester.state, visibleData })
  }, 0)
}

export const updateDimensions = (webTester: WebTester) => {
  handleResize(webTester)
}

export const handleClick = (webTester: WebTester, event) => {
  const { joyride, orders } = webTester.props
  const target: string = getNoHashtagTargetId(joyride.guideSteps[joyride.step])
  if (joyride.guideIsOn && (isThereNextStep(joyride, event.target.id, target) || orders.pipetteModeIsActive)) {
    const {
      step,
      guideSteps,
      originalTutorial: { Steps }
    } = webTester.props.joyride
    const nextStepIndex = step + 1 + +orders.pipetteModeIsActive
    const waitModal = isWaitingModalOpen(event.target.id) || orders.pipetteModeIsActive
    if (waitModal) {
      webTester.props.setActionOnModalOpened(true)
    } else {
      onNextStep(webTester, Steps, nextStepIndex, guideSteps)
    }
  }
}

export const handleTabOrBrowserClosed = (webTester: WebTester, event: BeforeUnloadEvent) => {
  if (webTester.props.joyride.repeatIsPressed) {
    return
  }

  if (store.getState().saves.clean) {
    return
  }

  saveProjectOnClose(webTester)

  if (webTester.props.runMode === RunMode.DEFAULT) return

  event.preventDefault()

  event.returnValue = 'Do you really want to close?'

  if (webTester.props.joyride.guideIsOn) {
    webTester.telemetryService?.sendLessonEvent(
      webTester.props.joyride,
      webTester.props.language,
      LessonEventType.LESSON_CANCELED
    )
  }

  return event.returnValue
}

export const handleTutorialFetchById = async (webTester: WebTester, id: string, position: number) => {
  await loadProject(webTester, ProjectName.BASIC_COURSES)

  let tutorial = await getTutorial(id, webTester.props.language)
  if (tutorial) {
    tutorial.Position = position + 1
    tutorial.Steps.map((each) => {
      if (each.TutorialWindow.FtControlName && each.TutorialWindow.FtControlName.includes('ChartWindow')) {
        each.TutorialWindow.FtControlName = 'ChartWindow'
      }

      return each
    })

    webTester.props.saveOriginalTutorial(tutorial)
    handleTutorialConversion(webTester, tutorial)
  }
}

export const handleJumpTo = (webTester: WebTester, date: string) => {
  if (!date || isNotDefined(date)) return

  const { entireData, visibleData, lastBarIndexes, currentBars } = webTester.state

  getNewVisibleDataAndBarIndex(
    webTester.state.chartTimeFrames,
    date,
    entireData,
    visibleData,
    lastBarIndexes,
    currentBars
  )

  webTester.setState({
    visibleData,
    lastBarIndexes,
    currentBars
  })

  if (!webTester.state.testIsPaused) {
    handleStartPauseButtonToggle(webTester)
  }
}

export function handleChartToolAdd(webTester: WebTester, tool: IGraphicTool, graphicToolType: GraphicToolType) {
  const { timeFrame, symbol } = webTester.props.graphs.currentChart

  addGraphicToolToChart(webTester, tool, timeFrame, symbol, graphicToolType)
}

export function handleChartToolEdit(
  webTester: WebTester,
  tool: IGraphicTool,
  graphicToolType: GraphicToolType,
  index: number
) {
  const { timeFrame, symbol } = webTester.props.graphs.currentChart

  editGraphicTool(webTester, tool, index, graphicToolType, timeFrame, symbol)
}
