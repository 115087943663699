export interface ITutorialButton {
	buttonNext: {
		display: 'block' | 'none'
		backgroundColor: string
		borderRadius: number
		color: string
	}
	buttonClose?: {
		display: 'block' | 'none'
	}
}

export interface ICourseImage {
	path: string
	alt: string
}

export enum ImageAlignment {
	Left = 'left',
	Right = 'right',
	Top = 'top',
	Bottom = 'bottom'
}

export interface IOverlay {
	overlay?: {
		pointerEvents: 'auto'
	}
}

export interface IWindowScale {
	options?: {
		width: number
		height: number
	},
	tooltip?: {
		width: number
		height: number
	}
}
