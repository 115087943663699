import axios from 'axios'
import { INews } from '../interfaces/INews.interface'
import { getBaseServerUrl } from './utils'

const apiClient = axios.create({
	baseURL: getBaseServerUrl() + 'data/api/HistoricalNews',
	responseType: 'json',
	headers: { 'Content-Type': 'application/json' }
})

export const getNews = async (
	symbol: string,
	start: number,
	end: number
): Promise<INews[]> => {
	const params = {
		symbol,
		start,
		end
	}

	const response = await apiClient.get<INews[]>('/news', { params })
	const news = response.data

	if (news) return news

	throw new Error('Unable to fetch news!')
}
