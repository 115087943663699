export const defaultBearsPower = [
  {
    t: 1580680800,
    v: -0.00022999999999995246
  },
  {
    t: 1580684400,
    v: -0.00010285714285718228
  },
  {
    t: 1580688000,
    v: -0.00026244897959171176
  },
  {
    t: 1580691600,
    v: -0.0004963848396501991
  },
  {
    t: 1580695200,
    v: -0.0006740441482713244
  },
  {
    t: 1580698800,
    v: -0.0018920378413755934
  },
  {
    t: 1580702400,
    v: -0.0015688895783219081
  },
  {
    t: 1580706000,
    v: -0.00100047678141868
  },
  {
    t: 1580709600,
    v: -0.001571837241216123
  },
  {
    t: 1580713200,
    v: -0.002508717635328006
  },
  {
    t: 1580716800,
    v: -0.004626043687424142
  },
  {
    t: 1580720400,
    v: -0.0067608945892205785
  },
  {
    t: 1580724000,
    v: -0.00782933821933196
  },
  {
    t: 1580727600,
    v: -0.007819432759427425
  },
  {
    t: 1580731200,
    v: -0.006153799508080704
  },
  {
    t: 1580734800,
    v: -0.005850399578354759
  },
  {
    t: 1580738400,
    v: -0.007270342495732773
  },
  {
    t: 1580742000,
    v: -0.007958864996342241
  },
  {
    t: 1580745600,
    v: -0.0071761699968648784
  },
  {
    t: 1580749200,
    v: -0.007268145711598395
  },
  {
    t: 1580752800,
    v: -0.006989839181370083
  },
  {
    t: 1580756400,
    v: -0.00584129072688877
  },
  {
    t: 1580760000,
    v: -0.005036820623047555
  },
  {
    t: 1580763600,
    v: -0.004202989105469346
  },
  {
    t: 1580767200,
    v: -0.003911133518973653
  },
  {
    t: 1580770800,
    v: -0.0028381144448346163
  },
  {
    t: 1580774400,
    v: -0.0028869552384296338
  },
  {
    t: 1580778000,
    v: -0.0031659616329395845
  },
  {
    t: 1580781600,
    v: -0.002747967113948313
  },
  {
    t: 1580785200,
    v: -0.001691114669098548
  },
  {
    t: 1580788800,
    v: -0.000990955430655971
  },
  {
    t: 1580792400,
    v: -0.00034081894056248174
  },
  {
    t: 1580796000,
    v: -0.0012135590919106765
  },
  {
    t: 1580799600,
    v: -0.005735907793066319
  },
  {
    t: 1580803200,
    v: -0.005152206679771343
  },
  {
    t: 1580806800,
    v: -0.0018976057255182255
  },
  {
    t: 1580810400,
    v: 0.000027766520984240373
  },
  {
    t: 1580814000,
    v: 0.00017951416084360794
  },
  {
    t: 1580817600,
    v: 0.0004952978521517437
  },
  {
    t: 1580821200,
    v: 0.0002688267304158387
  },
  {
    t: 1580824800,
    v: 0.00024042291178516528
  },
  {
    t: 1580828400,
    v: 0.0008217910672443374
  },
  {
    t: 1580832000,
    v: 0.00042867805763813216
  },
  {
    t: 1580835600,
    v: 0.001066009763689646
  },
  {
    t: 1580839200,
    v: 0.0010694369403054793
  },
  {
    t: 1580842800,
    v: 0.0014080888059762042
  },
  {
    t: 1580846400,
    v: 0.000659790405122429
  },
  {
    t: 1580850000,
    v: 0.00020267749010494107
  },
  {
    t: 1580853600,
    v: -0.0000891335799100279
  },
  {
    t: 1580857200,
    v: -0.00024354306849416751
  },
  {
    t: 1580860800,
    v: 0.000021248798433459726
  },
  {
    t: 1580864400,
    v: -0.0007860724584856094
  },
  {
    t: 1580868000,
    v: -0.00124091925013059
  },
  {
    t: 1580871600,
    v: -0.0007679307858261541
  },
  {
    t: 1580875200,
    v: -0.0005225121021368295
  },
  {
    t: 1580878800,
    v: -0.000750724658974411
  },
  {
    t: 1580882400,
    v: -0.0006563354219779605
  },
  {
    t: 1580886000,
    v: -0.0014997160759813166
  },
  {
    t: 1580889600,
    v: 0.0006988147920161669
  },
  {
    t: 1580893200,
    v: 0.00011755553601400237
  },
  {
    t: 1580896800,
    v: 0.0011821904594406796
  },
  {
    t: 1580900400,
    v: 0.000844734679520398
  },
  {
    t: 1580904000,
    v: 0.00019977258244607832
  },
  {
    t: 1580907600,
    v: -0.004418766357903392
  },
  {
    t: 1580911200,
    v: -0.006883228306774347
  },
  {
    t: 1580914800,
    v: -0.006142767120092429
  },
  {
    t: 1580918400,
    v: -0.0035423718172220564
  },
  {
    t: 1580922000,
    v: -0.0016391758433331738
  },
  {
    t: 1580925600,
    v: -0.0012407221514285638
  },
  {
    t: 1580929200,
    v: -0.0022391904155101283
  },
  {
    t: 1580932800,
    v: -0.0020907346418657102
  },
  {
    t: 1580936400,
    v: -0.0014649154073134163
  },
  {
    t: 1580940000,
    v: -0.0013484989205545528
  },
  {
    t: 1580943600,
    v: -0.000961570503332343
  },
  {
    t: 1580947200,
    v: -0.0015242032885705026
  },
  {
    t: 1580950800,
    v: -0.0016193171044889265
  },
  {
    t: 1580954400,
    v: -0.0014265575181333645
  },
  {
    t: 1580958000,
    v: -0.0012356207298285238
  },
  {
    t: 1580961600,
    v: -0.0013448177684243223
  },
  {
    t: 1580965200,
    v: -0.0020027009443637223
  },
  {
    t: 1580968800,
    v: -0.0011123150951690697
  },
  {
    t: 1580972400,
    v: -0.0008619843672876826
  },
  {
    t: 1580976000,
    v: -0.0012202723148180272
  },
  {
    t: 1580979600,
    v: -0.0019073762698440522
  },
  {
    t: 1580983200,
    v: -0.0021820368027234416
  },
  {
    t: 1580986800,
    v: -0.002770317259477295
  },
  {
    t: 1580990400,
    v: -0.002218843365266121
  },
  {
    t: 1580994000,
    v: -0.0029090085987995007
  },
  {
    t: 1580997600,
    v: -0.0020562930846852456
  },
  {
    t: 1581001200,
    v: -0.004528251215444534
  },
  {
    t: 1581004800,
    v: -0.003957072470381018
  },
  {
    t: 1581008400,
    v: -0.0030103478317551335
  },
  {
    t: 1581012000,
    v: -0.0028274409986472904
  },
  {
    t: 1581015600,
    v: -0.00284923514169777
  },
  {
    t: 1581019200,
    v: -0.002323630121455089
  },
  {
    t: 1581022800,
    v: -0.0019202543898186875
  },
  {
    t: 1581026400,
    v: -0.001690218048415959
  },
  {
    t: 1581030000,
    v: -0.0012801868986422882
  },
  {
    t: 1581033600,
    v: -0.0011844459131218343
  },
  {
    t: 1581037200,
    v: -0.0006195250683902476
  },
  {
    t: 1581040800,
    v: -0.0003595929157629296
  },
  {
    t: 1581044400,
    v: -0.000051079642082507704
  },
  {
    t: 1581048000,
    v: -0.0003437825503564973
  },
  {
    t: 1581051600,
    v: -0.0002618136145913219
  },
  {
    t: 1581055200,
    v: -0.00020155452679260222
  },
  {
    t: 1581058800,
    v: -0.0006356181658222493
  },
  {
    t: 1581062400,
    v: -0.0015419584278477672
  },
  {
    t: 1581066000,
    v: -0.0011759643667266229
  },
  {
    t: 1581069600,
    v: -0.0009622551714798533
  },
  {
    t: 1581073200,
    v: -0.0009176472898400068
  },
  {
    t: 1581076800,
    v: -0.0005936976770057889
  },
  {
    t: 1581080400,
    v: -0.0003288837231476549
  },
  {
    t: 1581084000,
    v: -0.0015961860484123847
  },
  {
    t: 1581087600,
    v: -0.00012815947006772355
  },
  {
    t: 1581091200,
    v: -0.0034469938314865
  },
  {
    t: 1581094800,
    v: -0.003407423284131239
  },
  {
    t: 1581098400,
    v: -0.003207791386398151
  },
  {
    t: 1581102000,
    v: -0.003170964045484226
  },
  {
    t: 1581105600,
    v: -0.0031165406104149973
  },
  {
    t: 1581109200,
    v: -0.002627034808927231
  },
  {
    t: 1581285600,
    v: -0.0023046012647947034
  },
  {
    t: 1581289200,
    v: -0.0018625153698239583
  },
  {
    t: 1581292800,
    v: -0.0014764417455632728
  },
  {
    t: 1581296400,
    v: -0.0009726643533398693
  },
  {
    t: 1581300000,
    v: -0.0007622837314342767
  },
  {
    t: 1581303600,
    v: -0.0006791003412294216
  },
  {
    t: 1581307200,
    v: -0.0008520860067680047
  },
  {
    t: 1581310800,
    v: 0.00014535485134170578
  },
  {
    t: 1581314400,
    v: -0.00014683869884990486
  },
  {
    t: 1581318000,
    v: -0.0025858617418712537
  },
  {
    t: 1581321600,
    v: -0.002429310064461232
  },
  {
    t: 1581325200,
    v: -0.0010808371981096965
  },
  {
    t: 1581328800,
    v: 0.0005664252587631591
  },
  {
    t: 1581332400,
    v: 0.0014040787932254606
  },
  {
    t: 1581336000,
    v: 0.0005620675370505701
  },
  {
    t: 1581339600,
    v: 0.0011803436031860848
  },
  {
    t: 1581343200,
    v: 0.0008402945170165044
  },
  {
    t: 1581346800,
    v: 0.00034453815744273086
  },
  {
    t: 1581350400,
    v: -0.0007203958650490172
  },
  {
    t: 1581354000,
    v: -0.0009160535986134644
  },
  {
    t: 1581357600,
    v: -0.0008380459416685859
  },
  {
    t: 1581361200,
    v: -0.00010118223571597973
  },
  {
    t: 1581364800,
    v: -0.000726727630613766
  },
  {
    t: 1581368400,
    v: -0.0006614808262401795
  },
  {
    t: 1581372000,
    v: -0.001005554993920077
  },
  {
    t: 1581375600,
    v: -0.0003490471376457194
  },
  {
    t: 1581379200,
    v: -0.0004063261179818589
  },
  {
    t: 1581382800,
    v: -0.00023113667255603154
  },
  {
    t: 1581386400,
    v: -0.0004309742907622205
  },
  {
    t: 1581390000,
    v: 0.000004879179346550799
  },
  {
    t: 1581393600,
    v: -0.00038010356056017436
  },
  {
    t: 1581397200,
    v: -0.0003615173376230185
  },
  {
    t: 1581400800,
    v: -0.0003898720036767944
  },
  {
    t: 1581404400,
    v: -0.0017041760031515363
  },
  {
    t: 1581408000,
    v: -0.0017878651455585093
  },
  {
    t: 1581411600,
    v: -0.0015181701247644064
  },
  {
    t: 1581415200,
    v: 0.00010013989305912574
  },
  {
    t: 1581418800,
    v: -0.0003755943773777748
  },
  {
    t: 1581422400,
    v: 0.00005663339081918295
  },
  {
    t: 1581426000,
    v: 0.0002756857635590748
  },
  {
    t: 1581429600,
    v: 0.0005691592259078337
  },
  {
    t: 1581433200,
    v: 0.0010264221936353568
  },
  {
    t: 1581436800,
    v: 0.00008979045168766575
  },
  {
    t: 1581440400,
    v: -0.0003330367556964031
  },
  {
    t: 1581444000,
    v: 0.0007716827808317284
  },
  {
    t: 1581447600,
    v: 0.0012028709549987138
  },
  {
    t: 1581451200,
    v: 0.000678175104284584
  },
  {
    t: 1581454800,
    v: 0.00021272151795814764
  },
  {
    t: 1581458400,
    v: -0.00011766727032158641
  },
  {
    t: 1581462000,
    v: 0.000561999482581621
  },
  {
    t: 1581465600,
    v: 0.0003859995564985397
  },
  {
    t: 1581469200,
    v: 0.00025799961985573105
  },
  {
    t: 1581472800,
    v: 0.0007682853884478646
  },
  {
    t: 1581476400,
    v: 0.0005342446186695771
  },
  {
    t: 1581480000,
    v: 0.0003064953874309939
  },
  {
    t: 1581483600,
    v: 0.0002855674749409065
  },
  {
    t: 1581487200,
    v: -0.0003352278786219465
  },
  {
    t: 1581490800,
    v: -0.0005230524673902615
  },
  {
    t: 1581494400,
    v: 0.0000673835993798555
  },
  {
    t: 1581498000,
    v: 0.00009918594232560807
  },
  {
    t: 1581501600,
    v: 0.001287873664850503
  },
  {
    t: 1581505200,
    v: 0.001003891712729077
  },
  {
    t: 1581508800,
    v: 0.0000033357537678746496
  },
  {
    t: 1581512400,
    v: -0.0007014264967704076
  },
  {
    t: 1581516000,
    v: -0.0014140798543746946
  },
  {
    t: 1581519600,
    v: -0.0012520684466068577
  },
  {
    t: 1581523200,
    v: 0.00012108418862277759
  },
  {
    t: 1581526800,
    v: -0.0004690706954662005
  },
  {
    t: 1581530400,
    v: -0.0005377748818282679
  },
  {
    t: 1581534000,
    v: -0.0008709498987098385
  },
  {
    t: 1581537600,
    v: -0.0009036713417511777
  },
  {
    t: 1581541200,
    v: -0.0008988611500724275
  },
  {
    t: 1581544800,
    v: -0.001177595271490528
  },
  {
    t: 1581548400,
    v: -0.001070795946991998
  },
  {
    t: 1581552000,
    v: -0.0008535393831359261
  },
  {
    t: 1581555600,
    v: -0.0007501766141164712
  },
  {
    t: 1581559200,
    v: -0.0005958656692426345
  },
  {
    t: 1581562800,
    v: -0.0007164562879222736
  },
  {
    t: 1581566400,
    v: -0.0009912482467904216
  },
  {
    t: 1581570000,
    v: -0.001312498497249015
  },
  {
    t: 1581573600,
    v: -0.0010835701404992015
  },
  {
    t: 1581577200,
    v: -0.0009659172632852098
  },
  {
    t: 1581580800,
    v: 0.0001349280600413394
  },
  {
    t: 1581584400,
    v: -0.0006129188056789303
  },
  {
    t: 1581588000,
    v: 0.00037321245227528443
  },
  {
    t: 1581591600,
    v: -0.0006001036123355341
  },
  {
    t: 1581595200,
    v: 0.00026276833228355834
  },
  {
    t: 1581598800,
    v: 0.0037666585705287137
  },
  {
    t: 1581602400,
    v: 0.0021042787747389546
  },
  {
    t: 1581606000,
    v: 0.0040479532354906755
  },
  {
    t: 1581609600,
    v: 0.00349395991613477
  },
  {
    t: 1581613200,
    v: 0.0030276799281154787
  },
  {
    t: 1581616800,
    v: 0.0028608685098134856
  },
  {
    t: 1581620400,
    v: 0.0024293158655543934
  },
  {
    t: 1581624000,
    v: 0.0016551278847609652
  },
  {
    t: 1581627600,
    v: 0.0009086810440810389
  },
  {
    t: 1581631200,
    v: 0.0001288694663550416
  },
  {
    t: 1581634800,
    v: 0.0005561738283044182
  },
  {
    t: 1581638400,
    v: 0.0004752918528323491
  },
  {
    t: 1581642000,
    v: 0.0005445358738562778
  },
  {
    t: 1581645600,
    v: 0.00044103074901991057
  },
  {
    t: 1581649200,
    v: 0.0000980263563028494
  },
  {
    t: 1581652800,
    v: 0.00011545116254518817
  },
  {
    t: 1581656400,
    v: -0.000015327574961254697
  },
  {
    t: 1581660000,
    v: 0.00022971922146175494
  },
  {
    t: 1581663600,
    v: -0.00005024066731862398
  },
  {
    t: 1581667200,
    v: -0.001253063429130119
  },
  {
    t: 1581670800,
    v: -0.0022526257963972895
  },
  {
    t: 1581674400,
    v: -0.001846536396912013
  },
  {
    t: 1581678000,
    v: -0.0012713169116389533
  },
  {
    t: 1581681600,
    v: -0.001601128781404837
  },
  {
    t: 1581685200,
    v: -0.0032881103840614045
  },
  {
    t: 1581688800,
    v: -0.002034094614909776
  },
  {
    t: 1581692400,
    v: -0.001884938241351275
  },
  {
    t: 1581696000,
    v: -0.0020770899211581817
  },
  {
    t: 1581699600,
    v: 0.00006820863900736107
  },
  {
    t: 1581703200,
    v: 0.0004027502620063128
  },
  {
    t: 1581706800,
    v: -0.00034049977542327525
  },
  {
    t: 1581710400,
    v: -0.00045757123607703676
  },
  {
    t: 1581714000,
    v: 0.0008949389405055186
  },
  {
    t: 1581890400,
    v: -0.0010471951938524349
  },
  {
    t: 1581894000,
    v: 0.00010383269098368508
  },
  {
    t: 1581897600,
    v: 0.000026142306557286332
  },
  {
    t: 1581901200,
    v: 0.000029550548477752514
  },
  {
    t: 1581904800,
    v: 0.00018104332726665007
  },
  {
    t: 1581908400,
    v: 0.00022089428051419446
  },
  {
    t: 1581912000,
    v: 0.000020766526155124865
  },
  {
    t: 1581915600,
    v: -0.0000836286918670659
  },
  {
    t: 1581919200,
    v: -0.00046739602160039695
  },
  {
    t: 1581922800,
    v: -0.0012891965899430868
  },
  {
    t: 1581926400,
    v: -0.0009393113628084482
  },
  {
    t: 1581930000,
    v: -0.0006422668824073163
  },
  {
    t: 1581933600,
    v: -0.0013062287563490038
  },
  {
    t: 1581937200,
    v: -0.0026853389340135703
  },
  {
    t: 1581940800,
    v: -0.0022745762291545013
  },
  {
    t: 1581944400,
    v: -0.0018553510535610496
  },
  {
    t: 1581948000,
    v: -0.002458872331623674
  },
  {
    t: 1581951600,
    v: -0.0018376048556774105
  },
  {
    t: 1581955200,
    v: -0.0020136613048664653
  },
  {
    t: 1581958800,
    v: -0.0012402811184568652
  },
  {
    t: 1581962400,
    v: -0.0016230981015343815
  },
  {
    t: 1581966000,
    v: -0.001805512658457964
  },
  {
    t: 1581969600,
    v: -0.001519010850106861
  },
  {
    t: 1581973200,
    v: -0.0012034378715199967
  },
  {
    t: 1581976800,
    v: -0.0011372324613028173
  },
  {
    t: 1581980400,
    v: -0.0007690563954025897
  },
  {
    t: 1581984000,
    v: -0.0014591911960593063
  },
  {
    t: 1581987600,
    v: -0.0014935924537651246
  },
  {
    t: 1581991200,
    v: -0.0012902221032273786
  },
  {
    t: 1581994800,
    v: -0.0008330475170519325
  },
  {
    t: 1581998400,
    v: -0.0008697550146159472
  },
  {
    t: 1582002000,
    v: -0.000854075726813619
  },
  {
    t: 1582005600,
    v: -0.0014534934801258004
  },
  {
    t: 1582009200,
    v: -0.0014172801258223178
  },
  {
    t: 1582012800,
    v: -0.0026233829649904283
  },
  {
    t: 1582016400,
    v: -0.0020543282557059506
  },
  {
    t: 1582020000,
    v: -0.0007951385048907689
  },
  {
    t: 1582023600,
    v: 0.0021127384243793124
  },
  {
    t: 1582027200,
    v: 0.00214091864946786
  },
  {
    t: 1582030800,
    v: 0.0007379302709724644
  },
  {
    t: 1582034400,
    v: 0.00020394023226222302
  },
  {
    t: 1582038000,
    v: -0.00005376551520397399
  },
  {
    t: 1582041600,
    v: -0.0005017990130318228
  },
  {
    t: 1582045200,
    v: -0.0015658277254557706
  },
  {
    t: 1582048800,
    v: -0.001789280907533719
  },
  {
    t: 1582052400,
    v: -0.001549383635028878
  },
  {
    t: 1582056000,
    v: -0.0013709002585959862
  },
  {
    t: 1582059600,
    v: -0.0011907716502250754
  },
  {
    t: 1582063200,
    v: -0.001092089985907263
  },
  {
    t: 1582066800,
    v: -0.0008232199879205648
  },
  {
    t: 1582070400,
    v: -0.0007527599896461901
  },
  {
    t: 1582074000,
    v: -0.0005623657054110165
  },
  {
    t: 1582077600,
    v: -0.0007334563189236309
  },
  {
    t: 1582081200,
    v: -0.0006243911305061633
  },
  {
    t: 1582084800,
    v: -0.00043519239757672246
  },
  {
    t: 1582088400,
    v: -0.0005730220550657084
  },
  {
    t: 1582092000,
    v: -0.0013411617614851323
  },
  {
    t: 1582095600,
    v: -0.0011324243669872391
  },
  {
    t: 1582099200,
    v: -0.0007663637431318815
  },
  {
    t: 1582102800,
    v: -0.0026768832083987615
  },
  {
    t: 1582106400,
    v: -0.0025158998929133425
  },
  {
    t: 1582110000,
    v: -0.0021936284796397754
  },
  {
    t: 1582113600,
    v: -0.0014273958396913144
  },
  {
    t: 1582117200,
    v: -0.002027767862592622
  },
  {
    t: 1582120800,
    v: -0.00395094388222228
  },
  {
    t: 1582124400,
    v: -0.006000809041904809
  },
  {
    t: 1582128000,
    v: -0.00447640775020397
  },
  {
    t: 1582131600,
    v: -0.004976920928746242
  },
  {
    t: 1582135200,
    v: -0.0046002179389255105
  },
  {
    t: 1582138800,
    v: -0.004684472519078886
  },
  {
    t: 1582142400,
    v: -0.0030966907306388958
  },
  {
    t: 1582146000,
    v: -0.002850020626262051
  },
  {
    t: 1582149600,
    v: -0.00308287482251024
  },
  {
    t: 1582153200,
    v: -0.002082464133580153
  },
  {
    t: 1582156800,
    v: -0.0019563978287828743
  },
  {
    t: 1582160400,
    v: -0.002321198138956815
  },
  {
    t: 1582164000,
    v: -0.002552455547677246
  },
  {
    t: 1582167600,
    v: -0.0026049618980090727
  },
  {
    t: 1582171200,
    v: -0.002001395912579307
  },
  {
    t: 1582174800,
    v: -0.0013183393536393417
  },
  {
    t: 1582178400,
    v: -0.0020000051602622193
  },
  {
    t: 1582182000,
    v: -0.003185718708796248
  },
  {
    t: 1582185600,
    v: -0.0031563303218256067
  },
  {
    t: 1582189200,
    v: -0.001295425990136101
  },
  {
    t: 1582192800,
    v: -0.0034103651344024044
  },
  {
    t: 1582196400,
    v: -0.005383170115202063
  },
  {
    t: 1582200000,
    v: -0.004195574384458833
  },
  {
    t: 1582203600,
    v: -0.00426477804382186
  },
  {
    t: 1582207200,
    v: -0.0034555240375617746
  },
  {
    t: 1582210800,
    v: -0.0024647348893385157
  },
  {
    t: 1582214400,
    v: -0.0014697727622901002
  },
  {
    t: 1582218000,
    v: -0.0008669480819629705
  },
  {
    t: 1582221600,
    v: -0.000918812641682587
  },
  {
    t: 1582225200,
    v: -0.0004975536928708557
  },
  {
    t: 1582228800,
    v: -0.0007021888796034936
  },
  {
    t: 1582232400,
    v: -0.000734733325374437
  },
  {
    t: 1582236000,
    v: -0.0008397714217496244
  },
  {
    t: 1582239600,
    v: -0.0004498040757854316
  },
  {
    t: 1582243200,
    v: -0.00031411777924472695
  },
  {
    t: 1582246800,
    v: -0.00020638666792405225
  },
  {
    t: 1582250400,
    v: 0.00018309714177933145
  },
  {
    t: 1582254000,
    v: 0.0003426546929536922
  },
  {
    t: 1582257600,
    v: 0.00025370402253166446
  },
  {
    t: 1582261200,
    v: 0.0005774605907413743
  },
  {
    t: 1582264800,
    v: 0.00015782336349268178
  },
  {
    t: 1582268400,
    v: -0.0001932942598634746
  },
  {
    t: 1582272000,
    v: -0.0007056807941687726
  },
  {
    t: 1582275600,
    v: 0.0017137021764266347
  },
  {
    t: 1582279200,
    v: 0.0010674590083656899
  },
  {
    t: 1582282800,
    v: 0.00037925057859933275
  },
  {
    t: 1582286400,
    v: 0.0017107862102279103
  },
  {
    t: 1582290000,
    v: 0.0013778167516238504
  },
  {
    t: 1582293600,
    v: 0.0010067000728204434
  },
  {
    t: 1582297200,
    v: 0.0015600286338459668
  },
  {
    t: 1582300800,
    v: 0.002808595971868
  },
  {
    t: 1582304400,
    v: 0.002904510833029672
  },
  {
    t: 1582308000,
    v: 0.002868152142596836
  },
  {
    t: 1582311600,
    v: 0.0026412732650828996
  },
  {
    t: 1582315200,
    v: 0.0009525199414996965
  },
  {
    t: 1582318800,
    v: 0.0007535885212854865
  },
  {
    t: 1582495200,
    v: -0.0006469241246123136
  },
  {
    t: 1582498800,
    v: -0.00038450639252496366
  },
  {
    t: 1582502400,
    v: -0.0004910054793070273
  },
  {
    t: 1582506000,
    v: -0.0006108618394060628
  },
  {
    t: 1582509600,
    v: -0.0007721672909195565
  },
  {
    t: 1582513200,
    v: -0.001251857677931012
  },
  {
    t: 1582516800,
    v: -0.0010515922953693302
  },
  {
    t: 1582520400,
    v: -0.0007199362531737918
  },
  {
    t: 1582524000,
    v: -0.0009128025027205222
  },
  {
    t: 1582527600,
    v: -0.00040954500233181257
  },
  {
    t: 1582531200,
    v: -0.0030210385734272815
  },
  {
    t: 1582534800,
    v: -0.004719461634366207
  },
  {
    t: 1582538400,
    v: -0.0035166814008853464
  },
  {
    t: 1582542000,
    v: -0.003331441200758789
  },
  {
    t: 1582545600,
    v: -0.00381980674350757
  },
  {
    t: 1582549200,
    v: -0.0021641200658635285
  },
  {
    t: 1582552800,
    v: -0.0021435314850259957
  },
  {
    t: 1582556400,
    v: -0.0005730269871651039
  },
  {
    t: 1582560000,
    v: -0.0005797374175700032
  },
  {
    t: 1582563600,
    v: -0.0005997749293455978
  },
  {
    t: 1582567200,
    v: 0.00025733577484654546
  },
  {
    t: 1582570800,
    v: -0.0010151407644172217
  },
  {
    t: 1582574400,
    v: -0.0010815492266433857
  },
  {
    t: 1582578000,
    v: 0.000027243520020148182
  },
  {
    t: 1582581600,
    v: -0.0007795055542685336
  },
  {
    t: 1582585200,
    v: -0.0005710047608014079
  },
  {
    t: 1582588800,
    v: -0.0006580040806869469
  },
  {
    t: 1582592400,
    v: -0.0011382892120173072
  },
  {
    t: 1582596000,
    v: 0.0007771806754137689
  },
  {
    t: 1582599600,
    v: 0.0006704405789261791
  },
  {
    t: 1582603200,
    v: 0.0005375204962223545
  },
  {
    t: 1582606800,
    v: -0.0003306967175236153
  },
  {
    t: 1582610400,
    v: -0.00014488290073444432
  },
  {
    t: 1582614000,
    v: -0.0005984710577724428
  },
  {
    t: 1582617600,
    v: 0.00033988195048095804
  },
  {
    t: 1582621200,
    v: 0.002229898814698039
  },
  {
    t: 1582624800,
    v: 0.00178848469831272
  },
  {
    t: 1582628400,
    v: 0.0004787011699822763
  },
  {
    t: 1582632000,
    v: -0.00009825614001512939
  },
  {
    t: 1582635600,
    v: -0.0002513624057272068
  },
  {
    t: 1582639200,
    v: 0.0005874036522337533
  },
  {
    t: 1582642800,
    v: 0.0010434888447719004
  },
  {
    t: 1582646400,
    v: 0.002338704724090146
  },
  {
    t: 1582650000,
    v: 0.001997461192077221
  },
  {
    t: 1582653600,
    v: 0.0019106810217803805
  },
  {
    t: 1582657200,
    v: 0.001459155161525949
  },
  {
    t: 1582660800,
    v: 0.0007492758527365417
  },
  {
    t: 1582664400,
    v: 0.0009022364452027087
  },
  {
    t: 1582668000,
    v: -0.00009379733268355395
  },
  {
    t: 1582671600,
    v: 0.00027245942912834664
  },
  {
    t: 1582675200,
    v: 0.00004639379639570507
  },
  {
    t: 1582678800,
    v: 0.0001611946826249877
  },
  {
    t: 1582682400,
    v: 0.00018388115653555914
  },
  {
    t: 1582686000,
    v: -0.0003566732943980355
  },
  {
    t: 1582689600,
    v: -0.0012057199666268836
  },
  {
    t: 1582693200,
    v: -0.0007563313999661148
  },
  {
    t: 1582696800,
    v: -0.0015111411999708668
  },
  {
    t: 1582700400,
    v: -0.0014966924571178097
  },
  {
    t: 1582704000,
    v: -0.0017457363918151891
  },
  {
    t: 1582707600,
    v: -0.004064916907270177
  },
  {
    t: 1582711200,
    v: -0.004589928777660068
  },
  {
    t: 1582714800,
    v: -0.004665653237994283
  },
  {
    t: 1582718400,
    v: -0.0050177027754236825
  },
  {
    t: 1582722000,
    v: -0.003933745236077479
  },
  {
    t: 1582725600,
    v: -0.0034174959166377583
  },
  {
    t: 1582729200,
    v: -0.003692139357118096
  },
  {
    t: 1582732800,
    v: -0.003283262306100987
  },
  {
    t: 1582736400,
    v: -0.0032813676909437817
  },
  {
    t: 1582740000,
    v: -0.003434029449380427
  },
  {
    t: 1582743600,
    v: -0.0029363109566116208
  },
  {
    t: 1582747200,
    v: -0.0024168379628100034
  },
  {
    t: 1582750800,
    v: -0.002908718253837206
  },
  {
    t: 1582754400,
    v: -0.00280175850328912
  },
  {
    t: 1582758000,
    v: -0.001957221574247603
  },
  {
    t: 1582761600,
    v: -0.0015061899207837737
  },
  {
    t: 1582765200,
    v: -0.0005567342178147694
  },
  {
    t: 1582768800,
    v: -0.0006900579009840868
  },
  {
    t: 1582772400,
    v: -0.00013433534370044242
  },
  {
    t: 1582776000,
    v: -0.00021085886602900672
  },
  {
    t: 1582779600,
    v: 0.00048783525768936187
  },
  {
    t: 1582783200,
    v: 0.00017100164944805485
  },
  {
    t: 1582786800,
    v: -0.0006805700147587412
  },
  {
    t: 1582790400,
    v: -0.0015190600126502307
  },
  {
    t: 1582794000,
    v: -0.0019163371537003204
  },
  {
    t: 1582797600,
    v: -0.004632574703171688
  },
  {
    t: 1582801200,
    v: -0.0047479211741472405
  },
  {
    t: 1582804800,
    v: -0.004466789577840524
  },
  {
    t: 1582808400,
    v: -0.0019201053524346978
  },
  {
    t: 1582812000,
    v: -0.0034000903020867668
  },
  {
    t: 1582815600,
    v: -0.002662934544645834
  },
  {
    t: 1582819200,
    v: -0.0029339438954105734
  },
  {
    t: 1582822800,
    v: -0.002520523338923386
  },
  {
    t: 1582826400,
    v: -0.0011418771476487954
  },
  {
    t: 1582830000,
    v: -0.0003901804122703467
  },
  {
    t: 1582833600,
    v: -0.000717297496231728
  },
  {
    t: 1582837200,
    v: -0.0007662549967701882
  },
  {
    t: 1582840800,
    v: -0.0014282185686600446
  },
  {
    t: 1582844400,
    v: -0.0004999016302802417
  },
  {
    t: 1582851600,
    v: -0.00011134425452574703
  },
  {
    t: 1582855200,
    v: -0.000678295075307922
  },
  {
    t: 1582858800,
    v: -0.0017471100645496396
  },
  {
    t: 1582862400,
    v: -0.0016303800553283399
  },
  {
    t: 1582866000,
    v: -0.0007531829045672733
  },
  {
    t: 1582869600,
    v: -0.0009012996324861966
  },
  {
    t: 1582873200,
    v: -0.0023011139707025396
  },
  {
    t: 1582876800,
    v: -0.0020423834034593025
  },
  {
    t: 1582880400,
    v: -0.0015049000601079499
  },
  {
    t: 1582884000,
    v: -0.0005170571943782232
  },
  {
    t: 1582887600,
    v: -0.0017717633094671559
  },
  {
    t: 1582891200,
    v: -0.0017386542652573223
  },
  {
    t: 1582894800,
    v: -0.0033317036559350477
  },
  {
    t: 1582898400,
    v: -0.00502288884794444
  },
  {
    t: 1582902000,
    v: -0.009761047583952465
  },
  {
    t: 1582905600,
    v: -0.009255183643387621
  },
  {
    t: 1582909200,
    v: -0.00792730026576094
  },
  {
    t: 1582912800,
    v: -0.009641971656366488
  },
  {
    t: 1582916400,
    v: -0.006430261419742678
  }
]
